import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280 1280">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="">

<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1280.772827,710.765381 
	C1281.000000,711.750000 1281.000000,712.500000 1281.000000,713.625000 
	C1279.452881,715.695923 1280.191650,717.790894 1279.999023,719.723999 
	C1279.258423,727.156250 1281.766479,734.917786 1277.740601,742.016602 
	C1277.568359,742.320312 1277.501099,742.665527 1277.715088,742.958313 
	C1282.668823,749.737793 1278.347900,757.470215 1279.899292,764.632996 
	C1280.442749,767.141418 1279.220825,769.831543 1279.784546,772.534302 
	C1280.193970,774.498047 1278.942383,776.039551 1277.401855,777.165161 
	C1275.760376,778.364319 1274.459717,779.836487 1273.154175,781.843750 
	C1270.265137,784.175659 1267.499390,786.006165 1264.127808,786.579834 
	C1262.382080,786.876770 1261.106934,787.877380 1259.580933,789.267517 
	C1257.724487,790.278442 1256.206421,790.067139 1254.350830,789.207031 
	C1253.572021,788.758423 1253.179199,788.438538 1252.583008,787.798828 
	C1251.591187,786.282471 1250.892090,784.992676 1249.431641,784.327148 
	C1249.668335,785.144104 1250.352417,785.636169 1250.804077,786.686035 
	C1251.117188,787.483215 1251.225098,787.964172 1251.313721,788.835632 
	C1251.378174,791.833008 1249.865601,793.531494 1247.602783,795.182495 
	C1246.806152,795.581970 1246.317627,795.676941 1245.455322,795.585938 
	C1243.526123,794.646240 1243.017456,793.012756 1242.231812,791.569702 
	C1240.053467,787.568054 1239.404541,787.525513 1235.590576,791.331421 
	C1235.144653,791.708435 1234.950806,791.841614 1234.436768,792.123413 
	C1232.018921,793.030212 1229.907349,793.168884 1227.775757,792.414612 
	C1224.275513,791.176086 1223.896729,789.667908 1226.242188,786.307617 
	C1224.861572,787.866760 1222.984253,788.506165 1221.081421,789.105896 
	C1216.337036,790.601318 1214.636108,789.760742 1213.665527,784.891846 
	C1213.152466,782.317322 1212.320435,780.187805 1210.133057,778.604431 
	C1207.872314,776.968018 1207.600830,774.829834 1209.094604,772.497009 
	C1210.743774,769.921692 1212.913452,769.617188 1215.538208,770.995056 
	C1216.970215,771.746704 1218.353638,772.806946 1220.183228,772.410156 
	C1219.875610,765.281067 1216.109497,759.983948 1211.153809,754.913818 
	C1210.146729,752.725525 1211.317627,749.884888 1208.088013,748.796753 
	C1207.672974,748.413635 1207.526733,748.240051 1207.236572,747.759644 
	C1206.816528,745.852966 1205.384033,745.213928 1204.311646,744.092590 
	C1204.717407,745.326172 1205.895752,745.731934 1206.621338,746.970947 
	C1207.288696,748.919006 1207.625732,750.585938 1207.610474,752.662720 
	C1207.404297,758.233093 1205.313721,763.139893 1204.953857,768.249939 
	C1204.710693,771.703979 1205.127686,775.283691 1201.437988,776.769104 
	C1197.588501,778.318787 1193.079346,778.380981 1190.274536,775.033630 
	C1186.719849,770.791321 1182.797363,769.463013 1177.543945,770.339539 
	C1174.459595,770.854248 1171.587158,769.908875 1169.612305,766.990601 
	C1169.577393,766.845337 1169.412964,767.130676 1169.541260,767.294922 
	C1170.160889,768.346802 1170.125000,769.286682 1169.841309,770.553223 
	C1168.845459,772.353882 1166.381226,773.681274 1169.434814,775.851562 
	C1171.020996,777.419495 1168.018311,778.696838 1169.528076,779.808899 
	C1172.033081,781.654053 1174.833496,782.652649 1177.960938,781.535522 
	C1179.220093,781.085754 1180.191406,780.093079 1181.295410,779.349487 
	C1185.771484,776.334717 1186.834351,776.553955 1189.391724,781.154358 
	C1189.966675,782.188538 1190.595459,783.128662 1191.434204,783.957458 
	C1192.771484,785.278687 1193.310547,786.835266 1191.961914,788.382019 
	C1190.629639,789.910217 1188.860229,790.337463 1186.992676,789.331543 
	C1185.126221,788.326233 1183.385498,785.546753 1181.409058,789.199341 
	C1178.961060,793.723267 1179.159912,795.622681 1182.831787,798.174500 
	C1184.701904,799.473999 1186.727295,800.590149 1185.622192,803.240234 
	C1184.687256,805.482483 1182.657227,804.849243 1180.838501,804.499023 
	C1180.060425,804.349243 1179.113281,803.912354 1178.595215,804.875854 
	C1178.140991,805.720215 1178.725586,806.453247 1179.217651,807.122864 
	C1179.714355,807.799072 1180.274902,808.477234 1181.004517,808.849060 
	C1186.039795,811.415588 1187.098511,815.960449 1187.208740,821.025513 
	C1187.235718,822.267700 1186.531860,823.964355 1188.289551,824.453552 
	C1189.756592,824.861694 1190.732666,823.659607 1191.465576,822.500061 
	C1192.619751,820.673950 1194.098999,820.027222 1196.108154,821.076721 
	C1197.463379,821.784485 1198.681763,821.222534 1199.932983,820.736023 
	C1202.228760,819.843445 1204.212524,819.853516 1205.128784,822.684814 
	C1205.961792,825.259216 1204.347168,830.697083 1202.571899,831.291870 
	C1201.683105,831.589600 1200.997681,831.143066 1200.240356,830.772217 
	C1194.063477,827.747498 1187.491455,831.058044 1186.024292,837.949829 
	C1185.333130,841.197083 1185.763550,844.632080 1184.336792,848.133301 
	C1183.812744,850.209106 1183.609009,851.996399 1182.548096,853.924316 
	C1181.630981,854.750000 1180.863770,854.922546 1179.638916,854.756165 
	C1177.931396,853.966370 1177.374512,852.755920 1176.938599,851.022705 
	C1176.869629,850.363525 1176.864502,850.099060 1176.900879,849.442871 
	C1177.078491,848.290955 1177.440308,847.590759 1177.574585,846.443542 
	C1179.308350,839.485596 1177.671265,833.197815 1175.683228,827.027649 
	C1171.615356,814.402222 1167.496826,801.791504 1164.287354,788.916992 
	C1163.228271,784.668152 1160.968750,783.520691 1156.706909,785.073853 
	C1155.006836,785.393250 1153.723633,785.759155 1152.630127,787.208191 
	C1152.168213,787.535889 1151.968262,787.645020 1151.442383,787.857422 
	C1150.670410,788.035522 1150.221191,788.061584 1149.439941,788.011230 
	C1148.264160,787.827576 1147.427490,787.625916 1146.261963,787.472168 
	C1145.518555,787.452637 1145.091797,787.453857 1144.341431,787.482056 
	C1143.354858,787.586121 1142.740234,787.848389 1141.766602,788.111084 
	C1136.785278,788.488464 1132.906738,790.821899 1128.594238,793.018738 
	C1122.673096,793.217590 1119.619019,796.368774 1117.767578,801.355164 
	C1116.905762,803.675903 1116.765991,806.229614 1114.854858,808.837036 
	C1114.060547,809.354065 1113.821533,809.404053 1113.224365,809.378906 
	C1110.703857,807.664062 1109.906860,805.002563 1107.969238,802.754150 
	C1103.757812,798.019775 1098.070923,796.961121 1091.618042,799.939880 
	C1086.643555,802.236145 1082.123657,805.264526 1079.595215,810.883545 
	C1078.185059,811.882446 1076.858154,811.680298 1076.354492,810.586914 
	C1073.678589,804.778503 1069.755859,806.017639 1065.200439,808.567139 
	C1064.392090,808.741272 1063.922729,808.719604 1063.139282,808.453613 
	C1062.700195,802.906921 1061.465332,798.103699 1056.620850,794.805725 
	C1055.571533,794.091431 1055.823608,792.413452 1056.598877,790.836914 
	C1059.765869,789.364075 1062.049561,785.277832 1066.232178,788.674194 
	C1067.176392,789.861816 1067.416992,791.434753 1069.512085,790.914368 
	C1072.308472,791.583557 1073.508667,791.337769 1073.597412,788.092590 
	C1073.772827,781.679138 1077.665161,778.876404 1083.846069,780.137756 
	C1087.884399,780.961853 1091.618042,782.914185 1095.800781,783.299927 
	C1097.896606,783.493225 1098.095215,785.910889 1099.544922,787.388123 
	C1101.783569,785.695312 1103.298340,783.013672 1106.663940,782.641846 
	C1107.975952,782.483582 1108.934326,782.368835 1110.256348,782.559021 
	C1111.445679,782.752563 1112.207031,783.042480 1112.853271,783.614502 
	C1114.175903,784.785278 1113.994263,787.989441 1116.959961,786.907715 
	C1119.688843,785.912415 1121.596069,784.014221 1122.745850,780.093933 
	C1118.224365,783.076477 1114.320312,786.182373 1110.454102,780.375854 
	C1110.037109,779.648254 1109.867188,779.201172 1109.687256,778.384155 
	C1109.857544,771.889160 1111.386841,770.167419 1117.395020,770.541687 
	C1122.022095,770.830017 1126.466064,771.707275 1130.878662,769.086487 
	C1132.985596,767.835205 1135.334351,769.286377 1137.291870,770.758484 
	C1139.532837,772.443604 1141.859009,774.045532 1144.577637,774.236389 
	C1145.844360,773.423889 1145.390015,772.632385 1145.065674,771.893372 
	C1143.852417,769.129456 1145.005615,768.361572 1147.713257,768.340210 
	C1152.750977,768.300476 1161.494873,762.318970 1163.983032,757.420410 
	C1162.616699,756.350891 1161.107544,757.013977 1159.670410,757.109985 
	C1157.754272,757.238159 1155.828613,757.060974 1154.651733,755.492859 
	C1152.818848,753.050659 1150.858032,751.363037 1147.681641,750.498657 
	C1144.761108,749.703918 1145.349854,746.346985 1148.706909,743.618774 
	C1143.060181,743.531311 1143.163574,743.741211 1138.421631,746.075928 
	C1134.979126,747.770996 1130.647217,746.764404 1127.332764,749.395508 
	C1126.623047,749.958984 1125.482300,748.925537 1124.938599,748.039734 
	C1123.728882,746.069092 1122.309814,744.241272 1121.872559,741.833313 
	C1120.613770,734.900208 1120.728271,734.742493 1127.620483,734.052124 
	C1132.162476,733.597229 1135.565674,731.040527 1138.739990,727.841736 
	C1137.171631,726.527527 1135.354248,726.870605 1133.856323,726.197388 
	C1130.699463,724.778320 1128.979980,724.888184 1127.230957,728.956482 
	C1125.495239,732.993652 1120.773682,734.881226 1116.061768,735.145874 
	C1106.520874,735.681702 1103.066406,741.950439 1107.822876,750.417725 
	C1109.351440,753.139038 1107.641479,754.364929 1105.584351,755.924438 
	C1101.025146,756.769165 1098.884644,761.042664 1094.483398,762.138306 
	C1090.152710,761.390747 1088.717896,758.604309 1088.362427,755.036377 
	C1088.084106,752.240967 1088.000610,749.431519 1088.797363,746.290161 
	C1092.525513,741.734314 1093.573730,747.482056 1096.713867,747.811035 
	C1093.949219,744.949890 1091.897583,742.130981 1090.315796,739.028381 
	C1087.826538,734.145630 1088.183228,730.829895 1091.681519,727.939697 
	C1093.296997,727.803772 1094.433228,728.186584 1095.777832,729.051636 
	C1097.093018,730.759338 1097.963257,732.413940 1100.272583,730.871033 
	C1101.643433,729.954956 1103.480591,730.274353 1105.317383,729.522522 
	C1107.987061,727.922546 1106.535767,725.997681 1106.424194,724.050781 
	C1101.195068,727.734253 1100.708618,727.583313 1097.708862,721.849182 
	C1096.634766,719.795959 1095.443604,717.805359 1095.676270,714.990112 
	C1095.800903,714.123413 1095.962646,713.647095 1096.400391,712.863647 
	C1098.618530,710.669739 1100.993774,709.694763 1104.102905,709.564331 
	C1105.212036,709.529785 1105.939941,709.433533 1107.020752,709.412842 
	C1108.159424,709.273987 1109.180664,709.338684 1109.288940,708.265076 
	C1109.368164,707.480225 1108.512451,707.340210 1107.644165,706.881836 
	C1106.961914,706.427429 1106.637939,706.092102 1106.198486,705.400024 
	C1105.528564,703.612244 1105.714966,702.150940 1106.315186,700.776733 
	C1107.675903,697.661499 1105.829224,696.574463 1103.311401,696.006104 
	C1100.292480,695.324585 1099.662109,697.194336 1099.574707,699.648926 
	C1099.481812,702.255798 1099.567993,704.933289 1097.318359,707.271973 
	C1092.336914,707.581543 1087.767578,704.673828 1082.598633,706.907532 
	C1078.818481,705.291870 1079.283691,702.595154 1080.331787,699.467346 
	C1081.739014,697.914185 1083.155640,698.046570 1085.232178,698.688538 
	C1083.780518,697.653503 1082.706421,697.077148 1081.674316,695.935303 
	C1081.038330,695.156250 1080.703979,694.578369 1080.181274,693.733154 
	C1079.057983,691.164551 1077.001343,690.623962 1074.536133,690.112366 
	C1073.309082,689.675171 1072.502686,689.190613 1071.624023,688.202271 
	C1071.031128,684.039612 1074.012939,681.710571 1075.950317,679.176270 
	C1077.324219,677.379089 1078.361328,675.819702 1078.545898,673.623352 
	C1078.642944,672.469482 1078.935181,671.341064 1079.486328,670.312256 
	C1080.513428,668.394714 1081.634155,666.487732 1084.075806,666.240051 
	C1086.738892,665.969788 1087.000122,668.440979 1088.335571,670.178589 
	C1089.623535,670.870850 1090.629272,671.423035 1091.718262,671.690247 
	C1093.199219,672.053833 1094.643921,674.151672 1096.023560,672.810059 
	C1097.396118,671.475342 1095.483643,669.922974 1094.954834,668.122925 
	C1094.529297,665.165039 1094.438965,662.428650 1097.928223,662.141418 
	C1101.203247,661.871826 1101.922485,664.448059 1102.375610,667.074829 
	C1102.681274,668.846680 1102.861694,670.677917 1103.894653,672.364502 
	C1105.364380,671.223083 1106.677612,670.072571 1108.456665,669.444824 
	C1111.268066,668.452820 1112.496704,664.874939 1111.440063,662.104492 
	C1110.867798,660.604004 1109.932617,660.473755 1108.700317,661.017761 
	C1105.499390,662.430664 1103.039917,661.312012 1101.557129,658.475586 
	C1100.296021,656.063110 1098.732788,655.886780 1096.292480,656.246460 
	C1090.218384,657.141724 1087.187500,655.447388 1087.043579,651.559204 
	C1086.897583,647.618469 1089.896606,645.269348 1095.576172,645.455872 
	C1098.882690,645.564514 1102.170898,644.858215 1105.511230,645.569824 
	C1107.110229,645.910583 1109.126465,645.280396 1109.297485,643.216919 
	C1109.457886,641.280762 1109.260376,639.096497 1107.736572,637.641663 
	C1106.869019,636.813477 1105.840820,637.665039 1105.278687,638.546753 
	C1104.558960,639.675659 1104.394653,641.058899 1103.798218,642.248230 
	C1101.874756,646.084106 1097.584717,646.753296 1095.581055,643.070190 
	C1093.085083,638.481995 1089.634644,638.383118 1085.490234,639.420837 
	C1082.537231,640.160217 1080.675293,638.612610 1079.215454,636.442810 
	C1077.582275,634.015198 1078.160156,631.310364 1080.808960,630.776367 
	C1086.961548,629.536194 1086.690674,624.714783 1087.126465,620.481140 
	C1087.686157,615.044739 1088.089722,614.444092 1093.390015,615.123169 
	C1096.050171,615.463867 1098.572998,616.270325 1101.112305,617.100586 
	C1104.153442,618.094849 1107.066040,617.731567 1109.449463,615.378540 
	C1110.859131,613.986938 1111.093384,612.509949 1109.316406,611.164734 
	C1107.711060,609.949463 1106.627197,610.354187 1105.871826,612.186707 
	C1104.819580,614.738953 1102.768921,615.605774 1100.192505,615.065063 
	C1097.534302,614.507141 1095.095825,612.730469 1095.122925,610.199829 
	C1095.175659,605.239746 1092.517700,601.067993 1092.008057,596.350647 
	C1091.095459,598.487610 1091.095337,598.487610 1091.274292,600.983459 
	C1091.638062,606.057068 1090.286499,608.397400 1085.413574,610.060059 
	C1082.894287,610.919678 1080.889160,611.915833 1080.056763,614.665894 
	C1079.566528,616.285706 1078.086304,617.303528 1076.536621,616.615479 
	C1073.315063,615.185242 1069.816772,614.723450 1066.593140,613.437744 
	C1063.639771,612.259766 1061.288696,607.307739 1062.001465,604.209961 
	C1062.885132,600.369507 1065.884399,599.625122 1069.252075,599.430786 
	C1073.340576,599.195007 1077.460693,599.794678 1081.595459,598.713257 
	C1076.551880,597.212280 1072.064087,593.341370 1066.120972,596.373230 
	C1062.777100,598.079163 1059.062378,598.365417 1055.545776,595.435364 
	C1054.857178,594.236511 1054.777222,593.270813 1054.963867,591.922363 
	C1055.428589,590.247559 1056.614624,589.261414 1056.701416,587.447754 
	C1056.841187,586.895020 1056.912720,586.677979 1057.125244,586.148743 
	C1057.679077,585.043640 1058.361816,584.375366 1058.384766,583.044800 
	C1059.338013,578.162292 1058.531982,573.869202 1057.033813,569.552307 
	C1051.393555,553.299133 1046.343384,536.844604 1040.217041,520.343140 
	C1039.548340,518.732361 1039.425171,517.407959 1039.332520,515.682129 
	C1038.521118,508.545349 1036.588379,502.105621 1034.475098,495.666656 
	C1028.725952,478.148499 1022.362183,460.849670 1015.622742,443.704254 
	C1013.255188,437.681183 1011.986816,431.322540 1009.209290,425.449493 
	C1007.275330,421.359955 1005.567017,417.328125 1000.995605,415.386078 
	C999.675781,414.825439 998.663208,413.534058 997.667786,411.921448 
	C997.634033,406.739349 995.211792,404.020386 990.219788,403.516754 
	C990.859863,404.385254 991.080078,405.565918 990.307373,407.042786 
	C985.896057,408.688141 983.415161,405.203827 980.218628,403.814056 
	C975.808105,401.896515 972.112549,398.858002 968.340820,395.414368 
	C968.142761,391.876984 970.025513,391.028137 972.760193,391.307617 
	C974.254333,391.460266 975.796936,391.748169 977.101746,392.421997 
	C984.621765,396.305817 992.350220,395.708405 1000.193237,393.777863 
	C1002.345825,393.247986 1004.506470,393.520050 1006.607056,394.160553 
	C1008.411804,394.710907 1010.143250,394.681610 1011.993042,394.171112 
	C1018.377258,392.409180 1020.507629,393.532532 1023.629150,400.370819 
	C1023.122437,403.483459 1021.039307,403.857452 1018.761292,403.876160 
	C1017.600281,403.885712 1016.437927,403.587372 1015.285950,403.685791 
	C1014.113098,403.786011 1012.214539,403.157257 1012.301941,405.169037 
	C1012.369934,406.734222 1013.993774,407.075745 1015.372681,407.041626 
	C1018.370422,406.967407 1021.339355,407.252045 1024.652222,407.599915 
	C1027.579468,408.230377 1029.347900,409.654541 1028.657349,412.488586 
	C1028.025757,415.080688 1026.038452,416.164429 1023.350342,415.797791 
	C1022.554199,415.689240 1021.695007,415.399780 1021.211731,416.824524 
	C1020.390076,419.058716 1019.292542,420.584564 1017.275269,421.354645 
	C1015.589966,421.997986 1015.037415,423.161102 1016.340576,425.317719 
	C1018.288269,422.830597 1020.084045,420.170227 1023.896240,420.214661 
	C1028.316772,422.122162 1026.962402,424.787781 1025.130615,427.754700 
	C1024.800903,428.449280 1024.421387,428.945435 1025.523926,429.069824 
	C1032.012939,431.532349 1034.719727,428.474396 1036.831177,422.928497 
	C1038.593872,418.298462 1039.949341,418.309784 1044.454834,420.866821 
	C1046.183472,421.847809 1048.234863,422.419922 1049.381348,424.153687 
	C1051.537720,427.415009 1053.812622,428.414246 1056.839722,425.152618 
	C1057.511108,424.429260 1058.636230,424.203278 1059.627075,424.745636 
	C1060.846558,425.413116 1060.984375,426.611420 1060.734619,427.790802 
	C1060.251953,430.068817 1059.778198,432.344025 1059.357788,434.636780 
	C1058.538208,439.105072 1055.434204,441.064301 1050.965088,440.153076 
	C1049.324951,439.818634 1047.856567,438.976715 1046.279297,438.450378 
	C1051.840698,441.520660 1054.464233,441.813873 1060.460693,440.198578 
	C1062.086548,439.760620 1062.915771,439.069458 1063.078857,437.173035 
	C1063.621216,430.863068 1067.745972,427.629669 1073.791260,429.101593 
	C1076.938843,429.867950 1078.891602,429.743805 1080.888306,426.793701 
	C1082.480347,424.441437 1085.269775,424.184387 1087.076416,427.018402 
	C1088.468262,429.201660 1090.356934,430.705505 1092.323486,432.260193 
	C1094.220581,433.760101 1095.686646,435.882812 1094.950317,438.330719 
	C1093.790039,442.187042 1095.458618,444.308044 1098.666138,445.925537 
	C1099.952393,444.771271 1099.323853,443.161316 1099.568970,441.783417 
	C1099.974121,439.505188 1101.036987,438.095398 1103.502563,438.149780 
	C1106.473755,438.215332 1108.897461,436.951996 1111.381958,435.083435 
	C1108.357300,434.121063 1105.938965,435.161194 1103.464355,436.173981 
	C1098.398682,438.247345 1096.755859,437.349243 1093.463257,433.098663 
	C1089.928345,428.535278 1086.908447,423.527527 1082.220337,419.848785 
	C1077.820190,416.396057 1078.047119,414.863861 1082.301636,410.956482 
	C1084.984985,408.492249 1085.652466,405.520813 1083.190063,402.594757 
	C1081.327393,400.381348 1081.166626,398.411987 1082.248901,395.758240 
	C1084.111206,391.191406 1084.661133,386.033875 1089.187866,382.835022 
	C1090.510010,381.900665 1089.767944,380.009247 1089.980713,378.558563 
	C1090.354614,376.009308 1091.688599,374.099854 1094.205444,373.556366 
	C1096.928101,372.968414 1097.920044,375.183716 1098.896851,377.151917 
	C1099.562744,378.493622 1099.952148,379.962616 1100.732788,381.251526 
	C1101.826904,383.057831 1102.861572,382.235168 1104.122070,380.902100 
	C1107.617920,378.053192 1110.597778,378.460175 1113.598389,381.224609 
	C1117.207275,384.549408 1122.194580,385.942993 1125.909912,389.552032 
	C1127.760864,389.173615 1127.619995,387.707703 1128.240112,386.354431 
	C1129.101562,384.520020 1130.051270,383.020325 1131.432373,382.160797 
	C1134.901978,380.001862 1136.675293,376.756104 1138.067871,373.118652 
	C1139.751831,368.720184 1142.715454,366.426910 1147.720947,367.206482 
	C1150.838623,367.692017 1154.046631,367.182098 1157.192871,367.610931 
	C1162.784668,368.373108 1162.782959,368.382172 1164.075562,362.702637 
	C1164.186401,362.215607 1164.317749,361.733185 1164.427490,361.245941 
	C1164.963745,358.863220 1164.894531,355.803101 1168.489258,356.065521 
	C1171.971924,356.319794 1173.131104,358.888123 1173.266357,362.020294 
	C1173.332397,363.550629 1173.600952,364.946747 1174.558472,366.220917 
	C1176.842407,369.260406 1177.006714,372.624298 1176.009766,376.190491 
	C1175.488159,378.056305 1174.596924,380.023285 1176.686157,381.775482 
	C1176.242188,380.123077 1175.337036,378.555206 1175.874756,376.763428 
	C1176.471558,374.775238 1177.794312,373.281738 1178.895752,371.628693 
	C1179.823853,370.235840 1180.690552,368.806702 1181.000122,367.136566 
	C1181.405518,364.949554 1181.125244,363.187012 1178.457642,362.612762 
	C1176.734131,362.241730 1174.502563,362.071716 1174.546753,359.582001 
	C1174.586914,357.336884 1176.290527,356.126129 1178.138428,355.175995 
	C1180.440430,353.992310 1183.975098,355.103790 1185.203735,352.211975 
	C1186.602783,348.918579 1182.557739,348.074554 1181.497559,345.780670 
	C1180.324341,343.241943 1180.401978,340.607117 1180.442017,337.945709 
	C1180.469849,336.096313 1180.634644,334.175873 1178.546509,333.209106 
	C1177.023071,332.503754 1175.206177,331.641388 1174.025146,333.359558 
	C1172.907104,334.986237 1174.947510,335.506409 1175.792480,336.348328 
	C1178.726929,339.272247 1179.127930,342.249878 1176.968750,345.713348 
	C1175.154907,348.622925 1168.502563,351.677338 1165.175049,350.992615 
	C1162.506104,350.443329 1160.530029,348.846375 1160.224121,346.058411 
	C1159.825928,342.430206 1163.078857,342.088013 1166.320801,340.562988 
	C1159.800903,339.662201 1159.128418,335.487244 1159.572632,330.832581 
	C1159.876709,327.645630 1158.830444,324.991241 1157.220459,322.365662 
	C1156.160522,320.637024 1155.278198,318.549835 1157.100098,316.876862 
	C1158.751221,315.360535 1160.486084,316.560699 1162.142822,317.414246 
	C1166.415649,319.615601 1167.001953,319.394806 1168.525024,314.925995 
	C1169.122070,313.174469 1169.952881,311.571564 1170.839966,309.964417 
	C1172.581787,306.808594 1174.859619,305.219574 1178.607422,306.595184 
	C1181.997803,307.839600 1183.865112,307.259460 1183.699097,302.888000 
	C1183.499268,297.621552 1185.085327,295.996185 1190.279419,294.378754 
	C1197.601807,292.098602 1198.565796,290.645142 1197.614990,282.739105 
	C1197.274536,279.907715 1197.780151,276.870087 1196.175537,273.855988 
	C1194.203369,276.170746 1194.996216,278.398773 1194.844360,280.466278 
	C1194.757935,281.644043 1194.729126,282.888306 1193.364502,283.344177 
	C1192.065063,283.778229 1190.822388,283.134216 1190.373779,282.102356 
	C1188.331055,277.403564 1185.689941,272.935242 1184.342041,267.971954 
	C1183.636597,265.373779 1185.186646,264.004089 1187.808838,263.612152 
	C1195.082642,262.525024 1200.219482,265.531219 1202.457153,272.580078 
	C1203.098755,274.600891 1204.376221,275.098022 1206.130859,275.255219 
	C1208.290527,275.448730 1210.458984,275.312866 1212.620850,275.419250 
	C1214.292969,275.501495 1216.493896,275.331726 1217.283813,276.712128 
	C1219.782593,281.078735 1224.366089,281.018250 1228.558228,282.639618 
	C1229.943726,280.337372 1229.730347,277.571747 1230.474854,275.101013 
	C1231.288696,272.400299 1232.847168,270.744904 1235.779785,270.711365 
	C1238.576904,270.679352 1239.849609,272.235748 1240.544678,274.852112 
	C1241.527100,278.550659 1241.683594,278.509064 1250.355103,282.642029 
	C1252.420532,279.489288 1250.960571,274.442200 1254.746948,272.547577 
	C1258.047485,270.896088 1261.968384,272.425323 1265.555542,272.924927 
	C1268.587646,273.347137 1269.883545,270.741547 1272.534668,269.788574 
	C1278.222656,270.129120 1279.291138,271.236908 1279.504272,276.224884 
	C1280.048706,288.961884 1279.882935,301.695190 1279.540649,314.434448 
	C1279.416626,319.047546 1279.816284,323.669006 1280.735596,328.625946 
	C1281.000000,454.687561 1281.000000,580.375122 1281.000000,706.531372 
	C1279.894531,708.054138 1279.783569,709.236267 1280.772827,710.765381 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M1.245998,865.248291 
	C1.000000,812.312439 1.000000,759.624878 1.000000,706.468628 
	C6.665801,704.754089 11.090673,706.365967 12.231058,711.638245 
	C13.724005,718.540405 17.808493,719.786560 23.862251,719.919617 
	C33.386253,720.128906 33.705029,720.697815 34.873497,730.190979 
	C35.622066,736.103271 30.053593,736.991211 27.828930,740.267517 
	C30.342062,740.190735 30.475355,740.257751 31.228580,738.760010 
	C32.215282,736.797974 32.867996,734.431519 35.829945,735.759155 
	C38.307114,736.869446 38.310017,739.018799 37.750435,741.281067 
	C37.633438,741.754028 37.443497,742.213257 37.515587,743.299072 
	C41.144863,738.996948 45.941418,738.079346 50.831753,737.577209 
	C53.860161,737.266296 56.599045,737.486450 58.458622,740.806519 
	C60.062752,743.670593 63.176300,743.769470 65.929817,742.454468 
	C67.848549,741.538208 69.842712,740.663574 71.851608,739.121582 
	C72.323875,738.710632 72.533951,738.564941 73.104279,738.266846 
	C75.943321,737.596069 78.272911,738.118103 80.854309,739.368164 
	C81.690674,740.250977 81.880241,740.991577 81.748840,742.163574 
	C80.529030,744.443298 78.507462,743.834534 76.836029,744.238586 
	C75.202515,744.633606 73.168198,744.190308 72.108589,746.566833 
	C75.276192,747.517578 78.256966,747.270203 81.568497,746.309448 
	C85.353310,744.463989 88.084236,747.529907 91.454147,747.597107 
	C89.700974,746.625122 87.707741,745.944031 88.363365,743.092163 
	C89.023689,741.881592 89.822433,741.359375 91.120300,740.953247 
	C95.982300,742.130920 97.088600,738.136597 99.543015,735.581909 
	C103.032890,733.532349 106.969833,733.283936 110.189316,730.619080 
	C112.076172,729.293457 114.679367,731.561584 115.992844,728.586304 
	C116.728432,727.541321 117.373817,726.882507 118.459045,726.198853 
	C121.776482,725.562073 124.279922,724.426575 125.311905,720.830505 
	C126.131737,719.727112 126.947563,719.181213 128.243088,718.685181 
	C133.706192,716.934204 138.862732,715.678711 144.613892,716.396606 
	C145.490234,717.316101 145.447876,718.082886 145.035187,719.230957 
	C143.686417,721.204956 141.862305,721.586731 139.737579,722.177124 
	C130.521408,722.349670 125.858932,728.528198 121.071762,735.077087 
	C120.661758,735.827820 120.352730,736.203613 119.704979,736.756287 
	C116.174889,738.225525 113.279106,741.449829 109.322296,738.566528 
	C108.681381,738.099426 107.668579,738.574585 107.106689,739.269470 
	C106.350121,740.205078 107.252434,740.715881 107.736679,741.322937 
	C109.686928,743.767700 109.647171,746.595520 106.691330,747.384399 
	C103.195679,748.317444 104.043625,750.156189 104.182816,752.427307 
	C104.415596,756.225342 104.623177,759.790649 100.500298,762.447693 
	C97.268311,764.530701 98.196167,774.524475 102.025032,776.850098 
	C108.299332,780.661255 110.574287,787.198425 114.389008,792.688477 
	C115.907280,794.873535 116.351883,797.265259 114.210739,799.524353 
	C111.879700,801.983887 111.298714,804.647949 113.373581,807.634766 
	C114.244652,808.888733 114.667480,810.455261 114.465263,811.929321 
	C113.498627,818.975647 118.105713,822.485229 123.070694,825.776184 
	C127.650078,828.811646 132.190262,831.904358 136.447296,835.378479 
	C140.645889,838.804993 143.590958,843.065491 144.589859,848.505432 
	C144.649643,848.830994 144.007843,849.184692 144.970718,849.449097 
	C154.119110,851.960815 161.975967,858.081665 171.704926,859.029846 
	C172.685852,859.125427 173.527054,859.976074 174.462616,860.142944 
	C178.830963,860.921875 179.364136,867.342407 184.575089,866.862610 
	C189.210068,866.435791 192.340118,870.427979 196.634247,871.160706 
	C198.106079,871.411865 199.124329,872.767883 200.284210,873.711426 
	C201.642609,874.816345 203.492279,875.827332 204.877655,874.974731 
	C209.952057,871.851746 215.679123,874.267578 220.882278,872.696899 
	C222.037933,872.348022 223.255035,872.431335 224.253281,873.129944 
	C227.037735,875.078735 230.249298,875.934692 233.409439,876.978699 
	C235.170776,877.560547 236.882919,878.439819 236.185577,881.171143 
	C234.722473,882.465149 233.204239,882.710754 231.723907,882.592957 
	C223.302933,881.922791 214.765289,883.753662 206.425049,881.410583 
	C205.617920,881.183838 204.794449,881.155334 203.958878,881.311768 
	C192.751404,883.410278 184.291077,878.549805 176.307037,871.264404 
	C172.983231,868.231445 167.717102,868.144775 163.225754,867.435059 
	C157.249634,866.490601 151.021927,867.208313 144.939041,864.831970 
	C137.178818,868.102234 135.651779,867.416870 132.669495,858.708496 
	C131.879974,863.048828 131.242355,866.459595 130.619278,869.872742 
	C130.157059,872.404724 129.426529,874.740906 126.310402,875.732788 
	C124.331604,875.762268 122.670303,874.394409 120.723557,875.962769 
	C119.247902,876.377747 118.091179,876.352295 116.591965,876.028320 
	C111.777000,874.243652 113.197556,878.080139 112.322563,880.234558 
	C110.044861,882.627991 107.271439,882.333740 104.670151,882.693115 
	C99.584427,883.395691 98.434418,884.705872 98.487770,889.715271 
	C98.507309,891.550659 98.635956,893.446960 97.954613,895.137146 
	C96.115562,899.699585 97.895828,902.423706 102.445389,904.167480 
	C104.335548,904.454834 105.308083,903.835144 105.625580,901.877930 
	C105.933380,900.558838 106.384949,899.699463 107.374352,898.726929 
	C107.905258,898.374573 108.138321,898.265259 108.750168,898.074463 
	C109.643120,897.962769 110.148552,898.015259 110.989273,898.300293 
	C114.738991,902.610535 114.204994,907.651611 109.450409,909.883789 
	C104.312927,912.295776 98.861938,914.896301 92.972107,911.677002 
	C90.571030,910.364563 90.147186,911.952087 89.737137,914.106689 
	C89.376991,914.891174 89.039474,915.247559 88.670509,915.587036 
	C87.072899,917.057190 83.388557,915.973572 83.460533,919.259033 
	C83.529503,922.406921 84.256584,925.748657 86.809006,927.857483 
	C90.234093,930.687256 92.728844,933.925171 94.379784,938.094116 
	C95.799759,941.679810 99.471634,941.951172 102.816216,942.296204 
	C105.738853,942.597717 108.879440,941.962341 111.531830,944.635742 
	C113.378838,947.423035 111.480583,950.605103 113.575432,953.352783 
	C116.353035,961.491760 118.151222,962.508484 126.397224,960.757507 
	C127.676399,960.485840 128.922714,960.046387 130.630569,960.108276 
	C132.707657,960.489258 133.976776,961.605286 135.271225,962.624817 
	C137.059555,964.033264 138.748840,964.861816 141.152695,963.770813 
	C144.636536,962.189758 146.508606,964.102051 147.266235,967.357422 
	C147.918045,970.158081 147.666397,972.973999 146.826630,975.722168 
	C144.275024,984.072449 145.914795,985.624573 153.273758,990.792419 
	C155.493103,992.350891 158.183365,993.609741 161.092072,993.817078 
	C164.523422,994.061707 165.662292,996.228149 165.434982,999.260132 
	C165.211380,1002.242615 165.070221,1005.214050 165.155396,1008.588196 
	C164.519577,1010.912598 163.101074,1012.027527 161.008530,1012.958801 
	C159.041321,1013.540771 157.383743,1013.437988 155.729950,1013.467407 
	C154.631042,1013.487000 153.033936,1013.004822 152.714966,1014.417786 
	C152.324448,1016.147644 154.127441,1016.365295 155.275833,1016.850952 
	C156.955551,1017.561218 158.635208,1018.252014 160.376221,1019.575073 
	C163.376480,1024.027466 165.494659,1028.349365 164.949326,1033.678711 
	C164.643600,1036.666260 165.985184,1039.164551 169.411270,1040.435547 
	C171.005493,1041.705322 170.626175,1042.946655 170.102829,1044.213379 
	C169.666824,1045.268677 169.026794,1046.278687 169.094635,1047.471802 
	C169.200836,1049.339844 169.621017,1051.132690 171.559418,1051.925293 
	C172.684723,1052.385254 173.853348,1052.136475 174.663544,1051.100586 
	C175.882614,1049.541870 176.126068,1047.841675 175.224731,1046.041382 
	C173.992676,1043.580566 171.219208,1042.693481 169.403732,1040.273315 
	C168.195572,1036.655029 169.968155,1035.036865 172.787796,1034.090820 
	C174.513428,1033.511841 176.341705,1033.056152 178.113449,1033.314941 
	C184.098846,1034.189575 190.205078,1033.394165 196.495468,1034.956299 
	C197.237762,1035.597412 197.503387,1036.064209 197.635300,1036.992554 
	C197.258209,1039.397705 195.170151,1039.869019 194.265778,1041.002686 
	C198.375900,1044.002930 201.348175,1049.550659 208.152252,1047.704102 
	C212.058716,1048.933960 212.233551,1052.205688 213.171860,1055.410645 
	C213.624969,1058.090210 215.606720,1059.004272 217.449295,1060.509399 
	C218.162598,1061.315186 218.515732,1061.936768 218.951996,1062.920898 
	C219.771317,1065.611572 219.686172,1068.113892 221.120560,1070.355225 
	C223.250366,1073.682983 222.437469,1075.068848 218.606781,1075.344971 
	C217.613449,1075.416504 216.621994,1075.216675 215.627136,1075.201050 
	C214.607956,1075.185059 213.429047,1075.211304 213.359558,1076.504517 
	C213.283936,1077.911743 214.548187,1077.888916 215.518509,1077.867432 
	C217.684464,1077.819458 219.826904,1077.992920 221.960129,1078.332397 
	C228.609650,1079.390747 232.581116,1085.532715 230.257355,1091.860718 
	C229.260696,1094.574707 229.957657,1095.946289 231.765213,1097.848267 
	C236.386459,1102.710571 235.089951,1107.204590 228.784180,1108.700195 
	C226.366150,1109.273804 226.840408,1110.491577 227.689484,1111.930176 
	C228.121674,1112.662231 228.978882,1112.912720 229.701904,1113.319214 
	C234.454941,1115.991455 234.479080,1116.129639 231.437241,1121.060181 
	C234.494965,1122.452393 237.623672,1121.108521 240.968109,1121.496216 
	C242.832962,1121.202759 244.322006,1121.395874 246.172623,1121.656006 
	C249.158646,1122.633179 251.829300,1123.301025 254.560898,1123.873291 
	C256.880341,1124.359253 258.337494,1124.310425 260.054321,1122.363525 
	C262.580872,1119.498413 265.431885,1121.307739 268.380615,1122.875977 
	C271.793793,1127.002808 271.799408,1132.734985 267.835541,1137.574829 
	C265.120117,1140.890503 263.689606,1145.131470 259.973358,1147.760498 
	C258.169220,1149.036743 258.027039,1151.508301 258.173401,1153.750854 
	C258.707855,1161.939697 262.510315,1169.019409 265.941010,1176.187378 
	C267.373718,1179.180664 269.697601,1181.000366 273.581573,1180.397339 
	C277.129883,1179.846436 278.720551,1182.304199 277.674622,1185.377808 
	C276.073883,1190.081543 278.030396,1194.231934 278.769073,1198.593140 
	C279.086487,1200.467163 280.089355,1201.666992 282.136383,1201.501831 
	C284.111694,1201.342651 285.254730,1200.086670 285.192932,1198.202881 
	C285.010986,1192.656250 289.833832,1190.038818 292.376831,1186.184692 
	C294.121399,1183.540649 297.179657,1184.896362 299.107941,1187.298950 
	C301.997437,1190.899292 302.559052,1197.421265 299.891602,1201.416260 
	C298.252563,1203.871094 297.741180,1205.998657 298.831329,1208.805908 
	C299.996918,1211.807251 299.943542,1215.054199 299.092590,1218.160767 
	C298.247009,1221.247681 296.241943,1222.642944 293.045776,1221.287598 
	C291.200409,1220.505127 289.352051,1219.786743 287.401581,1219.309448 
	C284.459961,1218.589844 282.635956,1216.881104 282.974854,1213.642090 
	C283.299866,1210.535522 281.916016,1208.952026 278.915680,1208.529297 
	C276.779236,1208.228394 275.485992,1206.871460 274.246613,1205.146484 
	C272.340912,1202.493896 270.672211,1202.748169 269.159058,1205.713989 
	C268.082855,1207.823364 267.850983,1210.165039 267.562012,1212.487305 
	C267.292145,1214.656128 267.028564,1217.268311 264.263733,1217.335571 
	C261.525757,1217.402100 261.716949,1214.734009 261.442780,1212.838989 
	C261.151642,1210.826660 261.079010,1208.812500 258.615692,1208.087769 
	C256.301819,1207.406738 255.061081,1208.491821 253.624695,1210.232178 
	C251.738998,1212.516724 248.925293,1211.613037 246.655594,1207.719604 
	C244.967545,1204.823853 243.025757,1202.605835 239.725006,1201.751587 
	C238.781448,1201.507446 237.747467,1200.787964 236.907104,1201.555542 
	C235.915421,1202.461426 236.385086,1203.758789 236.665939,1204.864258 
	C238.032745,1210.244385 242.085434,1213.283447 247.639542,1213.161377 
	C255.950363,1212.978516 259.158539,1215.928467 259.619904,1224.122925 
	C259.773712,1226.854858 259.089691,1230.093262 263.461578,1230.298828 
	C266.998688,1230.465210 269.777832,1230.331421 270.916046,1225.804321 
	C272.453278,1219.690552 276.540344,1218.125488 282.423492,1220.553589 
	C284.608795,1221.455566 286.860565,1222.570312 288.069031,1224.547852 
	C292.898956,1232.451294 301.451691,1234.593262 308.977448,1238.218140 
	C312.047302,1239.696899 313.553101,1240.834106 311.096588,1243.964600 
	C309.737610,1245.696533 310.832306,1247.067871 312.156067,1248.627197 
	C315.324371,1251.205566 318.032288,1250.744873 320.900543,1248.060059 
	C322.651917,1246.954224 324.262878,1246.394897 325.927368,1246.468262 
	C331.340088,1246.706299 336.481110,1247.126587 341.413025,1243.396973 
	C344.355682,1241.171875 351.128906,1244.711060 354.196960,1249.250244 
	C354.470551,1250.756714 354.519165,1251.861328 354.181946,1253.364746 
	C353.704926,1254.802124 353.084412,1255.664551 351.912231,1256.565552 
	C348.470673,1258.066650 346.198425,1256.284912 343.899139,1254.632935 
	C341.278992,1252.750366 339.468506,1252.118652 337.344147,1255.865234 
	C335.406952,1259.281738 333.656494,1261.777100 336.098450,1265.799072 
	C338.175201,1269.219360 334.435303,1272.446655 334.667450,1276.213989 
	C335.877228,1277.601685 336.854248,1278.732178 336.222382,1280.700928 
	C301.979095,1281.000000 267.958221,1281.000000 233.468658,1281.000000 
	C231.098831,1277.714844 228.897202,1276.573364 224.986404,1278.476074 
	C220.741760,1280.541138 215.935532,1279.467529 210.987015,1279.153687 
	C210.400223,1278.922485 210.176346,1278.806763 209.660126,1278.456055 
	C207.456329,1273.460327 205.291443,1269.017090 200.819366,1265.862915 
	C198.813431,1264.448242 197.794312,1263.681152 196.072327,1265.835205 
	C194.886734,1267.318115 193.274551,1268.475098 191.303436,1267.323364 
	C189.568756,1266.310059 189.905548,1264.440308 190.098114,1262.798218 
	C190.428802,1259.978271 190.213058,1257.134155 190.531448,1254.321533 
	C190.792740,1252.013428 191.536957,1250.050537 194.437241,1250.053955 
	C191.847031,1250.161987 189.299347,1249.552124 186.715530,1249.484253 
	C182.410355,1249.370850 180.980728,1250.807007 181.021805,1255.014648 
	C181.031509,1256.010010 181.272720,1257.015747 181.042435,1257.999146 
	C180.399017,1260.746582 178.868622,1261.156006 176.711823,1259.502075 
	C173.647369,1257.151978 172.940216,1252.784546 174.895569,1248.615967 
	C177.410629,1243.253906 176.654755,1241.543213 170.687775,1240.644287 
	C166.868317,1240.068970 165.189209,1237.989990 164.652832,1234.474976 
	C164.275055,1231.999268 164.606247,1229.457764 164.050629,1227.015747 
	C163.095383,1222.817139 162.544800,1218.214600 156.570633,1218.202515 
	C155.753098,1218.200928 154.873535,1217.758911 154.376877,1217.108398 
	C149.970749,1211.336060 145.689774,1205.486450 144.504074,1198.041382 
	C144.251328,1196.454346 144.918015,1195.490723 146.449692,1195.080566 
	C147.734482,1194.736694 149.131531,1195.184326 150.667664,1194.366699 
	C152.467300,1193.481934 154.078598,1192.882935 154.834396,1190.803467 
	C156.816711,1187.049438 154.240967,1183.883911 154.484253,1180.244629 
	C155.000946,1179.637207 155.400528,1179.433472 156.191437,1179.364868 
	C157.473877,1179.594238 157.871689,1180.355713 158.429596,1181.360352 
	C158.820999,1181.834106 158.931183,1181.954834 158.931183,1181.954834 
	C159.303558,1182.463013 159.323441,1182.477661 158.808716,1181.803711 
	C158.412689,1181.291260 158.287033,1180.939941 158.219299,1180.260010 
	C158.258606,1179.337280 158.470367,1178.745728 158.528137,1177.806641 
	C158.544830,1177.050415 158.547440,1176.614502 158.532501,1175.841309 
	C158.424805,1174.817505 158.466217,1174.131470 158.530441,1173.094238 
	C158.537491,1171.801758 158.545776,1170.860229 158.528259,1169.567993 
	C158.638931,1167.595093 157.928574,1166.059082 158.025375,1164.129883 
	C157.987350,1163.318604 158.183746,1162.773804 157.551117,1162.418945 
	C156.977646,1162.716675 157.082764,1162.870850 157.422562,1163.202271 
	C157.894180,1163.662476 157.016296,1163.617188 157.752869,1163.942627 
	C157.734741,1166.008545 156.764267,1166.805298 154.726562,1166.775635 
	C153.264603,1166.371948 152.400192,1165.576904 151.421936,1165.006592 
	C148.144653,1163.096436 147.926468,1159.974976 150.781906,1157.472168 
	C151.647339,1156.713623 152.673477,1156.174072 153.715317,1155.700073 
	C156.512085,1154.428101 157.959579,1152.453857 157.290604,1149.018311 
	C157.333466,1148.519043 157.846573,1148.541382 157.527924,1148.403687 
	C156.458710,1149.655396 155.422714,1150.587402 153.591248,1150.800049 
	C150.137146,1150.725708 149.481232,1148.143677 148.206329,1145.705200 
	C145.825516,1141.356812 146.249237,1138.699707 150.359528,1135.374023 
	C153.776337,1132.609253 157.647385,1130.287231 160.732727,1127.089111 
	C161.611969,1126.177734 163.062469,1125.183350 162.350723,1123.739502 
	C161.441025,1121.894165 160.063873,1123.439941 158.910278,1123.725098 
	C158.269211,1123.883423 157.597610,1124.395386 157.006760,1124.256470 
	C149.062134,1122.388184 140.903824,1125.641235 132.974472,1123.188232 
	C130.080246,1122.292847 127.626450,1121.230347 127.583458,1117.639648 
	C127.540596,1114.059814 129.296356,1112.010376 132.776047,1111.443970 
	C135.370499,1111.021606 137.207520,1109.742432 137.373138,1107.008057 
	C137.554291,1104.017212 134.896240,1103.455444 132.950455,1103.244629 
	C127.711937,1102.677124 125.570930,1099.974731 123.976074,1094.949097 
	C122.591797,1090.587158 119.272339,1086.731812 117.497299,1082.278076 
	C117.254913,1081.670044 116.625290,1081.179688 116.509972,1080.553955 
	C115.110779,1072.960815 109.739754,1071.708496 103.417366,1072.736572 
	C99.013176,1073.452637 94.485771,1071.559204 90.058296,1074.506348 
	C87.167458,1076.430542 80.896500,1069.816650 80.959023,1065.361328 
	C80.977791,1064.023682 81.477623,1062.764526 81.517372,1061.414307 
	C81.666603,1056.345459 82.303078,1051.596924 78.572464,1046.855103 
	C75.806702,1043.339722 75.959831,1038.018066 76.777634,1033.306274 
	C77.067719,1031.634888 77.781448,1029.815796 79.703011,1029.686646 
	C82.061592,1029.527832 81.926056,1031.707764 82.223938,1033.276611 
	C82.449013,1034.462158 82.721214,1035.535400 84.076889,1035.924805 
	C85.667931,1035.022461 85.330574,1033.269043 85.944298,1031.975708 
	C86.727226,1030.325684 87.911629,1028.966064 89.757133,1029.382568 
	C93.272270,1030.175903 93.845695,1027.453857 95.706924,1025.603149 
	C101.805763,1019.538391 98.711761,1012.998901 97.671349,1006.489563 
	C97.174843,1003.383240 95.237617,1002.787964 92.540550,1003.772339 
	C91.276619,1004.233643 90.122871,1004.939636 89.132927,1005.830566 
	C85.409676,1009.181519 81.438309,1010.123779 76.766632,1007.715881 
	C73.900719,1006.238770 71.041176,1007.262817 68.407822,1008.071472 
	C68.253319,1009.131653 68.532288,1009.502808 68.935822,1009.832947 
	C70.324966,1010.969604 73.528923,1011.300232 71.960869,1013.949524 
	C70.430458,1016.535217 67.282280,1017.050781 64.928627,1015.276062 
	C62.083252,1013.130554 58.996799,1012.460999 55.689770,1011.957886 
	C52.174961,1011.423279 49.908115,1012.255310 49.428967,1016.299500 
	C48.973175,1020.146545 46.591255,1022.183289 42.229359,1021.971558 
	C41.415306,1021.671509 41.011921,1021.391724 40.453682,1020.735474 
	C39.148335,1011.055908 41.576630,1007.022278 50.730648,1005.884033 
	C57.647446,1005.024048 62.115856,1002.729248 62.819012,995.300476 
	C62.955959,993.853699 63.884708,992.504517 63.987968,990.984070 
	C64.021301,990.493286 64.011551,989.990784 63.263523,989.268311 
	C62.355206,993.932312 60.188789,997.293030 55.234756,998.021362 
	C53.917892,998.214905 53.726715,999.693176 53.410255,1000.791687 
	C52.464111,1004.075806 50.087078,1004.687866 47.151535,1004.156738 
	C45.453926,1003.849548 43.927448,1003.114929 43.366272,1001.304871 
	C42.796829,999.468201 43.562252,997.843750 45.024673,996.937439 
	C49.886650,993.924438 50.060097,988.203674 52.613182,983.882019 
	C54.198887,981.197937 52.470264,977.940369 50.397728,975.652527 
	C48.092758,973.107849 47.002239,970.226807 47.458408,966.965393 
	C48.167793,961.893860 45.708035,957.266541 45.450752,952.392700 
	C44.626278,952.860718 43.868160,953.895508 42.739338,954.485474 
	C38.329651,956.789734 34.540367,956.676819 32.124310,954.044922 
	C29.466087,951.149353 29.215225,944.835815 31.669329,941.415405 
	C32.732529,939.933411 34.311928,938.845276 35.060780,937.119873 
	C36.251953,934.375427 36.883362,932.099243 33.584568,929.853271 
	C29.855000,927.313904 28.312500,922.744934 25.937941,919.020508 
	C24.542702,916.832153 23.345325,915.918945 20.847244,916.288818 
	C17.569260,916.774170 14.573647,916.424683 12.588215,913.130859 
	C10.564917,909.774231 11.254028,906.793945 13.309330,903.828857 
	C13.877979,903.008484 14.434308,902.174866 14.890111,901.282837 
	C18.377661,894.457397 22.102287,893.959656 27.202438,899.643250 
	C27.532763,900.011292 27.775087,900.478333 28.340572,900.532043 
	C29.849363,895.065369 28.586117,891.826355 23.521851,890.047913 
	C20.258406,888.901855 18.523092,887.089172 18.144611,883.707886 
	C17.813164,880.746765 16.377176,878.673279 13.662449,876.990906 
	C8.429543,873.748047 8.826549,868.971741 14.223043,866.257202 
	C17.701181,864.507568 18.359381,862.253235 16.501766,858.897278 
	C15.187911,856.523682 15.001067,853.926941 16.862211,851.895081 
	C18.396023,850.220581 17.951675,849.484009 16.688570,847.938538 
	C14.804147,845.632874 12.991925,842.788330 15.280330,839.802368 
	C17.577778,836.804626 20.604525,837.872620 23.475094,839.075867 
	C24.705990,839.591736 25.892117,840.195068 27.072357,840.816284 
	C29.183987,841.927673 31.185946,842.855713 31.485270,839.093079 
	C31.624037,837.348755 33.282421,837.419189 34.522022,837.069031 
	C34.367416,836.852661 34.143810,836.578918 33.791992,836.527283 
	C25.291403,835.278992 20.841215,830.994995 19.345804,822.477600 
	C18.972425,820.350830 18.945168,818.714966 22.123825,818.526550 
	C24.968006,818.357788 27.843853,817.487244 30.659372,816.737305 
	C30.989399,816.649414 31.109186,815.886719 31.303440,815.426147 
	C30.933968,815.603577 30.735909,815.983093 30.394249,816.194580 
	C28.903341,817.117310 27.312620,817.078979 26.390656,815.645630 
	C22.839748,810.125061 16.541504,809.223694 10.712943,805.712158 
	C11.922996,808.938110 15.311451,812.350281 9.189361,812.643372 
	C7.127100,812.742065 7.762985,814.628601 8.022368,815.794617 
	C10.712732,827.888611 10.759027,840.250122 9.560906,852.345154 
	C8.957184,858.439819 10.867579,864.114990 10.016212,869.994324 
	C9.826328,871.305664 10.081212,872.847290 8.436147,873.290649 
	C6.724386,873.751892 6.042003,872.339417 5.594451,871.107605 
	C4.760827,868.813416 2.803625,867.403442 1.245998,865.248291 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M126.752289,1.243196 
	C129.041672,1.000000 131.083328,1.000000 133.562500,1.000000 
	C135.366714,2.220424 136.231186,3.934432 137.802673,4.944990 
	C140.723938,6.823515 143.103256,8.565022 147.119293,5.871111 
	C151.505264,2.929047 156.869858,5.222091 161.643570,6.408941 
	C164.927185,7.225319 167.834930,7.432304 170.705048,5.506589 
	C173.871384,3.382148 176.362640,4.435414 178.442215,7.214788 
	C180.796555,10.361407 180.678421,13.685196 178.148422,16.273165 
	C176.957077,17.491808 175.456299,18.229849 173.926270,18.893703 
	C172.137833,19.669664 171.571823,20.950932 171.740631,22.881790 
	C172.373718,30.124067 171.783310,37.593960 177.140656,43.702522 
	C178.700409,45.480976 179.038025,48.193874 176.980240,50.087414 
	C175.173737,51.749725 173.164215,50.673943 171.509674,49.478252 
	C168.306305,47.163250 165.526703,46.547218 163.431213,50.819496 
	C163.067444,51.561142 162.212494,52.034443 161.401352,52.185978 
	C157.918060,52.836685 157.526825,55.378597 157.602402,58.220058 
	C157.713547,62.399151 156.804077,65.512917 151.537781,65.095612 
	C148.892136,64.885963 147.306885,66.758301 146.202713,68.962433 
	C144.699020,71.964142 142.637863,74.171974 139.023621,74.396126 
	C137.130157,74.513557 135.810242,75.618042 135.489670,77.404709 
	C134.847183,80.985542 135.053925,84.506149 136.932404,87.840889 
	C138.117020,87.110565 137.948364,86.218399 137.955566,85.426003 
	C137.974976,83.291801 138.262421,81.326553 140.871933,80.934654 
	C143.341217,80.563820 145.129105,81.628723 146.053635,83.901985 
	C147.732361,88.029709 149.827896,91.926933 149.705582,96.721573 
	C149.560394,102.413269 147.752579,106.804466 142.640747,109.506279 
	C140.635498,110.566132 139.778305,112.159317 139.609299,114.303978 
	C139.211777,119.348274 137.072495,122.444084 131.582428,123.509926 
	C127.963638,124.212486 124.384819,125.893616 121.242271,128.285568 
	C116.558640,131.850540 110.549454,129.743332 105.382965,131.509781 
	C103.354874,132.203201 99.926537,130.520157 99.467194,133.595749 
	C99.008667,136.665924 102.474678,137.078033 104.650261,138.079803 
	C106.961555,139.144073 109.435249,139.542450 111.974640,139.158112 
	C119.172203,138.068817 125.847839,140.249130 132.351685,142.755371 
	C137.759491,144.839264 138.269028,148.061752 134.400650,152.283768 
	C132.448730,154.414139 130.257339,156.172653 127.244698,156.501114 
	C125.166206,156.727737 123.483376,157.551758 122.095825,159.201492 
	C120.126778,161.542587 118.183456,161.111252 116.237495,158.950668 
	C113.430626,155.834259 110.559090,155.414978 107.356888,158.006897 
	C104.805504,160.072021 102.190529,162.259186 101.464600,165.822891 
	C100.864609,168.768387 98.835564,170.795837 96.642403,172.626205 
	C94.839729,174.130692 92.731544,174.789017 90.700615,173.222610 
	C88.351532,171.410828 89.526665,169.416626 90.792450,167.521561 
	C91.237579,166.855118 91.946426,166.312469 91.898659,165.365829 
	C91.136185,164.568649 90.048721,164.847839 89.129829,164.570206 
	C83.003906,162.719284 83.238930,162.874680 80.004562,168.525742 
	C77.651985,172.636124 74.148277,175.993790 71.523712,180.009857 
	C69.869003,182.541840 66.726028,183.602356 62.842064,181.831696 
	C63.696545,186.182022 62.768120,189.121429 59.669155,191.938049 
	C56.638458,194.692642 53.849102,196.184570 49.744617,194.891052 
	C49.322067,197.617783 50.447971,198.594437 52.642345,198.666275 
	C54.972397,198.742554 57.288372,198.680389 59.622841,198.460358 
	C62.614269,198.178391 63.572937,200.200668 61.738541,202.084335 
	C58.922298,204.976273 57.714035,209.597015 53.057468,210.603714 
	C52.574902,210.708054 51.814274,210.905624 51.795143,211.280991 
	C51.417656,218.687393 41.621098,219.725449 41.131145,227.370438 
	C40.944199,230.287491 38.053009,230.026443 36.357979,227.817993 
	C33.791603,224.474304 31.369776,221.550446 26.590782,224.519623 
	C25.257095,225.348236 23.817427,223.820816 22.500967,223.150955 
	C20.662081,222.215240 18.879419,222.493408 17.367147,223.756577 
	C15.418754,225.384048 17.122904,226.745239 17.950500,228.056137 
	C20.010725,231.319504 18.730021,234.600983 17.816471,237.714355 
	C16.706451,241.497284 17.475077,243.776779 21.479588,244.830750 
	C24.045643,245.506134 26.408855,246.841034 29.027405,247.446335 
	C30.467297,247.779175 32.215935,248.097336 32.351585,249.949814 
	C32.494144,251.896667 30.885458,252.807251 29.400188,253.605042 
	C26.746235,255.030548 23.376003,254.215302 20.616623,256.463684 
	C15.281010,257.404053 11.788634,256.195190 9.954562,252.150360 
	C8.317266,248.539474 6.832056,246.835220 3.429995,250.260284 
	C2.980359,250.712967 2.192800,250.627991 1.266732,250.218231 
	C1.000000,168.431854 1.025119,86.863670 0.913300,5.295686 
	C0.908551,1.831095 1.449561,0.868369 5.194757,0.887674 
	C41.483295,1.074725 77.773178,1.000000 114.531342,1.000000 
	C118.690582,4.574836 122.517380,4.927675 126.752289,1.243196 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M34.336288,731.104248 
	C32.514332,729.705383 33.237476,727.878662 32.868362,726.314941 
	C32.040924,722.809631 31.132463,720.160950 26.208376,721.557678 
	C22.907461,722.493835 19.178991,720.786926 15.500390,721.362244 
	C13.969522,721.601746 12.671422,719.344299 12.527145,717.774231 
	C11.907211,711.027954 8.216727,707.340454 1.375778,706.052124 
	C1.000000,700.645081 1.000000,695.290100 1.000000,689.467590 
	C4.531391,686.602661 6.738076,689.205872 9.303150,691.302063 
	C9.613951,682.641357 10.031761,674.134705 8.087646,665.724487 
	C7.740544,664.222900 7.884754,662.730530 8.454083,661.344971 
	C9.184574,659.567200 8.603549,658.517944 6.627169,657.655029 
	C4.945852,656.059326 4.577352,654.341064 4.655714,652.105957 
	C5.928286,648.307800 4.575536,646.019653 1.252864,644.241455 
	C1.000000,608.312439 1.000000,572.624878 1.000000,536.468628 
	C2.376312,534.765625 3.490162,533.396973 3.629072,531.432495 
	C3.942289,527.002808 4.668071,522.622375 5.270783,518.227905 
	C5.685706,515.202637 4.772801,513.079407 1.259678,512.229126 
	C1.000000,508.638702 1.000000,505.277435 1.000000,501.458069 
	C2.579101,499.200836 5.843801,501.497498 6.712416,499.205902 
	C8.065883,495.635193 7.763044,491.680725 6.766633,488.045532 
	C5.739358,484.297760 5.987664,480.941010 8.115720,477.351074 
	C12.937236,476.499481 14.543169,473.355316 15.217659,468.950592 
	C16.803268,467.290039 18.540182,466.450836 20.513462,465.331604 
	C21.631319,464.902893 22.460281,464.722534 23.290512,464.805573 
	C25.834372,465.060150 27.829954,464.629700 28.781038,461.774567 
	C29.761242,458.831940 27.133877,459.118866 25.892979,458.126434 
	C22.059114,455.060394 21.660135,450.431366 25.159924,446.284058 
	C27.597082,445.349640 28.922575,442.995697 31.634066,442.865204 
	C27.683182,443.677673 27.959587,439.044098 24.959404,438.118073 
	C22.489891,436.710297 20.052174,436.210724 17.712608,435.452667 
	C16.593731,435.090149 15.270791,434.831848 14.814960,436.307831 
	C14.440590,437.520050 15.142590,438.625641 16.235876,439.182953 
	C19.112665,440.649475 22.081789,441.097870 25.461704,439.628082 
	C27.534557,441.155334 27.413298,442.874969 26.393757,445.015747 
	C24.578672,446.137421 23.318174,447.411102 21.474636,448.466614 
	C19.997053,448.910614 18.932175,448.405243 17.228626,447.941895 
	C18.799587,450.826385 19.820126,453.340485 19.059372,456.505035 
	C15.750780,460.828796 14.336566,460.566925 11.659163,455.146942 
	C11.436363,452.829956 11.219905,450.904816 10.348262,448.755798 
	C11.061068,442.995453 6.881008,438.805908 6.303576,433.459564 
	C5.864180,433.123810 5.687637,432.988525 5.253790,432.639618 
	C4.098091,431.294983 2.460905,431.486725 1.248860,430.235870 
	C1.000000,425.643097 1.000000,421.286194 1.000000,416.464661 
	C4.460616,413.355133 5.933884,410.122162 4.219710,405.801575 
	C3.679976,404.441162 3.654894,402.942505 4.201092,401.158203 
	C7.430727,396.624207 6.743165,391.414215 7.668023,386.711365 
	C8.359342,383.196045 3.557187,382.652588 1.251265,380.239319 
	C1.000000,357.979095 1.000000,335.958221 1.000000,313.468658 
	C2.754938,311.531921 4.418507,312.203705 5.750078,313.506073 
	C7.623064,315.337921 9.984582,316.429596 12.224789,318.323425 
	C14.310841,322.002533 9.932639,323.528839 10.100884,326.067505 
	C10.290496,328.928497 14.606169,329.375641 15.011483,332.563263 
	C15.276643,334.648621 14.395111,336.448547 13.794449,338.333344 
	C13.248443,340.046631 12.408488,341.704651 12.962358,343.979248 
	C14.409203,338.356964 16.249733,333.228790 23.814922,334.686890 
	C25.039976,334.751740 26.050186,335.407104 26.899286,334.005127 
	C29.830580,329.958466 30.496525,325.151489 33.406521,321.121155 
	C35.321045,320.536591 36.537117,320.174164 36.965389,317.965271 
	C37.695549,314.199402 39.558262,310.858063 43.568047,309.476288 
	C42.784740,307.854248 41.526730,307.261597 40.313728,306.828644 
	C34.749073,304.842377 33.970322,299.665375 33.399513,295.124908 
	C32.878998,290.984528 36.065327,292.870789 38.556938,293.374298 
	C33.709564,288.561493 31.921776,281.810760 27.191879,276.532288 
	C28.663370,270.374176 32.620811,273.323303 36.250622,273.913849 
	C37.816090,274.848541 38.393318,276.035400 38.994823,277.643250 
	C39.406460,278.702454 39.432793,279.483551 39.709393,280.199127 
	C40.004818,279.759979 39.712788,279.338837 39.627800,278.565613 
	C39.413136,276.762451 39.402191,275.317139 40.102448,273.598236 
	C41.424610,271.785614 43.013962,271.854858 44.611179,272.177399 
	C45.761875,272.409760 46.836323,272.686646 47.382118,270.875427 
	C47.397045,269.866455 47.420567,269.189972 47.561314,268.173279 
	C47.863945,265.684540 48.208553,263.542847 48.816303,261.475342 
	C49.651665,258.633484 51.635910,257.302307 54.525082,258.239899 
	C57.368114,259.162506 57.443012,261.466980 56.820415,263.966949 
	C56.328934,265.940460 54.541111,267.234802 54.009308,269.781311 
	C55.924110,269.308502 57.833233,267.468018 59.431709,269.780212 
	C60.618862,271.497437 60.763779,273.506744 61.342579,275.557861 
	C62.540520,272.317627 62.235794,270.064117 58.799332,268.332123 
	C56.395805,267.120728 54.863396,254.560837 56.351131,252.133606 
	C57.834530,249.713425 60.983356,249.229843 62.509506,251.792618 
	C65.015968,256.001617 68.480225,259.813751 69.967964,264.405792 
	C71.418564,268.883240 73.272209,271.085846 78.350670,269.470856 
	C77.832542,265.897583 77.690842,261.903046 79.881905,258.546967 
	C82.444763,254.621399 86.111977,254.759903 90.149178,257.274139 
	C90.598625,254.940948 88.752617,254.085175 88.096062,252.347107 
	C87.660904,251.004257 87.689331,250.003967 88.103043,248.651993 
	C88.395279,248.097916 88.542465,247.895981 88.983734,247.470047 
	C89.560127,247.101151 89.884201,247.030640 90.142250,246.865799 
	C90.699326,246.509979 91.609871,246.975464 91.923767,246.087875 
	C91.310776,245.931396 90.677483,245.943649 89.697960,245.802521 
	C84.185318,244.345428 84.148979,244.340164 83.427704,249.312424 
	C83.152573,251.209137 82.574638,252.894958 80.928299,253.988907 
	C79.375160,255.020935 77.804153,255.186310 76.512138,253.541733 
	C74.819336,251.386978 74.662682,239.636551 76.608925,238.206879 
	C80.062294,235.670090 83.341827,232.648209 87.824951,231.948059 
	C92.248360,231.257248 96.753487,231.204636 101.501663,230.417786 
	C104.767464,236.167725 102.889091,241.165283 97.033699,242.103317 
	C101.524414,244.684448 100.923698,248.839523 101.022850,253.106003 
	C100.065529,256.409363 102.155083,257.130127 104.488663,258.104126 
	C105.546089,262.153809 106.918991,265.735382 108.855576,269.502747 
	C109.041718,270.347443 109.058891,270.838257 108.931633,271.685852 
	C107.653534,274.605225 105.258614,273.857117 103.212578,274.087738 
	C98.164307,274.656738 98.187164,275.923828 98.367821,280.755951 
	C98.581375,286.467957 101.335388,287.746368 105.991135,287.975311 
	C107.293518,288.039337 108.572380,288.528290 110.016022,289.415619 
	C111.813522,291.672150 111.826859,294.334900 113.238167,296.471191 
	C114.619926,298.562775 114.876617,300.999390 112.790100,302.840454 
	C110.686424,304.696655 108.212143,303.882812 106.354279,302.492249 
	C104.903076,301.406067 104.412605,301.346405 102.634285,302.227600 
	C98.763031,304.145935 95.707542,301.039276 92.778236,299.049805 
	C89.436707,296.780365 91.498878,293.683228 92.098740,290.710876 
	C90.252876,293.428406 87.078552,294.535187 85.003342,297.354736 
	C84.482887,298.413269 83.954147,299.113464 84.043015,300.340149 
	C84.329887,302.316498 85.449898,303.192780 86.836624,303.911377 
	C88.533653,304.790771 89.256851,306.220367 88.474403,308.034424 
	C87.683113,309.868958 86.155609,311.225311 84.168892,310.381775 
	C80.384247,308.774933 79.490776,311.129791 78.770203,313.871246 
	C78.095978,316.436432 77.512253,319.019135 75.458092,321.341583 
	C70.766647,321.920746 68.879471,326.207031 64.993294,328.005096 
	C62.492985,328.717468 59.754349,327.578125 58.299492,330.518372 
	C58.134186,332.631622 54.577419,333.816986 57.623837,336.402039 
	C61.815830,339.323364 64.625954,343.826447 70.428680,343.502777 
	C73.245064,343.345673 73.938721,346.595398 75.624451,348.755493 
	C76.369766,349.494659 76.898888,349.960510 77.484192,350.843384 
	C77.045242,355.380707 74.743279,357.034180 70.995979,355.168640 
	C68.164230,353.758911 65.231682,353.633026 62.347694,353.308075 
	C58.925209,352.922424 57.814034,355.164581 59.536190,358.222107 
	C59.699512,358.512085 59.932690,358.789368 59.981277,359.107666 
	C60.488728,362.431946 63.226986,365.787170 60.769161,369.086426 
	C58.105110,372.662415 54.380665,370.536987 51.063240,370.025513 
	C49.592335,369.798737 48.170868,369.274628 46.757812,368.791473 
	C42.055031,367.183350 40.062305,363.600464 42.175007,359.110962 
	C43.415920,356.473999 42.783482,355.956940 40.559666,354.860474 
	C38.537098,353.863251 36.898106,352.021698 35.859100,349.853668 
	C35.572124,349.254883 35.424816,348.522797 34.971809,348.081299 
	C32.905533,346.067596 31.463997,343.052002 28.226700,342.785767 
	C26.679174,342.658508 25.738703,345.903961 25.616917,351.276093 
	C27.507298,349.633026 29.684153,348.322906 31.362028,350.432404 
	C33.406120,353.002319 36.720551,355.271942 34.975033,359.731628 
	C34.319000,361.155792 31.979700,361.605774 33.786530,363.635437 
	C37.194214,362.986816 39.213413,363.864410 40.796940,366.991425 
	C42.491619,370.337982 44.797424,373.450043 47.607513,376.045837 
	C49.139717,377.461151 50.481850,378.870148 49.836025,381.148621 
	C49.198761,383.396881 47.344185,384.022400 45.310902,384.447205 
	C43.749531,384.773468 41.966820,384.446594 40.633595,385.886810 
	C42.006039,387.531525 44.081043,387.765015 46.114647,388.829712 
	C46.658020,389.129425 46.862366,389.278046 47.306267,389.727417 
	C47.887600,390.756958 47.945026,391.513947 47.614544,392.646057 
	C46.956345,393.602386 46.287376,393.915314 45.216576,394.150177 
	C43.726738,394.199127 43.262405,394.509857 44.489601,395.783508 
	C44.757801,396.282562 44.841671,396.498627 44.991959,397.062225 
	C43.205105,402.778534 45.060585,406.808014 49.242313,410.356079 
	C51.431805,412.213776 51.887905,414.595001 48.118637,416.100830 
	C47.293659,416.452209 46.450825,415.595245 46.325447,416.854614 
	C48.582699,418.544067 49.323254,420.526398 48.890331,423.065887 
	C48.614159,424.685883 48.566460,426.345337 49.739105,428.113037 
	C50.320538,428.996857 50.609665,429.639252 50.914574,430.656708 
	C51.102695,433.586609 50.299316,436.076813 50.791393,438.766449 
	C51.735107,443.924774 49.551373,445.716797 44.469170,444.436188 
	C43.874535,444.286377 43.302929,443.772034 42.655289,444.759216 
	C42.425907,446.741486 43.413246,447.474396 45.180321,447.972351 
	C46.379475,448.593964 47.106949,449.303406 47.990025,449.770905 
	C54.726322,453.337097 55.045544,457.995178 48.877861,462.796478 
	C48.376453,463.186737 47.645447,463.320007 47.390797,464.276337 
	C52.027332,464.383087 56.650436,465.355713 60.785923,462.176727 
	C61.942234,461.287872 63.710724,460.680298 64.919785,461.984192 
	C66.393867,463.573944 65.027466,465.017456 64.119698,466.411285 
	C63.448856,467.441376 61.201263,468.413940 64.139610,469.926270 
	C64.827660,470.387848 65.155571,470.726868 65.563705,471.450562 
	C62.950668,480.340210 56.771454,483.013855 49.109032,478.179626 
	C49.629524,482.685760 47.865211,485.375854 43.531750,485.694672 
	C41.933460,485.812225 39.896431,485.338745 40.705814,483.430206 
	C42.113678,480.110382 40.490414,479.435120 37.907196,478.899139 
	C36.567974,478.621246 35.936752,477.172180 34.374619,476.510742 
	C33.976334,478.526093 34.761467,480.090271 35.867352,481.424530 
	C38.400963,484.481476 38.674843,488.468842 40.324421,491.899536 
	C41.620293,494.594574 38.821697,495.930847 36.874611,498.159729 
	C39.359085,498.039490 41.317322,497.342224 43.644585,497.530060 
	C44.704075,497.559540 45.410320,497.589691 46.468300,497.644775 
	C49.323460,497.759033 51.780056,498.207581 54.222790,498.709747 
	C55.963657,499.067627 57.341492,499.978943 57.687153,501.859039 
	C58.016365,503.649719 56.866699,504.624329 55.464920,505.361298 
	C54.056332,506.101807 52.967693,507.083496 52.986588,508.803284 
	C53.029793,512.736206 50.795219,514.083069 47.136986,513.942261 
	C48.642044,515.223450 52.146500,514.546143 51.294689,517.791504 
	C50.625626,520.340515 48.114124,520.501404 45.912914,520.389709 
	C42.931732,520.238464 40.001133,519.615295 37.400036,521.942200 
	C34.177895,524.824707 34.489002,527.326294 38.569706,528.915161 
	C39.802353,529.395142 41.491703,529.423340 41.536205,531.083618 
	C41.681263,536.496033 45.448807,535.355225 48.247829,534.445251 
	C51.091335,533.520874 52.864014,533.785828 55.409214,535.572571 
	C62.064396,540.244507 68.996559,538.447449 71.562218,531.654175 
	C71.546494,530.310242 72.319984,529.074646 70.507462,528.464355 
	C66.125763,533.784485 61.029995,533.284119 57.754120,527.116882 
	C55.578541,523.021118 52.185745,518.936584 56.844139,513.934326 
	C57.288219,513.238159 57.577419,512.865356 58.130249,512.251587 
	C59.378819,511.126282 60.257889,510.081757 61.498119,509.527985 
	C64.098274,508.366913 66.726044,508.019287 68.882202,510.291870 
	C70.551910,512.051758 69.736923,514.119690 66.576767,517.189087 
	C72.551285,519.674255 76.242439,524.426758 79.492477,529.664978 
	C79.507904,529.651245 78.994171,530.597168 79.092102,530.436584 
	C80.058952,528.850342 81.278053,527.841736 83.194176,528.402832 
	C84.737953,528.854919 85.083069,530.265808 85.001122,531.626526 
	C84.817268,534.679504 86.483704,535.777954 89.194420,536.001892 
	C90.712509,536.127319 92.269768,536.328186 92.763962,538.137878 
	C93.305328,540.120361 92.219612,541.268860 90.594330,542.114990 
	C89.828033,542.513977 89.473175,543.309570 88.938858,543.938110 
	C86.324142,547.013916 83.653687,547.081543 81.261284,543.727661 
	C79.736618,541.590332 78.223480,540.211670 75.541908,540.886414 
	C72.588524,541.629700 71.150284,543.657593 70.851089,546.998291 
	C76.767532,545.106140 81.980011,546.111450 86.683670,550.801575 
	C88.383469,548.416626 90.004166,546.378601 93.442139,547.577820 
	C95.366638,548.249084 96.251595,545.796631 96.330017,544.256653 
	C96.557526,539.788696 98.334503,536.944824 103.007294,536.999084 
	C103.262794,535.060547 101.991295,534.935059 101.216125,534.564331 
	C96.933594,532.516541 95.313271,529.957458 95.370689,524.509521 
	C95.426018,519.260620 96.259964,516.145203 99.644020,511.920380 
	C102.589989,508.242462 106.174660,505.098145 109.151001,500.988281 
	C109.560844,500.540527 109.739952,500.373627 110.224503,500.000092 
	C113.878357,496.691193 117.700073,494.588837 122.337914,494.224731 
	C124.420860,494.061157 125.506172,492.726776 126.347946,490.989746 
	C127.503510,488.605255 128.820389,486.311646 131.098236,484.245300 
	C133.524002,483.204224 135.639267,483.185547 138.126984,483.989655 
	C140.787872,489.377777 135.964539,491.628876 133.859070,494.966705 
	C133.188370,496.030029 131.735428,496.532379 130.672058,497.335449 
	C129.874588,497.937622 128.480408,498.530304 128.473419,499.259125 
	C128.428955,503.902039 123.769211,505.751892 122.486328,509.577942 
	C121.783936,511.672699 120.795898,513.577271 118.943611,514.885864 
	C117.596474,515.837585 117.098175,517.133789 117.037575,518.734497 
	C116.903450,522.277405 116.263321,525.654846 114.005585,528.586121 
	C112.511078,530.526489 112.980270,532.315125 114.911682,533.751343 
	C117.726776,535.844727 118.742607,538.664917 118.308510,542.087341 
	C118.115456,543.609375 118.342735,545.067444 119.034416,546.455750 
	C119.405388,547.200378 120.095009,548.405823 119.719208,548.761780 
	C115.310150,552.937866 119.640320,557.282410 119.320877,561.499512 
	C119.270943,562.158752 118.942329,562.889221 119.355614,563.426636 
	C123.533226,568.858826 123.066299,576.144165 126.731918,581.792969 
	C127.837570,583.496826 128.383224,585.420654 127.082336,587.423645 
	C124.766342,590.989624 126.169426,593.817383 129.259186,595.868835 
	C131.677612,597.474548 132.425446,599.625916 133.390182,602.242554 
	C135.122528,606.941284 136.931290,611.866516 140.820877,615.403870 
	C142.809753,617.212585 144.459396,619.269653 146.199249,621.284668 
	C149.353073,624.937439 152.811905,627.603760 157.915253,629.013794 
	C172.251129,632.974670 186.497559,638.005920 198.951004,646.043213 
	C206.954056,651.208374 215.240417,654.316528 223.988907,657.334656 
	C229.203873,659.133789 234.092957,662.324402 238.963089,665.176453 
	C254.714737,674.400879 270.534058,683.393127 287.388397,690.616272 
	C297.756866,695.059875 308.331787,698.990723 318.973022,703.702942 
	C319.547180,703.897034 319.770752,703.993042 320.309235,704.273071 
	C324.152496,706.934265 327.720673,709.238281 331.723969,711.025635 
	C336.505341,713.160278 339.766876,717.311523 342.328461,721.831848 
	C342.983032,722.987000 343.425995,724.222412 344.758789,725.351929 
	C348.421417,730.410461 347.647614,735.814514 345.310516,740.206055 
	C342.030365,746.369324 338.050293,752.468933 330.612976,755.468872 
	C329.536285,755.971741 328.767334,756.075378 327.600403,755.968506 
	C324.513519,754.620361 323.850037,753.148560 326.877625,751.445618 
	C331.306122,748.954590 334.508698,744.828918 337.453522,741.142639 
	C339.626831,738.422241 340.404358,733.818970 337.796387,728.835266 
	C331.468506,716.742981 320.331207,712.182922 309.038513,707.361755 
	C307.020142,706.500122 304.789246,706.617615 302.268250,706.387695 
	C301.407349,706.287537 300.929962,706.161682 300.136597,705.810303 
	C295.676422,701.157471 290.220276,699.431458 284.134094,698.169556 
	C283.551544,697.961792 283.326569,697.856079 282.798218,697.533142 
	C280.463898,693.996094 277.028748,693.657227 273.759827,693.721863 
	C269.169861,693.812561 265.358795,692.353210 261.868530,689.519653 
	C255.263107,684.156921 247.814758,680.031494 240.238907,676.364319 
	C231.786499,672.272949 223.630081,667.693542 215.383362,663.248535 
	C212.603409,661.750061 209.593735,660.964478 206.280502,661.261841 
	C202.474991,661.603271 199.186539,659.885498 195.825348,657.575439 
	C194.370163,652.989868 190.922897,650.959656 187.163254,649.523987 
	C178.636780,646.268127 169.927567,644.540955 160.619370,644.774292 
	C153.796234,644.945374 146.978699,643.004883 140.704575,639.018616 
	C138.594360,635.797852 136.847107,632.825317 132.394775,634.373718 
	C130.518326,635.026245 129.456406,633.413940 128.540863,631.970642 
	C127.640022,630.550720 127.889198,628.726562 126.625290,627.132019 
	C125.648445,626.102600 124.817680,625.414490 123.579399,624.706543 
	C118.008049,623.090149 116.032410,619.971558 116.872078,614.297424 
	C117.346634,611.090515 115.179817,608.138550 111.740196,606.392212 
	C110.441162,606.386230 109.510750,606.246460 108.278900,605.803955 
	C106.649422,604.825500 105.857796,603.549133 105.169327,602.238403 
	C104.032982,600.074768 102.559013,598.985046 99.977074,599.817932 
	C97.466774,600.627747 95.493233,599.530029 93.837646,597.654114 
	C92.964478,596.664673 91.954788,595.807495 90.986511,594.909363 
	C89.501503,593.531921 86.040703,593.285950 86.780746,590.743713 
	C87.663246,587.712036 90.673691,586.035095 93.647888,584.848999 
	C95.178162,584.238831 96.944138,585.079651 98.451836,584.160645 
	C98.529945,582.626465 97.427231,581.767639 96.493301,580.430298 
	C95.479332,578.211914 95.321686,576.282776 96.469421,574.440125 
	C98.253548,571.575684 97.298470,569.088013 94.073921,568.335632 
	C87.856110,566.884766 86.609543,567.530396 83.956215,573.554077 
	C83.423195,574.764099 82.894875,575.976440 82.195312,577.475037 
	C81.572273,578.821289 80.333099,579.753479 81.759666,581.315002 
	C82.229317,581.935486 82.463310,582.318237 82.798790,583.040894 
	C83.374840,585.883545 82.748680,587.846252 79.840576,587.884949 
	C76.605652,587.928101 73.342171,586.787903 73.113510,583.268372 
	C72.826042,578.843506 71.737572,575.386719 68.000519,572.705872 
	C66.415413,571.568787 67.151756,569.348511 67.291824,567.507446 
	C67.612228,563.296021 65.648628,561.735413 61.693203,563.277771 
	C58.758041,564.422180 57.070274,563.309570 56.230408,560.785461 
	C54.510788,555.617371 49.069084,556.617310 45.623428,554.317993 
	C44.266685,553.412598 42.641930,556.644287 39.950874,557.029236 
	C41.655968,558.261230 43.379143,558.801514 44.269108,560.289490 
	C45.313488,562.035706 45.285515,563.754395 44.232628,565.474487 
	C48.647739,564.805054 50.865322,566.392334 52.181900,570.909241 
	C52.659595,572.548157 51.718552,575.182373 54.048203,575.603455 
	C56.403580,576.029175 58.916283,575.910217 61.175121,574.237488 
	C64.686516,571.637207 65.975433,572.228394 66.434151,576.529114 
	C66.718765,579.197571 66.929398,581.831360 68.168907,584.266479 
	C69.620781,587.118835 68.085327,589.388794 66.625458,592.025208 
	C66.526459,593.075073 66.364861,593.759155 66.063049,594.773193 
	C65.042023,596.600464 65.510323,598.390259 64.933235,599.939697 
	C64.105133,602.163025 62.490246,603.760010 60.082695,603.438599 
	C57.553341,603.100891 57.948826,600.798584 58.152901,599.034180 
	C58.564510,595.475403 57.939106,593.666077 53.614147,594.704773 
	C50.226933,595.518188 48.457680,593.095032 49.047638,589.977661 
	C49.705372,586.502075 48.734558,583.791809 46.475460,580.876343 
	C47.114033,585.437256 44.412216,586.646057 40.969997,586.402954 
	C37.328056,586.145813 35.186722,587.196533 33.766491,590.807190 
	C32.348244,594.412781 29.785442,597.360840 24.379374,597.177246 
	C27.857719,598.426941 29.033604,598.021667 31.572351,594.794678 
	C32.883156,593.128479 34.228996,591.723816 36.479656,591.994446 
	C39.131046,592.313232 41.271954,593.729431 42.270477,596.173462 
	C43.188507,598.420410 41.320244,599.825562 39.907391,601.221497 
	C39.246464,601.874512 38.356873,602.278015 37.232460,603.315552 
	C42.411201,603.356201 47.121624,604.422852 50.869011,600.433655 
	C51.848919,599.390503 53.463459,598.920837 54.436844,600.115906 
	C56.898975,603.138916 60.635593,605.298889 61.225197,610.016602 
	C61.014320,612.319824 60.964394,614.322021 60.473877,616.187805 
	C59.483063,619.956604 58.580521,623.394165 63.571346,625.157532 
	C64.964310,625.649719 65.298714,627.428894 65.361969,629.315857 
	C64.693886,633.190552 62.312489,634.164062 59.102486,633.918457 
	C54.870258,633.594666 50.563316,634.197144 46.518749,632.035034 
	C44.508038,630.960083 42.352322,632.108582 39.777172,633.307556 
	C42.510483,635.254578 45.380093,636.298340 46.791862,639.062866 
	C48.710911,642.820618 47.686871,645.109497 43.512741,645.263855 
	C40.432041,645.377686 39.323021,649.028931 36.128082,648.653625 
	C39.153568,649.279358 41.711353,650.318115 43.217987,653.043274 
	C43.959831,654.385010 45.031456,654.875977 46.517323,654.725281 
	C53.200138,654.047729 59.701962,656.148315 66.396484,656.018250 
	C69.127594,655.965210 73.099693,655.569214 74.086876,660.264160 
	C73.961639,662.022034 73.232689,663.177002 72.344940,664.591125 
	C71.883751,665.328125 71.570175,665.801025 71.599075,666.651123 
	C72.170288,667.639832 72.867950,668.159241 73.569824,669.133789 
	C73.075844,673.617126 73.075844,673.617126 67.543106,674.496033 
	C67.458572,674.875793 67.597794,675.238159 67.980782,675.262024 
	C70.407677,675.413086 73.351929,674.758362 73.912415,678.267090 
	C74.414658,681.411133 71.685204,682.055664 69.729118,683.615601 
	C65.753967,686.785828 62.275566,687.382996 57.481552,685.128601 
	C53.707588,683.354004 48.995640,686.467346 47.577873,690.744019 
	C46.489464,694.027283 42.846062,696.067505 43.679695,700.276184 
	C45.634041,697.897034 48.938450,696.353577 47.908611,692.155884 
	C47.419418,690.161865 47.942562,688.424866 49.971684,687.621704 
	C51.892223,686.861389 52.942871,688.305481 54.175167,689.494812 
	C56.891739,692.116577 58.852451,695.016113 59.146145,698.927490 
	C59.338219,701.485596 60.601727,703.182434 63.426125,703.609497 
	C67.594353,704.239868 67.722961,707.750610 68.077805,710.875000 
	C68.462494,714.262329 66.510406,716.232117 63.467224,717.058228 
	C58.653458,718.365051 54.033253,720.256714 49.146450,721.388306 
	C45.561111,722.218506 42.586613,722.056519 39.894485,719.232971 
	C43.021484,724.557495 42.528896,726.805359 37.676147,729.456299 
	C36.656429,730.013367 35.838463,730.909790 34.336288,731.104248 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M41.736618,1021.222778 
	C46.305790,1020.918213 48.501759,1018.926636 48.014942,1014.431213 
	C47.788120,1012.336609 48.627136,1010.438721 50.746906,1010.375732 
	C55.695225,1010.228638 60.961693,1009.228760 64.913467,1013.646729 
	C67.271584,1016.283020 69.252068,1014.127930 71.517662,1013.394775 
	C70.664284,1011.393250 69.017105,1012.301270 67.918350,1011.776733 
	C66.235359,1010.973450 65.329102,1009.498108 65.327774,1007.810059 
	C65.325966,1005.509399 67.368591,1006.260193 68.718102,1005.984741 
	C72.648376,1005.182495 76.361610,1004.608704 80.053650,1007.350281 
	C82.391357,1009.086243 84.896606,1007.425171 86.759766,1005.648926 
	C88.479530,1004.009460 90.327675,1002.653320 92.515427,1001.770813 
	C96.619789,1000.114868 99.837524,1002.476990 99.585381,1006.821228 
	C99.357185,1010.752991 101.706589,1014.324097 101.316414,1018.319275 
	C101.224007,1019.265381 101.893417,1020.978394 101.619354,1021.110779 
	C97.310532,1023.191833 96.890930,1027.869263 94.566109,1031.242310 
	C93.835938,1032.301758 92.378914,1032.191040 91.358597,1031.507935 
	C87.682549,1029.046631 87.111534,1031.228027 86.995399,1034.284424 
	C86.921051,1036.241455 87.223282,1038.992920 84.373962,1038.645142 
	C81.913177,1038.344849 79.793564,1036.580566 80.374840,1033.399902 
	C80.546539,1032.460449 81.267403,1031.536499 80.173744,1030.585571 
	C77.984314,1031.342773 77.900185,1033.307983 78.058708,1035.027588 
	C78.528740,1040.126831 77.463142,1045.663086 82.535316,1049.498169 
	C84.113907,1050.691650 84.164162,1053.001099 83.805084,1055.108765 
	C83.194824,1058.690796 82.683769,1062.293335 82.265175,1065.902832 
	C82.045326,1067.798584 87.320084,1073.681274 89.141327,1073.771362 
	C90.557266,1073.841431 90.741974,1073.590576 93.176842,1068.031250 
	C94.303169,1071.910156 96.587570,1071.816406 99.730446,1071.229858 
	C103.275139,1070.568481 107.036026,1070.936768 110.695930,1071.061035 
	C113.873253,1071.168945 116.271698,1072.852905 116.721085,1076.017700 
	C117.095985,1078.658081 119.479744,1080.666870 119.633827,1082.522339 
	C120.128426,1088.478516 126.837929,1091.232300 126.438683,1097.462280 
	C126.288521,1099.805420 128.013580,1101.373413 130.781250,1101.019897 
	C132.501694,1100.800171 134.065201,1101.457153 135.543472,1102.263428 
	C137.394424,1103.273071 140.725815,1103.109131 139.558472,1106.683716 
	C138.619339,1109.559692 138.369415,1113.238159 133.853424,1113.006836 
	C131.338562,1112.877930 129.258621,1113.207520 128.966644,1116.518066 
	C128.689392,1119.661743 129.929520,1120.986572 132.938614,1121.536133 
	C137.292358,1122.331299 141.535751,1124.398804 145.880585,1121.321045 
	C146.914185,1120.588867 148.493484,1120.852661 149.730743,1121.530518 
	C153.350632,1123.514038 156.760284,1124.227295 160.075485,1120.763916 
	C161.378143,1119.403076 162.908997,1120.297363 163.936356,1121.568115 
	C165.199905,1123.130981 165.831345,1125.731689 164.125214,1126.386108 
	C161.027252,1127.574341 161.001984,1133.086426 156.240097,1131.673218 
	C155.765152,1131.532227 154.670044,1133.189209 153.991669,1134.106934 
	C153.304855,1135.036011 152.880875,1136.862061 152.164902,1136.956543 
	C145.886536,1137.785889 148.891373,1141.996826 148.993820,1145.537109 
	C148.340851,1148.031372 148.166809,1150.104858 149.904419,1151.788208 
	C151.826874,1153.650513 152.289505,1152.130249 152.860443,1150.250244 
	C154.775208,1149.940674 155.469818,1147.772461 157.651154,1148.009644 
	C161.104309,1153.411499 157.918671,1155.914917 153.419907,1157.663574 
	C151.638931,1158.355713 150.208145,1158.956421 149.982620,1161.246216 
	C149.663254,1164.488647 152.466339,1162.884277 153.555984,1163.851074 
	C153.859695,1164.120605 153.842331,1164.751831 153.981354,1165.557495 
	C153.983566,1166.578735 154.371933,1167.093872 154.890106,1167.909302 
	C155.687286,1169.619873 156.895386,1170.852905 156.396744,1172.920044 
	C155.782928,1173.741211 155.196564,1174.064941 154.256348,1174.403198 
	C152.507050,1175.178467 152.624146,1176.277588 153.366379,1177.706787 
	C154.028091,1178.373047 154.441406,1178.846436 154.892975,1179.679688 
	C156.487823,1183.347656 158.728058,1186.608643 156.218689,1190.757690 
	C153.581635,1191.682739 152.673309,1189.856812 151.422562,1188.226562 
	C151.306763,1188.036743 151.073975,1187.779053 151.144104,1187.926514 
	C151.983246,1189.396973 151.723740,1190.661255 150.907654,1192.125000 
	C150.819626,1192.351318 150.857346,1192.800537 151.004486,1192.980469 
	C151.557022,1193.466797 151.922104,1193.808716 152.108047,1194.547729 
	C150.503632,1197.260864 148.135101,1196.667236 145.497711,1196.338745 
	C149.417938,1200.660645 146.959930,1207.040649 152.436111,1211.377808 
	C155.355179,1213.689697 156.583740,1217.722900 161.493378,1217.197266 
	C162.972961,1217.038696 163.651947,1218.406006 163.958923,1219.863403 
	C164.875290,1224.214111 166.448105,1228.390137 166.101517,1233.024536 
	C165.827271,1236.691406 167.136261,1239.758057 171.815430,1238.975830 
	C175.139679,1238.420288 176.629578,1240.730225 178.169373,1242.852417 
	C179.846344,1245.163818 178.127609,1246.970703 176.795517,1248.607666 
	C175.692444,1249.963135 174.745087,1251.299316 175.041458,1253.084473 
	C175.475327,1255.697876 176.632874,1259.299683 178.892258,1259.096680 
	C181.388535,1258.872559 179.085464,1255.830933 178.998367,1254.051636 
	C178.816345,1250.333496 181.488663,1247.198242 185.329987,1247.431274 
	C189.072952,1247.658325 192.675446,1249.171143 196.582153,1248.281128 
	C198.019379,1247.953735 199.065414,1249.333008 198.732956,1250.914795 
	C198.393494,1252.530029 197.285217,1253.123047 195.757553,1252.163208 
	C191.884384,1249.729492 192.117798,1252.563843 191.994125,1255.038330 
	C191.845169,1258.018921 192.425552,1261.038696 191.504028,1263.979248 
	C191.205368,1264.932129 190.816071,1266.029297 192.017761,1266.582520 
	C193.377258,1267.208252 194.318100,1266.299316 194.821640,1265.211548 
	C197.297394,1259.862305 199.619659,1259.534668 201.926071,1265.301270 
	C202.040497,1265.587280 202.331024,1265.914185 202.610687,1266.006592 
	C208.045822,1267.801514 209.354935,1272.254761 209.941360,1277.609863 
	C204.304916,1279.973267 198.419815,1280.178345 192.483185,1279.901001 
	C192.151001,1279.885498 191.832092,1279.746216 191.505142,1279.708496 
	C183.099518,1278.735107 183.099289,1278.737061 184.300964,1270.617676 
	C180.476532,1273.236938 178.007370,1273.053711 175.459808,1268.681763 
	C172.880661,1264.255493 168.903641,1263.724854 164.001083,1265.726685 
	C159.162842,1267.702026 154.159851,1266.915283 149.216705,1267.052124 
	C147.509216,1267.099365 145.853546,1266.104492 145.270248,1264.415283 
	C144.239578,1261.430542 142.965363,1258.579102 141.675110,1255.703979 
	C140.475540,1253.030884 142.548706,1250.293823 145.474609,1251.097534 
	C149.891342,1252.311035 152.748016,1250.851196 155.068817,1247.488770 
	C152.776627,1247.377808 150.418320,1246.816040 148.602310,1244.689331 
	C146.118759,1251.561401 139.810898,1252.465576 134.178619,1253.281006 
	C129.836395,1253.909912 129.660645,1255.207397 130.415192,1258.853516 
	C131.289474,1263.078247 133.967194,1265.957397 136.555481,1268.916016 
	C138.291626,1270.900513 140.862778,1269.987549 143.180557,1269.772583 
	C151.730164,1268.979370 159.927200,1270.959717 168.056595,1273.224609 
	C169.513794,1273.630615 170.844681,1274.715332 170.419586,1276.544312 
	C169.923294,1278.679810 168.083801,1279.320435 166.198776,1279.404663 
	C161.041595,1279.634888 155.875687,1279.421997 150.715118,1279.521118 
	C148.943466,1279.555176 147.005890,1278.958618 145.250488,1280.751465 
	C126.312439,1281.000000 107.624878,1281.000000 88.468658,1281.000000 
	C86.275757,1278.009888 88.371986,1276.591187 90.583817,1276.715820 
	C98.897797,1277.184814 106.826523,1273.409668 115.235672,1274.880737 
	C119.553841,1275.636108 123.543900,1273.417603 128.223038,1272.510742 
	C125.374611,1268.882080 121.476875,1268.986694 118.187332,1268.049438 
	C111.062477,1266.019531 111.065590,1266.180176 110.592880,1258.701172 
	C110.246803,1253.225586 108.333031,1247.995117 107.954262,1242.504272 
	C107.830521,1240.710571 107.012527,1238.969238 108.032028,1237.185669 
	C108.350502,1236.628418 108.792175,1235.712402 108.220108,1235.347900 
	C102.500702,1231.703857 106.908882,1228.793091 108.937332,1225.342041 
	C109.122047,1224.759277 109.217140,1224.534180 109.521683,1224.011230 
	C111.759361,1222.323486 115.700661,1222.650269 114.511780,1218.017578 
	C114.571671,1216.493774 114.113220,1215.355957 114.325783,1214.189697 
	C114.491631,1213.279419 115.052261,1212.613892 115.969368,1212.617065 
	C117.299728,1212.621582 117.461021,1213.671021 117.555145,1215.068604 
	C119.593742,1217.148071 121.019829,1219.469116 123.642014,1221.000244 
	C124.733467,1221.629883 125.288055,1222.377563 126.252090,1223.182129 
	C126.647629,1223.607666 126.794006,1223.789307 127.125946,1224.268433 
	C128.902771,1227.982910 128.652084,1232.101318 131.506531,1235.627808 
	C133.627609,1231.817993 136.523315,1231.377197 139.986130,1233.198975 
	C141.829681,1234.168701 143.478958,1233.739380 144.948563,1232.243530 
	C146.519791,1230.644531 148.353577,1229.643433 150.074142,1230.279663 
	C148.071213,1229.833374 145.559052,1228.847046 142.764954,1228.836182 
	C134.975906,1228.805908 132.757721,1226.739258 131.940460,1218.911377 
	C131.950012,1215.656006 133.776138,1214.661865 136.663086,1214.365967 
	C138.478226,1213.851440 140.305481,1214.235718 141.349731,1212.197021 
	C141.895111,1210.500000 142.023376,1209.052124 142.186356,1207.305298 
	C142.400391,1206.757324 142.519012,1206.491089 142.606308,1206.212036 
	C142.694763,1205.929077 142.633911,1206.007568 142.160080,1206.608521 
	C138.228592,1208.076416 142.353439,1210.219971 141.214386,1212.136353 
	C140.407120,1213.669067 139.293320,1213.978516 137.669281,1213.974121 
	C132.582809,1212.014160 131.039291,1212.845459 130.721344,1217.852905 
	C130.594452,1218.414185 130.504135,1218.629272 130.158524,1219.083008 
	C129.678040,1219.378540 129.452850,1219.435181 128.885773,1219.458130 
	C128.331955,1219.349487 128.120010,1219.274780 127.619766,1219.035645 
	C126.436829,1218.294312 125.957069,1217.280151 124.752304,1216.645996 
	C122.510223,1216.681396 120.560722,1217.821899 118.367249,1216.313477 
	C117.159180,1213.686646 117.079620,1210.650269 113.676163,1209.917236 
	C112.221237,1211.526978 114.156242,1212.972046 113.752213,1214.835083 
	C113.484108,1215.930786 113.095474,1216.563477 112.271759,1217.349854 
	C111.252213,1217.901367 110.511810,1217.973755 109.405197,1217.694214 
	C107.068199,1214.945190 105.769142,1211.888672 101.408089,1211.823486 
	C98.699715,1211.782959 98.653381,1208.473511 101.345802,1205.756104 
	C102.761047,1204.327759 105.264053,1203.444702 103.378555,1200.371582 
	C102.600090,1193.620728 104.785957,1188.708374 110.845230,1185.851685 
	C112.193916,1185.215820 113.553299,1184.592041 114.838486,1183.828491 
	C117.198952,1182.426147 116.949318,1180.664551 114.713257,1179.860352 
	C108.309837,1177.557617 104.255051,1173.234375 101.847343,1166.959106 
	C100.985397,1164.712646 99.374359,1165.179688 98.212914,1167.081543 
	C98.039871,1167.364990 97.824463,1167.638550 97.737045,1167.953125 
	C95.438034,1176.225220 88.612305,1178.704956 81.065605,1180.708984 
	C75.910622,1177.587036 74.486366,1173.737305 77.052307,1169.029419 
	C78.768684,1165.880127 79.229538,1162.937256 78.244705,1159.551636 
	C77.207962,1155.987427 77.085915,1152.373779 78.456955,1148.815674 
	C78.934502,1147.576416 79.276779,1146.303467 78.408127,1144.403198 
	C76.518684,1145.913208 77.073761,1147.704956 77.148354,1149.313110 
	C77.253387,1151.577515 77.079086,1153.775635 74.516464,1154.590942 
	C71.973923,1155.399780 71.101013,1153.236938 69.703369,1151.484619 
	C69.275162,1151.213745 69.096176,1151.128296 68.633240,1151.004272 
	C67.516991,1151.077515 67.064087,1151.738892 66.263885,1152.431641 
	C65.152641,1153.166016 64.165543,1153.390991 62.931824,1153.702393 
	C62.374817,1153.832886 62.112282,1153.971558 61.894318,1154.397339 
	C61.896973,1154.606689 62.118511,1155.007935 62.266037,1155.201660 
	C62.747913,1155.825806 63.207981,1156.042114 63.988815,1156.255859 
	C64.585953,1156.512573 64.892418,1156.713745 65.353523,1157.388184 
	C65.553123,1157.797485 65.753372,1158.114624 65.963837,1158.071045 
	C66.174301,1158.027344 66.645523,1157.897217 66.908951,1157.855713 
	C67.577301,1157.794067 67.978462,1157.829346 68.714828,1158.003906 
	C71.457451,1159.586548 70.436905,1161.317871 69.425697,1163.052979 
	C68.493095,1164.653076 66.824013,1165.406372 65.108185,1166.714355 
	C62.937939,1167.673462 61.023861,1167.429932 58.751190,1167.468262 
	C55.028122,1167.531372 51.785419,1166.518921 48.431107,1166.657227 
	C45.912048,1166.760986 43.506332,1166.665039 41.912060,1169.295776 
	C40.680729,1171.327759 38.584785,1171.417480 36.616650,1170.381836 
	C34.561813,1169.300415 31.918329,1168.014526 32.473423,1165.379883 
	C33.037228,1162.703979 32.014141,1160.982666 30.815748,1159.010498 
	C30.126230,1157.875854 29.859133,1156.532104 29.491983,1155.252930 
	C27.200970,1147.271240 29.513700,1143.953613 37.748230,1143.401245 
	C39.573280,1143.278564 41.415207,1143.433105 43.226841,1143.119263 
	C44.200657,1142.950684 45.299583,1142.822266 45.502171,1141.570312 
	C45.732994,1140.144043 44.837414,1139.396606 43.631950,1139.037109 
	C41.084877,1138.277222 38.571510,1137.650513 35.803711,1138.070923 
	C28.820734,1139.131836 26.441988,1138.290649 25.033541,1134.711060 
	C22.698334,1128.776123 24.474672,1121.079834 29.007040,1118.421021 
	C30.470627,1117.562378 31.128574,1116.429077 31.613747,1114.991943 
	C32.264454,1113.064575 33.433067,1111.785889 35.649189,1112.074341 
	C37.748383,1112.347656 38.766430,1113.807983 39.299000,1115.694702 
	C39.838600,1117.606323 39.864941,1119.660645 41.256310,1122.079102 
	C41.382633,1119.756592 41.260841,1118.050171 41.041229,1116.358765 
	C40.633270,1113.216553 41.206017,1110.703247 44.779713,1109.843750 
	C48.001148,1109.068848 47.773300,1105.853760 49.106918,1103.506592 
	C46.251915,1107.419922 42.741703,1107.974365 38.236946,1107.142212 
	C34.522705,1106.456299 32.920792,1109.910645 31.605286,1112.835815 
	C29.093712,1118.420654 22.786249,1122.329590 16.956251,1121.801514 
	C7.542025,1120.948853 3.112394,1126.617188 6.213927,1135.917603 
	C8.730494,1143.463867 8.018864,1150.639038 4.691639,1157.700439 
	C4.057284,1159.046753 3.540779,1160.558228 1.296845,1160.213623 
	C1.000000,1140.312378 1.000000,1120.624878 1.000000,1100.468628 
	C3.870953,1097.670898 5.923417,1099.719116 8.030120,1101.339111 
	C12.882722,1105.070679 13.698806,1105.046753 16.608204,1099.631470 
	C19.008293,1095.164185 23.204632,1096.994385 27.200253,1096.139648 
	C23.981640,1094.826294 23.802765,1092.368286 23.827595,1089.787964 
	C23.851101,1087.345215 24.100409,1084.993774 26.853157,1084.095825 
	C29.369478,1083.274780 31.538240,1084.026367 32.920391,1086.285034 
	C34.981174,1089.652832 37.010651,1088.980591 39.192528,1086.488892 
	C39.741165,1085.862427 40.315678,1085.025635 41.068085,1084.879150 
	C47.409687,1083.644531 45.563442,1079.881592 43.863586,1076.128296 
	C42.019997,1072.057739 42.406239,1067.712280 42.469276,1063.200195 
	C41.039120,1064.491577 39.589352,1065.759888 37.963512,1065.318481 
	C33.229469,1064.033936 29.228834,1065.634521 25.232185,1067.823608 
	C24.074057,1068.457886 22.656096,1068.524658 21.315830,1068.458618 
	C19.324205,1068.360352 17.067446,1068.463013 16.379139,1066.009155 
	C15.768919,1063.833496 17.407909,1062.743896 18.974724,1061.658081 
	C19.524809,1061.276855 22.860289,1060.580078 18.749481,1059.707031 
	C17.231585,1059.355835 17.539103,1057.415649 16.242601,1057.520386 
	C15.740689,1057.561035 15.978634,1058.636108 15.854511,1059.593262 
	C15.368071,1061.691772 14.542450,1063.292847 14.238441,1065.124512 
	C13.121362,1071.854980 8.681816,1075.051025 1.295855,1074.232666 
	C1.000000,1063.312378 1.000000,1052.624878 1.000000,1041.468628 
	C2.479787,1038.647827 6.758869,1039.864136 7.178379,1036.777100 
	C7.495686,1034.441895 5.685203,1032.130981 5.379623,1029.617065 
	C4.933434,1025.946289 2.715258,1022.893250 1.238629,1019.244690 
	C1.000000,979.979126 1.000000,940.958191 1.000000,901.468628 
	C3.434469,899.665710 3.778139,901.715088 4.329627,903.162781 
	C4.966394,904.834229 4.931225,906.692261 5.593032,908.592102 
	C4.814425,902.412415 6.463628,896.611145 6.802614,890.718079 
	C7.070543,886.060303 6.298481,885.139893 1.269818,884.223755 
	C1.000000,877.978821 1.000000,871.957703 1.000000,865.468262 
	C5.340847,865.493164 7.106070,868.395569 7.606090,873.625671 
	C10.103539,868.497742 8.479780,864.824402 7.386656,861.184692 
	C7.068192,860.124329 5.908374,859.266113 6.569308,857.995117 
	C10.112777,851.181091 8.688148,843.495605 8.411637,836.660278 
	C8.144852,830.065552 9.429067,822.843689 6.365716,816.319031 
	C5.775240,815.061340 6.030640,813.371948 6.026348,811.881409 
	C6.019551,809.521240 6.829505,808.661560 8.949352,810.438416 
	C9.652515,811.027832 10.434732,812.218506 11.425010,811.206665 
	C12.390002,810.220703 11.249866,809.578674 10.647370,808.792603 
	C9.605764,807.433716 5.919736,807.128418 7.889371,804.662048 
	C9.362533,802.817322 11.852863,804.515198 13.811446,805.470520 
	C17.180979,807.113953 20.599869,808.658020 24.021055,810.192444 
	C25.134628,810.691895 26.235207,811.344482 26.531685,812.473633 
	C27.774841,817.208374 29.416620,816.811218 31.514408,813.008362 
	C32.076805,811.988770 32.780399,810.605164 34.108047,811.479736 
	C35.531269,812.417480 34.552170,813.445923 33.567329,814.240601 
	C33.184525,814.549500 32.554695,814.969238 32.577503,815.301331 
	C32.925568,820.369873 32.061516,821.557861 26.949938,818.529907 
	C24.026093,816.797974 26.743637,821.623840 24.993490,822.466309 
	C22.810799,822.551147 23.112663,819.140930 20.051554,819.701172 
	C21.402700,824.831665 23.760912,829.402405 26.458876,833.808044 
	C27.094151,834.845398 28.387358,835.077759 29.613340,834.865784 
	C30.264030,834.753357 30.885031,834.370239 31.530020,834.340820 
	C34.352257,834.211731 36.775139,835.588623 38.412647,837.517822 
	C39.667053,838.995667 38.440533,839.811096 36.575729,839.346252 
	C35.344555,839.039307 34.189079,838.134521 32.640301,838.685059 
	C32.632339,840.251343 35.196484,841.888489 32.773540,843.374023 
	C31.050110,844.430786 29.099545,844.295410 27.583912,843.145996 
	C25.826975,841.813599 23.725096,841.291809 21.928997,840.198853 
	C19.318607,838.610535 16.527393,838.417053 15.811351,841.347534 
	C15.267825,843.571960 16.091833,846.897705 19.326246,847.945801 
	C20.413725,848.298218 21.802357,848.124390 21.699585,849.821777 
	C21.630402,850.964417 20.814632,851.689880 19.778622,851.814880 
	C17.358465,852.106873 17.004757,853.610107 16.964752,855.682129 
	C16.917322,858.138672 18.066191,859.380737 20.308357,859.896240 
	C21.231211,860.108398 22.533810,860.012878 22.415777,861.421387 
	C22.343407,862.284973 21.464205,862.753540 20.596170,862.889526 
	C19.141285,863.117371 18.046291,863.669678 18.013716,865.359985 
	C17.970032,867.626648 16.506376,868.005066 14.634874,868.018494 
	C13.195552,868.028870 11.228873,867.730042 11.220411,869.889038 
	C11.212088,872.012817 11.491096,875.054993 13.644176,875.451111 
	C19.285486,876.489075 19.393030,880.742004 19.939526,884.791565 
	C20.196527,886.695984 20.681671,887.586121 22.763800,888.094177 
	C29.041702,889.626221 32.231098,894.994324 30.954155,901.139221 
	C30.700851,902.358093 30.315561,903.445679 28.973232,903.705750 
	C27.346632,904.020813 26.310265,903.100403 26.156834,901.614197 
	C25.755001,897.721741 22.690338,897.376404 19.964577,897.190186 
	C16.798731,896.973816 17.415089,899.901428 16.737926,901.839417 
	C16.279158,903.152344 15.034080,904.199951 14.105012,905.337158 
	C12.007147,907.904846 12.631505,910.327026 14.123637,912.984802 
	C15.675383,915.748779 18.233852,915.355286 20.265213,914.810974 
	C24.366426,913.712219 25.451281,914.703796 27.761971,918.703430 
	C29.760073,922.161987 30.428442,928.382690 36.390144,929.170227 
	C37.566429,929.325623 37.883007,930.341309 37.957062,931.412048 
	C38.209476,935.061218 37.228607,938.386536 34.566456,940.861938 
	C31.226900,943.967407 30.242331,950.077942 32.923016,953.159424 
	C35.069759,955.627380 40.250854,955.142761 43.777931,952.088074 
	C44.524235,951.441772 45.051140,950.544250 45.788647,949.885071 
	C46.718681,949.053833 48.015163,948.603943 48.978832,949.434204 
	C50.368542,950.631592 48.895412,951.445984 48.156864,952.207336 
	C47.287880,953.103088 45.453381,953.836365 46.986103,955.296631 
	C49.723343,957.904602 48.893345,961.138672 48.983322,964.218201 
	C49.102074,968.282837 48.745399,972.008911 52.398712,975.497559 
	C56.292343,979.215576 55.664597,983.790588 52.375702,988.114258 
	C51.361893,989.447144 49.899368,990.814880 50.458786,992.501221 
	C51.480434,995.580994 49.648422,996.283997 47.442562,997.045471 
	C45.646446,997.665405 43.752293,998.693787 44.419937,1000.885925 
	C45.033268,1002.899719 47.203400,1002.918274 49.070465,1002.980469 
	C51.229576,1003.052368 52.120579,1001.932434 51.977337,999.904663 
	C51.790207,997.255554 52.682545,995.805481 55.625961,995.973267 
	C59.405090,996.188599 61.081635,994.208191 61.025059,990.482178 
	C60.994881,988.494263 60.914471,985.922974 63.874146,986.360413 
	C66.589462,986.761780 66.229179,989.404846 65.869461,991.255249 
	C64.999435,995.730530 61.764446,999.588074 62.634403,1004.599426 
	C62.871063,1005.962646 61.036118,1005.993408 59.887928,1005.978210 
	C56.184383,1005.929260 52.708324,1007.297913 49.108696,1007.699524 
	C44.326645,1008.233093 41.791107,1010.316895 41.882309,1015.317017 
	C41.905769,1016.603149 41.327652,1017.900269 41.012642,1019.596191 
	C39.952873,1021.178467 38.561028,1020.780701 37.266926,1020.809631 
	C35.911873,1020.839966 34.514881,1020.903015 33.766094,1022.228088 
	C33.370567,1022.928040 34.040836,1023.484619 34.622917,1023.775696 
	C36.095123,1024.511963 37.606491,1025.448486 39.216774,1024.082031 
	C40.105381,1023.328064 40.536842,1022.167480 41.736618,1021.222778 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M1041.768066,1.230004 
	C1051.354248,1.000000 1060.708496,1.000000 1070.531372,1.000000 
	C1077.772705,2.913581 1084.791504,3.820951 1091.464966,6.199375 
	C1093.889526,7.063496 1097.286865,6.603580 1097.583130,10.031615 
	C1097.918579,13.910486 1094.397583,13.996520 1091.744507,14.710010 
	C1091.113892,14.879613 1090.438354,14.964386 1089.807251,15.740151 
	C1090.782959,17.605785 1092.696411,17.513294 1094.387695,17.645670 
	C1098.098022,17.936062 1099.908447,19.592255 1098.790527,23.828115 
	C1097.972778,25.083469 1097.088867,25.595276 1095.604736,25.743874 
	C1089.789185,23.728024 1083.961548,22.644045 1079.383423,18.661943 
	C1078.281128,17.703184 1076.679321,16.833153 1075.269775,17.849680 
	C1073.858398,18.867500 1074.540771,20.598017 1074.667847,22.051523 
	C1074.831177,23.917995 1075.994995,24.684837 1077.745117,24.505608 
	C1083.501465,23.916050 1087.247925,28.004591 1091.670776,30.475258 
	C1093.913818,31.728390 1094.340088,33.781864 1093.241455,36.071476 
	C1092.233643,38.172150 1091.425293,40.393635 1090.089478,42.307426 
	C1088.667236,44.345119 1088.270508,46.374592 1088.730469,48.876026 
	C1089.714478,54.228924 1088.271362,56.427227 1083.096069,58.308449 
	C1081.843506,58.763725 1080.555542,59.118042 1079.271118,59.468494 
	C1076.579834,60.202812 1074.970337,61.368702 1076.915771,64.362579 
	C1078.570190,66.908791 1078.110352,69.467056 1076.625122,71.900124 
	C1074.352295,75.623413 1074.226074,79.443520 1075.986694,83.362228 
	C1077.322021,86.334183 1076.897949,88.908455 1074.279053,90.926422 
	C1071.907471,92.753822 1071.713623,94.917892 1072.729248,97.570168 
	C1074.515381,102.234337 1072.226929,106.418640 1070.677124,110.553436 
	C1069.829468,112.815117 1067.645630,113.357185 1065.933594,111.237869 
	C1063.136963,107.775780 1058.722778,105.729439 1057.232910,101.117088 
	C1056.637085,99.272400 1055.256592,98.915848 1053.731079,100.132019 
	C1050.753174,102.506004 1047.953003,102.167557 1045.149902,99.837952 
	C1044.345459,99.169441 1043.332397,99.009285 1042.314453,99.306602 
	C1041.356079,99.586525 1040.282471,99.915047 1040.171509,101.077148 
	C1040.074463,102.093369 1041.101318,102.348442 1041.818237,102.701515 
	C1043.010254,103.288551 1044.313110,103.669518 1045.336182,104.564056 
	C1047.479858,106.438744 1047.765503,108.839760 1047.104736,111.413658 
	C1046.503174,113.756866 1044.586182,114.336365 1042.489380,114.510048 
	C1040.156982,114.703217 1037.793579,114.772537 1035.528687,114.165520 
	C1026.718750,111.804382 1018.177063,111.139709 1010.129822,118.310905 
	C1016.536987,115.143005 1020.914734,117.519669 1025.209473,121.671165 
	C1028.957397,125.294151 1034.063843,126.950218 1039.040283,128.113892 
	C1042.562744,128.937576 1045.545898,129.675629 1046.190796,133.752350 
	C1046.831909,137.804092 1047.051880,141.995010 1043.721191,144.948242 
	C1040.677979,147.646622 1037.187256,146.585999 1033.968262,144.706436 
	C1033.394775,144.371552 1032.798218,144.065430 1031.603882,144.072662 
	C1030.812866,147.007965 1032.117310,149.757614 1032.701172,152.570648 
	C1033.494873,156.395615 1035.501465,160.273788 1032.333740,163.984009 
	C1032.009277,164.363983 1031.645996,164.968353 1031.976562,165.347031 
	C1035.396851,169.264587 1032.665405,172.293976 1030.782227,175.655457 
	C1029.137451,178.591507 1030.830566,181.861481 1030.644653,184.994980 
	C1030.333618,190.238510 1028.604370,192.662262 1023.698425,194.037323 
	C1019.147400,195.312927 1016.654175,194.203293 1014.427063,189.911102 
	C1012.136108,185.495941 1005.971313,183.225662 1001.341614,185.239838 
	C998.968018,186.272430 996.609558,186.783142 993.721436,186.124420 
	C991.044739,185.320541 990.493591,182.534317 987.960327,181.662247 
	C986.960999,181.754852 986.396118,182.010437 985.829102,182.410248 
	C981.137878,185.717682 980.200012,185.502182 978.096069,179.949158 
	C976.634705,176.092270 974.501587,174.043030 970.187012,175.514999 
	C967.939758,176.281677 965.962463,175.347748 963.973694,174.436539 
	C962.934753,173.960571 961.748535,173.358612 960.727112,174.341919 
	C959.855652,175.180801 960.333740,176.287186 960.780823,177.129593 
	C963.336182,181.944473 960.090820,186.161819 959.302551,190.578522 
	C958.858948,193.064178 956.220581,193.482117 953.966064,193.015732 
	C950.089478,192.213776 947.418823,193.672058 944.767822,196.476562 
	C940.435059,201.060074 938.779663,201.652847 932.459961,203.017441 
	C935.577881,204.188751 936.475037,204.153229 938.809875,202.102829 
	C940.802185,200.353348 942.520630,197.836517 945.552979,200.129135 
	C947.871338,201.881943 948.172913,207.524490 946.126343,210.342468 
	C944.092102,213.143631 941.182617,214.146759 938.277222,212.254807 
	C934.813538,209.999329 931.391113,209.095062 927.444946,210.318222 
	C925.549438,210.905731 923.357300,211.251755 923.106445,213.767609 
	C922.873474,216.103180 924.783325,216.787552 926.467285,217.521591 
	C929.717957,218.938522 931.826355,220.929565 930.380310,224.851212 
	C928.782227,229.185120 926.549561,230.538483 922.016663,229.371185 
	C917.850464,228.298309 916.661804,230.956329 915.833435,233.969925 
	C915.181335,236.342178 915.355774,239.013107 912.556335,240.713730 
	C911.794312,241.077255 911.334778,241.218964 910.507568,241.360931 
	C905.672241,241.682373 901.237061,241.992462 897.200073,239.240280 
	C894.809021,237.610138 893.529968,239.492813 892.624023,241.363388 
	C891.100159,244.509857 888.783813,245.985672 885.384094,244.761230 
	C882.050598,243.560638 882.130066,246.623657 880.657837,248.323303 
	C878.075012,249.986053 878.402527,251.950424 878.992310,254.198578 
	C881.254639,262.822418 881.212524,262.854828 873.352966,266.375458 
	C872.062500,266.953522 870.852356,267.529877 871.109924,269.173401 
	C871.559326,272.041168 869.876465,274.218384 868.656677,276.900940 
	C862.747986,276.716766 857.069641,277.322510 851.392578,277.230164 
	C849.321289,277.196472 847.804443,275.847351 848.185852,273.624817 
	C849.125305,268.151276 850.604248,263.271576 857.465454,263.414307 
	C858.549377,263.436859 859.753174,263.744568 860.843994,262.882935 
	C859.206299,261.682922 857.239075,261.189941 855.719177,259.903076 
	C852.543884,257.214447 852.030762,254.017685 854.418640,251.238525 
	C856.717590,248.562881 860.458435,248.284637 863.380615,250.573853 
	C863.908936,250.987808 864.341003,251.493851 864.727722,252.037781 
	C865.493042,253.114624 866.138489,254.458160 867.792053,254.100037 
	C869.365173,253.759338 869.097656,252.372177 869.118347,251.255661 
	C869.145874,249.769455 869.320862,248.155762 867.294250,247.678635 
	C865.662537,247.294464 864.105896,246.747879 862.810913,245.607697 
	C862.321655,245.176956 861.423767,244.571152 861.102966,245.257370 
	C858.951355,249.859772 852.724487,249.222473 850.459412,253.795105 
	C849.149048,256.440277 846.640259,257.908478 843.153198,257.826660 
	C842.347717,257.542969 841.958008,257.257996 841.448608,256.574402 
	C841.423889,252.261292 841.114929,248.540527 838.716370,244.935394 
	C837.014282,242.377029 835.513733,240.537445 832.280701,240.335342 
	C830.166931,240.203217 828.383057,239.091949 828.253174,236.662766 
	C828.136658,234.485229 829.568054,233.241165 831.509399,232.856567 
	C833.456055,232.470947 833.472534,231.666260 833.375671,229.819778 
	C833.214172,226.739731 834.013489,223.660126 834.000183,220.488388 
	C833.982300,216.229462 837.213440,214.148560 841.136963,215.877533 
	C844.638306,217.420456 846.056091,216.315826 846.770447,213.026093 
	C846.946777,212.214111 847.267456,210.954849 847.845764,210.862289 
	C854.964050,209.723022 854.796875,202.012695 859.276306,198.278702 
	C864.600830,193.840179 864.147949,186.653854 864.325562,180.205856 
	C864.376892,178.337921 862.439880,177.677444 861.039551,176.775162 
	C857.434204,174.452209 856.642090,170.931473 858.839905,167.431046 
	C860.690857,164.483170 866.359680,162.679504 869.017517,164.170044 
	C869.788086,164.602219 870.578308,165.207596 870.417786,166.052338 
	C868.897095,174.054352 875.875366,174.538010 879.759094,177.539383 
	C881.309631,176.857040 880.914673,175.962402 880.954529,175.189163 
	C881.197754,170.467422 882.766357,168.991821 887.474915,169.202728 
	C889.463562,169.291779 891.467224,169.373734 893.761108,169.981934 
	C897.701599,172.470200 901.167297,175.019958 903.529785,178.710892 
	C905.318604,181.505585 908.906555,181.136978 911.293518,183.509369 
	C911.722778,184.055511 911.913391,184.339172 911.998413,184.656967 
	C913.813110,191.447632 917.141418,193.310059 923.667358,190.226929 
	C927.570129,188.383057 930.552307,188.460983 933.590393,191.405075 
	C934.269409,192.063156 935.139160,192.787643 936.213318,192.087814 
	C936.668335,191.791336 936.680664,191.224045 936.511414,190.728516 
	C936.140869,189.643402 935.273621,189.237091 934.211121,189.154785 
	C933.548584,189.103455 932.880493,189.137451 932.218384,189.079422 
	C929.987183,188.883911 928.276794,187.948624 928.273254,185.477768 
	C928.269714,183.039734 930.057617,182.076065 932.160706,181.653198 
	C934.455933,181.191727 936.811646,181.530014 939.119995,181.233826 
	C940.246765,181.089264 941.736450,181.046860 941.777588,179.552475 
	C941.824219,177.856369 940.248230,177.881714 939.188477,178.126129 
	C933.742310,179.382233 929.313599,177.445175 924.659241,174.719849 
	C921.066101,172.615921 916.150146,173.117477 912.727295,169.413025 
	C911.784241,167.206512 912.372314,165.560928 913.763245,163.759796 
	C916.246643,162.331177 918.533508,161.592484 920.900391,162.989975 
	C923.261353,164.383987 923.522400,162.233566 924.631287,160.793640 
	C925.505188,160.111328 926.205017,159.918167 927.319702,159.867462 
	C929.767029,160.164795 931.107788,161.688583 932.946289,163.047058 
	C934.131897,163.930923 934.764526,165.501389 936.523926,163.984955 
	C940.293701,158.478867 942.440918,158.606400 945.413269,164.273972 
	C946.923889,167.154373 946.923889,167.154373 947.138794,171.344635 
	C946.798340,167.270813 948.235229,162.998535 944.640869,159.363342 
	C942.755798,157.456818 944.143494,154.691895 944.604187,152.224442 
	C945.252197,148.754105 947.574402,145.581757 946.146179,141.688095 
	C944.200745,136.384720 941.187256,133.039474 935.030029,132.864243 
	C931.083679,132.751938 927.137756,131.786819 923.205627,131.121613 
	C920.130188,130.601318 917.422485,129.467773 916.170227,125.825378 
	C915.612427,124.589661 915.335022,123.635658 914.854736,122.344879 
	C914.182129,120.171822 913.842041,118.385040 915.536377,116.515610 
	C919.024414,114.512329 921.839294,114.023125 924.604431,117.429794 
	C926.950928,120.320770 930.645142,119.278152 931.013184,115.604630 
	C931.406677,111.677467 933.103455,107.855568 932.145935,103.793221 
	C931.831543,102.459480 932.032532,101.100548 932.704041,99.915527 
	C933.903809,97.798302 934.799744,94.834503 937.935181,95.484657 
	C940.901978,96.099838 940.463135,99.015800 940.535889,101.276237 
	C940.620728,103.912865 940.010498,106.737877 943.235474,108.114540 
	C944.235901,108.541557 945.219238,108.693298 945.889832,107.670471 
	C947.729126,104.865105 945.644043,102.846176 944.662231,100.396416 
	C942.281128,94.454803 942.862549,88.285072 944.965942,82.374733 
	C945.910889,79.719376 947.869141,80.111320 949.585205,82.023026 
	C950.554382,83.102768 950.494080,84.930626 952.890259,85.856812 
	C952.848694,80.184090 950.438232,74.482101 954.259705,69.198387 
	C957.032410,66.942780 959.404846,68.093506 961.757507,69.352104 
	C963.312256,70.183830 964.548645,71.693359 966.914307,71.567978 
	C969.793884,71.419678 972.280884,71.104683 975.123535,70.579437 
	C978.822815,69.637230 982.016113,70.041107 985.418762,71.633415 
	C986.922485,72.506104 988.190125,73.705788 989.000488,70.962555 
	C989.715393,65.593704 988.161987,60.226799 991.577637,55.266075 
	C993.701111,52.346539 993.526550,45.533619 991.281372,42.659733 
	C990.178833,41.248516 989.088135,39.809647 987.798462,38.277233 
	C987.120361,42.680073 988.090942,47.972103 981.163818,48.532467 
	C974.878967,49.390022 972.663696,47.007557 974.091675,41.350456 
	C974.801514,38.538380 976.343079,36.185493 978.345215,33.670868 
	C979.206482,31.295853 980.997314,29.035311 977.117676,27.636457 
	C976.095032,26.987560 975.282959,26.410664 975.196594,25.621168 
	C974.496643,19.225540 969.569641,18.706554 964.752991,17.887245 
	C961.049194,17.257225 957.162842,17.254972 953.720093,14.683017 
	C955.955750,9.623268 960.629822,10.289354 964.315979,10.751781 
	C970.212708,11.491517 975.709595,12.527239 981.784851,10.622521 
	C985.177551,9.558853 989.523438,11.404103 993.392822,12.233941 
	C999.350098,13.511546 1000.710938,12.521541 1001.565918,6.115918 
	C1005.232483,3.485303 1009.410156,4.100554 1013.190063,3.043303 
	C1014.932495,2.555952 1016.771790,2.400174 1018.696045,1.250393 
	C1021.700012,1.000000 1024.400024,1.000000 1027.550049,1.000000 
	C1028.748291,1.836845 1029.589233,2.108252 1030.793335,1.211840 
	C1032.066650,1.000000 1033.133423,1.000000 1034.600098,1.000000 
	C1037.080322,2.550591 1039.222168,3.228055 1041.768066,1.230004 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M88.769592,251.771500 
	C90.187531,253.132355 91.561035,254.132660 92.508080,255.441528 
	C93.320511,256.564331 93.181870,258.017761 92.106842,259.099976 
	C91.031929,260.182037 89.730019,259.650146 88.965439,258.894379 
	C86.267303,256.227295 83.383408,255.593903 81.314438,259.047699 
	C79.426620,262.199066 78.066376,265.906006 80.589165,269.566650 
	C81.139053,270.364563 82.116867,270.911285 81.401367,272.512085 
	C78.814568,271.562469 76.206749,271.197479 73.211143,271.743713 
	C68.276329,272.643585 70.158821,267.311249 68.529305,265.060638 
	C65.657486,261.094238 64.042732,256.228271 61.248474,252.191010 
	C59.667507,249.906784 57.244656,251.172134 57.207115,253.702621 
	C57.146301,257.801239 57.822712,261.915985 58.298180,266.011108 
	C58.360989,266.552124 59.069008,267.451996 59.482868,267.454651 
	C68.419258,267.511688 61.120358,274.551147 63.704163,277.383057 
	C64.130142,277.849915 63.126007,278.802277 62.174618,278.870117 
	C60.901958,278.960938 59.744801,278.588959 59.456718,277.255707 
	C59.185902,276.002380 59.925549,274.688782 59.297955,273.359070 
	C58.695641,272.082886 59.290520,269.878510 57.435001,269.655060 
	C55.380131,269.407593 56.355511,271.613312 55.804535,272.640564 
	C55.031380,274.082092 53.939617,274.235779 53.266151,272.800659 
	C52.308491,270.759979 51.037857,268.568298 53.309090,266.417877 
	C54.251003,265.526062 55.069172,264.466919 55.782036,263.378082 
	C56.977455,261.552216 56.249012,260.129242 54.425259,259.402466 
	C52.461296,258.619812 50.737911,259.056824 50.171139,261.372955 
	C49.701756,263.291046 49.458561,265.264526 49.057346,267.606750 
	C48.194099,268.726013 46.774418,269.337708 48.763371,270.769775 
	C48.060207,276.866211 44.285378,272.652222 41.400711,273.012482 
	C39.543915,272.893494 38.500561,273.635864 37.296501,274.829498 
	C35.213562,274.791077 33.409168,274.681183 31.647793,274.345520 
	C29.861504,274.005096 28.292398,273.780396 28.017900,276.616821 
	C25.629129,278.120605 23.816088,276.236603 24.637489,274.862427 
	C26.662828,271.474060 26.916214,266.536621 32.146065,265.294464 
	C33.734474,265.734558 35.085022,266.976196 36.174217,264.585083 
	C37.320015,262.934143 38.253025,261.515961 40.516937,261.459808 
	C41.334755,261.680603 41.566296,262.205536 42.073723,262.297485 
	C41.902897,261.871277 41.627121,261.512238 41.133316,260.868835 
	C38.860878,257.697296 35.780548,259.088898 33.122292,258.733490 
	C28.820400,258.158264 24.122643,260.126587 20.132933,256.360657 
	C20.645020,253.224960 22.881268,253.008682 25.076967,253.041031 
	C27.732376,253.080154 29.911777,252.405045 31.770102,249.481216 
	C27.189697,249.540573 23.828518,246.896103 19.878389,246.011078 
	C15.823836,245.102707 14.059367,243.208221 15.820443,238.917953 
	C16.626873,236.953354 16.656397,234.682037 17.338345,232.650543 
	C17.956097,230.810272 17.775368,229.599335 16.107918,228.381927 
	C13.979481,226.827972 12.763897,224.373627 15.366031,222.634705 
	C17.739180,221.048828 20.979525,218.628632 23.721178,221.800842 
	C25.590031,223.963196 26.693720,223.949554 28.437428,222.082825 
	C30.278261,220.112122 31.681770,219.064362 33.612579,222.495773 
	C34.807575,224.619492 37.344574,226.101334 38.854061,229.301865 
	C40.181904,225.952789 41.221443,223.337982 42.255318,220.720917 
	C43.002468,218.829666 43.817677,216.712799 45.942097,216.296494 
	C49.136124,215.670593 52.451660,215.756241 48.591244,211.326294 
	C47.730011,210.338028 49.334133,209.084808 50.518097,209.165222 
	C53.937195,209.397461 55.970913,208.273697 57.014763,204.754440 
	C57.629463,202.682037 61.247757,202.705460 61.624561,199.137283 
	C58.201168,201.905594 54.596066,200.241837 51.173477,200.503998 
	C45.912636,200.906952 49.142197,194.816879 46.231426,192.996704 
	C48.048618,191.501617 48.956329,193.041092 50.131958,193.340195 
	C52.730396,194.001312 54.703217,194.638931 57.390511,191.770248 
	C60.586876,188.358139 63.510582,186.106781 60.435432,181.280884 
	C59.985271,180.574432 60.803349,179.721848 61.629593,179.371094 
	C62.481438,179.009491 63.114235,179.426117 63.869236,179.912277 
	C67.559196,182.288315 71.886375,180.080597 71.688637,175.742737 
	C71.609192,174.000031 72.579468,173.784286 73.482002,173.280151 
	C77.409515,171.086243 79.873619,167.857651 79.928238,163.318207 
	C79.967323,160.069656 81.532959,160.447861 83.757904,160.929184 
	C87.577095,161.755417 90.992180,165.404022 96.053238,161.611694 
	C94.877708,165.185638 93.397041,167.062714 91.697357,168.827911 
	C90.785240,169.775162 89.587479,170.795990 90.997871,172.179520 
	C92.087196,173.248077 93.681465,173.360855 94.662727,172.432846 
	C96.658867,170.545029 99.306053,169.012482 99.915634,166.069489 
	C101.204712,159.845993 104.840034,156.224258 111.041084,154.728851 
	C114.254730,153.953873 116.662849,153.568970 117.184189,157.779373 
	C117.313866,158.826645 118.033875,159.605011 119.168495,159.718353 
	C120.489273,159.850311 120.768616,158.860779 121.043465,157.857483 
	C121.707817,155.432327 123.696587,155.132721 125.755898,155.017044 
	C127.401413,154.924606 129.244431,155.322662 130.462082,153.903122 
	C132.343613,151.709671 136.465714,150.216492 135.525192,147.105682 
	C134.370117,143.285187 129.646088,142.831009 126.319893,142.285339 
	C121.083397,141.426239 115.970314,139.298584 110.277428,140.830460 
	C106.315887,141.896469 102.599930,139.202286 99.095169,137.354752 
	C97.117416,136.312164 96.624779,134.277481 97.228294,132.131516 
	C97.807800,130.070969 99.611694,129.729279 101.249115,130.060226 
	C106.572449,131.136108 111.456581,127.135147 116.843811,128.897781 
	C118.175819,129.333603 119.298088,128.153580 120.283134,127.213402 
	C124.047264,123.620758 128.651382,122.141418 133.737976,121.573990 
	C136.763321,121.236496 137.865112,118.516754 137.839844,116.077797 
	C137.793671,111.624504 138.626465,108.853966 143.622696,107.229012 
	C147.917282,105.832245 149.748398,93.996574 146.964401,89.667526 
	C145.952255,88.093651 145.028580,86.695297 144.883194,84.748329 
	C144.705612,82.370537 142.918060,81.796883 140.894470,82.118416 
	C138.547623,82.491325 139.273193,84.105988 139.687592,85.574387 
	C140.356720,87.945244 140.076248,90.186424 137.289780,90.623550 
	C134.277420,91.096115 134.623428,88.165001 134.179276,86.480927 
	C133.550949,84.098488 132.805374,81.461586 133.249451,79.152611 
	C133.828690,76.140877 133.643738,71.623985 139.211304,72.820045 
	C142.088089,73.438049 143.562744,71.358940 144.368622,69.091064 
	C145.907333,64.760834 148.313843,62.176792 153.297623,63.574970 
	C155.443542,64.176994 155.950745,62.913219 155.977310,61.166595 
	C156.005203,59.334148 155.979248,57.500881 156.007324,55.668438 
	C156.030121,54.181465 156.207764,51.474838 157.269897,51.782482 
	C163.033142,53.451794 162.053436,43.374153 168.108505,45.887150 
	C169.454758,46.445881 170.948685,46.434795 171.967896,47.780121 
	C172.999237,49.141502 174.597885,50.580887 176.152725,49.390671 
	C177.828842,48.107624 177.369766,45.544941 176.021194,44.461243 
	C171.117828,40.520927 171.282104,34.843399 170.379044,29.542124 
	C169.862305,26.508621 170.412170,23.436050 169.570633,20.288294 
	C168.886688,17.730011 172.236282,17.909637 173.906281,17.217850 
	C179.511932,14.895766 180.484726,11.061199 176.438232,6.919501 
	C174.917694,5.363189 173.707733,4.889021 171.838181,6.571817 
	C168.520782,9.557844 164.984116,9.505766 160.780334,7.759605 
	C157.532867,6.410678 153.671661,6.445361 150.060226,6.126223 
	C149.031433,6.035312 147.837265,6.716500 146.869690,7.295542 
	C145.491440,8.120356 145.272736,12.074249 143.377502,10.233473 
	C140.347717,7.290754 133.998337,7.917330 134.001312,1.385456 
	C183.687561,1.000000 233.375122,1.000000 283.531342,1.000000 
	C284.127991,1.106526 284.243195,1.283600 284.385834,1.309550 
	C296.867340,3.579895 309.214264,7.208278 322.146820,4.675618 
	C325.250916,4.067725 328.486389,3.993082 331.498871,5.296774 
	C335.658752,7.097027 336.269836,11.164959 332.891174,14.011652 
	C329.663177,16.731405 310.002655,18.878679 306.240662,16.849615 
	C304.614807,15.972708 303.135468,14.951313 301.121185,15.678890 
	C294.706360,17.995974 288.015594,17.715631 281.353546,17.676691 
	C279.356293,17.665016 277.306366,17.476007 275.392822,18.077555 
	C272.715546,18.919188 270.828094,19.954102 273.268433,23.334555 
	C276.296417,27.528969 274.932861,33.361778 270.532593,33.841393 
	C262.639160,34.701752 262.133759,41.212303 259.634003,46.207447 
	C258.622223,48.229267 258.318939,50.664101 257.789185,52.932495 
	C256.868195,56.876373 254.308899,57.714767 250.636520,57.235088 
	C248.980469,57.018772 246.284271,54.642548 245.729462,57.493916 
	C245.287384,59.765972 246.598877,62.780785 249.579910,63.846333 
	C251.461731,64.518959 253.492188,64.646805 255.389771,65.295815 
	C260.892487,67.177872 263.342133,71.192818 261.529633,75.381439 
	C259.069824,81.066002 253.784439,83.227287 248.654648,80.555283 
	C246.855835,79.618324 245.018402,78.488792 243.012405,78.869095 
	C236.939301,80.020454 231.819138,78.895065 228.436707,72.765015 
	C227.801147,74.413025 228.644638,74.573120 229.094727,74.909866 
	C233.396896,78.128555 234.870117,83.400314 232.449661,87.015984 
	C230.190567,90.390602 223.922668,91.529648 219.698288,89.333252 
	C215.878067,87.346992 215.356262,87.620392 214.858994,91.879066 
	C214.624710,93.885536 214.894608,95.861763 215.174454,97.845673 
	C215.615891,100.975159 216.575546,104.164330 216.030762,107.259674 
	C215.222382,111.852776 214.956558,115.805756 218.739227,119.588081 
	C221.182861,122.031487 219.996109,124.665955 216.430984,125.329620 
	C212.715942,126.021179 210.747162,127.174973 211.534027,131.489334 
	C211.972351,133.892792 209.381454,134.488373 207.532516,135.228882 
	C206.345642,135.704239 204.798584,135.830353 204.931763,137.701782 
	C205.047974,139.334473 206.192627,139.908890 207.562180,140.268799 
	C209.172760,140.692078 211.028519,141.771881 212.392838,141.218582 
	C217.526596,139.136536 222.459473,141.496216 227.467224,141.368271 
	C229.467529,141.317154 230.814560,141.973602 230.970978,143.993622 
	C231.231995,147.364578 232.493607,150.454666 233.314148,153.671326 
	C234.300385,157.537491 232.438400,159.498611 228.482269,158.715408 
	C225.404373,158.106079 222.735428,156.547485 219.941162,154.986862 
	C212.840302,151.020950 206.152130,145.805725 197.309586,145.812317 
	C190.386780,145.817459 187.977356,147.825851 187.680725,154.805893 
	C187.603058,156.633072 187.694153,158.477402 186.868866,160.562119 
	C185.160034,162.824509 182.760590,163.272980 180.704468,164.302017 
	C178.234787,165.538040 176.968277,166.979218 178.927200,169.710602 
	C180.596863,172.038681 179.871155,174.099823 177.782700,175.807709 
	C176.322388,177.001938 175.498962,178.586243 174.727386,180.279205 
	C172.873657,184.346481 171.901672,184.597122 167.868423,182.393021 
	C161.776382,179.063782 161.776382,179.063782 154.286163,181.088745 
	C158.559784,181.680145 161.231033,183.746674 164.636063,184.342819 
	C168.511658,185.021378 166.924667,188.934204 167.885681,191.734604 
	C167.878860,194.333099 166.843124,195.970825 164.649689,196.519241 
	C162.363510,197.090836 160.882965,195.733612 159.754654,193.922714 
	C158.775970,192.351990 157.833374,190.541779 155.570709,191.152969 
	C153.207779,191.791260 153.643692,194.048309 153.524216,195.749069 
	C153.262009,199.481323 150.693787,201.759125 148.273590,203.910553 
	C145.848572,206.066269 143.934769,204.357300 143.062363,199.189331 
	C138.977325,201.722672 132.895905,195.401016 130.141769,202.470825 
	C132.292221,202.530075 135.358322,200.543594 136.676132,204.238831 
	C137.526352,206.622864 134.632339,209.546875 130.722534,210.360672 
	C128.604141,210.801575 126.517014,211.334106 124.071251,211.931854 
	C115.976555,211.852356 114.893524,212.864960 114.673798,220.486511 
	C114.617355,222.444412 114.677330,224.411850 113.794899,226.610138 
	C110.058640,228.612350 105.783394,228.474701 102.336754,231.136139 
	C98.707634,232.691422 95.227249,233.369492 91.534386,233.008148 
	C85.498909,232.417587 81.898979,237.473923 76.939117,239.389526 
	C76.683273,239.488327 76.419403,240.413635 76.583588,240.625854 
	C79.127327,243.913864 75.423378,247.651337 77.369545,251.070938 
	C77.783852,251.798904 76.740028,253.432007 78.453957,253.730331 
	C79.754456,253.956696 80.557091,252.965408 81.259460,252.024017 
	C82.233131,250.718979 82.005646,249.177124 81.983398,247.708420 
	C81.951904,245.629242 82.098610,243.467209 84.268166,242.658951 
	C86.285973,241.907227 88.083130,242.970963 89.731407,244.791092 
	C90.643410,245.671661 90.754028,246.451492 90.236664,247.655426 
	C89.833946,247.999588 89.666969,247.999802 89.249542,248.000305 
	C87.563019,249.015854 86.459450,250.074158 88.769592,251.771500 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1280.694946,1110.785522 
	C1281.000000,1123.020874 1281.000000,1135.041870 1281.000000,1147.531372 
	C1278.883545,1153.144775 1280.590088,1158.554688 1279.809570,1164.193848 
	C1277.594360,1167.051880 1274.486938,1167.986694 1271.665527,1168.826050 
	C1267.760498,1169.987793 1265.613525,1172.235229 1264.311646,1176.233521 
	C1258.343750,1178.163818 1253.926025,1174.537109 1249.313354,1172.512329 
	C1246.826416,1171.420898 1247.777710,1164.977295 1250.671143,1160.310547 
	C1256.100952,1156.834106 1256.104858,1156.693970 1250.813232,1153.574097 
	C1248.094116,1150.406494 1247.893066,1146.614014 1246.745850,1143.176514 
	C1245.528320,1139.528687 1236.682495,1136.886841 1233.632812,1139.245361 
	C1232.015015,1140.496582 1232.354126,1141.692871 1233.429077,1143.053345 
	C1235.815674,1146.073608 1236.015991,1149.414795 1234.568115,1153.263184 
	C1232.262085,1155.630615 1229.972290,1155.252808 1227.300049,1153.901855 
	C1225.570557,1152.345825 1225.290649,1150.473999 1224.727905,1148.760620 
	C1223.588623,1145.292603 1222.681396,1142.113159 1218.453247,1140.556152 
	C1215.328979,1139.405640 1214.546387,1135.712036 1215.225220,1132.384644 
	C1215.878662,1129.182373 1218.563843,1128.679077 1221.358765,1128.378174 
	C1223.511353,1128.146362 1226.964355,1128.695679 1226.526245,1125.358032 
	C1226.077026,1121.936279 1222.766235,1123.013916 1220.373657,1123.296509 
	C1218.390991,1123.530518 1216.446899,1123.979736 1214.688965,1125.009521 
	C1211.475464,1126.892090 1208.024170,1126.706787 1204.515991,1126.227173 
	C1202.658936,1125.973389 1200.517212,1124.850586 1199.455933,1127.652344 
	C1198.531738,1130.092407 1198.885986,1132.134399 1200.879395,1133.943970 
	C1205.483887,1138.123901 1209.440796,1142.891602 1215.990234,1144.698242 
	C1221.164185,1146.125610 1221.497070,1148.179443 1218.869629,1153.120361 
	C1218.080933,1153.927734 1217.443726,1154.316040 1216.420410,1154.769531 
	C1213.157471,1155.367188 1210.203857,1155.108887 1207.245117,1155.581909 
	C1203.130371,1156.239502 1200.932739,1153.500244 1199.784180,1149.861572 
	C1199.105347,1147.710815 1199.039551,1145.322144 1197.314575,1143.558228 
	C1192.394775,1149.242065 1191.085205,1149.359741 1180.985840,1144.841431 
	C1178.839233,1142.147949 1176.051392,1140.507446 1174.327393,1137.442871 
	C1173.901489,1136.325073 1173.511353,1135.277832 1173.963745,1134.708984 
	C1176.922729,1130.987915 1177.042603,1127.499146 1174.412842,1123.359863 
	C1173.268921,1121.559204 1175.248413,1119.916870 1177.453369,1120.132568 
	C1179.730713,1120.355225 1182.142700,1120.434814 1184.056763,1122.004517 
	C1185.971436,1123.574707 1188.325195,1124.320557 1189.568115,1121.897339 
	C1190.994385,1119.116333 1187.874390,1119.095459 1186.300659,1118.277588 
	C1186.008423,1118.125854 1185.685181,1118.031006 1185.082275,1117.711548 
	C1181.783691,1115.054077 1183.255005,1110.475586 1180.003662,1107.210571 
	C1179.671143,1111.356567 1185.262329,1115.829102 1178.147461,1118.155518 
	C1172.629395,1116.765015 1170.432129,1114.640503 1170.594604,1109.608643 
	C1170.770020,1104.182617 1169.180542,1100.576660 1163.584717,1099.322144 
	C1162.072144,1098.983032 1161.044556,1097.809692 1160.076538,1096.643311 
	C1159.230835,1095.624268 1158.620728,1094.189209 1157.388916,1093.753296 
	C1152.167725,1091.904907 1150.603149,1088.598145 1152.861572,1082.931030 
	C1152.227173,1088.745605 1148.609497,1090.630371 1143.681763,1091.565918 
	C1143.152710,1091.849854 1142.932495,1091.947266 1142.367188,1092.149170 
	C1137.538452,1093.091675 1134.197998,1091.671021 1132.288696,1087.365234 
	C1131.877197,1086.437500 1131.265869,1085.610962 1130.262817,1085.284912 
	C1129.126221,1084.915405 1127.856567,1084.696533 1127.234985,1086.050537 
	C1126.672241,1087.276367 1127.743774,1087.703125 1128.600220,1088.256348 
	C1134.126221,1091.826538 1134.205200,1100.955566 1128.812134,1104.983154 
	C1126.550293,1106.672241 1124.357178,1108.445435 1121.197754,1109.293701 
	C1119.557251,1109.659424 1118.271118,1109.570068 1116.590576,1109.469971 
	C1114.302612,1109.536987 1112.519165,1109.032715 1110.383423,1108.290161 
	C1108.811523,1107.614990 1107.748535,1106.882446 1106.624268,1105.622803 
	C1105.384033,1100.417358 1103.042847,1095.826904 1102.639160,1090.479980 
	C1101.798096,1089.799805 1101.201050,1088.988892 1100.369751,1089.668335 
	C1099.839600,1090.101562 1100.127686,1090.850220 1100.271484,1091.824585 
	C1100.717407,1095.208984 1100.020508,1097.879883 1097.396240,1100.226196 
	C1095.235962,1100.914795 1094.298462,1099.840698 1093.518188,1098.438843 
	C1090.863281,1093.669067 1092.335693,1085.578247 1096.524658,1081.986450 
	C1100.268799,1078.776123 1101.133301,1075.631226 1099.517944,1071.091797 
	C1099.190674,1070.171997 1099.036621,1069.053101 1097.728027,1069.044800 
	C1096.627197,1069.037842 1095.891724,1069.701172 1095.374146,1070.623047 
	C1095.044189,1071.210571 1094.772461,1071.876099 1094.777222,1072.510864 
	C1094.832275,1079.883545 1090.511475,1086.315186 1090.367554,1093.695679 
	C1090.279663,1098.207153 1087.812744,1098.981689 1081.540894,1096.788940 
	C1076.515137,1096.951294 1073.274658,1095.474609 1072.153809,1090.341919 
	C1071.534546,1087.505737 1068.761719,1085.967163 1066.631836,1084.137451 
	C1065.231567,1082.934326 1063.636108,1081.650269 1064.560181,1079.560425 
	C1065.470581,1077.501709 1067.528687,1077.456787 1069.294922,1078.014648 
	C1072.706177,1079.092041 1072.283691,1076.853760 1072.417236,1074.540161 
	C1072.689941,1074.025024 1072.844482,1073.845459 1073.324219,1073.522949 
	C1075.404297,1073.067871 1076.832153,1074.172974 1078.442261,1074.484131 
	C1081.011230,1074.980713 1082.895386,1073.760010 1083.415649,1071.460327 
	C1084.140015,1068.258667 1081.339478,1068.869995 1079.180908,1068.530762 
	C1076.436768,1068.356689 1074.112915,1067.984375 1073.201416,1065.316406 
	C1072.104004,1062.104614 1074.978271,1061.120239 1076.541260,1059.266846 
	C1076.086060,1059.039429 1075.767700,1059.153320 1075.218262,1059.543213 
	C1073.189087,1062.953125 1071.010498,1065.489502 1066.841797,1065.246094 
	C1064.612427,1065.115845 1063.100830,1066.479858 1062.012207,1068.271851 
	C1060.984619,1069.963257 1060.302124,1072.122070 1057.715820,1071.696411 
	C1055.254272,1071.291260 1054.224731,1069.360962 1053.523560,1067.217651 
	C1053.161255,1066.109985 1052.794434,1065.004150 1052.274780,1063.571045 
	C1052.037476,1062.568970 1051.779419,1061.576538 1052.191772,1061.300049 
	C1056.229736,1058.592407 1055.525635,1053.905273 1057.078247,1050.165527 
	C1059.180420,1045.102173 1057.195312,1041.096802 1054.489990,1037.160400 
	C1053.899048,1036.300537 1053.063477,1034.929810 1051.728149,1035.906494 
	C1050.772339,1036.605713 1051.349976,1037.839355 1051.819458,1038.655884 
	C1053.874268,1042.230957 1053.390137,1046.039673 1053.096313,1049.867798 
	C1052.706543,1054.946045 1052.209839,1055.344116 1047.397095,1054.021606 
	C1046.490723,1053.772461 1045.516968,1052.899536 1044.700562,1053.984985 
	C1044.138672,1054.732056 1044.689331,1055.577637 1045.021240,1056.318481 
	C1045.851929,1058.172485 1046.639038,1060.015869 1045.197876,1062.283691 
	C1041.075684,1064.147217 1039.832153,1061.280518 1038.224121,1058.405029 
	C1037.197998,1055.016479 1034.456909,1054.441650 1031.999878,1053.748535 
	C1029.231445,1052.967529 1027.787231,1051.511841 1027.915039,1048.538452 
	C1028.011108,1046.304565 1027.500366,1044.277588 1024.756592,1043.238525 
	C1021.539612,1041.928711 1019.320984,1042.327881 1018.238892,1045.641724 
	C1017.647095,1047.453979 1016.444641,1048.899414 1014.616882,1049.382812 
	C1011.270996,1050.267578 1008.429443,1052.351807 1004.729919,1053.189209 
	C1003.454285,1053.163818 1002.564880,1052.944458 1001.370605,1052.593506 
	C1000.620239,1052.562622 1000.022400,1052.441406 999.875854,1052.881836 
	C999.690369,1053.439331 1000.304382,1053.466919 1001.015137,1053.655029 
	C1002.255005,1053.878052 1003.100647,1054.319824 1004.412537,1054.276855 
	C1007.471497,1053.762207 1010.286987,1053.038574 1010.744141,1057.500366 
	C1010.181946,1058.973755 1009.322754,1059.633179 1007.906494,1060.233398 
	C1005.714844,1060.662842 1003.993896,1061.544434 1001.720947,1061.071411 
	C999.403503,1059.984253 997.689697,1058.987061 995.906677,1061.642822 
	C994.561584,1063.646362 992.223511,1063.624390 989.777588,1062.344482 
	C988.470947,1058.217285 986.842712,1055.677979 982.499573,1058.910645 
	C981.855652,1059.389893 980.910889,1059.445190 980.082031,1059.423218 
	C977.806458,1059.362427 975.133789,1059.861816 974.466614,1056.690308 
	C973.916077,1054.073120 976.555054,1050.033813 979.171692,1050.085938 
	C983.367065,1050.169556 986.922485,1046.648804 991.287415,1048.209717 
	C991.751343,1048.375732 992.322510,1048.019165 992.549988,1047.583008 
	C994.380432,1044.072998 997.446594,1044.894409 1000.432007,1045.214844 
	C1002.051270,1045.388672 1003.749451,1045.707764 1005.810791,1044.210205 
	C1001.417908,1043.200928 996.620056,1043.299194 995.384644,1036.491699 
	C993.471619,1041.202881 991.065308,1041.250244 987.880127,1039.662476 
	C985.654541,1038.553101 982.970459,1039.012939 980.501343,1039.409058 
	C978.598389,1039.714355 976.836365,1039.872070 975.455017,1037.670776 
	C975.178833,1037.043823 974.965942,1036.650024 975.091980,1036.401367 
	C977.258179,1032.128906 980.981506,1028.836914 982.369873,1023.947693 
	C982.738403,1022.649963 986.181091,1021.375854 984.520447,1019.511658 
	C982.928772,1017.724976 979.838989,1016.600220 977.327026,1018.103760 
	C975.277893,1019.330261 973.110901,1021.060120 973.238525,1023.956360 
	C973.367249,1026.875122 972.378418,1029.115723 969.971741,1030.788330 
	C967.963745,1032.183716 968.110107,1034.570312 967.421631,1036.926636 
	C968.383362,1041.099976 971.668274,1041.882568 974.830994,1042.603882 
	C977.187317,1043.141357 979.724121,1043.445801 979.746704,1046.473999 
	C979.776550,1050.467285 975.686279,1050.366943 973.436707,1051.987061 
	C971.163025,1053.624634 969.815552,1051.422729 968.849365,1049.689453 
	C967.393677,1047.078003 964.761108,1046.299316 962.560364,1044.756104 
	C958.354065,1041.806519 954.481934,1038.269287 950.295471,1035.155762 
	C948.637268,1033.922485 949.253967,1030.731812 947.637573,1028.634888 
	C946.452515,1028.403931 945.986145,1029.090698 945.185852,1029.839111 
	C943.377441,1031.144165 941.546021,1031.365601 940.434082,1029.848145 
	C938.113525,1026.681152 934.800659,1026.836792 931.503967,1026.374268 
	C924.289978,1025.361816 921.403748,1020.514282 925.158997,1014.616089 
	C921.356506,1018.346802 917.597168,1019.087708 913.408936,1017.266113 
	C910.894348,1016.172363 908.298584,1016.136475 905.588562,1016.207031 
	C901.475891,1016.314148 900.494141,1014.622559 902.144348,1010.954041 
	C903.862915,1007.133484 902.918884,1005.172668 898.787964,1005.309998 
	C892.476746,1005.519836 886.699524,1003.099182 880.614441,1002.278992 
	C877.902527,1001.913391 875.655457,1000.327454 875.612854,997.495422 
	C875.521179,991.403076 872.503235,985.928101 872.337769,979.866821 
	C872.237793,976.204163 868.642090,976.995789 866.588928,976.330383 
	C863.841187,975.439880 860.528015,976.799561 860.166504,979.527649 
	C859.136902,987.297607 851.416931,986.819946 847.395691,990.779053 
	C846.854248,991.312073 845.681763,991.308167 844.985962,990.914795 
	C840.658325,988.468506 837.395874,984.761414 834.237305,981.067627 
	C833.172424,979.822266 834.103699,977.788818 835.617371,976.712585 
	C837.695618,975.234924 840.006714,975.239258 842.284302,976.177612 
	C846.805359,978.040100 846.839539,978.079041 847.441650,972.904297 
	C847.733521,970.396057 848.293274,968.231628 851.361633,968.069824 
	C853.048523,967.980835 853.506042,966.895691 853.846680,965.474426 
	C854.772644,961.612000 856.598816,957.982666 856.616943,953.875549 
	C856.623596,952.363037 857.190125,950.797729 858.941101,950.404907 
	C860.935852,949.957336 861.986572,951.270752 862.790405,952.861572 
	C863.131409,953.536377 862.915833,955.950684 864.225769,953.054688 
	C865.410950,950.434448 868.399597,950.514221 869.553040,953.180115 
	C870.908264,956.312256 872.841614,958.863647 874.980103,961.419067 
	C876.755615,963.540649 876.190796,966.115234 875.620483,968.605164 
	C875.322937,969.903809 875.034302,971.468445 876.972229,971.732239 
	C878.853088,971.988403 879.092468,970.430237 879.222656,969.102844 
	C879.486206,966.413147 879.545959,963.781860 877.067444,961.826477 
	C875.378662,960.494202 873.223572,958.930786 874.623901,956.492554 
	C876.156372,953.824402 878.640869,954.990723 880.833130,955.879761 
	C881.289368,956.064758 881.708923,956.352844 882.263184,956.345764 
	C882.957520,955.540771 882.235352,954.809265 882.065186,954.077148 
	C881.552368,951.870361 881.372742,949.692505 883.281433,948.069519 
	C885.454407,946.221741 887.665405,947.298706 889.810486,948.305603 
	C891.740845,949.211853 893.531799,950.393860 895.684204,951.581116 
	C895.687927,946.392029 897.925293,942.334412 900.401978,938.007202 
	C901.336670,937.227539 902.151611,936.885681 902.972839,937.063171 
	C905.438599,937.596008 906.890442,937.242981 907.345215,934.216736 
	C907.684998,931.956177 909.255859,929.829651 911.536865,928.925720 
	C918.097656,926.325500 923.925781,921.736633 931.469604,921.751404 
	C935.174194,921.758667 938.557495,920.533203 941.799255,918.582886 
	C945.280334,916.488525 953.139465,917.674438 955.758362,920.648438 
	C954.377319,924.212036 951.708130,925.452820 948.121338,925.810303 
	C946.837830,925.790894 946.005859,924.535828 944.831665,925.831970 
	C943.939941,927.418640 943.663147,928.918518 943.042175,930.274658 
	C941.410400,933.838501 938.967163,934.877869 935.364014,933.406982 
	C933.521667,932.654785 931.744446,931.758667 929.835632,931.145020 
	C924.088440,929.297729 919.312866,931.047913 916.238159,936.144043 
	C915.682556,937.065002 915.502625,938.012939 915.688171,939.001953 
	C916.222717,941.852600 914.761292,943.759155 912.575806,945.731567 
	C912.112793,946.584106 911.274292,946.565735 911.101685,947.247620 
	C912.159790,946.958984 912.517334,945.921082 913.585449,945.110107 
	C916.329407,944.092529 918.738281,944.111694 921.500793,945.169128 
	C922.511902,945.920898 922.819824,946.648254 922.962891,947.847778 
	C922.050232,951.743347 920.486755,953.805359 916.147583,953.944458 
	C912.488220,954.061890 914.231506,957.843384 914.338440,959.946655 
	C914.419800,961.545105 915.276245,963.746399 917.967712,961.780151 
	C919.937561,960.341064 921.781128,961.489685 923.320984,962.917725 
	C924.783264,964.273682 926.147400,965.733093 927.585327,967.592529 
	C928.303528,972.109985 929.591919,975.218079 934.820557,975.570801 
	C938.307007,975.806030 940.451538,978.263000 939.249023,982.281860 
	C938.958496,983.252747 938.870789,984.257996 939.471252,985.145203 
	C939.952209,985.855896 940.661255,986.368469 941.558228,986.155212 
	C942.386414,985.958313 942.441956,985.117249 942.260681,984.530823 
	C940.907776,980.154968 942.167358,976.046692 943.388550,971.909058 
	C943.826965,970.423523 943.403442,968.974304 942.859985,967.558472 
	C942.200806,965.841187 941.515747,964.092041 943.594604,962.409607 
	C947.491272,961.907166 948.980408,964.388489 949.912842,967.092957 
	C950.995178,970.231873 952.871094,972.641907 955.534607,974.419189 
	C958.129456,976.150696 959.508484,978.350647 959.032288,981.493591 
	C958.348267,986.007996 960.648865,988.210083 964.743774,989.174866 
	C965.540222,989.362488 966.357300,989.521362 967.136658,989.788330 
	C973.801575,992.070923 973.800659,992.073608 977.311279,986.374878 
	C982.280090,984.602295 986.490662,984.800415 990.740295,987.979431 
	C994.856934,991.059021 996.431458,990.039246 997.039185,984.955750 
	C997.280029,982.941406 996.700439,980.969727 996.883484,978.978455 
	C997.101929,976.601562 998.245422,974.801941 1000.673218,974.305359 
	C1003.149353,973.798828 1004.385010,975.511108 1005.039795,977.505066 
	C1005.757935,979.691956 1007.139038,981.118469 1008.962585,982.440491 
	C1011.428345,984.228210 1012.767700,986.679443 1011.461243,989.826416 
	C1010.202698,992.858215 1007.732910,993.330078 1005.349243,992.795532 
	C1005.502197,992.808960 1004.601868,992.891846 1004.732727,992.885193 
	C1009.857056,992.622986 1012.077942,995.103577 1011.902039,1000.109253 
	C1011.757446,1004.225159 1013.310730,1007.575317 1016.854370,1009.803406 
	C1018.625427,1010.916992 1019.776306,1012.320862 1020.494995,1014.337097 
	C1022.713745,1020.561340 1024.227539,1021.538147 1031.072388,1021.692566 
	C1032.255371,1021.719238 1033.376831,1021.989197 1034.498291,1022.321716 
	C1032.337402,1021.669861 1030.136719,1021.092224 1028.315186,1019.672119 
	C1025.390747,1017.392212 1025.573730,1015.473633 1028.877930,1013.687317 
	C1030.104370,1013.024292 1031.985840,1013.080322 1031.806641,1010.618164 
	C1029.549561,1010.176880 1026.886108,1011.943848 1024.619507,1009.512573 
	C1024.112427,1008.793762 1023.916931,1008.328796 1023.726318,1007.453979 
	C1023.601440,1006.301575 1023.638062,1005.536865 1023.892090,1004.419678 
	C1024.555176,1002.607239 1025.474609,1001.390869 1026.891113,1000.765625 
	C1028.512573,1000.049805 1029.065796,999.317871 1028.452637,997.369568 
	C1027.709961,995.010071 1029.449463,993.545044 1031.501465,994.371887 
	C1034.750854,995.681152 1038.241699,996.231689 1041.377441,997.753357 
	C1043.587891,998.826050 1045.114746,1000.545410 1044.908081,1003.600586 
	C1043.856812,1006.434387 1045.708862,1006.630615 1047.326294,1007.023254 
	C1049.773193,1007.617126 1052.009277,1008.448669 1052.238770,1011.507202 
	C1052.089478,1009.828857 1051.876221,1008.104309 1053.820557,1007.299561 
	C1055.732544,1006.508179 1057.530884,1007.122925 1058.755371,1008.566162 
	C1061.087646,1011.315247 1063.923096,1013.088379 1067.710449,1014.085449 
	C1069.263306,1014.995117 1070.269897,1016.011475 1071.500000,1017.302246 
	C1074.617432,1023.294067 1074.627930,1023.351685 1081.345825,1022.131287 
	C1083.507690,1021.738586 1086.024414,1022.819397 1088.021484,1020.428345 
	C1088.672363,1019.666138 1089.253052,1019.190979 1089.162109,1018.474915 
	C1088.434448,1012.735229 1090.149658,1009.811096 1095.884155,1009.356323 
	C1099.725464,1009.051697 1101.815674,1007.381287 1103.839844,1004.528931 
	C1105.948486,1001.557556 1108.697754,999.844238 1112.484131,1003.002930 
	C1114.176514,1005.530518 1115.094116,1007.798584 1112.129150,1009.599731 
	C1111.304321,1010.100769 1110.895264,1010.461670 1111.291992,1011.484253 
	C1112.869629,1015.551147 1108.828003,1017.576355 1107.746094,1020.698425 
	C1107.245361,1022.143066 1105.220093,1021.880066 1103.696533,1021.487244 
	C1102.049072,1021.062500 1100.858643,1019.792114 1098.903320,1019.028076 
	C1097.937866,1021.526550 1098.711304,1024.212280 1098.014038,1026.655151 
	C1097.239014,1029.370239 1098.232666,1032.456665 1095.182495,1034.702515 
	C1093.469727,1035.963623 1096.258423,1038.258423 1095.744629,1040.590576 
	C1093.911377,1044.220825 1091.486816,1044.395386 1088.775146,1042.227295 
	C1086.468506,1040.382935 1083.945557,1038.692749 1082.673950,1035.849365 
	C1081.152710,1032.447876 1079.345459,1031.403687 1076.821411,1035.020386 
	C1076.448120,1035.555420 1075.792236,1035.834473 1074.864990,1036.242188 
	C1072.355591,1036.990723 1070.329956,1038.434326 1067.854736,1037.367676 
	C1066.468140,1036.770264 1064.914062,1036.341919 1063.671753,1037.626831 
	C1062.697876,1038.634277 1063.613525,1039.488892 1064.309692,1040.124268 
	C1065.692505,1041.386597 1066.704102,1043.774048 1069.160278,1042.573486 
	C1070.939209,1041.703857 1072.570190,1040.534180 1074.868530,1039.949219 
	C1077.935669,1039.319824 1080.206421,1039.797729 1082.380981,1041.872925 
	C1086.840332,1046.128174 1088.119385,1046.186157 1093.941528,1043.233521 
	C1095.270142,1042.559814 1096.908203,1042.856812 1098.312256,1041.526367 
	C1101.272583,1039.834229 1100.437012,1037.857422 1099.387939,1035.807617 
	C1096.986206,1031.115356 1097.365356,1030.027588 1102.260620,1029.192261 
	C1104.200928,1028.861084 1105.222900,1028.117798 1105.800049,1026.347656 
	C1106.544434,1024.064087 1108.179199,1022.822205 1110.950195,1022.630249 
	C1112.043579,1022.612061 1112.741333,1022.780579 1113.721191,1023.260864 
	C1114.518188,1023.918884 1114.969238,1024.476074 1114.984375,1025.143799 
	C1115.135254,1031.842041 1119.219849,1035.975586 1124.315918,1039.399902 
	C1125.600830,1040.263184 1126.091431,1041.320435 1125.897705,1042.742310 
	C1125.674561,1044.379639 1124.651367,1045.419189 1123.130005,1045.949463 
	C1121.142700,1046.641846 1119.206909,1046.320435 1117.179688,1045.463257 
	C1116.724609,1045.223633 1116.582886,1046.119141 1116.844116,1045.505737 
	C1116.848633,1045.495117 1116.958008,1045.538330 1117.171997,1045.635498 
	C1122.419556,1048.018066 1122.456909,1048.038452 1119.767700,1052.968628 
	C1119.164917,1054.073486 1119.075562,1055.150757 1119.167969,1056.313232 
	C1119.259644,1057.466064 1119.115601,1058.619507 1119.234741,1060.293091 
	C1120.898804,1058.171509 1122.119995,1055.936157 1125.056641,1056.198486 
	C1127.732910,1056.437500 1125.662109,1054.620239 1125.625488,1054.105347 
	C1125.132202,1047.149170 1128.760986,1043.825806 1136.202026,1045.072510 
	C1137.269897,1047.458740 1135.562012,1050.286987 1138.489258,1052.119385 
	C1140.237549,1053.702637 1142.202148,1054.432129 1143.848633,1056.161865 
	C1145.796143,1058.622681 1147.651001,1060.711914 1148.571533,1063.459106 
	C1149.305420,1065.649048 1150.994629,1066.660522 1153.247314,1065.905640 
	C1155.509888,1065.147583 1157.338745,1063.632202 1158.122559,1061.320923 
	C1158.570679,1059.999268 1157.202271,1059.784790 1156.293823,1059.540405 
	C1153.913452,1058.899780 1151.394287,1058.596313 1149.485596,1056.220215 
	C1148.469482,1052.076782 1147.593872,1048.362183 1148.672974,1044.286377 
	C1149.846313,1039.854736 1145.779419,1038.846680 1142.905518,1037.660278 
	C1137.365845,1035.373657 1137.158081,1035.307373 1138.286255,1029.179565 
	C1142.263306,1025.404419 1145.011963,1028.169800 1148.104004,1030.689697 
	C1149.285767,1031.967285 1149.925903,1033.269897 1151.579346,1034.027710 
	C1153.719360,1035.342651 1155.419556,1036.660034 1157.320557,1038.711182 
	C1156.143799,1036.346802 1154.449219,1035.235596 1153.145264,1033.793945 
	C1151.770386,1032.273438 1151.592285,1030.541382 1152.372925,1028.703369 
	C1153.206665,1026.740356 1154.737183,1026.044800 1156.804077,1026.385376 
	C1158.117554,1026.601685 1159.459473,1026.820557 1160.761475,1026.473999 
	C1165.085449,1025.322998 1167.876587,1026.375732 1168.535034,1031.290894 
	C1168.843872,1033.596313 1170.999878,1034.706909 1172.582520,1036.147705 
	C1176.753784,1039.945312 1176.970947,1041.754272 1173.754883,1046.234253 
	C1172.663208,1047.755005 1171.536865,1049.236084 1172.550049,1051.209229 
	C1173.686401,1053.422241 1175.603394,1053.406128 1177.700806,1052.955566 
	C1179.354492,1052.600342 1181.005859,1052.618652 1182.797119,1053.850342 
	C1185.486450,1060.021362 1183.928223,1062.065430 1176.111084,1062.772705 
	C1172.970581,1062.783203 1172.883179,1062.869873 1173.929932,1065.026367 
	C1178.004150,1067.081665 1181.685303,1066.882935 1185.545044,1064.395264 
	C1187.786621,1063.460938 1189.203247,1062.346802 1189.855591,1060.158081 
	C1190.694458,1057.343994 1192.639282,1055.289551 1195.789062,1056.220093 
	C1199.114746,1057.202637 1197.484619,1059.964355 1197.147583,1062.366089 
	C1199.385742,1062.322998 1201.303833,1058.564819 1203.539429,1061.628052 
	C1205.006226,1063.637939 1203.325195,1066.232544 1204.562744,1068.885010 
	C1207.697754,1066.025879 1210.711670,1066.309448 1214.026367,1068.788208 
	C1215.713623,1070.049805 1218.266846,1069.574951 1220.377197,1069.107056 
	C1224.688354,1068.151489 1227.463135,1069.687988 1228.998657,1073.777954 
	C1230.302368,1077.250122 1232.172363,1080.382446 1234.995117,1082.859253 
	C1237.336304,1084.913452 1237.263184,1087.498047 1236.481689,1090.153198 
	C1238.385864,1091.418823 1239.803345,1090.113892 1241.282349,1089.653076 
	C1242.890625,1089.151855 1244.889771,1088.175171 1246.064331,1089.314087 
	C1249.472900,1092.618774 1252.529053,1090.691772 1256.021240,1089.639526 
	C1259.488525,1088.594727 1263.302490,1088.302368 1265.888184,1092.434326 
	C1266.020996,1093.805176 1265.670898,1094.728516 1265.221436,1095.997070 
	C1261.550903,1102.689575 1256.752319,1104.856934 1249.171387,1103.494263 
	C1248.340088,1103.344971 1247.475464,1103.055054 1246.977905,1103.971191 
	C1246.631226,1104.609375 1247.109497,1105.203125 1247.427856,1105.780273 
	C1249.427368,1109.405029 1251.989380,1110.424072 1256.112549,1109.170532 
	C1257.062500,1108.881836 1257.974609,1108.461060 1258.974609,1108.355713 
	C1260.645386,1108.179443 1261.957764,1108.778442 1262.570923,1110.371338 
	C1263.275269,1112.201294 1262.614868,1114.158081 1260.923340,1114.499878 
	C1254.609131,1115.775635 1254.786743,1120.417358 1254.851929,1125.500732 
	C1254.005249,1128.374023 1251.494873,1130.466553 1252.888550,1133.729614 
	C1256.252197,1131.906982 1254.263916,1128.279785 1255.974609,1125.683472 
	C1258.158936,1125.013306 1260.048584,1124.753784 1261.458740,1123.005249 
	C1262.322510,1121.934326 1263.853760,1121.905273 1265.132446,1122.706543 
	C1266.628296,1123.644043 1267.060791,1125.115967 1266.340698,1126.607910 
	C1264.586060,1130.243042 1264.604614,1134.396729 1262.800415,1138.011963 
	C1261.339600,1140.939209 1262.598999,1142.638672 1265.465210,1143.742310 
	C1274.121582,1147.075928 1276.976318,1145.637207 1274.955322,1136.754150 
	C1273.018433,1128.240112 1278.264648,1121.377686 1277.692139,1113.488525 
	C1277.588745,1112.062134 1278.362793,1110.499146 1280.694946,1110.785522 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M464.263672,1280.762695 
	C462.611115,1281.000000 461.222198,1281.000000 459.416656,1281.000000 
	C452.622345,1279.128784 446.173645,1277.585815 439.494720,1278.907349 
	C435.348083,1279.727661 431.598541,1278.189453 427.706573,1277.473389 
	C426.038605,1277.166382 424.300293,1276.527832 424.447815,1274.369507 
	C424.591125,1272.273193 425.962921,1271.151489 428.026154,1270.831055 
	C429.021912,1270.676636 430.012299,1270.999634 431.170990,1270.766479 
	C431.015930,1269.364746 429.845154,1268.908447 428.870178,1268.620117 
	C423.325378,1266.979736 421.458069,1262.961060 421.353088,1257.608154 
	C421.326050,1256.228882 421.830353,1254.179443 419.957397,1253.794800 
	C418.389221,1253.472534 418.153473,1255.430176 417.497864,1256.511597 
	C415.449127,1259.891602 405.757416,1262.444458 402.312653,1260.490723 
	C399.573303,1258.937256 399.795715,1255.691040 402.501251,1253.486938 
	C403.841888,1252.394775 407.634491,1252.996094 406.296844,1250.234497 
	C405.122467,1247.809692 403.220520,1244.536377 399.810669,1244.656738 
	C392.876129,1244.901489 389.240723,1240.707764 386.101410,1235.523804 
	C382.351837,1229.332275 377.113525,1225.086670 370.092804,1223.051147 
	C365.565125,1221.738403 364.736511,1220.206909 365.913574,1215.729370 
	C366.671326,1212.847046 368.841919,1210.129150 369.301086,1207.660400 
	C370.268951,1202.456055 374.594330,1197.584351 371.673096,1191.782349 
	C370.608612,1189.668091 370.356659,1187.314453 370.395996,1184.963501 
	C370.444519,1182.062134 369.930450,1179.450684 368.018585,1177.051880 
	C366.087463,1174.628906 366.051483,1171.762817 367.673950,1169.091675 
	C369.394287,1166.259521 372.022980,1167.330933 374.422241,1167.836060 
	C377.759857,1168.538940 381.052338,1168.366943 384.309967,1167.478271 
	C388.327576,1166.382202 391.927429,1161.497559 390.820404,1157.604614 
	C389.326599,1152.351685 389.474121,1147.078369 389.286713,1141.768677 
	C389.237091,1140.362061 389.576752,1138.276489 387.732483,1137.982300 
	C385.742371,1137.665039 385.725616,1139.872803 385.304443,1141.168579 
	C384.141907,1144.745239 381.595032,1146.521240 378.098328,1147.321045 
	C374.218811,1148.208252 370.196045,1148.343140 366.421021,1149.852539 
	C364.600189,1150.580566 362.754211,1149.968140 361.668121,1148.128052 
	C360.284760,1145.784180 362.491028,1144.594971 363.771606,1142.690186 
	C359.558289,1141.880493 358.404663,1138.983887 358.341431,1135.325439 
	C358.312561,1133.656494 357.913788,1132.032227 357.575195,1130.398926 
	C356.349152,1124.484741 358.293793,1122.547363 364.242035,1124.251465 
	C367.488342,1125.181396 370.457489,1124.778198 373.555267,1124.027100 
	C377.475128,1123.076660 377.833099,1120.021606 377.324829,1116.983032 
	C376.786072,1113.762329 374.758240,1111.455566 371.100067,1111.937622 
	C367.679657,1112.388184 364.493988,1113.133057 361.472076,1115.455078 
	C356.565247,1119.225464 350.732147,1117.999756 345.225281,1116.472168 
	C343.167786,1115.901489 341.287476,1114.719727 339.213196,1114.058594 
	C332.853668,1112.031982 330.749420,1107.007446 333.610443,1100.837769 
	C334.855408,1098.153198 336.435181,1095.681274 335.381989,1092.351929 
	C334.581146,1089.820312 336.867249,1089.033081 338.816193,1088.339966 
	C341.260223,1087.470703 343.525543,1086.412842 343.113220,1082.819824 
	C342.970642,1081.976685 342.967834,1081.489136 343.076874,1080.640991 
	C343.419617,1079.091553 344.405304,1078.241821 344.801971,1076.713989 
	C345.394470,1075.262329 345.767242,1074.102905 346.662292,1072.786987 
	C349.640015,1069.883667 353.959351,1069.913330 356.435242,1066.803589 
	C356.816986,1066.324097 357.731018,1066.061157 358.271423,1066.328369 
	C362.839294,1068.585815 367.613800,1066.113403 372.587372,1066.928955 
	C373.691528,1066.730103 374.435120,1066.741699 375.543213,1066.835449 
	C382.432709,1068.437134 387.196716,1065.500122 391.572388,1060.614502 
	C392.497742,1059.989258 393.198608,1059.760254 394.299744,1059.597412 
	C398.379700,1059.331421 402.516327,1060.327759 405.088684,1055.793457 
	C406.065063,1054.809570 406.941650,1054.394287 408.319031,1054.270752 
	C415.602264,1053.090332 422.868195,1053.638306 429.654053,1050.010864 
	C434.020874,1049.292358 437.674286,1047.690796 441.476807,1045.472534 
	C442.473145,1045.029419 443.182526,1044.912109 444.262756,1044.876465 
	C449.673859,1044.819580 453.338379,1041.521118 457.782349,1039.136230 
	C462.045197,1037.228882 466.012421,1034.678711 469.990479,1034.054443 
	C476.926758,1032.965820 484.195831,1031.455078 491.349915,1034.548828 
	C491.191406,1037.010132 489.454285,1037.492432 487.880493,1037.862183 
	C483.187195,1038.964722 478.694672,1040.744385 474.072998,1042.073364 
	C469.102448,1043.502808 464.784912,1046.953003 459.371338,1047.611328 
	C457.710022,1047.813477 457.430023,1049.661255 457.117432,1051.168335 
	C456.440033,1054.434570 454.307465,1055.605225 451.209473,1054.794312 
	C445.204041,1053.222290 439.368347,1054.793945 433.474731,1055.499268 
	C431.564758,1055.727783 430.677856,1057.143555 429.669647,1058.448242 
	C427.873199,1060.772949 425.472290,1061.809570 422.640076,1061.277222 
	C413.797150,1059.614624 406.704224,1063.075439 400.506531,1068.855103 
	C397.728729,1071.445557 394.836029,1073.377197 390.976501,1073.776123 
	C389.115692,1073.968384 387.353027,1074.616333 387.039795,1076.907837 
	C386.719574,1079.250244 387.872528,1080.732178 389.905853,1081.610474 
	C392.400055,1082.687622 394.404236,1084.402588 396.353851,1086.237793 
	C398.216980,1087.991577 400.417938,1089.366943 403.027527,1089.221191 
	C409.251221,1088.873291 413.279480,1092.819214 417.205811,1096.557861 
	C421.233063,1100.392578 419.542877,1105.250244 414.115204,1106.513794 
	C409.389069,1107.613892 406.287506,1114.303589 409.267761,1117.877563 
	C411.565491,1120.632935 415.160248,1119.636230 418.089050,1118.573730 
	C421.943665,1117.175659 422.235260,1115.538574 419.551331,1112.525757 
	C417.766357,1110.522217 417.365295,1108.412476 418.897949,1106.182861 
	C420.407654,1103.986572 422.719696,1103.201050 425.037964,1104.342163 
	C428.180115,1105.888550 430.834106,1106.229614 433.674835,1103.697632 
	C435.129944,1102.400635 437.265045,1102.588623 438.859406,1103.659668 
	C441.789185,1105.628052 444.923615,1105.833252 448.331146,1105.905762 
	C451.590118,1105.975098 454.908203,1106.940063 453.351868,1111.928711 
	C451.639465,1114.138428 448.725891,1111.864502 447.192078,1114.449219 
	C446.677246,1115.317993 446.230774,1115.818726 445.451996,1116.469238 
	C443.324829,1117.762695 440.635101,1117.064819 439.158569,1119.652832 
	C438.391357,1120.842651 436.851898,1121.570312 437.547485,1122.760498 
	C438.271515,1123.999756 439.931793,1123.362671 441.526978,1123.629517 
	C445.614105,1124.433350 449.826538,1124.767944 450.400482,1129.711792 
	C450.961456,1134.543701 451.137695,1139.330566 445.984741,1142.387939 
	C442.662292,1144.359253 442.472076,1147.454956 445.679626,1149.888672 
	C450.153381,1153.283203 452.917328,1157.512085 453.774109,1163.018188 
	C454.024261,1164.625977 454.639893,1165.912842 456.418091,1166.146973 
	C460.794189,1166.722656 462.538849,1169.523682 462.996765,1173.589966 
	C463.199982,1175.394165 464.620056,1176.858887 464.631409,1179.110107 
	C462.881897,1181.945679 460.850800,1180.767456 458.902832,1179.938843 
	C457.540009,1179.359131 456.319092,1178.442505 454.823669,1178.174561 
	C453.401337,1177.919800 452.173462,1178.313232 451.347961,1179.522339 
	C450.869751,1180.222900 450.660828,1181.152222 451.154175,1181.871216 
	C452.037170,1183.158325 453.262451,1182.314941 454.302399,1182.080200 
	C457.435059,1181.373169 460.509491,1183.250732 463.994415,1182.237793 
	C468.633087,1183.530762 470.168762,1187.041748 469.425568,1190.729004 
	C467.922607,1198.185669 471.251221,1204.441772 473.429413,1211.386719 
	C473.748474,1212.118408 473.925507,1212.530273 474.232788,1213.227295 
	C474.572174,1213.869019 474.806610,1214.204468 475.349182,1214.680176 
	C475.812561,1214.950317 476.006714,1215.052856 476.520996,1215.270630 
	C480.933685,1215.786133 484.335968,1217.993408 487.901184,1219.717163 
	C490.796661,1221.117188 491.479553,1223.882080 491.571533,1227.201660 
	C491.660889,1228.553223 491.707245,1229.667358 492.885925,1229.957275 
	C496.566498,1230.862915 498.366730,1233.268188 498.847443,1236.922363 
	C499.069672,1238.611694 500.301697,1239.649780 501.916595,1239.569702 
	C503.925537,1239.470093 503.240173,1237.637573 503.359375,1236.469604 
	C503.509155,1235.002075 503.178009,1233.468872 503.455383,1232.037354 
	C504.327057,1227.539429 502.644043,1225.174683 497.685364,1224.828247 
	C494.955719,1222.405884 496.229156,1219.742798 496.582825,1216.822510 
	C497.115356,1215.203003 497.433868,1213.900146 497.966614,1212.687622 
	C498.672485,1211.081177 498.711670,1208.877441 500.954437,1208.448975 
	C503.412689,1207.979370 503.918732,1210.051636 504.721680,1211.661011 
	C504.721680,1211.661011 518.318359,1216.103027 518.318359,1216.103027 
	C519.284485,1215.660645 519.719177,1214.720215 520.314392,1213.939941 
	C522.046509,1211.669312 524.457458,1211.325928 527.041260,1211.598145 
	C529.846375,1211.893555 531.557678,1213.363403 530.530579,1216.276611 
	C529.328247,1219.687134 530.817993,1221.684326 533.442627,1223.326782 
	C535.288635,1224.481812 536.950867,1225.810303 538.408447,1227.451660 
	C540.433472,1229.732056 542.151184,1229.464233 544.024536,1226.937866 
	C546.717590,1223.306274 548.442566,1223.495483 550.286011,1227.635498 
	C551.263794,1229.831543 552.454041,1231.203857 554.855042,1231.635742 
	C556.732971,1231.973633 558.519470,1232.852539 558.507202,1235.085571 
	C558.495605,1237.197876 556.783875,1238.177979 555.058716,1238.938477 
	C553.235718,1239.742188 551.266724,1240.179932 549.533020,1241.221558 
	C547.032776,1242.723145 546.459167,1244.984863 546.852295,1247.667969 
	C547.253662,1250.406616 549.550354,1250.163086 551.432251,1250.587891 
	C555.994568,1251.618042 559.864258,1250.153320 563.638489,1247.631348 
	C567.386230,1245.127075 570.091370,1245.995117 571.640015,1249.746460 
	C573.029785,1253.112915 571.802429,1255.453491 568.270447,1256.624512 
	C565.587158,1257.514282 562.704163,1256.562256 559.447144,1257.596558 
	C562.130249,1260.565552 564.904236,1260.346558 567.875488,1259.660522 
	C573.679688,1258.320557 573.898499,1258.465820 576.017944,1263.763306 
	C577.422424,1267.273804 581.488892,1271.828125 585.454224,1274.331665 
	C588.182007,1269.701904 597.474487,1266.635986 603.469666,1268.387451 
	C609.088196,1270.029053 612.737427,1274.931274 612.225830,1280.726807 
	C596.979126,1281.000000 581.958191,1281.000000 566.468628,1281.000000 
	C564.510742,1275.059937 559.119690,1275.365234 554.778198,1274.084106 
	C553.151184,1273.603760 552.373596,1274.626099 552.373108,1276.272461 
	C552.372681,1277.739746 552.508728,1279.250610 551.233337,1280.750244 
	C547.969421,1281.000000 544.938782,1281.000000 541.454102,1281.000000 
	C535.317505,1278.383179 529.279358,1279.568726 523.413513,1279.613647 
	C518.307312,1279.652588 513.309143,1280.055908 508.149597,1279.164917 
	C504.238678,1278.489502 500.073761,1279.768555 495.932007,1279.786255 
	C491.217468,1279.806396 486.345703,1279.287598 481.321899,1280.751831 
	C480.555542,1281.000000 480.111115,1281.000000 479.333313,1281.000000 
	C474.244720,1278.739136 469.371582,1280.072510 464.263672,1280.762695 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1094.791992,25.290684 
	C1095.805420,24.753344 1096.610718,24.506313 1097.708008,24.129318 
	C1099.387573,22.415138 1099.260254,20.563763 1098.606934,18.824316 
	C1097.378662,15.553802 1098.895264,13.554807 1101.842896,13.652609 
	C1114.336670,14.067154 1126.289917,8.199825 1138.923584,10.621430 
	C1140.920166,11.004133 1142.888672,10.282365 1144.875854,10.146396 
	C1147.386841,9.974576 1149.470825,10.844624 1150.933472,12.925495 
	C1152.386719,14.993011 1151.374756,16.751410 1149.995239,18.317478 
	C1148.314941,20.225027 1148.829834,22.101158 1150.147827,23.751360 
	C1154.414062,29.092497 1155.650879,34.608982 1151.874390,40.785210 
	C1150.839478,42.477615 1150.757202,44.735111 1153.093262,45.327381 
	C1157.627319,46.476818 1160.320312,49.793274 1163.679810,53.243107 
	C1162.980957,48.039856 1168.670532,45.776474 1168.534424,40.806221 
	C1168.489258,39.152870 1170.517822,38.609428 1172.005371,38.530296 
	C1175.954834,38.320213 1176.537964,35.928730 1176.147583,32.701111 
	C1175.845337,30.201473 1175.183228,27.810438 1174.291870,25.463873 
	C1172.992798,22.044056 1171.090576,18.465450 1174.956177,15.496012 
	C1177.833252,13.285931 1185.151001,14.269219 1188.083374,16.980923 
	C1188.945068,17.777840 1189.353760,18.939215 1190.634521,19.922834 
	C1191.811890,17.492037 1193.447144,15.487450 1195.952271,14.379133 
	C1197.081421,13.879593 1197.935913,13.067386 1197.656616,11.670636 
	C1197.308960,9.931359 1195.827881,9.946177 1194.521240,9.829550 
	C1192.848999,9.680298 1191.203003,10.026807 1189.557861,10.280827 
	C1187.042725,10.669202 1184.672485,10.292909 1182.202881,9.567614 
	C1172.040894,6.583145 1161.650879,5.682143 1151.214966,7.647865 
	C1145.873291,8.654027 1140.686401,9.201799 1135.455200,7.478630 
	C1133.302490,6.769564 1131.234619,7.101015 1129.071533,7.524516 
	C1124.067749,8.504215 1122.497314,6.743810 1123.759766,1.285393 
	C1149.354248,1.000000 1174.708496,1.000000 1200.531372,1.000000 
	C1206.051514,4.406391 1211.816284,3.523481 1217.380005,3.623114 
	C1228.171387,3.816363 1238.833374,1.077001 1249.719604,2.403188 
	C1254.586548,2.996087 1259.092651,4.645416 1263.750000,5.838439 
	C1266.650146,6.581313 1267.416504,8.895710 1266.937866,11.750804 
	C1266.773560,12.731788 1265.728638,14.311549 1267.576294,14.594543 
	C1269.330688,14.863273 1268.966553,13.164388 1269.124023,12.088704 
	C1270.088013,5.508222 1273.811646,3.333174 1280.702393,5.751493 
	C1281.000000,14.687564 1281.000000,23.375128 1281.000000,32.531345 
	C1280.472900,33.453236 1279.877197,33.628349 1279.169922,33.600060 
	C1274.329956,33.406464 1272.018677,34.917732 1272.173340,39.538849 
	C1272.365479,45.284058 1270.896484,51.120834 1273.292358,56.826622 
	C1275.083252,61.091320 1272.736694,65.354416 1272.069580,69.558052 
	C1271.498047,73.158119 1270.717529,76.745071 1270.072510,80.737549 
	C1267.995483,83.440987 1269.108032,87.696289 1264.764282,88.843811 
	C1259.897461,88.820801 1257.179932,85.988091 1254.494507,82.435349 
	C1253.684204,80.942902 1253.495972,79.684715 1253.458496,78.035492 
	C1252.719727,73.310959 1249.521606,69.252159 1252.045166,64.388985 
	C1250.418335,66.778786 1251.838989,69.764099 1249.750366,72.211761 
	C1248.995972,73.032272 1248.361816,73.397217 1247.282471,73.694481 
	C1241.336304,76.315193 1236.080200,79.506653 1230.493530,82.089706 
	C1228.192505,83.153526 1229.062622,87.316879 1228.772827,90.003052 
	C1228.369263,93.745514 1227.349365,96.801056 1224.285400,99.017052 
	C1222.474365,100.327034 1221.923584,101.992867 1222.115723,104.213707 
	C1222.373291,107.189209 1222.002563,110.187653 1222.071777,113.188202 
	C1222.140747,116.169403 1220.587646,117.316132 1217.487305,116.333206 
	C1211.640869,114.479668 1205.948608,114.958603 1201.043701,118.842628 
	C1197.337646,121.777298 1193.487915,121.358742 1189.749268,119.739815 
	C1186.004395,118.118172 1185.017456,119.080307 1185.427368,122.864258 
	C1185.695679,125.340004 1184.737427,127.494225 1182.600708,128.893875 
	C1179.688354,130.801636 1178.548706,133.601425 1178.191162,136.896011 
	C1177.741943,141.034683 1177.150269,145.139038 1173.828125,148.611099 
	C1177.552979,149.858170 1180.403442,149.593430 1182.754395,146.809372 
	C1184.318237,144.957443 1186.292236,143.761063 1188.699463,143.421738 
	C1192.177979,142.931366 1193.455566,140.820435 1193.366577,137.508713 
	C1193.322144,135.849823 1193.332642,134.168839 1193.777588,132.544006 
	C1194.365723,130.396683 1195.666382,128.980621 1198.007812,129.020248 
	C1200.601685,129.064178 1201.850220,130.695816 1202.281982,133.088989 
	C1202.642578,135.086761 1202.039795,137.050262 1201.891113,139.863678 
	C1203.462769,136.158447 1205.541626,133.676712 1206.915161,130.757538 
	C1208.043823,128.358322 1210.116699,127.326881 1212.703979,128.050613 
	C1215.367065,128.795578 1216.663940,131.090820 1216.038940,133.465500 
	C1214.618164,138.864517 1214.981567,144.371780 1214.317017,149.796097 
	C1213.696045,154.863998 1209.348145,157.229019 1205.563843,159.429260 
	C1202.047852,161.473495 1201.489380,163.588486 1202.000000,167.485046 
	C1203.308594,177.473160 1201.833862,186.987793 1194.778198,194.939301 
	C1193.321167,196.581390 1191.726318,197.429504 1189.564697,197.259125 
	C1187.154907,197.069183 1186.107056,195.565872 1185.736450,193.362167 
	C1185.598267,192.540390 1185.255127,191.800217 1184.797974,191.078033 
	C1181.236938,185.451218 1181.548096,180.837448 1185.682861,175.823196 
	C1186.650879,174.649368 1188.852051,173.846466 1187.642944,171.784775 
	C1186.589355,169.988205 1184.803101,169.054535 1182.652466,168.904510 
	C1176.828979,168.498291 1173.239136,172.475586 1174.243652,178.248917 
	C1174.646240,180.563293 1175.452393,182.720230 1176.460083,184.849564 
	C1178.813110,189.822250 1177.086426,193.088531 1171.623047,194.281189 
	C1168.819458,194.893219 1165.987793,194.393799 1162.451416,194.438126 
	C1164.071777,196.596786 1166.051636,198.599930 1164.301147,201.053467 
	C1162.498901,203.579468 1159.740723,203.471893 1157.031494,203.198410 
	C1154.034668,202.895920 1151.032227,203.108170 1148.052368,203.270203 
	C1144.685303,203.453262 1141.420044,204.206787 1139.335449,207.331573 
	C1138.967041,207.883987 1138.519531,208.490616 1137.926270,208.724472 
	C1133.573120,210.440475 1129.448242,211.999603 1126.734863,216.572968 
	C1125.275757,219.032532 1121.632202,218.043442 1118.985229,216.417374 
	C1117.486938,215.496826 1116.511108,213.555832 1113.956543,213.982025 
	C1106.049927,213.607574 1101.093628,208.948761 1100.929443,201.677673 
	C1101.563721,200.010513 1102.512207,199.117798 1103.991943,198.233154 
	C1108.809448,196.549255 1108.809448,196.549255 1105.895020,192.129517 
	C1105.732300,186.327423 1105.749023,180.896301 1107.365234,175.218536 
	C1111.212891,173.046585 1112.786011,175.335938 1113.987671,178.091354 
	C1114.793579,179.939453 1114.772827,181.940552 1114.382202,184.310608 
	C1113.946655,185.976425 1112.954712,186.802109 1112.191040,187.805557 
	C1111.275391,189.008820 1112.131836,189.433487 1113.603394,189.505981 
	C1116.958374,189.443314 1118.485962,188.040939 1118.313477,184.898102 
	C1118.223267,183.255905 1118.265015,181.607712 1118.051147,179.969009 
	C1117.551270,176.141617 1116.095215,173.347061 1111.561035,173.561935 
	C1110.420776,173.616013 1109.269897,173.310181 1107.968506,172.454529 
	C1107.250000,171.566345 1107.105103,170.847702 1107.258301,169.724075 
	C1109.084595,166.937347 1112.088257,167.308426 1114.412231,166.177475 
	C1115.588257,165.605133 1116.814087,165.516083 1115.850952,163.447495 
	C1113.488037,158.372482 1114.761963,156.710083 1121.037354,156.600189 
	C1122.944214,158.553040 1123.453247,160.896179 1124.506714,162.957611 
	C1125.507935,164.916733 1126.765381,166.066574 1129.191162,165.763611 
	C1134.965088,165.042496 1136.492188,165.233109 1134.762817,159.053101 
	C1134.134644,156.808716 1134.489014,154.419052 1134.334839,152.101196 
	C1134.199097,150.060272 1134.842285,147.322159 1131.773560,146.950439 
	C1128.944702,146.607742 1125.735962,145.568253 1123.696167,148.868393 
	C1123.173340,149.714203 1122.770996,150.642105 1121.579346,151.354614 
	C1120.737915,151.503647 1120.048462,151.502151 1119.847290,151.130203 
	C1117.662231,147.090759 1114.496216,145.609818 1109.802490,145.994171 
	C1106.973755,146.225830 1106.535278,143.141556 1106.159790,140.816315 
	C1105.816528,138.691010 1106.037109,136.496475 1108.535522,136.035294 
	C1112.886230,135.232162 1112.165405,132.670456 1111.231689,129.722153 
	C1109.899536,125.515007 1110.184204,121.533081 1113.117554,117.944412 
	C1115.316162,115.254829 1117.690796,114.138779 1120.835205,116.436165 
	C1122.347412,117.541107 1123.998413,118.472038 1125.921387,117.542297 
	C1127.707520,116.678658 1129.936890,115.800636 1129.933838,113.547859 
	C1129.930786,111.318016 1127.441284,111.912537 1126.057617,111.218956 
	C1119.907593,108.136086 1113.827393,107.917259 1107.420166,110.735367 
	C1104.595581,111.977684 1102.981079,110.128036 1103.939697,107.054016 
	C1104.510254,105.224937 1104.623779,103.583344 1104.264282,101.713104 
	C1103.408203,97.259575 1104.675293,94.945198 1108.242188,93.850754 
	C1112.875732,92.429024 1117.292603,93.978432 1118.989990,97.621025 
	C1120.930420,101.785004 1123.405640,102.076180 1126.304443,98.411102 
	C1127.125610,97.373085 1127.734497,96.175423 1128.673584,95.206963 
	C1133.904053,89.812584 1133.880493,89.839531 1127.025391,86.355438 
	C1124.468262,85.055779 1121.750977,84.315247 1118.998169,83.654388 
	C1115.845947,82.897644 1112.935669,81.788750 1111.163208,78.810966 
	C1110.146729,77.103424 1108.335571,76.613945 1106.683350,75.828064 
	C1102.299927,73.743004 1101.121948,71.600128 1102.354248,67.075615 
	C1103.740234,61.986786 1103.992798,57.803379 1098.156250,54.996426 
	C1095.210449,53.579681 1095.678467,50.748867 1098.393066,48.596611 
	C1102.731445,45.157166 1107.669800,42.873192 1112.767578,40.852367 
	C1113.927246,40.392643 1115.548828,40.473396 1115.860718,38.770638 
	C1115.956177,38.249435 1115.578003,37.787281 1115.087036,37.638569 
	C1113.239624,37.079025 1112.995605,38.769161 1112.197144,39.726856 
	C1109.774292,42.632847 1106.737427,42.400009 1106.030884,38.792122 
	C1105.115723,34.119648 1101.586792,32.487621 1098.394897,30.236158 
	C1096.691895,29.034903 1094.280151,28.422037 1094.791992,25.290684 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M529.647217,1.268817 
	C545.354248,1.000000 560.708435,1.000000 576.531372,1.000000 
	C577.537720,1.611075 577.825317,2.319065 577.772888,3.515286 
	C576.700073,5.769534 576.573975,7.360658 579.030701,8.190217 
	C579.659119,8.402407 580.243835,8.739816 580.852051,9.010824 
	C586.981445,11.741771 591.573181,15.462646 591.284241,23.104528 
	C591.172302,26.066418 592.909119,28.854027 593.361328,31.821932 
	C594.532471,39.507568 591.108826,44.016224 583.375366,43.772148 
	C579.643677,43.654377 577.074951,45.369724 574.140625,46.760918 
	C573.236450,47.189594 572.288696,47.751114 572.497437,48.966042 
	C572.691528,50.095501 573.741882,50.446613 574.646179,50.848980 
	C576.408569,51.633152 578.218140,51.451309 580.039856,51.065971 
	C583.491882,50.335819 585.825073,51.912991 587.484558,54.772785 
	C589.339355,57.969234 590.185913,61.282120 588.073792,64.680725 
	C586.885681,66.592552 585.182861,67.217903 582.916321,66.427162 
	C575.903564,63.980587 566.961182,67.698265 563.513367,74.407555 
	C563.055359,75.298721 562.560059,76.164680 561.580566,77.133286 
	C560.193115,77.956177 558.907532,78.053520 557.991211,77.373245 
	C553.944092,74.368591 549.614990,75.253494 544.890137,76.212250 
	C542.775879,76.221336 541.373779,75.471786 540.239624,74.163918 
	C541.386353,75.540489 542.112976,77.438652 544.719482,77.425125 
	C547.399170,77.192520 549.718262,77.112061 552.030945,77.017685 
	C556.385986,76.839958 557.339905,77.951012 556.658203,82.311493 
	C556.401428,83.953682 556.042542,85.561623 556.373413,87.640152 
	C556.428040,89.175789 556.405518,90.289833 555.695923,91.688980 
	C550.259155,92.775017 545.086121,93.182579 540.186279,89.733124 
	C539.575684,88.772972 539.642700,87.634880 538.103149,87.984596 
	C534.631348,90.479454 537.154602,91.201180 538.982727,91.996384 
	C541.261475,92.987602 544.523071,92.717865 544.342834,96.838745 
	C540.783752,100.554634 537.091797,103.174942 531.845459,101.913223 
	C530.935791,101.694458 529.704407,101.739792 529.305542,102.894234 
	C528.937378,103.959839 529.788147,104.759895 530.422791,105.508644 
	C531.506287,106.786934 532.815308,107.794983 534.282532,108.618073 
	C541.305969,112.558067 541.626465,118.974243 534.743530,124.189636 
	C533.519348,124.463676 532.590027,124.463730 531.644348,124.786835 
	C531.778992,126.033890 532.960876,125.468102 533.885010,125.980530 
	C537.124939,127.285591 539.230164,129.335724 540.780945,132.460205 
	C541.731812,136.111923 541.737366,139.081146 537.789062,140.492020 
	C536.326355,141.014694 534.757446,141.913345 535.633362,143.684326 
	C536.422302,145.279465 537.963684,144.086166 539.149414,143.764587 
	C540.270752,143.460449 541.360840,143.070068 542.848511,143.602478 
	C544.362549,146.464996 543.128723,149.010239 542.508179,151.908310 
	C539.477173,154.201981 536.725159,151.470428 533.571472,152.155548 
	C530.150085,152.543625 528.157288,154.602768 525.802795,156.800018 
	C523.696106,157.674332 521.899170,157.681534 519.735168,157.026611 
	C517.224731,155.476776 516.829224,153.461136 517.666809,151.213150 
	C518.443176,149.129700 518.102356,147.657364 515.934204,146.814606 
	C512.440063,145.456421 510.737610,143.108994 510.905823,139.156326 
	C511.077911,135.111450 508.276306,136.167999 506.061798,136.964172 
	C503.298218,137.957703 500.810760,140.165558 497.037384,138.037231 
	C497.732056,140.673859 498.601746,142.650452 498.288910,144.773117 
	C498.031586,146.519531 497.396210,148.029800 495.575928,148.606766 
	C493.676178,149.208908 492.400818,148.189362 491.367920,146.730927 
	C490.982178,146.186310 490.693573,145.584778 490.355591,145.012405 
	C488.808899,142.393066 488.130737,138.034317 484.287628,138.742615 
	C480.268860,139.483307 474.914062,139.100082 473.442383,144.889587 
	C473.244720,145.667130 472.451233,146.245209 472.117493,146.984756 
	C471.392059,148.592377 467.729706,149.942139 471.230042,152.033844 
	C473.838623,153.592651 476.526978,154.625061 478.897522,150.950348 
	C481.818848,146.421677 484.431732,145.895187 489.536163,147.442596 
	C492.845673,148.445877 493.678589,150.494431 492.582245,153.547348 
	C491.277313,157.181183 489.160950,159.854904 485.050812,160.746017 
	C481.003479,161.623505 477.055878,162.854568 473.227448,164.603409 
	C468.174835,166.911484 464.933899,165.429520 462.408966,160.335770 
	C462.040436,159.592331 461.755402,158.795227 460.665192,158.343506 
	C458.787994,159.742096 459.493439,161.852203 459.422333,163.714157 
	C459.036957,173.800034 451.068817,178.424149 442.036530,173.775040 
	C441.149719,173.318588 440.286163,172.817642 439.389740,172.376282 
	C436.676971,171.040558 435.284607,171.604263 434.481537,174.809738 
	C432.601807,182.312912 426.704498,184.225098 420.623199,179.433990 
	C419.607819,180.964355 420.612030,182.267258 421.007050,183.595016 
	C421.526031,185.339493 422.506165,187.274536 420.407898,188.510742 
	C418.450195,189.664139 416.540222,188.896881 414.922150,187.474014 
	C413.739624,186.434158 412.951477,184.563080 410.498260,185.765594 
	C404.591003,185.658798 398.764526,184.301910 394.508820,189.706375 
	C394.089386,190.239029 392.943817,189.900787 391.820831,189.437729 
	C389.155365,186.800720 388.740997,183.659195 388.282593,180.137497 
	C388.017456,177.611160 386.077942,175.561401 388.146057,173.052200 
	C392.112854,171.042328 392.493958,175.753571 395.087891,176.253693 
	C396.268127,175.223404 395.221375,174.211807 394.974792,172.870331 
	C393.946259,168.905518 396.270233,166.761078 399.648834,164.578903 
	C396.132324,163.882584 394.583313,165.579819 392.782959,166.550842 
	C388.341736,168.946259 385.135925,167.736847 383.440582,162.980728 
	C382.826721,161.258667 382.525177,159.454666 382.286133,157.642441 
	C381.893219,154.663666 382.763641,152.716431 386.540894,152.858658 
	C388.136383,153.243500 389.117462,154.012894 390.417908,154.947723 
	C393.624481,157.861954 396.606903,155.855728 399.022156,154.663147 
	C402.700500,152.846832 404.815918,153.247787 406.483185,157.066711 
	C407.773621,160.022476 410.397125,161.939209 413.559967,161.665771 
	C418.788025,161.213806 420.842316,163.644043 421.316193,168.405304 
	C421.425842,169.506729 421.607697,170.722641 422.704529,171.703964 
	C420.171661,167.406235 420.409424,163.141479 422.219971,158.763092 
	C423.330811,156.076828 421.231506,155.618439 419.319153,155.522507 
	C413.679993,155.239624 408.708588,153.261658 404.124542,150.046661 
	C402.579346,148.962952 400.886017,148.061340 401.320618,145.836151 
	C401.782532,143.471069 403.712067,142.841461 405.784485,142.558762 
	C407.435303,142.333588 409.101746,142.394638 410.762909,142.387161 
	C420.236267,142.344574 420.196930,142.282272 427.635223,137.658264 
	C429.308929,136.617798 429.191956,135.751999 428.171021,134.539490 
	C427.424591,133.652969 426.455139,132.953796 425.852966,131.944016 
	C423.787964,128.481094 425.266144,125.725487 429.315369,125.710297 
	C430.875977,125.704437 432.556000,126.348831 431.686829,123.423904 
	C431.176575,122.793671 430.890747,122.423820 430.414093,121.745880 
	C429.821625,120.828423 429.516235,120.167023 429.113861,119.147568 
	C428.939117,118.565643 428.886810,118.329117 428.804810,117.733101 
	C428.795654,116.167862 429.281281,115.169449 429.968475,114.182640 
	C430.948395,112.775467 433.861237,112.708305 433.201508,110.336754 
	C432.546814,107.983017 431.493896,105.660858 429.584290,103.925369 
	C428.848083,103.256310 428.085907,102.601860 427.244415,102.080788 
	C424.066956,100.113235 421.830536,97.670921 422.707947,93.561455 
	C423.553864,89.599533 425.221130,86.355606 429.680786,85.493576 
	C430.308411,85.372269 430.938171,85.065903 431.196045,83.994141 
	C430.751495,81.248787 429.692688,78.936584 429.593597,76.476112 
	C429.405273,71.798805 427.328156,69.129059 422.106323,68.878891 
	C420.879059,68.208862 420.155334,67.516823 419.551788,66.717728 
	C418.251984,64.996841 416.571564,63.265076 417.410065,60.910469 
	C418.340546,58.297638 421.024200,59.182247 422.976318,58.542122 
	C431.231903,55.834949 436.874176,60.788887 443.060883,65.341667 
	C444.740417,67.476089 444.580292,69.677513 444.668518,71.824463 
	C444.911530,77.739098 449.020813,81.359467 454.986389,80.837128 
	C456.808075,80.677620 458.556091,80.078064 460.337311,79.676338 
	C463.066071,79.060883 464.379700,77.492226 463.814331,74.662285 
	C462.807770,69.623756 463.913544,65.602158 468.553070,62.644386 
	C470.587555,61.347408 470.976196,54.014141 469.671631,51.797863 
	C468.835358,50.377178 467.932648,50.413113 467.011383,51.662792 
	C465.752045,53.371037 465.769348,55.399796 465.679962,57.390743 
	C465.536438,60.587395 465.532104,63.827595 461.696411,65.153931 
	C457.484406,66.610382 451.621887,65.531281 449.158081,62.792671 
	C448.382355,61.930439 447.973602,60.780289 446.753418,59.784439 
	C445.050293,56.494137 443.700653,53.449829 443.026825,49.786484 
	C443.978363,47.078457 444.589844,44.943481 442.970795,42.408569 
	C441.475464,40.067295 442.231628,37.899414 445.652832,37.163498 
	C449.973938,39.203384 454.338379,40.262547 458.385559,42.071491 
	C460.459900,42.998650 461.416443,42.562393 462.250549,40.702274 
	C462.930145,39.186798 463.813446,37.770100 464.580719,36.295372 
	C465.923187,33.715073 465.808533,31.936893 462.397675,31.352375 
	C459.093353,30.786114 458.756531,28.620399 459.578369,25.730684 
	C460.837280,21.303932 459.919281,19.221056 455.245605,18.230698 
	C448.286133,16.755970 443.728058,10.651318 436.691040,9.316938 
	C434.727386,8.944590 433.250732,6.539578 435.831970,3.995469 
	C443.887146,2.559497 451.602997,2.841752 459.710663,3.731015 
	C462.681030,6.462752 464.348022,9.822485 467.800537,11.735422 
	C471.778992,13.939738 473.731598,13.508304 475.062012,9.154664 
	C475.536652,7.601395 475.624756,5.911042 477.104187,4.370215 
	C483.160339,1.840794 489.210724,2.797257 495.106873,2.588009 
	C504.388489,2.258615 513.690186,2.501914 522.983398,2.499180 
	C525.135132,2.498547 527.316101,2.680322 529.647217,1.268817 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M343.626953,1081.869629 
	C345.399017,1086.579346 343.895996,1089.218750 339.179749,1089.950928 
	C336.703308,1090.335327 334.570160,1090.543213 337.563263,1093.280029 
	C339.128601,1094.711304 338.450378,1095.675049 337.509644,1097.080688 
	C336.047028,1099.265869 334.547241,1101.558105 333.754944,1104.026855 
	C332.776001,1107.077148 335.644623,1111.248169 336.614441,1111.501831 
	C343.210632,1113.226807 349.270844,1117.779297 356.646942,1116.027832 
	C357.753693,1115.765015 359.370300,1116.239380 359.900360,1115.190918 
	C361.998138,1111.041260 365.867126,1111.207642 369.613831,1110.522583 
	C373.363861,1109.837036 378.835785,1112.357910 379.587372,1115.715942 
	C380.757416,1120.943604 378.248535,1125.010864 373.194214,1125.933594 
	C369.660645,1126.578613 366.345734,1127.290283 362.656006,1125.333496 
	C359.429749,1123.622559 357.929260,1125.507202 358.893097,1129.175171 
	C359.354431,1130.930786 360.002106,1132.750610 359.922424,1134.512329 
	C359.740784,1138.530518 360.296387,1141.552612 365.390869,1141.137207 
	C366.403503,1141.054443 367.455078,1141.674805 367.491302,1142.845825 
	C367.547791,1144.671143 366.072205,1144.843140 364.716431,1144.982666 
	C363.308563,1145.127563 361.772614,1145.331177 362.392334,1147.252197 
	C362.954803,1148.995605 365.006531,1149.624512 366.050385,1148.661499 
	C369.220978,1145.736694 373.169220,1146.786255 376.721252,1146.066284 
	C380.890076,1145.221680 383.946991,1143.829834 384.097717,1138.917847 
	C384.166412,1136.680420 385.627014,1135.121460 387.971832,1135.175049 
	C390.363129,1135.229492 390.759979,1137.275635 390.939026,1139.118652 
	C391.572174,1145.634644 390.161163,1152.191162 393.108704,1158.774170 
	C393.927185,1160.602173 391.653137,1167.136475 387.211151,1168.550293 
	C382.859894,1169.935303 378.217285,1170.921265 373.496429,1169.363892 
	C372.005035,1168.871704 370.003204,1167.133545 368.742096,1169.733643 
	C367.684814,1171.913574 367.044373,1174.800049 369.154938,1176.177246 
	C372.414795,1178.304321 371.989319,1181.149292 371.986023,1184.087524 
	C371.983063,1186.762085 371.901581,1189.407593 373.395874,1191.824585 
	C375.452911,1195.151611 375.873291,1198.354370 372.408691,1201.138428 
	C372.034912,1201.438721 371.474670,1202.162231 371.566040,1202.292236 
	C376.202057,1208.889160 366.438507,1211.030151 367.008179,1216.904907 
	C367.254517,1219.445068 366.909088,1220.743042 369.443817,1221.257202 
	C378.538513,1223.101929 384.257568,1228.793213 388.673309,1236.710815 
	C390.982025,1240.850342 394.658569,1244.580444 400.534546,1242.652710 
	C402.031921,1242.161377 403.326233,1242.327637 403.754181,1243.921753 
	C404.381897,1246.259888 406.082153,1247.427246 407.927734,1248.630859 
	C409.028168,1249.348267 410.082428,1250.304443 409.447845,1251.934448 
	C408.899048,1253.344238 407.890839,1254.012573 406.373291,1253.994751 
	C405.036591,1253.979126 403.696655,1254.006104 402.611847,1254.994263 
	C401.703003,1255.822021 401.003937,1256.699829 401.587067,1258.030640 
	C402.203888,1259.438354 403.175842,1260.201782 404.735535,1259.587891 
	C406.996613,1258.697632 409.347046,1259.005981 411.659790,1259.023560 
	C414.176880,1259.042725 415.699890,1258.266724 416.139832,1255.499268 
	C416.514771,1253.140381 417.515228,1250.832642 420.578461,1251.083252 
	C424.062256,1251.368408 422.980743,1254.204590 422.939148,1256.101318 
	C422.816071,1261.712891 423.518005,1266.522217 430.557251,1267.186523 
	C432.024139,1267.325073 432.457031,1268.950073 433.360474,1269.887329 
	C434.254791,1270.815186 435.536804,1271.889771 434.390961,1273.283691 
	C433.396240,1274.493530 432.264160,1273.525635 431.321747,1272.868896 
	C430.305359,1272.160522 429.201935,1271.973022 428.002380,1272.062012 
	C426.635254,1272.163452 425.836670,1272.986206 425.588776,1274.212646 
	C425.211578,1276.078613 426.794434,1275.630859 427.798248,1276.027466 
	C434.311829,1278.600464 441.052734,1276.128540 447.623505,1277.020874 
	C451.505859,1277.547974 455.468201,1277.901489 458.911255,1280.642212 
	C438.979095,1281.000000 418.958221,1281.000000 398.468658,1281.000000 
	C392.257324,1278.922852 386.233673,1280.089233 380.357971,1279.650757 
	C375.898834,1279.318115 372.112427,1278.848022 370.141754,1274.282471 
	C369.325348,1272.391113 367.432922,1272.002808 365.835938,1271.155518 
	C361.803436,1269.016113 361.246674,1266.379028 363.970123,1262.675293 
	C364.757172,1261.604980 365.857941,1260.773682 366.564575,1259.657349 
	C367.987091,1257.409790 368.279053,1255.748169 365.178436,1254.179199 
	C361.457275,1252.296265 357.855621,1250.507568 353.304810,1250.233887 
	C349.979980,1244.384277 342.611877,1242.966064 338.393555,1247.423706 
	C336.528961,1249.394165 334.584961,1249.172607 332.526031,1248.639526 
	C328.903107,1247.701416 325.359894,1247.662964 321.406555,1248.938232 
	C318.165344,1248.883911 315.332214,1248.774780 312.331604,1250.007080 
	C306.524994,1246.222778 306.510651,1246.024414 311.685303,1240.600098 
	C304.585602,1239.676636 299.551422,1234.656860 293.161407,1232.563599 
	C291.315460,1231.958862 290.213745,1230.553101 289.375641,1228.816528 
	C287.869995,1225.696655 285.374756,1223.564453 282.248962,1222.116211 
	C276.300507,1219.359863 272.677856,1221.034058 272.139954,1227.438477 
	C271.810883,1231.357300 270.020172,1232.246948 266.688599,1232.016235 
	C265.196136,1231.912842 263.688843,1231.942749 262.191833,1232.001831 
	C259.238708,1232.118286 257.923706,1230.736816 257.988098,1227.793457 
	C258.042664,1225.298706 258.258636,1222.733643 257.790833,1220.318237 
	C257.013458,1216.304443 253.201172,1214.172363 248.816452,1214.850708 
	C240.654663,1216.112915 232.177185,1207.186157 234.219650,1199.485107 
	C234.557510,1198.211304 235.601929,1197.763916 236.458832,1198.451294 
	C240.080902,1201.356567 246.785950,1199.983521 247.710602,1206.810791 
	C247.918777,1208.348022 249.830963,1210.353638 251.555389,1210.375488 
	C254.194504,1210.408813 251.738617,1207.861938 252.635773,1206.944580 
	C254.392273,1205.148560 256.713074,1205.927856 258.726837,1206.231445 
	C260.775238,1206.540039 262.938446,1206.973267 263.080933,1210.046387 
	C263.178711,1212.154541 261.765442,1216.110840 264.247375,1216.051880 
	C267.309845,1215.979126 265.414154,1211.944092 266.078308,1209.735718 
	C266.652740,1207.825806 267.288574,1205.934326 267.867432,1204.025757 
	C268.574463,1201.694580 270.397003,1201.115723 272.522919,1201.048828 
	C274.630615,1200.982544 275.555664,1202.448364 275.803619,1204.159058 
	C276.150513,1206.552246 277.513977,1207.053955 279.618958,1207.024658 
	C284.042267,1206.962891 285.708405,1209.321655 284.623047,1213.460205 
	C284.125610,1215.356812 284.056793,1216.929199 286.143311,1217.444946 
	C289.161224,1218.190918 292.159027,1218.511108 294.857056,1220.557129 
	C296.345123,1221.685547 297.541199,1219.516479 297.705200,1218.083496 
	C298.118134,1214.474854 298.898468,1210.648071 296.573456,1207.432617 
	C295.051575,1205.327759 295.439026,1204.080933 297.081879,1202.335083 
	C300.828247,1198.354248 301.017670,1192.055908 297.886139,1187.804321 
	C297.009888,1186.614624 296.008087,1185.922974 294.536560,1186.124023 
	C293.000793,1186.333740 292.134094,1187.326294 292.080231,1188.821167 
	C291.999786,1191.052856 291.354126,1192.601318 288.858704,1193.119385 
	C286.254639,1193.659912 286.878326,1196.013916 287.067749,1197.648193 
	C287.557922,1201.877075 285.194733,1203.644775 281.647369,1203.718018 
	C278.226868,1203.788574 277.211212,1201.455322 276.859741,1198.075073 
	C276.359039,1193.260132 273.623749,1188.645752 276.710449,1183.676758 
	C277.369415,1182.615967 275.923248,1180.752808 274.380371,1181.522583 
	C267.343781,1185.033936 265.570892,1179.054810 263.369110,1175.104614 
	C261.144196,1171.112793 259.136749,1166.674561 257.880341,1162.473022 
	C256.758972,1158.723022 255.230652,1154.128174 256.804688,1149.623779 
	C257.505280,1147.618896 258.035065,1145.788086 260.193024,1145.126343 
	C262.044464,1144.558472 262.996887,1143.469727 263.002197,1141.532227 
	C263.006683,1139.892700 263.807953,1138.939819 265.227814,1137.989258 
	C268.630920,1135.711060 270.292786,1128.284546 268.272888,1124.231079 
	C267.900116,1123.748779 267.800262,1123.497681 267.888428,1122.888916 
	C271.391296,1119.859253 275.216797,1119.439819 279.335144,1119.613281 
	C286.575531,1119.918213 287.614960,1118.862915 286.995636,1111.767090 
	C286.660492,1107.927246 287.519379,1105.244263 291.020569,1102.988159 
	C295.616974,1100.026245 295.217773,1093.220459 290.706482,1090.133789 
	C286.709503,1087.399048 286.932739,1078.084839 291.107574,1075.785400 
	C293.264771,1074.597168 294.862000,1075.757202 295.289703,1077.757080 
	C296.726196,1084.473511 301.513519,1085.619751 307.286011,1085.506958 
	C313.030243,1085.394653 318.717163,1086.006714 324.133331,1082.757202 
	C328.076477,1080.391357 332.891388,1081.425293 337.333221,1081.804810 
	C339.336670,1081.976074 341.282318,1082.081177 343.626953,1081.869629 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1178.649658,854.040100 
	C1179.744019,854.003662 1180.495972,854.000000 1181.624023,853.996948 
	C1183.543945,855.833679 1182.707886,857.787415 1182.146118,859.637573 
	C1181.551880,861.595032 1181.541504,863.457581 1181.891235,865.473999 
	C1182.887695,871.218384 1181.695068,872.349609 1175.154053,871.855591 
	C1172.042603,871.729614 1171.806396,874.084900 1171.356567,875.787903 
	C1170.527100,878.927185 1169.260620,881.889465 1168.057373,885.279907 
	C1167.748291,885.826660 1167.597290,886.032227 1167.149414,886.486206 
	C1164.854736,887.747375 1162.470337,887.767639 1160.779175,889.698792 
	C1159.736694,890.889099 1157.844849,891.453369 1156.203369,891.383545 
	C1150.833496,891.155457 1146.063477,892.107422 1142.737915,896.866211 
	C1142.288574,897.509277 1141.285889,898.079285 1140.558960,897.925842 
	C1134.374268,896.619446 1127.819580,898.236389 1121.568604,895.263489 
	C1119.047119,887.089539 1113.235962,882.933960 1105.560303,880.304199 
	C1103.102539,879.676941 1102.399048,877.502869 1100.847900,876.395630 
	C1098.062256,874.407227 1095.244629,875.702515 1091.125977,880.519653 
	C1088.193970,881.170654 1085.807129,881.982666 1083.657471,883.801819 
	C1078.871582,887.851807 1072.291870,889.335022 1068.198486,894.456482 
	C1067.566162,895.247620 1066.428955,895.866638 1065.524536,895.393311 
	C1063.325684,894.242676 1062.659668,894.972473 1061.660400,897.108582 
	C1060.209595,900.210388 1057.441406,899.296936 1055.307373,897.742432 
	C1052.031250,895.356018 1049.864868,896.025696 1048.275391,899.589905 
	C1047.442505,901.457581 1046.069702,902.887207 1044.451172,904.121216 
	C1042.962036,905.256531 1041.508911,906.612732 1041.800903,908.644897 
	C1042.532715,913.738892 1040.024780,914.891113 1034.576050,913.251648 
	C1039.685181,916.957764 1041.959839,916.772034 1044.684448,913.293945 
	C1046.331299,911.191589 1049.371826,911.763062 1050.752686,914.453552 
	C1051.290894,915.502441 1051.204834,916.739075 1051.687744,917.809937 
	C1051.902832,918.286560 1052.261353,918.636658 1052.804199,918.604736 
	C1053.597656,918.558105 1054.050415,918.002441 1054.004028,917.287537 
	C1053.863647,915.131531 1052.304321,912.429382 1056.133667,911.908447 
	C1058.138062,911.635803 1059.833496,912.386353 1060.677246,914.259521 
	C1061.927490,917.035339 1061.731689,919.774475 1059.823364,922.257935 
	C1059.098267,923.201599 1058.033325,923.717773 1057.049072,924.345093 
	C1052.977051,926.940735 1050.758301,933.201538 1052.500732,937.781860 
	C1053.378906,940.090088 1053.613647,942.230530 1052.782104,944.558899 
	C1052.366211,945.723633 1050.445312,947.385620 1053.173584,948.010864 
	C1055.203247,948.476074 1057.427856,948.016052 1058.029419,945.654480 
	C1058.559937,943.571960 1058.203735,941.128540 1061.228882,940.856873 
	C1064.075684,940.601196 1066.471313,941.509033 1068.209595,943.883118 
	C1068.601074,944.417786 1068.950317,945.214172 1069.548462,945.314575 
	C1075.054443,946.238525 1075.339844,949.876770 1075.214111,954.552734 
	C1075.085571,959.341797 1079.462036,958.044373 1082.369751,958.524841 
	C1085.482788,959.039307 1086.662842,957.478027 1086.647461,954.627197 
	C1086.626953,950.850525 1086.621948,947.072571 1086.663574,942.160400 
	C1084.546997,944.621582 1085.624268,946.321960 1085.752930,947.895813 
	C1085.989868,950.791504 1085.478882,953.354431 1082.182739,954.108093 
	C1079.050293,954.824341 1077.139648,953.200623 1076.004028,950.384460 
	C1074.178833,945.858032 1071.246216,942.010925 1067.825317,938.671509 
	C1065.048096,935.960510 1064.018555,933.583679 1067.242798,930.771484 
	C1069.300903,928.976501 1068.817627,927.492432 1067.264404,925.715332 
	C1065.376465,923.554993 1063.963013,921.080017 1063.780396,918.131348 
	C1063.647705,915.989197 1064.243042,914.061707 1066.562256,913.396484 
	C1068.763306,912.765259 1070.149658,914.149719 1070.823242,915.949707 
	C1071.864136,918.731689 1073.668335,920.308350 1076.578857,920.792664 
	C1077.741089,920.986084 1078.810669,921.626404 1079.532593,922.559814 
	C1081.851074,925.557556 1084.732300,925.787781 1088.010376,924.501160 
	C1089.595581,923.878845 1091.222046,923.594543 1092.870483,924.291687 
	C1096.589722,925.864563 1096.597168,929.001160 1092.685669,933.181580 
	C1097.020508,936.022888 1097.925659,940.054382 1096.644409,944.867432 
	C1095.909424,947.629639 1096.957153,950.115112 1098.403687,952.433777 
	C1099.185791,953.687439 1100.352539,954.203430 1101.811279,954.020813 
	C1103.426636,953.818604 1103.998413,952.645142 1104.228394,951.254211 
	C1104.712036,948.330444 1104.550049,945.076355 1108.791504,944.663086 
	C1113.888184,944.166565 1120.085449,946.731506 1121.867310,950.294617 
	C1122.464233,951.488647 1122.714478,952.817932 1123.202393,954.057617 
	C1125.207886,959.153625 1130.671265,962.121399 1135.979614,961.126648 
	C1137.643921,960.814636 1138.158691,959.761597 1138.327515,958.358643 
	C1138.618652,955.940491 1137.807861,954.122192 1135.338135,953.334106 
	C1133.881226,952.869263 1132.331909,952.444946 1132.344727,950.523926 
	C1132.358154,948.521179 1133.877808,948.198608 1135.673462,948.015137 
	C1134.076538,947.599854 1132.562622,947.717834 1131.303223,946.768066 
	C1128.953857,944.996338 1128.858032,942.851135 1131.532471,941.949158 
	C1134.460815,940.961670 1135.904175,939.496277 1136.113892,936.362854 
	C1136.250977,934.316528 1137.730469,933.017944 1139.988647,933.585754 
	C1144.710571,934.772949 1149.392822,936.050537 1154.260498,936.638672 
	C1156.544434,936.914612 1157.198364,940.860229 1154.774902,942.799805 
	C1151.150391,945.700928 1150.581421,949.779114 1149.180298,953.596680 
	C1148.694824,954.919067 1147.495117,956.568298 1149.419189,957.520081 
	C1151.078247,958.340942 1151.575195,956.478149 1152.359497,955.512146 
	C1152.883667,954.866516 1153.384644,954.202026 1154.230591,953.336609 
	C1156.519409,951.770630 1158.531860,951.964661 1160.867920,953.282715 
	C1161.712280,954.106567 1161.999878,954.807495 1162.197876,955.923889 
	C1161.640625,959.138611 1163.594482,960.553528 1165.735107,962.230713 
	C1168.542969,964.625549 1171.664795,966.569702 1168.967285,970.388977 
	C1170.164795,971.906311 1171.381348,970.965515 1172.432617,971.167969 
	C1175.428101,971.744873 1176.240356,973.460388 1174.758789,976.107849 
	C1172.677734,979.826599 1173.340454,981.422119 1177.562500,982.178223 
	C1182.992554,983.150635 1187.809204,982.395508 1190.782227,976.502075 
	C1191.968140,975.786560 1192.939819,975.663391 1194.305908,975.720154 
	C1195.657959,975.953735 1196.575684,976.338013 1197.332886,976.993164 
	C1199.321777,978.713867 1200.912231,979.101318 1202.271851,976.182800 
	C1203.096802,974.411499 1204.821533,973.682983 1207.188965,974.098999 
	C1207.733643,974.332275 1207.936401,974.458313 1208.367432,974.859741 
	C1210.179443,978.029053 1213.010498,978.141113 1215.588257,975.134338 
	C1216.835083,973.679810 1217.973267,972.357910 1219.986084,972.244751 
	C1223.585938,972.042297 1225.726562,974.619385 1224.593262,978.083557 
	C1223.467407,981.524597 1225.885986,982.554321 1228.101074,983.587769 
	C1231.994629,985.404358 1235.277222,987.450684 1233.737061,992.593018 
	C1233.523193,993.306824 1233.489868,993.930176 1234.398804,994.073120 
	C1239.699463,994.906860 1238.061035,998.042053 1236.740723,1001.078735 
	C1236.412476,1001.833618 1236.004517,1002.573486 1236.407349,1003.892578 
	C1239.066895,1003.510254 1240.726562,1000.150574 1244.115112,1001.049805 
	C1244.675537,1001.242615 1244.889404,1001.348083 1245.378296,1001.682373 
	C1246.350830,1002.833008 1246.965210,1003.812317 1247.538452,1004.811707 
	C1249.049683,1007.446533 1249.281616,1009.546082 1245.381958,1009.980530 
	C1243.890381,1010.146667 1242.138550,1010.387573 1242.745483,1012.581421 
	C1243.312378,1014.630432 1244.821289,1014.180725 1246.347168,1013.657776 
	C1250.694824,1012.167786 1255.456787,1012.210205 1260.005737,1010.061462 
	C1266.364624,1011.576599 1271.886841,1014.566589 1278.331543,1015.952637 
	C1279.671387,1016.582520 1279.953857,1017.615112 1280.751221,1018.719849 
	C1281.000000,1019.444458 1281.000000,1019.888916 1281.000000,1020.666687 
	C1279.568115,1022.338623 1280.031860,1024.099854 1280.018188,1025.714966 
	C1279.969971,1031.376587 1279.966187,1037.038940 1280.010498,1042.700562 
	C1280.044312,1047.024902 1280.410522,1051.269043 1276.473999,1054.584351 
	C1274.861450,1055.942383 1275.064941,1058.579712 1275.090698,1060.765259 
	C1275.112915,1062.656372 1275.025513,1064.982422 1273.255005,1065.637451 
	C1269.788208,1066.920166 1266.404297,1067.869019 1265.242310,1072.015747 
	C1265.061401,1072.661133 1263.681885,1072.830688 1262.973999,1072.332886 
	C1259.831665,1070.122437 1256.229248,1068.758911 1252.980469,1066.767822 
	C1251.644775,1065.949219 1250.890747,1064.572510 1251.359131,1062.899780 
	C1251.794678,1061.344604 1253.140503,1061.044312 1254.506592,1060.919556 
	C1258.698730,1060.536621 1262.012451,1058.765137 1264.312622,1055.127197 
	C1262.983765,1053.952148 1261.500244,1054.098389 1260.336792,1054.282959 
	C1253.575562,1055.355957 1249.102661,1050.864868 1244.154907,1047.709595 
	C1241.450073,1045.984619 1241.447388,1038.689087 1243.779907,1035.938110 
	C1246.549194,1032.671997 1249.114014,1031.207520 1253.602417,1030.403442 
	C1251.468872,1029.212402 1249.203491,1028.546631 1247.478760,1026.926025 
	C1246.568848,1026.071045 1245.369141,1025.610474 1244.317261,1026.525269 
	C1241.317017,1029.134521 1237.990479,1027.853760 1234.761963,1027.437378 
	C1232.292725,1027.119019 1230.004272,1026.161011 1230.256226,1023.155029 
	C1230.488037,1020.389771 1232.671143,1019.401733 1235.158569,1019.014832 
	C1236.319946,1018.834106 1237.418335,1019.550415 1238.712402,1019.213074 
	C1238.005981,1015.629028 1235.556396,1014.020325 1231.948242,1013.159912 
	C1231.406982,1012.916687 1231.199219,1012.800964 1230.714355,1012.461182 
	C1228.494873,1009.270569 1225.002197,1007.349854 1224.592529,1003.328857 
	C1224.456299,1001.990479 1223.144287,1001.188232 1222.051758,1001.499573 
	C1217.384277,1002.829834 1213.659424,999.307251 1208.913086,999.403625 
	C1209.000122,1001.047058 1208.151001,1002.862732 1209.814331,1004.789917 
	C1213.075073,1009.099365 1216.426147,1012.658325 1222.269897,1013.241882 
	C1224.757202,1015.084045 1226.920288,1016.966064 1224.843018,1019.852905 
	C1223.017456,1022.389954 1220.261108,1022.782227 1217.413940,1021.509705 
	C1216.374023,1021.044983 1215.399658,1020.463623 1213.874634,1020.263245 
	C1212.145142,1019.879333 1211.099365,1018.215027 1209.229736,1019.280945 
	C1209.375244,1022.931519 1215.190674,1022.704712 1214.384277,1027.142090 
	C1210.053223,1032.092407 1205.555054,1029.530518 1201.550293,1027.516602 
	C1197.418945,1025.439087 1194.288452,1024.820312 1191.388794,1029.264038 
	C1190.009033,1031.378418 1187.866577,1030.949097 1186.381958,1029.064331 
	C1183.799927,1025.786255 1181.735107,1026.789307 1179.724365,1029.609131 
	C1178.858765,1030.822876 1178.005981,1032.047363 1177.100464,1033.231445 
	C1175.609253,1035.181396 1173.797119,1037.130737 1171.402588,1034.923828 
	C1168.926636,1032.641846 1171.567993,1031.232056 1173.036133,1029.418701 
	C1173.260864,1028.201904 1172.913940,1027.375854 1172.648071,1026.196167 
	C1170.187988,1022.370972 1166.348877,1020.252625 1164.663696,1016.034607 
	C1162.675293,1017.875183 1163.829224,1018.875854 1164.172485,1019.835266 
	C1164.790649,1021.563354 1164.976807,1023.194153 1163.166260,1024.199219 
	C1161.227783,1025.275391 1159.286865,1024.490601 1158.442627,1022.784485 
	C1156.679199,1019.220459 1155.137939,1015.730835 1151.247437,1013.779236 
	C1149.798340,1013.052307 1150.326538,1011.179932 1150.971436,1009.703369 
	C1153.231323,1004.528809 1153.210693,1003.320557 1147.718384,1002.638306 
	C1139.796509,1001.654114 1132.770874,999.251099 1126.387207,994.615173 
	C1125.882080,994.248291 1125.131348,994.211243 1124.192383,993.805725 
	C1121.803711,991.631348 1119.259399,990.282837 1116.667969,988.400085 
	C1114.544922,984.485901 1116.380493,981.486389 1118.670898,979.129944 
	C1120.687378,977.055237 1123.480347,977.328552 1126.032959,978.969666 
	C1128.043701,980.262390 1129.625122,978.807129 1130.892944,977.602478 
	C1133.607300,975.023254 1136.398682,973.391846 1140.299438,974.823486 
	C1142.135620,975.497375 1143.193237,974.706116 1142.931396,972.600830 
	C1142.516968,969.269043 1144.109985,967.204285 1146.982666,965.653381 
	C1144.546875,968.867004 1138.556152,966.324524 1137.794678,972.012756 
	C1137.591797,973.528564 1136.360596,974.117676 1135.065063,974.629761 
	C1132.760742,975.540466 1130.265381,976.088257 1128.441650,977.969299 
	C1128.214600,978.203369 1127.784424,978.474915 1127.561768,978.373596 
	C1121.933105,975.813721 1118.990479,979.966125 1114.845825,982.568604 
	C1108.619507,986.478027 1102.680298,982.641663 1101.526855,974.787537 
	C1101.396484,972.623352 1101.771729,970.837402 1102.008911,968.645630 
	C1101.170044,970.560669 1101.632935,972.228027 1101.325195,974.165527 
	C1101.325317,977.810059 1102.213745,981.127319 1100.752686,984.687500 
	C1098.569580,986.770874 1096.454956,986.280151 1094.615479,984.977600 
	C1091.369263,982.678772 1090.384277,984.282166 1089.667480,987.321228 
	C1088.967163,990.290649 1088.819336,993.872803 1084.101196,994.056580 
	C1082.374146,993.672058 1080.914429,992.227783 1079.473145,994.573120 
	C1077.593018,996.021667 1076.078247,995.902954 1074.464355,994.162048 
	C1073.434692,992.675964 1073.781494,990.695923 1071.529175,990.132019 
	C1070.058228,989.730469 1069.159668,989.986816 1069.446655,991.377686 
	C1069.686279,992.538696 1070.841064,993.147888 1071.594849,993.998474 
	C1072.967407,995.547241 1073.355835,997.211121 1071.740356,998.773926 
	C1070.264404,1000.201782 1068.746582,999.589905 1067.326416,998.509766 
	C1066.418457,997.819275 1065.811523,996.770203 1064.332886,996.155273 
	C1062.917114,998.289856 1062.401367,1001.960144 1058.086426,1000.942627 
	C1056.946167,1000.614563 1056.189697,1000.265808 1055.432617,999.922363 
	C1049.285889,997.134094 1048.789917,995.228149 1052.922241,990.172974 
	C1050.322388,989.313538 1049.864502,991.292725 1049.125244,992.948486 
	C1047.582397,996.404236 1044.850342,996.982910 1041.683350,995.628052 
	C1038.463989,994.250732 1038.654663,991.533813 1039.728149,988.856689 
	C1040.641235,986.579285 1041.286865,984.161133 1043.394775,982.468262 
	C1046.274170,980.155945 1043.561401,976.917480 1044.488647,974.236511 
	C1041.679321,973.265808 1040.207397,970.818481 1038.210938,968.887268 
	C1036.678711,967.405151 1034.749390,968.527527 1033.983398,970.615479 
	C1033.461914,972.037354 1033.575195,973.575378 1033.536621,975.068481 
	C1033.461914,977.963806 1032.435913,980.243774 1029.642090,981.475952 
	C1027.319214,982.500366 1026.062744,984.362610 1025.458008,987.117920 
	C1025.208862,988.369202 1025.117554,989.281128 1025.551514,990.537720 
	C1026.720581,994.247131 1027.348999,997.597351 1025.186523,1001.156494 
	C1023.653931,1002.168823 1022.125061,1002.389954 1021.164856,1001.408508 
	C1017.463501,997.625183 1013.462952,993.960693 1012.105896,988.555054 
	C1011.126038,984.651917 1012.779053,982.138977 1017.420166,981.437134 
	C1014.358643,978.356812 1012.168152,975.475647 1010.207642,972.437805 
	C1008.878906,970.379028 1007.476257,969.937500 1005.235779,971.180481 
	C999.135742,974.564697 992.244629,973.088562 985.739197,974.015320 
	C983.774963,974.295105 982.592773,972.573425 981.814270,970.735474 
	C978.939453,963.947388 978.694702,963.890381 972.506104,967.898621 
	C967.632080,969.272827 963.150391,970.078369 958.745056,967.337646 
	C957.192993,966.371948 955.194580,966.680847 953.387695,966.488647 
	C951.531555,966.291321 949.848999,965.805786 948.600037,964.227356 
	C947.272583,962.549866 945.221802,960.949463 946.599792,958.618042 
	C948.059021,956.148926 950.096008,958.089966 951.884338,958.548523 
	C956.201355,959.655396 960.816956,956.817810 965.057495,959.626587 
	C962.919739,958.682678 961.208252,957.075806 958.676208,957.070679 
	C952.209351,957.057556 952.209534,956.959839 952.038086,950.185913 
	C951.234863,943.236328 951.738403,942.492371 958.416077,940.206909 
	C961.865662,939.026306 961.266296,937.654114 958.280273,936.046387 
	C955.452698,933.906860 952.535400,933.642273 949.478455,933.850525 
	C947.907654,933.957520 946.413208,933.606567 945.757751,931.968384 
	C944.995300,930.062866 946.662720,929.507629 947.734497,928.490234 
	C951.594482,924.825989 956.120117,922.104126 960.582092,919.328247 
	C964.601562,916.827820 969.005920,915.420715 973.838806,916.795288 
	C975.763245,917.342712 977.765747,917.480225 979.694275,917.996277 
	C980.491028,918.209412 981.851013,918.741943 982.051819,918.303772 
	C984.734314,912.452515 990.692139,915.399963 994.843811,913.532959 
	C996.404968,912.830811 995.900146,912.288330 995.675720,911.408447 
	C994.941162,908.528137 996.797668,907.981201 999.312012,907.680176 
	C1003.567627,900.508362 1011.508850,899.423279 1017.717468,895.761841 
	C1020.719788,893.991333 1024.184937,894.684265 1027.493652,895.263062 
	C1030.216309,895.739319 1032.778198,895.809143 1034.514282,892.983704 
	C1035.222900,891.830505 1036.521240,891.107971 1037.898926,891.114014 
	C1040.079834,891.123657 1041.720215,890.631042 1042.569336,888.334167 
	C1043.001953,887.163696 1044.336426,886.755981 1045.517090,886.808838 
	C1052.342285,887.114014 1058.387451,883.075439 1064.809692,882.772339 
	C1075.302368,882.277100 1081.356812,875.407043 1088.276123,869.406372 
	C1093.410889,864.953430 1097.224731,865.014526 1103.340088,867.824768 
	C1111.886230,871.752075 1119.375244,876.917358 1126.213379,883.345703 
	C1130.264160,887.153809 1135.388184,888.682922 1141.332520,886.266052 
	C1142.133301,886.016907 1142.603516,885.932495 1143.431885,885.857788 
	C1147.730713,884.490479 1151.225830,881.881958 1155.794800,882.252563 
	C1157.643677,882.402466 1157.773682,879.866211 1158.929199,878.306030 
	C1159.274048,877.850647 1159.427368,877.681946 1159.841675,877.297974 
	C1161.881226,876.102051 1163.215454,874.755127 1163.816162,872.395142 
	C1164.647949,870.394165 1165.654175,868.872864 1167.342896,868.111450 
	C1170.175293,866.834473 1171.239868,864.384827 1172.174561,861.366943 
	C1173.547607,859.713989 1174.740234,858.354004 1175.243652,856.179260 
	C1176.199097,854.983887 1177.182007,854.414917 1178.649658,854.040100 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M444.767029,1116.297119 
	C445.257690,1115.503784 445.515350,1115.007690 445.886505,1114.255737 
	C447.405426,1112.027588 449.775909,1113.510498 451.722107,1112.239746 
	C452.953003,1108.448853 451.046295,1106.273804 447.080963,1107.327393 
	C443.739716,1108.215210 441.384888,1107.628906 438.953461,1105.421753 
	C436.989075,1103.638672 434.787140,1103.120117 433.162964,1105.989380 
	C431.274872,1109.324585 428.665833,1108.468262 426.476990,1106.788330 
	C423.932037,1104.834961 421.589813,1104.071289 419.747711,1107.077515 
	C417.684448,1110.444580 420.287598,1111.826782 422.975281,1112.838745 
	C424.059143,1113.246704 425.427307,1113.412476 425.536102,1114.829346 
	C425.674408,1116.631104 423.563171,1116.401855 422.919617,1117.275635 
	C419.581665,1121.808105 414.860840,1120.988403 410.281311,1121.008301 
	C407.963226,1121.018311 406.928589,1120.033325 406.999146,1117.716064 
	C407.065063,1115.552979 407.110931,1113.379395 406.978638,1111.221924 
	C406.704620,1106.754639 408.674866,1104.706177 413.179199,1104.973511 
	C415.183289,1105.092285 417.412781,1105.161133 417.862823,1102.444580 
	C418.241302,1100.160034 417.603729,1098.177002 415.531006,1096.813232 
	C411.076477,1093.882568 407.358856,1089.362061 400.976532,1090.872559 
	C399.140198,1091.307129 397.504578,1089.588989 396.228760,1088.422241 
	C393.539429,1085.962646 391.048309,1083.530151 387.349182,1082.392944 
	C384.695343,1081.577026 384.967834,1078.742920 385.012787,1076.366333 
	C385.060150,1073.862183 386.855469,1072.229614 388.723572,1072.406860 
	C396.846130,1073.177368 399.713226,1065.208130 405.709625,1062.718262 
	C410.489868,1060.733276 415.236450,1057.699707 420.884186,1059.408447 
	C424.652496,1060.548584 427.758453,1060.456421 429.241425,1055.910645 
	C429.838287,1054.081055 431.423492,1053.864014 433.443542,1053.451904 
	C437.864136,1052.550049 442.554230,1053.979980 446.710297,1051.803467 
	C448.296997,1050.972534 449.204041,1051.752563 450.199615,1052.677734 
	C451.553406,1053.935791 453.085724,1054.440430 454.731934,1053.405640 
	C456.105347,1052.542358 455.668884,1051.235962 455.511871,1049.846313 
	C454.996155,1045.281738 458.945190,1045.359985 461.514771,1045.181152 
	C464.862000,1044.948364 467.687714,1044.470093 469.873413,1041.715698 
	C470.448608,1040.990845 471.521759,1040.176147 472.338226,1040.204956 
	C478.870331,1040.433472 484.248596,1036.345947 490.736633,1035.258545 
	C492.290802,1033.462891 493.998810,1033.505493 496.164062,1033.845825 
	C498.153320,1034.656860 499.590698,1035.725586 501.553741,1035.181396 
	C502.994202,1034.782104 504.447266,1034.967041 505.331818,1036.915527 
	C504.802124,1039.472046 503.369781,1041.171875 501.282593,1041.210938 
	C494.688507,1041.334473 488.988342,1045.316772 482.028809,1045.335449 
	C478.360382,1044.977661 475.353119,1045.398438 472.999359,1048.077271 
	C472.345856,1048.820923 471.384155,1049.364502 470.413513,1049.533691 
	C466.503510,1050.214722 464.386749,1052.186035 463.986633,1056.405640 
	C463.712738,1059.293823 461.475403,1060.739624 458.397125,1061.160889 
	C451.613525,1062.089355 448.604675,1067.642578 451.353851,1073.717529 
	C451.765839,1074.627930 452.004822,1075.704224 452.652832,1076.406494 
	C456.648346,1080.736694 459.264709,1084.983887 458.164978,1091.697388 
	C457.446716,1096.082275 462.890717,1099.342407 465.042877,1103.449951 
	C465.627472,1104.565552 467.461212,1104.295898 468.793915,1104.299805 
	C470.825073,1104.305908 472.709930,1103.586060 474.654419,1103.137329 
	C480.286835,1101.837158 483.799683,1104.396851 483.254364,1110.036621 
	C482.800232,1114.733032 483.744049,1117.698486 488.671234,1119.067993 
	C492.488373,1120.128906 493.062714,1123.612915 492.348877,1126.833008 
	C491.038879,1132.742310 493.535187,1136.280640 498.423462,1139.061157 
	C501.696991,1140.923096 502.715057,1147.305908 501.118103,1152.834229 
	C500.792358,1153.961914 499.847382,1154.802856 499.749237,1155.994019 
	C499.985840,1156.306519 500.278473,1156.461914 500.640259,1156.427612 
	C502.719879,1156.230591 505.621704,1157.328613 506.618958,1155.268433 
	C507.683868,1153.068604 506.705902,1150.061523 505.027039,1148.284790 
	C499.440155,1142.371704 500.258087,1136.060303 502.940704,1129.306763 
	C504.198334,1126.140747 505.262634,1125.618652 507.895050,1127.879517 
	C509.888977,1129.592041 511.312714,1131.990845 514.505310,1132.811035 
	C509.878632,1129.411499 510.942444,1125.377930 512.852661,1121.396362 
	C515.078369,1116.757446 518.242004,1112.862183 523.519897,1111.703003 
	C526.913940,1110.957520 529.652954,1112.567017 531.595703,1115.487671 
	C536.426208,1122.750000 535.128052,1129.001831 526.547607,1135.905396 
	C530.080505,1135.072632 532.744263,1134.729126 534.478088,1137.450684 
	C535.971619,1139.795288 536.468079,1142.333618 536.395874,1145.069946 
	C536.242798,1150.869385 536.339844,1150.968018 542.061157,1150.993408 
	C544.067627,1151.002319 546.047119,1151.283447 548.041504,1151.387085 
	C551.984375,1151.592041 554.753662,1153.254517 555.504272,1157.376099 
	C555.975525,1159.963623 557.119873,1160.809082 559.633789,1159.464478 
	C562.910828,1157.711792 566.214661,1158.777954 569.286804,1160.021484 
	C572.287964,1161.236450 573.502625,1163.775146 572.504944,1166.964233 
	C571.432190,1170.393433 568.938965,1169.790405 566.415894,1168.837891 
	C565.636536,1168.543823 564.842407,1168.279907 564.014587,1168.162476 
	C562.845886,1167.996582 561.467468,1167.764893 560.945190,1169.075317 
	C560.516541,1170.150635 561.685303,1170.791382 562.413208,1171.427979 
	C564.433533,1173.194824 566.752441,1174.823730 565.014160,1178.151123 
	C565.008606,1178.291626 565.065430,1178.036865 565.462585,1177.835571 
	C571.471436,1174.791870 577.122131,1170.938354 583.152710,1168.171387 
	C588.576843,1165.682861 595.027405,1167.832886 600.773499,1169.352173 
	C604.386169,1170.307129 605.079468,1174.133301 605.452271,1177.533569 
	C605.776611,1180.491943 605.511353,1183.509399 607.065247,1186.283936 
	C608.100708,1188.132690 607.314209,1189.487183 605.286072,1190.056030 
	C604.124512,1190.381836 603.008911,1190.718262 602.919006,1192.564697 
	C602.705078,1194.110229 602.203369,1195.235229 601.272949,1195.933716 
	C598.627625,1197.919678 598.402344,1200.256470 599.126953,1203.389404 
	C600.652771,1209.986694 596.163086,1213.840332 591.254456,1218.009399 
	C595.489685,1220.079102 597.885559,1223.208130 597.395020,1227.922241 
	C597.307678,1228.762207 597.456787,1229.724487 598.214844,1230.167969 
	C601.892273,1232.319336 602.098083,1236.039429 602.591064,1240.907715 
	C604.268982,1235.607666 603.563293,1231.214722 604.645447,1227.102905 
	C605.391663,1224.267700 606.831421,1222.471436 609.987610,1223.745972 
	C612.070740,1224.587280 612.892456,1226.139404 612.210876,1228.575317 
	C610.850464,1233.437744 610.959534,1233.462769 615.542908,1232.405518 
	C616.758850,1232.125000 617.844116,1232.474976 619.067627,1233.456055 
	C620.049316,1235.071533 619.450928,1236.219971 618.827881,1237.438232 
	C618.312622,1238.445679 616.522888,1239.794434 618.411194,1240.619263 
	C620.078308,1241.347778 622.064514,1240.640381 622.964233,1238.664185 
	C623.594299,1237.280273 623.374207,1235.731079 623.445007,1233.885742 
	C622.253357,1229.629395 618.890442,1227.931641 615.827637,1225.969727 
	C613.808960,1224.676758 611.684082,1223.402954 612.127258,1220.602051 
	C612.616089,1217.512329 615.280945,1216.932983 617.770874,1216.596802 
	C620.621948,1216.211792 623.236816,1215.497070 625.709106,1213.910767 
	C629.089478,1211.741821 632.696167,1212.165649 636.557495,1213.927734 
	C638.993835,1215.786987 638.983826,1218.108154 638.853149,1220.476074 
	C638.682190,1223.574097 640.060608,1224.391479 642.822327,1223.142090 
	C643.276123,1222.936768 643.731689,1222.733398 644.199341,1222.561157 
	C646.512878,1221.708984 648.610474,1221.932861 649.633362,1224.409546 
	C650.641418,1226.850220 649.521667,1228.868774 647.136536,1229.522339 
	C642.498779,1230.793213 640.849182,1234.141479 639.341736,1238.301880 
	C637.486511,1243.421875 634.165344,1247.914673 631.757446,1252.835815 
	C630.574219,1255.254028 628.116455,1255.437256 625.525879,1254.972900 
	C626.953430,1256.348145 628.639893,1255.703491 630.138916,1255.955933 
	C632.162354,1256.296753 633.319946,1257.244751 633.609558,1259.484009 
	C634.098145,1263.261841 635.743591,1264.118408 639.150818,1262.729736 
	C639.612427,1262.541748 640.058777,1262.318481 640.471619,1262.037109 
	C644.887146,1259.026123 648.822998,1259.714844 652.551147,1263.858887 
	C650.271667,1257.901367 648.626160,1252.271973 654.286011,1247.138428 
	C658.675964,1245.682617 662.840515,1246.268433 666.953796,1246.250732 
	C671.206543,1246.232666 675.432495,1247.057373 679.611694,1245.147461 
	C681.747070,1244.171753 684.233521,1245.036621 686.321289,1246.101685 
	C689.270203,1247.605835 692.133911,1249.175415 695.527832,1249.563354 
	C697.219727,1249.756592 698.762756,1250.633789 698.713013,1252.727905 
	C698.667053,1254.662598 697.321045,1255.594849 695.682922,1256.227295 
	C693.979309,1256.884888 692.076782,1256.775269 690.052856,1257.779663 
	C685.601013,1259.754272 681.393066,1259.726929 677.078308,1258.980103 
	C673.325378,1258.330322 670.362366,1259.012939 669.003845,1263.358398 
	C668.185059,1265.977539 666.062256,1268.095093 664.700562,1270.972290 
	C663.528564,1272.687866 660.381958,1272.536987 661.138916,1274.877563 
	C661.803406,1276.932251 664.293152,1276.959351 666.240967,1277.162842 
	C669.353943,1277.488037 672.500793,1277.117065 675.608215,1277.430054 
	C677.608398,1277.631470 680.144958,1277.293945 680.212524,1280.705566 
	C657.645752,1281.000000 635.291565,1281.000000 612.468628,1281.000000 
	C611.022461,1278.280396 611.270996,1275.173828 608.373047,1273.126099 
	C603.103088,1269.402466 594.419128,1268.801758 588.489319,1272.136597 
	C584.742065,1274.243896 590.209717,1274.823364 589.018799,1276.737793 
	C586.556824,1277.216187 583.851379,1276.533203 582.415588,1274.670532 
	C579.365845,1270.713867 574.439087,1268.037720 573.892456,1262.241821 
	C573.581238,1258.942017 571.171997,1259.980225 569.392151,1260.863037 
	C565.122131,1262.980957 561.369446,1262.166382 557.860596,1259.238647 
	C555.718445,1257.451172 556.244873,1256.572998 558.689209,1255.549561 
	C561.643433,1254.312500 564.550903,1256.764526 567.465637,1255.170898 
	C568.650635,1254.523193 570.551147,1255.215332 570.911499,1253.089355 
	C571.296997,1250.814819 570.023926,1249.202637 568.637878,1247.805786 
	C567.144104,1246.300415 565.880371,1247.645508 564.825806,1248.682739 
	C559.777100,1253.648560 553.663940,1252.739624 547.560547,1251.865967 
	C545.766418,1251.609131 545.016052,1250.340454 545.058472,1248.671997 
	C545.131897,1245.780273 544.076721,1242.547485 547.250488,1240.479614 
	C549.507996,1239.008667 552.009827,1238.209839 554.613342,1237.548950 
	C555.949280,1237.209717 557.807251,1236.664551 557.463623,1234.689819 
	C557.181213,1233.067017 555.572876,1232.916016 554.244080,1233.037598 
	C550.578918,1233.372803 549.050354,1231.592407 548.959351,1228.122070 
	C548.927917,1226.923462 548.626343,1225.577271 547.199707,1225.496094 
	C545.266968,1225.386108 545.602844,1226.817749 545.434631,1228.230103 
	C545.132690,1230.765137 539.418762,1232.496582 538.417725,1230.271240 
	C536.364868,1225.708008 531.084351,1225.267700 528.763672,1221.360718 
	C528.002075,1220.078613 526.676147,1219.194458 528.017517,1217.492676 
	C529.007812,1216.236450 531.466858,1214.435303 528.489929,1213.347168 
	C526.285034,1212.541260 522.308899,1211.671265 521.477783,1214.867188 
	C519.797363,1221.328979 516.817688,1218.453125 514.112000,1216.348999 
	C511.693817,1214.468384 509.554596,1213.043579 506.537323,1214.947754 
	C504.007690,1216.544312 503.356384,1214.888794 503.022461,1212.776611 
	C502.828766,1211.551270 503.488129,1209.645142 501.724121,1209.435059 
	C499.437042,1209.162598 500.265015,1211.566406 499.622894,1212.725586 
	C499.145264,1213.587891 498.880310,1214.568115 498.260315,1215.747803 
	C497.080658,1216.357056 496.324066,1216.001465 495.837067,1215.280396 
	C492.887573,1210.912842 496.727966,1200.364502 501.846466,1199.658691 
	C505.568542,1199.145508 507.271362,1197.437378 508.431000,1194.083496 
	C509.624603,1190.631226 511.276276,1187.321777 511.904816,1183.694092 
	C512.524109,1180.119263 511.797028,1177.289429 507.984283,1175.540039 
	C503.006866,1173.256714 502.861694,1170.558228 507.102814,1167.062012 
	C508.076965,1166.259033 509.856873,1165.876587 509.387451,1164.199951 
	C508.946564,1162.625366 507.262970,1162.861694 505.983734,1162.741577 
	C499.148651,1162.100342 497.201477,1164.087646 497.893524,1170.939941 
	C497.960419,1171.602295 498.129395,1172.256348 498.162292,1172.918091 
	C498.289978,1175.486450 499.316681,1178.521484 495.699829,1179.561646 
	C493.222504,1180.274292 488.717560,1177.276123 487.823395,1174.616211 
	C487.449432,1173.503784 487.420746,1172.325684 487.257538,1171.173462 
	C486.653900,1166.911865 484.073059,1164.663452 479.895508,1164.527344 
	C475.548248,1164.385986 471.898712,1162.400269 468.168335,1160.603027 
	C466.463867,1159.781982 465.112732,1158.455444 465.408447,1156.292725 
	C465.691010,1154.226318 467.135162,1153.169067 468.998535,1152.570190 
	C469.632904,1152.366211 470.340576,1152.328613 470.918976,1152.043091 
	C473.860413,1150.591431 478.657623,1153.743164 479.978119,1150.168701 
	C482.364380,1143.709229 485.191254,1137.168213 482.374146,1129.642090 
	C480.267975,1124.015137 478.129425,1119.532837 471.700928,1117.960449 
	C468.542664,1117.188110 466.935242,1117.930054 465.776550,1120.593628 
	C464.368866,1123.829468 464.489716,1127.028564 465.977997,1130.249023 
	C466.821045,1132.073364 467.722198,1134.244995 465.332336,1135.512573 
	C462.943085,1136.779785 461.472717,1135.255249 460.016907,1133.313965 
	C456.841797,1129.079712 453.227234,1125.242920 448.569641,1122.523193 
	C446.326447,1121.213379 443.529755,1120.164551 444.767029,1116.297119 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M843.783203,1.287997 
	C866.020874,1.000000 888.041809,1.000000 910.531372,1.000000 
	C913.765320,5.516048 912.850708,10.298323 911.757751,14.973330 
	C911.190247,17.400742 909.159180,18.251358 906.658508,17.848364 
	C902.724182,17.214325 898.766724,16.846207 894.845032,15.983201 
	C888.955811,14.687241 887.532959,15.805233 886.108215,21.691290 
	C885.507874,24.171419 883.766663,25.648954 881.844727,27.012129 
	C879.528198,28.655222 877.221863,30.309694 875.633728,32.731079 
	C875.099609,33.545441 874.296509,34.520340 875.005127,35.435661 
	C875.861572,36.542023 877.111511,36.051399 878.200134,35.582096 
	C878.657349,35.385056 879.043457,35.050129 879.461426,34.776936 
	C885.684082,30.709167 886.433899,30.963409 889.349304,38.003994 
	C890.589905,40.999889 892.723999,43.690891 890.177979,47.281887 
	C888.971130,48.984032 891.180176,50.541534 892.417664,51.830582 
	C894.634949,54.140125 896.250488,56.739033 894.612000,59.886429 
	C892.821899,63.325165 889.585510,64.656952 885.799988,64.370094 
	C881.254761,64.025681 878.529663,59.442711 880.260559,55.264423 
	C882.000244,51.065075 881.023743,49.117989 876.505859,47.959579 
	C875.280640,47.645428 874.003601,47.499313 872.310730,47.495811 
	C875.208252,48.423164 878.818665,48.773426 878.386780,52.078098 
	C877.743896,56.997772 872.315796,55.551693 869.166748,57.354145 
	C870.298157,59.046066 872.140442,59.071266 873.529968,59.890182 
	C875.169128,60.856266 877.186951,62.173672 876.885498,63.911446 
	C876.104736,68.411842 878.636780,70.864464 881.195862,73.891670 
	C883.729431,76.888779 882.285217,82.017464 878.538879,85.575058 
	C874.580566,85.127945 871.807922,82.149017 867.888855,82.283218 
	C866.155518,82.342567 864.630676,82.113190 863.516785,83.807594 
	C862.291626,85.671356 860.543518,86.632912 858.386963,85.548012 
	C856.157898,84.426666 855.710876,82.297897 855.927490,79.994667 
	C856.037659,78.822411 856.462769,77.722694 856.904968,76.645584 
	C857.753845,74.577965 857.168152,73.098244 855.185669,72.125099 
	C852.596619,70.854256 850.586853,68.858162 848.400879,66.876106 
	C851.470520,70.133865 853.145752,73.726295 851.626221,78.208824 
	C850.056335,82.840012 845.849487,84.526169 842.068115,86.464607 
	C839.141052,87.965111 833.079529,84.930634 831.469666,81.678963 
	C829.835144,78.377792 830.791443,75.577232 834.529053,72.719299 
	C838.900391,69.376801 839.225037,62.641376 835.194946,58.972492 
	C834.696594,58.518822 834.182434,58.099537 833.483398,58.020111 
	C833.004456,57.965694 832.504272,57.839035 832.041809,58.409817 
	C832.034241,60.110786 833.629700,61.092903 834.322388,62.545746 
	C837.031189,68.227028 835.172974,73.800385 829.347595,76.052773 
	C826.657349,77.092964 825.383240,78.223495 826.563232,81.133492 
	C827.713440,83.969856 826.814758,86.285583 824.650818,88.322426 
	C822.954041,89.919601 821.446655,91.713257 820.004211,93.548042 
	C818.585999,95.351974 819.076294,96.980339 820.635254,98.382347 
	C822.080872,99.682381 823.380249,99.596153 825.037109,98.338074 
	C829.526489,94.929092 830.277161,95.003372 834.100952,99.121147 
	C837.011658,102.255516 841.009094,102.032501 844.644653,102.893326 
	C846.194275,103.260254 846.940186,101.973824 847.493042,100.642349 
	C848.787964,97.524132 849.449280,94.313263 849.512085,90.923988 
	C849.552734,88.728683 849.872864,86.365044 852.788818,86.147034 
	C855.410522,85.951027 856.168396,87.939629 856.501038,90.004997 
	C856.920776,92.612000 858.432983,94.551918 859.987244,96.525223 
	C862.801514,100.097984 863.057129,104.181137 862.031494,108.395508 
	C861.443237,110.812302 860.024353,112.853477 857.311829,112.845131 
	C854.225281,112.835632 852.928040,114.478104 851.919617,117.038910 
	C851.029663,119.298859 849.233887,121.804024 846.711792,120.342224 
	C841.113403,117.097458 836.505859,120.264999 831.565491,121.715828 
	C828.914734,122.494240 825.387390,125.252960 825.718750,119.150253 
	C825.788391,117.867767 824.689270,117.924568 823.933716,118.059944 
	C821.512817,118.493713 819.510254,117.069244 817.306152,116.391388 
	C816.444275,119.294205 817.948730,121.562103 818.244995,123.990227 
	C818.853760,128.979431 817.039490,131.181946 812.017029,131.317596 
	C807.220886,131.447144 802.382690,132.063126 798.315857,128.307022 
	C797.058716,127.145844 794.875366,127.180649 793.148010,126.566132 
	C791.727783,126.060883 790.893555,126.587402 790.653625,128.052856 
	C790.167908,131.019363 789.359192,133.903839 788.534058,136.789185 
	C787.922119,138.928970 788.605103,139.779266 791.027344,139.633804 
	C797.428772,139.249329 799.932678,141.078079 802.103943,147.080338 
	C803.487244,150.904465 803.105957,153.979553 798.432434,155.678970 
	C800.504578,156.898727 802.210876,155.818542 803.882996,155.708633 
	C806.599792,155.530106 809.032166,156.091553 810.037903,158.865738 
	C811.165344,161.975861 812.460083,165.285294 809.766846,168.294998 
	C807.910156,170.369919 805.470825,170.574142 803.524597,168.782196 
	C800.153320,165.678116 796.056091,164.627914 791.830444,163.591843 
	C788.930725,162.880844 786.111938,161.934937 783.666077,160.002228 
	C780.817139,157.750977 777.194763,159.003281 773.740784,158.356583 
	C776.870239,160.523636 779.492126,162.905380 777.627014,167.081451 
	C776.170776,170.341965 772.225952,172.108063 767.421021,171.413071 
	C765.128357,171.081436 763.458374,171.345428 762.647095,173.727493 
	C761.564636,176.906097 759.272827,177.583145 756.285034,176.807770 
	C752.710449,175.880142 751.082825,176.783554 751.248901,180.934341 
	C751.437744,185.653763 749.319641,187.239105 744.504333,185.855225 
	C740.572998,184.725372 739.704773,186.329605 739.629944,189.606934 
	C739.603333,190.772217 739.582947,191.939804 739.453979,193.100739 
	C739.203186,195.358215 738.507263,197.397980 736.228210,198.348602 
	C733.828064,199.349686 732.676819,197.299530 731.018127,195.885864 
	C732.047180,199.298325 735.941528,198.780746 737.576050,201.526703 
	C735.547607,205.422684 735.153198,209.970413 731.228577,212.735321 
	C729.169189,212.236938 728.443237,210.889008 727.735474,209.073624 
	C727.217834,204.320587 723.652649,205.931381 720.874634,205.332642 
	C714.469299,205.699799 709.937622,202.754333 705.589172,198.557098 
	C704.170410,198.103638 703.077209,197.794678 701.590088,197.757416 
	C699.001099,198.661758 696.810181,199.218384 694.901855,197.011246 
	C692.899048,194.694916 690.755981,194.550232 688.449341,196.636475 
	C687.707520,197.307404 686.739014,197.595413 685.756531,197.857483 
	C681.826477,198.905716 681.228516,201.188904 684.035828,204.249298 
	C687.134521,207.627350 692.654968,208.408432 696.790161,205.869064 
	C698.567505,204.777573 700.172668,203.674072 702.514160,205.291138 
	C705.218018,211.941254 709.591187,217.788254 708.588745,225.237045 
	C708.303589,227.356064 708.642029,229.866913 706.047668,230.701385 
	C703.094788,231.651199 701.952637,229.348907 700.813538,227.245071 
	C700.352051,226.392624 699.901611,225.472885 698.652283,225.197144 
	C697.304626,227.151474 698.241028,228.684937 699.312988,230.178894 
	C700.792786,232.241135 701.817566,234.470200 699.251221,236.264145 
	C696.601013,238.116638 694.563293,236.575317 692.925781,234.365067 
	C689.953003,230.352509 687.114502,226.215591 682.612366,223.674240 
	C681.449280,223.017746 680.233398,222.099213 678.924683,222.844543 
	C677.688965,223.548309 678.165161,224.989075 678.372498,226.057236 
	C679.497253,231.851151 677.244934,236.086807 672.562378,239.319992 
	C671.468933,240.074982 670.493164,241.000885 669.786255,242.160049 
	C668.676453,243.979919 667.338440,245.406555 664.936951,244.661179 
	C662.606750,243.937897 661.125366,242.107773 661.126831,239.886414 
	C661.129150,236.286591 659.933289,232.632324 661.456421,228.807220 
	C660.393616,230.703659 658.718201,231.136200 656.479858,231.730820 
	C661.265076,232.607925 661.387024,235.680786 660.795471,239.046432 
	C659.709534,245.224777 653.328552,250.809647 647.117859,251.081421 
	C644.476685,251.196991 644.009094,250.754364 641.909973,246.000244 
	C639.384705,248.584885 638.307861,251.562317 638.320679,255.360779 
	C638.210083,255.929153 638.147095,256.151550 637.948914,256.690308 
	C637.432983,257.797699 636.723206,258.422363 636.644897,259.691040 
	C636.604797,261.861877 637.122742,263.690338 636.617981,265.862518 
	C635.376709,267.815552 633.771667,269.025787 633.104858,271.053040 
	C631.636902,275.516479 628.664734,276.344849 625.004883,273.446503 
	C623.652649,272.375610 622.892639,270.176147 620.540894,271.150085 
	C617.762756,272.300598 614.977905,273.582642 612.928894,275.797302 
	C611.479004,277.364380 613.064270,278.919861 613.855835,280.291748 
	C615.382935,282.938721 615.107300,285.035736 612.107971,286.589630 
	C613.284668,287.828857 614.949890,287.608978 616.169678,288.371552 
	C617.834412,289.412201 618.951904,290.755981 618.923462,292.805115 
	C618.884338,295.626831 613.645264,302.880615 610.753113,303.842621 
	C608.679260,304.532471 607.030396,305.169525 607.604553,307.912354 
	C608.208008,310.795288 605.481689,311.774048 603.664185,313.906250 
	C606.100342,314.413727 608.118408,313.242554 610.517578,313.452148 
	C613.127808,313.139740 615.325684,313.164764 617.772949,314.356842 
	C618.710815,315.299866 618.880371,316.111572 618.668884,317.392303 
	C610.198120,319.859863 602.449158,325.470306 593.025818,320.212738 
	C589.506348,318.249146 584.774658,317.589874 580.077942,319.406494 
	C573.277771,322.036621 567.363831,320.332458 564.977051,315.757721 
	C563.415344,312.764313 563.636780,309.315826 563.452942,305.670166 
	C566.765625,303.623230 567.873108,301.124664 567.104431,297.512939 
	C566.455505,294.463745 568.106812,292.793915 571.165771,292.536682 
	C573.986755,292.299438 576.804443,292.026764 579.603760,291.608551 
	C581.662048,291.301025 583.135437,290.192719 582.860535,287.970337 
	C582.564453,285.577118 580.697021,286.538879 579.323120,286.726349 
	C577.322815,286.999329 575.493591,286.851593 575.119934,284.432373 
	C574.699097,281.707428 576.193665,279.456665 578.629456,279.177399 
	C583.462158,278.623413 588.130981,276.178955 593.476440,277.526428 
	C594.398132,277.110809 595.031982,276.866364 595.698547,276.842163 
	C601.642456,276.626312 601.606812,276.643066 599.856262,270.604919 
	C598.102173,264.554718 595.947632,258.658264 593.129761,252.615479 
	C592.311462,251.057266 592.097046,249.828812 593.424988,248.426636 
	C594.728394,247.711502 595.763672,247.662338 597.187683,247.893097 
	C601.675110,249.387222 602.191223,254.777191 606.851013,256.251312 
	C605.551758,253.330673 600.530334,252.224197 603.131714,248.246933 
	C604.682434,245.875992 607.166382,245.651947 609.997681,247.140579 
	C610.934937,247.723129 611.840271,248.874435 612.125305,248.435196 
	C612.910706,247.224854 611.640198,246.184784 611.026489,244.739410 
	C609.273254,238.696106 607.160461,233.143875 606.934937,227.082550 
	C606.724731,221.434235 607.954163,220.316528 613.050110,222.417511 
	C614.271423,222.921021 615.456299,223.510895 617.113647,223.824921 
	C617.964722,223.774658 618.446289,223.761230 619.275024,223.780106 
	C623.768127,224.680557 623.275330,221.056381 624.524902,218.659332 
	C624.934875,218.122330 625.177612,217.896744 625.454712,217.715805 
	C627.445496,216.416229 628.874878,213.513229 631.776245,214.651840 
	C634.793274,215.835861 635.102356,219.046783 635.726440,221.725327 
	C636.694397,225.879654 633.784607,227.180939 630.537537,228.182144 
	C625.955627,229.594940 625.791565,229.970459 628.968262,233.519333 
	C630.824890,235.593536 631.495178,238.130127 632.869324,241.366013 
	C633.820801,238.335785 634.817749,236.050247 637.241699,234.716064 
	C640.234497,233.068787 638.685669,230.392853 638.380920,228.031479 
	C637.613281,222.085480 637.994202,216.491898 643.640015,212.355774 
	C644.750671,212.189865 645.456055,212.346298 646.383545,212.980957 
	C648.152344,216.413101 652.079285,219.498062 647.062012,222.980728 
	C646.418030,223.427719 646.466187,224.157135 646.912476,224.743988 
	C647.490540,225.504272 648.315308,225.608734 649.131592,225.208084 
	C651.408752,224.090393 651.848999,222.208176 651.632019,219.805679 
	C650.955261,212.312943 654.527405,209.501663 662.178040,211.709106 
	C661.782898,208.939529 660.089539,207.255981 658.266968,205.620850 
	C656.424255,203.967667 654.356445,202.450119 654.102417,199.331223 
	C654.204956,198.753952 654.279907,198.530228 654.561768,198.019958 
	C654.908630,197.647568 655.045776,197.492126 655.189148,197.486069 
	C658.833496,197.332108 663.100220,195.322556 665.822144,192.440125 
	C666.844299,192.030380 667.560669,192.003418 668.625977,192.277863 
	C672.517151,195.875381 670.851196,200.919891 672.786865,205.382568 
	C676.511414,202.449203 676.003235,198.332611 675.897278,194.901459 
	C675.685791,188.048981 677.407898,186.312988 683.962097,188.281616 
	C685.113037,185.784225 685.093994,183.155899 684.924316,180.484924 
	C684.816772,178.792328 684.845703,176.946213 686.943604,176.452545 
	C689.101868,175.944687 689.965698,177.526581 690.318665,179.250580 
	C691.221741,183.661606 693.705811,183.523438 696.916992,181.749237 
	C698.907043,180.649704 701.106201,180.286316 703.175842,181.223480 
	C705.888916,182.452026 708.504639,182.649597 711.388550,181.901123 
	C715.136597,180.928360 716.253052,184.605545 719.169678,187.267059 
	C717.726501,181.997437 714.950256,177.558090 719.241882,173.554108 
	C723.927490,169.182571 727.837036,173.957062 732.517700,174.966248 
	C731.912781,171.009094 732.585266,168.462814 737.302185,169.735474 
	C738.683105,170.108063 743.905945,163.647217 743.882935,162.129349 
	C743.857483,160.449158 742.354919,160.672928 741.405029,160.179871 
	C737.837036,158.327820 737.582703,156.765976 740.099976,153.634506 
	C742.998840,150.028458 747.371216,149.209229 751.565002,147.282501 
	C756.732483,144.347473 761.247803,146.752945 765.793518,148.125229 
	C770.104065,149.426498 773.640259,147.899094 775.334167,143.775146 
	C777.924866,137.468048 777.593323,136.819122 770.816345,134.932800 
	C770.174072,134.754028 770.979431,134.971741 770.330505,134.819809 
	C767.950134,134.262451 765.125427,134.326935 764.363037,131.232834 
	C763.831116,129.074341 765.361328,127.848885 766.928467,126.823181 
	C769.510376,125.133179 772.191589,123.806129 775.264526,125.683144 
	C776.760986,126.597229 777.836792,128.420959 780.372986,127.763908 
	C780.535278,125.354942 778.479980,124.106529 777.389465,122.463524 
	C775.526733,119.657242 774.747559,117.060509 776.248779,113.644234 
	C778.614197,108.261055 776.558228,101.720207 771.911560,98.084259 
	C770.863281,97.263962 769.793701,96.473602 768.861450,95.518791 
	C766.475769,93.075172 764.855713,90.484863 767.468750,87.217415 
	C769.823914,84.272522 774.172974,83.788239 777.342834,86.080811 
	C777.748108,86.373962 778.266296,86.656113 778.435547,87.083405 
	C781.599731,95.068298 789.425354,95.213852 795.958435,97.448868 
	C798.533508,98.329834 801.484192,96.295441 804.183472,98.938019 
	C803.882812,95.099976 801.854492,93.863304 799.154236,93.696815 
	C794.133911,93.387260 791.589966,90.809639 791.310791,85.845047 
	C791.211609,84.082130 790.181396,82.815880 789.053040,81.533905 
	C783.570984,75.305511 784.256470,68.891388 791.125000,63.354752 
	C793.700867,61.330906 792.733521,58.887043 792.731140,56.625439 
	C792.726379,52.122372 794.289551,50.741611 798.488892,51.927670 
	C801.025940,52.644211 803.434143,52.598240 805.836609,51.811760 
	C808.137512,51.058517 810.355530,50.746380 812.624207,51.826847 
	C815.287109,53.095089 816.121460,51.649384 816.620789,49.292534 
	C817.366821,45.771259 817.205811,42.428596 815.928101,39.011105 
	C813.976624,33.791233 813.060120,28.695358 818.553467,24.654486 
	C820.342041,23.338827 820.338074,21.150181 819.242493,19.188498 
	C817.173767,15.484306 819.115112,13.226115 822.028259,11.339763 
	C826.166260,8.660267 830.618835,8.928307 834.998535,10.406128 
	C840.047180,12.109619 845.509094,11.577898 850.518616,13.750673 
	C852.779541,14.731340 855.215271,13.172694 857.758606,11.019144 
	C854.072205,10.356889 850.216553,14.287058 848.259338,10.152933 
	C846.849854,7.175682 843.794617,5.134971 843.783203,1.287997 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M186.182404,161.303024 
	C185.998581,159.001968 186.165375,156.987564 185.970779,155.008698 
	C185.100327,146.156494 189.661789,143.487610 198.941544,144.247711 
	C203.996933,144.661804 208.394882,146.861832 213.055618,149.062683 
	C218.348969,151.562271 222.505478,156.178238 228.483978,157.283554 
	C229.803375,157.527481 231.028610,158.288773 232.144501,157.054565 
	C233.214233,155.871429 231.995804,154.995255 231.682632,153.944275 
	C230.585022,150.260727 228.336487,146.792145 230.140930,141.732178 
	C226.354691,144.145386 223.507782,144.724136 220.966156,141.704681 
	C220.284409,140.894760 218.949081,140.832458 218.192566,141.505539 
	C214.190247,145.066391 210.028549,142.202469 205.954453,141.919296 
	C202.319748,141.666656 202.864594,138.338898 202.398773,136.181137 
	C201.825790,133.526901 204.368271,134.338531 205.818146,133.941635 
	C208.211929,133.286316 211.802948,134.025467 209.437927,129.026855 
	C207.874161,125.721779 210.903946,124.148346 214.239243,123.972160 
	C215.782959,123.890610 217.950241,124.799667 218.630676,122.719940 
	C219.240417,120.856270 217.342880,119.783501 216.161514,118.721069 
	C213.610779,116.427147 211.724503,114.418068 213.868484,110.494644 
	C215.859528,106.851051 214.404114,102.585327 213.604034,98.826927 
	C212.575287,93.994370 213.103409,89.647125 214.955215,85.252464 
	C215.270309,84.504715 215.221222,83.507530 216.251816,83.359177 
	C217.638062,83.159637 217.674408,84.309921 218.048798,85.230034 
	C219.990250,90.001465 223.960129,89.278908 227.729935,88.523582 
	C232.007370,87.666550 232.413849,84.419525 231.938828,80.831696 
	C231.559784,77.968842 229.879883,76.408806 227.049057,76.002502 
	C225.674637,75.805237 224.074554,75.392365 224.521423,73.690796 
	C224.820435,72.552261 225.990005,71.483063 227.024673,70.735123 
	C228.501511,69.667557 229.801315,70.830025 230.262024,71.986092 
	C232.500778,77.603737 236.395432,78.622444 241.888321,77.111015 
	C244.840805,76.298599 247.651474,77.486938 250.180344,79.551857 
	C253.220505,82.034225 256.850616,80.424957 260.042480,75.328888 
	C262.113251,72.022743 260.435699,69.637863 257.605560,67.883400 
	C256.499146,67.197525 255.084930,66.433975 253.895020,66.552208 
	C247.206055,67.216904 245.142624,62.343807 242.826508,57.691093 
	C241.931442,55.893085 242.904800,54.827953 244.404846,54.221214 
	C245.114777,53.934063 246.059113,54.039722 246.857224,54.185890 
	C256.062439,55.871696 256.626129,55.943905 257.619446,46.741177 
	C258.145203,41.870388 262.865326,39.396366 262.757721,34.389328 
	C262.718689,32.573357 265.805084,33.118282 267.574005,33.000313 
	C269.916229,32.844105 272.403839,32.938259 272.950867,29.801008 
	C273.395355,27.251894 274.506714,24.521517 270.754913,23.295528 
	C269.316254,22.825413 268.461395,21.337040 269.371887,20.109262 
	C270.824554,18.150475 272.558502,16.129755 275.318512,16.053228 
	C279.478027,15.937900 283.642761,15.989621 287.805145,16.004272 
	C292.466797,16.020681 297.070099,15.833716 301.313904,13.513054 
	C303.236694,12.461616 304.814362,13.304005 306.218994,14.719221 
	C309.293518,17.816847 312.483398,17.977499 315.922485,15.213253 
	C317.758118,13.737821 319.893890,13.803267 322.093140,14.510515 
	C325.470764,15.596684 328.630920,14.975868 331.658295,13.176907 
	C333.062927,12.342222 334.061218,11.393770 333.893066,9.646917 
	C333.723022,7.880055 332.424103,7.111615 330.986053,6.570756 
	C328.245117,5.539889 325.291412,5.613555 322.602661,6.195538 
	C315.483917,7.736396 308.116516,7.823329 301.280212,6.616043 
	C295.618530,5.616187 289.571259,4.434585 284.095093,1.365710 
	C312.687561,1.000000 341.375122,1.000000 370.531342,1.000000 
	C374.076080,7.872580 379.076569,9.114588 386.077393,7.107504 
	C390.222443,5.919147 393.346588,8.485233 394.083069,11.977663 
	C394.845154,15.591512 393.153687,18.054338 388.635529,19.539707 
	C386.720001,20.169434 384.993805,21.188122 383.111542,21.852280 
	C381.161774,22.540262 378.744812,23.063980 377.372253,21.619696 
	C374.448608,18.543293 373.142029,20.062792 371.155823,22.634489 
	C367.824829,26.947483 363.946320,27.151382 359.459595,23.847719 
	C357.045593,22.070229 354.723267,20.174763 352.318573,18.388733 
	C351.354492,17.672689 350.048584,17.256411 349.076233,18.141197 
	C348.025848,19.097004 348.813049,20.296576 349.256256,21.362150 
	C350.166809,23.551476 353.534363,24.946770 352.096527,27.781893 
	C350.866608,30.206978 347.600952,29.545753 345.453125,30.764261 
	C344.587952,31.255064 343.672363,31.707321 342.699707,31.939051 
	C339.428864,32.718338 335.884735,32.426071 335.780121,37.788506 
	C335.711487,41.305508 335.473663,44.121902 338.212311,46.794682 
	C340.778015,49.298641 340.442993,52.245224 337.935059,54.757317 
	C335.107147,57.589947 326.558655,58.605202 322.923187,56.661026 
	C316.770966,53.370914 312.703918,55.283367 311.538330,62.116241 
	C310.953339,65.545692 309.398560,68.754768 309.617676,72.380516 
	C309.982117,78.411736 315.908783,82.518761 321.554108,80.466148 
	C328.553101,77.921349 331.153931,79.940392 330.349457,87.401955 
	C330.063873,90.050659 329.646118,92.679016 329.556824,95.352768 
	C329.478577,97.695236 329.418854,100.212288 326.537018,101.033775 
	C323.653870,101.855644 321.392151,100.787956 319.462524,98.553978 
	C316.524384,95.152420 316.467773,95.201309 312.310791,94.229507 
	C311.987427,96.570267 313.663300,97.905853 314.861877,99.287498 
	C317.070312,101.833183 317.357025,104.595139 316.531250,107.693314 
	C314.963135,113.576805 310.080414,114.930466 305.611328,110.684814 
	C305.369904,110.455475 305.169250,110.185585 304.937775,109.946655 
	C301.321686,106.214149 301.120575,106.249588 299.236908,110.928535 
	C297.670807,114.818771 295.184448,116.150009 291.443573,115.029724 
	C288.840149,114.250076 287.054260,112.646996 286.738708,109.810036 
	C286.436493,107.092560 287.403839,104.973541 289.724548,103.397301 
	C291.119934,102.449547 293.903473,102.762230 293.620880,100.252235 
	C293.353485,97.877243 290.768524,97.769554 289.028687,96.952507 
	C286.781952,95.897385 284.590485,94.551567 281.937622,94.894844 
	C281.112610,95.001595 280.179565,95.145622 279.904785,96.075195 
	C279.623749,97.025902 280.328430,97.735512 281.056030,98.048378 
	C286.574188,100.421143 286.509033,104.863098 285.743164,109.794220 
	C285.337128,112.408440 285.317657,115.173553 286.118744,117.737885 
	C287.259125,121.388283 287.678040,124.874352 284.463043,128.741745 
	C292.307892,129.001633 293.766479,135.651611 297.773773,140.134628 
	C298.478119,142.792511 298.519867,144.892883 295.292175,145.756317 
	C292.489258,144.347519 291.003265,141.369583 287.330139,141.501648 
	C285.160492,141.657135 283.320496,141.703354 282.208496,143.557144 
	C280.340485,146.671341 277.986267,149.057449 273.992279,149.083054 
	C272.607910,149.091934 271.846191,150.010300 272.004974,151.382980 
	C272.288788,153.836334 273.380432,155.901108 275.863922,156.642471 
	C278.011597,157.283600 280.155304,157.658157 279.967163,160.958496 
	C279.540680,162.386002 278.828644,163.144562 277.517548,163.890778 
	C276.326050,164.308472 275.493774,164.330185 274.243591,164.240417 
	C264.716125,162.162582 263.969482,154.603485 262.756287,146.996796 
	C266.460052,142.296875 261.645386,139.618423 260.594025,136.205231 
	C261.208588,139.487564 264.407898,142.363861 262.248474,146.424683 
	C261.628876,148.672607 261.544220,150.737106 259.824860,152.525986 
	C255.658966,153.418900 253.161331,155.262314 253.388168,159.647400 
	C253.447693,160.797974 253.016769,161.986694 252.328201,162.928711 
	C250.164398,165.888931 250.104233,169.037308 251.124908,172.407623 
	C251.809250,174.667389 252.580734,177.274643 249.272995,178.074265 
	C246.297516,178.793594 243.205231,178.971313 241.711823,175.297531 
	C241.211945,174.067841 241.005005,172.733383 240.425903,171.529312 
	C239.644135,169.903824 238.517532,168.807693 236.539719,169.096085 
	C235.239120,169.285751 234.385590,170.102753 234.140244,171.315308 
	C233.755493,173.216843 235.342316,173.876465 236.598068,174.609558 
	C238.329498,175.620377 240.117889,176.563354 241.165588,178.351807 
	C242.250198,180.203293 243.748077,182.307648 241.794540,184.227524 
	C240.021881,185.969604 237.760620,185.299316 235.805817,184.081772 
	C234.097672,183.017883 232.790344,181.495300 231.419250,180.047043 
	C230.298721,178.863480 229.536896,177.298553 227.881805,176.622971 
	C226.766724,177.276093 227.522888,178.010101 227.801910,178.671799 
	C228.585846,180.530853 230.990295,182.420990 228.645294,184.382874 
	C226.461273,186.210068 223.632477,185.735336 221.019852,184.911865 
	C218.891769,184.241119 218.176361,180.998520 215.193527,181.792740 
	C212.413681,182.532913 212.919266,185.696777 211.092178,187.105728 
	C210.830780,187.307297 210.531006,187.444290 209.850403,187.525452 
	C206.787476,186.353012 208.662842,182.482437 205.568726,181.201492 
	C205.077988,180.915131 204.887283,180.791412 204.430969,180.456100 
	C202.669403,178.182404 200.023163,178.806946 197.849426,177.449692 
	C196.868576,176.521820 196.560791,175.589172 196.023758,174.365082 
	C195.763885,173.542923 195.667862,173.062317 195.589661,172.212585 
	C196.142807,165.164917 191.148529,163.480789 186.182404,161.303024 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M103.406540,1199.604980 
	C107.921112,1202.546875 107.950485,1203.590088 103.475998,1205.948486 
	C101.672882,1206.898926 100.100578,1208.126221 100.669685,1210.012817 
	C101.202782,1211.779907 103.183350,1210.847900 104.597000,1210.655762 
	C109.245491,1210.023804 107.934639,1214.066895 108.999100,1216.642822 
	C109.718414,1217.771362 110.162682,1218.628296 110.155716,1220.039795 
	C110.115433,1221.779907 108.922867,1222.984741 109.764893,1224.722168 
	C111.532127,1227.697144 109.574814,1228.823120 107.710388,1229.962280 
	C105.357903,1231.399414 105.851440,1232.297485 108.195587,1233.022949 
	C111.394524,1234.012939 114.244095,1235.180420 109.399956,1238.093262 
	C108.708321,1238.509155 108.634216,1238.935303 108.771545,1239.690063 
	C110.172318,1247.390015 112.896698,1254.890991 112.117676,1262.924805 
	C111.940384,1264.753174 113.621681,1265.786255 115.051285,1265.730591 
	C118.853729,1265.582520 122.001961,1267.797729 125.652245,1268.226807 
	C128.071777,1268.511230 130.968643,1269.706543 130.651184,1272.772217 
	C130.340240,1275.775024 127.365356,1274.724609 125.353172,1275.013916 
	C113.891678,1276.662231 102.363968,1277.536865 90.801796,1278.006592 
	C89.301231,1278.067627 88.188705,1278.470703 88.024803,1280.591064 
	C60.225319,1281.000000 32.450211,1280.928955 4.676514,1281.094360 
	C1.391929,1281.113892 0.924645,1280.148926 0.932404,1277.191650 
	C1.034082,1238.440430 1.000000,1199.688721 1.000000,1160.468628 
	C5.433944,1155.734009 7.910028,1143.043213 4.880788,1137.502563 
	C1.892961,1132.037476 4.767232,1127.096069 5.962392,1122.218262 
	C6.477863,1120.114380 10.146826,1118.353271 13.263310,1119.623779 
	C19.128927,1122.014893 23.574242,1118.715576 28.192259,1116.112915 
	C29.612942,1115.312378 29.565832,1113.638550 30.097542,1112.259399 
	C32.638947,1105.667358 36.063248,1103.288574 41.533161,1105.847168 
	C43.774792,1106.895630 44.809135,1106.507568 45.501568,1104.168091 
	C46.050964,1102.311890 47.243893,1100.916504 49.467728,1101.029541 
	C50.585308,1101.086426 51.945606,1100.683350 52.565994,1102.017334 
	C53.197716,1103.375732 52.259521,1104.269165 51.212921,1104.840576 
	C49.530228,1105.759155 49.030220,1107.168091 48.937057,1108.962280 
	C48.835373,1110.920654 47.826599,1112.167725 45.696922,1111.708496 
	C41.799641,1110.868164 42.013607,1112.842163 42.646591,1115.638672 
	C43.436565,1119.128906 43.092110,1122.969971 42.041862,1126.703369 
	C39.948936,1123.460571 37.587597,1120.774292 37.955669,1116.788818 
	C38.121269,1114.995728 37.536968,1113.334717 35.403934,1113.176392 
	C32.879555,1112.989014 32.972996,1114.954102 33.018780,1116.619141 
	C33.080482,1118.863159 32.258301,1120.093262 29.854952,1120.009644 
	C26.712717,1119.900391 25.886440,1121.801025 25.992441,1124.532227 
	C26.056993,1126.195435 25.995489,1127.863281 26.001186,1129.529053 
	C26.026131,1136.821411 28.952372,1139.169434 35.563885,1136.435547 
	C39.697128,1134.726318 42.743477,1137.286743 46.195049,1138.038940 
	C48.046082,1138.442261 48.267529,1140.866699 47.790852,1142.792969 
	C47.346642,1144.587891 45.910049,1144.883789 44.315983,1144.994141 
	C40.685566,1145.245361 37.032070,1144.540527 33.404678,1145.669922 
	C30.901257,1146.449341 29.340210,1147.939209 30.115976,1150.327148 
	C31.388676,1154.244629 30.130220,1159.465820 35.809731,1161.384033 
	C37.735394,1162.034424 36.745949,1163.479858 35.103893,1163.961426 
	C34.103802,1164.254883 32.824078,1164.661133 33.394749,1165.827026 
	C34.366638,1167.812500 36.580231,1168.589478 38.362125,1169.704590 
	C39.224434,1170.244263 40.656723,1169.807739 40.714195,1168.647827 
	C40.934933,1164.192871 44.119141,1165.076416 46.841164,1164.993896 
	C50.342873,1164.888062 53.822041,1164.962769 57.639854,1166.001953 
	C61.514637,1167.124146 61.886772,1169.493408 60.781380,1172.819336 
	C60.979015,1174.286011 60.736725,1175.379639 60.355225,1176.785034 
	C59.197514,1179.209473 60.420525,1180.158691 62.318184,1181.143188 
	C61.059574,1185.588623 65.268265,1187.980835 65.481400,1191.884033 
	C64.816322,1192.725342 64.188507,1193.033813 63.158878,1193.308350 
	C61.867542,1193.597046 60.532749,1193.142578 60.446487,1195.091553 
	C60.142326,1199.874878 59.682491,1204.027954 62.098671,1208.509888 
	C63.972240,1211.985107 62.301098,1216.567627 60.764091,1220.644287 
	C63.181480,1218.141113 65.016891,1215.324951 69.263153,1215.904419 
	C71.477562,1216.206543 71.794464,1212.645142 74.101837,1211.530151 
	C76.182823,1210.808594 78.659569,1211.467285 79.396400,1209.057495 
	C79.965240,1207.197144 78.131027,1206.683960 76.659058,1205.719238 
	C73.574509,1203.789551 72.105339,1201.537964 73.266495,1198.087646 
	C73.964348,1196.013794 72.377960,1194.330811 71.979591,1192.434326 
	C71.845039,1191.793823 71.575882,1191.182007 71.403145,1190.547119 
	C69.900917,1185.026123 68.172470,1183.307861 63.018005,1182.159180 
	C62.305553,1180.599243 63.745617,1179.575562 63.293297,1177.972046 
	C63.267769,1177.212524 63.337284,1176.782471 63.621132,1176.061523 
	C65.091797,1174.500244 66.645088,1173.384644 65.099785,1171.199707 
	C64.295639,1170.062744 64.454994,1168.660767 64.942505,1166.672607 
	C68.801460,1161.162720 68.801460,1161.162720 68.214806,1159.026489 
	C65.693527,1157.092407 66.020462,1155.144653 67.923340,1152.893188 
	C68.576180,1151.629517 68.096489,1150.489502 68.779778,1149.244385 
	C70.133507,1149.629883 71.520203,1149.999146 71.878822,1151.496826 
	C72.203072,1152.850830 72.530807,1154.332275 74.382500,1153.567383 
	C76.063354,1152.873169 76.187920,1151.269409 75.721886,1149.753540 
	C75.177261,1147.981689 74.708977,1146.258179 75.387314,1144.431763 
	C75.960464,1142.888428 76.925392,1141.950195 78.713692,1142.058838 
	C80.793480,1142.184937 81.016327,1143.601929 80.975662,1145.192261 
	C80.950470,1146.177368 81.155380,1147.391968 80.670685,1148.105103 
	C77.661568,1152.533569 79.230408,1157.016479 80.355713,1161.465820 
	C80.897972,1163.609741 81.624382,1165.751831 79.855675,1167.534546 
	C75.575661,1171.848755 76.947556,1175.784424 80.734863,1179.841309 
	C82.006065,1180.745972 82.129478,1181.801270 82.093010,1183.283691 
	C80.096695,1187.670166 79.981529,1191.998413 79.827255,1196.315796 
	C79.753250,1198.386963 80.078033,1200.525391 81.854309,1201.695557 
	C84.656044,1203.541382 85.476913,1206.252441 86.000946,1209.593018 
	C86.037102,1210.422607 86.110962,1210.888428 86.353569,1211.680542 
	C86.473045,1212.250977 86.474983,1212.487183 86.365295,1213.059570 
	C85.651543,1214.421753 84.771469,1215.188843 83.595093,1216.143188 
	C82.705811,1216.655029 82.104256,1217.059814 81.944435,1217.720215 
	C80.515099,1223.626587 80.527939,1223.629639 74.218224,1223.240723 
	C76.523209,1225.721069 77.771996,1226.447998 79.129234,1226.094849 
	C81.451912,1225.490356 80.613586,1222.947388 81.844597,1221.663940 
	C83.043961,1220.413696 80.661224,1218.271118 83.239227,1217.151733 
	C88.371254,1215.671753 88.711243,1214.962524 86.264206,1210.790283 
	C84.590767,1205.991699 85.428329,1202.115112 88.607582,1198.562256 
	C90.287422,1196.685059 91.996292,1195.421631 94.959122,1195.907227 
	C97.674873,1197.704224 99.930511,1199.635254 103.406540,1199.604980 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M297.223572,139.748962 
	C294.458832,139.896729 293.874908,137.783325 293.032074,136.026031 
	C291.118469,132.036026 288.348938,129.650375 283.616760,130.642151 
	C282.807556,130.811752 281.891907,131.105972 281.543243,130.027832 
	C281.239166,129.087463 281.981812,128.415558 282.607239,127.944092 
	C286.132385,125.286812 286.220734,122.404068 284.514496,118.367233 
	C283.056000,114.916557 283.405212,110.673241 284.350128,107.035500 
	C285.311859,103.333092 284.486328,101.389122 281.098175,99.994637 
	C279.175537,99.203339 276.514496,98.430824 277.231567,95.603668 
	C277.966858,92.704483 280.750366,92.939468 283.052307,93.039825 
	C287.328461,93.226257 290.568542,96.257683 294.508087,97.483963 
	C296.319244,98.047752 296.059875,100.227295 295.910400,101.902512 
	C295.721649,104.018051 294.087585,103.925430 292.576599,104.014427 
	C289.239380,104.210976 287.935883,106.145157 288.010071,109.315010 
	C288.093353,112.873466 290.592224,113.625587 293.390167,113.959023 
	C296.709564,114.354599 297.851654,112.368240 297.970581,109.478683 
	C298.056580,107.388611 298.136993,105.406822 300.877625,105.057693 
	C303.268005,104.753166 304.516663,105.997421 305.526306,107.977844 
	C307.128174,111.119987 309.510681,113.132317 313.128235,111.123337 
	C315.740509,109.672661 316.207458,103.384521 314.159058,101.034683 
	C312.863129,99.548027 311.686462,97.938179 310.263245,96.586929 
	C308.738129,95.138954 309.501678,93.884438 310.278046,92.591194 
	C311.380493,90.754837 312.591949,91.439110 314.023193,92.340553 
	C316.655548,93.998482 320.233917,94.175171 321.125122,98.303787 
	C321.524658,100.154716 324.064178,100.165123 325.950104,99.892067 
	C328.365326,99.542366 328.118652,97.576157 327.950836,96.010231 
	C327.516785,91.959755 329.117188,88.129593 329.008087,84.124893 
	C328.905579,80.361755 326.765411,78.680206 324.028595,81.165253 
	C318.208252,86.450180 313.401642,81.498627 309.955414,79.037178 
	C306.253662,76.393219 307.469910,70.484535 308.818848,65.952812 
	C309.236389,64.550163 310.285400,63.233868 309.959656,61.726803 
	C308.703278,55.914185 313.816620,54.947464 316.981110,52.880409 
	C319.406006,51.296478 321.563446,53.848026 323.559174,55.146320 
	C326.487030,57.050945 335.178925,55.861935 337.482056,53.211739 
	C339.274139,51.149574 339.310699,49.211815 337.113892,47.687458 
	C333.460449,45.152359 332.078735,42.213554 334.424530,38.050766 
	C334.791687,37.399281 334.910767,36.349018 334.664124,35.653473 
	C333.248230,31.659903 335.499420,30.722347 338.659851,31.056168 
	C343.075012,31.522516 346.030701,27.446901 350.463409,27.695349 
	C352.261658,27.796135 351.461395,26.002010 350.419800,25.327291 
	C349.105072,24.475615 348.150757,23.423906 347.503082,22.018936 
	C346.609039,20.079395 345.045959,18.085552 347.413818,16.213465 
	C349.663452,14.434843 352.533051,15.391888 353.620728,17.022148 
	C355.769897,20.243376 359.415985,21.254555 361.983521,23.866291 
	C364.506775,26.433014 368.118408,24.750906 369.931610,21.824108 
	C371.539307,19.229040 373.600769,17.321163 375.903564,15.121148 
	C376.555298,21.504522 379.313232,22.762711 384.604431,19.323921 
	C385.779236,18.560411 386.840881,18.023901 388.226135,17.983511 
	C391.496033,17.888174 393.173004,16.165100 392.917419,12.890554 
	C392.634521,9.266264 390.021545,8.260807 386.958862,8.293235 
	C384.993256,8.314047 383.006958,8.744722 381.075317,9.188906 
	C374.231659,10.762598 374.238037,10.795096 371.894318,4.098039 
	C371.619690,3.313278 371.363586,2.522015 371.049438,1.366884 
	C387.687561,1.000000 404.375122,1.000000 421.531342,1.000000 
	C424.341797,3.394153 427.312958,2.506391 430.035156,2.341660 
	C432.157135,2.213250 434.027222,2.036050 435.080139,4.626321 
	C436.153931,10.711645 441.741180,8.442051 444.967224,10.440728 
	C445.462677,10.747680 446.946198,10.156983 447.141113,10.979828 
	C448.348358,16.076647 452.822235,15.556458 456.424683,16.820938 
	C462.271790,18.873293 463.113159,20.956917 460.770142,26.445833 
	C459.533569,29.342745 460.816986,29.817972 463.360657,30.047499 
	C468.370819,30.499590 468.630676,31.282207 466.564728,35.969120 
	C465.368378,38.683170 463.195465,40.884937 462.730255,44.008957 
	C462.505554,45.517811 460.958649,46.408916 459.764984,45.039360 
	C457.434814,42.365952 454.483368,42.146835 451.299866,41.697620 
	C449.573792,41.454060 448.071655,39.623363 446.231628,38.254311 
	C444.522766,35.717205 446.351135,34.599533 447.750854,33.566277 
	C450.154785,31.791763 451.740448,30.295378 447.942200,28.275520 
	C445.757507,27.113741 445.694122,24.791206 445.441589,22.602011 
	C444.928772,18.156549 443.123077,17.310263 439.174072,19.555653 
	C438.302368,20.051300 437.586609,20.768364 436.768402,21.340370 
	C434.225006,23.118517 431.883545,23.454355 430.002045,20.389452 
	C429.332977,19.299515 428.905121,17.780506 427.084351,18.331881 
	C425.408722,18.839291 424.886505,20.229431 424.575043,21.754904 
	C424.132751,23.921272 424.175842,26.054323 424.972626,28.148048 
	C425.387451,29.238119 426.410065,30.417070 426.189758,31.375996 
	C424.203339,40.022133 431.581299,39.807724 436.143402,41.218399 
	C440.271576,42.494900 442.160675,44.948074 443.808899,48.707405 
	C444.360687,52.279022 447.347626,54.706463 447.001709,58.609146 
	C448.524567,62.512821 446.370087,64.310432 443.390625,65.954041 
	C439.962982,65.695236 437.360626,64.922607 435.695038,61.866718 
	C434.093140,58.927731 430.785706,59.936073 428.155975,59.542973 
	C427.232544,59.404938 426.089996,59.606316 425.278381,60.063011 
	C423.054565,61.314384 419.673767,58.892593 418.542267,61.157902 
	C417.341187,63.562481 421.196594,64.942039 421.858215,67.686745 
	C424.214935,70.383026 424.294800,73.411415 424.401947,76.371429 
	C424.491241,78.836845 423.702820,81.038033 421.132416,81.934715 
	C418.659729,82.797310 416.301605,82.590424 414.177338,80.753777 
	C409.867401,77.027443 409.954132,77.202324 405.316925,81.055786 
	C402.405029,83.475533 399.208130,85.526184 396.908173,88.633377 
	C396.040802,89.805168 395.289673,90.810905 397.135010,92.183685 
	C398.333862,93.553574 398.937195,94.886696 399.934235,96.390915 
	C400.650970,97.727219 401.365326,98.668732 401.711426,99.791870 
	C402.344391,101.845993 402.316284,103.815262 400.371674,105.156471 
	C398.391205,106.522423 396.487732,105.791611 394.938141,104.347977 
	C393.254425,102.779335 391.481750,101.242004 390.800842,98.849449 
	C390.323761,97.173088 390.884216,94.801727 388.780579,94.077110 
	C386.291626,93.219772 383.630615,92.239433 381.047516,92.417824 
	C378.268707,92.609718 375.840881,94.356323 376.063599,97.567139 
	C376.310974,101.133232 379.383667,99.127251 381.549255,99.705719 
	C382.946198,99.924942 383.891815,100.326721 385.161224,100.990295 
	C387.171326,102.576599 388.741119,104.028305 389.083496,106.725998 
	C384.287415,111.057762 381.627014,115.999855 381.450775,122.342957 
	C381.412994,123.703758 379.686981,124.686943 378.402771,125.477325 
	C374.947052,127.604118 372.364929,130.664719 369.661621,133.591324 
	C367.277954,136.171844 364.571014,138.447632 369.721375,141.427048 
	C372.548889,143.062744 372.041534,149.729218 369.483154,152.350876 
	C366.670990,155.232620 363.771240,154.200775 360.946716,152.291000 
	C360.117432,151.730270 359.426086,151.003235 358.636353,149.890762 
	C358.042542,146.178207 357.119476,144.411240 352.956848,146.130096 
	C350.582855,147.110367 347.151581,149.590118 344.640106,145.196899 
	C344.435150,143.631760 345.057526,142.622742 345.566925,141.581573 
	C346.303864,140.075363 346.048737,138.909088 344.273865,138.612473 
	C340.379608,137.961655 337.076416,136.490417 334.477295,133.293823 
	C333.477478,132.064178 331.314667,132.586166 329.376068,132.068756 
	C327.211029,131.276093 326.201813,129.802414 325.368744,128.146469 
	C323.933044,125.292717 321.999573,124.735657 319.206726,127.141502 
	C320.347260,128.603973 322.308319,128.731461 323.589081,129.825317 
	C324.904785,130.949005 326.118195,132.149261 325.296326,134.020493 
	C324.532867,135.758728 322.917969,136.219696 321.158600,136.053268 
	C318.835358,135.833481 316.841919,134.452789 314.243347,133.925003 
	C310.923340,130.505310 307.524994,128.313568 302.497742,129.879547 
	C295.929779,130.832199 297.340729,135.490189 297.223572,139.748962 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M518.731689,155.707550 
	C521.041687,155.999054 523.083313,155.999313 525.562500,155.999481 
	C528.480103,154.944244 532.229858,155.732224 532.762329,151.252106 
	C536.106201,148.300278 538.418640,152.292068 541.623413,151.985245 
	C544.351013,150.094238 549.218140,152.679794 549.887695,147.289307 
	C551.152649,144.142395 553.184326,141.931213 555.220764,139.509003 
	C553.687866,139.767166 552.316895,141.355881 550.866760,139.918793 
	C549.516052,138.580276 549.389099,136.715881 550.249817,135.287949 
	C553.729065,129.516022 550.478271,125.434258 546.846069,121.651672 
	C543.555420,118.224800 541.166504,114.282906 539.181396,110.078751 
	C536.481628,104.361145 538.268372,101.112206 544.959351,99.758904 
	C551.678528,100.089943 558.144714,100.086281 562.835022,106.087082 
	C565.040833,109.479065 568.088989,111.662468 570.113220,115.052437 
	C572.159546,113.013542 571.450562,111.417839 570.290527,109.597672 
	C566.889709,104.261581 566.882935,104.154701 571.811279,100.232384 
	C573.442505,98.934158 572.913635,97.556015 572.488403,96.208298 
	C571.367920,92.656776 571.480896,88.958298 570.981567,85.336136 
	C570.553162,82.228607 571.207336,79.910919 575.184326,80.547470 
	C574.794006,77.042725 572.306824,76.742325 569.767517,76.437012 
	C567.874878,76.209457 566.001282,75.634521 565.525940,73.539192 
	C565.011230,71.270523 566.545105,69.787354 568.186584,68.560539 
	C573.522400,64.572701 579.018250,66.422356 580.981934,72.836090 
	C581.324036,73.953468 581.417786,75.130531 581.720337,76.253922 
	C582.092163,77.634621 582.170776,79.674782 584.113159,79.412170 
	C585.767395,79.188515 585.992371,77.287422 586.211914,75.789948 
	C586.529236,73.625214 586.153809,71.467850 586.104370,69.306412 
	C586.051208,66.986336 586.696411,64.727005 589.254150,64.345062 
	C591.976929,63.938492 592.808044,66.133278 593.421204,68.376472 
	C594.013855,70.544510 593.215454,74.621880 596.828552,73.709579 
	C600.251953,72.845200 599.087646,69.206650 598.466675,66.404907 
	C597.003113,59.801472 595.513489,53.100590 594.344116,46.564037 
	C593.215454,40.255260 596.281677,33.137585 595.343506,26.212809 
	C599.775818,22.171865 598.610168,19.712112 593.560608,17.091730 
	C590.548767,15.528820 591.559387,12.351544 596.004456,9.398376 
	C592.201843,9.779739 589.085876,9.407489 586.300537,7.832096 
	C583.505371,6.251137 580.233948,6.052899 577.213867,4.279717 
	C577.000000,3.249801 577.000000,2.499868 577.000000,1.374967 
	C613.687561,1.000000 650.375122,1.000000 687.531372,1.000000 
	C689.804993,2.588065 692.538635,2.496045 694.384766,4.688909 
	C695.972107,8.430569 694.260071,10.655416 691.245422,11.539582 
	C686.341675,12.977790 684.990356,18.101368 681.107605,20.627810 
	C679.432251,21.717924 680.092163,23.785244 682.650391,23.672359 
	C685.834229,23.531868 688.114197,24.759720 689.728271,27.895571 
	C690.751892,31.734138 689.011902,33.576988 685.863525,34.511131 
	C684.583740,34.890823 683.266296,35.343727 681.956909,35.304867 
	C676.925659,35.155552 675.303162,37.720154 675.522949,42.442165 
	C675.701599,46.281872 674.061890,49.547386 671.088196,52.099922 
	C669.429443,53.523811 668.131287,55.292400 667.127563,57.211998 
	C666.218079,58.951447 663.846191,60.880989 666.771179,62.724201 
	C669.690735,64.563942 670.779907,62.340851 671.941589,60.092243 
	C674.515076,55.110981 677.437561,53.726284 682.937256,54.564323 
	C689.758850,55.603786 692.392273,61.288788 688.534485,67.045364 
	C686.653320,69.852425 685.004333,72.639595 684.439148,76.035347 
	C683.926697,79.114182 682.378723,81.412514 678.845337,81.235123 
	C675.451965,81.064743 672.672241,79.817642 671.677185,76.159393 
	C671.545959,75.676834 671.446533,75.187027 671.353333,74.696075 
	C670.860168,72.097031 670.189514,69.633614 666.699951,70.171631 
	C663.540588,70.658730 659.882446,70.167915 659.483459,75.121750 
	C659.218384,78.413147 658.569153,81.696396 658.170349,84.999573 
	C657.833618,87.789368 656.664062,90.272820 653.115173,89.932716 
	C650.100647,89.643837 647.669434,86.135994 647.781921,83.060768 
	C647.841675,81.426521 650.693115,79.807388 648.855286,78.839584 
	C646.049438,77.361977 644.636353,73.945816 641.123230,73.647141 
	C637.939697,73.376495 635.037964,71.844742 631.934814,71.353691 
	C627.274536,70.616249 629.687805,76.947525 625.780884,77.506241 
	C622.868103,75.891769 623.260193,72.784134 621.886169,70.574379 
	C621.102539,69.314056 620.565430,67.546486 618.652283,68.225845 
	C616.565918,68.966705 617.767883,70.671387 617.898438,72.064850 
	C618.301331,76.366623 620.903503,78.058807 625.249512,78.297607 
	C628.031372,82.629074 624.625427,82.960571 622.103516,83.309113 
	C615.978455,84.155624 615.894409,84.027420 615.655640,89.991592 
	C615.543823,92.787018 614.644104,95.026421 612.774719,97.189476 
	C610.182800,100.188660 607.662720,103.320915 606.260925,107.154091 
	C605.004272,110.590340 602.669861,111.931885 599.218628,110.229034 
	C596.523804,108.899406 595.482239,110.269447 595.069214,112.572556 
	C594.642395,114.952599 594.469910,117.380302 597.716736,118.041885 
	C599.884399,118.483589 602.024597,118.053284 604.176697,117.813255 
	C609.415649,117.228920 612.815063,118.917061 613.984192,122.610588 
	C615.157593,126.317581 612.905823,131.881882 608.823242,133.451218 
	C604.912109,134.954651 602.096375,137.460129 599.264709,140.334473 
	C595.652649,144.000931 593.486023,143.295227 591.913208,138.362228 
	C591.609619,137.409866 591.447998,136.413773 591.133423,135.467407 
	C590.334595,133.064270 590.436768,129.875931 586.412109,130.336899 
	C582.817688,130.748627 578.932434,130.229446 578.387573,135.609039 
	C577.931458,140.112518 574.048767,140.962067 570.247375,141.657928 
	C574.400452,144.777359 578.394653,145.433975 582.710388,142.419220 
	C584.336243,141.283478 586.331177,140.718842 588.403992,140.816040 
	C589.758423,140.879547 591.034302,141.234589 592.183167,141.973328 
	C594.331604,143.354843 595.020142,145.726135 594.184509,147.672195 
	C592.595154,151.373657 596.819519,156.865341 590.754456,159.023849 
	C588.954956,159.664276 587.598877,160.682816 586.232422,161.955490 
	C582.425293,165.501266 578.436157,164.776962 577.156799,159.700653 
	C575.949097,154.908890 573.216736,154.613190 569.080078,155.158997 
	C567.076538,154.892761 565.599365,154.187393 564.129089,153.461411 
	C562.497681,152.655869 560.850525,151.665146 559.276062,153.543076 
	C558.178711,154.851990 558.174072,156.226135 559.191467,157.578537 
	C560.583252,159.428711 562.221130,159.157578 563.942261,158.083679 
	C565.080505,157.373459 566.277405,156.888916 567.982605,157.395035 
	C570.283325,159.001129 569.749939,161.042511 569.612793,163.024734 
	C569.454590,165.311874 570.506348,166.203278 572.653259,165.218857 
	C575.653442,163.843170 578.704346,161.461548 581.213257,165.809418 
	C582.751221,168.474655 580.909851,174.418137 577.876892,177.126373 
	C571.549561,182.776199 564.351196,181.977371 559.170715,175.050430 
	C558.771484,174.516541 559.447571,174.868439 558.905945,174.659073 
	C554.026245,172.772705 551.683716,168.411865 548.769226,164.570526 
	C547.576843,162.998993 546.175171,163.450058 544.378479,164.157166 
	C539.934814,165.906036 536.321594,169.464264 531.009033,169.963043 
	C530.409424,169.755554 530.179199,169.638824 529.659546,169.257690 
	C526.369324,162.998077 526.369324,162.998077 519.823181,163.911453 
	C515.026550,164.580704 510.461060,159.078064 511.980774,154.482071 
	C512.379333,153.276840 513.063721,152.408279 514.378601,152.272095 
	C516.708496,152.030807 517.319275,154.082809 518.731689,155.707550 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M939.213989,1280.719116 
	C930.312439,1281.000000 921.624817,1281.000000 912.468628,1281.000000 
	C908.952515,1278.270752 905.858032,1276.481201 902.235840,1280.761597 
	C897.975464,1281.000000 893.950928,1281.000000 889.463196,1281.000000 
	C887.627014,1279.556396 885.573547,1279.089233 884.214600,1277.589233 
	C882.732483,1275.953247 882.001343,1277.557983 880.828491,1278.647339 
	C879.294373,1279.405029 878.037720,1279.396362 876.403809,1278.923096 
	C874.346008,1277.003662 872.308777,1276.431030 869.818604,1276.370850 
	C865.115417,1276.257324 859.559570,1268.139160 860.886902,1263.687622 
	C861.816101,1260.571045 865.385010,1259.801147 868.157349,1262.187622 
	C869.821777,1263.620483 870.679871,1265.693848 872.012573,1267.397949 
	C873.229065,1268.953369 874.579895,1270.090210 876.683960,1269.046997 
	C878.531189,1268.130981 878.740417,1266.557495 878.207153,1264.802490 
	C877.322937,1261.892090 877.543640,1258.848022 877.044495,1255.897095 
	C876.603638,1253.290039 875.537048,1251.063110 872.621948,1250.486206 
	C869.433716,1249.855103 869.385864,1252.679199 868.470703,1254.459473 
	C866.326477,1258.630493 861.145264,1261.443848 855.959290,1260.916138 
	C852.277100,1260.541382 848.735046,1260.228149 845.256897,1262.112427 
	C842.689392,1263.503296 840.698853,1262.245850 839.640259,1259.740723 
	C838.621948,1257.331177 838.167786,1254.893188 840.886597,1253.099609 
	C845.760193,1249.884277 846.522156,1247.618042 844.183228,1242.275146 
	C843.162659,1239.943726 842.542786,1237.610596 842.313110,1235.081909 
	C841.766296,1229.061890 840.419556,1228.117920 833.846680,1229.052490 
	C831.622559,1228.649048 829.480835,1227.546997 830.222412,1231.288208 
	C830.849976,1234.453491 830.192749,1238.000977 833.691101,1240.470947 
	C834.760376,1241.854126 835.093262,1243.065430 834.537903,1244.781494 
	C829.255676,1246.988892 824.114014,1245.778809 819.572083,1243.947021 
	C815.496155,1242.303101 811.527527,1240.218140 807.376953,1238.609131 
	C805.166138,1237.752075 803.397400,1236.269409 801.961304,1233.882080 
	C800.353516,1230.526245 799.129028,1227.418823 798.213379,1224.223389 
	C796.980835,1219.921875 798.490051,1217.402710 802.926941,1216.666992 
	C807.163452,1215.964600 811.423401,1215.147339 815.751953,1216.706543 
	C818.300415,1217.624512 821.074280,1217.747314 823.193176,1215.476562 
	C824.752319,1213.805786 825.957703,1211.595825 824.247375,1209.684692 
	C822.808777,1208.077148 820.864319,1206.749146 818.127991,1208.531250 
	C814.424133,1210.943604 803.249023,1209.092041 800.427979,1205.924438 
	C798.362671,1203.605469 798.311218,1201.069824 800.407959,1198.729492 
	C803.316772,1195.482544 806.695312,1193.344360 811.388428,1194.232178 
	C813.666138,1194.663208 815.966064,1195.158936 818.316772,1195.070679 
	C820.954773,1194.971558 822.544861,1193.572876 822.102356,1190.956299 
	C821.653748,1188.304321 819.445618,1188.965576 817.703186,1189.453125 
	C815.460876,1190.080688 813.261902,1190.895386 811.179688,1191.948242 
	C808.161987,1193.474121 805.138000,1193.796631 802.093262,1192.154297 
	C800.068115,1191.062012 798.608215,1191.067383 797.030334,1193.187378 
	C794.532227,1196.543945 790.806458,1195.853638 787.380676,1195.106201 
	C784.213257,1194.415039 783.660522,1191.674927 783.267700,1188.935547 
	C782.842529,1185.969238 782.312073,1183.018066 782.313782,1180.006470 
	C782.315063,1177.755005 781.574768,1175.871948 780.020264,1174.138916 
	C776.328247,1170.023071 776.296265,1167.900269 779.535828,1164.900635 
	C782.827942,1161.852417 787.797668,1161.729126 790.863708,1164.628906 
	C791.966980,1165.672363 792.632080,1167.039185 793.494141,1168.260498 
	C794.972595,1170.354980 796.976440,1170.859131 799.158508,1169.695190 
	C801.410706,1168.494019 802.123230,1163.584351 800.701111,1161.022827 
	C799.285034,1158.472290 797.602783,1159.593628 795.625366,1160.279175 
	C787.753357,1163.007812 779.568359,1162.363403 771.465576,1161.988159 
	C767.776611,1161.817261 766.437073,1157.958862 763.805847,1156.045776 
	C760.231323,1153.446777 762.261597,1150.096436 762.876038,1146.915283 
	C763.472839,1143.825562 765.550842,1140.406982 761.216064,1137.763550 
	C759.777039,1133.241577 762.967407,1133.593384 765.287964,1133.812866 
	C768.180786,1134.086426 770.927063,1135.645630 773.961243,1134.088379 
	C774.931641,1133.590332 776.330200,1133.975586 777.076782,1135.074829 
	C778.027588,1136.474731 777.121399,1137.600952 776.325562,1138.672729 
	C775.224976,1140.154907 773.780212,1141.294189 772.406799,1142.506836 
	C770.376221,1144.299683 770.365601,1146.473511 772.194153,1148.158569 
	C774.184448,1149.992554 775.570007,1148.207031 776.467041,1146.559082 
	C778.044739,1143.660767 780.265869,1142.514282 783.491089,1143.360718 
	C784.131653,1143.528687 784.811279,1143.506226 785.479614,1143.468628 
	C793.221802,1143.033447 793.221863,1143.034302 796.870483,1148.899292 
	C797.879700,1147.572998 796.748474,1146.719360 796.248901,1145.859985 
	C794.651733,1143.112793 793.578247,1140.208008 795.891235,1137.521484 
	C798.436768,1134.564819 800.643005,1138.026123 803.074646,1138.422607 
	C804.219910,1137.146118 802.845215,1136.339111 802.486572,1135.417236 
	C801.842590,1133.761353 801.728821,1132.196411 802.660278,1130.631714 
	C803.803162,1128.711792 814.168640,1123.889282 816.410278,1124.180664 
	C818.266907,1124.421997 819.004456,1125.689575 819.055969,1127.282471 
	C819.120544,1129.281006 819.832031,1130.447144 822.313477,1130.496826 
	C825.821899,1130.743896 829.037842,1129.996460 832.474487,1131.362671 
	C833.379333,1131.505737 833.896057,1131.575195 834.784058,1131.746094 
	C838.756104,1133.139282 839.652527,1135.445923 837.904846,1138.883545 
	C836.395569,1141.852173 835.353577,1145.033569 833.945435,1148.048462 
	C832.942078,1150.196655 831.255310,1151.422974 828.758606,1151.137817 
	C826.467651,1150.876099 825.273254,1149.387817 824.634705,1147.321777 
	C824.092041,1145.566162 823.778503,1143.756592 823.423584,1141.959595 
	C822.965332,1139.639404 821.909729,1137.595337 819.364258,1137.645020 
	C816.845093,1137.693970 814.180725,1138.656982 813.197998,1141.207764 
	C811.294250,1146.149048 812.434570,1151.067139 814.270264,1155.812012 
	C814.812012,1157.212402 816.419861,1157.260742 817.630920,1157.736328 
	C820.634949,1158.915771 821.601929,1161.124146 820.883179,1164.137329 
	C820.209045,1166.963623 818.411987,1168.381470 815.440491,1168.151489 
	C815.108154,1168.125610 814.775513,1168.088379 814.442993,1168.083862 
	C812.494873,1168.057251 809.596313,1167.420898 809.329102,1169.731812 
	C809.048584,1172.156860 811.931152,1172.775513 814.075012,1173.137207 
	C816.903870,1173.614624 819.720825,1173.203369 822.527466,1172.754150 
	C826.205444,1172.165649 829.309631,1172.627075 831.659546,1176.180054 
	C833.627930,1179.155884 836.442444,1178.605103 838.103149,1175.331177 
	C841.108032,1169.407227 841.109314,1169.400269 847.959961,1170.622803 
	C850.990356,1171.163574 853.918762,1170.304077 856.885498,1170.000732 
	C858.542908,1169.831177 860.191589,1169.458496 861.846802,1169.901611 
	C863.752991,1170.411621 865.200867,1171.522095 865.534485,1173.542358 
	C865.844849,1175.421753 864.685669,1176.694214 863.240784,1177.660400 
	C861.270569,1178.977905 858.991638,1179.570435 856.739990,1180.170532 
	C854.808838,1180.685059 853.792725,1181.605835 854.589539,1183.738892 
	C855.777771,1186.919922 854.821899,1190.777222 857.920471,1193.405762 
	C859.055481,1194.368652 858.587585,1195.949951 857.606140,1197.026123 
	C855.022217,1199.859741 857.062561,1201.676270 858.773438,1203.863159 
	C864.860046,1211.642578 873.765259,1212.427856 882.443970,1213.155640 
	C887.089539,1213.545288 891.796326,1213.715942 896.699829,1212.854736 
	C900.479248,1212.190918 905.653076,1214.367920 908.851318,1218.040283 
	C910.380066,1219.795776 909.311951,1221.397827 907.997498,1222.888794 
	C903.643188,1227.827759 903.663757,1227.846069 909.463501,1231.522461 
	C913.526245,1234.097900 914.755310,1237.863892 912.869385,1242.933350 
	C910.601562,1244.639404 908.460571,1245.894897 906.481812,1247.439209 
	C900.276794,1252.282227 900.119263,1253.942261 905.932495,1260.171997 
	C907.040039,1257.761719 904.669495,1254.380005 907.717224,1252.875977 
	C911.148193,1251.182861 912.081909,1254.968994 914.143921,1256.282715 
	C915.314575,1257.028320 916.591736,1257.389038 917.932678,1257.379517 
	C922.123779,1257.349976 925.719910,1258.921753 929.271545,1261.588013 
	C931.003052,1264.537109 929.632507,1267.035278 928.883362,1269.508545 
	C927.918823,1272.692993 928.376648,1274.536377 931.973511,1275.842773 
	C934.604004,1276.798096 938.564941,1276.364990 939.213989,1280.719116 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M877.677917,85.208313 
	C882.939392,78.973381 881.888062,74.434052 874.879700,71.958076 
	C873.204529,71.366257 873.428162,70.713989 874.086487,69.422684 
	C877.049927,63.610130 876.388123,62.084908 870.305725,60.267750 
	C869.671631,60.078304 869.000061,60.018005 868.355530,59.858643 
	C866.862976,59.489586 866.074402,58.523273 866.162842,56.982674 
	C866.268860,55.136276 867.671265,54.974846 869.045166,55.033745 
	C871.414978,55.135345 873.678101,54.744450 875.872192,53.879841 
	C876.667175,53.566582 877.427551,53.134899 877.532288,52.216515 
	C877.670776,51.002155 876.785583,50.479752 875.814209,50.104733 
	C873.779419,49.319176 871.691956,48.866505 869.486267,48.947090 
	C868.693970,48.976040 867.689148,48.929604 867.630676,47.798935 
	C867.579224,46.803173 868.423767,46.453953 869.215698,46.169453 
	C872.994385,44.812023 882.773560,47.697403 885.118042,50.904175 
	C886.300476,52.521400 885.236572,53.449448 883.846191,53.857075 
	C880.778320,54.756504 880.685730,56.912979 881.323486,59.381733 
	C882.074951,62.291012 884.098389,63.246696 886.981384,63.017406 
	C890.024109,62.775425 892.600952,61.565861 893.743286,58.781380 
	C894.878601,56.014122 892.769592,54.235733 890.869202,52.474037 
	C888.389465,50.175220 884.678650,47.862293 890.086487,44.596077 
	C891.278870,43.875877 890.254333,42.785839 889.578918,42.123486 
	C888.055847,40.629715 887.716553,38.629227 887.138306,36.743652 
	C885.789246,32.344372 884.495361,32.014420 881.205627,35.348526 
	C880.623840,35.938190 880.245361,36.803589 879.573059,37.215046 
	C877.440125,38.520535 874.792480,38.683754 872.976440,37.393532 
	C870.672119,35.756409 872.949951,33.910133 873.849670,32.267853 
	C875.602356,29.068598 878.556152,27.148304 881.521667,25.321056 
	C884.034607,23.772686 885.699707,22.104794 884.484253,18.858839 
	C883.380188,15.910440 885.601746,14.765161 887.723511,14.388492 
	C890.301575,13.930816 893.466980,13.272896 895.556335,14.328453 
	C899.455322,16.298269 903.648315,14.881526 907.522400,16.503056 
	C910.264465,17.650768 910.779419,14.807985 910.900452,12.905520 
	C911.132446,9.259651 910.988159,5.589840 911.000000,1.464982 
	C918.020935,1.000000 925.041931,1.000000 932.531433,1.000000 
	C936.730469,3.295962 940.971375,3.094942 945.060242,3.195446 
	C950.600159,3.331620 956.015198,3.841632 961.133484,6.092759 
	C963.576111,7.167068 965.943726,6.830324 968.433960,6.256308 
	C972.777649,5.255058 977.131714,3.804321 982.006714,5.560369 
	C983.768433,6.361973 984.961304,7.896060 987.149292,6.675785 
	C992.109070,5.269644 996.555969,1.992883 1001.782776,5.672752 
	C1001.861694,8.052935 1005.241028,9.726454 1002.414368,12.156357 
	C999.828796,14.379082 997.232117,15.880958 993.931030,14.087654 
	C990.345764,12.139908 985.891968,14.208818 982.523376,11.468283 
	C982.417114,11.381812 982.142883,11.346912 982.050903,11.419543 
	C973.350708,18.290468 963.427063,8.744551 954.287231,13.735901 
	C953.561707,16.145630 954.790405,17.285658 956.623169,17.294367 
	C960.566162,17.313101 961.554443,19.668169 961.186035,22.854927 
	C960.835205,25.890787 961.853210,28.320904 963.338135,30.822554 
	C965.457458,34.392914 967.332336,38.100777 968.701416,42.054802 
	C969.717834,44.989929 970.221191,47.771595 968.501953,50.669769 
	C967.203369,52.858818 967.907227,55.175495 968.609253,57.436249 
	C969.194519,59.321377 969.088074,61.133266 967.252563,62.309895 
	C965.276428,63.576645 963.444885,63.012207 961.800476,61.542809 
	C961.180542,60.988873 960.645447,60.343746 960.004700,59.812870 
	C958.792542,58.808556 957.475037,58.049713 955.949463,59.087574 
	C954.604004,60.002945 954.893372,61.422310 955.164795,62.723801 
	C955.563049,64.633301 956.719788,66.485962 955.234375,68.738762 
	C954.038513,73.919792 952.425903,78.738533 954.852783,83.826866 
	C955.527832,85.242241 955.727966,88.305870 952.823303,88.493538 
	C950.767578,88.626350 949.115356,87.204926 949.006226,84.733582 
	C948.947998,83.413979 948.934265,81.606430 947.275024,81.645508 
	C945.402283,81.689621 946.255737,83.726387 945.842468,84.857811 
	C944.368835,88.891960 945.203491,93.086937 945.019775,97.210854 
	C944.957703,98.603706 945.262268,99.947990 946.552185,100.676704 
	C950.964417,103.169228 948.138611,106.528297 947.466431,109.443405 
	C947.081482,111.112747 944.983765,111.507126 943.723877,110.593025 
	C941.648315,109.087189 938.030151,108.808495 938.541077,104.843895 
	C938.815063,102.718262 938.865845,100.560944 938.950317,98.415283 
	C938.983215,97.579010 938.813965,96.696556 937.869934,96.386375 
	C936.919250,96.073967 936.222595,96.635109 935.882446,97.424431 
	C934.802795,99.929787 932.921082,101.607719 934.090515,105.425926 
	C935.281067,109.313210 933.552429,114.351929 932.266541,118.635704 
	C931.585876,120.903320 928.566467,121.012238 926.149048,121.012527 
	C924.136475,121.012772 923.383179,120.180260 922.874695,118.181175 
	C921.813171,114.007767 918.868896,117.277206 916.398376,116.987900 
	C914.419312,116.938812 913.493225,115.831947 912.283264,114.547882 
	C909.942749,111.245277 907.951416,107.917488 903.078003,110.796677 
	C900.705505,111.074577 898.979309,111.961708 897.294128,113.045807 
	C895.095215,114.460335 892.722473,114.813034 889.918823,113.594719 
	C887.510132,111.350510 887.539185,108.664955 887.358948,106.027321 
	C886.960449,100.194748 886.653625,99.929428 880.493652,100.145393 
	C879.598755,100.130638 879.098938,100.028168 878.271362,99.671616 
	C875.989502,97.320465 875.636230,94.412804 874.302490,91.542099 
	C873.981445,88.554184 875.262024,86.762215 877.677917,85.208313 
z"/>
<path fill="#D0CDCC" opacity="1.000000" stroke="none" 
	d="
M843.750000,1.000000 
	C845.422607,4.217711 849.443237,5.689319 849.543335,10.012672 
	C849.577332,11.477565 852.131592,11.133160 853.300354,10.039110 
	C855.447876,8.028679 857.956604,7.021846 859.489746,9.960685 
	C861.101440,13.050356 857.904724,13.189037 855.924255,14.382648 
	C852.363281,16.528786 849.567566,14.941619 846.130371,14.332001 
	C839.615723,13.176558 833.279480,10.779981 826.512024,10.974256 
	C824.013794,11.045975 822.788635,12.992052 820.969788,14.047405 
	C817.604126,16.000263 820.546509,17.461170 821.386597,19.178896 
	C822.735596,21.936979 822.161377,25.135838 819.723572,26.260492 
	C813.819031,28.984507 815.463440,33.486294 817.095764,37.157650 
	C819.296021,42.105957 818.878723,46.775604 818.000854,51.711895 
	C817.624817,53.826199 816.304749,55.959579 814.578735,54.482738 
	C810.224060,50.756546 806.187805,54.148178 802.055969,54.228168 
	C800.763733,54.253185 799.546509,54.958492 798.349121,53.835651 
	C797.423950,52.968021 796.425293,51.621845 794.975342,52.851898 
	C793.670288,53.959038 793.901123,55.574299 794.217957,57.049789 
	C794.759033,59.569355 796.335266,62.266457 792.372192,63.939384 
	C789.132019,62.542038 789.132019,62.542038 787.733154,55.213577 
	C782.424988,58.615692 776.513000,58.411896 770.992249,57.001045 
	C766.866638,55.946751 764.876282,57.379436 763.742920,60.521641 
	C762.628540,63.611118 760.999573,65.399467 757.677673,65.305008 
	C754.475464,65.213943 753.048157,63.104565 752.239075,60.330750 
	C751.764038,58.702381 750.968262,57.244312 749.585327,56.198792 
	C747.992126,54.994267 746.355164,53.468338 744.225464,54.856842 
	C742.144653,56.213478 742.204102,58.628876 742.182434,60.716152 
	C742.155029,63.351089 744.539246,63.057522 746.246826,63.337616 
	C756.114807,64.956245 758.295471,67.898048 756.379272,77.497681 
	C755.597656,81.413048 754.617371,85.267036 754.487488,89.300728 
	C754.408875,91.742912 753.754456,94.070000 750.062378,93.591576 
	C744.080750,91.371620 742.959473,89.435173 743.773987,83.258102 
	C744.483582,77.877296 742.651428,75.853943 737.294617,76.311165 
	C734.973511,76.509285 732.627319,76.439369 730.331909,76.864792 
	C729.027893,77.106483 727.357849,77.422577 727.385559,79.014900 
	C727.412598,80.568436 729.091858,81.131874 730.374023,81.117821 
	C738.752625,81.026031 739.991150,88.032303 742.794617,93.466179 
	C743.173584,94.200653 742.948242,95.103569 742.511963,96.261360 
	C740.040894,99.142914 737.055054,99.559105 733.463135,99.319931 
	C730.148621,98.006226 727.082520,98.640343 723.678284,98.456665 
	C722.725037,98.449753 722.181946,98.435036 721.242554,98.342819 
	C715.755676,97.774292 714.096130,99.070038 713.770264,103.865005 
	C713.624512,106.009407 713.829102,108.182617 713.282532,110.292854 
	C712.682251,112.610619 711.303467,114.116745 708.792297,114.208893 
	C706.280945,114.301033 704.817261,112.864761 703.991516,110.642235 
	C703.406311,109.066994 703.256042,107.400887 703.219849,105.751259 
	C703.136841,101.969856 701.483887,99.468048 697.328125,98.474258 
	C693.177368,97.172882 692.125671,94.408768 693.314270,90.967468 
	C694.534729,87.434090 697.612915,87.265839 700.763123,87.801689 
	C702.421143,88.083717 703.858154,88.954697 705.333862,89.706261 
	C706.962036,90.535538 708.608032,91.346428 710.442200,91.583527 
	C713.241211,91.945343 717.348450,89.453590 717.330444,87.158447 
	C717.306946,84.185188 715.755371,82.125061 712.611511,81.721886 
	C708.131470,81.147324 706.162659,78.722076 706.906921,74.217766 
	C707.221313,72.314598 706.663513,70.637085 705.750061,68.998375 
	C704.587097,66.911842 704.174622,64.655235 704.493286,61.871052 
	C704.440369,58.647598 703.865540,55.824043 704.685425,52.595173 
	C705.314880,50.408718 706.594421,49.364094 708.592773,48.526672 
	C711.394226,48.000271 711.299377,46.077843 711.678833,44.250191 
	C712.601379,39.806553 716.234863,37.636692 720.226746,39.533169 
	C723.440918,41.060181 727.574097,41.375961 728.581055,46.385727 
	C729.175476,49.343380 732.748474,48.342304 735.130066,48.051815 
	C737.566406,47.754650 737.615540,45.631062 737.626221,43.800755 
	C737.670898,36.158421 739.760559,34.880333 746.606323,38.400948 
	C753.457336,41.924252 755.788513,41.336880 760.216492,34.819683 
	C761.442688,33.014942 762.628845,31.226610 765.631104,30.281727 
	C762.666687,29.087221 760.431763,28.064358 758.269958,28.218884 
	C753.293213,28.574617 749.927124,26.701488 747.819946,22.023891 
	C748.734924,26.198439 747.179749,29.384623 744.512451,32.045227 
	C742.351196,34.201077 739.577942,34.884739 736.666138,33.819069 
	C734.093811,32.877644 733.428345,30.586052 733.523682,28.114014 
	C733.678101,24.113810 732.356140,22.060839 727.980713,23.367432 
	C727.039612,23.648449 725.989136,23.507042 725.001343,23.311752 
	C722.310059,22.779716 720.315918,21.387724 719.981506,18.510553 
	C719.640015,15.571570 721.320129,13.507144 723.948120,12.920712 
	C731.399414,11.257965 738.896240,9.489496 746.572388,11.918668 
	C748.571350,12.551267 750.569580,13.894904 752.783691,12.723050 
	C753.364502,12.415661 753.705811,11.501694 753.289001,10.971212 
	C751.663757,8.902998 749.142883,8.873699 746.899109,8.840010 
	C741.222534,8.754779 735.606445,8.051994 729.965698,7.603745 
	C727.139282,7.379137 724.296204,7.199760 721.625366,5.144341 
	C720.888611,3.762765 720.919861,2.711057 721.749390,1.340054 
	C753.687561,1.000000 785.375122,1.000000 817.531372,1.000000 
	C822.609192,5.362442 828.416077,5.666389 834.267944,5.768775 
	C837.776245,5.830155 839.314270,2.106641 842.724976,1.211249 
	C843.000000,1.000000 843.500000,1.000000 843.750000,1.000000 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M1230.763672,1012.242188 
	C1237.146484,1012.153809 1240.202759,1014.541748 1241.572510,1020.338196 
	C1241.710449,1020.921936 1241.758667,1021.776672 1241.437378,1022.168518 
	C1240.712769,1023.051941 1239.941040,1022.302612 1239.306763,1021.829163 
	C1237.122559,1020.198853 1234.907227,1019.476685 1232.478760,1021.346863 
	C1231.272217,1022.276001 1230.716675,1023.365479 1231.635010,1024.771484 
	C1232.802856,1026.559692 1242.026855,1027.188110 1242.800293,1025.335938 
	C1244.333130,1021.664917 1247.042358,1023.804138 1247.791626,1024.836914 
	C1249.679565,1027.438843 1252.272583,1028.002197 1254.930298,1028.830933 
	C1255.893188,1029.131104 1257.705566,1028.663940 1257.367310,1030.493774 
	C1257.096924,1031.955444 1255.199951,1032.921021 1254.298828,1032.537842 
	C1249.310181,1030.416382 1247.783691,1035.376465 1244.707153,1037.014771 
	C1243.321777,1037.752563 1242.050415,1039.389282 1244.328369,1040.934570 
	C1244.712036,1041.194702 1245.333374,1041.830566 1245.325317,1041.839722 
	C1240.342896,1047.552734 1247.207275,1046.824219 1249.102173,1048.672119 
	C1251.563843,1051.072388 1253.790771,1053.251099 1258.151123,1052.499023 
	C1260.911011,1052.023193 1264.208252,1052.812378 1267.403687,1052.181885 
	C1265.345337,1058.771118 1260.565430,1062.447388 1254.503540,1062.305176 
	C1253.642090,1062.285156 1252.799316,1062.100098 1252.371460,1063.014648 
	C1251.971924,1063.869141 1252.300415,1064.702393 1252.999878,1065.238647 
	C1256.226929,1067.712402 1260.759399,1067.647583 1263.664429,1071.240356 
	C1264.535767,1072.317993 1265.775391,1069.573975 1265.416748,1067.845703 
	C1264.733398,1064.551392 1265.211670,1063.277222 1268.873047,1065.152588 
	C1271.773071,1066.637939 1273.881836,1064.781494 1273.652710,1061.423096 
	C1273.383301,1057.474976 1272.959839,1053.605469 1277.455811,1051.265991 
	C1279.154053,1050.382446 1279.007202,1048.103760 1279.003784,1046.242310 
	C1278.991211,1039.412476 1278.961426,1032.582275 1279.028931,1025.753052 
	C1279.044434,1024.187744 1278.389038,1022.345459 1280.614868,1021.112305 
	C1281.000000,1042.687622 1281.000000,1064.375122 1281.000000,1086.531372 
	C1280.477051,1087.423340 1280.335205,1087.949951 1280.786011,1088.789551 
	C1281.000000,1089.750000 1281.000000,1090.500000 1281.000000,1091.625000 
	C1279.475952,1094.063721 1279.325073,1096.228516 1280.760864,1098.746338 
	C1281.000000,1102.692871 1281.000000,1106.385864 1281.000000,1110.539307 
	C1278.089355,1111.736328 1279.061523,1114.136108 1279.046997,1115.936646 
	C1279.016113,1119.786987 1279.287720,1123.624268 1276.032837,1126.567871 
	C1274.910767,1127.582520 1274.930298,1128.945190 1275.647461,1130.235229 
	C1277.993286,1134.455566 1276.940552,1139.040894 1276.886353,1143.453857 
	C1276.844971,1146.835815 1275.121216,1148.720825 1271.266724,1147.175659 
	C1269.140991,1146.323364 1266.805176,1146.010986 1264.606079,1145.321533 
	C1259.868530,1143.835938 1258.659424,1141.286011 1261.351196,1137.195679 
	C1263.777710,1133.508301 1261.905029,1128.486450 1265.532593,1125.263916 
	C1266.040771,1124.812378 1265.230225,1123.734375 1264.389160,1123.378174 
	C1263.267212,1122.902954 1262.335815,1123.347168 1261.920898,1124.359253 
	C1260.826416,1127.028687 1259.003296,1127.176147 1256.357056,1126.047363 
	C1255.610596,1126.378174 1255.149048,1126.529907 1254.306519,1126.227295 
	C1251.749390,1122.412476 1254.018677,1118.910400 1254.356079,1115.411011 
	C1254.539062,1113.512939 1256.720825,1114.112427 1258.123657,1114.009033 
	C1259.652954,1113.896240 1261.162109,1113.825317 1261.717407,1112.027588 
	C1261.986938,1111.154663 1261.705078,1110.349487 1261.015991,1109.807251 
	C1259.661377,1108.741455 1258.522949,1109.825439 1257.362549,1110.323486 
	C1251.865479,1112.682617 1246.534790,1110.538086 1244.324463,1105.019409 
	C1243.930664,1104.036377 1244.001831,1102.993164 1244.866821,1102.293091 
	C1246.215454,1101.201538 1247.730957,1100.696655 1249.484375,1101.386230 
	C1254.751831,1103.457520 1260.505493,1101.411743 1263.732178,1096.221802 
	C1266.088867,1094.667969 1268.072632,1093.302856 1270.581299,1092.590820 
	C1274.405884,1091.505005 1277.330322,1085.006470 1275.970459,1081.202393 
	C1275.447144,1079.738647 1275.181030,1078.344116 1275.369629,1076.824829 
	C1275.581787,1075.117554 1275.046387,1073.745483 1273.193726,1073.517578 
	C1271.109497,1073.261230 1270.243774,1074.722290 1269.856323,1076.479858 
	C1269.526978,1077.973633 1270.065918,1079.457031 1270.092407,1080.948364 
	C1270.144653,1083.898315 1269.297119,1086.220581 1265.558838,1086.404541 
	C1265.016724,1086.031006 1264.782837,1085.794922 1264.561035,1085.546997 
	C1264.718872,1085.758667 1264.929810,1085.918335 1265.354248,1086.363159 
	C1266.838013,1088.875854 1269.916504,1091.116577 1265.331543,1093.213379 
	C1261.833130,1089.701782 1258.335938,1089.934814 1254.459717,1091.715698 
	C1251.681519,1092.992065 1248.509033,1096.513794 1246.229614,1090.717529 
	C1245.647583,1089.237793 1243.551880,1090.347656 1242.175537,1090.916138 
	C1240.499512,1091.608154 1238.842163,1092.395020 1237.102417,1092.866211 
	C1235.947632,1093.178955 1234.731323,1092.598389 1233.947388,1091.716553 
	C1232.753418,1090.373779 1234.148926,1089.720703 1234.913330,1088.928101 
	C1236.582642,1087.196899 1236.210449,1085.513550 1234.411255,1084.314697 
	C1230.725586,1081.858887 1228.949463,1078.208496 1227.565674,1074.234253 
	C1226.441772,1071.006348 1224.866821,1069.661133 1220.867188,1070.600098 
	C1217.564575,1071.375610 1213.237671,1072.753174 1210.523560,1068.368408 
	C1209.478027,1066.679565 1208.364746,1067.504639 1207.546631,1068.915527 
	C1206.748413,1070.292358 1206.148560,1072.474121 1204.031982,1071.567139 
	C1201.914062,1070.659546 1201.897949,1068.408569 1202.140747,1066.360474 
	C1202.217529,1065.712036 1202.538818,1065.096069 1202.695068,1064.452515 
	C1202.929810,1063.485840 1203.271851,1062.323242 1202.295532,1061.718384 
	C1201.224243,1061.054565 1200.490356,1062.078125 1199.828003,1062.793213 
	C1199.378906,1063.278076 1199.083252,1063.919067 1198.596436,1064.352539 
	C1197.602661,1065.237305 1196.466064,1066.270874 1195.096680,1065.276489 
	C1193.607422,1064.194946 1194.614014,1062.909424 1195.165039,1061.727905 
	C1195.760742,1060.450439 1197.460938,1059.473267 1196.475220,1057.766113 
	C1196.031860,1056.998169 1195.047974,1056.950806 1194.214600,1057.193970 
	C1192.240723,1057.770020 1190.989624,1059.144897 1191.082275,1061.176147 
	C1191.227051,1064.355347 1189.694824,1065.329956 1186.417603,1064.999512 
	C1184.334473,1063.517334 1184.711182,1061.857544 1185.548340,1060.131470 
	C1187.515381,1056.075439 1187.291504,1055.605713 1182.336670,1053.960083 
	C1180.521973,1054.121948 1178.642090,1053.709839 1177.639404,1054.463867 
	C1174.357056,1056.932373 1172.844849,1054.462891 1171.134766,1052.538330 
	C1168.978760,1050.111694 1169.983521,1047.364014 1171.903687,1045.903076 
	C1177.015381,1042.013672 1174.401001,1039.377319 1170.984009,1036.623047 
	C1168.686890,1034.771606 1165.654663,1033.581665 1166.759888,1029.467407 
	C1167.174561,1027.924194 1164.673462,1026.310181 1163.810547,1027.397583 
	C1160.827148,1031.157715 1154.458252,1023.749207 1153.279053,1030.576294 
	C1152.711670,1033.860474 1157.652344,1034.557007 1158.838867,1037.571289 
	C1159.202881,1038.496338 1161.733765,1038.638184 1160.255005,1040.370850 
	C1159.250122,1041.548462 1157.766113,1040.957886 1156.675659,1040.062744 
	C1154.885620,1038.593262 1153.165039,1037.039062 1151.207520,1035.260376 
	C1150.352539,1033.951660 1149.977295,1032.651978 1148.269287,1032.167114 
	C1145.286987,1030.815674 1143.484009,1027.176025 1139.345459,1028.958984 
	C1137.472656,1028.674683 1136.861328,1024.981201 1134.887695,1027.308472 
	C1133.544067,1028.892944 1131.194946,1031.263428 1133.719116,1033.800537 
	C1136.061523,1036.154907 1136.425537,1038.258057 1132.964478,1040.289917 
	C1139.364746,1041.546265 1139.947266,1042.246094 1138.285889,1046.572998 
	C1137.464233,1046.740723 1136.995483,1046.667969 1136.271973,1046.241333 
	C1132.695435,1045.174805 1129.591431,1046.106445 1127.873901,1048.747192 
	C1126.230591,1051.274170 1126.271973,1054.445557 1128.550049,1057.071899 
	C1129.156738,1057.771484 1130.196655,1058.579224 1129.082520,1059.548462 
	C1128.385010,1060.155029 1127.466553,1059.790894 1126.869629,1059.137695 
	C1123.271118,1055.199829 1122.016968,1057.920288 1121.039429,1061.207642 
	C1120.724365,1062.267578 1121.015381,1063.906128 1119.320923,1063.625732 
	C1117.568726,1063.335693 1116.980591,1061.651001 1117.271973,1060.160034 
	C1117.690674,1058.015747 1118.310181,1056.100586 1116.782837,1054.028076 
	C1115.690674,1052.545776 1117.298828,1052.202881 1118.349121,1051.736084 
	C1119.383423,1051.276367 1120.778320,1050.922241 1120.695435,1049.500732 
	C1120.592651,1047.738159 1118.765137,1047.992188 1117.670654,1047.408936 
	C1117.245117,1047.182251 1116.721436,1047.107910 1116.231934,1047.039185 
	C1114.851685,1046.845093 1113.596313,1046.489868 1113.236572,1044.899536 
	C1113.033081,1043.999878 1113.351562,1043.192871 1114.088013,1042.725952 
	C1115.653076,1041.734009 1116.511963,1043.121216 1117.475342,1043.925659 
	C1119.058105,1045.247437 1120.872925,1045.131958 1122.684326,1044.817505 
	C1124.211182,1044.552368 1124.953369,1043.459473 1124.868896,1041.947876 
	C1124.769531,1040.167480 1123.597290,1040.426636 1122.353027,1040.687134 
	C1121.751831,1040.812988 1120.921021,1040.804565 1120.460815,1040.479492 
	C1118.010010,1038.747559 1119.725708,1034.569946 1116.023438,1033.303955 
	C1112.069580,1031.951904 1113.159546,1028.730225 1113.957397,1025.801270 
	C1114.042236,1025.489868 1113.986572,1025.139893 1113.998047,1024.403931 
	C1114.958618,1022.788940 1116.269409,1022.693542 1118.027344,1022.938110 
	C1120.800171,1024.361206 1122.507202,1026.343872 1123.730103,1028.767822 
	C1124.162354,1029.624634 1124.301025,1030.884521 1125.598755,1030.721558 
	C1126.672607,1030.586548 1127.156250,1029.578857 1127.556885,1028.644531 
	C1127.885132,1027.878784 1128.088867,1027.071899 1128.323364,1026.274780 
	C1130.555054,1018.689819 1132.045288,1017.791443 1139.608398,1019.515869 
	C1140.412231,1019.699036 1141.168335,1020.145386 1142.020142,1020.046326 
	C1142.701050,1019.967224 1143.254028,1019.600647 1143.656616,1019.031311 
	C1143.656616,1019.031311 1140.335571,1011.061768 1140.335571,1011.061768 
	C1137.932129,1012.419861 1135.190308,1008.169800 1132.755005,1011.671692 
	C1131.568359,1013.378113 1129.630127,1012.697754 1128.416870,1011.364014 
	C1125.641479,1008.312683 1123.528687,1008.801392 1121.338623,1012.068298 
	C1120.336182,1013.563660 1118.266235,1013.999451 1116.662598,1015.487976 
	C1112.863403,1017.113403 1113.562134,1021.189087 1111.236572,1023.752808 
	C1108.708130,1023.934448 1106.978271,1024.694214 1107.055664,1027.298218 
	C1107.164917,1030.979980 1105.237549,1031.549194 1102.300903,1030.586670 
	C1100.763184,1030.082520 1099.611938,1030.524292 1098.928955,1031.807251 
	C1098.104614,1033.355591 1099.683838,1033.678223 1100.523071,1034.341187 
	C1103.004395,1036.301636 1102.004395,1039.304199 1102.387817,1041.756836 
	C1102.651245,1043.442627 1100.647217,1041.975586 1099.338135,1042.040161 
	C1097.765259,1042.324097 1096.563110,1042.375122 1095.231201,1041.288818 
	C1094.839111,1039.278198 1093.860229,1037.932617 1093.042725,1036.482178 
	C1091.961060,1034.563232 1090.387085,1032.185791 1094.796631,1032.761108 
	C1095.843018,1032.897583 1096.446655,1032.461182 1096.197021,1031.397949 
	C1095.227051,1027.266479 1098.211182,1023.261902 1096.547607,1019.113159 
	C1096.175293,1018.184814 1095.844727,1017.117188 1096.928223,1016.456909 
	C1097.908447,1015.859558 1098.903198,1016.375916 1099.692749,1016.973389 
	C1100.472534,1017.563416 1100.986450,1018.561646 1101.816772,1019.014648 
	C1103.094849,1019.711853 1104.511475,1020.234558 1105.931274,1020.563965 
	C1106.836914,1020.774170 1107.856445,1019.470337 1107.545532,1019.173462 
	C1103.835205,1015.630188 1114.683594,1014.739746 1108.991089,1010.995117 
	C1108.156616,1010.446167 1107.113403,1009.843140 1107.686523,1008.691528 
	C1108.078857,1007.903259 1109.126709,1007.960388 1109.949951,1008.174255 
	C1114.164307,1009.268982 1113.028442,1006.836121 1112.145386,1004.327759 
	C1111.633423,1002.634888 1112.680664,1002.423218 1113.910156,1002.028320 
	C1115.879883,1002.129578 1117.233521,1001.690796 1118.320557,999.956726 
	C1118.701172,999.566528 1118.866455,999.424438 1119.303467,999.105652 
	C1120.989136,998.535095 1121.590454,997.543396 1121.691650,995.805176 
	C1122.028931,994.550598 1122.492432,993.759460 1123.658813,993.109619 
	C1127.320923,992.793335 1129.519775,994.110352 1132.168457,996.451538 
	C1135.269775,999.192932 1140.115845,1001.228271 1144.417969,1000.444336 
	C1148.872681,999.632507 1151.244995,1003.353699 1155.090576,1003.665955 
	C1156.820557,1003.806396 1155.191162,1006.156738 1154.401245,1007.276306 
	C1152.190430,1010.409485 1148.885254,1013.610657 1156.089233,1014.674561 
	C1157.841553,1014.933289 1157.863770,1016.766541 1158.103516,1018.295593 
	C1158.356201,1019.907776 1158.645630,1021.642334 1159.982544,1022.793945 
	C1160.868530,1023.556946 1161.962891,1024.194214 1163.088989,1023.114868 
	C1164.034546,1022.208496 1163.573608,1021.336426 1162.930786,1020.478149 
	C1162.337158,1019.685608 1161.572998,1018.931519 1161.272095,1018.030823 
	C1160.856323,1016.786499 1160.628052,1015.287964 1161.981812,1014.416260 
	C1163.057983,1013.723328 1164.408936,1013.956299 1165.212280,1014.869019 
	C1168.204346,1018.268311 1171.068237,1021.780579 1173.989990,1025.625244 
	C1173.022339,1027.104492 1173.722046,1027.847412 1174.776123,1028.781250 
	C1174.888794,1030.472656 1174.199951,1031.488159 1172.746948,1031.954834 
	C1171.618286,1032.317261 1170.914673,1033.005615 1171.908203,1034.099731 
	C1172.842896,1035.129028 1174.008667,1035.176758 1174.866089,1033.969238 
	C1176.399536,1031.809448 1177.815308,1029.566772 1179.313232,1027.381348 
	C1181.708618,1023.886230 1185.056763,1024.006958 1187.095337,1027.708862 
	C1187.592529,1028.611694 1187.921265,1029.619141 1189.136353,1029.511597 
	C1190.309082,1029.407837 1190.511719,1028.343506 1190.969482,1027.444214 
	C1192.952393,1023.546875 1196.199829,1022.950256 1200.153442,1025.004883 
	C1204.213379,1027.114868 1208.273193,1030.466187 1213.642578,1027.050659 
	C1215.369385,1032.217896 1210.663696,1032.010742 1207.689941,1033.787109 
	C1200.965088,1034.666992 1200.749756,1035.008179 1203.103638,1041.171997 
	C1203.785767,1042.958008 1204.463623,1044.644409 1201.647339,1045.613281 
	C1196.804443,1044.038086 1192.660645,1043.921631 1189.600464,1048.900757 
	C1193.394897,1047.245361 1197.318604,1050.574829 1201.308105,1047.801514 
	C1203.918579,1045.807007 1206.830444,1044.720215 1208.602051,1041.702026 
	C1209.828369,1040.793823 1210.959961,1040.644775 1212.556396,1040.401001 
	C1210.785645,1039.091309 1208.867554,1037.947388 1210.064697,1035.563721 
	C1211.371094,1032.962158 1213.581787,1032.424683 1216.561523,1033.077393 
	C1222.601318,1036.773560 1223.670654,1036.747437 1226.451782,1032.906372 
	C1227.221436,1031.843384 1228.282959,1030.765259 1227.712891,1029.336060 
	C1227.035889,1027.639160 1225.385132,1027.728394 1223.889404,1027.697144 
	C1223.061279,1027.679810 1222.232178,1027.708862 1221.083740,1027.405029 
	C1218.712402,1024.079468 1213.824341,1023.883118 1212.877197,1019.334900 
	C1214.627563,1018.988708 1216.197144,1019.013794 1217.637451,1020.059814 
	C1220.102661,1021.850098 1222.530273,1020.902954 1224.143433,1018.976318 
	C1225.815552,1016.979248 1222.961060,1016.068604 1222.154541,1014.292358 
	C1218.543213,1006.421936 1215.487427,1004.354553 1209.303223,1005.792786 
	C1205.275513,1003.912415 1208.643555,999.646790 1206.302002,997.137085 
	C1206.656128,996.838806 1207.010254,996.540588 1207.364502,996.242310 
	C1209.193115,1000.248596 1214.571533,995.609680 1216.379150,999.983337 
	C1216.967651,1001.407166 1218.224854,1000.543945 1219.353027,1000.156677 
	C1223.765991,998.642029 1225.730957,999.871094 1226.144165,1004.322693 
	C1226.283203,1005.820801 1227.115479,1006.777832 1228.236328,1007.497009 
	C1229.668335,1008.415894 1231.070923,1009.269043 1230.991089,1011.630249 
	C1230.543091,1012.455688 1230.108276,1012.929565 1229.774048,1013.486389 
	C1229.999756,1013.133301 1230.251221,1012.799561 1230.763672,1012.242188 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1173.756104,870.283081 
	C1175.127197,870.208740 1176.275269,870.339172 1177.377441,870.639038 
	C1180.542114,871.500061 1181.969238,869.492432 1180.700317,867.310608 
	C1177.940674,862.565735 1181.168091,858.875671 1181.948486,854.373901 
	C1182.143188,852.182373 1181.634644,850.310425 1182.737183,848.276733 
	C1187.650513,845.694519 1189.746582,846.168274 1191.838989,850.329224 
	C1192.694702,852.030762 1193.582397,853.469177 1195.416260,854.208496 
	C1197.514282,855.054260 1199.591064,856.172974 1198.518921,858.920959 
	C1197.449707,861.661011 1195.268799,862.507629 1192.448364,861.437561 
	C1192.023560,861.276428 1191.657227,860.896179 1190.961548,861.127686 
	C1195.660400,862.961670 1201.086548,861.170532 1205.562866,864.689026 
	C1206.043945,865.029358 1206.224121,865.183167 1206.637329,865.605103 
	C1207.603516,867.899780 1209.260742,868.441345 1211.182007,868.545044 
	C1213.384277,868.664001 1215.335693,869.559692 1217.782715,870.913635 
	C1218.231812,867.317627 1220.170654,865.583923 1223.676147,865.924988 
	C1225.129028,866.066284 1226.360229,865.504822 1226.564453,863.874939 
	C1226.752808,862.371826 1225.458618,862.009033 1224.413940,861.559204 
	C1222.702881,860.822510 1220.701538,860.149658 1221.776367,857.718201 
	C1222.769775,855.470581 1224.587036,854.030334 1227.225830,854.158447 
	C1228.951294,854.242249 1230.661011,854.945068 1231.567505,856.286987 
	C1234.024292,859.923889 1237.504761,860.591248 1241.774658,860.492188 
	C1242.432495,860.993652 1242.693726,861.385620 1242.972534,862.168518 
	C1243.010132,865.286316 1244.033691,867.335693 1246.936523,868.633362 
	C1247.304565,866.537842 1245.455078,865.816528 1245.382324,864.041748 
	C1246.804932,860.199097 1249.535156,860.418152 1251.837769,862.126465 
	C1255.782349,865.052673 1259.541016,865.505371 1263.811890,863.057861 
	C1265.204468,862.259766 1267.177490,861.530212 1268.266479,863.384521 
	C1269.341431,865.215210 1268.237793,867.013306 1266.836182,868.246460 
	C1264.449829,870.346069 1261.417969,871.474243 1258.538574,872.608887 
	C1252.553223,874.967285 1251.882080,876.237061 1254.552612,882.467041 
	C1254.007080,881.651001 1253.807739,880.687866 1253.535889,879.779846 
	C1252.918579,877.717346 1253.758789,876.107483 1255.658203,875.325989 
	C1258.067017,874.335022 1259.836792,875.504272 1261.069092,877.563171 
	C1263.070312,880.906128 1264.424194,884.753662 1268.527832,886.986816 
	C1270.024048,879.922485 1275.965820,881.702576 1280.727051,880.779175 
	C1281.000000,887.021179 1281.000000,893.042297 1281.000000,899.531738 
	C1279.401123,901.169312 1278.164062,900.104065 1277.901611,898.869690 
	C1276.410156,891.857483 1270.750000,892.539795 1265.761353,892.186768 
	C1263.120972,891.999939 1260.558472,893.265564 1257.861938,893.181824 
	C1256.982910,893.154541 1256.184448,893.497498 1255.883423,894.401367 
	C1253.741333,900.833069 1249.532227,904.963928 1242.773804,906.315796 
	C1242.455200,906.379517 1242.282593,906.748962 1242.098999,907.350525 
	C1246.069946,908.112488 1248.829102,912.116516 1253.888184,910.507996 
	C1256.324829,909.733215 1256.790161,913.591003 1258.374390,915.892822 
	C1259.444702,913.914368 1260.105957,911.441467 1262.890991,911.838074 
	C1266.132446,912.299683 1268.825439,913.935059 1271.234741,916.002991 
	C1272.164185,916.800720 1272.203857,918.143433 1271.638184,919.265564 
	C1270.519043,921.486267 1268.492920,920.320190 1266.216675,920.278503 
	C1268.156128,922.488037 1267.435669,924.545593 1267.010010,926.682007 
	C1266.396118,929.763611 1265.697510,932.899475 1267.828491,935.812073 
	C1269.671509,938.331116 1267.901367,940.140442 1266.459106,942.096924 
	C1268.227417,940.584717 1269.808838,938.769775 1272.674194,938.597351 
	C1279.289062,939.420044 1280.429199,941.719727 1277.190918,946.998352 
	C1276.348145,948.372070 1274.949341,949.670776 1276.291748,951.429504 
	C1278.560669,954.402039 1277.187134,955.981262 1274.203979,956.747986 
	C1272.376587,957.217590 1271.339966,958.025269 1271.136475,960.012390 
	C1270.847656,962.830261 1269.046997,964.377258 1265.996826,964.993774 
	C1263.894409,964.963989 1262.598633,963.940979 1260.985474,962.796936 
	C1260.265381,962.470093 1259.853027,962.283752 1259.168213,961.884521 
	C1258.132080,961.185547 1257.574585,960.465271 1256.707642,959.575562 
	C1256.374634,959.110779 1256.259033,958.911682 1256.014038,958.390991 
	C1255.434082,956.046509 1255.422607,953.952698 1254.857788,951.596558 
	C1256.417358,945.975220 1253.068115,941.821289 1251.582886,937.263977 
	C1250.408569,933.660706 1246.985596,933.382019 1243.968994,932.602539 
	C1245.938477,933.283875 1248.696777,933.138184 1249.469116,935.323303 
	C1250.810303,939.117249 1253.897583,942.008545 1254.704224,945.960022 
	C1255.015625,947.485413 1254.061157,948.832642 1252.344238,949.858032 
	C1248.105591,952.110046 1244.513062,950.277100 1240.534424,948.982117 
	C1238.248779,948.386719 1238.158081,950.147278 1237.221191,951.427734 
	C1234.325439,953.552856 1232.083374,952.963257 1231.010376,950.031921 
	C1229.831055,946.810303 1228.025391,947.341370 1225.546631,948.195068 
	C1219.963745,950.117920 1218.324219,948.527405 1219.382080,942.598145 
	C1216.029175,943.689331 1212.832275,945.454102 1209.129639,945.408020 
	C1204.843994,945.354736 1203.072021,943.328735 1203.590820,939.086731 
	C1204.016235,935.606812 1205.523682,934.810791 1208.571167,936.492798 
	C1209.423096,936.963013 1210.037231,937.871094 1211.532349,937.878784 
	C1212.456543,935.101868 1211.073364,930.446045 1216.803101,930.822144 
	C1215.549805,927.798340 1213.751343,925.584656 1213.738037,922.557434 
	C1213.727051,920.070190 1215.671265,917.345825 1212.299683,914.046448 
	C1213.511597,917.075500 1214.442139,919.537537 1211.820679,920.566833 
	C1208.641235,921.815186 1205.322510,923.471741 1201.782837,922.171509 
	C1200.018677,921.523438 1199.631836,919.626221 1199.989258,917.907837 
	C1200.696533,914.506042 1198.981812,913.026917 1195.997192,913.265381 
	C1188.253540,913.884033 1180.935181,910.694641 1173.242065,910.991333 
	C1170.998169,911.077881 1169.197754,909.930786 1169.226074,907.501648 
	C1169.261108,904.482666 1171.331421,902.851807 1174.137939,903.031494 
	C1178.060913,903.282715 1181.253418,901.812378 1184.417603,899.877991 
	C1185.791504,899.038086 1187.081055,898.203125 1186.015869,896.382935 
	C1184.980225,894.613220 1183.727417,895.775330 1182.601074,896.338745 
	C1179.733276,897.773315 1176.980469,897.269836 1174.649902,895.402344 
	C1172.703491,893.842651 1170.792847,892.348022 1168.567749,891.170410 
	C1166.878174,890.276245 1165.945435,888.639160 1166.774902,886.269653 
	C1167.000000,886.000000 1167.000000,885.500000 1167.000000,885.250000 
	C1171.148315,881.080627 1172.336182,875.825562 1173.756104,870.283081 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M939.454102,1281.000000 
	C936.489014,1277.911255 932.506042,1276.999390 929.240051,1276.752441 
	C925.507019,1276.470215 925.925049,1275.262939 926.534302,1272.871826 
	C927.387085,1269.524780 928.178772,1266.162109 928.998291,1262.403076 
	C927.223511,1260.209229 927.767151,1258.034668 927.936401,1255.890381 
	C928.462097,1249.227905 925.472473,1245.645752 918.770935,1244.819336 
	C916.653442,1244.558228 914.422485,1244.876343 912.250977,1243.300293 
	C913.594177,1236.803955 911.780151,1233.455811 905.877136,1231.981812 
	C903.562805,1231.403809 901.890625,1230.086914 901.282654,1227.900879 
	C900.692810,1225.779785 902.859924,1225.279663 904.094238,1224.283447 
	C904.482483,1223.970215 904.958313,1223.684082 905.188538,1223.270874 
	C906.033203,1221.755493 909.149902,1221.426025 908.222046,1219.338135 
	C907.399902,1217.488159 905.184937,1216.313721 902.929199,1216.061035 
	C901.284302,1215.876709 899.578674,1216.129761 898.757629,1214.102783 
	C898.410156,1213.244873 897.504089,1213.004395 896.679504,1213.582764 
	C890.419434,1217.973633 883.895996,1214.178711 877.501770,1214.170288 
	C876.408081,1214.168945 875.170654,1212.907471 874.244385,1213.152710 
	C864.617004,1215.701660 861.269775,1206.872192 855.460876,1202.711426 
	C853.120056,1201.034790 852.878113,1197.943359 856.112793,1196.157837 
	C858.478333,1194.852173 857.704407,1193.643799 855.902283,1193.272583 
	C853.017944,1192.678101 852.973572,1191.314209 853.745728,1189.005127 
	C854.087524,1187.982910 854.156006,1186.427368 853.605713,1185.620850 
	C850.161560,1180.574463 851.957458,1178.596680 857.462891,1178.255737 
	C858.911011,1178.166016 860.348633,1177.465332 861.733032,1176.902466 
	C863.286072,1176.270752 865.075317,1175.474731 864.409363,1173.383179 
	C863.858887,1171.654053 862.226379,1170.871094 860.392761,1171.059204 
	C857.758911,1171.329712 855.084229,1171.526001 852.531250,1172.168457 
	C850.006226,1172.803955 847.926270,1172.828979 845.432007,1171.545898 
	C842.684265,1170.132202 840.002197,1170.897217 840.038574,1174.807129 
	C840.080383,1179.297607 836.738525,1179.456421 833.667542,1180.301636 
	C830.242065,1181.244141 830.722229,1178.310913 830.147461,1176.802612 
	C828.568115,1172.657593 825.348938,1173.978882 822.555115,1174.367065 
	C817.946167,1175.007324 813.377991,1176.144165 809.127441,1173.094727 
	C807.764465,1172.116821 806.081299,1171.296631 806.226685,1169.365723 
	C806.409058,1166.945068 808.756897,1167.063843 810.360657,1166.405762 
	C811.796814,1165.816650 813.334473,1165.829346 814.756226,1166.437866 
	C818.293762,1167.952026 819.226013,1165.634766 819.770203,1162.996582 
	C820.356628,1160.153564 818.801758,1158.952026 816.138733,1159.020508 
	C813.158752,1159.096924 812.357666,1157.591309 811.874329,1154.786255 
	C811.118225,1150.397705 810.282776,1146.184937 810.892700,1141.620117 
	C811.542480,1136.757080 815.880493,1137.201782 818.583435,1135.559204 
	C820.869385,1134.169922 823.235840,1136.578247 824.272705,1138.370972 
	C825.448059,1140.403198 825.528259,1143.115723 825.881470,1145.561890 
	C826.174683,1147.591675 826.149780,1149.645752 828.910034,1149.940063 
	C831.864502,1150.255005 832.213074,1148.017944 833.209534,1146.052246 
	C834.697510,1143.117188 834.756348,1139.635498 837.200378,1137.031372 
	C839.035461,1135.075928 836.955994,1134.246216 835.229492,1133.249146 
	C833.531555,1131.687500 835.050720,1129.971069 834.395386,1128.456055 
	C833.397156,1129.697632 834.267334,1131.297607 833.224243,1132.745361 
	C829.711182,1131.840210 826.298645,1131.932007 822.444214,1132.000000 
	C821.379700,1131.582764 820.749451,1131.172974 820.901978,1129.985107 
	C825.162781,1129.097534 827.575623,1127.375977 826.835327,1122.627686 
	C826.481140,1120.355957 827.889038,1118.556274 830.688782,1117.882568 
	C831.556274,1117.855713 832.046326,1117.940308 832.876221,1118.234619 
	C837.514832,1122.211182 840.648438,1127.710083 844.853577,1130.819580 
	C853.583740,1137.275269 848.123474,1142.627563 845.773926,1148.619141 
	C845.532654,1149.234497 845.179810,1149.797241 844.549683,1150.606079 
	C842.802124,1151.697876 841.833557,1150.705322 840.774963,1149.895630 
	C837.554810,1147.432495 836.621155,1147.645752 835.838257,1151.718384 
	C835.201904,1155.028442 834.073120,1157.655518 830.928345,1159.268066 
	C828.567200,1160.478516 828.754700,1162.492065 830.119446,1164.298706 
	C831.568359,1166.216675 833.637512,1167.426514 836.101379,1167.229736 
	C836.937805,1167.162964 836.748718,1165.863403 836.677917,1165.058594 
	C836.561707,1163.736328 836.291687,1162.430664 836.168335,1161.109985 
	C835.697876,1156.073364 837.198730,1154.571533 843.034546,1154.682861 
	C844.164795,1158.151001 845.528259,1160.002563 849.578979,1158.932861 
	C853.004517,1158.028320 855.477844,1160.083252 857.120483,1163.295410 
	C859.647095,1168.235962 862.231506,1168.458130 865.467041,1164.175415 
	C866.824219,1162.378906 868.171143,1160.663696 870.717285,1161.097778 
	C873.449890,1161.563477 874.383911,1163.751709 875.012573,1166.069946 
	C876.373291,1171.087158 874.259644,1175.532959 869.124756,1178.585571 
	C866.837097,1179.945435 864.218506,1180.706055 862.121338,1182.405151 
	C861.352356,1183.028198 860.408875,1183.737427 860.731262,1184.848389 
	C861.053162,1185.957886 862.193726,1186.162476 863.201538,1186.116211 
	C866.308167,1185.973633 869.343445,1184.701294 872.549805,1185.918213 
	C873.601685,1186.317505 873.647522,1184.979248 873.653992,1184.176636 
	C873.661987,1183.179443 873.494751,1182.181396 873.597229,1181.182739 
	C873.753296,1179.661621 873.984741,1178.116089 875.619385,1177.453735 
	C877.261963,1176.788452 878.694214,1177.501831 879.528503,1178.833008 
	C881.303101,1181.665039 883.020142,1181.833008 885.439148,1179.495239 
	C888.259766,1176.769531 891.612793,1177.489380 894.796692,1178.830566 
	C897.878235,1180.128662 898.387634,1182.958496 898.533569,1185.890625 
	C898.616577,1187.558350 898.841492,1189.355591 898.149536,1190.838745 
	C895.531738,1196.449829 897.683044,1201.577026 899.404907,1206.799927 
	C900.360840,1209.699585 902.302246,1211.574707 905.024780,1212.789917 
	C909.558960,1214.813843 911.279724,1214.034058 912.454102,1209.247437 
	C912.900940,1207.426270 913.218018,1205.575439 915.403687,1205.052124 
	C917.724182,1204.496582 919.241394,1205.495605 920.157104,1207.625610 
	C920.952087,1209.475098 922.823547,1209.397705 924.371948,1209.868652 
	C928.525879,1211.131958 931.438171,1213.122314 931.270569,1218.241577 
	C931.141907,1222.170532 936.704285,1224.316284 940.332092,1222.011108 
	C941.616028,1221.195312 942.460144,1219.915894 943.504822,1218.846436 
	C947.093506,1215.172363 950.922363,1214.576172 955.063477,1217.654419 
	C957.844055,1219.721313 958.985840,1219.120239 960.043701,1216.163208 
	C963.577026,1206.286987 964.865295,1205.469849 975.722778,1205.809448 
	C978.812195,1205.906128 982.059387,1204.957764 984.764343,1207.477173 
	C988.656311,1211.102051 989.003174,1215.539551 985.264526,1220.962524 
	C983.094971,1224.109497 980.738953,1226.776733 984.860718,1230.278564 
	C986.523193,1231.691162 985.457764,1233.672485 984.041504,1235.096069 
	C981.401306,1237.749756 977.422363,1237.971069 974.020447,1235.535645 
	C971.996887,1234.086914 970.230408,1232.251343 967.701782,1231.628540 
	C966.161987,1231.249268 964.693970,1231.263916 963.602417,1232.623535 
	C962.392700,1234.130249 963.662231,1235.122314 964.513611,1236.045898 
	C966.718323,1238.437622 967.495239,1241.605225 969.148560,1244.295410 
	C970.156921,1245.936157 970.594055,1247.848877 968.772217,1249.240601 
	C967.190491,1250.449097 965.490845,1249.741699 964.203247,1248.605713 
	C960.842285,1245.640381 957.252747,1242.877930 954.806885,1239.011353 
	C954.362000,1238.307983 953.976318,1237.566895 953.487915,1236.890869 
	C952.224243,1235.141602 950.590698,1234.405884 948.854492,1235.981323 
	C947.308716,1237.383911 947.769775,1239.119751 949.278503,1240.272461 
	C952.925659,1243.058838 954.607300,1247.619995 959.209839,1249.784790 
	C963.086731,1251.608276 962.960144,1258.454712 959.952881,1263.033936 
	C964.432617,1269.413330 972.058472,1267.608398 978.071228,1270.057861 
	C979.537048,1270.655029 980.153198,1269.415894 980.618958,1268.323486 
	C981.945374,1265.213013 984.462952,1264.216553 987.535278,1264.720459 
	C990.453552,1265.198975 991.965698,1264.418335 993.133484,1261.459961 
	C995.459167,1255.568481 1002.126831,1252.706299 1008.182434,1254.446045 
	C1008.665955,1254.584961 1009.203003,1254.751709 1009.485657,1255.142456 
	C1013.723633,1260.999023 1021.295349,1264.120605 1023.426514,1272.164307 
	C1023.867554,1272.875610 1024.065796,1273.310425 1024.330566,1274.101562 
	C1024.570679,1276.879150 1028.188232,1277.570801 1027.221924,1280.725586 
	C1019.312439,1281.000000 1011.624817,1281.000000 1003.468628,1281.000000 
	C1000.391724,1278.397949 997.221375,1276.888184 993.310303,1276.088257 
	C991.516296,1275.382324 990.109070,1275.226318 989.093018,1276.755981 
	C987.518188,1279.127197 984.999573,1279.799316 982.250244,1280.764160 
	C979.958313,1281.000000 977.916687,1281.000000 975.437500,1281.000000 
	C966.565491,1279.699219 958.127930,1278.552124 949.314392,1280.747070 
	C945.969421,1281.000000 942.938782,1281.000000 939.454102,1281.000000 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1263.688354,1176.216797 
	C1263.276245,1170.953979 1265.022949,1168.171509 1270.532349,1167.762573 
	C1273.149414,1167.568237 1274.960449,1164.162842 1278.594727,1165.000000 
	C1280.536987,1168.305420 1278.835449,1171.655640 1278.975464,1175.008789 
	C1279.100586,1178.002686 1278.978027,1181.136353 1278.668091,1184.529663 
	C1277.295044,1186.491333 1275.186890,1185.503174 1273.468262,1186.591064 
	C1275.100586,1187.118896 1276.805786,1186.527588 1278.240723,1187.938232 
	C1277.916260,1190.566528 1277.659790,1192.632324 1278.873169,1195.127319 
	C1280.328369,1198.119629 1278.604980,1201.288818 1277.036255,1204.054688 
	C1274.199097,1209.057251 1273.001221,1213.817871 1277.870728,1218.419800 
	C1279.385010,1219.850952 1279.554810,1222.018433 1279.230225,1224.042358 
	C1278.806519,1226.684326 1278.955811,1229.333374 1278.806885,1232.324707 
	C1276.106323,1235.769165 1276.305542,1238.860596 1278.920410,1242.291870 
	C1280.041870,1246.038574 1279.323364,1249.174194 1277.613892,1252.169312 
	C1276.208374,1254.632080 1274.672974,1257.253906 1274.976929,1260.074707 
	C1275.816528,1267.866333 1272.330566,1274.134644 1268.267212,1280.686035 
	C1265.299927,1281.000000 1262.599976,1281.000000 1259.449951,1281.000000 
	C1257.706421,1276.020142 1254.614746,1273.057007 1249.441895,1272.290039 
	C1248.296265,1272.120117 1247.314941,1271.379761 1246.360352,1270.129150 
	C1245.756592,1268.855103 1245.697998,1267.853516 1245.842041,1266.460571 
	C1247.670410,1261.490601 1247.921631,1257.336060 1244.320557,1253.119995 
	C1241.503174,1249.821533 1242.806519,1245.762207 1244.862061,1242.377075 
	C1246.955688,1238.929199 1245.818481,1238.000000 1242.280762,1238.010254 
	C1238.492554,1238.021240 1235.133301,1235.413574 1231.133667,1236.191406 
	C1229.393921,1236.529663 1227.396484,1235.854492 1226.675659,1238.298950 
	C1225.994141,1240.610229 1225.627930,1242.851562 1227.320435,1244.916504 
	C1228.294800,1246.105347 1229.615356,1246.817627 1230.925781,1247.536987 
	C1233.171021,1248.769897 1234.467285,1250.540527 1234.095093,1253.205933 
	C1233.702148,1256.020508 1232.482178,1258.276367 1229.604736,1259.242920 
	C1225.226440,1260.713989 1220.827271,1260.962402 1216.052246,1259.249634 
	C1214.964600,1258.845703 1214.349854,1258.390137 1213.572510,1257.525879 
	C1214.218140,1248.586060 1213.573486,1239.569580 1219.186157,1232.071533 
	C1222.067627,1228.222168 1221.563965,1222.510498 1217.494629,1219.136719 
	C1216.980103,1218.776367 1216.788452,1218.613159 1216.356934,1218.160156 
	C1214.909058,1215.222290 1213.089233,1213.107056 1210.574097,1211.630615 
	C1207.800293,1210.002319 1205.344849,1208.180054 1204.338135,1204.847656 
	C1203.630737,1202.505615 1202.005615,1202.984375 1201.244263,1204.663574 
	C1199.240967,1209.081543 1194.612549,1210.009277 1191.194946,1212.452026 
	C1187.951416,1214.770264 1184.315063,1213.257568 1182.719849,1209.478882 
	C1181.928955,1207.605835 1180.759644,1206.041138 1179.735596,1204.343750 
	C1176.781006,1199.446655 1179.127319,1194.905518 1184.820190,1194.448608 
	C1188.301758,1194.169434 1191.803955,1194.332886 1195.295410,1194.345703 
	C1198.128662,1194.356201 1200.070190,1193.559204 1200.544556,1190.369141 
	C1201.080566,1186.764282 1203.680298,1185.516724 1207.010010,1185.421631 
	C1210.328735,1185.326782 1213.650146,1185.222168 1216.738770,1185.517090 
	C1213.193115,1183.509644 1209.457764,1183.405884 1205.418945,1185.158691 
	C1201.867676,1186.699707 1194.099365,1183.121704 1191.498657,1178.773560 
	C1190.081909,1176.404785 1188.589233,1174.464233 1186.000366,1173.308960 
	C1183.869873,1172.358398 1182.462524,1170.619019 1183.279907,1168.101074 
	C1184.048950,1165.732178 1186.151855,1166.282349 1187.933838,1166.434692 
	C1189.797729,1166.594238 1191.569702,1166.166382 1193.363647,1165.820068 
	C1196.641235,1165.187134 1199.665894,1165.431396 1201.990479,1168.243652 
	C1204.001343,1170.675903 1206.674805,1170.501831 1209.327637,1169.859131 
	C1211.088257,1169.432495 1212.087158,1168.145630 1212.284790,1166.262573 
	C1212.488770,1164.316040 1211.710449,1162.935547 1209.874634,1162.433228 
	C1205.901489,1161.346191 1202.778809,1159.036865 1199.759399,1156.322510 
	C1197.221558,1154.041382 1193.758179,1154.771362 1190.645020,1154.540039 
	C1181.944702,1153.893677 1181.564331,1153.539185 1180.780029,1144.293091 
	C1183.743530,1145.047241 1186.791382,1144.824097 1189.458008,1146.475952 
	C1191.734619,1147.886230 1193.603027,1146.376465 1194.747437,1144.331909 
	C1195.584717,1142.836060 1196.093994,1141.156738 1197.075562,1138.764160 
	C1198.529785,1142.811890 1201.017822,1145.359863 1201.198486,1149.289307 
	C1201.382812,1153.301025 1204.845947,1155.262207 1208.478882,1153.675415 
	C1210.963989,1152.589722 1212.986816,1153.797241 1215.608154,1153.988647 
	C1218.881714,1157.658325 1217.422852,1162.543823 1219.653320,1166.767212 
	C1220.825928,1173.122314 1225.884155,1174.990723 1230.424805,1177.333984 
	C1232.253540,1178.277588 1233.244263,1177.235229 1233.567627,1175.454224 
	C1233.889526,1173.680664 1233.396851,1171.771362 1234.716309,1169.870850 
	C1235.414429,1169.432861 1235.874268,1169.351807 1236.685425,1169.495361 
	C1239.110596,1171.332397 1237.984497,1174.415527 1240.091064,1176.425537 
	C1241.632080,1177.668945 1243.418335,1176.692139 1244.941040,1177.939453 
	C1246.618042,1179.689941 1246.221802,1181.539185 1246.140137,1183.335571 
	C1246.047974,1185.364990 1246.315430,1187.239868 1248.643677,1188.661621 
	C1249.864380,1184.159668 1247.542969,1181.225098 1245.024658,1178.013916 
	C1244.486450,1173.732056 1247.482300,1173.625610 1249.991577,1173.966309 
	C1252.664673,1174.329102 1255.536133,1174.389282 1257.911377,1176.178101 
	C1259.594849,1177.445923 1261.492676,1177.689941 1263.688354,1176.216797 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M832.314758,1280.726318 
	C817.645752,1281.000000 803.291565,1281.000000 788.468628,1281.000000 
	C784.568481,1279.114380 781.103210,1277.986694 777.237183,1280.765625 
	C775.933289,1281.000000 774.866638,1281.000000 773.399963,1281.000000 
	C771.268921,1279.506348 769.390564,1279.793457 767.236938,1280.771484 
	C758.979065,1281.000000 750.958191,1281.000000 742.468628,1281.000000 
	C740.192688,1278.822632 741.273987,1276.616821 742.016418,1274.471313 
	C742.767639,1272.300781 743.834778,1270.222656 743.516235,1267.415283 
	C743.502930,1265.678345 743.447815,1264.343262 743.745850,1262.645020 
	C744.597717,1260.099243 746.121399,1258.677734 748.162048,1257.677002 
	C749.431580,1257.054321 751.446960,1256.489014 750.106995,1254.516968 
	C748.661255,1252.389404 747.465454,1254.266968 746.631714,1255.313232 
	C744.158325,1258.416870 741.013794,1258.701538 738.327759,1256.416626 
	C735.474243,1253.989258 736.116089,1250.818359 738.460388,1248.068115 
	C735.988892,1249.408936 733.340149,1250.138794 731.061340,1247.758911 
	C729.028687,1245.635986 729.514526,1243.085205 730.790161,1240.785767 
	C734.381592,1234.311401 733.689514,1226.846069 735.729797,1220.015137 
	C736.585999,1217.148438 737.238770,1214.237793 740.529907,1211.302246 
	C734.695068,1212.288330 730.167480,1213.204834 725.600403,1213.837646 
	C723.098572,1214.184082 721.920105,1215.745972 721.626648,1218.230835 
	C721.317688,1220.847168 721.732117,1222.823486 724.702393,1223.551270 
	C725.969177,1223.861694 727.421875,1224.432251 727.312866,1226.108887 
	C727.206665,1227.741577 725.718872,1228.058838 724.447571,1228.462402 
	C718.393005,1230.384399 712.564575,1229.266968 706.904175,1226.950073 
	C704.556519,1225.989258 703.416992,1223.877808 703.316406,1221.371826 
	C703.233032,1219.292725 704.493958,1217.428101 706.303406,1217.242554 
	C713.541016,1216.500610 713.948730,1210.218506 716.127930,1205.495117 
	C717.298767,1202.957397 718.166138,1200.206421 718.861938,1197.466675 
	C719.962402,1193.133545 721.754517,1189.629272 726.866028,1189.208252 
	C727.639709,1189.144531 728.684631,1188.940063 728.681763,1187.868774 
	C728.678711,1186.726685 727.679626,1186.109619 726.786865,1185.781860 
	C722.835083,1184.331177 719.161682,1182.466431 716.218140,1179.371460 
	C714.244751,1177.296631 711.864502,1175.209351 714.328491,1172.125000 
	C716.111206,1169.893555 720.300781,1170.463867 722.510681,1173.724609 
	C727.105408,1180.504272 734.139893,1179.347534 740.593140,1179.574341 
	C743.241089,1179.667480 744.501526,1176.574829 745.051270,1174.166748 
	C745.510498,1172.155396 743.711060,1172.180664 742.378540,1172.591309 
	C739.426636,1173.501221 736.512634,1173.533691 733.506958,1172.868896 
	C730.737732,1172.256348 728.725952,1171.036133 727.458618,1168.277832 
	C725.384827,1163.764404 723.886292,1163.333374 719.229492,1165.736206 
	C716.448730,1167.171021 713.652527,1167.881348 710.577881,1167.358032 
	C709.420776,1167.161255 708.195190,1166.863892 707.745056,1165.607910 
	C707.164185,1163.987061 708.363037,1163.126465 709.480652,1162.412109 
	C712.037415,1160.777832 714.580627,1159.174927 715.253967,1155.500244 
	C718.340088,1153.055298 720.665649,1154.604004 722.918213,1156.430054 
	C723.898132,1157.224487 724.585571,1159.006592 726.036987,1158.346191 
	C727.600098,1157.635132 726.503174,1156.004395 726.415833,1154.772095 
	C726.369080,1154.112061 726.287231,1153.460693 726.543823,1152.456543 
	C727.821777,1150.725342 729.323853,1150.621460 731.294067,1151.004639 
	C736.431091,1156.486938 743.018982,1158.233643 749.550110,1160.154663 
	C754.486267,1161.606689 757.302734,1166.339966 757.434265,1173.025269 
	C757.519409,1177.355469 755.798035,1179.563965 750.477966,1181.408447 
	C747.399536,1182.475830 744.999695,1184.339844 742.776611,1186.613647 
	C743.889709,1188.702881 746.030823,1188.311035 747.576111,1189.092407 
	C751.678528,1191.166870 752.331421,1195.096802 749.043518,1198.307617 
	C747.942505,1199.382690 745.660950,1200.185059 747.247498,1202.157104 
	C748.836243,1204.132080 751.294678,1205.317261 753.826477,1205.093140 
	C755.521545,1204.942993 754.546997,1203.295288 754.295471,1202.255371 
	C753.151672,1197.525757 755.097473,1195.728760 759.993469,1196.848877 
	C762.340881,1197.385986 764.196899,1199.812622 767.349915,1198.573242 
	C777.119934,1196.478516 781.346619,1199.590210 781.765930,1208.960083 
	C781.887390,1211.674194 782.327820,1214.047241 784.232117,1216.249268 
	C787.612061,1220.157837 787.164429,1225.252075 787.680054,1229.966675 
	C788.093262,1233.744873 787.657654,1237.584351 788.131714,1241.783447 
	C788.387512,1243.492920 788.729248,1244.761353 789.378113,1246.343750 
	C792.676270,1249.406128 793.910339,1252.858765 793.448914,1256.911743 
	C793.074036,1260.203735 793.819153,1262.756104 797.253174,1264.070312 
	C798.733398,1264.636597 800.647278,1265.293213 800.331055,1267.407471 
	C799.968872,1269.828857 797.871643,1270.370605 795.846313,1270.539551 
	C792.555115,1270.813843 789.239197,1271.072998 785.652527,1269.928467 
	C783.552979,1268.408203 783.933960,1266.500610 784.074829,1264.709839 
	C784.376831,1260.871338 782.280518,1259.573608 778.893066,1259.913452 
	C776.082581,1260.195190 773.297241,1260.507202 770.457886,1260.426636 
	C768.804626,1260.379761 763.017700,1268.072266 763.275940,1269.673584 
	C763.433960,1270.653076 764.128845,1271.067627 764.979614,1271.201904 
	C768.033142,1271.683472 770.995972,1272.863770 774.107727,1270.829590 
	C776.801086,1269.068848 779.994690,1268.680420 783.566895,1269.584229 
	C786.497192,1272.473999 789.737183,1272.988770 793.368530,1273.300659 
	C802.282349,1274.066040 811.300537,1273.742554 820.100464,1275.899536 
	C824.467407,1276.970093 829.053589,1275.411621 833.797363,1276.794067 
	C834.967896,1278.788696 833.831177,1279.634888 832.314758,1280.726318 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M688.757202,1257.317749 
	C689.718872,1254.066406 692.129883,1255.221436 693.928406,1255.017456 
	C695.604553,1254.827515 697.646667,1254.924316 697.629639,1252.679565 
	C697.612488,1250.429810 695.493835,1251.050293 694.147522,1251.040405 
	C691.740784,1251.022705 689.799866,1250.348999 687.745850,1248.899292 
	C684.536072,1246.633789 680.932312,1244.564575 677.061829,1248.174438 
	C676.450500,1248.744629 675.123901,1249.016602 674.288147,1248.802368 
	C667.880554,1247.158081 661.377502,1248.392334 654.468384,1248.000000 
	C648.849060,1250.806396 643.121155,1250.161987 641.177490,1246.557495 
	C638.503723,1241.599243 640.538452,1234.542847 645.351868,1232.080811 
	C651.130188,1229.125366 651.690796,1227.121948 649.024292,1218.022339 
	C648.032166,1216.413940 646.982910,1215.698364 645.035645,1216.376465 
	C642.248535,1216.697876 639.814087,1217.063354 637.317261,1215.258423 
	C632.263977,1213.975952 627.652954,1212.765747 623.696594,1217.331299 
	C622.298706,1218.944336 619.487610,1217.579224 617.432678,1218.165161 
	C615.796814,1218.631592 613.494507,1218.465820 613.170654,1220.865601 
	C612.839172,1223.321045 615.089355,1223.433838 616.555359,1224.434448 
	C619.947144,1226.749390 624.585205,1228.021606 624.998535,1233.617798 
	C623.386902,1235.426636 621.644531,1235.704224 619.372803,1234.295654 
	C616.733582,1233.462646 614.381470,1232.454102 613.872620,1236.255371 
	C613.632568,1238.048828 612.155640,1237.902588 611.501770,1236.618042 
	C610.171936,1234.005615 607.185120,1231.401978 610.568604,1228.241089 
	C611.386230,1227.477295 611.978577,1226.748047 610.997009,1225.778687 
	C610.157898,1224.950073 609.130066,1224.315186 607.908142,1224.429321 
	C606.745605,1224.537720 606.045288,1225.511963 606.156799,1226.502197 
	C606.550415,1229.996704 604.095154,1233.325195 605.471191,1236.782959 
	C606.490051,1239.343140 604.431152,1240.379028 603.336609,1241.874756 
	C602.594849,1242.888306 601.288208,1243.317017 600.220032,1242.643311 
	C598.909363,1241.817017 600.503601,1241.006592 600.546265,1240.195679 
	C600.720459,1236.888062 601.851562,1233.044067 596.950989,1231.949219 
	C595.501892,1231.625732 595.247620,1230.851196 595.324524,1229.446045 
	C595.753845,1221.601807 595.499329,1221.287109 586.931763,1217.922607 
	C591.137939,1216.901245 593.424011,1214.164551 595.838745,1211.602173 
	C598.100525,1209.202271 599.091797,1205.791748 597.660400,1203.305420 
	C595.176575,1198.991089 596.815613,1196.972412 600.341858,1195.054077 
	C600.889771,1194.755981 601.239563,1194.093994 601.840454,1193.300049 
	C606.918030,1190.901245 608.499512,1191.896973 610.882812,1198.837646 
	C611.357239,1200.245850 611.963623,1200.707886 613.333496,1199.794312 
	C615.662659,1198.580200 617.834900,1198.388184 620.437622,1198.692871 
	C622.537415,1199.529663 624.399414,1199.410278 626.209961,1199.345581 
	C636.593323,1198.975342 646.977539,1198.541260 657.315430,1197.470703 
	C660.012878,1197.191284 661.226196,1198.336426 661.096375,1200.736816 
	C660.847595,1205.338379 661.560303,1209.672363 663.595215,1213.824829 
	C664.784485,1216.251709 662.908325,1218.698608 663.425293,1221.712769 
	C670.361938,1217.420166 673.896057,1221.785522 676.875916,1227.020508 
	C678.626404,1230.095825 680.615540,1230.527832 683.299255,1228.364380 
	C685.969543,1226.211670 688.809448,1225.291260 692.063354,1227.071899 
	C693.169067,1227.676880 694.307068,1227.376709 695.472534,1227.192261 
	C702.501526,1226.080566 708.715698,1226.992310 712.220093,1234.248047 
	C712.833435,1235.517944 713.815491,1235.146973 714.771362,1234.964111 
	C716.282715,1234.675171 717.780151,1234.668213 719.167786,1235.384888 
	C722.337402,1237.021973 723.670471,1239.678589 723.363098,1243.202759 
	C722.985168,1247.535645 719.444885,1246.942261 716.327698,1247.796753 
	C717.980713,1249.853027 718.539246,1253.455078 722.679932,1251.013916 
	C724.435059,1249.979126 725.567078,1250.880615 726.126892,1252.542969 
	C726.764038,1254.434937 727.214478,1256.390381 728.302124,1258.499268 
	C727.490173,1263.246582 724.544067,1262.105347 721.898438,1261.054810 
	C714.121094,1257.966675 714.706238,1258.609619 708.206421,1263.838379 
	C704.140015,1267.109497 704.295837,1272.051270 700.694946,1275.392212 
	C698.465332,1276.479370 696.642273,1275.813232 694.269714,1275.006226 
	C695.510925,1276.901367 696.725891,1278.549072 695.231323,1280.749268 
	C690.310730,1281.000000 685.621521,1281.000000 680.466125,1281.000000 
	C679.411255,1278.357910 677.266113,1279.133789 675.562195,1279.008667 
	C671.275024,1278.693726 666.928345,1279.638184 662.682373,1278.367188 
	C660.362000,1277.672607 657.921753,1276.826294 658.198975,1273.908813 
	C658.469299,1271.063843 661.041992,1271.149658 663.602295,1271.001587 
	C666.779663,1268.473755 670.220032,1269.500610 673.421509,1269.261353 
	C674.238159,1269.200195 675.063232,1269.252808 675.884399,1269.251587 
	C685.393860,1269.238159 685.911255,1268.819824 687.881470,1259.487427 
	C688.018188,1258.839844 688.189697,1258.214111 688.757202,1257.317749 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1263.624023,88.065521 
	C1268.469971,87.776001 1265.817627,82.852028 1268.688477,81.183212 
	C1272.710815,85.382156 1278.163452,88.700874 1276.892944,95.910416 
	C1276.269287,99.449608 1276.594238,103.048836 1274.244629,106.249435 
	C1273.127930,107.770828 1273.681519,109.982346 1275.698730,110.894463 
	C1278.619019,112.214912 1279.565674,114.805443 1280.760864,117.735565 
	C1281.000000,188.020889 1281.000000,258.041779 1281.000000,328.531342 
	C1277.124023,325.421875 1278.895752,320.451385 1278.226440,316.203552 
	C1277.444824,311.242615 1278.438477,306.014435 1278.719238,300.917786 
	C1279.207764,292.047424 1278.325317,283.097900 1277.940308,274.184906 
	C1277.794189,270.800934 1275.206055,271.004974 1272.421631,270.999298 
	C1269.951294,269.252167 1270.165161,266.812073 1270.134521,264.517517 
	C1270.061157,259.020905 1270.074951,253.523270 1269.901855,247.636765 
	C1270.449585,245.355087 1272.033691,244.900208 1273.612061,244.403564 
	C1274.834473,244.018890 1276.429199,243.792542 1276.414062,242.163269 
	C1276.400635,240.720963 1274.813477,240.790466 1273.814697,240.379593 
	C1273.058838,240.068680 1272.297363,239.792358 1271.426880,238.963547 
	C1269.749756,235.052277 1270.190063,231.198120 1269.801514,227.053284 
	C1271.089111,220.431351 1269.711304,214.173920 1270.033447,207.930252 
	C1270.124512,206.164276 1269.331177,204.348114 1269.566895,202.110443 
	C1270.903564,195.341385 1271.731079,188.931396 1270.495483,182.422913 
	C1270.293213,181.357468 1270.798706,179.749893 1269.177856,179.627029 
	C1267.822266,179.524292 1267.371704,180.914963 1267.043213,182.030411 
	C1266.431396,184.107712 1266.294067,186.296646 1265.638062,188.366531 
	C1265.178345,189.816696 1264.663696,191.293274 1262.919434,191.622116 
	C1260.989868,191.985901 1259.743896,190.851334 1258.820801,189.378128 
	C1255.470581,184.031586 1252.415649,178.648560 1256.563477,172.265869 
	C1257.375732,171.015747 1257.327271,169.380981 1257.995239,167.625916 
	C1259.210083,165.503159 1261.263794,164.932373 1262.914307,163.317596 
	C1265.107300,161.557022 1264.177124,159.450241 1264.369507,157.549149 
	C1264.620605,155.068069 1265.781860,152.833511 1268.428589,152.623627 
	C1271.533081,152.377472 1271.248413,155.168427 1271.560547,156.845169 
	C1271.572510,154.400574 1271.398926,151.623993 1270.402710,148.949432 
	C1269.086548,145.415955 1268.970337,141.738388 1270.185059,138.242569 
	C1271.635620,134.067963 1270.370605,131.572662 1266.025635,130.048553 
	C1260.708008,125.673203 1260.732056,125.691757 1264.862427,120.329971 
	C1267.063843,117.472176 1269.089600,114.641151 1265.553345,111.182198 
	C1264.246460,109.903801 1264.495728,107.733093 1263.845947,105.666367 
	C1262.794678,102.382812 1262.511963,99.018852 1258.760010,97.365540 
	C1257.937134,96.221367 1257.795044,95.245132 1257.948975,93.841339 
	C1259.088989,91.025520 1260.775269,89.193024 1263.624023,88.065521 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M1023.236328,1275.233643 
	C1023.000000,1274.555542 1023.000000,1274.111084 1023.000000,1273.333252 
	C1021.182800,1269.761353 1023.230286,1268.940430 1025.726807,1269.307861 
	C1028.232544,1269.676514 1030.255615,1269.032959 1032.384644,1267.985229 
	C1034.547363,1266.921143 1036.885864,1266.378174 1038.978760,1268.078125 
	C1040.719360,1269.492065 1042.253418,1270.322632 1044.104126,1268.291504 
	C1045.954224,1266.261475 1049.050659,1264.654297 1047.511841,1261.267090 
	C1045.618286,1257.098755 1044.827026,1253.121948 1047.088135,1248.855469 
	C1047.934326,1247.258667 1046.682861,1246.291382 1045.377075,1245.848755 
	C1041.593872,1244.566162 1039.952759,1241.724365 1039.246216,1238.051880 
	C1038.928467,1236.400269 1038.003174,1234.982178 1037.230469,1233.507202 
	C1035.758789,1230.697754 1034.901001,1226.799438 1030.421997,1230.411011 
	C1029.515137,1231.142334 1027.854858,1230.934448 1027.163452,1230.025146 
	C1024.212769,1226.144409 1019.644775,1224.851074 1015.562256,1222.182373 
	C1015.592163,1216.198242 1020.612122,1216.183960 1024.483154,1214.373535 
	C1027.661133,1215.094116 1027.794800,1217.556885 1028.522461,1220.277466 
	C1029.355103,1217.022461 1031.417847,1215.014771 1034.186401,1215.928223 
	C1037.706787,1217.089966 1039.209229,1215.774902 1040.433350,1212.901733 
	C1040.744995,1212.170532 1041.588379,1211.762939 1042.618164,1211.175781 
	C1045.889893,1210.487915 1048.148315,1209.122070 1049.492554,1206.266846 
	C1050.455933,1204.220337 1052.271118,1203.135132 1054.501465,1202.489624 
	C1058.695435,1201.276001 1059.324341,1198.891113 1056.613770,1195.279297 
	C1052.282104,1189.507446 1052.257202,1187.796143 1055.889404,1182.074707 
	C1057.740601,1179.158569 1060.136475,1178.385498 1062.338379,1180.977783 
	C1065.409546,1184.593384 1068.527832,1187.594604 1073.467163,1188.461670 
	C1075.578979,1188.832397 1076.353638,1191.038086 1076.424316,1193.574951 
	C1076.348877,1197.777344 1073.402344,1199.392090 1070.662354,1200.833130 
	C1065.961914,1203.305298 1065.824341,1208.520142 1063.305664,1212.280640 
	C1062.755371,1213.102417 1063.442505,1214.297241 1064.100342,1215.024658 
	C1066.800171,1218.009521 1067.286621,1221.891113 1068.432739,1225.489380 
	C1069.814941,1229.828613 1068.803711,1231.154175 1063.506836,1231.907715 
	C1059.545776,1233.373657 1061.699219,1236.009277 1061.991211,1238.565552 
	C1062.723022,1241.121948 1063.705444,1243.141113 1065.654175,1245.024048 
	C1066.190430,1246.332153 1066.195801,1247.326660 1065.754028,1248.685303 
	C1065.157227,1249.690552 1064.560791,1250.164917 1063.569214,1250.730225 
	C1061.826660,1251.453369 1060.299438,1251.413086 1058.840698,1251.727417 
	C1057.522461,1252.011353 1056.051636,1252.328735 1056.171875,1254.097290 
	C1056.286865,1255.789062 1057.719238,1256.076050 1059.075317,1256.245850 
	C1060.238403,1256.391479 1061.397949,1256.175903 1062.907959,1256.061157 
	C1065.964233,1256.323608 1066.947754,1258.073730 1066.895996,1260.519409 
	C1066.832275,1263.525024 1068.214233,1263.671265 1070.686890,1262.947388 
	C1078.975098,1260.520874 1080.201782,1260.964478 1085.350830,1267.636841 
	C1086.435181,1269.094360 1087.542358,1269.703247 1089.404419,1269.258301 
	C1092.640137,1268.280640 1095.636963,1267.916748 1098.516357,1268.659668 
	C1102.005615,1269.559814 1105.328613,1269.010498 1108.735962,1268.474854 
	C1110.688843,1268.167969 1112.670166,1268.314453 1114.909180,1269.334717 
	C1116.177612,1270.430420 1116.625122,1271.490112 1116.520264,1273.177856 
	C1115.285156,1275.392090 1113.436523,1275.741211 1111.606323,1275.744507 
	C1102.484253,1275.760620 1093.401611,1277.954224 1084.231567,1276.079956 
	C1081.639526,1275.550171 1078.920898,1276.054199 1075.871826,1276.358643 
	C1072.754883,1276.635864 1070.839355,1272.938843 1068.461182,1274.202271 
	C1066.514648,1275.236450 1067.796265,1278.942749 1065.244385,1280.754395 
	C1064.250000,1281.000000 1063.500000,1281.000000 1062.375000,1281.000000 
	C1061.542480,1280.522461 1060.970581,1279.530884 1060.693970,1279.598999 
	C1055.341064,1280.917114 1049.773315,1278.357666 1044.245483,1280.759644 
	C1039.643066,1281.000000 1035.286255,1281.000000 1030.464600,1281.000000 
	C1028.096680,1278.834351 1025.827881,1277.099854 1023.236328,1275.233643 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1123.531372,1.000000 
	C1124.173218,6.939651 1124.154907,6.873281 1129.977661,5.264087 
	C1131.898804,4.733186 1134.035278,4.557832 1135.833618,5.616966 
	C1139.922974,8.025381 1144.435669,7.610293 1148.435669,6.492446 
	C1155.723145,4.455887 1162.832397,4.864105 1170.139771,5.743115 
	C1175.155762,6.346478 1180.063965,6.109344 1184.947266,8.636685 
	C1187.549683,9.983662 1191.710571,8.241943 1195.176270,8.026512 
	C1197.415039,7.887349 1199.672119,7.995646 1199.939941,11.058629 
	C1200.162842,13.609777 1199.822632,15.650707 1196.602295,16.093903 
	C1191.850342,16.747910 1192.612305,21.119436 1191.559204,24.195330 
	C1189.760376,24.622700 1189.634888,23.142809 1189.227661,22.199461 
	C1186.779541,16.527733 1182.264038,14.262870 1176.491455,16.174351 
	C1172.729980,17.419886 1173.747314,20.286625 1174.934570,22.732050 
	C1176.933716,26.849802 1178.076050,31.075726 1178.045532,35.684666 
	C1178.027466,38.407291 1177.590820,40.194149 1174.258911,40.065331 
	C1172.597290,40.001091 1170.046753,39.028011 1169.421265,41.314251 
	C1168.640625,44.167210 1172.162109,43.418564 1173.339478,44.795444 
	C1173.009888,46.339245 1171.784424,46.080067 1170.989380,45.772720 
	C1167.631714,44.474766 1166.857056,46.850277 1166.209961,49.127781 
	C1165.856201,50.373001 1166.041626,51.772846 1165.994751,53.103333 
	C1165.947266,54.450974 1165.979004,55.978886 1164.443359,56.461346 
	C1162.867188,56.956543 1161.986328,55.635353 1161.337524,54.501972 
	C1159.080444,50.558937 1157.137085,46.885487 1151.261108,47.250237 
	C1149.097900,47.384510 1148.113647,43.083328 1149.663940,41.060658 
	C1154.634277,34.575989 1152.791382,29.245071 1148.010986,23.781614 
	C1146.430786,21.975632 1145.058838,19.448301 1148.258789,17.429817 
	C1149.445801,16.681072 1151.411499,16.081238 1150.309326,14.136942 
	C1149.296509,12.350559 1147.304565,10.658866 1145.394165,11.542616 
	C1141.087891,13.534648 1136.880249,11.789890 1132.653442,11.945855 
	C1126.850830,12.159965 1121.204956,12.039438 1115.525635,14.365068 
	C1112.066895,15.781464 1107.652466,15.003320 1103.670166,14.957984 
	C1100.483643,14.921709 1098.313721,15.214117 1099.786621,19.347649 
	C1100.287842,20.754326 1100.628906,22.694565 1098.365723,23.902519 
	C1098.081665,20.976961 1098.288696,17.893549 1093.569824,19.541197 
	C1092.913696,19.770353 1091.822510,19.487724 1091.236450,19.042305 
	C1089.660767,17.844763 1086.108398,18.625483 1086.581787,15.576194 
	C1086.971313,13.067891 1089.630859,12.987864 1091.892944,12.967582 
	C1093.883789,12.949730 1096.518066,12.921180 1096.625244,10.451235 
	C1096.747925,7.624363 1093.922241,8.438147 1092.094604,7.945326 
	C1085.072510,6.051749 1077.869751,4.736523 1071.090820,1.359173 
	C1088.354248,1.000000 1105.708496,1.000000 1123.531372,1.000000 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1281.000000,880.666687 
	C1278.888306,881.768860 1277.159668,883.337952 1274.561035,883.096130 
	C1272.071289,882.864441 1268.850708,882.857300 1271.323364,887.095642 
	C1271.825806,887.956787 1271.384644,888.746033 1270.340820,888.862183 
	C1268.875366,889.025269 1267.315430,889.356018 1266.167603,888.001831 
	C1263.914429,885.343567 1261.649292,882.670959 1260.313599,879.407593 
	C1259.673096,877.843079 1259.323730,876.166382 1257.171021,876.106201 
	C1256.051758,876.074890 1255.324219,876.624817 1254.786011,877.572144 
	C1253.753784,879.388733 1255.099854,880.631653 1255.677246,882.143860 
	C1256.291992,883.753967 1260.542358,884.085144 1257.939941,886.264526 
	C1256.711914,887.293091 1254.558838,884.239502 1253.418579,882.491760 
	C1251.455933,879.483765 1250.601074,876.325439 1252.979370,872.997925 
	C1253.436523,872.358276 1253.765381,870.947205 1254.393921,871.273010 
	C1258.653931,873.481140 1260.600586,868.061218 1264.474487,868.388916 
	C1266.241211,868.538391 1267.383545,866.004578 1267.609863,863.567078 
	C1265.192017,863.207764 1263.508301,864.766479 1261.729004,865.695068 
	C1258.321289,867.473267 1255.435669,866.726318 1252.671631,864.433655 
	C1250.773438,862.859131 1248.947144,860.728760 1246.172607,863.698242 
	C1244.700195,865.088623 1243.562744,864.579590 1242.234375,863.320557 
	C1242.000000,862.555542 1242.000000,862.111084 1242.000000,861.333313 
	C1241.647461,860.605896 1241.383789,860.159119 1241.150269,859.271851 
	C1241.204224,857.064331 1241.276245,855.242310 1241.551270,853.037964 
	C1241.683472,850.589905 1242.587524,849.053894 1244.848145,848.044128 
	C1248.261475,847.580200 1251.222656,850.049438 1254.651245,848.084778 
	C1255.911865,847.708557 1256.857056,847.646179 1258.139648,847.651001 
	C1258.875610,847.660217 1259.446533,847.890625 1259.637207,847.534180 
	C1259.862183,847.113647 1259.222534,846.974548 1258.774902,846.437988 
	C1258.199707,845.615601 1258.030029,844.980103 1257.951416,843.990723 
	C1257.958862,842.674011 1258.385620,841.635193 1257.467163,840.454224 
	C1257.221680,839.993652 1257.133179,839.803284 1256.938354,839.315125 
	C1256.581421,837.782715 1256.440063,836.509338 1255.438477,835.194458 
	C1255.175049,834.470703 1255.096191,834.037415 1255.066162,833.263306 
	C1255.357788,830.435486 1256.618530,827.883240 1254.447632,825.237427 
	C1253.536865,823.726807 1253.495117,822.308838 1253.033691,820.656311 
	C1252.728149,817.846436 1251.147217,816.496399 1248.752197,815.342651 
	C1248.338379,814.883118 1248.198242,814.670593 1247.908447,814.096313 
	C1247.466064,812.675659 1247.326294,811.603882 1247.499390,810.081604 
	C1247.399048,802.469543 1247.996948,801.499878 1253.949951,799.911499 
	C1257.939331,801.437744 1261.842041,800.208435 1265.479614,801.300598 
	C1267.198608,801.816772 1269.000610,802.053467 1270.880127,803.257690 
	C1271.718628,804.122742 1271.990356,804.850037 1272.119629,806.019653 
	C1272.086792,806.636108 1272.044067,806.875854 1271.867065,807.445801 
	C1270.858154,809.086548 1269.444214,809.741272 1268.050049,810.783203 
	C1267.862183,810.917908 1267.539795,811.057434 1267.539795,811.057434 
	C1267.539795,811.057434 1267.680664,811.370483 1267.888306,811.290283 
	C1269.322632,811.115662 1270.436279,811.497559 1271.803223,812.198608 
	C1272.260376,812.526733 1272.430298,812.678223 1272.813965,813.098877 
	C1273.525513,814.408875 1274.277100,815.311157 1274.784180,816.736450 
	C1275.131104,818.492676 1275.318970,819.936707 1276.510376,821.142822 
	C1277.688232,820.106689 1276.483032,818.500305 1277.593018,817.174255 
	C1278.854614,816.382690 1279.694336,816.774902 1280.729492,817.746643 
	C1281.000000,821.692932 1281.000000,825.385803 1281.000000,829.539368 
	C1278.747437,832.714172 1280.140381,835.630798 1280.764160,838.745850 
	C1281.000000,840.388916 1281.000000,841.777771 1281.000000,843.583374 
	C1279.690552,845.375488 1279.789185,846.900024 1280.762207,848.745972 
	C1281.000000,851.041687 1281.000000,853.083313 1281.000000,855.562500 
	C1278.671265,858.027039 1279.798218,860.293823 1280.773438,862.767151 
	C1281.000000,864.388916 1281.000000,865.777771 1281.000000,867.583374 
	C1279.725952,871.448120 1278.518433,874.899231 1280.761963,878.748047 
	C1281.000000,879.444458 1281.000000,879.888916 1281.000000,880.666687 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M41.250694,261.933777 
	C39.144234,261.987488 38.831482,263.941833 37.285637,264.845032 
	C36.004753,265.687256 34.885773,265.949646 33.347656,265.984833 
	C31.180544,266.507812 28.947189,266.430054 29.579655,269.535522 
	C29.977757,271.490265 28.092268,272.199310 26.784473,273.142365 
	C24.603085,274.715363 24.884293,275.985626 27.667780,276.955139 
	C31.269068,278.994232 34.631680,280.809509 34.205070,285.608490 
	C34.046391,287.393494 36.032402,287.619720 37.315666,288.338409 
	C39.568275,289.600006 41.462360,291.151703 40.148960,294.275665 
	C39.660835,295.436676 40.004227,297.258087 38.309715,297.385345 
	C36.651314,297.509918 37.216434,295.665497 36.706486,294.746429 
	C36.277458,293.973236 35.688488,293.203583 34.809669,293.628601 
	C33.931309,294.053375 34.199963,294.967224 34.529175,295.792389 
	C36.311741,300.260376 37.074188,305.421570 43.304943,306.389923 
	C45.160961,306.678406 46.193840,308.618378 45.935574,310.681305 
	C45.455585,314.515259 43.165668,310.511841 42.586266,311.316467 
	C40.981022,313.545776 37.869366,315.258392 38.404015,318.379364 
	C39.002934,321.875519 37.080128,321.914642 34.382278,321.998596 
	C33.527321,321.458527 33.421604,320.813293 33.478149,319.787842 
	C33.469032,318.664459 33.487549,317.869415 32.630196,316.829071 
	C32.819633,318.314575 33.042667,319.497620 32.097847,320.800690 
	C28.420109,323.299255 24.484716,323.928162 20.574696,324.456543 
	C17.841274,324.825928 15.282117,324.199158 13.609049,321.157593 
	C14.695060,315.538635 18.307631,319.678345 20.343189,318.983551 
	C17.858149,318.883789 15.328883,317.458832 12.915625,316.442780 
	C9.447127,314.982391 11.640642,312.448181 13.376263,310.254700 
	C13.793936,309.788116 13.961188,309.505005 13.964124,308.993469 
	C13.574709,308.637787 13.274963,308.496124 12.709444,308.222015 
	C11.967048,307.648651 11.582460,307.144287 11.070505,306.309448 
	C10.637272,305.350464 10.528252,304.674561 10.867562,303.668182 
	C11.684946,302.665436 12.929625,302.257233 12.671274,301.082458 
	C11.937428,297.745544 11.728644,294.429047 12.865499,290.938416 
	C10.747198,292.939728 9.917183,296.639923 6.375741,295.453033 
	C2.536748,294.166382 2.665926,290.492950 2.484642,287.158875 
	C2.362699,284.916168 3.173886,282.507385 1.240415,280.247314 
	C1.000000,278.285645 1.000000,276.571289 1.000000,274.428467 
	C1.523059,273.576660 1.664643,273.049957 1.213986,272.210388 
	C1.000000,264.979065 1.000000,257.958099 1.000000,250.468567 
	C2.851393,249.862137 3.987998,248.753754 4.785041,247.197098 
	C6.502188,243.843414 8.068806,244.318039 9.328433,247.461029 
	C11.163795,252.040588 13.354417,256.121094 19.609526,255.999756 
	C26.244110,258.095245 32.679352,256.499786 39.004990,256.996765 
	C41.074760,257.159393 43.831661,256.591766 42.151428,260.656525 
	C41.944675,261.352875 41.778442,261.641937 41.250694,261.933777 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1213.793945,1256.715576 
	C1214.496216,1257.257690 1214.992310,1257.515259 1215.744263,1257.886475 
	C1217.289673,1259.155151 1218.401978,1260.440186 1217.554810,1262.294800 
	C1216.754272,1264.046753 1215.124268,1263.709351 1213.650146,1263.517700 
	C1209.968628,1263.038818 1207.564453,1266.797241 1203.897339,1266.441040 
	C1203.485718,1266.401123 1203.286255,1267.136963 1203.430542,1267.647705 
	C1203.628662,1268.349487 1204.186890,1268.650146 1204.834473,1268.904541 
	C1212.529907,1271.927734 1212.530396,1271.936890 1212.223145,1280.731934 
	C1201.312378,1281.000000 1190.624878,1281.000000 1179.468628,1281.000000 
	C1174.982666,1278.053345 1175.746826,1274.993042 1179.447632,1271.629272 
	C1174.567505,1270.553711 1170.592773,1268.805176 1167.443359,1265.135254 
	C1167.456055,1263.004639 1168.490112,1261.683716 1169.590576,1260.433594 
	C1171.558472,1258.197998 1173.636475,1257.532593 1175.825684,1260.194702 
	C1176.343384,1260.824097 1177.057861,1262.081299 1177.548950,1261.784424 
	C1180.564697,1259.961548 1181.915405,1256.625610 1183.980103,1253.954468 
	C1185.381470,1252.141724 1183.390503,1251.056152 1181.901489,1250.244873 
	C1181.026245,1249.768311 1180.059448,1249.450562 1179.259521,1248.846191 
	C1176.394775,1246.682129 1176.304565,1243.405396 1179.612305,1242.199829 
	C1184.770386,1240.320068 1184.684082,1236.737183 1183.959229,1232.579834 
	C1183.491699,1229.898071 1183.596680,1227.272339 1184.532715,1224.711792 
	C1185.624146,1221.726196 1187.585205,1220.230591 1190.683228,1221.861816 
	C1192.818237,1222.986084 1194.680786,1222.775635 1196.861938,1222.039062 
	C1200.878418,1220.682617 1202.330078,1221.844971 1202.468994,1226.136963 
	C1202.545776,1228.508301 1202.216797,1230.906494 1200.928833,1232.864136 
	C1198.273804,1236.899658 1198.740967,1240.667969 1201.272461,1244.467651 
	C1205.607544,1240.341309 1207.958984,1240.963257 1209.835693,1246.668335 
	C1210.925781,1249.982178 1212.003784,1253.305298 1213.793945,1256.715576 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M1.000000,1074.468628 
	C4.136773,1072.860352 7.848060,1073.428955 10.332078,1070.281738 
	C11.908614,1068.284302 13.803254,1066.659180 12.439609,1063.834839 
	C11.182770,1061.231689 14.360169,1061.249023 14.925762,1059.343262 
	C15.667095,1058.335327 16.451344,1058.247070 17.655392,1058.763428 
	C20.013857,1059.471558 22.331177,1055.693604 23.572130,1058.394043 
	C24.888100,1061.257690 22.092081,1062.635376 19.367571,1063.137329 
	C18.103102,1063.370361 17.134933,1064.138916 17.309252,1065.513794 
	C17.507574,1067.078003 18.903601,1066.993042 20.044605,1066.969604 
	C21.531515,1066.939209 23.225882,1067.186157 24.462624,1066.572021 
	C28.811077,1064.412842 32.664688,1061.720825 38.186481,1063.793335 
	C41.522369,1065.045288 41.563015,1060.698853 43.057377,1057.362427 
	C44.486958,1061.413940 44.053329,1064.376099 43.940407,1067.200317 
	C43.742195,1072.157715 45.210613,1076.566528 47.709465,1080.692993 
	C49.329590,1083.368408 48.691776,1084.921875 45.612335,1084.918091 
	C42.733009,1084.914429 41.168098,1086.306885 39.588051,1088.520386 
	C36.891415,1092.297729 33.973465,1091.876953 31.974867,1087.433350 
	C31.006128,1085.279541 29.571753,1084.808350 27.628639,1085.115479 
	C25.207958,1085.498291 25.054262,1087.039673 25.457895,1089.199829 
	C25.929445,1091.723511 23.703501,1095.934692 29.420897,1094.474243 
	C30.102009,1094.300293 30.816891,1094.697632 30.712317,1095.529053 
	C30.637146,1096.126831 30.342588,1096.834351 29.905722,1097.223022 
	C28.668991,1098.322754 27.073154,1097.957275 25.600286,1097.990845 
	C21.969507,1098.073608 18.285566,1096.992920 17.191473,1102.755859 
	C16.460876,1106.604248 11.912334,1106.977905 8.711143,1104.200684 
	C6.636959,1102.401123 5.180212,1099.581055 1.382049,1100.001343 
	C1.000000,1091.645752 1.000000,1083.291504 1.000000,1074.468628 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1212.468628,1281.000000 
	C1211.697998,1278.813843 1210.064087,1277.116699 1210.631836,1274.438110 
	C1211.044189,1272.492554 1209.895752,1269.665405 1206.379517,1270.695068 
	C1203.000244,1271.684570 1201.153564,1270.146240 1201.100830,1266.786499 
	C1201.042114,1263.050659 1203.495972,1264.113892 1205.628418,1264.701904 
	C1207.154907,1265.122681 1208.209961,1264.391602 1208.923462,1263.106689 
	C1210.018188,1261.134888 1211.476440,1260.754883 1213.507690,1261.750000 
	C1218.198364,1264.048218 1216.703979,1260.975830 1216.045776,1258.352417 
	C1219.913574,1258.748047 1223.699341,1259.057617 1227.791992,1258.269897 
	C1231.248535,1257.604614 1232.114136,1255.702759 1232.777588,1253.064941 
	C1233.601685,1249.788574 1231.016235,1249.365967 1228.894897,1248.373169 
	C1223.946289,1246.057617 1222.334595,1239.318237 1225.664795,1235.210083 
	C1226.049927,1234.734985 1226.977905,1234.087158 1227.270508,1234.242432 
	C1231.070801,1236.260498 1235.841309,1231.857422 1239.311401,1236.151367 
	C1240.531250,1237.660767 1242.102417,1236.242310 1243.506958,1236.086060 
	C1245.480225,1235.866821 1247.057739,1236.243896 1248.111450,1238.207275 
	C1249.152222,1240.146606 1248.172241,1241.180542 1246.923584,1242.486694 
	C1243.304565,1246.272217 1243.515259,1250.435669 1247.039062,1254.078369 
	C1250.484375,1257.639771 1249.439697,1261.593750 1247.285645,1265.749512 
	C1242.765991,1268.572266 1238.181885,1268.495117 1233.545654,1267.664673 
	C1231.584595,1267.313599 1229.668945,1266.660278 1227.651245,1266.741211 
	C1226.358154,1266.793091 1224.621704,1266.515991 1224.149170,1267.984131 
	C1223.566040,1269.796143 1225.406250,1270.475220 1226.586060,1271.286865 
	C1228.616455,1272.684082 1230.743042,1272.978882 1233.199463,1272.156860 
	C1237.455688,1270.732422 1241.859253,1269.863647 1246.684570,1269.835938 
	C1249.391113,1270.673706 1251.845215,1271.181396 1254.159058,1272.058838 
	C1257.886108,1273.472534 1260.533081,1275.715576 1259.042480,1280.653076 
	C1243.645752,1281.000000 1228.291504,1281.000000 1212.468628,1281.000000 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M1264.738770,964.287842 
	C1268.525757,963.279846 1270.948853,961.919983 1269.416138,957.584290 
	C1268.671143,955.476562 1269.948120,954.852844 1271.838135,955.448364 
	C1273.642944,956.017151 1274.950562,954.997803 1277.412598,954.179993 
	C1270.785400,951.718262 1273.528198,948.717834 1275.923462,945.799500 
	C1279.023682,942.022461 1278.682373,941.047424 1273.392456,940.001709 
	C1272.062866,938.733093 1271.998779,937.159851 1273.303711,936.500732 
	C1277.930054,934.163574 1277.060913,929.994385 1277.145630,926.099304 
	C1277.181763,924.438477 1277.091187,922.764099 1277.426880,921.129517 
	C1277.761841,919.498962 1278.071289,917.779968 1280.621582,917.787537 
	C1281.000000,947.687561 1281.000000,977.375122 1281.000000,1007.531372 
	C1279.150757,1010.128906 1279.375488,1012.620117 1279.740967,1015.487732 
	C1279.648804,1016.057373 1279.569092,1016.277344 1279.244751,1016.748657 
	C1272.326660,1016.909424 1266.524780,1014.017334 1260.334717,1011.148682 
	C1255.721558,1007.532288 1258.881714,1003.855164 1259.840088,999.917236 
	C1259.875854,998.318298 1263.598633,997.745667 1260.341797,995.795898 
	C1256.158325,993.903198 1252.553101,991.999390 1254.498413,986.296082 
	C1254.584961,985.187744 1254.672852,984.458923 1255.119629,983.459106 
	C1256.022827,981.465942 1259.348145,982.059204 1258.682373,978.867981 
	C1257.931030,976.114258 1257.457886,973.703369 1259.006592,971.037659 
	C1261.556274,968.420715 1264.402710,968.441345 1267.053833,969.678101 
	C1269.618164,970.874451 1269.228638,973.506409 1268.991577,975.854248 
	C1268.856689,977.189636 1268.867432,978.530212 1269.284668,979.804932 
	C1270.214844,982.646912 1269.473511,984.617126 1266.588623,985.770813 
	C1264.923462,986.436646 1264.110352,987.730774 1264.550049,990.339478 
	C1266.832520,987.433350 1268.606567,984.847595 1271.956909,984.150024 
	C1273.713623,983.784241 1273.117554,982.448303 1272.397095,981.432800 
	C1269.988525,978.038452 1271.037476,975.117493 1273.738647,972.586731 
	C1276.117554,970.357849 1276.839844,967.691650 1276.540039,964.413391 
	C1274.619873,970.594849 1270.280029,971.625977 1264.200928,967.128784 
	C1263.956665,965.997681 1264.089722,965.263550 1264.738770,964.287842 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1281.000000,917.600037 
	C1278.974976,918.359314 1279.003418,919.907715 1279.003662,921.442749 
	C1279.004150,924.104065 1278.901489,926.769897 1279.023560,929.425537 
	C1279.206055,933.397034 1279.355591,937.213684 1273.830811,937.551208 
	C1273.533447,937.569336 1273.300537,938.642883 1273.018799,939.612610 
	C1270.523804,939.873535 1268.856567,940.967651 1268.061035,943.322083 
	C1267.678589,944.454285 1267.314819,945.666870 1265.836426,945.579102 
	C1264.774048,945.516052 1264.058594,944.691650 1263.599365,943.789856 
	C1262.905151,942.426575 1263.815186,941.449280 1264.786987,940.876526 
	C1267.768677,939.119629 1268.084106,938.169250 1265.365723,935.110168 
	C1263.275391,932.757935 1264.578125,928.213562 1265.864502,924.850769 
	C1266.437134,923.354309 1266.881836,922.497498 1265.031982,921.827698 
	C1263.639893,921.323547 1262.591431,920.210999 1263.569824,918.782043 
	C1264.405029,917.562378 1265.945801,918.024902 1267.273804,918.285278 
	C1268.437622,918.513428 1269.852539,920.963318 1270.736816,918.365234 
	C1271.440918,916.297424 1269.305420,916.324768 1267.981812,915.739319 
	C1266.644409,915.147888 1265.594727,913.769226 1264.237183,913.394470 
	C1262.542480,912.926575 1259.825562,911.892761 1260.750610,915.762878 
	C1261.076050,917.124817 1261.110596,918.547668 1259.250854,918.823792 
	C1257.601440,919.068726 1256.862061,917.876892 1256.403564,916.570251 
	C1256.021484,915.481018 1255.984131,914.251892 1255.502686,913.220825 
	C1254.762451,911.635132 1253.680664,909.856201 1252.084473,912.442139 
	C1250.815186,914.498596 1249.138306,913.506226 1248.297119,912.751709 
	C1245.810059,910.520691 1243.731689,908.106567 1239.746826,909.475403 
	C1238.755493,909.815979 1237.812256,908.218079 1239.146484,907.047852 
	C1240.390259,905.956787 1241.718262,904.198303 1243.072632,904.137085 
	C1247.448853,903.939148 1250.197754,901.874268 1252.063843,898.148132 
	C1252.245972,897.784607 1252.983032,897.741089 1253.316406,897.404297 
	C1255.007690,895.695923 1252.682861,891.442627 1255.441772,891.455627 
	C1261.583740,891.484497 1267.737183,889.418640 1273.900879,891.427795 
	C1276.330566,892.219788 1277.981079,893.219421 1278.279907,896.063538 
	C1278.419189,897.389771 1278.664429,899.105713 1280.649170,899.920044 
	C1281.000000,902.041687 1281.000000,904.083313 1281.000000,906.562500 
	C1279.498535,908.870300 1278.003540,910.745850 1276.208252,912.453918 
	C1277.629761,913.630920 1279.338135,912.420166 1280.748047,913.767578 
	C1281.000000,915.066711 1281.000000,916.133362 1281.000000,917.600037 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M145.468658,1281.000000 
	C144.847229,1278.036987 146.830460,1277.975098 148.979843,1277.988647 
	C154.636337,1278.024170 160.293518,1278.028931 165.949707,1277.972534 
	C167.378006,1277.958374 169.360580,1278.174194 169.342133,1276.067383 
	C169.328415,1274.503540 167.530045,1274.478394 166.300552,1274.052979 
	C160.613953,1272.085815 154.334015,1273.372070 148.700348,1270.649292 
	C147.261017,1269.953735 144.864014,1271.042480 142.965088,1271.535156 
	C138.621765,1272.662354 136.122681,1271.262817 132.913223,1267.701538 
	C129.161377,1263.538086 129.944672,1258.181274 126.935875,1254.121826 
	C125.493896,1252.176270 127.676743,1251.885742 128.873093,1252.065552 
	C134.092834,1252.849487 138.601715,1250.613770 143.260590,1248.964478 
	C145.880814,1248.036743 147.937057,1246.815308 146.054825,1243.561646 
	C145.540619,1242.672729 144.923584,1241.718750 146.423248,1241.223511 
	C147.470642,1240.877686 148.538818,1241.200073 148.967728,1242.226074 
	C150.487076,1245.860596 153.006897,1246.615723 156.614639,1245.447998 
	C157.872894,1245.040894 159.431473,1245.730591 159.627304,1247.311157 
	C159.837265,1249.005371 158.307449,1248.872803 157.183441,1248.991821 
	C156.046799,1249.112061 154.638962,1248.900146 154.265579,1250.342529 
	C152.957260,1255.396484 150.059921,1254.460449 146.720459,1252.541138 
	C145.320206,1251.736572 143.720428,1251.699219 142.710297,1253.182373 
	C141.761551,1254.575317 142.701736,1255.888306 143.751465,1256.629150 
	C145.637787,1257.959839 146.177216,1259.683838 146.004593,1261.805176 
	C145.826447,1263.994141 146.989197,1265.895508 148.928406,1265.665527 
	C154.969116,1264.949219 161.537094,1266.750977 166.903625,1262.522827 
	C168.882339,1260.963867 170.373367,1260.516724 171.562988,1263.160522 
	C172.395477,1265.010864 173.671402,1266.057983 175.775360,1266.064209 
	C177.160049,1266.068359 177.899048,1266.883789 177.944748,1268.267212 
	C178.090088,1272.664673 179.732269,1272.562012 181.965561,1269.476562 
	C183.125366,1267.874268 184.420151,1267.648682 185.739532,1268.662598 
	C187.439377,1269.968872 187.633179,1272.104980 186.256012,1273.526001 
	C181.681595,1278.245728 185.682251,1277.137451 188.726151,1278.174072 
	C195.710388,1280.552368 202.390106,1276.461914 209.596329,1277.998535 
	C209.997559,1278.000000 210.000000,1278.000000 209.999695,1278.000000 
	C210.578369,1278.685669 210.731720,1279.458008 210.235092,1280.657349 
	C188.645767,1281.000000 167.291550,1281.000000 145.468658,1281.000000 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M353.001770,1250.399902 
	C356.854919,1248.992065 360.759399,1247.588379 363.650330,1251.975220 
	C363.960541,1252.445923 364.766907,1252.637695 365.380615,1252.850586 
	C366.941620,1253.391968 369.529022,1252.175171 369.812958,1254.857422 
	C370.093994,1257.512817 369.485291,1260.124146 367.116882,1261.921875 
	C366.329895,1262.519287 365.461487,1263.066162 364.835999,1263.809448 
	C362.378113,1266.729492 363.065125,1269.280762 366.816956,1269.849731 
	C369.907471,1270.318481 371.583496,1271.466797 372.170990,1274.603027 
	C372.687683,1277.361328 374.896362,1278.115479 377.470459,1277.997192 
	C383.436920,1277.723267 389.276123,1279.621094 395.266113,1279.035889 
	C396.274567,1278.937378 397.258667,1279.167969 397.888672,1280.616455 
	C387.312439,1281.000000 376.624878,1281.000000 365.468658,1281.000000 
	C361.289948,1279.154419 357.375885,1279.471924 353.077942,1279.861328 
	C349.110779,1277.551758 345.255646,1276.723633 341.026276,1277.441162 
	C338.940491,1277.795166 336.798981,1277.846802 334.369751,1277.046143 
	C331.260223,1276.829712 329.978119,1276.274414 332.575256,1273.618774 
	C336.169769,1269.942993 336.054779,1266.195557 332.305634,1262.939819 
	C330.599670,1261.458374 331.028076,1260.312622 332.452576,1259.426392 
	C335.007996,1257.836426 336.575500,1255.759277 337.055695,1252.764160 
	C337.538513,1249.752930 339.257111,1250.241333 341.413971,1251.183838 
	C344.506195,1252.534912 346.371796,1256.097778 350.613953,1255.998779 
	C353.795868,1257.530518 352.275177,1260.281250 352.904907,1262.788574 
	C353.431793,1264.621704 353.093140,1266.064453 352.735352,1267.513306 
	C352.406952,1268.842896 352.118713,1270.318848 353.900452,1270.740112 
	C355.748840,1271.177490 357.665619,1270.868896 358.702423,1269.107422 
	C359.549927,1267.667480 359.024597,1266.208008 357.590729,1265.250122 
	C356.359985,1264.427979 354.775574,1264.280762 353.420898,1262.940796 
	C352.531738,1260.042725 352.817322,1257.421021 352.992493,1254.401367 
	C353.001556,1252.933350 353.001312,1251.866577 353.001770,1250.399902 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1281.000000,117.531342 
	C1277.930176,116.081764 1276.390259,112.570343 1272.721680,110.930016 
	C1271.116699,110.212402 1270.997681,105.759407 1273.756226,103.579758 
	C1275.359619,102.312851 1276.385132,101.020554 1274.724365,99.041618 
	C1273.962524,98.133949 1274.429810,97.144142 1274.895630,96.246132 
	C1276.709473,92.749329 1276.469604,90.074219 1272.491699,88.076523 
	C1270.045288,86.847900 1270.970947,83.508392 1269.258545,81.248260 
	C1268.511719,74.442139 1270.927612,68.342133 1271.994873,62.030403 
	C1272.130493,61.228260 1272.860962,60.201168 1272.575317,59.703224 
	C1269.430786,54.223030 1270.714233,48.217167 1270.196411,42.411442 
	C1269.946289,39.607109 1270.479614,37.031574 1271.465454,34.685417 
	C1273.036011,30.947464 1276.408081,30.464930 1280.657471,32.954945 
	C1281.000000,61.020893 1281.000000,89.041786 1281.000000,117.531342 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M699.772949,1275.327148 
	C700.634216,1273.846313 701.842224,1272.670776 701.799011,1271.543091 
	C701.647583,1267.591797 702.923645,1265.275635 706.795349,1263.487061 
	C708.826172,1262.548828 709.444946,1259.167969 712.187256,1257.651978 
	C715.018127,1256.087036 716.844849,1256.341797 719.415039,1257.807983 
	C721.857788,1259.201294 724.140137,1262.693726 727.828003,1259.300659 
	C730.856934,1254.681030 733.584473,1254.236450 739.346375,1257.543457 
	C739.218140,1260.465942 737.041199,1260.244507 735.381653,1260.627686 
	C732.614502,1261.266479 730.727539,1262.721802 731.143677,1265.719971 
	C731.500305,1268.288696 733.723328,1268.485352 735.778198,1268.239014 
	C738.750244,1267.882568 740.114380,1266.082275 739.754883,1263.089600 
	C739.599670,1261.796875 738.984558,1260.533691 739.817993,1258.903564 
	C742.679382,1257.549316 744.059265,1258.847168 744.933228,1261.601318 
	C744.998718,1263.388916 744.999390,1264.777832 745.000000,1266.583374 
	C743.661316,1269.377441 742.966614,1272.125000 740.779358,1274.014160 
	C738.721680,1275.791626 739.687744,1278.268555 739.222046,1280.731201 
	C737.285645,1281.000000 735.571289,1281.000000 733.428467,1281.000000 
	C731.501282,1279.704712 729.904480,1278.541626 728.675232,1276.926880 
	C726.989075,1274.711792 724.594360,1273.350342 721.878174,1273.207275 
	C718.536743,1273.031250 714.928467,1272.993164 714.950623,1277.989746 
	C714.955261,1279.034302 714.250610,1279.822021 713.246338,1280.752197 
	C711.285645,1281.000000 709.571289,1281.000000 707.428467,1281.000000 
	C705.695801,1279.639648 703.846863,1280.303223 702.343079,1279.743530 
	C700.506348,1279.059814 699.263733,1277.846680 699.772949,1275.327148 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M9.237981,476.722046 
	C6.104618,483.211792 9.381344,489.407349 9.087523,495.639648 
	C8.718239,503.472748 8.994159,503.485748 1.367990,501.049744 
	C1.000000,484.979095 1.000000,468.958221 1.000000,452.468658 
	C1.513852,451.575165 1.686682,451.057739 1.214123,450.211578 
	C1.000000,448.933319 1.000000,447.866669 1.000000,446.399994 
	C1.804329,445.268738 2.702291,444.734314 4.203007,444.661926 
	C5.330328,444.728210 6.020708,444.955505 6.927269,445.525787 
	C7.763750,446.230042 8.172384,446.946655 9.267846,447.180603 
	C9.923199,447.268097 10.274729,447.396576 10.802065,447.794098 
	C13.319623,449.108765 12.978372,451.235992 12.999894,453.612183 
	C12.010465,455.034424 11.123641,456.150360 10.215742,457.637512 
	C9.710227,460.381348 10.850878,462.754944 10.463889,465.551697 
	C10.426439,466.582550 10.398728,467.270081 10.190461,468.279663 
	C9.678171,470.058044 8.968113,471.485779 9.902304,473.358276 
	C10.150183,474.673187 10.067397,475.606750 9.237981,476.722046 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M983.266602,7.236540 
	C977.698608,5.579552 972.589844,6.685070 967.568176,8.318677 
	C965.414917,9.019140 963.393921,9.267970 961.353882,8.156055 
	C955.938477,5.204430 950.053345,4.975898 944.071777,4.949968 
	C940.186951,4.933128 936.137146,4.995937 933.078125,1.357828 
	C961.354248,1.000000 989.708435,1.000000 1018.531372,1.000000 
	C1016.284729,6.456187 1010.616028,4.431844 1006.345398,5.930318 
	C1005.310242,6.293548 1004.042664,5.994459 1002.440979,5.999886 
	C996.920288,4.230705 992.422729,6.646491 987.387207,7.988843 
	C985.826050,7.944719 984.611816,8.155688 983.266602,7.236540 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1274.333008,817.297852 
	C1273.365479,816.203918 1272.730835,815.410217 1272.048096,814.309509 
	C1275.628784,811.512451 1273.976196,809.452881 1271.233521,807.230347 
	C1271.000000,806.248169 1271.000000,805.498779 1271.000000,804.374695 
	C1269.572144,802.164185 1270.383545,800.710327 1272.119263,800.004822 
	C1275.585327,798.596130 1276.146484,795.926208 1275.780273,792.363892 
	C1275.510254,790.632324 1275.456665,789.233398 1275.306885,787.509644 
	C1275.327759,785.845703 1274.315552,785.401550 1273.100586,784.700012 
	C1272.323853,783.999634 1272.042358,783.391785 1271.979980,782.344238 
	C1272.540894,779.355957 1273.123779,776.768738 1276.319458,776.005554 
	C1277.796021,775.652893 1278.946289,774.265625 1278.543823,773.126953 
	C1277.338379,769.715454 1279.364868,766.555237 1278.925659,763.351562 
	C1278.272461,758.585693 1278.376831,753.893616 1278.971191,749.155090 
	C1279.231812,747.077576 1278.990479,745.288330 1276.229980,744.777710 
	C1274.276733,744.416321 1273.118896,742.065735 1274.847290,741.182495 
	C1280.389282,738.350830 1278.462891,733.361877 1278.919312,729.153625 
	C1279.309937,725.551270 1278.961426,721.872498 1279.020508,718.228760 
	C1279.043579,716.810425 1278.556030,715.171143 1280.614990,714.112305 
	C1281.000000,748.354248 1281.000000,782.708435 1281.000000,817.531372 
	C1280.194824,817.753601 1279.389648,817.507202 1278.292236,817.131592 
	C1277.003052,817.841003 1275.899658,818.084351 1274.333008,817.297852 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1281.000000,5.500037 
	C1278.855347,6.109721 1276.606934,5.850227 1274.591064,6.417686 
	C1271.731812,7.222611 1269.324463,8.727892 1270.964355,12.527822 
	C1271.513672,13.800752 1272.480835,15.554514 1270.570679,16.460680 
	C1268.586792,17.401810 1266.262939,17.524353 1264.345337,16.381809 
	C1262.353882,15.195248 1263.921753,13.967155 1264.705200,12.715643 
	C1266.893555,9.219213 1266.135254,7.767354 1262.251587,6.969077 
	C1258.229858,6.142436 1254.263672,5.037090 1250.286743,4.001794 
	C1239.161865,1.105679 1228.387207,5.861989 1217.344849,5.530270 
	C1212.916382,5.397236 1208.542725,4.687439 1204.105957,4.723630 
	C1202.606201,4.735864 1201.563965,3.298891 1201.056030,1.371673 
	C1226.349121,1.000000 1251.698853,1.088071 1277.046631,0.896805 
	C1280.503540,0.870720 1281.724609,1.648813 1281.000000,5.500037 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M1073.657715,1275.218872 
	C1079.394165,1273.601074 1084.757935,1274.594604 1090.192017,1274.786133 
	C1098.561768,1275.081421 1106.979126,1274.028076 1115.688110,1273.273438 
	C1116.409546,1272.579346 1116.896606,1272.288452 1117.889648,1272.070068 
	C1121.278809,1272.023438 1124.160034,1270.919434 1127.166992,1271.455078 
	C1129.417480,1271.856079 1131.346313,1272.653442 1132.010010,1275.066406 
	C1132.662720,1277.438721 1131.896362,1279.283569 1129.332275,1280.721680 
	C1128.555542,1281.000000 1128.111084,1281.000000 1127.333252,1281.000000 
	C1126.251709,1280.163086 1125.410889,1279.891479 1124.206665,1280.788086 
	C1106.979126,1281.000000 1089.958130,1281.000000 1072.468628,1281.000000 
	C1070.345703,1278.651001 1071.415894,1276.946289 1073.657715,1275.218872 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1167.732056,1264.716064 
	C1172.156250,1265.809570 1174.664307,1270.424072 1179.902344,1269.428833 
	C1182.307495,1268.971802 1182.093750,1272.483032 1180.455322,1273.304565 
	C1176.640869,1275.217651 1177.264038,1277.489746 1178.987549,1280.635742 
	C1173.645020,1281.000000 1168.290161,1281.000000 1162.467529,1281.000000 
	C1160.900024,1276.107544 1157.792358,1273.879272 1152.926758,1272.638184 
	C1149.990601,1271.889404 1147.062012,1269.626953 1145.590332,1266.158447 
	C1147.643311,1264.241821 1150.095703,1263.248169 1151.627197,1260.643311 
	C1155.942139,1257.934082 1158.987427,1259.461792 1161.938721,1262.897461 
	C1164.059204,1263.339355 1165.917358,1263.337280 1167.732056,1264.716064 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M1129.444092,1281.000000 
	C1129.673828,1278.790527 1132.403198,1276.844238 1130.325073,1274.276245 
	C1128.482422,1271.999023 1125.690430,1273.300049 1123.317139,1273.027222 
	C1121.688232,1272.839844 1120.239014,1274.448730 1118.266113,1273.256348 
	C1117.125610,1271.491211 1117.417480,1270.001709 1118.274048,1268.207031 
	C1120.553223,1265.580078 1124.466431,1267.501465 1126.344238,1264.196045 
	C1127.363037,1258.762817 1124.690186,1255.458618 1120.649902,1252.421143 
	C1120.335693,1251.877686 1120.243164,1251.638672 1120.097168,1251.013184 
	C1122.042114,1245.871460 1128.517334,1243.517822 1134.265991,1245.505371 
	C1136.801392,1246.382202 1138.998291,1247.867065 1141.382568,1249.774902 
	C1141.893555,1250.160889 1142.076172,1250.338501 1142.446045,1250.842285 
	C1143.139526,1253.458740 1142.246826,1254.562988 1139.878662,1254.755981 
	C1134.766113,1255.172607 1134.250977,1255.962036 1135.709717,1260.887573 
	C1136.133179,1262.317017 1136.869141,1263.621826 1137.920288,1265.172852 
	C1138.517944,1267.600708 1138.747925,1269.701294 1138.566162,1272.225830 
	C1137.693604,1275.143799 1138.505981,1277.879028 1137.244019,1280.687744 
	C1134.629395,1281.000000 1132.258911,1281.000000 1129.444092,1281.000000 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M435.471924,5.000134 
	C433.569916,3.244021 431.854980,3.538436 429.789764,3.736117 
	C427.172760,3.986613 423.861877,5.461575 422.064575,1.379121 
	C457.687561,1.000000 493.375122,1.000000 529.531372,1.000000 
	C529.103088,4.272512 526.386780,3.964055 523.936523,3.980754 
	C516.457764,4.031723 508.975464,3.869792 501.499969,4.035483 
	C493.621033,4.210118 485.748291,4.666765 477.437653,5.000118 
	C471.981689,7.410400 466.888184,5.534914 461.409058,5.128878 
	C452.644287,5.000073 444.292267,5.000105 435.471924,5.000134 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1277.880981,1233.308594 
	C1277.209473,1230.105957 1276.552612,1227.285034 1277.685425,1224.233032 
	C1278.468018,1222.124756 1278.065308,1219.923950 1276.101074,1218.383057 
	C1272.558228,1215.603882 1271.560303,1208.936279 1274.476562,1205.146484 
	C1276.168091,1202.948242 1277.077881,1200.523560 1277.776001,1197.976929 
	C1278.216553,1196.369141 1278.402344,1194.291504 1276.554077,1193.557495 
	C1269.550903,1190.776489 1275.673950,1190.592163 1277.277954,1189.476074 
	C1277.528320,1189.301758 1277.656250,1188.951782 1277.920410,1188.341553 
	C1277.420410,1187.314331 1277.267822,1186.541748 1277.764648,1185.342529 
	C1276.475708,1178.495972 1276.593750,1172.068604 1278.949219,1165.371582 
	C1279.000122,1160.893799 1278.946167,1156.786499 1279.027832,1152.682007 
	C1279.058594,1151.135132 1278.409058,1149.320190 1280.615112,1148.111816 
	C1281.000000,1176.354248 1281.000000,1204.708496 1281.000000,1233.531372 
	C1280.838867,1234.150024 1280.677856,1234.299927 1280.215820,1234.586670 
	C1279.693970,1234.737305 1279.473511,1234.751099 1278.932739,1234.678345 
	C1278.228638,1234.354492 1277.945190,1234.029541 1277.880981,1233.308594 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M6.669067,657.081360 
	C10.692822,656.822205 12.153969,658.312500 10.143323,661.727905 
	C9.305154,663.151672 8.666798,664.406250 9.595405,665.822388 
	C12.731114,670.604187 10.250473,676.054626 11.350156,681.203613 
	C12.084235,684.640747 11.242373,689.017578 10.986135,692.966187 
	C10.924375,693.917908 11.146654,695.158997 9.847826,695.376282 
	C8.940347,695.528076 8.272140,694.707458 8.208974,693.859131 
	C7.902652,689.745239 4.629982,689.682922 1.388149,689.010620 
	C1.000000,679.645752 1.000000,670.291565 1.000000,660.468628 
	C2.444571,658.424011 4.606486,658.197815 6.669067,657.081360 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1268.476685,1281.000000 
	C1267.712769,1277.689575 1268.409668,1274.697876 1271.051514,1272.470947 
	C1273.112549,1270.733521 1273.111572,1268.443359 1272.993286,1266.101562 
	C1272.703857,1260.376343 1273.106201,1254.873169 1276.882568,1250.089233 
	C1278.533813,1247.997559 1277.940430,1245.322021 1278.000000,1242.428467 
	C1277.762695,1241.576416 1277.780151,1241.155762 1278.246094,1240.485229 
	C1279.052490,1239.818726 1279.688110,1239.612549 1280.700684,1239.836792 
	C1281.000000,1252.724609 1280.958008,1265.449341 1281.042358,1278.173462 
	C1281.056030,1280.225220 1281.039673,1281.381348 1278.363647,1281.081055 
	C1275.262939,1280.733032 1272.093140,1281.000000 1268.476685,1281.000000 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1146.172241,1265.640869 
	C1146.243774,1267.714478 1147.388672,1268.245361 1148.852539,1267.821045 
	C1150.921631,1267.220947 1152.147339,1267.194336 1151.251953,1269.927246 
	C1150.961182,1270.814331 1151.532227,1271.845337 1152.465088,1271.521729 
	C1156.254150,1270.206665 1158.028809,1273.533203 1160.744263,1274.729492 
	C1164.075928,1276.197388 1163.741333,1277.955688 1162.044434,1280.660645 
	C1157.643066,1281.000000 1153.286255,1281.000000 1148.464600,1281.000000 
	C1146.046875,1278.973022 1142.138062,1280.234131 1140.893921,1276.459229 
	C1140.698242,1274.118286 1140.473511,1272.233643 1139.415161,1270.456177 
	C1138.225586,1268.458130 1137.159546,1266.198853 1139.216064,1264.153198 
	C1141.137451,1262.241699 1143.355347,1262.571899 1145.769531,1264.258057 
	C1146.146362,1264.789307 1146.245483,1265.035645 1146.172241,1265.640869 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M11.218805,303.599670 
	C11.257738,304.495850 11.515668,304.991730 11.887227,305.743164 
	C12.013421,306.607483 12.059226,307.207001 12.737112,307.851868 
	C13.497343,307.741394 13.992898,307.484009 14.743940,307.113281 
	C15.576871,307.685822 15.741701,308.455841 15.236107,309.657288 
	C14.596637,310.688080 14.310459,311.495331 13.761810,312.036438 
	C12.927444,312.859375 11.766771,313.555328 12.346592,314.892822 
	C12.914871,316.203705 14.191087,314.963989 14.910857,315.267090 
	C18.568150,316.807404 22.048391,318.832458 26.331636,318.657806 
	C22.409487,320.867798 18.438152,319.631653 14.266960,319.764221 
	C13.285633,320.426819 12.819945,320.031464 12.195948,319.256592 
	C7.286653,319.225067 5.943143,313.671570 1.371169,313.008545 
	C1.000000,308.975464 1.000000,304.950897 1.000000,300.463196 
	C5.936713,297.734924 8.996877,298.672913 11.218805,303.599670 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M832.468628,1281.000000 
	C832.004211,1279.816406 832.813904,1279.156982 833.813538,1278.249878 
	C834.528687,1277.312744 835.255676,1276.706299 836.062012,1276.723633 
	C841.001099,1276.830444 845.456665,1275.795898 849.406860,1272.514526 
	C850.982117,1271.205933 853.535156,1271.498779 855.112305,1272.691040 
	C859.869812,1276.287598 865.403015,1275.555176 870.733154,1275.977051 
	C872.439697,1276.112183 874.028625,1276.339722 875.688354,1277.732666 
	C877.388245,1278.000000 878.776428,1278.000000 880.581116,1278.000000 
	C881.663757,1278.665771 881.752136,1279.450195 881.236328,1280.655273 
	C864.979126,1281.000000 848.958191,1281.000000 832.468628,1281.000000 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M11.382397,303.989197 
	C9.101375,300.001648 5.281606,300.412567 1.410301,300.000092 
	C1.000000,293.645599 1.000000,287.291199 1.000000,280.468384 
	C3.994159,280.334869 3.950487,282.530243 4.020581,284.767273 
	C4.102353,287.376984 3.494346,290.069214 4.813688,292.558899 
	C5.361681,293.593018 5.893057,294.768463 7.299345,294.575134 
	C8.689806,294.384033 9.707108,293.605194 9.949983,292.057434 
	C10.185411,290.557098 9.454319,288.504181 11.914308,288.159760 
	C13.195889,287.980316 14.212709,288.579834 14.701580,289.759705 
	C15.455176,291.578491 16.223238,293.218109 13.692780,294.705902 
	C12.240566,295.559723 13.332498,297.329041 14.086381,298.672333 
	C15.609227,301.385742 15.371025,303.450317 11.382397,303.989197 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M481.468658,1281.000000 
	C481.244171,1279.644409 482.111267,1278.873169 483.427551,1279.056885 
	C488.836823,1279.811401 494.207306,1277.313354 499.616882,1278.907837 
	C499.925232,1278.998901 500.383392,1279.092896 500.590820,1278.939819 
	C505.661133,1275.201050 510.677338,1278.743896 515.730957,1278.890137 
	C521.147644,1279.047241 526.532227,1277.592041 532.021667,1277.966431 
	C535.129150,1278.178101 538.273621,1277.998657 540.906982,1280.635376 
	C521.312439,1281.000000 501.624878,1281.000000 481.468658,1281.000000 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M1.000000,380.468536 
	C8.328020,380.630249 10.565971,383.975800 8.437082,390.652710 
	C7.860832,392.460022 8.078056,394.532440 8.009359,396.485931 
	C7.944871,398.319794 8.036178,400.183899 5.383241,400.934753 
	C4.008694,401.824799 2.929209,402.074371 1.361853,401.252930 
	C1.000000,394.312347 1.000000,387.624725 1.000000,380.468536 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M713.468384,1281.000000 
	C713.206970,1280.208984 713.425842,1279.420776 713.618713,1278.626343 
	C714.177063,1276.325806 711.657654,1273.030762 716.265808,1272.054199 
	C721.229980,1271.002197 725.691895,1271.346069 729.341064,1275.071655 
	C730.795166,1276.556152 731.733948,1278.545654 732.953674,1280.652588 
	C726.645569,1281.000000 720.291199,1281.000000 713.468384,1281.000000 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M1.000000,884.333313 
	C5.917614,881.974976 9.468314,883.962891 8.765738,889.072144 
	C7.664394,897.081482 6.787276,905.085266 6.979001,914.234924 
	C2.204820,910.185913 4.698421,904.631592 1.367681,901.098389 
	C1.000000,900.250000 1.000000,899.500000 1.000000,898.375000 
	C2.821292,894.256348 2.095106,890.405884 1.239899,886.280029 
	C1.000000,885.555542 1.000000,885.111084 1.000000,884.333313 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M721.531372,1.000000 
	C722.000000,2.066704 722.000000,3.133407 722.000000,4.600119 
	C720.722534,7.294553 718.533569,7.369669 715.899780,7.304238 
	C714.203186,6.913452 712.903076,6.790403 711.177612,7.224629 
	C705.431519,6.393440 699.842590,7.905321 694.333984,5.274791 
	C691.735535,4.433779 689.466125,3.876167 688.065308,1.335900 
	C699.020874,1.000000 710.041809,1.000000 721.531372,1.000000 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M1.000000,1019.468567 
	C5.327268,1020.883850 5.701755,1025.210205 6.909461,1028.914429 
	C7.394805,1030.402954 7.100639,1032.129272 7.844977,1033.685547 
	C10.452561,1039.137817 10.420833,1039.169067 4.724680,1040.579712 
	C3.777736,1040.814331 2.784518,1040.861938 1.406347,1040.998047 
	C1.000000,1033.979004 1.000000,1026.958130 1.000000,1019.468567 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M1.000000,512.468628 
	C6.579354,510.683380 7.043331,511.043610 6.997356,516.717529 
	C6.952175,522.293640 4.700553,527.635986 5.439124,533.355774 
	C5.644703,534.947876 3.591907,535.929871 1.370902,535.991516 
	C1.000000,528.312439 1.000000,520.624817 1.000000,512.468628 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M842.531372,1.000000 
	C840.618408,3.634527 838.764099,8.149221 834.943542,7.521100 
	C829.139282,6.566836 821.799072,8.991731 818.048401,1.351552 
	C826.020935,1.000000 834.041809,1.000000 842.531372,1.000000 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M551.466125,1281.000000 
	C550.993469,1279.838623 551.064514,1278.670898 550.967529,1277.517090 
	C550.446167,1271.313721 550.942932,1270.936646 557.253052,1272.932739 
	C559.149658,1273.532471 561.010864,1274.614624 562.926025,1274.709229 
	C567.194092,1274.919800 567.542542,1276.959106 566.054443,1280.617310 
	C561.310730,1281.000000 556.621521,1281.000000 551.466125,1281.000000 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M210.468628,1281.000000 
	C209.999786,1280.250000 209.999573,1279.500000 209.999359,1278.375000 
	C216.070038,1277.545654 222.182037,1279.014648 228.122192,1275.425903 
	C231.553925,1273.352295 233.285294,1276.544556 232.999786,1280.586670 
	C225.645752,1281.000000 218.291504,1281.000000 210.468628,1281.000000 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M334.150146,1277.289062 
	C339.341034,1275.228882 344.780731,1275.309082 350.268646,1276.089722 
	C351.720764,1276.296265 351.848389,1277.595825 352.736145,1278.747314 
	C353.377350,1279.385376 353.524658,1279.845215 353.219788,1280.689697 
	C347.645081,1281.000000 342.290131,1281.000000 336.467590,1281.000000 
	C336.026276,1279.564941 335.050476,1278.627563 334.150146,1277.289062 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M1.000000,401.466125 
	C2.066613,401.000061 3.133226,401.000153 4.599805,401.000732 
	C4.744133,403.536011 6.016807,405.745575 6.482862,408.119659 
	C7.249690,412.025879 6.644404,415.351929 1.381092,415.989868 
	C1.000000,411.310760 1.000000,406.621490 1.000000,401.466125 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M1073.698364,1274.839844 
	C1073.338623,1276.758057 1072.677246,1278.515991 1072.007812,1280.636963 
	C1069.958374,1281.000000 1067.916626,1281.000000 1065.437500,1281.000000 
	C1065.330933,1279.384399 1065.754395,1277.780762 1065.967896,1276.149780 
	C1066.197021,1274.399536 1065.536865,1272.238403 1067.721436,1271.386230 
	C1069.917725,1270.529419 1070.886719,1272.640625 1072.244141,1273.733521 
	C1072.631226,1274.045288 1073.012695,1274.364136 1073.698364,1274.839844 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M699.612366,1274.989502 
	C700.123657,1279.540649 704.931824,1278.041748 706.914307,1280.648193 
	C703.307068,1281.000000 699.614197,1281.000000 695.460632,1281.000000 
	C695.029297,1278.817627 695.131409,1276.630981 692.307678,1275.915283 
	C691.361450,1275.675415 690.216187,1275.148315 690.647461,1273.945190 
	C691.156250,1272.525513 692.620605,1272.921631 693.644287,1273.167847 
	C695.539978,1273.623657 697.368347,1274.359619 699.612366,1274.989502 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M949.468628,1281.000000 
	C950.317810,1277.028076 953.763428,1277.850342 956.505615,1278.089355 
	C962.610107,1278.621460 968.865845,1278.056152 974.889526,1280.617188 
	C966.645752,1281.000000 958.291504,1281.000000 949.468628,1281.000000 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M992.655029,1275.158447 
	C996.996399,1275.456421 1001.298035,1275.339966 1002.952271,1280.639893 
	C998.975464,1281.000000 994.950928,1281.000000 990.463196,1281.000000 
	C988.500000,1278.182983 990.905640,1276.953491 992.655029,1275.158447 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M739.375000,1281.000000 
	C736.291504,1277.773438 737.378967,1275.085449 740.021912,1272.420044 
	C741.613953,1270.814331 742.903503,1268.908569 744.664429,1267.068848 
	C745.940125,1271.842773 742.604004,1275.722290 742.010742,1280.611084 
	C741.250000,1281.000000 740.500000,1281.000000 739.375000,1281.000000 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M6.844503,656.650696 
	C7.051361,661.056641 3.979037,659.731445 1.415894,659.999878 
	C1.000000,656.307068 1.000000,652.614197 1.000000,648.460632 
	C3.593328,646.669312 4.553775,648.724854 5.874791,650.646606 
	C6.061337,652.790649 5.743238,654.628357 6.844503,656.650696 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M1044.468018,1281.000000 
	C1044.654663,1278.147461 1045.853394,1276.602417 1049.179565,1278.024780 
	C1053.547729,1279.892944 1057.769653,1279.915894 1060.843506,1275.401489 
	C1062.634155,1276.944580 1061.735229,1278.697388 1062.000000,1280.603027 
	C1056.312012,1281.000000 1050.623901,1281.000000 1044.468018,1281.000000 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M1141.739502,1275.739258 
	C1142.601685,1279.270752 1147.060425,1277.296021 1147.927246,1280.652466 
	C1144.638672,1281.000000 1141.277466,1281.000000 1137.458008,1281.000000 
	C1137.036499,1278.577393 1135.365479,1276.178101 1136.950439,1273.367432 
	C1139.740723,1271.571289 1140.205200,1274.255981 1141.739502,1275.739258 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M126.539368,1.000000 
	C126.555664,4.128529 124.211540,5.478007 121.585968,5.788332 
	C118.621773,6.138680 116.273331,4.640334 115.048920,1.352964 
	C118.692909,1.000000 122.385818,1.000000 126.539368,1.000000 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M992.828308,1274.699463 
	C993.631897,1277.641968 990.502747,1278.228149 990.050171,1280.652344 
	C987.629456,1281.000000 985.258850,1281.000000 982.444153,1281.000000 
	C983.885742,1278.029663 988.043701,1277.436035 989.357483,1273.643555 
	C989.778809,1272.427490 991.717957,1273.219482 992.828308,1274.699463 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M1.345114,441.266998 
	C1.000000,438.629425 1.000000,436.258881 1.000000,433.444153 
	C2.170302,431.714935 3.401379,430.606110 5.662747,431.753448 
	C5.999568,431.998779 6.000000,432.000000 6.000485,432.000000 
	C7.520514,435.195770 7.613635,438.358704 5.406069,441.690033 
	C3.860418,442.286652 2.743469,442.198761 1.345114,441.266998 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1281.000000,913.562500 
	C1279.873657,914.172363 1278.662720,914.143372 1277.643433,914.570862 
	C1276.519287,915.042297 1275.458740,916.719971 1274.381714,914.938110 
	C1273.598022,913.641174 1273.723999,911.961914 1275.239014,910.976746 
	C1277.032471,909.810669 1278.082275,907.689209 1280.650391,907.046753 
	C1281.000000,909.041687 1281.000000,911.083313 1281.000000,913.562500 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M881.444153,1281.000000 
	C880.999756,1280.250000 880.999512,1279.500000 880.998413,1278.375000 
	C881.325073,1276.501343 880.065918,1274.303711 882.137817,1273.646729 
	C883.610779,1273.179688 884.033264,1275.206177 884.768311,1276.209595 
	C885.940735,1277.810059 888.439697,1278.038452 888.951782,1280.656494 
	C886.629456,1281.000000 884.258850,1281.000000 881.444153,1281.000000 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M902.454102,1281.000000 
	C901.721375,1278.074707 903.556763,1276.730103 905.979553,1276.239014 
	C909.174927,1275.591064 910.716003,1277.783936 911.951660,1280.647461 
	C908.969421,1281.000000 905.938782,1281.000000 902.454102,1281.000000 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M6.257991,650.732788 
	C3.804287,651.171448 3.330874,648.742676 1.328650,648.062500 
	C1.000000,646.933289 1.000000,645.866638 1.000000,644.399963 
	C6.663084,644.224976 7.167584,644.815979 6.257991,650.732788 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M777.458069,1281.000000 
	C779.331116,1276.129639 782.574646,1275.918823 787.924683,1280.647461 
	C784.638733,1281.000000 781.277405,1281.000000 777.458069,1281.000000 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M464.466125,1281.000000 
	C466.806702,1278.156128 474.494293,1277.791748 478.915924,1280.648438 
	C474.310760,1281.000000 469.621490,1281.000000 464.466125,1281.000000 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M353.460632,1281.000000 
	C352.999573,1280.555542 352.999176,1280.111084 352.998169,1279.333252 
	C357.132599,1277.604126 361.089569,1277.859863 364.906860,1280.637085 
	C361.307098,1281.000000 357.614166,1281.000000 353.460632,1281.000000 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1281.000000,878.541931 
	C1276.471313,875.930481 1276.428955,875.279358 1280.647949,868.085815 
	C1281.000000,871.361267 1281.000000,874.722595 1281.000000,878.541931 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M1.000000,886.460632 
	C4.534301,889.714478 3.448977,893.728088 1.381389,897.890320 
	C1.000000,894.307068 1.000000,890.614197 1.000000,886.460632 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M1.000000,441.416656 
	C2.066766,440.999969 3.133532,440.999939 4.600331,441.000122 
	C7.224177,442.227905 7.079973,443.747620 5.274317,445.718689 
	C3.933818,445.999359 2.866971,445.999969 1.400062,446.000275 
	C1.000000,444.611115 1.000000,443.222198 1.000000,441.416656 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1281.000000,838.550049 
	C1278.175781,836.302124 1277.878540,832.543640 1280.648193,830.051208 
	C1281.000000,832.700012 1281.000000,835.400024 1281.000000,838.550049 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1279.293579,1016.137939 
	C1277.654541,1013.089539 1277.236450,1010.332397 1280.648804,1008.075195 
	C1281.000000,1010.041687 1281.000000,1012.083313 1281.000000,1014.562500 
	C1280.596680,1015.500122 1280.246094,1016.058777 1279.293579,1016.137939 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M1023.047363,1275.341064 
	C1026.052856,1275.785522 1029.016357,1276.687866 1029.954468,1280.646484 
	C1029.250000,1281.000000 1028.500000,1281.000000 1027.375000,1281.000000 
	C1027.172241,1278.145020 1023.472473,1278.133423 1023.047363,1275.341064 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M1041.562500,1.000000 
	C1040.173950,5.734232 1037.736450,4.462382 1035.073486,1.348793 
	C1037.041626,1.000000 1039.083374,1.000000 1041.562500,1.000000 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1281.000000,862.562500 
	C1278.176392,860.967896 1276.370850,858.831116 1280.645508,856.052734 
	C1281.000000,858.041687 1281.000000,860.083313 1281.000000,862.562500 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1281.000000,1098.562500 
	C1276.935059,1097.091309 1277.991211,1094.679565 1280.663208,1092.071045 
	C1281.000000,1094.041626 1281.000000,1096.083374 1281.000000,1098.562500 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M5.909879,431.639709 
	C4.575108,432.300537 3.150649,432.602264 1.363095,432.952026 
	C1.000000,432.250000 1.000000,431.500000 1.000000,430.375000 
	C2.644289,430.285522 4.646183,429.225098 5.909879,431.639709 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M767.428467,1281.000000 
	C768.572815,1277.772217 770.634949,1278.539062 772.908630,1280.643799 
	C771.285645,1281.000000 769.571289,1281.000000 767.428467,1281.000000 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1281.000000,1239.583374 
	C1280.503906,1240.257690 1280.007690,1240.515381 1279.255737,1240.886475 
	C1277.007080,1239.090576 1277.190430,1236.933350 1278.639893,1234.336548 
	C1279.000000,1234.000000 1279.500000,1234.000000 1279.750000,1234.000000 
	C1280.231812,1234.213135 1280.463257,1234.426636 1280.847046,1234.820312 
	C1281.000000,1236.388916 1281.000000,1237.777832 1281.000000,1239.583374 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1281.000000,848.583374 
	C1278.144165,847.768494 1278.284302,846.103882 1280.650757,844.083984 
	C1281.000000,845.388916 1281.000000,846.777771 1281.000000,848.583374 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1281.000000,710.600037 
	C1278.457886,710.121277 1278.380249,708.813171 1280.664795,707.075928 
	C1281.000000,708.066711 1281.000000,709.133362 1281.000000,710.600037 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M1279.000000,1016.250000 
	C1279.496094,1015.742310 1279.992310,1015.484680 1280.744263,1015.113525 
	C1281.000000,1016.066711 1281.000000,1017.133362 1281.000000,1018.600037 
	C1280.113892,1018.893250 1279.356323,1018.609619 1279.054199,1017.312500 
	C1279.000000,1017.000000 1279.000000,1016.500000 1279.000000,1016.250000 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M1.000000,450.333313 
	C1.864199,450.128937 3.122764,449.721710 3.338394,450.912384 
	C3.535846,452.002716 2.373964,451.830170 1.333355,451.991425 
	C1.000000,451.555542 1.000000,451.111115 1.000000,450.333313 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M1124.375000,1281.000000 
	C1124.560913,1279.010742 1125.395996,1278.246094 1126.937378,1280.678223 
	C1126.250000,1281.000000 1125.500000,1281.000000 1124.375000,1281.000000 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M1030.625000,1.000000 
	C1030.438843,2.988749 1029.603760,3.753153 1028.062744,1.321688 
	C1028.750000,1.000000 1029.500000,1.000000 1030.625000,1.000000 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M1.000000,272.333313 
	C1.627879,272.106873 2.380742,272.172272 2.420881,272.957367 
	C2.436235,273.257721 1.869483,273.587830 1.284001,273.952209 
	C1.000000,273.555542 1.000000,273.111115 1.000000,272.333313 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1281.000000,1088.666748 
	C1280.371826,1088.893433 1279.618652,1088.828125 1279.578613,1088.042603 
	C1279.563354,1087.742188 1280.130127,1087.411987 1280.715820,1087.047607 
	C1281.000000,1087.444458 1281.000000,1087.888916 1281.000000,1088.666748 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1281.000000,1234.750000 
	C1280.696899,1234.871216 1280.443848,1234.677246 1280.120361,1234.209106 
	C1280.000000,1234.000000 1280.500000,1234.000000 1280.750000,1234.000000 
	C1281.000000,1234.000000 1281.000000,1234.500000 1281.000000,1234.750000 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1270.868652,225.634125 
	C1271.906372,229.984512 1272.208618,234.017746 1272.000000,238.546005 
	C1272.765503,241.187958 1272.967529,243.335251 1271.263428,245.680786 
	C1272.779907,253.961304 1271.718384,262.039490 1272.000000,270.530334 
	C1270.525391,274.273651 1268.389282,276.011536 1264.607300,274.417023 
	C1263.725342,274.045166 1262.583008,273.845215 1261.677856,274.060242 
	C1259.229614,274.641846 1255.592041,271.927399 1254.784058,274.004333 
	C1253.612305,277.016052 1251.915039,280.328186 1252.482178,283.904541 
	C1252.630371,284.838898 1253.345825,285.909576 1252.003540,286.427826 
	C1251.084595,286.782623 1250.385620,285.988464 1249.959839,285.333038 
	C1248.471069,283.041962 1246.387573,282.253967 1243.722656,281.833282 
	C1240.604858,281.341095 1238.935669,279.055878 1239.000488,275.750610 
	C1239.038696,273.795471 1238.911865,272.052917 1236.230103,272.005188 
	C1233.648193,271.959229 1232.137573,272.819550 1231.951172,275.635284 
	C1231.809814,277.773010 1231.262085,279.880768 1231.017456,282.015900 
	C1230.864502,283.350281 1231.008545,284.882172 1229.526855,285.441833 
	C1228.104614,285.979004 1226.865234,285.187347 1226.226929,283.907959 
	C1225.594727,282.640564 1224.901245,282.246338 1223.488770,282.654602 
	C1220.435059,283.537170 1218.324341,282.830841 1217.764160,279.214508 
	C1217.441284,277.130249 1215.552246,277.014374 1213.822388,277.007416 
	C1210.657715,276.994690 1207.484375,277.124054 1204.331299,276.927734 
	C1202.003174,276.782776 1199.961670,275.881866 1200.581055,272.904114 
	C1201.547119,268.259857 1199.009155,266.544556 1195.001465,265.954651 
	C1194.836914,265.930450 1194.663208,265.946045 1194.505127,265.902344 
	C1191.614380,265.103394 1187.739502,263.573700 1186.030518,265.792267 
	C1184.253540,268.099274 1186.747925,271.255371 1188.708740,273.611298 
	C1189.671021,274.767578 1189.952759,276.283905 1190.046387,277.829254 
	C1190.169556,279.862701 1190.983643,281.549652 1193.459106,283.257385 
	C1193.130005,279.066956 1192.381226,275.614319 1194.046387,272.438690 
	C1194.412354,271.740936 1194.888672,270.819275 1195.520508,270.602692 
	C1196.749268,270.181488 1197.330200,271.379486 1197.584106,272.265259 
	C1199.413818,278.648010 1200.907593,285.244415 1198.621338,291.645752 
	C1197.569702,294.590454 1193.800537,295.580048 1190.641602,295.933746 
	C1185.760010,296.480408 1184.591431,299.417389 1185.140625,303.633026 
	C1185.802246,308.712219 1184.415405,309.976532 1179.436279,308.910370 
	C1177.630127,308.523651 1175.757202,305.657990 1174.149902,307.866608 
	C1172.565063,310.044342 1170.592285,312.220337 1170.034912,315.078857 
	C1168.710571,321.868958 1166.560059,322.664703 1160.914795,318.416443 
	C1159.972778,317.707489 1158.996704,316.631744 1157.861694,317.463898 
	C1156.395996,318.538330 1157.266113,320.132080 1158.092041,320.980896 
	C1161.454956,324.436920 1161.753174,328.397461 1161.099976,332.923523 
	C1160.604004,336.361267 1161.449707,339.481476 1166.260376,338.479614 
	C1167.995972,338.118134 1168.735596,339.174225 1168.851929,340.688934 
	C1168.987427,342.453766 1167.813965,342.892426 1166.304810,342.995819 
	C1164.409180,343.125671 1162.064697,342.422302 1161.280640,345.075256 
	C1160.831055,346.596161 1162.062744,347.500519 1162.977905,348.449493 
	C1164.783691,350.321716 1166.552002,350.063721 1168.801392,348.915833 
	C1171.486694,347.545593 1175.005615,347.706696 1176.294067,344.080566 
	C1177.337036,341.145447 1177.852905,338.434021 1174.083862,337.208344 
	C1171.818604,336.471649 1170.318726,335.163330 1171.363037,332.868713 
	C1172.417969,330.550690 1174.545410,329.619843 1177.199463,330.617035 
	C1180.508057,331.860260 1183.317139,332.830383 1182.222900,337.775818 
	C1181.452759,341.256378 1180.588867,345.865570 1186.170044,347.658051 
	C1187.577881,348.110199 1188.192261,350.631836 1187.591064,352.562592 
	C1186.918213,354.723785 1185.238159,355.757324 1183.086548,355.928467 
	C1180.789429,356.111237 1178.322266,355.653168 1176.682007,357.870056 
	C1176.217163,358.498352 1175.610962,359.281403 1175.648315,359.961700 
	C1175.705200,360.993622 1176.823242,360.850159 1177.620728,360.986023 
	C1183.025024,361.906891 1184.538086,365.012360 1181.979004,369.970398 
	C1181.600464,370.703827 1181.054199,371.350159 1180.591064,372.040405 
	C1178.356689,375.369568 1173.892334,378.404205 1180.069580,382.432159 
	C1181.601929,383.431305 1179.710693,384.682953 1178.342896,384.879150 
	C1175.505493,385.286102 1172.678345,381.677307 1173.672485,378.982788 
	C1175.376831,374.363739 1176.816650,369.842010 1171.902588,366.083710 
	C1170.614380,365.098480 1171.355835,363.679626 1171.690063,362.380676 
	C1172.402832,359.610107 1171.228149,357.577057 1168.507080,357.211304 
	C1165.229004,356.770660 1167.014526,359.466309 1166.492310,360.879517 
	C1165.561401,363.399750 1165.072998,365.880096 1164.791138,368.531067 
	C1164.531738,370.969971 1162.656250,371.312958 1160.670654,370.289581 
	C1155.337402,367.540924 1149.483398,369.828735 1143.943237,368.537109 
	C1142.187256,368.127686 1140.826050,370.551392 1140.034180,372.523376 
	C1138.368164,376.672150 1137.456909,381.321259 1132.431763,383.248230 
	C1131.214355,383.715027 1130.478760,385.438110 1129.260742,386.791748 
	C1128.787109,387.119293 1128.574097,387.238556 1128.031738,387.196014 
	C1127.411011,386.644043 1127.198120,386.216187 1127.048340,385.390625 
	C1128.108398,381.442139 1127.073364,379.040283 1122.863770,378.097900 
	C1122.327881,377.828339 1122.123047,377.696198 1121.659180,377.298096 
	C1120.671631,375.962769 1120.380127,374.740692 1120.134277,373.112183 
	C1120.130005,372.225037 1120.242798,371.739075 1120.670654,370.983307 
	C1121.810791,370.110962 1122.827393,369.999939 1123.727295,370.540527 
	C1125.530762,371.623749 1127.142456,373.461914 1129.380859,371.261841 
	C1131.555786,369.124084 1134.487305,367.769897 1134.222290,363.755341 
	C1133.816528,357.609039 1135.495361,355.445740 1141.134277,353.320618 
	C1143.309448,352.500885 1145.499390,351.566956 1147.847168,351.525024 
	C1152.213623,351.446991 1153.529053,349.037201 1153.419800,345.062683 
	C1153.313354,341.192139 1152.267212,337.661438 1150.639404,334.213684 
	C1148.527832,334.958832 1148.906128,336.256134 1149.465820,337.534119 
	C1151.154419,341.390198 1149.682617,344.323456 1146.727661,346.746643 
	C1144.470581,348.597565 1141.933350,350.108582 1139.006714,348.423584 
	C1135.589355,346.456024 1138.872437,344.084717 1138.966919,341.711121 
	C1136.703247,346.487823 1135.013428,347.210876 1128.861816,346.181152 
	C1123.641602,345.307312 1123.523682,345.341461 1123.228882,350.524658 
	C1122.921143,355.933594 1122.675171,361.244354 1116.537109,363.754211 
	C1114.618042,364.538879 1114.504150,367.145935 1112.460083,368.419922 
	C1111.871338,368.519531 1111.627930,368.505371 1111.053711,368.330322 
	C1108.576050,365.736908 1109.992554,362.473816 1108.541016,359.563324 
	C1107.413330,358.346680 1106.433960,357.486633 1105.362061,356.744751 
	C1104.711914,356.294708 1104.017334,355.710693 1103.192993,356.428009 
	C1102.949829,356.639587 1102.888672,356.987213 1102.916138,357.321899 
	C1103.001221,358.355591 1103.625122,358.672363 1104.577271,358.577606 
	C1105.538086,358.482025 1106.501099,358.165741 1107.734375,358.788086 
	C1109.233643,362.391846 1105.863892,365.604340 1107.594360,369.386841 
	C1106.235718,373.180542 1107.534790,377.109528 1105.251587,380.695679 
	C1105.013306,382.994110 1104.627808,384.763397 1102.168579,384.886505 
	C1099.746094,385.007690 1099.237427,383.309540 1098.846802,381.346924 
	C1098.592529,380.067993 1097.923096,378.861176 1097.367188,377.655487 
	C1096.733398,376.280884 1096.819580,373.753571 1094.409058,374.621552 
	C1092.607788,375.270142 1090.587891,376.873199 1091.338379,379.150818 
	C1092.461426,382.558685 1091.535522,384.121216 1088.324829,385.724640 
	C1086.050903,386.860168 1085.159790,389.893799 1085.028931,392.665161 
	C1084.956543,394.196655 1084.880127,395.679535 1083.667725,396.800201 
	C1081.568359,398.740692 1082.373779,400.149567 1084.351807,401.586609 
	C1087.695557,404.015778 1086.986572,411.235870 1082.957275,412.680267 
	C1078.221436,414.377960 1079.880127,415.972290 1082.436401,417.984589 
	C1087.893799,422.280426 1091.842041,427.759552 1095.293091,433.729279 
	C1097.032349,436.737946 1099.987671,436.754425 1102.606201,434.840546 
	C1105.756104,432.538208 1109.234741,433.036591 1112.699341,433.051575 
	C1113.565430,433.055328 1114.420532,433.307220 1114.654297,434.254639 
	C1114.933228,435.384949 1114.315308,436.513123 1113.328003,436.582062 
	C1109.586182,436.843506 1107.267212,441.487030 1102.738525,439.656433 
	C1100.842285,438.889893 1101.099731,441.163147 1101.028320,442.369080 
	C1100.930054,444.029572 1101.032959,445.701019 1100.969849,447.364716 
	C1100.890259,449.457825 1099.860107,450.429474 1098.314575,448.602448 
	C1096.791748,446.802094 1095.212036,445.376617 1093.122803,444.212463 
	C1091.228760,443.156952 1092.349121,441.242554 1093.119873,439.778503 
	C1094.515381,437.127655 1094.170044,434.872589 1091.525757,433.366455 
	C1088.906860,431.874756 1086.812256,430.409058 1085.476074,427.251129 
	C1084.413818,424.740997 1081.591553,426.620789 1081.275879,428.566864 
	C1080.513916,433.264160 1077.856689,432.375854 1075.166870,431.014465 
	C1071.416016,429.115997 1068.481323,430.386230 1065.533203,432.689819 
	C1064.113525,433.799042 1063.543701,435.129395 1064.470459,436.564911 
	C1067.299927,440.947662 1063.711548,441.433289 1061.073608,441.858185 
	C1057.865967,442.374908 1054.367188,441.431854 1051.793945,444.386505 
	C1051.487061,444.738708 1050.578735,444.567047 1049.949219,444.638214 
	C1050.099609,440.048004 1045.697021,441.343811 1043.563232,439.706757 
	C1042.732788,439.069672 1041.976318,438.355164 1042.336792,437.229401 
	C1042.816162,435.732239 1044.241333,435.833344 1045.268188,436.133789 
	C1046.665894,436.542816 1047.911743,437.450073 1049.249634,438.087128 
	C1054.434814,440.555908 1058.044067,438.650848 1057.849731,433.028809 
	C1057.754517,430.273254 1060.220459,428.307587 1059.470703,425.247864 
	C1058.647583,425.597443 1057.950073,425.703522 1057.528687,426.101715 
	C1052.844849,430.527527 1052.806396,430.579376 1048.530396,425.183136 
	C1046.687500,422.857239 1043.609497,422.563080 1041.560181,420.532257 
	C1040.553711,419.534851 1039.342651,420.359650 1038.977783,421.739899 
	C1038.295776,424.319916 1036.293091,426.214722 1035.716553,428.958282 
	C1034.976685,432.478485 1032.994507,434.522919 1029.507202,431.074677 
	C1028.468506,430.047699 1027.129761,429.981323 1025.391113,430.002106 
	C1024.159180,429.782684 1023.903809,428.977295 1023.208008,428.201508 
	C1023.697510,425.816559 1028.017334,424.494171 1024.292847,421.164612 
	C1022.665283,419.960297 1021.443909,418.797638 1020.140259,417.285400 
	C1020.089294,415.572052 1020.178650,414.145355 1020.312988,411.999298 
	C1022.717712,413.889832 1024.728882,416.547058 1027.114624,413.245178 
	C1028.529297,411.287231 1027.911987,409.890625 1025.351074,409.050354 
	C1022.743347,406.879089 1024.708374,403.848907 1023.194336,401.263580 
	C1021.972046,399.490112 1021.102966,397.832458 1019.873413,396.510010 
	C1018.526245,395.061096 1016.797058,393.497101 1014.653870,394.764801 
	C1011.398804,396.690277 1008.294495,397.353882 1004.884033,395.214172 
	C1004.264465,394.825500 1003.049133,394.709930 1002.457703,395.059174 
	C997.334656,398.084625 991.382568,394.968658 986.204163,397.355774 
	C984.363892,398.204102 982.942993,397.108582 981.669373,396.088348 
	C978.794983,393.785858 975.117859,393.816559 971.896423,392.538635 
	C970.256165,391.888000 969.458984,392.998535 968.995178,394.803314 
	C963.408630,394.907654 959.283752,392.322845 956.420044,387.245972 
	C960.097839,381.359283 962.031128,380.873535 967.603210,384.052826 
	C972.981140,387.121338 978.575378,386.112091 981.151245,381.345093 
	C982.695190,379.673950 984.304565,379.942322 985.992676,380.155487 
	C989.348877,380.579193 991.196228,378.581055 989.207214,376.140045 
	C985.557617,371.661255 982.466614,367.482788 984.204224,361.358093 
	C984.292114,361.048401 983.700317,360.544464 982.652893,359.762787 
	C983.369934,362.977997 983.877747,365.843506 981.994019,368.221466 
	C980.162292,370.533691 978.467957,373.123474 974.725586,373.277466 
	C971.149109,370.897583 967.169434,372.287415 963.416565,370.827850 
	C961.468628,369.289520 960.964844,367.305206 960.350586,365.426422 
	C959.394592,362.502625 959.969788,360.001068 963.190613,359.024719 
	C965.775696,358.240997 966.751953,356.410156 967.654114,354.130402 
	C970.881226,345.975861 975.309998,344.420227 983.078552,348.614502 
	C986.188599,350.293579 988.710205,350.703766 991.314270,347.367035 
	C994.416321,343.392242 998.184326,343.592224 1001.880310,347.380249 
	C1003.292542,348.827545 1004.603638,350.718597 1006.879822,349.776154 
	C1009.009644,348.894287 1008.187805,346.627350 1008.400146,344.895844 
	C1008.481018,344.236206 1008.396729,345.063660 1008.414734,344.397308 
	C1008.506897,340.987213 1008.617798,337.509186 1012.657288,336.248901 
	C1015.633728,335.320312 1019.256714,337.594421 1020.919250,341.991577 
	C1022.345703,345.764587 1024.403320,347.714752 1028.579346,347.563599 
	C1032.189331,347.432922 1033.858887,349.890747 1034.602905,353.118256 
	C1035.149536,355.489441 1035.456543,357.907104 1032.912354,359.310730 
	C1029.911133,360.966400 1026.834839,361.048462 1024.076050,358.691162 
	C1021.579773,356.558228 1019.299988,354.544128 1017.474915,359.408264 
	C1016.874878,361.007416 1015.324280,360.533417 1014.023926,359.915009 
	C1013.051941,359.452728 1011.979980,358.283447 1011.003418,359.739349 
	C1010.214966,360.914886 1010.077454,362.324158 1011.206238,363.411438 
	C1013.481873,365.603363 1015.902405,367.650848 1018.455261,369.502563 
	C1020.103760,370.698425 1021.268738,369.418884 1022.149658,368.141510 
	C1024.763916,364.350952 1028.254517,363.691864 1032.215210,365.504700 
	C1034.772095,366.674988 1035.811523,365.988312 1036.341675,363.355591 
	C1037.553345,357.338654 1037.193604,351.431335 1035.144653,345.696747 
	C1032.875122,339.344330 1033.147217,338.326385 1039.421875,335.795410 
	C1041.029297,335.147095 1041.259155,334.038116 1041.393677,332.714996 
	C1041.646729,330.224792 1041.919800,327.724182 1042.647461,325.337158 
	C1043.659790,322.015900 1045.325928,321.625580 1047.311401,324.454010 
	C1049.252930,327.219513 1051.544800,328.613220 1054.880859,328.085236 
	C1056.219360,327.873413 1057.585815,327.457031 1057.838867,325.856537 
	C1058.069946,324.394653 1056.898926,323.869019 1055.815918,323.386658 
	C1053.942017,322.551971 1052.684326,321.305115 1053.468628,319.115326 
	C1054.152588,317.206055 1055.753540,316.681458 1057.702271,316.203125 
	C1066.382812,314.072388 1073.345825,317.979065 1080.236694,322.233765 
	C1081.577759,323.061890 1083.032471,323.216064 1084.472168,323.588654 
	C1088.636841,324.666473 1089.646851,326.367920 1087.667969,329.988739 
	C1086.299072,332.493530 1086.004639,334.416351 1087.867920,336.772522 
	C1089.328735,338.619843 1088.815063,340.618042 1086.850098,341.958069 
	C1084.738403,343.398041 1082.781860,342.513062 1081.710938,340.660187 
	C1079.727173,337.227753 1076.794189,334.202667 1076.149170,330.152557 
	C1075.728149,327.508392 1074.586914,326.788940 1072.134521,327.274902 
	C1069.859863,327.725708 1067.498169,327.763824 1065.592285,329.403931 
	C1062.791748,331.814087 1062.721924,333.392578 1065.763184,335.591095 
	C1067.567139,336.895142 1068.807007,338.647888 1070.273560,340.222687 
	C1072.894043,343.036591 1071.988281,345.695618 1069.775024,348.198669 
	C1068.739502,349.369751 1067.442139,350.140900 1065.786987,349.596863 
	C1064.269897,349.098236 1063.967773,347.770386 1063.843628,346.405579 
	C1063.637329,344.134247 1063.734863,340.555908 1060.768555,341.397980 
	C1057.362671,342.364838 1060.639771,345.228516 1060.796387,347.260437 
	C1061.144653,351.777618 1065.380493,352.709320 1068.258667,354.558929 
	C1070.065308,355.720001 1072.509521,355.867371 1074.318115,357.599457 
	C1075.927612,359.140869 1076.404175,357.617279 1076.748413,356.125305 
	C1077.906128,351.110870 1077.958862,351.095215 1082.652832,353.756012 
	C1085.332153,355.274841 1087.437134,354.285217 1089.337402,352.355225 
	C1090.504272,351.170227 1091.043823,349.164154 1092.528687,348.576904 
	C1099.040649,346.001373 1098.052734,341.466888 1097.311157,335.804901 
	C1096.410400,328.927856 1095.725220,321.356354 1102.347168,316.017609 
	C1104.263672,314.472595 1103.468628,312.386444 1103.044067,310.441437 
	C1102.504517,307.970612 1102.085815,305.459534 1103.402954,303.130676 
	C1104.919922,300.448578 1119.170776,299.558472 1121.203125,301.957306 
	C1121.757324,302.611511 1122.191040,303.465515 1121.989258,304.257996 
	C1120.909912,308.496826 1123.666016,310.310944 1126.732422,311.893127 
	C1129.754883,313.452637 1131.383667,316.034058 1132.284058,319.972412 
	C1134.923950,317.716766 1135.001831,315.776855 1133.151978,313.852173 
	C1131.575806,312.212280 1131.177002,310.567444 1131.806274,308.420990 
	C1132.939209,304.557251 1129.482544,302.896790 1127.598999,300.112457 
	C1127.153687,297.428558 1126.310181,296.319275 1123.765381,298.444519 
	C1120.100098,297.910614 1119.003784,295.351532 1119.605469,292.631592 
	C1120.316895,289.415833 1123.128906,290.414307 1125.404663,290.899139 
	C1125.894409,291.003479 1126.384399,291.119843 1126.883301,291.139038 
	C1128.383301,291.196716 1129.917603,291.320435 1131.046021,290.024719 
	C1131.383423,289.637390 1131.638184,289.066589 1131.188232,288.683685 
	C1129.594727,287.327362 1128.064087,288.348053 1126.536987,288.923279 
	C1121.187500,290.938080 1121.165527,290.950073 1118.607788,285.728638 
	C1118.216919,284.930725 1117.801025,284.215698 1116.952148,284.015625 
	C1112.901733,283.061005 1108.994507,281.529755 1104.836182,281.012146 
	C1102.585205,280.731964 1101.635986,281.609955 1102.362061,283.860107 
	C1103.454102,287.244934 1104.665771,290.520935 1108.709229,291.516418 
	C1110.927734,292.062653 1112.980713,293.109985 1112.789062,295.799133 
	C1112.583984,298.676849 1110.471924,300.148468 1107.902466,300.797455 
	C1106.306274,301.200592 1104.569824,301.306519 1102.966431,300.696411 
	C1099.672363,299.442963 1096.773682,297.255493 1092.702148,298.703979 
	C1090.816772,299.374695 1089.107056,297.158264 1088.687744,295.024170 
	C1088.070679,291.882629 1086.327881,290.708099 1083.144043,290.536407 
	C1076.806763,290.194641 1071.779907,284.565460 1071.576172,278.016632 
	C1071.493164,275.346771 1072.069214,272.923737 1074.980469,272.023102 
	C1077.809448,271.147949 1080.431763,271.719452 1081.918457,274.438629 
	C1083.915283,278.091095 1087.480957,279.979797 1090.965454,283.690308 
	C1090.229126,278.231323 1087.196411,274.422272 1087.945068,269.828674 
	C1088.182007,268.375244 1087.757690,266.764465 1088.706909,265.484436 
	C1090.176392,263.502625 1092.077637,262.223755 1093.902832,264.857422 
	C1096.714722,268.915100 1101.762451,269.748810 1105.108276,273.426514 
	C1108.392456,277.036499 1116.369507,274.135223 1119.023193,269.235352 
	C1123.682129,260.633209 1131.272461,258.133545 1140.596191,258.158325 
	C1141.117798,257.217560 1139.988647,256.333618 1139.670776,255.296326 
	C1138.662354,252.005905 1139.400879,249.607803 1143.063232,248.954880 
	C1144.793213,248.646454 1146.236206,248.068390 1147.684326,246.539520 
	C1148.900269,245.826965 1149.884033,245.686035 1151.273926,245.823975 
	C1154.306763,246.492279 1154.016846,249.718613 1155.922119,250.884735 
	C1156.673096,250.810654 1156.917969,250.371460 1156.920166,249.856369 
	C1156.950439,242.769165 1156.919678,242.699036 1163.648682,240.247223 
	C1165.623901,239.527527 1165.277344,238.487946 1164.570190,237.221451 
	C1162.374268,233.288773 1164.899292,231.797607 1167.974121,230.675842 
	C1171.696289,229.317963 1173.156128,231.309647 1173.667114,234.658234 
	C1174.119751,237.624084 1175.994873,239.346863 1178.779175,240.117981 
	C1180.358276,240.555283 1182.157349,241.302353 1183.571777,240.214401 
	C1185.383301,238.821030 1183.700317,237.199249 1183.312500,235.709503 
	C1181.574951,229.035095 1184.008057,225.840515 1190.655151,226.640747 
	C1194.429321,228.323624 1197.549316,230.681808 1201.780029,231.127960 
	C1202.697388,231.106476 1203.202393,230.895279 1203.613159,230.021393 
	C1204.166016,228.675842 1204.666382,227.663040 1205.661621,226.541275 
	C1208.185059,224.738968 1210.927612,224.676865 1213.440552,223.891418 
	C1217.496704,222.623627 1217.345947,221.324799 1216.592041,217.390854 
	C1215.556885,211.989685 1213.072266,206.672043 1214.499146,200.894135 
	C1215.093140,198.489273 1215.753906,196.146149 1218.697388,196.256302 
	C1221.660034,196.367157 1222.930054,198.508011 1223.216675,201.268005 
	C1223.353638,202.587021 1223.212769,203.916687 1223.267334,205.592087 
	C1222.538940,209.526657 1222.473022,212.243652 1227.543457,212.568558 
	C1229.899536,212.719528 1231.947021,215.493469 1234.914307,214.517639 
	C1236.166748,214.105804 1237.910522,214.038849 1237.930420,212.425812 
	C1237.952881,210.616699 1236.121216,210.807297 1234.884155,210.661514 
	C1232.906250,210.428452 1230.871582,210.673080 1228.927734,210.166092 
	C1226.856812,209.625977 1224.551758,209.254120 1224.467041,206.035095 
	C1225.510376,203.942749 1226.315796,202.173523 1226.356567,200.086929 
	C1226.407104,197.506210 1226.044800,195.501617 1222.809814,195.286621 
	C1220.041504,195.102631 1218.573486,193.571243 1219.151245,190.276215 
	C1222.084717,186.955994 1223.651245,183.440125 1223.987793,178.967087 
	C1228.909790,174.284225 1235.967896,172.526535 1240.053711,174.960602 
	C1242.429321,177.872803 1243.905396,180.822800 1244.556396,184.163742 
	C1245.028564,186.586716 1246.216797,188.498154 1248.807495,189.786469 
	C1253.585205,193.596603 1253.619507,193.922226 1249.603516,197.777893 
	C1248.640991,198.702072 1247.815430,199.697403 1247.111084,200.830048 
	C1244.364624,205.247314 1244.641357,206.404541 1249.243408,208.890930 
	C1250.114502,209.361511 1251.086792,209.640244 1251.599121,210.996552 
	C1251.063599,214.580948 1251.647339,217.979401 1250.109619,221.622894 
	C1253.246704,221.621460 1256.279175,221.768875 1258.112915,224.557327 
	C1259.286011,226.341080 1259.210938,228.378845 1258.908691,230.337570 
	C1258.282959,234.392197 1256.239746,235.544708 1252.756226,233.311127 
	C1248.678101,230.696213 1244.354248,232.190552 1239.839600,231.597015 
	C1239.139648,231.146408 1238.819458,230.785507 1238.512085,230.406525 
	C1237.875366,229.621048 1237.925781,227.979294 1236.210815,228.521729 
	C1235.879150,228.626633 1235.554688,228.945236 1235.658447,229.207077 
	C1236.174316,230.509232 1237.689575,229.832016 1238.772705,230.803726 
	C1239.953369,232.397812 1241.506958,232.546127 1242.876465,233.078003 
	C1244.985840,233.897110 1246.006836,235.408768 1245.381592,238.042267 
	C1244.452271,239.222275 1243.261353,239.599213 1242.612183,240.587524 
	C1241.927002,241.630646 1241.774170,242.727478 1242.511719,243.810730 
	C1243.118774,244.702408 1244.012451,245.029968 1245.071289,244.965149 
	C1246.504395,244.877426 1246.990967,243.890808 1247.235840,242.685165 
	C1247.561646,241.080627 1247.420166,239.393509 1248.276367,237.526657 
	C1250.278687,235.305557 1252.456909,235.990799 1254.565918,236.387817 
	C1256.531616,236.757874 1258.481323,237.250031 1260.498657,237.150284 
	C1261.644409,237.093643 1262.990845,237.186157 1263.497803,235.855148 
	C1263.966187,234.625717 1263.258301,233.628540 1262.420166,232.738770 
	C1260.390503,230.583908 1257.550415,228.555481 1260.249634,225.153381 
	C1262.158569,222.747452 1266.864502,222.903870 1270.868652,225.634125 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1088.255371,671.773560 
	C1087.545288,671.813599 1087.036377,671.706665 1086.645508,671.431946 
	C1085.201904,670.417664 1087.051392,666.409668 1083.729736,667.521423 
	C1081.467041,668.278748 1080.235352,670.897644 1080.030029,673.529175 
	C1079.802856,676.438660 1081.114990,680.196716 1076.094116,680.322144 
	C1075.958862,680.325439 1075.709717,680.578613 1075.711304,680.580872 
	C1078.872314,685.229675 1072.057983,684.371521 1072.016968,687.624023 
	C1071.338135,688.937500 1070.340698,689.174377 1068.914673,689.309814 
	C1066.923096,689.262878 1065.354614,688.168457 1063.375366,689.242920 
	C1060.029175,690.746521 1057.323730,692.799316 1054.042969,693.610718 
	C1052.134155,694.082886 1051.698730,695.625183 1051.036011,697.213562 
	C1049.687988,700.444397 1048.044067,700.746033 1045.474121,698.456238 
	C1044.849487,697.899597 1044.698364,696.239380 1043.419922,697.476196 
	C1042.426392,698.437378 1041.712158,699.990112 1043.138428,700.938965 
	C1046.667236,703.286316 1045.428589,705.836853 1044.005737,708.683777 
	C1042.458252,711.780090 1043.641602,714.874084 1044.516357,717.898621 
	C1045.014648,719.621765 1046.364136,719.631897 1047.734741,719.114380 
	C1049.627808,718.399292 1052.009277,718.668884 1052.622192,715.707031 
	C1054.079224,708.666321 1060.537231,703.580322 1067.627441,703.526306 
	C1070.834717,703.501831 1073.197754,704.514221 1073.483521,708.168030 
	C1073.535645,708.834290 1073.779541,709.463257 1073.911255,710.112244 
	C1075.279297,716.856323 1074.083984,718.917725 1067.525635,721.247681 
	C1066.468750,721.623169 1065.023682,721.620911 1064.738525,722.984375 
	C1064.445435,724.385498 1065.793457,724.913269 1066.675415,725.607849 
	C1067.457642,726.223938 1068.361938,726.691406 1069.075073,727.391907 
	C1071.601440,729.873474 1071.368774,732.211182 1068.183838,733.680786 
	C1065.926392,734.722351 1063.718628,735.722717 1061.600952,737.091492 
	C1058.684570,738.976501 1056.878906,737.615662 1055.308960,732.870178 
	C1055.878418,734.773926 1053.168823,737.014771 1054.475098,737.881104 
	C1056.900269,739.489685 1059.814819,740.381653 1061.872437,742.661621 
	C1062.054443,742.863220 1062.491577,742.652100 1062.699463,742.350464 
	C1065.910034,737.691772 1068.415649,741.565491 1070.947632,743.143799 
	C1075.940918,746.256348 1076.067017,747.419739 1072.163818,752.214111 
	C1070.296143,753.218201 1070.884033,753.831665 1072.334839,754.603149 
	C1075.197144,758.222046 1072.887695,760.696411 1070.946899,763.193054 
	C1069.032349,765.655945 1066.619995,766.779907 1063.511841,765.560913 
	C1062.086792,765.001892 1060.673584,764.619141 1059.182007,765.315613 
	C1056.352295,766.636780 1053.666260,766.167236 1051.130493,764.556152 
	C1048.334839,762.780029 1046.942261,763.331665 1046.904419,766.809570 
	C1046.889160,768.209595 1045.859741,769.203369 1044.573608,770.282471 
	C1043.763428,771.007141 1042.949585,771.321167 1042.969238,772.548218 
	C1043.441040,773.730530 1043.448608,774.839905 1044.403442,775.402710 
	C1047.595703,777.284363 1046.871826,779.345947 1044.529663,781.226929 
	C1042.590942,782.784058 1042.474243,785.336670 1040.990112,787.169495 
	C1042.980103,785.447083 1045.523193,784.767639 1047.713745,783.434937 
	C1050.096558,781.985291 1052.610596,781.008545 1055.036499,783.056335 
	C1057.278931,784.949219 1058.637939,787.355957 1057.232666,790.737671 
	C1056.197754,795.499390 1060.578491,796.923401 1062.774048,798.681580 
	C1065.806519,801.110168 1065.478882,802.219177 1063.436035,804.529907 
	C1062.978271,805.047791 1063.132812,806.107056 1062.998535,807.277893 
	C1063.035278,808.760437 1062.520386,809.628296 1061.447632,810.612854 
	C1055.715210,814.722351 1050.428589,818.516174 1051.303101,826.547424 
	C1051.961182,832.591736 1050.618042,838.844238 1053.393799,844.676575 
	C1053.890625,845.720154 1053.501709,846.975220 1052.973145,848.441772 
	C1052.672363,848.994019 1052.526245,849.202332 1052.098877,849.672363 
	C1050.913208,850.438232 1050.102173,851.083191 1048.873535,851.813782 
	C1046.583252,852.550354 1044.772583,852.365784 1042.830322,850.888550 
	C1039.759277,845.577881 1039.227295,839.866272 1037.775757,834.432983 
	C1037.046387,831.703430 1036.488892,828.936035 1035.364502,826.323181 
	C1031.682007,817.765869 1025.543701,815.547302 1017.237427,819.791199 
	C1015.598999,820.628235 1013.986511,821.569397 1012.751343,822.910400 
	C1010.005371,825.891846 1007.278564,826.945862 1003.091797,825.197510 
	C998.898987,823.446594 994.622498,825.194397 990.686401,827.260681 
	C988.196838,828.567627 985.930786,830.352783 982.718262,830.979370 
	C978.356689,830.818726 974.823120,832.875183 970.602600,833.366211 
	C967.598633,832.925476 965.697693,834.689453 963.167358,835.715271 
	C960.197327,836.099854 958.158020,834.346252 955.753906,833.820923 
	C948.153809,832.160034 941.141846,834.317505 934.148315,836.823059 
	C933.072815,837.208313 932.611206,837.972900 932.414795,839.389160 
	C932.205811,840.486389 931.926575,841.172363 931.419189,842.169739 
	C930.519470,843.857727 929.472290,844.871033 927.679932,845.535034 
	C924.352600,845.762756 921.417664,845.669861 919.711548,849.046814 
	C918.768188,850.914062 916.709717,851.445251 914.263733,850.758667 
	C912.711182,849.843872 911.709412,848.709900 910.081665,847.935547 
	C908.880859,847.176025 907.952454,847.259766 906.712097,847.922241 
	C900.015991,848.676331 894.207703,850.913086 888.458130,854.309692 
	C887.897400,854.121643 887.705994,853.968445 887.326721,853.502014 
	C886.369202,850.512634 887.175659,848.110901 889.016602,846.300598 
	C892.042847,843.324646 896.117554,841.361450 897.618713,836.949646 
	C897.812866,836.378967 898.655029,835.971741 899.285217,835.959045 
	C905.560974,835.832275 911.776978,833.625000 918.000793,834.620972 
	C923.428040,835.489563 926.312805,831.118958 930.559875,829.664978 
	C931.887573,829.210449 930.754272,826.887451 929.840210,825.724670 
	C927.757690,823.075317 927.375122,819.962463 927.109924,816.795166 
	C926.984863,815.301514 926.723572,813.566223 928.354309,812.802307 
	C930.069946,811.998474 931.047852,813.537842 932.078674,814.591919 
	C933.004944,815.539185 933.618774,816.770081 934.991272,817.357300 
	C934.153809,815.331543 932.234802,814.097900 930.966187,812.388977 
	C928.886902,809.588257 928.533447,806.977234 931.557556,804.556763 
	C934.356995,802.316223 935.708618,798.075500 940.824097,799.227478 
	C944.506653,800.056824 946.529663,801.447205 946.554260,805.349915 
	C946.582581,809.844727 946.533020,814.339844 946.614502,819.501099 
	C948.316528,817.781067 949.967224,816.502075 952.172058,817.455872 
	C954.283081,818.369202 955.432922,820.106140 956.290527,822.506592 
	C956.670532,817.469177 956.063416,812.447815 960.643738,809.099121 
	C961.881592,808.194031 961.025330,806.941833 960.465332,805.955017 
	C959.389709,804.059448 958.646545,802.036743 957.904114,800.001038 
	C957.154846,797.946411 957.114075,796.110779 958.994873,794.598389 
	C959.824829,793.931030 960.006409,793.010803 960.079163,791.942383 
	C960.444824,786.574036 960.482300,786.576599 965.752197,785.813782 
	C963.683472,783.017151 964.670349,781.230408 967.591187,780.132141 
	C969.786926,779.306458 970.871338,777.555298 971.685608,775.411499 
	C973.660583,770.212097 976.251831,765.405273 981.315491,762.475525 
	C982.889343,761.564941 983.418335,760.067688 983.885559,758.398560 
	C984.949158,754.598755 987.460266,752.061035 991.271423,750.984009 
	C992.764954,750.561951 994.209167,750.261108 994.689392,748.530212 
	C996.031250,743.693542 998.755676,739.441345 1000.442810,734.719971 
	C1002.207642,729.781128 1004.919678,725.162170 1009.538208,722.180176 
	C1011.552246,720.879761 1012.150452,719.748535 1010.098816,718.010559 
	C1007.242065,715.590576 1007.443054,712.899475 1009.513184,710.080933 
	C1010.106445,709.273193 1010.989990,708.279785 1010.748840,707.421143 
	C1008.834167,700.602234 1012.916748,695.340210 1016.144836,690.544617 
	C1019.153381,686.075134 1021.154236,680.406616 1026.632446,677.751709 
	C1028.622803,676.787109 1029.941040,675.551758 1028.566406,672.635254 
	C1026.617676,668.500793 1029.886230,665.293457 1032.067505,662.349121 
	C1034.223145,659.439392 1035.891113,656.714844 1035.894653,652.931580 
	C1035.897705,649.716858 1038.400635,647.572998 1040.489014,645.456848 
	C1041.990845,643.935120 1043.151978,642.598389 1042.188232,640.273193 
	C1041.587036,638.822571 1041.867554,637.204163 1042.911499,636.025635 
	C1044.597290,634.122681 1045.156250,632.185364 1044.454590,629.643738 
	C1043.901733,627.640808 1045.379517,626.196228 1046.864258,625.051270 
	C1049.875732,622.729309 1051.295288,619.754700 1050.948608,615.934021 
	C1050.630615,612.432434 1052.223877,609.716309 1054.956177,607.820435 
	C1057.793945,605.851196 1058.249634,603.882080 1055.588135,601.078491 
	C1054.838501,599.942322 1054.457397,599.032593 1054.127319,597.699097 
	C1054.041626,596.541199 1054.232422,595.823120 1055.035400,594.988159 
	C1059.976929,596.645813 1064.385620,596.901550 1068.371338,593.218628 
	C1068.932251,592.700378 1070.339844,592.104614 1070.486938,592.278748 
	C1073.663086,596.036987 1078.660400,594.327637 1082.366943,596.339355 
	C1083.704102,597.065125 1085.140015,598.024902 1084.665527,599.769226 
	C1084.204834,601.462769 1082.665771,601.880493 1081.039795,601.482910 
	C1077.086792,600.516296 1073.077881,601.213684 1069.100830,601.004883 
	C1066.445435,600.865417 1063.550293,601.207642 1063.303467,604.310059 
	C1063.059204,607.379150 1063.947632,610.595032 1067.240845,612.184387 
	C1070.263916,613.643433 1074.201904,612.238525 1076.789429,615.532288 
	C1077.569824,616.525696 1078.979126,615.204712 1078.917114,613.832886 
	C1078.759033,610.341919 1080.697266,609.394653 1083.781250,608.908875 
	C1089.302856,608.039246 1090.680542,605.674438 1089.562866,600.144348 
	C1089.301758,598.852722 1089.059692,597.525696 1089.072998,596.217651 
	C1089.092407,594.300964 1090.002441,592.828979 1092.139526,593.303223 
	C1094.044922,593.725952 1095.681152,594.696045 1094.245850,597.184509 
	C1093.855225,597.861816 1093.409668,599.303894 1093.502075,599.349243 
	C1098.322998,601.713013 1095.549683,605.968872 1096.163574,609.382507 
	C1096.639526,612.029175 1098.490601,613.786926 1101.352539,613.947266 
	C1104.461426,614.121338 1104.961914,612.407166 1104.273193,609.768982 
	C1104.029907,608.836609 1104.813721,608.260803 1105.723877,608.108826 
	C1108.562378,607.635010 1110.432983,609.470032 1112.251587,611.114685 
	C1113.328491,612.088501 1113.085938,613.543823 1112.433838,614.831543 
	C1110.611938,618.429565 1104.542969,620.809509 1100.832031,618.786377 
	C1097.751953,617.107239 1094.409424,617.246033 1091.256348,616.296387 
	C1089.162598,615.665771 1087.970825,616.339050 1088.767822,618.833008 
	C1090.167114,623.211121 1086.894043,626.728516 1086.458130,630.794189 
	C1086.308472,632.190063 1085.178833,633.263733 1083.562256,632.017578 
	C1081.921143,630.752502 1080.759155,631.503479 1079.805786,632.866882 
	C1078.549316,634.663818 1080.127686,635.638489 1081.154419,636.593262 
	C1082.255249,637.616943 1083.613403,639.968689 1085.129761,637.963440 
	C1087.582397,634.720093 1089.687866,638.442932 1091.760986,637.769165 
	C1095.642212,636.507751 1095.877319,638.863586 1096.161621,641.617310 
	C1096.379028,643.723267 1098.244995,644.027588 1099.887817,644.289917 
	C1101.705933,644.580383 1102.068237,642.976746 1102.487549,641.806030 
	C1102.928223,640.576111 1102.815674,639.145996 1103.267212,637.922363 
	C1104.045532,635.812744 1105.709961,634.847412 1107.985352,635.049805 
	C1110.488037,635.272339 1110.346924,636.638367 1110.353638,638.843506 
	C1110.379395,647.327148 1110.591797,646.960754 1101.747803,646.407349 
	C1098.416870,646.198853 1094.817139,646.716736 1091.388184,647.209351 
	C1089.086914,647.539978 1088.198486,649.638184 1088.261475,651.718262 
	C1088.323364,653.766113 1089.600098,655.065369 1091.868530,654.991699 
	C1092.533936,654.970093 1093.227051,655.116638 1093.862915,654.979492 
	C1098.038452,654.079224 1102.331909,652.100586 1103.716553,658.974487 
	C1104.178345,661.267456 1106.572021,660.793945 1108.510986,659.167480 
	C1110.922119,657.145142 1112.472290,657.628296 1113.162231,661.146912 
	C1113.701172,663.896545 1112.938965,666.400452 1112.883667,669.015625 
	C1112.859497,670.161316 1112.079102,670.971558 1110.959595,670.880371 
	C1107.404175,670.590820 1105.457764,671.882446 1104.679932,675.600830 
	C1104.287964,677.474243 1102.706055,676.327820 1102.364868,675.183167 
	C1101.580078,672.549255 1100.644775,669.826416 1100.637329,667.137207 
	C1100.631470,665.016113 1100.761353,663.284790 1098.307007,663.174500 
	C1095.392944,663.043579 1096.188599,665.496033 1096.000244,667.591431 
	C1093.936523,670.096619 1089.995239,668.231018 1088.255371,671.773560 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1067.690674,688.139648 
	C1069.066650,688.000000 1070.133423,688.000000 1071.600098,688.000000 
	C1072.805298,688.246887 1073.610596,688.493713 1074.708008,688.870239 
	C1075.131592,689.172668 1075.263184,689.345276 1075.335449,689.831787 
	C1075.042847,690.575684 1074.526001,690.828125 1074.411133,691.280823 
	C1074.356689,691.494507 1074.818848,691.574341 1075.480225,691.277710 
	C1077.515503,691.022949 1079.067383,691.476501 1080.726807,692.757202 
	C1081.313232,693.471558 1081.626587,693.943176 1081.969971,694.707397 
	C1082.040161,696.197021 1083.218018,697.256531 1082.214844,698.776855 
	C1080.609253,701.330566 1080.129883,703.624023 1082.638428,705.905334 
	C1085.719727,711.640259 1090.464355,714.090637 1096.735840,714.775879 
	C1096.343018,718.999817 1099.769775,721.451477 1100.910156,724.804382 
	C1101.567017,726.735657 1103.430908,725.264709 1103.847900,723.621704 
	C1104.264160,721.981140 1104.809814,720.740967 1106.784546,721.204346 
	C1108.749146,721.665344 1108.840454,722.998474 1108.290161,724.647400 
	C1107.609375,726.686890 1110.673828,729.673096 1106.355225,730.946777 
	C1102.988892,729.808838 1099.684204,731.345520 1096.344971,729.999023 
	C1094.895508,729.694092 1093.790894,729.388184 1092.343262,729.041138 
	C1091.413452,728.403564 1090.978394,727.726562 1090.636108,726.948120 
	C1088.349121,721.747498 1086.420532,721.493347 1083.016357,726.113281 
	C1080.714600,729.237122 1078.592529,727.796936 1076.263428,725.964355 
	C1075.468872,729.487793 1072.875488,732.221619 1073.088623,736.212219 
	C1080.706543,735.002625 1086.891968,736.825989 1090.130615,744.673767 
	C1090.001831,748.328918 1090.007690,751.657898 1090.004395,754.986816 
	C1090.001465,757.984985 1091.173096,760.130554 1094.630615,760.949951 
	C1099.700317,765.156189 1100.580200,764.885254 1104.470337,760.833496 
	C1106.562012,758.654907 1104.020264,757.257996 1104.778809,755.272034 
	C1105.918335,753.559875 1110.220093,752.348633 1105.112549,750.530334 
	C1103.576904,749.983643 1103.149048,740.590454 1104.750732,739.266602 
	C1107.191040,737.249573 1108.570679,733.484741 1112.514404,733.707581 
	C1116.379150,733.926025 1119.773682,732.956238 1123.164429,731.064270 
	C1125.191284,729.933289 1126.926758,729.454590 1126.400635,726.491760 
	C1125.761353,722.891357 1128.161377,722.834473 1130.781128,723.423828 
	C1133.756470,724.093140 1136.300537,726.223267 1139.809937,725.227112 
	C1141.815674,724.657837 1142.602783,726.792480 1141.165039,728.033386 
	C1136.605713,731.968689 1132.293213,736.914551 1125.294800,735.530457 
	C1122.374878,734.952942 1122.151123,735.494812 1122.981567,738.165466 
	C1123.855835,740.977417 1124.006470,743.852356 1125.735596,746.576233 
	C1127.122559,748.761230 1127.590698,749.166626 1128.925293,747.048035 
	C1130.361328,744.768738 1131.804443,743.442444 1134.459961,745.535828 
	C1135.404663,746.280579 1137.043945,746.290833 1137.615356,745.179932 
	C1140.517822,739.537476 1146.126587,743.212646 1150.149780,741.450073 
	C1150.972778,741.089600 1152.557129,741.911072 1152.641724,743.330139 
	C1152.743774,745.042053 1151.276245,744.954285 1150.157593,744.975891 
	C1148.220581,745.013489 1146.831421,745.558960 1147.093628,747.839050 
	C1147.384155,750.363464 1149.114624,750.125122 1150.794189,749.545410 
	C1152.976929,748.791992 1153.622559,749.635071 1154.104126,751.741943 
	C1154.899292,755.221130 1157.031494,756.573853 1160.781250,755.355286 
	C1162.661377,754.744202 1165.341675,753.916138 1166.614624,756.313293 
	C1167.877686,758.691711 1165.078491,759.583740 1164.025146,761.120422 
	C1162.506348,763.335999 1158.517334,763.257080 1158.628052,766.960876 
	C1153.558472,764.197388 1151.568604,776.028442 1145.823486,768.506165 
	C1145.401733,771.900146 1149.961060,772.081970 1148.018433,774.704590 
	C1147.116211,775.922485 1145.195923,777.157166 1143.632202,776.493103 
	C1141.130005,775.430664 1138.563965,774.056396 1136.652710,772.175903 
	C1133.980103,769.546265 1131.586304,769.332642 1128.781982,771.536377 
	C1126.559692,773.282532 1124.558716,773.663452 1121.585083,772.544006 
	C1115.153442,770.122620 1111.456665,772.046875 1111.000122,777.579224 
	C1109.380615,779.167725 1108.525146,778.063232 1107.995117,776.806030 
	C1106.926880,774.272339 1104.934692,774.204346 1102.619751,774.518127 
	C1108.233643,776.374268 1108.741821,777.330872 1107.227661,783.741394 
	C1104.656006,784.483337 1102.436523,785.138367 1101.970703,787.981262 
	C1101.691772,789.684448 1100.553711,790.031494 1099.070923,789.910522 
	C1097.286499,789.764832 1096.991821,788.505676 1097.022705,787.092468 
	C1097.070190,784.918701 1096.311768,784.456116 1094.023682,784.675110 
	C1090.323608,785.029419 1087.268066,782.341125 1083.668213,781.657837 
	C1077.958008,780.574097 1075.038330,782.596985 1075.010620,788.278503 
	C1075.000366,790.367188 1075.964111,793.536072 1074.980103,794.316467 
	C1073.045288,795.850891 1072.255859,791.345276 1069.371460,792.009277 
	C1067.952881,791.791382 1067.138306,791.200928 1066.215332,790.217468 
	C1064.692383,788.881958 1063.498047,786.857056 1061.729614,789.477173 
	C1060.765991,790.904846 1059.295410,791.028809 1057.384155,790.998535 
	C1056.572632,789.232788 1056.337646,787.388306 1055.666260,785.719238 
	C1054.933716,783.898438 1052.908081,782.116089 1051.425293,783.291443 
	C1048.275635,785.788025 1043.250977,785.411438 1041.479370,789.890381 
	C1041.131592,790.769714 1040.623413,791.934875 1039.335938,791.339172 
	C1037.895874,790.672913 1038.081299,789.195007 1038.830078,788.364563 
	C1041.258179,785.671814 1039.969971,780.679565 1044.716553,779.336426 
	C1046.162842,778.927185 1045.772461,777.478699 1044.223389,776.938416 
	C1042.216553,776.238525 1041.678345,774.461243 1041.046387,772.357056 
	C1041.542480,771.475830 1042.065308,770.931946 1042.797607,770.197571 
	C1044.407227,768.534851 1046.818848,767.583862 1045.449341,764.758118 
	C1044.940063,763.707458 1044.692017,762.408386 1045.789307,761.553467 
	C1047.011230,760.601501 1048.382202,761.093872 1049.511719,761.812866 
	C1053.036011,764.055847 1056.421753,766.908630 1060.432983,762.539368 
	C1061.031616,761.887329 1062.015747,762.579468 1062.616211,763.167908 
	C1064.320068,764.837769 1066.545410,766.060181 1068.215698,763.976013 
	C1070.090088,761.637207 1073.796631,759.808716 1072.041260,755.344971 
	C1071.332764,754.337524 1070.625732,753.694763 1070.840332,752.314270 
	C1073.051758,749.097290 1076.815186,745.919189 1069.677002,744.590759 
	C1069.018433,744.468140 1068.453003,743.539734 1067.961548,742.896057 
	C1067.235840,741.945740 1066.553833,740.918945 1065.158691,741.364502 
	C1063.955444,741.748779 1064.096313,742.895081 1064.004395,743.868896 
	C1063.898438,744.992065 1064.162598,746.482422 1062.606445,746.657959 
	C1061.040283,746.834656 1060.994141,745.231079 1060.592163,744.226379 
	C1059.277222,740.940369 1056.663696,739.958740 1053.412231,739.971252 
	C1052.206421,739.975891 1050.888184,739.774719 1050.578125,738.444336 
	C1050.269897,737.120789 1051.751709,737.131348 1052.494629,736.586487 
	C1054.668945,734.991455 1054.014526,732.765137 1053.714233,730.652832 
	C1053.579590,729.705322 1052.951416,728.586853 1054.463623,728.297791 
	C1055.601807,728.080261 1056.369263,728.842102 1056.683105,729.863342 
	C1056.921753,730.639771 1056.947510,731.493469 1056.988037,732.316650 
	C1057.068970,733.964966 1056.765015,735.894958 1058.715210,736.609985 
	C1060.875000,737.401917 1061.480103,735.563782 1062.311401,734.094116 
	C1063.232422,732.465515 1065.001343,733.226746 1066.392578,732.915527 
	C1067.696411,732.623840 1069.155884,732.374390 1069.619019,730.941223 
	C1070.186035,729.186096 1068.415894,728.793457 1067.467529,728.024475 
	C1066.575073,727.300781 1065.518677,726.756348 1064.481201,726.239807 
	C1062.831787,725.418274 1061.772583,724.286316 1062.176025,722.313599 
	C1062.548462,720.492004 1063.987427,720.286926 1065.538208,719.960571 
	C1072.749268,718.443542 1075.649536,712.073242 1071.576782,706.223206 
	C1069.934326,703.864197 1066.940918,704.771790 1064.670044,705.446594 
	C1062.160889,706.192261 1059.866455,707.652588 1057.467285,708.777832 
	C1054.681274,710.084656 1053.391846,712.176270 1054.253540,715.230164 
	C1055.173706,718.491455 1053.905029,719.611755 1050.668945,720.087463 
	C1043.342041,721.164673 1043.391235,721.331970 1041.843506,713.250305 
	C1041.536621,711.647827 1040.687134,710.164124 1041.951538,708.535522 
	C1043.813110,706.137817 1046.358887,703.666870 1040.992920,702.027954 
	C1039.548462,701.586792 1039.720337,699.365173 1040.688721,698.051758 
	C1041.896606,696.413696 1042.446289,693.760376 1045.290527,694.157349 
	C1047.276245,694.434570 1046.729248,695.939392 1046.515381,697.272644 
	C1046.284546,698.712952 1047.326172,698.973755 1048.387085,698.829895 
	C1049.623047,698.662292 1050.058838,697.707275 1049.670776,696.625061 
	C1048.862427,694.370422 1049.947510,692.497009 1051.811035,692.413452 
	C1055.944092,692.228210 1058.686157,689.224731 1062.641846,688.046265 
	C1064.787109,690.554688 1064.787109,690.554688 1062.252441,694.312195 
	C1065.108887,693.003052 1064.420410,689.074890 1067.690674,688.139648 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1259.245239,848.662476 
	C1257.933350,849.000000 1256.866577,849.000000 1255.399902,849.000000 
	C1254.868652,848.908081 1254.689453,848.845520 1254.612915,848.720093 
	C1251.065552,842.916748 1249.084351,844.215698 1246.239746,848.752319 
	C1243.988892,849.166992 1243.040405,850.301697 1243.007812,852.638672 
	C1241.782593,854.557495 1239.750732,854.244202 1237.870605,855.246033 
	C1238.925781,856.690613 1240.970093,856.858704 1241.882568,858.686279 
	C1242.000000,859.444458 1242.000000,859.888916 1242.000000,860.666687 
	C1237.700073,862.350464 1233.673828,863.200745 1231.182495,857.832642 
	C1230.442383,856.237732 1228.437378,855.072815 1226.573608,855.309448 
	C1224.780884,855.537109 1222.908569,856.966187 1222.685547,858.687439 
	C1222.445068,860.543396 1224.663818,859.781433 1225.801758,860.130920 
	C1227.951172,860.791199 1229.539062,861.806885 1228.674072,864.395081 
	C1227.943115,866.582214 1226.795166,868.483826 1224.017090,867.789185 
	C1220.494019,866.908325 1217.803223,866.365723 1220.461792,871.537842 
	C1221.203613,872.981079 1219.460083,874.413818 1218.209106,873.291748 
	C1215.248535,870.636169 1211.917236,869.875916 1208.141235,869.753235 
	C1206.641968,869.704529 1206.082031,868.251953 1206.012939,866.394775 
	C1208.604614,863.385193 1213.276367,867.022522 1215.171509,862.841736 
	C1210.324951,861.541931 1210.324951,861.541931 1206.246704,865.755432 
	C1201.486450,863.707886 1196.545776,863.407654 1191.645874,863.033569 
	C1189.650757,862.881226 1187.897461,862.751404 1188.049561,860.143860 
	C1188.096069,859.344543 1188.095337,858.256714 1188.581177,857.829163 
	C1189.550903,856.975464 1190.352173,857.983032 1191.093628,858.609558 
	C1191.850464,859.249084 1192.515503,860.068420 1193.375610,860.500427 
	C1195.321655,861.477783 1196.620483,860.232178 1197.425537,858.730347 
	C1198.524292,856.680908 1196.961304,856.372009 1195.389404,855.857056 
	C1192.845825,855.023743 1190.401855,854.590210 1190.361572,850.486084 
	C1190.325562,846.827332 1186.286499,848.330444 1183.425415,847.998535 
	C1183.656982,845.587219 1184.151733,843.174988 1184.024536,840.633728 
	C1183.619385,832.541260 1190.092529,828.261719 1196.464600,827.074829 
	C1199.357056,826.536011 1200.693237,828.201416 1202.043457,831.527283 
	C1202.864746,828.879578 1203.543701,827.266541 1203.831055,825.586487 
	C1204.069092,824.193909 1204.605225,822.429077 1203.024658,821.487488 
	C1201.549927,820.609131 1200.596802,822.023560 1199.618164,822.848877 
	C1197.205811,824.883606 1195.477905,823.819763 1193.865967,820.973206 
	C1192.826538,823.561157 1192.375854,825.932312 1189.708984,826.620483 
	C1187.873413,827.094238 1186.308960,826.488770 1185.005859,825.599060 
	C1183.573486,824.621033 1185.235229,823.631714 1185.539429,822.632751 
	C1186.700195,818.821960 1184.393921,813.268372 1180.868164,810.873901 
	C1179.509399,809.950989 1178.323120,808.745667 1177.162354,807.566528 
	C1175.858643,806.242004 1175.467163,804.424988 1176.465820,802.983582 
	C1177.588867,801.362732 1179.411011,801.902710 1180.981201,802.671204 
	C1182.226562,803.280823 1183.672485,804.945862 1184.657593,802.727295 
	C1185.513184,800.800354 1184.004639,800.249084 1182.294067,799.945312 
	C1179.132202,799.384033 1179.440186,795.213196 1175.613403,794.345947 
	C1178.141846,792.776855 1180.297607,791.889709 1179.475342,788.852173 
	C1179.031738,787.213013 1180.791992,785.938599 1182.484131,785.363098 
	C1184.240723,784.765625 1185.975708,784.902466 1186.707397,786.800476 
	C1187.699707,789.374817 1189.367798,789.330627 1190.887939,787.827820 
	C1193.224609,785.517700 1190.628784,785.330933 1189.227417,784.738892 
	C1188.327271,784.358643 1188.270752,783.526978 1188.015869,782.729004 
	C1186.348755,777.511292 1185.806763,777.159973 1181.809448,780.956055 
	C1178.862061,783.755005 1176.201172,784.063232 1172.391846,783.231689 
	C1167.973022,782.267029 1167.709351,780.099304 1168.993164,776.330811 
	C1167.878174,774.702209 1167.578857,773.298096 1168.724854,771.371338 
	C1168.835205,769.832642 1168.670410,768.665283 1168.252808,767.248962 
	C1167.574829,766.598511 1167.211670,766.155823 1167.026978,765.288330 
	C1167.825195,762.198853 1168.993652,763.274292 1169.782227,764.900757 
	C1171.875122,769.217834 1175.448242,769.431152 1179.451782,768.675781 
	C1184.015259,767.814880 1187.857788,768.429993 1190.272217,773.106506 
	C1191.666382,775.806885 1198.156372,777.403625 1201.064331,775.636292 
	C1203.982422,773.862854 1204.515503,770.964294 1202.935181,767.882019 
	C1201.042969,764.192139 1205.069580,762.758301 1205.997070,760.135498 
	C1206.885986,757.622070 1201.393066,755.021118 1206.715210,753.028137 
	C1207.244873,752.853577 1207.430664,752.727722 1207.952515,752.619507 
	C1209.288574,753.125793 1210.422974,753.197754 1211.717163,753.825745 
	C1213.791626,756.030396 1214.729858,759.052246 1218.196289,759.117676 
	C1219.377930,759.139954 1220.135742,760.111084 1219.848755,761.154663 
	C1218.655029,765.495789 1221.177246,768.765076 1222.844116,772.284607 
	C1223.188110,773.010681 1224.001343,773.790649 1223.148804,774.428650 
	C1222.596924,774.841614 1221.515747,774.955078 1220.840088,774.719666 
	C1218.696655,773.972717 1216.281128,774.027100 1214.505615,772.130310 
	C1212.665405,770.164429 1211.409180,771.566467 1210.180786,773.217163 
	C1208.751343,775.137817 1208.886230,776.486938 1211.103149,777.555359 
	C1213.105957,778.520752 1214.838745,779.701294 1214.989014,782.266541 
	C1215.128906,784.651855 1214.528687,788.624939 1217.366699,788.400391 
	C1220.070312,788.186462 1224.040405,788.036194 1225.796631,784.486755 
	C1226.442017,783.182373 1227.187622,781.473877 1229.095825,782.922058 
	C1230.184692,783.748413 1230.077148,784.964294 1229.231689,786.009155 
	C1228.928833,786.383545 1228.461670,786.623657 1228.074951,786.931946 
	C1227.118652,787.694641 1225.163696,787.749695 1225.603027,789.528442 
	C1225.988403,791.088684 1227.750732,790.734680 1228.944458,791.191650 
	C1230.455200,791.769958 1231.849976,791.634888 1233.642090,791.045654 
	C1233.199585,795.257385 1234.656128,798.747925 1237.675293,802.065186 
	C1237.676270,802.642883 1237.644043,802.874207 1237.491089,803.434570 
	C1236.254761,806.688843 1235.436035,809.626343 1236.826172,812.829956 
	C1238.306519,812.026794 1237.635498,810.652466 1238.103149,809.686951 
	C1239.727783,806.333252 1243.433472,805.905518 1245.617920,808.954041 
	C1246.659912,810.408203 1246.978516,812.340759 1248.752075,813.753296 
	C1249.000000,814.000000 1249.000000,814.500000 1249.000000,814.750000 
	C1250.138306,816.860901 1249.909912,820.015503 1253.700439,819.824829 
	C1255.362183,821.167053 1254.901855,822.764832 1255.000000,824.615356 
	C1255.042236,826.863159 1252.861084,827.339417 1252.476807,828.967346 
	C1253.350342,830.376465 1255.363281,830.599976 1255.937500,832.661133 
	C1256.000000,833.444458 1256.000000,833.888916 1256.000000,834.666687 
	C1256.789673,836.057983 1256.430664,837.615906 1257.769287,838.767700 
	C1258.000000,839.000000 1258.000000,839.500000 1258.000000,839.750000 
	C1258.604370,840.678833 1258.575928,841.582458 1258.922852,842.690063 
	C1259.000000,843.750000 1259.000000,844.500000 1259.000000,845.625000 
	C1259.601685,846.682556 1259.761353,847.458313 1259.245239,848.662476 
z"/>
<path fill="#1E293E" opacity="1.000000" stroke="none" 
	d="
M1154.765503,784.236206 
	C1156.490845,782.595764 1158.463745,782.876831 1160.167358,782.300781 
	C1162.727783,781.434937 1164.233032,782.498901 1164.969727,785.054504 
	C1169.911133,802.194092 1174.953369,819.304993 1179.804443,836.469971 
	C1180.715576,839.693909 1180.255005,843.067566 1178.846924,846.610229 
	C1176.813477,848.155334 1176.405029,850.412170 1174.625000,852.075073 
	C1174.195679,852.513855 1174.025757,852.696716 1173.561523,853.122803 
	C1164.144409,860.031311 1154.230591,863.164673 1142.938721,860.172485 
	C1141.657349,859.832947 1140.358276,859.536194 1139.189453,858.892395 
	C1137.843994,858.151367 1136.694458,857.177795 1136.534790,855.532471 
	C1136.348267,853.612244 1137.857178,853.024780 1139.240112,852.476807 
	C1140.477051,851.986694 1141.651489,851.392090 1143.177490,850.590576 
	C1144.045776,850.257812 1144.562622,850.174622 1145.472412,850.223755 
	C1151.850464,852.603699 1157.043213,850.663757 1162.249756,847.015137 
	C1164.502319,845.242798 1166.372437,843.588318 1167.376099,841.232117 
	C1168.504883,838.582092 1168.192017,835.958252 1166.498413,833.695618 
	C1164.806274,831.435120 1162.573975,832.765808 1160.577148,833.286987 
	C1158.337158,833.871521 1156.129761,834.564880 1153.845337,834.989502 
	C1137.428467,838.041443 1123.468872,826.697571 1123.092651,809.543213 
	C1123.995483,807.662720 1124.776367,806.137085 1124.504883,803.947510 
	C1124.297485,802.265259 1124.512085,801.003418 1125.038574,799.398621 
	C1125.748169,798.027771 1126.531250,797.171082 1127.415283,796.476746 
	C1130.370117,794.155518 1132.571167,795.053894 1133.275391,798.728394 
	C1133.620117,800.527527 1132.548706,802.762512 1135.090820,804.271118 
	C1135.670288,804.944824 1135.914917,805.384277 1136.199219,806.212219 
	C1136.751953,809.962219 1137.061035,813.421936 1139.299316,816.241394 
	C1141.257324,818.707764 1143.389404,820.967224 1147.342773,820.527710 
	C1148.160645,820.516296 1148.627441,820.521606 1149.442627,820.536804 
	C1150.253418,820.540588 1150.715698,820.541504 1151.521973,820.533569 
	C1152.795654,820.524109 1153.669189,820.230896 1154.893311,819.907959 
	C1155.694580,819.702087 1156.163452,819.623901 1156.995117,819.518066 
	C1160.045410,819.002136 1159.442139,816.878845 1159.480835,815.140015 
	C1159.675903,806.342712 1157.296997,798.082642 1154.026855,789.614746 
	C1153.756104,788.723755 1153.675537,788.195923 1153.656006,787.249390 
	C1153.718750,785.996277 1154.003296,785.236328 1154.765503,784.236206 
z"/>
<path fill="#0C161B" opacity="1.000000" stroke="none" 
	d="
M1055.370117,594.421875 
	C1055.067261,595.135925 1054.781738,595.491150 1054.481689,596.083313 
	C1054.759277,597.593445 1053.897583,598.088806 1052.601562,598.607971 
	C1051.985107,598.640747 1051.737793,598.605835 1051.165283,598.390015 
	C1050.713623,597.998962 1050.586914,597.788818 1050.384155,597.222473 
	C1050.234009,593.215088 1049.994019,589.556458 1049.404419,585.970703 
	C1048.594604,581.044006 1045.530151,579.597046 1041.046997,581.856201 
	C1037.393799,583.697266 1034.686157,586.668640 1032.385010,589.974915 
	C1030.847168,592.184387 1029.445801,594.447205 1026.590454,595.157532 
	C1025.742432,595.368530 1024.833984,595.921570 1024.816406,596.731506 
	C1024.705566,601.839355 1019.551575,603.763916 1017.882996,607.931213 
	C1016.883728,610.427063 1014.769775,609.907104 1012.662476,608.296631 
	C1011.295471,605.301880 1012.739746,603.228577 1014.362366,600.880005 
	C1015.324585,599.859741 1016.425476,599.384094 1016.522339,597.796997 
	C1016.687500,596.122925 1017.124084,594.858398 1017.750732,593.296265 
	C1018.055664,592.567749 1018.263428,592.168335 1018.635803,591.475952 
	C1019.674683,589.642273 1020.376404,587.990112 1021.838989,586.445312 
	C1023.237610,585.510254 1023.672729,584.399353 1023.847046,582.786011 
	C1024.418213,580.724670 1025.492065,579.188354 1025.839600,577.010986 
	C1026.223389,575.716309 1026.567261,574.787537 1027.043823,573.491333 
	C1035.986572,556.975647 1039.751343,539.604065 1039.891479,520.931885 
	C1040.208252,520.322144 1040.372681,520.098633 1040.901611,519.674316 
	C1047.480103,537.745422 1053.671021,556.025208 1059.930542,574.281433 
	C1060.973267,577.322876 1059.942505,580.173523 1059.397583,583.471069 
	C1059.071045,584.520691 1058.845581,585.169495 1058.405029,586.099304 
	C1058.339600,586.438477 1058.173218,586.817688 1057.980347,586.892944 
	C1057.374756,588.224426 1057.280029,589.592163 1056.367554,591.026367 
	C1055.969238,592.248901 1055.678345,593.156006 1055.370117,594.421875 
z"/>
<path fill="#AAE4EE" opacity="1.000000" stroke="none" 
	d="
M956.709290,386.844849 
	C959.711304,391.161530 964.682861,392.065979 968.594116,395.078064 
	C975.795715,398.967499 981.846191,404.157959 989.614014,407.079102 
	C993.303955,406.424408 995.481018,408.288300 997.742554,410.777222 
	C1004.722290,415.260986 1010.567566,420.792969 1012.282959,428.481659 
	C1013.828125,435.407104 1016.856995,441.589447 1019.167603,448.111450 
	C1023.091858,459.188141 1028.021606,469.951324 1031.287720,481.206390 
	C1034.523438,492.357208 1040.012329,502.911926 1040.590454,515.197205 
	C1036.412354,514.010376 1036.607178,509.503601 1034.191895,506.326935 
	C1031.131104,495.583801 1027.420654,485.456573 1023.759644,475.304443 
	C1018.012817,459.368622 1011.968262,443.535309 1006.703369,427.431885 
	C1005.218140,422.888947 1002.805481,419.698181 998.526062,417.365021 
	C965.513367,399.366333 933.357605,379.855347 900.476746,361.619751 
	C898.871948,360.729706 897.190002,360.008240 895.230957,359.008179 
	C886.676086,352.636108 877.446594,348.045074 868.687500,342.712708 
	C857.248291,335.748688 845.638367,329.037720 833.584351,322.428162 
	C832.639587,322.024017 832.058289,321.670990 831.167847,321.199219 
	C830.301819,320.746002 829.752014,320.401581 828.909119,319.925385 
	C827.739136,319.203583 826.891724,318.568878 825.662842,317.936432 
	C823.804932,317.008820 822.521667,315.738892 820.538574,314.985657 
	C809.601196,309.139130 799.497498,302.564026 788.886108,296.114075 
	C787.713806,295.335968 787.001404,294.632843 786.720825,293.218018 
	C788.151733,290.395233 790.334106,291.363373 792.722656,291.580078 
	C793.618469,291.683777 794.110413,291.820129 794.918579,292.196960 
	C804.687683,300.164734 815.667847,305.379547 826.208191,311.399414 
	C838.904724,318.650696 851.709900,325.767456 863.993103,333.743256 
	C870.397278,337.901672 877.997131,340.058044 883.991943,345.609924 
	C885.650269,346.736176 887.232544,347.231903 888.965820,348.196167 
	C891.511963,351.268402 895.290894,351.333923 898.444946,353.241577 
	C899.706421,353.416138 900.593567,353.673340 901.778320,354.150726 
	C906.067993,357.298553 909.863892,360.612396 915.316528,361.657043 
	C915.872742,361.781403 916.091125,361.847992 916.621521,362.054749 
	C917.742310,362.599396 918.314453,363.353485 919.461914,363.865082 
	C919.952026,364.159302 920.141235,364.290649 920.587830,364.655090 
	C923.386108,368.598206 927.726624,369.712616 931.291626,371.876160 
	C933.786987,373.390503 936.334351,374.823517 939.049194,376.628632 
	C940.817139,377.408813 942.171387,378.255127 943.963379,378.986816 
	C947.749756,380.848938 951.050659,382.852875 954.463501,385.316528 
	C955.456055,385.663971 956.043640,386.020142 956.709290,386.844849 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M1034.631470,505.229858 
	C1038.543823,507.656891 1038.096436,511.995697 1040.229980,515.351685 
	C1040.744629,516.688782 1041.027954,517.754150 1041.288818,519.146606 
	C1041.266235,519.473572 1041.270020,519.805298 1041.144775,519.914124 
	C1040.292969,520.713867 1039.620850,521.488281 1038.175049,521.633301 
	C1035.149292,520.845642 1034.971802,518.197876 1033.616699,515.907410 
	C1032.016846,518.633667 1029.719360,519.430115 1026.864746,518.676941 
	C1024.978149,518.179199 1023.313110,518.968506 1021.572510,519.565430 
	C1015.556030,521.628906 1009.103638,519.842529 1005.868958,514.507324 
	C1002.727051,509.325043 997.708496,510.288422 992.839783,509.283539 
	C990.961060,503.801239 991.135681,498.319824 990.629761,492.928162 
	C990.473083,491.258301 991.185364,489.612091 992.865967,488.780548 
	C994.834717,487.806458 996.685364,488.693939 997.801941,490.070160 
	C1002.770020,496.193634 1010.532471,497.519012 1016.805420,501.376404 
	C1018.613281,502.488068 1020.960083,503.962036 1023.364502,501.240601 
	C1028.318726,499.324463 1031.042847,502.958252 1034.631470,505.229858 
z"/>
<path fill="#212C3D" opacity="1.000000" stroke="none" 
	d="
M1064.638184,808.120117 
	C1070.749390,803.184326 1073.971558,803.805969 1076.666748,810.054810 
	C1076.959961,810.734619 1077.315674,811.165222 1078.478027,810.800659 
	C1081.292725,813.048828 1079.786499,815.946838 1079.663574,818.555969 
	C1079.538696,821.208374 1079.860229,823.394897 1082.336304,825.343323 
	C1085.979004,835.119019 1088.465332,844.833435 1090.253662,855.151062 
	C1090.211670,856.433960 1089.956299,857.232178 1089.235107,858.300537 
	C1088.330444,859.232483 1087.559814,859.590820 1086.327881,859.951111 
	C1085.343628,860.060486 1084.784546,860.010010 1083.840576,859.739929 
	C1077.130737,857.961792 1074.839355,853.232056 1073.242188,847.396729 
	C1069.731934,834.570679 1067.875000,821.398865 1064.638184,808.120117 
z"/>
<path fill="#212C3D" opacity="1.000000" stroke="none" 
	d="
M1108.452271,801.791626 
	C1110.837158,803.697571 1111.562866,806.174866 1112.860352,808.701050 
	C1116.213745,812.452209 1116.437378,816.971252 1117.448730,821.625732 
	C1118.378418,828.297302 1120.735229,834.147095 1122.606812,840.129395 
	C1124.416992,845.915649 1123.458862,848.262512 1117.824463,850.169678 
	C1113.196533,849.850220 1111.130981,847.346985 1109.779053,843.474121 
	C1107.192505,836.063599 1105.406006,828.460510 1103.557373,820.861328 
	C1102.402344,816.114258 1100.787231,811.600769 1096.518311,808.100952 
	C1095.712402,807.412781 1095.243042,806.924866 1094.612549,806.708862 
	C1092.384399,805.945312 1089.989624,805.197388 1090.350098,802.190430 
	C1090.681519,799.425049 1093.376465,799.371277 1095.272583,798.835449 
	C1099.941406,797.515930 1104.358154,798.189453 1108.452271,801.791626 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1090.351074,745.051392 
	C1088.562500,743.408386 1087.338135,741.526855 1085.645752,740.281738 
	C1081.293701,737.079895 1076.655151,735.239746 1071.629639,739.769165 
	C1070.305054,735.843140 1071.517822,732.705688 1072.944702,729.838074 
	C1073.754395,728.210632 1075.082397,727.032898 1074.250610,724.958130 
	C1073.857666,723.977966 1074.401489,722.910645 1075.474976,722.564331 
	C1076.975952,722.079895 1077.580200,723.168823 1077.869995,724.364197 
	C1078.199829,725.724792 1078.496582,727.030334 1080.323364,726.815308 
	C1081.263428,726.704651 1081.880005,726.062195 1081.886353,725.172729 
	C1081.915894,721.092224 1084.476929,720.618225 1087.626953,721.144653 
	C1090.982666,721.705383 1092.415527,723.797058 1092.005005,727.168579 
	C1091.965210,727.495239 1091.999878,727.830933 1092.000000,728.581238 
	C1089.950195,729.325073 1089.802856,730.905334 1090.055298,732.396057 
	C1090.959961,737.738525 1092.442871,742.789978 1097.850586,745.487061 
	C1098.700684,745.911011 1100.009155,746.376282 1099.391602,747.567444 
	C1098.815308,748.678955 1097.773071,749.640381 1096.737305,750.395447 
	C1095.754517,751.112061 1094.925171,750.267883 1094.158936,749.602051 
	C1092.697876,748.332581 1093.236450,745.535889 1090.351074,745.051392 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M1064.366699,807.660522 
	C1068.905151,812.308472 1068.724243,818.463257 1069.752808,823.978577 
	C1071.452148,833.090393 1073.922485,841.982910 1076.003906,850.983215 
	C1076.992676,855.259216 1080.427612,856.574402 1083.868164,858.464600 
	C1083.973022,859.330872 1083.820435,859.800964 1083.220459,860.475464 
	C1082.499268,861.008179 1082.029541,861.208923 1081.150635,861.417969 
	C1079.017334,862.106323 1077.239624,862.521057 1074.983032,861.992676 
	C1073.291260,861.230347 1072.530762,860.122253 1071.859009,858.445557 
	C1071.810303,854.673828 1070.645996,851.516907 1069.508301,848.467041 
	C1065.572876,837.916870 1063.223755,826.960815 1060.520508,815.685791 
	C1060.071289,814.838074 1059.905273,814.315308 1059.773682,813.354126 
	C1059.748291,812.071106 1059.993164,811.287720 1060.855225,810.325684 
	C1061.670654,809.414062 1062.184692,808.791565 1062.844971,807.903870 
	C1063.359497,807.627991 1063.727417,807.617371 1064.366699,807.660522 
z"/>
<path fill="#192334" opacity="1.000000" stroke="none" 
	d="
M1108.720703,801.719971 
	C1104.152466,800.362122 1099.580078,798.170410 1094.556030,800.664185 
	C1093.365967,801.254883 1091.124390,800.575989 1091.334717,802.888000 
	C1091.510010,804.812866 1093.224487,804.926331 1094.754272,805.017639 
	C1095.626587,805.069763 1096.373779,805.325012 1096.937256,806.485596 
	C1096.234375,808.371277 1094.653687,808.279602 1093.266479,808.564392 
	C1089.327515,809.373230 1086.456299,811.398804 1085.307617,815.764221 
	C1085.319946,816.552185 1085.307861,817.002136 1085.244751,817.787720 
	C1085.086304,818.781921 1084.843506,819.393799 1084.301636,820.211975 
	C1083.711182,820.898438 1083.241089,821.263550 1082.709473,821.936279 
	C1082.510498,822.548340 1082.467896,822.917297 1082.479004,823.580444 
	C1082.557617,824.271484 1082.577393,824.674622 1082.511108,825.397583 
	C1082.342773,825.904968 1082.230957,826.094299 1081.822754,826.138977 
	C1077.777832,825.440308 1078.425415,822.317932 1078.022705,819.877197 
	C1077.554321,817.038940 1078.524658,814.313049 1078.873535,811.105835 
	C1080.685791,801.515686 1096.412354,795.390442 1103.503052,797.750366 
	C1105.648804,798.464600 1107.343262,799.609375 1108.720703,801.719971 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1193.093018,737.665283 
	C1190.656372,740.012146 1190.167847,742.228271 1192.862427,744.173279 
	C1194.980469,745.701904 1194.862305,746.936157 1193.056030,748.442444 
	C1191.543823,749.703613 1190.920288,751.320862 1190.911865,753.279236 
	C1190.907349,754.316467 1190.416992,755.283630 1189.292847,755.519531 
	C1188.104126,755.768982 1187.525879,754.844177 1186.996948,753.981506 
	C1185.149902,750.969055 1183.046021,747.955261 1186.840088,744.845764 
	C1187.634644,744.194397 1187.728760,743.486145 1186.955566,742.780762 
	C1185.983887,741.894165 1184.685669,741.706238 1183.705811,742.356689 
	C1180.086792,744.759094 1178.763306,743.200989 1179.046143,739.595886 
	C1179.285156,736.551697 1174.227539,736.955811 1175.420898,734.464844 
	C1176.856445,731.468079 1179.237549,736.006531 1181.432251,734.636902 
	C1183.578369,733.297546 1182.464722,730.889954 1183.458984,729.270447 
	C1184.438965,727.674316 1182.852051,724.559753 1185.686401,724.375061 
	C1188.054565,724.220886 1190.049072,725.797791 1190.951172,728.645630 
	C1190.271729,730.581055 1187.331055,729.614075 1187.398926,731.823669 
	C1187.469604,734.127563 1189.383423,735.077637 1191.329102,735.787476 
	C1192.120850,736.076294 1192.807617,736.525085 1193.093018,737.665283 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1127.791504,384.733582 
	C1128.000000,385.444458 1128.000000,385.888885 1128.000000,386.666687 
	C1127.944458,388.173553 1128.156006,389.387726 1127.236328,390.732300 
	C1125.213013,392.742035 1123.948975,391.684418 1123.081543,390.074829 
	C1120.324829,384.959869 1113.381104,385.496124 1110.396362,380.494415 
	C1109.571655,379.112457 1107.315674,380.487396 1105.373291,380.947632 
	C1105.583496,377.152069 1103.647339,373.015198 1106.667236,369.204773 
	C1107.838013,367.882904 1108.950317,367.449554 1110.661377,367.832092 
	C1111.000000,368.000000 1111.500000,368.000000 1111.750000,368.000000 
	C1113.114868,368.709808 1112.701660,371.447906 1115.485596,370.415558 
	C1117.227417,371.502808 1118.933472,371.895691 1120.761475,372.775208 
	C1122.104614,373.856049 1121.980225,375.087097 1121.999512,376.637207 
	C1121.353638,378.743042 1118.108154,380.011047 1121.332642,382.450073 
	C1122.307373,382.525848 1122.956055,382.467804 1123.936646,382.361084 
	C1125.683716,382.576508 1126.834839,383.205811 1127.791504,384.733582 
z"/>
<path fill="#192334" opacity="1.000000" stroke="none" 
	d="
M1134.911621,804.950989 
	C1132.186035,804.912415 1130.697998,804.048523 1131.368774,801.369568 
	C1131.795410,799.666504 1132.921265,797.620483 1130.995728,796.500427 
	C1128.826538,795.238525 1128.376831,798.422607 1126.379639,798.939575 
	C1125.093262,798.536621 1124.747192,797.653625 1124.430420,796.363586 
	C1124.464111,794.051025 1125.787720,793.128174 1127.603760,792.159058 
	C1131.703491,788.678833 1136.284668,787.113342 1141.547607,786.160034 
	C1145.207642,791.205811 1145.289429,791.219910 1148.734985,787.092896 
	C1149.453003,786.941101 1149.900269,786.991638 1150.663330,787.165833 
	C1153.316162,788.721924 1154.394165,790.714844 1153.553711,793.821045 
	C1150.466064,796.435608 1147.037109,795.331543 1143.828857,795.623535 
	C1141.221313,795.860901 1138.395996,795.587830 1137.366577,799.265015 
	C1137.354736,799.862915 1137.351562,800.104370 1137.334961,800.712402 
	C1137.274658,801.574402 1137.185303,802.062195 1136.936279,802.902344 
	C1136.467285,803.961426 1135.974976,804.525452 1134.911621,804.950989 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1255.399414,800.922668 
	C1249.274170,800.968933 1248.552246,804.625000 1249.000000,809.571411 
	C1248.050171,810.819580 1246.938110,810.641235 1246.411499,809.760681 
	C1244.781738,807.036011 1242.846802,806.612732 1239.762573,807.478455 
	C1237.190186,808.200500 1235.791870,806.419434 1236.765259,803.295288 
	C1237.781006,802.321045 1237.988281,801.237183 1239.055664,800.326111 
	C1241.020752,799.364807 1242.778076,799.380371 1244.892822,799.411499 
	C1247.712280,800.050720 1248.961426,798.448792 1249.671021,796.611084 
	C1250.187012,795.274231 1248.597900,796.178467 1247.573486,795.366150 
	C1247.124390,792.749573 1250.417603,792.291748 1249.991821,789.362793 
	C1250.904297,787.810120 1252.239258,788.299255 1253.702271,788.063232 
	C1255.421387,788.328064 1256.842651,788.656189 1258.632080,788.992188 
	C1260.807983,790.489685 1260.572266,792.503052 1260.364746,794.912048 
	C1259.708252,797.899109 1258.033081,799.601196 1255.399414,800.922668 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1227.652588,769.188416 
	C1226.936035,766.955627 1226.532593,765.073486 1225.248291,763.397705 
	C1222.294434,759.543579 1223.941895,755.150024 1228.628662,753.688232 
	C1229.901367,753.291321 1230.991211,753.140320 1231.481812,754.585144 
	C1231.888550,755.782898 1230.986694,756.473755 1229.997681,756.772949 
	C1228.568237,757.205383 1226.583008,757.360535 1227.264282,759.414917 
	C1227.998169,761.628784 1230.471313,762.119751 1232.412598,762.797974 
	C1234.017456,763.358704 1234.828003,762.470764 1234.440430,760.586060 
	C1234.211426,759.472107 1235.186890,759.046448 1235.974121,759.702515 
	C1238.018555,761.406921 1239.163696,760.254883 1240.471069,758.705811 
	C1241.145142,757.907104 1242.328003,757.771057 1243.156982,758.558777 
	C1243.952271,759.314514 1243.390503,760.287659 1242.709595,760.648926 
	C1239.224487,762.498108 1238.180908,766.247131 1235.932983,769.049072 
	C1233.467651,772.122070 1230.753052,773.233215 1227.652588,769.188416 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M1127.442871,791.921875 
	C1126.924683,793.032288 1126.010864,794.166016 1124.966553,795.591125 
	C1125.220703,798.682373 1123.712891,800.796631 1122.079102,803.211243 
	C1120.781738,804.451050 1120.477539,805.605408 1121.128540,807.301514 
	C1121.401978,808.803040 1121.366455,809.963562 1120.988281,811.452881 
	C1118.508545,814.848022 1120.859375,818.546265 1119.317749,822.032593 
	C1118.433960,822.722046 1117.731445,822.782043 1116.702271,822.332092 
	C1115.232178,818.009766 1114.056274,813.911499 1112.941162,809.415894 
	C1113.001831,809.018494 1113.464722,809.063110 1113.697021,809.073608 
	C1114.091675,809.176575 1114.254028,809.269104 1114.278198,809.117676 
	C1115.921021,806.085205 1115.506836,802.694946 1116.925659,799.649963 
	C1119.087036,795.011047 1121.832520,792.115967 1127.442871,791.921875 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1105.217041,710.754028 
	C1102.300293,710.566711 1100.117065,712.107544 1097.368896,712.950073 
	C1095.386230,711.308533 1095.893799,709.471375 1096.815186,707.309265 
	C1098.042358,703.952637 1098.077881,700.802368 1098.023071,697.630066 
	C1097.997559,696.155518 1097.847046,694.492554 1099.920044,694.115845 
	C1103.306763,693.500488 1106.102783,694.714722 1108.660522,696.848450 
	C1110.632690,698.493591 1108.903320,699.441711 1108.042969,700.483765 
	C1107.110718,701.612854 1106.985596,702.885376 1107.000977,704.617798 
	C1103.923584,706.110901 1105.287842,708.483765 1105.217041,710.754028 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1096.636719,706.947144 
	C1098.364624,708.761169 1097.056641,710.528992 1097.006592,712.646851 
	C1097.000000,713.444458 1097.000000,713.888916 1097.000000,714.666687 
	C1094.039551,716.847534 1091.460938,716.521729 1088.904907,714.026794 
	C1087.656128,712.807800 1085.956909,711.882202 1084.295166,711.292480 
	C1081.602295,710.336670 1081.749878,708.715271 1082.852051,706.333252 
	C1087.674194,702.591187 1091.997925,704.384216 1096.636719,706.947144 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1259.261963,796.234497 
	C1259.000000,793.958313 1259.000000,791.916687 1259.000000,789.437500 
	C1259.331787,786.324341 1260.601318,784.617188 1263.538818,785.022949 
	C1266.692261,785.458496 1268.521729,782.614319 1271.632812,782.048706 
	C1272.313354,782.470947 1272.626587,782.941895 1272.969971,783.705200 
	C1271.286987,786.247559 1270.228516,788.173035 1271.599854,791.469299 
	C1273.371460,795.727417 1269.759888,799.076599 1265.313843,797.773438 
	C1263.394165,797.210876 1261.482300,796.779053 1259.261963,796.234497 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1022.774170,401.270508 
	C1028.399902,402.089935 1024.277832,405.824402 1025.001587,408.605713 
	C1021.585022,409.429260 1018.228821,408.028198 1014.741150,408.884491 
	C1012.575378,409.416199 1011.001648,407.995392 1010.158936,405.952179 
	C1009.186401,403.594238 1009.030457,402.165314 1012.511780,401.862946 
	C1015.873291,401.571014 1019.309570,403.919769 1022.774170,401.270508 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1104.625977,755.051758 
	C1105.088867,756.353516 1106.071289,756.937927 1107.088257,757.574341 
	C1109.241211,758.921631 1109.890625,760.234009 1106.722534,761.016174 
	C1105.497803,761.318420 1104.472900,761.838379 1104.162720,763.093445 
	C1103.476807,765.867249 1101.450562,766.179626 1099.132446,765.952637 
	C1096.626953,765.707275 1095.234131,764.299683 1095.010132,761.383911 
	C1098.067749,759.009033 1099.739380,754.827209 1104.625977,755.051758 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1110.711182,780.340210 
	C1114.244263,783.172546 1117.248291,783.611328 1120.138184,779.455383 
	C1121.026978,778.177124 1122.534180,777.497009 1123.997803,778.791931 
	C1125.463013,780.088257 1124.724854,781.385986 1123.847900,782.737305 
	C1122.666992,784.556885 1121.364136,786.173035 1119.564331,787.463257 
	C1115.369873,790.470337 1113.242065,789.622803 1111.324463,784.067139 
	C1110.163452,783.004028 1109.907593,781.909302 1110.711182,780.340210 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1193.372559,737.992676 
	C1191.406860,735.931580 1187.626953,738.329041 1186.598389,734.662598 
	C1186.091919,732.857788 1184.203125,731.165527 1185.326416,729.273499 
	C1186.527222,727.250671 1188.582397,729.588379 1190.609131,728.997314 
	C1193.987793,728.775330 1195.991211,729.502319 1196.118408,733.120789 
	C1196.196777,735.354065 1197.555176,737.801086 1193.372559,737.992676 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1093.375122,408.555145 
	C1090.077515,404.382080 1090.279663,403.785004 1094.885376,402.966949 
	C1095.047852,402.938080 1095.214966,402.920380 1095.379639,402.921112 
	C1097.712524,402.931335 1100.109741,399.374298 1102.106812,402.118225 
	C1103.873535,404.545624 1100.195190,406.143341 1099.521851,408.479889 
	C1098.189087,413.104187 1095.681152,408.447510 1093.375122,408.555145 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1111.000000,779.666687 
	C1111.000000,781.066040 1111.000000,782.132141 1111.000000,783.597900 
	C1109.933350,783.998108 1108.866577,783.998657 1107.399902,783.998413 
	C1106.782349,782.876282 1106.151489,781.642151 1106.421265,780.653931 
	C1107.400635,777.066467 1105.921143,776.022583 1102.669800,776.650818 
	C1100.679565,777.035278 1099.157227,776.526855 1099.261230,774.400818 
	C1099.378418,772.004089 1101.350342,772.989441 1102.875610,772.651489 
	C1107.552246,771.615356 1109.161743,774.177734 1110.641846,777.907104 
	C1111.000000,778.444458 1111.000000,778.888916 1111.000000,779.666687 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M1196.448975,615.446045 
	C1197.853760,617.731750 1198.550415,619.695801 1196.573975,621.633545 
	C1195.525635,622.661377 1194.282227,623.089233 1192.925171,622.583374 
	C1190.864014,621.815002 1189.818359,620.064758 1189.405762,618.042603 
	C1188.652222,614.350342 1191.090088,613.330872 1196.448975,615.446045 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M998.042542,410.662415 
	C995.055237,410.819183 993.146545,408.344025 990.208191,407.375793 
	C990.483643,403.827362 988.068726,403.053192 985.641052,401.963654 
	C990.134399,400.528412 993.902405,402.870117 997.570862,404.553772 
	C1000.102051,405.715546 998.655090,408.275391 998.042542,410.662415 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1019.739258,417.250000 
	C1022.272949,416.926117 1023.924744,417.613403 1023.990479,420.626343 
	C1019.859375,421.677124 1018.141846,424.625153 1017.480225,428.451538 
	C1014.191589,427.552856 1014.081848,424.895569 1013.405396,422.631683 
	C1012.902466,420.948822 1013.678223,420.030151 1015.320312,420.074982 
	C1017.345642,420.130249 1018.832703,419.558990 1019.739258,417.250000 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1088.333496,672.146423 
	C1087.377319,665.220459 1091.944458,667.786560 1095.601440,667.998291 
	C1097.252319,669.127808 1098.109619,670.466187 1098.533447,672.133545 
	C1098.886597,673.522278 1098.182251,674.341553 1097.271973,675.102844 
	C1096.271851,675.939209 1094.969116,676.300415 1094.252197,675.083374 
	C1092.934326,672.845642 1090.494263,673.266846 1088.333496,672.146423 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1246.309326,800.696045 
	C1243.958374,801.000000 1241.916626,801.000000 1239.437500,801.000000 
	C1235.600342,799.799988 1233.834351,797.949219 1236.764160,794.160400 
	C1239.575439,793.526672 1241.998901,792.962341 1244.641113,794.713074 
	C1245.444458,795.000000 1245.888916,795.000000 1246.666748,795.000000 
	C1248.491211,796.911865 1248.400269,798.711731 1246.309326,800.696045 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1237.383301,794.767334 
	C1234.677002,797.609497 1237.831909,798.765137 1238.741211,800.792725 
	C1238.893188,801.886047 1238.609619,802.643555 1237.312500,802.945862 
	C1236.956543,801.448608 1235.592163,800.962158 1234.687500,800.086975 
	C1232.041016,797.526672 1229.193970,794.950378 1233.950928,791.344360 
	C1234.000000,791.000000 1234.500000,791.000000 1234.750000,791.000000 
	C1235.383667,790.703308 1235.739502,790.350037 1236.192627,790.172058 
	C1238.182251,789.390564 1240.446533,789.284241 1241.169189,791.473267 
	C1242.020630,794.052124 1239.142090,793.729126 1237.383301,794.767334 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1237.444092,795.000000 
	C1237.405884,793.213501 1239.767944,793.496887 1240.423218,791.849304 
	C1239.033569,790.411011 1237.311646,791.246338 1235.398193,791.000000 
	C1235.506836,788.842712 1236.367798,786.464539 1238.695557,786.321167 
	C1241.538086,786.146057 1242.877808,788.472412 1243.588379,791.068970 
	C1243.890625,792.173889 1244.456787,793.206604 1244.951538,794.636047 
	C1242.629395,795.000000 1240.258911,795.000000 1237.444092,795.000000 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M1148.783325,786.648010 
	C1149.753784,789.828796 1149.006104,791.660034 1145.625366,791.950439 
	C1141.675415,792.289673 1141.986206,789.631592 1141.977173,786.643555 
	C1142.478882,786.080139 1142.987061,785.985413 1143.743164,785.980530 
	C1144.434204,786.523865 1144.948730,786.688538 1145.784180,786.269287 
	C1146.858765,786.189331 1147.709717,786.297363 1148.783325,786.648010 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1219.447021,746.294678 
	C1215.666138,745.630920 1216.174316,742.209534 1215.203491,739.800049 
	C1214.780884,738.751282 1216.044922,738.262390 1217.192017,738.613953 
	C1218.908569,739.139954 1220.961548,739.468750 1220.847168,741.834656 
	C1220.778687,743.251099 1220.105347,744.638367 1219.447021,746.294678 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1096.000000,730.383301 
	C1096.311768,729.443909 1096.718628,728.372009 1096.921265,728.409302 
	C1099.914185,728.960388 1102.879761,729.658752 1105.926025,730.661133 
	C1103.471680,731.823792 1100.925659,731.904907 1098.423218,733.662659 
	C1095.008179,736.061218 1096.622437,731.994751 1096.000000,730.383301 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1105.375000,710.998413 
	C1104.619263,710.326416 1104.378662,709.493530 1103.828857,709.017578 
	C1102.667725,708.012207 1101.582397,707.041687 1102.489990,705.390808 
	C1103.493896,703.564758 1104.976562,704.645203 1106.653076,704.957520 
	C1107.126953,705.304321 1107.320801,705.557556 1107.790771,705.879761 
	C1109.121460,707.297852 1109.420532,708.701965 1108.275146,710.628662 
	C1107.250000,710.999756 1106.500000,710.999451 1105.375000,710.998413 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1237.508301,753.588989 
	C1236.023804,752.492432 1234.529663,751.457153 1235.506714,749.845459 
	C1236.267456,748.590515 1237.991211,748.293030 1239.451782,748.276306 
	C1240.677368,748.262207 1240.947266,749.368286 1240.944946,750.402283 
	C1240.940186,752.442627 1240.347168,753.988831 1237.508301,753.588989 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1206.365234,747.236816 
	C1203.469727,746.510681 1201.709717,745.053711 1201.264282,742.354187 
	C1201.145630,741.634827 1201.961792,741.066040 1202.551758,741.236694 
	C1205.202148,742.003296 1206.866821,743.887512 1207.906494,746.749146 
	C1207.549072,747.398804 1207.119507,747.514160 1206.365234,747.236816 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M1152.795288,794.425781 
	C1153.206665,791.723389 1151.843384,789.945801 1151.034790,787.631653 
	C1151.149902,787.193909 1151.320557,787.098328 1151.784424,787.001099 
	C1152.821045,786.364746 1153.640747,786.266113 1154.755127,787.027954 
	C1154.917725,787.657959 1154.893555,787.914429 1154.935547,788.366394 
	C1155.728882,790.424255 1155.775635,792.428406 1156.011230,794.386047 
	C1156.130005,795.372986 1155.886597,796.517212 1154.773560,796.813354 
	C1153.252197,797.218262 1152.783081,796.106384 1152.795288,794.425781 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1250.344849,787.237305 
	C1249.104248,785.068481 1244.678955,784.136719 1246.787842,781.929260 
	C1248.716919,779.909973 1250.537109,783.931396 1252.626099,784.872803 
	C1252.897705,784.995239 1252.867065,785.788574 1252.988159,786.635559 
	C1252.333984,787.664917 1251.552490,787.767822 1250.344849,787.237305 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1082.358765,699.051636 
	C1082.145752,697.767273 1080.855225,696.712769 1081.777344,695.205688 
	C1083.439819,695.756714 1084.906982,696.466431 1086.310791,697.285034 
	C1087.423096,697.933655 1089.037598,698.732605 1088.316040,700.176270 
	C1087.600220,701.608521 1086.062378,700.922607 1084.870483,700.254272 
	C1084.160889,699.856323 1083.435669,699.486023 1082.358765,699.051636 
z"/>
<path fill="#CDCCCD" opacity="1.000000" stroke="none" 
	d="
M1178.945923,853.646606 
	C1178.846191,855.405579 1178.004028,855.981995 1176.319580,855.986816 
	C1175.787231,856.140137 1175.603638,856.282776 1175.069580,856.439331 
	C1174.007935,856.287903 1173.416748,855.923218 1172.788086,855.054504 
	C1172.576416,853.644592 1173.197998,852.981384 1174.328003,852.657349 
	C1174.615723,852.861023 1174.779663,852.760071 1174.829468,852.677734 
	C1174.105347,851.160339 1175.755371,850.953979 1176.400269,849.910095 
	C1176.870728,849.661255 1177.081665,849.608887 1177.594727,849.679688 
	C1178.231201,850.963806 1178.565552,852.124817 1178.945923,853.646606 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1212.000000,753.596130 
	C1210.656372,754.437439 1209.217285,755.920410 1208.060669,753.324097 
	C1207.860596,751.506470 1207.157227,750.054871 1207.847168,748.250610 
	C1211.890381,747.806519 1212.138062,750.352783 1212.000000,753.596130 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1080.949219,692.648926 
	C1079.172607,693.164673 1077.448120,692.725403 1075.377319,692.052490 
	C1074.650391,691.602722 1074.505249,691.141235 1074.782471,690.307739 
	C1075.000000,690.000000 1075.000000,689.500000 1075.000000,689.250000 
	C1077.499390,689.145508 1079.846680,689.562866 1080.949219,692.648926 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1108.354736,710.950012 
	C1108.000000,709.611084 1108.000000,708.222229 1108.000000,706.416626 
	C1109.363403,706.211121 1111.404785,705.379822 1111.692993,707.455627 
	C1111.951782,709.318359 1110.389404,710.330750 1108.354736,710.950012 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M1208.012817,747.770386 
	C1208.639893,749.462524 1209.530396,750.904236 1208.244629,752.768005 
	C1208.000000,753.002441 1207.529053,752.980286 1207.293945,752.979858 
	C1206.034668,751.389404 1205.736816,749.653137 1206.052734,747.412537 
	C1206.498535,747.036865 1206.908081,747.049316 1207.623901,747.082397 
	C1207.930176,747.103149 1207.977539,747.549011 1208.012817,747.770386 
z"/>
<path fill="#0C161B" opacity="1.000000" stroke="none" 
	d="
M1177.203857,849.827271 
	C1177.122559,851.230469 1176.776001,852.121033 1175.149414,852.449890 
	C1174.297607,849.744202 1174.892334,847.527100 1178.330322,846.824585 
	C1178.501831,847.440002 1178.401855,847.927307 1178.104736,848.574341 
	C1177.610718,848.961487 1177.444946,849.264282 1177.203857,849.827271 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1168.640625,771.056152 
	C1169.000000,772.388916 1169.000000,773.777771 1169.000000,775.583374 
	C1167.737305,775.935059 1166.341431,776.004456 1166.135620,774.281128 
	C1165.942261,772.663025 1166.667603,771.560303 1168.640625,771.056152 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1023.000000,428.382721 
	C1023.887146,428.106476 1024.644287,428.391815 1024.949219,429.688599 
	C1024.800537,430.856140 1025.244629,432.532104 1023.922791,432.211945 
	C1022.294128,431.817505 1023.264709,429.961609 1023.000000,428.382721 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1250.000000,787.333313 
	C1250.750000,787.000000 1251.500000,787.000000 1252.625000,787.000000 
	C1253.304688,787.126587 1253.557739,787.320496 1253.879639,787.790833 
	C1253.626953,790.666260 1251.946655,789.264709 1250.327881,789.037354 
	C1250.000000,788.555542 1250.000000,788.111084 1250.000000,787.333313 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1066.009766,790.346008 
	C1067.135132,790.053467 1068.361938,789.909546 1068.931396,791.664551 
	C1067.092285,793.647217 1066.285645,792.785339 1066.009766,790.346008 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1197.686279,728.520081 
	C1198.076904,726.389648 1199.132812,727.092957 1200.066162,727.857605 
	C1200.590332,728.287048 1200.885254,729.214966 1200.097168,729.432007 
	C1199.460693,729.607239 1198.614380,729.020203 1197.686279,728.520081 
z"/>
<path fill="#060D0E" opacity="1.000000" stroke="none" 
	d="
M1154.983643,786.963684 
	C1154.258179,787.241882 1153.535278,787.078613 1152.445068,786.957397 
	C1151.572388,785.065125 1152.976318,784.543945 1154.671387,783.945557 
	C1155.013306,784.784729 1154.999756,785.653503 1154.983643,786.963684 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1127.348877,390.917175 
	C1127.164795,389.831757 1127.329590,388.664764 1127.747192,387.248901 
	C1128.000000,387.000000 1128.500000,387.000000 1128.750000,387.000000 
	C1128.840820,388.371857 1129.479614,390.014679 1127.348877,390.917175 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M1056.497314,591.101318 
	C1055.391235,589.736816 1056.686401,588.602417 1057.464844,587.126831 
	C1057.436401,588.265686 1057.085449,589.563171 1056.497314,591.101318 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1166.796021,765.182068 
	C1167.313354,765.471558 1167.626587,765.943115 1167.969971,766.707397 
	C1167.128540,766.800842 1166.030640,766.796509 1166.796021,765.182068 
z"/>
<path fill="#1D2639" opacity="1.000000" stroke="none" 
	d="
M1145.674561,786.008789 
	C1145.916138,786.717163 1145.835205,787.459717 1145.043335,787.481995 
	C1144.730713,787.490784 1144.402344,786.935364 1144.036011,786.353638 
	C1144.441040,786.025635 1144.891235,785.981018 1145.674561,786.008789 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M1058.577881,586.261841 
	C1057.918213,585.596924 1058.191772,584.871826 1058.966675,584.014832 
	C1059.083130,584.588867 1058.949585,585.337036 1058.577881,586.261841 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1062.659180,585.586609 
	C1063.373779,585.387878 1063.634155,585.636597 1063.521606,586.192444 
	C1063.498291,586.307800 1063.334961,586.462708 1063.218384,586.479919 
	C1062.657471,586.562866 1062.423096,586.289612 1062.659180,585.586609 
z"/>
<path fill="#1E293E" opacity="1.000000" stroke="none" 
	d="
M1177.229126,849.936157 
	C1176.962524,849.453308 1176.835205,848.857727 1177.721680,848.644165 
	C1177.931763,848.912476 1177.956177,849.090820 1177.938599,849.536011 
	C1177.896729,849.802856 1177.440918,849.848999 1177.229126,849.936157 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1062.518311,589.529785 
	C1062.481445,589.548584 1062.555298,589.510986 1062.518311,589.529785 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M344.000000,1081.666748 
	C342.514648,1083.898071 341.277374,1085.112183 338.278595,1083.855835 
	C334.105072,1082.107422 329.048462,1081.806030 325.198914,1084.010498 
	C319.259338,1087.411987 313.125977,1086.976196 306.879150,1086.993286 
	C297.438568,1087.018921 297.427917,1087.003784 293.943542,1077.856445 
	C293.630890,1077.035645 293.391907,1076.270142 292.344971,1076.340454 
	C291.551575,1076.393799 291.304565,1077.038940 291.083923,1077.681396 
	C290.438934,1079.559448 289.554718,1081.391235 289.190613,1083.321533 
	C288.666687,1086.098755 288.772308,1088.565918 292.616974,1089.168945 
	C296.588928,1089.791870 297.804382,1092.210815 296.153809,1096.074829 
	C294.605988,1099.698364 295.845703,1105.067993 289.641022,1105.656494 
	C288.657196,1105.749878 288.156128,1107.401489 288.217926,1108.614258 
	C288.259247,1109.424438 288.669373,1110.208496 288.802765,1111.022583 
	C290.256226,1119.892090 287.265503,1123.016968 278.347412,1121.151123 
	C274.639404,1120.375244 272.111908,1123.165894 268.403381,1122.998291 
	C265.612885,1120.672974 262.878876,1118.851318 259.793976,1117.604858 
	C258.567017,1117.108887 257.258606,1116.626221 256.691010,1118.658081 
	C254.982040,1124.775391 254.944595,1124.762939 248.684265,1123.081055 
	C248.366959,1122.995850 248.026337,1123.010376 247.347321,1123.003418 
	C245.285873,1123.000000 243.571167,1123.000000 241.427933,1123.000000 
	C234.230042,1119.904785 231.678055,1114.497681 233.819962,1107.397827 
	C234.496170,1105.156372 233.910980,1103.150024 233.377899,1101.072510 
	C232.923615,1099.302124 232.283905,1097.546753 233.012512,1095.699829 
	C233.728912,1093.884033 234.894028,1092.512329 236.933365,1092.273682 
	C239.127655,1092.017090 240.240387,1093.489136 241.132004,1095.187134 
	C242.296921,1097.405518 242.046677,1099.743286 241.506271,1102.026733 
	C240.780090,1105.095093 241.139175,1107.344604 244.913300,1107.612671 
	C245.405563,1107.647461 245.885132,1107.997681 246.354553,1107.987305 
	C248.296326,1107.944702 249.407700,1112.045776 251.773148,1109.244873 
	C253.899139,1106.727661 253.226715,1101.528198 251.634064,1098.948486 
	C249.302795,1095.172363 246.333328,1093.355225 241.904877,1093.230469 
	C239.226837,1093.155151 237.776657,1091.027344 237.593628,1088.477051 
	C237.230560,1083.417847 233.241135,1079.884644 232.221283,1075.120239 
	C231.927917,1073.749634 230.626678,1073.525024 229.448212,1073.405396 
	C225.637421,1073.018677 223.305283,1070.917725 222.586258,1067.224976 
	C222.126068,1064.861450 220.633850,1063.908691 218.270081,1063.225830 
	C217.686554,1062.528442 217.373123,1062.056885 217.029541,1061.292725 
	C215.736099,1059.497681 214.036591,1058.552856 212.231171,1057.224731 
	C210.248764,1054.657959 212.008209,1050.839844 208.351105,1049.075439 
	C207.442413,1048.385864 206.804611,1047.767334 206.709381,1046.925903 
	C206.324646,1043.526611 204.974152,1042.058472 201.164001,1042.896729 
	C198.317017,1043.522949 195.796722,1042.451538 196.769684,1038.285400 
	C196.999420,1037.555542 196.999451,1037.111084 196.999451,1036.333252 
	C195.103928,1034.522339 194.884583,1032.452515 194.634750,1030.223389 
	C193.899918,1023.666748 190.325409,1021.235107 184.099411,1022.864258 
	C182.128647,1023.380005 180.170563,1023.695679 178.195633,1023.069275 
	C176.047623,1022.387756 174.792572,1020.921936 175.022369,1018.599182 
	C175.260056,1016.196594 176.855957,1015.225464 179.146576,1015.128418 
	C181.078125,1015.046631 183.044571,1015.816772 185.081940,1014.928711 
	C183.802811,1013.393188 181.940445,1012.974548 180.166306,1011.812012 
	C179.379410,1011.586609 178.912094,1011.525146 178.074936,1011.493286 
	C176.964355,1011.502258 176.219742,1011.468811 175.090408,1011.455261 
	C172.856262,1011.477600 171.081406,1011.023804 168.901550,1010.562378 
	C167.119553,1010.518677 165.742706,1010.493408 164.280380,1009.308105 
	C163.904739,1006.243774 162.804810,1003.670349 163.691940,1000.680176 
	C164.422150,998.218933 165.410614,995.262878 159.780533,995.384705 
	C155.451065,995.478394 151.045944,991.925903 147.409103,988.401733 
	C143.588028,984.699036 142.829880,982.622681 145.444687,975.073853 
	C146.183929,972.939575 146.077606,970.786255 145.947250,968.651306 
	C145.678345,964.246704 144.472153,963.664490 140.781937,966.165894 
	C139.050385,967.339661 137.700027,966.965942 136.307800,965.762146 
	C134.674728,964.350342 133.045166,962.934448 131.206757,961.260132 
	C131.383057,958.263428 133.609451,959.203430 135.190399,958.944885 
	C137.215714,958.613647 138.923767,957.778564 138.523499,955.492554 
	C138.166016,953.450867 136.235138,953.356689 134.505539,953.450195 
	C132.651672,953.550476 130.961609,954.318604 129.201965,954.812988 
	C122.379677,956.729797 119.442085,954.372681 119.911026,946.985596 
	C120.138443,946.432373 120.253098,946.221680 120.600220,945.738403 
	C122.453506,944.591553 123.265877,943.503479 122.625000,941.463684 
	C121.896950,939.146362 123.326057,938.209961 125.451195,937.765076 
	C126.708755,937.501770 129.089798,938.825989 129.020966,936.563354 
	C128.954788,934.388428 129.697098,931.987610 127.979881,929.872070 
	C123.629318,924.512268 123.699890,922.319763 128.568909,915.471436 
	C124.887985,918.198425 122.595222,915.524536 119.934380,915.049133 
	C116.593086,914.452209 115.174500,917.755554 112.287262,918.886902 
	C109.067955,919.237183 105.202835,917.887451 105.850723,923.373291 
	C106.074112,925.264832 104.201965,926.081848 102.427643,926.296204 
	C99.776901,926.616638 97.475952,925.993164 95.974602,923.574951 
	C94.500397,921.200439 93.520134,918.510132 91.139313,916.345825 
	C90.226738,915.761963 89.747574,915.240356 89.208893,914.293396 
	C87.824829,912.040894 87.743088,910.220337 89.833466,908.826477 
	C91.893372,907.452881 92.900253,909.352905 93.898827,910.452820 
	C96.611275,913.440674 99.668533,911.392517 102.249664,910.773804 
	C105.660484,909.956238 109.748978,910.085571 111.582664,905.492981 
	C112.531807,903.115662 112.474678,901.532959 111.142853,899.311890 
	C110.608879,894.713928 111.662338,890.562378 113.445457,886.866760 
	C115.304657,883.013550 119.058052,882.470886 122.539307,885.154968 
	C124.530739,886.690430 125.085045,886.203796 126.713829,884.512024 
	C131.340012,879.707031 134.341095,873.725464 138.755692,868.782410 
	C140.222107,867.140503 142.158051,866.165100 143.754822,864.244751 
	C144.316498,862.510376 145.183350,860.269592 146.632187,861.985229 
	C151.550079,867.808655 158.100784,864.214966 163.752121,865.497559 
	C168.136703,866.492798 172.246490,868.557739 176.902649,868.605896 
	C179.419708,868.631897 177.163376,871.973145 179.274551,872.848572 
	C185.230118,875.318237 190.332062,879.710632 197.145111,880.336243 
	C199.021820,880.508484 200.628235,880.718323 202.372055,879.884338 
	C203.369415,879.407349 204.762695,878.881348 205.664993,879.217285 
	C215.308456,882.807861 225.310822,879.901245 235.537415,880.998474 
	C236.631302,880.453979 237.356262,880.148926 238.589218,880.176392 
	C248.754364,880.895630 258.636353,882.696594 268.159607,877.230652 
	C269.215546,876.897766 269.936615,876.864868 271.000031,876.993408 
	C272.504272,877.455688 273.648285,878.158081 275.244629,877.258423 
	C276.035095,877.078491 276.504547,877.053955 277.345367,877.129761 
	C281.723083,879.160278 286.492188,877.761536 290.724152,880.284180 
	C292.498871,883.934753 289.836731,884.606445 287.650177,885.175781 
	C284.749298,885.931396 281.697266,885.779602 278.707977,885.889648 
	C276.715576,885.962952 274.730957,885.677795 272.946564,886.947327 
	C272.124237,887.532471 270.742828,888.059998 270.150665,887.348022 
	C265.902588,882.240295 261.330139,886.935059 256.965759,886.906433 
	C254.893250,886.892822 252.711380,887.663635 250.559937,887.406982 
	C242.412903,886.435120 234.234283,886.651794 226.187164,887.794922 
	C220.818726,888.557617 215.028961,889.671631 212.637482,895.907898 
	C212.461960,896.365540 212.124374,896.846130 211.694870,897.048889 
	C203.564209,900.887573 197.902374,908.725159 188.255676,910.969788 
	C191.386490,914.938354 191.752060,918.756104 190.259033,923.246033 
	C188.985382,927.076172 195.511078,933.229980 200.013992,933.061584 
	C201.842178,932.993286 203.633347,932.502625 205.474823,932.498108 
	C208.404297,932.490845 210.784012,933.145264 211.133896,936.683594 
	C211.311417,938.478455 212.163437,939.985046 214.129135,940.156189 
	C217.675461,940.465088 220.026108,943.098145 223.020950,944.457397 
	C224.818100,945.273071 226.213257,946.382324 225.934677,948.586426 
	C225.619644,951.079102 223.870605,952.254333 221.648575,952.895447 
	C219.718735,953.452271 217.636368,952.724182 215.737915,953.585327 
	C214.081985,954.336487 212.460373,955.184692 210.475906,955.444153 
	C211.291718,955.439392 210.456955,955.461243 211.120590,955.501282 
	C214.004486,955.675232 217.556244,954.322571 219.347305,957.493103 
	C221.002960,960.423950 217.654312,962.140991 216.605774,964.442688 
	C214.850647,968.295532 212.048477,971.932983 214.176804,976.654480 
	C215.243317,979.020569 216.070312,980.205750 218.899139,978.467102 
	C221.710785,976.739136 224.570007,974.995667 227.704758,973.591553 
	C227.357635,972.574585 226.440628,973.017700 225.818222,972.905823 
	C223.541275,972.496521 221.933853,971.430237 222.574951,968.893616 
	C223.205658,966.398071 224.876663,964.669312 227.662552,965.196838 
	C230.887177,965.807251 233.773621,967.539551 235.284256,970.400269 
	C238.251114,976.018738 240.579697,981.914917 239.450073,988.542908 
	C238.973419,991.339539 238.097931,993.839111 234.766220,994.116455 
	C231.737762,994.368469 230.227219,992.538025 229.528244,989.819641 
	C228.966980,987.636902 228.193817,985.008301 225.367706,986.350159 
	C222.746277,987.594910 222.456360,991.088928 223.960083,992.845215 
	C228.458008,998.098816 226.868698,1002.941772 223.933136,1008.044800 
	C227.469223,1007.305481 227.627060,1007.381531 229.228180,1004.058228 
	C230.674301,1001.056702 232.359940,999.752808 235.894363,1000.916443 
	C240.792435,1002.529053 241.841965,1001.366455 240.647964,996.398743 
	C239.855423,993.101379 240.939026,991.751892 244.108368,992.624878 
	C248.469421,993.826233 252.358887,994.525024 255.857819,990.389832 
	C257.553497,988.385864 259.493591,989.444885 260.183807,992.013672 
	C261.241241,995.949341 258.913330,1000.084229 254.507614,1001.049072 
	C251.351532,1001.740173 250.650116,1003.083923 250.088776,1006.257690 
	C249.069443,1012.020874 247.762314,1018.270752 240.412521,1019.849792 
	C239.747177,1019.992798 239.439285,1020.714844 238.960602,1021.613892 
	C238.456635,1022.485657 238.002228,1022.967041 237.651627,1023.522278 
	C237.388031,1023.939758 237.216263,1024.382935 237.606628,1025.141113 
	C239.151840,1024.750610 238.950806,1023.248169 239.560455,1021.906982 
	C241.324112,1019.841980 242.988678,1020.021545 244.380798,1021.726074 
	C245.943359,1023.639282 246.464767,1025.842651 245.409454,1028.251831 
	C244.801453,1029.639771 243.264786,1030.290161 242.706879,1031.670044 
	C246.259796,1034.336670 251.404526,1033.955688 253.914398,1038.819702 
	C255.371017,1041.642456 261.623688,1040.107544 262.426697,1036.579834 
	C263.358307,1032.487549 265.492126,1030.684204 269.282623,1029.852905 
	C270.756409,1029.529541 272.171753,1028.166870 271.693634,1026.524780 
	C270.206421,1021.416748 273.166718,1017.087036 274.174957,1012.516357 
	C275.226959,1007.747192 277.498535,1003.108765 279.881042,998.743042 
	C282.800110,993.394104 293.964020,989.758240 301.072571,991.809509 
	C301.628418,995.117493 299.951843,996.554382 296.879333,997.236877 
	C290.799530,997.869812 281.777405,1006.271118 280.813721,1011.893677 
	C280.588898,1013.205261 280.764557,1014.624939 280.289185,1015.842346 
	C277.918579,1021.913330 278.372681,1026.838867 282.492310,1032.725342 
	C285.472900,1036.984131 285.929565,1042.974121 290.315613,1047.187134 
	C292.901123,1049.670776 291.587585,1054.192505 291.630096,1057.825928 
	C291.691620,1063.084106 290.618042,1068.708008 296.799835,1071.682373 
	C297.213684,1071.881470 297.422974,1072.443726 297.685181,1072.870483 
	C303.161987,1081.785767 308.123627,1084.024658 318.307190,1081.818726 
	C322.696716,1080.867920 327.028992,1079.667969 331.513214,1079.205444 
	C333.246307,1079.026611 334.560791,1078.068237 335.905487,1077.032471 
	C338.812531,1074.793457 341.877228,1072.799927 346.329346,1072.666992 
	C346.836823,1074.167358 346.671234,1075.334717 346.252808,1076.750977 
	C345.299011,1077.744385 344.473999,1078.390015 344.047302,1079.695312 
	C344.000000,1080.444458 344.000000,1080.888916 344.000000,1081.666748 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M126.733047,626.225342 
	C129.936020,626.497253 128.421021,629.291321 129.460831,630.738831 
	C130.386688,632.027710 130.907974,636.239380 133.118515,631.500793 
	C133.443863,630.803406 134.347717,630.257874 134.495758,630.623779 
	C135.763321,633.757324 140.672394,633.278076 140.990387,637.626831 
	C137.637634,639.608215 134.062210,639.139893 130.527740,639.077209 
	C129.399445,639.057251 128.092117,638.727478 127.439407,639.988831 
	C126.782097,641.259094 127.739853,642.121765 128.526962,642.974915 
	C130.687347,645.316406 131.408951,648.477295 133.120407,651.086609 
	C134.005234,652.435730 133.819107,653.916016 132.441406,655.418640 
	C129.482269,656.974304 126.659477,656.803040 123.930489,656.037781 
	C121.704552,655.413574 119.710724,655.526062 117.412971,656.763672 
	C115.216553,657.643921 113.235649,656.577942 111.098511,657.411316 
	C110.671265,657.741760 110.497787,657.875061 110.049408,658.195801 
	C108.458649,659.374390 107.109924,660.251099 105.035202,659.485718 
	C104.152290,658.792358 103.717888,657.514893 103.372368,657.595703 
	C102.621628,657.771362 102.965912,658.892151 102.464188,659.852600 
	C100.616302,661.061646 99.789146,662.146851 99.992828,664.363342 
	C100.240601,667.059631 100.914467,669.775574 100.262169,672.810425 
	C99.641319,673.391846 99.174744,673.531921 98.665222,673.541138 
	C96.479645,673.580566 94.909981,669.805237 92.984695,671.620728 
	C89.382370,675.017578 83.003754,675.550659 82.006180,682.142456 
	C81.700607,682.651245 81.552475,682.839294 81.097824,683.211304 
	C80.556946,683.423950 80.322586,683.452515 79.744019,683.412842 
	C75.456413,681.792114 71.719849,683.701294 68.985901,688.911560 
	C68.586731,689.794983 68.208038,690.313477 67.957001,690.889709 
	C67.742088,691.382996 66.999054,691.999878 67.566658,692.385376 
	C67.951813,692.647034 68.583260,691.827148 69.468842,691.456177 
	C71.492149,691.182007 73.500610,691.510620 73.773865,688.572449 
	C74.363251,687.026367 75.223335,686.278320 76.892380,686.087036 
	C78.977898,686.708374 80.720284,687.080872 82.852768,686.345642 
	C85.576340,687.698547 87.678452,686.769226 90.072144,685.451843 
	C93.976929,687.050903 96.429230,690.224487 99.600761,692.479858 
	C101.652733,693.939087 103.260788,697.448730 100.472244,699.917297 
	C97.775650,702.304504 95.624580,699.898193 93.661453,698.040344 
	C88.474800,693.131653 84.083656,693.521545 78.768867,699.627319 
	C85.598015,700.575317 93.955467,698.453125 95.864639,708.654785 
	C93.822380,712.812927 90.769691,714.355530 86.432495,714.191101 
	C81.475044,714.003235 76.427788,713.865173 71.818863,716.363708 
	C70.664230,716.989685 69.184364,717.344543 69.494041,719.115601 
	C69.815826,720.955872 71.054596,721.535706 72.798706,721.217285 
	C75.233597,720.772888 77.116135,721.545471 78.088104,723.872070 
	C79.124397,726.352539 80.467415,725.445312 81.959427,724.431152 
	C83.752548,723.212402 85.394547,721.813416 87.038376,720.409180 
	C89.598663,718.222107 92.388855,716.329590 95.773911,715.856750 
	C99.323647,715.360840 102.315308,714.453308 104.847290,711.466187 
	C107.415512,708.436279 111.358315,709.569885 113.429512,713.253967 
	C114.708435,715.528809 114.199730,718.126953 115.165237,721.506836 
	C118.956581,716.287109 123.885338,714.424500 129.944824,714.913330 
	C131.189560,716.768616 130.513275,718.115173 129.255600,719.703064 
	C128.194565,720.246704 127.389122,720.493469 126.291687,720.870117 
	C123.986649,723.038879 121.773849,724.841980 119.233696,726.767273 
	C118.488281,727.555847 117.976555,728.111694 117.232262,728.833740 
	C115.586304,730.875122 113.385376,730.027771 111.292816,730.783081 
	C108.779068,735.092407 104.504478,734.969055 100.390594,735.988708 
	C98.354584,737.436279 96.635544,737.029968 94.569992,736.051758 
	C93.005119,735.269348 92.357361,733.720703 90.395111,733.386353 
	C90.834267,735.725403 92.966660,735.459656 94.179871,736.765503 
	C95.111618,739.083008 93.838783,740.283264 92.258377,741.731323 
	C91.194633,742.246826 90.389580,742.493713 89.292564,742.870239 
	C85.334129,745.305664 83.946671,741.527649 81.313416,740.118896 
	C78.513275,740.327759 76.188713,739.460449 73.403839,739.012756 
	C72.629608,739.120911 72.495613,739.370300 72.800049,739.874023 
	C69.061699,742.687317 65.596161,746.562622 59.912739,744.243225 
	C58.026905,743.473694 55.846565,743.560364 56.491478,740.570129 
	C56.718681,739.516724 55.826996,739.289185 54.974731,739.042969 
	C50.426941,737.729309 41.590919,740.951294 38.853130,745.043457 
	C38.266796,745.919861 37.740154,746.874390 36.586945,746.505981 
	C35.295078,746.093323 34.830452,744.849548 35.144981,743.664185 
	C35.519653,742.252258 36.440842,740.986389 36.823257,739.575378 
	C37.238884,738.041870 36.333626,736.976929 34.872864,736.601685 
	C33.041298,736.131226 33.043697,737.568604 33.025249,738.714172 
	C32.957550,742.918335 29.749172,742.644287 27.011429,742.911560 
	C25.783180,743.031372 24.688276,742.441650 24.328167,741.131287 
	C23.888145,739.530090 25.232136,739.363708 26.227377,738.758484 
	C29.246490,736.922302 32.995193,735.830872 33.990993,731.367798 
	C35.452133,729.813293 35.114651,727.226868 38.468906,727.292725 
	C41.206989,727.346436 41.165188,724.077148 39.846897,721.852417 
	C39.012169,720.443665 37.702393,719.324829 36.707218,717.999634 
	C36.158150,717.268494 36.220573,716.417603 37.005806,715.796692 
	C37.772511,715.190369 38.723900,715.177734 39.188164,715.981506 
	C44.230629,724.711182 50.715786,718.400940 56.623337,717.633606 
	C58.055954,717.447571 59.359619,716.407227 60.795574,716.084473 
	C67.281204,714.627136 67.510376,714.063721 66.103432,707.744141 
	C65.545586,705.238403 64.229309,704.899353 62.258190,705.006714 
	C59.331547,705.166016 57.775215,703.818298 57.973488,700.896545 
	C58.312119,695.906372 54.462959,692.998657 52.361217,689.228333 
	C51.776524,688.179382 50.766613,688.129089 49.806049,688.841553 
	C48.756474,689.620178 48.429302,690.358765 49.194717,691.760315 
	C50.573174,694.284485 47.121620,701.994690 44.370140,702.833069 
	C43.257622,703.172058 42.038944,703.101135 41.062912,702.251404 
	C40.115978,701.427002 40.647438,700.599304 40.879723,699.592102 
	C42.081013,694.383545 46.948654,691.192200 48.082218,685.802063 
	C48.590832,683.383484 57.422489,682.489929 59.789780,684.224976 
	C62.269203,686.042358 64.560867,686.587036 66.692947,683.806519 
	C67.900269,682.231995 69.857780,682.065918 71.444748,681.206177 
	C72.855522,680.441956 73.235466,679.228210 72.691048,677.830017 
	C72.067146,676.227661 70.964645,676.074280 69.508545,676.978516 
	C66.452217,678.876587 65.206108,677.378784 65.039818,674.362244 
	C64.919388,672.177612 65.154518,670.611023 68.065895,672.294495 
	C70.602074,673.760864 72.589088,673.029724 72.989159,669.380798 
	C74.757126,667.685425 74.992012,667.521606 76.154839,669.188843 
	C77.864014,671.639343 79.349686,672.015015 80.901535,669.280029 
	C82.785202,665.960266 86.873482,667.795715 89.374855,665.298950 
	C89.699417,664.463684 90.516640,664.173096 90.190491,663.431396 
	C89.965652,663.864075 89.773071,664.314514 89.307182,664.998962 
	C86.475784,666.291931 85.019379,664.247498 83.738457,662.748413 
	C81.233223,659.816589 78.779587,658.579346 75.424225,661.337708 
	C74.925331,661.747803 74.166687,661.657227 73.255463,661.230652 
	C73.128830,658.910767 72.151321,657.955933 70.054985,657.959473 
	C61.761536,657.973267 53.591167,655.428223 45.234352,656.749573 
	C43.817059,656.973633 42.392563,657.038269 42.136120,655.237732 
	C41.554237,651.152222 38.978268,650.179382 35.398155,650.725708 
	C33.853436,650.961365 32.585186,650.581665 32.333069,649.014282 
	C32.014271,647.032349 33.664070,646.943726 34.986725,647.038879 
	C38.852505,647.317017 40.995819,645.585510 41.277534,641.862122 
	C42.716587,641.369141 42.823772,642.327820 43.158669,643.034912 
	C43.599060,643.964905 44.208263,644.994080 45.364033,644.346008 
	C46.403675,643.763000 47.163185,642.502075 46.444614,641.374756 
	C44.981857,639.080078 44.086720,636.039551 40.516956,635.989197 
	C38.921810,635.966675 37.337334,635.562256 37.259094,633.656433 
	C37.166737,631.406677 39.146183,631.571899 40.652367,630.893494 
	C44.503246,629.159058 47.782803,631.592468 51.117340,631.673767 
	C55.195610,631.773132 60.026272,634.952759 63.929703,630.343506 
	C68.877251,628.396484 73.636894,628.325195 78.320396,631.455566 
	C78.236916,634.458191 75.951653,634.108276 74.343704,634.516235 
	C70.935005,635.381165 68.492119,636.802734 69.120888,640.941406 
	C69.380272,642.648743 68.381020,644.097961 67.761772,645.636414 
	C66.555695,648.632629 67.966988,652.355225 70.666557,653.006104 
	C73.758896,653.751709 76.716309,653.687622 77.560997,649.566284 
	C77.863800,648.088928 78.371223,646.644470 80.551178,646.361694 
	C81.230049,646.430664 81.603645,646.443298 81.854317,646.632385 
	C86.968803,650.492310 93.163292,649.371033 98.889740,650.059265 
	C102.034622,650.437195 105.483421,649.707397 105.658684,644.865540 
	C105.780998,641.486145 107.416412,638.962646 111.516197,641.110779 
	C112.150932,641.443359 112.718842,641.812561 113.227135,641.088318 
	C115.636528,637.655457 119.670807,636.581909 122.924919,634.380798 
	C125.785591,632.445862 125.304520,629.099243 126.733047,626.225342 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M239.333099,881.239258 
	C238.249451,881.003113 237.499817,881.001282 236.375397,880.998535 
	C235.589264,878.709900 233.798050,878.178894 231.925385,878.069275 
	C230.012711,877.957275 228.221176,877.433350 226.794678,876.325623 
	C223.979233,874.139221 221.306351,872.792480 217.991150,875.399231 
	C216.943039,876.223450 215.738464,875.389709 214.720215,874.854919 
	C212.764954,873.828064 211.059998,873.938599 209.135071,875.208618 
	C205.217163,877.793640 201.950211,877.554504 198.051392,874.046021 
	C194.825897,871.143494 190.088852,869.852417 185.915680,868.099243 
	C183.732498,867.182190 182.542145,869.653320 180.837280,870.508423 
	C178.655807,863.421143 175.039474,861.648621 167.692642,859.726562 
	C161.091370,857.999573 153.801743,856.851074 148.651184,851.428345 
	C147.598770,850.320312 146.551590,849.656372 145.255142,850.798218 
	C144.309860,851.630737 143.326889,852.091919 142.096252,851.934143 
	C141.622513,851.873413 141.169327,851.652222 140.681976,851.496277 
	C140.470581,849.131042 144.713409,848.674805 143.599548,847.614075 
	C140.625000,844.781494 140.533295,840.239014 137.244308,837.876892 
	C131.737045,833.921753 126.324341,829.847778 120.649994,826.099304 
	C116.232719,823.181213 111.303070,819.725586 112.928749,812.791321 
	C113.280037,811.292908 113.373558,809.477234 111.952187,808.107971 
	C108.059898,804.358276 110.364922,801.065491 113.153793,798.311646 
	C114.336601,797.143616 115.073753,796.299866 114.005058,795.154053 
	C109.825241,790.672607 109.554413,783.232544 103.633568,780.339233 
	C97.455467,777.320129 97.583305,771.850159 97.075439,766.549133 
	C96.797874,763.651794 97.851814,761.547302 100.893005,759.786438 
	C104.783112,757.534119 102.510345,753.239929 102.103745,749.832886 
	C101.962738,748.651245 100.518921,747.384216 101.324402,746.166382 
	C102.518623,744.360901 103.561722,746.443787 104.691345,746.690186 
	C106.284866,747.037903 107.334038,746.429565 107.754349,744.939453 
	C108.179092,743.433594 107.683083,742.346313 105.976921,742.016663 
	C104.637299,741.757874 102.685638,740.983032 103.629463,739.680481 
	C104.890419,737.940308 107.143761,736.851318 109.103165,735.709717 
	C110.355995,734.979675 110.737671,736.069031 111.045586,737.113586 
	C111.846138,739.829407 113.351051,738.872437 114.748016,737.693542 
	C115.787437,736.816467 116.789726,735.971191 118.620270,736.004822 
	C120.750893,737.978027 120.000717,739.800903 118.340225,741.208435 
	C115.198936,743.871338 114.331680,747.190674 114.915558,751.083069 
	C114.989433,751.575562 115.136162,752.154541 114.936005,752.569336 
	C111.479935,759.730957 115.592682,766.512268 116.035744,773.467834 
	C116.159180,775.405701 117.162865,777.281677 116.636841,779.336426 
	C116.427536,780.153992 116.401131,781.051086 117.188004,781.620056 
	C124.294525,786.759583 124.430283,795.677917 128.340347,802.572632 
	C131.567688,808.263489 132.880112,815.028198 135.968369,820.946777 
	C136.352966,821.683838 136.528122,822.604736 137.118439,823.151550 
	C144.814972,830.280212 149.935104,839.678223 158.068375,846.482239 
	C163.841385,851.311707 170.931717,852.554504 177.592453,855.000183 
	C180.167206,855.945618 182.400955,857.098816 184.264664,859.145996 
	C189.404480,864.791504 196.065887,867.188416 203.495224,867.277954 
	C210.348206,867.360535 216.879242,868.158936 223.586227,870.134155 
	C233.083618,872.931091 243.383881,875.396423 252.989426,874.027283 
	C265.221710,872.283752 277.872742,874.280396 289.742310,869.846375 
	C290.997345,869.377502 292.380768,869.419983 293.705200,869.416870 
	C304.523041,869.391724 313.991699,865.518677 322.733917,859.439575 
	C325.475800,857.532898 328.900360,857.305054 331.958008,856.110535 
	C342.308868,852.066895 352.514740,847.590576 363.258728,844.596558 
	C365.042816,844.099426 366.754547,843.447021 368.910828,844.482788 
	C370.954102,848.298035 368.830017,850.356750 365.913696,851.070862 
	C355.254486,853.680908 346.032410,859.987244 335.315063,862.532043 
	C330.904297,863.579468 326.993225,866.532776 322.466431,868.686890 
	C320.466339,869.412903 319.168060,870.579590 317.401794,871.663147 
	C316.207825,872.199646 315.282990,872.345520 314.018066,872.464478 
	C312.601166,872.419495 311.592468,872.613037 310.500549,873.561401 
	C309.345917,874.329529 308.428955,874.880981 307.238647,875.621460 
	C304.461700,877.402161 300.992249,874.455505 298.547546,877.770996 
	C297.458038,878.620850 296.544434,879.018127 295.167542,879.292664 
	C289.136475,878.618408 283.457153,878.321289 277.390320,878.001221 
	C276.556641,878.000977 276.112183,878.001953 275.334351,878.003906 
	C274.313599,878.578552 273.543488,878.743713 272.341827,878.239258 
	C271.248779,878.003418 270.499176,878.001953 269.374817,878.000244 
	C265.393250,877.621582 262.643463,880.245911 259.114105,880.657104 
	C252.622482,881.413513 246.056885,879.430786 239.333099,881.239258 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M369.343109,845.254883 
	C363.306549,845.404663 357.827301,847.336609 352.825317,849.538330 
	C344.957001,853.001770 336.846375,855.918396 328.876556,858.988464 
	C323.869446,860.917236 320.082214,865.077393 315.103088,865.994324 
	C308.650299,867.182678 303.412140,872.403198 296.264160,871.058716 
	C290.253571,869.928162 284.621033,872.956177 278.659912,873.445068 
	C273.597290,873.860291 268.678833,875.308594 263.523865,874.044800 
	C262.426758,873.775818 261.035339,873.656067 260.062561,874.094910 
	C250.928268,878.215698 242.133072,874.075500 233.151657,873.402710 
	C222.285080,872.588562 212.223572,867.443787 200.887161,868.707520 
	C195.101334,869.352478 188.908310,866.641541 184.799225,862.022278 
	C180.056381,856.690491 173.810577,855.646912 167.562042,853.761475 
	C161.188080,851.838074 155.789139,847.720581 151.433929,842.682312 
	C147.137939,837.712585 143.857254,831.774963 138.722000,827.627930 
	C133.876495,823.714844 133.007446,817.765564 130.298355,812.853210 
	C127.319252,807.451355 127.623871,800.417969 122.616814,795.711792 
	C122.003014,795.134827 122.133865,793.818787 121.788193,792.894348 
	C120.258560,788.803711 120.616592,783.374146 114.444801,782.505554 
	C114.040726,782.448669 113.761681,781.503357 113.424469,780.971313 
	C118.495003,778.064819 111.982224,774.147156 114.300568,770.896606 
	C115.117317,769.751404 113.991287,769.247925 112.916649,768.960022 
	C110.668976,768.357849 110.750084,767.231445 112.323639,765.859436 
	C114.012344,764.386902 114.786911,763.075684 112.028847,761.830078 
	C109.417091,760.650635 109.725693,758.847168 111.445702,757.092102 
	C113.372406,755.126160 113.717621,753.037292 113.169174,750.290466 
	C112.342911,746.152283 113.232880,742.260864 117.368790,740.058899 
	C118.880547,739.254089 118.976402,738.119324 118.992203,736.380676 
	C119.298370,735.868164 119.551979,735.673767 119.874649,735.202271 
	C123.904823,729.846191 128.929398,726.152161 135.120407,723.821655 
	C136.562897,729.161987 132.604401,731.732239 129.846588,734.746277 
	C123.379440,741.814148 118.789268,749.510681 120.230652,759.942200 
	C120.358376,761.558411 120.850822,762.626160 121.914047,763.849670 
	C129.797592,779.034180 136.861511,794.069275 141.083954,810.456543 
	C143.273315,818.953552 147.249786,826.976196 154.866577,832.880676 
	C164.106430,840.043152 174.108932,845.833618 183.849915,852.136475 
	C193.374390,858.299316 203.483170,860.521179 213.982330,862.825256 
	C221.574829,864.491577 229.303894,865.809875 236.522247,868.809143 
	C242.150848,871.147888 247.675217,870.358704 253.085449,868.673828 
	C256.395935,867.642822 259.611145,867.201050 262.884399,867.914551 
	C272.891693,870.095703 282.653961,868.724731 292.332031,866.123535 
	C299.223907,864.271118 305.991821,861.923157 313.152466,861.219849 
	C314.493652,861.088074 315.731506,860.715820 316.874176,859.927917 
	C324.144226,854.915100 332.139801,851.638611 340.634949,849.193481 
	C347.639282,847.177490 354.136597,843.549805 361.280640,841.818665 
	C366.437256,840.569092 371.580536,839.300232 376.513794,837.299438 
	C377.780609,836.785583 379.103516,836.711914 380.742737,837.453003 
	C382.397644,840.178284 380.752960,841.388184 378.857239,842.166016 
	C376.714325,843.045288 374.759460,844.197144 372.453613,845.593628 
	C371.245697,845.956482 370.444366,845.887085 369.343109,845.254883 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M120.761459,945.764771 
	C121.011627,954.792175 122.118958,955.516968 130.477020,952.461060 
	C132.012131,951.899780 133.690796,951.604431 135.325989,951.475525 
	C137.380630,951.313660 139.302338,951.791138 140.367233,953.863342 
	C141.100677,955.290588 141.258347,956.757263 140.223694,958.089539 
	C138.870071,959.832520 137.393433,961.467834 134.818665,960.772339 
	C133.719818,960.475647 132.671097,959.758179 131.246460,960.751099 
	C127.837769,961.863464 124.886238,963.251038 121.387573,963.085388 
	C114.886276,962.777527 113.444237,961.593201 113.000099,954.406494 
	C111.681046,951.306396 113.642143,948.027710 111.240822,945.242188 
	C108.208015,943.408142 105.145653,944.162781 102.191841,943.999512 
	C95.988037,943.656372 93.649384,941.891418 91.853416,936.113342 
	C91.298706,934.328674 90.602509,932.404541 88.901649,931.646118 
	C84.395729,929.637085 83.046432,925.771973 81.931717,921.460876 
	C80.625862,916.410706 80.981972,915.620483 86.032646,914.994263 
	C86.843994,914.893738 87.641518,914.681702 88.723320,914.260620 
	C89.546387,914.180298 90.091568,914.360596 90.818375,914.770447 
	C93.066994,917.112549 91.635498,919.365479 91.278763,921.584900 
	C90.480858,926.548584 91.748184,927.390442 96.750015,927.012451 
	C103.022400,926.538269 109.397995,927.152100 115.586021,923.955383 
	C113.820618,922.535950 110.581009,921.906311 111.778427,918.267395 
	C113.347496,917.454590 113.696182,915.974854 114.656914,915.121155 
	C117.082672,912.965698 119.824951,911.887695 122.695435,914.286987 
	C124.468140,915.768860 125.865005,916.601624 127.592339,914.196777 
	C128.626694,912.756653 130.307587,912.568115 131.302765,914.232178 
	C132.306519,915.910583 130.602692,916.288391 129.534271,917.072205 
	C124.747314,920.584229 125.725494,925.361084 129.945557,929.355408 
	C131.838593,931.147156 131.025589,936.192505 130.668579,939.682373 
	C130.582138,940.527527 127.702888,939.068298 126.058533,939.062073 
	C124.078102,939.054565 122.343292,939.291382 124.317963,941.770569 
	C127.350151,945.577576 124.616051,945.806885 121.377823,945.998779 
	C119.767799,945.021851 119.816399,943.403564 119.016983,941.333252 
	C118.184311,943.883240 119.802750,944.514343 120.761459,945.764771 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M218.000000,1063.430664 
	C222.148926,1060.454102 223.763748,1062.149902 224.020416,1066.478027 
	C224.269318,1070.674927 226.787384,1072.534668 230.750122,1071.779297 
	C233.288864,1071.295166 234.270538,1071.894287 233.975113,1074.357910 
	C233.639145,1077.159790 235.348709,1079.086792 236.745911,1081.102417 
	C238.114731,1083.077148 239.181915,1085.071289 239.002029,1087.549316 
	C238.771133,1090.730225 240.239517,1092.307495 243.428757,1091.975952 
	C247.561890,1091.546265 250.652191,1092.672119 251.916306,1097.029663 
	C252.086151,1097.614990 252.676620,1098.593994 252.958237,1098.555664 
	C259.198669,1097.703125 255.384674,1101.357178 255.081985,1103.319336 
	C254.829498,1104.956055 254.374344,1106.563232 253.975235,1108.175293 
	C252.463013,1114.283569 252.385254,1114.331665 247.672821,1110.217896 
	C246.251877,1108.977539 244.646774,1108.999023 242.985565,1108.994507 
	C239.604614,1108.985229 238.674103,1107.380737 239.387070,1104.145874 
	C239.974915,1101.478882 241.448364,1098.764526 239.947876,1095.951294 
	C239.282867,1094.704346 238.937363,1092.822388 236.890274,1093.320190 
	C235.323563,1093.700806 234.272842,1094.926636 234.150131,1096.535889 
	C234.025284,1098.173340 233.930908,1099.941406 234.409943,1101.468262 
	C235.275208,1104.226196 235.027618,1106.669800 234.593262,1109.562378 
	C233.818527,1114.721558 235.853455,1119.491577 240.926468,1122.657227 
	C238.528137,1123.119385 236.057098,1122.792969 233.594086,1123.591187 
	C231.468155,1124.279907 229.397507,1123.378296 228.529266,1121.463013 
	C227.314499,1118.783325 230.117401,1119.319458 231.355637,1118.643799 
	C232.143738,1118.213745 232.719818,1117.605835 232.513840,1116.678345 
	C232.319000,1115.800781 231.599319,1115.319580 230.736191,1115.156860 
	C229.761093,1114.973022 228.692032,1115.054932 227.803772,1114.685181 
	C224.664749,1113.378174 224.609467,1110.549072 225.147461,1107.920166 
	C225.801071,1104.726196 227.888748,1106.662720 229.401566,1107.210571 
	C230.601669,1107.645142 231.812469,1107.969238 232.431641,1106.646851 
	C233.576309,1104.202026 233.597229,1100.987427 231.585419,1099.718262 
	C227.228470,1096.969604 225.991196,1094.893066 229.464722,1090.144043 
	C231.723969,1087.055054 226.770203,1080.298340 222.645889,1080.056885 
	C219.903214,1079.896484 217.259766,1078.719971 214.338989,1079.816284 
	C212.241287,1080.603638 210.943497,1079.300537 211.004150,1076.879395 
	C211.062912,1074.533813 211.856171,1072.843140 214.472214,1073.156738 
	C216.725571,1073.427002 218.900894,1074.666504 221.789597,1073.200317 
	C217.687103,1071.077515 217.807053,1067.481689 218.000000,1063.430664 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M174.771393,1010.250977 
	C175.749908,1010.000000 176.499817,1010.000000 177.624664,1010.000000 
	C178.423203,1010.523132 178.950150,1010.664795 179.790100,1010.213989 
	C182.750046,1010.237610 184.121262,1013.360718 187.092758,1013.313171 
	C188.070480,1013.297546 188.823364,1014.343384 188.697800,1015.490540 
	C188.557007,1016.776733 187.490341,1016.972900 186.470291,1016.953064 
	C184.147675,1016.907959 181.793686,1016.980042 179.515747,1016.612305 
	C177.728653,1016.323792 176.491135,1016.471069 176.174759,1018.414734 
	C175.854034,1020.385010 176.748169,1021.703552 178.778671,1021.896667 
	C180.582245,1022.068298 182.345215,1022.073425 184.142609,1021.281982 
	C190.313004,1018.565063 197.041626,1023.589844 196.265610,1030.404297 
	C196.063477,1032.179443 196.443405,1033.670288 196.947723,1035.626465 
	C189.212265,1036.582153 181.480637,1034.366699 173.667542,1035.590332 
	C170.892258,1036.024902 169.520309,1037.276855 170.000198,1040.584473 
	C165.733627,1041.748169 163.122253,1040.057007 163.454559,1035.635742 
	C163.860657,1030.232300 162.253525,1025.456909 160.008728,1020.374207 
	C159.404816,1019.364563 159.482147,1018.693054 160.167542,1017.773987 
	C163.827621,1017.047302 167.275299,1017.089050 170.415817,1015.197998 
	C172.388962,1014.009888 173.655411,1012.496704 174.771393,1010.250977 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M143.643555,864.045898 
	C144.119232,866.968140 141.339676,868.330078 139.823410,869.818665 
	C135.571426,873.992920 134.067978,880.196289 129.027893,883.641357 
	C128.059311,884.303406 127.235672,885.197205 127.117783,886.450317 
	C126.576988,892.198608 124.748688,889.372314 122.638664,887.268188 
	C121.308670,885.941895 120.325760,883.100159 117.464165,885.034241 
	C115.260612,886.523621 112.474205,887.905457 113.665916,891.176392 
	C114.877426,894.501770 111.821617,895.889038 111.049423,898.644165 
	C110.555252,899.000000 110.110809,899.000000 109.333031,899.000000 
	C108.275627,898.331177 107.798340,897.520325 107.492905,896.579529 
	C106.636650,893.941895 106.254684,896.047974 105.546013,897.032349 
	C103.935776,898.478577 101.043854,899.067078 103.692200,902.146179 
	C104.139709,903.495361 104.068054,904.472046 103.243240,905.692993 
	C100.875992,905.874817 99.598625,904.076904 97.765129,903.354187 
	C95.072830,902.293030 94.542480,900.337280 95.588882,897.746643 
	C96.976746,894.310730 97.124924,890.834106 96.663498,887.103943 
	C96.021873,881.917053 100.659378,881.867310 103.913345,881.117004 
	C106.256783,880.576599 108.845116,881.146484 111.595764,880.061157 
	C113.584358,878.775696 114.303566,876.906921 115.729256,875.181274 
	C117.389183,875.000000 118.777763,875.000000 120.582916,875.000000 
	C122.107742,874.883423 123.211678,874.536377 124.664978,874.968750 
	C127.749908,874.578369 128.975739,872.876770 128.994431,870.196960 
	C129.024124,865.939880 131.527039,862.025940 130.541794,857.569519 
	C130.182098,855.942566 131.833969,855.958740 132.999237,856.275452 
	C134.523697,856.689636 134.756866,857.973206 134.999329,859.353882 
	C136.191772,866.143494 137.047226,866.606750 143.643555,864.045898 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M85.119049,778.067749 
	C86.200790,779.100708 87.758018,780.144104 87.605919,780.775879 
	C86.119240,786.950745 91.082268,788.810730 94.612892,791.552429 
	C97.639503,793.902649 99.347717,796.315979 96.156609,799.740417 
	C94.874901,801.115845 95.774567,802.889282 95.396339,804.348450 
	C94.299316,804.804626 93.662621,804.197388 93.140816,803.593506 
	C91.017860,801.136353 89.315376,800.463867 88.217957,804.417847 
	C87.920410,805.489929 87.282982,806.731506 85.820335,806.535583 
	C84.340591,806.337402 83.259377,805.142029 83.255653,803.849609 
	C83.248405,801.330383 81.331818,797.987427 85.585083,796.825623 
	C86.304573,796.629089 86.736397,796.086304 86.643494,795.370483 
	C86.513260,794.367004 85.672066,794.012024 84.817108,794.074524 
	C81.525673,794.315369 81.027779,792.902039 81.516022,789.852234 
	C81.825043,787.922058 80.012451,786.336731 78.282143,785.143188 
	C72.295578,781.013733 72.099411,777.899414 77.264542,773.077026 
	C78.322792,772.088928 79.175095,770.748779 79.761436,769.412537 
	C81.234154,766.056335 83.866646,767.409058 85.635361,768.343445 
	C88.234238,769.716431 86.672058,771.629700 85.305222,773.179199 
	C84.021675,774.634277 81.925438,775.991394 85.119049,778.067749 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M75.966812,915.003540 
	C71.281013,917.127563 70.636345,922.511353 66.595406,925.050476 
	C65.639099,925.651428 67.796509,928.125305 68.984825,929.514771 
	C69.982986,930.682068 72.073227,931.990234 70.001678,933.544434 
	C68.434669,934.720154 66.742950,933.618225 65.998924,931.865173 
	C64.769043,928.967224 63.226826,926.867493 59.638321,926.511963 
	C57.751366,926.325073 56.939728,924.264648 57.037010,922.199951 
	C57.131989,920.184204 58.217339,919.267212 60.138874,918.967163 
	C63.969246,918.369202 67.396500,917.041443 70.113724,914.011902 
	C71.735603,912.203613 74.083122,911.681519 76.545540,912.022522 
	C77.368988,912.136536 78.407799,912.136169 78.408478,913.200073 
	C78.409233,914.393616 77.349083,914.740234 75.966812,915.003540 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M191.524506,1233.889526 
	C188.750702,1234.093384 186.343216,1234.019409 184.983978,1231.684570 
	C183.371902,1228.915283 183.257584,1225.797119 186.558487,1224.580688 
	C189.136703,1223.630615 191.761124,1222.684937 194.367645,1221.747314 
	C196.655670,1220.924194 198.880157,1221.505737 200.251053,1223.154785 
	C202.106064,1225.386353 199.004150,1225.948975 198.280716,1227.359375 
	C196.814133,1230.218506 195.046341,1232.662964 191.524506,1233.889526 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M193.587280,1159.404053 
	C188.922287,1160.488281 184.441818,1161.744629 179.948639,1161.791748 
	C175.920364,1161.834106 175.618576,1158.223145 176.110397,1155.150635 
	C176.714722,1151.375366 179.403427,1152.375122 181.860138,1153.092041 
	C183.988098,1153.712891 185.722229,1155.265381 188.403687,1154.443970 
	C193.965652,1152.739868 194.066101,1152.987549 193.587280,1159.404053 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M196.642914,1038.050537 
	C196.953705,1040.855103 198.122818,1042.489136 201.175232,1041.726318 
	C207.752838,1040.082764 207.918259,1040.174683 207.999420,1047.155640 
	C208.003220,1047.482666 207.999969,1047.809692 208.000000,1048.568359 
	C205.362640,1048.999756 202.723343,1049.061401 200.088547,1048.979126 
	C198.091217,1048.916748 195.540100,1049.785522 197.251236,1045.695312 
	C198.460022,1042.805786 195.336594,1043.180176 193.460648,1043.089111 
	C192.349014,1043.035278 190.685577,1044.673340 190.281250,1042.434204 
	C189.998611,1040.868896 191.510986,1040.377441 192.795517,1039.882080 
	C194.007812,1039.414551 195.126526,1038.704346 196.642914,1038.050537 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M247.259125,1123.263550 
	C248.846054,1120.991699 250.693130,1123.131470 252.557938,1123.035767 
	C255.440826,1122.887817 256.710968,1122.266113 254.862778,1119.222534 
	C252.976028,1116.115112 255.907440,1114.731201 257.617126,1115.278564 
	C261.599823,1116.553345 266.278656,1117.381104 267.991791,1122.629028 
	C268.000977,1123.166626 268.000732,1123.333374 268.000244,1123.750000 
	C266.517944,1123.374268 265.072937,1122.617798 263.536957,1122.182373 
	C262.857574,1121.989746 261.386047,1122.296021 261.302429,1122.631958 
	C259.051056,1131.675171 253.742615,1122.863159 250.211334,1124.491089 
	C249.592987,1124.776123 248.429428,1123.878540 247.259125,1123.263550 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M170.000305,1041.000000 
	C175.555161,1042.005493 178.515076,1045.258423 177.348831,1049.280762 
	C176.778534,1051.247803 176.615952,1053.794189 173.707001,1053.930054 
	C171.951981,1054.012085 169.800095,1054.584839 168.871429,1052.445801 
	C167.854767,1050.103882 166.121887,1047.741333 167.721954,1044.977905 
	C168.375885,1043.848511 169.169632,1042.800171 169.949585,1041.357422 
	C169.999390,1041.000000 170.000000,1041.000000 170.000305,1041.000000 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M103.374100,869.370483 
	C101.134354,870.127563 99.282387,870.350952 98.321602,868.179504 
	C97.541634,866.416809 98.422951,864.932678 99.760582,863.772217 
	C101.096039,862.613708 101.959206,860.834045 104.439720,860.341919 
	C103.077454,865.808960 105.374649,866.829651 109.967041,865.122803 
	C111.014366,864.733521 112.410538,864.996094 112.754578,866.473511 
	C113.158081,868.206177 111.924255,868.520203 110.594307,869.120300 
	C108.241241,870.182312 106.005180,868.673523 103.374100,869.370483 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M184.229645,1056.166992 
	C185.477875,1054.282715 186.862869,1053.273071 188.507172,1054.731323 
	C189.671570,1055.764038 191.117004,1057.036499 190.725632,1058.915527 
	C190.352081,1060.708984 188.991989,1061.925171 187.209641,1061.878052 
	C184.320892,1061.801758 182.164200,1062.664673 181.210388,1065.806030 
	C176.646820,1060.533447 180.852081,1058.677979 184.229645,1056.166992 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M80.999710,740.375000 
	C84.778427,737.690796 85.112694,743.777283 88.635300,742.991333 
	C89.160561,744.233154 88.763893,745.908691 91.005653,745.458923 
	C92.231140,745.212891 93.745575,744.314392 94.434151,746.039551 
	C94.885086,747.169312 93.980522,748.276550 93.148285,749.072998 
	C91.907471,750.260498 90.299324,750.348022 89.076897,749.295166 
	C87.222336,747.697876 85.307915,746.652283 82.403809,747.000244 
	C80.687637,746.242615 80.178673,745.134766 80.769333,743.323975 
	C80.999901,742.250000 80.999794,741.500000 80.999710,740.375000 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M135.372482,724.275146 
	C130.240005,727.636963 125.472816,731.264160 120.350555,734.942017 
	C120.102829,731.976257 121.094620,729.760071 124.291046,728.604797 
	C125.859169,728.038086 127.211838,726.489502 128.318710,725.109802 
	C130.893356,721.900513 134.007339,720.292908 138.580307,721.001770 
	C139.726517,721.712463 139.815140,722.572083 139.371933,723.894653 
	C138.061798,725.078003 136.923096,725.120361 135.372482,724.275146 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M161.277176,1017.716919 
	C160.686707,1018.471619 160.373413,1018.943176 160.030060,1019.707397 
	C157.081604,1020.194397 154.978531,1017.819763 152.075836,1017.565796 
	C150.519562,1017.429626 149.816818,1015.311646 150.376694,1013.484009 
	C150.885910,1011.821594 151.833237,1011.198242 153.738770,1011.671143 
	C155.462845,1012.099121 157.338928,1011.914551 159.574005,1012.000000 
	C160.651733,1012.448059 161.264130,1012.936218 161.820557,1014.002808 
	C162.232803,1015.445740 162.130905,1016.472107 161.277176,1017.716919 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M80.635910,742.952759 
	C81.305984,744.104553 81.611977,745.209045 81.959137,746.656799 
	C79.833572,749.195007 72.114876,749.655334 70.289467,747.710693 
	C68.623375,745.935730 69.433060,744.657104 71.256676,743.947998 
	C74.140274,742.826538 77.117813,741.945862 80.635910,742.952759 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M41.790070,1020.879761 
	C41.911087,1023.398315 41.497055,1025.852539 38.980717,1026.708862 
	C36.196079,1027.656494 34.000443,1025.331543 31.800558,1024.228149 
	C30.129362,1023.389771 31.152565,1021.307495 32.691471,1020.230408 
	C35.115719,1018.533691 37.663624,1018.862915 40.613625,1019.944641 
	C41.126160,1020.304565 41.320000,1020.557678 41.790070,1020.879761 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M129.346283,719.953735 
	C129.301346,718.575378 129.602692,717.150818 130.183868,715.114746 
	C134.112961,714.252563 137.973801,715.259094 141.542709,712.546509 
	C142.293945,712.281433 142.743225,712.218750 143.537445,712.265381 
	C144.942673,712.730835 146.000351,712.787598 147.383118,712.100098 
	C148.751892,711.781982 149.741577,711.871338 151.005707,712.572266 
	C151.793839,713.521179 151.917343,714.296570 151.650604,715.504761 
	C151.026947,716.581116 150.333466,716.973450 149.168671,717.333008 
	C147.746109,717.434021 146.707062,717.610168 145.337357,717.189087 
	C139.803101,717.470459 134.635223,718.095825 129.346283,719.953735 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M152.893753,1149.759521 
	C152.671265,1151.740112 154.891190,1151.629150 155.243866,1153.226929 
	C152.980637,1153.797241 149.639984,1155.991089 148.809860,1154.084717 
	C147.985199,1152.190796 143.461456,1148.964111 148.706360,1146.119629 
	C149.747284,1146.130005 152.135010,1144.007568 150.986496,1146.699463 
	C149.863663,1149.331177 152.038422,1148.684570 152.893753,1149.759521 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M175.597534,1210.517090 
	C175.923233,1211.316650 176.000381,1211.822632 176.221283,1212.255371 
	C177.294785,1214.357666 176.893845,1216.087891 174.916122,1217.366211 
	C173.595871,1218.219604 172.344604,1217.938599 171.478973,1216.669067 
	C170.343674,1215.004150 169.329071,1213.146973 170.612091,1211.189331 
	C171.778259,1209.409790 173.320572,1208.125610 175.597534,1210.517090 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M147.785126,716.232056 
	C148.757706,716.006226 149.504944,716.000610 150.625793,715.990112 
	C151.185165,716.152954 151.370926,716.320618 151.720612,716.827026 
	C152.008682,717.656189 152.038879,718.152405 151.874374,719.013428 
	C150.479980,721.691284 147.792603,722.074097 145.600189,723.781616 
	C143.893616,724.590515 142.514786,725.242432 140.554840,725.009644 
	C139.952042,724.812805 139.724503,724.695862 139.246338,724.288208 
	C138.998184,723.248962 139.000626,722.500366 139.004028,721.377563 
	C140.514984,720.500732 142.024979,719.998169 143.769180,719.250244 
	C145.203888,718.174927 146.611008,717.632935 147.785126,716.232056 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M93.794830,735.315491 
	C95.745102,735.326721 97.489281,735.653442 99.616776,735.990112 
	C99.831024,738.689392 97.384384,740.189392 96.578041,742.725403 
	C95.818352,745.114563 94.133675,741.875000 92.344025,742.046143 
	C91.614395,740.124512 93.182564,739.092590 93.932709,737.339233 
	C93.620743,736.616272 93.483894,736.159912 93.794830,735.315491 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M152.787476,1173.269653 
	C153.758575,1173.003296 154.504532,1173.001953 155.623352,1172.990723 
	C156.375931,1172.640503 156.799301,1172.367798 157.642410,1172.108276 
	C158.535858,1172.092651 159.006454,1172.260254 159.697418,1172.828003 
	C159.960876,1173.795776 159.957779,1174.493164 159.945419,1175.539307 
	C159.480515,1176.382812 159.302994,1176.939331 159.761444,1177.829102 
	C159.792664,1178.600098 159.619217,1179.134033 159.221512,1179.845825 
	C158.602554,1180.360229 158.147034,1180.503540 157.318787,1180.246094 
	C156.561478,1180.021240 156.116165,1180.004150 155.336639,1179.983887 
	C154.422821,1179.805176 153.843185,1179.630005 153.059937,1179.224365 
	C152.668259,1177.179077 152.350021,1175.371094 152.787476,1173.269653 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M312.101562,1250.369751 
	C314.384155,1246.911865 317.168243,1246.042725 320.771973,1248.729980 
	C318.899048,1253.786133 315.574158,1252.379517 312.101562,1250.369751 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M151.686371,1186.836548 
	C153.108170,1188.295166 154.253540,1189.512085 155.677795,1190.871948 
	C156.300323,1193.460938 154.758926,1194.312012 152.423386,1194.798340 
	C151.588882,1194.547363 151.099533,1194.281128 150.324860,1193.986694 
	C149.557968,1193.574463 148.928055,1193.645752 148.203842,1193.199707 
	C149.629974,1191.591919 151.641724,1190.365845 150.105789,1187.389160 
	C150.460876,1186.715942 150.902924,1186.562134 151.686371,1186.836548 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M161.369324,1014.294067 
	C160.686722,1013.528381 160.373428,1013.056763 160.030075,1012.292603 
	C161.232590,1011.187683 162.465179,1010.375366 163.848877,1009.281494 
	C165.388885,1009.000000 166.777786,1009.000000 168.583344,1009.000000 
	C169.701981,1014.305481 167.473328,1016.020569 161.369324,1014.294067 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M115.933014,874.670898 
	C115.854538,877.254272 116.800285,880.208252 112.400742,879.996643 
	C112.584938,878.183289 108.698830,876.779968 111.441986,874.850647 
	C112.716667,873.954163 114.084549,871.691345 115.933014,874.670898 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M121.386200,863.860474 
	C121.899895,865.780396 122.747261,867.531860 120.832161,868.601013 
	C119.833992,869.158203 118.716270,868.439514 118.478683,867.447510 
	C118.033653,865.589355 116.327652,863.386169 117.645988,861.881104 
	C119.286011,860.008606 120.375137,862.105469 121.386200,863.860474 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M119.406113,726.999878 
	C119.945572,723.805481 121.880379,721.647522 125.635468,721.007690 
	C126.339058,725.476929 124.142021,727.338074 119.406113,726.999878 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M44.272808,772.971558 
	C42.304489,772.635437 40.754910,772.128906 41.723061,770.068665 
	C42.289810,768.862732 43.403229,767.917725 44.948971,768.245972 
	C46.357529,768.545044 47.463074,769.532715 47.665852,770.874573 
	C48.024696,773.249023 46.082710,772.870483 44.272808,772.971558 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M104.777061,897.348999 
	C105.397942,895.199707 104.007050,891.973511 106.109138,891.849182 
	C108.786934,891.690857 108.821892,894.920166 108.992447,897.203735 
	C109.016937,897.531555 108.998260,897.862610 108.999626,898.596069 
	C108.999695,899.000000 108.499695,899.000000 108.249695,899.000000 
	C107.305672,899.502380 106.550270,899.778320 105.309006,899.443237 
	C104.601555,898.789124 104.473495,898.269287 104.777061,897.348999 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M111.000412,945.442444 
	C116.512611,946.394958 113.267731,950.429443 113.267120,953.702759 
	C109.111313,952.088989 111.786125,948.560547 111.000412,945.442444 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M65.348015,936.568115 
	C67.730690,936.528625 69.246826,937.312866 68.738998,939.685242 
	C68.429474,941.131226 66.951248,942.024292 65.757980,941.505249 
	C63.385395,940.473267 63.622482,938.586365 65.348015,936.568115 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M154.242767,1168.275391 
	C152.646103,1167.723877 151.650192,1167.281006 153.710876,1165.979248 
	C155.571579,1166.007568 156.922028,1165.787354 157.057587,1163.417969 
	C157.209503,1162.905518 157.326416,1162.753906 157.723328,1162.537231 
	C158.188568,1162.521240 158.373840,1162.570312 158.751984,1162.832397 
	C159.289490,1164.776611 159.634125,1166.507690 159.989380,1168.619385 
	C159.687164,1169.326904 159.309799,1169.539062 158.536835,1169.634521 
	C156.949814,1169.307373 155.634171,1169.175171 154.242767,1168.275391 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M100.123260,877.327271 
	C98.820129,874.678467 100.262024,873.577026 102.260643,873.498230 
	C103.575813,873.446411 104.893585,874.827942 104.673607,876.189209 
	C104.219276,879.000916 102.093559,877.870422 100.123260,877.327271 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M352.305725,1263.217529 
	C349.911987,1261.154663 352.799347,1258.589966 351.042480,1256.345459 
	C351.512085,1255.444214 352.024170,1254.888184 352.769043,1254.166138 
	C354.924744,1256.512329 353.608276,1259.422729 354.000000,1262.561523 
	C353.602264,1263.349976 353.140228,1263.494995 352.305725,1263.217529 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M38.261757,897.451416 
	C36.752632,897.907959 35.048454,898.607605 34.640221,896.993469 
	C34.190350,895.214661 35.593605,893.792664 37.029503,891.983887 
	C38.101189,893.929626 39.061478,895.311951 38.261757,897.451416 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M215.879395,1251.231689 
	C217.380325,1253.109863 216.444153,1253.778442 214.841751,1253.919434 
	C211.652878,1254.199951 211.906006,1251.770386 211.359406,1249.813599 
	C213.368103,1249.025635 214.143600,1250.996460 215.879395,1251.231689 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M193.624634,1049.004395 
	C192.856873,1050.478882 192.144852,1052.380615 190.509018,1050.995117 
	C189.468170,1050.113525 189.470230,1047.895020 190.510696,1047.014038 
	C192.145126,1045.630371 192.863892,1047.520264 193.624634,1049.004395 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M66.265617,951.726562 
	C64.747047,950.224548 64.636024,948.972839 66.539284,948.234741 
	C67.314468,947.934204 68.270508,948.196533 68.582817,949.035522 
	C69.235863,950.789795 68.634628,951.855408 66.265617,951.726562 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M67.806458,768.347656 
	C65.499962,766.419556 67.053894,766.132446 68.478874,766.066956 
	C69.515457,766.019287 71.288696,765.566345 71.316956,767.059082 
	C71.357262,769.188171 69.631882,768.977356 67.806458,768.347656 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M103.373672,905.990601 
	C103.000000,904.933289 103.000000,903.866638 103.000000,902.399963 
	C103.314171,901.578064 103.721466,901.285767 104.601837,901.072998 
	C105.501549,901.060669 105.977966,901.223755 106.705109,901.756531 
	C107.074928,904.274353 106.386353,905.924255 103.373672,905.990601 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M111.356918,731.046753 
	C112.108414,728.679626 114.236237,729.022583 116.611893,729.000000 
	C116.453423,732.767761 114.076111,731.912048 111.356918,731.046753 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M212.062851,1057.330811 
	C214.082153,1057.443359 216.138062,1057.923706 216.951843,1060.656494 
	C214.875534,1060.615723 213.263580,1059.483887 212.062851,1057.330811 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M159.193939,1162.814575 
	C158.944855,1163.045532 158.471848,1163.016602 158.235123,1163.006348 
	C157.749695,1162.770508 157.469452,1162.595215 157.077408,1162.232178 
	C157.251480,1160.730835 157.478424,1159.057251 159.205292,1159.669067 
	C160.420914,1160.099731 160.035736,1161.534790 159.193939,1162.814575 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M186.912415,1242.534668 
	C187.590744,1240.008057 188.971466,1241.164429 190.631638,1241.625854 
	C189.595825,1243.334961 188.353302,1242.966553 186.912415,1242.534668 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M158.340698,1147.990845 
	C158.297287,1146.852539 157.322540,1145.242065 159.090698,1144.685303 
	C159.364212,1144.599243 160.112122,1145.396606 160.344208,1145.917603 
	C161.075150,1147.557861 159.975220,1147.883301 158.340698,1147.990845 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M124.854362,874.726318 
	C123.956345,876.186584 122.753990,876.210938 121.224365,875.241943 
	C121.998428,873.209229 123.153740,872.477905 124.854362,874.726318 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M142.183517,969.321167 
	C143.671341,969.558472 144.136246,970.241882 143.378052,971.299744 
	C143.177856,971.578979 142.452759,971.766174 142.132523,971.624695 
	C140.913544,971.086304 141.058823,970.252319 142.183517,969.321167 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M157.709808,1169.330688 
	C158.446808,1168.997559 158.890564,1169.005493 159.667160,1169.006714 
	C159.996094,1170.091187 159.992203,1171.182495 159.976135,1172.686035 
	C159.527390,1173.077271 159.090775,1173.056274 158.326172,1173.024902 
	C157.180222,1172.008911 156.879791,1170.913208 157.709808,1169.330688 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M147.691650,716.040405 
	C147.828690,718.012329 146.693634,718.960510 144.364136,718.996643 
	C144.259979,718.507629 144.516556,718.010315 144.886871,717.257080 
	C145.791336,716.690430 146.582092,716.379639 147.691650,716.040405 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M149.893951,995.424561 
	C151.009186,996.255066 150.508972,996.894348 149.810822,997.330627 
	C149.531830,997.505005 148.706436,997.387390 148.621368,997.190308 
	C148.225662,996.273987 148.840286,995.764221 149.893951,995.424561 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M107.032135,901.682617 
	C106.554848,902.000000 106.110611,902.000000 105.333191,902.000000 
	C104.399452,901.316345 104.236427,900.538818 104.750305,899.334839 
	C105.750389,899.000000 106.500160,899.000000 107.624817,899.000000 
	C107.688194,899.788391 107.376694,900.576782 107.032135,901.682617 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M159.042053,1182.267334 
	C159.481750,1182.290039 159.963516,1182.579956 160.445297,1182.869873 
	C160.279312,1183.064453 160.113312,1183.259033 159.947311,1183.453613 
	C159.659576,1183.147339 159.371857,1182.840942 159.042053,1182.267334 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M157.060654,1180.346436 
	C157.449097,1180.027710 157.891373,1180.017090 158.665466,1180.015137 
	C158.998215,1180.462769 158.999191,1180.901855 159.000076,1181.670410 
	C158.125946,1181.895752 157.369614,1181.626465 157.060654,1180.346436 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M159.974579,1177.703369 
	C158.803345,1177.673218 157.321579,1177.375366 159.647171,1175.958008 
	C159.951813,1176.372314 159.967453,1176.856445 159.974579,1177.703369 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M73.000427,739.750000 
	C72.298431,739.847534 71.418442,739.866699 71.579315,738.785522 
	C71.595612,738.676025 72.287987,738.667114 72.834351,738.805725 
	C73.001671,739.166687 73.001259,739.333313 73.000427,739.750000 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M86.465958,812.955811 
	C86.824074,812.305847 87.183578,812.296448 87.500626,812.763000 
	C87.566933,812.860596 87.563950,813.086304 87.494675,813.181152 
	C87.163177,813.634460 86.804039,813.618042 86.465958,812.955811 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M156.761749,1162.019531 
	C157.301743,1162.121826 157.555634,1162.315796 157.878342,1162.786499 
	C157.998398,1162.996216 157.545120,1163.034668 157.318848,1163.045898 
	C156.676163,1162.891968 156.259750,1162.726807 155.843338,1162.561646 
	C156.071182,1162.389160 156.299026,1162.216553 156.761749,1162.019531 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M114.181633,86.935234 
	C114.254883,84.346321 114.479126,81.681267 117.093758,81.573936 
	C119.446754,81.477341 119.141457,84.552986 120.198639,85.898018 
	C123.418617,89.994736 123.228127,96.129555 128.359863,99.036736 
	C129.742325,99.819908 131.085007,101.545166 130.326569,103.218857 
	C129.679825,104.646111 127.993530,103.591347 126.715698,103.483391 
	C123.448349,103.207306 123.380241,106.053818 122.451088,108.068825 
	C122.058891,108.919350 122.205597,110.257538 120.978065,110.447586 
	C119.599884,110.660973 118.938454,109.474998 118.342751,108.516602 
	C116.832962,106.087585 115.805595,103.541588 115.960716,100.538712 
	C116.192383,96.053917 116.285454,91.539787 114.181633,86.935234 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M112.517555,115.400429 
	C113.985466,116.654572 115.746964,117.395576 115.520233,119.373001 
	C115.466682,119.840027 114.649902,120.569649 114.173706,120.578003 
	C112.020920,120.615746 109.907799,119.340378 109.188316,117.802933 
	C107.871971,114.990028 110.613312,115.483856 112.517555,115.400429 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M148.109772,52.976112 
	C148.604828,51.248447 149.531693,50.618301 150.518036,51.929329 
	C151.276062,52.936874 151.523163,54.656895 150.137512,55.286625 
	C148.738724,55.922321 148.346069,54.465660 148.109772,52.976112 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M147.368530,23.814947 
	C145.971390,22.281580 147.132095,22.184025 148.096420,22.143763 
	C148.712570,22.118038 149.552414,22.361391 149.242065,23.190022 
	C149.100494,23.568043 148.217773,23.668476 147.368530,23.814947 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M143.184875,18.589958 
	C143.325684,17.742449 143.421814,16.860874 143.796677,16.719765 
	C144.610062,16.413570 144.873779,17.239079 144.847992,17.856396 
	C144.808151,18.809414 144.719543,19.973690 143.184875,18.589958 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M111.232269,500.728271 
	C110.833893,501.000153 110.667175,501.000275 110.250381,501.000824 
	C103.536346,501.021393 100.101624,496.101318 101.047935,488.093811 
	C98.367432,490.647034 96.680267,494.046631 91.665283,493.296356 
	C86.404015,492.509247 86.155663,488.851318 85.048943,483.999786 
	C83.658737,487.848450 82.694489,490.648407 80.181351,492.827393 
	C82.119820,494.146576 84.172241,494.906555 85.683899,496.366791 
	C88.473999,499.061981 87.639908,503.211212 84.012360,504.281586 
	C80.322685,505.370300 76.547005,506.138458 72.865669,504.045837 
	C70.855896,502.903381 68.835739,502.780457 66.679123,503.858673 
	C63.379974,505.508179 61.460644,504.109406 61.170368,500.704803 
	C60.955544,498.185120 60.545250,496.810059 57.664165,498.201569 
	C55.605907,499.195648 53.360344,499.542297 51.772514,497.415070 
	C50.137730,495.224915 50.106434,492.665497 51.414810,490.334381 
	C52.726562,487.997223 55.017826,487.651520 57.517025,488.041290 
	C58.745659,488.232941 59.984241,489.249908 61.274338,488.176086 
	C61.545486,486.689697 60.203648,486.236389 59.538673,485.377686 
	C58.914841,484.571991 57.942200,483.215607 58.548038,482.777710 
	C61.611469,480.563812 62.248791,476.256897 65.610695,474.537292 
	C67.861420,473.386078 65.199417,473.167847 65.244316,472.249939 
	C64.873138,471.695435 64.679169,471.442261 64.208817,471.120239 
	C63.082195,466.671692 64.735703,463.929626 68.816872,463.993927 
	C72.199036,464.047211 73.754730,463.942474 72.435249,459.770874 
	C71.275421,456.103973 73.449982,454.958862 78.697998,455.993011 
	C79.298195,455.243103 79.225723,454.691925 78.641647,453.912476 
	C78.091980,453.164795 77.750130,452.652679 77.127289,451.970947 
	C76.140594,451.041382 75.424568,451.233368 74.461533,452.087769 
	C69.431221,454.797974 69.079735,454.635712 66.421768,448.598816 
	C65.993698,448.201660 65.818527,448.047180 65.405876,447.636597 
	C61.682869,444.252350 61.908455,439.556000 60.006935,435.419525 
	C59.064255,434.376434 58.393612,433.516754 57.142258,432.795654 
	C56.725273,432.415649 56.573624,432.244751 56.248653,431.774109 
	C55.898422,431.043396 55.763618,430.599762 55.625015,429.795654 
	C55.338924,425.828979 54.252129,422.192657 55.890594,418.612518 
	C56.340607,417.629211 55.997169,416.652679 55.451271,415.763458 
	C54.427906,414.096436 53.731873,412.357239 55.558483,410.841461 
	C57.406235,409.308197 60.033985,408.903900 61.623566,410.274078 
	C64.921440,413.116760 69.022568,412.761902 72.681953,414.037445 
	C73.767502,414.415833 74.964386,415.224060 75.923897,414.159882 
	C77.058311,412.901642 75.727058,412.041779 74.994247,411.255676 
	C72.806358,408.908600 72.885933,406.876007 76.460968,405.075256 
	C73.275116,404.929901 71.461792,406.265778 69.904678,407.686096 
	C66.550858,410.745422 63.696209,410.052460 60.809387,407.093689 
	C59.762592,406.020874 58.730038,404.936859 57.704494,403.844482 
	C56.967812,403.059814 56.091927,402.451996 55.206814,403.520630 
	C54.241386,404.686188 53.343014,406.395020 51.702774,404.842529 
	C49.846958,403.085938 48.775021,400.713654 49.319366,398.071686 
	C49.743343,396.013885 51.598480,395.712128 53.296070,395.918915 
	C56.029312,396.251801 57.735573,395.102783 58.922958,392.779510 
	C59.453163,391.742126 60.037380,390.466156 61.092365,390.105072 
	C64.830994,388.825592 65.688820,387.093231 63.463711,383.432220 
	C62.214058,381.376129 65.720688,377.481384 68.624054,377.341644 
	C71.827629,377.187408 73.172592,379.085938 72.939552,382.082306 
	C72.582031,386.679352 74.530396,390.390106 77.160141,393.883118 
	C76.902222,392.066345 74.803513,390.957001 75.256516,388.842926 
	C75.766357,386.463593 77.764107,385.943939 79.900894,385.027710 
	C82.479309,381.706573 85.408997,379.244263 86.566261,375.043549 
	C87.352417,372.189941 91.243164,370.690460 94.263443,368.754700 
	C95.573372,366.091797 93.273445,366.584076 91.970718,365.818054 
	C91.273567,365.442291 90.903236,365.178284 90.299362,364.650085 
	C89.319977,363.518066 88.423012,362.772156 87.735695,361.846130 
	C85.159050,358.374542 85.799881,356.667816 89.982880,355.722198 
	C91.106400,355.468201 93.309265,356.039001 92.693886,354.042206 
	C92.135101,352.229004 90.477211,353.683655 89.398903,354.127808 
	C88.011856,354.699066 86.672882,355.293365 84.788986,354.890320 
	C84.249886,354.655212 84.049110,354.528503 83.623535,354.125122 
	C82.562172,352.014404 82.761070,349.945923 81.952583,348.064575 
	C80.534203,344.763977 82.404839,343.622833 85.302994,344.083435 
	C90.443260,344.900421 95.121864,343.691345 99.859314,341.866608 
	C101.951004,341.061005 102.593277,340.368622 101.543770,338.368011 
	C99.617592,334.696350 99.462898,330.363770 97.040565,326.817444 
	C95.714905,324.876648 95.563934,322.416107 96.288750,320.058258 
	C97.244240,316.949982 94.707367,319.199371 93.881325,318.332794 
	C94.309105,320.131592 95.435806,321.461487 95.121086,323.170959 
	C94.792130,324.957794 94.014839,326.351257 92.103371,326.683075 
	C90.300522,326.996063 89.451454,325.840057 88.722794,324.374756 
	C85.026749,316.941956 85.005272,316.372955 89.034645,312.332153 
	C89.924751,311.777557 90.596375,311.555420 91.459152,310.958801 
	C93.266624,309.982300 94.124893,308.474518 95.401749,307.429871 
	C97.342384,305.842163 99.327484,305.443939 100.929703,307.805542 
	C102.954239,310.789642 106.363983,310.855194 108.862015,310.211975 
	C111.007774,309.659485 112.658752,307.141083 113.218582,304.136230 
	C113.954491,300.186310 115.074860,296.315643 117.209457,292.588013 
	C118.599236,290.161072 120.919670,285.933533 116.701866,282.844025 
	C115.445671,281.923889 115.073860,279.583099 115.989517,278.750946 
	C118.859428,276.142700 117.686722,273.147369 117.580536,270.171265 
	C117.486031,267.522980 118.966705,265.466919 120.568939,263.121033 
	C121.013344,261.904144 121.469193,261.057953 121.563019,259.713928 
	C121.686188,258.559174 121.913475,257.824127 122.588928,256.844543 
	C124.046120,255.705551 125.373375,255.586334 127.151505,255.895798 
	C128.023926,256.189728 128.468048,256.450409 129.126801,257.043518 
	C130.182007,258.053406 130.168427,259.651642 131.542892,259.721252 
	C132.909286,259.790405 132.827454,258.538422 133.052521,257.261414 
	C133.173477,256.686829 133.242569,256.458893 133.483444,255.912292 
	C134.465332,254.652939 135.445847,253.945847 136.934021,253.359848 
	C140.946350,253.334747 142.820648,255.977753 142.203934,258.815430 
	C140.723068,265.629242 145.869263,268.414917 149.377792,272.187592 
	C150.862442,273.783997 152.559158,275.099487 153.014893,277.368103 
	C154.345917,271.794403 156.850266,272.284058 161.435394,275.711517 
	C162.543259,274.135437 161.310577,271.510193 163.483871,270.554443 
	C165.779587,269.544891 167.163879,271.472107 168.824661,272.421814 
	C170.407806,273.327118 172.044662,272.999481 173.687241,272.779633 
	C175.340485,272.558319 176.999603,272.376709 178.650925,272.705505 
	C179.982468,272.970581 181.335342,273.353729 181.469849,275.027191 
	C181.576813,276.357697 180.748993,277.513947 179.619476,277.670349 
	C176.278168,278.133057 176.037369,279.913147 176.588837,282.766998 
	C176.900604,284.380310 175.560165,285.846039 173.993057,287.192139 
	C173.298767,287.613708 172.870255,287.784668 172.088928,287.973633 
	C170.877670,288.170258 170.006134,288.320862 168.948608,289.003784 
	C166.310486,290.263184 164.361694,292.470337 161.053543,291.579956 
	C156.653793,287.958130 153.236786,291.408417 149.903946,292.795837 
	C144.386200,295.092804 139.218155,298.427979 132.684326,295.723999 
	C134.766220,297.398621 137.306061,299.089478 135.427628,301.950714 
	C132.786880,305.973053 129.883194,301.713928 126.990776,301.752747 
	C127.796799,303.091431 129.127899,303.251678 130.384323,303.574036 
	C136.380203,305.112701 139.448181,308.987091 139.245636,314.999451 
	C139.188675,316.689911 139.574356,317.985596 141.113876,318.690430 
	C143.306824,319.694305 145.668716,319.350220 147.983826,319.357147 
	C151.389481,319.367340 151.269821,316.762756 151.663040,314.503113 
	C151.776764,313.849640 151.550934,314.648407 151.760391,314.015503 
	C153.022964,310.200287 158.418365,309.273224 165.415405,311.669250 
	C169.681900,313.130249 170.611740,315.263153 168.838913,319.641357 
	C168.582611,320.274292 168.408966,320.882965 168.556061,321.912231 
	C169.935242,324.703186 171.778610,326.911072 171.797607,329.886261 
	C171.806030,331.206879 172.014557,332.729065 170.481735,333.269287 
	C168.920258,333.819611 168.145889,332.533966 167.260544,331.074646 
	C165.943680,328.160278 164.695251,329.486176 164.087784,330.918610 
	C160.802597,338.665283 153.401169,335.780518 147.754120,337.209076 
	C145.618011,337.749481 144.104156,335.952393 144.339828,333.427490 
	C144.449127,332.256775 144.408096,331.069275 144.009918,329.979401 
	C143.490936,328.558868 144.119812,326.185883 141.769836,326.118408 
	C139.324463,326.048218 137.230621,327.230682 136.096222,329.527039 
	C135.047943,331.648987 136.796906,332.453644 138.166031,333.380768 
	C139.571808,334.332733 140.658676,335.599121 141.439041,337.077271 
	C142.438492,338.970428 143.898987,341.165131 141.196747,342.683777 
	C138.958755,343.941559 136.705109,343.455414 135.053009,341.215759 
	C133.967178,339.743805 133.997620,337.082306 131.169479,337.429199 
	C130.678986,337.489380 130.173981,337.958862 130.270370,338.444427 
	C130.736328,340.791840 133.122391,340.882507 134.552902,342.079895 
	C138.979477,345.785034 141.754715,343.743866 145.013870,340.289612 
	C148.650436,336.435303 151.610748,337.274017 153.942230,342.288513 
	C154.695724,343.909119 156.239319,345.206421 156.032593,347.578308 
	C155.484528,350.611450 152.779434,352.175049 152.423828,354.925354 
	C152.363770,355.389801 152.527374,355.891113 153.183273,356.209473 
	C154.266541,354.244324 153.635483,350.401886 157.837708,351.620270 
	C162.042206,352.839294 163.722794,357.263336 162.132690,363.287598 
	C163.620377,363.199829 163.630188,361.695618 164.715942,360.815643 
	C169.829208,360.748871 172.813690,364.786133 176.994827,366.386108 
	C178.332932,366.898132 178.250641,368.942261 178.001129,370.449127 
	C177.731613,372.076935 177.467041,373.694763 177.869171,375.720520 
	C177.932617,377.164490 177.611832,378.110657 176.671234,379.192444 
	C175.337723,380.130157 174.224991,380.183075 173.026993,379.747040 
	C174.255249,380.247345 175.234161,380.998322 175.532730,382.344910 
	C176.026566,384.572266 172.192429,389.135895 169.222015,389.887024 
	C166.335739,390.616882 163.266968,388.908051 163.081497,385.674103 
	C162.761047,380.086761 159.785583,376.529449 155.580551,373.461304 
	C153.607361,372.021545 151.829803,370.285126 150.975525,368.809601 
	C151.524567,369.689941 152.858292,370.979645 153.862305,372.496124 
	C155.178467,374.484100 155.575424,376.680328 153.280457,378.201050 
	C151.059555,379.672699 149.714188,378.096100 148.372589,376.387024 
	C143.898895,370.687958 143.483307,370.644928 135.935623,374.367462 
	C141.320160,372.738312 143.147293,375.473450 143.717575,379.843384 
	C144.030228,382.239075 145.945343,382.953918 147.897568,383.583588 
	C149.592987,384.130402 150.964981,385.031281 150.928879,387.041931 
	C150.884888,389.490204 149.279160,390.966125 147.379227,392.112427 
	C145.690247,393.131378 144.749481,391.877594 143.589096,390.819702 
	C140.468124,387.974518 135.384766,387.487885 131.672516,389.635315 
	C129.576477,390.847809 127.934349,391.022339 126.876045,388.455322 
	C126.416046,387.339600 125.615234,386.516632 124.707954,385.768616 
	C121.856796,383.417877 121.392456,381.019348 124.747818,378.509003 
	C122.561813,379.728668 120.436508,381.129547 118.224174,379.342072 
	C116.247009,377.744598 117.320328,375.692200 118.097855,373.853638 
	C118.576759,372.721283 118.392029,372.046936 117.215775,371.479462 
	C114.344589,370.094208 114.703880,367.501678 114.712303,364.974335 
	C111.598816,369.209625 115.636528,373.367706 115.386642,377.711548 
	C115.147797,381.863464 114.490631,386.730835 116.561440,392.268951 
	C117.674889,389.605347 116.951637,387.184052 119.245346,386.547852 
	C121.352150,385.963562 122.642052,387.478516 124.165092,388.412323 
	C125.864212,389.454132 127.732285,390.212921 129.315796,391.447235 
	C131.690338,393.298126 133.929459,394.916260 134.420654,389.985321 
	C134.531464,388.872955 135.553116,388.255280 136.652634,388.535370 
	C139.008896,389.135620 140.913940,390.428131 141.791977,392.815735 
	C142.339279,394.303955 141.927887,395.724091 140.876465,396.982330 
	C146.077530,396.241058 149.655685,400.214417 154.232697,400.794373 
	C155.314941,400.931458 155.822662,402.433289 155.414413,403.722809 
	C154.964828,405.142853 153.848495,405.837372 152.468262,406.105316 
	C149.948929,406.594360 147.510300,405.758453 145.040146,405.521423 
	C142.681549,405.295074 140.518158,404.903748 139.922287,408.148376 
	C139.784256,408.900055 138.624207,409.072418 137.435028,409.251770 
	C136.586060,409.233032 136.112122,409.135193 135.322968,408.819092 
	C134.183563,408.115936 133.662811,407.310822 132.891876,406.279938 
	C132.263138,405.430328 131.460495,404.939789 131.050842,405.452057 
	C128.387451,408.782379 125.961235,407.474640 123.527855,405.232574 
	C121.839706,403.677063 120.427353,403.870178 118.928780,405.627014 
	C117.328949,407.502594 114.945999,408.539703 113.442513,410.590485 
	C112.011383,412.542572 110.058983,413.139648 107.200577,411.939453 
	C107.443008,413.716644 108.366631,415.258392 107.394257,417.030945 
	C105.066315,418.999481 102.763771,416.669312 100.037376,417.743256 
	C102.563995,418.962982 105.374130,418.205872 107.258865,420.507568 
	C108.497955,423.563354 105.241417,424.203796 104.618256,426.552612 
	C108.198730,426.741638 110.954300,428.407379 113.433708,431.203369 
	C114.414368,431.529388 115.038612,431.820374 115.891922,432.418640 
	C115.744667,438.509949 116.897957,444.147339 118.459129,450.020935 
	C111.610603,451.252533 113.275543,456.951843 112.477119,461.005035 
	C112.030945,463.270081 114.140083,465.214233 114.828629,467.659912 
	C117.177780,466.694031 119.291199,463.670258 121.461906,466.915680 
	C123.300491,469.664551 123.623116,472.475800 120.408264,474.758545 
	C118.703133,475.969330 118.611488,477.830933 118.929749,479.736725 
	C119.394241,482.518250 120.212669,484.269775 123.396591,481.990967 
	C125.857040,480.229980 128.773132,478.839600 131.776962,479.131042 
	C135.122375,479.455566 135.612503,475.448761 138.816895,475.596619 
	C140.838837,477.120392 140.067627,479.396057 141.178650,481.392212 
	C141.539215,483.157043 140.900162,484.129486 139.364166,484.914917 
	C136.629791,485.000092 134.259094,485.000183 131.444183,485.000763 
	C127.941505,491.643341 122.495422,494.206879 115.468697,494.245056 
	C111.115875,494.268677 112.571465,498.232819 111.232269,500.728271 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M144.342285,713.235901 
	C143.555222,713.000000 143.110947,713.000000 142.333435,713.000000 
	C138.319550,714.514587 134.387573,714.138367 130.278229,714.760376 
	C127.686501,716.042847 125.821281,719.083801 122.694458,716.525574 
	C122.144890,716.075928 121.380394,716.552795 121.146416,717.283142 
	C119.976089,720.936096 115.953690,721.596313 112.982178,724.321472 
	C112.982178,721.758179 113.058861,719.192017 112.960358,716.632507 
	C112.864235,714.134949 111.781044,711.971313 109.355225,711.193909 
	C106.801613,710.375610 105.423332,711.927734 104.622597,714.335999 
	C104.109940,715.877808 102.404846,716.815002 101.048668,716.708557 
	C92.289276,716.021057 87.197441,722.461182 81.307373,726.986267 
	C78.123009,729.432617 77.453773,728.726501 77.017166,725.501343 
	C76.598938,722.411865 75.346626,721.204895 72.379402,723.077515 
	C68.594429,725.466064 68.849159,721.354126 67.754631,719.933533 
	C66.175385,717.883850 67.306633,716.395935 69.521500,715.294006 
	C74.674446,712.730347 80.352806,711.751282 85.730896,712.480469 
	C89.774208,713.028748 91.988762,711.693115 94.696732,709.161743 
	C97.352448,706.261902 98.928535,702.968994 101.274933,700.227539 
	C101.595367,699.853210 102.250557,699.386719 102.143097,699.086731 
	C100.201492,693.665771 104.666977,691.059509 107.284889,687.264038 
	C107.593605,686.767883 107.741508,686.585022 108.157578,686.176880 
	C109.201012,685.407837 110.284134,685.127441 109.765450,683.718872 
	C108.288254,679.707275 110.458824,679.254578 113.771774,679.372070 
	C116.916954,679.483643 120.091370,678.974365 123.424683,681.457397 
	C122.819939,677.579956 123.939552,676.546814 127.319725,676.365417 
	C132.521591,676.086365 137.837906,675.260864 142.635010,671.755371 
	C140.308823,669.669189 136.765320,668.209839 138.097641,664.325867 
	C139.322586,660.754944 143.833878,662.072937 146.032135,659.065308 
	C142.541306,659.281067 139.516327,660.588318 136.355942,661.083679 
	C132.897446,661.625671 130.133621,662.865173 131.751144,667.297058 
	C131.972458,667.903442 131.861694,668.627075 131.572845,669.247009 
	C130.582626,671.371887 121.012405,673.862915 119.446579,672.066711 
	C116.842293,669.079224 112.977524,666.323608 115.025223,661.366699 
	C115.687553,659.763428 114.461143,658.035522 115.769592,656.262634 
	C118.831848,653.845215 121.541687,652.492920 125.213852,654.797424 
	C126.653168,655.700623 129.111023,654.980713 131.552643,655.000000 
	C133.512421,654.670044 134.691666,653.738586 133.944794,652.228760 
	C132.315628,648.935242 134.021652,646.046204 134.648743,643.029724 
	C135.079697,640.956726 136.113113,639.308594 138.508591,639.052490 
	C141.025833,638.783325 142.564896,640.183533 143.753647,642.137207 
	C146.727417,647.024719 152.627426,648.902710 157.617645,646.469238 
	C161.410202,644.619873 165.165894,642.796143 169.402573,645.257019 
	C170.650711,645.981995 172.000748,645.657471 173.299622,645.368652 
	C176.450745,644.667664 178.573792,645.588623 179.043915,649.020691 
	C179.222214,650.322510 179.529099,651.295532 181.142776,651.084656 
	C185.701706,650.488892 189.223022,652.345093 192.113510,655.726990 
	C192.935989,656.689270 194.162659,656.740601 195.655045,656.961914 
	C201.746811,660.664917 208.910187,658.195190 214.784683,661.354126 
	C220.949600,664.669128 227.380081,667.569092 233.207214,671.393005 
	C235.414185,672.841248 238.540848,672.080750 238.821991,672.557983 
	C240.454773,675.329590 243.438599,675.361572 245.557022,676.860107 
	C252.508347,681.777039 260.277313,685.451111 266.772797,691.061584 
	C268.964233,692.954468 271.986481,691.474426 274.514709,692.067505 
	C277.600159,692.791260 281.886017,691.357178 282.989197,696.608276 
	C281.183533,699.366211 278.417633,700.200500 275.873322,699.730286 
	C269.982178,698.641602 263.939911,698.555298 258.140961,696.937256 
	C256.026489,696.347290 254.192642,695.127930 254.344284,692.895447 
	C254.735382,687.137573 250.597427,685.821106 246.537552,684.953247 
	C243.053879,684.208496 239.735657,683.509338 236.958832,681.137939 
	C236.198990,680.489075 235.132675,679.853882 234.224792,680.053833 
	C228.847366,681.238342 225.610428,676.000732 220.765091,675.707397 
	C217.658783,669.511963 213.580215,672.714722 209.465118,674.372437 
	C206.659561,675.502808 204.562836,675.014282 202.357834,672.000122 
	C199.333847,667.866455 195.700989,663.835449 189.037094,666.614807 
	C184.640427,668.448547 176.499344,663.481995 173.319107,658.099976 
	C172.444916,659.681580 172.338806,661.549194 171.016388,662.862610 
	C169.900070,663.971375 168.100174,664.417236 167.463486,666.041382 
	C168.464417,666.884277 169.245895,666.428406 170.017654,666.233643 
	C172.016373,665.729492 174.141144,665.270508 175.371841,667.436829 
	C176.519913,669.457703 175.086914,670.891663 173.620850,672.099243 
	C172.484619,673.035278 171.902893,674.114624 172.370102,675.590942 
	C173.557800,679.343811 171.283020,682.060364 169.341934,684.676575 
	C167.384079,687.315369 164.651123,689.327087 163.918228,692.862366 
	C163.556885,694.605469 161.936218,694.804993 160.260376,694.506165 
	C158.239639,694.145874 155.845856,691.613708 154.333740,695.162964 
	C153.389999,697.378052 155.689804,701.692139 158.716553,702.656677 
	C162.462036,703.850281 163.483841,706.442627 163.498108,710.243286 
	C161.420105,713.085876 158.457367,713.539124 155.242523,714.361206 
	C153.669678,714.490295 152.600540,714.252380 151.332062,713.271606 
	C149.936569,713.003296 148.868881,713.001648 147.400787,713.000000 
	C146.314346,713.577942 145.543961,713.742065 144.342285,713.235901 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M83.762161,354.233978 
	C86.274673,354.260345 88.088516,353.458496 89.784935,351.915192 
	C91.081192,350.735962 92.929268,350.445740 94.270645,351.711517 
	C95.572479,352.939972 95.181488,354.843445 94.787193,356.385254 
	C94.210594,358.639984 92.553947,357.500061 91.183640,357.241425 
	C89.917648,357.002411 88.388832,356.530792 87.556381,357.831787 
	C86.559128,359.390259 88.352837,360.058105 89.049835,361.045135 
	C89.609077,361.837097 90.255775,362.567322 90.931992,363.662170 
	C88.670250,369.108734 84.669861,369.701477 78.648621,365.457367 
	C78.846420,363.588196 80.041550,363.103851 81.345085,362.673798 
	C80.421852,362.648529 79.877953,363.277252 79.014137,363.979980 
	C76.677551,365.015839 76.296715,363.376221 75.664658,362.048431 
	C75.197517,361.067047 75.271355,359.466003 73.761200,359.508026 
	C72.373978,359.546692 72.287048,361.092041 71.756287,362.052887 
	C71.276733,362.920990 70.632164,363.681366 69.986588,364.994385 
	C72.483910,364.927246 75.133827,364.415802 76.483665,367.059143 
	C77.932350,369.896088 75.148148,371.505310 74.178314,374.300812 
	C81.298126,374.933807 80.160789,380.764069 81.103516,385.663147 
	C80.328209,386.898376 79.231766,386.782288 78.338531,387.153900 
	C75.912544,388.163300 74.927643,389.349121 77.852921,391.220093 
	C79.321617,392.159485 80.857521,393.470215 80.423164,395.605804 
	C80.245438,396.479706 79.270111,396.991241 78.456963,396.791168 
	C76.477013,396.303986 74.560234,395.355377 74.102234,393.202209 
	C73.236916,389.134277 69.445091,385.670898 71.707329,380.900848 
	C72.298492,379.654388 70.884888,378.780914 69.732002,378.511230 
	C67.310272,377.944763 66.386101,380.322144 64.811195,381.361542 
	C63.625320,382.144196 64.219818,383.351379 65.057457,384.366791 
	C68.739639,388.830475 68.721581,388.846100 63.218094,390.205627 
	C60.868984,390.785950 60.606449,393.043091 59.677448,394.718140 
	C58.672176,396.530701 57.798798,398.128693 55.220348,397.888397 
	C49.426277,397.348450 49.415855,397.435486 51.823250,402.840057 
	C52.069527,403.392975 52.366291,403.923401 52.684387,404.552826 
	C54.601921,403.424286 51.643970,400.285339 54.666794,400.288147 
	C56.344936,400.289703 58.407200,400.657532 59.079952,402.854004 
	C59.888596,405.494141 62.741177,406.091522 64.277267,408.075073 
	C65.581963,409.759857 66.729248,408.332184 67.934372,407.300323 
	C70.540375,405.069031 73.070503,402.374023 77.042114,403.230499 
	C78.108757,403.460510 79.528717,403.813080 79.436569,405.310913 
	C79.366089,406.456390 78.268425,406.765472 77.241859,406.955658 
	C76.195045,407.149628 74.560043,406.640289 74.546661,408.341949 
	C74.537163,409.550018 75.701591,410.374573 76.818893,410.762787 
	C79.209175,411.593353 79.490349,413.129578 78.248444,415.007233 
	C76.994904,416.902527 75.121284,417.674866 73.099937,416.318787 
	C71.186569,415.035156 69.790573,413.042877 67.161041,415.508148 
	C65.874496,416.714325 64.976723,414.699646 64.177856,413.747223 
	C62.723904,412.013855 61.098206,410.734253 58.642673,410.999390 
	C57.467796,411.126251 56.198830,411.097778 55.625198,412.333435 
	C54.957886,413.770874 56.109348,414.404358 57.091961,415.052795 
	C58.099369,415.717682 59.378815,416.732178 58.336323,417.798492 
	C54.883415,421.330139 57.362164,425.376312 56.999393,429.577576 
	C56.476360,430.423035 56.337475,430.948853 56.788845,431.788025 
	C57.000458,432.000000 57.000000,432.000000 57.000153,432.000000 
	C57.771801,432.666260 58.294777,433.643005 59.693199,433.954865 
	C62.214432,434.376740 63.053822,436.168976 62.865345,437.904297 
	C62.495464,441.309662 64.331665,443.692474 65.948380,446.636536 
	C65.704453,447.659241 65.120811,447.986572 64.203392,448.420319 
	C62.985054,448.730652 62.090523,448.652618 60.896698,448.260010 
	C60.081230,447.705658 59.644463,447.232513 58.987892,446.640503 
	C58.786301,446.502350 58.325588,446.482361 58.148239,446.669006 
	C55.477554,449.240509 55.218685,451.604004 58.147198,453.741821 
	C58.376266,452.107300 57.297977,449.389923 61.098068,449.699707 
	C64.411850,452.107574 61.393101,456.883118 66.082520,459.460327 
	C63.796215,455.938019 63.027843,452.984741 64.099159,449.482361 
	C64.748787,448.280823 65.723305,448.027222 66.740662,447.242126 
	C68.058533,447.556335 68.877235,448.346252 68.914932,449.595917 
	C69.040283,453.751007 70.873703,453.374268 73.689842,451.166046 
	C75.138824,451.147552 76.223976,450.560883 77.683197,450.876221 
	C78.313324,451.471619 78.626595,451.943237 78.969933,452.707428 
	C79.789734,454.057892 79.430550,455.615784 80.768951,456.767578 
	C79.740120,459.456696 78.304222,458.846344 76.744019,457.289825 
	C75.866783,456.414642 74.849159,455.813019 73.767090,457.007324 
	C72.834511,458.036652 72.995003,459.148621 73.800461,460.155914 
	C74.919899,461.555878 76.587814,462.809296 74.944847,464.838867 
	C73.502235,466.621063 71.681183,466.015778 69.936401,465.467743 
	C65.531242,464.084106 65.299835,467.799805 64.013519,470.626526 
	C62.323151,470.949158 60.314342,470.987183 59.732735,469.193512 
	C59.252132,467.711365 61.414059,467.429443 62.142857,466.369873 
	C62.949112,465.197693 64.634010,464.603882 64.432632,461.906921 
	C57.501656,466.333374 50.520035,468.045258 43.354801,463.259033 
	C46.191315,463.015839 48.532318,461.963959 50.249424,459.933380 
	C53.158287,456.493469 52.139450,453.235718 47.771015,451.953735 
	C46.373886,451.543671 46.150517,450.425934 45.261841,449.252960 
	C43.815311,447.814331 42.571957,446.687531 41.224365,445.220642 
	C41.157574,442.432098 40.866508,439.107910 44.663269,442.929779 
	C45.841339,444.115631 47.301064,444.306000 48.686867,443.409454 
	C50.153629,442.460571 50.418213,440.783447 49.585453,439.509338 
	C47.764744,436.723663 48.706566,434.260834 49.954048,431.356445 
	C50.644943,430.200500 50.399509,429.640198 49.264297,429.123901 
	C45.895172,428.130951 46.692535,425.406677 47.212643,423.748199 
	C48.096111,420.931091 47.415466,419.179749 45.076645,417.347717 
	C45.182484,416.141571 45.839287,415.731506 46.773537,415.184998 
	C48.104141,414.897339 49.478474,415.158264 49.741039,413.605408 
	C49.967754,412.264709 48.868790,411.681244 47.951168,410.954956 
	C42.433563,406.587860 41.748241,404.268158 43.951439,397.364380 
	C45.093582,396.001373 46.053665,394.901947 46.818359,393.258545 
	C46.999992,392.250000 46.999989,391.500000 46.999916,390.375000 
	C48.219627,387.737152 50.184151,388.318420 52.408237,388.787415 
	C49.806107,386.211914 48.237789,388.452423 46.245865,389.758392 
	C44.423512,390.121887 43.118595,389.511902 41.867878,388.602203 
	C40.392670,387.529266 37.100430,388.444000 37.401157,385.666809 
	C37.695702,382.946747 40.859661,383.871674 42.709591,383.148315 
	C43.882362,382.689758 45.431309,383.217072 46.626743,382.787689 
	C49.297222,381.828552 50.039486,379.833344 47.659309,378.003021 
	C43.582588,374.868011 40.777443,370.930389 38.866879,366.252472 
	C38.158169,364.517242 37.300484,363.639465 35.713875,365.502380 
	C34.931618,366.420868 34.047604,367.396423 33.056690,365.301239 
	C32.837605,363.460083 32.940281,361.957947 33.817856,360.270172 
	C35.293327,355.886047 31.832113,353.637146 30.034245,350.964966 
	C28.882906,349.253754 27.515009,350.989899 27.055050,352.608368 
	C26.794376,353.525604 26.815662,354.850433 25.336742,354.370819 
	C24.195591,354.000732 23.144979,353.038452 23.431299,351.767944 
	C24.121857,348.703796 23.793930,345.407074 25.380581,342.559174 
	C26.329969,340.855164 28.170454,339.046906 29.702248,340.606018 
	C32.115265,343.062073 35.625259,344.592560 36.920319,348.014862 
	C38.248554,351.524841 40.655281,353.507324 44.434628,353.463135 
	C45.479317,353.450897 45.906078,353.973297 46.300648,354.735779 
	C46.958782,356.007568 46.689732,357.149261 45.392735,357.627686 
	C41.714291,358.984650 43.067101,361.995758 43.183884,364.427673 
	C43.315880,367.176422 45.944916,366.869629 47.747864,367.391754 
	C50.611774,368.221100 53.481697,369.154938 56.416367,369.580383 
	C59.512402,370.029236 61.637436,368.649658 60.020004,365.300171 
	C58.676750,362.518524 60.318333,357.771851 55.150394,357.251251 
	C54.906773,357.226715 54.584587,356.456940 54.567898,356.023834 
	C54.533714,355.136871 61.854858,350.082886 62.276424,350.652252 
	C64.773399,354.024933 69.831833,349.912170 72.276627,354.311462 
	C72.867302,355.374420 76.420128,354.626129 76.986450,351.366333 
	C76.777184,350.340607 76.291428,350.017944 75.311562,349.964569 
	C73.888206,349.470276 73.371376,348.538666 72.935135,347.407257 
	C71.882668,344.677734 70.386673,343.039307 67.608116,345.825836 
	C67.038834,346.396729 65.626038,346.947632 65.588577,346.217346 
	C65.306557,340.718140 58.417366,342.001862 57.051003,337.356628 
	C58.604797,335.115387 60.750244,335.401367 63.216194,335.600891 
	C64.637489,335.915375 65.649734,336.443817 67.118073,336.225525 
	C67.884735,336.151611 68.325378,336.176178 69.088989,336.339142 
	C71.929520,337.895142 73.405258,340.855652 76.761940,341.513336 
	C75.565804,339.645477 71.575134,340.456116 72.949600,336.633911 
	C77.539948,335.739899 79.881302,333.282501 80.172531,328.518188 
	C80.792465,327.696930 81.359100,327.431183 82.041946,327.392639 
	C83.023407,327.337219 84.129623,327.352997 84.291023,326.060394 
	C84.459351,324.712402 83.259041,324.575287 82.380173,324.300842 
	C80.666626,323.765778 79.015045,323.069763 76.982025,322.387543 
	C76.188164,322.133759 75.779724,321.894043 75.203384,321.296326 
	C76.706161,317.230682 76.768761,312.921356 78.874397,309.278259 
	C80.087898,307.178680 81.529083,306.170044 83.530861,308.307129 
	C84.397446,309.232300 85.101738,310.103271 86.378510,309.000946 
	C87.149315,308.335419 87.900070,307.582214 87.741135,306.488922 
	C87.509613,304.896393 86.135056,305.084717 85.014214,304.975159 
	C82.007263,304.681305 82.089653,302.883667 82.955017,300.356018 
	C83.211983,299.453186 83.660118,299.061737 83.972733,298.281128 
	C83.823914,296.694427 83.648438,295.390076 83.409904,293.616974 
	C87.148788,294.617676 89.245193,293.017639 90.073891,289.416504 
	C90.438644,287.831512 91.497231,286.499451 93.294113,287.553497 
	C94.693970,288.374634 94.971069,289.842285 94.256485,291.485046 
	C93.496681,293.231750 90.915199,294.956818 92.403496,296.750702 
	C94.358368,299.106964 97.399200,300.450104 100.339081,301.548767 
	C102.472382,302.346008 102.872147,300.910950 103.012016,299.200378 
	C103.097435,298.155701 102.655991,296.535614 104.445732,296.634857 
	C105.544342,296.695770 105.997238,297.908386 105.932114,298.928040 
	C105.747894,301.812500 107.900200,301.906067 109.807236,302.428101 
	C111.127350,302.789490 112.163483,302.581238 112.655182,301.253784 
	C113.362419,299.344452 112.940331,297.778351 111.244652,296.437805 
	C109.405388,294.983765 110.150558,292.769165 110.000000,290.418060 
	C109.470070,288.898926 110.177986,288.207733 111.107605,287.282837 
	C114.272469,286.110291 112.847244,284.467072 111.923141,282.584717 
	C111.314812,280.294678 111.302315,278.337311 111.832230,276.030212 
	C113.721405,273.623688 112.764839,271.522217 111.998894,269.066925 
	C112.062164,267.217438 112.440956,265.802246 113.316010,264.147675 
	C113.889946,263.467651 114.302322,263.173248 115.122803,262.813782 
	C116.235626,262.533386 116.966812,262.305786 118.068512,261.958130 
	C119.515785,261.724152 120.535057,261.846680 121.736298,262.709045 
	C122.043297,265.396301 119.452576,266.369751 119.052254,268.604706 
	C118.357544,272.483276 119.497482,275.594940 122.848633,277.894501 
	C120.717087,279.477325 118.889824,275.406586 117.363831,278.146667 
	C116.870667,279.032166 117.035133,280.428894 117.244804,281.525604 
	C117.531815,283.026794 118.833717,282.972626 120.031372,282.809723 
	C120.939941,282.686157 122.065445,281.822479 122.622505,283.118805 
	C123.201149,284.465363 122.231178,285.338928 121.085548,285.790924 
	C119.831619,286.285645 119.236320,286.844849 119.602943,288.367340 
	C120.280785,291.182190 120.431870,293.546051 117.165184,295.558563 
	C115.954437,296.304474 114.536026,300.141418 114.855400,301.896240 
	C115.669548,306.369781 113.294212,308.882141 110.886879,311.691620 
	C110.470970,312.176971 110.036308,313.277435 109.839760,313.225494 
	C106.584038,312.365112 101.405197,312.244110 100.504143,310.233429 
	C97.768379,304.128693 96.618263,307.943634 95.040382,310.433624 
	C94.434372,311.389923 93.815521,311.921783 92.367622,311.986694 
	C91.403450,312.016876 90.868797,312.200226 90.205612,312.794800 
	C86.831032,315.187500 85.861099,317.612152 89.013344,320.667358 
	C89.548370,321.185913 89.748306,322.124359 89.936249,322.908783 
	C90.194565,323.986877 89.850685,325.375793 91.402283,325.705170 
	C92.298683,325.895477 93.044678,325.401184 93.461174,324.640289 
	C94.131844,323.414978 94.261765,322.068939 93.520203,320.849426 
	C92.869850,319.779877 90.970947,319.692627 91.098450,317.984985 
	C91.156815,317.203461 91.336655,316.379883 91.704826,315.708923 
	C91.795700,315.543304 93.020821,315.696869 93.410233,316.050140 
	C95.218399,317.690460 96.904938,319.264832 98.471306,314.507843 
	C97.329124,320.914886 96.757881,325.532257 100.895325,330.396301 
	C102.422119,332.191223 102.751671,336.955261 103.825729,340.320557 
	C104.082085,341.123810 103.933411,342.332001 103.116219,342.492035 
	C97.229317,343.644501 91.906624,348.386749 85.337540,345.343719 
	C84.433861,344.925140 83.272049,344.763306 82.675140,345.789673 
	C82.168816,346.660248 82.950401,347.341125 83.636642,347.807190 
	C86.090630,349.473877 83.805786,351.439758 84.009293,353.620880 
	C83.559074,354.523132 82.912682,354.768616 82.396568,355.189636 
	C82.831642,355.041779 83.182442,354.762329 83.762161,354.233978 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M138.933777,485.337524 
	C139.577713,484.180511 140.154907,483.361023 140.944580,481.921234 
	C142.294540,479.438385 144.421295,478.522980 145.854858,476.424377 
	C147.453033,475.214722 148.877899,474.775940 150.819733,475.511108 
	C152.384598,477.648987 151.409836,479.257202 150.253433,480.703949 
	C147.453461,484.206940 145.351944,488.292267 141.983688,491.302216 
	C135.991638,496.656708 133.515961,504.376251 128.738541,510.535919 
	C127.674042,511.908356 127.639626,513.662109 127.654259,515.327087 
	C127.702744,520.840027 127.426888,526.310913 125.625977,531.582397 
	C125.133926,533.022583 125.609741,533.970642 126.463547,535.169861 
	C129.314240,539.173889 131.272095,543.635864 130.560455,548.654724 
	C129.490463,556.201294 132.187943,562.717285 135.696213,569.005676 
	C137.978622,573.096741 139.115097,577.270447 138.183624,581.914978 
	C137.418198,585.731628 139.161438,588.763184 141.441620,591.485596 
	C144.044128,594.592773 145.588242,598.142639 147.218735,601.826843 
	C150.447250,609.121704 154.454742,616.116455 162.831726,618.820557 
	C165.204193,619.586365 166.173126,622.104492 168.333206,622.982727 
	C172.172180,624.543335 175.639023,626.831909 179.700409,628.027222 
	C184.789917,629.525330 189.716003,631.625244 193.546539,635.625488 
	C195.422012,637.584045 197.919815,638.612854 200.786224,639.665527 
	C201.387161,639.868835 201.620468,639.972290 202.173157,640.288208 
	C205.865005,645.531799 211.796982,646.177673 216.687378,648.335083 
	C228.850037,653.700806 240.233856,660.276550 251.141388,667.817627 
	C254.806854,670.351868 258.677917,672.623352 262.997009,674.119507 
	C267.061920,675.527527 271.093048,677.196472 274.090668,680.605591 
	C275.318909,682.002563 277.075195,682.726501 278.920593,683.056519 
	C288.916687,684.843811 297.368805,690.232483 306.128937,694.846924 
	C308.689117,696.195557 311.249268,697.083435 314.053467,697.654785 
	C317.640259,698.385498 320.880371,699.737976 320.234131,704.701538 
	C317.941498,705.223511 315.776611,704.930969 314.205078,703.837402 
	C306.344208,698.367188 296.643921,697.361145 288.296021,692.731018 
	C282.447662,689.487366 275.841370,686.864136 269.290009,684.556824 
	C265.874664,683.353943 262.640503,680.616211 259.269318,678.638916 
	C254.841217,676.041687 250.537018,673.328125 245.617493,671.647583 
	C241.346344,670.188599 238.399673,666.549316 234.427200,664.359436 
	C225.334137,659.346741 215.596069,656.306274 205.845062,653.156433 
	C204.912598,652.855164 203.752258,652.977478 203.190216,652.001099 
	C199.308990,645.258057 191.986267,644.417419 185.727066,641.745667 
	C184.259415,641.119202 182.396088,641.088501 181.829071,639.047974 
	C181.671875,638.482239 180.957565,637.578613 180.619339,637.629639 
	C175.235931,638.440674 170.795105,635.035095 166.183746,633.565552 
	C158.072952,630.980835 148.267853,630.004761 143.768692,620.645630 
	C143.419235,619.918701 142.862900,619.040894 142.184952,618.761292 
	C136.059296,616.235229 136.407150,608.829346 131.830902,605.148254 
	C131.005630,604.484436 130.945709,603.347412 131.383835,602.364502 
	C132.617340,599.597168 131.096802,597.940857 128.783356,597.531250 
	C123.639282,596.620300 125.693932,591.431458 123.312965,588.946106 
	C123.146515,588.772339 123.442345,587.723816 123.673798,587.663879 
	C129.326324,586.200867 125.370308,583.515442 124.626678,581.107788 
	C123.366928,577.029114 120.357407,573.489502 120.904533,568.835449 
	C121.047997,567.615173 120.212997,566.625916 119.261726,565.887695 
	C117.227089,564.308655 114.079773,563.230103 118.306839,560.251099 
	C119.893623,559.132874 118.080620,557.838623 116.679855,557.028320 
	C112.251541,554.466736 112.169098,553.927429 116.673431,551.384827 
	C118.836327,550.163940 119.478256,549.248840 117.782425,547.314697 
	C117.453156,546.939148 116.831657,546.529907 116.858643,546.175232 
	C117.298126,540.399292 116.831612,535.223816 110.691582,532.127319 
	C109.875069,531.715515 111.784706,528.536926 112.872284,527.054504 
	C113.977112,525.548584 114.852623,524.302673 115.115677,522.329224 
	C115.596573,518.721436 113.811371,513.992371 119.520264,512.454163 
	C120.342857,512.232483 120.615631,509.844757 121.076706,508.437683 
	C121.705864,506.517670 122.226982,504.520966 124.617149,504.061340 
	C126.925232,503.617523 127.068207,502.641296 125.377747,501.179108 
	C124.405792,500.338440 124.239273,499.690186 125.583977,499.001984 
	C131.446533,496.001587 135.396439,491.072083 138.933777,485.337524 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M64.925758,472.341125 
	C66.458855,472.043518 67.478195,472.889435 68.375748,473.906036 
	C69.232918,474.876892 70.596268,475.935883 69.379860,477.285126 
	C68.200783,478.592987 67.390846,477.247223 66.623947,476.391144 
	C66.073891,475.777191 65.428345,475.011841 64.572716,475.790192 
	C63.823639,476.471619 64.247231,477.320587 64.687332,478.049896 
	C66.530777,481.104645 65.573059,482.033081 62.284813,481.261871 
	C60.997135,480.959869 59.807751,481.308990 59.484356,482.643311 
	C59.132088,484.096771 60.331329,484.638702 61.497734,485.000275 
	C63.692135,485.680359 64.537819,487.285461 63.609615,489.236176 
	C62.473984,491.622711 60.577610,491.023712 58.529232,490.079163 
	C54.393082,488.171936 52.143951,489.394714 52.007458,493.350250 
	C51.856129,497.735626 53.554398,498.800690 57.471062,496.691284 
	C58.906673,495.918091 60.240711,494.328674 61.961430,495.427582 
	C63.706444,496.541992 62.976128,498.387634 62.512012,499.965881 
	C62.137573,501.239166 62.437332,502.355591 63.351032,503.147400 
	C64.654213,504.276733 65.437996,502.913513 66.275406,502.240967 
	C68.404449,500.531067 70.614548,500.499176 72.656410,502.166290 
	C76.351517,505.183228 80.241356,503.544464 84.113808,502.906036 
	C85.570435,502.665894 85.942772,501.630463 85.956657,500.364838 
	C85.971375,499.024078 86.065727,497.607300 84.357903,497.120209 
	C81.685432,496.358002 80.147400,492.520996 76.001938,494.690430 
	C80.555580,491.389557 83.125130,487.715790 83.432526,482.646088 
	C83.458099,482.224213 84.308144,481.633881 84.861420,481.511292 
	C85.798615,481.303650 86.407150,482.009644 86.683167,482.841583 
	C86.888329,483.460052 86.916832,484.141174 86.992035,484.798004 
	C87.261971,487.155975 86.964577,490.388580 89.383186,491.151520 
	C93.646828,492.496368 97.683914,491.455414 99.988647,486.958832 
	C100.291977,486.367035 100.563194,485.757568 100.824745,485.145844 
	C101.203018,484.261169 101.402206,482.991760 102.796913,483.703308 
	C103.898399,484.265259 103.600266,485.271820 103.341125,486.316376 
	C101.308891,494.507751 102.433731,497.140259 109.681824,500.854309 
	C109.872147,501.814514 109.965988,503.208099 109.582771,503.354706 
	C103.108788,505.831512 102.876945,514.167725 96.799248,516.894226 
	C94.795074,517.793274 95.345810,518.753479 96.394173,519.866821 
	C97.474220,521.013794 98.072380,522.227295 97.857094,523.897827 
	C96.981354,530.692993 97.556221,531.691467 103.685364,533.924072 
	C104.919884,534.373779 106.602417,534.295471 106.498154,536.078613 
	C106.391640,537.900330 104.591919,538.542480 103.240219,538.650635 
	C99.658119,538.937195 97.195908,539.379700 98.424698,544.192383 
	C98.827698,545.770691 96.750290,548.001648 95.757698,549.908386 
	C95.304085,550.779785 94.356926,551.132507 93.699928,550.476257 
	C87.899727,544.682922 89.401138,550.153748 89.377258,553.360474 
	C87.251373,553.856201 85.741020,552.568726 84.791985,551.175842 
	C82.334259,547.568604 78.348679,548.250122 74.969856,547.255249 
	C73.920166,546.946167 73.058998,547.530273 72.445984,548.435303 
	C71.784615,549.411804 71.686859,551.490784 69.797516,550.388733 
	C68.509735,549.637573 68.974579,547.959167 69.035599,546.679932 
	C69.299660,541.143494 74.246918,537.643066 79.495956,539.099731 
	C81.255371,539.587952 81.806877,540.751648 82.067963,542.340454 
	C82.400223,544.362305 83.786644,545.030151 85.683380,544.949463 
	C87.889435,544.855591 87.805435,543.595825 87.560989,541.850342 
	C87.217186,539.395325 89.015747,540.323303 90.214828,540.501160 
	C91.279694,540.659119 91.802666,540.131714 91.852806,539.173950 
	C91.914764,537.990417 91.420807,537.472595 90.069839,537.497437 
	C85.762222,537.576721 81.602768,537.369751 83.722527,531.026062 
	C84.018990,530.138855 83.498627,529.322815 82.501991,529.272034 
	C81.122620,529.201965 80.482025,530.128357 80.120331,531.364441 
	C79.755066,532.612732 80.441605,535.035156 78.166199,534.487305 
	C76.176208,534.008240 77.208191,532.029297 77.638596,530.674072 
	C78.111244,529.185730 77.603249,528.038452 76.552017,527.095459 
	C74.107582,524.902649 71.295555,523.174683 69.712875,520.017944 
	C68.849609,518.296204 66.766159,518.980408 65.160057,519.435120 
	C63.851391,519.805664 62.731754,519.653748 62.476040,518.172058 
	C62.274261,517.002869 63.215565,516.430115 64.250328,516.149780 
	C64.730286,516.019714 65.244286,516.005859 65.715233,515.853760 
	C67.734871,515.201538 68.874840,513.587952 68.469467,511.696136 
	C68.025856,509.625885 65.892113,510.092010 64.265930,510.020813 
	C62.156876,509.928528 61.092361,511.639069 59.314034,512.830139 
	C58.621143,513.010376 58.306767,513.153442 58.035473,513.728027 
	C53.242634,519.491943 57.975842,523.801514 60.038475,528.249207 
	C61.009285,530.342529 63.599102,531.306580 66.068596,530.867859 
	C68.960052,530.354187 67.324272,528.186951 67.520470,526.558716 
	C67.771881,524.472412 68.898369,525.598267 69.848434,526.722656 
	C71.162361,527.953430 71.894524,529.224976 72.800049,530.758057 
	C74.370300,536.019470 69.205002,537.289978 67.506493,540.549927 
	C67.448898,540.660400 67.114906,540.769531 67.074219,540.725891 
	C63.270363,536.646484 56.582542,540.821228 53.148907,535.872437 
	C52.535904,534.988892 51.747467,533.748962 51.072784,534.848694 
	C49.408180,537.562073 46.983940,536.316895 44.873264,536.539734 
	C40.126312,537.040955 38.884468,535.420959 40.212639,530.673889 
	C35.828697,530.855164 32.726669,528.268433 33.078476,524.724915 
	C33.343044,522.060059 39.236099,517.646423 42.102032,518.291138 
	C44.246071,518.773438 46.332523,519.191833 48.500984,518.860718 
	C49.498566,518.708435 50.556164,518.297424 50.331905,517.002502 
	C50.149384,515.948486 49.102848,516.096619 48.310131,516.026123 
	C47.647804,515.967285 46.977051,516.008972 46.312859,515.964172 
	C44.693928,515.855103 43.094105,515.377258 43.345562,513.436462 
	C43.583061,511.603485 45.365585,511.852814 46.577374,512.153381 
	C50.045837,513.013916 52.140446,512.891602 51.180817,508.250824 
	C50.709339,505.970703 52.020184,504.061371 54.839035,503.945862 
	C55.915043,503.901764 56.833401,503.472412 56.705017,502.156250 
	C56.594360,501.021759 55.811413,500.327026 54.757309,500.189880 
	C52.332027,499.874298 49.889256,499.693146 47.226788,499.229370 
	C46.248772,498.181946 45.411736,497.885559 44.206333,498.788330 
	C40.473522,498.543762 37.257000,500.367157 33.869476,500.515381 
	C32.925812,499.190704 33.997124,498.562439 34.621334,497.928314 
	C36.222694,496.301514 37.918346,494.767487 39.576275,493.196350 
	C36.478580,490.374146 37.956600,485.428406 34.904182,482.753418 
	C33.182217,481.244385 32.989956,479.360870 32.368168,477.539215 
	C31.789501,475.843872 31.814775,474.069183 33.807781,473.366425 
	C35.662285,472.712555 35.914867,474.458252 36.372196,475.640961 
	C37.078377,477.467346 38.418064,478.064392 40.286865,478.020752 
	C41.740223,477.986816 43.590904,477.631134 44.268345,479.310150 
	C44.976418,481.065094 42.881310,481.688599 42.179993,482.906799 
	C41.949512,483.307159 41.787483,483.746887 41.563732,484.234985 
	C48.150795,483.722748 48.180298,483.714783 46.643555,478.013336 
	C46.404705,477.127197 46.274258,476.397400 47.006332,475.781555 
	C47.747471,475.158081 48.530590,475.277222 49.252918,475.866547 
	C50.538406,476.915375 51.784622,478.015228 53.104996,479.017670 
	C54.872723,480.359741 57.646317,480.829254 58.371174,479.057709 
	C59.712688,475.779053 62.535446,474.618042 64.925758,472.341125 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M163.296600,710.252563 
	C161.490051,707.378296 161.630783,703.054993 156.325775,704.258606 
	C154.830750,704.597839 151.696548,696.202637 152.445999,694.269592 
	C153.629486,691.216980 155.542038,689.968567 158.587387,692.084290 
	C161.088806,693.822205 163.172287,694.758606 163.049988,690.198853 
	C162.993835,688.105347 164.813049,687.259338 165.988541,685.895813 
	C168.651031,682.807312 173.191330,680.311462 170.248718,674.838440 
	C169.504379,673.453979 169.689590,671.474670 172.064407,671.006165 
	C173.626541,670.697998 175.430115,669.915649 174.521423,668.153198 
	C173.456314,666.087341 171.123138,666.640015 169.862564,667.979736 
	C166.940445,671.085449 165.955002,668.935974 165.173965,666.467712 
	C164.735504,665.082092 165.413544,663.550781 166.888809,663.315613 
	C171.021347,662.656738 171.916992,660.111938 171.319016,656.514526 
	C171.137299,655.421265 171.161163,654.435242 172.488785,654.268677 
	C173.619553,654.126953 174.630814,654.870972 174.685959,655.870972 
	C175.094147,663.274292 182.049957,663.053833 186.373703,665.564697 
	C188.804443,666.976379 191.613983,663.561584 194.797211,663.691040 
	C196.718246,663.769226 197.845795,663.628235 198.433990,665.683044 
	C198.929886,667.415466 200.323807,668.339417 202.017914,668.838013 
	C202.795547,669.066895 203.856873,669.138550 204.267609,669.678528 
	C207.277267,673.635132 209.850571,674.340698 213.343430,670.920227 
	C217.054352,667.286255 218.725708,668.050110 219.777451,672.674500 
	C219.850632,672.996277 220.013916,673.580627 220.104980,673.574707 
	C224.715286,673.276184 227.138214,678.395752 231.615616,678.235474 
	C232.942017,678.187988 234.602966,677.544312 235.479614,678.420105 
	C239.579849,682.516602 245.304321,682.418640 250.146988,684.524902 
	C253.493164,685.980286 258.271027,686.857361 255.473251,692.747253 
	C254.837341,694.085876 256.557068,695.788757 258.147766,695.541199 
	C260.145111,695.230347 261.710388,696.481384 263.358276,696.482727 
	C269.658875,696.487976 275.875641,699.814514 282.616821,697.008728 
	C283.001221,696.997559 283.500610,696.999146 283.750305,696.999573 
	C288.678284,700.483704 294.347168,701.929749 299.731384,704.756958 
	C300.445129,704.998596 300.889038,704.999573 301.665863,705.001526 
	C302.843658,705.830688 303.804779,706.520081 304.953705,706.790222 
	C316.647064,709.539551 325.639771,717.039429 334.687958,724.195923 
	C339.482300,727.988037 338.814880,734.125732 338.156158,739.523193 
	C337.678406,743.438110 334.247681,745.275024 330.438812,746.490662 
	C328.359039,743.052612 330.791199,740.020935 330.869690,736.781067 
	C330.970764,732.607544 329.431122,729.976013 326.020477,728.373657 
	C318.759888,724.962463 312.650452,719.728088 305.690216,715.855286 
	C304.087921,714.963745 302.530975,713.488159 300.749573,713.616638 
	C292.946259,714.179626 286.197205,710.661621 279.115448,708.417358 
	C276.758606,707.670471 274.560394,706.810974 271.896759,707.793762 
	C269.124084,708.816895 266.991180,706.176819 264.423248,705.597229 
	C257.704071,704.080872 251.788574,701.147400 246.614426,696.398743 
	C239.131210,689.530884 229.832977,688.164368 220.091461,688.948669 
	C218.256332,689.096436 216.447266,689.068420 214.620514,689.079163 
	C211.385147,689.098328 209.104126,687.963806 208.459549,684.557434 
	C207.984146,682.045105 206.986496,681.045898 204.393631,682.575867 
	C199.680313,685.356934 194.327194,686.470215 189.177948,688.123291 
	C187.301239,688.725830 186.393784,687.941711 185.469421,686.611389 
	C184.544174,685.279785 184.909180,683.328552 183.116592,682.050415 
	C182.145813,685.845764 181.776123,689.067139 184.578186,692.339050 
	C187.907898,696.227173 187.098465,697.742798 181.869156,698.645874 
	C181.212219,698.759338 182.021408,698.563599 181.376587,698.723083 
	C179.111328,699.283142 176.252090,698.370422 174.623978,699.737366 
	C173.055664,701.054199 175.842239,703.674744 174.412201,706.062744 
	C173.461411,707.013428 172.545959,707.262695 171.261078,707.485962 
	C169.681625,707.614319 168.316681,707.197571 167.161652,708.672607 
	C166.809418,709.073608 166.658112,709.226074 166.251862,709.578613 
	C165.628250,710.009827 165.240219,710.201904 164.515564,710.430298 
	C163.981583,710.490234 163.764908,710.474854 163.296600,710.252563 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M96.630180,580.042480 
	C99.008018,580.165466 99.534470,581.989380 100.494347,583.252991 
	C101.149231,584.115112 102.124214,585.242126 101.256889,586.368103 
	C100.537109,587.302551 99.401428,586.679016 98.497231,586.324219 
	C93.312210,584.289978 91.031624,588.911438 86.758835,591.283936 
	C90.123932,591.937866 92.264008,593.326416 93.903839,595.411621 
	C95.940132,598.001038 97.831261,600.334717 101.568420,597.486633 
	C103.190170,596.250610 104.676323,597.922180 105.535683,599.402893 
	C106.441605,600.963928 107.121849,602.655823 107.950500,604.645264 
	C106.135292,610.319641 105.222603,610.654785 98.367546,608.278503 
	C98.933266,610.801147 99.833977,613.167297 98.929169,615.960266 
	C98.355682,617.276062 97.687843,618.051819 96.413528,618.788757 
	C94.358330,619.432861 92.791176,618.990417 90.870209,618.207153 
	C88.446594,616.994873 86.174591,616.553406 84.040024,615.732178 
	C82.037880,614.961914 80.833923,613.539978 80.504982,611.017883 
	C78.824448,606.126709 80.330368,601.115601 77.985916,596.487549 
	C77.482109,596.193176 77.282692,596.072754 76.817772,595.727539 
	C76.078850,595.013000 75.698151,594.449646 75.091057,593.659912 
	C74.625229,593.043762 74.209137,592.391357 73.906296,592.621277 
	C73.040337,593.278564 73.984505,593.915222 74.303192,594.862793 
	C74.929924,596.608582 76.706604,596.727905 77.458237,598.355469 
	C77.131432,601.585327 74.600479,601.521667 72.650276,602.120911 
	C69.707321,603.025269 67.157356,604.083496 65.990967,607.411499 
	C65.200539,609.666748 63.501362,611.409485 60.299141,610.247803 
	C59.643993,605.761902 55.014492,604.570557 53.535770,600.953064 
	C52.946308,599.511047 51.839451,601.665527 51.123528,602.340637 
	C48.754539,604.574768 37.341137,606.230469 34.628284,604.588257 
	C34.148289,604.297668 33.673267,603.605347 33.637104,603.068848 
	C33.584885,602.294128 34.421143,602.205566 35.050404,602.001831 
	C36.148792,601.646118 37.382225,601.417480 38.263145,600.746887 
	C39.650059,599.691101 41.812405,598.326843 41.097000,596.617615 
	C40.428265,595.019958 38.603107,593.483704 36.500751,593.136169 
	C34.506809,592.806519 33.934509,594.407776 32.793110,595.665283 
	C30.543173,598.144104 28.854555,602.656189 23.959658,599.941467 
	C22.687141,599.235596 21.799959,598.258240 22.349421,596.717590 
	C22.792477,595.475342 23.957762,594.793518 25.144060,595.123230 
	C30.542768,596.623840 31.808359,592.282776 32.816353,589.025085 
	C34.004715,585.184509 35.552731,583.720764 39.488552,584.919189 
	C40.696812,585.287048 42.182976,585.151306 43.445393,584.846558 
	C46.011745,584.226868 46.399204,582.848145 44.260101,581.115356 
	C42.945980,580.050842 42.705296,578.841858 44.014957,577.800110 
	C45.485695,576.630310 46.714134,577.835083 47.247276,578.964600 
	C48.931698,582.533020 53.364407,585.279846 50.268322,590.382996 
	C49.283142,592.006714 50.604340,594.074707 53.138233,593.705322 
	C60.759926,592.594177 60.805943,592.616089 59.345524,600.485107 
	C59.234177,601.085083 59.330891,601.723694 59.330891,602.332825 
	C65.291290,602.617493 62.786438,597.544617 64.675606,595.068298 
	C68.097733,594.742737 66.475662,593.553284 65.247955,592.245239 
	C66.891983,588.695801 68.308228,585.740112 65.420212,581.819153 
	C64.049187,579.957764 64.878784,576.475464 64.215111,573.119141 
	C62.275330,579.969788 56.227787,575.854797 52.612514,578.263611 
	C51.173508,579.222412 51.121185,577.122131 51.024620,576.068176 
	C50.858582,574.255859 51.076443,572.360474 50.627285,570.631409 
	C49.555214,566.504578 47.375038,565.773010 43.764709,568.164124 
	C42.678459,568.883545 41.512516,569.386414 40.706364,568.260193 
	C40.015343,567.294739 41.159546,566.529907 41.804775,565.789429 
	C45.057091,562.057129 44.418449,560.273560 39.294155,559.014648 
	C37.971546,558.689697 36.359177,558.643311 36.496689,556.894104 
	C36.629303,555.207214 38.046104,554.969055 39.518414,555.322021 
	C39.841576,555.399536 40.404762,555.634338 40.446869,555.560974 
	C44.301159,548.848267 48.678539,554.800354 52.804691,555.033997 
	C55.964607,555.212891 56.755558,557.611755 57.525471,560.048828 
	C58.318024,562.557678 59.399017,564.287476 61.789024,561.311340 
	C62.607918,560.291626 64.042450,558.425964 64.672020,559.772156 
	C65.868614,562.330811 71.080467,562.159363 69.320221,566.234314 
	C67.590080,570.239563 68.172714,572.715332 72.449127,574.367676 
	C74.761589,575.261230 75.077324,577.264526 74.326317,579.785339 
	C73.108353,583.873718 75.267426,585.889709 79.241844,586.619995 
	C81.897820,587.108093 81.948441,585.560120 81.997459,583.387817 
	C84.584297,580.923035 87.744202,582.421021 90.570168,581.638000 
	C92.460342,581.114258 94.425476,580.842041 96.630180,580.042480 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M59.838100,610.367432 
	C62.016865,610.126465 64.311005,609.414673 64.425636,607.567993 
	C64.836998,600.940918 70.128563,601.273376 74.351166,600.039124 
	C75.303802,599.760681 76.358498,599.852905 76.908234,598.359680 
	C76.398972,597.316284 76.233444,596.539246 76.749695,595.334839 
	C80.067795,594.840942 81.032776,596.499939 81.029877,599.328857 
	C81.026505,602.624939 80.519608,605.989624 81.936798,609.588989 
	C80.839218,611.476685 78.727074,610.233215 77.489182,611.537354 
	C77.559784,613.051697 78.895561,613.724670 79.569908,614.812927 
	C83.804420,621.647034 83.819572,621.637634 90.665451,617.087402 
	C92.421494,617.328064 93.842690,617.656128 95.631790,617.992065 
	C97.477699,623.234070 102.307465,624.471924 106.383171,626.673889 
	C104.950211,622.278625 100.962349,619.986328 98.134972,616.325623 
	C97.804008,613.771973 98.579147,611.400513 96.872620,609.397034 
	C96.198204,608.605408 94.731483,607.698853 95.884621,606.577209 
	C96.997261,605.494873 99.196457,605.479492 99.983864,606.442200 
	C103.294312,610.489502 105.346428,608.425659 107.700607,605.167725 
	C109.066887,605.000000 110.133476,605.000000 111.600029,605.000000 
	C113.889732,607.423523 112.191803,608.957397 110.606339,610.479248 
	C108.804184,612.209167 105.379448,612.884888 106.329819,616.428284 
	C107.307602,620.073975 110.550476,620.781006 113.961205,621.699707 
	C116.396591,625.500427 115.786499,629.475220 115.743889,633.962158 
	C117.612679,630.439087 115.305763,627.207703 116.705154,623.946289 
	C119.077187,622.973633 121.243927,622.987854 123.710686,623.761902 
	C125.135147,624.053589 126.361916,623.909729 126.931610,625.664551 
	C128.019821,631.671875 126.150826,635.329712 120.476990,637.632935 
	C118.004425,638.636597 113.728310,639.226562 114.253212,644.144531 
	C114.375259,645.288025 112.081093,645.171204 111.273972,643.745239 
	C110.666008,642.671204 110.825844,640.828979 108.827400,641.374634 
	C107.276779,641.797974 107.215340,643.221924 106.988609,644.578552 
	C106.564293,647.117310 108.676010,651.312683 103.395454,650.939087 
	C100.852806,650.759216 98.105087,654.898682 95.713562,650.601379 
	C95.520050,650.253662 94.252663,650.044861 93.897316,650.314880 
	C88.620125,654.325806 85.382408,648.661865 81.086823,647.353882 
	C80.031662,645.999146 79.647163,644.805420 79.887512,643.059082 
	C80.978455,641.034241 81.060501,639.125671 81.592224,636.986755 
	C83.762749,634.993347 86.679611,635.232056 88.840103,633.153931 
	C93.213585,630.037415 93.213585,630.037415 91.780045,626.710571 
	C89.971085,628.345093 90.959854,631.011353 89.176689,632.809753 
	C87.103806,634.911926 84.554382,634.459167 82.017960,635.247559 
	C80.965294,635.581970 80.260414,635.521301 79.259750,635.052979 
	C78.586830,634.540466 78.302834,634.156372 78.048660,633.357239 
	C77.426247,630.377808 75.301765,631.092529 73.606766,631.017334 
	C70.658836,630.886536 67.656761,631.357605 64.411125,630.062622 
	C64.343330,626.978394 63.024761,625.211304 59.972889,625.060303 
	C57.579071,624.941956 56.191124,623.446045 57.230640,621.624512 
	C59.216908,618.143982 58.487789,614.262390 59.838100,610.367432 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M319.999390,705.000000 
	C319.355896,700.858337 316.816864,699.000244 312.751251,699.007263 
	C311.243347,699.009827 309.574799,699.159424 308.433655,698.137451 
	C303.396912,693.626221 296.790405,692.191467 291.031952,689.105225 
	C287.316589,687.114075 283.885651,684.315979 279.095337,684.863037 
	C276.786804,685.126648 274.244354,683.853027 272.928314,682.049988 
	C269.623138,677.521545 264.749847,676.287598 259.870300,674.998169 
	C256.612091,674.137207 253.746948,671.883789 251.537552,670.125671 
	C247.059372,666.562439 240.938965,665.323364 237.599396,660.232910 
	C237.022430,659.353394 235.767288,658.672302 234.698105,658.400574 
	C224.967087,655.927917 217.370621,648.586548 207.423569,646.553101 
	C204.968338,646.051086 203.032639,644.252686 202.040863,641.368408 
	C206.548248,637.772766 210.902420,640.950439 215.325531,641.495972 
	C216.967834,641.698547 218.592087,642.408630 219.888046,643.425415 
	C229.177917,650.714172 239.937866,655.657349 250.153900,661.253479 
	C265.513641,669.667236 280.357666,679.259033 297.037048,685.192505 
	C303.281189,687.413696 308.921356,691.077881 314.741211,694.189819 
	C320.447083,697.240906 326.303253,699.987244 332.090851,702.816528 
	C346.508087,709.864441 352.392761,721.603638 352.294434,737.402527 
	C352.049713,738.504028 351.712524,739.162842 351.075684,740.067261 
	C349.306854,741.441345 348.117279,742.734253 348.289124,745.169922 
	C347.499115,748.150574 345.330200,749.822754 343.638611,752.230591 
	C341.178558,753.760376 338.541687,753.824646 336.011444,755.176147 
	C334.470337,755.997498 333.124451,756.009460 331.433899,755.872131 
	C330.896240,755.727234 330.689972,755.639343 330.243469,755.316040 
	C330.287476,754.502930 330.471069,753.505493 330.870789,753.409668 
	C338.267181,751.636902 340.304077,744.402283 344.095978,739.408264 
	C346.273102,736.540955 346.760040,730.963989 344.144043,726.326477 
	C343.766876,725.757507 343.441589,725.506653 343.412872,725.211304 
	C342.452393,715.337463 335.201752,711.585205 327.204193,708.633972 
	C324.882111,707.776978 323.103821,705.838135 320.282898,705.238770 
	C319.998779,705.000000 320.000000,705.000000 319.999390,705.000000 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M114.067444,263.660248 
	C113.996819,265.480408 114.098701,266.976562 113.149734,268.663422 
	C111.760704,270.200562 110.173645,270.235626 108.300232,270.156128 
	C105.486382,266.713104 103.081017,263.387756 103.999077,258.433716 
	C102.581856,257.040375 101.156837,256.090149 100.111725,254.294922 
	C99.714355,252.396637 98.837593,250.620178 99.259750,249.241425 
	C100.159378,246.303299 98.743759,245.059708 96.621849,243.722626 
	C95.173172,242.809769 93.483429,241.851562 93.509895,239.732956 
	C93.710144,239.574387 93.856590,239.367325 93.964951,239.385635 
	C101.430443,240.647202 101.430183,240.648727 102.000008,231.424744 
	C102.408348,230.736633 103.041557,230.563446 103.190147,230.194870 
	C104.999527,225.707077 108.409927,225.857956 112.595139,226.998230 
	C114.313904,225.473282 115.000626,222.455307 117.687157,223.598770 
	C120.654076,224.861588 123.629898,226.646881 124.746170,230.060562 
	C125.241058,231.573990 124.892677,233.424179 127.068985,234.476044 
	C127.593582,235.187286 127.764313,235.662491 127.858253,236.561340 
	C127.747917,238.001678 127.337326,238.958939 126.698730,240.230072 
	C125.447838,241.721252 124.047310,242.527695 122.590012,242.384659 
	C117.359352,241.871231 115.343254,244.905182 114.259079,249.654343 
	C113.200386,251.380768 111.578308,252.283005 111.035522,253.876770 
	C110.319405,255.979431 108.120003,258.520447 112.475090,259.577789 
	C114.117683,259.976562 114.401100,261.697632 114.067444,263.660248 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M195.999817,656.595276 
	C194.256668,659.254456 192.707977,660.123840 190.797699,656.972351 
	C188.289093,652.833801 184.782959,651.182373 179.949936,653.321167 
	C177.080078,654.591248 176.793549,652.684998 177.328064,650.583557 
	C178.463974,646.117615 176.741425,645.352966 172.959808,647.016785 
	C172.052414,647.415955 171.012100,648.124451 170.160446,647.366760 
	C164.604889,642.423706 160.244247,647.094055 155.732239,649.269226 
	C153.462860,650.363220 151.727127,651.919373 150.200577,648.579102 
	C149.956223,648.044434 149.122467,647.250122 148.818604,647.351013 
	C144.206436,648.882263 142.740021,646.827820 142.433990,642.473267 
	C142.321762,640.876282 140.421158,639.878906 138.520447,640.139954 
	C136.709854,640.388428 135.963974,641.679871 136.055038,643.338867 
	C136.227158,646.474243 133.203171,649.269775 135.478302,652.788086 
	C136.197311,653.899963 134.635529,655.856140 132.209763,655.222168 
	C132.856659,653.567261 133.045135,652.105652 131.523651,651.079590 
	C128.548279,649.072693 129.438141,644.563477 125.508095,642.788940 
	C123.403473,641.838623 125.667870,636.615173 128.153717,637.215576 
	C132.208466,638.195068 136.294678,636.554321 140.608627,637.988953 
	C145.641373,638.941833 150.212326,640.649170 154.631973,641.922302 
	C163.923080,644.598572 173.797165,641.756226 182.797058,646.358398 
	C185.609848,647.796631 188.972366,649.064758 192.172195,650.150635 
	C195.192734,651.175598 196.400146,653.114563 195.999817,656.595276 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M11.991287,319.371124 
	C12.496510,319.257690 12.992564,319.515411 13.744367,319.886536 
	C15.825082,323.641998 19.056009,323.337372 22.206120,322.710205 
	C25.273033,322.099640 28.267866,321.126984 31.647480,320.156677 
	C32.686008,319.420227 33.458317,319.268097 34.657284,319.764862 
	C34.742203,320.496155 34.484547,320.992310 34.113400,321.744232 
	C33.721500,322.179840 33.183693,322.393799 33.202217,322.534515 
	C33.842754,327.399445 30.019991,330.498688 28.253052,334.751099 
	C27.389454,335.733734 26.573778,335.979584 25.328770,335.993835 
	C19.654156,335.403473 15.360734,336.146118 15.407037,343.031647 
	C15.414023,344.070618 14.658624,345.219177 13.996364,346.127899 
	C13.371819,346.984894 12.270518,347.096344 11.266984,346.719666 
	C10.039026,346.258789 10.116381,345.256683 10.431902,344.245087 
	C11.318778,341.401642 12.018505,338.475830 13.184227,335.750214 
	C14.307674,333.123413 13.931817,331.630096 11.359206,330.070435 
	C6.236100,326.964539 6.320332,326.471924 10.872753,322.413086 
	C11.682491,321.691132 11.948531,320.772003 11.991287,319.371124 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M329.640747,746.068420 
	C333.197144,744.613159 337.020966,742.894226 336.745911,739.174011 
	C336.339966,733.683533 339.091248,727.359131 331.102844,723.411926 
	C326.300812,721.039246 321.717255,717.158447 317.105438,713.864197 
	C313.649200,711.395386 310.132660,709.681213 306.051208,708.997070 
	C304.029266,708.658203 302.732605,707.648254 302.052124,705.364868 
	C310.822601,704.467590 317.883423,708.858398 324.807404,713.388184 
	C330.541473,717.139648 335.842926,720.831299 338.969421,727.913269 
	C340.431488,731.225098 341.679108,733.009399 340.492340,736.626282 
	C339.468353,739.746704 339.474121,742.818420 335.992706,744.816223 
	C332.217438,746.982666 330.860291,752.202393 326.080627,753.416077 
	C326.371948,753.957886 326.663300,754.499695 327.086243,755.317932 
	C327.031586,755.978333 326.742676,756.262146 326.066132,756.554565 
	C325.126556,756.754211 324.485260,756.815308 323.504578,756.829956 
	C322.711670,756.727722 322.293243,756.546814 321.698822,756.001953 
	C321.212769,750.180298 326.009033,748.777466 329.640747,746.068420 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M69.374268,337.224792 
	C68.555382,337.000000 68.111061,337.000000 67.333511,337.000000 
	C66.614632,337.380341 66.154411,337.527557 65.309814,337.220886 
	C62.629482,337.000000 60.258961,337.000000 57.444298,337.000000 
	C55.735111,336.107788 53.358551,336.045990 53.586830,334.017639 
	C53.787552,332.234100 56.102623,332.223328 57.690971,331.141724 
	C59.513874,329.322205 61.924232,329.019379 63.745148,327.210510 
	C65.604179,326.919708 67.089020,326.093842 67.428040,324.718323 
	C68.379929,320.856171 71.144852,320.817108 74.583130,321.000000 
	C75.444443,321.000000 75.888893,321.000000 76.666672,321.000000 
	C78.451859,323.011536 77.532135,326.365936 80.822266,327.718536 
	C80.999718,328.655273 81.001076,329.310516 80.999557,329.965790 
	C80.983299,336.958923 80.983177,336.958923 73.436920,337.000000 
	C72.011185,337.836121 70.924461,337.964233 69.374268,337.224792 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M9.002563,457.329102 
	C9.216753,455.018768 10.772188,454.559235 12.657032,454.042236 
	C12.892616,455.947449 13.462299,457.651550 15.340650,458.423645 
	C16.864733,459.050110 17.097712,457.435150 17.864481,456.295105 
	C20.151035,453.948944 17.689178,450.370880 20.736715,448.264832 
	C21.755613,446.613556 22.716116,445.461639 24.674013,445.039154 
	C25.632847,445.277130 26.099030,445.705475 26.138962,446.697449 
	C22.266756,451.783539 23.725723,455.855927 29.662373,457.176056 
	C30.711679,457.409424 31.505604,457.860229 31.590473,459.038269 
	C31.913700,463.524841 27.848091,466.944916 23.267456,466.070374 
	C22.789024,465.979034 22.284878,466.022400 21.396248,466.001648 
	C18.554676,465.087555 15.664797,463.311798 16.158531,468.706116 
	C15.775604,469.967133 15.125834,471.076630 15.394374,471.878693 
	C17.426069,477.946869 14.307416,478.185028 9.402075,477.001892 
	C9.000215,475.933319 9.000085,474.866669 8.999979,473.399994 
	C9.050750,471.855194 10.153334,470.781067 9.115278,469.285400 
	C9.000072,468.250000 9.000117,467.500000 9.000273,466.375000 
	C12.644944,463.221466 8.346602,460.438416 9.002563,457.329102 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M97.000450,579.594299 
	C94.569702,585.397705 89.427795,582.489746 85.614876,583.638428 
	C84.812004,583.880310 83.746941,583.252014 82.401337,583.012329 
	C81.695496,582.873169 81.442360,582.679260 81.120300,582.209167 
	C80.177269,581.009033 79.926392,579.929871 80.747063,578.362122 
	C80.912125,574.194031 83.827469,571.400940 84.423889,567.756042 
	C84.739899,565.824768 86.950645,565.910461 88.607201,566.050720 
	C91.018364,566.254883 93.311470,567.410400 95.870201,566.348694 
	C97.269600,565.768005 97.915794,567.020386 97.960831,568.328979 
	C98.032967,570.425110 98.874672,571.767578 101.107208,572.052063 
	C101.951996,572.159729 102.813942,572.603760 102.306610,573.659546 
	C102.067841,574.156494 101.217056,574.823303 100.836823,574.717712 
	C96.262543,573.446838 97.042099,576.652405 97.000450,579.594299 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M112.948639,274.612427 
	C113.000275,277.370575 113.000244,279.741119 113.000259,282.555847 
	C113.219231,284.515015 113.695938,286.059296 112.249496,287.706726 
	C111.419357,288.493011 110.838722,288.985992 110.129044,289.739502 
	C106.720032,289.611664 103.426491,289.309509 100.166161,288.796417 
	C98.669174,288.560822 97.175064,287.850128 97.107094,286.021271 
	C97.005867,283.297455 98.266685,280.350403 94.644119,278.566986 
	C93.574173,278.040283 95.080414,276.608887 95.775192,275.727417 
	C98.897636,271.766052 103.311867,272.529266 107.756088,272.248688 
	C110.604088,270.605621 111.791985,272.324005 112.948639,274.612427 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M72.850258,661.289062 
	C80.287636,653.669189 83.822075,661.207214 88.667267,664.935181 
	C89.166565,664.871948 89.333130,664.743896 89.749542,664.807861 
	C89.995918,667.763000 88.644516,669.136047 85.921501,668.400757 
	C83.577019,667.767761 82.585541,668.921448 81.639748,670.703857 
	C81.009521,671.891541 81.183067,674.075256 79.000450,673.704346 
	C77.177887,673.394653 75.500534,672.584900 75.057327,670.423523 
	C74.875984,669.539307 75.043282,668.265137 73.223419,668.793640 
	C71.864883,668.946594 70.638321,669.090088 70.068855,667.335449 
	C70.018509,666.403442 70.199577,665.866394 70.791519,665.201538 
	C70.975632,663.565674 71.950371,662.627747 72.850258,661.289062 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M81.122406,289.352600 
	C81.794952,294.097473 77.913986,296.039124 76.135216,299.075836 
	C75.791176,299.663177 75.020950,299.783661 74.351936,299.428772 
	C73.511681,298.983002 73.156036,298.243042 73.519081,297.357910 
	C74.019470,296.137878 74.693298,294.989136 75.194954,293.769562 
	C75.829063,292.227936 76.743622,290.325562 75.526115,289.050568 
	C73.962227,287.412811 72.617149,289.445007 71.273254,290.169800 
	C70.366829,290.658630 69.433281,291.249359 68.613503,290.292694 
	C68.285500,289.909943 68.179939,288.881165 68.458046,288.504883 
	C71.216080,284.773163 68.340729,284.074280 64.364998,283.411346 
	C67.887352,281.571320 70.435570,283.653778 72.876953,282.355927 
	C74.678398,281.398285 76.451035,281.449799 74.631210,278.844727 
	C73.588737,277.352417 73.696861,275.593292 75.458763,274.507202 
	C76.808876,273.674957 78.137299,274.351227 79.089226,275.287323 
	C80.597290,276.770294 80.040192,278.398071 78.743294,279.676300 
	C76.476433,281.910553 77.261620,283.681763 79.710159,284.649261 
	C82.217186,285.639923 81.968460,287.095245 81.122406,289.352600 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M123.908997,623.639893 
	C121.900291,623.890381 119.822090,625.315247 117.353493,624.044189 
	C116.442909,623.826721 116.056686,623.427612 115.507584,622.796753 
	C115.209686,622.460144 115.092224,622.331970 114.487228,622.101929 
	C109.537369,622.890076 105.944000,621.078613 104.602478,617.090820 
	C103.238861,613.037415 106.092636,611.473572 109.001862,609.964233 
	C110.698288,609.084167 111.976082,607.842285 111.991592,605.369141 
	C114.596962,605.149597 119.695694,610.886475 118.811172,613.690613 
	C117.143944,618.976135 118.503906,621.979919 123.908997,623.639893 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M320.068787,705.337280 
	C327.487640,705.825378 333.856659,709.425720 339.887939,713.518372 
	C343.850494,716.207153 343.978943,720.784302 343.995239,725.575684 
	C340.606049,722.821899 338.698700,718.455078 335.265289,715.258667 
	C334.046570,714.124023 333.053680,712.674133 331.419891,712.338135 
	C326.863068,711.400940 323.624451,708.354614 320.068787,705.337280 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M111.353729,500.948242 
	C110.742744,499.720184 110.490044,498.439301 110.225922,497.160767 
	C109.710472,494.665619 110.121475,492.968842 113.246605,492.964996 
	C116.765541,492.960632 119.909859,490.362854 123.773819,491.777466 
	C125.681618,492.475922 126.380180,490.288879 126.928886,488.723572 
	C127.525818,487.020691 127.883995,485.123322 130.628174,485.014038 
	C129.510208,487.915039 128.083557,490.863708 126.502853,493.727356 
	C125.760635,495.071930 124.520424,495.976929 122.878838,495.955933 
	C118.390701,495.898529 114.739479,497.686523 111.353729,500.948242 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M25.245228,439.661560 
	C23.362797,440.691925 23.210871,444.560730 21.494181,443.226501 
	C18.560671,440.946594 13.050219,442.016205 12.509928,436.737061 
	C12.217688,433.881592 14.514524,432.414398 16.521383,433.309448 
	C19.183376,434.496704 22.348923,434.304138 24.812485,436.685791 
	C25.601978,437.682983 25.761061,438.458282 25.245228,439.661560 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M20.399677,417.620514 
	C23.541435,419.904938 26.061117,424.166687 30.173618,419.126801 
	C32.079819,423.358154 27.965244,426.214874 27.130156,425.483093 
	C23.797039,422.562439 19.136086,430.159760 17.155157,423.897522 
	C16.714926,422.505798 17.797516,420.690186 17.896347,419.052673 
	C17.982796,417.620422 17.671963,416.164154 17.530754,414.655670 
	C19.502853,414.663422 19.419943,416.406311 20.399677,417.620514 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M58.526489,667.042358 
	C56.930954,670.246033 53.477257,670.981018 51.074402,671.753357 
	C48.821995,672.477295 45.439262,669.996826 42.689487,668.649780 
	C41.967113,668.295837 42.564610,667.157959 43.279091,666.590759 
	C45.805630,664.585022 55.904785,664.656128 58.526489,667.042358 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M7.003947,632.378418 
	C7.687074,628.312805 9.450432,624.797363 7.317174,620.971619 
	C6.625649,619.731445 7.708872,618.327698 9.352104,618.317322 
	C10.986835,618.306946 11.038448,619.732178 10.959494,620.860657 
	C10.706794,624.472534 10.237294,628.070557 10.037056,631.684265 
	C9.929326,633.628479 9.654043,635.664551 10.951498,637.446838 
	C12.129665,639.065308 11.682425,640.330872 9.774740,640.775574 
	C7.861587,641.221436 7.034772,640.117798 7.025523,638.283569 
	C7.016335,636.461304 7.009120,634.638977 7.003947,632.378418 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M29.056501,433.361389 
	C30.560728,433.039337 32.288254,434.668457 33.372620,431.756256 
	C34.175762,429.599365 36.748676,428.749268 38.758484,427.682922 
	C39.951511,427.049927 41.362572,425.494232 42.708893,426.951813 
	C43.884182,428.224243 45.177422,429.926300 44.580688,431.799469 
	C43.997257,433.630920 42.054958,433.007629 40.687687,432.951111 
	C38.560692,432.863159 36.949566,433.805969 35.208817,434.803284 
	C33.010677,436.062592 30.584267,437.191589 29.056501,433.361389 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M28.129187,366.932129 
	C28.979126,369.591736 30.664783,370.564270 32.846642,369.794312 
	C35.742966,368.772247 36.718170,370.121979 37.365826,372.579803 
	C37.779091,374.148132 38.254238,375.440399 36.038898,375.992706 
	C33.682205,376.580292 32.574635,376.146942 30.506409,374.104095 
	C27.037638,370.677948 25.317694,367.391022 25.742018,362.487885 
	C27.548903,363.385498 27.363220,365.232819 28.129187,366.932129 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M65.081833,309.295898 
	C64.561714,306.485321 68.839729,304.380188 65.546379,301.350098 
	C65.035980,300.880493 66.429565,299.801575 67.107407,300.337189 
	C69.538635,302.258331 72.465637,301.732086 75.158714,302.331970 
	C76.191475,302.562012 76.886856,303.427521 76.710754,304.561096 
	C76.502457,305.901794 75.414215,306.055664 74.444633,305.609863 
	C72.042343,304.505463 71.351158,305.973175 70.408104,307.757202 
	C69.381615,309.699066 67.591339,310.521240 65.081833,309.295898 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M5.293181,446.029358 
	C4.989215,444.526245 6.344962,443.134460 5.130458,441.299438 
	C6.420592,438.389313 5.072318,435.442657 5.999422,432.385803 
	C6.613523,432.199707 7.775203,432.472992 7.759719,432.588593 
	C7.050006,437.884674 13.840700,441.514404 11.010160,447.618652 
	C10.555901,448.000153 10.111649,448.000305 9.334209,448.000824 
	C8.382090,448.051422 7.850396,447.867096 7.209807,447.206421 
	C6.528913,446.686646 6.057305,446.373322 5.293181,446.029358 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M46.738060,682.909546 
	C44.225147,686.161682 40.331085,685.107788 37.294563,686.693848 
	C35.377922,687.694946 34.437405,684.958313 35.296810,683.550415 
	C36.325562,681.865234 38.387344,679.551392 39.831161,679.665588 
	C42.160603,679.849915 45.800556,678.583557 46.738060,682.909546 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M299.953003,704.648438 
	C293.757233,704.623291 288.230927,702.683838 284.048950,697.352844 
	C290.127045,697.634460 296.011444,698.797668 299.953003,704.648438 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M41.890869,346.359528 
	C44.062687,340.183929 38.577305,342.988495 36.776043,341.426422 
	C36.496868,339.378357 38.964207,339.974274 39.606834,338.631592 
	C40.117142,337.565338 39.373474,334.910797 41.837528,335.757568 
	C43.524231,336.337189 45.278881,338.044220 44.840382,339.929688 
	C44.136349,342.956909 47.640759,345.941986 44.359169,348.909607 
	C42.072430,349.395172 42.148102,347.842163 41.890869,346.359528 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M25.249012,440.244568 
	C24.999994,439.249390 24.999989,438.500000 24.999954,437.375916 
	C26.403008,436.962738 27.749649,437.187561 28.263680,438.693573 
	C28.981781,440.797577 29.828314,441.977112 32.519196,441.298309 
	C34.171516,440.881439 35.210361,442.632416 34.793812,444.311127 
	C34.239433,446.545288 33.164200,444.763763 32.278458,444.428314 
	C29.615683,443.419830 28.834795,446.696106 26.349941,446.950500 
	C25.743273,446.502197 25.485275,446.006805 25.114029,445.255859 
	C26.371073,443.629791 26.197176,442.089020 25.249012,440.244568 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M325.648071,756.035400 
	C326.291840,755.970215 326.573669,755.908447 327.034119,755.708923 
	C327.925354,755.515808 328.632904,755.437195 329.671844,755.219604 
	C330.168854,755.160400 330.334412,755.240051 330.750763,755.433838 
	C333.029480,758.691101 337.237671,760.307007 337.209076,765.029175 
	C331.244324,765.025269 326.856232,761.852722 322.490356,758.164001 
	C322.508423,757.559631 322.573914,757.323425 322.920715,756.854858 
	C323.916687,756.503784 324.581238,756.198242 325.648071,756.035400 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M20.657951,447.930786 
	C19.705050,450.448456 20.975283,453.645203 18.360407,455.906769 
	C17.888332,454.233124 18.370157,452.369385 17.151268,450.782928 
	C15.992004,449.274017 13.794222,447.547302 15.680444,445.778442 
	C17.936049,443.663177 18.561319,447.511505 20.657951,447.930786 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M46.250534,389.999176 
	C48.009079,385.752991 51.812943,384.687378 54.095333,387.738281 
	C54.935337,388.861115 55.468018,390.196472 54.376892,391.295837 
	C53.187927,392.493713 52.326149,391.346283 51.665039,390.400146 
	C50.497665,388.729401 49.080418,389.555206 47.347763,389.957458 
	C46.999847,390.000000 46.500305,389.999573 46.250534,389.999176 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M16.338390,468.959473 
	C13.357295,466.914215 14.196142,464.007080 15.911829,462.648407 
	C18.405838,460.673309 19.267881,464.037109 20.835466,465.692566 
	C20.115009,467.796783 18.405830,468.372650 16.338390,468.959473 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M63.692886,326.867249 
	C64.214424,330.856110 61.680687,331.146454 58.404991,330.999817 
	C57.020779,330.436707 55.932739,329.999847 55.092369,329.275604 
	C54.201729,328.507996 54.462757,326.815704 55.486607,327.353027 
	C58.439537,328.902740 60.707684,325.724792 63.692886,326.867249 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M16.769123,708.071594 
	C14.928126,711.613220 13.481769,708.793945 12.195477,707.903137 
	C10.425367,706.677246 10.665480,704.637329 11.478097,702.965271 
	C12.033628,701.822144 13.119619,702.139587 13.798968,703.165161 
	C14.795802,704.670105 15.754736,706.200073 16.769123,708.071594 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M113.330994,274.955505 
	C111.553719,274.026917 110.107132,273.053833 108.331032,272.040344 
	C108.001282,271.555359 108.001038,271.110748 108.001007,270.332611 
	C109.162766,268.471954 110.583076,267.994476 112.675461,268.963440 
	C112.216820,271.082703 118.998970,272.318542 113.330994,274.955505 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M37.004513,275.349365 
	C37.032379,273.329315 37.055710,271.478729 38.746613,270.692383 
	C39.930416,270.141846 40.156788,271.712067 40.867340,272.710571 
	C41.000282,274.714355 40.999908,276.428711 40.999233,278.571533 
	C40.998932,279.000000 40.701145,279.389221 40.469471,279.473389 
	C39.910370,279.597046 39.581879,279.610535 39.005859,279.584656 
	C38.759399,279.571289 38.304871,279.385529 38.152817,279.192749 
	C37.670490,277.899597 37.340214,276.799164 37.004513,275.349365 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M25.418999,699.942993 
	C26.951342,701.630981 29.463354,700.065735 30.400450,702.109009 
	C30.522741,702.375732 30.150129,703.111267 29.817518,703.305481 
	C27.650219,704.570740 25.744993,702.857788 23.700844,702.620361 
	C22.861473,702.522827 22.220167,701.638184 22.448627,700.773682 
	C22.815084,699.386902 23.821796,699.036621 25.418999,699.942993 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M4.077818,542.293030 
	C3.039542,540.224487 3.297856,538.539368 5.541506,538.628296 
	C6.914042,538.682800 8.644682,540.323364 8.377750,541.646423 
	C7.897455,544.027100 5.863854,542.865295 4.077818,542.293030 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M90.414818,247.999390 
	C90.000664,247.249695 90.000397,246.500000 90.000221,245.375458 
	C91.679062,244.013916 93.375259,243.741913 94.930885,245.157440 
	C95.337631,245.527542 95.792595,246.424545 95.616539,246.760605 
	C94.522240,248.849503 92.463913,247.676117 90.414818,247.999390 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M99.752388,254.239914 
	C102.054672,254.221359 103.313644,255.381775 103.954681,257.659760 
	C100.228928,259.735321 98.574623,258.763458 99.752388,254.239914 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M65.000458,592.375000 
	C66.963615,591.893555 68.562981,592.518982 69.470589,594.362549 
	C69.905487,595.245850 69.470772,595.990417 68.728882,596.449829 
	C67.347206,597.305359 66.243233,596.759521 65.166809,595.295166 
	C64.999901,594.250000 65.000107,593.500000 65.000458,592.375000 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M88.999275,251.598450 
	C88.361496,251.940033 87.688477,251.992874 87.092995,251.802811 
	C85.351242,251.246857 84.118706,249.652405 84.589180,248.203125 
	C85.234634,246.214783 87.031456,248.506500 88.645096,247.993317 
	C88.999214,249.066650 88.999344,250.132675 88.999275,251.598450 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M48.999626,270.625000 
	C48.521286,270.995941 48.041851,271.011993 47.564987,270.984070 
	C46.092522,270.897949 44.210938,270.723999 44.497433,268.865875 
	C44.885349,266.349976 46.865326,267.630402 48.656551,267.958679 
	C48.999599,268.750031 48.999653,269.500000 48.999626,270.625000 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M33.635185,360.014038 
	C35.098351,361.674622 34.721050,363.188263 33.248497,364.804810 
	C30.924368,363.224792 29.175636,361.467407 33.635185,360.014038 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M40.068790,463.597046 
	C37.705952,464.243896 36.331543,463.278015 35.512486,461.445282 
	C35.403667,461.201813 35.897854,460.352600 36.119827,460.349854 
	C38.239632,460.323456 39.735630,461.266724 40.068790,463.597046 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M41.003311,445.393097 
	C43.274036,444.925629 44.924473,445.613831 44.990303,448.626343 
	C42.695549,449.137756 40.729465,448.855438 41.003311,445.393097 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M38.046112,279.343292 
	C38.000763,279.000000 38.500336,279.000000 38.750122,278.999603 
	C39.166527,278.868561 39.333145,278.737885 39.749866,278.802979 
	C39.999966,278.998779 40.499462,278.999756 40.749199,278.999878 
	C41.150990,279.461975 41.231030,279.965698 41.469879,280.377319 
	C41.994492,281.281464 43.628006,282.045288 42.421322,283.205444 
	C41.217915,284.362457 40.401215,282.814301 39.677914,282.097870 
	C39.009117,281.435425 38.609577,280.501160 38.046112,279.343292 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M73.144684,530.673828 
	C70.660027,531.167419 69.835419,529.929382 70.002151,527.385010 
	C72.092377,527.137329 74.933220,526.539185 73.144684,530.673828 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M30.368402,688.381836 
	C28.141548,689.102966 27.090548,687.951477 26.513807,686.338562 
	C26.271225,685.660217 27.026476,685.081421 27.681091,685.230042 
	C29.204657,685.575989 31.008129,685.797119 30.368402,688.381836 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M59.478416,304.865967 
	C57.955753,304.814606 56.170120,305.417725 56.579426,303.165436 
	C56.745895,302.249390 57.363045,301.008392 58.550907,301.590942 
	C59.688107,302.148651 61.599285,302.877686 59.478416,304.865967 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M46.625198,393.000336 
	C48.372597,395.529816 48.133434,397.150757 44.373314,396.998291 
	C44.000130,396.833344 43.999954,396.666656 43.999508,396.250000 
	C43.399403,395.317413 43.233887,394.543365 43.755669,393.339355 
	C44.751236,393.001862 45.500820,393.001251 46.625198,393.000336 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M49.368805,351.069580 
	C50.765156,349.824127 51.968353,349.679657 52.725578,351.313660 
	C53.081009,352.080688 53.029533,353.180481 52.086765,353.390656 
	C50.614059,353.718933 49.542389,352.997894 49.368805,351.069580 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M28.999695,433.000000 
	C28.837696,432.993103 28.676056,432.982208 28.514297,432.979980 
	C26.662556,432.954376 25.826128,432.067352 26.188183,430.201630 
	C26.353615,429.349091 26.921139,428.416260 27.698475,428.593140 
	C29.788195,429.068695 28.692478,430.947174 29.000187,432.610229 
	C29.000610,433.000000 29.000000,433.000000 28.999695,433.000000 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M43.696968,392.866333 
	C44.000992,393.751801 44.000336,394.501190 43.999458,395.625275 
	C42.172611,395.688538 40.286179,394.959869 40.615208,392.884583 
	C40.838970,391.473236 42.402336,392.329651 43.696968,392.866333 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M83.676956,298.006287 
	C84.859535,298.113251 86.282188,297.801453 86.272591,298.898651 
	C86.258842,300.469055 84.658150,299.775238 83.356796,299.998535 
	C82.512131,299.688751 81.617195,299.385345 81.611046,299.064850 
	C81.590027,297.968781 82.620079,298.142548 83.676956,298.006287 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M54.182690,343.762970 
	C52.985798,343.251434 51.947742,342.679993 52.820488,341.592865 
	C53.490921,340.757751 54.679699,340.851685 55.363411,341.681213 
	C56.177128,342.668549 55.680767,343.436249 54.182690,343.762970 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M46.686249,414.860596 
	C46.893276,415.886444 46.609291,416.643951 45.312126,416.946777 
	C43.766991,416.782745 42.686325,416.207001 43.447407,414.842529 
	C44.145344,413.591248 45.363987,414.256958 46.686249,414.860596 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M10.204115,506.997375 
	C10.883318,508.188507 11.109115,509.166016 9.886647,509.633942 
	C8.944117,509.994720 8.288288,509.363373 8.184413,508.477753 
	C8.041504,507.259369 8.153414,506.059052 10.204115,506.997375 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M41.753998,346.238892 
	C42.505177,346.878510 43.010349,347.757019 43.757759,348.817749 
	C43.766396,349.969330 43.453766,351.109589 42.184532,350.289917 
	C40.750130,349.363556 40.783440,347.920807 41.753998,346.238892 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M21.718325,683.240112 
	C23.243261,684.762451 22.145849,685.316284 21.013529,685.734741 
	C20.356232,685.977661 19.602234,685.758789 19.532570,684.966370 
	C19.422457,683.713928 20.386726,683.362549 21.718325,683.240112 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M28.680027,468.978058 
	C29.986973,467.792694 31.210827,467.675018 32.548416,469.013672 
	C31.175430,470.313263 29.956394,470.225800 28.680027,468.978058 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M49.112877,429.255737 
	C50.232605,428.629425 51.706203,427.145020 52.607067,429.088470 
	C53.262295,430.502014 51.877949,430.858826 50.355087,430.987885 
	C49.742283,430.503845 49.484398,430.007660 49.112877,429.255737 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M75.181503,350.229553 
	C75.138245,349.197632 75.336700,348.246552 76.357689,348.462799 
	C77.373001,348.677856 77.974174,349.442078 77.214264,350.804626 
	C76.454247,350.820526 75.908798,350.639832 75.181503,350.229553 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M80.664291,578.075928 
	C80.999802,579.066711 80.999908,580.133362 81.000000,581.600037 
	C78.455872,581.121948 78.378593,579.813660 80.664291,578.075928 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M42.482216,455.007996 
	C43.249374,454.037292 44.005890,454.028961 44.564850,454.808838 
	C44.962784,455.364075 44.876163,456.168884 44.205585,456.430664 
	C43.282574,456.791016 42.750935,456.122284 42.482216,455.007996 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M33.458881,335.111572 
	C33.054485,334.092316 32.906384,333.258392 33.695198,332.731659 
	C33.855423,332.624695 34.644356,333.024506 34.719048,333.304077 
	C34.963104,334.217529 35.165054,335.266479 33.458881,335.111572 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M58.209549,513.879761 
	C56.940105,513.797058 55.896725,513.392456 56.719360,512.127319 
	C57.372589,511.122742 58.326828,511.080933 58.947495,512.705200 
	C58.873657,513.304504 58.679722,513.557678 58.209549,513.879761 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M44.375000,499.000000 
	C44.561348,497.009857 45.396938,496.246521 46.937500,498.679260 
	C46.250000,499.000793 45.500000,499.000397 44.375000,499.000000 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M25.211111,336.233643 
	C25.570978,335.185120 26.417093,335.016724 27.665836,335.005127 
	C27.744734,336.551361 27.054625,337.337280 25.211111,336.233643 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M9.532745,610.739136 
	C10.539111,610.482666 10.852931,610.983582 10.725895,611.639099 
	C10.666800,611.944031 10.095361,612.429199 9.875689,612.372253 
	C9.161935,612.186951 9.142779,611.559692 9.532745,610.739136 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M70.720047,665.039062 
	C70.819824,665.545471 70.639633,666.090942 70.229874,666.818237 
	C69.029182,666.103638 69.254822,665.481079 70.720047,665.039062 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M103.760696,1199.428223 
	C100.577881,1201.219238 97.669548,1201.921021 96.054947,1197.352295 
	C92.477394,1191.234985 86.666611,1188.200195 81.196907,1184.290405 
	C81.061226,1182.902344 81.039261,1181.850098 81.029892,1180.403198 
	C82.191895,1179.357056 83.268105,1178.408203 84.503029,1178.104126 
	C90.805466,1176.552856 94.898483,1172.805176 96.661247,1166.527466 
	C97.169533,1164.717407 97.794403,1162.946655 100.222343,1163.087891 
	C102.545006,1163.223022 103.264084,1165.092407 103.741905,1166.794678 
	C105.360443,1172.560913 108.770828,1176.321777 114.662514,1177.969849 
	C116.587570,1178.508423 119.006256,1179.261108 118.908463,1182.158691 
	C118.815971,1184.899170 116.408371,1185.127197 114.653755,1185.655396 
	C107.924339,1187.681152 104.677231,1192.359497 103.760696,1199.428223 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M79.175507,1058.831299 
	C80.098373,1060.400391 80.355621,1061.907715 79.337776,1062.866211 
	C75.410583,1066.564453 75.938972,1071.620483 75.031036,1076.223999 
	C74.661415,1078.098022 74.531700,1080.418945 71.945930,1079.328247 
	C69.645004,1078.357666 65.054901,1082.947388 64.973633,1076.572510 
	C64.965385,1075.925415 64.914917,1074.822510 64.595726,1074.703369 
	C58.670731,1072.490967 61.905357,1066.398682 59.477371,1062.714111 
	C58.733509,1061.585205 58.793045,1060.117310 59.822201,1059.009644 
	C61.025906,1057.714111 62.601612,1057.766602 63.934200,1058.556763 
	C67.043922,1060.400757 69.793999,1060.116943 72.660347,1057.962769 
	C74.760117,1056.384521 77.001122,1056.596313 79.175507,1058.831299 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M128.635101,1175.384277 
	C125.568169,1174.435303 122.813858,1173.790649 120.195839,1172.789551 
	C117.083069,1171.599121 115.915825,1169.612061 118.677338,1166.828247 
	C121.593857,1163.888306 124.450844,1160.874268 127.542488,1158.127319 
	C130.776093,1155.254517 132.732346,1155.786987 134.039246,1159.878052 
	C134.989655,1162.853271 135.536316,1165.963379 136.172363,1169.032104 
	C137.854553,1177.148193 136.892792,1178.005981 128.635101,1175.384277 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M128.712265,1219.170532 
	C129.180191,1218.994873 129.362228,1218.989624 129.817230,1218.974365 
	C130.730927,1218.412964 131.460175,1218.255005 132.607132,1218.681152 
	C133.475632,1219.519531 134.412918,1220.109253 134.481430,1220.786865 
	C134.935211,1225.275391 137.658997,1228.713135 141.259735,1227.387329 
	C146.349930,1225.513062 149.456451,1230.940674 154.055695,1229.613037 
	C154.763229,1229.408691 155.013824,1230.777222 154.777451,1231.626343 
	C154.464554,1232.750366 153.561218,1232.758423 152.807617,1232.318604 
	C149.322830,1230.284790 147.026764,1231.308960 144.995346,1234.658813 
	C143.386597,1237.311890 141.140915,1235.507446 139.283401,1234.610352 
	C136.771408,1233.397217 134.370316,1231.770874 133.564835,1236.348511 
	C133.346100,1237.591675 132.001328,1237.997070 130.724472,1237.838257 
	C129.506836,1237.686768 129.270325,1236.761841 129.012100,1235.724609 
	C128.101059,1232.064819 127.088974,1228.430176 126.057602,1224.393066 
	C126.473572,1222.272461 127.281891,1220.719238 128.712265,1219.170532 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M47.689751,1038.641602 
	C48.566288,1041.868042 50.001404,1042.754883 52.522907,1040.907715 
	C53.689060,1040.053467 54.644474,1038.538818 56.335030,1039.959351 
	C57.104630,1040.605957 57.972542,1041.375000 57.707085,1042.446777 
	C57.114326,1044.840088 55.031960,1043.797974 53.549820,1043.981445 
	C52.155975,1044.154053 51.156628,1044.590942 50.214180,1045.756958 
	C46.602440,1050.225342 41.220871,1050.672607 36.937889,1046.785278 
	C35.019798,1045.044434 33.987679,1042.538940 31.811985,1040.835449 
	C30.445715,1039.765625 31.913992,1038.549438 33.667824,1038.045166 
	C38.525467,1035.030273 42.973804,1035.668579 47.689751,1038.641602 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M111.117661,1144.994263 
	C117.046951,1145.832764 119.293594,1148.103516 118.996559,1152.879883 
	C118.734459,1157.094360 116.342400,1159.605347 112.140701,1159.950195 
	C108.007385,1160.289551 104.997238,1158.501709 104.105560,1154.251709 
	C103.125435,1149.580078 105.400497,1146.545776 111.117661,1144.994263 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M107.180817,1111.153320 
	C102.628510,1112.293579 98.449005,1113.163940 95.485954,1116.845459 
	C94.000412,1118.691040 91.817612,1118.213379 90.097832,1116.876343 
	C88.079559,1115.307251 89.821251,1113.973267 90.617744,1112.634766 
	C91.481239,1111.183472 92.074867,1109.667969 92.000214,1107.937012 
	C91.807648,1103.472046 94.175041,1103.110840 97.309174,1104.953857 
	C100.403656,1106.773560 103.373535,1108.057739 107.113091,1107.125854 
	C108.691963,1106.732300 111.302475,1106.424927 111.412491,1109.018677 
	C111.509483,1111.305298 109.108459,1110.808594 107.180817,1111.153320 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M97.115120,1087.772705 
	C99.266495,1087.365356 99.664696,1085.860840 99.918022,1084.395264 
	C100.230179,1082.589600 100.798904,1081.096313 102.931915,1081.142578 
	C105.260338,1081.193115 105.458687,1083.277344 105.529793,1084.750488 
	C105.612656,1086.467407 105.345718,1088.880005 102.942574,1088.846558 
	C99.879097,1088.804077 98.208130,1089.831543 96.914330,1092.689087 
	C95.848587,1095.042847 93.338974,1095.541138 90.887878,1094.381958 
	C88.549110,1093.276001 86.646721,1092.080078 87.503700,1088.711914 
	C88.500214,1084.795654 88.847649,1080.714111 89.595901,1075.944946 
	C93.540176,1079.528320 90.124542,1086.803711 97.115120,1087.772705 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M38.308990,969.580078 
	C39.442017,972.797485 37.917870,974.408264 35.309628,974.848145 
	C32.580418,975.308350 29.740677,974.770142 27.208128,976.608582 
	C26.083305,977.425049 25.127964,976.172974 24.587545,975.057129 
	C23.788511,973.407227 23.823229,971.816101 25.129219,970.449524 
	C29.080397,966.315125 33.564472,967.856812 38.308990,969.580078 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M48.210213,1038.793945 
	C43.688354,1038.009155 39.314808,1037.838745 34.458332,1038.000000 
	C34.000908,1030.093750 33.987038,1030.129883 41.167194,1032.886597 
	C44.200161,1034.051025 47.120838,1035.262939 48.210213,1038.793945 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M140.639740,1132.747803 
	C142.390106,1134.616211 144.797653,1135.994385 143.406448,1138.768188 
	C142.517090,1140.541382 140.888596,1141.305786 138.808517,1140.793091 
	C135.330811,1139.936035 134.704697,1138.067139 137.242752,1135.374512 
	C138.189346,1134.370239 138.526550,1132.566650 140.639740,1132.747803 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M86.209427,1133.830078 
	C84.715820,1134.600220 85.091164,1137.600952 83.047874,1136.515259 
	C81.321915,1135.598267 80.741051,1133.219849 80.578201,1131.411011 
	C80.399185,1129.422607 82.363258,1130.248535 83.572746,1130.226929 
	C83.988068,1130.219604 84.620560,1129.998779 84.769699,1129.689941 
	C85.294708,1128.603149 85.347343,1126.421631 87.071304,1127.542969 
	C88.403732,1128.409546 89.450775,1130.422241 89.715820,1132.071411 
	C90.223145,1135.227905 86.864937,1131.571899 86.209427,1133.830078 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M132.966980,1218.522949 
	C132.227219,1218.916382 131.514191,1218.943481 130.445679,1218.967529 
	C129.693115,1217.879395 129.164886,1216.821289 128.934647,1215.701904 
	C128.688919,1214.507202 125.869965,1211.814575 130.517761,1213.012207 
	C132.112427,1213.423218 132.585419,1210.402222 134.238113,1209.257446 
	C135.049377,1210.444336 135.777969,1211.510254 136.757782,1212.786377 
	C137.507568,1213.404541 138.066315,1213.758789 138.130173,1214.710693 
	C136.856781,1216.902222 133.147903,1214.721191 132.966980,1218.522949 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M108.924545,1123.727905 
	C109.021919,1126.325073 109.579483,1128.904419 106.390434,1129.659912 
	C103.903999,1130.249023 102.468628,1128.552734 101.931770,1126.618774 
	C100.611504,1121.862915 104.162766,1122.144043 107.553490,1122.017822 
	C108.417831,1122.401367 108.773697,1122.845459 108.924545,1123.727905 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M118.009506,1215.609131 
	C117.407555,1215.172241 116.799927,1214.334717 116.114479,1213.389893 
	C114.634033,1214.841797 116.405296,1216.134888 115.967270,1217.737793 
	C115.308617,1218.453491 114.711700,1218.239136 113.880592,1217.686035 
	C113.218712,1216.934570 113.056503,1216.313965 113.009476,1215.327881 
	C112.602386,1214.115479 112.383766,1213.071289 111.772141,1212.369507 
	C110.527336,1210.941162 109.754662,1209.932129 111.850883,1208.408936 
	C113.830185,1206.970581 115.098717,1207.262573 116.410408,1209.070557 
	C117.749947,1210.916870 118.148552,1212.987061 118.009506,1215.609131 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M118.004715,1216.007446 
	C120.394592,1216.446167 122.450066,1215.370117 124.801712,1214.793579 
	C126.145523,1216.400391 126.193832,1218.046143 126.028931,1220.113037 
	C125.652283,1221.027222 125.284164,1221.437500 124.396484,1221.857178 
	C119.893814,1222.617188 119.593224,1219.010986 118.065659,1216.365967 
	C118.015190,1216.009766 118.000000,1216.000000 118.004715,1216.007446 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M113.707367,1216.724365 
	C114.506676,1217.219971 114.986191,1217.519531 115.715065,1217.954102 
	C116.344795,1221.861572 115.551277,1224.613281 110.443100,1223.938721 
	C108.948524,1222.084595 110.001427,1221.630493 111.861198,1221.492310 
	C112.486069,1221.338257 112.789185,1221.150879 113.224358,1220.727539 
	C113.404922,1220.565430 113.130722,1220.165771 113.227768,1220.393311 
	C113.083282,1220.880737 112.812569,1221.106567 112.245361,1221.400635 
	C111.141563,1221.463257 110.311630,1221.604736 109.250168,1221.217896 
	C109.005402,1219.936768 108.998405,1218.878540 109.001785,1217.424438 
	C109.751724,1216.989014 110.491280,1216.949341 111.600037,1216.879150 
	C112.399139,1216.552002 112.872002,1216.445557 113.707367,1216.724365 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M60.294811,1173.244507 
	C58.768177,1172.736328 57.343197,1171.538574 58.543419,1170.887451 
	C62.331264,1168.832397 58.751957,1167.995239 58.112839,1166.349365 
	C60.086601,1166.020020 62.107697,1166.017822 64.563927,1166.007812 
	C64.999062,1166.000000 65.000000,1166.000000 64.999252,1166.000000 
	C64.821198,1168.476074 65.473412,1170.976440 64.475571,1173.641357 
	C63.706490,1174.036377 63.223137,1174.148682 62.342010,1174.174561 
	C61.450794,1174.040161 60.993626,1173.820679 60.294811,1173.244507 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M69.782608,1152.736572 
	C68.338326,1154.390991 66.397804,1155.664185 67.900955,1158.633545 
	C67.495575,1158.971191 67.080093,1158.975586 66.352165,1158.983521 
	C65.724403,1158.863647 65.462318,1158.668701 65.131210,1158.187134 
	C64.879860,1157.670898 64.682518,1157.424316 64.205322,1157.120850 
	C62.864304,1156.925415 62.172050,1156.142944 61.243275,1155.182251 
	C61.040798,1154.949585 61.024109,1155.010132 61.045242,1154.986572 
	C60.555355,1154.299194 60.897209,1153.784058 61.645409,1153.137451 
	C62.841049,1151.964355 64.067184,1152.004883 65.640427,1151.968750 
	C66.347519,1151.640137 66.746017,1151.433472 67.536011,1151.332520 
	C68.551041,1151.557861 69.065277,1152.007568 69.782608,1152.736572 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M98.878357,1126.693359 
	C99.240082,1131.147949 95.133156,1129.538574 93.583641,1131.985596 
	C92.900658,1128.805786 92.321396,1126.416992 94.688721,1124.136230 
	C97.200127,1123.276001 97.969513,1124.875854 98.878357,1126.693359 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M128.824249,1129.223877 
	C130.873489,1128.045898 133.029480,1127.014282 134.294113,1129.267700 
	C135.387177,1131.215332 133.786911,1133.529785 132.287491,1134.283691 
	C130.690323,1135.086792 128.317383,1134.556519 128.114670,1131.382324 
	C127.999840,1130.398193 128.154602,1129.858765 128.824249,1129.223877 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M5.513041,1048.608643 
	C3.863535,1047.395508 2.491570,1046.333130 4.108979,1044.481812 
	C5.169187,1043.268311 6.498861,1042.766113 8.069037,1043.249390 
	C9.079698,1043.560425 9.900475,1044.331055 9.776135,1045.379883 
	C9.512322,1047.604736 8.245176,1048.907837 5.513041,1048.608643 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M125.196289,1130.744141 
	C118.031807,1128.734863 117.467209,1127.669067 121.648544,1124.026123 
	C122.698387,1124.994751 123.432854,1125.716553 124.789268,1124.231445 
	C126.142166,1124.919800 125.998360,1126.113647 125.933212,1127.635498 
	C126.100731,1128.896240 126.122650,1129.771484 125.196289,1130.744141 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M142.660797,1205.960938 
	C142.757614,1207.872559 144.230545,1209.897949 142.245544,1211.833496 
	C141.824615,1212.146851 141.655609,1212.264526 141.243912,1212.191040 
	C140.537079,1211.332520 139.921204,1210.722534 139.642334,1209.984985 
	C139.156906,1208.701172 136.837708,1208.076904 137.878586,1206.398438 
	C138.944519,1204.679688 140.757156,1205.434204 142.660797,1205.960938 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M11.528528,943.363159 
	C13.455457,941.853943 15.467853,941.051880 16.610720,943.354370 
	C17.328011,944.799500 15.765603,946.189819 14.357831,946.585205 
	C12.050547,947.233276 11.007765,946.047241 11.528528,943.363159 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M53.932312,1151.865234 
	C49.575371,1153.304321 51.359901,1150.026855 51.089275,1148.471069 
	C50.886303,1147.304321 51.463863,1145.858765 52.941078,1146.362671 
	C55.822357,1147.345825 55.084511,1149.502319 53.932312,1151.865234 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M124.805008,1221.258057 
	C124.964775,1219.239990 124.990417,1217.546387 125.025665,1215.429688 
	C126.740738,1215.013062 126.865990,1216.547119 127.758324,1217.786621 
	C128.208511,1218.235474 128.426193,1218.462036 128.826111,1218.839844 
	C128.999191,1219.000000 129.000000,1219.000000 128.999084,1219.000000 
	C128.943237,1220.960815 128.736496,1222.849365 126.343452,1223.930664 
	C125.272339,1223.350952 124.888329,1222.512451 124.805008,1221.258057 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M18.040577,1058.659302 
	C17.250063,1059.000000 16.500330,1059.000000 15.375755,1059.000000 
	C13.243022,1057.294434 14.452242,1056.087646 15.709260,1054.916626 
	C16.377218,1054.294312 17.182035,1054.141724 17.929470,1054.718506 
	C19.368488,1055.829224 18.272732,1057.105225 18.040577,1058.659302 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M99.169762,1126.826660 
	C97.806747,1126.115356 96.682297,1125.182129 95.283165,1124.091675 
	C95.076584,1122.985962 94.885544,1121.861084 95.967644,1121.389160 
	C97.111839,1120.890381 97.824585,1121.685913 98.237152,1122.612061 
	C98.802414,1123.881104 100.108597,1124.959106 99.169762,1126.826660 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M152.655991,1173.092773 
	C152.960251,1174.835205 156.859482,1176.463013 153.074600,1178.733887 
	C149.884430,1177.323364 150.494568,1175.309326 152.655991,1173.092773 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M7.971382,1055.536011 
	C7.834150,1057.666992 8.425770,1059.492310 7.198414,1061.564453 
	C5.297335,1058.973389 5.605630,1056.349609 6.649582,1054.039673 
	C7.076086,1053.096069 7.734306,1054.433960 7.971382,1055.536011 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M157.952560,1168.698975 
	C158.001770,1170.062866 158.000473,1171.135986 157.998672,1172.611816 
	C157.553207,1173.013184 157.108261,1173.011719 156.329742,1172.995605 
	C155.993118,1171.266479 153.812469,1170.571289 154.006653,1168.413330 
	C155.267456,1168.513672 156.739288,1166.685669 157.952560,1168.698975 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M115.463768,1123.924316 
	C114.726913,1125.553589 115.545334,1127.275269 113.903564,1128.569946 
	C112.409645,1126.830933 112.732292,1124.803101 113.381157,1123.145630 
	C113.932510,1121.737183 115.139565,1121.748413 115.463768,1123.924316 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M60.422081,1159.381836 
	C61.799709,1160.563599 61.994797,1161.518311 60.248440,1161.858643 
	C59.075718,1162.087280 57.664520,1161.678101 57.606194,1160.390137 
	C57.528896,1158.683105 59.029213,1159.117676 60.422081,1159.381836 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M112.608635,1221.005127 
	C112.430893,1222.235596 110.535057,1221.762695 110.088913,1223.597168 
	C110.024185,1224.087646 110.014328,1224.267090 109.995850,1224.716064 
	C107.760689,1224.535767 107.729408,1223.171143 108.765961,1221.229614 
	C110.050957,1220.978027 111.089516,1220.961060 112.608635,1221.005127 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M125.347443,1130.981934 
	C125.117363,1130.129517 125.283531,1129.285889 125.681900,1128.216187 
	C126.760277,1128.249146 127.606445,1128.508057 128.757874,1128.908813 
	C128.892029,1129.582397 128.720901,1130.114136 128.339111,1130.831787 
	C127.333527,1131.008545 126.538605,1130.999634 125.347443,1130.981934 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M151.973068,1186.710449 
	C151.544846,1187.066040 151.126923,1187.053589 150.396439,1187.048950 
	C148.699921,1185.757202 148.682816,1184.148438 150.443283,1183.702637 
	C152.400696,1183.206909 151.779099,1185.240845 151.973068,1186.710449 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M140.683304,1212.142090 
	C141.001221,1212.000000 141.497620,1212.011353 141.745636,1212.020264 
	C141.824234,1214.044800 140.667343,1214.962158 138.343796,1214.992676 
	C137.722595,1214.506348 137.463928,1214.012573 137.107117,1213.257812 
	C138.127777,1212.759155 139.246582,1212.521729 140.683304,1212.142090 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M18.306938,829.449707 
	C17.577309,828.884094 17.132736,828.321533 17.626301,827.738464 
	C18.151205,827.118347 18.813982,827.445007 19.197359,827.999451 
	C19.725130,828.762817 19.784485,829.462463 18.306938,829.449707 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M124.691360,1124.038086 
	C124.543076,1125.068237 124.011032,1126.081299 123.478989,1127.094360 
	C122.504204,1126.556641 122.330551,1125.563477 122.040649,1124.307007 
	C122.775803,1124.003662 123.541695,1124.012329 124.691360,1124.038086 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M61.649223,1152.860352 
	C61.713955,1153.479126 61.495258,1153.973145 61.171471,1154.715088 
	C60.106285,1154.831787 59.070683,1154.624512 59.249428,1153.375977 
	C59.460110,1151.904297 60.490917,1152.411499 61.649223,1152.860352 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M70.156601,1152.800171 
	C69.449364,1152.826050 68.905556,1152.647705 68.179184,1152.243408 
	C67.401619,1151.328735 67.233620,1150.555054 67.771378,1149.345581 
	C68.010735,1149.002930 68.503136,1149.002686 68.749298,1149.001343 
	C69.436974,1150.198608 69.878494,1151.397217 70.156601,1152.800171 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M61.064339,1155.271240 
	C62.168007,1155.019653 63.063023,1155.501587 63.845192,1156.728271 
	C62.035599,1158.567017 61.605564,1156.990112 61.064339,1155.271240 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M67.676521,1149.068726 
	C68.007607,1149.756348 68.004478,1150.509644 67.998978,1151.640137 
	C67.554100,1152.005371 67.111588,1151.993530 66.337440,1151.967407 
	C66.048172,1150.822388 65.930435,1149.615845 67.676521,1149.068726 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M22.765850,990.514160 
	C23.592909,990.153198 24.220839,990.180603 24.381613,990.902405 
	C24.431149,991.124695 23.929039,991.678650 23.622196,991.728638 
	C22.966993,991.835327 22.473198,991.512390 22.765850,990.514160 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M64.779388,1159.191040 
	C65.167366,1159.730103 65.344566,1160.458496 65.466515,1161.196045 
	C65.471458,1161.225952 64.839561,1161.496338 64.700050,1161.392334 
	C63.917759,1160.808960 64.096107,1160.090942 64.779388,1159.191040 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M124.741318,1221.067139 
	C125.269470,1221.739502 125.599815,1222.545288 125.965378,1223.675537 
	C125.104744,1223.891602 124.346100,1223.596802 124.047348,1222.286377 
	C124.113136,1221.668579 124.296776,1221.411987 124.741318,1221.067139 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M113.985840,1216.638916 
	C113.569107,1216.922852 113.111023,1216.925415 112.311096,1216.888306 
	C112.164581,1216.344727 112.359924,1215.840942 112.777069,1215.170898 
	C113.314079,1215.455688 113.629288,1215.906494 113.985840,1216.638916 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M109.151794,1123.841064 
	C108.636238,1123.546875 108.338219,1123.075317 107.995026,1122.318970 
	C108.841843,1122.212402 109.987755,1122.169312 109.151794,1123.841064 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M148.031891,1193.235352 
	C148.559937,1193.158569 149.106293,1193.340210 149.846069,1193.740234 
	C149.287460,1194.184448 148.418198,1194.912354 148.031891,1193.235352 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M65.005898,1158.229126 
	C65.323944,1158.095825 65.584206,1158.292114 65.914719,1158.774048 
	C65.866440,1158.989990 65.693161,1158.992920 65.259941,1158.997925 
	C65.000946,1158.828735 65.001892,1158.657349 65.005898,1158.229126 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M143.105606,1205.949097 
	C143.000000,1206.000000 143.229446,1205.618896 143.229446,1205.618896 
	C143.229446,1205.618896 143.211212,1205.898193 143.105606,1205.949097 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M128.879349,1218.790527 
	C128.693451,1218.875000 128.440109,1218.681519 128.119583,1218.209717 
	C128.304733,1218.126343 128.557907,1218.319946 128.879349,1218.790527 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1094.911011,24.642853 
	C1095.990356,29.234440 1100.542969,29.303278 1103.245117,31.377552 
	C1105.615112,33.196789 1107.216187,34.273052 1107.041870,37.301651 
	C1106.953125,38.845291 1107.257690,40.523808 1109.200195,40.669216 
	C1111.225220,40.820808 1110.846436,39.017853 1111.052002,37.793484 
	C1111.443481,35.463127 1112.964478,34.676414 1115.031982,35.243141 
	C1116.556519,35.661049 1118.617188,36.082970 1118.533325,38.193748 
	C1118.452637,40.230095 1117.729370,41.848373 1114.991699,42.071671 
	C1108.294922,42.617874 1103.552734,47.513100 1098.005005,50.535263 
	C1095.765869,51.755112 1098.045044,53.719376 1099.602417,54.571617 
	C1101.201416,55.446552 1103.599121,54.618351 1104.313232,57.381920 
	C1105.085693,60.370899 1105.373413,63.251785 1104.359741,66.189125 
	C1102.374634,71.941780 1103.142578,73.418587 1109.057617,74.953499 
	C1110.000122,75.198074 1111.380249,74.934822 1111.643311,76.044754 
	C1113.064941,82.041222 1118.376953,81.830765 1122.689087,82.865273 
	C1126.805542,83.852852 1130.033325,86.572845 1134.000732,87.865051 
	C1136.483765,88.673798 1136.023071,91.052567 1134.255615,92.687721 
	C1131.655884,95.093010 1128.596802,96.721573 1127.170776,100.600647 
	C1126.283691,103.013977 1121.944702,102.438164 1119.399414,104.080688 
	C1118.404541,104.722755 1118.133911,102.262451 1118.001709,100.994194 
	C1117.442993,95.638275 1111.880371,92.824318 1107.218750,95.603645 
	C1105.016602,96.916618 1104.509644,98.885384 1105.637817,101.190987 
	C1106.772827,103.510208 1107.952393,105.772690 1105.053833,107.744797 
	C1104.455566,108.151802 1104.278687,108.930611 1104.874878,109.449532 
	C1105.732666,110.196213 1106.771362,110.002625 1107.656860,109.454788 
	C1113.094360,106.090836 1118.569092,105.361526 1124.178345,109.054108 
	C1125.799194,110.121185 1127.676758,109.956139 1129.504883,110.039261 
	C1132.289062,110.165855 1132.747437,112.396187 1132.497559,114.302361 
	C1132.026367,117.894875 1123.981079,120.942810 1120.996948,118.874023 
	C1119.607788,117.910942 1118.760132,115.074745 1116.691895,116.480110 
	C1114.442139,118.008842 1112.427979,120.255569 1112.069580,123.231628 
	C1111.770508,125.715782 1112.156616,128.109024 1113.161987,130.528046 
	C1115.158203,135.331100 1114.340332,136.543854 1109.258545,137.076431 
	C1107.473755,137.263458 1106.740356,138.178314 1107.463501,139.713394 
	C1108.370361,141.638733 1107.184692,146.119431 1111.095215,144.750580 
	C1117.253052,142.595123 1118.994019,145.946548 1120.726074,150.767258 
	C1122.716797,152.578491 1122.602661,154.391663 1121.340210,156.653595 
	C1119.744995,157.380936 1118.339600,157.523895 1117.267822,158.197540 
	C1115.335205,159.412125 1114.206909,160.958008 1117.076416,162.536362 
	C1118.354492,163.239319 1120.099976,163.814468 1119.677002,165.656174 
	C1119.228516,167.608521 1117.179565,166.691223 1115.904907,167.213821 
	C1113.628296,168.147095 1111.428101,169.360092 1108.413574,168.999283 
	C1104.522583,171.700577 1100.496338,170.149017 1096.312500,170.356277 
	C1090.874512,167.221130 1085.700928,169.921783 1080.625732,170.107117 
	C1078.123535,170.198486 1078.910645,172.828354 1079.433960,174.507095 
	C1080.157349,176.827255 1081.668091,178.952255 1080.871094,181.933640 
	C1078.416626,185.483475 1075.241089,188.006042 1073.689209,191.850769 
	C1072.785645,194.089233 1070.380615,194.495667 1068.187134,194.647980 
	C1065.168701,194.857574 1063.593262,196.290695 1063.705933,199.382080 
	C1063.857056,203.527344 1062.494873,206.868896 1058.720825,208.985062 
	C1058.147217,209.306595 1057.726807,209.849899 1057.313721,210.379501 
	C1052.259277,216.858810 1044.527344,217.735596 1038.023804,212.566895 
	C1037.244995,211.947906 1036.523071,211.270355 1035.633423,210.786530 
	C1030.926880,208.226959 1029.129883,208.878036 1027.201660,213.898163 
	C1026.900879,214.681351 1026.512329,215.406876 1025.677124,216.318665 
	C1023.768799,217.649826 1021.922791,217.977509 1019.669312,218.274536 
	C1017.368347,218.255325 1015.178101,217.822739 1014.428894,216.224030 
	C1012.237976,211.548584 1008.405273,209.665680 1003.554077,208.491592 
	C1004.104187,204.262115 1003.756470,201.507523 998.644836,201.007324 
	C995.055969,200.656174 994.693665,196.129105 992.096191,193.644531 
	C991.070435,190.669724 990.892273,188.091080 992.666382,185.300964 
	C996.301636,185.001907 999.586304,185.369797 1002.318115,182.604401 
	C1003.467346,181.441010 1006.072571,181.347275 1007.020630,182.361145 
	C1009.917175,185.458878 1016.516113,184.229401 1016.445923,191.012589 
	C1016.418579,193.657333 1019.919556,193.264862 1021.883972,192.863953 
	C1024.868286,192.254913 1028.368164,191.785965 1028.931274,187.753479 
	C1029.159668,186.118317 1029.285278,184.362900 1028.941284,182.774048 
	C1027.985962,178.362137 1028.554810,174.844269 1031.694214,171.033066 
	C1034.968018,167.058777 1029.863159,166.692322 1026.985718,164.890839 
	C1034.549316,163.571396 1032.654785,158.776855 1031.592651,154.324432 
	C1030.862061,151.261719 1030.003174,148.229797 1029.244141,145.173553 
	C1028.906372,143.813095 1028.962646,142.474319 1030.268066,141.597397 
	C1031.664429,140.659470 1032.860596,141.325684 1033.803833,142.364273 
	C1036.521606,145.356689 1039.791870,146.199036 1043.109985,143.929932 
	C1045.573975,142.244949 1045.705933,133.508636 1043.689941,131.134995 
	C1043.294922,130.669937 1042.595825,130.083435 1042.104248,130.139740 
	C1037.826050,130.629501 1034.614380,127.787743 1030.915649,126.807182 
	C1026.708862,125.691948 1024.020020,123.183136 1021.347046,120.441444 
	C1018.856384,117.886749 1016.373108,117.483299 1013.266235,118.847771 
	C1012.071472,119.372498 1010.765625,119.782997 1009.476379,119.916016 
	C1008.139282,120.053978 1006.620361,119.799004 1006.503723,118.039597 
	C1006.404602,116.544296 1007.705933,116.082825 1008.887817,116.048157 
	C1012.719421,115.935776 1014.606812,111.739174 1017.713623,111.317017 
	C1022.729065,110.635490 1028.025513,110.137627 1033.038330,111.900055 
	C1035.775879,112.862549 1038.541626,113.165421 1041.384888,112.984383 
	C1043.237793,112.866402 1045.687622,113.685585 1045.940186,110.570221 
	C1046.150146,107.982910 1046.209351,105.424606 1042.781250,104.793076 
	C1042.458740,104.733673 1042.150024,104.580368 1041.844971,104.447563 
	C1040.149780,103.709358 1037.381958,104.380859 1037.238525,101.560188 
	C1037.130859,99.445580 1038.979492,98.468742 1040.681396,97.716026 
	C1042.756226,96.798294 1045.117310,96.219749 1046.539673,98.455643 
	C1048.685669,101.828751 1050.464355,101.039162 1052.823853,98.742775 
	C1056.229736,95.427948 1057.709961,95.700737 1058.751465,100.081299 
	C1059.623901,103.750626 1063.010986,104.956558 1064.748047,107.406143 
	C1069.874512,114.635773 1069.289062,109.926193 1070.630737,105.547501 
	C1071.618408,102.324196 1072.370483,99.834930 1070.612183,96.669670 
	C1069.221802,94.166710 1069.480225,91.506660 1072.754150,90.086151 
	C1075.585083,88.857803 1076.248901,86.992111 1074.620483,84.117874 
	C1072.117676,79.700485 1072.125244,75.258934 1075.330566,71.046440 
	C1077.025391,68.819252 1077.353271,66.789116 1075.102539,64.438217 
	C1072.545288,61.767231 1071.991211,59.209427 1076.930420,58.477394 
	C1078.555786,58.236507 1080.098877,57.482174 1081.703613,57.053352 
	C1087.816895,55.419777 1088.707764,54.034554 1086.755615,47.884197 
	C1086.051025,45.664619 1086.166626,44.040459 1087.938599,42.558262 
	C1090.461304,40.448135 1090.064087,36.694206 1092.524536,34.464737 
	C1093.314941,33.748547 1092.781250,32.528534 1091.628052,32.113926 
	C1086.278442,30.190498 1082.472168,23.694860 1075.467651,26.641903 
	C1075.180176,26.762833 1074.692505,26.850176 1074.504150,26.698076 
	C1071.440552,24.223412 1073.164795,20.529482 1072.506592,17.471384 
	C1072.078979,15.484625 1074.893066,14.787210 1077.088989,15.542015 
	C1077.561157,15.704305 1078.178345,15.714594 1078.490845,16.030348 
	C1083.020386,20.607128 1089.396240,21.505201 1094.911011,24.642853 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M884.296143,347.032349 
	C876.950317,341.221893 868.114685,338.529694 860.699097,333.243622 
	C853.495789,328.108948 844.977905,324.851776 837.573120,319.959229 
	C824.393921,311.251465 809.502625,305.542511 796.757812,296.150085 
	C796.005310,295.595551 795.586121,294.588654 794.944641,293.445068 
	C791.156738,290.132568 789.118286,286.428406 789.980774,281.547943 
	C790.271240,279.904388 790.742676,278.155884 792.627808,277.661469 
	C794.346436,277.210693 795.350708,278.581818 796.373474,279.654938 
	C797.779114,281.129639 798.777344,282.815216 799.019226,284.903259 
	C799.153442,286.061523 799.311890,287.309082 801.318787,287.220093 
	C802.059448,285.630798 800.430054,284.640594 800.092224,283.374695 
	C799.038574,279.426666 801.087036,276.751556 805.190857,277.073730 
	C809.914673,277.444580 814.578613,275.581879 819.387817,277.076782 
	C821.322021,277.677979 822.949585,275.590912 824.658691,274.603119 
	C827.892212,272.734192 831.105408,270.813263 834.636658,274.109589 
	C835.765747,275.163483 836.797974,274.730164 837.412720,273.414429 
	C838.581421,270.913086 840.474243,268.671600 839.352234,265.490387 
	C838.146851,262.072998 839.317932,259.303558 842.712891,257.197449 
	C846.076782,256.390594 848.547058,255.116333 849.803772,251.912460 
	C850.318359,250.600540 851.242004,249.119766 852.793518,248.707657 
	C855.947693,247.869843 859.166870,247.708710 859.562988,243.044006 
	C859.705872,241.361618 862.317749,241.907471 863.521484,243.449234 
	C864.206421,244.326584 864.314270,245.968933 865.745178,245.846069 
	C870.414368,245.445160 871.976807,246.804321 870.868347,251.861816 
	C870.497131,253.555573 872.119446,255.680832 869.079407,255.884689 
	C867.130859,256.015350 863.989990,258.331207 863.819336,253.542328 
	C863.719360,250.735001 860.825317,250.610519 858.846985,250.291656 
	C856.563477,249.923676 855.237549,251.879715 854.393799,253.665268 
	C853.347717,255.878784 854.648438,257.522614 856.485840,258.660370 
	C858.151550,259.691864 859.627869,261.131714 861.849670,261.063446 
	C863.263916,261.019989 864.643066,261.572815 864.660278,263.239990 
	C864.680847,265.235992 862.977356,264.896667 861.714233,264.979919 
	C860.718994,265.045502 859.716248,264.996429 858.716858,265.000183 
	C852.597412,265.023193 849.507629,267.938416 849.256897,273.828644 
	C849.173279,275.792877 850.427368,275.958282 851.846863,275.975281 
	C854.133667,276.002686 857.249084,276.341553 858.541626,275.544464 
	C862.321960,273.213135 864.539795,275.653870 867.633667,276.987762 
	C869.603210,273.669922 871.740540,271.842529 875.278870,274.637390 
	C876.636414,275.709717 877.609741,275.362915 878.686401,274.279083 
	C880.534668,272.418396 882.750305,271.507935 885.758911,272.032867 
	C887.647705,272.608551 889.102600,273.412476 891.131775,273.461182 
	C896.339539,272.355530 901.141357,270.993103 906.202087,271.274109 
	C908.713989,271.413544 910.476501,270.121246 912.181396,268.307983 
	C915.695984,264.570038 919.841431,265.380402 921.109985,270.338440 
	C922.321106,275.071777 926.047424,279.788696 922.295776,285.012543 
	C921.730408,285.799774 922.098999,286.672607 923.022888,287.007019 
	C924.324036,287.477875 925.684998,287.617645 926.587341,286.256348 
	C927.334534,285.129150 927.354309,283.732910 927.736328,282.467712 
	C928.528503,279.843933 930.178284,277.785431 932.965332,277.926971 
	C937.274292,278.145844 941.599670,277.039795 945.856445,278.012634 
	C952.494019,279.529572 958.389893,279.180817 962.434326,272.192017 
	C969.024109,271.133392 975.341980,269.772217 980.536011,275.132782 
	C981.574158,276.204132 983.128723,276.154327 984.226746,275.267456 
	C987.292053,272.791504 990.991211,272.878723 994.970581,272.329407 
	C995.946960,272.258545 996.488464,272.385284 997.318115,272.931763 
	C997.883423,273.658844 998.269775,274.088898 998.250916,274.568909 
	C997.884033,283.926544 997.630371,293.299927 994.752075,302.704773 
	C992.357849,304.648804 989.074158,303.807587 987.027100,306.524017 
	C983.983459,307.415100 981.571533,307.007294 979.551636,304.423523 
	C979.628113,301.657867 982.015625,300.247650 982.440308,297.558838 
	C983.510620,295.799774 985.042542,297.235107 986.360657,296.414764 
	C984.172791,293.514679 979.413147,293.018951 979.360474,288.217346 
	C977.250366,292.179260 977.668030,297.074066 972.878113,299.178528 
	C971.808777,299.648346 973.181274,301.597382 973.567566,302.812958 
	C975.732239,309.624634 974.878174,310.644897 967.956360,309.438843 
	C966.811523,309.239349 965.669983,308.968445 964.494629,308.977966 
	C963.884766,308.707916 964.100891,309.845520 964.198364,309.186005 
	C964.223328,309.016449 964.370850,309.081177 964.694641,309.160858 
	C967.004517,309.729187 968.463318,311.174561 967.486023,313.476868 
	C966.312317,316.241821 963.728943,317.137878 960.826233,316.646973 
	C958.167236,316.197235 956.172913,314.109528 953.231750,313.324005 
	C952.148193,315.478729 952.698425,317.565338 953.702393,319.389343 
	C955.904419,323.389801 955.237915,327.048615 952.962280,330.700684 
	C951.726624,332.683716 950.941956,334.900360 949.894043,336.983093 
	C949.525330,337.716095 949.112610,338.612915 949.850159,339.278961 
	C950.549988,339.910919 951.440247,339.487610 952.213257,339.202332 
	C954.186829,338.474091 955.482422,336.990356 956.585388,335.269409 
	C957.561462,333.746429 958.879395,332.652130 960.742737,333.621552 
	C962.625488,334.601105 962.965393,336.359528 962.543335,338.346924 
	C961.389282,343.781952 954.510742,347.550354 948.671875,345.701935 
	C945.984070,344.851013 943.335266,343.864349 940.643494,342.996979 
	C936.621155,341.700836 936.251282,339.423920 939.303894,336.529663 
	C940.298218,335.586884 942.536072,334.828522 941.061584,333.160522 
	C939.744995,331.671112 938.435486,333.605774 937.255310,334.338287 
	C932.674561,337.181732 929.239258,336.530792 926.417297,331.875122 
	C924.917053,329.400085 923.986023,328.862946 921.689148,331.251373 
	C917.330566,335.783875 912.707458,340.076263 908.051758,344.314484 
	C905.394958,346.733063 897.372375,346.389709 894.823975,343.748444 
	C892.707458,341.554810 890.876953,341.647644 888.968262,343.910400 
	C887.795593,345.300751 886.729187,346.872375 884.296143,347.032349 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M902.711426,110.206375 
	C905.261108,105.499458 908.056091,107.919762 910.485229,109.641197 
	C911.596558,110.428757 912.115845,112.051811 912.951782,113.647911 
	C913.869751,116.494392 912.822205,119.630852 915.757996,121.757034 
	C916.814697,122.570984 916.982910,123.417343 916.995605,124.666016 
	C917.389282,128.881241 914.299438,130.449799 911.956360,132.451691 
	C906.977600,136.705643 906.988281,138.105759 912.485168,141.598190 
	C914.557251,142.914688 916.366455,144.197159 916.030212,147.028503 
	C915.506226,151.440750 911.391296,153.290115 907.470032,150.599304 
	C901.454590,146.471512 899.252441,140.422943 899.600769,132.786072 
	C897.688904,136.628204 897.848450,140.888931 894.432922,143.359421 
	C891.244446,145.665741 887.243958,142.602921 883.589905,144.875168 
	C886.115784,147.954239 889.573792,149.116730 892.585938,148.760529 
	C898.354858,148.078293 901.672485,150.193481 903.885437,155.267654 
	C904.698669,157.132248 907.164551,158.252228 906.159729,161.155334 
	C904.115234,162.485641 902.373230,161.948044 900.300781,161.050995 
	C897.157043,157.975845 893.830017,156.467178 889.704712,156.604233 
	C884.601135,156.773773 881.659424,159.479813 881.644592,164.614227 
	C881.638611,166.696259 881.893127,168.774338 879.497314,169.631943 
	C877.190308,170.457794 875.750000,168.906647 874.429749,167.333649 
	C872.154175,164.622360 869.565979,162.947037 865.755798,163.944473 
	C864.002075,164.403580 862.169922,164.013260 860.356689,163.484543 
	C855.645142,162.110672 855.233276,162.539825 855.771423,167.578568 
	C855.859924,168.406738 856.036987,169.224472 856.119141,170.052841 
	C856.716492,176.076035 853.924805,179.086655 847.797241,179.041672 
	C841.478333,178.995270 840.852356,179.668930 842.433533,185.430756 
	C843.759705,190.263672 842.889465,194.317108 838.458740,197.631683 
	C835.679016,198.729416 833.541626,197.775284 831.092834,196.527924 
	C828.503479,195.051987 826.184998,193.933014 824.041809,192.345078 
	C820.557373,189.763443 816.992249,191.633865 817.081604,195.871170 
	C817.166931,199.910889 815.395264,203.519623 814.817871,207.750793 
	C812.647156,210.378220 810.542664,212.553680 807.290405,213.819244 
	C802.835938,210.845642 799.251404,209.855270 797.442871,216.196732 
	C797.362549,216.478653 797.144958,216.577728 797.136658,216.579681 
	C792.483826,217.684860 789.340515,220.798355 786.274780,224.247299 
	C784.949280,225.738464 782.838806,224.693085 781.141541,223.903137 
	C779.943481,223.345551 778.633728,222.579041 777.423950,223.643936 
	C776.218689,224.704712 776.920349,226.122040 777.151611,227.395569 
	C777.299866,228.211761 777.505188,229.022003 777.775330,229.807938 
	C778.096924,230.743896 778.369751,231.842163 779.485046,232.056671 
	C780.683960,232.287277 781.175293,231.219894 781.704407,230.400467 
	C782.244324,229.564407 782.527161,228.570175 783.156067,227.791107 
	C784.628052,225.967484 786.044434,226.217163 786.966797,228.221985 
	C788.929993,232.489288 789.435791,236.846878 786.966675,241.123077 
	C784.656799,245.123505 780.896362,244.561722 777.232483,243.986618 
	C774.891174,243.619110 773.145264,244.613037 771.238464,245.779419 
	C767.846252,247.854401 765.793457,246.667084 765.936096,242.648483 
	C766.055298,239.290070 765.687561,235.952667 768.648743,232.971619 
	C771.823120,229.776001 770.714172,227.111145 765.977478,223.968842 
	C766.251465,224.865784 767.127319,225.083649 767.717407,225.588608 
	C769.514343,227.126343 770.498108,228.936722 769.605896,231.301712 
	C768.716370,233.659378 766.686462,234.228500 764.415466,234.491730 
	C759.968872,235.007156 756.467773,232.448441 752.330811,230.844971 
	C750.778625,229.616653 750.278992,227.933289 748.699707,226.981873 
	C748.820435,228.398270 749.701294,229.462067 750.684875,230.869217 
	C751.608582,233.367767 752.736633,234.985779 755.451843,234.813263 
	C756.972229,234.716629 758.431091,235.260513 759.605957,236.268936 
	C761.243530,237.674591 763.290100,239.173660 762.206299,241.627960 
	C761.274719,243.737564 759.103394,243.166672 757.342712,242.664810 
	C753.291016,241.509872 751.235901,244.659744 748.928101,246.774323 
	C747.554382,248.033005 749.535461,249.386612 750.197144,250.632187 
	C751.925415,253.885437 750.116333,256.419586 748.376221,258.853424 
	C746.856812,260.978546 744.830078,262.552307 741.998657,261.632965 
	C739.397034,260.788239 736.543762,259.327118 736.517334,256.469604 
	C736.483765,252.823227 735.485474,249.242920 735.961243,245.598755 
	C736.156067,244.105820 736.273987,242.620285 735.837280,240.782623 
	C735.556763,239.473541 735.215637,238.554718 734.989746,237.234192 
	C734.988037,235.097229 735.733337,233.485657 737.196228,232.715683 
	C742.018799,230.177429 741.701172,226.495865 739.887817,222.327530 
	C739.561523,221.577637 739.379150,220.772568 739.250122,219.603027 
	C739.350830,218.026703 740.061462,217.114227 740.855286,216.280029 
	C742.903198,214.127792 742.662781,212.274948 740.279297,210.551880 
	C737.181152,208.312149 737.871033,205.911255 740.334351,203.248657 
	C742.454590,201.831055 742.737671,199.720718 743.550049,197.936386 
	C746.289368,191.919235 751.455933,190.318237 756.766357,194.119720 
	C758.966125,195.694412 759.697571,195.339264 760.512756,192.851425 
	C762.322876,187.328003 765.408386,186.302002 770.828308,189.086807 
	C775.279419,191.373825 779.245850,195.303238 785.012512,193.322006 
	C780.126709,186.512436 780.105408,182.517532 785.053833,175.037918 
	C785.924194,173.722229 786.195374,172.320114 786.537659,170.864853 
	C787.596619,166.363373 789.082031,165.450684 793.669250,166.443344 
	C795.131531,166.759750 796.595337,167.109161 798.018555,167.568680 
	C804.411194,169.632614 805.336609,173.622849 800.573059,178.445801 
	C800.116333,178.908249 799.524658,179.309204 799.721191,180.109787 
	C803.935486,181.107941 806.483704,179.679993 806.240906,175.821091 
	C805.950745,171.209747 809.256714,169.014847 811.735718,166.273178 
	C813.161255,164.696487 815.099487,165.257019 816.789856,166.280502 
	C818.500610,167.316315 820.046509,168.589478 821.711670,169.694092 
	C824.194824,171.341461 825.654175,169.766022 827.238892,168.156708 
	C829.090088,166.276825 828.161499,165.069366 826.570007,163.663345 
	C824.783447,162.085022 824.151184,159.715363 823.075256,157.660980 
	C822.000916,155.609558 821.428711,153.434982 823.838745,151.947922 
	C826.335266,150.407501 829.130371,150.908234 830.422119,153.275696 
	C832.016968,156.198669 834.652832,156.685684 837.179199,157.804733 
	C839.406189,158.791183 840.374268,158.054779 840.344421,155.828781 
	C840.290161,151.773041 840.683594,148.184952 845.689758,147.136154 
	C847.041199,146.853043 846.603027,145.177170 846.425171,144.052109 
	C846.191589,142.574966 845.724976,141.112350 846.007874,139.605621 
	C846.387390,137.585022 846.991699,135.628235 849.357483,135.135559 
	C851.645081,134.659134 853.123840,135.925629 854.336853,137.686310 
	C855.319946,139.113297 855.723450,141.256241 858.544373,140.942047 
	C858.200623,139.583405 856.634094,139.087570 856.384399,137.952271 
	C855.100281,132.114014 851.970886,130.098251 845.873291,131.482773 
	C842.877319,132.163010 840.321594,129.397949 838.864990,126.676743 
	C837.347046,123.841194 839.723206,121.278603 843.514893,122.090958 
	C847.284363,122.898544 850.729553,122.254875 854.321716,121.513351 
	C852.471436,115.546593 856.564392,111.046967 862.668091,112.110252 
	C865.510925,112.605484 866.282898,114.774559 866.472351,117.253616 
	C866.827148,121.897797 866.502319,126.516754 865.916504,131.437775 
	C866.225952,132.403076 866.389771,133.057541 866.759155,133.991730 
	C869.337341,136.377151 871.126648,135.175903 872.607605,133.059586 
	C873.775391,131.390762 875.002075,129.506958 876.991272,129.188065 
	C882.069824,128.373962 883.531067,124.651047 884.599426,120.525024 
	C885.537048,116.904488 887.527893,114.408546 891.476746,114.574585 
	C895.807739,114.756691 899.046021,112.503868 902.711426,110.206375 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M813.888489,208.331009 
	C813.350769,203.047668 815.865906,198.535583 815.883728,193.567474 
	C815.897827,189.626633 822.216370,188.293365 825.547913,191.480515 
	C827.062866,192.929901 828.769653,193.650238 830.760925,194.745758 
	C828.851196,197.776077 827.454651,197.987549 823.804077,196.090210 
	C819.847717,194.033951 819.847107,194.033920 819.590515,198.554535 
	C819.571899,198.882629 819.577515,199.212051 819.562378,199.540421 
	C819.178528,207.869507 821.521973,209.651260 830.459595,207.435318 
	C831.415649,207.082092 832.079285,206.934677 832.739929,207.043015 
	C841.245300,208.437973 841.244568,208.442398 850.211731,204.674576 
	C845.665039,205.645737 842.091370,204.358734 838.253418,202.698578 
	C836.732666,200.948303 836.912964,199.311096 837.815308,197.319061 
	C841.981873,192.766235 842.742981,188.278915 839.773682,183.007568 
	C838.007629,179.872314 841.558655,176.720657 846.194031,177.060745 
	C854.287231,177.654541 855.300781,176.543839 854.090820,168.406754 
	C852.729675,159.252197 852.858582,159.151642 861.677795,162.297394 
	C862.453308,162.574036 863.331482,163.052979 864.055054,162.907227 
	C869.523987,161.805710 874.253418,162.219879 876.927673,168.114319 
	C877.344543,169.033279 878.631531,169.085052 879.609985,168.509521 
	C880.895813,167.753143 880.598877,166.724289 880.260681,165.487183 
	C878.833557,160.266251 882.736206,155.007050 888.107666,155.038055 
	C892.480164,155.063293 897.308533,154.157639 899.948486,159.639740 
	C898.986206,164.008545 898.661438,168.346649 894.376221,170.985077 
	C891.671753,170.939362 889.331360,171.007645 887.017822,170.790649 
	C882.666504,170.382553 881.200195,172.168350 882.838379,176.205032 
	C883.262512,177.250107 884.412354,178.217102 883.347534,179.322449 
	C882.226685,180.485977 880.911194,179.730713 879.637756,179.311325 
	C875.052307,177.801178 871.857056,174.273407 868.233154,171.410522 
	C865.465820,169.224274 868.379517,167.762268 869.416992,166.131516 
	C864.946594,164.285477 860.878296,165.450089 859.551636,169.074005 
	C858.483459,171.991959 858.332458,174.701294 862.297913,175.623138 
	C866.294739,176.552322 866.921997,179.319061 866.228394,182.852310 
	C865.430054,186.919052 864.657166,190.992767 863.993530,195.082977 
	C863.729370,196.711090 862.859375,197.923828 861.657104,198.781097 
	C858.249146,201.210999 854.929138,203.493057 855.779175,208.583801 
	C856.014343,209.992065 854.667297,210.977524 853.007751,210.451019 
	C849.180481,209.236801 848.485413,211.587280 847.955627,214.564590 
	C847.098877,219.380478 845.143005,220.243362 841.198975,217.616989 
	C839.770935,216.666031 838.478394,215.288315 836.876709,216.930389 
	C835.447693,218.395523 834.410645,220.309891 835.403442,222.364532 
	C836.230774,224.076584 837.278748,225.435562 835.404724,227.175507 
	C833.752930,228.709167 833.428955,230.288544 836.143433,231.236450 
	C836.906982,231.503082 837.698853,232.062408 837.346985,233.089188 
	C837.005737,234.084763 836.027161,233.921402 835.231079,233.974777 
	C834.401184,234.030441 833.565186,233.985611 832.732910,234.015762 
	C830.959351,234.080002 829.190918,234.398926 829.275513,236.647491 
	C829.346985,238.548035 831.016785,239.271881 832.522095,238.914322 
	C837.824463,237.654800 838.560974,241.792664 840.474182,244.973724 
	C842.480286,248.309219 844.984985,251.298248 842.045410,255.646240 
	C835.831116,258.132416 829.231018,255.609192 826.633423,249.956406 
	C826.019897,248.621353 825.190735,248.297348 823.945862,248.285339 
	C820.662231,248.253647 817.507874,247.795334 814.129211,249.001251 
	C809.319031,250.718109 804.602478,248.407578 800.319763,246.407227 
	C797.580322,245.127716 798.611694,241.053543 801.698669,239.819992 
	C803.379028,239.148544 805.385071,239.610901 807.191711,238.126801 
	C803.725830,233.542725 803.829895,228.398438 805.848511,222.174820 
	C803.586365,225.033295 802.262207,226.878555 802.619385,229.749832 
	C803.651184,238.044144 799.910706,243.442688 791.651672,245.132401 
	C788.433411,245.790817 788.012268,246.835678 788.715515,249.767273 
	C789.698792,253.865921 788.315430,258.006866 787.825745,262.072113 
	C787.439819,265.275269 784.150696,265.557068 781.417053,265.532623 
	C777.703796,265.499481 773.885986,264.347931 772.015503,269.472992 
	C771.241089,271.594971 768.398254,271.291626 766.944397,269.493378 
	C764.227844,266.133545 760.370972,266.678864 756.858826,266.109772 
	C754.170593,265.674255 753.706848,267.679108 754.112854,269.624756 
	C754.504211,271.500519 755.229248,274.015167 757.874146,273.112305 
	C762.987427,271.366974 767.908264,271.261292 772.880493,273.395630 
	C774.314209,274.011047 775.450378,273.098206 776.601868,272.466797 
	C778.398865,271.481445 780.270996,270.784302 782.337585,271.246124 
	C785.081909,271.859436 787.651733,273.037140 787.774841,276.224792 
	C787.900574,279.482910 785.332764,280.089325 782.669556,280.481049 
	C781.147766,280.704895 778.362244,280.103241 779.898743,283.755371 
	C780.287659,285.139282 780.262268,286.159027 779.699463,287.512817 
	C778.888855,288.498505 778.143372,288.618378 776.965698,288.232880 
	C776.205383,287.730072 775.836060,287.367554 775.272827,286.667725 
	C771.446655,282.656342 766.777161,284.085480 763.091248,285.085175 
	C759.375916,286.092804 756.143555,286.341644 752.279602,285.151978 
	C745.850952,283.080933 740.000427,280.820190 733.198975,281.835083 
	C727.805908,282.639862 722.406860,282.188049 717.950012,286.365448 
	C716.204468,288.001587 713.845093,286.956116 712.248108,285.104004 
	C711.265198,283.964050 710.720520,282.533051 709.801453,281.351837 
	C709.066528,280.407257 708.160217,279.517426 706.890198,279.839294 
	C705.752930,280.127533 705.739075,281.364594 705.556152,282.311890 
	C705.171997,284.300781 704.548889,286.166962 702.974487,287.999115 
	C701.083740,289.511414 699.032898,290.260712 697.652039,292.418945 
	C695.936401,294.061432 693.953674,294.814667 692.373779,293.748749 
	C686.320129,289.664581 680.209778,292.446228 674.157959,293.324799 
	C671.382996,293.727600 669.536926,293.477783 669.162476,289.966217 
	C669.308716,289.361298 669.403320,289.130829 669.727295,288.610413 
	C674.957520,285.089111 680.760132,284.135162 686.385437,282.878967 
	C689.461914,282.191925 692.175720,281.262146 694.141907,278.847992 
	C697.537964,274.678070 701.794189,272.653381 707.192261,272.794342 
	C709.165222,272.845886 711.170288,272.470123 713.019592,270.737061 
	C711.782104,269.053711 709.966064,268.966003 708.251648,269.008301 
	C704.081970,269.111206 702.230713,266.754181 701.620300,263.025269 
	C701.270996,260.892151 700.870972,258.754089 700.782104,256.593994 
	C700.713196,254.917755 700.760925,252.952515 702.867859,252.610809 
	C704.887390,252.283249 705.938538,253.918945 706.197815,255.625793 
	C706.696472,258.909698 708.155029,260.264984 711.594421,259.470367 
	C713.890137,258.940002 714.909973,257.773621 715.320679,255.492706 
	C716.752197,247.543488 719.984192,245.375885 729.422668,246.479889 
	C725.666504,245.754456 722.239380,246.069000 721.440430,241.696106 
	C722.266418,239.376190 723.967651,238.343002 725.753174,238.749710 
	C729.269653,239.550720 732.821045,238.928482 736.655029,239.748444 
	C737.324158,240.732361 738.130493,241.722733 737.898010,242.157318 
	C735.508240,246.623947 740.001831,251.182831 737.522034,255.767731 
	C736.624512,257.427002 742.340393,261.325989 744.439514,260.634064 
	C748.255371,259.376282 749.943542,253.344040 747.456116,250.302582 
	C745.641174,248.083542 745.046692,245.787460 748.447815,244.375443 
	C749.827820,243.802536 751.206360,243.415268 752.053772,241.971466 
	C753.451721,239.589722 755.394714,239.573074 757.526611,241.019150 
	C758.666260,241.792145 760.191833,242.629440 761.100342,241.437576 
	C762.404846,239.726212 760.210815,238.976212 759.383423,237.939056 
	C758.308777,236.591827 756.846252,235.548676 755.054321,236.216171 
	C750.528442,237.902084 749.836914,235.402969 750.000244,231.409714 
	C750.205017,230.292358 750.691711,229.888565 751.695923,229.857697 
	C754.243591,229.814972 756.341675,230.298096 758.092163,231.712418 
	C760.447937,233.615692 763.160034,232.878616 765.763855,232.939545 
	C767.343689,232.976517 768.104736,231.874573 768.613953,230.567810 
	C769.188843,229.092621 768.545471,227.945862 767.445496,227.066864 
	C766.816772,226.564377 766.007507,226.280411 765.264709,225.929382 
	C763.564331,225.125732 761.398071,223.995117 762.533142,222.014511 
	C763.773254,219.850616 765.608154,221.499771 767.117981,222.584579 
	C772.901978,226.740494 773.274902,228.967453 768.706970,234.755600 
	C766.287354,237.821411 768.036865,241.354919 767.404480,244.634247 
	C767.091309,246.258804 769.548828,246.099594 770.271362,244.882507 
	C772.377136,241.335007 775.259277,241.702805 778.500671,242.637711 
	C780.805054,243.302353 784.014160,243.669418 784.923645,241.614777 
	C786.039551,239.093796 788.498169,236.468643 786.783936,233.040741 
	C785.965942,231.405090 785.815125,229.435852 785.325195,227.469589 
	C783.438538,228.539200 784.039490,230.348328 783.246826,231.558258 
	C782.414429,232.828842 781.713867,234.209854 780.204102,234.697571 
	C778.666016,235.194412 777.365051,234.317947 777.087524,232.959488 
	C776.366150,229.428970 773.398926,226.056915 775.410950,222.344467 
	C776.451843,220.423965 779.261108,220.255875 780.753906,221.655457 
	C785.698730,226.291397 787.386169,222.276428 788.767212,218.674377 
	C789.620422,216.448959 790.509521,215.608780 792.688965,216.405930 
	C795.083069,217.281662 796.561462,216.653214 796.992004,214.024628 
	C797.456970,211.185410 798.721436,208.864304 801.789978,208.396286 
	C805.050537,207.899002 804.419617,211.736343 806.659302,212.921143 
	C807.810547,213.621506 808.763611,213.893509 809.740784,214.078003 
	C811.942200,214.493683 814.632812,216.329803 816.263306,214.332520 
	C818.364197,211.759018 814.069336,210.852371 813.888489,208.331009 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M963.245361,272.744751 
	C957.768250,280.948120 955.477478,282.014008 945.580444,279.594727 
	C941.125122,278.505646 936.867310,280.467834 932.359619,279.381042 
	C929.905334,278.789276 928.886719,281.892670 929.002747,284.465363 
	C929.089233,286.381012 928.894897,288.418243 926.986572,289.195282 
	C924.774231,290.096130 922.318604,289.300171 920.333008,288.345459 
	C918.364014,287.398712 918.882385,285.395172 920.370789,284.236176 
	C923.445435,281.841949 923.095215,279.288910 921.226440,276.440094 
	C920.083679,274.698120 920.063965,272.689880 919.886353,270.693085 
	C919.724548,268.875214 918.567017,267.670563 916.797546,267.196320 
	C914.957458,266.703156 913.983398,267.845764 913.359375,269.315491 
	C912.045837,272.409271 909.612549,273.411072 906.500000,272.982239 
	C901.403870,272.280151 896.696289,274.210327 891.410034,274.997986 
	C886.466797,268.909210 886.831299,265.598755 892.804993,261.329712 
	C898.523926,257.242737 901.731812,250.765793 907.259033,246.491058 
	C909.048584,245.106979 908.748108,242.541428 909.768372,240.283142 
	C910.444458,240.000000 910.888916,240.000000 911.666687,240.000000 
	C913.415955,240.265106 914.084839,241.316895 914.743958,242.847656 
	C914.678284,246.491287 916.097046,248.109787 919.472046,248.873734 
	C922.844910,249.637192 925.813293,251.561768 926.575806,255.789154 
	C926.057983,257.817078 923.098877,258.316559 924.099792,260.523285 
	C925.078979,262.682068 927.316345,263.176025 929.507751,263.311646 
	C932.779724,263.514160 936.057678,263.396515 939.334106,263.373993 
	C941.873413,263.356537 943.308167,262.164001 943.756836,259.312408 
	C944.031616,257.988068 944.492737,257.102478 944.883484,255.797882 
	C946.399536,253.124191 948.391724,252.894653 950.625122,253.928650 
	C955.218079,256.055023 960.149536,255.098038 964.919861,255.581558 
	C967.317444,255.824585 968.411560,254.276566 969.287537,252.260101 
	C972.559082,244.729355 978.785950,240.735260 986.867920,241.131744 
	C991.428833,241.355484 995.108887,239.164536 999.122131,237.652863 
	C998.894592,237.220001 998.855286,236.998154 998.734131,236.936188 
	C995.457520,235.260330 991.577576,233.793915 992.206726,229.191071 
	C992.833862,224.603394 997.167114,224.284027 1000.599548,223.316513 
	C1004.596252,222.189987 1008.776855,221.809906 1012.723755,220.385956 
	C1014.665771,219.685318 1016.675964,219.940826 1018.816711,221.206528 
	C1021.264954,227.717575 1017.165710,234.349091 1010.606934,235.185135 
	C1009.684753,235.302673 1008.373474,234.828781 1008.108826,236.140915 
	C1007.799500,237.674423 1009.201233,237.952682 1010.267273,238.400452 
	C1012.863403,239.490845 1016.885132,239.032959 1016.900940,243.181427 
	C1016.916687,247.304382 1012.842834,247.371033 1010.226257,248.903961 
	C1005.940613,251.414703 1002.023132,254.498505 999.350769,258.737946 
	C997.702698,261.352417 996.068665,262.396240 993.315308,260.526794 
	C992.764771,260.152985 991.793396,259.449158 991.622803,259.628876 
	C987.237244,264.248840 978.433411,257.395599 975.520508,265.841644 
	C975.148926,266.919098 973.894165,267.940826 972.736206,267.606323 
	C967.980835,266.232483 965.738281,269.301056 963.245361,272.744751 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M779.334351,284.019531 
	C777.498840,283.200287 776.687683,281.975159 777.274231,280.309296 
	C777.850830,278.671783 779.444641,279.158325 780.716980,278.991608 
	C782.865173,278.710083 785.635437,279.996735 786.718262,276.890961 
	C787.161560,275.619476 786.261780,274.616180 785.293335,273.941650 
	C782.948242,272.308411 779.954102,271.382629 777.937683,273.459076 
	C775.024780,276.458954 773.211975,275.280975 769.870056,274.065460 
	C766.643555,272.891937 761.573853,271.144714 758.618469,276.065430 
	C758.106812,276.917358 757.105408,277.077667 756.513733,276.335876 
	C754.412231,273.701172 750.931091,271.640015 752.135376,267.442932 
	C752.881470,264.842804 753.518127,261.901428 757.466064,264.471039 
	C758.572327,265.191101 760.373108,265.017792 761.844788,264.964142 
	C764.371460,264.872040 766.455566,265.294983 767.523193,267.960205 
	C767.956604,269.042175 768.652161,270.115967 770.146118,269.670898 
	C771.457886,269.280060 771.988342,268.190430 771.932190,266.967010 
	C771.743835,262.862457 773.739563,262.771851 776.992981,263.702911 
	C779.006531,264.279144 781.288513,263.936218 783.450562,263.968323 
	C784.870422,263.989410 786.868225,263.365875 786.572876,262.182190 
	C785.485596,257.824799 788.734009,253.693802 786.947754,249.429031 
	C785.060547,244.923248 786.010681,243.546127 790.969421,243.838425 
	C792.372925,243.921173 794.062012,244.022339 794.405457,242.580124 
	C795.080872,239.743561 797.211365,240.177704 799.063599,239.226349 
	C802.079102,237.677521 802.374512,233.627350 801.048828,230.088379 
	C800.453979,228.500519 803.557068,219.964294 804.993896,219.426010 
	C805.516052,219.230408 806.366638,219.360947 806.834839,219.681839 
	C807.940613,220.439774 808.159546,221.655533 807.516235,222.781372 
	C806.039185,225.366104 805.966064,228.143295 805.963623,231.004471 
	C805.961670,233.320618 806.285767,235.243958 808.616882,236.432007 
	C809.572266,236.918915 810.802429,237.692398 810.347839,239.113159 
	C809.975525,240.276871 808.677063,240.018250 807.766357,240.209320 
	C805.989929,240.582031 804.182434,240.828171 802.377136,241.033417 
	C801.046875,241.184662 800.186035,241.705841 800.090820,243.146072 
	C799.989990,244.670425 800.425903,245.864761 802.156189,245.925568 
	C806.894287,246.092087 811.214111,249.814377 816.530334,246.358551 
	C819.246460,244.592911 823.455566,247.427338 827.190857,246.266632 
	C828.862366,245.747192 828.893982,247.727753 828.454895,249.136993 
	C827.321716,252.773788 829.072876,253.812531 832.451782,254.098511 
	C835.398804,254.347946 838.284424,255.321564 841.598633,255.987610 
	C842.126648,256.304657 842.320496,256.557831 842.790771,256.879761 
	C841.120361,260.399261 839.710754,263.343262 841.516907,267.821320 
	C842.516052,270.298523 839.276489,274.282959 837.129028,277.137970 
	C836.422363,278.077515 835.076355,277.766907 834.587830,276.795074 
	C831.378174,270.409363 827.271606,274.362274 824.542969,276.587677 
	C821.305481,279.228119 818.516968,279.586151 815.072144,278.013245 
	C813.794678,277.429932 812.563171,277.008362 811.314514,277.886536 
	C809.205872,279.369507 807.275940,280.519958 804.744812,278.337616 
	C803.747803,277.477905 802.052307,278.159973 801.447632,279.593018 
	C800.696350,281.373169 800.680725,283.182678 802.377747,284.563599 
	C803.489685,285.468414 805.516174,286.175476 804.526855,287.910522 
	C803.429321,289.835358 800.899292,289.357117 799.099304,289.383148 
	C797.483948,289.406525 796.761841,287.327698 797.281555,285.898926 
	C798.773499,281.796478 794.196533,281.264282 793.718750,278.092529 
	C790.039307,282.826477 790.555481,287.131104 794.747559,290.525513 
	C795.465210,291.106598 795.849304,291.733246 795.122803,292.869324 
	C794.463745,293.085663 794.050110,293.073456 793.325439,293.045776 
	C790.084656,291.081604 786.718445,291.057404 783.046509,290.853668 
	C781.981445,290.775574 781.350037,290.510071 780.672852,289.678192 
	C780.962463,287.111298 778.476868,283.365631 784.487244,283.364227 
	C782.114380,282.927979 780.960327,283.896973 779.334351,284.019531 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M914.320801,243.161438 
	C913.417114,242.186325 912.834229,241.372650 912.125671,240.279480 
	C913.473206,238.730438 914.329834,237.194946 913.995667,235.186722 
	C913.452209,231.920425 915.444702,229.956406 917.521851,227.930405 
	C919.047241,226.442657 920.356445,225.699493 922.115784,227.566986 
	C923.344421,228.871124 925.145935,229.784683 926.475525,228.255981 
	C927.944824,226.566605 929.757935,224.666977 929.708923,222.273026 
	C929.666992,220.225311 927.888977,219.278137 925.895081,218.978302 
	C924.207581,218.724548 922.437805,218.225815 921.633057,216.697861 
	C920.590088,214.717407 920.434082,212.461838 922.138123,210.656372 
	C925.040649,207.581207 934.194519,207.090210 937.437439,210.038361 
	C939.846558,212.228546 942.045715,212.609161 944.448120,210.447449 
	C946.392639,208.697739 946.621948,202.856934 944.906067,201.084183 
	C943.118896,199.237823 941.966125,200.488663 940.999817,202.035797 
	C939.125244,205.037125 936.401306,206.325409 932.954346,205.924301 
	C930.771912,205.670349 928.935364,204.510529 929.316528,202.077087 
	C929.710632,199.560501 931.702942,200.569763 933.304565,200.864471 
	C938.686829,201.854828 941.794922,197.825821 944.355286,194.577042 
	C947.201599,190.965271 950.071960,190.442947 953.867371,191.480530 
	C957.463867,192.463715 959.080017,191.344635 958.305664,187.564667 
	C958.042358,186.279449 958.336243,185.272507 959.134338,184.339111 
	C961.061829,182.084930 960.996338,179.864059 959.108093,177.614319 
	C957.588196,175.803558 957.688416,173.825562 959.124084,172.139511 
	C960.582947,170.426254 962.449829,171.768463 963.633057,172.490005 
	C966.523926,174.252838 969.023071,174.373169 972.351074,173.303711 
	C976.317261,172.029221 978.168884,175.214523 979.394775,178.637833 
	C980.363159,181.341919 980.345459,187.813065 984.886841,180.488693 
	C985.176270,180.021927 986.428467,180.152100 987.616333,180.002594 
	C990.630066,183.536652 988.799500,187.160858 988.043701,191.124573 
	C981.856201,194.757233 975.805786,197.661926 968.615601,195.360199 
	C966.399719,194.650848 963.735718,194.164688 962.966125,197.422134 
	C962.270996,200.364410 963.146362,202.685699 966.517456,203.411133 
	C967.815247,203.690430 969.091980,204.111649 970.163879,204.947281 
	C971.505737,205.993393 972.854858,207.206192 972.421692,209.061447 
	C971.904175,211.278107 969.894287,210.893616 968.307983,210.770477 
	C964.636353,210.485428 961.466553,210.159622 961.468750,215.625885 
	C961.469849,218.366943 958.809631,220.049225 955.551880,220.511963 
	C952.640625,218.917816 950.063843,220.313004 947.514709,220.490356 
	C945.381958,220.638763 945.469727,222.297424 945.766541,223.830383 
	C946.775635,229.040390 943.063965,233.594727 943.759521,238.830978 
	C943.965637,240.382767 942.051941,241.080750 940.515747,241.337646 
	C938.564148,241.664032 936.586182,241.800598 934.272705,242.110687 
	C929.205322,240.732407 924.612000,240.001053 920.026672,242.866501 
	C918.556824,243.785034 916.477478,243.545364 914.320801,243.161438 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M906.280640,161.228394 
	C904.746521,157.086731 900.123962,155.346115 899.573853,150.857529 
	C899.445190,149.807785 897.488953,149.752579 896.438660,150.157791 
	C891.631042,152.012558 887.736816,149.248215 883.605286,147.764709 
	C882.189514,147.256348 880.755188,146.403397 881.204407,144.497665 
	C881.612976,142.764603 883.155029,142.351715 884.657288,142.104172 
	C885.467590,141.970657 886.324402,141.987900 887.145813,142.074142 
	C894.796265,142.877319 894.997375,142.660812 896.965942,135.593872 
	C897.572998,133.414886 896.960938,129.307358 899.721619,129.610977 
	C903.242249,129.998154 900.852234,133.658615 900.995911,135.888962 
	C901.372437,141.732544 903.858276,146.407532 908.651733,149.751450 
	C910.603271,151.112778 912.620850,151.607147 914.055542,148.854355 
	C915.333374,146.402390 915.446045,144.354202 912.381409,143.291962 
	C911.110291,142.851364 909.946716,142.350296 908.910217,141.430557 
	C905.073853,138.026474 905.048279,135.646423 909.101746,132.585693 
	C911.868225,130.496735 914.877380,128.699982 916.752991,125.223648 
	C918.382507,128.958862 921.841370,129.481033 925.289062,129.958252 
	C930.521667,130.682571 935.759460,131.371384 941.001160,132.026398 
	C942.429321,132.204880 943.536316,132.852585 944.306458,134.001297 
	C948.395020,140.100021 949.455261,146.580292 946.067261,153.277878 
	C944.412109,156.549850 944.721313,158.936615 947.825012,160.916031 
	C948.377808,161.268585 949.326660,161.756424 949.305115,162.137222 
	C949.064819,166.378937 948.742432,170.620422 948.246521,174.838974 
	C948.161011,175.566269 947.032227,175.969284 946.149170,175.609924 
	C944.739380,175.036148 944.890564,173.674545 945.062927,172.610107 
	C945.510132,169.847031 945.835876,167.560242 943.798828,164.822098 
	C940.428406,160.291855 940.833618,159.990387 937.074829,164.740814 
	C935.601990,164.268356 935.837585,161.912506 933.503540,163.653839 
	C930.972900,164.010223 930.028503,162.248459 928.291931,161.152679 
	C926.522766,154.561615 928.942139,149.182907 932.625488,144.173523 
	C933.538818,142.931442 936.056824,142.232407 935.053833,140.426102 
	C934.255127,138.987656 932.007446,139.635208 930.374878,139.774963 
	C926.823242,140.078995 925.004700,141.255676 924.826843,145.617966 
	C924.623779,150.597748 924.359924,155.474991 925.159180,160.687592 
	C924.890808,162.151398 924.987305,163.373093 924.626038,164.439743 
	C924.024719,166.215286 922.744873,167.861557 921.320557,165.319534 
	C919.745178,162.508133 917.724487,163.796768 915.362793,163.991928 
	C914.119263,162.474304 915.371887,161.320557 915.830688,159.680328 
	C916.333679,158.632568 916.836365,157.995621 917.220459,157.201752 
	C914.946350,159.837830 911.052185,158.945496 908.311279,161.419006 
	C907.499756,161.637100 907.027039,161.597794 906.280640,161.228394 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M955.395813,69.001457 
	C955.248413,66.905518 954.224365,65.223328 953.466309,63.368420 
	C952.591614,61.228374 952.295349,58.707012 954.829773,57.167664 
	C957.416016,55.596859 959.564087,56.501526 961.351562,58.655834 
	C961.988037,59.422882 962.524841,60.274509 963.179626,61.024147 
	C964.241516,62.239769 965.558960,62.173233 966.769531,61.377583 
	C967.873474,60.651978 968.198914,59.468266 967.479248,58.319458 
	C965.151978,54.604359 965.984863,51.543434 968.108459,47.776852 
	C969.500305,45.308243 967.157959,41.812550 965.901550,39.026451 
	C964.344666,35.574112 962.509216,32.185295 960.330688,29.095839 
	C958.700195,26.783560 959.302734,24.789234 959.862610,22.515476 
	C960.177917,21.234974 960.186707,19.776020 958.989807,18.829155 
	C957.711365,17.817928 957.038208,19.073162 956.058411,19.649874 
	C954.029663,20.843945 953.232788,19.271051 953.748230,14.253174 
	C959.064636,16.182186 964.633789,15.774210 969.883240,17.091049 
	C973.708496,18.050632 976.936462,19.053566 976.071655,24.046621 
	C975.897461,25.052151 976.505554,25.958059 977.673462,26.802555 
	C979.869934,28.333769 979.222839,30.532801 979.909912,32.653332 
	C979.602417,36.756531 975.736511,38.684269 975.223877,42.541260 
	C974.639587,46.938118 975.647034,48.107693 980.630554,47.050224 
	C982.245239,49.237865 983.445557,51.029610 986.718750,50.081112 
	C990.070435,49.109879 992.532837,50.542439 993.043152,54.659065 
	C989.698364,60.088387 991.799683,65.984489 990.274841,71.689148 
	C989.005981,72.677650 987.932068,73.110611 986.348999,73.022629 
	C982.909607,72.590584 980.011230,70.569725 976.358765,71.954506 
	C972.911133,68.246544 970.219666,70.847382 967.234253,72.766953 
	C965.533875,73.593666 964.189270,74.677086 962.902771,72.435219 
	C961.393005,69.804474 958.663147,69.115219 955.395813,69.001457 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M909.550293,240.000000 
	C912.061096,244.659912 909.278809,247.804932 906.102539,250.138336 
	C900.250549,254.437378 897.210571,261.401947 890.657837,265.156464 
	C886.777466,267.379761 891.205566,271.123352 890.989380,274.612885 
	C889.331421,274.949219 887.546631,275.288483 886.180298,273.308960 
	C884.735657,271.813263 883.993164,270.330750 883.735901,268.644867 
	C883.003784,263.847412 881.874817,259.205994 879.553345,254.864197 
	C878.531616,252.953262 878.144592,250.740723 879.739746,248.329239 
	C880.348938,246.560074 880.665833,245.110947 881.054199,243.681274 
	C881.689758,241.341782 882.636169,240.651016 884.821472,242.524551 
	C887.790161,245.069656 889.881226,244.696243 891.177368,240.483032 
	C892.603516,235.846939 895.201843,235.141327 898.680481,238.258606 
	C901.904785,241.148102 905.623047,239.509918 909.550293,240.000000 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M912.748657,169.258667 
	C918.345398,172.467178 925.594299,171.154221 930.450806,176.339249 
	C932.098389,178.098236 935.521301,177.204407 938.119019,176.448700 
	C940.314392,175.810059 942.365417,175.683853 944.038452,177.649307 
	C944.975830,178.750519 944.563049,179.764252 944.041626,180.862869 
	C942.960205,183.140976 940.924622,183.008530 938.912964,183.001419 
	C936.919495,182.994370 934.925232,182.970413 932.932800,183.017670 
	C931.004761,183.063400 928.921509,183.344437 929.328369,185.916733 
	C929.677551,188.124649 931.472412,188.058746 933.245361,187.420120 
	C935.682007,186.542526 937.557495,187.348999 938.593506,189.630798 
	C939.301697,191.190521 939.400391,193.023376 937.914185,194.221146 
	C936.186279,195.613876 934.180359,195.126678 933.097046,193.469009 
	C930.315002,189.212128 927.421509,189.533752 923.509888,192.071457 
	C920.487183,194.032486 916.547668,193.548569 913.306396,192.653915 
	C909.312744,191.551605 911.594238,187.574326 911.000000,184.443130 
	C911.444275,181.613739 912.891052,180.428696 915.650879,180.539612 
	C917.276550,181.969437 916.430359,183.467072 916.399292,184.889465 
	C916.358643,186.751770 917.630920,186.956711 919.053101,186.975601 
	C920.648865,186.996796 920.859253,186.093094 920.815063,184.715637 
	C920.719971,181.756073 920.130188,179.490219 916.040527,180.308105 
	C915.169312,176.444626 911.940735,173.555298 912.748657,169.258667 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M916.192078,180.750000 
	C913.973267,180.988342 913.029114,182.751648 911.291992,183.846771 
	C904.448303,183.886246 901.549316,178.418564 897.445068,174.728317 
	C896.351440,173.745010 895.365662,172.641815 894.165161,171.296753 
	C897.342346,168.306335 895.842224,163.031494 899.786499,160.272522 
	C901.744446,160.326660 903.488831,160.653336 905.616638,160.989700 
	C906.443909,160.999405 906.887878,160.999420 907.664673,160.999420 
	C910.777710,160.269745 913.350525,158.371246 916.729980,159.749573 
	C917.024841,161.434402 916.050171,162.372223 915.150024,163.710693 
	C913.736572,165.158493 913.688782,166.857086 913.043152,168.654984 
	C912.920715,170.513596 913.183350,171.986023 914.458618,172.898087 
	C916.864685,174.618927 916.777344,176.837082 916.007690,179.636414 
	C916.128052,180.166672 916.256104,180.333328 916.192078,180.750000 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M879.603516,248.021912 
	C878.759766,253.599457 883.988342,257.231659 884.356323,262.404541 
	C884.589233,265.679321 885.428040,268.911011 885.997559,272.580994 
	C884.347961,272.961090 882.492188,272.773102 881.285034,273.955811 
	C878.661560,276.526276 876.691833,280.366974 872.972900,274.739838 
	C871.789429,272.949219 870.075867,275.920563 868.296509,276.843231 
	C867.330139,273.754761 871.875366,270.895844 868.572266,267.274445 
	C867.871887,266.506531 869.808289,265.622345 870.864441,265.227997 
	C878.694702,262.304321 878.591431,262.329956 877.227356,253.736588 
	C876.912781,251.754837 875.643188,248.906677 879.603516,248.021912 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M925.375000,160.999435 
	C922.668335,157.028336 921.423462,152.929565 923.256104,148.357269 
	C923.605957,147.484344 923.802185,146.390991 923.616638,145.492844 
	C923.111206,143.046280 921.878174,140.277054 925.312683,139.221481 
	C929.424316,137.957809 933.795044,136.733612 937.865234,139.166748 
	C939.629700,140.221573 938.064453,141.759613 937.079285,142.322311 
	C930.077942,146.321091 930.199646,153.837143 928.010925,160.608307 
	C927.250000,160.999420 926.500000,160.999451 925.375000,160.999435 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M956.756042,127.349510 
	C955.211060,126.607445 954.033508,125.983170 952.813782,125.456299 
	C947.344788,123.093933 947.303528,122.937874 951.858215,118.825424 
	C952.385925,118.348969 953.004517,117.868698 952.759521,117.232689 
	C951.714844,114.520950 952.942139,112.513268 955.392822,112.591385 
	C958.602539,112.693718 962.098083,113.573677 964.080994,116.502365 
	C965.810059,119.056122 965.339966,121.981903 963.647583,124.494621 
	C962.130554,126.746933 960.363159,128.855789 956.756042,127.349510 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M993.353516,54.948853 
	C991.024292,52.015453 989.222961,49.509521 984.899597,52.206116 
	C982.343933,53.800179 980.402100,51.410633 980.958069,47.344990 
	C984.416504,47.374855 985.897644,45.638790 985.974976,42.408661 
	C986.010742,40.916718 985.931030,39.419716 986.020874,37.932072 
	C986.096069,36.685390 985.500000,34.905113 987.150330,34.482246 
	C989.211853,33.954033 988.707031,36.036888 989.207092,37.097321 
	C989.697510,38.137115 990.046814,39.515873 990.897461,40.064228 
	C993.903625,42.002289 995.338440,44.314014 994.127625,47.959465 
	C993.741760,49.121353 994.837036,50.076092 995.377930,51.092472 
	C996.389160,52.992771 995.674438,54.247063 993.353516,54.948853 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1064.777710,80.992767 
	C1061.235352,81.364944 1059.608521,80.207848 1059.984985,76.862389 
	C1060.095093,75.884727 1060.000366,74.884048 1060.000000,73.893913 
	C1059.997192,65.782570 1063.137939,63.909527 1070.785645,67.481270 
	C1071.507202,67.818291 1072.407349,68.080566 1072.445312,69.096329 
	C1072.482422,70.084557 1071.756348,70.685966 1070.951294,70.770615 
	C1066.901611,71.196472 1067.220581,72.935028 1068.661865,76.070465 
	C1069.847900,78.650734 1068.696899,80.977165 1064.777710,80.992767 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M1077.217529,39.769722 
	C1073.868286,36.965794 1074.097656,33.787659 1076.450439,31.364450 
	C1078.898071,28.843506 1082.350464,29.405621 1085.077881,31.293133 
	C1087.147217,32.725166 1087.935791,35.497032 1086.431885,37.446743 
	C1084.317505,40.187969 1081.430176,42.435215 1077.217529,39.769722 
z"/>
<path fill="#DDD9D9" opacity="1.000000" stroke="none" 
	d="
M986.763184,192.396881 
	C986.405029,188.202469 988.029785,184.604004 987.999634,180.428848 
	C991.372009,179.528275 990.994019,183.051514 992.835693,184.692413 
	C993.000000,187.699844 993.000000,190.399673 993.000000,193.549454 
	C993.169739,196.503937 991.492310,197.095596 989.583008,196.892746 
	C987.296875,196.649796 986.280762,195.105576 986.763184,192.396881 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1059.268311,93.333923 
	C1056.983032,94.225403 1055.962646,93.457184 1056.064331,91.483040 
	C1056.209839,88.663605 1057.288574,86.263741 1060.136597,85.229156 
	C1061.470581,84.744553 1062.898682,85.109146 1063.526611,86.534958 
	C1064.305908,88.304703 1064.373169,90.158531 1062.657959,91.495010 
	C1061.755127,92.198425 1060.635376,92.623474 1059.268311,93.333923 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M967.424377,73.000305 
	C967.592651,69.661385 970.054993,68.458435 972.982544,68.136620 
	C974.997559,67.915115 975.893005,69.316101 975.989136,71.619408 
	C973.343567,72.821220 970.634705,73.214737 967.424377,73.000305 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M915.962769,121.670784 
	C911.697937,120.988800 911.470825,118.057381 912.742188,114.270508 
	C913.906799,114.816391 914.813599,115.632912 915.860229,116.724869 
	C914.980103,118.430542 915.380981,119.885132 915.962769,121.670784 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M980.256958,32.728561 
	C976.916687,32.205273 978.371216,29.657858 977.998291,27.407707 
	C983.977295,26.446764 980.144653,30.420097 980.256958,32.728561 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M986.079102,73.343216 
	C986.743591,71.481766 987.787842,70.785637 989.718994,71.876877 
	C989.712341,74.818863 988.810669,76.244225 986.079102,73.343216 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M937.000610,165.000610 
	C935.435364,168.219498 934.218323,167.060226 933.048096,164.345734 
	C932.998474,164.001221 933.000000,164.000000 933.001221,163.999390 
	C934.304504,162.963684 935.410522,163.414337 936.718750,164.725494 
	C936.998413,164.998779 937.000000,165.000000 937.000610,165.000610 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1047.341797,56.413391 
	C1046.626587,56.612320 1046.366211,56.362900 1046.477905,55.806908 
	C1046.501099,55.691578 1046.664917,55.536705 1046.781616,55.519466 
	C1047.342773,55.436550 1047.577759,55.709812 1047.341797,56.413391 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M197.632629,176.958923 
	C200.400162,177.422806 203.487015,175.747787 204.938370,179.626404 
	C204.689575,180.309586 204.379517,180.619537 204.069702,180.929733 
	C204.236465,180.765839 204.400650,180.599365 204.783203,180.217239 
	C210.105545,179.655701 209.602234,182.913116 209.001114,186.616302 
	C210.622452,188.430527 210.721436,190.804016 212.485443,192.581711 
	C213.481079,194.256638 213.821228,195.745209 213.817184,197.707382 
	C211.295013,202.680725 208.649109,206.996277 201.904785,205.059784 
	C197.543808,203.399872 193.647659,200.825531 189.333527,204.508728 
	C188.031113,205.620651 186.123123,204.638336 184.519669,204.268570 
	C181.219849,203.507629 178.950089,204.357666 178.408798,208.056656 
	C178.289017,208.875305 177.998322,209.661331 177.724503,210.445496 
	C174.247116,220.404205 171.433533,222.012207 161.297806,219.840881 
	C158.974548,219.343170 158.155853,217.518997 157.682968,215.543137 
	C157.178009,213.433228 156.323761,211.815002 153.576126,211.540344 
	C150.997818,211.347275 148.878983,210.552490 149.278152,214.608215 
	C149.919556,221.125244 145.070251,223.958786 139.643280,226.414001 
	C136.406418,228.376938 132.355789,225.593323 129.480438,229.106674 
	C128.442886,229.513733 127.740829,229.390656 126.727348,228.960388 
	C125.287521,228.077438 124.337013,227.146881 123.308853,226.315201 
	C121.941315,225.208984 119.950912,224.377350 120.611343,222.070328 
	C121.232994,219.898758 123.057747,219.102600 125.092346,218.642822 
	C126.352386,218.358109 127.756912,218.448929 129.116898,217.005630 
	C125.687004,216.577347 121.777931,216.548416 123.751801,211.246719 
	C125.770523,209.689529 127.619507,208.683121 129.961227,208.978195 
	C131.749176,209.203461 133.201462,208.451508 134.367737,207.111252 
	C135.123230,206.243073 136.204147,205.412018 135.626068,204.106674 
	C134.946945,202.573135 133.699188,203.331558 132.617645,203.631439 
	C132.457397,203.675858 132.290894,203.798187 132.144058,203.775558 
	C129.928909,203.434525 126.884758,206.091507 125.839729,203.993286 
	C124.735909,201.776978 128.887604,202.382019 129.839691,200.611099 
	C131.856903,196.859055 134.241104,194.818634 138.297272,198.442123 
	C139.683960,199.680908 141.038391,198.173889 142.335098,197.496490 
	C143.598892,196.836304 145.005280,196.741302 146.025757,197.863190 
	C147.391785,199.365036 145.691681,199.612961 144.918228,200.358505 
	C143.752853,201.481873 144.645798,202.628815 145.511963,203.314484 
	C146.833298,204.360474 147.199677,203.131943 148.050613,202.220093 
	C150.798050,199.275955 152.808090,196.313492 152.141388,191.957428 
	C151.844360,190.016800 153.477585,189.129074 155.405884,189.018768 
	C157.992737,188.870819 159.811859,189.676056 160.910583,192.269501 
	C162.841156,196.826553 163.936996,196.845520 166.954529,192.345795 
	C166.965042,188.820312 168.310349,187.589233 171.740616,189.058594 
	C172.677063,189.777466 173.064545,190.562576 173.757065,190.977051 
	C175.398682,191.959625 177.149902,191.418320 178.216751,190.298477 
	C179.483490,188.968811 177.488098,188.170441 176.957352,187.135574 
	C176.589111,186.417603 176.131165,185.746857 175.820724,184.590912 
	C177.048096,178.333694 180.069336,177.300385 185.199387,181.371338 
	C186.477173,183.369125 186.682007,185.634064 189.060577,186.783508 
	C193.081879,187.819580 195.207336,192.329742 199.997040,191.630615 
	C201.396500,192.890839 201.550232,194.429581 202.162308,195.752716 
	C202.762848,197.050873 203.635422,198.177277 205.157227,197.312958 
	C206.754639,196.405685 205.464828,195.376755 204.894943,194.497177 
	C204.337463,193.636673 204.060593,192.737350 204.220078,191.398132 
	C204.367188,190.366257 205.369080,189.970032 204.967804,189.140533 
	C204.913788,189.028900 204.417404,189.243408 203.882767,189.591614 
	C202.913925,189.942307 202.234375,189.937347 201.222809,189.610687 
	C199.487808,187.320786 197.853394,185.903519 194.978470,186.807526 
	C193.578445,187.247742 192.142639,186.644714 190.624115,185.541504 
	C189.110474,183.786621 188.617432,182.085037 189.066467,179.791183 
	C191.174438,176.324112 194.286530,176.837158 197.632629,176.958923 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M197.996063,176.665726 
	C196.320084,180.496155 192.766876,176.836761 190.357529,178.953888 
	C188.820160,178.592789 188.516586,176.801178 186.892166,177.029327 
	C185.158081,178.810638 188.056976,181.523376 185.338074,183.081635 
	C183.554138,181.547821 182.534195,179.350327 179.813477,180.218552 
	C177.828293,180.852066 176.798737,182.085373 177.002472,184.610168 
	C175.517059,186.563660 174.039322,188.133377 172.297562,189.868286 
	C170.183914,189.638855 168.122025,188.566162 167.241425,191.786789 
	C165.670395,190.837234 166.072693,189.252808 165.993820,187.793854 
	C165.914185,186.320786 165.733688,185.138657 163.692764,185.617035 
	C159.417938,186.619080 156.711121,181.256622 152.229279,182.690475 
	C151.758347,182.841141 151.214203,181.935257 151.304718,181.186371 
	C151.442261,180.048248 152.199097,179.455032 153.229965,179.175415 
	C158.803085,177.663666 164.375351,176.670288 169.100220,181.217392 
	C172.085114,184.089996 172.757324,182.354553 173.364532,179.497070 
	C173.815659,177.374191 174.618011,175.452591 176.950836,174.462067 
	C179.717026,173.287521 179.263550,171.345963 177.261688,169.925125 
	C173.114990,166.981934 175.993820,165.057114 178.293869,163.607086 
	C180.339844,162.317200 182.904190,161.849548 185.618958,161.009491 
	C186.813736,161.097656 187.628464,161.272003 188.445007,161.280853 
	C195.045807,161.352524 196.977692,165.432251 196.997345,171.588959 
	C196.579025,172.683075 195.484390,172.406509 195.236359,173.156525 
	C195.168060,173.363083 196.022934,173.484406 196.727341,173.821808 
	C197.814972,174.571121 197.983093,175.417328 197.996063,176.665726 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M123.645103,210.842743 
	C123.981956,214.169357 124.172188,217.069260 128.616440,214.729019 
	C129.790909,214.110580 130.859482,214.184616 131.549072,215.466293 
	C132.203827,216.683197 131.712982,217.738037 130.818665,218.561996 
	C130.234192,219.100525 129.503494,219.638718 128.754181,219.810852 
	C126.310089,220.372345 122.618164,219.036911 121.864693,222.185699 
	C121.199318,224.966339 125.834923,224.852768 126.840866,227.700180 
	C128.085068,229.503754 128.709366,231.150665 128.566895,233.422943 
	C128.471039,234.338730 128.203995,234.761032 127.383530,235.038620 
	C124.758766,235.014740 122.340210,235.042923 123.295052,231.689911 
	C124.666458,226.874069 119.102051,227.792908 117.711029,224.889923 
	C116.724228,222.830521 115.319527,226.612106 113.328003,226.937408 
	C113.000282,224.211502 112.979271,221.422821 113.004311,218.634537 
	C113.070312,211.285873 116.005287,209.024765 123.645103,210.842743 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M113.308853,197.294891 
	C113.227760,194.776016 112.732231,192.871490 110.163322,192.610764 
	C106.625320,192.251663 104.317924,189.639053 101.410767,188.140335 
	C100.580849,187.712463 99.494797,186.932739 99.352661,186.161957 
	C98.620110,182.189529 102.506798,181.093323 104.378944,178.763199 
	C105.842361,176.941788 108.197121,176.340927 110.278236,177.432999 
	C112.570114,178.635666 113.596481,181.680161 112.718414,183.449112 
	C109.439713,190.054260 112.761612,192.058380 118.413971,193.062729 
	C118.945793,193.157227 119.367302,193.872559 119.919403,194.648911 
	C118.524269,197.470810 116.555977,198.726654 113.308853,197.294891 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M74.222733,217.806732 
	C71.404167,216.242523 70.780022,218.580032 69.757744,219.873856 
	C68.736801,221.166000 67.967773,222.246536 66.212593,220.812256 
	C64.866661,219.712448 63.187500,218.651657 64.819717,216.873291 
	C66.576614,214.959091 64.856079,211.729645 66.973953,210.577362 
	C69.456947,209.226410 70.562080,213.031204 72.977287,213.182663 
	C74.097702,213.252930 75.256454,213.731339 76.267715,214.269302 
	C77.591156,214.973297 80.190666,215.172684 79.220131,217.357620 
	C78.429230,219.138184 76.321579,219.394928 74.222733,217.806732 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M68.763077,254.344330 
	C66.770859,251.312912 63.290676,249.692795 63.128555,245.315277 
	C63.018848,242.353027 64.548149,242.106277 66.539207,241.671387 
	C69.693863,240.982330 68.614296,242.869705 68.418167,244.494720 
	C68.154564,246.678818 69.817932,248.020172 70.991760,249.593918 
	C72.465202,251.569351 73.735130,253.697586 73.990662,256.627472 
	C72.699409,258.107788 71.299103,258.435272 69.371292,257.276337 
	C69.000008,257.001221 69.000000,257.000000 68.999695,257.000000 
	C68.421463,256.314667 68.267693,255.542923 68.763077,254.344330 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M71.151917,233.341019 
	C70.883667,230.730225 72.153435,228.751816 73.210617,226.802048 
	C74.944031,223.605087 77.982346,224.006241 80.569839,225.270828 
	C83.033104,226.474655 82.970940,228.917450 82.011475,231.277161 
	C81.265732,233.111237 79.563377,233.667862 78.599091,232.210724 
	C76.498093,229.035889 75.616646,230.176498 74.413620,232.736755 
	C73.871910,233.889618 72.642715,234.135910 71.151917,233.341019 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M112.832947,197.301422 
	C115.430824,197.488724 117.272736,196.034317 119.636047,195.051270 
	C121.158653,198.762863 119.176674,202.958420 115.616142,203.816467 
	C113.480225,204.331192 110.886612,204.394241 109.666634,202.378296 
	C107.890007,199.442566 111.796577,199.318344 112.832947,197.301422 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M34.474895,243.636856 
	C36.622177,244.234634 38.287380,242.278427 40.455170,243.678970 
	C39.215260,245.829056 37.478737,248.173233 35.136600,247.696136 
	C32.455357,247.149979 30.046349,245.131073 27.615950,243.598389 
	C27.095757,243.270340 27.419617,242.373871 27.939785,241.845139 
	C28.592817,241.181335 29.447128,240.946426 30.221659,241.307709 
	C31.570900,241.937073 32.826603,242.766953 34.474895,243.636856 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M25.000299,232.580322 
	C22.824675,233.270218 21.166975,232.608124 20.058876,230.578369 
	C19.446472,229.456604 19.746960,228.690552 20.582632,228.008850 
	C21.596294,227.181961 22.701454,226.122711 24.077198,226.618866 
	C25.459896,227.117538 24.882332,228.597443 24.984932,229.681976 
	C25.062410,230.500992 25.001169,231.333145 25.000299,232.580322 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M70.903580,199.239838 
	C73.662132,200.723938 72.925232,203.209015 73.999748,206.008972 
	C71.180336,204.727783 68.881737,204.182419 68.265762,201.719727 
	C67.888542,200.211548 69.027779,199.354370 70.903580,199.239838 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M35.770836,230.006226 
	C33.534618,231.361160 29.140726,229.036789 29.440979,226.901764 
	C29.516432,226.365204 30.047569,225.772202 30.530952,225.441330 
	C31.287399,224.923569 32.152992,225.131561 32.724258,225.811935 
	C33.769897,227.057281 34.719364,228.383392 35.770836,230.006226 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M77.188293,265.079041 
	C75.707657,266.014526 74.547173,267.382812 72.989571,266.175507 
	C71.777718,265.236206 72.476791,264.108673 72.998383,263.081543 
	C73.638969,261.820129 74.560928,260.794403 76.086472,261.296875 
	C77.808884,261.864166 78.252159,263.154419 77.188293,265.079041 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M191.402802,128.417725 
	C190.509033,127.715614 189.805801,127.198425 189.446960,126.503410 
	C188.799652,125.249756 188.879456,123.949539 189.933777,122.875580 
	C190.565918,122.231659 191.402374,121.987267 192.153015,122.513824 
	C193.259293,123.289848 192.995743,124.570976 192.971359,125.685066 
	C192.947845,126.759407 193.320023,128.084396 191.402802,128.417725 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M33.012566,266.362671 
	C33.394073,263.625854 34.802422,263.945648 36.691864,264.863708 
	C36.982719,266.533020 36.952286,268.153534 35.154171,268.680359 
	C33.601078,269.135315 33.121029,268.052094 33.012566,266.362671 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M41.001953,262.381104 
	C41.126499,261.695435 41.320385,261.442261 41.790619,261.120239 
	C43.292500,261.827606 45.399635,262.085114 44.593052,264.432800 
	C44.317612,265.234497 43.598137,265.808472 42.691395,265.741028 
	C40.716564,265.594025 41.149841,263.995453 41.001953,262.381104 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M91.260666,225.047318 
	C93.494682,225.189453 94.464111,225.979843 93.297844,227.861893 
	C92.676208,228.865082 91.611465,228.895828 90.785004,228.098770 
	C89.768822,227.118744 88.100838,225.944489 91.260666,225.047318 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M69.050652,257.349396 
	C70.388741,257.001282 71.777466,257.001373 73.582794,257.001953 
	C72.843590,260.594513 71.267647,261.226135 69.050652,257.349396 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M92.178604,185.040131 
	C89.083939,185.837784 90.246742,183.708725 90.180550,182.529297 
	C90.139999,181.806870 90.807114,181.295044 91.500252,181.590683 
	C93.028389,182.242416 93.113228,183.442520 92.178604,185.040131 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M66.380280,233.485107 
	C65.062828,232.377777 65.042297,231.418015 66.247597,230.612503 
	C67.053749,230.073761 67.928589,230.402725 68.292168,231.137573 
	C69.075630,232.721115 68.297371,233.505295 66.380280,233.485107 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M25.000572,232.999390 
	C25.163658,233.002365 25.326538,233.000183 25.488630,233.010422 
	C26.998621,233.105957 28.570942,233.227356 28.539246,235.258957 
	C28.532482,235.692413 27.705374,236.494232 27.289293,236.473160 
	C25.631229,236.389160 25.529110,234.857697 25.041187,233.342072 
	C24.998928,233.001221 25.000000,233.000000 25.000572,232.999390 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M76.835365,177.520203 
	C76.333015,175.751907 77.278290,175.256958 78.552399,175.242630 
	C79.262306,175.234634 79.827545,175.824875 79.713013,176.516785 
	C79.454086,178.080887 78.277672,177.997482 76.835365,177.520203 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M68.638229,254.014374 
	C68.998268,254.751358 68.998672,255.500900 68.999237,256.625214 
	C67.912811,256.882782 66.491508,257.181641 66.305542,255.608307 
	C66.152184,254.310776 67.273560,254.131958 68.638229,254.014374 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M53.260902,251.532715 
	C53.517464,252.539169 53.016460,252.852814 52.361023,252.725784 
	C52.056038,252.666672 51.570835,252.095245 51.627861,251.875610 
	C51.813168,251.161850 52.440437,251.142822 53.260902,251.532715 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M64.532455,185.738632 
	C65.539291,185.481888 65.852974,185.983124 65.725883,186.638794 
	C65.666725,186.943924 65.095116,187.429367 64.875427,187.372314 
	C64.161377,187.186935 64.142487,186.559402 64.532455,185.738632 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M242.259735,66.772430 
	C242.321426,65.827934 242.659500,65.321144 243.366379,65.714577 
	C243.621887,65.856789 243.797287,66.563049 243.673309,66.880913 
	C243.401230,67.578529 242.855255,67.641975 242.259735,66.772430 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M36.486351,235.765381 
	C36.847851,236.593170 36.820229,237.221619 36.097794,237.382462 
	C35.875137,237.432037 35.320610,236.929535 35.270622,236.622452 
	C35.163872,235.966583 35.487331,235.472534 36.486351,235.765381 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M62.259884,207.772354 
	C62.321651,206.828079 62.659531,206.321213 63.366367,206.714615 
	C63.621811,206.856781 63.797096,207.562973 63.673149,207.880814 
	C63.401138,208.578293 62.855278,208.641937 62.259884,207.772354 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M61.341858,224.413651 
	C60.626324,224.612717 60.365856,224.363022 60.477573,223.806854 
	C60.500759,223.691437 60.664787,223.536484 60.781563,223.519226 
	C61.343048,223.436218 61.578068,223.709625 61.341858,224.413651 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M56.586441,219.341949 
	C56.387371,218.626465 56.637203,218.366257 57.193161,218.477936 
	C57.308537,218.501114 57.463428,218.665115 57.480690,218.781860 
	C57.563690,219.343277 57.290318,219.578156 56.586441,219.341949 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1168.156982,1264.703369 
	C1165.958374,1265.000000 1163.916626,1265.000000 1161.437500,1265.000000 
	C1159.728516,1262.353149 1159.795166,1258.836914 1155.232910,1258.747559 
	C1152.837524,1258.700806 1153.039917,1256.179321 1154.099609,1254.430542 
	C1157.399780,1248.984375 1158.511353,1242.666748 1161.180298,1236.974365 
	C1164.614868,1229.649048 1164.247437,1227.972412 1157.837158,1223.045776 
	C1155.590698,1221.319092 1155.942383,1219.365356 1156.974365,1217.301636 
	C1158.538330,1214.174561 1159.909180,1210.967773 1161.191162,1207.719849 
	C1162.393188,1204.674438 1161.528687,1203.060425 1158.099243,1202.649170 
	C1151.583374,1201.868042 1146.390137,1199.109375 1142.424194,1193.437866 
	C1139.825928,1189.722412 1135.428467,1187.448242 1131.601807,1184.879150 
	C1129.202148,1183.268066 1127.583496,1181.469971 1127.365479,1178.566162 
	C1127.107300,1175.122925 1125.391357,1172.672119 1122.398926,1171.009399 
	C1120.360474,1169.876831 1118.649658,1168.235596 1116.648438,1167.058838 
	C1113.593506,1165.262329 1112.035400,1162.658813 1112.531982,1159.195312 
	C1113.024902,1155.756958 1111.354492,1154.143799 1108.578613,1152.624878 
	C1104.273315,1150.269287 1099.578613,1149.191406 1094.971436,1147.837769 
	C1091.768677,1146.896851 1089.666870,1145.207642 1089.103760,1141.456543 
	C1090.715210,1135.464233 1093.402466,1134.613647 1097.253784,1138.541870 
	C1098.507812,1139.820801 1099.477173,1141.562012 1102.968628,1140.232666 
	C1098.417236,1138.635986 1095.755981,1135.338257 1091.300171,1135.561035 
	C1089.298340,1135.661255 1087.299438,1135.288452 1085.484497,1134.359497 
	C1080.976318,1132.052490 1077.168457,1131.690063 1074.147217,1136.938965 
	C1072.846802,1139.198120 1070.113647,1140.055176 1067.673218,1137.938599 
	C1065.622314,1136.159912 1065.566284,1131.914917 1067.950317,1130.444702 
	C1070.699097,1128.749756 1070.812500,1126.708130 1070.061401,1123.838501 
	C1069.739380,1123.403564 1069.584106,1123.251465 1069.186523,1122.888428 
	C1067.079712,1121.326172 1065.755371,1122.720337 1064.018799,1123.748779 
	C1063.051758,1124.188232 1062.363892,1124.344360 1061.298828,1124.420410 
	C1058.288086,1124.442505 1055.630127,1123.956909 1055.224243,1120.896729 
	C1054.767700,1117.453857 1054.819946,1113.710571 1057.820312,1111.264893 
	C1060.460815,1109.112671 1062.374878,1110.847900 1063.766968,1113.294800 
	C1064.310791,1114.250610 1064.384888,1115.843750 1065.832275,1115.748535 
	C1067.588135,1115.633301 1066.857056,1113.996826 1067.052246,1112.976685 
	C1068.339722,1106.246338 1068.167114,1106.075195 1061.180420,1107.105103 
	C1059.870361,1107.298340 1058.547607,1107.424194 1057.250122,1107.678711 
	C1052.756348,1108.560669 1051.567383,1110.457397 1053.254395,1114.635132 
	C1054.563354,1117.876831 1054.886841,1121.007080 1053.451904,1124.600830 
	C1053.261841,1125.158569 1053.173218,1125.377319 1052.897461,1125.895996 
	C1049.009155,1128.654663 1047.099487,1132.861938 1043.342896,1135.724487 
	C1042.380493,1136.407959 1041.626099,1136.848022 1041.593018,1138.196289 
	C1040.192139,1142.517212 1042.993408,1146.975586 1039.893921,1151.044678 
	C1037.544800,1152.528442 1035.761353,1151.835449 1034.268311,1150.287476 
	C1033.409424,1149.396973 1032.672119,1148.604614 1030.975098,1149.223022 
	C1027.962036,1149.468628 1025.529541,1149.782837 1023.379150,1151.900513 
	C1020.780212,1154.460083 1015.569641,1154.194580 1011.863464,1151.899536 
	C1008.329529,1149.711182 1006.697510,1146.170776 1007.879944,1142.046509 
	C1009.148071,1137.623291 1011.436768,1133.571899 1011.216614,1128.651855 
	C1011.097900,1125.998413 1014.329895,1125.356689 1016.366577,1127.547363 
	C1018.769226,1130.131836 1018.698242,1133.236572 1017.857117,1136.453613 
	C1017.555359,1137.608032 1016.595154,1139.346680 1018.785461,1139.530273 
	C1020.457214,1139.670288 1020.828918,1138.152832 1020.802979,1136.702026 
	C1020.782227,1135.539551 1020.580688,1134.384277 1020.626831,1133.219482 
	C1020.769836,1129.606079 1022.615967,1127.717407 1026.273071,1127.711792 
	C1027.930908,1127.709229 1029.581421,1127.934326 1031.248169,1127.726196 
	C1036.010742,1127.131348 1039.750000,1122.875854 1038.795654,1119.077881 
	C1038.067749,1116.181030 1032.044678,1113.446655 1028.576172,1115.277100 
	C1024.807739,1117.265869 1021.313904,1118.106323 1017.233887,1116.935547 
	C1016.089478,1116.606934 1014.956970,1117.198608 1014.365356,1118.423462 
	C1013.860168,1119.469360 1013.342041,1120.505493 1012.692505,1121.479126 
	C1008.816772,1127.289185 1008.824707,1127.293701 1002.545044,1124.537964 
	C1001.630859,1124.136963 1000.684509,1123.823730 999.391479,1123.431519 
	C995.382446,1122.319946 991.666016,1121.436401 988.472900,1119.196167 
	C986.613403,1117.891602 984.668518,1118.613892 982.717651,1118.969727 
	C980.078308,1119.451172 977.559631,1119.052734 976.318420,1116.417603 
	C974.999268,1113.617310 975.958008,1111.010498 978.586853,1109.588623 
	C982.295776,1107.582397 979.475220,1106.895630 977.804749,1105.601440 
	C976.434814,1103.765503 975.024048,1102.957031 972.961121,1103.240967 
	C970.485901,1103.581665 968.067932,1103.008911 965.348267,1102.281372 
	C963.890076,1101.750122 962.536011,1100.959961 962.624634,1099.907349 
	C963.017578,1095.236328 959.124817,1092.319824 958.026245,1088.169189 
	C957.241577,1085.204834 953.067627,1084.068970 949.774658,1082.521973 
	C948.157959,1082.670410 946.955017,1082.458374 945.473511,1081.781494 
	C943.144470,1078.742554 945.538330,1077.410645 947.168030,1075.869629 
	C950.069519,1073.126221 953.186096,1070.549683 953.936890,1065.896973 
	C954.201904,1065.349487 954.332642,1065.144165 954.732910,1064.696289 
	C957.503723,1063.205933 959.425049,1064.432617 961.405884,1065.821777 
	C965.222107,1068.498291 966.549255,1068.044556 968.240601,1063.633911 
	C968.596313,1062.706177 968.650879,1061.645630 969.217957,1060.816650 
	C970.737366,1058.595581 973.198547,1058.531860 973.938599,1060.687012 
	C974.978088,1063.714478 976.771301,1063.104858 979.132202,1063.055176 
	C979.691284,1063.244263 979.905029,1063.347656 980.396545,1063.673218 
	C985.323120,1068.483276 987.326172,1068.149048 989.723755,1062.215576 
	C992.302551,1061.520508 995.222534,1063.324951 995.786255,1058.389771 
	C996.128113,1055.397339 999.152832,1057.843506 1000.835449,1059.692383 
	C1003.194031,1063.448975 1004.881226,1061.631836 1006.767700,1059.234985 
	C1007.805237,1058.753052 1008.610535,1058.506104 1009.707886,1058.129639 
	C1013.057800,1054.541016 1016.983582,1055.378052 1021.234009,1055.962402 
	C1025.483765,1060.481567 1025.421509,1063.969482 1021.285767,1067.412476 
	C1019.396606,1068.985107 1017.597961,1070.682617 1015.250427,1072.294434 
	C1011.605408,1074.356567 1008.894836,1076.509521 1009.027710,1080.925903 
	C1009.102722,1083.418701 1007.461060,1084.821533 1004.975647,1085.172729 
	C1003.875732,1085.328247 1002.644409,1085.178589 1001.803833,1086.266724 
	C1005.200806,1088.061035 1006.614075,1088.165527 1008.418091,1085.585938 
	C1010.976624,1081.927612 1013.500854,1082.692139 1016.423584,1084.793213 
	C1017.952026,1085.891968 1019.303528,1087.534424 1021.609558,1087.044556 
	C1021.791626,1084.733154 1019.863586,1084.251221 1018.778870,1083.152832 
	C1016.582947,1080.929321 1017.178406,1079.041626 1019.888672,1078.354004 
	C1022.354675,1077.728394 1023.813354,1076.446533 1025.327393,1074.514893 
	C1027.966064,1071.148193 1036.695068,1069.743530 1041.214111,1071.700684 
	C1043.645630,1072.471924 1045.741821,1073.112305 1048.310303,1073.389160 
	C1054.630737,1071.553101 1060.814087,1071.706421 1066.893433,1071.057129 
	C1069.134155,1070.817749 1071.213867,1071.267578 1072.838013,1073.614380 
	C1073.230957,1074.785278 1073.455200,1075.572754 1073.694580,1076.355469 
	C1074.101318,1077.684937 1075.678101,1079.031738 1074.243774,1080.384155 
	C1072.921631,1081.630737 1071.504639,1080.640503 1070.219238,1079.829468 
	C1068.734009,1078.892090 1066.699463,1078.280762 1065.707886,1079.719604 
	C1064.199341,1081.909058 1067.033569,1082.092163 1068.029297,1083.130615 
	C1070.749023,1085.966919 1074.568726,1087.746338 1074.541870,1092.842529 
	C1074.524658,1096.115845 1078.114746,1094.606812 1080.588623,1095.000000 
	C1083.824463,1098.483643 1079.963745,1099.652100 1078.539185,1101.767700 
	C1080.967773,1104.761597 1084.423706,1104.797729 1088.047974,1105.359009 
	C1090.699341,1107.606323 1091.005981,1110.190918 1089.442383,1112.692383 
	C1087.918823,1115.129761 1085.304321,1115.147705 1082.722778,1114.643188 
	C1082.233154,1114.547485 1081.757446,1114.369507 1081.266235,1114.305176 
	C1079.044189,1114.014404 1075.989258,1112.063721 1074.778442,1114.195312 
	C1072.982544,1117.357178 1075.684082,1120.432983 1077.101074,1123.397217 
	C1078.373657,1126.059204 1080.263062,1126.578125 1083.122314,1125.231689 
	C1086.288330,1124.528076 1087.146729,1122.028564 1088.681274,1119.686279 
	C1090.856934,1118.345093 1092.869263,1117.518677 1095.454102,1117.447266 
	C1098.101318,1120.502686 1099.315063,1124.537109 1103.976685,1125.598999 
	C1107.382812,1126.615479 1107.239380,1129.375854 1107.791504,1131.757935 
	C1108.014771,1132.720947 1108.272583,1133.846680 1109.467896,1133.940796 
	C1110.410400,1134.015015 1110.721802,1133.008911 1111.196777,1132.351807 
	C1114.942139,1127.170654 1114.624023,1126.171265 1108.567505,1124.125122 
	C1107.462280,1123.751831 1106.330322,1123.466797 1104.873047,1122.979858 
	C1102.884521,1121.993286 1101.480225,1120.947510 1101.439941,1118.513184 
	C1103.112671,1116.461426 1103.246704,1114.745605 1102.550903,1112.419800 
	C1101.499634,1108.906250 1101.977295,1105.593018 1106.729492,1104.781738 
	C1107.878540,1105.505249 1108.756958,1106.010376 1109.817749,1106.757812 
	C1110.943115,1108.342163 1110.577148,1109.888306 1110.648071,1111.355835 
	C1110.769043,1113.866455 1112.268066,1115.249878 1114.414429,1116.119873 
	C1115.704590,1116.642700 1117.031616,1116.946899 1117.965088,1115.518799 
	C1118.770020,1114.287476 1118.053711,1113.333374 1117.229492,1112.434692 
	C1116.221069,1111.335449 1115.077271,1110.285645 1115.774170,1108.274170 
	C1117.385376,1108.003296 1118.775757,1108.001709 1120.583008,1108.000000 
	C1122.487915,1108.810913 1123.917969,1108.049438 1125.375854,1107.857544 
	C1128.328491,1107.469116 1130.268066,1108.777344 1130.907593,1111.635620 
	C1131.504517,1114.303101 1130.469238,1116.440063 1127.664307,1116.850952 
	C1124.010254,1117.386108 1123.578857,1120.214844 1122.608276,1122.606934 
	C1127.710449,1126.002930 1132.278320,1125.931030 1136.632202,1121.462646 
	C1138.195312,1119.858276 1140.636963,1119.243408 1142.538330,1120.999878 
	C1145.121704,1123.386353 1143.908569,1126.169922 1142.483398,1129.076660 
	C1141.702881,1129.721191 1141.072998,1130.098633 1140.437256,1129.993652 
	C1136.296387,1129.309204 1134.393555,1131.367798 1133.807251,1135.200317 
	C1133.709839,1135.836548 1133.243774,1136.374023 1132.580811,1137.146973 
	C1131.687012,1137.803467 1130.987183,1137.969849 1129.887695,1137.947632 
	C1127.679688,1137.423096 1126.611938,1135.929565 1125.346191,1134.679810 
	C1124.534912,1133.878906 1123.672729,1132.957275 1122.428345,1133.935425 
	C1121.475464,1134.684448 1121.738892,1135.687500 1122.130249,1136.604492 
	C1122.771606,1138.107544 1123.410156,1139.599976 1123.377808,1141.647217 
	C1123.363525,1142.348999 1123.262573,1142.683716 1123.286255,1143.005127 
	C1123.477905,1145.601074 1119.303223,1148.623779 1123.771118,1150.752563 
	C1128.259521,1152.891357 1133.586914,1150.955322 1135.706177,1146.520874 
	C1137.011963,1143.788696 1139.006592,1142.493164 1141.929199,1142.384399 
	C1146.573364,1142.211548 1151.222656,1142.315796 1155.867065,1142.078735 
	C1158.139648,1141.962646 1160.075806,1142.948730 1161.714355,1144.458374 
	C1164.912964,1147.405396 1164.440918,1150.748657 1160.358398,1152.051880 
	C1157.197510,1153.060913 1154.149414,1154.367920 1150.582031,1155.355347 
	C1148.662598,1155.639526 1147.125000,1155.765381 1145.212402,1155.623291 
	C1142.991699,1155.803101 1140.628540,1154.364624 1139.525146,1157.542236 
	C1138.476807,1160.865967 1138.891479,1163.943237 1138.774170,1167.333740 
	C1137.881714,1169.578369 1135.952271,1170.389771 1134.531250,1171.696777 
	C1140.642822,1177.618164 1140.642822,1177.618164 1145.643555,1174.440430 
	C1147.608398,1173.841553 1149.126465,1174.523926 1150.673950,1174.884155 
	C1152.377808,1175.280762 1153.494629,1174.772339 1154.075684,1172.683105 
	C1155.854370,1170.201782 1158.684326,1169.926392 1160.970947,1168.087769 
	C1162.912476,1167.427246 1164.488892,1167.400146 1166.403320,1168.104248 
	C1168.950684,1170.517212 1168.189087,1172.552979 1166.058472,1174.169189 
	C1164.299194,1175.503418 1163.615845,1177.178833 1164.687256,1178.907227 
	C1166.659424,1182.088867 1169.760986,1184.388672 1171.426636,1187.964111 
	C1172.180420,1189.582275 1173.708496,1188.027466 1174.032593,1186.597046 
	C1174.624146,1183.986450 1174.300415,1181.284790 1174.707886,1178.299072 
	C1175.040894,1176.824829 1175.550659,1175.782715 1176.021729,1174.729004 
	C1177.421631,1171.597778 1177.119751,1169.293457 1173.240479,1168.453979 
	C1171.423950,1168.060669 1169.641113,1167.329590 1169.427368,1165.131714 
	C1169.243530,1163.240479 1170.566406,1162.103760 1172.004639,1161.215454 
	C1173.725586,1160.152466 1175.571045,1159.212158 1174.984131,1156.692261 
	C1174.461060,1154.446899 1172.400146,1154.406860 1170.718384,1153.895020 
	C1169.458618,1153.511719 1168.126465,1153.368408 1166.622070,1152.531006 
	C1164.846191,1150.963623 1164.434082,1149.196045 1164.481445,1147.322998 
	C1164.577881,1143.506470 1163.121216,1141.075806 1159.137207,1140.182617 
	C1156.936768,1139.689209 1155.330688,1138.083618 1155.187500,1135.594238 
	C1155.094116,1133.972046 1155.928711,1132.739502 1157.244995,1131.835938 
	C1158.575928,1130.922363 1160.167358,1130.599365 1161.517334,1131.303833 
	C1165.641724,1133.456177 1170.233643,1134.395874 1174.687012,1136.735107 
	C1176.660645,1139.410278 1180.187622,1140.044922 1180.989014,1143.641602 
	C1181.116943,1146.722290 1184.970337,1148.241455 1183.340210,1151.659424 
	C1182.964600,1152.446899 1184.353882,1152.418945 1185.200928,1152.444946 
	C1188.822998,1152.556030 1192.441040,1152.802246 1196.059937,1153.011108 
	C1196.721802,1153.049316 1197.528809,1152.922852 1198.017456,1153.249023 
	C1202.991577,1156.569458 1207.551636,1160.474121 1213.498901,1162.289551 
	C1215.365845,1162.859619 1214.419922,1169.105713 1212.456055,1170.671631 
	C1209.455200,1173.064697 1202.779053,1172.321167 1200.587891,1169.071777 
	C1198.448730,1165.899536 1195.712524,1166.984497 1193.015137,1167.535522 
	C1190.957153,1167.956177 1189.027832,1169.345825 1186.814575,1167.953003 
	C1186.004028,1167.442749 1184.832520,1166.888306 1184.290161,1168.208618 
	C1183.728516,1169.576294 1184.130493,1171.198608 1185.576172,1171.540283 
	C1189.642456,1172.501465 1191.966187,1174.705200 1193.191406,1178.836792 
	C1194.025513,1181.649536 1198.260254,1180.503662 1200.008057,1183.261719 
	C1202.256714,1186.810303 1204.582764,1183.510986 1206.814331,1182.633911 
	C1209.235962,1181.682007 1212.011475,1181.308105 1214.105225,1182.427612 
	C1217.049805,1184.002197 1220.069214,1185.008911 1223.355347,1185.483887 
	C1223.344727,1185.989258 1223.333984,1186.494629 1223.323242,1187.000000 
	C1219.029053,1187.000000 1214.731689,1187.102417 1210.441284,1186.972412 
	C1205.776245,1186.831177 1201.152588,1186.261597 1201.774048,1193.439453 
	C1201.975586,1195.768555 1199.594971,1195.990234 1197.677246,1195.992554 
	C1193.514282,1195.997437 1189.350952,1195.969482 1185.188477,1196.013794 
	C1183.241089,1196.034546 1180.824707,1195.583374 1180.269653,1198.225342 
	C1179.810425,1200.411865 1179.161865,1202.966309 1181.890625,1204.414062 
	C1183.378906,1205.203613 1184.059570,1206.344604 1184.016235,1208.031494 
	C1183.971069,1209.791870 1184.307129,1211.456787 1186.411743,1211.833374 
	C1188.619751,1212.228638 1190.911865,1212.251465 1192.211182,1210.056519 
	C1193.179932,1208.419800 1193.689819,1206.947266 1196.219971,1207.709961 
	C1197.986084,1208.242310 1198.763428,1206.614014 1199.002197,1204.969727 
	C1199.396484,1202.254272 1201.336670,1200.582886 1203.687134,1200.505615 
	C1206.673828,1200.407471 1205.771362,1203.272461 1206.037476,1205.089478 
	C1206.337402,1207.139282 1207.787109,1207.977661 1209.378662,1209.056274 
	C1212.490479,1211.165527 1217.104736,1212.072876 1216.998779,1217.616943 
	C1215.008789,1220.003296 1212.644653,1218.750854 1210.418457,1218.776001 
	C1208.652222,1218.795898 1206.470215,1218.394043 1206.070557,1220.893188 
	C1205.695190,1223.239990 1206.662476,1225.188721 1209.065308,1225.925415 
	C1211.208862,1226.582520 1212.974121,1225.938354 1213.908081,1223.709229 
	C1214.660034,1221.913940 1214.747437,1219.786621 1216.753906,1218.246948 
	C1219.393188,1218.124878 1222.811401,1218.682861 1222.505859,1221.018799 
	C1221.947266,1225.287476 1225.230591,1230.710449 1219.989380,1233.760254 
	C1217.074219,1235.456665 1216.426514,1237.768555 1217.078735,1240.398193 
	C1218.523804,1246.223267 1213.488525,1250.626221 1214.000000,1256.581299 
	C1209.354126,1255.382080 1209.971069,1250.543457 1208.174683,1247.213257 
	C1207.343506,1245.672363 1208.476929,1242.840576 1205.283203,1243.097900 
	C1201.498657,1243.402954 1204.146362,1246.069824 1203.745117,1247.628784 
	C1203.670532,1247.918579 1203.419189,1248.162842 1203.244629,1248.425049 
	C1197.972900,1245.524780 1195.805786,1238.225708 1198.902466,1233.573975 
	C1200.041504,1231.862915 1200.728271,1229.986694 1200.896362,1228.018311 
	C1201.046265,1226.260986 1201.805054,1223.963745 1200.286133,1222.817871 
	C1198.759277,1221.666138 1197.361206,1223.652466 1195.929199,1224.352783 
	C1193.991577,1225.300293 1192.077271,1225.423462 1190.461060,1223.692627 
	C1187.696533,1220.732544 1186.685791,1222.469849 1185.940186,1225.289551 
	C1185.300171,1227.709473 1184.962891,1229.983398 1185.588013,1232.589722 
	C1187.190918,1239.271240 1186.357178,1240.578979 1180.117798,1243.171997 
	C1179.131958,1243.581787 1178.360474,1244.134521 1178.228027,1245.248779 
	C1178.094727,1246.371582 1178.653564,1247.188843 1179.633179,1247.640991 
	C1180.534058,1248.056641 1181.502930,1248.322632 1182.434814,1248.673462 
	C1187.822876,1250.701050 1188.340576,1253.471680 1183.797729,1257.040405 
	C1182.099365,1258.374512 1181.153931,1259.824829 1180.762207,1261.918945 
	C1180.476196,1263.447998 1179.337891,1264.590088 1177.676147,1264.775269 
	C1176.323730,1264.926147 1175.445557,1264.094482 1175.184814,1262.825928 
	C1174.170776,1257.892090 1172.454468,1258.593994 1170.239746,1262.125122 
	C1169.716187,1262.959961 1168.961914,1263.650146 1168.156982,1264.703369 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M954.750305,1064.763916 
	C956.250977,1070.889526 951.794006,1073.718140 948.340637,1076.913330 
	C947.175964,1077.990845 945.580261,1078.568604 945.048950,1080.647949 
	C944.836121,1081.168823 944.672180,1081.337524 944.183105,1081.640869 
	C942.821167,1081.960083 941.810364,1081.752075 941.056152,1081.085693 
	C938.950989,1079.225586 937.115234,1079.083008 935.174133,1081.396973 
	C933.950317,1082.855957 932.351807,1083.007568 930.595276,1081.372681 
	C930.051147,1080.439209 929.937561,1079.741943 929.982788,1078.668457 
	C931.090942,1075.585693 932.604431,1072.787109 928.650269,1070.517456 
	C928.162964,1069.867798 927.951294,1069.455566 927.685730,1068.690430 
	C924.935486,1063.824585 927.212341,1059.732056 928.705933,1055.551880 
	C930.078247,1051.710815 929.102905,1050.608765 924.194824,1050.819336 
	C922.434998,1051.263306 921.046631,1050.952271 919.316772,1050.609741 
	C917.589661,1050.234497 916.768494,1050.775391 916.390076,1052.151855 
	C915.408142,1055.723267 912.738037,1056.588989 909.077026,1056.390625 
	C907.204651,1056.257812 905.809082,1055.745483 904.110229,1054.985962 
	C902.511780,1054.025635 901.317749,1053.115601 900.183655,1052.143311 
	C896.821533,1049.261108 895.852722,1045.500854 897.410645,1041.882935 
	C899.086670,1037.990723 902.256226,1038.395386 905.451660,1039.119385 
	C908.879578,1039.896118 910.383179,1038.412109 910.845215,1035.141602 
	C911.301270,1031.913208 911.426880,1028.921509 907.223389,1028.364136 
	C904.675537,1028.026245 902.382263,1027.253662 900.323181,1025.738403 
	C896.780212,1023.131348 893.482727,1023.212463 889.873901,1025.976685 
	C885.978333,1028.960693 884.859497,1028.562744 882.562195,1024.605835 
	C881.452515,1026.595703 880.432495,1028.895386 877.640930,1028.453491 
	C874.788757,1028.002075 875.472473,1025.095337 874.514832,1023.132385 
	C863.291260,1027.809082 855.526001,1021.767456 848.159180,1014.578735 
	C847.202698,1013.645386 846.582520,1012.442200 846.046204,1011.214478 
	C843.636658,1005.699463 845.350952,1003.139160 851.172302,1003.615906 
	C850.028748,1001.439880 848.407043,1002.580872 846.838623,1002.873474 
	C841.149902,1003.934875 839.003235,1002.332397 838.731201,996.737122 
	C838.604248,994.125977 837.099060,993.311951 834.840637,993.223389 
	C831.680115,993.099365 828.530029,992.775513 825.401062,992.390869 
	C819.570862,991.674194 817.709717,988.984497 818.903870,983.234985 
	C819.381836,980.934204 819.325378,978.602173 819.625549,976.295288 
	C820.097717,972.666321 824.111023,972.356445 825.432800,970.291077 
	C826.626953,968.425171 824.820129,964.267944 827.852295,962.745422 
	C833.891174,959.713257 838.043457,953.593506 845.185303,952.401123 
	C846.783020,952.134399 848.355347,951.274292 849.241638,949.716248 
	C851.173035,946.321045 854.255554,945.051208 858.308594,944.906982 
	C859.569336,945.083252 860.420959,945.421204 861.555542,945.878906 
	C864.073669,945.200562 862.062317,942.462341 864.212097,941.259644 
	C874.295227,939.406311 883.773926,936.235840 893.700317,935.406250 
	C895.856323,935.226074 898.026794,935.138428 900.442871,936.261658 
	C900.954468,936.730652 901.113342,936.964661 901.136108,937.600586 
	C900.985291,939.496460 900.535217,940.795959 899.960388,942.223145 
	C898.626831,945.534058 895.378052,948.720276 898.315552,952.769897 
	C898.689819,953.285889 898.829529,954.093628 898.136108,954.546387 
	C897.342834,955.064453 896.410767,954.866699 895.880066,954.201599 
	C893.927246,951.754456 890.939026,950.926575 888.457458,949.319336 
	C887.099915,948.439941 885.501831,947.346741 883.937683,948.897034 
	C882.587769,950.234985 882.905701,951.951050 883.387512,953.588745 
	C883.527222,954.063416 883.835693,954.488464 883.975037,954.963135 
	C884.415405,956.462708 886.539917,958.049194 885.116455,959.369019 
	C883.468506,960.897217 882.371338,958.424255 881.035400,957.680054 
	C880.166565,957.196167 879.310547,956.644348 878.378906,956.329773 
	C877.274658,955.957031 875.990051,955.686890 875.371338,957.099854 
	C874.894836,958.188171 875.507324,959.267639 876.452271,959.571960 
	C882.197876,961.422180 880.976501,966.114136 880.975159,970.316467 
	C880.974670,971.914673 880.585693,973.400757 878.780823,973.766418 
	C877.175964,974.091614 875.433716,974.462952 874.152893,972.922119 
	C873.108276,971.665405 872.782288,970.219116 873.666077,968.758728 
	C876.113159,964.714783 874.727966,961.789673 870.990784,959.634094 
	C869.058899,958.519836 869.009155,956.781067 868.922058,954.915710 
	C868.853943,953.455383 868.170654,952.198853 866.524963,952.313354 
	C864.955322,952.422546 865.081360,953.856628 865.011475,955.006714 
	C864.961060,955.836243 865.009521,956.681946 864.862122,957.492859 
	C864.681091,958.488281 864.306091,959.546326 863.013062,959.332031 
	C861.954163,959.156555 862.099548,958.110840 862.027954,957.319275 
	C861.968140,956.657776 862.042358,955.982239 861.960815,955.324890 
	C861.756287,953.676575 861.615051,951.447693 859.640686,951.468506 
	C857.115112,951.495056 857.858337,954.054504 858.088745,955.387451 
	C858.917480,960.182129 853.843079,963.734192 855.555054,968.768555 
	C855.951477,969.934326 853.788391,970.202698 852.772949,969.879395 
	C849.299805,968.773682 848.780945,970.520020 848.973511,973.354614 
	C849.074402,974.839417 849.046509,976.368835 848.798096,977.830811 
	C848.577332,979.129883 848.045471,980.469604 846.503174,980.802673 
	C845.024841,981.121887 843.751099,980.569641 843.252563,979.153687 
	C842.216125,976.210327 839.838013,977.062988 837.798340,977.102051 
	C836.302856,977.130615 835.295227,978.043335 835.135864,979.575073 
	C834.959045,981.274292 835.657837,982.802979 837.435669,982.801086 
	C840.224243,982.798157 840.877441,984.656372 841.899841,986.623352 
	C844.033630,990.728943 846.024475,990.872803 849.353943,987.469788 
	C849.814514,986.999023 850.279175,986.264832 850.821716,986.183289 
	C855.784851,985.437683 858.676697,983.349609 858.612305,977.609924 
	C858.586304,975.292053 865.930054,973.783691 868.333130,975.234375 
	C868.613831,975.403809 868.909729,975.762695 869.162537,975.732422 
	C875.859802,974.931702 875.245239,978.416931 873.307556,982.724487 
	C873.185120,982.996582 873.174500,983.597717 873.307251,983.666016 
	C879.598877,986.903809 874.834290,993.061646 876.893311,997.492859 
	C877.678894,999.183533 878.388000,1000.791199 880.076111,1000.734924 
	C886.611206,1000.516907 892.434509,1004.866028 899.244629,1003.337891 
	C901.570007,1002.816223 904.054016,1004.452087 905.363892,1006.866211 
	C906.656067,1009.247498 905.044312,1010.565186 903.395508,1011.818787 
	C902.713684,1012.337158 902.039185,1012.902527 902.367920,1013.853455 
	C902.784546,1015.058777 904.029907,1015.243530 904.855347,1014.894592 
	C908.665649,1013.284058 911.975464,1014.909729 915.343506,1016.251526 
	C917.662720,1017.175476 919.561035,1017.116699 921.782837,1015.432556 
	C923.879150,1013.843445 926.679199,1013.182678 929.202454,1012.099426 
	C929.644531,1013.555420 929.333008,1014.193298 928.714844,1014.454407 
	C926.215088,1015.510315 923.143616,1016.543762 924.330322,1019.934448 
	C925.421509,1023.052429 928.409241,1024.558594 931.742615,1024.986084 
	C934.321411,1025.316772 937.188416,1024.116455 939.426758,1026.120972 
	C940.486816,1027.070190 941.022095,1028.596680 942.058044,1029.586548 
	C942.408447,1029.921387 943.567566,1029.410156 944.678528,1029.142822 
	C945.865784,1028.354370 945.952881,1027.230835 946.787048,1026.217529 
	C949.837830,1026.890869 950.904846,1029.325684 950.832458,1031.783447 
	C950.717224,1035.693237 953.027039,1036.786499 956.018921,1036.872192 
	C958.987183,1036.957397 960.266724,1038.011719 960.076477,1040.992554 
	C959.999146,1042.203369 960.725769,1043.853027 961.828979,1043.593140 
	C968.407715,1042.042236 969.064148,1047.720337 971.686707,1051.421875 
	C973.311462,1051.126099 973.674255,1048.580566 975.862793,1048.900024 
	C977.443054,1049.130737 978.939087,1048.227783 978.735229,1046.324585 
	C978.501892,1044.145020 976.745605,1043.749023 975.004944,1044.208130 
	C970.535278,1045.387085 968.873962,1040.533325 965.314941,1039.775146 
	C965.219543,1039.754761 965.431213,1038.293457 965.749878,1037.251953 
	C966.650024,1036.261475 967.516602,1035.774902 968.200378,1035.066162 
	C972.030823,1031.096313 973.323242,1031.387451 975.733826,1036.833008 
	C978.924316,1040.151978 982.376404,1035.841919 985.452942,1037.639893 
	C985.854919,1037.874878 987.010071,1036.986816 987.295776,1037.230225 
	C990.972778,1040.362549 992.848267,1039.753906 994.165527,1034.909546 
	C994.602905,1033.300903 996.816895,1034.264648 996.946472,1036.292969 
	C997.279053,1041.498901 1000.382812,1042.684204 1004.851440,1041.825562 
	C1006.458862,1041.516724 1007.895813,1041.067261 1007.962219,1043.480835 
	C1008.011658,1045.278198 1007.844116,1046.958740 1005.477966,1046.939453 
	C1003.156128,1046.920532 1000.800232,1046.973511 998.523621,1046.601440 
	C996.074524,1046.201294 993.864563,1045.012085 994.391174,1049.415283 
	C994.675842,1051.795654 992.225586,1051.116943 991.127380,1050.331787 
	C988.614807,1048.535400 986.580566,1048.811523 984.487854,1050.915405 
	C983.136902,1052.273560 981.483459,1052.078613 979.822327,1051.456055 
	C978.557800,1050.982178 977.173950,1051.140747 977.179565,1052.643433 
	C977.186279,1054.459961 974.283447,1056.193970 976.114075,1057.430908 
	C978.071594,1058.753662 980.887573,1058.434326 983.240417,1057.147339 
	C984.830444,1056.277710 985.579773,1053.331909 988.114563,1054.831299 
	C990.555847,1056.275513 989.935669,1058.886353 990.000000,1061.588623 
	C990.000000,1062.495605 990.074341,1063.004883 989.987122,1063.484619 
	C989.606689,1065.576782 990.737244,1069.765625 988.240295,1069.138916 
	C985.610840,1068.478760 980.634766,1069.917847 980.011536,1064.386230 
	C980.199158,1063.801758 980.397949,1063.603271 980.596191,1063.404297 
	C980.794556,1063.205322 980.728027,1063.271118 980.198364,1063.801025 
	C978.358948,1064.043823 976.629333,1063.769409 975.421387,1065.325195 
	C974.805237,1066.118774 974.161865,1067.048096 973.019592,1066.178589 
	C971.997192,1065.400146 972.305664,1064.450195 972.954407,1063.569824 
	C973.922241,1062.256104 974.020020,1060.914917 972.294922,1060.416016 
	C969.906433,1059.725342 970.243530,1061.714722 969.982361,1063.140015 
	C968.715698,1070.054810 965.473450,1071.263428 960.247070,1066.703247 
	C958.899475,1065.527344 957.527466,1064.868896 955.383362,1065.001099 
	C953.516541,1063.647339 953.347595,1061.847168 953.481018,1060.039307 
	C953.621826,1058.131348 952.753662,1056.947632 951.014893,1056.176514 
	C954.861450,1057.901123 949.398071,1063.298462 954.750305,1064.763916 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1175.001709,1136.605103 
	C1170.461304,1137.394775 1166.648804,1135.341553 1162.794189,1133.403809 
	C1161.040771,1132.522461 1159.279053,1131.262085 1157.381836,1133.186279 
	C1156.520264,1134.060181 1155.808594,1135.041870 1156.321167,1136.268555 
	C1157.098145,1138.128418 1158.624146,1138.940430 1160.646484,1139.028442 
	C1164.686157,1139.204346 1166.610718,1141.125244 1166.057007,1145.391235 
	C1165.807129,1147.316162 1165.689941,1149.376343 1166.833496,1151.621948 
	C1168.757690,1154.247803 1168.211060,1156.374023 1166.492432,1158.278320 
	C1162.194702,1163.040405 1152.095947,1160.955322 1149.777344,1154.412354 
	C1153.532104,1153.289795 1156.287598,1150.333008 1160.243286,1150.642700 
	C1161.389404,1150.732544 1163.155762,1149.805786 1162.692993,1148.136963 
	C1161.902222,1145.285767 1159.091919,1143.339111 1156.811401,1143.589355 
	C1151.482544,1144.174072 1146.193115,1143.785278 1140.895874,1143.985107 
	C1138.028564,1144.093384 1137.339478,1145.933105 1136.968628,1148.221558 
	C1136.392822,1151.775024 1134.376709,1153.442871 1130.719116,1153.013550 
	C1129.898926,1152.917358 1129.057861,1153.000366 1128.226318,1152.999878 
	C1118.776611,1152.994507 1118.158936,1152.121948 1121.576294,1143.695923 
	C1121.820312,1143.094360 1121.851196,1142.406250 1121.990601,1141.379150 
	C1124.083618,1139.687744 1126.628174,1139.151611 1128.674561,1137.114502 
	C1129.750000,1137.000000 1130.500000,1137.000000 1131.625000,1137.000000 
	C1134.876953,1136.455444 1138.093628,1138.623291 1140.917847,1135.672241 
	C1142.530151,1134.273560 1142.558105,1132.954468 1141.485107,1131.196777 
	C1141.327881,1130.411255 1141.391357,1129.963745 1141.775879,1129.264282 
	C1142.059204,1128.007080 1141.991943,1126.985840 1142.205933,1126.027466 
	C1142.559814,1124.442139 1143.629883,1122.711792 1141.660156,1121.580322 
	C1139.713379,1120.462280 1137.965698,1121.647949 1136.810547,1122.998657 
	C1133.165649,1127.260620 1129.244751,1128.686279 1124.393677,1124.992310 
	C1124.016724,1124.705322 1123.529419,1124.446899 1123.071289,1124.402344 
	C1120.312500,1124.134155 1120.572388,1124.192749 1121.597412,1121.267822 
	C1122.751831,1117.973389 1122.854492,1114.189209 1128.161621,1115.468628 
	C1129.598145,1115.814941 1130.088013,1113.848022 1129.862305,1112.464111 
	C1129.548218,1110.538574 1128.722046,1109.065674 1126.358032,1108.999390 
	C1124.689819,1108.952515 1122.834473,1110.791626 1121.170898,1108.300049 
	C1124.884277,1107.656006 1127.051758,1104.209839 1129.824463,1102.265503 
	C1132.172363,1100.619019 1130.786499,1095.681763 1131.586792,1092.220093 
	C1132.103271,1089.986328 1129.160278,1090.248047 1127.452271,1089.865356 
	C1125.735718,1089.480957 1124.163086,1088.523071 1124.459106,1086.553711 
	C1124.735596,1084.714478 1125.799805,1083.191772 1128.038086,1083.028687 
	C1130.544312,1082.846313 1133.187012,1083.226685 1133.649170,1085.910400 
	C1134.449951,1090.560059 1137.296143,1091.329224 1141.583130,1091.000000 
	C1143.875732,1093.291626 1143.946411,1095.595703 1141.534180,1097.425781 
	C1139.504883,1098.965210 1137.121948,1096.206543 1133.778564,1098.201050 
	C1141.616333,1097.508911 1148.053711,1098.426392 1153.732178,1102.510864 
	C1154.873901,1095.612183 1153.591919,1093.846313 1146.936768,1092.973022 
	C1145.600708,1092.797729 1144.311401,1092.653687 1143.163452,1091.307617 
	C1143.332031,1090.455566 1143.680786,1089.428223 1143.993652,1089.439209 
	C1148.849854,1089.606689 1150.725098,1087.404297 1150.193481,1082.567749 
	C1150.007690,1080.878418 1151.386963,1079.865601 1153.280884,1080.702515 
	C1155.094116,1081.503906 1156.952393,1082.873169 1155.330078,1084.795044 
	C1152.173584,1088.534058 1152.877686,1089.452881 1157.250122,1091.933594 
	C1161.546265,1094.370850 1165.115723,1098.354980 1169.815308,1100.767090 
	C1172.533569,1102.162231 1172.105591,1105.688843 1171.988403,1108.524414 
	C1171.821655,1112.559448 1172.849609,1115.789062 1177.636597,1116.952393 
	C1180.153931,1119.134155 1182.205566,1115.983154 1184.667725,1116.938721 
	C1186.326416,1117.062744 1187.733521,1116.866943 1188.960327,1117.249146 
	C1190.750244,1117.807007 1192.427246,1119.114136 1192.654663,1120.967163 
	C1193.011719,1123.876709 1189.773438,1123.680054 1188.304077,1125.059326 
	C1186.767212,1126.501831 1185.259644,1125.932129 1184.141602,1124.247314 
	C1183.866211,1123.832275 1183.663452,1123.282104 1183.274292,1123.043823 
	C1180.965576,1121.630371 1177.631714,1120.444458 1175.940063,1121.760620 
	C1173.564819,1123.608643 1177.121948,1125.212280 1178.033203,1126.890625 
	C1179.359985,1129.334839 1181.489746,1131.454224 1176.684937,1132.425415 
	C1174.950317,1132.776001 1175.019165,1134.690552 1175.001709,1136.605103 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M901.378662,938.011292 
	C901.000000,937.835388 901.000000,937.668274 901.000000,937.250610 
	C899.045959,934.082336 901.158325,932.784119 903.241455,931.591553 
	C905.227478,930.454651 907.650879,929.914185 908.643860,927.040771 
	C911.637207,922.820190 916.237305,921.317688 920.249146,918.418701 
	C921.048828,918.183655 921.515564,918.130615 922.323730,918.186768 
	C923.457764,918.369324 924.217407,918.917847 925.356445,918.305725 
	C925.832825,918.038574 926.027161,917.929810 926.532593,917.680542 
	C930.973267,915.767578 935.315369,915.541260 939.745667,915.460815 
	C942.442261,915.411926 945.304138,915.356445 947.949524,913.349243 
	C948.823425,912.645752 949.473694,912.274658 950.496765,911.797913 
	C954.712769,910.352356 957.752258,907.671692 960.685181,905.088806 
	C965.365662,900.967102 970.590881,900.505920 976.027832,902.211975 
	C980.436646,903.595398 984.010681,903.270447 987.471191,900.072998 
	C989.844482,897.880127 992.896790,896.865784 996.182312,897.002869 
	C1000.218811,897.171387 1004.069702,895.992615 1007.967834,895.327393 
	C1010.490173,894.896973 1012.678345,893.368164 1014.227844,890.927429 
	C1017.170166,886.292664 1018.920959,885.645081 1024.207642,887.743774 
	C1028.100342,889.289124 1031.248535,889.223755 1034.295166,885.623169 
	C1035.094360,885.195374 1035.586182,885.039978 1036.480591,884.906738 
	C1042.970581,882.296204 1049.827515,882.754028 1055.929321,880.039429 
	C1060.778687,877.881958 1065.747559,876.410889 1071.226807,876.020813 
	C1077.316284,875.587280 1082.618164,872.697937 1085.587891,866.381104 
	C1086.796021,864.111938 1085.557129,861.145447 1088.706299,859.869690 
	C1093.454956,859.706909 1097.634033,860.636047 1101.573975,862.475830 
	C1104.726196,863.947876 1107.817383,865.463928 1111.381226,865.720642 
	C1112.884644,865.828918 1114.415649,866.498108 1115.348755,867.613953 
	C1119.310181,872.351379 1125.008667,874.914368 1129.390991,879.081055 
	C1131.276489,880.873840 1133.530640,881.713318 1135.851318,882.579834 
	C1138.140381,883.434448 1140.712280,883.834839 1142.058350,886.606079 
	C1136.953125,891.684570 1128.479858,887.767029 1123.407471,883.223145 
	C1118.244263,878.597778 1113.767578,872.654663 1106.048828,871.803345 
	C1105.736450,871.768921 1105.413940,871.582153 1105.149780,871.391785 
	C1094.944946,864.037048 1093.775146,866.609375 1085.444336,874.034058 
	C1082.969849,876.239441 1080.485474,878.453125 1077.838745,880.440918 
	C1074.984375,882.584656 1072.337769,884.012756 1068.024902,883.627197 
	C1063.438110,883.217285 1058.451904,885.575439 1053.883423,887.351135 
	C1051.465454,888.290894 1049.499634,889.134338 1046.847656,888.258728 
	C1044.885620,887.610962 1042.690308,887.638794 1043.447754,891.084900 
	C1043.962158,893.424805 1042.713623,894.376343 1040.636475,893.019653 
	C1038.730347,891.774719 1036.261841,891.873413 1035.855469,893.481873 
	C1034.152832,900.221252 1029.232300,896.979309 1025.930664,896.559387 
	C1022.780151,896.158569 1019.974182,896.197266 1017.252747,897.473206 
	C1013.554871,899.207153 1010.391052,901.856201 1006.175049,902.814209 
	C1004.379150,903.222290 1001.599792,904.813477 1002.775635,908.375610 
	C1003.468567,910.474731 1002.280884,911.291199 1000.253418,909.184265 
	C997.308655,906.677979 994.004272,906.754089 991.038757,907.599792 
	C986.758301,908.820618 982.179016,909.133057 978.230103,911.491028 
	C976.739319,912.381104 975.072876,912.328735 973.381042,912.058716 
	C964.418152,910.627991 961.728088,912.052612 957.595337,920.329834 
	C957.179016,921.163513 956.489929,921.514526 955.286499,921.219604 
	C954.967407,920.835144 954.990234,920.594421 954.890808,920.522278 
	C953.915100,919.815002 943.524780,918.916870 942.727051,919.694519 
	C938.369629,923.942261 932.604797,923.007751 927.379211,923.964050 
	C921.171448,925.100098 916.098755,928.770996 910.607605,931.435242 
	C909.249878,932.094055 907.111572,934.325500 909.039673,937.336853 
	C910.431641,939.510681 908.307190,940.072632 906.428650,939.600891 
	C904.839478,939.201843 903.312012,938.556519 901.378662,938.011292 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M1115.252930,1002.806641 
	C1114.147949,1003.170349 1113.295776,1003.340759 1112.221924,1003.755615 
	C1107.697876,1000.122925 1105.579590,1004.061707 1104.024414,1006.790283 
	C1102.244629,1009.912537 1100.801880,1011.584412 1096.778442,1010.640564 
	C1094.141113,1010.021851 1092.022461,1012.984802 1089.471436,1013.971924 
	C1088.698120,1014.271118 1089.526367,1015.738037 1090.053345,1016.601135 
	C1090.955322,1018.078430 1091.731445,1019.591003 1089.345947,1020.918579 
	C1086.466309,1020.000916 1084.189087,1018.393860 1081.189209,1017.624634 
	C1079.438843,1015.479431 1080.513184,1011.879822 1076.868530,1011.301880 
	C1075.673706,1012.284668 1076.392090,1014.218079 1074.755127,1015.296997 
	C1072.476807,1015.708313 1070.769409,1013.018738 1068.318359,1014.954468 
	C1064.013916,1014.941589 1060.497192,1014.097900 1058.397827,1010.144714 
	C1057.601074,1008.644531 1056.036621,1007.456116 1054.246338,1008.311157 
	C1052.064331,1009.353210 1053.707520,1010.724487 1054.301880,1011.980042 
	C1055.092529,1013.649902 1054.435913,1015.206848 1052.881226,1015.579285 
	C1050.909424,1016.051697 1050.950195,1014.160522 1051.039307,1012.910034 
	C1051.396729,1007.900940 1047.322510,1008.459106 1044.584839,1008.137451 
	C1040.518188,1007.659790 1040.597656,1006.592407 1043.699585,1004.160706 
	C1045.495850,1002.450256 1047.453613,1002.287720 1049.410522,1002.334778 
	C1052.253174,1002.403137 1054.860840,1001.760803 1057.617432,1000.095459 
	C1060.483276,1000.195923 1062.236206,999.631897 1062.034058,996.565063 
	C1061.953247,995.338745 1061.632568,993.673523 1063.409912,993.308899 
	C1064.906372,993.002014 1065.761841,994.244324 1066.559570,995.303406 
	C1066.954712,995.827942 1067.217651,996.455261 1067.632080,996.961060 
	C1068.356201,997.845032 1069.096558,998.925476 1070.441650,998.500061 
	C1070.967285,998.333740 1071.536011,997.674438 1071.702393,997.124084 
	C1072.132080,995.702698 1071.045532,995.084656 1070.004395,994.512573 
	C1068.856079,993.881592 1067.565308,993.385254 1067.201904,991.922241 
	C1066.811157,990.348999 1067.267090,988.912659 1068.339722,987.811096 
	C1069.598633,986.517944 1070.708984,987.292236 1071.828735,988.700378 
	C1073.153687,989.968567 1073.570190,991.466675 1074.777344,992.778381 
	C1075.743652,994.520020 1076.787354,995.213562 1078.716919,994.123413 
	C1079.911865,992.889404 1081.175049,993.056946 1082.697510,993.033569 
	C1085.973145,993.509338 1087.212158,991.822693 1087.745239,989.248291 
	C1088.113281,987.471130 1088.520996,985.700623 1088.829468,983.913147 
	C1089.443848,980.353760 1091.346069,980.343262 1093.664673,982.275757 
	C1095.332520,983.665955 1096.755981,985.336975 1099.613525,984.998840 
	C1105.450439,986.024353 1111.370239,983.883301 1116.722778,987.719177 
	C1119.701416,988.789490 1122.822998,988.903931 1123.951294,992.649048 
	C1123.688477,993.788391 1123.376953,994.576782 1123.032715,995.682556 
	C1121.734863,996.883606 1121.320068,998.382507 1120.218262,999.771118 
	C1120.000000,1000.000000 1119.500000,1000.000000 1119.250000,1000.000000 
	C1117.764282,1000.776245 1116.428101,1001.418152 1115.252930,1002.806641 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M971.707336,967.289673 
	C973.840576,964.840698 975.725708,962.306335 978.957092,963.287964 
	C981.961914,964.200745 983.136292,966.718933 983.093811,969.915894 
	C983.063477,972.197449 984.574219,973.212219 986.759155,972.816040 
	C991.443115,971.966919 996.098389,971.177063 1000.911438,971.703430 
	C1001.877625,971.809082 1003.291016,971.045898 1003.944763,970.236328 
	C1006.880188,966.601257 1008.831787,968.604614 1011.075195,971.059326 
	C1013.594238,973.815491 1014.596619,977.898743 1018.552917,979.428040 
	C1019.719849,979.879028 1020.160217,981.116272 1019.617859,982.376831 
	C1019.085449,983.614319 1018.018005,984.069458 1016.749939,983.742432 
	C1012.392395,982.618469 1012.483826,985.769653 1013.311829,988.034424 
	C1015.238098,993.303467 1018.747498,997.591797 1023.638550,1000.990723 
	C1024.809448,1001.577148 1025.002808,1002.413269 1024.995361,1003.668335 
	C1025.000000,1004.750000 1025.000000,1005.500000 1025.000000,1006.625000 
	C1024.491211,1007.430115 1024.293945,1007.948486 1024.773926,1008.797363 
	C1026.727417,1010.101135 1028.354126,1009.899963 1030.156494,1008.974548 
	C1031.215454,1008.430786 1032.706177,1006.009399 1033.673218,1008.404358 
	C1034.457397,1010.346863 1034.835083,1013.050110 1032.268066,1014.640930 
	C1032.131470,1014.725525 1031.948486,1014.731873 1031.803955,1014.807190 
	C1030.359253,1015.559937 1027.412964,1013.898438 1027.467651,1016.679688 
	C1027.510742,1018.865906 1030.357300,1019.083679 1032.049805,1019.957764 
	C1033.804565,1020.863953 1035.768066,1021.363708 1037.634766,1022.056152 
	C1038.572144,1022.403687 1039.729370,1022.792664 1039.364380,1024.036743 
	C1038.995850,1025.293091 1037.655762,1025.136108 1036.853394,1024.720215 
	C1033.749634,1023.111206 1030.728516,1022.887451 1027.270752,1023.777832 
	C1024.723877,1024.433716 1019.379089,1019.019775 1019.113464,1015.613892 
	C1018.912109,1013.031616 1017.868713,1011.864502 1015.567322,1010.937805 
	C1012.120850,1009.550049 1009.447876,1003.315247 1010.453918,999.835083 
	C1011.396790,996.573914 1008.715576,993.650818 1005.184265,994.173157 
	C1003.342285,994.445557 1001.519287,995.806030 999.225769,993.904114 
	C1001.465332,992.732727 1003.341064,990.956543 1005.612244,991.529297 
	C1008.444336,992.243530 1009.765747,991.109070 1010.513855,988.793823 
	C1011.403076,986.042236 1009.724548,984.026489 1007.555664,983.298706 
	C1005.088257,982.470520 1004.050171,981.351807 1003.875366,978.804565 
	C1003.756653,977.075623 1003.290405,974.701660 1000.714050,975.416138 
	C998.584351,976.006653 997.591492,977.929749 998.273376,980.192688 
	C998.864197,982.153503 999.008972,983.986389 998.541321,986.038330 
	C997.010132,992.756714 995.574158,993.915405 990.144714,989.570618 
	C986.290771,986.486633 982.774231,987.000183 978.415283,987.000000 
	C976.190674,985.920471 975.666443,984.189758 975.599854,981.862671 
	C975.497437,978.662903 978.459656,977.064331 978.597473,974.288330 
	C976.106201,972.691467 973.223999,974.811951 970.590576,972.976562 
	C969.503113,970.733887 969.786621,969.014832 971.707336,967.289673 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M954.787537,921.261780 
	C956.402649,920.799500 957.304749,919.946411 956.667053,918.638000 
	C955.444763,916.130310 956.976746,915.004089 958.646362,913.817810 
	C963.079712,910.667725 967.544189,907.743408 973.326172,910.562683 
	C974.541626,911.155273 976.135437,911.437073 977.126465,910.534424 
	C981.435791,906.609314 987.274780,907.475464 992.252747,905.635376 
	C994.670227,904.741760 998.207397,904.901611 999.944092,908.633240 
	C997.486755,909.119019 994.266846,908.822510 998.247437,912.384094 
	C999.305115,913.330383 998.767700,914.540588 998.109314,915.555908 
	C997.007507,917.254883 995.929749,915.190430 995.529846,915.337280 
	C992.239868,916.545471 989.059631,917.515869 985.729065,915.557007 
	C985.704956,915.542786 985.454651,915.775879 985.433899,915.912231 
	C983.813171,926.572144 976.892822,918.505615 973.782410,918.088501 
	C969.481262,917.511658 964.738342,917.970032 962.731262,919.817261 
	C958.932983,923.313232 952.274536,923.048950 950.645142,929.167175 
	C950.169617,930.952820 946.030334,928.903381 946.816528,931.567810 
	C947.582520,934.163452 950.261841,931.567322 952.099976,931.964722 
	C954.174744,932.413269 956.396057,932.318481 957.915771,934.642822 
	C958.206360,938.175964 955.916321,939.705139 953.691040,941.030945 
	C951.136414,942.552917 948.686401,944.263489 945.656311,945.646912 
	C941.680481,944.631165 938.045105,943.084656 934.167786,946.079834 
	C930.136475,948.024658 926.435364,946.983398 922.366577,946.101318 
	C919.299988,946.000000 916.599976,946.000000 913.449951,946.000000 
	C912.833313,946.128052 912.666687,946.256104 912.250000,946.192078 
	C912.416016,942.904846 914.983521,940.701294 913.754272,936.616028 
	C912.682983,933.055603 924.522461,927.034546 928.280273,928.933777 
	C930.897827,930.256775 933.859314,930.344727 936.405945,932.321594 
	C938.857361,934.224609 941.119995,932.281860 941.876953,929.414429 
	C942.250183,928.000488 941.919006,926.376282 943.686096,925.186279 
	C944.685730,924.421021 945.457275,924.263611 946.657471,924.764343 
	C949.840698,924.531128 952.356934,923.354858 954.787537,921.261780 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M1073.250000,1074.000000 
	C1066.732910,1071.787720 1060.428833,1072.854126 1054.137817,1073.830811 
	C1052.348022,1074.108765 1050.591797,1074.601685 1048.410156,1074.998047 
	C1046.782227,1073.930176 1045.978760,1072.479736 1044.724243,1071.442261 
	C1043.073730,1070.077393 1042.133911,1070.830688 1041.247803,1072.755005 
	C1035.694580,1070.809204 1028.463135,1073.028076 1025.474487,1077.733154 
	C1024.389404,1079.441284 1022.971252,1080.326904 1020.852356,1079.499878 
	C1019.760437,1079.073608 1018.764709,1079.497925 1018.515686,1080.627441 
	C1018.289734,1081.652100 1019.466248,1081.712158 1020.059448,1082.203857 
	C1021.943481,1083.765625 1025.820312,1084.982056 1024.033569,1087.922119 
	C1021.754700,1091.672119 1018.825745,1088.674072 1016.553101,1086.943481 
	C1013.835144,1084.873657 1011.241333,1080.960938 1009.568298,1087.833740 
	C1009.158813,1089.515991 1001.299622,1089.863525 1000.073608,1088.494873 
	C999.234375,1087.557983 998.782776,1086.498779 999.500366,1085.329956 
	C1000.315308,1084.002441 1001.286926,1083.040771 1003.147705,1083.540894 
	C1006.477417,1084.435791 1008.115601,1083.770752 1007.544189,1079.618408 
	C1007.002380,1075.680908 1009.423645,1073.123535 1013.642883,1072.045898 
	C1018.563599,1070.753662 1022.717102,1068.889771 1025.533203,1064.405029 
	C1030.802124,1064.394287 1030.035156,1059.952393 1031.696167,1056.951416 
	C1034.031494,1055.243652 1036.153320,1055.090942 1038.661011,1056.729492 
	C1041.133179,1058.260742 1040.302612,1062.631714 1044.621460,1061.997925 
	C1047.513062,1061.120728 1049.984253,1060.906982 1052.672119,1062.730957 
	C1055.105347,1063.929077 1054.829956,1065.915649 1055.166748,1067.685059 
	C1055.466431,1069.258423 1056.472534,1070.508301 1058.107910,1070.689697 
	C1060.144653,1070.916016 1059.667480,1069.092773 1060.092285,1067.927490 
	C1061.000244,1065.436157 1063.462402,1062.875854 1065.360229,1063.417969 
	C1070.715820,1064.947998 1072.351562,1061.608887 1074.654663,1058.081177 
	C1075.625000,1058.072021 1076.153076,1057.877930 1076.793091,1057.211426 
	C1077.928955,1057.095093 1079.252441,1056.791260 1079.464600,1057.976074 
	C1079.760376,1059.628540 1078.551880,1060.618652 1077.194458,1061.354004 
	C1075.854126,1062.080078 1073.786255,1062.154541 1074.142822,1064.478149 
	C1074.506226,1066.845703 1076.333496,1066.994995 1078.604980,1066.998169 
	C1079.421021,1070.473267 1076.350098,1071.593994 1074.282959,1073.790771 
	C1074.000000,1074.000000 1073.500000,1074.000000 1073.250000,1074.000000 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M944.750122,945.246338 
	C948.702087,941.185242 954.539307,940.209473 957.795654,935.265137 
	C960.081482,935.240234 962.891296,934.080750 962.998718,937.900696 
	C963.093750,941.276855 961.231079,942.228638 958.330566,941.986816 
	C952.668762,941.514648 952.990540,945.430969 953.000000,949.572510 
	C953.552612,951.368774 952.800354,952.509399 951.779175,952.882141 
	C947.249084,954.535828 945.237427,957.970337 944.244385,962.717773 
	C943.316711,963.578003 942.709900,964.536865 943.297302,965.137451 
	C946.989868,968.912842 944.931152,972.781982 943.634460,976.626709 
	C942.863159,978.913818 942.641724,981.163513 943.733154,983.369507 
	C944.587585,985.096619 945.262939,986.820618 943.523376,988.312378 
	C942.047485,989.578064 940.753418,988.208252 939.427246,987.707092 
	C937.112244,986.832153 936.207825,984.672607 937.193054,982.911072 
	C940.187439,977.557129 936.203064,976.378296 932.929443,976.662659 
	C926.050598,977.260193 927.028931,972.901489 927.000000,968.427612 
	C925.947388,963.683228 927.933167,960.288940 930.318909,957.005676 
	C932.568359,953.909973 932.218872,952.895569 928.385376,952.325256 
	C926.037170,951.976013 923.773010,951.736694 922.121521,949.322510 
	C922.000000,948.250000 922.000000,947.500000 922.000000,946.375000 
	C923.693481,944.436218 925.719971,944.766785 927.531433,945.411987 
	C929.565491,946.136536 931.363159,945.798523 933.614746,945.055115 
	C935.525940,945.144653 936.980164,945.548889 938.030640,946.714661 
	C940.895264,949.893494 942.840576,948.524109 944.750122,945.246338 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M1025.267944,1003.791382 
	C1024.185303,1003.429504 1024.017090,1002.583984 1024.004395,1001.336426 
	C1025.915527,997.625305 1025.046509,994.215210 1024.060303,990.402222 
	C1024.092041,989.559082 1024.134277,989.113770 1024.058838,988.331665 
	C1024.022217,984.046387 1024.975220,980.916870 1029.534302,979.825989 
	C1033.272339,978.931580 1031.988159,975.515686 1031.911865,973.114563 
	C1031.797363,969.511169 1032.467651,966.837891 1036.567505,966.328552 
	C1037.205933,966.249268 1038.112061,966.082458 1038.478760,966.414734 
	C1041.226685,968.904297 1042.674805,972.977966 1047.276001,973.227478 
	C1047.898438,973.261292 1047.720093,974.809692 1047.178467,975.426453 
	C1045.306152,977.558289 1044.312866,979.121765 1047.699951,981.141968 
	C1049.875854,982.439880 1047.983276,983.522827 1046.351807,983.825439 
	C1043.670288,984.322876 1039.620605,989.925659 1040.249634,992.519531 
	C1040.729004,994.496033 1042.477051,994.800049 1044.158203,994.933472 
	C1046.259644,995.100159 1047.516113,994.266235 1047.994995,992.002136 
	C1048.811035,988.143616 1052.195923,986.729553 1055.361206,988.763489 
	C1056.931763,989.772705 1057.689087,990.968750 1055.335815,991.880066 
	C1053.699585,992.513794 1051.213623,992.342163 1051.328125,994.735413 
	C1051.454590,997.377991 1053.875488,997.823242 1056.067993,998.249207 
	C1056.643799,998.361084 1057.118896,998.991516 1057.820068,999.691895 
	C1057.527222,1002.991760 1055.588867,1004.040283 1052.774048,1004.003479 
	C1050.155762,1003.969177 1047.536621,1003.998596 1044.458984,1004.000000 
	C1044.212524,1000.711304 1042.098511,999.082153 1039.412354,998.400024 
	C1036.738892,997.721130 1033.842896,997.938538 1031.755005,995.583435 
	C1031.214355,994.973633 1030.171021,994.932190 1029.556519,995.714966 
	C1028.949951,996.487549 1029.264404,997.228455 1029.960571,997.853394 
	C1031.010010,998.795288 1032.618774,999.334900 1032.428101,1001.622375 
	C1029.722046,1000.810181 1027.247070,1001.093872 1025.267944,1003.791382 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M1237.250610,1170.235596 
	C1236.555542,1170.000000 1236.111084,1170.000000 1235.333252,1170.000000 
	C1229.935059,1168.864014 1224.741821,1169.030396 1219.309326,1168.242432 
	C1215.577271,1164.184082 1216.128784,1159.458740 1216.000000,1154.444580 
	C1216.471680,1153.686890 1216.943359,1153.373657 1217.707520,1153.030273 
	C1220.601074,1150.869507 1223.352539,1150.714966 1226.621094,1152.686279 
	C1229.135986,1152.636719 1231.156860,1154.773926 1233.672119,1153.146118 
	C1238.052002,1149.982666 1242.586426,1151.228638 1247.406982,1151.647217 
	C1251.160400,1153.211426 1249.521729,1157.274170 1251.875732,1159.655273 
	C1251.923340,1161.723267 1250.216431,1162.600952 1249.965820,1164.382446 
	C1249.043701,1170.939697 1249.086304,1171.104370 1255.832642,1173.485229 
	C1258.282471,1174.349731 1260.379761,1176.237183 1263.603760,1175.998535 
	C1262.308472,1179.268433 1260.175537,1179.087036 1257.085938,1177.579224 
	C1253.334229,1175.748779 1249.215332,1172.940186 1245.076660,1177.644531 
	C1243.457275,1177.907227 1242.098999,1179.542969 1240.226562,1178.242554 
	C1238.903076,1175.567139 1241.997803,1174.480713 1241.842651,1172.313599 
	C1240.690308,1170.999634 1238.677002,1171.701538 1237.250610,1170.235596 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1227.000000,1152.587158 
	C1224.299927,1153.000000 1221.599976,1153.000000 1218.449951,1153.000000 
	C1220.192505,1149.195557 1219.754517,1146.490601 1214.868530,1145.827148 
	C1206.749756,1144.724731 1203.093994,1137.607300 1197.613647,1132.992188 
	C1194.263794,1130.171143 1198.728027,1127.439819 1198.496826,1124.354858 
	C1198.350098,1122.399536 1201.315063,1123.930176 1202.671631,1124.208008 
	C1207.133911,1125.122070 1211.162964,1125.733643 1215.412354,1122.700684 
	C1217.579590,1121.153809 1221.126709,1121.174683 1224.052246,1121.165894 
	C1226.605103,1121.158325 1229.056763,1122.297119 1228.943359,1125.598999 
	C1228.837402,1128.681885 1226.954224,1129.964966 1224.031616,1129.990723 
	C1223.034912,1129.999512 1222.035645,1130.044800 1221.041992,1129.991699 
	C1218.905151,1129.877319 1216.713745,1130.126831 1216.401978,1132.619873 
	C1216.068848,1135.282104 1216.687866,1139.131836 1219.100708,1139.486450 
	C1227.257202,1140.684326 1225.177734,1147.499268 1227.000000,1152.587158 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M944.354248,963.049438 
	C942.117737,955.542419 947.284485,953.008118 952.825562,950.306763 
	C954.534668,950.254089 955.014465,950.954834 954.097107,952.379578 
	C952.001770,955.634033 954.245850,956.816284 956.543335,955.729370 
	C961.554993,953.358521 964.271912,958.873169 968.552979,958.525146 
	C969.235107,958.469727 969.808960,959.572632 969.463806,960.506531 
	C969.146240,961.365967 968.408997,961.877686 967.529846,961.776611 
	C966.060608,961.607544 964.548767,961.431335 963.169434,960.937988 
	C960.558289,960.004089 958.229980,959.207275 955.364502,960.775635 
	C953.494263,961.799255 951.165222,960.891418 949.521057,959.114624 
	C948.969421,958.518494 948.081055,957.974365 947.452881,958.938660 
	C947.180847,959.356079 947.259460,960.337097 947.580994,960.745422 
	C948.695984,962.160950 950.233093,964.735901 951.172363,964.558228 
	C957.687439,963.325745 963.057983,969.951294 969.673279,967.039001 
	C970.092163,966.854675 970.658997,967.006165 971.578491,967.001953 
	C971.667480,968.744080 971.334900,970.485657 971.001221,972.613647 
	C970.512146,975.698059 970.512146,975.698059 966.221680,975.559082 
	C968.746643,976.005066 972.097839,975.215210 972.487183,979.658875 
	C971.316895,981.318848 969.795837,981.283752 968.323792,981.308350 
	C966.571899,981.337585 965.399658,982.008118 965.549133,983.916321 
	C965.748535,986.462646 967.695374,987.538208 969.770935,988.158325 
	C971.877136,988.787598 972.190063,987.108826 972.395691,985.571350 
	C972.611877,983.954285 972.535889,982.265320 973.924194,980.663696 
	C974.957153,980.175537 975.682617,980.198853 976.697754,980.767334 
	C976.835571,982.827393 977.274292,984.551880 977.947449,986.622681 
	C975.337646,993.867188 974.772400,994.078979 967.183167,991.274048 
	C966.260864,990.933167 965.240601,990.867615 964.290588,990.589539 
	C956.717834,988.373596 956.698792,988.365479 957.365540,980.472900 
	C957.445557,979.526123 958.356506,978.181396 957.391602,977.729614 
	C951.590515,975.013184 948.990601,969.890442 946.737183,964.388245 
	C946.489380,963.783020 945.404968,963.520386 944.354248,963.049438 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M1096.300049,1118.175171 
	C1093.933838,1118.682007 1092.291992,1120.682251 1089.393311,1119.999756 
	C1085.191040,1119.940796 1084.835693,1122.729004 1084.231567,1125.751465 
	C1079.942871,1129.268433 1077.744019,1128.798340 1075.877441,1124.475708 
	C1074.719360,1121.794189 1072.890137,1119.520996 1072.516357,1116.367310 
	C1071.954102,1111.623047 1072.309937,1110.943115 1076.855713,1111.792725 
	C1078.645386,1112.127319 1080.446289,1112.445435 1082.198120,1112.930420 
	C1088.184570,1114.587402 1089.726807,1113.040771 1088.156738,1106.354248 
	C1087.470825,1105.430542 1087.210205,1104.760742 1087.331177,1103.633545 
	C1089.225952,1101.020020 1091.447144,1101.654663 1093.614014,1102.502930 
	C1094.800659,1102.967651 1095.850098,1103.983154 1097.283813,1102.604492 
	C1097.491333,1101.717407 1097.364746,1101.144287 1097.069580,1100.292725 
	C1097.914062,1097.335083 1099.651855,1094.871704 1099.000000,1091.411865 
	C1099.760132,1089.740479 1101.015137,1089.352417 1102.673462,1089.049805 
	C1103.455322,1089.183716 1104.323608,1089.429321 1104.306763,1089.542236 
	C1103.529663,1094.728516 1107.902832,1098.959717 1107.000000,1104.572998 
	C1103.299805,1107.110840 1102.039673,1109.751343 1104.732178,1113.652100 
	C1106.251953,1115.853516 1106.749390,1118.062988 1102.381592,1117.997559 
	C1100.256714,1118.991333 1098.436401,1118.795166 1096.300049,1118.175171 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1137.703735,1046.377930 
	C1139.196533,1042.220337 1137.200684,1041.652100 1134.231323,1042.441895 
	C1132.118896,1043.003906 1130.837036,1041.928833 1130.389404,1040.328735 
	C1129.590210,1037.471924 1131.897583,1038.800537 1133.100586,1038.623535 
	C1133.994873,1038.492065 1134.585327,1037.921387 1134.581909,1036.964966 
	C1134.578491,1036.000122 1133.881836,1035.447876 1133.092285,1035.207153 
	C1129.105347,1033.991943 1129.628174,1031.951904 1131.926880,1029.465210 
	C1132.353882,1029.003174 1132.687744,1028.359741 1132.827515,1027.746094 
	C1133.208740,1026.072144 1132.777222,1023.764099 1135.653687,1024.166016 
	C1137.922607,1024.483032 1139.196899,1025.780273 1139.000732,1028.607422 
	C1139.120117,1032.319458 1137.770386,1035.444824 1143.847290,1036.004272 
	C1149.843018,1036.556030 1151.188354,1040.784790 1149.385864,1047.217529 
	C1148.575073,1050.110352 1152.017334,1052.436768 1150.043701,1055.647461 
	C1148.313721,1058.200562 1146.195190,1058.614624 1143.355957,1057.260742 
	C1141.919800,1055.097656 1139.111694,1055.712280 1138.067261,1053.329834 
	C1136.643433,1051.028564 1135.975220,1048.991821 1137.703735,1046.377930 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1096.636475,1099.912231 
	C1097.993774,1100.242310 1099.658569,1099.463745 1099.208008,1101.793823 
	C1096.959839,1103.928589 1094.959473,1103.225464 1092.859863,1102.030640 
	C1091.345215,1101.168823 1089.656250,1100.094604 1088.184814,1102.722412 
	C1088.000000,1103.750000 1088.000000,1104.500000 1088.000000,1105.625000 
	C1083.588501,1108.228149 1080.288940,1104.836060 1076.685913,1103.522461 
	C1074.245850,1102.632446 1075.400024,1100.964111 1077.114014,1099.992432 
	C1078.876953,1098.993164 1081.480591,1098.815186 1080.997681,1095.383545 
	C1081.164429,1095.022705 1081.338623,1095.017700 1081.491821,1095.071899 
	C1089.113892,1097.769531 1089.099731,1097.771362 1088.342651,1089.499023 
	C1088.271240,1088.718384 1088.214600,1087.519409 1088.661743,1087.172119 
	C1093.872559,1083.124756 1091.425171,1076.962036 1092.864868,1071.899536 
	C1093.575562,1069.400513 1094.337280,1067.730347 1096.857544,1066.859009 
	C1099.357056,1065.994995 1101.003540,1065.828979 1101.063965,1069.269165 
	C1101.101318,1071.393066 1101.795654,1073.497437 1101.967285,1075.628906 
	C1102.220581,1078.777222 1102.161133,1081.221436 1098.143677,1082.874268 
	C1092.857544,1085.049316 1092.286377,1093.771484 1096.636475,1099.912231 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M977.297852,1004.844849 
	C972.947266,1004.964233 970.178467,1006.028259 971.754944,1010.971680 
	C972.036255,1011.853760 971.527771,1012.505188 970.338562,1012.926697 
	C968.526672,1013.601379 967.977173,1012.550903 967.200623,1011.273926 
	C966.401306,1006.787415 966.302002,1002.859741 970.033691,999.622131 
	C971.394897,998.441162 971.942627,996.531921 974.281128,997.524353 
	C975.140869,997.889343 976.095947,997.534302 976.406555,996.741089 
	C979.010864,990.090088 984.618835,993.271545 988.822510,993.390747 
	C992.579895,993.497253 993.983215,998.096375 991.940796,1001.470703 
	C991.516846,1002.171143 990.742676,1002.718079 990.495728,1003.457886 
	C988.289307,1010.069031 987.802795,1010.291260 981.366333,1007.054260 
	C979.317688,1007.036621 981.212158,1001.803040 977.297852,1004.844849 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1053.016357,1062.623047 
	C1050.532227,1062.803345 1048.011353,1063.453125 1045.294434,1062.267090 
	C1045.163208,1059.924683 1044.734131,1058.108398 1043.161743,1056.557861 
	C1041.846313,1055.260864 1041.489746,1053.528687 1042.794800,1052.081543 
	C1044.305542,1050.406250 1046.051147,1050.888916 1047.650513,1052.192261 
	C1048.651978,1053.008423 1049.623047,1054.399902 1051.061401,1053.549438 
	C1052.639771,1052.616333 1050.983398,1051.079956 1051.284302,1049.978638 
	C1052.536255,1045.395996 1051.997192,1041.255981 1049.338867,1037.241699 
	C1048.506714,1035.984985 1048.948242,1034.223145 1050.594971,1033.428833 
	C1052.099976,1032.702881 1053.388306,1033.126831 1054.600220,1034.364502 
	C1059.362915,1039.228027 1060.589233,1045.797607 1057.769287,1051.867065 
	C1057.494995,1052.457397 1056.943970,1053.338745 1057.159912,1053.674316 
	C1060.157593,1058.333862 1057.178467,1059.741821 1053.431763,1060.866821 
	C1053.183228,1060.941528 1053.160522,1061.768921 1053.016357,1062.623047 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M921.699951,949.171631 
	C924.574829,949.650513 926.696411,951.470886 929.884155,950.562927 
	C931.853577,950.002014 933.581543,952.571289 934.411621,954.452454 
	C935.282837,956.426880 932.890442,956.419495 931.936401,957.341858 
	C929.112610,960.071960 928.241699,963.764587 927.256775,967.735474 
	C926.151733,967.481323 925.169678,967.099487 924.476624,966.422058 
	C919.634155,961.688599 919.653259,961.669067 916.059509,966.312866 
	C912.963196,962.975769 912.098206,959.003540 912.262329,954.538879 
	C912.354858,952.019897 913.970825,950.565369 915.486816,951.644043 
	C919.166870,954.262390 919.885071,951.193237 921.699951,949.171631 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M1249.307617,1153.217529 
	C1244.310669,1153.000000 1239.621460,1153.000000 1234.466064,1153.000000 
	C1233.944702,1148.940308 1234.968994,1144.513428 1230.019165,1142.206421 
	C1229.190918,1141.820557 1229.065796,1140.541016 1229.782593,1140.071167 
	C1231.805786,1138.744873 1233.216553,1135.936401 1235.926147,1136.303955 
	C1239.347168,1136.768066 1242.628174,1138.094604 1246.167603,1138.150146 
	C1247.598755,1138.172485 1247.740479,1139.482544 1248.012451,1140.684692 
	C1248.888062,1144.553589 1249.919922,1148.386963 1250.945801,1152.617065 
	C1250.602783,1153.349609 1250.141235,1153.494629 1249.307617,1153.217529 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M976.013306,1036.627686 
	C975.025024,1037.493408 974.578369,1039.333374 973.269226,1038.671875 
	C971.255310,1037.654175 973.598206,1036.942627 973.733643,1036.033936 
	C973.897461,1034.934326 973.644470,1034.066895 972.629211,1033.557739 
	C971.726013,1033.104980 970.977905,1033.563354 970.512817,1034.270996 
	C969.587158,1035.679565 968.718018,1037.080078 966.368042,1036.991211 
	C966.612061,1033.619263 966.000427,1029.620239 970.730774,1028.417358 
	C971.690430,1028.173340 972.293518,1026.731812 971.855042,1025.884399 
	C968.995422,1020.358215 975.300415,1019.190796 976.666687,1015.689087 
	C977.029968,1014.758057 986.070496,1017.289307 986.517944,1018.547363 
	C987.672668,1021.794312 986.675720,1024.156860 983.160339,1024.949463 
	C981.225281,1025.385864 980.937012,1026.505249 981.968384,1027.765869 
	C985.174377,1031.684692 982.097351,1032.224609 979.320129,1033.064819 
	C977.703857,1033.553833 976.077393,1034.113403 976.013306,1036.627686 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1264.870239,1093.291992 
	C1268.920044,1090.922729 1263.966309,1088.821899 1265.008301,1086.368530 
	C1268.930664,1085.142334 1269.436157,1082.740356 1268.308105,1079.268799 
	C1267.351196,1076.323730 1268.113647,1073.712524 1270.782349,1071.835083 
	C1272.926270,1070.326782 1274.511963,1071.772217 1276.177612,1072.803345 
	C1276.453735,1072.974243 1276.700439,1073.221924 1276.900757,1073.480591 
	C1277.613159,1074.401001 1277.906494,1086.060547 1277.288818,1087.122925 
	C1276.291260,1088.838989 1275.274902,1090.546753 1274.355347,1092.304443 
	C1273.527466,1093.886597 1272.608765,1095.358154 1270.561157,1094.306030 
	C1267.825684,1092.900391 1267.277100,1097.789185 1264.295532,1096.124512 
	C1264.246826,1095.194702 1264.493652,1094.389282 1264.870239,1093.291992 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M1022.152893,1056.687256 
	C1018.251465,1057.021362 1014.475342,1056.725342 1010.416626,1057.985352 
	C1009.824158,1053.369629 1006.967896,1055.427734 1004.374512,1055.947754 
	C1003.162720,1055.003418 1002.902832,1053.902588 1003.698730,1052.332764 
	C1007.971375,1051.137573 1011.110535,1048.164795 1015.255066,1047.638428 
	C1016.467834,1047.484497 1016.727661,1046.074951 1016.990845,1044.870117 
	C1017.915222,1040.638916 1019.949280,1039.755127 1024.827026,1041.697998 
	C1028.266479,1044.889771 1028.041992,1048.431763 1026.684814,1052.467529 
	C1025.105835,1054.176636 1023.506348,1053.280884 1021.970032,1053.337646 
	C1019.247742,1053.437866 1021.531799,1054.481079 1021.948120,1055.414307 
	C1022.202332,1055.921997 1022.254089,1056.148193 1022.152893,1056.687256 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1142.750000,1091.000000 
	C1147.068115,1091.589233 1151.393799,1090.975708 1154.945190,1093.938354 
	C1155.717773,1094.582764 1156.968506,1094.739136 1155.967651,1095.921509 
	C1154.525024,1097.625977 1155.559937,1099.021362 1156.234985,1100.483398 
	C1156.913086,1101.951782 1157.291016,1103.412354 1155.672241,1104.426758 
	C1154.211914,1105.341797 1153.100586,1104.696533 1151.745850,1103.626587 
	C1147.089355,1099.949219 1141.928345,1097.904175 1135.959961,1100.388306 
	C1134.001099,1101.203613 1132.658813,1100.745605 1132.200439,1098.685059 
	C1131.776855,1096.780640 1132.600830,1095.415649 1134.634155,1095.116089 
	C1135.430786,1094.998535 1136.481567,1094.880493 1137.060303,1095.279541 
	C1141.919067,1098.629517 1142.204102,1095.619141 1142.000000,1091.415527 
	C1142.000000,1091.000000 1142.500000,1091.000000 1142.750000,1091.000000 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1094.710205,1041.150879 
	C1096.167480,1041.164795 1097.334839,1041.329468 1098.751221,1041.747070 
	C1091.829590,1049.360474 1087.973633,1049.332886 1080.546387,1041.877930 
	C1079.186890,1040.513428 1077.432739,1041.173340 1075.420898,1041.000000 
	C1072.541382,1039.808350 1072.410278,1037.935425 1073.727051,1035.359375 
	C1075.018677,1034.891235 1075.964600,1034.590088 1076.498901,1033.648193 
	C1077.295532,1032.244141 1076.828247,1029.566406 1079.642090,1030.199829 
	C1081.875366,1030.702393 1083.431396,1032.301758 1083.886597,1034.537476 
	C1084.713745,1038.600220 1088.782349,1039.557251 1091.033569,1042.254150 
	C1092.144531,1043.585083 1093.355591,1041.942993 1094.710205,1041.150879 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1024.752197,1053.196289 
	C1026.916260,1049.623779 1026.045776,1046.214478 1025.060303,1042.402344 
	C1028.447021,1042.847778 1030.186157,1045.199829 1029.545044,1048.550415 
	C1029.068970,1051.037964 1029.570557,1051.717285 1031.989746,1052.115112 
	C1034.722290,1052.564575 1037.558472,1053.318848 1038.948242,1056.645752 
	C1036.859863,1057.173828 1034.663696,1056.583130 1032.287842,1057.734863 
	C1028.310425,1058.600220 1026.138916,1060.488647 1026.238525,1064.753662 
	C1024.187866,1070.616577 1019.902832,1072.414551 1014.241089,1072.229492 
	C1016.371643,1069.884277 1018.031494,1067.165649 1021.123535,1065.605225 
	C1024.545410,1063.878296 1022.804260,1060.552124 1022.057373,1057.391846 
	C1022.000000,1057.000000 1022.000000,1056.500000 1022.000000,1056.250000 
	C1022.198547,1054.519775 1023.296082,1053.902954 1024.752197,1053.196289 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M1143.068115,1057.340454 
	C1145.070679,1056.674316 1147.141235,1056.348511 1149.605957,1056.011353 
	C1152.570557,1057.131348 1155.194214,1057.873535 1158.047119,1058.124390 
	C1160.935791,1058.378296 1161.782104,1060.169922 1159.839600,1062.543457 
	C1158.491699,1064.190552 1156.986816,1065.795898 1155.276001,1067.040283 
	C1152.089600,1069.357666 1147.991089,1068.050781 1147.147583,1064.242554 
	C1146.527710,1061.443726 1144.722534,1059.707397 1143.068115,1057.340454 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1182.156982,1054.353271 
	C1183.782349,1050.501099 1185.747559,1051.890625 1186.959229,1054.190063 
	C1187.844482,1055.870239 1192.557495,1057.638794 1187.752319,1059.996826 
	C1185.638550,1061.034180 1186.166626,1062.624878 1186.000244,1064.591675 
	C1183.320435,1068.543701 1178.547241,1069.173950 1173.204590,1066.331299 
	C1171.641479,1064.386597 1172.132690,1062.955566 1173.716553,1061.302002 
	C1175.291382,1060.999756 1176.583130,1061.023438 1177.873901,1060.995117 
	C1182.940552,1060.883911 1183.496826,1060.089233 1182.156982,1054.353271 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M1221.596436,1093.030518 
	C1221.148315,1094.987061 1220.243286,1096.056030 1218.453369,1095.972778 
	C1215.742554,1095.847046 1213.980347,1097.176514 1212.118774,1099.108521 
	C1208.937378,1102.410522 1204.835327,1101.249268 1201.029053,1100.221802 
	C1199.954346,1099.931763 1199.179321,1098.765381 1199.212158,1097.489258 
	C1199.246582,1096.162476 1200.103882,1095.182861 1201.362305,1095.182739 
	C1206.993896,1095.182129 1211.580322,1091.050049 1217.308105,1091.304443 
	C1218.970337,1091.378174 1220.595825,1090.746094 1221.596436,1093.030518 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M1073.657715,1035.045410 
	C1074.061401,1036.789062 1073.743042,1038.627441 1074.844727,1040.650635 
	C1073.893188,1042.424561 1071.855347,1042.070312 1070.598511,1043.800781 
	C1069.125366,1045.829346 1066.688232,1045.793945 1064.977661,1043.316040 
	C1064.433350,1042.527588 1063.821533,1041.759521 1063.121094,1041.110962 
	C1061.888550,1039.969727 1059.090698,1038.863159 1060.953491,1037.179443 
	C1062.367432,1035.901245 1064.702148,1032.808594 1067.571777,1035.606812 
	C1069.756958,1037.737549 1071.451782,1035.788818 1073.657715,1035.045410 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M1115.612183,1107.993652 
	C1116.097168,1109.642212 1116.942017,1111.040405 1118.371582,1111.492798 
	C1121.537964,1112.494507 1121.115356,1114.281982 1119.755615,1116.364136 
	C1118.501221,1118.284668 1117.214722,1119.476929 1114.501221,1118.102173 
	C1108.759155,1115.193237 1108.178833,1114.319092 1109.840820,1107.360962 
	C1111.743042,1107.327515 1113.486206,1107.654907 1115.612183,1107.993652 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M1080.654053,1017.021484 
	C1084.052979,1016.991577 1087.390381,1016.328125 1088.847900,1020.671448 
	C1088.854004,1022.422913 1088.190063,1023.980957 1086.771484,1023.742432 
	C1083.660156,1023.219177 1080.885620,1024.018921 1077.937134,1024.709473 
	C1073.237549,1025.810059 1071.836914,1022.500061 1071.048828,1018.364502 
	C1071.499512,1017.391418 1072.171387,1017.190002 1073.278198,1017.247314 
	C1075.948364,1019.524658 1078.127563,1018.265015 1080.654053,1017.021484 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1074.306519,1074.163574 
	C1073.992432,1070.718384 1077.169312,1069.765381 1078.841431,1067.298096 
	C1081.423584,1067.109375 1084.434082,1066.356323 1085.607300,1069.152710 
	C1086.746704,1071.868286 1084.394165,1073.903442 1082.642578,1075.451538 
	C1079.803101,1077.960938 1077.116821,1076.042969 1074.306519,1074.163574 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M923.203125,999.061890 
	C921.796204,998.458679 919.165283,988.626099 919.893311,987.745117 
	C920.649292,986.830322 921.490234,985.974304 922.851624,986.511658 
	C925.632385,987.609436 927.511841,989.609558 928.603027,992.370728 
	C929.126221,993.694702 928.637268,994.651489 927.422974,995.229248 
	C925.729858,996.034912 924.296448,997.095398 923.203125,999.061890 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1002.497314,1017.377136 
	C1000.280273,1018.788452 999.237976,1020.612976 998.451111,1022.676880 
	C998.148560,1023.470459 997.314453,1023.850647 996.399536,1023.758423 
	C995.605286,1023.678467 994.980042,1022.888794 995.257935,1022.447754 
	C997.233643,1019.312683 995.514771,1014.887329 999.165771,1012.251160 
	C1001.742981,1010.390320 1003.801575,1011.234741 1005.205200,1013.119995 
	C1007.024780,1015.564270 1004.902527,1016.566162 1002.497314,1017.377136 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M1184.942383,1116.607544 
	C1182.792358,1117.942993 1180.745239,1120.480103 1178.080811,1117.344238 
	C1180.829834,1116.445801 1181.722412,1115.044189 1179.943970,1112.506470 
	C1179.662109,1112.104126 1179.230835,1111.801025 1178.976196,1111.385986 
	C1178.029907,1109.843262 1177.065186,1108.223755 1177.633423,1106.352051 
	C1177.947510,1105.317383 1179.059814,1104.991577 1180.096313,1105.134033 
	C1182.572388,1105.474121 1182.971313,1107.727783 1183.562378,1109.481323 
	C1184.286743,1111.630249 1184.468506,1113.962280 1184.942383,1116.607544 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M997.387268,1006.977539 
	C995.077209,1006.618713 993.175476,1005.810791 993.529785,1003.636475 
	C993.849731,1001.673340 995.770813,1000.988464 997.755188,1000.997009 
	C998.245850,1000.999084 998.739441,1000.881958 999.227112,1000.907227 
	C1001.017822,1001.000000 1002.704895,996.930786 1004.389221,1000.372314 
	C1005.787598,1003.229553 1003.003052,1006.464539 999.261963,1006.978394 
	C998.778381,1007.044800 998.278198,1006.990662 997.387268,1006.977539 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1119.408936,1024.027100 
	C1117.611084,1024.000000 1116.222168,1024.000000 1114.416626,1024.000000 
	C1113.250000,1024.000000 1112.500000,1024.000000 1111.375000,1024.000000 
	C1111.896240,1020.765808 1110.157593,1016.290283 1115.614502,1015.011414 
	C1120.913208,1018.322632 1121.637939,1020.041504 1119.408936,1024.027100 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1248.991455,1153.370483 
	C1249.444458,1153.000000 1249.888916,1153.000000 1250.666748,1153.000000 
	C1252.282471,1153.194214 1253.708374,1153.099487 1254.814819,1153.648315 
	C1256.593872,1154.530884 1257.304443,1156.268311 1256.862549,1158.243164 
	C1256.343384,1160.565063 1254.351562,1159.817139 1252.412598,1159.999512 
	C1247.034302,1159.822510 1248.591675,1156.500732 1248.991455,1153.370483 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1253.746216,1126.262817 
	C1254.443970,1126.000000 1254.887939,1126.000000 1255.664795,1126.000000 
	C1257.487793,1129.563354 1256.961426,1132.818726 1254.307861,1135.605347 
	C1253.544434,1136.407104 1252.426392,1137.129150 1251.192017,1136.335083 
	C1250.178101,1135.682861 1250.198486,1134.668213 1250.631226,1133.695190 
	C1251.677856,1131.341187 1249.566162,1127.723389 1253.746216,1126.262817 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1193.785522,1098.034546 
	C1193.233521,1100.922119 1191.500488,1102.145142 1189.156128,1101.279785 
	C1187.546875,1100.685913 1186.165161,1099.174438 1186.100586,1097.121216 
	C1186.045898,1095.386475 1186.944824,1094.355103 1188.673828,1094.076172 
	C1191.539185,1093.614014 1192.915161,1095.246216 1193.785522,1098.034546 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1004.272156,1033.218750 
	C1002.801636,1034.630981 1001.192383,1035.472656 1000.508606,1033.419312 
	C999.817749,1031.344849 998.546997,1028.842041 999.668396,1026.842896 
	C1000.677185,1025.044678 1002.147888,1027.284790 1003.453064,1027.544312 
	C1008.329041,1028.513428 1007.122864,1030.573730 1004.272156,1033.218750 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M916.890381,976.908813 
	C914.065063,976.428955 912.620300,975.187378 913.128052,972.472229 
	C913.458130,970.707214 914.674561,970.095398 916.328125,970.029724 
	C918.141052,969.957764 919.861328,970.576294 920.335876,972.265137 
	C921.009277,974.661743 919.758057,976.324951 916.890381,976.908813 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M1095.763550,1057.048828 
	C1097.304443,1057.899292 1098.484863,1058.812134 1097.400879,1060.255859 
	C1096.478149,1061.484619 1095.265869,1060.689209 1094.460083,1059.863403 
	C1092.987915,1058.354248 1091.393066,1056.866943 1090.341919,1055.080566 
	C1089.683838,1053.962280 1089.365601,1051.906982 1091.297607,1051.388794 
	C1093.181396,1050.883545 1093.776611,1052.477783 1093.958496,1054.063232 
	C1094.090088,1055.210205 1094.089844,1056.391113 1095.763550,1057.048828 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M1053.890137,1023.475464 
	C1050.546021,1022.496704 1048.663208,1019.356201 1045.680664,1017.640503 
	C1045.131592,1017.324707 1045.792603,1016.122864 1046.404663,1016.280090 
	C1048.882568,1016.916748 1051.442749,1017.508484 1053.697388,1018.656921 
	C1055.746826,1019.700806 1053.898315,1021.622864 1053.890137,1023.475464 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M917.656616,1002.572021 
	C918.362244,1004.758179 923.642944,1006.578735 918.357544,1008.827454 
	C917.191711,1009.323486 914.918579,1009.627014 914.521729,1007.927612 
	C913.996094,1005.677124 915.001038,1003.549133 917.656616,1002.572021 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M1074.252441,1017.664429 
	C1073.250000,1018.000000 1072.500000,1018.000000 1071.375000,1018.000000 
	C1069.896240,1017.381104 1068.545166,1017.064270 1068.053467,1015.316040 
	C1070.656494,1010.250549 1070.656494,1010.250549 1073.747070,1014.797119 
	C1074.642334,1015.673523 1074.765381,1016.459595 1074.252441,1017.664429 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M884.105286,990.898499 
	C884.491028,991.666870 884.751526,992.082642 884.999878,992.505493 
	C885.845642,993.945374 886.912598,995.760803 884.745361,996.580750 
	C882.724854,997.345154 881.965454,995.207642 881.496094,993.721985 
	C881.004761,992.166931 879.996765,990.143250 882.641418,989.377014 
	C882.946899,989.288452 883.523560,990.135803 884.105286,990.898499 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M1006.622681,1059.052490 
	C1006.788208,1059.782349 1006.617310,1060.578613 1006.355835,1061.344116 
	C1005.842163,1062.847778 1005.654724,1064.858154 1003.510742,1064.764893 
	C1001.045044,1064.657715 1001.039124,1062.622681 1001.003296,1060.390259 
	C1002.829041,1060.163940 1004.552795,1059.725586 1006.622681,1059.052490 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M966.852661,1011.284607 
	C968.183105,1011.005249 968.843140,1011.813721 969.749512,1012.814331 
	C969.684387,1014.496887 970.868958,1016.468201 968.880920,1017.392090 
	C968.111511,1017.749695 967.580322,1016.655212 966.979980,1016.117371 
	C965.342896,1014.650635 964.981506,1013.196350 966.852661,1011.284607 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M915.906189,994.567627 
	C915.959351,996.365601 915.959351,997.793823 915.959351,1000.223511 
	C913.124756,997.779968 911.219971,995.978210 913.135803,993.205017 
	C914.393982,991.383667 915.256287,992.959106 915.906189,994.567627 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M977.202393,1005.197510 
	C977.292969,1003.560181 976.269653,1000.997803 978.944275,1001.358459 
	C981.640625,1001.721985 982.138367,1003.878113 981.042297,1006.652466 
	C980.555542,1007.000000 980.111084,1007.000000 979.333313,1007.000000 
	C978.475220,1006.458130 977.933350,1005.933228 977.202393,1005.197510 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1071.122803,1048.575684 
	C1074.443848,1046.382812 1073.890747,1049.687622 1074.691772,1050.947998 
	C1075.116089,1051.615601 1074.645386,1052.696777 1073.637085,1052.697998 
	C1071.057861,1052.701172 1071.059814,1050.773926 1071.122803,1048.575684 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M911.634888,945.991882 
	C912.000000,946.000000 912.500000,946.000000 912.750000,946.000000 
	C912.886841,946.469849 912.879944,947.003784 912.642151,947.398560 
	C911.627869,949.082275 910.121643,949.304016 908.460510,948.527039 
	C907.509094,948.082031 906.798035,947.090454 907.351685,946.166443 
	C908.430786,944.365295 909.958557,946.367798 911.634888,945.991882 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M1137.666748,1046.000000 
	C1138.000000,1048.041626 1138.000000,1050.083374 1138.000000,1052.562500 
	C1134.733887,1051.795166 1134.588501,1049.459106 1135.851807,1046.333008 
	C1136.444458,1046.000000 1136.888916,1046.000000 1137.666748,1046.000000 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M1001.301270,1054.667236 
	C998.898743,1055.044434 996.651917,1054.483887 996.635254,1052.226440 
	C996.620850,1050.286499 998.936646,1051.284302 1000.613831,1051.000000 
	C1001.837280,1051.996582 1002.097168,1053.097412 1001.301270,1054.667236 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1102.842041,1088.714111 
	C1102.744263,1091.019653 1101.235352,1090.994873 1099.371338,1090.998413 
	C1097.957886,1090.075439 1097.778687,1089.079346 1098.947388,1088.171997 
	C1100.222534,1087.182129 1101.546631,1086.788452 1102.842041,1088.714111 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M899.889893,976.977783 
	C899.418640,978.535095 899.668945,980.802551 897.567993,979.983215 
	C896.497253,979.565735 896.731018,977.520813 897.270447,976.396484 
	C898.074707,974.719971 899.138550,974.838623 899.889893,976.977783 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M987.148438,1028.341797 
	C989.016235,1028.419189 990.031311,1029.168213 989.627258,1030.804932 
	C989.328735,1032.013794 988.160767,1032.130615 987.238708,1031.671631 
	C985.674194,1030.892944 985.834595,1029.682129 987.148438,1028.341797 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1148.044434,1032.335938 
	C1148.958740,1031.728027 1149.265625,1029.611450 1150.702026,1030.875732 
	C1151.738403,1031.787964 1152.070923,1033.073364 1151.128662,1034.703857 
	C1149.496094,1034.890991 1148.429810,1034.234863 1148.044434,1032.335938 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1001.291992,1055.129883 
	C1001.000000,1053.933350 1001.000000,1052.866577 1001.000000,1051.399902 
	C1001.805359,1051.246704 1002.610657,1051.493530 1003.708008,1051.870117 
	C1004.000000,1053.066650 1004.000000,1054.133423 1004.000000,1055.600098 
	C1003.194702,1055.753174 1002.389343,1055.506348 1001.291992,1055.129883 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M1173.645996,1061.054199 
	C1173.671997,1062.421387 1173.343994,1063.842651 1173.008057,1065.632080 
	C1169.389526,1064.148193 1170.741699,1062.592529 1173.645996,1061.054199 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1025.000000,1008.666687 
	C1024.124023,1009.214172 1023.008240,1010.329285 1022.424194,1009.031250 
	C1021.697998,1007.417297 1023.092834,1007.157410 1024.655518,1007.010193 
	C1025.000000,1007.444458 1025.000000,1007.888916 1025.000000,1008.666687 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M967.518433,1023.991699 
	C966.751038,1024.962646 965.994324,1024.971191 965.435181,1024.191284 
	C965.037109,1023.635986 965.124146,1022.831177 965.794861,1022.569397 
	C966.717712,1022.209167 967.250122,1022.876709 967.518433,1023.991699 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M946.955078,924.668213 
	C946.250000,925.000366 945.500000,925.000793 944.375000,925.000610 
	C944.133301,924.086670 943.717102,922.809509 945.002808,922.541016 
	C946.228577,922.284973 946.603210,923.377014 946.955078,924.668213 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1076.707397,1057.030029 
	C1076.843994,1057.869141 1077.318970,1059.024414 1076.062012,1059.385620 
	C1075.839355,1059.449585 1075.433105,1058.874268 1075.055786,1058.297119 
	C1075.471558,1057.686646 1075.943237,1057.373413 1076.707397,1057.030029 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M946.664429,1026.068359 
	C946.946289,1027.135010 947.090393,1028.361938 945.335388,1028.931396 
	C945.053223,1027.864746 944.908569,1026.637573 946.664429,1026.068359 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M977.054077,1005.312561 
	C977.886353,1005.106384 978.643677,1005.390442 978.946045,1006.687378 
	C978.113831,1006.893311 977.356079,1006.609924 977.054077,1005.312561 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M444.609436,1116.000000 
	C445.028564,1117.166626 444.853119,1118.691528 445.988556,1119.179688 
	C450.767181,1121.234741 454.331146,1124.704346 457.985504,1128.262207 
	C459.512360,1129.748779 462.401276,1129.716919 461.458496,1133.076172 
	C461.173767,1134.090576 462.399384,1134.708130 463.460114,1134.861206 
	C464.313354,1134.984131 465.188232,1134.890381 465.595917,1134.019287 
	C466.094482,1132.953857 465.341370,1132.185303 464.819275,1131.324951 
	C462.053802,1126.767700 462.394043,1120.915161 465.676147,1117.018555 
	C466.293732,1116.285278 467.124908,1115.538208 468.005005,1115.254150 
	C471.297791,1114.191162 481.225647,1120.442993 482.051056,1123.835449 
	C483.249023,1128.759155 485.477325,1133.386475 485.534271,1138.658691 
	C485.555176,1140.593994 485.784180,1142.493896 484.407104,1143.703979 
	C482.444183,1145.429077 482.030243,1147.379272 481.881287,1149.823608 
	C481.704285,1152.727783 480.053986,1154.589966 476.845520,1153.401611 
	C474.059723,1152.369629 471.635681,1153.977539 469.020142,1154.063477 
	C467.436859,1154.115356 466.654541,1155.252319 466.421112,1156.643921 
	C466.181610,1158.071655 467.303040,1158.805176 468.410828,1159.140503 
	C472.644073,1160.422119 476.268280,1163.250366 481.107147,1163.128906 
	C486.002777,1163.005859 488.846649,1166.436035 489.002625,1171.349121 
	C489.074554,1173.615234 488.463074,1176.256836 491.659332,1177.008179 
	C493.236816,1177.378662 494.807037,1179.313965 496.230164,1178.221191 
	C497.802032,1177.014404 496.958954,1174.859741 496.531677,1173.093872 
	C496.453583,1172.771362 496.237488,1172.455933 496.247559,1172.142334 
	C496.354858,1168.795532 494.395782,1164.806519 497.737335,1162.356567 
	C501.316162,1159.732544 505.778564,1161.039673 509.872131,1161.266479 
	C511.347198,1161.348145 511.910522,1162.786621 511.956360,1164.259766 
	C512.014465,1166.128174 511.446167,1167.588989 509.398529,1167.914795 
	C507.242767,1168.257568 505.313782,1169.125366 505.268585,1171.470947 
	C505.219543,1174.013428 507.540588,1173.695923 509.299377,1174.109009 
	C512.808533,1174.933228 513.889465,1177.729370 513.954590,1180.911011 
	C514.041199,1185.140625 512.336731,1188.962036 510.774719,1192.787598 
	C510.215057,1194.158325 509.188293,1195.496216 509.128662,1196.881592 
	C508.984619,1200.228516 507.207764,1201.432861 504.275482,1200.951050 
	C499.589813,1200.181152 497.619324,1202.918945 497.420593,1206.714722 
	C497.267181,1209.644531 494.693878,1212.690308 497.688171,1215.817383 
	C497.832062,1218.496826 497.664093,1220.993774 497.748047,1223.745361 
	C498.077454,1228.781860 496.192841,1229.972778 490.369446,1228.294189 
	C489.849335,1222.044800 486.660889,1219.029419 478.421326,1217.075073 
	C477.466278,1216.848511 476.493042,1216.698730 475.264099,1216.256714 
	C474.338074,1215.216797 474.709625,1213.850586 473.257446,1213.184082 
	C470.850189,1210.725098 471.181488,1206.954590 469.701385,1205.099731 
	C465.571472,1199.924316 467.994507,1194.706665 468.056335,1189.518066 
	C468.090515,1186.646973 466.699341,1184.882690 464.314667,1183.170166 
	C463.356445,1182.328491 463.245819,1181.548218 463.763672,1180.343750 
	C462.786682,1176.844727 460.422638,1174.216187 460.704041,1170.365601 
	C460.873688,1168.044312 458.353821,1167.932373 456.490601,1168.020874 
	C453.337494,1168.170654 452.056702,1166.829102 451.939484,1163.611572 
	C451.750366,1158.420654 449.684631,1154.129395 444.717041,1151.743530 
	C441.593567,1150.243408 441.587494,1147.095459 441.296173,1144.446167 
	C441.001709,1141.768066 443.471527,1141.537354 445.609314,1140.723389 
	C450.331299,1138.925537 448.658051,1134.384766 449.003082,1130.760376 
	C449.277252,1127.880371 447.898956,1126.432251 445.131805,1126.003906 
	C443.990265,1125.827148 442.885345,1125.413696 441.381866,1125.053955 
	C439.883270,1123.304565 441.110840,1120.478638 438.189026,1119.281250 
	C439.265900,1116.327026 441.548737,1115.762085 444.609436,1116.000000 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M497.293488,1035.239258 
	C495.284851,1035.003296 493.570953,1035.001709 491.428650,1035.000000 
	C487.826691,1032.912476 484.063538,1034.288940 481.087219,1034.593750 
	C475.401154,1035.175781 468.871307,1033.615967 463.992615,1038.668335 
	C462.948303,1039.749634 460.544312,1039.518066 458.384583,1039.945679 
	C456.693054,1041.054321 455.468292,1040.655518 454.012177,1039.466675 
	C453.704346,1038.884521 453.655457,1038.618408 453.723633,1037.965576 
	C454.121155,1037.166382 454.372925,1036.668945 454.807159,1036.455566 
	C464.464935,1031.707642 474.259888,1027.082275 485.374847,1028.039551 
	C493.169830,1028.710938 500.482178,1027.724976 507.502289,1024.354858 
	C508.569153,1023.842651 509.703339,1023.627197 511.256226,1023.553406 
	C514.182556,1023.364746 516.520874,1022.650879 518.595886,1021.092957 
	C520.775513,1019.456299 523.205139,1018.666138 525.991760,1018.477600 
	C531.409424,1018.111084 534.762390,1014.203369 538.580444,1010.447998 
	C544.195679,1004.725952 549.773743,1003.194763 557.325684,1006.645752 
	C561.702026,1008.645508 567.332764,1008.178467 572.692261,1006.708496 
	C575.479370,1004.376770 579.083862,1003.741272 580.993896,1000.275757 
	C581.988647,998.865906 583.044922,998.144592 584.725891,997.690674 
	C590.544495,997.124695 596.085388,997.598022 601.353027,995.697693 
	C601.964783,995.476990 602.729431,995.388794 603.322815,995.611328 
	C612.979431,999.233887 620.066345,993.482910 627.472656,988.987488 
	C628.910828,988.114624 630.238708,987.153931 632.392395,987.430542 
	C633.996582,992.615417 629.767517,994.979980 627.154785,998.489868 
	C625.988892,999.117798 625.071960,999.392578 623.897583,999.939575 
	C622.068970,1000.694458 621.023254,1001.963928 619.410522,1003.059814 
	C618.016602,1003.901794 616.836060,1004.271667 615.187988,1004.397949 
	C614.020081,1004.419434 613.237976,1004.424927 612.073792,1004.225586 
	C608.005798,1002.764343 604.443054,1005.187317 600.389099,1004.495239 
	C598.691589,1004.698059 597.384644,1004.791443 595.689697,1004.608398 
	C591.783875,1005.005920 587.783142,1003.333496 584.676086,1007.139648 
	C584.001038,1007.733337 583.554932,1007.984009 582.703430,1008.268738 
	C577.955688,1009.703064 574.093445,1012.160156 569.637573,1013.563721 
	C562.431580,1015.833618 555.253662,1016.104126 548.049866,1015.606018 
	C544.422974,1015.355225 541.926331,1015.920227 539.824219,1018.661011 
	C537.867615,1021.212097 535.887512,1023.678955 532.218994,1023.613403 
	C531.543457,1023.601257 530.757019,1023.888245 530.472351,1024.448120 
	C528.252563,1028.813354 523.924744,1028.429932 519.710815,1029.167969 
	C517.606140,1028.635254 516.185791,1029.245605 514.689514,1030.127930 
	C509.828186,1032.994873 503.936005,1033.087402 498.995270,1035.808350 
	C498.615021,1036.017822 498.037689,1035.692261 497.293488,1035.239258 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M355.390564,1103.790527 
	C350.360962,1095.536987 353.282684,1089.952759 362.473022,1089.395264 
	C364.097260,1089.296631 365.762329,1089.864990 366.268829,1091.229980 
	C367.617584,1094.865112 367.708954,1098.880005 366.404694,1102.378906 
	C365.282440,1105.389648 361.796997,1105.028564 358.960236,1104.997314 
	C357.779663,1104.984253 356.643738,1104.860474 355.390564,1103.790527 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M376.395569,1067.987061 
	C375.250000,1068.000000 374.500000,1068.000000 373.375000,1068.000000 
	C370.296967,1067.253662 367.605316,1066.393311 364.848083,1067.854492 
	C364.430359,1068.075684 363.753601,1068.164185 363.397919,1067.946411 
	C359.027344,1065.270142 355.385162,1067.507812 351.355865,1069.447998 
	C349.474762,1067.489746 350.932312,1065.906250 351.454956,1063.860840 
	C352.094025,1059.463623 355.115021,1057.801758 358.741394,1056.254883 
	C363.743958,1056.833618 367.926605,1058.931519 372.289581,1060.388916 
	C375.625183,1061.503174 378.593140,1062.045166 381.853333,1060.492554 
	C384.004639,1059.468018 386.307404,1059.176758 388.894073,1060.518555 
	C389.934814,1062.310059 389.305115,1063.628662 387.899078,1063.922607 
	C383.980377,1064.741821 380.780762,1067.434082 376.395569,1067.987061 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M490.011841,1228.389160 
	C493.029480,1227.872559 496.513641,1228.842773 497.946228,1224.365234 
	C499.332764,1224.002441 500.665527,1224.003418 501.998291,1224.007690 
	C504.629974,1224.016235 505.912964,1224.731567 505.332245,1227.922607 
	C504.744019,1231.154907 505.156464,1234.559082 504.962799,1237.878174 
	C504.873077,1239.414917 505.731110,1242.260864 503.969391,1242.165771 
	C501.821075,1242.049805 497.765350,1241.586914 497.677185,1239.393188 
	C497.469788,1234.231934 495.071106,1231.851318 490.563416,1230.606201 
	C490.239899,1230.516846 490.197052,1229.411255 490.011841,1228.389160 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M416.957703,1241.032471 
	C415.512085,1243.391113 413.701477,1244.690186 411.375824,1243.693115 
	C408.892731,1242.628662 410.551575,1240.600586 410.816498,1238.941284 
	C411.245544,1236.254028 410.986237,1233.702148 409.672302,1231.230591 
	C408.041229,1228.162598 409.679230,1225.784302 412.239777,1224.726074 
	C414.978699,1223.594360 415.329681,1226.595703 415.863617,1228.256836 
	C416.759399,1231.043457 418.801636,1231.058350 421.007324,1230.998169 
	C422.679840,1230.952637 423.979462,1231.491821 424.463501,1233.186401 
	C425.062286,1235.282593 423.472260,1235.712524 422.009033,1236.126709 
	C419.581635,1236.813721 418.029694,1238.399170 416.957703,1241.032471 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M389.344177,1061.239014 
	C387.511169,1061.035400 385.694275,1060.518433 384.582794,1061.182251 
	C375.070374,1066.864014 367.822876,1059.477905 359.360168,1057.052002 
	C358.492981,1052.266968 361.979248,1053.678223 363.847260,1054.377563 
	C369.900818,1056.643677 376.155640,1056.741333 382.448700,1057.104126 
	C386.778656,1057.353516 390.835999,1054.943359 395.560425,1056.311401 
	C397.084137,1057.922729 396.709808,1059.181152 395.299255,1060.688477 
	C394.248169,1061.000000 393.498779,1061.000000 392.374695,1061.000000 
	C391.315216,1061.578613 390.544434,1061.736816 389.344177,1061.239014 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M381.562012,1191.429810 
	C380.489166,1190.384644 379.498352,1189.748169 378.741486,1188.902344 
	C377.905304,1187.968018 377.739380,1186.731567 378.615387,1185.743896 
	C379.527802,1184.715210 380.988678,1184.793091 381.730194,1185.655518 
	C383.448059,1187.653687 385.213684,1187.809326 387.792633,1187.541626 
	C390.418915,1187.269165 392.982086,1188.911987 390.810883,1192.188843 
	C389.273315,1194.509277 391.529358,1199.378296 388.074127,1199.176392 
	C385.153412,1199.005737 383.006317,1195.030396 381.562012,1191.429810 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M463.624207,1179.991089 
	C464.000000,1180.750000 464.000000,1181.500000 464.000000,1182.625000 
	C462.213379,1184.476807 460.403381,1185.148438 458.198364,1183.673462 
	C456.885956,1182.795532 455.371033,1182.816040 453.956573,1183.687500 
	C452.405396,1184.643188 450.366089,1186.127197 449.398590,1183.693115 
	C448.458160,1181.327271 448.244141,1178.447021 451.391968,1176.805054 
	C453.603302,1175.651611 455.560913,1176.056885 457.477600,1177.304565 
	C459.278839,1178.477051 460.937714,1179.921143 463.624207,1179.991089 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M441.933228,1045.623779 
	C439.171570,1049.696655 434.931885,1050.224976 430.400269,1050.998291 
	C426.812592,1050.930054 424.217438,1052.932251 421.174561,1053.522095 
	C418.768341,1053.988403 417.060638,1053.691162 415.526215,1051.402344 
	C415.611145,1049.031128 416.967010,1047.875244 418.685974,1047.814941 
	C422.694977,1047.673950 426.541626,1046.077881 430.445221,1046.210205 
	C433.053589,1046.298706 434.899414,1045.709351 437.016327,1043.920654 
	C437.790375,1043.432739 438.285004,1043.282471 439.200653,1043.257324 
	C440.675995,1043.556030 441.402649,1044.246338 441.933228,1045.623779 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M540.521362,1257.312988 
	C542.007324,1255.648193 543.037598,1253.468994 545.453125,1254.427002 
	C547.547180,1255.257568 548.166809,1257.174805 547.988037,1259.315308 
	C547.811035,1261.434082 548.572327,1263.105835 550.002014,1264.652710 
	C550.964539,1265.694092 551.462769,1267.193115 549.566406,1267.739868 
	C548.097290,1268.163574 545.108521,1268.395020 545.486023,1266.703125 
	C546.406799,1262.575562 543.042419,1261.854858 541.348206,1259.772949 
	C540.869019,1259.184082 540.692139,1258.349121 540.521362,1257.312988 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M350.685120,1068.747314 
	C352.665405,1068.965210 353.644592,1067.793945 354.743439,1066.786987 
	C355.346130,1066.234741 356.367554,1065.226929 356.667023,1065.391968 
	C361.742676,1068.189331 367.573975,1064.614990 372.803650,1067.676147 
	C368.488678,1068.006836 363.946381,1070.050781 359.484070,1067.936035 
	C358.269043,1067.360229 357.222198,1067.111084 356.499451,1068.309570 
	C354.492279,1071.637695 350.741425,1071.561768 347.381226,1072.990234 
	C347.002441,1073.000000 347.000000,1073.000000 347.000000,1073.000000 
	C346.277405,1072.246582 345.614563,1071.459717 345.552979,1069.960205 
	C345.785522,1069.058228 346.109985,1068.647461 346.920593,1068.160400 
	C348.389435,1067.699585 349.424408,1067.825073 350.685120,1068.747314 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M388.906433,1061.365479 
	C389.750763,1061.003296 390.500488,1061.001709 391.625122,1061.000000 
	C389.980438,1066.509644 383.605103,1069.622559 376.289978,1068.264648 
	C379.528351,1064.392944 384.438293,1063.607056 388.906433,1061.365479 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M382.695312,1136.515625 
	C381.241760,1138.419189 379.891266,1139.592773 378.099548,1137.494995 
	C376.723419,1135.884033 376.619171,1134.224243 378.140442,1132.610718 
	C378.252930,1132.491455 378.393707,1132.328491 378.533417,1132.315674 
	C380.943909,1132.092041 381.040710,1126.707153 384.064209,1129.148193 
	C386.622589,1131.213623 384.317291,1133.980957 382.695312,1136.515625 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M415.720520,1050.725098 
	C418.827728,1054.342163 421.968018,1052.035034 424.976288,1051.439453 
	C426.489685,1051.139771 427.728302,1049.528442 429.757843,1050.751465 
	C425.592682,1053.229126 421.289459,1055.824951 415.974365,1054.240112 
	C414.499786,1053.800537 413.603638,1055.199707 412.424713,1055.718140 
	C411.368011,1056.182861 410.159454,1057.646240 409.119080,1054.977051 
	C409.545624,1053.231934 409.773956,1052.055298 410.786713,1050.841553 
	C412.489258,1049.657471 413.951630,1049.585938 415.720520,1050.725098 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M437.733551,1119.206543 
	C443.681732,1119.239746 443.957092,1119.703003 441.074829,1124.648926 
	C440.677795,1125.000000 440.348267,1125.044434 440.034576,1124.992798 
	C438.214966,1124.692749 435.646393,1126.001831 434.717102,1124.048706 
	C433.738525,1121.991943 436.491638,1120.974976 437.733551,1119.206543 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M522.504028,1240.443359 
	C525.831909,1241.488037 527.046143,1244.763184 525.287598,1246.867188 
	C524.463623,1247.852905 523.383789,1248.080688 522.194946,1247.792847 
	C520.703125,1247.431885 520.043579,1246.368042 520.105225,1244.906006 
	C520.177002,1243.205933 520.927979,1241.781982 522.504028,1240.443359 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M453.675415,1039.041992 
	C455.106873,1039.306030 456.211334,1039.612183 457.659119,1039.959106 
	C454.483612,1043.443237 450.217865,1045.442017 445.229370,1046.242188 
	C444.267700,1044.410156 445.567474,1043.410278 446.314545,1041.911621 
	C447.018463,1041.132080 447.594666,1040.747437 448.514618,1040.265625 
	C450.263855,1039.480469 451.890289,1039.650391 453.675415,1039.041992 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M409.000000,1055.000000 
	C408.194916,1055.247803 407.389832,1055.495483 406.293121,1055.874023 
	C403.781036,1058.228027 401.607422,1060.614746 397.846313,1057.930664 
	C398.386292,1051.749268 403.354828,1052.099487 407.739014,1051.068481 
	C408.383514,1051.252319 408.628571,1051.365479 409.157227,1051.766357 
	C409.818268,1052.817139 409.840240,1053.586182 409.239075,1054.679077 
	C408.998779,1054.996582 409.000000,1055.000000 409.000000,1055.000000 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M397.693268,1056.769775 
	C400.761200,1059.460083 402.925385,1057.015747 405.638733,1056.056152 
	C404.473389,1061.439209 400.044891,1060.980225 395.424805,1061.000000 
	C395.304230,1059.895508 395.610870,1058.791016 395.958160,1057.343262 
	C396.395569,1056.647949 396.857910,1056.497437 397.693268,1056.769775 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M446.670349,1041.686279 
	C447.132385,1043.455811 446.669983,1044.643433 445.205627,1045.732544 
	C444.250275,1045.998901 443.500580,1046.000000 442.376038,1046.002808 
	C441.420044,1045.511475 440.838867,1045.018555 440.128387,1044.265137 
	C440.997375,1040.432129 443.552032,1040.617676 446.670349,1041.686279 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M451.639099,1111.992310 
	C451.204132,1115.084473 448.656860,1113.628174 446.407349,1114.000000 
	C446.445953,1109.888306 449.238525,1111.922241 451.639099,1111.992310 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M473.000000,1213.333252 
	C474.154083,1212.504150 475.377106,1211.664062 476.359375,1213.185181 
	C477.104584,1214.339478 475.973083,1214.848022 475.181641,1215.753906 
	C475.000000,1216.000000 474.500000,1216.000000 474.250000,1216.000000 
	C473.791321,1215.764526 473.573517,1215.537720 473.173309,1215.159912 
	C473.000000,1214.555542 473.000000,1214.111084 473.000000,1213.333252 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M344.281311,1079.848755 
	C342.425659,1078.076538 343.995453,1077.623047 345.683350,1077.038330 
	C345.955231,1078.130615 345.537537,1079.062500 344.281311,1079.848755 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M510.532410,1230.738770 
	C511.539154,1230.481934 511.853271,1230.982910 511.726196,1231.638794 
	C511.667114,1231.943970 511.095459,1232.429443 510.875671,1232.372437 
	C510.161591,1232.187134 510.142517,1231.559570 510.532410,1230.738770 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M473.120178,1215.209229 
	C473.304504,1215.126831 473.557648,1215.320679 473.879761,1215.790771 
	C473.695343,1215.873291 473.442139,1215.679565 473.120178,1215.209229 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M884.170349,347.315430 
	C885.410645,345.724365 886.774109,344.343170 887.933289,342.679688 
	C890.567078,338.899994 894.347107,339.002869 896.400574,343.181793 
	C897.737854,345.903290 898.659058,346.069183 900.553711,344.032257 
	C900.878906,343.682617 901.360596,343.376617 901.820862,343.269897 
	C908.751770,341.662872 910.477173,340.151306 915.312195,334.808167 
	C917.530212,332.357117 920.232666,330.373810 922.462158,327.745697 
	C924.357971,325.510773 926.543823,327.863525 927.582458,330.275421 
	C929.806335,335.439423 932.985779,336.111603 937.162048,332.340729 
	C938.365662,331.253967 939.386108,329.582581 941.365173,330.368866 
	C942.823608,330.948334 943.804260,332.116058 943.905151,333.789398 
	C944.045288,336.112732 942.329529,336.911072 940.605286,337.630585 
	C938.735657,338.410736 936.544678,340.337891 939.611450,340.934235 
	C944.907532,341.964081 949.567444,346.691650 955.614136,343.960083 
	C957.644226,343.042969 959.458496,342.377075 960.258057,339.939819 
	C960.860168,338.104401 962.844849,336.141479 960.456238,334.737091 
	C957.570862,333.040710 957.994263,336.446442 957.214783,337.663757 
	C955.521667,340.308105 952.773804,341.178589 950.148621,341.790405 
	C947.575378,342.390137 945.939941,339.327484 947.820190,337.042755 
	C949.981689,334.416077 950.380676,331.045898 952.492004,328.378601 
	C954.186768,326.237579 954.806763,323.231476 952.414917,320.578583 
	C951.422241,319.477570 950.913269,317.953644 951.031494,316.392151 
	C951.178589,314.450073 950.094360,311.961273 951.790710,310.696320 
	C953.907715,309.117676 954.365051,312.590057 956.156189,312.933655 
	C959.018921,313.482788 961.188171,317.308746 964.756958,314.643860 
	C965.818298,313.851349 967.302124,313.386810 966.620544,311.648682 
	C966.095215,310.308960 965.014099,310.215088 963.716553,310.590302 
	C962.021545,311.080505 960.035889,311.380890 960.239807,308.754547 
	C960.409790,306.565338 962.279968,306.918610 963.720093,307.445190 
	C965.078369,307.941864 966.294434,307.634247 967.616516,307.410004 
	C969.602539,307.073151 972.015808,310.777496 973.158325,308.043243 
	C973.928772,306.199310 972.314087,302.903412 970.971924,300.752899 
	C969.458740,298.328552 968.453979,296.964417 972.408508,296.982208 
	C974.925720,296.993530 975.900208,295.631287 976.034119,293.220734 
	C976.125122,291.581726 976.617493,289.965515 976.923279,288.337769 
	C977.180908,286.965759 976.763733,284.886505 979.130676,285.354095 
	C980.606262,285.645569 981.952393,286.772003 981.565369,288.590881 
	C980.937439,291.542725 982.637512,292.615417 985.000610,292.939423 
	C989.836060,293.602325 988.597290,297.526947 987.418335,299.314667 
	C984.679993,303.466980 985.742676,296.138306 983.218079,297.795898 
	C982.351257,298.844147 981.415527,299.166534 980.213989,299.502808 
	C979.991577,299.595886 979.668518,299.905579 979.629150,300.130737 
	C979.725769,301.047821 980.033997,301.701233 980.025757,302.709839 
	C981.046265,306.146729 984.151733,304.985931 986.614136,305.988098 
	C988.632080,305.397247 990.315491,305.396576 992.360779,305.600281 
	C992.935913,305.641449 993.164062,305.652557 993.724609,305.653564 
	C996.097168,300.792084 1001.195862,299.313446 1004.665894,296.037415 
	C1005.908142,294.864624 1007.318665,293.907928 1009.143555,294.638733 
	C1011.226440,295.472900 1012.328918,297.208435 1012.244080,299.329620 
	C1012.084595,303.315796 1012.591675,307.284973 1012.246216,311.279541 
	C1011.865295,315.683899 1013.190857,317.139191 1016.828064,317.281860 
	C1020.890625,317.441193 1021.418091,314.917786 1021.311951,311.762054 
	C1021.154480,307.079285 1019.534058,302.635284 1019.100525,297.995880 
	C1018.936829,296.244202 1017.469482,295.548645 1016.124817,294.925140 
	C1011.799438,292.919586 1008.038025,289.674194 1003.025024,289.155273 
	C1000.910828,288.936371 999.860596,287.431396 999.870605,285.323730 
	C999.880188,283.299255 1000.840332,281.832458 1002.764954,281.094574 
	C1009.089844,278.669800 1014.998779,275.472626 1020.550415,271.598938 
	C1025.065552,268.448547 1030.032593,269.180420 1034.984863,270.006927 
	C1036.453247,270.251984 1037.830566,270.951843 1039.344849,271.019196 
	C1041.558472,271.117676 1043.036621,270.689087 1041.914917,267.908722 
	C1040.538330,264.496490 1042.372803,261.506439 1045.286255,261.490875 
	C1053.108887,261.449127 1055.076660,256.942627 1055.436035,250.122894 
	C1055.915161,247.483063 1054.961914,245.683350 1053.836548,243.740982 
	C1051.594116,239.870743 1052.819580,237.027740 1057.173584,235.797470 
	C1059.742188,235.071686 1062.199463,234.241684 1064.597412,233.026154 
	C1068.277710,231.160706 1070.546875,232.396378 1071.417114,236.540604 
	C1071.893921,238.811523 1072.218262,241.143646 1071.752686,243.468369 
	C1071.213623,246.159225 1071.128906,248.544861 1074.695312,249.043625 
	C1078.078857,249.516800 1082.120361,246.985321 1081.354004,244.060410 
	C1079.210815,235.879547 1084.154541,230.537231 1088.158813,224.330521 
	C1089.794678,222.746384 1091.482300,223.149399 1093.101196,223.356094 
	C1095.601074,223.675262 1098.015869,223.901093 1100.166870,222.091660 
	C1101.588135,220.895996 1103.139404,221.259949 1104.831299,221.828094 
	C1109.155640,223.280167 1110.634155,222.378586 1111.010864,218.049194 
	C1111.184814,216.050903 1111.880127,214.574356 1113.753662,213.243561 
	C1114.195923,212.235840 1114.391968,211.471695 1114.622192,210.573746 
	C1116.646606,212.324997 1118.494263,214.309860 1120.700806,215.741669 
	C1124.016479,217.893082 1127.624023,216.135239 1127.549927,212.334076 
	C1127.480957,208.795914 1129.900635,209.111664 1131.877197,209.073441 
	C1135.146240,209.010239 1137.867432,208.400665 1138.927612,204.851929 
	C1139.323853,203.525513 1140.353516,203.278290 1141.516724,202.889587 
	C1146.761108,201.136902 1152.116455,201.048264 1157.503662,201.708267 
	C1159.922363,202.004578 1162.424072,202.360870 1163.630615,199.828308 
	C1164.870972,197.224396 1161.912354,197.196335 1160.718140,196.093719 
	C1159.859863,195.301300 1158.854004,194.471130 1159.379028,193.142609 
	C1159.956299,191.682251 1161.301147,191.950256 1162.375854,192.318375 
	C1165.477539,193.380646 1168.681885,193.285385 1171.767578,192.789337 
	C1176.390747,192.046173 1177.546753,189.332230 1174.940674,185.510345 
	C1172.751343,182.299637 1172.596191,178.637070 1172.075195,175.075195 
	C1171.544800,171.449173 1174.097778,169.814987 1177.003174,168.460739 
	C1180.322266,166.913651 1183.558228,165.911530 1186.840454,168.381866 
	C1191.861816,172.160980 1191.879272,172.360413 1187.300659,176.227676 
	C1184.942383,178.219467 1184.791016,181.131439 1183.781250,183.735458 
	C1182.467651,187.123154 1183.891724,188.682922 1186.671021,189.941132 
	C1187.700439,190.407120 1188.049194,191.388428 1187.672119,192.465759 
	C1187.039307,194.273178 1186.812012,195.822708 1189.422485,195.958099 
	C1191.534058,196.067642 1193.458618,195.904678 1193.982422,193.234970 
	C1194.167725,192.290848 1194.453369,191.158768 1195.102173,190.544800 
	C1203.002686,183.068634 1200.979980,173.746841 1199.626099,164.771790 
	C1199.173950,161.774292 1199.732178,160.419693 1202.269531,159.394791 
	C1203.484497,158.904022 1204.666748,158.306061 1205.802734,157.650681 
	C1211.546265,154.337265 1214.168335,149.556625 1213.053467,142.801727 
	C1212.619385,140.171631 1213.123779,137.563156 1214.371094,135.052658 
	C1215.533447,132.713257 1215.220825,130.192474 1212.490601,129.255432 
	C1209.695923,128.296280 1208.613647,130.034317 1207.730713,132.746704 
	C1206.653320,136.056183 1203.498901,138.474655 1203.041626,142.252060 
	C1202.962402,142.906174 1201.997070,143.602814 1201.062622,143.408310 
	C1199.998901,143.186935 1199.968994,142.191376 1200.050049,141.408188 
	C1200.288452,139.107269 1200.758667,136.828110 1200.945801,134.524780 
	C1201.114746,132.445572 1200.941772,130.272659 1198.149780,130.153229 
	C1195.383301,130.034882 1195.004028,132.055878 1195.005981,134.232025 
	C1195.007202,135.564621 1194.881104,136.910202 1195.022217,138.227432 
	C1195.491333,142.604309 1193.590820,144.923538 1189.191040,144.962997 
	C1187.196411,144.980896 1185.208984,145.454071 1184.469727,147.179688 
	C1182.090820,152.732971 1177.365845,150.803223 1173.358032,150.885345 
	C1171.057617,150.932495 1170.136230,148.477814 1171.761719,147.618423 
	C1177.528687,144.569305 1175.790039,138.921204 1176.820068,134.382843 
	C1177.429810,131.696304 1178.212280,129.399933 1180.563232,128.374985 
	C1184.438110,126.685623 1184.451538,123.824554 1183.647339,120.550095 
	C1183.276855,119.041687 1182.709473,117.574150 1184.367920,116.554695 
	C1185.889893,115.619041 1187.036377,116.446831 1188.401367,117.330368 
	C1193.641846,120.722099 1196.967773,120.250648 1201.239258,115.828979 
	C1202.332764,114.697029 1203.742065,113.700600 1205.202148,114.164848 
	C1208.333740,115.160622 1210.818115,114.828819 1213.420532,112.577812 
	C1215.086182,111.137085 1216.343018,112.978874 1217.290649,114.431999 
	C1217.975586,115.482506 1218.959473,116.264160 1220.205078,115.474976 
	C1221.171265,114.862694 1221.183472,113.593689 1220.704102,112.755890 
	C1218.608398,109.093239 1222.625488,104.870277 1219.537109,101.258156 
	C1218.987183,100.614929 1220.223999,99.780212 1220.922729,99.495651 
	C1227.209839,96.935188 1227.804932,91.098328 1227.740845,85.816200 
	C1227.678345,80.668839 1230.386230,79.176483 1234.246704,78.161568 
	C1237.392456,77.334518 1240.741943,77.291962 1242.104126,73.283012 
	C1242.730347,71.439827 1244.825928,71.814232 1246.737915,72.744751 
	C1247.567383,81.577545 1243.187256,86.383537 1234.752930,86.117325 
	C1232.465210,86.045120 1231.647217,86.727737 1232.000732,88.874008 
	C1232.595947,92.487869 1232.887451,96.238350 1237.914795,96.652100 
	C1240.247681,96.844101 1241.895752,98.360031 1241.978638,100.912872 
	C1242.053589,103.222511 1240.624390,104.479645 1238.597168,105.284096 
	C1237.197266,105.839645 1235.013916,105.707535 1235.634155,108.606201 
	C1235.240356,114.596191 1234.350830,115.658073 1229.450806,115.216896 
	C1227.171143,115.011642 1226.645020,115.890579 1226.162842,117.781998 
	C1225.499512,120.385033 1225.334106,120.342873 1222.448242,121.972511 
	C1231.428589,120.849724 1237.074097,114.904045 1242.896973,108.554581 
	C1244.136963,107.853828 1245.124878,107.796928 1246.495117,108.161087 
	C1248.284668,109.095253 1249.161743,110.546173 1250.824097,111.197243 
	C1258.637695,114.257362 1260.364014,122.739182 1254.390259,129.703186 
	C1258.633789,130.482788 1260.483154,133.069046 1260.520142,137.216431 
	C1260.558960,141.561279 1259.520874,145.364868 1255.062744,146.855621 
	C1251.171387,148.156906 1250.919556,150.542999 1251.869629,153.835815 
	C1252.468018,155.910339 1253.046021,157.959854 1253.913208,159.963791 
	C1255.327759,163.233109 1253.686401,165.229736 1250.270508,164.709259 
	C1241.292969,163.341354 1239.343018,165.323700 1240.645752,174.477753 
	C1240.691528,174.799408 1240.570801,175.131088 1240.228760,175.723999 
	C1237.570435,174.574020 1235.537842,173.674728 1232.794189,176.189377 
	C1231.094116,177.747650 1227.984375,177.767899 1225.253662,178.739563 
	C1221.022217,180.283264 1216.481567,180.442062 1213.747314,185.016174 
	C1215.639038,186.431290 1218.433594,186.996048 1219.888184,189.668716 
	C1219.675171,193.279358 1221.077026,194.685425 1224.304810,193.712250 
	C1227.063110,192.880569 1228.204590,194.256973 1227.901001,196.593323 
	C1227.494141,199.724823 1229.839478,203.574936 1225.385010,205.933960 
	C1224.314209,206.579636 1223.541748,206.732147 1222.342651,206.235138 
	C1220.645508,204.269287 1222.074341,202.484528 1221.887695,200.748856 
	C1221.669678,198.720398 1220.473267,197.237015 1218.380371,197.252060 
	C1216.535156,197.265289 1215.779297,198.907578 1216.084961,200.456268 
	C1216.753052,203.842178 1212.667847,208.522293 1218.710938,210.523544 
	C1218.995605,210.617828 1219.279419,211.673813 1219.220947,211.708755 
	C1214.513428,214.522842 1218.489868,216.173279 1219.596802,218.802963 
	C1220.539307,221.042099 1218.945557,224.301102 1215.504639,225.064896 
	C1212.607056,225.708130 1209.706543,226.338318 1206.403564,226.987137 
	C1201.045776,223.736557 1196.311768,225.674728 1191.288818,227.805252 
	C1188.894287,228.283401 1185.896240,225.873550 1184.860596,229.025406 
	C1184.033936,231.541473 1183.510376,234.543045 1185.885132,237.147064 
	C1187.610107,239.038528 1186.042114,242.439392 1183.607666,242.793900 
	C1177.978027,243.613708 1172.775146,239.698013 1171.991821,234.056473 
	C1171.843018,232.985611 1172.239136,231.340393 1170.459351,231.546982 
	C1168.497925,231.774612 1166.456177,232.286789 1165.025879,233.790222 
	C1164.075684,234.789093 1165.160400,235.565125 1165.793701,236.285004 
	C1166.865601,237.503387 1168.527954,238.573517 1167.517700,240.516693 
	C1166.578003,242.323853 1164.674683,242.130447 1163.066406,241.960831 
	C1158.752319,241.505844 1157.185913,243.228302 1158.309326,247.431244 
	C1158.942017,249.798264 1160.225220,252.953613 1156.768311,253.591980 
	C1153.554077,254.185532 1153.087036,251.034897 1152.872314,248.440369 
	C1152.846191,248.126785 1152.636108,247.828400 1152.255615,247.261475 
	C1150.429565,243.565598 1150.596802,239.003143 1145.180786,237.437500 
	C1147.854004,240.241531 1149.550659,242.957993 1148.227295,246.739365 
	C1147.958252,249.191742 1147.582153,251.218292 1144.687988,250.684937 
	C1143.192993,250.409439 1141.845215,249.661011 1140.806396,251.495544 
	C1139.768311,253.328751 1140.816284,254.667236 1141.696533,256.065948 
	C1142.526001,257.383850 1144.784302,258.503296 1143.374268,260.226349 
	C1142.056885,261.835999 1139.930054,261.015533 1138.308228,260.106720 
	C1135.583862,258.579987 1133.258789,258.767334 1131.226318,261.248962 
	C1130.914673,261.629425 1130.499878,262.177582 1130.106079,262.201202 
	C1122.896118,262.633179 1121.350708,269.059204 1118.010986,273.570099 
	C1116.048096,276.221344 1102.586548,277.219727 1102.226807,274.565308 
	C1101.539307,269.493286 1097.921753,270.204224 1095.038330,269.136444 
	C1094.053467,268.771759 1093.303833,268.317444 1093.056763,267.156891 
	C1092.824097,266.064453 1093.399780,263.923279 1091.118164,264.745300 
	C1089.662720,265.269653 1089.030518,266.651703 1089.260986,268.426514 
	C1089.801270,272.589264 1089.888794,276.778503 1092.474854,280.475616 
	C1093.461792,281.886566 1093.779663,284.337372 1092.021118,285.463470 
	C1089.765259,286.908051 1089.081055,284.527924 1087.986938,283.108398 
	C1085.457031,279.825958 1081.190063,278.344147 1079.608765,273.790771 
	C1078.821167,271.522583 1073.653442,273.199005 1073.064941,275.862396 
	C1071.802979,281.574890 1077.607666,289.165985 1083.386841,288.927765 
	C1087.597168,288.754181 1090.260742,289.721558 1090.035767,294.521790 
	C1089.950562,296.341156 1091.136230,297.266541 1092.673462,297.688599 
	C1095.299072,298.409576 1095.807495,295.978119 1096.804810,293.810394 
	C1100.035156,298.481262 1103.517700,301.250580 1108.794556,299.042053 
	C1110.351318,298.390564 1111.887817,297.316071 1111.765869,295.408783 
	C1111.625977,293.223694 1109.792847,293.093323 1108.051270,292.990997 
	C1103.120972,292.701202 1102.248413,288.614227 1101.064331,285.104401 
	C1098.957153,278.859100 1099.667725,278.406921 1106.351196,279.525146 
	C1110.280273,280.182526 1113.821533,282.183228 1117.915649,282.267456 
	C1119.756226,282.305328 1121.229004,283.428375 1120.544556,286.033508 
	C1119.444580,290.220490 1121.651245,289.684113 1124.277710,288.373779 
	C1126.161377,287.434021 1127.714722,285.517853 1130.145752,286.076263 
	C1131.702026,286.433716 1134.229004,286.407501 1134.288696,288.034088 
	C1134.361816,290.025146 1132.988037,292.305298 1130.661743,292.770294 
	C1128.766235,293.149170 1126.699097,292.875366 1124.737061,292.644745 
	C1123.418945,292.489838 1122.284546,290.316437 1120.811035,292.040100 
	C1119.426880,293.659302 1121.556519,294.655182 1121.869385,296.000732 
	C1122.061890,296.828735 1122.434814,297.562988 1123.969238,297.946472 
	C1125.015381,298.020752 1125.423340,298.018463 1126.139160,298.051788 
	C1126.854736,298.164307 1127.233521,298.319061 1127.791626,298.772308 
	C1131.367310,302.160126 1136.594238,304.523102 1132.707764,310.693451 
	C1132.150146,311.578735 1133.575684,311.794983 1134.198608,312.248169 
	C1136.292236,313.771057 1137.062988,315.898010 1136.246826,318.313507 
	C1135.597412,320.235321 1134.244385,322.399261 1132.276611,322.385803 
	C1130.042725,322.370544 1130.085693,319.851135 1130.016113,318.052521 
	C1129.845947,313.646484 1125.639648,313.524445 1123.155029,311.692230 
	C1120.804321,309.958740 1118.090210,309.125305 1120.549805,305.285767 
	C1121.905151,303.169983 1120.209229,301.971832 1117.884155,302.011780 
	C1114.125488,302.076355 1110.467773,303.789001 1106.568115,302.293671 
	C1105.232422,301.781433 1104.145752,303.291443 1104.107910,304.784851 
	C1104.066040,306.429901 1103.853027,308.289032 1104.501099,309.691620 
	C1106.320435,313.629822 1105.922729,316.476501 1101.873657,318.743408 
	C1099.561279,320.037964 1098.905518,322.444305 1098.936279,325.199890 
	C1098.989014,329.952667 1096.845825,335.343658 1099.325928,339.318085 
	C1103.351196,345.768677 1098.577637,346.571716 1094.906006,348.548798 
	C1093.880493,349.101074 1092.927490,349.652344 1092.248413,350.711273 
	C1087.504028,358.110199 1084.830688,358.482483 1078.776855,352.656189 
	C1077.489380,353.815979 1078.234375,355.077972 1078.605103,356.295410 
	C1079.287109,358.534454 1078.080688,359.857086 1076.284302,360.644348 
	C1074.251221,361.535278 1073.261719,360.330444 1072.631958,358.488647 
	C1072.380127,357.752014 1071.404175,356.528290 1071.257324,356.596802 
	C1066.334229,358.894440 1065.044800,352.435577 1061.065918,352.398987 
	C1059.960205,352.388794 1058.773804,351.236267 1059.288696,349.888702 
	C1060.009888,348.000885 1059.091309,346.773071 1058.240112,345.313629 
	C1057.249878,343.615631 1056.028320,341.681793 1058.235840,339.995422 
	C1059.328247,339.160858 1060.484009,337.434723 1062.085815,338.817444 
	C1063.765625,340.267578 1066.183350,341.439301 1065.706909,344.318695 
	C1065.451904,345.860260 1064.046509,347.860565 1066.265015,348.592346 
	C1068.585083,349.357635 1068.983032,346.651031 1070.059570,345.339905 
	C1071.378662,343.733154 1071.107788,342.152069 1069.185059,341.257965 
	C1066.559570,340.037109 1066.192383,336.298859 1062.669678,335.889862 
	C1061.319458,335.733154 1060.525879,333.557159 1061.251343,332.087769 
	C1062.589111,329.378326 1064.552368,327.002777 1067.593994,326.151184 
	C1069.805054,325.532196 1072.159180,325.410065 1074.456055,325.116760 
	C1076.957031,324.797394 1078.148682,325.512604 1077.758667,328.434814 
	C1077.048218,333.757385 1082.030151,336.614807 1083.703857,340.967896 
	C1084.178101,342.201172 1085.695679,341.697662 1086.669556,340.767883 
	C1087.848511,339.642181 1088.141846,338.235840 1086.753418,337.381226 
	C1082.862183,334.986115 1083.429321,332.645264 1086.122681,329.645599 
	C1089.045288,326.390686 1087.411743,324.918610 1083.682007,325.039795 
	C1081.166870,325.121552 1079.708130,323.355957 1077.816162,322.469635 
	C1073.713379,320.547638 1069.761230,318.292450 1065.620972,316.447662 
	C1064.763062,316.065430 1063.976807,316.554382 1063.386963,317.169586 
	C1061.812988,318.811188 1060.297119,319.364716 1058.161255,317.854279 
	C1056.191284,316.461212 1054.991455,318.137360 1054.414673,319.707031 
	C1053.720215,321.596863 1055.235840,321.988342 1056.670288,321.966431 
	C1058.923950,321.932037 1059.968506,322.797302 1060.041260,325.189209 
	C1060.113770,327.573151 1059.267090,328.825684 1056.969360,329.538727 
	C1051.525635,331.227966 1047.687622,329.404297 1044.934082,323.083160 
	C1043.650513,327.104736 1042.923462,330.681946 1043.002808,334.414642 
	C1043.053345,336.789734 1042.530151,338.184357 1039.567627,337.752258 
	C1035.789429,337.201233 1033.843750,339.579132 1035.596313,342.896698 
	C1039.290894,349.890717 1039.001831,357.142761 1037.983032,364.565735 
	C1037.428711,368.604523 1035.329712,369.592804 1032.097900,367.287048 
	C1028.749878,364.898376 1025.646240,365.543762 1023.456604,369.083862 
	C1020.667542,373.593140 1019.528870,373.838165 1016.157715,369.947327 
	C1014.693420,368.257355 1013.089294,366.829773 1011.207520,365.859070 
	C1008.215393,364.315552 1007.797363,362.322479 1008.881714,359.314941 
	C1009.463257,357.701843 1010.229309,356.685059 1011.785889,356.290314 
	C1013.691162,355.807098 1013.802979,357.521210 1014.460266,358.586456 
	C1014.805359,359.145905 1015.276550,359.659119 1016.134888,359.437866 
	C1018.311401,358.186005 1014.197571,354.525116 1017.649536,354.317719 
	C1020.355774,354.155090 1023.488831,354.310516 1025.306152,357.688354 
	C1026.641479,360.170441 1029.468384,359.046173 1031.651245,358.529846 
	C1033.246094,358.152618 1034.315674,357.022034 1033.533691,355.164001 
	C1032.582031,352.902924 1033.422363,349.435913 1029.603271,349.059265 
	C1028.282471,348.928955 1026.931885,348.907623 1025.610229,349.019073 
	C1022.828125,349.253632 1021.194275,348.114166 1020.609558,345.350922 
	C1020.370361,344.220825 1019.936584,343.117096 1019.475098,342.051727 
	C1018.270081,339.269806 1016.270996,336.898224 1013.143677,337.358276 
	C1009.700745,337.864777 1009.970703,341.083435 1009.997986,343.780334 
	C1010.009766,344.946198 1010.092285,346.121429 1009.977661,347.276062 
	C1009.810852,348.957733 1010.768494,351.297241 1008.164001,351.821472 
	C1005.675171,352.322388 1003.283447,352.194519 1001.446777,349.778168 
	C997.319946,344.348877 995.373657,344.405701 991.489685,350.097778 
	C990.347473,351.771729 988.989014,352.076233 987.247498,351.972046 
	C984.986267,351.836792 983.109375,350.686493 981.304443,349.532349 
	C976.485046,346.450714 971.254028,348.048065 969.395264,353.442261 
	C968.221619,356.848480 968.571045,361.204834 962.697998,360.506836 
	C961.013184,360.306610 960.634583,362.921814 961.489441,364.571350 
	C962.261536,366.061340 963.197266,367.428925 962.998413,369.604065 
	C956.790771,372.827179 953.087280,370.206421 952.900085,362.335327 
	C949.653992,363.260681 949.097778,366.172852 947.556885,368.695831 
	C946.394775,369.961761 945.300598,371.168243 944.019043,370.934692 
	C936.103699,369.492340 927.401978,371.435272 920.159729,365.338135 
	C919.830505,365.075714 919.417664,365.092163 919.212036,365.072235 
	C918.489319,364.454529 917.902588,363.928009 917.067139,363.241333 
	C916.819275,363.080200 916.411560,363.117310 916.207275,363.104126 
	C915.216309,361.888367 913.958191,361.624542 912.675415,361.258423 
	C909.296082,360.293884 908.520630,358.340149 910.759216,355.174896 
	C907.958801,358.115509 905.181946,357.198059 902.121582,355.272156 
	C900.817444,355.065765 899.794067,355.058167 898.387451,355.029968 
	C896.861267,350.500549 891.864319,351.984772 889.135498,349.278748 
	C887.163757,349.156799 885.716370,348.548615 884.170349,347.315430 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1113.620117,212.990234 
	C1113.734497,215.388428 1110.708740,217.372864 1113.311646,220.256393 
	C1114.393188,221.454681 1112.607056,222.298141 1111.625244,222.627747 
	C1109.333618,223.397064 1107.293945,226.437576 1104.519165,223.741837 
	C1103.551270,222.801468 1102.453735,221.232635 1101.378784,222.810913 
	C1097.898560,227.920410 1093.701904,224.350967 1089.389893,224.056946 
	C1087.446533,221.915237 1086.140503,219.592957 1082.824097,221.681168 
	C1081.391846,222.582977 1079.235840,222.331467 1078.508301,220.506989 
	C1076.761963,216.127304 1074.288208,218.199417 1071.516357,219.653931 
	C1070.997681,219.961456 1070.779663,220.069336 1070.210938,220.284485 
	C1065.235229,221.778519 1061.697266,219.081314 1058.466309,216.708725 
	C1055.781372,214.736984 1057.160889,211.806229 1059.606323,210.608795 
	C1064.097046,208.409927 1066.256470,205.242340 1066.918579,200.250809 
	C1067.490845,195.935638 1070.406128,195.086349 1074.233521,197.402847 
	C1075.080200,197.915268 1075.770508,198.712311 1076.805298,198.870697 
	C1077.821533,199.026230 1078.867310,199.013794 1079.429077,197.977325 
	C1079.877441,197.150253 1079.387329,196.438736 1078.928833,195.698547 
	C1074.915649,189.221176 1074.987427,188.542618 1080.325562,182.756195 
	C1085.005127,180.284149 1088.076904,175.848724 1093.601074,174.781647 
	C1095.922607,174.324112 1096.736938,172.351654 1098.392578,171.546219 
	C1100.631714,170.456955 1102.876343,170.151932 1105.061157,171.508270 
	C1107.351196,172.929901 1107.166260,175.138748 1106.480591,177.296234 
	C1105.488403,180.418076 1106.027832,183.589691 1106.067383,186.726318 
	C1106.106934,189.876251 1104.778442,191.748917 1101.848022,192.673309 
	C1099.462524,193.425797 1097.065918,194.098755 1094.605591,194.585663 
	C1090.332397,195.431290 1088.245483,199.704010 1090.117065,203.602936 
	C1091.322021,206.113327 1092.777832,204.710648 1094.137207,203.807724 
	C1096.398315,202.305756 1098.010010,199.404755 1101.721680,200.744568 
	C1102.155151,207.388092 1105.639160,211.101501 1113.620117,212.990234 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1122.030762,44.147907 
	C1127.150269,42.271347 1131.526978,39.716846 1136.366577,36.343754 
	C1136.354614,39.337734 1134.244019,41.026840 1135.613037,43.255135 
	C1137.116577,45.702560 1139.204590,43.985352 1140.808960,44.508945 
	C1144.501465,45.713940 1146.962769,48.661877 1146.897827,52.674557 
	C1146.828613,56.937122 1147.675415,60.240604 1151.319336,63.052906 
	C1154.279907,65.337883 1153.401245,69.898369 1149.954712,71.749123 
	C1148.388306,72.590302 1147.300049,73.490501 1146.471924,75.034447 
	C1145.708130,76.458427 1146.772583,79.296875 1144.919556,79.253708 
	C1142.848877,79.205452 1140.331421,78.214134 1138.932617,76.249680 
	C1137.620972,74.407646 1136.237793,73.168564 1133.905273,72.634338 
	C1131.623169,72.111626 1132.043213,69.801407 1132.000732,68.009743 
	C1131.932129,65.116142 1130.946167,63.465569 1127.693115,64.598930 
	C1126.058472,65.168449 1124.366333,65.230049 1122.852295,64.358765 
	C1120.269531,62.872410 1118.020386,62.685535 1115.683350,64.987488 
	C1114.463501,66.188957 1112.597168,66.464218 1111.090820,65.242088 
	C1109.604126,64.035736 1110.057129,62.204460 1110.031982,60.567795 
	C1110.001709,58.598141 1111.221069,58.086014 1112.927490,57.963112 
	C1117.203979,57.655106 1118.615601,54.387276 1119.796631,51.022556 
	C1120.559448,48.849701 1121.089111,46.595009 1122.030762,44.147907 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1235.377808,109.311386 
	C1233.932373,108.315407 1232.204102,108.131096 1232.304565,106.456123 
	C1232.409424,104.707397 1234.239258,104.924881 1235.365601,104.421661 
	C1236.096802,104.095001 1236.976318,104.055168 1237.797729,103.984413 
	C1239.740845,103.817032 1240.940430,102.917847 1240.878418,100.853912 
	C1240.824341,99.048820 1239.660034,98.424171 1238.012085,98.006897 
	C1232.044800,96.495804 1229.092529,91.979568 1230.044434,86.058838 
	C1230.340820,84.215233 1230.801392,82.995850 1233.289429,83.794968 
	C1240.617188,86.148582 1242.772583,84.852448 1245.125610,77.303177 
	C1245.517578,76.045929 1246.166870,74.868866 1246.848633,73.327072 
	C1247.496094,72.741837 1247.992310,72.484268 1248.744141,72.113350 
	C1251.512817,72.694466 1253.048828,74.581627 1254.766724,76.765747 
	C1255.000000,78.388870 1255.000000,79.777740 1255.000000,81.583267 
	C1257.349854,85.021935 1258.026855,88.691925 1258.986572,92.628647 
	C1259.000000,94.066483 1259.000000,95.133224 1259.000000,96.599976 
	C1259.761597,102.164566 1258.345703,104.462425 1253.441040,105.389351 
	C1251.730347,105.712669 1250.225342,105.954018 1249.218140,107.998749 
	C1248.665894,108.713333 1248.245605,108.994698 1247.375854,109.083351 
	C1245.933350,109.000549 1244.866577,109.000481 1243.399902,109.000671 
	C1240.735352,110.959198 1238.317017,111.124977 1235.377808,109.311386 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1121.001465,157.390839 
	C1121.000000,155.284790 1121.000000,153.570801 1121.000000,151.428406 
	C1123.199951,145.213821 1126.369507,143.700577 1133.032715,145.028168 
	C1135.319458,145.483765 1135.961182,146.863220 1135.995361,148.913330 
	C1136.075317,153.704758 1135.479492,158.557083 1137.451172,163.180923 
	C1137.716919,163.803848 1137.454468,164.848099 1137.091187,165.506744 
	C1135.929932,167.612000 1125.485107,168.512558 1124.425049,166.434021 
	C1123.026855,163.692383 1121.008301,161.105209 1121.001465,157.390839 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1102.270020,200.864990 
	C1098.381470,200.696152 1097.050537,203.912552 1094.785645,205.690674 
	C1093.488525,206.709030 1092.180786,208.105759 1090.410034,207.445892 
	C1088.480957,206.726944 1088.097656,204.913071 1087.974243,202.946472 
	C1087.457397,194.702301 1087.617310,194.494461 1095.876465,192.803436 
	C1097.815430,192.406433 1099.644043,191.407196 1101.588135,191.112106 
	C1104.736694,190.634186 1105.488159,188.689362 1104.767456,186.124924 
	C1103.780762,182.614868 1103.607178,179.308060 1105.427246,176.018860 
	C1106.148682,174.714890 1106.045776,173.235855 1104.618042,172.568878 
	C1102.586182,171.619629 1100.443726,171.599503 1098.519897,173.080246 
	C1097.262207,174.048355 1096.815918,176.076614 1094.369873,175.988068 
	C1092.396362,173.761459 1093.196777,171.648773 1094.669556,169.277039 
	C1099.024536,168.999924 1103.049072,168.999847 1107.536865,168.999573 
	C1108.000000,169.749542 1108.000000,170.499710 1108.000000,171.624939 
	C1108.579590,172.685699 1108.732178,173.458221 1108.235352,174.657471 
	C1108.630981,180.760162 1106.992676,186.387711 1107.000000,192.555908 
	C1107.134033,194.998260 1106.739502,196.849762 1105.243652,198.731644 
	C1104.179932,199.576248 1103.359985,200.153122 1102.270020,200.864990 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1160.451416,163.741699 
	C1161.387451,166.969635 1162.021362,169.918900 1165.934448,170.243835 
	C1166.962280,170.329178 1167.710083,171.172424 1167.698364,172.320633 
	C1167.685181,173.624084 1166.691040,174.000488 1165.660767,173.935623 
	C1160.397827,173.604294 1155.617188,175.608459 1150.645508,176.780243 
	C1147.624268,177.492294 1144.417969,177.307053 1141.683228,175.366852 
	C1141.540527,174.080429 1142.914795,174.283752 1143.198730,173.722183 
	C1145.653198,168.866348 1148.665771,166.403595 1154.299438,169.666565 
	C1155.921265,170.605942 1156.787109,168.109238 1157.618774,166.697845 
	C1158.315918,165.514755 1157.292236,162.877853 1160.451416,163.741699 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1108.383789,175.010513 
	C1108.000000,174.250000 1108.000000,173.500000 1108.000000,172.375000 
	C1119.291748,172.000015 1119.792969,172.760941 1120.106079,184.584412 
	C1120.233887,189.414719 1119.194458,191.979721 1113.443359,190.999985 
	C1111.609375,189.741669 1111.661865,188.294067 1112.721436,186.375473 
	C1112.843140,183.415924 1113.427246,180.776093 1112.538574,178.251724 
	C1111.917725,176.488739 1110.965210,174.997864 1108.383789,175.010513 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1254.991577,76.630646 
	C1252.345459,76.496666 1250.765015,74.538651 1249.062134,72.331055 
	C1249.456909,69.657295 1250.821533,67.329918 1248.734009,65.014236 
	C1247.946289,64.140411 1248.387817,63.059444 1249.363525,62.519897 
	C1250.732788,61.762726 1252.244995,61.770672 1253.622437,62.507854 
	C1254.604248,63.033241 1255.097290,64.160400 1254.383789,65.012779 
	C1251.043091,69.004585 1253.438110,72.590416 1254.991577,76.630646 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1113.048340,18.132605 
	C1115.688599,21.557848 1114.555908,26.529720 1111.226074,28.131697 
	C1110.033691,28.705341 1109.111816,28.476202 1109.142700,27.190899 
	C1109.224243,23.784204 1107.477661,19.568686 1113.048340,18.132605 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1259.287598,92.849976 
	C1255.161133,90.577377 1256.324463,86.206367 1255.012207,82.397415 
	C1258.146851,83.352715 1259.476685,87.518059 1263.755249,87.748650 
	C1262.525146,89.566261 1261.050171,91.133232 1259.287598,92.849976 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1215.801514,103.061302 
	C1213.624756,104.519089 1212.084839,103.926544 1211.102295,102.049072 
	C1210.128418,100.188293 1209.122803,98.255577 1209.360718,96.057732 
	C1209.430298,95.415207 1210.179932,95.170189 1210.917725,95.226883 
	C1213.122803,95.396332 1216.302979,100.075394 1215.801514,103.061302 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1126.832275,201.651917 
	C1128.133545,205.245178 1125.588379,206.128738 1123.342407,206.820892 
	C1121.803955,207.295013 1120.460449,206.359909 1120.170776,204.587341 
	C1119.901123,202.937149 1120.479980,201.634094 1122.203491,201.210358 
	C1123.638062,200.857651 1125.115967,200.843353 1126.832275,201.651917 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1172.575928,73.611542 
	C1171.508301,74.144524 1170.767700,74.657631 1169.979614,74.746063 
	C1168.208984,74.944710 1166.685059,74.202087 1165.696655,72.757492 
	C1165.050171,71.812691 1165.866455,71.189804 1166.718750,70.995605 
	C1168.734863,70.536156 1170.440186,68.222969 1172.725342,69.617950 
	C1174.326538,70.595390 1174.162354,72.078735 1172.575928,73.611542 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1105.391357,198.998199 
	C1105.165039,197.054443 1104.414795,194.886948 1106.683594,193.188507 
	C1109.123779,193.362747 1111.082275,194.423462 1110.633179,196.700226 
	C1110.183594,198.979294 1107.751343,198.918930 1105.391357,198.998199 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1161.416260,93.640160 
	C1159.557129,94.063934 1157.948486,94.082855 1157.489746,92.294220 
	C1157.110840,90.816994 1158.401489,89.950653 1159.590210,89.392868 
	C1160.848022,88.802612 1162.273682,88.808098 1163.142456,89.987968 
	C1164.410522,91.709946 1162.827759,92.561043 1161.416260,93.640160 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1173.546875,55.117794 
	C1175.044434,55.689724 1176.103760,56.336578 1175.303223,57.761185 
	C1174.532593,59.132515 1173.105225,59.048969 1171.777954,58.877304 
	C1170.609619,58.726154 1170.048950,57.929924 1170.179810,56.796551 
	C1170.393188,54.949032 1171.837402,55.059868 1173.546875,55.117794 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1112.638672,186.056900 
	C1113.000000,187.388885 1113.000000,188.777786 1113.000000,190.583344 
	C1112.016968,191.310455 1110.715454,192.343155 1110.231445,190.712265 
	C1109.666992,188.809509 1109.839111,186.860062 1112.638672,186.056900 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1127.862793,77.053345 
	C1127.266235,79.082664 1126.065430,79.337296 1124.783936,78.421089 
	C1123.787964,77.708954 1124.114868,76.592720 1124.934326,75.894310 
	C1126.192993,74.821625 1127.320557,74.852776 1127.862793,77.053345 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1131.739014,136.467331 
	C1131.482300,135.460754 1131.983521,135.147324 1132.638794,135.274216 
	C1132.943848,135.333298 1133.429077,135.904663 1133.372192,136.124313 
	C1133.186890,136.838181 1132.559570,136.857040 1131.739014,136.467331 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M358.657318,148.712891 
	C359.541962,149.380692 360.115936,149.723358 360.620239,150.148712 
	C362.671082,151.878555 364.872589,154.089874 367.636963,152.360504 
	C370.565674,150.528320 370.159668,147.146881 369.908203,144.164047 
	C369.690369,141.580292 367.577850,141.236023 365.532684,141.000122 
	C362.614502,140.663498 362.539856,139.103088 364.240753,137.371490 
	C368.840759,132.688446 372.172333,126.698303 378.433960,123.704201 
	C381.167908,122.396889 381.588867,119.623627 380.899506,117.230392 
	C379.390717,111.992371 381.832703,109.896515 386.432770,108.933640 
	C386.968018,108.821602 387.379608,108.118942 387.924866,107.344757 
	C389.332764,104.346245 387.752075,103.084549 385.324219,102.036209 
	C384.195343,101.752403 383.389709,101.505592 382.292023,101.128624 
	C380.537170,98.925362 381.556091,97.578392 383.414062,96.668709 
	C385.719604,95.539894 388.148224,95.642693 389.675201,97.777481 
	C391.886383,100.868851 394.561859,103.547585 396.845825,106.546722 
	C398.591248,108.838676 399.208954,111.818733 396.489349,113.536453 
	C393.568665,115.381180 392.771393,117.282875 393.869720,120.413376 
	C394.375580,121.855301 393.916840,123.349144 392.721375,124.909988 
	C391.472290,125.819046 390.461700,125.735184 389.094513,125.213776 
	C385.287109,122.567924 384.353363,125.356369 384.647552,127.637939 
	C385.225128,132.116974 384.130585,135.057434 379.584656,136.351929 
	C378.352112,136.702911 377.993286,137.894653 379.063171,138.557098 
	C380.805756,139.636063 383.753448,141.519760 384.699585,139.229309 
	C386.714172,134.352478 389.794464,132.711121 394.701050,133.211655 
	C395.678650,133.311386 396.320618,132.160049 396.993713,131.435486 
	C398.552673,129.757294 400.574249,129.367371 402.451599,130.327805 
	C405.699677,131.989487 408.952332,132.149933 412.700500,131.422623 
	C414.018188,132.001297 414.056976,133.527954 415.378082,133.672882 
	C415.572662,132.640137 414.664551,132.340378 413.981018,131.481430 
	C413.661682,130.972763 413.580811,130.739029 413.506653,130.129333 
	C413.850189,128.424622 414.705658,127.542915 416.262939,126.839081 
	C420.142853,126.657196 420.686188,122.111809 424.387390,121.742897 
	C425.216705,121.728310 425.681885,121.790390 426.461914,122.021034 
	C428.320465,122.793701 429.775818,125.018974 431.740845,122.187317 
	C434.912842,122.821381 434.284851,125.149445 433.591064,126.856796 
	C432.862885,128.648834 431.091064,127.879730 429.672760,127.313637 
	C428.469360,126.833344 427.122040,125.897087 426.319427,127.774879 
	C425.652344,129.335602 426.309448,130.623032 427.390686,131.846558 
	C432.142731,137.224152 431.299164,136.168350 426.070709,140.183701 
	C419.847992,144.962616 413.450531,143.624802 406.925690,144.002960 
	C405.153046,144.105682 402.795624,143.582779 402.395691,145.988098 
	C401.972290,148.534317 404.816681,148.060715 406.028870,149.102463 
	C410.985748,153.362396 416.913147,153.703445 422.947571,154.125839 
	C425.410248,154.298187 425.179199,156.264938 424.467163,157.906937 
	C422.525848,162.383575 420.118561,166.710464 425.153198,170.950058 
	C426.561310,172.135803 425.402405,173.907593 423.996307,174.617111 
	C422.483063,175.380707 420.912750,174.853836 420.363251,173.131714 
	C419.971008,171.902481 420.081970,170.508713 419.995575,169.186584 
	C419.630676,163.601791 418.859985,163.348038 413.485901,163.534241 
	C409.843842,163.660446 406.334869,161.875046 405.050751,158.008621 
	C404.103271,155.155823 403.866486,153.471024 400.217407,155.885834 
	C397.492920,157.688766 393.781860,159.660614 390.097260,156.366760 
	C392.439423,153.650665 388.943024,151.588058 389.918823,149.078491 
	C391.771729,147.200058 394.477814,145.878799 391.493042,142.557648 
	C391.890045,144.882217 391.898254,146.735153 390.416382,148.557404 
	C388.505615,149.926758 387.304352,151.322006 387.233276,153.754196 
	C384.429474,153.818161 382.644623,154.191208 383.867584,157.532104 
	C384.483002,159.213333 384.503967,161.102692 384.999390,162.839264 
	C386.129517,166.800415 388.689026,167.725464 392.030792,165.285156 
	C393.768799,164.015991 395.282104,162.404022 397.530823,162.111313 
	C399.210297,161.892715 401.041931,161.854416 401.664124,163.877609 
	C402.232208,165.724823 400.950562,166.643265 399.353394,167.018051 
	C396.530884,167.680344 395.691589,169.543823 396.000305,172.595856 
	C394.553284,173.679428 393.472198,172.725937 392.308380,172.164505 
	C391.260437,171.658936 390.208130,170.943283 389.168579,172.710815 
	C387.384064,175.093094 388.666229,176.711151 389.937134,178.671005 
	C385.912689,180.733063 382.911530,178.631561 379.812683,175.854095 
	C378.681000,173.374664 380.541992,172.340103 381.401031,170.580048 
	C377.965027,173.654617 371.702454,171.353668 369.627594,177.418625 
	C367.894073,177.965805 366.596222,177.325363 365.182434,177.055832 
	C366.239105,177.640076 367.416077,177.621780 368.758026,178.227173 
	C370.765289,180.065643 373.062286,178.905670 375.220154,179.860077 
	C375.850006,180.738983 375.953644,181.426804 375.779663,182.487579 
	C371.167603,186.187408 373.076324,192.050095 370.393463,196.473328 
	C369.499603,198.192627 369.612305,199.524536 370.794861,201.067169 
	C371.572510,203.965576 371.916748,206.709076 374.577209,208.743164 
	C375.828583,211.617981 376.085846,213.815826 371.964966,214.021606 
	C371.090515,213.828171 370.635254,213.622192 369.935852,213.131821 
	C368.611053,212.789810 368.502899,209.507141 366.624817,212.334747 
	C366.643372,214.131744 366.711395,215.622543 367.208923,217.391052 
	C367.428314,220.356079 369.601898,221.962448 370.673096,224.509415 
	C369.352997,229.723068 372.688599,229.513931 376.073395,229.766495 
	C377.166626,231.509018 376.676910,232.975693 375.864136,234.218994 
	C373.542664,237.770096 374.943726,239.984055 378.171814,241.885254 
	C379.973694,242.946457 382.471283,244.271805 381.147583,246.801941 
	C379.843323,249.294876 377.573669,251.133606 374.226318,250.404480 
	C370.956879,249.692322 369.776672,250.815796 370.122375,254.153671 
	C370.775452,260.458679 370.786224,260.562531 364.382080,261.226837 
	C361.291718,261.547394 359.447723,263.659485 357.167419,265.262421 
	C354.920990,266.841553 352.338562,268.979797 350.490723,264.425354 
	C351.565521,262.669434 351.483948,260.979401 351.713623,259.386597 
	C351.844513,258.479095 351.988678,257.148621 350.537628,257.228821 
	C349.207703,257.302338 349.243744,258.411530 349.564545,259.439758 
	C349.992096,260.810120 351.031616,262.043365 350.349640,263.945312 
	C344.288666,267.761292 342.036041,267.087921 336.989288,260.309265 
	C338.974548,264.017914 336.858337,266.043793 335.199677,269.138702 
	C332.895020,273.438873 331.746094,278.706329 329.683044,283.419281 
	C329.452942,283.944977 329.527283,283.278687 329.810730,283.866974 
	C330.702118,285.717255 332.873627,287.523468 330.646820,289.609406 
	C328.228271,291.874908 325.334839,290.721985 322.267731,289.932068 
	C321.528595,289.539001 321.158173,289.239899 320.615845,288.605042 
	C319.323547,286.126465 320.126801,284.209991 321.248077,282.318665 
	C322.408752,280.360840 322.338043,278.598236 320.035400,277.018066 
	C318.317535,275.412598 316.574066,274.671326 314.235596,274.513794 
	C313.448303,274.518250 312.999420,274.575928 312.228149,274.803162 
	C304.271057,282.513611 294.758057,283.020325 284.798126,282.517792 
	C281.405731,282.346649 277.441681,281.402740 274.567780,285.169617 
	C273.856049,287.146851 271.536041,288.545898 273.848145,290.849792 
	C275.723877,292.858307 277.646454,294.156311 280.341858,294.629944 
	C280.583679,294.733124 280.972137,295.026276 280.972137,295.026276 
	C280.972137,295.026276 280.884491,294.931915 280.676941,294.779572 
	C278.458832,293.377228 275.923401,293.090942 273.994141,290.984558 
	C273.394867,289.994232 273.564056,289.332672 274.341522,288.483093 
	C277.049591,287.149994 279.632294,288.021698 282.481201,287.597382 
	C283.357727,287.587189 283.856537,287.601501 284.722473,287.674866 
	C288.510010,290.583099 292.621826,292.945618 292.601624,298.581146 
	C291.461090,300.322357 290.321899,301.634460 289.241577,303.443665 
	C287.126862,304.781281 285.170685,305.255859 282.698608,304.823914 
	C279.412262,300.799805 276.351807,303.493256 273.085632,304.527740 
	C275.013153,307.146942 278.084229,305.597198 280.474365,307.112122 
	C281.521698,308.780670 281.645782,310.300964 281.524017,312.248291 
	C281.443451,313.410858 281.479431,314.190643 281.281006,315.338867 
	C280.950867,316.686951 280.455414,317.575928 280.080261,318.887878 
	C276.906830,322.518433 276.346222,318.463593 274.377075,317.230682 
	C273.903564,320.834534 274.103241,324.687714 270.153381,326.502563 
	C269.427917,326.835938 269.758759,327.711945 270.216064,328.227692 
	C273.093475,331.473022 270.777283,333.579559 268.682831,335.866852 
	C265.227417,339.640472 261.508057,342.816589 255.634949,342.640625 
	C253.410568,341.629303 252.465652,342.898804 251.259186,344.404449 
	C247.441635,345.347076 247.255539,348.917450 245.477127,351.135590 
	C244.954590,351.787292 245.147232,352.256317 245.736725,352.794739 
	C250.595459,357.232635 246.733871,361.290558 244.921677,365.204742 
	C243.037323,369.274811 239.125854,370.235840 235.197205,369.114929 
	C231.255737,367.990387 228.151703,370.457703 224.237915,370.665680 
	C222.367386,370.734131 220.931366,370.380371 219.616776,369.612762 
	C216.313934,367.684204 215.934052,365.647797 218.177856,362.048248 
	C217.731461,361.499969 217.059158,361.384491 216.449905,361.154602 
	C214.576172,360.447662 213.323395,359.203796 213.370728,357.111877 
	C213.414948,355.157043 214.867142,354.238770 216.477417,353.647308 
	C218.840195,352.779572 220.407318,351.713501 218.432281,349.010315 
	C216.792633,346.766205 216.505295,344.441986 218.490189,342.212921 
	C219.758728,340.788300 221.305176,339.719452 223.293503,340.281586 
	C226.161438,341.092438 226.799240,343.518188 227.094193,346.098785 
	C227.167313,346.738403 227.069794,347.452087 228.242813,348.151825 
	C227.599869,343.410309 231.135956,342.689941 234.465210,341.406433 
	C234.725754,341.316437 235.114517,341.175385 235.114517,341.175385 
	C235.114517,341.175385 235.133499,340.770905 234.896576,340.665161 
	C233.424850,340.058624 232.160263,339.618286 230.978104,339.005005 
	C229.018692,337.988495 226.914078,336.878723 227.568832,334.179352 
	C228.154877,331.763184 230.210114,330.885712 232.493744,330.612091 
	C235.162003,330.292358 237.807068,330.440521 240.424393,331.125671 
	C241.507217,331.409119 242.626465,331.988037 243.755753,331.207947 
	C241.478424,326.584076 240.376724,326.098572 236.143494,328.045013 
	C234.882690,328.624756 233.638565,329.207428 232.374512,328.376984 
	C230.838226,327.367706 231.055984,325.727051 231.474228,324.297943 
	C232.277664,321.552612 232.463333,318.836945 232.377899,315.960541 
	C232.272903,312.425232 235.674652,308.967834 239.059082,309.226257 
	C244.814056,309.665680 251.321854,307.798309 255.584488,313.841034 
	C256.113586,314.591064 257.068756,315.407013 258.135284,314.511871 
	C259.055054,313.739899 259.876770,312.621460 259.246765,311.459015 
	C257.698242,308.601746 258.133179,306.591522 260.920502,304.705139 
	C262.569153,303.589325 261.444794,301.891357 260.396149,300.843994 
	C258.825592,299.275330 257.155457,300.078735 256.112732,301.501465 
	C253.520355,305.038605 250.301880,306.691223 245.832474,306.193726 
	C239.410049,305.478851 236.160950,298.621155 239.943542,293.282104 
	C242.531204,289.629700 244.075226,285.268738 247.580734,282.233429 
	C249.164261,280.862305 248.476547,278.788422 248.852249,277.041138 
	C249.067825,276.038391 249.406448,275.081421 250.465698,274.690155 
	C251.545853,274.291168 252.591187,274.698212 253.091461,275.577942 
	C254.448380,277.964081 254.841187,280.878235 257.871613,282.218292 
	C260.722626,283.478973 260.878540,286.821472 262.463898,289.504700 
	C265.159851,287.575043 265.871674,284.774353 266.564453,281.875854 
	C265.422668,282.740906 265.345337,284.835968 263.364868,284.655853 
	C261.512512,284.487335 261.020508,283.020081 260.398682,281.660370 
	C260.260162,281.357452 260.150970,281.038483 259.984192,280.751434 
	C255.290253,272.672974 255.353653,272.724945 260.844574,265.887482 
	C262.825836,263.420319 264.864899,261.640991 267.956024,263.698944 
	C270.564026,265.435242 270.338104,269.367462 267.703766,272.240906 
	C267.839813,273.090912 268.650055,273.159393 269.198547,273.300568 
	C271.100250,273.789978 272.561127,277.387390 274.576965,275.099701 
	C276.314667,273.127655 275.722382,269.983215 274.651917,267.455750 
	C273.949036,265.796295 272.299103,264.630493 271.878204,262.383484 
	C271.847321,261.539124 271.921387,261.063721 272.200684,260.266846 
	C274.628906,257.373932 277.018158,256.196991 279.787079,259.317841 
	C280.371094,259.976135 281.072113,260.230652 281.992249,259.852203 
	C288.763336,257.067413 293.924683,260.591339 298.706451,264.544586 
	C300.837799,266.306610 302.728638,266.790771 304.944427,265.571228 
	C307.456329,264.188690 307.426575,261.920593 306.419983,259.540039 
	C305.560303,257.506836 303.949585,256.057159 302.244202,254.242065 
	C301.052765,248.284302 298.003448,252.241623 296.064026,252.580734 
	C298.131683,251.684998 300.324127,251.683990 302.269928,254.285858 
	C303.810974,255.907684 304.707306,257.496368 305.391052,259.200470 
	C306.127777,261.036560 306.175140,262.829620 304.421356,264.139160 
	C302.680664,265.438934 300.672119,265.111481 299.420715,263.706238 
	C297.449554,261.492706 294.950836,261.300659 292.380127,260.635437 
	C288.958435,259.749969 285.764923,258.109558 285.527405,253.376556 
	C285.537628,251.600235 285.388031,250.220963 286.108215,248.559814 
	C286.798767,247.534668 287.548981,246.869461 288.289001,247.013519 
	C292.717621,247.875748 295.527496,245.425003 298.451965,242.273865 
	C300.159515,241.555664 301.602539,241.522339 303.400726,241.602875 
	C304.346985,241.628830 304.964569,241.722382 305.569885,241.038086 
	C302.506989,233.759766 303.225677,232.099335 310.362976,228.860794 
	C313.509552,227.433014 316.155029,225.088715 319.224060,223.503113 
	C321.044861,222.562393 323.093079,221.030670 324.701843,224.326996 
	C324.497528,226.507828 324.371796,228.334396 325.689453,229.856598 
	C328.650299,233.277054 328.293274,236.072891 323.722992,238.242355 
	C326.796753,238.778290 328.007507,237.427475 328.440125,235.082428 
	C328.828766,232.975845 329.009552,230.809921 330.685333,228.808441 
	C331.413422,228.409195 331.878174,228.334091 332.694458,228.493286 
	C334.589142,229.983063 334.352020,231.888718 334.555603,233.673035 
	C335.118225,238.604126 337.523224,240.232376 341.897705,238.180954 
	C345.117188,236.671143 348.063385,236.126068 351.572876,238.850388 
	C350.431671,236.745560 348.818085,236.441467 347.546051,235.139923 
	C346.659454,233.705444 346.604858,232.471344 346.900208,230.837646 
	C347.514648,229.118347 348.323608,227.895782 348.985596,226.211395 
	C350.761505,223.104416 349.174927,219.188309 352.583923,216.726761 
	C353.334961,216.368134 353.800751,216.297516 354.619385,216.429382 
	C356.973724,217.697388 358.060944,222.086380 360.540405,220.195511 
	C362.980804,218.334473 358.893280,216.295715 358.241577,213.785461 
	C357.886230,212.941452 357.754211,212.359451 357.482849,211.564117 
	C357.217102,211.035568 356.963867,210.864960 356.353577,210.882874 
	C353.258240,212.447662 350.122925,212.453629 347.054016,212.837509 
	C345.643951,213.013901 343.618683,212.462265 342.982513,213.817581 
	C342.090698,215.717667 344.575745,216.292496 345.563416,217.900360 
	C346.166351,219.133713 346.203339,220.088837 345.832611,221.396729 
	C343.537323,224.099411 341.071503,223.422729 338.538727,222.501587 
	C338.921082,225.801193 337.111633,227.410202 333.706299,228.006424 
	C331.961761,227.768326 330.558350,227.658905 328.798645,227.615540 
	C327.794983,227.092972 327.437286,226.487579 327.277039,225.380493 
	C327.876801,222.576981 326.654724,220.487076 325.972961,218.288635 
	C324.161987,212.448776 325.541809,210.838776 331.407684,212.053116 
	C331.948334,209.608536 331.925629,206.768066 335.643005,205.891586 
	C333.300201,206.195724 331.659515,207.082489 329.886627,207.511002 
	C326.267090,208.385849 324.263336,207.177567 323.698578,203.825989 
	C323.069672,200.093842 324.551483,198.291733 328.349518,197.896667 
	C332.261353,197.489746 335.892548,198.286682 339.516754,199.732101 
	C341.119324,200.371231 343.491730,202.539276 344.638824,199.896820 
	C345.674103,197.511887 344.611816,195.029587 342.556244,192.787659 
	C337.050629,186.782944 330.452545,189.011826 323.994751,189.671539 
	C327.372894,195.480331 326.377319,197.700089 319.968353,197.742767 
	C313.129364,192.076141 311.620270,185.433731 315.044159,177.572983 
	C316.182190,174.960297 318.241150,173.729553 320.975800,173.855377 
	C324.140045,174.000977 325.164398,172.452301 324.806824,169.189178 
	C324.617554,166.147842 325.109650,163.864380 328.554260,163.121155 
	C329.198120,163.172974 329.447571,163.229950 330.031464,163.456665 
	C331.485901,164.872131 332.760071,165.908020 334.285706,166.634201 
	C338.582733,168.679581 340.500031,167.790588 340.943298,163.073090 
	C341.384399,158.378525 344.111206,158.255096 347.933380,159.328506 
	C352.060974,165.451447 352.366699,171.051514 348.623352,175.267044 
	C351.434631,174.202942 353.538147,172.961990 355.934814,172.394897 
	C360.723724,171.261734 362.288055,166.556015 359.635101,162.349655 
	C357.586761,159.101913 354.470490,157.289474 350.917969,155.328995 
	C348.832764,153.020081 347.699524,150.465683 349.669739,148.352493 
	C352.248322,145.586777 355.429047,146.896271 358.657318,148.712891 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M518.958862,155.655975 
	C516.726196,156.412857 515.938477,151.080154 513.516174,154.080109 
	C511.670898,156.365402 513.993713,159.074783 515.266174,161.322586 
	C516.361450,163.257294 518.077820,163.145737 520.002075,162.371948 
	C527.241150,159.461014 528.871033,160.442688 529.998230,168.596283 
	C529.728088,169.708954 529.207153,170.199554 528.271118,170.774292 
	C527.294922,171.222244 526.605591,171.396851 525.572510,171.618927 
	C519.991089,177.273926 519.994263,177.270645 514.228394,171.743637 
	C513.016602,170.582062 511.539520,169.850342 510.054779,169.083755 
	C505.148102,166.550308 504.653229,165.276901 506.365082,160.015900 
	C506.832306,158.580017 507.568695,157.076447 506.458313,155.772522 
	C505.213928,154.311234 503.415039,154.734482 501.791504,155.066086 
	C500.674316,155.294266 499.302856,155.506531 499.059570,156.851227 
	C498.847595,158.022720 500.105896,158.363907 500.869171,158.959732 
	C505.426941,162.517303 504.561340,168.498795 498.981659,170.452713 
	C490.627625,173.378204 482.178284,175.979187 473.582642,170.974670 
	C471.036865,169.492493 468.776733,170.234360 467.023956,172.525482 
	C466.305054,173.465179 466.562500,174.403214 467.262787,175.239548 
	C467.787933,175.866730 468.330505,175.646133 469.015564,175.357071 
	C475.658173,172.554398 480.469147,174.425674 483.557739,181.243835 
	C483.862427,182.586960 483.646301,183.504944 483.183411,184.425415 
	C481.087738,188.592438 481.101562,188.599396 486.342194,189.175323 
	C486.848022,189.457245 487.029907,189.605347 487.364166,190.071594 
	C487.757629,191.713074 486.822174,192.467712 486.125061,193.364166 
	C484.899048,194.940781 484.739960,196.910721 485.639771,198.443726 
	C486.527374,199.955978 488.328369,198.710266 489.843231,199.001587 
	C490.415497,196.571686 486.579529,194.464157 489.972321,192.105347 
	C491.322906,191.655426 492.322174,191.730148 493.661377,192.236420 
	C495.346497,193.302673 495.556244,194.930283 496.430267,196.139008 
	C498.596680,199.135056 498.593750,201.970444 495.514954,204.467575 
	C494.309265,205.445511 493.745453,206.855499 493.274414,208.692551 
	C492.472473,210.799744 492.413879,212.457062 493.072540,214.332672 
	C494.208313,217.566971 492.284424,218.348495 489.690308,217.469772 
	C487.345001,216.675323 485.120972,215.541489 482.233612,215.249634 
	C479.331146,214.197754 477.483429,212.503250 476.318085,209.632309 
	C476.743042,204.379684 471.799408,203.142914 469.847870,199.882706 
	C469.145386,198.709167 468.076263,199.657837 467.731873,200.710968 
	C466.361847,204.900375 464.058258,208.910492 464.914642,213.566864 
	C465.538544,216.959259 465.332886,220.134094 463.151550,222.989471 
	C462.409576,223.960739 462.400238,225.105209 462.512085,226.302139 
	C462.753021,228.880768 461.373352,230.294556 458.619507,230.872192 
	C455.570618,229.363129 452.933319,227.252960 449.485107,230.161255 
	C442.182495,232.189331 440.687164,231.257599 437.541077,223.055786 
	C437.349884,222.417130 436.648163,223.303482 437.219818,222.960464 
	C437.369904,222.870407 437.432800,223.004578 437.567139,223.287766 
	C437.854492,223.893448 438.202087,224.543152 438.071899,225.177994 
	C437.229340,229.286713 438.368225,231.737747 442.993713,232.220276 
	C445.172302,232.447571 446.689392,234.287231 447.839325,236.643219 
	C448.118805,237.460449 448.205231,237.948456 448.248291,238.823639 
	C445.872131,244.720047 443.636169,250.332733 437.893372,253.864349 
	C433.860962,254.170288 431.833466,250.109879 427.789459,250.304932 
	C428.239044,253.004471 430.897858,255.204239 429.348450,258.434631 
	C423.745789,260.447540 418.383545,261.363159 412.897369,257.930908 
	C411.009125,255.028458 411.722015,252.511139 413.175171,250.156448 
	C415.001404,247.197266 415.372437,244.629425 411.900665,242.603271 
	C410.297089,241.667450 409.949768,239.535782 407.955414,238.406937 
	C406.203278,241.282288 403.713135,242.955383 400.175201,242.243530 
	C398.098358,241.825684 396.193298,240.239609 397.414215,238.520432 
	C400.582550,234.059036 397.560669,230.312881 396.764771,225.917114 
	C396.506989,223.911285 396.198608,222.314987 395.750061,220.718979 
	C394.010376,214.529327 394.753174,213.009308 401.180573,209.751877 
	C403.808441,208.979019 407.129791,210.913498 408.287781,208.012466 
	C410.022247,203.667175 412.838379,199.479340 411.891663,194.390381 
	C411.681610,193.261292 410.616516,192.987823 409.821411,192.415787 
	C406.312775,189.891449 406.291077,189.131470 409.732605,185.254822 
	C411.795441,182.399994 413.555267,180.300995 415.068848,185.204117 
	C415.796478,187.561218 417.873962,188.590698 419.717163,187.627197 
	C421.922302,186.474487 419.513947,184.834305 419.175385,183.409485 
	C418.909515,182.290710 418.255737,181.246338 418.099609,180.123672 
	C417.892029,178.630707 417.912018,176.886658 419.793854,176.476196 
	C421.199585,176.169571 422.530304,176.987061 422.818695,178.455780 
	C423.304962,180.931915 424.945129,181.460129 426.855347,180.770309 
	C429.252289,179.904724 432.209229,179.497757 432.897186,176.279083 
	C433.068359,175.478195 432.960815,174.620483 433.012238,173.790802 
	C433.247162,170.000687 434.388153,168.301224 438.681305,170.179718 
	C442.386444,171.800903 445.419769,175.335373 450.579071,173.484161 
	C456.097931,171.503952 458.095551,169.797195 457.965271,163.783875 
	C457.944000,162.803238 457.600830,161.833862 457.480438,160.850220 
	C457.179901,158.394943 458.309082,156.226044 460.422272,155.655319 
	C463.110016,154.929428 463.316467,157.742096 463.892609,159.496658 
	C465.282410,163.729294 468.848785,165.808914 472.449341,163.441467 
	C476.666626,160.668457 481.398102,160.268280 485.951752,158.986206 
	C488.394775,158.298386 492.336517,152.851898 491.853149,150.687439 
	C491.450806,148.885605 489.970062,149.139694 488.699615,148.928513 
	C486.191345,148.511627 483.578857,146.751999 481.340454,149.518417 
	C481.031097,149.900696 480.875275,150.403244 480.594971,150.813614 
	C479.420624,152.532837 479.250366,155.804565 476.834808,155.594513 
	C473.808197,155.331345 470.718628,154.036850 468.004486,152.551239 
	C466.187378,151.556625 466.879272,149.512909 468.441589,148.433868 
	C471.055267,146.628677 472.413086,144.082474 473.449066,141.225082 
	C474.269989,138.960876 475.652771,137.523010 478.368469,138.249344 
	C479.129364,138.452835 480.048523,138.626602 480.753235,138.390564 
	C487.328003,136.188278 488.302032,136.652710 491.040283,142.403717 
	C492.013641,144.448013 491.872772,148.988632 495.594543,147.183258 
	C498.484589,145.781387 497.041443,142.214066 495.755005,139.594345 
	C495.154602,138.371658 493.310791,137.216660 495.095612,135.752380 
	C496.896423,134.274994 497.481567,136.011505 498.468658,137.172897 
	C499.664490,138.579956 501.110046,137.789017 502.507080,137.025421 
	C504.242523,136.076874 506.037994,135.124268 507.925568,134.592178 
	C511.564026,133.566498 513.709167,135.336395 512.709229,138.941132 
	C511.693237,142.603561 512.912109,144.234818 516.197144,145.214081 
	C519.949890,146.332718 522.629333,147.929276 518.705872,152.004395 
	C517.726318,153.021820 518.745728,154.209045 518.958862,155.655975 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M576.637878,4.057195 
	C580.239075,3.713162 583.333069,4.178131 586.059937,6.045661 
	C589.641602,8.498565 593.684326,7.945369 597.651184,8.025835 
	C598.720398,8.047523 600.305176,7.654129 600.356506,9.345678 
	C600.390076,10.452148 599.181396,10.829826 598.139954,10.937342 
	C596.062561,11.151804 593.439392,10.589443 593.147766,13.721828 
	C592.833801,17.094954 595.893738,16.983358 597.892212,17.800360 
	C602.660278,19.749592 602.985779,21.334042 598.654053,24.500580 
	C596.561096,26.030506 596.006958,27.287821 596.674194,29.853542 
	C597.785156,34.125523 598.925110,38.467239 595.511414,42.619488 
	C594.201904,44.212215 594.770325,46.718937 596.132324,48.704140 
	C598.369629,51.965199 599.251343,55.634796 599.005798,59.585888 
	C598.849060,62.107456 599.091370,64.539879 600.349548,66.837082 
	C601.266724,68.511772 601.221680,70.400223 600.725037,72.193855 
	C600.057800,74.603500 598.573669,76.802612 595.965698,76.493469 
	C593.533630,76.205193 592.266968,74.061813 591.976746,71.447655 
	C591.728027,69.207497 593.127258,65.190163 589.599548,65.485641 
	C585.517212,65.827553 588.286377,69.720970 588.011475,72.042694 
	C587.705566,74.626068 588.732239,77.416328 586.794739,79.736389 
	C585.888184,80.821991 585.616943,82.837257 583.701233,82.528229 
	C582.112488,82.271927 581.191040,80.822289 580.777710,79.380966 
	C580.189392,77.329643 579.858276,75.196030 579.532654,73.079819 
	C579.020935,69.753883 576.601318,68.045204 573.799744,67.623924 
	C570.790344,67.171402 568.374023,69.470024 567.007690,71.807724 
	C565.157104,74.973793 568.152710,74.829796 570.039734,74.965538 
	C572.733154,75.159286 575.810181,74.191147 576.471802,78.443802 
	C576.727356,80.086662 579.056580,81.688835 577.277466,83.336037 
	C575.288940,85.177094 574.538818,81.612991 572.068726,81.687798 
	C573.177490,86.718094 572.501770,91.793633 574.329895,96.643646 
	C575.367249,99.395729 575.807861,101.745888 571.456055,102.147514 
	C568.421326,102.427589 568.249695,105.697952 571.235535,108.399338 
	C572.759399,109.778015 574.838928,110.796135 573.320679,113.148056 
	C572.174255,114.923874 572.962402,118.170807 570.564087,118.537880 
	C568.247620,118.892433 569.010376,115.740089 567.958008,114.329903 
	C566.170593,111.934792 563.197083,110.629662 562.046631,107.345970 
	C563.472839,103.727036 565.221802,100.602921 567.458252,97.282173 
	C563.461121,96.747299 559.748230,98.349915 555.773193,97.166077 
	C554.444946,95.665382 553.967224,94.259293 554.766724,92.313957 
	C555.000000,90.933510 555.000000,89.867027 555.000000,88.400612 
	C556.760254,86.521225 558.035767,88.057854 559.425842,88.740158 
	C560.506714,89.270714 561.572083,89.735985 562.632019,88.809967 
	C563.904968,87.697876 563.337402,86.440155 562.826416,85.196648 
	C561.805359,82.711601 559.204529,80.694069 560.769409,77.254059 
	C562.208862,69.438736 567.241882,66.100410 574.283997,65.034042 
	C575.451172,64.857307 576.680725,64.860634 577.427307,63.806461 
	C579.394287,61.029129 581.048035,62.261703 582.519531,64.227631 
	C584.689392,67.126534 586.037720,65.720009 587.244751,63.429291 
	C589.123779,59.863270 587.197327,56.938873 585.407837,54.153896 
	C583.736389,51.552708 581.671875,52.017216 578.934570,53.160831 
	C576.101196,54.344551 573.169250,52.516357 570.724182,50.819714 
	C569.741333,50.137665 569.916748,48.694237 570.164856,47.531628 
	C570.333984,46.739349 579.836731,41.263844 580.603394,41.450489 
	C589.371948,43.585266 594.235596,38.954151 591.587830,30.626766 
	C590.216919,26.315250 588.992065,22.070107 588.983276,17.534555 
	C588.979553,15.613560 588.098328,14.511663 586.232849,13.904449 
	C582.467896,12.678946 579.608521,9.775833 575.613098,8.629046 
	C572.546448,7.748840 573.901917,5.369033 576.637878,4.057195 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M413.763458,129.746216 
	C413.998779,130.001221 413.999573,130.500214 413.999786,130.749710 
	C413.939453,131.349609 413.772400,131.638489 413.249023,131.933044 
	C409.396484,133.582962 405.856628,134.523468 402.295929,131.747345 
	C401.615265,131.216644 399.666016,131.009735 399.565369,131.230164 
	C397.415131,135.940369 393.248138,134.902969 389.418152,135.100021 
	C387.673828,135.189743 387.201447,136.767700 386.915894,138.367371 
	C386.585571,140.217911 385.911987,142.275101 383.531036,141.814056 
	C380.984741,141.321014 377.958801,141.038971 376.550415,138.515366 
	C375.255798,136.195526 377.260345,135.243866 379.167969,134.841476 
	C382.655823,134.105820 383.922150,132.613861 383.185760,128.653412 
	C382.272278,123.740517 385.011261,121.578873 388.918091,123.652328 
	C388.892426,124.484535 388.712433,124.947548 388.418274,125.345161 
	C387.294281,126.864479 386.512177,128.449036 387.433044,130.358429 
	C387.921906,131.372101 388.766052,131.280670 389.487915,130.773941 
	C391.230743,129.550583 393.238525,128.400543 392.066528,125.336334 
	C392.054291,123.261223 393.459412,121.717438 392.254669,119.761452 
	C390.098999,116.261559 390.129669,113.769424 395.183990,112.761314 
	C398.258545,112.148056 397.264862,109.407257 395.945435,107.609520 
	C393.897858,104.819626 391.538635,102.260056 389.346741,99.574104 
	C386.595581,96.202820 384.766937,96.361633 382.014282,100.628021 
	C381.011780,101.058228 380.008270,101.028053 379.037964,101.193176 
	C374.667908,101.936897 372.786011,99.707825 374.139130,95.336937 
	C375.148590,92.076080 380.313263,90.315689 383.671509,90.953239 
	C388.905243,91.946823 393.370605,92.970703 392.628418,99.783752 
	C392.578369,100.243393 393.316956,100.917496 393.834778,101.287315 
	C395.679657,102.604805 396.839172,106.249847 399.764252,104.257759 
	C402.732117,102.236504 399.816376,99.889488 399.053802,97.363228 
	C400.255646,94.625031 402.581635,94.126839 404.893738,93.890404 
	C409.539093,93.415367 412.150604,96.540291 411.030426,101.038002 
	C409.590485,106.819496 413.196930,111.459381 419.074738,111.387497 
	C427.180389,111.288376 427.976959,111.745438 429.787292,117.706268 
	C429.998779,117.999519 429.998993,118.498871 429.998901,118.748543 
	C428.941681,120.766258 426.628204,120.921974 425.226379,122.769211 
	C423.337555,122.900085 422.232849,123.478065 421.764374,125.235001 
	C421.239960,127.201714 419.891998,128.216339 417.386719,127.997162 
	C412.741425,122.411514 407.806702,126.228821 402.810699,126.820274 
	C406.283447,129.031052 410.064056,128.597717 413.763458,129.746216 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M444.213745,48.735401 
	C443.378601,48.791183 442.269012,48.682812 442.205170,48.364697 
	C441.094910,42.828941 434.943542,44.127552 432.139954,40.873154 
	C430.935272,39.474724 429.310913,40.903366 427.887482,41.001507 
	C424.804596,41.214062 422.813293,39.680878 424.402649,37.019810 
	C426.313904,33.819778 424.736938,31.746969 423.653931,29.228106 
	C421.152618,23.410435 422.474945,18.812899 426.619354,16.457882 
	C429.205353,14.988409 430.368408,15.594249 430.809753,18.098902 
	C431.750366,23.436804 434.163147,22.041689 436.700012,19.439491 
	C437.790802,18.320597 438.900574,17.507835 440.392670,17.081274 
	C445.709320,15.561355 446.923828,16.409843 446.980286,21.977663 
	C447.006134,24.526741 446.734589,27.126120 450.108398,27.840412 
	C451.405090,28.114946 452.855621,28.196526 452.882538,30.057060 
	C452.907349,31.771177 451.813568,32.765553 450.559723,33.655407 
	C448.989410,34.769859 446.672699,34.970818 446.045715,37.651054 
	C441.619202,39.029442 443.193604,41.610519 445.092896,43.232826 
	C447.965729,45.686672 446.700409,46.994831 444.213745,48.735401 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M430.010376,117.615540 
	C426.979523,111.685333 421.363556,112.492317 416.073608,113.288673 
	C413.372284,113.695343 411.303986,113.143112 411.280945,110.655487 
	C411.243317,106.592957 409.459900,104.718185 405.689606,104.499702 
	C405.836273,102.932907 406.857300,103.128410 407.574188,102.908417 
	C410.440277,102.028854 410.297638,99.695366 409.779205,97.608940 
	C409.191711,95.244652 407.072418,94.816116 404.973663,95.236023 
	C403.194427,95.591988 401.491150,96.327713 399.377747,96.947853 
	C397.599213,96.342369 396.978577,95.214188 397.005432,93.346390 
	C395.277557,89.968445 396.975220,88.120010 399.623352,88.451675 
	C403.626404,88.953049 407.554413,88.216293 411.489105,88.584648 
	C416.286316,89.033760 418.224060,91.070824 418.638306,95.708603 
	C418.520996,90.460999 420.296417,86.157249 424.830414,83.315933 
	C427.380066,81.718140 429.993774,80.937408 432.739838,83.752388 
	C432.910004,85.923325 433.535553,88.144218 429.971710,87.252182 
	C426.037201,86.267357 425.335449,89.912270 424.532471,92.365433 
	C423.786987,94.642937 422.626495,97.568871 426.105133,99.187988 
	C430.724243,101.337936 433.103851,105.570641 435.456268,109.727905 
	C436.570068,111.696289 435.782776,113.376907 433.534058,113.849609 
	C431.542084,114.268341 430.047119,114.906837 430.010376,117.615540 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M443.000000,66.465866 
	C444.264587,63.873249 446.470459,62.327744 446.960022,59.345200 
	C448.252869,59.089085 449.369110,59.574207 449.807129,60.759895 
	C450.865997,63.626461 452.982880,64.067039 455.744110,64.053711 
	C463.778931,64.014908 463.779449,64.113037 464.000854,55.886467 
	C464.018768,55.221615 463.949341,54.540604 464.069122,53.894672 
	C464.612305,50.966087 466.027649,47.618614 468.854340,47.671467 
	C472.021942,47.730686 471.841827,51.590378 471.916412,54.083382 
	C472.054596,58.700180 473.368347,63.731934 466.867340,66.012177 
	C463.981201,67.024483 464.829926,70.627380 465.280182,73.216309 
	C466.406219,79.691223 466.036469,79.327255 459.914825,81.739922 
	C454.099091,84.032043 449.698822,81.155952 444.813416,79.660477 
	C443.094116,79.134186 443.010223,77.455254 443.008118,75.911850 
	C443.004059,72.917564 443.002563,69.923271 443.000000,66.465866 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M545.237061,100.745872 
	C539.187622,102.100914 537.716125,104.587692 540.462463,109.524689 
	C542.781006,113.692474 544.981384,117.912857 548.648376,121.304459 
	C553.709961,125.985893 554.076843,129.345261 551.447998,135.429764 
	C550.903442,136.690079 550.500244,138.281967 551.594238,139.156128 
	C552.910400,140.207840 553.537598,138.637329 554.149475,137.726151 
	C555.238770,136.104050 556.442627,135.925079 557.787292,137.374039 
	C559.632874,139.362854 557.539612,139.487091 556.531250,140.467590 
	C554.653015,142.293823 553.166931,144.523331 551.256348,146.792603 
	C548.795593,147.383774 547.685425,145.440994 545.765503,144.420807 
	C543.963806,140.554932 544.396667,136.018112 540.258911,133.248260 
	C538.264954,131.039612 536.795349,128.808594 534.203552,127.257233 
	C533.176331,126.009232 532.925293,124.929741 533.747070,123.361687 
	C538.612366,121.603798 537.612915,117.147141 538.457031,113.996460 
	C539.192688,111.250900 535.951416,111.203934 534.041382,110.465012 
	C531.310364,109.408447 529.503479,107.177025 527.716797,105.037308 
	C525.411804,102.276848 526.568298,99.608917 529.726685,100.280022 
	C535.058289,101.412910 539.121582,99.617912 543.937500,97.170784 
	C545.501892,98.218559 545.906250,99.242218 545.237061,100.745872 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M432.943542,83.612320 
	C426.504730,82.326538 420.894653,86.260269 420.050018,93.118340 
	C419.928619,94.104103 420.040283,95.116379 419.992371,96.113892 
	C419.914825,97.727341 420.366608,99.604042 418.406433,100.442749 
	C417.988739,100.621460 417.039307,100.241074 416.712616,99.823051 
	C415.652405,98.466454 415.919922,97.013474 416.879272,95.683517 
	C418.720276,93.131325 416.695007,92.073547 415.000397,91.055061 
	C412.407196,89.496490 409.493805,89.672493 406.747467,90.343674 
	C404.698334,90.844467 402.885071,91.062561 400.986633,90.012970 
	C398.506714,88.641930 397.225494,89.549934 397.008606,92.632797 
	C393.683105,92.923210 393.360901,91.207932 394.873596,88.802467 
	C396.007111,86.999985 397.095123,84.877670 398.777863,83.772217 
	C402.318878,81.446014 405.195953,78.488182 408.333344,75.758987 
	C410.604950,73.782959 413.475861,76.260750 414.621643,78.715179 
	C416.151642,81.992805 418.696899,81.189835 420.935791,80.677017 
	C423.484589,80.093201 423.071625,77.612068 422.943787,75.691284 
	C422.791473,73.402756 422.329590,71.134834 422.002136,68.429138 
	C429.849884,68.001450 429.792938,68.010567 431.088226,76.104828 
	C431.473450,78.512321 432.276611,80.852951 432.943542,83.612320 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M409.594055,184.999847 
	C409.218414,187.686371 405.816437,191.289780 412.432587,191.258957 
	C414.100342,191.251175 413.948212,193.124054 413.984985,194.422440 
	C414.143951,200.037109 411.242188,204.777115 409.233856,209.689392 
	C408.252686,212.089310 405.063721,210.713150 402.432587,210.999573 
	C400.553711,207.639557 404.256989,208.298935 405.253143,206.602356 
	C405.046936,205.533295 403.490387,205.649307 402.808472,204.363770 
	C402.628815,203.820847 402.615906,203.587784 402.745514,203.036255 
	C403.860199,201.727448 405.278076,201.673950 406.414215,201.038025 
	C407.354584,200.511688 408.017365,199.764496 407.260376,198.660736 
	C406.417633,197.431992 405.381470,197.533539 404.340332,198.451477 
	C403.214020,199.444489 402.652832,200.873459 401.379700,202.177490 
	C395.998932,203.284897 396.550629,198.993759 395.602386,195.739502 
	C392.220520,197.723572 393.807892,200.543335 393.563019,203.239258 
	C389.130798,205.960098 388.616241,210.332565 387.528290,214.835846 
	C383.839142,216.366898 382.558746,213.794220 380.930542,211.231155 
	C380.496521,209.449844 380.597351,207.920349 379.520447,206.334564 
	C378.845367,204.152771 380.302063,202.800888 380.628235,200.795151 
	C381.344940,198.058899 381.463379,195.464600 383.741547,193.404968 
	C386.736237,192.251160 388.449554,189.622253 391.647736,188.966675 
	C395.185028,189.186157 396.870575,187.102509 398.099396,184.671112 
	C399.353973,182.188843 400.699890,181.417328 403.064697,183.381866 
	C404.807190,184.829437 406.960419,185.164291 409.594055,184.999847 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M560.642578,76.953979 
	C560.749023,79.942574 562.839417,81.876045 564.052246,84.201782 
	C565.075806,86.164566 566.897095,88.116737 564.133301,90.305550 
	C561.535522,92.362885 559.660217,91.584068 557.777893,89.358894 
	C557.278992,88.769058 556.395264,88.504723 555.344116,88.045807 
	C553.423157,85.583992 554.304016,83.375443 555.386536,81.049896 
	C556.351624,78.976746 555.613525,77.721596 553.197571,78.465004 
	C550.444580,79.312157 547.663086,78.840202 544.448975,78.999878 
	C543.176392,78.008942 542.925476,76.929756 543.747070,75.362160 
	C549.715393,73.186165 555.296997,72.514168 560.642578,76.953979 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M391.946899,188.621124 
	C389.770416,190.471054 388.627228,193.336517 385.349854,193.953766 
	C383.168335,193.359497 382.696136,191.740982 382.378265,190.126648 
	C381.619141,186.271576 379.311890,184.118195 375.238342,183.260101 
	C375.000824,182.249542 375.000427,181.499695 375.000000,180.374924 
	C375.184235,177.171539 375.843719,174.722626 379.675995,174.774231 
	C382.718536,177.176605 386.103180,177.775146 389.632874,178.950836 
	C389.607880,182.290909 390.937927,185.228241 391.946899,188.621124 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M460.997314,5.425211 
	C466.046143,2.706318 471.097443,4.406859 476.575378,4.997249 
	C476.922211,7.131838 477.175781,9.268006 476.388672,11.366582 
	C475.729431,13.124174 475.240173,15.026056 472.923065,14.912041 
	C470.800629,14.807606 468.148956,16.116480 466.879578,13.079515 
	C466.477448,12.117468 465.803101,11.189803 464.766510,11.213238 
	C460.726898,11.304564 460.850769,8.617399 460.997314,5.425211 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M554.610840,91.988800 
	C555.328064,93.420944 555.656128,94.841888 555.992065,96.630676 
	C553.318909,100.914635 549.752075,101.096443 544.303955,97.289757 
	C544.000000,97.000305 544.000000,97.000000 544.000000,97.000000 
	C543.384399,92.548882 539.244263,94.509117 536.895752,93.206261 
	C535.331665,92.338577 534.088806,91.688210 534.033264,89.713287 
	C533.968933,87.425903 535.325806,86.947357 537.600830,87.002998 
	C538.913208,86.827858 539.784485,86.852829 540.757935,87.767288 
	C541.254089,89.933456 542.325317,91.493088 544.263794,90.636589 
	C547.999390,88.986092 550.983032,91.021584 554.610840,91.988800 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M540.000000,133.427551 
	C546.758240,133.558212 546.003479,138.566467 546.000000,143.564575 
	C545.336304,144.664337 544.553467,144.753311 543.349854,144.241776 
	C541.478577,144.602402 539.923950,145.134628 538.433228,145.808502 
	C536.578064,146.647095 534.556152,147.846024 533.603210,144.952103 
	C532.770264,142.422607 533.125061,139.420197 536.207947,139.210068 
	C540.297668,138.931305 540.105896,136.592377 540.000000,133.427551 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M511.115753,118.093414 
	C511.547699,116.594963 511.793091,115.448280 512.214905,114.370667 
	C512.667969,113.213242 514.226562,112.821770 514.369629,113.834183 
	C514.950500,117.946152 518.768188,120.982117 517.996399,125.498528 
	C517.539185,128.173904 515.819641,128.733017 514.209167,127.215248 
	C511.834747,124.977516 508.466766,122.682335 511.115753,118.093414 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M542.956787,144.353607 
	C543.753601,144.005478 544.502380,144.003632 545.625549,144.000580 
	C548.100952,143.761154 549.815796,144.210388 550.740723,146.759644 
	C550.774841,152.926208 549.229614,153.886993 542.391907,152.051071 
	C541.544128,149.467712 541.031494,146.935699 542.956787,144.353607 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M541.500977,53.766617 
	C541.815674,55.427208 541.946899,56.739719 541.978943,58.054653 
	C542.027771,60.061420 541.478760,61.662888 539.116272,61.925655 
	C536.627625,62.202461 535.907715,60.836567 535.984741,58.657310 
	C536.002014,58.167160 535.820740,57.667168 535.856628,57.180992 
	C535.995361,55.302391 531.856506,54.202286 534.340576,52.433487 
	C536.197876,51.111046 539.147278,51.655956 541.500977,53.766617 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M389.338745,173.066574 
	C388.596497,172.056259 386.849335,171.729767 387.616364,170.277969 
	C388.307434,168.969971 389.418488,169.466843 390.560181,169.686523 
	C392.594635,170.077972 393.905304,171.490906 395.698120,172.838470 
	C397.457611,174.648163 399.208374,176.667023 396.679138,178.337921 
	C394.185913,179.985046 392.988037,177.451950 391.851959,175.663498 
	C391.247467,174.711960 390.934814,173.519684 389.338745,173.066574 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M387.249908,154.186462 
	C386.895630,153.527390 386.833740,153.040298 386.678680,152.584930 
	C386.247406,151.318130 384.263489,150.296829 385.621704,148.787781 
	C386.626099,147.671860 388.017120,149.237717 389.638916,148.992477 
	C390.043823,150.576065 391.461456,151.037415 392.347931,151.947815 
	C394.800171,154.466171 393.618866,155.588120 390.359924,155.992920 
	C388.817047,155.995193 388.156647,155.186798 387.249908,154.186462 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M532.619446,151.000549 
	C533.000000,151.813919 533.052490,152.632553 532.989014,153.442108 
	C532.854309,155.157913 533.645264,157.334320 531.655701,158.299332 
	C529.908386,159.146820 529.622925,156.497101 528.178162,156.243790 
	C527.698303,156.159653 527.215454,156.092514 526.367004,156.008499 
	C526.808716,152.746964 528.791138,150.959702 532.619446,151.000549 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M583.544067,35.537392 
	C581.694946,37.172840 580.011353,38.951920 577.794495,36.950565 
	C576.601501,35.873535 576.969482,34.199646 577.979126,33.389278 
	C580.585205,31.297638 582.657043,31.906958 583.544067,35.537392 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M504.545715,116.441200 
	C505.863861,117.754456 506.516052,119.020660 504.644318,119.748306 
	C502.283173,120.666214 500.533203,119.132713 498.875641,117.741249 
	C498.031830,117.032898 497.653290,115.656837 498.384521,115.099342 
	C500.587311,113.419861 502.176636,116.241501 504.545715,116.441200 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M522.597778,134.321350 
	C525.634949,131.995636 527.753418,133.452148 529.570068,135.624313 
	C530.128357,136.291840 529.673950,137.747375 528.935059,137.576706 
	C526.616760,137.041290 523.403687,138.187286 522.597778,134.321350 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M543.657104,74.952133 
	C544.000000,76.066811 544.000000,77.133324 544.000000,78.599762 
	C539.583252,78.837753 539.655396,74.275818 537.296875,72.086708 
	C536.867859,71.688507 537.197083,70.683868 537.920166,70.577316 
	C538.448547,70.499458 539.136414,70.867996 539.617126,71.222183 
	C541.026245,72.260391 541.225769,74.526558 543.657104,74.952133 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M533.577637,122.999725 
	C534.000000,124.066521 534.000000,125.133354 534.000000,126.600243 
	C533.677246,127.000237 533.343140,127.054855 533.033569,126.991020 
	C531.325928,126.638893 528.426147,128.014511 528.326843,125.175301 
	C528.208313,121.787178 531.377930,123.451073 533.577637,122.999725 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M425.332336,122.999557 
	C424.828766,120.000816 426.381592,118.822479 429.609436,119.000107 
	C430.312500,119.470535 430.626190,119.942848 430.969971,120.708130 
	C430.785828,123.023613 429.843079,124.069679 427.339600,123.241074 
	C426.555359,123.002144 426.110626,123.001244 425.332336,122.999557 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M427.068054,123.332306 
	C428.459167,123.132240 429.647003,122.669914 430.734985,121.206482 
	C431.304810,121.127396 431.558075,121.321098 431.880066,121.791405 
	C431.886505,123.167442 433.246216,124.817093 431.604980,125.403069 
	C429.945435,125.995567 428.194458,125.358788 427.068054,123.332306 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M540.988770,87.647247 
	C540.147949,87.829117 539.295837,87.658852 538.221924,87.244141 
	C538.184509,86.192734 538.339172,85.294228 539.396484,85.268890 
	C540.752197,85.236397 540.866699,86.291771 540.988770,87.647247 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M448.044128,87.464890 
	C448.695221,87.823608 448.704529,88.183769 448.237213,88.501419 
	C448.139496,88.567848 447.913391,88.564911 447.818390,88.495491 
	C447.364014,88.163399 447.380798,87.803581 448.044128,87.464890 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M301.708160,1142.972656 
	C299.034637,1142.760132 297.227417,1144.161621 295.182983,1144.726929 
	C290.688324,1145.970093 288.203156,1144.064941 288.002747,1139.399780 
	C287.590485,1129.803711 288.941895,1128.424683 298.483551,1130.254272 
	C301.303345,1130.794678 305.059937,1129.914307 306.832306,1133.458130 
	C308.699188,1137.190674 307.283234,1139.988159 301.708160,1142.972656 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M283.297058,1170.763672 
	C281.799225,1169.573242 281.547943,1168.062500 282.451996,1167.058716 
	C283.819489,1165.540649 284.715424,1167.416138 285.649261,1168.092041 
	C286.988739,1169.061646 288.467987,1168.954712 289.956696,1169.013550 
	C291.113525,1169.059326 292.578674,1168.948853 292.692505,1170.486206 
	C292.801819,1171.962769 292.380585,1173.609985 291.138550,1174.518799 
	C290.007507,1175.346558 288.857361,1174.464600 288.135529,1173.520142 
	C286.974976,1172.001587 285.745911,1170.745361 283.297058,1170.763672 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M272.640137,1210.114014 
	C273.946716,1208.789917 275.093811,1207.153809 275.919220,1209.977539 
	C276.053162,1210.435913 276.013336,1210.958496 275.987610,1211.447754 
	C275.861694,1213.842285 275.973480,1217.403076 273.334656,1217.358032 
	C270.942871,1217.317627 272.328949,1213.831055 272.082916,1211.864258 
	C272.026367,1211.412476 272.302429,1210.919189 272.640137,1210.114014 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M306.221802,1213.209473 
	C308.232483,1213.552612 309.373566,1214.480591 308.449280,1216.198853 
	C307.843353,1217.325317 306.391602,1217.170654 305.433319,1216.492554 
	C303.706818,1215.270996 304.186646,1214.132080 306.221802,1213.209473 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M276.409149,1144.509033 
	C277.777069,1144.415894 278.692780,1144.803833 278.420288,1146.052124 
	C278.207092,1147.028320 277.212677,1147.038452 276.442627,1146.790894 
	C275.018402,1146.333008 275.377167,1145.488647 276.409149,1144.509033 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M285.974030,1190.364258 
	C284.932404,1189.529541 284.832642,1188.655762 285.595673,1187.814697 
	C285.721954,1187.675537 286.284576,1187.686768 286.408539,1187.831421 
	C287.141510,1188.687500 287.054810,1189.559326 285.974030,1190.364258 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1154.909668,953.661255 
	C1154.356079,955.153381 1153.381348,956.239929 1153.132202,957.473022 
	C1152.465820,960.771973 1150.047607,960.408142 1148.143066,959.619385 
	C1146.043701,958.750061 1145.465088,956.542053 1146.800293,954.564392 
	C1149.605103,950.409851 1148.937012,944.324707 1154.263184,941.517029 
	C1155.409546,940.912720 1155.207031,939.158325 1154.331299,937.416382 
	C1152.129517,940.175293 1150.111816,940.855774 1148.574951,936.932800 
	C1148.086670,935.686401 1146.544434,935.906921 1145.449463,936.374207 
	C1143.308716,937.287842 1141.828613,936.778137 1140.183716,935.071655 
	C1138.606079,933.434875 1136.864990,934.924194 1137.298950,936.474060 
	C1139.034180,942.669067 1135.098267,943.148804 1130.068970,943.148010 
	C1131.821045,945.707397 1132.988892,947.432495 1135.877441,946.097900 
	C1137.028198,945.566284 1138.923706,943.968140 1139.403320,946.301819 
	C1139.745483,947.965820 1138.493286,949.824524 1136.105469,949.814087 
	C1135.110352,949.809753 1133.524414,948.497437 1133.329468,950.449707 
	C1133.155151,952.194031 1134.783569,951.533875 1135.874756,951.719543 
	C1138.958618,952.244263 1140.863281,956.595764 1139.940430,960.644836 
	C1139.123047,964.230591 1136.512329,962.837891 1134.357788,962.601013 
	C1133.373657,962.492798 1132.414551,962.089661 1131.432617,962.038513 
	C1123.169189,961.607788 1124.174561,960.551697 1121.141602,952.977844 
	C1119.491821,948.858154 1111.611938,944.470093 1107.547485,946.188965 
	C1105.416870,947.090149 1106.139160,948.954468 1106.015869,950.504150 
	C1105.813843,953.041199 1105.878784,955.611389 1102.359009,956.003174 
	C1099.004028,956.376587 1097.140137,955.029663 1096.015869,951.823425 
	C1094.951172,948.788025 1094.287231,946.113037 1095.399048,942.772217 
	C1096.447876,939.620300 1096.156372,936.033264 1091.700928,935.028870 
	C1090.573364,934.774780 1089.196411,934.899170 1089.294067,933.205383 
	C1089.375000,931.802612 1090.445801,931.267273 1091.670532,931.059814 
	C1093.681641,930.719116 1095.535156,929.998596 1094.530396,927.555542 
	C1093.589844,925.268433 1091.600830,924.370056 1089.188477,925.575684 
	C1084.905151,927.716309 1081.019043,928.516663 1078.341797,923.196411 
	C1078.048462,922.613281 1076.930664,922.078247 1076.235474,922.124512 
	C1072.075684,922.401733 1070.136841,920.380920 1069.561401,916.422852 
	C1069.374512,915.137756 1068.482422,913.791992 1066.751831,914.360291 
	C1065.249023,914.853699 1064.902100,916.243958 1065.056885,917.633667 
	C1065.422119,920.911743 1066.828735,923.844971 1069.429077,925.771423 
	C1072.286133,927.888062 1072.437988,929.342529 1069.315063,931.170471 
	C1065.835083,933.207336 1064.656372,934.453735 1069.216309,937.640076 
	C1073.009644,940.290649 1075.657471,945.121887 1077.349243,949.824646 
	C1078.350830,952.609070 1080.044434,953.831177 1082.553223,952.697388 
	C1084.762207,951.699219 1085.145142,949.449646 1084.069824,947.172058 
	C1083.275269,945.489197 1082.441895,943.794189 1083.712524,942.003967 
	C1084.501343,940.892395 1085.469116,939.880798 1087.020142,940.288757 
	C1088.582275,940.699646 1089.312134,942.076172 1088.827271,943.377014 
	C1087.073730,948.082520 1088.944336,952.905212 1088.101929,957.661011 
	C1087.638550,960.276794 1087.028564,961.361511 1084.354370,960.319519 
	C1083.752075,960.084839 1083.050659,960.076599 1082.390015,960.024658 
	C1073.696411,959.340637 1073.708740,959.343628 1073.929199,950.923462 
	C1073.950439,950.115601 1073.608765,948.585693 1073.574219,948.589661 
	C1068.208984,949.210022 1067.061279,942.595581 1062.450317,942.113037 
	C1060.487061,941.907654 1058.565308,941.710022 1060.192017,944.958191 
	C1061.625732,947.821350 1058.756714,948.738525 1056.889160,949.397827 
	C1054.430664,950.265686 1051.598267,951.018372 1049.548462,948.502258 
	C1048.644653,947.392639 1048.824707,945.899841 1050.325928,945.113464 
	C1052.713623,943.862793 1052.631592,940.591125 1051.780640,939.994629 
	C1046.859619,936.545105 1051.324219,932.804810 1051.220581,929.350769 
	C1051.126587,926.219788 1053.057495,923.615601 1056.805786,922.747192 
	C1060.450928,921.902771 1060.254272,918.635254 1059.883545,915.790527 
	C1059.495239,912.809998 1057.327393,912.774780 1055.052734,913.295227 
	C1053.967407,914.729858 1055.432495,915.037598 1055.987183,915.671326 
	C1057.349487,917.227783 1056.330688,918.503418 1055.481323,919.690247 
	C1054.328369,921.301453 1052.572998,921.210571 1050.939087,920.731873 
	C1049.325073,920.259155 1049.212524,919.029114 1049.648926,917.521118 
	C1050.284912,915.323425 1049.935913,913.095642 1047.209351,913.208191 
	C1043.564331,913.358521 1046.556396,915.378967 1046.565552,916.602417 
	C1046.581543,918.729553 1045.132324,919.228699 1043.537109,918.611267 
	C1041.350586,917.764893 1039.364258,917.393372 1036.890747,917.566650 
	C1034.177856,917.756592 1032.656250,914.646606 1032.627930,912.335815 
	C1032.602051,910.214722 1034.817261,911.045837 1036.091187,911.762207 
	C1037.418457,912.508606 1038.754395,913.610046 1040.175781,912.465088 
	C1041.659302,911.270203 1039.976074,910.171204 1039.730469,908.992126 
	C1039.138672,906.151001 1041.016113,904.300598 1043.133911,903.043335 
	C1045.127197,901.860107 1046.269775,900.384827 1047.158447,898.256287 
	C1049.267090,893.206482 1051.424316,892.784607 1055.746094,896.267273 
	C1056.939575,897.229065 1058.063477,898.400635 1059.704346,897.702820 
	C1061.559570,896.913818 1060.945190,895.232178 1060.756470,893.788696 
	C1060.636353,892.871033 1059.851685,891.776550 1061.292603,891.296936 
	C1062.394409,890.930298 1063.353638,891.632996 1063.842651,892.456970 
	C1065.721313,895.622314 1067.197632,894.079407 1068.407471,892.052429 
	C1071.327148,887.160767 1077.579102,887.333923 1081.185547,883.491882 
	C1081.819702,882.816406 1083.416138,882.770691 1083.694824,882.081177 
	C1085.097778,878.609680 1087.607788,879.244141 1090.619629,879.989990 
	C1093.939697,877.774231 1096.339233,873.852417 1100.938110,876.842102 
	C1102.128540,877.615967 1102.627930,879.347168 1104.693970,879.165039 
	C1108.743530,878.696655 1111.929810,879.522217 1113.833252,883.174255 
	C1114.119385,883.723328 1114.729004,884.337524 1115.294800,884.470215 
	C1119.970703,885.567383 1121.334106,889.084961 1121.946045,893.619507 
	C1121.988403,898.546936 1120.111816,903.076294 1121.990723,907.983093 
	C1122.231812,908.811951 1122.299072,909.305481 1122.318115,910.195129 
	C1120.409058,914.491089 1118.577271,915.190613 1115.812500,912.506104 
	C1113.977295,910.724243 1111.079590,909.906372 1110.284912,906.639526 
	C1109.587158,904.695923 1108.686035,903.425964 1106.595215,902.855713 
	C1105.904785,902.364441 1105.588257,901.997986 1105.181885,901.240356 
	C1104.866943,900.152588 1104.909668,899.418884 1105.286377,898.364380 
	C1107.012695,896.235413 1109.438354,896.689514 1111.349121,895.791565 
	C1113.011353,895.010437 1114.560547,894.103149 1115.216797,891.770996 
	C1110.850220,890.955505 1106.815308,890.547546 1103.596924,894.977478 
	C1100.584351,894.715271 1102.689209,897.578979 1101.034058,898.273926 
	C1097.496826,898.635315 1094.876587,897.303833 1092.455078,894.749268 
	C1092.162476,893.927368 1092.113525,893.438538 1092.244385,892.596802 
	C1092.470947,891.109436 1094.507690,890.810547 1093.666992,889.222290 
	C1093.355713,888.634033 1092.709473,888.854004 1093.221191,889.281311 
	C1093.732910,889.708679 1092.281250,889.502319 1092.774414,889.903076 
	C1091.888428,891.218262 1090.652832,892.058777 1091.308594,893.955322 
	C1094.274658,898.056335 1094.445068,903.145508 1097.896851,906.797607 
	C1099.685303,908.689941 1098.813477,911.705017 1096.356201,913.282959 
	C1093.781860,914.935974 1091.609375,914.007812 1090.018799,911.540588 
	C1089.626709,910.932434 1089.403687,910.203613 1088.800415,909.470764 
	C1088.044556,912.318237 1089.145264,913.531494 1091.351440,914.869385 
	C1097.421143,918.550415 1104.171387,917.541260 1110.660278,918.343811 
	C1110.990112,918.384644 1111.494019,918.455933 1111.624146,918.255981 
	C1114.722656,913.495178 1118.804199,916.148560 1122.885498,916.561157 
	C1124.320801,915.642395 1125.510742,914.959656 1126.436035,913.430908 
	C1127.251099,912.674622 1127.893433,912.309814 1128.966187,911.947266 
	C1132.276978,911.638855 1134.900757,912.639771 1137.730713,914.195435 
	C1139.652344,919.314270 1136.253540,921.543213 1133.750244,923.610474 
	C1140.354736,920.249268 1140.737671,928.858765 1145.225220,930.649536 
	C1138.203613,926.674805 1140.192749,921.046448 1142.296021,915.123657 
	C1144.643677,912.883179 1143.570068,910.581360 1143.245361,908.335510 
	C1142.454956,902.868652 1144.277954,900.959534 1149.723145,901.725952 
	C1151.096313,901.919250 1152.363892,901.761780 1153.633057,901.489014 
	C1156.688843,900.832153 1159.395264,901.540100 1159.453369,904.895874 
	C1159.510010,908.162720 1156.693604,909.019165 1153.741455,908.841614 
	C1153.086548,908.802246 1152.481079,908.447571 1151.776489,908.480713 
	C1151.318848,912.069092 1153.507568,912.836365 1156.759766,912.937744 
	C1158.944702,913.794250 1159.877930,915.587646 1161.617920,917.003479 
	C1166.668823,918.744385 1168.804077,922.591492 1170.526123,927.235962 
	C1170.863403,929.177917 1169.878662,930.418518 1169.375610,931.801086 
	C1168.931030,933.023254 1168.908813,934.252136 1170.329956,934.675476 
	C1171.968994,935.163635 1172.754272,934.049561 1173.063843,932.590393 
	C1173.340332,931.286682 1172.955078,929.937988 1173.403320,928.302246 
	C1174.378418,926.568909 1176.445557,926.345032 1177.059814,924.211548 
	C1178.531738,922.192627 1180.098999,920.945435 1182.681396,920.695190 
	C1188.189331,922.378723 1189.421021,924.769409 1187.913452,930.524353 
	C1186.835938,934.637634 1184.781738,938.531067 1185.155151,943.246399 
	C1187.088135,943.949951 1189.235352,941.202698 1191.021118,943.456055 
	C1192.726318,945.607727 1191.900635,947.829407 1191.201416,950.414795 
	C1190.645508,951.694763 1190.011719,952.470825 1189.045654,953.449219 
	C1185.198242,956.195312 1183.142700,954.305115 1181.048706,951.363831 
	C1180.019043,949.917480 1180.030151,946.096436 1176.120605,948.487854 
	C1174.933228,949.123169 1174.000122,949.197083 1172.712036,948.857544 
	C1170.453979,947.641663 1171.749268,945.444214 1170.514893,943.868164 
	C1169.686890,943.483826 1169.123413,943.242004 1168.272339,942.879639 
	C1167.246216,942.395752 1166.662598,941.755432 1165.517212,941.526001 
	C1164.549316,941.409180 1163.915161,941.247009 1162.957031,940.984253 
	C1161.268555,940.340576 1160.057861,939.996582 1160.126221,942.224976 
	C1160.201172,944.665405 1158.825806,946.185120 1156.700562,947.052429 
	C1153.431030,948.386536 1153.289062,950.585815 1154.909668,953.661255 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1138.368408,915.279114 
	C1135.258301,914.340393 1132.516724,913.680786 1129.387451,913.010620 
	C1127.314209,908.531494 1132.216919,908.988037 1133.859985,906.447449 
	C1129.535400,906.465576 1125.458008,906.130737 1121.333984,908.116821 
	C1119.082153,906.057556 1118.211060,903.917786 1120.043579,901.474182 
	C1121.031494,900.156738 1120.542358,899.011658 1119.918701,897.837402 
	C1118.961548,896.035339 1119.151489,894.702942 1121.661377,894.046631 
	C1123.159546,895.325195 1124.950439,896.565613 1126.317627,895.806763 
	C1130.413696,893.533447 1133.876099,895.498108 1137.570801,896.657471 
	C1139.854614,897.374084 1141.762939,896.689514 1143.027710,894.472473 
	C1144.815552,891.338867 1147.429199,889.817871 1151.179565,889.947144 
	C1155.057373,890.080750 1159.117432,890.622620 1161.881958,886.567505 
	C1162.699829,885.367798 1164.699951,886.119263 1166.580566,886.000000 
	C1167.751587,888.252930 1167.693848,891.235718 1171.556885,890.415771 
	C1173.283569,890.049255 1173.485474,891.715759 1174.170898,892.840942 
	C1176.192505,896.159729 1180.276733,896.769836 1182.829712,894.198975 
	C1183.627075,893.395996 1184.162598,891.865295 1185.493774,892.567627 
	C1187.543701,893.649109 1188.046875,895.958313 1188.730957,897.970764 
	C1189.161865,899.238403 1188.408203,900.463440 1187.167358,900.685303 
	C1182.627686,901.497192 1179.384521,906.637817 1173.801025,904.330627 
	C1171.990479,903.582397 1170.231445,905.513245 1170.259033,907.542053 
	C1170.288330,909.699463 1171.904541,909.953186 1173.879395,909.586060 
	C1179.240845,908.589417 1184.337158,910.734802 1189.469849,911.592590 
	C1193.031982,912.187927 1196.623779,909.900757 1200.115234,912.388977 
	C1201.890137,913.653870 1203.860474,914.077332 1201.914062,916.649231 
	C1200.929443,917.950073 1200.627197,919.439880 1201.661865,920.802490 
	C1202.955811,922.506714 1204.330200,921.125000 1205.677612,920.706177 
	C1206.619263,920.413513 1207.586914,920.138428 1208.562012,920.034180 
	C1210.033081,919.876770 1211.824951,920.213440 1212.446289,918.484009 
	C1213.044678,916.818237 1211.323486,916.341003 1210.331177,915.546082 
	C1209.506104,914.885132 1208.844849,914.034363 1209.418091,912.984863 
	C1210.130249,911.681458 1211.381470,912.009766 1212.508423,912.355225 
	C1215.376831,913.234680 1216.324707,915.122681 1215.847534,918.096802 
	C1215.234131,921.920837 1214.317017,925.847290 1218.139160,928.885071 
	C1219.355591,929.851990 1219.405396,931.706665 1218.555786,933.044434 
	C1217.629150,934.503296 1216.680786,933.025146 1215.664185,932.800598 
	C1211.736938,931.932922 1213.695435,934.531860 1213.812256,936.148804 
	C1213.924927,937.710510 1214.782104,939.709167 1212.793091,940.577148 
	C1211.293823,941.231445 1210.002686,939.994263 1208.804321,939.091980 
	C1207.745728,938.295044 1207.485352,935.644043 1205.628662,936.930847 
	C1204.273560,937.869934 1205.046509,940.042664 1205.078491,941.671204 
	C1205.118652,943.719360 1206.598633,944.066101 1208.210083,943.971375 
	C1213.358398,943.668823 1218.072998,942.200684 1222.238403,938.353088 
	C1222.867188,940.557068 1221.321655,941.736084 1220.581543,943.186829 
	C1219.872681,944.575989 1219.517090,946.161072 1220.735474,947.357666 
	C1221.862183,948.464111 1223.167114,947.791626 1224.384033,947.101807 
	C1225.384399,946.534790 1226.479980,946.131226 1227.546753,945.687378 
	C1230.315552,944.535461 1232.077515,944.927734 1232.031128,948.459961 
	C1231.990479,951.559509 1233.667969,952.144897 1236.640625,951.045532 
	C1238.298462,950.138855 1238.405884,948.419250 1239.778564,947.225952 
	C1243.697510,947.936646 1247.059204,950.740051 1251.617188,949.010254 
	C1253.243896,949.404785 1254.624146,949.465027 1255.820801,950.720886 
	C1256.616333,953.350403 1257.274780,955.694885 1257.000000,958.584778 
	C1254.798096,961.142578 1252.500732,961.739502 1249.877930,959.097107 
	C1248.851440,957.208801 1253.840454,956.133179 1249.776367,954.263550 
	C1244.908569,955.441772 1243.263794,957.910889 1244.591797,961.801697 
	C1245.516357,964.510620 1245.979858,967.173767 1243.189331,968.890198 
	C1240.207153,970.724426 1236.988159,972.355530 1233.478638,969.847656 
	C1232.391235,969.070740 1231.400269,968.173889 1230.593262,967.121582 
	C1229.261230,965.384705 1227.864868,964.399292 1225.520630,965.453491 
	C1223.287109,966.458008 1221.221802,965.895203 1219.942627,963.694092 
	C1218.655762,961.480042 1220.344116,960.445251 1221.846436,959.300415 
	C1223.529663,958.017700 1224.400513,956.039734 1222.323120,954.827209 
	C1218.725220,952.727051 1216.302246,949.069641 1212.151123,947.468506 
	C1217.663330,952.581970 1219.638672,965.581421 1215.623047,971.491028 
	C1214.062988,973.786926 1211.547729,975.272644 1208.273560,975.220093 
	C1206.382568,973.586670 1204.262329,973.377502 1203.209839,975.019043 
	C1201.044678,978.395752 1198.452881,977.698059 1195.289795,976.826721 
	C1193.933350,977.000305 1192.866577,977.000549 1191.399902,977.001587 
	C1190.844971,976.842163 1190.689941,976.681885 1190.417480,976.208862 
	C1190.099854,974.564148 1190.520020,973.302612 1190.794189,971.708984 
	C1191.080200,970.399048 1191.202637,969.398193 1190.778809,968.090576 
	C1190.459717,966.733398 1190.273560,965.672363 1190.184570,964.269104 
	C1190.243896,963.728149 1190.291260,963.515381 1190.464600,963.009949 
	C1190.785400,962.301697 1191.389282,961.705994 1191.101196,961.503967 
	C1190.154663,960.840393 1189.977783,962.167053 1189.036133,962.575684 
	C1184.687744,963.898987 1182.123535,962.097046 1180.650879,958.478271 
	C1179.890381,956.609924 1178.670410,955.138611 1177.150757,953.415894 
	C1176.841187,952.942688 1176.737183,952.746643 1176.542480,952.241089 
	C1176.380249,951.335022 1176.445679,950.734192 1176.326416,949.871155 
	C1176.143555,949.258118 1176.055298,948.904480 1175.996582,948.272156 
	C1176.244263,946.815857 1175.356689,945.113586 1177.049927,944.601501 
	C1178.325684,944.215576 1179.018311,945.528503 1179.648315,946.447754 
	C1180.838745,948.184937 1182.130371,949.891357 1183.033691,951.776550 
	C1184.054199,953.906128 1185.380249,954.307739 1187.677246,953.143799 
	C1189.822510,953.149719 1191.619873,953.491089 1193.432983,953.714661 
	C1195.781372,954.004272 1197.954468,952.919556 1199.211426,951.433960 
	C1200.821533,949.531311 1198.436523,947.916382 1197.368164,945.976929 
	C1196.890381,945.212585 1196.422241,944.226013 1196.208130,944.410950 
	C1195.548096,944.981018 1196.573120,945.188721 1196.997070,945.888306 
	C1198.375854,947.829102 1200.820801,949.550476 1198.659668,951.619812 
	C1196.249390,953.927551 1193.230591,953.508423 1190.247314,951.322632 
	C1190.155273,949.696228 1190.182495,948.362488 1190.500610,947.102295 
	C1190.845093,945.737976 1191.197266,944.362732 1189.674927,943.661560 
	C1188.090942,942.931885 1187.839111,944.603027 1187.251953,945.458374 
	C1186.510620,946.538147 1185.698242,947.219421 1184.398193,946.480225 
	C1183.041382,945.708740 1182.938721,944.333679 1183.033691,943.001587 
	C1183.352539,938.524597 1185.032715,934.405273 1186.333984,930.167114 
	C1187.263550,927.140259 1188.609497,923.498108 1183.378418,922.053833 
	C1181.053345,919.795776 1184.288086,917.269348 1181.912964,914.181458 
	C1180.787109,918.116882 1177.088623,920.168335 1177.799316,924.722900 
	C1177.832886,927.011108 1176.697876,927.959167 1174.361206,927.992188 
	C1173.003662,928.838806 1171.900757,929.086548 1170.332764,928.300537 
	C1169.171631,927.227478 1167.609131,926.429626 1167.634888,925.686584 
	C1167.794189,921.075500 1164.244751,920.174683 1161.090698,918.361206 
	C1161.069214,917.680481 1161.167725,917.368896 1161.308594,917.073853 
	C1162.012695,915.599243 1163.186523,913.811462 1161.885986,912.531250 
	C1160.391113,911.059570 1159.066895,913.390991 1157.273926,913.807312 
	C1155.851807,914.102905 1154.649170,914.023865 1153.566772,914.346985 
	C1151.395996,914.995056 1149.753174,915.256653 1150.795898,912.099854 
	C1151.639404,909.546265 1146.860474,908.372192 1148.867920,906.127625 
	C1151.202515,903.517029 1152.359009,908.429626 1154.716919,907.833984 
	C1156.180176,907.464417 1157.618652,907.020264 1158.433105,905.628906 
	C1158.931763,904.776917 1158.389526,904.066956 1157.842041,903.415527 
	C1156.827515,902.208435 1155.674683,902.302979 1154.265259,902.736328 
	C1152.753906,903.200989 1151.340942,903.887085 1149.497925,903.309143 
	C1145.045288,901.912598 1143.811646,903.111450 1144.733276,907.602295 
	C1145.059692,909.193237 1144.876587,910.865479 1145.860352,912.373230 
	C1147.671021,915.148132 1145.332397,914.830017 1143.368652,915.000122 
	C1141.701782,916.130066 1140.292358,916.403687 1138.368408,915.279114 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1207.989868,975.380920 
	C1210.169434,972.961243 1214.173584,973.118286 1214.948608,969.465149 
	C1216.237427,963.391418 1216.871948,957.274719 1214.086914,951.416382 
	C1212.974121,949.075806 1210.408203,948.506897 1207.659912,947.573914 
	C1210.393311,945.268250 1212.993408,945.416931 1215.200562,946.936584 
	C1218.406250,949.143677 1220.440674,952.810913 1224.763062,953.759705 
	C1226.443115,954.128479 1225.248047,959.950195 1223.256714,960.868713 
	C1222.144775,961.381592 1219.624390,960.607178 1220.948975,963.188782 
	C1221.779175,964.806763 1223.623169,965.716370 1225.102661,964.159546 
	C1228.141846,960.961304 1229.379639,962.617432 1231.215576,965.510742 
	C1233.639893,969.331116 1236.719360,971.053894 1241.617065,968.245667 
	C1244.938477,966.341309 1244.562134,964.036926 1243.173584,962.273071 
	C1241.446289,960.078735 1239.341553,959.546082 1243.120850,956.791382 
	C1244.349609,955.895874 1246.569092,953.664856 1247.997070,951.754822 
	C1248.558716,951.003540 1249.500977,951.390686 1249.942383,952.690491 
	C1251.113281,954.299561 1251.417969,955.705078 1250.279297,957.631592 
	C1251.602051,961.336060 1254.210938,958.390503 1256.624878,959.000854 
	C1257.330322,959.380371 1257.551514,959.814087 1257.609863,960.676636 
	C1257.305786,961.849426 1256.904663,962.745850 1256.167969,963.030884 
	C1250.941528,965.053833 1250.312744,969.160767 1250.957764,974.268433 
	C1249.869995,976.042053 1249.021240,977.516113 1250.028809,979.659424 
	C1250.187378,980.429932 1250.234741,980.878296 1250.257446,981.671265 
	C1249.149536,987.427368 1254.060791,983.906921 1255.748291,985.770996 
	C1253.722656,990.917542 1257.031982,992.739380 1260.824707,994.695190 
	C1261.709839,996.066589 1261.823242,997.195679 1261.177490,998.692871 
	C1262.163696,1002.898682 1257.347168,1006.209290 1259.947144,1010.622009 
	C1255.738892,1015.284851 1249.249634,1012.974243 1244.428589,1016.350464 
	C1242.444580,1017.739868 1241.419067,1014.231445 1240.593140,1012.286682 
	C1239.700439,1010.184204 1240.639282,1009.434937 1242.483765,1008.616089 
	C1244.160645,1007.871704 1245.714722,1008.759460 1247.390503,1008.397583 
	C1247.757202,1005.963684 1244.525757,1005.094910 1245.007690,1002.362427 
	C1245.000000,1002.000000 1245.319336,1001.677063 1245.159668,1001.838501 
	C1242.468140,1001.686340 1240.493774,1002.608582 1238.932251,1004.609680 
	C1237.967041,1005.846497 1237.063965,1007.882874 1235.081055,1006.227539 
	C1233.472778,1004.884888 1233.736816,1002.927185 1234.598999,1001.199280 
	C1235.032837,1000.329529 1236.022095,999.715942 1236.377686,998.832581 
	C1237.103516,997.029663 1237.779541,994.994751 1234.375732,995.721069 
	C1233.078247,995.997803 1231.507080,996.476746 1230.654785,995.272888 
	C1229.408081,993.512207 1231.798706,993.383240 1232.294556,992.398865 
	C1234.127686,988.758362 1231.951538,986.825684 1228.930664,985.543091 
	C1227.017700,984.730835 1225.094360,984.523315 1223.534790,982.528198 
	C1221.249634,979.604614 1220.110840,977.588806 1225.333008,976.093994 
	C1221.589722,973.819885 1219.218994,971.276733 1216.487061,976.610657 
	C1215.377441,978.777283 1211.312134,980.553467 1208.632202,980.499390 
	C1205.495728,980.435974 1208.489746,977.399780 1207.989868,975.380920 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M1142.332642,886.991699 
	C1139.733643,885.607666 1137.348511,884.538696 1134.739380,883.946106 
	C1132.089355,883.344299 1130.272339,881.346191 1128.093994,879.965942 
	C1122.317871,876.306152 1118.116699,869.435181 1112.138672,867.838013 
	C1104.591064,865.821472 1097.887939,860.967896 1089.383057,860.998413 
	C1088.085449,860.426575 1087.250854,859.838684 1087.776367,858.260986 
	C1088.298584,857.463440 1088.605469,856.982178 1089.036865,856.242432 
	C1092.597290,852.110413 1093.996338,847.905762 1092.092041,842.727600 
	C1091.003540,839.767578 1090.958984,836.547546 1092.422607,833.184204 
	C1095.504395,831.618652 1096.823242,833.408142 1097.169312,835.679871 
	C1097.938965,840.732666 1100.670410,845.123352 1101.578247,850.158630 
	C1102.228516,853.766235 1104.482666,856.642639 1108.356567,857.950867 
	C1111.062988,858.864868 1112.845337,858.450378 1113.934814,855.296631 
	C1114.984375,853.806152 1115.648560,852.551575 1115.825806,850.697815 
	C1116.189087,849.913818 1116.515015,849.553223 1117.295410,849.203979 
	C1121.975464,848.473145 1122.736694,846.353271 1121.475586,842.176636 
	C1119.556763,835.821045 1116.838989,829.642151 1116.353394,822.507507 
	C1116.937012,822.104919 1117.465942,822.101746 1118.260498,822.094666 
	C1120.054199,821.981445 1120.151367,823.353699 1120.864502,824.517456 
	C1121.314453,825.567871 1121.467041,826.398315 1122.141113,827.327271 
	C1122.343384,827.784790 1122.398804,827.977295 1122.438721,828.467163 
	C1122.652954,829.131287 1121.321289,829.398987 1122.510986,830.078491 
	C1122.699097,830.542847 1122.732544,830.745483 1122.762451,831.258057 
	C1122.348145,832.888489 1122.949341,833.987244 1123.678345,835.368896 
	C1124.271973,836.595398 1124.603271,837.607666 1125.531494,838.654785 
	C1125.894531,839.313232 1126.052002,839.712097 1126.252441,840.434265 
	C1126.340942,842.502930 1127.026001,843.983887 1128.362671,845.546936 
	C1128.696411,846.243103 1128.796997,846.674011 1128.842041,847.456848 
	C1128.764526,848.477234 1128.500732,849.109497 1128.224854,850.088562 
	C1128.026855,850.617371 1127.935059,850.823669 1127.677002,851.323242 
	C1126.224243,853.911255 1124.841431,856.165222 1124.446777,859.185303 
	C1124.407471,860.234924 1124.244141,860.914185 1123.897217,861.914307 
	C1121.889282,866.322388 1122.449585,869.764648 1125.910522,872.842834 
	C1131.735718,878.023621 1138.254761,880.010498 1145.971924,877.183167 
	C1150.709229,875.447571 1155.996948,874.991821 1159.538574,870.271973 
	C1160.133911,868.842529 1161.023682,868.049927 1161.945190,866.849487 
	C1162.478271,866.241455 1162.818848,865.932678 1163.460938,865.455383 
	C1166.233765,864.564575 1167.539917,862.405701 1169.558105,860.579590 
	C1170.514404,860.111511 1171.201172,860.053528 1172.468262,860.615234 
	C1173.088135,864.784119 1172.654785,868.211182 1168.076782,869.193298 
	C1166.445435,869.543213 1166.011230,871.114502 1165.139893,872.673767 
	C1163.586548,874.331787 1162.231201,875.708862 1161.217773,877.760132 
	C1160.990234,877.989441 1160.495117,877.992981 1160.247559,877.995911 
	C1157.667969,880.598083 1155.508057,883.095520 1151.342041,882.966858 
	C1148.747192,882.886719 1146.422119,884.717102 1144.335693,886.918701 
	C1143.555420,886.992859 1143.110718,886.992981 1142.332642,886.991699 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1190.735840,975.748718 
	C1191.000000,976.167053 1191.000000,976.334167 1191.000000,976.751831 
	C1190.554321,980.867920 1188.789551,983.532593 1184.594727,983.871155 
	C1179.607666,984.273743 1174.582275,984.657043 1170.889771,980.243958 
	C1169.338257,978.389648 1170.507202,977.322937 1172.167603,976.392883 
	C1173.379272,975.714172 1175.392456,975.197205 1174.300659,973.236694 
	C1173.306519,971.451599 1171.921509,972.170227 1170.617188,973.300903 
	C1169.298462,974.443970 1168.154907,973.593262 1167.178223,972.594177 
	C1166.430176,971.828979 1165.859741,970.717224 1166.821411,969.997681 
	C1171.645630,966.388611 1166.452881,965.572632 1165.072510,963.339722 
	C1166.302490,961.416382 1168.932007,962.335083 1170.228271,960.084473 
	C1173.287598,958.475098 1174.706787,956.521973 1173.227051,952.973267 
	C1173.396851,952.399719 1173.507446,952.180847 1173.905273,951.722900 
	C1174.932251,951.181885 1175.665649,951.190430 1176.696167,951.764648 
	C1179.565308,953.642578 1182.012817,955.018494 1182.354492,958.819885 
	C1182.601807,961.571228 1185.690552,961.906555 1188.602661,961.998535 
	C1189.750610,961.384644 1190.421265,961.545166 1191.020020,962.604980 
	C1191.000000,963.000000 1191.000000,963.500000 1191.000000,963.750000 
	C1190.439331,964.671509 1190.272095,965.557861 1189.657959,966.467041 
	C1189.549683,966.674866 1189.527100,967.082703 1189.704590,967.211670 
	C1190.411987,967.442200 1190.951294,967.498718 1191.733643,967.833618 
	C1192.279419,968.858032 1193.111938,969.625549 1192.216064,970.820312 
	C1191.139648,971.915100 1190.055420,972.360229 1188.490234,972.548401 
	C1186.666016,972.401245 1185.795044,973.551331 1184.384033,974.307495 
	C1183.170166,974.616150 1182.393799,974.343811 1181.506592,973.500977 
	C1181.045654,973.109314 1180.856323,972.894104 1180.730103,972.634338 
	C1180.585327,972.336914 1180.618896,972.413025 1181.040405,973.064575 
	C1181.980957,973.940063 1182.234131,974.996338 1183.369629,975.673340 
	C1183.795532,975.885132 1183.960083,975.974976 1184.367798,976.182129 
	C1185.148926,976.515869 1185.634033,976.414673 1186.317627,975.859741 
	C1186.743164,975.546875 1186.927612,975.430176 1187.428467,975.190674 
	C1188.709717,974.855591 1189.627441,974.942993 1190.735840,975.748718 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M1117.000000,987.585938 
	C1113.629639,987.045166 1110.220947,986.329285 1106.797363,987.606995 
	C1103.910889,988.684082 1102.920410,985.481750 1100.355957,985.049500 
	C1099.843384,981.509155 1099.686768,978.018372 1099.765137,974.263794 
	C1100.685669,973.420288 1101.458130,973.268127 1102.657349,973.764893 
	C1103.333862,975.949097 1103.660645,977.899475 1104.004883,979.846741 
	C1104.152222,980.680115 1104.416992,981.393494 1105.329346,981.769470 
	C1108.129150,982.923096 1114.920288,980.491028 1117.564941,978.567200 
	C1119.719238,977.000061 1122.188599,972.960510 1125.738525,976.976990 
	C1125.944946,977.210571 1126.291626,977.320190 1126.573120,977.487488 
	C1128.486938,973.070557 1133.671753,975.184875 1136.368408,972.623718 
	C1138.384644,970.708862 1133.951294,968.277283 1136.121582,966.363586 
	C1138.037354,964.674072 1139.845703,968.704956 1141.986084,967.156677 
	C1142.693604,966.645020 1142.864868,965.430847 1143.368042,964.589783 
	C1144.427368,962.819336 1146.039185,962.763855 1147.712769,963.449585 
	C1149.065674,964.003967 1150.823608,964.508972 1150.682129,966.354614 
	C1150.509155,968.607727 1148.478882,967.838684 1147.142822,968.020752 
	C1144.212280,968.419983 1143.717773,970.120239 1144.378296,972.662231 
	C1144.715576,973.960449 1146.583496,975.364563 1144.850220,976.588684 
	C1143.505005,977.538818 1141.311279,977.523804 1140.059448,976.694946 
	C1136.552734,974.373169 1134.497681,975.321289 1132.429688,978.662109 
	C1131.150513,980.728577 1128.691528,981.465149 1126.224121,980.728943 
	C1118.753906,978.500061 1117.508545,979.335754 1117.000000,987.585938 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1174.038086,951.664062 
	C1174.000000,952.000000 1174.000000,952.500000 1174.000000,952.750000 
	C1172.748291,954.202087 1171.884766,955.799866 1169.931152,956.817749 
	C1166.420166,957.806641 1163.717407,957.104675 1161.280518,954.291443 
	C1159.184204,954.383789 1157.476685,952.608643 1155.312012,953.865173 
	C1152.065308,954.168335 1151.945679,952.236572 1151.993164,950.075073 
	C1152.036743,948.095093 1152.409668,946.461975 1154.735840,946.041138 
	C1157.126465,945.608704 1159.465332,945.046875 1158.737061,941.718506 
	C1158.435059,940.337769 1157.167480,938.625549 1158.813843,937.707886 
	C1160.283936,936.888489 1161.288818,938.893738 1162.791748,939.740234 
	C1165.139771,944.325928 1166.469238,944.766235 1170.757324,942.244324 
	C1174.574341,943.084656 1172.322754,945.386780 1172.050049,947.632446 
	C1172.957397,948.943787 1174.091431,949.777466 1174.038086,951.664062 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1217.333130,1033.866211 
	C1215.518433,1034.128174 1214.008667,1034.109985 1212.576294,1034.434204 
	C1211.561401,1034.663940 1211.027100,1035.700684 1210.833130,1036.695801 
	C1210.575806,1038.014404 1211.749390,1038.228638 1212.591187,1038.702148 
	C1213.906982,1039.442139 1216.870117,1038.266724 1216.494873,1040.558472 
	C1216.069702,1043.154053 1213.159912,1041.596069 1211.370361,1041.984375 
	C1210.894775,1042.087646 1210.377563,1042.000000 1209.439697,1042.000000 
	C1208.808838,1041.860840 1208.617676,1041.721680 1208.222534,1041.291016 
	C1207.570679,1039.663818 1207.180664,1038.302490 1206.201172,1036.797607 
	C1205.841431,1035.436401 1205.913818,1034.467651 1206.758667,1033.290527 
	C1208.804443,1030.586060 1213.109497,1031.428589 1213.951782,1027.349365 
	C1213.417847,1024.557007 1211.345093,1023.760010 1209.366089,1022.988159 
	C1207.731445,1022.350586 1207.041992,1021.275085 1207.023315,1019.598755 
	C1207.009155,1018.327454 1207.440430,1017.381104 1208.607422,1016.751404 
	C1210.668457,1015.639221 1211.623901,1017.117065 1212.841187,1018.701416 
	C1214.036255,1021.100586 1215.891113,1022.211365 1218.003906,1022.832947 
	C1219.811157,1023.364563 1220.986206,1024.240234 1220.990234,1026.625977 
	C1221.696655,1030.137207 1219.467163,1031.827637 1217.333130,1033.866211 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M1209.318970,1006.158752 
	C1209.099854,1004.839172 1208.994995,1003.648132 1210.476196,1003.163269 
	C1215.063843,1001.661865 1222.801270,1008.328918 1222.139160,1013.680542 
	C1215.823486,1014.748352 1212.267456,1011.266724 1209.318970,1006.158752 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1173.643799,870.053284 
	C1174.719971,876.160950 1173.932251,881.679199 1167.335815,884.933960 
	C1169.577637,880.238586 1168.069946,873.752563 1173.643799,870.053284 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M1104.702026,878.830750 
	C1103.924561,882.319214 1102.661987,879.923035 1101.582886,879.054749 
	C1098.404907,876.497864 1095.551025,874.940552 1093.848022,880.505737 
	C1093.265869,882.408264 1092.196777,881.500305 1091.160889,880.286499 
	C1091.839355,877.260376 1093.111938,873.731689 1095.752563,873.661926 
	C1099.005615,873.575928 1103.102661,874.302979 1104.702026,878.830750 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1170.922729,959.606934 
	C1171.633423,965.119019 1168.375000,963.503174 1165.385986,963.010559 
	C1164.031494,960.844910 1158.706665,962.160522 1160.860474,957.314575 
	C1163.894409,954.796814 1166.421143,957.458679 1169.488647,957.802856 
	C1170.272583,958.318481 1170.607544,958.726257 1170.922729,959.606934 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1144.226318,887.235046 
	C1144.821167,884.563110 1146.013428,883.149780 1149.004150,882.605103 
	C1152.624023,881.945862 1156.274292,880.512634 1159.652222,878.044189 
	C1159.500854,882.278137 1157.873779,885.084717 1152.793213,884.047058 
	C1150.551392,883.589233 1148.572510,884.416809 1147.279907,886.472229 
	C1146.605347,887.544861 1145.790405,888.254761 1144.226318,887.235046 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1195.313477,977.169678 
	C1199.085938,975.833313 1203.170166,974.659790 1207.268188,973.537170 
	C1207.377075,973.507202 1207.637451,974.029236 1207.913574,974.646973 
	C1206.379883,975.084045 1204.192139,974.562927 1203.578613,976.434326 
	C1201.480713,982.833740 1198.557373,979.615540 1195.313477,977.169678 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M1103.000000,973.596313 
	C1102.250000,974.000000 1101.500000,974.000000 1100.375000,974.000000 
	C1100.103394,971.397522 1099.380981,968.579834 1101.914185,966.608459 
	C1102.653442,966.033203 1103.325317,964.917542 1104.409058,965.773987 
	C1105.199097,966.398254 1104.838013,967.379456 1104.309448,968.010498 
	C1103.017334,969.553284 1102.935059,971.340271 1103.000000,973.596313 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1170.253174,958.663696 
	C1167.196655,958.612244 1164.727051,956.852600 1161.396362,957.001709 
	C1161.000000,956.250000 1161.000000,955.500000 1161.000000,954.375000 
	C1163.782471,954.456055 1166.156982,956.584290 1169.596680,955.999756 
	C1170.670776,956.666565 1170.763428,957.458984 1170.253174,958.663696 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M1082.868408,992.713013 
	C1082.626709,995.665283 1080.946777,994.264954 1079.327881,994.037354 
	C1079.174316,992.661011 1079.406616,991.154236 1080.739502,990.649475 
	C1081.970703,990.183289 1082.158691,991.704163 1082.868408,992.713013 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1161.322754,877.932190 
	C1160.898193,875.553528 1161.904907,873.858032 1164.640381,873.041992 
	C1164.623291,875.128723 1163.487305,876.739502 1161.322754,877.932190 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1174.967041,1028.682617 
	C1173.668823,1028.784546 1171.801514,1029.609619 1171.336426,1027.611450 
	C1171.049927,1026.380615 1172.261963,1026.130249 1173.635986,1026.013794 
	C1174.311401,1026.788330 1174.622803,1027.576782 1174.967041,1028.682617 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M1174.603882,856.095215 
	C1175.004883,855.998535 1175.488159,856.000305 1175.729492,855.998901 
	C1175.956177,857.961304 1176.322266,860.098145 1173.386230,860.961426 
	C1173.029297,861.027466 1173.003906,861.004089 1172.992188,860.992310 
	C1171.540161,858.912598 1172.218140,857.385437 1174.603882,856.095215 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M1074.992188,992.638733 
	C1072.989136,992.832764 1072.040405,991.698425 1072.007812,989.361328 
	C1074.011108,989.167114 1074.959351,990.301880 1074.992188,992.638733 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1023.794189,988.155762 
	C1024.627930,988.106750 1025.380737,988.172302 1025.421021,988.957336 
	C1025.436279,989.257751 1024.869629,989.587952 1024.284058,989.952271 
	C1023.368591,989.557617 1023.052307,989.038391 1023.794189,988.155762 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M610.342651,1201.235840 
	C608.480408,1199.776489 609.164917,1197.897217 608.647156,1196.364990 
	C607.660095,1193.444702 605.566101,1192.718872 602.407104,1193.000000 
	C599.910522,1188.519043 599.910522,1188.519043 606.683655,1188.539429 
	C603.605164,1185.526733 604.032654,1181.972290 603.957703,1178.447998 
	C603.817444,1171.851929 602.138916,1170.259033 595.731873,1169.978394 
	C594.415466,1169.920654 593.105530,1169.646973 591.799377,1169.431519 
	C586.939636,1168.630249 582.443481,1168.298462 578.576660,1172.667969 
	C576.358643,1175.174561 573.268738,1177.238892 569.384033,1177.052612 
	C567.133057,1176.944580 566.096375,1178.280396 565.986511,1180.471191 
	C565.912048,1181.955200 565.478027,1183.269165 563.581543,1182.560669 
	C562.204468,1182.046387 561.891174,1180.731445 562.742798,1179.705078 
	C566.157715,1175.589722 563.840271,1173.614380 560.107056,1172.003540 
	C558.777893,1171.430054 557.973755,1170.390015 558.135010,1168.856201 
	C558.330750,1166.993164 559.819214,1166.526611 561.289062,1166.151001 
	C563.367004,1165.620117 565.178589,1166.750854 567.111084,1167.162231 
	C568.387024,1167.433838 569.654785,1170.244019 571.055054,1167.244019 
	C572.034790,1165.144897 571.888977,1162.758789 569.981018,1161.944580 
	C566.645508,1160.520996 562.885315,1158.248047 559.354492,1161.502808 
	C556.690918,1163.958252 555.214417,1162.963989 554.266174,1160.155518 
	C551.912415,1153.184082 549.738098,1151.799438 541.888550,1152.567505 
	C535.081055,1153.233765 533.986877,1152.139160 534.912659,1145.468506 
	C535.273621,1142.868286 534.625305,1140.438477 533.411499,1138.258301 
	C532.513367,1136.645020 531.140259,1135.231201 528.988220,1137.000488 
	C527.662109,1138.090820 525.530396,1140.154175 524.601379,1137.899170 
	C523.782288,1135.911133 525.419128,1133.201294 527.931763,1132.080078 
	C531.767273,1130.368408 533.860046,1125.903198 532.703491,1121.662476 
	C530.922668,1115.132690 528.010071,1111.916138 523.633606,1113.192383 
	C519.245789,1114.471802 515.674805,1117.673584 514.135864,1122.319946 
	C511.706238,1129.655884 512.219055,1130.561768 519.286377,1132.758911 
	C513.614563,1135.843262 513.614563,1135.843262 504.698944,1126.930786 
	C505.218781,1130.590454 503.764679,1133.118042 502.435974,1135.816650 
	C501.366943,1137.987793 501.453094,1140.758301 503.319122,1142.879272 
	C506.307190,1146.275513 507.879181,1150.277710 509.194946,1154.567261 
	C510.012390,1157.232300 509.444092,1157.898926 507.008026,1157.997192 
	C504.192993,1158.110718 501.354340,1157.688232 498.567047,1158.545898 
	C497.464935,1158.885132 496.625641,1158.189941 496.309509,1157.114014 
	C496.043915,1156.210083 496.613190,1155.692139 497.297150,1155.082520 
	C499.717407,1152.925781 501.393158,1143.681763 499.428101,1141.536011 
	C497.471527,1139.399414 494.304993,1139.074707 492.261230,1136.265015 
	C490.311981,1133.585327 489.876404,1131.598511 490.559814,1128.654175 
	C491.977142,1122.547607 491.307617,1121.677124 485.583191,1119.876953 
	C482.027496,1118.758667 480.731323,1116.088379 481.334656,1112.476929 
	C482.693451,1104.342896 480.908264,1102.844116 472.950378,1105.467407 
	C467.364716,1107.308716 464.434662,1105.966797 461.539795,1100.483032 
	C460.941925,1099.350464 459.682648,1098.587891 458.911774,1097.521973 
	C456.625885,1094.361084 453.858124,1091.254517 456.942719,1087.048096 
	C458.380341,1085.087524 457.550476,1083.237061 455.790710,1082.430786 
	C451.265808,1080.358032 450.616211,1075.740356 448.738403,1072.060547 
	C447.319244,1069.279419 447.299133,1066.482422 450.691956,1063.861694 
	C453.699799,1061.538330 455.368988,1058.411621 460.286438,1059.372314 
	C462.409302,1059.786865 463.133301,1055.737183 462.968414,1053.174438 
	C462.782440,1050.284546 463.715027,1048.963013 466.855591,1048.866699 
	C469.269196,1048.792847 471.692596,1048.131836 473.269562,1045.679565 
	C474.931366,1043.095459 477.458344,1042.616211 480.635559,1043.951416 
	C483.598755,1049.034790 483.314758,1054.472534 479.747192,1058.071533 
	C477.610504,1060.227051 477.502838,1061.867798 479.617462,1063.856689 
	C480.097260,1064.307861 480.547211,1064.794312 480.987305,1065.286011 
	C485.355743,1070.165771 484.980438,1072.514526 478.975403,1075.245728 
	C476.091187,1076.557495 475.829132,1077.805054 478.367218,1080.055176 
	C480.698608,1084.083740 483.288361,1086.872437 488.118835,1087.183716 
	C491.581024,1087.406860 494.385742,1087.020264 496.675446,1083.753662 
	C502.050812,1084.888428 507.296661,1084.903198 512.810425,1085.675049 
	C516.422058,1087.856445 520.111206,1087.526367 524.080261,1087.659424 
	C525.466614,1087.449463 526.485413,1087.394775 527.902954,1087.494141 
	C530.416626,1087.178467 532.537476,1087.242676 535.044189,1087.563599 
	C537.744141,1088.182007 539.554871,1089.642456 541.725830,1091.189453 
	C543.081299,1092.890503 542.472717,1094.632202 542.813965,1096.211792 
	C543.138855,1097.715820 544.101257,1098.470215 545.568665,1098.550049 
	C546.595764,1098.605957 547.618408,1098.310059 548.005127,1097.260010 
	C548.522217,1095.855835 547.366211,1095.218384 546.466248,1094.529907 
	C545.550598,1093.829224 544.565369,1093.193359 544.633057,1091.504150 
	C547.489197,1089.114502 550.561462,1087.432007 551.210022,1083.488159 
	C551.420959,1082.205566 552.751953,1081.278809 554.288879,1081.466187 
	C555.771301,1081.646851 556.762451,1082.676880 557.039001,1084.037354 
	C558.290344,1090.193848 557.968933,1096.287476 555.823608,1102.218384 
	C555.332153,1103.577026 553.981506,1104.017334 552.679810,1104.447144 
	C551.338318,1104.890137 549.220947,1104.453491 548.957825,1106.296631 
	C548.673645,1108.286133 550.790161,1108.806763 552.414795,1109.941772 
	C554.815857,1114.433228 551.568298,1114.004395 549.142639,1114.130737 
	C546.902588,1114.247681 543.820923,1111.822754 542.673645,1113.949341 
	C541.429504,1116.255737 545.347778,1117.477661 546.337524,1119.670288 
	C547.011108,1121.162598 548.829773,1122.540283 548.581726,1124.010254 
	C547.512878,1130.344360 553.370117,1131.479492 556.288574,1134.768433 
	C557.113770,1135.698364 558.714355,1136.572632 559.725464,1135.123047 
	C560.681946,1133.751831 559.312073,1132.715576 558.344849,1131.821045 
	C557.734863,1131.256836 556.949585,1130.920288 556.295959,1130.415161 
	C553.115662,1127.957397 552.560669,1123.486816 555.053467,1120.318726 
	C555.771545,1119.406006 556.777649,1118.761597 557.550232,1117.893555 
	C560.888977,1114.142822 560.388916,1112.661377 555.138184,1111.401367 
	C555.232605,1107.139038 558.316711,1105.017578 561.750793,1106.897705 
	C564.303101,1108.295044 566.439209,1109.327026 569.556335,1108.494263 
	C572.178406,1107.793701 574.050659,1110.059937 575.792053,1112.375732 
	C576.674011,1115.402832 577.258301,1118.097290 578.353882,1120.749756 
	C579.985474,1124.700073 578.571167,1128.546265 576.130432,1131.953857 
	C575.389526,1132.988403 573.919556,1133.459961 573.695679,1135.686157 
	C576.332886,1133.212280 578.254456,1130.580811 581.513367,1129.827881 
	C582.354736,1129.633545 582.522522,1128.371094 582.145691,1127.656250 
	C579.364990,1122.381714 582.200317,1116.834106 581.361328,1111.147461 
	C583.073120,1108.254517 585.430115,1106.864624 588.414734,1106.827271 
	C591.831238,1106.784546 594.338318,1110.039917 592.302124,1112.262329 
	C589.157532,1115.694580 592.054382,1116.981934 593.528748,1119.325439 
	C593.324707,1120.122070 592.995361,1120.457031 592.671997,1120.811646 
	C592.435059,1121.071533 591.660828,1121.190308 592.166626,1121.694824 
	C592.322205,1121.849976 592.895325,1121.214111 593.539062,1120.705322 
	C594.244446,1120.273193 594.697205,1120.131958 595.536804,1120.049683 
	C596.143372,1120.092651 596.383545,1120.140259 596.961792,1120.331543 
	C598.777832,1122.365356 601.869629,1122.336914 602.720703,1125.372925 
	C602.698242,1126.468628 602.474121,1127.155884 602.058044,1128.154175 
	C600.390381,1133.211792 601.365967,1135.567871 605.395203,1137.578247 
	C608.953247,1139.353394 612.456360,1141.050293 616.884399,1139.392456 
	C620.298035,1141.730835 618.340515,1143.440552 616.429626,1144.875122 
	C613.548767,1147.037842 614.403259,1148.595825 616.963379,1150.088867 
	C617.827332,1150.592773 618.752258,1150.978638 619.625366,1151.462646 
	C621.799133,1152.667480 623.657043,1154.107178 622.405640,1156.974976 
	C621.298035,1159.513428 617.917419,1160.441895 615.134949,1159.062866 
	C614.392395,1158.694824 613.777527,1158.095703 613.041565,1157.707153 
	C611.443237,1156.863281 610.151794,1157.496216 610.211304,1159.125366 
	C610.339417,1162.627441 609.900940,1166.092896 609.926880,1169.573608 
	C609.938232,1171.102539 610.520813,1172.702759 612.147217,1173.158813 
	C613.948853,1173.663940 614.720093,1172.035645 615.587402,1170.852783 
	C619.606445,1165.370850 621.762390,1164.978149 627.477356,1168.625488 
	C630.063660,1170.276245 631.966370,1172.622681 633.741455,1175.006104 
	C635.991211,1178.026855 633.191833,1183.810913 628.748474,1185.824463 
	C627.362732,1186.452637 625.789856,1186.261597 623.978943,1187.086670 
	C626.100586,1189.336914 627.942688,1191.739624 626.985413,1194.965332 
	C626.201233,1197.608154 624.108887,1198.925659 621.336182,1199.907104 
	C618.554626,1199.956665 616.104187,1199.871826 613.394165,1200.942383 
	C612.314209,1201.578003 611.543945,1201.741821 610.342651,1201.235840 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M513.382507,1086.844604 
	C511.094666,1086.691895 509.333191,1085.841797 507.230225,1086.680298 
	C503.549438,1088.148193 500.604828,1086.060425 497.363770,1084.052124 
	C490.866943,1083.176270 484.755066,1082.221069 478.339600,1081.021484 
	C475.558167,1080.597900 473.987671,1079.260254 474.145172,1076.694580 
	C474.316681,1073.901245 476.707581,1074.289917 478.602814,1073.853027 
	C483.326447,1072.764160 483.942993,1070.624512 480.641632,1067.153809 
	C479.273590,1065.715454 477.883423,1064.293701 476.434845,1062.937622 
	C474.852570,1061.456421 474.938782,1059.381958 476.472717,1058.718506 
	C482.227692,1056.229370 480.907318,1051.363525 480.998108,1046.904541 
	C481.011597,1046.242920 481.000000,1045.580811 481.000000,1044.459473 
	C487.765900,1044.133911 493.416870,1038.914917 500.381989,1039.972534 
	C502.440643,1040.285034 503.101196,1038.344482 504.680176,1037.186157 
	C505.408539,1035.938843 506.164246,1035.260376 507.638153,1034.885376 
	C511.629211,1035.621094 514.776428,1035.239624 517.485657,1032.224854 
	C519.313110,1030.191406 522.433533,1030.520386 524.792053,1029.268799 
	C529.416321,1026.814819 533.341919,1023.230652 538.438477,1021.462280 
	C540.877380,1020.616089 542.980957,1019.670044 545.536987,1020.626282 
	C548.599426,1021.771851 551.394287,1021.423706 553.739563,1018.916565 
	C554.303345,1018.313782 555.051086,1017.534607 555.794678,1017.594788 
	C562.801880,1018.162231 568.784851,1013.939331 575.880371,1013.663330 
	C577.356018,1016.463318 575.621765,1017.685181 573.469116,1019.022583 
	C571.018921,1019.605408 569.051331,1020.286987 567.505249,1022.426514 
	C567.042053,1022.793640 566.842957,1022.922302 566.317749,1023.189209 
	C564.883667,1023.670959 563.788025,1024.166626 562.600403,1024.014038 
	C557.276184,1023.330444 553.404053,1025.369385 550.877625,1030.095459 
	C549.703674,1032.291748 547.638428,1032.489990 545.692200,1031.676025 
	C536.892822,1027.995850 529.875122,1032.745850 522.827515,1036.609985 
	C519.334473,1038.525146 518.275879,1042.608643 516.953186,1046.195557 
	C515.578979,1049.922241 513.331299,1052.498169 509.082916,1052.763916 
	C507.152191,1052.884766 505.244141,1052.818237 504.635498,1050.583862 
	C504.092438,1048.590454 505.669952,1047.623047 507.085632,1046.706787 
	C508.216614,1045.974976 509.640320,1045.963013 510.774597,1045.239502 
	C511.663666,1044.672241 511.600952,1043.865845 511.189880,1043.070312 
	C510.708527,1042.138672 509.964966,1041.924927 509.216278,1042.731567 
	C504.116760,1048.226685 497.757721,1051.331177 490.279236,1052.663696 
	C492.962219,1053.821411 495.600922,1054.617554 497.704834,1056.552856 
	C499.023102,1057.765503 500.515930,1056.878662 501.914215,1056.339600 
	C503.585052,1055.695312 505.246124,1054.625732 507.244812,1056.267822 
	C505.857758,1060.272461 506.057404,1063.368408 510.184937,1065.343750 
	C511.192963,1065.826172 511.754700,1066.959106 512.262390,1068.414307 
	C512.922302,1072.991455 512.710144,1077.309082 514.839783,1081.278320 
	C515.793640,1083.056030 515.540100,1085.165771 513.382507,1086.844604 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M648.736572,1215.818359 
	C654.289551,1218.751831 651.613892,1223.587646 651.762878,1227.517456 
	C651.882568,1230.675659 650.024414,1232.897461 646.520874,1233.740112 
	C641.942261,1234.841431 640.946228,1237.845215 642.016968,1244.753296 
	C642.697937,1249.146729 646.034912,1247.741577 648.607483,1247.975464 
	C650.087708,1248.109985 651.590210,1248.000000 653.541382,1248.000000 
	C651.715088,1253.199097 650.091980,1258.316772 655.222107,1262.948975 
	C655.988403,1263.640869 656.205261,1264.970337 655.090149,1265.503540 
	C653.401855,1266.310669 651.016724,1266.715454 650.181152,1264.823608 
	C647.864502,1259.578003 644.723755,1261.310669 641.402100,1263.304688 
	C638.819641,1264.854736 636.289673,1265.528076 633.457947,1263.787109 
	C631.747131,1262.735474 628.710144,1262.662354 632.039429,1259.657959 
	C633.529846,1258.312988 631.588745,1256.823486 630.453430,1257.274658 
	C625.702026,1259.163086 624.181091,1255.192627 621.569153,1253.290649 
	C621.983521,1252.101440 622.847839,1251.882324 623.290283,1252.199097 
	C628.173706,1255.694946 630.476807,1253.603027 632.258972,1248.662598 
	C634.207947,1243.259399 638.370789,1238.868286 639.586731,1233.025879 
	C640.267456,1229.754761 643.226868,1229.074341 646.066711,1228.437256 
	C648.009216,1228.001465 649.714905,1227.056763 648.585022,1224.653320 
	C647.633606,1222.629639 646.073059,1222.937500 644.441772,1224.030151 
	C642.513550,1225.321533 640.384094,1227.042847 638.126038,1225.197632 
	C636.211975,1223.633667 636.857178,1221.214478 637.477661,1219.131348 
	C637.850891,1217.878296 637.323547,1216.852661 637.048096,1215.367798 
	C639.700012,1215.000000 642.400024,1215.000000 645.550049,1215.000000 
	C646.818970,1215.233398 647.658630,1215.386108 648.736572,1215.818359 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M688.586182,1257.014160 
	C691.364136,1261.860596 687.491455,1265.474609 686.357422,1269.608887 
	C685.908447,1271.245361 683.890991,1270.986694 682.404663,1270.990601 
	C676.582153,1271.006226 670.759705,1270.998901 664.468628,1271.000000 
	C663.692017,1265.881104 667.856506,1262.642334 669.620789,1258.387573 
	C670.773560,1255.607788 675.167053,1256.640625 677.911804,1257.325195 
	C681.482544,1258.215820 684.776001,1257.882080 688.586182,1257.014160 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M508.269592,1035.737305 
	C507.193878,1036.247070 506.388977,1036.494141 505.292053,1036.870605 
	C502.733124,1034.498901 499.281097,1039.196289 497.087433,1035.358398 
	C502.245636,1031.724731 508.731079,1031.751221 514.041138,1028.614990 
	C515.395325,1027.815186 516.710327,1026.546143 518.765503,1027.754639 
	C519.267944,1032.077148 516.248169,1033.163696 513.278381,1034.317383 
	C511.746094,1034.912476 509.899292,1034.192627 508.269592,1035.737305 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M618.950928,1234.351562 
	C620.714355,1234.000000 622.428711,1234.000000 624.571533,1234.000000 
	C624.955444,1237.841675 626.051453,1242.265991 619.887207,1242.608032 
	C618.204224,1242.701538 616.552979,1243.313232 615.517944,1241.545288 
	C614.471985,1239.758545 615.367310,1238.472534 616.907288,1237.420532 
	C617.863708,1236.767090 618.647400,1235.909424 618.950928,1234.351562 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M865.323486,132.025406 
	C864.198975,131.792038 862.965271,131.466446 863.548523,130.521194 
	C866.141785,126.318214 864.598572,121.745132 864.964233,117.357323 
	C865.102112,115.703049 865.063843,113.461563 862.962280,113.394081 
	C859.997925,113.298897 856.856689,114.120979 854.733826,116.232727 
	C852.941589,118.015656 855.399780,118.961578 856.480164,120.049828 
	C857.311401,120.887123 858.254333,121.806030 857.573547,123.073807 
	C856.973022,124.192108 855.895264,123.575584 854.918640,123.511871 
	C850.608826,123.230705 846.343506,125.531349 841.887939,123.236656 
	C840.507935,122.525932 838.216736,124.333824 840.019226,126.199249 
	C842.034241,128.284546 843.673523,131.824020 848.109436,129.417160 
	C851.452942,127.603043 856.597595,130.577713 856.852051,134.214996 
	C857.060059,137.188065 858.032288,138.867874 860.908508,139.553207 
	C861.641235,139.727798 862.624451,140.234192 862.264587,141.268066 
	C862.085144,141.783539 861.379028,142.346237 860.822815,142.461594 
	C857.793396,143.089874 854.505005,144.458679 853.574524,139.490417 
	C853.206848,137.526993 851.913025,135.382904 849.419128,136.316223 
	C847.358765,137.087326 847.045593,139.355667 847.347778,141.393982 
	C847.540039,142.690781 848.134399,143.929657 848.564087,145.189163 
	C849.314880,147.390076 848.037781,149.657364 846.412231,149.385345 
	C840.780701,148.442886 842.050598,152.182449 842.015381,155.081421 
	C841.935059,161.703690 842.165833,161.146088 836.008240,158.968323 
	C832.770142,157.823120 829.717285,158.047516 829.100830,153.578949 
	C828.956360,152.531570 826.057678,151.660141 824.236023,153.001495 
	C822.140564,154.544418 823.594727,155.812759 824.610168,157.143051 
	C826.358154,159.433060 827.053223,162.227234 829.279724,164.465851 
	C831.772095,166.971848 827.984802,169.023041 826.834656,171.210190 
	C825.706055,173.356369 823.907715,173.155884 822.167786,172.034134 
	C820.358582,170.867752 818.644836,169.552109 816.901733,168.284668 
	C815.521790,167.281235 814.134766,164.986526 812.400879,167.269501 
	C810.551697,169.704361 806.326599,171.681900 808.059448,175.253159 
	C810.361816,179.998001 806.031067,180.204849 804.300659,181.943146 
	C802.228760,184.024429 799.548706,183.119598 797.444641,181.566513 
	C795.276123,179.965805 796.678650,178.837280 798.293030,177.861435 
	C800.201904,176.707596 803.369019,176.033997 802.554260,173.172729 
	C801.771423,170.423874 799.358887,168.690125 796.054260,168.765732 
	C794.952271,168.790939 793.803040,168.203537 792.729797,167.763611 
	C789.403625,166.400131 787.917786,167.730652 788.040405,171.059738 
	C788.138916,173.733963 787.290283,175.444077 785.107117,177.334335 
	C781.645020,180.332123 782.790527,188.684906 786.508423,191.699387 
	C787.495667,192.499863 789.195435,192.936279 788.368164,194.669220 
	C787.647766,196.178299 786.133057,195.362915 784.909912,195.565521 
	C778.771912,196.582306 774.418091,193.028931 769.711548,190.203506 
	C765.202087,187.496368 762.509460,188.802170 762.015564,193.851471 
	C761.867676,195.363312 761.991699,197.043991 760.248535,197.617081 
	C758.435120,198.213287 757.136902,197.135223 755.969116,195.802399 
	C752.643433,192.006531 747.216553,193.027115 745.208313,197.704575 
	C744.274475,199.879654 744.674011,202.849152 741.358032,203.945404 
	C739.656006,203.867767 738.417542,203.494339 737.234192,202.229034 
	C734.667236,200.764786 732.226440,199.691055 730.044556,198.230438 
	C728.843079,197.426086 728.033020,195.959366 728.487305,194.296417 
	C728.702148,193.510101 729.062256,192.521332 729.991699,192.526031 
	C731.789368,192.535095 732.454102,193.970306 732.968201,195.416016 
	C733.371643,196.550415 733.779358,197.798950 735.270691,197.532227 
	C736.705383,197.275635 737.655029,196.103256 737.800232,194.750320 
	C738.048096,192.441193 737.859070,190.088440 738.033020,187.767441 
	C738.364746,183.342026 740.192017,182.292038 743.975220,184.303772 
	C745.561646,185.147354 747.260620,185.369431 748.771729,184.623474 
	C750.677002,183.682892 750.021057,181.726105 749.807373,180.152969 
	C749.125305,175.131561 751.156494,173.327011 756.004517,175.061203 
	C758.722168,176.033295 760.592285,176.779846 761.182922,172.679993 
	C761.699036,169.096832 764.380676,168.191162 767.573730,169.835190 
	C771.224426,171.714813 773.808350,169.551651 775.888245,167.324005 
	C777.742004,165.338486 777.860229,162.754990 774.842102,161.116455 
	C773.536743,160.407745 772.239502,159.669708 771.011475,158.837418 
	C770.072998,158.201355 769.811890,157.125946 770.330017,156.183426 
	C771.050781,154.872208 771.986511,155.721085 772.819641,156.211731 
	C772.963257,156.296295 773.121216,156.468765 773.250183,156.449661 
	C782.434692,155.090668 789.057495,163.011398 797.861145,163.234833 
	C800.448303,163.300507 802.147766,165.613602 803.910828,167.392044 
	C805.018188,168.509079 806.231689,169.427353 807.856201,168.395432 
	C809.197021,167.543747 810.151123,166.305603 809.933899,164.665405 
	C809.066895,158.119019 808.170959,154.947159 801.228333,158.213242 
	C797.463074,159.984573 797.102356,155.521957 794.441101,155.220184 
	C794.808228,154.663513 794.863342,154.474945 794.983582,154.413223 
	C797.143921,153.303589 801.328979,155.281403 801.440796,151.421921 
	C801.556335,147.435959 799.709656,143.687378 796.226868,141.139191 
	C794.442017,139.833328 792.797791,140.539551 791.100220,141.392349 
	C789.690613,142.100494 788.181335,142.331268 786.927490,141.093201 
	C785.668701,139.850143 785.940308,138.353928 786.585022,136.917160 
	C788.099548,133.541931 788.916565,130.037796 789.071594,126.320091 
	C789.173828,123.871025 789.909973,122.764153 792.878296,124.247337 
	C799.232666,127.422440 805.533447,130.909439 813.199097,130.042389 
	C817.646790,129.539291 817.231628,126.797592 816.746887,123.966896 
	C816.302673,121.373146 814.770691,119.009888 815.024658,116.229996 
	C815.137390,114.996765 815.435303,113.854446 816.671997,113.385193 
	C818.157593,112.821526 818.702209,113.870033 819.190247,115.042854 
	C819.887512,116.718681 821.194458,117.202713 822.856079,116.537125 
	C825.287476,115.563126 827.348938,116.400558 829.357483,117.631668 
	C829.458679,119.238014 827.943604,118.855156 827.306030,119.487648 
	C826.826111,119.963814 826.208435,120.464508 826.594543,121.157204 
	C827.092346,122.050056 828.196411,122.074074 828.733276,121.498703 
	C831.676697,118.343925 836.446960,119.698021 839.536133,116.988434 
	C841.660339,115.125290 843.330444,116.717934 844.805969,117.941048 
	C847.948608,120.546288 850.006958,119.765594 850.766846,116.011620 
	C851.537048,112.207001 853.117065,110.272842 857.277222,111.505951 
	C859.240540,112.087883 860.039246,110.402901 860.380981,109.005608 
	C861.478333,104.519562 861.864075,100.045845 857.978027,96.506737 
	C856.041260,94.742920 854.668762,92.841927 854.950745,90.026085 
	C855.085876,88.676979 854.877808,87.194664 852.984558,87.218102 
	C851.105042,87.241371 850.883118,88.738693 851.051392,90.072113 
	C851.568176,94.166496 850.206604,97.867783 849.042542,101.657669 
	C848.635071,102.984093 848.447632,104.216194 846.987000,104.779457 
	C844.440613,105.761436 834.265320,102.069336 832.639526,99.548485 
	C830.573914,96.345718 828.343384,96.166473 826.322205,99.511459 
	C823.957092,103.425598 821.668579,101.621361 819.408875,99.806068 
	C816.934937,97.818672 815.593628,95.086639 818.350342,92.628723 
	C820.124268,91.047134 821.154297,88.872597 823.036926,87.540863 
	C825.782349,85.598755 826.558105,83.781120 824.600830,80.513176 
	C823.301636,78.344093 822.908386,75.436905 827.337341,74.900742 
	C833.605286,74.141968 836.100586,68.082687 832.739014,62.610420 
	C832.308350,61.909447 831.595703,61.376934 831.191772,60.665272 
	C830.399902,59.270256 827.148560,58.101013 829.166260,56.588123 
	C830.993408,55.218140 834.285461,55.127945 836.516968,57.340317 
	C840.650696,61.438671 839.542603,66.742073 839.641907,71.721817 
	C839.666260,72.943718 838.206360,72.817810 837.294128,73.119781 
	C834.692749,73.980843 832.166199,75.005035 832.051880,78.323883 
	C831.933838,81.753029 834.441040,82.707832 836.964294,84.331413 
	C842.578369,87.943611 845.045532,82.433640 848.383850,80.642670 
	C851.263855,79.097565 851.435669,75.138100 850.106567,71.613464 
	C849.403137,69.748108 847.781250,69.428810 846.588745,68.380684 
	C845.311951,67.258446 844.337585,65.602013 845.828247,64.026291 
	C847.426392,62.336967 848.680054,63.975494 849.468262,65.070404 
	C851.672852,68.133102 854.334106,70.409561 857.986450,71.579346 
	C860.075989,72.248596 860.123535,73.805229 859.203613,75.598160 
	C858.446228,77.074524 857.602051,78.571335 857.224365,80.160797 
	C856.774963,82.051498 857.280518,83.991310 859.310974,84.702026 
	C861.457764,85.453430 862.506653,83.742676 862.734375,82.073799 
	C863.194397,78.702827 864.577637,80.029297 866.725586,80.518318 
	C870.543152,81.387428 874.753113,80.839355 877.691772,84.835236 
	C878.069397,87.341759 875.473083,88.223114 875.044617,90.657562 
	C873.534607,92.870087 869.963013,93.345505 871.113220,96.954468 
	C871.644165,98.620636 871.459778,100.766968 873.428467,101.523079 
	C875.473816,102.308640 875.900818,99.783752 877.664001,99.093445 
	C878.444458,99.000000 878.888916,99.000000 879.666687,99.000061 
	C881.653503,100.353172 882.189636,102.371193 882.586975,104.244194 
	C883.446472,108.295700 885.517883,111.125000 889.727905,112.757660 
	C893.177551,113.223274 896.178894,113.073997 898.386292,110.204742 
	C899.422058,108.858406 900.908020,108.863052 902.680054,109.857422 
	C902.152344,116.049988 896.680847,115.350632 893.072144,115.717926 
	C888.605896,116.172493 886.632446,117.526726 885.724792,121.878128 
	C884.863464,126.007744 883.516418,130.249100 877.797607,130.315475 
	C875.847595,130.338104 875.073730,132.406616 873.957458,133.868561 
	C871.667908,136.867111 868.820190,138.481766 865.094238,135.360565 
	C865.470520,134.548676 865.947510,134.112411 866.515381,133.776459 
	C867.040771,133.465622 868.037781,133.231857 867.801758,132.597092 
	C867.480408,131.732681 866.382874,132.249084 865.323486,132.025406 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M732.663208,98.086472 
	C735.942749,98.273201 738.664917,97.462898 741.658325,96.153488 
	C744.207520,94.181824 747.928772,95.603828 749.764160,92.242493 
	C751.633728,92.792747 753.231750,92.884445 752.923401,90.525490 
	C752.185974,84.884399 754.585388,79.743828 755.191895,74.322708 
	C755.815002,68.752487 754.350403,66.204216 748.944946,65.209793 
	C747.160828,64.881577 745.225708,65.268631 743.486145,64.828583 
	C740.912720,64.177574 737.773071,63.498268 740.533997,59.612076 
	C741.928162,57.649731 739.660950,54.548580 742.722168,52.949203 
	C746.775635,50.831516 752.645691,54.202888 753.900269,59.628082 
	C754.230530,61.056213 753.847534,62.966343 755.567322,63.488716 
	C758.116394,64.262978 761.130127,65.037582 761.900269,61.159328 
	C762.427795,58.502899 764.806396,57.145885 765.673523,54.789322 
	C766.056274,53.748959 766.864807,53.121136 767.973511,54.031525 
	C772.397888,57.664513 777.605042,55.401829 782.435059,55.949299 
	C784.052612,56.132641 785.340149,55.429840 785.922729,53.739849 
	C786.293335,52.664772 786.595642,51.177013 788.137451,51.633236 
	C789.574219,52.058388 789.695068,53.611805 789.956421,54.919018 
	C790.520447,57.739334 788.754578,60.990074 791.759949,63.701675 
	C791.264221,66.575905 787.384460,66.733490 787.470337,70.024239 
	C787.517517,71.830627 786.468384,73.990501 787.146118,75.397217 
	C789.592407,80.475197 793.701904,84.654549 794.508484,90.681290 
	C794.738647,92.401352 797.661621,91.909378 799.470459,92.010201 
	C804.142456,92.270630 807.179932,95.897057 806.547546,100.370583 
	C806.447449,101.078644 806.295471,101.662766 805.491150,101.810257 
	C804.375549,102.014832 803.484009,101.683586 803.094788,100.575340 
	C802.276917,98.246521 800.979065,97.762291 798.980103,99.324051 
	C798.607361,99.615227 798.045654,99.893532 797.611694,99.841293 
	C793.926758,99.397667 791.186218,96.377983 786.927063,96.794998 
	C782.658447,97.212921 780.506836,93.463295 778.776855,89.897972 
	C777.527954,87.324181 775.556213,85.771408 772.433167,85.990417 
	C769.946960,86.164764 768.405334,87.613792 767.414978,89.583794 
	C766.451721,91.500015 768.175537,92.608788 769.298889,93.776970 
	C770.220703,94.735542 771.146606,95.728210 772.220459,96.496475 
	C779.048950,101.382027 780.889954,108.338753 777.003235,115.519180 
	C775.210693,118.830788 778.445068,120.238335 779.359802,122.506706 
	C779.712097,123.380241 780.696045,123.977684 781.263733,124.790596 
	C782.190247,126.117462 785.025208,127.187393 782.889343,129.127960 
	C781.130066,130.726425 777.690613,130.985138 776.523132,129.108459 
	C773.299072,123.925713 770.268677,126.551559 766.975891,128.523102 
	C765.951172,129.136658 764.857056,129.726044 765.343018,131.201111 
	C765.766846,132.487610 766.909241,133.148697 768.081787,132.910889 
	C772.448303,132.025223 775.284546,135.990540 779.372131,136.265182 
	C781.625732,136.416595 781.108398,138.558121 779.659973,139.455444 
	C777.504456,140.790863 777.177307,142.601303 776.876343,144.878326 
	C776.368408,148.722244 769.740662,151.117554 766.102539,149.939987 
	C761.871399,148.570480 757.616821,145.835129 752.395020,147.988770 
	C750.003418,148.409637 748.207031,147.605988 746.039673,146.941071 
	C739.443542,143.395462 738.783630,143.619125 734.946655,150.584839 
	C732.763245,151.773361 730.770264,151.716354 728.402832,151.277542 
	C726.895203,151.197266 726.433655,149.176315 724.786133,150.386002 
	C724.030273,151.607895 723.368713,152.502289 722.202148,153.406265 
	C718.814209,153.755768 716.371704,155.005142 713.990723,157.118652 
	C710.097229,160.574707 705.402161,160.496048 701.423035,157.584778 
	C697.880432,163.258789 696.989563,163.621460 691.305542,161.334747 
	C689.309692,158.997025 689.187195,156.637344 689.965332,153.784012 
	C693.370850,150.408707 696.117676,146.799927 700.429016,145.033279 
	C703.263611,143.871750 703.236572,141.304062 703.241638,138.712280 
	C703.250061,134.361252 703.849976,133.868683 707.762207,135.348022 
	C710.650391,136.440109 713.238464,136.457932 716.066895,134.438736 
	C719.332397,133.846619 719.478333,131.262589 720.503418,128.914520 
	C721.384705,128.098083 722.180542,127.612488 722.986267,127.702217 
	C729.599487,128.438751 731.357910,123.278000 733.373291,118.857971 
	C735.327881,114.571175 732.008789,110.774849 727.395874,111.544594 
	C724.258423,112.068138 721.441956,113.693489 717.907349,114.130524 
	C716.999695,114.017204 716.508179,113.827682 715.740723,113.269180 
	C713.489624,106.921364 715.591003,99.811440 720.568970,97.032608 
	C721.444458,97.000183 721.888916,97.000252 722.666687,97.000313 
	C726.005249,98.214958 729.098755,98.727707 732.663208,98.086472 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M739.692993,218.760468 
	C740.764221,220.830063 741.616211,222.629196 742.274292,224.496658 
	C743.595703,228.246521 743.977661,231.634796 738.980042,233.092316 
	C737.557373,233.507202 736.491089,234.713959 736.048950,236.647141 
	C734.469971,239.243210 732.407837,240.841537 729.729370,239.582840 
	C726.597351,238.110962 729.165588,235.629059 729.325562,233.445053 
	C725.090149,233.353180 724.200317,238.134735 720.464050,239.123657 
	C714.796021,242.532211 708.908264,243.708679 702.726990,244.055008 
	C700.551025,244.176941 698.088379,244.946365 696.401428,243.667603 
	C692.556885,240.753357 689.204346,241.582764 686.177246,244.029633 
	C691.276855,243.846420 699.704102,251.133789 699.870850,256.288208 
	C699.968750,259.313782 698.391724,260.094818 695.702576,259.324158 
	C692.320557,258.354919 690.837463,255.513901 689.269165,252.612701 
	C689.053406,253.642715 689.360901,254.530136 689.845093,255.059525 
	C694.214111,259.836273 691.733276,265.872284 692.749756,271.249969 
	C693.261108,273.954987 690.961914,275.354065 688.048340,274.438812 
	C687.253784,274.189209 686.668396,273.349335 685.904541,273.201355 
	C680.780212,272.208801 677.306946,270.666199 681.187439,264.522705 
	C682.386475,262.624359 680.088257,262.238098 678.534180,262.297943 
	C676.207397,262.387543 673.936646,263.141815 671.595398,263.018219 
	C669.654297,262.915802 667.909729,262.220032 667.363342,260.158630 
	C666.803650,258.047119 668.099304,256.688477 669.768433,255.691528 
	C671.637329,254.575241 673.782654,254.094330 675.574158,253.290833 
	C669.862305,252.655670 664.048584,253.308044 658.122192,254.674866 
	C658.090759,258.349030 660.121643,258.504578 663.024841,257.692596 
	C665.376587,257.937225 665.983093,259.447754 666.331177,261.151978 
	C666.564148,262.292877 666.578857,263.466248 666.777710,264.613007 
	C668.301270,273.400513 665.884277,276.029541 656.927368,274.464447 
	C653.506470,273.866760 650.752747,274.251740 648.036926,276.209381 
	C645.894897,277.753387 643.563354,278.189636 640.959595,277.395966 
	C638.746643,276.721405 636.742310,277.041351 635.205688,279.602905 
	C637.962402,280.795990 640.178650,279.768494 642.416382,278.829651 
	C647.569214,276.667725 652.262817,278.318512 654.674866,283.499969 
	C656.546082,287.519653 658.753113,291.503662 658.376160,296.243225 
	C660.223816,296.090302 660.626404,294.596161 661.571594,293.781982 
	C663.608765,292.027161 663.701721,290.518036 660.858643,289.474518 
	C658.915344,288.761200 657.047424,287.794708 657.432129,285.265289 
	C657.842468,282.566559 660.044495,282.257996 662.260071,281.829163 
	C668.601257,280.601776 670.175720,281.821625 670.224792,288.730957 
	C668.157410,292.215332 671.270630,296.233124 668.331421,299.313202 
	C668.020325,299.639221 668.538574,300.199615 669.065002,300.330658 
	C669.966248,300.555023 670.916016,300.226379 671.268860,299.547394 
	C673.897522,294.488708 679.084778,294.620422 683.572876,293.322052 
	C684.700500,292.995819 686.036499,292.503571 686.959717,293.120087 
	C690.313416,295.359680 694.320496,296.382202 697.485718,299.289490 
	C696.672791,301.909210 694.763916,302.675171 692.751526,302.377991 
	C684.046692,301.092438 676.101807,303.445648 668.048340,307.247437 
	C666.804626,307.876862 665.560974,308.331787 664.878113,307.783997 
	C660.146851,303.988831 654.492004,304.637573 649.046509,304.212158 
	C648.588257,304.176392 647.735168,303.706299 647.703857,303.856445 
	C646.542664,309.424011 637.572754,307.780548 637.608276,314.881592 
	C637.216675,315.340210 637.028076,315.495728 636.505249,315.805237 
	C635.459656,316.163513 634.739441,316.172394 633.676392,315.975494 
	C632.188538,315.541687 631.146362,314.349670 629.533325,315.613129 
	C627.428345,316.309631 626.305359,317.646729 625.061523,319.390991 
	C623.883118,320.112579 622.911743,320.218628 621.528870,320.111267 
	C620.125793,319.812317 619.208130,319.362854 618.215210,318.322937 
	C618.001648,317.248474 618.000793,316.499390 618.000000,315.375763 
	C614.799072,309.869293 615.283630,308.118805 620.235718,306.924622 
	C613.066711,307.067474 613.066711,307.067474 610.232056,314.738342 
	C608.135925,314.877777 606.373901,315.198334 604.719116,316.112610 
	C603.411560,316.834961 602.114685,316.982239 601.390869,315.355408 
	C600.668945,313.732788 601.117737,312.194336 602.820862,311.711365 
	C606.323547,310.718079 607.277954,309.275330 605.304443,305.767120 
	C604.194763,303.794556 606.288818,303.194611 608.117310,302.896698 
	C609.883484,302.608948 612.919861,302.134460 613.071350,301.312927 
	C613.700073,297.903046 616.664734,295.983887 617.631104,293.061859 
	C618.382324,290.790405 616.380310,289.519531 614.225525,289.081696 
	C613.097473,288.852448 611.886841,288.971405 610.790161,288.661682 
	C609.652649,288.340424 608.564026,287.523621 608.583862,286.261871 
	C608.605164,284.913635 609.922668,285.070770 610.875000,285.010651 
	C615.242676,284.734772 614.000549,282.835907 612.199219,280.649597 
	C609.512817,277.388977 608.750061,274.626892 613.922302,272.933960 
	C615.922424,272.279297 617.654175,270.816254 619.524109,269.749573 
	C622.056885,268.304810 624.267883,267.141602 625.288391,271.544250 
	C625.781860,273.673126 628.375122,273.987000 630.313721,273.778717 
	C632.570557,273.536255 630.988464,271.735962 631.172119,270.623077 
	C631.563599,268.250916 633.210449,266.953735 635.650696,266.047241 
	C637.027039,266.227295 637.690552,266.993683 638.455078,267.624512 
	C643.348267,271.661774 644.069275,271.534607 647.673462,265.617859 
	C645.301697,265.403015 642.275574,266.597443 641.416321,263.487183 
	C640.759033,261.108337 640.609802,258.597015 639.995300,256.211578 
	C639.326538,256.762115 638.752747,257.693176 637.288330,257.198669 
	C636.505615,253.061340 637.535339,249.414368 639.214966,245.922134 
	C640.086548,244.109863 641.772461,242.954605 643.636353,243.382523 
	C645.729736,243.863159 644.805420,245.718079 644.465637,247.124420 
	C643.730164,250.168289 645.539307,250.221085 647.577087,249.613602 
	C653.389832,247.880768 658.378418,245.144150 659.638245,238.486221 
	C660.152832,235.767242 660.803833,232.890884 656.153259,233.593475 
	C654.706543,233.812057 652.702515,233.001343 652.701904,231.414810 
	C652.701050,229.096909 654.978149,230.052139 656.221863,230.068008 
	C658.774109,230.100586 660.369507,229.228516 660.910217,226.650009 
	C661.135376,225.576279 661.128845,223.907883 662.814514,224.441589 
	C664.566833,224.996414 664.089966,226.675507 663.489502,227.804611 
	C661.484009,231.575821 661.709412,235.527542 662.388977,239.486832 
	C662.695862,241.274826 663.357422,243.084000 665.496521,243.625122 
	C668.242554,244.319778 667.729858,241.896744 668.372620,240.593704 
	C669.021484,239.278229 670.396179,238.938477 671.482910,238.206543 
	C675.809937,235.292099 678.194641,231.505737 676.821899,226.086212 
	C676.342102,224.191864 675.053894,221.956833 677.318176,220.677856 
	C679.603943,219.386780 681.721802,221.015640 683.692993,222.234756 
	C688.136169,224.982727 691.210327,229.052185 694.057373,233.269302 
	C695.273987,235.071396 696.423279,237.021820 698.659058,235.383667 
	C701.476685,233.319183 698.781372,232.133072 697.432861,230.865372 
	C696.391418,229.886398 695.935791,228.673782 696.017944,227.274445 
	C696.120728,225.523636 695.390198,223.106857 697.672729,222.559006 
	C699.787659,222.051376 701.412170,223.486298 702.062317,225.707123 
	C702.295593,226.503830 702.658630,227.261978 702.903992,228.055801 
	C703.302307,229.344543 704.072632,230.086075 705.476807,229.772385 
	C706.667847,229.506302 706.990173,228.516434 706.945618,227.475082 
	C706.737305,222.609543 708.288086,217.412201 703.983887,213.336548 
	C702.127686,211.578934 703.621338,208.737915 702.156006,206.352814 
	C702.201904,205.139359 702.577271,204.334137 702.907898,203.157776 
	C703.441956,201.023819 703.330994,199.069351 704.719482,197.219818 
	C707.510132,197.928116 711.043579,197.253860 711.300354,201.565277 
	C711.398132,203.206131 713.245911,202.919205 714.508301,202.990265 
	C716.437439,203.098877 718.424255,202.701080 720.625610,203.834991 
	C722.927246,206.382828 726.458618,205.350113 728.743774,207.758240 
	C729.496521,209.197601 729.993042,210.395187 730.744751,211.796387 
	C732.265625,213.493515 731.992676,215.019684 730.849609,216.870956 
	C727.668823,219.435486 724.796570,217.552811 721.547058,217.068634 
	C719.790771,217.200439 718.591614,216.215637 717.080933,216.188187 
	C717.997620,217.879730 719.579346,218.397186 721.208923,219.420868 
	C725.619690,223.731918 730.872437,223.824493 735.517578,219.603821 
	C736.871460,218.572662 738.027283,218.103409 739.692993,218.760468 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M717.244995,134.750214 
	C713.430054,139.006577 710.838074,139.157013 704.579712,135.527756 
	C704.729370,137.293564 704.888062,138.864395 704.989075,140.438934 
	C705.098328,142.142593 705.075867,144.597015 703.706116,145.015320 
	C698.839844,146.501511 696.277283,151.198181 691.369568,152.992615 
	C684.808411,151.930450 684.686340,151.910034 683.575195,158.552261 
	C683.001343,161.982941 681.249573,163.329529 677.872864,163.433365 
	C671.097778,163.641708 670.925720,163.878586 669.541992,170.038925 
	C668.457886,174.865250 669.581299,177.429916 674.013733,179.761856 
	C676.418091,181.026840 678.174011,182.494843 677.431274,185.579102 
	C676.297485,190.287231 673.334717,193.288589 669.275635,193.231293 
	C668.250000,193.001465 667.500000,193.001083 666.375000,193.000977 
	C663.144226,191.896149 663.182129,189.145432 662.643066,186.828217 
	C661.853333,183.433167 660.947571,180.105240 659.134827,177.100525 
	C658.641663,176.283066 658.087219,175.288162 656.880310,175.581421 
	C655.922302,175.814194 655.785339,176.791382 655.693848,177.572739 
	C655.396423,180.113678 654.288147,182.319122 652.890625,184.366730 
	C650.739624,187.518417 649.429443,187.472794 646.344849,184.006943 
	C646.075500,183.215149 645.984070,182.747879 645.911072,181.921875 
	C645.772949,179.831253 646.141479,177.386368 643.017883,179.989044 
	C642.672546,180.366638 642.533508,180.509888 642.203735,180.862076 
	C641.656311,181.521271 641.557129,182.011047 641.929810,182.820511 
	C642.390076,183.671417 642.655212,184.276093 642.995056,185.245636 
	C643.445190,189.613831 641.255371,193.180740 641.389771,197.371414 
	C641.494873,200.646790 637.500671,200.692810 634.627686,199.761200 
	C631.556335,198.765305 629.546265,196.183807 626.769653,194.062805 
	C622.813354,190.624512 619.602844,186.968033 614.967529,184.874588 
	C611.831543,183.458313 611.699585,179.895935 611.467163,176.461304 
	C614.428101,175.011520 617.881958,176.200562 620.357849,173.440857 
	C621.278625,172.414551 622.410278,171.706146 622.074158,170.218307 
	C621.597534,168.109085 620.057373,169.042267 618.880005,169.338089 
	C615.867065,170.095108 614.956909,168.539444 614.954468,165.532104 
	C615.536255,161.451050 617.238770,159.106277 621.798706,159.777252 
	C627.716309,161.088760 629.883545,167.274002 635.692200,169.238174 
	C634.855164,165.995712 632.730774,164.878128 630.501892,163.952469 
	C627.678772,162.779984 625.874939,160.899750 625.692261,157.364258 
	C625.742126,156.513824 625.848206,156.038437 626.153076,155.235031 
	C626.819092,154.048721 627.575806,153.423172 628.569214,152.548279 
	C629.851685,151.803970 630.958984,151.672226 632.319458,151.392624 
	C633.093262,151.112106 633.485168,150.808701 633.579712,149.946289 
	C633.341919,147.088715 633.574036,144.493851 633.071045,141.623383 
	C632.738708,140.503189 632.605347,139.644485 631.822266,138.706772 
	C631.563721,138.218292 631.483704,138.009338 631.367310,137.463806 
	C631.597534,134.649902 634.457275,133.859894 635.174988,131.253326 
	C635.567200,130.812729 635.750671,130.659103 636.268616,130.367218 
	C640.542969,129.983688 639.885559,132.898407 640.105957,135.601074 
	C640.199524,136.427887 640.222656,136.901230 640.234131,137.725586 
	C641.645691,141.959366 642.472107,145.845947 641.741638,150.072235 
	C641.389038,152.112244 641.661255,154.306992 644.975891,154.436020 
	C651.451355,154.688126 652.337402,155.747574 653.002991,162.353195 
	C653.102478,163.340042 653.104980,164.367981 654.652283,164.830154 
	C656.562927,161.533508 658.415833,158.109833 660.731689,154.950775 
	C662.509521,152.525589 660.903625,150.496521 658.215271,149.781738 
	C655.490784,149.057327 652.838623,148.093079 650.111694,147.412872 
	C646.068054,146.404221 644.076965,144.178711 645.067993,139.872223 
	C645.411499,138.379623 645.509827,136.918671 644.218140,135.345947 
	C643.996643,130.472336 648.877930,124.212440 654.154297,122.217041 
	C659.851990,120.062325 663.236328,123.195992 666.300171,127.924217 
	C666.256897,119.362579 673.606445,115.540733 677.320923,109.498634 
	C680.275513,104.692490 685.225891,103.907806 689.918457,106.402542 
	C693.307251,108.204185 694.437866,106.674896 695.144226,103.771690 
	C695.647461,101.703293 695.082886,99.398674 696.761475,97.268303 
	C704.312927,98.188766 704.780823,98.726433 705.000549,106.163002 
	C705.034851,107.324692 704.951355,108.511673 705.164062,109.641418 
	C705.528931,111.578484 706.423218,113.071487 708.778748,112.951256 
	C711.096558,112.832962 711.964722,111.488396 711.977966,109.356026 
	C711.989319,107.527435 711.994385,105.698799 711.999756,103.870171 
	C712.018311,97.549149 713.646118,96.132271 720.741089,96.744675 
	C716.707764,101.177322 716.302368,106.638672 716.000000,112.567032 
	C716.339233,114.019882 715.702148,114.741684 714.935181,114.867157 
	C710.834595,115.537964 710.210266,119.063721 709.002808,122.020317 
	C707.487488,125.730759 708.113342,126.554443 712.223938,126.843895 
	C718.085693,127.256638 719.753052,129.680817 717.244995,134.750214 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M617.555908,317.998352 
	C618.853638,318.167542 619.704895,318.337494 620.778076,318.751282 
	C621.337708,319.377899 621.530762,319.820526 621.457397,320.693054 
	C620.593079,322.129761 619.428894,322.495728 617.883667,322.858429 
	C616.016846,322.971069 614.409973,322.550629 612.775208,323.871399 
	C611.547729,324.347443 610.611145,324.523163 609.270386,324.497009 
	C605.451294,325.042358 602.053528,325.863007 598.244202,326.430603 
	C597.411438,326.458038 596.940247,326.468170 596.129761,326.496368 
	C594.842529,326.544556 594.125610,327.107025 593.056396,327.724182 
	C592.093506,328.150360 591.409180,328.303589 590.352661,328.413391 
	C589.276001,328.447845 588.556519,328.444946 587.474304,328.454834 
	C586.625671,328.461060 586.139954,328.462097 585.287720,328.464996 
	C584.430359,328.471497 583.939514,328.475006 583.078003,328.486572 
	C578.072815,329.659363 573.558289,330.355225 569.716431,326.360077 
	C568.723694,325.327759 567.209045,325.720551 565.892578,325.954468 
	C562.516174,326.554382 560.056458,325.298981 558.318115,321.966492 
	C557.807373,319.803925 558.676697,318.399506 559.757385,317.099518 
	C561.415039,315.105408 560.526245,313.731689 558.649902,312.719543 
	C555.984009,311.281494 553.630981,312.266876 552.114136,314.447876 
	C550.685974,316.501312 552.166809,317.780792 554.185242,318.437988 
	C555.442078,318.847168 556.769043,319.140839 557.640869,320.749847 
	C557.959290,323.226135 558.256958,325.430054 557.601990,327.479187 
	C556.795837,330.001251 554.984497,332.031738 552.200500,331.784302 
	C546.398010,331.268585 542.502991,334.268890 538.628113,338.378723 
	C537.900635,338.875702 537.442017,339.081940 536.588135,339.307800 
	C532.412354,340.516418 528.614685,341.674438 524.481995,343.044495 
	C523.589905,343.174164 523.075684,343.170837 522.181396,343.045654 
	C517.086853,342.185516 512.356323,341.459167 508.936798,337.550262 
	C508.530304,337.085602 507.687653,336.601288 507.163330,336.741425 
	C501.502258,338.254669 495.621124,335.195374 489.979858,337.713104 
	C487.089844,339.002930 483.612457,340.381500 481.162018,337.467255 
	C478.462952,334.257324 476.709198,330.169312 478.327087,325.893921 
	C479.787781,322.033783 481.778473,318.289032 486.040741,316.060059 
	C488.026703,315.780670 489.412659,316.456543 491.005524,317.327759 
	C491.622406,317.496338 491.823578,317.350250 491.821686,316.966888 
	C491.820435,316.710388 491.739594,316.582245 491.462677,316.336060 
	C491.210999,315.905548 491.117310,315.721222 490.909302,315.235596 
	C490.585510,314.055481 490.454407,313.159363 490.169739,311.932556 
	C490.151886,311.095276 490.240570,310.627167 490.579712,309.863281 
	C490.987671,309.310028 491.218842,308.963196 491.509338,308.925140 
	C497.159943,308.184631 502.823364,307.656036 508.740417,307.786682 
	C507.703613,303.909912 506.196228,299.638641 511.319946,296.818024 
	C512.854919,295.973083 512.104004,294.507751 511.159271,293.432800 
	C510.169159,292.306213 508.765228,291.670319 507.698242,290.631317 
	C506.260834,289.231628 505.572113,287.603760 506.758209,285.758118 
	C507.818390,284.108307 509.514832,283.954041 511.169006,284.585175 
	C513.765625,285.575958 516.672302,285.891235 519.078247,288.174286 
	C519.490784,288.612549 519.637512,288.806091 519.955017,289.323364 
	C520.145386,292.216156 523.321472,293.457214 522.810913,296.577911 
	C522.541626,297.391113 522.301575,297.813965 521.760193,298.466248 
	C519.634338,299.809448 518.427185,301.644592 516.773743,302.986908 
	C515.894165,303.700989 514.865112,304.479523 515.276733,305.839081 
	C515.744934,307.385590 517.216736,307.422211 518.475220,307.687134 
	C520.551880,308.124237 522.765259,307.354401 525.058350,308.611084 
	C525.598328,309.250946 525.775940,309.697266 525.916138,310.536652 
	C525.918091,311.722748 525.734741,312.526184 525.638794,313.340607 
	C525.006836,318.704285 524.225891,319.267365 519.108643,318.006775 
	C518.124817,317.764465 517.179565,317.650818 515.862549,317.930450 
	C514.375916,318.468170 512.751465,318.167633 512.349243,320.284790 
	C512.137695,322.538849 508.571442,323.596375 510.263550,325.527802 
	C511.833099,327.319427 514.263306,325.844025 516.228271,324.973694 
	C521.696472,322.551849 521.698059,322.555389 524.967834,327.480255 
	C525.958435,328.972229 525.958435,328.972229 529.867615,329.636963 
	C528.903503,325.583221 524.842407,324.538757 522.643555,321.443787 
	C521.823792,318.710846 523.842590,318.321869 525.270996,317.785034 
	C527.496948,316.948456 529.303528,315.642853 531.166260,314.188995 
	C534.673096,311.451843 536.973999,308.854858 534.397827,304.051178 
	C533.185669,301.791077 534.115784,299.390625 537.262207,298.942505 
	C538.971375,298.699036 540.851318,298.172882 541.737671,296.940613 
	C547.142700,289.426300 553.069275,295.408264 558.763062,296.110107 
	C559.346008,296.181976 559.704285,297.011536 560.495483,297.640686 
	C560.942932,298.000397 561.107117,298.161102 561.468201,298.602814 
	C562.659424,300.378418 562.043030,302.465729 563.737000,304.399841 
	C566.631836,308.427399 564.347778,313.282196 567.658936,316.720764 
	C569.423035,318.552826 571.008118,319.461487 573.242065,318.707550 
	C576.335449,317.663574 579.748840,317.369904 582.775330,316.697327 
	C586.202087,315.935852 591.687378,315.839661 595.059082,318.958252 
	C598.521179,322.160461 602.562988,321.821869 605.616821,320.227051 
	C609.453064,318.223541 613.136658,317.976044 617.555908,317.998352 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M669.042358,193.348053 
	C671.663635,192.396606 676.862183,186.237106 676.581604,183.887512 
	C676.404419,182.403854 675.173218,181.182144 673.960022,181.270889 
	C669.595398,181.590271 668.851196,178.357239 667.763367,175.446243 
	C665.100464,168.320709 670.276245,161.318161 677.756165,161.958832 
	C680.594666,162.201965 682.336426,161.488968 682.004822,158.237747 
	C681.954651,157.745270 681.956909,157.235947 682.019836,156.745316 
	C682.359070,154.102417 682.513428,150.940048 684.813354,149.584351 
	C687.300842,148.118134 688.500305,151.701035 690.788574,152.737946 
	C691.169556,155.429581 690.530640,157.933304 691.838867,160.634964 
	C692.925476,163.552628 691.289795,165.843460 691.239563,168.308197 
	C691.208252,169.841614 691.159851,171.290436 692.264282,172.528275 
	C693.392151,173.792389 694.623901,173.512421 695.862915,172.803833 
	C697.400940,171.924286 696.789246,170.920517 695.938171,169.963287 
	C694.635437,168.498047 693.600525,166.914520 695.261047,165.148926 
	C696.866638,163.441727 698.959106,163.584167 700.795105,164.616959 
	C703.952759,166.393234 706.759644,166.409363 709.385498,163.717667 
	C709.726685,163.367935 710.227112,163.065140 710.701660,163.036804 
	C716.801331,162.672363 719.494995,158.473709 721.759277,153.251312 
	C722.045044,151.868927 722.462524,150.936905 723.718750,150.150665 
	C724.756042,149.376831 725.561707,149.468765 726.693176,149.916443 
	C729.370544,149.999512 731.741150,149.999634 734.555847,149.999573 
	C738.994141,150.229614 742.504211,149.136627 745.672607,146.107391 
	C748.001526,145.895630 749.918823,146.111649 751.769775,147.728210 
	C751.781860,149.750961 750.507324,149.903931 749.098083,150.047806 
	C746.712585,150.291351 744.428650,151.253860 742.917419,152.965195 
	C741.696655,154.347610 738.938721,155.170639 739.629211,157.482620 
	C740.280945,159.664612 742.713318,158.862473 744.454590,159.013199 
	C746.825439,159.218445 747.465271,160.355957 746.105469,162.280655 
	C743.907043,165.392395 741.567810,168.404816 739.281494,171.454285 
	C736.870972,174.669495 735.922729,169.804504 733.729614,170.658737 
	C733.014099,171.213654 733.584412,171.895950 734.045532,172.558762 
	C735.094788,174.067230 735.516296,175.708023 734.082031,177.079636 
	C732.490356,178.601761 731.469604,176.596909 730.195496,176.122681 
	C726.907227,174.898788 723.968506,170.557297 720.028442,174.615250 
	C716.345154,178.408691 718.712524,181.949326 720.908264,185.384109 
	C721.646301,186.538605 722.596558,187.910828 720.804077,188.682343 
	C719.008057,189.455429 716.926819,189.146118 716.152527,187.074966 
	C714.671753,183.113876 712.631226,181.644043 708.781860,184.424408 
	C707.244995,185.534470 706.014648,184.273483 704.752563,183.476334 
	C701.986938,181.729523 699.490479,181.490891 696.559387,183.735214 
	C692.863342,186.565155 689.671021,184.915680 688.992371,180.315781 
	C688.815369,179.115341 689.326233,177.142349 687.293274,177.466690 
	C685.443726,177.761719 685.968384,179.555496 686.447083,180.729416 
	C687.697510,183.795456 685.598206,186.562714 685.830505,189.534851 
	C685.910156,190.553772 685.168152,191.420242 684.074463,191.675644 
	C682.816406,191.969452 682.346252,191.184952 682.017334,190.112274 
	C681.005798,186.813660 679.175110,189.032471 677.833191,189.675827 
	C675.832947,190.634872 675.728516,191.563599 677.674622,193.258209 
	C679.534241,194.877563 676.276733,206.267807 673.934814,207.345566 
	C671.285583,208.564774 669.800781,207.122681 670.183044,204.715820 
	C670.794739,200.865097 669.033203,197.397156 669.042358,193.348053 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M646.669067,182.842377 
	C648.715210,186.191376 650.198914,186.885681 652.134399,182.991806 
	C653.189392,180.869431 654.223633,178.937241 654.037109,176.486237 
	C653.924072,175.000641 653.938354,173.378418 656.027771,173.098618 
	C657.986206,172.836349 659.221252,173.283340 660.358521,175.327744 
	C663.327087,180.664200 663.943542,186.642090 665.844971,192.651901 
	C665.625061,193.723862 665.251465,194.447220 664.874878,195.169037 
	C661.669067,201.314667 661.668945,201.314606 655.372314,198.013321 
	C653.536438,195.775558 651.475159,194.745117 648.504333,194.760880 
	C646.671631,194.550781 645.934998,192.967209 644.102600,192.577560 
	C644.860901,194.482208 646.569580,193.939682 647.909790,194.687439 
	C649.211243,198.015442 645.247192,199.549057 645.489380,202.826157 
	C645.670471,204.079590 641.746277,203.181808 644.689331,205.366287 
	C646.019775,207.974228 643.628052,210.057190 644.155273,212.713379 
	C639.645996,217.677597 638.636475,223.183212 640.267578,229.183563 
	C641.212341,232.659164 642.054138,235.635193 637.047424,236.635010 
	C634.483398,237.147003 635.107605,239.734619 634.966797,241.668503 
	C634.867249,243.035004 634.672363,244.531586 632.889648,244.493195 
	C631.451782,244.462219 631.205688,243.141342 630.991333,241.962997 
	C630.329468,238.323822 629.413208,234.935211 625.750732,232.893036 
	C624.589905,232.245758 623.908203,230.543594 624.629456,228.885101 
	C625.244446,227.470749 626.371521,227.075729 627.822876,226.972229 
	C629.128540,226.879120 630.420288,226.519958 631.705017,226.221359 
	C634.236023,225.633087 635.359924,224.482864 634.336792,221.587448 
	C631.941040,214.807861 632.061218,214.765396 625.345337,218.954437 
	C623.763855,217.688980 624.343079,216.254089 624.842651,214.499023 
	C626.136169,212.430801 627.356812,210.692612 624.374390,208.962219 
	C623.924194,208.200424 623.764771,207.716080 623.633423,206.808304 
	C623.365723,203.241913 624.500427,200.131531 623.021790,196.839874 
	C622.134338,194.864365 622.688965,192.042969 626.655701,192.762421 
	C629.435059,193.142288 630.517029,195.219604 632.086548,196.584946 
	C633.886047,198.150345 635.850464,199.218399 638.322205,198.916931 
	C639.554016,198.766724 641.181396,197.667648 640.650391,196.952988 
	C637.462219,192.661560 642.184143,189.545975 641.998596,185.392639 
	C642.996643,183.386292 644.527466,182.773438 646.669067,182.842377 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M594.089111,277.604858 
	C593.903442,279.125610 594.261536,280.339539 593.143677,281.554199 
	C591.168152,279.061310 589.220947,275.779419 585.974182,280.386322 
	C585.562805,280.970032 583.185608,280.129822 581.714050,280.014099 
	C579.621399,279.849518 577.728943,280.240753 576.559570,282.231232 
	C576.028076,283.135956 575.834473,284.169708 576.408752,285.059021 
	C577.085693,286.107300 578.136292,285.909851 579.017334,285.307983 
	C580.787048,284.099121 582.667358,283.192780 584.285889,285.216644 
	C585.494690,286.728210 585.402527,288.811035 584.540771,290.421295 
	C583.560730,292.252625 582.355652,294.222229 579.527710,293.400604 
	C578.660889,293.148773 577.609131,293.509644 576.645447,293.619629 
	C574.046143,293.916321 570.764832,293.372284 569.028564,294.758301 
	C566.987976,296.387268 569.097656,299.640411 569.136230,302.198975 
	C569.195740,306.146179 567.161987,305.566986 564.380188,305.009705 
	C562.013000,303.688019 563.133545,300.958984 561.244019,299.246216 
	C561.000000,299.001221 561.000000,299.000000 561.000000,299.000000 
	C560.892273,298.873962 560.665100,298.686188 560.693787,298.630646 
	C564.151306,291.939270 560.669312,283.304840 566.786011,277.282898 
	C568.458008,275.636780 569.890686,274.348053 572.047058,274.952118 
	C565.007996,274.231934 564.129944,273.567993 563.890930,266.907562 
	C563.808167,264.603790 562.565186,263.716217 560.827271,262.415405 
	C560.407288,261.673828 560.323853,261.196838 560.422974,260.345734 
	C562.540222,257.574951 565.050293,255.672684 567.671997,254.040787 
	C569.999878,252.591827 570.182190,251.345749 568.378784,249.465637 
	C567.575806,248.628479 567.046509,247.586319 566.860168,246.415543 
	C566.592773,244.736069 566.613525,243.063171 567.984375,241.843079 
	C569.387390,240.594391 571.065247,240.748215 572.602661,241.542633 
	C574.055725,242.293503 575.552979,243.001633 577.096680,244.305176 
	C577.610962,244.980972 577.811523,245.420273 577.994751,246.247925 
	C577.902161,249.127258 576.195984,252.179672 580.633667,253.434967 
	C581.955505,254.529877 583.395996,254.541016 584.454163,255.287613 
	C587.112854,257.163452 588.104248,259.133392 584.209473,261.246887 
	C582.082886,261.844727 579.070618,261.077545 579.168274,263.777496 
	C579.275208,266.734436 579.547363,270.118958 582.505920,272.101013 
	C583.099792,272.498901 583.717346,271.846344 584.481567,271.151917 
	C590.790161,267.539581 593.352295,269.255096 594.089111,277.604858 
z"/>
<path fill="#D0CFCE" opacity="1.000000" stroke="none" 
	d="
M624.749390,206.756760 
	C625.000000,207.444443 625.000000,207.888901 625.000000,208.666672 
	C626.217712,210.192551 626.589844,211.602280 626.044067,213.618484 
	C625.698669,215.424957 625.397339,216.849411 625.047974,218.636932 
	C624.985840,222.197006 625.765076,225.949005 620.240601,225.229919 
	C618.819214,223.041641 620.511047,221.132233 620.014282,218.744247 
	C619.254456,220.862747 619.806580,222.965027 618.239380,224.746613 
	C615.626221,227.067917 614.104126,224.794174 612.340515,223.863052 
	C611.197754,223.259735 610.071167,221.910080 608.790649,222.691193 
	C607.392334,223.544144 608.583801,225.066284 608.538513,226.294525 
	C608.307983,232.544418 611.284363,238.141022 611.997986,244.591705 
	C612.056152,246.185471 611.445618,247.029419 610.295959,247.874100 
	C608.711853,247.834412 607.394104,247.426132 606.144409,247.577881 
	C605.053223,247.710358 603.838623,248.320633 603.476379,249.565475 
	C603.078003,250.934402 604.151001,251.703735 605.071716,252.381927 
	C605.866394,252.967285 606.777344,253.407562 607.669800,253.844681 
	C609.445190,254.714203 610.322510,255.876923 609.015869,257.764343 
	C607.774048,259.558105 606.637573,258.556641 605.238281,257.781342 
	C601.856689,255.907852 600.744141,252.224045 598.161682,249.303284 
	C595.804077,249.286072 601.093445,246.029175 597.087524,247.090424 
	C595.841003,247.420670 594.587708,246.811569 593.005981,246.483032 
	C592.181335,246.423660 591.712524,246.359146 590.903381,246.159424 
	C588.062805,245.164719 585.327515,245.428146 582.493469,244.427292 
	C578.835754,244.129303 577.868530,239.234573 573.540894,240.238480 
	C569.682068,239.509857 568.353149,237.671127 570.329224,233.939758 
	C571.272217,233.082764 572.048157,232.799652 573.279236,232.620880 
	C579.420227,232.512390 585.193237,233.866394 591.266602,232.265106 
	C593.824097,233.147888 594.147522,235.082977 594.458801,237.054672 
	C594.749878,238.898285 594.438782,241.418793 597.551331,240.795013 
	C601.045593,240.094696 599.017578,237.992203 598.510803,236.321762 
	C598.317688,235.685226 598.267578,235.047806 598.469360,234.053619 
	C601.948669,229.223724 604.628296,224.545776 603.047852,218.581482 
	C602.614868,216.947449 602.451660,215.156479 603.925049,213.991074 
	C605.624573,212.646805 606.978088,213.994919 608.532837,215.251602 
	C608.975464,215.624191 609.165649,215.754745 609.629150,216.087326 
	C610.560425,216.828918 611.294617,217.294373 612.100464,216.719193 
	C612.948608,216.113892 612.740051,215.323395 612.160095,214.623581 
	C611.741943,214.118973 611.189270,213.756790 610.664673,213.365402 
	C609.172668,212.252243 607.814392,211.006119 608.409485,208.894226 
	C608.995972,206.813156 611.115967,207.469650 612.685486,206.574707 
	C613.031433,203.780670 611.444763,206.226654 610.538208,205.680450 
	C609.079224,205.510101 608.410950,204.821793 607.887817,203.452545 
	C607.669006,202.256027 607.769836,201.464005 608.181519,200.315826 
	C610.223938,197.723312 612.464783,196.548355 615.820679,197.542511 
	C617.795959,197.930573 618.948303,198.811508 619.723694,200.630402 
	C620.013428,201.773666 619.830627,202.655228 620.516968,203.607086 
	C620.915466,203.922729 621.080383,204.048981 621.500610,204.368683 
	C622.557190,205.371353 623.697144,205.704666 624.749390,206.756760 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M594.400330,278.000244 
	C593.444458,276.855743 592.642883,275.765503 592.377869,274.557526 
	C591.447632,270.318787 589.416870,269.045044 585.320129,271.852448 
	C581.625061,272.095123 579.921143,268.982513 581.787964,267.629944 
	C585.429688,264.991364 583.010193,262.919861 582.782227,260.269012 
	C584.091370,259.881958 585.484985,260.180359 585.704224,258.591034 
	C585.868408,257.400665 584.776489,257.027191 583.950623,256.515625 
	C582.751526,255.772858 580.874268,256.449921 580.083069,254.349701 
	C582.081055,251.681854 584.611511,253.445587 586.913879,253.171463 
	C588.705139,252.958176 590.512390,253.541183 592.526611,252.711853 
	C592.987183,252.413391 593.177979,252.303589 593.684326,252.096893 
	C598.258850,256.667755 598.259583,263.118805 600.870850,268.487640 
	C601.792297,270.382050 602.048401,272.589539 602.842590,274.559631 
	C603.492310,276.171265 604.159424,277.574097 602.474792,278.966949 
	C601.035461,280.157013 599.877808,279.899994 598.590332,278.691620 
	C597.542480,277.708130 596.099304,278.063232 594.400330,278.000244 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M703.890381,203.677811 
	C703.893372,204.886383 703.609497,205.643784 702.312561,205.946381 
	C700.764343,205.397705 699.750916,205.067291 698.426270,206.246368 
	C692.230103,211.761597 682.611450,208.889404 680.507690,201.045990 
	C679.610474,197.700851 680.285095,195.585052 684.211548,196.301453 
	C685.900635,196.609650 686.924377,196.513458 688.030090,195.060104 
	C690.724915,191.517975 693.618225,192.398529 695.646423,195.595657 
	C697.290527,198.187225 698.630615,197.439880 700.681030,196.058258 
	C702.390503,198.276566 701.635803,201.364120 703.890381,203.677811 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M654.662903,198.754898 
	C655.461365,201.941696 657.829651,203.327637 659.892639,204.941162 
	C663.314880,207.617706 664.890076,210.890976 663.328613,215.975403 
	C661.617065,213.450623 660.076965,211.458557 657.011169,211.982864 
	C655.056885,212.317047 654.401306,213.730438 653.337769,214.974701 
	C651.981323,216.561676 652.546509,217.906830 653.199890,219.524963 
	C654.834229,223.572723 652.321594,225.712845 649.184143,227.324936 
	C647.370911,228.256638 645.997559,227.211502 644.985229,225.747192 
	C644.203064,224.615829 643.519226,222.574799 644.751221,222.212555 
	C650.633301,220.483109 647.023010,217.423676 646.045044,214.353577 
	C647.723206,210.573456 647.484741,206.719116 648.369263,203.108627 
	C649.136292,199.977966 650.362000,197.481445 654.662903,198.754898 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M729.937622,217.389114 
	C730.328064,215.578644 730.656128,214.157272 730.992065,212.367950 
	C732.059631,211.820221 733.256042,211.168381 732.836487,210.181107 
	C731.001709,205.863556 734.446045,204.588486 736.929321,202.338257 
	C738.371948,202.159058 740.014099,201.522018 740.917969,203.650665 
	C739.797668,206.012360 737.586792,207.615067 741.635437,209.630951 
	C746.156494,211.882034 745.603516,214.449860 740.208496,218.739380 
	C738.895447,219.305923 737.790955,219.611862 736.343201,219.958893 
	C733.370972,220.878647 731.417908,219.893951 729.937622,217.389114 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M636.802490,257.202454 
	C640.210815,256.449158 638.180847,253.230026 640.226074,251.153854 
	C641.111023,254.865845 642.576233,258.179565 642.504883,261.459869 
	C642.423401,265.202484 643.649109,265.093414 646.193298,263.902649 
	C647.582703,263.252350 649.145935,262.471527 650.204285,264.063904 
	C651.466492,265.962860 649.475952,266.542053 648.389038,267.414307 
	C648.260132,267.517700 648.139771,267.633240 648.003723,267.725952 
	C646.238953,268.928864 647.960999,273.155029 644.207458,272.451050 
	C640.739319,271.800629 637.540100,270.380676 636.046631,266.347534 
	C635.598022,263.945648 634.701416,261.971100 635.000183,259.401825 
	C635.541870,258.475220 636.066650,257.933289 636.802490,257.202454 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M655.000000,198.749542 
	C651.652100,198.988480 650.353577,200.413437 649.777039,203.970276 
	C649.237732,207.297501 650.825317,211.590073 646.398682,213.978424 
	C645.528381,213.687347 645.056824,213.374084 644.292603,213.031021 
	C640.550598,210.474777 642.984375,208.574783 644.839783,206.297913 
	C644.419861,205.313232 644.267334,204.541000 644.764526,203.342712 
	C643.093323,199.446335 644.958130,197.363098 647.927124,195.341049 
	C647.822754,194.833420 647.645447,194.666824 647.734009,194.250366 
	C650.580872,194.515121 655.402649,190.097458 654.999634,197.590240 
	C655.000000,198.000000 655.000000,198.499695 655.000000,198.749542 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M593.883667,251.733963 
	C594.000000,252.000000 594.000000,252.500000 594.000000,252.750000 
	C587.104736,255.452957 581.911438,252.644608 577.214355,247.319733 
	C577.000000,246.555695 577.000000,246.111374 577.000000,245.333832 
	C575.649414,243.333847 572.314758,242.950516 573.762207,239.314117 
	C578.748169,235.920212 578.776489,241.778442 581.661560,242.933685 
	C584.464844,244.953506 587.352417,245.148132 590.661133,245.000122 
	C591.444458,245.000076 591.888916,245.000153 592.666687,245.000412 
	C593.871338,245.955780 594.902954,246.841660 594.227661,248.682983 
	C592.946960,249.730026 593.298096,250.593414 593.883667,251.733963 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M610.444153,314.999207 
	C608.557373,311.469147 610.085510,307.286926 612.197327,306.144196 
	C615.132446,304.556061 619.405884,305.376617 623.108704,305.322845 
	C623.915100,305.311127 624.667114,306.147156 624.417908,307.096649 
	C624.136536,308.168854 622.897095,308.917084 622.139771,308.695038 
	C615.651245,306.792114 617.581970,311.038605 617.998230,314.603455 
	C615.629456,315.000671 613.258850,315.000153 610.444153,314.999207 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M704.264893,203.712219 
	C699.559814,203.036789 699.564331,200.173676 700.948975,196.371246 
	C702.167358,196.164780 703.334717,196.329559 704.751038,196.747162 
	C704.825928,199.140152 705.417358,201.336334 704.264893,203.712219 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M618.333313,225.000000 
	C618.014404,222.698074 617.979553,220.394394 618.061340,218.094879 
	C618.111389,216.687958 618.635864,215.345963 620.263367,215.331238 
	C622.230225,215.313400 621.855225,217.002563 621.991272,218.245438 
	C622.237122,220.490280 621.395813,222.435959 620.158936,224.658081 
	C619.555542,225.000000 619.111084,225.000000 618.333313,225.000000 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M594.399963,248.999298 
	C593.694092,247.894867 593.388184,246.790802 593.041138,245.343674 
	C595.020264,245.190826 596.950134,246.770752 599.083801,245.299973 
	C600.219055,244.517426 601.726196,243.141266 602.598633,245.233063 
	C603.264343,246.829178 601.365173,247.191437 600.271606,247.763550 
	C599.702026,248.061554 599.076843,248.253464 598.238403,248.746887 
	C596.933289,249.000214 595.866638,248.999924 594.399963,248.999298 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M728.987305,207.604813 
	C726.156128,207.385590 722.575745,208.583755 721.017334,204.381622 
	C723.803894,204.707886 727.983582,201.997391 728.987305,207.604813 
z"/>
<path fill="#D0CDCC" opacity="1.000000" stroke="none" 
	d="
M610.045288,248.340302 
	C610.045471,246.869278 610.462524,245.937225 611.718750,245.151291 
	C614.902161,245.666153 613.855225,248.379517 614.577881,250.156464 
	C614.687073,250.424973 613.893860,251.436508 613.442871,251.491943 
	C611.431946,251.739090 610.675232,250.264862 610.045288,248.340302 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M636.687439,257.054016 
	C636.893555,257.886353 636.609741,258.643799 635.312561,258.945984 
	C635.106628,258.113800 635.390381,257.356293 636.687439,257.054016 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M201.704071,203.758331 
	C207.639450,206.268005 209.236633,200.723129 212.784592,198.265900 
	C213.494659,197.407944 213.986588,196.813751 214.634033,196.050522 
	C214.739426,195.837372 214.698822,195.364456 214.538513,195.165375 
	C213.725220,194.493011 213.046188,194.052902 212.238281,193.237335 
	C207.944992,193.050995 209.266708,189.980530 209.000031,187.415909 
	C210.092651,186.810150 210.884186,186.269241 210.938675,185.080215 
	C211.075256,182.100845 212.022995,180.350983 215.500229,179.741455 
	C218.645172,179.190216 219.161789,180.416672 220.506302,182.725922 
	C221.998444,185.288742 225.288345,183.937973 227.829666,183.657898 
	C228.621613,183.570633 228.630768,182.639313 228.259644,182.048599 
	C227.822510,181.352859 227.235413,180.738678 226.652161,180.149734 
	C224.788879,178.268219 223.926041,175.396210 225.700546,173.959625 
	C227.520279,172.486420 229.580551,174.802841 230.740662,176.624725 
	C232.383194,179.204269 234.618652,181.188187 236.856369,183.165039 
	C238.055664,184.224533 239.783981,184.514755 241.020950,183.522736 
	C242.446777,182.379227 240.877899,181.143265 240.475479,179.994675 
	C239.606018,177.513123 237.460419,176.682236 235.201553,175.854813 
	C233.311874,175.162628 231.078461,173.834396 231.556915,171.733200 
	C232.020828,169.695724 233.180176,167.279739 236.069717,167.032074 
	C238.610031,166.814316 240.775543,167.356033 241.792679,169.958115 
	C242.333435,171.341476 242.777893,172.808609 242.962921,174.276108 
	C243.482834,178.399765 246.690887,177.102692 248.768448,176.838287 
	C252.206955,176.400711 249.744568,174.246902 249.440186,172.794434 
	C248.660004,169.071457 247.425140,165.349564 251.072601,162.246414 
	C252.309433,161.194153 252.077835,159.464493 251.980499,157.982269 
	C251.687119,153.514465 253.551147,151.365295 258.578369,152.000000 
	C259.951294,155.889679 261.013092,159.484818 265.476349,161.142365 
	C267.485352,161.888443 267.457611,164.372543 266.401428,166.309128 
	C265.623138,167.736176 264.542969,168.992203 263.689636,170.794983 
	C262.515747,172.242493 260.848022,172.401535 259.885773,173.561829 
	C259.113495,174.493073 258.747314,175.571732 259.268097,176.746490 
	C259.546265,177.373901 260.120392,177.773468 260.833191,177.706116 
	C262.321594,177.565475 262.540344,176.402206 262.684204,175.232117 
	C262.823853,174.096115 262.879059,172.942337 263.876007,171.743057 
	C266.993896,170.490204 268.453217,173.737793 271.246277,173.773788 
	C272.187805,170.073318 273.134003,166.238358 278.119568,166.091232 
	C283.091644,165.944489 286.430603,168.574356 288.401520,172.695007 
	C290.218842,176.494461 288.654755,180.513870 287.831512,184.362518 
	C287.522736,185.805984 286.828888,187.077194 287.253082,188.684509 
	C288.070526,191.781769 286.113037,193.347092 283.519897,193.152832 
	C279.400757,192.844269 275.662598,193.610458 271.889313,195.105240 
	C269.826874,195.922256 267.613586,195.381470 265.263794,194.270447 
	C262.505005,192.311951 259.621185,191.643036 256.807281,190.721786 
	C252.248322,189.229202 251.518036,186.586685 254.445724,182.646912 
	C251.924728,182.591965 249.620636,181.560593 246.746063,181.496521 
	C247.558273,185.128586 246.696136,188.695602 247.722107,192.510971 
	C250.200928,198.909363 244.841156,202.046280 242.341354,206.425262 
	C247.090668,207.817810 251.441254,209.456223 255.898560,206.319382 
	C258.696960,204.349991 259.523926,202.533920 256.388824,200.211060 
	C252.894806,197.622330 252.213974,194.767990 255.996735,191.405701 
	C258.611542,190.832993 260.421417,191.594315 261.974854,193.790161 
	C263.623749,197.802917 265.922638,199.931259 270.282043,199.959915 
	C273.757416,199.982773 274.880493,202.606964 272.273285,204.728973 
	C269.080078,207.327942 268.895721,213.098282 263.032043,213.065979 
	C261.056152,212.590195 259.488861,212.230194 259.984497,215.231293 
	C260.010712,216.095505 259.953430,216.591599 259.722961,217.455444 
	C257.443817,221.702774 254.335541,222.085236 250.319824,220.048370 
	C248.459412,217.113876 246.537796,216.715347 245.218384,219.728241 
	C243.369598,223.949905 239.250214,225.783859 236.380997,228.907852 
	C234.442856,231.018082 232.161880,230.141571 230.359177,228.186661 
	C228.947815,226.656082 227.954468,224.534927 225.462769,224.675171 
	C225.043365,224.897141 224.819931,225.219589 224.996643,225.509018 
	C228.378128,231.047638 228.383331,231.044464 221.136719,234.725800 
	C220.561874,235.006760 220.186310,235.391769 220.333893,235.562500 
	C220.762390,236.058197 220.027542,235.305069 220.996429,235.228851 
	C223.068726,235.073822 224.694794,235.490128 226.390259,236.871216 
	C227.193588,238.237671 227.226257,239.320740 226.625824,240.797119 
	C222.361969,244.182098 218.464081,241.750717 214.151855,240.731903 
	C210.750687,240.188705 208.258850,241.792084 205.281464,242.854553 
	C203.558121,242.900970 202.637695,243.386963 202.651672,245.302826 
	C200.695938,247.756668 198.639130,249.692810 196.258942,251.798843 
	C195.142914,252.130295 194.385071,252.087784 193.288422,251.664307 
	C192.547470,251.134888 192.236832,250.724945 191.880676,249.892944 
	C191.540329,248.321594 191.313583,247.110962 191.024841,245.546143 
	C190.888947,244.727631 190.849640,244.259781 190.842346,243.449036 
	C191.635254,240.607391 190.384369,238.516495 189.507996,236.017303 
	C191.839310,232.415131 194.110718,235.789886 196.461334,235.750916 
	C187.798019,231.272659 186.113174,227.765518 190.450348,222.811798 
	C191.040894,219.976624 193.152893,218.153000 193.494751,215.212219 
	C193.574738,214.360977 193.674973,213.782761 193.409103,213.398895 
	C191.744217,210.995056 190.395477,208.686508 192.562363,205.763718 
	C194.966431,202.521011 198.029968,202.565506 201.704071,203.758331 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M261.657013,211.867462 
	C264.445801,212.069855 266.738281,211.996170 267.060150,208.702240 
	C267.306183,206.184204 269.641113,205.457687 271.246857,204.092941 
	C271.814148,203.610794 272.398712,202.759277 272.393066,202.081268 
	C272.383850,200.978989 271.176422,200.884201 270.487915,201.145569 
	C265.069122,203.202744 263.011688,199.603592 261.047913,195.351990 
	C261.817688,193.850372 262.780609,192.939865 264.661835,192.993744 
	C268.601501,194.227585 272.009064,194.600769 275.327698,191.940048 
	C277.512451,190.188416 280.066803,191.271271 282.500061,191.864288 
	C283.774567,192.174911 285.297485,192.225967 286.156433,190.966339 
	C287.259705,189.348358 285.409088,189.219650 284.706757,188.489639 
	C283.923248,187.675278 283.740662,186.228867 284.541443,185.829361 
	C287.959137,184.124329 286.701324,180.997452 287.119019,178.389023 
	C288.137268,172.030716 283.428223,166.628510 277.248535,167.473328 
	C274.690552,167.823044 272.651276,169.591217 273.446289,172.730576 
	C273.846588,174.311188 274.167694,175.745865 272.431305,176.583557 
	C270.713104,177.412460 269.683350,176.074615 268.578949,175.081635 
	C267.360748,173.986313 266.442932,172.494675 264.345673,172.045715 
	C263.651184,171.937454 263.359711,171.775589 263.067047,171.249893 
	C262.922211,169.416290 261.607666,167.981949 264.212982,166.553146 
	C267.346741,164.834488 266.410675,161.987549 263.252991,161.365997 
	C257.664673,160.266022 257.381134,157.159973 258.987366,152.401703 
	C260.780426,150.524139 259.392059,148.185410 260.745544,146.262314 
	C261.682159,145.399551 262.456421,145.233871 263.661438,145.754532 
	C264.042145,149.971939 266.108459,153.467316 266.574310,157.423737 
	C266.952515,160.635529 271.163727,160.781021 273.693176,162.836548 
	C274.748291,163.836960 275.589233,164.108231 276.793365,163.211853 
	C277.580566,162.506958 278.161163,162.013916 278.870850,161.260437 
	C280.715088,158.838120 282.863159,158.450958 285.194397,159.760834 
	C287.445343,161.025558 289.498535,162.009033 290.740173,158.664734 
	C291.665070,156.173401 289.854828,152.014206 287.412262,151.090240 
	C286.326782,150.679642 285.125061,150.698639 283.970703,150.560471 
	C282.550049,150.390427 280.788696,150.833588 280.378296,148.747971 
	C280.040375,147.030945 281.072205,145.913635 282.300720,144.979233 
	C283.621552,143.974640 284.923187,142.931137 286.789734,142.084198 
	C289.700195,141.625183 291.825317,142.384186 293.725250,144.712799 
	C297.227692,145.193008 297.037201,142.944305 296.999695,140.402374 
	C294.301331,131.666275 294.880859,130.158264 301.613098,129.001724 
	C305.578735,133.745255 306.995636,134.744827 313.439056,137.043427 
	C314.559387,137.885727 314.993713,138.759613 315.302917,140.098846 
	C315.531158,143.502655 314.078674,145.414139 311.263245,146.431183 
	C309.870636,146.934250 308.343719,147.446198 308.224030,149.246887 
	C308.078613,151.434891 309.886810,151.789291 311.415070,152.371292 
	C312.371918,152.735672 313.041321,153.453415 313.755341,154.603134 
	C314.343231,158.538757 316.745056,161.284729 318.283447,164.794617 
	C319.595154,168.495987 321.498138,170.507446 325.681458,168.867340 
	C327.015930,173.509506 325.151611,175.994080 320.833740,175.369431 
	C318.045288,174.966049 316.638184,176.166245 316.173553,178.495621 
	C315.565674,181.543030 315.629608,184.731506 314.943115,187.754501 
	C313.930420,192.213760 318.713257,193.186447 319.854248,196.682724 
	C322.512482,200.080292 321.813171,203.647125 321.155304,207.003418 
	C320.511627,210.287369 316.886566,211.717667 313.571655,209.419052 
	C309.184814,206.377136 303.961884,205.877716 299.402405,203.568237 
	C297.683044,202.697342 295.863098,202.648895 294.058411,203.144104 
	C290.976105,203.989914 287.912506,205.101181 285.442505,201.842361 
	C285.013763,201.276703 284.254700,201.132431 283.688354,201.673767 
	C282.893066,202.433960 283.376465,203.203110 283.933105,203.838943 
	C285.026428,205.087753 286.017029,206.429047 287.235779,207.566315 
	C288.678741,208.912857 289.361115,210.576233 288.959869,212.509735 
	C288.498810,214.731354 289.136810,216.729782 289.686127,218.838486 
	C290.846466,223.292816 288.166534,226.114975 283.656586,224.999817 
	C277.822144,223.557144 271.998535,223.505188 266.073425,223.991470 
	C261.710754,224.349518 259.989807,221.376740 259.014709,217.361450 
	C259.000000,216.555557 259.000000,216.111099 259.000000,215.333328 
	C259.004486,213.368149 259.537140,212.110626 261.657013,211.867462 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M287.235107,142.657379 
	C285.743896,144.008072 285.180237,145.777603 283.279541,146.110519 
	C282.077820,146.321030 280.918854,147.151215 281.316376,148.493774 
	C281.825409,150.212860 283.356720,149.060440 284.290161,148.949768 
	C288.256073,148.479538 290.408630,150.377457 291.970612,153.800797 
	C293.388611,156.908691 293.127136,159.303513 290.809052,161.945251 
	C289.160767,163.823669 287.890503,164.005966 286.389343,162.352066 
	C284.447632,160.212830 282.322052,159.673340 279.364197,160.951294 
	C278.890198,159.696259 279.071259,158.345886 277.051910,158.196045 
	C272.872955,157.885986 269.814270,154.445267 270.002289,150.622192 
	C270.121582,148.196793 271.248505,146.466766 273.686218,147.222107 
	C277.712158,148.469635 279.717712,145.743103 280.509216,143.188446 
	C281.576630,139.743378 283.658478,139.929550 286.582214,140.000000 
	C287.578613,140.685883 287.731842,141.458191 287.235107,142.657379 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M264.212402,145.736969 
	C263.249176,146.000137 262.499481,146.000275 261.374878,146.000504 
	C261.789825,143.566315 262.324524,141.302429 259.955597,139.195602 
	C259.115082,138.448074 259.118866,136.766449 258.702148,135.522995 
	C258.258881,134.200165 258.364990,133.178696 260.044678,133.077026 
	C261.439819,132.992554 263.178375,133.142242 262.679443,134.997238 
	C262.020752,137.446243 262.931091,138.766266 264.823792,139.915543 
	C268.262512,142.003601 266.972961,143.793167 264.212402,145.736969 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M304.632263,82.922028 
	C303.860321,83.503998 303.243713,83.962906 302.667114,83.917183 
	C300.128784,83.715897 296.677063,85.267128 296.086456,80.920532 
	C295.850494,79.184265 296.362793,76.985474 298.336823,77.337082 
	C301.178101,77.843185 304.790649,78.219490 304.632263,82.922028 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M221.454987,135.104492 
	C221.509811,133.017853 221.813629,131.226562 222.002579,129.423218 
	C222.128723,128.219284 222.242722,126.946121 223.520050,126.522667 
	C224.932709,126.054359 226.111115,126.921059 227.071762,127.851082 
	C228.155212,128.900024 228.321609,130.347534 227.533066,131.525558 
	C226.152603,133.587875 224.616211,135.636795 221.454987,135.104492 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M287.371643,143.008560 
	C287.000000,142.250000 287.000000,141.500000 287.000000,140.375000 
	C289.792755,140.648239 293.356354,140.028610 293.990784,144.621674 
	C291.915253,144.338638 289.829254,143.677872 287.371643,143.008560 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M196.999969,173.666763 
	C195.502274,174.671906 193.970306,175.689194 192.807724,173.495956 
	C192.312271,172.561264 192.015991,171.594925 192.973785,170.837021 
	C194.541077,169.596832 195.157013,171.759949 196.681091,171.942322 
	C196.999573,172.444916 196.999741,172.889221 196.999969,173.666763 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M277.514038,133.234192 
	C277.153137,132.407120 277.180511,131.779129 277.902344,131.618362 
	C278.124756,131.568832 278.678772,132.070953 278.728668,132.377808 
	C278.835297,133.033051 278.512238,133.526825 277.514038,133.234192 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M276.625000,162.999939 
	C276.439117,164.989655 275.603699,165.753143 274.062561,163.321808 
	C274.750000,162.999954 275.500000,162.999924 276.625000,162.999939 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M62.761200,1180.873535 
	C65.001488,1182.867432 66.677132,1181.825562 68.168060,1180.316040 
	C69.839417,1178.624023 71.062485,1179.717285 72.475342,1180.702393 
	C74.163071,1181.879150 73.972397,1182.933472 72.753090,1184.359497 
	C71.533501,1185.785645 72.076775,1187.451050 72.520607,1189.088623 
	C73.535492,1192.833008 75.902168,1196.324097 74.173416,1200.568359 
	C73.306183,1202.697632 76.284767,1202.757935 76.920532,1204.604004 
	C76.724358,1207.164551 75.604126,1209.171753 75.226341,1211.693604 
	C73.973129,1213.445068 72.910316,1214.918945 71.819160,1216.371460 
	C71.337578,1217.012451 70.431854,1218.201050 70.337891,1218.142822 
	C65.188118,1214.948364 64.304886,1219.289917 62.507454,1222.346191 
	C61.868244,1223.432861 60.417618,1224.250610 59.050579,1223.238892 
	C57.741821,1222.270386 57.865135,1220.761353 58.747391,1219.665039 
	C61.275105,1216.524170 61.093147,1212.894897 61.005489,1209.229980 
	C60.958820,1207.278931 60.713215,1205.567261 58.374664,1205.053223 
	C54.814140,1204.270386 56.086723,1202.435181 57.676189,1200.905151 
	C59.270584,1199.370483 60.205181,1197.860718 59.041008,1195.343994 
	C59.225040,1193.949463 59.978394,1193.482666 61.286926,1193.222412 
	C62.329208,1193.242432 62.977898,1193.475342 63.934906,1193.821167 
	C64.418404,1194.072998 64.598579,1194.191406 64.991730,1194.535400 
	C65.273773,1194.944336 65.342850,1195.127930 65.416656,1195.586914 
	C65.341827,1196.190308 65.332321,1196.519775 65.563385,1197.015625 
	C65.733879,1197.180176 66.150391,1197.448242 66.375832,1197.583984 
	C66.762108,1197.809937 66.922951,1197.900024 67.345619,1198.090576 
	C69.107368,1198.281616 69.859970,1197.743042 69.239792,1196.159912 
	C68.535339,1194.361816 67.096581,1193.295166 65.211647,1192.206055 
	C64.785065,1191.163452 64.668617,1189.758789 64.356155,1189.713745 
	C57.670944,1188.750000 60.439167,1185.065674 61.994564,1181.340332 
	C62.182320,1180.881836 62.351677,1180.809326 62.761200,1180.873535 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M80.761490,1184.140137 
	C87.123741,1186.714966 94.166901,1188.288574 95.863098,1196.668457 
	C89.623116,1197.172974 85.873672,1202.630737 87.008209,1210.460815 
	C86.663475,1211.331787 86.193268,1211.525269 85.286041,1211.165283 
	C84.879143,1207.738281 84.894775,1204.682129 80.867554,1203.464111 
	C79.809937,1203.144287 78.716499,1201.171265 78.587364,1199.863892 
	C78.066116,1194.586792 77.292442,1189.195068 80.761490,1184.140137 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M84.186234,1217.660889 
	C83.519836,1219.447021 83.519836,1219.447021 85.665375,1220.931763 
	C83.769234,1222.173584 81.055336,1222.906250 82.638596,1226.170044 
	C83.277611,1227.487549 81.590897,1227.861328 80.384758,1227.984131 
	C75.012016,1228.531006 72.634377,1224.894043 70.220551,1221.220459 
	C71.239212,1220.064331 71.869438,1220.719849 72.604111,1221.036865 
	C78.060822,1223.391235 80.066139,1222.340942 81.147957,1216.716431 
	C81.254395,1216.162964 81.866180,1215.706909 82.615997,1215.101196 
	C83.749474,1215.597778 84.346466,1216.292603 84.186234,1217.660889 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M64.638275,1191.967285 
	C66.563087,1192.154053 69.160683,1189.964722 69.479401,1192.786621 
	C69.734764,1195.047485 74.040329,1196.267822 71.145927,1198.878906 
	C69.953300,1199.954834 68.216454,1201.869629 66.101738,1199.409058 
	C66.150581,1198.906250 66.266167,1198.721924 66.191498,1198.272217 
	C65.834015,1198.006348 65.666779,1198.005859 65.248695,1198.002686 
	C64.632179,1197.628662 64.484550,1197.179932 64.708374,1196.340820 
	C64.912132,1195.848145 64.931328,1195.679199 64.966324,1195.255859 
	C64.809959,1194.873291 64.637794,1194.745117 64.189545,1194.782471 
	C63.396511,1194.774048 62.879581,1194.599976 62.184540,1194.212891 
	C61.619251,1193.616211 61.486019,1193.158569 61.878479,1192.363037 
	C62.861385,1192.065796 63.572571,1192.032715 64.638275,1191.967285 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M75.363144,1212.125977 
	C73.475510,1209.344849 73.721710,1207.076538 76.699982,1205.123535 
	C79.733917,1205.081421 82.404907,1206.073853 81.607033,1209.136475 
	C81.022499,1211.380127 79.120018,1214.022461 75.363144,1212.125977 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M97.706543,1222.064087 
	C95.635712,1222.697754 94.950546,1218.256958 92.463837,1221.072754 
	C91.234871,1219.204346 92.958153,1217.672485 93.078758,1215.947510 
	C93.194916,1214.286011 94.090927,1214.468872 95.494942,1214.958618 
	C99.333916,1216.297607 99.525047,1218.670410 97.706543,1222.064087 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M84.358414,1218.125000 
	C83.708595,1217.201050 83.373253,1216.419312 83.013245,1215.317139 
	C83.785774,1214.364868 84.582954,1213.733032 85.694878,1213.045654 
	C85.734047,1212.478027 85.458481,1211.965942 85.076225,1211.179321 
	C85.424324,1210.907349 85.879097,1210.909790 86.673615,1210.878906 
	C88.855507,1211.429077 90.842087,1212.443237 89.397903,1214.519653 
	C88.328896,1216.056641 88.703743,1220.248535 84.358414,1218.125000 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M63.767361,1173.305786 
	C64.997353,1171.026123 61.808510,1168.401733 64.952003,1166.338135 
	C67.730072,1169.206421 67.582939,1174.330688 64.111504,1175.750610 
	C63.205559,1174.863647 63.206444,1174.209595 63.767361,1173.305786 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M63.323364,1180.965332 
	C63.001362,1181.010132 62.507259,1180.977539 62.260109,1180.965820 
	C61.268276,1181.456299 59.938915,1182.468262 59.866035,1182.384644 
	C58.619152,1180.954590 57.768696,1179.328369 58.736305,1177.089355 
	C60.600777,1176.348389 62.047989,1176.953979 63.753868,1177.745972 
	C64.727318,1178.149292 65.640839,1178.215088 65.527237,1179.252319 
	C65.413589,1180.290283 64.592484,1180.722046 63.323364,1180.965332 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M101.808792,1221.842041 
	C102.305367,1221.415649 102.512604,1221.165039 102.670403,1220.886597 
	C103.306580,1219.763672 101.460754,1218.199585 103.168526,1217.367798 
	C104.007568,1216.958862 104.809624,1217.640869 104.862373,1218.584229 
	C104.968704,1220.485962 105.695412,1222.605591 103.698097,1224.018677 
	C102.777786,1224.669800 101.581367,1225.406982 100.608887,1224.269775 
	C99.745171,1223.259644 100.512344,1222.498413 101.808792,1221.842041 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M64.005127,1177.636963 
	C62.438705,1178.127808 60.675964,1179.576416 59.232365,1177.065918 
	C59.299656,1175.788574 59.611988,1174.726807 59.962997,1173.330078 
	C60.445683,1172.989136 60.889687,1172.983154 61.666588,1172.988647 
	C62.864723,1173.648315 62.951385,1174.771118 63.783554,1175.785767 
	C64.000824,1176.436646 64.001656,1176.873169 64.005127,1177.636963 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M61.767036,1192.069336 
	C62.108646,1192.520508 62.067093,1192.941895 62.015991,1193.681641 
	C61.199165,1194.246582 60.391895,1194.493286 59.292084,1194.869873 
	C56.846710,1191.639038 59.594978,1192.225830 61.767036,1192.069336 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M84.765869,1235.514160 
	C85.592834,1235.152954 86.220642,1235.180542 86.381538,1235.902100 
	C86.431099,1236.124390 85.929146,1236.678345 85.622314,1236.728394 
	C84.967094,1236.835327 84.473358,1236.512329 84.765869,1235.514160 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M102.413101,1213.658447 
	C102.611496,1214.372681 102.362907,1214.633301 101.807411,1214.521240 
	C101.692284,1214.498169 101.537987,1214.334351 101.520653,1214.217651 
	C101.437439,1213.656982 101.710571,1213.422607 102.413101,1213.658447 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M60.993408,1190.673828 
	C60.470844,1190.826660 59.970207,1191.032349 59.992886,1190.322510 
	C60.371166,1190.391602 60.749451,1190.460815 60.993408,1190.673828 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M69.481339,1156.469482 
	C69.518639,1156.450439 69.444046,1156.488403 69.481339,1156.469482 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M317.249695,166.239838 
	C316.899048,162.121857 311.584808,160.292236 312.999023,155.409302 
	C314.976898,150.597610 315.227448,150.526703 321.619171,152.965347 
	C320.599304,150.492874 319.712616,148.384537 319.723389,146.012482 
	C319.738495,142.690262 315.654388,143.696762 314.244995,141.244019 
	C313.829529,140.147995 313.659058,139.295990 313.244293,138.221985 
	C311.720825,136.396667 311.996460,134.889008 313.640198,133.221710 
	C315.866913,132.882324 317.605103,133.255844 319.295197,134.107025 
	C321.135742,135.033997 323.463104,135.900238 324.410675,133.394028 
	C325.185944,131.343369 322.486603,131.184967 321.363312,130.132751 
	C319.697723,128.572571 315.595337,130.058640 315.944489,127.520279 
	C316.176208,125.835625 319.784637,123.793518 322.119598,123.426765 
	C325.083160,122.961281 326.505463,125.673576 327.156860,128.492249 
	C327.324005,129.215485 328.083099,129.801910 328.785126,130.725281 
	C329.825226,133.835724 328.912170,136.640900 328.221802,139.223740 
	C325.717804,148.591614 327.987518,153.169861 336.873840,156.268646 
	C337.651245,157.082489 337.876282,157.764862 337.892944,158.872070 
	C337.458282,160.866226 336.043640,161.875290 335.110535,163.592285 
	C334.736298,164.032593 334.569427,164.194473 334.105164,164.540497 
	C333.150116,165.002884 332.464996,165.095016 331.420959,164.938110 
	C330.891907,164.724609 330.698151,164.599609 330.252197,164.237915 
	C330.000000,164.001221 330.000000,164.000000 330.000000,164.000305 
	C327.568909,161.983582 325.485504,160.290359 324.130463,165.181778 
	C323.004608,169.245834 320.180115,168.378815 317.249695,166.239838 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M337.261841,157.228638 
	C327.938751,156.440842 323.691071,151.093781 326.719330,140.158264 
	C327.494812,137.357895 326.098267,134.066391 328.818573,131.331116 
	C332.586334,131.113159 336.548065,129.843994 338.236115,135.081604 
	C338.603241,136.220734 342.040802,136.567657 344.124969,136.917358 
	C349.409088,137.804016 349.833862,138.698135 346.599457,142.883209 
	C346.194946,143.406601 345.746307,143.895889 345.159058,144.700592 
	C343.527588,147.054108 340.917023,146.036163 338.813049,147.344559 
	C339.509949,150.258804 343.410919,152.491821 341.333923,156.137970 
	C340.504089,157.594742 339.193787,158.176315 337.261841,157.228638 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M337.000854,157.376053 
	C341.393524,155.925171 341.908234,153.824677 338.840302,150.333603 
	C337.755249,149.098938 334.710968,148.897720 335.791382,146.586563 
	C336.682404,144.680511 339.186218,144.985382 341.167938,145.000107 
	C342.153625,145.007431 343.139465,145.000870 344.562622,145.000397 
	C348.314148,148.271500 351.201599,144.325302 354.353027,144.321701 
	C355.519806,144.320374 356.156525,141.453278 358.318207,142.922501 
	C360.624573,144.490082 359.025116,146.463196 359.007751,148.637283 
	C357.677643,148.985184 356.351685,148.902908 355.033875,148.973083 
	C353.479309,149.055862 351.672058,147.116867 350.465424,148.954254 
	C349.224884,150.843216 350.282288,152.756805 351.825073,154.677078 
	C353.603119,158.405807 352.203979,160.035141 348.383545,160.229828 
	C344.804138,160.016418 341.086639,158.998032 342.755493,164.851044 
	C343.563965,167.686523 339.817017,170.359818 336.918762,169.403961 
	C334.242096,168.521225 331.197266,167.944382 330.013367,164.369751 
	C330.166656,164.001022 330.333344,164.000809 330.750000,164.000610 
	C331.744293,164.629807 332.551666,164.916824 333.747986,164.230667 
	C334.000000,164.000610 334.000000,164.000000 334.000610,164.000000 
	C335.110565,162.999908 335.625427,161.572586 336.778259,160.222183 
	C337.000153,159.250290 337.000305,158.500595 337.000854,157.376053 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M313.850922,132.668274 
	C313.671875,134.421356 313.343781,135.842728 313.007843,137.632050 
	C311.902130,137.708389 310.673248,136.940857 309.727386,137.201309 
	C302.861023,139.092056 302.643311,134.277985 302.012390,129.400177 
	C305.727020,126.279129 311.514923,127.929138 313.850922,132.668274 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M312.549622,122.224991 
	C308.721405,119.882118 309.814392,117.302444 311.477081,114.540154 
	C315.346436,118.820076 315.667603,120.738434 312.549622,122.224991 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M385.153900,102.291367 
	C388.897156,101.254944 389.799042,102.463181 388.319061,106.934807 
	C388.010925,104.973511 386.248932,104.028641 385.153900,102.291367 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M633.802368,139.797134 
	C636.019653,143.197540 634.560181,146.740326 635.000000,150.547485 
	C634.981201,151.596069 634.797058,152.130585 634.204102,152.794098 
	C632.611084,152.999832 631.222229,153.000290 629.416626,153.000977 
	C628.474670,153.542221 627.931030,154.065018 627.202332,154.802490 
	C627.000000,155.444305 627.000000,155.888626 627.000000,156.666168 
	C625.409729,158.148712 627.331787,162.068542 623.246826,161.222656 
	C619.593140,160.671005 616.563843,160.891022 616.259216,165.727798 
	C615.188843,166.924805 615.206421,168.161041 614.773438,169.222290 
	C613.924500,171.302933 612.289978,172.294495 610.058289,172.147079 
	C608.036194,172.013504 606.719604,170.877869 605.959229,169.013550 
	C603.536438,163.073105 606.472046,156.600647 612.709656,155.190964 
	C616.470398,154.341034 618.079590,152.623489 617.833374,148.811737 
	C617.697388,146.705994 619.026794,144.647293 618.159973,142.006805 
	C615.966370,142.846451 615.638733,145.991714 613.028687,146.083771 
	C610.794312,146.162582 608.828186,145.147537 606.688721,144.912796 
	C605.011658,144.728790 603.360718,144.884628 601.754639,145.340561 
	C600.192627,145.783966 597.972778,145.951523 597.835449,147.818726 
	C597.695007,149.729416 600.048157,149.334000 601.294189,149.938156 
	C603.618103,151.064987 605.491028,152.736359 604.376648,155.358505 
	C602.126465,160.652939 603.499146,165.851105 604.386047,171.001129 
	C604.965576,174.366302 605.097717,177.048035 601.227539,178.074600 
	C597.478638,179.068954 595.080078,177.675446 594.237183,173.417877 
	C592.160522,170.832169 590.083923,167.609985 587.059998,168.528885 
	C583.754700,169.533295 583.528381,173.691635 582.341919,177.057892 
	C581.723938,179.783066 579.342896,180.095505 577.617981,181.644302 
	C577.235046,182.354233 576.991821,182.747971 576.477966,183.383560 
	C573.353333,185.902588 569.970642,185.807724 566.678162,185.309006 
	C563.136108,184.772491 560.955017,185.423981 560.340820,189.456268 
	C559.795288,193.037521 557.357849,193.861588 554.539734,191.759903 
	C551.957886,189.834442 550.355164,190.755081 548.653992,192.793732 
	C547.136658,194.612030 545.944641,196.553192 545.408142,198.897186 
	C544.879456,201.207184 543.601257,202.787048 541.014038,202.949844 
	C540.083252,203.008408 538.776184,202.805603 538.843933,204.302658 
	C538.909241,205.745041 540.058289,206.140106 541.310242,206.253830 
	C543.428284,206.446259 545.301208,205.783005 546.809998,204.356598 
	C549.718811,201.606583 552.859070,201.623306 556.523438,202.731674 
	C561.574829,204.259613 566.128235,206.478851 569.833862,210.248230 
	C571.962585,212.413483 573.134155,215.023941 571.572876,217.944061 
	C569.977234,220.928375 567.245911,221.231827 564.218445,220.385406 
	C559.111084,218.957504 553.505493,220.283524 548.615784,217.578918 
	C548.197632,217.347626 547.558411,217.300156 547.153992,217.539429 
	C542.297729,220.412537 539.465149,218.080688 537.131775,213.885132 
	C536.510620,212.768295 534.921814,211.632645 533.764893,212.325211 
	C531.871155,213.458969 533.004822,215.993927 532.106201,217.773163 
	C531.422607,219.126801 532.380005,220.007858 533.711731,220.225159 
	C537.216736,220.797134 540.471741,222.722153 544.352783,220.841187 
	C547.817627,219.161972 549.440063,220.508057 549.628296,224.606003 
	C549.709717,226.377991 549.928345,227.986191 551.316223,229.091217 
	C554.557617,231.671967 555.205994,234.973999 554.717590,238.896469 
	C554.501282,240.633972 554.790222,242.424133 552.520142,242.624191 
	C550.486511,242.803406 548.503418,242.380127 547.307617,240.406219 
	C546.962341,239.836136 546.923218,239.141953 546.744751,238.504486 
	C546.296448,236.903870 547.877869,234.276932 545.325317,233.841522 
	C542.775085,233.406494 540.479004,234.733139 539.000000,237.566772 
	C538.617432,238.060425 538.439331,238.241455 537.930298,238.621384 
	C533.796387,240.299881 530.658569,236.673462 526.562439,237.413452 
	C525.665466,237.362869 525.169067,237.264236 524.349243,236.945465 
	C520.042236,232.204865 516.604309,235.495590 512.710815,237.581253 
	C508.476837,235.984360 510.444305,233.511276 511.677979,230.906113 
	C512.964783,229.816223 514.257080,229.669754 515.738403,229.425369 
	C514.421204,228.453400 513.244263,227.899246 511.717072,227.267883 
	C510.254974,226.766800 509.139557,226.344696 507.851044,225.465027 
	C506.330841,223.534698 506.324738,221.479538 505.972473,219.536957 
	C505.427734,216.532898 505.080719,213.449783 507.768738,211.224533 
	C510.651459,208.838058 514.077026,209.680252 516.690125,211.237091 
	C520.479004,213.494507 523.022400,213.382538 525.054321,209.320129 
	C525.265564,208.897751 525.730896,208.567947 526.166260,208.339417 
	C529.244324,206.723526 530.971558,205.404312 528.320068,201.326096 
	C526.100586,197.912415 528.072510,193.546570 529.749329,189.893219 
	C530.939209,187.300705 531.568848,184.942673 529.108887,182.270187 
	C526.685303,185.098953 527.677795,188.696884 526.406982,191.575302 
	C525.808411,192.931076 525.816895,194.512085 525.313477,195.916260 
	C524.166321,199.116180 522.314636,199.626907 519.687744,197.511169 
	C518.482483,196.540497 517.707825,194.235992 515.736511,195.458954 
	C514.075134,196.489670 514.922241,198.513870 515.132996,200.097412 
	C515.573975,203.410477 514.057617,205.879349 511.928314,208.115112 
	C510.726166,209.377380 509.302765,210.234406 507.483612,209.618942 
	C505.663055,209.003006 504.928680,207.513687 504.952179,205.726852 
	C505.015961,200.874451 503.672943,197.225418 498.033264,196.754440 
	C496.120911,196.594727 495.509308,194.768661 494.243103,193.253296 
	C492.933319,193.000412 491.866669,193.000198 490.399994,193.000000 
	C488.941864,192.345413 488.246460,191.328369 487.216736,190.215027 
	C487.001221,190.000000 487.000000,190.000000 487.000000,189.999695 
	C485.121643,187.991867 485.156769,185.624725 485.870972,182.734558 
	C486.601379,181.189743 487.523834,180.479507 489.104248,179.953766 
	C490.078064,179.812790 490.626984,179.847504 491.537659,180.094559 
	C497.074188,184.296249 499.908997,183.673538 503.058899,177.711899 
	C504.002625,175.925827 505.258514,174.840683 507.736755,175.402481 
	C508.249756,175.698532 508.431946,175.853073 508.824402,176.295105 
	C509.452942,177.671021 509.783417,178.926636 510.924530,179.409729 
	C514.334778,180.853531 516.313354,183.548141 517.970337,187.077438 
	C519.095581,180.980179 521.410706,175.571243 524.758179,170.245880 
	C525.750000,170.000443 526.500000,170.000290 527.625000,170.000061 
	C528.876892,169.982056 529.766113,170.021301 530.762939,169.228760 
	C535.828674,167.214981 540.307434,164.710205 544.494507,161.787186 
	C545.693481,160.950195 546.577393,159.696686 547.580750,161.233627 
	C549.434998,164.074234 554.434204,164.683640 553.485291,169.490173 
	C553.455872,169.639481 553.537781,169.949112 553.547302,169.948303 
	C559.789246,169.407791 560.096375,177.022949 565.215515,178.310486 
	C572.911926,180.246231 578.868835,176.848114 580.431946,169.267960 
	C580.825562,167.359207 580.236328,165.991974 579.018311,164.806854 
	C577.954590,163.771927 576.825989,164.579987 575.831055,165.128632 
	C573.664795,166.323135 572.042603,169.385986 568.914978,167.225052 
	C565.663757,164.978683 568.638550,162.446564 568.667236,160.043686 
	C568.672913,159.572739 568.409546,159.098587 568.135132,158.312347 
	C567.164246,157.004913 566.906860,155.910278 567.710938,154.340836 
	C569.458008,153.708191 570.905640,153.339401 572.376160,153.139145 
	C575.514587,152.711807 578.083313,153.012558 578.048401,157.223602 
	C578.028687,159.600082 578.570740,162.141098 581.305664,162.763214 
	C584.176575,163.416260 585.427185,161.340393 586.205566,158.921951 
	C586.466614,158.110916 587.102966,157.217224 587.965332,157.428955 
	C594.007568,158.912460 592.769409,155.301682 592.112732,151.816818 
	C591.913513,150.759872 592.513794,149.564911 592.680786,148.423325 
	C592.938965,146.658279 593.765198,144.600403 591.910034,143.380844 
	C590.113220,142.199722 587.993652,141.451096 585.781006,142.642090 
	C585.634766,142.720779 585.444519,142.747147 585.333252,142.857040 
	C579.188965,148.924423 573.013916,145.098938 566.838928,142.931198 
	C566.199829,142.706818 565.411865,142.499527 565.619812,141.702179 
	C565.759521,141.166260 566.338379,140.489105 566.845886,140.357391 
	C567.950989,140.070587 569.138062,140.109894 570.288147,139.984375 
	C574.171753,139.560516 577.534180,138.795868 577.089417,133.543579 
	C576.814270,130.294800 578.718567,129.002563 582.084412,128.931076 
	C590.991516,128.741867 590.928711,128.621094 593.359314,137.386505 
	C593.733154,138.734695 593.346375,140.848007 594.920898,141.316498 
	C596.790466,141.872772 597.812500,139.858795 598.734131,138.656876 
	C601.196106,135.445938 603.830139,133.188385 608.103333,132.153839 
	C611.972229,131.217209 613.593994,127.042305 612.692139,123.263161 
	C611.714233,119.165123 608.228699,118.371277 604.311951,119.363815 
	C602.880737,119.726494 601.378296,119.940048 599.902832,119.973320 
	C593.380249,120.120422 591.809082,118.151688 593.251404,111.797615 
	C594.385986,106.799278 595.855225,106.178329 600.161560,109.049538 
	C602.969360,110.921608 604.277771,109.305473 604.765625,106.923874 
	C605.766052,102.039856 609.005127,98.617310 612.316284,95.441475 
	C615.075439,92.795021 613.791565,89.849808 613.925354,87.063545 
	C614.215149,81.031136 614.179932,81.010231 620.203003,81.782059 
	C622.194580,82.037262 625.003174,83.160255 625.226074,79.238586 
	C624.623047,78.615715 624.486511,78.159454 624.796387,77.316254 
	C627.722656,76.483170 626.658813,74.199211 627.097595,72.589096 
	C627.899597,69.646202 628.593872,67.146217 632.764160,69.582527 
	C635.683228,71.287903 639.179016,72.325722 642.813538,72.036041 
	C643.954529,71.945084 645.454590,71.927116 645.704041,73.354805 
	C646.211548,76.258980 647.993835,77.072998 650.631348,77.162903 
	C651.746033,77.200897 653.121338,77.620911 653.374023,78.914772 
	C653.680054,80.481697 652.280396,80.778938 651.141479,80.985527 
	C648.775513,81.414665 648.714844,83.151794 649.218018,84.784737 
	C649.881409,86.937317 651.373779,88.627182 653.767822,88.854156 
	C656.119751,89.077141 656.687134,86.763023 656.557495,85.453445 
	C656.144653,81.284317 658.125671,77.484856 657.980469,73.431564 
	C657.865906,70.236908 659.258118,68.834839 662.441833,68.968857 
	C663.580322,69.016769 664.746704,68.753860 665.879272,68.527367 
	C670.873657,67.528549 672.920837,68.731606 672.863098,73.875969 
	C672.810303,78.572845 675.558289,79.615677 679.046021,79.876007 
	C682.983765,80.169930 682.783752,77.007118 683.033691,74.343895 
	C683.291565,71.594513 684.473511,69.132713 686.369690,67.257668 
	C688.764526,64.889526 690.054932,62.243362 688.363037,59.262131 
	C686.341003,55.699219 682.570129,55.983994 679.020508,55.976093 
	C675.467651,55.968189 674.108765,58.259235 673.161255,61.094067 
	C671.372253,66.446770 670.475647,66.674942 665.343262,64.336525 
	C662.846619,63.199017 662.397705,61.507702 663.450439,59.615837 
	C665.049011,56.743023 666.657776,53.465408 669.181641,51.611393 
	C673.259888,48.615582 674.366150,44.900028 673.959534,40.397640 
	C673.546875,35.827953 674.974426,34.021095 679.964233,33.714867 
	C683.093628,33.522808 688.751770,35.335754 688.997925,28.403381 
	C691.397827,24.292812 694.956299,24.153614 698.712952,24.995834 
	C700.650208,25.430161 702.542542,26.125332 704.700439,27.232502 
	C707.059204,30.132940 705.937622,32.327232 703.958679,34.325668 
	C702.241150,36.060112 700.201843,37.377693 697.725098,38.730434 
	C692.307251,40.551449 691.414978,42.323441 690.746704,49.436069 
	C690.320801,53.968182 693.619385,54.840214 696.454834,56.342770 
	C699.524963,57.969654 703.875854,57.328766 705.759155,61.738823 
	C706.018127,64.789619 705.681641,67.505592 708.338196,69.718292 
	C709.748657,70.893105 709.082520,73.163742 708.513184,74.931816 
	C707.404297,78.376022 708.668396,80.375374 712.232178,79.912926 
	C716.773621,79.323578 717.786987,82.485916 719.251343,85.483063 
	C720.106262,87.232849 720.111572,88.922127 718.456970,89.886650 
	C715.659668,91.517326 713.739502,95.313408 709.350708,93.337181 
	C706.772949,92.176422 704.293762,90.866028 701.759338,89.657997 
	C699.573547,88.616180 696.746094,87.945297 694.972290,90.433067 
	C693.309753,92.764679 693.709839,95.150253 696.647034,96.924622 
	C696.732544,100.282455 697.783264,103.724060 696.032532,106.820076 
	C694.722351,109.137016 693.275024,111.099693 690.194153,108.718971 
	C685.854309,105.365387 680.891602,106.105431 677.785583,111.010910 
	C675.724304,114.266396 673.506226,117.216789 670.812317,119.959297 
	C669.321228,121.477348 667.691162,123.332275 668.755005,126.157204 
	C669.409851,127.896156 668.805847,130.095032 666.721191,130.647675 
	C664.601318,131.209656 664.080383,129.086029 663.386169,127.629814 
	C660.714233,122.025276 656.763550,120.984871 652.178528,125.416595 
	C649.500916,128.004639 644.776794,129.210739 645.001221,134.617035 
	C644.245911,139.566071 643.496216,140.062225 639.168274,138.373856 
	C639.000000,137.555405 639.000000,137.111038 639.000000,136.333374 
	C638.976074,134.815536 639.599365,133.764999 639.744324,132.637222 
	C640.104065,129.838593 638.742371,129.454926 636.251892,130.763153 
	C636.000000,130.999390 636.000000,131.000000 636.000000,131.000565 
	C635.067078,132.570374 634.113953,134.024673 632.207520,134.704376 
	C630.933472,135.158630 630.603149,136.291153 631.761108,137.755341 
	C632.532898,138.534042 633.066284,139.067017 633.802368,139.797134 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M705.345947,28.090931 
	C703.079712,27.938110 701.167603,28.178871 699.385376,26.968180 
	C695.984924,24.658222 692.886902,26.729650 689.376831,27.947815 
	C686.658203,25.964565 684.447632,23.932177 680.864075,25.690300 
	C679.533752,26.342930 678.298096,25.060379 678.160583,23.544748 
	C677.930542,21.007149 679.164001,19.022572 681.153503,17.605179 
	C685.150757,14.757417 687.513794,9.706035 693.132263,8.951041 
	C694.145569,8.814870 693.955627,6.941224 693.998474,5.392940 
	C699.723389,5.243092 705.513977,4.298714 711.578308,5.985230 
	C712.799988,6.000908 713.600037,6.000625 714.700012,6.000417 
	C717.134094,6.115484 719.226868,5.940964 721.613037,5.055720 
	C732.040833,5.988582 742.077820,7.019896 752.125000,7.938784 
	C755.054565,8.206708 756.519409,9.387132 755.662048,12.437928 
	C754.901123,15.145433 753.316101,16.427673 750.490906,14.988962 
	C742.382507,10.859681 734.049927,12.740823 725.712097,13.945355 
	C723.082520,14.325229 721.010864,15.297584 721.170166,18.157354 
	C721.346191,21.318089 723.758606,22.098387 726.545105,21.994558 
	C727.209778,21.969791 727.887024,21.999706 728.536133,21.881763 
	C734.852905,20.733910 734.839722,20.726755 735.007812,27.685604 
	C735.058716,29.794239 734.541748,32.405167 737.570679,32.830002 
	C740.064880,33.179844 742.625244,32.821640 744.016907,30.245451 
	C745.312622,27.847078 748.451904,25.846214 745.472534,22.441927 
	C744.409668,21.227512 744.981689,19.546263 746.682678,18.746611 
	C748.857666,17.724182 749.453125,19.487289 749.733704,20.851992 
	C750.657898,25.346197 752.564270,27.320417 757.709167,26.300146 
	C760.711548,25.704762 763.888062,28.286840 767.264832,28.396097 
	C768.075256,28.422318 768.664246,29.284409 768.705750,30.206770 
	C768.765320,31.527559 767.813721,32.027973 766.774414,31.916018 
	C763.541443,31.567781 762.617798,33.866161 761.395508,36.117859 
	C757.900513,42.556644 753.050110,43.910130 746.780212,40.391685 
	C745.342102,39.584690 743.926819,38.644447 742.377808,38.157341 
	C741.172791,37.778397 739.594971,37.598587 738.615295,38.719109 
	C737.647156,39.826477 738.716675,40.821125 739.204468,41.856895 
	C740.467712,44.539341 739.697754,47.906208 738.061401,49.164532 
	C735.475586,51.153015 731.631714,50.242306 728.415466,49.696186 
	C726.397278,49.353504 725.885559,47.294666 726.374634,45.256371 
	C726.450989,44.938164 726.585876,44.355877 726.501404,44.317371 
	C723.399597,42.903118 720.366760,40.783779 717.126587,40.390057 
	C714.169067,40.030682 712.681030,42.722454 712.969849,45.924026 
	C713.126343,47.659046 712.802307,49.294678 710.376831,49.936737 
	C709.519836,45.203991 707.567383,41.224007 703.425781,38.048592 
	C703.064575,33.421200 705.813843,31.073137 709.369995,31.668848 
	C716.212830,32.815125 721.824402,29.757151 728.957520,27.409279 
	C723.553345,26.402237 719.344788,24.983618 714.858826,27.194899 
	C711.983093,28.612404 708.883484,29.120403 705.345947,28.090931 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M625.589233,27.739649 
	C622.949585,32.875755 623.185669,38.805378 618.846130,42.904984 
	C617.006287,44.643135 616.485962,44.680775 614.730713,42.817936 
	C612.317749,40.257088 610.944458,37.758144 610.797913,34.112499 
	C610.690308,31.436558 610.093567,28.629301 608.787476,26.000568 
	C606.626709,21.651638 608.713135,12.444137 612.032837,9.754911 
	C613.506470,8.561121 614.886414,9.567143 616.281494,9.891785 
	C621.661499,11.143755 624.775269,15.847010 623.517639,21.198503 
	C622.883911,23.894995 626.771667,24.711124 625.589233,27.739649 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M544.041199,97.343430 
	C547.789734,97.148880 551.484863,100.496193 555.686218,97.167618 
	C559.534668,95.265961 563.523743,96.306480 567.192078,95.165634 
	C568.482300,94.764397 569.807373,95.882889 570.299805,97.194191 
	C570.993896,99.042603 569.216858,98.789871 568.283325,99.192459 
	C566.695618,99.877136 566.425232,101.389221 565.909973,102.782623 
	C565.283020,104.478317 564.815186,106.336548 562.354248,106.948997 
	C558.365906,103.555252 554.487305,100.838356 549.025879,101.895172 
	C547.990601,102.095512 546.805664,101.522186 545.344849,101.152618 
	C544.694153,99.895103 544.388245,98.790825 544.041199,97.343430 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M625.001221,76.592041 
	C625.001587,77.445389 625.000793,77.889557 625.000000,78.666862 
	C619.547363,80.075623 618.153076,79.163162 616.928711,73.768402 
	C616.785095,73.135345 616.614929,72.449333 616.249268,71.935783 
	C614.566711,69.572464 614.729065,67.293770 617.451782,66.305687 
	C619.826355,65.443939 622.416687,65.613487 623.066833,69.180489 
	C623.499268,71.553055 625.607910,73.493423 625.001221,76.592041 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M567.766296,153.748077 
	C568.000000,155.066803 568.000000,156.133011 568.000000,157.598999 
	C566.819824,158.014343 565.749146,158.149445 564.910767,159.208664 
	C562.989441,161.636246 560.541138,161.837555 558.569763,159.636627 
	C556.948181,157.826202 554.886108,155.875473 557.507385,152.789337 
	C559.676758,150.235275 561.374695,149.571259 564.067139,151.629868 
	C565.096191,152.416687 566.370178,152.883118 567.766296,153.748077 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M564.432495,125.470192 
	C566.441162,127.784241 565.419312,129.823059 564.425293,131.674530 
	C563.713196,133.000732 562.319580,133.412811 561.190674,132.038010 
	C559.629272,130.136475 558.015930,128.218658 556.832031,126.082130 
	C555.569641,123.804077 557.196350,122.965530 559.125061,123.311363 
	C560.863770,123.623108 562.491089,124.556122 564.432495,125.470192 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M608.152588,128.500076 
	C607.518433,128.802124 607.199402,128.931290 606.881409,128.928619 
	C604.350586,128.907333 601.301208,129.726044 601.058472,125.679123 
	C600.934448,123.611511 601.810364,121.765396 604.043640,122.193108 
	C607.093933,122.777283 609.546509,124.390862 608.152588,128.500076 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M540.943970,158.821838 
	C540.297791,161.388962 538.994202,161.055298 537.630432,160.002579 
	C536.717163,159.297607 535.973022,158.390228 536.241882,157.091797 
	C536.460693,156.035400 537.141357,155.294510 538.214539,155.248291 
	C540.399109,155.154221 540.888794,156.655075 540.943970,158.821838 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M609.096008,56.949402 
	C607.027283,56.637867 605.918213,55.768063 606.281189,53.919968 
	C606.485168,52.881573 607.326294,52.113354 608.464600,52.429203 
	C609.884399,52.823135 611.439148,53.341179 611.707214,55.079159 
	C611.958984,56.711254 610.698425,56.899994 609.096008,56.949402 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M530.750000,168.999893 
	C530.745117,170.550110 530.055847,171.337372 528.211487,170.233643 
	C528.471619,169.686630 528.943237,169.373260 529.707397,169.029648 
	C530.166687,168.999527 530.333313,168.999649 530.750000,168.999893 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M821.243835,1129.719727 
	C821.257690,1130.496216 821.515381,1130.992310 821.886536,1131.744263 
	C819.313599,1131.679565 816.366516,1131.772583 817.622559,1127.455688 
	C818.263672,1125.251953 816.943909,1124.807129 815.071289,1125.611084 
	C812.035828,1126.914429 808.987488,1128.190063 805.980408,1129.556396 
	C802.545044,1131.117676 801.999023,1133.319824 804.707031,1136.108887 
	C805.742859,1137.175781 808.144226,1137.955811 805.899170,1139.856445 
	C804.472351,1141.064453 802.701538,1141.642090 801.020020,1139.882690 
	C799.891174,1138.701416 798.911316,1136.058105 796.855469,1138.132568 
	C795.251160,1139.751343 795.642273,1142.172241 796.843994,1144.153564 
	C797.350769,1144.989136 798.169922,1145.631592 798.822937,1146.382812 
	C799.886780,1147.606445 800.462708,1148.992065 799.374451,1150.424805 
	C798.658203,1151.367920 797.576233,1151.918091 796.294739,1151.507446 
	C794.897461,1151.059692 794.023438,1150.166382 794.032898,1148.609375 
	C794.064270,1143.441895 791.192383,1143.335083 787.415955,1144.813232 
	C786.952637,1144.994507 786.411926,1145.493652 786.064148,1145.376953 
	C781.153015,1143.729980 778.051453,1144.769531 776.199646,1150.203613 
	C775.621094,1151.901489 771.791565,1151.669556 770.306641,1149.296509 
	C768.678406,1146.694458 767.525818,1143.265747 770.835693,1141.454102 
	C773.364624,1140.069946 774.397339,1137.729004 776.693909,1136.088013 
	C776.078735,1135.655518 775.579346,1135.102539 775.381042,1135.194946 
	C771.936279,1136.802124 768.616211,1136.673950 765.120361,1135.146729 
	C763.950195,1134.635376 762.098572,1134.519043 761.093201,1136.637207 
	C760.837036,1137.197876 760.676514,1137.395874 760.191895,1137.791992 
	C759.555542,1138.108887 759.168274,1138.348022 758.921021,1138.235352 
	C753.060425,1135.567139 747.023193,1137.990967 741.091675,1137.581543 
	C735.704102,1137.209473 733.771484,1134.538208 734.991272,1129.224609 
	C735.499084,1127.012451 736.914062,1124.876221 734.828857,1121.598145 
	C734.542908,1125.871216 731.972961,1128.191650 730.317810,1131.060425 
	C729.639099,1132.236572 728.771179,1133.533691 727.378967,1133.518555 
	C724.649658,1133.489258 722.618958,1134.269775 721.357239,1136.834961 
	C720.992859,1137.575562 720.010803,1137.693726 719.159302,1137.796509 
	C711.891602,1138.673950 702.870483,1129.897949 703.584595,1122.613525 
	C703.746582,1120.960938 704.118042,1119.334961 704.244385,1117.675293 
	C704.824890,1110.050049 704.673828,1109.824585 697.202576,1107.593262 
	C695.670288,1105.191772 696.902832,1103.570557 698.647766,1102.998535 
	C703.083557,1101.544312 704.373047,1098.540405 703.985046,1094.257446 
	C703.882568,1093.126221 704.375488,1091.987549 705.002136,1090.594971 
	C705.355042,1090.013916 705.582458,1089.794312 705.792480,1089.561279 
	C706.595703,1088.669312 708.551392,1088.569824 708.261230,1087.054932 
	C707.982483,1085.599731 706.069885,1086.153442 704.781616,1085.221436 
	C703.934021,1084.173096 703.798950,1083.239502 703.789185,1081.927124 
	C704.510315,1078.622070 702.979248,1077.192993 700.466064,1076.277100 
	C699.630859,1077.614502 700.632141,1078.662598 700.355042,1080.084717 
	C699.870667,1081.161499 699.353333,1081.857666 698.616577,1082.206055 
	C694.408142,1084.196167 692.152405,1087.556030 691.419739,1092.078247 
	C691.289368,1092.882812 690.767090,1093.574341 689.882996,1094.363281 
	C684.113220,1096.328491 683.887756,1098.496094 688.678833,1105.229858 
	C689.428345,1107.982666 688.851990,1110.252075 687.753540,1112.815918 
	C682.666870,1115.732910 681.059570,1115.181519 680.479736,1110.140625 
	C680.067993,1106.561523 678.806580,1103.783813 675.815125,1101.770630 
	C674.849365,1101.120483 673.685242,1100.288696 673.534302,1099.197388 
	C672.687805,1093.076782 668.986267,1093.462769 664.322876,1094.497192 
	C659.358032,1095.598145 654.849304,1093.338013 650.473938,1091.236328 
	C647.957336,1090.027588 648.340027,1088.368286 649.718445,1086.371338 
	C653.712158,1080.585205 653.283691,1079.533691 646.228516,1077.644531 
	C643.574768,1076.933960 641.208069,1076.045044 642.422241,1072.641357 
	C643.258789,1070.296265 646.078552,1070.594238 648.004700,1069.017212 
	C651.499023,1067.339111 654.515625,1068.563354 657.454773,1069.636719 
	C659.717285,1070.463013 661.648010,1070.533569 663.667725,1068.477295 
	C664.709290,1067.895752 665.521362,1067.537720 666.337341,1067.600708 
	C670.549133,1067.926270 672.424988,1066.000977 673.406067,1061.869507 
	C674.583191,1056.912109 676.699829,1056.395386 679.987732,1059.824097 
	C682.099426,1062.026245 683.382690,1061.417725 684.527039,1059.203979 
	C685.982971,1056.387573 686.865723,1053.344604 687.687012,1050.286865 
	C688.645447,1046.718384 690.669800,1045.483154 694.401123,1046.764160 
	C701.409729,1049.170410 705.488770,1054.277466 706.001038,1062.125122 
	C706.117065,1063.902832 706.872314,1064.688110 708.413330,1065.412720 
	C714.499756,1068.274780 715.436401,1070.889404 712.499146,1076.629517 
	C710.290649,1080.945435 714.505127,1079.948853 716.352722,1081.358887 
	C715.220581,1076.073975 714.165894,1070.955688 720.736694,1068.187988 
	C721.870605,1068.126587 722.563660,1068.353516 723.417725,1069.103027 
	C725.268921,1074.293335 724.493347,1079.574951 726.198730,1084.548462 
	C727.112671,1087.213989 728.221558,1088.697632 730.911560,1089.604492 
	C736.303650,1091.422241 737.930786,1094.381104 737.085144,1100.263184 
	C736.711975,1102.858398 737.470459,1104.557251 740.016479,1105.170654 
	C742.792297,1105.839233 745.284912,1107.224121 747.971619,1108.090332 
	C755.103638,1110.389648 755.844666,1111.461670 755.030334,1119.338867 
	C757.636353,1116.293091 761.013550,1115.333618 764.049316,1113.760986 
	C766.998657,1112.233154 768.691345,1110.509277 767.148682,1106.658203 
	C765.322998,1102.100342 766.519409,1100.761108 771.529724,1100.467407 
	C773.025146,1100.379761 774.523438,1100.422119 776.018616,1100.569336 
	C778.427063,1100.806641 780.041748,1101.989136 780.708069,1104.373291 
	C781.425842,1106.941650 780.780212,1109.318237 778.457642,1110.392090 
	C774.952698,1112.012329 774.301941,1114.528809 774.447021,1118.024170 
	C774.621643,1122.233032 773.416016,1125.907104 768.887085,1127.645020 
	C767.557007,1128.155518 765.725037,1129.557373 766.723877,1130.958740 
	C768.150208,1132.959839 769.611816,1130.944946 770.793945,1129.938843 
	C774.688232,1126.625000 777.516968,1122.084961 782.560913,1120.075439 
	C784.538025,1119.287842 783.713745,1117.758057 783.032043,1116.311646 
	C779.978577,1109.833130 780.542053,1108.941406 787.561401,1108.854370 
	C780.604858,1102.860718 779.969299,1101.059814 783.544067,1096.392090 
	C784.307495,1095.983765 784.777710,1095.826904 785.638184,1095.679932 
	C788.034607,1095.308960 789.864014,1096.368652 791.872314,1096.416382 
	C794.341797,1096.474976 796.010986,1098.237061 796.156250,1100.517822 
	C796.532166,1106.421021 801.151123,1110.548584 802.293579,1116.082886 
	C802.424866,1116.719238 802.877197,1117.767212 803.253174,1117.715088 
	C806.836243,1117.218750 809.923218,1120.395142 813.536499,1119.119629 
	C819.393921,1117.052124 822.724487,1119.450073 822.540833,1125.698364 
	C822.501587,1127.031006 822.397827,1128.349976 821.243835,1129.719727 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M759.757507,1137.251465 
	C760.000000,1137.000000 760.498779,1137.000000 760.748169,1137.000000 
	C765.411011,1136.657593 766.806335,1138.745605 765.547974,1142.795898 
	C764.860168,1145.009766 764.930969,1147.366333 763.831299,1149.531738 
	C762.636169,1151.885010 762.208374,1153.925415 765.728699,1155.427490 
	C767.859680,1156.336792 768.473755,1160.653809 771.339478,1160.461914 
	C779.621460,1159.906982 788.319397,1162.662109 796.113892,1157.693604 
	C797.636353,1156.723145 799.457336,1156.537476 800.801208,1157.696533 
	C803.264404,1159.821045 804.041199,1163.143311 803.246338,1165.850098 
	C802.568481,1168.158936 802.519165,1172.145386 799.088745,1172.321167 
	C795.819275,1172.488892 792.133240,1172.259521 791.114807,1167.518066 
	C790.463013,1164.483887 787.711182,1163.847900 784.750183,1163.966064 
	C781.619019,1164.091064 779.998596,1166.210327 778.523132,1168.386353 
	C777.438293,1169.986328 778.585510,1171.219238 780.033875,1172.000244 
	C783.435791,1173.834595 784.169495,1176.757935 784.084412,1180.400635 
	C784.011597,1183.518433 784.773621,1186.646729 784.969971,1189.782715 
	C785.108582,1191.995361 785.544678,1194.135498 788.159668,1193.853882 
	C790.807739,1193.568481 794.447876,1195.924561 795.943359,1191.817993 
	C797.301453,1188.088623 798.870728,1187.474365 801.989563,1190.241089 
	C804.412109,1192.389893 807.187439,1192.608887 810.222168,1190.597412 
	C812.518066,1189.075806 815.270447,1188.151855 817.927307,1187.296387 
	C819.819580,1186.687134 821.853210,1186.659790 823.587341,1188.164062 
	C825.666077,1189.966919 824.115662,1191.930786 823.786682,1193.723633 
	C823.378052,1195.950684 821.628418,1197.119385 819.377380,1196.946655 
	C816.244202,1196.706055 813.059021,1196.500732 810.025024,1195.755493 
	C804.924622,1194.502930 802.827515,1198.516357 800.397827,1201.293457 
	C799.171753,1202.694946 800.425049,1205.189209 802.757202,1205.914917 
	C804.974915,1206.604858 807.356262,1206.857056 809.478821,1207.747192 
	C814.337524,1209.784546 817.972839,1209.264526 819.467468,1203.606567 
	C821.644592,1203.911499 820.639343,1206.972290 822.855835,1206.925537 
	C826.586243,1206.846924 827.452881,1209.156250 826.899170,1212.198975 
	C826.301453,1215.483521 824.653564,1218.204834 821.082703,1218.889526 
	C819.486572,1219.195435 817.384521,1219.481079 816.175964,1218.720825 
	C811.816040,1215.977905 807.396790,1218.071777 803.056458,1218.137085 
	C799.370239,1218.192383 798.161743,1220.440918 799.637024,1223.967163 
	C800.783386,1226.707153 801.816345,1229.494629 802.949829,1232.630493 
	C801.880798,1235.675903 801.657898,1238.513916 801.498901,1241.713745 
	C801.545715,1245.985352 803.708008,1247.505249 807.414734,1247.383179 
	C808.703918,1247.340698 809.999756,1247.477539 811.649658,1247.647705 
	C815.770142,1248.264526 819.331177,1245.525024 823.464844,1247.198975 
	C825.213135,1248.731812 824.392456,1250.079468 823.753357,1251.406494 
	C822.272339,1254.481567 823.800903,1255.206909 826.556885,1255.123291 
	C831.860352,1254.962158 835.798645,1252.239990 839.457092,1248.318115 
	C837.975586,1247.039185 835.919434,1247.744019 834.216064,1246.519409 
	C833.835632,1246.072266 833.737549,1245.851074 833.819763,1245.314941 
	C833.761597,1243.878906 833.523254,1242.757690 833.142456,1241.318359 
	C831.676025,1236.935059 831.976196,1232.802368 832.742432,1228.330566 
	C841.412842,1225.246582 843.765564,1226.932007 843.964783,1236.183105 
	C844.061279,1240.663330 848.280823,1243.858398 847.185608,1248.886353 
	C846.523804,1251.924072 846.773926,1254.530151 842.612488,1254.056641 
	C841.369934,1253.915161 840.182983,1254.394531 840.174805,1255.904663 
	C840.164734,1257.759399 840.709229,1259.559204 841.844910,1261.012939 
	C842.828613,1262.272339 843.980408,1261.491821 844.860901,1260.695679 
	C847.298340,1258.491577 850.039856,1258.432861 853.133057,1258.924561 
	C856.443787,1259.450928 859.657288,1260.136597 863.041809,1257.766357 
	C865.205750,1256.250977 866.967834,1255.286377 867.434265,1252.463867 
	C867.733337,1250.653809 868.062744,1248.050049 870.748535,1248.168945 
	C873.570557,1248.294067 876.473206,1248.908325 877.800598,1251.956299 
	C878.736694,1254.105713 879.120544,1256.385010 879.032288,1258.770996 
	C878.959717,1260.732910 878.776978,1262.708130 880.001038,1264.555786 
	C881.807190,1267.282104 880.441040,1269.417847 878.054749,1270.741211 
	C875.598389,1272.103516 872.986328,1272.598145 871.218689,1269.407837 
	C870.334045,1267.811279 869.191345,1266.349976 868.386292,1264.718628 
	C867.245972,1262.407715 865.356079,1261.104858 863.196899,1262.444580 
	C860.998352,1263.808960 861.816895,1266.218384 862.861877,1268.220703 
	C864.983643,1272.286621 866.907715,1276.554810 872.917664,1275.095825 
	C874.275818,1274.766235 875.447998,1275.736084 875.934448,1277.617554 
	C871.617615,1276.937500 867.159241,1276.889038 862.688477,1276.998047 
	C862.191345,1277.010132 861.593445,1277.178467 861.210876,1276.967896 
	C855.025513,1273.565674 849.339050,1272.210815 843.495972,1278.286133 
	C842.162537,1279.672607 837.827209,1278.171875 834.436768,1278.000000 
	C832.097229,1276.465942 830.192932,1278.087891 828.290833,1278.090332 
	C818.835571,1278.102905 809.665039,1275.419556 800.193115,1275.402222 
	C797.096985,1275.396484 793.893433,1274.909424 790.766968,1275.069824 
	C787.134583,1275.256348 784.611938,1274.115356 783.149780,1270.324219 
	C782.821167,1268.941772 783.454895,1268.643555 784.666748,1268.787109 
	C788.808350,1268.993530 792.616699,1268.996704 796.424927,1268.974731 
	C797.611877,1268.967896 799.037659,1268.862549 799.325684,1267.573242 
	C799.726990,1265.776489 797.967285,1266.069092 796.982361,1265.844727 
	C792.175110,1264.749146 790.507507,1261.946777 791.738647,1257.132446 
	C792.727478,1253.265747 791.334412,1250.089722 788.077881,1247.348633 
	C788.734192,1245.673218 789.063721,1244.409058 787.225464,1243.222412 
	C785.488281,1241.502930 785.945618,1239.571655 786.039978,1237.827881 
	C786.344604,1232.195923 785.541565,1226.653320 784.969238,1221.087524 
	C784.829285,1219.726562 784.560547,1218.111694 783.413696,1217.589355 
	C779.748962,1215.919800 779.598816,1212.845947 780.042419,1209.684692 
	C781.199646,1201.438232 777.084900,1198.177979 767.390137,1199.989014 
	C765.630249,1199.301636 764.800842,1197.885864 765.394653,1196.716797 
	C767.315369,1192.935791 765.463623,1190.166748 763.613098,1187.136108 
	C762.222046,1184.858154 761.716187,1182.160645 761.653992,1179.469849 
	C761.555542,1175.209351 763.039246,1173.416748 767.255432,1172.877197 
	C769.303894,1172.614990 771.237854,1172.403076 772.657288,1169.462036 
	C770.254272,1172.412354 767.796692,1173.593750 765.035950,1172.295654 
	C762.348022,1171.031616 763.181091,1168.315186 763.331909,1166.055298 
	C763.588867,1162.203979 763.645142,1158.586792 758.581421,1157.749023 
	C757.618347,1157.589600 757.001648,1156.593018 756.309387,1155.868774 
	C752.552490,1151.938477 752.523865,1151.923950 748.651794,1155.618164 
	C747.708313,1156.518311 746.723022,1156.979980 745.525574,1156.437012 
	C744.116821,1155.797974 743.861755,1154.463745 743.849792,1153.080200 
	C743.819946,1149.632568 748.096619,1143.424805 751.531738,1143.669800 
	C756.643677,1144.034668 757.793762,1140.736694 759.757507,1137.251465 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M911.614746,1243.001343 
	C914.143494,1243.000854 916.288879,1242.942383 918.430054,1243.012939 
	C926.915161,1243.292725 931.191467,1248.864502 929.437195,1257.237427 
	C929.169678,1258.514160 929.140198,1259.840698 929.000244,1261.572144 
	C924.361084,1260.562134 919.961670,1258.323486 914.832886,1258.651978 
	C912.911438,1258.774902 911.867737,1256.818115 911.024475,1255.133057 
	C910.504944,1254.094727 909.824097,1252.901367 908.398376,1253.582153 
	C906.908813,1254.293457 906.831360,1255.617432 907.630005,1256.934570 
	C908.231812,1257.927124 909.036560,1258.801392 908.414246,1260.094482 
	C907.762939,1261.447510 907.163757,1263.063354 905.613831,1263.366821 
	C903.423218,1263.795288 904.228027,1261.631470 903.748840,1260.620972 
	C903.166199,1259.391968 902.310974,1258.475098 901.363098,1257.523926 
	C899.174500,1255.327637 899.154541,1252.117310 900.768982,1250.261475 
	C903.537842,1247.078613 906.656189,1243.836060 911.614746,1243.001343 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M824.365112,1248.299072 
	C819.976929,1247.050415 816.455872,1249.944946 812.249512,1249.250977 
	C810.804260,1242.207642 805.129517,1243.271240 800.262573,1242.228760 
	C800.378967,1238.956909 798.818665,1235.414795 802.636902,1233.081543 
	C804.285095,1235.246338 806.201477,1236.530273 808.649414,1237.451538 
	C812.548889,1238.918945 816.447571,1240.166138 820.107666,1242.587158 
	C823.705200,1244.967041 828.946655,1243.292969 833.602539,1244.998535 
	C834.000000,1245.000000 834.000000,1245.500000 834.000000,1245.750000 
	C831.866882,1249.935425 830.105530,1250.429077 824.365112,1248.299072 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M915.237915,1271.670410 
	C913.241211,1269.001343 912.031616,1266.512939 913.957764,1263.649414 
	C915.325134,1261.616699 917.049194,1261.193359 919.527344,1261.805664 
	C921.689453,1262.339966 924.205383,1262.392334 924.631714,1264.888916 
	C925.212524,1268.290527 922.969055,1270.893555 920.719482,1273.073242 
	C918.756775,1274.975098 917.132690,1272.886719 915.237915,1271.670410 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M832.701660,1227.841797 
	C834.121216,1232.056763 834.631653,1236.130005 833.164856,1240.625977 
	C829.958252,1240.519897 828.963379,1238.532959 828.987976,1235.695435 
	C829.005005,1233.722534 829.127441,1231.747192 828.460999,1229.800293 
	C827.995422,1228.440552 827.266846,1226.591064 828.789368,1225.738159 
	C830.424622,1224.822021 831.114441,1227.121948 832.701660,1227.841797 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M833.333313,1133.000000 
	C832.692078,1131.741699 832.237854,1130.497681 832.113892,1129.221558 
	C831.965942,1127.698242 832.514893,1126.274536 833.850342,1125.427368 
	C834.611206,1124.944458 835.409851,1125.338379 835.926819,1126.106079 
	C836.795227,1127.395142 837.263062,1128.695068 836.248779,1130.161011 
	C835.793396,1130.819092 835.479248,1131.575073 835.050537,1132.643311 
	C834.555542,1133.000000 834.111084,1133.000000 833.333313,1133.000000 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M889.937683,112.590691 
	C882.905640,112.822906 880.763977,110.000587 880.000244,99.411316 
	C886.857117,96.744484 888.977539,98.245384 888.987793,105.434235 
	C888.991089,107.730705 888.956055,110.013741 889.937683,112.590691 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M877.998840,98.615265 
	C876.684631,100.836143 877.612488,104.978798 873.387817,103.829041 
	C868.623352,102.532387 869.869080,97.954010 869.216614,94.765503 
	C868.527771,91.399025 871.660522,91.159134 874.596924,91.001160 
	C876.877075,93.045677 878.031250,95.391907 877.998840,98.615265 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M908.084595,44.992077 
	C905.304565,44.688419 902.470459,44.405354 903.326050,41.107639 
	C903.851746,39.081390 904.892822,36.044315 907.612122,36.337048 
	C910.282959,36.624557 912.418640,38.882278 912.811768,41.675510 
	C913.240173,44.719498 910.825073,45.026928 908.084595,44.992077 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M983.070679,7.331303 
	C984.167297,7.164681 985.334534,7.329237 986.750916,7.746395 
	C985.595886,9.238234 984.271301,9.566173 983.070679,7.331303 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M706.000000,61.545609 
	C701.794556,58.750893 696.232727,58.772953 691.741821,56.066349 
	C690.136658,55.098969 689.098877,54.361973 689.012207,52.476681 
	C688.811279,48.104843 690.050598,43.968353 691.115784,39.823971 
	C691.834656,37.027069 694.360901,38.326443 696.576294,38.000229 
	C698.925415,38.323677 700.637817,36.629452 702.907776,37.602455 
	C704.355225,41.118614 705.264038,44.793545 703.397278,47.777737 
	C701.263367,51.189056 703.558777,50.992191 705.749756,51.759991 
	C706.000000,55.030785 706.000000,58.061165 706.000000,61.545609 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M749.627441,91.950165 
	C750.241028,98.110130 746.357056,96.799026 742.384705,96.010590 
	C741.533630,91.519615 737.835938,88.561836 736.538757,84.447029 
	C736.168274,83.271881 734.779663,83.040985 733.556885,83.023575 
	C732.391174,83.006973 731.195496,83.143997 730.066162,82.931175 
	C727.591797,82.464874 724.554504,81.812569 724.583191,78.883972 
	C724.608459,76.312492 727.466309,75.373215 729.805420,75.123848 
	C733.092651,74.773407 736.386475,74.765846 739.696960,74.232750 
	C743.365356,73.642021 746.819275,78.812675 745.517090,82.281509 
	C743.816406,86.812126 745.004333,89.868851 749.627441,91.950165 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M706.168213,51.702477 
	C704.551086,52.304386 703.078003,52.936073 701.659119,52.831738 
	C699.765076,52.692471 697.982178,50.712151 699.793396,49.635052 
	C703.724670,47.297279 702.942627,44.418289 702.253662,41.119572 
	C702.104614,40.406067 702.663208,39.544792 702.947937,38.375870 
	C703.166626,37.872059 703.333191,37.743961 703.749878,37.807629 
	C711.170166,41.850460 711.812744,43.031677 710.055054,49.615635 
	C708.589600,49.974586 707.224731,50.068253 706.168213,51.702477 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M732.697144,97.730469 
	C732.813904,99.384933 731.888489,100.280312 730.647339,99.903427 
	C728.143982,99.143242 725.371521,99.249245 723.063171,97.324875 
	C726.127808,97.227707 729.302429,96.499741 732.697144,97.730469 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1119.046753,1024.344482 
	C1122.048462,1019.826172 1116.546997,1018.645447 1116.046753,1015.348755 
	C1117.524658,1011.553406 1121.173218,1010.147461 1123.501831,1007.385498 
	C1124.838623,1005.799927 1127.502197,1007.573120 1128.781250,1009.620728 
	C1129.394775,1010.602966 1129.935059,1011.896973 1131.356323,1011.514282 
	C1132.802124,1011.125000 1132.816895,1009.920593 1132.380981,1008.669800 
	C1132.098999,1007.860901 1131.947998,1006.940369 1132.937622,1006.565918 
	C1133.866821,1006.214417 1134.592041,1006.963623 1135.002319,1007.626648 
	C1136.536011,1010.105591 1138.456177,1010.453735 1140.922363,1009.069336 
	C1141.728760,1008.616638 1142.783447,1007.844421 1143.570435,1008.966248 
	C1144.224121,1009.898132 1143.455566,1010.847595 1143.117310,1011.770813 
	C1142.535889,1013.357849 1140.857788,1015.323181 1144.101807,1015.923157 
	C1145.948486,1016.264709 1147.918213,1016.745789 1146.221558,1018.890808 
	C1144.928101,1020.526184 1143.972778,1023.823853 1140.489990,1021.956970 
	C1134.181763,1018.575562 1131.640625,1019.660522 1129.947632,1026.228394 
	C1129.493286,1027.991089 1128.916382,1029.745728 1128.173584,1031.404541 
	C1127.311157,1033.330811 1125.470215,1033.077881 1123.817139,1032.881226 
	C1122.278198,1032.697876 1122.014282,1031.630615 1122.386353,1030.309082 
	C1123.236572,1027.289429 1119.110107,1027.194824 1119.046753,1024.344482 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1227.668457,1042.765625 
	C1230.357422,1044.775391 1232.112427,1044.176392 1232.874268,1041.445801 
	C1233.369873,1039.669434 1234.192871,1038.643433 1235.978516,1039.239258 
	C1237.990967,1039.910767 1240.372559,1040.400024 1241.374512,1042.478149 
	C1242.060181,1043.900269 1240.278320,1044.231812 1239.366943,1044.808838 
	C1236.218628,1046.802124 1236.209473,1046.787720 1234.479980,1050.054565 
	C1235.768555,1051.833740 1237.180176,1049.968140 1238.510010,1050.166260 
	C1240.369751,1050.443604 1241.035522,1051.671875 1240.880737,1053.363770 
	C1240.744263,1054.856812 1239.374390,1056.492065 1238.375977,1055.678711 
	C1234.055542,1052.159058 1228.028809,1053.395386 1223.775757,1050.028931 
	C1223.426392,1049.752319 1223.324707,1049.162842 1223.053955,1048.359375 
	C1222.528198,1044.656494 1224.063232,1042.904419 1227.668457,1042.765625 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1257.231689,1080.150146 
	C1255.844971,1081.651978 1254.687378,1083.286133 1252.913940,1082.894531 
	C1248.786865,1081.983154 1244.662476,1081.709229 1240.459229,1081.835815 
	C1239.244873,1081.872437 1238.344116,1081.052734 1238.397339,1079.576660 
	C1238.530762,1075.866333 1244.231201,1072.368042 1247.383789,1074.328003 
	C1249.309570,1075.525269 1250.805176,1075.937378 1252.743164,1074.583740 
	C1254.327881,1073.477051 1255.806641,1070.601685 1258.079590,1073.007935 
	C1260.117188,1075.164917 1258.751587,1077.645020 1257.231689,1080.150146 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1217.066528,1034.344971 
	C1217.459229,1031.422729 1219.381348,1029.685791 1220.843872,1027.297119 
	C1222.971436,1025.898560 1225.076050,1025.715576 1227.217041,1026.269897 
	C1229.247681,1026.795532 1230.549805,1028.226074 1229.861572,1030.272949 
	C1228.806030,1033.411621 1226.727539,1036.321289 1223.841553,1037.622192 
	C1221.400024,1038.723022 1218.853760,1036.917969 1217.066528,1034.344971 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1228.190430,1042.744995 
	C1225.160156,1043.111206 1224.858154,1045.921997 1223.155273,1047.704102 
	C1219.247803,1048.962402 1219.346802,1050.837646 1221.813110,1053.191284 
	C1222.847656,1054.178589 1224.578247,1054.811157 1223.415283,1056.730103 
	C1222.840210,1057.679077 1221.792236,1058.170410 1220.855835,1057.698853 
	C1219.326294,1056.928589 1216.941772,1057.772583 1216.238403,1055.488159 
	C1214.329346,1049.288330 1220.130981,1040.818970 1226.834106,1040.099243 
	C1228.451660,1039.925659 1230.873413,1039.759033 1228.190430,1042.744995 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1206.609741,1032.989136 
	C1207.000000,1034.066650 1207.000000,1035.133423 1207.000000,1036.600098 
	C1208.214233,1037.869873 1207.758667,1039.470093 1208.771484,1040.763916 
	C1209.000000,1041.000000 1209.000000,1041.500000 1209.000000,1041.750000 
	C1209.226196,1046.430298 1205.732666,1046.907715 1202.363403,1047.951660 
	C1200.253052,1048.038940 1200.369873,1046.872192 1200.753540,1045.346191 
	C1202.601929,1044.657959 1203.415405,1043.917114 1202.226807,1042.332275 
	C1201.653076,1041.567383 1200.569580,1040.987549 1200.379272,1040.169800 
	C1199.831543,1037.815796 1199.303101,1035.239624 1200.810059,1033.113159 
	C1202.355591,1030.932495 1204.400879,1033.143677 1206.609741,1032.989136 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1200.895142,1044.625244 
	C1201.170410,1045.852173 1201.340698,1046.704224 1201.755615,1047.778198 
	C1201.779907,1051.469482 1200.569946,1053.339722 1197.172729,1050.581543 
	C1195.625610,1049.325439 1194.470947,1048.959961 1192.971069,1050.500610 
	C1190.594116,1052.942139 1188.553955,1051.187500 1187.158691,1049.457764 
	C1184.837769,1046.580444 1188.600708,1047.061035 1189.504517,1046.023926 
	C1192.702271,1042.354614 1196.546509,1041.778809 1200.895142,1044.625244 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1210.763306,1057.753174 
	C1212.126709,1057.932129 1213.131104,1057.939331 1214.112671,1058.092529 
	C1215.889404,1058.369995 1216.698853,1059.680298 1216.680542,1061.267822 
	C1216.658081,1063.213867 1214.963379,1062.981445 1213.696533,1062.963745 
	C1212.217041,1062.943115 1210.675781,1062.918945 1209.281372,1062.497925 
	C1207.937744,1062.092407 1206.030518,1061.537476 1206.524414,1059.603882 
	C1206.971802,1057.852173 1208.378662,1056.867065 1210.763306,1057.753174 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1258.844727,1118.856934 
	C1259.820312,1118.188599 1260.507080,1117.704468 1261.263672,1117.386108 
	C1262.687134,1116.786987 1264.384155,1115.209839 1265.498657,1117.497437 
	C1266.607544,1119.773804 1264.432739,1120.179810 1262.991821,1120.852661 
	C1262.395996,1121.130737 1261.760132,1121.321411 1261.148071,1121.565918 
	C1259.908325,1122.061157 1259.075317,1124.495239 1257.573730,1123.072876 
	C1256.314087,1121.879517 1257.953125,1120.395142 1258.844727,1118.856934 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1237.608643,1072.481079 
	C1236.396973,1074.133911 1235.339478,1075.506470 1233.478882,1073.889893 
	C1231.920044,1072.535522 1231.750488,1070.787720 1232.247437,1068.951904 
	C1232.520996,1067.941162 1233.286133,1067.121826 1234.410645,1067.430298 
	C1236.745117,1068.070679 1237.932861,1069.666016 1237.608643,1072.481079 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1237.198853,1065.088745 
	C1236.043579,1064.178101 1233.662231,1063.978027 1234.998169,1062.384888 
	C1236.152588,1061.008179 1238.332031,1059.714600 1240.009277,1059.773926 
	C1241.641235,1059.831421 1240.954468,1062.229614 1240.926025,1063.601562 
	C1240.873169,1066.157715 1239.338501,1066.091187 1237.198853,1065.088745 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1275.545776,1104.395752 
	C1278.571533,1104.341431 1278.191895,1106.069458 1277.452515,1107.605469 
	C1276.801147,1108.958252 1275.363159,1109.372192 1274.204834,1108.340698 
	C1272.268311,1106.616089 1274.018677,1105.583984 1275.545776,1104.395752 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1115.232178,1003.243835 
	C1113.344604,998.995605 1115.847412,999.339233 1118.703369,999.871033 
	C1118.823730,1002.178772 1118.789551,1004.501404 1115.232178,1003.243835 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1180.201660,1035.625610 
	C1180.221069,1033.136597 1181.472412,1032.615967 1183.124268,1033.510864 
	C1184.281860,1034.138062 1184.344849,1035.736694 1183.732422,1036.643188 
	C1182.333130,1038.713867 1181.304688,1036.972290 1180.201660,1035.625610 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1265.042969,1085.651367 
	C1263.555542,1085.730713 1261.299316,1086.757935 1261.331177,1084.036255 
	C1261.346802,1082.705688 1262.547729,1082.086426 1263.821289,1082.119751 
	C1266.603882,1082.192383 1265.269165,1083.996704 1265.042969,1085.651367 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1140.104980,1006.109985 
	C1138.330322,1007.114990 1137.262573,1006.868652 1137.168823,1004.980164 
	C1137.111084,1003.819275 1137.825806,1003.108215 1138.984619,1003.169495 
	C1140.870972,1003.269348 1141.115479,1004.338440 1140.104980,1006.109985 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1120.377319,999.983765 
	C1119.579102,997.997070 1118.495605,995.617249 1122.636841,996.001343 
	C1122.874512,997.675110 1123.484009,999.766052 1120.377319,999.983765 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1181.501221,1042.771240 
	C1183.359375,1042.313599 1184.127563,1043.396851 1184.516235,1044.681519 
	C1184.733765,1045.400269 1183.983154,1045.896729 1183.289429,1045.785767 
	C1181.768433,1045.542480 1181.000977,1044.626099 1181.501221,1042.771240 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1201.874512,1057.097412 
	C1201.097168,1055.015381 1202.312988,1055.056641 1203.507690,1055.181274 
	C1204.265869,1055.260498 1204.676392,1055.817383 1204.410767,1056.526123 
	C1203.996826,1057.630737 1203.146240,1057.858643 1201.874512,1057.097412 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1230.655151,1012.049194 
	C1230.922485,1013.406372 1231.109009,1014.973083 1229.303711,1015.482178 
	C1229.042969,1015.555786 1228.370361,1015.090637 1228.306885,1014.787231 
	C1227.976440,1013.206360 1228.975342,1012.502319 1230.655151,1012.049194 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1145.822266,1003.392578 
	C1146.480713,1004.240417 1147.070801,1005.137878 1146.036133,1005.476929 
	C1144.984375,1005.821472 1144.290039,1004.841248 1144.201294,1003.848694 
	C1144.097900,1002.695923 1144.756958,1002.586487 1145.822266,1003.392578 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1227.218018,1061.743530 
	C1226.349121,1061.149170 1226.406006,1060.601562 1227.107666,1060.327759 
	C1227.426758,1060.203125 1228.136230,1060.378296 1228.277954,1060.634033 
	C1228.669434,1061.340454 1228.166748,1061.683350 1227.218018,1061.743530 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1256.217896,950.806213 
	C1254.627808,950.841187 1252.985229,951.479065 1252.082031,949.348816 
	C1252.594604,948.225586 1253.707642,947.441895 1253.694092,946.678406 
	C1253.635498,943.410645 1249.562500,942.136047 1249.343506,939.137085 
	C1249.047485,935.085815 1246.593018,934.305359 1243.400391,933.985657 
	C1242.743164,933.919739 1242.048584,933.859558 1241.455078,933.603027 
	C1240.425537,933.158081 1238.922974,932.769287 1239.373657,931.284851 
	C1239.925171,929.468262 1241.353394,930.720764 1242.300659,930.940979 
	C1244.708740,931.500854 1247.164917,932.051270 1249.416504,933.031311 
	C1251.640503,933.999329 1254.971680,934.582581 1253.172852,938.496765 
	C1253.063721,938.734009 1253.191284,939.301514 1253.390015,939.426086 
	C1257.859253,942.227661 1255.608643,946.839050 1256.217896,950.806213 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M1249.952393,975.382324 
	C1249.970215,974.171082 1250.143677,973.269592 1249.877686,972.525085 
	C1248.178467,967.769653 1248.557007,964.075745 1254.358154,962.739258 
	C1255.102539,962.567749 1255.712524,961.812561 1256.692383,961.163818 
	C1257.385620,960.619751 1257.845825,960.472534 1258.690186,960.779175 
	C1259.444458,961.000061 1259.888916,961.000122 1260.666748,961.000427 
	C1262.394287,963.024353 1262.130371,964.755432 1259.782227,966.333008 
	C1258.943604,966.852112 1258.377930,967.153809 1257.582764,967.597656 
	C1256.956299,968.127747 1256.792236,968.581665 1257.005615,969.414978 
	C1257.209961,970.118896 1257.287354,970.543823 1257.361816,971.332397 
	C1257.393799,973.302429 1256.945923,974.711609 1255.257568,975.915405 
	C1254.180054,976.382263 1253.396484,976.483276 1252.264526,976.770630 
	C1251.065674,976.748779 1250.368408,976.477173 1249.952393,975.382324 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1241.869507,863.301147 
	C1243.104492,863.305908 1244.209106,863.611816 1245.656738,863.958862 
	C1246.709717,864.642822 1247.318604,865.521912 1248.151123,865.876709 
	C1249.645996,866.513794 1250.441772,867.537292 1249.625000,868.892334 
	C1248.735962,870.367371 1246.499512,871.352600 1245.466797,870.310913 
	C1243.713745,868.542419 1240.731079,867.043091 1241.869507,863.301147 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1258.040649,966.483948 
	C1259.399658,964.863464 1261.563721,964.265137 1260.998291,961.372314 
	C1262.146851,961.904968 1263.293701,962.809204 1264.720215,963.856689 
	C1265.000000,964.750000 1265.000000,965.500000 1265.000000,966.625000 
	C1262.684082,969.084717 1260.378052,969.010620 1258.040649,966.483948 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M1263.747314,957.966309 
	C1263.067383,956.018555 1262.274170,954.381348 1262.717041,952.266479 
	C1265.006348,953.109680 1266.731079,954.334106 1266.734863,956.737671 
	C1266.737183,958.250610 1265.647461,959.103760 1263.747314,957.966309 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1239.636719,947.001343 
	C1239.874878,948.675293 1240.483887,950.766174 1237.377197,950.983826 
	C1236.579102,948.997131 1235.495239,946.617188 1239.636719,947.001343 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1258.945801,960.687500 
	C1258.555542,961.000000 1258.111084,961.000000 1257.333252,961.000000 
	C1257.000000,960.555542 1257.000000,960.111084 1257.000000,959.333313 
	C1257.885986,959.106934 1258.643677,959.390320 1258.945801,960.687500 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M1015.725830,1221.786499 
	C1020.549011,1222.937012 1024.808105,1223.957397 1027.455566,1228.654541 
	C1028.806519,1231.051270 1031.099365,1229.346924 1032.153076,1227.492065 
	C1034.051392,1224.150879 1035.606567,1225.284912 1036.339111,1227.932739 
	C1037.536377,1232.260376 1041.277222,1235.436646 1041.383789,1240.289795 
	C1041.433960,1242.581787 1043.389038,1243.688477 1045.838501,1244.242920 
	C1049.231689,1245.010620 1051.474487,1246.641602 1048.209106,1250.453735 
	C1046.176758,1252.826416 1046.282104,1255.908813 1048.016357,1258.360840 
	C1050.990601,1262.565430 1050.269897,1265.693604 1046.128784,1268.441528 
	C1045.180054,1269.071045 1044.321899,1269.983398 1043.712036,1270.948730 
	C1042.192627,1273.353638 1040.585938,1272.813232 1039.603516,1270.830811 
	C1037.734009,1267.058105 1034.891602,1267.912842 1032.553101,1269.496948 
	C1030.024414,1271.209717 1028.083862,1272.561035 1025.102417,1270.368896 
	C1023.936707,1269.511719 1023.115112,1270.936157 1023.012817,1272.637451 
	C1019.831116,1270.965454 1020.327637,1266.435059 1016.976624,1264.389038 
	C1014.737366,1263.021973 1013.042786,1260.750977 1010.785767,1259.428467 
	C1009.060425,1258.417725 1010.063232,1254.640137 1005.997559,1255.805786 
	C1000.831421,1257.287109 994.272034,1256.006226 993.993713,1264.196655 
	C993.889648,1267.260132 991.892395,1267.275635 989.812866,1266.729614 
	C985.772705,1265.668823 982.160034,1264.770630 981.699219,1270.932129 
	C981.549927,1272.927612 979.517700,1273.485840 977.851501,1272.177612 
	C975.292053,1270.167725 972.424561,1269.787231 969.365356,1270.004395 
	C967.067627,1270.167603 964.909241,1269.422363 963.318481,1268.032593 
	C960.971436,1265.982056 958.291138,1264.571289 954.578064,1262.886230 
	C958.023804,1261.848145 961.471619,1261.443237 960.818176,1257.738159 
	C960.204773,1254.260254 960.678406,1249.781494 955.084229,1249.407104 
	C953.438110,1249.296875 952.175232,1247.922485 951.980103,1245.959106 
	C951.776855,1243.914429 950.529236,1242.614380 948.616028,1242.047729 
	C945.476562,1241.118042 946.002502,1238.605225 946.060364,1236.315186 
	C946.124756,1233.768311 948.366211,1233.759644 949.993958,1233.230957 
	C952.541443,1232.403687 954.128906,1233.658813 955.002686,1236.016113 
	C956.963074,1241.304932 962.010864,1243.876343 965.459778,1247.855591 
	C966.233643,1248.748535 967.448486,1249.425781 968.458191,1248.203979 
	C969.318359,1247.163086 968.909973,1246.068359 967.985046,1245.105469 
	C965.072083,1242.072998 965.354553,1236.804565 960.727173,1234.874146 
	C959.840454,1234.504272 959.864563,1232.893677 960.837646,1232.306030 
	C962.708252,1231.176636 963.791931,1228.470093 966.752075,1229.389404 
	C969.664978,1230.294189 972.352844,1231.455933 974.384033,1233.861694 
	C976.972168,1236.927002 979.949646,1236.442871 982.830566,1234.425415 
	C984.718628,1233.103149 985.931763,1231.353027 982.743408,1230.103638 
	C978.532166,1228.453369 979.976685,1225.796021 981.491638,1223.293823 
	C982.675293,1221.338867 984.189026,1219.576294 985.486084,1217.685791 
	C987.774353,1214.350464 986.122192,1212.036987 984.652039,1209.485596 
	C983.153809,1206.885376 980.839844,1206.697266 978.137024,1207.412720 
	C974.786316,1208.299927 971.451599,1206.481812 967.856750,1207.666504 
	C964.259277,1208.852173 962.738281,1211.088989 962.089966,1214.284302 
	C961.760803,1215.906616 961.541321,1217.419434 960.742249,1218.972778 
	C959.128906,1222.109009 958.077026,1223.450195 955.151672,1219.834839 
	C951.686768,1215.552490 947.405884,1216.135864 944.145020,1220.675415 
	C940.780396,1225.359375 935.400879,1226.191772 930.712463,1222.821899 
	C929.305420,1221.810669 928.981689,1220.569214 929.184509,1218.924316 
	C929.800049,1213.930420 928.207703,1211.831299 923.195312,1211.079712 
	C920.790588,1210.719116 917.407227,1211.937256 918.407898,1207.196533 
	C918.658386,1206.009888 917.058228,1206.048828 916.083801,1206.098145 
	C914.638184,1206.171265 914.005066,1207.102173 914.060181,1208.428955 
	C914.207214,1211.965820 914.026245,1215.305786 909.487549,1215.879883 
	C905.933228,1216.329590 899.029663,1212.327393 898.249146,1208.834229 
	C896.889893,1202.750854 893.385315,1197.225708 896.618835,1190.121582 
	C899.380798,1184.053467 896.554749,1180.280396 891.254150,1179.236938 
	C888.930298,1178.779663 887.269592,1179.227905 885.689697,1181.345215 
	C882.918274,1185.059448 880.930420,1184.744141 878.987061,1180.599487 
	C878.382202,1179.309692 877.931580,1177.977295 876.262329,1178.366333 
	C874.776184,1178.712646 875.038330,1180.165283 875.028748,1181.289062 
	C875.020325,1182.280396 875.080444,1183.294678 875.288940,1184.260132 
	C875.597412,1185.688477 876.668518,1187.315063 875.202942,1188.422119 
	C873.770691,1189.503784 872.307190,1188.248535 871.138367,1187.416748 
	C869.754883,1186.432129 868.598267,1186.036743 867.125916,1187.212036 
	C865.707275,1188.344360 863.942261,1188.028931 862.297913,1187.947632 
	C860.597290,1187.863647 858.824585,1187.516846 858.257874,1185.636963 
	C857.611267,1183.491821 859.041077,1182.135498 860.694763,1181.212524 
	C863.296753,1179.759888 866.053894,1178.585327 868.654480,1177.130615 
	C873.501343,1174.419556 875.004211,1170.387329 873.266113,1165.366211 
	C872.766113,1163.921753 872.411438,1162.449097 870.583435,1162.145874 
	C868.431519,1161.789062 867.433838,1163.428711 866.873535,1164.858154 
	C865.835754,1167.505737 864.911560,1169.592285 861.355408,1169.036499 
	C858.033936,1168.517456 856.195740,1167.389282 855.521179,1163.657471 
	C854.876404,1160.090332 851.663147,1159.354126 848.371216,1160.482910 
	C843.342224,1162.207397 842.076477,1161.146851 842.956909,1155.349365 
	C842.759460,1154.657349 842.702454,1154.293701 843.014404,1153.654785 
	C843.447815,1152.956055 843.851562,1152.575073 844.004883,1151.520142 
	C843.953491,1147.058228 845.814148,1143.779541 847.867981,1140.665527 
	C849.304932,1138.486816 849.448730,1136.486694 847.361816,1135.039673 
	C841.461609,1130.948364 838.437134,1124.250732 833.047913,1119.343018 
	C836.052795,1114.408813 836.942078,1114.336060 840.324036,1118.739258 
	C844.793762,1122.785645 846.599426,1128.353760 851.152954,1132.260620 
	C853.942078,1134.653442 858.140503,1138.303589 858.281860,1143.425049 
	C858.319702,1144.794312 859.283752,1145.556274 860.624146,1145.462646 
	C862.198364,1145.352661 862.957336,1144.350708 862.923218,1142.768311 
	C862.915955,1142.435425 862.870544,1142.106689 862.815918,1141.777100 
	C862.445557,1139.544800 861.229553,1136.918213 864.542542,1135.947754 
	C867.550842,1135.066650 870.472412,1135.265503 872.715210,1137.988159 
	C876.139404,1142.145142 876.410889,1142.372314 880.296936,1138.472656 
	C885.081909,1133.671143 889.964600,1132.309692 895.832153,1136.247314 
	C896.511963,1136.703613 897.348450,1136.943359 898.162109,1137.123535 
	C900.801880,1137.708496 902.296082,1137.111938 900.934204,1134.106812 
	C899.698669,1131.380493 899.863464,1129.072144 902.976746,1128.076050 
	C906.116760,1127.071289 908.626038,1127.883667 909.261536,1131.620483 
	C909.588623,1133.543945 910.821533,1134.882690 912.067932,1136.236572 
	C914.209900,1138.563477 914.044250,1140.538696 910.811462,1141.351196 
	C907.618896,1142.153564 906.862610,1144.255493 906.400879,1147.147583 
	C905.626160,1151.999390 903.006775,1153.159912 898.533081,1151.046753 
	C894.521973,1149.151978 890.937988,1146.838867 889.591797,1142.166870 
	C889.181458,1145.501099 889.779785,1148.071533 893.007935,1150.141479 
	C897.374023,1152.941162 901.340271,1154.286987 906.992249,1154.316772 
	C913.047668,1154.348877 918.314514,1152.836426 923.881470,1151.617554 
	C925.050415,1151.361694 926.280945,1151.112305 926.964661,1150.142822 
	C930.313721,1145.393677 934.794128,1146.642456 939.167297,1147.845703 
	C943.651184,1149.079346 944.948486,1151.969238 943.211426,1156.253296 
	C942.834656,1157.182739 942.258972,1158.005371 941.758179,1158.869019 
	C940.945068,1160.271240 939.643066,1161.622559 940.950623,1163.395020 
	C942.281372,1165.198853 943.922302,1164.257324 945.855591,1163.763184 
	C950.690369,1163.674316 954.353943,1159.824585 959.411255,1161.125366 
	C961.770630,1165.017090 964.073303,1168.208130 968.197144,1170.254761 
	C971.084595,1171.687744 972.240356,1175.297241 973.298706,1178.465332 
	C974.784363,1182.912109 976.678345,1183.443848 980.201538,1180.191040 
	C982.028503,1178.504272 983.553467,1176.499390 986.752930,1176.669800 
	C989.814026,1177.171997 992.576477,1176.206421 995.598145,1177.202637 
	C997.811157,1181.054199 1001.347107,1183.053711 1004.528564,1185.949463 
	C1005.523804,1188.631470 1004.799561,1190.952026 1004.554138,1193.673218 
	C1004.403137,1195.364990 1004.831726,1196.657227 1004.555298,1198.340332 
	C1004.835815,1201.605103 1004.271790,1204.681885 1006.558044,1207.486572 
	C1008.319214,1214.467407 1007.944946,1221.649536 1012.050110,1227.530640 
	C1013.169556,1225.669922 1011.525085,1222.028931 1015.725830,1221.786499 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M821.372803,1130.000732 
	C820.992920,1127.840820 820.985718,1125.681519 820.978760,1123.522339 
	C820.972168,1121.470093 820.017273,1119.967773 818.117249,1119.288940 
	C816.301208,1118.640259 814.897339,1119.390137 813.954651,1121.062256 
	C813.714600,1121.488037 813.384949,1121.885864 813.022278,1122.214844 
	C812.356628,1122.818481 810.891113,1123.029175 810.840820,1122.549438 
	C810.120117,1115.664429 801.658630,1124.598633 800.786804,1117.469971 
	C800.336975,1113.792236 799.150208,1109.989014 795.989563,1107.334351 
	C795.532959,1106.950806 795.075439,1106.119751 795.170410,1105.595703 
	C796.419312,1098.703247 792.358704,1097.114746 786.371033,1097.005371 
	C784.928650,1093.715454 787.502991,1093.723999 789.290833,1093.809204 
	C794.608521,1094.062378 799.626953,1095.234497 801.980042,1100.853638 
	C803.101929,1103.532471 804.754517,1104.355225 807.447632,1103.194946 
	C812.436523,1101.045654 817.061096,1101.069824 820.856445,1106.370605 
	C821.814758,1103.331665 821.842712,1101.410645 819.592468,1099.572754 
	C816.229065,1096.826050 815.289490,1090.331665 817.442017,1086.996826 
	C818.968445,1084.631958 825.861084,1083.081543 828.958557,1084.406128 
	C832.759827,1086.031982 835.873230,1091.611572 834.548401,1095.421143 
	C833.332947,1098.916260 834.513123,1101.545166 836.515503,1104.568115 
	C836.597351,1110.036255 833.149292,1113.878662 831.264404,1118.709595 
	C828.062622,1119.350220 827.850281,1121.372192 828.127014,1123.689453 
	C828.880554,1130.000977 828.290283,1130.585693 821.372803,1130.000732 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M886.144104,1193.779053 
	C888.704102,1196.251221 888.082153,1199.584351 889.662964,1202.181396 
	C890.732239,1203.938232 889.342712,1205.821655 887.054932,1205.896973 
	C882.583801,1206.043945 878.104858,1205.980591 873.629395,1205.959473 
	C871.367126,1205.948853 870.588074,1204.583618 871.350708,1202.669189 
	C872.415466,1199.996338 873.418884,1197.495483 873.854126,1194.488770 
	C874.277100,1191.566895 878.367920,1192.022705 881.182800,1191.998657 
	C882.920776,1191.983765 884.411072,1192.628296 886.144104,1193.779053 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M831.333313,1119.000000 
	C831.119141,1114.005615 833.004944,1109.675537 835.990234,1105.377686 
	C836.810547,1104.425293 837.647705,1104.659546 838.764343,1105.037109 
	C840.261292,1105.956421 840.647095,1104.652466 841.700317,1104.064209 
	C848.489319,1103.158447 850.043335,1104.365845 850.719971,1111.070312 
	C850.365723,1112.260986 849.870789,1113.033813 849.945190,1114.291382 
	C850.031006,1115.316528 849.963013,1116.001221 849.638306,1117.017334 
	C848.962585,1118.251465 848.209839,1118.948853 847.136719,1119.893311 
	C844.498352,1121.530029 842.134277,1121.768433 839.351318,1120.222168 
	C838.163696,1115.030640 835.808960,1117.484375 833.332397,1118.954346 
	C832.555542,1119.000000 832.111084,1119.000000 831.333313,1119.000000 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M844.235596,1153.657227 
	C843.695679,1154.127075 843.442566,1154.320923 843.120361,1154.790894 
	C836.722473,1156.787354 836.712158,1156.789185 838.193726,1164.973022 
	C838.475098,1166.527466 839.811829,1168.272705 838.050293,1169.523315 
	C836.389160,1170.702759 835.006226,1169.128296 833.545959,1168.431030 
	C831.365723,1167.390381 828.786560,1167.150024 827.860291,1164.203491 
	C826.911682,1161.185425 826.344604,1158.711914 830.398132,1157.684448 
	C832.941101,1157.039795 834.232056,1155.327271 834.043640,1152.537598 
	C833.968811,1151.429565 834.083496,1150.090332 834.654297,1149.206421 
	C835.597290,1147.746094 836.030884,1145.539917 838.137756,1145.285400 
	C840.360168,1145.016968 840.332031,1147.380737 841.337646,1148.550781 
	C841.979797,1149.297974 842.707458,1149.971558 843.698364,1150.839111 
	C844.576965,1151.686035 844.741516,1152.455933 844.235596,1153.657227 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M1009.639282,1262.210449 
	C1010.113708,1262.722412 1010.440125,1262.951904 1010.511719,1263.244019 
	C1011.062805,1265.492920 1015.027954,1266.526733 1013.055176,1269.567383 
	C1011.171875,1272.469849 1008.568359,1270.202637 1006.252869,1270.099121 
	C1004.127136,1270.004028 1001.917725,1269.530151 999.918030,1270.739868 
	C998.918152,1271.344971 997.938110,1272.180420 996.832947,1271.015503 
	C995.777283,1269.902710 995.983643,1268.603638 996.413330,1267.280884 
	C998.275818,1261.547241 1003.559998,1259.370239 1009.639282,1262.210449 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M949.835205,1264.092529 
	C952.319580,1264.295288 953.917358,1265.523438 955.499451,1266.723511 
	C956.697876,1267.632690 959.264587,1267.685303 958.514099,1269.932617 
	C957.956543,1271.602295 955.804077,1271.640747 954.223145,1271.888428 
	C951.948547,1272.244995 949.620728,1271.890015 947.337280,1272.676147 
	C944.705139,1273.582397 942.724426,1272.693604 942.165344,1269.751465 
	C941.580627,1266.674194 942.763855,1264.994385 946.068542,1264.914917 
	C947.198059,1264.887573 948.316162,1264.389648 949.835205,1264.092529 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M944.317627,1226.184570 
	C945.712463,1225.219849 947.078796,1224.714966 948.277039,1223.947754 
	C949.153931,1223.386353 949.645386,1221.748169 951.000427,1222.847656 
	C952.148682,1223.779297 952.987793,1225.107788 952.507019,1226.715210 
	C952.134949,1227.958984 951.507935,1229.756348 950.183472,1229.507202 
	C948.030090,1229.102539 944.968506,1229.922119 944.317627,1226.184570 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M947.215210,1258.644287 
	C946.125427,1257.285645 945.687073,1256.129761 946.901245,1254.123779 
	C948.664551,1256.072144 950.267395,1257.843140 952.200134,1259.978760 
	C949.819702,1260.963501 948.673584,1259.796509 947.215210,1258.644287 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M931.305298,1228.040283 
	C932.572266,1228.118286 934.293274,1227.873779 934.113525,1228.809448 
	C933.725708,1230.828125 931.663025,1230.158691 930.542969,1229.722900 
	C928.832214,1229.057251 929.900024,1228.413940 931.305298,1228.040283 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M861.505615,1201.222656 
	C861.131531,1200.082520 861.388000,1199.362305 862.378296,1199.246582 
	C863.092712,1199.163086 863.667480,1199.634644 863.503296,1200.376465 
	C863.312256,1201.239746 862.657593,1201.684692 861.505615,1201.222656 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M926.043945,1233.465820 
	C926.694214,1233.823975 926.703491,1234.183716 926.236877,1234.500977 
	C926.139343,1234.567261 925.913452,1234.564209 925.818542,1234.494873 
	C925.364685,1234.163086 925.381409,1233.803833 926.043945,1233.465820 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M1247.000000,1269.600098 
	C1242.142456,1273.111206 1236.222656,1272.549561 1230.929443,1274.521606 
	C1227.807983,1275.684448 1223.793823,1272.437988 1221.632324,1269.028809 
	C1220.374146,1267.044434 1221.778687,1265.767456 1223.578491,1265.403320 
	C1225.505981,1265.013550 1227.811401,1264.461548 1229.470459,1265.146484 
	C1234.999512,1267.429199 1240.565430,1265.934326 1246.555298,1266.000000 
	C1247.000000,1267.066650 1247.000000,1268.133423 1247.000000,1269.600098 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1219.000977,1168.382690 
	C1224.312622,1167.047241 1229.664062,1165.679199 1234.768066,1169.724609 
	C1235.122437,1171.959839 1235.466675,1173.937744 1235.308716,1175.874756 
	C1235.128784,1178.081909 1234.041748,1180.987549 1231.543091,1179.703857 
	C1227.279297,1177.512939 1222.362427,1175.958252 1219.512329,1171.623901 
	C1219.023560,1170.880615 1219.156372,1169.728638 1219.000977,1168.382690 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1228.802612,1186.422974 
	C1232.851074,1183.604492 1236.664185,1185.411499 1240.330688,1187.477905 
	C1241.444458,1188.105469 1242.019653,1189.503540 1241.434326,1190.957153 
	C1240.675903,1192.841064 1239.150146,1193.908081 1237.236938,1193.861816 
	C1234.430908,1193.793823 1232.076172,1192.566772 1231.179688,1189.702637 
	C1230.762939,1188.371216 1230.411377,1187.213989 1228.802612,1186.422974 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M1245.000000,1178.413330 
	C1248.882202,1178.959595 1252.782227,1186.578857 1250.679688,1189.662109 
	C1248.837036,1192.364136 1246.947754,1190.572266 1245.599487,1188.826416 
	C1243.172485,1185.684204 1245.090332,1182.172852 1245.000000,1178.413330 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1269.794922,1228.800293 
	C1266.278198,1227.666016 1264.806763,1223.668091 1266.598511,1221.265381 
	C1267.241577,1220.403076 1268.131592,1220.068115 1269.181885,1220.115234 
	C1270.473389,1220.172852 1271.589478,1220.813599 1271.726440,1222.036011 
	C1271.989746,1224.383057 1272.943481,1227.013794 1269.794922,1228.800293 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1237.000000,1170.424805 
	C1239.552856,1170.568726 1243.289429,1168.402954 1244.490234,1171.373535 
	C1245.517212,1173.913818 1241.552979,1175.190430 1240.158447,1177.700439 
	C1235.465210,1177.099487 1237.493896,1173.445312 1237.000000,1170.424805 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1278.790771,1233.879639 
	C1279.000000,1236.041626 1279.000000,1238.083374 1279.000000,1240.562500 
	C1278.695557,1241.126831 1278.442383,1241.320679 1278.120361,1241.790771 
	C1274.097168,1239.088257 1273.873901,1236.424194 1277.627930,1233.061157 
	C1278.126953,1233.304321 1278.320801,1233.557495 1278.790771,1233.879639 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1277.634033,1184.950684 
	C1278.000000,1185.750000 1278.000000,1186.500000 1278.000000,1187.625000 
	C1277.009644,1188.060425 1276.007324,1188.044678 1275.031860,1188.198853 
	C1273.486572,1188.442993 1271.888672,1189.783691 1270.484985,1188.084961 
	C1270.194580,1187.733276 1270.283813,1186.669189 1270.610474,1186.277222 
	C1271.818604,1184.827515 1273.361572,1183.892944 1275.376099,1184.302979 
	C1276.020630,1184.434082 1276.638306,1184.698120 1277.634033,1184.950684 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1262.561523,1248.973389 
	C1260.920044,1248.697266 1259.024536,1249.790527 1258.597412,1247.651733 
	C1258.444458,1246.885742 1259.395874,1246.274658 1260.260864,1246.176758 
	C1261.930908,1245.987793 1263.608154,1246.120850 1264.280029,1247.951050 
	C1264.545654,1248.674561 1263.542236,1248.812866 1262.561523,1248.973389 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1270.890015,1236.971924 
	C1270.658325,1238.464600 1270.269775,1239.924927 1268.868652,1239.295654 
	C1267.476685,1238.670654 1267.725708,1236.947998 1268.477661,1235.936890 
	C1269.456421,1234.620850 1270.388428,1235.239380 1270.890015,1236.971924 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1238.051270,1228.249512 
	C1238.430420,1226.315308 1239.316284,1225.949829 1240.330933,1227.238647 
	C1241.166016,1228.299316 1241.471680,1229.860352 1239.961792,1230.521606 
	C1238.418579,1231.197388 1238.161621,1229.786499 1238.051270,1228.249512 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1253.944824,1244.163574 
	C1253.828125,1245.220703 1253.933716,1246.556763 1252.961914,1246.212646 
	C1251.393433,1245.657227 1251.754517,1244.000732 1252.464600,1243.062134 
	C1253.277344,1241.987427 1253.655151,1243.106934 1253.944824,1244.163574 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1239.111572,1219.541260 
	C1238.092285,1219.945923 1237.258179,1220.093994 1236.731567,1219.304810 
	C1236.624512,1219.144531 1237.024536,1218.355469 1237.304077,1218.280884 
	C1238.217407,1218.036987 1239.266724,1217.834473 1239.111572,1219.541260 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1262.781738,1266.257080 
	C1263.650391,1266.851318 1263.593750,1267.398682 1262.892212,1267.672363 
	C1262.573242,1267.796631 1261.864014,1267.621460 1261.722412,1267.365967 
	C1261.331055,1266.659668 1261.833252,1266.316895 1262.781738,1266.257080 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M621.312622,1200.273926 
	C621.161255,1199.534790 621.184021,1198.916138 621.504272,1198.627075 
	C627.186279,1193.500732 627.190308,1193.513184 621.565674,1187.967285 
	C620.992798,1187.402466 620.251709,1186.818359 620.650146,1185.866211 
	C621.047241,1184.917358 621.996216,1185.087891 622.792664,1185.015991 
	C625.351257,1184.784912 628.065186,1185.647339 630.218994,1183.223022 
	C634.324829,1178.601685 633.607300,1174.676636 627.647827,1170.397827 
	C621.998108,1166.341431 620.481567,1166.673462 616.711670,1172.389526 
	C615.571228,1174.118530 614.301086,1176.480713 611.711731,1175.608765 
	C609.328796,1174.806274 608.157471,1172.462891 608.113159,1170.068359 
	C608.040955,1166.168823 609.330688,1162.311646 608.238586,1158.326172 
	C607.925293,1157.182861 608.843933,1155.685425 610.291260,1155.250366 
	C611.986145,1154.740601 613.720032,1154.872437 614.688354,1156.575439 
	C616.267090,1159.351807 618.011108,1159.286865 620.308472,1157.425903 
	C622.655457,1155.524902 622.204773,1154.213379 619.724060,1153.088745 
	C618.971008,1152.747192 618.163574,1152.525391 617.411560,1152.181885 
	C610.849243,1149.183350 610.639221,1147.378174 616.426392,1143.270752 
	C617.489136,1142.516602 618.253479,1141.816162 617.239380,1140.226562 
	C612.291870,1135.284546 612.046265,1133.776978 615.355347,1128.081543 
	C616.822571,1126.782227 618.292725,1126.635986 619.789001,1126.606445 
	C621.033752,1126.581787 622.066528,1126.415894 622.100769,1124.775391 
	C622.198242,1120.101807 625.728516,1119.748169 629.061951,1119.650635 
	C637.755066,1119.396606 637.755249,1119.484253 640.525085,1111.310303 
	C640.739197,1110.678345 641.154724,1110.160278 641.935913,1109.478271 
	C644.945557,1108.356689 646.613037,1109.578613 647.584167,1111.965576 
	C648.253357,1113.610229 649.424133,1114.997803 649.978088,1117.100586 
	C650.108704,1119.151855 649.456116,1120.643799 647.857422,1121.014160 
	C644.087524,1121.887695 645.307373,1123.916382 646.227051,1126.235962 
	C649.037842,1133.325073 644.672852,1139.874756 637.088867,1140.117920 
	C633.182800,1140.243164 631.281860,1138.050903 629.805664,1132.333374 
	C631.167175,1136.199585 630.848511,1140.465332 633.012207,1144.130127 
	C634.225891,1146.186035 636.147705,1145.662964 637.922241,1145.184814 
	C641.664673,1144.176147 644.419617,1145.435913 646.462708,1148.618774 
	C647.544189,1150.303833 648.940796,1151.752808 650.200562,1153.300415 
	C652.565796,1156.206299 653.473145,1158.958008 650.510193,1162.120239 
	C649.106262,1163.618652 648.719971,1165.665405 649.381531,1167.751709 
	C649.917053,1169.440430 650.806152,1170.764282 652.745850,1170.996704 
	C654.410767,1171.196289 655.627380,1170.655151 656.399231,1169.059814 
	C656.967590,1167.885132 657.587219,1166.400024 659.212463,1166.659546 
	C661.011963,1166.946533 661.303711,1168.655029 661.578491,1170.114380 
	C662.503174,1175.023926 663.642212,1179.974487 661.253052,1184.791382 
	C660.444458,1186.421753 660.803223,1187.431152 662.535645,1187.651733 
	C668.099060,1188.359741 671.740967,1191.086548 672.932190,1196.794800 
	C673.341431,1198.756226 674.944031,1199.289917 676.816772,1199.036621 
	C677.905029,1198.889648 678.699585,1198.382446 678.860962,1197.211670 
	C679.135681,1195.218750 677.518372,1194.819824 676.247986,1194.059937 
	C671.063171,1190.958008 670.930603,1188.759644 674.423462,1183.744507 
	C678.429321,1177.992798 675.876038,1171.952637 674.218323,1166.089600 
	C673.861206,1164.826660 672.900330,1163.772217 672.479126,1162.502075 
	C671.599487,1159.849609 672.282166,1157.768188 675.112061,1157.052002 
	C677.839233,1156.361816 679.428955,1157.435181 679.940186,1160.470581 
	C681.052795,1167.076294 684.745544,1168.316528 690.454773,1164.695801 
	C693.970337,1162.466187 697.075134,1160.474243 696.682434,1155.596802 
	C696.525330,1153.645508 697.928528,1151.809082 699.982483,1151.841553 
	C704.306030,1151.910034 708.417175,1149.947388 713.134766,1150.826416 
	C714.970703,1151.637451 716.462402,1152.314819 716.218140,1154.657227 
	C718.330261,1161.806152 711.619385,1162.017700 708.246948,1165.330444 
	C712.171387,1166.243164 715.305664,1166.538940 718.672607,1164.060669 
	C723.549927,1160.470581 727.458191,1162.103638 729.031860,1167.836304 
	C729.897156,1170.988403 736.912476,1173.245605 740.901001,1171.271606 
	C743.268066,1170.100098 745.183167,1169.412842 747.075500,1171.707764 
	C748.932800,1173.960205 747.038025,1175.337646 745.775696,1176.814819 
	C744.404724,1178.418945 744.294006,1181.634155 741.905518,1181.669556 
	C737.646240,1181.732422 733.278442,1181.337280 729.138611,1180.360352 
	C725.969238,1179.612549 722.422363,1178.823486 721.392212,1174.458862 
	C720.950928,1172.588867 718.467590,1171.927979 716.309998,1172.155762 
	C715.420715,1172.249512 714.547913,1172.776611 714.470154,1173.603149 
	C714.279907,1175.624023 715.772949,1176.706787 717.014954,1178.108276 
	C720.356018,1181.878540 725.201294,1182.771729 729.245667,1185.155640 
	C730.543945,1185.920898 732.035034,1186.899292 731.717346,1188.580200 
	C731.346558,1190.541382 729.414185,1191.070557 727.779907,1190.906494 
	C722.954346,1190.422119 721.718018,1193.182129 720.634888,1197.226440 
	C719.032104,1203.211304 716.653870,1208.994019 714.495728,1214.822144 
	C713.547607,1217.382690 711.318420,1217.998779 708.816162,1218.012207 
	C706.643555,1218.023804 704.409363,1218.722656 704.519775,1221.114990 
	C704.620728,1223.299805 705.629456,1225.287354 708.505859,1226.101318 
	C714.478943,1227.791870 720.294922,1228.743286 726.519775,1226.107422 
	C726.005981,1225.686768 725.709961,1225.245728 725.343201,1225.175903 
	C722.722839,1224.677002 719.281494,1225.402588 719.543030,1220.930298 
	C719.969299,1213.640869 720.817200,1212.520874 726.086975,1211.988525 
	C730.370972,1211.555786 734.663513,1211.079468 738.748413,1209.588135 
	C740.305237,1209.019775 741.782654,1208.848999 742.582886,1210.603271 
	C743.353760,1212.293213 741.848755,1212.804443 740.806641,1213.629517 
	C736.666016,1216.907959 736.760376,1221.934814 736.114136,1226.431885 
	C735.336792,1231.840454 734.901245,1237.226196 731.758667,1241.933228 
	C730.751404,1243.441895 730.650085,1245.139282 731.590942,1246.652466 
	C732.653442,1248.361450 734.462341,1248.115479 736.102844,1247.755615 
	C737.060364,1247.545410 737.921692,1246.931274 738.866089,1246.619385 
	C740.395386,1246.114014 742.593994,1245.080933 743.217163,1246.983643 
	C743.853149,1248.925415 741.507019,1248.868408 740.108398,1249.053223 
	C738.013062,1249.329956 738.039612,1250.858276 738.008179,1252.393677 
	C737.972290,1254.143433 738.039673,1255.756836 740.366272,1256.052856 
	C741.936340,1256.252686 743.599731,1257.429565 744.772034,1255.342651 
	C745.261292,1254.471924 745.692261,1253.567505 746.203552,1252.710449 
	C747.095215,1251.215454 748.488464,1250.698120 750.057373,1251.276489 
	C752.005066,1251.994629 752.633972,1253.817139 752.876343,1255.659058 
	C753.099548,1257.354980 752.283508,1258.728027 750.492493,1258.847046 
	C748.348572,1258.989624 746.580811,1259.514160 745.272217,1261.773438 
	C743.273743,1261.413696 742.950500,1258.776855 740.302856,1259.029907 
	C739.791931,1259.144043 739.630432,1259.258789 739.231995,1259.194092 
	C735.680054,1255.418457 732.189514,1256.587280 728.333191,1258.817139 
	C724.076111,1257.983887 725.363281,1254.255737 724.442505,1250.642456 
	C721.929504,1256.126831 719.610413,1255.369263 717.193298,1251.844116 
	C716.739929,1251.182983 716.015564,1250.670898 715.334717,1250.210815 
	C714.222717,1249.459106 712.776489,1248.778687 713.338867,1247.184692 
	C713.982544,1245.360229 715.744141,1245.120605 717.471069,1245.094360 
	C718.931458,1245.072144 720.911926,1247.062866 721.713196,1244.545044 
	C722.410767,1242.353027 722.382324,1239.975708 720.518188,1238.007812 
	C718.857483,1236.254639 716.986572,1235.487671 714.709290,1236.359741 
	C713.815002,1236.702271 713.118408,1237.533203 712.286194,1238.071167 
	C710.767761,1239.052490 710.135437,1238.318848 710.112671,1236.795410 
	C710.085510,1234.979492 712.177673,1232.413208 709.438843,1231.550049 
	C705.069275,1230.172852 701.149475,1225.915283 695.938660,1228.760864 
	C694.573792,1229.506226 693.155212,1230.568970 691.899597,1229.178345 
	C688.489624,1225.401978 686.044800,1228.148193 683.505798,1230.223022 
	C680.138855,1232.974487 677.525269,1232.535645 676.003540,1228.551758 
	C675.004700,1225.936890 672.894226,1224.255981 671.286377,1222.213867 
	C669.331055,1219.730225 667.171692,1221.334595 665.381226,1223.098145 
	C664.576599,1223.890625 663.792847,1225.018188 662.439636,1224.456543 
	C661.186707,1223.936401 660.565247,1222.404053 661.108765,1221.550903 
	C664.414734,1216.361694 660.059814,1211.977539 659.827698,1207.193604 
	C659.804199,1206.710327 659.676392,1206.151367 659.398071,1205.774414 
	C657.697449,1203.470947 661.968018,1199.823730 658.683167,1198.884155 
	C656.897156,1198.373291 653.755127,1199.834595 651.163330,1200.239990 
	C649.315430,1200.528931 647.716003,1201.058105 646.009827,1199.736694 
	C644.590881,1198.637695 642.831177,1198.791748 641.239258,1199.465210 
	C634.821228,1202.180908 628.203125,1200.932007 621.312622,1200.273926 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M716.375488,1155.052490 
	C716.000977,1153.285278 714.476501,1153.129150 713.214966,1152.242310 
	C712.263611,1151.277954 711.218750,1150.405029 710.391663,1150.805908 
	C706.624573,1152.632324 702.729065,1150.512207 699.206482,1151.188477 
	C694.032349,1152.181763 689.376587,1150.625610 684.512329,1150.080200 
	C680.345581,1149.613159 677.429626,1143.780518 679.498962,1140.133423 
	C680.712769,1137.994141 682.669617,1137.438477 684.507935,1139.059204 
	C687.354431,1141.569092 690.976440,1141.486694 694.261902,1142.507324 
	C696.014099,1143.051636 697.863098,1142.795410 698.290833,1140.758057 
	C698.743286,1138.603394 697.661560,1136.881836 695.467346,1136.112183 
	C694.841919,1135.892822 693.947754,1135.669067 693.515320,1136.002808 
	C689.223633,1139.314697 683.761963,1137.523804 679.187500,1139.357910 
	C673.988770,1141.442261 669.304016,1139.190308 664.710876,1137.596436 
	C662.226257,1136.734253 662.584717,1134.115356 664.361816,1132.210571 
	C665.302490,1131.202515 666.634827,1130.590332 667.950745,1130.648071 
	C674.326172,1130.927979 680.026489,1127.391846 686.546021,1127.809326 
	C691.630249,1128.134766 694.069458,1125.752563 692.912109,1123.096924 
	C691.312561,1119.426880 688.008301,1121.126465 685.566772,1121.845093 
	C682.419434,1122.771484 679.509155,1123.231567 676.315796,1122.151123 
	C674.088684,1121.397583 671.658447,1121.583008 669.391113,1122.187744 
	C664.276367,1123.551636 659.171082,1123.310059 653.677002,1122.360229 
	C653.087280,1122.199585 652.859131,1122.117554 652.316956,1121.861206 
	C650.791748,1120.906738 650.591492,1119.331543 649.238770,1118.237427 
	C648.215942,1115.598022 645.505188,1114.247803 645.627747,1111.229492 
	C645.680908,1109.919556 643.909302,1110.092529 642.396851,1110.003174 
	C635.309753,1110.648926 628.605225,1109.411865 621.908508,1110.151245 
	C620.944702,1110.257568 619.386780,1109.671021 619.389587,1108.933594 
	C619.406128,1104.567749 615.688843,1103.666016 613.123108,1101.664429 
	C612.628784,1101.278809 612.555054,1100.491821 612.091614,1099.586182 
	C611.792725,1098.799805 611.676453,1098.332397 611.561401,1097.495605 
	C611.416443,1095.940552 611.074158,1095.110107 609.781555,1094.475586 
	C605.435242,1092.341797 605.454834,1092.088379 609.075684,1088.203125 
	C613.204834,1086.301147 613.204834,1086.301147 609.167236,1081.384277 
	C607.701965,1079.395996 608.710693,1077.161499 607.700439,1075.340942 
	C605.251770,1075.530762 605.178955,1077.794312 604.184021,1079.171509 
	C601.673340,1082.646484 598.584045,1083.046631 595.306396,1080.216187 
	C594.553162,1079.565796 594.065796,1078.492676 593.128906,1078.191162 
	C586.880493,1076.180664 586.874939,1070.665527 585.818604,1065.310791 
	C585.027344,1062.309937 583.625366,1059.790894 584.249512,1056.567139 
	C585.560486,1054.685425 587.245117,1054.564941 588.700256,1055.319458 
	C591.005737,1056.514893 593.024841,1055.813110 595.469055,1054.971436 
	C596.293701,1054.918579 596.775391,1054.971802 597.258606,1055.016235 
	C603.705322,1055.607056 604.106506,1055.088135 601.920044,1048.563843 
	C602.312988,1044.535400 602.751831,1040.880493 608.163086,1041.087646 
	C610.249451,1042.054077 611.153320,1043.654419 612.371582,1045.447388 
	C612.193665,1048.294434 612.450867,1049.936157 615.828186,1049.872437 
	C618.243408,1049.827026 619.149841,1051.628052 617.918213,1054.060303 
	C617.102478,1055.671021 614.298645,1057.374023 616.345093,1059.026001 
	C618.337219,1060.633911 620.047852,1057.924561 621.707520,1056.722900 
	C621.973267,1056.530518 622.241394,1056.341553 622.859253,1056.017212 
	C624.159424,1055.531494 625.151978,1055.405640 626.549927,1055.486084 
	C628.745056,1055.626221 630.583923,1055.297241 632.376587,1055.679077 
	C634.598267,1056.151978 636.825500,1057.100464 636.652344,1059.713501 
	C636.346619,1064.327271 639.508850,1065.103394 642.781982,1065.998291 
	C644.943176,1066.589233 647.526917,1066.003296 649.052124,1068.620850 
	C649.102051,1071.062378 648.169739,1072.053955 646.056763,1072.022461 
	C644.714905,1072.002441 643.198364,1072.092529 643.216125,1073.964355 
	C643.237366,1076.193237 645.037354,1075.888550 646.485718,1075.994385 
	C646.815918,1076.018799 647.149292,1075.997803 647.479797,1076.019775 
	C650.735291,1076.236694 653.321838,1077.826050 654.595459,1080.696899 
	C656.012756,1083.891357 652.942444,1085.321655 651.215210,1087.185181 
	C650.682373,1087.760132 650.050781,1088.243530 649.048950,1089.141235 
	C654.417236,1090.734131 658.767639,1094.062988 664.339233,1093.011475 
	C665.146423,1092.859131 666.160156,1093.171265 666.786438,1092.789429 
	C672.133240,1089.530273 674.379211,1091.111450 674.095703,1097.198120 
	C674.002502,1099.199463 675.778320,1099.982910 677.200378,1100.729370 
	C680.880066,1102.661133 682.487549,1105.629272 681.984741,1109.700073 
	C681.542053,1113.284302 683.128174,1114.085083 686.620239,1113.009644 
	C691.009216,1112.308105 694.644653,1111.050781 696.662415,1106.917847 
	C698.496948,1107.001221 699.997925,1107.073120 701.490112,1106.989258 
	C704.622131,1106.813110 706.168396,1108.135620 706.005981,1111.369995 
	C705.823608,1115.002930 706.063416,1118.605347 705.252502,1122.262329 
	C703.887634,1128.417847 713.276184,1137.579590 719.469849,1136.567383 
	C721.198730,1136.284912 720.893188,1135.013062 721.029785,1133.938477 
	C721.216370,1132.469604 721.825928,1131.437012 723.402466,1131.168457 
	C725.118469,1130.875977 727.503052,1134.198730 728.032227,1131.828369 
	C728.893005,1127.972656 733.768311,1126.550903 733.066406,1122.087769 
	C732.863770,1120.799194 732.351868,1118.652832 734.434692,1118.399902 
	C736.294922,1118.174194 736.318237,1120.305786 737.052795,1121.531738 
	C738.424255,1123.820435 738.619629,1125.616943 737.077026,1128.234253 
	C734.118713,1133.253662 736.003174,1136.089111 741.846985,1135.940918 
	C745.153442,1135.857056 748.849365,1137.246704 751.538330,1133.702515 
	C752.469543,1132.475098 753.733521,1133.461548 754.243408,1134.625854 
	C755.241516,1136.904541 757.094666,1137.157104 759.599243,1136.999878 
	C759.682800,1138.618408 759.233826,1140.224121 759.075867,1141.857910 
	C758.750061,1145.227539 757.850769,1147.010620 754.075684,1144.796509 
	C751.703247,1143.405029 749.954407,1145.446289 748.604187,1147.010742 
	C746.809998,1149.089478 744.616516,1151.140381 745.134338,1154.362793 
	C745.245850,1155.057251 745.644287,1155.641357 746.402405,1155.662964 
	C747.419617,1155.692261 747.619385,1154.891846 747.959839,1154.090088 
	C749.989868,1149.308838 753.386353,1148.876953 756.426208,1153.225830 
	C757.903137,1155.338501 758.929260,1157.380127 762.058533,1157.131836 
	C764.057739,1156.973389 764.954529,1158.508301 764.956665,1160.420288 
	C764.959412,1162.906982 765.018311,1165.406372 764.790894,1167.876099 
	C764.641846,1169.494995 763.620361,1171.658447 766.286438,1171.447754 
	C767.639465,1171.340820 770.858032,1172.278442 770.414246,1168.780273 
	C770.232483,1167.347656 770.786743,1166.521484 772.111938,1166.238647 
	C773.212280,1166.003540 774.069946,1166.536987 774.555481,1167.512817 
	C775.113647,1168.634644 775.243103,1169.984009 774.132874,1170.670776 
	C771.803040,1172.112183 770.631042,1175.609497 766.639648,1174.254150 
	C764.098633,1173.391235 763.180359,1175.881104 763.110718,1177.923218 
	C762.985168,1181.607056 763.495605,1185.382568 765.954346,1188.231445 
	C768.218750,1190.855103 770.254639,1192.948242 766.714233,1196.025146 
	C765.677490,1196.926147 766.741333,1198.208252 766.958374,1199.655273 
	C765.261169,1201.677246 763.615234,1201.031006 762.190430,1199.631714 
	C760.739563,1198.206787 759.047180,1197.942993 757.151855,1197.992432 
	C754.575928,1198.059692 753.498413,1198.762329 755.600159,1201.116211 
	C757.227600,1202.938965 757.725037,1205.066162 756.128235,1206.984863 
	C754.422729,1209.034180 752.882812,1207.002686 751.324402,1206.298828 
	C750.569824,1205.958008 749.690979,1205.854736 748.991028,1205.437988 
	C747.214600,1204.380493 744.427307,1204.238525 744.258911,1201.566772 
	C744.100952,1199.058350 746.001038,1197.769653 748.204346,1197.049438 
	C750.114014,1196.425293 750.144592,1194.934814 749.846619,1193.406128 
	C749.496094,1191.607178 748.301147,1190.491211 746.505249,1190.125854 
	C744.905823,1189.800171 743.034180,1190.401611 741.743347,1189.081055 
	C740.327515,1187.632568 737.206848,1186.522339 741.988892,1184.548950 
	C745.612244,1183.053955 748.424194,1179.777466 752.725037,1178.967163 
	C755.516602,1178.441162 756.149292,1175.744873 756.014160,1172.977661 
	C755.647522,1165.468140 752.708618,1162.210571 743.847595,1159.998657 
	C739.114624,1158.817139 734.474854,1157.602661 732.046143,1152.346191 
	C733.215759,1145.639893 733.215759,1145.639893 726.906616,1141.808105 
	C725.765808,1143.418335 725.735779,1145.656860 723.834778,1147.217773 
	C719.872314,1150.643311 723.254150,1150.952881 725.657227,1151.924194 
	C726.160828,1152.203735 726.344604,1152.345581 726.764160,1152.743774 
	C727.502686,1154.036255 728.057983,1155.050781 728.495728,1156.113770 
	C729.171387,1157.754517 729.332031,1159.449951 727.572021,1160.469238 
	C725.689575,1161.559570 723.619690,1160.921509 722.932068,1159.139160 
	C721.754578,1156.086670 719.177673,1155.864136 716.375488,1155.052490 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M785.013184,1268.594971 
	C784.454468,1269.180176 783.908936,1269.360474 783.181763,1269.770386 
	C779.472961,1271.391479 775.605591,1270.948120 772.147522,1273.936035 
	C770.408691,1275.438477 765.142395,1273.172119 761.622620,1272.108765 
	C760.563293,1271.788696 760.978760,1269.731934 761.375305,1268.432983 
	C761.941895,1266.577148 764.421509,1265.576904 764.660583,1264.358643 
	C766.702454,1253.952881 775.201965,1259.894653 780.418213,1258.366943 
	C785.312134,1256.933838 786.786499,1260.591187 785.013184,1268.594971 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M727.266968,1152.741455 
	C727.000000,1153.000000 726.500000,1153.000000 726.250000,1153.000000 
	C724.428040,1151.555054 724.069458,1149.451172 723.053955,1147.337402 
	C723.259888,1146.403564 723.355347,1145.509155 723.806824,1145.260132 
	C726.331360,1143.867554 722.415222,1139.977051 726.288147,1139.146240 
	C729.504150,1138.456421 729.084961,1142.198242 731.392334,1143.057739 
	C736.029053,1144.784790 736.210510,1147.818970 732.227661,1151.725586 
	C730.511292,1152.161011 729.022583,1152.322021 727.266968,1152.741455 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M758.650696,1225.549561 
	C760.407959,1225.546387 761.847778,1225.899536 763.296753,1225.941772 
	C765.432739,1226.004395 767.693848,1226.306274 767.479492,1228.891235 
	C767.245789,1231.708252 764.562256,1230.928833 762.735596,1230.970337 
	C757.107666,1231.098755 756.531982,1230.364502 758.650696,1225.549561 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M773.426575,1244.639160 
	C771.623901,1243.206177 770.243042,1241.683838 771.572998,1239.842407 
	C772.633728,1238.373535 774.627197,1238.851074 776.184326,1239.291626 
	C777.919800,1239.782593 780.025879,1240.526123 779.824951,1242.712158 
	C779.393066,1247.411987 776.465698,1246.051025 773.426575,1244.639160 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M766.997681,1217.799316 
	C768.690979,1220.479736 766.778931,1220.926514 765.189331,1221.387329 
	C763.346558,1221.921509 761.300171,1223.164185 759.576721,1222.024536 
	C758.371643,1221.227661 759.052979,1219.479492 760.198181,1218.606323 
	C762.133423,1217.130737 764.304077,1216.426514 766.997681,1217.799316 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M760.480103,1248.639404 
	C757.879578,1250.622192 755.895630,1250.399536 755.171448,1247.343262 
	C754.843628,1245.959473 754.279358,1243.365723 756.513733,1243.420288 
	C758.995728,1243.480957 760.901733,1245.307739 760.480103,1248.639404 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M771.046387,1255.406982 
	C768.146729,1253.701904 768.832458,1250.390015 767.287720,1248.102417 
	C767.107544,1247.835571 767.315613,1247.074829 767.594299,1246.779175 
	C768.139771,1246.200317 768.777954,1246.522705 769.331787,1246.943970 
	C772.270508,1249.179688 772.694641,1252.032471 771.046387,1255.406982 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M787.000488,1243.362793 
	C788.605835,1243.327881 790.984436,1242.055420 791.613159,1244.334106 
	C792.093018,1246.073120 790.043518,1246.404541 788.361206,1246.951660 
	C786.895813,1246.143799 787.019653,1244.912964 787.000488,1243.362793 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M762.487915,1254.184814 
	C762.362488,1253.299194 762.311951,1252.605957 762.558289,1252.042358 
	C763.014893,1250.997559 763.703857,1249.617065 765.033569,1250.448120 
	C766.406799,1251.306519 765.145630,1252.483643 764.689209,1253.431885 
	C764.317810,1254.203003 763.657715,1254.691772 762.487915,1254.184814 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M763.413208,1209.658569 
	C763.612000,1210.373657 763.362122,1210.633667 762.806702,1210.521851 
	C762.691406,1210.498657 762.536804,1210.334595 762.519470,1210.217896 
	C762.436584,1209.656860 762.709900,1209.422241 763.413208,1209.658569 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M653.024170,1202.349121 
	C655.124817,1203.009644 656.939758,1203.654419 656.868958,1205.978638 
	C656.803650,1208.121948 654.974182,1208.406616 653.454102,1209.032715 
	C652.394409,1209.469360 651.193604,1209.864990 650.423645,1210.642944 
	C648.616333,1212.469116 647.151855,1211.939453 645.890320,1210.212769 
	C644.482239,1208.285522 644.688538,1206.398804 646.848145,1205.158447 
	C648.710754,1204.088867 650.718933,1203.272827 653.024170,1202.349121 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M603.941589,1218.209961 
	C603.494934,1221.736938 601.637451,1221.324707 599.759705,1220.059570 
	C598.091064,1218.935303 597.337708,1217.075928 598.566772,1215.369141 
	C600.295593,1212.968140 601.646240,1215.348022 602.989624,1216.203613 
	C603.457886,1216.501709 603.623047,1217.275757 603.941589,1218.209961 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M648.943359,1215.686279 
	C647.967285,1216.315186 646.811279,1217.500122 646.047241,1215.293945 
	C647.128174,1213.837646 648.124390,1213.698608 648.943359,1215.686279 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M610.062561,1201.321777 
	C610.750000,1201.000000 611.500000,1201.000000 612.625000,1201.000000 
	C612.439270,1202.990112 611.604004,1203.754150 610.062561,1201.321777 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M638.586670,1211.341309 
	C638.388306,1210.627197 638.636780,1210.366211 639.192627,1210.478516 
	C639.307922,1210.501831 639.462708,1210.665405 639.479919,1210.781860 
	C639.562683,1211.342529 639.289612,1211.577271 638.586670,1211.341309 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1247.012695,109.393402 
	C1247.304565,108.873810 1247.557739,108.679932 1247.879761,108.209763 
	C1253.053955,105.050911 1258.418457,103.534325 1264.656738,104.732567 
	C1266.637573,107.564560 1266.394653,110.650124 1269.283691,113.234543 
	C1271.867310,115.545631 1267.839600,120.148170 1265.610596,121.879158 
	C1261.707520,124.910439 1263.374756,126.295540 1265.936768,128.668686 
	C1262.958130,134.903961 1263.606079,140.811768 1265.792847,146.810532 
	C1266.654785,149.174973 1266.878662,151.722839 1265.558350,154.078705 
	C1263.901733,157.034744 1265.810303,160.367004 1264.248779,163.670700 
	C1263.608887,166.502228 1261.259888,166.115799 1259.354492,166.953232 
	C1254.970581,172.259354 1251.070557,177.564453 1252.677856,184.824493 
	C1253.303833,187.652328 1252.358032,189.716888 1249.344971,190.968140 
	C1245.701904,191.212479 1243.870117,189.096298 1243.437256,186.397186 
	C1242.877197,182.905624 1241.565918,179.823212 1240.014160,176.375916 
	C1239.763794,175.208466 1239.634033,174.364502 1239.273804,173.634140 
	C1237.251221,169.533966 1239.291992,163.840591 1243.457153,161.772049 
	C1245.729126,160.643738 1247.577881,160.980804 1249.319336,162.762497 
	C1250.316284,163.782440 1251.601196,164.418961 1252.848511,163.300003 
	C1254.536743,161.785492 1252.320312,161.220963 1252.001099,160.179581 
	C1250.893921,156.567474 1249.879150,152.889801 1249.321533,149.163376 
	C1249.071289,147.491760 1250.478394,146.421936 1252.441040,145.944595 
	C1258.172974,144.550568 1259.674805,142.081177 1258.973999,135.759598 
	C1258.468140,131.194000 1255.490967,130.672211 1251.736938,131.510696 
	C1251.047607,131.664642 1250.383545,131.479645 1250.308472,130.603073 
	C1250.243042,129.840683 1250.667725,129.301407 1251.390259,129.182755 
	C1253.510132,128.834656 1255.557739,127.986603 1255.759277,125.741241 
	C1256.069702,122.283165 1256.131958,118.724693 1255.642578,115.302719 
	C1255.355469,113.294594 1253.010254,113.023773 1251.120483,113.016846 
	C1248.877563,113.008636 1247.130981,112.461563 1247.012695,109.393402 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1249.153809,191.282043 
	C1251.016602,188.554245 1253.065308,186.267242 1250.296265,182.856934 
	C1249.607056,182.008255 1249.753296,179.584900 1250.487305,178.641266 
	C1252.900269,175.539200 1253.822510,171.898483 1255.043335,168.342514 
	C1255.623779,166.652039 1256.571411,165.822403 1258.691162,166.866821 
	C1259.022339,169.976364 1259.089111,172.798691 1256.854248,175.388565 
	C1255.132202,177.384018 1255.748535,180.110611 1256.794678,182.459503 
	C1257.260376,183.504883 1257.901245,184.520340 1258.657349,185.377884 
	C1260.114258,187.030579 1259.485840,191.072815 1262.351685,190.513718 
	C1265.359619,189.926910 1264.176147,186.090469 1264.860962,183.733475 
	C1265.356079,182.029129 1265.231079,180.139450 1266.312012,178.563934 
	C1267.570312,176.730072 1269.457764,176.795883 1271.239502,177.157333 
	C1273.545654,177.625153 1272.404785,179.290085 1272.314697,180.713745 
	C1271.953613,186.418854 1274.138550,192.168076 1271.997803,197.860718 
	C1271.593506,198.935745 1271.404175,200.091568 1271.057861,201.604736 
	C1266.281616,204.323990 1266.898438,208.176697 1268.059326,212.202911 
	C1268.647339,214.241806 1269.890747,216.618866 1266.984375,217.770248 
	C1264.320923,218.825348 1262.473389,217.615784 1261.784180,214.797714 
	C1261.145142,212.184326 1259.480469,213.066620 1257.562012,213.765427 
	C1256.750366,213.985535 1256.271606,214.037338 1255.421875,213.999451 
	C1253.584106,213.574051 1252.371216,212.792725 1251.219971,211.291183 
	C1249.753296,209.325562 1246.858154,211.978867 1246.053101,209.100540 
	C1245.500244,207.123840 1241.858032,205.645020 1242.708984,204.814117 
	C1245.301025,202.282944 1245.391846,198.017380 1248.986938,196.371750 
	C1251.960083,195.010849 1251.824829,193.342453 1249.153809,191.282043 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1250.833130,211.310822 
	C1252.459839,210.867432 1253.646729,211.331360 1254.734985,212.794510 
	C1255.422241,213.523788 1255.947876,213.661301 1256.787231,213.210571 
	C1261.628784,209.389145 1261.659790,209.381134 1263.213379,215.396790 
	C1263.694336,217.258728 1265.073120,217.071930 1266.325317,216.844025 
	C1267.846558,216.567123 1268.233643,215.393860 1267.213379,214.324738 
	C1265.346191,212.368210 1266.436279,209.884766 1265.736694,207.752426 
	C1264.383789,203.628799 1265.942383,201.780426 1270.593994,201.999878 
	C1273.233521,208.338379 1270.148804,215.125778 1272.648926,221.453644 
	C1273.269165,223.023254 1272.400757,224.314087 1271.269897,225.774338 
	C1269.736450,225.670120 1268.459229,225.022354 1267.212280,225.076187 
	C1264.874268,225.177078 1261.683350,223.808578 1260.500122,226.677078 
	C1259.293945,229.600723 1262.433350,230.188492 1264.252563,231.494247 
	C1266.198486,232.890976 1266.348755,235.237106 1265.783447,237.382874 
	C1265.204102,239.582352 1263.130371,238.745148 1261.599487,239.013153 
	C1257.404663,239.747421 1253.667236,236.974396 1249.255859,237.745758 
	C1248.006470,238.832916 1246.912598,239.092102 1245.344360,238.285858 
	C1244.526733,235.244110 1243.553223,233.544403 1240.115845,234.345154 
	C1236.861450,235.103317 1239.135254,232.672409 1238.960571,231.334015 
	C1244.479370,230.017487 1250.054932,228.078629 1254.792358,233.082489 
	C1255.682129,234.022354 1256.661011,233.544037 1256.915161,232.256744 
	C1257.382202,229.891617 1258.846680,227.515762 1256.946045,225.130295 
	C1255.283569,223.043610 1253.109741,222.735703 1250.670654,223.272568 
	C1249.312866,223.571426 1247.577881,224.893753 1246.726196,223.067123 
	C1245.988281,221.484436 1248.155640,221.190994 1248.990356,220.291077 
	C1250.012085,219.189560 1250.248413,217.865540 1249.663330,216.598694 
	C1248.746826,214.614319 1249.344727,213.080719 1250.833130,211.310822 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1264.355225,163.909424 
	C1263.642334,156.166412 1265.644165,148.319473 1262.356812,140.465958 
	C1260.899414,136.984283 1260.833618,132.081757 1265.685181,129.170013 
	C1273.779297,131.078003 1273.745361,131.085175 1271.646484,138.709763 
	C1270.713379,142.099503 1270.645874,145.377869 1272.096191,148.846603 
	C1274.035034,153.483643 1272.825439,158.384262 1271.639648,163.306427 
	C1271.123779,161.032761 1270.644043,158.750031 1270.076416,156.489349 
	C1269.799072,155.384460 1270.528809,153.372528 1268.425415,153.654297 
	C1266.865601,153.863251 1265.932007,155.446335 1265.751221,156.933929 
	C1265.536011,158.706482 1265.907715,160.543411 1265.830078,162.344040 
	C1265.808228,162.850998 1265.102539,163.328491 1264.355225,163.909424 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1264.944824,104.614746 
	C1259.607300,105.990891 1254.214722,106.981766 1248.411011,107.986603 
	C1248.292847,105.772217 1249.595581,104.011108 1251.751465,104.158882 
	C1256.630859,104.493340 1257.932007,101.484550 1258.849854,97.337479 
	C1265.045410,96.084259 1263.546021,101.314796 1264.944824,104.614746 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1271.417236,246.000885 
	C1271.174194,243.860428 1270.582275,241.663589 1271.735107,239.286896 
	C1274.481689,239.491638 1278.529053,237.503677 1278.740234,241.829971 
	C1278.962402,246.379166 1275.004883,245.988663 1271.417236,246.000885 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M1085.236816,1269.227783 
	C1084.146851,1268.854614 1083.562988,1268.458862 1083.066284,1267.661987 
	C1079.897217,1262.578491 1077.221802,1261.733398 1071.953857,1264.025879 
	C1065.566895,1266.805176 1065.539307,1266.804688 1065.620972,1259.575073 
	C1065.637451,1258.126831 1064.949585,1257.452026 1063.349609,1257.050781 
	C1061.073120,1255.088257 1061.150513,1252.979248 1062.692627,1250.378174 
	C1063.471680,1249.686768 1063.943237,1249.373413 1064.707520,1249.030029 
	C1065.794434,1248.158936 1066.830078,1247.495850 1067.349365,1246.454956 
	C1068.447144,1244.255249 1067.338989,1244.213135 1065.329712,1244.931030 
	C1061.932129,1244.563110 1061.429199,1242.184692 1061.008545,1239.368896 
	C1062.379761,1236.274780 1060.217773,1233.305420 1061.790039,1230.289551 
	C1068.294434,1230.876221 1068.000732,1230.945801 1066.472412,1224.498535 
	C1065.644531,1221.005737 1064.765259,1217.864136 1062.046753,1215.368774 
	C1061.417480,1214.791016 1060.818115,1213.075684 1061.122559,1212.754761 
	C1064.639404,1209.047852 1063.479614,1202.557373 1068.918945,1199.974731 
	C1071.344482,1198.822876 1074.345337,1198.061279 1074.988770,1194.362305 
	C1078.398926,1192.086060 1082.042114,1191.052856 1086.325439,1191.092529 
	C1088.503906,1193.083008 1090.659424,1193.732666 1093.277466,1193.824829 
	C1097.052612,1193.957520 1100.786011,1194.684082 1104.243652,1197.272949 
	C1106.457886,1200.174438 1105.674072,1202.107056 1103.527222,1204.468018 
	C1099.989502,1208.358643 1095.344360,1211.078125 1092.657715,1215.954834 
	C1090.723633,1219.465332 1085.834595,1218.698242 1081.884277,1214.911987 
	C1080.714966,1213.791382 1080.011719,1212.189697 1078.114014,1211.868774 
	C1073.399292,1211.071533 1072.725098,1214.846069 1071.455444,1217.771362 
	C1070.324829,1220.376343 1070.548706,1222.657959 1073.898560,1223.573120 
	C1076.598267,1224.310913 1077.870483,1226.260498 1078.419067,1228.954590 
	C1079.458862,1234.061279 1082.027466,1237.830811 1087.637939,1238.690308 
	C1088.791016,1238.866943 1090.420532,1239.139038 1090.758789,1240.035889 
	C1093.107666,1246.263916 1100.233154,1248.521606 1102.663940,1254.612915 
	C1103.166748,1255.872803 1104.141357,1256.804443 1105.194824,1258.118774 
	C1105.778198,1259.017700 1105.966309,1259.689087 1106.104370,1260.710327 
	C1106.266113,1261.937256 1105.926758,1263.046021 1107.507324,1263.480835 
	C1108.470093,1263.608398 1109.109863,1263.766846 1110.091187,1264.033081 
	C1111.979126,1264.926392 1113.681519,1265.407227 1115.308472,1266.807373 
	C1115.849365,1267.885132 1115.840820,1268.648315 1115.263672,1269.704834 
	C1111.399048,1270.204712 1107.668213,1269.297241 1104.279053,1271.311890 
	C1103.133301,1271.992920 1102.057373,1271.469849 1100.970947,1270.994751 
	C1097.275146,1269.378418 1093.543701,1269.708252 1089.405273,1270.938721 
	C1088.132935,1269.781250 1086.532959,1270.235596 1085.236816,1269.227783 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1087.352539,1192.055908 
	C1083.078979,1191.536499 1079.849731,1194.931641 1075.422363,1194.000000 
	C1075.338989,1190.949951 1074.002441,1189.048950 1070.922241,1189.099121 
	C1067.285889,1189.158447 1065.337891,1187.438965 1063.518066,1184.419312 
	C1060.499023,1179.409546 1059.569824,1179.888428 1056.350464,1184.717773 
	C1053.810547,1188.527832 1053.751099,1191.170410 1057.389282,1193.953857 
	C1058.869263,1195.086182 1059.417114,1196.903442 1060.064453,1198.631958 
	C1061.371582,1202.121704 1059.838257,1203.954468 1056.549805,1203.880859 
	C1053.161987,1203.804932 1050.975708,1205.170410 1050.199341,1208.159912 
	C1049.256226,1211.791992 1046.905151,1212.308960 1043.414429,1212.000000 
	C1042.196045,1208.193237 1040.039185,1207.400146 1036.319702,1208.271606 
	C1032.858276,1209.082520 1029.120972,1208.442261 1025.291016,1209.645996 
	C1024.706177,1209.590576 1024.476929,1209.529419 1023.960327,1209.258667 
	C1022.225037,1205.068237 1024.896606,1204.143066 1027.842041,1203.565063 
	C1030.328613,1203.076904 1032.979248,1203.670288 1035.388550,1202.473267 
	C1030.494629,1202.314941 1025.639526,1202.620483 1020.426514,1203.177490 
	C1016.893677,1203.152466 1014.106384,1202.026367 1012.426575,1199.360840 
	C1010.299622,1195.985718 1005.777954,1197.453125 1003.272339,1194.273438 
	C1002.672974,1191.512939 1003.540649,1189.187744 1003.987366,1186.401855 
	C1008.998047,1181.299194 1014.821533,1182.561523 1020.988953,1183.839966 
	C1023.148315,1184.918091 1023.582031,1186.762695 1024.294067,1188.418213 
	C1024.821777,1189.645264 1025.368774,1190.872192 1026.600830,1191.553589 
	C1027.798218,1192.215820 1029.230469,1192.766968 1030.236816,1191.573608 
	C1031.150757,1190.489746 1030.184448,1189.349365 1029.383789,1188.550537 
	C1027.264282,1186.435669 1025.180786,1184.318848 1024.004517,1181.088135 
	C1023.204041,1178.152710 1023.672485,1174.999268 1019.963745,1174.017822 
	C1018.312378,1173.580933 1017.054871,1172.213989 1017.554382,1170.241455 
	C1018.111938,1168.039795 1020.024170,1166.398315 1021.937134,1167.107544 
	C1029.809326,1170.026245 1032.555542,1164.832031 1035.271362,1159.299805 
	C1035.488892,1158.856689 1035.818359,1158.474854 1036.425415,1157.868408 
	C1041.627563,1157.797241 1044.310425,1160.453979 1043.625977,1164.928223 
	C1043.148926,1168.046875 1042.639038,1171.146851 1042.433960,1174.298828 
	C1042.268311,1176.843994 1040.750488,1178.444214 1038.673584,1179.806885 
	C1034.087769,1182.815552 1034.156982,1183.467407 1037.571533,1187.892090 
	C1039.800537,1190.780396 1042.886963,1192.853760 1044.836914,1195.617065 
	C1046.139282,1191.094238 1046.834229,1186.256104 1049.664429,1181.662354 
	C1052.294800,1177.392944 1054.810547,1173.452759 1058.907471,1170.546143 
	C1060.390625,1169.493896 1061.629028,1167.897461 1063.700684,1168.599609 
	C1065.789795,1169.307739 1065.971802,1171.244995 1066.197510,1173.084351 
	C1066.278687,1173.745850 1066.160645,1172.918945 1066.246094,1173.580078 
	C1067.494019,1183.239380 1073.978149,1188.572754 1083.957764,1188.148071 
	C1087.456299,1187.999268 1088.076050,1188.654663 1087.352539,1192.055908 
z"/>
<path fill="#D0CDCC" opacity="1.000000" stroke="none" 
	d="
M1003.000000,1194.416626 
	C1007.622070,1195.006836 1012.484009,1195.364258 1015.233948,1200.292603 
	C1015.782349,1201.275635 1017.888916,1201.389404 1019.638489,1201.952026 
	C1022.150635,1203.643555 1021.293640,1206.909180 1023.756836,1208.757690 
	C1024.000000,1209.000000 1024.500000,1209.000000 1024.750000,1209.000000 
	C1026.720337,1210.580078 1026.598389,1212.402954 1025.351196,1214.664062 
	C1022.543640,1217.821899 1017.558533,1217.069214 1016.048767,1221.649048 
	C1013.080200,1222.738037 1014.338318,1225.156006 1013.987488,1226.944458 
	C1013.645813,1228.686279 1015.087036,1231.575317 1011.990234,1231.688843 
	C1009.166870,1231.792358 1010.507080,1228.984253 1009.940247,1227.463501 
	C1008.579163,1223.812012 1006.454346,1220.422974 1006.302002,1216.373779 
	C1006.208313,1213.883667 1006.101135,1211.394165 1006.000000,1208.452148 
	C1007.440186,1205.819092 1009.478577,1203.895508 1009.051941,1200.646240 
	C1009.067566,1199.632690 1008.657166,1199.774292 1007.911011,1200.091797 
	C1006.159668,1200.493774 1004.775940,1200.430420 1003.275452,1199.238892 
	C1003.000000,1197.611084 1003.000000,1196.222168 1003.000000,1194.416626 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M1025.266235,1215.263672 
	C1025.000000,1213.285645 1025.000000,1211.571289 1025.000000,1209.428467 
	C1026.366089,1207.307007 1028.149902,1206.909790 1030.268188,1206.954468 
	C1033.051147,1207.013306 1035.812012,1207.056030 1038.618896,1206.371216 
	C1044.145386,1205.023071 1044.820557,1205.908936 1043.009033,1211.628906 
	C1040.022705,1213.975098 1040.114746,1217.312500 1039.614746,1220.220947 
	C1038.159302,1220.513428 1038.144531,1219.416260 1037.661133,1218.845825 
	C1036.218262,1217.143311 1034.316162,1216.715088 1032.252319,1217.207520 
	C1029.879517,1217.773315 1030.103027,1219.789917 1029.976074,1221.619141 
	C1029.891724,1222.831665 1030.361694,1224.675537 1028.329712,1224.555298 
	C1026.767212,1224.463013 1027.006470,1222.851074 1027.033936,1221.755615 
	C1027.089722,1219.526245 1026.437988,1217.505127 1025.266235,1215.263672 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M1062.566162,1250.000000 
	C1063.000000,1252.041626 1063.000000,1254.083374 1063.000000,1256.562500 
	C1061.332764,1258.590698 1059.286133,1258.054199 1057.395630,1257.830444 
	C1055.183960,1257.568359 1053.941528,1256.299927 1053.992554,1253.839722 
	C1054.039429,1251.581787 1055.287964,1250.511963 1057.229736,1250.197388 
	C1058.832397,1249.937866 1060.495850,1250.051636 1062.566162,1250.000000 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M1055.677612,1240.305908 
	C1052.881226,1244.250366 1051.170776,1242.590820 1050.189209,1239.476562 
	C1049.719604,1237.986206 1049.976318,1236.215454 1050.133423,1234.593750 
	C1050.285767,1233.021606 1051.204956,1231.607544 1052.814087,1231.507935 
	C1054.850098,1231.381836 1054.827881,1233.198486 1055.021606,1234.667603 
	C1055.254639,1236.434448 1055.550049,1238.193115 1055.677612,1240.305908 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M1073.057129,1268.903198 
	C1073.040894,1265.992310 1074.378174,1264.943848 1076.792114,1265.048462 
	C1078.753174,1265.133423 1079.831909,1266.109009 1079.943237,1268.096802 
	C1080.031982,1269.679810 1079.778442,1271.251343 1078.062500,1271.705688 
	C1075.859863,1272.288818 1073.704468,1272.244995 1073.057129,1268.903198 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1065.000000,1245.399902 
	C1064.895996,1242.635254 1066.103516,1241.600098 1068.319580,1242.167236 
	C1070.554443,1242.739136 1070.065674,1244.627930 1069.719482,1246.187988 
	C1069.269897,1248.212769 1067.902588,1249.183228 1065.394531,1248.997314 
	C1065.000000,1247.933350 1065.000000,1246.866577 1065.000000,1245.399902 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M1058.252686,1226.341187 
	C1056.350708,1227.110474 1054.845703,1227.026245 1054.452026,1225.152710 
	C1054.171631,1223.817871 1054.969849,1222.549194 1056.425537,1222.195801 
	C1057.871216,1221.844971 1059.519775,1221.728027 1060.334106,1223.296997 
	C1061.194214,1224.954712 1059.685791,1225.529541 1058.252686,1226.341187 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M1061.701660,1230.166504 
	C1061.639038,1232.927856 1064.836914,1235.966309 1061.363281,1238.904419 
	C1059.384033,1236.029541 1058.264404,1233.082275 1061.701660,1230.166504 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M1115.032715,1270.317383 
	C1115.000000,1269.250000 1115.000000,1268.500000 1115.000000,1267.375000 
	C1115.142456,1266.833252 1115.284790,1266.666504 1115.725098,1266.357422 
	C1116.459351,1266.122314 1116.898071,1266.114136 1117.652832,1266.287842 
	C1118.343018,1266.644409 1118.644653,1266.961304 1118.935791,1267.674561 
	C1118.997559,1269.479614 1119.098877,1270.976074 1118.149780,1272.663330 
	C1117.555542,1273.000000 1117.111084,1273.000000 1116.333252,1273.000000 
	C1115.688599,1272.211548 1115.377075,1271.423218 1115.032715,1270.317383 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M1085.082031,1269.348877 
	C1086.371948,1269.159302 1088.014282,1268.522095 1088.917969,1270.651123 
	C1087.628174,1270.840576 1085.985718,1271.478027 1085.082031,1269.348877 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1258.843750,796.286255 
	C1260.820312,795.590820 1262.438965,793.286865 1264.543823,795.858582 
	C1265.771118,797.358276 1268.049316,797.755127 1269.373901,796.209167 
	C1270.684448,794.679626 1271.771484,792.523315 1269.869263,790.416504 
	C1266.537964,786.726807 1269.203613,785.322388 1272.648438,784.043457 
	C1274.371948,784.159790 1276.016968,783.520630 1276.918335,785.653320 
	C1275.162964,787.708008 1272.842407,789.373047 1276.767822,791.769897 
	C1278.577026,797.019043 1277.337769,799.695190 1272.696655,801.102356 
	C1271.570068,801.443970 1271.151855,802.163147 1271.021240,803.606323 
	C1265.786499,803.206055 1260.572998,802.412109 1255.179688,801.309082 
	C1255.570068,798.975281 1257.373901,797.978149 1258.843750,796.286255 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1236.634033,803.097290 
	C1237.042969,804.864075 1237.951294,807.739563 1239.528442,806.613953 
	C1244.625366,802.976135 1245.685669,808.152954 1248.662109,809.934265 
	C1249.000000,811.066711 1249.000000,812.133362 1249.000000,813.600037 
	C1246.687378,814.108215 1245.222046,813.254578 1244.929688,810.740723 
	C1244.697998,808.750000 1243.383057,807.840393 1241.435791,808.174133 
	C1239.366821,808.528687 1238.931885,810.028687 1239.170532,811.894409 
	C1239.353516,813.327148 1241.015625,815.501343 1238.190308,815.749878 
	C1235.607666,815.976990 1234.154663,813.397888 1234.306274,811.503906 
	C1234.527588,808.738892 1234.148071,805.596008 1236.634033,803.097290 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1246.359375,849.051758 
	C1246.119263,846.156006 1245.863159,843.056274 1250.089355,843.019409 
	C1253.566772,842.989014 1255.413574,844.507751 1254.999634,848.589661 
	C1252.278687,852.160583 1249.500000,850.737671 1246.359375,849.051758 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1256.257690,832.731934 
	C1255.457642,832.643127 1254.809814,832.380310 1254.392334,831.912048 
	C1253.028564,830.382080 1249.381348,831.866150 1249.550903,828.850708 
	C1249.686401,826.441101 1253.132202,827.067139 1254.693848,825.163086 
	C1256.835205,825.025757 1258.290771,825.346497 1257.587280,827.790466 
	C1257.145996,829.324158 1256.867188,830.904541 1256.257690,832.731934 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1242.262329,858.720459 
	C1240.827393,858.477173 1239.677002,857.894226 1238.475830,857.448120 
	C1237.247681,856.992004 1234.788452,858.629883 1235.151978,855.554382 
	C1235.433716,853.171204 1237.317261,853.015076 1239.198730,853.006836 
	C1240.177734,853.002502 1241.156616,853.002197 1242.567871,853.000000 
	C1242.856323,854.814941 1243.366455,856.687012 1242.262329,858.720459 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1253.999512,819.585327 
	C1251.996582,820.946167 1249.807007,821.854553 1247.981567,820.082886 
	C1246.286499,818.437805 1249.489990,817.392761 1248.988770,815.356934 
	C1252.052734,814.728455 1254.599365,815.063538 1253.999512,819.585327 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1273.857788,817.317871 
	C1275.066650,816.998657 1276.133423,816.999756 1277.600098,817.001648 
	C1278.028442,817.492371 1278.021973,817.987366 1278.091431,818.471436 
	C1278.379028,820.477173 1279.949097,822.477722 1278.172974,824.468506 
	C1277.962769,824.704224 1276.879150,824.559998 1276.875610,824.524048 
	C1276.607910,821.859802 1271.765503,821.278931 1273.857788,817.317871 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1271.000000,807.248169 
	C1274.144653,806.758301 1275.359985,808.779907 1275.828857,811.373352 
	C1276.253784,813.723206 1274.564575,814.004333 1272.391113,813.999023 
	C1272.000000,813.835388 1272.000000,813.668274 1272.000000,813.250610 
	C1271.097656,811.666870 1270.590454,810.214905 1270.836182,808.292847 
	C1271.000000,807.832947 1271.000000,807.665833 1271.000000,807.248169 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1270.638794,808.007812 
	C1271.585327,809.427917 1273.564941,810.462524 1272.124390,812.713379 
	C1270.575439,812.698181 1269.150879,812.396362 1267.363159,812.046021 
	C1266.871948,811.831299 1266.743774,811.665039 1266.807861,811.249390 
	C1267.167358,808.989136 1268.302002,808.040588 1270.638794,808.007812 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1263.406738,833.785889 
	C1261.879150,830.680725 1264.622559,829.739563 1266.252563,828.748718 
	C1267.856934,827.773376 1268.039307,829.581543 1267.802856,830.710449 
	C1267.359741,832.826904 1265.504883,833.315369 1263.406738,833.785889 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1264.281494,878.435730 
	C1262.708252,876.818909 1262.882446,875.294250 1264.013550,873.645630 
	C1263.694946,875.412415 1268.768555,876.156799 1264.281494,878.435730 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1259.318726,849.141602 
	C1259.000000,848.250000 1259.000000,847.500000 1259.000000,846.375000 
	C1260.812744,846.081177 1262.963623,846.127319 1262.672119,848.624084 
	C1262.442139,850.593933 1260.819824,849.742493 1259.318726,849.141602 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1256.250854,860.977417 
	C1254.156250,862.153076 1253.780273,860.688965 1253.498535,859.480774 
	C1253.266479,858.485229 1254.096802,858.082947 1254.975708,858.128906 
	C1256.747437,858.221558 1257.134399,859.192200 1256.250854,860.977417 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1274.763184,859.817383 
	C1274.251465,861.014221 1273.680298,862.052490 1272.592896,861.179565 
	C1271.757935,860.509155 1271.851440,859.320251 1272.681152,858.636414 
	C1273.668457,857.822815 1274.436279,858.319275 1274.763184,859.817383 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1257.998535,838.628662 
	C1255.984009,838.699036 1254.653198,837.945190 1255.872803,835.294739 
	C1258.019409,835.255798 1257.994873,836.764648 1257.998535,838.628662 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1266.667725,811.008423 
	C1267.000000,811.000000 1267.000000,811.498779 1267.000000,811.748169 
	C1266.848755,812.813904 1266.638794,813.737244 1265.586182,813.488525 
	C1265.129883,813.380737 1264.522705,812.600586 1264.524536,812.127869 
	C1264.528687,811.043091 1265.560913,811.133789 1266.667725,811.008423 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1259.233643,842.788513 
	C1257.318359,842.829834 1257.010742,841.962952 1257.881104,840.279541 
	C1259.550049,840.254883 1260.337402,840.944092 1259.233643,842.788513 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1255.413330,810.658813 
	C1255.611694,811.372986 1255.362915,811.633728 1254.807129,811.521729 
	C1254.691895,811.498474 1254.537231,811.334717 1254.520020,811.218079 
	C1254.437134,810.657227 1254.710327,810.422729 1255.413330,810.658813 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1269.341309,824.413208 
	C1268.626587,824.612000 1268.366821,824.362488 1268.478760,823.807190 
	C1268.502075,823.691833 1268.665649,823.536865 1268.782104,823.519714 
	C1269.343018,823.437073 1269.577393,823.710388 1269.341309,824.413208 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1271.658691,825.586792 
	C1272.373169,825.388428 1272.633667,825.637390 1272.521606,826.192993 
	C1272.498291,826.308228 1272.334473,826.462830 1272.217896,826.480103 
	C1271.657104,826.562988 1271.422485,826.289978 1271.658691,825.586792 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M25.827267,299.296143 
	C25.674280,296.071564 26.496019,293.432129 28.041609,290.980316 
	C28.571787,290.139313 29.205854,289.045471 30.306549,289.743896 
	C31.250450,290.342834 31.135059,291.640503 30.423454,292.393677 
	C28.826094,294.084351 28.781536,295.669006 30.621273,297.008728 
	C35.833694,300.804352 31.825663,304.013763 29.833420,307.036407 
	C27.830137,310.075806 24.947130,309.655853 22.097765,307.367126 
	C20.704845,303.289703 25.626043,302.647583 25.827267,299.296143 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M19.381512,302.272919 
	C17.694931,297.999512 17.408384,293.906555 17.950916,289.373077 
	C18.672344,288.357361 19.456863,288.238617 20.663082,288.754578 
	C22.991665,290.189606 26.105888,291.179840 21.625980,293.346100 
	C20.019213,294.123077 19.873072,295.677582 20.120741,297.227631 
	C20.406887,299.018494 21.867294,298.939728 23.603127,298.996704 
	C24.948278,303.003143 22.669146,303.148895 19.381512,302.272919 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M19.000452,302.403503 
	C20.951998,301.933319 23.132452,302.355988 23.945454,299.361511 
	C24.445576,299.000244 24.889187,299.000519 25.665489,299.001007 
	C26.838251,300.626434 29.327667,302.397766 25.197714,303.340942 
	C24.065392,303.599487 23.270746,305.336700 22.160072,306.697693 
	C18.774050,307.192871 18.962162,304.945892 19.000452,302.403503 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M14.788486,282.905029 
	C14.874475,284.072235 15.108700,284.998077 14.117662,285.399384 
	C13.366919,285.703369 12.476303,285.075684 12.605233,284.621399 
	C13.349292,281.999847 10.989220,279.272888 13.206354,275.847443 
	C13.820050,278.589569 14.262203,280.565216 14.788486,282.905029 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M34.990875,319.627747 
	C34.250031,320.000000 33.500214,320.000000 32.375504,320.000000 
	C31.960093,319.019806 31.963276,318.051483 31.200733,317.253418 
	C30.422066,316.438507 28.398760,315.719666 30.216652,314.359833 
	C31.438084,313.446167 32.609436,314.557983 33.434772,315.735687 
	C34.193535,316.818451 35.033463,317.830841 34.990875,319.627747 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M21.133949,288.691803 
	C20.251600,289.001495 19.500982,289.001801 18.375088,289.002258 
	C17.539703,287.581512 15.949027,285.696289 18.703270,285.193817 
	C20.770674,284.816681 22.293402,285.805023 21.133949,288.691803 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M12.971325,307.706238 
	C12.527326,308.561798 11.872216,308.934570 11.437876,308.182220 
	C11.188121,307.749542 11.495637,306.995178 11.776578,306.192200 
	C12.314197,306.470428 12.627533,306.942078 12.971325,307.706238 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M15.322132,309.937378 
	C14.999980,309.250000 14.999617,308.500000 14.999340,307.375000 
	C16.989513,307.560242 17.753862,308.395294 15.322132,309.937378 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1214.171997,1270.361328 
	C1215.577393,1268.787354 1216.268677,1269.610962 1216.529419,1270.893921 
	C1216.615601,1271.317505 1216.057251,1272.202271 1215.622559,1272.341309 
	C1214.157837,1272.809448 1214.177979,1271.636108 1214.171997,1270.361328 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1257.744263,966.113159 
	C1259.830444,967.721252 1262.101074,966.728027 1264.583252,967.000000 
	C1266.782349,968.445984 1268.660522,968.743713 1270.988525,968.265381 
	C1273.508911,967.747559 1275.702881,967.688110 1274.279053,964.108704 
	C1273.647827,962.521973 1274.239380,961.005615 1276.353394,961.151428 
	C1278.122803,961.273315 1279.197266,962.434753 1278.687744,964.207031 
	C1277.648682,967.820801 1278.534912,972.128906 1273.922974,974.474548 
	C1272.232910,975.334167 1270.753296,978.508118 1273.947876,980.629822 
	C1275.202637,981.463196 1276.324707,982.652893 1275.603760,984.383179 
	C1275.002808,985.825684 1273.607056,986.047668 1272.238647,985.977600 
	C1269.973633,985.861450 1268.472656,986.757507 1267.560303,988.879517 
	C1266.838013,990.558899 1266.897217,993.170166 1263.938110,992.697327 
	C1262.860229,992.525146 1262.152100,991.632751 1262.171997,990.570618 
	C1262.215698,988.237244 1262.442749,984.889282 1264.644653,984.599731 
	C1269.767944,983.925903 1268.298828,981.472839 1267.154907,978.638367 
	C1266.922852,978.063477 1267.022217,977.321838 1267.082031,976.667358 
	C1267.666504,970.283630 1267.673340,970.284241 1260.303223,970.868652 
	C1259.009644,971.825378 1257.931396,972.083984 1256.362061,971.253540 
	C1256.000000,970.555847 1256.000000,970.111633 1256.000000,969.334351 
	C1255.621094,968.613708 1255.472168,968.151978 1255.776489,967.306763 
	C1256.496216,966.740479 1256.992310,966.483398 1257.744263,966.113159 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1270.470581,991.341492 
	C1273.823975,989.332520 1276.169434,990.912781 1277.398071,993.370911 
	C1278.924805,996.425598 1277.534790,999.512634 1275.573120,1002.053345 
	C1274.814331,1003.036255 1273.367554,1003.493408 1272.223145,1004.171387 
	C1271.645508,1004.513611 1270.971802,1004.872131 1270.487793,1004.145996 
	C1270.251831,1003.791992 1270.112793,1002.967163 1270.314209,1002.788025 
	C1274.131958,999.394531 1272.507446,996.559387 1269.582520,993.689636 
	C1268.905518,993.025330 1269.552979,992.143738 1270.470581,991.341492 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1255.998291,971.371338 
	C1257.066650,971.000000 1258.133423,971.000000 1259.600098,971.000000 
	C1258.946899,973.410889 1259.281250,975.825500 1259.990234,978.620544 
	C1256.816284,981.443542 1253.144531,980.432434 1249.310181,980.233032 
	C1248.235229,978.299194 1245.431396,976.454163 1249.673828,975.042236 
	C1250.471558,975.313293 1250.943115,975.626648 1251.707275,975.969971 
	C1252.597168,975.983459 1253.134155,975.802368 1253.798340,975.208557 
	C1256.018921,974.743774 1255.994385,973.235107 1255.998291,971.371338 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1249.000000,980.333313 
	C1252.270386,978.373474 1255.756470,978.945068 1259.595947,979.000000 
	C1262.437744,983.134888 1259.471436,982.949341 1256.400757,983.001587 
	C1253.684570,985.479736 1251.466431,985.895569 1249.209473,982.345947 
	C1249.000000,981.555542 1249.000000,981.111084 1249.000000,980.333313 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1248.868408,982.304810 
	C1251.019531,982.818237 1252.846313,984.746765 1255.647461,983.047607 
	C1256.000000,983.751831 1256.000000,984.501160 1256.000000,985.625244 
	C1253.278809,985.512878 1248.344604,989.766418 1248.868408,982.304810 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1261.362549,998.991760 
	C1260.421387,997.889832 1259.910156,996.774841 1260.877441,995.281616 
	C1262.481567,995.086304 1264.323975,994.894226 1264.520508,996.893860 
	C1264.694214,998.658813 1263.096558,998.854248 1261.362549,998.991760 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1265.413330,906.858093 
	C1262.110229,907.291382 1259.485352,906.811707 1257.289795,903.826172 
	C1259.799683,903.137573 1261.301392,899.496277 1264.469849,902.122375 
	C1265.779663,903.207825 1268.126221,904.458374 1265.413330,906.858093 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1271.866089,905.224121 
	C1273.999146,908.363342 1273.999146,908.363342 1268.480591,909.542725 
	C1268.605225,907.320435 1269.746948,906.050964 1271.866089,905.224121 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1267.988770,899.617554 
	C1266.813232,899.105652 1265.861450,898.531067 1266.892090,897.465637 
	C1267.480713,896.857178 1268.495361,896.861145 1269.080566,897.467346 
	C1270.099487,898.522949 1269.181641,899.113281 1267.988770,899.617554 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1276.201416,896.466553 
	C1276.649536,898.187622 1275.927734,898.797058 1274.501831,898.486572 
	C1273.844360,898.343384 1273.349731,897.731262 1273.564331,897.036194 
	C1273.963013,895.744812 1275.027222,896.108154 1276.201416,896.466553 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M351.996887,1263.389771 
	C352.442566,1263.000000 352.887543,1263.000000 353.666260,1263.000000 
	C356.466248,1262.987427 359.206787,1262.583984 360.468262,1265.396606 
	C361.355591,1267.375000 361.839996,1270.144165 359.712830,1271.211060 
	C357.426697,1272.357788 354.645325,1274.055054 351.828949,1272.147217 
	C349.430267,1270.522339 350.096863,1268.661621 351.468842,1266.637207 
	C351.966461,1265.902832 351.838623,1264.744385 351.996887,1263.389771 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M738.567993,1259.014160 
	C738.995117,1259.014648 739.474792,1259.024414 739.714111,1259.026855 
	C740.349365,1259.928345 740.657837,1260.879517 741.156982,1261.716919 
	C742.895386,1264.633545 741.559998,1267.034424 739.481934,1268.773193 
	C736.970825,1270.874146 733.812622,1270.487427 731.031555,1269.195923 
	C728.935120,1268.222168 728.618103,1266.030396 729.228760,1263.986694 
	C730.085083,1261.121460 731.598694,1258.802856 735.147339,1258.999756 
	C736.142334,1259.054932 737.142822,1259.011719 738.567993,1259.014160 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M8.746212,457.259094 
	C10.478816,458.578857 10.319314,460.893097 11.872038,462.609497 
	C14.154851,465.132935 12.255784,465.957397 9.380562,465.997925 
	C8.831063,463.172729 8.661744,460.345490 8.746212,457.259094 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M8.751758,469.196045 
	C10.169995,469.219788 11.955379,468.386139 12.344178,469.954987 
	C12.803895,471.809967 10.659966,471.896759 9.306782,472.859253 
	C5.853641,472.207581 7.893148,470.701477 8.751758,469.196045 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M7.007422,447.322510 
	C7.546141,447.180573 8.091765,447.361115 8.819205,447.771423 
	C8.689306,448.488525 8.385207,449.383118 8.064494,449.389099 
	C6.968360,449.409607 7.142181,448.379852 7.007422,447.322510 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1277.000000,791.569458 
	C1275.196289,791.602478 1272.273315,793.146729 1272.179810,789.705261 
	C1272.114868,787.314026 1273.551636,785.803711 1276.607422,786.004150 
	C1277.000000,787.715393 1277.000000,789.428406 1277.000000,791.569458 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1087.010498,1192.380737 
	C1088.427124,1188.854980 1085.871094,1188.604736 1084.481079,1189.497314 
	C1081.145874,1191.639038 1078.458130,1190.161011 1075.743408,1188.724854 
	C1072.912598,1187.227295 1070.040283,1185.658691 1067.353271,1184.088013 
	C1063.728516,1181.969116 1065.441284,1176.767212 1064.397827,1172.999023 
	C1064.086304,1171.874146 1065.309814,1170.219604 1063.560791,1169.559814 
	C1061.742920,1168.874268 1060.817383,1170.545532 1060.006714,1171.647827 
	C1058.693848,1173.432861 1057.500488,1175.135132 1055.017334,1175.269165 
	C1054.596436,1175.291870 1053.919556,1175.704956 1053.833984,1176.053833 
	C1051.744995,1184.567871 1043.806763,1191.333130 1046.660767,1201.184570 
	C1045.987549,1201.184570 1045.441772,1201.235229 1045.430054,1201.177368 
	C1044.212524,1195.187378 1038.888428,1192.284668 1035.408325,1188.036743 
	C1031.734253,1183.552246 1031.971680,1181.102661 1037.469727,1178.754150 
	C1040.203125,1177.586670 1041.187500,1175.831909 1041.049194,1172.972656 
	C1040.954468,1171.012573 1040.829346,1168.803345 1041.595215,1167.100098 
	C1043.692383,1162.435913 1041.590820,1159.961304 1037.344482,1158.045532 
	C1036.101685,1157.847412 1035.414551,1157.304688 1034.476562,1156.548096 
	C1033.293213,1155.365356 1032.486450,1154.310791 1031.806396,1152.767334 
	C1031.568970,1151.108887 1030.558716,1149.997192 1030.837891,1148.295410 
	C1032.671021,1146.332764 1033.902710,1147.258301 1034.842163,1148.783691 
	C1035.661621,1150.114380 1036.630859,1151.011108 1038.631348,1150.991211 
	C1041.256226,1150.951172 1042.346802,1149.870850 1042.155151,1147.549072 
	C1042.031250,1146.048096 1041.658813,1144.595703 1041.398560,1143.123047 
	C1041.045898,1141.127075 1041.229980,1139.229004 1043.405151,1138.636963 
	C1045.659302,1138.023315 1046.848022,1139.718140 1047.252930,1141.610840 
	C1047.894775,1144.609741 1049.667114,1146.610962 1052.070435,1148.297241 
	C1054.643433,1150.102539 1056.041504,1149.085327 1056.849609,1146.486450 
	C1057.304932,1145.022339 1057.029785,1143.511841 1056.951172,1142.019043 
	C1056.785767,1138.876709 1057.789429,1136.461304 1061.031250,1135.413208 
	C1063.953735,1134.468140 1065.926758,1134.727417 1067.201416,1138.228027 
	C1068.109863,1140.723145 1072.886597,1139.861572 1074.480225,1137.140137 
	C1078.111328,1130.939209 1083.219727,1130.876221 1086.650513,1137.075562 
	C1087.404907,1138.438965 1088.488281,1139.411011 1089.742920,1140.755127 
	C1090.982300,1146.862305 1096.395630,1146.850098 1100.222412,1147.594238 
	C1105.174438,1148.557129 1108.823364,1151.875610 1113.452881,1153.130737 
	C1114.665649,1153.459717 1115.075073,1154.427979 1114.645142,1155.453735 
	C1111.354980,1163.302490 1117.456421,1166.146973 1122.359985,1168.991577 
	C1126.690796,1171.503784 1129.427368,1174.328125 1129.036743,1179.526123 
	C1128.960327,1180.543457 1129.173584,1182.047363 1130.040405,1182.163696 
	C1134.858276,1182.810181 1136.386719,1188.390015 1141.022827,1189.270752 
	C1141.883301,1189.434082 1142.966309,1190.254517 1143.306641,1191.046509 
	C1146.611816,1198.741333 1152.987183,1200.732544 1160.633057,1201.155884 
	C1164.253052,1201.356079 1164.510986,1204.372925 1163.330200,1207.172363 
	C1161.718506,1210.993652 1159.916748,1214.743652 1158.010010,1218.427734 
	C1157.074829,1220.234619 1156.743774,1221.162231 1159.172363,1222.085693 
	C1165.224243,1224.386963 1167.186035,1228.733643 1164.021240,1234.595581 
	C1160.479126,1241.156494 1160.044556,1248.949951 1155.384766,1254.974487 
	C1154.323730,1256.346069 1153.665649,1257.781006 1156.315918,1257.962280 
	C1160.339966,1258.237427 1161.196655,1260.788818 1161.000000,1264.592407 
	C1158.896729,1262.350098 1157.142944,1258.981689 1152.385254,1260.998779 
	C1151.336426,1260.552979 1150.986572,1259.895874 1150.702148,1258.798218 
	C1150.674805,1257.776733 1150.578735,1258.625244 1150.620483,1257.959839 
	C1150.970093,1252.382446 1150.970093,1252.382446 1144.924316,1252.395996 
	C1143.813110,1252.281372 1143.144287,1251.997559 1142.286743,1251.246094 
	C1141.832886,1250.802368 1141.665894,1250.604614 1141.277222,1250.086182 
	C1140.738647,1249.032349 1140.670776,1248.260254 1140.742065,1247.087646 
	C1140.982910,1245.944092 1141.348633,1245.286133 1142.243530,1244.566650 
	C1144.369873,1243.576782 1146.008789,1245.321289 1148.061523,1244.722656 
	C1144.254272,1240.978149 1137.640137,1241.579102 1135.442505,1235.881104 
	C1136.086060,1237.436401 1135.930908,1238.772339 1134.642578,1240.238281 
	C1133.775635,1240.837891 1133.107910,1241.001587 1132.070312,1241.070801 
	C1130.851807,1241.046143 1129.974487,1240.897339 1128.759277,1241.065186 
	C1127.748291,1241.163696 1127.071533,1241.133545 1126.056396,1241.000000 
	C1121.227661,1238.469849 1116.204346,1240.898315 1111.570801,1239.406616 
	C1110.289673,1238.994263 1109.962524,1240.250000 1110.192627,1241.342407 
	C1110.540527,1242.993530 1111.162354,1244.638428 1112.524048,1245.632080 
	C1115.614502,1247.887207 1118.013428,1250.893066 1121.313965,1253.446411 
	C1121.732178,1253.896973 1121.876709,1254.101562 1122.173584,1254.654663 
	C1124.187012,1261.374634 1124.010864,1261.532349 1117.186279,1263.153442 
	C1115.039185,1263.663574 1113.324829,1265.747437 1110.392578,1265.003784 
	C1109.250000,1265.000000 1108.500000,1265.000000 1107.375000,1265.000000 
	C1106.300659,1264.254761 1105.479126,1263.606934 1105.048828,1262.304443 
	C1105.000000,1261.250000 1105.000000,1260.500000 1105.000000,1259.375000 
	C1103.448853,1256.977539 1102.609619,1254.746460 1102.353638,1252.158081 
	C1102.145996,1250.057251 1102.406982,1247.419189 1098.356201,1248.647583 
	C1095.982666,1249.367065 1094.885010,1246.668701 1094.765015,1244.285767 
	C1094.705566,1243.106445 1094.987793,1241.964355 1095.370117,1240.862305 
	C1096.515625,1237.560059 1096.532471,1235.425415 1091.860107,1235.953735 
	C1088.591797,1236.323242 1085.201294,1235.385376 1082.029663,1234.666870 
	C1076.405151,1233.392700 1078.178101,1228.022339 1077.554688,1224.247314 
	C1077.026978,1221.051758 1077.783081,1217.834961 1081.472534,1216.477173 
	C1083.783203,1215.626587 1085.687500,1216.959229 1087.578979,1217.898560 
	C1089.607788,1218.906250 1090.948364,1218.760864 1092.801758,1217.083374 
	C1098.067627,1212.317627 1102.655640,1213.754150 1105.077515,1220.444458 
	C1106.099487,1223.267334 1107.520142,1225.943115 1106.193848,1229.070312 
	C1105.785156,1230.033691 1105.952393,1230.931274 1107.041748,1231.613770 
	C1110.373657,1233.701294 1118.856079,1230.804688 1120.438599,1227.098267 
	C1121.241089,1225.218872 1120.704712,1223.997192 1118.892944,1223.788940 
	C1113.990234,1223.225342 1114.038452,1217.912476 1110.854492,1215.610596 
	C1109.540405,1214.660645 1111.099487,1212.901367 1112.646729,1212.323608 
	C1114.586182,1211.599365 1117.735596,1212.276733 1118.114624,1209.723145 
	C1118.665161,1206.014771 1119.876587,1202.234497 1118.897095,1198.413696 
	C1118.486328,1196.810669 1117.751709,1195.286255 1117.481812,1193.636475 
	C1117.156128,1191.645996 1116.042725,1190.683350 1114.025635,1190.983032 
	C1111.058350,1191.424194 1108.990723,1190.182617 1107.144287,1187.920532 
	C1105.203247,1185.542236 1102.790283,1185.348389 1099.818726,1187.368408 
	C1102.628174,1189.618042 1107.761108,1187.864990 1108.025024,1192.812988 
	C1108.157959,1195.307251 1107.369751,1197.425659 1104.329224,1198.127441 
	C1099.287964,1196.699951 1094.820435,1194.272461 1089.576050,1195.722778 
	C1087.858032,1196.197876 1087.108154,1194.434326 1087.010498,1192.380737 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M1141.987305,1251.361816 
	C1142.750000,1251.000000 1143.500000,1251.000000 1144.625000,1251.000000 
	C1147.768555,1252.577637 1150.491943,1254.200439 1151.765137,1257.756470 
	C1152.000000,1258.750000 1152.000000,1259.500000 1152.000000,1260.625000 
	C1152.019043,1264.507446 1150.177368,1266.069092 1146.386963,1265.998779 
	C1146.000000,1266.000000 1146.000000,1265.500000 1146.000000,1265.250000 
	C1142.853394,1263.107910 1142.995117,1258.250610 1138.607544,1256.928467 
	C1137.591675,1259.472656 1139.279907,1262.342407 1137.276611,1264.785156 
	C1132.835693,1262.760986 1133.828491,1258.411377 1133.505005,1254.991577 
	C1133.276245,1252.574219 1136.395752,1253.074341 1138.316284,1253.074097 
	C1139.712280,1253.073975 1141.714355,1254.782837 1141.987305,1251.361816 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M1141.787598,1249.731934 
	C1142.000000,1250.000000 1142.000000,1250.500000 1142.000000,1250.750000 
	C1138.746094,1251.049927 1137.054932,1248.062012 1134.281372,1247.049316 
	C1130.183960,1245.553467 1124.016968,1246.842163 1121.055420,1250.636963 
	C1120.246460,1251.590576 1119.298584,1251.620972 1118.853638,1250.887817 
	C1117.010742,1247.851807 1118.280518,1244.899780 1119.481445,1242.069946 
	C1120.568848,1239.507812 1122.675903,1239.069580 1125.582153,1239.973633 
	C1126.750000,1240.000000 1127.500000,1240.000000 1128.625000,1240.000000 
	C1129.444458,1240.000488 1129.888794,1240.000488 1130.666626,1240.000244 
	C1131.750000,1240.000000 1132.500000,1240.000000 1133.625000,1240.000000 
	C1140.099976,1238.979126 1142.677979,1240.135742 1143.244751,1244.628906 
	C1142.686646,1245.471558 1142.373413,1245.943115 1142.030029,1246.707397 
	C1141.152466,1247.699585 1141.194214,1248.552612 1141.787598,1249.731934 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1125.739258,1239.735229 
	C1120.485840,1240.940430 1118.155396,1245.377075 1120.653076,1250.920166 
	C1121.000000,1251.000000 1121.000000,1251.500000 1121.000000,1251.750000 
	C1121.378906,1252.383545 1121.516113,1252.839600 1121.205811,1253.684326 
	C1116.179443,1250.753174 1110.881470,1248.008545 1108.591797,1242.113892 
	C1108.036987,1240.685425 1107.534546,1239.132690 1108.543701,1237.860840 
	C1109.719360,1236.378906 1111.396851,1237.026978 1112.583374,1237.869629 
	C1115.188721,1239.719727 1117.738525,1237.705811 1120.261597,1238.059570 
	C1122.031738,1238.307617 1123.741699,1238.984863 1125.739258,1239.735229 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1137.000000,1265.444092 
	C1136.932129,1263.011230 1136.850830,1261.022705 1136.801025,1259.033447 
	C1136.768799,1257.740845 1134.111938,1256.452026 1136.346558,1255.321045 
	C1138.108032,1254.429565 1140.211914,1254.837646 1141.885254,1256.386475 
	C1144.212891,1258.540894 1144.603149,1261.628418 1145.952148,1264.637451 
	C1145.057373,1264.738770 1144.122314,1264.444336 1143.170166,1264.224365 
	C1141.826904,1263.914429 1140.515991,1263.962036 1139.627808,1265.198975 
	C1138.677734,1266.522339 1139.472656,1267.593140 1140.168823,1268.764404 
	C1141.355103,1270.760132 1143.667480,1272.487427 1142.041748,1275.655518 
	C1139.480957,1276.680908 1139.249146,1273.900024 1137.339355,1273.072510 
	C1137.000000,1270.629395 1137.000000,1268.258911 1137.000000,1265.444092 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1110.219727,1265.265625 
	C1113.455200,1264.301880 1115.833862,1261.356445 1120.062988,1261.548218 
	C1123.510254,1261.704468 1121.528931,1257.943115 1121.055786,1255.385986 
	C1124.794434,1253.729370 1125.633423,1256.783325 1126.783203,1259.118896 
	C1127.410156,1260.392456 1126.727905,1262.040039 1127.753906,1263.690796 
	C1127.695557,1270.216431 1122.877319,1267.264038 1119.437500,1268.000000 
	C1118.695679,1267.872925 1118.442505,1267.679077 1118.120361,1267.209106 
	C1117.570923,1266.499023 1117.055786,1266.315796 1116.210693,1266.786133 
	C1116.000000,1267.000000 1115.500000,1267.000000 1115.250000,1267.000000 
	C1113.291748,1267.094116 1111.778442,1266.583496 1110.219727,1265.265625 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M1128.000000,1263.583862 
	C1126.273193,1263.763184 1125.970459,1262.539185 1126.007324,1261.098999 
	C1126.086182,1258.012939 1124.358276,1256.229248 1121.355835,1255.049561 
	C1121.000000,1255.000000 1121.000000,1254.500000 1121.000000,1254.250000 
	C1121.000000,1253.555542 1121.000000,1253.111084 1121.000000,1252.333252 
	C1126.255127,1253.891235 1128.343140,1257.767456 1128.000000,1263.583862 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M714.803833,5.769372 
	C714.271545,6.949082 713.413147,7.164041 712.196167,6.231103 
	C712.728394,5.051328 713.586792,4.836267 714.803833,5.769372 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1116.271973,371.176910 
	C1115.552856,371.162323 1115.044800,371.238464 1114.670654,371.503815 
	C1113.741943,372.162567 1113.808472,374.402130 1112.012085,373.560791 
	C1110.350952,372.782776 1111.544800,371.354126 1111.779541,370.189331 
	C1111.874268,369.719482 1111.914429,369.238617 1111.989746,368.381409 
	C1112.962769,364.549957 1114.760010,362.118591 1118.376099,360.687683 
	C1123.051514,358.837616 1120.659424,354.014069 1121.532471,350.588959 
	C1121.846802,349.356354 1121.748901,347.722321 1121.958008,346.271362 
	C1122.367188,343.435364 1123.824585,342.421570 1126.446533,343.773438 
	C1128.446899,344.804718 1130.528076,344.938934 1132.709595,345.045074 
	C1135.498535,345.180786 1137.028076,344.445770 1137.243652,341.307953 
	C1137.332886,340.011383 1137.287720,337.212585 1139.558105,337.767883 
	C1141.700439,338.291779 1140.657593,340.837799 1140.935547,342.558228 
	C1141.254028,344.529907 1136.032959,345.522797 1139.777222,347.526550 
	C1142.689819,349.085297 1144.980713,346.434113 1147.027100,344.465607 
	C1148.869995,342.692871 1150.210205,340.298340 1148.121216,338.400238 
	C1143.381592,334.093628 1148.715576,333.383667 1150.375610,331.580109 
	C1151.250610,330.629333 1152.795288,331.223083 1152.849854,332.757416 
	C1153.030640,337.848297 1155.866821,342.468262 1155.095459,347.789520 
	C1154.602661,351.190308 1153.340210,353.269745 1149.854248,352.927887 
	C1146.145508,352.564209 1142.976074,354.254608 1139.842407,355.461975 
	C1137.136719,356.504425 1134.328125,358.465881 1135.629028,362.732941 
	C1136.727173,366.334198 1131.036621,373.795746 1127.391724,374.755280 
	C1123.856445,375.685913 1124.603394,370.730408 1121.367798,371.011780 
	C1119.536987,371.395172 1118.080078,371.869781 1116.271973,371.176910 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M946.945801,368.339661 
	C946.949646,364.676086 948.679016,362.318390 951.264221,360.552032 
	C952.427673,359.757080 953.807983,359.815063 954.978149,360.773315 
	C955.675720,361.344482 955.853088,362.091949 955.679016,362.999817 
	C954.559204,368.840546 954.843018,369.228638 960.676147,369.979340 
	C961.167297,370.042542 961.672668,369.995422 962.585693,369.999756 
	C965.769836,370.000977 968.539795,369.988647 971.309448,370.007935 
	C972.936646,370.019287 974.721069,369.817963 974.975952,372.593079 
	C976.121216,376.474304 979.217041,378.151794 981.855957,380.688049 
	C981.489624,387.533997 980.843018,387.898621 974.565735,387.190399 
	C970.840698,386.770142 967.505188,386.909302 964.353882,383.669800 
	C961.742981,380.985779 959.976624,386.036163 957.138184,386.861420 
	C956.178772,387.100800 955.453003,387.067780 954.363525,387.007629 
	C952.689941,382.114441 948.660217,379.829559 944.591431,377.094910 
	C944.023743,376.494598 943.749146,376.111023 943.349365,375.387329 
	C941.510498,371.251923 945.502869,370.719055 946.945801,368.339661 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1211.989502,257.857727 
	C1214.350220,258.347382 1214.904785,259.914856 1215.326538,261.567261 
	C1216.054321,264.418335 1217.067383,266.590729 1220.772339,265.135468 
	C1221.827515,264.720978 1223.541992,264.458618 1224.371460,265.874725 
	C1224.940063,266.845276 1224.112915,267.783051 1223.571655,268.585663 
	C1222.581055,270.054657 1221.001587,270.375824 1219.601562,269.642181 
	C1215.666504,267.580292 1211.218262,267.847382 1207.104126,266.602783 
	C1198.885986,264.116547 1190.633545,261.688965 1181.427368,262.606934 
	C1185.359985,254.326935 1185.359985,254.326996 1193.437134,255.658279 
	C1193.926758,255.738968 1194.589844,256.065369 1194.880737,255.861481 
	C1201.033813,251.548416 1206.043457,257.137329 1211.989502,257.857727 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M982.397583,381.001556 
	C978.540405,379.563385 974.184387,379.012268 974.859924,373.314789 
	C976.834290,371.324036 978.675720,369.655792 980.500916,367.969940 
	C982.348389,366.263397 982.225830,364.086121 981.742676,361.937378 
	C981.345215,360.169525 978.770020,358.667267 980.821838,356.674988 
	C981.636902,355.883484 982.600830,357.128235 983.270813,357.803833 
	C985.222351,359.771606 988.714966,362.189331 986.554626,364.743073 
	C982.869385,369.099335 985.275879,370.012573 988.727295,371.641937 
	C990.605408,372.528534 990.834290,374.918549 991.387207,376.820618 
	C991.984131,378.874603 991.447937,381.002960 990.149597,382.379272 
	C988.741882,383.871399 987.162659,382.241882 985.691284,381.545258 
	C984.832825,381.138763 983.767517,381.169250 982.397583,381.001556 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1222.358276,243.762939 
	C1223.585693,246.344009 1224.876099,248.507523 1225.645508,250.842728 
	C1226.311646,252.864761 1226.653442,255.277359 1225.410767,257.112122 
	C1224.284790,258.774536 1222.734375,257.179810 1221.422485,256.704071 
	C1218.932251,255.801010 1217.611816,254.108017 1218.038940,251.359863 
	C1218.190918,250.380646 1218.584229,249.427505 1218.647705,248.448212 
	C1218.797852,246.137268 1219.744629,243.227264 1218.716675,241.609970 
	C1215.339722,236.296844 1219.536865,235.112106 1222.607910,233.277573 
	C1226.143799,231.165283 1232.425781,231.697632 1234.885864,234.037048 
	C1235.731079,234.840775 1235.982666,235.649643 1235.570312,236.680832 
	C1234.937256,238.264008 1234.188721,239.787842 1232.193359,239.916458 
	C1229.870605,240.066177 1227.612427,239.878891 1225.292114,239.346588 
	C1221.823242,238.550751 1220.452759,239.949600 1222.358276,243.762939 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1067.850586,379.007141 
	C1071.586426,379.239868 1074.399414,381.454376 1077.876709,381.260895 
	C1079.089600,381.193420 1080.732788,382.422516 1080.353394,383.813934 
	C1079.827148,385.743958 1077.952271,385.021393 1076.609253,384.622589 
	C1069.817017,382.605652 1064.118530,386.811768 1063.973633,393.939270 
	C1063.941528,395.515442 1064.715576,397.626221 1062.943359,398.475555 
	C1061.416748,399.207214 1059.112305,399.801361 1057.811035,399.146484 
	C1056.141357,398.306122 1058.054565,396.599396 1058.328735,395.247803 
	C1059.184937,391.025970 1059.709717,386.880646 1061.656738,382.755249 
	C1063.159302,379.571381 1064.593872,378.888733 1067.850586,379.007141 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1221.945068,206.383575 
	C1222.750000,206.000183 1223.500000,206.000381 1224.625000,206.000580 
	C1225.914429,208.859222 1228.500854,208.660049 1230.686890,208.935318 
	C1232.646606,209.182129 1234.660034,208.976257 1236.647827,209.036331 
	C1238.671387,209.097458 1240.473511,210.029388 1240.630737,212.043793 
	C1240.807373,214.307724 1238.944824,215.707214 1236.883667,215.865753 
	C1233.717163,216.109299 1229.936401,218.473145 1227.715942,213.876846 
	C1227.524048,213.479706 1226.568115,213.253082 1225.980347,213.276749 
	C1220.559570,213.494919 1220.202271,212.948669 1221.945068,206.383575 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1107.504517,338.278595 
	C1109.751465,336.415588 1112.564697,337.148102 1114.637451,335.448944 
	C1115.711792,334.568268 1116.767578,335.647369 1117.333862,336.804260 
	C1118.926514,340.057922 1117.818970,343.453796 1116.097900,345.752441 
	C1114.212158,348.270752 1110.461914,348.924835 1107.400879,347.456970 
	C1101.852417,344.796326 1105.953857,341.575775 1107.504517,338.278595 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1220.320557,189.852112 
	C1217.012085,189.304291 1214.988281,186.165619 1211.421021,186.753738 
	C1210.540161,186.898956 1210.362793,185.817612 1210.798096,184.887527 
	C1212.406128,181.451935 1220.388550,177.868927 1224.626465,178.948654 
	C1224.967163,183.146744 1225.764038,187.630814 1220.320557,189.852112 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1172.210693,299.335754 
	C1166.660156,301.357361 1166.338379,295.893250 1163.746582,293.891602 
	C1162.427124,292.872559 1163.951294,291.478455 1164.920654,290.636414 
	C1167.433594,288.453583 1170.457031,287.424286 1173.653198,288.309723 
	C1175.917969,288.937164 1176.205933,291.273102 1175.877808,293.287231 
	C1175.504028,295.581055 1174.610107,297.745331 1172.210693,299.335754 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1191.305908,228.163712 
	C1193.817749,223.005844 1202.800537,221.971420 1205.949097,226.642120 
	C1205.694092,228.104477 1205.388062,229.208923 1205.041016,230.656540 
	C1204.458252,231.524551 1203.933594,232.066589 1203.197632,232.797119 
	C1198.833130,232.345901 1194.780762,231.413559 1191.305908,228.163712 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1074.869141,401.793762 
	C1074.144409,403.683472 1073.680420,405.455780 1071.800293,405.867157 
	C1070.559204,406.138763 1069.501709,408.388092 1067.978882,406.363403 
	C1066.750488,404.730286 1066.226562,402.370422 1067.874146,401.449310 
	C1071.239624,399.567780 1071.100830,396.803589 1071.031128,393.788300 
	C1071.012817,392.997284 1070.901123,392.016937 1071.884521,391.654663 
	C1072.880127,391.287933 1073.248657,392.224579 1073.861084,392.739349 
	C1076.936768,395.324158 1076.555786,398.288391 1074.869141,401.793762 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1159.055420,305.547302 
	C1160.068848,308.840973 1161.800781,310.870483 1166.808105,309.856171 
	C1161.731812,314.247742 1157.313599,314.164551 1152.899658,313.974091 
	C1151.542725,313.915527 1149.563354,314.579041 1149.338989,312.381958 
	C1149.166260,310.689301 1150.554321,309.405701 1152.020264,309.283203 
	C1154.952515,309.038147 1156.683594,307.064697 1159.055420,305.547302 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1265.663818,263.306030 
	C1262.140381,266.631989 1257.219727,265.665955 1255.499390,261.741272 
	C1254.856812,260.274750 1255.260376,259.185760 1256.509766,258.338715 
	C1258.866699,256.741028 1261.563599,256.470978 1264.088257,257.365021 
	C1266.735229,258.302460 1265.952515,260.841797 1265.663818,263.306030 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1062.191895,416.084015 
	C1063.878540,419.554352 1061.660522,421.417480 1059.332397,423.048462 
	C1057.271973,424.491913 1054.888306,424.550598 1052.817139,423.105621 
	C1050.509277,421.495483 1050.681274,419.602295 1052.799072,417.812744 
	C1055.481689,415.545898 1058.688477,416.010468 1062.191895,416.084015 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1244.611816,238.000198 
	C1246.070190,237.996887 1247.135498,237.997452 1248.600342,237.998077 
	C1248.999634,239.323105 1248.965210,240.649094 1249.006226,241.972763 
	C1249.090088,244.687286 1248.794189,247.012024 1245.195679,247.002899 
	C1242.553101,246.996201 1240.478516,246.112335 1240.145386,243.340591 
	C1239.801025,240.475739 1240.443237,237.930511 1244.611816,238.000198 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1173.235352,254.657471 
	C1172.506958,255.580917 1172.014038,256.161835 1171.260498,256.872009 
	C1169.932739,258.028320 1168.885498,259.139893 1167.265381,259.150116 
	C1164.976562,259.164551 1162.886841,258.523590 1162.239014,256.081146 
	C1161.360962,252.769730 1163.438354,251.290726 1166.624023,250.233261 
	C1169.281494,249.034027 1172.170166,245.115631 1172.997314,251.616760 
	C1173.579590,252.685699 1173.732178,253.458221 1173.235352,254.657471 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1148.399902,247.000000 
	C1146.819702,244.019852 1147.937134,239.755203 1143.196899,238.754715 
	C1142.023560,238.507050 1141.964355,237.291687 1142.556396,236.319595 
	C1143.252075,235.177109 1144.303345,235.044174 1145.573975,235.352997 
	C1149.875854,236.398438 1153.028564,241.668549 1152.009521,246.621689 
	C1150.933350,247.000000 1149.866577,247.000000 1148.399902,247.000000 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1137.004883,267.922058 
	C1137.871338,265.818481 1134.837036,263.086151 1137.515381,262.428925 
	C1139.869751,261.851166 1141.231934,264.656006 1142.302368,266.707886 
	C1143.483643,268.972076 1143.092407,271.182495 1141.802856,273.279114 
	C1140.979126,274.618408 1139.663940,275.768829 1138.209351,275.449127 
	C1136.180542,275.003326 1137.725708,273.337250 1137.627075,272.199738 
	C1137.514282,270.898285 1137.223389,269.612335 1137.004883,267.922058 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1166.594360,250.002197 
	C1166.823486,250.279419 1166.686768,250.596146 1166.463867,250.830841 
	C1164.994751,252.376984 1161.740845,253.262405 1163.526367,256.178619 
	C1165.109741,258.764740 1167.819458,258.034485 1170.622559,257.053711 
	C1170.946045,257.327667 1170.979248,257.704468 1170.824341,257.972565 
	C1169.676025,259.960693 1170.432983,263.373535 1167.106689,263.698120 
	C1164.789062,263.924255 1161.183472,258.228912 1160.974487,254.888733 
	C1160.731934,251.011765 1162.918579,249.994034 1166.594360,250.002197 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1173.411377,254.999817 
	C1173.000000,254.250000 1173.000000,253.500000 1173.000000,252.375000 
	C1176.283936,251.783066 1178.344116,249.020401 1181.307495,248.006851 
	C1183.315552,247.320068 1185.309204,246.174744 1186.434326,248.501404 
	C1187.713501,251.146805 1184.834473,252.145630 1183.307617,252.528687 
	C1180.141479,253.322998 1177.315186,255.527954 1173.411377,254.999817 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1120.180908,328.712189 
	C1117.887939,326.724030 1116.502441,324.124329 1113.325439,323.725555 
	C1112.149902,323.577972 1112.016602,322.392670 1112.175781,321.349854 
	C1112.338379,320.285278 1112.929321,319.408905 1114.009399,319.298126 
	C1116.386841,319.054199 1118.465332,320.258606 1119.325317,322.177612 
	C1120.208252,324.147675 1123.773071,325.980499 1120.180908,328.712189 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1249.743408,253.395233 
	C1249.328491,249.930847 1252.849365,246.548523 1255.653442,247.150085 
	C1256.941406,247.426422 1257.870361,248.201004 1257.771362,249.493546 
	C1257.628174,251.361893 1256.911987,253.249084 1254.922363,253.712509 
	C1253.382935,254.071106 1251.674805,253.704971 1249.743408,253.395233 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1178.055176,276.149292 
	C1174.059570,277.749329 1172.830444,275.955841 1173.122314,272.564819 
	C1173.262695,270.934296 1173.696899,269.314972 1174.147949,267.733307 
	C1174.300293,267.198792 1174.832764,266.573303 1175.337524,266.388519 
	C1175.985596,266.151306 1176.707031,266.622650 1176.772339,267.351898 
	C1177.031250,270.247345 1179.550415,272.726532 1178.055176,276.149292 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1111.000000,367.553711 
	C1109.899536,368.329712 1108.799072,368.659424 1107.349365,368.993958 
	C1103.326172,365.515717 1105.931396,362.642365 1107.951172,359.353699 
	C1113.693848,359.965607 1109.859619,364.456909 1111.000000,367.553711 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1049.209961,386.484344 
	C1050.699585,386.638733 1051.825684,386.837158 1052.952148,387.033203 
	C1054.582275,387.316925 1056.419189,387.532990 1056.732910,389.518829 
	C1056.959473,390.952637 1055.794556,391.978424 1054.573242,392.516327 
	C1052.838501,393.280396 1050.894287,393.457001 1049.634399,391.780792 
	C1048.512939,390.288818 1047.086426,388.570374 1049.209961,386.484344 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1206.693726,240.662018 
	C1204.037231,239.332489 1204.670532,236.953552 1205.317627,235.404892 
	C1206.302002,233.049133 1207.769043,236.058807 1208.939331,235.709198 
	C1212.727539,234.577606 1214.498047,235.862213 1214.382446,239.780609 
	C1214.364502,240.385880 1214.392212,241.313004 1214.049561,241.524078 
	C1213.235229,242.025543 1212.484985,241.577530 1212.118286,240.670593 
	C1210.468506,236.589020 1208.765625,238.215851 1206.693726,240.662018 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1046.350342,375.296082 
	C1046.080444,372.764740 1045.407593,370.759125 1044.495850,368.773224 
	C1043.854248,367.375702 1043.577271,365.695007 1045.663940,365.186035 
	C1047.578735,364.719025 1048.951294,365.396576 1048.964966,367.654907 
	C1048.979126,369.975708 1049.058594,372.301361 1048.937988,374.615997 
	C1048.847656,376.352386 1048.137695,377.239929 1046.350342,375.296082 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1229.160889,252.476685 
	C1230.483154,250.501114 1232.095215,249.340134 1233.880615,250.788956 
	C1235.635376,252.212753 1235.251343,254.441544 1234.560059,256.346588 
	C1234.031372,257.803406 1232.644165,258.169678 1231.234253,257.778168 
	C1228.586182,257.042786 1229.053955,254.830933 1229.160889,252.476685 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1107.991211,358.631836 
	C1107.242920,359.274231 1106.416016,359.434448 1105.743286,359.846375 
	C1104.298096,360.731201 1102.565918,361.849152 1101.506104,359.976807 
	C1100.479126,358.162323 1100.339355,355.670441 1101.902222,353.991364 
	C1103.542480,352.229218 1104.744995,354.518585 1106.098877,355.096252 
	C1107.430298,355.664398 1107.987305,356.830505 1107.991211,358.631836 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1068.006226,413.800293 
	C1068.244141,411.751648 1067.264526,409.425842 1070.077515,409.183136 
	C1071.855835,409.029663 1072.797119,410.200775 1072.921021,411.900177 
	C1073.077637,414.050873 1073.289795,416.677063 1070.971436,417.511719 
	C1068.489624,418.405151 1068.155762,416.110077 1068.006226,413.800293 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1052.379883,409.751740 
	C1048.848267,412.735077 1045.686646,408.554871 1042.354126,409.754211 
	C1042.208008,409.806793 1041.527954,409.091248 1041.553955,408.770691 
	C1041.628784,407.851318 1042.286743,407.249847 1043.225830,407.400360 
	C1044.671875,407.632111 1046.082764,408.280396 1047.415527,407.031830 
	C1051.109619,403.570618 1052.011475,406.155457 1052.379883,409.751740 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1130.679199,274.579163 
	C1128.027832,275.048096 1126.341064,273.769562 1125.618408,271.948639 
	C1125.082397,270.597961 1126.708130,269.615112 1127.982910,269.256500 
	C1129.599365,268.801697 1131.408691,268.657928 1132.440308,270.391541 
	C1133.492310,272.159454 1132.848755,273.561676 1130.679199,274.579163 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1128.142334,384.689880 
	C1126.565430,385.025421 1125.627197,384.051361 1124.288940,383.150391 
	C1122.752930,382.286469 1122.691895,380.937195 1122.218628,379.405579 
	C1122.166870,378.519867 1122.288574,378.037750 1122.764648,377.292847 
	C1129.363770,378.383514 1130.316772,379.714264 1128.142334,384.689880 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1053.860840,338.952087 
	C1052.983765,339.727448 1052.306030,340.225830 1051.778564,340.851044 
	C1050.831055,341.974030 1050.096313,343.560455 1048.230713,342.448730 
	C1047.244263,341.860870 1046.114014,341.058502 1046.459106,339.738617 
	C1046.927246,337.948395 1048.398804,336.893585 1050.174316,337.113159 
	C1051.533936,337.281219 1053.980347,335.459839 1053.860840,338.952087 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1239.000000,231.000000 
	C1238.517212,231.093323 1237.990112,231.092911 1237.559448,231.296524 
	C1235.893311,232.084122 1234.405762,232.091705 1233.139282,230.553757 
	C1232.304565,229.539978 1232.710815,228.703232 1233.557739,228.040619 
	C1235.122314,226.816452 1236.871948,225.420685 1238.894653,226.363373 
	C1240.848755,227.274048 1239.232666,228.934906 1239.049316,230.634491 
	C1239.000000,230.999054 1239.000000,231.000000 1239.000000,231.000000 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1122.750000,377.000031 
	C1123.000000,377.444458 1123.000000,377.888885 1123.000000,378.666687 
	C1122.903687,380.482819 1123.262329,382.019135 1122.240112,383.710663 
	C1120.485596,384.095764 1118.449097,385.236542 1118.137085,382.553192 
	C1117.850952,380.092377 1117.466797,377.336212 1121.610840,377.012115 
	C1122.000000,377.000000 1122.500000,377.000061 1122.750000,377.000031 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1058.731323,359.777985 
	C1056.963623,361.483246 1055.392212,361.173523 1054.498901,359.435089 
	C1053.651733,357.786224 1053.398071,355.626495 1055.412109,354.658325 
	C1057.325806,353.738403 1057.831787,355.777435 1058.428223,357.040131 
	C1058.761963,357.746307 1058.728394,358.625854 1058.731323,359.777985 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1071.664795,420.526398 
	C1072.451782,421.125244 1073.067505,421.505066 1073.358398,422.056213 
	C1074.077759,423.419281 1075.870972,425.010284 1074.213379,426.378021 
	C1072.496582,427.794525 1071.253174,425.781708 1070.243164,424.636200 
	C1068.652710,422.832367 1068.344727,421.116272 1071.664795,420.526398 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1113.095459,350.075134 
	C1115.258789,350.098938 1116.786987,350.532013 1116.751221,352.726929 
	C1116.733276,353.822815 1115.962158,354.504242 1114.949951,354.765747 
	C1113.591431,355.116791 1112.254272,354.937073 1111.549072,353.593414 
	C1110.789185,352.145386 1110.750732,350.741638 1113.095459,350.075134 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M993.776855,360.252258 
	C995.673462,361.027344 996.999817,361.976044 996.735962,363.939209 
	C996.593201,365.001373 995.827759,365.644836 994.749084,365.770538 
	C993.654724,365.898102 992.752502,365.392059 992.429565,364.396881 
	C991.936401,362.877411 991.331970,361.200531 993.776855,360.252258 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1157.485718,286.130981 
	C1152.927490,285.226135 1154.963135,282.036469 1155.695679,279.924469 
	C1156.197144,278.478638 1157.733765,279.555328 1157.861572,280.901794 
	C1158.015381,282.523895 1157.791138,284.181854 1157.485718,286.130981 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1240.183228,265.001221 
	C1241.951050,265.222534 1243.635376,264.414276 1244.524902,266.119202 
	C1244.681885,266.419922 1244.489258,267.223907 1244.220459,267.376312 
	C1241.534546,268.899506 1239.289185,266.873505 1236.725586,266.678314 
	C1237.073853,264.297699 1238.738159,265.317780 1240.183228,265.001221 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1137.452148,329.595032 
	C1134.985596,329.918671 1133.881836,328.911407 1134.331665,326.806061 
	C1134.596924,325.563782 1135.842407,324.693390 1137.018799,325.390289 
	C1138.541626,326.292419 1139.480347,327.731354 1137.452148,329.595032 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1182.367188,287.865448 
	C1180.257446,286.933350 1179.655151,285.874939 1181.722412,284.734192 
	C1183.008057,284.024750 1184.847412,283.273438 1185.526611,285.063660 
	C1186.336548,287.198578 1184.551880,287.814667 1182.367188,287.865448 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1005.384094,374.402771 
	C1005.113647,371.672424 1006.458679,370.872894 1008.586121,371.224915 
	C1009.795837,371.425110 1010.128723,372.498535 1009.563416,373.441559 
	C1008.659912,374.948730 1007.656128,376.785095 1005.384094,374.402771 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1137.360229,286.529053 
	C1137.948486,286.242523 1138.269165,286.066467 1138.573975,286.090759 
	C1139.918091,286.197998 1141.594482,285.330780 1142.368408,287.144318 
	C1142.495605,287.442535 1141.833008,288.446350 1141.396484,288.552216 
	C1139.585815,288.991608 1137.545532,289.617767 1137.360229,286.529053 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1241.557373,273.016144 
	C1241.747803,272.572327 1241.857910,272.423950 1242.001709,272.375336 
	C1243.620972,271.827850 1245.194336,271.672577 1246.294434,273.313293 
	C1246.463135,273.564941 1246.270386,274.423615 1246.043823,274.517731 
	C1244.276611,275.251984 1242.587524,275.384552 1241.557373,273.016144 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1116.044922,371.334839 
	C1117.455688,370.323242 1118.896484,369.508575 1120.767212,370.752136 
	C1121.000000,371.442566 1121.000000,371.887543 1121.000000,372.666260 
	C1119.217896,373.093353 1116.950806,374.976471 1116.044922,371.334839 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1128.143555,298.676422 
	C1127.555542,299.000000 1127.111084,299.000000 1126.333252,299.000183 
	C1125.403687,298.981445 1124.869141,298.796967 1124.205322,298.204010 
	C1124.264526,295.853760 1125.448975,294.677856 1127.602783,295.232666 
	C1129.491699,295.719208 1128.796753,297.141205 1128.143555,298.676422 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1097.583496,365.710876 
	C1097.459473,363.700287 1098.298584,362.457520 1100.022705,362.624573 
	C1101.242920,362.742767 1101.061035,364.164001 1100.894653,365.070892 
	C1100.488159,367.285339 1099.245850,367.177429 1097.583496,365.710876 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1056.264648,372.843109 
	C1056.864136,370.765961 1058.085693,370.719940 1059.297729,371.669525 
	C1060.154297,372.340668 1060.203491,373.571777 1059.371826,374.266296 
	C1057.954712,375.449677 1057.011108,374.479370 1056.264648,372.843109 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1043.003906,393.757690 
	C1044.884033,392.841583 1046.209717,393.046173 1046.558350,395.011261 
	C1046.717529,395.908936 1045.933472,396.700745 1045.091064,396.581146 
	C1043.739502,396.389160 1041.881836,396.312683 1043.003906,393.757690 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1051.549072,399.532715 
	C1049.442505,399.904846 1048.811768,399.102966 1049.487061,397.468872 
	C1049.879639,396.518921 1050.823120,395.872375 1051.802979,396.344727 
	C1053.572388,397.197571 1052.783569,398.334625 1051.549072,399.532715 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1122.784180,316.707642 
	C1120.767090,316.478577 1119.780762,315.620087 1120.508911,313.834229 
	C1120.650757,313.486511 1121.713623,313.163818 1122.132324,313.342651 
	C1123.643188,313.987946 1124.510864,315.011993 1122.784180,316.707642 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1081.773682,376.009216 
	C1080.988525,374.581268 1080.942871,373.477661 1082.498169,373.233246 
	C1083.506348,373.074738 1084.517334,373.703766 1084.531128,374.778290 
	C1084.552856,376.471161 1083.575195,377.005249 1081.773682,376.009216 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1153.400879,327.450867 
	C1153.128052,325.324921 1153.998413,324.809052 1155.585938,325.490082 
	C1156.364624,325.824097 1156.889404,326.597290 1156.501221,327.425385 
	C1155.758667,329.009674 1154.679321,328.990906 1153.400879,327.450867 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1002.226501,380.348907 
	C1004.266296,378.535278 1004.728027,380.443329 1005.467834,381.536285 
	C1005.593689,381.722351 1005.195740,382.599243 1004.924438,382.662537 
	C1003.320312,383.036835 1002.494263,382.164154 1002.226501,380.348907 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1044.240723,418.998413 
	C1044.975098,416.361786 1046.035889,417.681366 1046.924194,418.549774 
	C1047.495239,419.107971 1048.055664,420.040222 1047.043213,420.580627 
	C1045.776733,421.256500 1044.916626,420.406006 1044.240723,418.998413 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1034.752686,386.409790 
	C1032.365601,386.018585 1033.649170,384.922791 1034.153564,384.077637 
	C1034.320801,383.797333 1035.190186,383.559296 1035.340820,383.707092 
	C1036.280029,384.629181 1035.701416,385.472931 1034.752686,386.409790 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1064.698730,360.736206 
	C1063.466064,360.557190 1063.016479,359.954254 1063.355835,359.057800 
	C1063.463135,358.774414 1064.197266,358.444366 1064.468384,358.557556 
	C1065.485352,358.982178 1066.003784,359.682434 1064.698730,360.736206 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1031.974121,407.363739 
	C1030.932739,406.529297 1030.833862,405.655762 1031.596069,404.814941 
	C1031.722168,404.675903 1032.284546,404.687134 1032.408569,404.831879 
	C1033.141113,405.687378 1033.054443,406.559021 1031.974121,407.363739 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1127.522583,326.746399 
	C1128.687866,326.315704 1129.343018,326.769867 1129.515503,327.641663 
	C1129.664185,328.392395 1129.067017,328.844879 1128.354736,328.746643 
	C1127.362793,328.609833 1127.118530,327.883362 1127.522583,326.746399 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M976.467346,366.260986 
	C975.460938,366.517456 975.147156,366.016541 975.274231,365.361023 
	C975.333374,365.056000 975.904846,364.570801 976.124512,364.627838 
	C976.838318,364.813141 976.857056,365.440582 976.467346,366.260986 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1163.259888,305.772247 
	C1163.321655,304.827759 1163.659668,304.321045 1164.366577,304.714752 
	C1164.621948,304.856934 1164.797119,305.563110 1164.673096,305.880920 
	C1164.401123,306.578217 1163.855103,306.641998 1163.259888,305.772247 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1228.555664,228.985992 
	C1229.012939,228.165558 1229.581177,228.062973 1230.139648,228.454010 
	C1230.587891,228.767868 1230.582886,229.264450 1230.121460,229.564148 
	C1229.552612,229.933701 1228.985962,229.825455 1228.555664,228.985992 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1071.044189,362.465851 
	C1071.694214,362.824066 1071.703125,363.183685 1071.236816,363.500641 
	C1071.139282,363.566986 1070.913574,363.563934 1070.818726,363.494568 
	C1070.364990,363.163055 1070.381836,362.803864 1071.044189,362.465851 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1216.658691,232.586853 
	C1217.373169,232.388184 1217.633301,232.637466 1217.521484,233.192825 
	C1217.498413,233.308105 1217.334473,233.462784 1217.218018,233.479965 
	C1216.657471,233.562698 1216.422729,233.289810 1216.658691,232.586853 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1203.312500,232.945496 
	C1203.106323,232.113083 1203.390625,231.356049 1204.687500,231.053787 
	C1204.893555,231.886002 1204.609863,232.643555 1203.312500,232.945496 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M988.658630,366.586914 
	C989.373108,366.388245 989.633301,366.637390 989.521423,367.192902 
	C989.498291,367.308136 989.334595,367.462830 989.218018,367.480042 
	C988.657288,367.563019 988.422729,367.289886 988.658630,366.586914 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1009.412964,367.658691 
	C1009.611938,368.373413 1009.362366,368.633209 1008.807129,368.521729 
	C1008.691895,368.498596 1008.537170,368.334778 1008.519897,368.218201 
	C1008.436951,367.657593 1008.709839,367.422760 1009.412964,367.658691 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1013.586914,372.341492 
	C1013.388062,371.626740 1013.637512,371.366608 1014.192993,371.478210 
	C1014.308228,371.501373 1014.462952,371.665283 1014.480164,371.781952 
	C1014.563171,372.342834 1014.290039,372.577484 1013.586914,372.341492 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1135.413086,279.658447 
	C1135.612061,280.373108 1135.362793,280.633392 1134.807129,280.521820 
	C1134.691895,280.498657 1134.537231,280.334869 1134.519897,280.218201 
	C1134.436646,279.657196 1134.709839,279.422546 1135.413086,279.658447 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M1055.589600,599.756592 
	C1061.351929,604.213562 1061.286133,604.992493 1056.135986,608.650818 
	C1053.538818,610.495728 1051.709717,612.718079 1052.563354,616.646973 
	C1053.367310,620.347290 1051.818726,623.794250 1048.275635,625.863403 
	C1045.815674,627.299927 1044.088379,628.691895 1046.900635,631.529724 
	C1048.748901,633.394958 1046.890381,634.302673 1045.511719,635.322632 
	C1043.299683,636.959229 1041.695801,638.793945 1044.552734,641.333923 
	C1046.456665,643.026611 1045.356323,643.756409 1043.730103,645.031189 
	C1040.228394,647.776306 1036.043945,650.244751 1037.511108,656.114624 
	C1037.940918,657.834229 1036.901489,660.117676 1035.052490,661.288086 
	C1032.006470,663.216187 1031.479004,666.806885 1029.502075,669.440491 
	C1028.694458,670.516357 1028.649292,672.207031 1030.348022,672.752258 
	C1034.110718,673.960083 1033.274170,675.657776 1030.559692,676.664062 
	C1024.170288,679.032959 1020.755005,685.255554 1018.283264,689.970825 
	C1015.875244,694.564453 1008.745300,698.977051 1012.750061,706.350952 
	C1013.735901,708.166138 1011.975037,709.978333 1010.416016,711.432251 
	C1008.177551,713.519775 1008.689697,715.421326 1011.179932,717.108887 
	C1012.304138,717.870605 1014.771729,717.438843 1014.391907,719.488281 
	C1014.074890,721.198914 1012.708862,722.668213 1010.917175,723.496582 
	C1005.981750,725.778381 1003.682190,730.454163 1002.141968,735.019653 
	C1000.238525,740.661743 996.136353,745.437500 995.758240,751.642212 
	C995.698914,752.616211 994.730652,752.709534 993.835205,752.762634 
	C987.682129,753.128174 986.096375,754.662659 985.542480,761.157593 
	C985.425903,762.524841 984.785034,762.700989 983.790894,763.036072 
	C977.893677,765.023132 974.850220,770.154724 973.456299,775.300598 
	C972.197327,779.947998 969.665833,781.474854 965.907593,782.379822 
	C965.456238,783.799011 966.448059,783.849915 967.188904,784.054504 
	C968.573242,784.436951 969.106140,785.315796 968.704285,786.669373 
	C968.350464,787.861084 967.264465,788.068115 966.283447,787.944763 
	C962.161560,787.426575 958.497559,787.109070 962.557800,792.838196 
	C962.808167,793.191467 962.567932,794.414246 962.227844,794.587097 
	C955.197937,798.160645 960.580566,802.198364 962.244568,805.421936 
	C963.783142,808.402344 964.668945,809.566162 960.808594,811.269470 
	C957.682556,812.648743 958.090515,816.491699 957.988403,819.602112 
	C957.900208,822.289307 958.731079,825.428894 954.980042,826.580933 
	C953.723877,825.244385 955.225891,824.016296 954.937378,822.754150 
	C954.406616,820.432556 953.011780,818.721008 950.779236,818.292847 
	C948.564087,817.868164 948.219055,819.828857 947.884827,821.525879 
	C947.689148,822.519348 947.255005,823.619629 946.001465,823.316162 
	C944.741028,823.010925 945.030518,821.723633 945.023438,820.788574 
	C944.984314,815.627502 945.038635,810.465637 944.976929,805.304993 
	C944.956299,803.579529 945.440918,800.887390 942.778748,801.232544 
	C940.420044,801.538452 937.176636,798.207703 935.578674,802.635315 
	C935.260986,803.515503 934.546814,804.349731 933.814758,804.967102 
	C929.727661,808.414429 929.715332,809.260437 933.576416,813.110779 
	C934.856445,814.387268 936.668091,815.114624 937.430969,816.937927 
	C938.010803,818.323730 938.065857,819.595398 936.679993,820.440247 
	C935.272095,821.298706 933.837891,820.820557 933.412048,819.371399 
	C932.663879,816.825439 930.345093,815.647949 928.718018,813.667297 
	C929.347839,817.604309 927.791504,821.971802 931.618896,825.179688 
	C933.220520,826.522034 932.963745,828.453125 932.996704,830.286499 
	C933.058044,833.697937 930.653015,831.945251 929.156738,832.232544 
	C928.204956,832.415283 927.101807,832.748718 926.450867,833.399231 
	C923.385437,836.462585 920.402222,836.156067 916.045837,835.883301 
	C910.858459,835.558350 905.023071,836.775024 899.451721,837.207703 
	C898.918091,837.249146 897.964722,838.019348 898.002686,838.363220 
	C898.559998,843.409180 893.926575,843.951172 891.258240,846.056213 
	C889.227417,847.658386 888.438843,849.629822 888.049683,852.329102 
	C887.395081,853.630920 886.645203,854.491089 885.592651,855.547302 
	C879.521667,858.727661 876.081970,864.190063 871.409912,868.880249 
	C869.038025,870.941345 866.896057,872.654419 865.357666,875.085327 
	C863.843567,877.477905 863.149109,879.741638 864.055176,882.537720 
	C864.880615,885.085327 864.514771,887.788757 864.442566,890.423828 
	C864.397095,892.083252 864.192566,893.959961 862.196838,894.388062 
	C860.069275,894.844604 858.791626,893.317566 857.972168,891.641602 
	C856.368225,888.361389 855.234558,884.912781 855.027344,880.879639 
	C854.798584,880.009521 856.289673,879.647705 854.945557,878.860718 
	C853.459229,875.746643 855.441101,873.586609 856.474976,871.209167 
	C858.682739,866.132446 856.374023,862.817261 850.935669,863.664368 
	C847.427246,864.210815 844.111572,864.052246 841.537231,861.630127 
	C838.355225,858.636230 834.984619,857.292969 830.658447,858.353210 
	C829.213440,858.707336 827.666382,858.451599 826.012756,857.481140 
	C824.569702,855.352600 825.093323,853.329590 825.624878,851.457825 
	C826.354370,848.888794 827.216187,846.701904 825.000183,844.151184 
	C823.376709,842.282410 825.427307,840.799622 827.117676,840.015503 
	C830.524109,838.435425 833.760193,837.648193 829.218262,832.972534 
	C827.563477,831.269165 827.315125,826.999939 826.093079,823.969543 
	C825.520691,822.550354 826.698914,821.010498 828.139832,820.334534 
	C831.613281,818.704895 835.283081,817.002319 839.138733,817.907654 
	C845.711609,819.450806 851.881958,819.621704 857.593262,815.351746 
	C858.333008,814.798767 859.466064,814.724548 860.443970,814.528198 
	C857.166260,814.756775 850.168884,811.175720 848.944214,808.591125 
	C847.799866,806.176025 849.156128,805.088074 851.289368,804.864563 
	C854.133423,804.566650 856.933655,805.199158 859.605164,806.120850 
	C868.579773,809.217346 877.360474,809.099182 885.843262,804.593689 
	C887.545593,803.689575 889.072449,802.605286 890.782593,804.182068 
	C892.418335,805.690063 891.889893,807.486328 891.079163,809.232666 
	C890.800964,809.832031 890.498535,810.433044 890.749573,811.337524 
	C891.825989,809.353760 893.069153,807.501221 894.814026,805.593628 
	C896.318970,803.587769 898.052246,802.446106 900.180481,803.009338 
	C903.556702,803.902832 906.747803,803.106079 910.039490,802.654114 
	C913.302612,802.206055 916.817444,802.703064 918.683350,798.988281 
	C918.965881,798.425842 919.741699,798.016541 920.383301,798.053406 
	C928.328918,798.510010 932.388489,791.948730 937.358032,787.796570 
	C940.971863,784.777161 944.515991,783.146362 948.742920,782.690063 
	C954.114868,782.110107 958.106201,779.545410 961.371460,775.505615 
	C962.844482,773.683167 964.482544,772.071960 966.421143,770.724670 
	C974.611328,765.032898 977.372009,755.557556 982.334351,747.622742 
	C982.904358,746.711304 982.704285,745.348145 982.431641,743.832581 
	C982.223450,743.002441 982.188538,742.515259 982.294678,741.663757 
	C982.486511,741.088196 982.599548,740.872498 982.964111,740.396362 
	C986.987854,738.209412 988.772400,734.403625 990.520630,731.065796 
	C992.605103,727.085999 994.975830,723.075806 995.708740,718.407349 
	C996.444275,713.721924 1000.048889,710.412659 1002.237793,706.417358 
	C1003.042969,704.947754 1003.918030,703.649170 1003.998108,701.592407 
	C1004.231506,700.556519 1004.508423,699.909058 1004.981567,698.967041 
	C1007.212891,695.785339 1009.522034,693.044250 1010.256470,689.089539 
	C1010.521301,687.170837 1011.228394,685.776978 1012.249207,684.174927 
	C1012.987488,683.400330 1013.744019,683.002930 1013.444824,681.732300 
	C1013.397705,680.731323 1013.661926,680.157410 1014.459656,679.521667 
	C1017.841003,679.174988 1019.483276,678.058228 1020.141174,674.517700 
	C1020.891296,670.480957 1023.917297,667.012390 1024.236572,662.337097 
	C1027.205200,658.618469 1030.592163,655.505432 1030.321533,650.183044 
	C1029.994019,648.075195 1030.870361,646.651672 1031.844971,644.898926 
	C1033.239990,643.378052 1033.617798,641.800354 1034.035889,639.848267 
	C1039.715698,628.628723 1044.681274,617.456116 1050.440918,606.670288 
	C1051.823242,604.081848 1051.003296,600.140076 1055.589600,599.756592 
z"/>
<path fill="#212C3D" opacity="1.000000" stroke="none" 
	d="
M981.859619,829.777832 
	C983.703613,830.769104 984.519348,829.477844 985.557190,828.723694 
	C988.939026,826.266174 992.571594,824.320740 996.708069,823.414307 
	C998.852295,822.944458 1001.556396,822.181580 1003.012878,823.448853 
	C1007.430176,827.292236 1009.434937,823.754150 1012.211670,821.316589 
	C1023.066589,811.787292 1033.937744,815.023132 1037.781860,828.960999 
	C1039.632446,835.670227 1041.059814,842.496155 1042.824707,849.638306 
	C1046.060547,854.414917 1046.489746,859.578125 1047.029175,865.076294 
	C1046.591797,866.249451 1046.053589,866.861816 1045.097412,867.645264 
	C1044.319092,868.169556 1043.840210,868.396729 1042.959717,868.657776 
	C1034.723877,868.412415 1032.853760,867.047485 1031.435669,859.513000 
	C1030.110718,852.473328 1028.472290,845.503662 1027.109619,838.475281 
	C1026.599854,835.846863 1025.916504,833.275513 1024.630615,830.906494 
	C1023.490234,828.805481 1022.226929,826.862488 1019.407959,826.917542 
	C1016.701355,826.970398 1015.197632,828.703918 1014.022888,830.798584 
	C1013.315430,832.060364 1012.197510,833.267334 1012.953918,835.276489 
	C1013.406616,836.294373 1013.669922,836.994141 1014.075073,838.053467 
	C1016.339966,848.151001 1018.834534,857.781921 1022.426025,867.527222 
	C1023.128540,870.422058 1024.122559,872.928162 1021.751587,875.528198 
	C1020.348450,876.302246 1019.239624,876.363586 1017.718262,876.060181 
	C1011.317261,872.496826 1008.429504,867.242493 1007.169861,860.432678 
	C1005.789612,852.970764 1004.518494,845.417053 1001.944458,838.224365 
	C1000.463745,834.086792 997.224426,832.792114 993.757996,834.622742 
	C989.309387,836.972046 987.235718,841.838440 989.481689,845.541138 
	C992.579895,854.250793 994.471191,862.952148 997.321411,871.371704 
	C997.797302,872.777344 998.284668,874.162903 999.008850,875.919067 
	C998.586121,881.680115 997.119629,882.954346 990.764771,883.137695 
	C986.491577,881.014893 984.811462,877.291443 983.601074,873.517700 
	C980.198181,862.908142 976.797363,852.288696 974.483154,841.362854 
	C972.956604,834.155640 973.062683,833.893127 979.934448,831.164246 
	C980.527710,830.928589 980.958740,830.325317 981.859619,829.777832 
z"/>
<path fill="#26323D" opacity="1.000000" stroke="none" 
	d="
M930.312988,842.440430 
	C930.487610,841.627197 930.746033,841.206055 931.045410,840.528320 
	C933.883362,834.970337 939.391174,835.358521 944.053528,834.496948 
	C950.177063,833.365356 955.803772,835.646423 961.482056,838.809570 
	C963.523254,840.078491 964.479431,841.837341 966.130005,843.511230 
	C973.472534,854.501221 973.239014,866.412109 972.191345,878.429504 
	C972.033325,880.242065 971.140076,881.923218 969.613464,883.594482 
	C967.326172,882.435547 967.071045,884.339417 966.111572,885.668457 
	C963.251953,887.783630 960.366516,889.117737 957.391907,890.137085 
	C945.503784,894.210876 935.374573,889.969604 928.991516,879.093506 
	C921.638855,866.565186 923.458069,854.666809 930.312988,842.440430 
z"/>
<path fill="#1D2639" opacity="1.000000" stroke="none" 
	d="
M909.670471,846.660156 
	C911.577332,847.360413 913.229065,847.691406 914.241333,849.651978 
	C914.688477,850.438171 915.199768,850.757141 915.363770,851.594910 
	C915.273010,852.184875 914.924683,852.516235 915.150024,852.811768 
	C915.551575,853.338257 914.853394,852.815369 915.750854,852.818604 
	C916.655396,854.091675 916.192200,855.037720 915.297485,856.181274 
	C914.820129,856.486755 914.604431,856.569397 914.044189,856.698730 
	C907.297424,856.097046 901.525085,857.277649 897.091187,862.414612 
	C896.425476,863.185913 895.863098,863.964600 895.964600,865.366150 
	C896.015076,866.114319 896.001038,866.536682 895.914246,867.255798 
	C895.772766,868.119812 895.655518,868.684448 895.783997,869.546082 
	C895.911133,870.015503 895.969727,870.204346 896.117676,870.682251 
	C896.534363,872.188721 896.822021,873.416931 897.293213,874.941589 
	C900.349060,883.687500 903.511475,886.196411 912.102966,886.706421 
	C914.968567,887.433533 917.127686,886.322510 919.635132,885.228149 
	C920.408630,885.018921 920.864990,884.968994 921.675537,884.994812 
	C922.722900,885.183899 923.335205,885.512207 924.135742,886.200012 
	C924.951294,887.178467 925.243408,888.035339 925.553345,889.242554 
	C925.653442,889.784790 925.672668,890.004456 925.667725,890.549194 
	C925.559692,891.292725 925.489929,891.713440 925.543579,892.454529 
	C925.681458,893.005188 925.713684,893.238220 925.710938,893.836060 
	C922.484619,899.907898 912.878723,902.746460 904.976990,901.104004 
	C889.745850,897.938232 878.378723,877.378540 883.955750,864.697998 
	C884.710327,862.982483 884.364624,861.057007 885.319275,859.031006 
	C885.500122,858.435059 885.556152,858.194824 885.707275,857.601807 
	C885.971191,856.802734 886.175537,856.373596 886.569397,855.652222 
	C886.889038,854.910461 887.122498,854.518250 887.714905,853.986206 
	C893.093079,849.023132 899.238586,846.995911 906.527466,846.757568 
	C907.709717,846.284363 908.493286,846.143250 909.670471,846.660156 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M930.024414,842.290161 
	C933.268433,843.097290 931.062744,844.769775 930.502686,845.911438 
	C928.392761,850.212280 926.613831,854.564026 926.014893,859.382629 
	C924.943726,867.999695 928.159241,875.204407 932.941345,882.029297 
	C939.095398,890.812195 949.269775,891.192078 957.920227,888.305969 
	C960.414551,887.473755 962.733459,886.115601 965.781677,885.134216 
	C966.539490,885.481995 966.648499,885.694031 966.816406,886.261597 
	C966.850281,887.108826 966.737244,887.580505 966.346191,888.347168 
	C965.195862,889.499146 964.254944,890.343140 962.836731,891.214111 
	C952.101501,897.184021 941.875854,897.447937 932.018677,890.269226 
	C926.208069,886.037537 923.467957,879.660034 921.426697,873.147705 
	C919.511353,867.036865 919.768311,860.675049 921.574646,854.062134 
	C922.371704,851.538574 923.564575,849.630066 924.737732,847.303345 
	C925.266663,846.325806 925.748718,845.778259 926.678467,845.185181 
	C927.949158,844.204346 928.884399,843.368164 930.024414,842.290161 
z"/>
<path fill="#0C161B" opacity="1.000000" stroke="none" 
	d="
M981.967896,829.297974 
	C981.348633,831.843567 982.822266,836.368958 977.174316,833.201782 
	C974.208984,831.538940 974.978210,835.491272 975.268188,836.980225 
	C976.338501,842.476440 977.257263,848.078430 979.117676,853.327026 
	C982.062500,861.635315 983.206177,870.563110 987.663574,878.339783 
	C988.318359,879.482178 989.119324,880.540771 990.023438,881.960876 
	C990.188904,882.556885 990.183533,882.830078 989.911499,883.414795 
	C983.469482,886.140747 981.399597,885.136536 979.523926,878.839844 
	C978.301758,874.737061 977.883667,870.428284 976.158203,866.059814 
	C974.829529,863.446960 974.886597,860.728455 973.154907,858.272827 
	C972.408081,856.214966 972.318787,854.402893 971.804871,852.281372 
	C970.934692,847.256531 969.715698,842.707031 968.652771,837.712402 
	C968.580017,836.768860 968.571716,836.226318 968.605042,835.271606 
	C968.699646,834.006714 969.007507,833.255920 969.927856,832.376587 
	C974.127502,831.243225 977.646851,829.075562 981.967896,829.297974 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M1060.764893,810.025146 
	C1061.201416,810.792175 1061.246216,811.547913 1061.219482,812.677734 
	C1060.204956,813.571411 1060.065674,814.639709 1059.276367,815.709351 
	C1055.601440,817.872559 1055.696655,821.818237 1055.940674,824.384277 
	C1056.423462,829.462463 1055.964966,834.605408 1056.985229,839.643616 
	C1057.525391,842.310913 1058.300537,844.378174 1061.613037,844.156982 
	C1062.429810,844.102478 1063.085815,844.829590 1063.396240,846.080933 
	C1060.516479,850.041321 1056.809570,850.711792 1052.288330,849.266296 
	C1051.636963,843.282898 1049.373535,837.938599 1049.674561,832.078857 
	C1049.836182,828.930908 1050.054810,825.658997 1049.619751,822.376587 
	C1049.260620,819.665955 1050.537598,816.493347 1053.460815,815.485779 
	C1056.551514,814.420349 1056.258789,809.467468 1060.764893,810.025146 
z"/>
<path fill="#212C3D" opacity="1.000000" stroke="none" 
	d="
M1051.848267,849.066772 
	C1055.758789,849.302124 1059.034546,847.395020 1062.867676,846.432190 
	C1063.487183,845.721069 1063.940796,845.471252 1064.933594,845.576050 
	C1067.948242,847.782288 1068.041992,850.807251 1068.255005,854.161987 
	C1067.477783,857.774109 1066.899658,860.982971 1062.956543,862.148743 
	C1059.539673,863.158997 1057.140015,862.785522 1056.134888,858.932556 
	C1055.328003,855.839294 1053.604858,853.238831 1051.259277,850.604248 
	C1050.916504,850.116394 1050.825073,849.891052 1051.042236,849.471680 
	C1051.350952,849.277527 1051.707275,849.184937 1051.848267,849.066772 
z"/>
<path fill="#EDECEF" opacity="1.000000" stroke="none" 
	d="
M962.202698,840.452454 
	C951.941040,834.429077 941.924927,834.552673 931.435913,840.145020 
	C930.507507,836.803345 931.935303,835.133240 935.471680,834.640198 
	C941.184204,833.843750 946.231750,830.288879 952.637878,831.755188 
	C956.024719,832.530334 959.294373,833.093384 962.638794,834.951538 
	C964.385620,836.850098 963.777527,838.543762 962.202698,840.452454 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M1055.850464,599.573608 
	C1054.090698,600.106140 1052.849243,601.825317 1052.886841,602.846130 
	C1053.051636,607.314697 1049.988281,610.356567 1048.346924,613.937012 
	C1044.369751,622.613220 1039.822510,631.028137 1035.245117,639.769287 
	C1034.760986,640.100403 1034.536743,640.210632 1033.994873,640.119385 
	C1032.886353,638.736755 1033.310791,637.497437 1033.526855,635.895996 
	C1035.361450,628.758301 1039.059937,622.829956 1042.007568,616.574097 
	C1044.330688,611.643616 1046.758423,606.756348 1049.516602,601.619629 
	C1050.264160,600.152405 1050.891846,599.055603 1052.392334,598.357910 
	C1053.183960,597.775696 1053.595459,597.188660 1054.237061,596.460938 
	C1054.913818,597.274109 1055.360474,598.227905 1055.850464,599.573608 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M1046.615967,866.006531 
	C1045.146118,860.820435 1044.011108,855.823364 1042.923462,850.416870 
	C1044.631592,851.060120 1046.480957,851.122314 1048.763306,851.018433 
	C1049.428711,850.933960 1049.689819,850.863892 1050.264160,851.052734 
	C1051.542603,852.766296 1051.494751,854.373413 1051.208496,856.393066 
	C1049.645142,859.489807 1050.059570,862.829712 1047.967773,865.683228 
	C1047.432251,866.018738 1047.191528,866.107178 1046.615967,866.006531 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M961.929077,840.969604 
	C962.170959,839.082703 962.532837,837.452820 962.949768,835.411499 
	C964.693176,833.012024 966.583618,831.470154 969.853760,832.103271 
	C970.115906,832.828979 969.973328,833.532593 969.701294,834.571167 
	C969.241882,835.599670 968.684387,836.183655 968.562561,837.286072 
	C968.681458,838.646606 969.004150,839.691223 968.656494,841.103394 
	C967.871765,842.700867 967.138794,843.922180 965.241638,844.182434 
	C963.948425,843.127563 962.998779,842.177124 961.929077,840.969604 
z"/>
<path fill="#D0CFCE" opacity="1.000000" stroke="none" 
	d="
M926.890137,844.779846 
	C926.844238,845.585754 926.674500,846.131042 926.281982,846.861450 
	C922.793457,849.853455 919.562622,852.690613 917.399231,856.930298 
	C916.356934,857.424377 915.728333,857.147095 915.014038,856.280273 
	C914.929504,855.144592 915.005066,854.339111 915.030884,853.297058 
	C914.865662,852.867371 914.750183,852.674377 914.782104,852.177856 
	C914.710266,851.404175 914.490967,850.933960 914.344543,850.208618 
	C916.922058,850.432861 918.718323,849.420044 919.523682,847.094543 
	C920.906860,843.100464 923.953796,844.240845 926.890137,844.779846 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1067.682251,687.858337 
	C1066.423950,689.982971 1065.645752,692.332642 1064.919434,694.705872 
	C1064.478394,696.147034 1063.514160,696.900879 1061.999146,696.908203 
	C1060.938843,696.913391 1059.917969,696.601990 1059.558594,695.563843 
	C1059.000977,693.952637 1060.299316,693.150879 1061.362183,692.415039 
	C1062.736084,691.463928 1064.432373,690.774170 1063.128418,688.298767 
	C1064.363281,686.495972 1065.857422,687.006104 1067.682251,687.858337 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1082.995605,655.771606 
	C1083.021240,657.538757 1082.721802,658.781738 1081.671387,659.727966 
	C1081.063477,660.275574 1080.180786,660.972656 1079.591064,660.166382 
	C1078.102539,658.131226 1079.753296,656.116516 1079.968262,654.084412 
	C1080.082031,653.010132 1079.836792,651.603882 1081.409058,651.381104 
	C1082.397949,651.240906 1082.806519,652.061279 1082.908081,652.918640 
	C1083.003662,653.725159 1082.973511,654.546692 1082.995605,655.771606 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1073.089111,638.984131 
	C1073.145264,636.338013 1074.130249,635.494324 1076.162842,636.862183 
	C1077.259033,637.599854 1078.530029,638.914124 1077.442749,640.091431 
	C1076.055786,641.593140 1074.246826,641.527893 1073.089111,638.984131 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1090.627686,664.857605 
	C1087.743042,664.718628 1087.695679,663.130005 1088.518799,661.411072 
	C1089.139893,660.114197 1090.337402,660.064331 1091.408203,661.026733 
	C1092.987427,662.446228 1093.237549,663.780457 1090.627686,664.857605 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1070.634766,752.001709 
	C1071.311523,752.789001 1071.623169,753.578064 1071.967285,754.684753 
	C1070.366577,754.785278 1068.019409,755.644958 1067.652710,753.218445 
	C1067.419312,751.675171 1069.260620,752.193237 1070.634766,752.001709 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M888.087891,853.528931 
	C887.961121,854.162842 887.917114,854.521057 887.923218,855.147339 
	C887.892761,855.851685 887.703491,856.236206 887.168945,856.784912 
	C886.916992,856.993530 886.991272,856.812561 886.902954,856.770447 
	C886.768372,856.938049 886.721985,857.147705 886.353027,857.355225 
	C885.408630,857.041626 885.073303,856.526855 885.110840,855.563965 
	C885.243530,853.821472 886.229614,853.328735 887.733765,852.861084 
	C888.089355,852.877991 888.130005,853.065613 888.087891,853.528931 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1060.522461,683.746338 
	C1061.687744,683.315735 1062.343506,683.769775 1062.516113,684.641541 
	C1062.664795,685.391907 1062.067139,685.844910 1061.354858,685.746826 
	C1060.362671,685.610107 1060.118530,684.883301 1060.522461,683.746338 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M909.945679,846.587036 
	C909.246460,846.881409 908.468506,846.894287 907.318848,846.784302 
	C907.730896,844.988037 908.684448,844.706970 909.945679,846.587036 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M1050.430298,851.065857 
	C1050.110352,851.024109 1049.637573,851.018555 1049.402588,851.011230 
	C1049.266724,850.053772 1049.604126,849.302673 1051.021973,849.228760 
	C1051.350952,849.277527 1051.388550,849.668274 1051.379395,849.864258 
	C1051.332642,850.509521 1051.126221,850.858765 1050.430298,851.065857 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1042.687500,770.054016 
	C1042.893555,770.886292 1042.609619,771.643677 1041.312500,771.945923 
	C1041.107056,771.114075 1041.390015,770.356018 1042.687500,770.054016 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1085.987915,770.832153 
	C1087.666382,774.336487 1087.149292,776.362305 1083.250732,777.247559 
	C1081.072266,777.742310 1079.177368,779.401978 1077.084839,780.388672 
	C1075.500244,781.135925 1073.836914,781.513000 1072.985840,779.278748 
	C1072.337036,777.575256 1071.802124,776.297668 1073.688599,774.519958 
	C1077.207275,771.204346 1080.542969,768.480835 1085.987915,770.832153 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1080.996826,715.016052 
	C1082.983521,715.133728 1085.267456,714.627991 1085.410767,716.825928 
	C1085.580933,719.433533 1082.853760,718.751038 1081.322510,719.360596 
	C1080.056274,719.864563 1078.612915,720.304443 1078.175049,718.367065 
	C1077.744751,716.462708 1078.320679,715.075806 1080.996826,715.016052 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1051.807983,703.250000 
	C1052.172485,700.951843 1051.493774,698.091797 1054.833130,698.338989 
	C1057.285156,698.520447 1057.100952,700.902100 1056.927124,702.699097 
	C1056.588989,706.195007 1054.162598,703.610840 1052.374512,704.009277 
	C1051.871948,703.833313 1051.744019,703.666687 1051.807983,703.250000 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1046.589600,773.135864 
	C1048.191528,773.302368 1050.141357,772.173462 1050.374023,774.414795 
	C1050.477173,775.408264 1049.229492,775.933777 1048.216919,775.878723 
	C1046.590088,775.790100 1044.787354,775.662720 1046.589600,773.135864 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1075.568848,708.436646 
	C1074.744263,707.147278 1073.490479,705.914978 1075.566650,705.198792 
	C1076.702759,704.806885 1078.066406,705.175781 1078.385742,706.416199 
	C1078.913696,708.467407 1077.589355,708.916870 1075.568848,708.436646 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1074.628906,690.015564 
	C1075.000000,690.444458 1075.000000,690.888916 1075.000000,691.666687 
	C1074.882568,692.914856 1075.227173,694.115356 1073.981934,694.442078 
	C1073.008789,694.697449 1072.477539,693.857971 1072.262695,693.074402 
	C1071.798218,691.381042 1072.237671,690.155884 1074.628906,690.015564 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1051.627197,703.016296 
	C1052.000000,703.000000 1052.000000,703.500000 1052.000000,703.750000 
	C1051.874146,705.085449 1052.179565,706.448975 1050.581543,706.689209 
	C1049.896118,706.792236 1049.356567,706.347046 1049.208008,705.605774 
	C1048.875488,703.947693 1049.633911,703.144287 1051.627197,703.016296 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1065.723022,780.338379 
	C1065.247192,782.085571 1064.195312,782.145081 1063.043579,781.592651 
	C1062.826416,781.488525 1062.678955,780.599976 1062.847656,780.439514 
	C1063.653687,779.672607 1064.545166,778.769592 1065.723022,780.338379 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1112.413086,739.658325 
	C1112.612183,740.373230 1112.362915,740.633850 1111.806885,740.522095 
	C1111.691528,740.498840 1111.536743,740.334900 1111.519409,740.218262 
	C1111.436523,739.657410 1111.709229,739.422058 1112.413086,739.658325 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1111.586670,746.341370 
	C1111.387451,745.626099 1111.637329,745.366272 1112.192993,745.477966 
	C1112.308228,745.501160 1112.463135,745.664978 1112.480347,745.781616 
	C1112.563232,746.342407 1112.290527,746.577759 1111.586670,746.341370 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1111.586914,765.341309 
	C1111.388062,764.626526 1111.637695,764.366821 1112.192993,764.478394 
	C1112.308228,764.501587 1112.462524,764.665466 1112.479858,764.782104 
	C1112.563232,765.343262 1112.289429,765.576965 1111.586914,765.341309 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1092.043945,772.465515 
	C1092.694092,772.823608 1092.704224,773.183472 1092.237183,773.500854 
	C1092.139526,773.567139 1091.913696,773.564148 1091.818726,773.494873 
	C1091.365112,773.163391 1091.381104,772.803955 1092.043945,772.465515 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1222.204346,849.766418 
	C1220.255127,848.156250 1216.952515,848.226624 1217.280029,845.098755 
	C1217.590820,842.129944 1220.035767,841.322510 1222.647583,840.833008 
	C1225.026855,840.387146 1224.961548,838.359253 1225.029175,836.514587 
	C1225.086792,834.938721 1225.764038,833.944580 1227.474976,834.165039 
	C1229.020386,834.364197 1229.969971,835.225525 1229.470703,836.882446 
	C1228.784912,839.159241 1228.810425,841.468994 1231.002808,842.592773 
	C1233.643066,843.946106 1235.623657,842.056763 1237.500366,840.333618 
	C1236.839478,838.472534 1234.966675,838.787415 1233.858276,837.919128 
	C1232.896973,837.166016 1231.846313,836.464417 1232.713745,835.127319 
	C1233.448364,833.994995 1234.711670,833.757507 1235.756958,834.563660 
	C1237.709229,836.069153 1239.493896,837.720398 1241.843262,838.726013 
	C1243.171509,839.294495 1243.073364,840.915955 1242.740601,842.287292 
	C1241.876099,845.849121 1235.884766,850.967896 1232.108765,850.929382 
	C1228.902832,850.896790 1225.702393,850.319763 1222.204346,849.766418 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1208.921143,836.993530 
	C1211.060181,837.028198 1212.489868,836.229431 1213.960938,835.423584 
	C1215.097900,834.800781 1216.069214,832.298279 1217.476562,834.065002 
	C1218.674194,835.568726 1218.407593,837.858582 1216.734863,839.476196 
	C1213.670166,842.440063 1204.312622,842.980347 1201.407837,840.194885 
	C1200.029785,838.873413 1199.372437,836.976746 1200.656250,835.233887 
	C1202.037354,833.358887 1203.269409,834.847046 1204.339111,835.815308 
	C1205.563721,836.923950 1207.006836,837.029297 1208.921143,836.993530 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1204.087524,860.886841 
	C1203.081299,860.774719 1201.912354,860.566223 1201.921509,860.448425 
	C1202.284180,855.734619 1201.383545,850.177002 1203.620972,846.577942 
	C1205.593384,843.405273 1209.086670,848.854309 1212.392944,849.200867 
	C1214.390869,849.410217 1214.166504,851.372498 1213.708984,852.813599 
	C1213.400146,853.786743 1212.401611,853.667419 1211.613159,853.250488 
	C1206.650879,850.627136 1205.855347,853.412781 1205.977905,857.522156 
	C1206.016724,858.822693 1206.138794,860.260803 1204.087524,860.886841 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1206.390625,866.001343 
	C1206.259766,862.810425 1209.394653,860.703125 1209.245361,857.325867 
	C1209.215576,856.652222 1209.930176,856.162170 1210.667236,856.356323 
	C1211.061035,856.460083 1211.701172,857.008545 1211.650757,857.214661 
	C1210.552612,861.707947 1213.714722,860.942932 1216.243774,861.021851 
	C1217.551147,861.062500 1219.248413,861.025269 1219.344604,862.703674 
	C1219.442383,864.404297 1217.225098,863.454773 1216.760132,864.117004 
	C1213.977295,868.079834 1210.376709,866.859741 1206.390625,866.001343 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1207.035889,784.055176 
	C1205.647705,782.164307 1204.580688,781.674316 1203.950439,784.155151 
	C1203.521729,785.842468 1202.282471,786.398071 1200.984985,785.571167 
	C1199.391235,784.555420 1200.486938,783.335693 1201.269043,782.237122 
	C1201.927734,781.312012 1202.573120,780.348694 1203.034058,779.317200 
	C1204.093872,776.946350 1205.279297,776.627319 1206.906128,778.822815 
	C1207.785522,780.009460 1208.773560,781.115723 1209.718994,782.252869 
	C1210.465698,783.150879 1211.453613,784.209900 1210.421143,785.258789 
	C1209.100830,786.599915 1208.078979,785.433655 1207.035889,784.055176 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1208.015503,832.255371 
	C1207.004517,830.934509 1206.233154,829.877502 1205.574707,828.754395 
	C1205.081665,827.913086 1205.409912,826.947571 1206.187012,826.674988 
	C1208.463257,825.876587 1209.900879,828.242859 1212.022705,828.469666 
	C1213.018799,828.576111 1213.101074,830.050110 1212.729492,830.989319 
	C1211.874878,833.149658 1210.214355,833.267639 1208.015503,832.255371 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1223.979248,812.899292 
	C1223.895264,814.211792 1223.999023,815.510986 1222.689819,815.404663 
	C1221.359253,815.296448 1221.074585,814.012207 1221.060425,812.829895 
	C1221.049072,811.881470 1220.953735,810.711060 1222.126831,810.436951 
	C1223.793457,810.047607 1223.831909,811.398438 1223.979248,812.899292 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1244.083374,830.479492 
	C1245.550049,831.037537 1246.252563,831.859741 1245.482788,833.153870 
	C1245.342529,833.389648 1244.516724,833.505066 1244.241699,833.322754 
	C1243.152466,832.600830 1242.690186,831.654358 1244.083374,830.479492 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1211.423340,819.326294 
	C1211.135010,821.473633 1210.067139,820.956238 1209.040527,820.246399 
	C1208.458374,819.843994 1208.171143,819.125671 1208.747070,818.593872 
	C1209.685913,817.726685 1210.531616,818.327881 1211.423340,819.326294 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1184.816650,795.678589 
	C1183.329102,795.441284 1182.864502,794.757874 1183.622314,793.700439 
	C1183.822388,793.421204 1184.547363,793.234009 1184.867432,793.375488 
	C1186.086304,793.913757 1185.940308,794.747620 1184.816650,795.678589 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1234.700439,827.897461 
	C1233.975342,828.855103 1233.204346,829.081970 1232.617676,828.281677 
	C1232.199463,827.711365 1232.585571,827.006836 1233.169312,826.689392 
	C1234.094971,826.185852 1234.636719,826.612854 1234.700439,827.897461 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1226.735962,819.301636 
	C1226.557007,820.534302 1225.953979,820.983398 1225.057861,820.644165 
	C1224.774414,820.536926 1224.444458,819.802917 1224.557617,819.531799 
	C1224.982178,818.514954 1225.682373,817.996704 1226.735962,819.301636 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1193.884277,815.106689 
	C1193.251953,813.958130 1193.485962,813.294861 1194.547974,813.243164 
	C1195.252808,813.208923 1195.787109,813.737549 1195.751709,814.443542 
	C1195.698486,815.504700 1195.032959,815.737427 1193.884277,815.106689 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1209.341309,764.413086 
	C1208.626831,764.611633 1208.366333,764.362488 1208.478149,763.806885 
	C1208.501343,763.691589 1208.665039,763.536743 1208.781738,763.519592 
	C1209.342773,763.436707 1209.577148,763.710083 1209.341309,764.413086 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1214.658691,809.586914 
	C1215.373413,809.388062 1215.633789,809.637146 1215.521851,810.192932 
	C1215.498535,810.308289 1215.334717,810.463135 1215.218140,810.480347 
	C1214.657349,810.563171 1214.422363,810.290222 1214.658691,809.586914 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1207.466064,804.955994 
	C1207.824219,804.305664 1208.183838,804.296631 1208.501099,804.763000 
	C1208.567505,804.860596 1208.564453,805.086365 1208.495117,805.181274 
	C1208.163452,805.634888 1207.804199,805.618591 1207.466064,804.955994 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1214.341309,824.413208 
	C1213.626587,824.611877 1213.366455,824.362183 1213.478027,823.806763 
	C1213.501221,823.691528 1213.665405,823.536926 1213.781982,823.519714 
	C1214.343140,823.436890 1214.577515,823.710083 1214.341309,824.413208 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M1119.385742,812.137085 
	C1119.572632,810.425293 1119.644653,809.101929 1119.705078,807.379456 
	C1120.077393,805.828552 1119.897827,804.580811 1120.419678,803.091858 
	C1121.782104,800.585449 1123.021484,798.418579 1124.548340,796.067139 
	C1125.731812,796.444031 1125.989258,797.314453 1126.021973,798.656738 
	C1125.996338,800.373230 1125.954956,801.734192 1125.905640,803.503662 
	C1124.597778,805.182251 1125.347046,807.062561 1124.340576,808.764771 
	C1124.957397,817.055176 1127.671387,823.863831 1134.208008,829.279541 
	C1141.812256,835.579590 1149.859985,834.439941 1158.268921,832.398682 
	C1158.752930,832.281250 1159.217407,831.984253 1159.697998,831.966736 
	C1162.477539,831.865295 1165.343750,827.823181 1168.034912,832.156433 
	C1170.833008,836.661926 1170.528931,841.268738 1167.037354,844.978699 
	C1166.025879,846.053406 1164.803955,846.929993 1163.344238,847.970703 
	C1162.523804,847.941589 1162.093872,847.730408 1161.544189,847.082092 
	C1160.968750,843.898132 1161.890625,840.985413 1161.012695,837.863892 
	C1160.493408,837.135376 1159.991821,837.038269 1159.093994,837.305359 
	C1155.120483,838.507935 1151.456421,839.589661 1147.700806,840.200623 
	C1138.462769,841.703613 1131.107422,838.525757 1125.755859,830.868591 
	C1121.912109,825.368958 1119.296509,819.394592 1119.385742,812.137085 
z"/>
<path fill="#0C161B" opacity="1.000000" stroke="none" 
	d="
M1174.259277,852.643799 
	C1174.199097,853.390808 1173.782349,853.920532 1173.338013,854.711060 
	C1173.609619,856.049683 1173.015991,856.775757 1172.050659,857.681396 
	C1170.464600,858.441345 1169.370239,859.244934 1168.377930,860.747192 
	C1167.332520,861.687683 1166.435425,862.153320 1165.029297,862.484314 
	C1157.501709,866.145569 1149.949585,867.401184 1142.031982,866.695923 
	C1137.323853,866.276550 1132.877441,865.268555 1129.359863,861.137756 
	C1128.484863,855.239929 1131.612061,850.235535 1138.114258,847.012390 
	C1140.762939,847.057129 1142.799438,847.593384 1144.222534,850.019653 
	C1143.463623,852.520508 1142.343262,854.252441 1139.625977,854.021973 
	C1138.855347,853.956604 1137.833008,853.935364 1137.595825,854.989502 
	C1137.329346,856.173218 1138.220581,857.023438 1139.082764,857.294678 
	C1143.651123,858.731567 1148.283325,860.530090 1153.130493,859.698120 
	C1159.313232,858.636902 1165.614380,857.640686 1170.645142,853.256287 
	C1171.489624,852.520447 1172.649536,852.041626 1174.259277,852.643799 
z"/>
<path fill="#060D0E" opacity="1.000000" stroke="none" 
	d="
M1152.542969,793.985352 
	C1153.154175,794.772339 1153.118286,795.764526 1154.030640,795.991699 
	C1154.875610,796.202271 1154.951660,795.392456 1154.949829,794.769104 
	C1154.944702,793.010132 1154.908203,791.251282 1154.923340,789.025269 
	C1158.885864,797.303528 1161.701904,806.284729 1161.067505,816.053772 
	C1160.911377,818.459045 1160.945068,820.821533 1157.242432,820.990845 
	C1156.529297,816.584045 1155.318726,812.362244 1153.873047,807.856812 
	C1153.559570,806.557373 1153.652710,805.490967 1152.852783,804.387207 
	C1152.559937,804.014343 1152.443726,803.866150 1152.147949,803.503784 
	C1151.614990,802.861877 1151.240601,802.458801 1150.475830,802.085510 
	C1150.063843,801.853271 1149.900269,801.756470 1149.496338,801.505432 
	C1148.647095,800.891785 1148.022949,800.468567 1146.921631,800.469604 
	C1146.037354,800.505310 1145.445923,800.490295 1144.557739,800.478210 
	C1143.451538,800.473328 1142.721924,800.723267 1141.789551,801.303467 
	C1141.163330,801.583862 1140.790039,801.691406 1140.120361,801.770996 
	C1139.467285,801.774963 1139.098145,801.750244 1138.460571,801.703491 
	C1138.012939,801.676575 1137.834717,801.659058 1137.400024,801.565186 
	C1136.861328,801.268982 1136.634644,801.003052 1136.361084,800.444946 
	C1134.973022,796.660400 1136.572998,794.282898 1140.719360,794.048401 
	C1144.494263,793.834778 1148.289673,793.983582 1152.542969,793.985352 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1148.501953,822.170349 
	C1140.401123,822.553406 1135.718384,817.496521 1134.789307,807.374512 
	C1136.050049,805.986816 1137.459717,805.797607 1138.559204,806.694580 
	C1141.891235,809.413391 1145.387085,812.163818 1147.026489,816.236145 
	C1147.719116,817.956848 1146.538208,820.366882 1148.501953,822.170349 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M1144.615845,850.386719 
	C1142.890503,849.354126 1140.689209,849.683838 1139.440430,847.590027 
	C1138.441040,846.793884 1138.351440,846.052246 1138.790771,844.892090 
	C1140.149170,843.704834 1141.456787,843.613220 1143.186523,843.726624 
	C1146.018677,844.627136 1148.427368,845.021118 1151.018555,843.017639 
	C1151.930298,842.708374 1152.481079,842.631592 1153.484131,842.627991 
	C1157.542969,842.893372 1158.682617,845.007446 1158.036377,848.784180 
	C1154.501709,851.833740 1150.627808,851.940430 1146.291504,851.122559 
	C1145.599731,850.661926 1145.250610,850.514404 1144.615845,850.386719 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M1143.556152,800.083862 
	C1144.503418,799.555054 1145.286133,799.359314 1146.477905,799.165588 
	C1147.682007,799.831970 1148.744385,799.971924 1149.822632,800.736572 
	C1150.058472,800.929504 1150.498535,801.000671 1150.718018,801.039734 
	C1151.532104,801.535339 1152.050903,802.072937 1152.749023,802.825562 
	C1152.927002,803.041870 1152.969849,803.012024 1152.962280,803.036865 
	C1153.732056,803.752686 1153.857300,804.838440 1154.650391,805.871155 
	C1155.651123,810.825806 1159.270874,815.075134 1156.953735,820.635010 
	C1156.427124,821.011902 1156.023193,821.013977 1155.314941,821.022949 
	C1153.217896,819.821228 1153.707031,817.729736 1153.110229,816.062195 
	C1151.524170,811.631165 1149.564575,807.446411 1145.705322,804.495117 
	C1144.325806,803.440125 1143.133667,802.362366 1143.556152,800.083862 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M1143.438110,799.627380 
	C1145.495728,804.130432 1150.510620,805.715698 1152.381836,810.291382 
	C1153.718872,813.560974 1155.131470,816.606018 1155.006592,820.605347 
	C1154.253662,821.397156 1153.497070,821.764526 1152.413696,822.119263 
	C1150.808960,821.093201 1150.711426,819.584961 1150.321655,818.153076 
	C1148.960449,813.151245 1146.731812,808.641541 1142.648682,805.228821 
	C1141.531128,804.294800 1140.232788,803.387939 1140.774414,801.281433 
	C1141.103882,799.583618 1142.025513,799.390198 1143.438110,799.627380 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M1148.670654,822.609253 
	C1145.851196,818.111267 1144.278320,813.147644 1140.016724,809.790649 
	C1138.614990,808.686584 1137.712524,807.008423 1135.266602,806.987427 
	C1134.821777,806.528931 1134.752319,806.088806 1134.608398,805.319641 
	C1134.878540,804.467468 1135.223389,803.944397 1135.832520,803.250977 
	C1138.272705,802.042542 1140.082642,803.204651 1141.541016,804.425049 
	C1146.869751,808.884277 1150.841187,814.157227 1150.416748,821.972839 
	C1149.842896,822.455750 1149.418457,822.571472 1148.670654,822.609253 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M1150.575195,822.360596 
	C1148.446533,814.087585 1145.100464,806.931580 1136.384033,803.255493 
	C1136.211060,802.660339 1136.325317,802.240051 1136.608032,801.512207 
	C1136.982910,801.150024 1137.189209,801.095459 1137.715698,801.033203 
	C1138.194946,800.885925 1138.354126,800.746277 1138.776123,800.785156 
	C1139.474365,800.930176 1139.910034,800.896851 1140.669678,800.917786 
	C1140.994995,803.017090 1142.922852,803.472595 1144.049194,804.521545 
	C1148.932983,809.069885 1151.758545,814.541992 1152.060913,821.683472 
	C1151.685669,822.198181 1151.284302,822.289612 1150.575195,822.360596 
z"/>
<path fill="#0C161B" opacity="1.000000" stroke="none" 
	d="
M1146.030518,851.198242 
	C1149.490723,850.431946 1153.032593,850.054504 1156.782227,849.330078 
	C1157.631104,848.430542 1158.109985,847.704895 1159.128174,847.099731 
	C1160.026978,846.707397 1160.658447,846.595154 1161.604004,846.825195 
	C1162.174194,847.179871 1162.457886,847.383118 1162.875732,847.815186 
	C1158.044800,852.408752 1152.486206,853.942871 1146.030518,851.198242 
z"/>
<path fill="#171D2F" opacity="1.000000" stroke="none" 
	d="
M1124.486694,808.934204 
	C1124.215088,807.356812 1123.308228,805.450317 1125.552002,804.032349 
	C1125.776978,805.598511 1126.716553,807.515503 1124.486694,808.934204 
z"/>
<path fill="#1A1F34" opacity="1.000000" stroke="none" 
	d="
M1050.803711,601.759644 
	C1045.609009,612.916687 1040.232910,623.833862 1034.826172,635.091553 
	C1033.056763,638.943359 1031.872559,642.688782 1030.085693,646.551025 
	C1029.536743,647.658691 1028.833374,648.267212 1028.444336,649.444336 
	C1027.825684,651.487732 1027.074707,653.111938 1025.962402,654.933105 
	C1024.793823,656.356750 1023.691772,657.427368 1023.261108,659.307617 
	C1022.539795,661.309021 1021.712463,662.905579 1020.870117,664.863586 
	C1019.242615,666.866638 1017.989014,668.691345 1017.397278,671.267334 
	C1017.218750,672.075684 1017.052551,672.522522 1016.665833,673.276123 
	C1012.352478,679.480591 1009.788879,686.203064 1006.397217,692.483215 
	C998.431274,707.233337 991.986206,722.767151 983.439941,737.219421 
	C981.933655,739.766602 980.602478,742.588440 976.872314,743.372437 
	C967.761169,744.980652 958.966980,746.155579 950.409119,748.546326 
	C947.889221,749.250305 945.406067,750.297302 942.308594,749.548706 
	C941.290588,748.779846 941.022156,748.042053 941.176880,746.796509 
	C944.863098,742.884888 949.646057,740.382812 950.886658,734.828003 
	C951.177002,733.528137 952.622620,732.844360 953.860840,732.318420 
	C955.965027,731.424500 957.424133,729.949402 958.327698,727.855896 
	C958.792969,726.778015 959.425476,725.710571 960.503357,725.189941 
	C965.142090,722.948975 967.779114,719.793091 967.009521,714.161133 
	C966.608093,711.223938 969.515076,709.663269 971.448425,707.892456 
	C976.292297,703.455994 976.376099,703.453491 973.800720,697.575867 
	C972.726929,695.125183 973.386963,693.370361 975.443237,691.996277 
	C978.840088,689.726379 980.995239,686.784241 980.921326,682.520630 
	C980.912720,682.025940 980.901062,681.356567 981.214417,681.076843 
	C986.435608,676.414856 987.670044,669.096924 992.406372,664.186096 
	C995.815369,660.651489 998.104370,656.700745 997.906738,651.806030 
	C997.593201,644.039246 999.951233,638.123169 1007.799866,635.378296 
	C1010.071228,634.583923 1011.113037,632.751831 1011.784424,630.590637 
	C1014.818909,620.823425 1019.918030,612.049438 1025.653564,603.738159 
	C1030.172241,597.190369 1035.886108,591.534607 1042.146851,586.535828 
	C1044.674927,584.517456 1046.851318,585.271667 1048.439453,587.797791 
	C1050.050415,590.360657 1051.042114,593.176147 1050.999023,596.635498 
	C1051.000000,597.000000 1050.985962,597.498474 1050.980957,597.747803 
	C1050.695923,599.155029 1050.178833,600.289185 1050.803711,601.759644 
z"/>
<path fill="#0E1B28" opacity="1.000000" stroke="none" 
	d="
M1051.255615,596.744690 
	C1049.670776,594.026001 1048.385010,591.031555 1046.997070,588.085144 
	C1046.063843,586.103882 1044.786743,585.879150 1043.095093,587.411560 
	C1035.038330,594.709839 1027.146851,602.362671 1022.044495,611.936646 
	C1018.579468,618.438293 1014.295715,624.802246 1012.957092,632.340576 
	C1012.496399,634.934814 1010.912231,636.234131 1008.401733,636.997803 
	C1002.092102,638.917053 998.716736,644.669678 999.655151,651.448242 
	C1000.265259,655.855347 996.583923,663.890503 992.611328,665.879395 
	C990.599792,666.886475 989.903015,668.151306 990.012512,670.150085 
	C990.218628,673.912537 988.692932,676.524658 985.139465,677.988525 
	C983.277710,678.755432 982.016357,680.564758 982.278381,682.326050 
	C983.115601,687.953064 980.510132,691.158020 975.726135,693.364929 
	C973.741089,694.280640 973.886963,695.519409 975.363647,697.266113 
	C979.162659,701.759644 978.751526,703.740540 974.273315,707.481018 
	C970.834900,710.353027 967.114319,712.745117 968.696228,718.750366 
	C969.554626,722.008606 965.643066,724.248047 962.689636,725.849121 
	C961.859253,726.299377 960.477234,726.067749 960.236328,727.221802 
	C959.393311,731.260132 956.986450,733.485535 952.982727,734.341309 
	C952.302185,734.486877 951.461365,735.843750 951.415222,736.680725 
	C951.095276,742.488708 946.588379,744.107788 941.967041,746.075562 
	C940.219910,742.691528 943.319946,740.442261 943.969360,737.165649 
	C949.884216,728.647156 953.834656,719.466370 958.467529,710.682068 
	C971.558533,685.860718 983.943604,660.674927 996.640686,635.650085 
	C1000.760803,627.529663 1004.672424,619.302856 1008.948792,611.261414 
	C1009.659363,609.925110 1010.430908,608.654968 1012.251770,607.952881 
	C1014.958984,608.542480 1017.373718,609.341675 1017.153320,605.366943 
	C1017.128723,604.923584 1017.404236,604.152710 1017.713928,604.048340 
	C1022.020874,602.597839 1022.332581,598.443787 1023.789978,595.109802 
	C1024.937744,592.484131 1028.685425,594.756470 1029.674438,591.643799 
	C1030.763916,588.215332 1033.512817,585.872864 1036.042480,583.453064 
	C1036.403442,583.107727 1036.724121,582.712219 1037.117188,582.409790 
	C1040.085083,580.125854 1043.298706,577.982971 1047.158081,579.365906 
	C1050.921753,580.714539 1050.983154,584.536865 1051.347168,587.786682 
	C1051.668701,590.657715 1051.476685,593.586243 1051.255615,596.744690 
z"/>
<path fill="#266E80" opacity="1.000000" stroke="none" 
	d="
M1012.711182,607.566528 
	C1004.939270,622.826965 997.231934,637.720764 989.574768,652.640259 
	C975.838379,679.404602 962.138184,706.187561 948.395142,732.948547 
	C947.718018,734.267029 946.776550,735.449829 945.701355,736.894897 
	C945.204529,737.049988 944.964417,737.005920 944.515320,736.661316 
	C943.231079,732.115417 946.714233,729.254333 947.646484,725.249512 
	C949.160706,720.951294 952.100708,717.796082 953.730591,713.553345 
	C954.091980,712.835449 954.321472,712.439392 954.742432,711.761719 
	C955.277588,710.901794 955.670471,710.354370 956.192017,709.490723 
	C959.023560,705.849609 959.691162,701.421814 962.549683,697.812134 
	C963.640747,696.405334 964.492065,695.236511 964.606079,693.385071 
	C964.683472,692.641602 964.763672,692.219727 964.958130,691.485229 
	C965.272034,690.537598 965.583435,689.949829 966.108826,689.094360 
	C966.440125,688.641174 966.585632,688.469543 966.980957,688.071899 
	C969.915527,686.362793 970.055420,683.513062 970.996460,680.702820 
	C973.682190,675.412720 975.703674,670.169373 978.768433,665.462463 
	C981.134338,661.828979 982.633484,657.846069 983.584167,653.265381 
	C985.306335,648.632263 988.536316,645.305176 990.827393,640.984375 
	C998.110718,626.039917 1005.402710,611.506897 1013.188843,597.226807 
	C1014.299866,595.189331 1015.177124,592.974548 1018.205017,591.808838 
	C1019.123047,591.952393 1019.286987,592.152283 1019.269897,592.695068 
	C1018.686646,594.370605 1018.284485,595.703247 1017.829590,597.380615 
	C1017.531433,598.777405 1017.081055,599.727539 1016.112061,600.732910 
	C1014.200562,602.707764 1013.489746,604.954956 1012.711182,607.566528 
z"/>
<path fill="#266E80" opacity="1.000000" stroke="none" 
	d="
M1037.728027,521.348389 
	C1038.826538,520.746460 1039.608887,520.433289 1040.705322,520.071533 
	C1041.476074,533.100220 1039.498901,545.881531 1035.592163,558.291870 
	C1033.912476,563.627441 1031.017334,568.580383 1028.386719,573.910034 
	C1027.541016,574.275330 1027.071899,574.136230 1026.513428,573.342896 
	C1025.347412,568.041748 1029.518921,564.851990 1030.617676,560.188904 
	C1034.646484,550.018250 1036.344727,539.849243 1036.218994,529.332275 
	C1036.187744,526.724976 1035.856323,524.020447 1037.728027,521.348389 
z"/>
<path fill="#056C86" opacity="1.000000" stroke="none" 
	d="
M1026.843506,572.640564 
	C1027.200073,573.282227 1027.419678,573.559570 1027.869141,573.974915 
	C1027.961670,574.930115 1027.824219,575.747375 1027.497070,576.812866 
	C1025.206543,577.947510 1025.287109,580.259033 1024.219971,581.824036 
	C1023.226929,583.280396 1022.636047,585.079651 1020.230103,585.509277 
	C1018.220459,584.926819 1018.171387,583.624878 1018.535889,582.301819 
	C1019.588074,578.482849 1021.553284,575.097900 1023.889709,571.950012 
	C1024.773926,570.758728 1025.995972,570.213806 1026.843506,572.640564 
z"/>
<path fill="#266E80" opacity="1.000000" stroke="none" 
	d="
M1019.685547,585.239868 
	C1022.055908,583.662537 1023.211365,581.624878 1023.884949,579.403137 
	C1024.331055,577.931763 1024.853638,576.969116 1026.907227,577.043945 
	C1026.617188,578.806091 1025.927002,580.551025 1025.076904,582.606567 
	C1024.092163,583.981018 1024.198975,585.434875 1023.155884,586.719238 
	C1022.046143,588.420837 1021.192505,589.917908 1020.156250,591.687073 
	C1019.815125,592.096313 1019.656555,592.233398 1019.246094,592.182495 
	C1018.994080,591.994385 1018.974487,591.982422 1018.965088,591.975586 
	C1018.268005,591.449646 1018.003662,590.708496 1017.853516,589.497803 
	C1017.968567,587.731018 1018.438049,586.509155 1019.685547,585.239868 
z"/>
<path fill="#0E1B28" opacity="1.000000" stroke="none" 
	d="
M1051.228638,601.755249 
	C1048.442993,601.123291 1048.394287,599.810547 1050.643799,598.075317 
	C1051.277344,598.048462 1051.578613,598.099731 1052.326172,598.256897 
	C1052.339111,599.412170 1051.905640,600.461548 1051.228638,601.755249 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M1023.259155,586.867554 
	C1022.923523,585.525818 1023.008606,584.151550 1024.617676,583.083618 
	C1024.857300,584.339600 1025.398926,585.962646 1023.259155,586.867554 
z"/>
<path fill="#162034" opacity="1.000000" stroke="none" 
	d="
M1016.216064,600.870972 
	C1016.330200,599.989014 1016.809814,599.062012 1017.533203,597.930176 
	C1017.924316,598.969360 1018.195312,600.260986 1016.216064,600.870972 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M1019.001831,592.256714 
	C1018.994080,591.994385 1019.482849,591.944397 1019.728271,591.951782 
	C1019.885193,592.274536 1019.716064,592.538086 1019.277466,592.893921 
	C1019.062317,592.864990 1019.035889,592.692017 1019.001831,592.256714 
z"/>
<path fill="#12D1EB" opacity="1.000000" stroke="none" 
	d="
M1034.963623,505.139557 
	C1030.984985,505.198975 1028.237061,502.325134 1024.202026,500.968109 
	C1023.426575,500.981140 1023.255981,500.991058 1022.830017,500.959412 
	C1022.408386,500.867157 1022.242004,500.816559 1021.832275,500.669373 
	C1021.264099,500.430939 1020.939941,500.287415 1020.356934,500.044647 
	C1019.919373,499.880157 1019.741272,499.813690 1019.290161,499.644958 
	C1018.636780,499.394653 1018.261292,499.234955 1017.595154,498.919983 
	C1010.138489,493.103149 1002.039185,488.972015 994.064209,484.636292 
	C992.464966,483.766815 990.937683,482.786560 989.262207,481.440125 
	C988.395691,480.683167 987.888428,480.022522 986.949463,479.415344 
	C986.492493,479.225647 986.309814,479.147522 985.851685,478.951935 
	C985.204163,478.672516 984.834412,478.505554 984.181030,478.205200 
	C983.330261,477.772308 982.838684,477.373413 982.190186,476.666809 
	C981.397156,475.087280 980.363220,474.607147 978.613525,474.810669 
	C977.539795,474.625153 976.952026,474.253113 976.401550,473.280579 
	C976.226807,472.430878 976.272705,471.941162 976.622314,471.148071 
	C979.770996,468.686707 982.408142,469.859070 985.113342,471.484344 
	C991.793579,475.497803 997.933777,480.313721 1004.858398,484.011292 
	C1010.308594,486.921570 1016.119812,488.959778 1021.537354,491.883698 
	C1023.698547,493.050110 1025.851318,494.119904 1027.598022,496.804993 
	C1024.124268,482.032562 1018.715210,468.640808 1013.345642,455.221191 
	C1011.690613,451.084930 1008.005737,449.858673 1004.622009,448.408752 
	C1000.694946,446.726013 997.221191,444.895447 994.878540,441.095917 
	C993.155396,438.301178 990.053284,437.048676 987.080688,435.952118 
	C979.405029,433.120605 971.841492,430.202942 965.927673,424.143402 
	C964.083435,422.253693 961.412720,421.329712 958.979614,420.265228 
	C953.182129,417.728729 947.353271,415.300446 942.697693,410.786987 
	C941.618347,409.740540 940.141541,409.132355 938.645630,408.958313 
	C931.417419,408.117737 926.124451,403.856750 921.177002,399.068359 
	C919.188965,397.144165 916.963745,395.886566 914.337891,395.170502 
	C911.753479,394.465729 908.915161,393.772980 907.278442,391.656677 
	C903.098389,386.251678 897.589050,382.289368 892.849976,377.515106 
	C891.038757,375.690460 889.903687,375.278687 887.947998,377.005890 
	C883.199219,381.199951 878.496887,385.390106 875.353882,391.043488 
	C874.156921,393.196564 872.848877,395.463287 869.529541,395.506409 
	C868.993469,395.240143 868.810364,395.076813 868.486633,394.572998 
	C869.393616,389.227539 872.535278,385.281799 875.347046,381.283813 
	C878.594177,376.666809 881.836670,372.057556 885.030640,366.969818 
	C887.486267,363.859283 889.422668,360.793335 892.313049,358.028931 
	C893.281250,357.393127 893.995605,357.245605 895.119263,357.622406 
	C903.855469,360.674469 911.120300,365.720703 918.775879,370.016052 
	C930.370605,376.521637 942.131287,382.763947 953.447021,389.726593 
	C969.752075,399.759247 986.635681,408.734985 1003.325134,418.069275 
	C1005.548157,419.312561 1006.417969,421.477600 1007.200867,423.690033 
	C1016.301636,449.408142 1025.401611,475.126526 1034.453003,500.862061 
	C1034.882324,502.082611 1034.853760,503.464111 1034.963623,505.139557 
z"/>
<path fill="#069BB9" opacity="1.000000" stroke="none" 
	d="
M892.753784,358.632812 
	C890.918884,361.572540 888.847473,364.233978 886.454224,366.988220 
	C878.630310,371.497406 872.332336,369.756470 868.490967,362.039001 
	C867.030029,359.103821 865.144775,356.706146 862.172241,355.271484 
	C858.540649,353.518677 856.602844,350.629486 856.421021,346.631348 
	C856.276001,343.443054 854.615295,342.120697 851.557617,341.408020 
	C847.392456,340.437286 843.335693,338.957947 839.226318,337.730591 
	C834.062073,336.188202 829.745117,333.056122 827.166077,327.967316 
	C826.134949,325.932892 825.097046,323.574280 822.025574,326.053040 
	C820.424194,327.345490 818.939636,325.952148 817.537781,324.377991 
	C816.437744,321.490051 817.223450,319.282959 819.038086,316.952026 
	C821.361084,315.103027 823.292114,316.278107 825.630249,316.903015 
	C826.918884,317.363220 827.821411,317.817932 828.902649,318.523468 
	C829.357727,319.403046 829.858826,319.739502 830.830444,319.864380 
	C831.641113,320.067841 832.135132,320.282776 832.800171,320.710297 
	C839.331604,326.943634 847.851562,329.422394 854.902771,334.265198 
	C862.945496,339.788971 871.486816,344.454529 879.788635,349.530212 
	C880.775085,350.133331 881.723938,350.879059 882.397461,351.809082 
	C884.935547,355.313599 888.299438,357.478363 892.753784,358.632812 
z"/>
<path fill="#266E80" opacity="1.000000" stroke="none" 
	d="
M788.991394,295.000793 
	C799.769470,301.105927 810.179321,307.271088 820.798096,313.705261 
	C821.126648,314.187683 821.246216,314.401093 821.213379,314.946716 
	C819.534241,316.454498 817.874084,316.261871 816.344116,315.468201 
	C808.689148,311.497162 800.087463,309.294464 793.456726,303.426208 
	C791.568970,301.755432 790.677612,302.778320 789.452820,304.176941 
	C786.558533,307.481689 784.646057,311.402740 782.613220,315.198547 
	C777.578735,324.599030 771.345398,333.287476 766.476135,342.775604 
	C764.010620,347.579803 764.390076,349.309113 768.878052,352.336700 
	C773.439636,355.413940 778.286743,358.072998 783.142700,360.601288 
	C789.193298,363.751648 794.674866,367.914490 801.281128,370.757019 
	C801.879883,371.750305 801.949402,372.492004 801.612610,373.612610 
	C799.199097,375.927307 797.024353,374.706970 795.002991,373.633698 
	C783.118225,367.323547 771.294373,360.901245 759.862793,353.786377 
	C758.720764,353.075623 757.596802,352.376984 757.084106,350.622742 
	C757.492798,344.538391 760.942383,340.102631 763.724670,335.493469 
	C771.076416,323.314667 778.245422,311.029510 785.519836,298.806122 
	C786.354004,297.404419 786.956604,295.784149 788.991394,295.000793 
z"/>
<path fill="#071518" opacity="1.000000" stroke="none" 
	d="
M789.158997,294.719238 
	C780.324646,310.419373 771.266724,325.884735 762.276855,341.389557 
	C760.782715,343.966583 759.584961,346.715546 758.066650,349.695435 
	C757.470764,350.384857 756.987488,350.535431 756.078003,350.282776 
	C753.521667,345.998810 756.492737,342.675110 757.844788,339.418701 
	C761.396179,330.865753 765.806213,322.688477 770.217651,314.527740 
	C772.662415,310.005188 774.776550,305.298218 776.565430,300.453613 
	C777.987488,296.602417 780.142700,293.322205 784.748779,291.960632 
	C785.689514,292.007050 786.192932,292.180664 786.922119,292.769470 
	C787.776062,293.550537 788.367126,294.024139 789.158997,294.719238 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M893.250977,358.686401 
	C888.478577,359.528625 885.402588,357.870331 882.448914,354.164368 
	C877.841492,348.383392 870.245850,346.481903 864.476562,342.184357 
	C855.590271,335.565125 845.760132,330.689240 836.147827,325.372589 
	C834.736267,324.591858 833.497314,323.365204 833.015808,321.276672 
	C845.797058,326.807281 857.575378,334.562927 869.682068,341.709045 
	C878.219910,346.748566 887.164612,351.150269 895.293213,357.462585 
	C894.819580,357.992157 894.165588,358.226929 893.250977,358.686401 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M946.685974,368.168640 
	C948.147827,369.197968 948.240417,370.422821 946.472229,370.830475 
	C944.479065,371.290009 943.888062,372.413239 944.002869,374.613770 
	C942.348633,375.595428 940.570435,375.969482 939.249695,377.812073 
	C932.801880,373.739105 924.999695,371.913574 919.913940,365.416901 
	C926.903198,368.772125 935.055420,367.153076 942.367126,369.655670 
	C944.078003,370.241302 945.181458,369.211945 946.685974,368.168640 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M901.940430,355.402954 
	C904.693359,354.806915 908.364136,357.877106 910.086914,352.820831 
	C910.449829,351.755737 912.134094,351.930450 912.928711,353.154755 
	C913.645447,354.259003 914.602783,355.807617 913.013550,356.580536 
	C907.111633,359.451050 911.403809,359.546234 913.904602,360.018005 
	C915.292175,360.279755 915.906860,360.982269 915.986084,362.700928 
	C910.310120,362.608826 905.847473,359.792297 901.940430,355.402954 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M944.680176,376.235077 
	C946.264465,376.789368 947.434082,377.933624 948.811096,378.301758 
	C952.995605,379.420502 954.240540,382.253479 953.999634,386.576996 
	C950.761902,384.897919 947.524109,382.815399 944.086304,380.453552 
	C943.220093,378.834076 943.502930,377.615356 944.680176,376.235077 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M944.879761,375.790771 
	C944.735779,377.166931 944.471558,378.333862 944.046753,379.837524 
	C942.184875,380.217133 940.844299,379.403473 939.298523,378.206665 
	C939.241394,375.314697 938.475830,371.260590 943.703918,374.844666 
	C944.304565,375.126740 944.557678,375.320587 944.879761,375.790771 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M888.933350,349.415833 
	C891.897156,350.775513 897.481140,348.006561 897.991821,354.619934 
	C895.558167,352.321869 890.905640,353.414948 888.933350,349.415833 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M787.470825,292.920929 
	C786.804382,292.980560 786.423767,292.884186 785.749207,292.779358 
	C784.355652,292.670258 783.434631,292.267639 782.820679,290.938324 
	C786.701050,287.653168 789.497559,291.037720 792.835327,292.717346 
	C791.237366,293.427612 789.566650,291.717529 787.470825,292.920929 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M820.677979,315.199158 
	C820.921814,314.828857 820.932922,314.657410 820.975586,314.230133 
	C822.833740,314.234314 824.172363,315.443359 825.837463,316.637024 
	C824.301758,318.634857 822.540527,316.390228 820.446777,316.959534 
	C819.647278,316.301971 819.956970,315.829041 820.677979,315.199158 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M830.960999,319.611389 
	C830.186096,320.949585 829.487366,320.856781 829.085327,319.086853 
	C829.645874,318.972809 830.210449,319.171326 830.960999,319.611389 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M916.913818,363.389099 
	C917.780823,363.088501 918.592468,363.333038 918.946960,364.722229 
	C918.103027,364.951202 917.330017,364.657593 916.913818,363.389099 
z"/>
<path fill="#045B70" opacity="1.000000" stroke="none" 
	d="
M1037.770508,520.823486 
	C1038.184570,531.639526 1038.191162,542.184448 1035.169678,552.504822 
	C1034.373047,555.226074 1033.364014,557.782288 1032.050903,560.644592 
	C1026.006104,566.264709 1025.975342,566.149536 1020.825317,560.175293 
	C1016.977417,555.711548 1013.217773,551.388916 1006.951660,549.509277 
	C1001.842163,547.976624 997.161987,544.747437 992.752319,541.454895 
	C988.393494,538.200378 987.727783,538.275085 984.271057,542.424255 
	C982.377258,544.697388 980.350037,545.963440 977.115112,544.333740 
	C975.600525,541.675598 977.277649,539.976868 978.315063,538.152222 
	C982.170837,531.370422 986.224487,524.710388 989.363892,517.525574 
	C990.549500,514.812195 989.655579,511.412384 992.632629,509.126038 
	C1000.542114,507.063843 1003.164124,508.126312 1007.602234,514.915405 
	C1009.879395,518.398987 1017.497070,520.088135 1021.512268,517.950073 
	C1022.809326,517.259460 1024.171753,515.712646 1025.528809,516.496094 
	C1031.067627,519.693665 1032.498901,516.213013 1033.498657,511.220184 
	C1035.803589,514.375732 1035.407227,518.041809 1037.770508,520.823486 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M992.986938,508.533295 
	C990.986511,521.951904 983.243164,532.238159 977.010864,543.934265 
	C976.185913,547.473572 974.453003,549.886536 973.160339,552.873535 
	C972.506531,554.166565 971.876709,555.082642 971.089294,556.290222 
	C970.202881,557.406189 969.482910,558.237427 969.295166,559.733276 
	C967.639343,563.240051 966.554260,566.622681 964.844482,570.122559 
	C961.590027,572.840881 961.151672,576.727661 959.399780,579.875854 
	C952.156250,592.892578 944.278992,605.557434 937.508301,619.239868 
	C937.261536,619.773499 937.160217,619.984863 936.875732,620.493408 
	C933.452087,625.146118 931.643250,630.365051 928.170898,634.964294 
	C927.199951,636.507507 926.541199,637.834045 926.021057,639.576050 
	C925.755188,640.267700 925.580078,640.652222 925.267639,641.319580 
	C924.699402,642.564392 924.423279,643.570984 924.168091,644.928406 
	C924.015015,645.460327 923.937988,645.670410 923.701782,646.180054 
	C921.938782,648.107727 920.811707,650.080444 919.424011,652.267944 
	C918.784851,653.530396 918.358398,654.526306 917.776733,655.810791 
	C916.899170,657.181091 915.575562,657.765564 914.975891,659.386536 
	C914.422180,660.274719 913.964355,660.802612 913.122681,661.454590 
	C911.832153,661.980774 910.899048,661.916016 909.782471,661.053101 
	C909.318298,660.359802 909.175049,659.909851 909.144287,659.084961 
	C909.225464,658.517822 909.286621,658.297485 909.494263,657.771606 
	C909.864380,657.080261 910.094666,656.698242 910.478638,656.023193 
	C910.824646,655.323059 911.031738,654.924133 911.420166,654.229492 
	C917.906250,641.996033 923.856567,629.867493 930.756531,618.250122 
	C932.118530,615.957092 933.055420,613.503296 933.931030,610.641541 
	C935.585205,608.438049 936.727478,606.344177 937.653564,603.767578 
	C937.896362,603.298462 938.003540,603.118164 938.288269,602.683289 
	C938.964539,601.809631 939.537170,601.235107 939.782593,600.122070 
	C939.962280,599.657837 940.044128,599.476013 940.277222,599.036377 
	C940.865173,598.124329 941.496582,597.622803 942.028442,596.669800 
	C942.464600,595.881714 942.763855,595.356934 943.152222,594.522888 
	C944.191406,592.246155 945.624207,590.593445 946.950500,588.472290 
	C948.678833,586.150696 948.784729,583.327820 951.000366,581.281738 
	C954.451965,578.980286 954.949829,575.491089 956.015747,571.902161 
	C961.107544,561.817017 965.520752,551.771912 970.892822,542.247864 
	C973.637512,537.381958 978.034546,533.464600 980.099609,528.121155 
	C982.718506,521.344543 987.045959,515.301453 988.276489,507.891296 
	C988.711792,505.269897 988.754150,503.021301 987.592224,500.314270 
	C986.383423,497.196716 986.277588,494.305084 986.284058,491.006714 
	C986.135681,487.891266 984.040466,485.860748 983.661682,483.201660 
	C983.408264,481.422455 982.657349,479.650635 984.253784,477.774292 
	C984.872498,477.587708 985.136292,477.576904 985.707397,477.815918 
	C986.014771,478.065765 986.318604,478.234772 986.475952,478.308685 
	C987.510559,478.393036 987.967896,479.088593 988.762268,479.775024 
	C996.526428,484.332886 1004.065735,488.720032 1011.663879,493.002777 
	C1014.102905,494.377625 1016.301819,496.020172 1018.680054,497.994202 
	C1019.290710,498.342804 1019.592468,498.567413 1020.196533,498.771851 
	C1020.479187,498.777771 1020.785645,498.784821 1020.892578,498.894562 
	C1021.233459,499.272339 1021.498901,499.504822 1022.042847,499.793060 
	C1022.293945,499.879730 1022.692139,499.938416 1022.846436,500.067627 
	C1023.185730,500.316711 1023.370789,500.436584 1023.827759,500.744812 
	C1024.099609,500.933167 1024.067993,500.928680 1024.084229,500.912781 
	C1024.479248,504.936951 1022.982056,506.101624 1019.201843,504.386383 
	C1015.042969,502.499359 1011.165894,499.879730 1006.880493,498.414520 
	C1002.702454,496.986023 999.597717,494.614197 996.867676,491.333923 
	C996.033447,490.331512 994.984863,488.636200 993.187561,489.705902 
	C991.661072,490.614380 991.766846,492.376801 992.052612,493.764191 
	C993.029175,498.504395 993.074951,503.277161 992.986938,508.533295 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M1046.721313,866.338318 
	C1048.854004,863.433716 1046.891602,859.482605 1050.307373,857.041992 
	C1053.413086,858.208740 1053.794312,860.659485 1053.632324,863.672852 
	C1053.223511,864.916504 1051.856812,865.543152 1052.446289,866.572021 
	C1053.279663,868.026367 1055.081055,868.268372 1056.359985,867.884216 
	C1058.674805,867.189087 1061.072876,866.630188 1063.116455,865.051514 
	C1066.794189,862.210754 1070.713745,861.238831 1074.827515,864.414917 
	C1076.235474,865.502075 1077.785034,865.899475 1079.422852,864.121704 
	C1079.687744,863.637207 1079.789917,863.441895 1080.073975,862.972412 
	C1080.898315,861.822693 1081.849609,861.259583 1082.761230,860.203857 
	C1083.156250,859.676331 1083.334229,859.381653 1083.735107,858.946899 
	C1084.414185,858.827209 1084.870483,858.847534 1085.664062,858.936157 
	C1087.725464,861.928711 1085.838257,864.101318 1084.309814,866.709229 
	C1083.537598,868.066162 1082.762939,868.979980 1081.822998,870.220703 
	C1080.895508,871.187195 1080.147705,871.815613 1079.015381,872.554810 
	C1078.166260,872.837402 1077.656860,872.882812 1076.755249,872.776611 
	C1071.547363,869.420471 1066.717529,870.874756 1062.354248,873.343201 
	C1058.301636,875.635864 1054.240356,876.891663 1049.631348,876.691101 
	C1046.072632,876.536255 1042.988403,877.328674 1040.159668,879.980652 
	C1036.982910,882.958862 1032.642700,883.908630 1028.567139,882.294739 
	C1024.518921,880.691772 1022.806946,877.041931 1022.995972,872.640747 
	C1023.063721,871.062012 1022.475647,869.737427 1021.221558,868.268677 
	C1017.259399,858.553162 1014.520630,848.859741 1012.973633,838.422791 
	C1016.232727,836.468262 1017.749451,838.905273 1019.482239,840.853088 
	C1020.724121,842.249146 1021.196960,844.044128 1022.189270,845.968384 
	C1022.591919,846.680420 1022.755676,847.111694 1022.935547,847.885132 
	C1023.123047,849.047607 1023.254395,849.883545 1023.893433,850.896484 
	C1024.710815,852.374512 1025.086426,853.645752 1025.061523,855.352783 
	C1024.441772,857.542175 1025.076782,859.065613 1026.481201,860.726929 
	C1026.797607,861.462646 1026.897949,861.910034 1026.952881,862.716431 
	C1026.506958,866.776489 1027.249634,870.189331 1029.255981,873.388794 
	C1031.768066,877.394714 1033.807861,877.693054 1038.048950,874.159729 
	C1039.068970,872.871887 1040.384033,872.236145 1040.732422,870.456055 
	C1041.935547,867.706604 1045.071167,868.513184 1046.721313,866.338318 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M1093.229492,832.672302 
	C1093.253662,837.034546 1092.537842,841.029175 1094.622559,845.030884 
	C1096.474731,848.586487 1093.182861,854.951904 1089.467041,856.013550 
	C1086.738892,846.232239 1084.316284,836.480713 1081.709961,826.361694 
	C1081.538452,825.781738 1081.550659,825.569214 1081.397705,825.083374 
	C1081.350464,824.572021 1081.468628,824.333984 1081.845215,823.790771 
	C1086.291382,821.887207 1089.156616,823.595398 1091.118164,827.234314 
	C1091.668701,828.255615 1092.038696,829.351074 1093.209229,830.428955 
	C1093.594360,831.290466 1093.606689,831.815735 1093.229492,832.672302 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M1083.279053,867.214539 
	C1083.306396,864.287903 1086.126099,862.633606 1085.989868,859.373535 
	C1086.471436,858.667236 1086.941528,858.330200 1087.701660,857.968872 
	C1088.235596,858.759766 1088.479492,859.574890 1088.849609,860.685791 
	C1086.277954,861.620483 1088.848389,864.323914 1087.113037,865.684570 
	C1086.070923,866.974670 1085.135254,867.908752 1083.279053,867.214539 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M1117.262207,849.014771 
	C1117.454224,849.417969 1117.249878,849.705200 1116.881226,850.204834 
	C1116.678955,850.686646 1116.641113,850.956055 1116.306885,851.506592 
	C1115.041382,852.339783 1113.984497,852.501221 1112.499512,852.464966 
	C1107.817261,852.301208 1106.776611,849.004272 1105.914917,845.783936 
	C1103.655273,837.339478 1101.068359,828.990967 1098.671143,820.587402 
	C1097.772461,817.436890 1096.927490,814.035217 1092.229980,814.324463 
	C1090.964722,815.183716 1089.793213,815.485840 1088.580078,816.438904 
	C1088.054688,816.719116 1087.833008,816.811096 1087.257935,816.982971 
	C1086.162720,817.134216 1085.459595,817.010864 1084.528931,816.410034 
	C1083.519897,812.180420 1087.455566,807.647400 1092.294189,807.012024 
	C1093.576050,806.843750 1094.891968,806.934937 1096.622437,806.867310 
	C1102.457275,809.007751 1103.648438,814.273499 1104.788940,818.877502 
	C1106.983887,827.738586 1109.376465,836.523193 1111.967896,845.271912 
	C1112.785034,848.030396 1115.096680,847.896484 1117.262207,849.014771 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M1061.166260,814.994629 
	C1061.835083,815.168640 1062.328979,815.293091 1062.361206,815.492249 
	C1064.561401,829.093628 1069.237427,842.034912 1072.906128,855.243958 
	C1073.077759,855.861694 1072.947754,856.563171 1072.904053,857.640381 
	C1069.186768,858.958679 1069.289185,855.807068 1068.795410,853.891907 
	C1068.012451,850.855164 1067.332153,847.846252 1065.151611,845.053833 
	C1064.628052,844.699646 1064.430298,844.538635 1063.996582,844.074524 
	C1058.274170,835.173767 1058.263916,825.793579 1060.009155,815.847778 
	C1060.391235,815.330566 1060.583618,815.155273 1061.166260,814.994629 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M1064.816406,844.308838 
	C1068.650024,845.684753 1068.757935,849.461975 1069.932251,852.543823 
	C1070.617920,854.343018 1070.451660,856.472717 1072.510010,857.947754 
	C1073.433838,858.877686 1074.017822,859.700378 1074.811279,860.740723 
	C1076.368652,862.829529 1078.618042,862.793091 1080.752197,863.776978 
	C1079.901489,868.185791 1077.025391,867.755493 1074.553711,865.896790 
	C1070.303101,862.700439 1067.137939,863.976074 1063.223511,866.745422 
	C1060.316162,868.802368 1056.559082,870.651672 1052.590088,868.909302 
	C1049.717651,867.648499 1048.132568,866.008484 1052.520996,863.985229 
	C1057.827271,865.633606 1061.740356,862.754456 1065.765015,860.848633 
	C1068.094849,859.745544 1066.415283,857.309204 1066.830078,855.224731 
	C1067.095825,852.130798 1066.209351,849.499268 1065.124390,846.545959 
	C1064.600464,845.755310 1064.471191,845.236328 1064.816406,844.308838 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M1081.072998,863.809753 
	C1078.415039,864.798035 1075.765991,865.726746 1075.040039,861.321045 
	C1076.800171,860.755127 1078.579468,860.551758 1080.738403,860.381226 
	C1081.691772,861.025635 1081.800049,861.736694 1081.404053,862.864380 
	C1081.240234,863.208191 1081.136108,863.611389 1081.072998,863.809753 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M1081.546875,863.077881 
	C1081.189697,862.508667 1081.139282,861.809082 1081.103271,860.761780 
	C1081.525391,860.289856 1081.932861,860.165649 1082.659424,860.006226 
	C1083.039307,861.127869 1082.987305,862.242065 1081.546875,863.077881 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M1058.865967,815.024231 
	C1059.052368,813.885376 1059.571777,813.276550 1060.832031,813.086548 
	C1061.271240,813.479065 1061.394531,813.906311 1061.537109,814.671448 
	C1061.556519,815.009338 1061.245117,814.879089 1061.079102,814.909790 
	C1060.706665,815.276062 1060.409302,815.490784 1059.748535,815.582581 
	C1059.294312,815.474060 1059.125610,815.384949 1058.865967,815.024231 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M1086.750732,816.061035 
	C1087.052612,815.692139 1087.183716,815.576721 1087.562500,815.376465 
	C1092.164429,816.188599 1093.854736,819.688110 1095.364502,823.232178 
	C1098.949829,831.648438 1100.814941,840.669556 1104.365967,849.104980 
	C1106.676636,854.593933 1107.703491,855.193604 1114.460205,854.724060 
	C1113.615234,860.482788 1108.311157,861.851135 1104.286133,857.557007 
	C1102.215332,855.347717 1099.758301,853.001953 1099.731812,849.553101 
	C1099.692139,844.366760 1095.391602,840.390808 1095.747192,835.079102 
	C1095.813477,834.087524 1094.797363,833.396973 1093.376587,833.059265 
	C1092.984863,832.564270 1092.969604,832.128540 1092.933228,831.366455 
	C1092.500854,830.526245 1091.944336,830.042053 1091.825928,829.408813 
	C1090.921753,824.578674 1087.988525,822.029297 1082.916992,821.288086 
	C1082.613770,820.807556 1082.556519,820.579041 1082.640625,820.026733 
	C1083.125977,819.299011 1083.470093,818.895203 1083.964600,818.273071 
	C1084.696777,817.134766 1085.708008,816.823364 1086.750732,816.061035 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M1082.616089,821.043701 
	C1090.027100,820.602722 1091.867798,822.311462 1092.939941,830.644409 
	C1091.707397,830.880676 1090.544312,830.421875 1090.275024,829.188904 
	C1089.344849,824.929871 1086.265137,824.068909 1082.155029,824.073608 
	C1081.742676,824.088074 1081.312988,824.274536 1081.178223,824.083008 
	C1081.115479,823.508362 1081.187622,823.125366 1081.329346,822.457642 
	C1081.607422,821.754639 1081.907104,821.414551 1082.616089,821.043701 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M1086.583252,815.824829 
	C1086.683594,817.014465 1085.831177,817.612122 1084.436279,818.022461 
	C1084.118896,817.636353 1084.122437,817.217957 1084.189697,816.487183 
	C1084.917236,816.063843 1085.581055,815.952942 1086.583252,815.824829 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M1082.924072,820.632812 
	C1082.599609,821.319763 1082.251953,821.693542 1081.651611,822.120178 
	C1081.128540,821.245056 1081.210205,820.436157 1082.471436,819.812134 
	C1082.821655,819.908447 1082.861206,820.114014 1082.924072,820.632812 
z"/>
<path fill="#212C3D" opacity="1.000000" stroke="none" 
	d="
M1081.032104,824.051453 
	C1081.043335,823.891418 1081.375488,824.052307 1081.559082,824.070190 
	C1081.742676,824.088074 1081.500244,824.458130 1081.366333,824.634155 
	C1081.106689,824.630127 1081.036133,824.430542 1081.032104,824.051453 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1183.772217,738.739258 
	C1182.829346,738.677856 1182.322510,738.340942 1182.715454,737.634705 
	C1182.857300,737.379578 1183.562744,737.204590 1183.880371,737.328308 
	C1184.577271,737.599670 1184.641113,738.144592 1183.772217,738.739258 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1122.292480,383.969940 
	C1122.160889,382.511200 1122.321777,381.022430 1122.741455,379.266815 
	C1124.104370,379.856171 1123.979736,381.087219 1123.999390,382.637207 
	C1123.528320,383.313293 1123.056763,383.626587 1122.292480,383.969940 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M1136.844482,800.985046 
	C1136.776489,801.204529 1136.771729,801.217163 1136.780640,801.234375 
	C1136.668335,801.093506 1136.546997,800.935425 1136.337036,800.492371 
	C1136.588013,800.252441 1136.809448,800.438538 1136.844482,800.985046 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1246.390625,800.996826 
	C1246.332642,799.257629 1246.665283,797.515320 1246.998901,795.386475 
	C1249.169922,794.666077 1251.474609,793.070251 1253.660400,796.143127 
	C1250.013550,796.051636 1250.672974,801.325439 1246.390625,800.996826 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M1161.986572,846.577942 
	C1161.435547,847.293823 1160.980591,847.611084 1160.245361,847.936279 
	C1158.121704,845.929504 1158.421631,840.853516 1153.095825,843.498413 
	C1152.379761,843.696411 1151.982056,843.753479 1151.284546,843.840332 
	C1148.903564,844.576965 1146.730591,844.456970 1144.262817,844.604858 
	C1142.574585,844.720215 1141.208374,844.812927 1139.435059,844.969116 
	C1136.397217,847.005066 1134.499756,844.894409 1132.420898,843.835938 
	C1130.671631,842.945251 1129.532104,841.232056 1127.441650,840.196167 
	C1126.705200,839.775208 1126.345581,839.455750 1125.803955,838.809326 
	C1125.083984,838.010986 1124.688110,837.423523 1124.245605,836.453430 
	C1123.639404,834.600952 1122.781982,833.180420 1122.807739,831.168213 
	C1122.758667,830.623291 1122.700806,830.421692 1122.491943,829.943848 
	C1122.115356,829.632507 1121.889648,829.597229 1121.891357,829.305542 
	C1122.118530,829.049072 1121.967651,828.576233 1121.869507,828.348267 
	C1121.437012,827.433044 1120.891724,826.900879 1120.318359,826.046021 
	C1119.736572,824.765137 1119.271484,823.741882 1118.666260,822.404663 
	C1119.172485,818.761047 1117.228638,815.334167 1119.201904,811.895874 
	C1121.353394,817.880188 1122.803223,824.063721 1126.616089,829.536926 
	C1131.843994,837.041382 1139.586060,840.561584 1148.436401,838.428040 
	C1151.968750,837.576477 1155.480103,836.638367 1159.391968,835.659058 
	C1160.305298,835.387024 1160.836914,835.356567 1161.735596,835.679932 
	C1163.667358,839.310059 1163.629150,842.745483 1161.986572,846.577942 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M1120.249512,802.875854 
	C1120.904541,804.036377 1120.994263,805.303711 1120.001953,806.751892 
	C1118.503418,805.569031 1118.927002,804.264221 1120.249512,802.875854 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M1114.085938,808.924988 
	C1114.242432,809.086487 1114.345093,809.299194 1114.447632,809.511902 
	C1114.325073,809.474854 1114.202515,809.437805 1114.004639,809.242432 
	C1113.929199,809.084106 1114.031860,808.976196 1114.085938,808.924988 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M1170.909790,857.443115 
	C1171.791016,856.681580 1172.372192,856.085266 1173.131836,855.230347 
	C1173.783081,855.162537 1174.255859,855.353333 1174.866699,855.771362 
	C1174.300537,857.410095 1172.840210,858.468445 1172.991455,860.632690 
	C1172.262085,860.998718 1171.543823,861.017029 1170.466064,861.044128 
	C1169.937134,860.898193 1169.767700,860.743408 1169.492188,860.260315 
	C1169.316772,858.906128 1169.847778,858.196411 1170.909790,857.443115 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M238.317719,1022.199463 
	C237.906769,1019.838074 238.054565,1018.095032 240.982391,1017.901428 
	C243.850632,1017.711731 245.350922,1016.205811 246.248627,1013.013916 
	C247.288208,1009.317322 249.671967,1006.236450 248.336197,1001.857300 
	C247.687332,999.730103 250.574036,1000.166931 252.110825,999.996216 
	C257.214844,999.429260 259.731781,996.643799 258.941864,992.336548 
	C258.834045,991.748657 258.566101,991.031006 258.126434,990.700623 
	C257.468323,990.205933 256.795624,990.794922 256.483643,991.339966 
	C253.375656,996.769409 248.744736,995.257812 244.190887,994.080261 
	C243.285187,993.846069 242.296280,992.823792 241.507599,993.881592 
	C240.728271,994.926636 241.712906,995.630432 242.489517,996.294006 
	C244.620148,998.114258 244.248764,999.980591 242.576782,1001.908142 
	C240.816406,1003.937622 238.690674,1004.752197 236.420731,1003.125977 
	C233.046585,1000.708679 231.221863,1000.944336 230.612808,1005.461121 
	C230.266434,1008.029785 228.003555,1009.154358 225.799347,1010.094116 
	C224.105804,1010.816162 222.017914,1011.916626 220.773224,1010.247925 
	C219.682571,1008.785645 221.859909,1007.760132 222.787445,1006.733215 
	C224.592758,1004.734619 224.599655,1002.207458 225.316650,999.848267 
	C226.300568,996.610474 223.697418,996.057922 222.279282,994.382507 
	C219.878860,991.546448 222.069885,988.367249 222.658737,985.501953 
	C223.085526,983.425293 225.710953,983.965088 227.481354,984.062134 
	C230.701462,984.238586 230.887619,986.726868 230.986847,989.117432 
	C231.071533,991.157959 231.593613,992.714844 233.977203,992.941589 
	C235.981613,993.132141 237.128845,992.165955 237.672638,990.352112 
	C239.403244,984.579590 236.282974,969.998840 229.409729,967.141052 
	C227.008133,966.142456 225.394272,965.878418 224.070099,968.397156 
	C223.683075,969.133240 223.079819,969.786255 223.577957,970.666077 
	C224.179276,971.728149 225.102448,971.735046 226.163437,971.442139 
	C226.955978,971.223328 227.792252,971.053650 228.608704,971.055115 
	C229.984985,971.057678 231.515503,971.279358 231.530807,973.021240 
	C231.543533,974.469177 230.427597,975.327698 228.988281,975.657959 
	C226.258194,976.284546 219.894318,979.773926 217.784500,981.928711 
	C216.167191,983.580566 215.185150,982.659485 214.271408,981.241272 
	C210.282059,975.049683 211.310059,966.939758 216.790253,961.544739 
	C217.739517,960.610229 219.140396,959.768066 218.654419,958.296326 
	C218.031403,956.409607 216.152008,957.173828 214.751709,957.003357 
	C211.677704,956.629150 208.192947,958.035583 205.587067,954.540955 
	C211.136185,956.277283 214.687180,949.614441 220.441101,951.679932 
	C222.107880,952.278259 224.438950,950.909973 224.816269,948.512573 
	C225.215668,945.974976 222.806946,946.632751 221.616592,945.798950 
	C218.540741,943.644409 215.710144,941.524719 211.626511,941.465942 
	C210.043091,941.443176 208.877090,939.025208 209.269043,937.518250 
	C210.334549,933.421570 207.703415,933.927002 205.401306,934.040344 
	C203.927780,934.112976 202.474319,934.551514 201.006805,934.795654 
	C196.979095,935.465698 193.805695,934.316711 191.768951,930.562561 
	C191.146393,929.415100 190.506821,927.994568 189.472809,927.407776 
	C186.797272,925.889160 187.138519,924.218140 188.537842,922.094421 
	C190.930557,918.463013 190.849945,915.249390 186.744827,912.831055 
	C185.851791,912.304932 185.075851,911.553589 185.288116,910.435547 
	C185.581970,908.887817 186.943359,908.984619 188.076828,909.027466 
	C191.314636,909.149658 193.975555,908.090149 195.821854,905.367981 
	C196.770676,903.969055 198.296082,903.668335 199.689987,903.300049 
	C204.414413,902.051697 205.326874,894.938660 211.357864,895.794434 
	C211.370163,891.366455 214.159866,890.776367 217.785324,888.860291 
	C226.035492,884.499939 234.451294,885.415100 242.765198,885.372375 
	C250.003754,885.335083 257.184418,886.392151 264.123413,883.493286 
	C264.428925,883.365601 264.767212,883.313538 265.067261,883.176208 
	C268.673737,881.525330 268.738739,886.710510 271.316437,886.486267 
	C273.256927,886.317566 272.891266,882.634827 276.960419,883.773560 
	C281.086731,884.928284 286.744385,885.831177 290.916565,881.370178 
	C291.525574,880.371338 292.231201,880.143311 293.385590,880.126343 
	C297.639130,882.745911 300.986786,880.289246 304.377045,878.916870 
	C309.296875,876.925415 313.908661,874.200623 319.326599,872.478271 
	C319.820862,872.120911 320.031097,871.991577 320.585266,871.717957 
	C326.252502,868.678894 331.819641,866.292236 337.280273,863.731323 
	C341.936890,861.547546 346.787445,859.567139 351.316559,857.030457 
	C353.558655,855.774597 355.860199,855.088196 358.695801,856.350891 
	C360.573395,859.445129 358.148865,860.558594 356.458435,861.599854 
	C351.550995,864.622742 346.109070,866.643860 341.329773,870.012024 
	C338.515106,871.995605 335.087219,873.058350 333.000702,876.113892 
	C332.128479,877.391174 330.666016,878.020935 329.111145,878.115234 
	C326.792969,878.255737 325.036011,879.395691 323.445618,880.953796 
	C322.731903,881.653137 321.962341,882.486023 321.026917,882.685364 
	C314.299561,884.119019 309.187836,889.195923 302.511353,890.768188 
	C299.273956,891.530579 296.887482,895.377502 292.567688,893.305908 
	C291.327087,892.657227 290.411865,892.051392 288.990173,892.860046 
	C288.454010,893.071777 288.232117,893.146973 287.660156,893.292114 
	C280.345490,893.688843 273.314697,894.448486 267.420197,889.337280 
	C266.012115,888.116333 264.286835,888.671265 262.651581,889.078857 
	C257.176178,890.443665 251.649811,891.604553 246.205597,893.091858 
	C242.095108,894.214722 237.780106,893.541199 233.653107,895.452332 
	C226.810898,898.620911 224.714584,901.953674 226.555405,909.098328 
	C228.280441,915.793640 227.925339,916.177307 220.930603,915.658630 
	C217.977661,915.439636 215.012527,914.526794 212.052399,915.643433 
	C210.611328,916.187134 209.098770,916.809448 209.315918,918.678101 
	C209.512817,920.372498 211.251343,920.864563 212.366440,920.574280 
	C220.404938,918.482117 229.183151,919.690857 236.572372,914.466553 
	C240.934982,911.382202 246.073532,909.649292 251.898285,912.284119 
	C255.065994,917.637817 254.988617,917.938477 249.115509,923.993103 
	C248.819916,924.515259 248.441971,924.651917 248.506027,925.020142 
	C248.763687,924.908142 248.995224,924.753235 249.520416,924.486328 
	C251.899094,924.628418 252.593140,926.183533 253.271179,928.185303 
	C253.697220,935.141418 254.407974,936.079651 260.804688,938.105713 
	C263.474091,939.866943 264.551697,942.266541 265.087982,944.926819 
	C265.504517,946.993042 265.305389,949.353760 263.333710,950.245117 
	C258.484375,952.437378 254.477234,957.069153 248.320419,955.753174 
	C247.536285,955.585693 246.665558,955.644775 245.639465,956.437317 
	C247.231857,958.484436 249.771149,959.771362 250.874634,962.615784 
	C251.646408,965.006409 251.862396,967.138184 252.017609,969.286377 
	C252.315735,973.412598 255.794083,975.926086 259.773926,975.009644 
	C261.913910,974.516663 262.110474,972.919983 262.093018,971.206726 
	C262.069733,968.923401 261.168518,966.625549 262.436829,964.073853 
	C263.278839,963.220581 264.138916,962.688538 264.865936,962.962585 
	C268.826447,964.455261 271.603790,962.534180 274.561584,959.863159 
	C276.771240,959.475464 278.522491,960.252075 280.348969,960.291382 
	C289.438599,960.486938 290.074585,960.870544 292.615417,969.316284 
	C294.029755,974.017578 297.318848,978.197021 296.489838,983.555359 
	C296.208099,985.376160 297.693848,985.608276 299.145752,985.628906 
	C301.983612,985.669373 304.825714,985.742981 307.291077,988.169922 
	C307.511841,988.770142 307.544739,989.030273 307.485413,989.676758 
	C305.973785,991.977844 304.028381,992.609680 301.374542,992.197266 
	C294.342987,991.735535 288.486633,994.290222 282.737213,997.255310 
	C281.372192,997.959351 281.135498,999.264832 280.677795,1000.497742 
	C278.006104,1007.694519 275.331238,1014.890198 272.620880,1022.072388 
	C272.288940,1022.951965 272.609467,1023.594604 272.886810,1024.394653 
	C274.427185,1028.837524 273.008698,1031.351929 268.446716,1031.938599 
	C265.927917,1032.262451 264.233368,1032.183228 264.585175,1035.909058 
	C264.963806,1039.918945 260.994324,1041.228394 257.856628,1041.827759 
	C254.915527,1042.389526 251.665863,1041.849365 251.056335,1037.851074 
	C250.689407,1035.443970 250.367233,1034.427734 248.511627,1037.128784 
	C247.808197,1038.152710 247.100586,1036.986694 246.712860,1036.322876 
	C245.305023,1033.912109 243.865753,1032.479858 240.756821,1034.296021 
	C239.115021,1035.255249 238.881805,1033.541382 239.496597,1032.370972 
	C240.362427,1030.722534 241.882141,1029.664673 243.455826,1028.691162 
	C245.286896,1027.558472 244.896637,1026.000000 244.258743,1024.317139 
	C243.597977,1022.574036 243.304688,1020.529480 240.307678,1021.867676 
	C239.620316,1022.392517 239.164505,1022.529724 238.317719,1022.199463 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M300.869141,992.323853 
	C302.794067,991.376587 304.570526,990.742676 306.702240,990.077393 
	C308.303741,990.003662 309.221100,990.630859 310.176025,991.805481 
	C310.428162,993.138855 309.889893,993.831360 308.816956,994.548279 
	C308.302734,994.750854 308.089661,994.805237 307.556732,994.898376 
	C306.642273,994.998840 306.021210,994.950745 305.334290,995.599365 
	C304.696045,996.529663 304.236053,997.216431 303.447815,998.042725 
	C302.847412,998.458984 302.461212,998.605103 301.744934,998.683228 
	C300.369598,998.529297 299.573883,999.196167 298.394531,999.727295 
	C296.265747,1000.071472 295.441040,1001.166138 295.353699,1003.298645 
	C295.163422,1004.111267 294.999878,1004.562439 294.615997,1005.319824 
	C289.118103,1011.094177 290.195648,1017.450562 292.127686,1023.755676 
	C293.337952,1027.705444 294.921448,1031.577026 298.065674,1034.539062 
	C303.680481,1039.828735 302.046600,1047.190063 303.472626,1053.674927 
	C304.246033,1057.192017 303.998077,1061.033691 306.155029,1064.115234 
	C309.317230,1068.632690 313.289398,1071.307495 319.340729,1071.300049 
	C330.650970,1071.286255 340.940552,1069.383789 348.572937,1059.663696 
	C348.819580,1058.597168 349.150848,1057.961060 349.775635,1057.086914 
	C350.461334,1056.314087 351.018585,1055.931763 351.888977,1055.441162 
	C352.709137,1054.997925 353.268341,1054.737549 353.947845,1054.104004 
	C354.317566,1053.747070 354.470764,1053.607422 354.869781,1053.272217 
	C356.372162,1052.552612 356.919128,1051.273560 358.197662,1050.264038 
	C358.717438,1049.974243 358.940979,1049.877808 359.526581,1049.690430 
	C363.382050,1049.104370 367.029022,1048.817505 370.206238,1050.287476 
	C375.210114,1052.602295 380.557434,1053.386230 385.699615,1052.847900 
	C394.340820,1051.943115 403.178741,1050.542725 409.821259,1044.048950 
	C412.882141,1041.056641 415.906067,1039.361572 420.364380,1040.149292 
	C426.745850,1041.276489 433.173981,1041.197388 439.521210,1039.522949 
	C440.524506,1039.258301 441.440063,1039.069580 442.287476,1037.824097 
	C442.824554,1037.386108 443.065460,1037.239380 443.710052,1036.949219 
	C445.574554,1036.559082 447.016296,1036.505493 448.729065,1037.499878 
	C449.667297,1038.528931 449.781342,1039.362305 449.238861,1040.629150 
	C448.524658,1041.307983 448.052948,1041.620850 447.288788,1041.964355 
	C444.673798,1041.808105 442.554626,1042.509155 440.248535,1043.744141 
	C439.555664,1044.004639 439.112244,1044.004395 438.336243,1044.004639 
	C433.219086,1047.409424 427.567505,1047.047852 422.161713,1047.429199 
	C417.567566,1047.753296 412.899597,1047.358154 409.316071,1051.766846 
	C405.026642,1052.451782 401.389709,1053.710327 398.169983,1056.689819 
	C397.555756,1056.992676 397.110931,1056.995239 396.332428,1056.998779 
	C390.060669,1056.796509 384.546082,1059.665039 378.316742,1058.676880 
	C373.492645,1057.911743 368.515167,1058.287476 363.939850,1056.009277 
	C362.379944,1055.232544 360.613800,1054.005371 359.081879,1056.651367 
	C357.339264,1059.407471 353.219360,1059.460938 353.000366,1063.621460 
	C351.136169,1065.487427 349.424500,1067.163330 347.234283,1068.769043 
	C346.699738,1069.127686 346.447052,1069.322266 346.126282,1069.793457 
	C340.519043,1073.957642 335.141479,1077.887573 327.941589,1078.496338 
	C321.437500,1079.046387 315.118561,1079.654907 308.739075,1077.395996 
	C301.041260,1074.670410 296.630829,1069.497070 295.438019,1061.508179 
	C295.069519,1059.040039 294.540771,1056.583008 294.416229,1054.091064 
	C294.053619,1046.835815 291.803802,1040.450562 286.468872,1035.266968 
	C282.774414,1031.677490 282.828156,1026.808472 282.801971,1022.178284 
	C282.784210,1019.041626 283.302856,1015.882324 283.526764,1012.728882 
	C284.086456,1004.846069 288.755310,999.854675 295.593994,996.047363 
	C298.593170,996.312622 299.813477,994.694946 300.869141,992.323853 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M295.775940,995.770935 
	C283.257751,1004.670715 283.919281,1017.565430 285.146790,1030.607666 
	C285.314789,1032.392700 286.859436,1033.425659 288.064850,1034.531494 
	C293.397705,1039.423340 295.572571,1045.635620 296.028107,1052.680054 
	C296.252014,1056.142578 296.525635,1059.643677 297.259155,1063.022705 
	C298.619415,1069.288696 302.442413,1073.874512 308.607574,1075.599243 
	C319.342712,1078.602783 329.952789,1079.112427 339.679474,1072.017700 
	C341.197754,1070.910278 343.383392,1070.717896 345.633575,1070.052734 
	C346.819702,1070.574707 346.982849,1071.420898 346.995636,1072.666260 
	C343.062500,1074.629395 338.898590,1075.845459 336.017395,1079.339844 
	C335.514069,1079.950317 334.689423,1080.709839 334.018158,1080.705078 
	C325.506348,1080.644409 317.615662,1085.015991 308.954437,1084.042114 
	C303.262054,1083.402222 298.795929,1080.067139 296.868195,1074.258545 
	C296.475677,1073.075806 295.831757,1071.809570 294.777191,1071.625122 
	C289.697418,1070.736084 289.896179,1067.049438 289.984741,1063.276611 
	C290.058990,1060.113525 289.873962,1056.941284 290.029572,1053.784180 
	C290.262024,1049.067261 289.512329,1045.139526 284.058807,1043.785156 
	C282.041809,1043.284180 281.689240,1041.458618 282.283813,1039.733643 
	C283.786316,1035.374268 281.908722,1032.261353 278.621429,1029.849121 
	C276.029755,1027.947388 275.530029,1025.749390 276.537262,1022.888550 
	C277.916809,1018.970215 279.424744,1015.075989 279.096985,1010.779480 
	C279.049744,1010.160156 279.389618,1009.493958 279.616882,1008.873840 
	C280.990082,1005.126343 291.330872,996.490356 295.775940,995.770935 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M200.702637,1006.989929 
	C199.878342,1005.398315 199.792984,1004.020325 200.272156,1002.778198 
	C201.407898,999.833923 201.088074,998.315491 197.416458,997.746399 
	C194.103516,997.232971 191.926804,992.006409 193.109253,988.817261 
	C193.269363,988.385437 193.495193,987.841492 193.853439,987.642334 
	C195.945587,986.479065 204.315765,991.005737 204.917221,993.578552 
	C206.247314,999.268250 208.852768,1000.558167 213.975449,997.964294 
	C216.104980,996.885986 218.438751,994.343628 220.377899,997.466125 
	C222.186737,1000.378967 220.937210,1003.445740 218.134537,1005.255127 
	C214.262344,1007.754883 209.567886,1008.157898 205.228531,1009.406006 
	C203.935959,1009.777832 202.000580,1008.783447 200.702637,1006.989929 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M218.862579,1053.238525 
	C216.484100,1051.776245 213.937119,1051.300659 212.780075,1048.657837 
	C211.747818,1046.299927 210.297821,1044.318848 213.624939,1042.470215 
	C215.695511,1041.319946 214.811157,1038.896729 215.013428,1036.978149 
	C215.157654,1035.610352 214.425613,1033.526733 216.552353,1033.369507 
	C218.801544,1033.203247 218.886017,1035.216919 219.011154,1036.921875 
	C219.068573,1037.704224 219.262360,1038.476562 219.450562,1039.584717 
	C220.104462,1039.126221 220.697342,1038.934082 220.759155,1038.632935 
	C221.438766,1035.322510 222.841644,1031.889648 226.548660,1032.388062 
	C230.260803,1032.887085 230.582397,1037.231934 231.736862,1040.177734 
	C232.617584,1042.425049 230.575104,1043.899658 228.761658,1043.747314 
	C223.224335,1043.282227 222.655930,1047.044800 222.065842,1050.912354 
	C221.800705,1052.650024 221.784210,1054.473267 218.862579,1053.238525 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M111.619873,917.946350 
	C112.279221,921.119141 114.093460,922.227844 117.075546,922.089478 
	C118.253502,922.034912 119.507141,922.519958 119.491325,924.018066 
	C119.475578,925.509338 118.230736,925.823364 117.036842,925.946960 
	C110.910683,926.580811 105.135300,930.292664 98.668495,928.041443 
	C98.242798,927.893311 97.661255,928.034180 97.197266,928.182007 
	C95.135910,928.839172 92.833153,931.559570 91.011711,928.963013 
	C89.170685,926.338501 88.194382,923.010254 90.107994,919.617554 
	C90.723747,918.525818 90.718155,917.083679 90.998413,915.400879 
	C94.725052,916.028748 95.737923,919.359741 96.909019,922.350037 
	C98.300026,925.901855 101.022446,925.031921 103.562157,924.740051 
	C104.741425,924.604553 104.929443,923.495911 104.783066,922.412109 
	C104.047729,916.967529 105.051857,916.266541 111.619873,917.946350 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M185.947128,896.004028 
	C185.928040,894.441223 185.952408,893.029541 184.623428,892.691711 
	C182.691208,892.200745 183.154114,893.966370 182.976364,894.946472 
	C182.656540,896.709900 181.590652,897.403809 180.220718,896.370422 
	C178.806702,895.303650 176.158936,894.232666 177.929230,891.784851 
	C180.258957,888.563477 181.701401,884.494141 185.875000,882.852661 
	C187.895691,882.057922 188.731522,881.133606 189.605392,884.658203 
	C190.471283,888.150513 191.451965,892.204468 193.912949,895.190002 
	C195.569733,897.199951 196.107254,898.679138 194.138931,900.656189 
	C191.869141,902.935974 189.110001,902.314453 186.731415,901.519958 
	C183.953659,900.592041 185.666962,898.214966 185.947128,896.004028 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M202.714783,981.672546 
	C199.690201,983.246765 196.975220,983.527649 194.262070,982.094971 
	C192.847534,981.347961 190.748489,981.094849 190.513687,979.208984 
	C190.257782,977.153625 193.185699,977.575195 193.504547,976.733704 
	C195.750565,970.806091 199.317276,973.427734 202.854202,974.907166 
	C203.566742,975.205139 204.493195,974.997986 205.321198,975.012390 
	C207.146423,975.044189 208.686661,975.566772 208.861603,977.667419 
	C209.056122,980.003174 207.637283,980.874146 205.505066,981.016235 
	C204.691635,981.070496 203.892136,981.333862 202.714783,981.672546 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M233.354889,1063.873779 
	C231.704880,1063.028320 230.344238,1062.358398 229.165771,1061.451782 
	C226.390625,1059.316406 226.362015,1057.462646 229.918320,1056.159180 
	C230.692276,1055.875366 231.504822,1055.614746 232.183838,1055.167603 
	C236.099991,1052.589111 239.343567,1052.742676 241.145416,1056.351685 
	C243.038406,1060.143188 238.642914,1061.061523 237.026230,1063.223267 
	C236.296417,1064.199219 234.901611,1064.004028 233.354889,1063.873779 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M160.337021,872.003784 
	C163.781891,871.866150 166.683762,871.677856 168.403030,875.136047 
	C169.121780,876.581848 171.175751,875.788940 172.596024,876.132324 
	C173.621155,876.380127 174.526245,876.825684 174.532135,878.047058 
	C174.536789,879.010559 173.682465,879.899841 173.018555,879.687012 
	C168.383759,878.201782 162.917160,882.588867 158.754028,877.571594 
	C157.830719,876.458740 154.975983,876.926453 155.528610,874.564819 
	C155.992783,872.581116 157.906357,871.943726 160.337021,872.003784 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M136.986267,889.098633 
	C139.007858,885.882690 141.162659,886.927795 143.332245,888.277527 
	C145.259048,889.476318 147.122620,891.186340 146.434830,893.439026 
	C145.666214,895.956421 143.353683,896.955139 140.509308,897.081299 
	C137.313492,897.223022 134.965805,893.874084 131.467575,895.726501 
	C131.360733,891.591431 135.792252,891.956970 136.986267,889.098633 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M239.090790,881.364624 
	C245.488770,877.096497 252.636490,879.957581 259.443420,879.193237 
	C262.551178,878.844177 265.126678,875.988403 268.740692,877.743530 
	C262.519135,882.111572 255.545914,884.064392 247.984207,881.972473 
	C244.967865,881.138123 242.002075,884.690979 239.090790,881.364624 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M161.000000,1014.399963 
	C164.492676,1014.173035 168.014893,1014.400818 168.950195,1009.369690 
	C170.744431,1009.326721 172.488846,1009.653503 174.616638,1009.990112 
	C176.395325,1013.083801 175.170853,1014.849609 172.190292,1015.937134 
	C168.782501,1017.180664 165.836975,1020.348450 161.346588,1018.153809 
	C161.000000,1016.933289 161.000000,1015.866638 161.000000,1014.399963 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M294.351807,880.844482 
	C293.250092,881.000244 292.500427,881.000488 291.376007,881.001587 
	C286.508423,880.189148 281.811523,880.489380 277.264709,878.266479 
	C282.726196,875.578491 288.456573,876.729492 294.593536,877.988403 
	C295.845490,879.002014 295.519989,879.872559 294.351807,880.844482 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M283.855469,1109.249268 
	C283.433289,1112.186035 283.294647,1115.321777 279.471771,1114.552856 
	C276.390228,1113.932983 276.949036,1111.160522 277.071594,1108.915649 
	C277.169556,1107.121582 278.182373,1105.675293 279.912476,1105.322998 
	C282.583893,1104.779053 282.974457,1107.153931 283.855469,1109.249268 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M150.993866,876.124878 
	C150.752945,877.796021 150.602859,879.096069 150.231461,880.329468 
	C149.993561,881.119690 149.363159,881.793457 148.375198,881.496582 
	C147.247910,881.157837 145.929657,880.626587 146.544434,879.165771 
	C147.586670,876.689392 144.301376,873.225403 147.900986,871.668274 
	C149.591461,870.937073 150.546204,873.055725 150.921936,874.744873 
	C150.992310,875.061218 150.970306,875.398132 150.993866,876.124878 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M245.563873,1065.904053 
	C245.844879,1066.710571 246.014008,1067.212891 245.965790,1067.693481 
	C245.781647,1069.528809 246.780685,1072.178467 243.872589,1072.465942 
	C242.205246,1072.630859 241.330582,1070.571655 241.454025,1069.209717 
	C241.639374,1067.164429 242.088394,1064.652344 245.563873,1065.904053 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M237.620911,1022.009033 
	C238.447144,1021.991211 238.890610,1021.994690 239.666733,1021.999023 
	C241.354828,1024.193481 241.063034,1026.070801 238.809204,1027.426147 
	C237.473694,1028.229370 236.075897,1027.942749 235.509750,1026.515015 
	C234.778748,1024.671509 234.191666,1022.561829 237.620911,1022.009033 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M246.407684,1049.881104 
	C244.498138,1050.099976 243.128906,1049.727905 242.073456,1048.493408 
	C241.128784,1047.388672 240.691406,1046.205933 241.800201,1044.985596 
	C242.875687,1043.801758 244.107712,1044.337524 244.701401,1045.335449 
	C245.530533,1046.729370 248.802414,1047.112061 246.407684,1049.881104 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M129.570770,889.273315 
	C129.039169,887.868652 128.821259,886.696960 130.370331,886.338074 
	C131.036057,886.183838 131.636841,886.611206 131.753632,887.337463 
	C131.990982,888.813660 131.800110,889.991150 129.570770,889.273315 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M272.061615,878.325928 
	C272.749023,878.003967 273.499695,878.003113 274.625671,878.003540 
	C274.437805,879.990417 273.601044,880.750610 272.061615,878.325928 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M179.667114,1010.000000 
	C179.893372,1010.627869 179.827820,1011.380981 179.042603,1011.420959 
	C178.742050,1011.436279 178.411911,1010.869568 178.047455,1010.284058 
	C178.444275,1010.000000 178.888947,1010.000000 179.667114,1010.000000 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M115.625473,655.990845 
	C116.075775,658.618652 118.535355,661.142578 115.702637,664.085632 
	C115.221458,664.585571 118.433342,669.270325 120.548279,671.423279 
	C122.026535,672.928101 123.906151,671.598572 125.182259,670.001770 
	C125.547707,669.544434 126.374680,669.056458 126.865547,669.161865 
	C130.907440,670.030518 131.765305,669.141846 129.459641,665.403564 
	C128.170517,663.313354 129.480133,661.400757 131.695358,660.742493 
	C136.818939,659.219849 141.994553,657.862122 147.349228,657.346741 
	C148.389053,657.246643 149.426956,656.987793 149.698410,658.349426 
	C149.889328,659.307068 149.154022,660.159790 148.391327,660.182068 
	C145.508621,660.266418 144.383026,664.322449 141.003937,663.275024 
	C139.878448,662.926208 139.164917,663.800415 139.075073,664.978699 
	C138.970474,666.350647 139.033829,667.754028 140.428345,668.463806 
	C141.445633,668.981506 142.681747,669.073669 143.690109,669.603394 
	C144.848022,670.211609 145.003387,671.443237 144.949921,672.655884 
	C144.912308,673.509216 144.674011,674.726074 143.763702,674.459045 
	C141.305847,673.738159 139.774139,675.782349 137.943771,676.092590 
	C133.510544,676.843994 129.208313,679.215027 124.515434,677.880615 
	C122.950157,680.079529 128.122269,679.964661 126.056175,682.058411 
	C124.099426,684.041382 121.891914,682.710693 120.185341,681.436401 
	C117.256630,679.249695 113.948135,682.953552 110.941055,680.600525 
	C110.435776,680.205200 110.047600,682.116150 110.898537,682.772461 
	C114.158203,685.286926 112.041557,686.109680 109.343475,686.957214 
	C106.786751,684.832886 105.085388,682.196289 101.956978,680.936218 
	C98.801155,679.665161 100.625313,676.594482 100.107056,673.662964 
	C97.593315,669.500793 99.310478,665.228760 97.677887,661.354919 
	C96.535690,658.644775 99.686874,659.603027 101.657822,659.958801 
	C102.631668,659.321228 103.341301,658.808838 104.667770,658.950806 
	C106.875580,659.385925 107.907516,657.817871 109.660515,657.067139 
	C109.999695,657.000000 110.332550,657.338440 110.332550,657.338440 
	C110.332550,657.338440 110.296097,657.295898 110.148048,657.147949 
	C111.072525,653.164978 113.537544,656.510376 115.625473,655.990845 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M107.781792,686.708740 
	C108.160728,689.406250 109.682701,692.429504 104.935310,692.178345 
	C102.841858,692.067566 103.089302,693.955627 103.327248,695.550598 
	C103.976265,699.900940 99.797234,706.980225 95.330917,708.851685 
	C93.844177,703.851990 88.762848,700.439636 83.531487,701.322266 
	C82.554199,701.487183 81.602028,701.882507 80.624054,701.943420 
	C78.969223,702.046570 77.071739,702.218750 76.370865,700.236206 
	C75.670113,698.253967 77.176849,697.139282 78.613121,696.269958 
	C81.377914,694.596680 85.140396,694.900269 87.228294,691.744812 
	C87.753311,690.951355 88.649658,691.840942 89.245934,692.459229 
	C91.423592,694.717163 94.500069,695.924988 96.304756,698.669495 
	C97.083855,699.854187 98.461525,700.327148 99.808815,699.391541 
	C101.031364,698.542603 100.681496,697.405823 100.131508,696.283203 
	C99.144409,694.268433 98.757652,691.836304 95.674698,691.683899 
	C92.332367,691.518677 92.579292,687.816040 90.346939,686.081909 
	C87.758827,684.320923 87.386856,682.461670 89.812096,680.191406 
	C90.963547,679.779419 92.155205,679.487610 92.511421,680.005310 
	C96.197502,685.362854 102.981407,683.302368 107.781792,686.708740 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M77.660675,632.963867 
	C78.304794,633.126770 78.557899,633.320618 78.879814,633.790771 
	C80.390465,636.259888 81.217873,638.708130 81.230202,641.676147 
	C81.000496,643.388916 81.000252,644.777771 80.999847,646.583374 
	C78.944283,647.326233 78.917419,648.901062 79.020119,650.415649 
	C79.220131,653.365173 77.683258,654.969788 74.971565,654.870544 
	C72.123222,654.766296 68.441124,656.630676 66.907043,652.200012 
	C65.865387,649.191467 63.272198,646.192200 67.354126,643.415466 
	C67.759727,643.139648 67.853737,642.216858 67.871277,641.588196 
	C68.078835,634.148193 69.830788,632.528625 77.660675,632.963867 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M91.242058,680.764587 
	C87.758240,681.702209 89.721161,683.724976 89.954956,685.648743 
	C88.060532,687.541016 86.806831,692.215027 83.240463,687.213745 
	C81.757797,686.203674 80.681122,685.293396 80.841377,683.319824 
	C80.593597,677.547852 83.550308,674.575745 88.389160,673.008118 
	C88.850571,672.858643 89.651001,672.980469 89.787186,672.715637 
	C93.108925,666.256714 96.186348,670.203125 99.648636,672.948730 
	C101.361374,674.739868 100.524803,676.985901 98.980179,676.516174 
	C94.709435,675.217529 93.866577,679.071777 91.242058,680.764587 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M88.608421,708.275269 
	C84.084450,708.288940 79.803894,707.800659 75.740906,709.175842 
	C73.984238,709.770447 72.425316,710.287964 71.675461,708.137817 
	C70.895020,705.899963 69.116638,703.709167 70.713974,701.136230 
	C71.099274,700.515625 71.983704,700.162720 72.389526,700.701172 
	C76.728302,706.456909 83.299644,703.592102 88.749809,704.993591 
	C90.077934,705.335083 91.621735,705.434937 91.526733,707.136658 
	C91.447525,708.555603 90.263695,708.917725 88.608421,708.275269 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M54.531532,735.779602 
	C56.386761,732.607300 57.296398,729.118530 57.534840,725.291931 
	C57.638298,723.631653 59.222931,722.182617 61.064205,722.472778 
	C63.279083,722.821716 63.057976,724.716187 62.708263,726.416443 
	C62.609085,726.898621 62.216194,727.539246 62.398998,727.828796 
	C67.785042,736.360474 59.550026,733.874329 56.296375,735.386963 
	C55.852966,735.593079 55.350819,735.672974 54.531532,735.779602 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M74.765823,688.767334 
	C74.393921,691.263184 75.317413,695.574341 70.289444,692.150391 
	C69.168701,691.364319 68.381233,690.693604 68.059540,689.304810 
	C67.528732,683.377502 72.894249,683.262207 75.789307,680.867798 
	C77.641380,679.335938 78.637161,681.239868 79.844635,682.706543 
	C78.988953,684.475769 77.192139,684.478088 75.807556,685.249573 
	C74.513969,685.970398 73.041534,686.699219 74.765823,688.767334 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M75.068146,688.664551 
	C73.805283,688.740906 72.060616,689.672607 71.579948,687.895630 
	C71.240570,686.640991 72.690544,686.002686 73.384811,685.115173 
	C74.887230,683.194458 76.936806,682.808960 79.600349,683.000000 
	C79.999390,683.000000 80.000000,683.000000 80.000000,683.000000 
	C81.135880,684.768921 80.844658,686.113525 78.413635,686.972351 
	C76.864899,687.053284 75.638115,686.909668 75.068146,688.664551 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M93.950569,734.633545 
	C94.000610,735.444458 94.000305,735.888916 93.999847,736.666687 
	C93.335770,736.997253 92.671837,736.990967 92.007919,736.992432 
	C90.022537,736.996704 88.287483,736.606262 88.121796,734.168396 
	C88.020676,732.680481 88.654579,731.544617 90.194763,731.212524 
	C92.662231,730.680359 93.322044,732.448975 93.950569,734.633545 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M67.693268,689.174805 
	C69.394852,689.136597 69.985481,689.964844 69.991447,691.651978 
	C68.205292,692.693481 68.383957,697.197998 65.752670,695.170227 
	C63.717228,693.601624 65.817139,691.066833 67.693268,689.174805 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M90.359016,654.635437 
	C92.387863,652.543335 93.941833,653.352783 94.642525,655.288757 
	C95.151833,656.695984 94.328369,658.505127 92.682159,658.651611 
	C90.144318,658.877319 89.954292,656.934326 90.359016,654.635437 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M104.998352,658.607727 
	C104.428894,659.814819 103.582878,659.983093 102.334541,659.995605 
	C101.904182,659.510803 101.835793,659.014160 101.706795,658.533813 
	C101.297852,657.010864 100.016548,654.868225 102.390694,654.406860 
	C104.947235,653.910034 104.900764,656.378296 104.998352,658.607727 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M90.360992,664.987427 
	C89.999390,665.000000 89.499695,665.000000 89.249847,665.000000 
	C89.699081,663.460388 86.939148,661.282166 89.525055,660.333862 
	C91.015648,659.787231 92.245033,661.395081 92.688766,662.870789 
	C93.148003,664.398010 92.043427,664.886841 90.360992,664.987427 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M78.148369,687.275879 
	C78.779465,685.963684 80.176529,685.296875 79.992691,683.340210 
	C80.000000,683.000000 80.499878,683.000000 80.749832,683.000000 
	C81.641457,684.117737 82.283127,685.235474 82.962250,686.676636 
	C81.475891,687.558533 80.191261,690.156067 78.148369,687.275879 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M46.442738,736.500366 
	C44.633835,734.701477 45.406559,733.641968 47.192135,733.260681 
	C48.492435,732.982971 50.307495,733.538086 50.226818,734.868347 
	C50.125671,736.536072 48.099312,736.349976 46.442738,736.500366 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M66.232010,727.941284 
	C67.282845,725.075562 68.326797,726.203674 69.252640,727.681030 
	C69.779823,728.522278 70.328476,729.753784 69.299286,730.350342 
	C67.192551,731.571777 66.962120,729.475098 66.232010,727.941284 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M100.789146,726.175964 
	C100.491928,727.692627 100.371559,729.676697 98.831993,729.127319 
	C97.301910,728.581299 97.715286,726.410767 98.323746,725.270447 
	C99.203323,723.622131 100.101677,724.789124 100.789146,726.175964 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M39.227692,732.260010 
	C40.171879,732.321716 40.678497,732.659607 40.285160,733.366272 
	C40.142979,733.621704 39.437023,733.796936 39.119240,733.672974 
	C38.421780,733.401123 38.358269,732.855347 39.227692,732.260010 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M56.013973,635.555603 
	C56.834400,636.012939 56.937080,636.581238 56.546059,637.139709 
	C56.232143,637.588074 55.735653,637.582947 55.435951,637.121582 
	C55.066395,636.552612 55.174400,635.985962 56.013973,635.555603 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M359.290161,857.227051 
	C352.140350,857.712646 345.881134,859.881836 340.623749,864.525146 
	C340.026184,865.052917 339.302521,865.782532 338.613861,865.809021 
	C332.071869,866.060791 327.332336,870.523621 321.354736,872.950684 
	C319.863373,871.704102 319.585083,870.297424 320.723755,868.371582 
	C325.706604,864.519409 330.981171,861.989563 336.465179,860.228333 
	C341.789948,858.518127 346.964600,857.055115 351.991638,854.189514 
	C355.489868,852.195435 359.841980,850.167908 364.374817,849.989807 
	C367.094635,849.882874 368.711334,848.564026 368.996216,845.394531 
	C369.756714,845.009216 370.504364,845.006287 371.626038,845.002869 
	C377.378143,844.618530 382.024994,842.305786 385.358582,838.315918 
	C387.601013,835.631897 389.954010,833.248169 392.908264,830.884277 
	C393.424286,830.230896 393.760223,829.891907 394.397827,829.344238 
	C396.187683,828.414612 397.453674,827.061218 399.676941,827.520752 
	C401.219147,828.939026 400.735016,830.342773 400.189941,832.068726 
	C399.937408,832.593994 399.815613,832.791870 399.461487,833.244751 
	C398.235077,834.351624 396.760132,834.572388 395.896118,836.139282 
	C395.519165,836.563965 395.356689,836.725708 394.918823,837.097473 
	C386.242889,847.274353 373.467194,850.011597 362.952179,856.484558 
	C362.527313,856.746094 362.109039,857.017395 361.356079,857.432251 
	C360.529541,857.648621 360.049591,857.609192 359.290161,857.227051 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M294.156677,881.296021 
	C294.311279,880.212219 294.622864,879.424438 294.967224,878.319580 
	C295.805420,877.754333 296.610870,877.506226 297.708282,877.127808 
	C300.428162,874.597717 303.732758,876.889954 306.668427,875.219849 
	C307.135864,873.606445 307.963135,873.014099 309.650208,873.006714 
	C310.641052,872.123352 311.772797,872.053589 312.785217,871.218262 
	C314.066956,871.001831 315.133911,871.001282 316.600952,871.001587 
	C318.160461,870.998474 319.046021,871.530396 319.877747,872.705811 
	C319.713135,873.583557 319.527618,874.584656 319.121826,874.683716 
	C311.605835,876.518799 305.105133,880.776489 297.954620,883.464050 
	C296.076355,884.170044 295.271210,882.715393 294.156677,881.296021 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M306.598206,874.999756 
	C304.941925,878.338318 301.618774,876.521606 298.429749,876.998535 
	C299.668732,871.966431 303.525299,875.912170 306.598206,874.999756 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M320.250610,873.001648 
	C318.872131,872.954468 317.938477,872.539734 317.152252,871.283569 
	C317.753906,869.613647 318.715240,868.463318 320.672150,868.039001 
	C320.999054,869.388916 320.999329,870.777771 320.999207,872.583374 
	C320.998779,873.000000 320.500000,873.000854 320.250610,873.001648 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M312.652039,871.010803 
	C312.862579,872.397339 312.033051,872.985718 310.346375,872.989319 
	C310.136902,871.603882 310.966034,871.014954 312.652039,871.010803 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M138.596832,723.998291 
	C139.166687,724.001648 139.337646,724.005676 139.765045,724.015869 
	C141.042252,725.285034 140.600937,726.539734 139.786743,728.125793 
	C135.929504,732.117676 132.932800,736.202515 130.311508,740.698303 
	C124.774895,750.194214 128.178360,759.257751 131.595123,768.033936 
	C134.266953,774.896729 136.083389,782.225464 140.525604,788.343628 
	C140.915924,788.881287 141.080795,789.557373 141.145004,790.226318 
	C141.985840,798.985779 144.965408,806.921021 150.213562,814.005676 
	C151.120224,815.229614 151.393570,816.748596 151.718979,818.215698 
	C152.500336,821.738464 154.047363,825.091125 156.821838,827.199646 
	C162.601730,831.592407 168.000488,836.570679 174.699249,839.688660 
	C179.692703,842.013000 184.108276,845.408508 188.543198,848.535767 
	C198.532242,855.579590 209.933533,858.329651 221.410553,861.167114 
	C229.775558,863.235229 237.953293,865.942383 246.808884,865.814392 
	C250.979187,865.754150 255.215698,865.914856 259.210083,865.049377 
	C266.437286,863.483521 273.596191,862.808960 280.945068,863.686218 
	C286.549377,864.355225 291.902435,862.758179 297.056396,860.916626 
	C310.496490,856.114380 324.045258,851.657349 337.362183,846.433105 
	C347.592865,842.419617 357.697601,837.302673 369.055298,836.178223 
	C377.139526,835.377869 383.215759,829.772217 389.646637,825.488342 
	C392.510803,823.580383 396.110016,822.699524 398.653320,819.593018 
	C399.992310,818.879883 401.035736,818.805481 402.468506,819.302734 
	C404.924957,822.798950 402.119110,824.053284 399.895508,825.850281 
	C398.785614,826.392578 397.825378,826.395325 396.959717,827.319458 
	C396.437073,827.831909 396.121735,828.105408 395.544128,828.550171 
	C394.114410,829.198547 393.192780,830.005066 392.125732,831.136780 
	C391.717957,831.459900 391.547852,831.580750 391.110840,831.865112 
	C390.088440,832.385864 389.356323,832.774780 388.652679,833.741699 
	C386.453766,835.886475 384.608734,838.002380 381.311279,838.158630 
	C377.529175,837.579834 374.636353,839.621277 371.554016,840.550781 
	C360.971100,843.742065 350.346375,846.846497 340.075409,851.058289 
	C335.549561,852.914246 330.852142,853.821533 326.469299,856.627380 
	C318.209595,861.915161 308.490845,863.822449 298.957611,866.114014 
	C290.449463,868.159119 282.045776,870.393982 273.260162,870.737915 
	C271.434784,870.809387 269.297333,871.519165 267.836456,870.820679 
	C261.006989,867.554993 254.562531,869.869873 247.964920,871.459290 
	C243.891037,872.440796 240.060745,871.917236 235.909119,870.337341 
	C226.092545,866.601562 215.714905,864.787354 205.376801,862.823914 
	C198.951157,861.603638 191.860138,861.264526 186.974960,855.565613 
	C184.988266,853.248047 181.940170,852.292847 179.204224,851.163208 
	C169.883698,847.315125 162.839142,840.156494 154.599426,834.814453 
	C146.357498,829.471008 143.408783,821.172913 140.101654,813.327820 
	C137.749969,807.749146 136.253494,801.478455 134.754333,795.430481 
	C133.328735,789.679382 130.969162,784.099365 127.812485,779.537659 
	C124.552650,774.826904 125.036270,768.802002 121.000778,764.385986 
	C121.080009,762.798157 120.178627,762.172363 119.226471,761.222839 
	C116.481316,753.058899 119.790962,746.500244 123.536148,739.789246 
	C126.173965,735.062500 131.174469,732.594116 133.679657,727.875793 
	C134.224289,726.850037 134.999191,725.981628 134.996979,724.376770 
	C136.070770,724.006226 137.134354,724.002625 138.596832,723.998291 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M380.943848,838.366455 
	C383.138947,836.436890 385.276062,834.871338 387.704895,833.150452 
	C388.641083,832.124512 389.772339,832.053894 390.783722,831.216675 
	C391.000000,831.002441 391.000000,831.000000 391.000610,831.000000 
	C391.705719,830.486267 392.472260,830.266724 393.669556,830.755066 
	C388.378876,837.286743 383.356720,844.316223 374.551727,846.652954 
	C373.470673,846.939941 372.612671,846.823792 372.071960,845.338562 
	C373.420258,842.738708 375.071533,840.879272 378.086823,840.935303 
	C379.518616,840.961914 380.508636,840.171692 380.943848,838.366455 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M119.008980,761.350525 
	C122.613571,761.437256 122.765564,761.605286 121.301147,763.952515 
	C119.605057,763.862976 119.005684,763.040039 119.008980,761.350525 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M121.000000,945.611755 
	C119.171577,945.966187 117.236145,945.829529 116.393188,943.966858 
	C115.721413,942.482422 116.840172,941.170288 117.735390,940.027344 
	C118.383263,939.200012 119.334473,938.895142 120.346832,939.368042 
	C122.078300,940.176941 121.057663,941.595764 121.042648,942.787842 
	C121.032417,943.599792 121.014496,944.411621 121.000000,945.611755 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M105.000443,898.666687 
	C105.000404,899.750000 105.000198,900.500000 105.000000,901.625000 
	C104.555557,902.000000 104.111107,902.000000 103.333328,902.000000 
	C101.062157,902.054321 99.840225,901.362000 100.075661,899.173767 
	C100.370087,896.437195 102.513062,897.171326 104.586243,897.000427 
	C104.999893,897.444458 105.000084,897.888916 105.000443,898.666687 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M134.814331,869.631104 
	C133.281723,871.027649 133.184692,869.867188 133.144455,868.903687 
	C133.118744,868.288147 133.361542,867.448486 134.189819,867.758728 
	C134.567581,867.900146 134.667953,868.782349 134.814331,869.631104 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M130.375092,715.009277 
	C133.195923,711.413025 137.440872,712.973328 141.655640,712.847412 
	C138.876923,717.165649 134.990738,717.079651 130.375092,715.009277 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M151.002960,713.377563 
	C152.073120,713.005554 153.141983,713.006226 154.611725,713.010254 
	C155.554855,713.633179 155.842972,714.346497 155.720200,715.567749 
	C155.264114,716.708557 154.679443,717.233398 153.550537,717.698792 
	C152.622742,717.833496 152.110107,717.726013 151.313721,717.235962 
	C151.001083,716.824890 151.001572,716.657043 151.000732,716.237305 
	C151.000137,715.240295 151.000885,714.495300 151.002960,713.377563 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M144.062256,713.321777 
	C144.749741,713.000000 145.499954,713.000000 146.625305,713.000000 
	C146.439224,714.989624 145.603745,715.753662 144.062256,713.321777 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M152.823669,717.233032 
	C153.520370,716.746826 154.007355,716.448486 154.746216,716.014648 
	C155.666229,715.790894 156.296524,715.922668 157.179581,716.385986 
	C157.782120,716.875000 158.045624,717.229004 158.318359,717.954407 
	C158.286255,719.252014 157.641251,719.848572 156.666199,720.581543 
	C155.686447,721.184509 154.914337,721.493530 153.930664,722.039307 
	C152.921661,722.529541 152.461426,723.117859 152.101532,724.183594 
	C151.863205,724.650879 151.751938,724.833252 151.424438,725.260376 
	C149.727905,726.559448 148.147461,727.390137 145.980560,727.665894 
	C145.412811,727.554260 145.194061,727.467346 144.720947,727.131958 
	C144.084824,726.251648 143.940674,725.563843 144.066925,724.484497 
	C144.276398,723.935974 144.402832,723.734802 144.775818,723.276245 
	C146.149689,720.731628 148.637878,720.559326 150.759598,719.222168 
	C151.543518,718.473938 152.065521,717.933533 152.823669,717.233032 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M144.656982,723.057251 
	C145.022369,723.018799 145.023926,723.516968 145.019135,723.765991 
	C144.354767,724.737976 144.099152,725.542847 144.723663,726.749756 
	C144.933182,727.015991 144.981216,727.022583 145.003479,727.013000 
	C145.980331,728.087280 146.196762,729.278015 145.297668,730.884216 
	C144.829285,731.294434 144.609024,731.414795 144.020111,731.591492 
	C143.166275,731.595764 142.703232,731.467773 141.977386,731.054321 
	C140.979431,730.320129 140.469131,729.560608 139.357483,728.529053 
	C139.305862,726.897705 139.616013,725.799805 139.973816,724.361938 
	C141.444839,723.713196 142.868210,723.404419 144.656982,723.057251 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M152.694794,717.025574 
	C152.908859,717.929688 152.591965,718.665833 151.296936,718.931885 
	C150.987289,718.533630 150.991791,718.093567 150.998459,717.323120 
	C151.452469,716.997070 151.904343,717.001465 152.694794,717.025574 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M226.212830,237.275467 
	C224.173523,237.163696 222.449005,236.727081 220.379074,236.051117 
	C219.873260,235.832047 219.744705,235.665909 219.808075,235.249893 
	C220.402191,232.683441 220.986023,230.665207 224.128815,231.783264 
	C224.957031,232.077942 225.811325,231.640015 226.270462,230.876358 
	C227.011902,229.643188 225.819366,229.235489 225.216019,228.503159 
	C223.930969,226.943359 220.281143,225.632278 223.231384,223.183243 
	C225.954010,220.923157 229.074677,223.238708 230.371368,225.460373 
	C232.765976,229.563187 233.065979,229.567932 237.015121,226.070068 
	C240.449280,223.028320 242.647079,219.109619 246.098145,216.291168 
	C246.951187,215.594482 247.311508,213.922638 248.695084,214.345001 
	C250.646210,214.940598 249.781113,216.830490 249.999725,218.602982 
	C250.609863,222.775330 249.717087,225.634918 246.145660,227.857605 
	C242.936920,229.854568 243.476135,232.054138 247.111526,233.349609 
	C248.521545,233.852097 250.071686,234.081573 251.210205,235.095108 
	C253.365494,237.013794 255.487061,236.890167 258.092010,235.860245 
	C264.801056,233.207687 267.038025,233.851532 271.892914,239.472000 
	C273.224609,241.013718 274.657104,241.720795 276.650330,241.549225 
	C278.472260,241.392395 280.305756,241.310440 282.130920,241.569824 
	C284.885681,241.961319 286.373688,243.360291 285.854675,246.314499 
	C285.481903,248.436066 284.897461,250.572296 286.745911,252.742981 
	C286.577454,258.442505 291.239899,258.710999 294.510437,259.724396 
	C296.716888,260.408081 298.824005,260.540588 300.309662,262.653473 
	C301.233795,263.967804 302.744141,264.435669 304.122162,263.085938 
	C305.288208,261.943848 304.994171,260.646973 303.986389,259.591339 
	C302.676453,258.219177 301.790405,256.740662 302.001160,254.386917 
	C306.013397,254.901749 309.716187,259.648895 308.890411,263.259705 
	C307.920349,267.501526 304.656036,268.334900 300.839661,267.983215 
	C300.347046,267.937836 299.471741,267.965881 299.422211,267.783661 
	C297.807434,261.842010 291.199524,263.789825 287.942749,260.477264 
	C286.415070,258.923401 284.888214,258.438354 283.608490,260.812225 
	C281.790497,264.184509 279.530853,263.061401 278.580841,260.600983 
	C277.089447,256.738434 275.368683,259.546600 273.344116,259.956482 
	C269.511536,259.792694 266.669189,258.883118 267.179016,253.866806 
	C265.522766,255.218735 264.398987,256.654114 262.579895,256.924500 
	C261.701904,257.055023 260.309631,256.977203 260.553925,258.473755 
	C260.731842,259.563538 261.771515,259.917023 262.781433,260.166168 
	C265.974884,260.953979 269.473480,259.556335 272.748627,261.765259 
	C275.599335,265.556335 278.135345,269.060913 277.067413,273.957123 
	C276.700897,275.637390 276.618988,277.328430 275.022095,278.247498 
	C272.944092,279.443512 272.036957,277.455719 270.949066,276.389648 
	C269.458862,274.929321 267.945892,273.818115 265.722839,273.958160 
	C264.753784,274.019196 263.543518,273.963013 263.391968,272.661041 
	C263.233673,271.301453 264.369476,271.163696 265.383636,271.033569 
	C266.936218,270.834381 269.261719,271.274475 268.578888,268.607422 
	C268.175934,267.033630 268.672943,264.651123 266.203003,264.123199 
	C263.937805,263.639069 262.950531,265.033722 262.125366,266.887085 
	C261.392334,268.533478 260.912781,277.940521 261.639679,279.662262 
	C261.890900,280.257324 261.916229,280.952820 262.012085,281.607239 
	C262.157562,282.600433 262.217072,283.845764 263.577332,283.669037 
	C265.219055,283.455811 264.530426,282.237030 264.160248,281.391083 
	C263.374573,279.595581 263.992432,278.963959 265.835083,278.995819 
	C267.749908,279.028961 269.568542,279.563446 268.745270,281.788147 
	C267.482117,285.201630 266.078522,288.724609 263.930084,291.600098 
	C262.063049,294.098969 260.405212,291.329559 260.308411,289.918823 
	C260.018890,285.699585 258.221222,283.139709 254.132538,281.981384 
	C252.391937,281.488281 252.086075,280.074005 252.687714,278.348877 
	C253.109131,277.140503 252.784134,275.707642 251.384094,275.616058 
	C249.607086,275.499817 249.781937,277.314880 250.133865,278.193970 
	C251.378433,281.302948 249.081009,282.374786 247.722244,284.557922 
	C244.654373,289.487000 238.066925,293.290955 240.760757,300.816650 
	C242.252411,304.983887 249.181320,306.621002 252.314499,303.364044 
	C253.459320,302.173981 254.269302,300.664337 255.398849,299.456512 
	C257.894501,296.787811 261.585144,297.603210 262.815460,301.048553 
	C263.414337,302.725616 263.915924,304.437469 264.467651,306.152557 
	C263.385101,307.219543 262.244751,305.776337 261.180847,306.216309 
	C259.373474,306.963654 258.130096,308.005005 260.176727,309.763092 
	C260.997528,310.468201 263.084503,310.810150 262.358276,311.911713 
	C260.949158,314.049072 259.116119,315.870026 258.115326,318.349365 
	C257.892426,318.901520 256.638519,318.076477 256.283844,317.273407 
	C252.502518,308.711823 244.385849,311.463531 237.878540,310.587128 
	C235.477371,310.263733 232.963226,314.657684 233.655640,315.813782 
	C235.762085,319.330719 233.381744,321.774536 232.935654,324.658478 
	C232.781906,325.652313 231.520493,326.929901 233.213028,327.673920 
	C234.274902,328.140686 235.103012,327.332123 235.806519,326.613220 
	C237.025253,325.367798 238.224167,322.853973 240.102707,324.550751 
	C242.383987,326.611298 246.225525,327.750671 246.579208,331.076172 
	C246.884674,333.948242 244.054565,334.400635 241.917435,333.334656 
	C239.056259,331.907532 236.135651,332.007111 233.147812,332.000732 
	C231.002289,331.996185 228.856430,332.447235 228.527420,334.823700 
	C228.235931,336.929016 230.529602,337.126373 231.948639,337.832855 
	C233.130295,338.421143 234.353333,338.926361 235.779633,339.734802 
	C236.379044,340.389038 236.530136,340.850983 236.226898,341.693542 
	C234.558624,342.779907 233.359406,343.924896 231.558212,344.128754 
	C229.851562,344.321899 228.720886,345.310699 229.850052,347.247131 
	C230.614502,348.558136 231.771622,350.151215 229.468277,350.747101 
	C227.179840,351.339142 225.681442,349.823395 225.218155,347.595520 
	C225.063232,346.850616 225.669144,345.972443 225.614639,345.176575 
	C225.495956,343.443848 224.859314,341.798492 223.041962,341.290710 
	C221.070740,340.739899 220.077393,342.443542 218.975052,343.631042 
	C217.731857,344.970245 218.134705,346.229492 219.137756,347.640717 
	C222.967255,353.028656 222.937042,353.096191 217.066742,354.960754 
	C215.747787,355.379639 214.254578,355.581299 214.442062,357.326202 
	C214.619308,358.975830 215.703110,359.948303 217.506454,359.991425 
	C219.144547,360.030579 221.176041,359.791412 221.619019,361.911621 
	C222.126755,364.341949 219.895035,363.711914 218.619354,364.122528 
	C216.710617,364.736969 217.567368,365.729889 218.275146,366.741058 
	C219.519913,368.519409 221.536499,368.937531 223.677063,369.855652 
	C224.091751,370.202362 224.184738,370.404755 224.163300,370.919373 
	C222.385666,372.783447 220.340851,371.238495 218.344711,372.065979 
	C217.981613,372.327515 217.842545,372.429993 217.527649,372.696960 
	C217.137238,373.106293 217.039902,373.391113 217.143555,373.958435 
	C217.308014,374.368652 217.388702,374.536377 217.629761,374.954590 
	C221.443817,381.777557 218.453766,391.656433 211.729996,395.059418 
	C208.089905,396.901672 204.766953,399.796844 200.171082,398.664246 
	C199.373505,398.467682 198.461975,398.503113 198.093246,399.515594 
	C197.662933,400.697205 197.656662,402.309174 198.712769,402.674103 
	C206.339081,405.309814 204.368958,412.142181 205.304001,417.939270 
	C204.731766,418.534485 204.293060,418.716888 203.458191,418.828064 
	C201.082718,418.398407 199.293594,417.220490 196.957642,418.534943 
	C194.737823,419.784027 192.934769,417.719910 191.175766,416.550690 
	C189.328339,415.322754 187.923172,414.485046 185.463776,416.122742 
	C181.122818,419.013458 173.060532,416.307648 170.750031,411.647522 
	C169.059555,408.237946 171.201004,406.386017 173.788834,404.819214 
	C174.951828,404.115082 176.273331,403.906372 177.599930,403.747559 
	C179.197113,403.556427 180.597855,403.001465 180.815094,401.197205 
	C181.015533,399.532379 180.184570,398.369385 178.624710,397.684082 
	C177.132523,397.028503 175.245605,396.582916 175.371246,394.439758 
	C175.521790,391.872162 177.675308,392.013702 179.453384,391.788574 
	C180.917969,391.603088 181.310822,390.561432 181.511032,389.357910 
	C182.083389,385.917419 183.472641,383.065735 186.748169,381.348145 
	C188.208481,380.582428 188.728455,379.210052 188.170242,377.233917 
	C185.623688,378.060150 184.388168,380.503235 182.257889,381.672363 
	C178.764893,383.589325 177.042313,383.071991 175.777832,379.271088 
	C176.310623,378.210724 176.622482,377.422699 176.967163,376.317322 
	C179.025101,374.557587 182.057846,373.847321 180.479630,370.087830 
	C179.965027,368.862000 181.481537,367.877777 182.639496,366.782837 
	C183.496902,365.825378 184.166077,365.103333 183.108826,363.847961 
	C181.773224,361.014221 183.169601,360.216522 185.405319,360.219574 
	C187.758926,360.222809 190.047943,360.617432 191.474350,361.806946 
	C190.000809,360.875458 189.794052,359.148132 190.821350,357.031494 
	C188.459854,358.108307 186.093781,358.819946 183.729141,359.449371 
	C181.233856,360.113586 178.866516,359.218719 179.739014,356.341614 
	C180.499451,353.834045 179.851730,352.762756 178.050644,351.445709 
	C176.588684,350.376617 175.758194,348.642670 174.436279,347.356384 
	C170.154892,343.190521 170.118927,342.010254 173.808945,337.419189 
	C172.341873,336.244904 170.609848,337.342804 169.075424,336.842712 
	C166.960648,336.153381 165.738632,334.653351 165.312698,332.590179 
	C164.982529,330.990875 165.273071,329.531738 167.726624,329.781036 
	C169.116913,330.414825 168.625290,332.279724 170.276855,332.401672 
	C170.831482,328.603119 169.073334,325.627106 167.050751,322.359039 
	C167.324951,321.599884 167.736679,321.334320 168.608307,321.170868 
	C170.283707,321.255066 171.266907,321.996399 172.485596,323.030884 
	C173.439911,324.212280 174.460632,324.782043 175.311829,325.542847 
	C176.997330,327.049347 178.688110,328.426117 180.783905,326.223389 
	C182.930786,323.967010 180.383148,322.793762 179.448914,320.883453 
	C179.203247,319.145386 179.871063,318.170807 181.301117,317.265533 
	C182.804947,316.744934 183.925369,317.008301 185.057327,317.343323 
	C185.828766,317.571686 186.554581,317.840118 187.186371,316.844330 
	C188.518570,314.744537 188.280624,307.675842 186.596695,306.003906 
	C184.987747,304.406464 182.201447,303.108429 183.570160,300.407776 
	C185.080048,297.428528 187.574432,295.100067 191.292725,294.846893 
	C191.956573,294.801666 192.623688,294.864197 193.284576,294.942963 
	C195.743607,295.236053 197.995804,294.897217 199.397125,292.578125 
	C200.872131,290.137054 199.040787,288.569366 197.754822,286.856445 
	C196.523605,285.216461 195.260910,283.259705 196.657440,281.404175 
	C198.193283,279.363464 200.836517,278.498260 203.281647,278.563477 
	C205.692108,278.627777 206.167496,280.946320 206.455475,282.999329 
	C206.523666,283.485565 206.526596,283.986786 207.033493,284.914612 
	C208.211060,281.338013 206.194687,278.606995 205.785339,275.344849 
	C205.655441,274.264099 205.709747,273.544373 205.921616,272.478790 
	C207.595703,267.636749 211.137665,269.984497 214.492538,270.001465 
	C214.038483,268.026672 211.078552,266.586365 213.178131,264.552399 
	C214.603622,263.171509 216.389114,262.273499 218.338364,261.799774 
	C220.712585,261.222809 223.291306,261.325592 225.567383,259.427185 
	C226.273987,258.723724 225.670258,259.347534 226.134674,258.869263 
	C226.458618,258.535583 226.793228,258.212250 227.109390,257.870941 
	C226.759979,257.972229 226.708450,258.373810 226.202179,258.785461 
	C225.033966,259.984161 223.823669,260.134705 222.780777,259.527344 
	C219.924835,257.864197 218.091949,258.752197 216.040833,261.168335 
	C213.028473,264.716797 211.297318,260.740356 208.817749,259.353363 
	C207.383331,258.780670 206.259995,258.368652 204.920258,257.543579 
	C203.874008,256.568542 203.624222,255.569717 203.198792,254.247025 
	C203.107986,253.361877 203.169617,252.864365 203.483826,252.049728 
	C206.880356,248.438171 209.256714,251.571228 212.156403,253.208923 
	C208.497528,247.947754 208.636200,245.018845 212.757660,239.250366 
	C217.221542,238.574570 220.772156,242.211960 225.561340,241.000458 
	C226.738144,241.497971 227.255005,242.268845 227.141617,243.074860 
	C226.609619,246.856735 228.479980,248.922409 232.237015,250.024796 
	C232.316650,246.595749 235.863510,244.133652 235.130127,240.235886 
	C233.892227,240.242142 233.770294,241.229996 233.229492,241.745285 
	C230.187241,244.643860 228.180496,244.073868 227.052750,239.929810 
	C226.838135,239.141174 226.767075,238.310410 226.212830,237.275467 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M175.591370,378.998901 
	C177.822418,384.295227 180.630585,380.844025 182.774780,379.519104 
	C184.699295,378.329926 186.146057,376.378937 187.847809,374.812073 
	C188.489502,374.221252 189.344925,373.955048 190.135803,374.463989 
	C190.960770,374.994873 190.907227,375.883850 190.600601,376.687958 
	C189.609619,379.286896 190.861649,382.911041 185.995880,383.610657 
	C183.684509,383.943024 182.985428,387.657715 182.994171,390.455475 
	C183.002213,393.028625 182.979355,395.511902 179.216202,393.334015 
	C178.253418,392.776794 176.684875,392.688385 176.408966,394.270020 
	C176.108917,395.990021 177.666931,395.964569 178.782913,395.970520 
	C182.711075,395.991364 182.932587,399.015350 182.758926,401.543121 
	C182.573578,404.240356 180.963287,406.159698 177.651230,405.384857 
	C174.733459,404.702209 172.603149,406.632080 171.532303,408.644379 
	C170.172272,411.200073 172.967392,412.237549 174.698349,413.413513 
	C180.355499,417.256866 181.664139,417.124451 186.239288,412.172852 
	C187.250366,411.078583 188.244812,411.527039 188.579605,412.481354 
	C189.595215,415.376373 193.511017,414.404144 194.575699,417.245667 
	C194.905334,418.125427 196.093231,417.839600 196.723129,417.226044 
	C198.781113,415.221283 200.652939,416.194641 202.771515,417.730377 
	C202.623062,421.283051 199.419846,420.661163 197.539856,421.792938 
	C196.202148,422.598328 194.366394,422.583740 193.714783,424.825317 
	C193.346436,425.270081 193.175690,425.427429 192.702988,425.756470 
	C190.281891,427.494446 186.886383,424.529053 185.147720,428.251465 
	C179.837311,433.933197 175.433655,439.905548 171.200943,446.013031 
	C170.236877,447.404114 168.920898,448.418732 167.320557,449.627686 
	C165.702011,450.554810 165.377838,451.777313 165.378815,453.525574 
	C165.098373,455.121338 164.515793,456.146973 163.074829,456.978088 
	C159.892929,457.252289 157.840393,458.241852 157.163681,461.399780 
	C156.673401,463.687683 154.961838,465.345642 153.091675,467.205627 
	C151.038971,470.661896 147.555084,472.212555 145.171509,475.346710 
	C144.837509,475.844513 144.681213,476.032074 144.229492,476.446716 
	C143.249664,477.037781 142.514526,477.207489 141.358002,477.214233 
	C140.459061,477.061554 139.999527,476.834167 139.296326,476.250244 
	C137.576508,476.161041 137.134689,477.108795 136.924210,478.385590 
	C136.639267,480.114166 135.622086,480.997284 133.770599,480.765137 
	C128.808929,480.142975 124.810890,481.940033 121.561653,485.644104 
	C120.158936,487.243164 119.242836,486.549896 118.849129,484.856995 
	C117.846497,480.545746 113.911354,475.923157 120.818047,472.558594 
	C122.645615,471.668335 121.291451,468.631622 120.251221,467.268311 
	C118.633736,465.148499 117.944092,467.511078 117.177338,468.577728 
	C116.367317,469.704590 115.428986,470.839539 113.967308,470.392517 
	C112.526794,469.951996 113.329681,468.363647 112.952721,467.355072 
	C111.584175,463.693420 108.394173,460.173035 112.325729,456.190674 
	C112.435081,456.079895 112.041695,455.318420 111.742592,454.986755 
	C110.707214,453.838715 108.473595,453.096802 109.804527,451.055481 
	C111.016693,449.196350 112.829849,450.796295 114.384628,450.797760 
	C115.345367,450.798676 116.306526,450.347168 117.633751,450.049377 
	C118.547310,449.472595 119.197586,449.201447 120.313484,449.281860 
	C121.402260,449.671417 121.913811,450.219330 122.258469,450.932159 
	C122.452492,451.333405 122.688217,452.015442 123.091499,451.822205 
	C123.638596,451.560028 123.320915,450.744202 123.704865,449.894287 
	C125.671692,448.010742 127.774208,447.906250 130.317459,448.349091 
	C131.719086,448.739166 132.772247,448.836670 134.103012,448.129395 
	C135.524933,447.623566 136.692078,447.556976 137.842026,447.400330 
	C139.847610,447.127106 140.497879,446.213867 139.313599,444.386841 
	C137.316452,441.305756 138.088211,439.422058 141.433075,437.739532 
	C145.020416,435.934998 144.623047,430.882202 140.999039,429.060364 
	C139.380157,428.246552 137.475784,428.032135 135.855911,426.472687 
	C135.009933,424.717529 135.768524,423.680511 136.718689,422.663940 
	C137.479752,421.849762 138.621445,421.249207 138.492981,419.862579 
	C132.495026,418.431274 131.916306,418.801147 131.942978,424.208710 
	C131.953674,426.377960 131.620331,428.503937 131.288483,430.988647 
	C130.624359,433.414185 130.002045,435.492096 129.183228,437.494202 
	C128.547913,439.047729 126.517494,440.452728 125.864952,438.997894 
	C123.926682,434.676575 119.151878,436.031494 116.251770,433.247864 
	C115.250000,433.000000 114.500000,433.000000 113.375000,433.000000 
	C111.015411,431.079132 111.269516,428.517761 110.955399,425.783417 
	C110.570671,424.294556 110.650627,422.883362 108.777023,422.357483 
	C108.111824,422.017151 107.772301,421.755463 107.230988,421.228943 
	C105.035301,419.813232 102.849632,420.066162 100.698929,419.970642 
	C99.341492,419.910370 97.163033,420.490601 97.366867,418.197998 
	C97.568932,415.925262 99.375702,414.861725 101.698296,415.167633 
	C103.344460,415.384399 104.409698,417.157043 106.620216,416.990051 
	C110.169586,415.832581 113.330460,414.605072 117.105881,415.771606 
	C118.091278,416.240753 118.528015,418.051117 119.624306,415.986938 
	C121.045769,412.415009 121.606682,408.218536 127.082947,410.014160 
	C127.768776,410.491760 126.605942,411.297333 127.955200,411.368652 
	C128.783432,411.601929 129.224411,411.910706 129.703812,412.348694 
	C129.761398,409.115234 127.634270,405.024445 133.713120,404.777344 
	C134.311493,405.788391 134.622971,406.576813 134.967224,407.682617 
	C135.393982,408.595367 135.926056,408.693176 136.779114,408.216797 
	C143.140915,407.615906 136.734268,404.984833 138.198761,403.126862 
	C141.823425,403.637512 145.821884,404.220215 149.826599,404.756195 
	C151.612015,404.995148 154.188492,405.588806 154.373993,403.243500 
	C154.603027,400.347595 152.210205,401.191162 150.392670,401.661652 
	C149.829163,401.807526 148.833923,401.718018 148.565750,401.350281 
	C145.955841,397.771393 142.568054,397.715942 138.775681,398.876312 
	C138.277191,399.028809 137.580551,398.533691 136.464172,398.173462 
	C137.935593,396.421570 141.287598,396.881775 140.860321,394.053253 
	C140.462784,391.421600 137.921280,390.807648 136.349579,389.290222 
	C135.885818,389.696655 135.455002,389.897858 135.452774,390.103668 
	C135.432709,391.961304 137.261398,394.512177 134.872711,395.460205 
	C132.764893,396.296814 130.272736,395.074219 128.756775,393.093109 
	C126.706299,390.413513 122.518417,390.940125 120.855858,387.685486 
	C120.735855,387.450592 119.567154,387.490692 119.085548,387.759857 
	C118.279610,388.210327 118.112610,389.112000 118.556641,389.934601 
	C119.161461,391.055145 119.880890,392.113800 120.637756,393.339142 
	C113.683083,393.250916 112.228287,391.416992 114.265999,385.502777 
	C114.725632,384.168762 115.121552,382.965393 114.169411,381.751770 
	C112.646713,379.810883 112.904274,377.765320 113.609451,375.599792 
	C114.132568,373.993378 114.129456,372.531189 112.897285,370.965271 
	C110.297127,367.660828 111.295761,364.357025 115.199562,362.222870 
	C115.903267,361.838165 116.565369,361.042358 117.384850,361.720276 
	C118.180458,362.378357 117.751755,363.231812 117.355698,363.978394 
	C117.200630,364.270721 116.969978,364.522034 116.801437,364.808319 
	C115.795631,366.517090 115.526176,368.325836 116.779739,369.947601 
	C118.332603,371.956604 119.650276,369.012878 121.657684,369.693268 
	C120.809341,371.584808 120.045753,373.484650 119.112999,375.297455 
	C118.495560,376.497437 117.401451,377.794769 119.135086,378.656311 
	C120.449547,379.309540 122.154442,379.395905 122.858711,377.450867 
	C123.245407,376.382935 123.333061,374.782532 125.017708,375.781006 
	C126.254173,376.513794 128.193283,376.948120 128.357880,378.533051 
	C128.581390,380.685333 126.473274,379.711304 125.395454,380.091766 
	C124.402832,380.442108 123.435310,380.811646 123.439476,382.009064 
	C123.444077,383.332458 124.432877,384.363342 125.501823,384.550903 
	C128.485580,385.074280 128.895401,387.096924 128.449982,389.807220 
	C136.486298,384.679016 143.103012,385.363892 145.826645,391.304596 
	C147.255737,391.362885 147.627335,389.973267 148.433655,389.254333 
	C150.699570,387.233917 150.729767,385.492340 147.297531,385.024536 
	C143.530319,384.511078 141.783508,382.722290 141.911316,378.728302 
	C142.022110,375.265991 140.273788,374.200531 137.116302,376.220001 
	C135.804596,377.058899 134.225037,377.618744 133.323166,375.718750 
	C132.603683,374.203033 133.762619,373.315033 134.879425,372.467010 
	C137.755234,370.283295 141.474899,370.724274 144.702682,369.615143 
	C145.642349,369.292267 148.358658,373.265747 149.785553,375.564697 
	C150.632889,376.929840 150.901611,378.621521 152.836197,377.309631 
	C154.417740,376.237152 153.930527,374.805054 152.900726,373.418884 
	C151.123520,371.026764 149.446320,368.559692 147.753433,366.105988 
	C147.163086,365.250366 146.693192,364.191925 147.878067,363.578461 
	C148.763641,363.119965 149.458633,363.983368 149.800629,364.728912 
	C151.837128,369.168671 155.987869,371.397705 159.439270,374.434814 
	C162.309250,376.960266 164.897614,380.216400 164.256714,384.627106 
	C163.961502,386.658691 164.995819,387.821716 166.568604,388.586731 
	C168.066864,389.315521 169.450485,388.933533 170.605728,387.649994 
	C171.377060,386.792999 172.285614,386.060425 173.121002,385.259857 
	C173.949966,384.465454 175.076523,383.727478 174.463623,382.351715 
	C173.972778,381.249939 173.156189,380.373322 171.744934,380.684723 
	C170.319229,380.999329 168.392914,380.854279 168.629272,379.355164 
	C169.014786,376.910187 170.934540,378.842072 172.193436,378.863037 
	C173.190750,378.879639 174.187149,378.951599 175.591370,378.998901 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M206.682861,274.739349 
	C208.075424,278.821014 210.896759,282.440186 208.251114,286.690491 
	C207.743393,287.506165 207.687820,288.795410 206.389526,288.647919 
	C205.120270,288.503784 205.068588,287.347137 205.029205,286.340637 
	C204.990189,285.342896 205.058380,284.339172 204.985931,283.344910 
	C204.789352,280.647736 205.372620,279.155548 200.927856,280.818390 
	C194.243820,283.319092 197.763535,284.953705 200.296844,287.127869 
	C202.348724,288.888824 202.454468,290.920868 201.396576,292.932709 
	C199.904114,295.770966 197.458725,297.619049 194.067398,296.758453 
	C191.674652,296.151184 189.768173,295.844727 187.659882,297.744751 
	C183.281830,301.690308 183.061493,301.738556 188.055969,304.785583 
	C189.919464,305.922455 190.104935,307.241486 189.963409,308.895447 
	C189.695465,312.026581 189.406311,315.167084 188.876648,318.261017 
	C188.465881,320.660400 187.196564,321.621521 185.240768,319.390930 
	C184.530579,318.580963 183.799728,318.048706 182.362823,318.010834 
	C180.139282,314.747101 184.987915,315.081696 184.793274,312.409393 
	C182.541718,311.634003 180.028442,312.950348 177.077469,311.492615 
	C177.220154,311.608551 177.346252,312.890167 177.520889,312.246765 
	C177.579193,312.031921 177.690414,312.170837 178.010193,312.312805 
	C180.317581,313.337128 179.941559,315.028259 179.209641,316.843292 
	C178.690765,318.130005 177.407349,319.475952 179.714355,320.810547 
	C181.294342,322.032043 183.936783,321.548645 183.862061,324.067596 
	C183.784439,326.684326 181.689682,327.900085 179.729492,329.109100 
	C177.603363,330.420441 176.496399,328.990173 175.264069,327.565369 
	C174.340775,326.497864 173.188370,325.628479 172.068985,324.335449 
	C172.169571,322.151093 171.338409,321.363129 169.250580,321.820557 
	C168.556030,321.999176 168.111450,321.999603 167.333435,322.000000 
	C165.968735,321.124756 166.454315,320.371216 167.050705,319.450745 
	C169.670944,315.406616 168.993118,313.936340 164.192841,312.967285 
	C162.895508,312.705383 161.623718,312.292450 160.317871,312.101318 
	C154.360046,311.229248 153.493195,311.822205 153.039490,317.696472 
	C152.864395,319.963531 152.098587,321.067841 149.763229,320.999023 
	C147.107773,320.920776 144.422928,321.176941 141.798218,320.875366 
	C137.986984,320.437408 136.391266,317.662628 137.590683,314.116180 
	C138.590363,311.160339 134.153107,304.380646 131.418884,305.304779 
	C127.780304,306.534546 126.694260,302.761780 123.954323,302.587891 
	C123.186653,302.539154 123.025391,301.340881 123.456337,300.483246 
	C123.875137,299.649841 124.629570,299.155182 125.478760,299.416901 
	C127.494232,300.038055 129.764191,299.954163 131.369156,301.774750 
	C132.430389,302.978546 133.805756,303.058136 134.563721,301.412140 
	C135.279388,299.858002 135.096405,298.391083 132.999084,298.081848 
	C130.960526,297.781281 128.368790,297.531464 129.468765,294.735931 
	C130.685074,291.644745 133.070892,293.628876 134.909409,294.480286 
	C137.519150,295.688843 140.130981,297.453918 141.639801,293.160767 
	C141.733948,292.892883 142.288818,292.502930 142.349472,292.547821 
	C146.444824,295.579559 148.106873,290.046997 151.283447,290.030975 
	C153.186462,290.021362 154.770767,289.593018 156.291809,288.306610 
	C158.878128,286.119202 160.501404,286.986725 160.990417,290.626282 
	C162.460281,292.560211 163.525208,294.660522 166.407654,294.792542 
	C169.401245,295.665741 171.839935,297.390961 175.153824,296.869263 
	C176.704742,296.930908 177.935516,296.979401 179.428345,296.727783 
	C179.931244,296.989166 180.469620,297.111115 180.469467,297.065430 
	C180.467346,296.401581 180.779312,297.060120 179.878632,296.904907 
	C179.501923,296.589294 179.359436,296.446045 179.068634,296.029236 
	C178.299149,294.475739 178.623810,292.721100 176.743790,291.738159 
	C175.265686,290.224030 173.541763,289.396667 172.891846,287.324982 
	C175.630417,283.888885 177.136658,280.817200 172.084152,278.025146 
	C174.484299,276.383057 176.759903,277.375275 178.749298,276.830048 
	C179.576004,276.603516 180.398361,276.185242 180.367981,275.229034 
	C180.333191,274.134033 179.343094,274.034943 178.526215,274.050690 
	C176.869781,274.082703 175.120728,273.915314 173.580383,274.386993 
	C170.556610,275.312988 168.015503,275.278961 166.132828,272.348206 
	C165.669464,271.626862 165.029007,270.981140 164.091187,271.382568 
	C162.845963,271.915558 163.205643,272.955139 163.472794,273.929749 
	C163.907257,275.514679 164.811401,277.551514 163.048111,278.452026 
	C160.884293,279.557037 159.932083,277.316376 158.923416,275.852325 
	C158.107330,274.667847 157.173645,273.702850 155.643524,274.377411 
	C153.980103,275.110718 154.150116,276.287079 155.058533,277.623016 
	C156.272385,279.408173 155.441635,280.514130 153.601654,280.818878 
	C151.564011,281.156372 150.819931,280.094788 151.446732,278.150757 
	C151.788910,277.089447 151.756012,275.797913 150.826294,275.470123 
	C145.996567,273.767426 145.171463,267.966400 140.774338,265.884766 
	C138.874313,264.985260 138.076859,264.686005 140.123688,262.249023 
	C142.238251,259.731354 141.850327,255.869247 137.368469,254.054840 
	C136.001617,252.083359 137.288010,251.422592 139.160248,250.783203 
	C142.380066,250.667389 144.501160,251.979019 146.497192,254.350174 
	C147.452042,256.155670 147.485779,257.729218 147.463318,259.674500 
	C147.465363,260.368591 147.343567,260.816467 147.516708,261.012787 
	C149.785614,263.585876 156.694839,263.687988 159.250549,260.868774 
	C160.100876,256.811707 162.648346,255.537506 166.155960,255.351059 
	C168.408966,255.231277 170.835526,255.435638 172.749298,253.130051 
	C175.738632,250.430984 174.830551,247.237686 174.855331,243.855286 
	C176.065170,240.119415 178.076569,237.896606 182.313141,238.279297 
	C185.133514,240.723389 181.778168,241.634583 181.517471,243.422791 
	C184.545425,239.329742 188.031342,240.866302 191.762085,242.744812 
	C192.000000,243.443970 192.000000,243.888550 192.000000,244.666565 
	C190.898254,246.372986 191.080414,247.553360 192.765778,248.771698 
	C193.000000,249.444443 193.000000,249.888901 193.000000,250.666672 
	C193.476700,251.493973 194.004379,251.931915 194.730988,252.650818 
	C195.960480,254.573181 196.886642,256.368500 198.579803,253.378387 
	C199.709473,251.383408 200.897705,249.060150 203.887909,251.684143 
	C204.001022,252.444366 204.000809,252.889175 204.000610,253.667572 
	C204.018082,254.876740 203.978668,255.764572 204.771515,256.759766 
	C206.166870,257.163422 207.334976,257.329254 208.752136,257.748169 
	C210.058121,259.337616 209.321915,260.357910 208.115265,261.573914 
	C206.230026,262.368256 204.797638,263.194702 203.280640,264.577301 
	C201.696075,265.484680 200.277893,265.790985 198.525085,266.217590 
	C194.575912,266.865173 191.700043,265.614990 189.341476,262.901031 
	C188.569412,262.012634 187.587296,261.330750 186.283951,261.881195 
	C185.977631,262.010559 185.799805,262.335815 185.834015,262.683533 
	C185.995361,264.323303 187.388031,264.183258 188.456375,264.379669 
	C190.585480,264.771118 192.711533,265.104614 194.346527,267.243408 
	C194.540955,267.795349 194.586212,268.026245 194.611435,268.608276 
	C194.649841,272.765594 191.950653,275.834534 191.745972,279.804871 
	C191.697937,280.758759 191.850403,280.530151 192.191498,279.810059 
	C194.943283,273.811157 199.791718,272.068787 206.682861,274.739349 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M146.173050,261.397247 
	C146.000000,259.285645 146.000000,257.571289 146.000000,255.428467 
	C144.715057,252.954575 144.252136,249.593048 140.234955,251.773010 
	C138.864883,252.053482 137.638138,251.909592 137.068787,253.664581 
	C136.517822,255.017838 135.791168,255.736496 134.317902,255.964935 
	C132.512894,257.585663 130.685272,257.464691 128.398331,257.019104 
	C126.611092,257.000000 125.222214,257.000000 123.416664,257.000000 
	C122.254028,257.609558 122.017975,258.571930 121.134392,259.494446 
	C120.630333,259.794739 120.411812,259.884430 119.845184,260.037231 
	C115.388283,258.448059 114.872849,256.618439 117.892853,254.026443 
	C119.212257,252.894012 121.199379,252.280075 120.301247,249.592636 
	C117.970345,249.756607 115.976624,253.407730 113.167648,250.301605 
	C112.413651,240.857941 118.908630,240.691422 125.624939,240.164749 
	C126.658104,239.358505 127.336609,238.727570 127.067795,237.334106 
	C126.999741,236.555832 126.999786,236.111633 126.999763,235.334335 
	C127.304192,234.873978 127.557365,234.679810 127.879456,234.209366 
	C129.350128,231.269958 131.331131,232.313812 132.921646,233.456497 
	C136.295303,235.880295 139.453873,234.931458 142.669388,233.301666 
	C144.455002,232.396622 145.562881,231.323944 144.542297,229.014709 
	C143.404526,226.440292 143.996521,224.444321 147.214264,224.082611 
	C149.240982,223.854797 149.260605,222.305450 149.514221,220.827682 
	C150.123749,217.276016 150.373398,213.612259 152.760117,210.252808 
	C157.042038,209.498856 159.248886,210.729202 159.200867,215.358109 
	C159.174789,217.870865 160.567902,220.129761 163.742950,217.820236 
	C165.506134,216.537735 166.740265,217.791458 168.275040,218.421463 
	C171.123672,219.590729 173.533463,219.338776 174.094391,215.496429 
	C174.617615,211.912216 176.728134,208.882004 177.333313,205.154694 
	C177.939377,201.421967 181.934998,201.558655 185.073334,202.639481 
	C187.505737,203.477158 189.918625,205.240295 190.133133,200.659897 
	C190.180557,199.647415 191.228409,198.996780 191.866318,199.620667 
	C194.670135,202.362717 198.884018,201.029297 201.901764,203.633423 
	C200.673019,204.002869 199.338211,204.099533 198.020645,203.989532 
	C195.058456,203.742233 194.103104,206.253510 192.791473,207.975739 
	C191.350296,209.868057 193.599289,210.810013 194.529404,212.024811 
	C195.117752,212.793259 196.031448,213.411041 195.221802,214.793243 
	C191.764862,215.715225 188.850464,214.141388 185.779724,213.690857 
	C182.274124,213.176498 179.532333,214.823013 179.202789,217.977188 
	C178.831223,221.533508 181.904022,221.683655 184.105789,222.497986 
	C186.507370,223.386215 189.013260,221.543320 191.734604,222.773193 
	C192.089783,225.366196 189.870499,225.659393 188.533325,226.740173 
	C191.471863,229.486237 193.248871,233.394150 197.693634,234.393158 
	C198.855011,234.654175 200.491852,236.365891 199.577408,237.954163 
	C198.417023,239.969574 197.104813,238.113525 195.851868,237.546631 
	C194.101303,236.754593 192.671555,235.046402 190.220856,235.773865 
	C189.487106,236.563370 188.838562,236.808502 187.751007,236.760925 
	C186.205414,237.356598 186.060150,233.490158 184.387726,236.330475 
	C184.246231,237.357605 183.984634,237.992630 183.283005,238.781036 
	C179.799667,238.979691 177.520325,240.372864 176.143799,243.687256 
	C173.213562,246.246796 174.115784,249.419250 173.955170,252.656677 
	C173.713287,257.866089 169.974701,256.834930 167.043289,256.968170 
	C164.066116,257.103516 160.764984,256.531555 161.002289,261.589539 
	C156.075165,264.814178 151.222916,262.423279 146.173050,261.397247 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M183.574524,366.467590 
	C182.931229,368.763550 180.013580,369.801208 182.939941,372.792877 
	C184.862793,374.758667 183.380249,375.893494 177.410065,376.000000 
	C175.254181,374.468079 174.871552,372.902710 176.361038,370.834503 
	C177.435852,369.342041 177.199951,366.674561 175.561386,366.678894 
	C170.982620,366.690948 168.910767,363.037048 165.352005,361.048615 
	C164.724762,360.806854 164.331406,360.533203 164.379257,360.288208 
	C165.797379,353.030212 159.714981,351.465912 155.236115,348.254364 
	C155.105804,344.852936 151.436005,343.437653 151.517761,340.069061 
	C151.550644,338.715027 149.237122,338.750916 148.182327,339.626251 
	C145.356689,341.971252 142.302872,344.226990 140.128937,347.112793 
	C138.392014,349.418427 137.651627,348.560791 137.265350,346.844513 
	C136.703415,344.347839 135.969513,343.521637 133.050140,343.409241 
	C130.433548,343.308472 127.952759,340.686432 127.570320,337.787384 
	C127.340813,336.047577 129.728302,335.469421 131.329056,335.169739 
	C134.177902,334.636536 135.091980,336.796753 135.767380,338.902771 
	C136.228745,340.341370 136.269699,342.004852 138.345474,341.933105 
	C139.310837,341.899780 140.516693,341.932373 141.169220,341.395294 
	C142.090652,340.636841 141.361328,339.445343 140.783722,338.726410 
	C139.473312,337.095428 139.019775,334.481598 136.525558,334.133698 
	C131.889709,333.487000 133.269745,330.634308 134.545197,328.363525 
	C136.432770,325.002930 140.371704,324.782806 143.564362,323.568451 
	C144.782379,323.105133 145.203033,324.764313 144.924774,325.616241 
	C143.917953,328.698639 148.062607,331.222656 145.650253,334.557770 
	C144.902527,335.591522 146.625046,335.906403 147.576614,335.969910 
	C151.618561,336.239777 155.253830,332.975098 159.556519,334.701508 
	C160.513885,335.085602 160.845734,333.477142 161.072083,332.505585 
	C161.605087,330.217834 163.501755,328.960022 165.058716,327.519104 
	C167.737991,325.039520 167.912048,327.185364 167.998901,329.639191 
	C166.258286,330.295380 165.619965,331.330292 166.598618,332.794159 
	C167.453033,334.072205 167.970734,336.020416 170.154861,335.717865 
	C170.967819,335.605225 171.750336,335.154938 172.555435,335.128998 
	C174.116089,335.078766 176.131317,334.338409 176.758942,336.572296 
	C177.284943,338.444366 175.743912,339.280609 174.298157,339.729980 
	C172.147629,340.398407 171.456650,341.830933 172.825714,343.408417 
	C175.295273,346.253906 177.823608,349.261963 180.929001,351.273193 
	C183.331848,352.829407 184.588593,353.446808 181.781250,355.542572 
	C180.986954,356.135559 179.975159,356.727905 180.734695,357.896301 
	C181.385681,358.897705 182.663605,359.023376 183.328125,358.235229 
	C185.323486,355.868561 189.649902,357.819580 190.840851,353.994049 
	C191.099228,353.164124 192.269577,353.159180 192.979767,353.772949 
	C194.009796,354.663116 194.277267,355.892181 193.119781,356.752380 
	C189.840240,359.189484 191.134308,360.687378 193.999771,362.040283 
	C194.924332,362.476837 195.802155,363.145691 195.522293,364.298157 
	C195.393021,364.830475 194.771851,365.444336 194.235275,365.638397 
	C192.725769,366.184174 192.377258,364.955414 191.762177,363.925140 
	C189.844864,360.713501 186.401459,362.412415 183.727661,361.588379 
	C183.735916,362.196503 183.744186,362.804596 183.640671,363.961914 
	C183.098755,364.871826 182.267944,364.635651 182.113708,365.200165 
	C182.035782,365.485352 182.775665,365.695160 183.574524,366.467590 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M135.665329,425.912079 
	C137.273834,426.326599 138.548035,426.651794 139.821457,426.980072 
	C145.487183,428.440582 147.739395,433.773651 144.819214,438.683594 
	C143.932663,440.174286 142.785416,439.064087 141.761887,439.205688 
	C139.506088,439.517731 138.246613,440.673645 139.976532,442.775909 
	C141.222794,444.290466 143.391586,445.569305 142.270599,447.676392 
	C141.058838,449.954163 138.393829,448.735901 136.354279,448.990936 
	C135.866776,449.051910 135.365173,449.000000 134.435028,449.000000 
	C133.351898,449.812378 132.486740,449.827362 131.285797,449.830444 
	C128.911179,449.213470 126.820312,449.475830 124.364120,449.992157 
	C123.314056,450.579712 122.541779,450.732178 121.342957,450.235474 
	C120.250458,450.000000 119.500305,450.000000 118.375076,450.000000 
	C117.754997,447.966431 116.715317,446.363373 115.384979,444.858459 
	C114.243080,443.566742 111.683792,442.254578 115.142242,440.587616 
	C115.864761,440.239380 115.663422,439.281097 115.127373,438.927582 
	C111.576424,436.585907 115.118660,435.325623 115.938614,433.323669 
	C117.780518,433.145844 119.424438,434.471191 121.293228,433.733154 
	C124.281425,432.552979 126.135406,432.670471 125.241196,436.702332 
	C125.053894,437.546906 125.783516,438.371216 126.654984,438.447174 
	C127.700043,438.538269 127.957542,437.612122 127.957802,436.755920 
	C127.958382,434.850159 128.853317,433.270111 129.846680,431.331024 
	C132.059052,429.565308 133.441589,427.433899 135.665329,425.912079 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M81.345261,385.918335 
	C78.683968,384.901978 76.419373,384.573669 79.076630,380.777313 
	C80.832840,378.268280 77.312820,376.531342 74.561195,376.026917 
	C73.332054,375.801636 71.668159,376.375549 71.287399,374.580658 
	C71.004753,373.248322 72.132996,372.584137 73.126167,371.919342 
	C74.641273,370.905182 76.554817,369.785767 75.560173,367.645264 
	C74.441368,365.237610 72.324562,366.148193 70.356873,366.702332 
	C69.165436,367.037872 67.996765,368.020508 66.337906,367.171387 
	C67.544197,363.842743 71.679344,362.147827 71.464539,358.010956 
	C71.415115,357.059296 73.004028,357.020264 74.003464,357.074554 
	C75.482002,357.154816 76.448639,357.857269 76.860664,359.352661 
	C77.273987,360.852844 76.328682,362.793945 78.635376,363.903687 
	C82.360580,367.419037 87.255127,367.487885 90.649643,364.079163 
	C91.304909,364.126434 91.558167,364.320465 91.880501,364.790741 
	C93.378914,366.173065 95.700287,366.144592 96.133858,368.675049 
	C93.690315,371.095062 91.979187,374.009827 88.447342,374.436584 
	C88.039818,374.485809 87.310654,375.033142 87.345505,375.131378 
	C89.347099,380.773834 83.266693,382.121765 81.345261,385.918335 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M117.362152,417.253723 
	C113.747162,415.511322 110.725304,418.407104 107.251556,417.250153 
	C106.882210,416.186127 106.906967,415.317017 106.617401,414.569702 
	C105.983856,412.934631 103.518875,411.287598 104.711594,409.844604 
	C106.436020,407.758392 107.640579,410.747925 109.126366,411.399719 
	C110.000282,411.783112 111.001579,411.729492 111.348854,410.982086 
	C112.804764,407.848663 116.729927,406.871552 118.042206,404.265717 
	C120.291328,399.799713 122.038063,401.755890 124.208664,403.740173 
	C126.206596,405.566589 127.926361,408.550385 130.175690,403.782379 
	C130.751297,402.562256 132.821594,402.528168 133.919083,404.655640 
	C131.119492,404.839081 131.080276,406.740387 130.946869,408.875641 
	C130.822433,410.867310 131.950317,413.191071 129.378357,414.892303 
	C128.946472,413.865021 129.090607,412.638336 127.335686,412.067993 
	C125.788284,408.466095 124.062447,410.339447 122.910126,411.843048 
	C121.973488,413.065125 121.677139,414.777954 121.048416,416.636597 
	C120.009262,417.822205 118.933487,418.095459 117.362152,417.253723 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M154.792557,348.266663 
	C157.723831,347.821655 160.339676,347.880310 161.618286,350.950562 
	C161.678665,351.095551 161.815979,351.213226 161.931396,351.329834 
	C164.490250,353.914429 165.724091,357.492218 165.002411,360.624359 
	C164.925491,361.993469 165.053085,363.057831 164.735229,363.966370 
	C163.947433,366.218079 161.910828,366.489197 160.218643,365.664612 
	C158.117447,364.640656 159.465240,363.106720 160.491287,362.058044 
	C162.527435,359.977020 162.142776,357.862885 160.800598,355.701660 
	C159.710968,353.947113 158.113266,352.081329 156.101242,352.654327 
	C154.078217,353.230530 155.111893,355.427856 155.482788,356.970917 
	C155.747589,358.072662 155.637268,359.039917 154.524094,359.463318 
	C153.140762,359.989441 152.305542,358.934052 151.685074,357.984863 
	C150.709549,356.492554 149.326447,354.922485 150.764755,353.017456 
	C151.945801,351.453125 153.304535,350.022980 154.792557,348.266663 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M172.666672,287.000000 
	C174.066803,288.383972 176.634995,288.002197 176.953201,290.660797 
	C178.251419,294.056183 175.540039,294.336273 173.921936,295.330292 
	C172.027206,296.494232 169.862854,295.985077 167.405518,296.084381 
	C164.109253,295.896545 160.378159,297.004150 161.000519,291.418610 
	C164.109665,291.684357 165.367676,287.862946 168.629364,288.008392 
	C169.390823,287.559875 169.841843,287.209290 170.711121,287.026550 
	C171.444443,287.000000 171.888901,287.000000 172.666672,287.000000 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M111.779663,424.776306 
	C114.419960,427.166473 111.147606,430.043915 112.955414,432.646179 
	C110.233337,430.657471 108.285469,426.598969 103.429306,428.689331 
	C102.736923,428.987366 102.142586,427.896637 102.078499,427.000366 
	C101.993660,425.813904 102.055099,424.690918 103.361832,424.123199 
	C104.685783,423.547943 106.223885,423.280365 106.925743,421.351196 
	C107.444679,421.001099 107.889061,421.000977 108.666718,421.001038 
	C110.147141,421.973114 110.571770,423.465698 111.779663,424.776306 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M137.284271,363.764526 
	C136.251282,364.569122 135.338287,364.802734 135.476868,363.469879 
	C135.614319,362.147644 136.110519,360.945282 135.139999,359.696564 
	C134.225815,358.520264 132.014603,357.254913 134.248917,355.689850 
	C135.863327,354.559052 137.291306,356.234802 138.623764,357.163727 
	C138.758926,357.257965 138.915680,357.365540 138.983643,357.505249 
	C140.148895,359.900543 144.913055,359.790344 144.138107,362.620697 
	C143.268356,365.797333 139.635223,361.781219 137.284271,363.764526 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M133.668930,256.776550 
	C135.041351,259.079254 135.630661,261.116302 132.544327,261.849579 
	C130.147247,262.419067 128.837570,261.375092 129.003723,258.397339 
	C130.137772,256.592682 131.705948,256.477325 133.668930,256.776550 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M90.366798,382.028320 
	C92.253441,378.588470 93.633209,381.376465 95.015366,382.226044 
	C95.904335,382.772461 95.412971,383.630249 94.596558,384.119019 
	C92.396332,385.436249 91.312309,384.062256 90.366798,382.028320 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M163.452209,370.485657 
	C163.297455,367.358795 165.232712,367.963654 166.804214,368.297455 
	C167.733673,368.494934 168.042618,369.490692 167.606369,370.300140 
	C166.567032,372.228638 165.163284,372.342590 163.452209,370.485657 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M104.415466,461.570190 
	C103.334564,460.543762 101.461304,459.713379 103.020096,458.438263 
	C104.204269,457.469543 105.504501,458.781738 106.177818,459.895203 
	C107.152733,461.507416 106.198616,461.901520 104.415466,461.570190 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M119.779495,259.222351 
	C120.166687,258.999786 120.333374,258.999603 120.750092,258.999084 
	C121.618896,258.948547 122.150162,259.133392 122.790466,259.793701 
	C122.829285,260.852051 122.658875,261.704132 122.244080,262.778076 
	C120.933037,263.000000 119.866386,263.000000 118.399734,263.000000 
	C117.880974,262.825378 117.762215,262.650787 117.632126,262.158875 
	C117.850502,260.705902 118.756638,260.117249 119.779495,259.222351 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M77.837173,450.709351 
	C76.781700,451.668945 75.913773,454.477386 74.060043,451.317719 
	C74.797287,448.105103 76.179634,448.906921 77.837173,450.709351 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M139.000000,476.407349 
	C139.444443,475.999969 139.888901,475.999969 140.666672,476.000000 
	C142.491318,477.608215 142.498993,479.352875 141.240540,481.596954 
	C141.003586,482.001740 141.000000,482.000000 141.000610,482.000610 
	C137.916733,481.205139 139.371628,478.657562 139.000000,476.407349 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M96.245911,368.761871 
	C94.765762,367.856812 93.531494,366.713623 92.149071,365.285217 
	C94.268997,365.191803 97.285126,364.430267 96.245911,368.761871 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M59.931694,433.664581 
	C59.461792,434.806274 58.988323,435.957275 57.773582,435.380859 
	C56.705849,434.874176 57.100426,433.653503 57.001362,432.352905 
	C58.135632,432.053070 59.362041,431.909943 59.931694,433.664581 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M80.998093,456.628662 
	C78.984146,456.698486 77.652588,455.945190 78.872849,453.294800 
	C81.019142,453.256012 80.995323,454.764404 80.998093,456.628662 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M134.000000,256.750000 
	C132.575562,257.301392 131.151138,257.602783 129.363647,257.952087 
	C128.695816,257.873474 128.442490,257.679657 128.120300,257.209290 
	C129.494110,255.431137 131.436646,256.192017 133.597885,256.000000 
	C134.000000,256.000000 134.000000,256.500000 134.000000,256.750000 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M136.667099,408.000793 
	C136.779282,409.297424 136.548660,410.799164 134.793884,410.431854 
	C133.908951,410.246613 134.077423,409.170441 134.771240,408.205139 
	C135.444565,408.000122 135.889114,408.000244 136.667099,408.000793 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M66.750153,447.000000 
	C66.893166,447.886047 66.609703,448.643707 65.312531,448.945862 
	C64.536179,449.173157 64.151810,449.075348 63.929482,448.332703 
	C64.471741,447.686707 64.943497,447.373413 65.707932,447.030060 
	C66.000610,447.000000 66.500305,447.000000 66.750153,447.000000 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M122.999802,259.625000 
	C122.454239,259.819458 121.908791,259.638916 121.181732,259.228577 
	C121.106865,258.112885 121.390808,257.355835 122.687531,257.054047 
	C122.999969,257.750000 122.999939,258.500000 122.999802,259.625000 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M90.292618,312.969147 
	C90.198883,312.126984 90.204659,311.030609 91.817978,311.796021 
	C91.528412,312.313171 91.056824,312.626343 90.292618,312.969147 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M57.000317,431.666687 
	C56.372410,431.893402 55.619598,431.828003 55.578915,431.043060 
	C55.563347,430.742798 56.129978,430.412323 56.715263,430.047791 
	C56.999550,430.444458 56.999863,430.888885 57.000317,431.666687 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M170.817932,286.795959 
	C170.528259,287.313324 170.056534,287.626648 169.292084,287.969971 
	C169.198624,287.128235 169.203644,286.030762 170.817932,286.795959 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M91.367729,681.013794 
	C91.864494,678.656250 94.510742,676.715759 92.240021,674.244263 
	C95.074791,671.389404 97.555077,679.488464 99.987564,673.360596 
	C104.803368,673.660645 98.455482,679.227173 103.087616,679.995422 
	C106.614868,680.580444 108.286789,682.974182 108.991364,686.628418 
	C108.833534,687.000000 108.666771,687.000000 108.249847,687.000000 
	C102.115150,686.270386 95.560928,687.364441 91.367729,681.013794 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M157.142212,717.133179 
	C156.347122,716.723999 155.830063,716.536560 155.155563,716.114136 
	C154.999924,715.163391 155.001709,714.447693 155.008057,713.372742 
	C157.515732,712.184875 160.018860,711.356262 162.783463,710.286621 
	C163.044952,710.045593 163.467407,710.082581 163.678955,710.096558 
	C164.283264,710.352112 164.565140,710.687134 164.791977,711.447754 
	C164.825134,712.004272 164.803284,712.227905 164.669800,712.768799 
	C164.437973,713.274231 164.317764,713.462402 163.971512,713.894714 
	C162.511398,714.837952 162.309250,716.331543 161.074432,717.445068 
	C160.363510,717.827209 159.912827,717.925232 159.096970,717.900391 
	C158.318619,717.646301 157.876129,717.482605 157.142212,717.133179 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M119.619934,258.982117 
	C119.954948,260.130768 119.537308,261.063019 118.280975,261.849731 
	C117.475418,262.543030 116.929848,263.063416 116.198166,263.797516 
	C115.556206,264.000092 115.111488,264.000214 114.333237,264.000793 
	C112.950668,261.954742 112.375916,259.332062 108.944305,260.822449 
	C108.026184,261.221222 106.704460,259.929077 107.350868,259.114105 
	C109.493912,256.412201 107.821327,251.569733 112.674812,250.148865 
	C114.297195,249.732651 115.966469,251.803329 116.891289,249.572983 
	C117.743034,247.518829 119.040222,246.644318 121.149895,247.303726 
	C122.389984,247.691345 123.094032,248.786163 122.857155,250.026123 
	C122.472809,252.037949 122.594757,254.382645 119.717072,255.012833 
	C116.154755,255.792984 115.446251,257.170166 119.619934,258.982117 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M81.327393,328.066040 
	C75.930939,328.352295 75.271729,325.804688 76.946922,321.378357 
	C80.008949,320.930969 82.283913,323.532013 85.418564,323.239899 
	C86.542336,323.135193 87.113281,324.404266 86.754944,325.400238 
	C86.156891,327.062469 85.627457,329.098541 84.369331,330.081818 
	C82.943260,331.196350 83.104263,328.132843 81.327393,328.066040 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M63.753040,447.902100 
	C64.126671,448.304596 64.320518,448.557770 64.790771,448.879761 
	C64.659615,453.383728 64.836319,457.564697 69.894516,459.879700 
	C63.682590,461.784607 61.781033,460.375122 61.787937,454.635071 
	C61.789478,453.350891 61.290298,452.066132 61.010387,450.390808 
	C60.620621,449.616211 60.483665,449.159943 60.794182,448.315552 
	C61.813656,447.656891 62.622189,447.248260 63.753040,447.902100 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M60.987240,447.634491 
	C60.999866,448.444458 60.999733,448.888885 60.999367,449.666687 
	C59.208813,450.277283 58.853466,451.330414 59.459866,452.880035 
	C60.383583,455.240479 59.392448,456.657806 57.001362,457.678375 
	C57.055973,453.519897 49.745686,449.262695 57.835228,445.296692 
	C59.588219,444.974426 60.954151,445.239807 60.987240,447.634491 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M69.159195,337.299438 
	C70.066452,337.000000 71.133202,337.000000 72.599976,337.000000 
	C73.395645,339.389893 75.312874,338.901123 76.882271,339.031525 
	C78.109589,339.133514 79.192055,339.496735 79.645500,340.738373 
	C80.049835,341.845520 79.483932,342.616791 78.524399,343.170105 
	C77.296799,343.877991 76.005409,344.256256 74.864807,343.178528 
	C72.959694,341.378357 71.161751,339.464722 69.159195,337.299438 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M54.714069,377.865845 
	C53.458958,377.442200 52.523357,377.173340 51.594437,376.883118 
	C50.217583,376.452911 49.809410,375.426971 50.310928,374.209229 
	C50.917332,372.736847 52.104290,373.199280 53.199566,373.639160 
	C54.757587,374.264923 55.959229,375.346619 57.017773,376.611389 
	C57.448799,377.126343 57.839554,377.770020 57.265007,378.303589 
	C56.508453,379.006226 55.767258,378.257355 54.714069,377.865845 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M78.999695,364.000000 
	C79.319633,363.271301 79.447403,362.305756 79.996925,361.859192 
	C81.526009,360.616638 83.338249,360.757629 85.066673,361.476501 
	C85.373856,361.604248 85.721001,362.382080 85.600189,362.673096 
	C84.734200,364.758881 82.818398,363.732971 81.331238,363.987000 
	C80.854691,364.068420 80.352524,363.999908 79.430885,364.000000 
	C78.999695,364.000000 79.000000,364.000000 78.999695,364.000000 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M107.809677,304.104309 
	C108.954163,304.737549 110.229492,305.464172 109.167854,306.371582 
	C107.702713,307.623932 106.116425,305.881622 104.518318,305.833496 
	C103.786812,305.811493 103.264252,305.168488 103.618347,304.448792 
	C104.627747,302.397186 106.139107,303.792572 107.809677,304.104309 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M84.000305,354.000000 
	C83.942368,354.324829 83.956001,354.685760 83.813843,354.968384 
	C83.068878,356.449738 82.349693,358.158447 80.203522,357.299957 
	C79.191093,356.894989 78.859032,355.711609 79.148552,354.744293 
	C79.977951,351.973297 81.923080,354.456543 83.634109,353.991455 
	C83.999390,354.000000 84.000000,354.000000 84.000305,354.000000 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M48.540245,402.546204 
	C47.120457,404.114532 45.766136,404.366272 44.713177,402.771820 
	C43.954117,401.622375 45.043236,401.006805 45.926918,400.621521 
	C47.507553,399.932404 48.886993,399.792358 48.540245,402.546204 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M112.364731,287.985901 
	C112.161018,286.511230 112.322037,285.022461 112.741684,283.266846 
	C115.075729,284.775238 116.824936,286.532715 112.364731,287.985901 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M100.759354,339.001434 
	C100.025055,341.638153 98.964043,340.318634 98.075706,339.450287 
	C97.504692,338.892120 96.943932,337.959869 97.956688,337.419403 
	C99.223190,336.743469 100.083282,337.593964 100.759354,339.001434 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M54.594231,383.460083 
	C53.236259,383.420776 53.003033,382.716553 53.396992,381.868927 
	C53.694096,381.229736 54.461586,380.997101 55.027348,381.380524 
	C55.958527,382.011658 55.492039,382.726105 54.594231,383.460083 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M86.897537,335.299805 
	C87.855247,336.024780 88.082153,336.795898 87.281868,337.382629 
	C86.711655,337.800690 86.006882,337.414551 85.689461,336.830902 
	C85.185875,335.905060 85.612823,335.363464 86.897537,335.299805 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M89.532700,330.739105 
	C90.539131,330.482544 90.852814,330.983551 90.725800,331.638977 
	C90.666702,331.943970 90.095245,332.429169 89.875603,332.372131 
	C89.161842,332.186829 89.142830,331.559570 89.532700,330.739105 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M59.227673,381.260010 
	C60.171753,381.321747 60.678524,381.659546 60.285202,382.366241 
	C60.143040,382.621643 59.437008,382.796936 59.119244,382.673004 
	C58.421833,382.401031 58.358307,381.855316 59.227673,381.260010 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M56.534111,463.044098 
	C56.176003,463.694183 55.816517,463.703400 55.499390,463.236877 
	C55.433086,463.139282 55.435963,462.913544 55.505295,462.818726 
	C55.836823,462.365143 56.196026,462.381836 56.534111,463.044098 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M58.000069,445.000000 
	C57.518513,444.710175 57.036880,444.420319 56.555252,444.130493 
	C56.721146,443.935944 56.887035,443.741394 57.052917,443.546875 
	C57.340683,443.853058 57.628452,444.159241 57.958267,444.732727 
	C58.000313,445.000000 58.000000,445.000000 58.000069,445.000000 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M65.047775,337.284058 
	C65.444504,337.000000 65.889008,337.000000 66.666885,337.000000 
	C66.893265,337.627930 66.827789,338.380951 66.042633,338.421082 
	C65.742264,338.436462 65.412117,337.869629 65.047775,337.284058 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M82.413078,316.658630 
	C82.611809,317.373138 82.362480,317.633301 81.807114,317.521667 
	C81.691872,317.498474 81.537178,317.334686 81.519936,317.218079 
	C81.437042,316.657349 81.710083,316.422760 82.413078,316.658630 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M116.293335,263.969818 
	C116.107002,263.113983 116.390450,262.356842 117.687241,262.055023 
	C117.999695,262.001221 117.999695,262.500610 117.999718,262.750305 
	C117.528412,263.313202 117.057083,263.626434 116.293335,263.969818 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M201.245422,640.703125 
	C197.994400,641.006897 195.303131,639.763184 193.387939,637.728638 
	C188.600662,632.643127 182.343918,630.938599 176.130005,628.516174 
	C172.131790,626.957520 166.766525,626.363281 164.480301,621.431030 
	C164.236725,620.905640 163.546921,620.391785 162.969559,620.245239 
	C152.108200,617.488892 148.042023,608.824219 144.916534,599.421021 
	C143.399063,594.855591 138.659927,592.541687 137.234711,587.923401 
	C136.906616,586.860229 135.674088,585.840820 136.095261,584.740234 
	C139.200989,576.624756 134.812332,570.093506 131.226151,563.728821 
	C128.385223,558.686829 129.002335,553.638123 129.010376,548.459595 
	C129.018524,543.222046 128.246674,538.384949 123.219284,535.377075 
	C122.450508,534.917114 122.047707,534.031860 122.614388,533.230103 
	C126.875183,527.201721 125.868225,520.281372 125.972939,513.563416 
	C126.003090,511.629181 126.342072,509.935242 127.868034,508.632782 
	C127.994225,508.525055 128.139694,508.414948 128.212524,508.273224 
	C134.202835,496.618774 143.872345,487.609924 150.951752,476.353699 
	C150.313080,474.443054 151.318817,473.437134 152.281311,472.568665 
	C161.997025,463.801941 168.320190,452.427704 175.821091,441.963898 
	C177.347244,439.834808 178.944702,438.102264 182.126617,438.207855 
	C182.690231,438.420929 182.897781,438.546478 183.305450,438.973328 
	C183.340729,443.562714 180.335037,446.470276 178.061432,449.558533 
	C174.036301,455.025970 170.511017,460.807892 166.649796,466.370056 
	C164.104767,470.036255 161.651291,473.748718 158.530350,477.033661 
	C151.518265,484.414154 146.105591,493.072845 141.971436,502.230377 
	C139.772461,507.101288 137.589325,512.503235 137.708405,518.235107 
	C137.779663,521.664734 135.330734,524.388916 135.164886,528.080078 
	C135.328384,536.083374 138.936462,543.341614 137.767685,551.137268 
	C137.667313,551.806702 137.992645,552.432373 138.360138,553.006287 
	C143.691620,561.332886 145.762314,570.567749 145.961395,580.323120 
	C145.968185,580.656433 145.902145,581.038696 146.048218,581.311646 
	C149.428848,587.627686 150.649765,594.806519 154.137451,601.105225 
	C160.942825,613.395691 172.509521,619.040222 184.994476,623.138123 
	C191.191971,625.172180 195.554123,629.321960 199.927109,633.613098 
	C201.711029,635.363586 202.898895,637.706360 201.245422,640.703125 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M201.252289,641.000488 
	C201.310684,637.089722 199.062119,634.714600 196.445068,632.131836 
	C190.664154,626.426697 182.999847,624.940430 176.061508,621.694519 
	C167.793945,617.826904 160.046768,613.077637 154.445786,604.792358 
	C149.682999,597.747070 149.450195,589.110535 144.290863,582.452393 
	C142.586655,580.253113 145.486237,577.155762 144.300247,574.242554 
	C141.424591,567.179016 142.256409,558.727356 135.805573,552.932068 
	C133.935059,551.251648 136.698822,548.857239 136.512421,546.411682 
	C136.061981,540.501892 132.922272,535.009644 133.978958,528.485718 
	C137.264603,526.096252 138.755310,528.228821 139.253143,530.854553 
	C141.170853,540.969116 144.714493,550.658813 146.552444,560.848999 
	C147.518967,566.207764 149.254013,572.002014 151.920532,577.137146 
	C152.852966,578.932739 153.699142,580.748413 153.735336,582.787598 
	C153.856049,589.586304 157.786942,594.979919 160.499390,600.774231 
	C162.050598,604.087952 164.773071,606.707520 168.772385,608.176453 
	C172.047394,612.104248 175.218704,615.222168 180.568329,615.152588 
	C182.856628,615.122803 184.857422,616.791870 186.676071,618.310608 
	C190.891449,621.830811 196.540207,623.652710 200.213013,627.387146 
	C205.706894,632.973328 214.207153,632.424438 219.324997,638.412842 
	C221.254318,640.670288 225.235901,639.531433 228.198044,641.094055 
	C232.373627,643.296936 235.873871,646.265320 239.373230,649.204163 
	C245.452209,654.309448 252.701431,657.109192 259.631744,660.609802 
	C267.731201,664.701172 275.633728,669.136597 283.355835,673.970825 
	C288.760590,677.354309 295.067688,679.394165 300.954651,682.058411 
	C306.878143,684.739197 311.857483,688.912659 317.784332,691.476074 
	C319.296906,692.130249 320.652832,693.122803 322.305389,693.521118 
	C331.343811,695.699646 337.777008,702.080444 345.085480,707.746826 
	C345.741089,708.313049 346.072968,708.679382 346.592041,709.372437 
	C348.232544,713.442505 351.550659,715.801819 353.921326,719.348877 
	C355.318115,724.200806 355.646301,728.793457 355.323425,733.417419 
	C355.161560,735.735413 354.832306,738.142395 351.368103,738.228455 
	C351.856720,727.837402 347.634735,719.330750 342.053802,711.344543 
	C340.215271,708.713562 337.296509,707.079102 334.458618,705.845825 
	C324.353424,701.454163 314.493896,696.406006 304.980652,691.047791 
	C295.730103,685.837524 285.214020,683.608704 276.505066,677.658203 
	C269.426575,672.821716 261.182098,670.117126 254.349335,664.927124 
	C253.184296,664.042175 251.958389,663.923035 250.581116,664.262451 
	C249.989502,664.408142 248.795288,664.358887 248.792679,664.325928 
	C248.382523,659.115784 242.520004,660.504944 240.144409,657.132874 
	C237.977737,654.057373 233.289566,653.625244 229.483566,652.388184 
	C226.311829,651.357117 224.370209,647.898193 221.339966,645.999084 
	C215.590225,642.395691 209.307724,641.580811 202.424271,641.014893 
	C201.990753,641.020752 201.498489,641.005859 201.252289,641.000488 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M183.268143,438.713226 
	C179.885132,439.207489 178.332214,440.842529 176.716003,443.612152 
	C173.253067,449.546387 169.021210,455.056183 164.846909,460.544067 
	C160.785522,465.883575 157.055893,471.576050 151.156006,475.703735 
	C149.574081,476.302185 148.151810,476.604340 146.091125,476.855896 
	C144.968216,476.443268 144.766434,475.871490 144.209381,475.204651 
	C144.458908,470.535675 150.214279,471.005890 151.658859,467.070862 
	C154.619858,465.699402 156.323166,463.648987 157.202911,460.852661 
	C157.878036,458.706787 159.247421,457.151245 161.722778,456.215332 
	C163.392563,455.862762 163.985092,455.035583 163.991394,453.348083 
	C165.218460,452.132507 164.764435,450.533020 165.772217,449.236908 
	C170.907028,443.872864 175.803360,438.622162 178.797043,432.182831 
	C179.992249,429.612000 182.683273,430.603607 183.816086,428.309601 
	C184.977936,427.203339 185.990219,427.147980 187.287888,428.182068 
	C188.493820,432.728699 184.071350,434.821198 183.268143,438.713226 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M141.320557,481.855591 
	C141.002396,480.286865 141.001190,478.571991 141.000000,476.428558 
	C141.750000,476.000000 142.500000,476.000000 143.625000,476.000000 
	C144.595886,476.018707 145.130295,476.202545 145.793945,476.794983 
	C145.999390,476.998779 146.000000,477.000000 146.002136,477.001831 
	C146.347137,480.241150 143.504639,480.522766 141.320557,481.855591 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M84.917969,512.874268 
	C81.001381,508.679352 79.326622,512.671143 78.021431,514.800598 
	C76.210617,517.755066 79.880089,517.424011 81.137444,518.474670 
	C82.160202,519.329468 84.533890,519.204773 83.551079,521.358093 
	C82.590630,523.462463 80.684738,523.323608 78.910881,522.228943 
	C77.227669,521.190247 75.669792,519.940552 73.953819,518.964783 
	C71.761024,517.717896 71.867561,515.722595 72.100639,513.740723 
	C72.338608,511.717407 73.612701,510.303619 75.636314,510.144531 
	C77.880394,509.968170 79.681450,509.678162 80.985298,507.402039 
	C82.200966,505.279816 83.975594,505.671021 85.252380,507.676727 
	C86.035278,508.906525 86.187393,510.480591 87.587418,511.393005 
	C88.697922,512.116760 89.161194,513.284851 87.889664,514.243652 
	C86.596603,515.218689 85.792297,514.184143 84.917969,512.874268 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M101.843552,498.040283 
	C103.550087,500.647003 102.213226,501.890106 100.088913,501.811249 
	C97.516327,501.715759 94.980324,500.409882 92.520462,502.391296 
	C91.969002,502.835510 91.220222,502.171387 91.165108,501.436737 
	C91.031921,499.661224 90.420326,497.732300 91.957008,496.267548 
	C92.945862,495.324951 94.348579,494.592621 95.564247,495.508362 
	C97.379333,496.875671 99.649200,496.806671 101.843552,498.040283 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M99.640808,506.952026 
	C99.859352,508.386993 101.484596,509.742279 100.086174,511.147766 
	C99.404274,511.833069 98.619507,511.729889 97.833374,511.359009 
	C96.212135,510.594025 94.926918,509.192657 95.305138,507.543671 
	C95.931587,504.812500 97.885483,506.862457 99.640808,506.952026 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M91.167694,531.497437 
	C91.264320,533.203125 90.215233,532.979248 89.303429,532.723755 
	C89.033043,532.648010 88.659637,531.839966 88.772537,531.682800 
	C89.323013,530.916199 90.154236,531.060974 91.167694,531.497437 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M90.739136,523.467285 
	C90.482521,522.460815 90.983513,522.147156 91.638962,522.274170 
	C91.943962,522.333252 92.429153,522.904663 92.372154,523.124329 
	C92.186882,523.838135 91.559586,523.857056 90.739136,523.467285 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M76.227493,507.100098 
	C76.681343,506.802063 77.295219,506.582733 77.399483,506.728302 
	C77.823975,507.321075 77.541885,507.907257 76.910980,508.126465 
	C76.339760,508.324951 76.179924,507.833130 76.227493,507.100098 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M99.999992,507.000000 
	C100.289772,506.518463 100.579559,506.036926 100.869347,505.555389 
	C101.063904,505.721161 101.258453,505.886902 101.453003,506.052673 
	C101.146973,506.340393 100.840958,506.628143 100.267776,506.957947 
	C100.000610,507.000000 100.000000,507.000000 99.999992,507.000000 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M330.047852,745.651978 
	C328.731964,750.260742 322.993500,750.660095 321.978180,755.546143 
	C321.434814,756.566956 320.748444,756.796204 319.600861,756.791626 
	C317.278320,755.463928 315.234955,755.378662 312.741547,756.358887 
	C299.237030,756.102173 286.525787,753.654785 273.853180,750.145020 
	C264.492188,747.552490 256.337128,742.945068 248.564682,737.666565 
	C243.235413,734.047241 240.406235,727.814941 237.130692,722.329529 
	C233.462265,716.186218 231.493958,709.257751 229.408051,702.440857 
	C227.631104,696.633545 226.590881,695.757874 220.497086,696.597717 
	C216.720535,697.118225 213.041290,698.154480 209.368835,699.291809 
	C198.919998,702.527771 187.921722,703.507019 177.472244,706.800720 
	C176.473587,707.115479 175.504669,707.017395 174.279114,706.273621 
	C174.123749,703.866150 173.139557,702.161682 172.011749,700.469727 
	C170.570663,698.307800 171.102585,696.448669 173.781708,697.296326 
	C177.775696,698.559937 181.261642,696.308777 184.822220,696.843994 
	C185.708069,695.644775 185.070602,694.916199 184.433884,694.506348 
	C179.639847,691.420349 180.600220,686.774170 181.008301,682.264099 
	C181.124069,680.984741 180.822205,679.303223 182.782852,679.323730 
	C184.373535,679.340332 185.688980,680.154053 185.913773,681.949036 
	C186.154984,683.875061 185.777069,685.989746 188.130646,687.623230 
	C189.053711,685.365356 190.024734,682.718567 193.107101,685.340210 
	C194.166550,686.241272 195.540176,685.509338 196.302887,684.757874 
	C199.220306,681.883423 203.970093,682.486084 206.630142,679.064819 
	C207.607635,677.807556 208.686035,678.433777 208.997894,680.009949 
	C209.126663,680.660645 209.307220,681.301575 209.476334,681.943726 
	C210.730270,686.705017 213.780685,688.903687 218.604019,687.801147 
	C224.238266,686.513123 229.468719,687.584961 235.037811,688.561096 
	C242.169724,689.811096 247.286713,694.146301 251.857605,698.489075 
	C255.409882,701.863953 259.687256,701.528687 263.284729,703.679565 
	C265.086731,704.756958 267.747131,704.528137 269.507690,706.332458 
	C269.894653,706.729065 271.027863,707.041016 271.312225,706.798828 
	C276.616730,702.280396 280.277344,708.209290 284.737091,708.886902 
	C289.517944,709.613220 293.756073,713.268494 299.016479,711.523438 
	C300.840820,710.918274 302.284515,712.035828 303.647614,712.875305 
	C309.743988,716.629883 315.898590,720.316528 321.749207,724.430054 
	C323.996674,726.010254 326.628510,726.355530 328.852966,727.871704 
	C333.950867,731.346313 333.463074,739.864746 330.047852,745.651978 
z"/>
<path fill="#ABCED8" opacity="1.000000" stroke="none" 
	d="
M318.724304,755.719849 
	C319.762878,755.955627 320.478302,755.961487 321.547241,755.927612 
	C322.319000,755.951050 322.737274,756.014404 323.531860,756.310608 
	C323.719940,756.594604 323.531647,756.645752 323.103149,756.864014 
	C321.633636,758.857300 319.790405,759.676331 317.723297,759.564209 
	C294.414612,758.299622 271.216003,756.594666 250.806183,743.300110 
	C246.532379,740.516235 243.147583,736.971191 240.064423,732.518921 
	C238.662308,730.960999 238.184921,729.233215 236.722290,727.755554 
	C235.971817,726.716064 235.634491,725.853210 235.104614,724.707703 
	C234.474045,722.867126 233.580948,721.585876 232.297821,720.126099 
	C231.997192,719.608582 231.893677,719.389221 231.692596,718.815613 
	C230.316757,713.129211 227.528473,708.304565 226.444717,702.893005 
	C225.780396,699.575806 223.271698,699.547424 220.593033,700.070068 
	C214.236557,701.310242 207.952148,702.861389 201.677780,704.451111 
	C190.614304,707.254211 179.190903,708.558838 168.024399,712.592773 
	C167.398788,712.776672 167.138519,712.819031 166.481384,712.835327 
	C165.834274,712.720215 165.584000,712.631226 165.039001,712.274292 
	C164.469864,711.236877 164.499786,710.444702 164.807175,709.332275 
	C165.046280,709.014954 165.519592,709.019043 165.756165,709.012451 
	C166.705765,707.512512 168.041626,706.864075 169.779083,706.213989 
	C171.093079,706.016663 172.146500,706.012390 173.594467,706.000244 
	C179.849289,705.648743 184.972336,702.229614 190.703873,701.712036 
	C199.540604,700.913940 207.798386,697.942505 216.248566,695.695251 
	C218.898315,694.990601 221.516907,695.207703 224.148407,694.974487 
	C227.409195,694.685486 229.029907,696.296448 229.928802,699.211914 
	C231.093857,702.990601 232.668320,706.657410 233.626724,710.482544 
	C235.976562,719.861267 242.453537,726.907837 247.118912,734.972595 
	C248.384293,737.160095 251.223083,736.920715 253.063980,738.402832 
	C263.552917,746.847595 276.389709,749.740906 289.047394,752.250000 
	C296.536346,753.734497 304.173431,755.968872 312.512878,755.021484 
	C314.756104,755.472107 316.663940,754.495850 318.724304,755.719849 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M169.650421,706.015869 
	C169.357071,707.523987 167.887238,708.030151 166.288986,708.877380 
	C165.835190,706.646729 167.088043,705.826599 169.650421,706.015869 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M213.658844,683.586975 
	C214.373184,683.388184 214.633148,683.637451 214.521576,684.192749 
	C214.498428,684.307983 214.334717,684.462708 214.218124,684.479858 
	C213.657623,684.562683 213.422943,684.289856 213.658844,683.586975 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M164.807953,709.142944 
	C165.040222,709.759644 165.034180,710.504333 165.029968,711.621582 
	C164.893097,712.124268 164.754395,712.254333 164.337799,712.320068 
	C164.019699,711.778503 163.979477,711.301208 163.914856,710.467285 
	C164.116852,709.830688 164.343231,709.550781 164.807953,709.142944 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M90.810280,616.682495 
	C89.905418,620.686768 86.693825,622.392212 83.581459,622.608765 
	C80.679268,622.810608 79.710266,619.460510 79.032860,616.902710 
	C78.657791,615.486389 77.944839,614.588257 76.754860,613.833496 
	C75.672188,613.146790 74.044022,612.368774 74.625175,610.873047 
	C75.299660,609.137146 77.142097,608.902710 78.876762,609.176514 
	C79.687355,609.304504 80.463631,609.650024 81.627426,609.948486 
	C81.499229,612.913757 83.125381,614.134583 85.664368,614.750671 
	C87.350670,615.159790 89.381950,614.571289 90.810280,616.682495 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M97.767685,616.166138 
	C103.047966,617.271912 105.042801,622.162537 108.616272,625.181274 
	C109.868347,626.239014 108.483932,628.324768 106.982323,629.285828 
	C105.157043,630.454163 104.293121,629.502441 103.905823,627.673706 
	C103.486588,625.694092 101.524467,626.271057 100.321007,625.583313 
	C97.663216,624.064270 94.230087,622.997803 95.958351,618.344238 
	C96.511490,617.444092 97.023277,616.888245 97.767685,616.166138 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M86.374634,606.570923 
	C86.102486,604.373901 87.051720,603.672424 88.604553,604.522217 
	C89.786041,605.168762 91.643677,605.642151 91.415283,607.462402 
	C91.320686,608.216309 90.287521,609.222168 89.493301,609.445557 
	C87.511658,610.003052 87.214592,608.108948 86.374634,606.570923 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M73.302917,596.120361 
	C72.842873,595.528259 72.798424,594.968262 72.507790,594.605835 
	C71.440559,593.274963 70.035149,591.744019 72.058044,590.454163 
	C73.701759,589.406067 75.244141,590.318604 75.943222,592.640198 
	C75.986458,593.651794 75.951813,594.300476 75.533646,595.193359 
	C74.865242,595.939453 74.294525,596.224670 73.302917,596.120361 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M86.077606,595.395081 
	C87.640480,596.172607 88.464066,597.130615 87.256126,598.459900 
	C86.656654,599.119568 85.722977,598.895569 85.110382,598.304749 
	C83.809143,597.049866 84.268250,596.081665 86.077606,595.395081 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M73.168076,596.295898 
	C73.496010,595.740540 73.991714,595.483521 74.742950,595.113281 
	C75.384216,594.619629 75.844444,594.472961 76.689728,594.780029 
	C77.000107,595.750000 76.999908,596.500000 76.999550,597.625000 
	C75.588562,598.025635 74.223671,597.931519 73.168076,596.295898 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M46.353958,557.517151 
	C47.847416,557.514954 47.766380,558.224121 47.233978,558.984558 
	C46.845860,559.538818 46.177208,559.872314 45.654572,559.240173 
	C45.169052,558.653015 45.616493,558.083862 46.353958,557.517151 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M60.955956,571.533997 
	C60.305973,571.175964 60.296749,570.816528 60.763149,570.499512 
	C60.860691,570.433228 61.086365,570.436096 61.181190,570.505371 
	C61.634697,570.836792 61.617908,571.195984 60.955956,571.533997 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M53.955841,565.533936 
	C53.305969,565.175964 53.296646,564.816467 53.763111,564.499451 
	C53.860683,564.433167 54.086380,564.436035 54.181149,564.505371 
	C54.634449,564.836792 54.618023,565.195923 53.955841,565.533936 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M76.969971,594.707397 
	C76.555229,595.000000 76.110466,595.000000 75.332085,595.000000 
	C75.179199,594.454529 75.359924,593.908997 75.770317,593.181763 
	C76.313309,593.471619 76.626625,593.943176 76.969971,594.707397 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M91.044159,598.465942 
	C91.694046,598.823975 91.703430,599.183350 91.236870,599.500366 
	C91.139259,599.566711 90.913559,599.563904 90.818817,599.494568 
	C90.365540,599.163025 90.382179,598.803894 91.044159,598.465942 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M94.341293,611.413086 
	C93.626701,611.611816 93.366783,611.362366 93.478371,610.807068 
	C93.501526,610.691833 93.665337,610.537109 93.781944,610.519897 
	C94.342606,610.437195 94.577248,610.710144 94.341293,611.413086 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M115.690216,621.779236 
	C116.313133,622.471558 116.626549,622.943176 116.970139,623.707397 
	C117.523697,627.095764 118.042686,630.192261 118.572227,633.286987 
	C118.863937,634.991821 117.690575,635.625305 116.459663,636.237915 
	C115.682556,636.624634 114.865150,636.887512 114.116570,636.318604 
	C112.915619,635.406006 113.335464,634.229004 113.837349,633.160583 
	C115.444252,629.739746 113.421295,626.281433 113.999756,622.428955 
	C113.999695,622.000000 114.000000,622.000000 114.000153,622.000000 
	C114.386505,621.621643 114.846642,621.474487 115.690216,621.779236 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M89.416115,632.999756 
	C89.000732,631.528442 88.976097,630.056274 89.008705,628.585388 
	C89.048264,626.801086 88.795982,624.695312 91.200066,624.286987 
	C93.121742,623.960571 94.352753,625.397339 94.724892,627.027222 
	C95.184196,629.039001 95.695564,631.426697 93.199867,632.620972 
	C92.237320,633.081482 90.963974,632.892578 89.416115,632.999756 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M74.681076,624.857544 
	C71.245010,624.782471 69.513046,621.632568 66.385017,621.432312 
	C65.459206,621.372925 64.863686,620.044189 65.366783,619.036194 
	C65.845894,618.076355 66.761787,618.438599 67.440598,618.977844 
	C69.081383,620.281372 70.758492,619.955078 72.559784,619.336304 
	C74.283401,618.744202 75.316307,619.760498 76.082558,621.210938 
	C76.908951,622.775391 76.894356,624.034485 74.681076,624.857544 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M88.567886,633.000000 
	C88.428558,636.654236 85.823845,637.154968 82.398300,637.000000 
	C81.295280,636.794312 80.896217,636.309021 80.865189,635.305176 
	C82.545700,631.362244 85.755936,633.662598 88.567886,633.000000 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M89.421944,643.515625 
	C92.824760,643.409546 92.055099,645.536377 91.642273,647.195129 
	C91.337875,648.418213 90.043541,649.372253 88.979073,648.493958 
	C87.274750,647.087585 87.812675,645.277893 89.421944,643.515625 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M80.743988,634.886475 
	C81.257500,635.496155 81.515320,635.992310 81.886719,636.744263 
	C81.711441,638.608826 84.290161,640.416809 81.331665,641.931641 
	C80.508385,639.549927 78.300003,637.576416 79.000076,634.397583 
	C79.496094,634.257690 79.992195,634.515320 80.743988,634.886475 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M66.638847,609.624023 
	C68.698975,608.973755 69.649315,609.781372 69.746399,611.550659 
	C69.784378,612.242981 69.213211,612.796753 68.528313,612.748962 
	C66.771591,612.626343 65.950912,611.683289 66.638847,609.624023 
z"/>
<path fill="#162034" opacity="1.000000" stroke="none" 
	d="
M337.256836,765.255127 
	C336.997589,762.734314 335.012360,762.221130 333.693390,761.106628 
	C332.189301,759.835571 330.711578,758.513794 330.994934,755.892700 
	C332.101257,755.257690 333.200897,754.967407 334.567047,754.572632 
	C337.163696,753.952820 339.403503,753.030884 342.161713,752.824707 
	C342.931427,752.570068 343.345520,752.371765 344.037018,751.974854 
	C344.952667,751.599365 345.610565,751.509399 346.510193,751.130615 
	C347.586945,750.758789 348.411652,750.644287 349.446960,750.189270 
	C350.653870,749.716248 351.635468,749.462158 352.598755,748.509949 
	C353.361450,747.936890 353.915863,747.616760 354.772461,747.154297 
	C356.109375,746.561035 357.190582,746.230591 358.584412,745.738037 
	C360.770538,745.192627 362.406921,744.027222 364.748627,744.001099 
	C368.633209,744.462219 370.437042,741.798706 372.533966,739.806580 
	C377.624329,734.970459 378.961182,734.657471 385.330658,737.344238 
	C386.098877,737.668274 386.872040,738.227661 387.676514,738.123352 
	C397.153687,736.894165 402.366119,743.971619 408.576935,748.948181 
	C411.829285,751.554260 414.697357,754.416870 419.128265,755.171387 
	C422.370514,755.723572 424.306396,758.580872 424.921478,761.492798 
	C425.899445,766.123108 428.450714,767.337585 432.970306,766.693359 
	C434.990417,773.994019 431.099457,779.609863 428.101349,785.321777 
	C426.285431,788.781494 423.342255,791.564087 419.771820,794.091431 
	C416.749207,795.475037 413.751801,796.123779 411.382385,798.739380 
	C406.690033,800.037415 402.883972,802.988708 397.783020,802.744263 
	C395.085205,801.327942 394.525513,797.873047 391.003601,797.495117 
	C389.750122,797.378052 388.920959,797.046875 387.876953,796.380493 
	C386.965240,795.651306 386.597900,794.843384 385.602966,794.236328 
	C385.174194,793.966064 385.007324,793.851562 384.599854,793.554321 
	C383.889038,793.010986 383.504425,792.535278 382.662476,792.173706 
	C382.216919,791.931519 382.041260,791.827515 381.610657,791.550537 
	C380.859222,791.012817 380.440430,790.539001 379.568146,790.151123 
	C379.106018,789.875488 378.926575,789.753540 378.497559,789.419189 
	C377.077850,787.448486 375.354126,786.618286 373.012695,786.174988 
	C372.480469,785.973877 372.273743,785.875610 371.782166,785.583740 
	C367.904846,780.628723 362.794464,778.443481 356.899414,776.976074 
	C354.767792,776.723572 353.558411,775.586487 352.189575,774.072388 
	C349.352722,769.257019 345.073151,767.378784 339.846863,766.412292 
	C338.745758,766.283875 338.075500,766.007385 337.256836,765.255127 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M345.226654,752.185059 
	C344.498077,752.431641 344.079803,752.584839 343.325287,752.753296 
	C342.824554,752.614197 342.660065,752.459900 342.737427,752.068970 
	C343.378448,749.207397 345.754028,747.831665 346.945740,745.338135 
	C348.566956,743.893799 348.296295,741.765686 349.753510,740.228760 
	C350.243927,739.496765 350.509949,739.006348 350.894592,738.263306 
	C356.689331,735.393616 352.605255,730.420959 353.490723,726.654236 
	C353.929443,724.788147 353.040222,722.179016 352.991516,719.443604 
	C351.987701,716.201050 348.163544,714.764465 349.277832,710.652710 
	C349.640594,710.109985 349.834808,709.926941 350.428925,709.693604 
	C356.750092,712.247437 358.103058,717.766541 359.370270,723.259155 
	C359.736328,724.845825 359.447296,726.566589 359.487549,728.621948 
	C359.708527,730.585083 359.659180,732.138550 359.140686,734.030273 
	C358.561890,736.011597 356.388184,736.559753 356.381897,738.852417 
	C356.180176,739.891846 355.895233,740.529175 355.368958,741.435303 
	C352.999451,744.033630 351.684723,746.904053 349.885559,749.859741 
	C349.189514,750.607605 348.603607,750.910339 347.639099,751.201782 
	C346.730133,751.567871 346.158630,751.888428 345.226654,752.185059 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M342.589844,751.890259 
	C342.979248,751.832336 343.007782,752.300354 342.998413,752.534424 
	C340.760925,754.305420 338.107452,754.177307 335.221008,754.561157 
	C336.840790,752.317383 339.667084,752.560974 342.589844,751.890259 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M349.618347,740.046326 
	C349.956085,741.954956 350.347931,744.111877 347.374237,744.953491 
	C347.056580,743.053528 346.646484,740.888062 349.618347,740.046326 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M118.761444,233.407700 
	C120.304169,235.507782 118.448860,235.865524 117.253807,236.512772 
	C117.109421,236.590988 116.341507,236.023285 116.298553,235.697464 
	C116.093658,234.143219 117.424911,233.887939 118.761444,233.407700 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M126.755432,237.221939 
	C127.806541,237.184662 128.705292,237.339127 128.730667,238.396332 
	C128.763199,239.752090 127.707642,239.866394 126.352173,239.988312 
	C126.170395,239.147552 126.340782,238.295700 126.755432,237.221939 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M112.658524,230.586731 
	C113.373375,230.387894 113.633522,230.637390 113.521873,231.192978 
	C113.498711,231.308273 113.334824,231.463028 113.218163,231.480255 
	C112.657234,231.563171 112.422485,231.290070 112.658524,230.586731 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M324.192688,756.712036 
	C323.908203,756.543457 323.863434,756.318970 323.825928,756.210815 
	C324.299408,755.520569 324.833527,755.020935 325.834412,755.792358 
	C325.772888,756.785095 325.169189,756.910278 324.192688,756.712036 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M110.356201,618.477844 
	C108.781105,617.218323 107.179337,616.025696 108.894463,614.284180 
	C109.868622,613.295044 111.006157,611.974731 112.703644,612.337769 
	C113.175537,612.438721 113.858650,613.256714 113.829559,613.705933 
	C113.689461,615.869751 113.420776,618.073547 110.356201,618.477844 
z"/>
<path fill="#BCBAC0" opacity="1.000000" stroke="none" 
	d="
M115.990959,621.666748 
	C115.555382,622.000000 115.111076,622.000000 114.333534,622.000000 
	C114.129425,621.136169 113.722191,619.877808 114.912308,619.662292 
	C116.002274,619.464905 115.829430,620.626587 115.990959,621.666748 
z"/>
<path fill="#044E62" opacity="1.000000" stroke="none" 
	d="
M371.735474,784.765198 
	C371.987000,785.011475 372.467651,785.045715 372.708313,785.056274 
	C374.734009,786.342407 376.817627,787.138184 378.758392,788.771179 
	C378.991089,789.004883 379.467255,789.042786 379.705627,789.057373 
	C380.532043,789.539856 381.060211,790.073059 381.796875,790.794312 
	C381.992798,790.996094 382.475586,791.033569 382.717377,791.046204 
	C383.533020,791.542419 384.062653,792.073120 384.799591,792.793579 
	C384.999908,792.990784 385.000000,793.000000 385.004425,793.000000 
	C385.644348,793.874634 386.771088,793.950256 387.781067,794.781616 
	C388.559265,795.788635 389.394409,795.975281 390.619934,796.045410 
	C394.416321,798.623535 394.571838,801.242188 390.799927,804.155518 
	C390.083130,804.613281 389.636017,804.788940 388.820618,804.952026 
	C386.682373,804.925659 384.984314,805.183105 383.278015,806.581177 
	C382.163727,807.104004 381.288910,807.194580 380.083923,807.234985 
	C378.936554,807.230408 378.123657,807.300842 377.084839,807.819092 
	C375.428162,808.567078 374.003235,808.985901 372.186768,809.114868 
	C371.076385,809.160522 370.273010,809.167847 369.226990,809.593628 
	C368.529602,809.815186 368.114380,809.895935 367.365906,809.986328 
	C366.105225,810.015320 365.231476,810.264343 364.026093,810.783691 
	C343.885071,815.008911 324.388550,820.361572 304.555756,824.198425 
	C289.176117,827.173767 273.811554,825.839417 258.474121,823.664001 
	C257.149719,823.476074 255.879745,823.096802 254.385712,822.153015 
	C251.082001,818.241272 251.775513,813.367676 249.708954,809.011841 
	C249.310059,808.228516 249.154770,807.756592 249.006378,806.910889 
	C249.200470,803.734558 248.081665,801.220093 247.066330,798.335449 
	C246.756927,797.361694 246.640808,796.693237 246.531509,795.686707 
	C245.909866,792.794556 244.934341,790.371338 243.661438,787.709167 
	C243.329132,787.015198 243.207169,786.595398 243.105743,785.840576 
	C243.054581,784.896301 243.095337,784.270935 242.980591,783.350159 
	C242.848114,782.659607 242.765518,782.267273 242.611862,781.584290 
	C242.402008,780.695068 242.062759,780.197632 241.582367,779.423645 
	C241.280853,778.762939 241.163422,778.364258 241.020508,777.649780 
	C240.845505,776.735718 240.729507,776.127625 240.280106,775.289062 
	C240.028732,774.808838 239.933594,774.611511 239.730682,774.100586 
	C239.138229,772.285034 238.632980,770.787842 238.544312,768.853516 
	C238.505112,767.836975 238.358566,767.183960 238.006073,766.227966 
	C237.790543,765.460938 237.703415,765.014160 237.610260,764.222168 
	C237.318542,761.135193 236.105270,758.664490 235.189209,755.754395 
	C234.924316,755.003906 234.810181,754.564026 234.662964,753.783569 
	C234.527267,752.051636 233.819534,750.870972 233.148773,749.298950 
	C232.933273,748.736633 232.858109,748.504578 232.710968,747.906006 
	C232.618088,747.286316 232.597168,747.033264 232.559723,746.394287 
	C232.517639,745.349304 232.555191,744.683350 232.441132,744.032715 
	C230.945114,735.496704 231.310776,734.781555 239.746613,730.572021 
	C244.543579,733.560242 246.786423,738.714661 251.453796,741.580383 
	C258.170013,745.703979 265.123108,749.280396 272.723206,751.288513 
	C288.881622,755.558044 305.284637,758.027283 322.469482,756.958252 
	C326.530243,761.056030 332.255920,761.560669 336.679565,764.928345 
	C337.764038,764.984009 338.491974,765.013123 339.584290,765.060425 
	C344.108978,767.577698 348.915131,769.005859 352.677643,772.831055 
	C354.083740,774.005432 355.228851,774.946106 356.689392,775.945923 
	C362.168091,778.270142 367.139282,780.861755 371.735474,784.765198 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M39.903511,278.853394 
	C39.833290,278.998932 39.666611,278.999054 39.249920,278.999207 
	C39.268955,278.902161 39.538006,278.805084 39.903511,278.853394 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M68.044601,1073.234863 
	C67.070473,1070.127441 69.245148,1071.190430 70.413246,1071.183716 
	C71.269043,1071.178833 72.001358,1071.915894 71.593460,1072.838013 
	C70.869560,1074.474243 69.542023,1073.952271 68.044601,1073.234863 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M112.983765,1219.315186 
	C113.703323,1219.411621 113.968224,1219.969971 113.291656,1220.895386 
	C113.089188,1221.066406 112.997879,1220.993408 112.952560,1220.953857 
	C112.918419,1220.498779 112.929596,1220.083252 112.983765,1219.315186 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M112.975815,1219.023438 
	C112.567513,1218.911499 112.177322,1218.780029 111.787140,1218.648560 
	C112.084129,1218.020142 112.431931,1218.352661 112.900894,1218.779297 
	C113.026756,1218.962402 112.993927,1219.004150 112.975815,1219.023438 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M64.000107,1175.630859 
	C62.605877,1175.862915 62.015057,1175.034668 62.008446,1173.347900 
	C62.443058,1173.002441 62.886635,1173.004883 63.662155,1173.029297 
	C63.996136,1173.787964 63.998173,1174.524780 64.000107,1175.630859 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M131.013016,1132.297119 
	C131.054749,1132.937622 130.958679,1132.901611 131.013016,1132.297119 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1056.712158,249.710129 
	C1056.669067,251.619537 1056.176025,253.225220 1056.036133,254.861084 
	C1055.527344,260.816864 1053.447021,262.903961 1047.287231,262.492706 
	C1045.187500,262.352539 1044.225464,263.287262 1043.109253,264.468628 
	C1041.734741,265.923340 1042.882812,266.756958 1043.926025,267.683197 
	C1045.052856,268.683777 1045.205322,270.030945 1044.610474,271.339081 
	C1043.860962,272.987732 1042.274414,273.005035 1040.751953,272.988373 
	C1036.446045,272.941284 1032.633423,270.304596 1028.106689,270.884888 
	C1025.410522,271.230530 1023.094177,271.524048 1020.829346,273.349640 
	C1018.063660,275.578979 1015.401001,278.364227 1011.287415,278.192261 
	C1010.242188,278.148590 1009.465332,279.018311 1009.180542,280.048767 
	C1008.586182,282.199951 1007.516724,283.010681 1005.158630,282.281036 
	C1002.872253,281.573578 1001.196289,282.507690 1001.070679,285.155823 
	C1000.923035,288.266876 1003.041870,288.309875 1005.109863,287.747681 
	C1009.303711,286.607666 1010.743225,291.311676 1014.351318,292.094543 
	C1015.932861,292.437653 1020.769226,292.638672 1020.790955,296.082855 
	C1020.835205,303.071198 1024.751465,309.824066 1022.635132,316.721283 
	C1021.336426,320.953644 1016.847229,318.927185 1013.846191,318.787659 
	C1010.344360,318.624817 1009.646667,314.788605 1010.323242,312.733521 
	C1011.749329,308.401672 1009.442627,304.177887 1010.882629,299.982758 
	C1011.459351,298.302704 1010.595825,296.593567 1008.984985,295.739838 
	C1006.874939,294.621582 1006.127747,296.566193 1005.105896,297.794067 
	C1004.370117,298.678101 1003.651123,300.226929 1002.836853,300.286865 
	C998.307922,300.620270 996.598267,302.997925 996.551025,307.333221 
	C996.539490,308.399200 995.197327,309.373596 994.067139,307.320374 
	C993.768005,306.335052 993.186768,305.951935 992.596191,305.149414 
	C992.361450,304.390350 992.402283,303.946442 992.776184,303.255615 
	C994.266174,301.766937 994.890198,300.022888 994.815613,298.503510 
	C994.407288,290.180267 996.783386,282.130829 997.000000,273.441650 
	C996.946411,272.843353 996.853394,272.690125 996.845215,272.532532 
	C996.758484,270.868835 999.609558,269.720459 997.695984,267.747406 
	C997.335510,267.375763 996.774902,267.280090 996.317688,267.514343 
	C994.161560,268.619110 994.987793,270.592041 994.984741,272.658234 
	C990.353333,274.646667 985.715637,276.312897 981.052307,277.904114 
	C980.685913,278.029175 979.870422,277.764648 979.718872,277.456940 
	C976.093567,270.097168 969.336060,273.943512 963.466553,272.999084 
	C964.163879,266.545410 965.593872,265.406036 971.896118,266.442169 
	C974.446167,266.861450 974.556030,264.843384 975.168823,263.533112 
	C977.256287,259.069458 980.246765,257.569916 984.730652,260.174164 
	C986.054932,260.943268 987.134216,261.179382 987.978943,259.493286 
	C989.692993,256.071869 991.746704,256.200470 994.009216,259.016327 
	C996.394592,261.985291 997.549133,260.649506 997.945251,257.745850 
	C998.188416,255.962982 998.503540,254.316605 1000.576355,254.111694 
	C1002.721008,253.899734 1003.682800,252.562012 1004.609619,250.883636 
	C1006.420532,247.604279 1010.025635,247.324402 1013.057617,246.154114 
	C1014.553894,245.576584 1015.853149,245.005005 1015.869873,243.163544 
	C1015.889648,240.991928 1014.016724,241.303406 1012.733337,240.715271 
	C1010.268860,239.585953 1006.476746,240.933609 1005.657776,236.594971 
	C1005.180603,234.066833 1005.939636,233.036377 1008.276428,233.133209 
	C1010.331848,233.218353 1012.835266,235.143021 1014.299744,232.924957 
	C1016.337585,229.838776 1019.074768,226.910629 1018.998718,222.392044 
	C1017.871948,220.702362 1017.596680,219.293060 1018.720947,217.368530 
	C1020.742371,216.667404 1022.484680,216.334839 1024.613525,216.001129 
	C1025.276611,216.187271 1025.668701,216.400894 1025.685669,216.671677 
	C1026.040161,222.314713 1029.444336,224.798492 1034.923096,225.677383 
	C1035.422241,226.363113 1035.755249,226.935562 1035.548828,227.308701 
	C1032.328491,233.127792 1035.552368,236.403030 1040.411377,239.400787 
	C1041.989746,241.882950 1044.314453,243.532181 1044.529053,246.712524 
	C1043.412354,248.700638 1041.728882,249.286041 1040.036011,249.832764 
	C1042.263794,251.756638 1043.844360,254.670166 1047.598877,255.088226 
	C1049.159424,255.884735 1050.577515,257.000275 1051.571777,255.139236 
	C1052.482544,253.434555 1050.445068,253.237854 1049.460449,252.102280 
	C1048.343506,250.447342 1047.931885,248.984299 1048.641113,247.019714 
	C1052.418457,244.729919 1054.474854,246.927628 1056.712158,249.710129 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1035.222656,227.256149 
	C1034.436035,226.695938 1033.899170,226.201416 1033.303833,226.116501 
	C1025.003174,224.932190 1025.001221,224.945847 1025.000000,216.448120 
	C1025.256348,215.385742 1025.629883,214.793243 1025.748657,214.153488 
	C1026.242676,211.489410 1025.456909,207.670563 1029.227173,207.343811 
	C1032.767212,207.037033 1036.785767,207.861267 1038.949829,211.141754 
	C1041.479370,214.976334 1045.047729,213.728867 1048.381104,214.000977 
	C1051.960327,214.293137 1053.367065,211.970718 1055.870850,209.795685 
	C1060.712524,205.589859 1062.451904,201.554459 1062.313110,195.594666 
	C1062.259033,193.275803 1065.033203,192.981873 1067.064575,193.037720 
	C1070.308472,193.126923 1072.648926,192.909286 1073.339966,188.620789 
	C1073.786133,185.851776 1077.318848,184.413498 1079.702148,182.157257 
	C1080.465454,181.827560 1080.852661,181.923996 1081.074463,182.666901 
	C1079.985229,184.700150 1078.937012,186.381470 1077.963745,188.105042 
	C1076.631592,190.464249 1076.599365,192.447342 1079.481201,193.779861 
	C1082.360107,195.111038 1083.036255,197.754822 1081.101929,199.837112 
	C1079.225342,201.857315 1076.244141,201.861832 1074.021973,199.709717 
	C1072.888794,198.612381 1072.421021,196.501266 1070.276978,197.299850 
	C1068.248047,198.055573 1067.930176,200.022079 1068.044067,201.861633 
	C1068.357666,206.927689 1066.224854,210.036942 1061.211060,211.172150 
	C1059.672607,211.520477 1058.421631,212.536438 1058.284546,214.170761 
	C1058.119019,216.144043 1059.924561,216.854462 1061.259521,216.782227 
	C1064.287964,216.618393 1065.827026,221.260757 1069.652832,219.043488 
	C1071.197388,220.009262 1071.563232,221.434570 1072.030151,222.844696 
	C1073.601074,227.588074 1074.736084,228.075317 1080.266846,226.366211 
	C1077.650879,223.473541 1073.748291,222.471420 1071.132690,219.319458 
	C1072.395020,215.992920 1075.586670,216.362274 1078.026489,215.500534 
	C1079.225342,215.077133 1079.737061,216.539825 1079.523315,217.905472 
	C1078.790161,222.586243 1081.419067,221.082108 1083.498169,219.892120 
	C1088.008179,217.310806 1088.569214,217.598984 1088.999878,223.595016 
	C1089.343506,228.748123 1084.713989,230.946213 1083.337280,234.927597 
	C1082.607788,237.036713 1081.322632,238.927490 1082.682129,240.890213 
	C1085.162109,244.470474 1083.019775,247.275909 1080.810303,249.146744 
	C1078.295776,251.275955 1074.738892,251.952759 1071.465698,250.109375 
	C1069.563232,249.037857 1068.349976,247.121674 1069.584717,245.099792 
	C1071.494995,241.972137 1069.843872,238.930603 1069.841309,235.891357 
	C1069.839111,233.373093 1067.956787,232.154968 1065.773682,233.951141 
	C1063.339355,235.954025 1060.837646,237.125320 1057.660522,237.130600 
	C1056.178467,237.133057 1054.638550,237.761673 1054.195679,239.469986 
	C1053.748779,241.193466 1053.986572,242.896545 1055.733765,243.793243 
	C1058.379761,245.151276 1057.960938,247.123962 1057.047852,249.638260 
	C1053.932983,250.586792 1053.013062,245.995697 1049.385864,247.001404 
	C1047.698120,248.141891 1046.268799,248.419815 1044.341553,247.317474 
	C1043.387695,244.471252 1040.241455,243.517914 1040.044678,240.352509 
	C1039.840454,239.191681 1039.720703,238.361938 1039.956055,237.567429 
	C1041.335327,232.911911 1039.644409,229.654572 1035.222656,227.256149 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1018.636841,216.951996 
	C1019.000000,218.388885 1019.000000,219.777786 1019.000000,221.583344 
	C1010.734558,221.323761 1003.178223,224.521378 995.456177,226.569199 
	C992.281921,227.410980 992.508911,231.549255 995.608459,233.699631 
	C996.408325,234.254578 997.390625,234.614487 998.341248,234.869400 
	C1000.067932,235.332413 1002.624146,235.241501 1002.585999,237.466736 
	C1002.548218,239.672989 999.653809,239.041870 998.273804,240.010269 
	C992.521851,244.046555 985.291504,241.074219 979.433167,244.082993 
	C975.384583,246.162308 971.048340,247.950897 970.638550,253.843887 
	C970.465698,256.328674 968.211975,258.372070 964.699402,257.467499 
	C959.187988,256.048126 952.949402,258.470245 947.867615,254.380768 
	C947.723877,254.265106 946.852234,255.053970 946.160034,255.710510 
	C942.610046,258.867432 938.244751,256.637878 934.114014,258.551208 
	C937.901978,259.194550 941.311218,256.287231 944.789856,258.729492 
	C945.594055,263.419556 943.944092,265.507629 939.228210,265.056000 
	C936.123718,264.758698 932.967529,264.974396 929.834961,265.008789 
	C928.159729,265.027191 926.505554,264.932739 925.072510,263.993713 
	C923.298157,262.830994 921.148071,261.743103 921.254761,259.256805 
	C921.357910,256.854004 923.920715,257.218445 925.674683,256.145935 
	C928.025940,254.474106 926.976379,251.755096 928.568970,249.666229 
	C932.012756,246.755295 936.066467,247.315079 939.749695,246.521729 
	C941.960022,246.045624 943.852051,245.539841 944.827698,243.163544 
	C946.513977,239.056381 949.829285,237.556763 954.084106,238.033646 
	C956.880188,238.347076 958.843201,237.592941 960.060791,234.825302 
	C961.603760,231.318237 964.776794,230.334030 968.306763,230.353317 
	C971.454590,230.370514 974.174500,229.762405 976.089844,226.899231 
	C977.147095,225.318710 978.934509,224.717789 980.839111,224.489716 
	C990.548767,223.327026 990.547180,223.313919 991.624329,213.037689 
	C994.119751,208.071533 997.280273,206.659027 1003.668213,207.709076 
	C1007.830383,208.592316 1012.878235,206.868713 1013.937317,212.877594 
	C1014.397827,215.490585 1016.216003,216.226288 1018.636841,216.951996 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M1004.157104,207.662018 
	C1000.243774,209.104446 995.723511,208.263138 993.055298,212.638855 
	C990.168518,214.744583 987.901550,216.413727 988.078369,220.590103 
	C988.237732,224.355026 983.327332,225.944763 980.251343,222.834045 
	C976.158447,218.694977 973.771545,219.970367 969.575684,223.033157 
	C965.154053,226.260696 961.440918,229.557709 958.939026,234.362839 
	C958.058716,236.053482 956.565918,237.196472 954.463928,236.822372 
	C952.236572,236.425980 951.621277,234.595352 951.474243,232.755005 
	C951.113831,228.245529 948.284912,223.133423 954.620178,219.986572 
	C958.376831,218.190582 962.229614,216.840347 959.561951,211.516434 
	C958.671143,209.738632 960.772949,209.115753 962.336365,209.043503 
	C965.293518,208.906845 968.255493,208.877853 971.669800,209.761307 
	C970.745667,206.344879 968.861389,205.258713 965.956116,204.942184 
	C961.476807,204.454224 960.116028,202.003082 961.042480,196.578735 
	C961.781311,192.253128 965.458801,192.229446 967.964539,193.364105 
	C973.339355,195.797943 977.962402,194.782623 982.530396,191.923676 
	C983.940186,191.041290 985.050781,191.630081 986.651794,191.957428 
	C987.750977,195.316406 989.009338,197.535080 992.822632,194.304199 
	C993.487183,194.040771 994.292419,193.915527 994.414368,194.148239 
	C996.617676,198.353912 999.650024,200.212418 1004.751465,200.682770 
	C1007.528381,200.938797 1005.269897,205.028198 1004.157104,207.662018 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1081.372314,182.991150 
	C1080.873413,182.695389 1080.679565,182.442245 1080.209229,182.120270 
	C1080.434570,179.496475 1078.903687,177.686157 1077.896606,175.633209 
	C1075.340454,170.422760 1076.868652,168.098862 1082.575073,167.972733 
	C1085.464722,167.908890 1088.434204,168.597351 1091.151855,166.651672 
	C1092.864014,165.425873 1094.247681,166.347336 1094.946533,168.643143 
	C1095.115479,171.134293 1094.939941,173.227051 1094.055664,175.613602 
	C1088.872803,176.498657 1086.787842,182.336700 1081.372314,182.991150 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1025.015503,198.024658 
	C1025.600464,191.834793 1030.470947,192.501160 1034.067139,192.186371 
	C1036.712524,191.954834 1040.786255,190.123367 1041.883301,194.871994 
	C1042.681152,198.325882 1039.908936,201.280441 1035.165161,202.433640 
	C1028.631714,204.021912 1026.586060,203.215790 1025.015503,198.024658 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M1056.785645,130.803864 
	C1049.864502,128.890671 1048.609741,127.185417 1050.728271,122.710251 
	C1051.774902,120.499474 1053.126343,117.809563 1056.007080,118.330994 
	C1059.568237,118.975578 1062.757202,121.671478 1063.390259,124.885529 
	C1064.117554,128.578247 1059.971680,129.493317 1056.785645,130.803864 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M1062.628784,179.408432 
	C1060.257080,181.600983 1051.739868,180.369003 1050.324707,178.006973 
	C1048.979126,175.761063 1051.582642,171.712250 1054.593262,171.368790 
	C1058.543091,170.918182 1062.718018,174.832764 1062.628784,179.408432 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M1053.767334,203.684540 
	C1051.472168,206.552765 1048.917969,207.828201 1045.673584,206.608841 
	C1044.031982,205.991882 1042.920654,204.671326 1043.185059,202.741165 
	C1043.434448,200.920135 1044.962524,201.157303 1046.263916,200.989761 
	C1048.223999,200.737396 1050.159912,200.206497 1052.123291,200.093384 
	C1054.679443,199.946106 1055.582031,201.039948 1053.767334,203.684540 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1109.739014,163.467407 
	C1109.482178,162.460724 1109.983154,162.146988 1110.638794,162.273895 
	C1110.943848,162.332916 1111.429077,162.904388 1111.372070,163.124176 
	C1111.187134,163.838303 1110.559570,163.857269 1109.739014,163.467407 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M995.331299,272.999390 
	C991.514893,270.909210 992.653381,268.312195 994.597412,266.118225 
	C996.332458,264.160156 998.425354,265.198822 999.709961,266.875183 
	C1001.924194,269.764618 1000.050903,271.359314 997.226196,272.750916 
	C996.554993,272.998810 996.110046,272.999054 995.331299,272.999390 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M896.201050,334.180511 
	C896.676086,333.990784 896.842346,333.959930 896.989319,333.889160 
	C899.264954,332.793304 900.969482,333.499268 901.557495,335.843201 
	C901.871765,337.095917 901.023560,338.968781 899.526794,338.448639 
	C897.838501,337.861908 894.338318,338.055450 896.201050,334.180511 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M992.650757,302.956787 
	C993.000000,303.444183 993.000000,303.888336 993.000000,304.665649 
	C993.380371,305.384460 993.527039,305.844604 993.219971,306.689636 
	C991.184692,306.858612 989.312561,307.367157 987.279297,306.261475 
	C987.113098,302.128082 989.358337,301.921112 992.650757,302.956787 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M980.324219,302.958496 
	C979.417053,302.186707 978.834045,301.373413 978.125549,300.280701 
	C977.619629,299.616150 977.473083,299.156494 977.780029,298.312378 
	C979.388916,298.001587 980.777771,298.000702 982.583374,297.999908 
	C983.941528,300.464233 983.001709,302.028687 980.324219,302.958496 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M831.038147,207.661484 
	C827.495422,211.975723 823.506775,209.895050 819.779724,208.663345 
	C816.619446,207.618942 817.262024,205.055267 817.812317,202.422394 
	C818.280457,200.182510 817.972412,197.781479 818.018982,195.451965 
	C818.046326,194.080353 818.228882,192.502502 819.470154,191.931686 
	C820.803345,191.318573 822.018188,192.451202 822.895020,193.448746 
	C824.989624,195.831573 827.420044,196.718521 830.729553,195.258240 
	C833.236816,195.174088 834.765259,197.559235 837.618652,196.999451 
	C839.019958,198.430756 838.618591,199.885208 838.037109,201.670746 
	C836.238831,204.484985 831.334229,202.882248 831.038147,207.661484 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M767.013977,216.038635 
	C765.443176,214.952866 763.130737,214.837448 763.528748,212.633316 
	C763.843933,210.887787 765.803894,210.753983 767.161682,210.523651 
	C771.046631,209.864670 774.975220,209.445557 778.897339,209.033798 
	C780.209167,208.896072 781.741638,208.921387 782.373230,210.352676 
	C783.124817,212.055786 781.546875,212.585526 780.484436,213.300217 
	C778.573120,214.585907 776.491455,215.679108 774.784607,217.194687 
	C771.784302,219.859009 769.502991,218.923721 767.013977,216.038635 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M808.988525,192.955109 
	C813.402039,193.196136 813.549927,196.072571 812.720276,198.681793 
	C811.516235,202.468246 808.040710,200.743881 805.448547,200.806183 
	C804.619568,200.826111 803.613281,200.048477 802.956726,199.374161 
	C801.289978,197.662460 798.449402,195.982971 799.795227,193.309845 
	C800.997986,190.921143 803.943115,192.090439 806.182617,192.260818 
	C806.990051,192.322235 807.774292,192.689331 808.988525,192.955109 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M719.757812,238.354950 
	C723.759705,237.217407 723.671875,231.468842 728.338623,231.646530 
	C729.744873,231.700073 731.353455,230.516296 732.319275,232.262070 
	C733.252380,233.948685 731.616394,234.516846 730.574036,235.265442 
	C729.554932,235.997375 728.692444,236.976059 729.492188,238.180206 
	C730.249695,239.320709 731.550415,239.049698 732.662048,238.525864 
	C733.557129,238.104080 734.405518,237.582977 735.637268,237.052979 
	C736.311462,237.788406 736.622986,238.576813 736.967224,239.682602 
	C733.469482,240.578278 729.993530,241.599503 726.369385,240.150238 
	C725.011414,239.607193 723.418823,239.976013 722.193237,241.678787 
	C719.535217,242.138687 719.116455,240.732773 719.757812,238.354950 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M813.661865,208.043396 
	C815.214600,208.812347 816.525452,209.513016 817.623657,210.460297 
	C819.887634,212.413239 819.374695,216.569244 816.747498,216.678726 
	C813.380798,216.819046 809.516785,217.323364 807.060913,213.373062 
	C809.483765,211.846329 810.569458,208.892838 813.661865,208.043396 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M788.165344,202.256287 
	C792.423706,199.586700 793.806885,203.219604 795.327515,205.347839 
	C796.518005,207.013931 794.410767,207.976883 793.097290,208.345627 
	C790.620483,209.040970 787.770203,211.738449 785.579529,208.476532 
	C783.948486,206.047852 785.189392,203.761337 788.165344,202.256287 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M750.525391,208.597687 
	C748.653992,207.270569 747.290344,205.797409 748.533142,203.920853 
	C749.558899,202.371948 751.514343,202.884369 753.062866,203.300964 
	C754.517090,203.692215 755.075439,204.958862 754.860229,206.385162 
	C754.509277,208.711517 752.913513,209.159027 750.525391,208.597687 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M865.000000,132.375397 
	C866.043091,130.094818 867.594849,129.571365 869.537598,130.475906 
	C870.507446,130.927521 871.039612,131.849548 870.726501,132.909317 
	C869.953247,135.525986 867.690613,134.855728 865.406250,134.998932 
	C865.000000,134.249237 865.000000,133.499710 865.000000,132.375397 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M751.997803,229.615173 
	C751.503845,230.257553 751.007690,230.515701 750.255737,230.887543 
	C746.648560,230.620987 747.035950,228.086212 747.000000,225.397369 
	C749.767212,225.108932 752.157776,225.663147 751.997803,229.615173 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M747.000000,225.000122 
	C746.518433,224.710373 746.036865,224.420517 745.555298,224.130676 
	C745.721069,223.936081 745.886902,223.741501 746.052673,223.546906 
	C746.340454,223.852921 746.628296,224.158951 746.958008,224.732178 
	C747.000000,224.999390 747.000000,225.000000 747.000000,225.000122 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M719.611938,238.001984 
	C719.974426,239.410873 720.068298,240.775345 721.702148,241.831787 
	C723.856506,245.068542 726.907715,244.980942 729.931030,245.053955 
	C731.290527,245.086807 732.616394,245.482040 733.368042,246.718323 
	C733.743530,247.335876 733.329224,248.027237 732.729370,248.406784 
	C731.474609,249.200714 730.273193,248.880539 728.956421,248.345108 
	C722.545471,245.738297 717.011597,248.962234 717.091919,255.697571 
	C717.175171,262.685791 711.668701,260.865936 708.232056,261.556213 
	C704.204224,262.365234 705.312378,258.640961 704.970764,256.392426 
	C704.777222,255.118225 704.825745,253.310211 702.932678,253.728622 
	C701.522888,254.040192 701.891357,255.720123 702.194031,256.739166 
	C702.819519,258.845123 702.796387,261.002350 703.119385,263.122192 
	C703.530762,265.821503 704.238098,268.459259 708.237427,267.246094 
	C709.496826,266.864075 711.111755,267.727448 712.576538,267.929840 
	C714.388611,268.180206 715.856018,268.778931 715.854126,270.924530 
	C715.852234,273.118408 713.959656,272.862671 712.622681,273.275757 
	C710.130798,274.045715 707.832520,274.894562 704.936584,274.412384 
	C702.295959,273.972778 699.183411,275.315063 697.436768,277.523132 
	C694.112610,281.725189 690.623657,284.031219 684.792664,284.693237 
	C680.269043,285.206848 675.276611,286.942413 670.388794,288.988953 
	C669.416321,287.452026 668.753052,285.928467 668.268616,284.350037 
	C667.636658,282.290558 666.596436,281.508301 664.655457,282.903046 
	C663.821472,283.502319 662.919739,284.120911 661.909485,283.760132 
	C660.165588,283.137390 658.846130,283.419067 658.508606,285.332336 
	C658.066162,287.839905 660.038452,287.881470 661.703308,287.996429 
	C663.549438,288.123901 665.138550,288.902069 665.521118,290.649750 
	C665.897400,292.368958 664.853455,293.894501 663.246216,294.710175 
	C662.173889,295.254364 661.174988,295.656738 660.987671,297.061371 
	C660.804382,298.434662 660.302979,299.971588 658.563110,299.465973 
	C656.846313,298.967010 656.307312,297.543976 656.217773,295.582153 
	C655.847412,287.471771 653.216125,281.098175 647.912415,279.224243 
	C646.593262,278.758148 645.471436,277.823364 644.470215,279.301880 
	C643.042847,281.409760 640.689331,281.397156 638.695312,281.748199 
	C636.602905,282.116577 633.071228,283.143280 632.713867,280.265045 
	C632.336304,277.225220 636.406311,274.446411 638.330444,275.382568 
	C644.472107,278.370819 648.104065,273.313538 652.860291,272.111389 
	C653.461975,271.959320 654.456421,271.437164 654.580688,271.602814 
	C657.486572,275.476257 661.318909,271.022858 664.544006,272.731018 
	C665.497864,273.236237 667.224487,271.666718 666.691345,270.695374 
	C664.563721,266.819153 666.109375,262.118500 663.327515,258.200470 
	C660.562683,258.525055 658.771667,257.459656 657.136841,255.336258 
	C655.944641,251.576508 656.537598,250.043884 660.288025,252.132217 
	C661.696777,252.916626 662.966492,252.936035 664.481445,252.077988 
	C669.118347,249.451645 674.206299,253.149704 678.976379,251.269196 
	C679.253296,251.160034 680.183838,251.952789 680.348572,252.472168 
	C680.693420,253.559952 679.753052,253.715408 678.932373,253.974655 
	C676.121155,254.862732 673.313171,255.770798 670.547119,256.788452 
	C669.286011,257.252441 667.949463,258.039948 668.295349,259.643921 
	C668.669678,261.379211 670.265381,261.902527 671.796326,261.760559 
	C674.092407,261.547546 676.427002,261.235870 678.618530,260.557190 
	C680.722656,259.905609 682.225098,260.329712 683.350464,262.080811 
	C684.469727,263.822510 683.487610,265.266998 682.404480,266.578430 
	C681.479065,267.698883 679.788696,268.732422 680.729248,270.302307 
	C681.536133,271.649200 683.126282,270.679321 684.398682,270.422363 
	C685.994324,270.100098 686.829590,271.270752 687.602112,272.339508 
	C688.480774,273.555115 689.451538,274.394379 690.880859,273.320526 
	C692.173889,272.349030 692.036255,271.003510 691.328247,269.734161 
	C690.027588,267.402100 689.533508,265.204559 691.358643,262.796967 
	C692.313721,261.537170 691.313049,260.051361 690.427734,259.215820 
	C688.469360,257.367401 688.019043,254.933701 687.294922,252.588715 
	C686.961121,251.507919 685.350037,250.121307 687.344299,249.325668 
	C688.788696,248.749420 689.801331,250.144043 690.494446,251.248093 
	C692.027039,253.689301 692.301636,257.095947 695.851868,257.932953 
	C696.751831,258.145142 697.647278,259.157440 698.532837,258.105377 
	C699.136902,257.387787 698.580872,256.556458 698.421631,255.756958 
	C697.707703,252.172882 694.567749,250.479736 692.484375,247.960052 
	C690.149292,245.136032 687.702515,244.119843 684.353271,246.523575 
	C683.472168,247.155975 681.928833,246.325928 681.572021,245.115646 
	C681.231873,243.961502 682.274231,243.335587 683.311035,243.143768 
	C686.140564,242.620255 688.665771,241.903809 690.247498,239.011444 
	C691.153809,237.354080 692.422424,238.359528 693.300964,239.636414 
	C695.897644,243.410629 699.387634,243.703064 703.340576,242.121979 
	C704.082458,241.825226 704.968872,241.307587 705.629578,241.484833 
	C709.815674,242.607773 713.464966,242.284790 716.390564,238.584808 
	C716.848206,238.006073 718.255676,238.178360 719.611938,238.001984 
z"/>
<path fill="#1A1F34" opacity="1.000000" stroke="none" 
	d="
M782.456543,290.529480 
	C783.553833,291.180908 784.344666,291.732971 785.295349,292.527954 
	C781.873230,293.938965 779.368469,296.603424 778.322937,299.891479 
	C774.585327,311.645447 767.585693,321.776398 762.488281,332.839874 
	C760.030457,338.174347 757.204956,343.365906 755.988647,349.591614 
	C755.424011,351.001923 754.462158,351.458435 753.041260,351.916779 
	C751.827454,352.090820 751.040710,351.968170 749.967468,351.404297 
	C747.981018,348.320557 749.162964,346.087799 751.217285,344.069763 
	C755.644287,339.721191 757.590210,334.688416 756.204346,328.397095 
	C754.792114,321.985901 755.997925,320.934998 762.256409,322.472046 
	C762.368469,322.353180 762.569092,322.241608 762.579529,322.114380 
	C762.828369,319.086212 765.555664,316.400146 764.932983,313.627563 
	C764.266113,310.658173 759.966370,311.644135 757.954529,309.566589 
	C755.154297,306.674957 750.561401,305.240051 750.706787,299.458954 
	C745.780640,303.445526 740.320251,302.456146 735.166199,302.940552 
	C726.171082,303.785889 717.558777,306.213745 709.119873,309.188751 
	C705.622681,310.421631 702.381409,311.654907 698.539551,311.290955 
	C694.352966,310.894379 689.861328,311.834106 686.454590,313.715759 
	C679.473450,317.571777 671.723083,317.425598 664.447632,319.511719 
	C651.903320,323.108521 638.869202,324.817383 626.363525,328.318848 
	C615.635071,331.322662 604.738953,333.697266 593.921692,335.789459 
	C580.685730,338.349487 567.970459,343.027832 554.553406,344.665070 
	C549.098022,345.330811 544.106689,347.288116 539.131348,349.960510 
	C531.768372,353.915405 523.658203,353.506683 515.657471,350.407532 
	C515.744019,348.611023 516.811829,348.148224 517.978088,347.867645 
	C533.634338,344.100677 549.091003,339.491394 564.945923,336.576294 
	C568.226379,335.973145 571.217529,334.359680 574.930786,334.083282 
	C576.402100,333.868927 577.549927,333.951538 578.934326,333.397278 
	C580.788147,333.046783 582.277710,332.613495 584.082214,332.059387 
	C585.287170,331.823944 586.150879,331.618378 587.260620,331.036438 
	C594.901306,328.428345 602.757751,328.364838 610.417480,325.851685 
	C611.851868,325.833923 612.937561,325.812225 614.230774,325.109436 
	C620.140442,323.636200 625.753540,322.344482 631.688538,320.964783 
	C632.874634,320.819305 633.740112,320.772125 634.825562,320.249451 
	C636.523254,319.567627 638.004639,319.335480 639.781921,319.024261 
	C640.873840,318.918060 641.666321,318.885101 642.679077,318.451294 
	C643.329163,318.223846 643.711060,318.130005 644.388550,318.013306 
	C645.249695,317.866943 645.825378,317.785217 646.642578,317.451294 
	C647.297791,317.223663 647.683105,317.128540 648.368469,317.008728 
	C649.246704,316.857910 649.835266,316.774811 650.673218,316.430298 
	C651.355957,316.191254 651.759583,316.087433 652.482056,315.938110 
	C653.674988,315.742065 654.477051,315.389954 655.599854,314.870819 
	C660.337952,313.689178 664.760071,312.724274 669.651855,312.250305 
	C671.096008,312.322296 671.910522,311.725830 673.102661,311.043488 
	C680.277100,309.059631 687.114624,307.288361 694.511841,306.291077 
	C695.885742,306.105011 696.851501,305.898743 698.018494,305.097412 
	C708.919312,301.339203 720.006409,299.944550 731.102112,297.030579 
	C732.586365,296.845642 733.738770,296.859985 735.076416,296.137085 
	C737.932312,295.009521 740.625244,294.605225 743.607971,294.001160 
	C745.021240,293.919739 746.082886,293.780396 747.368408,293.114441 
	C754.942993,290.739960 762.495728,289.635101 770.062500,287.172302 
	C771.273621,286.678528 772.227478,286.578644 773.474365,286.197418 
	C774.299011,286.064056 774.771790,286.090240 775.565552,286.329285 
	C776.110352,286.557861 776.330933,286.642639 776.681641,287.057037 
	C777.322205,287.637665 777.832520,287.888672 778.634644,288.178467 
	C779.529846,288.047943 780.054443,288.210480 780.650452,288.857300 
	C781.233276,289.555420 781.691711,289.992798 782.456543,290.529480 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M702.084351,288.406006 
	C702.270447,285.700256 704.445496,284.052521 704.056274,281.456635 
	C703.814514,279.844330 703.835022,277.880005 705.927490,277.341064 
	C707.907043,276.831238 709.551575,278.004456 710.722290,279.401062 
	C711.645264,280.502167 712.030518,282.042755 712.717834,283.355042 
	C714.187866,286.161926 715.887634,287.028687 718.433960,284.311646 
	C721.583313,280.951202 725.268555,279.673798 730.099731,280.663635 
	C733.413879,281.342621 736.786255,278.801117 740.505859,279.919586 
	C744.217224,281.035553 748.023926,281.833252 751.806030,283.677612 
	C752.090576,286.148193 750.326477,286.387054 748.988647,286.435699 
	C745.235535,286.572144 741.897034,287.400269 739.303101,290.360779 
	C738.785156,290.951935 737.800171,290.958099 736.562622,290.883789 
	C732.276062,288.723114 727.958191,288.692963 724.246033,290.290710 
	C720.328918,291.976654 716.704102,292.138885 712.795959,291.242493 
	C710.343140,290.679901 707.876404,291.014954 705.009766,290.935577 
	C703.470825,290.569946 702.678711,289.857269 702.084351,288.406006 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M735.614380,289.820984 
	C736.655396,289.929626 737.749756,290.074585 737.901306,289.762238 
	C740.796692,283.793549 746.550354,285.357941 751.695190,284.269531 
	C758.532349,286.378906 764.704956,281.840637 771.172485,283.001587 
	C773.056641,283.339813 775.610596,282.269684 776.098999,285.660339 
	C776.133911,286.135284 776.272095,286.314728 776.208130,286.767151 
	C775.557495,287.075867 775.109009,287.111511 774.332397,287.059113 
	C772.514954,286.451569 771.043945,286.165955 769.390381,287.469360 
	C768.674683,287.777863 768.241577,287.867737 767.468628,287.910889 
	C765.458191,287.479797 763.807373,287.249756 762.113403,288.801086 
	C760.145691,289.701660 758.419617,290.255310 756.221680,290.272949 
	C754.741516,290.211426 753.673767,290.480743 752.322021,291.122986 
	C746.403015,291.948334 741.299683,294.942200 735.138306,294.668823 
	C732.916504,294.515991 731.312317,295.340027 729.275208,296.039398 
	C728.391602,296.313965 727.867981,296.393921 726.930664,296.436707 
	C722.439880,296.448730 718.674683,299.392853 714.078125,297.519409 
	C712.473328,294.757721 713.357178,292.635071 715.847351,292.274200 
	C722.296204,291.339691 728.455750,288.125000 735.614380,289.820984 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M697.314758,299.312317 
	C693.510620,298.907196 690.432251,298.083679 688.201233,295.015961 
	C687.209412,293.652130 685.495544,293.756195 684.107422,294.635162 
	C682.802856,295.461212 681.501648,296.175232 679.930298,295.979553 
	C676.937134,295.606812 674.955933,296.607971 673.582092,299.483490 
	C672.799255,301.122131 671.517029,303.195496 668.992737,302.422729 
	C667.778503,302.050995 666.093323,302.437958 665.428772,301.031616 
	C664.677612,299.441711 666.087891,298.701385 667.089050,297.831390 
	C668.460205,296.639893 668.999268,295.231110 668.302429,293.380463 
	C667.735046,291.873383 667.975281,290.417114 669.692261,289.179688 
	C670.260437,291.156738 669.874634,292.870087 673.676636,292.123077 
	C679.673035,290.945007 686.049927,286.751892 692.127991,292.355499 
	C693.328857,293.462616 694.938782,293.024536 696.668518,292.147339 
	C699.294128,290.998657 701.135681,292.297974 703.385010,293.449646 
	C704.756958,294.848450 705.120178,296.151733 704.432922,298.030212 
	C702.193665,299.768005 700.110657,300.772675 697.314758,299.312317 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M831.378784,208.009521 
	C827.924988,205.710388 828.592957,204.105377 831.883972,202.877625 
	C833.604919,202.235611 835.343933,201.881348 837.588257,201.999710 
	C842.249146,201.395294 846.342102,205.865204 850.779175,202.323059 
	C851.394836,201.831619 852.535278,203.049271 852.771912,204.100754 
	C853.049622,205.334930 852.867371,206.785263 851.397461,206.774704 
	C847.794556,206.748779 845.220886,208.658340 842.409973,210.505051 
	C840.584167,211.704590 838.234924,211.117493 836.196716,210.086304 
	C834.741333,209.349991 833.608521,207.905548 831.378784,208.009521 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M725.586548,260.864258 
	C724.794373,262.234253 724.087036,263.396515 723.095032,264.212830 
	C721.977234,265.132690 720.669312,266.194611 719.062073,265.562897 
	C717.685425,265.021790 718.067200,263.621765 718.042725,262.507050 
	C717.997131,260.434662 719.400574,259.559845 721.128601,259.201630 
	C722.768921,258.861572 724.476624,258.713440 725.586548,260.864258 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M781.040894,288.909485 
	C780.240662,288.986969 779.706421,288.855896 779.049316,288.471039 
	C778.950684,287.092682 778.974854,285.968170 778.999390,284.421814 
	C780.860046,283.242279 782.059448,280.720734 784.565857,281.759003 
	C785.599060,282.187012 787.850220,281.418671 787.356262,283.551025 
	C786.968689,285.224213 785.586731,286.401855 783.561951,285.065765 
	C783.057678,284.733002 782.442749,284.567810 781.891113,284.330872 
	C780.771301,285.840881 782.715515,287.461884 781.040894,288.909485 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M703.337769,293.788025 
	C700.977661,294.173340 699.326294,293.180573 697.320374,292.147583 
	C696.850037,289.001282 698.833618,288.279541 701.624512,288.009277 
	C702.580627,288.493011 703.161255,288.985992 703.870972,289.739502 
	C705.046570,291.296753 704.601501,292.458191 703.337769,293.788025 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M737.352783,267.347656 
	C738.972351,268.609833 739.111450,269.833069 737.422546,270.479034 
	C736.082825,270.991455 734.603516,270.245483 734.381104,268.841553 
	C734.058472,266.805206 735.721741,267.087555 737.352783,267.347656 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M776.183594,287.182373 
	C776.001404,286.859680 775.996765,286.679138 775.993896,286.227264 
	C776.242004,286.243652 776.488281,286.531433 776.773193,287.102936 
	C776.811890,287.386658 776.361145,287.324585 776.183594,287.182373 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M929.268188,249.726501 
	C928.369507,252.026794 930.578918,255.109665 926.391907,255.979828 
	C924.043457,252.875809 921.818115,250.136841 917.696350,249.987091 
	C913.310608,249.827759 912.207336,247.635910 913.947021,243.378052 
	C920.338501,241.604904 926.230225,236.149109 933.621460,240.941528 
	C936.378662,245.859406 931.516235,246.895142 929.268188,249.726501 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M945.244263,258.778992 
	C942.369263,258.959473 939.729370,258.814636 937.346680,260.296478 
	C935.491760,261.450134 933.859680,260.494171 932.275085,259.613037 
	C931.321289,259.082672 929.881348,258.694641 930.364319,257.189392 
	C930.816833,255.779404 932.030212,256.899750 932.856445,256.720276 
	C936.904114,255.841156 940.995605,256.005341 945.541992,256.000000 
	C945.829468,256.852264 945.658997,257.704529 945.244263,258.778992 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M836.599731,261.154358 
	C840.332520,265.012451 835.748474,266.666321 834.872131,269.222473 
	C834.826477,269.355591 833.697937,269.384460 833.598083,269.191071 
	C832.476318,267.018494 832.924622,264.663055 833.181458,262.416138 
	C833.378967,260.687744 834.487976,259.723694 836.599731,261.154358 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M798.614380,254.645874 
	C797.250854,253.272858 794.307495,253.140091 795.634583,250.632965 
	C796.255188,249.460541 797.663391,248.173325 799.240234,248.472046 
	C801.023010,248.809799 801.855469,250.308350 801.951660,252.130096 
	C802.064148,254.261047 801.121399,255.212814 798.614380,254.645874 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M798.910889,273.337158 
	C800.143066,274.698822 800.420959,275.926300 798.743896,276.681732 
	C797.919434,277.053070 797.010498,276.588531 796.674316,275.775513 
	C795.934692,273.986847 797.061035,273.412292 798.910889,273.337158 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M828.533997,257.044250 
	C828.175842,257.694244 827.816284,257.703339 827.499268,257.236847 
	C827.432983,257.139282 827.436035,256.913574 827.505310,256.818756 
	C827.836975,256.365356 828.196045,256.381836 828.533997,257.044250 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M929.401855,249.939896 
	C928.759460,246.098770 934.075439,245.548447 933.991577,241.368332 
	C937.170044,239.968796 941.157532,235.357330 942.467529,232.460678 
	C944.079163,228.897049 945.614868,225.573380 943.484070,221.733932 
	C943.286987,221.378799 943.404236,220.749435 943.561340,220.314545 
	C944.327209,218.194626 953.348328,217.408386 954.989685,219.648895 
	C952.208008,223.100082 951.120667,231.551727 953.315857,234.794418 
	C954.629822,236.735245 956.175232,236.099854 957.233948,234.365295 
	C958.528320,232.244446 959.851624,230.109436 960.825745,227.836258 
	C961.464478,226.345566 962.249573,225.228775 963.725830,224.657303 
	C966.998169,223.390594 969.859253,221.745331 971.962280,218.691727 
	C973.543823,216.395279 975.909241,216.445526 977.953247,218.493118 
	C979.125061,219.666885 980.127563,221.014801 981.336975,222.144363 
	C982.727417,223.442886 984.509766,223.295059 985.997070,222.535828 
	C987.380310,221.829742 987.384705,219.984680 986.878479,218.944870 
	C983.686951,212.390213 989.184387,213.850922 992.597168,213.001831 
	C993.000000,213.831131 993.003052,214.662277 992.999512,215.493393 
	C992.963623,223.984024 991.617432,225.580368 982.993408,225.899963 
	C979.676514,226.022888 977.236755,226.539124 975.919495,229.953598 
	C974.920044,232.544113 972.382874,232.040314 970.278870,231.974762 
	C965.726013,231.832901 961.670898,231.877228 960.643677,237.930923 
	C960.126770,240.977081 956.798279,240.251907 954.709473,239.835175 
	C949.969604,238.889542 946.839050,240.027496 945.982117,245.130753 
	C945.591736,247.455521 944.034668,247.805145 942.035950,248.037979 
	C937.942627,248.514832 933.879395,249.250626 929.401855,249.939896 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M949.171875,209.212646 
	C949.854309,209.240707 950.209961,209.250092 950.476379,209.409012 
	C952.733337,210.755295 954.084778,212.979248 952.334961,215.010620 
	C951.192261,216.337143 948.364014,215.420837 947.090210,214.136810 
	C944.824341,211.852890 946.920776,210.450226 949.171875,209.212646 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M936.898315,227.882568 
	C935.469849,227.518311 934.796997,226.809875 935.570496,225.759583 
	C936.294434,224.776688 937.354187,225.087296 938.052979,225.890518 
	C938.971436,226.946365 938.435791,227.602936 936.898315,227.882568 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M917.171997,159.696442 
	C914.004944,159.605789 911.577881,162.445389 908.241455,161.247498 
	C908.489685,158.530624 910.445679,158.151199 912.446594,158.030548 
	C914.232117,157.922867 915.509644,157.174988 916.522766,155.734329 
	C917.136475,154.861679 917.067932,152.484955 918.977783,153.902420 
	C920.484253,155.020554 920.357178,156.736526 918.764404,158.061813 
	C918.266418,158.476089 917.816101,158.947571 917.171997,159.696442 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M943.990601,143.400848 
	C945.202637,145.136200 945.585876,146.836563 943.615723,147.689957 
	C942.152588,148.323761 939.904541,148.674271 938.962708,147.428574 
	C937.566223,145.581543 940.034912,145.194656 941.043396,144.412552 
	C941.774353,143.845657 942.771973,143.622604 943.990601,143.400848 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M936.997314,164.606476 
	C935.894958,164.693146 934.791443,164.387527 933.345215,164.040344 
	C933.299805,162.582214 932.237366,160.187927 934.911255,160.359619 
	C937.060669,160.497620 936.939453,162.565002 936.997314,164.606476 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M980.888062,61.515450 
	C980.191101,63.537167 979.075195,64.857727 977.532471,65.509270 
	C975.662659,66.298973 973.424988,66.644188 971.912109,65.023849 
	C970.427979,63.434303 972.137268,61.991249 972.927124,60.607624 
	C973.256104,60.031227 973.647766,59.490456 974.013916,58.935421 
	C975.219910,57.107254 975.662231,53.821922 978.612915,54.664394 
	C981.596436,55.516235 981.003784,58.636391 980.888062,61.515450 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M974.594360,51.454926 
	C972.896301,53.320560 971.824463,52.636765 971.240234,50.934296 
	C970.864563,49.839607 970.923767,48.419743 972.428955,48.301147 
	C974.243591,48.158161 974.957642,49.382538 974.594360,51.454926 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M915.949158,181.358017 
	C916.000000,181.000000 916.000000,180.500000 916.000000,180.250000 
	C916.691040,179.594437 917.488098,179.298172 918.046753,178.756119 
	C918.770935,178.053589 919.372253,177.093781 920.516174,177.549454 
	C921.776672,178.051575 922.026001,179.276611 921.962646,180.476791 
	C921.820740,183.163986 922.075378,185.750427 924.504944,188.955017 
	C920.638855,187.631104 917.507568,190.369781 914.926025,187.585342 
	C912.895935,185.395752 915.284424,183.635147 915.949158,181.358017 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M152.620880,209.998932 
	C152.998596,214.828918 150.515640,219.267700 150.851089,224.190567 
	C150.953339,225.691177 150.130676,227.340500 148.011871,225.951645 
	C147.121002,225.367691 146.214218,224.755188 145.470779,225.874985 
	C144.743301,226.970734 144.934875,228.147339 146.210403,228.811493 
	C147.360977,229.410599 148.102661,230.052505 147.595184,231.515884 
	C146.356934,235.086533 136.296982,237.986221 133.166092,235.632751 
	C131.804596,234.609283 130.920547,232.459930 128.302826,233.868896 
	C126.431198,232.505753 127.191940,230.563339 127.000000,228.402115 
	C127.496140,228.257431 127.992279,228.514862 128.744202,228.885529 
	C131.987274,227.327225 135.500595,227.721848 138.807037,226.275375 
	C139.131546,224.788116 139.578461,223.819519 140.790314,223.281311 
	C148.202377,219.989380 149.681259,216.261429 146.341827,208.741455 
	C148.588257,208.203537 150.144821,210.722839 152.620880,209.998932 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M185.008392,183.375748 
	C186.449463,181.732193 185.275696,180.571976 184.737350,179.513794 
	C183.609833,177.297546 184.174164,175.346863 186.288864,174.666580 
	C189.064377,173.773697 190.017181,175.728928 189.999390,178.622498 
	C189.834869,180.827988 190.274475,182.551926 190.948944,184.622116 
	C192.035782,186.577560 191.189240,187.364777 189.388245,187.957458 
	C186.003204,188.149216 185.278549,186.167694 185.008392,183.375748 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M200.646393,189.153839 
	C201.750000,189.000000 202.500000,189.000000 203.625000,189.000000 
	C204.708771,189.205612 205.113464,189.692032 205.143036,190.696381 
	C205.038712,191.988281 205.027863,192.972412 205.825302,193.754852 
	C206.886703,194.796265 209.604034,194.993942 208.487137,197.163895 
	C207.598770,198.889847 205.417282,199.748077 203.399857,199.755249 
	C201.250229,199.762894 201.073837,197.706955 200.607391,196.179932 
	C200.275101,195.092087 200.195312,193.927109 200.000824,192.397675 
	C199.153793,190.998718 199.470627,190.125595 200.646393,189.153839 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M172.000885,190.385742 
	C172.700714,187.733032 172.524582,184.720291 176.617767,185.001343 
	C177.167358,185.282227 177.338898,185.563309 177.501373,185.849533 
	C178.591812,187.770355 182.562195,188.644073 181.182388,190.933441 
	C180.153152,192.641190 176.685165,193.071671 174.205429,193.656113 
	C171.929337,194.192566 172.104355,192.234238 172.000885,190.385742 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M200.996323,188.598175 
	C200.688339,189.788101 200.376663,190.576202 200.031891,191.681549 
	C197.549271,193.735565 195.370987,193.882187 193.534439,191.065735 
	C192.595047,189.625107 190.940033,189.157944 189.227219,188.249847 
	C189.635376,187.205688 190.270340,186.410126 190.953568,185.306976 
	C192.920013,185.571991 194.784668,185.565826 196.788300,185.126785 
	C199.055756,184.629944 201.217010,184.957581 200.996323,188.598175 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M138.584015,225.999725 
	C139.846313,228.314453 140.639969,230.639587 136.668030,228.656174 
	C134.159332,227.403412 132.223343,231.650665 129.331238,229.145996 
	C129.480652,225.841690 130.898758,224.291016 134.336563,225.558853 
	C135.508835,225.991180 136.885803,225.868530 138.584015,225.999725 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M216.973022,195.711548 
	C216.342026,197.401062 215.214432,198.022003 213.346924,197.994690 
	C212.277451,196.673141 211.884354,195.268875 212.000732,193.373016 
	C213.121048,193.239563 214.242081,193.477905 215.681259,193.859039 
	C216.425354,194.402161 216.788239,194.844498 216.973022,195.711548 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M205.354538,190.909058 
	C204.742340,190.503830 204.484695,190.007675 204.113525,189.255753 
	C204.073029,187.714294 203.891464,186.286514 205.398865,185.657089 
	C205.704239,185.529572 206.514755,185.806564 206.581696,186.042160 
	C207.066437,187.748184 207.664795,189.538528 205.354538,190.909058 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M166.106415,206.755814 
	C164.577576,205.993591 163.490295,205.119034 164.681168,203.697906 
	C165.277100,202.986725 166.224777,203.560242 166.830582,204.054749 
	C168.136688,205.120865 168.354340,206.138733 166.106415,206.755814 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M173.246216,199.234802 
	C174.535904,199.157394 175.746872,199.081146 175.383621,200.550858 
	C175.123108,201.604874 174.090530,202.099472 173.075500,201.628494 
	C171.879257,201.073441 172.080627,200.167603 173.246216,199.234802 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M204.682922,180.040680 
	C204.815552,181.144333 204.679321,182.346100 203.175308,182.485367 
	C202.965469,182.504807 202.535706,182.163315 202.522766,181.968002 
	C202.438522,180.697617 203.409454,180.381622 204.682922,180.040680 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1030.572998,1148.000000 
	C1031.641724,1149.117798 1032.283325,1150.235474 1032.962402,1151.676636 
	C1030.966553,1153.454346 1027.734253,1152.534058 1026.108643,1155.545410 
	C1019.527161,1157.860474 1013.849609,1155.511108 1008.217285,1153.274536 
	C1008.899780,1157.989014 1010.683777,1162.494141 1011.203857,1167.274780 
	C1011.512573,1170.112427 1011.794312,1172.936035 1008.935730,1175.203125 
	C1004.939392,1174.706299 1003.504761,1171.367065 1001.221069,1169.097168 
	C999.680969,1167.566284 998.569214,1167.257935 997.298828,1169.206299 
	C996.047607,1171.125244 996.757263,1174.684448 993.216675,1174.658447 
	C990.812561,1174.640869 989.183594,1172.495972 987.058960,1171.594238 
	C986.756409,1171.465698 986.480713,1171.270386 985.888306,1170.914795 
	C985.039673,1170.228516 984.608276,1169.651245 984.089478,1168.697998 
	C983.784119,1163.877075 979.620300,1163.604980 976.703979,1161.498779 
	C976.173096,1160.851318 975.941223,1160.432129 975.666626,1159.637329 
	C972.591980,1154.933960 971.754517,1149.700073 969.529785,1144.653076 
	C968.970825,1140.947998 967.883240,1138.212158 963.750732,1138.238159 
	C963.267761,1138.241211 962.684082,1138.095459 962.348938,1137.773804 
	C958.961365,1134.522461 956.078491,1133.239136 952.376160,1137.942749 
	C950.573181,1140.233398 947.208801,1138.265381 946.229309,1135.982422 
	C944.204834,1131.264160 939.083679,1130.944702 935.940613,1127.869385 
	C934.437805,1126.399048 928.860474,1129.763916 927.726257,1132.677979 
	C926.946289,1134.681641 926.219360,1136.716187 925.454224,1139.124268 
	C924.627197,1141.971924 925.378357,1144.436768 925.427124,1146.907227 
	C925.474792,1149.323975 925.239136,1151.952271 922.292053,1152.320190 
	C919.079895,1152.721069 918.623596,1149.940918 918.448486,1147.639771 
	C918.146729,1143.675049 917.697144,1139.700806 917.662720,1135.738159 
	C917.625366,1131.421143 918.611389,1127.411987 923.802185,1126.249023 
	C925.497864,1125.869141 926.827637,1124.917358 927.153442,1122.599854 
	C929.784241,1119.954468 932.850098,1119.062988 936.037781,1119.377075 
	C939.128296,1119.681641 940.933411,1119.481567 941.594299,1115.723755 
	C942.119019,1112.739624 945.030518,1111.265991 947.818542,1112.896973 
	C951.940552,1115.308350 957.154541,1115.775269 960.061279,1119.977539 
	C961.656189,1119.434937 961.600525,1118.046631 962.199524,1117.113770 
	C964.566345,1113.427612 968.105530,1112.499146 971.508606,1115.210327 
	C974.279663,1117.417847 976.846069,1119.704834 980.612488,1119.826660 
	C980.933960,1119.837036 981.401428,1119.944702 981.523987,1120.176392 
	C984.220520,1125.273804 989.421143,1126.377319 994.142090,1128.405640 
	C996.269714,1129.319824 996.436218,1131.930298 996.641113,1134.108765 
	C996.852661,1136.358032 998.083191,1137.376221 1000.275208,1137.266113 
	C1001.950623,1137.181885 1003.724670,1137.179688 1004.048645,1134.901489 
	C1004.339600,1132.854614 1003.610718,1131.579102 1001.694702,1130.497437 
	C996.426208,1127.523438 996.140564,1126.545288 998.662537,1122.110474 
	C1000.451782,1122.321289 1002.111877,1123.245728 1003.309937,1122.832031 
	C1005.481323,1122.082642 1006.993347,1129.098633 1009.020752,1123.319702 
	C1009.816650,1121.051147 1012.788269,1120.819092 1012.675659,1117.347656 
	C1012.549744,1113.466553 1016.925781,1114.683105 1018.971191,1115.379272 
	C1021.957153,1116.395264 1023.804993,1116.541382 1026.321411,1114.124390 
	C1029.061890,1111.492310 1037.690308,1113.500000 1040.059204,1116.671875 
	C1041.736084,1118.916992 1040.283203,1125.842529 1037.150635,1127.949463 
	C1034.548340,1129.699585 1031.206909,1129.846069 1028.096924,1129.480591 
	C1022.414673,1128.812744 1021.289429,1129.925781 1022.394592,1135.670410 
	C1022.550842,1136.482422 1022.897339,1137.292725 1022.877747,1138.097046 
	C1022.831848,1139.979614 1021.703918,1141.233643 1019.986572,1141.747559 
	C1018.096619,1142.313354 1016.157654,1141.651611 1014.875916,1140.439697 
	C1013.541565,1139.177979 1015.471497,1138.146606 1015.989563,1137.063599 
	C1017.491821,1133.923340 1017.435791,1130.954834 1015.352722,1128.125000 
	C1014.843994,1127.433960 1014.074341,1127.026611 1013.166321,1127.335449 
	C1012.156067,1127.678833 1011.999451,1128.584229 1012.327332,1129.363403 
	C1014.155518,1133.707642 1011.470093,1137.055420 1009.899841,1140.625854 
	C1008.125854,1144.659180 1008.560425,1148.071777 1012.416077,1150.626709 
	C1015.996399,1152.999146 1020.608276,1152.685547 1023.185120,1149.625854 
	C1025.213745,1147.217285 1027.779541,1148.302979 1030.572998,1148.000000 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M998.736572,1121.743896 
	C997.084900,1125.996338 998.147400,1128.492188 1002.697388,1128.998901 
	C1006.819397,1129.457886 1005.859192,1132.535400 1005.978455,1135.061768 
	C1006.106567,1137.777100 1004.730103,1138.848389 1002.049072,1139.032715 
	C995.856140,1139.458740 995.414856,1139.288208 995.036682,1132.991089 
	C994.789856,1128.880737 992.102966,1128.069336 988.907898,1128.056274 
	C985.646179,1128.042847 982.808044,1127.964478 982.845520,1123.546997 
	C982.858398,1122.026978 981.607727,1120.937744 980.177856,1121.129272 
	C976.216614,1121.660400 973.824829,1119.611572 971.296326,1116.944580 
	C967.868469,1113.329102 964.087463,1114.565552 963.062012,1119.436279 
	C962.695496,1121.177246 962.362244,1123.012939 960.577759,1123.366089 
	C958.421204,1123.792725 958.878052,1121.340576 958.155518,1120.225464 
	C957.280640,1118.875488 956.220154,1117.778198 954.749634,1117.492798 
	C951.941345,1116.947632 949.263672,1116.416138 947.327698,1113.977661 
	C946.412231,1112.824585 945.201599,1113.318237 944.272766,1114.208984 
	C943.536194,1114.915527 941.979919,1115.144043 942.875671,1116.846313 
	C945.170776,1121.208008 939.953796,1121.895264 939.163635,1121.523071 
	C935.426575,1119.763184 932.217346,1122.124634 928.399048,1122.000000 
	C927.388855,1122.537109 926.712280,1122.939331 925.479431,1123.002075 
	C924.818909,1122.891113 924.562073,1122.805298 923.970215,1122.487427 
	C923.276001,1121.840454 923.000916,1121.375854 922.711670,1120.469360 
	C922.398987,1118.751709 921.804321,1117.516724 921.630371,1115.770752 
	C921.221436,1112.451660 921.015198,1109.529053 921.468872,1106.222656 
	C921.260376,1103.423706 921.649536,1100.956543 920.745667,1098.419800 
	C920.065613,1100.748535 920.318604,1103.533325 917.423218,1105.071777 
	C912.293091,1106.232910 907.505371,1106.076538 902.339233,1105.281982 
	C899.387451,1103.793213 899.104797,1101.288208 898.950989,1098.807861 
	C898.839478,1097.008423 899.502380,1095.318970 901.545227,1095.245117 
	C908.148254,1095.006348 911.233704,1091.265503 912.248108,1085.144531 
	C912.474121,1083.781006 914.157288,1082.730957 912.975586,1080.793457 
	C912.071289,1079.673584 911.865417,1078.339478 910.523499,1078.110840 
	C904.822693,1077.140015 901.933533,1072.608521 898.515991,1068.348389 
	C898.521729,1067.681641 898.648132,1067.394165 898.790405,1067.111450 
	C899.360840,1065.978027 901.726929,1065.499512 900.328369,1063.681885 
	C899.194946,1062.208862 897.706299,1063.178101 896.039368,1063.800293 
	C894.705383,1064.203125 893.722046,1064.181763 892.424561,1063.611694 
	C890.781433,1061.277222 892.084167,1059.512695 893.121094,1057.382324 
	C896.578064,1055.812622 900.811401,1057.909912 903.655640,1054.069458 
	C905.424622,1054.301270 906.849182,1054.602661 908.636902,1054.952026 
	C913.541931,1057.511353 914.846863,1057.128296 915.959045,1052.603882 
	C916.344055,1051.037720 916.863220,1049.832397 918.665588,1049.066895 
	C920.421387,1049.328125 921.842712,1049.656250 923.632080,1049.992188 
	C928.711792,1050.617188 929.491455,1051.828857 927.713867,1055.879761 
	C927.254028,1056.927612 926.845459,1058.027344 926.241150,1058.982056 
	C923.891663,1062.694214 924.878479,1065.440308 928.754395,1067.762085 
	C929.000000,1068.444458 929.000000,1068.888916 929.000000,1069.666748 
	C928.607239,1072.876099 928.587891,1073.106323 925.218933,1072.823242 
	C921.019531,1072.470337 919.779541,1074.174683 920.810791,1078.497192 
	C923.208618,1077.695923 924.725525,1075.944336 926.495667,1074.615356 
	C927.766418,1073.661377 928.843384,1071.776855 930.707397,1072.814331 
	C932.773438,1073.964233 930.939880,1075.790649 931.007507,1077.654785 
	C931.000000,1078.750000 931.000000,1079.500000 931.000000,1080.625000 
	C930.041443,1082.455322 928.486694,1083.625244 928.762451,1085.988037 
	C928.726501,1087.127197 928.910339,1087.881592 929.662842,1088.770508 
	C931.223511,1091.708130 932.809448,1094.179321 936.065063,1095.557861 
	C937.376770,1098.850464 934.573364,1102.370972 937.550171,1105.223022 
	C938.708069,1106.332397 939.482788,1108.479858 941.356567,1107.748291 
	C943.223572,1107.019165 941.910950,1105.136597 941.747131,1103.787109 
	C941.445190,1101.298584 941.334961,1098.817017 941.966248,1096.391602 
	C942.478027,1094.425537 941.830200,1093.206787 939.787842,1092.186523 
	C938.468811,1089.008667 940.295837,1087.688354 942.821533,1086.452637 
	C943.977173,1086.195679 944.788574,1086.320068 945.599182,1086.328369 
	C948.921570,1086.362305 950.535583,1084.993164 949.781616,1081.250244 
	C953.687195,1080.594238 955.066467,1086.188354 959.227905,1084.549683 
	C959.480591,1085.293823 959.963562,1086.043335 959.946350,1086.781128 
	C959.870483,1090.045166 960.000977,1093.028442 963.906738,1094.274170 
	C965.137573,1094.666626 964.492432,1095.939331 964.071167,1096.811768 
	C963.407104,1098.187134 962.703125,1099.535278 964.711731,1100.840332 
	C967.334534,1103.768311 965.138428,1105.929810 963.922913,1108.108887 
	C962.443054,1110.761963 962.021179,1112.072876 966.083618,1111.950195 
	C970.101624,1111.828735 973.105408,1110.849121 974.877136,1107.055664 
	C975.359863,1106.022217 976.144592,1105.130005 977.672241,1104.889526 
	C980.687134,1104.856689 982.421692,1106.690186 984.588074,1107.776733 
	C983.431946,1110.071777 981.318359,1109.633423 979.734802,1110.322144 
	C977.667603,1111.221191 976.696594,1112.927612 977.113281,1115.161011 
	C977.531311,1117.400635 979.823486,1118.577026 981.192688,1117.773682 
	C986.119568,1114.882690 989.463928,1117.929321 993.269165,1120.041138 
	C994.799988,1120.890747 996.726624,1121.027100 998.736572,1121.743896 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M1149.571533,1154.000000 
	C1153.224731,1158.729370 1159.207886,1160.630615 1163.944824,1158.454102 
	C1166.397217,1157.327271 1167.029907,1155.248779 1166.998291,1152.398315 
	C1169.350342,1151.776855 1171.555786,1152.508301 1173.824341,1152.925781 
	C1176.337280,1153.388306 1177.034546,1154.859253 1177.003540,1157.120483 
	C1176.973267,1159.336426 1177.066284,1161.523071 1174.083984,1161.993164 
	C1172.192627,1162.291382 1170.269897,1163.059937 1170.559692,1165.307739 
	C1170.796875,1167.148682 1172.771118,1166.806519 1174.216797,1167.026978 
	C1179.712280,1167.865356 1180.308472,1170.837646 1176.168335,1177.686523 
	C1173.006470,1181.525879 1171.231812,1181.242798 1169.445557,1177.169067 
	C1168.372192,1174.720947 1169.143555,1171.856934 1167.260254,1169.292480 
	C1165.285645,1169.000000 1163.571289,1169.000000 1161.428467,1169.000000 
	C1157.974365,1170.038452 1154.811035,1168.885498 1151.721558,1169.252441 
	C1151.517212,1171.661743 1153.771973,1171.364990 1154.765137,1172.745361 
	C1154.309204,1177.936523 1153.912231,1178.037842 1146.375610,1175.051880 
	C1143.708130,1172.487427 1140.364014,1171.278809 1138.075928,1168.333740 
	C1136.969238,1164.608765 1136.413330,1161.212158 1137.941406,1157.396118 
	C1139.409058,1155.229492 1141.796265,1155.588989 1143.752686,1154.235718 
	C1145.714355,1154.000000 1147.428711,1154.000000 1149.571533,1154.000000 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M1090.049194,1140.647339 
	C1087.221924,1141.161621 1085.011597,1140.711304 1084.936279,1137.159668 
	C1084.871582,1134.118164 1082.597046,1134.022827 1080.297974,1133.989746 
	C1077.781006,1133.953735 1075.965210,1134.698486 1076.028809,1137.578003 
	C1076.127808,1142.065552 1072.789185,1141.082031 1070.391846,1140.900879 
	C1068.460083,1140.755005 1064.751343,1142.750366 1065.443970,1137.869873 
	C1065.759399,1135.647095 1064.015625,1135.959473 1062.770996,1136.278442 
	C1060.050171,1136.975830 1057.458252,1138.081177 1058.427612,1141.652100 
	C1059.163696,1144.363770 1059.303101,1146.902954 1057.774658,1149.336060 
	C1056.172729,1151.886108 1054.344482,1152.863892 1051.783325,1150.316528 
	C1049.178345,1147.725464 1045.074829,1146.270264 1045.790283,1141.358154 
	C1045.954102,1140.234619 1044.969238,1139.066406 1043.466064,1139.579468 
	C1042.328247,1139.967651 1042.225098,1140.972290 1042.546387,1142.056519 
	C1043.204956,1144.278320 1044.020386,1146.426514 1044.046631,1148.838013 
	C1044.088257,1152.652466 1042.984375,1154.010132 1039.231445,1151.273071 
	C1041.396118,1146.755005 1038.551636,1142.114380 1039.998413,1137.398804 
	C1040.542114,1136.475464 1041.066772,1135.933228 1041.802490,1135.202393 
	C1045.987061,1132.952515 1046.865356,1127.655151 1051.656982,1126.045044 
	C1052.855103,1126.669067 1052.902344,1127.739258 1053.322021,1128.627197 
	C1055.700562,1133.661133 1057.837402,1134.050415 1062.421753,1130.989502 
	C1065.709351,1128.794189 1068.561646,1126.478638 1069.724609,1122.211670 
	C1072.931396,1124.957397 1072.927612,1129.005737 1069.723145,1130.828613 
	C1068.071045,1131.768555 1066.821777,1132.432739 1067.700317,1134.532593 
	C1068.180786,1135.681030 1067.729614,1137.407104 1069.463623,1137.786621 
	C1071.635620,1138.262329 1072.937622,1136.879761 1073.838257,1135.225098 
	C1076.208252,1130.871094 1079.632324,1130.069092 1083.882690,1132.129395 
	C1088.936157,1134.578979 1094.876221,1133.864502 1099.839355,1136.868408 
	C1101.737671,1138.017334 1104.505859,1137.848999 1104.850098,1140.831665 
	C1104.978760,1141.946655 1104.337402,1142.772461 1103.160156,1142.890625 
	C1100.452637,1143.161987 1097.909546,1143.217163 1096.235474,1140.319580 
	C1093.719238,1135.964111 1093.142212,1136.021118 1090.049194,1140.647339 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M1052.340088,1125.287842 
	C1053.019165,1121.121582 1053.910645,1117.502563 1050.739502,1113.813721 
	C1048.783813,1111.538940 1051.436890,1106.923584 1054.884644,1106.302246 
	C1058.768921,1105.602051 1062.741699,1105.365479 1066.685425,1105.041382 
	C1067.635620,1104.963379 1068.733765,1105.120239 1069.567627,1105.549561 
	C1070.925171,1106.248779 1071.343140,1108.697998 1070.734253,1108.848389 
	C1065.621704,1110.110474 1073.266113,1118.604858 1065.417603,1118.007202 
	C1063.711060,1117.877197 1061.969971,1118.138184 1062.684448,1115.344727 
	C1063.219849,1113.250610 1062.033813,1111.555420 1059.932251,1111.512817 
	C1058.064941,1111.474854 1056.796997,1113.320679 1057.148071,1114.969849 
	C1057.739502,1117.749023 1052.898560,1122.806641 1060.604858,1122.998291 
	C1062.167847,1126.201416 1059.752930,1126.568359 1057.702393,1126.904419 
	C1055.926880,1127.195190 1054.157593,1126.762939 1052.340088,1125.287842 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1166.996948,1169.392578 
	C1171.631714,1169.775757 1169.834229,1174.007446 1170.765747,1176.587036 
	C1171.869263,1179.643433 1172.786011,1180.544678 1175.702637,1178.156372 
	C1175.985229,1180.996948 1176.087769,1184.000854 1175.912354,1186.988281 
	C1175.802856,1188.850586 1175.231934,1190.677124 1172.874878,1190.920898 
	C1170.399048,1191.176880 1169.284912,1189.947632 1168.888672,1187.548584 
	C1168.508179,1185.245728 1167.619019,1183.610962 1164.898560,1182.551025 
	C1161.140137,1181.086426 1161.514893,1174.892456 1165.276123,1172.988281 
	C1166.824829,1172.204346 1166.956665,1171.136108 1166.996948,1169.392578 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M977.998596,1104.602539 
	C977.218994,1106.234863 976.344177,1107.421997 975.669922,1108.713745 
	C973.947205,1112.014282 971.906128,1114.528687 967.585754,1113.144409 
	C965.215820,1112.385010 961.760010,1116.257690 960.454590,1112.249878 
	C959.699951,1109.933228 962.578857,1107.667847 963.779053,1105.369507 
	C964.401917,1104.176636 965.054138,1103.131958 964.997559,1101.382690 
	C968.413757,1100.812134 971.675476,1102.745605 975.271729,1101.268433 
	C977.442505,1100.376709 978.015625,1102.334106 977.998596,1104.602539 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1052.000000,1125.250000 
	C1054.857910,1123.912231 1058.448853,1127.767456 1060.931396,1123.342773 
	C1061.750000,1123.000000 1062.500000,1123.000000 1063.625000,1123.000000 
	C1065.576782,1121.792969 1067.199951,1120.751953 1069.663330,1121.753906 
	C1070.849854,1126.393066 1068.915649,1129.208496 1065.014282,1131.004150 
	C1063.991089,1131.475220 1062.932373,1132.102539 1062.205322,1132.933960 
	C1060.791870,1134.550415 1059.527588,1136.934570 1057.106445,1134.932495 
	C1054.559204,1132.826050 1050.249268,1131.541016 1051.959229,1126.341797 
	C1052.000000,1126.000000 1052.000000,1125.500000 1052.000000,1125.250000 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1216.576904,1217.997437 
	C1216.645020,1219.779907 1216.341797,1221.572266 1215.921875,1223.336670 
	C1215.412842,1225.474854 1214.797974,1227.659790 1212.108276,1227.922363 
	C1209.608643,1228.166382 1207.078247,1228.294922 1205.450806,1225.720581 
	C1204.042480,1223.492798 1203.476929,1221.131958 1204.447510,1218.626221 
	C1205.013916,1217.164185 1206.461914,1217.174072 1207.805420,1216.987793 
	C1210.693848,1216.587280 1213.340454,1217.936523 1216.576904,1217.997437 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M1104.666260,1122.241211 
	C1108.316406,1121.458130 1110.888428,1123.899292 1113.994873,1124.284546 
	C1114.629150,1124.363159 1115.330322,1125.214600 1115.693115,1125.878052 
	C1116.330444,1127.043213 1111.469727,1136.535156 1110.116333,1136.697144 
	C1107.134644,1137.053955 1106.817749,1134.582153 1106.131104,1132.614136 
	C1105.516235,1130.851807 1106.917969,1128.565063 1104.367432,1127.098999 
	C1102.774658,1125.395752 1103.299316,1123.920410 1104.666260,1122.241211 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1137.644287,1168.049561 
	C1140.641479,1170.111816 1145.287231,1169.668579 1145.990967,1174.630859 
	C1141.032837,1180.820312 1140.272705,1180.897095 1135.945801,1174.795532 
	C1134.521606,1172.787231 1132.373535,1173.441528 1130.277710,1172.197754 
	C1132.778809,1170.735474 1135.033691,1169.417236 1137.644287,1168.049561 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M1141.603638,1128.988159 
	C1142.000000,1129.444458 1142.000000,1129.888916 1142.000000,1130.666748 
	C1143.063477,1132.310059 1143.150635,1133.739502 1142.260498,1135.628418 
	C1139.637695,1141.436890 1135.964355,1138.896606 1132.244629,1137.281494 
	C1132.209473,1136.205688 1132.443481,1135.417114 1132.624390,1134.616333 
	C1134.253052,1127.403198 1134.279053,1127.383667 1141.603638,1128.988159 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1175.842529,1236.404053 
	C1175.657715,1238.464600 1178.503540,1241.105713 1175.246826,1241.700806 
	C1172.391602,1242.222412 1168.571899,1243.538574 1166.503296,1240.305298 
	C1164.861450,1237.738770 1167.282593,1235.359497 1169.047729,1233.908691 
	C1171.849121,1231.606323 1173.843140,1234.178467 1175.842529,1236.404053 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M1128.841553,1136.701172 
	C1128.042847,1140.138062 1126.620239,1142.555786 1122.341553,1141.041016 
	C1122.037598,1139.637085 1121.893311,1138.298706 1120.794556,1137.349487 
	C1118.368652,1135.253784 1119.568481,1133.666870 1121.432861,1132.113525 
	C1123.585327,1130.320190 1124.961914,1131.246582 1126.204590,1133.305420 
	C1126.881958,1134.427612 1127.847534,1135.375732 1128.841553,1136.701172 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1104.798340,1121.744873 
	C1104.698730,1123.424561 1104.397461,1124.849121 1104.048096,1126.636841 
	C1099.656860,1125.903442 1095.780396,1124.351562 1096.000000,1118.401123 
	C1097.738525,1117.041992 1099.505249,1116.608765 1101.677246,1117.856079 
	C1101.975464,1119.825439 1103.343628,1120.614746 1104.798340,1121.744873 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1155.236450,1172.753174 
	C1153.842163,1172.962524 1152.669556,1173.027710 1151.529907,1172.864014 
	C1149.752930,1172.608765 1148.954346,1171.396973 1149.130981,1169.686523 
	C1149.344849,1167.614746 1150.748901,1166.884033 1152.655640,1167.155884 
	C1155.253662,1167.526367 1157.833374,1168.025757 1160.710449,1168.734863 
	C1160.010498,1171.513062 1157.628418,1171.831299 1155.236450,1172.753174 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M949.583374,1081.000000 
	C949.778503,1083.486694 953.249451,1084.336670 952.609863,1087.435669 
	C950.791565,1086.962891 948.843262,1085.910645 947.257690,1088.059448 
	C946.261658,1089.409302 945.270386,1088.264160 944.250305,1087.186523 
	C942.295166,1085.421631 942.404419,1083.637451 943.703552,1081.376099 
	C944.002441,1081.000000 944.501221,1081.000000 944.750610,1081.000000 
	C946.388916,1081.000000 947.777771,1081.000000 949.583374,1081.000000 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1041.255981,1073.255371 
	C1041.339478,1071.433350 1038.628662,1068.698364 1041.357788,1068.501099 
	C1044.540527,1068.270996 1047.744751,1070.056030 1047.998047,1074.619629 
	C1045.837280,1074.503540 1043.674683,1074.007080 1041.255981,1073.255371 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1084.377441,1125.990479 
	C1083.900879,1125.183228 1083.923218,1124.327515 1083.677124,1123.557739 
	C1083.165161,1121.956055 1082.440063,1120.263916 1083.897217,1118.929077 
	C1085.549927,1117.415039 1086.907349,1119.220703 1088.685059,1119.855225 
	C1089.150513,1123.104736 1088.457886,1125.611084 1084.377441,1125.990479 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1143.602417,1154.000000 
	C1143.327881,1156.869263 1141.015625,1156.876343 1138.384521,1156.998779 
	C1138.378540,1153.646606 1140.915161,1154.037476 1143.602417,1154.000000 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M1069.927124,1121.663086 
	C1068.172607,1121.835693 1066.448120,1122.274170 1064.377319,1122.947510 
	C1065.344238,1120.318359 1067.158203,1119.279663 1069.927124,1121.663086 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M1186.467285,1160.260986 
	C1185.461182,1160.517090 1185.146973,1160.016602 1185.274170,1159.361084 
	C1185.333496,1159.056030 1185.904785,1158.570923 1186.124512,1158.628052 
	C1186.838013,1158.813354 1186.857422,1159.440552 1186.467285,1160.260986 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1041.687500,1135.053955 
	C1041.893188,1135.886108 1041.609619,1136.643555 1040.312500,1136.945801 
	C1040.106445,1136.113647 1040.390381,1135.356323 1041.687500,1135.053955 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M903.932739,1053.658691 
	C900.883911,1059.733154 900.168945,1059.972290 894.360718,1057.052002 
	C891.273193,1059.087402 889.427979,1062.146484 886.256653,1063.780029 
	C884.006897,1064.938721 884.902466,1067.674683 883.645325,1069.779297 
	C883.261719,1070.234985 883.075195,1070.387085 882.553833,1070.671753 
	C880.565308,1071.111572 879.333862,1070.348755 878.147705,1069.101807 
	C877.870361,1072.869141 875.940186,1074.808228 872.269653,1073.891113 
	C868.859863,1073.039185 865.431152,1072.759888 861.968262,1072.402954 
	C857.108948,1071.902222 855.329956,1068.077515 857.700134,1063.756226 
	C860.233276,1059.137817 858.132446,1055.763672 852.692261,1055.759521 
	C851.861755,1055.758911 850.929504,1055.997559 850.244263,1055.576538 
	C847.315186,1053.777222 845.399170,1054.295898 843.286987,1057.230591 
	C841.245544,1060.066895 838.378235,1059.139893 837.708313,1055.590454 
	C837.096741,1052.350098 836.411865,1049.061401 836.640259,1045.723389 
	C836.803711,1043.334473 836.294556,1041.772827 833.434204,1042.185425 
	C831.426392,1042.474976 829.575317,1041.496704 827.332642,1041.059082 
	C827.220947,1045.711426 825.830444,1049.154907 820.273071,1049.185303 
	C818.908569,1048.975098 818.026184,1048.540527 816.963806,1047.670654 
	C815.000977,1044.373535 817.412659,1042.893921 819.147339,1040.804932 
	C816.550415,1043.167114 814.371582,1042.991333 812.539612,1040.160645 
	C811.905823,1039.181274 811.097107,1038.260132 809.983459,1037.857422 
	C800.576904,1034.455566 802.893555,1023.050354 810.680847,1018.578491 
	C808.328796,1018.397583 808.328796,1018.397583 806.212280,1017.139526 
	C806.152344,1018.069824 806.549194,1018.922363 806.469727,1019.823425 
	C806.294617,1021.810059 806.409729,1024.137695 803.911011,1024.638550 
	C801.235962,1025.174805 800.143372,1023.095703 799.323303,1020.998413 
	C798.233704,1018.211609 798.881042,1013.681396 794.867920,1013.581238 
	C791.814880,1013.505005 790.313293,1017.490479 787.983337,1019.515503 
	C786.438904,1020.857788 786.192566,1022.761658 789.145630,1023.835449 
	C789.689880,1024.110474 789.897644,1024.243286 790.374084,1024.634766 
	C791.577881,1026.138916 792.003113,1027.573730 791.943787,1029.095703 
	C791.865295,1031.110474 791.712524,1033.418213 789.592163,1034.209595 
	C787.205750,1035.100098 786.001282,1033.033081 785.054871,1031.280396 
	C782.734314,1026.982910 779.330139,1025.798950 774.635620,1026.858765 
	C770.502014,1027.791992 769.213501,1026.652100 768.890930,1022.390686 
	C768.678406,1019.582214 768.343262,1016.780640 768.438354,1013.944458 
	C768.655762,1007.461426 766.970764,1006.485657 760.478394,1009.603394 
	C758.982178,1010.323425 757.756409,1010.562561 756.111938,1010.519897 
	C752.686523,1011.381348 751.528687,1014.447449 749.290344,1016.288391 
	C746.322144,1018.729614 743.425720,1020.191772 739.286377,1019.980225 
	C738.450439,1020.088623 737.969482,1020.086060 737.133118,1019.989502 
	C734.510925,1019.248718 732.802612,1018.035767 733.044189,1015.373962 
	C733.259338,1013.004211 734.786621,1011.489258 736.774414,1010.393372 
	C737.814697,1009.819702 738.968262,1009.649658 740.137573,1009.534485 
	C745.327637,1009.023376 745.327026,1009.017273 743.668091,1003.353821 
	C741.825562,1006.437439 739.382446,1006.126404 736.717346,1005.164246 
	C730.905090,1003.065979 730.312256,1001.489990 733.556335,996.102905 
	C734.763062,994.099121 735.898682,992.080750 736.458740,989.775269 
	C736.814575,988.310913 737.229736,986.749756 738.989563,986.388489 
	C740.767944,986.023376 741.837646,987.172119 742.503601,988.588440 
	C743.802551,991.351135 745.649353,991.754883 748.117615,990.156311 
	C750.797913,988.420532 753.456360,988.679993 756.130432,990.211670 
	C758.288086,991.447571 760.594666,991.275269 762.939331,990.874329 
	C765.591431,990.420898 767.270020,989.910156 765.674255,986.416260 
	C763.955627,982.653381 765.218018,982.122559 769.276611,981.208496 
	C774.605286,980.008362 781.199951,980.299011 783.339417,973.182739 
	C783.574463,972.401062 784.384766,971.406067 785.062134,971.525513 
	C790.355957,972.458740 793.984497,967.112427 799.163818,967.884644 
	C800.840271,968.134583 802.071045,967.708435 802.971191,966.226746 
	C803.851685,964.777344 804.985291,963.570801 807.045776,963.017822 
	C808.757568,962.751099 810.159607,963.325073 811.847107,962.640015 
	C819.976318,958.251282 827.402954,953.361328 835.170105,949.052063 
	C836.192871,948.484619 837.199280,947.857544 838.374573,947.739685 
	C843.697021,947.206055 848.760742,945.941895 853.419006,943.149353 
	C856.021667,941.588989 858.212158,942.038452 859.111206,945.635864 
	C855.491638,946.658081 852.178345,946.793396 850.399414,951.258118 
	C849.118835,954.472107 844.426697,954.343567 841.268555,955.571594 
	C838.817932,956.524475 836.783386,957.351624 835.491638,959.746216 
	C834.582947,961.430664 832.873291,962.527100 831.071533,962.738403 
	C828.158020,963.080017 826.981689,965.023865 826.583130,967.335815 
	C826.090576,970.192932 829.909790,967.926941 830.373840,970.179504 
	C827.838745,972.740601 822.723999,971.345703 821.322327,975.177734 
	C820.104492,978.507202 821.292603,982.301941 819.770386,985.903442 
	C818.411499,989.118347 821.742004,990.283936 824.005249,990.677124 
	C827.694580,991.318115 831.517273,991.360596 835.282349,991.344177 
	C838.503479,991.330139 841.125671,993.767334 840.923950,996.707275 
	C840.828125,998.104553 837.752319,999.568970 840.511658,1000.732422 
	C842.476440,1001.560852 844.808838,1002.852722 847.158691,1001.233337 
	C851.344788,998.348511 852.719849,1001.084656 853.691345,1004.551025 
	C853.962830,1005.519836 854.262024,1006.690491 853.320068,1007.303589 
	C852.273193,1007.985046 851.559692,1006.890015 850.795654,1006.297607 
	C849.698303,1005.446594 848.856323,1003.534363 847.099243,1004.755127 
	C845.249939,1006.040039 846.351624,1007.833313 846.974121,1009.275269 
	C849.757263,1015.721985 854.929260,1019.685669 861.330383,1021.909546 
	C865.606384,1023.395142 869.891968,1024.653687 874.212769,1020.778137 
	C876.533569,1018.696533 876.964294,1021.574707 876.898010,1023.473999 
	C876.846497,1024.952393 875.230408,1027.065674 877.901184,1027.480957 
	C879.807312,1027.777222 880.549072,1025.855713 881.077942,1024.220947 
	C881.572937,1022.690735 882.085876,1021.166443 882.611816,1019.576599 
	C883.131226,1019.868469 883.606873,1019.985291 883.637695,1020.174683 
	C884.818359,1027.423340 884.699707,1027.259399 890.779175,1023.083008 
	C893.689209,1021.083923 896.998962,1021.342468 900.036926,1023.510986 
	C902.376526,1025.181030 904.417725,1027.839600 907.936035,1026.367676 
	C908.189819,1026.261353 908.578613,1026.446167 908.895996,1026.528931 
	C912.958679,1027.587280 914.209839,1032.151855 911.866150,1038.918945 
	C910.717224,1042.236206 907.751465,1041.440796 905.245483,1040.764771 
	C902.857361,1040.120605 900.420471,1038.735840 898.902405,1042.010254 
	C897.423218,1045.200928 897.683289,1048.171875 900.439941,1050.608154 
	C901.527832,1051.569580 903.281616,1051.701782 903.932739,1053.658691 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M859.221985,946.244263 
	C857.675781,943.570984 856.249756,942.580994 853.514282,944.595764 
	C850.594910,946.745850 847.207214,947.821228 843.477966,948.485474 
	C834.646362,950.058411 827.039612,954.536316 820.253357,960.302368 
	C818.000793,962.216187 814.919678,962.061157 812.363403,963.949707 
	C808.228516,960.821960 809.753296,958.023010 812.915283,955.060181 
	C813.630188,954.512573 814.092407,954.304932 814.955933,954.115662 
	C816.277222,953.976685 817.249329,954.057739 818.543091,953.683899 
	C824.367554,953.053284 828.548218,949.403564 833.479004,947.533691 
	C841.348083,944.549561 849.518616,942.340820 856.962158,938.216980 
	C860.026245,936.519348 863.905823,937.951233 867.476013,936.164978 
	C868.573181,935.737732 869.341736,935.598206 870.514343,935.538696 
	C874.627258,936.098206 877.793640,935.089355 881.089539,933.121521 
	C884.742615,930.940430 889.312683,930.975525 893.450745,930.066223 
	C897.771484,929.116699 902.136963,928.671448 906.082214,926.436096 
	C907.087769,925.866333 908.315857,925.915649 909.708130,926.749023 
	C909.661560,930.151917 907.863159,931.894653 904.819641,932.595276 
	C902.890076,933.039490 900.961975,933.652832 901.010254,936.621948 
	C892.076050,936.834717 883.414490,938.122986 874.889954,940.914795 
	C871.749207,941.943359 868.183105,941.673645 864.405518,941.998047 
	C862.168274,943.106262 863.611023,945.270447 862.254822,946.806335 
	C861.148010,946.829529 860.295959,946.659058 859.221985,946.244263 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M917.706665,1022.399536 
	C922.741211,1023.175781 925.688477,1026.878662 929.471497,1029.093994 
	C931.021118,1030.001343 932.314941,1031.398438 934.140076,1031.835938 
	C937.756287,1032.702759 941.024231,1034.759399 943.021423,1037.068726 
	C944.203613,1038.435791 943.852173,1039.429077 942.883667,1040.537842 
	C941.866272,1041.702637 940.299377,1042.328857 939.537842,1040.871704 
	C936.027832,1034.156372 930.553101,1034.773315 924.366760,1035.840820 
	C920.759460,1036.463379 917.280212,1034.842651 917.028076,1030.289795 
	C916.829956,1026.713257 913.428284,1026.881470 911.234558,1024.944580 
	C913.092041,1023.325195 914.835693,1022.046997 917.706665,1022.399536 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M943.651855,1080.951294 
	C944.001587,1082.714355 944.000793,1084.428711 944.000000,1086.571533 
	C942.476685,1088.252319 940.139587,1088.751465 940.009277,1091.650879 
	C941.464966,1095.307251 940.245789,1096.699219 936.395813,1096.248291 
	C931.225342,1096.633789 930.023743,1093.333862 929.048889,1089.365234 
	C928.578613,1088.591431 928.140808,1088.194580 927.998474,1087.294434 
	C927.256226,1084.454224 925.029846,1081.360352 930.607666,1081.002930 
	C932.264099,1081.650757 933.467041,1082.072754 934.328369,1080.394409 
	C936.805298,1075.568726 939.352966,1076.840942 941.976135,1080.287476 
	C942.237061,1080.630127 942.851196,1080.703979 943.651855,1080.951294 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M931.269104,1077.773682 
	C929.048706,1076.858643 931.257080,1074.900757 929.879944,1073.167725 
	C928.226196,1076.650513 923.986694,1077.171509 922.459534,1080.690674 
	C922.137634,1081.432617 921.211365,1081.970947 920.423035,1081.213745 
	C918.110718,1078.992432 918.456299,1076.308105 918.955383,1073.439087 
	C919.420105,1070.767700 921.250305,1071.306030 922.983093,1070.941162 
	C924.958557,1070.525391 927.018799,1073.339478 928.916138,1070.353394 
	C933.609863,1071.250000 933.846680,1073.970581 931.269104,1077.773682 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M928.953247,1067.652466 
	C921.880920,1066.417969 920.912415,1063.391724 926.123291,1055.671265 
	C928.349792,1052.372559 927.413757,1051.447876 924.225098,1050.246826 
	C925.529724,1046.983521 927.112183,1048.921143 928.800049,1049.870850 
	C931.177185,1051.208618 931.591248,1053.303711 930.535278,1055.392334 
	C929.620789,1057.200928 929.410828,1059.281006 928.399170,1060.896973 
	C926.863525,1063.349976 927.709900,1065.256836 928.953247,1067.652466 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M867.646423,992.302673 
	C869.192139,993.183899 871.459412,992.878296 871.369385,994.797119 
	C871.282776,996.641602 869.252625,995.866455 868.088013,996.117615 
	C866.390564,996.483887 864.311096,998.856445 863.150696,997.108887 
	C861.303467,994.326904 860.409729,990.885437 859.296692,987.659424 
	C858.990051,986.770569 859.935608,986.187500 860.830566,986.101562 
	C862.210510,985.969116 863.777283,986.020142 863.847656,987.812744 
	C863.942627,990.235596 865.325195,991.359741 867.646423,992.302673 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M955.000000,1064.564331 
	C953.672974,1064.975586 952.340149,1065.019653 951.020508,1064.908081 
	C949.607056,1064.788208 948.513550,1063.513550 949.290955,1062.584717 
	C952.271118,1059.024292 952.323486,1056.961914 947.064392,1056.621582 
	C946.886414,1056.610107 946.510010,1055.673828 946.656799,1055.446045 
	C946.985535,1054.935669 947.523926,1054.416992 948.086975,1054.225586 
	C950.646851,1053.355591 954.572815,1056.141479 954.932434,1059.153931 
	C955.127930,1060.791504 954.988403,1062.469116 955.000000,1064.564331 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M918.767761,1048.771729 
	C918.085815,1050.538208 916.932800,1051.900635 916.909668,1053.907593 
	C916.888123,1055.765015 916.287109,1057.632568 914.009094,1057.893677 
	C912.041504,1058.119141 909.930969,1058.313599 909.061401,1055.372070 
	C912.363037,1055.251831 915.149231,1054.958008 915.242432,1050.442017 
	C915.273132,1048.951782 916.803345,1048.099854 918.767761,1048.771729 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M889.497070,1008.762329 
	C888.393921,1012.012512 885.672241,1012.711548 883.250610,1013.699463 
	C882.051270,1014.188843 880.907776,1013.635010 880.400696,1012.375488 
	C879.807312,1010.901550 880.762451,1009.869202 881.983765,1009.962219 
	C884.717773,1010.170593 886.406250,1005.493530 889.497070,1008.762329 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M865.873169,1014.448303 
	C867.158203,1014.174683 868.141663,1014.024170 869.114197,1014.076538 
	C870.573181,1014.155151 871.684265,1014.868896 871.789734,1016.429688 
	C871.869873,1017.615417 871.369385,1018.754944 870.376709,1019.432007 
	C868.939453,1020.412354 867.898315,1019.363037 866.975830,1018.429382 
	C865.965759,1017.407288 864.622803,1016.442566 865.873169,1014.448303 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M937.598633,1049.684692 
	C936.113281,1048.924072 934.518677,1048.369019 935.562927,1046.694214 
	C936.418884,1045.321289 937.872986,1044.950806 939.456421,1045.760742 
	C940.661011,1046.376831 942.089478,1047.071045 941.692932,1048.601685 
	C941.177063,1050.592773 939.370483,1049.887817 937.598633,1049.684692 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M867.691833,981.561890 
	C867.326660,980.448914 866.779907,979.513123 867.519287,978.926575 
	C868.607544,978.063110 868.757935,979.267212 869.098694,979.923157 
	C869.703857,981.088074 870.273132,982.274414 870.936035,983.405762 
	C871.522339,984.406311 872.371765,985.393127 871.223633,986.457947 
	C870.437195,987.187317 869.351562,987.117859 868.415100,986.784058 
	C866.696838,986.171753 867.782288,984.902283 867.836792,983.868713 
	C867.871094,983.216919 867.818970,982.560547 867.691833,981.561890 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M862.047485,947.337158 
	C862.691223,945.247375 860.408875,942.661194 863.758728,941.793091 
	C864.293823,943.779114 864.630554,945.552673 864.861755,947.339905 
	C864.963318,948.124817 865.099548,949.161316 864.051025,949.398010 
	C862.782227,949.684387 862.417297,948.631714 862.047485,947.337158 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M848.290649,958.092163 
	C849.192322,960.189575 849.199768,961.722473 846.746338,961.919983 
	C845.570312,962.014709 844.056946,961.890625 844.323792,960.292725 
	C844.633545,958.437561 845.574951,956.764954 848.290649,958.092163 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M904.999634,1046.918579 
	C903.291321,1046.612915 902.012390,1046.149170 902.603638,1044.391724 
	C902.947937,1043.368530 903.969177,1043.097778 904.991089,1043.095703 
	C906.013062,1043.093628 907.036560,1043.361694 907.384216,1044.381836 
	C907.981018,1046.133057 906.710022,1046.608643 904.999634,1046.918579 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M906.218262,1022.700806 
	C904.903076,1021.399780 902.251526,1020.867920 903.848633,1018.532837 
	C904.418884,1017.699158 905.775635,1017.878906 906.547852,1018.526184 
	C908.116211,1019.841003 908.823242,1021.337769 906.218262,1022.700806 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M965.225220,1054.080811 
	C966.318420,1051.983643 967.710144,1051.484253 969.200500,1053.022827 
	C970.244446,1054.100586 969.406250,1055.191772 968.481201,1055.904175 
	C966.557983,1057.384888 965.562927,1056.495483 965.225220,1054.080811 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M955.277283,1043.280518 
	C958.902405,1040.994995 957.757629,1044.697510 958.669128,1045.814819 
	C959.124512,1046.372925 958.753967,1047.680176 957.773071,1047.615356 
	C955.314636,1047.453247 955.832031,1045.115112 955.277283,1043.280518 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M830.110352,978.886841 
	C830.105286,982.884705 826.928650,979.975159 825.696716,981.516479 
	C826.142212,978.895264 827.648926,978.163208 830.110352,978.886841 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M946.634033,1033.164307 
	C946.164612,1035.237061 945.007874,1035.202515 943.739319,1034.407104 
	C943.109741,1034.012329 943.120605,1033.216309 943.593811,1032.737427 
	C944.543030,1031.776978 945.585876,1031.907959 946.634033,1033.164307 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M865.670044,1001.486938 
	C866.626465,1001.984741 867.781921,1002.276367 867.270325,1003.242554 
	C866.739868,1004.244568 865.529663,1004.164307 864.768005,1003.464355 
	C863.844666,1002.615906 864.027588,1001.768860 865.670044,1001.486938 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M852.179077,961.678894 
	C851.063477,960.743713 850.911438,959.908386 852.137634,959.375122 
	C852.458862,959.235474 853.182861,959.425476 853.381042,959.705444 
	C854.130615,960.764282 853.670715,961.449280 852.179077,961.678894 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M920.178955,1045.679077 
	C919.063965,1044.743774 918.910767,1043.908081 920.137634,1043.375000 
	C920.458984,1043.235352 921.183167,1043.425659 921.381287,1043.705566 
	C922.130615,1044.764526 921.670776,1045.449585 920.178955,1045.679077 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M913.742432,1045.711914 
	C912.683105,1047.027588 911.980652,1046.503052 911.554932,1045.479126 
	C911.441162,1045.205322 911.771240,1044.466309 912.054871,1044.359741 
	C912.955933,1044.021484 913.563965,1044.470825 913.742432,1045.711914 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M872.897583,1006.299866 
	C873.855225,1007.024780 874.082153,1007.795776 873.281982,1008.382629 
	C872.711670,1008.800720 872.006958,1008.414612 871.689514,1007.830994 
	C871.186035,1006.905273 871.612671,1006.363525 872.897583,1006.299866 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M852.008545,993.518188 
	C851.037720,992.750916 851.028992,991.994446 851.809082,991.435364 
	C852.364319,991.037476 853.169128,991.124084 853.430847,991.794800 
	C853.791077,992.717651 853.123474,993.250000 852.008545,993.518188 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M832.446106,973.615479 
	C831.707092,974.510315 830.991394,974.968140 830.371338,974.026794 
	C829.995850,973.456848 830.232971,972.692261 830.872986,972.400269 
	C831.726257,972.011047 832.422424,972.258789 832.446106,973.615479 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M855.257202,1013.218384 
	C855.851196,1012.349792 856.398254,1012.406799 856.671570,1013.108276 
	C856.796021,1013.427246 856.621338,1014.135986 856.365906,1014.277466 
	C855.659607,1014.668823 855.317383,1014.166260 855.257202,1013.218384 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M858.467224,994.260864 
	C857.460693,994.517456 857.147339,994.016235 857.274231,993.360962 
	C857.333374,993.055969 857.904724,992.570923 858.124329,992.627930 
	C858.838013,992.813232 858.856995,993.440491 858.467224,994.260864 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M980.267212,1063.958008 
	C980.289795,1063.518433 980.579651,1063.036865 980.869507,1062.555298 
	C981.064026,1062.721191 981.258545,1062.886963 981.453003,1063.052856 
	C981.146851,1063.340576 980.840637,1063.628174 980.267212,1063.958008 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1163.536133,1114.742798 
	C1163.099487,1116.127075 1163.536499,1117.027100 1163.970459,1117.930420 
	C1164.855591,1119.773438 1165.846436,1121.861816 1163.800781,1123.331543 
	C1161.872925,1124.716553 1159.904907,1126.179077 1157.008545,1125.554077 
	C1151.840820,1124.438721 1149.589111,1119.786011 1145.861572,1116.954834 
	C1144.660889,1116.042969 1145.572266,1114.671143 1146.831665,1114.338257 
	C1148.568726,1113.879150 1150.864746,1112.972168 1152.104980,1113.682373 
	C1155.634521,1115.703491 1158.638428,1115.217651 1161.861206,1113.326294 
	C1162.664673,1112.854736 1163.442627,1113.355957 1163.536133,1114.742798 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1160.308105,1105.899658 
	C1160.885620,1102.736084 1163.270752,1101.632080 1165.589111,1101.364380 
	C1167.389648,1101.156494 1167.675781,1103.578735 1168.420410,1104.970459 
	C1168.971313,1106.000610 1169.297119,1107.197388 1168.455933,1108.208252 
	C1167.493286,1109.364990 1166.360474,1108.498047 1165.285278,1108.256104 
	C1163.542480,1107.864014 1161.665894,1107.820557 1160.308105,1105.899658 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1168.351318,1119.766357 
	C1166.588745,1117.992432 1166.655518,1116.661743 1168.965210,1116.134155 
	C1170.072021,1115.881226 1171.431763,1115.955444 1171.713989,1117.408936 
	C1172.192993,1119.876099 1170.494995,1119.994263 1168.351318,1119.766357 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1168.299072,1130.285400 
	C1169.678345,1129.197388 1170.666382,1129.112305 1170.855591,1130.820068 
	C1171.005127,1132.167480 1170.191895,1132.999146 1168.838623,1132.848633 
	C1167.135498,1132.659058 1167.219482,1131.666382 1168.299072,1130.285400 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1142.349121,1135.916016 
	C1142.000000,1134.611084 1142.000000,1133.222168 1142.000000,1131.416626 
	C1144.854492,1132.231689 1144.715576,1133.896118 1142.349121,1135.916016 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1170.615234,1122.553955 
	C1171.510864,1123.292725 1171.967773,1124.008423 1171.026733,1124.628418 
	C1170.457153,1125.003784 1169.692383,1124.766968 1169.400269,1124.127075 
	C1169.010620,1123.273804 1169.259033,1122.577881 1170.615234,1122.553955 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1163.955811,1130.534180 
	C1163.305664,1130.175659 1163.296265,1129.816040 1163.763306,1129.499023 
	C1163.860962,1129.432739 1164.086914,1129.435425 1164.181641,1129.504761 
	C1164.635254,1129.836670 1164.618408,1130.196167 1163.955811,1130.534180 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M871.254822,936.753601 
	C870.248169,936.998474 869.498779,936.999390 868.374695,937.001343 
	C860.644592,935.286865 854.460388,937.094971 849.050720,942.531128 
	C846.998962,944.592957 844.416687,944.034180 843.141357,940.519714 
	C843.805481,936.413086 846.901550,935.474426 849.854614,934.398804 
	C852.024170,933.608521 854.370300,933.074585 855.105347,929.964844 
	C856.097412,928.580811 857.149597,927.862000 858.703796,927.210693 
	C860.061340,926.860901 861.072815,926.893372 861.997559,927.289124 
	C869.071167,930.315857 875.129272,927.928650 881.416748,924.456116 
	C885.859558,922.002380 890.612427,919.490479 896.336426,921.122314 
	C899.941345,922.150024 903.313354,919.888489 907.040039,918.709717 
	C907.740234,918.260864 908.164917,918.054871 908.933716,917.775391 
	C911.134277,917.447388 912.506042,916.413818 913.785217,914.671997 
	C914.394531,913.536377 914.851929,912.707397 915.691345,911.679932 
	C916.183594,911.281433 916.404785,911.149109 916.994141,910.884827 
	C918.144409,910.592834 918.935791,910.542175 920.138306,910.551147 
	C929.824158,908.649902 939.365662,911.554810 948.616455,909.028870 
	C949.248840,908.856140 949.913879,909.105408 950.885742,909.575073 
	C951.754211,910.593567 951.814331,911.415771 951.240601,912.636108 
	C950.502136,913.254395 950.007080,913.513794 949.256897,913.887817 
	C945.118225,915.676086 941.021301,916.358765 936.864746,915.892273 
	C933.027283,915.461609 929.714539,916.476746 926.276672,918.720337 
	C925.996521,918.992676 926.000000,919.000000 925.996338,918.997559 
	C925.310181,919.576721 924.541138,919.742310 923.337158,919.244019 
	C922.549988,919.007385 922.107849,919.002563 921.333984,918.993652 
	C919.019043,918.557068 917.326050,919.446594 915.924255,920.552002 
	C911.704529,923.879211 906.410950,925.235168 902.068481,928.324646 
	C900.893311,929.160706 899.576904,930.070740 897.924744,929.626831 
	C891.326111,927.853943 885.512146,931.017944 879.600952,932.883545 
	C876.871216,933.745056 874.204956,935.276978 871.254822,936.753601 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M951.349915,912.952759 
	C950.999146,912.249084 951.001038,911.503113 951.003906,910.384033 
	C952.897278,906.131592 957.833069,905.306274 959.841370,901.070129 
	C960.266724,900.377808 960.536438,899.998596 961.033325,899.349548 
	C962.311951,898.091064 962.650818,896.591125 964.020447,895.401001 
	C968.353760,893.606079 972.141968,894.749817 975.975281,896.097168 
	C980.527893,897.697327 984.982239,898.078857 988.837524,893.716003 
	C989.151733,893.182434 989.303345,892.985657 989.742065,892.553406 
	C995.349365,889.932678 1000.803528,890.827454 1006.210938,891.802734 
	C1007.997498,892.124939 1009.492065,892.440979 1010.543457,890.137085 
	C1010.785400,889.611816 1010.894531,889.408813 1011.209351,888.931458 
	C1012.678833,887.473572 1014.404114,886.693787 1014.965576,884.457397 
	C1015.461121,883.534912 1015.902405,882.994995 1016.681396,882.276978 
	C1018.589966,881.093933 1020.422363,880.464722 1022.125977,881.377563 
	C1026.205322,883.563660 1030.709351,884.166382 1035.042480,885.609680 
	C1031.580322,891.381348 1030.145630,891.795776 1024.183228,889.235779 
	C1020.521606,887.663696 1017.193726,886.918213 1015.445068,891.983704 
	C1014.274170,895.375671 1010.833374,896.287720 1007.916809,896.836304 
	C1003.625183,897.643433 999.468689,899.213745 994.799683,898.545776 
	C992.001892,898.145569 989.011108,899.945618 987.083435,902.451904 
	C984.926208,905.256775 981.528625,906.056091 979.048706,904.785034 
	C969.453430,899.867249 962.758789,905.027344 956.003052,910.526001 
	C954.692810,911.592468 953.335754,912.474609 951.349915,912.952759 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M1035.328125,885.967651 
	C1030.436279,887.088806 1026.448242,885.368347 1022.771667,883.087036 
	C1020.893738,881.921631 1019.399048,882.361267 1017.319153,882.921326 
	C1015.536621,879.178284 1019.003601,877.703430 1020.703125,874.976074 
	C1023.202515,872.725708 1021.437256,870.706787 1020.984619,868.356079 
	C1023.740723,868.548828 1023.793579,870.823486 1024.073364,872.965332 
	C1024.549194,876.609192 1025.997925,880.562073 1029.439575,881.194641 
	C1032.985352,881.846313 1037.355713,881.710327 1040.224365,877.932434 
	C1041.501099,876.251221 1043.358276,874.713684 1045.720459,875.036072 
	C1052.975464,876.026245 1059.342041,873.719177 1065.510864,870.242310 
	C1069.364380,868.070435 1073.133057,868.478821 1076.793457,871.647949 
	C1077.120972,874.622437 1074.869507,873.941956 1073.498901,874.147095 
	C1067.627808,875.025635 1061.673340,875.431335 1056.754517,879.442322 
	C1055.356689,880.582153 1053.323730,881.175598 1051.582520,880.865906 
	C1045.950317,879.864258 1041.645508,882.209961 1037.334351,885.848999 
	C1036.544067,885.964539 1036.102051,885.967651 1035.328125,885.967651 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M1037.219482,886.216553 
	C1039.505127,880.804626 1043.566040,878.295715 1048.792236,879.211060 
	C1051.770752,879.732666 1054.609619,879.577332 1056.759033,877.684326 
	C1062.562988,872.572144 1069.767334,873.324707 1076.813477,872.157715 
	C1077.453613,871.918335 1077.873047,871.925476 1078.607422,871.932373 
	C1079.528564,871.469238 1080.056763,870.923523 1080.762207,870.153564 
	C1081.023682,868.822510 1081.496826,867.930786 1082.693848,867.133789 
	C1084.074219,866.673950 1085.183960,866.373108 1086.636230,866.024414 
	C1086.550781,871.044800 1078.321411,878.029785 1071.447632,877.791382 
	C1064.964355,877.566467 1059.679443,880.343628 1054.111938,882.522827 
	C1048.708496,884.637817 1042.601318,883.493774 1037.219482,886.216553 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M871.391846,937.000488 
	C872.180115,932.873352 876.544739,932.052979 879.293640,931.317566 
	C885.477417,929.663147 891.523376,925.010681 898.540039,928.487671 
	C898.845520,928.639160 899.542847,928.355103 899.865967,928.062256 
	C904.130615,924.198242 910.063782,923.161865 914.411072,919.409729 
	C916.246826,917.825256 918.381775,916.684021 920.932373,918.664673 
	C917.925842,922.147095 914.009583,924.096558 910.168823,926.700500 
	C904.167664,928.434326 898.609802,930.697083 892.692749,932.036316 
	C887.612488,933.186157 881.457092,932.324646 877.439575,937.460693 
	C876.267761,938.958679 873.773010,936.585327 871.391846,937.000488 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M926.387756,918.939026 
	C927.169922,914.512207 931.713745,914.187683 934.392822,914.516907 
	C939.128601,915.098816 943.575500,914.358154 948.571289,914.001587 
	C946.642761,917.877869 942.548462,916.613586 939.257202,916.839478 
	C934.989563,917.132263 930.840332,917.583313 926.387756,918.939026 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M923.056885,919.332886 
	C923.742493,919.007141 924.492737,919.002075 925.617859,918.996094 
	C925.443970,920.995178 924.608826,921.745972 923.056885,919.332886 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1095.460083,988.115112 
	C1097.838745,987.525635 1100.074707,988.638245 1101.721191,989.712219 
	C1104.774780,991.704041 1107.087524,993.964905 1110.657593,990.484314 
	C1111.881104,989.291382 1113.566772,990.654724 1113.814209,992.380493 
	C1114.113403,994.467529 1114.641479,997.229309 1111.762329,997.704468 
	C1108.043091,998.318359 1104.347168,999.279785 1100.470703,999.050415 
	C1096.403931,998.809875 1095.326660,995.962402 1096.704956,993.386536 
	C1098.162598,990.662476 1096.392944,989.909790 1095.460083,988.115112 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1074.014893,1018.368408 
	C1074.000000,1017.250000 1074.000000,1016.500000 1074.000000,1015.375000 
	C1074.278076,1014.603455 1074.507690,1014.159363 1074.842529,1013.818542 
	C1076.325073,1012.309021 1071.666870,1008.928406 1076.037354,1008.738098 
	C1079.507812,1008.586975 1081.898315,1012.802002 1081.047607,1016.640625 
	C1077.125610,1021.802612 1076.497437,1021.959167 1074.014893,1018.368408 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1087.292847,1005.564087 
	C1086.000244,1004.364746 1084.578613,1003.495667 1085.522095,1001.743652 
	C1085.889648,1001.061279 1086.814941,1001.013550 1087.201172,1001.453735 
	C1088.441772,1002.867737 1090.690430,1004.059998 1090.354370,1005.996033 
	C1090.093506,1007.499512 1088.551147,1005.987061 1087.292847,1005.564087 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1069.517090,1005.646118 
	C1069.514648,1004.152344 1070.223877,1004.233887 1070.984375,1004.766174 
	C1071.539062,1005.154358 1071.872070,1005.823181 1071.240234,1006.345703 
	C1070.653076,1006.831238 1070.083740,1006.383484 1069.517090,1005.646118 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M972.128296,980.309814 
	C970.758423,975.277771 967.202698,977.264771 964.338135,977.421936 
	C963.257507,977.481262 961.904602,978.438965 961.433167,976.835571 
	C960.939758,975.157654 962.729797,975.465088 963.553467,974.861816 
	C965.843567,973.184814 968.694763,975.571838 970.928772,973.328796 
	C972.602295,972.669189 974.206787,972.066284 975.806396,972.078796 
	C977.661316,972.093262 980.349243,970.964722 981.177734,972.978088 
	C981.805176,974.502869 980.101807,976.505554 978.848450,978.013367 
	C978.235168,978.751099 977.681702,979.538574 977.050598,980.651794 
	C976.250000,981.000000 975.500000,981.000000 974.375000,981.000000 
	C973.289307,981.467163 972.758118,981.111206 972.128296,980.309814 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1009.194458,976.047852 
	C1006.931580,977.103455 1006.226562,975.989075 1006.231384,974.247192 
	C1006.234314,973.201599 1006.964111,972.238586 1008.079468,972.461731 
	C1009.989624,972.843811 1010.169678,974.202271 1009.194458,976.047852 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1023.341553,1013.413330 
	C1022.626892,1013.611938 1022.366333,1013.362854 1022.478149,1012.806946 
	C1022.501343,1012.691650 1022.665100,1012.536804 1022.781799,1012.519592 
	C1023.342773,1012.436768 1023.577576,1012.709900 1023.341553,1013.413330 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M944.694702,944.836609 
	C945.089355,946.764465 946.888245,949.690491 943.693115,949.596130 
	C941.878845,949.542480 938.283264,953.479309 937.511597,948.109802 
	C937.261292,946.367981 934.552246,947.502808 934.075928,945.334839 
	C937.367249,941.842712 940.804565,940.914978 944.694702,944.836609 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1026.326782,1065.145752 
	C1025.850952,1064.370605 1025.703857,1063.740601 1025.552612,1063.111816 
	C1024.240967,1057.657349 1025.609619,1056.401245 1031.619873,1057.946533 
	C1031.966797,1065.027588 1031.390259,1065.813965 1026.326782,1065.145752 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1034.834839,993.303162 
	C1030.867798,991.309570 1030.275879,987.460266 1033.214966,984.937073 
	C1034.716309,983.648193 1035.779053,984.261841 1035.865845,985.951965 
	C1035.988159,988.336121 1036.647583,990.847961 1034.834839,993.303162 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M971.584351,979.999756 
	C972.496155,980.257690 972.992310,980.515381 973.744263,980.886597 
	C974.000000,981.986206 974.099426,982.984558 973.980042,983.956116 
	C973.700012,986.233704 975.172119,989.339233 971.909058,990.357727 
	C968.778564,991.334900 966.906189,988.429626 964.660706,986.988342 
	C961.990906,985.274597 964.155090,982.770874 964.190308,980.651794 
	C964.215271,979.148743 965.521179,979.140198 966.806641,979.414246 
	C968.237244,979.719177 969.712769,979.813477 971.584351,979.999756 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1088.411621,1103.000000 
	C1087.607910,1101.832153 1086.375122,1100.548340 1087.855835,1099.492432 
	C1089.144653,1098.573364 1090.821533,1098.727051 1091.980225,1099.747070 
	C1093.804321,1101.353149 1095.724121,1102.331421 1098.594482,1102.000000 
	C1098.943115,1105.668701 1096.565308,1105.514282 1094.345947,1104.412598 
	C1092.533569,1103.512817 1090.829346,1102.843506 1088.411621,1103.000000 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1143.222900,1048.494751 
	C1142.081909,1048.869141 1141.361450,1048.612061 1141.245728,1047.621460 
	C1141.162354,1046.906494 1141.634888,1046.331665 1142.376831,1046.495850 
	C1143.240356,1046.687012 1143.685425,1047.342163 1143.222900,1048.494751 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M980.348145,1022.443176 
	C978.810547,1020.976501 978.723267,1019.651428 980.294861,1018.631226 
	C981.324219,1017.962952 982.252686,1018.608459 982.595825,1019.607117 
	C983.176880,1021.297913 982.625427,1022.459473 980.348145,1022.443176 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M1024.678833,1052.852051 
	C1024.948242,1054.572388 1023.731262,1055.116577 1022.295776,1055.864380 
	C1020.162964,1055.325439 1016.678345,1055.304688 1017.911072,1052.600342 
	C1019.222046,1049.724121 1022.168396,1051.537476 1024.678833,1052.852051 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M486.446655,1183.625854 
	C492.014404,1178.890991 495.110168,1179.712036 497.501404,1186.298462 
	C497.803619,1187.130859 497.912933,1187.874390 497.152832,1188.539307 
	C495.289368,1190.169189 486.314728,1189.614502 484.046722,1188.695190 
	C478.658600,1186.510864 477.098267,1180.997681 473.475189,1177.316528 
	C470.758301,1174.556030 471.229553,1170.383301 473.676880,1167.149902 
	C475.590363,1164.622070 478.263885,1164.951904 480.917297,1166.137085 
	C482.319397,1166.763428 483.037384,1167.699585 482.721985,1169.258423 
	C482.231110,1171.684814 481.886871,1174.157471 481.177032,1176.519287 
	C479.914856,1180.718262 481.433197,1183.080200 486.446655,1183.625854 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M463.230774,1144.414185 
	C464.902161,1140.246826 467.898773,1139.555786 471.536499,1140.098999 
	C475.964447,1140.760254 477.022369,1144.695801 478.539917,1147.820679 
	C479.544830,1149.890259 477.199127,1150.083496 475.757935,1149.941040 
	C470.058105,1149.378662 464.438995,1147.795410 458.623047,1148.747314 
	C457.958832,1148.856079 457.339905,1148.351074 457.330353,1147.556152 
	C457.321533,1146.821655 457.762390,1146.369507 458.481293,1146.170044 
	C460.011902,1145.745728 461.911377,1146.491699 463.230774,1144.414185 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M475.077515,1135.000000 
	C473.269012,1134.584473 470.418488,1135.932007 470.750183,1133.597412 
	C471.193115,1130.479980 474.509460,1129.589355 477.304962,1129.269287 
	C479.345856,1129.035767 480.984039,1130.648315 480.708466,1132.655884 
	C480.292175,1135.689087 477.527893,1134.818481 475.077515,1135.000000 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M565.762146,1022.255859 
	C566.000000,1022.000000 566.500000,1022.000000 566.750000,1022.000000 
	C568.259705,1020.491638 569.992920,1019.590210 571.771973,1018.228821 
	C574.055664,1017.781311 575.309326,1016.615234 575.957336,1014.342346 
	C576.250427,1012.607117 577.302307,1011.868225 578.681763,1010.938721 
	C587.976990,1008.934509 596.463318,1004.532532 606.088379,1006.220215 
	C609.054260,1006.740295 611.724487,1004.656921 614.721802,1003.253845 
	C616.119263,1002.759094 617.238525,1002.518188 618.675171,1002.133850 
	C620.686279,1001.887817 621.996887,1001.242371 622.762085,999.244019 
	C623.566528,998.177368 624.414612,998.010803 625.663818,997.996765 
	C627.439941,998.391663 627.858459,999.474243 627.827820,1001.178345 
	C627.673828,1001.760620 627.579102,1001.982117 627.266479,1002.486450 
	C624.899780,1006.109314 621.886353,1008.039490 617.914734,1008.521729 
	C616.310669,1008.716492 615.485352,1010.092163 614.294495,1011.457031 
	C613.667236,1012.311523 613.177979,1012.823975 612.398560,1013.556763 
	C611.135254,1014.449646 609.881226,1015.190918 608.888733,1014.684143 
	C603.312073,1011.836243 598.730469,1014.959778 594.000061,1017.008240 
	C592.388672,1017.706055 590.868591,1017.945923 588.852173,1017.558838 
	C587.354919,1017.737366 586.200012,1017.642639 585.048584,1017.669250 
	C579.365540,1017.800598 579.163757,1017.990417 578.365845,1024.133545 
	C577.883240,1026.198975 577.886108,1028.292114 579.389221,1029.028320 
	C584.554871,1031.558105 587.284607,1036.833496 592.047729,1039.750854 
	C593.468811,1040.621338 594.843811,1041.520386 596.541931,1041.775146 
	C600.332764,1042.344116 601.931641,1044.662354 601.610352,1048.716797 
	C600.019775,1050.872314 597.909363,1052.074341 596.361084,1054.311279 
	C589.206726,1054.675781 582.415039,1055.414062 575.681213,1056.992798 
	C573.278687,1057.556030 570.844482,1055.897705 568.348633,1055.744873 
	C563.337158,1055.438232 558.622192,1053.168823 553.229370,1054.946289 
	C549.526794,1056.166626 544.796936,1051.152344 544.463745,1047.056152 
	C544.262634,1044.583740 545.903809,1043.869629 547.754761,1043.432983 
	C553.453613,1042.088501 558.445068,1044.466919 563.461975,1046.590332 
	C564.055786,1046.841675 564.497009,1047.420898 565.052917,1047.788818 
	C565.891052,1048.343628 566.872986,1049.050049 567.691956,1048.048340 
	C568.750183,1046.754028 567.544006,1045.962646 566.695435,1045.185791 
	C566.452393,1044.963257 566.141479,1044.819214 565.863464,1044.635742 
	C564.760925,1043.908813 563.306396,1043.270996 563.703247,1041.652466 
	C564.088379,1040.081787 565.660889,1040.095581 566.965332,1039.856812 
	C567.559631,1039.748047 568.273193,1039.922607 568.934143,1038.869751 
	C565.669189,1039.238037 562.830017,1038.588013 560.231934,1037.001709 
	C558.825256,1036.142944 557.432983,1036.239136 555.975830,1037.010376 
	C553.774292,1038.175781 551.744873,1037.796753 550.151123,1035.884521 
	C548.648682,1034.081909 550.228333,1032.679688 550.684814,1030.973389 
	C551.791077,1026.838501 554.272827,1026.015015 558.053284,1026.264771 
	C561.107544,1026.466553 564.099670,1025.873413 565.762146,1022.255859 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M633.367920,987.929016 
	C627.056396,991.145447 621.049011,994.174316 615.236633,997.540039 
	C612.189026,999.304810 609.347778,999.645752 606.363403,997.873840 
	C604.643066,996.852356 602.651794,996.482239 601.099854,997.267273 
	C596.187134,999.752625 591.005371,998.771240 585.446899,998.986084 
	C584.781494,994.899963 588.727295,990.368652 593.243896,989.622986 
	C596.086792,989.153687 598.976685,989.293518 601.606018,990.459961 
	C609.118896,993.793152 616.007080,990.430115 622.806091,988.233459 
	C625.829895,987.256531 629.774414,986.506836 631.328125,982.279419 
	C631.540833,981.426514 631.650879,980.937317 631.908936,980.104492 
	C633.276978,976.913391 636.951294,976.681213 638.533081,973.646729 
	C639.860840,972.511414 641.051941,971.954163 642.840820,971.862183 
	C646.898743,973.013794 649.261902,976.300171 653.210815,977.220947 
	C666.952026,980.425110 679.630859,978.040100 692.028137,971.628662 
	C698.876282,968.087036 706.550415,966.409302 713.954407,964.214905 
	C715.937256,963.627258 717.926636,964.150757 719.526306,966.283447 
	C719.840454,967.177429 719.860291,967.709045 719.616333,968.608398 
	C714.805481,971.055054 710.007996,972.930969 705.021606,973.858215 
	C700.518738,974.695435 696.276245,976.046570 692.381104,978.267395 
	C680.560120,985.007263 667.584290,986.203552 654.404053,986.612061 
	C651.462219,986.703247 648.966003,985.561462 646.397156,984.253845 
	C640.313599,981.157104 640.064453,981.299011 635.230774,986.702393 
	C634.657654,987.399414 634.237854,987.687622 633.367920,987.929016 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M632.751221,981.799988 
	C631.434082,988.041260 625.468323,988.420044 621.201355,990.710449 
	C619.261658,991.751587 616.509949,992.268005 614.122803,992.644348 
	C609.857727,993.316589 605.777039,994.476624 601.152222,992.128601 
	C594.400452,988.700745 589.978271,990.903870 585.030151,998.636780 
	C583.873352,999.068237 582.944763,999.488281 582.147705,1000.714966 
	C576.391724,1005.105164 570.244141,1006.847961 563.336365,1005.321838 
	C556.000854,1003.701172 552.684448,997.996033 555.257141,990.979309 
	C556.185364,988.447937 555.684082,985.192688 559.027893,983.535034 
	C559.675476,983.599182 559.921448,983.688293 560.432068,984.056152 
	C561.725525,985.932007 561.502808,987.854370 562.072571,989.589111 
	C564.096313,995.750977 567.799988,998.562683 574.059265,997.364563 
	C579.249878,996.371033 584.298401,994.477295 587.438599,989.191040 
	C587.853821,987.458862 588.717529,986.377014 589.770081,985.022827 
	C590.791138,983.574524 592.043518,982.614441 591.889160,980.607422 
	C591.865906,979.811523 591.878113,979.348694 591.952698,978.521973 
	C591.216553,971.179260 588.484070,964.718872 586.478455,958.083496 
	C585.014221,953.239136 584.071655,948.443726 584.387634,943.017944 
	C584.527649,941.568665 584.177612,940.552063 584.152405,939.107788 
	C585.179932,935.199036 586.886902,931.989807 588.571899,928.790649 
	C589.764099,926.526978 591.399658,924.660950 594.769104,925.510193 
	C596.810913,928.972168 594.109070,931.170288 592.963074,934.132263 
	C588.124817,940.922668 588.992371,947.950256 590.833740,954.966003 
	C591.799072,958.644226 593.081726,962.245605 594.122559,966.289978 
	C594.468140,967.534729 594.618652,968.467773 595.472839,969.473755 
	C595.956604,970.373352 596.147888,971.020386 596.364136,972.003052 
	C596.395325,973.949463 597.245239,975.171814 598.434692,976.612610 
	C598.715271,977.114502 598.807434,977.329224 598.985596,977.889038 
	C598.927002,983.514465 600.340149,985.830322 605.339661,986.598206 
	C613.350342,987.828613 621.219604,987.133057 628.427307,982.935181 
	C629.712708,982.186584 630.778076,981.016724 632.751221,981.799988 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M579.261230,1011.739136 
	C578.188538,1012.583130 577.374634,1013.166321 576.281616,1013.875977 
	C569.738647,1015.603210 564.212891,1020.266479 557.097717,1018.434143 
	C555.878601,1018.120178 554.921021,1019.344055 554.432007,1020.367554 
	C553.176941,1022.994629 550.743713,1023.698608 548.567383,1022.900635 
	C539.323364,1019.511292 533.393921,1025.933472 526.690735,1029.949829 
	C522.435913,1032.499268 517.049927,1032.862183 513.686401,1037.309937 
	C512.563293,1038.794922 510.479340,1036.263306 508.349182,1036.044434 
	C507.985962,1034.332764 508.432159,1032.837769 510.431793,1033.072632 
	C514.302429,1033.526489 516.597473,1031.329468 518.837708,1028.305054 
	C522.329834,1026.243286 527.640198,1028.341187 529.075989,1022.819336 
	C529.156738,1022.508911 529.960999,1022.114502 530.337402,1022.193420 
	C536.340576,1023.452637 537.652710,1017.723511 540.888489,1014.981506 
	C542.253418,1013.824951 543.411133,1012.488770 545.107422,1013.272827 
	C551.712463,1016.325500 558.776306,1014.349060 564.968262,1013.309387 
	C570.246338,1012.423157 576.587830,1011.027588 581.626892,1007.009460 
	C583.553528,1009.813232 580.233398,1009.923523 579.261230,1011.739136 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M560.536133,983.780334 
	C558.480469,984.153442 558.126160,985.285583 557.997437,986.776245 
	C557.884521,988.083496 557.516113,989.369690 557.247253,990.662415 
	C556.664429,993.465149 553.352234,995.777527 556.589783,999.224182 
	C560.184814,1003.051392 564.222595,1004.631165 569.379333,1004.407654 
	C573.660645,1004.222107 577.081726,1001.480347 581.594727,1000.988403 
	C581.170166,1004.946960 577.091980,1004.889893 574.350830,1006.956726 
	C567.477722,1009.321350 561.156433,1007.355957 555.082153,1005.463440 
	C551.670898,1004.400757 548.742432,1002.833313 545.304871,1004.950012 
	C544.772827,1005.277649 544.012939,1004.852356 543.132629,1004.323608 
	C541.405273,1001.559631 544.412842,1000.506958 544.732178,998.213135 
	C544.876526,997.426941 545.016663,996.993896 545.380127,996.287964 
	C545.730713,995.856873 545.901489,995.712708 546.375000,995.435059 
	C548.929626,995.200562 549.207031,993.463867 549.620483,991.460205 
	C549.776978,990.648254 549.929810,990.192322 550.286804,989.406494 
	C553.500977,983.722961 555.521423,978.098877 555.358826,971.834290 
	C555.316467,970.199585 555.486633,968.556213 556.749634,966.835083 
	C557.891541,966.151794 558.733337,966.161133 559.951172,966.736816 
	C562.156433,969.980225 561.728638,973.304077 561.657715,976.999695 
	C561.433472,978.890076 561.568787,980.357178 561.300842,982.239014 
	C561.084717,982.932129 560.961182,983.197021 560.536133,983.780334 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M512.217407,1024.599365 
	C507.688904,1024.578857 504.758606,1028.262573 500.460907,1028.634277 
	C495.478241,1029.065186 490.606995,1030.163330 485.492371,1029.756592 
	C481.433563,1029.433960 476.818207,1028.873779 473.314789,1030.401855 
	C467.176971,1033.078979 460.502258,1034.409912 454.359192,1037.947754 
	C453.319305,1038.592651 452.538483,1038.823242 451.258575,1038.469238 
	C450.235626,1037.616333 450.044922,1036.799927 450.188110,1035.483643 
	C450.516266,1034.534668 450.834167,1034.064941 451.566345,1033.393066 
	C456.278473,1031.684570 460.535553,1029.828247 465.198822,1029.297974 
	C466.657898,1029.131958 468.119690,1028.693604 469.439453,1027.943115 
	C475.850250,1024.297241 482.419464,1022.657288 489.889282,1024.764526 
	C493.837280,1025.878296 498.099579,1025.060059 502.318970,1023.012207 
	C504.517792,1021.930298 506.426605,1021.391174 508.925385,1021.591675 
	C510.918365,1021.852051 512.269836,1022.324402 512.217407,1024.599365 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M542.770081,1003.050659 
	C544.044128,1004.105103 545.848816,1004.409546 546.208008,1003.648438 
	C548.994873,997.743469 551.432617,1002.565186 554.286072,1003.526794 
	C560.359558,1005.573547 566.648438,1006.897949 573.561646,1007.001587 
	C573.755066,1008.356750 572.908691,1009.018738 571.571106,1008.964783 
	C565.033752,1008.700989 558.204773,1010.374756 552.129456,1006.827515 
	C548.243652,1004.558594 545.521301,1005.930969 543.351379,1008.970093 
	C542.346802,1010.377075 541.449707,1011.111450 539.431152,1011.010864 
	C538.841370,1011.139465 538.630554,1011.264832 538.091675,1011.221924 
	C536.396423,1007.996155 539.429504,1006.781860 540.588745,1004.380615 
	C541.196167,1003.414185 541.747559,1002.934570 542.770081,1003.050659 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M632.940552,988.329773 
	C633.108826,987.674744 633.299011,987.420959 633.759277,987.092163 
	C636.753967,984.108521 639.799133,983.523254 643.374268,985.612305 
	C645.548279,986.882812 648.094055,987.110596 650.554016,987.523071 
	C653.334167,987.989197 655.568054,989.237854 656.552795,992.508057 
	C655.552307,995.787659 653.349365,995.314148 651.251587,994.612061 
	C648.731262,993.768677 646.149414,993.168945 643.579712,992.565918 
	C637.338196,991.101074 634.836121,992.426575 632.174438,998.244751 
	C631.624451,999.446960 631.357056,1000.770142 630.311096,1002.159363 
	C629.237488,1002.801331 628.444946,1002.827271 627.319763,1002.264343 
	C626.760803,1000.877197 626.521606,999.754456 626.140381,998.312317 
	C629.139648,995.513123 629.822876,991.211548 632.940552,988.329773 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M537.698730,1010.717773 
	C538.132141,1010.988892 538.315979,1010.992493 538.776001,1011.005127 
	C536.654907,1015.686707 532.770691,1018.969421 527.766602,1019.655640 
	C523.773865,1020.203003 520.402771,1021.196533 517.522217,1023.918152 
	C516.156738,1025.208374 514.452148,1024.966919 512.404541,1024.993164 
	C511.858856,1023.589966 511.010284,1023.015503 509.328278,1022.996033 
	C507.683289,1020.362732 509.504761,1019.091187 511.413574,1018.690308 
	C515.708862,1017.788452 519.687561,1015.775574 524.309326,1015.858887 
	C528.158142,1015.928284 531.716919,1014.370361 533.148193,1009.691284 
	C533.471375,1009.178040 533.634338,1008.994568 534.143555,1008.682373 
	C535.915161,1008.500977 536.695496,1009.454834 537.698730,1010.717773 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M579.355835,1011.951233 
	C578.826477,1009.802612 581.258728,1009.331848 581.937866,1007.330933 
	C582.442871,1007.000427 582.885681,1006.998413 583.660583,1006.995728 
	C587.008179,1004.437744 591.296570,1005.673340 594.718811,1003.246216 
	C596.388916,1003.001648 597.777771,1003.000854 599.583374,1003.001282 
	C603.743958,1005.207581 607.516479,1003.485474 611.640320,1003.038452 
	C612.750916,1002.993713 613.500549,1002.995178 614.625122,1002.998291 
	C613.494385,1007.221985 609.413086,1008.126831 606.233643,1007.568542 
	C602.437927,1006.901978 598.849304,1007.153259 595.542603,1008.171875 
	C590.321777,1009.780090 584.908203,1010.351685 579.355835,1011.951233 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M594.548706,1003.000854 
	C593.036621,1007.922668 588.719849,1006.874146 584.429077,1006.996765 
	C583.853882,1004.028931 585.395996,1002.885559 588.215393,1002.990906 
	C590.173462,1003.064087 592.136536,1003.001282 594.548706,1003.000854 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M611.720337,1002.730957 
	C608.266968,1006.220703 604.286987,1005.681763 600.108398,1003.381592 
	C603.862244,1003.877808 607.565308,1001.336609 611.720337,1002.730957 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M450.789246,1037.783936 
	C451.748138,1037.997314 452.498779,1037.998047 453.624695,1037.999268 
	C454.000397,1038.166626 454.000763,1038.333374 454.001801,1038.750000 
	C453.516022,1041.611328 451.425659,1040.833984 449.388611,1040.996704 
	C448.996429,1040.245117 448.996521,1039.495239 449.209351,1038.163208 
	C449.807312,1037.451660 450.193634,1037.448364 450.789246,1037.783936 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M622.679321,999.051331 
	C622.864197,1001.094116 623.465637,1003.939758 619.318970,1002.137573 
	C620.116089,1001.029785 621.239807,1000.069214 622.679321,999.051331 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M347.403442,1069.000000 
	C347.629944,1066.147705 349.321808,1064.418579 352.627869,1063.996582 
	C352.356750,1065.429688 351.722504,1066.873901 351.044128,1068.659180 
	C349.934326,1069.000000 348.868652,1069.000000 347.403442,1069.000000 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M408.996033,1051.994995 
	C409.433716,1047.256470 412.874847,1045.906250 416.693787,1046.097778 
	C423.668274,1046.447632 430.467529,1045.796265 437.605499,1044.061523 
	C436.771393,1046.919678 434.665192,1047.668823 431.468018,1047.830322 
	C426.411011,1048.086060 421.223419,1048.317139 416.203003,1050.669678 
	C414.511322,1051.161011 413.022614,1051.322021 411.266968,1051.741455 
	C410.614075,1052.378174 410.154388,1052.524902 409.310974,1052.219727 
	C409.001221,1052.000000 408.999390,1051.999756 408.996033,1051.994995 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M409.000763,1052.374634 
	C409.445404,1052.000000 409.889557,1052.000000 410.666870,1052.000000 
	C410.947113,1053.135254 411.090881,1054.362061 409.334473,1054.929199 
	C408.999268,1054.247437 408.999786,1053.498413 409.000763,1052.374634 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1151.744995,233.282501 
	C1149.041626,230.190521 1150.756348,225.268265 1146.442871,223.316544 
	C1145.705688,222.983047 1146.189087,221.430435 1147.065796,220.753128 
	C1148.374268,219.742310 1150.162842,219.511368 1151.274536,220.401672 
	C1155.322388,223.643692 1159.662720,225.304199 1164.969360,225.218307 
	C1167.643799,225.175003 1167.325562,227.578766 1165.957886,229.198532 
	C1164.929443,230.416489 1162.914185,230.975143 1163.083252,232.957230 
	C1163.424438,236.957748 1160.674194,237.348953 1157.799438,238.005814 
	C1153.922485,238.891678 1153.033447,236.232452 1151.744995,233.282501 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1234.954590,109.353546 
	C1237.370605,109.000587 1239.741089,109.000565 1242.555908,109.000732 
	C1243.213623,112.471130 1242.107178,114.878525 1238.170898,115.033272 
	C1236.938354,115.081726 1235.901001,115.503853 1235.348267,116.706963 
	C1234.625610,118.280205 1235.518066,121.456131 1233.446899,121.342033 
	C1228.450439,121.066826 1223.939819,124.324097 1218.916138,122.922325 
	C1218.073120,122.687096 1217.237915,122.417305 1217.387573,121.370735 
	C1217.578247,120.036430 1218.737671,119.892006 1219.671143,120.181480 
	C1224.059082,121.542236 1225.435913,120.307037 1224.563843,115.627975 
	C1224.012207,112.669029 1227.158081,112.366409 1228.650146,113.262619 
	C1233.288574,116.048622 1233.840332,112.638519 1234.954590,109.353546 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1172.893066,210.557556 
	C1175.571289,209.792160 1177.899536,209.798080 1180.193237,210.286743 
	C1181.937134,210.658279 1183.356567,211.772278 1183.564209,213.570526 
	C1183.795654,215.573257 1182.188110,216.458832 1180.531250,216.967758 
	C1177.266846,217.970490 1173.921997,218.748230 1170.530396,220.782028 
	C1173.394531,217.636475 1164.803467,212.957901 1172.893066,210.557556 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1096.851807,257.760437 
	C1096.174072,259.414795 1095.981201,261.104401 1094.236206,261.433258 
	C1093.492432,261.573456 1093.176147,260.814850 1093.085205,260.131470 
	C1092.880493,258.594452 1093.099121,257.018280 1094.220459,255.997192 
	C1096.031616,254.347916 1096.760254,255.481430 1096.851807,257.760437 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M923.348022,339.367401 
	C921.019287,340.214600 919.421448,339.800629 919.243530,337.383728 
	C919.148865,336.098450 920.126343,335.133881 921.405518,335.243164 
	C923.810547,335.448700 924.224243,337.045685 923.348022,339.367401 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1232.658813,126.586983 
	C1233.372803,126.388557 1233.633179,126.637306 1233.521118,127.192741 
	C1233.497925,127.307945 1233.334351,127.462524 1233.217896,127.479729 
	C1232.657471,127.562500 1232.422729,127.289810 1232.658813,126.586983 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M993.250000,307.000305 
	C993.000000,306.555298 993.000000,306.110596 993.000000,305.332336 
	C993.871948,305.198517 994.969421,305.204407 994.203979,306.818848 
	C993.833313,307.001007 993.666687,307.000824 993.250000,307.000305 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1124.039062,298.279968 
	C1124.545532,298.180389 1125.090942,298.360809 1125.818237,298.770752 
	C1125.103394,299.970612 1124.480713,299.745270 1124.039062,298.279968 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M977.716003,298.049561 
	C978.000000,298.446594 978.000000,298.890747 978.000000,299.668060 
	C977.372375,299.893707 976.619568,299.828003 976.579346,299.043213 
	C976.563904,298.743073 977.130554,298.413086 977.716003,298.049561 
z"/>
<path fill="#DAD8D5" opacity="1.000000" stroke="none" 
	d="
M1070.750000,219.000000 
	C1075.560913,219.785294 1078.456543,223.662415 1082.643799,225.268631 
	C1084.637085,226.033325 1083.000366,227.317413 1081.919922,227.996948 
	C1077.142334,231.001770 1071.862549,228.770340 1070.579956,223.208084 
	C1070.323486,222.095505 1070.193359,220.953812 1070.002441,219.413696 
	C1070.166626,219.001404 1070.333374,219.000992 1070.750000,219.000000 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M1237.905762,88.102417 
	C1239.979248,88.586060 1239.967163,89.937569 1239.982544,91.257553 
	C1240.004517,93.122452 1240.028442,95.041893 1237.338135,94.908913 
	C1234.586426,94.772881 1235.088867,92.657494 1235.026611,90.931892 
	C1234.965576,89.238243 1235.561523,88.063873 1237.905762,88.102417 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M223.743958,371.230988 
	C223.999390,371.000000 223.999390,370.500000 223.999084,370.250000 
	C227.165726,368.742188 229.648331,365.721527 233.833267,367.287842 
	C240.464920,369.769897 248.208267,362.618378 246.765167,355.633514 
	C246.378998,353.764496 244.871735,354.146454 243.613968,353.981262 
	C242.674225,353.857880 241.481628,354.015381 241.559357,352.502838 
	C241.696732,349.829681 246.491196,350.923920 245.656219,347.161896 
	C244.757599,343.113220 248.169891,344.349640 250.598450,343.999207 
	C252.065994,343.278442 253.250229,342.776825 254.689819,342.086334 
	C260.145020,340.699951 264.822571,338.661377 268.234070,334.307953 
	C269.843658,332.253967 272.912598,330.393036 267.923157,328.796112 
	C267.117798,328.538330 266.405365,327.988342 266.445587,327.034607 
	C266.508240,325.548828 267.681549,325.262726 268.851837,325.017853 
	C271.980103,324.363434 273.513916,322.818726 272.334686,319.387177 
	C271.648743,317.391022 271.643066,315.170746 274.232361,314.575195 
	C277.074249,313.921539 276.791931,316.320312 277.039886,318.058441 
	C277.161987,318.914551 277.362671,319.712463 278.770081,319.206299 
	C279.621887,318.339111 279.839630,317.511108 279.986206,316.324005 
	C280.000000,315.250000 280.000000,314.500000 280.000000,313.375000 
	C282.680267,310.922913 284.186310,312.539642 285.591370,314.718964 
	C287.753479,318.072510 289.724243,321.535889 291.926361,325.109436 
	C286.744537,319.173004 290.239014,314.439484 294.110016,310.292999 
	C298.002441,306.123566 298.712463,302.122101 296.840118,296.707428 
	C294.138184,288.893799 295.660614,286.683563 303.222382,283.993896 
	C306.026825,282.996338 307.832550,283.469025 308.417572,286.495728 
	C308.985779,289.435303 310.424103,291.640045 312.835938,293.447357 
	C315.324524,295.312225 315.200897,298.419189 315.803223,301.401337 
	C316.567505,299.568542 316.440643,297.933289 315.633118,296.537476 
	C313.515656,292.877380 314.448334,290.934052 318.651917,290.511047 
	C319.674896,290.408081 320.502533,289.795563 321.674500,289.132385 
	C322.998383,289.012329 324.005920,288.941559 324.993591,289.050812 
	C330.476074,289.657318 330.463776,289.676514 328.416321,284.536713 
	C327.388184,281.955750 329.024719,280.144440 330.228333,278.151855 
	C331.591858,275.894531 334.074707,274.040161 331.642212,270.679810 
	C330.008453,268.422882 332.592285,267.603424 334.313232,267.136932 
	C338.229431,266.075256 337.662628,264.134796 335.491302,261.825165 
	C334.768982,261.056854 333.061920,260.964020 333.647614,259.344666 
	C334.032776,258.279724 335.107117,258.086578 336.139801,258.045868 
	C337.520996,257.991364 339.251404,258.081146 339.499207,259.556030 
	C340.577393,265.972443 344.667389,265.376068 349.600342,264.012390 
	C350.166656,263.871918 350.333344,263.743866 350.750000,263.807922 
	C352.303192,267.442535 354.326508,266.036316 355.913849,264.487274 
	C359.040100,261.436493 361.842560,258.625946 367.040253,259.725830 
	C369.784485,260.306549 370.852509,257.771973 368.846405,255.488358 
	C365.823700,252.047516 371.123627,249.712189 369.902832,246.460205 
	C369.803040,246.194458 371.751984,246.868713 372.463165,247.746017 
	C375.753296,251.804840 377.752350,247.406921 380.184692,246.436325 
	C381.120667,246.062836 380.349915,244.938477 379.669739,244.476334 
	C378.715881,243.828293 377.625854,243.384811 376.639862,242.779282 
	C371.745605,239.773544 371.517090,238.726578 374.462708,233.848434 
	C375.054871,232.867813 375.432678,231.757751 375.954041,230.353073 
	C375.858185,229.822281 375.717621,229.645767 375.593811,229.129776 
	C376.248993,227.589661 376.660309,226.290741 377.422424,224.767807 
	C378.113251,223.947098 378.709808,223.582397 379.755615,223.335190 
	C383.286682,223.945694 384.219147,226.256454 384.252258,229.098419 
	C384.273590,230.929337 384.629974,232.345551 386.755707,232.616699 
	C389.646240,232.985397 388.071106,230.716568 388.526581,229.646729 
	C389.667297,226.967178 392.160309,226.058533 394.931152,224.977722 
	C396.064301,224.892151 396.776001,225.075836 397.707275,225.758728 
	C399.638702,228.057648 398.843719,231.153046 401.229645,233.017929 
	C402.488678,234.001968 401.780609,235.357117 400.492981,236.294403 
	C399.311615,237.154404 397.881226,238.044922 398.378021,239.849808 
	C398.705750,241.040558 399.896240,240.975220 400.872925,240.973938 
	C402.664215,240.971573 404.696472,241.356369 405.654175,239.250381 
	C406.359131,237.700211 405.946075,235.117218 408.516571,235.329529 
	C410.538147,235.496490 410.298859,237.855682 410.916595,239.282684 
	C411.422424,240.451309 412.210480,241.323792 413.243195,241.739456 
	C418.759033,243.959625 417.012726,247.452927 414.658203,250.682098 
	C413.370117,252.448608 412.892914,254.157104 413.000122,256.596802 
	C412.877502,257.735016 412.449036,258.284790 411.653015,258.996460 
	C409.403748,261.977997 407.072815,264.439301 406.817749,268.219360 
	C406.600647,271.436066 404.507050,272.518066 401.590973,270.791504 
	C399.990601,269.843903 398.871338,269.010071 397.992188,271.602783 
	C397.081543,274.288544 395.143311,273.650635 393.159546,272.659790 
	C390.066101,271.114746 388.861786,267.716583 385.885956,265.742798 
	C384.564087,265.820831 384.368561,266.569855 384.226562,267.740631 
	C383.968567,269.087372 383.531250,269.959808 382.382080,270.828125 
	C377.191711,272.504120 372.473816,273.200226 368.583038,268.363678 
	C368.580688,267.682220 368.705658,267.388824 368.909149,267.144592 
	C369.115540,266.896912 369.344757,266.669342 369.576538,266.444916 
	C369.380310,266.567352 369.253571,266.762085 368.844940,267.155060 
	C367.062683,268.847015 365.853973,270.540100 364.783478,272.366089 
	C363.508759,274.540497 361.793335,275.780701 358.931580,274.138000 
	C358.029846,272.916809 358.426453,271.780029 358.179840,270.775482 
	C357.805328,269.249664 357.355042,270.121552 356.886871,271.175476 
	C354.869476,274.685059 350.625854,274.972839 348.702850,278.591705 
	C347.111542,281.586365 345.841095,284.011169 347.310944,287.292908 
	C347.999817,288.830872 348.093262,290.513214 347.297119,292.460632 
	C346.730347,293.773651 345.861633,294.520081 345.465698,295.891907 
	C345.282745,298.314148 347.141998,299.824951 347.102173,302.244385 
	C347.015900,303.968079 345.863556,304.908600 345.518250,306.593201 
	C348.000763,306.979309 351.185852,306.816528 351.131989,310.556763 
	C351.080353,314.143524 348.921051,316.456909 345.832581,317.695892 
	C342.056641,319.210632 340.244049,316.268402 338.249878,313.558594 
	C339.173187,317.369720 340.784760,319.898529 345.049164,319.649628 
	C347.627930,319.499084 350.327026,319.972137 350.718933,323.175995 
	C351.126312,326.506256 348.205170,326.999054 345.629822,328.073303 
	C342.427795,329.910858 340.625946,332.758667 337.849121,334.737946 
	C344.314117,338.117340 350.912781,336.984039 357.742889,337.062073 
	C361.783386,340.067505 364.507874,344.018463 368.618408,346.502045 
	C370.121307,347.410065 370.964111,349.255737 369.645142,351.485992 
	C365.676208,353.202942 362.908478,357.053253 358.167542,355.941833 
	C356.886566,355.641571 355.550018,355.610413 354.113434,354.747498 
	C353.669159,354.487793 353.485992,354.402527 353.040375,354.192139 
	C352.259705,353.852661 351.730103,353.731415 350.896484,353.971313 
	C350.244263,354.219635 349.855316,354.338165 349.148956,354.533417 
	C346.429871,356.433441 344.486115,355.116302 341.723114,352.908539 
	C344.442078,357.017090 347.023895,360.587921 343.639893,364.082001 
	C339.525482,368.330383 335.360443,364.922821 331.036774,362.993622 
	C330.767700,364.917145 332.315826,366.628754 331.210022,368.319183 
	C330.089508,370.032104 328.224945,370.275208 326.519928,370.893677 
	C324.854156,371.497925 323.163788,371.795990 323.196442,374.447266 
	C323.240448,378.023041 321.434326,378.954407 318.154114,377.264771 
	C315.347107,375.818817 312.725861,374.031219 310.896667,371.312073 
	C309.215851,368.813538 307.197449,366.567322 304.876678,364.680237 
	C302.695007,362.906250 300.706787,363.567383 301.024658,366.434601 
	C301.621368,371.817810 298.835205,374.471924 294.401642,376.185974 
	C292.855713,376.783630 291.985138,377.701996 291.857269,379.462769 
	C291.579254,383.291931 289.554291,383.466797 286.810547,381.617310 
	C285.331329,380.620239 284.124512,380.550201 282.818726,382.589081 
	C287.014160,383.750549 290.417267,386.094421 294.274689,387.472748 
	C295.646912,387.963043 297.546143,388.195129 297.297821,390.161133 
	C297.028595,392.292755 295.007233,392.285767 293.382172,392.432251 
	C291.387268,392.612030 289.428009,392.324127 287.455597,391.873840 
	C284.514313,391.202423 281.542786,391.016083 278.804382,392.835632 
	C278.741913,394.393738 280.340393,394.366669 281.236877,395.489044 
	C281.620178,395.993652 281.744812,396.220398 281.980255,396.825684 
	C282.205200,398.015533 282.097717,398.798004 281.565887,399.890198 
	C280.511322,400.971771 279.514740,401.177948 278.080444,401.029755 
	C276.345490,401.545105 275.827606,397.362915 273.848389,400.588898 
	C273.519714,401.071228 273.363281,401.252319 272.905518,401.643372 
	C267.678223,402.906067 262.631317,403.439789 257.540375,405.444885 
	C255.384048,406.176117 253.653870,405.686401 251.575394,405.091064 
	C250.030807,404.198273 248.867340,403.367676 247.117294,402.939880 
	C243.998596,401.688965 244.454285,397.272156 240.540161,396.880920 
	C239.185883,396.461578 238.312698,395.836792 237.196915,395.297607 
	C237.017563,395.261169 236.946472,395.641052 237.081421,395.832733 
	C239.103683,399.990692 238.560898,401.250885 234.596069,401.027008 
	C231.310532,400.841522 228.432739,401.013428 226.316849,404.405579 
	C225.950439,404.893646 225.781631,405.074738 225.306656,405.473755 
	C220.688477,406.731964 216.201019,404.928192 211.865265,406.608337 
	C210.694962,407.061829 209.443222,405.783844 208.430313,404.337708 
	C207.999527,403.255493 207.979630,402.503479 208.294418,401.404266 
	C211.547638,398.987732 213.850754,395.032471 218.691925,397.092346 
	C220.182419,397.726532 220.997818,396.193176 221.510956,394.929962 
	C222.762161,391.849792 225.037872,391.271545 228.221298,392.303802 
	C228.717667,392.561249 228.973389,392.598938 229.119171,392.348938 
	C226.666565,390.900726 228.304764,387.672485 225.792175,386.209412 
	C225.250702,386.005371 225.036301,385.912415 224.514038,385.646027 
	C221.538086,384.186768 219.686646,382.496124 219.764771,379.113739 
	C219.811905,377.072998 218.434052,375.456268 216.263763,374.244263 
	C216.000000,374.001221 216.000000,374.000000 215.999695,374.000000 
	C215.621262,373.613770 215.474808,373.153809 215.779755,372.310028 
	C216.166153,371.999817 216.332932,371.999634 216.749847,371.999146 
	C218.990738,369.739655 221.379791,372.295105 223.743958,371.230988 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M286.999207,252.582352 
	C282.770172,252.935562 283.620941,249.604996 284.228058,247.613663 
	C285.541931,243.303833 283.631042,242.669067 280.138428,242.988831 
	C278.821960,243.109344 277.343994,242.741226 276.199982,243.219101 
	C273.352356,244.408600 272.170227,243.163803 270.788940,240.804260 
	C267.447693,235.096680 263.921783,234.268143 258.054138,237.647400 
	C255.258041,239.257721 252.859680,239.939713 250.843475,236.737183 
	C249.655930,234.850861 247.799606,234.950302 245.984787,235.006821 
	C244.022919,235.067947 242.833908,234.146072 242.315201,232.346237 
	C241.786438,230.511536 241.584824,227.950043 243.360123,227.429932 
	C247.775757,226.136276 248.631744,222.827347 249.660660,219.073822 
	C253.030502,219.642273 256.279938,221.480637 258.727325,217.225464 
	C259.973236,218.118073 261.291870,219.086884 261.852203,220.383392 
	C263.289093,223.708252 265.554749,223.503601 267.870789,221.878784 
	C269.500641,220.735352 270.400940,221.620483 271.903076,221.861206 
	C276.793213,222.644867 281.781311,222.739456 286.746552,223.568542 
	C289.319641,223.998199 289.478485,220.748032 288.049469,218.657654 
	C286.634033,216.587051 285.939972,214.871399 287.522552,212.361633 
	C288.702545,210.490280 283.658600,204.939072 279.645477,203.335022 
	C279.827576,201.407440 281.719452,200.921051 282.791382,199.805283 
	C284.478912,198.048737 286.430908,196.680038 287.032227,200.720093 
	C287.455109,203.561539 289.329895,203.206146 291.113342,202.371017 
	C295.028381,200.537750 298.194031,201.430481 302.278809,203.003479 
	C306.694366,204.703827 311.022919,206.211334 315.161438,208.653900 
	C317.328430,209.932877 320.209991,209.338730 320.017059,205.414505 
	C319.894592,202.923248 319.997925,200.420898 320.000000,197.461456 
	C321.794250,196.931107 323.876160,197.347275 324.694641,195.121155 
	C325.495178,192.943741 323.946655,192.332550 322.239258,191.674820 
	C320.664429,191.068115 320.842987,189.521774 321.314392,188.246521 
	C321.837646,186.830902 323.146362,186.976318 324.284973,187.277954 
	C329.468414,188.651093 334.279419,186.911987 339.698242,185.439651 
	C338.234222,189.431320 339.857513,190.723801 343.673615,191.315033 
	C347.416351,191.894897 345.612152,196.083145 346.798615,198.471237 
	C347.535278,199.954041 346.901917,201.734695 345.459137,202.948318 
	C344.595276,203.674988 343.762207,203.813187 342.757935,203.178604 
	C338.228729,200.316589 333.325928,199.018768 327.927979,199.414764 
	C326.744904,199.501541 325.474304,198.988449 325.197083,200.577560 
	C324.886810,202.356125 324.578888,204.246918 325.737335,205.874405 
	C326.535004,206.995041 327.828369,207.212372 328.810699,206.385681 
	C331.205536,204.370193 334.275513,205.325668 336.900726,204.312057 
	C338.324066,203.762466 339.688477,204.655289 339.715668,206.244217 
	C339.749786,208.238419 338.282837,207.401794 337.196564,207.235046 
	C333.150146,206.613876 332.755188,209.037613 333.211884,212.109222 
	C333.390839,213.312866 334.692474,214.737946 332.918365,215.586609 
	C331.474121,216.277451 331.041962,214.662842 330.276276,213.892685 
	C329.473053,213.084824 328.736633,211.900635 327.378235,212.584915 
	C325.685303,213.437729 326.479858,214.826614 326.893036,216.058258 
	C327.575897,218.093842 328.014496,220.212036 329.328552,222.016739 
	C330.093140,223.066833 330.159393,224.205261 328.335999,224.929276 
	C327.005554,225.835770 325.910706,226.093185 324.341339,225.288040 
	C323.694916,222.881775 322.493469,223.243759 321.141602,223.951691 
	C316.427826,226.420273 312.783447,230.573349 307.054993,231.666260 
	C305.143738,232.030899 303.715240,236.105515 306.607971,238.767914 
	C307.479584,239.570114 310.000458,239.975433 307.307739,241.940475 
	C306.608917,242.440292 306.157990,242.790665 305.288818,242.973022 
	C303.285645,242.999527 301.571289,242.999649 299.428467,242.999588 
	C294.654846,243.219162 290.520172,243.974808 287.241089,247.762085 
	C286.999878,249.389450 286.999725,250.777695 286.999207,252.582352 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M321.879761,288.790741 
	C322.208160,291.621613 321.739899,293.372986 318.443390,292.126953 
	C317.567474,291.795807 316.256287,291.760986 315.722107,292.730499 
	C315.108337,293.844574 316.431030,294.352844 316.933197,295.142853 
	C318.810242,298.095978 318.265900,301.125397 316.893311,304.086365 
	C316.555237,304.815674 316.017456,305.599396 315.016815,305.364014 
	C313.951172,305.113373 314.014771,304.119049 314.039124,303.333954 
	C314.175232,298.949158 314.078522,294.881531 309.091522,292.655090 
	C307.721680,292.043518 306.980316,289.666473 307.056396,287.764526 
	C307.217560,283.735382 305.753235,284.421936 303.030090,285.866089 
	C296.452759,289.354187 295.707031,291.033752 298.844818,298.238190 
	C300.391083,301.788483 301.059967,305.019653 297.713074,308.131348 
	C295.034454,310.621735 293.857483,314.333496 291.021729,316.853302 
	C289.169647,318.499054 291.249390,320.205750 291.948212,321.733582 
	C292.486359,322.910156 293.509796,323.859100 294.276001,324.938660 
	C295.034760,326.007721 295.161102,327.157318 294.123352,328.080109 
	C292.665527,329.376404 291.518768,328.282806 290.851715,327.177734 
	C288.479431,323.247620 286.054688,319.319092 284.114502,315.172546 
	C283.297668,313.426788 282.378387,312.978729 280.387909,313.002869 
	C280.000000,311.285980 280.000000,309.571991 280.000000,307.429596 
	C279.356750,305.555786 280.264252,304.816345 281.652588,304.068542 
	C284.068451,303.667694 286.135712,303.335419 288.600861,303.001587 
	C289.594055,302.447662 290.062958,301.793213 290.700745,300.909027 
	C291.139801,300.431335 291.404633,300.182281 291.842896,299.474792 
	C291.187805,296.472137 291.365448,293.276276 287.547119,292.870422 
	C285.145905,292.615173 287.827423,289.585083 285.315063,289.060486 
	C284.577209,288.476959 284.049988,288.335114 283.209503,288.785980 
	C280.588654,290.180389 278.177795,289.052643 275.384247,289.001129 
	C274.168152,288.358337 273.249603,287.787323 273.020874,286.313263 
	C272.629364,282.399811 274.120270,280.831512 277.856049,280.951233 
	C283.461945,281.130829 289.080750,280.871643 294.690155,280.988068 
	C301.580750,281.131104 306.230133,276.714508 311.658142,273.069092 
	C312.423340,273.523041 312.950043,273.664673 313.789612,273.213989 
	C316.235687,273.039246 318.558228,272.857208 319.925720,275.648590 
	C319.606171,277.928223 317.981628,278.435822 316.467987,279.102875 
	C315.189148,279.666412 313.215088,279.642029 313.380737,281.689545 
	C313.534088,283.585052 314.641846,285.086426 316.378052,285.969086 
	C317.690979,286.636566 319.327362,286.482880 320.753662,287.764893 
	C321.304596,288.126648 321.557770,288.320465 321.879761,288.790741 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M374.653992,183.046082 
	C380.457825,180.871475 382.220551,184.571976 383.656281,188.590073 
	C384.193512,190.093567 384.358612,191.730042 384.847473,193.651794 
	C382.086731,195.248047 383.615784,198.395630 382.149414,200.664368 
	C379.378418,202.522781 378.259552,205.165741 376.782104,207.968216 
	C376.032135,208.712921 375.407196,208.979355 374.347595,209.022980 
	C369.185455,208.563278 370.026031,204.963516 370.000000,201.424240 
	C368.995361,200.107941 369.007629,198.907745 369.001923,197.356201 
	C369.338898,193.531555 371.878448,190.522858 370.618408,186.587540 
	C370.206818,185.302094 372.470520,183.687576 374.653992,183.046082 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M350.093140,225.640808 
	C349.840668,227.371948 350.478180,229.014435 348.348816,229.917969 
	C346.903931,231.085663 344.740356,228.934021 344.315033,230.805862 
	C343.838043,232.904999 346.358734,233.317368 347.753113,234.761368 
	C352.430664,234.550125 353.365692,237.915543 354.726257,240.796005 
	C353.665558,242.211777 352.613861,241.476913 352.002106,240.916122 
	C348.629608,237.824524 345.643005,237.064758 341.557343,240.361923 
	C338.565887,242.776031 333.200806,239.308441 333.018066,235.388443 
	C332.932983,233.563278 333.001251,231.730988 333.000000,229.451263 
	C332.621429,228.612640 332.470032,228.150299 332.773499,227.306778 
	C336.105072,225.927856 340.557373,225.333633 335.007172,221.233292 
	C334.433868,220.809753 334.220978,220.104187 334.788971,219.555374 
	C335.506653,218.861862 336.467987,219.121170 336.996033,219.706253 
	C338.971802,221.895477 341.463959,222.230011 344.585907,222.000000 
	C347.713654,221.537369 348.962372,223.389908 350.093140,225.640808 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M348.057770,160.396423 
	C350.461700,159.633591 351.811584,158.228638 351.992310,155.362839 
	C357.477264,155.253845 359.814819,159.702606 362.385040,163.361938 
	C364.741974,166.717575 360.585144,173.805923 356.396606,173.919907 
	C353.969788,173.985916 352.493683,175.400955 350.639801,176.437195 
	C349.061890,177.319183 346.599548,177.817001 345.646851,176.128891 
	C344.405029,173.928528 346.993530,173.661774 348.379730,173.055573 
	C349.645386,172.502060 351.385040,171.811783 350.362000,170.355286 
	C348.268433,167.374680 349.697601,163.761551 348.057770,160.396423 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M374.005188,209.345551 
	C374.471588,208.686707 374.943207,208.373428 375.707397,208.030060 
	C377.539398,213.125824 380.983795,217.685867 380.285583,223.695709 
	C379.503845,224.257706 379.007721,224.515411 378.255798,224.886566 
	C377.255768,225.686951 376.513550,226.376755 375.156982,226.812012 
	C373.172760,227.050949 371.718811,226.683868 370.241943,225.297119 
	C368.677826,222.903854 365.773956,221.814133 366.008240,218.362732 
	C367.771912,216.746414 370.464325,216.415024 370.770081,213.232117 
	C373.698608,213.439255 374.769409,212.397537 374.005188,209.345551 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M321.001770,287.606628 
	C318.656433,287.607605 316.021637,288.964996 313.976990,286.756348 
	C309.626465,282.056793 310.281708,279.406799 316.177307,277.738586 
	C317.275330,277.427856 318.282104,276.794678 319.665497,276.155151 
	C324.212311,277.078644 325.416473,279.696716 322.839294,282.864380 
	C321.720612,284.239410 320.890259,285.449951 321.001770,287.606628 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M380.087372,174.645111 
	C376.785614,174.905518 377.452728,178.476715 375.297180,179.831573 
	C373.052429,178.970856 370.766479,185.065384 369.012054,179.357758 
	C368.621429,178.611740 368.470062,178.149643 368.773499,177.306580 
	C369.874664,176.270477 370.020660,175.363266 369.638275,174.336929 
	C368.529419,171.361008 369.962921,170.516159 372.485138,171.394058 
	C374.917053,172.240540 376.525482,171.811600 377.957336,169.687119 
	C378.988281,168.157379 380.525085,167.716370 382.288086,168.669876 
	C383.455475,169.301254 384.820618,169.835205 384.729279,171.436234 
	C384.637787,173.039642 383.232330,172.908371 382.116180,173.026047 
	C381.237244,173.118713 380.539734,173.435410 380.087372,174.645111 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M273.000336,261.667053 
	C269.519165,261.992737 266.037018,262.041809 262.557922,261.960205 
	C259.844849,261.896576 258.136292,260.493530 258.079071,257.697998 
	C258.012756,254.459290 260.108551,254.989456 262.203491,255.510818 
	C264.266388,256.024200 264.636322,254.402969 265.192505,252.996613 
	C265.844147,251.348846 267.192963,250.616547 268.776672,251.314163 
	C270.622589,252.127289 269.607910,253.431992 268.945709,254.592163 
	C266.117004,259.548248 272.602905,256.839447 272.913605,259.647858 
	C273.001831,260.446320 273.001251,260.890228 273.000336,261.667053 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M358.794556,209.795975 
	C359.000000,210.750000 359.000000,211.500000 359.000000,212.625000 
	C357.978149,214.478943 357.623322,216.486740 355.328430,217.108414 
	C354.555542,217.000000 354.111115,217.000000 353.333313,217.000000 
	C350.605011,217.675018 348.116211,217.691528 345.337616,218.021927 
	C343.469513,217.837921 342.084503,217.304169 341.167236,216.038055 
	C340.393127,214.969498 339.715881,213.764587 340.507874,212.392624 
	C341.180145,211.228073 342.256073,211.291428 343.485504,211.256577 
	C347.822266,211.133652 352.314789,211.730865 356.621521,209.058441 
	C357.596222,209.018753 358.130798,209.203003 358.794556,209.795975 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M287.585266,277.676117 
	C283.946655,275.302765 282.983643,270.246277 285.179474,267.246643 
	C286.052063,266.054657 287.131073,266.378357 287.595306,267.223541 
	C289.362671,270.441132 295.863617,267.210907 295.316162,272.176788 
	C294.994690,275.093170 293.190918,279.591949 287.585266,277.676117 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M414.248840,129.814575 
	C409.477600,132.110184 405.444794,128.979263 401.134613,128.763718 
	C400.122528,128.713089 399.327759,127.885605 399.305634,126.731262 
	C399.280518,125.423813 400.260742,124.983574 401.292938,125.076408 
	C404.554688,125.369774 407.402832,123.962212 410.434296,123.213211 
	C413.602203,122.430481 416.477539,123.165665 416.990723,127.615372 
	C415.817291,128.004715 415.156525,128.813904 414.248840,129.814575 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M323.714813,225.156769 
	C325.067535,224.999191 326.133881,224.999603 327.600098,225.000305 
	C328.180267,225.545944 328.360535,226.091278 328.770416,226.818298 
	C329.566925,227.530334 330.381470,227.748306 330.908813,228.721924 
	C330.649994,231.604919 330.534790,234.267166 329.894196,236.796326 
	C329.067719,240.059418 326.413818,240.904373 323.400452,240.545258 
	C322.270416,240.410599 321.243713,239.604614 321.150543,238.235016 
	C321.052765,236.797562 321.864532,236.167496 323.229706,236.034653 
	C327.779602,235.591904 327.175873,233.788605 324.692474,231.091782 
	C323.275970,229.553543 320.871277,227.873840 323.714813,225.156769 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M391.681488,124.965317 
	C395.178192,125.164795 395.787506,126.548340 393.870789,129.044830 
	C393.376404,129.688766 392.822113,130.527283 392.132294,130.735062 
	C390.132019,131.337570 388.714783,135.980179 386.421082,132.617554 
	C384.720062,130.123840 383.294983,125.964073 388.639343,124.050766 
	C389.788055,124.309746 390.576141,124.621323 391.681488,124.965317 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M317.065002,166.334137 
	C320.064117,166.818481 323.426788,168.353531 323.129456,162.700699 
	C323.053772,161.262161 323.685394,159.332794 325.395477,159.311844 
	C327.789948,159.282486 329.035126,161.237625 329.952545,163.650223 
	C327.000519,163.828552 325.823242,165.382156 326.001556,168.610519 
	C322.159485,173.216446 322.159485,173.216446 317.065002,166.334137 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M281.998047,303.610657 
	C281.424072,304.820251 280.846954,305.640503 280.134888,306.730988 
	C278.009430,308.069366 275.936707,308.448273 273.861542,307.368530 
	C272.337494,306.575562 269.984161,306.296997 270.187866,303.953583 
	C270.452881,300.905212 272.824066,303.449097 274.053162,302.778656 
	C275.344238,302.074371 276.638763,301.374664 277.951721,300.712463 
	C280.648346,299.352356 282.024292,300.165680 281.998047,303.610657 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M355.008606,217.371643 
	C356.383667,215.932953 356.002197,213.365021 358.660797,213.046814 
	C360.103882,214.379639 361.070984,215.905624 362.348450,217.099533 
	C363.747925,218.407516 364.411255,219.604523 363.099548,221.284286 
	C361.781036,222.972733 360.229645,223.212967 358.358948,222.172150 
	C356.566864,221.175064 355.049713,220.037354 355.008606,217.371643 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M287.410278,248.003113 
	C288.976288,242.192032 290.833923,241.292145 298.709106,242.734634 
	C298.783051,243.249603 298.466919,243.464661 298.363861,243.755249 
	C296.447723,249.158844 292.571899,249.659485 287.410278,248.003113 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M345.000000,218.399994 
	C346.965302,214.954422 349.752319,215.941360 352.724213,216.741119 
	C350.994965,219.628204 352.157318,223.088455 350.337097,225.926880 
	C348.526886,224.773514 347.053772,223.547028 345.290344,222.160278 
	C345.000000,220.933334 345.000000,219.866653 345.000000,218.399994 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M427.432068,136.678528 
	C425.220856,139.268784 421.562988,138.623917 419.629791,141.337708 
	C418.990814,142.234741 417.381836,141.253387 417.465515,140.285294 
	C417.723297,137.303604 419.404358,134.902374 422.037903,133.619186 
	C424.723663,132.310547 426.905060,133.087708 427.432068,136.678528 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M351.362274,263.906555 
	C351.000000,264.000000 350.500000,264.000000 350.250000,264.000000 
	C349.967804,262.544525 349.037659,261.585052 348.150330,260.585358 
	C346.907440,259.185089 346.546997,257.565796 347.783752,256.077209 
	C348.994629,254.619766 350.853302,254.726257 352.419312,255.234848 
	C354.764313,255.996429 353.904480,257.872467 353.328857,259.374298 
	C352.765137,260.845093 353.665344,262.854492 351.362274,263.906555 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M302.066345,253.665268 
	C298.375916,251.880722 294.529694,254.788895 290.885193,253.740936 
	C290.818665,253.204987 290.684784,252.659210 290.756897,252.630463 
	C293.858704,251.394501 296.925232,250.016876 300.123535,249.102753 
	C301.370728,248.746323 303.425079,248.270523 304.226074,249.820541 
	C305.147583,251.603577 302.322906,251.817612 302.066345,253.665268 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M359.205505,194.946838 
	C360.882904,197.393829 362.828430,199.438446 361.672638,202.403137 
	C361.258118,203.466339 360.620667,205.166107 359.490448,204.624222 
	C356.824219,203.345871 356.980530,200.236633 356.260101,197.837143 
	C355.727020,196.061584 356.060394,194.041885 359.205505,194.946838 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M234.788300,362.735046 
	C232.014923,362.138519 230.536331,360.735352 231.530060,358.107727 
	C232.005112,356.851532 231.338547,354.692139 233.428223,354.526672 
	C234.458176,354.445099 235.753677,355.056152 236.585892,355.759674 
	C237.843979,356.823273 238.014328,358.338837 237.072311,359.811340 
	C236.454605,360.776917 235.784378,361.708893 234.788300,362.735046 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M370.879700,212.790833 
	C372.412384,218.735565 372.200653,218.984558 366.367157,218.007782 
	C365.038177,216.380478 364.913330,214.601151 365.000000,212.390640 
	C366.299713,210.893265 367.700104,210.564590 369.628723,211.724640 
	C370.304535,212.126801 370.557648,212.320679 370.879700,212.790833 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M369.858429,225.307434 
	C371.424286,225.300323 372.848541,225.602493 374.635864,225.952972 
	C376.138763,226.389252 376.624359,227.176941 376.223755,228.685364 
	C376.001221,229.000610 376.000183,229.499710 375.999481,229.749237 
	C375.377899,230.153793 374.741180,230.263275 374.139221,230.472672 
	C372.563751,231.020706 371.136597,232.547058 369.326965,230.795761 
	C367.299988,228.834152 369.031769,227.296432 369.858429,225.307434 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M390.000000,149.000000 
	C390.903503,145.866211 389.685394,142.800354 389.656006,139.556595 
	C391.176331,142.124374 395.741241,142.113907 394.923065,146.370728 
	C394.464508,148.756393 392.740723,149.011307 390.400452,148.998413 
	C390.000000,149.000610 390.000000,149.000000 390.000000,149.000000 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M347.999146,234.582794 
	C346.439728,234.828598 344.443207,235.650833 343.466553,234.077179 
	C342.401367,232.360840 341.032837,230.119324 342.711517,228.229736 
	C344.895538,225.771332 345.845581,229.101318 347.703705,229.843307 
	C347.999847,231.388733 347.999664,232.777466 347.999146,234.582794 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M374.842712,149.323212 
	C376.152161,148.744339 377.236755,148.130478 378.306885,148.154709 
	C380.121552,148.195816 381.289001,149.419708 381.778595,151.141037 
	C382.057007,152.119858 381.991699,153.178955 380.883698,153.661896 
	C379.263458,154.368103 377.969940,153.564972 376.961121,152.411591 
	C376.156067,151.491150 374.428558,151.306519 374.842712,149.323212 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M341.590790,247.891510 
	C339.767365,244.622772 342.392700,243.754059 344.337128,243.326065 
	C346.424133,242.866684 347.157349,244.820099 346.912506,246.585754 
	C346.353394,250.618500 343.510101,247.129089 341.590790,247.891510 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M413.000732,132.390808 
	C413.126038,131.695404 413.320221,131.442123 413.790649,131.119690 
	C415.508270,131.189911 417.607391,130.443130 417.828247,132.900864 
	C417.979034,134.578751 417.463745,136.555878 415.538025,136.674454 
	C413.022064,136.829407 413.066223,134.611298 413.000732,132.390808 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M280.982666,294.621155 
	C277.244720,296.964600 275.134613,295.418793 274.012970,291.366669 
	C276.449921,291.805634 279.791595,290.697174 280.982666,294.621155 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M250.888397,329.749268 
	C251.159607,327.939667 251.660522,326.643250 253.429703,327.455139 
	C255.662003,328.479523 254.164124,330.252380 254.013077,331.727417 
	C253.884430,332.983673 253.397308,334.123230 251.897812,333.683563 
	C249.922226,333.104309 250.242004,331.588776 250.888397,329.749268 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M368.666077,176.844742 
	C369.000824,177.444321 369.000427,177.888626 369.000000,178.666168 
	C368.504761,178.998764 368.008850,179.014709 367.514404,178.994690 
	C365.221710,178.901825 362.882172,178.874329 361.384674,176.726974 
	C361.237274,176.515610 361.551331,175.664078 361.856628,175.505737 
	C364.287720,174.244934 366.277954,175.689362 368.666077,176.844742 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M246.799683,297.177734 
	C248.591141,298.160248 249.791183,299.359985 247.851944,300.637543 
	C246.625824,301.445312 244.564453,301.399323 243.834167,299.884705 
	C242.640656,297.409332 244.678879,297.223663 246.799683,297.177734 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M254.991287,341.657959 
	C255.046997,344.480591 254.140686,345.557800 251.318832,344.140869 
	C250.906418,342.048340 251.448959,340.319916 253.374466,339.589142 
	C254.796295,339.049530 254.833878,340.363831 254.991287,341.657959 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M314.953949,245.947113 
	C313.177979,245.531662 311.572510,245.329544 311.287689,243.501236 
	C311.176239,242.785980 311.824524,242.117126 312.479156,242.256699 
	C314.360199,242.657761 316.259583,243.438110 317.206146,245.106720 
	C317.600677,245.802200 316.075500,245.849869 314.953949,245.947113 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M272.692566,286.178162 
	C274.016937,286.481720 274.736938,287.208130 274.965820,288.682007 
	C274.687561,289.471252 274.373932,289.942505 274.030151,290.706268 
	C274.000000,290.998779 274.000000,291.000000 273.999390,291.000000 
	C269.991974,290.658997 270.422577,288.775208 272.692566,286.178162 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M269.257904,310.975830 
	C267.384705,312.073547 266.379120,311.437439 266.460480,309.728851 
	C266.515717,308.568329 267.607605,307.349487 268.738586,307.573486 
	C270.661285,307.954315 269.887939,309.493713 269.257904,310.975830 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M396.409058,150.561920 
	C397.803528,148.901886 398.878418,149.123932 399.626953,150.704193 
	C400.021729,151.537582 399.768951,152.432327 398.821350,152.707520 
	C397.308777,153.146820 396.149872,152.838699 396.409058,150.561920 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M250.633392,323.631409 
	C248.840271,322.001984 249.399796,321.240875 251.226822,321.075775 
	C252.174576,320.990112 253.440582,320.972015 253.581497,322.170837 
	C253.825821,324.249207 252.233292,323.880127 250.633392,323.631409 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M257.609924,331.377777 
	C256.983368,329.521027 257.529755,328.452484 259.261902,328.468964 
	C259.711578,328.473236 260.532562,329.303650 260.531647,329.753357 
	C260.528259,331.478729 259.463593,332.028564 257.609924,331.377777 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M369.949646,211.651581 
	C368.611115,212.000000 367.222198,212.000000 365.416656,212.000000 
	C366.154968,208.406067 367.730896,207.771790 369.949646,211.651581 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M234.480240,351.013275 
	C236.364517,349.527161 237.255295,350.536469 237.747437,352.163086 
	C237.853699,352.514374 237.521027,353.349091 237.275467,353.399384 
	C235.648331,353.732727 234.996704,352.551514 234.480240,351.013275 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M281.000000,294.999390 
	C282.412262,295.122742 284.081665,294.825989 284.426056,296.831665 
	C284.471863,297.098480 283.922180,297.681458 283.564331,297.767029 
	C281.918060,298.160614 281.128662,297.340729 281.013977,295.365601 
	C280.998779,295.000000 281.000000,295.000000 281.000000,294.999390 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M292.222351,300.690125 
	C291.518616,302.017792 290.791107,302.736694 289.316925,302.964966 
	C288.978302,301.236938 289.204071,299.619019 291.648376,299.050171 
	C292.000000,298.998779 292.000000,299.000000 292.000610,299.000000 
	C292.378906,299.386353 292.525848,299.846466 292.222351,300.690125 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M331.332336,229.001312 
	C330.113251,228.893494 329.355682,228.609421 329.054016,227.312531 
	C330.110504,226.421371 331.225800,225.909927 332.719482,226.877563 
	C333.000854,227.444580 333.000488,227.889145 333.000061,228.667175 
	C332.555298,229.000671 332.110565,229.000732 331.332336,229.001312 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M368.755432,197.235413 
	C370.104279,197.856140 369.980408,199.087006 369.999512,200.637207 
	C367.781616,200.550217 367.724426,199.188538 368.755432,197.235413 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M380.167725,143.497375 
	C380.264374,145.202835 379.215515,144.979507 378.303558,144.723846 
	C378.033173,144.648041 377.659760,143.840149 377.772614,143.682922 
	C378.322998,142.916245 379.154205,143.060913 380.167725,143.497375 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M236.294922,342.124939 
	C235.999786,341.555817 236.000198,341.111633 236.000916,340.334351 
	C237.157883,340.149841 238.819153,339.761139 238.700485,341.640350 
	C238.609146,343.086761 237.456100,342.559967 236.294922,342.124939 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M241.185455,339.589905 
	C241.326248,338.742584 241.422302,337.861176 241.797043,337.720154 
	C242.610168,337.414093 242.873901,338.239319 242.848114,338.856537 
	C242.808273,339.809296 242.719666,340.973267 241.185455,339.589905 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M279.755554,316.221863 
	C281.384155,317.176788 280.482666,317.956665 279.263184,318.863495 
	C279.170380,318.147919 279.340759,317.295837 279.755554,316.221863 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M358.960968,209.720032 
	C358.454498,209.819717 357.909027,209.639450 357.181763,209.229584 
	C357.896515,208.029205 358.519073,208.254913 358.960968,209.720032 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M340.413025,180.658707 
	C340.611786,181.373245 340.362366,181.633209 339.807068,181.521622 
	C339.691833,181.498474 339.537170,181.334656 339.519928,181.218033 
	C339.437012,180.657288 339.710052,180.422775 340.413025,180.658707 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M379.658569,131.586823 
	C380.373291,131.388016 380.633423,131.637451 380.521790,132.192947 
	C380.498596,132.308243 380.334778,132.463028 380.218140,132.480255 
	C379.657288,132.563141 379.422607,132.289993 379.658569,131.586823 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M255.586914,336.341431 
	C255.388138,335.626831 255.637466,335.366638 256.192932,335.478302 
	C256.308167,335.501465 256.462860,335.665314 256.480133,335.781921 
	C256.563080,336.342773 256.289978,336.577332 255.586914,336.341431 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M246.533905,315.044067 
	C246.175888,315.694031 245.816467,315.703033 245.499512,315.236755 
	C245.433197,315.139252 245.436127,314.913574 245.505432,314.818756 
	C245.836868,314.365295 246.195953,314.382141 246.533905,315.044067 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M305.181946,243.203644 
	C305.471619,242.686249 305.943237,242.373093 306.707428,242.029968 
	C306.801392,242.872253 306.796509,243.969666 305.181946,243.203644 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M283.332520,289.000000 
	C283.106171,288.371887 283.171967,287.618591 283.957336,287.578552 
	C284.257996,287.563232 284.588287,288.130127 284.953156,288.715881 
	C284.556244,289.000000 284.111237,289.000000 283.332520,289.000000 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M313.666687,273.000000 
	C313.893219,273.627960 313.827728,274.380829 313.042633,274.420929 
	C312.742279,274.436340 312.412109,273.869568 312.047791,273.284058 
	C312.444458,273.000000 312.888885,273.000000 313.666687,273.000000 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M281.337494,397.287598 
	C281.008972,396.850739 281.006958,396.680573 281.002502,396.255219 
	C280.512726,392.319214 282.161102,390.668945 286.476013,391.711243 
	C290.610931,392.710052 294.531952,392.424133 298.645782,392.030975 
	C304.039429,391.515503 308.680634,388.336182 314.373901,387.883484 
	C314.868225,387.525665 315.084595,387.411163 315.654480,387.206726 
	C317.678802,386.976624 318.780182,387.875031 319.807037,389.060852 
	C324.307953,394.258423 327.734467,394.745117 334.334320,391.194885 
	C334.524139,390.838562 334.425812,390.546143 334.008087,390.436493 
	C331.576477,389.798309 328.692261,389.374359 329.562012,385.834198 
	C330.303772,382.815002 332.921600,382.486206 335.554688,382.709717 
	C337.540009,382.878204 339.490234,383.285370 341.644653,384.255737 
	C342.781433,385.038849 343.201202,385.967194 343.805511,386.752930 
	C344.878571,388.148132 344.755859,391.257446 346.654602,390.945435 
	C349.392822,390.495422 353.375916,390.931610 354.573792,389.699127 
	C356.556854,387.658661 359.200592,386.022980 360.593384,383.858490 
	C361.753174,382.056061 359.864685,378.789856 358.764038,376.405609 
	C355.896362,370.193481 356.094299,369.519562 363.196228,366.220398 
	C364.739410,365.705566 365.879974,365.266846 365.227966,363.260010 
	C364.933258,361.112274 366.440430,360.163239 367.279480,358.514832 
	C367.555786,356.568848 367.960815,355.005310 368.730957,353.181946 
	C372.040924,349.651917 369.765320,346.843811 367.270447,345.689514 
	C363.298615,343.851929 361.308228,340.744843 359.315063,336.950775 
	C360.722351,333.368561 366.835236,329.526886 369.796417,329.974731 
	C373.234985,330.494781 377.908173,336.048157 378.378967,340.631042 
	C378.720612,343.956726 379.118774,347.202911 380.863190,350.456085 
	C382.239197,348.310852 381.459808,346.500458 380.597168,344.644958 
	C379.229279,341.702789 379.331848,339.035797 382.708252,337.617157 
	C385.689148,336.364655 384.248932,333.560730 385.314117,331.281433 
	C385.790863,330.594086 386.151123,330.284241 386.881531,329.882050 
	C389.879456,329.248169 391.529144,326.892792 394.009491,325.999908 
	C395.135803,325.594482 396.938293,325.423004 396.759308,323.736908 
	C396.587708,322.119995 394.900970,321.630768 393.591064,320.999451 
	C392.064209,320.263550 390.744690,320.989746 389.085510,321.762054 
	C388.034760,322.063416 387.317535,322.057526 386.259277,321.782562 
	C382.973175,317.673126 384.267853,312.832794 389.143402,310.341949 
	C391.346954,309.216217 393.773285,308.534637 396.075073,307.580170 
	C398.857697,306.426239 400.565979,307.735443 400.654327,310.832672 
	C400.673279,311.496002 400.396179,312.171021 400.483337,312.817841 
	C400.840546,315.467896 402.132263,318.197021 404.819916,317.950989 
	C407.434784,317.711609 407.269714,314.697754 406.736969,312.583038 
	C406.288940,310.804688 407.428558,308.399017 404.597839,307.165039 
	C403.175140,306.336029 402.371063,305.405304 401.685730,303.910339 
	C399.227417,298.787964 400.637604,287.248047 403.970520,283.826691 
	C406.213501,281.524200 413.045197,282.069092 415.402313,284.845917 
	C417.919128,287.810852 419.180786,291.230957 417.741882,295.042664 
	C416.440552,298.490021 417.793518,299.579468 421.039978,299.570648 
	C422.199188,299.567474 423.340668,299.956543 424.500732,300.051788 
	C426.297211,300.199341 428.421082,300.806152 429.567535,298.989716 
	C430.791962,297.049683 430.430267,294.811432 429.379852,292.781586 
	C428.842041,291.742279 428.005798,290.903687 427.276703,289.994171 
	C425.893311,288.268372 424.446503,286.453094 425.964600,284.170959 
	C427.572418,281.753937 429.901642,281.325195 432.568298,282.299194 
	C433.113556,282.498413 433.592499,283.159515 434.276398,282.674896 
	C433.129059,279.800995 431.474274,278.656311 428.096832,279.594696 
	C424.194672,280.678833 422.682190,279.194458 422.493134,274.943848 
	C422.330322,271.283966 423.195526,267.667297 423.061615,263.984131 
	C422.920837,260.112518 427.131622,260.712769 428.917297,258.346802 
	C429.184021,254.731689 426.311554,252.788803 425.504089,249.947052 
	C425.278412,249.152832 424.829224,248.252426 425.695923,247.725586 
	C426.087952,247.487305 426.858490,247.882919 427.459656,247.975723 
	C430.978180,248.518829 433.548431,250.843872 436.682129,252.854416 
	C439.295654,255.228622 438.170013,257.903137 437.917389,260.870605 
	C441.305817,259.910004 444.618256,259.585510 448.041779,261.537842 
	C448.788788,262.415985 448.977142,263.143005 448.979370,264.307373 
	C448.781464,265.462006 448.434967,266.145386 447.745667,267.064575 
	C445.589325,268.898865 443.295593,269.155212 440.954987,269.299133 
	C439.614960,269.381561 438.093842,269.490479 437.854279,271.157043 
	C437.651917,272.564728 438.870209,273.229889 440.023773,273.456268 
	C442.916992,274.024017 444.417084,277.940063 447.201660,277.355133 
	C449.678223,276.834930 450.199188,273.285126 451.989014,271.353302 
	C453.272491,269.967957 455.011444,269.357422 456.450928,268.190216 
	C456.256744,266.396851 454.532776,266.706360 453.226013,265.876892 
	C452.042664,265.205444 451.360596,264.498932 450.502686,263.442444 
	C450.052490,262.702118 449.859802,262.249695 449.644012,261.424133 
	C448.784332,258.686310 448.294891,255.914551 444.630188,257.814606 
	C442.895142,258.714172 440.995331,259.196350 440.126862,256.767944 
	C439.194183,254.159836 439.619202,251.153229 442.445709,250.641937 
	C446.158203,249.970383 447.705841,248.093933 449.260834,244.930405 
	C450.840118,241.717453 456.371399,241.410477 461.753113,243.541016 
	C466.495361,244.696396 468.198700,248.869202 471.377289,251.673523 
	C469.847656,248.420959 467.338318,245.241913 471.867065,242.132935 
	C476.218384,240.849823 478.789154,238.835220 480.224731,234.343658 
	C481.867218,229.204559 487.593994,227.629181 491.977570,225.303680 
	C494.292053,224.075851 499.100281,228.815689 500.347839,233.170837 
	C501.054718,235.638580 501.691650,237.962143 504.608063,239.266800 
	C506.107544,241.689590 504.849335,243.062897 503.111176,243.903122 
	C500.198212,245.311249 501.525848,246.463333 503.065643,247.839706 
	C504.991119,249.560806 507.272339,249.547852 509.643311,249.513702 
	C512.956177,249.465988 516.253723,249.598938 516.088928,254.313080 
	C516.064697,255.004349 516.318176,255.737427 516.884033,256.069794 
	C520.633362,258.272125 520.252075,261.812622 520.045288,265.676819 
	C520.419983,270.271576 516.941162,271.479706 514.197693,273.106384 
	C512.469971,274.130859 511.435303,273.002625 511.148041,271.198364 
	C510.879944,269.514557 511.884399,268.010254 511.877075,266.057770 
	C510.242737,266.552429 509.808868,268.073822 508.873383,269.020264 
	C507.616058,270.292267 505.984436,271.419037 504.584717,269.965393 
	C501.977325,267.257629 500.258026,268.565582 498.111328,270.490601 
	C496.669464,271.783569 494.416748,271.878815 492.608917,273.434814 
	C489.369019,274.732880 486.196991,275.189148 483.445129,276.871399 
	C481.606506,277.995392 479.720459,277.892822 477.762634,276.876923 
	C475.386658,275.644073 473.086884,275.627838 470.913818,278.131134 
	C472.849548,279.351746 473.872498,277.733246 475.095062,277.349304 
	C476.633881,276.866028 478.131805,276.742096 479.228027,278.061493 
	C480.175507,279.201813 480.104492,280.729675 479.215210,281.786499 
	C477.497284,283.828064 477.490387,286.911896 474.728333,288.638794 
	C473.381256,289.358704 472.266968,289.733276 470.869019,290.327515 
	C467.637299,292.022644 464.897705,293.735992 462.842590,296.477509 
	C461.487549,298.285126 459.507080,299.376129 457.190704,298.006165 
	C450.342255,293.955811 443.621460,295.849579 436.708618,298.594971 
	C436.355682,299.301392 436.166382,299.717834 435.793976,300.437500 
	C435.037720,301.587677 434.478851,302.455200 435.328278,303.426727 
	C436.137573,304.352325 437.130157,304.177460 438.023682,303.615509 
	C439.137451,302.915100 439.917694,301.712769 441.262451,301.352509 
	C443.517395,300.748413 445.237732,301.547546 446.207306,303.612030 
	C447.175903,305.674408 445.809082,306.769653 444.136658,307.391418 
	C441.423920,308.400055 439.830414,310.393494 438.735352,312.928955 
	C438.537842,313.386292 438.378784,313.916443 438.034119,314.243103 
	C433.611237,318.434692 429.476593,322.749847 429.585266,329.485382 
	C429.597229,330.227386 428.724365,330.855591 428.092468,331.414185 
	C426.753143,332.598083 423.896057,332.501556 424.672119,335.484711 
	C425.482391,338.599457 426.378815,341.616638 429.865540,342.865082 
	C431.580048,343.479004 433.388062,343.922180 434.855133,345.084778 
	C436.246033,346.186951 437.246826,347.548065 436.432159,349.381775 
	C435.665619,351.107178 434.010010,351.117004 432.563782,350.617859 
	C429.255432,349.475952 425.566620,349.995300 422.122620,348.021729 
	C416.324219,345.609619 411.800415,346.898590 408.717438,351.603455 
	C407.990784,352.712341 407.278870,353.828979 406.399109,354.821747 
	C404.642273,356.804230 404.625641,358.680908 406.789948,360.339264 
	C409.045074,362.067261 410.084534,364.409576 410.481689,367.479675 
	C411.540344,372.339203 408.812317,376.148804 408.118622,380.340454 
	C407.932922,381.462585 406.801270,382.221680 405.653992,382.654480 
	C400.736084,384.509827 392.493774,379.766846 391.805695,374.670105 
	C390.716797,374.546082 390.323517,375.471405 390.084534,376.137665 
	C389.573212,377.563171 386.457977,378.280457 388.201965,380.030731 
	C389.996246,381.831573 390.969574,385.378174 394.761047,384.393799 
	C396.693176,383.892212 399.057648,383.122101 400.223816,385.240387 
	C401.519440,387.593994 401.292267,390.447754 400.652740,393.038574 
	C400.177460,394.964081 398.416260,394.830322 396.930664,394.351715 
	C393.429382,393.223755 390.758362,394.370178 388.535553,397.085297 
	C387.789032,397.997131 386.859924,399.036316 385.695648,398.971863 
	C380.025391,398.657867 374.535309,400.540985 368.958801,400.430573 
	C363.123322,400.315033 361.680542,401.004150 360.368286,407.099976 
	C354.250244,410.475006 349.062744,408.007965 343.963409,405.238190 
	C342.822235,404.618378 341.856873,403.682159 340.093445,403.504913 
	C338.890350,403.470642 338.124634,403.271729 337.036255,402.766907 
	C335.713104,401.759888 335.075470,400.596649 333.553589,400.410889 
	C327.728058,399.699860 322.074036,397.956207 315.824829,397.449524 
	C314.933746,397.430023 314.426697,397.404602 313.549469,397.303406 
	C310.756622,396.148132 308.441376,396.263031 305.890991,397.977661 
	C300.805237,397.745667 296.066345,397.034851 291.059845,398.945435 
	C287.689148,398.363708 284.500946,399.023712 281.337494,397.287598 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M404.735229,306.195770 
	C410.577881,307.036652 407.355316,311.966980 408.698944,314.893738 
	C409.611969,316.882568 407.697266,319.275574 405.549652,320.546051 
	C404.377502,321.239532 402.989807,320.871246 402.562347,319.715973 
	C401.565247,317.021362 397.313324,316.086090 398.510803,312.344025 
	C398.933990,311.021545 400.626343,309.800751 398.673615,308.547577 
	C396.831940,307.365570 396.251953,309.572632 395.042908,310.095398 
	C392.020935,311.402130 388.338074,311.074921 386.441223,314.872253 
	C385.477295,316.801941 384.615417,318.316010 385.852173,320.667664 
	C385.196442,324.077393 382.939941,322.331268 380.887543,322.363922 
	C379.430847,322.014221 378.343872,321.494720 376.836304,322.136353 
	C374.890411,322.537994 373.246490,322.332092 371.642365,322.583466 
	C365.534271,323.540649 363.972687,322.034668 364.767273,315.448120 
	C365.149933,314.708923 365.419983,314.321106 365.958038,313.701660 
	C367.471008,312.494720 368.272797,311.132355 369.450867,309.653442 
	C371.875549,307.971863 370.619629,306.486511 369.521790,304.738434 
	C368.230011,303.552429 366.807526,303.700043 365.579468,303.250671 
	C363.756958,302.583679 362.159668,301.646423 362.518402,299.394257 
	C362.840973,297.369171 364.326569,296.342590 366.283112,295.983337 
	C367.921783,295.682526 369.581146,296.203186 371.593964,295.807983 
	C372.960419,295.602692 373.956757,295.616119 375.308716,295.717224 
	C376.309174,295.854279 376.942749,296.020966 377.586273,296.133514 
	C382.912964,297.065674 382.913330,297.063629 383.882568,291.459351 
	C384.185455,290.432281 384.547821,289.814789 385.089661,288.884491 
	C387.279205,286.640198 388.680969,283.670990 392.614563,284.680298 
	C393.531433,285.022827 394.134186,285.235870 395.107239,285.177002 
	C396.937927,284.715881 399.048370,285.705536 399.842438,283.006592 
	C401.418732,280.159393 399.276184,277.413025 400.599121,274.558472 
	C401.357147,273.637573 402.027313,273.032837 402.841461,272.944946 
	C406.614532,272.537598 408.349548,270.843811 407.777130,266.754578 
	C407.356476,263.749481 410.391510,261.564850 410.762390,258.272827 
	C411.496124,257.742249 411.992249,257.484497 412.744202,257.113373 
	C417.908386,259.861572 423.247681,257.574646 428.668091,258.141785 
	C430.716553,260.633423 428.294647,260.648987 426.914978,261.142334 
	C424.641724,261.955261 423.704987,263.144104 424.666138,265.658569 
	C425.803345,268.633636 423.945099,271.488007 423.885986,274.487030 
	C423.801971,278.746582 425.316406,279.360840 428.916656,277.952393 
	C430.341248,277.395081 433.065399,275.650513 433.204559,276.850677 
	C433.561310,279.928009 437.254395,280.866486 436.954803,283.963623 
	C436.854126,285.004608 436.618805,285.999420 435.574799,286.426331 
	C434.392426,286.909729 433.648651,286.147339 433.243927,285.181122 
	C431.906250,281.987701 429.652374,282.518188 427.628357,284.064087 
	C424.731934,286.276306 427.419586,287.694733 428.725159,289.217194 
	C429.898560,290.585510 431.472504,291.602692 431.923706,293.560516 
	C433.457794,300.217102 430.846558,302.994598 424.079010,301.810150 
	C423.915833,301.781586 423.742737,301.759247 423.597198,301.687469 
	C420.799530,300.307617 416.749084,302.408020 415.053101,300.276917 
	C413.423096,298.228699 416.584229,295.268494 416.915649,292.505859 
	C417.321075,289.126373 415.350769,286.537659 413.248352,284.703796 
	C411.274017,282.981689 408.135010,284.048767 405.503235,284.184692 
	C405.146301,284.203156 404.481171,284.858917 404.528656,285.113007 
	C405.253265,288.988922 402.071167,291.516052 401.251648,294.835419 
	C400.581665,297.549042 400.904907,300.093079 402.918945,302.636108 
	C402.513733,304.097809 400.124634,304.117432 400.990662,305.761688 
	C401.582123,306.884705 403.098724,307.309479 404.735229,306.195770 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M462.254211,244.763611 
	C459.188263,244.518265 456.785950,242.442795 453.468689,243.804581 
	C450.725677,244.930603 448.485474,245.641357 449.557922,249.255798 
	C450.316467,251.812302 448.716309,252.235321 446.823395,251.720337 
	C445.146820,251.264160 443.628113,250.886505 442.141632,252.083817 
	C440.566467,253.352509 440.808014,255.104156 441.246277,256.649017 
	C441.637390,258.027863 442.667175,257.425507 443.592896,256.870544 
	C448.697784,253.810410 449.191589,254.050385 450.991516,260.628967 
	C450.335236,261.666351 449.693268,262.374939 448.311981,262.160767 
	C444.556488,262.441284 440.584320,259.643250 437.689423,263.709137 
	C437.506561,263.965942 436.377045,263.815399 435.883148,263.523743 
	C435.073700,263.045746 434.966888,262.060303 435.511261,261.381805 
	C437.336853,259.106293 436.985077,256.459900 437.000000,253.431168 
	C442.380920,250.118378 443.333923,243.936615 446.953979,239.352875 
	C448.653046,237.560059 450.570129,237.995605 452.791138,238.333801 
	C454.387024,239.040222 455.615082,239.877899 457.487518,238.862228 
	C456.303131,237.639328 454.728485,238.360001 453.373688,237.542053 
	C452.223114,233.563629 457.090424,233.453232 457.754852,230.240479 
	C459.863586,229.034500 463.780396,228.924591 459.847382,225.524734 
	C459.645050,225.349823 459.698975,224.421005 459.907074,224.295074 
	C466.304291,220.423325 463.005035,214.055527 463.351868,208.949768 
	C463.625366,204.924088 466.780945,202.269791 466.659973,198.107498 
	C466.609253,196.362686 470.478760,196.453842 471.622345,198.783127 
	C472.856537,201.296967 474.240173,203.206451 476.973419,204.192825 
	C479.810181,205.216568 478.471222,206.896744 477.153564,208.706421 
	C475.955627,210.296692 474.670776,211.455368 474.519348,213.608932 
	C474.220856,216.618179 475.234344,219.120453 475.778107,221.652832 
	C475.999939,222.685974 475.980682,225.213928 478.727112,223.970428 
	C479.997589,220.664520 478.482819,216.960022 481.686523,214.220184 
	C485.756622,213.282776 488.880402,215.032745 492.186371,216.960510 
	C491.403107,214.237015 488.541382,212.075958 491.735077,209.219757 
	C492.396759,208.635864 492.862061,208.415482 493.791046,208.364014 
	C495.512787,208.783249 496.558685,209.582687 497.659180,210.952667 
	C498.738800,213.288940 498.691956,215.359085 498.264984,217.804474 
	C498.210693,219.529007 495.717865,220.360580 497.605652,222.260712 
	C500.660522,225.082031 504.545624,222.659225 507.749939,224.766937 
	C509.167297,225.164673 510.334625,225.329330 511.750977,225.746689 
	C513.130188,227.216721 513.830811,228.568878 513.173889,230.646027 
	C512.056152,232.876938 510.613129,234.700256 512.681152,236.935455 
	C513.391846,237.389969 513.620117,237.859146 513.656982,238.808746 
	C512.280762,242.716782 509.637024,242.144211 506.545349,241.206146 
	C505.953369,240.914886 505.736633,240.760406 505.259949,240.302979 
	C501.199768,240.704605 499.023621,238.790222 499.181183,235.269287 
	C499.373016,230.982681 495.482697,229.682953 493.907898,226.750107 
	C493.427490,225.855377 491.717133,225.779083 491.264679,226.587509 
	C488.516693,231.498001 480.075409,231.021240 480.279633,239.032272 
	C480.389648,243.348251 475.406586,241.743210 472.403717,242.987213 
	C469.097443,240.714447 471.749146,236.934250 469.381622,233.736435 
	C466.836304,237.461166 465.760468,241.767731 462.254211,244.763611 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M496.961945,219.343781 
	C497.000000,216.629440 497.000000,214.258896 497.000000,211.444168 
	C496.115723,210.339844 497.470428,207.241501 494.287079,208.886185 
	C493.555542,209.000000 493.111115,209.000000 492.333313,209.000000 
	C491.898560,207.282181 492.168152,205.245743 493.474396,204.469162 
	C498.307251,201.595886 496.658539,198.515305 494.264709,195.185715 
	C494.009003,194.830093 494.091858,194.231049 494.008850,193.372772 
	C496.516754,193.606644 498.212921,196.161942 501.520294,195.283737 
	C503.558655,194.742493 507.229889,201.436203 506.654144,204.377975 
	C506.319092,206.089966 505.623962,207.791321 507.699036,208.624039 
	C509.792206,209.464020 510.408691,207.484421 511.501648,206.277374 
	C513.419800,204.159088 514.381409,202.158844 513.364258,199.113449 
	C512.725464,197.200806 511.723053,194.174805 514.871277,193.205200 
	C517.323608,192.449921 519.877747,192.668076 520.375671,196.248337 
	C520.516724,197.262589 521.534607,197.914917 522.661987,197.676727 
	C523.899170,197.415344 523.932434,196.305222 523.983398,195.306519 
	C524.194763,191.166824 526.582825,187.430832 526.095520,183.121078 
	C525.897583,181.370148 526.952209,179.791534 528.825012,179.483093 
	C530.508301,179.205887 530.866943,180.890808 531.485474,182.005203 
	C533.009644,184.751114 533.984863,187.476456 531.345398,190.208221 
	C529.338745,192.285080 529.880249,195.212784 529.144226,197.714828 
	C528.245972,200.768524 529.862183,202.509430 532.776672,203.735168 
	C534.435730,204.432877 533.687866,206.022049 532.230469,206.722519 
	C528.837097,208.353485 525.456177,209.682602 524.086548,213.920334 
	C523.543640,215.599945 521.110596,216.578598 519.096313,214.595642 
	C518.275024,213.787140 517.373596,212.561111 516.424744,212.477524 
	C513.886353,212.253906 511.303833,210.108795 508.826019,211.919830 
	C506.009857,213.978165 507.001709,216.882278 507.676208,219.680664 
	C508.020172,221.107834 507.906647,222.645264 507.999939,224.566422 
	C504.417419,224.304855 500.344391,227.699966 497.079041,223.350006 
	C496.189026,221.913910 496.598938,220.799759 496.961945,219.343781 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M508.143982,175.685806 
	C505.760406,175.942215 504.025818,176.633469 504.061401,179.217972 
	C504.096313,181.752487 503.447754,184.482758 500.788635,184.297501 
	C497.893921,184.095825 494.078156,185.581589 492.061310,181.372528 
	C492.811554,179.473633 495.008362,179.639191 495.928436,177.497025 
	C493.500732,177.867004 490.803619,176.728241 490.220886,180.740051 
	C489.194366,181.245987 488.388733,181.493225 487.290955,181.870239 
	C486.008392,182.824203 484.929291,183.074448 483.361816,182.253448 
	C481.282867,178.566803 478.142395,177.003738 474.925476,175.248703 
	C472.540924,173.947769 471.314972,173.957504 470.355682,176.443176 
	C469.706635,178.124924 468.817505,180.049133 466.990570,178.065521 
	C465.510895,176.458908 462.525909,174.364548 465.007599,171.786133 
	C467.404510,169.295746 469.966492,166.493103 474.388977,169.501526 
	C482.945221,175.321945 491.559753,170.936066 499.977142,168.580963 
	C502.150421,167.972885 502.330902,165.040405 502.674713,162.986633 
	C503.045685,160.770477 500.960907,160.381546 499.374084,160.084290 
	C497.411377,159.716644 496.298584,158.652557 496.423828,156.749298 
	C496.532013,155.105804 497.659576,154.155914 499.394501,153.920700 
	C500.700653,153.743607 501.964844,153.159164 503.267578,153.078873 
	C505.039673,152.969635 507.189789,152.434174 508.316559,154.277588 
	C509.505768,156.223190 509.488403,158.530197 508.019470,160.376144 
	C504.860687,164.345642 506.936554,166.272736 510.621429,167.738022 
	C513.390930,168.839294 516.209534,169.821335 517.247192,173.147919 
	C517.637695,174.399704 518.539734,175.660385 520.089539,175.533203 
	C521.823242,175.390900 521.842224,173.811279 522.077271,172.516983 
	C522.307861,171.246826 523.021240,170.427109 524.643188,170.055450 
	C523.872681,175.680634 519.918335,180.572433 520.463562,186.772903 
	C520.563110,187.904922 520.113281,190.022934 517.935303,189.694153 
	C516.124451,189.420807 514.916992,188.318436 515.030212,186.324677 
	C515.192017,183.475922 513.867371,182.218155 511.083954,181.744324 
	C509.172272,181.418884 508.086761,179.826416 508.010559,177.378967 
	C508.573425,176.407669 509.252411,175.960938 509.939667,175.515274 
	C510.577301,175.101761 510.734375,174.530838 510.214417,173.903748 
	C509.891235,173.513947 509.390625,173.133316 508.953430,173.285706 
	C507.919983,173.645889 508.450897,174.647964 508.143982,175.685806 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M398.000000,225.577774 
	C397.250000,226.000000 396.500000,226.000000 395.375000,226.000000 
	C392.288330,224.500809 388.994385,223.867676 387.366791,220.764664 
	C386.508698,219.128723 385.586609,217.533524 386.759247,215.307739 
	C387.000000,213.504745 387.207062,211.975113 386.961365,210.522003 
	C386.208740,206.070312 388.367035,204.061905 392.738464,203.258148 
	C394.492920,201.507339 395.975189,202.238052 397.376160,203.104828 
	C398.924469,204.062714 400.436523,204.955292 402.664825,204.052734 
	C404.520874,205.586777 408.208984,203.290573 408.364990,206.059479 
	C408.551117,209.362717 404.777740,208.528351 402.739197,209.526718 
	C402.485535,209.650970 402.357971,210.032761 402.085968,210.647308 
	C396.006622,213.707901 395.780426,214.303070 397.531067,220.307114 
	C397.977661,221.838638 397.859009,223.534943 398.000000,225.577774 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M442.309998,196.227936 
	C438.929138,193.848785 435.218231,193.549774 431.795746,192.409225 
	C430.804749,192.078979 429.422089,191.548615 429.550140,190.085342 
	C429.661682,188.810684 431.030273,188.943085 431.872894,188.558578 
	C433.868073,187.648087 435.456726,186.363159 436.987823,184.736465 
	C440.109070,181.420395 446.856110,182.335922 449.020782,186.291016 
	C449.845001,187.796951 451.091553,188.430069 452.207886,187.849289 
	C456.507416,185.612503 459.386963,189.184265 462.882141,190.148254 
	C463.862152,190.418564 464.957581,191.123749 464.337738,192.240936 
	C463.722992,193.348892 462.527405,192.812439 461.755890,192.148514 
	C458.428864,189.285339 455.723846,190.249985 453.158325,193.700623 
	C452.008301,194.821548 450.931763,195.087662 449.362030,194.254333 
	C448.194641,194.247772 447.389313,194.494293 446.291992,194.870728 
	C445.018005,195.820892 444.071716,196.724121 442.309998,196.227936 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M457.738495,229.786011 
	C460.595673,235.152145 454.832977,234.220001 453.171753,236.698456 
	C453.379150,237.383026 453.516022,237.839905 453.204163,238.684967 
	C451.232819,239.386673 449.472534,240.062714 447.344543,239.041351 
	C447.001740,238.555527 447.001007,238.111069 447.000153,237.333252 
	C446.764343,234.382416 446.585114,231.776276 448.746216,229.295822 
	C451.965912,227.106262 455.031616,223.727600 457.738495,229.786011 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M448.622986,229.053619 
	C449.178253,231.626251 448.208618,234.023376 447.258057,236.737732 
	C445.120850,234.475494 442.837463,232.722519 439.465515,232.993378 
	C435.916992,233.278412 435.484283,230.922089 436.197418,228.468323 
	C437.030609,225.601639 437.155182,223.329727 434.256897,221.623810 
	C433.616791,221.247040 432.976562,220.348587 433.802917,219.535461 
	C434.524292,218.825653 435.440491,219.156357 436.041382,219.736801 
	C437.583527,221.226547 439.532410,222.668594 439.854431,224.823044 
	C440.688721,230.404694 444.057495,230.417435 448.622986,229.053619 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M473.252686,214.275604 
	C473.144470,211.744705 472.199982,208.791687 476.599762,209.003250 
	C478.474915,210.566895 479.949860,212.133789 481.712402,213.850647 
	C481.440979,217.423111 480.881927,220.845596 480.161438,224.634048 
	C476.493378,225.178253 476.012054,222.378159 475.236023,220.010361 
	C474.642181,218.198547 474.775360,216.155945 473.252686,214.275604 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M486.917358,190.346863 
	C488.589417,189.973938 489.953949,190.240952 489.987244,192.634537 
	C488.292236,195.518951 490.976105,195.960953 492.013031,197.123016 
	C492.759430,197.959534 493.091248,198.793701 492.680573,199.816452 
	C492.213715,200.979172 491.180573,200.967773 490.164948,200.828705 
	C489.676331,200.761795 489.173859,200.638962 488.729950,200.430206 
	C486.821808,199.532898 484.501923,203.155640 483.524811,200.767563 
	C482.727661,198.819336 483.859711,196.088013 483.360321,193.564117 
	C482.953339,191.507370 486.353394,192.554749 486.917358,190.346863 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M449.049500,194.370087 
	C450.066376,194.000809 451.132721,194.000397 452.598938,194.000000 
	C453.277313,198.612442 453.542145,203.226913 457.626099,206.426605 
	C458.490753,207.104050 458.985443,207.978745 458.181213,208.953171 
	C457.081329,210.285843 455.507355,210.027374 454.036530,209.890823 
	C452.295715,209.729218 451.867279,208.421371 452.049622,207.033936 
	C452.641022,202.534119 450.958527,198.625336 449.049500,194.370087 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M482.932861,182.338425 
	C484.066376,182.000427 485.132721,182.000244 486.598938,182.000031 
	C486.999268,184.370407 486.999786,186.740799 487.000153,189.555298 
	C486.834869,190.000168 486.655212,190.044632 486.506805,189.995178 
	C484.163940,189.214569 481.018280,192.846008 479.442627,189.241058 
	C478.152740,186.289902 481.539124,184.804382 482.932861,182.338425 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M438.691467,204.355286 
	C440.538269,205.324905 441.624573,206.583893 440.447754,208.100372 
	C439.308228,209.568878 437.269470,209.422989 435.910645,208.452591 
	C434.566345,207.492554 433.681732,205.536621 434.454132,204.041687 
	C435.440674,202.132446 437.036591,203.752609 438.691467,204.355286 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M448.096802,209.539459 
	C449.209686,210.848007 449.248199,212.089035 448.374908,213.250351 
	C448.275360,213.382751 447.369324,213.193039 447.163727,212.913010 
	C446.195251,211.593628 445.658813,210.250397 448.096802,209.539459 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M442.056763,196.311279 
	C443.104004,195.695389 444.208710,195.388947 445.656738,195.041550 
	C445.260529,196.873627 444.576813,198.880417 442.056763,196.311279 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M404.011108,108.023102 
	C407.274445,108.027084 408.232422,109.681808 407.910034,112.245270 
	C407.687195,114.017166 406.777069,115.548409 404.831543,115.566338 
	C402.881287,115.584320 402.128815,114.007080 401.614105,112.308044 
	C400.984253,110.229027 400.407104,108.278831 404.011108,108.023102 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M407.030212,120.415482 
	C406.674438,124.168327 404.341553,123.000160 402.277191,122.367012 
	C401.950714,122.266876 401.612915,121.622261 401.596710,121.216743 
	C401.565948,120.447739 402.217682,120.113213 402.884735,120.100662 
	C404.166962,120.076538 405.451263,120.161942 407.030212,120.415482 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M381.618927,139.235504 
	C380.018890,138.052139 379.556671,136.925613 381.436768,136.231567 
	C382.608917,135.798859 384.345673,135.621552 384.647339,137.306534 
	C384.974457,139.133804 383.828430,139.971268 381.618927,139.235504 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M386.670288,214.936157 
	C387.552490,219.160675 388.870911,222.654160 393.793182,223.282349 
	C394.716980,223.400238 394.876129,224.401093 394.982544,225.620972 
	C393.739471,226.797836 392.506744,227.644592 391.211090,228.380630 
	C389.721222,229.227005 388.533203,230.088196 390.440948,231.661743 
	C391.284515,232.357559 391.963165,233.060165 391.079041,234.108063 
	C390.507050,234.786026 389.658386,234.973541 388.802643,234.733261 
	C385.600800,233.834229 380.479797,234.852173 382.889099,228.452988 
	C383.522736,226.770111 382.051117,225.365158 380.317017,224.173737 
	C379.954346,222.539642 380.311646,220.886078 379.118683,219.790955 
	C375.813721,216.757034 375.818176,212.860062 376.000000,208.437744 
	C376.905182,205.035477 376.948914,201.422546 381.627625,201.009277 
	C382.385468,202.875809 380.817719,203.907516 380.067139,205.660248 
	C381.214081,206.869736 380.758698,208.470154 381.771606,209.764465 
	C382.251923,212.689255 384.675964,213.442810 386.670288,214.936157 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M403.000793,203.750000 
	C401.945038,206.866501 400.319916,207.667862 397.890869,205.373871 
	C396.691895,204.241547 395.611420,202.931183 393.365173,203.007751 
	C390.467194,200.574142 391.593506,197.740311 392.423157,195.165802 
	C392.989471,193.408401 394.981140,194.032806 396.395233,194.056976 
	C398.134552,194.086685 399.624542,194.699463 398.166992,196.744125 
	C396.024445,199.749680 398.061340,200.815048 400.646576,201.950165 
	C401.631714,201.915665 402.187317,202.094803 402.840759,202.743073 
	C403.000153,203.166672 403.000275,203.333328 403.000793,203.750000 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M403.408630,202.999786 
	C402.454498,202.819565 401.908997,202.639145 401.181763,202.229065 
	C400.836731,200.211411 401.555878,198.798447 402.846558,197.620941 
	C403.955780,196.608963 404.479767,194.274673 406.644928,195.527298 
	C408.343964,196.510269 410.078735,197.828796 409.837463,200.129944 
	C409.596191,202.430710 407.627716,202.718964 405.793884,202.954712 
	C405.143829,203.038284 404.476624,202.988495 403.408630,202.999786 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M381.998474,209.629166 
	C379.980682,209.744186 380.004730,208.235321 380.001465,206.370850 
	C382.019012,206.256058 381.995178,207.764725 381.998474,209.629166 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1092.655029,893.761902 
	C1094.900635,896.301575 1098.324707,895.719849 1100.656494,897.929016 
	C1102.648315,898.601074 1104.159424,899.446716 1105.764893,900.770325 
	C1106.126465,901.302612 1106.320312,901.556335 1106.790771,901.879333 
	C1108.017456,903.068665 1109.032471,904.140442 1110.742676,904.816223 
	C1111.778564,908.688293 1115.436523,909.836487 1117.615967,912.285339 
	C1118.925171,913.756531 1120.665771,913.080139 1120.984375,910.370667 
	C1123.232910,908.794739 1123.614990,910.483459 1124.156250,912.360291 
	C1124.192505,913.921387 1124.127075,915.110779 1124.029541,916.650024 
	C1122.776978,920.504456 1120.457764,918.960205 1118.905151,917.711731 
	C1116.414307,915.708923 1114.827515,915.448425 1114.003906,918.954590 
	C1113.680786,920.330444 1113.017944,921.066162 1111.572510,920.658325 
	C1104.243286,918.589844 1096.129028,920.247009 1089.260742,915.914001 
	C1088.187134,915.236694 1086.132202,915.133484 1086.568481,913.566772 
	C1087.278442,911.016724 1085.911011,907.920166 1088.042725,905.946289 
	C1089.494629,904.601868 1089.670654,906.476257 1090.092773,907.510681 
	C1090.968628,909.656372 1090.592529,912.845215 1094.183838,912.896362 
	C1095.710571,912.918152 1096.751953,911.969849 1097.093018,910.585449 
	C1097.854248,907.495178 1096.628418,905.486328 1093.526123,904.572021 
	C1092.697266,904.327698 1091.898315,903.300720 1092.252075,902.656677 
	C1094.152710,899.196899 1090.833130,897.340515 1090.012573,894.368042 
	C1090.666016,893.335205 1091.450317,893.247192 1092.655029,893.761902 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1137.951660,915.352539 
	C1139.388916,915.000244 1140.777832,915.000488 1142.583374,915.001587 
	C1142.968628,916.160583 1143.282593,917.471680 1142.848633,918.451416 
	C1140.496948,923.760559 1141.172119,927.712341 1147.247070,929.698059 
	C1148.169922,929.999695 1149.074097,930.732056 1148.585815,931.955994 
	C1148.124878,933.111694 1146.995728,932.990540 1146.027466,932.932739 
	C1143.626465,932.789551 1142.098633,931.498352 1141.208984,929.270935 
	C1139.599121,925.239258 1137.370728,922.612671 1132.613770,925.500366 
	C1131.271606,926.315002 1129.294922,926.384766 1128.793701,924.675720 
	C1128.179565,922.582214 1130.285278,923.106934 1131.399780,923.015686 
	C1135.936035,922.644165 1135.775391,918.169556 1137.951660,915.352539 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1083.638184,908.291626 
	C1084.057739,909.563965 1084.640869,911.268005 1084.495728,911.332520 
	C1080.993286,912.888611 1077.802734,914.863647 1075.023071,917.523987 
	C1074.915283,917.627136 1073.858521,917.015564 1073.509644,916.541443 
	C1072.138428,914.677795 1073.010864,907.483826 1074.920532,906.258789 
	C1078.195190,904.158081 1081.205566,904.431213 1083.638184,908.291626 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1123.339600,914.288452 
	C1122.840698,912.626282 1123.477295,910.984497 1121.348633,910.081970 
	C1121.000000,909.555542 1121.000000,909.111084 1121.000000,908.333313 
	C1122.161499,905.740356 1123.823120,904.639832 1126.539917,904.941895 
	C1128.992432,905.214478 1131.478027,905.051453 1133.924194,904.305603 
	C1135.409912,903.852539 1136.492676,904.750916 1136.799072,906.293274 
	C1137.121094,907.913513 1136.397461,908.786438 1134.733276,908.972412 
	C1132.382446,909.235107 1130.084595,909.649109 1129.054688,912.639282 
	C1128.503906,913.257812 1128.007812,913.515564 1127.255859,913.886658 
	C1126.004883,914.834106 1124.911499,915.104736 1123.339600,914.288452 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1103.034180,895.328735 
	C1104.005371,890.806641 1106.297852,888.685486 1110.866821,889.912109 
	C1111.784546,890.158508 1112.848267,889.933472 1113.835083,889.832153 
	C1115.074585,889.704895 1116.523071,888.392212 1117.479492,889.847412 
	C1118.446777,891.318909 1117.216064,892.724060 1116.389526,893.909668 
	C1114.078247,897.225342 1110.776001,898.403748 1106.416748,898.000000 
	C1104.491211,897.884460 1103.647827,896.936157 1103.034180,895.328735 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1178.206665,924.734131 
	C1173.812134,924.210327 1173.781372,922.253052 1176.845337,919.999390 
	C1178.201294,919.002075 1178.902222,918.040833 1179.022339,916.430725 
	C1179.199951,914.047485 1180.297363,911.797852 1182.881958,912.383179 
	C1185.989624,913.087097 1185.066406,915.996582 1184.661377,918.190979 
	C1184.462036,919.270508 1183.648560,920.236694 1183.056396,921.627075 
	C1180.836548,921.643433 1179.796509,923.374756 1178.206665,924.734131 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1189.739502,951.127197 
	C1190.987793,951.057434 1192.005127,950.987671 1192.956787,951.205261 
	C1194.696289,951.602966 1196.672363,952.822266 1198.029053,951.069397 
	C1199.734863,948.865479 1195.891602,948.550964 1196.047363,946.338501 
	C1195.821411,944.942017 1196.454834,944.643433 1197.666748,944.787048 
	C1200.553833,946.050232 1199.626587,949.221252 1201.490967,950.745300 
	C1201.975342,951.141235 1201.757690,951.932007 1201.220337,952.491638 
	C1198.205811,955.631104 1193.018433,956.135925 1188.096558,953.364624 
	C1188.493042,952.418884 1188.986084,951.837830 1189.739502,951.127197 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1169.831421,928.312134 
	C1171.066650,928.000000 1172.133423,928.000000 1173.600098,928.000000 
	C1174.294678,929.117188 1174.664429,930.220825 1174.868530,931.354309 
	C1175.296753,933.732300 1174.589722,935.680603 1172.266235,936.660583 
	C1170.564575,937.378296 1168.814819,936.872742 1167.750854,935.517212 
	C1166.325684,933.701599 1166.887085,931.790710 1168.379639,930.139404 
	C1168.823242,929.648743 1169.235962,929.130066 1169.831421,928.312134 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1058.083374,904.922241 
	C1056.534668,900.276001 1059.354980,900.602905 1061.812866,901.682678 
	C1064.236206,902.747253 1065.361572,905.085144 1064.991699,907.877319 
	C1064.810425,909.245178 1065.629639,911.317566 1063.579590,911.590088 
	C1061.713013,911.838257 1062.126953,909.743958 1061.728149,908.614563 
	C1061.126953,906.911377 1059.904907,905.899719 1058.083374,904.922241 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1157.241455,914.213745 
	C1157.099609,911.502991 1159.189087,910.969971 1160.893311,910.059509 
	C1162.859741,909.008972 1164.262695,909.383362 1163.919556,911.819153 
	C1163.620117,913.943726 1165.610352,917.012939 1161.400391,917.978882 
	C1159.223389,917.405273 1158.086304,916.178894 1157.241455,914.213745 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1170.625000,942.000000 
	C1170.965942,943.676331 1170.846802,945.792847 1168.783936,945.655212 
	C1166.466064,945.500549 1163.769165,945.673340 1161.895142,943.976013 
	C1160.472534,942.687500 1162.734985,941.844543 1162.937988,940.327881 
	C1163.750000,940.000000 1164.500000,940.000000 1165.625000,940.000000 
	C1166.524048,940.542664 1167.068237,941.065186 1167.802490,941.797607 
	C1168.750000,942.000000 1169.500000,942.000000 1170.625000,942.000000 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M1102.753296,894.774170 
	C1103.906738,895.816528 1104.813477,896.632996 1105.860107,897.724731 
	C1106.000000,898.749451 1106.000000,899.498840 1106.000000,900.622925 
	C1104.174683,900.636475 1101.534668,902.091675 1101.018311,898.385742 
	C1100.917480,897.031860 1100.918823,896.047180 1100.731567,895.099792 
	C1100.494263,893.900330 1097.865601,892.690979 1099.987427,891.675354 
	C1101.755127,890.829285 1101.652710,893.528748 1102.753296,894.774170 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1130.895630,959.110657 
	C1129.314209,956.106140 1130.332520,954.162415 1133.103271,953.776917 
	C1135.014526,953.510986 1136.794922,955.358826 1136.461792,957.114990 
	C1135.953247,959.795044 1133.777344,960.583008 1130.895630,959.110657 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1088.619385,919.427185 
	C1088.992065,921.547241 1088.276855,922.682861 1086.447144,922.791626 
	C1085.374512,922.855469 1084.562622,922.154297 1084.291626,921.118958 
	C1083.923584,919.713013 1084.474121,918.415649 1085.571533,917.639771 
	C1087.195435,916.491699 1087.841431,917.930115 1088.619385,919.427185 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1091.990845,890.299072 
	C1092.311768,888.503906 1091.114258,886.462830 1093.116943,885.637207 
	C1094.178223,885.199646 1094.781982,886.512756 1094.952271,887.480103 
	C1095.241333,889.121582 1097.457397,891.375305 1093.381592,891.976746 
	C1092.424438,891.708801 1092.121826,891.213623 1091.990845,890.299072 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1174.375000,952.000000 
	C1172.890015,951.155884 1172.415649,949.945618 1172.037354,948.329163 
	C1173.066650,948.000000 1174.133423,948.000000 1175.600098,948.000000 
	C1176.304810,948.126526 1176.557983,948.320374 1176.879761,948.790771 
	C1177.380371,949.385559 1177.527466,949.845764 1177.220947,950.690247 
	C1177.000000,951.000000 1177.000000,951.500000 1177.000000,951.750000 
	C1176.250000,952.000000 1175.500000,952.000000 1174.375000,952.000000 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1091.666626,890.044434 
	C1092.318970,890.467102 1092.633179,890.936584 1092.981445,891.696960 
	C1093.010864,892.434448 1093.006104,892.881165 1093.000732,893.662720 
	C1092.250000,893.998352 1091.500000,893.999146 1090.375000,894.000000 
	C1088.209229,891.938049 1089.348389,890.871765 1091.666626,890.044434 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1122.784180,914.181641 
	C1124.066650,913.998413 1125.133423,913.999207 1126.600098,914.000000 
	C1126.890625,915.503601 1126.234253,916.569702 1124.335815,916.955444 
	C1123.037964,916.385376 1121.817505,915.911072 1122.784180,914.181641 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1110.949097,904.648315 
	C1108.447754,906.357910 1107.190674,905.468201 1107.007080,902.351624 
	C1108.763550,901.977722 1110.380737,902.203796 1110.949097,904.648315 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1082.759521,897.235291 
	C1083.915405,898.167603 1084.120483,899.071899 1082.928101,899.627747 
	C1081.914551,900.100098 1080.885742,899.607910 1080.626343,898.555969 
	C1080.266235,897.096313 1081.469482,897.159241 1082.759521,897.235291 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1150.185669,924.368958 
	C1151.718140,922.972351 1151.815186,924.132812 1151.855713,925.096252 
	C1151.881592,925.712219 1151.637939,926.551208 1150.810059,926.241333 
	C1150.432373,926.100037 1150.332031,925.217712 1150.185669,924.368958 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1167.946045,941.687378 
	C1167.114014,941.893066 1166.356201,941.609924 1166.053955,940.312622 
	C1166.886108,940.106628 1167.644165,940.389771 1167.946045,941.687378 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1210.685791,956.736694 
	C1211.306030,958.104492 1211.612183,959.209045 1211.959106,960.656738 
	C1212.380371,961.385559 1212.527466,961.845764 1212.220947,962.690247 
	C1211.204224,966.084839 1208.656982,964.628601 1206.407349,965.000000 
	C1204.473999,965.788147 1202.847168,968.262573 1201.789673,964.367737 
	C1202.737183,961.121948 1200.387573,958.432312 1201.741455,955.266235 
	C1205.203735,953.142700 1207.753418,955.002075 1210.685791,956.736694 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1210.948853,956.628235 
	C1207.908203,957.791382 1205.538574,955.580017 1202.385742,955.010620 
	C1202.281006,954.600037 1202.487549,954.106567 1202.854248,953.814026 
	C1208.784180,949.084534 1208.788696,949.090210 1210.948853,956.628235 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1168.672119,915.784668 
	C1169.582764,914.400696 1170.631104,913.535156 1171.492554,914.951416 
	C1172.437134,916.504517 1172.296875,918.411560 1171.488159,920.076355 
	C1171.302368,920.459229 1170.262817,920.812988 1169.849976,920.635681 
	C1167.700562,919.712402 1167.841309,917.966370 1168.672119,915.784668 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1186.730957,971.378174 
	C1188.388916,971.000000 1189.777832,971.000000 1191.583374,971.000000 
	C1191.997681,972.479614 1192.098877,973.976013 1191.149902,975.663330 
	C1189.933350,976.000000 1188.866577,976.000000 1187.399902,976.000000 
	C1185.620361,974.737671 1185.750366,973.283875 1186.730957,971.378174 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1166.202148,896.855469 
	C1166.720947,894.525269 1167.439087,893.243713 1168.967407,895.929016 
	C1169.043213,896.062073 1169.083130,896.259399 1169.048340,896.404907 
	C1168.518066,898.617676 1173.651123,899.348572 1170.978271,901.225830 
	C1169.462036,902.290833 1167.555908,899.012695 1166.202148,896.855469 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1236.657471,960.764526 
	C1239.064941,961.335999 1239.108643,962.819214 1238.862061,964.393066 
	C1238.717407,965.315918 1238.090576,965.956909 1237.130249,965.721924 
	C1235.106567,965.226929 1234.133423,963.798706 1234.009155,961.375793 
	C1234.685913,960.421448 1235.458252,960.267944 1236.657471,960.764526 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1237.009521,960.622803 
	C1236.250000,961.000000 1235.500000,961.000000 1234.375000,961.000000 
	C1233.660156,957.622864 1236.971802,956.245178 1237.895142,952.445801 
	C1239.563599,956.125183 1236.702271,957.941711 1237.009521,960.622803 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1152.161865,898.043152 
	C1153.745117,898.116882 1155.058350,898.325745 1154.619019,899.958313 
	C1154.295654,901.159973 1153.078979,901.678528 1151.864868,901.622742 
	C1150.871216,901.576965 1149.725098,901.139587 1149.566650,900.095947 
	C1149.339600,898.600647 1150.613281,898.262817 1152.161865,898.043152 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1191.365723,962.986511 
	C1190.528320,962.686768 1190.056763,962.373474 1189.292480,962.030090 
	C1188.998779,960.346924 1189.303955,958.928040 1191.443115,959.122742 
	C1192.511597,959.220093 1193.392334,959.758606 1193.669312,960.823853 
	C1194.081421,962.409424 1193.058228,962.867065 1191.365723,962.986511 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1250.370361,957.990723 
	C1250.000000,956.611084 1250.000000,955.222229 1250.000000,953.416626 
	C1251.607666,953.321533 1253.529663,953.525818 1253.727417,955.518433 
	C1253.911133,957.369141 1252.353149,957.891846 1250.370361,957.990723 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1201.701172,964.137939 
	C1202.373291,966.665955 1204.053467,965.264832 1205.672241,965.037354 
	C1205.725708,967.005127 1204.191162,967.567810 1202.550293,967.874634 
	C1201.384155,968.092651 1200.124390,967.900879 1199.627686,966.679138 
	C1198.998169,965.130615 1200.562988,964.905884 1201.701172,964.137939 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1197.983887,944.624878 
	C1197.454346,945.180176 1196.908813,945.360352 1196.181641,945.770264 
	C1194.878784,945.893250 1193.496338,946.145386 1193.261353,944.539612 
	C1193.095703,943.408325 1193.828613,942.604309 1194.839600,942.295959 
	C1196.538208,941.777710 1197.819214,942.169189 1197.983887,944.624878 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1187.779297,967.309570 
	C1188.619385,965.896667 1188.935913,964.545410 1190.683960,964.053711 
	C1191.329834,965.100464 1191.659790,966.200928 1191.994873,967.650696 
	C1190.899536,968.330017 1189.799072,968.659973 1188.349365,968.994934 
	C1187.621704,968.613586 1187.474609,968.153320 1187.779297,967.309570 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1124.570557,902.273560 
	C1124.038330,900.868469 1123.821289,899.696838 1125.370239,899.337708 
	C1126.036133,899.183289 1126.637085,899.610779 1126.754028,900.337097 
	C1126.991699,901.813904 1126.800049,902.991150 1124.570557,902.273560 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1229.811768,957.623962 
	C1229.015625,958.077454 1228.069214,958.621033 1227.934326,958.464661 
	C1226.646484,956.971069 1228.491821,956.321960 1228.946167,955.285400 
	C1229.219604,954.661804 1229.894531,954.209778 1230.456177,954.816711 
	C1231.302979,955.731873 1230.603271,956.594421 1229.811768,957.623962 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1160.125244,892.902710 
	C1160.902710,894.984802 1159.686768,894.942871 1158.492554,894.818176 
	C1157.734375,894.739014 1157.323730,894.182434 1157.589478,893.473572 
	C1158.003174,892.369141 1158.853516,892.141113 1160.125244,892.902710 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1177.283936,950.952271 
	C1177.000000,950.555542 1177.000000,950.111084 1177.000000,949.333313 
	C1177.628052,949.106689 1178.380859,949.172180 1178.420898,949.957397 
	C1178.436157,950.257812 1177.869507,950.587952 1177.283936,950.952271 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1212.284180,962.952393 
	C1212.000000,962.555542 1212.000000,962.111084 1212.000000,961.333313 
	C1212.628296,961.106384 1213.381226,961.171997 1213.421509,961.957336 
	C1213.436890,962.257812 1212.869995,962.588074 1212.284180,962.952393 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1150.341797,894.413208 
	C1149.626831,894.612122 1149.366699,894.362610 1149.478149,893.806824 
	C1149.501343,893.691589 1149.665039,893.536682 1149.781616,893.519531 
	C1150.342529,893.436707 1150.577637,893.709656 1150.341797,894.413208 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1163.534180,890.044067 
	C1163.175903,890.694458 1162.816284,890.703064 1162.499268,890.236633 
	C1162.432983,890.138977 1162.435791,889.913086 1162.505127,889.818298 
	C1162.836914,889.364685 1163.196289,889.381836 1163.534180,890.044067 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1235.469238,980.320557 
	C1235.460571,982.669617 1234.178223,983.335632 1232.551636,982.558044 
	C1230.764648,981.703918 1229.184570,980.283081 1228.526001,978.355713 
	C1228.201172,977.405273 1229.300659,976.981567 1230.182373,977.272949 
	C1232.040405,977.886963 1234.127563,978.123291 1235.469238,980.320557 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1245.605469,992.307129 
	C1247.259644,992.068359 1248.736328,991.815491 1248.735840,993.798523 
	C1248.735474,995.039368 1247.811279,995.856140 1246.786377,996.394775 
	C1245.478027,997.082458 1244.618896,996.191650 1243.936401,995.268677 
	C1242.690308,993.583679 1244.107178,993.042542 1245.605469,992.307129 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1245.354858,1001.998291 
	C1245.208130,1000.616760 1245.969849,999.890808 1247.389404,1000.196045 
	C1247.736572,1000.270569 1248.299683,1000.903870 1248.233032,1001.087952 
	C1247.784912,1002.324524 1246.618164,1001.845764 1245.354858,1001.998291 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1212.658936,974.586792 
	C1213.373413,974.388367 1213.633423,974.637573 1213.521484,975.192932 
	C1213.498413,975.308228 1213.334595,975.462830 1213.218018,975.479980 
	C1212.657349,975.562683 1212.422852,975.289612 1212.658936,974.586792 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M1126.949219,838.990967 
	C1131.656982,839.595581 1133.693359,844.560974 1138.658569,844.993774 
	C1139.078735,845.607666 1139.129272,846.182861 1139.189331,847.046021 
	C1134.409668,849.970520 1131.338867,853.968750 1129.705078,859.547852 
	C1129.688110,864.643982 1133.551147,865.796997 1136.861938,866.812378 
	C1143.723877,868.916931 1150.575928,868.687622 1157.259033,865.511475 
	C1159.415039,864.486877 1161.499390,862.780212 1164.530029,863.459229 
	C1165.449829,864.356384 1165.288452,864.995544 1164.380371,865.849487 
	C1163.950684,866.399170 1163.743896,866.640320 1163.279419,866.959900 
	C1154.727783,873.960388 1145.384033,876.077698 1134.834229,873.398926 
	C1127.283081,871.481689 1123.623047,865.894287 1125.445801,857.903442 
	C1125.545776,856.751343 1125.608154,855.994263 1125.961182,854.932800 
	C1126.500977,852.871155 1128.901855,852.460510 1128.876953,850.099304 
	C1128.907349,849.353210 1128.944946,848.926453 1129.050293,848.178772 
	C1129.692261,846.333435 1129.107422,845.308044 1127.480591,844.362671 
	C1126.820801,843.495056 1126.443970,842.864380 1126.151001,841.780701 
	C1126.063965,840.616333 1126.145508,839.856262 1126.949219,838.990967 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M1160.723389,869.779907 
	C1160.894409,872.088440 1159.532471,873.537109 1157.946289,874.280212 
	C1149.717651,878.135071 1141.458618,881.549316 1131.918701,879.035217 
	C1124.608887,877.108948 1119.301758,868.439392 1122.575195,862.094849 
	C1124.682983,861.595825 1124.816650,862.912170 1125.000000,864.021606 
	C1126.376343,872.350769 1134.921509,874.875061 1143.145874,874.745667 
	C1149.423340,874.646851 1154.722656,871.444031 1160.723389,869.779907 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M1161.195801,869.767700 
	C1156.500488,871.514160 1152.445679,873.997009 1147.842773,875.487244 
	C1141.990723,877.381958 1136.848877,876.142029 1131.563965,874.603516 
	C1125.664429,872.885925 1124.777832,867.475037 1123.213623,862.242676 
	C1122.964722,861.248596 1122.986084,860.507690 1123.016357,859.396179 
	C1124.032959,858.081116 1124.350708,856.223328 1126.618164,856.748413 
	C1125.344604,869.358459 1134.148560,875.624390 1148.610107,872.654724 
	C1153.586426,871.632812 1157.647217,868.652466 1162.703857,867.083679 
	C1163.048706,868.270569 1162.173584,868.865662 1161.195801,869.767700 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M1164.459595,866.091797 
	C1164.301636,865.551819 1164.497437,864.990173 1164.749512,864.136841 
	C1164.290039,863.189941 1164.281006,862.488037 1164.910400,861.528687 
	C1165.894409,860.898682 1166.580444,860.494629 1167.510376,859.917603 
	C1168.216919,859.497314 1168.709229,859.399536 1169.563965,859.642090 
	C1169.932495,860.035889 1169.968384,860.238892 1170.055420,860.747437 
	C1168.809814,863.218811 1167.953491,865.849243 1164.459595,866.091797 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M1126.954102,856.605408 
	C1125.836914,857.603027 1124.750122,858.252197 1123.344482,858.963440 
	C1123.238647,856.040588 1124.720825,853.580017 1126.623291,851.065674 
	C1127.680542,851.651672 1127.803833,852.470215 1127.377686,853.772339 
	C1127.120728,854.862915 1127.052734,855.559937 1126.954102,856.605408 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M1122.446411,830.973877 
	C1124.393066,831.815796 1124.885620,833.431335 1125.292969,835.443237 
	C1124.902344,836.263550 1124.389404,836.481079 1123.385132,836.217102 
	C1121.132935,834.670349 1120.090698,833.185059 1122.446411,830.973877 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M1127.502197,854.085938 
	C1127.126953,853.369324 1127.065186,852.572693 1126.985474,851.378296 
	C1126.967407,850.980469 1126.996216,850.499390 1127.005371,850.258545 
	C1127.665405,849.428955 1128.411377,849.272583 1129.574707,849.762939 
	C1131.837769,852.705322 1129.093750,852.973938 1127.502197,854.085938 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M1126.867920,841.706787 
	C1127.415405,842.389465 1127.723145,842.852905 1128.081665,843.597351 
	C1128.440918,844.347107 1128.531250,844.854736 1128.163086,845.721680 
	C1125.611328,845.505066 1125.245483,843.658447 1125.012207,841.358643 
	C1125.733521,840.552734 1126.192871,840.980469 1126.867920,841.706787 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M1127.935303,846.371094 
	C1127.961914,845.561340 1128.000977,845.080322 1128.086182,844.238892 
	C1129.980225,844.441528 1131.563110,845.171143 1130.208496,847.792725 
	C1129.568848,848.378418 1129.110962,848.518433 1128.259033,848.217773 
	C1127.941650,847.578125 1127.944824,847.139038 1127.935303,846.371094 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M1123.007568,836.343018 
	C1123.516602,835.969788 1124.028320,835.941956 1124.922119,835.849060 
	C1125.618774,836.292053 1125.933472,836.800110 1126.302368,837.606323 
	C1126.184204,838.307434 1125.916260,838.634155 1125.249512,838.962585 
	C1123.554932,838.855713 1122.999634,838.004395 1123.007568,836.343018 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M1115.641113,851.012207 
	C1115.809570,850.653015 1116.220825,850.372314 1116.468750,850.394775 
	C1116.965210,851.993713 1117.505493,853.629883 1115.454468,854.948608 
	C1115.090088,855.086243 1114.933960,854.936218 1114.856201,854.860962 
	C1113.872437,853.416321 1114.199341,852.297607 1115.641113,851.012207 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M1124.951172,839.357910 
	C1125.214722,838.733582 1125.495850,838.436584 1126.066895,838.021973 
	C1126.643799,838.003113 1126.870361,838.179565 1127.162231,838.614990 
	C1127.217041,839.578735 1127.145874,840.361389 1127.091187,841.535034 
	C1126.583496,841.693237 1126.059326,841.460327 1125.272461,841.111877 
	C1124.996094,840.559265 1124.982300,840.122253 1124.951172,839.357910 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M1127.714722,848.199463 
	C1128.386963,848.017273 1128.835327,848.017334 1129.618652,848.008057 
	C1129.952148,848.443665 1129.950684,848.888733 1129.925415,849.666870 
	C1129.180298,850.003723 1128.459106,850.007385 1127.376221,850.014404 
	C1127.173462,849.472412 1127.332153,848.927063 1127.714722,848.199463 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M1120.166016,826.113953 
	C1121.048584,826.069702 1121.730957,826.513000 1121.822266,827.804016 
	C1120.819092,827.955688 1120.208008,827.458008 1120.166016,826.113953 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M1121.942383,829.115479 
	C1122.118530,829.049072 1122.299927,829.335815 1122.320557,829.501770 
	C1121.960327,829.729675 1121.526855,829.853760 1121.942383,829.115479 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1170.120361,959.209167 
	C1170.000000,958.250000 1170.000000,957.500000 1170.000000,956.375000 
	C1170.727173,954.589355 1171.246338,952.947998 1173.653931,953.008545 
	C1177.463989,958.351257 1177.462036,958.357300 1171.368286,959.991333 
	C1170.695312,959.873413 1170.442261,959.679504 1170.120361,959.209167 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1186.684082,971.053589 
	C1187.000000,972.388916 1187.000000,973.777771 1187.000000,975.583374 
	C1187.000000,976.000000 1187.000000,976.500000 1187.000000,976.750000 
	C1186.313965,977.576965 1185.544067,977.741516 1184.342773,977.235596 
	C1183.399414,976.317261 1183.238647,975.541626 1183.754639,974.337524 
	C1184.618774,972.896118 1184.935791,971.545227 1186.684082,971.053589 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1183.708008,973.870239 
	C1184.000000,974.750000 1184.000000,975.500000 1184.000000,976.625000 
	C1181.661621,977.167542 1180.835449,975.931580 1181.002197,973.386108 
	C1181.805298,973.246765 1182.610596,973.493591 1183.708008,973.870239 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1176.372803,963.641663 
	C1176.854858,965.017395 1176.569336,965.817932 1175.236572,965.652100 
	C1174.892822,965.609314 1174.379028,965.084595 1174.343506,964.739990 
	C1174.206299,963.411072 1175.002563,963.127014 1176.372803,963.641663 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1180.938599,972.684448 
	C1180.132568,972.835327 1178.982544,973.316345 1178.613770,972.026184 
	C1178.561157,971.841919 1178.848389,971.400391 1179.062988,971.327698 
	C1180.011353,971.006348 1180.555542,971.521912 1180.938599,972.684448 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M1184.062744,977.321716 
	C1184.750000,977.000000 1185.500000,977.000000 1186.625000,977.000000 
	C1186.438965,978.988586 1185.604004,979.753662 1184.062744,977.321716 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1187.715820,967.047729 
	C1188.000000,967.444458 1188.000000,967.888916 1188.000000,968.666687 
	C1187.372070,968.893188 1186.619019,968.827942 1186.578857,968.042664 
	C1186.563477,967.742249 1187.130249,967.412109 1187.715820,967.047729 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M1208.998535,1040.628662 
	C1206.980591,1040.744141 1207.005737,1039.234863 1207.001587,1037.371338 
	C1209.019653,1037.255737 1208.994995,1038.764648 1208.998535,1040.628662 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M612.752808,1096.756104 
	C613.000000,1097.444458 613.000000,1097.888916 613.000000,1098.666748 
	C612.963196,1103.081665 612.196960,1107.205322 616.512756,1110.153442 
	C618.551331,1111.546143 617.538513,1113.431763 615.238953,1114.098633 
	C613.570618,1114.582520 611.939636,1114.244629 610.462036,1113.310425 
	C609.472656,1112.685059 608.725647,1111.758179 607.786621,1111.077148 
	C605.335632,1109.299561 602.308167,1107.513550 599.697754,1107.533447 
	C594.527588,1107.572510 599.538513,1112.069336 597.663574,1114.215698 
	C596.283569,1115.795532 597.286865,1118.436646 596.226318,1120.734497 
	C595.555542,1121.000000 595.111084,1121.000000 594.333313,1121.000000 
	C593.533081,1121.177734 593.150085,1121.066528 592.925537,1120.333130 
	C592.581726,1117.601440 590.678772,1118.115967 589.086548,1117.987915 
	C588.145447,1117.912231 586.871399,1118.232422 586.605957,1116.948608 
	C586.363647,1115.776367 587.533081,1115.371094 588.327515,1114.807007 
	C589.001038,1114.328979 589.653320,1113.803955 590.239258,1113.223389 
	C591.315430,1112.156982 592.491455,1111.001099 591.442139,1109.352417 
	C590.357056,1107.647461 588.534851,1107.727295 586.969727,1108.277710 
	C585.434631,1108.817627 584.080627,1109.872314 582.323547,1110.850586 
	C580.186951,1112.614136 578.188843,1113.748047 575.319702,1113.226074 
	C572.467896,1111.206421 570.476990,1107.303345 566.611084,1111.397705 
	C565.540527,1112.531494 564.211792,1110.830933 563.443359,1109.886597 
	C560.410522,1106.159424 557.737915,1107.137939 555.065430,1110.664185 
	C554.602722,1111.349609 554.141235,1111.494751 553.307739,1111.217529 
	C550.721863,1111.216187 548.801636,1110.631104 547.282166,1108.775146 
	C546.250244,1107.514771 545.712646,1106.208008 546.372009,1104.685181 
	C546.980896,1103.279053 548.336792,1102.851562 549.689819,1103.048828 
	C553.784119,1103.645874 554.825378,1101.731201 555.405334,1097.894531 
	C556.031616,1093.751709 555.811523,1089.679932 555.956909,1085.579102 
	C556.003784,1084.253540 555.840515,1082.688965 554.140686,1082.543945 
	C552.348694,1082.391357 551.966858,1083.952026 552.069336,1085.274292 
	C552.445007,1090.119507 549.147705,1090.936890 545.264282,1091.742920 
	C544.327393,1092.642822 543.543701,1092.761841 542.338867,1092.246338 
	C539.570679,1092.377197 538.213074,1090.595215 536.301636,1089.160645 
	C535.766052,1088.765381 535.546875,1088.517944 535.333130,1088.265381 
	C533.943726,1086.624023 534.585510,1082.983032 531.397827,1083.290771 
	C528.661621,1083.555054 530.109680,1087.097412 528.232117,1088.754883 
	C526.933289,1089.000000 525.866638,1089.000000 524.399963,1089.000000 
	C522.896057,1088.281250 522.559937,1087.110840 522.586487,1085.958130 
	C522.689209,1081.505249 521.327087,1077.829590 517.584961,1075.130859 
	C516.286865,1074.194702 515.774536,1072.696655 516.513367,1071.118286 
	C517.513184,1068.982178 519.478394,1068.686035 521.562317,1068.609741 
	C521.863403,1068.598877 522.676208,1069.278687 522.554382,1068.289307 
	C522.516541,1067.981934 521.769226,1067.750244 521.027588,1067.334717 
	C517.042542,1064.493286 512.392456,1062.892456 510.818970,1057.805176 
	C514.108398,1055.454346 517.616943,1055.368530 521.119751,1055.468262 
	C523.944153,1055.548462 526.772034,1055.991943 529.422180,1054.546021 
	C529.853027,1054.310791 530.449219,1054.072876 530.863098,1054.236816 
	C536.213135,1056.357788 540.451172,1060.309082 545.305725,1063.256348 
	C546.534119,1064.002075 545.702515,1066.057739 543.846680,1066.617432 
	C542.750366,1066.947998 541.299072,1066.485474 540.552429,1067.478271 
	C538.993713,1069.551147 538.339050,1072.339722 535.309021,1073.139038 
	C532.784607,1073.804810 530.084717,1073.729736 527.562805,1074.897827 
	C528.834717,1076.142456 530.405212,1076.102539 531.826233,1076.304932 
	C536.105408,1076.913818 539.159973,1078.937744 541.099304,1082.949951 
	C542.882141,1086.638428 543.561401,1086.610352 546.003723,1083.182617 
	C547.411682,1081.206543 548.999695,1079.359619 551.398743,1079.052368 
	C555.342468,1078.547241 558.968140,1078.083008 562.096741,1074.940674 
	C563.842041,1073.187744 566.532776,1074.032959 567.549438,1077.274292 
	C567.193604,1078.852051 565.757019,1079.029297 565.220215,1080.036621 
	C565.042358,1080.370361 564.713745,1081.038574 565.449707,1081.066406 
	C565.812439,1081.080078 566.099548,1080.400879 566.659180,1079.791260 
	C568.151733,1078.857300 569.423767,1078.544800 570.007141,1076.728516 
	C571.703125,1071.448242 577.858032,1070.285767 581.696594,1074.305054 
	C585.687134,1078.483521 585.961853,1083.387451 582.178406,1087.491089 
	C580.973022,1088.798462 580.425964,1089.673218 581.625488,1091.251465 
	C583.277100,1093.424683 583.979309,1096.345337 586.613647,1097.829834 
	C591.905945,1089.612305 593.246826,1089.379272 601.405151,1095.124023 
	C603.620972,1096.684204 605.737488,1098.340942 608.512268,1096.002197 
	C609.849304,1094.875244 611.312561,1095.240723 612.752808,1096.756104 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M596.250000,1121.000000 
	C595.236267,1118.431763 594.527832,1115.898682 596.261719,1113.376099 
	C597.074036,1112.194214 597.021851,1110.931274 596.121887,1109.670898 
	C595.368958,1108.616455 593.256165,1107.643799 595.211975,1106.065674 
	C596.866577,1104.730591 599.213440,1104.259521 600.781555,1105.670044 
	C603.928467,1108.500854 609.141418,1107.745728 611.378418,1112.303955 
	C612.007812,1113.586304 614.625610,1113.421509 617.157471,1111.772339 
	C610.524841,1109.328125 609.709717,1104.958618 612.736328,1099.258911 
	C614.331482,1101.632935 615.694702,1104.095947 619.188782,1101.496216 
	C619.832642,1101.017212 620.812073,1100.919067 621.430969,1101.696777 
	C622.193176,1102.654419 621.651367,1103.529907 621.061035,1104.362305 
	C620.264893,1105.485107 618.445618,1106.454956 619.927368,1108.083862 
	C621.183472,1109.464722 622.940613,1108.773438 624.584351,1108.695557 
	C630.188965,1108.429199 635.871521,1107.279419 641.661011,1109.847900 
	C640.439758,1112.467896 642.588440,1115.334106 640.761963,1118.051636 
	C638.594299,1121.276611 636.241638,1122.805420 632.504395,1121.643066 
	C630.546875,1121.034424 628.609070,1120.737061 626.641174,1121.316040 
	C624.333618,1121.994873 621.959717,1122.458740 624.175720,1125.934204 
	C625.817749,1128.509277 624.226501,1129.018921 621.768005,1128.464600 
	C620.173279,1128.104858 618.489380,1128.141113 616.130127,1127.982422 
	C614.570190,1127.889771 613.873230,1128.320435 612.825439,1128.735474 
	C612.052368,1128.933228 611.590393,1128.954224 610.764526,1128.841064 
	C609.421082,1128.363281 608.719666,1127.656738 608.035889,1126.408813 
	C607.586548,1125.095703 607.580322,1124.098267 607.530518,1122.753662 
	C607.463257,1120.790039 606.977234,1119.951172 605.103516,1120.641113 
	C602.661560,1121.540161 600.152893,1122.328979 597.248413,1121.226562 
	C597.000000,1121.000000 596.500000,1121.000000 596.250000,1121.000000 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M552.908875,1111.359131 
	C553.444458,1111.000000 553.888916,1111.000000 554.666687,1111.000000 
	C557.098206,1110.959229 559.449158,1108.542725 561.218079,1111.600098 
	C562.840759,1114.404785 560.816528,1116.305786 559.374023,1118.260864 
	C558.707947,1119.163696 557.725037,1119.839111 556.859070,1120.586792 
	C553.842590,1123.190674 554.196106,1127.533691 557.674683,1129.350952 
	C559.185669,1130.140259 560.513611,1130.971558 561.343689,1132.541504 
	C562.015930,1133.812866 563.426453,1134.862549 561.954224,1136.468140 
	C560.692871,1137.843994 559.144104,1138.443726 557.480591,1137.614136 
	C553.571228,1135.664429 551.433960,1131.034302 546.632141,1130.319336 
	C546.465942,1130.294556 546.180237,1128.994263 546.429565,1128.570801 
	C549.833313,1122.791870 544.402710,1120.442383 541.900024,1117.052734 
	C541.036194,1115.882935 538.290405,1115.737793 539.517212,1113.520264 
	C540.628906,1111.510986 542.632141,1110.891602 545.098267,1111.477295 
	C547.605042,1112.072632 550.171753,1114.571655 552.908875,1111.359131 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M520.656860,1066.085693 
	C523.670471,1066.510986 525.377869,1068.497192 528.197510,1069.772461 
	C525.291687,1071.841187 523.344666,1069.772949 521.329163,1069.985718 
	C519.839050,1070.143188 518.163025,1069.905151 517.443665,1071.560669 
	C516.575073,1073.559448 518.363647,1074.077637 519.602783,1074.812866 
	C524.924255,1077.970093 523.955872,1083.200195 524.000000,1088.549316 
	C520.394165,1089.115356 516.714172,1089.814209 513.198975,1087.325928 
	C514.295349,1084.469360 514.416626,1082.177124 512.391418,1079.783447 
	C509.659210,1076.554321 511.502380,1072.541382 511.000000,1068.456055 
	C513.581055,1064.723755 517.201782,1066.743530 520.656860,1066.085693 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M575.000000,1113.427734 
	C577.065247,1112.315308 578.708252,1110.318237 581.606689,1111.000244 
	C583.569031,1113.722168 583.790344,1116.439941 582.416931,1119.380249 
	C581.035767,1122.337036 583.051270,1124.830811 584.001831,1127.414307 
	C585.023499,1130.191040 584.732788,1132.052490 581.261963,1131.975098 
	C578.710510,1131.918335 577.166992,1132.823975 576.862000,1135.553467 
	C576.695435,1137.044067 575.691223,1137.992188 574.098083,1137.757080 
	C572.778137,1137.562256 571.386658,1137.074097 570.743286,1135.850464 
	C569.783325,1134.024658 571.740540,1133.660522 572.636414,1132.946289 
	C577.773315,1128.851318 578.582764,1125.092285 575.490601,1119.245728 
	C574.587708,1117.538574 575.146240,1115.655151 575.000000,1113.427734 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M610.701050,1128.242920 
	C611.414856,1128.011841 611.867798,1128.006958 612.660339,1128.000977 
	C613.685791,1127.422119 614.455811,1127.258301 615.657166,1127.764404 
	C615.380310,1130.988159 615.832336,1136.165039 616.991577,1139.629150 
	C613.092590,1143.385742 609.922913,1140.661865 605.968018,1139.570435 
	C600.694275,1138.115112 599.943970,1134.880249 599.187744,1130.846802 
	C598.987244,1129.777100 599.581116,1128.967896 600.734619,1128.167114 
	C602.650208,1127.459717 603.479309,1128.741821 604.434448,1129.538696 
	C606.917969,1131.611084 608.872742,1131.266235 610.701050,1128.242920 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M589.068604,1160.918457 
	C586.100159,1160.128418 583.948425,1158.608643 584.219971,1155.742920 
	C584.461792,1153.190308 587.216614,1152.951538 589.151001,1152.167114 
	C592.756409,1150.704712 596.372314,1148.772095 599.394226,1153.453613 
	C600.068481,1154.498291 602.263062,1154.527588 601.859314,1156.583618 
	C601.458801,1158.623413 599.834167,1159.461426 598.209778,1160.381592 
	C595.347473,1162.002808 592.285828,1159.460571 589.068604,1160.918457 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M478.148163,1081.330322 
	C479.168701,1079.669189 481.169128,1078.327271 482.310303,1079.214478 
	C486.135498,1082.188354 490.156982,1081.843506 494.440247,1081.155273 
	C495.675446,1080.956909 496.534607,1082.001343 496.939270,1083.627930 
	C497.016724,1087.534058 495.120331,1088.902710 491.733917,1089.025879 
	C483.497803,1089.325317 480.560425,1087.784058 478.148163,1081.330322 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M577.995178,1140.692871 
	C581.062073,1139.352661 583.068115,1140.840820 584.964661,1142.504639 
	C587.080017,1144.360352 587.904785,1146.209717 584.585938,1147.722778 
	C584.137817,1147.927002 583.700928,1148.178101 583.304199,1148.469360 
	C581.531433,1149.771362 580.959167,1153.572754 578.373718,1152.553711 
	C575.226807,1151.313110 578.753845,1149.035767 578.669922,1147.141968 
	C578.575684,1145.015259 574.241028,1143.553711 577.995178,1140.692871 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M566.161926,1141.005737 
	C567.869446,1141.142700 569.330078,1140.952759 570.419006,1141.500854 
	C572.114502,1142.354248 573.047913,1144.066162 572.664673,1146.016602 
	C572.307678,1147.833496 570.672119,1147.960083 569.146240,1147.985596 
	C568.161133,1148.002075 567.166565,1148.057251 566.191589,1147.952637 
	C563.639648,1147.678589 561.561584,1146.184204 561.385132,1143.794189 
	C561.192749,1141.188721 563.833923,1141.276245 566.161926,1141.005737 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M541.843140,1092.354370 
	C542.750000,1092.000000 543.500000,1092.000000 544.625000,1092.000000 
	C545.541077,1092.341309 546.044800,1092.778320 546.629883,1093.006836 
	C548.829346,1093.865967 551.340393,1094.830811 550.321960,1097.714600 
	C549.617920,1099.708008 547.556396,1100.929810 545.132263,1100.496582 
	C542.411072,1100.010254 540.551331,1098.696899 541.025635,1095.543213 
	C541.169434,1094.586914 541.461914,1093.652954 541.843140,1092.354370 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M464.869476,1065.233398 
	C464.490326,1064.314087 464.079681,1063.599487 464.254761,1063.083618 
	C465.233276,1060.200317 466.442352,1057.166748 470.343811,1058.408936 
	C473.774628,1059.501343 472.663391,1062.461060 471.796234,1064.586914 
	C470.279419,1068.305664 467.654755,1067.382690 464.869476,1065.233398 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M528.444153,1089.000000 
	C528.000061,1088.013062 527.928467,1087.019653 528.015015,1086.040405 
	C528.200439,1083.941162 527.099854,1081.070679 530.902710,1081.025757 
	C534.403015,1080.984253 536.031189,1082.754272 535.995422,1086.186768 
	C535.988586,1086.844604 535.997986,1087.502686 536.000000,1088.580322 
	C533.629456,1089.000000 531.258850,1089.000000 528.444153,1089.000000 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M610.706482,1127.885254 
	C610.662842,1129.761719 612.019836,1132.381348 609.099670,1132.821899 
	C605.605713,1133.349243 603.289001,1131.255005 601.307251,1128.210815 
	C601.218872,1127.456787 601.388550,1126.921997 601.779236,1126.205078 
	C602.552979,1125.479736 603.010193,1124.840576 604.005432,1124.216064 
	C606.023438,1123.648071 607.517334,1123.779663 608.768555,1125.697021 
	C609.473083,1126.602417 609.962036,1127.178101 610.706482,1127.885254 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M571.026550,1158.997803 
	C565.896729,1159.296753 566.842468,1155.369141 566.189880,1152.826782 
	C565.896179,1151.682617 567.035034,1151.299805 568.218567,1151.599854 
	C570.115967,1152.080688 572.130798,1152.213013 573.476807,1153.962402 
	C574.354431,1155.103027 576.109070,1155.932129 575.660278,1157.578613 
	C575.057007,1159.791992 572.937866,1158.727051 571.026550,1158.997803 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M604.240784,1124.770508 
	C603.529297,1125.319824 603.058533,1125.639526 602.294006,1125.991211 
	C601.783142,1122.982178 597.322815,1124.753296 597.054260,1121.354736 
	C600.173645,1119.567871 603.809448,1120.312500 607.006897,1117.704834 
	C608.992065,1116.085938 608.639526,1119.724609 608.997803,1121.600952 
	C607.970154,1123.696411 606.079651,1123.858765 604.240784,1124.770508 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M468.128784,1076.596191 
	C468.690369,1075.645752 469.399933,1075.353149 469.683441,1076.200562 
	C471.058533,1080.311401 467.101196,1087.023315 463.067993,1087.623291 
	C461.390472,1087.872803 461.049591,1086.819824 461.059937,1085.569214 
	C461.071442,1084.173950 460.745605,1081.814087 462.886688,1082.371094 
	C468.342651,1083.790161 467.264557,1079.611816 468.128784,1076.596191 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M608.872925,1147.054199 
	C608.690796,1148.973145 612.191589,1150.184692 609.520813,1151.880371 
	C607.939636,1152.884155 605.822266,1153.283936 604.681458,1151.424561 
	C603.452087,1149.420776 603.253784,1146.760498 604.942261,1145.006592 
	C606.989807,1142.879761 607.947021,1145.050049 608.872925,1147.054199 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M525.156616,1100.006470 
	C527.041931,1100.305176 529.404358,1099.164307 529.625427,1101.595947 
	C529.815674,1103.689575 528.005920,1104.770142 525.998108,1104.923218 
	C524.208862,1105.059692 521.950317,1105.416138 521.378113,1103.103394 
	C520.748718,1100.559204 522.760803,1100.122681 525.156616,1100.006470 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M517.589600,1095.557617 
	C518.089050,1098.158447 517.716003,1100.123169 514.861938,1099.632446 
	C513.120850,1099.333252 512.691162,1097.575928 513.262451,1096.064453 
	C514.106567,1093.831421 515.625122,1093.502930 517.589600,1095.557617 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M580.761047,1166.703613 
	C579.005615,1168.362183 577.571777,1168.408203 577.182556,1166.056030 
	C576.947571,1164.635864 577.419067,1162.662598 579.052368,1162.664307 
	C581.256042,1162.666748 580.954041,1164.753784 580.761047,1166.703613 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M592.617676,1120.001221 
	C593.126831,1120.304443 593.320740,1120.557617 593.790771,1120.879639 
	C594.136230,1123.465576 592.833374,1124.252563 590.586548,1123.902222 
	C589.551208,1123.740967 588.449585,1123.305298 588.506287,1122.168335 
	C588.627380,1119.741699 590.647339,1120.150635 592.617676,1120.001221 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M504.603424,1103.628906 
	C502.461731,1101.560669 503.572327,1100.588501 505.495087,1100.160767 
	C506.824280,1099.864990 508.443146,1100.231812 508.462799,1101.804932 
	C508.491669,1104.120483 506.606079,1103.993408 504.603424,1103.628906 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M504.154663,1092.993652 
	C504.894165,1090.721191 506.149780,1090.651978 507.487213,1091.882324 
	C508.091278,1092.438110 508.090271,1093.546143 507.490692,1094.102539 
	C506.151917,1095.344604 504.900330,1095.256714 504.154663,1092.993652 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M564.157104,1117.264404 
	C566.234436,1117.864014 566.280212,1119.085693 565.330505,1120.297729 
	C564.659424,1121.154175 563.428162,1121.203735 562.733459,1120.371582 
	C561.550293,1118.954102 562.520630,1118.010620 564.157104,1117.264404 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M558.773682,1153.009277 
	C557.989258,1151.581665 557.942566,1150.477905 559.497986,1150.233398 
	C560.506165,1150.074829 561.516907,1150.703613 561.530762,1151.778198 
	C561.552551,1153.471069 560.575134,1154.005249 558.773682,1153.009277 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M553.709961,1148.414307 
	C553.036865,1146.183472 554.212036,1145.997314 555.684937,1146.253052 
	C556.408203,1146.378784 556.825439,1146.985474 556.657166,1147.651001 
	C556.270447,1149.181030 555.107605,1148.967529 553.709961,1148.414307 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M546.704590,1145.113037 
	C546.639282,1146.404175 546.095825,1146.829712 545.167053,1146.319946 
	C544.582092,1145.998779 544.194702,1145.291504 544.614990,1144.720581 
	C545.205688,1143.918457 545.979370,1144.148926 546.704590,1145.113037 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M491.222900,1112.494385 
	C490.082153,1112.869019 489.362030,1112.611938 489.246216,1111.621582 
	C489.162628,1110.906738 489.634735,1110.332520 490.376678,1110.496460 
	C491.240570,1110.687500 491.684998,1111.342285 491.222900,1112.494385 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M592.044128,1144.466064 
	C592.694214,1144.823853 592.703003,1145.183105 592.237000,1145.500244 
	C592.139465,1145.566528 591.913940,1145.563721 591.819031,1145.494507 
	C591.365723,1145.163330 591.381836,1144.804199 592.044128,1144.466064 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M569.465820,1115.955811 
	C569.823914,1115.306152 570.183411,1115.296143 570.500488,1115.762939 
	C570.566833,1115.860596 570.563965,1116.086304 570.494690,1116.181152 
	C570.163269,1116.634521 569.803955,1116.618042 569.465820,1115.955811 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M476.413208,1086.658936 
	C476.611725,1087.373535 476.362488,1087.634033 475.806824,1087.521973 
	C475.691559,1087.498779 475.536926,1087.334839 475.519684,1087.218140 
	C475.436737,1086.657104 475.710052,1086.422607 476.413208,1086.658936 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M566.782471,1078.307617 
	C564.232788,1074.334717 562.879211,1074.285400 560.878784,1078.193848 
	C559.775574,1080.349243 558.761169,1080.942017 556.256653,1080.368774 
	C551.745605,1079.336182 547.960327,1080.393799 546.826111,1085.747681 
	C546.445068,1087.546387 545.030945,1087.972900 543.403687,1087.962402 
	C541.967468,1087.953247 540.508728,1087.555298 540.287231,1086.040527 
	C539.387878,1079.890991 535.507263,1077.745483 529.793457,1077.724121 
	C528.831848,1077.720459 527.882751,1077.191772 526.908875,1077.004150 
	C525.338684,1076.701538 523.621033,1076.007324 523.661316,1074.428101 
	C523.719910,1072.131592 525.919800,1073.249390 527.202881,1073.010864 
	C529.638428,1072.558228 532.107849,1072.290771 534.549866,1071.869263 
	C535.876160,1071.640259 537.377869,1071.229004 537.694458,1069.789795 
	C538.440613,1066.398438 538.740906,1062.708374 543.789246,1065.508789 
	C543.908752,1065.575073 544.622986,1064.992432 544.696411,1064.637329 
	C544.899353,1063.655762 544.162354,1062.895386 543.394836,1062.807251 
	C538.573547,1062.254272 535.320557,1059.447998 532.583923,1055.733032 
	C532.300293,1055.348022 531.121704,1055.084595 530.818176,1055.331055 
	C525.010681,1060.045288 517.870728,1055.069580 511.425903,1057.997314 
	C509.725006,1058.733765 508.579590,1058.391235 507.245422,1057.288818 
	C506.540466,1056.429321 505.915955,1056.129395 505.256256,1056.442993 
	C501.436493,1058.259033 497.621185,1061.728882 494.137665,1055.760498 
	C493.707245,1055.023071 491.671112,1055.169189 490.366333,1055.010864 
	C489.069214,1054.853394 487.501770,1055.145020 487.210602,1053.330933 
	C486.871460,1051.217651 488.684418,1051.011597 490.041107,1050.525757 
	C490.812225,1050.249756 491.652832,1050.083740 492.471619,1050.037964 
	C499.402496,1049.650635 504.408356,1046.128418 508.043518,1040.444336 
	C508.683533,1039.443726 509.394470,1038.842651 510.430084,1039.420898 
	C512.612305,1040.639038 513.968933,1042.561401 513.705811,1045.110107 
	C513.581482,1046.314209 512.994629,1048.035889 511.178528,1047.445923 
	C508.561584,1046.595703 507.070282,1048.141357 505.602661,1049.713623 
	C505.161987,1050.185669 505.650574,1051.325317 506.241211,1051.312012 
	C508.806305,1051.254395 511.668396,1051.730591 513.569519,1049.493652 
	C514.612305,1048.266602 515.466492,1046.620972 515.745789,1045.046997 
	C517.113220,1037.341675 522.903381,1033.943970 529.185730,1031.223145 
	C534.898743,1028.749146 540.815430,1026.886230 546.836670,1030.685547 
	C547.297302,1030.976074 548.671265,1030.782471 548.686768,1030.676880 
	C549.304382,1026.469971 553.616638,1025.899780 555.613403,1023.426208 
	C557.498535,1021.091064 558.442383,1020.564575 560.543579,1022.089844 
	C562.225830,1023.311096 563.696045,1022.552795 565.632935,1022.049255 
	C565.569458,1027.414551 561.964172,1029.705688 556.457031,1027.913086 
	C555.342651,1027.550293 553.597351,1026.650757 553.537781,1027.909790 
	C553.404724,1030.723999 549.637756,1032.003418 550.793335,1034.927002 
	C551.246582,1036.073730 553.429016,1037.233887 554.094604,1036.411133 
	C557.425659,1032.292847 560.021484,1034.850464 562.992981,1036.766602 
	C564.512024,1037.746094 566.367065,1037.693604 568.285217,1037.229370 
	C569.636841,1036.902100 572.246521,1035.991211 572.299072,1038.362915 
	C572.339355,1040.182007 570.409485,1041.788940 568.068481,1041.789551 
	C566.973694,1041.789795 565.946106,1040.616943 564.700073,1041.643188 
	C564.884583,1043.809326 567.158936,1042.629272 568.058167,1043.691895 
	C569.088989,1044.909790 571.003296,1045.612305 570.520203,1047.660400 
	C570.214844,1048.955200 569.581604,1050.223999 568.180359,1050.710327 
	C566.356384,1051.343262 565.135254,1050.341675 563.756775,1049.219482 
	C559.030762,1045.372559 553.566162,1044.111084 547.532104,1044.968628 
	C545.267151,1045.290405 545.360718,1045.996094 546.194458,1047.893188 
	C548.839844,1053.912354 551.300903,1054.968384 557.325806,1052.111450 
	C558.071838,1051.757690 558.927246,1050.918335 559.569214,1051.533081 
	C562.967224,1054.787109 567.567383,1053.280151 571.382019,1054.703735 
	C573.983643,1055.674805 576.521790,1056.051514 579.108826,1055.014038 
	C584.355286,1052.909912 589.695435,1052.731934 595.569275,1053.999512 
	C596.350647,1054.396851 596.500854,1054.859619 596.225342,1055.694092 
	C592.593567,1060.293823 589.182373,1056.723755 585.385986,1056.001465 
	C582.010803,1059.184692 584.810364,1062.497681 584.877686,1065.745117 
	C584.879272,1065.820923 585.714233,1064.958496 586.665527,1064.908691 
	C588.320618,1066.162109 587.809143,1067.756592 588.030457,1069.193726 
	C588.541809,1072.514526 588.997314,1075.913696 593.521667,1076.134155 
	C594.815735,1076.197144 595.587463,1077.115723 595.933594,1078.332153 
	C596.465088,1080.199951 597.631409,1081.153198 599.604614,1080.866577 
	C601.620239,1080.573975 602.761108,1079.380981 603.375244,1077.413452 
	C603.993225,1075.433838 604.289368,1073.005371 607.275940,1073.027222 
	C609.730774,1073.045288 612.157349,1073.128418 609.923340,1076.526245 
	C609.013062,1077.910645 609.621582,1079.044800 609.956665,1080.648682 
	C606.707336,1083.693604 610.733887,1085.969482 610.213867,1088.746338 
	C608.148804,1090.559204 604.624329,1092.274170 610.410400,1093.083252 
	C612.197937,1093.333252 612.920288,1094.532715 612.988647,1096.616943 
	C611.873718,1096.850098 610.016296,1096.203735 609.730591,1096.624512 
	C605.248230,1103.225952 602.488098,1096.902832 599.146301,1095.308838 
	C598.276917,1094.894165 597.547363,1094.177246 596.669006,1093.792236 
	C591.700256,1091.614746 590.461853,1092.239868 589.087708,1097.552490 
	C588.779114,1098.745605 589.362305,1100.489014 587.476440,1100.720581 
	C585.971008,1100.905518 585.323853,1099.451172 584.321838,1098.621460 
	C581.587585,1096.357056 581.853394,1091.983887 577.899902,1090.360107 
	C576.110901,1089.625244 577.990906,1088.502197 579.144470,1087.766357 
	C584.701538,1084.221680 585.028259,1080.811646 581.080505,1075.538452 
	C578.291443,1071.812988 575.127930,1073.860840 572.082336,1074.386230 
	C570.953552,1074.581055 571.169373,1075.944458 571.487061,1076.945435 
	C572.778687,1081.015625 570.867981,1081.288452 567.366943,1080.049805 
	C566.650330,1079.602783 566.505310,1079.141235 566.782471,1078.307617 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M506.779602,1057.268921 
	C508.121124,1057.238403 509.242279,1057.476807 510.681702,1057.857666 
	C514.192993,1060.493652 517.385925,1062.987305 520.789429,1065.740479 
	C520.547363,1068.405396 518.655212,1068.302124 517.127808,1067.767456 
	C515.208618,1067.095947 513.537720,1067.836670 511.380005,1067.990479 
	C510.266571,1066.308960 508.547974,1065.910400 507.168640,1065.054443 
	C503.242767,1062.618408 503.107483,1060.831665 506.779602,1057.268921 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M571.650269,1018.045593 
	C571.493896,1020.334045 569.497620,1021.020081 567.342773,1021.954773 
	C567.203491,1019.343872 569.307312,1018.777161 571.650269,1018.045593 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M637.016418,1260.105469 
	C637.988525,1260.251465 638.707336,1260.677490 638.246765,1261.312744 
	C637.603516,1262.200195 636.451538,1262.201782 635.799683,1261.325684 
	C635.332642,1260.698120 636.043457,1260.260010 637.016418,1260.105469 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M840.712708,130.827072 
	C841.068115,133.504150 841.346558,135.800064 841.489075,138.104370 
	C841.680420,141.199326 840.824158,143.561722 837.352905,144.425278 
	C835.698242,144.836929 833.414246,145.017227 833.192322,147.350006 
	C832.946655,149.933426 832.105896,151.724457 829.243530,150.781158 
	C826.781311,149.969757 824.620117,148.234314 825.224670,145.313858 
	C825.803528,142.517792 824.719604,139.127579 829.182007,137.561584 
	C832.485657,136.402252 830.932068,132.362854 830.618591,129.504059 
	C830.462036,128.076462 829.361145,126.512611 830.991638,125.447189 
	C832.483887,124.472137 833.793701,125.525101 835.045776,126.397278 
	C836.922180,127.704269 839.375000,128.218124 840.712708,130.827072 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M873.495728,125.663696 
	C870.397034,122.361977 870.281433,119.595123 874.226685,117.404816 
	C877.170654,115.770432 880.747559,114.722641 883.443176,116.338684 
	C884.927490,117.228561 884.623474,121.045425 883.092224,123.219162 
	C880.783020,126.497322 877.867004,128.356827 873.495728,125.663696 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M877.939941,108.682632 
	C877.026855,112.580383 872.705505,114.399490 870.156677,112.312500 
	C869.161865,111.497879 868.938538,110.421082 869.206177,109.218796 
	C869.489929,107.944038 870.362732,107.295441 871.603333,107.105186 
	C873.794128,106.769150 875.832703,107.162155 877.939941,108.682632 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M811.402771,159.334442 
	C812.368225,156.976761 813.631897,155.546036 816.029602,156.239304 
	C817.309631,156.609406 818.594666,157.465286 818.547180,158.924057 
	C818.482056,160.924637 816.763855,162.236496 815.299194,162.958420 
	C812.151733,164.509811 811.440552,162.239319 811.402771,159.334442 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M775.090576,178.592911 
	C774.268555,176.466858 773.723206,174.679337 776.456238,174.146835 
	C777.238403,173.994415 778.551270,174.079025 778.389160,174.997711 
	C778.081665,176.740402 778.332825,179.316864 775.090576,178.592911 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M807.906494,141.447388 
	C807.404541,140.581772 807.087585,139.817856 807.075867,139.049316 
	C807.057861,137.865280 807.416809,136.741699 808.785095,136.475143 
	C809.179260,136.398346 809.759094,136.622498 810.073853,136.907455 
	C811.205383,137.931702 810.537476,139.031479 809.938049,139.964890 
	C809.531799,140.597397 808.809326,141.026779 807.906494,141.447388 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M826.013916,164.555588 
	C826.834167,165.012939 826.937195,165.581146 826.546082,166.139755 
	C826.232117,166.588074 825.735474,166.582977 825.435791,166.121628 
	C825.066223,165.552704 825.174438,164.985962 826.013916,164.555588 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M846.341370,134.413147 
	C845.626831,134.611893 845.366516,134.362823 845.478333,133.807205 
	C845.501526,133.691910 845.665222,133.537216 845.781860,133.519974 
	C846.342529,133.437057 846.577393,133.709885 846.341370,134.413147 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M721.626465,152.947876 
	C721.430237,160.375107 719.192322,161.447296 712.711426,164.311279 
	C707.382446,166.666229 703.112427,168.847961 697.803833,165.155762 
	C697.102417,164.667892 695.845947,165.527542 695.489441,166.571426 
	C695.188049,167.454132 695.787048,168.084122 696.458435,168.659866 
	C697.776428,169.790039 701.195374,170.079666 699.242554,172.695709 
	C698.035278,174.313049 695.306885,175.617859 693.281921,175.589554 
	C690.854736,175.555649 689.160034,172.605148 689.399475,170.589188 
	C689.752563,167.616364 689.203613,164.175598 691.822510,161.325470 
	C696.927795,162.485855 699.490845,160.520752 700.014038,155.533752 
	C700.095642,154.756058 699.989624,153.692581 701.090454,153.640579 
	C701.580200,153.617432 702.289978,154.140640 702.572449,154.612045 
	C703.414246,156.017029 702.725037,158.867264 705.376831,158.505783 
	C708.542480,158.074249 712.346130,158.280365 714.231018,155.013702 
	C716.064026,151.836945 718.300537,151.569168 721.626465,152.947876 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M717.727600,113.280731 
	C721.052429,111.825279 724.046814,110.541794 727.303650,109.901001 
	C729.622498,109.444748 731.883728,108.462639 733.429138,111.125282 
	C735.074646,113.960648 737.250732,116.800034 734.828430,120.247353 
	C733.509399,122.124435 732.463867,124.046280 731.564880,126.215950 
	C729.703918,130.707245 725.275085,128.495026 721.445679,129.000427 
	C718.995850,125.949883 718.077759,122.362694 715.585693,119.460648 
	C713.904358,117.502861 715.698364,115.203033 717.727600,113.280731 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M747.786865,113.009155 
	C745.999329,113.001831 744.674988,113.007607 743.350708,112.998688 
	C737.807007,112.961319 735.369141,109.503273 737.169373,104.295158 
	C737.520813,103.278465 738.086853,102.349075 739.106628,102.255959 
	C743.221619,101.880234 747.371704,101.606026 751.454529,102.402672 
	C753.295837,102.761932 754.259521,104.007904 753.480347,106.102768 
	C752.414917,108.967194 753.653992,110.080208 756.528442,110.024910 
	C758.318848,109.990471 760.732300,109.850067 760.596252,112.394623 
	C760.467773,114.796661 758.057617,113.766029 756.577087,113.985840 
	C753.724121,114.409393 751.026794,113.340416 747.786865,113.009155 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M717.666687,113.000000 
	C716.954224,116.217865 714.577332,119.410339 719.836670,121.693596 
	C722.007568,122.636047 720.787781,125.911514 721.000000,128.572296 
	C720.794434,131.461441 721.886047,134.622086 717.388062,134.988251 
	C718.157593,129.158997 717.069153,127.944313 711.226318,128.795120 
	C709.403992,129.060471 707.985352,128.665878 706.864929,127.215820 
	C705.694214,125.700645 705.902588,124.117294 706.754944,122.587807 
	C707.869690,120.587486 708.863098,118.649063 709.273376,116.274094 
	C709.874634,112.793442 713.476501,114.721878 715.720764,113.261734 
	C716.444458,113.000000 716.888916,113.000000 717.666687,113.000000 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M745.823059,145.679016 
	C743.584290,151.100769 739.838684,152.660828 735.306213,150.162903 
	C734.972412,147.386810 734.585999,144.394196 737.765015,143.399414 
	C740.585693,142.516724 743.615295,142.333862 745.823059,145.679016 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M757.880981,131.496063 
	C758.032227,133.946045 758.186768,136.022430 755.157837,135.982834 
	C753.288574,135.958389 751.376160,135.828735 750.403748,134.014664 
	C749.508850,132.345428 750.561951,131.190125 752.065796,130.496933 
	C754.080017,129.568451 756.234924,127.725151 757.880981,131.496063 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M766.207825,63.611183 
	C765.256470,62.549438 764.911194,61.592876 765.113708,60.582577 
	C765.403931,59.134796 765.727112,57.195244 767.563110,57.402740 
	C769.630371,57.636368 769.026367,59.747814 768.927917,61.083359 
	C768.828979,62.424454 770.047852,65.431801 766.207825,63.611183 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M766.741211,100.315018 
	C766.823120,103.195801 765.125061,103.149261 763.416382,102.770195 
	C762.208984,102.502335 761.893433,101.440536 762.523865,100.484718 
	C763.611755,98.835228 765.052979,98.518982 766.741211,100.315018 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M726.957031,149.656799 
	C726.269348,151.053101 725.406128,150.701019 724.265991,150.115753 
	C724.293335,148.844620 723.297180,146.960785 725.110352,146.720657 
	C726.296692,146.563583 726.565002,148.238205 726.957031,149.656799 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M771.218384,67.742950 
	C770.349854,67.148949 770.406494,66.601738 771.108032,66.328224 
	C771.427063,66.203835 772.135986,66.378647 772.277588,66.634117 
	C772.669006,67.340424 772.166504,67.682808 771.218384,67.742950 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M766.044189,78.466019 
	C766.694031,78.824097 766.703430,79.183556 766.236816,79.500526 
	C766.139282,79.566833 765.913574,79.563965 765.818787,79.494652 
	C765.365540,79.163177 765.381897,78.804001 766.044189,78.466019 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M761.658691,115.586983 
	C762.373108,115.388275 762.633301,115.637558 762.521667,116.192886 
	C762.498535,116.308105 762.334656,116.462738 762.218018,116.479965 
	C761.657471,116.562782 761.422729,116.289955 761.658691,115.586983 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M637.254395,315.320251 
	C637.039856,308.265015 637.429993,307.670654 644.180054,306.563019 
	C646.030151,306.259430 645.901123,305.477020 645.577576,304.244995 
	C645.250977,303.001068 644.340942,301.444244 645.837830,300.684845 
	C647.303650,299.941132 648.049133,301.731598 649.012695,302.528534 
	C650.073608,303.405945 651.159851,304.399109 652.546082,303.551422 
	C656.765503,300.971191 660.045959,302.795563 663.143127,305.632965 
	C664.041138,306.455688 665.013855,306.995605 666.887817,307.262146 
	C667.855957,308.285950 667.867371,309.038116 667.411560,310.133850 
	C666.253662,311.438049 665.057678,311.859070 663.352173,311.984039 
	C656.990906,312.451355 650.787964,312.033081 645.430542,316.499329 
	C644.371643,317.011292 643.602966,317.130157 642.416382,317.117340 
	C640.540222,316.525116 638.872559,316.543579 637.254395,315.320251 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M696.792847,299.334229 
	C699.070679,298.673370 701.141418,298.346741 703.606079,298.007599 
	C705.770996,296.263000 707.663757,295.221222 710.239197,297.295288 
	C710.546753,300.183136 708.869141,301.079193 706.812073,301.311737 
	C704.682617,301.552490 702.700439,302.019531 700.635254,303.529144 
	C698.896057,304.459381 697.351501,304.448975 695.473511,304.623138 
	C694.120422,304.584839 693.278931,304.978546 692.152588,305.654907 
	C691.128113,306.039215 690.408325,306.120911 689.310364,306.096741 
	C685.148254,305.184692 681.826904,306.894501 678.025208,308.123505 
	C676.867981,308.447205 676.069214,308.522797 674.837708,308.470032 
	C672.159973,308.268066 669.770325,309.049103 667.348755,307.288818 
	C667.012024,307.011505 667.002319,307.002197 666.996948,306.996490 
	C673.019958,304.897797 677.249207,298.349243 685.174316,300.616028 
	C688.659241,301.612823 692.986633,302.282593 696.792847,299.334229 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M729.599548,216.999893 
	C731.792480,217.904694 733.584961,218.809372 735.688721,219.857025 
	C736.153442,222.991165 734.530396,224.455414 731.778687,223.921646 
	C728.136414,223.215134 722.921143,227.434631 721.046631,220.357178 
	C720.176086,219.008865 719.925598,217.929764 720.747070,216.362396 
	C724.054138,213.698578 726.253540,218.410599 729.599548,216.999893 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M645.781982,286.138428 
	C645.388306,290.318542 642.316040,289.413605 640.162415,289.229004 
	C638.784241,289.110809 637.886658,287.363159 638.180664,285.647827 
	C638.441223,284.127808 639.672852,284.024994 640.866455,284.027588 
	C642.667236,284.031494 644.623901,283.676910 645.781982,286.138428 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M632.639893,314.745544 
	C633.758118,314.999969 634.513855,315.002411 635.647339,315.009399 
	C636.552246,315.636261 636.813477,316.346375 636.617126,317.532898 
	C635.119446,319.698273 632.974121,319.470795 630.747437,319.968384 
	C629.523865,320.003723 628.653381,319.863159 627.462708,319.872833 
	C626.732178,319.863831 626.321411,319.805542 625.460327,319.307251 
	C624.922363,316.644623 625.677979,315.068573 628.629761,315.007141 
	C629.992004,314.176361 631.071838,313.926605 632.639893,314.745544 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M720.648865,215.952286 
	C721.000000,217.066956 721.000000,218.133301 721.000000,219.599518 
	C720.835083,219.998642 720.668518,219.982010 720.505554,219.999466 
	C716.923828,220.383118 714.933228,217.511414 714.526550,215.198288 
	C713.930054,211.806076 716.298889,213.315308 717.828430,214.341782 
	C718.636902,214.884338 719.473694,215.384598 720.648865,215.952286 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M656.668823,255.069641 
	C658.849182,255.834137 660.698425,256.668884 662.773804,257.751831 
	C662.836060,259.357269 662.665588,261.875122 660.885559,259.960358 
	C659.404480,258.367126 653.985535,260.137238 656.668823,255.069641 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M680.345093,239.032318 
	C681.793518,239.226807 683.774231,238.566101 683.263794,240.655136 
	C682.886047,242.200821 681.250732,242.086319 679.993652,241.406479 
	C678.686584,240.699615 677.537354,239.823929 680.345093,239.032318 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M636.261719,318.179871 
	C635.966797,317.172485 635.986938,316.452759 636.016174,315.373474 
	C636.025208,315.013977 636.513794,315.015472 636.758118,315.012726 
	C638.504272,315.136810 640.074646,314.681793 641.739075,315.818176 
	C641.174744,319.149872 638.844299,318.736786 636.261719,318.179871 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M652.306641,263.168365 
	C654.136780,261.567047 654.724854,262.572601 654.922180,264.146606 
	C655.020020,264.926758 655.093140,266.150391 654.221436,266.215027 
	C652.466370,266.345001 652.490051,264.710907 652.306641,263.168365 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M647.759155,252.235107 
	C648.915405,253.167404 649.120544,254.071884 647.928040,254.627747 
	C646.914673,255.100067 645.885376,254.608093 645.625854,253.556015 
	C645.265808,252.096558 646.468872,252.158875 647.759155,252.235107 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M632.925720,314.662506 
	C631.935120,314.998108 630.867737,314.998657 629.400208,314.998383 
	C629.879211,312.454834 631.188232,312.380920 632.925720,314.662506 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M621.369446,321.313568 
	C621.014160,320.576996 621.008301,320.125092 621.001221,319.334167 
	C622.067627,318.985260 623.135193,318.975372 624.602661,318.941956 
	C625.002563,318.918365 625.044250,319.102997 625.063354,319.195679 
	C625.466858,322.250763 623.593689,321.925659 621.369446,321.313568 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M707.485840,234.765976 
	C707.846680,235.592941 707.819153,236.220825 707.097534,236.381607 
	C706.875244,236.431122 706.321350,235.929016 706.271423,235.622177 
	C706.164856,234.967010 706.487915,234.473389 707.485840,234.765976 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M634.044067,288.466064 
	C634.694031,288.824158 634.703186,289.183594 634.236755,289.500610 
	C634.139221,289.566895 633.913513,289.563965 633.818726,289.494659 
	C633.365417,289.163208 633.382019,288.804047 634.044067,288.466064 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M538.238770,238.232071 
	C538.319214,234.829224 543.516541,230.553802 546.483459,231.269287 
	C548.670654,231.796707 549.274170,233.728409 548.575378,235.617126 
	C546.951416,240.006012 549.038879,241.374924 553.249695,241.531967 
	C553.285461,236.960693 554.649780,232.191788 549.062622,229.314743 
	C547.687378,228.606583 546.495300,226.542862 547.976074,224.543793 
	C548.785400,223.451157 548.989807,222.393219 547.726685,221.552155 
	C546.777039,220.919861 545.956665,221.343765 545.015320,221.929886 
	C542.634521,223.412384 532.103516,222.592209 530.154846,220.982864 
	C529.248535,220.234436 528.772034,219.218536 529.510986,218.412354 
	C531.394226,216.357803 532.039001,214.525879 530.368408,211.859650 
	C529.610535,210.650101 531.328003,210.010849 532.566956,210.038696 
	C535.970459,210.115143 538.895325,211.327942 539.896423,214.787766 
	C540.948486,218.423752 542.572632,218.694138 545.140564,216.354675 
	C546.382874,215.222855 547.848022,214.563065 549.179504,215.676407 
	C554.415039,220.054337 561.340942,216.367630 566.881226,219.519470 
	C568.022339,220.168625 569.832947,218.922653 570.528931,217.487885 
	C571.385010,215.723007 571.158020,213.957901 569.828857,212.372177 
	C565.218018,206.871399 558.746460,204.889694 552.201782,203.138718 
	C550.358643,202.645615 549.110962,204.227631 548.062256,205.487686 
	C546.273010,207.637604 544.032837,208.161850 541.378113,208.028275 
	C537.870605,207.851761 535.564087,205.220673 536.384644,201.949814 
	C536.819580,200.215973 537.947876,200.004929 539.416443,200.938980 
	C542.410278,202.843002 543.980469,201.515152 543.916504,198.447983 
	C543.826416,194.129547 547.531433,192.289474 549.416626,189.303192 
	C550.774109,187.152771 552.809692,188.670258 554.148071,189.820099 
	C557.083679,192.342239 558.981873,193.161072 559.049255,187.974609 
	C559.103088,183.826538 561.288696,182.451218 565.363953,183.561584 
	C568.616150,184.447678 571.959045,184.313019 575.593445,183.061478 
	C576.723755,182.419449 577.265076,183.278046 577.919617,183.243347 
	C577.629211,182.483444 576.963379,182.030411 576.226746,181.224670 
	C576.215515,177.856949 580.061401,180.028931 580.915894,177.349350 
	C585.030396,174.319687 590.444397,175.440659 594.630554,172.086639 
	C595.822998,172.193848 596.749146,172.386810 596.554016,173.508560 
	C596.295776,174.993118 595.612122,176.611038 597.965210,176.920731 
	C599.819397,177.164734 601.692627,177.046844 603.138672,175.693802 
	C604.454651,174.462433 602.937439,173.303650 602.883850,172.149338 
	C602.620544,166.480682 599.295532,160.938354 603.052063,155.027237 
	C604.813477,152.255493 601.710571,151.316177 599.287659,151.012115 
	C597.598694,150.800140 595.383179,151.603287 595.072815,148.788696 
	C594.766357,146.009247 596.610107,144.859421 598.802856,144.276245 
	C602.805603,143.211716 606.801758,142.026001 610.890808,144.344894 
	C612.934631,145.503937 614.818787,145.288300 615.024963,142.235001 
	C615.173462,140.034668 616.332397,138.622253 618.587158,139.279495 
	C620.662109,139.884308 621.084290,141.714081 620.591431,143.733459 
	C619.814148,146.917816 618.806946,149.936783 619.649719,153.453445 
	C620.202759,155.760941 617.569153,155.764114 615.720215,156.039673 
	C608.280090,157.148605 606.883362,158.756088 607.011902,165.796570 
	C607.047241,167.734085 606.576294,169.981750 609.176941,170.788528 
	C611.865479,171.622559 613.146606,170.022507 614.122864,167.851212 
	C614.381714,167.275345 614.959412,166.842804 615.695312,166.173065 
	C615.948853,168.911392 617.015442,169.093964 619.229248,167.523880 
	C620.652954,166.514114 622.818542,165.116226 623.609680,167.911209 
	C624.276733,170.267960 625.298279,173.918350 621.763306,174.617889 
	C618.539124,175.255920 617.699829,176.493515 618.268616,179.740936 
	C616.445984,177.967712 614.866638,176.545685 612.351501,176.044708 
	C611.199707,175.804169 610.673401,174.900970 609.450439,175.271057 
	C607.270386,180.235535 604.843201,183.814545 598.523560,182.544861 
	C595.258118,181.888779 594.242737,184.314926 596.071838,187.353943 
	C599.263245,192.656403 599.222717,192.724945 592.137390,193.334076 
	C589.810669,191.150177 583.150330,189.689087 581.156494,191.153427 
	C579.419067,192.429443 581.302246,193.611801 581.679565,195.020584 
	C584.428833,193.016388 587.056641,190.932861 590.922485,192.532394 
	C592.380676,195.297485 591.317810,197.964142 591.503540,200.575684 
	C591.710632,203.487564 588.771423,202.694321 586.629639,203.259552 
	C587.257324,204.887482 588.242798,206.352631 588.577454,208.072922 
	C589.656494,213.619995 588.828979,215.261887 583.201233,216.443069 
	C579.946899,217.126129 578.339600,219.045212 577.502625,221.933350 
	C576.949158,223.842911 576.459167,225.730118 575.451172,227.500839 
	C572.066650,233.446228 571.130371,233.729630 566.196777,229.331039 
	C564.229980,227.577515 562.215210,228.197418 559.789795,228.627380 
	C557.359985,228.949326 556.427856,229.825150 557.773499,232.256271 
	C557.967773,233.047241 557.995789,233.512100 557.923523,234.329330 
	C557.805115,234.902878 557.737976,235.127335 557.530273,235.669144 
	C556.421509,237.623383 557.379944,238.743637 558.372314,239.917969 
	C559.467712,241.214233 560.321289,242.705688 559.536194,244.427963 
	C558.623657,246.429855 556.593079,246.693695 554.869568,246.329361 
	C551.365417,245.588669 548.863770,247.024063 546.211548,249.438019 
	C545.608276,249.580536 545.355713,249.596649 544.726562,249.538483 
	C542.866089,248.755890 541.250793,247.855011 539.706360,248.031906 
	C531.049622,249.023468 528.861633,242.192062 525.783142,236.327774 
	C530.019775,235.045761 533.364319,237.877441 537.596313,237.998306 
	C537.548706,241.348358 539.897461,241.161545 542.282471,241.371368 
	C543.029724,241.944016 542.439514,241.259338 542.826416,241.799271 
	C543.134277,242.228821 543.110413,242.276535 542.704834,241.675385 
	C541.425293,240.153412 539.616699,239.752121 538.238770,238.232071 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M638.774048,138.223557 
	C641.994995,139.639954 643.450012,137.964401 644.755493,135.220062 
	C647.651550,136.515091 647.203796,138.806778 646.448853,141.098145 
	C645.765381,143.172607 646.041260,144.634293 648.412109,145.273804 
	C651.585266,146.129761 654.683044,147.402023 657.905212,147.895920 
	C660.500854,148.293777 662.071167,149.179382 663.109985,151.665161 
	C664.191711,154.253372 663.042297,155.765213 661.288391,157.193436 
	C658.997498,159.058945 657.709167,161.476868 657.108032,164.316956 
	C656.774170,165.894547 656.816956,168.015915 654.349365,167.479187 
	C652.137146,166.998032 651.177185,165.175583 651.097229,163.009537 
	C650.832031,155.822983 650.406372,155.398071 643.666992,156.620193 
	C642.277649,156.872147 641.360901,156.656799 640.985718,155.268753 
	C640.600098,153.842392 639.885254,152.318497 640.089966,150.953568 
	C640.743164,146.596756 640.633911,142.425125 638.774048,138.223557 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M608.757446,174.301483 
	C609.100098,173.327698 609.264893,172.609283 610.137817,172.775482 
	C611.499023,173.034607 611.984314,174.036835 611.992432,175.667694 
	C612.946838,183.767593 621.024292,185.104187 624.876709,190.256134 
	C625.448364,191.020615 626.204468,191.647125 626.937622,192.668732 
	C624.578857,192.864395 623.152588,194.507477 624.214722,196.283295 
	C626.216248,199.629623 625.044373,202.871780 625.000366,206.587585 
	C623.628113,206.840759 621.985901,207.478058 621.082031,205.349335 
	C621.761475,202.834183 621.109863,200.835327 620.460266,198.754288 
	C619.257019,194.899445 617.919067,196.537079 616.235229,198.757050 
	C613.693848,197.916016 611.648682,198.641983 609.340881,199.955322 
	C606.667419,199.218445 604.191589,198.787994 601.999878,197.683548 
	C598.668335,196.004761 597.665710,191.322693 600.646667,190.139420 
	C607.355713,187.476303 606.036865,181.245987 607.987000,176.477707 
	C608.224121,175.897873 608.184448,175.188339 608.757446,174.301483 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M623.081970,161.346924 
	C626.156860,161.464050 624.525391,157.740433 626.707397,157.047455 
	C626.839417,160.127090 628.372742,161.901642 631.356201,162.655228 
	C635.101562,163.601273 637.254456,166.091248 637.880127,169.867935 
	C638.022461,170.726822 637.427307,171.960281 636.703979,171.658295 
	C633.604980,170.364532 629.619202,169.406616 628.488892,166.197845 
	C627.422546,163.170425 624.478882,163.649963 623.081970,161.346924 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M647.000000,182.666672 
	C645.581970,183.696228 644.520874,185.164444 642.349731,184.994095 
	C641.686646,184.528595 641.373291,184.057175 641.029968,183.293182 
	C640.421936,182.314667 640.258301,181.544388 640.764404,180.342865 
	C641.166687,179.999878 641.333313,179.999756 641.750000,179.999512 
	C643.607727,178.741806 645.166443,178.647018 646.827881,180.597931 
	C647.000000,181.444443 647.000000,181.888901 647.000000,182.666672 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M632.065308,137.663635 
	C630.919861,137.889557 629.582397,138.173691 629.266907,136.662094 
	C628.999878,135.382568 629.851074,134.481247 630.792908,133.873596 
	C632.260437,132.926834 633.848450,132.166962 635.692749,131.164520 
	C636.601379,134.266602 633.368835,135.187012 632.065308,137.663635 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M636.352905,131.011078 
	C636.365540,129.045151 637.722229,127.943474 639.471375,128.556625 
	C641.389648,129.229050 641.121033,131.122971 640.569641,132.769333 
	C640.259766,133.694458 639.870178,134.592865 639.258301,135.751709 
	C637.076050,134.875290 640.856750,131.121124 636.352905,131.011078 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M647.225098,180.775742 
	C645.672791,180.277161 644.268066,179.884613 642.371948,180.000275 
	C642.196106,178.116547 643.176819,176.909164 645.127441,177.140228 
	C647.008545,177.363052 647.859375,178.607132 647.225098,180.775742 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M640.678223,180.062561 
	C641.000000,180.750137 641.000000,181.500290 641.000000,182.625519 
	C639.011536,182.439163 638.245850,181.604034 640.678223,180.062561 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M633.945923,139.686920 
	C633.113708,139.893509 632.356567,139.609756 632.054077,138.313065 
	C632.886292,138.106461 633.643433,138.390182 633.945923,139.686920 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M634.280029,152.960571 
	C634.180359,152.454071 634.360657,151.908737 634.770508,151.181702 
	C635.970764,151.896454 635.745361,152.519012 634.280029,152.960571 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M627.312500,154.946030 
	C627.106812,154.114029 627.390015,153.356262 628.687439,153.054886 
	C628.893188,153.887115 628.609192,154.644165 627.312500,154.946030 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M544.666931,248.891739 
	C545.000000,249.000000 545.500000,249.000000 545.750000,249.000000 
	C547.743042,248.473846 548.602112,249.651978 548.878052,250.934433 
	C549.911011,255.735062 552.985352,253.744965 555.839783,253.369644 
	C559.245422,252.921844 559.824951,251.352005 559.771606,248.063126 
	C559.708679,244.183823 560.143921,240.552628 556.571167,237.864075 
	C555.947144,237.394531 556.164185,236.318665 556.773315,235.262909 
	C557.000000,235.000610 557.000000,235.000000 557.000000,234.999695 
	C557.503174,234.511429 557.958679,233.973602 558.842102,233.404739 
	C560.570190,232.906265 561.919617,233.365936 563.626648,233.593384 
	C566.177307,234.161880 568.324341,231.995346 570.779785,233.737457 
	C570.564514,236.208344 569.232300,238.826874 573.640015,238.992538 
	C573.419373,241.601639 575.875671,242.567581 576.932800,244.661804 
	C575.639343,244.443405 574.199829,244.022476 572.938599,243.293304 
	C571.477478,242.448532 569.858582,241.138428 568.603455,242.884857 
	C567.419128,244.532761 567.357178,247.038269 569.167053,248.278931 
	C574.227356,251.747620 573.612915,253.532562 568.012512,255.482498 
	C565.795044,256.254578 564.544861,259.392365 561.356934,259.954163 
	C559.799316,261.290741 557.970459,261.188019 556.552246,262.008362 
	C554.170532,263.386078 552.915649,265.348633 553.482788,268.140381 
	C554.031555,270.841736 554.193115,273.828400 557.619629,274.789246 
	C559.488525,275.313263 560.819458,275.597656 560.425049,272.724915 
	C559.960266,269.339752 559.718994,265.888519 560.743958,262.239685 
	C564.025635,262.172028 566.124817,263.080658 565.600952,266.808075 
	C564.827454,272.311035 567.142639,274.527130 572.558289,273.629700 
	C573.968628,273.395966 575.453918,272.321533 576.553528,274.026184 
	C576.812256,274.427277 576.642944,275.388763 576.316589,275.831604 
	C575.170593,277.386536 573.733887,276.830536 572.317383,276.165192 
	C571.423340,275.745270 570.002319,275.091583 569.639221,276.107147 
	C568.363647,279.674774 563.352112,281.479004 564.176575,285.607239 
	C565.169128,290.576721 564.903992,294.731171 561.013306,298.626373 
	C557.483582,296.028687 551.621338,298.471405 548.803955,293.672638 
	C548.367249,292.928864 547.542908,293.809509 546.979553,294.531403 
	C544.405945,297.829102 541.211121,300.103271 536.855286,300.531891 
	C534.814758,300.732666 534.812805,301.042664 536.011719,305.073914 
	C537.046631,308.553680 535.985901,310.630524 533.951355,313.455383 
	C531.175720,317.309235 527.243042,318.761871 523.092285,320.641876 
	C520.472839,323.302521 517.923340,322.601288 515.044250,320.727600 
	C514.213013,319.556152 514.149170,318.676849 514.750549,317.382050 
	C516.210449,315.806549 517.589111,314.464417 519.248535,315.909271 
	C524.336853,320.339691 523.899780,316.248657 524.072266,312.916779 
	C524.096680,312.445923 524.374390,311.988190 524.811279,311.251465 
	C527.211975,310.034729 530.091858,310.033966 530.384277,306.429382 
	C530.423950,305.175873 530.035278,304.358246 529.659790,303.181946 
	C527.827332,297.248138 532.521362,296.718048 536.038086,295.068909 
	C534.276245,292.466217 530.166809,291.067108 531.567200,287.318604 
	C533.208496,282.925293 537.522095,286.278168 541.039001,284.804626 
	C538.018311,283.042938 535.550598,282.491821 532.723816,283.624756 
	C530.088989,284.680817 520.445557,281.797119 518.909973,279.586426 
	C518.068909,278.375610 517.776245,276.981079 518.865417,275.921967 
	C520.847107,273.995026 523.247864,272.893036 526.114319,273.577576 
	C527.096130,273.812042 527.866577,274.520508 528.646179,275.146210 
	C530.174866,276.373138 531.875427,277.084625 533.718689,276.080963 
	C535.926147,274.879028 535.048340,272.890717 534.528381,271.205170 
	C533.377197,267.473694 535.101929,265.489197 538.409729,264.390198 
	C540.757324,263.610229 542.424683,264.430603 543.509644,266.744507 
	C540.007507,261.592133 543.353577,256.018127 542.338989,250.721573 
	C542.163330,249.804626 543.265808,249.033112 544.666931,248.891739 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M487.234100,316.748901 
	C480.091339,321.765350 478.042725,328.203674 481.153503,335.467804 
	C482.116058,337.715485 483.891083,338.281555 486.053589,337.450867 
	C490.982361,335.557587 495.968719,335.598083 501.177277,335.308136 
	C507.035614,334.982056 510.592743,336.030426 514.160645,339.942627 
	C516.187378,342.164917 519.497375,338.986847 521.786255,341.735107 
	C522.265991,342.953583 521.674255,343.381927 520.563171,343.804871 
	C517.948914,343.504730 515.739563,344.678131 513.145386,343.911926 
	C510.967194,342.608612 509.448883,341.844330 506.969360,343.169281 
	C503.588287,344.976044 499.499634,344.101196 495.740784,344.361023 
	C491.817871,344.632233 487.861053,345.827393 483.888000,344.689941 
	C482.535461,344.302734 482.312683,345.345032 481.758636,346.175934 
	C479.568756,349.459839 476.630615,351.377045 472.518829,350.450165 
	C469.774536,349.831604 468.412109,351.187103 467.728302,353.516479 
	C466.512238,357.659332 463.648529,358.860718 459.315552,358.210815 
	C457.702454,357.867859 456.484283,357.497070 455.012085,356.692932 
	C453.680878,355.566589 453.144989,354.398376 452.773010,352.741211 
	C452.473053,349.881470 453.944672,348.398529 455.982208,347.201965 
	C452.906158,347.654694 450.048828,351.123840 446.949066,346.894226 
	C446.278046,343.055359 445.676849,339.531372 450.115662,337.560730 
	C452.093414,337.972198 452.583649,340.638733 455.161346,339.685669 
	C458.141266,338.972931 462.641968,341.378815 462.345581,335.276398 
	C464.038208,333.216461 463.648071,330.626923 465.313995,328.563599 
	C468.231720,326.488708 466.029510,323.532074 467.302856,321.035736 
	C469.782257,318.040527 471.427948,314.690216 476.028290,314.938019 
	C478.532867,315.072937 477.709351,311.697723 478.786591,310.815857 
	C477.987030,313.432739 475.417480,314.539459 472.525543,314.700317 
	C468.948700,314.899261 467.212891,311.217987 469.158325,307.933838 
	C471.367889,304.203766 481.078491,299.538940 485.382172,300.184479 
	C487.759430,300.541077 490.396973,301.787933 490.558044,297.522552 
	C490.602966,296.332733 492.177734,296.044189 493.767090,296.254242 
	C495.344666,296.897247 496.160919,298.071320 497.361420,298.637634 
	C499.406921,299.602539 501.700684,299.949371 503.586273,298.684509 
	C505.749695,297.233215 505.401672,294.985962 504.193329,292.542511 
	C503.583954,290.526886 502.082428,289.219330 502.297272,286.922913 
	C504.174835,283.426270 506.369049,280.555511 507.048615,276.827209 
	C507.398499,274.907562 509.104645,273.863800 511.207581,274.051392 
	C513.205322,274.229553 514.305786,275.652222 514.232117,277.391052 
	C514.082886,280.913635 515.314514,283.296234 518.532959,284.876617 
	C519.751526,285.474915 520.068726,286.970520 519.258545,288.698242 
	C516.381958,288.023254 513.746277,287.090729 511.152924,286.052399 
	C509.714691,285.476593 508.225342,284.641968 507.380554,286.665710 
	C506.627136,288.470642 507.924469,289.388580 509.326691,290.151398 
	C510.347504,290.706726 511.443420,291.197174 512.315369,291.940125 
	C515.623047,294.758453 515.413696,297.324585 511.347260,298.859619 
	C507.940430,300.145660 509.028412,302.385651 509.408997,304.539215 
	C509.690491,306.132111 511.250824,307.465118 510.687714,309.229797 
	C510.405975,310.112671 509.792328,311.159088 509.029449,311.555603 
	C507.897339,312.144073 507.236023,311.220703 507.023529,310.099701 
	C506.771576,308.770477 505.773834,307.482544 504.724335,308.434387 
	C501.262756,311.573853 497.775818,310.398285 494.101562,309.255890 
	C493.442810,309.051056 492.529297,309.665497 491.366699,309.950989 
	C486.880463,310.567535 489.511932,314.803650 487.234100,316.748901 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M519.000000,289.250000 
	C519.074646,286.697601 518.308472,285.108093 515.697937,285.010162 
	C512.568420,284.892822 511.749695,283.439362 512.603333,280.401672 
	C513.120667,278.560730 514.259644,275.619873 510.943146,275.265015 
	C507.817474,274.930573 507.849518,277.767181 508.045990,279.893707 
	C508.376373,283.468994 505.187408,284.459778 503.270691,286.789734 
	C502.447296,287.434937 501.854797,287.291840 500.989777,287.036682 
	C499.908966,286.689697 499.383820,287.043976 498.842896,288.027588 
	C498.522095,288.506897 498.374390,288.697876 497.951965,289.153564 
	C491.409729,292.378326 488.851227,290.801971 489.669098,283.980927 
	C490.083588,280.524323 488.219696,276.603394 491.694000,273.298584 
	C492.182983,270.997803 493.335602,270.042847 495.290344,270.019104 
	C497.132996,269.996704 498.331451,269.309479 499.063354,267.448639 
	C500.496704,263.804504 502.326569,264.853119 503.877289,267.383698 
	C505.846680,270.597504 507.189850,269.820465 508.580170,266.936432 
	C509.139740,265.775574 510.003754,264.699554 510.935333,263.797943 
	C511.808380,262.953033 513.082397,262.654633 514.113770,263.501526 
	C515.140991,264.345032 514.214111,265.308960 513.902649,266.187561 
	C513.207153,268.149139 512.018433,270.000000 512.537292,273.800110 
	C513.972717,269.535767 518.819824,270.884369 518.989014,266.382111 
	C518.932495,263.960205 519.417603,262.238312 521.857117,262.172791 
	C525.429688,262.076843 524.744263,259.524414 524.438110,257.525146 
	C523.994385,254.627502 521.970337,255.612869 520.245605,256.215820 
	C517.083130,257.321442 514.763489,257.386871 515.899292,252.874725 
	C516.234070,251.545059 515.471802,250.259552 515.582397,248.922699 
	C515.775269,246.591461 516.577515,244.586487 519.111084,244.100677 
	C521.753662,243.593964 523.607910,244.775528 524.549316,247.335281 
	C524.849548,248.151550 524.251587,249.574310 525.827148,249.556870 
	C526.659912,249.547684 527.504395,249.102341 527.829529,248.274963 
	C528.251221,247.201920 527.582825,246.366547 526.900330,245.617661 
	C525.096130,243.637878 522.965210,242.050232 520.806030,240.483276 
	C519.452454,239.501053 517.858582,238.259064 518.774109,236.415680 
	C519.766113,234.418060 521.653687,234.482910 523.742432,235.763977 
	C524.444458,235.999619 524.888916,235.999847 525.666687,236.000336 
	C527.944824,238.705078 531.863586,239.822998 531.629028,244.392380 
	C531.528870,246.344238 534.547852,245.966232 536.324158,245.981613 
	C539.327576,246.007614 542.384338,245.739136 544.815308,248.681641 
	C544.600098,249.293106 543.855469,249.582016 543.851807,249.879913 
	C543.793579,254.674713 543.666809,259.480774 543.962952,264.259003 
	C544.033813,265.401794 545.051636,266.613129 544.985352,267.995819 
	C544.921692,269.321777 545.076111,271.133698 543.486755,271.293762 
	C541.363159,271.507721 542.229492,269.472412 542.015686,268.339478 
	C541.809265,267.245667 542.278381,265.729523 540.799988,265.388885 
	C538.843933,264.938202 537.297546,266.247925 535.863037,267.309784 
	C534.688538,268.179199 535.295837,269.335602 535.910400,270.417786 
	C537.285217,272.838898 538.256409,276.014099 535.681458,277.482727 
	C533.272705,278.856537 529.803650,279.908020 527.381470,276.132111 
	C524.795532,272.100891 521.925415,275.819672 519.766113,276.842712 
	C518.147827,277.609436 519.764954,279.404449 521.358887,279.851593 
	C523.892456,280.562347 526.617493,280.743134 528.340393,283.210602 
	C528.855530,283.948364 529.988342,284.097137 530.508240,283.317566 
	C533.403076,278.977142 537.183105,282.126709 540.546143,282.084106 
	C543.308167,282.049072 543.131165,284.252258 542.880859,286.060944 
	C542.561768,288.366821 540.953674,287.456085 539.451111,287.386841 
	C537.168701,287.281647 534.061890,284.342712 532.771729,287.434052 
	C531.371948,290.787933 535.073486,291.928497 537.499573,293.267639 
	C538.325195,293.723328 539.574280,293.983307 539.443848,295.234558 
	C539.289551,296.715118 537.902222,296.606750 536.836426,297.025238 
	C534.255981,298.038391 529.182495,296.417206 530.956299,302.649841 
	C526.975891,306.094330 524.348755,302.651428 521.064880,300.632843 
	C520.515930,299.810883 520.438354,299.269562 520.773315,298.350281 
	C521.126648,297.694275 521.320496,297.441315 521.790771,297.119965 
	C525.032837,293.332794 520.335449,292.652283 519.241638,290.243042 
	C519.000000,290.000000 519.000000,289.500000 519.000000,289.250000 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M620.670288,321.039124 
	C622.385925,320.948792 623.921997,321.465271 624.977539,319.609100 
	C625.502563,319.262390 625.922668,319.236359 626.659302,319.218994 
	C627.421814,319.134918 627.872314,319.075897 628.667847,319.049561 
	C631.058960,318.690338 633.109009,318.327209 635.552795,317.928162 
	C637.796692,317.458008 639.646729,317.023743 641.742188,316.343658 
	C642.732239,316.058746 643.476929,316.019714 644.593201,315.956085 
	C650.063293,312.002625 656.415710,312.489777 662.613159,311.051971 
	C664.120117,310.757996 665.230042,310.444702 666.683960,310.084412 
	C669.018311,307.651367 672.078674,308.503876 674.859985,307.364624 
	C675.877441,307.177155 676.578735,307.179382 677.630493,307.193298 
	C681.430359,306.518494 684.689819,304.918640 688.663086,305.092682 
	C689.753784,305.084442 690.492126,305.040680 691.599182,304.968384 
	C692.542297,304.440094 693.098633,303.919525 693.953369,303.326355 
	C695.864197,303.150269 697.513794,303.089661 699.576172,303.005371 
	C708.642700,299.864838 717.566528,297.719788 726.850830,295.427094 
	C727.598267,295.224213 727.996765,295.210876 728.694031,295.185364 
	C730.693176,295.046814 732.390503,294.911804 733.943542,293.406891 
	C740.531189,293.997131 745.940735,289.886536 752.542725,289.955200 
	C753.395203,289.543793 753.848145,289.192474 754.715210,289.032349 
	C757.066833,288.679962 759.131042,288.349396 761.586060,287.986389 
	C763.208130,286.781738 764.765320,287.056091 766.621582,286.984924 
	C767.427124,286.949707 767.857422,286.921722 768.609680,286.863647 
	C769.655029,286.388214 770.311218,286.487000 770.930298,287.445374 
	C763.513123,291.099548 755.402039,291.955780 747.393066,294.366272 
	C746.595154,294.685028 746.101135,294.914612 745.286987,295.005341 
	C741.962280,295.723602 738.908020,296.423248 735.474792,297.247803 
	C734.594238,297.599579 734.116394,297.885712 733.289978,298.016663 
	C721.628723,300.751099 710.242188,303.457275 698.479492,306.292786 
	C697.389709,306.754883 696.704590,307.144196 695.728638,307.356934 
	C688.274719,308.904419 681.087097,310.579773 673.515686,312.390533 
	C672.399292,312.781464 671.724243,313.156036 670.770630,313.458435 
	C665.636230,314.334473 660.781433,315.285034 655.539551,316.374146 
	C654.622070,316.680054 654.111267,316.913910 653.274170,317.002258 
	C652.572815,317.063385 652.149719,317.106750 651.423584,317.252808 
	C650.607727,317.580719 650.119873,317.872955 649.267334,317.943970 
	C648.564392,318.010345 648.142822,318.074554 647.419312,318.245117 
	C646.606689,318.578735 646.119995,318.870697 645.267639,318.931824 
	C644.562256,318.988739 644.137695,319.047791 643.410095,319.216553 
	C642.600769,319.566040 642.118164,319.866486 641.263916,319.935760 
	C639.262695,320.308868 637.542908,320.681915 635.464844,321.179474 
	C634.601807,321.548431 634.125610,321.861542 633.273926,321.958435 
	C626.963196,323.383331 620.934021,324.798492 614.521545,326.350067 
	C613.614990,326.665833 613.111328,326.907959 612.277466,326.994141 
	C603.970276,328.772614 595.939941,330.535522 587.532715,332.447968 
	C586.624817,332.735413 586.110962,332.936554 585.295288,333.065247 
	C583.245300,333.505249 581.467102,333.906708 579.413208,334.424500 
	C578.615356,334.702179 578.109619,334.920837 577.288452,335.011261 
	C559.223999,339.329895 541.435852,343.641876 523.637573,347.911499 
	C521.229797,348.489105 518.771057,348.854126 516.074829,349.499756 
	C515.203247,350.942596 514.189514,351.818909 512.863953,352.094940 
	C506.946869,353.326996 501.182312,355.404999 494.614563,355.274841 
	C493.934875,355.046204 493.674561,354.922546 493.091736,354.511230 
	C491.879425,352.176300 492.997742,351.007782 494.978027,349.904175 
	C497.339752,349.330200 499.551270,349.960388 501.632233,348.269318 
	C504.427704,347.451233 506.810974,346.461517 509.783020,346.503174 
	C511.656281,346.529449 512.944519,345.846008 514.599304,345.128632 
	C515.625183,344.764893 516.332886,344.621857 517.412659,344.526794 
	C519.849487,344.107391 522.072510,344.195953 524.176514,342.601868 
	C526.164001,342.048248 527.787476,341.502899 529.877319,341.513885 
	C531.642883,341.539124 533.054810,341.583038 534.524902,340.388245 
	C535.053711,340.107056 535.273682,340.008545 535.840759,339.805115 
	C539.609314,339.523132 542.541138,337.429321 546.285889,337.073120 
	C547.077576,336.793884 547.542175,336.681061 548.367432,336.570374 
	C550.935181,335.882568 553.342102,335.875000 555.696777,334.423340 
	C556.495911,334.129547 556.976135,334.044403 557.835205,334.014343 
	C563.743958,334.785919 568.664978,332.189056 574.195190,330.987457 
	C576.661011,329.855804 578.886475,329.129456 581.503662,328.560120 
	C582.917419,328.561615 583.884338,328.264893 584.974854,327.411407 
	C585.601562,327.313324 585.937378,327.305939 586.525146,327.293732 
	C587.701050,327.752411 588.627197,328.183167 589.894531,327.332306 
	C590.872742,327.165497 591.587708,327.152130 592.660278,327.146515 
	C593.838135,326.862457 594.113770,326.023590 594.980225,325.402344 
	C595.604980,325.312805 595.940979,325.308960 596.528931,325.300110 
	C601.045349,326.944702 604.723999,324.656708 608.870850,323.365021 
	C610.192383,323.175751 611.220032,323.161865 612.633118,323.159973 
	C614.122742,322.653168 615.195190,321.995544 616.781616,322.244720 
	C618.145752,321.759338 619.233093,321.404358 620.670288,321.039124 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M558.326477,335.122681 
	C557.573242,335.173553 557.167664,335.167480 556.458130,335.152832 
	C554.252380,336.827332 551.131348,334.995758 549.242065,337.713867 
	C548.547302,337.998047 548.108765,338.021576 547.340942,338.054871 
	C544.122070,337.115967 541.437378,339.667664 538.266357,338.273132 
	C540.947083,332.379181 545.261963,329.396637 551.895325,330.399139 
	C555.094482,330.882660 556.184021,328.451599 556.325500,325.958313 
	C556.406982,324.521790 555.917664,323.055573 556.852295,321.333099 
	C557.379639,320.606689 557.838501,320.459595 558.688232,320.779694 
	C560.984802,323.793793 562.939331,326.594818 566.914612,323.738373 
	C568.249207,322.779358 569.865173,323.454132 570.463989,324.408142 
	C573.568359,329.353363 577.872498,328.111938 582.627319,327.034668 
	C583.587341,327.717926 583.743713,328.478394 583.228455,329.652649 
	C580.318787,329.697784 578.119629,331.162292 575.366760,331.997620 
	C569.346191,331.916931 564.183105,334.428528 558.326477,335.122681 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M514.636108,319.744781 
	C517.348206,320.617767 519.693665,321.274628 522.584717,321.000000 
	C526.776306,322.329926 529.100647,325.502380 531.885193,328.071808 
	C533.735535,329.779205 532.768738,331.081238 530.884399,331.777557 
	C528.465820,332.671295 523.213806,329.944031 523.117249,327.440216 
	C522.946716,323.015320 520.502563,324.531311 518.494873,325.448730 
	C516.554260,326.335571 514.949463,327.958588 512.624329,327.976929 
	C510.592133,327.992950 508.375793,328.048431 507.383636,325.932251 
	C506.252899,323.520355 508.578644,322.906860 509.993195,321.868652 
	C510.368561,321.593170 510.575806,321.088623 510.930725,320.343079 
	C511.991333,319.173859 513.070190,318.925964 514.636108,319.744781 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M559.063965,320.670959 
	C558.555542,321.000336 558.111084,321.000671 557.333313,321.000946 
	C556.599487,320.723236 556.196960,320.202881 555.798767,320.206238 
	C552.572449,320.233215 549.621704,319.645416 549.507874,315.711945 
	C549.393799,311.772217 553.136353,311.418671 555.656860,310.380829 
	C559.259033,308.897430 560.648987,312.734253 562.997864,314.228912 
	C563.895630,314.800232 563.169861,315.579376 562.578735,316.227722 
	C561.373840,317.549316 560.272095,318.964996 559.063965,320.670959 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M520.649719,298.047485 
	C521.000000,298.444031 521.000000,298.889313 521.000000,299.668518 
	C522.705444,301.711395 524.345337,303.473877 525.686157,305.936646 
	C525.958618,307.056488 525.861938,307.790314 525.222046,308.745605 
	C521.854675,309.250244 518.755127,309.791168 515.686829,308.917511 
	C512.871521,308.115875 511.717163,304.226288 514.164734,302.856262 
	C516.527466,301.533752 517.638428,298.836487 520.649719,298.047485 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M524.970703,309.333649 
	C524.979858,308.263916 525.016357,307.523010 525.070068,306.411652 
	C526.966858,303.335541 529.087830,303.467743 531.647095,305.816406 
	C531.476013,309.785187 530.846069,313.224609 525.410583,311.126892 
	C525.057861,310.539856 525.028015,310.101135 524.970703,309.333649 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M496.048492,328.836304 
	C496.306885,326.755341 494.042969,324.875732 495.977295,323.449097 
	C497.475098,322.344452 499.212860,323.392303 500.503479,324.383606 
	C503.315247,326.543304 500.408569,328.287567 499.617432,329.801178 
	C498.619537,331.710449 496.970337,331.353333 496.048492,328.836304 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M487.339172,317.066223 
	C487.026001,314.897797 486.834717,312.764832 487.137543,310.704559 
	C487.456268,308.536072 488.554596,307.291626 490.832367,309.703552 
	C491.000000,310.444672 491.000000,310.889069 491.000000,311.666718 
	C491.017944,312.876923 490.978729,313.766144 491.771240,314.762939 
	C492.000000,315.000000 492.000000,315.499390 492.000000,315.749084 
	C492.597961,316.682190 492.775452,317.454529 492.245911,318.662170 
	C490.123993,319.386261 489.092163,317.817749 487.339172,317.066223 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M531.224976,342.736633 
	C528.956787,343.025879 526.938477,343.100525 524.489136,343.207275 
	C523.923706,343.042023 523.789185,342.844635 523.821228,342.330078 
	C527.738708,340.682770 531.489685,339.352631 535.625549,338.017517 
	C536.582458,338.702820 536.736084,339.476624 536.243225,340.679626 
	C536.012573,341.025787 535.544434,341.062256 535.309692,341.066101 
	C534.121277,342.164917 532.571838,341.782562 531.224976,342.736633 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M536.412109,341.033020 
	C536.009766,340.272095 536.006958,339.518433 536.007324,338.388611 
	C536.451294,338.007751 536.892151,338.002960 537.663513,337.991913 
	C540.868225,338.595551 543.563171,336.007385 546.749512,337.787292 
	C543.611633,339.056549 540.211670,340.048401 536.412109,341.033020 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M608.747925,323.137146 
	C605.677551,326.443573 601.836670,328.154419 596.940186,325.625366 
	C600.630981,324.558319 604.481140,323.821472 608.747925,323.137146 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M519.009216,290.371094 
	C524.861328,292.142090 525.500916,293.343536 522.330078,296.937744 
	C522.695435,294.109192 518.627075,293.488098 519.009216,290.371094 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M487.721802,323.066956 
	C490.427734,321.828033 490.989868,323.254608 490.675598,325.212280 
	C490.492401,326.353424 489.364136,327.719391 488.342468,327.423676 
	C486.016663,326.750580 487.111328,324.862305 487.721802,323.066956 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M561.000122,299.398651 
	C565.058105,298.947968 563.731506,302.021149 564.000000,304.584106 
	C560.647644,304.621429 561.036743,302.086060 561.000122,299.398651 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M519.986938,334.624084 
	C518.860352,332.649323 517.378418,330.902496 519.281494,327.395233 
	C519.356384,330.725372 522.609924,332.055847 519.986938,334.624084 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M503.248047,313.054413 
	C504.780701,313.161621 506.192505,313.408905 505.526184,314.953979 
	C504.872925,316.468689 503.315216,316.165588 502.253448,315.337738 
	C500.967834,314.335236 501.315704,313.444153 503.248047,313.054413 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M523.656555,342.011536 
	C523.996399,342.216675 524.004944,342.420471 524.035889,342.931824 
	C523.189270,345.936249 520.418213,344.848816 518.362793,345.950226 
	C516.970215,344.406921 517.817688,343.623352 519.611694,343.029236 
	C520.544678,342.806427 521.091431,342.631439 521.819092,342.226410 
	C522.441772,342.000946 522.883545,342.005554 523.656555,342.011536 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M514.998291,319.623047 
	C513.931885,319.997681 512.866150,319.997833 511.400848,319.997437 
	C510.832153,317.655762 512.071716,316.834473 514.614990,317.002869 
	C514.999695,317.750305 514.999390,318.499420 514.998291,319.623047 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M616.749329,321.948425 
	C616.033936,323.058350 615.010925,324.006073 613.225403,323.367676 
	C613.321167,320.645355 614.766602,320.932861 616.749329,321.948425 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M492.280640,319.119446 
	C492.000000,318.249725 492.000000,317.499420 492.000000,316.373962 
	C493.751038,316.221802 494.696045,317.396362 494.528442,318.971832 
	C494.414795,320.040375 493.293549,319.532593 492.280640,319.119446 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M589.735962,327.120544 
	C589.473083,329.056122 588.497131,329.612976 586.895752,327.580566 
	C587.622803,327.213348 588.468445,327.137787 589.735962,327.120544 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M583.322510,329.948364 
	C582.999268,329.246399 583.008301,328.507233 583.028564,327.399200 
	C583.536926,327.063873 584.033997,327.097382 584.898438,327.225769 
	C585.060242,328.390320 585.018982,329.562042 583.322510,329.948364 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M594.921936,325.269287 
	C594.884338,326.242157 595.388428,327.903931 593.226562,327.342133 
	C593.112000,326.167847 593.394470,325.333099 594.921936,325.269287 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M491.995697,314.665710 
	C490.449799,314.745178 489.662323,314.056091 490.766296,312.211487 
	C491.815094,312.571045 491.983215,313.417236 491.995697,314.665710 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M546.341248,320.413025 
	C545.626587,320.611816 545.366638,320.362335 545.478333,319.806976 
	C545.501526,319.691742 545.665283,319.537109 545.781982,319.519867 
	C546.342773,319.436951 546.577271,319.709991 546.341248,320.413025 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M711.602966,170.136353 
	C712.896667,170.631470 714.028137,172.011276 713.252441,172.216843 
	C710.278809,173.004852 707.729980,175.149994 704.471130,174.972275 
	C702.510498,174.865356 701.908875,173.752319 702.098755,171.998489 
	C702.292603,170.207657 703.635010,169.684555 704.945984,170.263290 
	C707.162170,171.241623 709.149048,170.250397 711.602966,170.136353 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M658.165894,182.955414 
	C658.548706,184.183533 659.069275,185.329910 658.717224,186.049377 
	C657.895813,187.728668 658.595825,190.872849 655.282043,190.459290 
	C653.909607,190.288010 652.584351,189.138336 653.370117,187.668518 
	C654.425232,185.694733 652.868652,181.063019 658.165894,182.955414 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M647.737305,193.783417 
	C648.000000,194.000488 648.000000,194.500244 648.000000,194.750122 
	C646.024414,194.856613 643.522217,197.735672 642.177429,194.267731 
	C641.482788,192.476501 642.233337,190.284348 644.120972,189.780472 
	C647.070435,188.993118 645.810974,192.734055 647.737305,193.783417 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M644.632935,202.950409 
	C644.999756,203.750229 644.999512,204.499954 644.998413,205.624542 
	C644.504089,205.998215 644.009949,206.014343 643.517334,205.992676 
	C641.882568,205.920776 639.710876,206.093735 640.257812,203.605606 
	C640.856445,200.881897 642.848938,202.809753 644.632935,202.950409 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M626.294861,213.844360 
	C624.637817,212.833313 625.098267,211.235321 625.000000,209.384705 
	C626.436157,209.050491 628.094360,208.915985 628.548523,210.690399 
	C628.930054,212.180847 627.544495,212.875549 626.294861,213.844360 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M561.000000,261.666687 
	C562.605835,266.346405 560.554382,271.180481 562.831177,275.482117 
	C563.266174,276.304047 562.851379,277.396057 561.455811,277.568634 
	C556.962952,278.124207 553.059875,274.291351 551.851318,268.060730 
	C551.092712,264.149994 553.717102,260.310059 557.922424,259.398010 
	C558.647766,259.240692 559.512878,259.727783 560.656738,259.958618 
	C561.000000,260.444458 561.000000,260.888885 561.000000,261.666687 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M582.582764,260.000977 
	C583.177673,262.061890 585.042786,262.364349 586.364624,263.235840 
	C587.229736,263.806213 587.949097,264.546051 587.720459,265.648956 
	C587.468567,266.864441 586.381104,266.902893 585.381897,266.988525 
	C579.951904,267.453827 582.172852,269.562195 584.691040,271.832520 
	C584.862671,273.075470 585.197876,274.796265 583.504333,274.487946 
	C581.803040,274.178223 579.649902,273.577576 579.262817,271.554993 
	C578.856995,269.434204 577.980103,267.557831 577.336426,265.571991 
	C576.056580,261.623291 577.921692,259.582703 582.582764,260.000977 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M576.855042,247.323517 
	C579.806335,247.851135 582.291443,248.792892 584.223450,251.505768 
	C586.365173,254.513107 590.300842,251.691910 593.605835,252.998566 
	C591.615479,256.866608 588.035828,253.753601 585.086792,254.454926 
	C583.775879,254.766693 582.251160,254.179672 580.411743,254.000610 
	C574.646606,253.525528 574.646606,253.525528 576.855042,247.323517 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M571.375000,234.000000 
	C568.531311,233.199402 566.765503,235.571350 564.223877,235.225586 
	C563.160950,232.023804 558.733643,232.061584 558.781372,228.262436 
	C562.355408,225.238739 566.139954,225.483002 567.891418,228.946671 
	C569.729065,232.580704 570.967712,231.850006 573.003967,229.108078 
	C575.512207,225.730560 575.107971,221.340729 577.451965,217.906372 
	C578.696594,216.082703 579.846680,214.853622 582.165344,214.982697 
	C584.181702,215.094955 586.997803,215.124237 587.381775,213.114365 
	C587.840576,210.712692 586.962158,207.946884 586.225891,205.468643 
	C585.821167,204.106384 582.416321,204.092361 583.563354,202.019638 
	C584.782532,199.816620 587.115479,201.466202 588.986267,201.750839 
	C589.424133,201.817474 589.910278,201.567001 590.836731,201.358124 
	C588.701721,198.678177 589.155090,196.146469 590.950562,193.354095 
	C591.166565,192.872406 591.333191,192.744202 591.749878,192.807983 
	C593.581970,191.852295 595.152893,190.670670 597.315613,191.710373 
	C597.468201,189.553009 595.554260,190.179123 595.017029,189.272568 
	C593.749023,187.132919 592.193787,184.813263 593.624451,182.390350 
	C594.808105,180.385727 597.791443,179.893204 599.378235,180.611679 
	C604.527710,182.943344 605.884644,179.934875 606.980591,176.088943 
	C607.217896,175.256210 607.431519,174.510361 608.652832,174.081512 
	C609.871094,178.591507 606.943542,182.785309 607.742920,187.491592 
	C608.123413,189.731400 605.591858,190.003403 603.697937,190.007996 
	C601.413025,190.013519 600.256042,191.416367 600.247803,193.414398 
	C600.239136,195.500076 601.815796,196.496353 603.781860,196.954208 
	C605.631653,197.384964 608.396240,195.816925 608.979004,199.606781 
	C609.000000,200.750000 609.000000,201.500000 609.000000,202.625000 
	C604.703064,204.336685 601.031128,207.644485 595.293152,206.982681 
	C597.711060,210.427139 597.656860,214.007034 597.579163,217.575684 
	C597.515747,220.486404 598.270264,223.003250 600.002075,225.450516 
	C602.960083,229.630600 602.002625,231.333603 596.389160,231.868622 
	C595.382935,231.418793 594.860840,230.919693 594.205566,230.045135 
	C593.690125,229.176712 593.552490,228.533020 593.248535,227.954575 
	C592.145142,225.855103 591.720642,223.092911 588.669006,222.808792 
	C585.410767,222.505432 583.613220,224.529251 582.095215,227.444214 
	C581.766296,227.920380 581.615662,228.098038 581.187744,228.489838 
	C578.048584,229.341248 576.241211,231.387527 574.245117,233.754272 
	C573.250000,233.999588 572.500000,233.999786 571.375000,234.000000 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M595.789124,231.382324 
	C597.608459,230.883835 599.614990,231.478714 600.424561,229.550201 
	C601.164856,227.786545 599.393066,226.893539 598.374390,225.934723 
	C596.678162,224.338165 596.049683,222.535080 595.931580,220.197327 
	C595.698608,215.585434 597.695801,210.357620 592.706848,206.838455 
	C592.123962,206.427322 592.333801,205.580246 592.786682,205.035645 
	C593.571289,204.092224 594.547241,204.248886 595.483276,204.774811 
	C598.335266,206.377274 600.607239,206.452972 602.647949,203.230515 
	C604.021851,201.060959 606.353882,203.533813 608.614014,202.998825 
	C609.106689,203.886169 609.390381,204.643661 610.687439,204.945953 
	C612.158630,204.997604 613.043884,205.528824 613.875122,206.704865 
	C613.699707,207.551147 613.515625,208.230453 613.076294,208.626862 
	C611.982788,209.613419 609.870239,207.111313 609.346069,209.337891 
	C608.796997,211.670181 611.379089,211.876617 612.695740,212.923233 
	C614.171692,214.096512 615.573792,215.459991 613.959961,217.371353 
	C612.602539,218.979019 610.907104,219.947739 609.078613,217.341370 
	C609.120667,216.878586 609.241272,216.757187 609.180969,216.817902 
	C608.538086,216.841827 608.033325,216.755676 607.622803,216.511230 
	C606.604187,215.904678 606.114441,213.671432 604.618958,214.830200 
	C603.394775,215.778824 604.022339,217.528702 604.617615,218.957428 
	C606.307190,223.012848 603.640015,231.073685 599.364441,233.946732 
	C596.686829,235.049789 595.819275,233.886566 595.789124,231.382324 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M581.245911,228.235825 
	C581.000732,223.799622 583.538452,221.703094 587.273376,221.080170 
	C592.758911,220.165283 593.433533,224.719437 594.951050,228.634552 
	C593.981567,230.091919 593.681763,231.661835 592.275391,232.839081 
	C586.396729,235.542633 580.589600,234.661774 574.398499,234.011749 
	C574.664246,230.556503 575.474792,227.264572 580.594666,228.000626 
	C582.118591,229.172714 583.273376,230.294342 585.009705,230.595108 
	C584.027588,229.607880 582.431030,229.522507 581.245911,228.235825 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M592.164917,233.374298 
	C592.125366,231.324875 591.516052,229.233917 594.622742,229.016663 
	C595.257629,229.496643 595.515259,229.992661 595.886475,230.744354 
	C596.108704,232.504181 596.765869,233.569702 598.664185,233.955902 
	C599.281494,234.761505 599.437805,235.602356 599.864563,236.270370 
	C602.722351,240.743301 602.223206,241.899628 597.034607,242.817810 
	C594.404053,243.283295 592.876892,242.607925 592.975952,239.583084 
	C593.039917,237.628876 593.312439,235.605957 592.164917,233.374298 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M616.335815,199.044067 
	C616.045959,197.524475 615.808899,195.986664 616.898376,194.735748 
	C617.556274,193.980270 618.253540,193.223267 619.355774,193.259064 
	C620.667419,193.301620 620.983337,194.303406 620.955750,195.330170 
	C620.869995,198.522552 625.385376,201.341522 621.362854,204.905273 
	C620.833313,205.000381 620.666687,205.000137 620.250000,204.999649 
	C619.272522,204.386932 619.019348,203.574432 619.006226,202.329010 
	C618.890869,200.496155 618.234436,199.430069 616.335815,199.044067 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M590.721924,244.739029 
	C587.707275,246.950943 584.840576,245.986450 582.082764,243.354645 
	C584.814636,243.492691 587.629211,243.985382 590.721924,244.739029 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M614.331909,206.931244 
	C612.869751,206.954758 611.937195,206.538071 611.151245,205.281311 
	C611.943665,203.366486 613.221863,202.370819 614.957214,203.765335 
	C616.141174,204.716751 616.389038,205.984177 614.331909,206.931244 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M608.218262,242.743011 
	C607.349792,242.148911 607.406494,241.601639 608.108032,241.328140 
	C608.427185,241.203751 609.136169,241.378540 609.277710,241.634064 
	C609.669067,242.340500 609.166321,242.682831 608.218262,242.743011 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M618.766357,202.211517 
	C619.814819,202.571014 619.983093,203.416962 619.995667,204.665192 
	C618.449097,204.745316 617.662903,204.055710 618.766357,202.211517 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M591.944824,265.796448 
	C593.888733,264.677124 595.776978,262.859924 597.317505,265.328094 
	C598.358826,266.996582 596.655029,268.205444 595.300537,268.598511 
	C593.368835,269.159149 591.093140,269.438660 591.944824,265.796448 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M652.883667,202.768326 
	C652.164001,202.816223 651.671570,202.668640 651.862915,202.100937 
	C652.074097,201.474609 652.651794,201.195572 653.239563,201.607071 
	C653.384338,201.708481 653.174011,202.317230 652.883667,202.768326 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M255.677124,191.660095 
	C253.764069,195.430664 254.732269,197.757980 258.194855,199.764801 
	C262.036530,201.991333 261.637573,204.176910 258.013519,206.767151 
	C254.067978,209.587158 250.272034,211.109268 245.641632,208.840164 
	C245.197037,208.622284 244.695877,208.509491 244.272537,208.260300 
	C242.707245,207.339066 240.034424,210.614288 239.475800,207.353622 
	C239.271393,206.160538 241.347672,204.479523 242.541473,203.164474 
	C244.976517,200.482193 247.984650,198.120941 247.000519,193.411743 
	C249.276413,189.910843 252.173889,189.912094 255.677124,191.660095 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M204.350006,251.956879 
	C201.495865,250.558838 200.528870,251.801819 199.742538,254.127823 
	C199.280121,255.495728 199.493515,257.945526 197.113617,257.678375 
	C195.089249,257.451172 194.126297,255.793488 194.009613,253.374451 
	C194.124359,252.087082 194.855118,251.750870 195.773102,251.188141 
	C197.330734,248.645325 198.485382,246.113953 201.771210,245.249847 
	C202.745926,244.242371 202.602493,243.004135 203.728653,242.152466 
	C206.935959,241.546661 208.710449,237.921585 212.603088,239.000549 
	C213.092941,240.710541 212.308243,242.096161 211.693054,243.643723 
	C210.725052,246.078735 209.548065,249.156326 213.009796,250.904312 
	C214.210312,251.510513 215.615906,251.676849 215.718353,253.351929 
	C215.762421,254.072678 215.339401,254.655563 214.608902,254.780334 
	C212.789825,255.091049 210.734116,255.497147 209.576431,253.694885 
	C208.298889,251.706024 206.955505,250.796524 204.350006,251.956879 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M192.047119,222.638214 
	C188.049698,224.055038 184.246964,225.405334 180.185318,222.759399 
	C178.228897,221.484879 176.214325,220.691467 177.225433,218.050476 
	C178.331680,215.161011 179.630600,211.094910 183.080338,211.626770 
	C186.808228,212.201538 191.027817,212.107101 194.673218,214.822632 
	C195.808899,218.135300 192.580124,219.658615 192.047119,222.638214 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M256.366516,192.001892 
	C253.237610,192.028885 250.438751,191.717468 247.399475,192.940765 
	C245.289383,192.732727 244.690125,191.549728 245.217545,190.125046 
	C246.271805,187.277191 245.976685,184.712769 244.612442,182.027283 
	C243.537857,179.912003 244.737656,178.498459 246.831696,179.277222 
	C249.984497,180.449753 253.104233,181.165665 256.453461,181.147415 
	C257.435333,181.142090 258.492645,181.658264 258.382080,182.919128 
	C258.286316,184.011032 257.235901,183.899033 256.453827,183.988449 
	C255.116806,184.141327 253.609894,184.208115 253.464752,185.926422 
	C253.335434,187.457474 254.633759,188.277084 255.802689,188.833771 
	C257.680267,189.727936 259.385040,191.464157 261.763519,190.590149 
	C263.582947,189.921555 264.059357,191.266373 264.860107,192.698227 
	C264.155884,194.110580 262.945221,194.584213 261.329163,194.963043 
	C260.241974,193.055832 259.082306,191.682800 256.366516,192.001892 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M226.000427,237.400040 
	C226.501511,237.421371 227.106674,237.766205 227.483170,238.278931 
	C228.490433,239.650757 228.013596,242.537308 230.320984,242.381973 
	C232.669144,242.223877 232.832413,239.793182 233.132828,237.758026 
	C233.404495,235.917786 234.780685,236.154785 235.567993,237.240540 
	C237.088394,239.337219 238.790039,241.724472 236.464859,244.226288 
	C234.760056,246.060593 233.973114,248.036743 233.975998,250.475159 
	C233.977310,251.572281 234.337234,253.025620 232.950867,253.510666 
	C231.247498,254.106644 231.234238,252.530060 230.848679,251.505569 
	C230.735840,251.205765 230.541000,250.709625 230.356522,250.696854 
	C223.779083,250.241760 225.587692,245.693787 225.999771,241.414673 
	C226.000092,239.933777 226.000168,238.866928 226.000427,237.400040 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M186.638000,235.746643 
	C187.750000,236.000092 188.500000,236.000198 189.625000,236.000458 
	C192.072601,237.643799 194.054443,239.319717 192.144897,242.673355 
	C190.225906,242.796463 188.418823,242.740921 186.686111,242.353119 
	C184.551620,241.875366 183.126526,242.101456 182.981049,244.683975 
	C182.906189,246.012909 182.704590,247.444336 180.951904,247.493515 
	C180.455139,247.507462 179.745422,246.953094 179.470200,246.468185 
	C178.762909,245.222046 178.928925,243.903397 179.856644,242.814270 
	C180.794357,241.713425 181.857635,240.719543 182.932953,239.339401 
	C183.000122,238.250153 183.000259,237.500305 183.000488,236.375534 
	C183.991364,235.179153 185.068024,234.912292 186.638000,235.746643 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M239.683762,198.774689 
	C236.658905,199.219864 233.209900,201.859894 231.619598,199.777710 
	C229.770798,197.357086 232.969681,194.067642 233.272491,190.981766 
	C233.392197,189.761719 234.419449,189.078522 235.863220,189.596283 
	C238.949310,190.703049 241.096832,195.287109 239.683762,198.774689 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M210.894989,230.403915 
	C211.413269,230.619614 211.585815,230.646011 211.723831,230.729507 
	C213.257034,231.657043 216.648331,229.842331 216.407913,232.661377 
	C216.224609,234.810669 212.798462,235.063095 211.001511,234.599792 
	C208.393890,233.927475 206.140366,234.642059 203.730392,234.931198 
	C202.754196,235.048325 201.627441,234.955353 201.342728,233.726685 
	C201.117569,232.754959 201.880676,232.331955 202.681320,231.966736 
	C205.180222,230.826782 207.197586,227.568039 210.894989,230.403915 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M262.590149,171.000488 
	C263.304565,171.126740 263.557709,171.320618 263.879700,171.790802 
	C264.027771,172.829361 263.860992,173.725464 264.118469,174.476028 
	C265.022217,177.110596 264.641968,179.218597 261.647064,179.794220 
	C259.571869,180.193069 257.311340,179.544067 257.176727,177.053955 
	C257.040649,174.535812 256.957825,171.518265 260.686584,171.027664 
	C261.177155,170.963120 261.682037,171.007202 262.590149,171.000488 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M210.497253,220.433533 
	C213.415436,219.651779 219.309738,223.024689 219.763962,225.426392 
	C219.872604,226.000793 219.740707,226.795197 219.398590,227.243561 
	C218.713867,228.140945 217.469696,228.230957 216.733841,227.635452 
	C214.289490,225.657288 210.677887,224.694122 210.497253,220.433533 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M281.398315,177.505554 
	C280.589172,176.952042 280.027130,176.582367 279.616577,176.087112 
	C278.475464,174.710556 276.046265,173.659424 278.252045,171.321014 
	C279.870697,169.605011 281.304443,170.374313 282.234375,171.942810 
	C283.359558,173.840698 285.732758,176.106155 281.398315,177.505554 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M214.301071,207.170273 
	C214.827271,206.621292 215.327454,206.142456 215.664688,206.238113 
	C217.965195,206.890701 220.742630,206.920731 221.557022,209.882645 
	C221.812469,210.811691 220.946320,211.676727 220.188049,211.568619 
	C217.592468,211.198639 214.848389,210.829727 214.301071,207.170273 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M201.092758,206.061218 
	C203.438904,206.088242 204.219315,207.108856 203.738037,208.961365 
	C203.340485,210.491547 202.102432,210.950714 200.659454,210.942749 
	C199.643112,210.937134 198.556519,210.854233 198.270493,209.638763 
	C197.798416,207.632660 198.719437,206.467377 201.092758,206.061218 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M192.999573,248.637207 
	C191.120163,248.849823 188.635269,249.513382 188.325958,246.700134 
	C188.114822,244.779709 189.990463,245.142593 191.615143,245.002350 
	C193.104202,245.856155 192.980667,247.086945 192.999573,248.637207 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M222.114655,202.116272 
	C220.489380,203.014267 219.274155,203.140518 219.284454,201.126068 
	C219.289795,200.080978 220.084427,199.287216 221.129562,199.284332 
	C223.141068,199.278793 223.018265,200.491501 222.114655,202.116272 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M261.942993,211.693436 
	C261.162933,212.876190 260.325867,213.752365 259.244385,214.814270 
	C257.483185,213.643921 258.154510,212.415451 259.285980,211.300064 
	C260.126465,210.471558 261.078827,209.676880 261.942993,211.693436 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M223.234985,195.753693 
	C223.157608,194.464203 223.081375,193.253372 224.550873,193.616592 
	C225.604721,193.877060 226.099289,194.909485 225.628357,195.924408 
	C225.073364,197.120483 224.167648,196.919113 223.234985,195.753693 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M203.783142,241.766434 
	C203.947006,243.136093 204.090256,244.363037 202.335419,244.931976 
	C201.342499,243.312561 199.702057,241.181183 203.783142,241.766434 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M232.225067,222.921677 
	C232.528137,221.245789 233.282227,221.095688 234.228897,222.016602 
	C234.851822,222.622589 235.186081,223.554306 234.471161,224.171829 
	C233.339279,225.149536 232.808365,224.045578 232.225067,222.921677 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M219.643555,235.012482 
	C220.000000,235.000000 220.000778,235.499115 220.001312,235.748642 
	C219.776215,237.004547 219.486435,238.014694 218.137466,237.649979 
	C217.781158,237.553635 217.328995,236.995697 217.306641,236.626373 
	C217.224808,235.273987 218.280869,235.140427 219.643555,235.012482 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M196.746506,244.477478 
	C196.315826,243.312302 196.769653,242.656891 197.641510,242.484390 
	C198.391922,242.335907 198.844818,242.932892 198.746567,243.645172 
	C198.609756,244.636948 197.883560,244.881622 196.746506,244.477478 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M206.555267,223.986084 
	C207.012741,223.165512 207.581116,223.062637 208.139786,223.453827 
	C208.588150,223.767792 208.583191,224.264511 208.121658,224.564270 
	C207.552597,224.933884 206.985748,224.825806 206.555267,223.986084 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M195.625610,251.000000 
	C195.507614,251.580719 195.014542,252.161438 194.261047,252.871399 
	C193.687119,252.528870 193.373642,252.057129 193.030090,251.292694 
	C193.750168,251.000000 194.500351,251.000000 195.625610,251.000000 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M217.204071,195.817703 
	C216.686646,195.528625 216.373276,195.057541 216.029663,194.294159 
	C216.872162,194.198822 217.968643,194.202927 217.204071,195.817703 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M313.988342,141.386520 
	C315.612396,141.343079 317.234406,141.647675 318.833099,142.045776 
	C319.609680,142.239182 320.995209,142.603317 320.997162,142.897400 
	C321.024597,147.100388 322.344086,150.745377 324.343842,154.463852 
	C324.988525,155.662582 322.402283,155.498291 321.007507,154.669922 
	C318.584900,153.231110 316.037537,150.782516 313.288208,154.841125 
	C312.053558,154.470413 311.615875,152.958557 310.128052,153.482269 
	C306.612793,154.719574 306.721680,151.701614 306.250824,149.897141 
	C305.596741,147.390381 307.024902,145.650253 309.448364,145.172928 
	C311.614136,144.746338 313.904358,144.843964 313.988342,141.386520 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M289.536316,174.191544 
	C289.098633,169.917099 290.896881,167.922821 294.844360,168.007462 
	C297.567932,168.065872 300.185791,166.138428 303.047852,167.976898 
	C305.415253,169.497635 306.858765,171.695999 306.465424,174.127823 
	C306.043762,176.734589 303.703033,177.991425 300.852478,178.028183 
	C297.883301,178.066483 294.927032,177.850357 291.933502,178.431732 
	C288.233063,179.150391 289.748230,176.246292 289.536316,174.191544 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M281.791809,156.562805 
	C280.633606,155.001038 278.865692,154.055038 279.358826,152.108841 
	C279.613922,151.102188 280.604034,151.017731 281.397095,151.335663 
	C283.501526,152.179337 287.193970,152.946777 287.334229,154.099731 
	C287.815765,158.059235 284.402283,156.773361 281.791809,156.562805 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M64.588997,1196.056885 
	C64.919922,1196.457397 64.946892,1196.897827 64.985855,1197.669189 
	C64.148087,1198.193359 63.046638,1199.347656 62.524853,1198.000732 
	C62.109421,1196.928589 63.304016,1196.393311 64.588997,1196.056885 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M66.006836,1198.277954 
	C66.113121,1198.289551 66.224953,1198.572144 66.185898,1198.972656 
	C66.027473,1198.910156 66.019936,1198.729614 66.006836,1198.277954 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M64.180397,1194.965820 
	C64.208900,1194.860352 64.504349,1194.772583 64.890961,1194.843140 
	C64.803864,1194.995483 64.625603,1194.989624 64.180397,1194.965820 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M86.005142,1212.994873 
	C86.411118,1213.103882 86.812576,1213.217651 87.214035,1213.331299 
	C86.925476,1214.010986 86.578186,1213.628906 86.116814,1213.196289 
	C85.995071,1213.007324 86.000626,1212.999634 86.005142,1212.994873 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M336.668396,160.062195 
	C336.741302,161.605194 336.371704,163.143539 334.332703,163.937744 
	C334.258606,162.394073 334.628571,160.855988 336.668396,160.062195 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M333.625000,164.000519 
	C333.528015,166.343521 332.513336,165.965759 331.144928,164.270905 
	C331.750000,164.000549 332.500000,164.000488 333.625000,164.000519 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M316.955841,136.534180 
	C316.305786,136.176056 316.296448,135.816513 316.763031,135.499390 
	C316.860626,135.433075 317.086365,135.436005 317.181183,135.505325 
	C317.634735,135.836853 317.618195,136.196091 316.955841,136.534180 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M523.936035,235.621490 
	C522.497437,236.325653 520.186462,235.054901 519.662354,237.141846 
	C519.236145,238.839142 521.620789,239.002808 522.722595,239.894440 
	C524.395813,241.248413 525.922852,242.796356 527.666260,244.046661 
	C529.413269,245.299637 530.897339,246.896317 530.406311,248.944794 
	C529.864319,251.206055 527.172729,250.869110 525.430481,251.665070 
	C524.263733,252.198074 523.048767,251.738754 522.180664,250.937653 
	C520.465637,249.355026 522.709961,249.328323 523.179382,248.554733 
	C524.213013,246.851303 522.669250,246.086197 521.630127,245.525497 
	C520.004089,244.648132 518.012451,244.768936 517.469788,246.720398 
	C516.770264,249.236069 516.238098,252.025055 516.615112,254.549759 
	C516.994141,257.087433 518.760010,255.260574 519.942322,254.721497 
	C521.836670,253.857681 524.462891,253.108017 525.338989,254.998413 
	C526.445984,257.386963 526.461609,260.423584 526.360046,263.158844 
	C526.320129,264.234497 524.313110,264.211365 523.276184,263.822540 
	C521.292664,263.078766 520.120850,263.574249 519.248474,265.773376 
	C517.656616,264.904785 518.291626,263.666321 518.690918,262.401855 
	C519.327881,260.384888 518.693237,258.895935 516.779114,257.890778 
	C515.658691,257.302399 513.836304,256.793823 514.274475,255.249725 
	C516.007812,249.141602 511.163086,250.648880 508.913696,251.185486 
	C504.227783,252.303329 502.030640,249.125015 499.397491,246.748856 
	C497.402649,244.948746 497.549408,243.126633 500.785736,243.049683 
	C502.658234,243.005157 503.957062,242.394806 504.842773,240.331085 
	C505.000000,240.000000 505.500000,240.000000 505.750000,240.000000 
	C507.081116,240.766998 508.217163,241.497284 509.394928,242.045181 
	C511.670349,243.103699 512.845093,242.071289 512.994019,239.338455 
	C513.000000,238.555679 513.000000,238.111359 513.000000,237.333832 
	C516.565063,232.011429 520.117737,231.437286 523.936035,235.621490 
z"/>
<path fill="#D0CDCC" opacity="1.000000" stroke="none" 
	d="
M704.050903,37.625763 
	C703.833313,37.999527 703.666687,37.999664 703.250000,37.999977 
	C701.154236,38.064453 699.453674,39.727806 697.245300,38.250389 
	C699.413452,34.703236 705.134644,34.270607 704.998779,28.382524 
	C712.543762,26.580318 719.740051,22.700222 727.814087,24.978512 
	C729.531860,25.463232 731.342957,25.958033 731.463684,27.755474 
	C731.622437,30.121063 729.438904,30.057182 727.829285,29.954260 
	C724.996155,29.773109 722.693665,30.481152 720.724060,32.662724 
	C718.863770,34.723209 716.541321,33.618782 714.224243,33.353958 
	C710.320435,32.907784 705.644836,31.264360 704.050903,37.625763 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M643.675293,103.427750 
	C642.197083,108.899338 639.568726,112.381104 633.639404,112.003387 
	C629.315063,111.727905 625.724854,108.287979 626.018799,104.514023 
	C626.290039,101.031921 629.401733,99.292099 634.238831,99.860153 
	C636.218140,100.092583 638.184265,100.116798 640.135071,99.470253 
	C643.572876,98.330833 644.287476,100.105988 643.675293,103.427750 
z"/>
<path fill="#D0CDCC" opacity="1.000000" stroke="none" 
	d="
M594.841309,171.700348 
	C593.903137,176.356461 589.919128,176.035141 586.782227,176.894516 
	C585.254028,177.313187 583.522156,176.988327 581.441223,177.000000 
	C580.878967,173.473831 583.323059,170.860214 584.381958,167.746979 
	C585.105713,165.619308 587.188660,165.598923 588.977905,166.691437 
	C591.090698,167.981445 593.265442,169.246628 594.841309,171.700348 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M623.693054,121.556374 
	C623.339233,119.170135 625.286987,118.265862 626.226685,116.803902 
	C627.633240,114.615555 629.333191,114.039452 630.048340,117.435509 
	C630.355652,118.894783 631.338684,119.651581 632.555420,120.275383 
	C636.162537,122.124710 635.364624,125.119637 634.254761,127.914162 
	C633.748779,129.188202 632.619934,130.594391 630.948181,130.528229 
	C629.227905,130.460129 629.261536,128.865570 628.920593,127.596252 
	C628.193298,124.888382 626.350525,123.077377 623.693054,121.556374 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M490.333313,180.999100 
	C490.199524,179.073608 488.104401,178.490570 487.445312,176.659637 
	C489.714874,176.430634 491.847198,176.022476 493.970184,176.066360 
	C495.487885,176.097748 497.873138,173.577164 498.346100,176.429565 
	C498.659729,178.320938 497.362762,181.000702 494.277069,180.987076 
	C493.784637,180.984909 493.292145,180.995193 492.399841,181.000168 
	C491.555542,181.000214 491.111115,180.999817 490.333313,180.999100 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M508.000214,176.249847 
	C507.630798,174.312729 504.706635,173.693985 505.896118,171.555878 
	C506.217651,170.977936 508.084076,170.901443 509.109100,171.147018 
	C511.276123,171.666122 513.419128,173.563385 513.451294,175.251495 
	C513.508850,178.269119 510.457642,176.453690 508.415283,176.999496 
	C508.001221,176.999390 508.000427,176.499695 508.000214,176.249847 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M616.016663,111.820480 
	C617.699402,115.679573 615.273743,115.878891 612.807434,115.961136 
	C611.094604,116.018257 609.704590,115.331367 610.228027,113.406609 
	C611.076172,110.287697 613.426147,110.794266 616.016663,111.820480 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M513.413086,230.999420 
	C512.277161,229.672226 511.883972,228.267700 512.000610,226.371399 
	C513.015015,226.452133 514.207397,226.702866 515.005005,227.403183 
	C516.322876,228.560272 519.605835,227.039215 519.242310,229.361069 
	C518.832153,231.980499 515.765991,230.690399 513.413086,230.999420 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M576.000000,181.332825 
	C576.629639,181.193054 577.341187,181.260178 577.873108,181.604660 
	C579.330017,182.548050 580.228394,183.953629 580.548279,185.649078 
	C580.605042,185.950073 580.080444,186.658295 579.786438,186.680634 
	C577.502380,186.854233 577.695251,184.443527 576.260742,183.213425 
	C576.000000,182.555420 576.000000,182.110840 576.000000,181.332825 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M552.485840,172.765991 
	C552.846680,173.592987 552.819153,174.220871 552.097473,174.381653 
	C551.875183,174.431198 551.321228,173.929077 551.271301,173.622253 
	C551.164795,172.967041 551.487854,172.473389 552.485840,172.765991 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M816.726746,1046.780151 
	C817.807190,1047.243286 818.611877,1047.491455 819.708313,1047.869873 
	C821.542114,1049.852051 823.061401,1049.282471 824.791931,1048.160889 
	C826.536743,1047.029907 828.351868,1045.303589 830.644714,1047.031616 
	C832.293274,1048.273926 832.491760,1050.128174 832.218445,1052.008667 
	C831.825806,1054.709961 830.080200,1056.001343 827.466309,1056.300903 
	C826.823303,1056.374512 826.140442,1056.389038 825.540894,1057.147827 
	C827.479004,1062.611572 830.507080,1067.374512 835.436768,1070.826904 
	C838.287354,1072.823242 839.560669,1075.674805 840.651245,1078.997314 
	C842.338501,1084.137329 842.238647,1089.666016 844.480408,1094.954346 
	C843.769714,1096.416382 842.465759,1097.271851 842.922607,1098.942261 
	C843.079163,1098.066284 843.109558,1097.135864 843.923523,1096.082764 
	C847.577148,1093.229248 851.410156,1091.199219 854.851990,1088.026123 
	C856.248047,1087.636230 857.277832,1087.687134 858.667114,1088.147705 
	C861.688232,1090.502686 864.354126,1092.692627 865.358765,1096.628662 
	C865.408936,1098.290405 865.624634,1099.569946 865.275391,1101.196045 
	C864.165649,1104.773193 861.108521,1103.024048 858.964844,1103.749512 
	C860.061096,1105.751587 861.960327,1105.320435 863.691040,1106.100586 
	C864.439209,1106.493530 864.801086,1106.812256 865.298645,1107.505615 
	C865.598877,1108.304321 865.617920,1108.783569 865.341797,1109.577393 
	C864.657532,1110.335693 864.024170,1110.495483 863.391296,1110.682739 
	C862.469727,1110.955566 861.293701,1111.073486 861.542114,1112.484619 
	C861.780151,1113.837036 862.724243,1114.441650 864.045532,1114.278198 
	C866.646973,1113.956299 869.315552,1114.258179 872.208008,1113.473877 
	C873.357361,1113.752319 873.982727,1114.349854 874.769470,1114.618408 
	C875.462830,1114.437378 875.312378,1113.891113 875.242676,1113.434326 
	C874.779541,1110.398193 875.098145,1107.365479 878.481934,1106.581299 
	C881.812317,1105.809448 882.958496,1108.840576 883.889465,1111.314209 
	C884.871277,1113.923096 886.598450,1115.287109 889.270935,1115.862915 
	C891.566162,1116.357422 893.528809,1117.683228 895.421326,1119.043579 
	C896.813416,1120.044434 898.040710,1121.228760 897.673523,1123.145386 
	C897.269592,1125.252808 895.624084,1126.015869 893.730103,1126.394409 
	C892.254395,1126.689209 890.770813,1126.766724 888.937378,1126.506470 
	C887.822449,1126.630981 887.090027,1126.266113 886.323853,1126.314941 
	C886.666870,1127.146973 887.336121,1126.731934 888.237305,1126.698730 
	C888.900818,1126.757446 889.175354,1126.936523 889.463318,1127.086792 
	C891.327698,1128.060791 890.805542,1131.881592 893.510803,1131.244141 
	C895.292786,1130.823975 895.408020,1127.953979 896.652466,1126.420654 
	C897.603210,1125.249146 898.017334,1123.793945 898.755310,1122.512207 
	C899.597046,1121.050293 900.825317,1119.136841 902.330200,1119.425415 
	C906.857544,1120.292847 911.303284,1117.017090 915.767517,1119.417114 
	C918.290894,1120.773804 920.925964,1118.186768 923.739380,1119.770752 
	C924.000427,1120.444458 924.000854,1120.888916 924.001831,1121.666748 
	C922.001831,1124.934692 917.893738,1124.397705 915.785400,1127.186768 
	C918.267700,1124.786743 922.113525,1125.041382 924.757263,1122.247070 
	C925.750000,1122.000000 926.500000,1122.000000 927.625000,1122.000000 
	C929.004333,1124.792969 929.313599,1127.240112 925.226990,1127.414917 
	C920.049072,1127.636475 918.852844,1131.582031 919.236023,1135.324707 
	C919.691956,1139.777222 919.913818,1144.205078 920.043884,1148.660522 
	C920.083374,1150.014038 920.338257,1151.565552 922.108276,1151.460693 
	C923.823669,1151.359131 923.906250,1149.792114 923.973755,1148.445679 
	C924.136658,1145.198486 921.859497,1142.024414 923.839722,1138.362549 
	C925.120850,1137.257935 926.287842,1136.673828 928.061157,1137.018555 
	C932.143250,1140.490234 935.347168,1144.879028 941.748657,1143.637573 
	C942.875488,1143.544678 943.630066,1143.578491 944.756348,1143.836182 
	C945.369873,1144.055176 945.604980,1144.171387 946.144165,1144.537842 
	C947.556763,1147.107056 948.534363,1149.471924 948.961792,1152.393188 
	C946.275391,1156.277466 946.283142,1160.358643 946.228882,1164.737549 
	C943.897766,1166.589600 941.953430,1167.841187 939.575562,1165.138916 
	C937.210449,1162.451172 938.126404,1160.570557 940.140259,1158.237793 
	C942.127075,1155.936401 944.666626,1153.106567 941.155762,1150.202881 
	C937.775269,1147.406860 930.685059,1147.882568 928.959900,1150.774292 
	C928.199890,1152.048096 927.391479,1153.051270 925.931213,1152.918945 
	C923.096497,1152.662476 920.769287,1154.135620 918.308899,1155.020508 
	C915.041626,1156.195557 911.956421,1157.862549 908.636963,1155.184326 
	C908.222961,1154.850220 907.147583,1154.807495 906.710022,1155.109619 
	C898.752380,1160.605469 894.944824,1151.817139 889.367249,1149.669434 
	C886.728821,1148.653442 887.746216,1143.488647 886.722961,1140.291382 
	C886.492432,1139.571167 888.094482,1139.057007 889.146057,1139.108032 
	C890.678528,1139.182251 891.907104,1140.015015 891.832764,1141.506348 
	C891.510681,1147.964600 897.641724,1148.001587 901.009338,1150.635010 
	C902.534058,1151.827271 904.993896,1150.535400 904.824524,1148.245239 
	C904.409485,1142.633301 906.135376,1139.181885 912.959106,1139.739014 
	C910.654785,1136.238525 906.582581,1134.937744 907.445801,1130.210449 
	C907.762939,1128.473511 904.434265,1128.841309 902.805664,1129.387329 
	C901.124451,1129.950928 900.344360,1131.573608 902.034180,1132.809204 
	C904.700439,1134.758667 903.933960,1136.977539 902.653625,1138.915283 
	C901.226562,1141.075317 899.372864,1138.864014 897.701904,1138.739990 
	C897.062622,1138.692627 896.443298,1138.326660 895.822693,1138.085938 
	C886.154968,1134.335815 886.113647,1134.344238 879.098938,1141.820801 
	C875.955139,1145.171631 873.947998,1144.934326 872.450012,1140.648438 
	C871.533081,1138.025269 870.208069,1136.924438 867.415222,1136.940063 
	C864.267212,1136.957764 862.424744,1137.277222 864.407593,1141.048340 
	C865.454285,1143.038818 865.517456,1145.255981 863.265625,1146.815674 
	C861.397461,1148.109497 859.514709,1148.129395 857.720520,1146.775391 
	C857.101379,1146.308105 856.191345,1145.390869 856.314270,1144.908691 
	C857.261780,1141.190918 856.729431,1138.677612 852.155396,1138.688354 
	C851.857300,1138.688965 851.228638,1137.891724 851.307251,1137.635132 
	C852.929077,1132.344360 849.024353,1131.678467 845.551880,1130.500122 
	C844.938049,1130.291626 844.128662,1129.179321 844.218750,1128.613159 
	C844.876465,1124.479736 841.496399,1122.936890 839.164673,1120.306641 
	C841.445312,1120.043457 843.895630,1120.127441 846.605713,1119.057739 
	C851.049622,1118.736572 851.085083,1122.057739 851.841980,1124.582886 
	C852.710510,1127.480347 853.196960,1130.557739 856.864197,1130.868774 
	C851.997620,1128.541626 851.274658,1125.848999 854.345337,1119.794189 
	C852.591431,1119.169067 850.360046,1119.491943 849.105042,1117.335938 
	C849.000000,1116.250000 849.000000,1115.500000 849.000000,1114.375000 
	C848.619568,1113.614624 848.473022,1113.154785 848.780029,1112.310181 
	C849.690430,1105.783936 848.923950,1105.032104 841.431885,1105.000000 
	C840.602905,1105.433228 840.170471,1105.814453 839.290894,1105.983154 
	C838.194641,1105.753174 837.389343,1105.506348 836.291992,1105.129761 
	C832.303955,1102.890015 830.815308,1098.736816 832.865967,1095.656860 
	C834.025391,1093.915649 834.656616,1091.959351 833.000183,1090.765259 
	C830.642151,1089.065430 830.556396,1084.372314 826.116516,1085.443359 
	C825.156311,1085.675171 824.178284,1085.923218 823.199402,1085.968262 
	C820.858521,1086.075928 817.829895,1085.828857 818.193237,1089.144165 
	C818.532227,1092.237427 815.399902,1096.641602 821.061401,1098.298340 
	C823.206665,1098.926270 824.608459,1106.725098 823.005981,1108.149658 
	C821.374329,1109.599976 819.194336,1108.802124 818.747009,1107.418213 
	C816.622253,1100.845825 812.011475,1102.995972 808.326843,1104.662842 
	C804.008362,1106.616333 801.856567,1106.597900 800.487915,1101.411743 
	C799.115112,1096.209961 791.414490,1093.764771 786.092285,1096.633057 
	C785.553650,1096.996704 785.109802,1096.998291 784.332947,1097.000000 
	C780.386963,1097.634521 777.934814,1095.659058 775.861572,1093.160034 
	C774.290588,1091.266357 772.440002,1089.242920 773.820740,1086.462036 
	C775.119263,1083.846436 777.608643,1084.231201 779.983093,1084.440918 
	C780.378052,1082.897583 779.262817,1082.035034 778.333740,1081.837280 
	C773.815369,1080.876221 771.361694,1077.875000 769.259094,1074.020630 
	C767.277466,1070.387695 764.396057,1069.909180 760.934998,1072.392090 
	C758.184814,1074.365234 755.540466,1074.850464 752.596130,1073.174805 
	C749.646179,1071.495972 750.109497,1068.852173 750.882263,1066.129272 
	C750.479187,1067.953857 749.406799,1069.526367 749.733337,1071.439575 
	C750.151917,1073.892822 749.184387,1075.910278 746.730652,1076.440430 
	C743.875488,1077.057129 743.166992,1074.701904 742.500671,1072.572510 
	C742.256775,1071.793213 742.150757,1070.927612 741.042786,1070.198853 
	C738.387207,1074.346069 734.868958,1074.157104 731.037598,1071.793335 
	C728.708801,1070.356689 725.647827,1073.040527 723.248657,1070.245361 
	C722.528442,1069.686646 722.056824,1069.373413 721.292603,1069.030029 
	C719.137817,1066.703613 720.013123,1064.798584 722.295532,1063.839600 
	C725.811401,1062.362549 727.655212,1059.741821 728.913940,1056.350220 
	C729.830566,1053.880249 731.811646,1052.509155 734.388977,1053.543579 
	C737.752747,1054.893921 741.487732,1055.075684 744.658936,1056.750854 
	C746.791870,1057.877563 748.372009,1057.481079 750.454651,1056.197998 
	C754.662109,1055.982544 758.642944,1056.606445 762.434082,1053.997559 
	C766.546448,1051.041382 769.685120,1047.688354 770.349792,1042.861572 
	C770.876343,1039.037842 772.540588,1037.391602 776.347473,1038.591553 
	C781.513611,1040.220093 787.397095,1039.191528 792.007324,1042.955811 
	C793.298401,1044.010132 794.507324,1042.726440 795.615234,1042.095581 
	C797.653381,1040.935303 799.496704,1041.056763 800.897644,1042.990601 
	C802.443481,1045.124634 801.920593,1047.486816 799.996521,1048.734619 
	C797.189209,1050.555298 796.067383,1052.798096 795.874329,1056.026978 
	C795.754639,1058.030396 793.795654,1058.897461 792.069214,1059.607666 
	C790.398315,1060.295288 788.811646,1061.432861 789.443848,1063.279297 
	C791.284424,1068.655151 788.453796,1070.992188 784.017883,1072.551880 
	C783.560242,1072.712769 783.201965,1073.117188 782.627075,1073.692139 
	C782.114624,1074.106689 781.848816,1074.328003 781.844604,1074.778931 
	C782.714233,1075.423828 783.364868,1075.940674 784.424072,1076.466064 
	C785.975647,1077.848511 786.317139,1079.574341 787.248108,1080.943237 
	C788.440125,1082.696411 789.821655,1083.812622 792.253174,1083.173828 
	C798.784424,1081.458008 798.803894,1081.532104 802.322144,1087.128784 
	C804.449219,1082.419922 796.732849,1079.156860 802.193359,1074.628174 
	C795.186218,1073.981201 790.012939,1081.367065 783.858765,1075.363037 
	C783.112793,1071.367065 786.043396,1071.742310 788.156372,1071.489502 
	C790.947876,1071.155518 792.988892,1070.614990 792.373840,1067.004272 
	C791.874817,1064.074219 794.089417,1062.623291 796.222229,1061.200928 
	C798.091492,1059.954102 800.707092,1059.643799 801.709473,1056.748047 
	C802.207397,1055.756592 802.688904,1055.205200 803.557007,1054.523682 
	C804.787231,1053.891846 805.767639,1053.757324 806.756836,1053.977417 
	C808.874695,1054.448853 810.386963,1055.659668 810.700867,1057.879517 
	C811.028931,1060.199341 809.172058,1060.831421 807.551636,1061.729004 
	C803.175537,1064.152832 802.959351,1065.529785 806.629150,1068.418213 
	C807.039429,1068.720581 807.150146,1068.844482 807.282288,1068.937988 
	C808.308838,1069.664185 807.279053,1071.605957 809.005066,1071.872192 
	C809.266235,1071.912476 809.567505,1071.508423 809.643188,1071.187500 
	C810.093994,1069.276245 808.071411,1069.463379 807.072510,1068.331543 
	C808.593872,1064.135010 812.579224,1063.751343 815.875122,1067.708374 
	C818.096313,1070.375000 820.718201,1072.663208 822.869812,1075.715576 
	C822.684326,1073.837402 821.685852,1072.429321 821.636414,1070.808350 
	C821.499695,1066.322754 819.539795,1064.484375 814.921570,1065.687622 
	C813.112122,1066.158936 811.190979,1065.522095 810.391785,1063.506958 
	C809.682434,1061.718384 810.506470,1060.111084 811.909119,1059.120850 
	C815.586426,1056.525024 817.573486,1053.700928 814.727722,1049.199097 
	C813.951294,1047.970703 814.509827,1046.557983 816.726746,1046.780151 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M751.231201,1056.691406 
	C748.910522,1059.418945 747.576294,1061.788696 743.761658,1058.031860 
	C741.298706,1055.606201 736.500549,1055.474854 732.697327,1054.520996 
	C730.918152,1054.074707 730.488037,1055.801636 730.187866,1056.990112 
	C729.153870,1061.084351 727.536438,1064.480835 722.726135,1065.112793 
	C720.955994,1065.345337 721.052856,1066.885864 721.002930,1068.608887 
	C717.789551,1071.581177 714.719421,1074.115234 718.250977,1078.716309 
	C719.254761,1080.024048 719.664673,1082.043945 718.257568,1083.331299 
	C716.691589,1084.764038 715.232300,1083.484253 713.873474,1082.430054 
	C713.108398,1081.836426 712.191589,1081.393188 711.282043,1081.039185 
	C707.987610,1079.756958 708.157166,1077.715698 710.653015,1076.110718 
	C712.778870,1074.743652 713.221985,1073.363892 712.569031,1071.152832 
	C711.864624,1068.766968 711.012451,1066.795654 707.929993,1066.966187 
	C704.457520,1067.158447 703.718628,1065.051270 704.167480,1062.073975 
	C704.851440,1057.537231 700.986450,1050.813599 696.788818,1049.156494 
	C690.633301,1046.726440 690.687439,1046.742920 688.688843,1053.313843 
	C687.916260,1055.853882 686.825195,1058.296265 685.900208,1060.790894 
	C685.165527,1062.772339 683.502625,1063.585693 681.646362,1063.768066 
	C679.750366,1063.954346 679.286743,1062.348755 678.795837,1060.869629 
	C678.496521,1059.967773 678.219299,1058.703491 677.069275,1058.692261 
	C675.584778,1058.677612 675.568604,1060.222046 675.082886,1061.212891 
	C674.938660,1061.507202 674.810608,1061.851562 674.815796,1062.170654 
	C674.934753,1069.418945 670.164856,1069.557251 664.455627,1069.000000 
	C658.746277,1067.749756 656.344116,1064.700562 656.680176,1059.571899 
	C656.937988,1055.637817 658.977478,1051.404663 653.831970,1048.695679 
	C652.487488,1047.987793 653.255737,1045.944458 654.159546,1044.648193 
	C655.592468,1042.593384 657.659180,1041.698120 660.097534,1041.496826 
	C661.748047,1041.360718 663.449280,1041.571045 665.042847,1041.073364 
	C667.898132,1040.181641 669.702698,1037.759766 669.493591,1035.188721 
	C669.277405,1032.531006 666.298157,1032.751587 664.189270,1033.011597 
	C661.192505,1033.381104 658.886108,1032.668945 656.498962,1030.613281 
	C653.600525,1028.117310 649.024292,1028.876709 646.067566,1025.686523 
	C645.037720,1024.575317 643.664734,1027.509644 641.733521,1028.047241 
	C639.847046,1028.412231 638.220520,1028.241455 637.310913,1026.996094 
	C635.396667,1024.375244 634.104309,1022.466919 630.068481,1024.715210 
	C627.119019,1026.358276 625.631958,1028.250610 624.388306,1031.357666 
	C621.040710,1033.923706 618.773743,1033.124023 617.237366,1029.734009 
	C616.701843,1028.552490 615.914307,1027.518677 615.148926,1026.039795 
	C614.570801,1024.791016 614.483521,1023.789856 614.232117,1022.833313 
	C613.554565,1020.255310 612.634827,1017.251831 615.494446,1015.690430 
	C618.068665,1014.284912 620.590515,1015.676697 622.463501,1017.642334 
	C621.065613,1014.414551 621.146240,1011.197571 623.657654,1007.893799 
	C625.263489,1006.187683 624.977356,1003.878967 626.752136,1002.268188 
	C627.000000,1002.002441 627.000000,1002.000000 627.000000,1002.000000 
	C627.750000,1002.000122 628.500000,1002.000244 629.625000,1002.000122 
	C631.849792,999.404358 634.498962,997.605957 636.750793,995.422363 
	C638.815430,993.420349 641.396667,992.787720 644.574524,993.609131 
	C652.424622,995.638184 660.586487,995.045349 668.605103,995.668518 
	C671.129395,995.864685 672.717224,993.118530 674.932983,991.408875 
	C675.872681,990.475647 676.727173,990.153137 678.027588,990.089966 
	C679.232910,990.185303 680.099915,990.337402 681.290039,989.977173 
	C683.675476,989.357605 685.891174,989.149841 687.501953,987.578918 
	C692.953247,982.262390 700.065979,980.890442 707.068665,979.258301 
	C708.188477,978.997314 709.317688,978.803894 710.734375,978.219666 
	C711.605835,978.041382 712.114563,978.023132 713.007812,978.127930 
	C715.477356,979.207336 717.477234,979.188599 719.420959,977.786682 
	C723.651550,974.735352 728.309448,974.925598 733.393921,976.247681 
	C735.720093,979.767883 733.234741,981.006653 730.889282,981.829712 
	C728.221802,982.765686 725.493591,983.359863 723.618164,985.753235 
	C722.976196,986.572449 721.748474,987.027344 720.909851,986.583008 
	C717.054077,984.539856 713.987366,986.985596 710.605957,988.022949 
	C707.322021,989.030334 704.156189,990.408508 700.494507,990.280396 
	C698.338379,990.204834 695.425842,991.213196 694.548584,993.161926 
	C693.298340,995.939270 696.818726,996.637756 698.359131,998.617310 
	C698.872070,1003.348633 695.897949,1004.450806 692.504211,1004.211731 
	C689.685669,1004.013306 688.142639,1004.085632 688.246582,1007.542908 
	C688.333801,1010.442993 686.311707,1011.923889 683.518433,1012.451721 
	C681.968567,1012.744507 680.118225,1012.512695 678.899170,1014.928650 
	C684.836182,1016.268066 690.541199,1017.649597 696.522522,1017.245056 
	C698.868225,1017.086365 701.149353,1016.971497 703.388977,1017.933533 
	C707.744202,1019.804382 708.801880,1022.373352 706.857422,1026.616577 
	C704.701843,1031.320557 706.085510,1035.209351 710.921814,1038.039551 
	C709.086731,1034.567749 711.189331,1029.052979 714.848083,1027.687744 
	C716.762756,1026.973267 718.024902,1027.977661 719.200745,1029.256714 
	C721.432129,1031.684448 722.154724,1034.794678 723.228760,1038.053711 
	C723.753784,1038.686523 724.167236,1038.951538 725.005493,1038.694092 
	C725.856079,1038.306641 726.462463,1038.102905 727.454346,1037.942871 
	C731.646240,1038.369751 732.136719,1041.953125 733.141296,1044.433716 
	C735.523743,1050.316650 739.250854,1053.009888 745.700256,1052.470581 
	C748.117310,1052.268433 751.840454,1051.743896 751.231201,1056.691406 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M640.754517,1027.232178 
	C642.415771,1026.840942 642.615967,1025.716675 643.167603,1024.655640 
	C644.591370,1021.917114 646.551331,1022.714905 648.415894,1024.280273 
	C648.648193,1024.475464 648.788147,1024.841064 648.863525,1025.153564 
	C650.129700,1030.404175 654.787537,1025.401855 656.632019,1027.638916 
	C658.619141,1030.048950 659.609375,1033.289917 663.851135,1031.322754 
	C665.057922,1030.763062 666.862854,1030.953369 668.223755,1031.363281 
	C673.096313,1032.831177 672.848267,1033.662964 670.560486,1038.187256 
	C668.345337,1042.568115 665.344849,1043.568359 661.030823,1043.029175 
	C659.197510,1042.800049 657.371338,1042.995850 655.986206,1044.533325 
	C655.212891,1045.391479 654.064880,1046.144043 654.242859,1047.404419 
	C654.512329,1049.312500 655.923645,1048.313721 656.968506,1048.251221 
	C658.533203,1048.157715 658.961853,1049.196533 658.956665,1050.486938 
	C658.947998,1052.642334 659.422668,1055.005737 658.709167,1056.913330 
	C656.616638,1062.508789 659.299072,1065.747559 663.789795,1068.737427 
	C663.284912,1072.420410 661.442017,1073.655884 658.324829,1071.628662 
	C655.687256,1069.913208 652.531250,1070.238770 649.384521,1069.055298 
	C645.261230,1067.237183 640.799988,1068.333496 637.185303,1065.942505 
	C635.783875,1065.015625 633.680237,1064.227783 634.540283,1062.364136 
	C637.172363,1056.660156 633.213257,1057.185059 629.847290,1057.005615 
	C629.184448,1056.970215 628.518127,1057.000122 627.426758,1057.000000 
	C625.429688,1055.431152 627.973450,1053.937134 626.950623,1052.112061 
	C624.816284,1052.817993 624.949890,1055.356201 623.242126,1056.747681 
	C621.773743,1058.961060 620.523499,1060.831177 617.918213,1061.194946 
	C616.544067,1061.386597 615.066223,1062.680908 614.114441,1060.603394 
	C613.414368,1059.075439 613.127197,1057.705444 614.253357,1055.999390 
	C615.210449,1054.549316 618.125061,1053.701050 617.152039,1051.942505 
	C616.034607,1049.922974 613.239563,1051.319214 611.205566,1050.998169 
	C610.418457,1050.873901 609.624084,1050.796021 608.586853,1050.666260 
	C608.895874,1048.443481 611.911255,1048.789062 611.948242,1046.351562 
	C612.277893,1045.705566 612.609619,1045.506836 613.292419,1045.444580 
	C613.893921,1045.529419 614.136475,1045.945190 614.365173,1045.906738 
	C615.016113,1045.797241 614.388916,1046.219604 614.177979,1045.300781 
	C613.835754,1044.217773 613.904602,1043.409668 614.368103,1042.325073 
	C616.653809,1040.166260 618.062439,1043.105469 619.972595,1042.864136 
	C617.289490,1039.679810 616.601501,1039.519653 612.667358,1041.418213 
	C611.543091,1042.147217 610.649475,1042.455933 609.322754,1042.163818 
	C603.902710,1041.126221 604.078247,1045.228027 603.052734,1048.625732 
	C602.618652,1049.393677 602.162415,1049.529907 601.316528,1049.201294 
	C600.107849,1045.954102 600.265564,1042.565186 594.933533,1043.300171 
	C592.661255,1043.613281 589.090454,1040.731445 587.520752,1038.365479 
	C585.483521,1035.294434 583.273804,1033.239746 579.925659,1031.809448 
	C577.483643,1030.766235 575.759766,1028.594727 576.754944,1025.232910 
	C579.123718,1022.851990 581.873291,1023.514648 584.434143,1023.360107 
	C586.361206,1023.243774 588.336365,1023.585754 590.292847,1022.843506 
	C590.295959,1020.474670 587.165405,1019.089111 588.777832,1016.259888 
	C593.234863,1017.392944 596.212769,1013.954895 599.695740,1012.815063 
	C602.905579,1011.764709 605.266602,1010.212341 608.020203,1013.283447 
	C608.937256,1014.306213 610.205017,1013.262939 611.653381,1013.042114 
	C612.653564,1013.596130 612.989685,1014.443054 612.693359,1015.183289 
	C611.128174,1019.093079 612.828796,1021.918213 615.789917,1024.723145 
	C616.895325,1026.777100 619.007141,1027.563110 619.192383,1030.093994 
	C619.417175,1033.162598 621.746460,1031.265015 623.631958,1031.050293 
	C625.671936,1030.084839 627.007019,1031.035278 628.625000,1031.974854 
	C629.275330,1032.329468 629.498901,1033.188843 630.388550,1032.318604 
	C634.816284,1032.442505 636.879639,1028.305908 640.754517,1027.232178 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M723.000000,1070.457642 
	C727.412659,1069.919556 731.850464,1069.193359 735.823792,1072.261719 
	C737.512329,1073.565552 737.815613,1071.448853 738.390381,1070.349243 
	C739.052979,1069.081543 739.385132,1067.130615 741.311279,1067.505615 
	C743.109741,1067.855591 743.834106,1069.443970 743.989441,1071.268188 
	C744.135315,1072.980469 743.564758,1075.626709 746.173706,1075.454712 
	C748.646484,1075.291748 748.664246,1073.144775 748.307556,1070.853271 
	C748.016296,1068.981567 748.752441,1066.954346 749.003662,1064.992310 
	C749.146423,1063.877197 748.883728,1062.421265 750.531799,1062.351318 
	C751.979797,1062.289795 752.425293,1063.650391 752.681396,1064.728149 
	C752.896606,1065.633545 752.932007,1066.846436 752.488708,1067.590576 
	C751.435181,1069.359009 750.638855,1070.852905 753.117310,1072.026367 
	C755.315369,1073.067139 757.410217,1073.661133 759.539856,1071.702515 
	C764.132263,1067.479248 768.598206,1068.413208 771.138123,1073.926270 
	C772.580200,1077.056274 773.684570,1080.079712 778.568909,1079.687622 
	C781.080933,1079.485840 782.661194,1083.463257 783.246704,1085.891479 
	C783.623535,1087.454102 780.997925,1086.886475 779.708191,1086.294922 
	C777.970337,1085.497803 776.372437,1084.683838 774.883057,1086.716187 
	C773.421570,1088.710327 775.398560,1089.739014 776.079529,1091.068848 
	C777.629150,1094.095459 780.522095,1095.407227 783.766724,1096.748535 
	C781.560364,1100.991333 783.619568,1105.381226 788.797241,1106.981812 
	C790.095581,1107.383179 791.757874,1107.445190 791.465332,1109.210449 
	C791.147339,1111.129517 789.336182,1111.041992 787.837585,1110.904175 
	C786.858643,1110.814087 785.915894,1110.305176 784.938354,1110.231934 
	C784.172180,1110.174561 783.147034,1110.246826 782.646362,1110.706909 
	C781.762390,1111.519287 782.336548,1112.531860 782.929260,1113.382568 
	C783.119751,1113.655762 783.337402,1113.911865 783.510437,1114.195435 
	C786.879211,1119.716919 786.830750,1119.623535 780.841553,1122.649536 
	C776.916748,1124.632568 774.888367,1128.708130 771.730896,1131.573608 
	C770.475708,1132.712769 769.509521,1134.172729 767.608582,1133.891113 
	C765.948975,1133.645264 763.715149,1133.109497 763.615967,1131.445557 
	C763.459900,1128.828857 766.528931,1125.949463 767.645996,1125.875977 
	C774.510498,1125.424683 772.907959,1120.296875 772.818176,1116.732300 
	C772.726196,1113.079468 772.481995,1110.451050 777.042603,1109.704956 
	C779.065063,1109.374268 780.295166,1107.595703 779.559082,1105.081055 
	C778.894470,1102.810547 777.657654,1101.671753 775.244019,1101.996216 
	C774.916687,1102.040039 774.578186,1102.004761 774.244934,1101.999512 
	C766.654846,1101.879761 766.613342,1101.897461 769.662415,1108.940552 
	C770.203064,1110.189331 771.148804,1111.032715 769.488586,1112.255249 
	C764.577820,1115.871094 758.415283,1117.412842 753.935547,1121.750610 
	C753.407349,1122.262085 752.334229,1121.994019 751.428162,1120.999878 
	C753.574646,1119.242554 754.375427,1116.894531 753.983765,1114.092163 
	C753.726318,1112.250244 752.776306,1110.335938 751.181702,1110.413940 
	C746.742920,1110.630737 743.689270,1107.076660 739.420593,1106.778931 
	C735.388245,1106.497681 734.280273,1103.529785 735.276733,1099.868042 
	C736.510498,1095.334473 734.716370,1091.728149 730.345154,1091.050781 
	C726.716736,1090.488403 725.366211,1089.100952 724.678894,1085.119873 
	C723.861206,1080.383301 722.720520,1075.762817 723.000000,1070.457642 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M697.055481,1106.638794 
	C694.108154,1114.744019 693.982849,1114.827148 687.388062,1113.056152 
	C686.672485,1110.512939 687.540344,1108.187744 687.987366,1105.401855 
	C686.854797,1101.491577 686.717468,1098.015747 688.668091,1094.298340 
	C689.342102,1093.286499 689.961304,1092.581299 689.982910,1091.858154 
	C690.170044,1085.579468 694.011414,1082.334839 699.684570,1080.145020 
	C701.608032,1078.625732 703.106934,1079.043335 704.778076,1080.638672 
	C705.000000,1082.066650 705.000000,1083.133423 705.000000,1084.600098 
	C706.184875,1086.196167 706.662842,1087.589600 706.093506,1089.624756 
	C706.197571,1092.882568 704.920959,1095.615601 705.642273,1098.673218 
	C706.062561,1100.455200 705.089233,1102.705566 702.255188,1103.219971 
	C700.337280,1103.568115 697.854553,1103.657837 697.055481,1106.638794 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M678.047180,1077.493164 
	C676.178040,1074.191528 677.815552,1071.009766 677.797791,1068.007690 
	C677.782410,1065.414062 681.843506,1065.768555 684.091370,1065.312744 
	C687.044861,1064.713623 689.886230,1064.966797 688.196594,1060.508423 
	C687.638550,1059.035767 688.810059,1057.771973 690.426758,1058.187134 
	C692.456421,1058.708618 695.050537,1059.516479 694.598938,1062.168823 
	C694.178284,1064.639160 694.288208,1067.120361 693.849548,1069.543457 
	C693.322388,1072.455688 691.761719,1074.794556 688.440430,1073.806274 
	C684.554565,1072.650024 682.036133,1073.672729 680.327332,1077.234131 
	C679.890747,1078.144165 679.270264,1079.059448 678.047180,1077.493164 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M756.195496,1123.307617 
	C757.162781,1122.339844 757.867737,1121.637817 758.570740,1120.934082 
	C760.214844,1119.287842 762.232849,1117.890137 764.094177,1120.059082 
	C765.995850,1122.274902 765.158386,1124.889404 763.127930,1126.695557 
	C760.951782,1128.631226 757.921753,1128.595215 755.549927,1127.698730 
	C752.539673,1126.560913 755.006226,1124.915405 756.195496,1123.307617 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M717.036560,1090.004150 
	C721.456787,1089.690674 721.125916,1092.450684 720.924072,1094.988525 
	C720.681091,1098.042603 718.375366,1097.960083 716.135986,1098.003784 
	C713.540649,1098.054443 712.086182,1097.088257 711.989136,1094.267212 
	C711.874207,1090.921875 713.565063,1089.795166 717.036560,1090.004150 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M688.689453,1093.865479 
	C688.761353,1097.459229 689.515686,1100.962280 688.270813,1104.694580 
	C683.033508,1103.814697 683.941589,1099.233765 683.479187,1096.008789 
	C683.204102,1094.089844 685.897522,1092.802612 688.689453,1093.865479 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M737.152466,1114.669189 
	C737.255981,1110.426392 740.109009,1111.060059 742.539062,1111.048950 
	C743.834595,1111.042969 744.891052,1111.543945 744.849182,1113.029907 
	C744.795532,1114.935059 744.162720,1116.783447 742.352539,1117.569214 
	C739.878174,1118.643555 738.280640,1117.235107 737.152466,1114.669189 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M722.287598,1121.254639 
	C723.006958,1120.609619 723.580566,1120.103394 724.149475,1120.108643 
	C726.301575,1120.128052 729.197021,1119.125977 729.707275,1122.394775 
	C730.017517,1124.382812 728.286621,1125.635742 726.326965,1125.890869 
	C723.171753,1126.301636 721.703613,1124.848999 722.287598,1121.254639 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M791.951782,1117.160645 
	C793.609131,1114.494263 795.291748,1114.773560 796.990784,1116.535156 
	C797.864197,1117.440918 799.454529,1118.462524 798.244751,1119.815552 
	C796.778992,1121.454834 794.486206,1122.314575 792.397034,1121.663696 
	C790.107605,1120.950439 791.210815,1119.022705 791.951782,1117.160645 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M705.242920,1080.773804 
	C703.578613,1080.671875 702.157288,1080.343750 700.367920,1080.007812 
	C699.408508,1079.229370 698.833191,1078.445557 698.221191,1077.691528 
	C697.584290,1076.906738 696.972412,1076.102905 697.717285,1075.099731 
	C698.490723,1074.058228 699.799500,1073.642822 700.759827,1074.223633 
	C703.128418,1075.656006 706.188660,1076.755371 705.242920,1080.773804 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M706.389954,1089.998535 
	C704.637817,1088.833008 705.098145,1087.235229 705.000000,1085.384766 
	C707.077393,1084.720337 709.683289,1082.833984 710.594177,1086.479126 
	C711.402710,1089.715088 709.069092,1089.919678 706.389954,1089.998535 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M724.110474,1102.755127 
	C725.395142,1101.005615 725.887573,1102.049561 725.898865,1103.074951 
	C725.921204,1105.118286 726.544250,1107.260742 725.053528,1109.718506 
	C723.415894,1107.454346 724.205200,1105.192871 724.110474,1102.755127 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M735.773926,1109.177124 
	C735.172668,1111.320679 734.059937,1111.095215 732.906921,1110.108765 
	C732.222168,1109.522949 732.150391,1108.654419 732.814270,1108.019409 
	C734.172607,1106.720093 735.055603,1107.516113 735.773926,1109.177124 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M784.151855,1133.058105 
	C785.508545,1132.258667 786.585571,1131.957397 787.359436,1133.296387 
	C787.784790,1134.032349 787.311157,1134.768433 786.675659,1134.755371 
	C785.583740,1134.732910 783.520813,1136.068237 784.151855,1133.058105 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M747.520386,1126.936035 
	C746.326233,1128.302002 745.381104,1127.841431 744.670959,1126.740356 
	C744.492737,1126.464111 744.622192,1125.638794 744.859680,1125.503296 
	C746.159424,1124.762207 746.983276,1125.454834 747.520386,1126.936035 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M823.926392,1248.350342 
	C827.200867,1247.744019 830.602661,1248.821533 833.751343,1246.284302 
	C836.838989,1246.457275 841.108093,1244.453003 842.227417,1247.120239 
	C843.380676,1249.868408 838.522827,1250.411865 836.979980,1252.563354 
	C833.938843,1256.804321 829.145264,1256.108032 824.916626,1256.935059 
	C823.416931,1257.228394 821.924683,1256.767822 821.262268,1255.078247 
	C820.110352,1252.140137 822.398254,1250.586914 823.926392,1248.350342 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M826.906006,1270.000000 
	C828.193054,1269.995972 829.023743,1270.013550 829.852600,1269.983154 
	C831.194946,1269.933838 832.642151,1269.962524 832.799377,1268.106689 
	C832.929199,1266.574463 831.823242,1266.223877 830.618286,1265.934082 
	C829.471619,1265.658203 828.083496,1265.209351 828.757996,1263.707397 
	C829.199524,1262.724121 830.505493,1263.045776 831.476196,1263.320190 
	C833.546143,1263.905518 835.622131,1264.475220 837.669800,1265.131958 
	C839.325378,1265.662964 840.913513,1266.542725 840.518616,1268.572998 
	C840.154907,1270.442993 839.027649,1271.812866 836.808167,1271.988403 
	C829.080383,1272.599854 821.360901,1273.317139 813.633789,1273.938965 
	C812.285522,1274.047363 810.715454,1273.868896 810.529846,1272.197510 
	C810.332397,1270.419556 811.967834,1269.726929 813.199219,1270.173706 
	C817.690247,1271.803345 822.035156,1269.177368 826.906006,1270.000000 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M800.000122,1242.411621 
	C803.039246,1240.391846 806.412720,1241.435913 809.459900,1241.463379 
	C813.483582,1241.499512 811.488037,1245.699219 812.000000,1248.569092 
	C810.026001,1248.999756 808.046265,1248.902222 806.079224,1249.019043 
	C801.625305,1249.283325 799.651001,1247.219238 800.000122,1242.411621 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M791.472290,1219.058105 
	C791.785950,1216.559570 791.893860,1214.432007 792.172302,1212.326904 
	C792.305054,1211.323120 792.891785,1210.414185 794.100159,1210.494873 
	C795.273010,1210.573242 795.928467,1211.522461 795.837524,1212.512695 
	C795.569824,1215.426880 795.138367,1218.330200 794.647278,1221.216797 
	C794.506042,1222.046631 793.743774,1222.724121 792.835449,1222.517822 
	C791.175964,1222.140747 791.022949,1220.864136 791.472290,1219.058105 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M839.967041,1234.441162 
	C839.728455,1237.215210 840.617004,1239.315918 841.598083,1241.409546 
	C841.971130,1242.205688 841.905396,1243.099365 841.080933,1243.567017 
	C840.299011,1244.010254 839.501282,1243.693848 838.870667,1243.070190 
	C837.630005,1241.843140 837.480896,1233.885132 838.868774,1232.885986 
	C839.602539,1232.357788 839.725159,1233.505005 839.967041,1234.441162 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M768.848816,1183.267212 
	C770.476990,1184.016724 771.452881,1184.956421 770.264221,1186.372192 
	C769.567688,1187.201660 768.338013,1187.150146 767.670959,1186.294189 
	C766.727539,1185.083496 766.772827,1183.861328 768.848816,1183.267212 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M860.089233,1273.662598 
	C858.857056,1272.301025 858.579590,1271.073853 860.256287,1270.318481 
	C861.080444,1269.947144 861.989380,1270.411377 862.325623,1271.224487 
	C863.065247,1273.013062 861.938843,1273.587402 860.089233,1273.662598 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M1255.809082,966.843750 
	C1256.000000,967.442871 1256.000000,967.888123 1256.000000,968.667358 
	C1255.224121,968.881958 1254.359131,968.775940 1254.398560,967.742004 
	C1254.423096,967.101379 1254.791870,966.552917 1255.809082,966.843750 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1253.707520,975.030029 
	C1253.801514,975.872253 1253.796753,976.969543 1252.182129,976.204102 
	C1252.471680,975.686707 1252.943237,975.373413 1253.707520,975.030029 
z"/>
<path fill="#D0CDCC" opacity="1.000000" stroke="none" 
	d="
M1007.735779,1175.215698 
	C1012.200928,1170.827148 1009.550537,1165.909424 1008.435120,1161.894653 
	C1007.435425,1158.296265 1007.995544,1154.248901 1005.708130,1151.000000 
	C1005.263000,1150.367676 1005.385498,1149.676147 1006.098694,1149.386475 
	C1006.453308,1149.242554 1007.179199,1149.387329 1007.415466,1149.670166 
	C1012.150818,1155.339233 1018.714050,1154.864258 1025.549561,1155.000000 
	C1026.817261,1155.234253 1027.471802,1155.742065 1027.924316,1156.418457 
	C1030.144287,1159.736816 1032.272461,1159.118042 1034.693848,1156.164062 
	C1035.886230,1156.106445 1036.643433,1156.390381 1036.945801,1157.687500 
	C1036.728882,1161.219482 1034.316772,1163.526123 1033.351440,1166.536865 
	C1032.480591,1169.252930 1029.520264,1170.485474 1026.350098,1169.608887 
	C1024.765625,1169.170654 1023.229919,1168.397461 1021.625549,1168.234375 
	C1019.992065,1168.068237 1018.796143,1169.226807 1018.460144,1170.802856 
	C1018.218079,1171.937866 1019.236633,1172.854370 1020.201965,1172.787964 
	C1026.283447,1172.370239 1024.882446,1176.713135 1025.000000,1180.556152 
	C1025.058105,1182.792725 1024.343262,1184.126831 1022.342590,1184.950684 
	C1016.226624,1184.561401 1010.437622,1183.819214 1004.396362,1185.941406 
	C1000.042786,1185.000366 997.025513,1182.968262 996.007751,1178.365601 
	C996.341858,1177.655396 996.744873,1177.419922 997.572998,1177.306519 
	C1000.971069,1177.794922 1004.148987,1180.161255 1007.021851,1176.343262 
	C1007.242859,1175.824585 1007.357178,1175.628052 1007.735779,1175.215698 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M976.671753,1158.717041 
	C977.000000,1159.444458 977.000000,1159.888916 977.000000,1160.666748 
	C979.109192,1163.636230 982.047424,1165.316650 984.762817,1167.763672 
	C985.257690,1168.496094 985.515381,1168.992310 985.886536,1169.744141 
	C987.834839,1172.159546 988.355164,1174.594238 987.265259,1177.655273 
	C984.855164,1177.794922 983.103516,1178.255249 982.212646,1180.519531 
	C981.062378,1183.442749 978.027710,1183.652710 975.677917,1184.396851 
	C973.241577,1185.168213 972.399841,1182.953857 972.031555,1180.858521 
	C971.084778,1175.471802 969.346619,1170.900635 963.114258,1169.389648 
	C960.031067,1168.642090 959.918579,1165.553589 960.000183,1162.406494 
	C958.718384,1159.459595 960.439209,1158.097168 962.285156,1157.406128 
	C967.057251,1155.619385 971.928223,1154.476562 976.671753,1158.717041 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M976.953491,1158.640259 
	C973.104187,1158.046631 969.264221,1156.825928 965.146729,1157.991577 
	C963.067505,1158.580200 960.946106,1158.874878 960.055237,1161.636963 
	C955.092590,1160.721558 951.715454,1166.158325 946.417725,1165.000000 
	C945.062744,1160.979492 942.296692,1156.709595 947.781616,1153.267456 
	C948.945435,1152.053467 950.101135,1151.662476 951.777527,1151.724243 
	C953.444885,1151.916748 954.734436,1152.359497 956.390930,1151.960815 
	C957.769470,1151.641113 958.688660,1151.081299 959.925903,1150.417969 
	C961.564636,1149.856934 963.104187,1150.378784 964.363464,1149.548218 
	C964.414917,1147.330688 961.652954,1147.227661 961.546875,1144.933350 
	C963.805237,1141.688599 966.477417,1141.984497 969.657593,1143.719482 
	C971.139648,1144.627075 972.996094,1145.111572 972.770142,1146.498047 
	C971.991638,1151.274780 975.397034,1154.413940 976.953491,1158.640259 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M987.338562,1178.150391 
	C986.382812,1175.649780 985.725159,1173.305176 986.000000,1170.415283 
	C986.328369,1170.054199 986.669678,1170.068237 986.983154,1170.168823 
	C992.680664,1171.999268 992.586304,1171.925903 996.173462,1167.469604 
	C997.979553,1165.225708 999.932495,1163.412476 1002.135376,1167.563232 
	C1003.485657,1170.107300 1005.801636,1172.138916 1007.841919,1174.700439 
	C1008.000000,1175.000000 1008.000000,1175.500000 1008.000000,1175.750000 
	C1005.248962,1178.306519 1001.551636,1176.131104 998.350220,1177.981689 
	C997.555542,1178.000000 997.111084,1178.000000 996.333313,1178.000000 
	C993.195129,1177.254150 990.503784,1179.650024 987.338562,1178.150391 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1009.750000,1198.807983 
	C1011.084473,1202.782471 1011.328796,1206.259277 1006.367188,1207.987793 
	C1001.057617,1206.743530 1003.693115,1202.691528 1003.000000,1199.433838 
	C1004.714355,1199.000000 1006.428711,1199.000000 1008.571533,1199.000000 
	C1009.166687,1198.871948 1009.333313,1198.744019 1009.750000,1198.807983 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M844.321777,1153.937500 
	C844.000000,1153.250000 844.000000,1152.500000 844.000000,1151.375000 
	C845.989136,1151.561035 846.753784,1152.396240 844.321777,1153.937500 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M848.687439,1117.053955 
	C851.829224,1118.839722 855.217346,1117.324341 858.475708,1118.557251 
	C857.491150,1121.194824 852.906799,1121.173218 853.886841,1125.217529 
	C854.401611,1127.341675 854.393616,1129.125488 857.190979,1129.020386 
	C858.736328,1128.962524 860.209290,1129.271729 860.751892,1131.053833 
	C861.003845,1131.881348 861.083496,1132.957520 860.294312,1133.296021 
	C858.815491,1133.930542 857.368408,1133.058228 856.068848,1132.456299 
	C852.678894,1130.886230 850.692383,1128.053711 850.145203,1124.439331 
	C849.833008,1122.377930 849.530762,1120.527710 847.316040,1119.173096 
	C847.106445,1118.113647 847.390320,1117.356201 848.687439,1117.053955 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M839.128296,1106.280762 
	C839.471619,1105.686768 839.943237,1105.373535 840.707397,1105.030029 
	C840.703613,1106.153564 842.539612,1107.519775 841.052307,1108.331421 
	C840.097900,1108.852295 839.811340,1107.245361 839.128296,1106.280762 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M848.715881,1112.047729 
	C849.000000,1112.444458 849.000000,1112.888916 849.000000,1113.666748 
	C848.371887,1113.893433 847.619263,1113.827759 847.579041,1113.042603 
	C847.563660,1112.742188 848.130310,1112.411987 848.715881,1112.047729 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M648.910278,1118.357910 
	C650.588562,1117.973633 651.953735,1118.239990 651.987183,1120.634521 
	C651.433838,1121.594238 650.786865,1122.080933 650.057495,1122.458130 
	C650.588440,1122.166870 651.103882,1121.853027 651.776733,1121.221191 
	C658.559509,1121.056885 665.112427,1122.071655 671.657410,1120.142212 
	C673.872070,1119.489380 676.609131,1119.477539 678.832642,1121.210205 
	C680.671509,1122.643066 682.480164,1121.531128 683.872803,1120.488525 
	C686.740845,1118.341187 689.980103,1119.845459 693.000732,1119.247559 
	C695.043701,1118.843262 695.074036,1121.020996 694.959167,1122.487305 
	C694.778503,1124.791260 696.109375,1128.182739 692.605774,1128.698608 
	C689.234070,1129.195190 685.865967,1130.864258 682.318054,1129.604248 
	C681.368225,1129.266846 680.188171,1128.737305 679.470642,1129.496338 
	C676.246033,1132.906616 672.091675,1131.744751 668.215637,1132.061768 
	C666.375305,1132.212402 664.272400,1132.566406 664.235596,1134.942383 
	C664.196045,1137.502686 666.711670,1136.352783 667.921326,1137.083740 
	C673.768921,1140.618042 679.848389,1136.252441 685.717407,1137.851074 
	C687.282471,1138.277344 687.897278,1136.679321 688.849915,1135.799316 
	C692.194702,1132.709595 697.396973,1133.288452 699.444641,1136.894897 
	C700.640625,1139.001343 701.676575,1141.162598 699.632507,1143.281128 
	C697.791382,1145.189209 695.481628,1145.565674 693.283081,1144.078857 
	C690.158325,1141.965820 684.820068,1144.842163 683.113770,1139.470215 
	C682.935059,1138.907715 681.500427,1139.142822 680.941956,1139.870972 
	C679.080872,1142.297485 681.208557,1144.625000 681.336426,1147.000244 
	C681.429321,1148.725952 683.509155,1148.225464 684.793579,1148.701416 
	C691.107727,1151.040771 697.848633,1147.726685 704.025818,1150.475952 
	C705.504150,1151.133911 706.902222,1150.835449 708.386780,1150.405396 
	C709.940979,1149.955200 711.666992,1149.354980 712.907532,1151.638916 
	C708.519653,1151.388428 704.312927,1153.431641 699.806946,1153.283569 
	C697.336853,1153.202515 697.971863,1156.071411 698.054077,1157.742432 
	C698.225891,1161.234863 697.874329,1164.040649 693.467712,1164.450806 
	C692.510742,1164.539917 691.381470,1165.590942 690.807922,1166.499634 
	C688.260132,1170.536011 684.817749,1168.611450 681.893433,1167.641113 
	C678.998657,1166.680542 677.431213,1164.242065 678.194031,1160.905518 
	C678.447510,1159.796875 679.116028,1158.535156 677.325012,1158.143188 
	C676.173462,1157.891113 674.976807,1157.912720 674.025940,1158.780273 
	C672.799805,1159.898682 672.980408,1161.146606 673.902344,1162.329102 
	C678.085510,1167.694336 677.985168,1174.014648 678.017334,1180.329712 
	C678.028259,1182.466309 677.719971,1184.297363 675.488342,1185.255615 
	C673.827148,1185.969238 672.645325,1187.044800 673.215271,1189.100464 
	C673.748718,1191.024902 674.992126,1192.648804 676.921082,1192.700684 
	C680.662598,1192.801636 681.343201,1194.750854 680.972412,1197.898926 
	C680.658142,1200.567261 678.914551,1200.906738 676.728088,1201.023438 
	C673.743347,1201.182617 671.162842,1200.692139 671.095154,1197.095825 
	C670.974426,1190.681396 666.316895,1189.862183 661.619873,1189.146484 
	C657.821899,1188.567871 655.102905,1188.185791 659.812805,1184.230713 
	C662.342773,1182.106079 660.947998,1178.533081 660.884888,1175.647217 
	C660.826355,1172.976196 659.966187,1170.322510 659.286743,1166.756470 
	C657.473145,1169.482422 658.367615,1173.215454 654.445740,1173.026489 
	C652.056702,1172.911377 649.731873,1173.040771 648.406860,1170.524658 
	C646.818604,1167.508545 646.123840,1163.697510 648.551208,1161.644897 
	C653.926331,1157.099976 648.970947,1154.923950 647.016235,1152.103027 
	C646.396912,1151.209229 645.369263,1150.598633 644.954834,1149.427979 
	C643.737305,1145.988770 641.417664,1145.649658 638.177246,1146.747681 
	C632.911743,1148.531860 629.315002,1145.422485 629.626160,1139.667847 
	C629.678894,1138.693604 630.090332,1137.183594 629.628174,1136.819336 
	C621.181702,1130.163696 631.689209,1131.175293 632.694824,1127.678223 
	C634.234680,1130.209961 632.509277,1131.427612 632.192932,1132.867676 
	C631.544617,1135.818848 632.560486,1137.815430 635.457458,1138.616577 
	C639.709167,1139.792114 642.042480,1136.011475 645.091248,1134.367554 
	C647.003906,1133.336060 646.629028,1129.983154 645.477783,1128.418579 
	C641.675049,1123.250610 642.919434,1120.302490 648.910278,1118.357910 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M636.054199,1092.892090 
	C633.558655,1091.853638 632.897522,1089.453369 630.999268,1088.047729 
	C628.982117,1086.553833 630.414246,1084.425781 632.146729,1083.727539 
	C634.684265,1082.704834 637.443298,1081.852783 640.291321,1082.939209 
	C641.819763,1083.522217 643.676453,1083.752930 643.837341,1085.873169 
	C643.983582,1087.799805 642.434326,1088.521362 641.009033,1088.811401 
	C638.590149,1089.303345 639.118225,1092.878052 636.054199,1092.892090 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M655.398071,1107.526489 
	C658.031250,1104.655151 661.060303,1103.303589 664.211792,1105.175293 
	C665.583496,1105.989868 667.442627,1108.024536 666.636658,1110.171509 
	C665.751099,1112.530518 663.143188,1113.067505 661.006348,1113.744263 
	C658.943359,1114.397827 657.132812,1113.470337 655.912476,1111.641968 
	C655.150024,1110.499756 654.865906,1109.238037 655.398071,1107.526489 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M600.644409,1049.039185 
	C601.446289,1048.993652 601.890137,1048.996948 602.666870,1049.002686 
	C603.952271,1051.010254 606.973267,1052.990723 605.389099,1055.005859 
	C603.375916,1057.566895 599.883667,1057.566772 596.359741,1056.158203 
	C596.001648,1055.555542 596.000854,1055.111084 595.998779,1054.333252 
	C595.715942,1050.868530 597.814392,1049.815186 600.644409,1049.039185 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M610.405396,1088.999756 
	C609.951660,1087.410522 608.505554,1086.956421 607.625671,1086.026001 
	C605.148865,1083.407104 605.589905,1081.669556 609.628967,1081.009521 
	C610.998962,1081.834106 612.040649,1082.622681 612.985962,1083.513672 
	C614.190491,1084.648926 615.457214,1085.897095 614.694946,1087.748047 
	C613.951721,1089.552612 612.186096,1088.809204 610.405396,1088.999756 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M586.989319,1064.612305 
	C585.164612,1066.326538 586.822327,1068.856689 584.892700,1070.589722 
	C582.897522,1067.024536 582.864136,1063.126099 582.089417,1059.466431 
	C581.760559,1057.912598 582.541748,1056.596802 584.627441,1056.061035 
	C585.659546,1058.741577 586.319092,1061.483154 586.989319,1064.612305 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M623.399963,1057.000000 
	C623.114441,1055.875122 622.838379,1054.446533 623.426636,1053.690186 
	C624.561279,1052.231079 624.314087,1049.565552 626.715271,1049.290283 
	C628.240601,1049.115234 628.693970,1050.682861 629.377991,1051.785156 
	C631.091614,1054.546875 627.771545,1054.755737 627.073242,1056.659912 
	C625.933289,1057.000000 624.866638,1057.000000 623.399963,1057.000000 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M722.637268,1147.008057 
	C726.352966,1147.378784 725.963562,1149.914429 725.999939,1152.602417 
	C724.151001,1152.832153 722.172546,1153.507446 720.710205,1151.506714 
	C718.927124,1149.066772 718.606506,1147.422974 722.637268,1147.008057 
z"/>
<path fill="#CDCCCD" opacity="1.000000" stroke="none" 
	d="
M609.008423,1042.346069 
	C609.788452,1041.688477 610.576904,1041.377075 611.682678,1041.032837 
	C613.040588,1040.205200 614.043579,1040.147583 614.996216,1041.576294 
	C615.000000,1043.066650 615.000000,1044.133423 615.000000,1045.600098 
	C614.833313,1046.128052 614.666687,1046.255981 614.250000,1046.192017 
	C613.555542,1046.000000 613.111084,1046.000000 612.333313,1046.000000 
	C610.589111,1045.273071 608.948181,1044.753540 609.008423,1042.346069 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M1256.666748,213.000000 
	C1256.893677,213.628403 1256.827393,214.380844 1256.042358,214.421112 
	C1255.742065,214.436539 1255.411743,213.869690 1255.047607,213.284088 
	C1255.444458,213.000000 1255.888916,213.000000 1256.666748,213.000000 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1104.041504,1198.343750 
	C1106.162842,1196.604980 1107.423462,1194.554443 1106.710693,1192.096436 
	C1105.839111,1189.091919 1103.397583,1190.367920 1101.283325,1190.573853 
	C1099.182251,1190.778564 1097.747681,1189.374146 1097.444824,1187.389038 
	C1097.133545,1185.349731 1099.042358,1185.203247 1100.423218,1184.731323 
	C1104.137207,1183.462402 1107.357422,1183.651611 1109.098389,1187.825562 
	C1109.916748,1189.787354 1111.230469,1190.072021 1113.120850,1189.485840 
	C1116.755249,1188.358521 1117.913452,1189.245361 1119.421143,1193.436646 
	C1121.384155,1198.893677 1119.994873,1203.921021 1120.380737,1209.087036 
	C1120.669312,1212.952271 1117.530396,1213.843018 1114.284546,1213.556152 
	C1113.010986,1213.443604 1111.701782,1213.183594 1111.545776,1214.679565 
	C1111.388550,1216.186646 1112.759766,1215.788940 1113.720459,1215.692749 
	C1115.660645,1215.498657 1116.174561,1216.763062 1115.719482,1218.196533 
	C1114.669189,1221.504761 1116.667603,1221.666992 1119.055176,1222.095337 
	C1124.415649,1223.057251 1124.647827,1223.647095 1121.527832,1228.067627 
	C1120.417480,1229.640625 1120.652954,1232.659302 1118.335205,1232.735718 
	C1114.550049,1232.860596 1110.968262,1234.727661 1107.009644,1233.583984 
	C1103.901367,1232.685791 1101.976807,1232.168701 1104.609497,1228.479736 
	C1107.645752,1224.225342 1102.102661,1221.144897 1102.323242,1216.951904 
	C1102.400879,1215.475952 1099.138428,1216.106567 1097.377686,1216.048584 
	C1095.059204,1215.972290 1093.695557,1217.456177 1092.849121,1219.382080 
	C1091.682129,1222.037720 1090.367432,1222.035034 1088.477295,1220.023926 
	C1086.571655,1217.996216 1084.271484,1216.692749 1081.371216,1217.873291 
	C1078.260742,1219.139404 1079.102051,1221.955078 1079.015259,1224.355713 
	C1078.949097,1226.185059 1079.002686,1228.019897 1079.037842,1229.851562 
	C1079.065552,1231.288696 1079.901489,1233.221069 1080.880127,1233.071899 
	C1085.202637,1232.413330 1089.112183,1235.395752 1093.360352,1234.269531 
	C1097.294312,1233.226929 1098.487183,1234.120239 1097.914307,1238.096802 
	C1097.751953,1239.222900 1097.226562,1240.303345 1096.817749,1241.385986 
	C1095.972778,1243.623169 1094.923218,1246.300903 1097.693237,1247.510376 
	C1100.857910,1248.892212 1100.509521,1245.223999 1102.237549,1242.959473 
	C1103.229370,1248.479492 1104.100830,1253.329468 1104.986084,1258.589722 
	C1102.842285,1258.679443 1101.702637,1256.843506 1101.417969,1255.258545 
	C1100.782349,1251.720581 1098.971069,1250.085327 1095.524536,1249.602783 
	C1093.622192,1249.336426 1093.104370,1247.733643 1092.910034,1245.877319 
	C1092.553467,1242.470703 1090.479004,1240.586182 1087.089844,1240.035156 
	C1081.793457,1239.173950 1077.997559,1235.659424 1077.129761,1230.153442 
	C1076.621582,1226.928589 1075.847778,1224.710327 1072.045044,1224.914307 
	C1070.588745,1224.992310 1069.598755,1224.162842 1069.150513,1222.666870 
	C1067.770508,1218.060059 1071.267456,1214.812378 1072.617798,1211.044922 
	C1073.387573,1208.897217 1076.530884,1210.255493 1078.598755,1210.027222 
	C1080.471924,1209.820435 1081.547607,1210.511841 1082.178345,1212.555786 
	C1083.136841,1215.661987 1086.150635,1216.560669 1088.912476,1216.808472 
	C1091.942261,1217.080322 1091.773926,1213.784180 1093.140625,1212.156982 
	C1095.927612,1208.838623 1099.273315,1205.905273 1102.739380,1203.275024 
	C1104.639771,1201.832764 1104.879150,1200.584473 1104.041504,1198.343750 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M1074.662842,1246.851074 
	C1072.265869,1244.890137 1072.937134,1243.661255 1075.208984,1242.818848 
	C1078.707642,1241.521606 1082.024536,1243.320068 1085.439941,1243.478271 
	C1086.242432,1243.515503 1086.767700,1244.557129 1086.458618,1245.449219 
	C1086.093750,1246.502319 1085.114990,1246.913208 1084.064331,1246.926758 
	C1081.066650,1246.965088 1078.067993,1246.940063 1074.662842,1246.851074 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1079.655396,1199.913574 
	C1080.896606,1202.719849 1078.690674,1202.567383 1077.325928,1202.880249 
	C1076.050903,1203.172485 1074.562134,1203.161621 1073.741821,1201.841919 
	C1072.884155,1200.462158 1073.817627,1199.606934 1075.019897,1199.234009 
	C1076.482056,1198.780640 1077.973877,1198.955322 1079.655396,1199.913574 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1104.781250,1262.202515 
	C1106.017944,1262.482178 1106.736450,1263.208862 1106.964844,1264.682129 
	C1105.533813,1264.749023 1104.042847,1264.521362 1104.781250,1262.202515 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1024.013794,1208.598022 
	C1019.270142,1209.148071 1020.085876,1205.698608 1020.000000,1202.416992 
	C1024.840454,1200.058716 1030.086670,1201.445190 1035.054199,1200.167725 
	C1036.661011,1199.754395 1039.196655,1200.730957 1039.331787,1202.657837 
	C1039.470459,1204.634521 1036.931763,1204.609253 1035.478149,1204.812988 
	C1033.051880,1205.152832 1030.552002,1205.002563 1028.083374,1204.997070 
	C1025.855225,1204.992065 1024.153076,1205.573242 1024.013794,1208.598022 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M1022.010376,1185.385498 
	C1022.834900,1183.793701 1023.669861,1182.587402 1024.752441,1181.190552 
	C1026.778198,1183.081909 1028.035278,1185.500854 1030.428223,1187.204712 
	C1032.275879,1188.520142 1034.487061,1191.447876 1031.958862,1193.341797 
	C1029.654419,1195.067993 1026.037720,1194.638916 1024.102905,1191.753906 
	C1022.965088,1190.057251 1022.685974,1187.784546 1022.010376,1185.385498 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1055.739746,1197.227539 
	C1055.678345,1198.171997 1055.340332,1198.678345 1054.633789,1198.285034 
	C1054.378296,1198.142822 1054.202759,1197.436890 1054.326660,1197.119141 
	C1054.598511,1196.421753 1055.144287,1196.358276 1055.739746,1197.227539 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M1010.194275,1198.832642 
	C1010.000000,1199.000000 1009.500000,1199.000000 1009.250000,1199.000000 
	C1009.152466,1198.298706 1009.133484,1197.419312 1010.213745,1197.578857 
	C1010.323120,1197.594971 1010.332703,1198.286987 1010.194275,1198.832642 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M1116.333252,1267.000000 
	C1116.129150,1266.135986 1115.721924,1264.877686 1116.912354,1264.662109 
	C1118.002441,1264.464844 1117.829712,1265.626465 1117.991211,1266.666748 
	C1117.555542,1267.000000 1117.111084,1267.000000 1116.333252,1267.000000 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1143.352905,1245.075684 
	C1143.147217,1242.092163 1141.685669,1240.926025 1138.868286,1240.974121 
	C1137.442871,1240.998657 1136.011719,1240.689087 1134.291504,1240.265259 
	C1135.197632,1237.865234 1135.286987,1236.250244 1132.268433,1235.764038 
	C1131.339844,1235.614502 1130.951538,1234.749878 1131.496094,1233.859375 
	C1131.940063,1233.133423 1132.666748,1233.431396 1133.268433,1233.692993 
	C1134.028809,1234.023560 1135.027466,1234.232788 1135.456787,1234.829834 
	C1138.680054,1239.314209 1144.028687,1240.221924 1148.405640,1242.756348 
	C1149.529541,1243.407104 1151.968872,1243.471680 1151.125122,1245.476318 
	C1150.466064,1247.042358 1148.349731,1247.261108 1146.678589,1246.830688 
	C1145.614136,1246.556519 1144.691895,1245.730103 1143.352905,1245.075684 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M1034.941162,1155.676758 
	C1035.088379,1158.404053 1034.179565,1160.369263 1031.731934,1160.780762 
	C1027.968140,1161.413330 1027.600342,1157.836304 1026.048828,1155.354370 
	C1026.094727,1149.962280 1029.132446,1150.849854 1032.624512,1151.948120 
	C1033.627441,1153.117798 1034.255005,1154.235596 1034.941162,1155.676758 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1152.012695,1257.597900 
	C1149.051025,1256.600708 1147.447510,1253.741821 1145.062744,1251.331299 
	C1145.661499,1250.997437 1146.326782,1251.038940 1146.984009,1250.985107 
	C1153.592163,1250.443359 1153.591797,1250.439087 1152.012695,1257.597900 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1142.000000,1249.625000 
	C1141.026978,1249.189331 1139.030151,1248.586060 1141.705811,1247.047852 
	C1142.000000,1247.750000 1142.000000,1248.500000 1142.000000,1249.625000 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1130.844238,1239.794434 
	C1130.557495,1240.630859 1130.038330,1240.947510 1129.155762,1240.205566 
	C1129.442505,1239.369141 1129.961670,1239.052490 1130.844238,1239.794434 
z"/>
<path fill="#E2E0DE" opacity="1.000000" stroke="none" 
	d="
M961.614197,375.002869 
	C961.977112,375.481445 962.049988,375.992126 961.913391,376.438812 
	C961.510864,377.755310 961.482910,379.536804 959.477905,379.395172 
	C958.677002,379.338654 958.250183,378.436462 958.156738,377.577240 
	C957.897583,375.194519 959.492920,375.013367 961.614197,375.002869 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M968.584351,377.671234 
	C970.388123,377.011414 970.994507,377.748657 970.674622,379.188202 
	C970.502869,379.961121 969.871155,380.775299 968.926270,380.386444 
	C967.760986,379.906799 968.078491,378.849823 968.584351,377.671234 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M962.354858,374.998230 
	C962.208191,373.617310 962.969299,372.890686 964.388855,373.195892 
	C964.736023,373.270508 965.299133,373.903564 965.232422,374.087708 
	C964.784668,375.324310 963.618042,374.845734 962.354858,374.998230 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M982.675415,742.898254 
	C986.533081,744.946228 984.082703,747.866638 983.154907,749.199219 
	C978.416626,756.004089 976.915955,764.971558 969.585876,769.995544 
	C965.733948,772.635620 963.070801,776.400208 959.779846,779.552429 
	C957.060547,782.156921 953.898376,784.345276 949.638916,784.054199 
	C942.222534,783.547302 938.446594,789.767822 933.249023,793.318481 
	C931.213196,794.709290 929.500244,796.567139 927.603760,798.169067 
	C926.177185,799.374023 924.722534,800.356445 922.655090,799.427551 
	C921.428284,798.876282 919.622498,798.629761 919.319397,800.313477 
	C918.530762,804.693542 915.330627,804.032104 912.411011,803.982971 
	C907.938477,803.907776 903.535156,806.988037 898.957520,804.148682 
	C898.100525,803.617126 897.220154,804.827209 896.276062,805.782898 
	C895.872742,806.105713 895.655090,806.321472 895.631104,806.301636 
	C891.532776,802.929382 886.022217,805.467834 881.780396,803.018066 
	C880.065613,802.027832 879.356506,802.419861 878.120178,803.736511 
	C875.556213,806.466919 866.120361,806.522339 863.418640,804.139893 
	C861.244568,802.222595 862.598083,799.810791 862.406250,797.623474 
	C854.856873,797.834961 848.389893,792.694031 848.502197,786.474426 
	C846.245178,776.273621 839.015442,771.432129 830.395874,767.969666 
	C827.740295,766.902893 825.499329,765.146912 823.163879,763.576477 
	C820.116638,761.527466 817.685974,758.732239 813.154602,759.873535 
	C810.797791,760.467163 809.216370,758.201599 808.950745,755.392883 
	C808.124817,746.660339 801.127625,743.629028 793.582397,748.551270 
	C791.277710,750.054810 788.579590,750.310303 785.894897,750.189331 
	C783.040405,750.060730 781.099976,751.056824 780.265625,753.951416 
	C779.800537,755.564819 778.642517,756.837280 777.750183,758.236816 
	C776.828003,759.683228 776.042236,761.297119 776.968079,762.913940 
	C778.252808,765.157227 778.927429,767.360168 778.550659,770.008301 
	C778.293213,771.817627 779.549011,773.420471 781.263428,773.693176 
	C783.342957,774.023987 782.722839,771.925232 782.962158,770.812988 
	C783.669556,767.526001 786.449890,767.016296 788.952759,766.311340 
	C796.505005,764.183899 801.892212,768.140991 802.018127,775.870117 
	C802.039368,777.170349 802.182983,778.160095 803.503418,778.660034 
	C807.369202,780.123962 807.589905,782.987122 806.547302,786.410767 
	C806.169312,787.652161 805.568542,789.195862 806.980225,790.064026 
	C808.679932,791.109497 809.263489,789.477722 809.888062,788.355408 
	C810.686462,786.920776 811.683228,785.806213 813.446167,786.584900 
	C815.146057,787.335754 815.044495,788.942139 814.838928,790.464722 
	C814.536987,792.700500 813.523010,794.556458 811.532593,796.246155 
	C810.884583,797.051941 810.436523,797.576965 809.937622,798.491455 
	C807.775818,801.324219 805.650940,801.510559 803.561340,799.008484 
	C801.361267,796.374084 797.834351,795.638062 795.502319,793.299316 
	C796.240173,795.990479 797.193359,798.291565 800.660339,798.917480 
	C803.892029,799.500854 805.315247,801.856262 804.582153,805.277893 
	C804.340820,806.404419 804.272766,807.591919 804.901611,808.964844 
	C805.439453,809.472656 805.780151,809.728333 806.420959,810.135986 
	C809.292419,811.818970 809.072815,814.373047 809.361816,816.878052 
	C810.015503,822.543518 813.328857,824.548706 818.297729,822.158386 
	C823.780823,820.536499 825.710327,822.181458 824.503784,827.105286 
	C824.030090,829.038452 823.455444,830.960693 822.760132,832.829529 
	C820.885925,837.867371 819.002747,838.593750 814.274475,835.722168 
	C811.368469,833.957397 809.268494,834.657959 807.372559,837.083618 
	C805.504272,839.473694 805.013550,842.528992 803.508179,845.143005 
	C802.189941,847.432007 805.717957,846.948669 806.429504,848.721558 
	C807.059937,855.310242 804.309387,859.935730 798.934143,861.938232 
	C795.902466,863.067749 792.588806,861.417725 793.277039,858.782166 
	C793.911682,856.351868 792.590942,856.897339 791.472168,856.529236 
	C789.487793,855.876282 788.099182,856.268188 788.037720,858.576477 
	C787.944458,862.079895 786.728516,864.399109 782.409790,864.198730 
	C781.807678,864.052002 781.570984,863.965820 781.007202,863.679321 
	C779.139526,861.939575 778.477661,859.978027 777.959351,857.536743 
	C777.894470,856.638367 777.944885,856.132812 778.179077,855.283875 
	C780.482544,852.219055 781.244812,848.692749 783.497803,845.982361 
	C784.936279,844.251831 783.858521,842.482117 782.750061,841.021606 
	C780.643066,838.245300 777.850586,838.255554 774.867126,839.021484 
	C774.762695,840.991211 776.137939,841.097412 777.111023,841.513794 
	C779.177612,842.397949 781.355042,843.412231 780.579712,846.095398 
	C779.790100,848.828064 777.252441,848.993042 774.860107,848.777466 
	C773.389221,848.644897 771.956238,848.084961 770.471558,848.070190 
	C769.187866,848.057373 767.886353,848.245667 767.530029,850.040039 
	C767.794983,850.705566 767.855164,851.116699 767.802368,851.863892 
	C767.366760,853.170227 766.557129,854.062073 765.637390,853.851562 
	C762.380798,853.106384 760.512878,854.844055 758.640747,857.460571 
	C756.987732,859.310486 754.893677,859.300049 752.736267,860.056519 
	C742.611145,860.657471 737.946960,868.042664 732.881775,874.793213 
	C731.737366,876.318420 732.692078,877.653381 734.377014,878.283997 
	C737.963745,879.626526 741.081116,882.019653 745.185242,883.113647 
	C746.457153,884.130432 747.748047,884.855408 746.263916,886.630249 
	C743.626526,887.648132 741.339539,886.864502 739.063110,886.691467 
	C727.890564,885.842468 718.186157,888.979614 709.943604,897.297119 
	C706.020569,898.450195 705.523071,903.436401 701.118652,903.765625 
	C698.295227,902.610291 697.862976,900.189087 697.151428,898.033997 
	C694.970459,891.428284 693.104126,884.721619 691.344238,877.997070 
	C690.275818,873.914673 688.260132,872.450500 684.187073,873.873718 
	C682.312805,874.528748 680.313110,874.734131 677.938721,874.284546 
	C675.275391,869.955322 675.955872,868.745544 681.450134,867.960083 
	C682.270569,868.267944 682.630615,868.613220 682.837585,868.705750 
	C681.969543,865.358521 683.420837,863.281128 686.042664,861.979309 
	C688.686523,860.666504 689.221741,859.355225 687.516785,856.577026 
	C684.292175,851.322144 685.537537,849.180481 691.672974,848.361511 
	C694.804138,847.943604 697.904358,847.571472 701.109314,847.904175 
	C705.332825,848.342712 707.022156,846.781006 707.600525,842.580322 
	C707.849670,840.770447 708.487366,838.960266 708.157654,837.141052 
	C707.663574,834.415222 707.547974,831.351318 703.705933,830.882446 
	C701.423645,830.603882 700.711853,828.958374 700.958008,826.888184 
	C701.220703,824.679688 702.846863,823.487183 704.787720,823.763733 
	C709.632324,824.454041 713.898193,823.753296 717.809204,820.552246 
	C719.306946,819.326355 721.352661,820.267151 723.062195,820.974243 
	C725.683594,822.058533 727.243103,821.427856 727.436768,818.402893 
	C727.602783,815.808838 728.961975,813.737244 730.244202,811.566589 
	C733.138306,806.667236 736.940796,804.217041 742.778198,806.443970 
	C741.624939,801.390503 744.119263,797.952087 749.033447,797.433350 
	C751.317322,797.192139 752.366272,798.473755 753.010010,800.388916 
	C753.541687,801.970886 754.088562,804.101135 756.255615,803.020874 
	C758.046753,802.127869 759.048340,800.218628 758.118408,797.937256 
	C756.126099,793.049255 756.122498,793.000427 760.903870,791.588318 
	C762.755127,791.041565 764.385864,790.140320 765.264282,788.489380 
	C766.811218,785.582214 768.847290,783.430969 773.379639,782.990723 
	C769.965149,781.739014 767.741760,782.051025 765.485596,782.531616 
	C762.298523,783.210449 760.575256,782.174133 761.340637,778.303955 
	C761.808716,777.625732 762.165283,777.324707 762.890137,776.955627 
	C767.543274,774.716553 762.334412,773.977600 762.405029,772.157104 
	C761.951782,771.172852 761.846069,770.463440 761.922119,769.368408 
	C762.060120,768.776367 762.143250,768.548096 762.420044,768.014648 
	C764.057373,766.650757 765.615112,765.781860 765.407959,763.491211 
	C765.289551,762.180725 765.922729,760.599792 767.094971,760.066956 
	C770.574158,758.485596 771.137451,755.163940 772.526367,751.876221 
	C775.178162,749.071533 776.648254,745.879333 778.682800,743.081055 
	C782.378967,737.997131 786.682007,735.354187 793.598999,735.023132 
	C803.451965,734.551392 809.585266,740.794617 816.841980,745.038330 
	C827.466797,751.251953 836.252380,759.926086 845.445984,767.984558 
	C851.065308,772.910156 857.115723,777.253540 862.819336,782.043640 
	C866.213440,784.894226 869.887085,786.991272 874.376404,787.469727 
	C875.870178,787.628845 877.456726,787.910400 878.612671,788.848267 
	C883.915771,793.150879 890.143555,793.503174 896.561340,793.469910 
	C901.036194,793.446716 905.549683,793.359680 909.869080,795.003296 
	C911.505371,795.625854 913.144897,795.302002 914.617188,794.280273 
	C916.889587,792.703125 919.381042,792.565491 922.128357,792.378418 
	C927.094238,792.040222 930.845703,788.526062 934.230042,785.272278 
	C936.556580,783.035583 938.756348,781.530090 941.938538,781.407410 
	C943.430786,781.349976 944.963623,781.264221 946.270996,780.417786 
	C957.097229,773.408325 969.000488,767.643921 975.419434,755.036743 
	C975.645752,754.196777 975.804993,753.726257 976.194824,752.946655 
	C979.766602,750.199463 979.218506,745.398682 982.675415,742.898254 
z"/>
<path fill="#DDD9D9" opacity="1.000000" stroke="none" 
	d="
M848.644653,785.874329 
	C850.713684,786.108887 852.935303,786.435669 851.000732,788.619019 
	C847.812744,792.216736 851.414856,792.550842 853.000854,792.494080 
	C855.438721,792.406799 856.618408,792.726929 857.151001,795.303955 
	C857.622681,797.585938 859.191895,797.047241 860.441345,795.798523 
	C861.370239,794.870056 862.132263,793.380310 863.603943,794.777039 
	C864.715576,795.831909 865.397217,797.320557 864.421692,798.818542 
	C861.546814,803.232788 864.141357,803.868530 867.819885,804.034058 
	C869.614685,804.114929 871.387512,804.604614 873.180237,804.830566 
	C875.457581,805.117737 877.600037,804.206909 877.535278,801.927979 
	C877.399658,797.153076 879.534546,799.131775 881.137817,800.574951 
	C883.405396,802.616150 885.124817,804.369141 888.715637,802.447205 
	C891.554565,800.927856 892.653137,805.484314 895.651733,805.953369 
	C895.333557,808.404663 892.633057,809.550476 892.298950,812.285217 
	C892.155151,813.462097 892.569824,815.863953 890.280151,815.569519 
	C888.389648,815.326477 889.163269,813.068665 888.490662,811.819397 
	C888.214417,811.306213 888.146362,810.414490 888.410706,809.926025 
	C889.256409,808.363098 892.362366,807.282776 890.168579,805.100220 
	C887.785156,802.729187 886.590576,806.330200 884.813660,807.024536 
	C880.160461,808.843018 875.697449,810.834900 870.514648,809.159180 
	C868.957703,808.655884 867.214294,808.891724 865.657043,809.693237 
	C864.432922,810.323242 863.099548,810.893982 862.071838,809.510132 
	C859.492920,806.037231 855.338379,807.328247 852.018860,806.111511 
	C851.188599,805.807190 849.869873,805.795166 849.580688,807.094604 
	C849.376892,808.010315 850.170532,808.720947 850.955811,808.905029 
	C854.541687,809.745911 856.411011,815.858032 861.559204,812.207092 
	C862.706665,811.393372 864.160400,812.729858 864.395996,814.195618 
	C864.754395,816.425598 862.883606,815.975708 861.722595,815.958984 
	C858.678589,815.915222 856.563354,817.357239 854.261902,819.262512 
	C851.091980,821.886902 846.557983,821.164978 843.288147,820.270081 
	C838.579163,818.981323 834.567566,819.498108 830.249084,820.912903 
	C828.727478,821.411438 827.344299,821.757019 827.128967,823.575623 
	C826.904236,825.473267 828.220764,826.341675 829.716736,826.709778 
	C832.653992,827.432495 834.340088,828.104980 830.370178,830.147095 
	C828.642944,831.035583 828.823486,832.400574 830.633972,832.828125 
	C832.976440,833.381409 834.080627,834.485535 833.969604,836.930786 
	C833.846741,839.637451 831.686340,839.867981 829.866211,840.547302 
	C829.089172,840.837402 828.265442,841.010803 827.505493,841.336792 
	C825.605042,842.152039 824.375427,843.206482 827.104980,844.514343 
	C830.121460,845.959717 829.981323,847.154358 827.787720,849.627258 
	C826.452209,851.132751 826.621277,853.972900 826.057251,856.603149 
	C825.190430,858.119080 824.000671,858.239868 822.418518,858.177795 
	C821.061401,857.898071 820.061340,857.469238 818.669617,857.928711 
	C817.689819,858.176086 817.014404,858.226440 815.978455,858.184265 
	C812.960571,857.554199 810.882812,856.207642 809.839783,853.571533 
	C809.162720,851.860352 807.815552,850.705078 806.264404,849.247620 
	C799.863403,848.716614 798.909424,847.066101 803.408569,842.188843 
	C804.958862,840.508423 804.886536,836.874207 806.932861,834.813843 
	C809.458496,832.271118 812.164795,831.684082 815.227356,834.219788 
	C818.993225,837.337830 819.779846,837.042053 821.323792,832.426086 
	C822.005981,830.386597 822.674377,828.342285 823.327209,826.293213 
	C824.483337,822.664673 823.241089,821.476624 819.324829,822.855347 
	C817.710632,823.971375 816.207397,823.703247 814.795532,823.589417 
	C812.943237,823.440002 811.187744,822.740295 810.718567,820.741577 
	C810.143372,818.291443 812.289856,817.959534 814.217163,817.148682 
	C815.412720,816.505493 816.975830,817.274048 817.164246,815.703308 
	C817.205444,815.359436 816.368896,815.457336 815.567871,815.516357 
	C813.279602,816.072937 811.472900,815.875305 810.647217,813.830933 
	C809.901733,811.985107 811.108765,810.689575 812.548523,809.729919 
	C813.917725,808.817322 814.388123,807.585388 814.503052,805.988953 
	C814.758362,802.443115 816.556885,800.172485 820.603882,799.658936 
	C822.760437,799.874023 824.545044,800.034973 826.524536,798.962402 
	C828.131775,798.548462 829.502747,798.509705 830.983398,797.643555 
	C834.060303,798.267883 836.404663,799.827209 838.877625,801.080750 
	C841.101318,802.207947 842.846191,801.947205 844.626526,800.180237 
	C846.434265,798.385986 847.424255,796.641602 846.017395,794.121155 
	C844.982727,792.267517 844.338623,790.192139 842.584717,788.337524 
	C841.757385,786.414307 841.538574,784.679138 843.287720,783.790283 
	C845.465759,782.683594 846.796753,784.617737 848.644653,785.874329 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M815.652100,857.139343 
	C816.754150,857.004517 817.502747,857.004089 818.625671,857.003052 
	C819.384766,856.620728 819.844788,856.472717 820.689941,856.779175 
	C822.388916,856.999390 823.777771,856.998779 825.583374,856.995483 
	C828.629028,856.929749 831.255127,857.185730 833.842896,856.264709 
	C837.351807,855.015747 839.426270,857.706177 841.520081,859.649353 
	C844.420349,862.340881 847.261292,863.596497 851.128723,861.636902 
	C854.133362,860.114441 856.000244,862.808594 858.084778,864.210938 
	C860.093445,865.562317 859.602661,867.501465 859.038696,869.547119 
	C858.174744,872.680725 854.981567,874.888306 855.771423,878.773010 
	C856.420959,883.166687 857.843872,887.073181 859.186523,890.991455 
	C859.617065,892.248108 860.427917,893.653992 861.954163,893.357239 
	C863.450195,893.066284 863.036987,891.456787 862.975708,890.391907 
	C862.789185,887.146912 863.247314,883.995728 861.720703,880.685303 
	C859.867432,876.666565 864.520447,874.476318 866.053528,871.371033 
	C866.756653,869.946838 868.807129,869.187866 870.590576,868.069702 
	C872.151672,869.426086 871.547546,870.882324 870.785767,872.127441 
	C868.815674,875.347473 868.138916,878.998779 868.102722,882.549683 
	C868.021423,890.508972 865.770691,898.137756 864.941345,906.370605 
	C864.119568,907.770386 863.112854,908.244507 861.589844,908.641174 
	C860.308899,908.753479 859.511475,908.522400 858.416870,907.894897 
	C854.844910,902.685791 853.811157,896.890686 852.187317,891.341125 
	C850.596741,885.905273 849.069641,880.463257 846.754150,874.864624 
	C843.986572,868.550659 839.364380,865.371399 833.001282,864.187439 
	C826.998474,863.070557 821.075989,864.253906 814.737122,864.131470 
	C813.085815,863.598755 812.033142,862.841125 810.952271,861.496094 
	C810.886230,858.253235 812.549255,857.127991 815.652100,857.139343 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M977.280029,742.177307 
	C982.658325,738.647583 984.713440,733.093018 987.313660,728.023865 
	C996.696899,709.730835 1005.871887,691.330994 1015.422791,672.806030 
	C1016.247437,672.851257 1016.646484,673.206421 1017.066040,674.088745 
	C1017.256775,674.743408 1017.299744,675.014038 1017.356995,675.695679 
	C1017.338135,677.550842 1016.935120,678.807312 1015.216858,679.726929 
	C1014.508484,680.375366 1014.185608,680.888672 1013.832886,681.713623 
	C1014.591309,682.745422 1013.953003,683.170837 1013.166931,683.786316 
	C1012.884827,685.650696 1012.217712,687.057678 1010.820801,688.521851 
	C1009.722046,692.352539 1007.324219,695.156982 1005.776855,698.755676 
	C1005.531494,699.545715 1005.400452,700.017639 1005.096436,700.668701 
	C998.181702,711.557739 994.828186,723.968140 987.963196,734.625244 
	C986.623962,736.704224 985.454651,738.868042 983.231323,740.707764 
	C982.960632,740.956360 983.000000,740.997559 982.977844,740.980103 
	C981.010376,742.694763 981.553223,745.590942 979.595459,747.669556 
	C978.557007,748.176575 977.802795,748.271179 976.642334,748.166382 
	C976.015808,748.000916 975.774170,747.901245 975.222290,747.559326 
	C974.456970,746.614868 974.415161,745.849243 974.651733,744.674194 
	C975.117676,743.248657 975.908569,742.635132 977.280029,742.177307 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M863.998962,907.099609 
	C864.837402,896.555237 865.927063,886.336304 867.022766,876.117981 
	C867.340515,873.154053 869.986938,871.394226 870.901733,868.366089 
	C874.871033,863.109070 878.009094,857.363464 884.878479,855.216125 
	C885.436279,855.679321 885.615662,856.081909 885.884155,856.693115 
	C886.696350,858.435425 885.394592,859.284241 884.513245,860.638184 
	C875.348145,868.185120 870.900330,877.421448 871.897888,889.020386 
	C872.109070,891.476135 872.815369,893.900085 872.150146,896.760132 
	C869.664978,899.843323 868.545898,903.126770 867.547363,906.890991 
	C866.870239,907.541870 866.377075,907.741028 865.421143,907.782349 
	C864.757874,907.643372 864.505188,907.534363 863.998962,907.099609 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M983.329102,740.968994 
	C983.007690,737.352722 985.791443,734.964233 987.489807,732.365417 
	C993.564209,723.070435 996.522888,712.324036 1002.056458,702.825195 
	C1002.578613,701.928833 1003.147461,701.182556 1004.564209,700.895691 
	C1005.832825,703.200500 1005.241516,704.866150 1003.696106,707.085144 
	C1000.840576,711.185181 997.867554,715.267273 996.560608,720.433228 
	C994.828430,727.279907 991.702332,733.644348 986.985779,739.104919 
	C986.042725,740.196716 985.162781,740.995239 983.329102,740.968994 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1015.159912,680.030029 
	C1015.164185,678.797180 1015.496948,677.732300 1015.869873,676.328308 
	C1016.611877,675.393982 1017.300964,674.789673 1017.505066,673.460083 
	C1019.439697,669.324524 1020.707397,665.198792 1024.314819,661.989380 
	C1025.117188,665.850342 1024.891479,669.588562 1021.997803,672.583557 
	C1021.117981,673.494141 1021.318604,674.586609 1021.338806,675.782104 
	C1021.405640,679.740723 1019.518799,681.482544 1015.159912,680.030029 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M1024.669434,655.109009 
	C1025.641113,653.049988 1026.442383,651.356750 1027.468750,649.388184 
	C1027.890747,648.462891 1028.152100,647.842896 1028.658447,646.965454 
	C1030.596558,643.056458 1032.419556,639.465027 1034.519043,635.652771 
	C1034.566406,636.690796 1034.337158,637.949707 1034.049072,639.589722 
	C1034.670898,641.643860 1033.625610,642.949219 1032.982788,644.684204 
	C1032.279053,646.444824 1031.648438,647.877075 1030.964355,649.648926 
	C1030.240112,653.339722 1030.419189,657.314148 1025.074585,657.259888 
	C1024.473267,656.543335 1024.393677,656.023132 1024.669434,655.109009 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1024.585815,656.906128 
	C1029.274658,656.805664 1028.235596,652.313782 1030.611328,650.166992 
	C1031.717529,650.164795 1032.978760,650.439087 1032.519287,651.368469 
	C1030.660278,655.130005 1030.094360,659.779358 1025.356567,661.958923 
	C1025.009766,662.009766 1024.964722,661.975891 1024.942139,661.958984 
	C1024.011841,661.370300 1023.599365,660.476685 1023.273071,659.119019 
	C1023.245667,657.955017 1023.512878,657.290039 1024.585815,656.906128 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M1006.009888,698.932007 
	C1004.897766,694.760986 1007.545776,691.923889 1010.327271,688.913391 
	C1012.464783,693.538696 1008.545227,695.792664 1006.009888,698.932007 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M978.738464,747.179565 
	C980.597229,745.265198 979.993591,742.350037 982.608154,741.007568 
	C982.977173,741.413818 982.998596,741.864929 983.025879,742.654358 
	C980.758362,746.022278 981.585632,750.713562 977.370850,752.946533 
	C976.788452,753.111084 976.561279,753.222107 976.006409,753.143250 
	C975.225403,752.312378 975.092957,751.596436 975.288269,750.482788 
	C976.032349,748.770935 977.703003,748.602417 978.738464,747.179565 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M1033.232422,644.840088 
	C1031.316528,643.191223 1033.361206,642.030212 1033.853760,640.266724 
	C1033.990234,639.970825 1034.487915,639.984802 1034.736572,639.987549 
	C1034.969849,641.690369 1035.284668,643.491516 1033.232422,644.840088 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M1013.199829,683.992798 
	C1013.085205,683.457275 1013.308350,682.970581 1013.667358,682.254639 
	C1015.084106,682.173950 1016.836731,682.354736 1016.389160,683.983582 
	C1015.970215,685.508179 1014.527405,683.955750 1013.199829,683.992798 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M856.114136,878.950378 
	C856.000000,879.000000 856.244629,878.595459 856.244629,878.595459 
	C856.244629,878.595459 856.228333,878.900757 856.114136,878.950378 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M1020.477783,874.640930 
	C1021.849548,878.296692 1017.860901,879.488953 1017.005615,882.610596 
	C1016.684143,883.440491 1016.415771,883.923523 1016.020020,884.652344 
	C1014.885864,886.251831 1013.603210,887.344299 1012.209351,888.763855 
	C1011.980774,888.979858 1012.000000,888.997559 1011.990051,888.992249 
	C1010.508118,892.561401 1007.500061,893.057800 1004.708008,891.525208 
	C1000.494751,889.212463 996.754822,888.045715 992.837219,891.845093 
	C992.357239,892.310547 991.250793,892.052734 990.253052,892.735840 
	C989.971863,892.963379 989.997559,893.000000 989.980408,892.985107 
	C985.071838,898.875244 978.741455,899.310547 974.360168,893.340393 
	C972.039490,890.178284 969.293152,891.131226 966.250183,890.466553 
	C965.616333,889.815247 965.505737,889.324707 965.836304,888.487976 
	C966.171448,887.828064 966.259094,887.470459 966.410461,886.842163 
	C966.329224,885.868225 966.789124,885.350891 967.489685,884.326782 
	C974.115723,870.320251 971.965759,857.366272 964.980957,844.420410 
	C965.675232,843.143433 966.452271,842.208862 967.485229,841.098145 
	C969.382935,840.320435 969.560181,841.557129 969.964050,842.625671 
	C971.045654,845.487793 970.916687,848.705872 972.854614,851.662476 
	C974.331604,853.434692 974.280457,855.305908 974.665161,857.442627 
	C974.396484,862.910461 976.155945,867.653870 977.205811,872.915771 
	C977.922852,876.210632 977.745361,879.160461 977.737122,882.475098 
	C977.343262,886.570862 978.445435,889.655945 982.413757,890.334290 
	C985.351624,890.836548 988.068237,889.860840 988.563110,885.810852 
	C988.689453,884.716370 988.974304,884.060120 989.682922,883.199707 
	C989.906006,882.906860 990.083740,882.610901 990.139038,882.447266 
	C993.200134,881.335571 997.592041,882.363647 997.581909,876.750183 
	C998.071533,873.269226 997.789795,870.133850 997.747742,866.999573 
	C997.723694,865.196106 997.516052,863.309265 999.336548,861.713074 
	C1004.275391,863.859619 1002.777161,868.592041 1003.865173,872.270081 
	C1004.225769,873.489075 1003.743835,874.885254 1003.686218,876.203613 
	C1003.580627,878.621094 1004.578552,880.263306 1006.815247,881.282532 
	C1008.984375,882.270935 1010.659058,881.553406 1012.160034,880.035400 
	C1013.314026,878.868408 1014.726929,877.944885 1015.760254,876.178040 
	C1016.134705,875.723206 1016.312683,875.565063 1016.823425,875.277100 
	C1018.138794,874.982910 1019.121460,874.818604 1020.477783,874.640930 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M1046.794067,866.035278 
	C1047.025757,869.343811 1044.006104,868.986023 1042.003174,870.328735 
	C1041.530396,871.476562 1040.820068,871.904785 1039.589844,872.309448 
	C1036.741943,872.433716 1034.276489,874.158081 1031.603516,871.966431 
	C1027.934937,860.786377 1024.876099,849.808228 1022.291504,838.695801 
	C1021.616394,835.793030 1020.968567,832.473206 1016.245117,834.263428 
	C1014.947876,835.085632 1013.902344,835.614685 1012.422974,835.994019 
	C1011.756470,835.946777 1011.224670,835.950256 1011.150024,835.767395 
	C1010.166382,833.355713 1013.226562,826.812256 1016.268555,825.613586 
	C1019.765869,824.235596 1023.081909,824.361450 1025.198608,828.333313 
	C1029.418091,836.251587 1029.744019,845.194702 1031.998535,853.627808 
	C1032.381348,855.059570 1032.523315,856.554016 1032.816895,858.011414 
	C1034.474731,866.238525 1034.478882,866.237671 1043.024414,867.454956 
	C1044.094604,866.959778 1044.773071,866.476562 1045.866211,865.905396 
	C1046.281006,865.817505 1046.619629,865.971069 1046.794067,866.035278 
z"/>
<path fill="#060F14" opacity="1.000000" stroke="none" 
	d="
M1016.981140,874.812256 
	C1017.156128,875.147278 1017.044250,875.536133 1016.979736,875.727905 
	C1010.513733,881.074524 1007.521790,879.941284 1006.119812,871.876038 
	C1004.500732,862.561096 1001.918213,853.471436 999.547180,844.335327 
	C999.023010,842.315735 998.549072,839.681702 995.737549,839.889954 
	C993.349487,840.066833 990.624634,840.500793 990.130371,844.180847 
	C989.877747,845.058716 989.611877,845.500671 988.860352,845.927063 
	C987.288696,845.805298 986.245483,845.636658 986.033142,844.054504 
	C985.518005,840.216736 991.064026,832.696716 995.033875,832.007935 
	C999.978027,831.150024 1002.538452,833.207825 1004.107239,839.516907 
	C1006.340698,848.498657 1008.549133,857.487793 1010.628662,866.505920 
	C1011.191223,868.945312 1011.806763,870.871338 1014.751526,871.273193 
	C1016.278259,871.481445 1015.948059,873.496704 1016.981140,874.812256 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M988.603760,846.360229 
	C988.542786,845.526672 988.615234,845.084473 988.736694,844.309692 
	C989.073242,843.192993 989.587402,842.602295 990.682007,842.042786 
	C994.320068,841.135376 995.847839,843.160767 997.321899,845.948853 
	C998.257751,847.271790 998.678711,848.410461 998.918152,849.977539 
	C998.978210,851.389343 998.725342,852.501709 999.596436,853.752686 
	C999.958130,854.673462 1000.047424,855.320862 1000.062622,856.303711 
	C999.866882,857.683044 999.888184,858.710571 1000.546875,859.981934 
	C1000.660767,860.746582 1000.577576,861.173523 1000.135376,861.786804 
	C998.257568,864.924988 999.807739,867.888123 999.719788,870.827087 
	C999.669006,872.522766 998.751770,874.192627 997.906433,876.134033 
	C993.884338,866.738159 990.898193,856.868103 988.603760,846.360229 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M1012.167603,836.293701 
	C1012.191833,834.755005 1013.225220,834.392029 1014.432861,833.764648 
	C1017.464905,832.156921 1019.447021,833.042908 1020.567932,835.778015 
	C1021.969788,839.198364 1023.805725,842.582703 1022.171509,846.786133 
	C1018.941101,844.249573 1018.499512,839.177429 1013.327759,838.050720 
	C1012.736877,837.522522 1012.498230,837.040039 1012.167603,836.293701 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M1043.418213,867.421753 
	C1043.416138,867.442932 1043.426270,867.476501 1043.429688,867.447144 
	C1043.432983,867.417847 1043.420410,867.400635 1043.418213,867.421753 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M953.200378,880.120789 
	C944.701843,880.939453 941.288452,878.964478 938.404907,871.233459 
	C936.590637,866.369263 935.901978,861.302246 936.252197,855.674805 
	C936.649414,853.613281 937.759277,852.801941 939.689331,852.569824 
	C943.737122,855.510681 947.464600,858.114929 950.132629,862.321960 
	C953.398376,867.471619 954.196594,872.906372 954.748779,878.991455 
	C954.802490,880.085083 954.348206,880.451538 953.200378,880.120789 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M940.070679,852.281372 
	C938.997437,853.437988 937.946350,854.243347 936.581421,855.144531 
	C933.905151,853.282959 935.185791,851.213684 936.387878,849.493286 
	C939.970276,844.366333 946.975098,843.227356 952.029053,847.013611 
	C962.187256,854.623779 963.085815,865.665649 960.482605,875.609436 
	C960.290771,876.342285 959.667725,877.069031 958.314819,877.014709 
	C957.473999,871.959351 958.955017,866.515503 956.102905,861.660889 
	C954.085571,858.227112 952.680664,854.347107 949.328125,851.947510 
	C946.382385,849.839050 943.171936,848.438843 940.070679,852.281372 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M965.910889,884.918579 
	C966.187744,883.771667 966.175964,882.459412 967.596252,882.030518 
	C967.697998,882.462036 967.799805,882.893494 967.925842,883.606323 
	C967.950073,883.887695 967.973267,883.955627 967.937927,883.961792 
	C967.601562,884.497375 967.289673,885.016418 966.394775,885.164795 
	C966.105469,885.071472 965.974792,884.970764 965.910889,884.918579 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M953.023193,880.042847 
	C953.275757,879.588989 953.738464,879.375366 954.575317,879.250122 
	C954.702820,880.244507 954.369202,880.992615 953.023193,880.042847 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M922.872070,852.958862 
	C920.234497,863.521484 921.781738,873.141479 927.127197,882.318787 
	C932.615295,891.740906 943.978149,896.331238 954.475586,893.075500 
	C957.153625,892.244812 959.739502,891.116821 962.743408,890.140686 
	C964.315857,893.314880 962.019592,894.813110 959.955811,896.014221 
	C949.616577,902.031433 939.878845,903.760010 929.308594,895.421265 
	C927.370972,894.349304 926.139160,893.172668 925.837402,890.885498 
	C925.298340,889.484131 924.851440,888.424927 924.027100,887.199707 
	C923.281921,886.665527 922.792725,886.354553 922.058472,885.886475 
	C913.987122,880.799988 914.075195,873.430054 915.660278,865.204712 
	C916.569824,862.099670 917.273254,859.346863 918.288452,856.709900 
	C919.041687,854.753296 919.661255,852.426575 922.872070,852.958862 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M883.731934,860.286621 
	C884.585388,859.163147 885.152588,858.312805 885.846558,857.182068 
	C885.973267,856.901672 886.394592,856.814087 886.604614,856.771240 
	C886.893433,860.418945 885.733521,863.902588 884.893066,867.417297 
	C882.019165,879.435974 887.995728,888.321716 896.232056,895.271423 
	C903.178711,901.132935 911.982666,901.629700 920.632385,897.682556 
	C922.963562,896.618896 924.371765,895.033752 925.037720,892.254517 
	C929.315796,895.019958 926.801941,897.863831 924.452332,901.021973 
	C923.408752,902.059204 922.469299,902.592041 921.378967,903.572266 
	C920.574341,904.062744 920.072510,904.253662 919.148987,904.465088 
	C909.221985,908.164001 899.647766,907.997864 891.921692,901.260254 
	C882.668762,893.190979 875.615173,883.450562 879.405762,869.422241 
	C879.991089,865.823853 880.963745,862.838745 883.731934,860.286621 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M914.644958,855.953979 
	C915.397217,856.254089 915.940491,856.558167 916.778748,856.964478 
	C919.156799,859.819641 916.993652,862.039551 916.092224,864.809326 
	C915.928223,867.226013 915.266724,869.155151 914.828613,871.488342 
	C911.824341,874.408569 911.892334,877.502014 913.349976,881.074585 
	C913.738159,881.712585 913.927551,882.090698 914.231201,882.747986 
	C914.592407,883.586670 914.982239,883.949280 915.924194,884.006897 
	C916.441223,884.027100 916.651672,884.037476 917.181885,884.092529 
	C918.381348,884.348389 919.157715,884.743591 919.931885,885.714600 
	C918.395325,888.142639 915.822388,888.013672 913.236084,888.450928 
	C907.408875,886.870117 906.399902,882.694580 906.342224,877.729431 
	C906.284729,872.772705 905.654846,872.440430 901.231262,874.398376 
	C899.742004,875.057617 898.409424,876.235168 896.332764,875.233765 
	C895.136536,873.706055 895.185791,872.206787 895.256958,870.346497 
	C899.230957,866.774048 904.695068,866.659851 908.717407,863.050049 
	C909.637573,861.687012 910.383301,860.609009 911.040100,859.090393 
	C911.757080,857.806396 912.431091,856.912415 913.705566,856.147095 
	C914.024231,856.030212 914.435974,855.958008 914.644958,855.953979 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M896.053589,875.380554 
	C899.410339,874.362183 901.807251,871.676758 905.259705,870.959961 
	C907.439453,870.507446 907.972473,870.741699 908.003418,872.718079 
	C908.029419,874.377563 908.132080,876.050659 907.971375,877.695312 
	C907.552368,881.982910 908.733398,885.444702 912.815063,887.977234 
	C903.455566,888.560913 899.283447,885.458069 896.053589,875.380554 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M922.711914,901.129089 
	C924.504272,898.255920 927.961304,896.304626 925.375732,892.173523 
	C925.104492,891.889465 925.055481,891.946594 925.089722,891.961914 
	C924.974792,891.847961 924.825623,891.718689 924.784546,891.313782 
	C924.935913,890.866638 924.979370,890.695129 925.031372,890.258301 
	C925.226013,889.844482 925.412048,889.695923 925.909424,889.722229 
	C927.072693,891.014526 927.924622,892.131958 928.940796,893.508362 
	C930.717468,897.218933 933.327454,899.538818 936.875610,900.946167 
	C938.720215,901.677856 940.476135,902.708130 940.543579,905.429565 
	C936.821533,908.319885 933.463684,906.632812 930.501099,904.650757 
	C928.450317,903.278748 926.632385,903.136047 924.131348,903.701355 
	C923.547424,903.583435 923.322937,903.492859 922.850464,903.135193 
	C922.430786,902.400757 922.410889,901.925049 922.711914,901.129089 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M913.731689,855.756104 
	C914.256165,857.279724 913.523987,858.151184 912.604248,859.283508 
	C907.191345,861.289673 901.457397,861.664001 896.878296,865.863525 
	C896.350525,866.169739 896.116150,866.250000 895.525818,866.223572 
	C893.182007,864.565308 894.438477,863.311035 895.516052,861.996521 
	C900.205750,856.275085 906.129822,853.981018 913.731689,855.756104 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M920.376404,885.983154 
	C919.254883,885.758057 918.424438,885.502991 917.288208,885.134399 
	C914.901733,883.539429 914.085144,881.065613 912.303467,878.880859 
	C911.971741,876.400085 912.247009,874.363953 913.487976,872.131226 
	C913.875427,869.746277 913.876038,867.664307 914.940674,865.396362 
	C915.415894,864.635864 915.868835,864.477173 916.703979,864.782715 
	C917.688904,868.291809 916.133972,871.365723 916.253296,874.709534 
	C916.421448,879.426270 920.627869,881.318787 921.864685,885.388550 
	C921.431458,885.804138 921.049500,885.878662 920.376404,885.983154 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M895.148193,866.442078 
	C895.169861,866.116943 895.506287,865.975830 895.680908,865.923096 
	C900.184814,863.739136 904.285767,860.675964 909.852783,862.847412 
	C906.642517,867.943787 901.219727,868.715942 895.693970,870.014099 
	C895.316406,869.977966 895.026367,870.028809 894.879272,870.034485 
	C894.500854,869.607666 894.427856,869.147461 894.665039,868.349854 
	C894.935059,867.613281 895.030762,867.190247 895.148193,866.442078 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M926.125549,889.578979 
	C926.024597,889.920166 925.828369,889.943298 925.336060,889.979736 
	C924.054382,889.496704 924.173523,888.489807 923.861938,887.308289 
	C924.919250,887.347351 925.646851,888.133911 926.125549,889.578979 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M894.576050,868.077209 
	C894.848083,868.484192 894.856812,868.931946 894.798828,869.709961 
	C893.565979,869.623413 892.622131,869.158142 894.576050,868.077209 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M914.693359,851.985229 
	C914.949890,852.071472 914.970154,852.268494 914.985840,852.762939 
	C914.471252,852.921082 913.951294,852.787231 914.693359,851.985229 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M924.765198,891.155151 
	C924.892517,891.038147 925.059204,891.494629 925.091553,891.735962 
	C924.961975,891.742188 924.799927,891.507202 924.765198,891.155151 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M887.177002,856.796021 
	C887.059326,856.570129 887.201599,856.146729 887.658569,855.569336 
	C887.794495,855.809753 887.615784,856.204102 887.177002,856.796021 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M940.219116,906.125854 
	C939.598877,902.970886 937.129395,902.691162 935.046326,901.850220 
	C931.758423,900.522766 928.769775,898.878540 929.071655,894.174072 
	C930.831482,894.710144 932.572449,895.627625 934.282166,896.600098 
	C943.380371,901.774841 956.540039,899.135864 963.063354,890.491211 
	C963.902222,889.512939 964.685486,888.869995 965.776245,888.206421 
	C966.137512,888.625549 966.191162,889.065430 966.299805,889.833130 
	C967.672729,892.557556 966.337830,894.080017 964.329712,895.744995 
	C963.786499,897.193665 963.868225,898.522156 962.351318,899.381897 
	C958.858398,899.365356 957.195007,902.057556 954.954590,903.593811 
	C952.536316,905.252136 950.181274,906.558960 946.848633,906.186707 
	C945.268311,905.774353 944.085754,905.937683 942.661194,906.729980 
	C941.654541,906.899109 941.008362,906.778259 940.219116,906.125854 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M917.148560,864.673157 
	C916.568542,865.018677 916.132751,865.024109 915.370789,865.028687 
	C915.445801,862.555847 917.649353,860.596252 917.079529,857.458252 
	C917.927002,852.177979 920.687988,848.873230 925.692871,847.087036 
	C925.224670,848.924988 924.390259,850.803284 923.361328,852.967529 
	C919.894958,853.911316 919.947876,856.907104 919.040161,859.194702 
	C918.373779,860.874146 917.869324,862.617981 917.148560,864.673157 
z"/>
<path fill="#26323D" opacity="1.000000" stroke="none" 
	d="
M966.163513,885.454895 
	C966.544006,884.777588 966.982483,884.483765 967.661804,884.078918 
	C967.977051,884.862671 968.078735,885.767395 966.764954,886.413696 
	C966.389954,886.327148 966.305725,886.082764 966.163513,885.454895 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M976.409790,884.113403 
	C976.169434,880.491821 976.136414,877.202148 976.076843,873.459656 
	C977.014893,870.327576 973.907227,867.556641 976.619995,864.951965 
	C979.485107,868.858459 979.659302,873.431702 980.842163,877.683716 
	C982.666687,884.242249 982.538269,884.277954 989.516113,882.871948 
	C989.937500,883.656128 989.968933,884.405334 989.961670,885.529663 
	C985.163818,892.014526 981.724976,891.637390 976.409790,884.113403 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M973.187378,851.522766 
	C970.210266,851.549316 970.368958,849.178284 969.936157,847.097717 
	C969.536133,845.174744 968.901306,843.300476 968.055542,841.163391 
	C967.530334,839.846863 967.319519,838.771667 967.079224,837.363037 
	C967.672302,836.431824 968.399475,836.275024 969.543091,836.742493 
	C970.986816,841.686218 972.108032,846.401733 973.187378,851.522766 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M976.952087,864.638855 
	C974.971741,867.375671 978.244080,870.079895 976.300049,872.743652 
	C974.384705,868.214722 972.859741,863.402405 974.302551,858.025940 
	C976.522766,859.601501 975.951416,862.202942 976.952087,864.638855 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M969.932068,836.614258 
	C969.161255,836.990540 968.456848,837.010437 967.401123,837.029968 
	C967.006714,835.729492 967.679749,835.051086 969.248535,834.887878 
	C969.714111,835.356689 969.856323,835.807251 969.932068,836.614258 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M1065.038330,843.963379 
	C1065.029053,844.461243 1065.010010,844.961792 1064.981323,845.838257 
	C1064.570923,846.250916 1064.170044,846.287720 1063.471436,846.274658 
	C1062.936890,845.995544 1062.643311,845.529663 1062.471558,845.570923 
	C1056.235352,847.067444 1055.219482,842.696472 1055.222290,838.428223 
	C1055.226318,832.342102 1053.694580,826.310303 1054.872314,820.164795 
	C1055.317993,817.839233 1055.912231,816.041992 1058.443115,814.888184 
	C1058.775269,814.752686 1058.904907,814.896240 1058.971436,814.966248 
	C1059.740356,815.825378 1060.136475,816.786743 1059.833374,817.780090 
	C1058.001099,823.785339 1059.122192,829.762817 1059.784912,835.775330 
	C1060.156616,839.147461 1061.403076,841.785461 1064.733398,843.702759 
	C1065.038086,843.953003 1065.028564,843.966125 1065.038330,843.963379 
z"/>
<path fill="#0C161B" opacity="1.000000" stroke="none" 
	d="
M1066.791016,855.232788 
	C1068.790039,861.425720 1068.673218,861.604919 1062.310059,864.029358 
	C1060.767456,864.617126 1059.222168,865.289307 1057.833252,866.167725 
	C1055.511597,867.636047 1054.145996,866.985291 1053.118896,864.250732 
	C1052.278809,861.786499 1051.664307,859.604370 1050.862427,857.150513 
	C1050.497070,855.207458 1050.319092,853.536316 1050.125732,851.444641 
	C1050.380127,850.797241 1050.649902,850.570374 1051.144775,850.201904 
	C1055.019775,850.435852 1055.466064,853.801758 1057.017090,856.127869 
	C1058.149780,857.826660 1056.712158,861.533936 1060.002441,861.303589 
	C1063.184937,861.080688 1066.675049,860.019531 1066.791016,855.232788 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M1162.040039,835.606323 
	C1161.571899,835.862427 1161.050415,835.851929 1160.155762,835.709961 
	C1160.401978,834.433105 1161.075439,833.789490 1162.040039,835.606323 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M1164.830078,861.240479 
	C1165.131592,861.942993 1165.055054,862.583313 1164.892090,863.534302 
	C1159.205078,865.201721 1154.722168,869.520020 1148.598755,869.644775 
	C1142.042603,869.778259 1135.608521,869.815918 1130.332275,865.098328 
	C1128.868774,863.789795 1127.149414,862.392334 1129.312988,860.020996 
	C1137.334961,866.814819 1146.498047,865.502625 1155.530762,864.174561 
	C1158.585327,863.725525 1161.482788,862.207642 1164.830078,861.240479 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M1170.041016,859.582397 
	C1169.417969,859.851501 1168.939331,859.870056 1168.107422,859.816528 
	C1167.923218,858.082458 1168.869873,857.254517 1170.861084,857.225586 
	C1170.868286,857.962524 1170.526855,858.647156 1170.041016,859.582397 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M1149.973511,800.594971 
	C1148.903809,800.995422 1147.733398,801.102356 1146.992188,799.485596 
	C1148.077515,799.009888 1149.254883,798.888306 1149.973511,800.594971 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M1154.829102,805.800476 
	C1153.527710,805.838684 1152.994873,804.980347 1152.962891,803.397461 
	C1154.094116,803.465515 1154.752075,804.249268 1154.829102,805.800476 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M1152.901123,802.719238 
	C1152.049805,802.941406 1151.349609,802.596985 1150.998291,801.383057 
	C1151.874023,801.090515 1152.629639,801.368469 1152.901123,802.719238 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M1138.964844,800.774170 
	C1138.873901,801.012634 1138.709106,801.061707 1138.290039,801.068176 
	C1138.125610,800.499878 1138.408936,800.349731 1138.964844,800.774170 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M1153.184937,843.750854 
	C1153.935303,841.347351 1155.659546,840.987854 1158.003052,841.825684 
	C1160.871216,842.851013 1159.768311,845.210693 1160.006470,847.534180 
	C1159.187378,848.271301 1158.409424,848.598328 1157.310791,848.954224 
	C1156.736450,846.693054 1157.571899,843.680298 1153.184937,843.750854 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M1144.158447,844.954712 
	C1145.643311,841.820435 1148.147095,843.167053 1150.708008,843.622803 
	C1149.321289,846.865479 1146.871704,846.181152 1144.158447,844.954712 
z"/>
<path fill="#0C161B" opacity="1.000000" stroke="none" 
	d="
M977.178101,742.091919 
	C977.148071,742.822876 976.637939,743.525452 975.943115,744.501953 
	C975.078186,745.735657 974.003418,746.338440 973.107056,747.588013 
	C972.133484,748.152344 971.409363,748.316101 970.282532,748.410950 
	C969.146912,748.461853 968.388000,748.462585 967.247681,748.474609 
	C958.453125,749.407410 950.529785,752.263245 942.148010,754.830139 
	C941.116272,755.311890 940.368225,755.481323 939.337646,755.969727 
	C937.525696,756.550476 936.038086,756.871155 934.176453,757.280762 
	C932.504395,757.493286 931.215088,757.518188 929.556396,757.185669 
	C919.045837,752.212402 908.266724,748.376404 898.815857,742.420715 
	C885.805725,734.221924 872.006653,727.734436 858.403442,720.769714 
	C853.379028,718.197266 847.992554,716.331970 843.357727,712.974121 
	C842.007690,711.996033 840.428101,711.366760 839.770813,709.240112 
	C839.513306,707.809998 839.635254,706.789612 840.238159,705.468384 
	C842.906677,703.129456 844.869568,705.172852 846.760315,706.152710 
	C855.582520,710.724731 864.534790,715.032227 873.774231,719.581299 
	C874.356750,719.777832 874.583557,719.870972 875.124756,720.150024 
	C876.636169,721.506775 878.007751,722.414062 879.514648,723.146057 
	C895.489746,730.906067 911.010559,739.551392 926.905701,747.473633 
	C931.806885,749.916382 936.168762,751.764587 940.822021,747.080566 
	C943.803223,749.665894 946.444153,747.739746 949.028564,747.103027 
	C958.151672,744.855530 967.327148,742.934143 977.178101,742.091919 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M941.209839,747.149048 
	C935.939819,753.091614 935.918274,753.136230 928.391235,749.508850 
	C910.289795,740.785828 892.477417,731.499634 874.662720,721.583984 
	C877.411804,718.403870 879.844666,720.769592 882.691650,722.234680 
	C883.644653,722.844116 884.255188,723.284668 885.314453,723.653320 
	C897.118896,728.703796 908.050598,734.676025 918.955811,740.698669 
	C922.462463,742.635254 926.249207,743.950989 929.846191,745.669983 
	C933.613770,747.470520 935.992065,746.637878 937.920105,742.900940 
	C939.296631,740.232910 940.293518,736.839233 944.506836,736.753723 
	C944.801147,736.964111 945.117310,737.051086 945.280884,737.072632 
	C944.219421,739.846252 942.994263,742.598450 941.672729,745.727295 
	C941.420044,746.393311 941.326660,746.701904 941.209839,747.149048 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M1023.720154,658.776855 
	C1024.771362,659.545532 1024.942749,660.377563 1024.927734,661.613770 
	C1022.083069,665.235535 1021.083923,669.501221 1018.962280,673.547241 
	C1018.366577,674.730713 1017.518799,675.093262 1016.265991,675.132935 
	C1015.926331,674.449280 1015.898865,673.928833 1015.795776,673.024475 
	C1015.825562,672.271790 1015.931091,671.903076 1016.215210,671.306274 
	C1017.490784,669.139709 1018.374084,667.086060 1019.631592,664.870117 
	C1020.458069,662.897949 1021.216187,661.253052 1022.209229,659.358582 
	C1022.688782,658.734009 1023.029602,658.543274 1023.720154,658.776855 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M1019.417480,664.729248 
	C1019.887329,667.111023 1018.691589,669.094360 1016.707703,670.977661 
	C1016.634460,668.724609 1017.950134,666.849182 1019.417480,664.729248 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M1024.023560,658.704956 
	C1023.622314,659.024719 1023.286621,659.062744 1022.697571,659.104858 
	C1022.437439,657.479919 1023.269287,656.227539 1024.553833,654.896606 
	C1024.880371,655.234680 1024.920776,655.726135 1024.958496,656.587524 
	C1024.666992,657.446045 1024.378174,657.934631 1024.023560,658.704956 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M1028.519043,646.799438 
	C1028.996826,647.480225 1028.883057,648.227539 1027.957397,648.974548 
	C1027.436890,648.274231 1027.750488,647.586243 1028.519043,646.799438 
z"/>
<path fill="#1A1F34" opacity="1.000000" stroke="none" 
	d="
M959.518982,721.531250 
	C959.482056,721.550293 959.555908,721.512268 959.518982,721.531250 
z"/>
<path fill="#0484A2" opacity="1.000000" stroke="none" 
	d="
M966.217773,690.862122 
	C966.071716,691.526367 966.071106,691.935120 966.061768,692.650330 
	C965.859314,693.115906 965.665466,693.275085 965.102539,693.524780 
	C961.912231,693.962219 960.774902,692.268494 960.459167,689.869019 
	C960.089661,687.060669 958.490479,685.941101 955.802856,685.645020 
	C951.375610,685.157349 947.118958,683.419128 942.496094,684.265442 
	C940.496887,684.631409 938.490845,683.664978 936.989868,682.295349 
	C934.194702,679.744812 930.912109,679.128784 927.725769,680.644348 
	C919.155273,684.720947 910.397705,684.232300 901.448059,682.607361 
	C889.666748,680.468323 877.741699,679.227600 865.963135,677.063904 
	C863.828430,676.671814 861.664124,676.016846 859.891174,674.832275 
	C853.271240,670.409241 845.705872,668.596130 838.306763,666.066650 
	C826.852173,662.150574 816.086731,656.280029 804.623901,652.382263 
	C798.028259,650.139465 791.767822,647.287781 785.351501,644.712402 
	C780.182739,642.637878 775.270203,640.409119 771.581787,636.041321 
	C767.814453,631.580139 762.103760,630.188293 756.977417,627.218628 
	C754.090637,626.660522 752.548096,625.144470 751.608826,622.775269 
	C747.213989,611.688721 744.306702,600.096130 740.272705,588.918640 
	C735.901489,576.806702 731.333313,564.741699 726.821289,552.671631 
	C723.643982,544.171997 718.776245,536.770264 713.931824,529.282776 
	C713.008362,527.855530 712.094055,526.505798 712.332520,524.352173 
	C719.874939,517.014099 718.087952,508.270844 716.692627,499.828217 
	C716.069153,496.055725 715.321838,492.353760 715.357666,488.517365 
	C715.374695,486.695801 715.133301,484.975800 714.126160,483.290405 
	C711.656433,479.157715 711.771851,479.050476 716.655518,477.572510 
	C715.546631,474.897827 713.676941,472.818604 711.602966,470.896729 
	C708.691040,468.198517 708.545349,465.553894 712.020325,462.755493 
	C712.759644,462.369598 713.215088,462.243774 714.040955,462.207062 
	C715.496582,462.439636 716.554565,462.949249 718.090820,462.751038 
	C722.083557,463.391388 725.386902,464.548523 728.087646,467.342896 
	C729.789246,469.103485 732.078064,470.231049 734.423523,471.164490 
	C737.742249,472.485321 739.474060,474.969208 738.849365,478.628448 
	C737.426514,486.963409 740.069763,495.073212 740.117859,503.356537 
	C740.177673,513.673096 740.623413,523.909363 737.083252,533.842651 
	C735.942078,537.044800 738.575012,542.553772 742.359131,543.988037 
	C747.355469,545.881714 749.425171,549.178711 749.003601,554.331360 
	C748.858887,556.099304 749.256104,557.598938 751.028381,558.506958 
	C755.679077,560.889771 756.843567,565.065063 756.663208,569.850037 
	C756.532898,573.307434 757.208557,576.391602 759.748291,578.997498 
	C762.862549,582.192749 763.215393,586.104004 761.857239,590.157593 
	C760.926636,592.935181 760.760742,595.602844 761.458069,598.473022 
	C762.511597,602.809631 764.640686,605.633423 769.476562,605.893311 
	C773.774780,606.124329 777.471619,607.909729 780.680054,610.814636 
	C782.408875,612.379944 784.362366,613.830322 786.627197,614.402954 
	C792.158325,615.801514 795.058472,619.622009 796.233521,624.797729 
	C797.199036,629.050476 799.792664,631.202576 803.670227,632.683289 
	C812.057434,635.886108 820.319214,639.427490 829.168762,641.232605 
	C832.112366,641.833130 834.170166,643.629089 836.070007,645.839478 
	C840.394348,650.870911 845.953308,653.625610 852.134705,656.195007 
	C862.559570,660.528198 873.818298,658.569702 884.235962,662.017456 
	C887.075684,662.957214 890.214539,661.923645 893.082581,661.076965 
	C896.977112,659.927063 900.737183,658.840942 904.590271,661.284912 
	C905.525269,661.877991 906.873047,661.490479 908.413391,661.206299 
	C910.109192,661.248657 911.281372,661.936279 912.970886,662.070435 
	C918.902222,664.115295 924.234680,662.110352 929.351929,660.628906 
	C934.599792,659.109802 940.179199,658.826599 945.171692,656.322632 
	C946.768616,655.521667 948.551514,656.198364 949.804077,657.372742 
	C953.046509,660.412598 956.694092,661.462646 961.182617,661.179016 
	C965.773682,660.888977 968.955200,664.397339 968.195007,668.819641 
	C967.622437,672.150879 967.149475,674.814392 970.786377,676.632507 
	C971.756958,677.117737 971.771545,678.661438 971.697144,680.208618 
	C970.725403,683.448853 969.786072,686.295715 967.311462,688.766296 
	C967.062866,689.047241 967.045166,689.016846 967.044861,689.029846 
	C966.526489,689.437317 966.508179,690.049561 966.217773,690.862122 
z"/>
<path fill="#048CA9" opacity="1.000000" stroke="none" 
	d="
M756.718506,626.348511 
	C757.401428,625.488220 758.139160,625.019470 758.656860,625.446533 
	C763.133545,629.138916 769.623657,630.013000 773.055359,634.925964 
	C776.723572,640.177673 782.225647,641.825012 787.681946,643.639893 
	C789.790649,644.341309 791.643799,645.046997 793.242554,646.630310 
	C794.151672,647.530762 795.428955,648.497498 796.617432,648.608154 
	C805.867859,649.469849 813.773743,654.209351 822.020386,657.685852 
	C831.619385,661.732544 841.137817,665.766052 851.327209,668.070618 
	C854.340515,668.752136 856.241455,671.395264 858.862061,672.770325 
	C865.606934,676.309509 873.188965,676.900879 880.474426,677.801819 
	C890.318542,679.019226 900.075317,680.777283 909.885864,682.155518 
	C913.869629,682.715271 917.303894,682.654785 920.950806,681.092407 
	C923.830261,679.858887 926.890015,678.959778 929.949036,678.257935 
	C932.551270,677.660889 935.180542,677.493286 937.010681,680.344055 
	C938.014526,681.907715 940.092834,683.346375 941.651062,682.793945 
	C946.962402,680.911072 951.671082,684.085510 956.702148,683.998962 
	C960.348389,683.936279 962.522949,685.354309 961.975952,689.328369 
	C961.644653,691.735596 962.667053,692.627136 964.965576,692.740356 
	C965.510559,694.574707 964.452454,695.948364 964.229858,697.784363 
	C960.903320,701.418030 960.950806,706.454895 957.429688,709.889404 
	C951.355469,710.521240 949.370667,709.338562 948.938721,704.306458 
	C948.604614,700.413757 946.387634,699.519409 943.226624,699.765015 
	C931.586670,700.669495 919.962036,699.454224 908.322388,699.600037 
	C898.038086,699.728882 887.903687,696.963074 877.547058,697.354492 
	C875.566162,697.429260 873.718506,696.313660 871.815308,695.714355 
	C863.157959,692.988464 853.362305,693.040771 846.768311,685.335388 
	C839.061462,683.981689 831.592957,681.581238 823.908691,680.130676 
	C822.034790,679.776855 820.281494,680.418152 818.521301,680.909668 
	C816.222961,681.551331 813.922302,681.666260 811.625488,680.955505 
	C807.611328,679.713257 806.913330,677.928528 808.882629,674.028320 
	C806.624390,672.941345 804.478333,672.716553 802.390930,674.521973 
	C799.872009,676.700562 797.497498,675.899353 795.230957,673.973450 
	C793.203430,672.250854 791.583801,670.003540 789.199219,668.765564 
	C786.914429,667.579346 784.539124,666.907471 784.573975,671.353943 
	C782.136902,674.307556 780.069702,672.670166 777.996582,671.293335 
	C776.268372,670.145569 774.852051,668.237244 771.961548,669.068604 
	C770.194092,669.096558 769.022034,668.652710 768.032227,667.139526 
	C764.230164,659.294495 762.512451,651.081665 759.272034,643.427979 
	C759.005005,642.797180 758.462402,642.415894 757.608521,641.855652 
	C756.291992,640.714844 756.190796,639.562561 756.612305,637.967773 
	C757.701111,634.713318 755.497009,632.217468 755.650024,629.046387 
	C755.730469,627.918091 755.991516,627.232361 756.718506,626.348511 
z"/>
<path fill="#056C86" opacity="1.000000" stroke="none" 
	d="
M936.182373,620.145630 
	C940.161621,609.133667 946.375732,599.382263 952.109924,589.402649 
	C955.064453,584.260681 958.144104,579.237183 960.188416,573.646118 
	C960.757690,572.089172 961.395081,570.528503 963.510620,569.791382 
	C966.203796,569.798767 967.827759,568.125732 969.764099,567.258545 
	C978.352600,563.412354 984.537964,564.799866 990.263794,572.233154 
	C991.538696,573.888306 993.135559,574.340698 994.942322,574.695129 
	C996.902527,575.079651 998.905029,575.268311 1000.832458,575.786743 
	C1005.629883,577.077332 1006.736145,579.433105 1004.380066,583.840881 
	C1003.285278,585.888916 1002.499573,587.068359 1005.574036,588.157349 
	C1008.459473,589.179260 1009.228638,593.188660 1006.726624,594.436462 
	C1001.124146,597.230347 1004.289062,600.359985 1005.440247,604.015808 
	C1008.288086,600.962036 1009.546204,597.630676 1010.583618,594.097473 
	C1011.700623,590.292969 1013.782959,587.457336 1018.722046,588.763062 
	C1018.972778,589.749756 1018.974792,590.489746 1018.966187,591.599243 
	C1015.193787,594.380798 1014.090881,598.642822 1012.187683,602.224731 
	C1005.450806,614.903992 999.063782,627.769165 992.280884,640.783142 
	C990.291504,642.854797 988.451050,644.385559 985.688293,644.462769 
	C981.286499,644.585754 980.315369,643.301941 982.040039,639.194031 
	C982.376404,638.392822 982.887878,637.516602 982.216553,636.923157 
	C978.880859,633.974243 978.200073,628.837097 973.739380,626.815979 
	C970.706116,625.441650 969.667542,622.690125 969.056458,619.640991 
	C968.170898,615.222168 966.089355,613.940857 961.679077,615.231628 
	C958.661804,616.114746 955.881714,617.678711 952.894897,618.674072 
	C949.596741,619.773254 946.286255,621.123413 942.863403,619.027466 
	C940.303528,617.460022 938.851196,621.189453 936.182373,620.145630 
z"/>
<path fill="#0493B1" opacity="1.000000" stroke="none" 
	d="
M784.095581,672.375977 
	C783.976807,671.841919 783.901123,671.663818 783.938354,671.513794 
	C784.437378,669.505066 779.612427,668.737976 782.315063,666.813171 
	C784.700989,665.113892 788.126343,665.159424 790.694519,667.311646 
	C792.985779,669.231750 794.973145,671.511536 797.131958,673.593872 
	C798.489197,674.902893 799.996948,675.654541 801.185974,673.482422 
	C803.033020,670.108276 805.772522,670.301758 808.331848,672.019287 
	C810.218628,673.285522 812.623718,672.220703 814.278442,673.794312 
	C814.073425,674.177368 813.999207,674.570679 813.778198,674.688416 
	C812.055908,675.606323 808.378845,674.017517 808.689697,677.093567 
	C808.968994,679.856445 812.914001,680.582153 814.809265,679.814514 
	C825.841492,675.346313 835.423767,682.838074 845.833374,683.125610 
	C849.099487,683.215881 851.505920,685.737000 853.518188,688.224487 
	C855.949829,691.230530 859.092468,692.595032 862.763306,691.766602 
	C865.093811,691.240662 867.179138,691.515747 868.992188,692.596130 
	C874.413269,695.826843 880.476440,695.195862 886.306763,696.227539 
	C902.308105,699.058899 918.471313,696.945862 934.465454,698.794861 
	C938.203491,699.226990 941.972595,697.754639 945.840393,697.927002 
	C948.723633,698.055542 951.103760,699.726624 950.587952,701.881348 
	C949.267761,707.397095 952.704712,708.484375 956.790649,709.909668 
	C956.947449,710.612976 956.758179,711.155212 956.345947,711.884766 
	C955.822693,712.241638 955.561462,712.458984 955.221741,712.948364 
	C954.625366,717.623657 951.389893,720.671326 949.262939,724.660156 
	C944.402771,726.775513 942.274658,725.712036 942.315063,720.995483 
	C942.341125,717.951416 941.117126,717.111145 938.500366,717.265015 
	C935.952393,717.415039 933.352905,717.307678 931.292542,715.758240 
	C922.154480,708.886108 911.618835,709.114441 901.108276,709.939453 
	C892.345703,710.627258 883.492432,710.687561 874.866150,712.784119 
	C873.242798,713.178650 871.556641,713.251953 870.071167,714.886108 
	C868.044800,716.326111 866.386902,715.903809 864.628052,715.062744 
	C858.359558,712.065308 852.135010,708.996948 846.075989,705.581177 
	C836.003784,699.903137 825.496338,695.034424 815.406128,689.402100 
	C810.466980,686.645020 805.162292,684.866577 800.140869,682.472412 
	C794.571533,679.816833 788.900879,676.972900 784.095581,672.375977 
z"/>
<path fill="#069BB9" opacity="1.000000" stroke="none" 
	d="
M869.068237,715.348145 
	C869.334961,713.155945 869.818420,710.976746 872.082214,711.136414 
	C876.526001,711.450012 880.529541,709.479065 884.746094,709.131531 
	C893.503662,708.409607 902.334290,708.593140 911.098022,707.922729 
	C918.661560,707.344116 925.144897,709.741699 931.205933,713.813843 
	C933.515320,715.365479 935.616028,716.164246 938.607971,715.416016 
	C943.323975,714.236755 945.139709,716.712036 943.791077,721.290710 
	C942.524292,725.591187 946.161072,724.498352 948.692566,724.968079 
	C949.263672,729.271973 945.119141,732.017273 944.892944,736.594666 
	C941.066223,738.304504 940.210754,741.941406 938.598633,744.911621 
	C936.769287,748.281860 935.113586,749.708557 930.777710,747.741394 
	C920.851440,743.237854 911.184998,738.408691 901.794067,732.827759 
	C896.775574,729.845337 890.949280,728.016479 885.353516,725.118042 
	C884.616394,724.555847 884.160156,724.198608 883.285217,724.016479 
	C880.468628,722.949646 878.354431,720.814331 874.910767,721.193909 
	C874.493958,721.276123 874.221741,721.270386 874.121094,721.178711 
	C872.482605,719.202332 870.106812,718.073914 869.068237,715.348145 
z"/>
<path fill="#08748E" opacity="1.000000" stroke="none" 
	d="
M935.929932,619.926270 
	C937.778931,620.031494 938.209167,618.197632 939.377991,617.370422 
	C940.877686,616.309082 942.256348,614.160828 943.616516,617.678833 
	C944.240356,619.292419 946.047729,619.031372 947.584595,618.656616 
	C951.788757,617.631470 955.811646,616.110413 959.651794,614.145020 
	C962.358704,612.759705 964.986145,612.779846 967.816895,613.757507 
	C970.002991,614.512634 970.952942,615.384827 970.638306,618.047119 
	C970.206604,621.698608 971.301453,625.099976 975.637634,625.916504 
	C977.634216,626.292542 978.507263,627.517029 978.880005,629.199707 
	C979.459167,631.814941 981.170105,633.803955 983.084534,635.197083 
	C986.238037,637.491821 986.473083,639.016357 982.785461,640.643066 
	C982.190979,640.905334 981.586243,641.144470 980.442444,641.620728 
	C985.145203,644.253784 988.156799,642.349060 991.625610,641.018127 
	C990.651184,645.331360 987.785095,648.751038 985.084839,652.651489 
	C981.915649,657.577332 980.599731,657.989441 976.513733,654.279175 
	C972.007874,650.187866 967.155334,647.577820 961.121338,646.035522 
	C956.340515,644.813477 952.144531,641.953369 946.690918,642.356873 
	C943.169250,642.617432 939.893372,642.792969 936.688782,644.428833 
	C932.465393,646.584656 928.046204,647.786499 923.119629,645.196899 
	C923.009705,643.783447 923.200867,642.618835 923.641846,641.200867 
	C924.189209,640.758545 924.451416,640.529236 924.777039,640.015991 
	C924.874268,638.131104 924.888794,636.494934 926.613098,635.131958 
	C931.876404,631.409790 932.993103,625.549133 935.750977,620.263245 
	C935.930359,619.955933 935.941223,619.939209 935.929932,619.926270 
z"/>
<path fill="#097C99" opacity="1.000000" stroke="none" 
	d="
M922.789124,645.126587 
	C928.588074,645.534424 934.060974,644.816101 939.029419,641.515259 
	C940.715027,640.395386 942.778015,640.800415 944.620178,640.499695 
	C952.132385,639.273438 957.975891,644.165527 964.686768,645.139954 
	C970.591858,645.997314 973.902527,649.844360 977.565247,653.299561 
	C980.328125,655.905884 982.128113,655.976257 984.718628,653.144043 
	C984.620422,657.689270 983.076050,662.071716 980.397888,665.859619 
	C977.151489,670.451355 975.775696,675.978638 972.295776,680.771118 
	C971.872986,681.138916 971.695862,681.268799 971.258240,681.196899 
	C970.242432,678.887573 970.775940,676.115173 966.657288,676.548157 
	C964.160522,676.810608 964.953491,673.903259 965.551636,672.272888 
	C968.370972,664.587952 966.769592,662.389160 958.535767,663.000488 
	C955.110840,663.254700 952.317200,662.169983 950.099731,659.589539 
	C948.107666,657.271484 946.127319,656.108276 943.491455,658.875916 
	C941.538574,660.926514 938.668762,659.531189 936.331421,660.374573 
	C930.840393,662.355896 925.141113,663.555603 919.425964,664.668518 
	C917.303955,665.081726 915.127197,665.603027 913.194275,663.329346 
	C912.618774,662.602295 912.465881,662.122070 912.743774,661.230530 
	C913.132202,660.369629 913.317932,659.847351 913.722900,659.149353 
	C914.800476,658.068909 916.156921,657.650452 916.665100,655.998291 
	C917.002930,654.606812 917.230713,653.513672 917.710693,652.196411 
	C920.457153,650.866516 921.093384,648.218750 922.723816,645.997437 
	C922.802856,645.664490 922.759644,645.305176 922.789124,645.126587 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M970.749207,681.227173 
	C971.172974,680.997620 971.348145,681.000183 971.786743,681.005859 
	C971.526978,684.142395 971.457214,687.520569 967.414917,688.995850 
	C967.190552,686.056641 969.776611,684.179749 970.749207,681.227173 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M964.301880,697.877197 
	C963.968323,696.592590 963.910522,695.073669 964.851807,693.339844 
	C965.177551,692.995300 965.352600,692.993103 965.790405,692.973877 
	C966.180664,694.715820 965.616150,696.255310 964.301880,697.877197 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M966.294067,690.916626 
	C966.232544,690.544495 966.392822,689.971375 966.798828,689.220581 
	C966.868347,689.600464 966.692139,690.158081 966.294067,690.916626 
z"/>
<path fill="#048CA9" opacity="1.000000" stroke="none" 
	d="
M955.314087,713.029358 
	C955.285339,712.881531 955.460999,712.583740 955.879822,712.179077 
	C955.921509,712.341248 955.720032,712.610352 955.314087,713.029358 
z"/>
<path fill="#05657D" opacity="1.000000" stroke="none" 
	d="
M1019.122070,588.692505 
	C1014.830261,589.287781 1012.157410,590.927307 1011.828613,595.586243 
	C1011.524963,599.888794 1007.965332,602.813782 1006.404785,606.647278 
	C1005.841797,608.030273 1004.275024,607.936096 1004.140625,606.168518 
	C1003.905640,603.077637 1001.884338,600.617065 1001.451660,597.675598 
	C1001.198303,595.953613 998.903625,592.164062 1004.173218,594.560608 
	C1006.086426,595.430603 1006.678711,592.866943 1006.855103,591.268250 
	C1007.050171,589.499756 1005.958862,588.835022 1004.323120,589.407898 
	C1002.531067,590.035461 1001.388245,589.220520 1000.618652,587.809082 
	C999.755066,586.225220 1001.221741,585.435059 1002.038818,584.468872 
	C1003.302917,582.973816 1005.288879,581.526123 1004.352478,579.390381 
	C1003.497986,577.441406 1001.225952,577.307922 999.212769,576.957764 
	C994.593811,576.154541 989.751648,576.765259 987.293518,570.495239 
	C985.461792,565.822754 972.976929,566.047363 967.662598,570.110413 
	C966.372559,571.096741 965.518860,573.156921 963.953796,570.059082 
	C964.663513,566.150513 965.776794,562.729614 967.912231,559.335449 
	C968.818237,558.328491 969.182800,557.369385 969.778809,556.197144 
	C970.439148,555.061218 970.932068,554.179688 971.660339,553.107910 
	C974.840942,550.915894 975.727478,547.758789 976.762451,544.283264 
	C976.949829,543.986755 976.966858,543.972168 976.965027,543.957275 
	C979.914734,544.197266 982.112305,543.926453 983.594116,540.447144 
	C985.380188,536.253662 988.683289,536.096741 992.533997,539.067383 
	C998.373108,543.571899 1004.435425,547.442871 1011.811157,549.338257 
	C1015.966125,550.406006 1018.369202,554.801941 1021.073242,557.975037 
	C1024.123169,561.554138 1026.197388,565.370117 1031.625488,561.013550 
	C1031.482788,565.215454 1027.331543,567.913696 1027.140625,572.663208 
	C1026.284912,572.983948 1025.841064,570.836060 1024.916138,572.758789 
	C1023.043884,576.650696 1020.296265,580.144226 1019.772949,584.771179 
	C1019.756104,586.121216 1019.514709,587.248230 1019.122070,588.692505 
z"/>
<path fill="#08DEF9" opacity="1.000000" stroke="none" 
	d="
M868.646912,394.957703 
	C871.143616,394.822754 872.338684,393.427795 873.124939,391.721924 
	C876.406677,384.602295 882.566956,379.993683 887.868774,374.631836 
	C890.428223,372.043396 892.600403,373.008240 893.668518,375.803833 
	C894.755920,378.650024 896.646973,380.077942 899.294617,380.935242 
	C901.677612,381.706879 903.107300,383.342896 904.091248,385.631622 
	C905.382751,388.635620 907.685181,391.606934 910.635620,392.076904 
	C918.299255,393.297729 922.905762,398.929779 928.513733,403.114532 
	C931.383972,405.256378 934.227905,406.929108 937.951599,407.103210 
	C940.620728,407.227997 942.984314,408.389954 945.000366,410.406616 
	C947.804993,413.212189 951.108337,415.143280 954.918701,416.691803 
	C960.699402,419.041168 966.572754,421.477997 971.028870,426.384674 
	C975.100281,430.867706 981.244080,431.715302 986.554504,433.987579 
	C991.166809,435.961212 995.950012,437.647369 997.986572,442.896973 
	C998.780029,444.942200 1000.812744,445.328217 1002.474426,445.667816 
	C1011.394043,447.490906 1015.909668,453.821381 1017.934998,461.763275 
	C1020.720947,472.687988 1027.284668,482.384186 1028.100220,493.905945 
	C1028.151855,494.634705 1028.715332,495.419067 1029.236938,496.002014 
	C1030.103394,496.970367 1031.705444,497.924744 1030.348633,499.221069 
	C1029.184570,500.333405 1027.607422,499.124939 1026.961060,498.160187 
	C1023.322266,492.729095 1017.007019,491.872894 1011.822144,489.171570 
	C1004.771606,485.498322 997.700012,482.098022 991.215332,477.255310 
	C987.465881,474.455292 983.604248,469.994049 976.960693,471.026306 
	C972.603210,468.370880 968.579773,466.367584 965.452271,463.096100 
	C963.182861,460.722229 959.722656,460.877838 956.903137,459.559692 
	C952.606140,457.550842 949.482239,453.385986 944.116211,452.734802 
	C939.089539,449.177429 933.935608,446.537567 928.994019,443.550354 
	C920.242676,438.260071 910.925354,434.052917 902.129700,428.815674 
	C895.416260,424.818268 888.657288,420.578918 880.900269,417.592407 
	C879.359802,416.326752 878.491699,414.942169 876.810974,414.331268 
	C869.414368,411.643036 867.420349,405.390839 866.599365,398.030518 
	C866.762207,396.479004 867.160706,395.485352 868.646912,394.957703 
z"/>
<path fill="#101925" opacity="1.000000" stroke="none" 
	d="
M984.917297,477.876709 
	C984.402405,482.124054 985.967224,485.754456 987.936096,489.639038 
	C984.443787,491.774628 981.489807,489.587891 978.623535,488.263702 
	C975.492310,486.817047 972.334839,485.173401 969.899231,482.552582 
	C969.210754,481.811707 968.427917,480.910309 967.373901,481.219025 
	C959.227844,483.604797 952.618469,478.383484 945.382568,476.576874 
	C939.477661,475.102600 933.362793,473.974274 927.769653,471.222412 
	C925.027771,469.873383 924.138489,471.132996 924.921631,473.960815 
	C925.474609,475.957184 925.287170,477.930298 923.708069,479.920624 
	C921.008606,481.319366 920.187988,479.496979 919.305603,477.879608 
	C915.428467,470.772797 909.423035,466.121613 902.506714,462.084320 
	C882.834473,450.600922 862.217957,440.885559 842.505920,429.460480 
	C829.874512,422.139343 816.877197,415.393738 803.930969,408.602051 
	C801.773254,407.470093 799.472900,406.049774 796.751099,408.395447 
	C795.637573,409.216309 794.723267,409.579895 793.344849,409.652222 
	C791.290771,409.029053 791.251526,407.773499 791.764038,406.387054 
	C793.139099,402.666595 791.376770,399.909698 788.378418,398.490814 
	C779.597656,394.335602 771.858765,388.247437 762.685852,384.850800 
	C760.370483,383.993500 758.203979,383.894836 755.957947,385.074371 
	C753.292236,386.474335 750.800720,386.313049 748.854919,383.738007 
	C746.979065,381.255554 745.903137,382.103790 744.584290,384.397308 
	C741.262390,390.174255 735.151978,393.711304 732.042053,399.723541 
	C731.603271,400.571808 730.386780,400.769714 729.007080,400.303162 
	C727.381714,398.123291 728.116394,396.184509 729.125854,394.470001 
	C735.792419,383.147064 741.814575,371.466248 748.334717,360.063263 
	C749.003296,358.894012 749.900024,357.914307 751.444336,357.091003 
	C754.979187,356.116516 757.348450,357.027252 758.718201,360.154083 
	C759.798828,362.621124 761.796448,364.121582 764.305420,364.776886 
	C768.046448,365.753998 770.877258,367.879730 773.085327,370.972992 
	C773.567871,371.648865 774.104126,372.420410 774.842957,372.723572 
	C783.629150,376.328705 790.743469,383.018036 799.719604,386.225555 
	C802.306641,387.150055 804.465210,388.469727 806.374878,390.436493 
	C813.226013,397.492279 821.173279,402.493927 830.839966,405.210358 
	C838.445862,407.347748 845.449158,411.200653 850.451843,417.839111 
	C852.430481,420.464630 855.508667,420.969666 858.553833,420.982697 
	C863.658569,421.004547 867.887817,423.117188 871.784302,426.126678 
	C873.100464,427.143250 874.228210,428.501923 875.799988,429.063019 
	C884.394409,432.131165 891.386597,437.910553 899.005371,442.627747 
	C899.713440,443.066132 900.396790,443.866486 901.133057,443.888794 
	C911.828613,444.212555 919.112793,453.128387 929.473938,454.839661 
	C936.541321,456.006927 943.045288,460.049255 949.145935,464.015076 
	C952.744995,466.354706 956.732361,467.338104 960.595459,468.802887 
	C961.924500,469.306824 963.204895,468.607727 964.759155,467.940491 
	C965.502380,467.496429 965.963379,467.324982 966.812622,467.189301 
	C967.415588,467.189545 967.654114,467.218475 968.231628,467.355988 
	C971.126038,469.314209 974.397583,470.032410 976.809326,472.733734 
	C977.518738,473.239227 977.970642,473.527130 978.678162,473.898560 
	C980.422974,473.901520 981.623962,474.439972 982.668579,475.955322 
	C983.256836,476.551422 983.689575,476.840485 984.366760,477.223511 
	C984.847046,477.465454 985.082886,477.613464 984.917297,477.876709 
z"/>
<path fill="#06A6C2" opacity="1.000000" stroke="none" 
	d="
M800.877441,374.616150 
	C800.849365,373.441498 800.893494,372.654755 800.910278,371.473511 
	C800.412476,370.140717 800.305786,369.132660 800.650757,368.170715 
	C805.811401,353.780029 810.407410,339.187042 816.111877,324.991669 
	C816.298706,324.526764 816.740234,324.231201 817.551025,323.963623 
	C819.599182,324.228424 821.518433,326.967560 822.639282,323.035889 
	C823.361694,320.502075 824.938477,321.248718 826.057495,323.115906 
	C827.078979,324.820526 828.078003,326.539612 829.138611,328.219696 
	C832.508423,333.557831 837.427185,336.319763 843.644409,337.022522 
	C847.844116,337.497284 851.223572,340.625000 855.686462,340.662231 
	C857.227417,340.675079 858.107239,342.820892 857.969055,344.643036 
	C857.599487,349.515625 859.069946,352.888184 864.275757,354.411377 
	C866.764465,355.139526 868.414124,357.621460 869.534790,360.131134 
	C873.015259,367.925446 874.952881,368.902466 883.287903,367.153473 
	C883.929016,367.018982 884.608765,367.068481 885.701477,367.056732 
	C885.766418,369.500671 884.152100,371.269653 882.872803,373.192078 
	C878.378479,379.945679 873.822815,386.658386 869.137024,393.690186 
	C867.453613,395.168091 865.724670,395.382935 864.532593,393.844177 
	C862.247925,390.895294 859.124451,388.927216 856.479919,386.411316 
	C854.413452,384.445404 853.594849,381.985962 853.416687,379.250122 
	C853.224243,376.294189 851.746399,374.231506 849.091736,373.052582 
	C846.348755,371.834412 843.878113,369.937225 841.044861,369.088959 
	C835.884827,367.544067 831.307495,365.071747 826.730286,362.278412 
	C820.479370,358.463623 810.578308,361.270081 807.475159,366.824890 
	C805.758850,369.897369 806.946045,372.551086 807.397278,375.739136 
	C805.841675,377.337677 804.326782,376.577545 802.467346,376.190277 
	C801.624084,375.805756 801.242126,375.453461 800.877441,374.616150 
z"/>
<path fill="#04AFD0" opacity="1.000000" stroke="none" 
	d="
M806.797607,376.758423 
	C806.756531,375.880066 806.938782,375.108704 806.693481,374.932404 
	C801.931396,371.510254 805.151062,368.067017 806.808228,364.861267 
	C808.835938,360.938751 811.806274,359.629578 816.746643,359.466339 
	C830.289001,359.018860 839.598328,368.811127 851.389648,372.368195 
	C853.740906,373.077484 855.343933,375.432404 854.984924,378.324188 
	C854.521973,382.053223 855.965149,384.909546 859.174500,386.608734 
	C862.715515,388.483459 864.100586,392.893555 868.598083,393.991577 
	C868.995483,394.165894 869.007324,394.338013 869.039795,394.768463 
	C868.232483,395.547607 868.015564,396.367004 867.953613,397.596283 
	C866.320557,400.068634 865.433167,402.867310 862.235474,404.044861 
	C859.013306,404.754547 856.491699,403.440857 853.503601,402.640503 
	C852.414124,402.482391 851.682617,402.412689 850.628418,402.099915 
	C850.073486,401.869934 849.860718,401.759949 849.361938,401.441895 
	C848.908569,401.073792 848.741211,400.913757 848.355164,400.491394 
	C847.604370,399.244080 846.924377,398.433929 845.431885,398.093323 
	C839.597473,395.525360 834.320618,392.610626 828.808411,389.430298 
	C827.910034,388.644897 827.476746,387.837799 826.283691,387.470734 
	C825.094055,387.150269 824.300171,386.756500 823.381958,385.909180 
	C822.133667,382.852692 819.979614,382.132782 816.898315,382.445740 
	C812.954407,381.305695 809.122742,380.627563 806.797607,376.758423 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M977.060913,472.654175 
	C973.858398,472.167938 971.140381,470.453644 968.435425,468.174591 
	C971.883789,466.021240 973.815247,469.209808 976.626709,470.779999 
	C976.889221,471.055389 976.875671,471.119598 976.881592,471.153076 
	C976.943298,471.576721 976.999146,471.966888 977.060913,472.654175 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M982.830444,475.920715 
	C981.650574,475.686707 980.477051,475.111023 979.118713,474.258728 
	C981.067993,472.478119 982.091553,473.688568 982.830444,475.920715 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M988.897095,479.664978 
	C988.076660,480.024750 987.416992,479.613037 986.762390,478.659546 
	C987.603455,478.159149 988.357666,478.429169 988.897095,479.664978 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M1022.152100,499.639587 
	C1021.956238,499.747986 1021.618591,499.616241 1021.140259,499.244385 
	C1021.336487,499.136047 1021.673340,499.267761 1022.152100,499.639587 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M984.889404,478.032715 
	C984.515808,477.991974 984.516663,477.647919 984.563904,477.482727 
	C984.971680,477.405457 985.332275,477.493408 985.853760,477.823578 
	C985.764221,478.068329 985.513611,478.070892 984.889404,478.032715 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M1020.302246,498.538696 
	C1020.113831,498.692902 1019.748474,498.608032 1019.197266,498.305725 
	C1019.382690,498.158722 1019.754028,498.229187 1020.302246,498.538696 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M1024.001709,500.706543 
	C1023.768372,500.937683 1023.485535,500.823029 1023.125854,500.393005 
	C1023.356873,500.115143 1023.657898,500.209503 1024.001709,500.706543 
z"/>
<path fill="#03718B" opacity="1.000000" stroke="none" 
	d="
M817.959351,323.603821 
	C813.440979,337.047150 808.967285,350.128876 804.401611,363.178406 
	C803.533813,365.658600 802.301880,368.011383 801.060425,370.750977 
	C797.973633,371.594818 795.698486,369.780243 793.504456,368.558228 
	C784.568604,363.581421 775.756531,358.367981 767.058350,352.984100 
	C762.004028,349.855682 761.908508,347.608795 764.995422,342.342102 
	C771.750488,330.816803 778.345398,319.197113 784.921326,307.568207 
	C785.507080,306.532349 786.425903,305.775574 786.935059,304.848511 
	C789.653320,299.898407 791.427124,298.429504 796.399902,303.709259 
	C800.279968,307.828888 806.866760,309.544830 812.447998,311.851501 
	C814.998840,312.905762 817.029480,315.359528 820.531799,314.992188 
	C820.698120,315.485535 820.485535,315.970795 820.177002,316.702698 
	C819.378052,319.043274 818.675110,321.137177 817.959351,323.603821 
z"/>
<path fill="#060F14" opacity="1.000000" stroke="none" 
	d="
M800.443481,374.196106 
	C800.964844,374.503204 801.171082,374.737122 801.621704,375.056854 
	C817.529053,382.975281 832.829163,391.527679 848.401794,400.303955 
	C851.263062,402.833130 854.933289,403.078552 857.905640,405.358612 
	C858.396790,405.593384 858.597107,405.676514 859.086548,405.901917 
	C859.564880,406.141663 859.754211,406.239105 860.227844,406.480591 
	C860.703125,406.718781 860.894165,406.812958 861.373413,407.047119 
	C862.230469,407.504364 862.836426,407.744202 863.720825,408.183411 
	C868.007141,411.023499 872.085327,413.550140 876.813354,415.622772 
	C877.351746,415.818359 877.560242,415.912903 878.059692,416.186218 
	C880.072937,418.151154 882.307251,419.111755 884.764221,420.320312 
	C885.241455,420.554871 885.436768,420.637085 885.916382,420.854004 
	C886.576843,421.168488 886.955200,421.343170 887.630127,421.621277 
	C888.316589,421.882507 888.702087,422.050873 889.361694,422.385925 
	C890.556335,423.079376 891.268494,423.884033 892.432617,424.605469 
	C892.894226,424.848206 893.073547,424.953979 893.513062,425.229736 
	C894.445740,425.855103 895.045227,426.432983 896.157288,426.746979 
	C896.643372,426.933380 896.835144,427.017303 897.306030,427.248383 
	C898.778381,428.266632 900.041504,429.019440 901.678345,429.778229 
	C902.167908,430.023529 902.361389,430.131378 902.833984,430.426331 
	C905.696106,432.577820 908.729675,433.713226 911.857605,435.356873 
	C912.342407,435.590607 912.537354,435.675629 913.010742,435.901489 
	C913.469727,436.137115 913.650269,436.231873 914.098999,436.464508 
	C914.545959,436.689514 914.724792,436.776672 915.174561,436.983765 
	C915.815918,437.250244 916.189453,437.388306 916.861633,437.601471 
	C917.966431,437.931915 918.731689,438.275757 919.717651,438.908813 
	C921.200745,440.016602 922.350159,441.039398 924.116394,441.727661 
	C924.636902,441.952423 924.839783,442.058289 925.329224,442.357971 
	C929.617371,445.771576 934.018799,448.318451 939.043945,450.469666 
	C939.563354,450.681305 939.769836,450.765076 940.272583,450.995392 
	C941.707764,451.742950 942.783875,452.465118 944.308105,453.010986 
	C944.814026,453.191376 945.010071,453.281342 945.474609,453.551086 
	C946.530884,454.451233 947.351318,455.126526 948.615356,455.738647 
	C949.102722,455.988861 949.294373,456.098083 949.761841,456.393097 
	C951.396179,457.760376 953.044250,458.446899 954.906250,459.401764 
	C955.385132,459.631409 955.576660,459.718445 956.044067,459.946655 
	C956.499695,460.182434 956.679626,460.276978 957.127319,460.509827 
	C957.574097,460.737061 957.752930,460.825989 958.202148,461.041016 
	C958.839661,461.330902 959.209167,461.488892 959.869873,461.752014 
	C960.546021,462.029602 960.920593,462.221924 961.555908,462.614227 
	C963.208557,464.188660 965.279175,464.874756 966.097900,467.229614 
	C965.777283,470.014679 963.865540,471.337128 961.911682,470.831543 
	C957.338257,469.648010 952.530823,468.662079 948.645874,465.817871 
	C944.539124,462.811279 939.698425,461.366455 935.410522,458.878082 
	C929.766479,455.602722 922.744324,456.167328 917.575684,452.558899 
	C912.795837,449.221985 908.298523,446.933197 902.305115,446.200165 
	C898.304688,445.710846 894.437805,442.157990 891.030579,439.346649 
	C887.641724,436.550476 884.127380,434.153900 879.951599,433.030243 
	C874.480103,431.557892 871.008301,427.084442 866.328430,424.404327 
	C862.242981,422.064575 857.928223,423.463501 853.913879,422.052307 
	C851.139221,421.076904 848.496948,420.256653 847.969604,416.851593 
	C847.809937,415.820770 847.475281,414.689789 846.438782,414.466858 
	C839.427856,412.959076 834.929138,406.545013 827.447266,405.489594 
	C817.984741,404.154724 811.252686,397.552490 804.709656,391.095490 
	C804.007324,390.402435 803.443665,389.132751 802.674683,389.009399 
	C792.482544,387.374298 785.400330,379.606781 776.496948,375.370758 
	C770.294922,372.419983 764.955566,366.801300 758.546448,363.211151 
	C756.342651,361.976715 756.884949,356.852234 751.814209,357.911255 
	C750.752319,356.974823 750.698059,355.959015 750.852905,354.534363 
	C751.159851,353.127167 751.731262,352.291687 752.689941,351.250366 
	C753.531433,350.157776 754.395508,350.014557 755.645874,349.990784 
	C756.404846,349.992676 756.828003,349.999237 757.567139,350.005798 
	C763.743530,354.940735 770.670288,358.173950 777.240417,361.945435 
	C784.725586,366.242249 792.457275,370.109833 800.443481,374.196106 
z"/>
<path fill="#1B273C" opacity="1.000000" stroke="none" 
	d="
M988.690674,499.753845 
	C993.070557,505.956970 989.441040,511.721436 987.243530,516.532166 
	C984.888855,521.686646 983.308899,527.346191 979.884338,532.053589 
	C978.333374,534.185669 977.438171,536.891602 975.620850,538.714355 
	C970.862122,543.487244 968.632202,549.817505 965.711548,555.505615 
	C962.970764,560.843445 960.616272,566.435791 957.185303,571.810669 
	C954.942078,572.193481 954.393494,570.672668 953.885559,569.318359 
	C949.831299,558.508301 945.470581,547.808167 942.063965,536.393677 
	C942.234192,533.868774 942.561279,531.669312 940.240112,529.866699 
	C939.211975,528.776428 938.908630,527.726624 938.808960,526.272095 
	C938.777771,525.137329 938.812012,524.315735 938.330322,523.269043 
	C938.044922,522.609375 937.908142,522.221130 937.708984,521.527466 
	C937.503235,520.398865 937.240723,519.641235 936.296875,518.890991 
	C935.639038,518.142700 935.357300,517.551880 935.168213,516.563843 
	C935.457031,513.689697 935.016113,511.440430 932.472717,509.653931 
	C931.417786,508.480530 931.009277,507.392273 930.954651,505.812317 
	C931.465698,503.598785 931.729919,501.810608 929.333008,500.449463 
	C928.461426,499.305756 928.172729,498.345795 928.400879,496.888977 
	C929.241455,495.533569 929.268799,494.405975 929.429016,493.243256 
	C930.431335,485.967712 936.813171,481.485291 943.471130,484.275452 
	C955.377686,489.265228 968.389893,491.116913 979.799561,497.409210 
	C982.459778,498.876282 985.809753,497.596588 988.690674,499.753845 
z"/>
<path fill="#060D0E" opacity="1.000000" stroke="none" 
	d="
M935.963989,515.646729 
	C936.179993,516.545959 936.348511,517.097534 936.747742,517.831421 
	C937.875366,518.649963 937.954590,519.783752 938.750427,520.836548 
	C938.954407,521.501526 938.961487,521.931335 938.986755,522.682983 
	C939.443359,523.395630 939.791382,523.845520 939.955627,524.716797 
	C940.157043,526.171021 940.339111,527.336792 940.777710,528.747314 
	C942.136658,530.586304 942.773254,532.354004 943.014954,534.631836 
	C947.480713,547.167664 951.932373,559.345642 956.645508,571.767700 
	C957.066040,576.042603 955.974915,579.524292 952.173584,582.041504 
	C949.530273,578.745972 948.653381,574.791016 947.558350,571.010803 
	C939.538025,543.322571 928.596924,516.671143 918.864441,489.592255 
	C917.171204,484.881348 914.541382,480.586823 912.396423,476.074646 
	C910.393555,471.861450 906.398682,469.996765 902.520325,468.033112 
	C884.132690,458.723389 866.175720,448.615051 848.131653,438.669159 
	C844.802063,436.833862 841.800415,434.381134 837.664001,433.315033 
	C836.618469,432.967438 835.989563,432.603638 835.086914,432.017212 
	C833.224731,430.221741 831.406311,429.028656 828.877258,428.413208 
	C828.105957,428.250336 827.680786,428.099365 826.966675,427.750366 
	C824.334656,425.800568 822.042542,423.947968 818.799805,423.017792 
	C811.806396,419.652954 805.416748,415.923706 799.099121,412.071442 
	C797.715820,411.227936 796.227722,410.388916 795.894287,408.282349 
	C797.121643,403.253571 800.330750,405.052582 802.671997,406.149567 
	C808.221130,408.749664 813.627502,411.673187 818.992310,414.645111 
	C840.298035,426.447601 861.371643,438.656067 883.260559,449.408813 
	C892.891174,454.139801 902.116211,459.817719 911.077332,465.743225 
	C916.124390,469.080536 919.782410,474.169250 922.629211,479.944397 
	C927.485779,484.496582 927.818176,490.557159 929.074707,496.617249 
	C929.245911,497.805389 929.495972,498.611298 929.885193,499.705933 
	C931.202942,501.193909 931.931519,502.597107 931.984924,504.655609 
	C932.231812,506.123901 932.473206,507.245361 932.860962,508.683228 
	C934.700012,510.765625 934.911194,513.215698 935.963989,515.646729 
z"/>
<path fill="#162034" opacity="1.000000" stroke="none" 
	d="
M929.362610,496.902161 
	C926.098999,491.919403 924.934326,486.232361 923.129700,480.372986 
	C923.941650,477.289093 924.308044,474.618164 922.565918,471.963074 
	C921.935181,471.001862 921.693726,469.728699 922.607178,468.718628 
	C923.430542,467.808167 924.450256,468.280670 925.358276,468.652679 
	C936.941895,473.398560 949.478027,475.230835 960.956848,480.295258 
	C961.406494,480.493591 962.056274,480.946930 962.304260,480.790955 
	C970.227051,475.808411 973.024658,484.841492 978.436890,486.668915 
	C981.399902,487.669373 984.313965,488.814636 987.616516,489.947266 
	C988.145569,493.081390 987.596191,496.232910 988.945435,499.608337 
	C982.621277,501.502563 977.723083,497.507141 972.191284,495.657562 
	C962.190674,492.313751 952.068115,489.386169 942.272095,485.390045 
	C937.300964,483.362183 931.645691,487.487152 931.006592,492.817383 
	C930.837524,494.227631 931.487000,495.902618 929.362610,496.902161 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M836.759521,432.205322 
	C839.240540,432.039520 841.317810,432.699188 843.155029,433.840179 
	C857.522278,442.762787 872.704590,450.187988 887.531494,458.267273 
	C891.866455,460.629425 895.879944,463.759705 900.603882,465.227325 
	C910.993835,468.455353 915.592163,476.779724 918.993164,485.885468 
	C924.248596,499.956055 929.248718,514.160339 934.647461,528.139832 
	C938.816406,538.934937 942.018372,550.032288 946.125793,560.827698 
	C948.620483,567.384338 951.052734,574.157654 951.870667,581.688843 
	C950.713135,584.275085 949.610901,586.440186 948.243286,588.822144 
	C947.547913,589.397949 947.054871,589.543213 946.137817,589.302246 
	C945.453308,588.694702 945.253540,588.209961 945.182495,587.280518 
	C946.961426,583.395813 946.663513,579.891479 945.521790,576.257385 
	C942.303955,566.014465 937.874939,556.188416 934.742493,545.947693 
	C930.975098,533.631348 925.211853,522.087341 921.540161,509.751495 
	C918.937073,501.005859 914.947632,492.750854 911.703735,484.230377 
	C908.627808,476.151154 900.621521,472.692017 893.618958,468.508209 
	C889.511475,466.054077 885.027771,464.266174 880.923218,461.118103 
	C879.880859,459.085846 878.752930,457.836609 876.237061,458.168518 
	C874.986694,457.950958 874.164185,457.566681 873.115234,456.889465 
	C871.903076,455.940552 871.502258,454.491730 869.671753,454.430145 
	C868.476379,454.248230 867.666443,453.933472 866.680298,453.215881 
	C865.499390,451.807770 864.543945,450.647705 862.606567,450.265228 
	C860.284790,449.502533 858.369934,448.669983 856.462646,447.077026 
	C851.819641,442.644470 846.725952,439.590851 840.712402,437.375183 
	C839.028442,437.070007 837.865723,436.510712 836.658569,435.244751 
	C836.119629,434.101654 836.136475,433.301727 836.759521,432.205322 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M935.381104,609.982666 
	C935.167419,615.743713 932.110779,620.357117 929.360779,624.309692 
	C922.687012,633.901855 918.985779,644.991150 912.387329,654.863403 
	C910.050354,653.059692 910.196655,653.113770 911.348083,649.998657 
	C914.003723,642.813171 918.069336,636.339111 921.525818,629.564758 
	C927.461365,617.931458 932.619080,606.022827 936.030640,592.987427 
	C938.602295,587.196350 938.231628,581.482361 936.650513,576.123901 
	C935.064514,570.749084 932.913452,565.488403 930.600098,559.931946 
	C930.263733,559.385559 930.147888,559.156433 929.906799,558.564941 
	C929.826599,555.496521 928.821777,553.071472 927.619751,550.748230 
	C924.842590,545.381165 923.622192,539.380920 920.542847,533.749756 
	C920.119080,532.998962 919.940125,532.542725 919.728516,531.717346 
	C919.621338,528.992432 919.211365,526.736267 917.447876,524.535339 
	C917.138000,524.020447 917.029053,523.804138 916.804626,523.242188 
	C913.016907,511.604401 909.400513,500.289215 902.838562,489.826141 
	C902.302063,489.169983 902.063049,488.752563 901.748779,487.979492 
	C900.845886,483.986084 898.265503,481.986450 894.792053,480.333649 
	C893.094360,477.291595 890.333557,476.082947 887.885437,474.445740 
	C885.390686,472.777344 882.001587,471.719879 882.444275,467.367676 
	C885.097107,465.171326 886.518250,467.624390 888.661377,468.582794 
	C889.785400,469.073822 890.559692,469.501007 891.631836,470.038086 
	C892.609924,470.610504 893.144531,471.249176 894.106812,471.808472 
	C894.902100,472.201904 895.408997,472.523071 896.309692,472.692749 
	C897.017578,472.890442 897.406250,473.057648 898.051880,473.442169 
	C898.474426,473.786713 898.626770,473.945465 898.968384,474.374695 
	C899.584717,476.113922 900.754822,476.614166 902.400635,477.048157 
	C902.893982,477.339203 903.078674,477.478729 903.481995,477.890808 
	C904.054688,478.776581 904.265625,479.441864 904.461304,480.473267 
	C906.264832,484.597168 909.516235,487.414154 911.404480,491.516602 
	C912.447510,493.112885 912.168396,494.882965 913.519165,496.348755 
	C914.289368,497.623352 914.745361,498.694611 915.751404,499.856201 
	C916.043640,500.402924 916.141846,500.634735 916.325989,501.239075 
	C919.638672,512.163940 923.430237,522.510559 927.414368,532.801208 
	C930.155396,539.881104 931.972961,547.302368 935.251831,554.202271 
	C938.278992,560.572632 940.103516,567.408142 942.413452,574.042053 
	C944.142517,579.007874 944.587524,584.383484 944.458374,590.095947 
	C944.433350,591.206848 944.371399,591.932800 944.066528,592.962402 
	C943.613342,594.580933 942.323669,595.162048 941.615967,596.531982 
	C941.442627,597.378784 941.310791,597.936035 941.083252,598.759521 
	C941.030945,599.035950 940.995117,599.488220 940.983765,599.714722 
	C940.331360,600.744080 940.117798,601.793152 939.255737,602.816284 
	C939.059814,603.051086 939.005676,603.026428 938.986694,603.003479 
	C937.336426,604.960632 936.745300,607.478455 935.381104,609.982666 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M920.762024,530.738770 
	C921.011047,531.434692 921.022156,531.869385 921.033813,532.630188 
	C923.946106,541.205139 927.445618,549.226074 930.762573,557.703857 
	C931.008240,557.994812 931.034912,558.471191 931.039124,558.709778 
	C933.182739,565.171448 935.755615,571.287598 937.567871,577.562073 
	C938.902405,582.182373 938.433350,587.403015 937.291016,592.696167 
	C934.197876,602.258179 932.037109,611.535339 927.618652,620.387817 
	C922.009521,631.625977 914.842957,642.244568 911.842285,654.824585 
	C911.935913,655.474731 911.782349,655.915222 911.471069,656.668396 
	C911.365356,657.162842 911.417358,657.344604 911.186523,657.546143 
	C910.903687,657.565979 910.563660,657.884277 910.369690,658.013184 
	C909.726440,658.433044 909.234436,658.571228 908.333252,658.423828 
	C906.260864,655.291870 907.617065,652.599487 908.760742,649.850159 
	C912.060608,641.917542 916.252197,634.416870 919.921204,626.663696 
	C920.624817,625.176880 921.558105,623.768738 921.650391,621.668091 
	C921.777771,621.061157 921.848572,620.824707 922.080994,620.259338 
	C924.774353,617.979858 924.876099,614.570190 927.020752,611.991150 
	C928.718262,610.233521 928.885010,608.194702 929.487671,605.992798 
	C929.521912,605.192932 929.529358,604.730591 929.525879,603.907837 
	C929.406982,602.553345 929.503540,601.569458 929.733032,600.209961 
	C929.880981,599.585388 929.959106,599.339417 930.200195,598.741943 
	C935.595276,588.242554 932.775513,578.418030 929.015808,568.714050 
	C924.943787,558.204346 920.424805,547.877319 916.844299,537.165710 
	C913.483215,527.110413 909.250000,517.373962 904.893738,507.705231 
	C904.275330,506.332733 903.630737,504.994598 903.489563,503.087708 
	C903.508728,500.706635 902.034485,499.382751 901.194397,497.800262 
	C900.168152,495.867340 899.813416,494.083496 901.467407,491.952118 
	C901.987427,491.589294 902.225830,491.491882 902.849182,491.375122 
	C905.432373,491.823914 905.715393,493.822754 906.449829,495.431702 
	C910.490417,504.283569 913.826599,513.424927 917.751831,522.713257 
	C917.995117,523.002258 918.023010,523.477661 918.030029,523.715698 
	C919.413452,525.919617 919.511902,528.372742 920.762024,530.738770 
z"/>
<path fill="#056C86" opacity="1.000000" stroke="none" 
	d="
M935.629517,620.117615 
	C934.323242,625.583801 933.973694,631.911377 927.258545,634.918945 
	C929.709534,630.054077 932.519104,625.166687 935.629517,620.117615 
z"/>
<path fill="#045B70" opacity="1.000000" stroke="none" 
	d="
M976.653564,544.151855 
	C977.285461,547.813110 977.058228,551.366455 972.264771,552.900635 
	C972.872498,549.786011 974.155334,546.813110 976.653564,544.151855 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M942.799683,594.246399 
	C942.906189,593.194214 942.955627,592.471008 943.018066,591.385498 
	C943.865295,590.307617 944.103943,588.980530 945.700928,588.860107 
	C946.450195,588.999390 946.887085,589.006104 947.651001,589.025940 
	C946.797058,590.898804 945.616089,592.758484 944.156616,594.803467 
	C943.409790,595.097412 943.031311,594.959656 942.799683,594.246399 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M907.738281,657.760437 
	C908.533447,658.027100 909.003174,658.041382 909.824280,658.098877 
	C910.087219,658.542969 909.998779,658.943909 909.832520,659.644043 
	C909.684937,660.487244 909.442322,660.938965 908.670898,661.475525 
	C905.853149,662.118652 904.003357,661.558838 903.579407,658.385376 
	C903.888733,657.798035 904.085999,657.606384 904.662659,657.251221 
	C905.886597,656.921997 906.679016,657.049866 907.738281,657.760437 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M935.564575,610.158936 
	C934.681702,607.240234 936.647522,605.249023 938.624268,603.049011 
	C938.417786,605.536499 938.058655,608.176208 935.564575,610.158936 
z"/>
<path fill="#08748E" opacity="1.000000" stroke="none" 
	d="
M922.513000,645.848328 
	C922.999268,648.781311 921.480469,650.783630 918.334290,651.971069 
	C919.457764,650.045959 919.781067,647.279297 922.513000,645.848328 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M942.534668,593.988281 
	C943.060303,594.182007 943.263733,594.446594 943.672607,594.849976 
	C943.715088,595.523621 943.552063,596.058411 943.167480,596.773193 
	C942.317322,597.569580 941.577332,597.729797 940.403198,597.271851 
	C940.062012,595.538391 940.731812,594.507935 942.534668,593.988281 
z"/>
<path fill="#08748E" opacity="1.000000" stroke="none" 
	d="
M916.460083,655.804932 
	C918.352783,658.249573 917.346130,659.151672 914.308960,658.983398 
	C914.018372,657.479187 914.772278,656.471985 916.460083,655.804932 
z"/>
<path fill="#08748E" opacity="1.000000" stroke="none" 
	d="
M908.759216,661.192017 
	C909.013000,660.605835 909.171570,660.371582 909.581543,660.066711 
	C910.369873,660.810303 911.239563,661.045044 912.595276,660.918579 
	C912.963440,661.362671 912.980286,661.833374 912.998596,662.656982 
	C911.896423,662.712341 910.792908,662.414856 909.347107,662.079102 
	C908.865173,661.857544 908.725525,661.674316 908.759216,661.192017 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M940.081421,597.343689 
	C940.786255,597.027710 941.506226,597.003296 942.586060,596.966064 
	C942.906128,597.863220 942.665100,598.642212 941.346436,598.980347 
	C940.719543,598.569031 940.408081,598.102173 940.081421,597.343689 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M939.394897,602.973511 
	C938.926453,601.842407 939.398560,600.921692 940.682373,600.095154 
	C941.016907,601.119019 941.086731,602.307495 939.394897,602.973511 
z"/>
<path fill="#045B70" opacity="1.000000" stroke="none" 
	d="
M969.667114,556.099548 
	C969.956970,557.107056 970.108948,558.333679 968.321899,558.919678 
	C968.001648,557.842957 968.435242,556.915710 969.667114,556.099548 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M911.070312,657.562744 
	C910.903687,657.565979 911.152283,657.152344 911.232788,657.066711 
	C911.495422,657.201660 911.469910,657.394470 911.070312,657.562744 
z"/>
<path fill="#056C86" opacity="1.000000" stroke="none" 
	d="
M924.667847,639.928711 
	C924.709045,640.083435 924.542236,640.394409 924.133545,640.826416 
	C924.081116,640.659973 924.270447,640.372437 924.667847,639.928711 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M1021.924316,847.382202 
	C1021.786926,843.176025 1020.399353,839.619080 1019.091370,836.037842 
	C1018.445312,834.268982 1017.402466,833.228638 1015.038574,833.671509 
	C1019.224915,830.569336 1022.055969,831.201721 1023.297974,835.872620 
	C1026.330078,847.275391 1029.150269,858.734558 1032.061523,870.578308 
	C1031.458984,871.586487 1030.723145,871.841492 1029.487183,871.689575 
	C1026.617432,869.570740 1026.337402,866.668335 1025.923584,863.413818 
	C1025.843140,862.601501 1025.798706,862.180725 1025.675781,861.451843 
	C1024.989868,859.685181 1023.906006,858.394287 1024.031738,856.337097 
	C1023.678650,854.595703 1023.319275,853.196899 1022.840942,851.455750 
	C1022.473633,850.607971 1022.151794,850.132568 1022.088989,849.270447 
	C1022.060669,848.557617 1022.026123,848.127991 1021.924316,847.382202 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M1025.640137,863.289551 
	C1026.891235,865.438782 1027.894897,867.855225 1028.978027,870.629395 
	C1031.333984,873.999329 1034.450439,873.572754 1037.919312,873.157471 
	C1038.450195,873.364197 1038.647217,873.483826 1039.017090,873.903076 
	C1038.943970,877.260071 1036.784790,877.833740 1034.288452,878.003662 
	C1031.826416,878.171265 1029.896729,877.754272 1028.519897,875.207520 
	C1026.525024,871.518005 1024.925781,867.849854 1025.640137,863.289551 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M1023.787842,856.206543 
	C1025.361816,857.187500 1025.375610,858.887939 1025.679688,860.793945 
	C1023.713196,860.090820 1022.669495,858.671387 1023.787842,856.206543 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M1038.857666,871.501404 
	C1039.777588,871.020508 1040.421753,870.800232 1041.405640,870.558472 
	C1042.097534,872.221313 1041.399048,873.326050 1039.522461,874.083496 
	C1039.189941,874.202698 1039.039673,874.044189 1038.963379,873.966064 
	C1038.374756,873.238220 1038.290527,872.527039 1038.857666,871.501404 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M1080.638672,870.001221 
	C1080.898804,870.870972 1080.613647,871.638123 1079.249023,871.903076 
	C1079.078247,871.072815 1079.415894,870.351196 1080.638672,870.001221 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M1021.870728,849.140625 
	C1022.354614,849.498108 1022.613953,850.008911 1022.797607,850.816650 
	C1021.813049,850.832336 1020.965088,850.515137 1021.870728,849.140625 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M1115.437988,850.693848 
	C1115.511963,851.802063 1115.214355,852.951172 1114.847534,854.442932 
	C1108.740234,857.889038 1105.548706,856.686157 1103.243042,850.201965 
	C1100.220825,841.701965 1097.304565,833.163696 1094.421143,824.615479 
	C1093.216064,821.042969 1091.074097,818.236267 1087.951660,815.649902 
	C1087.994629,813.894775 1089.146484,813.931580 1090.437500,813.655029 
	C1094.678223,813.443787 1095.463135,816.721924 1096.766602,819.291626 
	C1101.147217,827.927307 1103.391602,837.320862 1106.296875,846.482666 
	C1107.105957,849.034607 1107.421753,852.344849 1111.865479,851.765686 
	C1113.175537,851.368835 1114.121094,851.051758 1115.437988,850.693848 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M1111.863525,851.502319 
	C1107.817017,855.139648 1106.504395,850.835449 1105.534668,848.408142 
	C1103.288452,842.786316 1102.063477,836.760498 1099.883789,831.106934 
	C1097.598755,825.180603 1096.188477,818.734497 1091.041748,813.824707 
	C1095.077393,811.072937 1098.140747,812.672424 1099.772461,818.438171 
	C1102.252930,827.202942 1104.562988,836.017029 1107.142578,844.752014 
	C1107.890259,847.283752 1107.821411,850.620239 1111.863525,851.502319 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M1064.981201,843.595215 
	C1059.723877,844.123535 1058.561523,840.576965 1058.006348,836.294800 
	C1057.113892,829.410034 1057.256226,822.602051 1059.026123,815.448364 
	C1059.455200,815.018066 1059.872314,814.999878 1060.601318,814.961121 
	C1060.272217,822.041870 1060.289429,829.070435 1061.824585,836.126892 
	C1062.411743,838.825439 1063.902588,840.870605 1064.981201,843.595215 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M1070.460693,860.530273 
	C1070.442383,860.492981 1070.479126,860.567627 1070.460693,860.530273 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M358.934631,857.339233 
	C359.445129,856.999817 359.889008,856.999573 360.665833,856.998474 
	C364.831818,854.694031 369.158508,853.529358 373.242096,851.819824 
	C381.549500,848.342102 388.868652,843.653687 394.753723,836.269531 
	C395.951721,834.825256 397.248932,834.131592 398.774231,833.221375 
	C398.998779,833.000000 399.000000,833.000000 399.000000,833.000000 
	C404.406830,825.198914 412.009827,819.918579 419.625397,814.540100 
	C423.613831,811.723267 427.614441,808.611023 430.125641,803.704102 
	C432.038849,801.421631 433.776703,799.487122 434.922455,797.067505 
	C435.623749,795.586487 436.635925,794.199402 439.004486,794.717834 
	C441.200226,797.765625 440.413605,800.646240 438.344513,802.659668 
	C434.981018,805.932678 433.678131,810.255859 431.583679,814.156921 
	C430.486694,816.200134 429.341248,817.859863 427.063904,818.399048 
	C424.865387,818.919617 423.183105,820.220154 421.524719,821.624268 
	C417.501434,825.030945 413.721375,828.588379 408.902069,831.146118 
	C404.036621,833.728333 402.130707,839.630859 398.220795,843.515991 
	C396.112976,845.610413 394.519989,848.221497 391.955566,849.886963 
	C390.068451,851.112488 389.753540,853.094788 389.454437,855.093262 
	C389.039734,857.863953 387.609039,859.306824 384.650024,858.665955 
	C381.032684,857.882507 377.836243,858.992981 374.564423,860.482727 
	C370.637085,862.270874 366.508514,863.609558 362.262726,864.536072 
	C357.260834,865.627441 353.732574,868.106812 353.490784,873.811340 
	C353.374573,876.553284 352.203339,878.626343 349.044312,878.841675 
	C347.559479,878.942871 347.291168,880.161743 346.839661,881.630249 
	C343.111908,884.138062 339.449738,886.895569 335.385895,887.217224 
	C327.561462,887.836548 322.447296,892.660217 317.017578,897.208252 
	C315.759644,898.261963 314.695648,899.558228 313.249084,900.657166 
	C317.136444,897.834106 320.227875,898.544373 323.038727,902.220398 
	C325.485809,905.420715 329.044342,904.534119 332.288239,903.958923 
	C339.001312,902.768433 343.455505,906.777405 342.818268,913.697632 
	C342.567200,916.423645 340.587891,918.437500 338.246216,918.132080 
	C331.617737,917.267456 324.874756,920.249084 318.371277,917.384644 
	C318.371277,917.384644 302.794617,916.567749 302.925385,916.574585 
	C304.633484,916.504883 306.675507,916.432617 308.025055,917.649231 
	C311.481323,920.765259 314.971527,923.590942 319.640869,924.818176 
	C321.220978,925.233398 322.136322,927.072632 321.651428,928.944641 
	C321.104645,931.055725 319.298340,930.924133 317.608734,930.986145 
	C312.939270,931.157593 308.781952,932.664551 305.246338,935.822144 
	C307.454285,934.767578 309.081757,931.896118 312.175659,934.186462 
	C315.311584,936.507996 316.707397,939.394958 316.076385,943.400024 
	C316.869507,939.160767 319.143524,936.347717 323.422119,935.460632 
	C324.946136,935.144653 326.192688,934.380920 327.192657,933.187927 
	C328.863831,931.194031 330.931793,930.256836 333.368378,931.547668 
	C335.519165,932.687073 336.224396,931.867371 336.822449,929.848511 
	C337.783600,926.603760 340.136841,926.837830 342.320587,928.339844 
	C345.465149,930.502808 347.946472,933.481262 350.716827,936.086365 
	C352.995758,938.229370 351.451355,941.172119 347.871521,942.303772 
	C343.301422,943.748413 343.301422,943.748413 346.093414,948.269043 
	C347.884949,945.338928 351.859924,945.140076 352.980438,941.108582 
	C353.661926,938.656677 358.441467,939.408936 360.373077,942.537903 
	C361.660797,944.623718 362.503723,945.280396 364.898224,944.174500 
	C367.034821,943.187744 369.156219,943.952881 370.504761,946.597046 
	C370.839539,948.249023 370.521606,949.452148 369.733948,950.919189 
	C366.251678,953.743774 362.948853,956.186096 359.278809,958.141602 
	C351.970062,962.035950 350.209320,966.301636 352.331604,974.369202 
	C352.589447,975.349426 352.774109,976.302368 352.001373,977.521851 
	C349.101746,979.116638 346.269104,978.643066 343.510712,978.726929 
	C337.207275,978.918579 331.107239,979.822754 325.397644,983.688660 
	C321.908234,984.536926 319.866547,987.392639 316.335632,988.071533 
	C314.591614,988.186768 313.147461,984.731506 311.763184,988.382263 
	C311.337982,989.503540 309.263550,989.674011 307.380676,989.219849 
	C304.062836,987.465332 301.081177,986.538269 297.613739,987.568054 
	C295.006409,988.342346 293.687286,986.917358 294.424774,984.328064 
	C296.669739,976.446106 290.072968,970.932983 289.008850,963.892639 
	C288.649933,961.518127 284.404633,960.824524 283.095154,961.591064 
	C280.116638,963.334656 278.125336,961.363831 275.363617,961.006104 
	C273.896576,959.994629 273.072174,958.521057 271.878906,957.844788 
	C266.821930,954.978943 265.465698,951.620544 269.250610,946.638428 
	C270.478180,945.022583 269.587616,943.117310 267.872284,941.922729 
	C266.518768,940.980286 264.989471,940.254578 264.018250,938.374023 
	C263.105438,931.572754 263.416870,930.875793 269.267456,928.994873 
	C271.055511,928.420044 271.564331,927.459595 271.793732,925.834656 
	C272.126648,923.475952 272.024780,921.215942 271.160431,918.969604 
	C270.321045,916.787842 269.855652,914.539429 269.875885,912.153564 
	C269.924530,906.415283 269.327789,905.972473 263.444977,906.964783 
	C262.125336,907.187317 260.817627,907.419373 259.486145,907.138367 
	C257.560242,906.732056 256.005829,905.737671 255.560089,903.757446 
	C255.052750,901.503540 256.972412,900.857056 258.371002,899.643799 
	C265.501923,893.457642 274.104218,893.547302 282.720428,893.495239 
	C284.362854,893.485291 286.083313,893.775269 287.731842,892.247009 
	C288.000000,892.000000 288.500000,892.000000 288.750000,892.000000 
	C289.685822,891.422058 290.455994,891.257935 291.657349,891.764099 
	C294.657410,892.991272 296.646759,892.538330 299.148315,890.677429 
	C302.154663,888.441162 306.016479,886.906433 309.723358,886.202026 
	C312.700592,885.636353 314.385590,885.143188 314.038818,881.821228 
	C313.937927,880.854736 314.011902,879.658020 315.223358,879.386230 
	C316.559235,879.086487 316.719604,880.241211 317.051422,881.197327 
	C318.015686,883.975586 319.333069,881.942688 320.396759,881.320679 
	C323.576233,879.461731 325.482910,874.974731 330.374329,876.603577 
	C330.730103,876.722046 331.738525,875.976501 331.879303,875.474487 
	C333.231140,870.653076 338.478058,870.943665 341.219482,868.256165 
	C346.335663,863.240723 354.222321,863.206238 358.934631,857.339233 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M287.533630,892.000000 
	C287.855774,893.222900 287.427673,894.549438 286.151093,894.651855 
	C280.751465,895.085022 275.336945,895.461060 269.924561,895.513306 
	C268.621246,895.525879 267.818970,896.178162 266.776215,896.410706 
	C263.032471,897.245544 261.591858,901.622192 257.188354,901.997742 
	C255.233292,902.164490 257.069824,904.743408 258.450806,905.516357 
	C260.550934,906.691650 262.632629,905.563721 264.680481,904.953003 
	C269.886047,903.400635 272.768768,905.867615 271.645813,911.266724 
	C271.243774,913.199585 271.078064,915.032104 272.073181,916.507141 
	C274.730225,920.445129 272.863770,924.851807 273.708313,928.953796 
	C274.031097,930.521301 272.800751,931.185669 271.567444,930.851807 
	C265.780853,929.285400 264.469177,932.207886 265.000000,937.570190 
	C264.137543,939.057312 263.033142,939.372559 261.363220,939.033691 
	C254.153976,938.944092 252.148010,936.900452 252.000000,929.415405 
	C252.180542,928.865112 252.361099,928.730164 252.876892,928.633789 
	C255.103912,929.695251 257.264343,931.369385 256.933960,926.787109 
	C257.493164,921.426453 257.772186,916.507568 252.242310,913.238403 
	C247.688965,911.516113 243.698029,913.633911 239.560944,914.106750 
	C239.396042,914.125610 239.163620,914.104187 239.079620,914.201904 
	C233.716919,920.443787 226.310715,920.047607 219.073059,920.871826 
	C216.323120,921.184998 213.838242,923.282288 210.618362,922.200684 
	C208.867905,921.612732 206.972519,921.830811 206.993317,919.161255 
	C207.012283,916.725098 207.844849,915.040649 210.214752,914.139771 
	C214.982010,912.327637 219.680267,914.263367 224.410385,914.302917 
	C225.019897,914.308044 225.628052,914.475769 226.556427,914.616699 
	C225.722321,911.941467 224.847656,909.678345 224.319916,907.337036 
	C222.990723,901.439941 226.266403,896.660522 231.914261,894.428894 
	C241.099411,890.799377 251.324570,891.721252 260.283905,887.838135 
	C265.784607,885.454041 268.981598,888.266479 272.970184,890.779480 
	C277.299133,893.506897 282.365021,891.446838 287.533630,892.000000 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M261.150635,939.326416 
	C261.856171,937.895752 263.087097,938.019836 264.637207,938.000488 
	C265.558441,939.101257 266.482697,939.588196 267.649841,939.999695 
	C271.907318,941.500793 273.179565,945.571899 270.165863,948.695679 
	C267.071838,951.902527 266.913879,953.949463 271.661255,955.135986 
	C274.336151,955.804504 274.019714,958.423462 274.949463,960.628479 
	C274.447266,961.315063 273.493469,961.546753 273.404327,961.963440 
	C272.461365,966.371155 270.242065,966.951782 266.665924,964.543213 
	C265.923950,964.043457 264.762268,964.166870 263.397614,964.001587 
	C261.523804,963.867126 260.467957,963.065002 259.510376,961.578369 
	C257.829987,957.791443 256.043823,958.505615 253.947693,961.251648 
	C252.906647,962.454834 251.964371,963.179993 250.335632,963.145630 
	C248.394867,961.130005 246.947830,959.114136 244.480499,958.187012 
	C243.430832,957.792603 242.202286,957.210632 242.498016,955.731018 
	C242.776932,954.335571 244.045654,953.888184 245.186554,954.074890 
	C250.441895,954.934814 254.767593,953.531860 259.381470,950.636963 
	C264.354767,947.516663 265.103394,947.419678 262.424316,941.866455 
	C262.064880,941.121399 261.676361,940.390381 261.150635,939.326416 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M266.970642,981.250977 
	C266.052399,987.318237 264.930115,987.914673 260.004456,985.715332 
	C257.017426,984.381531 254.245377,986.393311 253.722290,988.481506 
	C253.182922,990.634644 253.871094,992.889465 250.770874,992.920471 
	C247.803925,992.950195 244.351883,992.957214 242.604889,990.372559 
	C241.135300,988.198303 241.532028,985.153198 243.280426,982.835815 
	C246.574249,978.469971 252.688339,977.520447 257.204102,980.727966 
	C259.562286,982.402954 261.326447,982.410461 263.084106,979.920227 
	C264.998138,977.208313 266.249847,978.167725 266.970642,981.250977 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M250.000107,963.407410 
	C250.819946,962.423218 251.639923,961.846497 252.729950,961.134888 
	C254.946564,959.305115 257.056274,959.053162 259.666809,960.641968 
	C260.657959,962.157593 262.528442,961.835388 262.942322,963.681396 
	C263.327728,966.593811 263.815460,969.179626 263.943970,971.783203 
	C264.150146,975.960632 262.306946,977.492188 258.204102,976.841675 
	C250.694717,975.651123 250.694702,975.651184 250.000107,963.407410 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M253.330963,929.157593 
	C253.000000,929.000000 252.500000,929.000000 252.250000,929.000000 
	C251.741043,927.394653 251.371643,925.855835 249.331604,925.062256 
	C248.833435,925.128052 248.666870,925.256104 248.250458,925.192139 
	C248.048904,923.411743 247.662796,921.490784 249.590118,920.808533 
	C253.488464,919.428650 252.911026,916.693542 252.010010,913.381836 
	C258.735901,914.229065 260.103882,917.222168 258.013336,926.593140 
	C257.003296,928.613647 255.472610,929.226746 253.330963,929.157593 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M241.225250,924.692261 
	C239.950974,926.174561 238.714920,927.025269 237.082367,926.497437 
	C234.387314,925.626160 231.870377,925.926025 229.409302,927.263794 
	C228.154526,927.945862 226.929459,928.196960 225.939972,926.727966 
	C224.868942,925.138123 225.575073,924.029785 226.673294,922.797485 
	C228.331223,920.937073 230.435272,920.954041 232.615433,920.993225 
	C234.439529,921.026062 236.264679,921.000000 238.544983,921.000000 
	C240.172607,921.870850 241.902374,922.338074 241.225250,924.692261 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M241.807312,930.250244 
	C244.324722,928.112976 248.410492,929.024841 248.472092,931.726196 
	C248.524445,934.021973 249.042892,936.246338 249.013062,938.535645 
	C248.975159,941.445923 246.931534,942.227295 244.780243,942.416809 
	C242.196182,942.644348 242.688095,940.159241 241.955978,938.645569 
	C240.620483,935.884521 245.769974,933.928162 242.341034,931.073975 
	C241.871933,930.833496 241.743881,930.666992 241.807312,930.250244 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M217.269958,932.774414 
	C215.933334,933.000000 214.866653,933.000000 213.399994,933.000000 
	C212.871933,932.833435 212.743881,932.666809 212.807602,932.250122 
	C212.909317,929.388550 212.815659,926.819336 215.568405,925.238098 
	C218.023529,923.827820 219.159302,924.380310 218.997528,927.632568 
	C217.505493,929.193298 218.399155,931.152100 217.269958,932.774414 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M307.032684,989.308105 
	C309.985657,988.674255 312.207855,985.076721 316.021057,987.139282 
	C316.343475,989.939209 314.920105,991.822693 312.452148,993.387451 
	C311.315521,993.392639 310.689392,993.047729 309.924744,992.204651 
	C308.579590,991.978699 307.849365,991.427002 307.182648,990.302734 
	C307.057495,990.045959 307.043121,989.553955 307.032684,989.308105 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M228.316330,950.222168 
	C228.349442,948.410095 227.026520,945.993530 229.372009,945.436157 
	C231.170074,945.008850 230.839478,947.031738 230.998505,948.612976 
	C230.616272,950.145447 229.825134,950.624573 228.316330,950.222168 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M217.241241,933.206909 
	C217.285614,931.355652 216.225861,929.353882 218.639786,928.089844 
	C218.308868,929.752930 220.590927,932.338257 217.241241,933.206909 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M247.656982,925.009766 
	C248.000610,925.000000 248.500305,925.000000 248.750153,925.000000 
	C248.873154,926.263855 249.149887,927.852905 247.115112,927.741882 
	C246.241821,927.694214 245.516663,927.142578 245.515991,926.205139 
	C245.515167,925.056824 246.536438,925.155640 247.656982,925.009766 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M241.335266,924.929565 
	C241.024826,923.565735 240.050140,922.627869 239.150497,921.289062 
	C241.404724,921.238953 244.096405,921.278931 241.335266,924.929565 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M228.063843,950.322510 
	C228.806046,949.753174 229.611481,949.506409 230.709045,949.129822 
	C231.950378,949.860840 231.818878,950.716553 230.843506,951.383362 
	C229.721252,952.150452 228.713058,952.064941 228.063843,950.322510 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M241.642471,930.012573 
	C241.998978,930.166687 241.999191,930.333313 241.999695,930.750000 
	C241.775543,932.007019 241.484741,933.015320 240.136505,932.649658 
	C239.780334,932.553040 239.328705,931.995361 239.306442,931.626038 
	C239.224915,930.274597 240.280182,930.140442 241.642471,930.012573 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M291.937500,891.678162 
	C291.250000,892.000000 290.500000,892.000000 289.375000,892.000000 
	C289.560730,890.009460 290.396240,889.246399 291.937500,891.678162 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M212.730042,932.040771 
	C212.999573,932.166687 212.999756,932.333313 212.999969,932.750000 
	C212.599625,933.475220 212.046661,933.969482 211.612076,933.259766 
	C211.204468,932.594055 211.866455,932.262390 212.730042,932.040771 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M300.660736,998.176147 
	C301.408783,997.955322 301.816986,997.924622 302.832886,998.108521 
	C304.296967,999.789795 303.954285,1001.263184 303.247192,1002.601257 
	C297.609192,1013.269958 301.940643,1020.795898 312.935211,1026.073853 
	C314.129913,1026.647339 315.411652,1027.027100 316.592041,1027.639526 
	C319.539215,1029.168945 320.434662,1031.141113 319.146088,1034.548462 
	C316.888214,1040.518799 314.664185,1046.558594 316.544586,1053.246338 
	C317.456573,1056.489868 319.325378,1057.799683 322.444916,1058.291382 
	C329.171600,1059.351562 335.608826,1057.827148 342.062378,1056.386597 
	C343.356812,1056.097656 344.603180,1055.640625 346.335510,1055.818115 
	C346.953583,1055.988159 347.191498,1056.088013 347.730408,1056.432129 
	C348.306946,1057.139893 348.378876,1057.632935 348.088806,1058.503418 
	C347.775452,1059.048462 347.618958,1059.246094 347.170166,1059.678955 
	C344.796387,1060.694946 343.086914,1062.044678 341.107513,1063.673828 
	C340.314911,1064.090332 339.824768,1064.241821 338.922455,1064.371338 
	C335.653381,1064.166260 332.870941,1064.996704 330.224792,1065.758301 
	C324.507690,1067.403931 319.361755,1065.850830 314.287720,1063.690552 
	C310.123688,1061.917603 308.741974,1058.266113 308.521210,1053.995117 
	C308.296661,1049.651001 309.275604,1045.404541 309.820190,1041.161987 
	C310.221100,1038.038818 309.265106,1036.163574 307.293427,1034.165283 
	C302.877869,1029.690308 298.521851,1025.299805 295.804169,1019.320923 
	C293.049164,1013.259949 294.787292,1008.106140 297.238159,1002.369263 
	C298.030853,1000.516907 299.196991,999.458313 300.660736,998.176147 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M442.674713,1037.685303 
	C442.923828,1038.981323 442.950989,1040.596313 441.983368,1040.538696 
	C437.135559,1040.250122 432.874176,1044.149048 428.072357,1042.711548 
	C424.162048,1041.540894 420.110992,1042.725220 416.279938,1041.332886 
	C414.412872,1040.654419 413.611572,1042.496094 412.784973,1043.554199 
	C406.757477,1051.268921 397.357086,1052.430786 389.025879,1054.503052 
	C384.165314,1055.712280 377.820343,1055.827393 372.513306,1052.767822 
	C368.625671,1050.526611 364.179718,1050.927124 359.411591,1050.979736 
	C357.551727,1049.288818 358.299316,1047.796631 359.594513,1046.058350 
	C360.652283,1044.974609 361.596649,1044.267212 362.318176,1042.887451 
	C362.713379,1042.431641 362.890320,1042.263428 363.374084,1041.889160 
	C366.517639,1040.118408 369.398163,1040.590576 371.555298,1042.576294 
	C381.065765,1051.330444 391.467773,1049.296997 402.149384,1045.592285 
	C406.517395,1044.077271 410.025024,1041.559326 412.907410,1037.472168 
	C413.981934,1036.802856 414.807709,1036.660522 416.085327,1036.546387 
	C425.136108,1036.668701 433.711792,1037.945435 442.674713,1037.685303 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M349.689331,1058.778687 
	C347.922882,1064.755615 343.058258,1067.320435 338.028687,1069.865112 
	C330.807892,1073.518555 323.044495,1072.486938 315.456085,1072.768188 
	C311.022614,1072.932495 303.380585,1065.229126 303.008026,1060.740234 
	C302.514801,1054.797607 300.817627,1049.030396 300.842133,1042.979492 
	C300.856049,1039.548950 298.066132,1037.078003 295.993530,1034.480225 
	C290.327637,1027.378418 288.132599,1019.397156 289.917633,1010.393311 
	C290.361328,1008.155334 291.383240,1006.382935 293.644745,1005.066284 
	C294.910187,1005.681396 295.075836,1006.660156 294.985840,1008.078125 
	C293.437347,1011.255188 291.113159,1014.001587 294.415771,1017.479492 
	C294.902283,1018.425415 295.032928,1019.111816 295.071167,1020.163635 
	C294.533295,1022.452759 295.053009,1023.976746 297.108490,1025.234375 
	C297.505096,1025.668701 297.647980,1025.859131 297.959900,1026.367554 
	C298.594116,1030.585815 301.485718,1033.036987 303.712708,1035.845581 
	C306.777679,1039.711060 308.204407,1043.774658 306.708191,1048.741577 
	C305.950592,1051.256470 305.953705,1053.947876 307.692322,1056.669678 
	C308.278168,1057.917236 308.478271,1058.890259 308.605164,1060.252930 
	C311.037964,1066.549683 314.026367,1068.728149 320.729431,1068.435913 
	C327.740875,1068.129883 334.955017,1068.259399 341.259583,1063.462402 
	C342.877899,1061.574097 345.014862,1060.976562 346.765320,1059.266602 
	C347.022797,1059.032104 347.000549,1059.007690 347.012299,1059.015015 
	C347.722229,1058.481567 348.477325,1058.205933 349.689331,1058.778687 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M309.607697,992.231934 
	C310.274078,992.012268 310.897247,992.065063 311.767639,992.303650 
	C312.726288,993.439148 313.223999,994.460022 312.996643,996.105469 
	C307.490906,1005.235840 310.399719,1015.559082 320.879333,1018.248596 
	C327.478943,1019.942383 334.225739,1020.501831 341.049347,1020.346313 
	C344.546875,1020.266602 348.088806,1020.435303 350.384033,1024.311768 
	C350.488525,1025.286011 350.302826,1025.797241 349.603363,1026.501221 
	C348.486176,1027.145996 347.650970,1027.252197 346.376648,1027.218262 
	C339.662994,1026.254639 333.407227,1028.279785 327.151062,1027.576538 
	C318.837921,1026.642212 310.617584,1025.083252 305.219574,1017.715637 
	C301.477814,1012.608582 300.746399,1007.067688 303.862457,1001.278748 
	C304.806427,999.525146 306.065460,997.929993 306.777130,995.599060 
	C307.094818,995.004700 307.247040,994.781006 307.658691,994.245239 
	C308.466888,993.456909 309.015717,992.980652 309.607697,992.231934 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M294.009674,1021.337158 
	C293.993958,1020.252686 293.991028,1019.505371 293.984375,1018.384399 
	C291.838379,1015.340759 291.483490,1012.558350 293.717163,1009.309631 
	C293.988068,1007.924377 293.995758,1006.859253 293.997437,1005.394653 
	C293.997681,1004.555420 294.003876,1004.115723 294.013580,1003.346191 
	C294.567657,1002.148865 295.109924,1001.272034 296.613129,1001.007324 
	C297.517059,1001.021545 297.998383,1001.184631 298.716766,1001.729919 
	C292.697693,1013.179810 296.462982,1022.462463 305.445557,1029.651611 
	C311.859985,1034.785400 312.343597,1040.683716 310.615570,1047.516113 
	C306.955261,1061.988647 317.126740,1065.333374 328.386993,1064.595093 
	C331.630371,1064.382446 334.742279,1062.718262 338.567139,1062.998169 
	C339.040009,1067.451782 335.252655,1067.354492 332.580750,1067.832886 
	C329.855408,1068.320557 326.957855,1068.167358 324.156250,1068.507935 
	C317.324463,1069.338501 311.740051,1067.263794 307.319061,1061.302734 
	C307.004059,1059.927979 306.997803,1058.865723 306.989685,1057.405151 
	C305.013428,1054.175537 306.486206,1051.207764 306.784973,1048.350586 
	C307.379944,1042.661377 307.062286,1037.398804 302.032318,1033.569580 
	C299.674072,1031.774170 298.790588,1029.015015 297.253845,1026.259766 
	C297.008484,1025.994507 297.000000,1026.000000 297.003387,1025.995605 
	C295.851166,1024.663574 294.123749,1023.731018 294.009674,1021.337158 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M443.248291,1037.996582 
	C434.303802,1040.184204 425.606201,1038.151978 416.454834,1038.002930 
	C416.956390,1033.518799 418.988434,1032.083130 423.647675,1033.363159 
	C430.031921,1035.117065 436.268188,1034.994751 442.852112,1032.748169 
	C443.655365,1032.296997 444.143555,1032.111206 445.021942,1031.882446 
	C450.846710,1030.552856 454.952728,1026.240356 460.501465,1025.191895 
	C462.448242,1024.824097 464.605377,1023.051819 465.722931,1026.694092 
	C463.977997,1030.656250 459.902252,1030.093628 457.095062,1031.812988 
	C455.824585,1032.591309 454.123108,1032.989258 452.312866,1033.782959 
	C451.878052,1034.305176 451.683014,1034.558960 451.210693,1034.881226 
	C450.682800,1035.315063 450.349945,1035.611572 449.700500,1036.033081 
	C447.737885,1036.580078 445.956635,1036.558228 444.221985,1037.768066 
	C443.989594,1037.990234 443.495392,1037.993164 443.248291,1037.996582 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M361.229431,1045.798584 
	C360.286102,1047.411377 358.878723,1048.483398 358.969116,1050.624634 
	C358.955048,1050.970581 358.473999,1050.981812 358.233521,1050.988525 
	C357.255951,1051.414917 356.625092,1051.133301 355.791016,1050.422607 
	C354.725189,1048.415649 355.919281,1047.035156 356.411560,1045.185303 
	C356.514435,1043.914551 356.421936,1042.984741 356.514038,1041.695190 
	C357.785126,1039.445312 359.555969,1038.634644 362.098694,1038.703247 
	C363.660431,1039.765991 364.075134,1040.918579 363.266968,1042.692627 
	C363.040375,1043.024170 363.013031,1042.999268 363.019165,1043.015381 
	C362.276642,1043.744141 362.125763,1044.829224 361.229431,1045.798584 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M353.233276,1055.826416 
	C352.506256,1056.250244 352.015900,1056.518799 351.269775,1056.900391 
	C350.308228,1057.518433 349.540527,1057.723267 348.347229,1057.254150 
	C348.011108,1057.013550 347.964783,1056.962280 347.941040,1056.937256 
	C347.447357,1051.970459 351.025665,1051.234009 354.947937,1050.707275 
	C355.782379,1051.693848 355.826233,1052.491943 355.242645,1053.659668 
	C355.002441,1054.000000 355.005676,1054.005493 355.008331,1054.010742 
	C354.440125,1054.514526 353.998993,1055.133789 353.233276,1055.826416 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M444.406952,1037.995117 
	C444.450195,1035.607544 446.323120,1035.257324 448.635803,1035.008057 
	C449.576965,1035.685791 449.740784,1036.454590 449.234497,1037.653076 
	C448.998352,1037.994507 449.000000,1038.000000 448.996765,1037.997559 
	C447.603790,1037.996704 446.214050,1037.998291 444.406952,1037.995117 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M297.249756,1001.745972 
	C296.203552,1002.253357 295.401031,1002.504272 294.307770,1002.885742 
	C294.128387,1000.672852 294.361450,998.425842 297.786621,998.757446 
	C298.692352,999.989868 298.102966,1000.741760 297.249756,1001.745972 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M355.367554,1053.990967 
	C355.004974,1053.254028 355.007538,1052.507935 355.008820,1051.388916 
	C354.820465,1050.539673 354.932159,1050.148804 355.681824,1049.926636 
	C356.510895,1050.262207 357.001038,1050.514648 357.742065,1050.881104 
	C358.045532,1052.600708 357.734558,1053.931152 355.367554,1053.990967 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M307.703003,993.872437 
	C307.961029,994.116028 308.003937,994.298767 308.049591,994.763062 
	C306.820129,996.451721 306.415894,998.895752 303.419525,998.294128 
	C303.102356,998.097717 302.972107,997.974060 302.904907,997.911377 
	C303.126526,997.077881 303.415344,996.307129 303.841614,995.255432 
	C304.632538,994.282349 305.455017,993.897705 306.702087,993.717651 
	C307.042847,993.769653 307.487915,993.811584 307.703003,993.872437 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M297.337219,1002.001831 
	C297.253265,1001.191528 297.500458,1000.380554 297.876129,999.273804 
	C298.885468,998.537109 299.766296,998.096191 300.823853,997.820679 
	C300.421204,999.129578 299.841858,1000.273193 299.127594,1001.707153 
	C298.551239,1001.998779 298.109772,1002.000000 297.337219,1002.001831 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M347.895782,1057.268921 
	C348.761597,1057.008057 349.512085,1057.002563 350.638306,1057.005249 
	C350.756500,1057.514282 350.498993,1058.015015 350.130463,1058.775879 
	C349.270111,1059.030151 348.520813,1059.024170 347.397766,1059.020264 
	C347.276184,1058.522949 347.528320,1058.023560 347.895782,1057.268921 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M306.801514,993.580688 
	C306.243530,994.153381 305.444244,994.537170 304.312012,994.947754 
	C304.220795,993.438110 304.986877,992.756714 306.801514,993.580688 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M353.187073,1056.147217 
	C353.087402,1055.082397 353.393158,1054.341309 354.695679,1054.058838 
	C354.810822,1055.026489 354.934540,1056.267334 353.187073,1056.147217 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M106.518463,661.698059 
	C107.750259,662.228210 109.651306,661.652100 109.254395,663.332275 
	C108.964157,664.560852 107.327202,664.925903 105.974213,664.909302 
	C104.770706,664.894531 103.523453,664.532104 103.490242,663.156616 
	C103.446327,661.337769 104.718376,661.146667 106.518463,661.698059 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M110.000000,657.000000 
	C111.416542,657.079712 113.106651,656.871094 113.475357,658.729187 
	C113.705856,659.890747 112.516617,660.411011 111.607140,660.469788 
	C109.479401,660.607178 110.243439,658.828796 110.001266,657.365112 
	C109.999695,657.000000 110.000000,657.000000 110.000000,657.000000 
z"/>
<path fill="#BEBCBF" opacity="1.000000" stroke="none" 
	d="
M87.633675,695.611694 
	C88.039398,695.329651 88.200790,695.242737 88.354271,695.255005 
	C90.431053,695.420837 91.633072,696.541504 91.735077,698.624756 
	C91.773956,699.418884 91.020317,699.759338 90.360092,699.505676 
	C88.707863,698.870911 86.790222,698.354980 87.633675,695.611694 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M430.371826,803.706177 
	C430.335693,809.605774 425.282318,812.281677 421.803650,814.977478 
	C415.044800,820.215027 407.632111,824.561646 402.115356,831.318604 
	C401.531769,832.033386 400.507080,832.388062 399.343506,832.954956 
	C399.301178,831.574707 399.602386,830.149475 399.951172,828.360840 
	C400.172302,823.826599 404.269287,822.687805 406.514526,819.674622 
	C412.814606,816.005493 418.161194,811.655273 423.420197,807.164185 
	C425.280029,805.575806 427.106873,803.766968 430.371826,803.706177 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M394.655823,836.045654 
	C394.837982,842.012878 389.416107,844.082825 385.726471,846.529175 
	C381.501984,849.330200 377.653656,852.978394 372.002380,854.199524 
	C368.762817,854.899475 365.118103,855.725159 361.403320,856.985229 
	C363.818604,853.067444 368.348511,851.377625 372.383728,849.681152 
	C380.049530,846.458374 387.458496,843.197083 392.974823,836.701416 
	C393.261810,836.363342 393.858887,836.288635 394.655823,836.045654 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M406.508728,819.585693 
	C406.239807,823.730164 401.693207,824.210999 400.258728,827.790527 
	C398.560242,828.636353 397.121735,829.275208 395.342926,829.958252 
	C394.651093,829.605164 394.504578,829.142883 394.778625,828.305359 
	C395.121674,827.690918 395.315765,827.437683 395.785339,827.114624 
	C396.524475,826.457520 397.069580,825.936340 397.805237,825.205750 
	C400.541870,824.590820 401.981537,822.926575 402.963867,820.348755 
	C403.265778,819.630310 403.606873,819.354370 404.346924,819.083496 
	C405.127045,818.978577 405.570435,819.034607 406.508728,819.585693 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M398.637573,833.007446 
	C398.783539,834.981506 397.228119,835.440674 395.343964,835.957886 
	C395.166046,833.988037 396.301483,833.040955 398.637573,833.007446 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M394.667969,828.035156 
	C394.996033,828.441223 394.997986,828.887268 395.001282,829.667908 
	C394.874725,830.306030 394.680634,830.558838 394.211792,830.881714 
	C393.252655,831.001831 392.502106,831.001038 391.376404,831.000122 
	C391.752472,829.611267 392.715851,828.464783 394.667969,828.035156 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M398.403473,819.613708 
	C397.809143,824.793884 392.725494,825.059204 389.614166,827.356567 
	C382.969513,832.262878 376.094269,837.639709 367.261505,838.374084 
	C359.628967,839.008667 353.144012,843.016479 346.078644,845.150269 
	C335.800079,848.254578 326.426483,853.432922 316.269989,856.659851 
	C306.762268,859.680725 297.254852,862.718323 287.657990,865.432922 
	C284.918793,866.207703 281.614746,865.957214 278.997498,865.499939 
	C272.994843,864.451050 267.498596,865.452637 261.641296,866.372009 
	C250.218674,868.165039 238.678818,868.500244 227.431473,864.309631 
	C222.673264,862.536682 217.643951,861.260132 212.625916,860.460266 
	C198.854141,858.264954 187.980057,850.485779 177.182816,842.563171 
	C175.391922,841.249023 172.968170,841.769958 171.755966,840.572815 
	C165.603027,834.496277 155.430771,832.346313 152.446335,823.628723 
	C150.029160,816.568237 144.722229,811.186035 143.147461,803.536987 
	C142.217010,799.017578 138.777420,794.896118 139.858658,789.673340 
	C139.887894,789.532104 139.808533,789.260132 139.706711,789.215332 
	C132.867752,786.205444 135.770004,778.481750 132.151917,773.586121 
	C128.621613,768.809326 128.115616,762.188965 126.221428,756.399353 
	C123.839279,749.118225 127.139618,743.094482 130.445648,737.372437 
	C132.408005,733.976013 134.168152,729.876831 138.662201,728.062134 
	C138.995728,727.995605 139.005997,728.006042 139.011032,728.011169 
	C139.510559,728.565552 139.963852,729.140015 140.203995,730.241272 
	C140.252853,731.098450 140.192856,731.583374 139.951614,732.404663 
	C139.444870,733.662537 138.628540,734.245667 137.843323,735.307739 
	C137.429047,735.747192 137.251099,735.912231 136.773956,736.284607 
	C132.662567,741.654785 130.997574,747.063293 134.564575,753.078186 
	C134.819931,753.508789 135.108643,754.008423 135.055649,754.477295 
	C133.863037,765.030640 141.939743,772.385254 144.547531,781.710449 
	C147.287247,791.507263 151.227982,801.106262 155.234100,810.450928 
	C159.470703,820.333191 164.747040,830.408325 176.355347,834.310303 
	C182.636322,836.421570 187.506714,840.973816 192.731750,844.693237 
	C199.212189,849.306396 206.681732,850.760071 213.810516,853.264465 
	C219.523071,855.271362 225.268143,857.604187 231.547302,857.504639 
	C232.699509,857.486389 233.894485,857.882080 234.801880,858.646606 
	C237.214264,860.679016 239.793671,860.863831 242.729233,859.906982 
	C245.268646,859.079285 247.632019,860.200867 250.266846,861.316650 
	C253.390823,862.668884 255.212372,861.005066 257.338013,859.129883 
	C259.114136,858.456787 260.586090,858.481140 262.423157,858.938232 
	C266.124481,861.425049 269.549011,860.546082 273.314514,859.174255 
	C274.323486,858.894714 275.009155,858.873352 276.022369,858.973999 
	C277.147705,859.181641 277.949554,859.380981 279.058960,858.947144 
	C280.416809,858.370422 281.508728,858.091553 283.023010,858.329346 
	C287.819702,858.589294 292.419281,859.629517 296.494812,857.018066 
	C298.562653,855.692871 300.896393,855.593079 303.086578,854.890869 
	C314.082581,851.365540 324.878693,847.288574 336.012543,843.111267 
	C339.691376,840.820251 343.395782,839.230713 346.698120,836.340088 
	C347.937836,835.796448 348.895966,835.748047 350.218445,835.900574 
	C353.448547,837.909119 355.571106,836.179199 358.020569,834.330139 
	C370.718536,832.123230 381.864868,826.867065 392.782898,820.991089 
	C394.338593,820.153809 395.767883,818.795654 398.403473,819.613708 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M405.229492,819.730957 
	C404.539398,819.971313 404.102234,819.982544 403.337341,820.000854 
	C401.943878,820.007568 400.878143,820.007202 399.413544,820.010742 
	C399.014648,820.014648 398.999725,819.999207 398.994019,819.991943 
	C398.399292,819.147339 398.614594,818.271301 398.982910,817.074280 
	C399.387848,816.128662 399.761688,815.556580 400.406860,814.751953 
	C401.153992,814.023865 401.742340,813.656616 402.660645,813.154480 
	C404.336792,812.591492 405.596283,811.956177 407.179382,811.138000 
	C408.546570,810.780945 409.539062,810.841553 410.818726,811.475220 
	C411.810089,812.629883 411.696503,813.587708 411.007233,814.856445 
	C408.920471,816.473022 407.239227,818.033752 405.229492,819.730957 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M390.652039,831.010376 
	C390.862610,832.398315 390.031097,832.984619 388.344910,832.987671 
	C388.136597,831.601746 388.967072,831.014404 390.652039,831.010376 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M397.691162,825.056519 
	C397.892609,825.889954 397.606354,826.646851 396.307495,826.942200 
	C396.104645,826.107849 396.392059,825.351746 397.691162,825.056519 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M143.707474,731.216064 
	C144.997650,730.077393 144.924820,728.873596 145.007812,727.359009 
	C146.737747,726.317566 148.449783,725.631714 150.517273,724.928040 
	C151.941971,725.565430 152.175461,726.627136 152.229446,728.172546 
	C152.129105,728.847595 152.058746,729.111633 151.806885,729.737854 
	C151.455215,730.304138 151.285049,730.508240 150.806900,730.954224 
	C147.215714,734.919922 147.275589,738.896729 149.197678,743.993835 
	C151.850967,751.029724 155.607407,757.659302 156.968124,765.207764 
	C157.516556,768.250183 159.184158,771.064026 160.469894,773.888794 
	C164.569427,782.895386 167.531723,792.335083 171.322372,801.462708 
	C175.446671,811.393738 181.787735,818.537415 191.829224,822.364197 
	C194.804581,823.498230 197.416000,825.313354 200.068054,826.999207 
	C204.344849,829.717896 208.835159,831.623413 214.331726,831.952881 
	C223.654175,836.238342 232.234131,841.228760 242.110657,843.180420 
	C250.717896,844.881348 259.323273,844.117188 267.957123,843.903442 
	C275.604340,843.714172 283.113647,842.605042 290.605377,841.221863 
	C291.420929,841.071228 292.331055,841.232239 293.075684,840.926758 
	C307.919952,834.839111 324.110718,833.598267 339.114380,828.289001 
	C350.059662,824.415833 361.484924,822.714111 372.492035,819.355530 
	C378.013824,817.670715 383.796661,816.883850 389.105652,814.542175 
	C390.881714,813.758728 392.559204,813.415283 394.719879,814.418884 
	C396.333069,817.302429 394.482605,818.518921 392.584686,819.104065 
	C385.664734,821.237244 379.065887,824.190491 372.008270,826.096680 
	C363.710510,828.337769 354.994537,828.985474 346.622284,832.478516 
	C340.405579,833.167908 335.410797,836.356995 329.793365,837.727905 
	C320.666351,839.955383 311.934601,843.494263 303.029816,846.443237 
	C294.781036,849.174866 285.970734,850.129272 277.221375,850.452026 
	C271.738586,850.654358 266.328583,851.128601 260.885193,851.911499 
	C256.369171,852.560974 251.659821,851.563110 246.656738,852.417236 
	C245.704208,852.401123 245.164169,852.350586 244.237305,852.161377 
	C239.893326,849.617065 235.147049,850.932007 230.912582,849.147461 
	C225.952881,847.057312 221.182922,844.242493 215.298279,843.468994 
	C213.860153,843.141602 212.939240,842.620972 211.732758,841.799561 
	C207.419891,837.903259 202.081436,836.843994 197.479843,834.361389 
	C190.217194,830.443054 182.046951,828.154114 175.769333,822.469421 
	C168.637405,816.010986 163.516785,808.537537 161.513687,798.742981 
	C159.834335,790.531311 157.811310,782.167480 152.111267,775.419861 
	C150.711731,773.763123 149.728333,771.777283 149.294556,769.635681 
	C148.129410,763.883301 145.514725,758.821716 142.852158,753.615295 
	C139.192215,746.458618 140.512451,738.816162 143.707474,731.216064 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M152.897629,721.385925 
	C153.931870,720.836243 154.669327,720.481873 156.008896,720.336914 
	C156.742020,720.770569 156.873032,720.994873 157.118225,721.592041 
	C157.383484,723.843323 157.345230,725.639282 154.860046,726.558350 
	C153.415421,726.383484 152.696732,725.722473 151.972702,724.519043 
	C151.525314,723.159180 151.972183,722.342529 152.897629,721.385925 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M151.887634,723.725952 
	C153.071930,724.112915 153.714615,724.531250 154.007599,725.748901 
	C154.664795,726.776855 154.822327,727.595154 154.367310,728.869812 
	C153.357101,729.766113 152.528519,729.820068 151.310455,729.215637 
	C150.955109,727.882385 150.952240,726.802490 150.911057,725.316406 
	C150.872757,724.910217 150.938904,724.447021 150.962402,724.214233 
	C151.187088,723.808411 151.388275,723.635254 151.887634,723.725952 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M158.770416,717.037720 
	C159.315826,716.681152 159.715775,716.556213 160.407394,716.314758 
	C160.977707,716.214539 161.256332,716.230774 161.882782,716.480835 
	C163.485199,719.059448 162.469940,720.394775 159.834259,721.267944 
	C159.154327,721.345886 158.880508,721.345886 158.201294,721.295044 
	C157.541397,721.149719 157.286880,721.055359 156.686935,720.745544 
	C156.341492,720.530029 156.170685,720.291992 156.094009,720.167603 
	C156.528397,719.514709 157.039459,718.986206 157.710602,718.230103 
	C158.026215,717.659424 158.272827,717.410095 158.770416,717.037720 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M152.280289,723.706177 
	C151.985840,723.993469 151.785889,723.997131 151.285919,723.991089 
	C151.043549,722.812744 150.911591,721.543030 152.836395,721.184082 
	C152.921188,721.934570 152.647980,722.678528 152.280289,723.706177 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M144.955841,726.640991 
	C142.607651,726.477905 143.080688,725.462830 144.741592,724.153381 
	C145.002411,724.765381 144.990463,725.515686 144.955841,726.640991 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M158.574387,716.807312 
	C158.732697,717.119751 158.549515,717.433289 158.118515,717.874695 
	C157.567795,717.845520 157.322418,717.624634 156.999374,717.125610 
	C157.320419,716.877075 157.776657,716.842773 158.574387,716.807312 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M143.718475,730.900757 
	C143.919556,737.143860 141.343948,743.229431 142.912827,749.547424 
	C142.992172,749.866882 142.853287,750.323425 143.031891,750.523376 
	C149.807678,758.109619 148.887970,769.175720 155.234619,777.095825 
	C158.468826,781.131836 160.215698,786.132019 161.165802,791.536133 
	C163.389053,804.181702 168.464859,815.607178 179.175446,823.485901 
	C182.591766,825.999023 186.789078,827.476074 190.687302,829.302734 
	C197.097366,832.306519 203.551178,835.217102 209.996475,838.145020 
	C210.971085,838.587830 211.641373,839.233948 211.958130,840.634521 
	C209.500534,843.042175 206.314621,842.337158 204.593475,840.850159 
	C199.721024,836.640503 193.071564,837.040161 187.951843,833.557129 
	C185.285278,831.742981 182.251648,830.180176 178.680222,829.148621 
	C176.409378,827.964539 176.185608,825.377319 173.853943,824.291870 
	C172.452148,823.729919 171.350555,823.344116 170.021896,822.591919 
	C169.569092,822.201721 169.410812,822.019470 169.071503,821.522888 
	C166.809006,816.380432 162.522690,812.845215 160.756561,807.394531 
	C157.770142,802.186218 154.902313,797.413757 154.001801,791.626526 
	C153.369202,787.561035 151.658463,783.705017 149.593353,780.030212 
	C143.499573,769.186279 140.494751,757.241943 137.771515,745.248596 
	C136.539612,739.823059 138.772217,735.196228 142.646225,730.993774 
	C143.025208,730.868774 143.487671,730.884705 143.718475,730.900757 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M142.897461,730.677063 
	C137.922119,738.702698 139.767197,746.435669 141.803711,754.987610 
	C144.624313,766.832031 151.306290,776.914551 154.712006,788.314758 
	C156.582565,794.576111 158.250320,800.791077 161.937744,806.651123 
	C161.909317,808.585449 162.652222,810.242493 161.334717,812.043823 
	C156.197708,812.673645 154.762299,809.184326 154.101654,805.539368 
	C152.874512,798.768982 149.900726,792.770874 147.282333,786.504028 
	C144.103851,778.896606 140.374146,771.437561 138.062134,763.504395 
	C136.947861,759.681091 135.547714,755.965698 134.420731,752.154724 
	C132.689407,746.299927 132.495102,740.720825 136.746796,735.318542 
	C137.013824,735.019714 137.000610,735.001770 137.010010,735.007080 
	C137.535233,734.463928 138.070053,733.933960 138.795746,733.195862 
	C139.000519,732.556458 139.004074,732.115173 139.010834,731.342896 
	C139.673996,730.334717 140.309021,729.614014 141.702469,729.802368 
	C142.315704,730.068665 142.564194,730.247131 142.897461,730.677063 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M141.948471,729.621033 
	C141.233658,730.279846 140.443039,730.609619 139.333221,730.975708 
	C139.012177,730.262634 139.010330,729.513428 139.012283,728.390259 
	C140.140671,728.055481 141.348404,727.908630 141.948471,729.621033 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M229.290359,393.978455 
	C226.013977,392.763000 223.348480,391.667603 222.739471,396.682434 
	C222.464859,398.943604 219.970734,400.770264 217.974182,399.075104 
	C215.259155,396.770050 214.335876,397.756317 212.989059,400.154266 
	C212.389694,401.221436 210.922195,400.956970 209.387543,400.998627 
	C208.278351,401.756714 206.476700,402.328003 208.779510,403.783325 
	C210.206482,404.429749 211.454727,406.423157 212.363586,405.545105 
	C216.281662,401.759766 220.269608,405.047455 224.595016,404.999756 
	C224.999390,405.000000 225.399475,404.762512 225.399475,404.762512 
	C225.399475,404.762512 225.384949,404.295135 225.192474,404.147583 
	C226.691589,399.857910 229.599060,398.150757 234.021942,399.515900 
	C235.131210,399.858276 236.547348,400.453064 237.222763,399.391357 
	C238.191986,397.867798 236.486984,397.181274 235.297455,396.158417 
	C234.621582,395.613678 234.474426,395.153381 234.779221,394.309570 
	C237.342010,391.883270 238.389206,394.066376 239.844620,395.705933 
	C240.780853,396.787079 241.670364,397.488647 242.140030,398.894714 
	C242.450668,399.922089 242.448730,400.749908 243.467865,401.333435 
	C244.635071,401.465881 245.468353,401.736816 246.658386,401.966705 
	C248.372025,402.159180 250.013885,401.522125 250.918350,403.649902 
	C250.505508,407.441193 247.011124,406.425446 244.836365,408.010651 
	C243.758041,408.261841 243.030914,408.274139 241.957764,408.213257 
	C240.555099,408.037201 239.475937,407.710724 238.239532,408.708740 
	C236.926224,409.396942 235.813660,409.636383 234.367096,409.932556 
	C230.239578,409.668243 226.562469,410.622314 222.522385,411.088135 
	C220.948425,411.692932 219.657257,412.075531 218.012878,412.413269 
	C216.144241,412.491058 215.400330,413.400299 214.971375,415.112854 
	C214.513504,416.025909 214.136505,416.594116 213.409286,417.328644 
	C212.359009,418.064575 211.467834,418.268738 210.231201,418.464447 
	C208.979752,418.485992 208.225769,418.932037 207.156799,419.500183 
	C206.385895,419.694702 205.934921,419.670624 205.189484,419.386963 
	C204.798294,418.996826 204.704849,418.784698 204.806015,418.284485 
	C203.644882,415.369995 201.429337,413.281097 203.566513,409.561829 
	C204.594254,407.773285 201.675415,405.532501 199.254379,405.081757 
	C194.938675,404.278259 195.741913,401.039093 196.230530,398.458405 
	C196.854675,395.162018 199.023331,396.048492 201.146362,397.325592 
	C201.722794,397.672333 202.806412,397.556641 203.498093,397.269623 
	C208.208786,395.315155 212.847122,393.645569 215.759964,388.630096 
	C218.601532,383.737305 219.924774,379.473663 216.047791,374.364685 
	C218.617233,373.905457 221.299438,374.162598 220.898621,377.631531 
	C220.500168,381.079865 222.181381,382.853943 224.909515,384.641174 
	C224.880661,385.287323 224.753662,385.764801 224.871231,385.812592 
	C225.134888,385.919861 225.415939,385.687408 225.796661,385.206970 
	C227.770844,385.260742 230.983704,384.138855 228.659210,388.098938 
	C227.324860,390.372162 230.577103,390.678864 230.937637,392.670746 
	C230.633743,393.475677 230.185089,393.829956 229.290359,393.978455 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M197.776215,265.227295 
	C199.327271,264.277283 200.731750,263.884857 202.627808,264.001709 
	C205.814331,265.462616 206.182251,263.468719 206.778595,261.242126 
	C208.018433,260.518311 208.736679,259.791443 208.965851,258.318970 
	C209.476822,258.114471 210.179382,258.066071 210.393021,258.365967 
	C212.592545,261.453491 214.367264,262.458588 216.543457,258.134125 
	C217.745209,255.746033 220.083755,255.360748 222.193008,257.662903 
	C223.004654,258.548798 224.042557,258.995514 225.629776,258.991577 
	C226.101791,262.189209 223.936142,262.823517 221.432816,262.968384 
	C219.619690,263.073334 217.707855,262.791473 216.261703,264.209839 
	C215.404297,265.050781 213.218246,264.609161 213.628647,266.556427 
	C213.910141,267.891937 215.339630,267.892609 216.508575,268.017822 
	C217.293961,268.101929 218.356552,268.004303 218.412247,269.123657 
	C218.473892,270.362732 217.435822,270.801483 216.435165,270.902252 
	C214.253326,271.121948 211.935684,274.012268 209.851044,270.382538 
	C209.365738,269.537476 208.208694,270.915802 207.264099,271.795288 
	C203.481186,272.035431 200.030701,270.618134 196.423096,271.736481 
	C195.298813,272.085022 194.339767,270.971191 193.996521,269.360474 
	C194.000732,268.833344 194.000854,268.666656 194.001099,268.250000 
	C194.675156,266.440643 196.654922,266.701874 197.776215,265.227295 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M193.658600,269.076019 
	C195.111450,269.784393 195.987793,271.766876 197.640167,270.483215 
	C201.079819,267.811157 203.701096,269.596466 206.690948,271.834473 
	C207.000290,272.749725 206.999985,273.499420 206.999542,274.623962 
	C205.052032,275.001129 203.104294,275.029236 201.157394,275.000458 
	C196.941422,274.938171 194.451721,277.352753 192.345062,280.918396 
	C191.833389,281.127960 191.666763,281.255951 191.250671,281.191345 
	C190.729233,278.651794 186.736893,275.813416 192.294998,274.239136 
	C194.171753,273.707611 190.546265,270.443420 193.658600,269.076019 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M180.035156,320.682098 
	C178.769394,321.570587 177.462189,322.265991 176.526962,320.651306 
	C175.795181,319.387756 175.895203,318.153290 177.066284,317.060425 
	C179.447128,314.838562 180.330276,313.220032 175.710983,313.792206 
	C174.444229,313.949097 173.287430,313.290863 173.486572,311.800323 
	C173.674789,310.391602 174.897858,309.906616 176.127350,310.283875 
	C178.585892,311.038239 180.963074,311.272125 183.445007,310.368896 
	C184.903870,309.838013 186.709412,309.676056 187.351730,311.385223 
	C188.098755,313.372925 186.830032,314.873840 185.056519,315.751343 
	C184.069977,316.239441 182.664917,315.916718 182.080383,317.654968 
	C180.982712,318.482086 180.263611,319.208679 180.035156,320.682098 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M255.170227,246.844482 
	C253.037064,245.989594 251.335098,245.979141 249.496872,246.626724 
	C247.842865,247.209396 246.420807,246.551880 246.259003,244.678955 
	C246.096222,242.794678 247.556000,241.740555 249.119644,242.172760 
	C253.258255,243.316788 257.280060,244.315140 261.650543,243.253128 
	C262.978058,242.930542 264.138855,244.409683 263.717682,245.936523 
	C263.271088,247.555588 262.237762,249.231812 260.206329,248.330795 
	C258.681580,247.654541 257.270630,246.882263 255.170227,246.844482 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M204.667236,417.997803 
	C205.000610,417.996338 205.002808,418.488892 205.001816,418.735168 
	C203.989532,423.128754 199.892776,425.484894 193.324677,425.259583 
	C193.122559,422.718811 192.018341,419.808777 196.575607,420.656860 
	C198.355042,420.988007 200.513184,419.284058 202.749588,418.253967 
	C203.444855,418.000305 203.889343,417.999756 204.667236,417.997803 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M226.385513,258.988464 
	C226.000580,258.670349 225.968002,258.336670 226.007385,258.011719 
	C226.190628,256.499359 225.437271,254.475143 227.950165,254.183167 
	C228.853256,254.078217 229.614105,254.528641 229.770996,255.460846 
	C230.159515,257.769257 228.966370,258.823761 226.385513,258.988464 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M169.270416,322.134155 
	C170.609192,318.263611 172.445480,320.924408 173.766434,322.066040 
	C175.483948,323.550415 173.848053,323.787933 172.332123,323.991272 
	C171.180084,323.423157 170.360168,322.846344 169.270416,322.134155 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M223.614014,370.989166 
	C222.453568,374.595459 219.719833,370.810699 217.393356,371.999390 
	C218.479721,368.017822 221.154861,371.333435 223.614014,370.989166 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M184.000076,364.000000 
	C184.567291,363.990845 185.511810,363.775360 185.638489,364.007019 
	C186.310593,365.236298 185.414062,366.000824 184.272308,366.853760 
	C184.000610,367.000000 184.000000,367.000000 183.999695,366.999390 
	C183.421234,366.313446 183.268219,365.541779 183.764465,364.343567 
	C183.999390,364.001221 184.000000,364.000000 184.000076,364.000000 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M204.994690,256.663696 
	C203.447571,256.744598 202.663925,256.054596 203.766907,254.212326 
	C204.814835,254.571533 204.982666,255.416702 204.994690,256.663696 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M274.341370,252.413071 
	C273.626770,252.611893 273.366730,252.362411 273.478241,251.807098 
	C273.501404,251.691833 273.665222,251.537140 273.781830,251.519897 
	C274.342560,251.437012 274.577271,251.709976 274.341370,252.413071 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M215.715347,372.047668 
	C215.999557,372.444458 215.999725,372.888885 215.999634,373.666687 
	C215.371231,373.893555 214.618576,373.827728 214.578491,373.042603 
	C214.563171,372.742157 215.129852,372.411987 215.715347,372.047668 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M168.073608,416.660645 
	C169.699173,414.885010 170.903931,415.524200 172.241974,416.521667 
	C174.617935,418.292938 177.078476,419.950775 179.937256,421.960938 
	C177.339203,423.513306 174.481567,423.785187 172.586258,426.126434 
	C171.099731,427.962769 169.685226,426.312469 169.022003,424.548065 
	C168.453720,423.036163 167.593384,421.456146 165.844879,422.551239 
	C164.293137,423.523102 165.808670,424.829712 166.284088,426.000732 
	C168.291122,430.944183 166.562347,435.288879 161.755112,437.465698 
	C160.518890,438.025482 159.054214,438.513184 158.369354,436.813690 
	C157.831253,435.478424 158.851807,434.672089 160.008362,434.178741 
	C160.618988,433.918274 161.274841,433.762939 161.882706,433.497162 
	C163.206146,432.918457 164.140991,432.038818 163.852417,430.435089 
	C163.572205,428.877777 162.723175,428.070251 161.043701,428.490570 
	C158.419815,429.147156 158.013000,427.018402 157.337845,425.407867 
	C156.542709,423.511139 157.692749,422.523712 159.123062,421.328888 
	C161.708511,419.169067 163.576675,415.895233 168.073608,416.660645 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M192.760376,425.013977 
	C197.113739,423.682556 201.505997,422.962219 204.837799,419.291107 
	C205.442856,418.980957 205.884888,418.980408 206.658417,418.974487 
	C207.534729,418.450226 208.077057,417.928772 208.832642,417.222290 
	C210.095108,417.020203 211.148178,417.007233 212.596085,416.986115 
	C214.410995,415.808685 215.919144,415.475037 217.837357,416.792969 
	C218.422409,419.628479 216.499222,419.984192 214.773178,420.355225 
	C212.974304,420.741943 211.360474,421.348846 209.889587,423.070190 
	C209.411362,423.480347 209.202469,423.622131 208.650681,423.920563 
	C207.599548,424.307007 206.873535,424.449005 205.775665,424.529968 
	C198.885223,427.399536 193.559265,431.984558 188.205017,436.536865 
	C186.844711,437.693451 185.623718,439.128418 183.329544,439.150238 
	C183.007751,439.005859 182.979675,438.980499 182.965057,438.966278 
	C181.853317,434.839813 185.108032,432.585602 186.947937,429.350586 
	C187.976089,427.117401 190.013107,426.605652 191.797272,425.240173 
	C192.035873,425.020447 192.518936,425.019897 192.760376,425.013977 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M136.069427,425.654663 
	C134.597427,428.003754 135.229507,432.117279 130.408127,430.999634 
	C129.965179,427.598236 130.658234,424.241821 130.108490,420.780212 
	C129.712875,418.289001 130.950134,416.581787 133.949875,417.482971 
	C134.263870,417.577301 134.582382,417.657684 134.893066,417.761627 
	C136.838013,418.412476 139.630463,415.144867 140.813675,418.716400 
	C141.963242,422.186340 138.579086,422.932739 136.630951,424.476898 
	C136.395142,424.663818 136.299469,425.027588 136.069427,425.654663 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M159.664764,402.079651 
	C160.578156,400.236359 158.810806,396.961670 162.191910,397.406525 
	C164.474777,397.706879 167.185471,398.683289 167.015198,402.079041 
	C166.982407,402.732849 167.040985,403.421478 167.215988,404.049835 
	C167.744904,405.949127 168.801712,407.970306 166.607681,409.437469 
	C164.992111,410.517761 163.170715,410.041382 162.194351,408.555939 
	C160.976791,406.703552 156.702454,406.027679 159.664764,402.079651 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M158.791107,413.147339 
	C158.694138,413.975616 158.662033,414.537994 158.374298,414.893402 
	C156.857559,416.766907 155.010757,418.100800 152.444656,417.775360 
	C151.504501,417.656097 151.016357,416.830017 151.147400,415.955811 
	C151.539429,413.340332 151.791183,409.861847 153.802246,408.762665 
	C156.943878,407.045593 157.158493,411.242981 158.791107,413.147339 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M157.553955,381.653534 
	C158.326462,379.667542 159.626404,378.224487 160.448868,380.641632 
	C161.273102,383.063934 162.300201,385.461182 162.903763,387.964203 
	C163.124710,388.880524 163.112045,390.013275 162.053955,390.560211 
	C160.998642,391.105743 160.032715,390.602600 159.528717,389.713654 
	C158.177612,387.330536 153.945129,385.693054 157.553955,381.653534 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M161.610901,456.002197 
	C160.177475,458.371490 158.400162,460.757233 157.191208,463.533752 
	C156.361069,465.440308 155.088333,466.952789 152.368958,466.990051 
	C153.681580,464.140198 156.077545,461.730072 156.923508,458.339325 
	C157.433960,456.293243 159.302399,455.898804 161.610901,456.002197 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M144.947052,410.009705 
	C146.376556,411.003754 147.625534,412.160522 146.280212,413.314575 
	C144.340332,414.978729 142.293411,412.960663 140.272079,412.742126 
	C139.341995,412.641602 138.925110,411.569427 139.452026,410.852539 
	C140.796768,409.022980 142.601181,408.689911 144.947052,410.009705 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M147.671265,424.837738 
	C142.547882,423.222107 146.554779,420.301483 146.430710,417.997681 
	C146.393433,417.305206 148.216354,416.956024 148.315674,417.748108 
	C148.605743,420.061432 151.334717,422.599457 147.671265,424.837738 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M191.631271,425.013428 
	C191.776627,427.640808 189.704483,428.238159 187.347687,428.951660 
	C186.426147,428.181824 185.579956,428.016632 184.333466,428.003418 
	C184.103714,421.434692 188.855087,426.094391 191.631271,425.013428 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M159.888367,447.926056 
	C158.274750,448.673706 156.670700,449.787811 156.291565,447.308441 
	C156.097443,446.039001 157.228348,445.217468 158.479874,445.158783 
	C160.302277,445.073273 161.488983,445.626434 159.888367,447.926056 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M121.011925,450.383972 
	C121.750305,450.000000 122.500008,450.000000 123.624542,450.000000 
	C123.712502,451.833344 126.346924,451.652893 126.384262,453.592163 
	C123.699921,454.195282 121.440956,454.377808 121.011925,450.383972 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M111.992256,424.638733 
	C109.989326,424.833191 109.040535,423.699066 109.007721,421.362396 
	C111.011665,421.166992 111.959221,422.302155 111.992256,424.638733 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M127.244629,474.706665 
	C127.368317,473.375000 127.724121,472.414093 128.972519,472.528900 
	C129.756622,472.600983 129.982224,473.351044 129.737366,474.010315 
	C129.317093,475.141998 128.760757,476.230133 127.244629,474.706665 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M117.044128,417.330627 
	C118.066673,417.000000 119.133339,417.000000 120.600006,417.000000 
	C120.897652,418.159515 120.572632,419.316650 119.324852,419.567413 
	C117.984261,419.836792 117.393188,418.821960 117.044128,417.330627 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M165.651154,449.081909 
	C165.841141,450.372040 166.477844,452.014099 164.349335,452.917999 
	C164.158325,451.627563 163.521622,449.985565 165.651154,449.081909 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M146.460266,460.405762 
	C146.420898,461.763763 145.716721,461.997192 144.869003,461.603119 
	C144.229782,461.306000 143.997040,460.538391 144.380508,459.972656 
	C145.011612,459.041595 145.726212,459.507751 146.460266,460.405762 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M131.278992,450.118988 
	C131.570755,449.185059 132.416977,449.016663 133.665680,449.004303 
	C133.829910,450.682129 132.962723,450.989502 131.278992,450.118988 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M116.955887,457.533936 
	C116.306000,457.175934 116.296745,456.816498 116.763161,456.499481 
	C116.860695,456.433167 117.086418,456.436096 117.181190,456.505402 
	C117.634590,456.836853 117.618073,457.195953 116.955887,457.533936 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M129.586792,458.341431 
	C129.387985,457.626678 129.637512,457.366547 130.192963,457.478119 
	C130.308212,457.501312 130.462952,457.665192 130.480179,457.781830 
	C130.563080,458.342682 130.290039,458.577454 129.586792,458.341431 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M127.000305,412.000000 
	C126.710533,412.481598 126.420471,412.963196 126.130402,413.444794 
	C125.935898,413.278870 125.741386,413.112946 125.546875,412.947021 
	C125.853073,412.659180 126.159271,412.371307 126.732727,412.041138 
	C127.000000,411.998779 127.000000,412.000000 127.000305,412.000000 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M128.732758,415.041992 
	C128.710175,415.481537 128.420334,415.963104 128.130493,416.444641 
	C127.935997,416.278778 127.741508,416.112885 127.547012,415.947021 
	C127.853165,415.659332 128.159317,415.371643 128.732758,415.041992 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M145.945511,476.686493 
	C145.454041,476.818909 144.908691,476.639008 144.181671,476.229553 
	C144.000153,475.833344 144.000290,475.666656 144.001129,475.250000 
	C144.887238,475.106720 145.643997,475.390381 145.945511,476.686493 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M197.587570,265.000732 
	C198.167084,267.340027 196.929779,268.164917 194.386078,267.997955 
	C192.524780,266.053619 190.467377,265.844147 188.238144,265.983307 
	C186.218246,266.109406 184.010635,266.177307 183.501190,263.586609 
	C183.184372,261.975555 183.494217,259.801361 185.216507,259.436676 
	C187.265228,259.002899 189.798767,259.661682 190.743088,261.781647 
	C192.108078,264.845978 194.359558,265.278351 197.587570,265.000732 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M146.506317,298.393890 
	C148.921356,294.877380 151.310043,296.150604 152.323944,298.694214 
	C153.515076,301.682404 155.302185,302.219635 158.007751,302.042633 
	C159.105698,301.970795 160.585358,301.861603 160.648819,303.531525 
	C160.704819,305.005066 159.363846,305.415558 158.247940,305.613953 
	C155.142029,306.166260 147.862946,301.877106 146.506317,298.393890 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M167.234192,296.252258 
	C170.243195,294.472412 174.194504,294.562744 176.924347,291.344696 
	C180.225952,290.806732 180.037918,293.054413 179.999741,295.597595 
	C179.010345,296.906250 177.719131,297.163177 176.258667,297.808289 
	C172.761841,299.743500 170.169586,297.813263 167.234192,296.252258 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M145.775635,261.231812 
	C150.705765,261.333191 155.411545,261.666412 160.558655,261.999207 
	C158.484558,264.213104 156.391769,268.225677 152.427872,263.725281 
	C151.578827,262.761322 150.286469,264.029694 149.176834,263.944061 
	C147.498474,263.814514 145.333496,264.373077 145.775635,261.231812 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M174.328430,252.844513 
	C171.065811,250.180191 171.714142,245.596375 175.634933,244.009308 
	C175.603104,246.905075 179.297577,250.441071 174.328430,252.844513 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M206.639771,261.008606 
	C207.057907,261.639038 207.158813,262.276642 207.162079,262.914734 
	C207.168839,264.231018 209.768341,265.385742 207.903961,266.584229 
	C205.989639,267.814850 204.231400,266.765686 203.152634,264.324982 
	C203.166946,261.989563 204.301331,261.040070 206.639771,261.008606 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M184.531189,279.292053 
	C183.731094,278.118225 183.089020,277.148163 182.723572,276.083160 
	C182.341476,274.969666 183.206390,274.288849 183.998505,273.688721 
	C184.705124,273.153381 185.564301,272.941559 186.251968,273.512909 
	C187.164398,274.271027 186.867279,275.341400 186.392441,276.202301 
	C185.807388,277.263000 186.584137,278.989380 184.531189,279.292053 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M140.341187,252.041382 
	C140.443985,249.825180 142.034378,248.905319 143.939072,249.326935 
	C146.554108,249.905807 145.858261,252.320175 145.999847,254.594452 
	C144.254654,253.978027 142.766586,252.487061 140.341187,252.041382 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M184.837616,254.499954 
	C184.528885,253.985809 184.345078,253.576782 184.477356,253.368790 
	C185.287796,252.094574 183.521255,250.378128 185.124496,249.354919 
	C185.654678,249.016556 186.615158,249.399902 186.657379,250.084930 
	C186.762054,251.783310 188.244965,254.060547 184.837616,254.499954 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M178.497375,262.411499 
	C178.525986,264.062775 178.301376,265.533264 176.597992,265.459137 
	C175.561646,265.414093 174.951385,264.295776 175.221390,263.231415 
	C175.672470,261.453339 176.649292,260.631226 178.497375,262.411499 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M176.274994,298.117981 
	C176.255493,295.981506 177.763885,296.004517 179.628571,296.001465 
	C180.000000,296.000000 180.000137,296.500000 180.000366,296.750061 
	C178.969482,297.742676 178.275391,299.425842 176.274994,298.117981 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M190.672516,281.068298 
	C191.001221,280.998779 191.500610,280.999512 191.750305,280.999756 
	C191.904694,282.120544 192.140427,283.463379 190.561707,283.726654 
	C190.211533,283.785004 189.582962,283.388763 189.406433,283.038605 
	C188.934265,282.101990 189.492615,281.510284 190.672516,281.068298 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M180.000305,297.000000 
	C181.146393,296.654053 182.270264,295.356262 183.449234,296.815002 
	C183.618515,297.024445 183.389435,297.800415 183.110657,298.084473 
	C181.978531,299.238098 181.095673,298.541077 180.154053,297.280762 
	C180.000610,297.000153 180.000000,297.000000 180.000305,297.000000 
z"/>
<path fill="#C2C0BF" opacity="1.000000" stroke="none" 
	d="
M191.412872,254.658875 
	C191.611481,255.372986 191.362259,255.632904 190.807220,255.521332 
	C190.692062,255.498199 190.537445,255.334473 190.520233,255.217911 
	C190.437424,254.657532 190.710251,254.423035 191.412872,254.658875 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M137.658630,307.586914 
	C138.373184,307.388184 138.633316,307.637512 138.521698,308.192932 
	C138.498550,308.308167 138.334717,308.462891 138.218094,308.480133 
	C137.657333,308.563019 137.422653,308.290039 137.658630,307.586914 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M167.341400,304.413208 
	C166.626694,304.611938 166.366531,304.362579 166.478180,303.807037 
	C166.501358,303.691772 166.665207,303.537018 166.781845,303.519806 
	C167.342560,303.437012 167.577484,303.709900 167.341400,304.413208 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M155.882767,255.812836 
	C155.214676,255.290634 154.839752,254.932037 154.393112,254.763062 
	C148.596924,252.570251 148.748138,252.728333 152.892609,248.514984 
	C154.906845,246.467300 155.805130,247.172775 156.849945,249.083633 
	C158.538849,252.172424 158.268494,254.667023 155.882767,255.812836 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M133.643234,248.874329 
	C131.953964,248.970200 130.433655,249.203247 129.733536,247.788879 
	C128.842896,245.989655 130.769791,245.501770 131.668884,244.884781 
	C133.414307,243.687012 132.326126,239.593262 136.028351,240.600159 
	C137.121536,240.897476 138.735703,241.567749 138.505157,242.693237 
	C137.972214,245.295090 136.581818,247.561203 133.643234,248.874329 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M186.954376,235.645203 
	C185.933487,236.000153 184.866959,236.000290 183.400528,236.000519 
	C183.289032,234.528931 182.160110,232.177444 183.931732,231.749359 
	C186.133530,231.217331 186.193619,233.820984 186.954376,235.645203 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M133.525818,253.555267 
	C133.061111,253.848343 132.444077,254.065750 132.366226,253.942520 
	C132.007202,253.374222 132.152267,252.770462 132.834000,252.570007 
	C133.007812,252.518906 133.354553,253.055908 133.525818,253.555267 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M183.628540,363.991760 
	C183.999481,364.750610 183.999573,365.500000 183.999527,366.624084 
	C181.440277,367.175354 179.875595,365.727692 178.287064,363.959961 
	C180.266800,362.379211 181.700714,364.279541 183.628540,363.991760 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M140.102448,329.700195 
	C139.144623,328.975281 138.917953,328.204132 139.718063,327.617371 
	C140.288239,327.199249 140.993073,327.585388 141.310501,328.169006 
	C141.814026,329.094757 141.387207,329.636505 140.102448,329.700195 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M161.658600,339.586853 
	C162.373322,339.388031 162.633377,339.637512 162.521759,340.192963 
	C162.498611,340.308228 162.334763,340.463013 162.218140,340.480255 
	C161.657379,340.563110 161.422546,340.290131 161.658600,339.586853 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M157.533920,343.044128 
	C157.175873,343.693878 156.816437,343.703278 156.499496,343.236755 
	C156.433212,343.139191 156.436142,342.913544 156.505432,342.818726 
	C156.836899,342.365265 157.195999,342.382111 157.533920,343.044128 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M135.466263,443.729828 
	C136.002991,445.415894 136.123596,446.786896 134.070496,446.718842 
	C133.190704,446.689606 132.412720,446.046753 132.463440,445.143372 
	C132.561478,443.396942 133.665222,442.957489 135.466263,443.729828 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M162.240936,717.187256 
	C161.714996,716.842896 161.499634,716.738281 160.991669,716.416016 
	C160.922195,714.961182 161.332062,713.853455 162.967010,713.327148 
	C163.786102,713.456055 164.196457,713.763733 164.640167,714.570312 
	C164.807800,716.465332 164.331879,717.528137 162.240936,717.187256 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M164.113480,712.479126 
	C164.222107,712.213379 164.384277,712.171021 164.789124,712.061401 
	C165.206696,711.998962 165.381592,712.003845 165.818695,712.019165 
	C166.258743,712.429138 166.196671,712.795349 165.663315,713.283936 
	C165.195770,713.445007 164.992828,713.454956 164.528748,713.307739 
	C164.267578,713.150635 164.167038,712.702454 164.113480,712.479126 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M164.018417,713.164185 
	C164.446716,713.155029 164.625854,713.159485 165.072662,713.188477 
	C165.338135,713.400818 165.335938,713.588684 164.864838,713.948242 
	C164.080048,714.030884 163.804230,713.872192 163.424286,713.460144 
	C163.280045,713.276306 163.769257,713.177795 164.018417,713.164185 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M182.959793,439.353333 
	C189.738388,433.197723 196.471954,427.393524 204.714355,423.133453 
	C205.830475,424.022095 205.583252,424.954071 204.951782,426.198181 
	C198.856125,431.321747 192.805862,435.982361 188.298584,442.666840 
	C188.103409,443.204041 188.001678,443.408875 187.690430,443.883270 
	C187.136459,444.438477 186.764008,444.679871 186.065994,444.996216 
	C184.954590,445.417603 184.421677,445.974091 184.096008,447.123047 
	C183.632324,448.219818 183.085587,448.871796 182.208313,449.659546 
	C181.044022,450.378265 180.361389,451.090942 179.969757,452.440308 
	C178.334000,455.171204 177.062668,457.787262 174.432068,459.778198 
	C173.258286,460.585205 172.494202,461.297760 172.257965,462.784149 
	C172.083359,463.304688 172.000702,463.509430 171.762619,464.007996 
	C171.133636,465.047119 170.466354,465.667816 170.021301,466.838196 
	C167.786331,470.265656 166.712982,474.053711 163.356812,476.740234 
	C157.597458,479.314758 156.591736,485.154327 153.601089,489.390198 
	C149.276932,495.514771 146.862656,503.133575 145.785522,510.037781 
	C144.407745,518.869080 144.258057,528.204163 146.863403,537.598083 
	C149.904510,548.563171 154.185974,559.355835 155.045898,571.266846 
	C156.629837,580.583740 157.552719,589.786621 163.590958,597.195374 
	C166.158493,600.345642 166.534622,604.543457 169.047821,608.316956 
	C162.864487,608.390198 159.695770,604.119324 157.667831,599.080200 
	C155.326416,593.262207 153.248291,587.298584 151.365692,581.311462 
	C149.583603,575.643921 149.611557,568.848877 144.876648,563.841797 
	C142.772293,561.616516 143.853058,557.347290 143.455231,554.143677 
	C142.784836,548.745178 140.556396,543.990906 139.396774,538.865906 
	C138.850616,536.452087 137.823975,534.165710 137.959442,531.596741 
	C138.072693,529.449097 137.073013,528.113586 134.371948,528.037109 
	C132.968109,527.245850 131.515747,526.750549 133.186981,525.056885 
	C136.174911,522.028809 137.720551,518.610413 134.846893,514.587219 
	C134.274750,513.786255 134.724152,513.357483 135.383499,513.096313 
	C138.885086,511.709534 138.926407,508.701019 139.010086,505.638611 
	C139.122864,501.511566 141.649185,498.280609 143.621490,495.025177 
	C152.647079,480.128174 165.197617,467.745972 173.953384,452.573486 
	C176.515640,448.133484 179.821716,443.925629 182.959793,439.353333 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M345.271698,708.750488 
	C339.068970,705.019897 333.859619,699.867554 327.280151,696.992981 
	C322.528961,694.917175 317.173340,693.864136 313.009705,690.812195 
	C308.440582,687.463074 303.297546,685.466919 298.467926,682.769104 
	C294.018646,680.283875 289.559540,678.020813 284.586243,676.946899 
	C282.103973,676.410950 280.674011,674.403931 278.958282,673.010681 
	C272.360077,667.652893 263.701599,666.042969 257.203827,660.694397 
	C254.678864,658.615967 251.387589,659.743103 248.866135,657.894775 
	C243.691544,654.101685 238.048416,650.848633 233.711731,646.080200 
	C231.487930,643.635071 227.381729,644.801025 225.977051,641.373901 
	C217.718277,643.120361 213.986710,632.640991 205.873383,633.906433 
	C205.314636,633.993591 204.309052,633.645264 204.106064,633.217285 
	C201.365631,627.439758 194.881409,626.710754 190.565521,623.079834 
	C188.808990,621.602112 186.649597,620.741943 185.045853,618.909241 
	C183.666351,617.332825 181.456528,615.919006 179.334427,616.270447 
	C173.743515,617.196411 171.483017,613.420044 169.004150,609.366821 
	C168.991455,609.008240 168.999420,609.000549 169.000305,608.999023 
	C172.614136,607.061646 175.380264,610.231567 178.201065,611.165344 
	C183.016647,612.759521 187.967270,613.586792 192.376999,617.998779 
	C197.010254,622.634460 203.084793,627.240417 210.179749,629.153748 
	C215.809601,630.671936 220.922043,633.601929 226.761047,635.658569 
	C227.544327,636.014038 227.950195,636.284668 228.573639,636.859314 
	C232.284225,641.425049 237.122116,643.842163 242.115097,646.546997 
	C243.191544,646.596130 243.880341,646.782654 244.874451,647.216431 
	C252.990295,651.710266 259.376434,658.482605 268.736664,660.776733 
	C270.296173,661.143188 271.467804,661.521179 272.893829,662.265015 
	C275.566742,665.522827 278.527161,667.674255 282.154999,669.140869 
	C289.746643,672.210205 296.449982,677.192627 304.722809,679.679382 
	C305.310242,679.843994 305.539185,679.931335 306.088745,680.198730 
	C312.881256,686.906067 321.449219,689.447876 330.036560,692.745544 
	C330.604767,692.995850 330.822113,693.120483 331.329559,693.487061 
	C334.300079,697.744568 338.526672,699.767456 342.479492,702.137146 
	C344.907410,703.592651 346.888824,705.233032 345.271698,708.750488 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M345.117401,709.214905 
	C345.401245,706.115112 344.022369,704.546570 341.515808,703.139038 
	C337.598145,700.939087 332.858795,699.583008 331.007690,694.369629 
	C332.096130,692.828491 333.496887,692.883667 334.656219,693.589661 
	C339.770782,696.704285 344.494202,700.360840 348.629333,704.696472 
	C349.751465,705.873047 350.347290,707.541138 350.155457,709.646362 
	C350.000000,710.000000 349.997253,710.002502 349.993744,710.006104 
	C349.001007,710.835083 347.922821,711.071777 346.362701,710.250610 
	C345.697571,709.872986 345.443329,709.681335 345.117401,709.214905 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M346.009369,710.362061 
	C347.066040,709.999817 348.129608,710.002075 349.591736,710.007080 
	C348.905182,713.513611 352.879578,715.183655 352.985107,718.632568 
	C350.019104,716.791687 347.013184,714.605164 346.009369,710.362061 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M318.946716,755.609436 
	C317.205597,755.921753 315.328766,756.361755 313.259125,755.279297 
	C314.967316,754.222229 316.985901,752.877930 318.946716,755.609436 
z"/>
<path fill="#06ABCA" opacity="1.000000" stroke="none" 
	d="
M235.836258,723.743164 
	C236.487869,724.632751 236.875473,725.303650 237.261658,726.277100 
	C238.620087,729.476013 238.625473,731.935120 235.197998,733.418701 
	C232.340912,734.655396 231.776993,737.117615 231.826233,740.281616 
	C227.541595,743.726562 227.034088,749.488464 223.238220,752.693359 
	C216.846619,758.089905 213.186676,765.675476 207.501740,771.714294 
	C204.217804,775.202637 201.069626,780.557617 200.986755,786.371887 
	C200.962677,788.059814 200.650116,789.685486 199.046448,791.134399 
	C194.656998,789.669434 193.759415,785.557861 192.885986,782.258301 
	C190.610184,773.661011 188.259521,765.086426 185.722092,756.158630 
	C185.025436,751.500061 186.689957,748.451233 189.722305,745.276123 
	C194.072540,740.721191 198.264511,736.252930 203.563309,732.640137 
	C207.430359,730.003418 209.877914,725.503418 212.568558,721.534912 
	C216.118057,716.299683 220.017746,711.405701 225.649216,707.660278 
	C228.326111,707.547424 228.903702,709.340210 229.456375,711.020508 
	C230.317154,713.637573 231.525223,716.094604 232.983444,718.800415 
	C233.287750,719.020813 233.412079,719.239807 233.327301,719.332886 
	C233.732346,720.955139 234.281128,722.450745 235.836258,723.743164 
z"/>
<path fill="#071518" opacity="1.000000" stroke="none" 
	d="
M164.860077,714.542114 
	C165.168594,714.070679 165.203720,713.899292 165.289597,713.470459 
	C165.657776,713.072449 165.875687,712.838989 166.037476,712.271118 
	C166.259140,711.944031 166.437363,711.858459 166.872742,711.625122 
	C170.283157,711.275208 171.606506,713.379517 172.290131,715.898987 
	C177.445480,734.898865 185.053574,753.102783 190.386047,772.062866 
	C192.222458,778.592468 193.188202,785.654907 197.785034,791.574585 
	C198.315109,791.871033 198.539291,791.960632 199.083374,792.208618 
	C202.055435,794.847656 205.346283,796.400391 208.590668,798.607666 
	C209.106964,798.843689 209.310150,798.947449 209.806244,799.230103 
	C213.607544,801.586792 216.784302,804.347290 221.210297,805.590698 
	C222.033157,805.769287 222.486542,805.936279 223.248169,806.321777 
	C230.942200,811.698547 239.386765,814.548645 247.754761,817.569458 
	C249.746216,818.288330 251.979034,818.528687 253.699799,820.604980 
	C270.193756,824.192932 286.612701,825.889526 302.947540,822.702271 
	C323.472870,818.697449 343.807617,813.715820 364.607300,809.098755 
	C365.434906,809.020935 365.879608,808.996094 366.656891,808.937988 
	C367.415894,808.889648 367.842102,808.870544 368.585999,808.812622 
	C369.401611,808.502991 369.862244,808.155823 370.713806,808.000061 
	C372.715332,807.602844 374.433472,807.234619 376.526428,806.808838 
	C377.402557,806.486511 377.868683,806.147522 378.720001,805.993530 
	C380.038452,805.902161 381.073669,805.841003 382.493347,805.720459 
	C384.277710,804.894531 385.751343,804.326782 387.657898,803.983032 
	C388.422913,803.924194 388.849396,803.896057 389.594208,803.829224 
	C391.952820,802.763977 393.657318,800.794128 396.641571,801.731689 
	C401.661804,801.568359 405.667419,798.874207 410.608093,797.981750 
	C411.386902,798.805908 411.169830,799.575439 410.511719,800.649902 
	C397.349213,808.203857 383.162598,811.247253 368.382874,813.615356 
	C367.253326,813.557861 366.519714,813.600037 365.466461,813.984497 
	C364.423676,814.253418 363.715302,814.336853 362.658051,814.424927 
	C361.397705,814.464478 360.483368,814.481079 359.309204,815.001709 
	C358.540680,815.242615 358.091187,815.341125 357.292236,815.449707 
	C354.096954,816.395020 351.042175,816.467346 347.936371,817.743164 
	C347.203949,818.029724 346.775818,818.159546 346.022339,818.349426 
	C344.782135,818.481445 344.094940,819.055664 343.034302,819.627441 
	C342.285095,819.910583 341.835388,820.010132 341.024780,820.095337 
	C334.203156,820.485291 328.015961,822.098816 321.659943,824.536377 
	C320.681885,825.043030 319.978790,825.241577 318.894653,825.416138 
	C317.031372,825.509094 315.773956,826.282410 314.095215,826.976318 
	C313.264618,827.258057 312.772400,827.349548 311.892212,827.410767 
	C307.944611,827.010803 304.567688,828.493958 300.677551,828.507202 
	C299.332214,828.519104 298.465546,828.852600 297.366760,829.606812 
	C296.822601,829.874878 296.595215,829.966370 296.008301,830.150879 
	C294.646332,830.418213 293.644226,830.353577 292.261383,830.178589 
	C290.889038,829.825806 289.913757,829.446533 288.446777,829.617126 
	C285.245575,829.829102 282.445404,829.992493 279.224365,829.545532 
	C274.732513,829.503845 270.669128,829.452515 266.186035,829.508423 
	C261.633026,828.446838 257.439575,827.887207 252.805740,827.942200 
	C251.404205,828.337891 250.365677,828.333862 248.946655,828.127319 
	C248.097595,827.895203 247.639740,827.700684 246.895477,827.272156 
	C245.366592,825.775452 243.867584,825.601501 241.870514,826.025818 
	C241.045273,825.974243 240.586746,825.863403 239.834625,825.512878 
	C236.653152,821.757507 232.222733,821.721985 227.984833,820.383545 
	C227.129242,820.254639 226.654678,820.106750 225.856384,819.731201 
	C223.560837,817.380981 220.906631,816.504395 218.281647,815.481140 
	C208.409286,811.632629 201.625565,803.171997 192.512207,798.080994 
	C188.746262,795.977112 187.357895,791.226501 185.817368,787.208008 
	C181.723419,776.528809 178.725708,765.478577 175.148987,754.622375 
	C173.954025,750.995422 172.849228,747.393433 171.139160,743.929077 
	C167.882111,737.331055 167.422760,729.877869 165.328247,722.907654 
	C165.001587,721.820557 165.179291,720.585266 164.322342,719.211914 
	C163.994568,717.470825 164.630676,716.189880 164.860077,714.542114 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M198.989014,792.972900 
	C195.545685,792.548035 194.768951,789.860474 193.954849,787.367554 
	C186.256790,763.794922 178.615814,740.203735 170.963333,716.616272 
	C170.345947,714.713196 169.885529,712.719360 167.460907,711.634888 
	C171.560745,708.607239 176.786911,708.438904 181.701859,707.263000 
	C194.745972,704.142151 207.858948,701.303406 220.870407,698.055359 
	C225.037445,697.015137 227.132492,698.165100 227.896561,702.238037 
	C228.942703,707.814575 231.584366,712.859436 233.271942,718.624451 
	C228.777802,716.853210 229.785049,711.283081 226.354736,708.072876 
	C223.886642,704.408203 220.983383,704.468140 217.162125,705.002197 
	C204.987381,706.703552 193.202911,710.356506 181.014832,711.856995 
	C177.270279,712.318054 175.927124,713.947754 176.516724,717.801331 
	C177.152023,721.953613 178.182861,725.744934 180.312225,729.489990 
	C183.786606,735.600647 183.573151,742.906433 185.706741,749.505493 
	C186.209412,751.060242 186.208115,752.777405 186.761978,754.726196 
	C189.492661,764.045471 192.255707,772.965881 194.174393,782.136108 
	C194.804214,785.146301 196.878922,787.854126 198.598907,790.905457 
	C199.305481,791.567261 199.437912,792.091614 198.989014,792.972900 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M230.811493,741.159912 
	C230.651962,737.099976 231.281082,733.843384 235.453003,732.196838 
	C237.581146,731.356934 236.928467,729.169739 237.202484,727.017700 
	C238.838272,727.151245 239.233383,728.688843 240.071075,730.230347 
	C240.001465,730.824341 239.926468,731.287231 239.698318,731.382141 
	C232.611053,734.331970 232.151031,739.824402 233.921021,746.628601 
	C233.710495,747.165894 233.543381,747.278564 233.082443,747.315308 
	C232.457748,747.002258 232.165070,746.724976 231.734360,746.160645 
	C231.453796,745.740723 231.349670,745.567261 231.105377,745.124268 
	C230.703217,744.301758 230.498932,743.727722 230.295685,742.833191 
	C230.265900,742.113708 230.377319,741.726685 230.811493,741.159912 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M236.056030,723.616638 
	C233.755798,723.806152 233.016525,722.369812 233.189819,719.836792 
	C234.894760,720.182678 235.428406,721.744934 236.056030,723.616638 
z"/>
<path fill="#0F1523" opacity="1.000000" stroke="none" 
	d="
M433.249908,767.235352 
	C431.954437,767.470093 430.920807,767.972412 429.858276,768.403748 
	C427.328522,769.430786 425.382965,769.453003 424.780640,766.021423 
	C424.294495,763.251770 423.443970,760.539917 421.574158,758.289490 
	C420.713013,757.253052 419.695862,755.716553 418.420807,756.255493 
	C415.310913,757.569885 413.511505,755.422058 411.919373,753.839844 
	C407.812988,749.759338 402.549866,747.042725 398.868713,742.489868 
	C398.269531,741.748840 397.332062,740.977966 396.445251,740.822144 
	C390.864227,739.841003 385.233887,739.453857 379.845245,737.172852 
	C377.033234,735.982605 375.496429,739.583496 373.378235,741.044312 
	C370.878906,742.768066 369.603851,746.968323 365.076843,745.014221 
	C364.665558,743.999023 364.861786,743.327576 365.389465,742.383667 
	C367.664764,739.951965 370.178925,738.245789 372.101562,735.462891 
	C375.961670,731.669312 376.856567,726.502075 380.143036,722.368652 
	C380.633331,722.013000 380.844574,721.890930 381.397034,721.638672 
	C388.300720,715.132202 391.776276,714.664856 401.206665,720.110901 
	C408.265167,724.187195 414.997467,728.859741 422.132690,732.799927 
	C430.721161,737.542603 438.230927,744.019775 447.293915,747.993042 
	C451.826416,749.980042 453.332611,754.832458 451.590271,759.894775 
	C450.916443,761.852478 449.124390,763.640930 450.608765,766.328735 
	C450.835571,767.161743 450.865784,767.650269 450.738525,768.494751 
	C449.175323,771.215454 447.355682,773.310547 445.166260,775.543091 
	C444.156921,776.278442 443.374207,776.745850 442.517029,777.678467 
	C441.686462,778.328186 441.060303,778.627319 440.020081,778.809204 
	C436.846619,778.246826 437.634705,776.430176 438.289124,774.698730 
	C440.399475,769.115417 440.406738,769.122986 434.787079,768.138855 
	C434.305145,768.054504 433.881042,767.784729 433.249908,767.235352 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M366.177429,742.603027 
	C365.663635,743.352173 365.391296,743.814453 364.982391,744.505615 
	C363.136047,745.483948 361.426239,746.233276 359.363342,747.002136 
	C358.429321,746.513855 358.177521,745.860046 358.209106,744.747437 
	C359.329071,741.783264 362.134338,740.338135 363.039001,737.191833 
	C363.340881,736.680054 363.479065,736.485046 363.865051,736.031616 
	C368.279572,728.677368 367.642761,720.880249 367.299835,713.159729 
	C367.242645,711.871826 365.803711,711.043884 364.786072,709.729370 
	C364.326385,709.350708 364.134308,709.209595 363.690613,708.815552 
	C361.023621,702.868530 357.265411,698.120422 353.055328,693.669739 
	C351.661835,692.196655 350.145782,691.000732 347.873230,690.252136 
	C347.326996,690.015991 347.116302,689.901489 346.617584,689.569092 
	C340.690521,684.712891 334.856354,680.274780 328.470154,676.781921 
	C319.291412,671.761841 310.016541,666.922424 300.965393,661.663330 
	C298.413788,660.180847 295.841827,658.489380 292.345642,658.280579 
	C291.756592,658.128174 291.526611,658.046265 290.972961,657.793579 
	C274.946808,643.367798 255.708466,634.758911 237.223206,624.299500 
	C236.648956,624.118896 236.426880,624.023926 235.897705,623.737549 
	C229.745544,619.731018 223.783127,616.124878 217.020203,613.213623 
	C216.447647,612.998779 216.226120,612.889893 215.703461,612.561829 
	C212.558746,607.766846 208.193375,604.526001 203.448318,603.001892 
	C195.648987,600.496887 189.863449,595.010803 183.007492,591.172424 
	C180.816238,589.945557 178.579880,588.614441 179.536392,585.117065 
	C183.607529,582.970520 185.830460,586.044678 188.344589,587.864868 
	C194.182739,592.091736 199.949142,596.385193 207.536621,598.093994 
	C216.133636,605.833130 226.080688,610.726990 236.244064,615.907349 
	C237.375824,616.360718 238.134827,616.839783 239.372925,616.939636 
	C240.858109,617.110962 241.923706,617.486389 243.152374,618.366577 
	C244.918900,620.961548 246.749863,622.948120 249.838837,623.988770 
	C250.968521,624.600220 251.682343,625.198120 252.669373,626.009644 
	C259.976959,631.824890 268.456116,634.948792 276.326843,639.810425 
	C276.848938,640.074951 277.048157,640.199951 277.515442,640.554321 
	C281.874756,644.603821 286.504120,647.563965 292.102783,649.586182 
	C293.379395,649.857971 294.193970,650.311096 295.227234,651.083252 
	C297.127380,654.069031 300.247925,654.340393 303.080231,655.746826 
	C303.603394,655.965454 303.805542,656.070801 304.287659,656.374023 
	C308.110962,659.311279 311.494232,662.315674 316.349792,663.484497 
	C320.126312,663.467712 322.440491,665.853760 325.179352,667.337341 
	C332.173950,671.126160 339.054901,675.107239 345.855011,679.233582 
	C354.077057,684.222656 361.100067,690.872192 366.446838,698.521912 
	C370.264404,703.983887 374.460236,710.472412 374.671051,717.377563 
	C374.916504,725.416260 373.050842,733.511047 367.594849,740.128723 
	C367.062531,740.774353 366.819641,741.591309 366.177429,742.603027 
z"/>
<path fill="#101925" opacity="1.000000" stroke="none" 
	d="
M432.942749,767.390747 
	C434.319031,766.984497 435.647400,766.857727 436.951935,766.981750 
	C438.558624,767.134583 440.682068,764.780762 441.676575,767.406494 
	C442.477325,769.520569 442.327820,771.867432 440.652985,773.926392 
	C439.762390,775.021301 437.236053,776.221130 440.246429,778.154907 
	C440.578491,778.417786 440.589417,778.615662 440.468079,779.078857 
	C440.018585,779.856812 439.549805,780.197632 438.801758,780.635254 
	C438.268524,781.010559 438.015442,781.282166 437.665955,781.857300 
	C436.889709,783.510254 435.803741,784.588135 434.740967,786.079041 
	C434.337524,786.449158 434.162933,786.580383 433.704956,786.869263 
	C432.689270,787.386780 431.806915,787.470276 431.084900,788.443237 
	C430.498657,789.188721 430.129486,789.707642 429.529907,790.467407 
	C428.338959,791.667175 427.314819,792.551086 425.724365,793.220581 
	C423.570709,793.554688 422.151215,795.279236 419.560364,794.384888 
	C419.102783,790.376282 422.838409,789.278870 424.658569,787.202942 
	C429.734558,781.413513 430.930908,774.560730 432.942749,767.390747 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M371.944763,784.663940 
	C366.299133,783.609375 362.083984,779.634644 357.163147,776.310669 
	C363.059052,776.824768 368.639008,778.494446 371.944763,784.663940 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M352.822266,772.737549 
	C348.404633,771.078674 343.813965,769.192017 340.029968,765.413513 
	C345.295197,765.618774 349.716949,767.768066 352.822266,772.737549 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M396.969025,801.590332 
	C394.860931,802.575806 392.756714,803.198425 390.282501,803.805786 
	C391.533752,801.638428 393.985718,799.620972 391.002502,796.397827 
	C393.952484,796.624023 396.558014,797.648010 396.969025,801.590332 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M359.256470,743.739075 
	C359.004822,744.761047 358.998535,745.514404 359.001282,746.644653 
	C357.968567,747.465454 356.926819,747.909180 355.542816,748.375488 
	C352.039856,744.939148 355.914520,742.711426 356.843323,739.578674 
	C357.600464,738.297668 358.402130,737.659546 359.865479,737.394592 
	C360.487854,737.477356 360.725830,737.564270 361.210876,737.935913 
	C362.210815,740.494019 360.392090,741.809265 359.256470,743.739075 
z"/>
<path fill="#101925" opacity="1.000000" stroke="none" 
	d="
M410.211609,800.178528 
	C410.244995,799.416809 410.514343,798.945190 410.892822,798.224121 
	C412.608734,795.207153 414.825714,793.544434 418.602203,793.994629 
	C418.995087,793.995117 419.046906,794.064270 419.072754,794.098877 
	C419.465973,797.124512 416.718262,797.918091 414.943420,799.818481 
	C414.008240,800.403564 413.333130,800.689331 412.234375,800.878418 
	C411.360657,800.858765 410.889160,800.711670 410.211609,800.178528 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M357.454773,738.652344 
	C358.388824,742.331848 354.600830,744.222290 355.146606,747.988586 
	C354.704193,748.711426 354.207794,749.024719 353.426270,749.425781 
	C352.927643,749.358643 352.714081,749.203674 352.298462,748.707764 
	C351.055664,745.795044 353.415466,743.969727 353.790222,741.337769 
	C354.327942,740.546814 354.644348,740.078125 354.997223,739.319092 
	C355.525421,738.469788 356.143555,738.225464 357.454773,738.652344 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M378.944336,788.652771 
	C376.365021,789.107483 374.555725,787.743774 373.029114,785.397705 
	C375.508942,785.083252 377.802460,785.589783 378.944336,788.652771 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M387.934387,794.656982 
	C386.847961,794.979126 385.632935,795.104797 385.069916,793.338257 
	C386.150635,793.033020 387.362732,792.913879 387.934387,794.656982 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M346.781708,751.202515 
	C347.458008,750.669189 347.935913,750.366333 348.703186,750.025208 
	C349.171844,749.854431 349.351166,749.721924 349.836975,749.723267 
	C350.231812,750.061768 350.320190,750.266479 350.206055,750.737305 
	C349.211060,751.306213 348.418640,751.608948 347.301361,751.904175 
	C346.845459,751.742188 346.714355,751.587585 346.781708,751.202515 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M350.321533,751.003235 
	C350.003510,751.003418 350.015045,750.514709 350.022400,750.270386 
	C350.347504,748.874634 351.132935,748.398804 352.632446,748.627319 
	C352.974060,748.793701 353.074524,749.146912 353.107849,749.330200 
	C352.673553,750.625183 351.693451,750.876099 350.321533,751.003235 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M346.720459,751.009155 
	C346.980133,750.972107 346.982330,751.434570 346.979431,751.665649 
	C346.466553,752.091919 345.956543,752.287109 345.181458,752.380371 
	C345.431152,751.867676 345.945984,751.456909 346.720459,751.009155 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M381.942078,790.680786 
	C381.106781,790.919006 380.360931,790.637024 380.009369,789.377686 
	C380.861053,789.099731 381.615845,789.371826 381.942078,790.680786 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M384.947876,792.676147 
	C384.113312,792.912292 383.359070,792.640991 383.020874,791.366455 
	C383.874054,791.092651 384.624908,791.370361 384.947876,792.676147 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M361.306549,737.781372 
	C360.189362,738.240723 359.394135,738.496094 358.312225,738.885254 
	C358.025574,739.019043 357.998260,738.996704 357.985657,738.984924 
	C357.141876,737.987427 356.916107,736.911194 357.736023,735.354980 
	C357.998322,733.289001 358.010559,731.584045 358.025879,729.453003 
	C359.970825,722.411133 359.681671,716.106689 354.214447,711.265503 
	C350.840576,708.277954 349.076996,704.163391 345.502838,701.198975 
	C338.127380,695.081909 329.708496,690.971130 321.604706,686.130127 
	C309.475128,678.884155 296.555054,673.176025 284.530273,665.762451 
	C275.245819,660.038269 265.510437,655.080200 256.767975,648.442505 
	C252.272156,645.029053 246.405762,644.321472 241.482132,641.335693 
	C234.117310,636.869446 227.384918,631.316589 219.044540,627.744263 
	C217.741776,626.430237 216.634659,625.626160 215.173416,625.052734 
	C210.528015,623.229919 206.336243,620.671082 202.542877,617.319336 
	C197.684479,613.026550 191.662552,610.655579 185.746399,608.190247 
	C182.652649,606.901184 179.490860,605.674988 179.614075,601.173340 
	C182.374634,599.380981 184.546860,600.880188 186.798065,601.825378 
	C196.280350,605.806885 204.402740,612.057007 213.049850,617.442993 
	C217.066101,619.944580 221.271820,622.302307 226.483032,623.069458 
	C227.034821,623.362122 227.241547,623.502014 227.709900,623.904419 
	C228.255600,624.569519 228.456421,625.142456 228.851746,625.338135 
	C237.316742,629.528320 244.563080,635.997131 254.175522,638.807312 
	C264.886169,644.851746 275.279816,650.643250 285.010406,657.698608 
	C291.384705,662.320557 298.991425,665.324890 306.099731,668.914062 
	C313.676422,672.739929 321.151031,676.598816 328.159271,681.425476 
	C336.484222,687.158875 345.757202,691.413147 353.284576,698.431519 
	C366.535126,710.786011 368.502533,721.239197 361.306549,737.781372 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M218.689423,626.744019 
	C222.117233,626.436707 224.864441,628.083801 226.783600,629.736633 
	C232.937988,635.037231 240.599991,637.880249 246.924301,642.820068 
	C247.436981,643.220459 248.085144,643.626404 248.703033,643.684998 
	C253.919968,644.179810 257.931122,646.843079 261.726685,650.267090 
	C267.214996,655.218140 274.085358,658.590027 280.357513,661.894897 
	C291.036865,667.522034 301.877563,673.193542 312.450348,678.934204 
	C325.110413,685.808167 339.333923,691.147888 349.095917,702.343140 
	C354.740936,708.816895 362.948395,714.910583 360.025665,725.756287 
	C359.732239,726.844971 360.071136,728.123047 358.409424,728.908203 
	C358.582001,721.588989 356.161011,715.331482 350.301636,710.159546 
	C345.986145,702.299744 339.206940,697.760925 331.363373,694.014526 
	C330.996338,694.009766 330.496979,694.003052 330.247253,694.001526 
	C327.381165,691.234314 323.578430,690.731689 320.364777,689.147156 
	C315.620636,686.808228 310.997559,684.319275 306.272034,681.245361 
	C305.997589,681.007324 305.502075,681.000610 305.254333,680.997864 
	C294.478088,675.232239 283.618073,670.066467 273.257050,663.275024 
	C271.824615,662.855591 270.660858,662.679138 269.246735,662.252563 
	C261.807892,656.398560 253.820145,652.182861 245.263550,648.250366 
	C244.250504,648.003662 243.504700,647.997559 242.385986,647.990845 
	C237.748383,644.489197 234.280472,639.858521 228.258850,638.244568 
	C227.866455,637.707764 227.670502,637.452454 227.196396,637.127075 
	C224.533295,634.441711 221.460083,632.894348 218.257294,631.492432 
	C216.360916,630.662415 214.001038,629.983643 214.992310,626.722168 
	C216.314117,625.909790 217.342926,625.913147 218.689423,626.744019 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M352.979553,748.407532 
	C352.183746,749.110657 351.393402,749.427612 350.316406,749.885376 
	C349.856903,750.018982 349.684113,750.011902 349.251923,749.995850 
	C349.616791,746.675476 351.586548,744.068298 353.744324,741.217529 
	C353.669373,743.350769 353.327209,745.686096 352.979553,748.407532 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M357.634827,735.046204 
	C357.986542,736.055664 357.986969,737.117432 357.980225,738.576172 
	C357.237976,738.986755 356.502899,739.000427 355.400757,739.021362 
	C354.954376,737.243896 355.193329,735.641296 357.634827,735.046204 
z"/>
<path fill="#0ABFDE" opacity="1.000000" stroke="none" 
	d="
M222.788055,807.583496 
	C222.074402,807.522522 221.758636,807.403870 221.243530,807.126526 
	C217.072037,805.070801 214.293259,801.099243 209.390808,800.144653 
	C209.150772,799.887268 209.076172,799.949890 209.085327,799.902100 
	C207.876480,798.184509 206.016342,797.208130 204.537506,795.377686 
	C206.393127,790.708923 207.634857,785.640625 214.022171,786.008301 
	C214.698563,786.047302 215.402130,785.804260 215.832840,785.295410 
	C222.775955,777.092651 231.835800,770.268982 233.350876,758.511108 
	C233.435379,757.855530 233.397049,758.714783 233.875885,757.839417 
	C237.683334,757.353882 237.083221,760.410950 237.961380,762.675537 
	C238.007263,763.792358 238.019638,764.534546 238.312195,765.603516 
	C238.618179,766.387512 238.757843,766.843628 238.862579,767.662842 
	C238.194351,770.183167 238.535583,772.065613 240.660309,773.753967 
	C240.897995,773.997559 241.141479,774.350098 241.219940,774.549438 
	C241.465500,775.336060 241.850861,775.829712 242.074142,776.716492 
	C242.200912,777.383911 242.267654,777.753418 242.382538,778.400391 
	C242.596176,779.346436 242.955353,779.933716 243.344391,780.878418 
	C243.573395,781.596191 243.678986,781.995972 243.827454,782.703003 
	C243.935242,783.457825 244.007874,783.904175 244.163498,784.679993 
	C244.289169,785.377869 244.339615,785.744995 244.430603,786.387085 
	C244.911514,789.443115 245.883743,792.044250 247.233368,794.886902 
	C247.490128,795.913208 247.651611,796.602722 247.845856,797.645874 
	C248.210556,800.550903 249.471542,802.831177 250.066940,805.664429 
	C250.185776,806.382202 250.252670,806.756409 250.364258,807.412231 
	C251.526627,810.790527 253.064148,814.585632 250.877533,816.996826 
	C248.281570,819.859497 244.567093,817.296875 241.679794,816.042114 
	C235.459427,813.338989 229.145828,810.805908 222.788055,807.583496 
z"/>
<path fill="#05B9D9" opacity="1.000000" stroke="none" 
	d="
M234.309647,757.603638 
	C234.839508,764.808838 229.009506,776.009155 222.916733,779.178711 
	C220.373978,780.501465 219.230225,782.933350 218.120193,785.300659 
	C217.421616,786.790466 216.530792,788.400513 214.987747,787.856812 
	C210.438507,786.253784 208.959900,789.189697 207.336945,792.320618 
	C206.897659,793.168030 206.037796,793.797485 205.165833,794.801819 
	C202.632553,796.442383 201.354752,793.818359 199.095490,793.456238 
	C198.753281,793.289795 198.714340,793.277771 198.695068,793.269897 
	C198.756363,792.785950 198.836929,792.309875 198.911194,791.472229 
	C197.588699,783.340210 203.729385,777.834961 205.070129,770.868530 
	C205.158752,770.408081 205.382767,769.784180 205.736862,769.591919 
	C214.110260,765.045776 216.711563,755.266357 223.956726,749.532288 
	C226.478531,747.536316 225.804932,742.199646 230.666275,740.979126 
	C231.060608,741.390076 231.085770,741.828430 231.113907,742.595764 
	C231.439362,743.412354 231.481140,743.989502 231.826721,744.736084 
	C232.024185,744.939514 232.054840,744.963928 232.059204,744.983032 
	C232.440094,745.449158 232.482162,746.020081 232.800171,746.774841 
	C232.963943,747.000183 233.420410,747.044556 233.649002,747.048828 
	C233.877594,747.053162 233.926682,747.542297 233.903885,747.787048 
	C233.215759,749.135071 234.117706,750.071899 234.354950,751.375183 
	C234.778168,751.973511 235.034378,752.305969 235.381714,752.944946 
	C235.585541,753.618958 235.682175,753.998962 235.810913,754.671509 
	C235.725708,755.930054 235.262695,756.709900 234.309647,757.603638 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M222.594940,807.911072 
	C226.804428,807.176758 230.011948,810.263428 233.805450,811.608398 
	C237.362167,812.869385 240.644409,814.888794 244.173172,816.248047 
	C249.656418,818.360107 250.870682,817.453064 250.646210,811.837158 
	C250.600601,810.696228 250.375656,809.562439 250.321167,808.059570 
	C252.863510,811.452881 252.938721,815.903503 253.985168,820.463623 
	C242.934433,818.213379 232.720215,813.558105 222.594940,807.911072 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M234.407181,757.955078 
	C234.150711,756.810242 233.941483,755.646484 235.531357,755.037048 
	C237.694183,757.480286 238.333572,760.402039 238.569458,763.798950 
	C238.134735,764.403687 237.775482,764.485718 237.211349,764.160767 
	C236.958023,761.704712 237.055252,759.413208 234.407181,757.955078 
z"/>
<path fill="#08B8DC" opacity="1.000000" stroke="none" 
	d="
M240.913757,773.627563 
	C237.269562,773.679321 236.589890,771.566589 237.790039,768.219177 
	C238.406693,767.631348 238.829391,767.530823 239.498962,767.927368 
	C240.103851,769.898315 240.516693,771.577942 240.913757,773.627563 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M247.454437,794.868774 
	C245.104446,793.109070 244.021194,790.539001 244.349487,787.058105 
	C246.542603,788.869507 246.633881,791.860962 247.454437,794.868774 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M250.299438,805.751831 
	C248.364410,803.809875 247.788193,801.197571 247.677887,798.245850 
	C249.543091,800.221558 250.163086,802.806030 250.299438,805.751831 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M387.775574,803.734741 
	C386.808655,805.245117 385.122101,805.327026 383.231018,805.715454 
	C383.788177,803.531433 385.653137,803.484253 387.775574,803.734741 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M235.583023,752.906128 
	C235.174957,753.165894 234.861603,753.090332 234.307846,752.976990 
	C233.167511,751.896057 233.580750,750.670898 233.897125,749.166382 
	C235.032089,749.944641 235.614029,751.156372 235.583023,752.906128 
z"/>
<path fill="#06ABCA" opacity="1.000000" stroke="none" 
	d="
M234.144501,748.622253 
	C234.147766,749.929260 234.097580,751.025940 234.057114,752.532104 
	C232.561310,751.662781 232.876801,750.118347 233.653046,748.285889 
	C233.881088,748.031799 234.091049,748.411865 234.144501,748.622253 
z"/>
<path fill="#08B8DC" opacity="1.000000" stroke="none" 
	d="
M237.048752,764.216675 
	C237.363113,763.961975 237.680878,764.019043 238.229401,764.135559 
	C238.572830,764.571228 238.681808,764.968079 238.817825,765.673279 
	C238.681564,766.138977 238.518265,766.296448 238.113678,766.213989 
	C237.600220,765.485291 237.328049,764.996582 237.048752,764.216675 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M378.829224,805.763306 
	C378.504547,806.256409 378.005859,806.549500 377.204224,806.796875 
	C377.175262,805.896606 377.411530,804.986267 378.829224,805.763306 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M370.823700,807.771545 
	C370.500397,808.260132 370.003632,808.549194 369.205292,808.806091 
	C369.180328,807.929260 369.394440,806.993774 370.823700,807.771545 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M366.824280,808.714844 
	C366.589844,809.577271 366.078705,809.888550 365.158325,809.234253 
	C365.398407,808.394226 365.902649,808.053711 366.824280,808.714844 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M237.926620,766.304688 
	C237.872421,765.974060 238.359406,765.956055 238.602142,765.968811 
	C239.165131,766.511292 239.485413,767.040955 239.748352,767.894653 
	C239.327301,768.153137 238.963577,768.087585 238.323868,767.996826 
	C238.025528,767.526123 238.003174,767.080750 237.926620,766.304688 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M242.299423,776.821533 
	C241.809311,776.478516 241.484421,775.942688 241.228973,775.077820 
	C242.133026,775.098816 242.839539,775.528259 242.299423,776.821533 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M243.573044,780.904053 
	C242.691589,780.793152 242.205338,780.204407 242.336578,778.979309 
	C243.183670,779.104980 243.706833,779.680359 243.573044,780.904053 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M244.392548,784.817993 
	C243.522858,784.676270 243.137573,784.191833 243.682739,783.190430 
	C244.564407,783.352356 244.839920,783.869690 244.392548,784.817993 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1008.755493,257.336548 
	C1010.015503,255.820831 1011.506226,255.646225 1012.651489,256.184540 
	C1015.911621,257.716888 1018.446350,256.383911 1020.828064,254.456741 
	C1022.445251,253.148224 1023.938904,253.213760 1025.876221,253.729675 
	C1031.114136,255.124481 1031.173706,255.021149 1027.650269,258.835052 
	C1026.716309,259.845856 1026.348389,261.108337 1025.687012,262.223389 
	C1024.345825,264.484528 1022.188660,265.622284 1019.879639,263.823242 
	C1017.077759,261.640228 1013.983032,262.038818 1010.436157,262.000000 
	C1008.678528,260.841644 1007.649536,259.582672 1008.755493,257.336548 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M1034.834595,227.307419 
	C1042.310913,228.225235 1043.987061,231.988586 1040.284180,239.751617 
	C1037.887329,239.825333 1036.394165,238.378281 1034.896484,237.194992 
	C1031.311035,234.362335 1031.341553,231.692352 1034.834595,227.307419 
z"/>
<path fill="#E1DFDD" opacity="1.000000" stroke="none" 
	d="
M1043.627686,247.050278 
	C1045.388916,246.999100 1046.777832,246.999420 1048.583374,246.999878 
	C1049.328125,248.421356 1049.656250,249.842728 1049.992188,251.632050 
	C1050.803101,253.683090 1049.999634,254.820740 1048.355957,255.896973 
	C1045.005737,256.062195 1041.890259,256.553101 1040.861450,252.440933 
	C1040.392944,250.568451 1038.822876,251.325180 1037.528198,251.586441 
	C1036.646362,251.764420 1035.798096,251.432053 1035.594849,250.472748 
	C1035.350586,249.320221 1036.286621,249.037048 1037.119629,249.062134 
	C1039.415649,249.131287 1041.178711,247.645950 1043.627686,247.050278 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1008.593018,257.000122 
	C1009.301331,258.424591 1009.602722,259.849182 1009.952026,261.636871 
	C1009.033691,262.152557 1008.007874,262.148346 1007.112427,262.487885 
	C1004.675842,263.411865 1003.654114,262.016510 1003.286316,260.063232 
	C1002.886414,257.939087 1004.335754,257.167786 1006.216248,257.039093 
	C1006.870667,256.994293 1007.529297,257.011444 1008.593018,257.000122 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1048.044434,256.332367 
	C1048.578857,254.860641 1049.157715,253.721298 1049.868408,252.290970 
	C1051.530518,252.173691 1053.751221,251.383148 1054.203735,253.404266 
	C1054.671143,255.491577 1052.788696,256.914215 1051.250977,258.086334 
	C1049.523315,259.403198 1048.627686,258.295441 1048.044434,256.332367 
z"/>
<path fill="#DDDBD8" opacity="1.000000" stroke="none" 
	d="
M977.790527,270.910400 
	C975.733093,268.164795 978.396973,268.468109 979.494812,268.187256 
	C980.813965,267.849731 982.514954,267.470642 983.490295,268.977295 
	C984.010559,269.780914 983.460693,270.649414 982.534607,270.771362 
	C981.093201,270.961273 979.620605,270.914337 977.790527,270.910400 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M672.771790,278.354309 
	C668.932739,276.310455 670.151367,274.234863 672.828979,273.448151 
	C675.520386,272.657379 679.796204,271.536255 681.007263,274.372498 
	C682.257446,277.300293 677.387939,276.492188 675.906189,278.340668 
	C675.260193,279.146515 674.073853,278.935455 672.771790,278.354309 
z"/>
<path fill="#1D273D" opacity="1.000000" stroke="none" 
	d="
M493.670654,354.629150 
	C500.961914,352.779846 507.993042,351.240143 515.418945,349.691162 
	C516.836914,351.147278 518.448120,351.256927 519.870117,350.950409 
	C525.047424,349.834381 530.385498,351.678040 535.510437,349.989868 
	C536.286987,349.734039 537.364136,349.833344 537.848022,349.330902 
	C544.103210,342.836090 552.781921,343.275055 560.573242,341.882843 
	C568.821655,340.408966 576.473572,336.669403 584.779663,335.935974 
	C594.136353,335.109741 602.853760,331.555573 612.247375,330.463715 
	C619.700989,329.597290 626.971619,325.663422 634.773438,324.894196 
	C642.027466,324.178955 648.432190,319.633026 656.192932,320.729462 
	C658.946838,321.118561 661.970764,318.719208 664.694092,317.614960 
	C670.945984,315.080048 677.846130,316.903168 684.119446,312.721771 
	C688.211426,309.994293 694.670959,308.121185 700.705444,309.862427 
	C701.880859,310.201599 703.576294,310.102264 704.574524,309.476105 
	C712.504883,304.501740 721.760803,304.031891 730.470093,301.808563 
	C734.271545,300.838104 738.403076,300.946350 742.383118,300.961975 
	C745.240234,300.973206 747.755554,300.909882 748.930786,297.776947 
	C749.321716,296.734772 749.912537,295.979767 751.125854,296.325806 
	C752.427246,296.696991 752.793091,297.748444 752.930786,299.008484 
	C753.101318,300.567932 750.957825,302.770111 752.965820,303.518707 
	C756.802002,304.948853 758.136719,309.630402 762.691040,310.072723 
	C768.231750,310.610840 768.905457,312.334869 765.952637,317.052429 
	C764.978760,318.608398 763.880066,319.966644 764.773254,322.056152 
	C765.309814,323.311249 764.961975,324.755524 763.562256,325.508148 
	C762.076660,326.306976 761.043884,325.530457 760.198730,324.332184 
	C759.555603,323.420227 758.989319,321.898254 757.615540,322.803894 
	C756.594543,323.476929 756.679504,325.161987 757.266174,326.051239 
	C761.060303,331.802765 756.435730,336.989349 756.298035,342.456665 
	C756.268555,343.627991 755.025879,343.838837 754.068481,344.124207 
	C751.037598,345.027557 749.915405,347.212463 749.992920,350.593811 
	C745.720581,354.814697 740.013977,354.682037 734.972961,356.360413 
	C731.895447,357.385071 728.547302,357.634308 725.660828,360.146332 
	C724.913269,360.682800 724.427185,360.873688 723.519409,361.011841 
	C721.164795,360.382904 719.141113,360.558258 716.756348,360.581238 
	C714.066833,360.406311 712.123535,361.336609 709.993286,362.876343 
	C680.322266,370.357208 650.891724,377.229309 621.400391,383.864136 
	C605.692566,387.398010 589.960266,390.911804 574.458496,395.327118 
	C573.506104,395.598419 572.451111,395.658417 572.152832,397.205139 
	C572.390259,398.708191 572.415222,399.886780 572.978271,401.302368 
	C573.778137,403.703827 573.970764,405.946289 575.310913,408.144531 
	C575.603699,408.603027 575.707336,408.793701 575.933838,409.282959 
	C576.323120,410.375092 576.464355,411.205841 576.896362,412.266785 
	C577.410645,413.767517 578.417053,414.539429 579.333313,415.776154 
	C579.556396,416.266602 579.615845,416.477112 579.698975,417.016418 
	C579.105164,420.030548 581.497253,421.761505 581.914185,424.464325 
	C581.777771,427.753723 582.772583,430.439392 584.010498,433.412170 
	C584.759338,435.464203 585.474854,437.166473 586.172974,439.251678 
	C586.445557,440.283569 586.678101,440.954742 587.146851,441.930481 
	C589.299988,447.115753 590.729004,452.153625 592.257019,457.566406 
	C592.668274,459.781372 593.194153,461.592804 592.921387,463.894409 
	C592.706543,464.547882 592.582764,464.796722 592.165161,465.343231 
	C590.130432,466.768127 588.105713,466.943970 586.147522,467.220825 
	C570.640686,469.413055 555.571106,473.584229 539.989929,477.331940 
	C533.237671,478.802948 532.760925,478.330139 530.676758,472.237671 
	C523.675720,451.771912 517.791870,430.948242 510.529266,410.559967 
	C504.611115,393.945923 499.505035,377.044220 494.000275,360.282623 
	C493.434662,358.560425 492.697754,356.819122 493.670654,354.629150 
z"/>
<path fill="#101925" opacity="1.000000" stroke="none" 
	d="
M725.110413,360.773285 
	C724.701599,358.200165 725.936401,357.243500 728.242126,356.682922 
	C735.269714,354.974518 742.230042,352.989624 749.602661,351.048798 
	C750.726807,350.984558 751.464783,350.983795 752.569946,350.972778 
	C752.728333,351.869476 752.519714,352.776398 752.020142,353.889648 
	C748.114319,356.857971 743.669739,356.766296 739.166992,357.779358 
	C738.373474,358.061676 737.913635,358.184082 737.107605,358.336060 
	C736.107361,358.452118 735.442383,358.493164 734.509033,358.848633 
	C733.789124,359.052002 733.374756,359.148438 732.650024,359.296631 
	C731.721008,359.449554 731.117188,359.600342 730.258423,360.011658 
	C729.362305,360.402618 728.754944,360.652802 727.804382,360.961060 
	C726.780151,361.178101 726.094299,361.144745 725.110413,360.773285 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M771.282593,287.824554 
	C770.468384,287.537018 769.982971,287.260498 769.214600,286.908752 
	C771.040344,284.432373 772.291443,284.329407 774.113647,286.684509 
	C773.206665,287.259247 772.409058,287.547424 771.282593,287.824554 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M695.756470,307.399902 
	C696.152161,306.896729 696.841980,306.564392 697.817566,306.327087 
	C697.730164,307.360931 697.090942,307.824310 695.756470,307.399902 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M645.136475,319.141968 
	C645.503418,318.679352 646.020020,318.429016 646.826965,318.265076 
	C646.820251,319.257874 646.406311,319.950836 645.136475,319.141968 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M633.150024,322.174225 
	C633.500000,321.695038 634.007629,321.421936 634.810913,321.226410 
	C634.841980,322.222809 634.452271,322.932312 633.150024,322.174225 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M612.152954,327.205688 
	C612.521545,326.768219 613.042542,326.526764 613.850830,326.385864 
	C613.807861,327.353455 613.354370,327.973938 612.152954,327.205688 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M641.131470,320.152222 
	C641.495117,319.677612 642.007874,319.419434 642.813843,319.243774 
	C642.818481,320.234802 642.434021,320.975525 641.131470,320.152222 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M649.139282,318.154053 
	C649.502441,317.690857 650.018860,317.435547 650.827942,317.267853 
	C650.818237,318.248566 650.410156,318.949554 649.139282,318.154053 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M653.147095,317.218689 
	C653.523376,316.779358 654.050781,316.538696 654.865356,316.405396 
	C654.800659,317.359955 654.350891,318.006378 653.147095,317.218689 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M585.178101,333.288757 
	C585.543945,332.857239 586.064453,332.610657 586.870361,332.480774 
	C586.801636,333.417114 586.333618,333.999847 585.178101,333.288757 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M577.168701,335.218811 
	C577.534485,334.800385 578.053589,334.569519 578.855103,334.439758 
	C578.796265,335.383057 578.327942,335.958466 577.168701,335.218811 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M670.790527,313.524658 
	C670.991272,312.669220 671.668518,312.299103 672.840942,312.426147 
	C672.729492,313.404297 672.088501,313.855164 670.790527,313.524658 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M733.172852,298.242828 
	C733.514648,297.773010 734.014038,297.501129 734.804688,297.300964 
	C734.832214,298.265472 734.446838,298.957184 733.172852,298.242828 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M745.166504,295.218353 
	C745.524719,294.785553 746.032837,294.547211 746.821960,294.403931 
	C746.798401,295.372498 746.359375,295.983063 745.166504,295.218353 
z"/>
<path fill="#D7D4D2" opacity="1.000000" stroke="none" 
	d="
M710.328308,298.243164 
	C708.246826,295.991119 706.488403,298.266388 704.366699,297.987976 
	C703.761597,296.875427 703.523193,295.755707 703.142456,294.318024 
	C703.305969,292.895477 703.611877,291.790955 703.958862,290.343201 
	C707.553833,288.810486 711.091919,288.645264 714.685120,289.929840 
	C717.423645,290.908844 719.811401,290.522919 722.637085,289.162018 
	C726.742310,287.184937 731.410217,286.957855 735.888977,289.614868 
	C729.827637,291.102997 723.684021,292.451477 717.467957,293.188354 
	C715.143860,293.463867 714.177246,294.107330 714.011230,296.635803 
	C713.378662,298.611023 712.217712,299.003998 710.328308,298.243164 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M709.809753,298.312958 
	C711.121948,297.757812 712.244324,297.527679 713.684570,297.153992 
	C718.295349,297.521912 722.115601,294.984894 726.773132,295.167114 
	C726.143005,298.158386 723.184265,297.266266 721.217590,297.810455 
	C714.461914,299.679688 707.908936,302.439453 700.407104,303.007416 
	C700.705811,300.262451 703.169128,300.507263 705.134521,300.091614 
	C706.699341,299.760651 708.472046,300.226135 709.809753,298.312958 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M733.862366,293.225159 
	C733.452515,295.663727 731.914124,296.521942 729.247559,295.394470 
	C730.108459,293.642151 731.895264,293.623291 733.862366,293.225159 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M766.744995,286.729187 
	C765.836792,288.373444 764.224121,287.903839 762.363770,287.980988 
	C762.762756,285.257233 764.641113,285.909302 766.744995,286.729187 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M754.827515,288.802002 
	C754.522095,289.319794 754.041565,289.629028 753.261108,289.932617 
	C753.179077,289.043823 753.284119,288.017731 754.827515,288.802002 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M197.113129,187.295654 
	C198.404114,187.360794 198.829453,187.904282 198.319794,188.832870 
	C197.998734,189.417877 197.291367,189.804993 196.720734,189.384872 
	C195.918701,188.794342 196.149109,188.020752 197.113129,187.295654 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M960.186951,1150.748413 
	C959.863220,1152.394409 959.035461,1152.985474 957.348022,1152.990967 
	C955.878479,1152.624756 954.775269,1151.911621 953.292175,1152.907227 
	C951.611084,1153.000000 950.222229,1153.000000 948.416626,1153.000000 
	C947.762939,1150.473633 945.609009,1148.477295 946.001221,1145.388306 
	C946.369934,1144.688965 946.793457,1144.526733 947.582825,1144.713989 
	C950.808899,1148.367310 953.667053,1146.636353 956.486145,1144.617065 
	C953.822998,1143.842407 951.667786,1147.079468 948.645935,1145.571655 
	C946.764160,1144.369263 946.743530,1142.196899 945.001221,1140.872314 
	C944.643677,1140.426880 944.521118,1140.232056 944.274048,1139.714355 
	C943.889771,1133.882446 938.060425,1134.774658 935.808899,1131.568604 
	C934.020569,1133.207275 934.684204,1135.343628 933.433655,1137.127686 
	C932.167358,1138.212891 931.002930,1138.450562 929.362549,1138.081787 
	C927.611084,1138.000000 926.222229,1138.000000 924.416626,1138.000000 
	C926.023254,1133.310425 926.765625,1127.661499 933.098206,1126.245483 
	C933.987549,1126.046631 935.111084,1124.716919 935.721985,1125.621948 
	C938.403442,1129.594360 944.136719,1129.287354 946.498413,1133.822144 
	C949.018250,1138.660645 950.289734,1138.523682 953.637207,1134.083496 
	C955.687744,1131.363525 958.145569,1131.194214 960.214478,1133.949219 
	C961.598694,1135.792480 962.853333,1137.201538 965.412964,1137.032104 
	C970.031006,1136.726685 971.316711,1139.086182 970.008301,1143.629517 
	C967.421387,1144.536255 964.473694,1142.776123 962.103455,1145.626221 
	C962.657227,1147.932617 960.854858,1148.975830 960.186951,1150.748413 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M984.998657,1167.609375 
	C981.212769,1167.352051 978.029663,1165.944336 977.045776,1161.356323 
	C980.350342,1162.192505 985.207642,1161.447144 984.998657,1167.609375 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M980.035217,1147.379272 
	C980.401062,1145.247070 981.459961,1144.648193 982.857117,1145.726196 
	C984.183350,1146.749390 985.100098,1148.214722 984.377502,1150.051270 
	C983.926819,1151.196777 983.124878,1152.101929 981.750305,1151.805908 
	C979.367249,1151.292847 980.213135,1149.284424 980.035217,1147.379272 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M844.374268,1095.991211 
	C841.161011,1093.911377 839.113098,1091.615112 841.911743,1088.046387 
	C838.031921,1082.915039 840.553894,1074.744385 833.298645,1071.024780 
	C827.609253,1068.107910 826.017700,1061.541138 822.723816,1056.570923 
	C821.317932,1054.449463 823.933044,1054.338989 825.709106,1054.719849 
	C828.454346,1055.308472 830.837646,1055.116455 830.967957,1051.455933 
	C831.014954,1050.135864 831.176392,1048.695068 829.933533,1047.777710 
	C828.421875,1046.661743 827.560974,1048.260376 826.425964,1048.747070 
	C824.321655,1049.649292 822.171448,1053.153931 820.050964,1048.349609 
	C823.958679,1047.630737 827.053589,1046.647095 825.153259,1041.522339 
	C824.781433,1040.519653 824.693542,1039.117798 825.991333,1038.427734 
	C827.048645,1037.865234 827.958252,1038.491577 828.649353,1039.218506 
	C830.372192,1041.030884 832.228943,1041.031738 834.558228,1040.390991 
	C837.741882,1039.515381 839.526794,1040.702515 838.384338,1044.320312 
	C837.107727,1048.362915 839.665588,1052.142334 839.135071,1056.143555 
	C839.021545,1056.999268 839.664917,1057.717407 840.676208,1057.704468 
	C841.835449,1057.689697 842.542053,1056.952393 842.784058,1055.921387 
	C843.905396,1051.144653 846.198120,1050.566528 849.655457,1053.921143 
	C850.309814,1054.556152 851.029297,1054.674927 851.926880,1054.500977 
	C853.679749,1054.161255 855.814209,1052.279053 857.043213,1053.560181 
	C859.028320,1055.629639 859.868469,1058.634766 862.250305,1060.665771 
	C863.360718,1061.612671 861.696777,1062.691040 860.674255,1063.111572 
	C857.904175,1064.251099 857.553650,1066.403198 858.253784,1068.851562 
	C858.996948,1071.450073 861.321350,1070.997070 863.253906,1070.974487 
	C866.433960,1070.937378 869.432861,1071.249756 872.475586,1072.529663 
	C875.605713,1073.846313 877.208313,1072.069580 876.396912,1068.824219 
	C876.112732,1067.687500 874.329895,1066.312012 876.114685,1065.420044 
	C877.757751,1064.598877 878.180359,1066.586670 878.811584,1067.646484 
	C879.425964,1068.677856 880.028564,1069.634277 881.650635,1069.949707 
	C882.560791,1070.614502 883.248474,1071.234985 883.268799,1072.085938 
	C883.374390,1076.501831 885.919312,1079.385376 889.368896,1082.147217 
	C889.378662,1087.240234 885.334900,1085.342773 882.205200,1086.230103 
	C885.048096,1088.632690 887.576538,1087.732300 890.018372,1087.264282 
	C892.760315,1086.739014 895.106812,1084.863281 898.355225,1084.894287 
	C902.509766,1085.682617 904.945435,1083.859131 906.938477,1080.442627 
	C908.851074,1079.331421 910.533569,1078.819336 912.688354,1079.765137 
	C914.242188,1080.167358 915.875671,1079.489380 916.550354,1080.994873 
	C917.319519,1082.711182 915.458618,1083.131592 914.589661,1083.962280 
	C913.422363,1085.078003 913.103577,1086.398193 912.974854,1087.948120 
	C912.618652,1092.238525 907.678223,1097.373291 903.671570,1096.777954 
	C899.239197,1096.119141 899.771606,1097.942627 900.727539,1100.746948 
	C900.985779,1101.504517 900.915588,1102.374146 900.998047,1103.596191 
	C901.897705,1104.968628 903.328369,1105.702026 903.598450,1106.949463 
	C904.885437,1112.893555 909.087341,1112.634277 913.183960,1111.119263 
	C918.146362,1109.283936 920.614014,1111.480957 922.754700,1115.755127 
	C923.329956,1117.100464 923.659973,1118.200806 923.994995,1119.650635 
	C920.114746,1120.614990 916.194763,1123.307251 912.400269,1119.487915 
	C911.693909,1118.776855 910.612305,1119.948120 909.881531,1120.612427 
	C908.844604,1121.555298 907.639954,1122.446533 906.330200,1121.484741 
	C902.194031,1118.447266 900.464783,1121.376343 899.425964,1124.513428 
	C898.487305,1127.348145 895.537170,1129.155273 895.745605,1132.535645 
	C895.816528,1133.685181 894.508240,1133.927979 893.464478,1133.949097 
	C892.029480,1133.978149 890.726318,1133.594116 890.284851,1132.085449 
	C889.819336,1130.494507 889.681030,1128.800537 888.273987,1127.232910 
	C887.887695,1126.833374 887.775391,1126.666626 887.831543,1126.250000 
	C889.941528,1124.784302 892.141052,1125.095947 894.263916,1124.912720 
	C895.501770,1124.806030 896.494202,1124.235107 896.737244,1122.886475 
	C896.957275,1121.665771 896.090454,1121.083008 895.191284,1120.639038 
	C892.430359,1119.276245 890.211243,1116.791382 886.714355,1116.949219 
	C883.201660,1117.107910 883.354431,1113.902954 882.426392,1111.773438 
	C881.599487,1109.876099 881.226685,1106.752686 878.571838,1107.678223 
	C876.112671,1108.535645 875.322388,1111.180908 877.186951,1113.846191 
	C878.056030,1115.088501 878.739990,1116.939941 876.808838,1117.567139 
	C874.463623,1118.328979 873.334900,1116.367065 872.252075,1114.233398 
	C871.477417,1113.556763 871.154480,1112.989380 870.882874,1112.037598 
	C869.590210,1109.368530 867.451782,1111.953613 865.503784,1110.419189 
	C865.000000,1109.555542 865.000000,1109.111084 865.000000,1108.333252 
	C864.961548,1107.636475 864.807556,1107.334839 864.260132,1107.052246 
	C862.197266,1106.869995 860.275879,1107.082520 858.625732,1106.511597 
	C857.036560,1105.961426 855.952698,1104.259888 856.568970,1102.506226 
	C857.217468,1100.660889 858.826172,1101.037476 860.334167,1101.668701 
	C861.351013,1102.094482 862.269775,1103.071533 863.776245,1102.226562 
	C865.191956,1100.421143 867.182617,1100.786255 868.874084,1100.061646 
	C867.390808,1099.045410 865.244263,1099.431152 864.206116,1097.276978 
	C864.143738,1093.368774 859.875244,1092.698853 859.046570,1089.344482 
	C857.907349,1088.164307 856.763123,1087.734497 855.230347,1088.775146 
	C852.395691,1092.520752 849.307007,1095.330444 844.374268,1095.991211 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M923.000000,1115.541992 
	C922.353027,1115.910889 921.371399,1116.062500 921.108765,1115.697144 
	C916.427795,1109.185791 911.270874,1113.019287 906.267944,1115.018555 
	C905.087158,1115.490479 903.962097,1115.451782 904.207581,1114.274780 
	C905.020447,1110.376831 901.669312,1108.049927 901.009583,1104.380127 
	C906.354919,1104.000000 911.709900,1104.000000 917.532410,1104.000000 
	C919.486511,1104.337891 921.090820,1103.716675 922.757935,1104.777344 
	C923.000000,1108.361328 923.000000,1111.722534 923.000000,1115.541992 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M913.042542,1079.653809 
	C911.257629,1080.332642 909.515320,1080.665283 907.386475,1080.999023 
	C906.350220,1081.086182 905.712097,1081.046509 904.797119,1080.684326 
	C903.762146,1080.094849 903.123657,1079.505005 902.144897,1078.822998 
	C897.238647,1077.203003 894.091980,1073.271118 889.528564,1071.494263 
	C886.668823,1070.380737 888.492004,1067.517944 889.752808,1065.154541 
	C890.280212,1064.221191 890.759949,1063.702271 891.670776,1063.138672 
	C893.066711,1063.000000 894.133362,1063.000000 895.600037,1063.000000 
	C897.889343,1063.885010 898.193726,1065.703613 898.810669,1067.724854 
	C901.287659,1070.120728 903.599548,1072.216187 905.854187,1074.371216 
	C907.284424,1075.738281 908.423218,1077.106567 910.922058,1076.279785 
	C913.813721,1075.323120 913.826904,1077.232422 913.042542,1079.653809 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M935.945679,1096.366577 
	C938.273804,1096.074463 939.924500,1095.386230 939.990601,1092.373535 
	C943.300964,1091.123657 946.020691,1090.607666 943.762329,1095.680054 
	C942.375916,1098.793945 942.609070,1102.161865 943.736084,1105.404053 
	C944.203552,1106.749023 945.519348,1108.225830 943.705750,1109.366333 
	C942.085999,1110.385010 940.111633,1110.466309 938.514893,1109.334961 
	C934.471924,1106.470581 933.477295,1101.340698 935.945679,1096.366577 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M908.290283,1060.147949 
	C912.046082,1060.557129 914.373901,1063.658447 918.316528,1063.330811 
	C920.626038,1063.139038 919.036072,1065.535156 918.636658,1066.736694 
	C916.539124,1073.047607 916.017334,1073.078613 910.739014,1069.718628 
	C908.681641,1068.409058 905.800781,1068.816284 905.642883,1064.979492 
	C905.544434,1062.585815 905.796692,1061.176392 908.290283,1060.147949 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M892.147583,1062.679565 
	C891.819702,1063.545410 891.639404,1064.090820 891.229492,1064.818115 
	C889.499023,1066.334473 888.663330,1068.010010 888.031189,1069.923584 
	C886.857910,1073.475342 885.862854,1073.582642 882.920593,1070.392456 
	C883.241699,1067.598022 882.348083,1063.188477 883.598083,1063.125244 
	C888.291809,1062.887695 888.606079,1056.603638 893.606140,1057.001709 
	C894.173584,1059.022339 893.180115,1060.673584 892.147583,1062.679565 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M924.750610,1122.000000 
	C925.213562,1124.510620 924.601685,1126.258057 921.547363,1126.030884 
	C919.724060,1125.895264 918.397888,1126.836670 917.457458,1128.357544 
	C916.824402,1129.381348 916.778564,1131.324463 914.888000,1130.537598 
	C914.144775,1130.228271 913.553833,1129.069824 913.295654,1128.181274 
	C912.797119,1126.464966 913.881470,1125.148804 915.266174,1124.585205 
	C917.842407,1123.536865 920.567871,1122.854980 923.616821,1122.010254 
	C924.002441,1122.000000 924.501221,1122.000000 924.750610,1122.000000 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M923.000000,1104.546631 
	C921.391052,1104.711426 919.582886,1107.290527 918.068237,1104.331055 
	C918.942444,1102.318604 919.172363,1100.630859 918.456848,1098.748779 
	C917.719055,1096.808228 918.488892,1095.418335 920.591431,1095.179443 
	C922.706238,1094.939209 922.978638,1096.484131 922.986267,1098.108398 
	C922.995605,1100.103394 922.995911,1102.098389 923.000000,1104.546631 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M899.219421,1067.775269 
	C896.497559,1067.608643 896.883667,1065.259888 896.046143,1063.354492 
	C896.967712,1059.977661 899.328247,1060.712646 901.128967,1061.531372 
	C902.880432,1062.327759 903.133484,1064.351929 902.903931,1066.214111 
	C902.590698,1068.754639 900.451904,1066.726807 899.219421,1067.775269 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M916.939941,1087.876953 
	C917.851196,1087.418335 918.480469,1087.071289 919.103210,1087.082642 
	C921.306458,1087.123047 923.665649,1087.268311 923.830688,1090.188110 
	C923.929871,1091.941650 922.814514,1093.125488 920.943054,1092.396484 
	C918.978271,1091.631104 914.620789,1093.025269 916.939941,1087.876953 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M927.703003,1087.055908 
	C928.313293,1087.471680 928.626587,1087.943237 928.969910,1088.707520 
	C928.130920,1088.843506 926.975403,1089.319092 926.614136,1088.062012 
	C926.550171,1087.839478 927.125671,1087.433105 927.703003,1087.055908 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M992.658691,1124.586792 
	C993.373169,1124.387939 993.633240,1124.637451 993.521790,1125.192749 
	C993.498657,1125.307861 993.334900,1125.462524 993.218262,1125.479736 
	C992.657532,1125.562744 992.422791,1125.289917 992.658691,1124.586792 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M723.309753,1040.220947 
	C721.266602,1039.759766 720.955505,1038.526367 721.010803,1037.085327 
	C721.077332,1035.353027 720.682312,1033.671997 719.578552,1032.420166 
	C718.345764,1031.021851 717.822937,1027.679810 715.707031,1028.654297 
	C713.569824,1029.638550 712.024170,1032.281738 710.688232,1034.482788 
	C709.951965,1035.695801 711.620850,1035.820312 712.512878,1036.078247 
	C713.920715,1036.485229 715.164551,1037.158203 714.332947,1038.850464 
	C713.747925,1040.040894 712.780640,1040.849365 711.108521,1040.518799 
	C705.825806,1039.474487 702.052490,1030.249756 705.387207,1026.155029 
	C706.532410,1024.748779 707.404724,1023.458252 706.288696,1021.723938 
	C704.802124,1019.413818 701.944397,1018.138489 699.930237,1018.558716 
	C692.787598,1020.048950 686.133911,1017.483765 679.259216,1016.992981 
	C678.134521,1016.912659 676.730774,1017.189941 676.354431,1015.710327 
	C675.990601,1014.280029 676.866577,1013.171326 677.866699,1012.247375 
	C678.222534,1011.918701 678.716919,1011.538574 679.154541,1011.530334 
	C685.095825,1011.419250 688.637878,1009.816772 686.430176,1002.617065 
	C685.794556,1000.544006 688.290161,1001.457458 689.642212,1002.066772 
	C693.009155,1003.584106 696.087158,1003.836365 697.946411,999.364868 
	C697.902771,998.161133 697.684265,997.390503 697.031433,996.777527 
	C695.075378,994.941101 694.066162,993.047119 696.923767,991.130615 
	C699.142029,989.642822 704.095825,990.224060 705.164185,992.084229 
	C705.964966,993.478455 705.426758,994.731445 704.418152,995.686279 
	C701.701782,998.257935 701.594116,1001.932983 700.317627,1005.103821 
	C699.689026,1006.665283 700.201111,1008.041626 701.907043,1008.764587 
	C704.029175,1009.663940 703.767456,1007.784790 704.418030,1006.770325 
	C706.065613,1004.201477 709.073975,1004.178345 712.179932,1006.560730 
	C713.959045,1007.925415 715.252808,1009.536377 714.970825,1011.936340 
	C715.381409,1006.865540 711.722229,1004.761230 708.049683,1002.832947 
	C706.317139,1001.923157 704.041809,1001.811768 703.402588,999.530396 
	C702.631042,996.776611 705.301147,996.585083 706.598938,995.267029 
	C708.514648,993.321594 709.200562,990.495483 711.685730,988.723083 
	C716.569824,985.239746 717.380066,984.989624 722.249268,988.248047 
	C726.289246,990.951721 726.294189,990.953430 727.912598,986.238159 
	C728.185913,985.441711 728.421692,984.376465 729.151672,984.152222 
	C733.897583,982.694214 736.933350,978.186707 742.203186,977.400146 
	C745.973633,976.837341 750.016663,976.316528 753.568115,973.605286 
	C757.831970,971.755371 762.168640,971.798096 766.305969,970.729614 
	C773.232056,968.940796 779.762512,965.841125 787.166687,964.530518 
	C788.540649,963.669067 789.674316,963.215393 791.306519,963.299805 
	C793.837769,965.429871 795.997192,966.443420 798.388489,963.029968 
	C800.466797,961.875488 802.314453,961.100952 804.319580,960.952271 
	C806.143433,960.816895 808.110474,960.684692 808.214111,963.649414 
	C804.091370,966.451965 801.690918,971.289490 795.336121,969.942383 
	C793.697998,969.595215 790.364624,973.671082 786.411682,972.333496 
	C784.804749,971.789856 783.962036,973.796204 784.051208,975.414246 
	C784.233032,978.714417 782.183411,979.413574 779.517212,980.179749 
	C776.821594,980.954346 773.423584,980.421204 771.761780,983.817688 
	C771.064148,985.243774 770.192627,983.981262 769.541199,983.284119 
	C768.698669,982.382751 767.689941,981.587891 766.593201,982.729004 
	C765.659180,983.700745 766.083252,984.865540 766.705078,985.936890 
	C769.695068,991.088745 769.660339,990.967285 763.962402,992.254456 
	C760.459900,993.045654 757.229004,993.771851 754.190857,990.906006 
	C752.419922,989.235718 750.662659,990.269653 748.859436,991.549316 
	C744.326721,994.765747 743.509216,994.472290 741.359009,989.670776 
	C740.917297,988.684326 740.924316,987.124512 739.287476,987.367065 
	C738.032654,987.553040 738.028076,988.761169 738.024170,989.747131 
	C738.009705,993.349365 735.718872,995.953186 733.778015,998.523682 
	C732.044373,1000.819946 732.186646,1001.893433 734.812500,1002.814941 
	C736.375916,1003.363586 737.880920,1004.081116 739.449707,1004.611572 
	C740.779663,1005.061401 741.762085,1004.744812 741.780762,1003.081726 
	C741.793152,1001.978210 740.406921,1000.113098 742.952942,1000.309387 
	C744.556396,1000.432983 745.559753,1001.785339 745.838013,1003.358459 
	C746.179871,1005.291077 746.126038,1007.197021 747.262817,1009.051880 
	C748.602417,1011.237793 747.445679,1012.044312 745.077698,1011.548523 
	C741.831299,1010.868774 738.498779,1009.995300 735.728943,1012.812622 
	C733.564392,1015.014404 733.032532,1017.053589 736.762939,1018.746826 
	C739.227905,1023.636414 738.600098,1025.800659 733.925659,1026.803101 
	C730.572693,1027.522217 731.088623,1030.963379 728.757080,1032.589111 
	C728.187500,1033.618286 727.999634,1034.420288 728.144104,1035.606445 
	C728.179993,1036.853760 728.048279,1037.734863 727.231873,1038.745483 
	C726.503845,1039.257690 726.007690,1039.515381 725.255737,1039.886475 
	C724.614441,1040.380371 724.154236,1040.527466 723.309753,1040.220947 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M784.205811,1076.684326 
	C782.865051,1076.946167 781.638245,1077.090210 781.068604,1075.335449 
	C780.822083,1074.833374 780.644104,1074.666626 780.733093,1074.250000 
	C781.556335,1072.654053 782.846497,1072.152954 783.942993,1071.416992 
	C786.813416,1069.490234 790.895325,1068.394897 787.344727,1063.304565 
	C786.199219,1061.662109 787.835938,1058.465088 790.635986,1058.172974 
	C794.463013,1057.773804 795.687317,1056.237183 794.386169,1052.596680 
	C793.597290,1050.389404 794.313110,1049.026855 796.853455,1048.976929 
	C798.098389,1048.952393 799.104614,1048.444458 799.761597,1047.346924 
	C800.564575,1046.005615 801.185242,1044.714722 799.765503,1043.310547 
	C798.323059,1041.883911 797.053040,1042.208496 795.988403,1043.615967 
	C793.646240,1046.712280 791.598083,1047.043457 789.765930,1043.191895 
	C789.254150,1042.116089 788.212036,1042.123657 787.212646,1042.003540 
	C784.584229,1041.687134 781.834106,1041.707764 779.363586,1040.893188 
	C774.286316,1039.219238 771.697632,1039.083862 771.459534,1046.072632 
	C771.365173,1048.842651 768.124695,1053.529907 763.375854,1054.990356 
	C760.218201,1052.559326 760.844604,1049.933838 762.816895,1046.961426 
	C766.143616,1045.005371 766.079407,1042.696899 764.380676,1040.071289 
	C762.877502,1037.747803 761.989075,1034.856201 758.543457,1033.851562 
	C754.256958,1027.580811 754.257019,1027.580811 750.583862,1033.789795 
	C748.558167,1037.213989 746.255310,1037.461426 743.526062,1034.479126 
	C739.681030,1030.277710 740.099182,1024.667114 738.853027,1019.327698 
	C743.257080,1018.859985 746.220764,1016.100952 749.705322,1014.202026 
	C752.186890,1012.849609 750.951843,1009.031128 754.624634,1009.016113 
	C757.477600,1010.989929 759.064758,1013.800842 761.554016,1016.097351 
	C762.456055,1013.545898 761.122314,1011.652893 760.054688,1009.345581 
	C761.801880,1004.786560 765.385803,1005.638855 768.443420,1006.523987 
	C771.308472,1007.353333 770.890564,1009.869873 770.176575,1012.329163 
	C769.049500,1016.211670 770.734619,1020.100220 770.473938,1024.091919 
	C770.271545,1027.191895 773.056213,1025.933228 774.857178,1025.124878 
	C778.503113,1023.488037 784.407654,1025.682007 785.920959,1029.234619 
	C786.607239,1030.845703 786.918152,1033.745117 788.783997,1033.231812 
	C790.843811,1032.665161 790.764709,1029.862305 790.371887,1027.703979 
	C790.253601,1027.054199 790.136108,1026.404175 790.009094,1025.377197 
	C790.120239,1024.711426 790.245850,1024.233643 790.128052,1024.186401 
	C789.864258,1024.080444 789.583740,1024.313965 789.203125,1024.794067 
	C787.426575,1024.856812 785.363525,1025.443604 785.115479,1022.999573 
	C784.904297,1020.919800 784.434814,1018.740723 787.304810,1017.616882 
	C788.749146,1017.051331 789.776123,1015.463135 791.049500,1014.406860 
	C792.497009,1013.206116 792.837097,1009.317383 794.675415,1010.588928 
	C796.884094,1012.116638 800.858459,1013.056030 800.200928,1017.652832 
	C799.902222,1019.741211 800.341675,1023.797791 803.297729,1023.444153 
	C806.530640,1023.057434 804.662476,1019.382690 804.774658,1017.149597 
	C804.844727,1015.754333 803.537354,1014.379517 804.652893,1012.625793 
	C806.849854,1013.317139 805.812195,1016.185181 807.709290,1017.155945 
	C809.799866,1018.225647 811.574890,1016.522888 813.530090,1017.004028 
	C814.517334,1017.247009 815.702393,1017.263794 815.629822,1018.576111 
	C815.558716,1019.862732 814.351929,1019.818542 813.362000,1019.992676 
	C806.863159,1021.135803 803.653503,1026.619629 805.957275,1032.425049 
	C806.689453,1034.270020 807.733521,1035.537598 809.739014,1035.988647 
	C810.681213,1036.200562 811.641357,1036.565674 812.451599,1037.083374 
	C814.245728,1038.229858 815.010071,1045.922485 818.212830,1037.798340 
	C818.729370,1036.488037 820.605774,1037.953003 821.306641,1039.084961 
	C822.157837,1040.459961 822.517395,1042.123169 820.539551,1042.761353 
	C818.665894,1043.365967 817.020752,1043.864990 817.013184,1046.611084 
	C813.778076,1047.863159 816.319153,1049.275879 816.881348,1050.088745 
	C819.397217,1053.726807 818.660828,1056.442871 814.927795,1058.585938 
	C814.071594,1059.077393 813.309265,1059.733154 812.506348,1060.316772 
	C811.287964,1061.202637 810.670959,1062.327759 811.577148,1063.717041 
	C812.459229,1065.069092 813.632568,1064.882935 815.059875,1064.355225 
	C820.453491,1062.361084 822.917114,1063.819580 822.895203,1069.384644 
	C822.884521,1072.093750 825.023376,1073.894287 824.953857,1076.422119 
	C824.924500,1077.488892 825.480408,1078.962769 823.930481,1079.385620 
	C822.822693,1079.687866 821.802124,1078.688599 821.674316,1077.854492 
	C820.988770,1073.383179 816.167786,1072.170898 814.476624,1068.290283 
	C813.272888,1065.528198 810.119080,1064.878784 807.185059,1067.687378 
	C807.128052,1068.166626 807.256104,1068.333374 807.192078,1068.750000 
	C805.069824,1068.546875 801.775879,1070.249878 801.474792,1067.480469 
	C801.166870,1064.648926 802.968872,1061.779175 805.890503,1060.240601 
	C807.181458,1059.560669 809.876587,1060.781494 809.640015,1058.173340 
	C809.405457,1055.587158 807.152100,1054.931152 804.390625,1055.001465 
	C802.800049,1054.780396 801.751526,1052.120605 800.487183,1054.411865 
	C799.658081,1055.914429 801.827820,1055.795898 802.765869,1056.762207 
	C802.538635,1060.683228 799.178345,1061.148438 796.740906,1062.637817 
	C794.648682,1063.916260 792.345642,1064.671631 794.295959,1068.212036 
	C795.976685,1071.263306 793.332458,1072.453003 790.638184,1072.986572 
	C788.496155,1073.410767 785.978271,1071.941040 784.104980,1074.624634 
	C784.378967,1075.383423 784.516174,1075.839600 784.205811,1076.684326 
z"/>
<path fill="#D0CFCE" opacity="1.000000" stroke="none" 
	d="
M738.666687,1019.000000 
	C741.727722,1021.296692 741.141174,1024.735840 741.977234,1027.681885 
	C742.289001,1028.780518 742.120178,1030.255493 742.795166,1030.961426 
	C744.240967,1032.473511 744.696594,1035.564941 747.044617,1035.409912 
	C749.609009,1035.240479 749.528076,1032.358887 750.142456,1030.332642 
	C750.756836,1028.306396 751.223267,1025.994995 754.251221,1026.250122 
	C758.255127,1026.587402 757.849426,1029.852783 758.740356,1032.723389 
	C759.727539,1033.760620 760.097839,1034.656006 759.969604,1036.068604 
	C758.209534,1039.176147 760.612610,1039.515259 762.517822,1040.530029 
	C764.368164,1042.317627 765.450806,1044.038452 764.241882,1046.682251 
	C762.393921,1049.248047 762.078796,1051.676270 762.992310,1054.637451 
	C759.696228,1058.017334 755.974609,1058.817261 751.390625,1057.057129 
	C750.952820,1052.756226 747.698120,1054.021484 745.527039,1054.067993 
	C740.783569,1054.169434 735.468628,1054.688599 733.722229,1049.261475 
	C732.619141,1045.833862 729.092163,1043.643799 729.395752,1039.697632 
	C729.409729,1039.515991 728.277039,1039.246216 727.339233,1039.008789 
	C727.000000,1037.933350 727.000000,1036.866577 727.000000,1035.399902 
	C727.124451,1034.561890 727.176392,1034.115234 727.078186,1033.330078 
	C727.514587,1030.609741 731.024475,1029.463379 729.612549,1026.125000 
	C729.207153,1025.166382 731.042542,1024.817383 731.890625,1025.092896 
	C737.286011,1026.845459 737.152344,1023.376282 737.000000,1019.415161 
	C737.444458,1019.000000 737.888916,1019.000000 738.666687,1019.000000 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M882.750000,1070.000000 
	C884.616150,1072.325439 885.997314,1072.932373 887.276306,1069.661865 
	C887.936707,1067.973145 887.842834,1065.664185 890.641968,1065.054199 
	C888.950317,1068.523071 888.612610,1071.017578 893.791626,1072.046021 
	C897.228149,1072.728516 899.036255,1076.929443 902.942139,1077.688965 
	C902.833923,1079.315552 902.294128,1080.446045 901.016113,1081.591431 
	C899.234802,1082.520508 897.724976,1082.561890 895.842590,1081.974854 
	C893.917480,1080.778442 894.384644,1079.295288 894.664368,1077.792114 
	C893.833923,1078.758301 894.122253,1079.938599 893.770691,1081.347412 
	C892.689026,1083.145020 891.411926,1083.873657 889.318909,1083.239136 
	C883.242676,1080.931152 881.330139,1076.644165 882.000000,1070.433594 
	C882.000000,1070.000000 882.500000,1070.000000 882.750000,1070.000000 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M826.531982,1016.151733 
	C830.458374,1015.694031 834.286804,1018.399597 837.594116,1014.587769 
	C838.525391,1013.514282 839.720947,1015.502075 840.286499,1016.669922 
	C841.136414,1018.424927 841.575623,1020.396851 839.541321,1021.462097 
	C837.704468,1022.423950 836.093323,1023.158264 835.792786,1025.538940 
	C835.615356,1026.944336 834.437561,1026.525391 833.446045,1026.204590 
	C830.416565,1025.224243 827.741028,1023.595947 825.377197,1021.534485 
	C823.075989,1019.527771 824.256897,1017.771484 826.531982,1016.151733 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M808.297241,964.132568 
	C807.544495,961.679749 805.976379,961.917908 804.306213,962.395020 
	C802.463684,962.921448 800.621887,963.450317 798.117798,963.697632 
	C796.575256,960.751038 797.822937,959.001099 800.028931,957.806091 
	C801.328735,957.101990 802.539246,956.312439 804.005493,955.135010 
	C807.327881,953.814392 810.295532,952.745789 813.714844,954.686401 
	C812.550537,957.564331 809.633606,959.750244 811.950378,963.629883 
	C810.963257,965.345581 809.785767,964.894470 808.297241,964.132568 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M759.581177,1008.998169 
	C765.442139,1010.452576 762.811768,1015.092957 763.545288,1018.351562 
	C763.715149,1019.106201 761.941345,1020.247803 761.459167,1019.052185 
	C759.997009,1015.426575 755.797729,1013.753967 755.044434,1009.351868 
	C756.388245,1008.999634 757.776550,1008.999207 759.581177,1008.998169 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M857.878723,1049.023315 
	C856.199768,1052.373657 854.430115,1050.369995 853.328064,1049.104126 
	C852.128784,1047.726685 850.554260,1045.922119 851.513062,1043.875854 
	C852.144714,1042.527588 853.546021,1043.175049 854.348145,1044.109131 
	C855.599487,1045.566162 856.711243,1047.143066 857.878723,1049.023315 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M789.250000,1025.000000 
	C789.203430,1023.631653 788.411499,1021.772217 790.473999,1021.273438 
	C791.525818,1021.019165 792.416931,1021.785461 792.682556,1022.844604 
	C793.077271,1024.418701 792.049316,1024.873657 790.364746,1024.986694 
	C790.000000,1025.000000 789.500000,1025.000000 789.250000,1025.000000 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M878.232178,1054.277344 
	C880.037720,1054.433228 880.981628,1055.176636 880.694885,1056.787720 
	C880.563965,1057.523438 879.874817,1057.774048 879.198303,1057.458862 
	C877.861267,1056.835571 876.282837,1056.245239 878.232178,1054.277344 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M762.678711,999.183594 
	C762.441284,1000.671265 761.757874,1001.136108 760.700195,1000.377991 
	C760.421082,1000.177795 760.234009,999.452759 760.375488,999.132507 
	C760.913879,997.913513 761.747742,998.059021 762.678711,999.183594 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M868.740112,1064.227661 
	C868.678528,1065.171997 868.340576,1065.678955 867.633728,1065.285400 
	C867.378174,1065.143066 867.202820,1064.436890 867.326782,1064.119019 
	C867.598877,1063.421509 868.144836,1063.357910 868.740112,1064.227661 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M823.465820,1025.955811 
	C823.823914,1025.306152 824.183472,1025.296143 824.500549,1025.762939 
	C824.566833,1025.860596 824.563965,1026.086304 824.494568,1026.181152 
	C824.163208,1026.634399 823.803955,1026.618042 823.465820,1025.955811 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M874.658508,1052.586914 
	C875.373535,1052.388184 875.633728,1052.637695 875.521912,1053.193359 
	C875.498718,1053.308716 875.334839,1053.463379 875.218140,1053.480469 
	C874.657166,1053.563477 874.422241,1053.290527 874.658508,1052.586914 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M801.341553,1004.413208 
	C800.626892,1004.612000 800.366455,1004.362854 800.478210,1003.807129 
	C800.501404,1003.691772 800.665161,1003.536926 800.781799,1003.519714 
	C801.342529,1003.437012 801.577698,1003.709656 801.341553,1004.413208 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M777.341370,997.413208 
	C776.626770,997.611938 776.366272,997.362732 776.478210,996.807068 
	C776.501343,996.691772 776.665222,996.537048 776.781921,996.519775 
	C777.342651,996.436890 777.577515,996.709839 777.341370,997.413208 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M774.534180,993.044128 
	C774.176025,993.694275 773.816467,993.703552 773.499329,993.237000 
	C773.432922,993.139465 773.435974,992.913635 773.505310,992.818848 
	C773.836914,992.365112 774.196106,992.382019 774.534180,993.044128 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M814.335022,955.003052 
	C810.859924,954.530823 807.904114,955.780151 804.419495,956.001343 
	C800.056458,958.004517 796.020203,959.830505 791.871033,962.232178 
	C790.524597,962.475403 789.552795,962.666016 788.337891,963.349121 
	C784.095764,965.280884 779.801147,964.805664 775.818848,964.854248 
	C768.091919,964.948486 761.017700,967.940552 753.498474,968.739563 
	C751.356445,968.967224 749.668579,970.568481 748.161987,972.688721 
	C747.544678,973.298279 747.131409,973.559021 746.335815,973.850708 
	C745.119141,974.144897 744.213806,974.236267 743.086182,974.774048 
	C740.380737,976.031616 737.446594,974.620483 734.600342,976.746826 
	C731.164368,976.998230 728.332642,977.047485 725.503174,976.988403 
	C723.565186,976.948059 721.888428,977.353821 720.648682,978.979919 
	C718.473816,981.832214 716.009888,981.734009 713.189087,979.308960 
	C711.751953,975.876404 714.459900,975.321350 716.179138,974.440247 
	C721.709412,971.606201 727.967285,971.136108 733.812012,969.295349 
	C741.338013,966.925049 748.955261,964.651855 755.747070,960.588806 
	C761.198608,957.327515 767.261353,956.135925 773.005371,956.963379 
	C779.371399,957.880432 785.187378,956.593933 790.395935,954.230164 
	C799.476807,950.108948 809.109375,948.689270 818.580322,946.398132 
	C822.304016,945.497314 826.042175,944.812439 828.940674,941.980530 
	C829.804871,941.136169 831.004944,940.955994 832.577759,941.358643 
	C834.959839,945.873779 834.446350,947.772766 830.402222,948.979980 
	C826.301331,950.204285 823.030396,953.469727 818.236206,953.842651 
	C817.555664,954.362976 817.148987,954.631348 816.356567,954.927612 
	C815.554504,954.997925 815.111084,954.999512 814.335022,955.003052 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M817.058472,954.252563 
	C817.092834,953.346191 817.188171,952.694885 817.283447,952.043518 
	C821.739807,952.834534 824.483276,948.523254 828.339355,948.174255 
	C832.118164,947.832153 833.334534,946.291626 833.014526,942.445801 
	C834.279114,940.349976 836.181763,940.515930 838.326416,940.297363 
	C839.674805,940.375427 840.578918,940.159729 841.743042,939.509277 
	C842.555969,939.330383 843.022034,939.376526 843.737915,939.771240 
	C845.620056,943.611755 846.883972,943.151062 849.539246,940.413086 
	C854.952942,934.830566 860.120056,932.441223 867.806152,936.680359 
	C867.418030,938.083862 866.551575,939.038757 865.314758,938.891846 
	C856.491516,937.843323 850.137756,944.580017 842.195618,946.141235 
	C833.861511,947.779541 827.307983,953.945007 818.398987,955.000488 
	C817.640686,954.954651 817.350525,954.783752 817.058472,954.252563 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M816.788330,954.117554 
	C817.302856,954.125427 817.556702,954.320312 817.879578,954.792358 
	C817.555481,955.633362 817.034851,955.947693 816.153564,955.202698 
	C816.124268,954.691650 816.317993,954.438721 816.788330,954.117554 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M844.162109,939.703735 
	C843.556946,940.013428 843.115417,940.014587 842.342834,940.018250 
	C841.471863,940.544983 840.928406,941.065613 840.178345,941.778442 
	C837.943115,941.986328 835.914612,942.002075 833.452393,942.024902 
	C828.544250,942.655762 825.924133,946.801208 821.231140,947.587708 
	C807.593201,949.873291 794.357849,953.847046 781.444519,958.866150 
	C779.700012,959.544128 777.770813,959.367859 775.697754,959.040588 
	C768.819946,957.954956 761.828125,957.977600 755.989807,962.648560 
	C754.217773,964.066284 752.391235,964.766907 750.324707,965.433167 
	C742.807800,967.856445 735.467712,970.916077 727.840332,972.878113 
	C722.693970,974.202026 717.355103,974.582825 713.072571,978.644531 
	C712.556152,978.985840 712.112915,978.984192 711.337280,978.980469 
	C706.371948,978.115906 702.071228,979.421814 698.060547,981.438171 
	C687.469543,986.762878 676.054138,989.193176 664.236206,991.563721 
	C663.470947,991.829224 663.027222,991.935730 662.254761,992.055298 
	C661.098572,992.216125 660.316711,992.466431 659.369385,993.176758 
	C658.248779,993.801514 657.385376,993.896912 656.252808,993.230835 
	C654.402466,990.195496 652.165283,988.805481 648.769104,988.893066 
	C646.889404,988.941528 644.630310,988.481506 643.165527,987.403687 
	C640.261536,985.266724 637.586365,985.753662 634.333374,986.972046 
	C634.474670,983.691040 637.741150,982.458191 639.234619,979.895264 
	C639.698120,979.099854 640.653870,978.952393 641.407288,979.546936 
	C649.938599,986.280212 659.252197,984.885986 669.284241,983.765686 
	C680.190247,982.547729 688.984497,976.700562 698.953918,973.869324 
	C705.235779,972.085388 711.270691,968.690186 718.557983,968.989258 
	C721.042480,966.954041 723.662354,967.698059 726.093384,967.531494 
	C727.896790,967.407898 729.764343,967.812317 731.781738,966.628906 
	C732.543945,966.130066 733.013550,965.921753 733.868896,965.687988 
	C736.786499,965.169128 739.272217,964.559753 741.541016,962.519165 
	C742.454102,961.995605 743.119263,961.797363 744.139404,961.615845 
	C746.144287,961.687256 747.414246,960.974121 748.664734,959.465332 
	C751.880493,958.098511 754.460876,956.157654 757.806885,955.046753 
	C759.008240,954.612793 759.627380,953.977234 760.424072,952.994385 
	C761.465576,952.102905 762.393188,951.821411 763.749268,951.673706 
	C769.256592,953.040833 774.488831,953.580383 779.798706,953.482239 
	C781.795410,953.445312 783.845581,953.602051 785.209106,951.131531 
	C785.865723,950.590332 786.304321,950.399292 787.137573,950.305542 
	C789.107239,950.686401 790.680786,952.185425 792.676392,950.316040 
	C793.394043,949.927490 793.832336,949.760315 794.637085,949.557861 
	C801.066223,945.122681 807.785339,943.153870 815.321045,943.233826 
	C820.304871,943.286743 825.053284,941.611877 829.734924,938.927612 
	C830.225464,938.558228 830.432373,938.424927 830.973999,938.136597 
	C837.130371,936.633789 842.541992,934.201599 848.229126,931.491272 
	C848.966736,931.061523 849.415894,930.872498 850.238037,930.647949 
	C852.267395,930.494080 853.618103,928.875183 855.730591,929.782959 
	C856.130737,933.775818 853.554016,935.186279 850.538696,935.863281 
	C848.039062,936.424377 845.856628,937.261780 844.162109,939.703735 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M918.838684,903.357849 
	C919.381470,902.969055 919.672180,902.832336 920.222046,902.719727 
	C921.020813,902.457214 921.598694,902.393372 922.580688,902.739258 
	C922.964417,902.964111 923.036621,903.035461 923.073120,903.070679 
	C924.379333,904.749146 923.618408,906.108948 922.308594,907.694702 
	C921.957520,908.134521 921.805420,908.299438 921.402100,908.684448 
	C919.858765,909.699341 918.633667,910.588989 917.198120,911.736938 
	C916.954163,911.947876 916.497070,911.997742 916.268799,912.024902 
	C909.901978,917.030518 903.000366,919.326050 895.174988,917.381775 
	C889.233154,915.905457 884.508057,917.772766 879.996887,921.505920 
	C874.711609,925.879822 869.896301,925.759766 863.951416,921.290588 
	C863.268311,920.351990 863.009888,919.645142 862.839111,918.543579 
	C862.827026,917.973083 862.846558,917.750061 862.959595,917.220093 
	C863.192078,916.415833 863.982117,916.175354 863.533936,915.202393 
	C863.428467,914.730957 863.407288,914.536865 863.410889,914.049805 
	C863.553894,913.181580 863.755371,912.630981 864.008667,911.792419 
	C864.387268,910.763062 864.336670,909.995300 864.197754,908.916992 
	C864.233093,908.195862 864.363586,907.809692 864.797791,907.231934 
	C865.447083,906.986694 865.876160,906.961182 866.625977,906.968689 
	C869.846252,907.015625 869.321838,909.608032 869.958008,911.195801 
	C870.907532,913.565735 872.215698,914.256409 874.487122,912.941833 
	C876.193481,911.954285 878.053589,911.252747 879.783325,910.323975 
	C884.216553,907.943420 884.557129,906.728943 882.478271,901.271118 
	C882.259949,900.425415 882.285461,899.929138 882.609436,899.101685 
	C883.212524,898.435669 883.678894,898.226379 884.583984,898.135437 
	C885.213501,898.197693 885.458130,898.261047 886.026245,898.512085 
	C888.383667,900.906616 888.626404,904.191223 891.093079,906.199524 
	C896.600281,910.683411 902.714600,910.116028 909.287964,908.486938 
	C912.668518,906.894836 916.298767,906.525574 918.838684,903.357849 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M863.717346,919.704224 
	C870.549438,924.460693 873.680847,924.175110 879.741028,919.354736 
	C883.967834,915.992737 888.590637,913.708008 894.461121,915.540588 
	C900.565369,917.446228 906.513611,916.613525 911.996094,913.047729 
	C912.920959,912.446228 914.173645,912.348938 915.657715,912.035400 
	C915.859497,912.892700 915.678528,913.733398 915.254089,914.794006 
	C913.620117,916.357239 911.707336,917.024963 910.220154,918.765137 
	C909.550354,918.997070 909.110107,919.003845 908.339844,919.015015 
	C903.060120,920.370178 898.238098,921.217468 892.905151,920.389404 
	C888.540222,919.711670 884.350708,922.609802 880.590881,924.811279 
	C875.701538,927.673950 871.018860,929.119934 865.597229,926.717468 
	C863.490845,925.784058 861.385376,925.945251 859.260254,927.743774 
	C858.183350,928.579285 857.373962,929.165955 856.287903,929.883301 
	C854.552979,930.637512 853.094666,931.260986 851.291504,931.909668 
	C850.735474,931.666138 850.467041,931.346741 850.531433,931.066223 
	C851.218262,928.076965 860.312988,919.921753 863.717346,919.704224 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M859.372192,928.005127 
	C859.902222,924.186707 862.314514,922.955811 865.606995,924.896362 
	C870.608582,927.844177 874.643250,926.564209 879.275940,923.667542 
	C885.603394,919.711304 892.842834,917.901733 900.358154,919.264771 
	C902.914551,919.728394 904.933289,916.879028 907.697998,918.873413 
	C905.888611,921.780396 898.729004,923.712952 894.890503,922.781860 
	C889.834473,921.555420 885.375183,923.622864 881.537415,926.411621 
	C879.551514,927.854614 877.878479,929.080933 875.416077,929.005188 
	C874.587158,928.979614 873.540955,928.716858 872.959229,929.106934 
	C868.222900,932.283264 863.968079,930.579590 859.372192,928.005127 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M960.672791,900.766357 
	C959.637024,905.782593 955.127441,907.286804 951.349487,909.960632 
	C945.112305,911.787598 939.188660,912.501038 933.079102,911.035706 
	C928.917725,910.037598 925.111389,914.613770 920.367126,911.986938 
	C918.910889,910.655518 919.560303,909.580627 920.694336,908.245056 
	C920.945984,907.952271 920.997559,907.997559 920.975830,907.970825 
	C924.506531,904.684326 928.401978,904.586609 932.858398,906.046204 
	C941.090271,908.742432 948.920105,907.825745 956.062012,902.525940 
	C957.298279,901.608521 958.399292,900.022583 960.672791,900.766357 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M920.609863,908.001099 
	C920.653076,909.062378 920.360046,910.172485 920.018738,911.624146 
	C919.216797,911.975403 918.463257,911.985046 917.331909,911.971313 
	C917.190430,909.907471 918.406189,908.701172 920.609863,908.001099 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M910.334595,918.949951 
	C910.211243,916.341553 912.311523,915.772034 914.659180,915.050781 
	C914.483826,917.332520 912.498108,918.028625 910.334595,918.949951 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M961.232117,900.796875 
	C956.550842,902.703979 952.789795,905.660461 948.497498,907.725647 
	C943.636230,910.064697 939.147827,908.855652 934.894531,908.105164 
	C930.359802,907.304932 926.174927,905.694519 921.338684,907.955017 
	C921.640930,906.556335 922.327820,905.168701 923.062195,903.443481 
	C926.202271,901.340881 928.867981,899.854248 931.910034,903.895447 
	C933.114014,905.494873 936.715759,905.289490 939.624023,905.876221 
	C940.763733,905.866943 941.505127,905.887268 942.617188,905.879883 
	C943.648621,905.247986 944.449036,905.010254 945.638916,904.927307 
	C950.917725,905.993774 953.831421,902.271729 957.373047,900.036194 
	C958.759644,899.161011 959.778687,896.859619 961.979492,899.209473 
	C961.878845,899.865723 961.682495,900.238098 961.232117,900.796875 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M964.496033,896.019531 
	C964.750305,894.292603 965.446411,892.566650 966.248657,890.500854 
	C970.538696,887.720398 973.385315,887.909851 975.903687,892.985107 
	C978.124207,897.460083 982.947937,897.116455 989.601868,893.025879 
	C989.451599,897.126160 984.660278,899.560730 979.175720,898.576355 
	C974.429871,897.724548 970.015015,895.015137 964.496033,896.019531 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M990.399841,892.976562 
	C996.815125,885.799866 996.847839,885.741455 1004.561035,890.057434 
	C1007.125183,891.492310 1009.252441,891.155701 1011.661255,889.157471 
	C1011.814819,892.676575 1010.417175,894.287537 1006.311340,893.377808 
	C1001.239868,892.254089 996.020752,891.781799 990.399841,892.976562 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M1012.325317,888.943848 
	C1012.195190,886.946167 1013.411377,885.741638 1015.565918,884.970825 
	C1015.870789,887.080566 1014.615417,888.271545 1012.325317,888.943848 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M615.986816,1024.596436 
	C609.269714,1021.689270 609.269714,1021.689270 611.947021,1013.379150 
	C612.180237,1012.454468 612.360474,1011.908997 612.770386,1011.181763 
	C616.566833,1009.049805 619.887451,1006.315186 624.706299,1007.708679 
	C623.186707,1011.252319 621.778320,1014.477905 624.337341,1018.082825 
	C625.216858,1019.321777 625.662842,1021.497925 624.321960,1022.131897 
	C622.188965,1023.140442 622.261414,1020.812378 621.921509,1019.493164 
	C621.124817,1016.400940 618.194519,1016.377441 616.301636,1016.690857 
	C613.992371,1017.073303 615.163269,1019.632812 615.300781,1021.307068 
	C615.380798,1022.280823 615.740051,1023.231628 615.986816,1024.596436 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M588.545776,1016.000000 
	C588.822266,1018.034180 589.726379,1019.359253 591.503113,1020.351013 
	C592.770569,1021.058472 594.208862,1022.128662 593.601562,1023.809753 
	C592.955627,1025.598267 591.090820,1024.947388 589.709778,1024.973389 
	C585.786316,1025.047485 581.860535,1024.999756 577.467834,1025.000000 
	C577.002258,1016.000061 577.002258,1016.000061 588.545776,1016.000000 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M625.255188,1007.758789 
	C620.752502,1007.088135 617.742310,1010.838379 613.387085,1010.989624 
	C614.388672,1008.620056 616.261597,1006.730530 619.163147,1006.833618 
	C622.822083,1006.963745 623.536377,1003.103577 626.619812,1002.019287 
	C627.076111,1003.995483 626.278870,1005.752686 625.255188,1007.758789 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M589.598022,1044.975342 
	C589.232544,1046.351074 588.324341,1046.750610 587.467529,1047.254761 
	C585.230408,1048.571289 583.735046,1047.398560 582.352905,1045.808838 
	C581.704834,1045.063599 580.892456,1044.199463 581.914551,1043.284058 
	C583.379639,1041.972168 585.122314,1041.678955 586.963013,1042.529419 
	C588.004578,1043.010498 589.109741,1043.428833 589.598022,1044.975342 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M719.337646,967.206177 
	C714.534180,963.455994 710.637512,967.141602 706.533997,967.998657 
	C695.382019,970.327698 685.990112,977.666687 674.718323,979.487061 
	C667.023987,980.729675 659.179260,980.588318 651.556335,978.603943 
	C648.372314,977.775208 645.610291,975.921448 643.309753,973.041016 
	C642.262329,971.710266 642.583557,970.419556 642.974182,968.758972 
	C643.270691,968.163452 643.419800,967.940674 643.874939,967.455994 
	C646.226135,966.025696 647.926880,966.743469 648.712036,968.654419 
	C650.782288,973.692871 655.647888,974.634033 659.773804,975.135864 
	C668.869385,976.242065 677.991699,975.485657 686.382568,971.082214 
	C689.781677,969.298462 693.368469,968.054382 696.977722,966.777832 
	C701.486206,965.183167 706.136841,963.881165 710.288086,961.352478 
	C714.362122,958.870728 718.938171,958.946899 723.389099,959.505615 
	C730.115967,960.349915 736.197144,958.877136 742.042236,955.652283 
	C744.984253,954.029175 748.129456,952.910767 751.884460,954.515198 
	C753.168518,957.308350 751.767090,958.229370 749.608765,959.023315 
	C740.589600,962.341431 731.609131,965.758606 721.664307,967.387695 
	C720.737244,967.670532 720.197510,967.638306 719.337646,967.206177 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M593.299255,968.083069 
	C590.900146,960.096558 587.255493,952.676025 587.923706,944.269775 
	C588.215393,940.599915 588.892395,937.102600 591.521423,934.001221 
	C592.596375,934.456055 592.969482,935.267212 592.811829,936.079468 
	C590.674011,947.093323 595.460999,956.425903 600.089355,966.187927 
	C600.149719,970.030212 602.073730,972.442444 604.532043,974.577271 
	C610.876709,980.086914 619.548096,980.837524 628.291626,976.362549 
	C631.826294,974.746887 634.420105,971.698730 638.766846,972.518311 
	C639.222107,972.954529 639.343567,973.185730 639.372925,973.797485 
	C639.580078,978.254150 635.133850,977.428589 633.372925,979.887573 
	C626.473083,985.379578 619.011353,987.687195 610.528503,986.653809 
	C603.807312,985.834900 599.875305,983.013000 598.197510,977.424438 
	C597.921509,977.128906 597.929382,977.085815 597.911987,977.072449 
	C596.979858,975.910278 596.649536,974.375183 595.290955,973.141296 
	C595.070190,972.172974 595.033569,971.461243 594.963501,970.394714 
	C594.444641,969.459656 593.922668,968.911499 593.299255,968.083069 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M597.923340,977.496521 
	C600.709656,979.284180 601.989990,982.917297 606.137329,983.868103 
	C613.501831,985.556519 620.563721,986.688965 627.387146,982.198730 
	C628.862915,981.227600 630.690796,980.791504 632.734497,980.119141 
	C633.097107,980.551880 633.081726,980.974487 633.048401,981.715271 
	C627.391174,985.561096 621.464600,987.808105 614.665894,988.526917 
	C610.398621,988.978088 606.421814,988.660767 602.432922,987.810608 
	C598.393921,986.949768 596.835144,983.078125 597.923340,977.496521 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M719.004272,967.297668 
	C719.442810,966.962585 719.877441,966.965393 720.638062,966.968018 
	C724.903137,966.006042 728.795654,964.417114 732.960327,966.527100 
	C731.126160,970.199463 727.990540,968.692444 725.364136,968.960144 
	C723.566040,969.143372 721.734192,968.995667 719.455078,968.990723 
	C718.995178,968.535583 718.997742,968.085571 719.004272,967.297668 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M639.593384,974.104614 
	C639.196350,973.945618 639.111877,973.713013 638.915955,973.126221 
	C638.349548,969.461426 640.745544,968.962708 643.464844,968.561157 
	C644.063049,969.962219 643.928284,971.166077 643.125000,972.627014 
	C641.947876,973.318909 640.926941,973.674988 639.593384,974.104614 
z"/>
<path fill="#1D2639" opacity="1.000000" stroke="none" 
	d="
M592.968994,978.610046 
	C593.024414,979.434143 593.066528,979.850403 593.149048,980.583008 
	C588.300049,984.448792 582.506226,984.934937 576.806824,985.539917 
	C574.373962,985.798157 573.284973,983.668579 572.891968,981.707947 
	C570.348938,969.019653 565.699951,956.897156 563.010071,944.242371 
	C560.975464,934.670654 557.540161,925.456604 555.282288,915.923035 
	C554.072937,910.816772 552.944031,905.895447 556.846191,900.970581 
	C561.824524,895.508911 569.974182,895.139099 571.716919,904.087463 
	C574.446228,918.101379 579.509094,931.569031 583.156860,945.370728 
	C586.081177,956.435120 589.787659,967.231018 592.968994,978.610046 
z"/>
<path fill="#D7D4D5" opacity="1.000000" stroke="none" 
	d="
M593.219971,978.762451 
	C589.784973,973.145447 587.903748,966.742798 586.025391,960.409851 
	C580.407227,941.468262 574.963196,922.470276 569.836853,903.390747 
	C568.594482,898.766785 567.228699,896.478699 562.296204,898.894592 
	C561.131409,899.465210 559.755554,899.605103 558.191528,900.157471 
	C557.096924,902.460144 555.355774,903.021118 552.970459,903.108765 
	C548.748840,901.163147 550.412598,898.878052 552.689087,897.542236 
	C557.556580,894.686035 563.187500,891.754272 568.552551,893.396545 
	C572.963989,894.747009 576.840149,898.446472 581.223938,900.669556 
	C581.744080,900.933350 582.004822,901.926636 581.764832,902.495483 
	C578.133606,911.100830 584.786377,918.089294 584.961304,927.095886 
	C587.824707,923.401062 590.463623,921.018127 592.653076,918.171631 
	C595.226440,914.825928 597.463135,914.894897 600.825745,918.368591 
	C600.325256,921.973999 598.659424,924.411804 595.305176,925.933289 
	C588.917419,925.996033 589.554688,931.797729 587.228394,934.988159 
	C586.532837,935.942017 586.663269,937.527588 585.398071,938.740723 
	C584.001099,939.944336 584.764648,940.740906 585.547852,941.790039 
	C585.111572,953.600586 590.401672,963.865356 592.942993,974.737244 
	C593.231995,975.973511 594.277588,977.116699 593.219971,978.762451 
z"/>
<path fill="#101925" opacity="1.000000" stroke="none" 
	d="
M594.958862,926.321411 
	C596.524048,923.846375 598.129150,921.733215 599.867920,919.310852 
	C600.838440,918.302490 601.264709,917.268677 602.263672,916.286926 
	C603.862671,915.024719 605.093506,913.780090 607.108521,913.190247 
	C608.723389,913.126404 609.904236,912.760437 611.241089,911.796936 
	C613.756897,910.647156 615.945496,909.496094 618.806763,910.508301 
	C620.324280,913.278992 618.296997,913.902466 616.659790,914.633911 
	C606.038025,919.378906 599.484985,927.384338 597.102600,938.796997 
	C596.791931,940.285522 596.489380,941.791260 596.947083,943.237549 
	C599.348267,950.825806 601.366821,958.521973 607.494141,964.368347 
	C613.517578,970.115784 628.510803,972.607727 634.919678,968.415039 
	C636.718933,967.238037 639.087280,966.106567 639.222412,963.855103 
	C639.385925,961.130005 635.759644,962.618958 634.614502,960.458862 
	C634.390442,959.304016 634.650574,958.591858 635.340088,957.624512 
	C642.294250,953.624573 645.049438,955.153320 646.872803,963.980713 
	C646.662354,965.762451 645.438782,966.434021 644.491028,967.752747 
	C644.269104,968.054565 643.976685,968.328918 643.904663,968.515564 
	C641.834717,969.287476 640.716309,971.046997 638.977539,972.490723 
	C635.294128,973.103882 633.129089,976.250549 629.498901,977.142639 
	C617.268677,982.723755 604.119873,978.150146 599.231567,967.335693 
	C594.838135,961.044250 593.263062,954.052673 591.008850,947.326782 
	C589.541992,942.950500 590.886108,938.860046 591.903198,934.311157 
	C591.892700,931.060303 594.289917,929.237122 594.958862,926.321411 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M551.824463,902.223511 
	C553.815430,901.549500 555.484558,901.020386 557.529968,900.431519 
	C557.514160,902.269409 558.975342,904.746704 555.647583,905.713013 
	C554.450073,906.060730 555.316956,908.057068 555.598083,909.351868 
	C557.876282,919.846741 561.510376,930.008362 563.806885,940.452576 
	C566.755981,953.865051 571.943176,966.676880 574.019836,980.271545 
	C574.602722,984.087891 576.529297,984.697632 579.783142,983.885498 
	C583.951782,982.844971 588.153381,981.936157 592.765137,980.934937 
	C594.579285,983.145752 592.312134,983.467285 591.059021,984.622925 
	C589.492859,986.243835 587.673828,986.755798 585.533020,987.474304 
	C583.056885,988.021423 581.260925,989.230713 579.251404,989.852112 
	C575.665833,990.960938 572.451172,990.811401 570.642090,986.325073 
	C570.454956,984.102966 569.730347,982.441223 569.002258,980.381470 
	C568.641724,979.273438 568.515503,978.510986 568.446655,977.348633 
	C562.630615,955.665283 555.515198,934.774902 548.935364,913.316223 
	C548.736145,912.441956 548.669495,911.937073 548.612122,911.054932 
	C548.579529,910.435608 548.571594,910.189026 548.558350,909.575867 
	C548.551697,908.966797 548.550293,908.724243 548.545532,908.119629 
	C548.474365,906.793457 548.553040,905.837280 548.772400,904.532471 
	C548.934998,903.957520 549.023865,903.734863 549.316711,903.216675 
	C550.087341,902.399658 550.768555,902.289124 551.824463,902.223511 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M584.779785,986.484619 
	C586.739929,985.786255 588.400146,985.277832 590.431030,984.778687 
	C591.236084,986.433594 589.582153,987.071960 589.019287,988.604370 
	C586.372803,991.506409 583.712463,993.916931 580.378845,995.510315 
	C573.096741,998.990906 567.880920,997.484802 563.543335,990.652283 
	C562.476685,988.971985 561.660767,987.179932 560.555359,985.091980 
	C560.366821,984.752502 560.327759,984.418579 560.281189,984.258667 
	C560.234619,984.098694 560.328003,983.653198 560.379395,983.431519 
	C561.420959,981.662964 562.003174,980.084778 560.685181,978.064636 
	C560.403931,974.468506 560.239868,971.177490 560.071472,967.078247 
	C560.395752,965.288391 560.936890,964.598083 562.411743,964.979004 
	C563.585815,966.077209 564.010864,967.198975 564.363953,968.738403 
	C565.577209,973.558289 566.411133,978.112976 567.650024,982.542786 
	C570.029602,991.051270 574.340393,992.638916 581.918945,988.184204 
	C582.773193,987.682129 583.617004,987.162354 584.779785,986.484619 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M560.254150,985.176208 
	C563.563293,984.963928 563.837402,988.122437 565.120117,989.957642 
	C569.632812,996.414062 573.849915,997.453430 580.907349,993.813354 
	C583.570740,992.439575 584.999451,989.386536 588.560852,988.947632 
	C585.918640,996.290588 573.149414,1001.619751 566.391052,998.132080 
	C561.378784,995.545349 560.029846,990.874634 560.254150,985.176208 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M595.105286,973.237793 
	C597.023376,973.233826 598.027344,974.338013 597.926270,976.694702 
	C595.946899,976.721802 595.522644,975.158508 595.105286,973.237793 
z"/>
<path fill="#D0CFCE" opacity="1.000000" stroke="none" 
	d="
M585.792480,941.722046 
	C584.792297,941.912903 583.680176,942.069519 583.318176,940.869629 
	C583.022400,939.889221 583.649963,939.393982 584.846069,939.062378 
	C585.414978,939.813599 585.635742,940.613159 585.792480,941.722046 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M593.152893,968.163696 
	C593.996582,968.076050 594.729004,968.431885 594.916138,969.723877 
	C594.016418,969.891052 593.288269,969.528625 593.152893,968.163696 
z"/>
<path fill="#1D2639" opacity="1.000000" stroke="none" 
	d="
M557.699707,966.798645 
	C557.380920,975.200134 556.425659,983.017334 551.430664,990.060242 
	C549.950928,990.155090 549.372498,990.894897 548.741882,992.084106 
	C548.396667,992.498535 548.242188,992.655029 547.818420,993.012817 
	C543.349121,995.247620 538.897888,996.490723 534.391357,997.655518 
	C528.053589,999.293701 522.270142,998.119934 516.840210,994.609497 
	C516.089355,996.160400 517.180603,997.412720 516.712463,999.048584 
	C516.200317,1000.131226 515.567566,1000.538391 514.374023,1000.772034 
	C511.919495,1000.362061 510.054993,1001.301147 508.109436,1001.616272 
	C502.571838,1002.512878 499.730011,1000.939209 498.809814,995.405884 
	C497.717834,988.839661 496.089813,982.416931 494.567139,975.961487 
	C492.286407,966.292358 489.991913,956.628296 487.463837,947.015991 
	C485.239349,938.558044 482.308380,930.137695 483.898926,920.736572 
	C484.813263,918.809021 486.073547,917.785706 487.937500,916.854858 
	C489.358582,916.544128 490.304047,916.065430 491.673889,915.647156 
	C497.087372,914.710754 498.235474,915.292847 499.631958,920.086243 
	C500.928406,924.536072 502.192352,928.998840 503.354431,933.489197 
	C504.652039,938.503174 504.697510,938.491455 508.922119,935.626221 
	C520.190369,927.983826 535.640564,928.689941 546.200928,937.566772 
	C549.155884,940.614807 552.070129,943.196960 554.195435,946.964966 
	C556.822510,953.455566 559.150879,959.606873 557.699707,966.798645 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M516.164368,999.278198 
	C515.711914,998.122437 515.557251,997.268860 515.101013,996.630981 
	C514.063599,995.180542 513.270081,993.296997 514.429871,992.095886 
	C516.221375,990.240356 517.185120,992.874023 518.305115,993.666016 
	C524.482971,998.034790 530.995667,997.281555 537.645813,995.256104 
	C540.948730,994.250183 544.241577,993.210938 547.931152,992.227783 
	C548.985718,993.576233 548.542542,994.664001 547.328125,995.851196 
	C547.007446,996.040222 546.613525,996.112427 546.417847,996.154663 
	C545.200012,996.637329 545.026917,997.769531 544.042847,998.741455 
	C543.491943,999.108948 543.249695,999.227600 542.621582,999.486572 
	C535.568726,1003.347351 528.321533,1003.009155 521.083191,1002.582336 
	C519.064941,1002.463318 517.185669,1001.832092 516.164368,999.278198 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M557.845215,967.137573 
	C556.609253,960.586182 556.058411,953.911438 553.010986,947.423828 
	C553.021057,946.528870 553.277161,946.089294 554.052246,945.592346 
	C559.341675,946.762695 558.152832,951.554199 559.859558,955.137146 
	C560.173584,955.967468 560.300171,956.456909 560.423462,957.330872 
	C560.523987,958.718018 560.961121,959.636536 561.262085,960.980652 
	C561.685669,962.949036 560.867554,964.332153 560.551575,966.180664 
	C560.571960,966.559753 560.340942,966.784668 560.210327,966.878296 
	C559.440430,967.014099 558.801208,967.056213 557.845215,967.137573 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M542.738403,998.314697 
	C543.028687,998.040649 543.364258,997.827332 543.546814,997.748657 
	C544.284119,997.450439 544.849182,997.419250 545.762695,997.848450 
	C548.124573,1001.481079 543.605652,1000.781006 543.143433,1003.006592 
	C542.688354,1003.695496 542.361633,1004.056152 541.983276,1004.675171 
	C541.304810,1005.507324 540.565430,1005.737671 539.351929,1005.503540 
	C536.424927,1001.193237 540.906372,1000.644897 542.738403,998.314697 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M547.211731,996.314941 
	C547.433777,995.012512 547.860107,993.984802 548.304932,992.612915 
	C548.323364,992.268738 548.244385,992.144226 548.170898,992.139404 
	C548.540161,991.476440 549.176392,991.261475 550.264709,991.472961 
	C550.156372,993.670227 551.413879,996.807434 547.211731,996.314941 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M560.406982,978.195801 
	C563.832764,979.222229 564.208862,980.974304 560.765625,983.142578 
	C560.369263,981.683899 560.307678,980.158020 560.406982,978.195801 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M550.693970,991.388672 
	C549.973328,991.846375 549.354309,992.078613 548.416321,992.222656 
	C548.092651,990.193054 547.935608,988.075867 551.049072,989.938965 
	C551.089050,990.573059 550.979065,990.881226 550.693970,991.388672 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M546.161011,997.801147 
	C545.570129,998.042175 545.039429,997.964050 544.119080,997.777893 
	C544.081848,996.829895 544.641296,996.229492 545.901733,996.133301 
	C546.221802,996.625244 546.221497,997.053650 546.161011,997.801147 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M533.801025,1009.038940 
	C534.606995,1013.624268 529.201172,1017.128418 523.803772,1017.614807 
	C518.759583,1018.069397 513.049622,1017.768677 509.042084,1022.619568 
	C507.237244,1023.283447 505.495483,1023.585083 503.377441,1023.940857 
	C498.888123,1024.766846 494.904694,1024.389038 490.827637,1023.331299 
	C483.310394,1021.380920 476.645813,1024.395874 469.725952,1027.536377 
	C466.858246,1024.391724 470.012207,1023.184509 471.568054,1021.072266 
	C472.166260,1020.138611 472.658356,1019.589172 473.430664,1018.796387 
	C473.924286,1018.430603 474.139801,1018.309753 474.706207,1018.070496 
	C475.538910,1017.857300 476.024963,1017.800720 476.877106,1017.796631 
	C479.833160,1017.710083 482.309509,1017.410950 484.868622,1016.560669 
	C488.748108,1015.271545 492.408691,1017.873718 496.246796,1018.375549 
	C497.653961,1018.559509 498.915558,1019.571594 500.641937,1018.279785 
	C501.866669,1017.758789 502.807373,1017.582336 504.126740,1017.530090 
	C506.052765,1017.471008 507.703491,1017.825500 509.368408,1016.487793 
	C509.876892,1016.209839 510.088379,1016.112671 510.632080,1015.906067 
	C512.969177,1015.474915 515.225525,1015.790161 516.801147,1013.420410 
	C518.000610,1012.806152 518.952942,1012.718262 520.278320,1012.787170 
	C523.482361,1013.558716 526.363953,1014.509094 528.929199,1011.404724 
	C530.379700,1009.921936 531.696716,1008.870728 533.801025,1009.038940 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M468.605469,1026.696899 
	C471.117493,1026.928955 472.603363,1025.385864 474.169983,1024.326416 
	C480.200684,1020.247681 486.645721,1020.229797 493.162872,1022.292664 
	C496.252350,1023.270630 499.424683,1022.354309 502.700439,1023.728638 
	C497.951538,1026.738159 492.869995,1028.007324 487.101562,1025.843872 
	C482.394043,1024.078369 477.298828,1025.411621 473.339752,1027.630127 
	C466.823547,1031.281250 459.539185,1031.582275 452.401611,1033.988403 
	C455.396973,1030.324463 460.261017,1029.484863 464.764038,1027.232178 
	C465.981903,1026.165771 467.055176,1025.931519 468.605469,1026.696899 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M449.331818,1037.996338 
	C448.998901,1037.246338 448.999451,1036.498047 449.000275,1035.375488 
	C449.444794,1035.000244 449.889069,1034.999390 450.666809,1034.999878 
	C450.999817,1035.750122 450.999390,1036.499268 450.998230,1037.622437 
	C450.553436,1037.997070 450.109375,1037.997559 449.331818,1037.996338 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M538.429688,1004.694824 
	C539.558960,1004.812317 540.349609,1004.872498 541.535889,1004.933044 
	C540.640808,1006.728760 539.350037,1008.524109 538.003784,1010.652466 
	C536.872192,1010.945374 535.950378,1010.586487 535.126099,1009.432007 
	C534.011658,1007.068848 536.249939,1006.596680 537.187866,1005.109375 
	C537.665649,1004.807007 537.878296,1004.722290 538.429688,1004.694824 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M660.713745,991.023071 
	C661.446777,990.995972 661.891113,990.997253 662.668701,991.000488 
	C667.126526,991.446106 671.302917,990.560059 675.704346,991.744263 
	C672.818176,994.556152 671.379822,998.068604 665.020691,997.103760 
	C659.325134,996.239624 652.777161,995.119080 646.277832,996.024658 
	C643.112671,996.465759 638.989258,993.833252 636.445862,997.629761 
	C634.879150,999.968384 633.094116,1001.060120 630.331665,1001.853027 
	C630.243225,992.738220 635.174011,989.068115 644.136047,990.921204 
	C647.944153,991.708557 651.324463,994.394531 655.749390,993.247070 
	C656.855347,992.821777 657.704834,992.653320 658.775940,992.241394 
	C659.401611,991.574158 659.845276,991.210144 660.713745,991.023071 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M537.606995,1004.634521 
	C538.113586,1006.885620 536.534485,1007.598511 535.150513,1008.874329 
	C534.966370,1009.167603 534.512634,1009.174622 534.292236,1009.230713 
	C532.850525,1010.029480 531.629333,1010.772156 530.199707,1011.751343 
	C529.821167,1012.134338 529.651001,1012.280823 529.156128,1012.516235 
	C527.075317,1012.526001 525.304688,1012.173889 523.261841,1012.969971 
	C522.479736,1013.132263 522.024170,1013.142334 521.221802,1013.059631 
	C519.305054,1013.156677 517.539429,1012.469238 516.058960,1014.365234 
	C515.095398,1014.982178 514.349426,1015.103027 513.180359,1015.051514 
	C511.531006,1014.533325 510.132599,1014.409973 508.831970,1013.135620 
	C510.271027,1007.208557 515.348816,1007.543335 519.362427,1007.508728 
	C524.287048,1007.466064 528.661804,1006.102844 533.503601,1004.343262 
	C535.043945,1003.788879 536.175476,1003.808350 537.606995,1004.634521 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M314.793945,387.727173 
	C314.836182,390.018311 315.532166,393.087952 311.747406,390.527924 
	C310.746216,389.850739 309.449188,389.749573 308.454865,390.454559 
	C304.987762,392.912842 301.238586,393.598877 296.976898,393.740479 
	C293.619293,393.852051 290.541229,394.678284 287.097168,393.269226 
	C285.480103,392.607666 282.009918,391.391235 281.022186,395.601501 
	C279.085144,395.063385 275.395599,395.996582 275.486176,393.183472 
	C275.587677,390.031067 279.396179,390.856659 281.587097,390.188324 
	C284.688629,389.242279 287.802094,390.764069 290.928192,390.935089 
	C292.662415,391.029938 294.545624,391.516022 296.388214,389.783905 
	C291.187927,390.082245 288.463562,384.449402 283.161346,384.930634 
	C281.745453,385.059143 280.327332,384.403961 280.286713,382.801849 
	C280.231232,380.613251 282.554657,380.165039 283.770111,378.910767 
	C284.863647,377.782318 286.107269,377.903351 287.115936,379.323853 
	C287.821960,380.318085 288.133301,382.307343 289.847565,381.568024 
	C291.627014,380.800568 290.009155,379.232666 290.000244,378.043732 
	C289.984406,375.927338 290.951538,375.363251 292.917542,374.899017 
	C298.944153,373.476013 299.749390,372.025757 298.533875,365.748413 
	C298.017059,363.079315 300.494354,363.026154 301.704529,361.915405 
	C303.125061,360.611572 304.113892,361.439606 305.437866,362.612915 
	C309.877441,366.547272 312.340698,372.351654 317.761780,375.359833 
	C319.213165,376.165192 320.327972,377.528625 322.189606,376.502594 
	C319.889923,372.193176 320.735107,370.335541 326.146851,369.232208 
	C329.613342,368.525482 332.682404,368.317810 328.768463,364.088440 
	C327.885620,363.134460 327.866913,361.944092 328.831604,360.942627 
	C329.908264,359.824921 331.010529,360.422882 331.957275,361.120331 
	C333.283264,362.097198 334.395813,363.304901 336.032684,363.905975 
	C338.893127,364.956390 342.062042,365.599060 343.418915,362.405121 
	C344.731171,359.316284 343.297028,356.253387 340.299591,354.318451 
	C339.508148,353.807556 338.274170,354.218872 337.313629,352.403534 
	C340.292542,353.271576 343.220154,348.643250 345.511353,353.951752 
	C346.161774,355.458771 347.272217,353.069336 348.682739,353.058502 
	C350.458008,354.274994 350.852539,355.962524 351.025177,357.808990 
	C351.171936,359.378937 351.342163,361.068024 354.364288,361.788849 
	C351.320251,358.981476 350.178955,356.387177 352.717072,353.194916 
	C355.580627,354.729401 358.490417,354.178223 361.330322,354.313660 
	C362.549866,354.371765 363.666809,354.797119 363.958557,353.168152 
	C364.429138,350.540955 366.466614,351.111786 368.593323,351.000336 
	C369.475342,351.366150 369.829529,351.813995 369.977539,352.707794 
	C369.667206,354.742371 369.334442,356.485229 369.000244,358.614655 
	C368.136749,360.435608 365.002655,359.621002 365.783691,362.733276 
	C366.558411,364.250732 369.097351,364.333405 368.295013,366.208160 
	C367.699219,367.600311 365.980164,366.812317 364.380829,366.999908 
	C359.926544,366.783447 356.666138,369.708557 352.671326,369.915039 
	C351.343475,369.983643 351.472137,371.250854 351.702026,372.256744 
	C351.995911,373.542480 352.312805,374.820709 351.959778,376.489319 
	C351.020416,378.611084 349.568909,380.082886 347.669586,379.803864 
	C344.141052,379.285492 343.434570,381.382294 342.466339,384.492737 
	C339.464691,385.337555 337.105408,384.337433 334.654175,384.047363 
	C332.435699,383.784821 330.323975,384.387573 330.493134,386.982574 
	C330.647888,389.357269 332.634674,388.967468 334.323120,388.393158 
	C335.778351,387.898132 337.067047,388.192322 337.613922,389.681335 
	C338.279236,391.492859 337.057953,392.243073 335.576141,393.011749 
	C331.733643,395.004852 327.673523,395.177338 323.491882,395.006744 
	C321.302063,394.917450 320.230988,393.679504 319.480438,391.880859 
	C318.863098,390.401520 318.777069,388.526886 316.356262,388.053528 
	C313.690247,385.421143 310.846375,385.836700 307.951782,386.533142 
	C310.205231,386.527740 312.517090,386.092712 314.793945,387.727173 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M365.729370,314.717773 
	C365.781250,316.698578 364.689209,318.391022 366.342163,320.048706 
	C367.315369,321.024719 367.080688,324.213654 369.677795,320.943054 
	C370.505432,319.900787 373.932526,320.922668 376.585083,321.000000 
	C377.790863,321.193054 378.573120,321.527985 379.699463,321.072937 
	C381.840942,321.153015 383.681915,321.306030 385.761414,321.229523 
	C386.750000,321.000000 387.500000,321.000000 388.625000,321.000000 
	C392.195923,324.295807 391.068695,327.377075 388.267090,330.694275 
	C387.555542,331.000000 387.111115,331.000000 386.333313,331.000000 
	C384.588715,332.841431 383.048157,334.521545 380.814880,335.405640 
	C379.001831,336.123413 376.983154,336.399963 375.836395,334.708496 
	C372.404694,329.646729 366.905762,329.934357 361.547485,328.781952 
	C360.164490,328.388855 359.362518,326.728851 358.474426,327.340607 
	C357.603180,327.940857 358.985718,328.868683 359.423950,330.025330 
	C359.313202,333.548737 356.641937,334.778412 354.894867,333.210327 
	C351.877380,330.501953 348.044250,329.836578 344.940674,327.370667 
	C345.727386,326.655121 346.422577,326.170441 347.188721,325.994171 
	C348.838165,325.614746 350.080109,324.910400 349.698151,323.000275 
	C349.381836,321.418762 347.944427,320.734833 346.644745,321.085205 
	C339.219421,323.086792 337.663574,317.965942 336.109894,312.694977 
	C335.892090,311.956085 334.945831,311.258423 335.824921,310.525879 
	C336.785950,309.725128 337.747803,310.346161 338.445984,311.060638 
	C339.130371,311.760986 339.726074,312.591217 340.188477,313.456512 
	C341.228485,315.402588 342.205414,317.713287 344.935608,316.729584 
	C347.306305,315.875427 349.234528,314.070679 349.840179,311.490387 
	C350.323547,309.431091 349.752197,307.758057 347.031799,308.199890 
	C345.489990,308.450317 343.542542,309.971405 342.532898,308.055573 
	C341.286255,305.690155 344.238922,305.340332 345.244965,304.024902 
	C345.538239,303.641449 345.684723,303.145691 345.949249,302.350708 
	C348.196472,299.573181 352.018646,301.039642 354.525085,298.335602 
	C358.702057,295.422211 362.854004,293.373749 367.545746,292.842377 
	C369.966217,292.568237 372.861694,292.263763 372.238586,296.646423 
	C370.576080,298.561890 369.022980,297.766174 367.430237,297.325317 
	C365.488586,296.787903 363.940704,297.808929 363.589233,299.507751 
	C363.080444,301.967194 365.241241,301.811707 366.851318,301.994873 
	C368.252319,302.154236 369.911560,301.524414 370.901978,303.633667 
	C369.834625,305.747040 372.134460,307.836700 370.226501,309.766357 
	C369.740997,311.605347 369.371765,313.144226 367.331635,313.937805 
	C365.478668,313.549927 364.575378,312.559052 364.371185,310.646118 
	C364.494080,309.886810 364.765198,309.096924 364.719635,309.087311 
	C363.805176,308.894226 363.938141,310.004303 363.057617,310.436829 
	C362.123962,310.845581 361.467987,310.850800 360.540771,310.454102 
	C359.105469,309.364807 359.202423,308.170685 359.534546,306.689453 
	C359.565155,306.449493 359.433807,306.004089 359.219482,305.872314 
	C358.084595,305.576752 356.934631,304.866119 356.346863,305.958771 
	C355.770264,307.030670 356.755432,307.750702 357.616455,308.282898 
	C358.316620,308.715698 359.066345,309.069733 359.903290,309.909790 
	C361.245270,311.194397 361.948181,312.398895 361.638550,314.313782 
	C361.132996,315.946655 359.738007,316.771301 359.641693,318.449402 
	C361.146088,317.452606 360.929413,315.718689 362.212402,314.469666 
	C363.524506,313.857758 364.505524,313.914337 365.729370,314.717773 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M355.228760,298.729736 
	C354.034424,304.693970 349.542938,300.859253 346.446289,302.000000 
	C346.383362,299.613403 342.839447,298.944153 343.778168,296.205872 
	C344.649323,295.203644 345.790863,294.817047 345.986145,293.315552 
	C346.212769,291.877228 346.677307,290.734863 346.591766,289.635315 
	C346.319183,286.131531 342.207520,283.525513 346.609650,278.825226 
	C349.410217,275.834991 351.978821,273.282074 355.776306,271.267914 
	C357.466125,270.338898 358.125031,271.277863 358.854218,272.683838 
	C362.005585,275.282745 363.118073,273.177826 363.890778,270.768677 
	C364.598114,268.563324 365.440216,266.721375 368.613098,267.002350 
	C372.043945,272.118774 376.819702,270.209747 381.588287,270.000275 
	C382.894867,270.571289 383.487152,271.354553 383.696686,272.785248 
	C382.787140,276.238007 379.381653,276.900757 377.459015,279.506104 
	C373.427979,280.385803 371.179901,283.609100 367.572632,285.165955 
	C365.196838,285.328827 363.832916,284.537445 362.884888,282.625397 
	C360.551300,277.918671 356.316528,277.503479 353.068604,281.591125 
	C351.830261,283.149567 350.712097,284.810181 350.532776,287.230957 
	C350.766754,288.906281 351.295654,290.138580 352.025452,291.662476 
	C352.683075,294.431091 356.672485,295.131989 355.228760,298.729736 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M344.634644,327.011841 
	C346.004852,328.183746 347.427979,328.274933 348.642456,327.837341 
	C351.273285,326.889557 352.342896,327.807343 353.400970,330.299438 
	C354.654755,333.252441 356.829956,333.743347 358.936462,330.333069 
	C359.070831,329.079956 359.613892,328.503601 360.693787,328.047241 
	C365.222198,326.388031 370.072693,327.968567 372.157837,330.290283 
	C376.883972,335.552643 380.677765,334.816589 385.649750,331.047485 
	C386.156525,332.307556 386.140625,333.665924 386.507629,334.911469 
	C387.157349,337.116486 386.620636,338.590729 384.274933,338.909637 
	C379.397552,339.572693 380.407959,342.094543 382.539337,344.642670 
	C383.510193,345.803345 384.083130,346.718445 383.450012,348.130493 
	C382.545227,350.148560 383.760071,353.834259 381.126831,354.081055 
	C378.481567,354.328949 379.758331,350.496429 378.570648,348.976318 
	C376.839600,346.760925 376.947235,344.374695 377.010834,341.912720 
	C377.132568,337.197876 374.048218,334.397491 370.897644,331.680450 
	C370.355316,331.212738 369.129303,330.872955 368.600494,331.164917 
	C365.698364,332.767242 362.914978,334.584717 360.045410,336.664185 
	C359.711548,337.582642 359.217896,337.873505 358.299255,337.992828 
	C350.915833,340.186859 343.880829,337.702240 336.817444,337.669067 
	C333.902649,337.655396 335.169403,335.035156 336.105011,334.090607 
	C338.630585,331.540955 341.522644,329.354340 344.634644,327.011841 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M383.228088,273.252441 
	C382.688538,272.211578 382.377045,271.423187 382.032776,270.317383 
	C382.814880,269.428833 382.983246,268.582794 382.995636,267.334290 
	C383.697205,266.250763 383.722046,265.089233 384.786591,264.217194 
	C387.230560,263.753479 388.853180,264.324402 389.485962,266.801483 
	C389.859894,268.265045 390.983917,269.248444 392.241821,270.197357 
	C394.952301,272.242065 397.341339,274.516785 397.074829,268.423309 
	C396.968384,265.989777 398.332428,266.030914 399.874390,267.413727 
	C400.616913,268.079620 401.286377,268.826752 402.024902,269.497498 
	C402.750763,270.156769 403.470978,271.011353 404.611847,270.452881 
	C405.699982,269.920166 405.584869,268.998474 405.490662,267.914948 
	C405.123596,263.693054 407.994385,260.971619 410.661072,258.008789 
	C411.438019,262.514282 407.694244,266.556030 409.712341,271.394379 
	C410.275726,272.745026 408.036896,273.568207 406.554932,273.875793 
	C404.954803,274.207855 403.329620,274.419312 401.357697,274.841675 
	C398.154572,277.652283 394.782135,278.742920 390.551270,278.336975 
	C389.939697,278.201752 389.702667,278.129364 389.135559,277.900452 
	C387.115112,276.213867 385.345581,274.783722 383.228088,273.252441 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M352.689758,352.779999 
	C353.223267,355.529266 350.993317,359.024323 355.845428,359.732483 
	C356.743256,359.863495 357.159454,361.295776 356.499756,362.263336 
	C355.708954,363.423187 354.428711,363.888458 353.035553,363.896698 
	C351.522247,363.905640 350.442902,363.188080 350.176971,361.670502 
	C349.721313,359.069946 349.388916,356.447845 349.002380,353.417419 
	C349.444183,352.999847 349.888336,352.999695 350.665649,352.999146 
	C351.384827,352.619080 351.845032,352.472748 352.689758,352.779999 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M257.434662,405.613037 
	C261.454254,401.454865 266.714752,400.923584 272.579956,401.007629 
	C273.190338,401.225922 273.356293,401.440887 273.613586,402.053162 
	C273.403961,405.264343 271.049103,405.440765 269.061859,405.931519 
	C266.385529,406.592468 263.695435,407.273499 260.499664,407.231262 
	C259.293304,407.040863 258.556488,406.732208 257.434662,405.613037 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M358.156891,338.296783 
	C358.471619,337.686737 358.943237,337.373505 359.707428,337.030151 
	C362.397644,340.373962 364.782257,343.558502 368.749847,345.561340 
	C371.074280,346.734680 372.688751,349.523743 370.247375,352.772461 
	C369.686737,352.528046 369.373444,352.056549 369.030090,351.292542 
	C369.944092,348.728271 368.273865,347.261261 366.833221,346.649384 
	C362.909973,344.983154 360.803162,341.637177 358.156891,338.296783 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M243.791138,407.353638 
	C246.142776,406.188751 248.328674,405.343353 250.757599,404.248230 
	C252.742508,404.293030 254.484421,404.587494 256.601288,404.928040 
	C256.976257,404.974121 257.007080,405.006195 257.022675,405.021851 
	C257.175385,405.256805 257.312531,405.476105 257.444305,406.083801 
	C255.964813,409.197632 253.339432,409.226593 250.379318,409.307159 
	C248.858047,409.133240 247.714233,408.810974 246.180328,409.077087 
	C245.348343,409.067627 244.885620,408.966034 244.156723,408.573975 
	C243.793182,408.122589 243.709274,407.897827 243.791138,407.353638 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M241.216827,400.214233 
	C240.694534,398.895477 240.388443,397.790955 240.041473,396.343201 
	C246.094879,392.973969 244.349594,399.679199 246.826538,401.679504 
	C246.143936,403.104248 244.913162,402.980530 243.362793,402.998657 
	C242.134766,402.351044 242.048569,401.228424 241.216827,400.214233 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M332.529785,281.416565 
	C334.696960,281.063477 336.845459,280.795532 336.385712,283.595673 
	C336.151855,285.020081 334.670380,286.252350 333.026428,285.695496 
	C330.737122,284.920135 330.544861,283.346069 332.529785,281.416565 
z"/>
<path fill="#ABCED8" opacity="1.000000" stroke="none" 
	d="
M281.008453,397.383850 
	C284.083130,397.096466 287.206543,396.606750 290.691772,398.234436 
	C290.974396,399.619324 290.231750,400.166321 289.072906,400.763580 
	C288.068237,401.085632 287.373962,401.128448 286.330597,401.078583 
	C284.506775,400.417053 282.836792,401.179260 281.145874,400.177063 
	C280.949280,399.198730 280.977600,398.472809 281.008453,397.383850 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M280.531769,399.949402 
	C282.486389,400.142670 283.866180,398.535767 285.738708,399.688080 
	C286.088837,400.181061 286.203583,400.444458 286.094879,401.077637 
	C282.189575,403.376038 278.315491,403.983429 273.859802,402.921967 
	C273.403900,402.422821 273.318756,402.142914 273.128998,401.437012 
	C273.024414,401.010986 273.041840,400.540558 273.043762,400.305206 
	C274.064911,398.834076 275.226074,398.979126 276.728973,399.869507 
	C278.053802,400.004028 279.098206,399.989075 280.531769,399.949402 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M359.149475,272.664215 
	C358.179993,272.424225 357.359985,271.847260 356.269989,271.135132 
	C356.194946,269.794220 354.630829,268.533875 355.963593,267.425262 
	C356.767456,266.756622 357.863525,266.959778 358.670746,267.562408 
	C360.459717,268.897949 360.101715,270.603271 359.149475,272.664215 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M253.437943,352.556610 
	C251.965469,351.309052 250.128998,350.209259 251.914078,348.505219 
	C252.990265,347.477905 254.430710,348.121399 255.132019,349.485291 
	C255.919983,351.017700 256.705139,352.692810 253.437943,352.556610 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M384.684662,251.795898 
	C384.999664,252.663864 385.074066,253.336380 384.982910,253.985657 
	C384.812775,255.197327 385.606110,256.850067 383.889008,257.387573 
	C383.385681,257.545105 382.424591,256.795197 381.906372,256.242584 
	C380.532379,254.777542 381.203400,253.556976 382.794800,252.264618 
	C383.384491,251.623047 383.840942,251.486359 384.684662,251.795898 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M390.859802,245.064117 
	C393.446228,245.842529 393.174500,247.143204 391.764893,248.359528 
	C390.742767,249.241440 389.422729,249.001099 388.720795,247.873535 
	C387.523712,245.950623 388.570007,245.158844 390.859802,245.064117 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M369.365448,266.991455 
	C369.156342,265.341278 368.491913,263.156403 371.307312,263.136292 
	C372.319000,263.129059 373.508636,263.622040 373.399292,264.677216 
	C373.184753,266.748016 371.298767,266.739227 369.365448,266.991455 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M208.999573,403.625000 
	C208.500488,403.998993 207.997208,404.040283 207.503540,403.988922 
	C206.119446,403.845001 203.968750,404.366272 204.331985,402.039581 
	C204.728729,399.498352 206.732101,400.498962 208.649429,400.956543 
	C209.000320,401.750000 209.000031,402.500000 208.999573,403.625000 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M385.250519,251.752930 
	C384.555542,252.001328 384.111115,252.000839 383.333313,252.000168 
	C382.989014,251.674789 382.870239,251.303848 382.985504,251.032227 
	C383.503296,249.812103 380.713013,247.369278 383.877533,247.382172 
	C386.119293,247.391296 386.312469,249.566483 385.250519,251.752930 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M225.750153,385.000610 
	C225.796814,386.369568 226.588425,388.229095 224.525284,388.726868 
	C223.473770,388.980530 222.582504,388.214172 222.317200,387.154816 
	C221.922897,385.580383 222.951767,385.126373 224.635818,385.012451 
	C225.000610,384.998779 225.500305,385.000000 225.750153,385.000610 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M276.961731,399.674347 
	C275.899017,400.951172 274.769012,399.774353 273.349091,400.060028 
	C273.220459,398.861389 272.353790,397.036591 273.988312,396.648499 
	C275.692322,396.243866 276.397919,397.907013 276.961731,399.674347 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M345.718658,293.151245 
	C346.806580,293.538208 347.957336,294.011627 347.380798,295.226440 
	C346.874115,296.294067 345.653442,295.899536 344.352875,295.998840 
	C344.044983,294.869507 344.462128,293.937347 345.718658,293.151245 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M376.349915,228.918396 
	C375.753876,228.195404 375.506500,227.390182 375.129028,226.293121 
	C375.570129,225.185852 376.416443,225.016846 377.665588,225.004364 
	C377.841095,226.371979 378.478271,228.014038 376.349915,228.918396 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M224.999908,404.250000 
	C226.068665,404.089966 227.335892,403.839752 227.728409,405.256134 
	C227.820663,405.589050 227.542618,406.264191 227.244980,406.420685 
	C226.343781,406.894562 225.829727,406.146881 225.154541,405.280090 
	C224.999542,404.833344 224.999680,404.666656 224.999908,404.250000 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M402.405762,247.539963 
	C403.763702,247.579254 403.997040,248.283417 403.603027,249.131088 
	C403.305908,249.770233 402.538361,250.002914 401.972656,249.619492 
	C401.041443,248.988388 401.507996,248.273956 402.405762,247.539963 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M384.671265,264.006775 
	C384.946594,265.135193 385.090729,266.362091 383.335449,266.931458 
	C382.240173,265.461761 381.814026,264.073517 384.671265,264.006775 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M229.170593,394.217804 
	C229.470490,393.687561 229.942657,393.373901 230.707718,393.030121 
	C231.742584,394.974274 231.059799,395.301941 229.170593,394.217804 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M352.952301,352.715942 
	C352.555298,352.999908 352.110596,352.999786 351.332336,352.999237 
	C351.106476,352.371094 351.172516,351.618103 351.957642,351.578735 
	C352.258270,351.563629 352.588074,352.130341 352.952301,352.715942 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M234.715744,394.047577 
	C235.000000,394.444458 235.000000,394.888885 235.000000,395.666687 
	C234.371658,395.893311 233.618164,395.828308 233.577835,395.042511 
	C233.562424,394.742096 234.129730,394.411835 234.715744,394.047577 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M292.286194,300.952118 
	C292.001953,300.555542 292.001465,300.111115 292.001099,299.333313 
	C292.628906,299.106659 293.381012,299.172668 293.421478,299.956970 
	C293.436951,300.257294 292.870972,300.587585 292.286194,300.952118 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M491.584961,273.000000 
	C490.447235,277.610474 491.367981,282.453430 491.293213,287.093140 
	C491.201416,292.787140 495.189087,288.063538 497.662415,288.151306 
	C498.436218,287.792084 498.835205,287.861511 499.401123,288.428101 
	C499.976532,289.500336 500.509125,290.102112 501.819366,290.168488 
	C503.089050,290.385559 503.857361,290.853241 504.756653,291.761353 
	C508.476318,293.383911 506.759369,295.970795 506.391541,298.197418 
	C505.984070,300.663971 504.240143,301.101776 502.043365,301.025299 
	C498.990326,300.919006 495.637329,301.684875 494.155365,297.334290 
	C491.269867,293.722168 487.114532,293.371033 483.533325,291.812317 
	C481.747375,291.035004 478.536499,290.664673 481.149872,287.138000 
	C484.397736,286.833038 484.879517,284.701172 484.350586,282.607544 
	C484.010620,281.261780 483.326996,282.870270 482.541718,283.339233 
	C479.561127,284.924896 479.547607,287.498871 479.634552,289.943054 
	C479.921051,297.997467 477.563843,299.858643 470.432861,296.918915 
	C468.039612,295.932312 466.997192,296.870758 466.702362,299.138062 
	C466.507965,300.632660 466.567810,302.184906 467.388855,303.470032 
	C469.737823,307.146729 469.645111,310.219574 465.628265,312.693634 
	C464.034729,313.675110 464.824982,314.945374 465.606842,316.087921 
	C466.587372,317.520874 468.703522,318.219208 468.217957,320.729858 
	C468.257416,322.613068 468.487762,324.231079 468.779755,325.837891 
	C469.088501,327.537140 468.824036,328.819153 466.380615,328.982727 
	C465.526520,328.928253 465.100311,328.748657 464.516815,328.164246 
	C463.989441,327.254242 463.857574,326.576691 463.775269,325.548157 
	C463.881378,323.888062 463.457367,323.262665 461.728516,323.975647 
	C456.175751,324.436401 451.697998,322.984436 448.360565,318.582367 
	C450.796814,321.343079 453.152771,323.974121 448.565002,327.127838 
	C444.810120,327.449371 441.468445,325.923737 437.705444,326.686584 
	C436.638885,326.616302 436.771240,325.192047 435.666595,325.840454 
	C435.212708,326.716614 434.718201,327.252380 434.164551,328.068237 
	C433.809937,328.771881 433.722076,329.262360 433.989441,330.040527 
	C434.180054,330.488251 434.257568,330.673615 434.428101,331.156403 
	C438.410858,335.670929 443.039825,339.489532 442.202057,346.275208 
	C444.065704,346.915375 445.904083,345.123352 447.743652,346.766724 
	C449.739716,348.676941 451.344055,347.995117 452.820068,346.639191 
	C453.980652,345.573059 455.223358,344.890045 456.850250,345.040100 
	C458.283966,345.172333 459.586914,345.576538 459.689026,347.237823 
	C459.791901,348.911316 458.407410,348.928406 457.243500,348.977783 
	C455.672638,349.044464 454.158417,349.111908 454.020203,351.609924 
	C453.401337,352.588959 452.738281,353.046539 451.908844,353.288544 
	C443.686188,355.687408 443.188843,356.458710 443.267731,365.060760 
	C443.289551,367.437653 443.691101,369.111420 446.533386,368.646637 
	C448.928772,368.254974 451.370850,367.685760 450.652008,364.358612 
	C450.042297,361.536774 449.378662,358.777374 451.756409,356.413818 
	C452.495972,355.678741 453.378296,355.271667 454.730103,355.777466 
	C456.167358,356.164001 457.334747,356.329193 458.751038,356.747192 
	C461.866638,366.683685 456.731232,374.505066 449.185913,377.454041 
	C446.499634,378.503906 444.390411,377.253998 442.114166,375.480469 
	C441.490814,375.248016 441.171722,374.884979 440.794586,375.231812 
	C441.062103,375.279358 441.311279,375.379608 441.811890,375.712769 
	C447.001312,378.835358 449.194336,382.623474 447.672577,388.721680 
	C446.822174,392.129395 448.389343,395.918365 449.293060,399.424652 
	C450.059082,402.396820 450.387665,405.258667 449.458435,408.545959 
	C446.857941,410.393585 445.965027,408.546448 445.178986,406.887848 
	C444.317444,405.069885 444.134735,403.028198 443.923218,401.056549 
	C443.547089,397.550354 442.854401,394.607849 438.495148,394.035828 
	C436.016388,393.710602 435.620209,391.079193 434.629181,389.197601 
	C433.211700,386.506348 431.772369,386.616852 430.645996,389.379395 
	C429.440491,392.335999 428.607330,395.557648 424.269257,394.939423 
	C423.327423,394.805237 422.826813,395.633606 422.595337,396.483887 
	C421.435455,400.744293 417.858551,404.157379 418.449768,409.081055 
	C418.685059,411.040466 418.167511,413.193115 415.793854,413.920410 
	C413.256012,414.698059 411.309631,413.597900 409.574036,411.786621 
	C408.603516,410.773834 408.221313,408.554199 406.440918,409.076233 
	C404.454132,409.658844 404.120331,411.787109 404.126526,413.645721 
	C404.139191,417.422729 401.878937,418.298004 398.652527,418.577942 
	C394.111115,418.971985 390.257660,416.713043 386.127869,415.610901 
	C383.500336,414.909668 384.487701,412.898895 385.612579,411.236481 
	C386.575043,409.814056 388.971191,409.571686 388.937012,407.301147 
	C387.571075,406.239594 386.525848,407.230011 385.832214,407.956055 
	C382.873291,411.053345 379.338684,410.881378 375.669067,409.978088 
	C372.467926,409.190125 369.443115,408.233337 366.033508,409.731537 
	C363.995789,410.626953 361.831482,409.391357 360.141907,407.320404 
	C361.013702,404.962646 355.581146,401.870972 361.561676,400.265778 
	C364.347229,399.518127 366.313049,397.785309 369.688873,399.095154 
	C372.712341,400.268280 375.653442,396.441467 379.202850,397.416687 
	C383.732117,398.661102 387.081787,397.103729 390.134857,393.459381 
	C392.722351,390.370819 396.201721,392.357941 399.103912,393.567749 
	C400.208344,392.480591 399.319977,391.503876 399.420471,390.617920 
	C400.036987,385.183533 398.283569,383.793610 393.376709,386.626038 
	C391.964905,387.441010 390.398041,387.024109 390.021973,384.962433 
	C389.962280,384.635162 389.881897,384.311066 389.798096,383.988861 
	C389.064331,381.168671 384.317780,381.966919 384.588440,378.678925 
	C384.782013,376.327942 389.295166,377.525085 389.268768,373.795227 
	C389.250916,371.268616 392.044891,371.785004 393.662079,372.299835 
	C395.537170,372.896790 394.660370,374.315613 394.003845,375.542999 
	C393.635254,376.232056 393.205597,377.138306 393.367859,377.810455 
	C393.728394,379.303497 395.345062,380.108246 396.455505,379.796631 
	C400.012177,378.798645 404.048615,384.420654 406.337372,380.332672 
	C408.113953,377.159515 409.559723,372.893860 409.043335,368.344086 
	C414.202942,367.144257 416.135712,363.820984 416.026428,358.538147 
	C417.048889,356.631561 418.359222,355.259857 418.711151,353.007751 
	C419.410431,350.787720 420.038910,348.920044 421.685364,347.199280 
	C425.612061,348.789795 430.036377,346.884613 433.487854,349.550720 
	C434.168060,350.076111 435.152985,349.913513 435.607117,349.023743 
	C436.030426,348.194397 435.758087,347.280304 435.020355,346.814941 
	C433.916138,346.118469 432.727020,345.360443 431.479767,345.122467 
	C427.142639,344.294952 424.560760,342.267853 423.492798,337.566254 
	C422.665039,333.921967 420.368958,331.085785 426.623871,330.591705 
	C429.330475,330.377899 429.119385,326.990906 428.955322,324.708954 
	C428.676819,320.834778 429.340485,317.918365 433.791168,316.810394 
	C436.538269,316.126526 436.930359,313.133270 437.846008,310.890137 
	C438.851593,308.426758 440.207092,306.539886 442.979340,306.032318 
	C443.894989,305.864655 445.238678,306.243530 445.394043,304.919678 
	C445.467377,304.294769 444.787048,303.454193 444.246460,302.912781 
	C443.325745,301.990662 442.143341,301.830322 441.099457,302.695923 
	C440.478424,303.210938 440.099396,304.007538 439.559509,304.632568 
	C438.095123,306.327881 436.351593,306.408905 434.733612,305.043701 
	C433.097382,303.663055 432.017181,301.994659 434.655334,300.080566 
	C435.474762,300.030670 435.917664,300.198120 436.371368,300.319183 
	C436.596161,299.445160 435.808838,299.066254 435.233276,298.234833 
	C435.214630,296.608795 436.118073,296.215576 437.423981,295.988983 
	C440.755646,295.410950 444.439789,295.851105 447.021149,292.837036 
	C447.301666,292.509491 448.659332,292.501221 448.769836,292.720856 
	C450.780212,296.717468 455.898346,293.477966 458.345123,297.003082 
	C459.556396,298.748230 461.124695,296.597595 461.801117,295.544373 
	C463.689087,292.604523 466.439026,290.869385 469.688690,289.144165 
	C471.445404,290.626862 472.638580,290.532013 473.773376,288.242554 
	C476.263214,286.689545 475.720764,283.782227 477.379791,282.087128 
	C478.385284,281.059753 479.858795,279.670593 477.965393,278.402985 
	C476.554230,277.458221 475.098267,278.303772 474.167969,279.740570 
	C473.438080,280.867889 472.727264,282.228607 471.069580,281.316254 
	C469.705109,280.565277 468.813995,279.241028 468.548065,277.739166 
	C468.248688,276.047974 474.596863,272.958893 476.213074,274.436035 
	C479.938568,277.841125 482.770905,275.978668 485.836304,273.791748 
	C487.473053,272.624084 489.369141,273.112213 491.584961,273.000000 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M359.581604,406.998322 
	C362.931793,407.289062 365.957550,410.938995 368.792267,406.522644 
	C369.449554,405.498596 370.926941,406.568542 371.983246,407.009155 
	C376.961975,409.086151 381.839539,410.802551 386.109314,405.482147 
	C387.664856,403.543823 389.512268,403.589600 390.930634,405.927429 
	C392.209351,408.035004 392.214569,409.570129 389.412292,410.397491 
	C387.679871,410.908936 386.103455,411.878143 385.108521,414.363739 
	C390.296509,413.325684 393.714050,418.155151 398.786896,417.095642 
	C401.212769,416.588989 403.467346,417.144867 402.367859,413.250793 
	C401.478424,410.100830 404.452209,408.237885 406.140167,406.081055 
	C407.091339,404.865662 407.827728,405.885651 408.355713,406.786987 
	C409.276764,408.359314 410.056366,410.033661 411.130554,411.491821 
	C412.222290,412.973755 413.904053,413.317719 415.621552,412.733368 
	C417.278961,412.169373 417.282043,410.493835 416.930939,409.355927 
	C414.967010,402.991302 421.991028,399.174316 421.402588,393.135590 
	C421.282104,391.899109 423.202423,392.091125 424.240479,392.844360 
	C426.653259,394.595032 427.418762,393.673431 428.292053,391.090240 
	C429.084595,388.745911 429.080414,384.849548 431.946411,384.609711 
	C435.612335,384.302948 436.197968,388.187378 436.995178,390.999298 
	C437.560822,392.994354 438.957916,393.072876 440.463379,392.975616 
	C444.010040,392.746460 444.666016,394.634583 445.164795,397.801086 
	C445.751587,401.526154 444.691803,405.906433 448.906311,409.142426 
	C450.866577,412.470886 450.035217,415.704407 450.216675,418.806793 
	C450.431000,422.471588 452.115417,422.400452 455.039001,421.795166 
	C456.995148,422.113220 458.507965,422.927704 458.731201,424.470795 
	C459.797272,431.839569 466.099854,436.151093 468.987793,442.465607 
	C471.956482,448.956726 475.829895,454.986145 479.690491,460.987122 
	C483.353638,466.681152 483.216522,475.684387 479.376160,481.226685 
	C477.352753,484.146790 474.226105,484.658112 470.841797,485.575684 
	C467.615631,485.017670 467.358978,482.899261 468.768982,481.189423 
	C470.327850,479.299042 470.473206,476.862030 471.597290,475.167908 
	C476.281219,468.108887 471.190674,462.618408 468.081573,458.063263 
	C465.188538,453.824646 462.607147,449.553253 460.421936,444.974670 
	C459.663818,443.386261 459.128937,442.242645 456.819183,442.674652 
	C453.866882,443.226776 451.875275,440.983826 450.191742,438.948883 
	C448.564911,436.982544 446.541901,436.320007 444.183563,435.871399 
	C440.528839,435.176178 438.322723,435.999115 438.306000,440.204651 
	C438.287506,444.865326 435.039093,447.327332 431.456543,449.242065 
	C428.855011,450.632446 426.567139,451.965332 426.153107,455.238678 
	C426.004730,456.411682 425.266235,457.469360 423.854492,457.458527 
	C422.534485,457.448425 421.641785,456.571686 421.296783,455.416656 
	C420.626038,453.170837 419.349518,451.628235 417.081512,450.916351 
	C416.093445,450.606201 415.047241,450.222778 414.471832,451.398407 
	C411.934601,456.582306 408.900177,454.722260 405.496796,451.859985 
	C402.615356,449.495087 400.260284,447.285858 400.323914,443.457336 
	C400.344879,442.196045 399.428101,440.946747 398.443695,440.036072 
	C388.310699,430.661926 376.728271,423.536652 364.510925,417.155518 
	C357.459686,413.472656 349.999878,410.788208 343.018433,407.051605 
	C341.322998,406.144226 339.422699,405.118805 339.739746,402.337036 
	C340.892578,402.194885 342.110138,402.047943 342.757202,402.586487 
	C347.575592,406.596527 353.079102,407.816132 359.581604,406.998322 
z"/>
<path fill="#ABCED8" opacity="1.000000" stroke="none" 
	d="
M339.574951,402.027588 
	C341.763336,405.861847 345.587433,407.523895 349.052551,408.293823 
	C355.944244,409.825073 361.703094,413.755707 367.622009,416.860565 
	C378.794586,422.721191 389.810516,429.250336 398.862152,438.400848 
	C400.181000,439.734131 401.799011,441.217316 401.675140,442.961823 
	C401.423370,446.507111 403.607178,448.308167 405.857758,450.627258 
	C412.890503,462.556274 415.841461,475.833405 421.515869,487.967651 
	C422.993011,491.126373 423.232910,494.682922 423.339233,498.568726 
	C423.117676,499.779358 422.746338,500.482544 421.752167,501.216492 
	C420.528961,501.452728 419.775208,501.185120 418.786469,500.451294 
	C416.345276,493.410004 413.607605,486.850616 411.202332,479.781738 
	C410.496460,476.566986 408.406067,474.347656 407.578735,471.207428 
	C407.470184,469.873016 406.865967,469.148132 406.166260,468.077271 
	C404.870605,465.355042 404.069000,462.838776 403.538696,459.871887 
	C402.599823,456.136200 401.523804,452.820709 399.469543,449.517395 
	C398.960480,448.576965 398.758240,447.899200 398.578796,446.856995 
	C397.814087,440.660431 392.701019,439.057892 388.429443,436.191711 
	C387.977081,435.879059 387.806793,435.741669 387.407562,435.374207 
	C386.558136,434.299622 385.924347,433.469482 384.629303,432.900146 
	C383.403229,432.208282 382.497375,431.606995 381.337097,430.777435 
	C377.749054,427.275085 373.608002,425.298492 369.131226,423.212646 
	C368.177917,422.788269 367.579437,422.434448 366.673157,421.920959 
	C362.034515,418.759308 357.241333,416.648193 352.117065,414.468201 
	C351.335541,414.320984 350.902649,414.191864 350.185608,413.862061 
	C348.044098,412.195923 346.174072,410.733032 343.367310,410.355713 
	C342.813416,410.265198 342.591614,410.218994 342.038757,410.083679 
	C341.262695,409.859772 340.820709,409.715820 340.040100,409.454315 
	C335.213470,407.370728 330.487213,406.095520 325.494476,404.411316 
	C324.422272,403.854095 323.880432,403.298676 323.581726,402.103882 
	C326.942535,398.721832 331.259705,398.717804 336.647247,401.863403 
	C337.749847,402.040192 338.480286,402.035461 339.574951,402.027588 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M351.018982,377.340546 
	C348.141113,370.519196 349.655792,367.098663 357.337769,367.156555 
	C359.283783,367.171234 361.236450,365.394775 363.728760,366.740295 
	C362.741699,367.764709 361.423767,368.446930 360.240417,369.313049 
	C358.370148,370.681946 356.854553,371.423889 359.400116,374.401245 
	C361.783051,377.188446 362.244781,381.630951 363.492096,385.364990 
	C363.726440,386.066498 363.233948,386.954742 362.566650,386.770050 
	C356.450623,385.077240 356.654510,389.282135 357.025696,394.004944 
	C353.520233,391.474915 350.691437,389.233429 347.575958,393.181244 
	C346.545410,394.487122 344.367981,394.346893 344.234894,392.296967 
	C344.073944,389.817627 342.099701,388.150787 342.010925,385.388733 
	C342.000000,385.001221 342.000000,385.000000 342.000000,385.000000 
	C343.884460,383.072815 346.552612,383.036591 348.888611,382.420044 
	C351.925873,381.618378 351.975342,380.007385 351.018982,377.340546 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M421.986420,346.632690 
	C422.173004,349.021912 421.181000,350.673157 420.147644,352.678497 
	C418.410828,354.815613 416.475494,353.976807 414.530609,353.635559 
	C415.284729,354.975586 416.595490,355.834686 416.978577,357.655457 
	C416.999207,359.161346 417.000488,360.321503 416.999847,361.481628 
	C416.995728,369.003387 416.995575,369.003387 409.404266,368.000977 
	C408.822021,365.084229 408.104431,362.757507 405.065582,361.207123 
	C402.508575,359.902557 401.919586,356.866333 404.077148,354.903137 
	C405.877075,353.265411 406.909515,351.361176 408.114136,349.440002 
	C409.765137,346.807007 411.250549,344.009399 415.282135,345.893494 
	C416.488495,346.457275 417.778168,345.447876 418.938080,344.730286 
	C420.645752,343.673767 421.773560,344.147064 421.986420,346.632690 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M452.675079,265.018829 
	C455.051147,264.874786 457.072296,265.103546 458.653351,266.494568 
	C460.272308,267.919006 460.586884,269.764160 457.910950,269.853149 
	C453.267731,270.007538 451.294922,273.752289 450.388306,276.672760 
	C448.704407,282.097229 446.090637,279.956879 444.003021,278.045898 
	C442.234741,276.427246 440.860657,274.991547 438.268311,274.754425 
	C436.215912,274.566650 435.030853,272.512817 435.522644,270.690460 
	C436.011749,268.878021 437.479828,266.793671 439.876099,267.577759 
	C442.208038,268.340759 444.112488,267.149719 446.609955,267.010376 
	C449.104309,267.221527 450.588684,265.752045 452.675079,265.018829 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M462.310486,245.142395 
	C462.016266,243.468353 460.182220,241.544922 463.410645,240.793472 
	C465.689362,240.263062 465.729889,237.729279 465.920868,235.843445 
	C466.235016,232.740952 468.222168,231.879532 470.727753,232.121475 
	C474.402283,232.476303 472.078461,234.998367 472.079803,236.651093 
	C472.081268,238.472260 472.029236,240.293442 472.000854,242.557312 
	C470.321167,245.435654 468.437622,247.870758 473.108490,249.230087 
	C474.062225,249.507675 475.027985,250.351852 474.284851,251.444046 
	C473.481628,252.624512 472.444580,253.822922 471.222961,254.484329 
	C470.286743,254.991196 469.535461,253.885986 469.130554,252.912888 
	C467.782288,249.672668 466.089264,246.719131 462.310486,245.142395 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M388.350922,330.952423 
	C388.473114,327.938812 390.251801,325.019409 389.001160,321.385559 
	C389.939331,319.689026 390.844452,317.500885 392.713715,318.650848 
	C395.151611,320.150604 398.533661,320.715332 399.466553,324.136871 
	C399.844971,325.524780 398.899139,325.936615 397.898926,326.136505 
	C394.364380,326.842834 391.676758,329.143250 388.350922,330.952423 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M336.994354,401.648987 
	C332.832367,402.104095 328.746979,399.623199 324.200623,401.842468 
	C323.323822,402.489380 322.681854,402.476196 321.708618,402.344177 
	C321.174530,402.223907 320.963348,402.162140 320.444794,401.974426 
	C319.942108,401.741791 319.746796,401.635162 319.279724,401.334290 
	C317.744720,399.410339 314.566467,399.608704 314.668335,396.375031 
	C321.876099,395.591370 328.399811,398.610504 335.297577,399.132446 
	C336.494873,399.223053 336.864471,400.187134 336.994354,401.648987 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M315.250000,388.000000 
	C311.880707,387.990631 308.761292,387.996368 305.642242,387.963074 
	C304.640076,387.952393 303.478333,387.829987 303.396484,386.587921 
	C303.312714,385.316254 304.524017,384.812164 305.433441,385.093231 
	C308.860107,386.152161 313.649963,380.675690 315.947388,387.625397 
	C316.000000,388.000000 315.500000,388.000000 315.250000,388.000000 
z"/>
<path fill="#ABCED8" opacity="1.000000" stroke="none" 
	d="
M290.990723,397.985474 
	C295.535736,395.125031 300.364502,396.097198 305.607483,396.947449 
	C306.386292,397.449768 306.512604,398.015625 306.181824,399.020935 
	C302.348969,401.627136 298.447784,400.727051 294.172791,400.382324 
	C292.863617,399.989899 292.122253,399.511261 291.296265,398.417358 
	C291.036652,398.056519 291.005798,398.009308 290.990723,397.985474 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M314.543732,396.090485 
	C315.327271,398.879211 318.822296,397.949615 319.930573,400.421387 
	C319.276306,402.204956 317.879456,402.231934 316.102234,402.293152 
	C315.165497,402.212891 314.636292,402.130798 313.716827,401.965607 
	C312.014801,401.646484 310.698486,401.416840 309.155884,400.578796 
	C308.677582,400.110168 308.530365,399.879883 308.289246,399.248962 
	C308.308319,396.088989 310.229767,395.977478 312.691589,396.090607 
	C313.486328,396.115692 313.871338,396.107452 314.543732,396.090485 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M404.718750,305.848663 
	C404.992401,307.861877 404.318970,309.723114 402.269897,308.613647 
	C400.587769,307.702850 397.557617,307.384247 397.584137,305.054718 
	C397.615845,302.272705 400.424713,303.253143 402.600586,303.000183 
	C403.045166,304.130402 403.462585,305.062408 404.718750,305.848663 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M452.946136,264.686737 
	C451.368530,266.447205 450.805817,270.274048 447.151642,267.278748 
	C447.179779,266.453796 447.359558,265.908783 447.768433,265.181885 
	C448.770599,264.316101 449.259735,263.291962 450.689453,263.008240 
	C451.889740,263.106995 452.645264,263.391876 452.946136,264.686737 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M473.650696,288.005981 
	C474.046783,289.738342 475.990234,292.080841 472.707520,292.797974 
	C470.306458,293.322510 469.994476,291.603973 470.003723,289.387634 
	C471.101257,288.671051 472.201324,288.340912 473.650696,288.005981 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M312.835022,395.849915 
	C312.168121,397.531281 309.925873,396.544189 309.095703,398.595032 
	C308.294891,399.612427 307.465576,399.708374 306.296082,398.971252 
	C306.036224,398.237457 306.036896,397.866241 306.033508,397.215332 
	C307.752563,394.286041 310.118011,394.725708 312.835022,395.849915 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M435.000000,298.333313 
	C435.748932,298.286896 436.586121,298.446045 437.227264,298.888947 
	C438.348877,299.663757 439.040802,300.770081 438.889587,302.226318 
	C438.815948,302.935150 438.429596,303.609802 437.709290,303.479279 
	C436.198120,303.205414 435.975555,301.674133 435.140381,300.305878 
	C435.000000,299.555542 435.000000,299.111115 435.000000,298.333313 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M366.065369,362.669800 
	C363.652161,360.404968 363.835114,360.086121 368.642029,359.008606 
	C369.117615,361.037506 366.537720,360.756134 366.065369,362.669800 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M451.003632,262.666077 
	C450.518921,264.016541 449.792480,264.738464 448.316040,264.965363 
	C447.997803,264.250275 447.998016,263.500580 447.997925,262.376038 
	C448.786591,261.689026 449.575623,261.376862 450.682312,261.031738 
	C451.000763,261.443390 451.001556,261.888031 451.003632,262.666077 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M368.044128,384.466125 
	C368.694000,384.824188 368.703094,385.183624 368.236755,385.500610 
	C368.139191,385.566833 367.913513,385.563904 367.818726,385.494629 
	C367.365326,385.163147 367.382019,384.804047 368.044128,384.466125 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M366.765137,284.292114 
	C368.961060,283.975708 369.703827,282.468842 370.582825,281.095032 
	C371.920746,279.003967 374.024872,278.890228 376.599548,279.000122 
	C379.630341,280.597260 381.917480,281.381683 383.708344,277.677277 
	C384.574646,275.885284 386.566284,275.612335 388.733002,276.798676 
	C390.525879,278.994812 392.216827,280.367950 395.064789,280.028534 
	C397.192749,279.774933 399.129822,280.834808 400.842163,282.695007 
	C401.064880,287.137207 398.827728,287.353455 395.362854,286.048309 
	C394.614410,286.380310 394.154266,286.527588 393.309753,286.220886 
	C389.410980,283.708954 388.832153,288.056976 386.326935,288.938690 
	C385.574707,289.401825 385.209137,289.843445 385.026154,290.712585 
	C384.998596,298.958923 384.998596,298.958923 376.282104,297.262756 
	C373.803192,294.375732 370.573517,292.891510 368.462158,290.173767 
	C367.185791,288.530762 365.513519,287.021210 366.765137,284.292114 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M366.604187,283.978394 
	C367.158051,288.501282 370.926758,290.418549 373.687103,292.918549 
	C374.815552,293.940552 375.967499,294.605255 375.990479,296.628754 
	C374.933319,297.000000 373.866669,297.000000 372.399994,297.000000 
	C372.055725,293.602844 369.877380,293.707581 367.593872,294.327393 
	C363.483337,295.443176 359.517670,296.970123 355.375488,298.990936 
	C355.816986,295.551514 350.412506,296.067078 351.009064,292.377197 
	C351.266266,290.868713 352.317383,290.168945 352.716492,289.083801 
	C351.755798,288.033600 350.477936,289.080383 349.265747,288.226105 
	C349.598694,280.108521 354.218231,276.208984 361.783722,277.079651 
	C363.910309,277.324432 363.948975,278.716370 363.997833,280.263672 
	C364.049408,281.896576 363.761627,283.750336 366.604187,283.978394 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M401.249207,282.805603 
	C397.741302,282.653870 394.854889,280.743439 391.177185,281.749664 
	C388.671204,282.435303 389.159424,279.513702 389.000458,277.414734 
	C393.003510,277.860840 396.451050,275.604462 400.596924,275.001770 
	C400.335419,277.591461 403.957947,279.902222 401.249207,282.805603 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M423.839508,284.262177 
	C423.436676,287.605011 422.378387,289.321686 419.624359,286.334290 
	C417.648621,284.191101 414.779663,282.920166 412.993378,279.628906 
	C417.525177,279.792816 421.142517,280.513062 423.839508,284.262177 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M360.007874,310.367950 
	C360.750000,310.000000 361.500000,310.000000 362.625000,310.000000 
	C363.383667,309.620972 363.839996,309.483887 364.684509,309.795044 
	C365.627411,311.118652 366.254791,312.236084 366.941101,313.676758 
	C366.873260,314.304565 366.679413,314.557739 366.209229,314.879761 
	C364.933319,315.000000 363.866669,315.000000 362.399994,315.000000 
	C361.833344,315.128082 361.666656,315.256134 361.250000,315.192078 
	C360.671936,313.578644 360.343842,312.157257 360.007874,310.367950 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M382.530762,309.542114 
	C383.612915,307.234253 385.156250,307.718201 386.496674,308.839874 
	C387.129639,309.369507 386.869080,310.442780 386.171387,311.025269 
	C384.428955,312.480072 383.574554,310.977295 382.530762,309.542114 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M370.357788,309.987854 
	C370.815796,308.087524 368.176544,305.969910 370.674011,304.068970 
	C371.059601,305.999268 375.385010,308.201569 370.357788,309.987854 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M414.437561,270.867249 
	C416.441559,270.380981 417.335602,271.338043 417.408783,272.996704 
	C417.442871,273.768768 416.590302,273.899780 415.950867,273.853638 
	C414.184723,273.726166 413.946655,272.580139 414.437561,270.867249 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M420.658752,263.875061 
	C419.595337,265.098694 418.536835,265.280823 417.661224,264.215149 
	C417.469940,263.982391 417.850067,262.897797 418.249542,262.635101 
	C419.347900,261.912811 420.403778,261.925568 420.658752,263.875061 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M379.803802,320.769226 
	C379.439178,322.989777 378.603851,323.753418 377.062622,321.321808 
	C377.728546,320.051453 378.586914,319.836365 379.803802,320.769226 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M399.477448,291.253479 
	C398.312317,291.684113 397.657013,291.230316 397.484497,290.358490 
	C397.336060,289.608124 397.932922,289.155273 398.645203,289.253510 
	C399.636963,289.390320 399.881439,290.116577 399.477448,291.253479 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M390.983643,296.894562 
	C390.011444,296.748596 389.292542,296.322357 389.753082,295.687103 
	C390.396423,294.799744 391.548462,294.798096 392.200439,295.674316 
	C392.667633,296.302155 391.956696,296.740082 390.983643,296.894562 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M404.893097,278.884399 
	C406.041534,278.252167 406.704651,278.486176 406.756317,279.547943 
	C406.790588,280.252747 406.262146,280.786499 405.556213,280.751160 
	C404.495178,280.698059 404.262482,280.032928 404.893097,278.884399 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M380.260956,302.532654 
	C380.517548,303.539246 380.016449,303.852783 379.360962,303.725800 
	C379.055939,303.666748 378.570648,303.095215 378.627716,302.875549 
	C378.813080,302.161774 379.440399,302.142670 380.260956,302.532654 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M403.466125,313.955750 
	C403.824188,313.305908 404.183563,313.296722 404.500519,313.763153 
	C404.566772,313.860748 404.563873,314.086426 404.494568,314.181183 
	C404.163116,314.634491 403.804016,314.617859 403.466125,313.955750 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M385.229492,290.819153 
	C384.030243,290.104767 384.253876,289.481384 385.720032,289.039124 
	C385.819672,289.545685 385.639313,290.091370 385.229492,290.819153 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M393.047729,286.284088 
	C393.444458,286.000000 393.888885,286.000000 394.666687,286.000000 
	C394.893188,286.628021 394.827789,287.380981 394.042542,287.421204 
	C393.742188,287.436615 393.412018,286.869720 393.047729,286.284088 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M473.002136,214.411530 
	C475.292755,214.198120 475.859619,215.684494 476.052734,217.719345 
	C476.306641,220.394775 477.509033,222.714417 479.695801,224.828598 
	C479.035400,228.560989 477.608215,228.593781 475.791809,225.545624 
	C473.823700,222.242996 473.579071,218.489456 473.002136,214.411530 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M453.010986,239.362183 
	C452.998291,238.555771 452.999023,238.110916 452.999878,237.332428 
	C453.819366,236.899689 454.657043,236.876938 455.454163,236.685333 
	C457.005005,236.312607 458.757050,235.178757 459.718811,237.387848 
	C460.242798,238.591385 460.246277,240.628662 458.660553,240.605301 
	C456.838531,240.578445 454.223450,243.752640 453.010986,239.362183 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M512.730225,239.210571 
	C514.521484,240.738678 514.400452,242.558487 512.409363,243.525879 
	C509.446686,244.965378 507.956879,242.447205 506.164062,240.306549 
	C508.124542,239.483932 510.554718,242.379196 512.730225,239.210571 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M494.151245,209.281326 
	C494.090729,207.175095 494.267517,204.729507 496.647827,205.441864 
	C498.982239,206.140472 497.974030,208.612442 497.258575,210.725830 
	C495.869507,210.955002 494.937347,210.537872 494.151245,209.281326 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M470.787231,208.682495 
	C469.399231,210.363876 468.302734,209.921967 467.545654,208.508118 
	C467.090271,207.657623 467.617004,206.839355 468.439026,206.493683 
	C470.221344,205.744232 470.875977,206.662155 470.787231,208.682495 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M496.688599,219.145233 
	C498.347931,220.037079 497.892395,221.214706 497.132690,222.702850 
	C495.898560,222.909439 494.489380,223.163330 494.265991,221.640961 
	C494.058624,220.227890 495.451080,219.886215 496.688599,219.145233 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M470.256805,226.218369 
	C470.851013,225.349686 471.398407,225.406281 471.671997,226.108017 
	C471.796448,226.427124 471.621582,227.136230 471.366058,227.277863 
	C470.659515,227.669388 470.316986,227.166641 470.256805,226.218369 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M500.429535,202.288239 
	C504.809418,201.880219 502.400146,204.523376 502.625336,206.590439 
	C500.385498,205.643158 501.200745,203.743179 500.429535,202.288239 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M1103.093384,912.769775 
	C1100.698608,911.743652 1097.868286,911.096069 1099.842407,907.884155 
	C1100.865234,906.220215 1102.792847,905.127502 1104.436523,906.704407 
	C1106.704102,908.880005 1105.704224,911.071838 1103.093384,912.769775 
z"/>
<path fill="#E0DEDE" opacity="1.000000" stroke="none" 
	d="
M1111.127197,912.072632 
	C1112.841064,912.209473 1114.451416,912.462097 1113.304932,914.263611 
	C1112.568359,915.421204 1110.864624,915.344543 1109.861694,914.440247 
	C1108.603638,913.305847 1109.454224,912.508484 1111.127197,912.072632 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M566.599304,1078.003662 
	C567.000000,1078.444458 567.000000,1078.888916 567.000000,1079.666748 
	C566.816467,1081.695679 567.587463,1083.942505 565.115540,1084.476318 
	C563.716370,1084.778564 563.221191,1083.422852 563.083984,1082.215942 
	C562.818054,1079.877197 562.973633,1077.789551 566.599304,1078.003662 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M604.371521,1125.002563 
	C604.389038,1122.495605 606.739563,1122.885010 608.643311,1122.046387 
	C608.996887,1123.073364 608.996155,1124.146606 608.989807,1125.623291 
	C607.664978,1125.293579 606.267822,1124.884399 604.371521,1125.002563 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M618.772217,1123.739746 
	C617.828247,1123.678101 617.321716,1123.340576 617.714905,1122.633911 
	C617.857056,1122.378540 618.562866,1122.203369 618.880676,1122.327271 
	C619.578186,1122.598999 619.641296,1123.144775 618.772217,1123.739746 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M615.937378,1127.678223 
	C615.250000,1128.000000 614.500000,1128.000000 613.375000,1128.000000 
	C613.560913,1126.010742 614.395935,1125.245850 615.937378,1127.678223 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M568.410645,1065.351562 
	C564.456787,1070.727295 559.340576,1069.233521 555.038086,1067.543213 
	C551.978760,1066.341187 552.273010,1061.700073 550.192078,1059.038452 
	C549.764526,1058.491577 550.595215,1057.198975 551.538574,1057.529053 
	C556.583008,1059.294434 561.709229,1055.523682 566.750305,1057.885498 
	C568.275757,1058.600220 571.020386,1057.028442 571.530029,1059.294434 
	C572.021606,1061.479492 571.128174,1063.894043 568.410645,1065.351562 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M532.816345,1040.493164 
	C531.816956,1037.552856 533.484802,1037.053589 535.551147,1037.169556 
	C536.825317,1037.240845 538.022461,1038.146484 537.662903,1039.405518 
	C536.943115,1041.926025 534.804932,1040.831177 532.816345,1040.493164 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M548.264832,1069.468262 
	C549.938782,1069.427979 551.412537,1069.625488 551.478638,1071.269043 
	C551.526917,1072.470093 550.429688,1073.302246 549.281189,1073.595703 
	C548.268372,1073.854614 547.282654,1073.321045 546.689575,1072.543457 
	C545.482666,1070.960938 546.737732,1070.244629 548.264832,1069.468262 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M598.442261,1088.596313 
	C596.373657,1088.946655 595.147766,1088.236816 595.300659,1086.420166 
	C595.427063,1084.917847 596.846680,1084.864502 597.937439,1085.243042 
	C599.638428,1085.833130 600.308838,1086.910034 598.442261,1088.596313 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M580.596069,1062.557861 
	C580.946960,1064.626465 580.236511,1065.852173 578.420410,1065.699585 
	C576.918640,1065.573120 576.864441,1064.153687 577.243042,1063.062744 
	C577.833313,1061.361938 578.910095,1060.691650 580.596069,1062.557861 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M662.752625,310.794739 
	C657.551880,313.750458 651.329590,313.701935 645.351868,315.919922 
	C645.347900,313.852234 647.185608,312.603851 648.686829,312.475220 
	C653.338135,312.076660 657.708801,309.761017 662.752625,310.794739 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M674.718018,307.115997 
	C673.497253,310.098511 670.406006,309.428223 667.427734,310.050018 
	C667.020325,309.280518 667.012817,308.523621 667.008667,307.389130 
	C669.427979,307.026703 671.843933,307.041870 674.718018,307.115997 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M688.773193,304.842529 
	C685.648010,306.513184 682.088928,307.082306 678.248779,307.399902 
	C681.160217,304.978027 684.637146,303.937012 688.773193,304.842529 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M693.882202,303.187500 
	C693.960266,304.047729 693.568481,304.754181 692.283813,304.926605 
	C692.115356,304.009521 692.478455,303.271301 693.882202,303.187500 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M628.848145,318.830444 
	C628.595154,319.694153 628.070862,319.983582 627.152588,319.404633 
	C627.352722,318.486511 627.841736,318.054871 628.848145,318.830444 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M556.657776,235.079132 
	C557.169250,235.792816 557.068542,237.003479 557.548950,237.313507 
	C563.367310,241.068420 559.861938,247.455673 561.817139,252.290848 
	C562.234192,253.322205 560.976929,255.050568 559.937561,254.642517 
	C557.001892,253.489990 554.967712,255.988129 552.401062,255.987869 
	C549.653442,255.987579 546.292114,256.760315 547.889038,251.882812 
	C548.203735,250.921448 547.456177,250.002014 546.309937,249.181854 
	C547.596985,245.374329 549.620667,242.860840 554.210815,244.869934 
	C555.395996,245.388702 557.459595,245.594208 558.375305,244.222031 
	C559.639099,242.328354 557.607666,241.315186 556.613159,240.117630 
	C555.280457,238.512817 553.989258,236.959076 556.657776,235.079132 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M577.727417,205.809982 
	C575.735962,207.300232 574.115112,207.126007 572.420532,205.965912 
	C570.103088,204.379456 567.284119,204.125183 563.877625,203.496185 
	C567.370544,200.495331 570.198547,197.902039 573.205383,195.536072 
	C575.475281,193.750061 577.685059,193.729065 578.125122,197.181580 
	C578.477112,199.942917 581.782043,202.847748 577.727417,205.809982 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M591.991577,192.630768 
	C591.833313,193.000076 591.666687,193.000168 591.250000,193.000427 
	C587.624817,193.379059 583.753845,192.864578 582.812561,197.595840 
	C582.662659,198.349197 582.024719,198.823990 581.385864,198.629349 
	C580.879822,198.475159 580.229126,197.810638 580.178284,197.317535 
	C579.955811,195.159088 579.615295,193.330688 577.880737,191.558655 
	C576.140442,189.780746 578.066223,188.774734 579.690552,189.118835 
	C582.535583,189.721512 585.131287,189.531906 587.910706,188.649567 
	C590.765137,187.743393 591.618713,189.919403 591.991577,192.630768 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M558.661377,227.959656 
	C559.156738,230.175293 561.186646,230.020142 562.478882,230.738693 
	C563.975464,231.570877 565.328186,232.360947 564.130127,234.696716 
	C562.575439,234.697922 561.150818,234.396454 559.363159,234.046890 
	C558.403748,233.980759 557.869141,233.796906 557.205383,233.204010 
	C556.579041,231.188126 553.379028,230.515137 554.751099,228.078461 
	C555.622986,226.530075 557.105469,227.718399 558.661377,227.959656 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M569.289551,191.704803 
	C568.734680,191.856949 568.566528,191.914780 568.405640,191.898880 
	C566.374146,191.697983 564.015015,192.997299 562.429077,190.818985 
	C561.899048,190.090881 562.420044,189.119690 563.191284,188.706100 
	C565.529907,187.451859 568.316650,187.426285 570.447754,188.329117 
	C573.415649,189.586426 571.074646,190.716553 569.289551,191.704803 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M537.931641,238.339966 
	C540.366455,237.990585 542.819702,237.847519 542.989136,241.617676 
	C542.020752,242.062180 541.039490,242.103058 540.062622,242.191330 
	C538.471680,242.335114 536.999634,245.342667 535.471008,242.974915 
	C534.142029,240.916382 537.428711,240.363571 537.931641,238.339966 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M555.396484,194.873581 
	C555.899597,196.633957 556.647217,198.370728 554.369934,198.708176 
	C552.926270,198.922104 551.964600,197.681122 551.399231,196.434952 
	C550.765015,195.037186 551.299927,193.617432 552.401672,192.876999 
	C554.110657,191.728485 554.562500,193.502869 555.396484,194.873581 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M585.150757,179.008118 
	C586.793091,179.082565 588.124146,178.755203 590.077026,180.147186 
	C586.958557,180.318527 585.245361,184.222473 582.624573,181.508774 
	C581.141663,179.973328 583.104980,179.106430 585.150757,179.008118 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M543.007202,242.352356 
	C543.324890,242.054062 543.681335,242.040298 543.969238,242.174026 
	C545.149231,242.722168 546.530884,243.187912 546.166931,244.907028 
	C546.099792,245.224075 545.390625,245.658493 545.027039,245.616165 
	C543.329041,245.418472 543.103027,244.083908 543.007202,242.352356 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M557.621582,222.528717 
	C558.654968,223.511520 559.001648,224.354004 557.574951,224.798676 
	C556.806763,225.038086 555.812622,225.029648 555.603638,224.050201 
	C555.335510,222.794083 556.257812,222.424545 557.621582,222.528717 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M557.000000,233.333221 
	C557.545410,233.180008 558.090881,233.360016 558.818115,233.769409 
	C558.528503,234.312408 558.056946,234.626007 557.292725,234.969498 
	C557.000000,234.555084 557.000000,234.110779 557.000000,233.333221 
z"/>
<path fill="#CBC9C6" opacity="1.000000" stroke="none" 
	d="
M531.919067,305.793732 
	C529.898621,305.393982 527.871643,304.735779 525.436951,305.987732 
	C523.100952,304.747223 521.138489,303.434967 521.007324,300.359436 
	C524.534302,299.564514 526.405823,304.227020 530.598511,303.001648 
	C531.304932,303.822052 531.609924,304.641693 531.919067,305.793732 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M469.653870,485.182251 
	C470.484589,484.548737 470.928467,483.754669 471.454742,483.695648 
	C477.382477,483.031311 479.412079,479.368713 480.391876,473.851440 
	C481.298553,468.745697 480.531982,464.859650 477.577789,460.454010 
	C474.804352,456.317902 472.267670,451.800812 469.641144,447.547729 
	C466.359772,442.234253 465.159393,435.261871 458.793976,431.935760 
	C457.427582,431.221771 457.017609,429.308105 457.656982,427.461792 
	C458.321869,425.541870 458.005219,423.828339 455.359314,423.054749 
	C452.379242,421.752441 448.015167,421.287048 452.415833,416.972687 
	C453.173645,416.229797 452.521667,415.329712 451.867310,414.843353 
	C450.055054,413.496521 450.141418,411.339966 449.210815,409.265320 
	C449.000000,409.001221 449.000000,409.000000 448.999390,409.000000 
	C449.143646,400.540192 443.783508,392.574280 446.797150,383.830322 
	C447.425293,382.007782 446.350586,380.708740 444.680481,379.840515 
	C443.249146,379.096375 441.511688,378.634613 441.049408,376.350098 
	C440.620941,375.611053 440.469849,375.149139 440.772705,374.306519 
	C441.655914,374.061737 442.312958,374.113159 442.967499,374.187134 
	C445.050812,374.422577 445.829315,378.161804 448.881805,376.101593 
	C453.320404,373.105804 457.995483,370.420624 457.983673,364.130615 
	C457.979614,361.968323 458.035797,359.839600 458.939087,357.404358 
	C463.036682,357.433990 465.989899,356.935913 466.515930,351.714355 
	C466.771912,349.173462 468.921173,347.170074 472.497894,348.674805 
	C476.487823,350.353363 480.082520,347.650269 480.230774,343.211792 
	C480.280945,341.710999 481.090729,340.489746 482.194702,341.528870 
	C487.299377,346.333618 492.906158,341.807892 498.212921,342.593658 
	C499.984772,342.855988 502.037018,341.736908 504.030762,342.496613 
	C504.996094,342.864441 506.158051,343.097900 506.638916,341.941345 
	C509.168182,335.858276 510.754181,341.169952 512.838562,342.700623 
	C513.404785,345.076263 511.764313,345.807373 510.430237,346.002441 
	C506.028107,346.645996 501.760193,347.861786 497.288025,348.369080 
	C490.793884,349.105682 484.649475,351.877472 479.092438,355.486633 
	C476.172241,357.383270 473.561340,359.738464 470.153076,360.835327 
	C468.866669,361.249298 467.787506,362.145050 467.018494,363.234619 
	C461.754486,370.692657 455.939575,377.834229 453.437714,386.889404 
	C452.226532,391.273285 452.792206,395.066376 455.105713,399.238251 
	C457.448334,403.462616 460.060608,407.833832 460.772217,412.802948 
	C461.073822,414.908997 463.031586,415.046722 464.258423,416.559204 
	C464.686188,426.550751 472.604889,433.798309 472.984619,443.459015 
	C473.032288,444.671844 473.948761,445.532318 474.710266,446.411530 
	C479.677795,452.146820 482.760498,458.825043 484.543030,466.144867 
	C484.862518,467.456848 485.537506,468.586060 486.166931,469.748810 
	C488.301453,473.692108 489.854950,477.632019 486.311981,481.669312 
	C485.650146,482.423431 486.499756,483.230621 486.479431,484.396912 
	C486.299042,484.984924 486.167633,485.201477 485.734314,485.654266 
	C484.018677,486.471588 482.454590,486.713959 480.878357,487.921204 
	C471.587646,489.719666 463.053101,492.932983 454.122833,494.587341 
	C451.994965,494.981506 450.007324,495.800385 447.499390,494.760254 
	C445.298798,490.997253 448.004761,490.200378 450.389496,489.481659 
	C456.581238,487.615570 462.948059,486.499542 469.653870,485.182251 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M464.319550,416.330475 
	C461.206635,416.233826 458.117188,415.705505 459.642822,411.508423 
	C459.753418,411.204132 459.803467,410.572601 459.776489,410.566650 
	C454.207825,409.329865 456.924774,403.543884 454.472534,400.850067 
	C450.000488,395.937469 450.585907,390.909912 452.292175,385.135010 
	C454.913635,376.262756 461.087585,369.609619 465.656525,361.958344 
	C467.215210,359.348114 470.626984,359.864227 472.354614,358.293335 
	C478.617218,352.598846 486.394318,349.809113 493.960449,347.070923 
	C498.303253,345.499237 503.604614,346.439270 508.368011,345.076508 
	C509.808380,344.664429 511.283020,344.487915 512.759277,343.283020 
	C515.225525,343.586884 517.249451,341.631866 519.747314,342.734894 
	C519.361938,343.777435 518.726013,344.573517 518.042480,345.676453 
	C517.254150,346.023895 516.513306,346.064514 515.402039,346.124237 
	C513.588135,345.932190 512.547241,346.768768 511.223541,347.727234 
	C508.288208,348.320740 505.599670,348.698517 502.504272,349.082672 
	C500.519684,350.613678 498.252625,349.469391 496.276428,350.760559 
	C494.601257,351.092407 493.600525,351.760895 492.985321,353.367401 
	C492.534882,354.816193 491.535400,354.968933 490.187805,355.144531 
	C487.890747,354.762360 485.944458,354.331299 484.029419,356.099243 
	C483.520721,356.650421 483.214020,356.948242 482.649170,357.435242 
	C481.768738,358.151306 480.951813,358.447174 480.411865,359.508606 
	C480.120789,360.210388 479.897736,360.587402 479.401062,361.188416 
	C478.961823,361.544952 478.762299,361.657806 478.232635,361.871094 
	C475.626587,362.263031 472.543274,361.108459 472.230957,365.435608 
	C471.855835,366.490845 471.421265,367.085175 470.524872,367.783630 
	C463.828308,371.687622 460.896729,377.583862 459.731171,384.556580 
	C458.170288,393.894165 461.954681,402.455627 463.719574,411.308716 
	C464.009155,412.761444 464.681000,414.133240 464.319550,416.330475 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M468.349121,320.950256 
	C467.571320,318.374420 464.423431,318.508545 463.342285,316.196808 
	C462.137451,313.620636 461.335114,311.912048 464.841827,311.019562 
	C468.650848,310.050171 468.962952,307.904785 466.467468,305.312805 
	C463.949371,302.697296 464.944031,299.621094 465.236084,296.820587 
	C465.507355,294.219330 469.621155,293.362488 471.531006,295.412872 
	C472.786774,296.761047 473.658905,298.804901 475.852783,297.356415 
	C477.682037,296.148712 479.205170,294.366028 478.398987,291.913605 
	C477.746246,289.927979 478.999084,288.096466 478.324249,286.038452 
	C477.653900,283.994354 479.338104,283.143219 481.624146,283.009827 
	C483.346222,284.269714 483.364014,285.744476 482.332581,287.664795 
	C479.843414,288.806732 480.882507,289.474487 482.153290,289.887634 
	C485.525635,290.984131 488.875824,292.297363 492.343323,292.904510 
	C494.984833,293.367035 494.654724,294.571686 494.045227,296.642456 
	C487.844604,297.643341 493.555511,301.228973 492.176086,303.490540 
	C489.961853,302.893402 487.486969,302.223236 485.066956,301.666290 
	C481.730408,300.898376 479.231140,301.048218 477.287384,304.068817 
	C476.295105,305.610809 474.573334,305.732300 472.994446,306.258636 
	C470.774414,306.998566 469.440796,308.861145 469.443115,311.170685 
	C469.444489,312.500977 470.745850,313.908508 472.114227,313.535980 
	C474.226776,312.960876 476.791168,312.740417 477.867462,310.289276 
	C478.255310,309.405884 478.073639,308.247711 478.530212,307.424835 
	C479.276367,306.080017 480.574768,305.650452 481.867859,306.713837 
	C483.276459,307.872131 482.247009,308.664185 481.271271,309.432465 
	C480.084229,310.367096 478.684784,311.428589 479.282227,313.108978 
	C480.769958,317.293823 477.965485,317.728851 475.518372,316.773346 
	C470.979034,315.000854 471.607056,320.674194 468.349121,320.950256 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M448.000000,346.576630 
	C444.902954,345.786499 442.913025,350.653595 440.821747,349.125214 
	C438.991547,347.787628 441.086090,344.419952 440.565125,341.707916 
	C439.635071,336.866577 434.326752,335.910034 433.045898,331.354980 
	C437.164734,329.536469 441.362793,331.418457 445.857941,331.000854 
	C446.683350,331.199158 446.925018,331.961090 447.572632,331.657104 
	C447.303131,331.510864 447.051636,331.337646 446.658691,330.851440 
	C446.208710,329.068207 446.484344,327.713531 447.755127,326.273804 
	C450.891937,324.758911 450.684692,323.046753 448.671112,321.162476 
	C448.209747,320.730743 447.348724,320.694580 446.948700,320.235138 
	C445.992950,319.137390 443.357391,318.310425 445.043762,316.525269 
	C446.344269,315.148560 448.938019,315.773376 449.799377,317.501770 
	C452.250061,322.419281 456.860291,322.284454 461.590454,322.997925 
	C462.107758,323.208496 462.215546,323.416992 462.267334,323.972717 
	C461.878418,324.947418 461.378082,325.412201 460.515442,325.941162 
	C459.942139,326.335724 459.206665,326.465942 459.454865,326.858612 
	C459.791901,327.391876 460.489716,326.916229 461.371704,326.863586 
	C462.690582,326.775513 463.623138,326.915070 464.718140,327.759674 
	C465.304565,328.126740 465.557739,328.320587 465.879761,328.790771 
	C463.485901,330.319641 467.850861,334.297424 463.344543,334.990173 
	C461.940094,335.149750 461.012268,334.532166 459.675232,334.475281 
	C458.523407,334.461365 457.692871,334.320221 456.537292,334.287354 
	C453.834869,334.215393 453.743530,336.210602 452.954590,338.031952 
	C452.492981,338.711609 452.121857,338.987122 451.343658,339.089874 
	C448.617035,337.447235 448.571075,339.876678 448.324402,340.882507 
	C447.911896,342.564789 448.077667,344.388855 448.000000,346.576630 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M454.958923,355.655701 
	C451.338409,357.113739 450.373627,359.466705 451.956879,362.864014 
	C452.922058,364.935089 453.762238,367.115326 451.580994,368.725708 
	C449.132355,370.533508 446.122498,370.869080 443.175415,370.327301 
	C442.435547,370.191284 441.215698,368.651154 441.386749,368.201111 
	C442.958466,364.065704 440.080475,359.388214 443.277710,355.396942 
	C445.973022,352.032318 449.422302,351.856995 453.572601,352.000000 
	C454.305939,353.104218 454.611877,354.208435 454.958923,355.655701 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M459.779968,333.245422 
	C461.130554,333.044861 462.062714,333.462067 462.848755,334.718689 
	C464.409515,340.947723 464.355255,340.993439 455.438599,341.000000 
	C454.020172,340.272766 452.879578,339.710602 452.194427,338.237946 
	C452.423035,335.683411 451.958435,332.785645 455.782898,332.776367 
	C456.820709,333.912628 456.265106,335.902374 457.882965,336.021667 
	C459.408325,336.134094 458.842529,334.257660 459.779968,333.245422 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M499.202454,289.230774 
	C498.873352,288.695404 498.679535,288.442230 498.209259,288.120239 
	C498.000000,288.000000 498.000000,287.500000 498.000000,287.250000 
	C498.561401,286.133514 499.135620,285.288208 500.692230,285.777405 
	C501.496429,286.256866 501.992432,286.514923 502.744232,286.886505 
	C503.007568,288.731873 505.212463,289.450592 504.989777,291.646667 
	C504.147705,291.829529 503.295380,291.659058 502.220947,291.244293 
	C501.373505,290.083618 500.163055,290.153900 499.202454,289.230774 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M451.879730,338.209229 
	C453.504150,338.108734 454.570160,338.765381 454.956390,340.664124 
	C453.200592,341.981934 451.709259,342.067291 451.040955,339.324005 
	C451.304596,338.873352 451.557739,338.679474 451.879730,338.209229 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M476.977112,319.144348 
	C475.877594,320.776642 474.873535,321.298065 474.235138,320.766571 
	C473.936768,320.518188 473.598175,319.841644 473.725189,319.637421 
	C474.458130,318.458862 475.534180,318.345490 476.977112,319.144348 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M524.302856,288.076721 
	C521.653320,289.108643 520.894653,287.739532 521.344604,285.860596 
	C521.719177,284.296234 523.134033,282.545288 524.847351,283.454620 
	C527.090881,284.645386 525.745361,286.422943 524.302856,288.076721 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M537.987793,257.099426 
	C540.588806,258.099487 540.252319,259.135712 538.304626,259.976013 
	C537.175964,260.462952 535.489807,261.440399 534.844299,259.887482 
	C533.839661,257.470764 536.249023,257.607971 537.987793,257.099426 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M499.111389,277.726318 
	C497.030487,276.643860 496.422150,275.241364 497.763947,273.582489 
	C498.335144,272.876373 499.420929,273.119843 499.969391,273.804504 
	C501.104584,275.221558 501.788269,276.725006 499.111389,277.726318 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M532.490479,263.409546 
	C532.583435,264.776733 532.196045,265.692413 530.948181,265.419983 
	C529.972351,265.206940 529.962097,264.212860 530.209656,263.443024 
	C530.667419,262.019409 531.511292,262.377716 532.490479,263.409546 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M500.931152,285.668640 
	C500.194946,286.245941 499.389526,286.493103 498.292053,286.870117 
	C498.079102,286.038971 497.865601,284.919769 498.951691,284.388916 
	C499.893433,283.928619 500.488708,284.487061 500.931152,285.668640 
z"/>
<path fill="#060D0E" opacity="1.000000" stroke="none" 
	d="
M488.743958,354.343048 
	C490.146027,354.160400 491.171204,354.030090 492.568512,353.791290 
	C493.094299,353.806549 493.247925,353.930267 493.666199,354.186798 
	C498.939819,369.549133 503.945801,384.779724 508.958862,400.007965 
	C512.833374,411.777557 516.743530,423.535461 520.588867,435.314545 
	C524.679749,447.845795 529.031189,460.306702 532.584351,472.990082 
	C533.701660,476.978149 534.994934,477.485992 538.612305,476.066376 
	C540.127441,476.975800 540.319275,478.231537 540.020996,479.975220 
	C539.781860,480.559906 539.653198,480.776764 539.245117,481.235992 
	C538.744324,481.572418 538.522888,481.666473 537.954102,481.826111 
	C536.502075,481.907898 535.368103,481.763672 534.068481,482.607910 
	C533.279175,482.848328 532.805786,482.895538 531.958069,482.841797 
	C530.628296,482.461670 529.645508,482.177673 528.584290,481.211304 
	C520.101562,456.561249 511.678070,432.270844 503.624908,407.856415 
	C498.653229,392.784088 493.541534,377.755157 488.753479,362.244812 
	C488.050537,361.317230 487.702301,360.627594 487.241669,359.561890 
	C486.876099,358.453217 486.806580,357.687469 486.914490,356.530212 
	C487.223877,355.393433 487.671875,354.796692 488.743958,354.343048 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M558.266235,335.413574 
	C560.791687,331.839691 564.997742,332.968811 568.532104,332.169891 
	C570.500122,331.725037 572.360962,327.904297 574.922729,331.701324 
	C569.665588,333.934113 564.368286,335.974487 558.266235,335.413574 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M549.367798,337.949402 
	C549.307129,333.192352 552.984192,335.615356 555.711304,335.096252 
	C554.972534,338.266937 551.707764,336.590637 549.367798,337.949402 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M496.406982,351.014648 
	C496.831573,347.881897 499.392548,349.372711 501.680481,349.056976 
	C501.173065,352.014526 498.695953,350.711700 496.406982,351.014648 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M511.307648,347.937469 
	C511.470306,346.010712 512.570557,345.046417 514.839966,345.892578 
	C514.125977,347.167175 512.941528,347.662018 511.307648,347.937469 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M531.348267,342.972168 
	C531.293518,340.928833 532.819458,340.996613 534.697144,341.044586 
	C534.720520,343.042389 533.222107,343.019836 531.348267,342.972168 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M600.213135,210.317535 
	C601.600830,208.636490 602.697205,209.078140 603.454163,210.491730 
	C603.909485,211.342148 603.382751,212.160202 602.560913,212.505859 
	C600.778809,213.255371 600.124451,212.337387 600.213135,210.317535 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M609.347046,216.989136 
	C609.167725,215.835526 609.371704,214.646744 610.837891,214.519684 
	C611.111206,214.495987 611.683716,215.088318 611.698975,215.415863 
	C611.762390,216.775833 610.687805,216.864029 609.347046,216.989136 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M581.000000,227.999695 
	C582.823914,227.861267 584.543213,228.273438 586.236816,228.918671 
	C587.473206,229.389679 588.978577,229.765732 588.436829,231.486496 
	C587.967346,232.977921 586.488464,233.168976 585.162292,232.810974 
	C583.075562,232.247650 581.060791,231.468521 581.009705,228.377136 
	C581.000000,228.000610 581.000000,228.000000 581.000000,227.999695 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M521.596924,258.389282 
	C521.141235,257.310760 521.194763,256.471313 522.286682,256.524872 
	C522.721924,256.546234 523.456665,257.290131 523.470825,257.724213 
	C523.506409,258.809174 522.672791,258.866547 521.596924,258.389282 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M784.147583,1077.331543 
	C784.000000,1076.555542 784.000000,1076.111084 784.000000,1075.333252 
	C789.425781,1078.409912 793.796082,1074.216187 798.580078,1072.984131 
	C800.105896,1072.591187 801.738403,1071.297363 803.161499,1072.977051 
	C804.428345,1074.472168 802.927185,1075.727417 802.471008,1077.058472 
	C801.983887,1078.479980 800.141479,1080.323486 802.664124,1081.186279 
	C808.386353,1083.143555 803.734192,1086.085571 803.494141,1088.526978 
	C803.399231,1089.492432 802.189575,1090.015625 801.094910,1089.755005 
	C799.442688,1089.361938 799.471313,1088.251221 799.522644,1086.720703 
	C799.623169,1083.719116 796.844788,1082.186157 794.253357,1083.554321 
	C789.231873,1086.205566 786.503357,1085.024536 784.934814,1079.532837 
	C784.754211,1078.900269 784.509888,1078.285889 784.147583,1077.331543 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M929.008789,1138.372803 
	C929.856201,1136.895874 931.087036,1137.019653 932.637207,1137.000488 
	C936.628479,1135.930664 940.072388,1135.715088 942.922852,1139.517822 
	C943.521606,1141.457642 943.521301,1142.933716 942.284424,1144.645874 
	C936.517639,1146.343018 930.947205,1143.659424 929.008789,1138.372803 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M871.769531,1110.740234 
	C872.000000,1111.750000 872.000000,1112.500000 872.000000,1113.625000 
	C869.351440,1117.789673 865.187866,1114.618286 861.860413,1116.019897 
	C860.975525,1116.392456 858.661560,1113.094238 859.386597,1110.857910 
	C860.194397,1108.366211 862.373474,1108.839355 864.665039,1109.851196 
	C867.034546,1112.135498 869.441467,1109.202881 871.769531,1110.740234 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M780.049683,1057.044556 
	C778.600708,1054.278320 779.925110,1052.847412 782.038513,1052.319702 
	C783.952026,1051.841919 784.574524,1053.713623 784.762695,1055.089722 
	C785.012878,1056.919922 785.983215,1059.503540 783.927002,1060.436035 
	C781.781738,1061.408936 781.451172,1058.463745 780.049683,1057.044556 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M871.477539,1118.347778 
	C873.071472,1119.181763 874.660400,1119.388794 875.591736,1121.318481 
	C873.191040,1121.854126 870.920898,1122.457397 868.614136,1122.839600 
	C867.185425,1123.076416 865.457031,1123.304199 865.202087,1121.153076 
	C864.984131,1119.313477 866.465088,1119.128418 867.778625,1118.876343 
	C868.893555,1118.662354 870.007751,1118.444580 871.477539,1118.347778 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M864.000000,1097.416626 
	C865.789062,1098.092896 867.789368,1097.982300 869.769531,1098.047607 
	C871.175232,1098.093994 872.542969,1098.509644 872.653687,1100.141968 
	C872.789429,1102.143799 871.120178,1101.925171 869.850159,1101.975708 
	C868.200562,1102.041504 866.546509,1101.997070 864.447205,1102.000000 
	C864.000000,1100.611084 864.000000,1099.222168 864.000000,1097.416626 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M806.946289,1069.379883 
	C807.000000,1069.000000 807.000000,1068.500000 807.000000,1068.250000 
	C807.496399,1068.000732 807.994263,1067.977295 808.489014,1068.006470 
	C810.430359,1068.120605 813.332458,1067.967285 812.440063,1070.726929 
	C811.934631,1072.289673 810.137268,1075.284546 807.631775,1074.696045 
	C804.571411,1073.977173 806.687073,1071.514038 806.946289,1069.379883 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M803.113281,1056.744141 
	C801.408447,1056.724243 799.464722,1059.291138 798.243225,1056.591919 
	C797.606750,1055.185791 797.899658,1053.515503 799.179749,1052.470093 
	C801.637878,1050.462524 803.738831,1049.934692 803.997070,1054.610107 
	C803.742249,1055.496094 803.484436,1055.992310 803.113281,1056.744141 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M807.964539,1077.808838 
	C810.281189,1076.608765 812.283875,1076.900513 813.361206,1078.808716 
	C814.120667,1080.154053 812.378235,1081.010254 811.511414,1081.904419 
	C810.771545,1082.667480 809.826050,1082.970459 808.887329,1082.218872 
	C807.525818,1081.128540 806.505188,1079.894409 807.964539,1077.808838 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M810.728027,1087.233887 
	C812.630005,1089.766602 811.098755,1090.551758 809.088623,1090.904297 
	C808.185669,1091.062866 806.665894,1091.214600 806.656921,1090.015747 
	C806.640076,1087.770630 808.383606,1087.130249 810.728027,1087.233887 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M808.497681,1096.447876 
	C810.092529,1094.938354 811.575439,1094.277222 812.689941,1096.354370 
	C813.184753,1097.276733 812.845032,1098.445679 811.681885,1098.708252 
	C810.101135,1099.064819 808.211487,1099.542480 808.497681,1096.447876 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M887.748352,1125.765137 
	C888.000000,1126.000000 888.000000,1126.500000 888.000000,1126.750000 
	C887.155090,1128.363892 886.096863,1129.616699 884.696716,1127.808716 
	C883.680603,1126.496704 883.313904,1124.518555 884.943787,1123.521729 
	C886.703674,1122.445312 886.756470,1124.672974 887.748352,1125.765137 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M853.945312,1098.852051 
	C853.651550,1100.317017 852.980225,1101.133911 851.881775,1100.490601 
	C850.711853,1099.805420 850.945862,1098.509766 851.401855,1097.427612 
	C851.752808,1096.594727 852.096313,1095.272827 853.321350,1095.828491 
	C854.275208,1096.261108 853.939392,1097.540283 853.945312,1098.852051 
z"/>
<path fill="#CDCCCD" opacity="1.000000" stroke="none" 
	d="
M842.250000,1101.192017 
	C840.967896,1098.970825 840.361511,1097.055786 843.657715,1096.047852 
	C843.711182,1097.608887 846.290955,1099.417114 843.331055,1100.931641 
	C842.833313,1101.128052 842.666687,1101.255981 842.250000,1101.192017 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M942.375000,1145.000000 
	C942.000000,1143.611084 942.000000,1142.222168 942.000000,1140.416626 
	C942.671387,1139.356445 943.451660,1139.245972 944.656006,1139.763916 
	C946.113159,1141.299561 946.417480,1142.705078 945.279297,1144.631592 
	C944.250000,1145.000000 943.500000,1145.000000 942.375000,1145.000000 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M945.250000,1145.000000 
	C945.000000,1143.611084 945.000000,1142.222168 945.000000,1140.416626 
	C947.662537,1140.238037 948.067444,1142.071777 947.998779,1144.620605 
	C947.555542,1145.000000 947.111084,1145.000000 946.333313,1145.000000 
	C946.000000,1145.000000 945.500000,1145.000000 945.250000,1145.000000 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M875.299683,1137.102173 
	C876.024597,1136.144287 876.795593,1135.917603 877.382507,1136.717896 
	C877.800781,1137.288208 877.414795,1137.992920 876.831055,1138.310303 
	C875.905151,1138.813843 875.363464,1138.386841 875.299683,1137.102173 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M855.399963,1089.000000 
	C855.797058,1086.102783 857.179810,1086.908203 858.837036,1088.709473 
	C857.933289,1089.000000 856.866638,1089.000000 855.399963,1089.000000 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M780.726440,1073.884521 
	C781.000000,1074.000000 781.000000,1074.500000 781.000000,1074.750000 
	C780.123047,1074.840332 778.653931,1075.314331 778.642517,1074.068726 
	C778.631775,1072.900391 779.765198,1073.551025 780.726440,1073.884521 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M872.412964,1125.658691 
	C872.611694,1126.373291 872.362427,1126.633545 871.806885,1126.521729 
	C871.691589,1126.498535 871.536804,1126.334961 871.519592,1126.218262 
	C871.436707,1125.657471 871.709778,1125.422729 872.412964,1125.658691 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M780.586914,1045.341431 
	C780.388184,1044.626587 780.637512,1044.366333 781.193054,1044.478271 
	C781.308350,1044.501465 781.463196,1044.665161 781.480408,1044.781860 
	C781.563354,1045.342896 781.289795,1045.577271 780.586914,1045.341431 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M841.805542,1101.167358 
	C842.000000,1101.000000 842.500000,1101.000000 842.750000,1101.000000 
	C842.847839,1101.701782 842.866943,1102.581421 841.786072,1102.421265 
	C841.676453,1102.405029 841.666931,1101.713135 841.805542,1101.167358 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M864.120239,1107.209229 
	C864.414795,1106.799805 864.907166,1106.352783 865.227234,1106.453491 
	C865.801819,1106.634033 865.741089,1107.205811 865.196533,1107.839233 
	C864.695374,1107.873413 864.442200,1107.679565 864.120239,1107.209229 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M753.656250,973.781189 
	C753.914062,975.155273 753.937134,976.878235 752.528137,976.730591 
	C748.696411,976.329285 745.707520,979.242554 741.976868,979.029297 
	C739.668152,978.897400 737.740479,979.705994 736.561401,981.873291 
	C736.087158,982.745056 735.576965,983.832581 734.466003,983.813965 
	C729.782410,983.735779 728.239685,985.613770 729.441711,990.339294 
	C730.108154,992.959167 727.720520,993.221558 725.816101,992.662903 
	C724.775696,992.357666 723.950195,991.332825 723.016846,990.647400 
	C716.959961,986.199951 714.926697,986.388733 709.943054,991.802856 
	C709.183411,992.628235 709.039429,993.518127 709.335022,994.554810 
	C710.070007,997.132751 709.122620,998.037598 706.550720,997.350586 
	C705.503113,997.070679 704.442017,997.086670 704.282776,998.444824 
	C704.118958,999.841187 705.061218,1000.711548 706.317078,1000.818054 
	C714.298767,1001.494934 715.766602,1007.632141 717.342041,1013.743347 
	C717.647766,1014.929321 717.515259,1015.812683 716.178040,1015.864319 
	C714.175049,1015.941650 712.557800,1014.797546 713.340027,1012.867493 
	C714.873901,1009.082947 711.662842,1008.331848 709.908447,1006.690613 
	C708.877686,1005.726501 707.305054,1005.678162 706.126709,1006.321106 
	C704.406067,1007.259644 705.394775,1008.550049 706.115173,1009.684509 
	C706.608337,1010.460999 706.731140,1011.746216 705.806763,1011.611206 
	C703.558655,1011.282898 701.318542,1010.560852 699.218506,1009.662537 
	C697.438049,1008.901001 697.762756,1007.029663 698.325684,1005.729614 
	C699.933777,1002.016296 700.074829,997.586365 703.602478,994.770996 
	C704.344543,994.178833 705.211304,992.742493 703.889526,992.437378 
	C701.755127,991.944763 699.503113,990.484192 697.208740,992.012634 
	C695.977234,992.833130 695.815002,993.857239 696.981995,994.854187 
	C698.140991,995.844360 699.469055,996.702454 698.242432,998.770020 
	C696.361145,997.676147 693.252747,998.723022 692.801086,995.304993 
	C692.457031,992.701355 691.082397,991.368286 695.498291,990.223450 
	C701.956909,988.549133 708.718079,986.109863 714.971863,985.297485 
	C720.322144,984.602478 724.207703,981.207642 729.262634,980.881104 
	C731.235962,980.753601 733.175476,979.982117 733.942139,977.358093 
	C733.995117,976.995117 734.000000,977.000000 733.997803,976.997559 
	C737.222961,973.677917 741.807434,975.994873 745.564819,974.200989 
	C747.722900,973.170715 750.657532,972.964111 753.656250,973.781189 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M676.222168,991.758301 
	C671.813293,992.426636 667.571716,994.441284 663.250366,991.286621 
	C664.014404,989.770935 665.375122,989.170227 666.909912,989.043152 
	C679.767090,987.979126 691.198242,982.402283 702.935181,977.763428 
	C705.534302,976.736206 708.304382,975.995178 710.899658,978.608887 
	C710.436523,980.059814 709.605347,980.734314 708.350403,980.934265 
	C700.633972,982.163879 693.060486,983.688293 687.456177,989.913208 
	C685.960205,991.574768 683.753967,990.876709 681.413757,991.004272 
	C680.615051,991.382324 680.154358,991.528381 679.310120,991.222595 
	C678.148132,991.173096 677.296265,991.343628 676.222168,991.758301 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M629.250000,1033.266968 
	C627.538574,1032.427246 626.077148,1031.854614 624.307861,1031.140869 
	C624.004456,1027.457764 624.820068,1025.698120 628.752869,1023.461914 
	C634.552002,1020.164429 636.521729,1020.861694 638.416992,1026.464478 
	C638.531372,1026.802734 639.609558,1026.814819 640.619629,1026.989258 
	C638.066650,1029.790405 636.636353,1035.134766 630.402283,1033.000000 
	C629.833313,1033.177979 629.666687,1033.355957 629.250000,1033.266968 
z"/>
<path fill="#D0CFCE" opacity="1.000000" stroke="none" 
	d="
M723.047729,1040.283936 
	C723.444458,1040.000000 723.888916,1040.000000 724.666687,1040.000000 
	C724.893250,1040.628052 724.827942,1041.380859 724.042542,1041.421021 
	C723.742126,1041.436279 723.411987,1040.869507 723.047729,1040.283936 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M679.047607,991.286255 
	C679.444946,991.002258 679.889954,991.002075 680.668701,991.003418 
	C680.894348,991.632202 680.826355,992.384033 680.042114,992.422302 
	C679.741028,992.436951 679.411682,991.871277 679.047607,991.286255 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M615.330872,1042.062988 
	C614.429016,1041.184692 613.582764,1041.016846 612.334290,1041.004395 
	C614.205383,1038.041016 621.005554,1038.336670 622.550964,1041.466309 
	C623.281860,1042.946411 622.483643,1043.984131 621.476929,1044.935181 
	C620.443420,1045.911743 619.418823,1045.820801 618.432007,1044.859375 
	C617.502869,1043.954102 616.584717,1043.037598 615.330872,1042.062988 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M646.632385,1060.436646 
	C646.235413,1060.095947 646.137512,1059.961792 646.041626,1059.826294 
	C644.607117,1057.798096 644.973389,1056.086670 647.015564,1054.755127 
	C647.593811,1054.378052 648.571899,1054.348633 648.631409,1055.129028 
	C648.784180,1057.134277 650.518433,1059.758911 646.632385,1060.436646 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M623.004395,1036.857178 
	C621.542908,1034.475464 622.823853,1034.053589 624.595520,1034.169189 
	C625.624939,1034.236450 626.669556,1034.833618 626.516235,1035.954468 
	C626.210388,1038.189819 624.754578,1037.827881 623.004395,1036.857178 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M644.678467,1052.183594 
	C644.441345,1053.671143 643.757751,1054.135010 642.700500,1053.377808 
	C642.421326,1053.177979 642.234314,1052.452881 642.375671,1052.132935 
	C642.913940,1050.914551 643.747559,1051.059692 644.678467,1052.183594 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M596.257690,1019.287964 
	C597.317139,1017.972656 598.019409,1018.497620 598.445007,1019.521240 
	C598.558838,1019.795044 598.228516,1020.533936 597.944885,1020.640381 
	C597.043701,1020.978394 596.435852,1020.528748 596.257690,1019.287964 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M614.008362,1046.332153 
	C614.000000,1046.000000 614.500000,1046.000000 614.750000,1046.000000 
	C615.816284,1046.151733 616.738220,1046.363525 616.488464,1047.415161 
	C616.380127,1047.871338 615.599731,1048.477051 615.126831,1048.475098 
	C614.042358,1048.470703 614.133789,1047.438477 614.008362,1046.332153 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M655.444580,1037.014160 
	C654.987000,1037.834717 654.418579,1037.937012 653.860291,1037.545898 
	C653.412048,1037.232056 653.416992,1036.735352 653.878479,1036.435669 
	C654.447571,1036.065918 655.014282,1036.174561 655.444580,1037.014160 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M602.772156,1039.739502 
	C601.828064,1039.678345 601.321899,1039.340454 601.714844,1038.634033 
	C601.856934,1038.378784 602.562744,1038.203491 602.880432,1038.327271 
	C603.577637,1038.599121 603.641174,1039.144531 602.772156,1039.739502 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M590.765869,1036.514160 
	C591.592468,1036.153687 592.220459,1036.180542 592.381287,1036.902222 
	C592.430786,1037.124512 591.928894,1037.678345 591.622192,1037.728149 
	C590.967224,1037.834961 590.473450,1037.512085 590.765869,1036.514160 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M608.736023,1036.899902 
	C608.524475,1037.837280 608.052124,1038.744995 606.890991,1038.539429 
	C606.691956,1038.504150 606.400513,1037.707520 606.518372,1037.405884 
	C606.874207,1036.496216 607.640198,1036.431763 608.736023,1036.899902 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M633.514038,1048.233887 
	C633.153320,1047.406982 633.180542,1046.779053 633.902405,1046.618530 
	C634.124817,1046.569092 634.678467,1047.070923 634.728394,1047.377930 
	C634.835144,1048.033081 634.511902,1048.526489 633.514038,1048.233887 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M629.006348,1033.313965 
	C629.000000,1033.000000 629.500000,1033.000000 629.750000,1033.000000 
	C630.152893,1033.826416 631.361572,1034.910522 630.018250,1035.388184 
	C629.152527,1035.696045 629.106750,1034.389526 629.006348,1033.313965 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M609.113098,1036.950439 
	C609.000000,1037.000000 609.241943,1036.598511 609.241943,1036.598511 
	C609.241943,1036.598511 609.226196,1036.900757 609.113098,1036.950439 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M700.087341,1094.465576 
	C699.408936,1096.991089 698.028564,1095.835938 696.368408,1095.374023 
	C697.404480,1093.665527 698.646545,1094.033081 700.087341,1094.465576 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M998.201904,1178.328491 
	C1000.169617,1173.217896 1003.982727,1175.979736 1007.598877,1175.998047 
	C1008.875305,1177.675903 1009.103271,1179.506104 1006.795898,1179.744995 
	C1003.996033,1180.034912 1000.926086,1181.028809 998.201904,1178.328491 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M960.378906,1151.053101 
	C958.504700,1148.773682 959.822510,1147.553223 961.804443,1146.250122 
	C964.322083,1146.228027 967.310852,1146.293091 966.728149,1149.539795 
	C966.044067,1153.350952 963.070251,1151.698975 960.378906,1151.053101 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M953.224792,1153.241943 
	C953.115051,1151.955078 952.805115,1150.617676 954.240295,1150.296631 
	C955.772522,1149.953857 956.498535,1150.979004 956.948181,1152.649170 
	C955.956299,1154.186646 954.754150,1154.210938 953.224792,1153.241943 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M657.058228,1141.136719 
	C659.779419,1143.000977 661.002869,1149.689575 659.396118,1151.709473 
	C658.649536,1152.647949 657.807007,1152.836670 656.862061,1152.025391 
	C655.546692,1150.896118 655.410400,1142.980469 657.058228,1141.136719 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M655.863281,1138.762085 
	C653.181335,1137.431152 653.801147,1135.869385 655.409851,1134.555176 
	C656.453674,1133.702148 657.755188,1134.109863 658.380798,1135.256104 
	C659.613586,1137.514771 657.753418,1138.070435 655.863281,1138.762085 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M678.301392,1150.436279 
	C676.309448,1151.082397 674.701172,1151.086060 674.468445,1148.816406 
	C674.382202,1147.974854 675.191223,1147.312378 676.031677,1147.265015 
	C678.049866,1147.151001 679.082947,1148.044067 678.301392,1150.436279 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M665.759644,1156.943604 
	C665.042847,1155.446167 664.525635,1154.119751 666.162903,1153.401978 
	C667.082336,1152.998901 667.818909,1153.727051 667.816223,1154.583252 
	C667.812561,1155.754517 668.859253,1157.921265 665.759644,1156.943604 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M651.638306,1121.008301 
	C651.598572,1123.504028 650.264099,1124.416748 647.550476,1123.448120 
	C648.095215,1121.386475 649.662292,1121.168091 651.638306,1121.008301 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M686.218750,1156.244141 
	C687.117554,1155.170410 688.059021,1154.680908 688.483215,1156.112427 
	C688.762939,1157.056274 688.177063,1158.204956 687.134827,1158.331665 
	C686.010559,1158.468262 686.151978,1157.341797 686.218750,1156.244141 
z"/>
<path fill="#D2CFD0" opacity="1.000000" stroke="none" 
	d="
M1109.030273,1206.997559 
	C1107.254272,1206.739746 1105.277954,1207.746704 1104.692017,1205.933960 
	C1104.217529,1204.465698 1105.768066,1203.493774 1107.039795,1203.275269 
	C1108.908447,1202.954468 1111.482910,1202.034668 1112.480225,1204.124634 
	C1113.769653,1206.826416 1111.322510,1206.909180 1109.030273,1206.997559 
z"/>
<path fill="#1B273C" opacity="1.000000" stroke="none" 
	d="
M677.622498,874.645386 
	C690.760193,870.624939 690.692444,870.644409 694.272339,883.101746 
	C696.149048,889.632324 698.311829,896.080688 700.535400,902.875122 
	C702.670715,904.276489 703.024414,906.281555 703.475220,908.169861 
	C704.955994,914.372314 707.158325,920.371399 708.762634,926.921021 
	C709.561218,929.303284 709.920471,931.432068 710.917847,933.733643 
	C711.282959,934.723877 711.394470,935.420959 711.708191,936.428162 
	C711.880371,937.236816 711.911499,937.708496 711.862183,938.540161 
	C711.777954,939.132263 711.729065,939.367493 711.580383,939.948120 
	C711.379944,942.283752 711.282288,944.282959 711.808044,946.228271 
	C712.530334,948.901001 714.095764,951.625366 710.222290,953.378784 
	C705.076538,955.708008 701.905029,955.309021 699.185547,951.564331 
	C696.223206,947.485229 694.296814,942.972412 693.494019,937.954651 
	C692.311829,930.565186 690.725525,923.278015 687.360413,916.500732 
	C684.911926,911.569702 681.508118,909.843872 676.445740,911.046265 
	C670.697083,912.411621 667.927917,915.840088 668.147034,921.806458 
	C668.673889,925.926392 669.298645,929.659302 669.736938,933.419556 
	C670.645935,941.218262 673.671509,943.972656 681.888672,944.412903 
	C684.543884,944.598877 686.364136,943.417480 688.548584,942.137085 
	C689.857178,941.508301 690.862000,941.193665 692.359375,941.341187 
	C696.219910,945.447754 698.315125,949.802856 695.846313,955.479614 
	C694.786987,956.191467 693.986023,956.421021 692.736511,956.510376 
	C689.806030,956.388306 687.973999,958.043762 685.997559,959.160889 
	C679.476440,962.846802 673.247253,962.212036 667.182434,958.224487 
	C651.343079,947.810120 648.421631,925.133545 656.155579,912.027039 
	C660.588745,904.514221 667.419739,899.999756 676.506897,899.538025 
	C681.359192,899.291504 681.983276,898.376770 680.710327,893.667419 
	C679.886108,890.618530 678.781433,887.650879 677.789490,884.651001 
	C676.778625,881.594116 676.309387,878.508667 677.622498,874.645386 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M848.998474,785.604187 
	C847.764221,787.355103 846.886902,786.783813 845.781799,785.656250 
	C842.970093,782.787598 843.199524,785.475464 843.001587,787.641113 
	C843.014526,789.805847 843.385864,791.955811 841.073364,792.547363 
	C838.514648,793.201904 838.204163,791.024963 837.723267,789.262756 
	C837.490173,788.408630 837.108521,787.625061 836.041260,787.965454 
	C834.840271,788.348572 834.662720,789.404907 835.030945,790.388428 
	C836.320618,793.833252 835.302795,796.169006 831.530884,797.518066 
	C830.158875,798.049011 829.155273,798.597168 827.697510,798.884888 
	C825.591187,799.033997 824.084839,799.926758 822.252319,800.801208 
	C817.887512,800.698364 815.864563,802.506348 815.935181,806.731873 
	C815.966248,808.596130 815.320496,810.282166 813.300415,811.039490 
	C812.198608,811.452637 810.956421,812.032166 811.527527,813.474121 
	C812.359375,815.574402 813.972290,814.315552 815.657471,814.041138 
	C817.057739,814.949951 817.020081,816.072266 816.257202,817.634155 
	C815.452332,818.308472 814.930237,818.681641 814.351074,818.910522 
	C813.400208,819.286438 811.692749,818.550537 811.629639,820.154480 
	C811.561462,821.889709 813.232605,821.784363 814.485046,822.012085 
	C815.747192,822.241455 816.987122,822.592957 818.618408,822.945435 
	C817.553955,825.086304 815.722229,826.605774 813.206970,825.012695 
	C810.272827,823.154480 807.724487,820.627930 807.659241,816.934570 
	C807.624207,814.953430 808.447021,813.017761 806.222046,811.280273 
	C805.141113,808.044739 806.058350,805.585022 807.923584,803.338806 
	C805.213623,804.930847 806.650635,808.647583 804.237915,810.753662 
	C800.510559,809.753296 802.965454,807.098572 803.259827,805.649414 
	C804.094604,801.539612 802.582764,799.953918 798.808899,800.044067 
	C796.094177,800.108948 794.758728,799.164307 794.964783,796.345703 
	C795.088074,794.658752 793.938660,793.706726 792.812134,792.674683 
	C791.746826,791.698547 791.993530,790.507507 793.115356,789.739441 
	C794.288818,788.935974 794.896179,789.933228 795.376282,790.811951 
	C797.763062,795.180908 804.148438,794.627930 806.173218,799.623779 
	C806.490234,800.406189 807.791809,799.084412 808.734863,798.206177 
	C809.410522,797.576721 809.884705,797.200439 810.020935,796.294983 
	C811.623108,794.157471 813.400757,792.434631 813.618042,789.739990 
	C813.700806,788.714050 814.063049,787.591858 812.733215,787.336792 
	C811.380615,787.077393 811.097107,788.099670 811.219971,789.229431 
	C811.571411,792.461975 809.098267,792.945862 807.074829,792.642029 
	C804.628540,792.274719 803.352722,790.079224 804.339722,787.716614 
	C806.087708,783.532471 807.665222,779.914368 800.673828,779.801025 
	C798.860291,779.771606 799.476440,778.202942 799.958313,777.133667 
	C801.017212,774.783936 802.187866,771.638062 800.053833,770.215515 
	C797.253174,768.348694 793.980042,765.880310 790.007507,767.520752 
	C787.281067,768.646606 782.801453,768.244202 784.749634,773.600403 
	C785.154236,774.713074 783.921082,775.711365 782.916138,776.337769 
	C781.604736,777.155273 780.688477,776.234192 779.914001,775.375122 
	C777.943787,773.189758 774.370789,771.561096 777.429871,767.618042 
	C778.303406,766.492188 777.072388,765.306946 776.100708,764.781921 
	C773.827454,763.553650 774.289490,761.668396 775.057312,760.088562 
	C776.754517,756.596313 778.662659,753.191772 780.707764,749.889587 
	C781.782288,748.154663 783.692810,748.675964 785.494690,748.447021 
	C788.243774,748.097778 793.335938,751.055542 793.228088,744.580322 
	C793.209839,743.487427 794.230835,743.056152 794.936340,743.710266 
	C797.542664,746.126953 799.970215,745.430908 802.996643,744.312378 
	C805.287720,743.465454 811.152039,750.956909 810.805603,753.851257 
	C810.617798,755.419556 809.391235,757.030884 811.536377,758.208008 
	C813.544861,759.309998 815.300354,759.123413 816.996460,757.537842 
	C817.945129,756.651123 818.954895,755.490173 819.962219,757.716125 
	C822.403625,763.111145 827.519958,765.361877 832.569824,766.869629 
	C840.890930,769.354065 847.613586,776.313110 848.998474,785.604187 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M683.151245,868.670044 
	C680.463989,869.480591 676.017273,867.591797 677.826538,873.620178 
	C677.971741,873.947754 678.020752,874.205750 678.087769,874.318726 
	C677.445007,875.172241 676.559021,875.595215 675.218872,875.936829 
	C673.304260,876.216919 671.921143,875.726868 670.245789,874.901855 
	C667.606628,874.171997 665.485046,873.808289 663.334229,875.582886 
	C660.903992,877.587952 658.420044,876.894226 657.143311,874.184021 
	C656.019409,871.798401 654.395752,870.625488 651.986145,870.049988 
	C650.666809,869.734924 649.651733,868.814819 648.815857,867.733154 
	C646.450134,864.671570 646.968811,862.326599 650.759521,861.607178 
	C655.468140,860.713440 657.798767,856.688232 660.967712,854.079590 
	C662.919861,852.472717 662.807922,848.160339 664.756287,845.277527 
	C666.827881,842.212463 668.901855,840.229248 672.730652,840.515320 
	C675.007385,840.685425 676.692322,839.685730 677.126892,837.358826 
	C677.892090,833.261658 680.380920,831.404846 684.408997,831.247803 
	C686.193115,831.178223 687.464417,830.055725 688.976318,828.670166 
	C689.855225,827.977478 690.508850,827.622314 691.519775,827.151489 
	C697.903564,824.928589 702.778503,820.926941 707.511108,816.905579 
	C710.611389,814.271179 713.650269,812.361328 717.765686,812.929199 
	C720.120361,813.254028 721.690430,812.434387 723.482239,810.844360 
	C731.117859,804.068298 737.765015,796.185486 747.063354,791.249451 
	C751.376526,788.959717 754.112183,784.313843 757.565063,780.262207 
	C758.506165,778.495178 759.471497,777.321533 761.676819,777.789185 
	C762.165833,780.339539 761.434814,783.203491 765.720337,780.701477 
	C767.671753,779.562134 770.799072,780.183655 773.351135,780.433960 
	C774.612122,780.557556 775.702820,781.804199 775.702454,783.308228 
	C775.702087,784.961670 774.315552,785.045654 773.173401,784.957153 
	C770.244263,784.729980 768.550964,785.362122 767.258423,788.631836 
	C766.154419,791.424622 763.562256,794.320374 759.426575,793.309265 
	C758.568604,793.099426 757.711487,793.111572 757.364990,794.092163 
	C757.010376,795.095581 757.687073,795.628113 758.516052,795.959656 
	C760.836487,796.887634 761.921631,798.740295 760.181885,800.569336 
	C758.579102,802.254456 759.396118,806.617310 755.178528,805.532410 
	C752.748108,804.907227 750.873474,803.881042 751.520935,800.691711 
	C751.926208,798.695557 750.819275,797.849548 748.854858,798.776123 
	C747.357483,799.482361 745.874512,800.239441 744.461548,801.099487 
	C742.514771,802.284424 742.481628,803.660889 744.416077,804.891052 
	C745.482056,805.569031 747.305786,805.861328 746.121826,807.626160 
	C745.339844,808.791748 743.982178,808.721497 742.516724,808.290344 
	C735.492310,806.223572 729.470825,810.840210 729.002075,818.510925 
	C728.654175,824.204163 727.314392,825.221130 722.400085,822.420959 
	C719.531799,820.786560 717.817505,821.244934 716.028320,823.483704 
	C713.987183,826.037781 711.402954,826.412659 708.397400,825.667175 
	C706.968262,825.312744 705.457397,825.091919 703.991821,825.129761 
	C702.211365,825.175659 701.925415,826.591675 702.095520,828.018311 
	C702.316040,829.868103 703.513123,830.263062 704.924316,829.333557 
	C706.953613,827.996826 707.284729,828.566772 708.166565,830.760376 
	C709.919678,835.121033 709.826294,839.226746 709.171753,843.777954 
	C708.492004,848.504272 705.073669,850.010376 701.946533,849.627380 
	C698.199158,849.168396 694.733521,849.805420 691.149170,850.013916 
	C689.735779,850.096130 687.914795,849.487793 687.269836,851.392883 
	C686.677124,853.143555 687.685181,854.651184 688.672119,855.989258 
	C692.045654,860.563232 692.157166,860.739990 686.608765,863.173889 
	C684.066284,864.289185 684.183838,866.492859 683.151245,868.670044 
z"/>
<path fill="#212C3D" opacity="1.000000" stroke="none" 
	d="
M708.733643,897.302124 
	C718.649902,885.707092 730.939026,882.951294 745.390381,886.502441 
	C749.726074,886.786438 751.787720,889.504700 753.654175,892.463745 
	C754.958496,894.531738 755.992615,896.758179 757.500488,899.124207 
	C758.151489,900.282043 758.326355,901.249634 758.904785,902.456543 
	C759.120972,903.042297 759.196045,903.282776 759.337097,903.897827 
	C761.726868,914.422485 763.723999,924.659668 767.495422,934.422180 
	C768.037598,935.825562 768.355469,937.281006 768.013062,939.191162 
	C767.460754,940.633240 766.646790,941.279663 765.194702,941.712524 
	C764.544556,941.762085 764.285034,941.747437 763.653564,941.641113 
	C754.350342,940.598694 754.350342,940.598694 750.579163,936.403381 
	C750.460999,939.522034 749.651367,941.981079 747.527771,943.981445 
	C745.272888,946.105469 742.531311,946.384155 739.739563,946.888367 
	C735.531006,947.648560 731.417786,946.927856 727.342407,946.181702 
	C723.816223,945.536133 721.715393,942.913940 719.981323,939.920227 
	C715.051147,931.408264 715.713135,923.085083 720.615662,914.841187 
	C721.722961,912.979248 722.917664,911.174866 724.314697,909.080322 
	C724.646667,908.598999 724.772644,908.404724 725.125610,907.954712 
	C726.080627,906.938477 727.259644,906.596191 727.572388,905.056152 
	C727.843018,904.059082 728.148682,903.444397 728.866943,902.677490 
	C730.655334,901.760132 732.057312,902.393372 733.510681,902.738220 
	C735.069214,903.108093 737.402954,903.956299 737.856079,902.031250 
	C738.424194,899.617920 735.709534,899.546631 734.061462,899.115051 
	C731.273621,898.385071 728.460632,898.960571 725.636658,899.184509 
	C720.257996,899.610840 714.807495,901.782532 709.211609,898.596802 
	C708.825195,898.117554 708.718811,897.888916 708.733643,897.302124 
z"/>
<path fill="#212C3D" opacity="1.000000" stroke="none" 
	d="
M751.916016,859.356323 
	C753.916992,858.538940 755.687073,857.992310 757.728149,857.224243 
	C760.451721,853.789734 764.054504,854.028503 767.869446,853.986755 
	C768.670105,854.423157 769.068604,854.759705 769.666504,855.461548 
	C770.413452,856.703186 770.852356,857.660400 771.256104,859.050598 
	C771.439880,860.250366 771.451843,861.075500 771.568115,861.891663 
	C773.033813,872.178955 774.809509,871.806091 781.951843,869.171570 
	C788.423706,866.784302 790.874084,869.475098 792.218079,879.198914 
	C791.293274,883.478943 788.093201,884.342834 784.829590,885.102905 
	C778.255249,886.634094 777.812134,887.676880 781.607422,893.673096 
	C782.502197,894.301025 783.004211,894.828308 783.644287,895.725952 
	C785.228943,901.175598 786.066101,906.448303 788.082642,911.431702 
	C789.635925,915.270020 791.985474,917.120544 796.542419,915.798950 
	C798.629395,915.599976 800.258606,914.582825 802.386230,915.319031 
	C804.149658,916.136719 804.036987,917.621643 804.504456,918.792419 
	C804.685303,919.435120 803.726746,918.916626 804.393250,918.903137 
	C804.517883,918.900635 804.491577,918.761475 804.430786,918.462341 
	C804.131531,916.989563 803.617249,915.647461 802.110962,914.410034 
	C800.892090,907.565063 802.021484,904.497742 806.613525,902.551880 
	C809.842896,907.231506 808.754639,912.564819 809.916138,917.856689 
	C809.427429,919.866821 808.283691,921.068604 807.260498,922.781311 
	C806.646057,923.424438 806.155701,923.753845 806.047241,924.208740 
	C804.388123,931.164673 800.026855,934.379150 792.861938,934.697021 
	C784.726990,935.057861 778.870911,931.644714 775.480408,924.606018 
	C771.795349,916.955627 769.634705,908.686157 767.463806,900.485107 
	C766.643738,897.387207 765.534180,894.465210 763.992065,891.702454 
	C761.682312,887.564636 760.678467,887.456238 756.618530,890.951660 
	C755.600586,891.596497 754.862183,891.611389 753.817261,891.033447 
	C752.214783,888.781799 751.987976,886.102661 749.524048,884.360352 
	C749.067932,883.615295 748.884094,883.152100 748.703979,882.298523 
	C748.497681,878.579346 749.791748,876.752563 753.770691,877.402405 
	C754.450195,877.381958 754.874023,877.351929 755.026794,877.103027 
	C757.107056,873.714600 755.585388,863.745911 752.266235,860.484131 
	C751.883362,860.057983 751.784241,859.842529 751.916016,859.356323 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M976.643005,754.719116 
	C972.252136,764.012390 965.750916,771.294373 956.428711,775.795715 
	C953.419434,777.248779 950.594971,778.980896 948.163025,781.307129 
	C947.685486,781.763855 947.099243,782.517334 946.604980,782.485474 
	C936.990112,781.865479 932.553650,790.908447 925.083801,793.818054 
	C921.118103,795.362854 915.989563,794.176453 912.252625,797.542725 
	C903.694397,793.011719 894.141418,796.325378 885.254456,794.165039 
	C883.218811,793.670166 881.478394,793.153870 880.069031,791.599487 
	C878.921936,790.334473 877.413940,789.149475 875.798950,789.216064 
	C867.761597,789.547485 862.821777,783.983032 857.267700,779.689148 
	C846.790222,771.589050 836.626465,763.108398 827.226929,753.750366 
	C826.641907,753.168030 826.018738,752.531128 825.286377,752.203918 
	C817.398621,748.679382 810.880371,743.099243 803.972839,738.102905 
	C802.065430,736.723328 799.776245,737.375671 797.709229,736.942505 
	C786.433044,734.579834 780.550476,741.004761 776.185486,749.932129 
	C775.621582,751.085388 775.127625,751.975586 773.091553,751.764160 
	C772.189758,751.153625 771.998962,750.723511 771.828064,749.915588 
	C771.736206,747.872498 772.439209,746.469116 773.689819,744.950989 
	C774.442688,744.076660 774.880310,743.394226 775.046509,742.223999 
	C775.678345,739.544434 777.461121,737.993103 779.176880,735.980469 
	C779.846313,735.440125 780.182373,735.074951 780.474060,734.665955 
	C788.514587,723.391907 791.789917,723.656128 803.920959,730.017517 
	C812.099976,734.306519 820.096802,739.246704 827.808594,744.322998 
	C837.599670,750.768005 847.462585,757.397705 855.883606,765.765442 
	C859.624451,769.482483 864.787964,770.845398 869.123779,773.614197 
	C872.045471,775.479980 875.210693,777.074707 878.010742,779.134216 
	C881.125000,781.424927 884.072693,783.719910 888.209106,783.801392 
	C889.946594,783.835632 891.097900,785.238037 892.259033,786.488708 
	C896.399719,790.948975 899.108521,791.566589 904.534302,789.385803 
	C905.941101,788.820312 907.412170,788.215698 908.871765,788.300476 
	C916.221313,788.727051 922.540710,785.515564 929.078369,782.943115 
	C931.679138,781.919739 933.226013,779.589905 935.565796,778.362366 
	C941.605103,775.193970 947.683594,771.913269 954.125122,769.828003 
	C959.477295,768.095337 963.449097,765.002502 967.349548,760.869141 
	C968.024109,758.846069 969.208191,757.486206 970.031189,755.504395 
	C970.410950,754.957947 970.592346,754.756592 971.120117,754.331238 
	C973.137939,753.439575 974.753113,753.539062 976.643005,754.719116 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M753.849976,889.620972 
	C754.491089,890.230225 754.871094,890.645386 755.523987,891.147949 
	C756.001892,891.267639 756.206970,891.299866 756.709595,891.423828 
	C757.633179,891.677307 758.269958,891.752502 759.272583,891.754639 
	C763.182068,891.982361 764.866943,894.276428 765.789978,897.371948 
	C767.597168,903.432922 768.989380,909.593689 770.190308,916.168213 
	C770.214966,917.665039 770.178284,918.804626 770.867859,920.167725 
	C771.301331,921.367859 771.446716,922.279053 771.474976,923.558838 
	C771.448242,931.446411 775.699280,936.237610 782.053345,939.130554 
	C787.502991,941.611694 792.658081,940.439819 797.097290,935.673462 
	C798.481445,934.268555 799.790527,933.513611 801.829102,933.725647 
	C802.746887,934.497864 802.941895,935.247437 802.866943,936.456848 
	C801.749695,939.368042 799.632141,940.959534 797.368958,942.935181 
	C788.601990,948.327881 779.455322,947.454163 771.693726,940.534485 
	C771.171265,940.123108 770.971558,939.947815 770.524841,939.461670 
	C764.319031,927.962036 762.468262,915.404663 758.182861,903.281006 
	C757.938721,902.984985 758.001526,902.998535 757.989929,903.028442 
	C757.256287,902.275269 757.741089,901.108154 756.764099,900.143372 
	C753.030701,895.695068 752.019775,889.468811 745.922241,886.963928 
	C746.936707,885.785889 746.543396,885.048645 745.276611,884.172729 
	C738.448853,878.112488 740.413513,864.926208 748.856323,861.259644 
	C749.506409,861.435913 749.746033,861.556152 750.192017,862.011475 
	C748.511841,868.684448 748.306946,875.104126 749.637878,881.905640 
	C749.912476,882.585938 750.010437,882.950562 750.188171,883.587036 
	C751.532959,885.620667 752.862854,887.343750 753.849976,889.620972 
z"/>
<path fill="#DDDBDA" opacity="1.000000" stroke="none" 
	d="
M791.240417,880.045410 
	C790.463257,877.314453 789.652954,874.970276 789.184631,872.559753 
	C788.642822,869.770874 787.105530,869.019409 784.549377,869.719727 
	C784.071045,869.850769 783.581543,869.942261 783.106079,870.082092 
	C771.810669,873.402771 771.807800,873.404175 769.207825,861.726990 
	C769.064819,861.084839 769.040710,860.416199 769.010437,859.310059 
	C770.132874,859.672180 770.087463,858.311279 770.940430,857.895508 
	C773.043396,857.586121 773.710205,860.045471 775.978210,859.627808 
	C778.198547,859.899963 779.282532,861.275757 780.740845,862.741638 
	C781.002441,862.997559 781.501221,862.998779 781.750610,862.999390 
	C787.780945,864.142944 793.498718,865.085022 799.274170,862.643127 
	C802.798462,861.152954 806.174561,858.212036 810.729980,860.737549 
	C812.324829,861.106445 813.329590,861.720825 814.068970,863.290771 
	C812.929077,867.130737 809.034180,867.366028 806.464050,869.815308 
	C805.098328,870.580688 803.980896,871.018738 802.454956,871.384277 
	C799.070007,871.853760 799.251648,874.667908 798.138977,876.571289 
	C796.757263,878.935181 797.006897,882.852112 792.406494,882.573730 
	C791.428833,881.892151 791.317139,881.174133 791.240417,880.045410 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M811.187561,860.740906 
	C808.361206,860.965576 805.921448,861.096924 803.442688,862.548767 
	C796.645935,866.529785 789.483521,867.244873 782.091064,863.359131 
	C784.978699,863.071350 787.102783,862.967529 786.276123,858.621521 
	C785.445496,854.254456 787.665100,853.102722 791.841125,855.262268 
	C793.321533,856.027771 794.283081,855.740234 795.378601,854.705200 
	C796.039001,854.081238 796.973145,853.968323 797.501953,854.873840 
	C798.006042,855.736877 797.593872,856.499390 796.654419,856.799072 
	C795.363159,857.210876 793.947754,857.461182 794.203064,859.315552 
	C794.371582,860.539490 795.293884,860.895203 796.366455,860.937561 
	C797.883240,860.997498 798.817261,860.370667 800.085999,859.281189 
	C803.148987,856.650818 805.971741,854.215759 805.941223,849.396851 
	C808.668518,848.841248 810.879700,849.228088 811.119751,852.618225 
	C811.299011,855.150452 813.298950,856.002686 815.677002,856.856506 
	C814.466980,858.163879 812.928406,859.322815 811.187561,860.740906 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M750.241699,861.929932 
	C748.138367,861.631592 747.250793,862.765320 746.295105,864.012878 
	C742.240723,869.305420 741.632019,877.378479 744.994141,883.579468 
	C740.004578,884.105957 736.514221,880.053772 731.854736,879.016541 
	C730.453735,878.704712 729.515076,876.762329 730.538452,875.289246 
	C735.488647,868.164124 740.681152,861.234192 749.531738,858.528198 
	C750.075745,858.361755 750.804260,858.798828 751.796875,859.020386 
	C752.146912,859.085632 752.064453,859.517090 752.001831,859.727661 
	C751.935120,860.912720 751.358948,861.484009 750.241699,861.929932 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M708.498291,896.956177 
	C708.979431,897.129150 709.103821,897.314392 709.392212,897.791504 
	C709.749390,898.543274 709.840576,899.021179 709.732727,899.876465 
	C707.480896,905.717529 708.599854,909.143127 713.571289,912.018005 
	C714.722229,912.683533 715.793701,913.382874 716.569092,914.932251 
	C716.497437,918.381042 715.694763,921.296143 713.811523,924.238159 
	C711.414490,925.269897 710.489868,927.622009 708.047668,928.325623 
	C705.354065,920.138794 703.007629,912.061401 700.693359,903.584045 
	C704.779358,902.999512 704.247742,897.343872 708.498291,896.956177 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M767.281311,850.243896 
	C766.538330,847.051636 768.214172,845.631042 771.453979,846.325928 
	C773.206543,846.701843 774.824646,847.937134 776.682983,847.497375 
	C777.865173,847.217651 779.597534,847.215027 779.721191,845.482178 
	C779.837097,843.858643 778.578979,843.302917 777.150757,843.035156 
	C776.037659,842.826416 774.845825,842.663635 773.881470,842.131531 
	C772.686890,841.472290 771.956177,840.300049 772.170349,838.810547 
	C772.431091,836.996887 773.869507,837.127930 775.194031,837.001160 
	C781.388367,836.408203 784.166992,838.130493 786.179016,843.710999 
	C786.477112,844.537842 786.945068,845.622375 786.210266,845.934021 
	C782.202209,847.634277 782.831909,852.614380 779.347229,854.930908 
	C775.671570,852.270752 771.310852,852.149780 767.281311,850.243896 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M763.263306,767.721802 
	C763.002441,768.002441 762.999756,768.500000 762.997437,768.748779 
	C759.523132,773.083008 756.419495,777.444397 753.251343,782.175537 
	C748.403870,787.480042 744.418762,793.342407 737.091492,795.639160 
	C736.501160,795.533020 736.275391,795.442810 735.811401,795.076416 
	C734.748230,791.764587 737.497498,790.456543 738.684692,788.398499 
	C739.596985,786.816956 741.152771,785.684448 742.045776,783.635254 
	C747.934998,776.488586 754.476990,770.400330 759.920471,763.332031 
	C761.063232,761.848267 762.656189,760.905518 765.011597,761.743530 
	C765.976257,764.207642 765.134399,765.949829 763.263306,767.721802 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M752.338989,782.251587 
	C754.779541,777.162903 757.210083,772.051575 762.633179,769.009644 
	C762.997375,769.747559 762.999634,770.497620 763.002197,771.622559 
	C764.025452,773.625427 765.438416,775.144165 764.244690,777.660217 
	C763.554688,777.999146 763.111816,778.000732 762.336914,778.004028 
	C760.496216,778.106140 759.432068,778.764832 759.042786,780.664001 
	C757.032410,782.099182 754.995667,782.897034 752.338989,782.251587 
z"/>
<path fill="#E0DEDA" opacity="1.000000" stroke="none" 
	d="
M767.003662,850.332153 
	C771.361511,850.380615 776.377991,848.903137 778.987671,854.625244 
	C779.001648,855.446350 779.000793,855.890198 779.000000,856.667053 
	C778.434448,857.898071 777.678101,858.564331 776.292603,859.015137 
	C774.585388,859.251892 773.386658,858.988159 772.127563,857.760071 
	C770.878235,857.259216 769.935303,856.975769 768.775085,856.286499 
	C768.167542,856.023926 768.013611,855.793274 767.967285,855.281860 
	C767.349670,854.337036 767.266052,853.469360 767.170166,852.277222 
	C766.999268,851.550110 767.000977,851.107239 767.003662,850.332153 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M763.386230,767.999146 
	C762.571899,765.844360 764.404602,764.480896 764.916260,762.283203 
	C763.748901,760.442566 763.844482,758.990540 765.343262,757.317627 
	C768.659058,756.287964 769.258545,752.476562 772.653503,751.866577 
	C772.993286,751.995361 773.000000,752.000000 773.001221,752.001221 
	C772.791992,755.618835 772.699768,759.147095 768.266479,760.640991 
	C766.712341,761.164673 766.364075,763.078186 766.794189,764.892883 
	C767.384033,767.381836 765.939148,768.090149 763.386230,767.999146 
z"/>
<path fill="#DDD9D9" opacity="1.000000" stroke="none" 
	d="
M804.333313,811.000000 
	C804.920288,807.583191 803.840027,803.177490 808.653076,801.687439 
	C809.733704,801.352783 810.954224,800.578125 811.585754,802.077759 
	C812.101562,803.302856 811.044250,803.767212 810.015747,803.994934 
	C806.507141,804.772095 807.532227,808.204712 806.151978,810.658325 
	C805.555542,811.000000 805.111084,811.000000 804.333313,811.000000 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M766.763672,852.236389 
	C767.834534,852.582336 767.986328,853.449829 767.971191,854.727417 
	C764.577881,854.141235 761.759644,856.089050 758.358887,856.964600 
	C758.850098,851.737061 762.684631,852.098389 766.763672,852.236389 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M775.764893,858.342590 
	C776.788391,857.688477 777.576782,857.376953 778.682617,857.032715 
	C779.637085,858.765137 780.274170,860.530334 780.956848,862.646484 
	C779.561951,862.361023 778.121460,861.724426 776.340454,861.043945 
	C775.421326,860.314148 775.268188,859.541809 775.764893,858.342590 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M764.359070,777.997192 
	C763.671814,776.253296 763.346008,774.509033 763.011353,772.381165 
	C764.987915,772.117004 765.526184,773.954529 766.551270,775.127563 
	C768.963379,777.887878 766.669495,777.884644 764.359070,777.997192 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M976.982849,754.647034 
	C975.350708,755.004211 973.723328,755.032104 971.689331,755.067322 
	C971.094971,754.899719 970.907227,754.724792 970.726929,754.163025 
	C970.941284,753.289673 971.247864,752.876892 971.997192,752.347534 
	C973.397888,751.821289 974.407898,751.882874 975.657654,752.750244 
	C976.136902,752.997803 976.312561,752.998047 976.751953,752.999146 
	C977.006348,753.439270 976.997009,753.878479 976.982849,754.647034 
z"/>
<path fill="#DDD9D9" opacity="1.000000" stroke="none" 
	d="
M809.770447,796.181824 
	C810.860107,796.113525 812.282043,795.801758 812.272339,796.898926 
	C812.258362,798.468506 810.658020,797.774963 809.357056,797.998474 
	C809.180298,797.454529 809.360596,796.909119 809.770447,796.181824 
z"/>
<path fill="#D0CFCE" opacity="1.000000" stroke="none" 
	d="
M683.002441,869.002441 
	C683.609375,869.078552 684.213806,869.152222 684.818298,869.225891 
	C684.752930,869.424011 684.648621,869.798767 684.627930,869.794128 
	C684.195374,869.698792 683.772156,869.560608 683.177002,869.217773 
	C683.006836,869.004761 683.000000,869.000000 683.002441,869.002441 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M755.522705,856.529785 
	C755.482727,856.549988 755.562622,856.509644 755.522705,856.529785 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M830.809082,797.250000 
	C832.995850,795.212036 836.890564,794.055298 832.738098,790.447021 
	C831.594971,789.453735 832.916565,787.940063 833.744141,787.027954 
	C834.719177,785.953430 836.007629,784.955261 837.620056,785.540894 
	C838.711426,785.937256 839.535889,786.798767 839.636047,788.095520 
	C839.733704,789.358826 838.025024,791.392944 840.447998,791.663086 
	C842.930969,791.940002 840.106689,788.470825 842.690002,788.058655 
	C845.961792,789.126099 849.720154,796.282959 848.541809,798.553101 
	C846.032043,803.388550 842.088318,804.616699 837.393494,801.900208 
	C835.430542,800.764343 832.854858,800.642212 831.269897,798.210449 
	C830.872742,797.833313 830.745422,797.666687 830.809082,797.250000 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M831.640076,807.519287 
	C830.606140,805.949036 829.453430,804.465332 831.477051,803.512146 
	C833.992310,802.327148 836.318604,803.709351 838.374512,805.036987 
	C840.222839,806.230469 840.099548,807.988953 839.080933,809.826660 
	C838.234619,811.353760 837.260559,811.896484 835.466064,811.335999 
	C833.579895,810.747009 832.131470,809.956665 831.640076,807.519287 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M842.506104,813.422607 
	C844.892395,811.253235 845.740845,813.223206 846.633972,814.675415 
	C847.075623,815.393494 846.872437,816.476562 845.951416,816.630371 
	C843.806824,816.988403 842.731140,815.727966 842.506104,813.422607 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M816.369080,817.990784 
	C816.000000,816.933289 816.000000,815.866638 816.000000,814.399963 
	C817.370605,813.342041 818.938782,812.653137 819.601562,814.656982 
	C820.388184,817.035217 818.775024,817.796631 816.369080,817.990784 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M822.286621,801.124451 
	C822.238220,798.337646 824.071716,797.932434 826.620544,798.001221 
	C827.393738,798.379883 827.540588,798.838806 827.220337,799.688354 
	C825.572205,800.585693 824.537842,802.565979 822.286621,801.124451 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M827.371338,799.998413 
	C827.000000,799.555542 827.000000,799.111084 827.000000,798.333313 
	C828.100464,797.670166 829.200867,797.340332 830.650635,797.005249 
	C831.000000,797.000000 831.000000,797.500000 831.000000,797.750000 
	C830.743958,800.019043 829.235474,799.995117 827.371338,799.998413 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M820.952148,856.715881 
	C820.555542,857.000854 820.111084,857.001709 819.333313,857.002441 
	C819.106384,856.374084 819.171021,855.620789 819.956360,855.579407 
	C820.256470,855.563477 820.587402,856.130371 820.952148,856.715881 
z"/>
<path fill="#1E293E" opacity="1.000000" stroke="none" 
	d="
M863.628784,914.566895 
	C863.411621,914.835266 863.812195,914.870239 863.845947,914.671997 
	C864.050598,914.811096 864.221313,915.148438 864.160034,916.003174 
	C863.927856,916.520691 863.959473,916.408508 863.903564,916.391968 
	C863.795227,916.589294 863.742737,916.803284 863.467773,917.264282 
	C863.079468,917.660583 862.913574,917.809937 862.453125,918.121216 
	C861.016602,918.514099 860.157776,919.326538 858.782776,919.879517 
	C857.493958,920.221069 856.552185,920.146851 855.288269,919.734009 
	C851.766174,916.952209 849.455139,913.249878 845.092896,911.477295 
	C842.278748,909.192749 842.527283,906.295898 842.606628,903.471985 
	C842.657898,901.648315 843.169128,899.850952 842.918213,898.003967 
	C842.004517,891.276672 837.511230,889.190613 832.002991,893.101013 
	C829.031921,895.210327 826.044983,897.359070 823.931274,900.466370 
	C821.108337,904.616394 822.502869,909.474548 827.595947,911.745544 
	C828.826233,911.639221 829.723816,911.632629 830.950806,911.506226 
	C831.955566,911.411011 832.629150,911.376343 833.616943,911.082764 
	C834.187927,910.936462 834.420959,910.886169 835.013000,910.789062 
	C838.510193,911.220276 841.762512,910.403503 845.153687,911.788452 
	C846.901855,913.967590 847.118103,916.221313 846.915161,918.902222 
	C845.328003,921.505188 842.154602,920.606689 840.183594,922.717224 
	C837.346252,923.142273 835.101501,924.228027 832.766541,925.075806 
	C822.964355,928.634583 813.813110,924.124878 810.727112,914.143799 
	C806.862793,901.645813 811.520508,892.773132 823.210876,886.834656 
	C825.401794,885.721619 827.754761,884.840576 829.936768,883.696655 
	C834.443665,881.333984 834.586548,879.578613 830.863037,875.921082 
	C830.028198,875.101074 828.992493,874.550110 827.760071,873.602661 
	C825.612122,870.948181 825.872253,868.746643 828.114014,866.773499 
	C830.861450,864.355408 835.678345,863.921570 838.833801,865.862061 
	C842.121094,867.883545 844.199402,871.286499 847.674194,873.726624 
	C853.176636,884.279907 854.956116,895.649597 858.562439,906.796814 
	C859.802734,907.770203 859.967346,909.037354 860.914673,910.184570 
	C861.546875,912.085876 863.469543,912.637024 863.628784,914.566895 
z"/>
<path fill="#1D2639" opacity="1.000000" stroke="none" 
	d="
M847.947144,873.649658 
	C844.068298,873.880371 841.942017,871.243347 839.833313,868.418884 
	C837.048279,864.688538 833.444336,865.911377 829.985291,867.185181 
	C827.555115,868.080078 826.775635,869.830933 827.960693,872.617432 
	C822.241089,876.105286 815.903870,876.118652 809.715088,875.586609 
	C806.813293,875.337036 805.107117,872.554993 805.710022,868.803955 
	C808.105103,866.231689 811.051880,865.400940 813.955505,863.842041 
	C820.344788,861.971313 826.579468,861.346008 832.863647,862.479736 
	C839.544678,863.685120 844.957397,866.830627 847.947144,873.649658 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M863.697998,914.526184 
	C861.015930,915.274109 860.914795,913.064209 859.868042,911.494751 
	C859.778748,911.146667 859.855652,910.707458 859.918518,910.484985 
	C859.981384,910.262573 860.058960,909.783997 860.101562,909.535278 
	C860.238464,908.947144 860.399414,908.641785 860.835815,908.211304 
	C861.754578,907.673096 862.451660,907.287415 863.447021,906.837769 
	C863.955688,906.823059 864.165955,906.872131 864.697021,906.966736 
	C865.016174,907.454285 865.014404,907.896423 865.009399,908.670166 
	C864.999084,909.803711 865.001221,910.605652 864.967163,911.707764 
	C864.631653,912.744568 864.307983,913.480835 863.697998,914.526184 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M861.024414,910.630554 
	C860.800598,911.005127 860.594421,911.019653 860.083496,911.090393 
	C858.561707,910.304626 859.088928,908.922913 858.771545,907.445679 
	C859.362732,907.318359 859.928833,907.555481 860.776245,907.925720 
	C861.052368,908.796021 861.047241,909.533203 861.024414,910.630554 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M969.731628,747.154419 
	C970.555481,746.738220 971.176819,746.656921 972.110474,746.611572 
	C972.958801,746.392761 973.512329,746.346191 974.422058,746.768799 
	C974.984375,747.037903 975.207886,747.046204 975.765869,747.027588 
	C976.420654,747.425964 976.573608,747.905029 976.434570,748.804504 
	C975.372253,750.095337 974.458435,751.059875 972.580139,750.911743 
	C971.123901,750.559937 970.286011,749.955627 969.576782,748.641479 
	C969.479919,748.008606 969.504578,747.748901 969.731628,747.154419 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M975.660522,749.118286 
	C975.876343,748.335876 975.914673,747.950317 976.026672,747.282654 
	C976.814331,746.984741 977.528320,746.968872 978.599609,746.934448 
	C979.046936,748.481506 978.875061,749.870422 976.504272,750.049316 
	C975.924011,749.891296 975.703552,749.703186 975.660522,749.118286 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M1015.930786,675.224121 
	C1016.743713,674.654358 1017.533691,674.338928 1018.637329,673.969543 
	C1018.891785,675.403809 1018.021301,675.981873 1016.272217,675.984863 
	C1015.909302,675.818970 1015.908569,675.648682 1015.930786,675.224121 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M974.944519,746.768738 
	C974.240479,746.964417 973.720154,746.899353 972.811279,746.740845 
	C972.861145,745.386414 973.914062,745.032104 975.419617,744.810730 
	C975.548340,745.353210 975.338318,745.930603 974.944519,746.768738 
z"/>
<path fill="#0F1523" opacity="1.000000" stroke="none" 
	d="
M883.155151,900.526855 
	C886.558472,908.840881 886.326599,909.380188 878.277832,912.729248 
	C878.125427,912.792664 877.962585,912.831482 877.811340,912.897095 
	C869.717041,916.409363 869.717102,916.409546 867.216919,907.259399 
	C867.253418,903.554016 865.179016,899.140869 870.943115,897.543091 
	C872.566040,894.930115 874.985107,894.487366 877.720459,893.850769 
	C879.201172,893.790344 880.191589,894.043335 881.184204,895.137207 
	C881.578857,898.037964 879.404724,898.123596 877.828613,898.762207 
	C876.268250,899.394531 874.715027,900.080200 874.151672,901.768982 
	C873.505371,903.706482 873.265259,905.986084 875.340515,907.037964 
	C877.480896,908.122925 879.024658,906.693420 879.969727,904.671509 
	C880.705078,903.098389 880.736084,901.038330 883.155151,900.526855 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M878.305542,894.832642 
	C876.012878,895.812439 873.975769,896.541321 871.573242,897.278931 
	C871.035095,894.830994 870.695557,892.404846 870.332092,889.963013 
	C868.702026,879.012817 873.540833,866.694519 883.660278,860.017822 
	C882.963440,862.709900 881.908691,865.406189 880.742798,868.448364 
	C878.763489,871.358704 877.514709,874.284302 876.584351,877.237061 
	C875.052979,882.097168 874.499329,886.960571 878.901123,891.005066 
	C879.936890,891.956665 881.589600,893.681946 878.305542,894.832642 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M975.530640,748.865723 
	C975.890076,748.947205 975.942139,749.172913 976.069336,749.739014 
	C976.096375,750.808350 976.048279,751.537231 975.980713,752.631836 
	C974.960510,753.013733 973.959900,753.029846 972.583191,753.052185 
	C971.774231,752.835083 971.494019,752.484314 971.333252,751.673279 
	C971.400085,751.101135 971.478210,750.880249 971.817505,750.424316 
	C973.126892,749.796082 974.175110,749.403015 975.530640,748.865723 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M998.022461,851.317139 
	C997.795349,849.854553 997.564514,848.714844 997.261108,847.232910 
	C995.318359,845.435791 996.529846,839.953186 991.271301,842.785034 
	C990.468445,843.318237 989.937683,843.637817 989.096313,843.967285 
	C988.756653,839.591919 992.287903,839.109070 995.271606,838.395264 
	C997.916687,837.762512 999.911926,839.227112 1000.502869,841.825806 
	C1002.853516,852.163391 1006.371643,862.237427 1007.670715,872.808105 
	C1008.324280,878.125671 1009.085754,878.617432 1014.235474,876.416687 
	C1014.830505,876.162354 1015.515686,876.119080 1016.537231,875.948853 
	C1013.745972,883.110840 1010.297180,884.702148 1004.835327,882.128235 
	C1002.312073,880.939209 1001.639282,879.205872 1002.092590,877.018372 
	C1003.142883,871.949158 1001.410034,867.277527 1000.179626,862.234497 
	C999.879517,861.563843 999.886780,861.137146 999.835449,860.395020 
	C999.256531,859.351807 998.976990,858.548828 999.014893,857.321167 
	C998.943359,856.272217 998.872681,855.548340 998.701355,854.474121 
	C998.191711,853.346252 997.976990,852.523132 998.022461,851.317139 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M976.143921,884.178833 
	C977.038452,884.261353 978.154724,884.540649 978.664551,885.253601 
	C981.924622,889.813049 985.428406,889.568542 989.574951,885.967346 
	C989.935059,890.807678 986.526001,891.747498 982.693237,891.978455 
	C977.469727,892.293091 976.895142,888.291626 976.143921,884.178833 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M1038.749268,871.201904 
	C1039.067017,871.903259 1039.000732,872.565735 1038.910767,873.558044 
	C1035.420166,874.116089 1031.348633,877.055359 1029.091064,871.348816 
	C1029.808716,870.989563 1030.560303,870.991943 1031.689453,870.989990 
	C1034.103516,873.278198 1036.263672,871.179993 1038.749268,871.201904 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M991.386963,843.019653 
	C991.803833,841.147583 993.476868,839.968872 995.174133,840.626282 
	C997.441772,841.504517 997.073425,844.061340 997.219849,846.500122 
	C994.312744,847.113647 995.387024,841.781921 991.386963,843.019653 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M997.779907,851.177307 
	C998.928284,851.607361 998.982544,852.508118 998.732849,853.795471 
	C997.147644,853.626404 996.718262,852.733704 997.779907,851.177307 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M998.768433,857.186890 
	C999.952759,857.552490 1000.029663,858.458557 999.885254,859.744995 
	C998.217407,859.708557 997.754578,858.828735 998.768433,857.186890 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M878.384094,895.113159 
	C879.123535,894.001892 879.465088,892.984680 877.915771,891.982361 
	C872.043884,888.183472 873.579224,882.637939 874.804077,877.417664 
	C875.569214,874.156555 876.905701,870.930664 880.290283,868.845459 
	C877.446472,882.020203 883.512756,891.996643 892.826599,900.002136 
	C900.199829,906.339661 909.293213,906.295593 918.710632,903.124146 
	C918.361206,907.958130 914.407227,908.233032 910.420593,909.043030 
	C899.765869,911.575989 891.772949,909.718872 886.300842,899.270630 
	C886.035583,898.958557 885.641235,898.840332 885.438477,898.805054 
	C884.509949,898.531189 883.973206,897.865967 882.864624,897.764771 
	C882.018738,897.419800 881.580322,897.034729 881.258423,896.160034 
	C880.508972,895.110474 879.618469,895.109985 878.384094,895.113159 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M922.968323,902.605957 
	C922.406250,902.939819 921.848022,902.915527 920.876587,902.798523 
	C920.760376,901.819580 921.184448,901.062012 922.572632,900.882202 
	C922.924988,901.293457 922.948608,901.770691 922.968323,902.605957 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M910.370483,862.992310 
	C905.291138,862.427856 901.322388,865.843628 896.264648,865.913879 
	C899.684265,860.396118 905.220459,858.943115 911.930420,859.376831 
	C911.964966,860.711304 911.718750,861.980652 910.370483,862.992310 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M913.316162,871.865723 
	C914.520996,873.992065 913.268921,875.998047 913.094299,878.495728 
	C913.601379,879.654480 913.815308,880.473450 913.225952,881.726135 
	C910.162354,879.169128 910.157227,874.856445 913.316162,871.865723 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M912.959900,882.391846 
	C912.977783,881.275696 913.002014,880.479980 913.054260,879.285400 
	C915.709534,879.741272 915.982727,882.262329 916.952393,884.666260 
	C916.982483,885.020935 916.508301,885.077820 916.269531,885.080383 
	C915.157166,884.991821 914.254639,885.018677 913.259766,884.227173 
	C913.004211,883.567566 912.985229,883.139954 912.959900,882.391846 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M913.070557,884.307190 
	C913.882141,884.184082 914.741089,884.373108 915.815369,884.822510 
	C914.877136,886.075745 913.846436,886.296875 913.070557,884.307190 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M945.744263,904.678040 
	C945.435303,905.783142 944.585938,905.938782 943.322998,905.885620 
	C943.277588,904.365417 944.020935,903.710266 945.744263,904.678040 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M840.763062,708.806152 
	C843.036926,712.488403 847.092163,713.712280 850.081848,715.108459 
	C864.714111,721.941772 879.432800,728.679993 893.248779,736.961975 
	C900.571716,741.351746 908.119629,745.298462 915.691772,749.054565 
	C919.969788,751.176819 924.717529,752.440613 928.806030,755.742310 
	C928.764526,759.222412 926.371338,759.040833 923.733154,759.001221 
	C905.178955,749.729553 887.370178,739.863342 868.888123,731.286865 
	C853.125793,723.972595 838.298950,714.826050 822.983032,706.635315 
	C814.891541,702.308044 806.543030,698.233582 798.976501,692.912292 
	C797.001404,691.523254 794.408752,690.556641 794.671875,687.098694 
	C796.515137,685.489014 798.269897,686.051331 800.375854,686.640076 
	C803.927185,688.359741 806.733032,690.713074 810.591431,691.796631 
	C811.590881,692.156555 812.215149,692.484863 813.148376,692.970093 
	C816.235474,694.544983 818.538147,696.850769 822.046631,697.570129 
	C824.097778,697.719238 825.482056,698.549561 827.030273,699.811890 
	C828.456604,701.440247 830.076843,702.073486 832.057556,702.737366 
	C832.576782,702.937683 832.778809,703.033630 833.267273,703.304932 
	C835.671875,705.461792 838.519409,706.441223 840.763062,708.806152 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M868.625061,715.009216 
	C871.589172,715.938232 872.785461,718.142578 873.983765,720.733032 
	C863.050537,716.143494 852.329895,710.700562 841.395935,704.950989 
	C840.475708,705.014221 839.911743,705.050964 839.073242,704.854370 
	C838.439819,704.663147 838.085388,704.532837 837.472290,704.286255 
	C836.690125,703.953369 836.207458,703.660889 835.510986,703.180786 
	C834.835876,702.687134 834.408447,702.327393 833.645020,701.960754 
	C832.840088,701.600220 832.277161,701.420410 831.375366,701.253235 
	C830.638611,701.048401 830.228210,700.882019 829.527039,700.515381 
	C817.180481,693.016724 804.468445,687.008606 791.276794,681.165344 
	C789.483643,680.314758 788.000977,679.638306 786.183594,678.860046 
	C783.112732,676.255554 779.683655,675.027527 776.114746,673.349609 
	C775.446350,672.930542 775.078003,672.671997 774.443604,672.207214 
	C773.152039,671.177429 771.709717,670.727112 771.638550,668.795044 
	C773.782288,665.746826 775.510803,667.948242 777.279602,668.867493 
	C779.272400,669.903259 780.512268,672.364807 783.621826,672.005554 
	C798.869080,680.526489 814.787231,686.966370 829.837708,695.124023 
	C838.933350,700.054077 847.946411,705.138184 857.085815,709.984863 
	C860.682190,711.892029 864.521484,713.341187 868.625061,715.009216 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M922.749939,757.703735 
	C925.043701,758.060425 926.934082,757.832703 928.787048,756.277100 
	C930.409302,755.991699 931.804871,755.978027 933.617737,756.002747 
	C934.832642,756.841614 934.849182,757.769775 934.287964,759.068909 
	C933.493652,760.116699 932.596436,760.509033 931.683960,761.411743 
	C930.483582,762.269531 929.444946,762.658752 928.021545,762.946533 
	C926.619019,763.082764 925.621399,763.449829 924.329102,764.026001 
	C922.584961,764.522461 921.167175,764.471069 919.403809,763.983643 
	C917.446777,762.617310 915.471924,762.432251 913.241333,761.841797 
	C900.610413,752.440918 885.969238,747.309509 873.102051,739.045349 
	C871.122314,737.773743 868.535217,737.201538 866.372253,736.043762 
	C864.551819,735.069397 862.341675,734.184753 862.671021,731.101318 
	C866.702637,728.571838 869.583435,731.662170 872.456665,733.009094 
	C887.215454,739.927612 901.914551,747.002563 915.808350,755.598755 
	C917.834595,756.852356 920.246155,756.789978 922.749939,757.703735 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M926.718811,761.987427 
	C928.077942,761.622803 929.162781,761.322693 930.584961,760.976196 
	C931.525391,760.468018 932.058594,759.929443 932.796631,759.195190 
	C933.780090,758.426392 933.997375,757.604187 934.034180,756.370300 
	C935.452087,755.738342 936.869019,755.435547 938.653442,755.159302 
	C940.739807,755.649292 942.787842,755.551086 943.443604,758.195618 
	C936.825500,762.808899 930.002258,766.587830 922.056702,767.347473 
	C919.627319,767.579651 916.874634,769.183105 914.364502,766.394897 
	C913.889526,765.131409 913.951660,764.268188 914.561279,763.052734 
	C915.929321,761.944458 917.089111,761.987854 918.661011,762.689697 
	C920.738647,762.925415 922.432617,762.920105 924.549438,762.891724 
	C925.499268,762.661804 925.931763,762.284485 926.718811,761.987427 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M943.264404,758.213867 
	C942.138306,756.511047 939.867615,757.558105 939.090637,755.500244 
	C939.483704,754.837280 939.946472,754.488647 940.712646,754.116516 
	C947.610474,753.177551 953.708191,750.241760 960.658264,749.517212 
	C962.363708,751.831604 961.020081,753.038269 959.378235,753.976868 
	C958.278015,754.605774 956.834473,754.627808 955.785461,756.115112 
	C955.055298,756.602234 954.591553,756.778503 953.738159,756.906494 
	C950.540710,756.096130 947.956299,756.651611 945.247742,758.472534 
	C944.441711,758.661560 943.976990,758.614929 943.264404,758.213867 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M961.366943,750.306396 
	C954.433899,751.129578 948.326172,754.334290 941.275024,754.328613 
	C948.995361,750.331238 957.583984,748.696594 966.536865,746.898804 
	C967.457397,747.686035 967.197388,748.413696 966.504150,749.430237 
	C964.853577,750.742004 963.385559,751.260742 961.366943,750.306396 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M835.475098,701.792969 
	C836.363098,702.300598 836.931396,702.587219 837.766907,703.047241 
	C838.217346,703.335022 838.400574,703.449402 838.844482,703.756409 
	C839.637451,704.122742 840.169739,704.296509 840.870911,704.700256 
	C841.026917,706.027954 841.014038,707.125671 840.995728,708.636597 
	C837.742432,708.727051 835.308472,706.926025 833.021118,704.372559 
	C832.792786,703.895569 832.647888,703.753601 832.271667,703.809448 
	C830.268982,703.496216 828.243835,703.522888 827.019775,701.337952 
	C827.312988,700.605042 827.770996,700.370239 828.703186,700.323547 
	C831.009155,701.285828 833.175171,700.967834 835.475098,701.792969 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M966.229248,749.248352 
	C966.246460,748.475403 966.509583,747.963135 966.860229,747.174561 
	C967.695251,746.862244 968.442749,746.826111 969.562134,746.804688 
	C969.951538,747.041992 969.968994,747.264526 969.998474,747.821533 
	C969.583862,749.086914 968.905212,749.690552 967.499512,749.794312 
	C966.919495,749.685242 966.697388,749.597168 966.229248,749.248352 
z"/>
<path fill="#1A98B0" opacity="1.000000" stroke="none" 
	d="
M883.178833,724.222290 
	C883.881287,723.040283 884.513306,723.256409 884.989746,724.689148 
	C884.481445,724.795044 883.922363,724.625793 883.178833,724.222290 
z"/>
<path fill="#097C99" opacity="1.000000" stroke="none" 
	d="
M908.591980,660.868225 
	C908.947266,661.084045 908.961914,661.275085 908.990723,661.753418 
	C907.803101,663.317139 905.911255,663.507812 904.839844,662.837158 
	C900.781494,660.296814 897.070740,661.571472 893.053406,662.888672 
	C886.981873,664.879395 880.926147,664.133240 875.100159,661.586304 
	C873.340576,660.817017 871.496216,660.916443 869.688293,661.025574 
	C858.543091,661.698181 848.885315,657.554504 839.738586,651.811829 
	C838.353210,650.942017 836.896118,649.834656 836.082397,648.472656 
	C833.667847,644.431030 830.082153,642.702820 825.613464,641.993652 
	C817.078674,640.639282 809.531921,636.320618 801.436523,633.627625 
	C797.748413,632.400757 795.439758,630.256775 794.788940,625.882568 
	C794.107727,621.304382 792.175842,616.912598 786.414917,616.212463 
	C785.325256,616.080017 784.109558,615.519287 783.301025,614.776367 
	C778.004578,609.909546 771.931458,607.263428 764.650269,606.771179 
	C760.901794,606.517761 757.541138,595.621399 759.723877,591.056030 
	C761.959167,586.380737 761.757874,582.370544 757.549500,579.097412 
	C754.344421,576.604614 754.997742,573.202332 755.017639,569.937805 
	C755.046204,565.250427 754.950012,560.896362 748.894958,559.626953 
	C746.402100,559.104309 746.815002,556.420532 747.142578,554.509827 
	C747.969360,549.688782 747.039246,546.584839 741.367737,545.738586 
	C737.319580,545.134521 733.764954,536.531128 735.591248,532.873779 
	C737.340698,529.370239 737.664490,525.667053 738.137024,521.831726 
	C739.192688,513.263977 739.279846,504.496613 738.253235,496.226074 
	C737.397034,489.327759 736.945557,482.710419 737.536621,475.875854 
	C737.599243,475.151215 736.588379,473.823425 735.844727,473.620239 
	C729.921509,472.001862 726.417786,466.433319 720.724304,464.524078 
	C719.798950,464.213745 718.786560,464.163025 717.420898,463.990051 
	C716.427246,463.978821 715.881042,463.811981 715.194214,463.233795 
	C714.547974,463.020905 714.114807,463.007812 713.356567,462.991547 
	C709.995178,463.291809 707.019897,463.442108 705.551697,459.590820 
	C707.236389,457.249084 709.254700,458.030640 711.150818,458.486145 
	C718.614258,460.279327 725.845764,462.632812 732.465332,466.698029 
	C740.404907,471.573914 748.132080,476.747681 755.975220,482.407867 
	C756.834717,482.627777 757.317566,482.781708 758.120239,483.166931 
	C775.713013,501.260315 786.186890,523.005615 793.916687,546.272339 
	C800.635620,566.496460 808.204773,586.431885 814.446472,606.815308 
	C815.146545,609.101685 816.576538,610.439026 818.530151,611.549316 
	C844.441406,626.275818 870.883911,640.004456 897.404602,653.586426 
	C899.035583,654.421692 900.709839,655.169800 902.757874,656.023804 
	C903.511230,656.544189 903.836426,656.945801 904.145996,657.806335 
	C904.633057,660.491516 906.529480,660.534790 908.591980,660.868225 
z"/>
<path fill="#048CA9" opacity="1.000000" stroke="none" 
	d="
M705.825256,458.466919 
	C706.938171,462.316711 710.623596,460.795959 712.775757,462.733612 
	C710.417114,465.763336 709.117371,468.215118 713.729370,470.544586 
	C716.603149,471.996094 716.833008,475.952789 719.543762,477.837738 
	C720.181274,478.281067 719.818665,479.412781 719.171631,480.055542 
	C718.185181,481.035431 717.149841,481.063293 716.110352,480.101562 
	C715.356628,479.404175 714.350830,478.598053 713.662109,479.792114 
	C713.021179,480.903381 714.201294,481.553741 715.120850,481.896484 
	C716.387756,482.368591 717.030884,483.199463 716.962646,484.516418 
	C716.454651,494.326416 719.660767,503.774689 719.832520,513.473083 
	C719.908508,517.765137 717.445984,521.483032 713.272034,524.002930 
	C711.412170,525.190002 709.896912,524.543640 708.773499,523.650757 
	C702.060364,518.314941 694.920044,513.553528 688.073120,507.855103 
	C687.592163,503.983734 687.470032,500.595123 690.041748,497.659058 
	C691.701111,495.764587 691.147278,493.267487 691.246155,490.990997 
	C691.712341,480.258057 693.824402,469.869171 697.859253,459.453125 
	C698.335632,458.571045 698.704773,458.132721 699.503296,457.500641 
	C701.942139,456.420044 703.901978,456.393799 705.825256,458.466919 
z"/>
<path fill="#187589" opacity="1.000000" stroke="none" 
	d="
M688.684814,507.790222 
	C697.766663,511.849640 703.829102,519.612427 712.574097,524.002747 
	C713.893188,528.564636 717.195068,531.932495 719.647400,535.391907 
	C724.754089,542.595825 727.730591,550.604553 730.548157,558.661011 
	C733.055115,565.829224 735.496582,573.153564 738.352783,580.064941 
	C744.094360,593.958435 746.836182,608.795898 752.945801,622.496277 
	C753.667664,624.115051 754.524048,625.387756 756.607727,625.995605 
	C756.955933,626.796387 756.951233,627.532104 756.941223,628.636597 
	C756.396729,629.564331 755.925598,630.201843 754.798828,630.598633 
	C751.700745,630.319458 751.357117,628.017578 750.569580,626.046753 
	C746.345337,615.474792 742.824402,604.643250 738.511169,593.703735 
	C738.051636,592.926514 737.863586,592.448608 737.665405,591.574219 
	C735.168762,581.570801 731.227722,572.464417 727.843506,563.168457 
	C725.812927,557.590881 723.952576,551.932007 720.981323,546.357910 
	C720.609802,545.890686 720.468079,545.699768 720.144043,545.205811 
	C719.613098,544.041443 719.178345,543.231262 718.362061,542.239014 
	C717.950012,541.522095 717.772949,541.082764 717.561035,540.275146 
	C711.442627,527.500366 700.484436,520.263733 689.238953,513.431885 
	C682.371948,509.260101 674.787292,506.774384 666.932312,505.079865 
	C664.927246,504.647308 662.481506,504.717529 661.615967,501.764771 
	C662.691162,499.667511 664.400269,499.472992 666.052673,499.736938 
	C673.949402,500.998169 681.859070,502.285095 688.684814,507.790222 
z"/>
<path fill="#048CA9" opacity="1.000000" stroke="none" 
	d="
M715.027649,463.311584 
	C715.536682,463.200165 716.092224,463.366333 716.837585,463.760193 
	C716.155579,464.936493 715.507019,464.791290 715.027649,463.311584 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M771.441162,668.393311 
	C772.343689,669.759033 773.734436,669.943848 775.051025,670.792786 
	C775.366333,671.127319 775.406494,671.291504 775.694946,671.405029 
	C779.418701,673.206421 782.894104,675.058472 786.568604,677.209351 
	C784.911865,680.365906 782.434814,679.512756 780.329651,678.201172 
	C776.373718,675.736511 772.120789,673.878967 767.892151,672.061279 
	C762.343323,669.676147 759.735596,665.617188 759.333130,659.842590 
	C759.161438,657.379883 758.452087,654.944092 759.065430,652.072754 
	C762.141357,649.391541 762.298096,652.329346 763.142517,653.831726 
	C765.309204,657.686646 765.394653,662.498108 768.761963,666.210938 
	C769.173462,667.742126 770.205078,667.971924 771.441162,668.393311 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M768.904907,666.145325 
	C766.403076,666.279358 765.213867,664.825500 764.838501,662.424133 
	C764.204956,658.370361 762.230896,654.776245 760.498901,650.798523 
	C759.757019,650.398621 759.359680,650.081543 758.911743,649.272095 
	C758.399719,646.429077 756.680481,644.168884 757.737305,641.181274 
	C760.642029,639.767456 760.241211,642.210327 760.895691,643.410889 
	C763.566406,648.310120 764.160217,653.904663 766.093323,659.060120 
	C766.946350,661.335144 769.228333,662.989136 768.904907,666.145325 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M754.283813,630.857788 
	C755.061035,630.094116 755.633728,629.631226 756.571167,629.086853 
	C756.541992,632.074524 759.945374,634.714600 757.615295,638.174072 
	C756.820618,638.542603 756.359436,638.532532 755.608337,638.168030 
	C754.640320,637.239380 754.253723,636.363647 753.858032,635.091797 
	C753.582397,633.521240 753.598450,632.319702 754.283813,630.857788 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M755.632690,637.957031 
	C756.366699,637.953979 756.642273,638.031677 757.087036,638.292358 
	C757.376770,639.138916 757.471191,639.894714 757.770508,640.765503 
	C758.616089,643.339783 759.256714,645.799072 759.861755,648.636475 
	C754.929016,648.306274 752.968506,643.615295 755.632690,637.957031 
z"/>
<path fill="#12D1EB" opacity="1.000000" stroke="none" 
	d="
M889.023743,423.348633 
	C888.289490,423.248474 887.875732,423.185150 887.151184,423.077271 
	C886.739441,422.863037 886.638489,422.693298 886.247192,422.465271 
	C885.956848,422.406982 886.002808,422.241302 885.955811,422.169281 
	C884.682861,420.178650 881.951050,420.193146 880.955627,417.530182 
	C884.109192,416.770569 886.205505,418.718384 888.719543,419.953918 
	C900.079529,425.536804 910.822754,432.234314 922.020020,438.111481 
	C929.501526,442.038330 937.579041,445.278381 943.740051,451.856384 
	C942.604370,452.956421 941.642029,452.493652 940.434692,452.137878 
	C940.194885,451.854919 940.125977,451.892853 940.117676,451.854431 
	C935.136475,449.178925 930.363403,446.187439 925.093384,443.356995 
	C924.783447,443.186188 924.401367,443.057251 924.241638,442.934174 
	C922.603027,441.986511 921.432678,440.589783 919.389282,440.166565 
	C918.319153,439.529968 917.471008,439.126953 916.337402,438.517212 
	C915.854126,438.364777 915.656311,438.419098 915.314514,438.201477 
	C914.969849,437.859619 914.769165,437.789734 914.305359,437.539795 
	C913.845337,437.392670 913.648438,437.425629 913.337708,437.181641 
	C913.223816,436.904724 913.115784,436.916534 913.099731,436.864624 
	C909.775574,434.656433 906.167725,433.079254 902.376282,431.142761 
	C902.137329,430.890442 902.067749,430.960999 902.079590,430.912872 
	C900.716125,429.792938 899.165894,429.044373 897.321899,428.183350 
	C896.929810,427.897491 896.770264,427.837128 896.395020,427.639221 
	C895.325623,427.146484 894.458862,426.821411 893.411438,426.128143 
	C893.184509,425.865784 893.109436,425.901489 893.112305,425.860016 
	C891.891663,424.955658 890.831726,423.839294 889.023743,423.348633 
z"/>
<path fill="#12D1EB" opacity="1.000000" stroke="none" 
	d="
M878.075928,417.447357 
	C877.693665,417.286713 877.308289,417.245575 877.127136,417.176758 
	C873.079102,413.398712 867.684082,412.082367 863.316162,408.564697 
	C862.510437,408.498962 862.048340,408.466187 861.363525,408.138245 
	C861.002136,407.775452 860.865540,407.679291 860.517456,407.448669 
	C860.057556,407.388550 859.809021,407.462830 859.482056,407.156036 
	C859.403564,406.774933 859.303345,406.696899 859.247070,406.667389 
	C858.770386,406.412140 858.534546,406.068146 858.600708,405.259094 
	C859.357544,403.875061 860.713745,404.149139 861.818787,403.234833 
	C864.577332,402.179688 864.726868,398.850647 867.615417,397.995361 
	C869.234619,404.329865 870.182800,410.940857 877.918213,413.154205 
	C879.431152,413.587097 880.428955,414.715668 881.004272,416.578369 
	C881.081787,416.919830 880.940918,417.090637 880.872498,417.177673 
	C880.083679,417.799866 879.296021,417.875641 878.075928,417.447357 
z"/>
<path fill="#12D1EB" opacity="1.000000" stroke="none" 
	d="
M958.359070,462.184937 
	C958.031982,461.800446 957.825439,461.715118 957.341675,461.440979 
	C956.859680,461.321442 956.654846,461.390717 956.335388,461.156464 
	C956.220825,460.852966 956.147888,460.883392 956.138245,460.845093 
	C954.076782,459.421631 951.846069,458.378754 949.374023,457.128845 
	C949.160339,456.868988 949.088379,456.935333 949.100037,456.887817 
	C947.968079,455.896790 946.770569,455.039795 945.085815,454.367310 
	C944.801941,454.225555 945.000000,454.001221 944.901855,454.114319 
	C944.622681,453.650116 944.023315,453.393250 943.773926,452.584106 
	C943.780884,452.294556 943.930298,452.104218 944.007568,452.009521 
	C949.211975,450.508789 951.374878,455.655121 955.297363,457.001312 
	C963.467957,459.805420 970.513306,464.538757 976.865784,470.712891 
	C973.888916,470.916016 971.859070,468.382751 968.660278,467.870087 
	C968.307373,467.877686 968.182800,467.872223 968.146484,467.821533 
	C965.982483,466.131744 963.674316,464.777985 961.023621,463.344818 
	C960.344543,463.056458 959.970581,462.906677 959.356934,462.572021 
	C958.904663,462.419373 958.692139,462.451691 958.359070,462.184937 
z"/>
<path fill="#1A2537" opacity="1.000000" stroke="none" 
	d="
M728.203979,399.947815 
	C729.075928,399.885162 729.885071,399.995026 730.019897,399.743439 
	C733.458191,393.329010 739.956726,389.374939 743.434753,383.032532 
	C744.231140,381.580414 744.491150,379.732605 746.460205,379.439178 
	C748.735046,379.100220 749.065918,381.182190 749.755432,382.562408 
	C751.052856,385.159607 752.749451,385.173401 755.072449,383.970825 
	C759.424072,381.718018 769.866150,383.992096 772.920715,388.011719 
	C774.657288,390.296997 776.845581,391.211609 779.425598,392.168640 
	C785.036499,394.250031 790.401550,396.353638 793.870117,402.323669 
	C795.966492,405.931824 791.446960,406.308685 792.787415,408.799500 
	C793.374817,409.339081 793.666260,409.718781 793.995850,410.497040 
	C794.144775,411.331604 794.139771,411.815247 793.990479,412.651123 
	C793.680542,413.691437 793.329834,414.315338 792.713440,415.189758 
	C791.151367,416.672577 789.911804,417.960297 789.190308,420.037476 
	C788.469910,421.776672 787.922363,423.223083 787.422241,425.040375 
	C787.259094,425.814178 787.109924,426.240265 786.745850,426.946625 
	C785.084534,428.620514 783.693237,430.064362 783.212524,432.517120 
	C781.773254,435.756805 780.753174,438.862457 777.636230,440.993835 
	C772.413391,441.829773 772.213440,441.739380 773.381165,437.160553 
	C773.930847,435.004761 773.538330,433.251862 772.517822,431.469788 
	C769.646179,426.454865 768.367493,421.334320 771.575562,415.965179 
	C772.384277,414.611633 771.816101,413.791077 770.693359,413.109619 
	C768.988586,412.074921 767.292114,411.012665 765.470215,410.198853 
	C756.621582,406.246338 741.736267,412.215149 738.572327,421.292389 
	C736.905579,426.074097 733.251404,428.315033 729.219055,430.343384 
	C727.689514,431.112762 725.880188,431.382751 724.696350,429.730042 
	C723.437744,427.973022 724.025330,426.263916 725.437744,424.796570 
	C725.900818,424.315460 726.455139,423.937469 727.019470,423.588135 
	C729.012024,422.354645 730.128418,420.332550 728.418762,418.680328 
	C726.660706,416.981232 724.123535,418.002930 722.712219,419.755005 
	C720.849670,422.067200 719.579895,424.852020 718.285278,427.523895 
	C713.703918,436.979126 707.665466,445.504364 701.440796,454.317688 
	C700.639160,454.676453 700.144897,454.715546 699.291504,454.485657 
	C696.767944,451.353668 698.763306,448.844025 700.204102,446.622406 
	C707.859741,434.817627 713.165649,421.680939 721.069763,410.032593 
	C723.341492,406.684784 723.631897,402.004974 728.203979,399.947815 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M728.670410,399.573547 
	C722.105530,410.706390 715.495483,421.404114 709.942200,432.676910 
	C707.437317,437.761780 704.119507,442.314117 701.443909,447.262390 
	C700.428406,449.140411 699.707153,451.177551 698.813110,453.512573 
	C698.523560,454.699677 697.967285,455.262817 696.892517,455.866608 
	C694.884521,456.445129 693.334961,456.375244 691.774048,454.883911 
	C691.247253,453.524109 691.414001,452.459167 692.119934,451.757141 
	C700.396729,443.526093 703.774719,432.366089 709.663391,422.716187 
	C714.294739,415.126587 718.305603,407.233582 722.625122,399.087158 
	C723.823425,396.971252 725.423950,395.516541 726.186401,393.141418 
	C728.698059,389.492432 730.640747,385.944763 731.916748,381.675110 
	C732.148254,381.144043 732.258606,380.939453 732.579834,380.458099 
	C736.523804,375.309998 738.096191,369.414398 740.411133,363.883453 
	C741.459412,361.378998 739.659424,359.575775 738.056091,357.564423 
	C742.122192,355.148132 746.562134,354.763428 751.342957,354.140991 
	C751.673462,355.108978 751.617676,356.121979 751.465088,357.508026 
	C745.556030,368.276703 739.740173,378.670380 733.933289,389.069092 
	C732.083130,392.382477 729.737732,395.444336 728.670410,399.573547 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M793.257812,411.250122 
	C793.001831,410.553467 793.003662,410.114105 793.008057,409.345581 
	C793.805603,408.689301 794.600708,408.362244 795.717896,407.988647 
	C802.866821,413.590485 811.094971,416.904602 818.786316,421.734039 
	C819.543091,422.567444 819.816711,423.238373 819.669556,424.391876 
	C819.417908,424.953857 819.268738,425.154175 818.795654,425.540588 
	C817.291443,426.106323 816.117371,425.963715 815.151428,425.270050 
	C808.196838,420.275330 800.275452,416.803619 793.257812,411.250122 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M960.826050,463.517883 
	C963.693359,463.471313 966.094727,464.798584 968.035645,467.443817 
	C967.673035,467.744751 967.235901,467.718658 966.473022,467.626343 
	C964.409363,466.316620 962.671448,465.073090 960.826050,463.517883 
z"/>
<path fill="#060F14" opacity="1.000000" stroke="none" 
	d="
M751.520203,360.532715 
	C751.478882,360.554810 751.561523,360.510620 751.520203,360.532715 
z"/>
<path fill="#05657D" opacity="1.000000" stroke="none" 
	d="
M848.864014,401.488892 
	C844.012207,400.142517 840.353943,397.122223 836.286316,394.923828 
	C824.782837,388.706726 813.426697,382.216949 801.916077,375.509155 
	C803.395447,374.812317 804.710571,375.597229 806.426331,376.259308 
	C809.425354,378.753815 812.729614,379.906158 816.295715,381.241028 
	C819.126831,382.032959 821.883118,382.259766 823.643555,385.032898 
	C824.483337,385.691956 825.209961,386.011017 826.241943,386.297546 
	C827.510193,386.368866 827.996216,387.127625 828.673462,388.060150 
	C834.196411,391.222290 839.628784,394.072205 845.355591,397.089691 
	C847.058472,397.413696 848.015747,398.168884 848.734375,399.735077 
	C848.898315,400.011902 848.901062,400.073578 848.930298,400.083374 
	C849.090698,400.233856 849.221802,400.374512 849.388306,400.805542 
	C849.326965,401.269409 849.230164,401.442871 848.864014,401.488892 
z"/>
<path fill="#05657D" opacity="1.000000" stroke="none" 
	d="
M859.021606,404.590454 
	C859.010010,405.310974 859.107666,405.684814 859.198120,406.348267 
	C855.339233,406.035522 852.233826,403.813873 848.783813,401.645447 
	C848.594727,401.361420 848.822144,401.155212 848.934875,401.051086 
	C849.200867,400.827148 849.354187,400.707336 849.749268,400.750793 
	C850.653259,401.034180 851.315308,401.154297 852.314209,401.256958 
	C854.775879,402.316711 857.205078,402.737335 859.021606,404.590454 
z"/>
<path fill="#06A6C2" opacity="1.000000" stroke="none" 
	d="
M823.791748,384.974792 
	C821.363586,384.492645 818.998047,383.560974 816.867310,381.517212 
	C819.735779,380.870361 822.841492,380.446655 823.791748,384.974792 
z"/>
<path fill="#06A6C2" opacity="1.000000" stroke="none" 
	d="
M859.274109,404.973541 
	C856.982849,403.854828 854.622986,403.576538 852.856812,401.522278 
	C855.517944,401.828156 858.384766,402.416840 861.658813,403.011536 
	C861.870300,404.431030 861.051575,405.084534 859.274109,404.973541 
z"/>
<path fill="#06A6C2" opacity="1.000000" stroke="none" 
	d="
M848.914490,399.630463 
	C847.927490,399.269714 846.956665,398.527557 845.817871,397.521301 
	C847.325500,396.962555 848.832275,396.945953 848.914490,399.630463 
z"/>
<path fill="#06A6C2" opacity="1.000000" stroke="none" 
	d="
M828.782593,387.992310 
	C827.908569,388.127319 827.139526,387.767548 826.660278,386.568939 
	C827.653198,386.120728 828.574829,386.284912 828.782593,387.992310 
z"/>
<path fill="#06A6C2" opacity="1.000000" stroke="none" 
	d="
M849.865723,400.724548 
	C849.991150,400.914062 849.520447,400.966003 849.284058,400.956482 
	C849.047607,400.946960 848.944763,400.525909 848.952148,400.309540 
	C849.269409,400.152924 849.529663,400.300201 849.865723,400.724548 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M924.905396,443.497559 
	C930.637939,444.324188 935.041077,448.227234 940.033691,451.483643 
	C934.461121,450.316467 929.849487,446.864471 924.905396,443.497559 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M862.655640,408.376282 
	C867.724548,410.470825 872.997131,412.196167 876.848572,416.780151 
	C872.101257,415.192108 867.871460,412.176178 863.152222,409.355743 
	C862.745300,408.990387 862.609802,408.819489 862.655640,408.376282 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M902.280518,431.192871 
	C906.212769,431.671783 909.725220,433.609100 913.012939,436.482910 
	C909.088867,435.925323 905.828369,433.565948 902.280518,431.192871 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M877.845642,417.585236 
	C878.472046,417.237640 879.250488,417.188568 880.416504,417.202087 
	C881.911560,419.296661 884.895020,419.118896 885.825684,421.777161 
	C883.271790,420.692749 880.634705,419.288239 877.845642,417.585236 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M949.300720,457.171539 
	C951.830078,457.128784 954.082397,458.255890 956.048279,460.475952 
	C953.542786,460.411591 951.437683,459.051697 949.300720,457.171539 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M944.920898,454.530518 
	C946.672668,453.830963 948.041199,454.527527 949.047668,456.495178 
	C947.426331,456.838287 946.240784,455.821228 944.920898,454.530518 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M897.227295,428.257202 
	C899.087891,427.882690 900.743530,428.555023 902.024292,430.527832 
	C900.208801,430.724304 898.773804,429.667603 897.227295,428.257202 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M919.309753,440.225006 
	C921.115662,439.906982 922.728271,440.603729 924.013184,442.482117 
	C922.104858,442.921967 920.983215,441.306488 919.309753,440.225006 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M888.879883,423.589844 
	C890.602966,422.793671 892.005493,423.455597 893.051086,425.467651 
	C891.448059,425.822174 890.348816,424.644257 888.879883,423.589844 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M943.424866,452.236237 
	C944.108826,452.750458 944.436707,453.206360 944.784180,453.944824 
	C943.136475,454.203918 941.772766,453.453369 940.357056,452.146820 
	C941.152893,451.962585 942.110840,452.070251 943.424866,452.236237 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M893.299072,426.160217 
	C894.295044,425.734741 895.240906,426.038910 896.074097,427.210327 
	C894.975159,427.898071 894.192993,427.180237 893.299072,426.160217 
z"/>
<path fill="#05657D" opacity="1.000000" stroke="none" 
	d="
M862.563171,408.002258 
	C862.853455,408.279694 862.869934,408.455383 862.883667,408.896179 
	C862.356262,408.921448 861.831665,408.681610 861.222900,408.156677 
	C861.522278,407.881226 861.905823,407.890900 862.563171,408.002258 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M915.233887,438.129761 
	C915.416748,437.803528 915.648804,437.825745 915.959229,438.153320 
	C916.051880,438.310486 915.679199,438.501709 915.679199,438.501709 
	C915.679199,438.501709 915.297180,438.330017 915.233887,438.129761 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M913.262817,437.109558 
	C913.474792,436.810394 913.698486,436.854401 913.968628,437.198242 
	C914.042236,437.359741 913.660583,437.523438 913.660583,437.523438 
	C913.660583,437.523438 913.301819,437.314362 913.262817,437.109558 
z"/>
<path fill="#101925" opacity="1.000000" stroke="none" 
	d="
M886.072144,422.552338 
	C886.274048,422.252777 886.535889,422.318604 886.791382,422.818604 
	C886.590393,422.984070 886.372803,422.872375 886.072144,422.552338 
z"/>
<path fill="#05657D" opacity="1.000000" stroke="none" 
	d="
M859.432373,407.008850 
	C859.691284,406.657776 859.941467,406.712463 860.230103,407.126648 
	C860.306030,407.314270 859.866089,407.483063 859.866089,407.483063 
	C859.866089,407.483063 859.461243,407.242798 859.432373,407.008850 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M958.285095,462.098511 
	C958.508972,461.789825 958.746704,461.842133 959.034546,462.214905 
	C959.117188,462.387085 958.706726,462.531158 958.706726,462.531158 
	C958.706726,462.531158 958.331604,462.311188 958.285095,462.098511 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M956.276123,461.050110 
	C956.463623,460.742676 956.687317,460.773041 956.978271,461.098114 
	C957.064575,461.252167 956.696777,461.428314 956.696777,461.428314 
	C956.696777,461.428314 956.331360,461.247253 956.276123,461.050110 
z"/>
<path fill="#162034" opacity="1.000000" stroke="none" 
	d="
M936.255493,515.757080 
	C933.642151,514.575745 933.036926,512.305054 933.003784,509.379730 
	C936.642578,509.849457 936.747742,512.589966 936.255493,515.757080 
z"/>
<path fill="#162034" opacity="1.000000" stroke="none" 
	d="
M932.241272,504.762146 
	C930.645020,503.885895 930.098328,502.402008 930.019165,500.350037 
	C932.745178,500.468994 933.520142,502.004028 932.241272,504.762146 
z"/>
<path fill="#162034" opacity="1.000000" stroke="none" 
	d="
M943.267883,534.752563 
	C941.650818,533.507263 941.312439,531.630554 941.034668,529.361450 
	C943.623840,530.042847 943.918762,532.120850 943.267883,534.752563 
z"/>
<path fill="#162034" opacity="1.000000" stroke="none" 
	d="
M938.957458,520.715210 
	C937.590271,520.865906 937.008850,520.021912 936.989746,518.359924 
	C938.421509,518.133789 938.997803,518.988953 938.957458,520.715210 
z"/>
<path fill="#162034" opacity="1.000000" stroke="none" 
	d="
M940.184082,524.826660 
	C939.667053,524.533020 939.359192,524.060791 939.028076,523.296753 
	C939.884827,523.200195 940.979126,523.214417 940.184082,524.826660 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M836.854736,431.690582 
	C837.030457,432.666901 837.019531,433.432068 837.009949,434.578552 
	C834.325500,436.703430 832.340210,434.389099 830.254456,433.559723 
	C825.903564,431.829712 821.754211,429.602631 818.895874,425.371826 
	C819.000000,425.000000 819.016174,424.983765 819.024292,424.975586 
	C822.068542,422.660034 824.117249,425.451355 826.864014,426.647064 
	C827.606750,426.905792 828.004944,426.934662 828.700195,427.003082 
	C830.667480,428.507446 832.620178,429.521576 834.829712,430.743988 
	C835.613892,431.127747 836.140991,431.303558 836.854736,431.690582 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M827.085632,426.555664 
	C824.555969,426.973389 822.542175,424.566986 819.419617,424.977783 
	C819.021851,424.225494 819.011169,423.483643 819.000366,422.370270 
	C822.270508,422.252502 824.662292,424.157654 827.085632,426.555664 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M834.997986,430.623413 
	C832.417358,431.130920 830.587463,429.768463 829.066528,427.376953 
	C831.277710,427.563477 833.474792,428.235962 834.997986,430.623413 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M818.633667,424.992065 
	C824.311401,429.088776 830.293884,432.003723 836.652954,434.923279 
	C838.100830,435.286255 839.190308,435.612610 840.620117,435.981018 
	C845.927246,439.514587 850.890686,443.011444 856.714844,445.784698 
	C858.789856,446.905853 860.579407,447.819458 862.680542,448.869324 
	C863.995667,450.106445 865.417114,450.622314 866.777344,451.758484 
	C867.563477,452.797180 868.404053,452.983673 869.654785,453.003967 
	C870.822388,453.823303 871.768311,454.520905 872.765869,455.670135 
	C873.820068,456.187134 874.619507,456.463959 875.711914,456.869812 
	C877.188110,458.417877 879.541931,457.727356 880.768433,459.733154 
	C888.953186,463.566315 896.440735,468.132751 903.834778,472.608154 
	C908.407288,475.375793 912.417847,480.024872 914.335449,486.015625 
	C917.608582,496.241241 921.915344,506.130768 925.455322,516.277466 
	C929.881653,528.964905 935.059143,541.368042 939.240356,554.160095 
	C941.370605,560.677368 943.976685,567.087891 946.131592,573.636047 
	C947.538818,577.912048 949.355652,582.114685 946.337891,586.773438 
	C944.325806,587.325500 944.139160,586.073303 944.056580,584.902832 
	C943.238220,573.309143 937.727234,563.151428 934.235901,552.368347 
	C929.837769,538.784424 924.075989,525.702087 919.786987,512.070007 
	C918.685547,508.569000 917.451477,504.876007 915.270142,501.288940 
	C915.022461,500.981934 914.991577,500.507202 914.976013,500.269836 
	C913.561462,498.984375 914.550354,496.766937 912.680054,495.576599 
	C912.239197,494.907684 912.100098,494.477600 912.030029,493.685730 
	C911.990723,491.988678 911.585632,490.782043 910.378479,489.552734 
	C909.161560,487.546234 907.907837,485.938507 907.075073,483.705444 
	C906.322815,481.378418 905.179871,479.736328 903.222839,478.259155 
	C902.970581,478.028534 902.955261,478.037720 902.946777,478.041290 
	C901.638794,476.852356 899.717102,476.730316 898.242676,475.214142 
	C898.015381,474.980652 897.993286,474.506714 897.984619,474.269653 
	C897.574829,473.497040 897.057129,473.338684 896.199951,473.749390 
	C895.453979,473.759827 894.932190,473.573944 894.235840,473.165863 
	C893.707825,472.663452 893.424377,472.323761 893.095459,471.614594 
	C892.362183,469.437195 890.487976,469.647217 888.811768,468.866241 
	C886.340942,467.250092 884.291138,465.531616 881.077759,465.639526 
	C872.562683,459.809692 863.512207,455.643036 854.976868,450.590271 
	C838.644836,440.921967 820.798523,433.920441 805.550720,422.316193 
	C802.571472,420.048767 798.587402,419.271759 795.448792,417.053528 
	C794.141907,416.129822 792.288269,415.659119 792.786621,413.143127 
	C792.973328,412.418335 792.969788,412.010986 792.983154,411.298218 
	C801.009583,413.439636 807.295105,418.971680 814.409424,423.018524 
	C815.674744,423.738220 816.601257,425.054901 818.633667,424.992065 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M915.021240,501.382935 
	C919.613586,503.154327 919.485352,508.134735 920.889465,511.878571 
	C925.363708,523.808350 930.712402,535.480042 934.316895,547.638062 
	C937.323853,557.780457 942.012634,567.316406 944.301941,577.642944 
	C944.940125,580.521423 945.105957,583.504639 945.773193,586.732239 
	C946.047546,587.462036 946.037170,587.899292 946.020020,588.664673 
	C945.222290,589.633789 944.431335,590.274902 943.335754,590.969604 
	C943.077759,579.159546 939.303162,568.357422 934.643555,557.588989 
	C931.569946,550.485840 929.326050,542.937500 926.669189,535.636963 
	C923.431274,526.739807 920.912231,517.541748 916.762329,508.957672 
	C915.715271,506.791840 915.576294,504.187042 915.021240,501.382935 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M856.841309,445.691040 
	C850.272827,445.328491 845.627686,441.061646 840.984375,436.386597 
	C847.001160,437.802338 852.052124,441.242920 856.841309,445.691040 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M881.007812,459.594360 
	C879.247192,459.499146 877.263306,459.505524 876.075195,457.334595 
	C877.738525,457.591431 881.193604,454.326141 881.007812,459.594360 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M866.942383,451.642090 
	C865.383423,451.757599 863.867615,451.361725 863.056946,449.336487 
	C864.604675,449.240265 866.126282,449.627991 866.942383,451.642090 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M873.001709,455.553223 
	C871.472290,455.915070 870.455872,455.220673 870.037231,453.342865 
	C871.545166,452.973846 872.919739,453.187683 873.001709,455.553223 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M882.640991,466.851837 
	C883.262207,470.811066 886.583069,471.692841 888.953491,473.486511 
	C891.183472,475.173920 894.588867,475.626495 894.774902,479.820068 
	C890.409851,480.686310 888.614563,476.282349 884.606018,475.542084 
	C883.804016,475.320923 883.354492,475.158142 882.589600,474.795044 
	C877.576477,470.758911 871.938477,468.705597 866.527893,466.168121 
	C863.416077,464.708740 859.207947,464.243683 858.676514,459.330353 
	C860.549316,457.478149 862.095642,458.380096 863.809937,459.151428 
	C869.460144,461.693726 874.839600,464.893494 881.309082,466.369995 
	C881.911011,466.449097 882.145874,466.520325 882.640991,466.851837 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M917.993286,522.617065 
	C913.541565,518.994324 913.072144,512.993958 910.568665,508.010040 
	C908.008118,502.912750 905.914551,497.580963 903.310730,492.170715 
	C902.395630,491.288849 902.228027,490.496552 902.774292,489.274170 
	C907.507263,490.426727 907.740662,495.128845 909.248535,498.347748 
	C912.831055,505.996063 915.157410,514.232849 917.993286,522.617065 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M937.427124,592.927429 
	C937.848083,583.463013 936.079895,574.345581 932.056946,565.618347 
	C931.219971,563.802673 930.790833,561.831482 931.027527,559.366821 
	C931.353577,559.062012 931.861877,559.095093 931.945740,559.300964 
	C936.115662,569.540955 940.802490,579.680237 938.863708,591.232605 
	C938.768921,591.797485 938.169800,592.277588 937.427124,592.927429 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M931.012695,557.587280 
	C928.085693,557.600037 928.431335,554.614807 927.759949,553.002380 
	C925.155273,546.746948 921.873596,540.665100 921.020752,533.369629 
	C923.757874,533.366760 923.491333,536.092651 923.892273,537.604370 
	C925.685059,544.364258 930.382019,550.041504 931.012695,557.587280 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M907.731689,482.733093 
	C908.975464,484.762085 909.948486,486.526581 910.973999,488.633484 
	C911.539429,489.511017 911.811584,490.135254 911.349426,491.461426 
	C907.640625,489.116913 905.796387,485.075806 903.062317,481.334351 
	C905.172913,479.473480 906.182129,481.375031 907.731689,482.733093 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M895.030762,479.967987 
	C899.801758,479.421600 901.174866,483.081940 902.952271,486.648773 
	C901.899658,488.008850 900.666626,487.800385 899.043091,487.288818 
	C897.953003,486.881989 897.312988,486.453308 896.426636,485.701111 
	C895.098572,484.003479 893.637512,482.709656 894.753784,480.319519 
	C894.985352,480.013428 895.015808,479.983612 895.030762,479.967987 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M883.253540,467.116638 
	C882.901306,467.112122 882.463928,467.110077 882.245239,467.112000 
	C881.139099,467.449432 880.752502,466.959015 880.787659,465.577209 
	C883.942993,464.612000 886.417603,465.535126 888.789185,467.717346 
	C889.385620,468.373383 889.519287,468.830078 889.212646,469.671326 
	C886.640869,470.100525 885.769531,467.392395 883.253540,467.116638 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M898.668579,486.301208 
	C900.087463,486.312714 901.197815,486.615326 902.652832,486.958984 
	C903.008606,487.431091 903.019653,487.862213 903.033691,488.615479 
	C903.025024,489.701233 903.013489,490.464844 903.003418,491.609009 
	C902.838196,491.990784 902.671570,491.991913 902.254883,491.994690 
	C900.849854,492.271729 899.808228,492.041046 898.610962,490.973145 
	C897.627930,489.293671 897.640259,487.941284 898.668579,486.301208 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M907.958008,482.638947 
	C906.568176,482.358612 905.133850,481.719696 903.361572,481.034119 
	C903.005981,480.247528 902.988342,479.507538 902.970642,478.398071 
	C906.142334,477.673920 907.177307,479.803497 907.958008,482.638947 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M921.005371,530.607910 
	C917.928528,529.907837 918.377075,527.133606 918.024658,524.350830 
	C921.004517,525.102600 920.630371,527.838379 921.005371,530.607910 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M889.227844,470.231873 
	C889.008362,469.542236 889.007812,469.097778 889.011475,468.319550 
	C890.970947,468.050354 892.847168,468.280731 893.942261,470.642639 
	C893.628113,471.371307 893.164429,471.524597 892.306641,471.226410 
	C891.142151,470.831451 890.294495,470.654236 889.227844,470.231873 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M898.062012,475.343933 
	C900.080444,474.807281 901.581238,475.711426 902.800964,477.729736 
	C900.994080,477.956482 898.832397,478.317291 898.062012,475.343933 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M912.801147,495.295227 
	C915.600220,495.506592 914.823792,497.591583 914.972900,499.637970 
	C913.586853,499.875183 913.008850,499.041412 912.986816,497.379822 
	C912.608948,496.626648 912.479675,496.154022 912.801147,495.295227 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M913.012207,494.645294 
	C913.006897,495.431702 912.999573,495.889496 912.983765,496.690918 
	C910.434937,496.512482 911.146973,494.452362 910.970947,492.421631 
	C910.964600,492.034058 910.985474,492.013580 910.995178,492.005310 
	C911.727173,491.547333 912.176941,491.975037 912.798279,492.746704 
	C913.001709,493.439728 913.005859,493.878174 913.012207,494.645294 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M896.308472,473.976440 
	C896.092712,473.316803 896.189880,472.572357 896.981262,472.571747 
	C897.289062,472.571503 897.597290,473.144409 897.940674,473.744415 
	C897.531006,474.024139 897.086121,474.015656 896.308472,473.976440 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M892.047852,471.323517 
	C892.439697,471.003845 892.889526,470.999023 893.677002,470.989349 
	C894.025146,471.420380 894.035645,471.856262 894.053711,472.617920 
	C893.146729,472.902161 892.383240,472.634369 892.047852,471.323517 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M930.754150,599.777222 
	C930.939392,601.059692 930.916504,602.105103 930.861206,603.541382 
	C930.225708,604.968079 929.000732,605.488586 928.501099,606.957642 
	C928.367920,607.656067 928.283447,608.056946 928.124512,608.765686 
	C927.776245,610.128662 927.760803,611.246216 927.119873,612.560059 
	C925.964905,614.089539 924.866699,615.013123 922.852539,614.151978 
	C921.500244,612.307861 922.034424,610.639282 922.556091,609.098267 
	C924.499207,603.357605 924.470886,597.339783 925.161072,591.428101 
	C925.688477,586.910095 924.787842,582.858948 922.720764,578.538879 
	C919.596619,572.009521 917.455139,564.964172 914.887268,558.140747 
	C910.628967,546.825562 906.211426,535.567932 901.312439,524.524048 
	C898.617371,518.448303 896.463318,512.192627 894.045044,506.028198 
	C889.811401,495.236328 882.841248,487.627960 871.393921,484.095703 
	C867.444397,482.877106 863.738037,480.409912 859.962891,478.432617 
	C859.181580,478.023438 858.508362,477.691559 857.266602,478.083374 
	C855.525818,478.257111 854.281921,477.938171 852.876770,476.901550 
	C851.167969,473.869507 848.789978,472.135376 846.159912,470.608276 
	C829.807007,461.113129 813.558472,451.434235 796.970398,442.352631 
	C793.291504,440.338470 789.652283,440.379456 785.749146,442.908844 
	C782.703125,444.882721 780.839355,449.051025 776.018921,448.337646 
	C775.816650,440.037170 784.682861,432.575256 793.186401,433.699188 
	C798.164551,434.357208 802.761597,436.233429 807.188721,438.741943 
	C820.348450,446.198730 832.762634,454.818848 845.529114,462.887726 
	C848.775146,464.939392 852.256775,466.523895 856.382080,467.499023 
	C857.014404,467.550568 857.267456,467.570526 857.898315,467.644775 
	C859.026184,467.876312 859.688354,468.227631 860.541443,468.971191 
	C861.739563,471.095337 863.025452,472.369690 865.286804,472.634247 
	C867.939941,472.944611 870.284790,474.259979 872.985962,475.518524 
	C873.783081,475.701080 874.217285,475.868073 874.922974,476.280426 
	C876.826965,478.998993 878.642761,481.266327 882.201965,481.860107 
	C882.740479,482.075012 882.949463,482.177063 883.449951,482.470154 
	C887.536194,485.271759 891.028992,488.216522 894.126343,492.092255 
	C894.470703,492.519623 894.593506,492.698883 894.866150,493.161194 
	C895.413818,494.338501 895.425903,495.472534 896.791809,496.098389 
	C897.176025,496.425293 897.319946,496.568237 897.649963,496.952576 
	C898.267517,497.880859 898.621765,498.618500 899.359619,499.483124 
	C899.650574,499.927155 899.754883,500.114716 899.982666,500.603271 
	C900.268921,503.208313 901.132996,505.116638 903.383057,506.630249 
	C903.892517,507.524078 904.027100,508.186737 904.077515,509.205872 
	C903.929565,510.621552 903.864014,511.682861 904.978394,512.791626 
	C909.706238,521.144287 913.974060,529.189392 916.281738,538.405029 
	C917.799316,544.465332 919.525940,550.851990 923.442688,556.260864 
	C925.848938,559.583801 925.586487,564.212952 927.911743,568.119446 
	C928.305298,572.446228 928.170166,576.562256 931.672974,579.910278 
	C932.608154,581.884338 932.586914,583.624573 932.372314,585.740479 
	C932.182373,587.222778 931.534302,588.294250 932.071289,589.809937 
	C932.586609,591.964233 932.590088,593.775024 931.911499,595.886353 
	C931.321472,597.290710 929.777405,597.979797 930.754150,599.777222 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M930.740234,596.339600 
	C931.973511,594.218323 930.701294,592.458862 930.981079,590.353027 
	C930.997925,589.202026 931.000244,588.401306 931.001953,587.300293 
	C930.993774,584.967102 930.985168,582.934143 930.972290,580.465820 
	C928.698364,576.964844 928.461365,573.141724 927.050537,569.362976 
	C922.930237,563.034607 922.969238,555.065918 917.536926,549.784119 
	C919.013855,544.195862 913.720886,540.427185 913.696960,534.924561 
	C913.679810,530.984924 911.488464,526.703125 909.249329,523.230164 
	C907.264221,520.151245 905.950989,516.801819 904.029541,513.361023 
	C903.779114,512.173767 903.244995,511.471619 903.029053,510.317108 
	C902.986145,509.257111 902.981750,508.513336 902.972046,507.397705 
	C901.560974,505.447784 899.830688,504.020630 900.607056,501.172089 
	C902.203308,499.910431 903.337708,500.471863 904.684326,501.738586 
	C906.708618,508.340057 909.676453,514.180298 912.064575,520.248596 
	C916.512878,531.551758 920.487122,543.041931 924.958313,554.335632 
	C928.517334,563.325562 932.269531,572.212158 934.443115,581.690430 
	C935.854919,587.846619 933.934082,593.332642 931.420471,598.967529 
	C930.343628,598.413330 930.228455,597.606506 930.740234,596.339600 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M908.087158,657.625732 
	C907.288269,658.035645 906.512817,658.058411 905.362366,657.979492 
	C902.642273,654.958679 904.558838,652.262207 905.466370,649.541748 
	C906.145081,647.507446 907.929138,646.039001 908.515137,643.550415 
	C908.589539,641.696960 909.258179,640.391052 909.878174,638.715820 
	C910.449036,637.610718 911.073730,636.964233 911.752686,635.965698 
	C912.175171,635.365906 912.450928,635.055298 912.964111,634.558472 
	C913.569214,633.905090 913.891846,633.423279 914.047729,632.510803 
	C914.192139,631.763123 914.333008,631.344177 914.686096,630.632568 
	C916.540039,629.028687 916.464539,626.847961 917.439880,625.208252 
	C918.633545,623.201477 919.321655,620.578918 922.696777,620.776367 
	C922.835327,627.279724 918.708313,631.957642 916.263245,637.293640 
	C913.264709,643.837280 909.676086,650.115906 908.087158,657.625732 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M923.016235,620.761169 
	C919.966125,622.082825 918.960571,624.768188 918.139709,627.451904 
	C917.690613,628.920288 917.569214,630.418335 915.183838,630.772949 
	C914.095947,629.789551 914.165222,628.959290 914.837036,627.898315 
	C915.058655,627.249512 916.328186,627.589050 915.461060,626.439758 
	C915.119385,624.934753 915.558105,623.864990 916.212830,622.478638 
	C918.860352,620.279053 918.533875,615.526428 923.291382,616.002930 
	C924.103333,617.326538 924.118835,618.358948 923.307556,619.723633 
	C923.076843,620.062134 923.041382,620.528625 923.016235,620.761169 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M904.935425,501.667023 
	C903.879089,501.702271 902.785583,501.388306 901.350891,501.032959 
	C900.458984,501.226746 900.009827,501.099518 899.537231,500.239014 
	C898.418762,497.412964 897.206787,495.000854 898.514160,491.605591 
	C899.858765,491.165649 900.708008,491.339752 901.781128,491.755127 
	C899.981995,496.030731 903.896606,498.222168 904.935425,501.667023 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M923.426514,619.992493 
	C923.045532,618.972961 923.014221,617.883789 922.972290,616.387207 
	C922.639160,615.588379 922.513367,615.145813 922.837646,614.355713 
	C924.463745,614.015259 925.368042,613.038940 927.221313,612.163818 
	C926.285950,614.489502 926.850098,618.195374 923.426514,619.992493 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M927.034790,609.326660 
	C927.032898,608.564880 927.038635,608.125916 927.049927,607.357666 
	C927.962646,606.132996 928.812500,605.112183 930.633850,605.758789 
	C930.257263,607.959900 930.590881,610.323914 928.392944,611.957031 
	C928.055847,612.052856 928.022461,612.021851 928.006348,612.006042 
	C927.336731,611.346619 927.184814,610.499939 927.034790,609.326660 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M930.954895,605.638550 
	C929.870911,606.296997 928.796448,606.613220 927.388794,606.978943 
	C927.120728,605.036621 928.221680,604.091797 930.485413,603.953735 
	C930.874023,604.386902 930.919250,604.841553 930.954895,605.638550 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M930.636841,596.054932 
	C930.985535,596.748352 931.001648,597.524841 931.039917,598.690674 
	C931.062134,599.080078 931.028137,599.548706 930.995239,599.781494 
	C928.435486,599.113892 928.403137,597.790039 930.636841,596.054932 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M909.690552,642.762329 
	C909.398743,648.076538 904.709045,651.702698 904.986938,657.482483 
	C904.987366,657.877808 904.586365,657.997375 904.403320,658.094849 
	C903.903320,657.977905 903.586304,657.763489 903.113647,657.310303 
	C902.130859,656.350525 901.140503,655.752441 900.975891,654.176697 
	C900.869995,653.011597 900.859558,652.161499 900.544556,651.021179 
	C900.514099,650.476013 900.536316,650.254517 900.686646,649.720581 
	C902.586426,647.001404 904.617249,644.818298 906.973877,642.476196 
	C908.026794,642.111450 908.732239,642.167664 909.690552,642.762329 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M369.155823,951.288452 
	C369.242188,949.860107 369.479523,948.746582 369.857819,947.316528 
	C370.179688,945.373535 371.605804,944.529236 372.345093,943.256104 
	C373.434235,941.380493 373.205658,939.984314 371.187317,938.693848 
	C363.744141,933.934998 362.536804,930.716125 364.998993,922.059509 
	C361.109467,924.314758 357.658569,924.275574 354.251984,921.604736 
	C352.363190,920.123840 349.733734,920.777710 347.485687,920.243958 
	C345.305084,919.726196 343.030731,918.923889 343.292419,916.173645 
	C343.576355,913.189575 345.954468,912.719543 348.485748,912.820557 
	C353.879517,913.035706 353.448517,913.073853 352.959473,907.863281 
	C352.507935,903.052307 350.679138,900.425842 346.228821,898.101318 
	C343.028564,896.429749 340.602905,894.509766 336.646484,894.990601 
	C334.087646,895.301514 331.747162,893.261475 331.582367,890.448792 
	C331.416748,887.620728 333.701019,887.046265 336.097046,887.205811 
	C336.784760,887.251526 337.588562,887.324524 337.963989,886.786255 
	C339.892944,884.020630 343.046143,883.701050 345.925537,882.338989 
	C345.787628,880.862610 345.582397,879.723877 345.361359,878.588196 
	C344.932861,876.386108 345.999969,875.510498 347.792755,876.613037 
	C351.245209,878.736206 351.770294,876.881531 352.006714,874.016968 
	C352.618500,866.604248 354.760010,864.488892 362.146149,862.891907 
	C366.939209,861.855652 371.585449,860.384644 375.974030,857.909973 
	C378.531128,856.468018 381.914429,855.459656 384.898376,857.012024 
	C387.965088,858.607422 388.137207,856.760010 387.940704,854.763855 
	C387.595825,851.261108 389.489807,849.768982 392.082458,847.707581 
	C397.503540,843.397217 401.001617,837.177551 405.459991,831.883301 
	C406.197388,831.007751 406.832306,829.579651 407.730621,829.380310 
	C414.470154,827.884521 417.838837,822.056396 422.657166,818.110779 
	C423.037506,817.799255 423.469543,817.313110 423.879700,817.310974 
	C431.334900,817.272034 431.083496,810.842041 432.990784,806.270508 
	C433.386047,805.323059 433.556305,804.456787 434.619659,803.999023 
	C438.233032,802.443665 439.131683,799.391052 438.996582,795.402771 
	C437.497192,793.299744 438.663025,791.449707 438.792480,789.283813 
	C439.009491,788.669922 439.124786,788.436951 439.481750,787.902588 
	C443.377563,785.938232 445.620026,782.948792 447.327057,779.470581 
	C448.348694,777.388916 449.112335,775.050598 452.445923,775.214783 
	C456.926025,778.681702 454.067017,781.943359 452.391144,784.970154 
	C450.834625,787.781250 448.223694,789.895874 451.535461,793.495361 
	C453.185394,795.288574 451.436035,797.654846 449.209808,798.632629 
	C445.986328,800.048279 445.790588,801.800537 448.159363,804.209778 
	C448.854218,804.916443 449.293762,805.855835 449.582062,806.821716 
	C449.931458,807.992310 449.985657,809.191895 448.977692,810.051270 
	C448.109161,810.791809 447.120819,810.448486 446.175598,810.006836 
	C444.184448,809.076477 441.887177,806.670349 440.087250,809.478394 
	C438.343658,812.198486 434.127869,813.985291 436.548889,818.813782 
	C437.795227,821.299561 437.363281,824.627319 438.748657,827.374878 
	C439.358032,828.583496 439.197571,830.067810 438.012787,831.025085 
	C436.706207,832.080811 435.547607,831.181152 434.368835,830.537842 
	C431.655090,829.056763 421.505371,829.912598 419.245789,831.698853 
	C418.456024,832.323242 418.414215,833.083252 418.504486,833.912231 
	C418.676422,835.491150 418.042023,836.598511 416.483032,836.819458 
	C414.107605,837.156189 413.719788,838.973450 412.628204,840.683289 
	C410.331787,844.280273 409.277161,846.618713 414.052338,849.200012 
	C416.234833,850.379761 414.781860,853.062256 412.643494,854.818787 
	C408.831848,856.958557 405.420044,858.244141 403.413818,853.433228 
	C402.260773,853.453735 401.869354,854.023438 401.571472,854.601135 
	C399.718964,858.194153 399.010620,863.056946 393.767303,863.280029 
	C389.644196,863.455505 385.713257,865.647339 381.484467,864.347351 
	C380.012573,863.894836 378.539795,863.805176 377.132599,864.593201 
	C375.525879,865.493103 374.703766,866.850586 374.933533,868.706055 
	C375.153961,870.486267 376.636780,870.512512 377.942200,870.680969 
	C379.429474,870.872986 380.910278,871.077637 382.324951,871.595703 
	C384.324097,872.327820 386.019318,873.440247 385.779510,875.857666 
	C385.566376,878.006104 383.969543,878.870361 381.974335,879.148376 
	C381.185150,879.258240 380.278351,879.012756 379.668243,879.807678 
	C379.803131,880.916931 380.740509,881.414062 381.428955,882.094543 
	C385.739502,886.355774 385.805084,887.241577 381.234375,890.937195 
	C378.866852,892.851440 379.020599,894.953125 381.121857,896.227966 
	C385.846924,899.094482 385.104431,902.056763 381.484863,905.583740 
	C387.029449,907.826172 390.130676,911.578186 390.874939,917.170959 
	C391.019623,918.258240 391.287476,919.656982 392.816162,919.649109 
	C394.069977,919.642639 395.057831,918.732117 395.541840,917.696167 
	C396.864380,914.865479 398.452850,912.836182 401.923340,912.158142 
	C406.448700,911.274048 410.506439,912.229675 414.543060,913.863159 
	C416.354553,914.596191 417.598907,916.659851 415.411926,917.486389 
	C408.039246,920.272766 411.385803,925.252808 412.366791,929.810120 
	C412.574768,930.776184 413.016174,931.693604 413.281158,933.047119 
	C413.450592,934.497803 413.282867,935.527222 412.612122,936.852417 
	C411.541962,937.971008 410.509979,938.180481 409.028168,938.129761 
	C406.501709,937.611389 403.384918,934.666931 403.785309,940.283630 
	C403.948883,942.578613 401.948761,943.210571 399.952637,943.215820 
	C397.954468,943.221130 395.662048,943.268555 394.096466,942.364014 
	C388.360657,939.049622 385.933289,943.112793 382.973358,947.014038 
	C381.378174,949.041077 379.409424,949.848999 377.103333,950.765381 
	C375.763306,951.393799 374.749634,951.903503 373.423584,952.559082 
	C372.653107,952.807800 372.194031,952.857849 371.382507,952.797852 
	C370.351105,952.528931 369.778595,952.142395 369.155823,951.288452 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M345.689819,882.133484 
	C346.955719,882.980530 346.968475,884.979370 345.888550,884.562439 
	C340.621185,882.529236 339.103821,890.671082 334.019287,888.618530 
	C333.570587,888.437378 332.247620,889.234070 332.509735,890.222290 
	C333.010468,892.110168 334.936310,894.242859 336.186951,893.680481 
	C341.482788,891.298767 343.842712,895.173889 347.685822,897.232483 
	C351.872559,899.475098 354.940033,900.811951 353.985107,905.972778 
	C353.446869,908.881836 357.810120,911.127380 354.998413,914.285217 
	C352.299469,917.316467 349.839233,913.781616 347.239380,914.028809 
	C345.816864,914.164124 344.502716,914.503113 344.300537,916.135193 
	C344.083252,917.888672 345.564453,918.552185 346.809296,918.676941 
	C351.051971,919.102356 355.186920,918.983765 358.705566,922.478271 
	C360.378815,924.139893 362.309509,920.837646 364.340271,920.325256 
	C365.382660,920.062195 366.409332,918.686951 367.468170,919.861267 
	C368.430878,920.929016 367.595276,922.226746 366.899628,923.024475 
	C363.383850,927.056519 365.526337,930.752686 367.389282,934.489807 
	C367.927582,935.569641 368.757782,936.519775 369.934448,936.733093 
	C372.391449,937.178589 374.976440,937.042358 375.041321,940.752869 
	C375.098938,944.047913 373.172302,945.511597 370.319946,946.851868 
	C368.056549,945.003418 366.110657,943.852051 363.709229,946.474609 
	C361.652588,948.720703 360.506744,947.454651 360.059967,945.185730 
	C359.626282,942.983459 358.593719,941.384399 356.319489,941.116638 
	C354.303925,940.879333 352.329712,941.098877 353.709442,944.057922 
	C353.919006,944.507324 354.294800,944.898926 354.420105,945.364075 
	C354.724335,946.493042 353.902618,947.037048 353.066071,946.862183 
	C350.409760,946.306763 348.479828,945.988403 347.695312,949.728638 
	C347.060272,952.756470 345.570312,952.217163 343.771637,947.903870 
	C343.519012,947.298157 343.077881,946.773804 342.762848,946.190857 
	C340.457062,941.924622 340.820435,941.329834 345.893066,940.996948 
	C346.884369,940.931885 348.034241,941.120178 348.829132,940.681213 
	C350.402374,939.812256 351.767242,937.696472 349.834869,936.830505 
	C346.417816,935.299316 344.727264,932.371216 342.346252,929.975342 
	C341.445862,929.069397 340.216125,928.032166 338.826477,928.664673 
	C337.543396,929.248596 338.098846,930.718384 338.010040,931.823486 
	C337.904022,933.143433 338.142548,934.681396 336.672302,935.329407 
	C335.157288,935.997131 334.807587,934.341431 334.040741,933.606445 
	C331.633789,931.299438 329.663269,931.464783 328.206238,934.577698 
	C327.403259,936.293274 326.097900,936.984680 324.232208,937.013306 
	C320.206512,937.075134 318.208008,939.258057 317.921082,943.215881 
	C317.863037,944.016602 317.522949,944.833740 317.159180,945.567139 
	C316.570038,946.754761 315.914978,948.223145 314.214874,947.319214 
	C312.864136,946.600952 313.286285,945.332703 313.893036,944.265564 
	C315.935455,940.673523 314.701172,937.803467 311.764832,935.536804 
	C309.975525,934.155579 308.006744,933.399597 307.978180,937.055298 
	C307.969482,938.169434 308.024933,939.940857 306.465485,939.595215 
	C304.549500,939.170593 303.174805,937.411133 302.348572,935.674072 
	C301.767303,934.452087 302.936554,933.240967 304.145874,933.227722 
	C309.419586,933.170105 313.205170,927.345215 319.204742,929.636414 
	C320.940216,930.299316 321.076538,928.278320 320.247620,926.955078 
	C319.267731,925.390808 317.698639,924.916687 315.920685,924.996765 
	C314.179138,925.075256 311.883942,924.748169 311.383636,923.439880 
	C308.994385,917.192505 303.811005,917.987000 298.877075,917.876709 
	C298.483002,917.867920 298.097748,917.466492 297.197296,916.960083 
	C301.842957,915.183838 305.798523,912.984192 310.490173,915.564819 
	C311.506439,916.123840 313.091675,915.545044 314.380341,915.730286 
	C319.989929,916.536499 325.615082,916.656189 331.275604,916.686523 
	C333.561096,916.698792 335.972900,917.301636 338.197784,916.833435 
	C340.779114,916.290222 343.208954,915.008972 340.735962,911.695862 
	C339.969818,910.669434 340.154510,909.491272 339.957367,908.360352 
	C339.398193,905.152588 337.717377,903.431763 334.427460,905.098877 
	C331.369934,906.648132 328.218079,905.776184 325.110962,905.937805 
	C323.125244,906.040955 322.230255,904.546387 321.492981,902.985291 
	C319.876923,899.563354 317.686340,899.073975 314.837341,901.633301 
	C314.225189,902.183167 313.516083,902.641663 312.802521,903.058044 
	C311.750061,903.672302 310.570923,904.142822 309.701263,902.884644 
	C308.813416,901.600098 309.732758,900.612915 310.685974,899.944031 
	C315.719330,896.412476 320.425690,892.517456 324.910614,888.296021 
	C327.325714,886.022949 330.951630,886.863831 333.991730,886.187012 
	C336.336670,885.664917 338.575226,885.563599 339.927338,883.255066 
	C341.352661,880.821594 343.750031,883.640564 345.689819,882.133484 
z"/>
<path fill="#192334" opacity="1.000000" stroke="none" 
	d="
M345.727081,1025.807251 
	C346.795105,1026.036621 347.540253,1026.034790 348.657593,1026.050781 
	C352.380707,1025.061401 355.096100,1023.157837 354.780365,1019.384338 
	C354.505157,1016.096130 351.632782,1015.056946 348.697113,1014.483948 
	C345.590607,1013.877563 342.465820,1013.494019 339.308899,1013.653381 
	C334.389465,1013.901733 329.769592,1012.717468 325.271393,1010.908386 
	C314.717102,1006.663879 312.395630,993.185303 320.933807,985.673523 
	C321.926544,984.800171 323.018921,984.039124 324.038849,982.697510 
	C332.607422,977.033630 341.801300,976.191040 351.896301,977.788208 
	C356.082245,976.631897 358.509399,978.309998 360.163849,983.607910 
	C359.869812,985.454285 359.158081,986.611633 357.695831,987.719971 
	C353.455933,989.103821 349.435486,988.520996 345.463745,988.671021 
	C341.617188,988.816467 337.933655,989.401306 334.189606,992.183594 
	C339.531830,993.801086 344.496796,990.391907 349.251221,993.254272 
	C348.538849,998.248474 344.526947,997.552368 341.269867,998.506836 
	C344.482300,998.290344 347.542023,998.960632 350.996307,999.480225 
	C363.276337,999.837708 371.248657,1006.404541 372.231934,1017.093689 
	C370.841858,1023.516907 368.645325,1029.250854 365.937164,1035.223145 
	C365.297577,1036.949829 364.430054,1037.977051 362.615784,1038.536377 
	C354.666138,1039.755005 347.402985,1042.699097 339.583710,1041.847412 
	C338.759735,1041.757812 337.918030,1041.833008 337.085358,1041.800537 
	C328.546906,1041.467407 326.573914,1039.108276 327.821838,1030.156006 
	C328.227661,1029.284424 328.570892,1028.882446 329.353027,1028.425537 
	C331.161713,1028.077271 332.838226,1028.510010 334.187653,1026.770264 
	C338.178192,1026.643921 341.783112,1026.024658 345.727081,1025.807251 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M323.771912,982.373657 
	C324.152893,984.791992 322.298370,986.083374 320.860931,987.661560 
	C314.028839,995.162415 317.128326,1005.976318 326.511139,1009.868591 
	C334.047424,1012.994812 341.923065,1011.177490 349.517303,1012.842896 
	C353.839630,1013.790710 356.718903,1015.576538 356.912842,1019.988403 
	C357.118713,1024.671753 353.010986,1025.811890 349.692139,1027.407715 
	C349.638306,1027.433594 349.329163,1026.928345 349.084473,1026.369873 
	C349.318604,1025.906128 349.570312,1025.698608 349.925415,1025.244629 
	C351.098602,1022.023743 348.885162,1021.242737 346.818359,1020.556946 
	C344.105316,1019.656494 341.250366,1019.696106 338.420990,1019.692078 
	C332.181091,1019.683167 326.180695,1018.494873 320.522919,1015.846375 
	C313.051178,1012.348816 309.284698,1004.714783 311.616150,997.229370 
	C311.897797,995.723755 311.959198,994.550537 312.017761,992.933411 
	C313.343994,990.927673 314.673126,989.365906 316.163391,987.514282 
	C318.677551,985.619019 321.030548,984.013611 323.771912,982.373657 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M359.458862,984.141602 
	C359.209167,979.659973 355.787354,979.156555 352.635559,977.945557 
	C347.171600,966.590942 348.863251,961.652283 360.440735,955.931580 
	C363.265839,954.535645 364.806427,951.253174 368.639954,951.000610 
	C369.553802,951.162231 370.102722,951.350769 370.829895,951.774048 
	C372.298584,953.692200 371.650940,955.054993 369.982727,956.536987 
	C364.485748,959.002258 361.927551,963.051575 362.478668,968.476685 
	C362.898651,972.611389 361.772217,976.696106 362.316223,980.851685 
	C362.519684,982.405945 362.106750,984.306152 359.458862,984.141602 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M439.706726,788.784302 
	C440.172668,790.837036 439.725952,792.556091 439.049225,794.621155 
	C434.993256,796.584473 435.412140,801.935608 431.301056,803.843262 
	C431.002441,804.002441 430.998779,803.998718 430.995728,803.995728 
	C429.298523,802.140503 429.918152,800.344666 431.153259,798.590271 
	C431.903137,797.525085 433.035492,796.651550 432.772797,794.752075 
	C432.879578,792.816650 433.463989,791.474548 434.951355,790.187500 
	C436.609894,789.409790 437.711060,788.099060 439.706726,788.784302 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M232.730270,906.738770 
	C230.864822,908.193420 229.302124,908.292603 228.413910,906.326599 
	C227.641388,904.616638 227.636810,902.706848 229.400040,901.467346 
	C230.592285,900.629272 231.950333,899.975281 233.315155,899.448853 
	C234.678299,898.923035 236.578323,898.348022 237.293686,899.860657 
	C237.939087,901.225342 237.340103,903.168335 236.268051,904.403259 
	C235.457520,905.336975 234.117004,905.810608 232.730270,906.738770 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M253.056274,929.360107 
	C254.418060,928.303772 255.479004,926.835205 257.650269,927.005798 
	C259.520569,928.737183 259.373199,930.478577 257.409393,931.527222 
	C255.556488,932.516785 253.860794,931.953552 253.056274,929.360107 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M241.512070,899.579956 
	C240.106049,898.294556 240.152710,897.394836 241.829300,897.099731 
	C242.736816,896.940063 244.131943,896.752014 244.334045,897.866211 
	C244.663544,899.682678 243.239639,899.873657 241.512070,899.579956 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M257.094879,893.094360 
	C258.592621,893.354736 259.946350,893.838867 259.310822,895.215637 
	C258.766541,896.394653 257.267609,896.120605 256.329315,895.428040 
	C255.144409,894.553528 255.040131,893.585144 257.094879,893.094360 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M263.881348,919.852234 
	C264.701996,919.313782 265.389160,919.234924 265.509735,920.059265 
	C265.642242,920.965210 265.007996,921.605225 264.099762,921.479065 
	C263.270874,921.363953 263.349670,920.676758 263.881348,919.852234 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M230.380554,912.646484 
	C230.543747,913.661682 230.016968,913.877686 229.344650,913.783508 
	C229.147064,913.755798 228.815720,913.375671 228.849564,913.225708 
	C229.008850,912.519653 229.519272,912.284729 230.380554,912.646484 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M260.188354,960.746826 
	C257.958344,961.000000 255.916672,961.000000 253.437500,961.000000 
	C254.848465,957.024231 257.515686,956.059509 260.851379,958.278259 
	C262.275604,959.225525 260.868134,959.819946 260.188354,960.746826 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M345.757721,1025.766602 
	C342.390472,1027.688110 338.388733,1026.656006 334.296936,1027.723145 
	C333.272095,1028.852661 332.321045,1028.971924 330.921082,1028.733398 
	C330.558899,1028.641724 330.125153,1028.424927 330.125153,1028.424927 
	C330.125153,1028.424927 329.640259,1028.433350 329.429321,1028.552368 
	C327.686249,1030.101929 326.747192,1031.924316 325.645905,1034.068848 
	C324.779022,1035.091431 323.961029,1035.609497 322.773834,1036.225098 
	C319.908417,1038.748657 318.028839,1045.536499 319.284454,1048.480713 
	C320.833344,1052.112671 323.930817,1053.402588 327.672150,1053.563232 
	C337.265900,1053.974854 346.565613,1053.429199 353.823486,1045.878418 
	C354.764862,1044.899170 355.877258,1044.192871 357.649841,1044.751831 
	C358.104126,1046.747314 356.357300,1047.719482 356.052979,1049.665405 
	C355.712097,1050.135620 355.455566,1050.330200 355.129333,1050.804810 
	C352.057587,1051.918823 349.769226,1053.699951 347.988525,1056.580933 
	C343.411346,1056.735229 339.417267,1059.065430 334.923462,1059.476440 
	C331.531067,1059.786743 328.199860,1060.028931 324.770691,1060.152710 
	C314.375275,1060.527954 314.200317,1055.160034 314.463501,1046.809448 
	C314.608795,1042.197876 315.881653,1037.398682 318.217285,1033.015625 
	C320.246368,1029.207886 316.118225,1028.989868 314.252441,1028.375000 
	C310.042206,1026.987427 306.418610,1024.674927 303.412262,1021.710266 
	C298.307800,1016.676514 297.663116,1009.637695 301.144012,1003.384460 
	C301.955170,1001.927246 302.906311,1000.562805 303.068451,998.467041 
	C305.041992,997.929138 305.980408,996.208984 307.746735,995.171387 
	C308.280914,998.347839 305.612671,1000.379333 304.515228,1003.100647 
	C300.678253,1012.615173 308.808136,1021.748535 317.580933,1024.123901 
	C326.010254,1026.406128 334.172180,1026.398438 342.571014,1025.058350 
	C343.489258,1024.911865 344.498444,1025.335449 345.757721,1025.766602 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M307.053619,1061.341553 
	C307.335327,1061.050049 307.792145,1060.991943 307.966431,1061.186523 
	C314.030579,1067.960449 322.055389,1067.127930 329.970215,1067.064453 
	C333.461395,1067.036377 336.389221,1066.155029 338.921600,1063.351562 
	C339.434448,1062.989502 339.870697,1062.979126 340.634216,1062.963379 
	C341.142639,1062.841675 341.323853,1062.725220 341.756409,1062.811768 
	C341.702209,1066.145630 339.230286,1067.028687 336.821350,1067.557739 
	C328.876678,1069.302368 320.983826,1071.950439 312.786957,1068.188843 
	C309.729675,1066.785767 308.527710,1064.258423 307.053619,1061.341553 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M342.369690,1063.002075 
	C341.833466,1063.007080 341.659119,1062.999512 341.223083,1062.974854 
	C341.884247,1060.490112 343.788452,1059.352417 346.656982,1059.029053 
	C346.610626,1061.455322 345.211700,1062.808594 342.369690,1063.002075 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M362.523132,1038.243286 
	C363.445251,1037.399536 364.204620,1036.746948 365.083160,1035.813110 
	C367.252472,1029.970581 371.187317,1025.007935 370.831238,1018.299805 
	C370.227417,1007.799133 364.489807,1002.592285 351.576965,1000.562622 
	C349.141205,998.166687 350.475128,996.333862 352.372345,994.308167 
	C355.473480,992.856873 358.070251,994.095764 361.104797,994.619995 
	C362.268555,994.826660 362.965546,995.157166 363.926819,995.795105 
	C364.979553,996.612122 365.510773,997.454102 366.658142,998.140503 
	C370.347015,1000.992432 373.841125,1003.563599 375.014496,1008.457153 
	C375.389252,1009.727966 375.497528,1010.699829 376.041199,1011.931885 
	C376.395264,1013.253479 376.517456,1014.236084 376.480774,1015.607178 
	C376.529541,1017.693970 376.256042,1019.433533 376.956116,1021.469482 
	C377.208618,1022.574219 377.220032,1023.325928 377.138916,1024.454102 
	C376.803284,1026.020630 376.360535,1027.187500 376.452423,1028.822021 
	C376.445648,1029.691162 376.419739,1030.187378 376.317963,1031.052246 
	C374.883087,1037.043945 377.109497,1040.705688 383.222473,1042.351562 
	C391.836273,1044.670898 399.997528,1043.117065 407.724915,1038.939209 
	C409.047516,1038.224121 410.223022,1037.286377 412.456879,1037.726562 
	C410.318207,1046.234863 402.828003,1047.032959 396.094849,1048.967407 
	C394.842407,1049.327271 393.313995,1049.024536 392.226196,1049.609863 
	C386.009583,1052.954224 380.868622,1050.034302 375.585724,1047.163818 
	C371.890594,1045.156128 369.150757,1040.302490 363.408142,1042.966309 
	C362.812958,1041.947021 362.585541,1040.869995 362.194397,1039.501465 
	C361.906342,1038.852051 362.001678,1038.587280 362.523132,1038.243286 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M443.740967,1032.709106 
	C443.618561,1035.416870 441.591644,1034.826172 440.086609,1035.174561 
	C434.678986,1036.426514 429.172516,1036.832764 423.912811,1035.006592 
	C420.444000,1033.802124 418.188934,1034.476196 416.162109,1037.699707 
	C415.271667,1038.019775 414.542328,1038.034790 413.451172,1038.072510 
	C413.089355,1038.095215 413.047363,1038.049072 413.025330,1038.026978 
	C412.181702,1036.365356 413.361298,1035.239624 414.263733,1033.687988 
	C415.434875,1032.553711 416.241302,1031.568726 417.588715,1030.593628 
	C419.740509,1028.872803 421.826813,1027.234985 424.085297,1028.869873 
	C428.628357,1032.158691 433.713562,1031.444092 438.737915,1031.503296 
	C440.368317,1031.522461 442.038605,1031.342529 443.740967,1032.709106 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M361.352722,1045.944092 
	C361.029694,1044.869385 361.456879,1043.941772 362.736176,1043.170898 
	C362.963409,1044.159790 363.069458,1045.367432 361.352722,1045.944092 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M297.006836,1026.383301 
	C301.176422,1031.941650 310.066071,1034.880493 307.903717,1044.931396 
	C307.151764,1048.426392 307.643921,1052.430420 306.994995,1056.587891 
	C303.554535,1054.964355 304.115417,1051.327637 305.028961,1048.825806 
	C306.769409,1044.059570 305.477875,1040.651855 302.615509,1036.975342 
	C300.241089,1033.925415 296.759491,1031.320801 297.006836,1026.383301 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M293.632660,1009.072998 
	C292.873962,1011.734619 293.116425,1014.481201 293.976379,1017.619568 
	C289.336090,1015.406128 290.845856,1012.317871 293.632660,1009.072998 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M293.756134,1021.223267 
	C294.971497,1022.442322 295.946136,1023.884583 296.963745,1025.659180 
	C294.651245,1025.391846 292.640930,1024.527344 293.756134,1021.223267 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M311.469604,996.994080 
	C311.342621,1012.336670 321.431519,1016.607178 336.157562,1017.945618 
	C340.268036,1018.319214 344.513000,1017.625854 348.475037,1019.119080 
	C351.100220,1020.108582 353.533417,1021.560730 350.407104,1024.956299 
	C347.806519,1022.456665 344.917999,1021.800232 341.590393,1021.956665 
	C334.977570,1022.267334 328.483765,1021.315918 321.930481,1020.370178 
	C316.971985,1019.654602 313.949432,1016.572571 310.652771,1013.774475 
	C307.583252,1011.169250 308.178802,1000.405090 311.469604,996.994080 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M444.336182,1033.010986 
	C439.710205,1033.019531 435.414215,1032.962769 431.120117,1033.029419 
	C428.903748,1033.063965 426.579926,1032.962036 425.088440,1031.337036 
	C422.574432,1028.597656 420.639832,1029.456055 418.313110,1031.866211 
	C417.847809,1031.839844 417.676117,1031.660278 417.347656,1031.140625 
	C416.739777,1028.200073 418.570068,1026.630371 420.127747,1024.525146 
	C421.301941,1023.256714 422.368317,1022.528198 424.131653,1022.365723 
	C424.847046,1022.488464 425.219055,1022.553162 425.436035,1022.780762 
	C427.912781,1025.378296 429.877411,1028.444214 434.210693,1028.562866 
	C440.369232,1028.731567 446.113434,1027.773071 451.313843,1024.333984 
	C451.730927,1024.058105 452.247833,1023.873108 452.567902,1023.523743 
	C458.447235,1017.104980 465.794434,1016.918640 473.828583,1018.821594 
	C473.883575,1019.640991 473.683960,1020.163086 473.259583,1020.860596 
	C470.345001,1023.069092 467.406952,1023.766541 463.981964,1024.315430 
	C457.610260,1025.336548 452.026855,1028.960571 446.302643,1032.735596 
	C445.549255,1032.963135 445.108093,1032.979980 444.336182,1033.010986 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M446.209839,1033.127197 
	C446.092316,1030.686890 447.858368,1029.911865 449.531433,1029.263062 
	C455.495453,1026.950806 460.333649,1021.712097 467.554291,1022.543152 
	C469.046814,1022.714905 470.684357,1021.626892 472.644928,1021.077759 
	C472.696228,1023.525879 469.407135,1023.786011 469.017090,1026.614258 
	C467.905792,1026.958130 466.849945,1026.966675 465.397339,1026.972290 
	C464.623749,1024.863159 463.664764,1025.654785 462.233521,1026.088867 
	C456.623199,1027.790894 451.676880,1030.864868 446.209839,1033.127197 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M362.339539,1037.983398 
	C362.621674,1038.354370 362.473663,1038.608032 362.136230,1039.011475 
	C360.649170,1040.041382 359.267639,1040.872559 357.737823,1041.947998 
	C357.198914,1042.666382 356.722229,1043.026245 355.720886,1043.381104 
	C349.115021,1046.227173 342.643372,1047.606689 335.912384,1047.796265 
	C333.239014,1047.871582 330.570618,1047.759033 327.981842,1047.007202 
	C323.290405,1045.644775 321.876740,1043.175537 323.109711,1038.360718 
	C323.437561,1037.080322 323.551422,1035.723022 324.413177,1034.128906 
	C325.154541,1031.468506 325.104370,1028.777466 328.806335,1028.598877 
	C328.465820,1039.945557 328.517029,1039.830200 339.759796,1040.068359 
	C345.600098,1040.192139 351.235565,1039.433350 356.787415,1037.830811 
	C358.588898,1037.310791 360.271179,1036.865845 362.339539,1037.983398 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M354.830933,1042.520630 
	C355.683105,1042.234863 356.224396,1042.149902 357.177612,1042.128662 
	C357.731628,1042.919434 357.873749,1043.646240 357.980591,1044.667480 
	C353.202850,1046.884888 350.538635,1051.725098 345.433868,1053.374146 
	C338.118530,1055.737305 330.750732,1055.569702 323.371185,1054.763184 
	C319.630951,1054.354614 316.188812,1048.915283 317.271393,1045.276733 
	C318.301697,1041.813965 317.113007,1037.357788 321.622620,1035.046631 
	C323.686951,1036.541382 322.675812,1038.361328 322.531738,1040.111938 
	C321.965393,1046.994507 325.337067,1049.832153 332.272217,1049.760010 
	C340.528839,1049.673950 347.883301,1047.534546 354.830933,1042.520630 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M433.594971,794.756592 
	C435.549164,796.335388 434.204285,797.276978 433.331604,798.123718 
	C431.865295,799.546509 430.741211,801.055054 430.995117,803.612793 
	C426.607361,805.713379 423.080505,808.799866 419.839691,811.973633 
	C416.256287,815.483032 412.001465,817.666565 407.377502,820.001160 
	C407.011627,820.012207 407.000336,820.000305 406.996887,819.995239 
	C406.232239,817.597839 408.392090,816.839417 409.761017,815.256592 
	C410.331299,814.229919 410.647675,813.443115 410.989807,812.338013 
	C411.154816,811.831909 411.294098,811.644043 411.726257,811.263184 
	C412.658051,810.778198 413.335663,810.644165 414.363556,810.538208 
	C416.910492,809.864624 418.489136,808.351807 420.171875,806.419556 
	C423.285828,802.775879 426.505951,799.706299 429.567963,796.486328 
	C430.588470,795.413025 431.564575,794.233337 433.594971,794.756592 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M409.656189,815.021973 
	C409.998901,817.029663 408.246094,817.969604 407.147522,819.699585 
	C406.545410,819.988098 406.097321,819.986023 405.312927,819.971985 
	C405.714905,817.700867 406.466125,815.453003 409.656189,815.021973 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M136.659546,735.063599 
	C134.902908,740.865906 133.490295,747.025146 136.107056,752.807251 
	C139.705887,760.759644 142.084900,769.176025 145.447449,777.215149 
	C148.018524,783.362244 151.012268,789.603394 153.468307,795.882812 
	C155.357788,800.713745 153.622375,806.773071 158.332642,810.511475 
	C158.962448,811.011292 159.648224,811.440552 160.654053,811.951233 
	C162.458328,811.933533 163.328049,812.649780 163.724060,814.390503 
	C162.096909,817.568604 164.836121,818.789368 165.928360,820.556885 
	C167.695328,823.416443 169.229141,826.568604 173.227966,827.669128 
	C174.059357,827.874268 174.512466,828.067993 175.261719,828.520386 
	C176.819626,830.325806 178.319168,831.497070 180.172531,832.423218 
	C188.186859,836.427673 196.253159,840.363586 203.582214,845.585388 
	C204.759506,846.424133 206.044159,846.459595 207.354767,846.634766 
	C214.360199,847.570862 220.305206,851.490906 226.897537,853.611023 
	C235.081696,856.243103 243.369766,858.066895 252.021194,857.849976 
	C252.517807,857.837524 252.993774,858.051453 253.784607,858.468750 
	C255.368805,861.208740 253.689651,862.106934 251.404724,862.948608 
	C247.270279,861.728333 243.634476,859.825195 239.651291,862.536865 
	C238.155457,863.555176 236.710037,862.425293 235.773270,861.214539 
	C234.048828,858.985657 231.333832,858.225769 229.111313,858.388794 
	C221.046005,858.980408 214.518356,854.286377 207.153778,852.719177 
	C196.572617,850.467468 188.642426,843.820435 180.219635,837.856018 
	C178.765518,836.826233 176.991852,836.061584 175.253281,835.651855 
	C167.611359,833.850708 163.916229,827.698242 159.850296,821.953979 
	C158.222458,819.654175 156.996704,817.038452 155.184952,814.909424 
	C153.544907,812.981934 152.775345,811.028931 152.978149,808.567627 
	C153.045410,807.751099 153.178085,806.700439 152.767380,806.120361 
	C147.590179,798.808044 147.194916,789.477905 142.499481,781.974548 
	C140.537292,778.838928 140.911743,774.915466 138.961700,771.684814 
	C137.692719,769.582520 137.068680,767.095764 135.033798,765.461914 
	C134.781952,765.259583 134.443130,764.943848 134.443542,764.681213 
	C134.457245,755.855469 127.795441,747.300720 133.363373,738.244263 
	C134.138184,736.984009 134.571274,735.449280 136.659546,735.063599 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M254.242950,859.230896 
	C241.620026,859.827759 229.892136,857.559570 218.906586,851.658691 
	C216.890808,850.575867 214.455856,849.918823 212.413193,849.582031 
	C199.586121,847.467102 190.494751,837.691650 178.668060,833.645203 
	C176.958069,833.060120 175.671936,831.579285 175.348419,829.106079 
	C176.107452,828.319519 176.623764,828.276672 177.504837,828.549316 
	C181.009903,828.783630 183.550049,830.480896 186.003906,832.094788 
	C192.529572,836.386841 201.365021,836.375122 206.533356,843.169556 
	C206.922348,843.680908 207.605286,844.020996 208.262253,844.107422 
	C216.580460,845.201782 223.495453,850.639526 231.481735,852.131653 
	C240.869659,853.885498 250.190338,857.809326 260.256500,854.177979 
	C264.476501,852.655579 269.481506,853.262329 274.107056,853.565308 
	C279.944305,853.947693 286.099152,853.361511 291.238586,851.912476 
	C297.583099,850.123596 304.706390,850.538025 310.518707,846.235535 
	C313.719971,843.865906 318.056793,843.551270 322.346680,844.536865 
	C322.564056,847.073975 321.247253,848.355286 319.445801,848.982422 
	C311.379608,851.790955 303.631653,855.474487 295.345520,857.680481 
	C291.533600,858.695312 287.491272,857.975586 283.365356,859.176514 
	C281.890137,859.318604 280.790192,859.623840 279.348450,859.971863 
	C278.615387,860.386536 278.153076,860.530945 277.305420,860.233887 
	C276.243134,860.015015 275.495087,860.010498 274.373047,860.005310 
	C273.794708,859.931213 273.590393,859.857727 273.098877,859.634949 
	C270.564240,859.088196 268.303436,857.870056 265.824829,859.813354 
	C264.946594,860.429626 264.289215,860.608582 263.278381,860.225220 
	C261.282776,860.004700 259.569580,860.000854 257.428192,859.997314 
	C256.109283,860.055115 255.247269,859.979736 254.242950,859.230896 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M345.738831,831.249268 
	C353.096863,826.931335 361.388306,827.145935 369.022980,825.007751 
	C376.207764,822.995483 383.238007,820.577515 390.339081,818.351379 
	C392.027893,817.822021 393.985931,817.658875 394.939575,815.359497 
	C394.743103,814.020142 395.366486,813.614624 396.522766,813.242188 
	C398.221771,813.354187 399.514740,813.573547 400.827820,814.736694 
	C400.866241,815.594910 400.676392,816.131409 400.255005,816.843140 
	C392.607758,821.812134 384.545624,825.218567 376.315491,828.333984 
	C368.557617,831.270630 360.494934,833.108826 352.369019,835.747070 
	C352.001678,836.251892 351.830536,836.435974 351.324554,836.793457 
	C349.925842,836.979431 348.861938,836.992004 347.399048,837.006958 
	C342.900085,836.958008 339.657196,840.849487 334.996094,839.356689 
	C333.543030,837.006409 334.469849,835.945251 336.550781,835.197632 
	C339.580414,834.109253 342.541473,832.828613 345.738831,831.249268 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M283.072327,859.354492 
	C285.716858,857.107971 288.902008,857.181030 292.021515,856.745667 
	C299.242065,855.738342 306.181213,853.570740 312.364349,849.702942 
	C315.328400,847.848816 319.531311,849.122009 321.827881,845.307983 
	C322.465057,844.445007 323.028168,844.036133 324.041840,843.699829 
	C325.555206,843.593384 326.606659,843.216797 327.859985,842.354370 
	C328.863281,841.964905 329.572052,841.876160 330.657623,841.878296 
	C332.938812,842.882446 335.522736,840.915466 337.064331,843.635620 
	C330.478882,846.736511 323.391083,848.560486 317.260803,851.527222 
	C310.174347,854.956604 301.737671,854.445557 295.612793,859.842224 
	C295.488403,859.951843 295.334442,860.162231 295.230743,860.141663 
	C291.227905,859.347168 287.123932,861.286804 283.072327,859.354492 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M351.334198,836.252563 
	C351.033722,834.597656 351.198456,833.319153 352.962067,833.059509 
	C369.402527,830.638977 384.205963,823.461792 399.656372,817.058594 
	C399.765930,817.814697 399.508453,818.610962 399.119629,819.695923 
	C392.428894,822.116089 386.741913,826.054565 380.527466,828.893921 
	C373.596710,832.060547 366.546478,834.893555 358.380402,835.017944 
	C356.126770,836.460449 354.230835,837.862000 351.334198,836.252563 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M337.353638,843.947388 
	C335.347443,841.757019 333.184174,844.857361 331.226074,843.219604 
	C330.282288,840.889160 332.077972,840.158325 333.430725,838.852661 
	C333.966309,838.648315 334.197083,838.616211 334.711487,838.788330 
	C338.109161,838.112854 341.422333,837.844116 344.087738,835.643738 
	C345.071167,834.831970 346.243195,834.884644 346.931946,836.673462 
	C345.239777,841.108887 340.693237,841.449829 337.353638,843.947388 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M273.684174,858.759033 
	C273.998199,859.168823 273.998810,859.335938 273.999237,859.753906 
	C270.525940,862.065613 266.989777,863.213684 263.100098,860.379272 
	C263.542145,859.825195 264.088287,859.641907 264.817200,859.229248 
	C267.736816,857.914001 270.582001,858.703125 273.684174,858.759033 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M253.961395,859.333496 
	C254.956619,859.283997 256.180328,857.796021 256.960632,859.726257 
	C256.465973,863.261719 254.002014,863.427246 251.225647,863.227966 
	C251.835678,861.768188 253.717682,861.447693 253.961395,859.333496 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M350.987183,836.230713 
	C353.129700,836.124084 355.225494,835.932556 357.622284,835.061401 
	C356.418457,837.553223 354.771881,839.953979 351.147247,837.259766 
	C350.989746,836.966919 350.986603,836.476135 350.987183,836.230713 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M277.041779,860.301270 
	C277.439026,860.014771 277.886871,860.010071 278.670685,860.010010 
	C278.896362,860.639771 278.823608,861.387878 278.044067,861.425842 
	C277.741241,861.440552 277.410461,860.880981 277.041779,860.301270 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M138.684174,733.052307 
	C138.902023,733.886047 138.618835,734.640076 137.330383,734.955750 
	C137.105743,734.118713 137.385483,733.362244 138.684174,733.052307 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M412.231873,811.691284 
	C411.949768,811.942261 411.482025,811.975098 411.248779,811.997437 
	C409.955780,812.024902 408.896027,812.030090 407.152344,811.793335 
	C406.069031,810.864258 405.954346,810.122864 406.156433,808.973633 
	C406.624908,807.896973 407.187073,807.396790 408.237244,806.896790 
	C409.818451,806.539001 411.060425,806.404968 412.665649,806.209351 
	C413.281250,806.322083 413.519653,806.406799 414.046204,806.739746 
	C415.248718,809.094055 414.026215,810.326416 412.231873,811.691284 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M401.341370,815.012268 
	C399.871490,814.889893 398.686890,814.721313 397.243774,814.305908 
	C397.395691,810.765137 399.616302,810.736023 402.487427,811.445801 
	C403.262665,812.127808 403.457031,812.655090 403.226074,813.637451 
	C402.551300,814.331543 402.088959,814.648804 401.341370,815.012268 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M403.359253,814.012573 
	C403.001648,813.558899 402.989655,813.103577 403.230164,812.035767 
	C404.454559,810.912354 405.413544,810.928345 406.657532,811.724731 
	C406.955933,811.955811 406.999481,812.003235 407.020752,812.028442 
	C406.345764,813.415527 405.215759,814.037842 403.359253,814.012573 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M150.936066,729.194519 
	C151.719604,728.971802 152.481232,728.981262 153.623047,728.994995 
	C155.612671,728.761597 156.074951,729.994263 156.260818,731.086304 
	C157.353378,737.505859 160.337509,743.309326 162.296326,749.441101 
	C167.697571,766.349121 173.911041,783.021545 181.286072,799.161377 
	C184.960648,807.203125 192.167343,812.314209 200.318695,815.953613 
	C211.097443,820.766052 221.818970,825.809814 232.722107,830.219666 
	C245.203842,835.268066 258.606293,837.970703 272.270935,835.744690 
	C278.677490,834.700989 285.153229,834.657837 291.535706,833.546082 
	C296.260071,832.723145 300.730591,831.039001 305.494415,830.216858 
	C317.081696,828.217407 328.412048,824.974609 339.762543,821.826782 
	C348.786987,819.324036 357.841125,816.850586 367.091370,814.988831 
	C376.094025,813.176819 385.346527,811.461243 393.904694,807.602966 
	C395.973724,806.670105 398.229889,805.801575 400.968079,806.746521 
	C402.089020,809.722351 400.056519,810.536011 398.245544,811.339233 
	C389.886658,815.046448 381.020599,817.155212 372.227356,819.493103 
	C361.264771,822.407654 349.999634,824.009277 339.293488,828.120667 
	C330.944824,831.326843 321.937988,832.496399 313.211609,834.513123 
	C310.301208,835.185730 307.322052,835.541321 304.476044,836.533691 
	C284.405701,843.531616 263.835327,843.958557 242.998367,841.315674 
	C235.063095,840.309082 228.451447,835.732361 220.955307,833.563477 
	C219.059067,833.014893 217.265427,832.056824 214.905746,831.551514 
	C213.978821,831.417786 213.458511,831.320862 212.590240,831.044495 
	C206.259888,826.260071 199.691650,822.676086 192.579468,820.136658 
	C190.202393,819.287842 188.314194,817.667480 185.784027,816.434692 
	C185.281464,816.138855 185.089325,816.008606 184.640549,815.647827 
	C182.907547,813.495911 181.516281,811.495544 179.192291,809.901733 
	C178.764847,809.527344 178.607742,809.360474 178.261139,808.904785 
	C176.561508,804.508301 174.325287,800.784363 171.761078,796.835144 
	C171.403107,796.350708 171.275513,796.143188 170.999893,795.597656 
	C167.983322,787.099487 166.209717,778.514709 162.061981,770.864258 
	C160.640778,768.242859 161.195648,764.875916 158.431534,762.490601 
	C158.103241,761.922180 157.993057,761.680481 157.782928,761.049438 
	C156.218307,754.090637 155.166153,747.466553 151.549393,741.488098 
	C149.624832,738.306824 148.528229,734.430054 150.654907,730.224915 
	C150.893692,729.892639 150.914154,729.426697 150.936066,729.194519 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M213.760742,830.363892 
	C219.955719,829.385071 225.170547,832.698547 229.790543,835.027710 
	C242.191849,841.279785 255.070755,840.872681 268.143707,840.968079 
	C277.000488,841.032715 285.981659,840.366333 294.240326,837.839661 
	C308.017303,833.624878 322.379395,831.866577 335.963135,827.335876 
	C352.299286,821.887146 369.295074,819.352173 385.734650,814.611145 
	C390.891083,813.124084 396.710846,811.911438 400.950592,807.375244 
	C401.374817,806.420898 401.940979,806.089905 402.876068,805.736938 
	C404.023560,805.531006 404.851593,805.394348 405.804626,804.685669 
	C406.230072,804.389038 406.404541,804.276611 406.847412,804.007690 
	C408.039917,803.397827 408.938232,802.903381 409.948029,802.002563 
	C410.353790,801.665344 410.527557,801.542786 410.991760,801.284973 
	C412.822388,800.991577 414.113068,800.243835 415.619751,799.201294 
	C418.496307,796.477539 421.966827,795.104797 424.808472,792.315674 
	C426.188995,791.443115 427.305115,790.785828 428.750000,790.118225 
	C429.549988,790.221008 429.939148,790.468140 430.370544,791.166138 
	C430.406219,794.064697 428.909485,795.828247 426.861725,797.629395 
	C424.725403,798.446045 422.867767,799.068665 421.652374,801.224548 
	C421.240997,801.595337 421.063477,801.729675 420.595367,802.028809 
	C418.559998,802.724731 417.272430,803.951843 415.653015,805.279663 
	C415.002960,805.699890 414.594910,805.856934 413.839233,805.967163 
	C411.936462,805.934998 410.834595,807.082092 409.240540,807.798950 
	C408.460114,808.187317 407.921570,808.377930 407.201630,808.795227 
	C406.085785,810.229370 404.573669,810.613953 403.204102,811.745972 
	C402.969238,811.955811 402.974487,811.969971 402.964417,811.967773 
	C400.952576,811.892029 399.031982,812.113464 397.206909,813.772949 
	C396.447357,814.226501 395.909485,814.394043 395.191040,814.786133 
	C388.637787,816.586670 382.258057,818.134705 375.894348,819.746094 
	C369.083832,821.470581 362.322937,823.405396 355.476898,824.970947 
	C348.388641,826.591858 341.377563,828.622314 334.772339,831.481689 
	C329.718994,833.669189 324.432617,833.516907 319.351227,834.888672 
	C311.370483,837.043091 303.549957,839.916565 295.590515,841.818848 
	C288.644714,843.478760 281.544037,845.282288 274.051575,845.273743 
	C266.806580,845.265503 259.448761,845.501892 252.204666,846.143982 
	C244.643845,846.814148 238.107254,842.261475 230.703369,842.875488 
	C226.956268,836.915649 218.787704,838.514038 214.267471,833.207520 
	C213.422516,832.319214 213.269424,831.553589 213.760742,830.363892 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M333.728577,838.701233 
	C334.012177,840.855957 332.069580,841.162170 331.157532,842.690735 
	C330.252014,842.982727 329.503174,842.992126 328.380005,843.005615 
	C327.354980,843.875671 326.227448,843.948181 325.204956,844.771973 
	C324.238708,844.986084 323.492157,844.989319 322.372253,844.992065 
	C317.667725,845.284241 313.152588,845.670776 309.718994,848.352844 
	C306.805023,850.628967 303.893188,851.193054 300.498962,851.031311 
	C299.520721,850.984741 298.400818,850.973206 297.562531,851.385742 
	C290.109772,855.053528 282.036133,855.114624 274.112579,854.872192 
	C264.802460,854.587463 255.785767,857.933838 246.569809,856.866089 
	C240.714722,856.187622 235.047455,854.023743 229.227737,852.870361 
	C222.015045,851.440918 216.200714,845.743042 208.321014,846.305603 
	C206.225967,846.455200 204.792374,844.541321 203.616699,842.928833 
	C201.032623,839.384827 197.633560,837.505371 193.280106,836.923401 
	C189.631866,836.435669 187.308228,833.180725 183.850830,831.888611 
	C181.791916,831.119202 179.674850,830.207458 177.260712,829.251587 
	C176.823608,828.528687 176.935410,828.146545 177.667984,827.921875 
	C180.519196,828.110474 182.686600,829.132874 184.924698,830.253052 
	C193.405746,834.497681 202.045425,838.398254 211.614670,840.981323 
	C212.814301,841.241028 213.614441,841.493225 214.703918,841.878784 
	C223.624954,847.528564 233.079514,850.481018 243.642700,851.048706 
	C244.443985,851.004089 244.888000,851.003296 245.665009,851.003723 
	C250.393311,853.764709 255.172348,852.201965 259.593353,851.628357 
	C267.824921,850.560120 276.111145,850.723816 284.338776,849.772949 
	C290.250183,849.089661 296.339966,848.792908 301.828125,846.745422 
	C308.325226,844.321533 314.924652,842.156372 321.402222,839.665344 
	C325.182556,838.211487 329.260406,837.121094 333.728577,838.701233 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M213.664124,830.014893 
	C213.996292,830.767517 213.998077,831.510681 213.999619,832.625671 
	C210.021423,833.657715 206.206635,832.621338 203.150818,830.416870 
	C195.660416,825.013489 186.188873,823.229675 179.363403,816.442139 
	C175.494629,812.594910 172.940247,808.381042 170.601227,803.614990 
	C166.207565,794.662170 163.642151,785.016235 159.538300,775.935669 
	C157.503738,771.433838 153.984192,766.967834 154.916473,761.284851 
	C155.285156,759.037476 152.672775,758.045105 152.024170,756.312805 
	C149.817993,750.420410 147.561874,744.480347 146.216873,738.362427 
	C145.621796,735.655640 145.935669,732.001953 149.608429,730.032715 
	C149.029907,739.428284 155.388077,746.919250 156.980194,755.713745 
	C157.260605,757.262756 157.820328,758.899109 158.750259,760.704834 
	C158.992905,761.002930 159.006012,761.493958 159.009277,761.739502 
	C162.849258,771.296021 166.701096,780.601685 169.423950,790.321472 
	C169.921783,792.098572 170.881607,793.674255 171.788834,795.680664 
	C172.010162,795.992737 172.000000,796.000000 172.005371,795.995056 
	C174.078278,800.206238 175.842194,804.579834 178.739029,808.750122 
	C178.981491,809.008789 178.999390,809.001221 178.994751,809.009949 
	C180.589325,811.104736 182.136917,813.242554 184.762146,814.768799 
	C184.999390,815.000000 185.000000,815.000000 185.000305,815.000000 
	C188.624191,819.410889 194.550247,819.846558 198.856079,823.000671 
	C202.752029,825.854614 207.573196,826.754517 211.741257,829.747498 
	C212.452271,829.995300 212.893005,830.000305 213.664124,830.014893 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M334.253418,839.003784 
	C324.827179,839.092590 316.497162,842.395142 308.227936,845.872986 
	C301.966858,848.506165 295.484039,850.546570 288.735352,850.879517 
	C282.286865,851.197693 275.799683,853.002319 269.595612,852.516235 
	C263.542236,852.041992 258.060394,853.691162 252.301346,853.971863 
	C250.005966,854.083679 247.571228,854.230469 246.082352,851.371338 
	C259.115692,849.727417 272.385529,849.909851 285.390472,848.221619 
	C292.479584,847.301270 299.862671,846.574402 306.919006,843.516052 
	C315.576447,839.763733 324.726013,836.763123 334.126434,834.895630 
	C337.967529,834.132568 341.165161,831.319214 345.609528,831.009155 
	C344.053101,834.855591 339.857666,834.815552 336.578217,836.274170 
	C335.404999,836.796021 335.116425,837.322815 335.003174,838.661743 
	C334.995087,838.992676 334.500641,839.000244 334.253418,839.003784 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M243.796478,850.671997 
	C243.795898,852.392517 242.905731,853.206116 241.601624,852.921509 
	C232.933258,851.029846 224.170273,849.379578 216.396332,844.755493 
	C215.777634,844.387390 215.521301,843.410156 215.044922,842.364136 
	C221.371384,842.266907 226.821289,845.451233 232.304474,848.079407 
	C236.052856,849.876038 240.348404,847.255493 243.796478,850.671997 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M158.995865,760.587036 
	C154.918915,757.454468 148.417526,736.495605 149.759567,731.237061 
	C149.800201,731.077881 149.840042,730.918396 149.928131,730.363403 
	C149.976013,729.967651 150.436493,729.936829 150.665100,729.914734 
	C150.990646,734.898560 152.168869,739.336548 154.582581,744.007874 
	C157.057800,748.798340 158.722946,754.455078 158.995865,760.587036 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M404.172302,806.728638 
	C403.208008,806.945068 402.479889,806.972351 401.388428,807.015137 
	C393.594849,808.125183 387.160065,812.322693 379.764343,813.682739 
	C372.073883,815.097107 364.480804,817.074585 356.887665,818.975647 
	C348.586365,821.054077 340.200256,822.925720 332.095581,825.622253 
	C323.074097,828.623840 313.657593,829.614746 304.547241,832.014526 
	C294.436523,834.677795 284.114990,835.921021 273.791870,837.237183 
	C264.543640,838.416382 255.439117,838.773132 246.361603,835.523865 
	C242.085419,833.993347 237.134415,834.633240 232.885742,832.473877 
	C221.240311,826.555359 208.984451,821.997559 197.151306,816.544067 
	C186.539795,811.653625 179.455353,802.829468 176.033661,791.482971 
	C173.380310,782.684326 169.160263,774.511902 166.392532,765.703369 
	C163.903702,757.782410 160.798019,750.042908 158.364929,742.030884 
	C157.124420,737.945862 154.873581,734.054138 154.271057,729.287964 
	C154.015152,728.262146 154.027069,727.525024 154.052307,726.420776 
	C156.666351,725.431030 155.760971,723.114014 156.464081,721.006470 
	C156.900864,720.501953 157.148178,720.473755 157.768311,720.425110 
	C159.624695,721.455872 160.182877,722.995178 160.328842,724.709290 
	C161.114410,733.934448 163.693939,742.597473 167.897537,750.870483 
	C170.766266,756.516418 171.929337,762.852112 174.260651,768.720947 
	C178.334137,778.975647 182.189209,789.330566 187.170700,799.203796 
	C191.012146,806.817383 197.827560,810.634888 205.767181,813.524780 
	C214.231216,816.407166 221.671173,820.711426 229.541168,824.042480 
	C234.232666,826.028320 238.715118,829.226379 244.691910,827.814575 
	C245.313431,827.981384 245.554916,828.073547 246.126801,828.367554 
	C251.901672,832.506836 258.070496,832.424377 264.727234,831.711914 
	C265.652161,831.565918 266.190430,831.533875 267.146729,831.530029 
	C275.785309,831.696228 283.981445,831.232239 292.169342,830.531677 
	C293.319000,830.433350 294.482361,830.488708 295.706451,829.313965 
	C296.023682,829.029236 296.497589,828.983582 296.734192,828.957642 
	C297.360199,828.631348 297.784424,828.393311 298.606140,828.187012 
	C303.267090,828.556641 307.401367,827.729675 311.739563,826.237305 
	C312.441437,825.977478 312.882416,825.958435 313.654236,825.927856 
	C315.437378,826.123108 316.645966,825.712524 317.796509,824.289917 
	C318.773499,824.028931 319.496704,823.990112 320.581787,823.936523 
	C327.227448,821.768005 333.866791,821.025024 340.598511,819.020447 
	C341.425415,818.954956 341.854431,818.938843 342.604858,818.902100 
	C343.478424,818.453918 344.009644,818.000061 344.853088,817.379761 
	C345.556061,817.170837 345.968231,817.155212 346.689667,817.131958 
	C350.202942,816.306213 353.602600,816.217102 356.865387,814.379517 
	C357.561340,814.178650 357.971436,814.151245 358.689575,814.113037 
	C359.874786,813.997314 360.779755,814.021606 361.865143,813.379150 
	C362.863007,813.165466 363.572998,813.119385 364.638916,813.074463 
	C365.591187,813.014526 366.143280,812.832703 366.882568,812.321777 
	C381.746002,810.110840 395.954193,806.622864 409.662231,800.070374 
	C410.422607,799.902039 410.869568,799.915710 411.651581,799.945007 
	C412.453491,800.675171 412.063202,801.157837 411.293396,801.820068 
	C411.044922,802.035095 411.002014,802.014404 411.016907,802.033447 
	C409.967560,803.076233 408.562622,803.610535 407.214233,804.767883 
	C406.987244,804.983582 407.000000,805.000000 406.993652,804.993286 
	C406.242371,805.694641 405.173981,805.865540 404.172302,806.728638 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M156.575531,720.477783 
	C156.653534,720.530090 156.536591,720.659485 156.494171,720.666504 
	C156.451767,720.673584 156.358231,720.624146 156.349854,720.577087 
	C156.341492,720.530029 156.497528,720.425476 156.575531,720.477783 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M206.220215,814.714111 
	C195.131210,812.063354 187.412247,805.571777 183.149521,795.032654 
	C180.289047,787.960693 178.192719,780.579529 174.134323,773.980530 
	C172.799438,771.809937 172.374634,769.025269 171.779007,766.464355 
	C169.828812,758.079224 165.654755,750.556274 162.551453,742.625305 
	C159.824326,735.655884 159.287033,728.367310 158.133652,720.776001 
	C159.628159,719.802795 161.872574,720.271545 161.947845,717.323242 
	C163.204605,716.747314 163.832703,715.912903 164.251068,714.425293 
	C164.395950,714.119995 164.767227,714.162354 164.950333,714.202148 
	C165.101852,715.552002 165.070251,716.862061 165.047562,718.567627 
	C165.768005,720.615845 164.632706,721.820129 163.984985,723.104553 
	C163.306717,724.449585 163.302002,725.666443 164.335480,726.770447 
	C167.517502,730.169495 167.921646,734.544556 168.410934,738.806335 
	C169.165176,745.375854 171.569595,751.315125 174.784348,756.983765 
	C177.026337,760.937195 178.620865,765.059265 178.564056,770.044250 
	C178.720398,773.776917 179.778168,776.786621 182.398941,779.525757 
	C183.076859,780.676208 183.200668,781.614624 183.230164,782.919128 
	C182.958862,785.600525 183.640915,787.611816 185.480225,789.578491 
	C185.884644,790.273682 186.056961,790.700500 186.265625,791.481934 
	C187.402985,798.876160 192.378983,802.650146 198.394745,805.971985 
	C198.906586,806.259644 199.100464,806.394287 199.541351,806.783020 
	C200.669022,809.156738 202.464050,810.360352 204.323990,811.457642 
	C205.506149,812.155029 206.673050,812.776611 206.220215,814.714111 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M173.231079,828.686890 
	C169.540131,829.678894 168.646133,826.327454 166.839081,824.713074 
	C164.619629,822.730347 163.102142,819.926636 161.444031,817.372131 
	C160.940994,816.597107 161.752121,815.851013 162.705658,815.156982 
	C166.357407,815.401245 167.595352,818.374268 169.786728,820.725952 
	C169.996780,821.002441 170.002533,821.497559 170.005539,821.745117 
	C171.647018,823.858032 173.905807,825.388733 173.231079,828.686890 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M169.990677,820.636719 
	C167.185852,819.889404 165.082199,817.956604 163.251511,815.202332 
	C162.994553,813.812988 162.182571,813.156982 161.185059,812.250671 
	C161.162720,810.509705 161.325439,809.019470 161.749146,807.263367 
	C163.779984,811.955750 167.935333,815.480774 169.990677,820.636719 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M173.338852,828.994873 
	C173.039902,826.411255 169.743286,825.416748 170.013031,822.356567 
	C171.173935,822.160095 172.339340,822.327515 173.753601,822.747437 
	C175.465256,824.489258 176.739365,826.032715 175.261749,828.634766 
	C175.002441,828.978027 175.000183,828.992981 174.999786,829.001343 
	C174.556564,829.005554 174.113754,829.001282 173.338852,828.994873 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M175.335327,828.984253 
	C174.703537,827.236511 174.404633,825.494995 174.054077,823.376709 
	C176.655823,823.203857 177.220520,825.308044 177.956314,827.645996 
	C177.696945,828.123169 177.443787,828.317200 177.120651,828.786255 
	C176.556122,828.993591 176.112167,828.992004 175.335327,828.984253 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M218.256836,418.160828 
	C216.806824,416.630646 215.222580,417.104279 213.373840,416.987244 
	C213.174362,416.438049 213.357819,415.898071 213.769897,415.178131 
	C215.151001,414.030914 215.571777,412.535126 216.782242,411.226990 
	C218.426956,410.700989 219.850845,410.398315 221.637665,410.047180 
	C224.630371,411.153229 227.582291,409.576447 230.374939,411.267212 
	C231.033600,413.616302 229.433350,414.555817 228.079437,416.019867 
	C227.650269,416.435364 227.466980,416.589600 226.986389,416.945923 
	C226.273621,417.387451 225.846329,417.603302 225.072662,417.926056 
	C223.131668,418.524323 221.521057,418.975189 219.467926,418.858215 
	C218.899261,418.675568 218.685303,418.563995 218.256836,418.160828 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M230.712006,413.063171 
	C229.645554,411.229889 228.414246,411.000519 226.782822,411.582733 
	C225.151978,412.164764 223.580887,412.179260 222.189270,410.316650 
	C225.712494,408.962006 229.434540,408.014679 233.671417,408.855896 
	C234.889542,409.990143 234.568909,410.956726 233.665466,412.205109 
	C232.693283,412.956696 231.931519,413.168915 230.712006,413.063171 
z"/>
<path fill="#C1C0C2" opacity="1.000000" stroke="none" 
	d="
M233.330643,412.462158 
	C233.308380,411.408142 233.613037,410.546234 233.958633,409.343384 
	C235.099701,408.671600 236.199814,408.340790 237.649246,408.004364 
	C238.522537,407.802795 238.973358,407.912384 239.528610,408.660400 
	C239.822327,409.503815 239.817535,410.008667 239.521667,410.857178 
	C239.164627,411.378052 238.978485,411.554230 238.458008,411.918610 
	C236.651123,412.421295 235.216827,413.047607 233.330643,412.462158 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M239.204147,411.114563 
	C238.996902,410.400360 239.002884,410.010101 239.018555,409.327209 
	C239.522766,408.444031 239.760468,407.641022 240.778748,407.159760 
	C241.782074,407.054718 242.507782,407.044098 243.595184,407.033813 
	C244.017548,407.355652 244.078217,407.677124 244.232635,408.480255 
	C244.528030,410.122742 243.977295,410.930054 242.741257,411.740356 
	C241.331070,412.171600 240.333710,412.066406 239.204147,411.114563 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M216.651093,411.053284 
	C217.020660,412.765259 216.794144,414.382599 214.350250,414.947754 
	C213.980209,413.235962 214.204773,411.617249 216.651093,411.053284 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M240.842224,406.822266 
	C240.917267,407.935120 240.605377,408.672943 239.336166,408.974731 
	C238.894440,408.722168 238.694305,408.460632 238.213196,408.124329 
	C238.271164,406.407013 238.991058,405.643677 240.842224,406.822266 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M241.009430,400.348083 
	C242.393951,400.137146 242.985291,400.964233 242.990906,402.650879 
	C241.604691,402.863556 241.014755,402.035217 241.009430,400.348083 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M208.725250,417.072357 
	C208.898193,417.905487 208.605850,418.663757 207.303986,418.928619 
	C207.109100,418.079803 207.410995,417.330078 208.725250,417.072357 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M217.710480,418.064880 
	C218.244965,417.928009 218.447632,417.986725 218.944214,418.162781 
	C219.496185,418.746307 219.566956,419.239594 219.266876,420.097900 
	C216.796463,422.101318 214.381775,423.500214 211.185822,424.090759 
	C210.273560,423.996338 209.793930,423.802612 209.122162,423.190002 
	C210.080902,418.325897 214.522827,419.752106 217.710480,418.064880 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M204.030075,426.306335 
	C204.300507,425.195801 204.631378,424.454895 205.014359,423.416992 
	C205.784805,423.061584 206.503128,423.003143 207.582581,422.942871 
	C208.315247,423.286499 208.595810,423.694641 208.843445,424.538513 
	C208.825150,426.264587 208.242691,427.335236 206.767517,428.229889 
	C205.276337,428.289429 204.585419,427.618408 204.030075,426.306335 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M208.363098,425.258240 
	C208.004440,424.575531 207.989990,424.109039 207.959610,423.291809 
	C207.943695,422.941071 208.416550,422.908295 208.652100,422.892181 
	C209.360565,422.911072 209.833481,422.946106 210.661255,422.995850 
	C211.320648,424.838379 210.306519,425.430573 208.363098,425.258240 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M218.997772,626.613037 
	C217.943420,626.992859 216.868744,626.997925 215.391479,627.005127 
	C214.826447,627.146790 214.664047,627.286194 214.180084,627.416870 
	C212.869827,627.066650 211.995483,626.362976 210.536255,626.469604 
	C209.288971,626.443542 208.405777,626.248047 207.229935,625.785828 
	C202.230911,620.372192 196.653885,616.332458 189.885712,613.371216 
	C188.367859,612.962585 187.258438,612.483826 185.873627,611.720276 
	C184.445267,610.297729 183.292053,608.834167 181.599533,608.754150 
	C172.633438,608.330566 168.979889,601.348328 164.796997,595.157349 
	C163.651337,593.461670 162.495544,592.011292 160.501221,590.717957 
	C158.300766,588.513611 157.369308,586.168823 157.266586,583.421570 
	C157.124634,579.626099 155.369751,576.239075 154.193268,572.322021 
	C153.280426,568.610596 152.836624,565.140747 151.762314,561.878540 
	C148.276428,551.293640 146.315994,540.325745 143.393539,529.602600 
	C142.190140,525.187134 143.061386,520.656250 143.757950,516.365540 
	C144.112274,514.183044 144.493332,512.800720 142.876892,511.224487 
	C142.014481,510.383514 142.134354,509.098846 143.132370,508.673309 
	C146.525497,507.226685 146.273209,504.298920 146.826126,501.382019 
	C147.810394,496.189545 150.581131,491.627319 153.367615,487.210602 
	C155.209106,484.291748 156.179428,480.883301 158.713593,478.328033 
	C159.789764,477.242889 160.534256,475.985718 162.616623,475.990906 
	C163.764145,478.029144 162.295700,479.391510 161.468597,480.950348 
	C154.834335,493.453918 149.108231,506.361145 149.873184,520.854736 
	C150.349136,529.872314 152.253250,538.812012 154.456085,548.005127 
	C153.873611,551.395935 155.500488,554.036072 156.603745,556.422241 
	C159.515717,562.720154 159.187485,570.078247 163.505981,576.209717 
	C163.940369,576.950989 164.127075,577.401794 164.335480,578.223694 
	C165.224396,585.413879 167.838379,591.416138 172.548843,596.509888 
	C174.159119,598.251160 175.642593,599.960754 178.703369,599.283142 
	C179.514832,599.543762 179.866318,599.860413 180.064056,600.639893 
	C180.175751,603.266541 181.727264,605.145935 183.465210,605.510376 
	C188.208405,606.504883 191.660797,610.240051 196.384689,611.073364 
	C199.050339,611.543518 200.490555,613.861084 202.386398,615.361389 
	C207.114227,619.102966 211.800156,622.735596 217.783707,624.246643 
	C218.733139,624.486450 218.881378,625.390930 218.997772,626.613037 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M153.264801,549.287903 
	C150.608475,538.062561 147.867554,527.096802 148.184982,515.880188 
	C148.392441,508.550232 150.283981,501.292572 153.352707,494.430542 
	C156.102432,488.281891 158.543320,482.002411 162.836426,476.288788 
	C164.884613,472.861084 166.785934,469.741577 168.855927,466.320618 
	C169.542542,465.474792 170.064087,464.933807 170.762482,464.172485 
	C170.948196,463.960571 170.971603,463.480865 170.989792,463.241394 
	C171.892059,462.003479 172.000641,460.647614 172.840683,459.297668 
	C175.904083,457.721161 176.536789,454.526489 178.774353,452.242462 
	C179.730743,451.267609 179.868149,450.173309 180.768372,449.202393 
	C181.553604,448.486176 182.135361,447.994049 182.850800,447.243774 
	C183.793259,446.255066 183.788712,445.083160 184.723221,444.115204 
	C185.421722,443.939880 185.852219,443.924774 186.605286,443.927887 
	C189.314667,447.188843 186.223450,448.862701 184.650421,451.300781 
	C183.255722,452.590912 181.763260,453.333252 181.396210,455.403442 
	C180.755188,457.732300 179.914902,459.594421 178.277267,461.403229 
	C177.390244,462.211853 176.547440,462.570251 175.950653,463.666626 
	C173.513641,466.732605 172.650238,470.319763 170.271408,473.414978 
	C169.393082,474.241608 168.550751,474.635956 168.042801,475.824707 
	C167.732407,476.317017 167.597092,476.510437 167.223602,476.972260 
	C161.223053,484.750061 158.858292,493.616058 156.611359,502.589752 
	C155.109528,508.587708 156.034210,514.843445 154.156311,521.107605 
	C153.622711,522.919983 151.541473,524.321106 154.125183,526.146851 
	C155.538757,527.497681 156.102646,528.833618 155.817596,530.830444 
	C154.756256,532.856323 154.668945,534.393311 155.490433,536.277039 
	C157.440033,540.747314 157.074921,545.855225 159.135056,550.359680 
	C159.664810,551.518005 159.176651,552.943176 157.877228,554.192566 
	C154.288727,554.397583 154.495453,551.489319 153.264801,549.287903 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M153.865570,572.311157 
	C158.230499,575.072998 157.554703,580.156860 158.831070,584.381714 
	C159.359055,586.129333 159.862015,587.764893 160.924011,589.636597 
	C165.328934,594.092590 167.289871,599.961487 171.788895,604.013184 
	C174.345108,606.315308 176.626785,608.402649 180.294235,608.016663 
	C182.513367,607.782959 183.944016,609.248291 185.733490,610.746338 
	C187.121597,611.234375 188.240616,611.476135 189.677368,611.860107 
	C194.865036,617.006714 201.300369,619.945923 206.751358,624.753662 
	C208.063217,624.999634 209.124252,625.006592 210.583160,625.014099 
	C211.640213,625.872070 212.767029,625.950317 213.784607,626.785461 
	C213.999390,627.002441 214.494263,627.005737 214.741562,627.006531 
	C215.491440,629.801453 218.013763,629.822021 219.954041,630.437012 
	C223.139801,631.446838 225.533539,633.229248 226.943344,636.657715 
	C218.089600,634.630859 209.952332,630.284119 201.354858,627.131775 
	C199.738281,626.539062 198.696869,625.237000 197.842773,623.760925 
	C196.981857,622.273071 195.808990,621.003906 193.957565,621.070923 
	C191.381561,621.164001 189.828384,620.388550 189.851349,617.510193 
	C189.859741,616.457458 188.971710,615.164062 188.147415,615.376770 
	C183.607651,616.548401 180.693680,611.791077 176.102081,612.402344 
	C174.148697,612.662476 171.843048,610.276672 169.349335,609.045410 
	C163.328232,601.914612 158.984375,593.863281 156.169861,585.472900 
	C154.898926,581.684082 153.399307,577.118530 153.865570,572.311157 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M166.728516,476.295959 
	C166.975067,475.982330 167.024185,475.522339 167.041870,475.291687 
	C167.534668,474.473999 168.069748,473.942352 168.784348,473.191589 
	C171.421677,470.156616 171.801071,466.128021 174.772797,463.240601 
	C175.523041,462.455505 176.071045,461.940887 176.789230,461.199005 
	C178.492096,459.542114 179.176498,457.621033 180.025452,455.418396 
	C181.019302,453.947235 182.096466,452.919098 182.844986,451.278473 
	C184.363541,448.918488 186.918884,447.558350 186.967224,444.310822 
	C186.927887,443.946106 187.059906,443.548065 187.109192,443.344269 
	C190.283615,439.323456 193.806183,435.874786 197.319626,432.432251 
	C198.416260,431.357758 199.630859,429.927979 201.908630,430.668213 
	C203.253021,432.685028 202.319077,434.178894 201.127075,435.321106 
	C191.715347,444.339539 186.084290,455.894684 179.724838,466.964508 
	C179.393616,467.541077 178.967285,468.055389 178.336761,468.842346 
	C177.579575,469.791779 176.820038,470.298340 176.391129,471.456299 
	C176.133926,472.169891 175.936371,472.555328 175.507263,473.181610 
	C173.698410,474.550446 172.252350,475.764008 172.451538,478.345154 
	C172.423050,480.376282 172.015640,481.930023 170.556366,483.433105 
	C169.109909,484.399536 168.316956,485.390442 168.137329,487.183899 
	C167.949463,487.742737 167.858566,487.964294 167.588226,488.503052 
	C165.907898,490.657166 165.376434,492.948151 164.530640,495.082581 
	C163.760437,497.026337 162.848099,498.694336 160.067444,498.724182 
	C157.420410,497.343323 157.719299,495.287750 158.506958,493.451050 
	C160.955261,487.742004 162.849396,481.759766 166.728516,476.295959 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M202.460709,431.270142 
	C197.982071,431.962006 196.310623,435.821259 193.383728,438.140442 
	C191.449509,439.673065 190.215317,442.010162 187.489685,443.083527 
	C187.362671,438.002075 192.324402,436.332489 194.879196,432.925537 
	C196.964188,430.145111 200.486404,428.442444 203.670502,426.099335 
	C204.539612,426.312317 205.109604,426.687897 205.840393,427.263885 
	C207.029678,430.425568 205.271576,431.208801 202.460709,431.270142 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M184.766327,443.753723 
	C184.984406,445.113190 184.568878,446.059326 183.275360,446.843201 
	C183.418564,445.802734 181.913513,443.740479 184.766327,443.753723 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M172.755859,459.212250 
	C173.041977,460.461304 172.907959,461.807343 171.306015,462.839844 
	C170.967300,461.581696 171.061264,460.214325 172.755859,459.212250 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M180.638641,449.047974 
	C180.966125,450.127411 180.543243,451.059357 179.267395,451.838806 
	C179.039276,450.848938 178.918976,449.634003 180.638641,449.047974 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M170.638428,464.022888 
	C170.910110,464.872009 170.630905,465.622223 169.337036,465.961060 
	C169.083359,465.121063 169.355255,464.366638 170.638428,464.022888 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M273.002289,663.390259 
	C277.391174,663.834534 280.841095,666.506042 284.681854,668.526001 
	C290.906067,671.799316 296.856659,675.510681 303.022644,678.854797 
	C303.747284,679.247742 304.548187,679.444580 304.932190,680.656433 
	C297.086700,679.724060 290.957916,674.460754 283.807068,671.469360 
	C279.642792,669.727417 274.856232,668.772400 273.002289,663.390259 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M245.050095,648.370667 
	C254.170410,650.275574 262.618988,653.841003 268.934479,661.670410 
	C262.680267,662.265320 257.682526,658.540833 253.964844,654.652161 
	C251.219208,651.780334 246.039886,653.440796 245.050095,648.370667 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M306.061676,681.339783 
	C311.948822,681.184570 315.724731,686.003113 320.835388,688.069946 
	C323.978149,689.340942 327.988800,689.667603 329.944946,693.644714 
	C321.516541,690.904907 312.409393,689.022339 306.061676,681.339783 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M228.064804,638.350769 
	C235.268646,637.155701 238.430283,642.466492 241.964294,647.640991 
	C236.762405,645.820679 231.915512,643.048767 228.064804,638.350769 
z"/>
<path fill="#039EBD" opacity="1.000000" stroke="none" 
	d="
M186.978394,754.630188 
	C183.572495,749.273865 181.832474,742.939758 181.051178,736.365723 
	C180.790848,734.175171 180.821457,731.992859 179.122986,730.407043 
	C174.018097,725.640503 175.647110,718.956787 174.176788,713.168274 
	C173.745956,711.471985 175.356705,711.108643 176.778778,710.948364 
	C182.851471,710.263489 188.650589,707.770447 194.845932,708.024414 
	C197.660690,708.139709 199.806717,706.415344 202.343338,705.773010 
	C208.308533,704.262573 214.538651,704.472595 220.369781,702.622681 
	C224.782547,701.222839 226.279282,702.888245 226.052277,707.480042 
	C218.804611,715.549561 212.379013,723.858582 206.523453,732.637390 
	C204.632095,735.473083 200.940628,735.934143 198.352142,738.019348 
	C194.356720,741.237671 192.557281,746.403442 187.900940,749.050903 
	C186.241302,749.994446 187.170135,752.437134 186.978394,754.630188 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M182.144440,784.311401 
	C182.006699,782.934753 182.000122,781.870178 181.993500,780.406494 
	C179.848389,777.513550 178.834320,774.419495 177.249222,771.248901 
	C178.024292,763.810303 173.598663,758.349365 170.723297,752.567017 
	C167.753006,746.593872 167.050125,740.286316 166.050674,733.959778 
	C165.785004,732.278015 165.783829,730.224426 164.470963,729.376099 
	C158.982239,725.829773 161.315521,722.746277 164.880554,719.264343 
	C167.076691,719.373718 166.979568,720.950928 166.990082,722.470154 
	C167.002716,724.296814 167.743500,726.031860 167.989258,727.729065 
	C169.448761,737.808594 174.245880,746.786377 177.347870,756.283569 
	C181.281128,768.325684 183.867645,780.905579 189.851669,792.246582 
	C191.590240,795.541504 194.037231,798.067444 197.485321,799.745605 
	C202.268234,802.073364 206.294052,805.225586 209.931747,809.357544 
	C212.430603,812.195862 216.949921,813.535095 220.811676,814.786621 
	C223.043900,815.510010 224.703247,816.347961 225.945557,818.625549 
	C225.039948,820.196533 223.661438,820.341003 222.398987,819.838806 
	C215.028015,816.906738 207.768112,813.724731 200.977829,809.576172 
	C200.241928,809.126587 199.982651,808.267578 199.252655,807.301147 
	C198.995850,807.009094 199.000000,807.000000 199.001816,806.996338 
	C193.923080,802.701965 187.490921,799.673340 185.250824,792.251709 
	C185.010452,791.550293 185.002289,791.110657 184.990814,790.341187 
	C183.575928,788.470947 183.658218,786.178589 182.144440,784.311401 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M199.005615,807.378296 
	C206.372665,812.054199 215.144058,814.264465 222.826691,818.698792 
	C223.471375,819.070923 224.425980,818.906128 225.629410,818.980164 
	C226.457474,818.983398 226.892715,818.997314 227.654709,819.017212 
	C229.299515,820.338074 231.061188,820.956482 232.864166,822.130310 
	C234.003479,822.854919 234.534149,823.959839 236.095535,823.846069 
	C237.062714,823.854858 237.698593,823.949951 238.612900,824.283936 
	C239.740234,825.598083 240.742523,826.273987 242.556519,825.477783 
	C243.611923,825.192383 244.307938,825.231018 245.233704,825.866333 
	C245.822052,826.887451 245.809952,827.628662 245.238647,828.677490 
	C235.553207,831.375854 228.789261,824.558960 220.876602,821.785217 
	C215.976562,820.067505 211.439163,817.315247 206.371262,815.007568 
	C205.798233,812.611572 203.357147,812.889893 202.087143,811.762268 
	C200.770523,810.593262 198.901611,809.974182 199.005615,807.378296 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M295.621277,829.003296 
	C296.147491,831.513123 294.597809,831.710144 292.569519,832.073730 
	C284.383636,833.541016 276.147522,832.814514 267.465515,832.996216 
	C265.944702,829.398438 268.742798,829.851685 270.697510,829.534912 
	C273.243256,829.122498 275.964355,830.245178 278.711548,828.407471 
	C282.039703,828.118591 285.042206,828.093506 288.494324,828.073120 
	C289.622681,828.698120 290.410309,829.022522 291.669189,828.947021 
	C293.071625,828.948303 294.145233,828.962830 295.621277,829.003296 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M265.218872,832.649170 
	C258.918762,834.837463 253.075455,832.966736 247.199570,831.783447 
	C246.317734,831.605957 246.107590,830.645752 245.986908,829.414062 
	C246.203049,828.230469 246.690765,827.634033 247.710526,827.153687 
	C249.060913,827.060669 250.126709,827.054016 251.592163,827.050354 
	C252.461411,827.075806 252.907486,827.190063 253.609848,827.601257 
	C255.645462,830.477478 258.392883,829.260925 261.118103,829.553711 
	C262.248871,829.516235 262.989563,829.651184 264.069092,830.075684 
	C265.110474,830.766174 265.493896,831.427734 265.218872,832.649170 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M265.335388,832.995605 
	C264.818695,832.444763 264.634216,831.898010 264.219177,831.168274 
	C263.158783,829.988831 263.482544,829.128906 264.656982,828.185181 
	C269.374695,828.048889 273.738678,828.055298 278.569946,828.102722 
	C279.086090,831.030945 277.075531,830.836182 275.044220,831.058350 
	C272.430481,831.344238 269.509277,829.801575 267.108582,832.608704 
	C266.553741,832.992737 266.110687,832.996155 265.335388,832.995605 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M340.741211,818.745239 
	C335.045929,822.351685 328.444489,823.240540 321.374451,823.953369 
	C322.901367,820.580627 326.697449,821.591980 329.566467,820.511108 
	C332.972961,819.227844 336.826355,819.130737 340.741211,818.745239 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M233.211761,823.228027 
	C230.564102,823.094727 228.867126,822.089111 228.033813,819.371216 
	C232.152649,820.313538 237.339310,819.270691 239.980988,824.590332 
	C239.875000,825.099182 239.706665,825.236267 239.262436,825.200378 
	C238.239792,825.021179 237.493057,825.015015 236.372925,825.009094 
	C234.872986,824.931274 234.178070,824.145508 233.211761,823.228027 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M311.560455,825.981079 
	C308.928986,830.970764 303.808960,828.753418 299.271362,829.288025 
	C298.617065,828.648865 298.478363,828.192566 298.824402,827.375305 
	C303.085541,826.713623 307.102997,826.339661 311.560455,825.981079 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M264.718719,827.767212 
	C264.693787,828.815063 264.376831,829.587646 264.024231,830.672729 
	C263.244873,830.985168 262.501160,830.984985 261.385406,830.982666 
	C258.877960,829.088379 255.718765,830.268738 253.270676,828.250793 
	C252.697372,827.888428 252.445190,827.703369 252.115814,827.253967 
	C256.218079,824.894409 260.272430,827.610352 264.718719,827.767212 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M198.870422,793.599731 
	C201.027267,792.375183 202.421982,794.369080 204.602295,795.013184 
	C206.274887,796.486694 209.441238,796.010864 209.088409,799.489380 
	C205.171967,798.813843 202.256729,796.060852 198.870422,793.599731 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M209.308838,800.179565 
	C214.569229,799.271790 217.221817,803.743103 220.983109,806.627808 
	C216.550568,805.933411 213.212326,802.839783 209.308838,800.179565 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M356.752441,814.192749 
	C354.672485,817.409424 351.042999,817.320374 347.265137,817.346313 
	C349.634155,814.608276 353.298401,815.361023 356.752441,814.192749 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M317.715820,824.072754 
	C317.976959,826.561340 316.955566,827.626343 314.218018,826.186462 
	C314.860992,824.830261 316.077942,824.374146 317.715820,824.072754 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M247.882782,826.843384 
	C247.889862,827.959534 247.558578,828.679016 246.275009,828.969299 
	C245.962646,829.029114 245.483154,829.013428 245.243652,829.000610 
	C244.998917,828.250671 244.993683,827.513489 244.983597,826.407593 
	C245.347595,825.617859 245.806839,825.460388 246.678772,825.780579 
	C247.257599,826.203186 247.514008,826.411316 247.882782,826.843384 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M246.841187,825.704712 
	C246.551849,826.001038 246.102509,826.006958 245.315964,826.025818 
	C244.233078,826.032959 243.487396,826.027161 242.368561,826.020203 
	C241.870636,825.851135 241.745911,825.683289 241.806427,825.262207 
	C243.641632,824.140442 245.276657,823.444458 246.841187,825.704712 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M291.765503,828.699463 
	C290.920807,830.143799 289.932861,830.299255 289.019226,828.382935 
	C289.807007,828.206970 290.669983,828.336060 291.765503,828.699463 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M361.801086,813.155518 
	C361.706451,814.602905 360.919952,815.292297 359.214020,814.316040 
	C359.578796,813.190857 360.471405,813.041077 361.801086,813.155518 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M241.666748,825.000427 
	C241.992722,825.177307 241.993729,825.345581 241.995056,825.766418 
	C240.870285,827.157471 239.875793,827.284790 239.047943,825.336792 
	C239.162552,825.017517 239.338577,825.007629 239.778961,824.979919 
	C240.476151,824.972046 240.908981,824.981934 241.666748,825.000427 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M366.820679,812.146362 
	C366.844421,813.009460 366.658081,813.992493 365.179657,813.268066 
	C365.499054,812.758850 366.003296,812.442078 366.820679,812.146362 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M344.822021,817.175415 
	C344.904205,818.020935 344.511536,818.711426 343.241455,818.877197 
	C343.108795,817.995605 343.478851,817.281555 344.822021,817.175415 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M298.750427,827.110718 
	C299.047272,827.518860 299.026428,827.950134 299.001221,828.706604 
	C298.546143,829.021423 298.095428,829.011108 297.307770,828.966187 
	C297.099091,828.040466 297.435944,827.318787 298.750427,827.110718 
z"/>
<path fill="#06ABCA" opacity="1.000000" stroke="none" 
	d="
M232.005325,744.651123 
	C231.715103,744.467102 231.406036,743.994690 231.106934,743.223511 
	C231.406754,743.403992 231.696625,743.883362 232.005325,744.651123 
z"/>
<path fill="#06ABCA" opacity="1.000000" stroke="none" 
	d="
M232.954422,746.709534 
	C232.668823,746.527161 232.373703,746.054199 232.071045,745.291626 
	C232.357330,745.474365 232.651123,745.946594 232.954422,746.709534 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M382.278198,722.683594 
	C381.801544,723.000793 381.633362,722.996643 381.212952,722.986328 
	C378.627258,720.378845 379.162628,716.984680 378.818726,713.978394 
	C377.360138,701.227905 371.287964,691.300598 361.665192,683.037842 
	C355.426666,677.680969 348.672943,673.358643 341.370880,669.657104 
	C332.331879,665.075256 324.097839,659.099365 315.553375,653.687012 
	C307.952026,648.872192 299.852386,645.086853 292.009094,640.784485 
	C289.941986,639.650696 288.056854,638.367676 286.027405,636.613159 
	C284.884521,635.661743 284.178802,634.702393 282.638000,634.382263 
	C280.921021,633.883606 279.771484,633.091370 278.504730,631.840027 
	C275.179230,628.521912 272.628632,624.675110 267.311707,624.377930 
	C266.515289,624.278625 266.074158,624.166382 265.342407,623.859741 
	C263.906342,623.090454 263.352295,621.670715 261.597595,621.382629 
	C260.832794,621.182190 260.409088,621.015198 259.688171,620.652222 
	C244.921539,612.004700 231.459213,601.932190 216.758774,593.833557 
	C215.274384,593.015808 213.813812,592.397400 211.831238,592.095825 
	C210.707306,591.633911 210.046204,591.061890 209.047729,590.460815 
	C208.211731,590.085754 207.622391,589.991394 206.688782,590.042847 
	C205.916656,590.069946 205.470779,590.029297 204.685898,589.850952 
	C204.132095,589.630310 203.920212,589.512207 203.440430,589.141663 
	C199.484299,584.635254 194.920319,581.393250 190.404678,578.144287 
	C186.842087,575.581055 183.694138,572.718506 181.907578,568.692444 
	C180.775864,566.142151 179.758255,563.387268 182.272369,560.578125 
	C185.020645,559.836304 185.942169,561.422363 186.429138,563.295288 
	C187.476578,567.323792 190.210449,569.839355 193.698074,571.690491 
	C198.112106,574.033325 201.891525,577.414551 206.081833,579.897095 
	C210.718323,582.644043 215.358322,585.315063 219.989487,588.708618 
	C225.076355,590.123535 228.866562,593.509644 233.907654,595.044556 
	C239.133636,597.781067 243.195221,601.538452 247.633957,604.754456 
	C252.793579,608.492676 258.928192,610.609558 264.021515,614.096863 
	C270.896057,618.803772 279.161285,621.022461 285.325073,626.783264 
	C288.064056,629.343201 291.637024,630.479736 294.949921,631.975891 
	C300.306702,634.394958 304.929443,637.880981 309.689392,641.218567 
	C315.945068,645.605103 322.637512,649.321167 329.194977,653.225220 
	C332.677155,655.298462 335.931824,657.463745 338.695740,660.897644 
	C339.846680,663.440430 342.200806,663.342896 344.290283,664.381836 
	C352.548309,669.007141 360.808990,672.901978 368.019775,678.614929 
	C374.738708,683.938171 380.933899,689.575439 383.430237,698.609253 
	C384.795868,704.905090 385.282745,710.870056 384.646057,716.865601 
	C384.442505,718.782288 384.156250,720.865417 382.278198,722.683594 
z"/>
<path fill="#060D0E" opacity="1.000000" stroke="none" 
	d="
M382.326935,722.954956 
	C384.167908,714.953552 382.638824,706.899841 381.986145,698.405029 
	C383.073578,697.046448 384.261749,697.250122 385.178589,698.041687 
	C391.599030,703.584229 399.923676,706.138794 406.386810,711.578491 
	C411.144287,715.582520 417.436218,716.769958 422.337585,720.557251 
	C424.870880,722.514709 426.667419,725.832031 430.891754,725.796021 
	C431.460236,725.988647 431.679169,726.085876 432.197449,726.376831 
	C437.126068,730.273132 442.401794,732.890076 447.965546,735.687805 
	C448.509583,735.894958 448.720764,735.996643 449.227631,736.290771 
	C453.084351,739.854248 457.095154,742.812012 459.580292,747.620605 
	C460.208740,749.945129 459.159546,751.613647 458.509949,753.722839 
	C458.381042,754.531067 458.287109,754.989746 458.054565,755.778320 
	C457.670654,756.768738 457.303589,757.369324 456.679626,758.219666 
	C454.704071,759.901123 452.744873,761.179932 452.948090,764.228149 
	C452.699097,765.858704 452.095062,766.825134 450.414734,767.108276 
	C447.262543,765.607178 448.226654,763.660950 449.176208,761.570740 
	C452.539734,754.166260 451.240875,751.170898 443.899231,748.414917 
	C440.350739,747.082764 437.409973,744.521606 434.354034,742.419067 
	C423.634003,735.043762 411.924469,729.295471 401.286987,721.826843 
	C397.648712,719.272522 393.134430,719.187683 389.226807,717.348145 
	C387.438934,716.506470 386.097809,718.086853 385.351318,719.641846 
	C384.709015,720.979736 384.487579,722.554565 382.326935,722.954956 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M285.765808,634.808228 
	C298.380096,642.069397 311.006531,648.747192 323.059723,656.335571 
	C331.576599,661.697571 339.991943,667.176880 348.850830,671.977051 
	C358.091187,676.984070 365.921417,683.588867 372.210815,692.179077 
	C378.812347,701.195679 380.687225,711.349548 380.967102,722.534180 
	C378.418488,727.179199 377.424103,732.282410 373.214661,735.838745 
	C370.364410,735.054138 371.756348,733.555481 372.297119,732.122986 
	C374.531738,726.203613 377.007935,720.226135 375.355194,713.760254 
	C373.389832,706.071350 371.299622,698.387146 365.061249,692.692627 
	C361.381439,689.333679 357.956970,685.694824 353.839569,682.285034 
	C351.920746,679.186462 349.269348,677.605408 346.001282,676.301086 
	C345.471130,676.105652 345.264893,676.011658 344.766907,675.743896 
	C342.676147,674.278931 340.911194,672.924255 338.337585,672.418579 
	C337.562500,672.314209 337.130188,672.208923 336.403198,671.931152 
	C335.305878,671.438721 334.734222,670.744202 333.664856,670.229553 
	C333.201080,669.978882 333.020752,669.869995 332.581024,669.583618 
	C331.822723,669.038940 331.365723,668.603821 330.436615,668.342529 
	C329.714294,668.099915 329.315155,667.916382 328.640289,667.525024 
	C323.836609,663.766357 319.250916,660.300415 313.470184,658.067871 
	C312.943909,657.778015 312.743042,657.642517 312.278320,657.254639 
	C309.219360,651.668213 303.224640,651.359253 298.739777,648.734741 
	C291.426025,644.454651 284.170044,640.136719 277.419586,635.001160 
	C273.415192,631.954773 268.410980,630.717529 264.299255,627.956665 
	C260.905884,625.678284 256.100159,624.596191 255.722794,619.050293 
	C257.287659,618.101379 258.345551,618.863770 259.721436,619.740234 
	C260.450653,619.993408 260.890656,619.998901 261.660614,620.009277 
	C262.858734,620.858765 263.712433,621.718018 264.791809,622.779663 
	C265.447784,622.997925 265.888550,623.003174 266.659912,623.011536 
	C271.365631,624.689087 274.766235,627.861328 278.757324,630.751221 
	C280.119934,631.639160 281.233826,632.284180 282.670166,632.969788 
	C283.676056,633.803650 284.617706,634.187927 285.765808,634.808228 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M344.757263,674.761475 
	C345.008942,674.992615 345.499084,675.004883 345.744080,675.013000 
	C347.976562,677.576843 351.404724,678.156799 353.774841,680.752502 
	C360.373718,684.452759 366.320953,689.599243 369.621094,695.137756 
	C375.186859,704.478638 380.885712,714.947815 375.712250,727.087097 
	C374.556671,729.798523 373.289276,732.615906 372.686035,735.756409 
	C371.895172,739.259888 369.107269,740.844055 366.252869,742.777588 
	C367.004059,737.359009 371.227905,732.968811 371.932404,727.427673 
	C372.728394,721.167114 375.343018,714.455200 369.515900,708.958069 
	C368.935608,708.410583 368.365875,707.649231 368.198822,706.896667 
	C365.420380,694.380798 354.985687,688.203979 346.007935,681.306030 
	C338.208069,675.313293 329.055328,671.087402 320.544342,666.007141 
	C319.328735,665.281616 318.127502,664.983215 316.392456,664.996582 
	C312.166962,662.497192 308.713135,659.409302 304.246033,657.248291 
	C303.991577,657.012207 303.497620,657.003479 303.250641,656.999268 
	C301.045776,654.578552 297.725616,654.428589 295.242218,652.241394 
	C294.212463,651.689026 293.428192,651.373230 292.327545,651.022644 
	C289.823212,648.500244 286.392120,647.879211 284.017395,645.629517 
	C282.730835,644.410767 281.060181,643.543579 281.671509,641.027588 
	C283.385498,639.575439 285.087891,639.511108 286.441772,640.687988 
	C292.877563,646.282104 300.624329,649.540466 308.187683,653.134033 
	C309.867859,653.932434 311.191315,655.155884 312.725586,656.736145 
	C312.995087,657.002563 313.000000,657.000000 312.998444,657.002441 
	C318.346313,659.875488 323.161224,663.617249 328.749359,666.755615 
	C329.444061,667.005005 329.883972,667.012756 330.653870,667.026367 
	C331.532379,667.545349 332.067719,668.072205 332.801422,668.799072 
	C333.001221,668.997559 333.000000,669.000000 333.001221,668.999817 
	C333.753693,669.699524 334.819275,669.881592 335.799591,670.774292 
	C336.456329,670.991394 336.894287,670.998840 337.660645,671.014038 
	C340.047974,672.401672 342.022400,673.938416 344.757263,674.761475 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M412.849609,805.380005 
	C413.524200,805.066406 413.943542,805.031799 414.676544,804.962524 
	C416.539886,803.805176 418.226624,802.864685 419.825256,801.275757 
	C420.074066,801.075012 420.536591,801.041016 420.767029,801.015076 
	C422.366241,799.632202 423.762085,798.309204 425.731873,797.192505 
	C427.313965,795.184387 428.656830,793.390015 430.177155,791.298950 
	C429.771027,787.875000 433.427582,788.381348 434.414978,786.177246 
	C436.003204,783.661072 438.115051,782.014832 440.014771,779.840820 
	C440.389130,779.512634 440.442200,779.256531 440.531433,778.610168 
	C440.949860,777.967834 441.332153,777.715698 441.906250,777.331665 
	C442.437927,776.457153 443.082550,775.979248 443.874878,775.245361 
	C445.760712,773.084229 447.410828,771.102661 449.359314,769.018555 
	C453.765137,769.755981 451.637970,772.510315 450.732849,773.946960 
	C447.931610,778.393066 445.245453,782.979309 441.065735,786.852173 
	C439.032776,787.825928 437.468933,788.803284 436.266479,790.766968 
	C434.662781,791.677124 434.001923,792.786438 433.913483,794.626465 
	C428.858276,797.893066 426.035095,803.137756 421.269989,806.803345 
	C419.888519,807.957214 418.557526,808.309387 416.713379,808.378784 
	C415.737305,808.199158 415.231018,807.959351 414.458344,807.336060 
	C413.961853,807.042480 413.780151,807.030273 413.325500,807.006958 
	C412.630981,806.642334 412.478241,806.196716 412.849609,805.380005 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M439.728271,787.203369 
	C442.193817,783.760376 444.626129,780.695862 446.817932,777.468018 
	C448.499420,774.991821 450.936005,772.745056 449.950684,769.081421 
	C449.799225,768.502869 449.940765,768.089722 450.072021,767.349243 
	C450.720795,766.235107 451.379822,765.448303 452.083435,764.349487 
	C452.928772,762.204224 453.387482,760.075989 456.232178,759.848877 
	C458.682465,761.956116 458.026978,764.293213 457.122009,766.363037 
	C455.811981,769.359253 455.022369,772.541870 453.300873,775.740112 
	C449.831268,775.768799 449.738037,778.374146 448.665436,780.344055 
	C446.879883,783.623535 446.022827,788.094788 440.446899,788.013306 
	C439.889954,787.847839 439.740784,787.679749 439.728271,787.203369 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M443.772919,775.092041 
	C443.648926,775.739563 443.187256,776.413452 442.411804,777.143494 
	C442.119263,776.233215 442.366364,775.411804 443.772919,775.092041 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M360.993683,737.993652 
	C361.682434,733.753479 362.544708,729.505798 363.557159,725.390320 
	C365.994232,715.483887 360.961182,708.139832 355.081055,702.068237 
	C347.101166,693.828491 336.125214,689.212646 326.913452,682.482300 
	C317.307709,675.464111 306.485504,671.015198 296.227112,665.532043 
	C286.434326,660.297852 277.491058,654.096436 268.337311,647.997742 
	C264.056549,645.145630 258.496338,644.156921 254.307571,640.146912 
	C253.012863,639.089600 252.113068,638.143860 252.453217,636.271362 
	C254.553467,633.521057 256.565308,634.455627 258.742218,635.688538 
	C268.491150,641.210144 277.871674,647.281982 286.598969,654.315613 
	C287.802063,655.285217 289.138397,655.822327 290.723145,656.753479 
	C291.006531,656.992676 291.499298,657.001831 291.745667,657.005798 
	C303.343597,662.001343 313.360626,669.432007 324.739624,674.505249 
	C332.531738,677.979309 339.023621,684.119019 346.734802,688.751770 
	C347.003662,688.997559 347.496338,689.005676 347.742676,689.008911 
	C353.939148,694.521179 360.006470,699.931824 363.756531,707.741455 
	C364.006531,707.985352 364.498108,708.000977 364.743896,708.008789 
	C369.279266,717.526001 368.950989,726.971497 364.296265,736.675781 
	C363.995270,736.990234 364.000000,737.000000 363.995117,736.997559 
	C363.339539,737.664612 362.704926,738.362610 361.335266,738.166199 
	C361.020416,738.015869 361.002777,738.001892 360.993683,737.993652 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M166.638535,476.050781 
	C164.672714,481.798218 162.441696,487.644165 160.016830,493.408508 
	C159.424423,494.816772 158.668747,496.018188 159.811493,497.754272 
	C161.116043,499.149414 161.450653,500.517517 161.237061,502.451141 
	C159.457657,506.070953 158.874634,509.399658 160.036957,512.924927 
	C160.407501,514.048767 160.526581,515.227356 160.380096,516.371765 
	C158.453430,531.423340 163.900711,544.875488 169.433685,558.309509 
	C169.814911,559.235107 170.173584,560.156250 170.345123,561.526672 
	C169.643860,567.322876 171.931229,571.935120 174.569550,576.725220 
	C175.005112,577.083069 175.163910,577.243286 175.515991,577.679016 
	C176.506424,579.682068 176.569855,581.859985 178.932556,582.996765 
	C179.497681,583.540283 179.739716,583.914307 179.958862,584.664734 
	C180.001297,586.347717 179.951248,587.956238 181.345505,588.501282 
	C188.800903,591.415833 193.820526,598.153564 201.923325,600.514709 
	C207.346390,602.094971 213.432404,604.906921 215.944717,611.657959 
	C212.866928,613.843384 209.995239,612.446472 207.766464,610.616028 
	C204.524033,607.953064 200.997787,606.121033 197.230865,604.340271 
	C190.850845,601.324219 185.818787,596.084961 179.142639,593.212708 
	C175.807358,591.777832 174.103317,587.206970 170.251831,584.989197 
	C169.668182,584.386169 169.421036,583.982483 169.151428,583.203369 
	C168.890564,581.993469 168.753006,581.107361 168.182297,579.988586 
	C167.962967,579.450867 167.885910,579.228577 167.735901,578.656128 
	C167.110443,571.200867 165.134171,564.593750 160.587799,558.496826 
	C160.212067,557.961731 160.085999,557.728455 159.836792,557.113037 
	C159.011887,549.386536 156.906189,542.378967 154.531723,535.445312 
	C154.081116,534.129456 153.902359,532.922302 154.726089,531.315918 
	C155.020615,529.574890 154.928864,528.222229 153.304657,527.161560 
	C151.934052,525.687805 151.851639,524.259338 152.735138,522.369873 
	C153.297012,507.541840 155.944366,493.634186 162.698517,480.697601 
	C163.607010,478.957458 164.492889,477.165955 166.638535,476.050781 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M169.182495,563.323730 
	C169.682098,559.334961 166.636246,556.992493 165.549210,553.962524 
	C162.366211,545.090393 159.157303,536.052002 158.532394,526.835999 
	C158.015503,519.213013 156.809753,511.169495 159.668518,503.240662 
	C161.006348,502.694427 161.509872,503.378357 162.116379,504.527435 
	C162.940491,514.119995 161.162674,523.599304 164.523102,532.468994 
	C167.511841,540.357605 166.518280,549.529541 172.544235,556.454163 
	C174.010696,558.139404 174.472595,560.635376 174.456024,563.024902 
	C174.433655,566.249023 175.769196,568.775269 178.720764,570.872681 
	C179.368469,571.536133 179.621185,571.988770 179.855286,572.860596 
	C180.126938,579.448303 185.332611,581.853699 189.308884,584.903137 
	C194.223389,588.672058 199.074570,592.721863 205.280838,594.479431 
	C206.683029,594.876465 207.691574,596.170715 208.087128,598.342651 
	C200.033569,599.202881 194.573425,593.897827 188.607803,589.929871 
	C186.022156,588.210083 183.700256,586.120728 180.364807,585.011230 
	C179.695770,584.871216 179.443665,584.675964 179.123383,584.204651 
	C177.434525,582.405273 176.331070,580.515930 175.222504,578.246094 
	C174.988861,578.012573 175.000000,578.000000 174.993698,578.005249 
	C171.565002,573.829712 171.119705,568.483032 169.182495,563.323730 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M169.780258,584.268555 
	C171.113434,584.368958 172.659653,584.439880 173.209808,585.216370 
	C177.709869,591.568298 185.292679,593.689819 190.988724,598.386963 
	C194.017090,600.884277 197.111374,603.365356 201.194016,604.324585 
	C203.535431,604.874695 205.828964,605.985168 207.270020,608.351990 
	C209.048752,611.273376 212.562683,610.745239 215.615067,611.954590 
	C215.988251,612.008423 216.479782,612.029175 216.725677,612.035828 
	C219.546844,613.155396 221.729660,614.950562 224.220062,616.211365 
	C226.030563,617.127991 227.007385,618.494629 226.383972,620.953796 
	C223.815811,623.130188 221.561890,622.275452 219.223175,621.099121 
	C213.750336,618.346436 208.690811,614.913879 203.656677,611.481689 
	C196.547882,606.634949 188.655472,603.357178 181.065170,599.459229 
	C175.011154,596.350220 170.391891,591.838379 169.780258,584.268555 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M290.943420,656.661865 
	C286.716309,657.897217 283.484436,654.848694 281.207611,652.616394 
	C275.710236,647.226562 270.055878,642.669617 262.545868,640.498413 
	C260.979950,640.045654 259.690765,638.301941 258.506195,636.949768 
	C257.076630,635.317932 255.545639,635.051758 253.335999,635.965759 
	C252.781403,636.137573 252.568573,636.265320 251.992844,636.310425 
	C248.274261,633.946655 244.876083,631.738770 241.407623,629.624878 
	C239.151489,628.249878 236.236969,627.133850 236.775116,623.290161 
	C243.597672,624.089050 248.259094,628.963013 253.992645,631.765625 
	C262.282990,635.818115 269.967468,640.977295 277.853394,645.769531 
	C282.735535,648.736511 287.029297,652.203918 290.943420,656.661865 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M346.955017,688.652344 
	C341.470734,688.390625 337.165741,684.858704 333.218475,681.737305 
	C325.256775,675.441406 315.640808,672.470032 307.232117,667.206482 
	C302.188171,664.049072 297.147766,660.885986 292.048889,657.367615 
	C299.257751,657.225037 304.349457,662.341797 310.360016,665.319885 
	C318.319580,669.263733 326.288910,673.344543 333.664764,678.252136 
	C338.227905,681.288330 343.407928,683.771606 346.955017,688.652344 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M252.212936,627.184387 
	C251.427917,626.497437 250.847137,626.005554 250.135620,625.254395 
	C248.516891,623.355469 246.059784,622.336487 246.655106,619.138306 
	C250.387177,617.430542 252.962448,619.609558 255.344925,621.505859 
	C260.571564,625.666016 266.308075,628.818970 272.276825,631.775879 
	C276.128937,633.684143 281.214874,635.064331 281.033569,641.254761 
	C279.719543,642.101624 278.684601,642.104431 277.330444,641.251282 
	C277.001221,641.002441 277.000000,641.000000 276.998779,641.000000 
	C270.699951,635.780579 262.761292,633.397034 255.807190,628.683960 
	C254.979889,627.958496 254.416107,627.401917 253.256180,627.471741 
	C252.788330,627.443237 252.602753,627.411377 252.212936,627.184387 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M237.061676,616.333313 
	C232.161636,617.421082 229.618988,612.852844 225.465775,611.726807 
	C218.276611,609.777588 213.417389,604.396606 208.052856,599.348145 
	C207.984680,599.017090 207.996567,599.003662 207.999329,599.000610 
	C209.684509,597.423462 211.401489,598.122559 213.004166,599.051331 
	C219.517609,602.825989 226.067215,606.547729 232.392090,611.286621 
	C233.446808,612.693481 234.463547,613.581665 236.073151,614.294434 
	C236.682266,614.840393 236.936813,615.229126 237.061676,616.333313 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M236.753220,622.994446 
	C239.100479,628.675659 245.308090,629.303589 249.190659,632.812805 
	C250.090149,633.625793 251.575485,633.800476 251.949738,635.646729 
	C248.739594,637.954163 246.388718,635.605347 243.929108,634.179688 
	C238.586945,631.083130 233.074875,628.316284 227.302185,625.237549 
	C227.009659,624.990234 227.003906,624.496338 227.001038,624.249390 
	C226.400101,623.299561 226.200485,622.501953 226.610748,621.213623 
	C230.139374,618.744568 232.737335,620.653870 235.688324,622.705750 
	C236.016479,622.980835 236.507660,622.988892 236.753220,622.994446 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M364.364380,736.935608 
	C367.793274,727.737122 366.842529,718.315002 364.993164,708.438721 
	C371.819611,712.960327 368.861725,720.066345 368.486359,726.259155 
	C368.271484,729.804321 368.746399,734.354065 364.364380,736.935608 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M252.170120,627.288940 
	C252.008713,626.989258 252.498001,626.982605 252.742676,626.980469 
	C253.679688,626.410767 254.450989,626.285400 255.644821,626.785645 
	C258.990875,630.610046 263.524628,631.661987 267.451233,633.613342 
	C271.013916,635.383850 274.422668,637.111511 276.934479,640.665649 
	C269.993439,639.152710 264.448700,634.398010 258.003693,631.422424 
	C255.958862,630.478333 254.213776,628.884949 252.170120,627.288940 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M363.957520,707.624390 
	C363.388824,707.787842 362.330627,707.729187 362.218201,707.372375 
	C359.782227,699.643372 351.567871,696.560425 348.037506,689.358154 
	C356.358337,692.424438 360.543365,699.486389 363.957520,707.624390 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M235.959167,622.627075 
	C233.051895,623.266174 230.905609,620.156677 227.401520,621.003296 
	C226.830978,621.130005 226.665024,621.255127 226.257782,621.182800 
	C225.404404,615.704651 219.535645,616.067017 217.051849,612.375916 
	C224.148392,613.848999 229.712799,618.639038 235.959167,622.627075 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M277.049194,641.353271 
	C278.066895,641.002930 279.132568,641.003479 280.597900,641.003235 
	C281.164062,640.874756 281.330597,640.747070 281.739990,640.819702 
	C282.823395,644.434448 286.121735,645.301086 288.438965,647.158203 
	C289.665985,648.141663 291.503296,648.405884 291.958679,650.642822 
	C286.359650,648.985962 281.048218,646.437683 277.049194,641.353271 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M304.044739,657.362061 
	C309.475769,656.974976 312.109070,661.569641 315.940674,664.659180 
	C311.268372,663.823914 307.590790,660.918945 304.044739,657.362061 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M246.772522,618.757080 
	C246.769806,621.470703 250.167404,621.852417 249.993912,624.639038 
	C246.708740,624.539429 243.858215,623.487122 242.988251,619.396362 
	C243.111893,618.850525 243.249344,618.673035 243.682159,618.331421 
	C244.414459,618.040955 244.860123,617.987915 245.644928,618.083130 
	C246.172958,618.267883 246.370560,618.377380 246.772522,618.757080 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M295.048767,652.357666 
	C298.141541,652.490784 300.987274,653.528381 302.951172,656.642212 
	C300.243317,655.799438 296.771545,655.915894 295.048767,652.357666 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M360.970276,738.365417 
	C361.800232,737.697693 362.580017,737.379517 363.675049,737.028198 
	C364.111359,740.264587 361.777039,741.995422 359.345337,743.941223 
	C359.647461,742.243469 360.283783,740.479248 360.970276,738.365417 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M244.153412,618.702820 
	C243.824783,619.007141 243.654800,619.009460 243.229614,619.019897 
	C241.877197,618.716797 240.779968,618.405579 239.340973,618.054077 
	C238.902420,617.807434 238.805618,617.601135 238.818756,617.069580 
	C239.645462,616.052185 240.536850,615.895081 241.828644,615.958801 
	C242.391647,616.082947 242.610519,616.158569 243.123962,616.417664 
	C243.942154,617.088928 244.265076,617.676331 244.153412,618.702820 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M238.686005,616.791992 
	C238.999924,617.168884 238.999832,617.337830 238.999481,617.760254 
	C238.099030,618.973267 237.478149,618.736938 237.034943,617.285950 
	C236.994034,617.007324 237.001572,616.997986 237.004868,616.993652 
	C237.388351,616.603760 237.842972,616.468689 238.686005,616.791992 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M424.713562,792.114624 
	C423.684326,796.231689 419.629822,797.387146 416.295044,799.836914 
	C415.208405,800.646667 414.524170,800.490967 413.966980,799.335571 
	C415.604187,797.513245 417.183289,796.098267 418.930511,794.408325 
	C421.210724,794.409729 422.531311,792.610840 424.713562,792.114624 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M440.064941,779.625122 
	C439.863953,782.597168 437.416107,783.927063 435.470276,786.044800 
	C434.979584,786.320374 434.751923,786.388245 434.254669,786.223022 
	C433.967834,783.888184 435.871948,783.104187 437.076111,781.435669 
	C437.319885,780.922058 437.397278,780.698792 437.808228,780.355469 
	C438.692444,779.984192 439.243164,779.732849 440.064941,779.625122 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M433.733276,785.998352 
	C434.189423,786.027161 434.393768,786.064270 434.902710,786.176941 
	C435.476868,789.514771 432.083588,789.058838 430.539764,790.835266 
	C430.040833,790.840454 429.727020,790.678528 429.245972,790.312317 
	C429.276306,789.605042 429.473846,789.102112 429.853546,788.391907 
	C430.541595,787.150269 431.601501,786.886414 432.742310,786.203369 
	C433.145599,786.001526 433.313568,786.004089 433.733276,785.998352 
z"/>
<path fill="#162034" opacity="1.000000" stroke="none" 
	d="
M432.620728,786.004150 
	C432.600861,787.123352 431.802429,787.793701 430.362183,788.154541 
	C430.069763,786.733704 430.837799,786.034546 432.620728,786.004150 
z"/>
<path fill="#0F1523" opacity="1.000000" stroke="none" 
	d="
M437.976257,780.005066 
	C438.039124,780.519470 437.867432,780.752563 437.434570,781.040039 
	C436.727417,780.781677 436.154755,780.374695 436.634094,779.692017 
	C436.750671,779.526062 437.404144,779.737000 437.976257,780.005066 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M413.755798,799.028015 
	C414.483978,799.258057 414.942841,799.587891 415.694702,799.951172 
	C414.903870,801.351013 413.423126,801.887573 411.395508,802.024414 
	C411.288757,801.520752 411.532593,801.006409 411.881531,800.226318 
	C412.486603,799.682983 412.986572,799.405396 413.755798,799.028015 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M225.595795,620.990112 
	C226.016510,620.985352 226.506775,620.995056 226.751862,621.000000 
	C226.998077,621.754211 226.999222,622.503479 226.999268,623.627625 
	C221.307541,624.551819 217.082230,621.000977 212.331726,618.847961 
	C206.014389,615.984863 201.731979,610.030457 195.180496,607.636597 
	C190.188843,605.812622 185.952866,602.373474 180.365723,601.048096 
	C179.869141,600.698669 179.674088,600.447571 179.206528,600.125793 
	C172.955246,598.207153 169.767166,593.405212 166.761353,588.399780 
	C165.665207,586.574402 166.139572,584.914124 167.390289,582.996643 
	C167.768768,582.536987 167.928375,582.359985 168.386139,581.986450 
	C168.918549,581.750000 169.152786,581.710083 169.703613,581.833862 
	C170.021133,582.436340 170.022049,582.875183 170.025894,583.642517 
	C173.191635,590.940063 176.949692,596.379883 185.059845,599.601624 
	C190.593582,601.799927 196.470688,605.023682 201.717712,608.648743 
	C207.481873,612.631042 213.445404,616.325562 219.338409,620.120117 
	C221.134201,621.276428 223.189346,620.965881 225.595795,620.990112 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M227.072876,625.331177 
	C230.177048,624.362793 232.169205,626.301025 234.683716,627.822205 
	C239.925003,630.993225 245.711029,633.263916 251.637375,635.951050 
	C252.001526,635.997559 252.497971,636.002319 252.746094,636.006042 
	C253.303894,637.107910 253.613541,638.205994 253.966873,639.645203 
	C249.474930,639.490356 245.481262,637.837036 242.125732,634.657104 
	C239.191528,631.876404 235.162750,631.064148 231.792175,629.089661 
	C230.168564,628.138489 228.010773,627.711365 227.072876,625.331177 
z"/>
<path fill="#05B9D9" opacity="1.000000" stroke="none" 
	d="
M214.043945,795.464539 
	C214.695755,795.823486 214.705292,796.183960 214.237350,796.501892 
	C214.139481,796.568420 213.913223,796.565491 213.818115,796.496033 
	C213.363159,796.163574 213.379822,795.803528 214.043945,795.464539 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M585.222046,440.205750 
	C584.325134,438.238678 583.634094,436.478271 582.966309,434.360352 
	C584.927551,432.287811 587.485168,432.695496 590.108276,432.023407 
	C596.294800,429.059387 603.796814,430.418793 608.554260,424.526215 
	C610.299866,422.364014 613.330444,421.729126 616.278992,421.493683 
	C624.195740,420.861633 631.586060,418.971588 639.168213,415.938324 
	C647.410278,412.641052 656.708191,411.272736 665.654236,409.540955 
	C674.100891,407.905792 682.581848,406.400421 690.904114,404.203522 
	C696.181946,402.810242 699.571472,405.065430 699.110474,410.415924 
	C698.697815,415.205444 697.927612,420.026703 695.391418,424.276581 
	C692.163086,429.686096 688.878967,435.069611 685.332153,440.273773 
	C681.576965,445.783630 675.518616,445.862061 669.725708,446.458405 
	C665.649658,446.877991 661.382080,445.394592 657.119385,447.861206 
	C651.031799,449.229553 645.108521,447.588470 639.451294,449.210480 
	C626.258118,452.993164 612.821838,455.779846 599.096130,459.246582 
	C596.239136,459.814453 593.820374,460.073669 591.229187,458.319458 
	C589.211121,452.968414 587.563416,447.888214 585.899841,442.426758 
	C585.940002,441.442993 585.807922,440.893066 585.222046,440.205750 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M590.356567,432.877808 
	C588.022766,434.093628 586.019226,435.026459 583.345276,434.012177 
	C581.818054,430.870270 580.646667,427.737640 579.582520,424.198486 
	C580.231262,424.341827 580.702942,425.202332 581.421631,423.907196 
	C583.933044,419.381531 587.982788,417.714142 593.351929,417.893921 
	C598.422424,419.913605 602.885742,418.796875 607.115112,416.748444 
	C609.578491,415.555359 611.759583,414.680634 614.657349,415.336304 
	C618.101501,416.115662 620.912415,413.474274 624.028564,412.476257 
	C633.207275,409.536682 642.869324,408.724762 652.016357,405.745361 
	C653.277222,405.334686 654.614990,405.163910 656.154663,404.358276 
	C656.980713,403.989990 657.470947,403.837921 658.346985,403.675079 
	C667.349915,402.626190 675.572998,400.335175 683.430115,395.720215 
	C684.397888,395.147400 685.117859,395.021729 686.229187,395.106659 
	C689.950134,397.753265 692.115112,395.077271 694.827515,393.191650 
	C695.393616,392.933594 695.631042,392.848694 696.244690,392.694336 
	C699.516296,392.767090 702.038391,391.573120 704.659668,390.561737 
	C710.222107,388.415527 712.332703,390.273560 711.756348,396.070923 
	C711.352356,400.133698 710.721802,404.128265 709.900024,408.494019 
	C709.942444,409.694946 709.733337,410.445496 709.060608,411.430939 
	C704.525940,414.890686 702.816101,419.765503 700.581909,424.265564 
	C699.605652,426.232025 698.824219,428.198608 695.888855,428.073059 
	C694.315063,426.107544 694.877991,424.370056 695.704529,422.685181 
	C698.933411,416.102661 699.261841,408.446930 703.320740,402.167694 
	C703.910645,401.255005 703.811646,400.188171 703.060913,399.387573 
	C702.001099,398.257568 700.808533,398.974701 699.890930,399.566620 
	C695.985657,402.085846 691.891602,404.118530 687.469971,405.626801 
	C685.720032,406.223724 684.230286,407.270569 682.320862,406.427734 
	C680.629272,405.681000 679.029846,406.442322 677.453796,406.843933 
	C667.506226,409.378815 657.550171,412.006287 647.532166,414.095947 
	C637.522278,416.183929 628.079346,420.400757 617.776001,421.338898 
	C613.723938,421.707855 609.855591,423.287415 606.443176,426.021851 
	C604.209961,427.811401 601.503784,429.294373 598.206116,429.091522 
	C595.166199,428.904541 593.203552,431.496124 590.356567,432.877808 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M578.889160,416.200989 
	C575.948547,417.147095 576.646179,414.720123 575.947388,413.376099 
	C578.671997,408.627960 583.567688,408.742584 587.724854,407.691345 
	C593.484558,406.234833 599.555542,405.826080 605.050049,403.482269 
	C610.332336,401.228973 616.134644,401.947144 621.451538,399.776123 
	C627.373596,397.358002 633.958679,396.887634 640.247070,394.095337 
	C641.139404,393.749084 641.669617,393.631989 642.617798,393.568481 
	C647.211304,393.588593 651.136353,392.253204 655.181213,391.427185 
	C664.263184,389.572510 672.904114,386.182373 682.024109,384.420715 
	C689.084412,383.056885 696.339233,381.914429 703.083435,378.138733 
	C703.783203,377.000732 704.371033,376.226410 705.090332,375.125854 
	C705.482300,374.704681 705.653931,374.553894 706.110596,374.222839 
	C707.703735,373.693817 708.722351,373.022064 709.280579,371.350555 
	C710.082520,370.354523 710.895203,369.979248 712.117554,369.679443 
	C712.887512,369.589172 713.325867,369.568695 714.080688,369.549286 
	C715.457397,369.559143 716.412292,369.362976 717.105713,368.012695 
	C718.012756,366.510864 718.861694,365.413391 720.709839,364.980652 
	C722.852600,365.385223 724.177429,364.375885 725.628296,363.614868 
	C731.640015,360.461548 733.931641,360.986145 738.403809,366.711456 
	C737.550842,371.768127 734.525085,375.709381 733.232910,380.692505 
	C732.977722,380.977936 733.000000,380.997162 732.988647,380.990967 
	C732.018494,382.983063 731.127747,385.016876 729.700073,387.145569 
	C727.447144,388.992920 726.405762,391.143585 725.889893,393.980957 
	C725.242371,395.950806 723.902893,397.163177 723.200317,399.153107 
	C722.018982,400.530426 720.832214,400.909058 719.085327,400.359283 
	C716.811523,395.433472 721.134094,392.569244 722.324341,388.788086 
	C723.189026,386.041199 724.842529,383.393707 726.240295,380.763672 
	C727.434021,378.517487 728.472656,376.213593 726.405151,374.429779 
	C724.233337,372.556091 721.326721,372.912994 719.008301,374.532806 
	C715.190491,377.200073 711.136963,379.477325 707.257629,382.031281 
	C704.024048,384.160156 700.615051,385.792847 696.859314,386.715881 
	C688.179749,388.848969 679.632812,391.463165 670.930664,393.564545 
	C661.842773,395.758942 653.002502,399.103821 643.720398,400.827179 
	C631.833984,403.034088 620.405151,407.117493 608.656921,409.802490 
	C603.047668,411.084412 597.308777,411.989349 591.722656,413.507141 
	C587.618042,414.622406 583.755127,416.825470 578.889160,416.200989 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M659.283325,404.746460 
	C658.544678,404.985596 658.104065,404.984619 657.332886,404.983826 
	C650.677246,404.627808 645.247253,408.073395 639.235840,409.205963 
	C631.665527,410.632233 624.223389,413.316986 616.732605,414.582581 
	C609.282898,415.841278 602.384460,419.443420 594.352417,419.173615 
	C588.473389,418.193207 585.142151,422.976807 580.075073,424.043732 
	C578.148682,421.605621 577.360901,419.208923 578.092651,416.382599 
	C585.313354,416.650574 591.422241,413.661987 597.807617,412.193420 
	C611.822144,408.970215 625.674866,405.000183 639.653137,401.655090 
	C652.764282,398.517456 665.910278,395.659088 678.836304,391.737183 
	C684.137024,390.128845 689.741211,389.270844 695.098694,387.699097 
	C702.666809,385.478943 708.872131,380.616425 715.468811,376.543121 
	C716.739075,375.758759 717.914124,374.745056 719.279297,374.188141 
	C723.079041,372.638153 728.034241,375.669891 726.887878,379.156586 
	C724.483398,386.469971 721.234985,393.511261 715.889709,399.550903 
	C712.844910,397.839417 713.943176,395.475677 714.523743,393.314209 
	C715.336304,390.288940 716.932861,387.539337 718.691895,383.325134 
	C710.023987,385.877686 703.786377,390.956116 696.234497,393.746979 
	C695.981262,393.981689 695.490540,393.988098 695.245239,393.994049 
	C692.702209,395.116333 690.570374,396.783356 687.376648,396.098022 
	C686.251038,396.013062 685.504089,396.013306 684.383667,396.016541 
	C681.426636,398.327209 678.118408,398.778778 674.933289,399.441406 
	C669.614929,400.547821 664.785339,403.085175 659.283325,404.746460 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M714.694031,399.266113 
	C718.114563,398.872192 717.607178,396.094574 718.514404,394.299164 
	C720.614868,390.142395 722.844360,386.050781 725.027527,381.935944 
	C726.134399,379.849701 726.538025,377.784729 724.449585,376.079102 
	C722.523315,374.505981 720.496094,374.697052 718.465210,376.032593 
	C713.965210,378.991821 709.284607,381.702606 704.962585,384.899902 
	C699.208862,389.156342 692.029663,389.626801 685.748230,392.513672 
	C683.377258,393.603394 679.778503,391.185852 676.925537,393.063049 
	C669.557007,397.911438 660.866882,399.233368 652.636841,400.519958 
	C641.619629,402.242340 631.391907,406.464111 620.660095,408.598328 
	C608.031128,411.109833 595.743652,414.763519 583.260376,417.720917 
	C581.757935,418.076874 580.287720,418.193176 578.796387,416.611816 
	C578.529114,416.255432 578.588440,416.226532 578.545288,416.206635 
	C587.668640,413.807129 596.272095,409.729309 605.960144,408.744965 
	C613.411133,407.987915 620.820129,405.571381 627.922607,402.996063 
	C634.464233,400.624084 641.200806,399.733521 647.921692,398.563812 
	C655.880981,397.178680 663.115234,392.980194 670.908997,391.822754 
	C680.611084,390.381836 689.496399,386.539185 698.910950,384.377686 
	C705.089478,382.959167 709.984497,378.513824 715.301392,375.169983 
	C717.685059,373.670898 719.826599,372.022858 722.861328,371.720215 
	C724.904053,371.516571 726.830688,370.674622 728.166321,372.839844 
	C729.516113,375.027863 730.033508,377.148651 728.627563,379.801453 
	C725.232544,386.207825 722.220581,392.817230 719.072021,399.721497 
	C719.187134,400.330627 719.284424,400.563049 719.300598,401.165924 
	C718.249451,402.986389 717.599792,404.874115 714.920532,404.231995 
	C714.081299,403.379242 713.837158,402.635193 713.769165,401.425812 
	C713.880859,400.482666 714.081482,399.985352 714.694031,399.266113 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M571.323120,398.258911 
	C567.418030,395.141388 570.575317,394.638641 572.504395,394.140991 
	C581.945496,391.705719 591.410095,389.358368 600.890869,387.081970 
	C614.087036,383.913483 627.301636,380.821716 640.514404,377.722992 
	C655.793762,374.139587 671.073730,370.557983 686.365417,367.027740 
	C693.937134,365.279785 701.535828,363.648956 709.533936,361.955048 
	C711.597290,360.676605 713.712891,360.463470 715.762695,359.287231 
	C718.461426,359.260101 721.084106,358.237640 723.548340,360.552185 
	C723.777405,361.379517 723.610779,361.804626 723.111938,362.438416 
	C721.452515,363.496674 719.823730,363.663147 717.991333,364.289154 
	C716.490234,364.393341 715.440247,364.744751 714.225830,365.678406 
	C713.688782,365.949738 713.463318,366.043304 712.878845,366.230103 
	C711.515015,366.458099 710.513611,366.633087 709.100403,366.622650 
	C664.121643,378.523926 618.839600,387.463745 573.694763,398.783020 
	C572.715698,398.873322 572.176697,398.751923 571.323120,398.258911 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M712.661438,365.189087 
	C712.948792,364.895508 713.353333,364.726440 713.567017,364.677368 
	C714.692444,364.167023 715.730774,364.026154 716.887085,363.357635 
	C718.851562,362.770508 720.548340,362.332306 722.621765,361.952087 
	C724.142334,363.798492 723.067200,364.773193 721.362061,365.842682 
	C719.400879,366.036804 719.118103,367.489838 718.185303,368.714355 
	C717.690002,369.265808 717.381958,369.545929 716.711060,369.841522 
	C715.403076,370.068359 714.321228,369.862427 713.222900,370.790710 
	C712.193237,371.217041 711.377197,371.421112 710.338867,371.851624 
	C709.113770,372.777222 708.334045,373.721283 707.195679,374.721954 
	C706.963318,374.917267 706.526855,375.017975 706.305420,375.051880 
	C705.515015,375.531677 704.934814,375.959991 703.914612,376.355804 
	C702.589355,376.742676 701.828186,377.426544 700.568237,378.009460 
	C686.483826,380.405243 673.257812,384.792938 659.717407,387.668793 
	C640.254272,391.802551 621.217407,397.585480 601.764038,401.675476 
	C596.107971,402.864624 590.337585,403.393829 584.695923,404.760010 
	C581.236267,405.597809 578.078430,406.749023 575.007385,409.223206 
	C571.918152,407.872437 572.991882,404.927460 572.035706,402.392578 
	C573.213318,400.347015 575.136353,400.107819 576.873535,399.756226 
	C592.631409,396.567200 608.270569,392.886688 623.889099,389.069916 
	C636.893066,385.892151 650.198853,383.878632 663.113464,380.409637 
	C678.599243,376.250031 694.532776,373.595612 709.433960,367.350494 
	C710.521240,366.894806 711.443298,366.182678 712.661438,365.189087 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M574.653564,409.514404 
	C575.579895,404.303864 580.080322,403.934540 583.688049,403.121613 
	C591.202515,401.428284 598.959167,400.784027 606.444702,398.992096 
	C624.461853,394.678986 642.340027,389.837494 660.450928,385.842285 
	C671.718445,383.356689 682.930847,380.346344 694.149780,377.501465 
	C696.048645,377.019958 698.095215,377.121002 700.506165,376.948608 
	C701.523621,376.465759 702.059631,375.934204 702.805298,375.212769 
	C703.775696,375.010651 704.545044,375.007660 705.699219,375.045288 
	C705.810547,375.866547 705.536926,376.647247 705.129272,377.713257 
	C700.044373,381.252228 694.225281,381.919983 688.671692,383.132904 
	C673.525146,386.441010 658.799500,391.385132 643.278381,394.717377 
	C642.517273,394.958466 642.084412,394.960999 641.326965,394.972778 
	C633.488708,396.221588 626.361755,398.970459 618.933655,400.586151 
	C608.266663,402.906311 597.811646,406.199951 587.010620,407.896210 
	C583.110352,408.508728 579.727112,411.293701 575.219238,410.212646 
	C574.902222,409.967834 574.693176,409.685516 574.653564,409.514404 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M712.561096,364.861176 
	C712.204407,370.347717 706.953857,369.719757 703.711304,371.005890 
	C694.159851,374.794403 683.765747,376.009460 674.032898,379.204742 
	C659.826538,383.868713 645.042969,385.806885 630.604431,389.310791 
	C611.471375,393.954010 592.328918,398.803375 572.443237,402.019012 
	C570.846130,401.189941 570.981934,399.947845 570.982544,398.383484 
	C571.429077,398.024597 571.879395,398.029877 572.666992,398.045898 
	C578.824158,398.991882 583.887573,395.841522 589.342773,394.820404 
	C597.908264,393.217133 606.389160,390.958374 614.857361,388.799042 
	C629.218079,385.137146 643.805969,382.508514 658.152344,378.715515 
	C668.024109,376.105591 678.194519,374.500366 688.046265,371.645386 
	C694.839050,369.676849 701.870728,368.518951 708.742065,365.437958 
	C710.113220,365.087372 711.143311,364.957092 712.561096,364.861176 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M539.226807,481.171204 
	C538.992676,479.542725 538.997131,478.186340 538.998657,476.423706 
	C556.067261,471.410309 573.144958,466.831757 591.220886,465.023651 
	C592.185791,465.208923 592.576904,465.562134 592.913086,466.491943 
	C593.054016,467.444977 592.980774,467.984222 592.594543,468.863037 
	C591.816406,469.812531 591.106995,470.175598 589.943970,470.485382 
	C586.452881,471.562958 583.204834,472.043304 579.734863,473.155396 
	C578.887512,473.356903 578.395325,473.419647 577.533691,473.478210 
	C574.116516,474.219666 571.075439,474.975677 567.709595,475.885681 
	C566.709229,476.214813 566.021912,476.322784 564.997803,476.430664 
	C562.903931,476.550049 561.326233,477.150909 559.634155,478.374451 
	C559.162292,478.662079 558.963806,478.762909 558.449707,478.978516 
	C557.701111,479.209686 557.261414,479.290070 556.483765,479.367859 
	C555.931030,479.405701 555.710327,479.414856 555.160156,479.430725 
	C553.746582,479.457001 552.651123,479.385956 551.305664,479.942749 
	C550.555969,480.153687 550.118591,480.242706 549.340942,480.349731 
	C548.553101,480.421722 548.100586,480.445618 547.306641,480.487274 
	C545.173767,480.974854 543.369019,481.381195 541.199707,481.787994 
	C540.358582,481.788757 539.900574,481.657410 539.226807,481.171204 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M574.907654,410.301575 
	C591.493958,405.053009 608.581909,402.223938 625.335144,397.981812 
	C630.355164,396.710693 635.032654,393.906097 640.741455,394.732300 
	C635.535828,398.737946 628.909790,399.061615 622.821716,400.869080 
	C612.895996,403.815826 602.677307,405.723602 592.651733,408.400330 
	C587.311096,409.826233 581.812683,410.645996 576.320190,412.955994 
	C575.598145,412.219238 575.255615,411.427277 574.907654,410.301575 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M721.363037,366.201965 
	C720.874878,364.476410 722.598450,363.959930 722.970459,362.341492 
	C723.104614,361.710419 723.274170,361.452332 723.677612,361.076996 
	C724.077393,360.907318 724.298096,360.858612 724.656555,360.690002 
	C725.624756,360.477448 726.446777,360.347015 727.681641,360.240631 
	C728.748657,360.064697 729.393921,359.840424 730.345093,359.425446 
	C731.145020,359.162903 731.625793,359.122162 732.485718,359.233154 
	C739.283875,360.456451 740.454407,361.943695 738.288330,366.856781 
	C733.645874,362.610382 729.222290,361.580292 724.465637,366.433807 
	C723.654053,367.261963 722.596191,366.775085 721.363037,366.201965 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M590.811462,458.429321 
	C593.270752,458.044464 595.682800,458.040314 598.544434,458.035034 
	C601.419373,458.672974 604.305115,456.705505 606.464722,459.696777 
	C606.514343,460.299774 606.477173,460.544647 606.237793,461.104279 
	C605.445557,461.913544 604.754028,462.187317 603.640137,462.386658 
	C601.094299,462.165863 599.531128,463.309662 597.717163,464.867371 
	C595.718994,465.517700 594.119812,465.594849 592.325195,464.258911 
	C591.589417,462.268585 591.176819,460.539337 590.811462,458.429321 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M591.900635,464.253906 
	C593.707153,463.990051 595.412292,463.982239 597.543884,463.956238 
	C599.147583,462.420624 600.922180,462.061829 602.911194,461.389771 
	C603.911499,461.219543 604.615173,461.203033 605.670776,461.197449 
	C608.167664,460.533112 610.356506,458.760315 612.411987,461.855103 
	C611.024536,465.611359 607.645630,464.942108 604.651367,465.978821 
	C603.772034,466.272675 603.252502,466.366577 602.327759,466.431183 
	C598.816406,466.362701 596.049866,469.240234 592.438416,467.288879 
	C592.017029,466.593536 591.902039,466.150970 591.710083,465.371735 
	C591.688538,464.860046 591.743896,464.684998 591.900635,464.253906 
z"/>
<path fill="#101925" opacity="1.000000" stroke="none" 
	d="
M715.647888,359.059753 
	C715.176941,361.735046 712.719116,361.437500 710.326782,361.953339 
	C710.800903,359.289154 713.251404,359.565094 715.647888,359.059753 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M584.975098,440.296326 
	C585.928894,440.203674 587.018494,440.272034 586.108398,441.876770 
	C585.567322,441.561493 585.250671,441.077515 584.975098,440.296326 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M737.874146,367.390991 
	C740.225342,361.463074 735.948364,361.285675 732.230225,360.174408 
	C731.649658,359.633698 731.534424,359.294250 731.935791,358.743469 
	C732.603943,358.480896 733.004883,358.353821 733.721069,358.198517 
	C734.580200,358.062347 735.118408,357.933533 735.896118,357.634277 
	C736.581299,357.441681 737.002991,357.334595 737.752380,357.224152 
	C742.299561,357.841461 743.574158,360.387726 741.913391,364.040070 
	C739.324280,369.733826 738.361572,376.238342 733.340576,380.951599 
	C732.565613,375.823090 734.403992,371.488495 737.874146,367.390991 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M731.900879,358.497131 
	C732.149780,358.896362 732.090149,359.183350 732.002686,359.688110 
	C731.523438,359.973938 731.065552,360.040588 730.267090,360.174286 
	C729.841309,360.067474 729.756226,359.893646 729.841614,359.484863 
	C730.541016,358.962006 731.069946,358.674103 731.900879,358.497131 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M735.867249,357.432739 
	C735.817444,358.345764 735.385742,358.977203 734.200562,358.344666 
	C734.549072,357.885803 735.061951,357.601288 735.867249,357.432739 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M729.718750,359.260681 
	C730.012146,359.249908 729.947693,359.741791 729.937073,359.991577 
	C729.512756,360.779999 729.024536,361.006012 728.241028,360.436249 
	C728.538025,359.933594 728.981689,359.602539 729.718750,359.260681 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M945.042358,1139.653809 
	C944.250000,1140.000000 943.500000,1140.000000 942.375000,1140.000000 
	C940.003296,1136.406616 936.193237,1138.989502 933.285645,1137.262695 
	C933.002319,1135.674927 932.993652,1134.349731 933.009827,1133.024780 
	C933.028381,1131.504028 933.064209,1129.905273 934.836182,1129.335083 
	C936.701050,1128.735107 937.448853,1130.178955 938.280945,1131.451538 
	C939.005615,1132.559814 939.803772,1133.800781 941.339844,1133.644287 
	C946.285950,1133.139771 945.861450,1136.084717 945.042358,1139.653809 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M948.010376,1145.379517 
	C950.541565,1145.399536 952.302551,1143.996948 954.239075,1142.630005 
	C955.759644,1141.556885 958.070129,1141.350098 959.151733,1143.109863 
	C960.521362,1145.338379 958.220337,1146.766602 956.716248,1146.963867 
	C953.822937,1147.343384 950.016174,1152.090698 948.010376,1145.379517 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M894.690430,1080.779297 
	C896.714355,1081.000000 898.428711,1081.000000 900.571533,1081.000000 
	C901.429138,1081.328369 901.727844,1081.749512 901.957031,1082.654053 
	C901.932434,1084.516479 901.296997,1085.559448 899.392151,1085.960693 
	C894.799255,1086.131958 891.547119,1089.279785 887.281250,1089.551392 
	C885.357300,1089.673828 883.723755,1089.392334 882.049683,1088.816772 
	C880.378601,1088.242554 879.801392,1086.996216 880.432861,1085.282104 
	C881.065613,1083.564941 882.088806,1082.947021 883.845703,1083.918579 
	C885.481873,1084.823364 887.182129,1085.607666 888.828796,1083.299805 
	C890.197632,1082.503418 891.395264,1082.006958 892.796448,1081.255249 
	C893.386414,1080.621704 893.846680,1080.474609 894.690430,1080.779297 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M849.707031,1076.338013 
	C849.138794,1072.313721 851.795044,1072.872070 853.791443,1073.234863 
	C856.139282,1073.661743 855.885986,1075.413330 854.956848,1077.058228 
	C853.474365,1079.682495 854.308411,1082.574341 853.889648,1085.314941 
	C853.637878,1086.962158 853.172424,1088.803711 851.058838,1088.776245 
	C848.525085,1088.743408 849.400330,1086.528076 848.955688,1085.074951 
	C848.043030,1082.092407 852.079712,1079.722900 849.707031,1076.338013 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M831.494019,1063.192383 
	C832.834045,1060.044189 834.856201,1059.129883 837.519653,1060.733643 
	C839.224121,1061.760010 840.633667,1068.756592 839.655640,1069.967896 
	C839.136047,1070.611450 838.530273,1070.624268 837.953064,1070.239502 
	C835.294922,1068.467773 831.872559,1067.428467 831.494019,1063.192383 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M866.363525,1078.599365 
	C866.155762,1082.079834 864.138000,1083.369995 861.131348,1082.868652 
	C857.865906,1082.324097 859.222351,1079.559448 859.061462,1077.653564 
	C858.887085,1075.586792 859.952637,1074.956787 861.819153,1075.952148 
	C863.265259,1076.723389 864.688171,1077.538086 866.363525,1078.599365 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M899.240479,1086.248047 
	C899.634766,1085.204956 900.269592,1084.409912 900.952209,1083.307373 
	C902.037354,1081.959961 903.423401,1081.362305 904.718384,1080.145874 
	C905.545471,1080.180298 906.090942,1080.360596 906.818176,1080.770508 
	C907.041199,1086.317261 903.051514,1086.119873 899.240479,1086.248047 
z"/>
<path fill="#CFCED0" opacity="1.000000" stroke="none" 
	d="
M872.219849,1110.791992 
	C869.820557,1110.171143 867.415527,1113.033081 865.167297,1110.296265 
	C866.599182,1109.998047 868.131714,1109.988403 869.583557,1108.989502 
	C871.346191,1107.776855 872.601990,1108.056763 872.219849,1110.791992 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M904.871094,1079.739502 
	C905.103516,1082.318726 905.076660,1084.497803 901.347961,1083.042847 
	C901.000000,1082.555542 901.000000,1082.111084 901.000000,1081.333252 
	C901.053650,1079.864868 900.909119,1078.637817 902.664551,1078.068604 
	C903.580688,1078.493042 904.161438,1078.985962 904.871094,1079.739502 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M754.447876,974.000000 
	C747.599670,975.143982 741.253784,976.648743 734.357788,976.950684 
	C735.258179,972.062988 739.306763,974.692932 742.563965,973.997803 
	C743.390747,973.559448 743.841919,973.208740 744.711182,973.026489 
	C745.443787,972.999146 745.887512,972.998352 746.664062,972.997559 
	C752.298889,967.361450 759.786377,966.220276 766.572144,965.583801 
	C773.199463,964.962280 779.891357,963.352295 787.331665,964.558716 
	C786.988953,968.512817 783.755005,966.715759 781.642944,967.484375 
	C778.003479,968.808716 773.822266,968.818420 771.414673,972.577942 
	C766.267212,969.944214 761.942688,975.635925 756.876465,974.026001 
	C756.274292,973.834656 755.558777,974.000000 754.447876,974.000000 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M797.671570,963.735840 
	C797.860718,965.755554 796.970520,967.254578 795.326721,967.533447 
	C793.492126,967.844666 792.627197,966.263000 792.044556,964.338989 
	C792.186279,963.788330 792.372620,963.576660 792.930237,963.159973 
	C794.728271,962.504883 796.087219,962.585510 797.671570,963.735840 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M793.127197,963.687927 
	C793.000000,964.000000 792.500000,964.000000 792.250000,964.000000 
	C790.896057,964.306274 789.792114,964.612549 788.345337,964.960449 
	C788.002441,965.002197 788.000000,965.000000 788.000000,965.000000 
	C787.420227,964.317139 787.251587,963.555969 787.781128,962.351685 
	C788.571411,961.185486 789.416931,961.016235 790.665771,961.005981 
	C791.678589,961.862793 793.002991,962.109741 793.127197,963.687927 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M726.794312,1033.155762 
	C727.863953,1033.129150 729.122437,1032.722168 729.337952,1033.912231 
	C729.535339,1035.002197 728.373413,1034.829590 727.333252,1034.991211 
	C726.368958,1034.557495 726.052246,1034.038330 726.794312,1033.155762 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M759.114319,1037.295410 
	C759.000000,1035.933350 759.000000,1034.866577 759.000000,1033.399902 
	C764.895508,1033.478149 765.013733,1039.036499 767.235413,1042.627197 
	C768.635803,1044.890625 766.654785,1046.122925 764.345093,1046.953369 
	C764.173889,1044.977539 763.180237,1043.326538 762.147583,1041.320435 
	C761.196838,1039.765503 760.073975,1038.791016 759.114319,1037.295410 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M758.666138,1037.045654 
	C760.604004,1037.643066 763.372009,1037.179565 762.123779,1040.708130 
	C760.550964,1040.982666 759.060608,1041.136230 757.757690,1040.312866 
	C755.359924,1038.797363 756.423767,1037.859741 758.666138,1037.045654 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M737.594238,1040.460327 
	C736.235962,1040.420898 736.002869,1039.716309 736.396729,1038.868774 
	C736.693787,1038.229614 737.461609,1037.996582 738.027405,1038.380249 
	C738.958618,1039.011597 738.492249,1039.726196 737.594238,1040.460327 
z"/>
<path fill="#D0CFD0" opacity="1.000000" stroke="none" 
	d="
M895.067017,1080.661865 
	C894.555542,1081.000000 894.111084,1081.000000 893.333313,1081.000000 
	C892.987915,1080.006226 892.881592,1079.002441 892.987549,1078.021362 
	C893.120056,1076.794189 891.528198,1075.431763 893.058655,1074.403198 
	C894.010742,1073.763428 894.923706,1074.550293 895.670410,1075.246094 
	C897.207703,1076.678589 897.142700,1078.067749 895.597961,1079.461304 
	C895.369690,1079.667114 895.285095,1080.032349 895.067017,1080.661865 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M793.416626,964.000000 
	C792.365173,963.205627 791.730347,962.411255 791.047729,961.309692 
	C794.916443,958.958740 798.596558,956.334961 803.610779,956.014771 
	C803.771912,958.013550 802.120483,958.426575 800.622925,959.000671 
	C798.661011,959.752808 798.200500,961.412903 798.009399,963.624878 
	C796.611084,964.000000 795.222229,964.000000 793.416626,964.000000 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M787.625122,962.165222 
	C788.000000,962.750000 788.000000,963.500000 788.000000,964.625000 
	C774.396118,966.730408 760.181335,965.347900 747.358887,972.951111 
	C748.035156,969.081482 750.986084,967.859680 754.612122,966.937927 
	C765.340149,964.211121 776.290894,963.290161 787.625122,962.165222 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M744.818054,972.795898 
	C744.527832,973.313110 744.055725,973.626221 743.290527,973.968445 
	C743.198181,973.126221 743.205750,972.029846 744.818054,972.795898 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M840.287048,941.928223 
	C840.104126,941.093323 840.402100,940.341919 841.698364,940.065918 
	C841.891541,940.906555 841.595581,941.659546 840.287048,941.928223 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M858.584473,918.818726 
	C859.473267,917.919800 860.344788,917.391968 861.510010,916.777161 
	C862.131958,917.145752 862.448120,917.642517 863.246338,916.839172 
	C863.410339,916.607727 863.573792,916.380127 863.710754,916.377747 
	C863.906006,917.262329 863.964233,918.149231 864.032898,919.474854 
	C861.855652,919.679260 860.987305,921.391724 859.743164,922.684937 
	C856.924927,925.614441 853.971680,928.414001 851.010010,931.600586 
	C850.514221,931.942505 850.081787,931.950256 849.324402,931.956787 
	C843.109924,933.956299 837.388733,936.400818 831.286743,938.784546 
	C831.009766,938.997559 831.000000,939.000000 830.997742,939.004883 
	C825.777344,941.771179 820.366699,943.572754 814.418152,941.804077 
	C806.983704,939.593811 802.316711,944.285156 797.082153,948.644104 
	C795.031372,949.708679 792.601562,949.022217 791.166138,951.444946 
	C790.074097,951.900391 789.326843,951.868286 788.286560,951.273682 
	C787.542175,951.051941 787.113892,951.053528 786.364380,951.059570 
	C782.553833,953.774231 778.606323,952.817566 774.322754,952.371582 
	C770.592468,949.642456 766.728027,949.440552 762.409363,949.539795 
	C761.180969,946.617676 762.704102,944.316528 763.660522,941.609619 
	C764.770386,940.967468 765.578918,940.581177 766.710632,940.101807 
	C767.905884,939.068176 768.907471,938.444336 770.588928,938.856445 
	C771.090942,939.023438 771.267822,939.021973 771.710022,939.023193 
	C776.054871,947.066895 789.134644,948.931396 796.767456,942.273926 
	C798.510864,940.470276 799.993835,938.915466 801.759521,937.203979 
	C805.834167,933.204407 808.882568,932.736450 814.089966,934.578552 
	C818.222412,936.040283 822.506958,937.935486 827.074585,934.472595 
	C827.860474,934.007812 828.342163,933.816589 829.219971,933.611572 
	C830.777222,933.399658 831.953918,933.031555 832.820374,932.292053 
	C837.223328,928.534302 841.858276,926.105042 848.057556,926.230652 
	C852.575012,926.322144 855.331238,921.992126 858.584473,918.818726 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M761.727295,948.869995 
	C765.715698,948.624207 769.616516,946.581787 772.930176,950.633545 
	C770.649536,953.820435 767.395630,951.764099 764.321777,952.738770 
	C763.145508,953.155396 762.296021,953.337585 761.226318,953.764771 
	C760.455627,954.525208 759.934387,955.069946 759.200562,955.801758 
	C755.718872,956.863647 753.251831,959.605347 749.390259,960.001709 
	C748.044556,961.165771 746.757507,961.873474 745.219666,962.766968 
	C744.248962,962.994263 743.505920,963.003235 742.391357,963.015564 
	C740.218018,964.886536 737.226868,964.523499 735.235962,966.737915 
	C734.543701,966.976074 734.100220,966.979431 733.323975,966.978760 
	C729.280273,965.709229 725.573792,967.722046 721.413818,966.978760 
	C723.289001,963.509460 727.212402,965.084106 730.303345,964.021973 
	C737.597534,961.515625 745.308899,960.114746 751.956360,955.393799 
	C752.732544,952.289612 755.287415,952.184692 757.612549,951.205200 
	C758.663025,950.762756 759.422607,950.449768 760.124634,949.508972 
	C760.665222,949.016479 761.036316,948.837585 761.727295,948.869995 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M795.690674,948.212646 
	C797.110229,947.024841 798.434387,946.215942 799.299072,945.062683 
	C802.765076,940.439880 807.158447,938.561646 812.848633,939.997803 
	C818.728943,941.481873 824.500671,940.994629 830.589478,939.005737 
	C828.680420,942.392944 824.845764,943.202698 821.349243,943.848083 
	C816.434265,944.755432 811.541138,945.740112 806.305176,945.312317 
	C802.371582,944.991089 799.727356,949.820068 795.349731,950.939331 
	C794.187195,949.991943 794.417603,949.156616 795.690674,948.212646 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M764.381775,953.035828 
	C765.933289,949.132751 769.447388,951.778198 772.573181,950.992310 
	C777.047729,950.996887 781.077942,951.005981 785.575684,951.039673 
	C785.812683,954.451965 784.615479,955.879089 780.686951,955.256226 
	C775.412109,954.420044 769.851746,955.590332 764.381775,953.035828 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M831.415649,939.000305 
	C835.959106,934.423950 842.169861,932.801758 848.761169,931.733887 
	C843.988159,936.046692 838.002991,937.764160 831.415649,939.000305 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M735.351807,966.933350 
	C735.590027,963.296143 738.828186,963.803467 741.637939,963.021606 
	C741.157227,966.325256 737.922302,965.772522 735.351807,966.933350 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M795.618835,947.994019 
	C795.686279,948.779175 795.375305,949.566284 795.030029,950.669434 
	C794.556091,950.993469 794.116394,951.001587 793.346985,951.012085 
	C792.614380,951.386841 792.144348,951.517456 791.288452,951.191040 
	C791.253906,948.349304 793.078247,947.930481 795.618835,947.994019 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M790.595337,950.979614 
	C791.424744,950.984436 791.879700,950.993225 792.675964,951.008301 
	C791.779663,953.831848 790.101562,953.681274 788.063843,951.393188 
	C788.720642,951.028137 789.470825,951.005859 790.595337,950.979614 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M745.354004,962.983643 
	C745.226379,961.011108 746.776428,960.548767 748.669861,960.048584 
	C748.825867,962.013733 747.695068,962.968933 745.354004,962.983643 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M660.819336,990.791504 
	C660.529846,991.309448 660.057190,991.624207 659.291077,991.968384 
	C659.193298,991.120605 659.207520,990.032288 660.819336,990.791504 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M759.310547,955.946411 
	C759.103943,955.110229 759.390137,954.353333 760.692322,954.059937 
	C760.896729,954.895874 760.608521,955.650696 759.310547,955.946411 
z"/>
<path fill="#1A2537" opacity="1.000000" stroke="none" 
	d="
M880.896606,896.145325 
	C881.522949,896.197205 881.872375,896.522522 882.401123,897.011169 
	C883.147400,897.333862 883.458069,897.706787 883.405151,898.675415 
	C883.342468,899.416382 883.386963,899.775208 883.448730,900.403809 
	C882.173462,901.490234 882.064331,902.795898 881.952698,904.149048 
	C881.648132,907.839905 879.131714,909.013428 875.906860,909.503784 
	C873.272888,909.904297 872.748840,907.906555 872.355957,906.253967 
	C871.608887,903.111816 871.303345,899.804871 874.984680,898.105591 
	C876.747070,897.292114 878.734253,896.965698 880.896606,896.145325 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M886.024414,899.318115 
	C886.910522,899.375488 888.186646,899.553406 888.599182,900.245911 
	C893.334412,908.193359 900.845154,909.450073 909.575989,909.036133 
	C905.154785,913.560669 890.044434,910.628174 887.696350,904.714294 
	C887.044128,903.071594 886.569458,901.358459 886.024414,899.318115 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M865.194458,911.817078 
	C864.025024,911.260071 863.835144,910.393799 864.771484,909.193604 
	C865.961365,909.744202 866.159363,910.609863 865.194458,911.817078 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M883.622498,899.034302 
	C883.114319,898.649109 882.930664,898.240662 882.663757,897.503357 
	C884.120728,895.664551 884.767151,896.507996 885.175415,898.453430 
	C884.806091,898.850159 884.376587,898.930664 883.622498,899.034302 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M864.047729,916.269409 
	C863.712524,916.185730 863.497192,915.850708 863.580750,914.994751 
	C863.975647,914.988586 864.071655,915.503357 864.047729,916.269409 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M759.177979,951.781738 
	C756.858643,952.880920 754.753113,953.794678 752.333008,954.869019 
	C747.981750,955.009827 744.497009,956.229431 740.824890,958.167114 
	C735.962708,960.732727 730.599854,962.240967 724.626404,961.305115 
	C720.059570,960.589661 715.486267,960.881409 710.652100,962.850281 
	C698.242126,967.904541 686.318115,974.466858 672.811096,976.581116 
	C665.234131,977.767212 657.880249,977.368591 650.758118,974.335205 
	C649.087891,973.623779 648.176331,972.500488 647.907593,970.623047 
	C647.684998,969.067810 647.429749,967.063965 644.569336,968.018311 
	C643.983643,966.823914 644.646179,966.016418 645.791077,965.154480 
	C647.469666,964.007812 647.372070,962.098572 648.837280,960.763062 
	C651.433411,960.086914 652.068115,961.762207 652.446960,963.382507 
	C653.169373,966.472534 655.368286,968.005249 657.955750,969.359619 
	C667.079834,974.135498 676.118469,972.655884 684.638184,968.245117 
	C689.837036,965.553650 695.383972,964.012146 700.680664,961.723022 
	C705.381592,959.691406 710.012085,957.705139 712.981812,952.793823 
	C712.880249,948.040466 710.885010,943.998169 710.603882,939.303223 
	C710.762268,938.970520 711.002258,939.040405 711.126953,939.049316 
	C711.791748,938.447815 712.442993,938.021423 713.647461,937.824951 
	C714.579041,937.843628 715.087646,937.980530 715.891113,938.439331 
	C716.531677,939.079468 716.757935,939.536438 716.937683,940.409973 
	C718.678772,954.677368 725.671143,958.431458 736.680542,954.153564 
	C741.707092,952.200500 746.280396,949.414062 752.002502,949.026855 
	C755.924988,948.761414 759.395020,946.167114 761.107666,941.656982 
	C761.838440,940.747131 762.493530,940.426392 763.663452,940.586548 
	C764.023865,940.721313 763.972595,941.035461 763.967224,941.194641 
	C764.129517,943.852722 764.322205,946.357483 762.174072,948.681885 
	C761.704712,949.133057 761.450256,949.326721 761.124573,949.796143 
	C760.456543,950.526306 759.931458,951.068726 759.177979,951.781738 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M598.995056,967.376221 
	C599.779968,967.598999 600.721313,968.082336 601.295593,968.842896 
	C608.311646,978.134949 617.710693,979.524902 628.722473,977.164307 
	C622.848816,983.145813 615.648804,981.285828 608.828369,978.964172 
	C603.759399,977.238586 599.309021,974.085083 598.995056,967.376221 
z"/>
<path fill="#D0CFCE" opacity="1.000000" stroke="none" 
	d="
M690.146118,828.617310 
	C689.003723,831.822815 687.242310,833.399841 684.015198,833.025696 
	C680.892517,832.663696 679.063416,833.663025 678.896179,837.337158 
	C678.734070,840.899414 677.031311,843.205994 672.705139,842.188293 
	C669.982666,841.547913 667.973755,842.005249 666.658630,845.508423 
	C665.487427,848.627991 661.735596,850.802246 663.725037,854.955383 
	C663.776306,855.062378 663.656799,855.386841 663.582336,855.399109 
	C658.677673,856.206543 657.174744,862.025452 652.382019,862.993835 
	C651.041626,863.264648 648.676514,862.257141 648.661255,864.222107 
	C648.647461,866.004395 650.010254,868.488464 652.052673,868.653748 
	C655.684326,868.947632 658.428650,869.315002 658.459839,873.975952 
	C658.468384,875.238525 661.347717,876.592346 661.881409,875.119263 
	C663.798767,869.828003 667.300903,872.949341 670.613892,872.999634 
	C671.567505,873.633850 671.491882,874.451782 671.582886,875.593262 
	C671.041016,877.120911 670.089783,877.721191 669.003357,878.239075 
	C659.978943,882.540771 656.150269,889.816162 657.040283,899.624329 
	C657.296936,902.453247 656.334961,904.844238 655.044189,907.599304 
	C651.973999,910.540833 649.912659,913.905945 646.823425,916.827271 
	C643.632751,919.578125 641.570618,918.751648 639.936707,915.502869 
	C638.643127,912.930786 637.963867,910.264282 637.907166,907.399109 
	C637.846985,904.361023 636.547791,903.595886 633.818481,905.124878 
	C631.162231,906.613037 628.244995,907.045410 625.196655,907.002930 
	C618.008728,906.902527 612.008850,910.911865 605.439758,912.954041 
	C604.189880,913.342712 604.261780,915.236328 603.179749,916.511230 
	C602.337769,917.651428 602.080444,918.898254 600.336670,918.969971 
	C598.073730,915.896667 595.554260,915.753662 594.084778,918.889221 
	C592.228516,922.850220 587.037170,924.158081 586.563843,929.065308 
	C586.491028,929.820862 585.309143,930.019592 584.428711,929.778625 
	C583.330627,929.478027 583.723877,928.595093 583.550598,927.822693 
	C582.064514,921.199951 581.744690,914.221863 577.577942,908.396057 
	C576.393250,906.739502 577.866516,905.645325 578.976929,904.673645 
	C579.963074,903.810913 581.347351,903.169556 580.541687,901.503235 
	C579.965820,900.312134 578.815186,900.029602 577.600830,900.029480 
	C574.264404,900.029053 571.532227,899.224121 570.770630,895.375549 
	C570.678833,894.911743 569.790405,894.192810 569.392029,894.264404 
	C563.439880,895.334656 557.391113,896.156433 552.129517,899.481934 
	C551.412476,899.935120 551.113098,900.773621 551.902954,901.863770 
	C551.688232,902.468079 551.230103,902.857422 550.477173,903.327087 
	C548.922180,903.173828 547.746765,902.680359 546.405518,902.650696 
	C542.727234,902.569458 540.748352,900.636841 540.769043,896.494629 
	C540.885864,895.874878 540.955322,895.633789 541.182129,895.056091 
	C543.718750,892.164062 544.406372,889.742554 541.295654,887.085938 
	C539.802429,885.810669 539.387878,883.818726 540.752991,882.126770 
	C542.051086,880.517761 543.834778,880.987122 545.280823,881.942200 
	C548.702637,884.202332 549.970398,883.212402 549.915771,879.303406 
	C549.882141,876.889709 551.730774,875.916870 553.779053,875.350647 
	C556.595947,874.571960 558.945129,873.136475 560.752502,870.783264 
	C562.988525,867.872009 566.449585,867.600891 569.788025,866.641479 
	C566.724121,865.249756 567.036438,863.214539 568.676025,860.787231 
	C572.514160,855.105164 578.645447,854.713440 583.914551,856.111389 
	C591.951965,858.243774 598.503479,855.280457 604.845886,851.908447 
	C610.332825,848.991333 616.227661,848.332886 621.960266,847.074036 
	C628.427429,845.653992 634.955566,845.004028 640.719604,841.235474 
	C645.520996,838.096191 651.412659,837.753479 656.777344,836.223694 
	C662.251038,834.662964 667.075317,832.090942 672.374451,829.468140 
	C672.896484,829.111267 673.120605,828.985413 673.712036,828.727051 
	C677.474609,827.380432 680.752441,825.780579 684.309631,825.096130 
	C687.020691,824.574402 689.714050,824.503296 690.146118,828.617310 
z"/>
<path fill="#1B273C" opacity="1.000000" stroke="none" 
	d="
M634.593567,959.447571 
	C635.214478,959.995422 635.644836,960.607849 635.954468,960.551941 
	C638.119812,960.160828 640.544617,959.566101 641.634949,962.035034 
	C642.848999,964.784180 640.319275,966.253540 638.667664,967.744385 
	C632.489441,973.321289 625.774414,973.540894 617.969971,970.916565 
	C612.183533,968.970886 607.520020,966.586731 603.144958,962.051758 
	C592.574829,951.095093 593.667664,929.885498 605.610657,920.159668 
	C609.727661,916.806885 613.856934,913.850952 618.862183,911.583557 
	C619.943848,909.530701 621.659546,909.371887 623.272461,909.543152 
	C632.403259,910.512939 640.176819,913.643860 644.012573,923.381714 
	C644.997681,926.114868 645.727600,928.479492 644.925537,931.387085 
	C642.288757,933.694275 642.591003,936.617432 642.015015,939.618103 
	C640.794250,941.992981 638.955750,942.859802 636.829956,943.537598 
	C631.333862,945.290161 625.516174,945.683350 620.074585,947.573425 
	C617.915833,948.323242 615.161682,948.127258 614.136963,951.074951 
	C614.381958,951.528137 614.484131,951.709045 614.708008,952.179443 
	C614.940369,952.868958 615.010437,953.276062 615.042847,953.992310 
	C615.030640,955.064331 614.935059,955.845398 615.549927,956.795654 
	C618.884460,959.859009 622.519775,960.986084 627.012573,960.056030 
	C628.389038,959.583069 629.275879,958.847839 630.685425,958.443970 
	C632.189270,958.423584 633.321350,958.597839 634.593567,959.447571 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M643.332703,924.274658 
	C638.659546,913.645935 630.069031,910.856384 619.397034,911.125732 
	C616.393127,910.985291 614.046509,911.889526 611.338013,912.863037 
	C610.329529,913.612488 609.501099,913.824280 608.331787,914.017822 
	C606.536194,914.894043 605.061707,915.741394 603.265625,916.679626 
	C602.692749,913.317383 602.938293,910.830139 607.702454,910.664673 
	C610.954102,910.551819 613.406250,907.114990 617.169128,906.818726 
	C620.699768,906.540894 623.978882,904.716553 627.815491,905.369995 
	C629.550659,905.665466 631.573303,904.348389 633.449707,903.715942 
	C635.025208,903.184937 635.909973,900.188660 637.976318,901.892090 
	C639.466553,903.120667 640.191589,905.359863 639.660156,907.357422 
	C638.731323,910.848938 640.998474,913.303894 642.024597,916.172058 
	C643.020630,918.956238 644.214233,916.939148 645.636230,916.179871 
	C647.616333,919.443420 647.627502,922.444275 643.332703,924.274658 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M634.980225,959.475525 
	C633.653564,959.821899 632.467041,959.951538 630.932983,959.988281 
	C630.465881,959.461487 630.586853,959.071838 630.805542,958.700195 
	C629.557434,958.646179 628.885803,959.423889 628.165894,960.667847 
	C622.590881,963.348206 618.235107,961.271362 614.026489,957.474976 
	C613.744507,956.939514 613.643677,956.696106 613.740845,956.111511 
	C614.796753,955.125244 615.754639,954.754944 617.212280,954.713440 
	C624.698975,955.526489 631.329834,953.706299 638.169312,951.040405 
	C638.762024,951.074707 638.994995,951.119812 639.545410,951.319458 
	C642.160583,955.506775 637.807556,955.618896 636.205811,957.742676 
	C635.749390,958.452148 635.434875,958.855469 634.980225,959.475525 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M636.423218,958.030457 
	C634.626648,956.684692 635.844177,956.156433 636.851990,955.584045 
	C638.111328,954.868958 639.576660,954.378540 639.932495,952.334961 
	C639.812012,951.346252 639.412720,950.802246 639.106812,949.848755 
	C638.976318,949.046692 638.993286,948.579895 639.172974,947.767090 
	C639.417542,947.213440 639.552185,947.007141 639.990723,946.584473 
	C641.587158,945.850403 642.758850,946.128174 644.129944,947.162476 
	C645.041748,948.184265 645.402039,949.102295 645.955444,950.321899 
	C646.970886,953.099121 648.254456,955.338623 649.720093,957.889526 
	C649.963135,958.980957 649.874695,959.693909 649.290344,960.661499 
	C648.800964,962.560547 649.580444,964.750793 646.455444,964.989258 
	C645.749146,963.965515 645.421692,962.915527 645.084778,961.868591 
	C643.142090,955.833008 642.291626,955.438660 636.423218,958.030457 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M608.175171,914.271484 
	C608.254639,912.639648 609.467041,913.186951 610.634033,912.963440 
	C610.456665,914.122192 609.757690,914.947632 608.175171,914.271484 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M672.750000,829.741150 
	C672.584656,830.681152 672.329102,831.668823 671.722351,831.987488 
	C668.262085,833.804382 664.285217,836.607910 661.351624,836.988892 
	C657.055176,837.546875 653.348145,839.715027 649.148621,840.009277 
	C645.583252,840.259155 642.564575,841.684753 639.576477,843.743591 
	C634.737854,847.077332 628.758484,847.199585 623.238342,848.646729 
	C617.457703,850.161987 611.510498,850.282715 605.818604,853.229004 
	C599.437988,856.531799 592.849365,860.485229 584.366333,857.690063 
	C580.059570,856.270996 573.862183,855.895569 570.232666,861.269287 
	C569.549438,862.280823 567.716309,862.928833 568.751099,864.511536 
	C569.527710,865.699463 570.885010,865.937561 572.325073,865.394653 
	C573.289795,865.030884 574.462158,864.879639 574.475708,866.370972 
	C574.484070,867.286499 573.541016,867.540588 572.659180,867.716980 
	C567.910278,868.666443 562.756042,868.814697 560.187866,874.123474 
	C559.884949,874.749573 558.846069,875.053406 558.109131,875.429810 
	C554.761353,877.139526 549.976868,876.746460 551.280396,883.506592 
	C551.962036,887.041626 547.967896,885.491394 545.796082,884.204102 
	C544.416626,883.386414 543.080444,880.636658 541.415039,883.136108 
	C540.179321,884.990662 541.924805,886.180847 543.773743,887.182190 
	C546.856567,888.851562 546.403198,891.481201 542.360352,894.947998 
	C540.039490,894.689575 538.138550,894.027649 536.168640,893.811279 
	C533.016541,893.464966 531.014099,894.487976 531.593750,898.678467 
	C533.515869,897.252014 532.774414,893.913391 535.374390,893.875000 
	C537.496277,893.843567 539.670593,894.173218 541.746338,895.761353 
	C541.786743,899.805176 543.080750,901.993225 547.369934,901.135071 
	C548.606506,900.887634 549.601318,901.352722 550.093994,903.042297 
	C550.182373,903.407410 550.262695,903.756470 550.133240,903.880249 
	C549.704712,904.309753 549.362671,904.553955 548.660889,904.791138 
	C547.552063,904.941467 546.701965,904.747070 545.709961,905.449463 
	C545.242249,905.714844 545.048950,905.815430 544.549927,906.044678 
	C539.258850,908.282776 535.388916,911.687317 532.681641,916.355225 
	C531.691833,918.061951 531.004700,919.946899 529.175415,921.483398 
	C527.068909,922.415527 525.261536,921.978760 523.500610,921.541504 
	C516.697937,919.852661 511.350159,915.691040 517.919128,908.295532 
	C518.445251,907.703125 518.958435,906.989075 518.228333,905.281067 
	C510.962158,907.590149 503.842255,911.364624 495.485229,910.756348 
	C494.076935,910.074524 493.551361,909.031250 492.894196,908.086975 
	C490.047363,903.996948 487.652313,904.154480 485.506470,908.547424 
	C484.682129,910.234924 484.361237,911.662231 486.815491,912.283569 
	C489.533722,912.971680 490.717621,914.600342 489.234558,917.669250 
	C487.836823,918.429382 486.674866,918.933350 485.274414,919.648315 
	C484.609772,920.473389 484.009125,920.777527 482.933960,920.833374 
	C480.928131,919.820190 479.292664,919.873352 477.335052,921.020447 
	C475.052063,921.184204 473.531647,920.662048 473.146484,918.114990 
	C473.532288,915.938232 474.763550,914.563843 475.609802,912.993042 
	C478.168518,908.243225 477.840607,906.686890 473.166412,902.818176 
	C472.745361,902.400635 472.600525,902.210693 472.323608,901.688049 
	C472.152344,895.838196 476.500122,897.021973 479.767242,896.435303 
	C480.359161,895.056946 479.067139,894.365723 478.501801,893.540161 
	C477.386932,891.912048 478.113678,888.366028 476.169128,888.546631 
	C473.078003,888.833496 469.422546,888.856506 467.058990,891.759644 
	C466.100189,892.937378 464.974945,893.890503 463.556610,894.496033 
	C458.664001,896.584595 455.889740,894.825806 455.723907,889.508301 
	C455.477203,881.597412 458.185333,878.576904 465.906036,878.990173 
	C471.733276,879.302185 473.995239,874.405334 477.355194,871.459351 
	C479.617035,869.476196 480.932281,866.448303 484.581665,866.172607 
	C476.825745,864.457520 475.571472,861.547363 480.209045,855.647461 
	C481.697937,853.753235 481.555176,852.178406 480.829468,850.161255 
	C477.866425,841.925354 480.383698,833.726196 481.351349,825.565186 
	C481.690308,822.706238 484.708282,821.137207 486.432495,818.926941 
	C487.788818,817.188354 490.384552,815.134399 488.863159,812.989807 
	C487.348541,810.854736 484.502258,812.441406 482.416412,813.259155 
	C479.604279,814.361572 476.996124,817.805176 473.852051,815.249878 
	C470.444702,812.480652 470.160583,808.000183 469.949921,803.852600 
	C469.859070,802.064087 470.402832,800.285461 470.194641,798.411560 
	C469.574127,792.826538 471.100403,791.648621 476.490082,793.271545 
	C472.749817,791.717407 470.412231,789.330933 469.272736,785.148010 
	C468.239502,781.355286 463.899963,781.922058 460.415833,781.047913 
	C454.731781,776.755188 454.815033,775.413208 460.861816,771.298584 
	C462.504761,769.270752 460.395355,769.129761 459.617432,767.984741 
	C459.042236,766.369934 459.448273,765.241394 460.467682,763.928467 
	C462.588135,762.883545 464.135010,761.827576 465.301605,760.045654 
	C466.862274,757.661682 469.337097,757.031006 471.901154,758.119446 
	C474.755341,759.331116 474.370880,761.842407 473.481873,764.098022 
	C472.466797,766.673523 472.818726,768.246948 475.804871,768.686646 
	C476.623016,768.807129 477.560059,768.984985 478.128326,769.537720 
	C484.420929,775.659607 489.897705,772.656738 495.499664,768.293823 
	C497.626495,766.637451 497.485809,764.730103 496.573730,763.040039 
	C494.455841,759.115479 495.254120,754.489380 493.213898,750.595154 
	C492.831512,749.865295 492.577423,748.628601 493.083374,748.198242 
	C496.910858,744.942566 495.997284,739.748779 498.596313,735.713013 
	C502.198914,736.587830 505.613159,741.058350 505.117737,744.947510 
	C504.558777,749.335388 506.091187,752.683594 508.487732,756.012207 
	C511.933838,760.798645 511.844757,761.792297 507.471497,767.143311 
	C513.265564,765.945374 514.674988,769.048340 514.787598,773.468750 
	C514.835205,775.337280 515.747803,776.981873 516.452698,778.668396 
	C517.168640,780.381287 518.043213,782.361023 517.329468,783.986023 
	C513.527710,792.641663 517.478699,800.691589 520.108582,808.267273 
	C522.309998,814.608948 521.576721,819.923096 519.572144,825.549927 
	C518.595154,828.292480 517.951904,830.873779 518.398621,833.785034 
	C518.912720,837.135254 518.538696,840.436829 516.947388,843.506165 
	C515.503540,846.291016 515.652832,849.310669 515.612427,852.299133 
	C515.563110,855.944885 514.830017,859.395813 511.591675,861.502747 
	C509.228607,863.040283 509.068420,864.196289 511.313324,865.864258 
	C512.491699,866.739807 513.064758,868.258606 514.727478,869.455261 
	C515.529358,864.750610 519.265869,862.189209 521.424988,858.533691 
	C523.260620,855.425720 527.173035,855.574219 530.515808,855.748230 
	C532.898865,855.872253 534.055786,857.358582 533.796509,859.813965 
	C533.733032,860.415527 533.466125,860.900696 534.651611,860.823181 
	C542.950684,860.281067 551.640259,862.213623 559.125854,856.762817 
	C569.182922,857.642761 577.356689,850.687927 587.525940,850.436951 
	C595.852600,850.231506 604.609558,848.358765 612.703735,845.156372 
	C613.166870,844.973145 613.616882,844.538574 614.062500,844.558777 
	C621.483582,844.894958 628.014221,841.264221 635.046875,839.877869 
	C640.271545,838.847900 645.118713,836.546936 650.229553,835.086975 
	C654.505859,833.865356 658.804199,832.722168 662.836243,830.599060 
	C665.717468,829.081970 669.105225,828.347168 672.750000,829.741150 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M559.387329,958.110352 
	C559.212830,957.425171 559.186340,957.016541 559.155151,956.300903 
	C559.108459,951.741699 557.052734,948.136169 555.530945,944.330750 
	C552.665344,937.164429 548.477295,930.625305 545.845825,923.342468 
	C544.637939,919.999573 545.427795,916.826050 545.871887,913.609558 
	C546.096130,911.985291 547.300232,911.038330 549.464844,911.649048 
	C555.898804,930.049927 562.109314,948.193298 566.985840,966.755981 
	C567.826660,969.956421 568.804810,973.120728 569.708374,976.677429 
	C567.320312,978.072021 566.883911,976.027893 566.254700,974.682373 
	C565.426270,972.910522 564.871704,971.012573 563.317627,969.227478 
	C562.787842,967.843628 562.490784,966.732971 562.173950,965.277710 
	C561.686890,963.945862 561.558899,962.837158 560.907104,961.577820 
	C560.385742,960.309631 560.135437,959.274719 559.387329,958.110352 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M547.710388,904.096924 
	C548.476196,904.072205 548.911621,904.020264 549.675415,903.986145 
	C549.900879,905.033752 549.797974,906.063538 549.563599,907.460022 
	C549.095154,908.652344 548.524597,909.324707 547.751343,910.258057 
	C547.328186,910.628357 547.146240,910.763062 546.662598,911.053711 
	C540.682861,914.792236 541.494202,925.203430 546.770508,930.853455 
	C547.196045,931.221680 547.352417,931.382812 547.711182,931.809021 
	C548.364136,932.809021 548.743530,933.602844 549.809448,934.248413 
	C551.162903,935.328003 551.932922,936.418579 552.357056,938.114929 
	C552.566284,940.075806 553.384521,941.383484 554.558716,942.908386 
	C554.913757,943.926147 554.883728,944.618347 554.386108,945.585205 
	C553.922485,946.165833 553.668518,946.457031 553.168945,946.904419 
	C551.069336,944.267029 548.862976,941.782593 546.168823,939.291748 
	C544.216797,935.074768 539.722656,934.026611 537.065857,930.494934 
	C532.368225,918.654907 534.928772,910.340271 544.755005,905.305054 
	C545.060852,905.071228 545.004395,905.000000 545.035400,905.034180 
	C545.627930,904.249695 546.447998,904.030029 547.710388,904.096924 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M547.237061,933.173218 
	C547.050293,932.961365 546.953186,932.538696 546.914490,932.325317 
	C543.689209,927.812317 542.102417,923.015076 542.525940,917.646729 
	C542.753418,914.763794 543.426147,912.040405 546.551270,910.169373 
	C546.877441,909.893066 546.957092,909.909668 546.928955,909.880249 
	C547.171875,909.649170 547.463013,909.482605 548.026001,909.326782 
	C548.446289,909.320251 548.614380,909.337463 549.020996,909.440186 
	C549.426331,910.037842 549.593018,910.549988 549.776611,911.467590 
	C544.627136,913.717590 546.457764,918.411987 547.036255,921.326172 
	C547.976013,926.059937 550.674805,930.430725 552.837830,934.880005 
	C555.600891,940.563416 557.663147,946.593018 559.893066,952.523926 
	C560.200500,953.341431 559.735718,954.449341 559.389465,955.708313 
	C556.798950,953.102600 557.560059,948.845764 554.498474,945.999756 
	C554.071411,945.203430 553.966370,944.532227 553.807861,943.524658 
	C553.049194,941.903076 552.342834,940.618469 551.276123,939.188477 
	C551.017090,937.547913 550.498291,936.398804 549.067383,935.377319 
	C548.472168,934.468384 547.923950,933.946472 547.237061,933.173218 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M563.070618,969.354492 
	C567.756042,969.546631 566.569031,974.596130 569.388184,976.942871 
	C569.806824,977.783813 569.916443,978.514526 570.041992,979.614746 
	C569.803467,981.698303 570.451477,983.142090 571.617493,984.768677 
	C572.186584,989.176453 574.843933,989.621155 578.054504,988.705017 
	C580.209839,988.089966 582.248169,987.064697 584.709290,986.258789 
	C583.584778,989.120850 580.771179,990.465820 578.143982,991.606506 
	C573.480591,993.631165 569.404358,991.502747 567.346558,986.533325 
	C565.116394,981.147522 564.778442,975.273193 563.070618,969.354492 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M571.837646,984.696167 
	C569.656677,984.197510 568.694275,982.757385 569.805786,980.202881 
	C570.654907,981.438843 571.251953,982.893494 571.837646,984.696167 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M549.403564,909.085205 
	C548.778015,909.606567 548.248962,909.729065 547.310425,909.851196 
	C547.045654,908.703430 547.751953,908.115723 549.135376,907.867065 
	C549.454773,908.113342 549.477478,908.399841 549.403564,909.085205 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M549.308167,909.510559 
	C549.307068,909.484009 549.226196,909.447266 549.209351,909.469604 
	C549.192505,909.491943 549.259460,909.460083 549.259521,909.492920 
	C549.259583,909.525696 549.309265,909.537109 549.308167,909.510559 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M560.883789,960.699585 
	C563.260559,961.488770 563.200134,962.868469 562.371704,964.703186 
	C561.709717,965.358948 561.265076,965.784790 560.696228,966.385132 
	C560.414062,964.993164 560.256104,963.426575 560.100952,961.453491 
	C560.269836,960.834534 560.435852,960.622070 560.883789,960.699585 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M537.776428,929.743652 
	C540.798462,932.653320 544.872375,934.062561 546.023438,938.639954 
	C533.613770,929.151611 518.969177,929.321838 506.419708,939.717102 
	C503.995636,941.725037 503.559601,940.867004 502.964813,938.506897 
	C501.380035,932.218689 499.596863,925.980225 498.041779,919.685120 
	C497.265656,916.543396 495.602386,915.719360 492.368439,916.984009 
	C489.688904,913.700256 492.055450,912.018188 494.618469,910.140015 
	C501.791077,908.478210 508.883118,908.065491 515.042664,904.254456 
	C516.100037,903.600220 517.681274,903.210510 518.858704,903.460632 
	C520.946960,903.904297 522.701233,905.038269 520.836975,907.601868 
	C520.547668,907.999878 520.156067,908.323547 519.811890,908.681702 
	C517.866455,910.706482 515.023315,912.920715 516.448608,915.786560 
	C517.695435,918.293518 520.723145,919.697021 523.850647,920.055542 
	C525.315918,920.223511 526.749878,920.663574 528.596558,920.992615 
	C529.356323,921.223633 529.655212,921.506409 530.016235,922.140808 
	C530.450745,923.345764 530.585327,924.375305 531.908325,925.017090 
	C534.077087,926.484253 535.550110,928.302124 537.776428,929.743652 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M538.145691,1004.889099 
	C536.890381,1004.943176 535.844849,1004.962891 534.406494,1005.004578 
	C529.654785,1006.508423 525.303894,1007.862488 520.566406,1007.278015 
	C515.535828,1006.657227 511.368530,1009.240845 507.036438,1011.213989 
	C499.871460,1014.477356 495.033936,1012.373840 492.156586,1004.560181 
	C491.840698,1003.724060 491.721893,1003.237671 491.626160,1002.386475 
	C491.545441,1000.925781 491.586731,999.793213 490.585815,998.601929 
	C490.295746,998.117310 490.191010,997.914917 489.962921,997.390625 
	C489.485626,993.813782 488.233948,990.816467 487.050293,987.432739 
	C486.804169,986.647766 486.707764,986.189087 486.615906,985.376770 
	C486.445862,983.232056 486.710724,981.377380 485.747620,979.361084 
	C485.573944,978.145386 485.201508,977.374695 484.465454,976.403503 
	C484.191010,975.922424 484.093842,975.721985 483.885315,975.205444 
	C483.327972,972.914795 483.227325,970.925476 483.516541,968.592529 
	C483.660278,966.872253 483.234039,965.733643 481.815063,964.691895 
	C481.490417,964.248352 481.379944,964.056824 481.155457,963.553406 
	C480.941406,962.812744 480.870178,962.379211 480.793671,961.618591 
	C480.756012,959.681946 479.743225,958.511292 478.811432,956.923645 
	C476.084686,952.375732 473.237366,948.252869 471.957672,943.397888 
	C470.426270,937.587830 470.481567,931.908325 474.381714,926.437256 
	C474.748413,924.797424 475.410370,923.739319 476.858063,922.273315 
	C477.662933,921.635925 477.901215,921.526062 478.532623,921.367554 
	C479.467163,921.367676 479.952454,921.559021 480.622559,922.209839 
	C485.140472,947.888184 493.512115,972.282288 498.552460,997.464111 
	C499.988983,1004.640930 501.584259,1005.367065 508.393219,1002.314758 
	C510.050568,1001.571838 511.563354,1000.491882 513.786194,1000.138428 
	C514.693787,999.885315 515.208496,999.640686 515.863281,999.137085 
	C523.593811,1003.108337 531.327332,1001.226318 539.042969,999.373474 
	C540.157471,999.105835 541.196167,998.522583 542.649292,998.062988 
	C543.075623,1001.043335 539.211243,1001.350281 538.816650,1004.416992 
	C538.768311,1004.752075 538.355530,1004.854736 538.145691,1004.889099 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M514.071411,999.825378 
	C513.371887,1000.958984 512.677368,1001.953918 511.739838,1002.591309 
	C502.843964,1008.638977 499.139709,1007.036011 496.705261,996.854004 
	C491.957275,976.995605 487.587189,957.037048 481.660919,937.473999 
	C480.206604,932.673340 479.507385,927.748230 479.842773,922.242554 
	C479.790314,920.700317 480.486938,920.284119 481.712769,920.097595 
	C482.782318,920.018860 483.530548,919.903015 484.657349,919.823181 
	C484.613373,930.126831 486.862915,939.910645 490.182312,949.547241 
	C491.360840,952.968628 492.167908,956.552368 492.793335,960.123596 
	C494.866150,971.958923 499.047424,983.330505 500.359680,995.340088 
	C500.806976,999.433411 502.889069,1001.467773 507.595123,1000.171509 
	C509.508972,999.644348 511.660095,997.503845 514.071411,999.825378 
z"/>
<path fill="#060D0E" opacity="1.000000" stroke="none" 
	d="
M523.672485,943.973389 
	C535.671570,943.565186 537.999878,950.259338 540.694275,960.641418 
	C542.148499,966.245117 542.375427,972.447449 540.060364,978.637329 
	C539.244202,979.506287 538.457520,979.843384 537.141479,979.439331 
	C536.333313,978.829590 536.024048,978.343384 535.718445,977.371765 
	C535.193726,975.003662 535.182251,973.033203 535.382568,970.653198 
	C535.554077,968.678345 535.553467,967.106384 535.421753,965.164795 
	C534.530396,954.978638 529.403381,949.829224 520.305908,950.043884 
	C518.952454,950.308960 517.958069,950.285706 516.604858,949.934753 
	C515.778992,949.554932 515.406738,949.207520 515.013672,948.393250 
	C515.994080,945.816772 518.397827,945.919189 520.568359,944.932007 
	C521.627686,944.301941 522.424805,943.977539 523.672485,943.973389 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M536.614136,969.759521 
	C536.854126,972.078613 536.890137,974.076538 536.887085,976.501343 
	C536.151489,977.700623 536.217529,978.554199 536.637573,979.791870 
	C536.240540,982.405029 534.951660,983.663818 532.204712,983.448364 
	C531.493225,982.952332 531.201660,982.552307 530.886963,981.743652 
	C531.029236,970.119507 523.299988,959.856628 512.621094,957.804810 
	C511.794769,957.874817 511.329041,957.802490 510.582214,957.453125 
	C510.253845,956.791809 510.216858,956.336975 510.193481,955.539612 
	C510.510406,954.092896 511.353485,953.505188 512.657593,952.870850 
	C520.157410,950.324402 525.464417,953.127319 529.814148,958.641541 
	C532.444519,961.976196 535.700012,964.930115 536.614136,969.759521 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M519.571045,983.483765 
	C515.223694,983.006836 513.261475,980.615601 512.677673,976.618591 
	C512.246826,973.669434 511.297516,970.795959 510.579224,967.470703 
	C515.551331,964.888611 521.345764,967.882141 523.955994,974.287292 
	C525.194641,977.326782 526.101685,980.577148 524.791504,984.333862 
	C524.336121,984.894226 524.104797,985.075012 523.441284,985.171509 
	C522.856445,985.005310 522.703735,984.923340 522.297058,984.786255 
	C521.339966,984.250732 520.643188,983.760071 519.571045,983.483765 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M494.957703,909.651978 
	C495.765350,912.919983 491.089661,913.193054 492.005096,916.563782 
	C491.216949,917.275757 490.430511,917.605774 489.321411,917.930542 
	C488.754944,915.685242 489.843811,913.086243 485.188599,914.134277 
	C481.999237,914.852295 482.692871,910.707703 483.411713,908.904236 
	C484.293976,906.690796 486.210144,904.861084 487.802765,902.969360 
	C488.826660,901.753052 489.819489,902.631958 490.849762,903.247559 
	C493.180969,904.640503 494.088440,906.925415 494.957703,909.651978 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M524.213013,985.006348 
	C524.273987,973.531799 519.603638,967.852539 510.999878,967.041321 
	C509.276337,965.512146 509.373322,963.671082 509.543671,961.491699 
	C511.788269,960.278076 513.877686,960.871887 515.832520,961.698914 
	C523.692627,965.024414 529.360718,975.576965 527.730408,984.404053 
	C526.640747,985.683167 525.621521,985.874268 524.213013,985.006348 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M527.730591,984.812988 
	C527.722107,977.294556 525.101624,971.274841 519.794800,966.224731 
	C517.006958,963.571655 513.763489,962.139099 509.884613,961.218750 
	C508.930237,959.967163 509.555115,958.927612 510.138733,957.556763 
	C510.670624,957.196350 511.050415,957.146118 511.716370,957.064880 
	C521.422485,959.198120 529.993774,968.967590 530.538147,978.211487 
	C530.588623,979.068237 530.751038,979.837463 531.377197,980.880981 
	C531.629089,981.603516 531.710022,981.996765 531.879395,982.677979 
	C531.749084,985.647583 529.829529,985.132385 527.730591,984.812988 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M527.013000,984.732727 
	C528.702881,984.218567 530.065002,983.823853 531.697510,983.197510 
	C534.126404,982.881836 535.138000,981.454834 535.899414,979.327820 
	C536.140808,978.865417 536.319641,978.736877 536.764343,978.804199 
	C537.771973,979.004944 538.513733,979.009705 539.626099,979.021729 
	C537.190674,985.632507 531.156189,986.684753 525.097168,987.600525 
	C524.003113,987.765869 523.399414,986.802612 523.064575,985.431091 
	C523.166565,985.013184 523.324097,984.939087 523.722046,984.763123 
	C524.870056,984.724854 525.777710,984.788513 527.013000,984.732727 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M513.249573,953.763916 
	C512.247925,954.321533 511.522949,954.686646 510.502502,955.124390 
	C508.294556,950.887268 513.422241,950.939941 514.653564,948.223206 
	C515.193909,948.295227 515.484802,948.567749 515.891174,949.119629 
	C516.615784,951.643555 515.327881,952.760254 513.249573,953.763916 
z"/>
<path fill="#101925" opacity="1.000000" stroke="none" 
	d="
M523.788452,943.731079 
	C523.419128,944.811157 522.552734,944.972656 521.260864,944.900513 
	C521.287292,943.402954 522.037170,942.683167 523.788452,943.731079 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M519.467041,983.856079 
	C520.218506,983.171997 521.112061,983.065247 521.919067,984.397827 
	C521.093384,985.152283 520.291321,984.991333 519.467041,983.856079 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M561.117249,960.651001 
	C560.988403,960.999695 560.811157,961.009705 560.368835,961.033325 
	C559.783936,960.203186 559.463989,959.359375 559.191650,958.174683 
	C560.516052,958.167664 561.007263,959.081360 561.117249,960.651001 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M474.305969,1019.077759 
	C470.684723,1019.500854 467.350220,1020.285645 463.872803,1019.110229 
	C461.671478,1018.366150 459.919861,1019.782166 458.153503,1021.238403 
	C451.943939,1026.357422 445.218201,1029.970459 436.708893,1030.153687 
	C430.912659,1030.278442 426.799774,1028.960938 424.334686,1023.191223 
	C422.858307,1021.627625 423.802582,1019.919861 423.367401,1018.075378 
	C423.471893,1017.503479 423.551971,1017.281494 423.876068,1016.802063 
	C424.334534,1016.446045 424.549042,1016.347534 425.113647,1016.199463 
	C427.415497,1016.288391 428.165466,1017.740601 428.979828,1019.227844 
	C431.835724,1024.443726 435.732422,1026.163696 441.701874,1025.118042 
	C448.173340,1023.984314 452.893219,1019.896667 457.863556,1015.572144 
	C458.656647,1014.446350 459.448090,1013.852600 460.663513,1013.192810 
	C462.445679,1012.557556 464.126160,1012.123718 465.355011,1012.821533 
	C468.838165,1014.799683 472.505493,1014.273010 476.507202,1014.370789 
	C478.087952,1016.416992 476.692810,1017.388611 475.262268,1018.709290 
	C474.977661,1018.975159 474.528748,1019.036743 474.305969,1019.077759 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M475.314392,1018.988159 
	C474.956299,1017.535767 476.022064,1016.638550 476.804443,1015.237427 
	C476.187714,1013.319519 477.022949,1012.022461 478.001740,1010.410400 
	C478.945831,1009.366943 479.834381,1008.940735 481.178589,1008.625488 
	C483.887726,1008.023438 485.950867,1006.721985 488.606140,1005.959473 
	C489.461823,1005.838257 489.954559,1005.857666 490.802856,1006.038635 
	C491.852783,1006.479492 492.354309,1007.000183 492.783112,1008.039368 
	C492.912201,1010.968445 493.596008,1012.933472 497.137939,1012.536743 
	C498.642242,1012.499817 499.796997,1012.520386 501.257996,1012.896606 
	C503.447235,1013.900757 505.290497,1014.009766 507.417419,1012.736084 
	C507.953308,1012.600952 508.177307,1012.588867 508.680420,1012.764587 
	C510.137421,1013.125366 511.315247,1013.298340 512.749756,1013.720276 
	C513.739502,1015.560425 512.236633,1015.817017 511.235718,1016.754395 
	C510.988251,1016.987793 510.496033,1016.997253 510.249939,1017.002319 
	C508.828247,1018.471130 506.862183,1017.592346 505.249756,1018.758667 
	C503.931183,1019.000977 502.870117,1019.009277 501.411133,1019.020996 
	C498.041809,1021.118225 495.493347,1018.428406 493.310089,1017.604431 
	C487.667297,1015.474854 482.825287,1017.258972 477.374268,1018.914429 
	C476.549316,1018.992554 476.100220,1018.996826 475.314392,1018.988159 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M477.248016,1019.222656 
	C483.362793,1013.695984 490.181915,1014.928345 497.096161,1017.595398 
	C498.163910,1018.007263 499.213745,1018.465454 500.642548,1018.963318 
	C500.731384,1020.696960 499.306366,1021.578186 498.218323,1020.727539 
	C495.161621,1018.337646 491.073242,1019.948303 488.082611,1017.718384 
	C485.838043,1016.044678 484.686340,1019.009888 482.774750,1018.992493 
	C481.017273,1018.976562 479.256775,1019.289185 477.248016,1019.222656 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M520.796875,1012.334229 
	C521.464661,1012.024658 521.903198,1012.021301 522.670532,1012.015991 
	C524.793396,1011.950806 526.601746,1012.143555 528.691284,1011.830933 
	C529.007568,1011.949707 529.499939,1011.960205 529.745667,1011.973999 
	C528.211487,1016.219727 524.776184,1015.001953 521.286011,1014.287842 
	C520.618958,1013.640808 520.474792,1013.177429 520.796875,1012.334229 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M511.385742,1016.992554 
	C511.001740,1015.798218 511.833344,1015.147888 512.823364,1014.225281 
	C513.745300,1013.963074 514.484192,1013.956970 515.592651,1013.941589 
	C516.151367,1013.827148 516.340576,1013.722046 516.776489,1013.828003 
	C516.590027,1017.351685 514.068298,1016.964905 511.385742,1016.992554 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M505.384857,1018.996826 
	C505.483887,1016.383850 507.578156,1017.170593 509.611633,1017.002441 
	C509.512573,1019.616943 507.422607,1018.837524 505.384857,1018.996826 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M517.420410,1014.030396 
	C516.846375,1014.022827 516.669495,1014.006592 516.227417,1013.961365 
	C516.533081,1011.392639 518.589966,1012.151611 520.632690,1012.016785 
	C521.019653,1012.472595 521.013123,1012.917236 521.003296,1013.696167 
	C519.939209,1014.027588 518.878357,1014.024658 517.420410,1014.030396 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M492.068115,1009.326904 
	C491.822968,1008.446716 491.636444,1007.895874 491.212158,1007.168823 
	C490.450378,1006.387695 490.148987,1005.699341 490.280701,1004.507690 
	C490.768005,1003.400635 491.379242,1002.967285 492.560486,1002.878845 
	C496.472321,1013.983521 502.174896,1012.060364 509.755066,1008.321777 
	C514.052917,1006.202026 518.103943,1004.810486 522.873352,1005.902771 
	C526.355957,1006.700317 529.681641,1004.855774 533.582031,1005.010620 
	C530.366821,1008.509827 525.665649,1008.857483 521.082092,1008.912109 
	C516.716919,1008.964294 512.799316,1009.955139 509.057220,1012.603943 
	C508.959564,1012.952393 508.483673,1012.984924 508.245514,1012.997437 
	C506.390167,1014.102295 504.638367,1014.708374 502.314514,1014.270264 
	C500.593628,1014.031250 499.216675,1014.012329 497.426575,1013.989746 
	C495.257416,1012.686340 492.923035,1012.039307 492.068115,1009.326904 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M528.738831,1011.705078 
	C527.251526,1012.908447 525.454834,1013.469666 523.251099,1012.263550 
	C524.684814,1010.464233 526.566528,1010.854126 528.738831,1011.705078 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M350.695923,377.161774 
	C352.818085,378.057587 354.289856,379.352325 353.838623,381.737183 
	C353.296936,384.600220 350.780884,382.781860 349.483551,383.553314 
	C347.340942,384.827423 345.175903,385.124237 342.413330,385.000000 
	C342.011749,383.356689 341.892883,381.699371 342.067413,380.073517 
	C342.299316,377.912628 343.234589,376.416931 345.582581,378.110901 
	C347.566711,379.542297 348.938507,378.478424 350.695923,377.161774 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M336.268921,374.723724 
	C336.968567,373.518646 337.289429,371.694519 338.750916,373.006866 
	C340.046478,374.170166 341.338623,375.667328 340.829071,377.769226 
	C340.575195,378.816345 339.972260,379.594940 338.874878,379.739349 
	C337.254517,379.952545 336.388794,379.019592 336.143219,377.538086 
	C336.010834,376.739227 336.141449,375.896790 336.268921,374.723724 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M319.946411,384.142761 
	C318.528229,381.381409 320.032318,381.022430 321.964630,381.099060 
	C323.082672,381.143402 323.924591,381.763733 323.802765,382.927704 
	C323.535797,385.478546 321.901978,385.082001 319.946411,384.142761 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M311.299805,377.102478 
	C312.024689,376.144836 312.795685,375.917969 313.382507,376.718140 
	C313.800629,377.288330 313.414581,377.993103 312.830994,378.310547 
	C311.905182,378.814178 311.363617,378.387085 311.299805,377.102478 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M298.772278,376.739807 
	C297.828308,376.678162 297.321655,376.340363 297.714966,375.633820 
	C297.857117,375.378479 298.563019,375.203217 298.880768,375.327118 
	C299.578033,375.599030 299.641510,376.144653 298.772278,376.739807 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M360.048035,309.636902 
	C359.674408,309.999603 359.347931,309.983246 359.023376,310.001343 
	C356.956085,310.116516 355.046295,309.915283 354.368408,307.492767 
	C353.971588,306.074799 353.479523,304.421661 354.941071,303.472656 
	C357.058289,302.097900 358.460266,304.472473 360.632538,304.945557 
	C360.698669,306.424591 360.397339,307.849213 360.048035,309.636902 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M360.641602,315.051666 
	C361.000000,315.000000 361.500000,315.000000 361.750000,315.000000 
	C362.019806,315.161316 362.006592,315.335663 362.064392,315.481995 
	C362.947662,317.718292 362.857086,319.654510 360.289093,320.660767 
	C359.190979,321.091064 357.812225,321.227295 357.370300,319.872742 
	C356.535583,317.314178 358.104218,315.961060 360.641602,315.051666 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M360.949585,327.642212 
	C360.507538,328.580597 360.014252,329.161163 359.260498,329.870880 
	C356.507599,330.151917 355.834045,328.738770 356.021240,326.560699 
	C356.108215,325.548920 356.170197,324.342255 357.424469,324.295166 
	C359.366608,324.222260 360.316193,325.612457 360.949585,327.642212 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M365.337402,309.954407 
	C364.555542,310.000824 364.111115,310.000427 363.333313,310.000000 
	C363.030914,308.324188 363.096924,306.628693 365.209351,306.217377 
	C366.287018,306.007538 367.243896,306.534943 367.516754,307.553131 
	C367.894012,308.960907 366.869965,309.570557 365.337402,309.954407 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M355.318787,313.580994 
	C356.059235,315.972107 355.032043,316.870056 353.011139,316.732239 
	C352.175476,316.675293 351.372314,316.010101 351.471893,315.163269 
	C351.736725,312.910828 353.334686,312.910858 355.318787,313.580994 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M378.008545,328.518188 
	C377.037933,327.751007 377.029114,326.994446 377.809052,326.435425 
	C378.364288,326.037506 379.169006,326.124298 379.430725,326.794952 
	C379.790771,327.717560 379.123657,328.250153 378.008545,328.518188 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M373.467255,327.260925 
	C372.460724,327.517487 372.147186,327.016388 372.274231,326.360962 
	C372.333344,326.055969 372.904785,325.570801 373.124451,325.627838 
	C373.838226,325.813171 373.857239,326.440460 373.467255,327.260925 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M361.267273,304.957977 
	C361.289825,304.518433 361.579681,304.036896 361.869507,303.555328 
	C362.063995,303.721191 362.258514,303.887054 362.453003,304.052917 
	C362.146851,304.340607 361.840698,304.628296 361.267273,304.957977 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M388.851624,276.678802 
	C387.066803,277.073456 385.171082,276.785492 384.099945,279.239258 
	C381.925903,284.219482 380.338989,284.257507 377.051331,279.357971 
	C377.297089,275.544647 380.508820,275.141541 382.821838,273.305359 
	C385.773346,272.637573 387.744781,273.637268 388.851624,276.678802 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M349.039062,288.330933 
	C349.166840,287.979919 349.333282,287.965851 349.496460,287.934692 
	C351.401703,287.570923 353.633606,284.676056 355.101562,287.488739 
	C356.373077,289.924927 353.521667,290.922394 351.340088,291.956482 
	C349.890076,291.157043 349.416504,289.946808 349.039062,288.330933 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M218.755005,420.113403 
	C219.030045,419.459198 219.103989,419.125122 219.208038,418.535614 
	C220.754837,417.691040 222.271500,417.101929 224.135849,416.471313 
	C224.873260,416.700500 225.253845,416.701294 225.763000,416.096039 
	C225.902145,415.800964 226.185898,415.614136 226.355743,415.610779 
	C230.011719,413.926788 233.583679,412.489319 237.748077,411.638550 
	C238.239136,411.390869 238.390228,411.271973 238.766129,410.971832 
	C239.932617,410.693207 240.874329,410.595856 242.177277,410.505188 
	C243.845505,410.852814 244.549652,409.976532 245.540436,408.947632 
	C246.636261,408.438324 247.471329,408.132507 248.707489,408.124329 
	C251.543198,407.672272 254.045471,407.193085 256.864227,406.524475 
	C257.283020,405.721436 257.641663,405.460205 258.608948,405.722717 
	C263.725922,405.561554 268.321686,404.381439 273.194153,402.906281 
	C277.269958,402.062561 281.050842,401.422394 285.242126,400.819794 
	C286.435577,400.437592 287.491882,400.268250 287.943451,400.912567 
	C290.693298,404.836029 294.596191,404.313904 298.556641,403.986755 
	C302.424744,403.667175 305.870880,402.556519 308.903259,399.704163 
	C310.587036,399.894806 311.906281,399.972626 313.612732,400.142670 
	C314.306793,400.394531 314.610168,400.560455 315.161804,400.670654 
	C316.698975,400.699005 317.984436,400.789429 319.648743,400.807861 
	C320.027557,400.735931 320.452454,400.920715 320.669861,401.000732 
	C321.126495,401.341492 321.416901,401.425659 321.975616,401.238037 
	C322.604706,401.426910 323.016693,401.711029 323.672241,402.068359 
	C324.158264,402.842987 324.716339,402.991791 325.669891,403.048431 
	C326.244385,404.976410 324.587372,405.502167 323.354370,406.744141 
	C319.078369,409.628082 314.743439,411.743835 311.583099,415.606323 
	C309.719940,417.883362 306.663483,418.185669 303.852692,418.648468 
	C298.454651,419.537323 292.984222,420.069946 288.469086,423.804382 
	C286.181335,425.696533 283.794403,424.474518 281.552948,423.124817 
	C270.070587,416.210785 268.654266,416.970245 264.464325,429.660980 
	C262.664490,435.112396 263.089905,439.577484 266.299927,444.174896 
	C267.588470,446.020386 269.455811,447.682678 269.444183,450.570435 
	C267.578003,452.506927 265.820923,451.577545 264.268524,450.713287 
	C248.601639,441.991333 232.975540,433.197205 218.799255,421.520081 
	C218.598526,420.935638 218.576233,420.684601 218.755005,420.113403 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M258.785400,405.679932 
	C258.578583,406.063751 258.196381,406.234161 257.497437,406.369781 
	C257.146637,406.119720 257.112579,405.904449 257.058411,405.363342 
	C257.562195,405.180542 258.086090,405.323517 258.785400,405.679932 
z"/>
<path fill="#266E80" opacity="1.000000" stroke="none" 
	d="
M246.253906,409.004639 
	C245.542297,410.918793 244.441635,410.977051 242.877563,410.641907 
	C243.043365,410.121063 243.548218,409.730286 244.189728,409.150726 
	C244.583450,408.891479 244.841904,408.826660 245.279144,408.674377 
	C245.812180,408.568817 246.167816,408.556427 246.253906,409.004639 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M246.302124,409.339691 
	C245.902954,409.312683 245.821594,409.159973 245.598373,408.794312 
	C246.418915,407.951569 247.255447,406.909119 248.833023,407.883362 
	C248.233917,408.505646 247.426926,408.859833 246.302124,409.339691 
z"/>
<path fill="#045B70" opacity="1.000000" stroke="none" 
	d="
M309.194824,399.595032 
	C307.787750,406.149475 302.194733,405.173187 297.874878,405.821838 
	C293.405701,406.492828 288.336639,407.231110 286.050079,400.968079 
	C285.652496,400.857361 285.845581,400.398529 285.909821,400.158081 
	C286.773865,399.887115 287.573669,399.856628 288.749084,399.683716 
	C290.211639,399.616638 291.216858,399.186798 292.586243,399.018677 
	C296.981384,398.960175 301.057037,398.915741 305.584106,398.739990 
	C306.778961,398.745697 307.522430,398.882782 308.644226,398.968079 
	C309.022552,398.916290 309.121796,399.373016 309.194824,399.595032 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M292.747528,398.778503 
	C292.002991,399.861298 290.879364,399.786224 289.444702,399.645264 
	C289.665558,399.179749 290.206421,398.818176 290.891968,398.256592 
	C291.554199,398.221802 292.071747,398.387085 292.747528,398.778503 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M423.387390,362.471130 
	C423.910461,361.183167 424.535095,360.304840 424.678436,359.353973 
	C425.173248,356.070923 426.416016,355.399811 429.249268,357.451599 
	C430.671143,358.481354 432.109192,359.818298 434.281982,357.381104 
	C436.309509,355.106812 436.280182,359.154907 437.805298,359.749756 
	C439.188599,360.289307 439.500214,362.198456 438.104370,363.522430 
	C436.110535,365.413666 425.695648,365.581635 423.382233,363.798370 
	C423.205170,363.661865 423.299927,363.172729 423.387390,362.471130 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M447.192078,331.750000 
	C442.553040,332.181793 438.022705,333.597900 433.378204,331.052643 
	C433.000000,331.000000 433.000000,330.500000 433.000000,330.250000 
	C432.619690,329.614410 432.472412,329.154266 432.779114,328.309753 
	C433.106720,327.113739 433.390686,326.356293 434.687469,326.053223 
	C435.385773,325.619171 435.845886,325.472198 436.690125,325.778290 
	C440.416748,324.158813 443.833710,325.313751 447.625305,325.999023 
	C447.997223,327.479584 448.098663,328.976013 447.149750,330.663330 
	C447.128082,331.166656 447.256134,331.333344 447.192078,331.750000 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M430.000000,376.575104 
	C424.766724,376.586487 422.921143,371.342468 418.900696,369.157990 
	C417.933899,368.632690 418.217926,367.341888 419.182404,366.639496 
	C420.114105,365.960938 421.344299,365.944336 421.703491,367.055054 
	C422.554688,369.687286 424.471741,369.931152 426.780792,370.082733 
	C430.926392,370.354950 429.774506,373.667786 430.000000,376.575104 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M482.318542,288.136292 
	C481.999054,286.611115 481.999298,285.222198 481.999176,283.416656 
	C481.986725,280.809082 482.557495,278.748169 485.198761,279.205872 
	C488.007172,279.692535 486.097809,281.926331 485.926025,283.180145 
	C485.644745,285.233398 487.683533,289.195465 482.318542,288.136292 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M417.360413,357.944824 
	C416.009491,357.176514 414.966003,356.407654 414.043976,355.513580 
	C412.761414,354.269897 411.028442,352.638428 412.556396,351.012085 
	C414.045288,349.427399 414.647614,352.254211 415.874176,352.578613 
	C416.946442,352.862183 418.091797,352.869446 419.602417,352.998779 
	C419.932373,354.950897 420.356262,357.132019 417.360413,357.944824 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M420.156738,374.805023 
	C421.430206,376.150970 422.892670,377.536682 420.927948,378.666992 
	C418.979675,379.787781 419.439819,380.850037 420.112366,382.306580 
	C420.499146,383.144165 421.238129,384.302643 419.680756,384.674347 
	C418.352814,384.991302 417.964844,383.751160 417.863312,382.808502 
	C417.586731,380.239960 417.263977,377.638092 417.422302,375.078033 
	C417.495758,373.890320 418.901794,374.122101 420.156738,374.805023 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M465.000000,327.625153 
	C463.933319,328.000000 462.866669,328.000000 461.399994,328.000000 
	C460.041779,327.504486 459.687286,326.672272 459.842834,325.321655 
	C460.496155,324.742310 460.992310,324.484619 461.744232,324.113464 
	C462.987122,323.380524 463.785706,323.808258 464.772156,324.729675 
	C465.000000,325.750916 465.000000,326.500610 465.000000,327.625153 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M415.270264,398.466309 
	C413.584198,399.003021 412.213165,399.123596 412.281128,397.070526 
	C412.310272,396.190704 412.953125,395.412659 413.856598,395.463409 
	C415.602905,395.561493 416.042572,396.665192 415.270264,398.466309 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M465.124878,324.711304 
	C464.194702,324.753937 463.389435,324.506683 462.292053,324.129700 
	C462.000000,324.000000 462.000000,323.500000 462.000000,323.250000 
	C462.597168,322.002380 463.206360,320.870239 464.628357,321.525085 
	C465.991821,322.153015 465.840759,323.281830 465.124878,324.711304 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M437.041992,325.655334 
	C436.555542,325.998901 436.111115,325.999054 435.333313,325.998962 
	C435.198242,324.778534 433.602631,323.504883 434.999390,322.388733 
	C435.399994,322.068573 436.354431,322.045807 436.838776,322.299408 
	C438.362396,323.097351 437.122498,324.284821 437.041992,325.655334 
z"/>
<path fill="#D0CDCE" opacity="1.000000" stroke="none" 
	d="
M440.709991,373.848572 
	C440.999817,374.444183 440.999634,374.888367 440.999115,375.665680 
	C440.342072,375.935760 439.636627,376.004425 439.038544,375.783051 
	C437.680389,375.280334 436.453400,374.244171 437.540039,372.854492 
	C438.579132,371.525574 439.458466,373.316711 440.709991,373.848572 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M498.867188,289.298950 
	C500.135010,289.053497 501.361908,288.909058 501.930603,290.664551 
	C500.602936,290.750000 499.953674,293.014740 498.617218,292.221252 
	C497.575989,291.603027 498.369690,290.476227 498.867188,289.298950 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M454.378479,316.471252 
	C453.345245,315.488464 452.998169,314.645935 454.425110,314.201324 
	C455.193390,313.961914 456.187592,313.970337 456.396637,314.949738 
	C456.664703,316.205811 455.742279,316.575409 454.378479,316.471252 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M443.759949,321.718262 
	C442.237549,323.243469 441.683746,322.145599 441.265106,321.013458 
	C441.022064,320.356110 441.241180,319.602112 442.033569,319.532379 
	C443.286224,319.422150 443.637512,320.386597 443.759949,321.718262 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M459.631165,306.185547 
	C461.027710,307.718201 459.867249,307.815338 458.903687,307.855621 
	C458.287933,307.881317 457.448486,307.638336 457.758636,306.810059 
	C457.900116,306.432251 458.782349,306.331909 459.631165,306.185547 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M448.772308,312.739929 
	C447.828247,312.678253 447.321564,312.340424 447.714874,311.633789 
	C447.856995,311.378387 448.562988,311.203156 448.880768,311.327057 
	C449.578094,311.598938 449.641571,312.144653 448.772308,312.739929 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M454.257080,310.218353 
	C454.851044,309.349945 455.398254,309.406494 455.671783,310.108063 
	C455.796143,310.427094 455.621338,311.135956 455.365875,311.277527 
	C454.659576,311.668976 454.317291,311.166290 454.257080,310.218353 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M452.466064,300.955872 
	C452.824097,300.306000 453.183533,300.296844 453.500488,300.763184 
	C453.566772,300.860687 453.563812,301.086365 453.494507,301.181152 
	C453.163086,301.634583 452.804016,301.617828 452.466064,300.955872 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M432.715912,328.047699 
	C433.000000,328.444458 433.000000,328.888885 433.000000,329.666687 
	C432.371887,329.893402 431.619141,329.827759 431.578949,329.042603 
	C431.563568,328.742218 432.130341,328.412018 432.715912,328.047699 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M430.041992,377.267273 
	C430.481567,377.289856 430.963135,377.579681 431.444702,377.869507 
	C431.278809,378.063995 431.112946,378.258514 430.947083,378.453003 
	C430.659424,378.146851 430.371704,377.840698 430.041992,377.267273 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M412.044098,386.465912 
	C412.694031,386.823914 412.703430,387.183411 412.236969,387.500519 
	C412.139404,387.566833 411.913696,387.563904 411.818848,387.494629 
	C411.365417,387.163239 411.381897,386.804047 412.044098,386.465912 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M469.751831,484.749146 
	C467.091980,489.529022 461.834137,487.611115 457.871124,489.353882 
	C454.402771,490.879120 449.991577,489.927521 447.059570,493.625793 
	C445.213898,496.556305 442.381653,496.333160 439.389587,496.754517 
	C437.902069,496.706482 437.041992,497.294678 435.789062,497.972046 
	C431.177429,498.573303 427.239044,501.705750 422.381744,499.333740 
	C421.123260,494.608490 420.702820,490.184814 418.720947,485.785889 
	C413.854828,474.985321 411.085022,463.291412 405.195160,452.872070 
	C405.016724,452.556396 405.365723,451.942627 405.699493,451.219604 
	C406.999542,451.063629 407.661926,451.797852 408.448700,452.423492 
	C411.040924,454.484924 412.832916,454.022919 412.944946,450.506012 
	C413.057739,446.964813 413.943634,447.051178 416.652771,448.901184 
	C419.299774,450.708801 423.672791,450.609314 422.604401,455.649261 
	C422.564606,455.836914 423.315887,456.420441 423.705933,456.427734 
	C424.814178,456.448517 425.062775,455.496918 424.939484,454.697937 
	C424.346527,450.854889 426.735901,449.828583 429.732635,448.416748 
	C433.840027,446.481598 438.449188,444.158691 436.519287,437.993744 
	C436.088470,436.617493 436.794556,435.872894 437.811432,435.109222 
	C441.045959,432.680054 447.850006,434.111572 450.687469,436.969391 
	C452.584137,438.879669 453.886200,444.880157 458.669769,439.701355 
	C459.834564,438.440338 460.841705,439.712189 460.897583,441.033569 
	C460.978485,442.947418 462.335602,444.305359 462.841766,445.833435 
	C465.622772,454.229279 472.528961,460.209625 475.565826,468.386475 
	C476.147522,469.952759 476.006073,471.300385 474.897369,472.539124 
	C473.404694,474.206940 471.260101,475.483612 473.367310,478.364990 
	C474.417603,479.801178 472.720459,480.686249 471.343353,480.937256 
	C469.105530,481.345123 468.619080,482.536163 469.751831,484.749146 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M448.997833,409.413940 
	C451.394714,410.519012 452.607025,413.167267 454.762054,415.059631 
	C457.748322,417.681976 452.628448,418.097778 451.607941,419.963318 
	C452.036926,421.598053 454.522186,420.550537 454.925140,422.667542 
	C449.487518,424.389252 447.299561,421.556458 448.194855,414.111084 
	C448.367920,412.671631 448.723999,411.254242 448.997833,409.413940 
z"/>
<path fill="#08748E" opacity="1.000000" stroke="none" 
	d="
M322.790771,406.352844 
	C322.981018,404.321014 324.370605,404.073212 325.733643,403.337799 
	C331.050354,403.701538 335.676880,405.745331 340.704224,407.730347 
	C341.233704,408.232941 341.502075,408.516235 342.154785,408.658325 
	C342.449249,408.604889 342.807678,408.629272 342.959717,408.724915 
	C344.965363,410.758789 347.535095,411.378845 350.195740,412.441284 
	C350.887390,412.499329 351.227753,412.594177 351.807770,412.804260 
	C356.205627,416.656158 361.707703,417.933929 366.713379,420.739868 
	C367.613495,420.950073 368.170715,421.167938 368.944702,421.593658 
	C372.594788,425.518707 377.526733,426.849396 381.905701,429.703979 
	C383.049072,430.373291 383.857361,430.932892 384.847229,431.724731 
	C385.654053,432.769897 386.560760,433.174561 387.627930,433.847992 
	C387.815918,434.076843 388.123108,434.257812 388.242554,434.390137 
	C388.717377,436.922241 390.910767,437.463928 392.543243,438.426300 
	C395.497528,440.167786 397.847137,442.378815 399.485168,445.795135 
	C399.878265,446.696228 400.093719,447.299194 400.332031,448.223145 
	C401.908295,451.768066 401.974854,455.591827 404.590454,458.759277 
	C405.761749,461.584137 406.705475,464.128784 407.695496,467.038727 
	C407.245331,468.401001 408.132874,468.880249 408.762329,469.761505 
	C408.977844,473.109680 412.060364,474.966583 412.289246,478.368347 
	C412.153046,478.942169 412.004852,479.148285 411.502472,479.491760 
	C409.187866,479.605774 407.333282,479.380798 405.679993,481.133484 
	C403.340118,483.614014 400.976501,482.823822 399.819580,479.916687 
	C397.607727,474.358795 393.047302,471.898163 387.857391,470.238861 
	C378.198120,467.150635 369.614349,462.709198 363.891266,453.771881 
	C362.122467,451.009766 359.024536,449.088867 355.899414,448.006226 
	C352.049591,446.672577 350.253082,444.097443 349.749359,440.355957 
	C349.342621,437.334839 347.652863,435.722687 344.814514,434.805908 
	C338.983765,432.922577 337.825958,433.919159 338.264404,440.062836 
	C338.635223,445.258972 339.631348,450.221588 341.508453,455.095459 
	C342.687592,458.157043 343.678925,461.515961 340.258240,464.383972 
	C338.041992,463.991058 337.911041,462.383636 337.549652,460.957611 
	C336.608948,457.245514 335.153259,453.677368 334.250061,449.971222 
	C330.982025,436.561401 327.453400,423.231689 323.014069,410.154480 
	C322.640350,409.053619 322.409393,407.901733 322.790771,406.352844 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M437.847961,495.226837 
	C440.843903,495.297546 443.519348,494.689514 446.594452,494.020447 
	C453.412109,493.523956 459.531342,491.633392 465.742950,490.164825 
	C470.525757,489.034149 475.107361,486.961365 480.571686,486.993561 
	C481.880249,485.789185 483.473877,486.238342 484.783813,485.241730 
	C485.027740,485.021210 485.516449,485.020721 485.760742,485.014648 
	C486.965393,484.018829 488.128723,483.664917 489.849060,484.014832 
	C490.436066,484.271881 490.653839,484.413269 491.087189,484.881531 
	C491.414276,486.703217 490.569275,487.679413 489.155090,488.739807 
	C488.318024,489.167480 487.803894,489.312988 486.870117,489.431458 
	C469.131348,493.871124 451.928986,498.851990 433.859558,501.872375 
	C432.982208,502.207001 432.462524,502.326019 431.533081,502.405640 
	C428.515808,502.276306 425.952179,502.698547 423.002716,503.174103 
	C422.355408,503.053497 422.103271,502.971588 421.519531,502.670227 
	C421.049072,502.225403 420.910156,502.000000 420.918976,501.406006 
	C421.369446,500.570496 421.672180,500.103607 422.018555,499.354492 
	C426.643707,499.743164 430.660156,496.863922 435.571289,496.973694 
	C436.539734,496.446442 437.082184,495.923828 437.847961,495.226837 
z"/>
<path fill="#08748E" opacity="1.000000" stroke="none" 
	d="
M412.206207,478.817963 
	C415.364288,485.137146 417.708862,491.795898 419.291931,499.021332 
	C419.970306,500.721161 419.007599,501.492706 417.829620,502.547333 
	C416.429840,503.287933 415.320099,503.454865 413.773102,503.432465 
	C412.843536,503.339600 412.334351,503.219940 411.519623,502.845337 
	C410.017303,501.504547 410.292755,500.342987 411.154144,499.152649 
	C414.903442,493.971924 414.900269,493.969635 410.860474,489.078308 
	C410.499786,488.598633 410.369049,488.394287 410.085510,487.855835 
	C409.474304,484.696655 407.763000,481.647430 411.639893,479.305359 
	C411.920258,479.062683 412.111176,478.899872 412.206207,478.817963 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M399.699432,445.732483 
	C396.408112,443.089905 393.642548,439.488129 389.255249,437.885681 
	C388.183502,437.494232 388.126282,436.348328 388.291534,434.914917 
	C392.617767,437.652008 398.634888,438.935028 399.699432,445.732483 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M366.872498,420.457489 
	C366.827759,420.960480 366.485168,421.429108 366.387024,421.382568 
	C361.492065,419.060150 355.995026,417.833862 352.085754,413.275909 
	C357.163055,414.866425 361.980499,417.414520 366.872498,420.457489 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M382.054932,429.528381 
	C377.160858,428.679230 372.504944,426.784851 369.166199,422.161407 
	C373.850372,423.508453 378.009888,426.119415 382.054932,429.528381 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M416.697876,502.554108 
	C417.653412,501.522156 418.298035,500.806396 419.037964,499.731262 
	C419.654449,499.745911 420.175629,500.119995 420.881775,500.765747 
	C421.066711,501.037415 420.972382,501.518951 421.026917,501.758148 
	C421.893463,503.451202 421.072601,504.793304 420.793945,506.541931 
	C421.015900,508.717682 421.421631,510.546844 419.745911,512.316345 
	C415.002258,510.308655 415.168854,506.752075 416.697876,502.554108 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M404.842804,458.671875 
	C400.265533,457.249634 401.636810,452.650269 400.292969,448.956055 
	C403.014252,451.263428 403.362885,455.051117 404.842804,458.671875 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M350.354370,412.128754 
	C347.383209,413.278259 345.030792,412.062256 343.147614,409.182983 
	C345.829010,408.977539 348.007355,410.387390 350.354370,412.128754 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M387.723145,433.778900 
	C386.686798,434.015900 385.571350,433.929718 385.075378,432.286255 
	C386.161682,432.011383 386.975006,432.611328 387.723145,433.778900 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M409.022278,469.673248 
	C407.925385,469.783783 407.189514,469.316162 407.569214,467.735718 
	C408.181335,468.056854 408.620880,468.709686 409.022278,469.673248 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M342.268860,408.393616 
	C342.086517,408.522339 341.723816,408.439789 341.201599,408.115906 
	C341.390900,407.977173 341.739685,408.079742 342.268860,408.393616 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M321.867188,401.109589 
	C321.954926,401.654663 321.691681,402.102448 320.968719,401.338074 
	C321.105377,401.079346 321.323517,401.077881 321.867188,401.109589 
z"/>
<path fill="#266E80" opacity="1.000000" stroke="none" 
	d="
M315.212158,400.445038 
	C315.072632,400.599976 314.731750,400.591339 314.195435,400.408813 
	C314.336884,400.250427 314.673859,400.265930 315.212158,400.445038 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M491.210571,484.729309 
	C489.578552,484.972595 488.203979,484.991394 486.417236,485.009399 
	C485.925781,482.103241 482.485840,483.872620 481.626160,481.961060 
	C487.426300,479.734558 488.253601,476.977966 485.488739,471.135956 
	C483.108795,466.107269 481.781006,460.469727 478.815582,455.854767 
	C474.241302,448.735992 471.430969,441.017181 468.177521,433.338409 
	C466.004517,428.209778 463.476135,423.596771 463.996155,417.452271 
	C463.995117,417.004883 464.000000,417.000000 464.001221,416.998779 
	C468.011871,415.493042 468.793823,418.676910 469.782104,421.174133 
	C473.504303,430.579407 476.286041,440.393280 482.106476,448.867310 
	C484.066986,451.721680 483.391907,455.773163 486.511505,458.522644 
	C486.958282,459.279480 487.137939,459.747498 487.321289,460.607971 
	C487.454132,465.237885 487.815674,469.394684 491.624390,472.724426 
	C492.693085,474.974091 492.638428,477.000732 492.434143,479.401917 
	C492.270935,480.690094 491.976990,481.589661 491.943542,482.875488 
	C491.836914,483.670898 491.690399,484.101105 491.210571,484.729309 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M484.653931,485.015137 
	C484.733490,487.014191 483.236542,487.006042 481.367798,486.992828 
	C481.268066,484.963165 482.785156,485.004486 484.653931,485.015137 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M486.257111,461.263245 
	C486.005707,460.549622 486.002838,460.107391 485.996948,459.333496 
	C482.595917,447.994568 476.354034,438.228729 472.209961,427.484955 
	C469.771149,421.162109 470.056793,414.272675 466.933868,408.517731 
	C463.915283,402.955078 462.649139,397.006866 461.056671,391.094208 
	C459.013855,383.509460 463.407532,371.440247 469.706573,367.255280 
	C470.319641,366.534790 470.634979,366.065918 470.982147,365.305542 
	C471.934967,363.994385 472.904083,363.078094 474.815002,363.585663 
	C475.724274,364.388275 475.921021,365.134949 475.842682,366.333588 
	C475.332428,367.903809 474.387115,368.801758 473.673950,370.256348 
	C467.420013,375.628418 466.596619,382.422913 467.180420,390.004395 
	C467.214478,391.518433 466.617523,392.779510 467.868347,394.093872 
	C472.823120,402.104004 473.891663,411.174469 477.151031,419.439331 
	C479.799500,426.155060 480.544952,433.482880 484.381012,439.831299 
	C487.726196,445.367310 490.211395,451.377991 491.882019,457.675842 
	C493.325958,463.119202 496.189087,467.963043 498.584473,473.421875 
	C499.040070,474.508148 499.122894,475.280914 499.048279,476.462036 
	C498.287750,478.817047 497.403412,480.721832 496.803955,483.148499 
	C495.343994,485.103119 493.918457,486.330261 491.343018,485.287415 
	C491.019470,485.026611 490.988098,484.989685 490.970612,484.971741 
	C490.967743,484.520355 490.982391,484.086945 491.002258,483.327881 
	C491.098785,482.560242 491.140442,482.113892 491.066772,481.330078 
	C491.005920,478.628387 491.003174,476.259186 490.998291,473.446075 
	C487.620056,469.915527 488.872559,465.016449 486.257111,461.263245 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M469.615143,367.003479 
	C466.406158,373.256683 461.301544,379.261932 462.212219,386.860840 
	C462.976715,393.239990 463.899994,399.801361 467.592834,405.631409 
	C469.830017,409.163269 471.414673,413.365662 470.241364,417.918488 
	C470.045105,418.680084 469.942535,419.812408 470.354095,420.331055 
	C475.111328,426.325714 476.488190,433.885498 479.251923,440.698364 
	C481.576050,446.427551 487.797699,450.830963 485.997314,458.565979 
	C483.839935,458.666473 482.699524,456.845306 482.405212,455.242645 
	C481.000366,447.593628 474.635132,442.059601 473.306641,434.758911 
	C472.411774,429.841064 467.861603,426.669250 468.417175,421.328644 
	C468.605621,419.516968 466.867004,417.706696 464.348328,417.045410 
	C460.338776,404.792847 455.030182,392.574646 459.171539,379.665619 
	C460.745911,374.758179 462.945496,368.864990 469.615143,367.003479 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M475.351593,364.162048 
	C473.900787,364.310272 472.799133,364.617981 471.355743,364.969910 
	C471.193024,360.755493 473.222931,359.302216 477.620087,360.942261 
	C478.356873,361.364319 478.548431,361.815979 478.457336,362.715851 
	C477.705139,363.998657 476.803162,364.375580 475.351593,364.162048 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M477.756226,363.290527 
	C477.978729,362.530640 477.987671,362.091125 477.987915,361.321838 
	C478.148071,360.993042 478.316895,360.994049 478.738953,360.993835 
	C480.063019,359.870453 481.201263,359.048401 483.020477,360.415771 
	C483.360046,360.920563 483.449615,361.152863 483.548676,361.761475 
	C483.411987,362.901642 483.070831,363.575378 482.335907,364.467987 
	C480.956970,365.525208 479.772339,365.947357 478.086609,365.318359 
	C477.511322,364.630981 477.436432,364.134552 477.756226,363.290527 
z"/>
<path fill="#BFBDBD" opacity="1.000000" stroke="none" 
	d="
M488.923340,354.012573 
	C488.859222,354.712372 488.597626,355.134064 488.215912,355.785156 
	C487.446838,356.533142 486.774506,357.007507 485.516235,357.173767 
	C484.541565,357.110474 484.024475,356.921295 483.273254,356.306702 
	C483.644714,352.042206 486.404388,353.443665 488.923340,354.012573 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M482.787964,356.115479 
	C483.445984,356.000397 483.894379,356.006104 484.679016,356.016846 
	C485.561188,356.651001 485.811584,357.374756 485.575867,358.581360 
	C485.098999,359.375732 484.674072,359.671753 483.772461,359.884827 
	C482.869934,359.880371 482.367523,359.771667 481.514465,359.487427 
	C480.555573,358.648010 480.703857,358.011200 481.683228,357.214722 
	C482.119812,356.690002 482.315979,356.437469 482.787964,356.115479 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M480.685486,358.763580 
	C481.461578,359.001160 481.930603,359.015625 482.751038,359.049683 
	C483.393066,359.472412 483.500793,359.915649 483.175598,360.689636 
	C481.834351,360.278900 480.707855,360.653748 479.286926,360.974854 
	C478.992462,360.550690 478.992737,360.108765 478.998322,359.335358 
	C479.386566,358.625854 479.844849,358.471344 480.685486,358.763580 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M481.107605,358.732758 
	C480.550568,358.993378 480.108582,359.000061 479.335144,359.005310 
	C479.129822,357.606201 479.957062,357.016144 481.642487,357.004272 
	C481.734100,357.488861 481.478394,357.983856 481.107605,358.732758 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M459.600006,333.000000 
	C459.973053,334.784943 462.080017,336.742828 459.810699,338.312714 
	C458.342346,339.328491 457.083527,337.944672 455.819702,337.367096 
	C453.300934,336.216095 454.789124,334.871155 455.850525,333.286621 
	C457.066681,332.999176 458.133331,332.999573 459.600006,333.000000 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M459.616699,325.002197 
	C460.311462,325.788422 460.622925,326.576813 460.967194,327.682617 
	C459.076538,329.577820 457.132446,329.424591 456.391785,327.080780 
	C455.704224,324.904938 457.840332,325.134796 459.616699,325.002197 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M447.051941,332.350983 
	C447.000000,332.000000 447.000000,331.500000 447.000000,331.250000 
	C448.649200,331.047546 450.335388,331.069183 450.753632,333.183685 
	C450.889099,333.868500 450.605316,334.510742 449.879669,334.677551 
	C448.261261,335.049683 447.530670,334.063354 447.051941,332.350983 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M488.718933,361.224396 
	C490.172333,361.151001 490.741119,361.808990 490.970398,362.929749 
	C493.529083,375.438538 498.740723,387.120453 502.417969,399.289764 
	C507.096161,414.771606 513.130859,429.839508 517.993164,445.270996 
	C521.659302,456.906250 526.859863,468.065491 528.998291,480.593079 
	C528.399353,484.115021 526.091248,483.999176 523.365601,483.600769 
	C521.826538,478.504944 522.633484,473.406311 520.639832,468.487885 
	C515.091125,454.799011 511.225220,440.509705 505.510864,426.822601 
	C501.399872,416.975861 498.672729,406.465240 495.641510,396.157898 
	C493.023010,387.254150 490.695129,378.263092 488.140228,369.338409 
	C487.532715,367.216248 487.358154,365.108307 487.831421,362.553558 
	C488.100616,361.933044 488.246185,361.701965 488.718933,361.224396 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M489.011047,360.696716 
	C489.046082,360.977875 488.893372,361.370087 488.875458,361.579773 
	C487.718933,363.115570 487.522766,364.866425 486.603210,366.706512 
	C483.830292,373.549957 486.536621,379.432953 488.946228,385.170929 
	C490.433533,388.712555 492.109253,392.120575 492.469757,395.984070 
	C493.001343,401.680634 496.331085,406.460114 497.493408,412.010468 
	C499.605682,422.097290 504.670044,431.127686 508.483826,440.613922 
	C512.061157,449.511871 514.473877,458.920135 517.209534,468.154205 
	C518.444092,472.321564 517.549255,476.799713 515.894897,480.893646 
	C514.958984,483.209808 513.774597,485.519257 510.313293,485.410095 
	C509.060303,484.269806 509.200012,483.136475 509.431335,481.965454 
	C511.241638,472.800262 509.929810,464.369263 506.537323,455.393036 
	C500.597351,439.676239 493.678284,424.248260 489.560242,407.862549 
	C488.989716,405.592499 488.202820,403.418152 486.389374,401.278809 
	C486.332001,393.684204 481.605072,387.900299 480.259155,381.076111 
	C479.371918,376.577515 479.201538,372.208893 480.404541,367.370361 
	C480.605194,366.105560 480.939240,365.342255 481.695068,364.312988 
	C482.196442,363.486603 482.437500,362.991425 482.885345,362.309998 
	C483.871429,360.960419 484.888641,359.989014 486.016205,358.674866 
	C486.782074,358.369080 487.243683,358.398926 487.977905,358.771851 
	C488.496521,359.465729 488.736237,359.940643 489.011047,360.696716 
z"/>
<path fill="#0ABFDE" opacity="1.000000" stroke="none" 
	d="
M538.777039,481.118805 
	C539.432068,480.947449 539.875977,480.995789 540.658813,481.036499 
	C542.430237,481.217743 543.863281,479.869385 545.437988,481.391174 
	C545.414856,484.887360 543.064941,485.737366 540.617737,486.326752 
	C538.075012,486.939148 535.419067,487.021240 532.546021,488.023956 
	C528.783875,488.943481 525.466003,490.186981 521.522705,489.589294 
	C519.963257,488.331696 520.225891,487.078796 520.975464,485.477051 
	C521.525330,484.800018 521.916687,484.509644 522.707397,484.214539 
	C523.915527,484.018524 524.833679,484.213074 525.934692,483.568909 
	C526.629150,483.251709 527.049316,483.146484 527.811768,483.119324 
	C530.307495,483.650391 532.379272,483.421295 534.519653,482.059631 
	C535.043579,481.544678 535.421692,481.383118 536.141479,481.385071 
	C536.938171,481.322449 536.866333,482.502014 537.821289,481.817200 
	C538.237793,481.578186 538.401855,481.458344 538.777039,481.118805 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M528.359680,484.239258 
	C527.564148,484.255402 527.129211,484.314697 526.394897,484.515381 
	C525.646667,484.786987 525.191101,484.886749 524.379272,484.973755 
	C523.600769,485.037598 523.172058,485.083832 522.439148,485.243347 
	C520.522949,486.309662 518.851746,486.765259 516.657898,485.726990 
	C515.176697,483.968903 516.510132,483.513977 517.829712,482.747437 
	C519.601807,482.583801 520.950806,481.880829 522.683289,482.727295 
	C525.023499,483.101715 526.751587,482.240479 528.756470,481.257385 
	C529.884338,481.230408 530.769348,481.453979 531.854004,481.964722 
	C531.841003,484.403778 529.925842,483.760651 528.359680,484.239258 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M528.252441,484.431885 
	C529.207520,483.711426 530.415894,483.226807 531.838928,482.497070 
	C532.489929,482.202087 532.926331,482.152252 533.671875,481.987793 
	C534.236450,481.822235 534.491821,481.771271 534.924072,482.068665 
	C533.485718,484.876251 531.102966,485.086090 528.252441,484.431885 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M488.313782,358.613129 
	C487.803680,359.029572 487.350586,359.068329 486.563477,359.187317 
	C485.986450,359.324890 485.743408,359.382294 485.238037,359.172363 
	C484.985443,358.183929 484.995148,357.462830 485.010071,356.381805 
	C485.785248,356.016418 486.555237,356.010986 487.710510,356.010071 
	C488.187439,356.754883 488.279144,357.495178 488.313782,358.613129 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M535.408447,482.302917 
	C534.917908,482.318756 534.735046,482.220551 534.266602,481.997742 
	C534.335388,480.258789 535.164185,479.687134 536.884766,480.893158 
	C537.210876,481.300873 537.339661,481.457092 537.242310,481.816040 
	C536.582764,482.075470 536.149414,482.132141 535.408447,482.302917 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M537.089478,482.176178 
	C537.016174,482.018738 537.045532,481.581390 537.063782,481.363037 
	C537.260254,481.214264 537.438416,481.283844 537.845154,481.525696 
	C537.979919,482.210602 537.750793,482.529388 537.089478,482.176178 
z"/>
<path fill="#0C161B" opacity="1.000000" stroke="none" 
	d="
M674.531494,875.227051 
	C675.690369,874.791260 676.578613,874.578918 677.810791,874.399170 
	C677.259521,882.597961 682.101929,889.511902 683.189087,897.275574 
	C683.606079,900.252808 683.101807,901.197449 680.128357,901.025391 
	C658.927124,899.798523 649.890564,920.252258 655.282471,938.795715 
	C657.122192,945.122742 660.383850,950.227539 665.106873,954.641541 
	C671.624756,960.733093 678.201660,961.674622 685.977722,957.306824 
	C687.717712,956.329468 689.261414,955.006348 691.787659,955.024780 
	C693.566895,958.024902 691.670593,959.799866 689.620605,961.276245 
	C678.730469,969.118652 658.025208,968.401428 652.128296,951.324158 
	C649.605469,944.018127 648.149231,936.633667 647.536987,928.596558 
	C647.264526,925.734131 648.301514,923.468140 648.585449,920.701599 
	C649.391907,916.025085 650.732605,911.999023 654.176025,908.566528 
	C655.178345,906.842224 656.145508,905.555664 657.748657,904.374756 
	C658.744751,903.709717 659.561279,903.367554 660.356567,902.437195 
	C661.100769,901.769897 661.659485,901.405457 662.541626,900.915100 
	C664.121216,900.260193 665.354614,899.659790 666.945923,899.009399 
	C669.974792,897.681030 674.141602,898.779541 675.452271,896.314392 
	C676.732910,893.905762 674.184326,890.767761 673.329651,887.951721 
	C672.648560,885.707581 672.499390,883.410400 672.071167,880.786377 
	C671.908813,880.214050 671.855225,879.980652 671.761292,879.388062 
	C671.689514,878.296204 671.798584,877.584045 672.198486,876.570679 
	C672.851257,875.640686 673.546448,875.522888 674.531494,875.227051 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M648.572998,927.896240 
	C649.523743,936.298950 651.420959,944.199890 654.301880,952.090027 
	C658.872253,964.606995 673.822754,966.378113 683.052551,962.858032 
	C686.642517,961.488892 690.784485,960.293701 692.103210,955.383545 
	C692.861572,955.029541 693.547729,955.029419 694.578979,955.021362 
	C699.198486,955.854248 703.380005,954.378113 707.950928,954.725464 
	C709.150879,960.020386 704.516113,960.349976 701.833618,961.672180 
	C695.808411,964.642334 689.019409,965.549561 682.908447,968.765259 
	C675.508667,972.659119 667.478699,971.953003 659.701355,968.801514 
	C654.360229,966.637207 652.937988,961.286072 649.595093,956.964600 
	C648.672791,956.086914 648.268311,955.351379 647.799561,954.166077 
	C646.128601,947.078308 645.406677,940.309631 645.520996,933.025146 
	C645.567322,931.740479 645.533264,930.876404 645.967651,929.647949 
	C646.596130,928.469849 647.267395,927.981934 648.572998,927.896240 
z"/>
<path fill="#060F14" opacity="1.000000" stroke="none" 
	d="
M708.476074,955.600952 
	C705.429749,955.947937 702.743774,956.643921 700.013977,956.957581 
	C698.350159,957.148804 696.363831,957.749390 695.029114,955.391602 
	C696.585999,950.352600 694.416565,946.390198 692.293213,942.188110 
	C689.169067,937.611511 688.109192,932.556702 686.641724,927.659790 
	C686.166443,926.073669 685.925903,924.417908 685.363037,922.850220 
	C682.817871,915.761780 678.902344,914.220459 671.602295,917.768433 
	C670.540771,918.992371 669.573792,919.854797 668.625854,921.156738 
	C668.278931,921.556946 668.135132,921.712708 667.723755,922.040649 
	C664.476501,918.552612 666.807251,915.460754 669.127563,913.144043 
	C676.339050,905.943848 685.193237,908.196594 689.506836,917.367188 
	C692.519958,923.773010 694.016052,930.448853 694.845337,937.269958 
	C695.494385,942.608887 698.046448,946.891602 700.686523,951.217163 
	C703.298279,955.496277 706.795898,952.891296 709.929016,951.968323 
	C713.459167,950.928406 710.596619,948.761719 710.533264,947.152405 
	C710.428894,944.495728 708.882019,941.915161 710.447083,939.074829 
	C712.193848,943.395874 713.625427,947.821289 715.034912,952.642700 
	C712.984253,954.037354 711.412903,956.065979 708.476074,955.600952 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M667.504822,922.638123 
	C667.456299,922.212769 667.711487,922.234497 667.836853,922.208191 
	C677.370789,923.491089 686.066895,936.511169 683.280457,945.766479 
	C674.542847,948.062195 668.762329,942.245605 667.958008,933.991760 
	C667.603882,930.357300 666.858337,926.736938 667.504822,922.638123 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M683.402100,946.107666 
	C683.127014,934.991272 677.649963,927.482239 667.989136,922.411133 
	C667.418030,920.517273 668.370850,919.453857 669.732788,918.223633 
	C674.469727,916.127075 678.116760,916.806580 681.406738,920.848633 
	C686.462524,927.060242 688.923889,934.373901 689.207886,942.674561 
	C687.672485,944.792603 685.898682,945.774597 683.402100,946.107666 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M689.277466,942.973877 
	C686.705505,937.178955 685.886414,930.892700 682.717651,925.328430 
	C679.993774,920.545349 676.400635,917.838989 670.411194,918.021606 
	C674.249084,912.388733 682.166199,912.418884 685.040771,918.497131 
	C688.432495,925.669006 690.181213,933.471985 691.988037,941.604370 
	C691.178101,942.325256 690.387512,942.634644 689.277466,942.973877 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M707.760864,928.575623 
	C709.497559,927.103760 708.764832,923.253601 712.666138,924.002075 
	C715.676575,926.095032 715.590942,928.955322 715.224792,932.290222 
	C714.867004,933.556641 714.384583,934.270081 713.476685,935.199158 
	C712.318848,935.849548 711.494507,935.880676 710.318848,935.247925 
	C709.254822,932.981628 708.538025,930.958374 707.760864,928.575623 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M709.973999,935.296753 
	C710.738220,935.000916 711.504700,934.996948 712.654297,934.992065 
	C713.450317,934.615417 713.933960,934.463013 714.844055,934.709839 
	C715.492737,935.356140 715.610657,935.864929 715.403442,936.787231 
	C715.037170,937.325134 714.824036,937.489685 714.222656,937.765076 
	C713.019165,937.950623 712.225647,937.888550 711.148315,937.278442 
	C710.533936,936.566345 710.255127,936.077454 709.973999,935.296753 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M710.857239,937.395264 
	C711.561584,937.036438 712.310425,937.017700 713.431946,936.988892 
	C714.297791,937.302185 714.505310,937.756042 714.199097,938.672607 
	C713.289978,939.006531 712.608826,939.008423 711.589600,939.034241 
	C711.134949,938.617188 711.018311,938.176208 710.857239,937.395264 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M833.207947,776.683594 
	C830.929321,776.674255 828.858582,776.348511 826.393921,776.011353 
	C822.886780,776.143982 821.660828,773.710083 820.062622,771.349548 
	C820.260864,769.314026 821.376160,768.585938 822.725647,769.490662 
	C825.787170,771.543213 829.781616,771.814758 832.692993,774.835205 
	C833.391357,775.378845 833.529968,775.834656 833.207947,776.683594 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M818.750000,780.807922 
	C822.173706,782.495911 821.529602,785.963257 822.720825,788.467346 
	C823.215210,789.506470 822.019836,790.567749 820.756775,790.822449 
	C819.455872,791.084839 818.092346,791.211975 817.026428,790.203552 
	C815.875244,789.114441 816.417236,788.078430 817.453735,787.332947 
	C819.875305,785.591675 815.925293,783.390320 817.961670,781.331116 
	C818.166687,780.871948 818.333313,780.743896 818.750000,780.807922 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M819.623901,771.017029 
	C822.330933,771.933533 823.859070,773.823120 825.740112,775.800903 
	C822.706360,779.909546 820.892639,774.805725 818.267578,774.433655 
	C816.258972,774.148926 816.627625,771.639709 819.623901,771.017029 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M818.986694,780.630127 
	C819.000000,781.000000 818.500000,781.000000 818.250000,781.000000 
	C816.318481,780.950195 814.591980,780.922729 814.247437,778.730530 
	C814.139648,778.044800 814.487854,777.336304 815.248718,777.306335 
	C817.283875,777.226440 818.749878,777.993713 818.986694,780.630127 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M829.191711,782.749512 
	C828.699463,784.653198 827.708069,785.257263 826.162231,784.673462 
	C825.014893,784.240173 824.852905,783.116333 825.287170,782.205322 
	C826.029724,780.647217 827.269043,781.858826 828.648926,781.956787 
	C829.126160,782.166382 829.254822,782.332764 829.191711,782.749512 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M833.061401,777.308350 
	C833.000000,776.555542 833.000000,776.111084 833.000000,775.333313 
	C834.565552,774.262085 836.139709,773.876587 835.834106,776.420044 
	C835.678406,777.715942 835.189270,779.888489 833.061401,777.308350 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M786.649902,752.414551 
	C785.857117,754.838501 785.857117,754.838501 782.603455,754.084900 
	C783.387207,752.419861 785.223999,753.200989 786.649902,752.414551 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M829.362793,782.998413 
	C828.999756,782.833313 828.999512,782.666687 828.998413,782.250000 
	C829.205933,780.657166 829.862488,779.807068 831.303589,780.364746 
	C831.810242,780.560913 832.504883,781.325684 832.447754,781.738708 
	C832.220886,783.379089 830.768921,782.833740 829.362793,782.998413 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M811.569092,806.174805 
	C813.265076,807.580322 812.229187,808.266541 811.113831,808.689148 
	C810.318787,808.990356 809.410217,808.720886 809.318542,807.670288 
	C809.202209,806.336121 810.205933,806.147461 811.569092,806.174805 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M811.557495,762.407593 
	C812.425293,761.296082 813.249939,761.004272 814.113098,761.930115 
	C814.595398,762.447327 814.774170,763.176453 814.066284,763.550903 
	C813.003540,764.113220 811.889954,764.288757 811.557495,762.407593 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M804.739136,770.467407 
	C804.482422,769.460876 804.983398,769.147034 805.638916,769.274048 
	C805.943970,769.333130 806.429260,769.904602 806.372253,770.124268 
	C806.187012,770.838074 805.559631,770.857178 804.739136,770.467407 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M793.586792,753.341309 
	C793.388245,752.626831 793.637329,752.366333 794.192932,752.478210 
	C794.308228,752.501404 794.462830,752.665283 794.480103,752.781921 
	C794.562927,753.342651 794.289978,753.577393 793.586792,753.341309 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M808.955811,776.534058 
	C808.305847,776.175964 808.296631,775.816528 808.763184,775.499634 
	C808.860718,775.433350 809.086426,775.436157 809.181213,775.505493 
	C809.634521,775.836975 809.618103,776.196045 808.955811,776.534058 
z"/>
<path fill="#D0CFCE" opacity="1.000000" stroke="none" 
	d="
M751.843628,782.297791 
	C754.055298,781.597351 756.028442,780.701355 758.596069,781.000122 
	C753.640259,789.810120 744.283508,794.062256 736.841309,800.498596 
	C732.462524,804.285461 727.950623,808.043213 724.264526,812.454163 
	C722.386780,814.701111 720.876648,815.326965 718.467957,814.634583 
	C714.220581,813.413757 710.934631,814.866333 708.511719,818.275208 
	C707.403381,819.834412 706.262329,821.197632 704.422791,821.684448 
	C701.739563,822.394409 699.607788,823.658325 698.158447,826.158997 
	C696.992920,828.169922 694.826416,828.057434 692.403809,828.001831 
	C689.204529,825.403687 690.038208,823.187378 692.565308,820.924927 
	C696.336609,817.548645 701.486328,816.778381 705.479553,813.742371 
	C711.944641,808.827087 720.130859,807.041443 726.814697,802.369202 
	C729.896851,800.214600 731.141663,795.986755 735.648621,795.100525 
	C742.850403,792.915161 746.366150,786.616028 751.843628,782.297791 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M735.999756,794.999634 
	C735.860107,796.452881 735.215759,797.474182 733.720947,797.890015 
	C732.949890,798.104675 731.545898,798.615295 731.575684,798.767334 
	C732.725830,804.639709 726.867432,803.934692 724.428833,805.300293 
	C720.486084,807.507935 717.266235,811.731934 711.796448,811.215637 
	C709.473022,810.996399 708.331177,813.930786 706.460999,815.229187 
	C702.576233,817.926392 697.903442,818.819702 694.066162,821.679932 
	C691.663818,823.470581 691.272095,824.878357 691.994019,827.640015 
	C691.529968,828.314392 691.057495,828.626282 690.292480,828.967896 
	C689.011292,826.319946 687.342346,825.513855 684.557800,826.608704 
	C681.354919,827.867981 678.028503,828.813110 674.377930,829.947388 
	C672.989014,828.891602 672.596252,827.613647 673.081177,825.757202 
	C673.959839,824.117065 675.239075,823.652100 676.518372,823.310425 
	C690.168640,819.664978 701.544495,811.401367 713.836853,804.998047 
	C716.333557,803.697449 718.602173,801.872986 721.452148,801.341003 
	C724.452209,800.781067 725.769531,798.496765 727.321960,795.844299 
	C727.810913,795.399353 728.030029,795.245056 728.618286,794.923218 
	C731.091614,794.371094 733.158203,793.098267 735.663940,794.737793 
	C735.990234,794.989014 735.997131,794.996765 735.999756,794.999634 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M700.068359,844.715820 
	C698.168884,843.622131 694.974060,844.348450 695.366211,841.543518 
	C695.684570,839.266602 698.125671,837.827576 700.339783,837.226685 
	C702.896790,836.532837 703.860840,838.349121 704.119202,840.621826 
	C704.155701,840.943054 704.280334,841.270630 704.431885,841.559692 
	C705.167542,842.962952 706.849365,844.382141 705.173584,845.965454 
	C703.309387,847.726807 701.899658,845.694092 700.068359,844.715820 
z"/>
<path fill="#D0CFCE" opacity="1.000000" stroke="none" 
	d="
M672.379944,862.485718 
	C671.062073,861.378052 671.041870,860.417908 672.247314,859.612183 
	C673.053650,859.073181 673.928650,859.402100 674.292419,860.137329 
	C675.076233,861.721497 674.297546,862.505737 672.379944,862.485718 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M674.475708,875.000366 
	C674.400513,875.295654 673.998901,875.587830 673.337158,875.961670 
	C672.661194,876.399353 672.184692,876.524780 671.323730,876.208618 
	C670.999084,875.248413 670.998169,874.499329 670.996216,873.375732 
	C672.019592,873.708984 672.587036,875.139099 674.475708,875.000366 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M714.924927,937.275391 
	C714.808228,936.591919 714.744812,936.195740 714.576660,935.516846 
	C714.348389,934.671265 714.091858,934.146362 713.967468,933.288330 
	C713.718079,930.264771 713.500366,927.527527 713.171509,924.401428 
	C714.654114,921.475037 713.427429,917.958008 716.037231,915.382690 
	C717.526611,909.871643 719.953247,907.807617 725.541870,907.514954 
	C725.003174,913.091980 720.823547,916.863464 719.159729,921.986755 
	C717.106689,928.308716 719.328064,939.372864 724.642212,943.331299 
	C728.379211,946.114990 732.921997,945.047119 737.086243,945.655029 
	C739.281128,945.975464 741.839966,945.560974 743.899536,944.688110 
	C748.300903,942.822937 749.965332,939.278625 748.678223,934.536926 
	C748.465698,933.753967 748.056396,932.851868 749.064880,932.381470 
	C750.040161,931.926636 750.618469,932.605103 750.944092,933.438354 
	C753.098755,938.952271 756.788757,941.286072 762.695679,939.245056 
	C762.937073,939.161743 763.422485,939.784668 763.909058,940.399902 
	C763.440857,940.951233 762.857788,941.181091 762.096985,941.670776 
	C760.325256,945.233643 757.167969,946.115784 754.035095,946.387939 
	C749.938660,946.743835 746.276062,948.007263 742.719421,949.941772 
	C739.303833,951.799500 735.615173,952.780640 731.687195,952.675659 
	C722.650696,952.434082 718.326172,950.293457 716.044922,941.411011 
	C715.922424,940.593323 715.872437,940.183105 715.737549,939.474365 
	C715.446167,938.630432 715.120789,938.134766 714.924927,937.275391 
z"/>
<path fill="#060D0E" opacity="1.000000" stroke="none" 
	d="
M708.533569,900.305542 
	C708.883728,899.501953 709.055908,899.087402 709.392151,898.378113 
	C715.652039,899.382019 721.598206,898.077881 727.584595,897.139709 
	C730.709473,896.650085 733.813843,897.086060 736.902222,897.892456 
	C739.595520,898.595642 741.040466,900.344482 740.693298,902.850830 
	C740.320251,905.544067 737.667725,904.630859 735.844421,905.070862 
	C733.261230,905.694092 731.789978,902.882202 728.974792,903.365112 
	C726.612244,904.985840 724.364685,904.600464 721.745911,904.381958 
	C717.599731,902.623413 714.081604,905.811890 709.997253,904.637329 
	C708.395996,903.471802 707.896118,902.244751 708.533569,900.305542 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M708.361694,900.042236 
	C709.024658,901.083008 709.337830,902.249634 709.803467,903.746094 
	C713.630005,906.040894 716.757629,902.401184 720.642151,903.107727 
	C723.241150,903.214905 725.503052,903.281372 728.170532,903.413696 
	C728.646851,903.967346 728.717712,904.455139 728.873657,905.178650 
	C728.581421,906.637756 727.533630,907.097839 726.214722,907.758545 
	C725.929382,907.942749 725.962341,907.815430 725.916809,907.767212 
	C721.422485,908.226318 719.225342,911.669861 716.436157,914.877441 
	C715.310913,914.489197 714.541565,913.805298 713.642944,913.435852 
	C707.547974,910.930237 705.688843,906.607300 708.361694,900.042236 
z"/>
<path fill="#060F14" opacity="1.000000" stroke="none" 
	d="
M731.268188,920.966431 
	C730.109802,920.770264 729.218018,920.580200 729.211914,919.512085 
	C729.190247,915.730652 735.643372,910.072021 740.012146,910.004761 
	C744.679626,909.932922 746.680298,912.334106 747.066528,918.231140 
	C747.195679,920.202698 747.819519,922.148743 747.874207,924.115967 
	C747.915588,925.605042 748.964050,927.966675 746.753052,928.357727 
	C745.026611,928.663025 744.164917,926.538696 743.566223,924.988403 
	C742.913025,923.296875 742.617676,921.455566 741.867432,919.816345 
	C741.148621,918.246033 740.668762,916.184937 737.853882,916.460815 
	C736.402283,918.058899 734.616516,918.649719 733.067871,920.091919 
	C732.452576,920.601990 732.047607,920.808533 731.268188,920.966431 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M770.941101,938.631104 
	C769.847717,939.345154 768.781372,939.665405 767.374390,939.997253 
	C767.348267,938.481873 766.836670,937.121521 766.317017,935.708984 
	C762.502502,925.339172 760.039368,914.604797 757.880615,903.395874 
	C762.200317,907.551270 763.069031,913.606934 764.420898,919.237427 
	C766.003845,925.830383 769.641418,931.621582 770.941101,938.631104 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M732.005737,920.248291 
	C732.470093,917.214111 734.932983,917.256348 737.192505,916.566833 
	C740.904724,919.987366 740.997742,924.562683 741.723267,928.877502 
	C742.221558,931.841003 740.564819,933.097717 737.341675,932.982056 
	C734.813049,928.990112 734.616577,924.328186 732.005737,920.248291 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M731.688477,920.083618 
	C736.040222,921.110901 735.670654,925.174316 736.939941,928.126099 
	C737.415588,929.232056 736.992798,930.724365 736.964417,932.467896 
	C732.531616,933.071411 731.777588,929.693726 730.593872,926.207031 
	C730.146118,925.175964 730.067871,924.472717 730.439453,923.426636 
	C730.741333,922.635620 730.890991,922.171692 730.982361,921.341919 
	C731.114685,920.725342 731.307251,920.476013 731.688477,920.083618 
z"/>
<path fill="#060F14" opacity="1.000000" stroke="none" 
	d="
M730.331421,923.177856 
	C730.572021,923.795166 730.552368,924.490906 730.466125,925.528259 
	C728.827026,925.182495 728.604187,924.325500 730.331421,923.177856 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M726.320251,907.950439 
	C725.823059,906.290649 727.201416,906.100403 728.604431,905.500000 
	C728.963257,906.928284 728.358582,907.903931 726.320251,907.950439 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M756.496460,900.343506 
	C757.496216,900.390015 757.968445,901.187439 757.969849,902.680359 
	C756.922668,902.622314 756.896729,901.569275 756.496460,900.343506 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M805.559692,868.447083 
	C808.790344,875.105347 808.821350,875.282227 817.339417,873.789429 
	C820.607788,873.216736 823.823120,872.988281 827.551514,872.975220 
	C833.181580,872.699097 833.770996,877.753845 836.346924,880.416199 
	C837.506592,881.614807 835.565613,882.390625 834.416992,883.093201 
	C829.540039,886.076477 824.065674,887.811707 819.295532,891.159241 
	C813.275635,895.383850 810.507751,900.726929 811.032715,907.829407 
	C811.548889,914.812683 814.112976,920.669250 820.949951,923.831055 
	C825.247192,925.818420 829.361633,924.690186 833.470764,923.234680 
	C835.455627,922.531494 837.242554,921.151428 839.758423,921.734497 
	C840.130249,922.186218 840.271240,922.403992 840.365356,923.010193 
	C839.911987,924.414917 839.176392,925.152161 837.959351,925.971008 
	C832.995667,928.386108 828.601196,931.103149 823.079224,930.669556 
	C818.224731,930.288330 813.917725,929.080688 810.551392,924.783752 
	C809.755676,923.662231 809.505981,922.667358 808.683044,921.581116 
	C808.375793,920.789856 808.354248,920.314026 808.615112,919.521484 
	C807.729919,913.969666 806.691284,908.725403 806.568848,902.875854 
	C805.826050,901.577698 805.752930,900.636841 805.852051,899.315552 
	C806.246277,897.555725 807.024780,896.377686 808.083130,894.963806 
	C810.137634,893.061218 812.071411,891.536560 814.052551,889.563721 
	C815.164124,888.648682 815.978882,887.902466 817.288147,886.704163 
	C818.024536,886.198120 818.225952,886.099426 818.742798,885.885864 
	C820.121155,885.501099 821.231384,885.403259 822.539185,884.791870 
	C824.442993,884.097107 826.099548,883.618347 827.276001,881.875305 
	C825.650696,878.440063 822.558899,878.359985 819.721924,878.581055 
	C816.995789,878.793518 814.180298,879.107422 811.269714,880.433594 
	C808.586914,882.198486 806.109802,883.007446 802.988953,881.528320 
	C800.925293,879.426392 801.937866,876.906067 800.970703,874.426331 
	C800.688599,872.828369 800.868713,871.725586 801.988342,870.525635 
	C803.307190,869.695984 804.265503,869.100830 805.559692,868.447083 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M809.255615,894.703979 
	C808.699524,896.222534 807.982056,897.328857 807.042603,898.679565 
	C802.153931,903.999390 800.811890,910.815979 797.376953,916.943970 
	C789.647156,919.171875 789.187073,919.057983 786.675171,912.028748 
	C784.920166,907.117493 782.802673,902.255554 782.763733,896.454895 
	C784.416565,892.727539 787.547852,891.322449 790.766846,890.816589 
	C797.195679,889.806091 803.592407,889.956604 809.255615,894.703979 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M769.014404,918.278687 
	C767.282959,910.801086 765.536560,903.615601 763.880554,896.409363 
	C763.217834,893.525513 761.468079,892.720581 758.382080,893.360352 
	C757.421448,893.139038 756.883423,892.852478 756.172791,892.262695 
	C755.932556,892.073364 755.834595,891.658020 755.815735,891.446655 
	C755.598938,888.216919 757.160522,886.713318 760.002441,886.156372 
	C762.828430,885.602783 763.694641,887.583008 764.819824,889.494202 
	C769.256287,897.029419 770.135925,905.763733 773.109802,913.791565 
	C775.504517,920.256042 776.858032,927.009705 783.509399,931.233521 
	C791.490356,936.301697 801.741699,932.981445 804.309753,923.839722 
	C804.472290,923.261169 805.084473,922.809021 805.825989,922.146362 
	C807.946899,923.589478 807.528259,925.678711 807.418579,928.122253 
	C806.990173,931.325195 805.612854,933.327698 802.455688,934.209045 
	C800.885437,934.695801 799.739075,935.231079 798.329956,935.948242 
	C787.347290,943.497498 774.292542,937.889465 770.580811,923.993408 
	C770.274231,922.675110 770.093994,921.740112 769.816895,920.461060 
	C769.473206,919.607178 769.143982,919.133484 769.014404,918.278687 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M809.723389,895.047058 
	C800.672241,891.788818 791.965881,889.698486 783.145996,895.972168 
	C782.488098,895.743347 782.201355,895.461121 781.747925,895.050659 
	C779.101074,891.330383 779.502258,889.432617 783.446228,887.426208 
	C786.344238,885.951965 789.534851,884.989014 791.941772,882.034058 
	C796.576660,881.602295 795.625732,877.530457 797.082581,875.236328 
	C798.334656,873.264832 798.083374,870.079956 801.967285,870.195190 
	C802.283569,871.281006 802.218201,872.270996 802.096436,873.631531 
	C801.688171,875.093079 801.406616,876.210449 800.652466,877.519653 
	C797.479980,884.325317 799.162537,887.010620 806.422241,887.553650 
	C809.268005,887.766418 812.453918,886.586182 814.893433,889.659546 
	C813.346008,891.660156 811.687927,893.319031 809.723389,895.047058 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M806.529724,929.250977 
	C806.201294,927.015259 806.169922,924.949341 806.151489,922.439087 
	C807.154663,921.311279 806.718384,919.560669 808.504517,919.233032 
	C808.859680,919.598450 808.924316,919.986389 809.053833,920.663208 
	C809.557739,921.490479 810.014465,922.013611 810.697083,922.800476 
	C816.792419,929.766541 825.021179,931.220642 832.699280,926.963196 
	C834.129883,926.169922 835.654480,925.546204 837.509155,924.835938 
	C839.463684,927.153503 837.623840,928.440796 836.314331,929.839844 
	C834.606018,931.664856 832.492737,932.991272 830.148193,934.615295 
	C829.433777,934.830322 829.022583,934.830505 828.303101,934.829590 
	C820.827881,937.800415 812.918884,935.766174 807.774414,929.653809 
	C807.582947,929.426270 807.163513,929.435730 806.529724,929.250977 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M750.072998,862.207581 
	C750.406433,861.344360 750.888123,860.726318 751.654541,860.023193 
	C754.416870,859.649353 756.592224,859.076843 756.228088,863.239258 
	C755.969238,866.197876 757.467224,869.270264 757.810852,872.333496 
	C758.110535,875.004272 758.713379,878.113342 754.397400,879.005249 
	C751.094788,874.356506 748.732483,869.551147 750.168701,863.404907 
	C750.271790,862.831421 750.246521,862.642090 750.072998,862.207581 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M792.180298,881.593872 
	C792.550171,886.905396 788.347351,887.443726 785.129150,888.524292 
	C782.123657,889.533447 780.534973,890.829956 781.560425,894.521362 
	C777.291260,893.883667 777.603394,889.891357 777.319397,887.023621 
	C777.016479,883.965027 780.305725,884.366028 782.513855,883.929443 
	C785.504578,883.338135 788.876343,883.488770 790.974365,880.041687 
	C791.428162,880.227417 791.733826,880.766907 792.180298,881.593872 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M749.940796,863.161072 
	C751.530823,868.084900 752.764587,873.149109 754.004150,878.615479 
	C751.334717,877.620605 749.696167,877.966736 749.868530,881.834961 
	C745.993164,878.043274 745.879822,868.718079 749.940796,863.161072 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M797.566223,917.269775 
	C798.102295,910.157227 800.493408,903.785034 806.483948,898.967834 
	C806.698730,899.854980 806.576843,900.786011 806.424927,902.064331 
	C802.530884,905.467041 802.944397,909.866699 802.795410,914.497925 
	C803.021729,915.094788 803.183960,915.263123 803.001770,915.497375 
	C801.080811,916.135010 799.504211,916.706665 797.566223,917.269775 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M754.086426,889.466309 
	C751.784119,888.822876 751.012878,886.665161 750.220459,884.208862 
	C752.919617,884.611206 753.538513,886.829895 754.086426,889.466309 
z"/>
<path fill="#0F1523" opacity="1.000000" stroke="none" 
	d="
M802.775146,915.928467 
	C802.657410,915.563416 802.685181,915.221863 802.772339,915.074158 
	C805.771912,915.585266 804.913208,917.775513 804.837341,921.034668 
	C803.875671,918.690002 803.384277,917.491760 802.775146,915.928467 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M768.402405,856.468384 
	C769.428467,856.283508 770.433594,856.380493 771.719360,856.738708 
	C772.160095,857.166687 772.320251,857.333313 772.240173,857.750000 
	C771.280090,858.277039 770.560242,858.554016 769.449951,858.845581 
	C768.833557,858.156860 768.607483,857.453491 768.402405,856.468384 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M968.664795,760.738037 
	C965.454407,765.256775 961.672607,768.858948 956.167786,770.952271 
	C947.952148,774.076599 939.203674,776.462158 932.718750,783.093445 
	C931.965820,783.863342 930.721985,784.423767 929.642761,784.552429 
	C926.616821,784.912964 923.974548,785.574768 921.945862,788.250122 
	C920.896362,789.634094 919.007568,789.989136 916.939331,789.558960 
	C912.118652,788.556274 907.526794,789.355225 903.278442,792.063904 
	C900.263794,793.985962 893.262268,791.672607 891.240112,788.091492 
	C890.585632,786.932312 890.332397,785.277100 888.826111,785.223022 
	C878.380432,784.848206 872.380859,776.166077 863.960144,772.005066 
	C851.673767,765.934143 842.035522,756.128601 831.242493,747.944336 
	C827.870300,745.387146 823.366394,744.300232 819.906311,742.190674 
	C814.528931,738.912109 808.736145,735.968079 804.010315,731.614929 
	C800.648804,728.518494 796.084900,728.395081 792.105286,727.322144 
	C787.978027,726.209534 785.817322,730.723328 783.140686,733.297852 
	C782.062927,734.334534 782.336792,736.274475 780.312134,736.912964 
	C779.339050,736.548645 778.783020,736.049744 778.319214,734.990601 
	C777.914429,732.032227 779.607727,730.394348 781.358154,728.385010 
	C785.254578,723.753601 789.260742,720.421265 795.586670,721.338013 
	C798.623230,721.778076 801.583618,722.192017 803.911072,724.232727 
	C814.677490,733.671875 828.164062,738.495605 840.228271,745.727356 
	C843.370422,747.610962 846.392517,749.735291 849.206116,751.946167 
	C861.326660,761.470032 874.371094,769.566406 887.626465,777.301514 
	C900.881287,785.036377 914.396545,785.557617 928.218506,777.935364 
	C937.286194,772.934814 946.452332,767.934814 956.631836,765.315552 
	C958.401550,764.860107 959.945984,763.797546 961.783691,762.578857 
	C962.874207,761.678345 963.690918,761.046753 964.927673,760.330566 
	C966.354858,759.841797 967.383118,759.885071 968.664795,760.738037 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M778.367798,734.910645 
	C779.122375,735.431946 779.503357,735.893860 779.928711,736.664612 
	C778.672852,738.757568 777.372559,740.541748 776.018433,742.662109 
	C775.967712,743.594055 775.795166,744.128113 775.190796,744.776367 
	C774.313293,746.394043 772.821411,747.432190 772.964783,749.587341 
	C771.097595,751.414429 770.210999,754.152344 766.996704,754.121582 
	C766.307922,753.364685 765.877808,752.657898 766.163025,752.079651 
	C769.274902,745.771179 772.305359,739.393494 778.367798,734.910645 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M969.053589,760.646851 
	C967.925537,760.993408 966.871582,761.005127 965.421997,761.019531 
	C964.402832,760.560974 964.176941,759.908569 964.173462,758.815308 
	C964.499939,757.291870 965.345886,756.445374 966.182007,755.197510 
	C966.592773,754.794067 966.778442,754.655273 967.289551,754.396545 
	C967.844238,754.254944 968.073486,754.233337 968.642761,754.266052 
	C969.652527,754.556885 970.111328,755.060669 970.806641,755.805664 
	C971.402954,757.912476 969.821228,758.912659 969.053589,760.646851 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M766.611877,754.190735 
	C768.682739,752.627380 770.489685,751.343445 772.628540,749.997314 
	C772.971619,750.393250 772.982849,750.851257 772.993652,751.652344 
	C770.471252,753.284546 770.257019,756.680054 767.295410,757.925903 
	C766.458618,757.387878 766.064026,756.744690 766.024841,755.565186 
	C766.155396,754.945068 766.251709,754.707642 766.611877,754.190735 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M775.257568,744.937988 
	C775.153381,744.429199 775.332947,743.892456 775.738525,743.177063 
	C776.970947,743.889771 776.751038,744.509521 775.257568,744.937988 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M971.089417,755.795715 
	C970.491333,755.873230 969.935242,755.704956 969.180298,755.321472 
	C968.683777,753.906921 969.403870,753.664734 970.718018,753.859253 
	C971.100769,754.244202 971.123474,754.412659 971.214478,754.827881 
	C971.282715,755.074646 971.131409,755.549927 971.089417,755.795715 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M770.311768,924.030884 
	C771.007568,924.201111 771.723206,924.705750 772.089722,925.395813 
	C778.464172,937.396667 784.164917,939.838867 797.667969,936.125122 
	C794.839966,941.581726 788.002014,943.489868 781.110352,940.861877 
	C775.133423,938.582703 770.120972,931.064819 770.311768,924.030884 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M806.071167,929.368042 
	C807.344238,929.327942 808.903137,926.242188 809.507874,929.230591 
	C810.204773,932.674622 812.772522,933.132080 815.327393,933.540710 
	C819.332581,934.181396 823.322998,935.463745 827.727417,934.623413 
	C824.322449,940.053223 819.141113,938.403320 815.023499,936.565186 
	C810.518127,934.553772 806.814087,934.886047 802.416870,936.983154 
	C802.038940,936.324707 802.035645,935.602173 802.032104,934.520020 
	C803.324463,932.658630 804.617126,931.156799 806.071167,929.368042 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M796.658813,942.064697 
	C795.101135,945.934998 789.135376,948.505188 784.140015,947.533447 
	C781.883240,947.094421 779.655273,946.227539 777.393982,946.150818 
	C773.096558,946.005188 771.458130,943.957764 771.974976,939.442383 
	C776.261414,942.939148 781.212646,944.931091 787.106995,945.089355 
	C790.675110,945.185181 793.425110,943.688416 796.658813,942.064697 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M768.785156,918.158569 
	C769.282776,918.501770 769.572510,919.008667 769.791138,919.816345 
	C768.811829,919.854492 768.015137,919.520996 768.785156,918.158569 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M755.953674,892.355713 
	C756.528259,892.304260 757.124023,892.535095 757.851929,893.074951 
	C756.910767,894.222534 756.360168,893.652771 755.953674,892.355713 
z"/>
<path fill="#DCD9D9" opacity="1.000000" stroke="none" 
	d="
M772.016357,858.377197 
	C772.000000,858.000000 772.000000,857.500000 772.000000,857.250000 
	C773.100464,857.329895 774.200867,857.659729 775.650635,857.994751 
	C776.000000,858.750000 776.000000,859.500000 776.000000,860.625000 
	C774.324829,860.874634 772.234192,861.483337 772.016357,858.377197 
z"/>
<path fill="#DDDADA" opacity="1.000000" stroke="none" 
	d="
M795.412964,871.658813 
	C795.611755,872.373230 795.362366,872.633179 794.807190,872.521545 
	C794.691956,872.498474 794.537292,872.334656 794.520081,872.218079 
	C794.437317,871.657593 794.710022,871.422852 795.412964,871.658813 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M766.362061,754.558228 
	C766.783691,755.520386 766.873535,756.338074 766.962524,757.567505 
	C765.021667,758.261658 764.973633,759.719604 764.954529,761.561768 
	C763.276550,762.230652 761.877686,762.921570 760.819824,764.352356 
	C755.669312,771.318787 749.606323,777.477234 743.292969,783.835571 
	C742.630920,784.342163 742.194763,784.511353 741.351013,784.374634 
	C739.532410,781.015869 742.847656,779.594849 743.795471,776.904236 
	C745.771362,772.065491 750.065125,769.843811 753.512695,766.395508 
	C754.443665,765.740906 755.247803,765.429321 755.672363,764.289001 
	C756.209473,763.013550 756.841614,762.177246 757.868591,761.245239 
	C759.348267,760.399780 759.667725,759.123901 760.386230,757.710327 
	C762.064819,755.834595 763.694519,754.481262 766.362061,754.558228 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M740.662354,784.012573 
	C741.440552,783.995972 741.886108,783.996887 742.665833,783.998901 
	C741.884399,788.232361 737.886963,790.530396 736.037598,794.645508 
	C733.923096,795.319580 731.856018,795.650085 729.395203,795.991516 
	C728.410095,795.164978 728.512451,794.254272 728.821960,792.997864 
	C729.192200,792.009766 729.580688,791.432983 730.290649,790.661743 
	C731.071472,789.992676 731.664917,789.660645 732.561157,789.191223 
	C734.071350,788.616821 735.335388,788.268066 736.230591,786.762878 
	C737.638794,785.525574 738.830017,784.537964 740.662354,784.012573 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M971.287720,753.932739 
	C970.499939,754.249573 969.921875,754.423401 969.162598,754.851807 
	C968.981384,755.106262 968.497986,755.070374 968.256653,755.047791 
	C966.842529,751.798218 968.480957,750.703613 971.752563,750.833984 
	C972.116089,751.531189 972.118652,751.968506 972.164185,752.732056 
	C971.970581,753.302185 971.734009,753.545959 971.287720,753.932739 
z"/>
<path fill="#0F1523" opacity="1.000000" stroke="none" 
	d="
M845.176514,911.781616 
	C841.496460,913.036743 838.028625,912.686401 834.142212,911.882141 
	C833.340454,910.380310 834.348206,909.286621 834.799561,907.699829 
	C835.458191,904.788391 835.541687,901.849243 832.835266,900.875183 
	C829.501526,899.675110 826.922302,901.973145 824.740234,904.792114 
	C824.683228,908.022217 827.243225,909.661072 828.120605,912.455078 
	C825.731079,914.875854 824.334045,912.787842 823.314941,911.329956 
	C821.156677,908.242737 819.727356,904.618713 821.735779,901.051697 
	C824.839844,895.538574 829.754578,891.710083 835.546143,889.426270 
	C840.404114,887.510681 845.877441,894.130981 844.626099,900.411743 
	C843.957336,903.768494 843.700012,906.956055 844.940552,910.593811 
	C845.126709,911.173767 845.250916,911.350037 845.176514,911.781616 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M830.212524,934.850403 
	C831.730347,931.050293 836.260864,929.549500 837.872681,925.176392 
	C838.358948,924.257812 838.835205,923.687988 839.556152,922.982544 
	C841.267090,921.979126 842.606140,922.570984 843.971497,923.168945 
	C848.284424,925.057739 851.518738,923.672363 854.459229,919.418091 
	C855.963989,918.876038 856.929382,918.747620 858.248169,918.533447 
	C857.822815,922.784241 853.217896,927.919922 848.450378,927.695068 
	C842.152649,927.398071 837.677612,929.983337 833.324707,933.762878 
	C832.620300,934.374512 831.505371,934.513245 830.212524,934.850403 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M828.426819,912.753174 
	C825.525269,911.068359 823.672363,908.834900 823.073120,905.380005 
	C828.659851,901.421204 832.401245,902.088745 835.817078,907.633545 
	C835.269653,909.073059 834.617554,910.152222 833.842529,911.532104 
	C833.719666,911.832764 833.831482,911.966370 833.910156,911.928955 
	C833.521973,912.526306 832.738403,912.480530 831.766479,912.860107 
	C830.536560,912.874817 829.649231,912.795776 828.426819,912.753174 
z"/>
<path fill="#0C161B" opacity="1.000000" stroke="none" 
	d="
M844.978699,912.451355 
	C844.983887,911.863770 844.989746,911.690552 844.999023,911.257446 
	C850.363708,910.648560 852.432373,914.723083 854.924683,918.666138 
	C854.998657,919.004456 855.093201,919.148987 855.120178,919.231018 
	C852.479858,921.875610 849.716919,923.928955 845.915833,920.603210 
	C845.531738,917.742676 845.255554,915.304260 844.978699,912.451355 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M845.508423,920.102783 
	C848.981750,922.717896 851.614929,920.327820 854.769043,919.276123 
	C852.891968,926.167114 848.434998,927.303406 840.169556,922.942139 
	C839.800781,922.846802 839.874268,922.403381 839.931763,922.185913 
	C841.658875,921.132019 841.837341,916.291443 845.508423,920.102783 
z"/>
<path fill="#0F1523" opacity="1.000000" stroke="none" 
	d="
M831.792786,912.927856 
	C832.036499,912.584839 832.649048,912.215759 833.625244,911.869080 
	C833.379761,912.228271 832.770691,912.565063 831.792786,912.927856 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M862.996826,916.397339 
	C863.066223,916.976807 862.762695,917.452209 861.972046,916.891174 
	C862.063477,916.522339 862.323364,916.354614 862.996826,916.397339 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M860.994995,911.003906 
	C860.989990,911.007751 861.413025,911.232727 861.413025,911.232727 
	C861.413025,911.232727 861.107361,911.221863 861.057007,911.106262 
	C861.006714,910.990662 861.000000,911.000000 860.994995,911.003906 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M972.123657,750.866943 
	C969.940918,751.142090 968.724976,752.382385 968.046875,754.678711 
	C968.015320,755.025208 967.608765,755.114990 967.402405,755.142517 
	C966.503357,755.618347 965.759644,755.862610 964.552002,755.590027 
	C962.186707,752.387024 965.068420,751.305115 966.831665,749.396240 
	C967.904419,748.885437 968.671448,748.625244 969.724487,748.260620 
	C970.601746,748.658875 971.193054,749.161682 971.931519,749.926819 
	C972.078674,750.189209 972.133728,750.640076 972.123657,750.866943 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M922.959839,757.582642 
	C917.848450,759.080078 913.593140,756.145569 909.662048,753.845215 
	C899.427368,747.856140 888.920410,742.438232 878.283386,737.248535 
	C873.549744,734.939148 868.916199,732.353638 863.381592,731.003784 
	C862.806030,731.135315 862.609863,731.270569 862.059631,731.359863 
	C855.293274,727.689331 849.711182,722.619629 842.194946,720.228149 
	C841.619507,720.064209 841.394287,719.979492 840.851929,719.720581 
	C834.625244,714.932617 827.362366,712.728882 821.156128,708.620361 
	C811.261414,702.070007 800.376343,697.195435 790.169373,690.410706 
	C788.912781,689.017944 788.804138,687.746521 789.573242,686.040955 
	C791.542908,684.633301 793.089355,684.990967 794.738770,686.660034 
	C798.827881,693.007507 805.584229,695.001526 811.212463,698.394287 
	C821.081116,704.343079 831.378174,709.588928 841.560120,715.008301 
	C857.398560,723.438293 873.158447,731.955078 889.488708,739.512634 
	C898.428467,743.649963 908.134338,747.238647 915.778381,754.056946 
	C917.821594,755.879395 921.006165,755.057861 922.959839,757.582642 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M795.345337,687.029663 
	C793.577148,686.700928 792.194214,686.370605 790.451172,686.064514 
	C788.375916,683.974182 789.150696,681.999207 790.819580,679.788635 
	C804.462280,685.281372 817.443298,691.585083 830.130676,699.066040 
	C830.709900,699.989990 830.608398,700.515869 829.492249,700.963135 
	C828.554138,700.990417 828.092773,700.991699 827.284058,700.996155 
	C825.242737,700.976074 824.551208,698.809021 822.391846,699.024536 
	C819.558655,696.815430 816.769104,695.183655 813.229492,694.209900 
	C812.416077,693.836609 811.886475,693.670776 811.175415,693.288452 
	C808.448425,690.324463 804.770447,689.672119 801.297791,688.004944 
	C799.216370,687.673462 797.473389,687.350769 795.345337,687.029663 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M801.179932,688.255615 
	C805.156250,687.428772 808.682190,688.399292 810.953125,692.705566 
	C807.172852,692.864990 804.357849,690.540344 801.179932,688.255615 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M813.130615,694.297791 
	C816.565796,694.140137 820.000000,694.672791 822.018982,698.637939 
	C818.454895,698.881470 816.260132,695.996765 813.130615,694.297791 
z"/>
<path fill="#0C161B" opacity="1.000000" stroke="none" 
	d="
M832.264771,704.015015 
	C832.040405,704.007202 832.356812,703.647034 832.356812,703.647034 
	C832.356812,703.647034 832.789429,703.737976 832.863586,703.887756 
	C832.937744,704.037537 832.489075,704.022888 832.264771,704.015015 
z"/>
<path fill="#060D0E" opacity="1.000000" stroke="none" 
	d="
M790.994141,679.287537 
	C790.850159,681.439575 790.490601,683.354431 790.111084,685.678955 
	C790.214600,687.240356 790.338074,688.392029 790.666870,689.826538 
	C791.612976,690.527283 791.974426,691.210999 792.250977,692.383301 
	C792.330688,693.290344 792.318542,693.807678 792.212891,694.699646 
	C791.546021,696.471802 791.737671,697.988159 791.495972,699.808899 
	C791.576599,701.413147 791.554626,702.644836 791.288574,704.232666 
	C790.951050,705.772766 790.597717,706.929504 790.185059,708.441284 
	C789.282959,710.734802 788.309814,712.623962 787.870300,715.091064 
	C787.627869,715.653320 787.499817,715.866089 787.076538,716.311035 
	C786.312378,716.743164 785.823303,716.829773 784.949280,716.711914 
	C782.789185,715.610962 782.820068,713.872803 783.077759,712.246277 
	C784.678467,702.143250 778.575500,694.935730 773.359802,687.679993 
	C770.196838,683.279846 766.330994,679.453491 761.265808,676.393921 
	C756.713806,673.644348 751.547546,669.409546 751.415283,662.318848 
	C752.042053,661.389954 752.637085,660.952759 753.537537,660.320435 
	C755.835632,655.011780 753.452515,650.391907 751.829834,645.933228 
	C746.805664,632.128296 740.700623,618.719238 736.725037,604.527405 
	C736.232544,602.769409 735.742310,600.990906 733.877563,599.560669 
	C733.212708,598.610840 733.005737,597.880676 732.906982,596.729858 
	C733.856873,592.817932 730.834167,589.660095 732.075195,585.760498 
	C732.365417,585.198853 732.516174,584.992676 732.990234,584.579895 
	C738.210876,583.689331 736.645752,588.568176 738.826904,590.720215 
	C739.188904,591.309204 739.276062,591.636902 739.327393,592.218140 
	C741.971985,604.521240 748.024292,615.453796 750.876587,627.462280 
	C751.469543,629.958862 753.524597,631.964783 754.740967,634.710693 
	C755.349792,635.775024 755.700073,636.549988 756.057739,637.646729 
	C754.994995,642.190491 756.245850,645.752380 759.491821,648.928284 
	C759.965576,649.352966 760.104980,649.691406 760.255005,650.302979 
	C760.717224,654.794861 761.203430,659.010254 761.611206,663.233154 
	C762.108276,668.380981 766.397034,669.570068 770.099731,670.910461 
	C775.508606,672.868530 779.656677,677.677856 786.307678,677.631531 
	C788.322449,677.455872 789.597656,678.130615 790.994141,679.287537 
z"/>
<path fill="#0C161B" opacity="1.000000" stroke="none" 
	d="
M829.294373,701.254272 
	C829.334412,700.658447 829.653259,700.327759 830.123840,699.678528 
	C830.669006,699.469666 831.062500,699.579407 831.724365,699.842041 
	C832.399170,700.446533 832.970947,700.491760 833.708313,700.813660 
	C834.441711,701.181580 834.978699,701.348694 835.655212,701.764893 
	C833.719727,701.864075 831.590820,702.391235 829.294373,701.254272 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M833.757446,700.763184 
	C833.380859,700.827271 832.857056,700.640076 832.162964,700.223938 
	C832.531860,700.167236 833.071045,700.339539 833.757446,700.763184 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M839.005859,703.729980 
	C838.792053,703.922668 838.522339,703.800598 838.164978,703.401733 
	C838.381897,703.145996 838.672729,703.242798 839.005859,703.729980 
z"/>
<path fill="#187589" opacity="1.000000" stroke="none" 
	d="
M775.854736,671.268555 
	C775.943298,671.354370 775.635742,671.484497 775.635742,671.484497 
	C775.635742,671.484497 775.359436,671.297424 775.342773,671.130310 
	C775.326172,670.963196 775.766235,671.182800 775.854736,671.268555 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M790.944336,695.260376 
	C790.845703,694.462402 790.826660,694.025391 790.845093,693.261719 
	C794.521301,692.059814 796.887451,694.792786 799.640076,696.197632 
	C812.572144,702.797546 824.511536,711.190979 837.682129,717.373840 
	C838.581543,717.796082 839.575989,717.987366 840.732544,718.729919 
	C841.026245,718.959534 841.510437,718.981812 841.752441,718.994568 
	C848.329590,723.112549 854.756531,727.062012 861.752441,730.736877 
	C862.024353,730.965332 862.513367,730.981506 862.757812,730.990723 
	C863.450256,733.303894 865.413330,734.086060 867.181274,734.862183 
	C877.129272,739.228882 886.176697,745.360657 895.953918,749.909546 
	C902.118469,752.777649 907.373962,756.625488 912.738281,760.914185 
	C911.735046,762.155945 910.762085,762.320190 909.379639,762.303101 
	C907.541443,762.082581 906.372803,761.288513 905.018066,760.144836 
	C903.772949,758.826904 902.731628,757.790588 901.033020,757.098816 
	C900.193176,756.672668 899.666626,756.330688 898.870300,755.823730 
	C897.912476,755.175049 897.304382,754.540833 896.053162,754.422241 
	C885.865845,750.733093 877.221252,744.788818 867.991516,739.966003 
	C850.690369,730.925598 833.564880,721.557190 816.340332,712.375427 
	C809.354553,708.651428 802.520020,704.599731 795.649841,700.639954 
	C793.635193,699.478821 791.802673,697.995789 790.944336,695.260376 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M918.935120,762.573975 
	C917.971619,762.961670 916.898621,762.992676 915.423828,763.027710 
	C913.584045,763.836426 912.833069,763.296997 912.786316,761.395447 
	C912.992676,761.007324 913.019043,760.980713 913.033081,760.966125 
	C914.976990,761.356628 917.274780,760.081848 918.935120,762.573975 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M926.810486,761.730896 
	C926.631897,762.636414 926.450562,763.582947 925.125488,763.057617 
	C925.173950,761.989441 925.311462,761.030273 926.810486,761.730896 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M932.679382,759.036743 
	C932.896301,759.902588 932.594727,760.661377 931.246460,760.902954 
	C931.084167,760.064331 931.398621,759.318420 932.679382,759.036743 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M952.612732,755.973022 
	C953.431030,755.953735 953.868347,755.949585 954.633423,755.934631 
	C957.099731,754.197754 959.476013,753.424805 962.578552,754.349426 
	C964.047607,756.089111 963.357361,757.234985 961.857422,758.519653 
	C958.750122,759.623413 955.842285,759.896790 952.721558,760.924805 
	C951.876343,761.229553 951.380249,761.344177 950.501831,761.432251 
	C947.408997,761.431580 945.205933,763.404907 942.159729,763.444763 
	C940.359558,763.498352 938.769165,763.166687 937.605164,765.025574 
	C937.157959,765.342346 936.959229,765.442688 936.432861,765.619141 
	C934.457153,765.540527 933.011292,765.946533 931.610779,767.448792 
	C930.765442,768.109863 930.135376,768.452820 929.145752,768.905334 
	C928.331055,769.195068 927.847473,769.297424 926.982544,769.386475 
	C923.970032,769.914795 921.087769,768.903442 918.299561,770.854004 
	C916.666321,771.439758 915.353882,771.465942 913.637939,771.292908 
	C901.876770,770.729309 893.925476,763.637024 885.648865,756.589478 
	C885.960510,753.780701 887.700623,754.330017 889.349609,754.453369 
	C891.954285,754.648193 893.842468,756.368530 896.378113,757.570435 
	C897.540955,758.186279 898.225220,758.834717 899.182251,759.667236 
	C900.207031,760.576599 900.882385,761.408691 902.226990,761.842651 
	C902.697205,762.067566 902.881042,762.166931 903.325928,762.439697 
	C904.674683,763.589966 905.801514,764.487549 907.706055,764.461304 
	C909.212463,764.442688 910.380859,764.580872 911.929565,764.591309 
	C913.087952,764.683411 913.787598,764.961060 914.696411,765.709595 
	C919.728210,767.211060 924.295654,765.904480 928.647400,764.492310 
	C933.425232,762.941833 937.931152,760.553223 942.780579,758.249390 
	C943.441284,757.965515 943.877014,757.962097 944.639282,757.945435 
	C947.243286,756.743958 949.684204,756.166931 952.612732,755.973022 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M914.995850,765.589478 
	C914.252258,765.961853 913.506958,765.968933 912.388977,765.966309 
	C910.597290,764.718140 908.036682,764.389221 908.777649,761.305176 
	C910.066650,761.001587 911.130859,761.001953 912.593872,761.004883 
	C913.107483,761.899719 913.407043,762.655823 914.707642,762.968384 
	C915.012756,763.762573 915.003479,764.493408 914.995850,765.589478 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M963.286499,755.209961 
	C960.522339,754.592529 958.187927,755.587830 955.368286,755.938965 
	C954.970154,751.929810 960.300781,754.076904 960.971680,750.424194 
	C962.445740,749.746155 963.854675,749.415710 965.623474,749.036499 
	C966.175903,749.008179 966.368530,749.028687 966.849243,749.097412 
	C967.495972,751.666626 965.123840,752.587646 964.046997,754.643494 
	C963.832275,755.109802 963.683960,755.239197 963.286499,755.209961 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M952.726807,755.706665 
	C950.897949,757.735168 948.395264,758.061157 945.372253,757.956299 
	C946.767395,754.999268 949.405334,754.597107 952.726807,755.706665 
z"/>
<path fill="#BCDEE5" opacity="1.000000" stroke="none" 
	d="
M901.774414,652.691895 
	C902.196228,654.151123 902.449890,655.283508 902.830688,656.743713 
	C901.995300,656.894714 900.914917,656.942505 900.087097,656.508606 
	C872.235474,641.909546 844.170349,627.710388 816.835266,612.139832 
	C814.919434,611.048645 814.096191,609.570618 813.410889,607.565369 
	C804.963501,582.846558 796.665833,558.071777 787.799683,533.502686 
	C781.656982,516.480591 772.963013,500.808685 760.136169,487.723450 
	C759.350464,486.921936 758.853577,485.837341 758.046265,484.604095 
	C757.581726,483.896759 757.432556,483.425507 757.540649,482.550659 
	C758.268982,481.532349 759.006531,481.630920 760.114502,481.979370 
	C761.989746,483.042908 763.363831,484.143127 764.803223,485.749908 
	C768.214294,490.081329 770.799683,494.598755 774.920349,497.859528 
	C776.938538,499.456573 777.976318,501.759308 778.981262,504.071747 
	C781.841187,510.653046 784.876648,517.141541 789.420776,522.787109 
	C790.156677,523.701294 790.883911,524.687622 791.072632,525.842285 
	C792.862610,536.797363 798.095032,546.599487 801.677185,556.938477 
	C807.070190,572.504211 811.985901,588.216003 817.145752,603.851807 
	C817.792786,605.812622 818.683167,607.381958 820.563293,608.529785 
	C832.640747,615.902832 844.898254,623.142273 857.704285,628.957275 
	C869.281067,634.213989 879.093872,642.445312 890.995239,646.932678 
	C893.638306,647.929321 896.238037,649.038696 899.286377,650.014832 
	C900.392151,650.811279 900.844055,651.729431 901.774414,652.691895 
z"/>
<path fill="#141D31" opacity="1.000000" stroke="none" 
	d="
M696.834961,455.448364 
	C697.339294,454.739502 697.785583,454.392761 698.503174,453.964478 
	C699.270081,453.916931 699.765747,453.950897 700.632812,453.991211 
	C707.031677,452.152374 712.436523,454.862732 717.907898,456.511261 
	C729.158691,459.901062 738.991516,466.071503 748.400818,472.928558 
	C750.815552,474.688293 753.361267,476.236847 756.068970,478.256226 
	C756.503174,478.760712 756.648499,478.988068 756.928467,479.600159 
	C757.213989,481.097717 757.020020,482.152893 756.265503,483.469604 
	C750.779358,480.313324 745.406128,477.054657 740.373108,473.335632 
	C730.287292,465.882904 719.039246,461.283752 706.431152,458.843506 
	C704.347656,458.416504 702.661255,457.976135 700.595459,457.525055 
	C698.500122,458.096100 697.366943,457.501556 696.834961,455.448364 
z"/>
<path fill="#B6B4B9" opacity="1.000000" stroke="none" 
	d="
M756.324097,483.818909 
	C755.794556,482.733673 755.590393,481.687042 755.590698,480.279999 
	C756.490295,479.467743 757.125549,479.666321 757.964783,480.372711 
	C758.246643,480.876099 758.302917,481.105713 758.174377,481.638062 
	C757.975952,482.470093 757.962463,482.999451 757.909546,483.926270 
	C757.463257,484.168335 757.056396,484.012970 756.324097,483.818909 
z"/>
<path fill="#0596B4" opacity="1.000000" stroke="none" 
	d="
M689.032898,507.703674 
	C683.947754,508.185699 680.538940,503.781097 675.612244,503.369110 
	C671.234741,503.003052 667.124451,500.670166 662.268921,501.646362 
	C660.643433,503.374512 659.040894,503.361298 656.966187,502.518127 
	C655.257812,500.384064 652.820435,500.643951 650.770630,499.266968 
	C649.711365,497.531738 649.893372,496.056763 650.489441,494.647522 
	C654.325012,485.579803 654.908386,475.722198 657.354431,466.315491 
	C657.985046,463.889954 658.974915,462.043823 661.113586,460.780762 
	C663.273254,459.505280 665.217041,457.976837 666.870972,455.504761 
	C673.469482,453.846130 679.732544,454.981171 685.885498,455.707764 
	C690.044312,456.198914 694.535400,455.957550 698.672119,458.658936 
	C699.100159,461.176880 699.091125,463.265320 697.231995,464.915649 
	C696.668762,465.415588 696.154907,466.406097 696.221802,467.114990 
	C697.069336,476.090576 691.274597,484.207764 692.933289,493.313690 
	C693.398193,495.866180 693.253967,498.338562 690.292969,500.099640 
	C687.606750,501.697266 691.139526,505.140076 689.032898,507.703674 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M699.238647,458.808990 
	C688.602661,458.010284 678.232117,457.019562 667.421692,456.047974 
	C664.954529,458.001801 662.375061,457.651733 659.902100,457.815857 
	C650.409607,458.445953 641.024597,459.694305 631.574280,462.990540 
	C624.111694,464.608490 616.950928,465.490082 609.917358,467.081024 
	C607.863037,467.545715 605.654480,467.848297 604.007324,465.439941 
	C606.477539,464.130157 608.948059,463.259186 611.636719,462.111664 
	C612.175476,461.494934 612.535706,461.204712 613.267090,460.852722 
	C614.472839,460.540558 615.400940,460.574951 616.559448,460.078827 
	C617.309998,459.859283 617.745300,459.761353 618.513794,459.632874 
	C620.024475,459.568481 620.914917,458.868591 622.178467,458.149963 
	C623.637390,457.559967 624.830078,457.495941 626.398804,457.516724 
	C628.773315,457.545013 630.560547,456.733429 632.725220,455.885986 
	C636.250977,454.913300 639.185120,453.042358 643.038696,453.512238 
	C644.398560,453.513855 645.381714,453.499817 646.657959,452.974426 
	C647.979248,452.647095 648.960144,452.530090 650.326050,452.480957 
	C651.473511,452.402771 652.186401,452.111267 653.305176,451.776367 
	C654.782349,451.383240 655.892639,451.484039 657.425415,451.435974 
	C666.691284,451.455505 675.532837,450.524719 684.772217,451.539246 
	C686.441406,451.727173 687.515564,452.480743 689.110474,452.749451 
	C690.165649,452.901520 690.827820,453.117859 691.628723,453.809204 
	C693.049561,455.361176 694.639343,455.032593 696.512695,455.096802 
	C697.507263,456.219269 698.415588,456.960266 699.960022,457.354431 
	C699.978760,457.881897 699.741516,458.249451 699.238647,458.808990 
z"/>
<path fill="#0596B4" opacity="1.000000" stroke="none" 
	d="
M696.722778,506.770996 
	C694.752991,508.707825 694.162292,507.134705 693.541626,505.796021 
	C693.227600,505.118866 693.481567,504.432068 694.218628,504.304413 
	C695.826721,504.025940 696.571472,504.966309 696.722778,506.770996 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M656.648682,500.851227 
	C658.262878,502.077820 659.804260,501.694794 661.610229,501.906372 
	C665.564575,503.773407 669.655090,503.812286 673.410278,505.071564 
	C692.377502,511.432190 708.668091,521.338196 718.915466,539.659790 
	C717.552917,541.190002 716.440735,540.317688 715.090759,539.314697 
	C714.478271,538.688660 714.152466,538.222778 713.574219,537.663513 
	C713.386230,537.515137 712.925659,537.410583 712.690552,537.458313 
	C711.998230,537.764526 711.550415,538.039856 710.779053,538.314331 
	C709.367615,538.208313 708.567627,537.450378 707.386719,536.683289 
	C706.378601,535.842896 705.760681,535.111938 705.021606,533.996216 
	C701.119995,527.838501 695.650879,523.614685 690.159607,519.916870 
	C683.083679,515.152039 675.019165,511.991058 666.881165,509.106842 
	C657.158630,505.660919 647.468018,505.649384 637.224060,507.802399 
	C630.590698,508.836639 624.276001,509.505646 617.829834,511.524231 
	C616.553833,512.179565 615.551880,512.397705 614.157227,512.528442 
	C611.693909,512.749451 609.615540,513.008057 607.819580,514.948364 
	C600.521118,518.360291 593.405212,521.014771 585.732178,522.643677 
	C579.016724,524.069275 571.933472,523.881287 565.530029,527.149170 
	C556.312744,531.853027 555.001831,535.296448 559.937134,545.194031 
	C567.158264,559.736511 571.553833,574.936523 576.450317,589.932129 
	C579.914551,600.541565 585.071106,610.509460 588.013306,621.289551 
	C592.392944,637.336182 598.188049,652.940369 602.795776,668.917419 
	C604.543518,674.977600 604.783630,674.934937 610.749390,673.365662 
	C623.768372,669.940979 637.242065,668.351685 649.942688,663.632996 
	C651.639771,663.002441 653.243103,663.176392 654.320862,665.385010 
	C654.128052,667.390015 653.064392,668.520142 651.665466,669.004150 
	C646.883057,670.658508 642.579285,673.520569 637.177246,674.559204 
	C636.039490,674.546204 635.309082,674.666992 634.259277,675.080200 
	C632.245056,675.596436 630.580994,675.991821 628.551514,676.430115 
	C624.181763,677.663879 620.156738,678.777954 615.754639,679.902344 
	C614.945923,680.198608 614.470398,680.321289 613.621460,680.438477 
	C611.381531,680.922058 609.484680,681.293823 607.314697,682.083191 
	C605.263367,682.750305 603.545593,683.201538 601.347595,683.052002 
	C599.127441,682.568970 597.535522,681.734558 596.149963,679.899597 
	C594.436096,674.882874 593.552185,669.932617 589.303345,666.543823 
	C587.651917,665.226624 587.875977,662.932739 587.893738,660.918335 
	C587.926270,657.234131 586.415405,654.331543 583.941284,651.565491 
	C579.254700,646.325989 574.220642,642.298218 567.464966,639.356873 
	C561.980896,636.969116 556.361877,633.725647 551.003906,630.567932 
	C543.151123,625.939941 535.955688,620.311462 527.931152,616.021851 
	C523.721680,613.771729 522.130554,614.375427 519.436951,619.000427 
	C516.597961,620.147522 516.009216,618.238220 515.489746,616.488281 
	C514.457092,613.009521 514.223999,609.359924 513.290039,605.843262 
	C512.597107,603.234131 514.397156,601.983337 516.537048,601.058838 
	C518.661133,600.141174 521.127991,600.524536 523.417358,599.010864 
	C524.098022,598.512817 524.529968,598.304626 525.347046,598.095825 
	C527.183228,597.985779 528.492065,598.539917 530.075195,599.361572 
	C530.765442,599.765320 531.148499,600.008850 531.813538,600.431702 
	C533.043762,601.193054 533.889160,601.899109 534.842163,602.975403 
	C535.549500,603.948364 536.160889,604.500183 537.417725,604.646179 
	C546.454102,608.852966 553.997925,614.882690 562.236877,619.784790 
	C568.345398,623.419373 574.191589,627.489624 580.871216,630.739197 
	C578.824402,625.643921 577.906189,620.193420 575.046814,615.015747 
	C572.480957,605.413513 569.098511,596.458984 565.856812,587.477051 
	C563.398315,580.665039 561.879272,573.494873 558.188354,566.728027 
	C556.839172,561.666565 554.158691,557.553528 552.598145,552.598022 
	C552.257935,548.933167 551.022827,545.915710 549.754944,542.517334 
	C549.278381,540.706665 548.740601,539.307495 548.314758,537.874268 
	C547.294434,534.440125 545.214661,533.130310 541.551086,533.835693 
	C534.367310,535.218689 527.061096,535.871521 519.480042,537.266968 
	C517.186218,537.540161 515.365723,537.291016 513.380432,536.022339 
	C512.695007,535.377075 512.447449,534.915649 512.309937,533.997314 
	C512.377319,533.092834 512.568176,532.622559 513.126099,531.926758 
	C513.582275,531.516479 513.783569,531.377625 514.313354,531.086304 
	C515.518311,530.604492 516.489319,530.603943 517.657471,530.029785 
	C518.206299,529.827881 518.430420,529.756226 519.000244,529.606079 
	C521.170532,528.872070 523.034363,528.337219 525.253235,527.669250 
	C536.212830,525.005554 546.903992,522.844360 557.358398,519.733032 
	C560.841248,518.696472 564.512512,518.318787 568.226868,516.671387 
	C572.776306,516.465332 576.629150,514.747498 581.074951,514.111694 
	C581.906616,513.799500 582.396667,513.682373 583.270203,513.581055 
	C585.553772,513.110779 587.474243,512.707520 589.680298,511.901794 
	C591.018494,511.403076 592.026672,511.124969 593.436401,510.755127 
	C600.962585,510.107666 607.645203,507.561127 614.548279,506.013733 
	C625.638367,503.527740 636.686768,500.834442 648.024658,499.791260 
	C650.746460,499.540833 653.629883,499.639008 656.648682,500.851227 
z"/>
<path fill="#060D0E" opacity="1.000000" stroke="none" 
	d="
M714.756287,538.208618 
	C716.112915,538.616455 717.207397,539.278809 718.620728,539.975647 
	C719.063904,540.322754 719.188232,540.635498 719.304077,541.202026 
	C719.341431,542.397339 720.052856,542.930420 720.835571,543.775269 
	C721.078979,543.981323 721.223389,544.264526 721.217407,544.423340 
	C725.088928,554.240784 728.274109,564.161194 732.442566,573.718506 
	C733.868591,576.988220 734.438416,580.695923 733.246155,584.684692 
	C733.004883,584.995117 732.991638,585.006042 732.984375,585.011108 
	C730.491638,585.429932 729.802917,583.502625 729.235046,581.843933 
	C727.254456,576.058899 724.387756,570.614197 723.071228,564.561035 
	C721.647949,558.017090 717.810181,552.422913 715.128906,545.963684 
	C715.500488,543.170105 713.390747,540.939148 714.756287,538.208618 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M733.375488,585.006714 
	C733.213745,574.160095 727.114746,565.249390 724.004089,555.426331 
	C722.957947,552.122742 722.104919,548.758057 721.187561,545.001709 
	C722.191223,545.009216 722.699219,545.795227 723.086548,546.771973 
	C728.775940,561.118896 734.319641,575.518982 739.097412,590.592407 
	C735.452148,590.668152 737.580933,585.165161 733.375488,585.006714 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M755.035645,634.621094 
	C751.063416,633.869446 749.673157,629.929688 749.333313,627.129822 
	C748.295166,618.577820 744.004150,611.275452 741.363892,603.379211 
	C740.247009,600.038879 739.964783,596.677734 739.217773,592.917969 
	C742.808716,596.509705 742.825745,601.930420 744.817993,606.588074 
	C748.123901,614.316956 750.929688,622.259888 754.217224,630.334839 
	C754.682800,631.785400 754.877258,633.013733 755.035645,634.621094 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M721.053101,543.651001 
	C720.013916,543.849365 719.224060,543.426758 719.245911,541.827515 
	C720.153015,541.817261 720.796326,542.377502 721.053101,543.651001 
z"/>
<path fill="#1E283E" opacity="1.000000" stroke="none" 
	d="
M755.834473,479.027283 
	C750.343506,477.642303 746.791138,473.690216 742.616943,470.856171 
	C733.294250,464.526550 723.511597,459.626465 712.568970,456.647888 
	C708.897461,455.648529 705.217468,455.395447 701.301636,454.264801 
	C702.010315,449.201843 705.539368,445.824310 708.079651,441.956940 
	C712.699219,434.924133 716.762512,427.630676 720.156738,419.963287 
	C721.991455,415.818909 725.712341,415.866760 728.979309,416.258636 
	C732.319153,416.659210 730.783936,419.951508 730.888428,422.033447 
	C730.990234,424.061859 729.451538,424.772308 727.622253,425.092529 
	C725.642944,425.438995 724.489258,426.858307 725.327332,428.684082 
	C726.439026,431.106018 728.291382,429.439941 729.473206,428.636719 
	C732.937073,426.282654 736.132568,424.309631 737.568970,419.324463 
	C738.818787,414.986877 741.478271,409.116211 748.524170,409.829376 
	C749.937561,409.972443 751.588928,409.577332 752.869568,408.921051 
	C760.389099,405.067474 766.602722,408.745544 772.923584,412.129303 
	C775.033630,413.258850 775.757446,414.900085 773.786682,416.370239 
	C768.941528,419.984680 771.525757,424.320160 772.662231,428.171051 
	C773.757996,431.883911 777.443604,434.612335 774.185486,439.441681 
	C772.639099,441.733856 775.783569,439.446198 776.861816,440.071167 
	C777.933167,444.260651 774.789368,446.983307 773.098450,450.155060 
	C770.769714,454.523346 767.871277,458.586395 766.291992,463.741608 
	C766.110046,464.330383 766.014343,464.558563 765.723389,465.102234 
	C762.549377,467.920624 761.071045,471.415131 759.304199,474.733185 
	C758.471558,476.296814 757.903137,478.095978 755.834473,479.027283 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M764.909363,464.141235 
	C767.725159,455.624054 773.230469,448.639404 777.129639,440.412720 
	C778.756409,437.603119 780.328552,435.145050 782.105103,432.407257 
	C783.473755,430.983490 783.951782,429.302094 785.632080,428.168091 
	C793.113525,427.626251 800.099487,428.615631 806.055115,432.671051 
	C811.088074,436.098267 816.010315,439.600342 821.970947,442.101990 
	C822.967529,442.393951 823.628845,442.591370 824.568909,443.011597 
	C825.648132,443.563141 826.209839,444.273834 827.296875,444.782043 
	C828.567993,445.357361 829.593140,445.686920 830.870789,446.271545 
	C832.523010,447.509430 833.259338,449.353455 835.510742,449.861420 
	C836.023315,450.108124 836.222778,450.219452 836.702881,450.527618 
	C841.917114,454.391876 846.837769,458.077728 852.256226,461.634949 
	C853.108521,462.463287 853.467712,463.305481 854.568726,463.868896 
	C855.032227,464.165863 855.213867,464.294342 855.649231,464.645386 
	C856.768921,465.923157 857.859009,466.883057 857.217896,468.748291 
	C852.490540,468.381531 848.352112,466.789093 844.643494,464.159180 
	C838.106628,459.523682 830.429077,456.576782 824.809021,450.557770 
	C823.233582,448.870575 820.717163,448.234955 818.683655,447.428528 
	C810.981689,444.374268 805.135620,438.001770 796.838623,435.977722 
	C789.287354,434.135651 785.067932,435.075958 780.317688,441.227997 
	C778.808655,443.182312 777.496765,445.288849 776.064575,447.674561 
	C776.449829,448.703308 776.264160,449.371857 775.830933,450.348206 
	C774.172119,452.381104 772.626282,454.075195 772.341064,456.835022 
	C771.951965,458.763062 770.787659,459.774750 769.489868,461.076721 
	C768.762634,461.980377 768.329773,462.699951 767.762451,463.721680 
	C767.403320,464.131317 767.234253,464.273590 766.750977,464.527649 
	C765.982117,464.680969 765.557312,464.583344 764.909363,464.141235 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M792.749878,412.995605 
	C794.374756,416.034180 797.778809,416.484833 800.307129,418.126282 
	C816.573853,428.687408 833.834656,437.542694 850.789307,446.880615 
	C860.001404,451.954285 869.253906,457.008118 878.542847,461.968750 
	C879.616089,462.541870 880.606873,462.991974 880.986389,464.603943 
	C881.048950,464.950287 881.004822,464.995270 880.982788,465.017822 
	C877.330078,467.363525 874.467346,464.445892 871.779480,463.160889 
	C859.536194,457.307617 847.575745,450.883118 836.436768,443.054596 
	C835.891357,442.671326 836.638367,443.010529 836.029846,442.773041 
	C828.635193,439.887238 821.819946,435.820343 814.336365,432.281067 
	C812.710388,431.817291 811.689087,431.054749 810.449219,429.940613 
	C806.362549,425.596619 801.707092,422.774261 796.637878,420.545502 
	C794.256042,419.498291 791.429199,418.819427 791.733398,415.007233 
	C792.131653,414.186371 792.327271,413.675964 792.749878,412.995605 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M810.733887,428.731018 
	C811.823975,429.571106 812.630859,430.161987 813.702881,430.895721 
	C822.538208,436.594330 832.211609,440.152771 840.724854,445.794403 
	C842.572205,447.018616 844.989075,447.904205 845.128967,451.006104 
	C844.141785,452.087799 843.148804,452.300690 841.697021,452.254303 
	C841.065186,452.156219 840.818848,452.091583 840.228882,451.869720 
	C833.717163,447.263092 827.112549,443.578644 819.810913,440.445740 
	C816.887695,440.489166 815.215759,438.679169 813.367676,437.399109 
	C805.215881,431.752777 796.831482,426.693146 786.407349,425.280334 
	C785.804810,423.249207 787.081482,421.951843 787.819336,420.222290 
	C789.549072,418.216736 791.168823,417.943146 793.326721,418.955353 
	C799.259888,421.738342 805.113220,424.639160 810.733887,428.731018 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M786.061768,425.318420 
	C795.606995,423.508453 802.631287,428.941498 809.983459,433.467987 
	C813.045105,435.352905 815.685913,437.945374 819.610474,439.011963 
	C821.321350,440.160492 823.423584,440.851746 822.258545,443.642181 
	C815.668152,440.672546 809.142456,437.709778 803.799011,432.683533 
	C802.187683,431.167786 799.552612,431.145020 797.249146,430.822327 
	C793.705078,430.325836 790.197021,429.572449 786.330811,428.958710 
	C785.597229,428.573273 785.436890,428.081970 785.672302,427.146698 
	C785.893311,426.388000 785.948853,425.998108 786.061768,425.318420 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M810.952332,428.646606 
	C805.084717,426.219452 799.063965,423.627319 793.276855,420.589874 
	C791.649658,419.735779 790.445496,419.136963 788.453491,419.997803 
	C788.418701,417.878296 789.063354,415.924835 791.602905,414.815887 
	C792.502869,417.574402 794.913452,418.276703 797.177307,419.209961 
	C802.341797,421.338959 806.952393,424.322540 810.952332,428.646606 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M785.508179,426.866943 
	C785.874268,427.269226 785.910767,427.760529 785.967590,428.620605 
	C784.786072,429.823303 784.903259,431.963593 782.630615,432.091248 
	C782.295227,429.865234 783.323608,428.181366 785.508179,426.866943 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M692.049072,453.788696 
	C691.155518,454.059418 690.464783,454.039886 689.427490,453.986572 
	C688.907349,453.802734 688.733765,453.652710 688.428650,453.185242 
	C687.836731,450.226410 690.772522,449.930634 691.690430,447.849030 
	C692.682007,446.063812 693.975586,444.898926 695.024597,443.163086 
	C695.777527,442.050812 696.570496,441.325470 696.835938,439.930481 
	C697.042786,439.433075 697.139465,439.238617 697.418701,438.771637 
	C698.481018,437.571075 699.701294,436.854828 699.631042,434.975800 
	C699.773682,434.205231 699.907654,433.778381 700.234619,433.064850 
	C702.335510,430.649170 703.842468,428.279266 704.671265,425.149780 
	C704.855164,424.632996 704.945862,424.432800 705.207275,423.951660 
	C706.318481,422.510132 707.439575,421.451477 707.583191,419.486328 
	C707.809631,418.228302 708.185181,417.390076 709.008545,416.387787 
	C713.935669,412.271149 715.924194,406.698822 718.776855,401.267731 
	C719.018250,401.013123 719.060547,400.555267 719.075195,400.326752 
	C720.138000,399.752533 721.186218,399.406830 722.565308,399.015442 
	C723.090332,398.799255 723.284424,398.628784 723.758057,398.726471 
	C719.600647,410.013153 712.246643,419.504730 707.449036,430.275848 
	C704.684937,436.481506 700.877869,441.992432 697.732117,447.918152 
	C696.560181,450.125854 694.116028,451.658142 692.049072,453.788696 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M728.706482,387.225098 
	C728.816833,384.295654 730.965515,383.011230 732.668701,381.129578 
	C733.085388,385.761597 730.235352,389.253906 727.169800,392.782654 
	C725.505920,390.303406 727.268982,389.007568 728.706482,387.225098 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M724.251099,398.734131 
	C723.847778,398.992859 723.657837,398.991028 723.182129,398.979492 
	C722.956177,397.299103 723.366882,395.749329 724.723145,394.213318 
	C725.390320,393.502594 725.737183,392.950500 726.680298,392.856628 
	C726.975525,392.980988 727.046570,393.044708 727.083496,393.078156 
	C727.040283,395.297791 726.268738,397.141449 724.251099,398.734131 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M912.999146,492.630188 
	C912.502930,492.743164 912.008362,492.485046 911.259338,492.112000 
	C911.006470,491.242004 911.007996,490.487000 911.018005,489.353882 
	C913.023010,489.265411 912.997925,490.770630 912.999146,492.630188 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M845.054810,451.360779 
	C839.954651,445.187347 832.659851,442.830292 826.301514,439.119202 
	C822.191772,436.720581 817.859131,434.719269 814.044617,431.371826 
	C815.633911,430.978638 817.129822,431.209869 818.691406,432.094452 
	C831.962158,439.611694 845.453979,446.751556 858.557800,454.545563 
	C865.511780,458.681732 872.420532,462.679230 880.580566,465.020569 
	C881.165100,465.596222 881.369385,466.152069 881.800110,466.910950 
	C877.355652,468.869110 874.237000,464.947296 870.380859,463.787933 
	C866.692383,462.678986 863.282776,460.642700 859.028320,459.003296 
	C854.296997,457.294464 850.846313,454.487396 846.354736,452.959808 
	C845.667603,452.489929 845.362793,452.130646 845.054810,451.360779 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M844.579895,451.009766 
	C845.289001,451.136139 845.544983,451.330719 845.870728,451.804840 
	C850.169617,454.141235 854.200317,456.543427 858.709106,458.791687 
	C858.995117,459.001221 859.000000,459.000000 858.999512,459.003052 
	C860.368408,462.972595 864.329285,463.380249 867.375366,464.903687 
	C872.609314,467.521332 878.351929,469.185455 882.835815,473.731262 
	C882.821045,476.551086 881.101440,475.566711 879.421021,475.375946 
	C878.858093,475.211548 878.641418,475.127380 878.128357,474.870056 
	C876.637939,473.452515 875.458313,472.182739 873.204590,472.182892 
	C872.692200,472.025299 872.492493,471.947174 872.013916,471.710876 
	C870.693542,470.625641 869.880493,469.199310 867.767822,469.907196 
	C866.978516,469.902710 866.529724,469.825378 865.758545,469.570099 
	C857.717834,465.274445 850.863953,459.751007 843.229736,455.457916 
	C841.861694,454.688629 840.284851,453.710754 840.761108,451.331604 
	C842.040405,451.024139 843.109863,451.016479 844.579895,451.009766 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M894.641968,479.964783 
	C894.619629,481.883972 896.187134,482.905701 896.939270,484.653320 
	C895.901245,486.113739 894.651428,485.652222 893.054993,485.188934 
	C892.245728,484.811554 891.833252,484.516510 891.202637,483.893158 
	C889.680664,481.873322 888.085938,480.477417 886.362793,479.200165 
	C884.833435,478.066589 883.676147,476.732635 884.740295,474.328125 
	C888.630066,475.116058 891.085510,478.117493 894.641968,479.964783 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M884.636353,474.032013 
	C884.838562,478.820984 890.329712,478.937531 891.922974,482.676758 
	C887.278076,483.840973 880.334167,479.525970 878.771729,474.338684 
	C880.229553,473.837952 881.279480,475.127472 882.786499,474.248840 
	C883.433167,474.030853 883.870789,474.029449 884.636353,474.032013 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M892.752136,484.354431 
	C894.095215,484.321594 895.205078,484.619415 896.659912,484.954773 
	C897.493591,485.255920 897.982300,485.519470 898.724121,485.896545 
	C898.985657,487.393890 898.994141,488.777710 899.006042,490.576538 
	C899.009460,490.991547 898.986206,491.012756 898.974792,491.023254 
	C894.366882,491.402832 893.232361,488.257660 892.752136,484.354431 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M923.050354,613.662964 
	C923.042175,614.492249 923.023193,614.917297 922.983032,615.661011 
	C919.941345,617.223816 920.016357,620.945251 917.305176,622.841309 
	C915.209167,621.401733 915.880005,619.502441 916.420166,617.220032 
	C916.457458,615.989807 916.633728,615.199036 917.060120,614.057739 
	C921.099365,610.006104 920.982849,604.824463 921.187378,600.296204 
	C921.521729,592.893188 923.335083,585.048157 918.277405,578.229126 
	C917.093811,576.633362 917.461426,574.354614 915.968628,572.489380 
	C915.448242,572.159363 915.245239,572.022644 914.786194,571.628540 
	C912.989136,569.283020 913.291382,566.726624 912.833313,564.380432 
	C912.208679,561.181152 910.999390,558.529541 907.527100,556.969666 
	C906.865417,556.454590 906.547485,556.095581 906.102051,555.376343 
	C905.343567,552.962219 905.033020,550.852417 905.041748,548.655701 
	C905.064514,542.961060 902.705322,538.068298 899.339600,533.219238 
	C898.882019,532.799744 898.713562,532.613586 898.344360,532.104858 
	C897.106140,526.570740 895.233826,521.560547 894.387024,516.304993 
	C893.752808,512.368896 891.543213,509.274261 889.230469,506.191833 
	C887.734985,504.198700 886.307373,502.128693 885.627808,499.285278 
	C885.821533,495.972351 883.354980,495.370850 881.223511,494.059509 
	C880.242310,493.357117 879.647766,492.715363 878.759155,491.921478 
	C877.889954,491.151184 877.342041,490.482788 876.198975,490.077942 
	C875.709717,489.825378 875.516357,489.715759 875.043945,489.417542 
	C870.291138,487.654602 867.304688,483.388763 862.131287,482.430969 
	C861.052002,482.411621 860.354309,482.251984 859.334106,481.872925 
	C858.118774,481.238800 857.509155,480.463989 856.690674,479.400513 
	C856.402710,478.593414 856.406189,478.112061 856.769592,477.346069 
	C857.850708,475.982849 859.018616,475.730347 860.111694,476.383575 
	C865.081177,479.353271 870.365356,481.525208 875.698425,483.786285 
	C885.816101,488.075867 892.724304,495.901154 896.039734,506.769012 
	C898.778137,515.745361 902.676086,524.309753 907.109497,532.582825 
	C908.167542,534.557251 908.132629,536.675598 908.983643,538.690247 
	C913.790649,550.069763 917.679260,561.866272 922.960022,573.009216 
	C929.424744,586.650452 926.428162,599.845703 923.050354,613.662964 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M774.782532,450.263672 
	C775.255249,449.504150 775.526611,449.016571 775.916260,448.276184 
	C780.031738,447.096222 782.259094,443.588684 785.169434,441.249939 
	C789.259888,437.962799 793.277527,438.675201 796.855652,440.494263 
	C802.704285,443.467590 808.746948,446.352509 814.201904,449.745087 
	C819.513062,453.048279 824.943787,456.596771 830.332825,459.718567 
	C836.563721,463.328064 842.634338,467.320404 849.103149,470.555725 
	C851.089722,471.549286 852.924744,472.666748 853.010925,475.615723 
	C849.777283,478.515442 846.721436,476.677612 843.850342,475.321442 
	C840.579224,473.776306 837.392456,472.087341 833.512146,471.026978 
	C824.679199,463.320160 814.269043,458.692261 804.945435,452.407440 
	C801.039124,449.774323 795.732727,447.868988 791.426880,448.404510 
	C786.181335,449.056915 780.635132,451.623718 777.208374,456.548096 
	C775.703491,458.710785 774.191528,460.893585 773.273804,463.435760 
	C772.366943,465.947571 770.588806,467.577911 767.417053,467.778015 
	C765.316223,466.957001 765.625671,465.839203 766.762573,464.334778 
	C767.025635,464.046936 767.011841,464.013611 767.029236,464.018341 
	C767.733154,463.382263 767.986511,462.560852 768.068359,461.325684 
	C767.967957,459.015747 770.476257,459.193115 770.918823,457.304138 
	C772.316040,454.905334 773.299866,452.637360 774.782532,450.263672 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M832.730652,470.260254 
	C836.844055,469.444366 840.029419,471.237915 843.048584,473.121460 
	C845.908691,474.905762 848.953979,475.680878 852.615845,475.988190 
	C854.115112,476.328400 855.207275,476.672699 856.647339,477.037842 
	C856.992554,477.490692 856.990051,477.922760 856.990112,478.679443 
	C857.540894,479.522491 858.062561,480.067810 858.803467,480.793579 
	C859.750305,480.997833 860.498108,481.001007 861.619873,481.005157 
	C866.818787,482.912506 870.004761,487.723999 875.710327,488.791443 
	C875.999268,489.001221 876.000000,489.000000 875.998779,489.000610 
	C876.643250,489.871338 877.769348,489.951782 878.786499,490.781372 
	C879.887085,491.105682 880.643127,491.391785 880.949463,492.684326 
	C882.441284,495.005402 885.041565,495.598297 886.763062,497.773529 
	C886.929504,501.684845 889.509277,504.200562 891.550049,506.573914 
	C894.187073,509.640625 895.653198,512.935547 896.093994,516.753418 
	C896.657837,521.638367 899.518372,526.041260 899.008179,531.560913 
	C897.153320,533.156677 896.182739,531.801453 895.413025,530.543823 
	C893.677002,527.707520 891.283936,524.844727 891.092468,521.630798 
	C890.618652,513.677002 884.607727,508.693573 881.460815,502.216156 
	C879.652039,498.493073 875.179382,496.837372 871.870117,494.455139 
	C866.964966,490.924133 861.856812,487.591858 856.062927,485.223724 
	C849.491577,482.537903 843.148193,479.287415 836.419312,476.872284 
	C833.955139,475.987885 831.026306,474.341461 832.730652,470.260254 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M892.778748,483.897125 
	C893.723694,487.116150 896.245239,488.824829 898.637207,490.960541 
	C899.310242,493.743805 899.657043,496.453857 900.006836,499.575226 
	C899.844421,500.166748 899.679016,500.346924 899.249756,500.272186 
	C898.985962,500.017273 899.000000,500.001221 898.991943,500.006836 
	C898.466248,499.465027 897.933594,498.932190 897.209595,498.191376 
	C897.010742,497.990662 896.999573,497.502106 896.992432,497.257843 
	C896.145264,496.139465 895.284851,495.285431 894.215942,494.211365 
	C894.000000,493.998779 893.983154,493.510406 893.977417,493.266113 
	C891.284302,488.961304 888.302734,485.223236 883.246826,483.247437 
	C882.988525,483.018219 883.000000,483.001221 882.993042,483.006744 
	C880.267151,481.461121 877.240906,480.327972 875.227661,477.251648 
	C874.546570,477.008514 874.107971,477.000885 873.340332,476.987244 
	C870.953796,473.237030 865.830261,473.888336 863.595032,470.030701 
	C863.933044,468.858185 864.539490,468.511414 865.711670,468.758972 
	C866.472534,468.964905 866.908325,468.967987 867.671021,468.973511 
	C869.348267,469.110565 870.512146,469.669098 871.779419,470.759796 
	C872.018188,470.984344 872.507385,470.990295 872.751892,470.997589 
	C874.221924,472.361725 876.481201,471.860809 877.771240,473.761169 
	C878.009216,473.993011 878.501648,473.998779 878.747681,474.004272 
	C882.255981,478.124695 886.388123,481.040070 891.626709,482.964539 
	C892.116333,483.312897 892.313965,483.565399 892.778748,483.897125 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M865.949219,468.621277 
	C865.481262,469.145996 864.925781,469.330170 864.180298,469.741699 
	C863.041016,470.974030 861.930359,471.050934 860.344482,470.291565 
	C859.492371,469.738800 859.013000,469.432556 858.241333,469.087830 
	C857.882019,467.340210 857.198914,466.053955 855.246216,465.235626 
	C855.000000,465.001221 854.504883,464.995453 854.257324,464.994019 
	C853.475708,464.459625 852.934937,463.933411 852.201050,463.200256 
	C846.416870,459.640320 841.219543,455.739014 836.054688,451.357635 
	C836.938110,449.998108 838.052734,449.942383 839.634888,450.736237 
	C840.004211,450.992676 840.487976,451.010742 840.729492,451.021271 
	C843.336975,455.343353 847.884277,456.908783 851.778748,459.020844 
	C856.767822,461.726624 860.673706,465.965668 865.949219,468.621277 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M860.033203,470.403259 
	C861.043884,470.021973 862.115967,469.998901 863.589233,469.972473 
	C866.864075,472.215790 871.609436,471.819092 872.961426,476.633423 
	C869.826233,475.962860 867.007507,473.937561 863.465393,473.841614 
	C861.724976,473.794434 860.647644,472.419830 860.033203,470.403259 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M883.055481,483.352417 
	C889.021423,483.299011 891.699585,487.556244 893.974365,492.655182 
	C890.355347,489.910095 886.738892,486.798370 883.055481,483.352417 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M926.746948,569.250488 
	C929.669373,571.869080 930.781311,575.362610 930.979126,579.620850 
	C925.985229,577.999634 927.438416,573.250244 926.746948,569.250488 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M874.997681,477.386597 
	C878.149780,477.960297 880.410583,480.194305 882.835083,482.711304 
	C879.196045,482.977661 876.100647,481.888824 874.997681,477.386597 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M898.987427,500.413452 
	C899.156616,500.012115 899.327209,500.006927 899.753784,499.994171 
	C900.134460,500.293396 900.327698,500.548218 900.799683,500.871277 
	C901.640503,502.766388 902.271179,504.541229 902.934326,506.670959 
	C899.374390,506.404022 898.829224,503.832336 898.987427,500.413452 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M855.059021,465.324921 
	C857.105469,465.177551 859.500916,465.080688 858.091492,468.741669 
	C857.949036,469.049286 857.472046,469.039490 857.233887,469.025879 
	C856.369873,467.891022 855.743958,466.769806 855.059021,465.324921 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M894.010986,494.364441 
	C895.596191,493.969391 896.961670,494.240692 896.978149,496.646271 
	C895.396484,497.032440 894.037964,496.752380 894.010986,494.364441 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M902.758179,510.212036 
	C903.976990,510.606506 905.184265,511.092865 904.195557,512.825195 
	C902.423035,512.743347 901.670654,512.033813 902.758179,510.212036 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M930.770508,587.194519 
	C931.954407,587.734436 932.158508,588.596558 931.213989,589.808594 
	C930.044189,589.264343 929.836792,588.403320 930.770508,587.194519 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M926.806580,609.175537 
	C927.821350,609.582703 927.981384,610.423218 927.988342,611.658997 
	C927.816772,611.988403 927.643250,611.986511 927.208984,611.973511 
	C926.694702,611.109009 925.883240,610.332947 926.806580,609.175537 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M897.058838,498.304382 
	C897.898682,498.102142 898.650696,498.396912 898.936584,499.698181 
	C898.098877,499.896881 897.346313,499.602905 897.058838,498.304382 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M910.035645,642.634155 
	C909.234741,642.997498 908.494507,643.000732 907.383667,643.005615 
	C905.928894,641.990051 906.294250,640.787537 906.668213,639.237793 
	C906.861816,638.654907 906.964233,638.429688 907.285278,637.902710 
	C908.279236,636.893921 909.125671,636.459351 910.649414,637.462402 
	C910.764893,639.442139 910.430542,640.858032 910.035645,642.634155 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M915.050354,631.025513 
	C915.076965,631.474060 915.066650,631.905701 915.054077,632.661743 
	C915.002197,633.601990 914.857422,634.185364 914.234314,634.878357 
	C913.712341,635.199341 913.472656,635.386597 913.158447,635.828308 
	C912.766357,636.108276 912.494263,636.192200 911.890686,636.004395 
	C910.694214,633.122620 911.171814,631.194092 914.563477,630.764709 
	C914.939270,630.975525 915.013428,631.008667 915.050354,631.025513 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M914.186401,635.214294 
	C914.185547,634.499084 914.373596,633.927734 914.806763,633.171265 
	C916.205505,634.170105 916.078979,634.989014 914.186401,635.214294 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M911.577881,635.966431 
	C912.142883,635.982605 912.321899,635.989685 912.769653,636.010620 
	C912.923767,636.886658 912.619446,637.614380 911.400940,637.967041 
	C911.099182,638.026245 911.010864,637.999817 910.968384,637.981812 
	C910.433594,637.217957 910.479431,636.543457 911.577881,635.966431 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M911.979736,635.613647 
	C911.650635,636.446472 911.337463,636.917480 910.975098,637.676147 
	C910.133423,637.606262 909.340271,637.259094 908.248901,637.908569 
	C906.237305,636.401001 906.293152,634.280396 907.277832,632.570435 
	C909.829407,628.139465 911.412903,623.273743 913.828247,618.797974 
	C914.557434,617.446777 915.530518,616.348511 917.650269,616.774658 
	C917.666321,618.742859 917.335022,620.487793 917.005493,622.619995 
	C916.691223,624.113220 916.375122,625.219177 916.022827,626.670288 
	C916.111938,627.176331 916.237183,627.337097 916.170715,627.735840 
	C915.167786,628.547424 915.003967,629.399658 914.956421,630.642456 
	C912.596191,631.463501 912.259399,633.332520 911.979736,635.613647 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M916.131104,627.888184 
	C915.979004,627.973877 915.984741,627.494568 915.985718,627.255066 
	C916.140442,627.176514 916.311584,627.325684 916.435608,627.507019 
	C916.465271,627.550476 916.338135,627.701172 916.131104,627.888184 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M900.190430,651.215698 
	C895.672119,651.553833 892.368286,649.189758 888.748535,647.530151 
	C881.958313,644.416992 875.321594,641.251160 869.536682,636.306274 
	C865.633667,632.969971 859.889709,633.035339 855.571167,630.079712 
	C845.779236,623.378296 835.046570,618.012451 824.542603,612.888367 
	C814.188354,607.837158 814.490784,598.696167 811.182007,590.902954 
	C807.849304,583.053345 806.805481,574.405945 802.739990,566.612610 
	C800.538818,562.393066 799.937256,557.121216 798.252686,552.444214 
	C796.203064,546.753906 793.933960,541.142578 791.757263,535.498047 
	C791.518311,534.878418 791.115234,534.283386 791.036743,533.646301 
	C789.524780,521.371887 779.534729,513.103149 775.944031,501.693237 
	C774.627197,497.508820 769.042542,495.564270 766.721375,491.289825 
	C765.894714,489.767456 763.971924,488.853210 763.968872,486.390381 
	C762.998413,480.755737 767.276306,478.069458 769.706360,474.498230 
	C770.333984,473.575867 772.132324,473.180023 772.176636,472.156097 
	C772.416992,466.602112 777.097717,464.558105 780.367249,461.459991 
	C786.107544,456.020660 793.454224,455.056580 800.654602,458.520355 
	C804.528015,460.383667 808.276123,462.072357 811.252380,465.667053 
	C813.133057,467.938568 817.276794,467.844543 820.248291,469.354797 
	C825.997742,472.276886 832.067383,474.443878 837.125305,478.782410 
	C840.912842,482.031189 845.810303,483.702606 850.611633,485.099976 
	C853.593872,485.967957 855.979675,487.493530 858.318359,489.469910 
	C863.082764,493.496307 868.927002,496.191284 872.877747,501.245117 
	C873.177124,501.628143 873.673523,502.025299 874.120239,502.043213 
	C883.837097,502.432251 883.541931,511.193848 886.584717,517.078735 
	C887.404663,518.664551 888.049683,520.413452 888.719177,522.100220 
	C890.986877,527.813660 896.442810,532.227478 895.518250,539.291016 
	C895.277832,541.128235 896.983582,542.555908 898.329712,543.828552 
	C900.841187,546.202942 901.675964,548.917175 899.344055,551.768738 
	C897.637756,553.855347 899.152039,554.930115 899.819824,556.806641 
	C903.781311,567.938416 913.373657,576.377869 915.722900,587.877991 
	C917.361145,595.897400 918.702454,604.223328 914.951233,613.010193 
	C910.684631,623.004211 906.624512,633.173706 902.669800,643.343262 
	C902.198914,644.554077 901.685669,645.836121 901.914673,647.594727 
	C901.882996,648.477478 901.729126,648.950623 901.222351,649.676392 
	C900.965149,650.135620 900.953613,650.307373 900.932739,650.737305 
	C900.762512,651.135071 900.601624,651.274719 900.190430,651.215698 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M901.311829,649.916382 
	C900.979553,649.528076 900.982544,649.092407 900.987671,648.329590 
	C902.621643,644.519043 901.849731,639.415344 907.681396,638.774170 
	C907.695007,640.106079 907.390015,641.212219 907.049072,642.662842 
	C906.259827,646.104309 903.802429,647.868530 901.311829,649.916382 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M900.006165,651.325317 
	C899.940125,651.017090 900.432434,650.994141 900.677917,650.994751 
	C901.806641,651.201599 902.985535,651.148804 902.161438,652.838745 
	C901.063721,652.901855 900.328430,652.591187 900.006165,651.325317 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M473.666840,916.857544 
	C473.826385,919.411133 475.167175,920.155273 477.706146,920.020264 
	C478.381927,920.447937 478.488556,920.910828 478.228394,921.712524 
	C478.015808,922.013062 477.968628,922.031433 477.956512,922.009216 
	C477.713348,922.400146 477.451843,922.792786 476.839752,923.323120 
	C474.971497,924.046997 473.591034,925.046570 471.567627,925.353149 
	C465.263306,927.195801 460.170197,933.754272 459.820190,939.413391 
	C459.579590,943.303711 461.152100,945.285522 465.173248,946.045166 
	C468.009308,948.424500 472.798309,949.246338 470.244568,954.528625 
	C464.762573,954.324463 460.120911,951.944763 454.930847,951.618652 
	C446.553833,951.092163 439.010223,953.654724 431.086243,956.651001 
	C424.649811,955.233521 420.130371,957.350891 416.800812,962.508545 
	C415.486084,964.545288 414.242188,966.894165 410.815918,966.276550 
	C408.484283,962.893311 405.702179,962.457764 402.364166,963.748657 
	C401.139954,964.222168 399.823425,964.441406 398.542999,964.756531 
	C394.761444,965.687134 392.530518,964.136902 391.501343,960.505554 
	C390.916840,958.443298 390.204163,956.388733 389.927429,954.280273 
	C389.353424,949.906799 387.373047,947.353149 382.311859,947.604431 
	C381.645142,945.270325 383.024994,944.087646 384.417572,942.844421 
	C386.271454,941.189392 388.359650,939.604858 387.663757,935.981995 
	C390.121674,941.541199 395.026184,941.025635 399.270416,941.804626 
	C400.962219,942.115173 403.558990,942.483337 402.415649,939.333557 
	C401.822693,937.700073 400.708008,936.388672 402.564087,935.033752 
	C404.794983,933.405334 406.207672,935.075439 407.838928,936.707153 
	C409.409302,938.286865 409.486572,939.948914 409.083221,941.604370 
	C408.741577,943.006348 408.799072,943.873352 410.066101,945.000061 
	C413.918152,948.425476 413.756256,949.485291 409.638367,952.414124 
	C409.133606,952.773193 408.420837,952.951050 408.494324,953.790955 
	C414.011780,953.991028 415.937164,951.712097 414.409241,946.903320 
	C413.420868,943.792542 411.528412,940.954773 411.774658,937.268066 
	C411.999146,935.933289 411.999542,934.866638 412.000061,933.399963 
	C412.857117,931.643311 414.580933,931.808167 415.823883,930.633362 
	C416.029510,929.746765 415.932098,929.135681 415.768799,928.187256 
	C416.429260,920.450867 419.766235,914.618835 426.181915,910.745850 
	C428.740356,909.201294 431.390442,907.811584 434.184967,906.671509 
	C438.184052,905.040039 438.530518,902.856323 434.964905,900.354797 
	C431.785889,898.124390 428.689270,895.514160 424.080048,896.505615 
	C421.357880,897.091125 416.051086,891.505310 416.218903,888.705933 
	C416.432281,885.146545 419.153198,884.797180 421.935181,884.470093 
	C424.400696,884.180176 427.041779,886.429626 429.147705,884.655823 
	C431.192932,882.933105 430.109924,879.925415 430.617645,877.521912 
	C431.197662,874.776306 432.806091,874.035950 435.380859,874.691711 
	C436.526276,874.983521 438.006897,875.173096 438.058411,873.514282 
	C438.109833,871.858459 436.494232,871.774231 435.454773,872.161560 
	C429.503967,874.379028 423.162445,872.906860 417.159912,874.201904 
	C411.431213,875.437927 406.761353,872.440491 401.846741,870.704407 
	C398.843842,869.643616 399.012848,864.030090 402.068024,863.638489 
	C407.967590,862.882385 408.743500,857.912903 411.751770,854.271362 
	C413.354401,852.009766 417.213684,849.576294 410.640656,849.601074 
	C407.817078,849.611694 409.167480,845.760132 407.842560,843.873535 
	C406.518219,841.987793 408.949249,842.214355 410.071320,841.955627 
	C410.956329,841.751648 411.731781,841.426453 411.507324,840.362183 
	C410.414246,835.179077 413.693604,835.041687 418.126923,835.513977 
	C414.017914,831.544067 415.269043,830.254761 419.814667,829.705750 
	C422.316040,829.403625 424.916534,828.865723 427.401184,827.642273 
	C430.016754,826.354431 433.261230,826.003967 435.262177,829.387146 
	C435.701935,830.130676 436.464478,830.842041 437.272919,830.313782 
	C438.222321,829.693420 438.272583,828.050964 437.599884,827.676147 
	C433.077911,825.156372 437.892059,819.103516 433.285248,816.605408 
	C432.641052,816.256042 432.992767,814.505676 433.871765,813.962158 
	C436.398193,812.400024 437.913208,810.207825 438.910736,807.457764 
	C439.455353,805.956360 440.901184,805.988647 442.318176,806.252869 
	C444.635864,806.684998 446.418396,808.089722 448.480377,809.997437 
	C449.057861,806.479431 447.115479,804.633423 445.303223,803.309143 
	C441.971466,800.874573 443.529755,799.723206 446.052795,798.479248 
	C446.946442,798.038635 447.829803,797.559692 448.762115,797.219482 
	C449.915771,796.798462 450.935059,796.347900 450.883667,794.867004 
	C450.838470,793.564697 450.262207,792.515015 448.997620,792.252075 
	C444.700104,791.358337 446.342712,790.135437 448.318573,787.943115 
	C451.133820,784.819458 454.115570,781.474182 453.129333,776.307129 
	C452.824585,772.794495 453.295746,769.884521 455.147949,767.032471 
	C456.408295,765.091736 457.361176,762.540283 455.716980,759.677856 
	C455.314880,759.041504 455.243378,758.724243 455.391296,758.137756 
	C455.841309,757.393188 456.213531,756.885925 456.848297,756.203674 
	C457.729004,755.380493 457.988861,754.585632 458.143860,753.395996 
	C458.827362,752.124451 459.016266,750.982361 459.888733,749.805969 
	C461.269684,749.293457 462.437103,749.637451 462.497955,750.720093 
	C462.737000,754.972107 465.280518,759.504272 461.122070,763.667114 
	C460.695068,765.104492 460.388885,766.209045 460.041962,767.656738 
	C460.685577,768.770020 461.704041,769.260803 461.991089,770.685181 
	C461.808990,772.358276 462.524414,774.044312 460.605774,774.772583 
	C457.008972,776.137817 457.010773,776.142578 459.839264,779.698486 
	C464.706146,784.399170 465.071533,786.099121 461.744568,789.813599 
	C460.125732,790.054199 458.957794,789.151001 457.415894,789.697998 
	C455.994598,793.415894 458.401947,796.418762 458.790497,799.759399 
	C459.129517,802.674072 459.191101,805.235718 457.435242,808.021606 
	C456.586975,809.485107 455.554993,810.479370 455.523438,812.305176 
	C455.594086,815.651733 456.735840,818.126770 459.068848,820.025513 
	C463.523590,823.651062 465.021149,828.218262 463.422516,834.150024 
	C463.224091,835.911743 462.657654,837.034363 461.024292,837.825500 
	C458.701447,838.153748 457.076752,837.285156 457.073120,835.384399 
	C457.062531,829.820007 453.468506,830.447449 449.883026,830.870117 
	C449.061005,830.967102 448.243500,831.135559 447.509003,831.754944 
	C447.127106,833.183655 448.668243,833.526367 449.189453,834.456116 
	C450.214417,836.284424 450.114746,837.980225 448.765167,839.523865 
	C447.303436,841.195801 445.787903,840.386719 444.325867,839.431824 
	C443.768829,839.068115 443.178375,838.742310 442.664795,838.327271 
	C439.399017,835.687439 436.127167,834.531189 432.462646,837.739502 
	C431.382355,838.685364 429.818237,838.912842 427.290741,839.069824 
	C432.228210,840.027832 435.918060,841.470825 439.903534,841.717773 
	C441.835205,841.837463 443.450623,842.895386 443.740540,845.030090 
	C444.039307,847.229797 442.687531,848.377869 440.757233,849.011902 
	C439.830017,849.316406 438.456146,849.429565 438.465942,850.592590 
	C438.499603,854.585632 436.528381,857.115112 433.136292,858.857910 
	C432.353149,859.260254 432.020691,860.118591 432.513062,861.002380 
	C433.372406,862.545044 434.752808,863.451050 436.432129,863.842896 
	C437.686005,864.135437 438.718506,863.775574 439.286194,862.474670 
	C439.826294,861.237122 440.087799,859.936340 440.232147,858.612793 
	C441.024261,851.351196 444.699005,846.642700 452.089691,845.285217 
	C454.946869,844.760498 456.466309,841.573120 459.951202,841.624268 
	C461.081451,841.606018 461.800995,841.786987 462.805176,842.305908 
	C465.161591,845.051758 464.114410,847.511536 462.888794,849.943848 
	C460.265533,855.149963 461.092682,860.619690 461.628021,866.066223 
	C461.734406,867.148804 462.553680,867.714355 463.316437,868.394714 
	C466.763367,871.468994 466.653107,872.349060 462.486755,874.643616 
	C461.440308,875.219910 460.203339,875.694397 459.901093,876.829102 
	C458.373779,882.563416 453.376190,885.261780 449.494141,888.911499 
	C445.810089,892.375122 440.943451,891.620239 438.136719,887.316040 
	C437.707733,886.658203 437.580078,885.716309 436.549072,885.617126 
	C434.851868,892.028137 435.814728,893.599854 441.868713,894.318054 
	C449.564087,895.230957 452.210632,901.085754 447.776642,907.524048 
	C446.828613,908.900513 446.360809,910.512878 447.244263,911.686584 
	C448.990997,914.007324 450.330475,916.206848 450.212555,919.321533 
	C450.132690,921.430664 452.185272,920.466858 453.340393,920.488892 
	C460.260468,920.621094 466.724884,918.274658 473.666840,916.857544 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M417.787750,930.792847 
	C416.891541,933.321716 414.763245,932.976929 412.388000,933.000000 
	C411.466339,930.316833 409.761780,928.180420 409.427338,925.196594 
	C408.853333,920.075195 408.458527,916.133240 415.737488,916.232910 
	C410.914337,914.614014 406.955597,912.105835 401.849731,913.786194 
	C399.537628,914.547180 398.138550,914.787231 397.777161,917.558716 
	C397.422791,920.276245 394.533539,921.286133 392.473877,922.377441 
	C390.618195,923.360657 390.390015,921.023315 389.755219,919.744751 
	C388.445618,917.106995 389.656219,913.815613 387.101379,911.379944 
	C385.419830,909.776794 384.395233,907.638672 381.558167,907.941956 
	C380.318115,908.074463 379.242706,907.572754 379.140717,906.053955 
	C379.053009,904.748108 379.637939,903.825500 380.795654,903.248901 
	C384.122803,901.591675 385.244385,899.902405 380.754395,898.368347 
	C377.703491,897.325928 377.812958,894.487793 377.364868,892.202454 
	C376.789337,889.267273 379.786255,890.117615 381.186066,889.446045 
	C384.364105,887.921387 384.161194,886.287048 381.762512,884.304382 
	C380.487762,883.250671 379.039917,882.381042 377.885956,881.214783 
	C376.983978,880.303223 375.913818,878.996155 376.695404,877.698914 
	C377.375824,876.569519 378.765320,877.166504 379.907684,877.441040 
	C380.855164,877.668762 381.865753,877.859924 382.820557,877.770447 
	C383.882935,877.671021 384.603180,876.907898 384.757263,875.796692 
	C384.913116,874.672485 384.398499,873.749390 383.379028,873.419739 
	C381.652069,872.861389 379.869232,872.442261 378.082550,872.112488 
	C376.487305,871.818054 374.247009,873.884033 373.373322,871.164856 
	C372.668427,868.970825 372.375763,866.490051 374.121216,864.534180 
	C375.880524,862.562805 378.439240,861.104980 380.802734,862.281372 
	C385.845093,864.791321 390.312805,861.035583 395.162964,861.537231 
	C396.709412,861.697144 398.883392,859.758728 398.544556,857.343079 
	C398.142548,854.477356 401.163849,853.465088 401.653198,850.916870 
	C401.924988,849.501770 404.657440,849.714294 404.795135,851.711365 
	C405.213867,857.783752 408.508209,855.022888 411.640686,854.046509 
	C412.033081,857.041565 409.772217,859.390869 409.627655,862.513794 
	C409.529144,864.642639 406.977661,863.898438 405.428802,863.974304 
	C403.311188,864.077942 401.353424,864.211670 401.073853,866.943604 
	C400.761932,869.991577 403.327789,869.235413 404.858612,870.177673 
	C410.605225,873.714661 416.581573,872.452637 422.620667,872.043030 
	C426.736206,871.763916 430.868652,872.356140 434.833832,870.353027 
	C436.464752,869.529053 438.784241,869.347595 440.180115,871.526489 
	C441.267883,873.224487 440.773895,874.575867 439.525909,876.001526 
	C437.846832,877.919434 436.406738,878.018250 434.547485,876.183716 
	C433.110596,874.765991 432.155853,875.502625 432.040497,877.352112 
	C431.957703,878.679382 432.014954,880.015015 431.998627,881.346863 
	C431.923126,887.512024 431.098328,888.023560 425.128326,886.338135 
	C423.426147,885.857605 421.500122,886.034668 419.687256,886.124023 
	C418.210571,886.196899 417.181000,887.039856 417.218262,888.680237 
	C417.268463,890.888977 423.233795,895.088074 425.362213,894.449280 
	C429.519073,893.201843 439.479248,900.032654 439.889771,904.319153 
	C440.083618,906.343384 438.878296,907.046387 437.330872,907.336670 
	C432.980927,908.152832 429.271881,910.572205 425.762299,912.847107 
	C420.454041,916.287659 417.767609,921.840637 417.000183,928.595825 
	C416.927887,929.624695 417.121613,930.152771 417.787750,930.792847 
z"/>
<path fill="#1A2537" opacity="1.000000" stroke="none" 
	d="
M381.748352,947.626282 
	C389.093994,945.933228 389.093994,945.933228 391.058441,953.472534 
	C393.954132,964.585632 394.007141,964.758179 405.216217,961.313293 
	C409.326874,960.049988 410.635132,961.604187 411.218292,965.561401 
	C411.579712,966.366638 411.733368,966.813721 411.780579,967.646973 
	C412.578247,973.991882 409.123901,976.259033 403.978363,977.824463 
	C396.498596,980.099854 396.579865,980.367004 400.626404,988.000061 
	C401.068390,988.389282 401.235748,988.557495 401.604614,989.021484 
	C402.278992,990.173645 402.386749,991.115845 402.487549,992.411987 
	C403.461609,996.116028 404.125946,999.547546 405.164490,1002.881775 
	C405.896515,1005.231873 406.997345,1007.192810 410.153503,1007.410767 
	C411.412689,1007.516357 412.244171,1007.142456 413.430847,1006.793457 
	C415.893250,1006.470154 417.401947,1004.730042 419.826569,1004.327637 
	C424.219696,1005.486877 424.072235,1009.480164 425.718018,1012.686890 
	C425.885834,1013.266052 425.935089,1013.502441 425.997772,1014.099243 
	C425.971039,1014.695496 425.930878,1014.931213 425.745605,1015.493042 
	C425.283386,1016.174561 424.913513,1016.460266 424.246155,1016.924316 
	C424.010864,1017.184998 424.069305,1017.542297 424.080750,1017.722961 
	C422.324280,1024.494263 416.428284,1025.484375 411.259460,1026.370728 
	C399.293457,1028.422974 392.924896,1024.109253 389.247528,1012.400696 
	C386.811127,1004.643127 384.630035,996.807922 382.509064,988.958435 
	C381.685852,985.911865 380.614807,983.412537 376.806580,983.147156 
	C374.000244,982.951660 372.506775,980.821838 371.777557,978.264893 
	C371.004669,975.554871 370.288177,972.821289 370.160583,969.582092 
	C370.533752,967.742249 371.591888,966.991699 373.175537,966.274719 
	C376.675537,965.725708 377.388763,963.884399 376.949829,960.936279 
	C376.448212,957.567139 375.905609,954.189148 375.894806,950.471680 
	C377.594971,948.749756 379.696747,948.650818 381.748352,947.626282 
z"/>
<path fill="#C7C5C7" opacity="1.000000" stroke="none" 
	d="
M411.599030,937.000000 
	C413.437195,940.331604 414.872620,943.664490 416.315125,946.994385 
	C417.223938,949.092285 417.656067,951.289124 415.837646,953.011108 
	C413.705933,955.029602 411.330200,956.866760 408.036438,956.124512 
	C406.703644,955.824219 405.332001,955.392151 405.260162,953.726501 
	C405.185364,951.991394 406.694763,951.612427 407.922516,951.225647 
	C409.457977,950.742065 411.549255,950.993774 411.711975,948.747009 
	C411.853302,946.795532 409.970490,946.549683 408.642914,946.009766 
	C407.021057,945.350098 406.991150,944.153809 407.245667,942.731079 
	C407.536377,941.106140 407.751678,939.467651 408.000488,937.417542 
	C409.067230,937.000000 410.133270,937.000000 411.599030,937.000000 
z"/>
<path fill="#060D0E" opacity="1.000000" stroke="none" 
	d="
M375.745972,950.165527 
	C378.814819,954.452209 378.057556,959.472778 378.799530,964.150635 
	C379.159912,966.422607 377.850800,968.482666 374.428894,967.002930 
	C369.935242,962.536316 369.957611,957.626221 372.544739,952.038696 
	C373.631531,951.150146 374.532928,950.656372 375.745972,950.165527 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M372.433716,951.648193 
	C371.381744,956.635803 373.057098,961.362305 374.043243,966.552002 
	C373.187927,967.481079 372.292511,968.010986 371.199829,968.786377 
	C370.198181,970.002258 369.132324,970.089172 367.601318,969.780701 
	C366.991119,969.470398 366.771210,969.302429 366.317688,968.792358 
	C365.258514,966.351624 365.646729,964.154358 365.562500,961.591797 
	C365.675995,959.169373 366.813141,957.710083 368.345825,956.015259 
	C369.432953,954.755371 370.732666,954.159363 370.960205,952.335693 
	C371.384552,951.890076 371.760925,951.771240 372.433716,951.648193 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M439.539185,786.968872 
	C439.898010,787.081177 439.931091,787.267273 440.001648,787.734741 
	C440.039154,788.015991 440.016388,788.513733 440.010834,788.762939 
	C438.495728,788.937134 437.919586,790.506409 436.356659,790.963379 
	C436.148895,788.942688 437.404694,787.772095 439.539185,786.968872 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M333.362854,897.529175 
	C335.675323,898.245117 334.833405,899.385803 333.868439,900.333191 
	C333.288544,900.902649 332.215271,901.221985 331.713226,900.363647 
	C330.914642,898.998352 332.270966,898.395081 333.362854,897.529175 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M358.609985,932.343384 
	C358.036774,930.502197 358.954163,930.081177 360.270233,930.142273 
	C360.959381,930.174194 361.632202,930.616638 361.450745,931.326843 
	C361.114746,932.641785 360.127686,933.029602 358.609985,932.343384 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M345.358521,904.372925 
	C343.982697,904.855042 343.181732,904.570129 343.347687,903.236816 
	C343.390503,902.892944 343.915070,902.379089 344.259735,902.343506 
	C345.588959,902.206055 345.873047,903.002441 345.358521,904.372925 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M362.320923,942.179077 
	C363.256073,941.063538 364.091614,940.911072 364.624908,942.137573 
	C364.764618,942.458801 364.574463,943.182983 364.294556,943.381226 
	C363.235626,944.130737 362.550568,943.670837 362.320923,942.179077 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M368.179932,955.707886 
	C369.435364,957.616089 367.340088,958.676575 367.053040,960.646606 
	C365.471741,963.404236 365.843872,965.955750 366.587036,968.798828 
	C366.759521,969.075195 367.070221,969.128662 367.223663,969.092529 
	C367.988342,969.927551 368.210114,970.884705 367.907837,972.284058 
	C366.307220,974.352783 364.624146,976.014832 366.070160,978.945862 
	C366.315430,980.242004 366.256775,981.186035 365.912720,982.458862 
	C363.482758,985.067627 363.821106,987.554321 365.576752,989.826294 
	C369.688568,995.147522 374.123077,1000.213501 378.428101,1005.855347 
	C378.907166,1006.907898 378.927368,1007.659058 378.537720,1008.774048 
	C377.963135,1009.513733 377.503876,1009.780945 376.572174,1009.977112 
	C375.615662,1009.976624 375.107971,1009.803162 374.324615,1009.249207 
	C372.674561,1004.865845 369.602631,1002.146545 366.184265,999.275818 
	C365.456848,998.475281 364.937531,997.930847 364.196350,997.210083 
	C363.512543,996.739197 363.028168,996.467651 362.293274,996.075745 
	C359.655609,994.068237 356.802551,993.211182 353.750488,992.007751 
	C352.917694,988.965515 354.900665,988.137878 357.192352,987.155762 
	C357.994598,986.170227 358.422211,985.290527 359.011169,984.159058 
	C360.003998,983.682739 361.040741,983.305908 360.853027,982.381775 
	C359.897888,977.678772 361.628021,972.923035 360.359039,968.207031 
	C359.346710,964.444641 363.071960,958.539124 368.179932,955.707886 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M357.055420,987.039185 
	C356.635773,988.659729 354.557678,989.344421 354.081696,991.681091 
	C353.984314,992.501404 353.795319,992.932556 353.230225,993.550293 
	C351.848877,994.168884 350.847443,994.997559 349.270752,994.356567 
	C344.833069,993.058411 340.759369,994.138489 336.667938,994.627930 
	C334.742188,994.858215 332.245758,995.800232 331.596436,993.293762 
	C331.037323,991.135498 333.306335,989.952637 335.098145,989.122742 
	C341.989807,985.930664 349.341766,987.198303 357.055420,987.039185 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M348.778870,994.424561 
	C350.085175,993.749573 351.181427,993.365479 352.627441,992.967529 
	C353.343842,993.355835 353.502441,993.827820 353.256439,994.704041 
	C351.756226,996.366028 351.314850,998.043884 351.150635,1000.146973 
	C346.239960,1000.748779 341.512726,999.662048 336.746582,998.154663 
	C340.656342,996.713074 345.088165,997.314392 348.778870,994.424561 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M370.804077,1018.240356 
	C372.132507,1024.575439 369.679382,1030.070679 365.494812,1035.326294 
	C364.929688,1034.475220 363.775665,1033.377686 364.800140,1032.335083 
	C368.596161,1028.471802 368.636902,1023.104553 370.804077,1018.240356 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M330.802307,1028.898926 
	C331.458984,1028.392090 332.359070,1028.142334 333.613525,1027.921387 
	C333.992188,1028.533447 334.245270,1029.483643 334.000824,1029.636230 
	C332.975220,1030.276001 331.936554,1029.929077 330.802307,1028.898926 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M354.762482,1042.276611 
	C353.188232,1047.340820 348.072113,1048.298218 344.048553,1049.989868 
	C338.776154,1052.206787 332.566528,1052.004883 327.071350,1051.163696 
	C321.073517,1050.245605 319.852631,1045.057373 321.985535,1035.412109 
	C322.677917,1034.631836 323.368439,1034.268555 324.330078,1033.812012 
	C324.673035,1035.026855 325.132172,1036.461914 324.751770,1037.621582 
	C322.787445,1043.610474 323.304138,1045.065796 331.686981,1045.852661 
	C339.444672,1046.580688 347.112305,1045.291016 354.762482,1042.276611 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M375.296448,1016.234375 
	C375.048126,1014.909119 375.013245,1013.858948 374.966919,1012.414917 
	C374.729187,1011.477051 374.852631,1011.025513 375.682739,1010.518311 
	C377.183655,1010.245117 378.145966,1010.644714 379.283783,1011.632690 
	C379.955811,1012.646118 380.149200,1013.419800 380.245605,1014.618652 
	C380.350189,1018.974426 380.391876,1022.941711 380.861450,1026.884033 
	C381.412628,1031.511597 383.965698,1034.672485 388.487427,1035.551147 
	C393.979034,1036.618164 399.586609,1037.415771 405.086975,1035.329224 
	C406.939453,1034.626465 408.763245,1033.762573 411.083466,1034.902344 
	C410.941101,1038.965088 407.610779,1039.364258 405.047455,1040.476685 
	C397.554565,1043.728638 389.872864,1043.718750 382.241058,1041.061768 
	C377.974823,1039.576416 375.237091,1036.662842 375.205719,1031.379395 
	C375.031799,1030.548218 375.030670,1030.111450 375.030762,1029.346924 
	C376.112061,1028.098999 375.993011,1026.838867 375.995850,1025.306885 
	C376.002197,1024.248901 376.009766,1023.497986 376.024078,1022.371582 
	C375.832825,1020.169556 376.379852,1018.272217 375.296448,1016.234375 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M374.862915,1031.336914 
	C375.578552,1031.316650 376.593353,1031.639771 376.599548,1031.980957 
	C376.694733,1037.249390 380.441956,1039.432739 384.579285,1040.130249 
	C393.743439,1041.674683 403.067627,1042.794189 410.941528,1035.356689 
	C411.372498,1033.207764 412.481934,1032.355469 414.668701,1032.587158 
	C415.145569,1032.969604 415.269409,1033.194946 415.201843,1033.754883 
	C415.034424,1035.509766 414.016235,1036.395874 413.149902,1037.719849 
	C408.198059,1039.998413 403.887085,1043.150879 398.664520,1044.162231 
	C391.427582,1045.563721 384.371735,1045.933594 377.782288,1041.559204 
	C373.922821,1038.997070 373.232391,1035.757812 374.862915,1031.336914 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M353.471985,995.030090 
	C353.051544,994.574524 353.043030,994.110718 353.005859,993.300293 
	C353.179810,992.698547 353.382385,992.443420 353.821198,992.104736 
	C357.838898,990.321045 360.043793,992.389648 361.969604,995.627869 
	C359.323151,995.644165 356.603485,995.333008 353.471985,995.030090 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M375.054321,1016.365356 
	C378.050568,1017.137085 377.008331,1019.374939 376.284241,1021.728882 
	C374.404633,1020.502991 375.188538,1018.546143 375.054321,1016.365356 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M375.735413,1025.245483 
	C377.261993,1026.397339 377.548065,1027.717163 375.360016,1028.942871 
	C375.180023,1027.843140 375.328125,1026.667114 375.735413,1025.245483 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M376.270569,1010.683044 
	C375.791382,1011.200317 375.516693,1011.397766 375.098755,1011.808105 
	C374.146423,1011.437073 373.984985,1010.586853 373.996704,1009.333191 
	C374.442993,1008.997803 374.881134,1008.997986 375.647888,1008.999146 
	C376.363464,1009.373657 376.529694,1009.828064 376.270569,1010.683044 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M364.048767,997.323792 
	C364.894989,997.089478 365.642120,997.382019 365.958801,998.671143 
	C365.115692,998.901733 364.361481,998.619202 364.048767,997.323792 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M415.315491,1034.017212 
	C415.006531,1033.888550 415.002655,1033.687378 414.993469,1033.184448 
	C414.176544,1030.745850 415.395905,1030.366333 417.489868,1030.649048 
	C417.888611,1031.066895 417.915161,1031.257324 417.980560,1031.733643 
	C417.219818,1032.661255 416.420166,1033.302979 415.315491,1034.017212 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M366.376129,973.198364 
	C366.689240,971.849670 366.879883,970.818665 367.223816,969.421997 
	C368.340881,969.046936 369.304626,969.037476 370.635468,969.029907 
	C371.616302,971.089478 372.455750,973.110596 372.795502,975.212524 
	C373.443237,979.219482 373.765472,982.920227 379.692108,981.521851 
	C382.287048,980.909607 382.888672,983.935608 383.470673,985.960754 
	C385.710785,993.755127 387.810669,1001.589966 390.069214,1009.378784 
	C390.893768,1012.222473 391.470001,1015.183716 393.223511,1017.692749 
	C398.730255,1025.571899 406.201447,1027.362915 415.588379,1023.419312 
	C418.630249,1022.141296 421.774475,1021.319824 423.742859,1017.996033 
	C425.794647,1019.314697 425.187775,1020.820984 424.157288,1022.676392 
	C423.192719,1023.570801 422.346100,1024.128784 421.270447,1024.888794 
	C417.713837,1027.124878 415.375488,1031.047607 410.299805,1030.498291 
	C407.053284,1030.032959 404.398895,1031.657227 401.517303,1031.170410 
	C389.085693,1029.069946 389.135284,1026.774780 385.796600,1016.202698 
	C383.565277,1009.137207 381.944366,1001.830017 380.016541,994.639954 
	C379.213989,991.646790 378.048523,989.101379 374.495270,988.328552 
	C371.344238,987.643127 369.380890,985.386841 368.236725,981.965515 
	C367.728638,980.675781 367.593018,979.693848 367.501801,978.351929 
	C367.500183,977.047546 367.036804,976.268677 366.425110,975.181580 
	C366.191742,974.419495 366.178497,973.969055 366.376129,973.198364 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M409.941620,1029.433838 
	C414.282898,1029.516479 416.723450,1025.926147 420.679077,1025.106445 
	C420.037537,1026.800171 419.033661,1028.509766 417.945923,1030.547852 
	C416.796234,1031.027710 415.611481,1030.941162 415.055542,1032.564697 
	C413.852112,1033.488159 412.716095,1034.093750 411.294617,1034.856934 
	C405.402466,1037.293701 399.751038,1038.492432 393.522919,1038.145874 
	C384.374908,1037.636963 378.243317,1033.742554 378.953918,1023.379333 
	C379.131439,1020.790955 378.659790,1018.158020 378.707520,1015.265015 
	C382.104065,1014.667114 382.061615,1017.205383 382.563507,1019.166748 
	C385.652283,1031.238037 395.606598,1036.077393 407.539856,1030.912354 
	C408.355530,1030.220093 409.015839,1029.947021 409.941620,1029.433838 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M415.657196,807.300903 
	C417.392761,807.047852 418.771210,807.005005 420.562622,806.962280 
	C420.168335,809.637390 417.856232,810.698364 415.331726,811.940186 
	C413.692108,810.390686 414.120728,808.924500 415.657196,807.300903 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M415.701843,807.073486 
	C415.263092,808.375183 414.898468,809.754395 414.990417,811.612427 
	C414.227448,811.980774 413.468811,811.983521 412.329956,811.964233 
	C412.623932,810.532959 413.298126,809.123718 414.057922,807.384521 
	C414.558838,807.055176 414.974152,807.055725 415.701843,807.073486 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M273.926697,858.665405 
	C271.241791,858.608826 268.516754,860.730408 265.372925,859.051270 
	C267.709381,855.574524 270.826996,857.537781 273.926697,858.665405 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M325.334473,844.979614 
	C325.141113,843.596008 325.973907,843.010620 327.657410,843.014954 
	C327.859009,844.406006 327.023560,844.988892 325.334473,844.979614 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M403.369080,811.971191 
	C403.180023,809.959839 404.341400,809.040833 406.657867,809.020874 
	C407.007629,809.755981 406.994995,810.490051 406.969177,811.589966 
	C405.893585,811.966064 404.831238,811.976318 403.369080,811.971191 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M412.767090,805.102234 
	C413.085632,805.521606 413.066406,805.942200 413.049866,806.679260 
	C411.936462,807.329651 410.820374,807.663513 409.351501,807.997070 
	C409.769897,806.590820 410.737305,805.417542 412.767090,805.102234 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M172.008759,795.585083 
	C169.566132,794.612671 168.313705,792.597778 167.688644,789.769226 
	C165.954453,781.921326 163.199478,774.383179 159.715714,767.135925 
	C159.025909,765.700989 159.043060,764.279663 159.007736,762.395325 
	C160.080475,762.139343 160.899811,762.672485 161.278992,763.691650 
	C164.812637,773.189392 168.350311,782.685730 171.812576,792.209473 
	C172.137802,793.104187 171.954529,794.183777 172.008759,795.585083 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M211.958633,829.642944 
	C207.704559,828.781189 203.111511,828.494385 199.637375,824.964783 
	C196.698883,821.979370 192.004623,822.124939 188.737549,819.495117 
	C187.337372,818.367920 185.576385,817.671204 185.046158,815.347229 
	C193.778641,818.545959 202.281906,822.664185 210.495071,827.368286 
	C211.126221,827.729736 211.442871,828.640259 211.958633,829.642944 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M178.985275,808.617737 
	C173.560516,806.702209 173.061661,801.585876 172.007980,796.388977 
	C175.844223,799.209351 177.861420,803.464355 178.985275,808.617737 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M184.953552,814.651001 
	C181.693634,814.766235 178.858047,813.990845 178.993347,809.403931 
	C182.533508,809.020325 183.385132,812.036926 184.953552,814.651001 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M419.723755,801.090637 
	C419.699158,803.790833 417.391174,804.361023 415.195923,805.106201 
	C415.568604,802.641541 417.509674,801.918213 419.723755,801.090637 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M425.610291,796.985352 
	C425.251465,799.064819 423.878387,800.441284 421.340973,800.957764 
	C421.357544,798.532043 422.786163,797.211731 425.610291,796.985352 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M407.331360,804.950745 
	C407.230499,803.006897 408.786438,802.555237 410.685120,802.075806 
	C410.761475,804.001526 409.217163,804.458191 407.331360,804.950745 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M404.261902,806.912598 
	C404.150024,805.571350 405.000916,805.011719 406.643188,804.995605 
	C406.520813,806.043396 405.780670,806.758301 404.261902,806.912598 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M185.012238,792.375366 
	C188.620911,797.758484 195.010849,800.821594 198.956314,806.647827 
	C193.376083,806.508972 186.172073,799.184448 185.012238,792.375366 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M177.019974,771.372070 
	C179.980637,772.963501 180.702103,776.281189 181.988647,779.634827 
	C177.702301,778.841431 178.400757,774.671509 177.019974,771.372070 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M181.840057,784.234314 
	C184.616287,784.826782 185.319000,786.738281 184.993225,789.622314 
	C182.032211,789.271606 182.088760,786.727112 181.840057,784.234314 
z"/>
<path fill="#266E80" opacity="1.000000" stroke="none" 
	d="
M237.726105,411.443481 
	C234.776581,413.627167 231.145493,414.934937 226.924561,415.683350 
	C227.236237,414.243744 228.803848,414.156738 230.104721,413.163208 
	C231.000290,412.827850 231.679642,412.679504 232.681351,412.400604 
	C234.457199,411.972443 235.910690,411.674835 237.726105,411.443481 
z"/>
<path fill="#266E80" opacity="1.000000" stroke="none" 
	d="
M225.627472,415.801697 
	C225.621994,416.048370 225.341827,416.295776 224.772598,416.486511 
	C224.773285,416.220703 225.063049,416.011566 225.627472,415.801697 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M218.591064,419.851746 
	C218.956207,420.005096 218.956329,420.216949 218.969162,420.746338 
	C220.568604,423.211090 219.701981,425.365906 217.766678,426.007965 
	C212.409515,427.785339 209.471497,431.664703 206.065155,435.874451 
	C203.500839,439.043610 200.207565,441.956329 197.428040,445.194519 
	C194.592056,448.498596 192.782501,452.423309 190.423004,456.010468 
	C189.307983,457.705688 188.718964,459.724884 189.711182,461.680206 
	C191.379425,464.967651 190.357193,467.389069 187.293198,469.575867 
	C182.228302,469.704010 180.397400,472.630127 179.432861,476.790436 
	C178.777969,479.615173 179.081955,482.904663 175.941193,485.094055 
	C174.286514,485.660522 173.036011,485.663239 171.708374,484.362122 
	C170.778854,481.964508 172.388153,480.086304 172.028717,477.652954 
	C172.151657,475.640381 173.254684,474.592377 174.504547,473.202545 
	C174.809387,472.495453 174.882202,472.079987 174.998962,471.351257 
	C175.544067,470.471741 176.070190,469.928436 176.751862,469.144043 
	C183.890335,455.596039 191.383560,442.627716 202.013214,431.398895 
	C203.864655,430.796051 204.683136,429.331543 205.842133,427.776337 
	C206.598450,426.821075 207.195709,426.177887 207.905945,425.288330 
	C209.250763,425.174072 210.069305,424.523376 210.880630,423.306488 
	C213.670410,422.560791 215.413437,419.991821 218.591064,419.851746 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M168.829865,581.664185 
	C165.648407,586.801392 168.489975,590.111511 171.768616,593.238037 
	C174.003433,595.369141 176.371094,597.360901 178.838989,599.709961 
	C175.658417,602.037781 173.482880,600.364319 171.448410,597.772400 
	C167.246063,592.418823 163.762329,586.761658 163.074371,579.380127 
	C165.908890,577.341309 166.999619,580.027161 168.829865,581.664185 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M185.946381,610.629761 
	C173.330063,609.594055 166.037628,601.589111 161.048340,590.351074 
	C163.569229,590.396057 164.972916,592.119385 165.947739,594.328003 
	C168.065262,599.125549 172.184082,602.267395 175.615265,605.972717 
	C176.735428,607.182434 178.331100,606.946838 179.807404,607.008118 
	C182.350937,607.113770 184.771133,607.486145 185.946381,610.629761 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M153.019516,549.387085 
	C155.689331,549.373718 154.958847,552.326599 156.755646,553.782227 
	C157.420517,554.315491 157.753250,554.694458 158.156555,555.483765 
	C160.297501,561.664612 162.423569,567.448120 164.882446,573.515442 
	C165.116760,574.666992 165.056091,575.434143 164.607239,576.525269 
	C164.190582,577.007568 163.971863,577.151001 163.389511,577.147217 
	C159.055878,575.375854 159.618683,571.690735 158.790756,568.421631 
	C157.196381,562.126221 154.974670,555.989685 153.019516,549.387085 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M206.955994,624.649292 
	C200.446503,622.165955 194.124725,619.016968 190.042191,612.360107 
	C196.757919,614.557556 202.742249,618.182922 206.955994,624.649292 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M163.020386,577.331299 
	C163.195694,576.999756 163.365524,576.999512 163.790039,576.995117 
	C166.173416,575.428040 167.412170,576.647034 168.736435,578.705688 
	C168.995621,579.002441 169.001389,579.497559 169.003754,579.745117 
	C169.382980,580.380005 169.528137,580.840332 169.222931,581.684448 
	C169.004272,581.995117 169.000244,582.000000 168.997070,582.002563 
	C166.663864,582.056824 165.779678,579.489197 163.363220,579.035645 
	C163.021332,578.545837 163.018112,578.104248 163.020386,577.331299 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M213.930969,626.665649 
	C212.862961,626.953247 211.640808,627.090332 211.055542,625.348206 
	C212.124786,625.050964 213.352737,624.902710 213.930969,626.665649 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M157.305084,556.257080 
	C157.026871,555.538513 157.022629,555.097717 157.019562,554.326111 
	C158.584824,552.199341 157.925415,550.481506 157.098831,548.558472 
	C155.113571,543.939636 155.565384,538.634338 153.130020,534.082947 
	C152.635696,533.159119 152.929337,531.779297 154.610138,531.079346 
	C156.560699,537.145996 157.979492,543.363770 159.848602,549.442993 
	C160.539154,551.688965 161.094894,553.831970 160.999908,556.577881 
	C159.695938,558.033630 158.596161,557.587036 157.305084,556.257080 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M152.645691,522.082886 
	C152.998367,523.385071 153.002716,524.772522 153.008163,526.575928 
	C150.127426,525.774231 150.302383,524.105530 152.645691,522.082886 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M182.762527,451.187927 
	C183.026871,452.779388 182.764633,454.372040 180.431259,455.010864 
	C179.998993,453.273071 181.139130,452.240570 182.762527,451.187927 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M168.660736,473.041260 
	C168.918716,473.881958 168.639450,474.632141 167.367615,474.992798 
	C167.089447,474.145325 167.358597,473.386658 168.660736,473.041260 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M176.668365,461.045654 
	C176.910843,461.890930 176.626617,462.640930 175.341827,462.968994 
	C175.092316,462.120605 175.370071,461.365997 176.668365,461.045654 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M247.338455,619.064331 
	C246.976898,619.027283 246.484253,619.015869 246.237885,619.011597 
	C245.329559,618.191406 244.382904,617.815247 243.237183,617.191528 
	C242.998795,617.004883 243.000000,617.000000 242.997330,617.001221 
	C234.853745,612.666687 227.368073,607.228577 219.366364,602.706238 
	C212.022751,598.555847 204.373199,594.840698 197.124527,590.563965 
	C190.252777,586.509705 183.278824,582.063904 179.032410,574.359680 
	C178.966705,573.562683 178.963867,573.125244 178.956085,572.359802 
	C176.152008,569.158325 173.799667,566.316589 173.868210,561.740906 
	C173.905991,559.218872 173.081573,556.254822 170.810242,554.126526 
	C169.373993,552.780762 168.492950,550.774292 168.239868,548.830688 
	C167.210999,540.928711 163.887833,533.635742 162.348083,525.883057 
	C161.566818,521.949341 162.205597,517.950134 161.988434,513.988342 
	C161.835464,511.198303 162.492599,508.358826 161.253448,505.306274 
	C160.819275,504.441437 160.627853,503.889648 160.193542,503.162720 
	C159.975281,501.600708 159.999893,500.213776 160.051361,498.410645 
	C162.839050,497.812653 162.583344,495.494354 163.328613,493.716339 
	C164.132614,491.798218 164.364502,489.501862 166.593307,488.041595 
	C169.360107,490.394135 168.654755,493.228485 167.601517,495.847534 
	C166.359253,498.936615 166.241196,501.908295 167.046066,505.094269 
	C167.869568,508.354034 167.511597,511.729431 167.468002,515.051697 
	C167.366745,522.767029 169.131622,530.035645 171.142242,537.489380 
	C173.396790,545.847290 177.803909,553.359375 179.308273,562.020020 
	C180.346756,567.998718 183.607132,573.462341 188.817032,577.393127 
	C193.700562,581.077637 198.386337,585.023987 203.789276,588.650269 
	C204.023712,588.976440 204.513458,588.985046 204.758286,588.991333 
	C207.165771,590.081604 209.421524,590.959045 211.954056,591.993469 
	C214.320999,591.920471 215.688919,593.186951 217.339264,594.583008 
	C218.224976,595.612305 218.769989,596.538391 220.196014,596.876953 
	C221.076050,597.256348 221.614838,597.599609 222.394958,598.165894 
	C224.241608,599.844788 226.063675,600.929871 228.477112,601.636230 
	C233.427673,603.618652 237.147354,606.888123 241.051712,609.872681 
	C243.290527,611.584106 245.763123,612.858398 248.630280,614.144531 
	C250.559372,615.356689 251.325119,617.636353 254.041275,617.698730 
	C254.810013,617.936890 255.208130,618.164185 255.766785,618.740662 
	C258.335693,623.515259 262.598419,625.640564 266.923676,627.567139 
	C276.476654,631.822327 284.343750,638.767822 293.328583,643.931458 
	C297.606812,646.390198 301.652130,649.145508 306.542419,650.453369 
	C309.473572,651.237244 311.634857,653.432983 312.952942,656.656128 
	C303.895264,652.487183 294.199371,649.026917 286.486847,642.055298 
	C285.399719,641.072571 284.098877,640.987854 282.366516,641.014404 
	C281.982849,641.019958 281.490265,641.010010 281.243896,641.006226 
	C280.465027,637.100647 277.012573,635.391968 274.326111,634.162048 
	C266.173218,630.429626 258.795013,625.524353 251.325394,620.703857 
	C250.228882,619.996155 248.913895,619.627075 247.338455,619.064331 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M204.012604,588.611084 
	C191.443512,584.465820 181.783142,576.017578 177.862717,563.568176 
	C174.482925,552.835632 170.324738,542.374817 167.226242,531.575317 
	C166.239655,528.136658 166.222748,524.383850 166.032867,520.760620 
	C165.712830,514.653992 166.464859,508.517761 165.075745,502.432098 
	C164.005127,497.741882 168.031097,493.614349 166.956390,488.394165 
	C166.935684,487.955139 166.966064,487.470886 166.976593,487.228516 
	C168.016464,486.039185 167.774414,484.530853 168.758484,483.226196 
	C170.819672,482.102997 171.001175,480.455688 170.958801,478.332092 
	C171.406128,477.485992 171.767090,476.916107 172.741440,476.873108 
	C172.706833,479.091156 172.377594,481.164032 172.048676,483.631653 
	C172.498077,484.747986 172.832016,485.502777 172.613190,486.781128 
	C169.790451,490.272614 169.621902,494.206757 169.568222,497.899750 
	C169.410446,508.753510 169.145065,519.791748 172.673126,530.151306 
	C174.883575,536.642029 176.151672,543.530212 179.524841,549.593628 
	C181.023605,552.287781 179.344330,556.177673 183.092819,558.356323 
	C183.549591,559.213013 183.588943,559.752136 183.229492,560.650757 
	C181.060471,566.611389 185.191727,573.582092 191.166809,577.012024 
	C196.065842,579.824280 200.960999,583.096924 204.012604,588.611084 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M173.182068,476.724365 
	C172.523972,477.265442 172.011841,477.512604 171.233688,477.861389 
	C171.151016,475.777832 171.242325,473.535675 174.395416,472.944458 
	C175.149750,474.437347 174.003250,475.339630 173.182068,476.724365 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M168.629883,483.070374 
	C169.038788,484.407532 168.927246,485.764832 167.288818,486.823181 
	C167.131393,485.612030 166.527359,483.984711 168.629883,483.070374 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M176.618866,468.993286 
	C176.933014,469.853333 176.667068,470.614838 175.358719,470.979279 
	C175.069229,470.126038 175.348236,469.376312 176.618866,468.993286 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M233.032532,823.327820 
	C234.131271,823.039978 235.062332,823.462769 235.849365,824.725952 
	C234.085648,826.563538 233.623505,825.009399 233.032532,823.327820 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M253.061371,828.386230 
	C255.835724,827.985046 258.708557,827.844543 260.925781,830.628174 
	C258.332703,830.424927 254.895691,832.559143 253.061371,828.386230 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M432.310791,726.428345 
	C427.500916,728.010071 424.987030,724.943420 422.019226,722.138428 
	C417.188385,717.572876 410.198364,716.652100 404.969391,712.385681 
	C398.127075,706.802917 389.505280,703.804626 382.334198,698.016113 
	C378.284668,689.574280 372.222870,683.615234 364.596649,678.417114 
	C358.355133,674.162842 352.189270,670.127686 345.190338,667.337341 
	C344.698517,667.141235 344.463745,666.300476 344.049927,665.399292 
	C342.595398,663.301208 340.431915,663.128052 338.262451,662.191772 
	C334.626556,657.203735 329.078308,655.473999 324.504822,652.385864 
	C316.239044,646.804504 307.480927,642.009399 299.479767,635.967712 
	C294.692627,632.352905 288.008850,631.786682 283.549866,627.315186 
	C281.041107,624.799377 277.646118,623.841797 274.754486,622.052429 
	C263.123566,614.855408 249.921295,610.210999 239.795029,600.545654 
	C238.171860,598.996399 236.040848,597.979248 234.072601,596.382812 
	C231.431000,593.175659 227.359711,592.697266 224.314697,589.915405 
	C218.498947,585.287292 211.817078,582.807373 205.961792,578.938904 
	C203.970032,577.622986 201.390945,576.758423 201.710831,573.315430 
	C204.825226,571.206299 206.908722,573.496399 209.140945,574.638306 
	C221.239822,580.827820 232.170135,588.994324 244.043716,595.531067 
	C253.056488,600.492737 261.427704,606.399841 270.475891,611.918884 
	C271.749054,612.659058 272.517548,613.515137 273.819031,614.169922 
	C275.228027,615.243835 276.291840,616.253540 277.990967,616.856934 
	C279.031158,617.322693 279.779846,617.678955 280.820740,618.145508 
	C281.822754,618.673950 282.346863,619.353516 283.391968,619.805664 
	C283.836151,620.048767 284.008636,620.154297 284.427368,620.434082 
	C285.263336,621.138672 285.879761,621.627747 286.922882,622.007080 
	C287.759277,622.382568 288.302338,622.670410 289.147491,623.064026 
	C289.820068,623.394409 290.200378,623.598083 290.867004,623.971130 
	C292.941376,625.494995 294.764465,626.798706 297.011780,628.072754 
	C298.005920,628.762146 298.571198,629.467957 299.678192,629.984131 
	C300.130432,630.274597 300.304749,630.398499 300.723785,630.724304 
	C301.863434,631.814514 302.924988,632.422485 304.411163,632.923828 
	C304.881592,633.148438 305.064117,633.249512 305.502686,633.528687 
	C306.660339,634.544678 307.632324,635.272766 309.102356,635.780273 
	C309.565674,635.989380 309.745361,636.083984 310.179077,636.343689 
	C311.061066,636.986267 311.632812,637.544617 312.637512,637.995972 
	C313.071472,638.233154 313.242218,638.333252 313.660004,638.596375 
	C314.369415,639.122864 314.842316,639.467346 315.711243,639.703796 
	C316.175049,639.881226 316.356079,639.964111 316.792175,640.202759 
	C317.358185,640.602356 317.650940,640.866333 318.133911,641.354858 
	C318.491180,641.685547 318.638947,641.812866 319.025940,642.110596 
	C319.615417,642.489380 319.973480,642.683594 320.642761,642.991333 
	C333.623016,650.296875 346.080231,657.847473 358.578613,665.327148 
	C361.285065,666.946716 364.112030,668.318115 367.193878,669.989868 
	C375.076782,676.777100 382.110687,683.790588 385.700562,693.507874 
	C386.802277,696.490112 389.214569,698.197510 391.857941,699.761902 
	C404.157593,707.041138 416.739624,713.855347 428.577209,721.898132 
	C430.168610,722.979370 432.272491,723.669678 432.310791,726.428345 
z"/>
<path fill="#060D0E" opacity="1.000000" stroke="none" 
	d="
M201.596802,572.421692 
	C204.245117,578.189697 209.852646,578.948914 213.890121,581.716431 
	C217.364868,584.098267 221.518738,585.457581 224.861359,588.689941 
	C223.877808,590.483459 222.221146,589.826050 220.378754,590.003479 
	C219.500015,589.978699 218.735336,590.150208 218.546890,589.895447 
	C214.773132,584.794434 207.837662,584.547974 203.639297,580.140564 
	C201.561691,577.959595 199.236511,576.118225 196.425659,574.997986 
	C189.959122,572.421021 185.584610,567.952637 183.355225,561.083740 
	C182.990738,560.560852 182.979645,560.124207 182.958954,559.359985 
	C179.564926,554.187744 178.601105,548.372314 177.116074,542.902527 
	C175.430878,536.695557 172.620499,530.921448 171.338974,524.528259 
	C168.976624,512.742981 168.407440,501.051880 170.263611,489.213135 
	C170.368454,488.544434 170.864639,488.046814 171.632690,487.263184 
	C171.952103,486.217621 171.979538,485.486450 172.027985,484.390808 
	C172.909882,484.452820 173.784195,484.655243 174.969818,484.115723 
	C176.644211,485.670135 176.651306,487.600311 176.400726,489.977661 
	C175.913406,491.877899 175.387253,493.372406 176.270935,495.299011 
	C176.585938,499.596527 176.442032,503.513733 176.425735,507.786438 
	C176.393387,509.215759 176.073792,510.299194 176.653961,511.643555 
	C176.856750,512.128357 176.932770,512.324951 177.100418,512.824768 
	C177.464218,514.133789 177.320618,515.232239 178.006958,516.467529 
	C178.813675,518.588684 179.995346,520.294495 179.521179,522.735596 
	C180.003876,524.776917 180.154541,526.598328 181.462219,528.377380 
	C181.757050,528.880737 181.861328,529.091431 182.085556,529.635986 
	C182.311584,530.432129 182.388901,530.899170 182.473740,531.724121 
	C183.264023,534.830139 184.169022,537.533997 185.203003,540.575012 
	C185.532654,544.564331 187.057648,547.845459 188.276184,551.584717 
	C188.500229,552.389343 188.652069,552.839783 188.943237,553.620117 
	C189.172623,554.414917 189.267975,554.875122 189.380478,555.683350 
	C189.258667,558.952087 189.629089,561.785461 191.113327,564.710754 
	C191.487030,565.128662 191.631927,565.301636 191.973328,565.752197 
	C194.138489,569.525208 198.133804,569.957581 201.596802,572.421692 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M217.209900,596.216980 
	C215.547150,595.037659 214.103241,594.067932 212.327576,593.052612 
	C211.509186,592.600708 210.948242,592.257751 210.899384,591.280640 
	C216.506973,590.274597 220.352341,594.016724 224.410309,596.488953 
	C236.409821,603.799194 248.069748,611.666748 259.936584,619.652100 
	C258.893799,619.687988 257.776947,619.388123 256.311249,619.058167 
	C255.527893,619.018616 255.093338,619.009094 254.332581,618.990234 
	C252.466919,617.830872 250.663605,617.023621 249.199310,615.248169 
	C244.425385,615.017395 241.268951,612.154968 238.117035,609.522278 
	C235.168930,607.059937 232.065491,604.903137 228.350037,603.043213 
	C226.541275,601.218811 224.563919,600.254761 222.230225,599.239746 
	C221.491699,598.754578 220.998367,598.494568 220.251221,598.118469 
	C219.259521,597.284241 218.177505,597.127075 217.209900,596.216980 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M220.242432,590.258240 
	C221.168732,588.631653 222.777954,589.102234 224.639221,588.995850 
	C227.659409,591.571777 232.533569,591.009277 233.957825,595.694214 
	C228.695999,596.156250 225.371490,591.432495 220.242432,590.258240 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M278.956787,630.651184 
	C274.230835,629.886292 271.001923,626.267883 267.063416,623.348999 
	C272.625275,622.729675 275.606537,626.780212 278.956787,630.651184 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M338.037933,662.380432 
	C340.922058,660.299072 342.816315,661.298767 343.953613,664.686279 
	C341.843231,664.752502 339.163208,665.833923 338.037933,662.380432 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M210.957642,590.671021 
	C211.269333,591.484436 211.521530,591.987000 211.884766,592.748230 
	C209.151199,593.050720 207.134293,591.387695 205.070740,589.339478 
	C205.446472,588.993469 205.889832,588.989441 206.665741,588.984863 
	C207.443314,588.991028 207.888336,588.994019 208.667145,588.989990 
	C209.885056,589.096741 210.640030,589.385376 210.957642,590.671021 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M264.960297,622.657410 
	C263.501007,622.895813 262.436676,622.240112 262.037598,620.348633 
	C263.502808,620.102295 264.562469,620.763550 264.960297,622.657410 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M285.848572,634.719788 
	C284.864685,634.949158 283.641083,635.089722 283.063843,633.344360 
	C284.128937,633.041504 285.061676,633.458740 285.848572,634.719788 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M208.844574,588.782532 
	C208.560135,589.628418 208.039764,589.946289 207.153015,589.193359 
	C207.444016,588.359253 207.965790,588.051025 208.844574,588.782532 
z"/>
<path fill="#05657D" opacity="1.000000" stroke="none" 
	d="
M432.154358,726.779663 
	C424.857086,720.411499 416.245728,716.073059 407.976440,711.203247 
	C401.958771,707.659424 395.956299,704.089233 389.908691,700.597229 
	C387.998474,699.494202 385.919708,698.378601 385.209381,696.297668 
	C381.748779,686.159851 374.542084,678.817444 367.102966,671.221619 
	C366.614197,669.238953 367.955139,668.576721 368.855347,667.669128 
	C375.622955,660.845947 382.376556,654.008484 389.219086,647.260437 
	C390.725159,645.775208 392.130981,643.949158 394.993042,644.732056 
	C395.983978,646.037537 395.951416,647.286377 395.352844,648.275574 
	C391.226013,655.095825 395.365021,661.099670 397.169037,667.315247 
	C397.679535,669.074158 398.992126,670.066895 400.955139,669.860413 
	C405.701965,669.361206 408.696899,672.313843 411.574493,675.359009 
	C413.169983,677.047424 414.431061,679.144531 416.453156,680.311035 
	C422.171906,683.609924 425.441376,688.946594 427.087708,694.848206 
	C428.517487,699.973572 433.480011,702.912109 434.145020,708.247986 
	C434.256958,709.146118 435.362885,709.327698 436.179688,709.371399 
	C439.427948,709.545105 442.168243,711.264587 445.162170,712.174805 
	C449.221588,713.408936 453.766846,713.261658 456.650757,717.241638 
	C457.340240,718.193176 458.510986,717.588318 459.385559,717.088989 
	C461.003540,716.165222 462.680054,715.505005 464.955505,715.677490 
	C465.823944,715.816467 466.292694,715.996521 467.028625,716.501465 
	C467.779846,717.407654 467.945679,718.144836 467.873657,719.302734 
	C467.087769,721.168640 466.327393,722.652832 465.886353,724.631836 
	C464.307678,726.759949 461.562714,727.993591 463.088226,731.439575 
	C463.233246,734.330994 462.684265,736.796753 462.202362,739.660095 
	C461.675018,741.064148 460.956787,741.826172 459.671631,742.580872 
	C456.943909,743.564697 455.264618,742.424561 453.783173,740.771423 
	C452.578156,739.426819 451.005981,738.569092 449.278656,737.278320 
	C448.988708,737.024719 448.669678,736.867554 448.492737,736.849915 
	C443.133331,733.575623 437.774719,730.613464 432.352722,727.121399 
	C432.143738,726.834961 432.136780,726.793884 432.154358,726.779663 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M432.144318,727.201660 
	C438.039001,728.939270 443.158691,732.357666 448.169952,736.519653 
	C442.229095,734.959900 436.784576,731.966553 432.144318,727.201660 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M449.054047,737.353149 
	C452.930023,737.187439 454.478394,741.462219 458.385498,742.349609 
	C459.746277,742.489990 460.629364,742.835083 461.489990,743.946533 
	C462.104889,745.568665 461.890198,746.740662 460.672302,747.998779 
	C460.125122,748.283447 459.880188,748.341187 459.313965,748.200684 
	C455.701599,744.562195 452.410492,741.121887 449.054047,737.353149 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M455.161774,757.971313 
	C455.667877,758.199097 455.694061,758.512085 455.537872,759.083130 
	C454.585815,760.808838 453.643585,762.290833 452.414673,763.905151 
	C450.493683,760.882446 453.176056,759.690552 455.161774,757.971313 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M458.935364,748.365540 
	C458.992706,748.002441 459.423462,747.990356 459.638916,747.988953 
	C460.276764,748.337402 460.474823,748.786255 460.254211,749.667542 
	C459.622498,750.895020 459.185028,751.789124 458.703674,752.980469 
	C458.820435,753.904663 458.584625,754.286743 457.559998,754.333496 
	C456.476715,752.013733 458.749786,750.703796 458.935364,748.365540 
z"/>
<path fill="#BDBCBE" opacity="1.000000" stroke="none" 
	d="
M457.161652,754.544556 
	C457.476227,753.988220 457.784637,753.733459 458.376434,753.378296 
	C458.896912,754.300598 458.897400,755.245300 457.463409,755.930664 
	C457.125671,755.634521 457.140564,755.240295 457.161652,754.544556 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M328.939819,666.662476 
	C322.579559,665.819458 318.082458,661.357483 313.061401,657.341675 
	C319.496857,658.056580 324.123962,662.298096 328.939819,666.662476 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M353.965759,680.640808 
	C351.212921,679.472717 347.452332,679.400330 346.039185,675.369629 
	C349.267944,675.822754 352.093781,677.304810 353.965759,680.640808 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M344.936493,674.652222 
	C342.056427,675.277954 339.768036,674.174133 338.060699,671.363403 
	C340.848694,670.931641 342.849915,672.635193 344.936493,674.652222 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M335.945190,670.650269 
	C334.872925,670.971436 333.941010,670.549011 333.151367,669.283691 
	C334.141724,669.040405 335.355499,668.918884 335.945190,670.650269 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M332.948792,668.683960 
	C332.116913,668.902405 331.364044,668.621765 331.042419,667.341248 
	C331.885315,667.100952 332.636658,667.384399 332.948792,668.683960 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M157.031158,556.362549 
	C158.125717,556.292847 159.220306,556.606323 160.656219,556.960571 
	C160.997559,557.001404 161.004120,557.496094 161.006073,557.743469 
	C165.016678,561.698059 165.026489,567.105225 166.764465,571.765625 
	C167.246201,573.057373 166.449814,574.325684 164.379669,574.216431 
	C160.411209,568.769104 159.383804,562.488892 157.031158,556.362549 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M164.019592,574.375244 
	C166.675217,573.454468 165.643997,571.813354 165.231216,570.226685 
	C164.199905,566.262268 162.837708,562.426819 161.052689,558.356506 
	C162.634842,558.094360 164.220627,558.956116 164.447830,560.349915 
	C165.437836,566.423401 170.014542,571.568054 168.998749,578.564087 
	C167.271347,578.995422 166.566116,576.806763 164.392944,577.003052 
	C164.034790,576.243652 164.024872,575.496399 164.019592,574.375244 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M168.863663,563.313843 
	C172.685440,565.542847 171.808792,570.213196 173.877869,573.536865 
	C174.527664,574.580750 174.570984,576.002197 174.939651,577.630615 
	C173.571442,577.919495 171.905548,577.203796 171.859497,576.058105 
	C171.682480,571.655518 167.948257,568.207764 168.863663,563.313843 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M174.991440,578.391602 
	C177.548157,578.831543 178.598846,580.798401 178.995941,583.631836 
	C175.989182,583.541321 174.912491,581.600403 174.991440,578.391602 
z"/>
<path fill="#6D6F71" opacity="1.000000" stroke="none" 
	d="
M169.258255,581.996826 
	C169.003281,581.550232 169.002304,581.105286 169.003708,580.326538 
	C169.876099,580.194763 170.962372,580.209961 170.221039,581.814270 
	C169.850876,581.997925 169.681549,581.998230 169.258255,581.996826 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M178.733032,574.227051 
	C185.018402,580.036987 190.976166,586.439209 198.685135,590.158447 
	C209.152939,595.208740 218.813965,601.618835 229.039536,607.059631 
	C233.861084,609.625122 238.998962,612.149170 242.931213,616.665894 
	C241.929474,617.001587 240.864288,617.000732 239.399551,617.000000 
	C238.557297,616.997559 238.114609,616.995117 237.340042,616.990967 
	C236.748535,616.495422 236.488907,616.001526 236.115356,615.252563 
	C235.103622,613.886292 233.639908,614.011597 232.256897,613.200562 
	C224.707001,607.580261 217.313843,602.325562 208.376373,599.006836 
	C206.651093,596.826294 204.682785,595.813049 202.301804,594.737427 
	C193.233139,590.640747 185.442474,584.623169 178.797424,577.178650 
	C178.079208,576.374084 177.907822,575.438232 178.733032,574.227051 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M161.004929,505.462463 
	C163.331024,505.468658 163.092331,507.332977 162.968536,508.926910 
	C162.035248,520.944336 165.535248,532.029419 168.584167,543.500671 
	C170.513641,550.760071 176.309723,556.735168 175.392914,564.953613 
	C175.173370,566.921387 177.651276,569.190125 178.919861,571.676086 
	C174.898849,570.811951 173.166718,567.857178 173.023834,563.854858 
	C172.901230,560.420837 172.052475,557.397583 169.493210,554.886414 
	C168.476089,553.888367 167.564072,552.462158 167.277222,551.090210 
	C164.160751,536.183777 159.158615,521.527344 161.004929,505.462463 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M255.851868,626.733765 
	C255.243103,627.000854 254.491409,626.996887 253.363525,626.985596 
	C253.486511,624.653198 254.500153,625.042908 255.851868,626.733765 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M232.168762,613.298035 
	C233.372940,613.157837 235.013336,612.524414 235.919006,614.649170 
	C234.589890,615.027100 233.226334,614.931091 232.168762,613.298035 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M243.112152,617.260376 
	C244.135742,617.053650 245.360504,616.913635 245.925415,618.671143 
	C245.547882,619.007263 245.104248,619.007202 244.327698,619.005981 
	C243.738373,618.508545 243.481949,618.012207 243.112152,617.260376 
z"/>
<path fill="#D9D7D6" opacity="1.000000" stroke="none" 
	d="
M590.259705,433.253632 
	C590.242249,430.448944 592.118408,428.622742 594.143433,428.627045 
	C601.705933,428.643036 607.127625,423.447876 613.650146,421.015350 
	C620.391541,418.501190 627.585083,418.760132 634.258789,416.121918 
	C643.215332,412.581177 653.058899,412.343262 662.200134,408.824707 
	C667.392334,406.826141 673.296448,405.629883 678.943848,404.807770 
	C682.168945,404.338226 685.178162,405.127625 688.859558,403.825256 
	C693.008667,402.357452 695.540833,398.926941 699.575867,397.681854 
	C701.411316,397.115509 702.857361,395.044525 704.594849,397.462524 
	C706.034180,399.465576 706.153320,401.330353 704.789551,403.846375 
	C700.809570,411.188965 697.855408,419.001007 696.002258,427.593872 
	C696.648987,428.900360 696.299927,429.779175 695.774536,430.955261 
	C694.047791,432.567383 692.864563,434.136993 692.110229,436.421692 
	C691.849915,436.950897 691.728821,437.157684 691.382324,437.651001 
	C688.743347,439.559418 689.991821,442.803070 687.870239,444.984436 
	C683.734863,447.935913 679.469360,448.843140 674.862000,448.484253 
	C669.120422,448.037048 663.291748,449.403320 657.302490,447.328644 
	C657.593506,444.355591 659.737793,445.055237 661.440796,445.022675 
	C666.572632,444.924500 671.717224,445.292145 676.785522,444.009094 
	C680.501526,443.068359 682.907227,441.025360 685.153381,437.734039 
	C687.987061,433.581757 689.884827,428.896271 693.163635,424.991974 
	C697.089600,420.317078 697.127258,414.191101 697.698364,408.498169 
	C697.902161,406.466370 695.870605,403.633331 693.100159,404.739502 
	C683.435059,408.598572 673.156311,409.694244 663.100403,411.633392 
	C655.800476,413.041046 648.886230,416.282715 641.267761,416.198730 
	C640.218262,416.187164 638.911133,416.833984 638.143494,417.598114 
	C632.732483,422.984772 625.594238,422.537079 618.973328,422.820221 
	C613.089783,423.071838 608.765930,424.976379 605.588074,429.793427 
	C605.142700,430.468658 604.156616,431.637054 604.095886,431.595337 
	C599.202881,428.230499 595.920410,432.958679 591.919250,433.925446 
	C591.510132,434.024353 590.988708,433.658630 590.259705,433.253632 
z"/>
<path fill="#D0CDCC" opacity="1.000000" stroke="none" 
	d="
M656.527588,446.994812 
	C661.476379,446.995087 665.962280,446.869141 670.437439,447.034515 
	C676.273010,447.250122 682.002258,447.129700 687.623901,444.047852 
	C688.238159,443.892273 688.478699,443.789429 689.048218,443.903748 
	C689.669067,444.576691 689.832520,445.074982 689.828003,446.016418 
	C689.063782,448.770996 686.809509,449.439606 684.697144,450.837769 
	C675.703430,450.345978 667.087708,451.627014 658.101562,450.722321 
	C655.969299,450.573761 654.221924,450.605225 652.135742,450.765076 
	C651.348572,450.966919 650.896118,451.036743 650.113342,451.092712 
	C648.952209,451.143616 648.130676,451.247040 647.061584,451.702576 
	C646.153748,452.000488 645.523560,452.064484 644.568115,452.059692 
	C643.183960,451.830170 642.184570,451.974182 640.966064,452.734436 
	C639.994263,453.084747 639.309753,453.178314 638.258545,453.225281 
	C628.527466,453.917908 619.370178,455.516907 610.514709,459.878052 
	C609.047119,460.638184 607.879456,461.174469 606.299927,460.200806 
	C604.171753,457.546112 601.216309,460.631256 599.150330,458.308167 
	C607.467712,454.662048 616.666321,454.130890 625.283020,451.366425 
	C635.311829,448.148865 645.446411,445.961884 656.527588,446.994812 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M715.001160,398.601868 
	C714.994812,399.440826 714.987244,399.879181 714.973328,400.646362 
	C712.856506,403.086548 712.425354,406.133850 710.522034,408.735413 
	C710.059448,409.060242 709.847961,409.150269 709.313354,409.120117 
	C708.686768,408.273712 707.918396,407.153015 708.149658,406.880493 
	C711.425842,403.019531 707.874084,397.839600 710.737671,393.899384 
	C711.286438,393.144318 710.984802,391.840149 709.931091,391.359283 
	C708.498840,390.705658 706.900940,390.845825 705.675415,391.749573 
	C702.998596,393.723541 700.067261,394.351349 696.411865,393.988281 
	C696.056519,391.612518 698.025208,391.337280 699.633179,390.523132 
	C704.726501,387.944305 709.715027,385.156311 714.833862,382.631226 
	C716.598816,381.760590 718.979248,381.148865 720.377747,382.840759 
	C721.772339,384.528046 719.290100,385.583832 718.721191,386.999939 
	C717.253906,390.652283 715.104675,394.093445 715.001160,398.601868 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M594.090454,419.384338 
	C599.248901,416.116547 605.225952,415.595367 610.891052,414.153503 
	C622.380920,411.229156 634.025574,408.917480 645.573059,406.211090 
	C647.620422,405.731232 649.470581,404.463013 651.477539,403.741791 
	C653.284058,403.092651 655.248291,402.048126 656.911255,404.627472 
	C656.809387,406.066681 656.258850,406.709625 655.101501,406.961334 
	C649.282471,408.226654 643.533325,410.013367 637.651550,410.731903 
	C632.288086,411.387177 627.364319,413.258179 622.352051,414.865784 
	C618.890198,415.976105 615.769470,417.895996 611.924866,416.273010 
	C610.535706,415.686584 609.634949,417.276123 608.505676,417.893219 
	C603.998535,420.356079 599.282227,421.620239 594.090454,419.384338 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M659.409546,404.991821 
	C660.166321,401.856262 663.458801,401.918182 665.810730,400.738373 
	C671.232971,398.018463 677.306030,397.296783 683.594849,396.012787 
	C683.078674,399.203430 681.428772,400.217163 677.697937,400.488922 
	C673.182678,400.817810 668.945984,403.807007 664.205750,404.431091 
	C662.748779,404.622925 661.291077,404.808594 659.409546,404.991821 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M694.754517,431.344604 
	C695.245300,430.194153 695.493591,429.389496 695.872253,428.293213 
	C700.387878,424.578735 700.643677,418.647308 704.112610,414.641907 
	C705.154358,413.439178 705.364319,411.509949 707.643188,411.043976 
	C708.375122,411.349518 708.669617,411.768616 708.948547,412.630249 
	C707.151611,418.633698 703.305420,423.286407 701.270386,428.868591 
	C700.561279,430.813721 699.707397,432.829742 698.339355,434.948730 
	C697.570190,435.498322 697.051758,435.619324 696.100098,435.472839 
	C694.406067,434.423309 693.925354,433.230316 694.754517,431.344604 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M687.077881,396.354462 
	C689.191528,394.594360 691.679199,394.229919 694.602417,394.000793 
	C692.898499,396.389587 691.072388,399.580353 687.077881,396.354462 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M708.376221,413.218506 
	C708.004211,412.557648 708.001160,412.110901 707.994141,411.329041 
	C708.304382,410.524048 708.618591,410.054260 708.961548,409.292236 
	C709.158142,409.000000 709.325989,409.000000 710.039062,409.310974 
	C711.163147,411.467499 711.192383,413.047760 708.376221,413.218506 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M643.386475,394.973389 
	C643.574951,392.421814 645.911377,392.275299 647.701538,392.145752 
	C655.383545,391.589783 662.446594,388.560333 669.753174,386.648682 
	C680.412354,383.859955 691.168640,381.523071 701.847046,378.846863 
	C702.648804,378.645905 703.427856,378.354095 704.606323,378.051544 
	C703.288452,382.025513 699.060364,381.811432 695.916077,382.800995 
	C686.401917,385.795105 676.497070,387.292999 666.857178,390.076508 
	C659.447754,392.215942 651.875732,395.208588 643.386475,394.973389 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M726.989380,392.618774 
	C726.485168,393.247223 725.994812,393.513428 725.245850,393.885986 
	C725.023926,391.192780 725.189758,388.454315 728.602905,387.018188 
	C728.303467,388.719818 727.653381,390.488159 726.989380,392.618774 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M707.330688,374.935791 
	C706.940674,373.270691 707.352173,371.986237 709.740906,372.057922 
	C710.094849,373.696442 709.667786,374.973938 707.330688,374.935791 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M715.724731,369.180786 
	C716.412720,368.946899 716.851074,368.931976 717.617554,368.921936 
	C717.914307,370.377045 717.071472,370.962769 715.374268,371.022949 
	C714.509460,370.305817 714.954834,369.842468 715.724731,369.180786 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M715.626709,368.975006 
	C715.726685,369.475159 715.479004,369.988525 715.127563,370.768005 
	C714.575806,371.039246 714.127747,371.044312 713.344482,371.031189 
	C713.102295,369.594116 713.952026,369.023529 715.626709,368.975006 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M718.686890,401.070282 
	C718.262085,407.364319 715.047668,412.431519 710.378601,416.982117 
	C708.197266,414.167175 710.850220,412.278992 711.757568,410.099396 
	C712.354004,408.666443 713.656067,407.515625 713.843933,405.438019 
	C714.115967,404.874481 714.258301,404.665894 714.710571,404.239563 
	C717.044800,404.108551 716.758911,401.533417 718.686890,401.070282 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M715.012329,403.641022 
	C715.020508,404.021790 714.999878,404.507568 714.991211,404.750549 
	C713.474670,406.421631 713.826111,409.666870 710.324524,409.231323 
	C710.018127,409.016846 710.007874,409.007263 710.002686,409.003021 
	C710.699646,405.961212 710.630066,402.514038 714.600464,400.995850 
	C714.979431,401.736847 714.991821,402.498566 715.012329,403.641022 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M708.691284,365.203430 
	C706.135559,369.117218 701.311646,369.638397 697.280762,370.706940 
	C678.812866,375.602814 660.190552,379.930054 641.574463,384.249176 
	C625.411743,387.999084 609.168579,391.400726 592.991760,395.091553 
	C587.743225,396.289032 582.576233,397.859467 577.406311,399.376556 
	C575.848999,399.833588 574.502991,400.122223 573.186768,398.359253 
	C584.331787,393.628754 596.353149,391.897705 608.091370,389.078125 
	C624.219238,385.204163 640.310547,381.114532 656.563354,377.841522 
	C673.997925,374.330536 690.999146,369.174225 708.691284,365.203430 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M716.812134,363.143921 
	C716.771790,364.587128 715.807861,365.027527 714.127014,364.748901 
	C714.265686,363.398041 715.237427,363.012665 716.812134,363.143921 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M702.694092,375.059601 
	C702.888306,375.909149 702.589966,376.666779 701.259033,376.911987 
	C701.098633,376.079346 701.407410,375.331207 702.694092,375.059601 
z"/>
<path fill="#06C4E6" opacity="1.000000" stroke="none" 
	d="
M515.061462,532.217896 
	C514.967590,532.528748 514.752808,532.494019 514.760132,532.385498 
	C514.204956,532.110718 513.734985,532.251953 513.298828,533.095215 
	C513.226929,533.843872 513.213745,534.198547 513.154419,534.817139 
	C514.363647,537.726379 518.706665,538.113342 517.943909,542.288879 
	C515.636597,543.526428 513.822693,542.289062 511.925262,541.908203 
	C508.547485,541.230103 506.153595,541.806335 505.389923,545.813293 
	C504.523804,550.357910 501.498718,553.772705 498.540283,557.134460 
	C497.121307,558.746887 495.556549,560.227905 494.298950,561.985596 
	C492.271729,564.819031 490.937195,567.376099 495.319244,569.509583 
	C497.543213,570.592285 498.613007,572.722595 497.604797,575.683777 
	C493.036804,577.757690 490.834473,573.789856 487.978729,572.052795 
	C485.326721,570.439758 482.896423,568.377869 479.791779,566.718262 
	C478.930603,566.066711 478.423553,565.543030 477.416595,565.093506 
	C475.821014,564.060730 474.910400,562.902649 474.117493,561.181396 
	C473.227448,554.200256 476.875580,548.669128 478.947845,542.868164 
	C481.837860,534.777954 482.062744,534.093018 474.930420,528.998718 
	C469.163635,524.879700 465.339752,518.917603 460.783783,513.655579 
	C459.375427,512.028931 458.227814,510.189362 456.307312,509.033875 
	C451.128143,505.917755 451.127838,505.468872 456.350159,501.490479 
	C458.851746,500.047302 461.259674,499.478516 464.069519,499.021484 
	C467.617584,499.483948 470.856506,500.300293 473.840942,497.387085 
	C475.018402,496.768372 475.955658,496.589539 477.270752,496.508972 
	C479.985443,496.454742 482.185211,495.828094 484.547668,494.487427 
	C485.321014,494.175720 485.786407,494.076324 486.619476,494.021851 
	C491.743530,494.324524 495.916016,492.310425 500.484863,490.465820 
	C501.453308,490.026642 502.143250,489.911804 503.193604,489.889709 
	C506.031494,490.543701 508.286835,489.922821 510.721588,488.450500 
	C511.483276,488.165405 511.943298,488.087128 512.769043,488.069214 
	C514.272583,488.389557 515.438293,488.620636 516.908203,489.080261 
	C522.226685,491.540955 528.142822,490.878204 531.821655,487.304535 
	C533.985168,485.397400 536.329407,485.030579 538.794128,484.983765 
	C541.296814,484.936249 543.644104,484.495117 544.950928,481.553314 
	C545.270874,480.746460 545.633789,480.416870 546.477539,480.142822 
	C548.280640,479.941620 549.730591,480.073303 551.502930,479.825317 
	C552.479919,479.559509 553.149109,479.514404 554.105225,479.279144 
	C554.813660,479.061768 555.219482,478.948059 555.921509,478.739319 
	C556.802429,478.485413 557.455505,478.540894 558.089111,477.815674 
	C558.231689,477.542938 558.202759,477.275482 558.319641,477.208923 
	C558.624451,477.252106 558.812317,477.361847 559.297302,477.334656 
	C559.998108,477.024109 560.402527,476.852203 561.127380,476.587158 
	C562.096436,476.320312 562.738098,476.111572 563.756226,476.098755 
	C567.204590,477.069672 569.859070,475.859161 572.789429,474.242157 
	C573.604309,474.053497 574.084290,473.996979 574.930664,473.882812 
	C577.439209,473.389740 579.600952,473.449280 581.744202,473.720642 
	C583.587463,473.953979 584.867065,474.952637 584.857178,476.961639 
	C584.846619,479.085175 583.382019,479.566925 581.558533,479.902008 
	C570.331482,481.964844 560.853333,487.751831 552.239136,494.786652 
	C546.756287,499.264252 547.347717,501.847931 553.228455,506.002289 
	C557.800903,509.232513 558.347656,511.927063 554.789246,515.414734 
	C553.369751,514.464539 552.516541,513.158752 550.888428,513.582703 
	C551.927551,513.731018 553.037415,513.958984 554.106445,515.111694 
	C555.072266,516.207764 555.103943,517.175415 554.760803,518.544434 
	C551.800842,522.244690 547.427063,522.254517 543.472046,523.946533 
	C542.198853,524.009094 541.287415,523.715698 540.049316,524.023804 
	C539.081970,524.283325 538.423706,524.376648 537.428955,524.453613 
	C535.557373,524.702515 534.135925,525.315063 532.378418,525.971985 
	C529.952698,526.805847 527.753845,527.239075 525.368958,528.201660 
	C523.374268,528.920410 521.539062,529.050171 519.658691,530.121338 
	C518.742126,530.490906 518.044739,530.502502 517.249390,531.142090 
	C516.371948,531.520813 515.673645,531.428650 515.061462,532.217896 
z"/>
<path fill="#04BBDC" opacity="1.000000" stroke="none" 
	d="
M553.999878,515.401306 
	C556.886169,512.147217 556.451477,509.408875 552.800476,507.455994 
	C545.066589,503.319244 544.394043,499.939484 550.469604,494.079803 
	C558.203369,486.620880 568.304688,483.122650 577.632385,478.353180 
	C578.438660,477.940918 579.572021,478.128418 580.554749,478.111176 
	C581.809692,478.089203 583.820251,479.654083 583.771240,476.863159 
	C583.733154,474.693604 581.783020,475.079895 580.358643,475.018219 
	C578.871033,474.953827 577.378845,474.996948 575.438904,474.986572 
	C574.361206,473.416412 575.789001,473.325104 576.868530,472.657349 
	C577.567444,472.410553 577.968872,472.277740 578.688721,472.128357 
	C581.745789,472.597412 584.195801,471.063293 587.203064,470.965485 
	C589.330994,470.163452 591.685669,471.285156 593.312805,469.016296 
	C593.905029,468.515656 594.301819,468.324127 595.057373,468.156921 
	C596.681335,468.210632 597.927124,468.086304 599.321106,467.106323 
	C600.333191,466.722748 601.048889,466.612122 602.171082,466.633270 
	C606.691589,467.282318 607.781372,469.803192 606.617004,473.498047 
	C605.174500,478.075562 605.963806,481.976074 608.445618,486.231506 
	C612.852234,493.787323 613.293030,501.460022 605.146484,505.547760 
	C603.973145,506.136505 603.068298,507.193573 602.031128,508.613770 
	C600.445557,510.121460 598.749329,510.182343 596.722656,510.470093 
	C594.491028,510.455109 592.663757,510.457764 590.471130,510.706970 
	C589.032104,510.634277 588.220520,511.181274 587.109131,511.989685 
	C580.813599,513.883789 574.779968,515.318298 568.668579,516.395996 
	C567.369629,516.625061 566.045227,516.614441 564.697144,517.850220 
	C561.209229,519.055908 558.061157,520.148193 554.347534,519.292725 
	C554.023254,517.940857 554.012329,516.870728 553.999878,515.401306 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M596.346069,468.950653 
	C595.571045,468.963348 595.115967,468.986664 594.320862,469.044189 
	C593.408386,469.805420 592.882996,470.708344 591.893921,469.309692 
	C592.007935,468.539551 592.062500,468.110931 592.124573,467.359222 
	C595.406433,467.383911 598.126221,465.194702 601.681458,465.085297 
	C602.609863,465.801666 602.703918,466.532196 602.103027,467.583252 
	C601.096741,467.885315 600.381775,467.926147 599.310608,468.002808 
	C598.349915,468.738953 597.562317,468.984924 596.346069,468.950653 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M588.264099,471.756317 
	C585.097351,471.494659 582.482666,473.973724 579.284912,472.360657 
	C581.943054,470.638458 585.151855,470.172852 588.690552,469.625916 
	C589.459351,470.437927 589.275024,471.065826 588.264099,471.756317 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M576.786377,472.453979 
	C576.541931,473.198944 575.917786,473.854492 575.141479,474.745056 
	C574.536804,475.021698 574.084473,475.063324 573.295654,475.158752 
	C570.964417,475.593018 569.138306,477.724335 566.923340,475.383667 
	C569.861145,473.285889 573.187134,473.018433 576.786377,472.453979 
z"/>
<path fill="#0ABFDE" opacity="1.000000" stroke="none" 
	d="
M566.579102,474.998108 
	C568.641724,477.245361 570.473389,474.647369 572.595886,475.155823 
	C570.564880,477.591919 567.784363,478.523865 564.214050,477.212463 
	C563.629089,476.630463 563.528015,476.252747 563.923584,475.653931 
	C564.876221,475.335907 565.554260,475.179535 566.579102,474.998108 
z"/>
<path fill="#24CCDD" opacity="1.000000" stroke="none" 
	d="
M555.727905,478.679016 
	C555.913147,479.359070 555.701843,479.604370 555.215637,479.915924 
	C554.565125,480.470917 553.966980,480.479675 553.198853,480.811096 
	C551.255005,480.994812 549.535217,480.999023 547.385681,480.990417 
	C546.628723,480.675812 546.535583,480.307678 546.903076,479.709015 
	C547.556396,479.427094 547.952087,479.318176 548.656738,479.202728 
	C549.491882,479.490509 550.031128,479.533051 550.750732,479.049377 
	C552.058105,478.523163 553.200562,478.228271 554.698730,477.901154 
	C555.166992,478.011475 555.279541,478.154053 555.727905,478.679016 
z"/>
<path fill="#06ABCA" opacity="1.000000" stroke="none" 
	d="
M588.363892,471.958344 
	C588.276367,471.348358 588.608215,470.752869 588.996460,469.910950 
	C589.769409,469.452026 590.486023,469.239624 591.578003,468.997711 
	C592.388306,469.738373 592.965271,469.391083 593.753601,469.123352 
	C593.976990,473.147552 590.891541,471.682861 588.363892,471.958344 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M546.806458,479.427185 
	C547.186829,479.865814 547.141602,480.185547 546.990479,480.736450 
	C546.522522,480.999023 546.089172,481.020355 545.339355,481.122528 
	C543.815125,481.294952 542.607361,481.386566 541.198669,481.253479 
	C542.557251,479.633972 544.613831,479.824097 546.806458,479.427185 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M563.850708,475.408844 
	C564.187622,475.824310 564.127502,476.147095 563.985107,476.704651 
	C563.200012,477.009277 562.447632,477.069885 561.324036,477.204834 
	C560.765015,477.374664 560.577148,477.470215 560.162231,477.785950 
	C559.668884,477.892120 559.402527,477.778198 558.786316,477.403351 
	C559.838806,475.737274 561.695679,475.600037 563.850708,475.408844 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M550.575073,478.766418 
	C550.646118,479.619904 550.126648,479.900421 549.143066,479.389496 
	C549.388611,479.035767 549.811523,478.875275 550.575073,478.766418 
z"/>
<path fill="#24CCDD" opacity="1.000000" stroke="none" 
	d="
M557.816223,477.452911 
	C557.733337,477.999817 557.235046,478.456726 556.400208,478.987549 
	C556.063721,479.061432 555.991211,478.979858 555.956482,478.937744 
	C555.807495,478.705994 555.693237,478.516357 555.661865,478.005066 
	C556.296753,477.576630 556.848816,477.469757 557.816223,477.452911 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M555.550049,477.647217 
	C555.792908,477.883270 555.841003,478.083069 555.905396,478.589233 
	C555.634277,478.759705 555.391418,478.567413 555.123779,478.093872 
	C555.054504,477.868927 555.355286,477.610962 555.550049,477.647217 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M606.008667,460.262756 
	C607.147766,459.786255 608.285828,459.625000 609.672729,459.223816 
	C618.766846,455.700836 628.112854,454.381012 637.597412,451.973145 
	C638.730164,451.919800 639.460571,451.920868 640.555908,451.905548 
	C641.618896,451.297485 642.425720,450.995026 643.669556,450.953125 
	C644.730103,450.924438 645.461548,450.921814 646.558472,450.906921 
	C647.381592,450.542206 647.839783,450.190857 648.711182,450.049866 
	C649.442749,450.032562 649.885803,450.030212 650.661133,450.028259 
	C651.171387,450.188477 651.349243,450.348328 651.675171,450.835541 
	C651.969360,451.899109 651.856140,452.584503 651.135498,453.420135 
	C649.822205,453.736816 648.796265,453.840485 647.382812,453.932892 
	C646.141357,454.003052 645.268005,453.975677 644.186279,454.525452 
	C640.342712,454.710297 637.076172,456.112091 633.277405,456.885925 
	C631.070496,457.139252 629.399780,457.902374 627.335327,458.478668 
	C625.667053,458.817413 624.298279,458.994202 622.515381,459.189331 
	C621.353088,459.797394 620.425476,459.974701 619.258789,460.470764 
	C618.492493,460.679382 618.034241,460.727692 617.230103,460.782196 
	C616.093262,460.980713 615.259644,460.987579 614.236572,461.483521 
	C613.502563,461.703613 613.036072,461.774445 612.212219,461.840149 
	C610.136780,460.022766 608.242188,462.768280 606.260376,461.418030 
	C606.017639,460.998230 606.012634,460.788116 606.008667,460.262756 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M602.814941,461.167999 
	C602.481873,463.447449 600.293335,463.193207 598.328003,463.928223 
	C598.328369,461.226105 600.205688,460.897186 602.814941,461.167999 
z"/>
<path fill="#06ABCA" opacity="1.000000" stroke="none" 
	d="
M602.213135,467.903503 
	C601.889282,467.167969 601.966858,466.491394 602.058899,465.475464 
	C602.502075,465.093811 602.930847,465.051483 603.683350,465.005127 
	C607.467651,467.344055 610.747559,464.942535 613.965637,464.350189 
	C619.330505,463.362701 624.668823,462.320068 630.545898,461.858551 
	C631.539978,462.235992 632.105103,462.743744 632.128967,463.404785 
	C632.449341,472.294128 636.529724,480.886963 634.129578,490.008453 
	C633.496460,492.414551 633.301758,494.968658 635.031738,497.589172 
	C635.218140,498.188232 635.249756,498.437805 635.108765,499.019043 
	C633.887146,500.469360 632.188477,500.199738 630.769531,501.365814 
	C630.259033,501.681549 630.044983,501.793121 629.490479,502.031830 
	C625.859802,502.710358 622.420105,502.699158 619.176331,504.888824 
	C613.206360,506.712097 607.607971,508.504547 601.364868,508.324860 
	C601.292236,505.775024 603.286865,504.713104 604.694580,503.576599 
	C610.641602,498.775177 611.787231,492.192474 606.773315,486.441010 
	C602.929871,482.032227 603.200439,477.537689 605.261841,472.771271 
	C606.555603,469.780182 605.629272,468.392181 602.213135,467.903503 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M759.282104,951.926941 
	C759.088379,951.082947 759.402649,950.342346 760.689148,950.052490 
	C760.902344,950.905945 760.603333,951.658691 759.282104,951.926941 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M708.040649,955.729614 
	C710.180786,954.616821 712.227417,953.820496 714.643433,953.031433 
	C711.473450,959.323303 705.403809,961.759949 698.964844,963.746765 
	C690.640503,966.315369 683.395142,971.518616 674.703613,973.357849 
	C666.578003,975.077393 659.795959,972.459290 653.066650,968.486877 
	C650.051575,966.706909 652.206177,962.984680 649.424194,961.063477 
	C649.056458,960.218140 649.040100,959.477295 649.026550,958.365479 
	C648.994385,957.366943 649.114685,956.782898 649.772095,956.116516 
	C652.686096,956.390869 653.231384,958.927612 653.977234,960.684753 
	C657.524658,969.041809 665.242615,969.411316 672.241028,969.633484 
	C677.101746,969.787781 682.255798,968.313599 686.633728,965.063354 
	C690.408081,962.261292 695.712402,963.636963 699.806274,961.226562 
	C702.581238,959.592773 706.238708,959.356445 708.040649,955.729614 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M715.727661,941.239136 
	C716.247437,941.185913 716.622131,941.305115 716.783081,941.559570 
	C723.285034,951.836243 731.507385,954.534363 742.148743,948.224304 
	C748.265930,944.596924 755.550110,946.174133 761.569519,942.033386 
	C761.352844,948.583740 755.548645,950.996643 751.075928,950.921082 
	C745.702698,950.830261 742.148865,953.663086 737.941406,955.311829 
	C722.593384,961.326599 717.724548,953.904175 715.727661,941.239136 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M714.605103,936.968994 
	C715.179626,937.508057 715.487488,938.028076 715.723999,938.862000 
	C715.280579,939.124390 714.908508,939.072937 714.253784,939.013062 
	C713.955200,938.552612 713.939392,938.100525 713.864136,937.313599 
	C713.982605,936.969177 714.160583,936.959534 714.605103,936.968994 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M673.684265,824.768555 
	C674.000000,826.392090 674.000000,827.779724 674.000000,829.583679 
	C674.000000,830.000000 673.501221,830.001221 673.251831,830.001831 
	C661.649109,831.697510 651.405762,836.988831 640.482788,840.092041 
	C638.134094,840.759399 636.097168,842.413696 633.411438,841.325928 
	C631.726807,840.643677 630.731018,842.092163 629.495422,842.907776 
	C626.415833,844.940674 623.249756,846.754700 619.280457,845.301819 
	C618.394653,844.977539 617.040344,844.760803 616.368225,845.203247 
	C608.156616,850.608459 597.999695,848.763367 589.339783,852.544312 
	C587.108337,853.518616 584.527100,851.306458 582.025635,852.188354 
	C579.048096,853.237976 576.093994,854.354004 573.118652,855.409790 
	C569.788269,856.591553 566.744507,859.246338 562.780212,857.258728 
	C562.227722,856.981750 560.999023,857.353149 560.468323,857.842102 
	C555.533325,862.389771 549.157166,861.717163 543.251404,862.763184 
	C541.549927,863.064575 539.928040,863.044006 538.408997,862.178162 
	C536.726807,861.219299 535.338440,861.041809 534.371460,863.163635 
	C533.880737,864.240295 533.053345,865.410461 531.716064,864.398865 
	C530.634766,863.580811 531.124268,862.331543 531.530334,861.186584 
	C532.918640,857.272400 532.165649,856.349060 528.015869,857.238220 
	C525.147217,857.852905 522.782410,858.233948 521.202148,862.135803 
	C519.977478,865.159607 516.835693,867.515381 516.315979,871.546021 
	C516.077087,873.398987 513.811584,873.417236 512.901001,871.061523 
	C511.781494,868.165283 510.175354,865.744385 506.961670,864.800598 
	C506.171692,864.568604 505.377197,864.054626 505.567505,863.033386 
	C505.767365,861.960876 506.796753,862.131897 507.557648,861.927490 
	C512.341919,860.642395 514.172607,857.376648 514.026001,852.603333 
	C513.904541,848.647400 513.548767,844.650940 516.274719,841.145996 
	C516.985901,840.231689 517.219788,838.496460 516.927063,837.324463 
	C515.085999,829.951965 518.510803,823.557556 520.535583,816.927368 
	C521.902649,812.450989 517.425293,810.027771 517.104553,805.980042 
	C516.840698,802.650024 517.682922,798.321716 513.356323,796.218750 
	C512.823608,795.959839 513.274780,794.690552 514.011353,794.095581 
	C516.872253,791.784424 515.702515,790.605042 512.818237,789.943298 
	C511.922272,789.737793 511.155640,789.284729 511.384735,788.315735 
	C511.499481,787.830261 512.218933,787.240662 512.749634,787.138611 
	C518.417542,786.049316 515.825623,782.352478 515.114136,779.422546 
	C514.696716,777.703430 512.767090,776.485840 513.038086,774.467163 
	C513.682617,769.665710 512.409363,767.164734 506.985046,768.923218 
	C505.965668,769.253601 504.505981,769.071228 504.286743,767.486450 
	C504.130646,766.357910 504.985352,765.781433 505.867767,765.198242 
	C511.024445,761.790710 510.672821,761.736633 507.054535,756.740784 
	C504.829803,753.669067 501.246704,750.786743 503.663147,745.518433 
	C505.230011,742.102295 500.522369,739.578430 499.355469,736.087891 
	C493.475250,734.863586 489.916229,727.871826 493.127197,723.544006 
	C495.546783,720.282898 494.690857,718.142517 492.355804,715.764587 
	C490.787323,714.167236 489.436798,712.389160 489.963959,710.033508 
	C490.907990,705.815247 489.156128,702.093689 487.520905,698.603699 
	C484.948853,693.114380 483.824341,687.373657 484.776642,681.640442 
	C486.157837,673.325378 482.457825,666.290527 479.932404,659.084229 
	C477.567139,652.334900 472.124939,647.102295 467.589935,641.715698 
	C464.377106,637.899658 459.391968,634.760010 454.051117,633.864441 
	C450.643799,633.293213 447.655121,631.373840 443.956085,631.591858 
	C441.361572,631.744812 440.731354,629.080200 440.872253,626.756104 
	C441.360260,618.705322 436.985474,612.549927 431.880219,607.496521 
	C425.809265,601.487305 420.638031,594.947754 415.872070,587.963318 
	C410.276886,579.763672 402.367218,573.782104 395.506226,566.791687 
	C392.101166,563.322388 387.960541,560.170532 387.494263,554.395752 
	C392.026733,552.439026 394.356079,555.447571 396.603851,558.241821 
	C398.760681,560.922974 400.257172,564.111755 403.123352,566.665405 
	C408.814148,569.025757 412.873993,572.691406 416.289642,577.387573 
	C421.641998,584.746460 427.984955,591.324890 433.567993,598.516785 
	C435.501801,601.007935 437.681793,603.447388 440.573730,604.751587 
	C446.087067,607.238037 449.359650,611.783203 451.855133,616.851746 
	C453.850769,620.905090 456.692261,623.891907 460.651489,626.525208 
	C463.929321,629.165710 467.584320,630.432068 470.958801,632.003845 
	C476.153473,634.423584 479.889648,638.149963 482.026855,643.039673 
	C485.797791,651.667236 490.113159,660.119934 492.581421,669.266052 
	C495.434631,679.838379 496.984528,690.771606 500.498840,701.133667 
	C502.826599,707.997192 503.657623,715.343689 507.185669,721.812378 
	C507.821259,722.977722 508.173615,724.327576 508.242889,725.649841 
	C508.466797,729.923645 509.280426,733.860229 512.929260,736.719788 
	C513.962646,737.529785 514.716675,739.016357 514.562500,740.271057 
	C513.613586,747.992798 517.593262,754.520874 520.074951,761.210144 
	C522.862854,768.724854 522.599609,776.566833 524.376343,784.149719 
	C525.856995,790.469177 526.712830,796.867371 529.932861,802.650452 
	C532.307129,806.914551 531.357971,811.780701 531.969299,816.347961 
	C532.330566,819.047363 532.536499,821.572876 534.195618,823.802795 
	C534.591431,824.334839 535.009155,824.969055 535.026367,825.602966 
	C535.259399,834.141846 540.319214,839.762390 546.225830,845.134705 
	C549.602600,848.206055 553.005676,850.322510 557.666809,850.335266 
	C561.696716,850.346375 565.687378,849.921509 569.566895,849.045776 
	C574.310120,847.975037 579.180908,848.290100 583.798340,847.612976 
	C590.050842,846.696106 596.502441,845.470581 602.516602,843.063965 
	C603.589294,842.634766 604.584473,841.681213 605.717651,841.746765 
	C614.099182,842.231201 621.579773,838.260437 629.605957,837.038147 
	C634.234009,836.333313 638.836487,835.551147 642.833984,832.875244 
	C644.787903,831.567505 647.082642,832.327942 649.140198,831.739197 
	C653.298340,830.549561 657.506409,829.540283 661.426208,827.591614 
	C665.165894,825.732361 669.110901,824.469177 673.684265,824.768555 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M670.670349,876.150085 
	C671.462036,875.995422 671.924072,875.993347 672.731567,876.017273 
	C673.030334,876.767944 672.983643,877.492493 672.840942,878.568848 
	C665.679016,883.165710 662.665833,889.341492 663.829346,897.537964 
	C664.013000,898.832031 664.146729,900.162109 663.207825,901.650024 
	C662.524292,902.230957 662.081665,902.550232 661.393311,902.994507 
	C660.485291,903.508850 659.961304,904.064148 659.192627,904.770142 
	C657.971008,905.957214 656.956970,906.948914 655.676453,908.083740 
	C655.140137,908.307800 654.870361,908.388611 654.277588,908.197144 
	C655.377197,903.867554 654.701477,899.564392 655.265381,895.515991 
	C656.485474,886.756287 659.052795,879.504944 670.670349,876.150085 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M653.621765,908.001099 
	C654.198547,907.968323 654.442444,908.011780 655.048218,908.141113 
	C653.621521,911.891602 651.020386,915.173462 650.036255,919.618652 
	C648.229126,922.520264 648.277100,925.585999 647.224609,928.701904 
	C646.964355,929.715515 646.910889,930.420471 646.843750,931.478760 
	C646.616821,932.442078 646.147278,932.683838 645.260864,932.678589 
	C644.819397,932.528076 644.658142,932.428101 644.401489,932.059937 
	C644.657166,929.342346 643.749084,927.119507 642.981689,924.475464 
	C646.333313,922.666870 644.612915,919.107849 645.919128,916.407715 
	C648.560120,913.540771 649.950439,909.903687 653.621765,908.001099 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M542.000793,895.750610 
	C540.697266,895.834412 539.282043,895.938171 538.113831,895.450378 
	C535.135681,894.206848 533.456665,894.287292 534.557495,898.175293 
	C534.846680,899.196655 535.004272,900.256104 533.829956,900.705627 
	C532.562805,901.190735 531.190430,901.186523 530.067932,900.237061 
	C528.366455,898.797974 529.628479,897.195496 529.890625,895.645935 
	C530.143127,894.153320 530.479309,891.971985 532.155396,892.386108 
	C535.289246,893.160339 538.849365,891.942566 541.804077,894.677124 
	C542.002441,895.002441 542.001587,895.501221 542.000793,895.750610 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M551.658569,887.586731 
	C552.373413,887.387878 552.633667,887.637390 552.521790,888.193054 
	C552.498657,888.308350 552.334778,888.463074 552.218079,888.480347 
	C551.657104,888.563354 551.422546,888.290039 551.658569,887.586731 
z"/>
<path fill="#071518" opacity="1.000000" stroke="none" 
	d="
M608.985596,937.786011 
	C606.694336,938.078796 605.585144,937.025574 606.106567,935.267151 
	C607.519287,930.502747 609.025146,925.211121 614.319763,923.708313 
	C619.308289,922.292297 624.804138,921.518250 629.305786,925.776672 
	C633.377991,929.628906 633.086365,933.593140 627.831482,935.171265 
	C624.224854,936.254395 620.514526,939.129089 616.206970,936.574951 
	C617.106018,933.940369 620.019470,934.597778 621.350830,932.835205 
	C617.385193,928.361450 612.526489,929.127258 610.666077,934.530457 
	C610.291260,935.619019 610.169006,936.801392 608.985596,937.786011 
z"/>
<path fill="#060F14" opacity="1.000000" stroke="none" 
	d="
M614.327271,952.161133 
	C612.801208,951.752625 610.923340,952.701782 610.608459,950.942383 
	C610.299194,949.214417 612.042908,948.683289 613.452332,948.075256 
	C620.634827,944.977051 628.437927,944.296631 635.890259,942.255676 
	C637.506287,941.813110 639.112854,941.530334 640.686523,940.148438 
	C641.995667,939.771179 642.407776,940.383362 642.735596,941.572754 
	C642.703857,943.065674 642.312866,944.044678 641.401978,945.215027 
	C634.741211,947.416687 628.746155,950.508118 621.784668,951.896851 
	C620.938171,952.198608 620.438293,952.304504 619.547363,952.385132 
	C617.664368,952.394897 616.156067,952.653809 614.327271,952.161133 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M608.916138,938.109314 
	C608.189697,928.033569 615.061646,926.512451 621.627747,929.937256 
	C622.902954,930.602356 624.640076,931.274170 624.372253,933.028809 
	C624.148438,934.494568 622.425781,934.608887 621.196106,934.987793 
	C619.780090,935.424133 618.329224,935.747375 616.510620,936.179199 
	C613.825928,936.977844 611.970398,939.197693 608.916138,938.109314 
z"/>
<path fill="#515456" opacity="1.000000" stroke="none" 
	d="
M618.916260,951.489441 
	C619.626465,951.323853 620.019165,951.298218 620.706909,951.263428 
	C627.520813,950.734802 633.305054,947.864685 639.623901,945.646240 
	C640.125061,945.952393 640.264465,946.160156 640.294617,946.726929 
	C640.185303,947.085938 640.073303,947.534485 640.019165,947.759277 
	C636.153564,953.245544 630.459961,954.398987 624.540161,954.969177 
	C622.598389,955.156250 620.595276,954.800476 618.367432,955.787048 
	C616.938538,955.798950 615.900940,955.774170 614.477661,955.688232 
	C613.959900,955.444702 613.827820,955.262451 613.830933,954.770752 
	C613.954773,954.125183 613.943359,953.789001 613.962524,953.200439 
	C615.325867,951.801819 617.112061,952.238464 618.916260,951.489441 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M642.497864,942.211731 
	C641.857910,941.549316 641.663147,940.966248 641.236328,940.175781 
	C641.574280,937.229553 639.421997,933.633728 643.909180,931.897461 
	C644.306213,931.791687 644.526062,931.680176 644.648071,931.697510 
	C645.695312,934.943542 646.171021,938.155701 644.090942,941.598022 
	C643.489868,942.037048 643.216431,942.164124 642.497864,942.211731 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M618.851685,951.247070 
	C618.306213,953.434692 616.403381,952.852783 614.377319,952.966492 
	C613.992981,952.948181 613.995117,952.464111 613.996338,952.221924 
	C615.410339,951.283936 616.875122,950.866821 618.851685,951.247070 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M628.234558,960.943970 
	C627.462585,959.809631 627.276184,958.706421 628.780273,958.280457 
	C629.676758,958.026550 631.039856,957.333862 630.750732,959.619263 
	C629.886780,960.247986 629.188171,960.600464 628.234558,960.943970 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M613.656860,954.633606 
	C613.992554,954.656494 614.018921,954.851562 614.068604,955.336792 
	C614.056030,955.886902 614.020020,956.146790 613.914734,956.794800 
	C613.051941,956.521973 612.607056,955.788025 613.656860,954.633606 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M618.215088,956.102417 
	C618.837830,954.361633 620.209167,954.023193 621.784241,954.012634 
	C628.243164,953.969299 634.060303,951.941040 639.647522,948.120850 
	C639.989807,948.431763 640.014587,948.879578 640.027710,949.663940 
	C639.985046,950.601135 639.781799,951.131409 639.139893,951.753418 
	C632.474243,955.179382 625.657043,956.666687 618.215088,956.102417 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M639.174683,951.951416 
	C639.120483,951.400146 639.333069,950.870850 639.780884,950.171021 
	C640.649048,950.451111 640.907715,950.984680 640.183472,951.841431 
	C639.798096,951.986206 639.619751,951.979858 639.174683,951.951416 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M643.007385,942.653564 
	C644.537048,939.228882 644.014771,935.854736 644.588379,932.157593 
	C645.284241,931.762695 645.798462,931.810486 646.571350,931.845215 
	C647.073730,938.992798 647.694214,946.116150 648.941162,953.596069 
	C646.676697,954.689148 646.567688,952.434021 645.280884,951.222595 
	C644.249634,950.403381 644.047119,949.575195 644.052124,948.323608 
	C643.866150,946.265625 642.731018,944.760864 643.007385,942.653564 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M642.854492,942.325439 
	C644.208801,943.912598 645.753052,945.349976 644.287354,947.761597 
	C642.985413,947.682312 641.917358,947.374207 640.517334,947.075989 
	C640.121460,946.891663 640.057617,946.697327 639.921570,946.204956 
	C640.011108,945.631348 640.201965,945.377930 640.288452,944.950317 
	C640.630920,944.031189 641.106934,943.308167 641.817749,942.358765 
	C642.228149,942.188354 642.403687,942.244446 642.854492,942.325439 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M645.080444,951.318909 
	C646.888977,951.004028 647.150574,952.940491 648.661377,953.859375 
	C649.224731,954.495361 649.506775,954.976440 649.911133,955.707275 
	C649.850220,956.511292 649.666931,957.065674 649.256470,957.807251 
	C646.596558,956.585388 646.038391,954.010864 645.080444,951.318909 
z"/>
<path fill="#CDCCCD" opacity="1.000000" stroke="none" 
	d="
M388.087830,553.630371 
	C390.171112,560.748291 395.738617,564.887634 400.464508,569.500916 
	C407.331329,576.204163 414.132568,582.665588 419.618744,590.818787 
	C423.420959,596.469421 428.492706,601.766479 433.475311,606.856140 
	C438.474030,611.962158 444.202728,618.411804 442.158142,627.281372 
	C441.884247,628.469360 442.121765,629.629883 443.227203,630.382263 
	C444.466553,631.225830 445.368011,630.674133 446.199188,629.641235 
	C446.806580,628.886475 448.140900,627.798096 448.476959,629.026123 
	C449.513702,632.814697 452.789032,631.090698 454.786011,632.179321 
	C460.498749,635.293396 467.148682,636.920776 470.879791,643.353577 
	C473.740997,648.286621 478.863831,651.930481 480.823273,657.410278 
	C483.530426,664.981140 487.703217,672.155273 486.378082,680.925476 
	C485.483032,686.849304 486.278076,692.682861 489.377075,698.508667 
	C490.842163,701.262817 494.160217,705.237000 491.426910,709.646362 
	C490.162994,711.685303 491.758850,713.296082 493.413147,714.680420 
	C497.641388,718.218506 497.280029,719.900635 494.045715,724.441711 
	C491.581848,727.901123 494.979156,731.059265 496.497498,734.124329 
	C496.810608,734.756348 497.851227,735.028015 498.778259,735.732910 
	C498.080627,737.670532 497.611755,739.478394 498.287506,741.282410 
	C499.273621,743.914856 499.209717,745.623474 495.874512,746.359558 
	C493.933868,746.787781 493.304382,749.108948 494.514832,750.391235 
	C498.149353,754.241577 495.587982,759.727478 498.679016,763.941711 
	C500.178864,765.986511 499.947845,770.465210 494.722382,770.146729 
	C494.043762,770.105347 493.132690,770.712830 492.639771,771.286499 
	C488.455994,776.155151 484.905457,776.497925 479.794403,772.293518 
	C477.902802,770.737610 475.842651,770.065613 473.404846,769.927795 
	C470.198181,769.746643 469.353607,768.210083 471.112854,765.274963 
	C472.202972,763.456177 474.691925,761.027832 471.612946,759.405396 
	C469.125549,758.094604 466.422882,758.805420 465.830200,762.600464 
	C465.455322,765.000793 463.697968,765.310120 461.330536,764.146667 
	C460.168640,763.299622 459.817932,762.361450 460.195251,761.381287 
	C461.261871,758.610291 461.544434,753.631104 460.384766,750.074829 
	C460.050140,749.548584 460.040283,749.096191 459.942444,748.315674 
	C460.338348,746.925903 461.476685,746.106934 460.956360,744.367432 
	C460.346985,743.036194 460.748688,742.190063 461.425018,741.058777 
	C464.032990,736.526367 463.694611,731.643127 464.910309,726.788879 
	C465.513397,725.567200 466.291168,724.754211 466.021240,723.253296 
	C466.030396,722.041321 466.028168,721.150391 466.910889,720.215088 
	C467.281189,719.281921 467.375244,718.541992 467.412445,717.431458 
	C467.094330,716.391357 466.802490,715.731018 466.737366,714.646118 
	C468.138580,710.149353 466.723328,706.206055 465.713318,701.932617 
	C465.357452,701.235229 465.216034,700.813782 465.055359,700.057861 
	C464.888641,698.886597 464.757538,698.041931 464.277832,696.939697 
	C464.044159,696.180786 463.963074,695.729187 463.894592,694.914795 
	C462.583160,685.122864 458.375000,676.527588 455.998199,667.398132 
	C455.343842,664.884766 454.950012,662.195251 453.300659,659.591736 
	C452.743652,658.340332 452.609283,657.361694 452.421173,656.029968 
	C452.338837,651.972046 450.457489,649.865234 447.085144,648.300354 
	C443.418274,646.598877 439.801117,644.595947 436.644897,641.410767 
	C436.415894,640.870056 436.360352,640.639221 436.302032,640.053467 
	C437.046661,637.064575 435.323669,636.274719 433.341919,635.389526 
	C428.121796,633.057983 428.064636,632.401489 429.189514,626.586060 
	C430.708984,618.730835 426.915985,612.089233 422.789886,606.161621 
	C418.621674,600.173523 414.409210,594.197205 410.242798,588.195557 
	C408.821320,586.147888 407.462769,583.845703 405.288544,582.626343 
	C396.286346,577.577881 389.697876,569.819275 382.468231,562.770752 
	C380.499664,560.851501 378.202881,559.653931 375.362152,559.554810 
	C371.854919,559.432556 368.392609,558.804138 365.004608,557.966797 
	C362.292023,557.296387 359.822174,556.971436 357.443970,558.864807 
	C356.212006,559.845642 354.656952,560.047424 353.154327,559.590637 
	C349.278046,558.412231 346.766632,560.092773 344.835785,563.287292 
	C344.056702,564.576233 342.903564,566.371277 341.648895,566.265930 
	C335.842651,565.778687 332.825623,570.415466 328.262085,572.904724 
	C326.917786,573.095154 325.984436,572.939453 324.885437,572.143311 
	C323.892944,570.670532 323.456238,568.955994 321.039795,569.895874 
	C318.774048,570.008362 317.135834,569.181702 315.253601,568.059692 
	C313.925995,567.143860 313.442352,565.757385 311.780945,565.285828 
	C310.691864,564.847473 309.948608,564.429138 308.933807,563.847961 
	C307.981445,563.228027 307.417816,562.581543 306.275024,562.303345 
	C305.785675,562.137390 305.594055,562.059509 305.127960,561.837891 
	C304.141907,561.299500 303.631226,560.617554 302.593262,560.159241 
	C302.148376,559.906006 301.973969,559.796509 301.546967,559.506958 
	C300.672394,558.743469 299.959656,558.313965 298.827393,557.928833 
	C295.435364,556.347534 292.939606,553.910217 289.569824,552.882812 
	C287.608124,552.284790 286.448151,550.583618 287.003967,547.972290 
	C290.337311,545.753784 293.035339,548.084778 296.269897,548.613708 
	C301.294037,551.244690 305.441254,550.980225 309.124023,546.297180 
	C311.721527,545.832886 313.736450,546.616882 315.610931,547.678711 
	C328.064056,554.732544 340.516724,553.342102 353.318085,547.575317 
	C354.355774,547.047424 355.107849,546.909424 356.259399,546.884644 
	C359.244110,547.563477 361.845520,548.129578 364.640930,546.249878 
	C365.718018,545.894226 366.466797,545.846680 367.599030,545.926880 
	C369.383942,546.308167 370.759430,547.075134 372.260803,546.517212 
	C378.496552,544.199707 383.351837,545.787842 387.062195,551.785889 
	C387.747253,552.351501 388.014923,552.772095 388.087830,553.630371 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M474.341492,916.927185 
	C468.974396,920.781799 462.880280,920.956970 457.046021,921.967590 
	C455.749603,922.192139 454.305634,921.744812 453.067780,922.095093 
	C448.883453,923.279114 445.924408,923.656921 448.781525,917.783203 
	C449.495605,916.315125 448.447449,915.057739 447.088379,914.033203 
	C443.700470,911.479309 443.642609,908.775818 447.325592,906.117676 
	C451.158997,903.351074 445.958008,900.912415 447.442474,898.036499 
	C448.179932,896.607727 445.742676,896.078308 444.210175,896.014465 
	C443.544739,895.986694 442.877075,896.016296 442.211060,895.997437 
	C434.478516,895.778687 431.660217,891.685181 434.353973,884.566101 
	C434.839111,883.283936 435.451721,882.069885 437.131500,882.171570 
	C438.689575,882.265930 438.834534,883.434814 439.013885,884.642151 
	C439.335205,886.805054 439.779755,889.735229 442.219330,889.557007 
	C444.693268,889.376282 448.608673,890.426575 449.304138,885.947815 
	C449.403503,885.308044 450.421448,884.280640 450.947937,884.319946 
	C455.884888,884.688965 456.396515,880.654541 457.563202,877.574707 
	C458.577332,874.897644 459.421936,872.690918 462.936981,872.866882 
	C464.552246,872.947693 465.756927,871.419312 463.957092,870.384033 
	C457.537354,866.691345 459.743744,860.760437 459.736572,855.442017 
	C459.732758,852.633606 460.238251,849.919189 462.394470,847.646179 
	C463.128082,846.872864 462.827637,845.118591 462.999847,843.406250 
	C462.931946,840.150024 465.924316,837.112305 462.238312,834.234863 
	C464.010223,827.549866 461.059540,823.142517 456.079803,819.545837 
	C453.721039,817.842224 453.941620,815.297729 454.000000,812.411865 
	C454.744568,810.896729 456.614014,810.492126 456.052551,808.305176 
	C459.340576,803.465332 457.000854,798.940979 455.976257,794.413696 
	C455.500092,792.309631 453.781830,790.143860 456.639404,788.091858 
	C459.303833,789.662720 458.948700,792.413391 459.740540,794.695007 
	C460.058380,795.610840 460.197479,796.767517 461.475403,796.770447 
	C462.178253,796.772034 462.558136,796.074585 462.766968,795.437805 
	C463.104095,794.409607 463.122498,793.416077 461.851288,793.025940 
	C460.074493,792.480591 458.758545,791.693237 459.760773,789.249390 
	C463.102692,788.973511 463.541809,787.429321 462.291382,784.950073 
	C461.576324,783.532349 460.827942,782.131348 460.047272,780.361389 
	C462.962860,780.088684 466.047516,779.481079 468.691101,781.437256 
	C469.356293,781.929504 470.411621,782.477356 470.484772,783.094788 
	C471.072998,788.059143 473.847656,790.871277 478.485565,792.457825 
	C479.483643,792.799194 480.530914,794.941101 478.889923,796.281738 
	C477.366547,797.526367 476.114349,796.676331 475.161346,795.266296 
	C474.419403,794.168396 473.704132,792.663574 472.102448,793.781677 
	C470.651917,794.794189 470.807281,796.390259 471.573608,797.827881 
	C472.113312,798.840393 473.252899,799.872253 472.395325,800.978027 
	C469.116974,805.205566 473.171143,808.836670 473.381104,812.771790 
	C473.531433,815.588928 476.691162,815.761169 478.318085,813.910645 
	C480.694794,811.207397 483.823517,811.093933 486.787598,810.253296 
	C488.525543,809.760376 490.211182,810.166321 491.327606,811.557983 
	C492.634430,813.187012 491.544312,814.558777 490.550995,816.005615 
	C488.885345,818.431824 487.596283,821.066467 484.905396,822.705078 
	C483.326965,823.666199 482.055115,825.539368 482.213654,827.303467 
	C482.918427,835.146423 478.798401,843.033386 482.669464,850.892090 
	C483.651581,852.885986 483.534607,855.555542 480.947540,857.033997 
	C478.799866,858.261414 478.681824,860.311829 479.195404,862.284302 
	C479.849457,864.795898 482.153564,863.773865 483.835541,863.987976 
	C484.821808,864.113525 485.840027,864.025574 486.813568,864.201538 
	C487.647583,864.352173 488.398468,864.852356 488.496399,865.795532 
	C488.624481,867.028687 487.724060,867.837036 486.745026,867.790833 
	C482.366913,867.584229 479.232056,868.843567 478.749878,873.763306 
	C473.316925,874.025513 472.558350,883.428040 465.106049,880.478088 
	C463.012146,879.649292 459.335571,880.228271 457.860413,883.395630 
	C456.406036,886.518372 457.316559,889.887878 457.594330,893.030334 
	C457.765289,894.964722 464.717560,893.850830 465.544037,891.162231 
	C466.486816,888.095276 468.628021,888.688477 470.664825,887.832764 
	C476.235413,885.492615 480.051361,884.972168 479.638062,892.835571 
	C479.624786,893.088013 480.036163,893.347839 480.212402,893.627625 
	C481.127197,895.079895 484.339844,895.205200 483.371887,897.482788 
	C482.116211,900.437317 479.571777,897.916992 477.616180,897.983276 
	C475.284424,898.062439 473.181091,898.171509 473.012451,901.607178 
	C472.661926,902.333984 472.325134,902.669312 471.979431,902.995544 
	C472.168060,902.827271 472.348297,902.650208 472.764313,902.236572 
	C478.185944,903.146606 480.413910,907.173218 478.070648,911.645081 
	C477.123779,913.452026 476.492188,915.568176 474.341492,916.927185 
z"/>
<path fill="#CDCCCD" opacity="1.000000" stroke="none" 
	d="
M483.233826,799.231445 
	C479.078949,796.501587 481.382874,794.506470 483.842682,793.352173 
	C490.114502,790.409058 490.187988,790.565674 491.554443,784.785156 
	C495.925385,787.278687 501.384583,785.351685 504.950562,780.069336 
	C505.735504,778.906616 505.920563,776.963440 507.948761,777.283325 
	C509.693756,777.558594 510.127716,779.192505 510.663208,780.563232 
	C511.405609,782.463623 510.633362,783.795349 508.838470,784.567200 
	C505.689392,785.921448 505.863403,787.572876 508.170441,789.716003 
	C511.197235,792.527588 509.976074,795.335510 507.273132,797.197327 
	C504.454407,799.138855 501.956573,801.746033 498.329407,802.446289 
	C497.066040,802.690186 495.763367,803.415833 495.303497,804.814270 
	C494.789978,806.375793 494.721039,808.352417 492.276764,807.802246 
	C490.271576,807.350891 489.243408,805.930237 488.979980,803.906128 
	C488.917511,803.426514 488.672546,802.970642 488.257629,802.250732 
	C486.183868,801.703430 484.787964,800.650818 483.233826,799.231445 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M529.218140,923.213867 
	C529.000854,922.555908 528.999207,922.112671 528.996338,921.337158 
	C530.504150,914.208679 535.525085,910.127014 540.535156,906.021973 
	C541.665344,905.095886 542.935425,904.976501 544.673584,905.047974 
	C543.592651,908.312073 540.246521,909.828552 538.223511,912.655945 
	C534.218323,918.253662 536.081604,923.664978 537.983704,929.591187 
	C534.680054,930.905640 533.625549,927.765259 531.298218,926.170349 
	C530.137451,925.349548 530.047791,924.230530 529.218140,923.213867 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M495.764221,857.128479 
	C496.142609,855.187927 496.195160,853.323669 498.460114,853.296509 
	C499.714630,853.281555 499.924194,854.406128 499.955872,855.426086 
	C500.012878,857.261902 500.105957,859.476685 497.778748,859.711365 
	C495.580261,859.933105 493.360901,861.741211 491.190887,860.280884 
	C489.689789,859.270813 490.168793,858.014099 491.805969,857.410828 
	C493.061340,856.948242 494.331421,859.561523 495.764221,857.128479 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M483.010010,799.373840 
	C485.044739,798.831604 486.866486,799.107971 487.851685,801.676880 
	C485.175507,803.832458 483.529572,803.048645 483.010010,799.373840 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M478.299988,921.899048 
	C478.025421,921.571777 478.035004,921.130493 478.071075,920.359192 
	C479.171295,918.460571 480.271393,917.080078 481.968933,919.815063 
	C481.842346,921.160400 480.883270,921.035767 480.009766,921.561157 
	C479.397400,921.777954 478.990784,921.781494 478.299988,921.899048 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M462.346680,770.989746 
	C460.982086,770.517761 460.263733,769.791138 460.036011,768.317871 
	C461.647552,768.225769 463.852325,767.346008 464.430145,769.814087 
	C464.675903,770.863770 463.461395,770.844055 462.346680,770.989746 
z"/>
<path fill="#C2C1C3" opacity="1.000000" stroke="none" 
	d="
M547.837158,903.867493 
	C547.453186,904.895142 546.614868,905.050903 545.393555,905.068909 
	C545.249939,903.398743 545.950378,902.639465 547.837158,903.867493 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M546.508606,909.916748 
	C542.731567,914.771606 543.522949,920.209106 545.066345,925.741760 
	C545.594849,927.636230 547.051697,929.214050 546.914429,931.715088 
	C541.171143,929.681885 541.463135,924.312988 541.086792,919.548828 
	C540.771118,915.552368 541.812073,911.747253 546.508606,909.916748 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M551.083862,939.288452 
	C553.048645,939.299194 553.519958,940.821838 553.790161,942.830811 
	C552.047485,942.616333 551.481384,941.189758 551.083862,939.288452 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M547.069824,933.277893 
	C547.978577,933.076111 548.710022,933.427490 548.858276,934.800049 
	C547.979065,934.944458 547.274048,934.568176 547.069824,933.277893 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M547.556030,938.453003 
	C547.599792,938.521545 547.512329,938.384460 547.556030,938.453003 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M529.008911,923.347412 
	C530.402161,923.134338 530.986816,923.968018 530.986694,925.656677 
	C529.597534,925.865662 529.009460,925.034912 529.008911,923.347412 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M470.750671,924.329712 
	C472.816925,923.659363 474.566315,923.247559 476.704773,922.794434 
	C477.093811,922.753113 477.532471,922.585876 477.687103,922.762451 
	C477.261810,923.743958 476.681915,924.548889 475.969727,925.628784 
	C474.782990,927.383118 473.369232,928.550293 472.337250,930.062073 
	C468.923645,935.063049 467.575287,939.880798 471.529846,945.522827 
	C474.247772,949.400391 477.323181,953.074829 479.812103,957.511414 
	C480.474731,958.264038 480.612274,958.921936 480.953369,959.833435 
	C481.053741,960.975891 480.973053,961.823547 481.510925,962.883545 
	C481.638702,963.166138 481.797546,963.593140 481.894226,963.799377 
	C482.700348,964.761841 483.255402,965.600891 483.432465,967.025452 
	C483.487854,969.803223 483.432678,972.221863 484.656128,974.783142 
	C484.863159,975.076416 485.009247,975.509399 485.084351,975.725220 
	C485.415527,976.309448 485.628876,976.701172 485.843536,977.445068 
	C485.873779,980.161072 486.251343,982.465271 487.492798,984.904541 
	C487.705841,985.596863 487.768677,986.002441 487.848389,986.715759 
	C487.785767,990.758179 489.650085,994.015930 490.630066,997.822510 
	C490.814819,998.108948 490.926544,998.071899 490.970459,998.032715 
	C491.492828,998.466248 491.819122,999.019165 491.925537,1000.017822 
	C491.925751,1001.409729 491.415863,1002.386902 491.279358,1003.751282 
	C491.139648,1004.788513 491.083435,1005.523254 491.000824,1006.625366 
	C490.524841,1006.989685 490.075256,1006.986755 489.288391,1006.991516 
	C486.086151,1005.272827 485.481110,1002.200073 484.606171,999.382751 
	C482.087067,991.270752 479.915283,983.055969 477.787964,974.834045 
	C476.238373,968.844727 474.217102,963.048950 471.429504,957.169922 
	C471.226288,956.346436 471.707397,955.855835 471.168701,955.426758 
	C470.399963,955.429016 470.030457,955.110657 469.847412,954.336670 
	C470.844055,950.221680 467.071472,949.582520 465.239868,947.227539 
	C459.916962,941.863098 459.995148,934.893982 465.144653,929.700623 
	C466.872986,927.957458 468.250336,925.859680 470.750671,924.329712 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M480.008789,957.986938 
	C474.435974,953.161438 469.424255,947.491333 467.534454,940.780029 
	C465.884338,934.919800 469.446228,929.324219 475.505371,925.959961 
	C472.832764,932.151855 471.315186,938.306702 474.331390,945.252136 
	C475.992432,949.076904 478.400299,952.434448 480.037231,956.602661 
	C480.201904,957.232117 480.301880,957.508972 480.008789,957.986938 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M490.904236,997.694153 
	C487.836334,995.195862 486.319427,991.804199 487.615082,987.298462 
	C490.081726,990.084045 491.035156,993.529968 490.904236,997.694153 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M487.760620,984.786926 
	C483.401581,984.478882 485.446716,980.771606 484.674438,978.250000 
	C485.298706,977.613037 485.777618,977.472778 486.638611,977.807861 
	C487.411469,980.135437 488.328979,982.158936 487.760620,984.786926 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M484.936371,974.724243 
	C481.966522,973.773071 482.790405,970.895203 482.188324,968.252441 
	C482.873383,967.307739 483.582214,967.277466 484.606293,967.839111 
	C484.920868,970.192566 484.965210,972.282349 484.936371,974.724243 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M484.915070,967.731750 
	C484.214386,968.047913 483.552307,967.992981 482.563477,967.900879 
	C482.124908,966.835815 482.013092,965.808044 481.946106,964.392944 
	C484.116547,964.119019 484.971893,965.354126 484.915070,967.731750 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M479.694336,958.529419 
	C479.708313,957.987305 479.800903,957.786621 479.997742,957.270630 
	C480.891357,958.174133 482.792328,958.674988 482.259338,960.807739 
	C481.664459,961.382019 481.202454,961.523499 480.369141,961.187378 
	C479.971802,960.256531 479.872375,959.563660 479.694336,958.529419 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M491.415222,1003.921021 
	C490.720917,1003.230347 489.960144,1002.493164 490.589905,1001.186401 
	C491.207245,1000.603149 491.694031,1000.475037 492.537598,1000.860046 
	C492.858093,1001.536316 492.886536,1001.944824 492.927094,1002.659668 
	C492.504333,1003.240112 492.069458,1003.514160 491.415222,1003.921021 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M492.877930,1000.769104 
	C492.378113,1001.084534 491.926575,1001.041199 491.130127,1000.987488 
	C490.822662,1000.229431 490.860077,999.481689 490.955933,998.363770 
	C492.507019,998.122498 493.005310,999.038025 492.877930,1000.769104 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M486.933533,977.730469 
	C486.493683,978.032532 486.036377,978.019958 485.234192,977.999207 
	C484.914154,977.530029 484.938995,977.069031 485.061646,976.274536 
	C486.051697,976.067322 486.703430,976.480042 486.933533,977.730469 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M480.075775,961.255859 
	C480.510895,960.966675 480.950592,960.983948 481.721649,961.001099 
	C482.005493,961.491150 481.957977,961.981384 481.774567,962.818909 
	C480.801819,962.939941 480.227417,962.458740 480.075775,961.255859 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M477.907593,922.705383 
	C477.841736,922.939087 477.457214,922.886475 477.275513,922.819824 
	C477.214294,922.517883 477.383575,922.325623 477.773010,922.081665 
	C477.944397,921.987000 477.973480,922.471680 477.907593,922.705383 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M513.373779,953.965454 
	C513.892761,952.604980 514.812683,951.253723 515.869568,949.650757 
	C517.066467,949.325623 518.126404,949.252197 519.585083,949.190674 
	C529.598083,949.220032 533.628235,955.672180 536.678162,963.802246 
	C536.922607,965.812256 536.951538,967.522400 536.899292,969.656616 
	C533.982422,966.566589 531.010620,963.152283 528.343933,959.514282 
	C524.553955,954.343872 520.111938,952.536865 513.373779,953.965454 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M536.921448,963.710205 
	C535.811707,963.875061 535.280701,963.184814 534.885437,962.152222 
	C532.318848,955.448181 527.421509,951.374207 520.246216,949.421326 
	C524.435791,947.444580 529.411072,949.075806 532.658020,953.477112 
	C534.826721,956.416809 536.589294,959.571655 536.921448,963.710205 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M537.027954,978.652832 
	C536.852356,979.002502 536.674377,979.004700 536.229248,979.000488 
	C535.025574,978.150146 535.229370,977.513428 536.582703,976.982788 
	C536.907166,977.387268 536.966370,977.846313 537.027954,978.652832 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M531.698853,980.818726 
	C529.777039,981.001160 528.866943,980.018738 529.032715,978.225464 
	C529.770386,970.246277 523.692322,966.294983 518.901306,961.856140 
	C517.120667,960.206299 514.566895,959.029358 512.208252,957.296875 
	C517.206726,954.748596 520.515625,959.005371 523.423828,961.474182 
	C526.727661,964.278870 529.571106,968.067749 531.412598,972.527283 
	C532.613892,975.436340 532.741089,977.730530 531.698853,980.818726 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M461.523590,1013.838867 
	C460.596436,1014.568787 459.954376,1015.016724 459.132629,1015.696777 
	C455.902344,1018.574463 452.388153,1020.526794 448.467072,1021.205627 
	C442.100494,1022.307739 435.540131,1022.494568 431.166107,1016.655396 
	C426.085602,1009.873169 423.839539,1002.215515 426.544556,993.722473 
	C426.948242,992.455017 427.718048,991.348450 428.418823,989.774963 
	C430.661926,984.367310 434.331360,980.661682 438.923706,977.263794 
	C440.576569,976.050964 441.909027,975.020447 443.598206,973.876526 
	C445.805847,972.683716 448.032257,972.614990 450.575958,971.354187 
	C443.826324,969.170105 437.730164,968.853333 431.446167,971.170898 
	C425.839081,973.238892 423.682770,971.360168 424.564117,965.160767 
	C425.077393,962.804199 425.943085,960.975464 427.122681,958.861450 
	C427.710876,958.157959 428.125916,957.845825 428.967896,957.500000 
	C430.016571,957.414429 431.017120,957.468445 431.080109,958.076843 
	C431.660492,963.683655 435.176392,961.560181 438.019653,960.671997 
	C442.406281,959.301636 446.866516,959.121094 451.363190,959.515259 
	C456.888245,959.999512 461.133209,962.667542 463.806976,967.569519 
	C466.126038,971.821228 464.677490,974.994995 459.932098,976.217163 
	C457.673065,976.799072 455.309418,976.748779 453.022278,977.234985 
	C442.135986,979.549011 435.632294,986.128967 434.123657,996.419556 
	C432.434570,1007.940613 442.348877,1015.937073 453.386261,1012.028625 
	C456.304657,1010.995239 458.816406,1008.588135 462.617249,1009.453186 
	C464.314056,1011.452820 463.224426,1012.552856 461.523590,1013.838867 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M429.679443,988.778809 
	C426.964905,996.109619 426.433105,1002.888855 429.390045,1010.556946 
	C433.293915,1020.680542 441.638214,1020.978027 448.890411,1019.476624 
	C452.100983,1018.811951 455.114410,1017.194946 458.585144,1015.969543 
	C455.396637,1022.699951 440.615021,1029.537476 434.306396,1026.593262 
	C431.799164,1025.423096 427.689392,1024.538086 427.767761,1020.038940 
	C427.789642,1018.782654 426.969666,1017.507629 425.110596,1016.612122 
	C424.719482,1016.042358 424.749329,1015.864502 424.837799,1015.422607 
	C425.221649,1014.906555 425.353394,1014.583130 425.067841,1013.960510 
	C422.193298,1011.542908 423.744202,1007.144226 420.154358,1005.065979 
	C418.081726,1003.773132 417.677124,1002.100098 417.960266,1000.192993 
	C418.536774,996.310242 425.237396,989.484863 429.679443,988.778809 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M461.631561,1014.095581 
	C461.891846,1012.983032 462.545197,1011.845215 463.287354,1010.385315 
	C465.081055,1005.556824 466.376038,1005.169800 468.965027,1008.923401 
	C470.589264,1011.278137 472.020660,1011.564270 474.548004,1010.001709 
	C476.257477,1009.609558 477.535004,1009.705994 478.897278,1010.942139 
	C478.504395,1012.307129 477.863800,1013.353943 477.071350,1014.666504 
	C471.753510,1018.118652 467.014160,1013.933167 461.631561,1014.095581 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M424.523315,1016.335571 
	C424.689667,1016.220215 424.783997,1016.524780 424.791534,1016.683777 
	C424.799072,1016.842773 424.402618,1017.007629 424.206726,1017.096313 
	C424.072144,1016.914734 424.187500,1016.670044 424.523315,1016.335571 
z"/>
<path fill="#1A2537" opacity="1.000000" stroke="none" 
	d="
M474.089081,1010.920898 
	C471.151550,1014.847046 469.179504,1012.300476 467.991119,1009.780212 
	C466.259521,1006.107910 465.238861,1008.009399 463.660370,1009.968750 
	C460.442871,1009.540222 458.324860,1011.574524 456.071075,1012.727173 
	C450.030579,1015.816406 444.143860,1015.806030 438.714539,1012.006348 
	C432.882477,1007.924866 431.359253,1002.268372 432.619385,995.017395 
	C434.934967,981.693054 446.408051,975.750488 456.419189,975.005920 
	C457.576324,974.919800 458.771912,975.043884 459.890289,974.799072 
	C463.879120,973.926086 464.693756,971.897949 462.599243,968.310181 
	C458.208069,960.788513 451.477295,959.985413 441.033569,961.577759 
	C438.108612,962.023682 435.309448,963.246582 432.403809,963.894226 
	C431.433990,964.110474 430.069794,965.020691 429.283875,963.616455 
	C428.744324,962.652405 429.315552,961.735413 430.044006,960.947144 
	C430.957733,959.958496 431.296204,958.949646 429.439941,958.089661 
	C428.631927,957.336792 428.934204,956.796936 429.707703,956.159546 
	C442.737213,949.135132 455.735809,947.185364 469.556091,953.958008 
	C470.269745,954.422119 470.640472,954.786621 471.276245,955.185364 
	C471.765106,955.069397 471.988861,954.919067 472.002380,955.114075 
	C478.014160,964.103455 479.189514,974.567688 482.122681,984.380981 
	C484.309265,991.696411 486.016357,999.155579 488.916656,1006.630127 
	C486.860138,1007.978333 484.769165,1008.957336 482.327087,1009.930054 
	C477.191772,1009.028809 472.972687,1007.282288 470.944885,1001.606384 
	C470.074646,1006.118896 470.074646,1006.118896 474.089081,1010.920898 
z"/>
<path fill="#060F14" opacity="1.000000" stroke="none" 
	d="
M474.584656,1011.234253 
	C469.542816,1010.005798 468.135498,1006.542908 468.213562,1002.246216 
	C468.236786,1000.968140 468.821167,999.572144 470.367920,999.251587 
	C471.290680,999.060364 472.048309,999.746887 472.222473,1000.555908 
	C473.344604,1005.768555 477.576050,1007.480835 481.885864,1009.578857 
	C481.197601,1010.240906 480.419220,1010.557922 479.392944,1011.067627 
	C477.762177,1011.243408 476.379364,1011.226624 474.584656,1011.234253 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M491.779724,1009.219543 
	C493.755493,1010.310791 495.643616,1011.464539 496.949890,1013.664429 
	C493.783325,1014.165100 491.335571,1013.403503 491.779724,1009.219543 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M502.047607,1014.367249 
	C503.725891,1013.706421 505.481201,1013.362488 507.621948,1013.014221 
	C506.664703,1015.740234 504.625305,1016.022705 502.047607,1014.367249 
z"/>
<path fill="#141D31" opacity="1.000000" stroke="none" 
	d="
M186.899872,469.330078 
	C188.337326,466.549225 190.834869,464.545227 187.556244,461.380768 
	C186.087448,459.963165 187.738983,457.093903 188.900024,455.467529 
	C193.950607,448.392853 197.102066,439.878357 205.258804,435.089417 
	C207.571823,433.731384 207.114380,429.307831 210.578552,427.762238 
	C212.323425,426.983704 213.387878,425.318481 215.886276,425.622101 
	C217.725113,425.845551 218.874298,423.856964 218.976501,421.430176 
	C225.906418,425.467499 233.055679,429.559479 239.700577,434.350983 
	C248.703964,440.843140 258.666962,445.569275 268.633850,450.998505 
	C269.167755,450.894073 269.344696,450.743988 269.868347,450.622589 
	C274.315826,453.050507 278.034515,456.133026 282.991455,457.714539 
	C283.545380,457.929504 283.760712,458.036804 284.274933,458.353394 
	C285.428040,459.455261 286.287598,460.344452 286.503418,461.998657 
	C284.015533,465.644836 280.065674,465.450623 276.680450,466.282562 
	C268.947479,468.183014 261.217712,469.959717 254.927917,475.245880 
	C251.456879,478.163025 247.548889,480.801788 246.905701,486.182129 
	C247.097443,487.004883 247.143524,487.484650 247.119400,488.329041 
	C247.039230,488.923798 246.984955,489.158173 246.790909,489.724823 
	C246.162109,490.898682 245.420532,491.509796 244.430115,492.368286 
	C243.462997,492.884521 242.756226,493.000519 241.662888,492.911774 
	C241.075897,492.775909 240.847595,492.696503 240.305115,492.440308 
	C232.021530,488.503387 223.690475,485.650635 215.125061,482.415894 
	C213.817688,482.083313 212.924698,481.669952 211.667831,481.160248 
	C205.155029,478.561523 199.080231,475.880646 192.736740,473.867920 
	C190.399857,473.126465 187.937866,472.278198 186.899872,469.330078 
z"/>
<path fill="#04556A" opacity="1.000000" stroke="none" 
	d="
M270.102875,451.299500 
	C269.805756,451.111572 269.396057,451.105042 269.193451,451.074585 
	C268.831512,448.790558 266.759399,447.761169 265.547302,446.382538 
	C257.980011,437.775879 263.086029,429.195435 265.410492,420.735809 
	C266.297272,417.508423 272.471252,416.043945 275.869659,417.619934 
	C276.464569,417.895813 276.984497,418.339233 277.525604,418.724213 
	C282.228333,422.070007 286.468140,425.442383 292.303406,419.676086 
	C295.071350,416.940826 300.163879,417.681183 304.231720,417.028198 
	C307.610413,416.485840 310.555573,415.678894 312.483276,412.350403 
	C314.586304,408.719116 318.947205,408.101929 322.586700,406.002960 
	C326.760620,416.315063 330.737030,426.617584 332.803894,437.458649 
	C334.045105,443.969177 336.107971,450.202271 338.076874,456.473083 
	C338.815430,458.825256 339.340790,461.139221 339.732361,463.794739 
	C341.100983,465.208191 340.624512,466.408264 339.747437,467.908020 
	C339.104614,468.524170 338.660858,468.750488 337.810150,468.925049 
	C334.445984,468.787170 332.447906,465.834229 328.972229,466.006226 
	C328.179962,465.971283 327.734711,465.893036 326.978760,465.659668 
	C323.854584,463.950684 321.319031,461.459778 317.289429,462.870667 
	C316.501251,462.949432 316.049774,462.918549 315.271454,462.752380 
	C313.364380,461.528839 311.652954,461.315674 309.453003,461.931671 
	C308.564056,461.913727 308.058411,461.812286 307.191132,461.479004 
	C302.040527,459.662689 297.109222,460.775635 292.255157,460.510925 
	C289.664673,460.369659 287.013641,460.820160 284.197479,459.325134 
	C283.828369,459.094391 283.410828,459.072876 283.205566,459.033142 
	C279.451599,455.397980 274.533875,454.100647 270.102875,451.299500 
z"/>
<path fill="#CCC9CA" opacity="1.000000" stroke="none" 
	d="
M437.743835,495.077820 
	C437.906769,495.912903 437.599701,496.663666 436.301819,496.920593 
	C436.111877,496.067108 436.423767,495.321442 437.743835,495.077820 
z"/>
<path fill="#087996" opacity="1.000000" stroke="none" 
	d="
M338.874878,468.257202 
	C339.222534,466.856720 339.548828,465.774841 339.919464,464.357056 
	C343.123932,459.201355 339.767273,455.010101 338.257812,450.925079 
	C336.409576,445.923248 337.500244,440.695770 336.068542,435.744232 
	C335.749756,434.641754 336.197662,433.418396 337.232605,432.627930 
	C340.158875,430.392792 350.446930,434.288879 350.968384,437.905792 
	C351.563782,442.035645 352.005249,445.255737 357.416901,446.855133 
	C360.705505,447.827087 364.171448,451.239838 366.985229,454.789215 
	C369.819366,458.364197 372.715118,462.553497 378.336273,464.613922 
	C384.634644,466.922577 391.679810,468.548553 397.218323,473.054962 
	C399.288025,474.738983 400.658142,476.601990 401.030121,479.212433 
	C401.178772,480.255646 401.553833,481.254547 402.631042,481.524292 
	C403.801666,481.817413 404.504974,480.984589 404.836700,479.974457 
	C406.294067,475.536896 408.724548,476.985535 411.583679,478.967529 
	C409.908447,481.586456 411.057068,484.467682 410.999298,487.606201 
	C414.454895,489.157776 415.150726,492.456879 416.514130,495.201721 
	C417.417572,497.020538 416.214111,497.946838 414.715912,498.576172 
	C413.239868,499.196198 411.384155,499.321167 411.146790,501.835022 
	C411.449707,502.658569 411.548065,503.156372 411.236877,504.038208 
	C410.207245,505.101410 409.185669,505.352264 407.760834,505.623627 
	C407.121613,505.732208 406.866119,505.772095 406.229065,505.876862 
	C395.500122,507.626984 385.270508,509.867249 375.174774,512.679077 
	C367.889862,514.708008 365.511658,513.685120 362.415436,506.559052 
	C359.033630,498.775696 355.622864,491.054108 350.554321,484.121307 
	C347.662262,480.165619 343.326202,477.707428 340.445740,473.330933 
	C340.130341,472.817017 340.015533,472.608521 339.752747,472.084961 
	C339.519501,471.559967 339.434265,471.349945 339.239410,470.824097 
	C338.939056,469.878204 338.828125,469.236755 338.874878,468.257202 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M481.616943,364.019897 
	C481.947052,364.702850 481.938690,365.423859 481.910156,366.505310 
	C480.668243,368.104706 479.976044,369.619995 479.364929,371.598999 
	C475.704803,376.054596 476.499878,380.606232 478.255219,385.002014 
	C483.787140,398.854980 488.063690,413.125427 492.699646,427.279968 
	C494.600311,433.083069 496.595886,439.044495 499.299438,444.348297 
	C504.308533,454.175140 504.165466,465.347076 508.977051,475.465057 
	C509.432220,477.092804 509.444244,478.364594 509.119476,480.000793 
	C508.679810,481.524689 507.696869,482.302826 507.000000,483.650085 
	C506.662323,484.093658 506.514069,484.258179 506.114929,484.632996 
	C505.159821,485.303772 504.396118,485.684967 503.621460,486.576599 
	C502.294006,487.840302 500.797668,488.304779 499.411224,489.528015 
	C498.924286,489.821472 498.718719,489.925781 498.184204,490.150482 
	C496.896790,490.404388 496.011566,490.768280 494.762817,491.242828 
	C488.731049,492.203979 483.589600,495.628967 477.248657,494.555115 
	C475.876648,492.141754 477.080841,491.384399 478.981934,490.998108 
	C481.551697,490.475891 483.912842,489.307861 486.719971,488.292084 
	C487.475250,488.047180 487.911194,488.038300 488.674133,488.023621 
	C491.093048,486.721680 493.443909,485.952637 495.534637,484.609894 
	C501.070496,481.054688 502.335785,477.095306 500.832916,470.781067 
	C498.107086,459.328613 492.210968,449.048676 489.142578,437.727417 
	C485.784393,425.336853 480.328766,413.639740 476.691528,401.328003 
	C476.034393,399.103760 475.375824,396.796722 473.611664,395.144348 
	C470.629669,392.351410 470.014893,388.803101 470.523987,385.064880 
	C471.319702,379.222351 471.530334,373.196869 475.058258,367.672241 
	C475.782013,366.485138 476.257874,365.580627 477.642517,365.085632 
	C479.105957,364.700653 480.192230,364.379333 481.616943,364.019897 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M475.795471,366.836975 
	C473.606262,373.718689 472.003143,380.558319 472.010559,387.708282 
	C472.012085,389.188446 471.516754,391.140808 472.804657,391.973816 
	C478.899139,395.915680 478.581177,402.870056 480.452667,408.589172 
	C482.131104,413.718475 485.561218,418.089142 486.226135,423.675751 
	C486.513763,426.092377 489.417847,427.822876 487.644562,431.186859 
	C487.077820,432.262085 489.764587,434.966431 490.817780,437.000458 
	C492.711700,440.658112 493.249512,444.802155 494.407990,448.684174 
	C495.393005,451.985046 497.898193,454.382843 498.677490,457.273193 
	C500.513458,464.082306 503.305756,470.761169 503.049927,478.068115 
	C502.947266,481.001038 501.264801,482.921021 499.163635,484.046875 
	C496.045654,485.717529 493.096222,487.929413 489.219604,488.241211 
	C489.580750,487.198975 490.160400,486.380127 490.879761,485.293945 
	C492.760925,485.183655 494.132599,484.400970 495.684448,483.144531 
	C497.655365,481.580231 497.705872,479.568207 498.013916,477.324036 
	C498.024933,476.248840 498.025696,475.499390 498.027435,474.375214 
	C494.749939,460.798126 489.043488,448.424530 484.346008,435.743622 
	C480.031128,424.095612 476.565674,412.123962 471.590240,400.717682 
	C470.587799,398.419525 469.401764,396.261688 467.273193,394.268311 
	C465.107117,393.856964 465.830994,392.593262 465.986908,391.295898 
	C466.962158,386.440643 467.234375,381.745331 468.787628,377.281769 
	C469.652313,374.796936 470.296173,372.261292 472.723511,370.252563 
	C473.047638,368.856689 472.929047,367.637604 474.653992,367.053619 
	C475.171570,366.861267 475.356812,366.744995 475.795471,366.836975 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M486.635437,488.046600 
	C484.794128,491.765900 480.157043,490.802307 477.068329,493.434143 
	C476.705017,494.206482 476.358673,494.527466 475.539398,494.793457 
	C470.718262,495.102234 466.501312,496.211273 462.302612,498.653015 
	C460.180511,499.614868 458.256805,499.858765 455.958801,500.034607 
	C453.145172,499.415863 451.066254,500.239594 448.822205,501.878662 
	C444.614441,502.391998 440.991425,504.099457 436.661835,504.104248 
	C434.946289,503.651367 433.671509,503.184753 432.984070,501.463684 
	C435.326691,499.036194 438.293091,499.529968 440.972534,498.867035 
	C451.367157,496.295197 462.131439,495.363556 472.213531,491.300964 
	C476.610657,489.529144 481.542542,489.084503 486.635437,488.046600 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M432.686951,501.051025 
	C433.884003,501.537720 434.755432,502.020844 435.828247,502.735291 
	C435.970398,505.772644 433.433289,505.781982 431.811340,506.143280 
	C427.928314,507.008148 424.000946,508.737366 419.551758,506.624512 
	C419.703888,505.104889 420.221680,503.850098 420.910461,502.296326 
	C421.081451,501.997345 421.533234,502.006042 421.758087,502.028198 
	C424.963318,504.050720 427.662842,501.620697 430.784607,501.301331 
	C431.493683,501.077759 431.927460,501.062653 432.686951,501.051025 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M430.632507,501.064453 
	C428.481293,503.676178 425.691803,505.133148 422.083557,502.402557 
	C424.738770,501.834564 427.365387,500.571350 430.632507,501.064453 
z"/>
<path fill="#071518" opacity="1.000000" stroke="none" 
	d="
M406.768494,504.204285 
	C407.969025,503.878387 408.927185,503.754639 410.245636,503.594299 
	C411.309448,503.490753 411.889984,503.080872 412.869385,502.762970 
	C414.211243,502.502808 415.223602,502.343964 416.622314,502.211487 
	C417.105865,505.566650 417.047119,508.930298 419.483276,511.943542 
	C420.012604,512.127441 420.207001,512.178955 420.635559,512.422119 
	C421.136017,512.918335 421.370392,513.245728 421.710175,513.864136 
	C421.923950,514.317322 422.000427,514.502380 422.174927,514.969727 
	C422.425598,515.821655 422.570740,516.394653 423.145447,517.104370 
	C424.045685,518.365906 424.617737,519.464478 425.230164,520.896484 
	C425.490204,521.582458 425.658966,521.966187 425.998840,522.623657 
	C426.597382,524.137146 427.453033,525.159119 428.042786,526.697388 
	C428.420105,530.465210 430.403961,532.955444 432.996338,535.453857 
	C433.596985,535.915344 433.930756,536.183472 434.520813,536.624878 
	C435.820831,537.450500 436.874817,538.082703 438.190369,538.877197 
	C438.800507,539.256470 439.143738,539.481750 439.748108,539.873108 
	C441.195526,540.871826 442.375244,541.713501 443.619507,542.963745 
	C444.314575,543.833252 444.761322,544.515747 445.794373,545.041321 
	C446.225769,545.345825 446.394623,545.478516 446.798218,545.839600 
	C447.836304,547.216248 448.864838,547.980835 450.658325,547.994995 
	C452.286713,547.978333 453.519684,548.311951 455.028381,548.829590 
	C455.494263,549.049683 455.671387,549.153320 456.079590,549.455688 
	C456.557220,549.964050 456.770294,550.294983 457.067200,550.901245 
	C457.265076,551.316772 457.341553,551.480957 457.539551,551.882446 
	C457.754028,552.269104 457.846924,552.418396 458.097900,552.776489 
	C458.565277,553.441467 459.045013,553.676636 459.747131,554.083862 
	C460.158417,554.327209 460.322327,554.430969 460.726746,554.706421 
	C461.425354,555.282471 461.936279,555.606995 462.757416,556.046753 
	C463.208099,556.344421 463.382599,556.475525 463.795410,556.834961 
	C465.563995,559.177429 467.972137,559.857544 470.508728,560.998413 
	C471.009796,561.303040 471.199432,561.445679 471.630737,561.854004 
	C472.543549,563.615845 474.029999,564.856445 473.115662,567.078247 
	C470.070923,569.184082 467.478149,568.285339 465.010010,566.616577 
	C461.429657,564.195923 457.680450,561.990845 454.577454,558.929504 
	C448.555786,552.988770 441.070770,549.193604 433.764984,545.301880 
	C424.014557,540.107910 420.173248,531.153076 417.266266,521.502014 
	C415.908112,516.993103 415.305389,512.254517 411.444427,508.673553 
	C408.488159,509.130402 405.964355,509.866852 403.167542,511.014160 
	C402.312531,511.283630 401.804382,511.367493 400.890198,511.418762 
	C394.081451,510.952545 388.149750,513.294617 382.102478,514.754883 
	C377.150177,515.950684 372.247711,517.313599 366.791931,518.130249 
	C365.560242,518.383728 364.724854,518.455261 363.468567,518.258911 
	C362.236938,517.939270 361.536896,517.499817 360.644409,516.633301 
	C356.728241,508.955200 354.629639,500.923553 349.942291,493.939148 
	C338.428131,476.782471 322.762360,467.257538 301.988098,466.467896 
	C300.501404,466.411407 298.987885,466.348389 297.210449,467.122986 
	C293.494049,467.396545 290.238159,468.131073 286.618805,468.998474 
	C285.509186,469.349274 284.744263,469.447174 283.593079,469.482361 
	C280.751251,469.429932 278.267914,469.267120 275.698944,470.794556 
	C274.903778,471.116180 274.433960,471.247375 273.596741,471.379028 
	C268.827362,472.080231 264.776978,473.685242 260.770050,476.325073 
	C256.744965,478.246948 253.195877,480.246887 250.341461,483.774170 
	C249.589920,485.037964 248.945114,485.963226 247.846375,486.948975 
	C247.353043,487.214233 247.136948,487.282593 246.592300,487.263428 
	C244.196198,485.385132 245.215347,483.822479 246.424301,482.020386 
	C253.245499,471.852417 263.432770,467.424438 274.880005,465.032928 
	C278.418610,464.293640 281.922638,463.388885 285.719421,462.306885 
	C287.828217,459.653168 290.607452,460.530457 292.931702,460.292236 
	C297.674225,459.806030 302.474182,459.572693 307.614838,460.746918 
	C308.432953,461.055908 308.863098,461.092987 309.617126,461.126221 
	C311.445831,461.051056 312.970001,460.753418 314.704163,461.737122 
	C315.435577,462.014618 315.867188,462.059662 316.624420,462.103699 
	C320.472748,461.363800 323.342499,463.254852 326.698303,464.664459 
	C327.436005,464.955200 327.861450,465.027069 328.610321,465.116119 
	C331.862579,465.145142 333.772919,467.433563 336.598572,468.500000 
	C337.762177,468.932922 338.717102,469.007080 339.514099,470.011169 
	C339.625824,470.335602 339.808258,470.331757 339.816681,470.240906 
	C340.227570,470.289001 340.540222,470.539917 340.808472,471.209778 
	C343.708374,476.257965 348.748352,478.877502 352.034698,483.310852 
	C357.446350,490.611298 361.166138,498.757477 364.222321,507.116089 
	C366.104614,512.264160 368.992737,512.915649 373.257172,511.611664 
	C383.729736,508.409241 394.489899,506.490326 405.560852,504.171509 
	C406.144135,504.229645 406.335114,504.318024 406.768494,504.204285 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M412.875732,502.507751 
	C412.731506,503.382965 412.111053,503.823273 410.907257,503.651733 
	C410.726624,503.256866 410.852570,502.958221 411.061401,502.432404 
	C411.610107,502.253296 412.081329,502.303589 412.875732,502.507751 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M424.238586,522.232666 
	C423.508118,520.820374 423.008759,519.626831 422.275940,518.207703 
	C421.271423,517.296265 421.610596,516.332764 421.226318,515.234253 
	C421.030701,514.967224 420.906555,514.508667 420.849243,514.278137 
	C420.684540,513.848572 420.577148,513.649597 420.271393,513.175293 
	C420.073059,512.900085 419.946442,512.487671 419.882324,512.281738 
	C420.433167,510.278870 419.075806,508.762054 419.145081,506.704041 
	C424.744141,506.107758 430.218658,505.381470 435.758850,503.228149 
	C440.056519,502.254181 444.083405,501.541748 448.527832,500.751526 
	C450.605774,499.737152 452.347260,499.053772 454.631104,498.931610 
	C457.037384,498.536652 459.091705,498.174530 461.535828,497.731995 
	C465.797119,495.644592 470.192078,495.408051 474.622437,493.981567 
	C475.437622,493.942535 475.873779,493.931580 476.629272,493.845428 
	C482.998138,494.059418 488.272522,490.876862 494.437256,489.920715 
	C495.679199,489.348236 496.688446,489.608337 497.876404,489.131531 
	C498.152924,488.905121 498.398560,488.874054 498.522369,488.875244 
	C501.041046,487.890991 503.489410,487.052094 506.305023,486.077087 
	C509.493652,485.986115 512.279297,485.633820 515.398926,485.354553 
	C517.574646,485.405701 519.406189,485.272217 521.686340,485.247711 
	C521.735229,486.302551 521.335388,487.248474 520.867188,488.499634 
	C520.436340,489.521362 519.790405,489.766754 518.699097,489.832458 
	C516.890503,488.957062 515.286377,488.950317 513.331787,488.986450 
	C512.548096,489.005493 512.106323,489.029816 511.336121,489.102356 
	C509.027740,490.143066 506.869049,490.525238 504.366028,490.994995 
	C503.247192,491.021393 502.496368,491.040924 501.373932,491.117432 
	C496.727386,492.903717 492.256561,493.957306 487.399597,495.020233 
	C486.564758,495.055573 486.114746,495.076996 485.331848,495.158508 
	C482.892548,496.289001 480.436157,496.347351 478.053101,497.685669 
	C476.744659,497.892761 475.745941,497.927490 474.375061,498.008240 
	C471.629242,500.156464 468.669373,499.849945 465.440552,500.077637 
	C462.613831,500.717163 460.177673,501.322235 457.364227,501.983582 
	C449.465698,506.353638 440.719482,506.515778 432.714844,509.193390 
	C431.928680,509.456390 431.091858,509.554443 430.284760,509.753662 
	C425.110931,511.030640 424.887207,511.497253 426.601105,516.550049 
	C427.710022,519.819397 429.474365,522.980042 428.304260,526.961853 
	C427.819519,527.364136 427.571960,527.452881 426.954041,527.405762 
	C425.994629,526.083984 425.405579,524.897949 424.748596,523.381958 
	C424.610352,522.851868 424.540039,522.651672 424.238586,522.232666 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M486.005066,461.427612 
	C489.137451,461.482086 489.752136,463.909302 489.934631,466.426392 
	C490.077606,468.398712 490.142334,470.347809 490.939880,472.604675 
	C485.983704,470.790253 485.710754,466.453186 486.005066,461.427612 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M490.800842,481.152863 
	C491.636475,481.104248 492.386658,481.174103 492.424622,481.957611 
	C492.439270,482.259308 491.874817,482.589081 491.290833,482.954163 
	C490.370422,482.559601 490.051636,482.039093 490.800842,481.152863 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M467.003906,394.386353 
	C469.174896,393.834961 470.077789,394.690887 471.336426,396.801605 
	C476.154083,404.880829 476.908752,414.479492 481.138123,422.534180 
	C486.630463,432.994080 486.809509,445.462036 494.237061,455.065674 
	C495.053741,456.121643 495.171051,457.885681 495.223114,459.335358 
	C495.401184,464.291199 498.017151,468.654755 498.271454,473.758575 
	C494.459961,468.940704 491.807800,463.453644 490.074615,457.495239 
	C488.091827,450.678741 485.705231,444.026215 481.315277,438.294312 
	C480.115173,436.727325 478.878204,435.291016 479.695404,432.921814 
	C480.065277,431.849457 479.471680,430.295898 478.979401,429.098663 
	C475.148865,419.781891 472.137390,410.210754 469.910126,400.393036 
	C469.422394,398.243286 466.816681,397.231750 467.003906,394.386353 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M472.630249,370.038269 
	C471.137024,376.138367 467.786865,381.923584 468.551666,388.710022 
	C468.684875,389.892059 467.417572,390.182098 466.319885,390.864502 
	C464.009796,383.337067 466.830322,373.927704 472.630249,370.038269 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M497.746399,477.224060 
	C499.487640,479.384369 499.752869,481.502777 496.347961,482.930786 
	C495.906769,480.982513 496.111145,479.061615 497.746399,477.224060 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M476.300659,366.903931 
	C475.871979,367.032532 475.695038,367.019836 475.252228,366.992371 
	C474.991394,366.233948 474.996399,365.490356 475.001923,364.374664 
	C475.782196,363.683136 476.561920,363.363678 477.655731,363.007202 
	C477.980194,363.426086 477.990570,363.881989 478.010315,364.679962 
	C477.530579,365.602203 477.041473,366.182404 476.300659,366.903931 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M484.664490,358.947540 
	C485.187103,358.954437 485.398468,359.003876 485.919647,359.160431 
	C485.921417,360.656982 485.113495,361.592377 483.414581,362.067505 
	C483.064606,361.932678 483.037079,361.741669 482.982605,361.262146 
	C482.989532,360.550140 483.023468,360.126617 483.079895,359.386230 
	C483.519379,359.042908 483.936310,359.016479 484.664490,358.947540 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M523.084106,482.551300 
	C521.927063,483.277161 520.824951,483.601593 519.377808,483.940277 
	C517.393433,479.909515 519.010254,475.693604 518.080261,471.392151 
	C516.458191,463.889130 513.698975,456.809418 511.487671,449.555695 
	C508.221375,438.841431 503.423218,428.665283 498.767975,418.471191 
	C497.713928,416.162964 497.965210,413.582214 497.143890,411.207367 
	C493.376038,400.312836 490.618713,389.092407 486.517670,378.300873 
	C485.087769,374.538208 484.401733,370.640717 485.734131,366.301086 
	C485.954834,364.228790 486.315063,362.689453 488.526794,361.894958 
	C489.151733,368.596405 491.661377,374.867310 493.456116,381.326843 
	C495.539886,388.826447 497.231354,396.426727 499.742981,403.817505 
	C502.128021,410.835846 503.679321,418.240112 506.438141,424.990753 
	C508.574493,430.218201 510.458466,435.631805 512.595032,440.871826 
	C516.909790,451.453888 520.316284,462.419525 523.767822,473.331207 
	C524.645935,476.107269 524.316711,479.276764 523.084106,482.551300 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M485.592041,366.092712 
	C485.505798,375.741913 490.867096,384.066498 492.720978,393.265564 
	C494.247620,400.840729 498.007965,407.693085 499.471741,415.439026 
	C500.525452,421.014984 503.531799,426.522766 506.064087,431.857544 
	C511.174164,442.623047 514.596863,453.964447 518.211853,465.246643 
	C519.312683,468.682281 519.538452,472.351318 520.886902,475.769043 
	C521.976807,478.531433 519.859009,480.755219 519.037842,483.570770 
	C518.029297,484.077972 516.971924,484.040039 515.977600,485.216888 
	C513.268860,487.058502 510.511383,487.130005 507.315247,487.095947 
	C506.815155,486.866028 506.734955,486.645630 506.747528,486.084595 
	C507.479889,484.947784 508.324066,484.618652 509.649048,484.801300 
	C515.088989,482.359558 517.797241,472.507202 514.985901,465.627777 
	C512.345764,459.167023 510.901062,452.346649 508.637634,445.804932 
	C505.741882,437.435547 502.209961,429.246368 498.342621,421.275360 
	C497.038422,418.587341 496.782623,415.831787 496.273132,413.201447 
	C495.014496,406.703613 491.335236,401.036072 490.710602,394.292267 
	C490.163391,388.384247 485.556274,383.656586 484.698273,377.639191 
	C484.145172,373.760132 482.873840,369.925201 485.592041,366.092712 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M510.034363,484.569214 
	C509.194580,485.278809 508.419098,485.609283 507.329163,485.981812 
	C506.598633,486.387329 506.115753,486.540894 505.208801,486.318298 
	C504.558716,485.689240 504.459625,485.188324 504.774048,484.321960 
	C504.993896,483.994843 505.475616,483.971924 505.716614,483.963257 
	C506.731201,483.261169 506.871216,482.170715 507.744690,481.187866 
	C507.948822,479.580322 507.956238,478.201508 507.957520,476.409027 
	C508.168854,474.708893 508.200531,473.509949 507.950043,472.136932 
	C505.229218,457.220825 499.232971,443.452789 493.500549,429.562561 
	C490.354828,421.940155 488.714325,413.778931 485.819000,406.044373 
	C485.352539,404.798309 485.526001,403.420349 486.528748,401.551208 
	C490.070740,401.997742 490.398376,404.763824 491.031036,407.292297 
	C494.725616,422.058502 501.177338,435.833221 506.330231,450.075287 
	C509.253662,458.155212 513.291199,465.958221 512.131897,475.111328 
	C511.733307,478.258118 511.512573,481.321747 510.034363,484.569214 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M478.667358,371.301025 
	C478.947205,369.211731 479.244904,367.633545 481.552185,366.949097 
	C479.863647,375.060638 481.205872,382.747314 485.343292,390.029236 
	C487.125885,393.166626 487.116699,396.626831 487.029419,400.526489 
	C487.038300,400.962982 487.011658,400.988464 486.997894,400.999756 
	C486.103027,401.258331 485.095367,401.058990 484.778809,400.350494 
	C480.702484,391.226166 474.459808,382.558685 478.667358,371.301025 
z"/>
<path fill="#12D1EB" opacity="1.000000" stroke="none" 
	d="
M517.753296,488.908081 
	C518.716064,488.805328 519.412842,488.808197 520.454224,488.807983 
	C524.258362,488.207977 527.718018,487.611023 531.588501,487.012390 
	C532.948792,488.185699 535.051025,489.788086 532.216492,490.600189 
	C527.318176,492.003540 522.226929,493.514496 517.086670,490.382019 
	C517.028320,489.609833 517.205750,489.284546 517.753296,488.908081 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M524.152771,485.201538 
	C524.546753,484.782349 525.064026,484.573303 525.838379,484.510529 
	C525.764893,485.573456 525.230896,485.999817 524.152771,485.201538 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M672.580933,879.931641 
	C674.521912,885.479248 675.131836,891.206970 678.229309,896.243835 
	C679.413818,898.170105 677.377808,898.638184 675.868164,898.894653 
	C673.273804,899.335266 670.675415,899.751709 667.675903,900.164246 
	C662.850525,893.756287 665.540527,882.520447 672.580933,879.931641 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M673.010010,879.709473 
	C668.210693,882.464783 667.457214,887.074951 667.059875,891.747742 
	C666.850281,894.212585 667.129578,896.718872 667.230591,899.678284 
	C666.061951,900.782837 664.850891,901.415588 663.303345,901.980042 
	C661.277283,894.307556 661.257507,887.126709 667.262268,881.007446 
	C668.713745,879.528320 670.077820,878.860291 672.366455,878.912659 
	C672.825195,879.090149 672.905396,879.259705 673.010010,879.709473 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M647.281494,928.920288 
	C647.029907,925.865051 646.619690,922.618530 649.515015,920.010498 
	C650.532288,922.457581 649.025818,924.737732 649.041260,927.589966 
	C648.506897,928.271606 648.026001,928.550781 647.281494,928.920288 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M659.295410,904.935181 
	C659.046509,903.986328 659.619141,903.463135 660.836792,903.178711 
	C661.020630,904.048889 660.400696,904.552490 659.295410,904.935181 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M713.714233,933.182434 
	C714.186157,933.545532 714.436462,934.089050 714.579346,934.933350 
	C714.150513,935.190491 713.833801,935.124268 713.279663,935.013306 
	C713.189148,934.448425 713.340942,933.905701 713.714233,933.182434 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M674.412842,825.002075 
	C665.948914,827.844604 657.989990,830.990906 649.824280,833.450439 
	C638.620544,836.825195 627.073975,838.972900 615.945679,842.701721 
	C613.682983,843.459839 610.990662,842.981995 608.496155,842.988586 
	C606.302490,842.994324 604.328735,843.019958 602.742920,845.114868 
	C600.461182,848.129272 596.820190,845.704773 593.869263,846.808960 
	C592.084656,847.476746 590.042419,847.481201 588.359131,848.727417 
	C587.585876,849.299927 586.404419,850.008911 585.640076,849.801392 
	C575.879150,847.150940 566.823059,852.754333 557.202881,852.092651 
	C551.679932,851.712830 547.327393,850.027344 544.400696,845.157349 
	C543.731201,844.043274 542.779541,842.701294 541.664795,842.319275 
	C536.123596,840.420593 535.119385,835.853699 534.480103,830.956299 
	C534.051880,827.675903 533.763000,824.376465 530.826660,822.041138 
	C530.215271,821.554932 530.050964,820.210938 530.072876,819.271362 
	C530.238342,812.167542 529.945068,805.408386 526.213989,798.777832 
	C524.105042,795.030029 523.949219,789.920166 522.726196,785.599487 
	C520.666382,778.323120 522.030029,770.679077 518.738831,763.450073 
	C516.413574,758.342834 515.671265,752.431458 512.441528,747.532837 
	C511.827698,746.601807 512.380188,745.683838 512.795288,744.732483 
	C514.338623,741.195129 513.313354,738.188171 510.200104,736.193726 
	C507.785065,734.646545 506.916229,732.752075 506.955902,729.982361 
	C507.015961,725.790649 506.626007,721.649902 503.156311,718.575562 
	C502.799011,718.258972 502.398865,717.766602 502.371887,717.333801 
	C501.459381,702.695679 493.440460,689.679321 492.841370,674.915833 
	C492.588013,668.672546 489.098328,663.124695 486.421875,657.670959 
	C483.668030,652.059509 482.469971,645.911072 478.708008,640.525574 
	C474.698334,634.785461 468.549164,633.534790 463.460724,630.167053 
	C462.298401,629.397827 460.551727,629.521118 460.196594,627.074707 
	C460.914124,625.764160 461.681183,625.280945 462.650574,624.489014 
	C463.170532,624.195679 463.392334,624.097046 463.971558,623.901489 
	C472.612274,624.815002 477.853424,630.337708 482.537659,636.429871 
	C489.893890,645.997070 492.952698,657.386169 495.737366,668.861206 
	C496.672668,672.715332 498.236328,676.365845 499.858093,680.394653 
	C500.187103,681.231262 500.308441,681.728210 500.406433,682.615845 
	C501.792053,689.880249 502.596161,696.972229 506.984619,702.826660 
	C508.596466,704.976929 507.620026,708.080322 510.160950,710.152893 
	C511.002899,710.959473 511.444824,711.598511 512.034302,712.611206 
	C514.079529,718.802307 516.298401,724.555054 516.683960,730.771179 
	C516.787537,732.441833 517.241089,734.015930 517.843018,735.596436 
	C524.228516,752.365295 529.768738,769.378845 532.335938,787.218567 
	C532.507202,788.408875 532.789917,789.491821 533.464233,790.468628 
	C536.952759,795.522034 538.204285,801.298035 539.389404,807.219666 
	C541.049255,815.513000 543.299438,823.689697 546.355835,831.610718 
	C546.899597,833.019958 547.447205,834.482483 548.584595,835.474182 
	C556.592224,842.456604 565.750122,846.185791 576.485291,844.341553 
	C591.692444,841.729126 606.405457,836.987671 621.373047,833.342041 
	C627.852173,831.763916 634.203674,830.572144 640.842590,829.943359 
	C647.252991,829.336121 652.992310,825.634033 659.129028,823.559387 
	C661.781067,822.662781 664.304993,821.436401 667.133667,821.077820 
	C670.137573,820.696960 673.048035,819.947449 675.553650,818.171509 
	C678.823364,815.853760 682.813171,816.003418 686.403015,814.822083 
	C694.482483,812.163269 702.182373,808.598816 709.332825,803.989685 
	C712.268433,802.097351 715.844299,801.348755 718.597412,798.454590 
	C719.028503,797.967651 719.214661,797.785034 719.727783,797.387390 
	C722.548950,796.790710 724.809998,795.348328 727.731567,795.792603 
	C727.372620,800.258545 725.254822,802.259949 720.734009,803.338135 
	C716.441040,804.362061 712.685547,807.619019 708.678406,809.866760 
	C701.497742,813.894653 694.482727,818.280945 687.043396,821.757874 
	C683.323120,823.496643 679.118042,824.749695 674.412842,825.002075 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M728.273193,796.026123 
	C725.551147,796.445312 723.579590,798.725403 720.393799,797.996704 
	C720.507629,794.217529 724.430847,791.888611 729.683228,792.720093 
	C729.755127,793.810486 729.505432,794.613647 729.128662,795.709595 
	C729.001526,796.002441 728.515930,796.017334 728.273193,796.026123 
z"/>
<path fill="#212C3D" opacity="1.000000" stroke="none" 
	d="
M720.755615,902.851807 
	C717.421936,904.352844 714.047058,906.766663 709.983887,904.439331 
	C713.546936,904.025818 716.773315,901.439514 720.755615,902.851807 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M799.604187,877.320251 
	C799.998169,875.770325 799.949097,874.527527 801.696533,874.034180 
	C803.534668,875.928955 802.570435,878.183472 802.900757,880.689941 
	C804.734619,882.656433 806.737000,882.756897 808.937439,882.073608 
	C812.308899,881.026917 815.784668,881.310791 819.185547,881.772095 
	C821.077515,882.028748 823.148438,882.627991 823.142090,885.525085 
	C821.899536,886.305298 820.902405,886.744568 819.576294,887.197021 
	C819.022156,886.912048 818.728943,886.726990 818.181152,886.879395 
	C818.005493,887.085938 818.014465,887.016418 818.043640,887.035767 
	C813.234375,889.007874 808.237183,888.423462 803.454468,887.287598 
	C798.991882,886.227661 798.378052,884.126282 799.604187,877.320251 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M823.260681,885.868530 
	C819.126892,881.099670 814.046387,883.223083 809.419189,883.466614 
	C807.015381,883.593079 804.538696,885.000488 802.994568,881.478271 
	C805.562256,881.217834 808.141724,881.101746 810.642578,879.181335 
	C813.254944,878.845032 815.503967,879.011169 817.774048,878.313965 
	C821.912476,877.042786 825.382751,879.105408 828.936279,881.623535 
	C828.379517,884.776367 825.758423,884.978577 823.260681,885.868530 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M799.498413,877.020203 
	C800.339844,886.232178 801.169556,886.998901 810.728149,886.991211 
	C812.863403,886.989502 814.998718,887.004822 817.603394,887.033691 
	C817.775879,888.441345 816.806763,889.253174 815.323608,889.950012 
	C812.049927,888.387329 808.828979,889.275452 805.731018,889.018188 
	C797.839050,888.362671 795.588623,884.303162 799.498413,877.020203 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M829.358398,881.733521 
	C828.188232,881.806946 826.963928,881.970276 826.291016,881.437378 
	C822.952942,878.793640 819.428711,878.560852 815.496460,879.820496 
	C814.340393,880.190735 812.905884,879.691589 811.300415,879.350525 
	C816.085022,877.119995 821.233948,875.997009 826.501404,878.351746 
	C827.867493,878.962402 829.863953,879.178894 829.358398,881.733521 
z"/>
<path fill="#212C3D" opacity="1.000000" stroke="none" 
	d="
M810.910400,922.748779 
	C810.010803,922.949524 809.308472,922.579834 809.115662,921.271484 
	C810.030090,921.062073 810.718994,921.440613 810.910400,922.748779 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M818.305542,887.221130 
	C818.260864,886.700562 818.546204,886.463257 819.106628,887.051025 
	C819.033325,887.258850 818.819458,887.307556 818.305542,887.221130 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M963.071106,762.599731 
	C962.126099,764.739136 960.693665,765.688599 958.810364,766.221313 
	C946.708008,769.644714 936.189575,776.445740 924.964050,781.704224 
	C916.599548,785.622498 906.689270,786.620300 896.551514,783.032593 
	C885.573425,779.147461 877.182373,771.095215 866.534790,767.048462 
	C864.950745,766.446350 864.205139,765.320862 863.468323,763.989868 
	C862.993774,763.132507 862.364014,761.715637 861.744019,761.682983 
	C854.573425,761.304688 851.291199,754.771912 845.869507,751.612610 
	C837.680420,746.840820 830.041931,741.171204 821.106323,737.664551 
	C814.373474,735.022339 809.195190,729.898926 803.402283,725.786011 
	C796.841431,721.127991 789.658142,721.780945 784.297668,727.941284 
	C783.896851,728.401917 783.174377,728.582642 782.247253,728.922546 
	C781.529602,728.642212 781.255432,728.267639 780.993469,727.484741 
	C780.892456,726.678528 780.927979,726.219360 781.128784,725.442871 
	C781.561340,724.304871 782.255127,723.686096 782.518311,722.481384 
	C782.694519,721.470825 782.926025,720.824585 783.408691,719.892334 
	C784.432190,718.673645 785.448975,717.996399 786.837402,717.211243 
	C794.526978,716.793884 801.371094,717.349243 807.907715,721.918762 
	C815.738953,727.393250 824.089294,732.208374 832.822693,736.976196 
	C833.933105,737.223389 834.619385,737.536377 835.596436,738.110718 
	C843.678894,743.341675 850.875671,748.981812 859.760559,752.570312 
	C866.391968,755.248718 871.797180,761.347412 878.440430,764.786621 
	C884.322510,767.831665 889.373535,771.796143 894.148865,776.269775 
	C895.655090,777.680847 897.858582,777.730896 899.772461,778.162903 
	C905.805176,779.524475 911.999329,779.616211 917.845337,777.787537 
	C924.097717,775.831787 930.285156,773.624451 936.079529,770.259888 
	C942.100464,766.763794 949.342285,765.997253 955.839233,762.517456 
	C957.275879,761.250549 958.476257,760.297668 960.498901,760.235535 
	C961.937561,760.574585 962.738831,761.169006 963.071106,762.599731 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M781.302429,726.538818 
	C781.724243,727.157837 781.800598,727.667358 781.885010,728.564209 
	C780.907837,730.729492 779.922546,732.507446 778.839355,734.627686 
	C774.046570,740.556213 770.134521,746.651245 766.887634,753.548828 
	C766.875732,753.911255 766.797852,754.311462 766.745789,754.507019 
	C765.000427,755.765198 763.307129,756.827820 761.334717,758.086426 
	C760.885559,758.099609 760.715454,757.916687 760.432861,757.375793 
	C760.471558,754.214905 762.028625,751.901306 763.166138,749.058777 
	C768.400757,743.202942 772.896118,737.267273 776.510864,730.679321 
	C777.528687,728.824280 778.359070,726.674683 781.302429,726.538818 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M963.305908,762.927490 
	C962.441345,762.489136 961.886658,761.999023 961.163574,761.284119 
	C960.622803,760.665955 960.464478,760.199707 960.671326,759.307129 
	C961.879822,758.171631 963.030029,757.655457 964.711182,757.888306 
	C965.034790,758.816895 965.024353,759.551636 965.020142,760.654358 
	C964.883179,761.888977 964.582092,762.635498 963.305908,762.927490 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M965.368652,757.994019 
	C963.933228,758.392700 962.821167,758.703308 961.343384,758.490723 
	C961.628723,757.196838 962.279724,756.426086 962.984131,755.353394 
	C963.037415,755.051453 963.508850,755.011536 963.744751,754.995972 
	C964.783691,755.009033 965.586853,755.037659 966.792969,755.118164 
	C967.035278,756.269226 967.000183,757.437439 965.368652,757.994019 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M755.183594,766.777466 
	C751.947205,770.286743 747.661499,772.361389 745.246033,776.764465 
	C741.526306,780.406799 738.455078,784.213074 734.458374,787.604614 
	C733.935852,787.904297 733.716248,788.007141 733.148438,788.218750 
	C723.878601,792.095520 714.659668,795.246948 706.608215,800.829041 
	C704.412964,802.351135 701.786377,803.053101 699.288330,804.808105 
	C698.455444,805.178955 697.948975,805.306335 697.026489,805.402039 
	C690.104004,805.470520 684.858704,809.694275 678.707520,810.914429 
	C669.487122,812.743408 660.393372,815.208740 651.509155,818.117004 
	C644.403381,820.442932 636.975769,820.611023 629.854370,823.050781 
	C622.455688,825.585632 614.497498,826.773193 606.390015,828.592896 
	C603.498657,828.427551 601.606689,829.975830 599.112915,831.018921 
	C598.205872,831.326904 597.663269,831.411377 596.685303,831.447754 
	C588.770691,832.313782 581.521667,834.955933 573.849548,834.459534 
	C571.196045,834.287781 568.432251,834.383606 567.552612,830.629395 
	C568.961975,828.418274 570.751709,828.823120 572.607178,829.048218 
	C578.267090,829.734985 583.870972,829.810852 589.357849,826.509766 
	C598.632629,823.406433 607.756165,821.105103 616.850647,818.968628 
	C627.754395,816.407104 638.842285,814.413818 649.608948,811.382019 
	C660.422668,808.337036 671.442200,806.133484 682.181152,802.960327 
	C690.999329,800.354614 699.843750,797.510010 708.424561,793.067810 
	C708.929810,792.681458 709.128296,792.525452 709.644043,792.171753 
	C710.842468,791.583618 711.724915,791.195251 712.736572,790.325134 
	C714.532288,789.474976 716.060486,788.912415 718.011230,788.581055 
	C721.121399,788.325012 723.122437,786.751526 725.115723,784.542236 
	C727.516602,781.235840 732.872070,782.130005 733.933105,777.180298 
	C734.406494,776.246216 734.843872,775.709351 735.652344,775.041687 
	C741.913635,773.466919 746.362122,769.958191 749.880859,764.620483 
	C751.509460,761.656677 753.705261,759.943726 757.032227,759.161377 
	C758.623352,759.376831 759.448853,759.939270 759.206787,761.635742 
	C758.032654,762.510376 757.327087,763.244080 756.986328,764.639526 
	C756.457581,765.537476 755.921692,766.060242 755.183594,766.777466 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M734.306335,787.237305 
	C736.688354,782.917053 740.324036,779.811523 744.649902,777.028381 
	C744.786682,779.721741 741.960449,780.926514 741.133423,783.686279 
	C740.234192,785.371277 739.267517,786.512634 737.333374,786.967285 
	C736.306946,787.603027 735.520325,787.774353 734.306335,787.237305 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M759.326416,761.914429 
	C758.899780,761.210449 758.750977,760.474304 757.653442,760.070068 
	C756.241028,757.262024 758.160522,756.783142 760.531616,756.470459 
	C760.941589,756.878418 760.951782,757.159180 761.008789,757.861206 
	C760.927795,759.601562 761.358276,761.138245 759.326416,761.914429 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M755.296387,766.926636 
	C755.077820,766.094177 755.397156,765.374023 756.617432,765.010742 
	C756.898315,765.871643 756.610229,766.617004 755.296387,766.926636 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M698.937744,804.410767 
	C704.431580,799.283203 710.604736,795.452881 717.331360,793.372131 
	C722.610229,791.739136 726.486877,787.218628 732.611938,786.993408 
	C733.579529,787.688782 733.729614,788.460327 733.223083,789.652588 
	C732.510803,790.300903 732.039612,790.611511 731.278931,790.957397 
	C722.922913,793.588196 715.932678,798.074768 709.170410,803.629639 
	C707.103333,806.010681 704.771973,807.029907 701.600098,806.975586 
	C700.128235,806.536194 699.296570,805.899841 698.937744,804.410767 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M708.289429,803.244019 
	C710.022644,798.174805 715.253906,797.679626 719.005371,795.450134 
	C722.307068,793.487915 725.584045,790.178894 730.585999,790.991089 
	C730.739197,791.493958 730.488831,791.995300 730.121704,792.751953 
	C726.019836,792.842041 723.158203,795.251831 720.069336,797.651184 
	C719.995544,797.992676 720.000000,798.000000 719.996643,797.997559 
	C716.648376,800.779053 713.178711,803.304260 708.289429,803.244019 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M733.344971,789.988525 
	C732.991943,789.243347 733.001953,788.496521 733.007202,787.376099 
	C733.002441,787.002563 733.486816,786.989624 733.728760,786.984619 
	C734.730225,786.988281 735.489868,786.997009 736.629089,787.009827 
	C736.830872,789.028992 735.680969,789.964844 733.344971,789.988525 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M836.161316,907.720337 
	C832.496887,904.640198 828.579285,903.268372 823.503784,905.013916 
	C824.515015,901.537842 830.165039,897.736938 832.948242,898.783325 
	C837.062317,900.330139 836.870605,903.904968 836.161316,907.720337 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M840.952820,718.623169 
	C836.692627,720.366150 834.556396,716.317993 831.321045,715.001343 
	C825.242371,712.527649 819.136719,710.166565 814.014282,705.738281 
	C809.083862,701.475952 802.734192,699.476379 797.093018,696.334351 
	C795.226135,695.294495 793.763489,693.569214 791.245361,692.993896 
	C790.878662,692.228271 790.874695,691.521423 790.871521,690.462036 
	C794.342529,690.010681 796.659607,692.695740 799.302856,694.151489 
	C811.940735,701.111389 823.959106,709.182190 837.126282,715.218384 
	C838.593384,715.890869 840.084473,716.708923 840.952820,718.623169 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M861.954468,730.631836 
	C854.400635,728.950562 847.614624,725.458862 842.060547,719.343140 
	C843.303345,719.170471 844.970581,718.875549 845.861755,719.572571 
	C850.945129,723.548950 857.231750,725.710083 861.954468,730.631836 
z"/>
<path fill="#0F1523" opacity="1.000000" stroke="none" 
	d="
M784.732422,719.873169 
	C784.332886,720.770691 784.042480,721.340820 783.667480,722.221191 
	C783.681580,723.438416 783.018494,723.803955 782.064087,724.354858 
	C778.584656,725.415161 778.044189,723.370911 777.739990,721.012451 
	C776.388977,710.537903 772.241943,701.866577 763.947754,694.679138 
	C757.312073,688.928833 749.487244,685.397888 742.487915,680.442383 
	C740.378479,678.948914 739.020691,679.337585 737.855408,681.771118 
	C736.353516,684.907532 734.428345,687.819214 733.492432,691.243103 
	C732.042053,696.548584 728.001770,700.263672 724.941650,704.570923 
	C724.113953,705.735901 722.782471,706.061279 721.044922,705.353394 
	C719.951416,703.893738 720.282471,702.668274 720.971008,701.110352 
	C728.058105,692.452576 729.985718,682.270996 731.797607,671.724365 
	C731.547607,670.469482 731.542786,669.522156 731.065918,668.312256 
	C730.868896,667.503845 730.806213,667.031311 730.783569,666.191650 
	C730.852783,665.346619 730.978943,664.880371 731.339600,664.112427 
	C732.298218,662.841736 733.503479,662.443970 734.683167,661.414795 
	C735.440674,661.015198 735.901794,660.850830 736.741699,660.670044 
	C737.833801,660.533508 738.562012,660.448425 739.656982,660.518860 
	C741.200684,660.457458 742.381775,660.458496 743.891846,660.697510 
	C745.495605,661.034241 746.768616,661.243713 748.396057,660.942810 
	C749.388367,660.827026 750.049194,660.868835 751.017212,661.138489 
	C751.506775,661.400146 751.682617,661.539795 751.939575,662.006348 
	C752.708557,666.487366 761.397827,676.225098 765.323425,676.931335 
	C767.122620,677.255005 768.087158,677.975159 769.142517,679.527100 
	C772.739807,684.817200 776.773621,689.808044 780.469421,695.034302 
	C784.164734,700.259888 785.573792,706.140076 784.630249,712.499817 
	C784.461304,713.638550 783.828979,714.717896 785.113281,715.996826 
	C785.851685,717.445435 785.222778,718.474121 784.732422,719.873169 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M835.372681,739.004517 
	C834.509460,738.685120 834.015991,738.427063 833.270142,738.050171 
	C823.281982,730.246155 812.398499,724.453613 801.498108,718.664978 
	C798.745728,717.203308 795.934814,716.748779 792.930908,716.586365 
	C790.999207,716.481873 788.895569,716.893127 786.937561,715.144775 
	C785.326660,712.465393 788.440735,711.437866 788.657532,709.166382 
	C790.685974,707.361328 792.530334,708.104492 794.498413,708.676880 
	C803.400879,711.266174 810.918640,716.386963 818.651062,721.258606 
	C830.031250,728.428650 841.835938,734.889160 854.154236,740.363953 
	C857.961792,742.056213 861.269592,744.647583 865.189819,746.895020 
	C869.654663,750.767334 873.566589,754.801270 879.099731,756.461609 
	C883.080933,757.656250 884.916626,762.410034 889.803223,762.765747 
	C890.856689,763.098572 891.481567,763.477234 892.339844,764.167542 
	C894.627930,767.430725 896.780090,770.225708 901.419067,769.481812 
	C903.820801,769.152588 905.716675,770.278259 908.112427,770.070312 
	C912.153198,771.637573 915.865540,772.847046 920.187744,771.255249 
	C921.958374,770.597717 923.434875,770.122742 925.119751,769.280518 
	C926.459412,768.908997 927.609558,769.165405 928.766602,768.223389 
	C929.467041,767.683594 929.936523,767.367188 930.697266,767.014709 
	C932.263550,765.961853 933.685303,765.264221 935.647461,764.967957 
	C935.991028,764.941040 936.474304,764.927368 936.716003,764.924988 
	C937.379578,764.593933 937.856934,764.448914 938.744141,764.635437 
	C940.700806,769.909912 935.833435,770.466858 933.353027,771.961548 
	C928.442200,774.921021 922.542114,775.820679 917.400940,778.584839 
	C914.070740,780.375366 909.987549,779.203064 906.677063,778.515015 
	C900.821777,777.297974 893.791138,778.338684 889.794067,771.925659 
	C887.353882,768.010620 882.474182,767.736389 879.035889,765.184265 
	C866.646729,755.988403 853.552124,747.877136 839.915100,740.653137 
	C838.599731,739.956238 837.223572,739.357239 835.372681,739.004517 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M908.680054,760.939575 
	C908.834595,763.191223 911.232727,763.665588 911.949463,765.632690 
	C910.627930,765.966858 909.239563,765.977234 907.434937,765.985962 
	C905.997498,764.899170 904.579163,764.373291 903.221436,763.226440 
	C902.997559,763.006165 902.503479,762.996704 902.256348,762.994934 
	C901.326172,762.196960 900.382568,761.812622 899.234619,761.188721 
	C898.426086,760.498352 897.849487,760.004028 897.141724,759.251587 
	C890.928223,753.966003 882.872070,752.757263 876.105347,748.283020 
	C875.527039,748.101074 875.301697,748.006958 874.760010,747.725708 
	C866.485962,741.888306 857.594238,737.927063 849.051636,733.315430 
	C831.727173,723.963074 814.309448,714.785706 797.006104,705.395325 
	C794.676514,704.131042 792.441101,702.661560 790.458008,700.311157 
	C790.309021,698.541931 790.403625,697.046082 790.681519,695.224792 
	C796.955566,700.053040 804.495361,702.950195 810.931824,707.380432 
	C824.195496,716.510071 839.798950,721.219849 852.825623,730.717651 
	C856.873169,733.668762 862.053467,735.021423 866.570923,737.382080 
	C876.474976,742.557617 885.637329,749.140869 896.612488,753.005737 
	C897.526001,753.542236 898.066467,754.066772 898.806641,754.790894 
	C899.550171,755.174744 900.093628,755.359253 900.819702,755.771851 
	C902.002808,757.107849 903.424805,757.628418 904.782715,758.770386 
	C906.124634,759.618408 907.241150,760.248169 908.680054,760.939575 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M865.332397,748.236633 
	C850.475952,739.630554 834.732300,733.565308 820.675842,724.282654 
	C811.015198,717.902832 801.370972,711.339905 789.282959,708.968628 
	C789.016968,707.775940 789.155396,706.609253 789.458862,705.128540 
	C794.810608,703.388977 799.100769,706.133789 803.121033,708.415527 
	C815.064148,715.193604 826.947693,722.082397 839.257812,728.164795 
	C843.747681,730.383240 847.307861,734.066895 852.411865,735.864319 
	C853.932434,736.642334 854.941406,737.680420 856.752686,737.756104 
	C858.384583,738.245056 859.509033,738.986816 860.902832,739.922852 
	C861.885071,740.598206 862.430969,741.340698 863.688782,741.550171 
	C865.228943,741.859009 866.311829,742.358826 867.497986,743.461121 
	C868.360718,744.646484 868.690735,745.630615 868.560791,747.117920 
	C867.792786,748.600830 866.844055,748.872314 865.332397,748.236633 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M867.240234,744.241455 
	C865.903015,743.665405 864.805969,743.330750 863.360596,742.986206 
	C862.469971,742.455017 861.931274,741.930115 861.195312,741.203552 
	C859.564636,741.019287 858.630066,740.041931 857.294373,739.138306 
	C856.125244,737.787415 854.534546,738.232483 853.228821,737.235840 
	C851.327942,737.016357 849.727112,736.996399 848.294739,735.850159 
	C837.734192,727.399536 824.808167,723.029358 813.477661,715.774292 
	C806.488708,711.299255 798.957336,706.734070 790.021790,704.899292 
	C789.746094,703.481140 789.868347,702.147827 790.102539,700.426147 
	C794.983093,700.699158 798.217224,704.368164 802.285706,706.417725 
	C815.320923,712.984619 827.876526,720.472107 840.989624,726.954651 
	C852.096375,732.445374 862.753784,738.845764 873.589661,744.880371 
	C874.120605,745.175964 874.455444,745.823608 874.952759,746.644958 
	C874.553955,747.903442 873.689880,748.291626 872.444702,748.722473 
	C871.619629,748.859802 871.144958,748.837830 870.344360,748.611450 
	C869.838257,748.322571 869.661377,748.167664 869.311523,747.708740 
	C868.966858,746.214661 868.354248,745.274719 867.240234,744.241455 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M715.838806,544.690308 
	C720.388000,552.639832 723.703918,560.670166 726.048584,569.209290 
	C727.510620,574.533813 730.215515,579.517090 732.669861,584.834839 
	C732.172180,588.709778 735.065430,591.852722 734.296387,595.760864 
	C732.142700,596.768372 731.602783,594.789246 730.112305,593.902954 
	C729.356812,593.031982 729.019165,592.350281 728.704346,591.239380 
	C725.881653,581.482300 723.309631,572.023254 719.362549,563.067688 
	C715.947815,555.319763 712.752136,547.430542 707.964600,540.354370 
	C707.192261,539.212830 706.884399,537.921143 707.738037,536.276245 
	C709.181030,535.995667 709.837585,536.803650 710.735596,537.809692 
	C713.930542,539.106689 715.240173,541.422546 715.838806,544.690308 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M755.226624,661.663574 
	C754.261780,662.055481 753.509460,662.104858 752.388916,662.243774 
	C752.020752,662.333252 751.996399,662.175354 751.994873,662.095459 
	C748.079834,656.335327 746.587280,649.590820 744.404602,643.257446 
	C742.245728,636.992859 739.007751,631.282776 736.742798,625.131775 
	C735.215637,620.984131 733.500000,616.869934 733.151184,612.363647 
	C733.043396,610.972229 733.035767,609.727661 734.416809,608.459167 
	C735.289490,608.054504 735.828796,607.975830 736.785034,608.096802 
	C740.408630,612.308838 741.001709,617.559021 742.818665,622.233276 
	C746.268066,631.107361 749.967346,639.915222 752.952332,648.959412 
	C754.256409,652.910583 756.374329,656.856323 755.226624,661.663574 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M755.328735,661.833008 
	C755.038330,655.340637 751.454712,649.756836 749.538757,643.671448 
	C747.281860,636.503418 743.702942,629.874329 741.568420,622.586731 
	C740.307922,618.283386 738.572815,613.999512 737.266235,609.268555 
	C735.368103,607.556519 735.281738,605.143433 733.481201,603.375122 
	C732.891541,602.090698 732.838684,601.106567 733.635803,599.536987 
	C736.275452,598.739929 736.915527,600.161987 737.459595,601.862244 
	C739.962219,609.684387 741.791809,617.835632 745.289734,625.190125 
	C748.138733,631.180481 749.294189,637.728943 752.438782,643.483948 
	C755.128052,648.405823 754.992676,654.009277 756.809326,659.117065 
	C757.169495,660.129578 756.483582,661.014954 755.328735,661.833008 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M786.532104,715.029053 
	C796.393127,713.790588 804.054626,718.432312 812.270874,722.914246 
	C817.096741,725.546692 821.426331,729.162415 826.672729,731.120483 
	C829.529297,732.186707 831.516418,734.516663 832.968933,737.593872 
	C829.036987,738.755493 826.405823,735.595947 823.906799,733.819336 
	C817.213928,729.060974 809.783630,725.560181 803.138367,720.735535 
	C798.732788,717.537109 792.882935,719.210754 787.366089,717.976257 
	C786.530884,717.474182 786.327271,716.912964 786.401855,715.829834 
	C786.484619,715.378845 786.441956,715.122559 786.532104,715.029053 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M730.670410,593.348755 
	C731.844849,593.892090 732.694580,594.772766 733.799011,595.835083 
	C734.057312,596.755737 734.060974,597.494568 734.094971,598.601440 
	C734.125305,598.969482 734.042542,598.984985 734.000854,598.990845 
	C729.499878,599.441162 729.792175,596.652466 730.670410,593.348755 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M786.282227,715.488220 
	C786.623108,715.947754 786.761658,716.516602 786.915283,717.524902 
	C786.338013,718.657043 785.745605,719.349854 784.888184,720.121582 
	C784.119263,718.968201 784.861511,717.972473 785.333008,716.570618 
	C785.669495,716.052734 785.890198,715.841309 786.282227,715.488220 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M904.944092,758.657959 
	C903.390808,758.752747 901.860840,758.371094 901.063110,756.332153 
	C902.611206,756.248901 904.138977,756.632812 904.944092,758.657959 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M898.950928,754.677917 
	C898.117676,754.898682 897.362732,754.617798 897.043335,753.329224 
	C897.878723,753.102905 898.635437,753.381836 898.950928,754.677917 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M869.793579,748.271606 
	C870.457397,747.977539 870.899231,747.978149 871.672363,747.978516 
	C873.830322,748.083435 875.314758,749.265747 877.413940,749.645996 
	C880.882141,750.900146 883.984314,752.130249 885.807617,755.692627 
	C890.696228,759.184204 895.490601,762.241577 900.049622,765.617004 
	C903.788025,768.384827 908.049927,768.995117 912.707153,769.754883 
	C912.682068,772.233093 911.219238,771.921082 909.346313,771.278809 
	C906.616394,771.016418 904.257385,770.998596 901.456299,770.977844 
	C897.578857,770.071777 894.944580,767.924011 892.248169,765.245483 
	C891.508545,764.738586 891.014709,764.477112 890.266968,764.098267 
	C884.672302,760.262695 879.156921,756.831055 873.510803,753.589783 
	C871.636108,752.513489 869.216858,751.535767 869.793579,748.271606 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M886.240356,755.762695 
	C883.240845,754.353760 880.477905,752.718384 877.364502,751.028625 
	C876.124023,750.036133 875.100586,749.156677 875.772217,747.317261 
	C883.126099,750.467651 890.960571,752.626770 896.945190,758.656494 
	C893.314575,758.404236 890.584534,754.871704 886.240356,755.762695 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M961.006714,759.019165 
	C961.007080,759.485901 961.004395,759.934570 960.998413,760.721313 
	C959.561707,760.995911 958.625854,761.968140 957.287842,762.852295 
	C953.476807,764.459595 949.892944,765.674805 945.642578,765.607422 
	C943.283081,760.989868 948.135620,762.275024 949.726807,760.302673 
	C950.478516,760.053711 950.913330,760.045410 951.674438,760.033569 
	C954.956787,760.397095 957.530762,758.598633 960.705139,758.755737 
	C960.997559,758.965820 961.003662,759.001099 961.006714,759.019165 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M909.081787,771.395020 
	C910.146973,770.864319 911.318665,770.694336 912.741699,770.275024 
	C914.384460,770.021240 915.775940,770.016785 917.584595,770.014587 
	C919.269226,769.912537 920.489746,769.924744 921.083862,771.650024 
	C916.986328,775.557068 913.094849,773.302917 909.081787,771.395020 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M921.365173,772.006836 
	C920.136108,771.500488 919.269836,770.991394 918.202637,770.249634 
	C920.045227,766.651489 923.383118,768.496887 926.567566,767.995483 
	C927.461853,768.718872 927.027466,769.171143 926.259705,769.803223 
	C924.830994,771.209839 923.608826,772.312683 921.365173,772.006836 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M949.654297,760.052307 
	C949.726807,763.014404 946.146362,762.166504 945.040955,764.624146 
	C944.055237,766.013916 942.913147,766.103577 941.274292,765.529785 
	C940.269897,764.528625 940.187683,763.675354 940.757202,762.388672 
	C944.052124,762.588318 946.394714,760.210327 949.654297,760.052307 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M960.994141,758.716187 
	C958.114075,759.178162 955.660278,761.730713 952.258362,760.276611 
	C954.493713,758.443359 957.273254,757.998474 960.569031,757.975098 
	C960.977600,757.967529 960.990723,758.466614 960.994141,758.716187 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M940.621643,762.012207 
	C941.001953,762.753784 940.999023,763.492920 940.995605,764.601868 
	C940.613586,765.311035 940.164734,765.461914 939.345947,765.251526 
	C938.572876,765.029663 938.111450,765.005920 937.303833,764.952393 
	C936.857178,762.606750 938.133118,761.841248 940.621643,762.012207 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M935.760071,764.708374 
	C934.911682,766.356079 933.415466,766.896912 931.346069,766.982117 
	C931.675476,764.644775 933.174500,763.784180 935.760071,764.708374 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M903.059937,763.338013 
	C904.614441,763.240723 906.134399,763.638000 906.952515,765.654114 
	C905.393738,765.762085 903.866211,765.375671 903.059937,763.338013 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M926.354370,769.997742 
	C926.262146,769.508240 926.517700,769.009094 926.885132,768.252563 
	C927.441650,767.993225 927.886230,767.991394 928.664185,767.994751 
	C928.870239,769.399231 928.037048,769.983398 926.354370,769.997742 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M899.151245,761.276917 
	C900.138855,761.046753 901.361267,760.915283 901.938965,762.658325 
	C900.868652,762.965088 899.935974,762.543640 899.151245,761.276917 
z"/>
<path fill="#D8D7D6" opacity="1.000000" stroke="none" 
	d="
M908.002197,638.751831 
	C902.896729,639.631226 904.615723,645.415466 901.258789,647.785645 
	C899.373291,642.758240 902.348877,637.790649 904.529053,634.322021 
	C908.894287,627.376892 909.856506,619.534302 912.987793,612.370605 
	C914.363892,609.222351 916.150208,606.243286 916.232239,602.515686 
	C916.278442,600.413940 916.766418,598.627808 914.652832,597.352539 
	C913.922974,596.912109 913.221924,596.325867 913.537170,595.367737 
	C916.428833,586.578125 912.063538,580.277649 906.711670,574.022644 
	C904.342651,571.253906 902.062927,568.248291 901.794556,564.016357 
	C901.580750,560.645996 900.224060,557.108582 896.153503,556.016663 
	C893.470337,555.296875 894.392822,554.385803 895.812866,553.086304 
	C900.910767,548.421387 900.867981,548.398376 895.782776,543.842590 
	C893.470093,541.770691 893.060669,538.994934 893.517517,536.176697 
	C893.794495,534.468506 894.434082,532.604797 892.866089,531.576538 
	C890.141602,529.789917 889.642700,527.005920 888.315857,524.402039 
	C885.298035,518.479797 882.733398,512.453918 880.797241,506.125000 
	C880.414001,504.872223 879.476318,503.898071 878.258301,504.156830 
	C871.840576,505.520386 869.476685,500.791962 866.715515,496.660980 
	C865.993225,495.580322 865.075378,495.065887 863.826721,495.025421 
	C862.645752,494.987122 861.298584,494.982971 860.608398,493.969940 
	C856.018738,487.233459 848.209900,486.243896 841.537354,483.338989 
	C835.760864,480.824188 831.789124,475.625244 825.625366,473.627075 
	C820.875488,472.087311 817.008850,467.997498 811.397095,468.769867 
	C810.328979,468.916870 809.703674,467.983093 809.204407,467.036163 
	C806.512573,461.930939 798.258179,459.024170 792.731506,458.322968 
	C784.189880,457.239258 781.190002,464.866058 775.150635,467.428619 
	C775.147949,475.607208 765.906982,478.183929 764.113647,485.696045 
	C762.738281,485.201172 761.519653,484.381989 760.152832,483.280640 
	C759.648865,479.039154 761.519958,475.831635 763.626526,472.771698 
	C764.350525,471.719940 765.586792,471.018707 765.640747,469.165649 
	C765.858215,468.325012 766.082275,467.885376 766.668457,467.240356 
	C769.646667,466.284088 771.035156,464.805695 772.245117,461.966705 
	C774.476013,456.732361 777.087341,451.205444 783.732361,449.872528 
	C785.256897,449.566772 786.373047,448.606506 787.477173,447.562408 
	C788.884583,446.231598 790.765381,445.492950 792.524780,446.176208 
	C796.179138,447.595398 800.627258,447.954712 803.539978,449.791748 
	C813.119202,455.833344 823.361633,460.881042 832.254822,467.995697 
	C832.600342,468.272095 832.695496,468.861450 832.956055,469.649414 
	C832.346985,474.592804 836.547607,475.879486 839.052368,476.534119 
	C844.682434,478.005554 849.587280,480.326935 854.599243,483.246643 
	C857.780029,485.099609 861.608398,485.965424 865.077576,488.033081 
	C869.874878,490.892303 874.036865,494.525024 878.719543,497.455139 
	C881.373718,499.115936 883.459961,500.935608 884.630127,504.328674 
	C885.475586,506.780212 886.808960,509.791016 889.582214,511.474091 
	C891.409424,512.583008 891.846558,514.898804 891.852356,516.778931 
	C891.870911,522.796326 895.230225,527.238403 898.667236,531.923950 
	C899.009766,531.993469 899.504395,531.997437 899.751709,532.000488 
	C902.626099,537.211121 904.195496,542.733826 904.406006,548.550049 
	C904.488770,550.839050 905.174377,552.707153 906.741699,554.729370 
	C907.124146,555.304932 907.318909,555.559021 907.788513,555.885010 
	C909.017212,557.235413 910.326294,558.230957 911.117432,560.057251 
	C911.412903,561.478455 911.713013,562.529602 912.667236,563.670166 
	C914.018677,565.982422 913.356934,568.479553 914.766479,570.749146 
	C920.936157,580.707336 923.198181,590.963135 920.797180,602.349670 
	C920.024780,606.012573 919.701294,609.779480 918.199463,613.677490 
	C918.019348,614.764343 918.012939,615.508850 918.002014,616.625732 
	C914.142883,617.173218 914.797058,620.882935 913.904785,622.921082 
	C911.845703,627.624451 907.837402,631.584045 908.004517,637.601807 
	C908.007324,638.007324 908.004395,638.503662 908.002197,638.751831 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M766.586182,468.718353 
	C765.703308,473.894135 762.050781,477.653534 760.152344,482.674744 
	C759.501831,482.733704 758.998962,482.468933 758.242798,482.072449 
	C757.970398,481.765717 757.951355,481.590668 757.904480,481.153015 
	C756.543762,476.222229 760.944214,473.781799 762.338074,469.779419 
	C763.674072,468.632355 764.840942,468.110229 766.586182,468.718353 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M763.391235,469.740082 
	C762.737732,474.115601 759.216431,476.577667 757.961243,480.551666 
	C757.359558,480.644714 756.842529,480.399017 756.060242,480.036438 
	C755.795044,479.919556 755.600769,479.473969 755.535950,479.239563 
	C758.303345,474.202148 761.135559,469.399170 764.260132,464.404236 
	C766.860657,466.379730 764.913025,467.830505 763.391235,469.740082 
z"/>
<path fill="#039EBD" opacity="1.000000" stroke="none" 
	d="
M635.134644,499.413879 
	C632.115601,498.115570 631.104858,496.437317 631.914490,493.337006 
	C632.612732,490.663208 632.600830,487.811005 633.013550,485.054260 
	C634.149841,477.464783 629.442688,470.416229 630.987671,462.319519 
	C633.837891,458.838776 637.968994,458.865387 641.470276,458.283722 
	C649.570374,456.938019 657.720886,455.178680 666.518555,456.046082 
	C667.812561,459.960510 664.530151,460.836151 662.217957,462.076721 
	C659.895386,463.322845 658.644348,464.663635 658.964355,467.445251 
	C659.592896,472.906647 655.220032,477.300964 655.557556,482.766693 
	C655.907959,488.440613 652.629211,493.103851 651.009155,498.611023 
	C646.718140,501.094025 641.990967,500.347961 637.065918,501.009033 
	C636.073914,500.692230 635.620667,500.330231 635.134644,499.413879 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M635.659180,500.018341 
	C640.716248,499.910736 645.330383,498.459198 650.544678,498.995941 
	C652.946289,499.441711 655.350830,497.975677 656.894287,500.763824 
	C638.807739,500.933014 621.565613,506.110992 604.113220,510.043915 
	C600.718201,510.809021 597.315063,511.537476 593.491882,512.254028 
	C592.303162,509.895508 594.526672,510.285675 595.801025,509.336182 
	C597.567688,508.930206 599.040710,508.732941 600.791504,508.290375 
	C606.793640,506.742737 612.518066,505.440399 618.627319,504.070251 
	C622.363464,502.740631 625.736267,501.566101 629.693176,501.233459 
	C630.016907,501.019958 630.016418,501.026672 630.023438,501.031494 
	C630.516724,500.495270 631.111023,500.155151 632.187378,500.041687 
	C633.461426,499.998260 634.387390,500.000488 635.659180,500.018341 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M656.813232,449.371033 
	C666.050537,450.432068 675.149841,447.592590 684.512695,449.896729 
	C687.432983,449.238342 689.444214,447.061432 692.628357,447.736328 
	C693.247742,450.799896 690.141174,450.534363 689.052246,452.498993 
	C688.612915,453.300873 688.136169,453.516571 687.166077,453.289429 
	C677.119446,452.991119 667.447327,452.872925 657.318481,452.610931 
	C656.058350,451.605316 656.035645,450.657471 656.813232,449.371033 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M656.665771,449.059967 
	C657.021240,449.998291 656.967285,450.904755 656.887512,452.139221 
	C655.847168,452.593048 654.832581,452.718933 653.431030,452.833313 
	C652.380676,452.639771 651.954163,452.145081 651.278076,451.443787 
	C651.031128,451.023682 651.022949,450.824585 651.004150,450.327057 
	C652.508850,448.438385 654.466248,449.173248 656.665771,449.059967 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M627.387146,458.730164 
	C628.188599,456.299072 630.508606,456.906738 632.656067,456.703003 
	C631.470337,458.350433 629.625244,458.640900 627.387146,458.730164 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M644.240784,454.766724 
	C644.355713,453.294678 645.193787,452.780701 646.786011,453.710327 
	C646.409851,454.803314 645.540222,454.947601 644.240784,454.766724 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M651.041138,451.526428 
	C651.876160,451.183075 652.561523,451.419250 652.980835,452.538208 
	C652.524536,453.144470 652.005066,453.467102 651.166870,453.711456 
	C650.913086,453.032135 650.978027,452.431091 651.041138,451.526428 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M614.295776,461.723938 
	C614.359375,460.310333 615.171875,459.862244 616.678162,460.606293 
	C616.408386,461.743042 615.561584,461.880646 614.295776,461.723938 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M619.279541,460.720917 
	C619.312134,459.363983 620.241394,459.018707 621.767700,459.154541 
	C621.775879,460.528290 620.868958,460.944550 619.279541,460.720917 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M686.882202,453.378723 
	C687.277039,453.048126 687.762512,452.986908 688.609436,452.874146 
	C688.985596,453.011383 689.000244,453.200165 689.047913,453.670837 
	C688.226929,454.897705 687.529907,454.757172 686.882202,453.378723 
z"/>
<path fill="#1E293E" opacity="1.000000" stroke="none" 
	d="
M596.712280,678.739868 
	C597.665100,680.840332 599.814880,680.757996 601.094788,682.345093 
	C604.197632,686.584290 604.422058,691.599365 606.778870,696.126160 
	C607.567749,697.688843 608.103760,698.979797 608.821655,700.565063 
	C609.979736,704.501831 610.777344,708.210938 613.249023,711.606873 
	C613.598755,712.039246 613.736450,712.214600 614.065796,712.663696 
	C614.367493,713.130432 614.477600,713.323303 614.713501,713.823364 
	C615.025024,714.762146 615.088562,715.412292 615.161255,716.380981 
	C615.213623,717.551636 615.465454,718.345276 615.969482,719.412842 
	C616.709412,721.710876 617.083923,723.754150 617.467896,726.148560 
	C617.863098,729.045532 618.849854,731.408508 619.876953,734.116577 
	C620.603027,735.458801 621.137085,736.506226 621.859497,737.860474 
	C622.630554,739.751526 622.936401,741.415344 623.649658,743.325562 
	C625.800598,745.785889 628.162476,744.566711 630.281616,744.112915 
	C646.028809,740.740906 661.816589,737.522644 677.229004,732.759644 
	C678.660278,732.317383 680.133667,732.034058 682.021729,732.067139 
	C684.149414,732.671936 685.790771,732.559204 687.498352,731.717163 
	C689.984497,730.491272 692.482056,730.728027 695.084839,732.495850 
	C695.483398,736.280212 695.483398,736.280212 688.790771,738.490417 
	C692.423767,738.342163 695.271790,735.433044 699.205505,736.651978 
	C700.739990,738.292236 701.145813,740.007996 701.230774,742.203735 
	C700.154907,745.687805 699.651978,748.570129 702.765259,751.063293 
	C704.956421,752.817993 706.997803,753.510071 709.543823,752.075012 
	C710.697754,751.424622 711.925232,750.876831 712.978760,750.083252 
	C716.594910,747.359192 720.469055,745.961609 725.055542,747.156372 
	C726.497559,747.531982 728.018982,747.054565 729.869507,746.957764 
	C731.453857,747.100342 732.713135,747.580139 733.091675,748.676025 
	C734.826599,753.698792 739.037048,754.819275 743.516663,755.676697 
	C744.808044,755.923950 746.090210,756.326782 747.282776,756.902588 
	C751.290405,758.837524 751.626587,761.461792 748.347656,764.461609 
	C745.042603,767.485352 741.238403,769.806519 737.333435,772.580322 
	C736.176819,773.982727 734.721069,774.229858 733.287964,775.173523 
	C732.816895,775.476257 732.620544,775.583679 732.115845,775.819824 
	C731.385864,776.089539 730.957764,776.206665 730.198730,776.360596 
	C728.658081,776.507874 727.911560,777.381348 726.787231,778.334961 
	C717.636414,784.565186 707.263855,786.572876 697.217529,789.191040 
	C679.743591,793.744934 662.220825,798.197937 644.537354,801.843811 
	C626.847107,805.491089 609.470398,810.429016 591.775208,814.009583 
	C584.847229,815.411377 582.617004,814.180664 579.635010,807.807312 
	C574.887573,797.660889 572.005127,786.859802 568.675964,776.243896 
	C561.462891,753.242676 553.745789,730.404358 546.596924,707.386230 
	C540.925354,689.124756 533.808228,671.340271 528.728333,652.897400 
	C527.440369,648.221191 524.990173,644.016846 523.876709,638.914795 
	C524.164734,637.052368 524.438904,635.572998 524.847412,634.149475 
	C525.612915,631.481750 527.543518,629.505371 530.286438,629.960632 
	C537.363037,631.134827 544.677246,631.226501 550.198059,637.496033 
	C555.763428,643.816101 562.434753,648.923828 570.796448,651.665161 
	C578.291382,654.122498 583.509949,659.558044 585.816345,667.159302 
	C587.543396,672.851074 592.017273,675.534668 596.712280,678.739868 
z"/>
<path fill="#1E283E" opacity="1.000000" stroke="none" 
	d="
M654.327148,666.175415 
	C653.426636,665.028076 652.702820,663.785645 651.346924,664.543091 
	C642.556213,669.453979 632.388245,669.245667 623.123657,672.278381 
	C617.741699,674.040100 611.926697,673.916931 606.770569,676.520569 
	C604.782715,677.524353 603.553467,676.850586 602.829895,674.415527 
	C599.669617,663.779846 596.734497,653.040283 592.832092,642.671631 
	C587.917053,629.612000 585.277405,615.842712 579.371277,603.062927 
	C575.238220,594.119751 572.024658,584.585083 569.752930,574.994263 
	C567.396179,565.044067 561.896057,556.432861 559.146240,546.347412 
	C557.622253,543.933960 557.677490,541.493652 557.784973,539.245117 
	C557.961304,535.552490 559.905273,532.962769 563.863770,532.484558 
	C567.185364,532.083313 570.560059,531.976807 573.826660,532.615845 
	C577.565369,533.347168 580.731689,532.562195 583.601440,530.178223 
	C590.831543,524.172119 599.964661,523.222473 608.957336,520.855774 
	C610.737122,521.257874 611.859253,522.020630 613.145203,523.252930 
	C614.592896,524.998779 614.213074,526.951538 615.146362,528.867371 
	C616.127563,531.164124 616.605042,533.267151 617.773193,535.487549 
	C618.112122,536.262451 618.257080,536.723633 618.421692,537.554688 
	C620.193970,542.427124 620.880554,547.337280 623.838318,551.773682 
	C624.425659,553.308777 624.571838,554.552551 624.612671,556.183044 
	C626.718018,562.781494 628.264160,569.216064 631.533264,575.412598 
	C632.071594,576.397766 632.283752,577.109131 632.452148,578.209290 
	C632.964417,581.173096 633.848755,583.644409 635.039734,586.387939 
	C636.417847,589.550049 638.044678,592.238708 638.530151,595.717285 
	C638.528015,597.009033 638.999451,597.763123 639.657593,598.801331 
	C640.173035,599.920715 640.341125,600.794373 640.526611,602.001465 
	C640.610474,603.265442 641.194702,603.956543 641.813232,605.012207 
	C642.827820,607.527100 643.630249,609.752991 644.318359,612.389038 
	C644.361938,615.151550 644.908691,617.386780 646.619446,619.624695 
	C647.522888,621.480835 647.706116,623.173828 648.213501,625.146240 
	C648.466064,627.599976 649.072021,629.552002 650.543335,631.554688 
	C651.130615,632.684265 651.318909,633.580261 651.527100,634.812988 
	C651.531982,636.067627 652.018311,636.787170 652.650635,637.797485 
	C653.152527,638.886292 653.310852,639.736694 653.496765,640.905029 
	C653.510132,642.114014 653.993958,642.799927 654.620483,643.772217 
	C655.043823,644.646545 655.213745,645.270203 655.395752,646.224731 
	C655.493652,647.194824 655.668640,647.810364 656.085754,648.695251 
	C656.701538,650.229004 657.093323,651.491638 657.458252,653.107666 
	C657.658691,654.351074 658.265076,655.040100 658.833313,656.135376 
	C659.538330,658.045898 659.568970,659.675354 659.216797,661.660950 
	C658.854370,662.875732 658.333435,663.604187 657.503662,664.517639 
	C657.151367,664.924744 657.007446,665.083923 656.621704,665.453491 
	C655.849670,666.035034 655.272278,666.274353 654.327148,666.175415 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M525.024292,637.598267 
	C527.611511,644.422791 529.862061,650.955688 531.885620,657.590698 
	C537.620728,676.395508 544.511536,694.820984 550.228882,713.643738 
	C555.358887,730.532837 561.343018,747.160950 566.815369,763.948242 
	C570.209229,774.359558 573.578247,784.789795 576.511597,795.335876 
	C577.993225,800.662720 580.721924,805.400269 582.586670,810.533508 
	C583.885925,814.110168 587.564575,813.304260 590.465515,812.638000 
	C602.587708,809.854065 614.609619,806.595459 626.791687,804.117920 
	C650.201538,799.356812 673.328796,793.411377 696.519836,787.756653 
	C706.745239,785.263367 717.277954,783.160400 726.644653,777.025024 
	C727.348755,776.635254 727.769653,776.463562 728.593506,776.517456 
	C729.119568,776.774231 729.299622,776.936707 729.636047,777.432373 
	C729.965271,778.524475 729.877197,779.257507 729.354919,780.290100 
	C728.387634,781.376953 727.477112,781.883362 726.101807,782.370056 
	C693.839355,796.219421 659.545105,801.027100 626.209961,809.379700 
	C619.103699,811.160339 611.945557,812.729492 604.452637,814.562256 
	C603.300964,814.557068 602.553040,814.614746 601.496216,815.052002 
	C599.526794,815.650818 597.895142,816.090759 595.874573,816.437744 
	C594.794006,816.537781 594.120850,816.769592 593.140869,817.243652 
	C590.928894,818.129883 588.988770,818.547668 586.589600,818.516479 
	C584.949280,818.457581 583.689941,818.463562 582.104858,819.028320 
	C580.918823,819.188354 580.132629,819.105164 578.989014,818.730042 
	C575.403320,816.044617 574.379211,812.307007 573.184143,808.674011 
	C563.775085,780.069519 554.041321,751.563721 545.230957,722.773499 
	C538.379211,700.383301 529.844543,678.548767 523.601440,655.589722 
	C523.520813,654.072571 523.501404,652.917786 522.214905,651.879578 
	C521.358459,650.909424 521.060547,650.050293 520.891907,648.783264 
	C520.878967,645.267395 519.819946,642.378723 518.329712,639.256836 
	C517.876221,638.307800 517.704163,637.630066 517.572266,636.583801 
	C517.331177,633.385437 516.504578,630.774353 514.389221,628.286011 
	C513.146851,624.300293 512.429565,620.526611 511.068420,616.571045 
	C510.895325,615.113525 510.771088,614.002258 510.075867,612.678772 
	C509.136871,609.640259 509.034180,606.744934 507.762878,603.767029 
	C506.458862,599.851501 505.005524,596.428467 502.566681,593.485657 
	C501.274475,591.926453 500.595673,590.106934 501.019257,587.652466 
	C501.320374,586.804626 501.600372,586.378479 502.256561,585.760864 
	C503.004517,585.270325 503.482452,585.107117 504.360443,585.022827 
	C504.968933,585.058228 505.207184,585.102478 505.776489,585.283447 
	C507.326080,586.386475 508.323486,587.711365 510.366333,587.980957 
	C510.878021,588.222961 511.074371,588.336853 511.532410,588.667603 
	C513.404602,592.116577 517.506042,592.284851 519.589172,595.414551 
	C519.912109,597.800903 521.639832,597.729187 523.572693,598.515686 
	C523.533691,599.986450 527.060730,604.393494 521.734741,601.645752 
	C519.740173,600.616699 517.516663,602.215271 515.626404,603.089233 
	C512.314697,604.620422 515.527405,606.323669 515.777954,607.960266 
	C516.340698,611.635803 515.871643,615.549927 518.633545,618.900757 
	C523.288086,624.311401 525.142212,630.430237 525.024292,637.598267 
z"/>
<path fill="#171D2F" opacity="1.000000" stroke="none" 
	d="
M525.285156,637.726562 
	C523.060547,631.911804 521.085754,625.831482 519.054321,619.374390 
	C519.198486,613.010681 522.389282,610.884521 527.614197,613.974121 
	C532.715576,616.990784 538.003967,619.847961 542.424438,623.927002 
	C542.790527,624.264832 543.112854,624.865356 543.512878,624.919312 
	C550.760315,625.897522 555.269409,631.979248 561.913696,634.563660 
	C572.066467,638.512695 581.947449,644.093811 588.116028,653.893250 
	C589.558655,656.184998 590.098206,659.171265 589.440491,661.972473 
	C589.043457,663.663086 589.190857,665.142944 590.556946,665.955200 
	C595.272888,668.759338 596.043030,673.480652 596.946167,678.603516 
	C591.417664,678.343628 586.848083,675.540955 585.391663,670.254883 
	C582.648560,660.298584 576.015808,654.416992 566.810730,651.627502 
	C558.766296,649.189697 554.012146,643.023132 548.304565,637.858276 
	C545.437805,635.264160 542.783325,632.657227 538.469482,632.941956 
	C537.039734,633.036316 535.529785,632.575684 534.117554,632.160950 
	C527.723755,630.283447 526.892517,630.773193 525.285156,637.726562 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M653.786011,666.313721 
	C654.536133,665.776489 655.034546,665.497498 655.818298,665.182190 
	C658.539734,668.437439 655.626892,670.662781 654.215698,673.529785 
	C650.854492,675.625488 649.339844,678.937439 646.763977,681.275269 
	C639.394897,687.963318 630.372864,691.010620 621.016418,693.271973 
	C616.188171,694.438904 611.537598,696.180847 606.342651,697.141724 
	C603.470886,692.771667 602.339905,688.074158 601.315308,682.996826 
	C602.904785,682.310791 604.550476,681.949829 606.555237,681.550293 
	C608.933777,680.860596 611.187500,680.879456 613.244263,679.415100 
	C613.848083,679.568787 614.147278,679.625488 614.689575,679.537842 
	C619.313843,678.415222 623.795044,677.606628 628.216797,675.460327 
	C630.131042,675.194519 631.685791,674.922607 633.580139,674.593384 
	C634.625549,674.184265 635.326904,673.823975 636.299072,673.456177 
	C642.571228,671.996155 648.207336,669.662415 653.786011,666.313721 
z"/>
<path fill="#1A1F34" opacity="1.000000" stroke="none" 
	d="
M610.716797,521.781372 
	C600.799622,524.605774 590.587830,525.942200 582.682312,532.972168 
	C580.405579,534.996765 578.108032,535.259338 575.352783,534.660034 
	C573.593018,534.277344 571.766479,534.050354 569.966614,534.028809 
	C559.109436,533.898865 559.017517,534.018311 558.995972,545.568115 
	C553.772949,535.923096 552.761169,531.480286 563.539673,526.308105 
	C571.620850,522.430298 580.591370,522.580566 589.048645,520.600342 
	C594.889038,519.232910 600.831787,516.963013 606.762512,514.255005 
	C607.201355,513.864136 607.390442,513.730713 607.917969,513.683960 
	C608.852356,514.172180 609.288025,514.721436 609.860962,515.618164 
	C610.460938,516.785583 610.835632,517.664185 611.142090,518.954407 
	C611.284424,520.056091 611.188354,520.776489 610.716797,521.781372 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M707.865967,535.736450 
	C711.003906,542.008057 713.948975,548.054871 717.029236,554.032043 
	C722.951477,565.523865 726.365479,577.903870 729.994995,590.606506 
	C729.365906,591.551941 728.643799,591.812683 727.434692,591.597656 
	C726.405090,590.999023 726.033997,590.331604 725.670837,589.236023 
	C725.590698,587.414429 724.957275,586.146606 724.225098,584.548340 
	C723.943665,583.792480 723.816284,583.350891 723.655396,582.565979 
	C723.566650,581.017090 722.890320,580.107239 721.970703,578.915649 
	C717.677368,572.082336 716.272095,564.482910 713.364136,557.480835 
	C706.354858,540.603455 694.031860,528.842468 677.905029,520.766663 
	C669.870544,516.743286 661.477478,513.665100 652.768860,511.482422 
	C644.005310,509.285950 635.330994,510.667542 626.731079,512.535156 
	C623.433655,513.251221 620.261475,514.319031 617.161865,511.326843 
	C617.184387,509.897522 617.761658,509.180542 618.924133,509.086945 
	C624.670349,508.624084 630.226807,506.628448 636.516602,506.956787 
	C645.811096,505.404175 654.824158,504.635162 663.358704,507.618805 
	C675.352478,511.811798 687.104553,516.751282 696.930725,525.238525 
	C699.920044,527.820496 703.033752,530.252380 705.744019,533.698120 
	C706.589172,534.488770 707.161621,534.984497 707.865967,535.736450 
z"/>
<path fill="#31BCC9" opacity="1.000000" stroke="none" 
	d="
M506.277466,586.104858 
	C506.093536,585.825378 505.870605,585.575317 505.710663,585.525513 
	C504.321442,585.493286 503.280762,584.822266 501.854858,584.430054 
	C501.396118,584.235291 501.216187,584.150330 500.772064,583.928955 
	C500.333801,583.698608 500.159760,583.604797 499.728027,583.365234 
	C499.301697,583.116882 499.133087,583.014465 498.718262,582.748413 
	C498.312622,582.468506 498.153198,582.352173 497.531097,581.864380 
	C496.470001,580.965027 495.853882,580.461731 494.856873,579.949097 
	C494.395233,579.682007 494.211334,579.567505 493.766541,579.250916 
	C486.094818,573.631714 477.604675,569.664734 471.183624,562.348633 
	C470.953125,562.089355 470.983032,562.017395 470.947052,562.032349 
	C468.507416,560.409851 465.623962,559.549561 463.264954,557.220093 
	C463.035797,556.971558 463.005737,556.997559 463.014038,556.979858 
	C462.234650,556.327637 461.193970,556.114929 460.243591,555.191345 
	C460.042633,554.966187 460.008636,554.995850 460.017334,554.975342 
	C459.231171,554.331726 458.193817,554.113586 457.251526,553.180298 
	C457.055939,552.950439 457.010284,553.001099 457.027832,552.973206 
	C456.872101,552.802673 456.698822,552.659973 456.248535,552.326050 
	C455.806732,551.812683 455.641968,551.490601 455.478821,550.896667 
	C455.708832,548.615723 457.556000,548.764160 458.359955,549.273254 
	C463.884827,552.771851 470.844025,554.213379 474.780212,560.676270 
	C475.831665,561.904907 476.659668,562.804810 477.764618,563.844666 
	C478.423737,564.479492 479.022461,564.487183 479.770477,564.833496 
	C485.413330,568.668335 490.835724,572.324585 496.603790,576.039551 
	C502.845673,576.630005 506.637390,581.255066 511.887634,583.776978 
	C513.783081,585.045105 515.551514,585.846313 517.557678,586.877197 
	C518.532959,587.510498 519.084167,588.149475 519.946533,588.905457 
	C520.340088,589.242493 520.494995,589.380798 520.876526,589.732849 
	C521.512085,590.446838 522.074646,590.736633 522.912964,591.206238 
	C533.504578,596.803833 543.329590,602.979065 552.478027,610.207764 
	C558.891418,615.275391 566.681335,618.049805 573.664734,623.208252 
	C575.307251,619.030762 570.241760,615.876282 572.854980,611.955200 
	C575.241028,611.633423 575.193909,613.553894 576.317505,614.814392 
	C579.702942,620.786072 581.186768,627.183167 582.610779,634.168823 
	C567.721252,624.787231 552.831726,615.405701 537.620972,605.916870 
	C536.239258,605.532104 535.265442,605.085815 534.282837,604.061584 
	C533.364624,603.184021 532.606934,602.588623 531.566895,601.919189 
	C530.937988,601.765442 530.635986,601.603027 530.198547,601.167236 
	C529.008728,600.293823 527.998779,599.611267 526.701355,598.818115 
	C525.766113,598.695435 525.143555,598.481262 524.181335,598.386902 
	C522.916504,597.768188 521.866577,597.417419 520.723267,596.545898 
	C518.369385,592.873962 513.930664,592.464111 511.293427,589.173401 
	C511.083984,588.908997 511.016632,589.000000 511.046387,588.954346 
	C509.597168,587.958862 507.557861,588.033386 506.277466,586.104858 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M724.766724,581.812744 
	C724.975586,582.472168 724.979004,582.908020 724.987305,583.670654 
	C724.723511,584.816772 724.148682,585.310669 722.945190,585.562378 
	C717.679443,581.928467 716.741577,576.060608 714.779236,570.956055 
	C709.732422,557.828491 704.255981,545.094788 693.562378,535.364441 
	C683.891846,526.565002 672.510681,520.904236 660.097412,517.303528 
	C654.052063,515.549988 648.015015,513.622070 641.610596,513.225159 
	C637.551880,512.973572 633.403992,512.969971 629.785278,514.404053 
	C623.684204,516.821777 617.333069,516.095520 611.140076,517.042969 
	C610.670349,517.114807 610.192444,516.772644 609.473022,516.261841 
	C608.828979,515.545837 608.474060,515.081238 608.080811,514.314087 
	C609.170898,512.507324 611.527954,513.210632 612.801270,511.294312 
	C614.104309,511.054962 615.150452,511.026794 616.588440,510.991699 
	C622.865967,513.940125 628.526062,510.577209 633.970581,509.624512 
	C644.935120,507.705902 655.164185,509.668152 665.026306,513.397705 
	C673.613342,516.645081 682.249756,520.196777 689.737122,525.841003 
	C702.926880,535.783997 713.242798,547.651184 717.201904,564.296936 
	C718.351685,569.131165 721.027100,573.602661 723.023926,578.616821 
	C723.876892,579.667725 723.956238,580.782043 724.766724,581.812744 
z"/>
<path fill="#46CAD9" opacity="1.000000" stroke="none" 
	d="
M576.614258,614.513550 
	C575.093445,614.750061 574.761047,613.121887 573.410522,612.166992 
	C570.001038,611.157898 569.450012,608.386658 568.745605,605.892639 
	C565.860413,595.677246 561.674438,585.914978 558.356079,575.848267 
	C556.863525,571.320374 553.376343,568.976013 548.618652,566.972656 
	C539.393860,563.088318 530.496521,558.062744 523.881836,549.521851 
	C521.734192,547.127319 519.304749,545.437561 518.108643,542.343872 
	C517.306396,538.949768 512.854004,538.970642 512.879639,535.267395 
	C514.798828,535.591309 516.489441,536.101624 518.525513,536.775696 
	C521.640808,537.110046 523.347351,539.002197 525.335022,541.054443 
	C525.969116,541.845093 526.305237,542.432068 526.950134,543.211548 
	C527.326416,543.633789 527.475830,543.805420 528.054932,544.519653 
	C534.178589,554.091003 543.461914,557.802856 552.782776,561.451782 
	C555.087708,562.354126 557.628723,562.889709 559.029724,565.705627 
	C563.196777,572.173950 564.576660,579.431274 567.067322,586.256104 
	C570.428711,595.467041 573.406738,604.817749 576.614258,614.513550 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M525.036621,542.152832 
	C522.810913,540.398254 520.937988,538.928101 518.968018,537.198730 
	C524.135925,534.110718 530.148010,534.945190 535.702087,533.523010 
	C536.493958,533.320190 537.361023,533.432922 538.180481,533.312439 
	C547.758606,531.904114 547.757996,531.899780 551.139282,541.549561 
	C548.797424,545.228210 545.122192,545.536255 541.751648,545.502258 
	C536.178040,545.446167 530.542175,545.046448 525.036621,542.152832 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M559.267578,565.495056 
	C552.973877,563.087646 546.591309,560.336853 540.234741,557.527222 
	C534.580811,555.028076 530.446533,550.841736 527.179321,545.300537 
	C527.562622,544.486328 528.128418,544.062744 528.818726,544.137451 
	C537.479187,545.074097 545.585876,547.892639 553.784424,551.715332 
	C555.794678,556.363098 557.492371,560.744629 559.267578,565.495056 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M706.005005,533.616089 
	C703.918274,533.945679 702.870422,532.290833 701.562866,531.054871 
	C694.085571,523.987000 685.748291,518.366089 676.033020,514.688293 
	C672.556641,513.372314 669.203674,511.535431 665.814941,509.940735 
	C661.587769,507.951385 657.501953,506.845428 652.903137,506.967316 
	C647.907654,507.099792 642.905762,506.987396 637.434082,506.964783 
	C637.389038,504.969299 638.954956,505.173279 640.456726,504.980316 
	C654.399536,503.188904 667.565735,506.766357 680.010010,512.141235 
	C689.371643,516.184631 697.270691,523.082397 704.819397,530.052917 
	C705.559937,530.736755 705.618225,532.159424 706.005005,533.616089 
z"/>
<path fill="#CDCCCD" opacity="1.000000" stroke="none" 
	d="
M554.165466,551.591980 
	C545.036560,550.856018 536.843628,546.460144 527.475464,545.000244 
	C527.085449,544.976196 527.002197,544.994446 526.991089,544.953247 
	C526.821716,544.775146 526.663513,544.638245 526.313660,544.252808 
	C525.708313,543.509277 525.294617,543.014465 524.782349,542.193970 
	C528.247620,542.268616 531.938171,542.217468 535.345886,543.173950 
	C540.583862,544.644226 545.372131,543.137512 550.694092,541.980103 
	C552.142761,545.020813 553.188416,548.111572 554.165466,551.591980 
z"/>
<path fill="#1A98B0" opacity="1.000000" stroke="none" 
	d="
M553.655640,519.049316 
	C557.133362,518.464539 560.232544,517.918213 563.692871,517.220459 
	C565.220886,515.902222 566.647339,515.964111 568.498291,516.414307 
	C569.040222,516.861145 569.196960,517.105408 569.183594,517.750366 
	C554.635864,521.835876 540.258240,525.520569 525.471069,529.182007 
	C524.866516,528.957581 524.671448,528.756470 524.754150,528.240051 
	C526.217957,525.019226 529.177002,526.033875 531.638916,525.075928 
	C533.362854,524.052673 535.285706,524.776428 536.819092,523.307495 
	C537.834106,523.086914 538.568176,523.075317 539.668457,523.036743 
	C540.408630,522.654114 540.852234,522.503906 541.665222,522.775879 
	C546.068298,522.654663 549.368530,519.990234 553.655640,519.049316 
z"/>
<path fill="#31BCC9" opacity="1.000000" stroke="none" 
	d="
M569.360291,517.179626 
	C567.624573,517.090820 566.250000,517.076294 564.464722,517.065430 
	C564.281982,515.354187 565.486938,515.120422 566.927429,515.049377 
	C573.554077,514.722534 579.713867,512.056885 586.621216,511.077209 
	C587.530151,510.448578 588.085876,509.929230 588.854370,509.220398 
	C591.116821,509.041443 593.162720,509.048157 595.651611,509.091187 
	C595.996704,510.824646 594.319824,510.834717 593.232971,511.938263 
	C592.261841,512.458435 591.455627,512.691711 590.327271,512.917175 
	C588.128174,513.216248 586.258057,513.552795 584.159546,514.301392 
	C583.451416,514.549622 583.043701,514.695068 582.310303,514.858521 
	C577.851196,515.433472 573.850525,516.674805 569.360291,517.179626 
z"/>
<path fill="#1E293E" opacity="1.000000" stroke="none" 
	d="
M628.161926,675.354675 
	C624.870300,678.894958 620.371887,680.022888 615.192627,679.734070 
	C618.674805,676.507690 623.469238,676.655396 628.161926,675.354675 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M716.127197,544.699158 
	C714.419067,542.871033 712.845154,540.737488 711.127441,538.300781 
	C711.164185,537.450195 711.344666,536.902771 711.752808,536.170349 
	C712.432495,535.991638 712.884521,535.995056 713.675537,535.992920 
	C714.326843,536.454041 714.639038,536.917542 714.984863,537.667542 
	C714.161560,540.346008 717.682556,541.892883 716.127197,544.699158 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M568.999512,517.366760 
	C573.012085,515.569519 577.238831,515.040588 581.719727,514.668945 
	C578.108643,516.620911 573.894104,517.119751 569.422913,518.165649 
	C569.008972,517.976868 569.004456,517.802551 568.999512,517.366760 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M612.682434,511.099304 
	C612.712097,513.632446 610.916321,514.059204 608.415405,514.005493 
	C607.870789,514.008179 607.699036,514.005005 607.269775,513.999634 
	C607.653198,510.972290 610.632812,512.243164 612.682434,511.099304 
z"/>
<path fill="#1E293E" opacity="1.000000" stroke="none" 
	d="
M613.152344,679.317139 
	C612.233215,681.966614 610.081726,682.561218 607.176819,681.747559 
	C608.279297,679.284851 610.717468,679.780457 613.152344,679.317139 
z"/>
<path fill="#31BCC9" opacity="1.000000" stroke="none" 
	d="
M524.652283,527.940491 
	C525.037292,528.130310 525.042542,528.335876 525.054688,528.850098 
	C523.281921,529.808716 521.502258,530.458740 519.364746,531.122253 
	C518.811279,530.953674 518.615601,530.771484 518.690308,530.293030 
	C520.374634,528.388245 522.241821,527.959351 524.652283,527.940491 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M584.211914,514.507935 
	C585.307983,512.444824 587.509155,512.714905 589.773560,512.697754 
	C588.446716,514.291992 586.413269,514.156311 584.211914,514.507935 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M534.155273,604.150757 
	C535.074463,604.265015 536.026672,604.750671 537.139282,605.522949 
	C536.040283,605.917725 534.733459,606.140381 534.155273,604.150757 
z"/>
<path fill="#1E293E" opacity="1.000000" stroke="none" 
	d="
M636.194397,673.380310 
	C636.202576,674.514709 635.590271,675.190247 634.144287,674.724121 
	C634.268860,673.704712 634.869751,673.245911 636.194397,673.380310 
z"/>
<path fill="#060D0E" opacity="1.000000" stroke="none" 
	d="
M713.855103,535.784790 
	C713.562683,536.627014 713.035522,536.949036 712.140259,536.193237 
	C712.435608,535.357666 712.964600,535.041870 713.855103,535.784790 
z"/>
<path fill="#31BCC9" opacity="1.000000" stroke="none" 
	d="
M515.358154,532.526794 
	C515.405090,532.040344 515.842590,531.551941 516.618774,531.003052 
	C517.272522,530.840332 517.587463,530.738159 518.242676,530.997925 
	C517.638123,531.748291 516.693420,532.136597 515.358154,532.526794 
z"/>
<path fill="#46CAD9" opacity="1.000000" stroke="none" 
	d="
M518.719482,531.361938 
	C518.315308,531.277710 518.047852,531.195435 517.368958,531.027832 
	C517.433289,530.645142 517.908997,530.347778 518.672668,530.023560 
	C518.965454,530.186646 518.970337,530.376526 518.991089,530.851135 
	C519.006897,531.135803 518.856201,531.363953 518.719482,531.361938 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M524.070312,597.791870 
	C524.949829,597.728455 525.503052,597.967712 526.235107,598.457214 
	C525.900269,598.779236 525.386658,598.851013 524.485474,598.925415 
	C524.097961,598.928162 524.069275,598.942871 524.066406,598.934021 
	C523.957092,598.648254 523.850586,598.371399 524.070312,597.791870 
z"/>
<path fill="#31BCC9" opacity="1.000000" stroke="none" 
	d="
M513.573425,533.395142 
	C513.505859,533.112061 513.771667,532.734985 514.402466,532.317444 
	C514.480591,532.618408 514.193665,532.959778 513.573425,533.395142 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M530.151672,601.217957 
	C530.348206,601.108582 530.677734,601.240845 531.145874,601.609131 
	C530.951172,601.716614 530.617920,601.588074 530.151672,601.217957 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M763.429810,470.012817 
	C763.653015,468.391418 764.202332,466.704132 764.652100,464.614563 
	C764.552612,464.212250 764.548462,464.042816 764.604370,463.980042 
	C765.186157,463.947998 765.712036,463.978729 766.631714,464.028198 
	C766.895386,464.897034 766.765137,465.747131 766.792480,466.795197 
	C766.947632,467.424835 766.945312,467.856506 766.930786,468.610382 
	C765.864380,469.270691 764.810120,469.608795 763.429810,470.012817 
z"/>
<path fill="#1A2537" opacity="1.000000" stroke="none" 
	d="
M723.218323,437.742676 
	C722.350037,437.148834 722.406799,436.601959 723.108154,436.328613 
	C723.427124,436.204285 724.135681,436.378937 724.277283,436.634277 
	C724.668640,437.340485 724.165894,437.682495 723.218323,437.742676 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M839.951538,450.639618 
	C838.932312,450.997833 837.860413,451.002960 836.386108,451.015411 
	C835.983704,451.022736 835.991638,451.006744 835.985107,451.011505 
	C834.492798,449.802887 832.655579,449.038696 831.207397,447.255005 
	C829.811401,446.858337 828.654663,446.678101 827.249756,446.248291 
	C826.460205,445.475769 825.935791,444.935303 825.175171,444.233032 
	C824.230347,444.027832 823.496277,444.010529 822.395996,443.978729 
	C822.380005,442.100494 820.816650,441.093109 820.048279,439.362061 
	C826.020325,440.270935 830.371460,444.779938 835.782349,447.262726 
	C837.300354,447.959290 839.476501,448.006531 839.951538,450.639618 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M774.681030,450.152466 
	C775.099243,452.868073 773.351318,454.845093 771.327454,456.916748 
	C770.985352,454.181366 772.174316,451.988831 774.681030,450.152466 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M831.024170,447.385620 
	C833.142639,447.341949 834.729675,448.515533 835.925171,450.682343 
	C833.548340,451.111389 831.868774,450.086487 831.024170,447.385620 
z"/>
<path fill="#B8B6BC" opacity="1.000000" stroke="none" 
	d="
M767.834106,461.206146 
	C769.171936,462.150360 769.262756,463.154846 767.354370,463.963074 
	C767.219788,463.153625 767.392883,462.284149 767.834106,461.206146 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M825.028015,444.353577 
	C825.877197,444.093964 826.630859,444.375183 826.949341,445.683533 
	C826.115479,445.909698 825.362305,445.630524 825.028015,444.353577 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M852.052246,463.316498 
	C852.885437,463.104156 853.641113,463.388428 853.952881,464.680847 
	C853.119202,464.895874 852.360168,464.615631 852.052246,463.316498 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M710.011963,409.386292 
	C712.533447,408.913849 712.273376,405.742615 714.652222,405.058594 
	C715.835693,409.837402 710.093323,411.834869 710.070862,416.681519 
	C709.251343,417.612640 709.062927,418.441589 709.012329,419.669434 
	C707.820740,420.956451 707.109863,422.240753 706.257568,423.810760 
	C706.054199,424.052582 706.010071,424.513000 705.979126,424.742188 
	C703.695984,427.048828 703.532410,430.346283 701.321289,432.843201 
	C701.104187,433.543579 701.080750,433.961609 701.032288,434.692688 
	C700.490234,435.536041 699.867188,435.786896 698.550903,435.348816 
	C699.640686,427.117737 703.247864,420.160553 707.938110,413.339233 
	C710.012878,412.745453 709.994507,411.241455 710.011963,409.386292 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M693.136841,447.691895 
	C690.434204,448.257263 688.444092,450.447968 685.323120,449.934448 
	C685.140320,447.621307 687.885498,448.215759 688.841125,446.302612 
	C690.236023,445.053375 689.851196,443.714386 689.904785,442.140076 
	C690.045593,441.608582 690.124634,441.401489 690.375732,440.908081 
	C691.850037,439.423431 693.108826,438.189728 694.052795,436.260010 
	C694.540344,435.617401 694.900024,435.335266 695.650879,435.052948 
	C696.434204,434.991974 696.875671,434.992676 697.647583,434.985596 
	C697.978149,434.977783 698.023438,435.022980 698.046082,435.045593 
	C698.929321,436.053040 699.096802,437.139496 698.310303,438.701874 
	C698.073486,439.072632 698.039795,439.536316 698.014771,439.767456 
	C697.118225,440.649323 697.046448,441.774597 696.257446,442.824585 
	C696.337158,445.257233 694.500610,446.126160 693.136841,447.691895 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M701.455322,433.031433 
	C702.048157,430.212921 702.140259,426.875214 705.600098,425.029114 
	C705.412231,428.072449 704.454346,430.952911 701.455322,433.031433 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M698.418701,439.005371 
	C698.061157,438.002594 698.048889,436.932556 698.052612,435.465332 
	C698.802551,435.044037 699.536377,435.019928 700.638794,435.000732 
	C701.081116,436.785248 700.772461,438.346619 698.418701,439.005371 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M706.381470,423.978424 
	C705.916931,422.236908 706.192017,420.653595 708.638184,420.046326 
	C708.802856,421.634766 708.355286,423.119965 706.381470,423.978424 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M696.394104,442.979492 
	C696.025269,441.890137 695.886597,440.677765 697.651123,440.066162 
	C697.988464,441.148682 698.090759,442.343964 696.394104,442.979492 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M858.835693,458.701721 
	C853.844910,458.664886 850.243103,455.168518 846.065125,452.358032 
	C851.017273,452.562408 854.879700,455.416931 858.835693,458.701721 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M877.952026,473.631836 
	C876.257324,473.490814 874.249390,473.527191 873.067139,471.341248 
	C874.953796,471.058380 877.125916,470.647705 877.952026,473.631836 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M871.967896,470.648071 
	C870.591492,471.034790 869.223450,470.930908 868.163208,469.278992 
	C870.056213,467.215363 871.128540,468.347992 871.967896,470.648071 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M918.372742,613.935791 
	C917.200256,609.568970 919.284363,605.462830 919.573059,601.112061 
	C919.972412,595.093567 920.068665,589.113953 919.490723,583.127930 
	C919.385376,582.036987 918.938293,580.487915 918.145813,580.039429 
	C914.729553,578.105957 914.853394,575.031738 915.001465,571.426147 
	C916.533447,571.014893 918.072754,571.107910 918.702271,572.837769 
	C922.260681,582.614746 924.672974,592.457642 922.320740,603.036865 
	C921.478394,606.825073 921.963318,610.907349 918.372742,613.935791 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M906.996277,554.603821 
	C903.609741,554.264343 902.692322,552.619202 902.715393,548.806030 
	C902.747559,543.481140 901.007080,538.145508 900.012024,532.409058 
	C904.219421,536.653137 906.689758,542.128906 906.568909,548.386169 
	C906.530090,550.395447 907.308655,552.248169 906.996277,554.603821 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M910.218445,561.214966 
	C909.991760,559.269775 907.792664,558.552124 908.008057,556.359863 
	C912.969788,557.195129 913.689026,561.416016 914.585876,565.420471 
	C914.938599,566.995422 914.866760,568.665344 914.994141,570.645996 
	C911.632446,570.034668 913.587585,566.600037 912.051392,564.382324 
	C911.124451,563.359985 911.053650,562.228455 910.218445,561.214966 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M875.832642,488.692017 
	C869.680603,489.772400 866.624573,484.368713 862.064941,481.341125 
	C864.257202,480.769562 866.425415,481.196045 867.631897,483.210938 
	C869.500610,486.331909 873.885620,485.295135 875.832642,488.692017 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M886.984497,497.643982 
	C884.370422,497.463043 882.059509,496.493713 881.049316,493.341919 
	C883.405701,493.852600 886.384583,493.903168 886.984497,497.643982 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M878.933350,490.661133 
	C877.864807,490.949036 876.639709,491.091248 876.066589,489.336731 
	C877.133301,489.052826 878.359619,488.908447 878.933350,490.661133 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M858.947144,480.682373 
	C858.113220,480.895782 857.355652,480.613831 857.048035,479.316620 
	C857.880066,479.105835 858.639465,479.385529 858.947144,480.682373 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M460.295654,842.627197 
	C458.207947,842.941650 456.686279,843.351135 456.022888,845.273254 
	C455.661285,846.320923 454.943115,846.931885 453.811035,846.945923 
	C445.254913,847.051697 442.503998,852.300049 441.635345,859.885498 
	C441.404205,861.904053 442.482513,865.736816 438.208527,865.985168 
	C433.862152,866.237610 431.552094,863.289429 429.531891,860.248047 
	C428.383087,858.518494 430.354187,858.153015 431.528748,857.684814 
	C435.149445,856.241394 438.245697,854.740723 436.261047,849.701721 
	C435.392090,847.495361 437.126007,846.837769 439.210144,847.565918 
	C440.875916,848.147949 442.373260,847.798096 442.690216,845.937256 
	C443.069031,843.713196 441.343079,842.772461 439.594971,843.101074 
	C435.080017,843.949707 431.411102,840.570862 427.031464,840.915039 
	C425.603912,841.027283 424.215210,840.261658 424.298187,838.656799 
	C424.399048,836.706238 426.065948,836.833801 427.313843,837.307800 
	C430.052917,838.348206 431.670258,836.425415 433.590149,835.234558 
	C436.076080,833.692566 438.429626,831.242493 440.909363,835.534119 
	C441.606201,836.740173 443.778351,837.039062 445.171997,837.915833 
	C446.145355,838.528259 446.771271,840.587646 448.147064,838.475403 
	C449.203522,836.853516 449.205048,835.280823 447.219971,834.323120 
	C445.970490,833.720276 443.929993,834.010376 444.205902,831.782288 
	C444.495422,829.444031 446.758911,830.047546 448.169495,829.517700 
	C449.370544,829.066467 450.770477,829.064087 452.087830,829.017944 
	C456.084991,828.877930 460.452881,828.394775 458.135101,834.873169 
	C457.842651,835.690613 458.431946,836.457336 459.649048,836.915039 
	C461.426636,838.613098 461.703918,840.355469 460.295654,842.627197 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M429.834167,955.727905 
	C429.729126,956.507263 429.444885,956.979919 429.139343,957.729614 
	C428.861847,958.222534 428.605682,958.438416 428.157318,958.816650 
	C423.265900,963.301819 418.836151,967.932861 413.174866,971.584106 
	C411.363586,971.193542 410.805725,970.125000 410.733948,968.394287 
	C410.939911,967.558533 411.038208,967.093262 411.242371,966.286743 
	C413.606964,965.009460 414.802185,963.147278 415.904205,961.070679 
	C418.646149,955.903809 425.103577,953.319336 429.834167,955.727905 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M470.693909,924.055054 
	C470.928711,925.922791 469.664886,927.127136 468.361511,928.095642 
	C461.140320,933.461243 462.046295,939.732117 464.975403,946.587219 
	C458.879333,946.554138 458.680176,945.292786 458.292145,938.963928 
	C457.729340,929.784485 465.422516,928.254944 470.693909,924.055054 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M459.863464,788.736816 
	C460.155823,790.249207 459.880707,792.169312 461.910858,791.593506 
	C463.540497,791.131348 465.105103,789.643005 464.951813,792.811890 
	C464.824768,795.438782 464.810242,798.458496 461.713654,799.428711 
	C459.213806,800.211914 458.742493,797.723145 458.293915,796.113159 
	C457.634094,793.745361 457.426605,791.251404 457.013214,788.405640 
	C458.176727,786.616089 458.956635,787.517273 459.863464,788.736816 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M460.375000,843.000000 
	C460.000000,841.285645 460.000000,839.571289 460.000000,837.428467 
	C461.394501,836.863342 461.985382,836.035339 461.991028,834.348022 
	C467.001770,834.750183 467.460266,837.183594 463.313782,842.819275 
	C462.250000,843.000000 461.500000,843.000000 460.375000,843.000000 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M455.702148,808.168213 
	C457.373993,808.218994 458.909973,808.577393 458.484772,810.341553 
	C458.040558,812.184509 456.227112,811.936584 454.375488,811.998779 
	C453.974243,810.589539 454.068024,809.224915 455.702148,808.168213 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M417.969116,930.707397 
	C417.129944,930.843445 415.974457,931.318604 415.613922,930.061340 
	C415.550079,929.838745 416.125641,929.432800 416.702942,929.055786 
	C417.313141,929.471619 417.626312,929.943237 417.969116,930.707397 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M401.809540,875.950745 
	C403.017853,875.719910 403.809692,875.472290 404.616699,875.292297 
	C405.575989,875.078125 406.720612,874.722290 407.311707,875.754761 
	C407.942169,876.856018 406.909332,877.607727 406.205170,878.232361 
	C405.219757,879.106567 404.044556,879.764526 403.040375,880.620300 
	C400.205688,883.036377 398.309052,885.250549 402.516846,888.501770 
	C405.550659,890.845947 404.255219,894.550354 399.763611,896.379517 
	C397.414276,897.336304 395.167023,897.887390 394.081177,900.563660 
	C393.380219,902.291260 391.684113,902.217346 390.226288,901.519836 
	C388.501617,900.694580 386.230774,900.331299 386.057098,897.785400 
	C385.854858,894.820923 385.979614,891.873291 385.324860,888.893127 
	C384.766907,886.353699 387.285919,885.674500 389.168610,885.188049 
	C392.318542,884.374084 394.670105,883.660950 391.031464,880.231384 
	C389.152283,878.460205 390.373230,877.014160 392.207520,875.917236 
	C395.300476,874.067505 398.339447,875.532288 401.809540,875.950745 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M417.120300,905.222534 
	C418.387665,904.387329 419.277527,903.603943 420.315186,903.219055 
	C421.257263,902.869629 422.333038,903.117737 422.704071,904.298828 
	C423.119354,905.620850 422.605133,906.473022 421.255035,906.926270 
	C417.914856,908.047729 414.489471,908.189331 411.024750,907.921448 
	C410.164093,907.854980 409.327728,907.482849 409.378723,906.482910 
	C409.445709,905.170227 410.556000,905.147217 411.548248,905.034729 
	C413.331207,904.832458 414.960754,906.521912 417.120300,905.222534 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M399.284607,908.440979 
	C399.718109,910.203735 398.581787,910.646118 397.577881,911.181885 
	C395.669128,912.200562 393.862000,912.325439 392.205200,910.639526 
	C391.071594,909.486206 391.150879,908.299683 392.076660,907.138245 
	C393.600098,905.227112 395.294098,906.235596 396.925079,906.981323 
	C397.665680,907.319824 398.355286,907.769775 399.284607,908.440979 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M415.468231,878.997803 
	C414.354187,880.180969 413.289398,880.195984 412.449524,879.124451 
	C412.025116,878.583008 412.213074,877.742432 412.848999,877.448181 
	C414.113678,876.863037 415.021118,877.416626 415.468231,878.997803 
z"/>
<path fill="#87898A" opacity="1.000000" stroke="none" 
	d="
M430.212616,988.746460 
	C423.777863,991.861694 418.701355,999.413574 419.832153,1004.521118 
	C418.170044,1005.989929 416.898010,1007.761902 414.341614,1007.976074 
	C411.336273,1004.404419 412.235504,1000.674744 413.891571,997.157776 
	C414.733948,995.368774 415.589996,993.575745 416.377289,991.758606 
	C417.937286,988.158020 416.720581,986.485596 412.805206,987.061279 
	C409.676361,987.521301 406.806702,989.024170 403.706970,989.673523 
	C402.866333,989.849670 402.061554,989.865967 400.969910,989.260498 
	C400.654694,989.068909 400.266693,989.155457 400.068970,989.175598 
	C399.540100,988.704895 399.395142,988.164246 399.577728,987.169373 
	C406.119019,982.496704 412.728790,979.175781 420.804840,979.121155 
	C426.154938,979.084961 431.736420,979.348267 436.779358,976.627991 
	C437.794434,976.080444 439.051117,976.140686 439.987854,977.572021 
	C436.665802,981.364441 432.630157,984.092346 430.212616,988.746460 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M411.273315,1008.479980 
	C407.339630,1010.075928 405.316437,1008.489197 404.340179,1005.073792 
	C403.268433,1001.324097 402.214050,997.569580 401.216614,993.433472 
	C402.500092,989.722290 405.355743,988.925781 408.408539,988.518372 
	C410.608063,988.224792 412.928009,988.205261 414.355774,990.183777 
	C415.904572,992.329895 415.426331,994.649353 413.860077,996.660889 
	C412.397522,998.539368 412.509735,1000.682007 412.484406,1002.851501 
	C412.463318,1004.654663 412.831268,1006.546631 411.273315,1008.479980 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M411.283997,1008.867432 
	C411.368408,1003.818726 409.882202,998.654785 413.590240,994.011963 
	C415.100403,992.121094 413.776550,989.957092 410.654572,989.969727 
	C407.390472,989.982910 404.383728,990.478088 401.615417,992.953369 
	C401.092163,991.991638 400.903168,990.933472 400.684448,989.472107 
	C403.945282,987.969116 407.273193,986.967651 410.514191,985.737305 
	C412.984772,984.799500 415.339661,984.641907 417.698578,985.886353 
	C420.059296,987.131653 420.778687,988.980652 419.049377,991.149780 
	C415.307129,995.843628 413.312469,1001.027466 413.989685,1007.536621 
	C413.197540,1008.295471 412.400726,1008.609863 411.283997,1008.867432 
z"/>
<path fill="#0C161B" opacity="1.000000" stroke="none" 
	d="
M400.118774,986.639526 
	C399.987793,987.488586 399.960022,987.976257 399.901733,988.829834 
	C398.384125,988.290222 395.656403,981.733582 396.070831,979.931580 
	C396.704620,977.175354 398.987671,977.192871 401.133301,976.993408 
	C403.763580,976.748901 405.752747,974.657959 408.514404,974.569580 
	C410.068390,974.519836 410.460510,973.230957 410.090668,971.619507 
	C409.841431,970.533508 408.400543,969.212402 410.541779,968.093506 
	C411.353577,968.949524 411.865570,969.875244 412.531860,971.081665 
	C415.582703,974.285278 414.424988,976.687683 411.546783,978.589050 
	C409.485504,979.950806 407.076874,980.725159 404.744873,981.524902 
	C402.333099,982.351990 400.837860,983.844421 400.118774,986.639526 
z"/>
<path fill="#656768" opacity="1.000000" stroke="none" 
	d="
M424.868591,1014.098389 
	C425.000275,1014.119263 425.124298,1014.493835 425.072388,1015.013428 
	C424.884644,1014.923035 424.872833,1014.687561 424.868591,1014.098389 
z"/>
<path fill="#C6C5C6" opacity="1.000000" stroke="none" 
	d="
M412.518036,942.991394 
	C411.750916,943.961975 410.994568,943.970642 410.435577,943.190796 
	C410.037720,942.635559 410.124451,941.830994 410.795044,941.569397 
	C411.717651,941.209290 412.250000,941.876526 412.518036,942.991394 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M366.849854,968.630981 
	C363.707428,966.666931 364.592743,964.041382 366.660187,961.182251 
	C366.970764,963.392883 366.955505,965.789856 366.849854,968.630981 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M408.836121,1030.810059 
	C403.987885,1036.219604 398.057373,1034.696411 392.184967,1033.174683 
	C384.862854,1031.277222 381.982941,1025.782715 381.023285,1018.890930 
	C380.836823,1017.551697 380.821686,1016.241333 379.263733,1015.173950 
	C378.907410,1014.268127 378.886292,1013.551819 378.848755,1012.477234 
	C378.335449,1011.360229 377.741516,1010.641541 377.846100,1009.307861 
	C377.870056,1008.260803 377.862244,1007.554077 377.833679,1006.494385 
	C374.020020,1000.567871 369.811615,995.311768 365.666412,990.001709 
	C364.288483,988.236572 363.163391,986.008789 364.683716,983.234985 
	C364.907166,981.875732 364.913513,980.827698 364.911804,979.386719 
	C365.010468,978.010864 365.265167,977.106750 366.537292,976.444580 
	C367.361664,976.299927 367.823822,976.386719 368.473816,976.890747 
	C368.761871,978.199158 368.835785,979.221130 368.905823,980.627869 
	C369.630463,985.423584 371.459137,989.662537 374.805389,992.272156 
	C381.590240,997.563416 383.068695,1005.146057 385.016327,1012.609314 
	C385.893982,1015.972656 387.022186,1019.255859 387.942993,1022.603516 
	C389.482422,1028.200195 397.561737,1032.078369 404.310211,1030.735718 
	C405.768433,1030.445557 407.114075,1029.680908 408.836121,1030.810059 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M364.564270,982.992188 
	C364.701416,986.241882 366.146790,989.301697 368.173950,991.381958 
	C372.246094,995.560608 375.159668,1000.362305 377.811340,1005.783875 
	C377.649719,1006.118591 377.413574,1006.157776 377.333710,1006.063965 
	C372.242981,1000.083496 366.320038,994.730774 362.948944,987.445679 
	C362.200043,985.827271 361.570496,983.864502 364.564270,982.992188 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M367.154877,976.700195 
	C366.396149,977.562988 365.897369,978.131958 365.151184,978.847351 
	C363.845947,976.776245 362.789459,974.558899 366.162109,972.924194 
	C366.684631,973.319458 366.870636,973.758118 367.122314,974.549927 
	C367.466675,975.373413 367.542358,975.874512 367.154877,976.700195 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M377.561646,1008.968872 
	C378.807159,1009.532043 378.989258,1010.419373 378.882812,1011.764282 
	C378.091797,1011.832153 377.351196,1011.545349 376.338318,1011.130737 
	C376.048889,1010.557617 376.031769,1010.112366 375.995575,1009.333557 
	C376.399506,1008.990051 376.822449,1008.980164 377.561646,1008.968872 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M409.295471,1030.932617 
	C403.684418,1031.543335 398.345123,1032.824219 393.002991,1030.221802 
	C389.475342,1028.503296 386.746552,1026.386353 386.362701,1022.392090 
	C385.676147,1015.247986 381.798401,1009.039124 380.673553,1002.004883 
	C380.333801,999.880310 378.673737,997.838074 376.717834,996.432373 
	C371.609406,992.761047 368.533447,987.973999 368.686279,981.264709 
	C370.731842,984.484070 372.860565,986.859619 377.437164,987.364746 
	C381.091492,987.768127 381.023163,992.349365 381.959656,995.387512 
	C384.483398,1003.574951 385.745117,1012.106201 388.803528,1020.158325 
	C391.568481,1027.437866 397.293304,1030.829590 404.960693,1029.528076 
	C406.416992,1029.280762 407.907440,1029.234863 409.821198,1029.189209 
	C410.012604,1029.774414 409.764954,1030.266602 409.295471,1030.932617 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M367.196899,977.010986 
	C366.934570,976.525757 366.974274,976.057556 367.100952,975.246094 
	C368.077606,975.099976 368.730896,975.526794 368.734375,976.858887 
	C368.291626,977.127441 367.895264,977.077698 367.196899,977.010986 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M186.621613,468.950073 
	C194.475281,474.096802 203.529510,475.544678 211.761017,479.728333 
	C210.973801,483.543060 208.236694,482.191132 206.416138,481.464630 
	C200.199142,478.983765 193.760147,477.623901 187.223404,476.353424 
	C183.733307,475.675079 182.716202,476.416718 182.693680,479.786194 
	C182.628769,489.489288 177.913147,498.476501 178.187012,508.254883 
	C178.205124,508.901611 177.672974,509.463654 176.869537,510.161469 
	C176.252548,510.370911 175.984116,510.379730 175.366241,510.188049 
	C175.005173,505.300781 174.993530,500.614044 174.975815,495.460938 
	C175.010025,493.881317 174.986618,492.766785 175.056427,491.320190 
	C175.101028,488.960358 175.124069,486.934052 175.193970,484.476990 
	C178.217300,480.903656 177.461243,476.477997 179.080841,472.824188 
	C180.585114,469.430542 182.104706,467.040070 186.621613,468.950073 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M222.056610,599.348083 
	C224.501114,599.123535 226.832779,599.565613 227.952423,602.640015 
	C225.789383,602.341553 223.729965,601.417786 222.056610,599.348083 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M249.018784,615.379150 
	C251.128510,615.337280 252.711533,616.518677 253.942352,618.651489 
	C251.548401,619.130737 249.880951,618.083374 249.018784,615.379150 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M217.059113,596.343201 
	C218.129044,596.037292 219.061493,596.456848 219.848053,597.719177 
	C218.857300,597.958984 217.634888,598.094116 217.059113,596.343201 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M171.533508,486.986084 
	C169.150314,498.072449 169.378357,509.409119 171.610077,520.399414 
	C173.595322,530.175903 177.530243,539.552612 180.528656,549.129517 
	C181.466949,552.126465 182.158340,555.200806 182.956055,558.636108 
	C181.125015,558.805420 179.408188,557.776306 179.353592,556.102356 
	C179.157730,550.097351 175.640106,545.140320 174.175827,539.510803 
	C172.471970,532.960144 169.173416,526.608398 168.878906,519.741150 
	C168.437729,509.453857 166.800537,499.105743 169.095001,488.817719 
	C169.353973,487.656433 170.065811,487.171509 171.533508,486.986084 
z"/>
<path fill="#06C4E6" opacity="1.000000" stroke="none" 
	d="
M290.230865,624.848877 
	C289.927216,625.062073 289.482269,625.096008 289.482269,625.096008 
	C289.482269,625.096008 289.103149,624.860657 289.008362,624.658691 
	C288.416809,623.522095 288.041748,622.482483 286.570129,622.065674 
	C285.594818,621.978577 284.948151,621.899475 284.021912,621.547852 
	C283.708923,621.447205 283.596741,621.427673 283.556763,621.387146 
	C283.486572,621.138916 283.456421,620.931274 283.248444,620.463623 
	C282.054901,618.393738 280.167023,617.904480 278.145996,617.069702 
	C277.208588,616.844788 276.661774,616.532166 275.894653,615.966309 
	C275.043396,615.238770 274.750214,614.384644 273.618652,613.995483 
	C273.144653,613.806091 272.961456,613.712708 272.534363,613.429688 
	C271.682709,612.272705 270.667542,612.013916 269.370270,611.583008 
	C264.464752,609.616150 261.853088,605.166748 257.384918,602.615967 
	C256.527344,602.129883 255.972336,601.786255 255.087769,601.374390 
	C254.577286,601.184204 254.374222,601.101074 253.871719,600.874084 
	C253.178741,600.512939 252.801788,600.269653 252.171478,599.789062 
	C251.575516,599.248962 251.281097,598.895630 250.842712,598.218018 
	C246.485794,592.390991 246.496017,592.399170 251.083908,586.664062 
	C253.271164,583.929871 255.565979,581.215515 258.349823,579.162415 
	C264.808594,574.399109 267.262695,568.127441 266.905609,560.277161 
	C266.763489,557.153198 267.338074,554.026428 271.647858,553.320068 
	C273.303711,553.048767 271.723297,550.229309 273.478882,548.908691 
	C274.156647,548.454102 274.601501,548.330811 275.415039,548.374268 
	C276.843719,548.802246 277.346893,550.017395 278.819519,550.373718 
	C280.589081,550.582520 282.016693,550.750061 283.510559,551.879578 
	C286.930847,555.429016 291.353394,556.608826 295.101410,558.910156 
	C297.213165,560.206787 299.536530,561.245178 299.624573,564.564392 
	C298.172302,567.079346 299.956055,567.741821 301.290619,568.677124 
	C304.103577,570.648682 304.509583,572.763367 301.542084,574.987793 
	C301.276855,575.186646 300.766571,575.420776 300.790436,575.588928 
	C301.706940,582.040833 293.534393,585.692810 295.604492,592.515930 
	C296.190674,594.448059 295.386353,596.238708 293.925934,597.436584 
	C290.120605,600.557983 290.345245,605.109802 289.652374,609.255310 
	C289.187073,612.039246 291.008423,614.229980 292.777039,616.289490 
	C294.825989,618.675415 296.893768,621.106506 296.350708,624.917236 
	C294.113342,626.682007 292.397339,624.964355 290.230865,624.848877 
z"/>
<path fill="#24CCDD" opacity="1.000000" stroke="none" 
	d="
M299.150574,565.328369 
	C296.998352,559.158997 290.652344,559.294067 286.673187,556.130493 
	C285.588440,555.268066 283.835876,555.274536 283.085052,553.380981 
	C283.229309,552.707581 283.529663,552.476379 284.190796,552.299683 
	C284.673920,552.285522 284.866608,552.304810 285.337219,552.381531 
	C286.001617,552.510559 286.382721,552.479797 287.077026,552.339966 
	C293.168823,553.549133 297.290833,557.994324 302.904999,560.321167 
	C311.513519,565.683228 319.621735,571.196167 328.747925,575.678223 
	C329.310608,575.866028 329.528442,575.962341 330.047394,576.250854 
	C335.838715,582.343872 343.014160,585.745483 350.198181,589.725708 
	C350.923279,590.036499 351.314514,590.254639 351.966644,590.676758 
	C352.603088,591.095764 352.961212,591.341187 353.593689,591.762939 
	C354.051025,592.060303 354.232178,592.184082 354.674408,592.511841 
	C355.782532,593.461548 356.460449,594.347351 357.234283,595.587891 
	C363.883331,605.142517 373.364075,609.995239 383.596130,613.779297 
	C387.980133,615.400574 391.165283,619.196655 396.015991,620.704956 
	C396.538513,620.925354 396.738342,621.031067 397.211853,621.331665 
	C398.562592,623.033020 400.048950,623.744934 402.206116,623.862915 
	C405.378174,624.898193 407.754364,626.785706 410.787567,628.158691 
	C411.650879,628.818604 412.051849,629.406250 412.458374,630.409668 
	C412.577240,630.986816 412.589752,631.222473 412.519226,631.799072 
	C412.004059,632.993225 411.273346,633.517639 410.159058,634.075073 
	C409.059601,634.421814 408.320496,634.764282 407.462158,635.532959 
	C406.543793,636.206848 405.814240,636.604187 404.843384,637.212646 
	C402.489563,638.576477 400.996552,640.426453 399.132812,642.357483 
	C397.923584,643.517212 396.943024,644.442505 395.258545,644.805298 
	C390.917328,646.407776 388.544098,649.960388 385.596619,652.791992 
	C379.512390,658.637024 374.117676,665.197693 367.179749,670.610657 
	C358.882904,668.286743 352.159729,663.027893 344.804199,658.976562 
	C336.283752,654.283508 328.047882,649.073914 319.376465,643.916504 
	C318.865265,643.652283 318.666046,643.560120 318.242065,643.227295 
	C318.017334,642.986694 318.002380,643.000000 318.006287,642.992798 
	C317.540894,642.547058 317.536041,641.819214 316.721008,641.489136 
	C316.415955,641.572266 316.118896,641.457092 315.998230,641.353027 
	C315.381165,640.159790 314.147156,640.173279 313.044983,639.437988 
	C312.751282,639.325317 312.950714,639.071594 312.839111,639.188110 
	C312.403595,638.064453 311.092377,638.195251 310.026794,637.475708 
	C309.730377,637.378235 309.805908,637.216125 309.742676,637.279297 
	C308.781372,635.577576 306.667328,635.717712 305.056946,634.457642 
	C304.750092,634.327332 305.028107,634.121521 304.883728,634.215454 
	C303.710724,632.980957 302.090851,632.911194 300.485535,632.121094 
	C300.293304,631.840942 299.950928,631.687500 299.823486,631.549805 
	C299.609589,631.167664 299.523102,630.923279 299.553650,630.308167 
	C301.605286,628.151672 303.659973,628.913879 305.596039,629.809326 
	C310.636719,632.140625 315.402344,634.950134 320.087189,638.582153 
	C327.754486,642.646790 334.767822,647.125366 342.270111,650.766052 
	C346.278046,652.711060 349.475281,656.092896 353.208038,658.603271 
	C356.685699,660.941956 360.333435,663.146973 364.262695,664.313782 
	C369.457703,665.856384 371.336639,660.389221 373.846802,657.787170 
	C381.905029,649.433899 391.349518,642.436584 398.370636,633.067932 
	C397.711243,631.667419 396.379547,631.715271 395.346741,631.897400 
	C388.693726,633.070740 383.716797,629.008118 378.011078,626.898865 
	C367.811218,623.128174 362.346802,614.131104 355.645660,606.947754 
	C351.583893,602.593689 347.036530,598.606934 343.106140,594.080383 
	C339.901398,590.389648 335.859741,587.728699 330.840607,586.019653 
	C320.283630,578.844177 310.436737,571.225769 299.150574,565.328369 
z"/>
<path fill="#1A98B0" opacity="1.000000" stroke="none" 
	d="
M253.822876,599.829956 
	C253.977585,600.011047 254.427933,600.093689 254.656708,600.102661 
	C255.401917,600.438599 256.005463,600.493286 256.783813,600.865356 
	C262.395050,602.555847 264.583038,608.361694 269.754944,610.693237 
	C270.333923,611.249451 270.703247,611.543396 271.198090,612.180969 
	C271.304504,612.740356 271.271179,612.974121 270.901306,613.318481 
	C258.700256,606.195923 246.766464,599.073303 234.991776,591.696777 
	C224.458344,585.097839 213.877991,578.599548 202.446686,572.921021 
	C202.090378,572.902954 202.072861,572.935303 202.075150,572.931274 
	C198.509659,570.864990 194.281311,569.840332 191.573883,565.955322 
	C191.489594,565.616455 191.424271,565.590698 191.441544,565.560181 
	C188.540863,563.065857 189.414856,559.328308 188.284561,555.855713 
	C188.219116,555.126404 188.209229,554.786804 188.072235,554.220581 
	C187.816940,553.785645 187.688766,553.577393 187.535278,553.003540 
	C187.210968,548.732788 184.695755,545.335693 184.818832,540.958008 
	C184.523529,540.155640 184.346558,539.698303 184.153000,538.862549 
	C184.131454,538.247253 184.153320,538.000061 184.313507,537.407837 
	C184.607056,536.871277 184.762314,536.679749 185.212982,536.269287 
	C191.054199,533.886780 195.339005,529.923096 199.434021,525.845947 
	C203.436508,521.860901 207.862549,518.376038 211.958832,514.512695 
	C213.274155,513.272217 214.641876,511.947815 217.039032,512.766052 
	C218.493988,515.873962 216.844116,518.078003 214.921066,519.492859 
	C207.524368,524.934753 202.667923,533.046326 194.901398,538.098816 
	C191.192749,540.511475 189.788559,543.964355 191.620712,548.473938 
	C192.984070,551.829712 193.490433,555.440063 195.085129,558.817749 
	C198.679764,566.431580 205.142670,569.986633 212.869751,572.666382 
	C215.472961,573.896179 216.991440,576.140808 219.775757,577.108521 
	C221.362289,578.234985 222.639374,579.204956 224.378693,580.114014 
	C233.535934,586.176636 242.686035,591.604370 251.672760,597.893677 
	C252.022263,598.469666 252.232040,598.693726 252.722076,598.947693 
	C253.179108,599.243896 253.423248,599.446899 253.822876,599.829956 
z"/>
<path fill="#24CCDD" opacity="1.000000" stroke="none" 
	d="
M290.125244,625.333557 
	C291.652069,623.103882 293.473297,624.493103 295.630524,624.957886 
	C298.170410,625.263977 299.649353,626.170898 299.175568,629.004517 
	C298.436005,629.590820 297.910858,629.670959 296.973450,629.446289 
	C294.499847,628.080872 292.411560,626.842957 290.125244,625.333557 
z"/>
<path fill="#24CCDD" opacity="1.000000" stroke="none" 
	d="
M277.726440,616.296265 
	C280.230316,616.538208 282.507965,617.140930 283.954529,619.674683 
	C282.888947,620.909790 282.049713,620.226868 280.818604,619.487549 
	C279.727783,619.145691 279.038940,618.805786 278.160065,618.246338 
	C277.609222,617.625366 277.459564,617.156555 277.726440,616.296265 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M304.869324,634.635620 
	C306.846191,634.087524 308.388336,635.001099 309.629181,636.994568 
	C307.759186,637.240417 306.337616,636.163147 304.869324,634.635620 
z"/>
<path fill="#1A98B0" opacity="1.000000" stroke="none" 
	d="
M277.688416,615.873901 
	C277.961334,616.429077 277.990753,616.885986 277.995056,617.684814 
	C276.347900,617.930420 275.163055,616.965698 273.775330,615.755737 
	C273.544678,615.258972 273.510620,615.020081 273.789276,614.599548 
	C274.582336,614.327332 275.052704,614.359192 275.795288,614.754028 
	C276.534363,615.241882 276.989624,615.508728 277.688416,615.873901 
z"/>
<path fill="#24CCDD" opacity="1.000000" stroke="none" 
	d="
M286.629272,621.433350 
	C288.475403,621.143738 289.015015,622.146667 288.928589,624.056152 
	C288.351166,624.220764 287.788788,623.984680 287.000183,623.541321 
	C286.518890,622.850830 286.411255,622.336731 286.629272,621.433350 
z"/>
<path fill="#1A98B0" opacity="1.000000" stroke="none" 
	d="
M286.415527,621.065063 
	C286.834991,621.577820 286.862579,622.080811 286.832092,622.958862 
	C285.594116,623.409851 284.680389,622.892883 283.810974,621.748413 
	C284.480499,621.316528 285.252075,621.185913 286.415527,621.065063 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M300.427734,632.139221 
	C301.966248,631.847839 303.370361,632.483765 304.641907,633.983582 
	C303.109009,634.216370 301.809692,633.384705 300.427734,632.139221 
z"/>
<path fill="#1A98B0" opacity="1.000000" stroke="none" 
	d="
M273.715851,615.334167 
	C272.696594,614.974426 271.814484,614.450867 270.748596,613.678223 
	C270.564819,613.429077 270.849640,613.127441 270.991089,612.975708 
	C271.535217,612.554260 271.975098,612.462036 272.734741,612.755676 
	C273.017303,612.964050 272.997589,612.995361 273.014099,612.987793 
	C273.460602,613.380737 274.006378,613.671265 274.183350,614.548096 
	C274.124084,614.840271 273.852997,615.170410 273.715851,615.334167 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M309.851807,637.670288 
	C311.053772,636.949646 311.941071,637.524475 312.663635,638.965027 
	C311.550507,639.388611 310.717316,638.766907 309.851807,637.670288 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M312.867432,639.630676 
	C314.066406,638.952393 314.966187,639.524536 315.761749,640.923218 
	C314.694519,641.292725 313.755920,640.797668 312.867432,639.630676 
z"/>
<path fill="#1A98B0" opacity="1.000000" stroke="none" 
	d="
M296.728882,629.614258 
	C297.126862,629.241943 297.665649,629.165100 298.613342,629.078369 
	C299.221161,629.169800 299.420074,629.270935 299.779419,629.665710 
	C299.911621,630.203430 299.883392,630.447449 299.775635,631.051758 
	C298.753906,630.911377 297.811768,630.410583 296.728882,629.614258 
z"/>
<path fill="#1A98B0" opacity="1.000000" stroke="none" 
	d="
M280.565857,619.773315 
	C281.375275,619.627991 282.333923,619.770447 283.657928,619.963013 
	C283.967468,620.245483 283.911682,620.477905 283.686340,621.028442 
	C282.353027,621.419067 281.477112,620.864197 280.565857,619.773315 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M316.496216,641.865234 
	C317.133942,641.419312 317.576050,641.764771 317.940247,642.681396 
	C317.299438,643.113220 316.886047,642.725586 316.496216,641.865234 
z"/>
<path fill="#228EA3" opacity="1.000000" stroke="none" 
	d="
M318.130249,643.187622 
	C318.380127,642.878479 318.670624,642.979065 318.976624,643.516479 
	C318.733917,643.756836 318.460144,643.638123 318.130249,643.187622 
z"/>
<path fill="#04556A" opacity="1.000000" stroke="none" 
	d="
M175.737396,510.310669 
	C178.254120,505.560272 176.422180,500.614197 177.795059,495.943695 
	C179.731079,489.357513 181.838303,482.737030 181.104767,475.589600 
	C180.749161,472.124695 183.402252,473.394012 185.271683,473.901459 
	C193.251617,476.067749 201.627213,476.844727 209.121613,480.752106 
	C209.707520,481.057617 210.691849,480.598938 211.745667,480.240936 
	C212.799210,480.252960 213.598099,480.519470 214.687256,480.917480 
	C222.969009,485.714966 231.415054,489.265717 240.750336,491.617889 
	C241.071243,491.813812 241.391632,492.077087 241.523987,492.236664 
	C241.608047,492.633881 241.559738,492.871552 241.325775,493.422272 
	C238.310455,496.346008 235.179626,498.514557 231.347260,500.251404 
	C227.936142,500.259766 225.915253,502.382080 223.443954,503.681244 
	C215.051208,508.093414 209.205917,515.255981 202.501678,521.595337 
	C197.433426,526.387695 193.012115,532.366455 185.547211,534.627075 
	C183.401657,533.721924 182.897888,531.727112 181.319977,530.234924 
	C181.087311,529.984131 180.951721,529.578918 180.903458,529.370850 
	C180.477203,528.573120 180.140045,527.966675 180.053757,526.910400 
	C179.635254,523.558838 180.079361,520.352844 177.826157,517.449707 
	C176.878586,516.056213 177.216782,514.590942 176.273239,513.211426 
	C176.065948,512.959106 175.921799,512.500610 175.848022,512.271912 
	C175.534653,511.595978 175.454132,511.121704 175.737396,510.310669 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M191.374542,565.984497 
	C194.910049,567.856018 198.330490,570.095642 201.914185,572.631226 
	C197.692917,572.016663 193.276306,571.158813 191.374542,565.984497 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M184.971497,539.803955 
	C186.201523,543.899048 187.120789,548.039368 188.010498,552.583191 
	C187.814011,553.112305 187.647079,553.237915 187.262390,553.138184 
	C186.370483,549.131897 184.290649,545.715271 184.172104,541.396362 
	C184.282867,540.592163 184.442978,540.208679 184.971497,539.803955 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M177.713318,516.809082 
	C181.279877,518.970581 180.809021,522.480286 181.195679,525.778320 
	C178.939133,526.329590 179.508667,524.174316 178.342957,523.220581 
	C178.546585,521.016907 177.698257,519.342468 176.979355,517.314941 
	C177.117020,516.873352 177.286789,516.750671 177.713318,516.809082 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M188.164215,555.933960 
	C190.345718,558.219238 190.175735,561.750427 191.432465,565.167419 
	C186.538086,563.885986 188.617767,559.665894 188.164215,555.933960 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M185.289612,539.454224 
	C185.057007,540.056030 184.831772,540.353333 184.393814,540.825317 
	C183.170868,538.230103 182.160629,535.460266 181.138275,532.319946 
	C183.918381,530.566040 183.553024,533.757141 185.146851,534.779663 
	C185.658859,535.506775 185.737335,536.003662 185.538940,536.884277 
	C185.407867,537.244995 185.240509,537.257935 185.204254,537.182251 
	C185.167999,537.106506 184.745026,537.302856 184.745026,537.302856 
	C184.745026,537.302856 184.778061,537.768005 184.996338,537.850525 
	C185.242065,538.338623 185.269531,538.744141 185.289612,539.454224 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M185.142151,534.808228 
	C182.924881,535.096191 183.241684,532.628174 181.438171,532.033081 
	C181.086472,531.512695 181.046783,531.075928 181.047211,530.311646 
	C183.495117,529.783264 184.401398,531.313477 184.961578,533.655090 
	C185.126694,534.193787 185.241409,534.378235 185.142151,534.808228 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M178.143295,523.328369 
	C179.779282,523.065918 179.856567,524.573914 180.725769,525.795532 
	C180.956268,526.797363 180.952835,527.585632 180.902298,528.768311 
	C178.215622,528.202637 179.121246,525.484863 178.143295,523.328369 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M174.823334,491.202881 
	C176.118698,492.079620 176.141205,493.277985 175.208344,494.772583 
	C173.820145,493.916687 173.794250,492.712738 174.823334,491.202881 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M177.969070,516.623535 
	C177.799713,516.992126 177.629364,516.994080 177.203125,516.996033 
	C175.939453,516.089355 176.017593,514.873962 176.035965,513.321594 
	C178.017075,513.298645 177.954117,514.799866 177.969070,516.623535 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M175.710831,509.999451 
	C175.934708,510.457733 175.927200,510.914581 175.846954,511.707336 
	C174.902420,511.800934 173.998123,511.580719 174.814011,510.158905 
	C175.016815,509.987549 175.479462,509.998016 175.710831,509.999451 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M187.129684,553.141846 
	C187.044662,552.912781 187.510834,552.976379 187.745880,552.981567 
	C188.007431,553.155762 188.033920,553.324829 188.002762,553.743896 
	C187.628830,553.871582 187.385361,553.663879 187.129684,553.141846 
z"/>
<path fill="#065D74" opacity="1.000000" stroke="none" 
	d="
M394.911621,645.168213 
	C395.705872,643.909729 396.490753,643.028259 397.545166,642.012207 
	C398.609283,641.570496 399.310883,641.783325 400.212280,642.546143 
	C400.980469,651.603821 405.870331,657.120911 413.612213,660.545471 
	C420.454529,663.572266 427.388855,666.449524 432.368256,672.446472 
	C433.101654,673.329651 434.254761,674.005798 435.450439,673.903442 
	C442.102875,673.333801 444.895630,677.784180 447.101837,682.864563 
	C449.226471,687.757080 449.526337,687.991333 454.324524,685.745911 
	C456.042694,684.941772 457.725372,684.434509 459.951538,685.033691 
	C460.956299,685.558289 461.410950,686.112183 461.671326,687.205566 
	C461.669922,688.393433 461.201172,689.068420 460.905792,689.810547 
	C458.938721,694.752991 459.164215,695.236633 464.497375,697.314148 
	C465.212799,697.703491 465.578583,697.984619 466.037598,698.651367 
	C466.267120,699.332886 466.356354,699.691467 466.450409,700.327759 
	C466.670166,704.802979 468.578857,708.839600 467.576111,713.555786 
	C467.502625,715.258789 466.549774,715.842468 465.336853,716.727295 
	C461.482361,716.100647 459.804016,720.314514 457.230133,720.280090 
	C454.625397,720.245483 454.497498,714.617065 451.470367,714.942749 
	C445.168152,715.620911 440.627258,709.939270 434.421631,710.945801 
	C433.249512,711.135864 431.249817,710.175720 431.618317,709.583740 
	C435.154907,703.901917 427.491852,703.489136 426.988617,699.377930 
	C426.486572,695.275940 423.901825,691.424500 422.193573,687.479126 
	C421.934814,686.881470 421.605591,686.134277 421.092010,685.854492 
	C415.147339,682.616638 411.543762,676.756653 406.308380,672.722046 
	C404.748566,671.520081 403.286407,670.534546 401.352600,671.399475 
	C398.136627,672.837891 396.704407,671.053040 395.753784,668.425659 
	C394.796906,665.780945 394.157898,663.004272 393.018585,660.446106 
	C390.607361,655.032166 392.322266,650.262634 394.911621,645.168213 
z"/>
<path fill="#266E80" opacity="1.000000" stroke="none" 
	d="
M466.354980,725.879333 
	C465.155243,731.004150 465.843445,736.299500 462.276886,740.789001 
	C461.636749,740.942261 461.386383,740.760986 461.126831,740.205139 
	C460.770355,737.203491 461.326935,734.511536 461.997131,731.426392 
	C461.855865,728.731750 463.442322,727.227478 464.731384,725.227966 
	C465.307190,723.317078 464.196777,721.219421 466.808960,720.082520 
	C467.068817,721.261780 466.950500,722.501648 466.526062,724.103027 
	C466.461914,724.809570 466.554382,725.190918 466.354980,725.879333 
z"/>
<path fill="#065D74" opacity="1.000000" stroke="none" 
	d="
M464.610626,725.029663 
	C465.510498,727.420654 464.099518,729.032837 462.281860,730.804321 
	C459.857544,727.940063 461.647644,726.358215 464.610626,725.029663 
z"/>
<path fill="#065D74" opacity="1.000000" stroke="none" 
	d="
M460.796387,740.253235 
	C461.302521,740.111145 461.546051,740.311035 461.858704,740.788513 
	C462.149994,741.938660 461.987823,742.791687 461.037476,743.740906 
	C460.009460,744.015930 459.351196,743.697815 458.838013,742.677612 
	C459.357697,741.779297 459.978149,741.147766 460.796387,740.253235 
z"/>
<path fill="#266E80" opacity="1.000000" stroke="none" 
	d="
M465.418945,717.003540 
	C465.116058,716.126221 465.416107,715.401123 466.610901,714.987183 
	C467.071808,714.730469 467.233551,714.558960 467.556091,714.702393 
	C467.714691,715.558594 467.712524,716.099976 467.532959,716.851074 
	C466.838989,717.044556 466.322357,717.028259 465.418945,717.003540 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M694.660034,435.709534 
	C695.134277,438.443268 694.096375,440.114258 691.404785,440.977356 
	C688.460693,440.307983 689.433777,438.955536 690.723389,437.297180 
	C690.997192,436.998779 691.000000,436.500610 691.001221,436.251526 
	C691.995605,435.166595 693.088013,434.895874 694.660034,435.709534 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M695.208984,435.878296 
	C693.933960,436.000122 692.867859,436.000671 691.402100,436.001831 
	C691.439148,433.917755 691.924133,431.865601 694.653687,431.046875 
	C695.234070,432.118286 695.471130,433.237793 695.850525,434.674255 
	C695.870728,435.298676 695.679138,435.553955 695.208984,435.878296 
z"/>
<path fill="#D4D2D3" opacity="1.000000" stroke="none" 
	d="
M690.623901,437.060699 
	C691.150879,438.239258 689.825073,439.313263 690.822937,440.826172 
	C691.056396,441.029877 691.030457,441.515442 691.017822,441.758240 
	C690.449768,442.558624 690.258972,443.398407 689.283447,443.923401 
	C688.995789,443.993347 688.496704,443.995636 688.247131,443.995361 
	C688.422852,441.597504 686.751587,438.512512 690.623901,437.060699 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M637.734375,451.700012 
	C629.299622,455.708740 620.083679,457.518311 610.352905,458.995605 
	C608.983948,457.721191 609.678406,457.311432 610.871826,457.039917 
	C619.705017,455.030548 628.373291,452.234894 637.734375,451.700012 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M688.997192,444.329498 
	C689.490845,443.413696 689.985840,442.834015 690.743042,442.127716 
	C690.855164,443.378326 691.482056,445.018738 689.354126,445.927338 
	C689.002808,445.562134 689.000732,445.113861 688.997192,444.329498 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M643.782837,450.705231 
	C643.435425,451.808929 642.566223,451.976593 641.267090,451.918945 
	C641.258057,450.394928 642.015015,449.690857 643.782837,450.705231 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M648.826782,449.825623 
	C648.507874,450.327332 648.016113,450.619781 647.224182,450.903442 
	C647.176758,450.030273 647.315857,449.012054 648.826782,449.825623 
z"/>
<path fill="#08B8DC" opacity="1.000000" stroke="none" 
	d="
M475.003143,560.597046 
	C469.941101,557.782471 464.851898,554.600647 459.836151,551.306885 
	C458.685699,550.551392 457.650909,549.889404 455.859985,550.468506 
	C455.480469,550.624756 455.511047,550.599426 455.509460,550.581543 
	C454.527313,548.223389 452.062012,548.229187 449.912964,547.098389 
	C445.960358,545.308533 443.578522,541.731079 439.347198,540.341797 
	C437.365326,537.503662 433.980194,537.164429 431.952667,534.951111 
	C430.029510,532.851868 428.578033,530.512085 427.792145,527.423096 
	C427.200958,522.777588 425.802887,518.733032 424.035675,514.799866 
	C422.503326,511.389465 422.982086,509.431549 427.253662,508.591980 
	C436.950623,506.686096 446.550598,504.286652 456.588501,502.063873 
	C456.904785,503.495422 455.777039,503.824066 454.698242,504.329620 
	C454.181763,504.571686 453.559357,505.271088 453.551117,505.772675 
	C453.533600,506.832611 454.529236,506.979553 455.365326,506.970215 
	C457.923065,506.941742 458.951538,508.812683 460.377014,510.510010 
	C466.317200,517.583069 471.269653,525.569092 479.724152,530.306519 
	C483.797302,532.588806 483.564911,537.386902 480.962189,542.638794 
	C478.222656,548.166748 476.165649,554.059387 475.003143,560.597046 
z"/>
<path fill="#06D1F2" opacity="1.000000" stroke="none" 
	d="
M517.684631,541.979980 
	C519.974487,543.773682 521.860596,545.562683 523.894775,547.644165 
	C525.798157,553.119385 526.410828,558.643188 523.568542,563.276489 
	C519.342163,570.166138 515.897522,577.372009 512.274536,584.907104 
	C506.654449,583.010803 502.334015,579.241150 497.135071,576.384521 
	C497.211548,573.183167 496.610748,570.735413 493.285858,570.054321 
	C488.862640,569.148132 489.735413,566.502014 491.176208,563.741028 
	C493.885040,558.550293 499.155121,555.365234 501.891388,550.188782 
	C502.650085,548.753479 503.759003,547.568420 504.024170,545.789795 
	C504.865448,540.147156 508.236877,538.338440 513.666626,540.528076 
	C514.868591,541.012756 515.855957,542.027649 517.684631,541.979980 
z"/>
<path fill="#0ABFDE" opacity="1.000000" stroke="none" 
	d="
M487.270782,495.285095 
	C491.031677,492.439880 495.784882,492.309662 500.607483,491.132446 
	C497.021881,494.208282 492.429352,495.351044 487.270782,495.285095 
z"/>
<path fill="#04BBDC" opacity="1.000000" stroke="none" 
	d="
M554.000977,514.999023 
	C551.261658,514.998047 548.521240,514.998047 545.780884,514.998047 
	C545.793457,514.456543 545.805969,513.915039 545.818542,513.373535 
	C548.532593,513.675293 551.218323,509.441925 553.979370,514.614014 
	C553.998413,515.001953 554.000000,515.000000 554.000977,514.999023 
z"/>
<path fill="#0ABFDE" opacity="1.000000" stroke="none" 
	d="
M465.303589,500.343719 
	C467.492310,498.341980 470.576294,499.070496 473.602936,498.036316 
	C472.294037,502.601135 468.580139,500.430847 465.303589,500.343719 
z"/>
<path fill="#0ABFDE" opacity="1.000000" stroke="none" 
	d="
M478.150024,497.895264 
	C479.183990,495.246735 481.747467,495.265015 484.613495,495.153809 
	C483.650604,498.020050 480.768646,497.181030 478.150024,497.895264 
z"/>
<path fill="#0ABFDE" opacity="1.000000" stroke="none" 
	d="
M504.240356,491.251282 
	C505.804810,489.475220 507.970032,489.095795 510.623840,489.101196 
	C509.538147,491.892639 507.008118,491.690338 504.240356,491.251282 
z"/>
<path fill="#0ABFDE" opacity="1.000000" stroke="none" 
	d="
M517.754517,488.586487 
	C517.824890,489.105652 517.630432,489.408813 517.204102,489.855286 
	C515.808594,489.830597 514.644897,489.662567 513.235535,489.237854 
	C514.339966,487.673553 515.894775,487.873627 517.754517,488.586487 
z"/>
<path fill="#04BBDC" opacity="1.000000" stroke="none" 
	d="
M544.388916,518.402710 
	C543.310364,518.858337 542.471130,518.804443 542.524658,517.712891 
	C542.546021,517.277710 543.289978,516.543152 543.724060,516.528931 
	C544.808960,516.493469 544.866089,517.327148 544.388916,518.402710 
z"/>
<path fill="#04BBDC" opacity="1.000000" stroke="none" 
	d="
M536.742737,523.104370 
	C536.486206,525.552612 534.453674,524.891663 532.408569,525.050659 
	C533.168823,523.708191 534.886108,523.655212 536.742737,523.104370 
z"/>
<path fill="#0ABFDE" opacity="1.000000" stroke="none" 
	d="
M553.269043,480.967468 
	C553.454407,480.685730 553.934082,480.380890 554.707642,480.055298 
	C554.522156,480.337830 554.042725,480.641113 553.269043,480.967468 
z"/>
<path fill="#08B8DC" opacity="1.000000" stroke="none" 
	d="
M479.818665,564.779297 
	C479.453613,564.826294 478.916321,564.640503 478.210266,564.219604 
	C478.576508,564.171875 479.111450,564.359192 479.818665,564.779297 
z"/>
<path fill="#04BBDC" opacity="1.000000" stroke="none" 
	d="
M541.819458,522.785034 
	C541.535950,523.010376 541.106750,523.027527 540.356140,523.027222 
	C540.446228,522.354919 540.950989,522.052795 541.819458,522.785034 
z"/>
<path fill="#24CCDD" opacity="1.000000" stroke="none" 
	d="
M560.137817,477.933044 
	C560.051819,477.719299 560.248352,477.506531 560.738892,477.323456 
	C560.803040,477.530060 560.598877,477.723694 560.137817,477.933044 
z"/>
<path fill="#06ABCA" opacity="1.000000" stroke="none" 
	d="
M596.219543,469.187134 
	C596.589111,468.147858 597.425049,468.021027 598.630371,468.021606 
	C598.726379,469.631256 598.002380,470.320587 596.219543,469.187134 
z"/>
<path fill="#06ABCA" opacity="1.000000" stroke="none" 
	d="
M588.749146,509.074524 
	C588.921509,509.908295 588.624207,510.668945 587.328613,510.957947 
	C587.110962,510.098419 587.411926,509.340271 588.749146,509.074524 
z"/>
<path fill="#06A6C2" opacity="1.000000" stroke="none" 
	d="
M629.585999,501.010986 
	C626.718506,502.351593 623.559875,504.158508 619.420166,504.009613 
	C621.642395,500.464233 625.622925,501.490936 629.585999,501.010986 
z"/>
<path fill="#039EBD" opacity="1.000000" stroke="none" 
	d="
M633.280762,500.785339 
	C632.276917,500.998474 631.527771,501.017365 630.404541,501.036316 
	C630.231140,498.972168 631.755798,499.007538 633.627686,499.004150 
	C634.458435,499.713745 634.045288,500.166626 633.280762,500.785339 
z"/>
<path fill="#0596B4" opacity="1.000000" stroke="none" 
	d="
M633.356323,500.983215 
	C633.275879,500.489471 633.525635,499.999359 633.887817,499.257385 
	C634.000244,499.005493 634.497742,498.999237 634.746460,498.996216 
	C635.304382,499.126190 635.561218,499.326385 635.885376,499.809601 
	C635.428833,500.819977 634.590637,500.982910 633.356323,500.983215 
z"/>
<path fill="#BFBFBF" opacity="1.000000" stroke="none" 
	d="
M459.992340,627.006958 
	C455.958008,625.533630 453.229492,623.059021 451.244415,618.957214 
	C448.785858,613.877075 445.940643,608.966248 440.098785,606.243164 
	C433.215332,603.034607 430.088898,595.621765 424.911469,590.455933 
	C417.666290,583.226929 413.817963,572.669250 403.230286,568.250366 
	C398.842987,561.898193 394.267456,556.095459 390.039795,550.054626 
	C386.437744,544.907715 382.591370,541.309692 375.715851,543.325073 
	C374.141785,543.786438 372.131989,544.555847 370.556763,542.332031 
	C370.472992,540.052917 371.656128,538.967896 373.235596,538.205444 
	C377.884399,535.961121 387.174652,537.540222 390.955261,541.246765 
	C394.655182,544.874146 397.819092,548.862976 400.070282,553.610535 
	C403.725983,561.319946 410.290558,566.668701 416.478638,572.075623 
	C424.034760,578.677917 429.110748,587.429016 436.771057,593.914307 
	C442.432190,598.706970 447.504028,604.160767 453.482147,608.660950 
	C456.716980,611.096130 459.212097,614.516296 462.184357,617.368286 
	C463.952820,619.065186 464.289124,621.822571 463.232330,624.701599 
	C462.999359,625.000000 463.000000,625.000000 463.000610,625.000000 
	C462.355011,625.869263 461.228088,625.949280 460.214325,626.783508 
	C460.000000,627.000000 459.998108,627.001648 459.992340,627.006958 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M370.777222,541.715454 
	C372.012634,543.211975 373.094208,542.347900 374.167999,542.123840 
	C383.829773,540.107727 386.392059,541.629028 391.713257,550.042419 
	C395.371338,555.826355 400.143127,560.903625 402.953430,567.644897 
	C399.104797,567.506226 398.784943,563.588257 397.120361,561.245972 
	C394.819794,558.008606 392.176941,555.694336 388.349731,554.047485 
	C387.872925,553.695679 387.678955,553.442627 387.209045,553.120483 
	C384.225159,544.641785 377.855682,545.330811 371.191895,546.686462 
	C370.403687,546.846802 369.558014,546.695374 368.378754,546.953735 
	C367.250000,546.998535 366.500000,546.999512 365.375000,547.001099 
	C362.673950,548.167419 360.168091,548.017151 357.340271,548.003235 
	C356.250977,548.005005 355.502045,548.005188 354.378662,548.006104 
	C353.496307,548.171509 353.070831,548.037537 352.591187,547.301758 
	C352.444336,546.173950 352.731842,545.508789 353.483765,544.667114 
	C354.170685,544.172913 354.605316,543.969910 355.404785,543.743774 
	C357.088318,543.580688 358.421112,543.460205 359.789154,542.335754 
	C360.928925,541.808105 361.820312,541.642761 363.063477,541.509399 
	C364.604248,541.531677 365.573029,540.881958 366.947327,540.319763 
	C368.582428,540.074463 369.683258,540.439697 370.777222,541.715454 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M639.818237,944.783203 
	C640.428101,945.022583 640.440613,945.302490 640.020996,945.750366 
	C634.159607,949.085083 628.364685,951.930115 621.295776,951.461975 
	C627.161804,949.106934 633.321655,946.959595 639.818237,944.783203 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M329.961823,577.351196 
	C329.589081,577.195923 329.256470,577.180115 329.109283,577.102295 
	C327.829285,575.821289 326.914673,574.543091 327.711792,572.417664 
	C329.626495,570.698486 332.083344,570.204041 332.272980,567.198364 
	C332.402527,565.145264 334.456787,564.587830 336.128540,565.279236 
	C340.250549,566.984070 342.473175,564.820801 344.136200,561.621155 
	C346.262634,557.529846 349.218933,556.167969 353.494202,558.226624 
	C354.266113,558.598328 355.357574,559.043579 355.813171,558.363525 
	C359.193695,553.317566 363.422119,556.467041 367.435577,556.936218 
	C370.716400,557.319702 373.915527,558.165283 377.294495,558.096558 
	C380.720032,558.026855 382.692749,560.886841 384.987030,562.977722 
	C391.125824,568.572205 396.080597,575.393372 403.648743,579.521301 
	C410.189514,583.088806 413.382721,589.814087 417.090179,595.957031 
	C418.510315,598.310181 420.362640,600.414490 422.114258,602.553345 
	C427.391846,608.997253 430.515503,616.316162 431.400665,624.614685 
	C431.510376,625.642944 431.763458,626.568176 431.009430,627.409668 
	C427.418671,631.417053 430.259247,632.890381 433.686859,633.903137 
	C435.140839,634.332764 436.502380,634.735291 437.723175,635.689575 
	C440.056213,637.513367 439.503479,638.804688 436.926758,640.170898 
	C430.708679,640.552856 426.688446,636.769348 422.396393,632.797729 
	C417.623199,625.885193 418.784454,617.731995 416.244781,610.548462 
	C414.371826,605.250854 411.107025,601.060242 408.009735,596.525879 
	C402.170135,587.976868 393.567902,583.631287 384.973358,579.009094 
	C382.748322,577.812439 380.446472,576.794495 377.910614,576.784180 
	C368.993378,576.748047 360.095795,576.447083 351.294922,578.811218 
	C347.527100,579.823364 343.370880,579.370117 339.295044,581.203369 
	C335.181091,582.002136 333.049652,579.091980 329.961823,577.351196 
z"/>
<path fill="#04556A" opacity="1.000000" stroke="none" 
	d="
M460.268646,685.705444 
	C457.832306,685.961609 456.049683,686.230042 454.152130,687.893311 
	C450.900848,690.743042 446.971313,689.051514 446.032562,684.891296 
	C444.576080,678.436401 441.803619,676.150024 435.100098,675.930420 
	C434.042725,675.895874 433.005310,675.655701 432.425629,674.827637 
	C426.103149,665.795959 415.105621,664.097656 406.665100,658.389465 
	C402.310303,655.444336 398.702698,648.259399 399.945526,643.369629 
	C399.649689,640.021423 402.799133,639.045227 403.816467,636.469971 
	C404.687683,635.778992 405.327667,635.335632 406.253571,634.826172 
	C406.999207,634.553772 407.478638,634.416382 408.341248,634.426453 
	C410.139313,636.088562 411.688843,636.708740 414.029327,637.089844 
	C420.893311,638.207458 428.116974,638.712158 433.940582,643.316956 
	C438.009155,646.533997 442.283325,649.209595 447.641724,649.768311 
	C450.302002,650.045776 452.180878,651.636658 453.102295,654.627441 
	C453.592590,655.975403 453.996460,656.976685 454.381195,658.388794 
	C454.098785,664.476929 457.221130,669.216492 458.682465,674.416199 
	C459.693756,678.014526 461.614014,681.463623 460.268646,685.705444 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M352.749939,546.705078 
	C353.127380,547.306091 353.322235,547.560486 353.794373,547.885010 
	C351.430939,552.447815 346.265533,552.684021 342.470764,553.240051 
	C335.299072,554.290894 327.751984,555.031677 320.538452,552.007874 
	C316.879822,550.474243 313.796539,547.697449 309.365509,547.048157 
	C305.163605,547.705017 301.306793,548.326599 297.333435,549.909790 
	C293.639282,550.672241 291.066193,547.671814 287.091705,547.824829 
	C284.607635,543.282715 280.585632,540.259460 279.935150,535.044250 
	C279.518188,531.701477 275.783630,532.636963 272.857086,532.948364 
	C271.124847,533.263611 270.228729,534.231567 269.043610,535.390259 
	C266.485260,535.969116 265.816223,534.059753 264.498657,532.905090 
	C260.508423,529.408508 256.630035,525.640564 250.863770,524.161133 
	C247.265976,522.248840 245.208847,519.687988 245.324371,515.466309 
	C246.758606,513.592773 248.523849,513.485596 250.005676,514.317871 
	C252.563843,515.754700 253.994080,518.952148 258.227966,516.335571 
	C260.600372,514.869324 264.691650,516.483704 267.895599,517.500793 
	C268.395172,517.659363 268.971588,517.521912 269.263702,517.114197 
	C271.199371,514.413086 273.471375,513.661743 276.567749,515.325439 
	C279.008606,516.636963 281.562714,517.400513 283.826721,519.398071 
	C287.531830,522.667358 291.185150,526.581482 297.282654,525.465759 
	C300.460999,524.884216 302.266083,527.260254 301.631561,530.521912 
	C301.226044,532.606506 301.351654,533.915588 303.424286,534.995972 
	C310.738434,538.808716 317.952637,542.793884 324.924103,547.233643 
	C327.997772,549.191040 331.471863,550.434753 335.364594,549.049927 
	C339.079803,547.728394 343.423340,549.246948 347.191772,546.415283 
	C349.204315,545.169556 350.877899,545.197510 352.749939,546.705078 
z"/>
<path fill="#26323D" opacity="1.000000" stroke="none" 
	d="
M422.443054,632.019409 
	C426.669464,635.553772 431.327698,637.968201 436.397705,640.363403 
	C436.742126,640.459595 436.685120,640.767700 436.688202,640.924316 
	C436.248749,642.427917 435.120087,643.068359 433.966278,642.610352 
	C426.508362,639.649902 418.354370,638.204712 411.890656,632.347900 
	C411.737549,631.988708 411.794983,631.598206 411.800934,631.400940 
	C411.554321,630.579224 411.561005,629.842590 410.663269,629.391052 
	C407.797485,628.007812 405.272797,626.583313 402.416870,625.089478 
	C400.522186,624.205322 398.574280,624.071960 397.272827,622.156128 
	C397.064087,621.889526 396.999390,621.946411 397.042023,621.940430 
	C386.823212,615.942688 376.030243,610.880676 366.001556,604.513855 
	C362.977142,602.593811 359.197845,600.859436 358.384521,596.293152 
	C358.690521,595.766052 358.868317,595.591370 359.377625,595.246277 
	C362.408356,594.334778 364.530151,595.700012 366.673859,596.985229 
	C380.040558,604.999207 393.600433,612.689331 406.827515,620.936890 
	C407.682404,621.469849 408.639557,621.752502 409.828613,622.384033 
	C410.340637,622.668030 410.542725,622.783081 411.022217,623.102722 
	C411.815399,623.737610 412.260620,624.246765 413.082947,624.821960 
	C416.631439,626.887695 420.045105,628.581787 422.443054,632.019409 
z"/>
<path fill="#ABCED8" opacity="1.000000" stroke="none" 
	d="
M246.246094,514.678711 
	C246.063202,518.355774 248.181625,520.541992 250.666077,522.853271 
	C251.455902,523.489624 251.986130,523.916870 252.762085,524.579590 
	C254.874115,527.716614 257.051605,530.291809 260.555420,531.974609 
	C268.695343,538.435059 277.598419,542.945618 286.712616,547.779968 
	C286.966736,548.032288 286.980316,548.024719 286.977051,548.032959 
	C289.320251,553.463196 296.055695,552.634766 299.647461,556.871216 
	C300.517944,557.532166 301.059967,558.065552 301.803009,558.801270 
	C302.005157,559.002441 302.000000,559.000000 301.998291,558.997559 
	C302.317535,559.244690 302.639191,559.493408 303.117981,560.003174 
	C303.268616,560.468567 303.262756,560.671936 303.008606,561.052734 
	C296.742340,559.834656 292.511444,554.963684 286.426575,552.954590 
	C285.902893,553.053833 285.731812,553.211121 285.326385,553.110229 
	C285.092041,552.852173 285.110840,552.976257 285.119080,552.913940 
	C281.324127,550.869263 277.446472,549.043823 273.180023,547.203369 
	C272.636047,546.949402 272.423706,546.830872 271.915283,546.492798 
	C261.756226,540.463440 252.237839,534.251831 245.932785,524.284790 
	C241.830399,517.799744 240.216309,510.786713 241.726837,502.898621 
	C242.525681,502.420380 243.049591,502.371368 243.950729,502.663757 
	C245.287125,503.928802 245.528076,505.255463 245.589752,507.037964 
	C245.765778,509.758881 247.388885,511.895020 246.246094,514.678711 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M411.445343,632.006958 
	C418.919708,634.637390 425.350189,639.051025 432.855072,640.960571 
	C433.948730,641.238831 434.911896,642.117432 436.404572,641.225586 
	C441.606720,642.840271 445.378815,646.692200 450.387756,648.396118 
	C452.766907,649.205444 452.827240,652.075928 453.258057,654.640747 
	C451.080658,652.468140 448.645599,650.953979 445.122345,650.883545 
	C440.368317,650.788574 437.076111,647.349304 433.355713,644.889404 
	C426.581726,640.410400 419.297394,637.874329 410.964203,639.414612 
	C407.182739,640.113525 408.534821,637.499573 408.938293,635.309570 
	C408.604614,634.552063 408.462646,634.074158 408.752563,633.198425 
	C409.690491,632.586670 410.421814,632.305908 411.445343,632.006958 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M460.222565,686.178223 
	C460.028015,678.706726 456.708191,672.360840 454.128174,665.848633 
	C453.222260,663.561951 452.487457,661.588440 454.047363,659.059204 
	C458.289520,670.556152 462.423920,682.249878 464.982971,694.641479 
	C461.625824,694.040771 461.520355,691.245728 460.986267,688.363953 
	C460.816132,687.455872 460.634277,686.908691 460.222565,686.178223 
z"/>
<path fill="#ABCED8" opacity="1.000000" stroke="none" 
	d="
M327.604431,572.062988 
	C328.280579,573.486145 328.632172,574.888611 328.972931,576.657837 
	C322.126343,575.136658 316.867462,570.355957 310.935333,566.865784 
	C308.308411,565.320190 305.114166,564.375366 302.914062,561.531311 
	C302.760315,561.230103 302.902802,561.089233 302.971680,561.016479 
	C303.447906,560.577026 303.920959,560.443909 304.783051,560.755615 
	C305.106323,560.966736 305.532074,560.984314 305.744873,560.995239 
	C306.751312,561.694458 307.833191,561.892151 308.865173,562.779053 
	C309.863739,563.248474 310.626495,563.514893 311.663269,563.919189 
	C312.891602,564.842468 313.722687,565.759827 314.885742,566.771790 
	C316.836151,567.624939 318.543640,568.288025 320.593140,569.002319 
	C322.291809,569.190186 323.620087,569.396057 324.773193,570.803162 
	C325.558533,571.838196 326.391968,572.004517 327.604431,572.062988 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M368.215149,547.261475 
	C371.273285,543.155151 375.676575,545.798645 379.476685,544.992432 
	C385.604248,543.692444 385.869415,548.339844 386.946014,552.618042 
	C385.748810,552.916992 384.638458,552.429810 384.188568,551.248047 
	C382.030396,545.578979 377.731415,546.413818 373.478241,547.870911 
	C371.586151,548.519104 370.032532,548.953125 368.215149,547.261475 
z"/>
<path fill="#C9C8C9" opacity="1.000000" stroke="none" 
	d="
M297.252686,550.254272 
	C299.830994,545.642883 304.094055,545.930603 308.717651,546.736389 
	C308.448547,547.800659 307.750488,548.539368 307.373291,549.416138 
	C305.945404,552.734985 304.071289,554.140808 300.700775,551.551025 
	C299.863159,550.907410 298.582428,550.840454 297.252686,550.254272 
z"/>
<path fill="#04556A" opacity="1.000000" stroke="none" 
	d="
M460.684387,688.182495 
	C462.871063,689.751465 463.469666,692.171814 464.604797,694.667603 
	C464.894409,695.374084 464.963745,695.838318 465.028809,696.655823 
	C465.123352,697.208008 465.222198,697.406982 465.049225,697.798828 
	C462.612885,697.702942 459.759979,699.025391 458.536041,696.414124 
	C457.178741,693.518127 457.997223,690.648132 460.684387,688.182495 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M467.861694,714.649658 
	C467.716858,715.017273 467.310333,714.986755 467.110199,714.944336 
	C467.675476,710.380310 465.790771,706.003601 466.316345,701.054199 
	C468.605164,704.979187 468.663696,709.590027 467.861694,714.649658 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M357.276184,548.262817 
	C359.263885,546.293701 362.022766,547.120850 364.753662,546.771362 
	C363.035187,550.046143 360.247711,549.057434 357.276184,548.262817 
z"/>
<path fill="#D7D5D6" opacity="1.000000" stroke="none" 
	d="
M325.108276,570.756287 
	C323.578430,571.041077 322.201324,570.949341 321.117981,569.344116 
	C324.435852,567.369812 324.994843,567.550537 325.108276,570.756287 
z"/>
<path fill="#BCDEE5" opacity="1.000000" stroke="none" 
	d="
M315.045319,566.631592 
	C313.564392,566.961487 312.466095,566.320312 311.997986,564.393188 
	C313.482697,564.081238 314.523346,564.785583 315.045319,566.631592 
z"/>
<path fill="#BCDEE5" opacity="1.000000" stroke="none" 
	d="
M309.001068,562.649414 
	C307.921753,562.994995 306.958252,562.575378 306.120667,561.291443 
	C307.116669,561.043640 308.346252,560.922485 309.001068,562.649414 
z"/>
<path fill="#BCDEE5" opacity="1.000000" stroke="none" 
	d="
M305.003296,560.638916 
	C304.646515,560.971863 304.186676,560.976990 303.383728,560.962952 
	C302.764191,560.464478 302.487762,559.985229 302.103943,559.250488 
	C303.147919,559.028931 304.350128,558.950500 305.003296,560.638916 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M466.403992,725.994507 
	C466.164734,725.714417 466.116730,725.278625 466.144348,724.653687 
	C466.519409,724.517639 466.818878,724.570740 467.118347,724.623840 
	C466.943939,725.028809 466.769592,725.433777 466.403992,725.994507 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M464.989868,698.187073 
	C464.803040,697.823853 464.828735,697.656006 464.939453,697.248657 
	C465.652771,697.407532 466.721313,697.405212 466.325745,698.787720 
	C465.852692,698.777100 465.527527,698.579773 464.989868,698.187073 
z"/>
<path fill="#BCDEE5" opacity="1.000000" stroke="none" 
	d="
M301.951691,558.686096 
	C301.109589,558.910034 300.349823,558.625488 300.019653,557.331360 
	C300.862061,557.097900 301.630920,557.373413 301.951691,558.686096 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M465.372803,858.295654 
	C464.695007,857.436340 464.182953,856.894653 464.120239,856.305237 
	C463.819275,853.475830 463.661438,850.423706 466.286499,848.655945 
	C468.806152,846.959167 470.245819,850.223694 472.683960,850.854614 
	C473.112305,851.166687 473.224609,851.333313 473.168457,851.750000 
	C471.548462,853.464478 471.986603,855.610535 471.048218,857.638733 
	C469.386932,859.426636 467.644562,859.704407 465.372803,858.295654 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M464.945526,858.358032 
	C466.714355,858.000000 468.428711,858.000000 470.571533,858.000000 
	C471.372223,861.362732 470.949860,864.195129 467.160370,865.602051 
	C465.629639,866.170471 464.259491,867.744873 462.816589,865.942993 
	C461.571869,864.388855 461.575195,862.605164 463.111084,861.018311 
	C463.781586,860.325623 464.302307,859.487915 464.945526,858.358032 
z"/>
<path fill="#C9C7C7" opacity="1.000000" stroke="none" 
	d="
M473.300354,852.158691 
	C473.000000,852.000000 473.000000,851.500000 473.000000,851.250000 
	C473.556519,849.925415 474.353027,849.176758 475.624207,849.083557 
	C477.485565,848.947083 478.718109,849.915222 479.555054,851.441101 
	C480.075348,852.389771 479.946411,853.388611 479.120270,854.103882 
	C477.609375,855.412109 476.407013,854.102600 475.167999,853.476929 
	C474.596191,853.188171 474.120148,852.709778 473.300354,852.158691 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M472.610779,902.002991 
	C472.940369,903.683411 472.932251,905.446045 470.699738,905.730164 
	C469.708221,905.856384 469.179749,905.126160 469.152679,904.219055 
	C469.087585,902.036865 470.696014,902.051575 472.610779,902.002991 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M471.848450,835.366943 
	C473.095551,836.423096 473.228210,837.467468 472.258087,838.412109 
	C471.775635,838.881897 470.977112,838.885254 470.587769,838.253601 
	C469.803589,836.981445 469.765564,835.820374 471.848450,835.366943 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M449.154236,910.485962 
	C449.119049,909.267456 448.719910,908.106567 449.902985,907.744751 
	C450.751068,907.485474 451.000763,908.459595 450.882965,908.975891 
	C450.695282,909.798706 451.842743,912.106384 449.154236,910.485962 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M464.693146,913.549927 
	C465.423065,914.115784 465.867798,914.678711 465.373932,915.261902 
	C464.848907,915.881836 464.185333,915.556152 463.801819,915.001099 
	C463.273987,914.237305 463.215240,913.537170 464.693146,913.549927 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M471.600006,955.283813 
	C471.719666,955.305115 471.822205,954.379395 471.923187,955.320312 
	C471.792114,955.459473 471.658691,955.347961 471.600006,955.283813 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M400.394470,986.995789 
	C398.086426,983.643982 398.705505,981.603455 402.794037,980.719849 
	C405.159424,980.208740 407.315186,978.790161 409.661346,978.112671 
	C412.956909,977.161011 413.771515,975.208679 412.672485,971.761841 
	C417.376648,967.047791 421.364075,961.904419 427.609314,958.990845 
	C427.311707,960.725159 426.658264,962.471252 425.828613,964.521729 
	C424.057373,967.643311 420.492645,970.631287 422.376740,973.616211 
	C424.414185,976.843933 428.833923,974.913879 432.204681,974.710449 
	C434.500183,974.571960 436.680389,973.440063 439.000946,973.149658 
	C440.942657,972.906555 443.021179,972.244385 444.309326,974.807617 
	C443.039703,976.044434 441.833771,976.939453 440.311157,977.912903 
	C435.505981,977.878052 431.630585,980.879272 427.073273,980.520752 
	C426.121521,980.445923 425.124420,981.010742 424.165009,980.960388 
	C417.652527,980.618652 411.428558,981.259277 405.832428,985.128174 
	C404.401001,986.117737 402.473114,986.389282 400.394470,986.995789 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M444.610229,975.078979 
	C440.967712,972.748718 437.726929,974.829895 434.875153,975.816772 
	C431.435577,977.007080 428.013855,976.761414 424.682983,976.785706 
	C419.618988,976.822571 418.948151,975.061768 420.718140,970.263733 
	C421.585052,967.913940 422.940887,966.238098 425.339233,964.925354 
	C426.285248,966.633179 423.929504,968.891907 426.198914,970.321106 
	C428.321869,971.658142 430.307312,970.080505 432.195709,969.236206 
	C437.977570,966.651367 447.242859,967.649231 454.867523,972.048401 
	C451.303497,973.114868 448.139160,974.061707 444.610229,975.078979 
z"/>
<path fill="#060F14" opacity="1.000000" stroke="none" 
	d="
M446.142914,993.897217 
	C443.927063,993.622620 443.859436,992.330994 444.233734,990.921021 
	C445.605865,985.751892 453.500336,980.455688 458.847870,981.052917 
	C462.518585,981.462952 464.508881,983.505615 464.953186,987.151550 
	C465.796997,994.075806 463.545532,997.831238 455.814392,1001.786377 
	C454.892700,999.866943 456.166504,998.633789 456.779877,996.907837 
	C457.417847,995.631470 457.573730,994.558167 457.818817,993.172119 
	C458.233795,990.630371 458.077911,988.190063 455.881226,987.369934 
	C453.011688,986.298584 451.771912,989.176025 449.929932,991.119507 
	C448.755981,992.366760 447.827179,993.391418 446.142914,993.897217 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M457.927521,996.629761 
	C457.374756,998.343628 456.670288,999.688782 455.792725,1001.306824 
	C450.033112,1003.370422 446.946045,1001.529907 446.067719,995.473328 
	C450.287537,992.702454 454.145416,993.344116 457.927521,996.629761 
z"/>
<path fill="#4A4C4D" opacity="1.000000" stroke="none" 
	d="
M458.342773,996.759338 
	C454.502563,995.534180 450.689545,995.418579 446.524139,995.069885 
	C446.071899,994.899414 446.017548,994.702087 445.880981,994.208069 
	C446.538452,992.876648 447.018219,991.608337 448.641418,991.008118 
	C452.443237,989.317932 455.607666,990.086853 458.790344,992.632568 
	C458.870667,994.177551 458.738495,995.348877 458.342773,996.759338 
z"/>
<path fill="#0D1315" opacity="1.000000" stroke="none" 
	d="
M432.531097,956.482056 
	C432.556396,956.527161 432.505798,956.436951 432.531097,956.482056 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M240.956863,491.488098 
	C233.915268,492.163849 219.234100,486.447327 215.055832,481.381714 
	C223.792419,483.925842 232.595825,486.832184 240.956863,491.488098 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M307.892578,460.631439 
	C300.938507,460.816528 293.911133,461.401733 286.440582,462.031982 
	C285.336029,461.265320 284.675751,460.476746 283.921906,459.391296 
	C290.475494,459.064362 297.122711,459.008972 303.769714,459.024628 
	C305.191223,459.027954 306.837646,458.559326 307.892578,460.631439 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M241.352539,493.616119 
	C241.577271,493.296844 241.611069,492.845734 241.633713,492.620972 
	C242.306168,492.346252 242.955994,492.296265 243.930145,492.172180 
	C244.565247,491.690369 244.953323,491.378998 245.784805,491.069244 
	C247.821487,490.251373 249.685898,490.755798 251.764435,490.109741 
	C252.688370,490.088898 253.199036,490.209198 254.000671,490.678528 
	C255.051788,492.277954 254.682449,493.520599 253.692719,495.028961 
	C252.820190,495.820740 252.111725,496.112610 250.993073,496.356598 
	C249.229156,496.448669 247.811050,496.428375 246.402893,497.715851 
	C244.985672,498.522156 243.825729,498.909180 242.185455,498.461853 
	C240.652481,496.997498 240.571609,495.518341 241.352539,493.616119 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M269.922241,451.433746 
	C274.556946,452.804810 280.226624,452.822479 282.953735,458.643311 
	C277.888397,457.998230 273.986420,454.836121 269.922241,451.433746 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M252.273254,490.745026 
	C250.486053,492.540588 248.545532,491.851196 246.360199,491.971558 
	C245.595779,491.517212 245.445541,491.022797 245.664001,490.096252 
	C245.823044,489.727875 245.951599,489.367981 246.064957,489.214142 
	C246.403351,488.734741 246.673767,488.453644 247.262482,488.099091 
	C247.707687,487.897003 247.887619,487.820343 248.341797,487.644775 
	C249.410309,487.284393 249.881256,486.622406 250.692963,485.862854 
	C251.777435,485.154755 252.656754,485.063934 253.890900,485.544373 
	C254.511658,486.118042 254.714493,486.556885 254.841110,487.410278 
	C254.708313,489.216644 253.621475,489.851044 252.273254,490.745026 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M245.548859,489.865784 
	C245.867783,490.221222 245.912537,490.714569 245.956512,491.577911 
	C245.578186,492.000092 245.200623,492.052307 244.538773,492.101288 
	C244.207993,491.211670 244.600891,490.539276 245.548859,489.865784 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M248.145737,488.690857 
	C247.598618,489.005981 247.192352,489.026947 246.482208,489.054077 
	C246.186188,488.640747 246.194046,488.221161 246.232834,487.488770 
	C246.263763,487.175964 246.641953,487.113770 246.832428,487.092590 
	C247.715836,487.254089 248.117249,487.714752 248.145737,488.690857 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M326.924133,464.549133 
	C323.641388,464.938141 320.711395,463.181458 317.225922,462.340454 
	C320.981506,459.363373 323.853271,462.053009 326.924133,464.549133 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M337.004822,468.963928 
	C333.773560,469.029510 331.889801,466.697174 329.161011,465.377258 
	C332.236572,463.578308 334.036987,466.419281 336.675110,467.836731 
	C337.081268,468.232758 337.190521,468.469696 337.004822,468.963928 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M314.919739,461.625732 
	C313.509705,461.842133 312.015442,461.714691 310.231079,461.354797 
	C311.622345,459.646393 313.265411,459.345764 314.919739,461.625732 
z"/>
<path fill="#08748E" opacity="1.000000" stroke="none" 
	d="
M336.945831,469.459229 
	C336.771423,469.020752 336.833008,468.820343 336.933289,468.307861 
	C337.400543,467.983948 337.829071,467.972107 338.576935,467.949402 
	C339.201782,468.510742 339.507324,469.082916 339.719360,469.995361 
	C338.811157,470.122833 337.996490,469.910034 336.945831,469.459229 
z"/>
<path fill="#08748E" opacity="1.000000" stroke="none" 
	d="
M340.789307,471.217468 
	C340.527435,471.268677 340.201050,471.020386 339.849915,470.461060 
	C340.125031,470.406036 340.424927,470.662018 340.789307,471.217468 
z"/>
<path fill="#08748E" opacity="1.000000" stroke="none" 
	d="
M410.886475,488.049316 
	C411.000000,488.000000 410.756165,488.401733 410.756165,488.401733 
	C410.756165,488.401733 410.772919,488.098633 410.886475,488.049316 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M406.740295,503.990112 
	C406.738098,504.134216 406.465271,504.266266 406.072815,504.269775 
	C406.125366,504.086853 406.297546,504.032471 406.740295,503.990112 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M478.566345,371.023071 
	C478.944153,371.447479 479.054260,371.960175 478.970642,372.439087 
	C477.131348,382.972534 482.381653,391.640686 486.675171,400.826477 
	C487.199615,409.465546 491.266815,416.926636 493.305908,424.912964 
	C495.160278,432.175903 498.483093,439.166382 501.608795,445.880615 
	C505.446259,454.123596 506.906067,462.709076 509.259613,471.192230 
	C509.719879,472.851074 510.374390,474.395142 508.269623,475.823242 
	C504.942108,470.573242 502.642853,464.982574 502.935516,458.569794 
	C503.087769,455.233032 500.168732,452.898712 499.371063,450.036224 
	C496.472870,439.635742 491.837921,429.880005 488.750732,419.540161 
	C485.276489,407.904053 480.816284,396.553253 476.551117,385.165924 
	C474.176392,378.825745 474.431580,374.806946 478.566345,371.023071 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M504.633942,485.738373 
	C505.403168,485.956421 505.863586,485.973572 506.669342,486.007294 
	C507.001740,486.201477 506.988800,486.379028 506.935608,486.821503 
	C504.598816,488.364807 502.128448,488.960968 499.077148,488.922546 
	C499.210419,487.044281 501.065277,487.043091 502.669708,486.102112 
	C503.367798,485.626404 503.818115,485.489075 504.633942,485.738373 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M507.613098,481.036041 
	C507.979095,482.125732 507.550018,483.053528 506.251648,483.820801 
	C506.047699,482.842834 505.913605,481.626404 507.613098,481.036041 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M504.960022,485.615845 
	C504.505646,485.953156 504.068481,485.966980 503.302704,485.965118 
	C503.095337,485.071320 503.400543,484.331726 504.682739,484.044189 
	C504.988373,484.427338 504.982819,484.859833 504.960022,485.615845 
z"/>
<path fill="#616467" opacity="1.000000" stroke="none" 
	d="
M497.790649,488.904694 
	C497.485199,489.740845 496.580536,489.994507 495.191284,489.925415 
	C495.691864,489.561401 496.560120,489.232849 497.790649,488.904694 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M474.748474,493.712463 
	C471.449951,496.993042 466.717010,496.223785 462.313782,497.710144 
	C465.471588,494.321594 470.032166,494.042633 474.748474,493.712463 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M454.740784,498.675598 
	C453.553894,500.487610 451.437958,500.137421 449.309448,500.726593 
	C450.217834,498.486908 452.197418,498.067810 454.740784,498.675598 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M248.249954,488.992889 
	C247.750320,488.511017 247.495758,488.037018 247.132050,487.317230 
	C247.509537,486.164673 247.996185,485.257874 248.731873,484.167633 
	C252.677963,480.699341 256.511261,477.684540 262.203827,477.487366 
	C275.799377,472.835541 289.461273,473.271667 303.526184,473.580383 
	C304.213257,473.633789 304.483093,473.666138 305.140503,473.797546 
	C308.177216,475.734772 311.322205,476.234955 314.303894,477.197906 
	C320.586517,479.226959 325.763428,483.055054 330.911804,487.580017 
	C331.470459,487.820831 331.686157,487.932861 332.193665,488.260498 
	C340.015900,496.405304 346.613464,504.922089 350.019318,515.544006 
	C350.627350,517.440308 351.966644,518.977539 354.408417,519.603638 
	C354.948669,519.739136 355.158539,519.807617 355.662659,520.016479 
	C356.856384,520.625183 357.526855,521.530579 358.954407,521.599731 
	C359.658966,521.683411 360.059113,521.746826 360.757050,521.861267 
	C362.109039,522.213074 363.010620,521.827209 364.171082,521.158447 
	C365.712738,520.570740 367.016022,520.510132 368.645081,520.500488 
	C369.818878,520.488708 370.573120,520.186646 371.541565,519.564270 
	C372.202484,519.280518 372.599030,519.182190 373.307220,519.108643 
	C374.372406,519.058533 375.162506,519.301819 376.156219,518.722961 
	C376.614258,518.482788 376.806213,518.395630 377.306885,518.213745 
	C378.054352,518.030945 378.497528,517.979187 379.295105,517.955811 
	C381.565887,518.009216 383.621033,517.276062 385.398682,519.295898 
	C385.518616,521.670654 384.142365,522.890442 382.531616,523.025208 
	C377.294769,523.463318 372.509857,525.705200 366.980408,526.511414 
	C361.322968,527.616699 356.174469,528.189697 350.993652,525.778442 
	C347.216949,524.020630 345.066223,521.211914 343.658691,517.480530 
	C337.284393,500.581848 325.660919,488.585907 308.973389,481.919830 
	C301.020721,478.743042 292.416046,478.903961 283.555603,480.054657 
	C282.666565,480.328400 282.142059,480.404694 281.211121,480.433136 
	C271.483612,479.267883 263.537445,484.520111 254.392990,486.030884 
	C253.145905,486.182404 252.295425,486.349823 251.222595,486.759857 
	C250.457611,487.526154 249.931854,488.066986 249.187500,488.788910 
	C248.985214,488.986542 248.495026,489.000763 248.249954,488.992889 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M501.745850,586.746521 
	C501.042816,591.965088 506.400330,594.011780 507.167084,598.229553 
	C507.423370,599.639343 508.309906,600.934570 508.950378,602.643555 
	C508.412628,603.591248 507.704224,603.832764 506.515930,603.690247 
	C505.190918,603.102661 504.654022,602.244080 503.988495,601.032043 
	C501.465302,596.869446 499.519928,592.632324 494.362427,591.035034 
	C493.621735,590.709106 493.219971,590.482788 492.553162,590.032227 
	C489.018066,587.568970 486.530609,584.157166 482.071106,583.091675 
	C481.355072,582.750793 480.987457,582.490295 480.429047,581.934326 
	C479.180054,579.763428 478.180267,577.815796 475.421204,577.244019 
	C466.787842,573.339661 460.138763,567.046692 452.630737,562.038818 
	C450.155426,560.387695 447.910645,558.436707 444.674774,557.408203 
	C436.379211,550.957886 427.405518,545.788086 421.257507,537.321533 
	C418.761993,533.885010 417.418274,529.951721 416.283752,526.002563 
	C414.516785,519.851990 411.497894,514.106567 410.688721,507.419159 
	C414.450684,506.505035 414.680573,509.326111 415.746735,511.551971 
	C418.321045,516.926208 418.676056,522.933838 421.058319,528.415100 
	C424.607178,536.580627 429.989899,542.676208 438.156281,546.088745 
	C448.440125,550.385986 455.813629,558.792786 464.925781,564.744873 
	C467.326752,566.313293 469.474670,567.266113 472.941528,567.131592 
	C474.463989,568.295166 474.721649,569.759399 476.071075,570.304077 
	C482.168121,572.765137 486.590790,577.910400 492.483063,580.622681 
	C495.824402,582.160767 498.082275,585.202148 501.745850,586.746521 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M262.309692,478.719299 
	C257.340973,479.264679 253.615234,481.835541 249.337097,483.951965 
	C251.149506,479.775085 254.690399,477.292725 259.481384,475.883484 
	C263.875366,473.648376 268.314270,472.432129 272.773193,470.347412 
	C273.498444,470.110382 273.929932,470.086456 274.685547,470.060059 
	C277.508850,469.486938 280.041809,469.078064 282.790955,468.284485 
	C283.781830,468.047974 284.518341,468.013214 285.623535,467.974304 
	C297.326691,466.700104 308.106323,468.838196 318.435791,473.430756 
	C330.721405,478.893066 340.844513,486.973358 347.828522,498.610413 
	C349.952911,502.150208 351.942230,505.759857 353.568573,509.622772 
	C355.170441,513.427429 356.869629,517.924438 362.809143,517.546753 
	C363.843170,517.374573 364.528442,517.326721 365.557434,517.300659 
	C368.591095,518.448853 371.646912,515.538086 374.341003,518.417542 
	C374.454956,519.003540 374.436829,519.247437 374.213654,519.795532 
	C373.579071,520.096191 373.149536,520.092651 372.397766,520.087891 
	C371.472809,520.535034 370.942749,521.065796 370.183014,521.760925 
	C368.281586,521.981873 366.589294,522.015198 364.474060,522.054077 
	C363.391144,522.720337 362.580780,522.991394 361.332336,522.983154 
	C360.558990,522.985840 360.115295,522.986816 359.338837,522.984497 
	C358.350464,522.122009 357.227631,522.047119 356.197449,521.234802 
	C355.973816,521.028320 355.486176,521.016541 355.242493,521.007080 
	C350.457886,518.370605 349.008545,513.592712 347.126740,509.243683 
	C343.772064,501.490753 339.178314,494.752808 332.253021,489.251678 
	C331.989349,489.009521 331.502167,488.994843 331.258636,488.985657 
	C324.857361,480.858917 316.019928,477.600861 306.264282,475.255188 
	C305.976776,475.023773 305.490601,475.012878 305.247681,475.002167 
	C298.081696,472.626862 290.937164,473.629578 283.865326,473.640625 
	C276.438507,473.652222 269.575439,476.254944 262.309692,478.719299 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M362.902527,517.217957 
	C362.455261,521.705811 359.694855,519.153564 358.389893,518.430603 
	C354.619263,516.341797 352.656830,512.593689 351.156525,508.688477 
	C346.850220,497.479065 339.494781,488.646271 329.908203,481.639069 
	C320.532349,474.785919 309.885925,470.955444 298.442780,469.378296 
	C294.530518,468.839050 290.548676,468.804749 286.295410,468.255005 
	C289.210083,466.469635 292.618774,465.970642 296.561951,466.039978 
	C309.214508,465.282227 319.993286,469.330231 330.265594,475.594147 
	C342.944244,483.325470 351.619598,494.102905 356.509460,508.067780 
	C357.432007,510.702576 359.448669,512.824768 360.748413,515.718994 
	C361.551758,516.337280 362.099457,516.675354 362.902527,517.217957 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M400.705994,510.303955 
	C401.473755,510.065216 401.907349,510.058411 402.666077,510.060669 
	C409.602753,513.008972 412.939606,518.725647 415.185150,525.097107 
	C419.061859,536.096741 424.873840,545.453491 435.556549,551.037598 
	C438.719971,552.691223 440.201385,556.275452 443.744812,558.001404 
	C446.698761,560.778137 448.486267,564.510620 453.177124,564.870605 
	C454.743103,564.875671 455.874573,565.158630 457.090698,566.196716 
	C457.602264,566.837524 457.824799,567.246765 458.120728,567.998779 
	C458.718262,571.013611 463.021942,571.238281 462.153229,574.965027 
	C459.350952,576.804871 457.115784,575.511963 454.982819,574.215942 
	C450.985535,571.787048 447.409912,568.809937 443.975586,565.627625 
	C436.999756,559.163574 428.499176,554.692932 420.978882,548.352417 
	C420.429596,548.096924 420.219818,547.974121 419.729309,547.619263 
	C415.953278,542.589722 414.519897,536.632263 410.572784,531.677734 
	C410.262085,531.166992 410.155762,530.952637 409.934021,530.399780 
	C409.602448,529.148438 409.602661,528.214050 409.534424,526.953857 
	C409.621277,525.082153 409.465759,523.640991 407.722229,522.539551 
	C407.276917,522.234070 407.099365,522.108459 406.681000,521.759033 
	C406.001862,521.036865 405.676361,520.469360 405.306610,519.559326 
	C405.016144,517.975586 404.680908,516.808838 402.855377,516.467529 
	C402.125153,516.355225 401.708801,516.273621 400.981415,516.096375 
	C398.689697,515.107666 396.862122,515.427856 394.811462,516.818481 
	C393.458282,517.210327 392.463898,517.152283 391.157074,516.525879 
	C390.674652,516.077087 390.527924,515.850281 390.285583,515.227478 
	C390.389618,511.738922 392.861938,511.493561 395.065948,511.070892 
	C396.806122,510.737122 398.658539,511.307159 400.705994,510.303955 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M361.003906,515.607544 
	C356.614258,513.848145 355.251831,509.462738 353.875397,505.430237 
	C352.293488,500.795929 349.899109,496.781738 346.983765,492.966339 
	C339.532867,483.215149 330.167603,476.417450 318.783478,471.393219 
	C311.745850,468.287262 304.695282,467.935303 297.292938,466.302704 
	C298.298065,464.581116 300.060242,464.956604 301.661194,465.024780 
	C317.901306,465.716858 332.888885,470.337311 343.455872,483.093384 
	C349.512421,490.404572 356.732300,497.625397 358.192780,507.947876 
	C358.561188,510.551544 361.450897,512.250488 361.003906,515.607544 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M444.349426,559.167236 
	C443.177307,558.891541 441.995514,559.064331 441.611542,558.590515 
	C435.677979,551.268982 425.569824,548.573120 420.778198,540.164856 
	C417.479065,534.375549 414.466736,528.317993 412.239929,522.055359 
	C410.437592,516.986511 406.035675,514.570251 403.038147,510.405518 
	C404.811890,507.664978 407.831818,508.250519 410.546570,507.145660 
	C416.270813,516.645325 416.660706,528.237122 423.185883,537.254150 
	C427.060211,542.607971 432.085480,546.921875 437.906769,550.001770 
	C440.701508,551.480347 441.056427,555.201294 444.653320,555.964844 
	C445.554779,556.627258 445.797455,557.336182 445.514832,558.516968 
	C445.140472,559.020752 444.925690,559.167908 444.349426,559.167236 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M400.591766,510.033264 
	C400.432739,512.607361 398.359100,511.902435 396.694000,511.988190 
	C394.580780,512.096985 392.434998,512.021362 391.077148,514.555176 
	C390.259430,515.708618 389.400818,516.274536 387.921631,516.428772 
	C384.078857,516.499634 380.793884,517.404541 377.280273,518.763184 
	C377.008148,519.007324 377.023041,519.032776 377.028473,519.046570 
	C376.322845,519.600952 375.548523,519.782715 374.343872,519.238220 
	C371.728851,516.139648 368.426849,520.872314 366.033203,517.630737 
	C377.316040,514.883545 388.271667,510.298645 400.591766,510.033264 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M470.963196,562.433716 
	C478.866913,567.525818 486.780670,572.962219 494.823761,578.712341 
	C493.109192,580.809448 491.042023,580.255676 489.562897,578.880676 
	C485.370575,574.983337 480.091217,572.890686 475.389099,569.855347 
	C474.522125,569.295715 473.919952,568.522095 473.250671,567.301880 
	C473.053192,566.977112 473.049377,566.943115 473.057953,566.925415 
	C472.368774,565.531250 471.671021,564.154663 470.963196,562.433716 
z"/>
<path fill="#187589" opacity="1.000000" stroke="none" 
	d="
M427.509186,527.094971 
	C430.010681,532.485413 434.841278,535.496460 438.949280,539.788025 
	C438.818634,540.395325 438.643127,540.422546 438.201233,540.453491 
	C436.766205,539.762512 435.597534,539.067810 434.125946,538.219482 
	C433.719635,537.879761 433.616241,537.693726 433.212952,537.398315 
	C429.872498,534.828491 427.411896,531.974548 426.595886,527.633179 
	C426.787872,527.222107 426.992035,527.174316 427.509186,527.094971 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M272.676483,470.098755 
	C269.835602,474.016174 265.038177,474.540710 260.243866,475.884583 
	C263.054932,471.897614 267.848450,471.363770 272.676483,470.098755 
z"/>
<path fill="#187589" opacity="1.000000" stroke="none" 
	d="
M439.029419,539.968811 
	C443.465576,540.110229 446.040649,543.521667 449.623047,545.775330 
	C450.029877,546.188416 450.196564,546.380066 450.189331,546.911865 
	C448.652466,548.059509 447.587219,547.487854 446.340515,546.243958 
	C446.072754,545.971436 446.049225,545.936829 446.028656,545.932861 
	C445.209808,545.328918 444.189789,545.104858 443.240967,544.196716 
	C441.823303,543.100464 440.608398,542.223694 439.104431,541.200867 
	C438.815338,541.054810 438.727264,540.688599 438.719147,540.500427 
	C438.711029,540.312256 438.923370,540.083374 439.029419,539.968811 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M282.633148,468.043396 
	C281.309998,471.274048 278.153687,470.111603 275.263123,470.298767 
	C276.926300,467.662323 279.627167,468.021179 282.633148,468.043396 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M463.078339,557.319153 
	C466.119751,557.535583 468.261505,559.730652 470.808350,561.724792 
	C467.796875,561.507324 464.819458,560.724182 463.078339,557.319153 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M446.096619,546.312134 
	C447.050354,546.336182 448.027985,546.700867 449.319366,547.117188 
	C450.845673,545.992065 451.052216,547.099792 451.229126,548.368164 
	C448.422150,550.406006 447.233795,548.616150 446.096619,546.312134 
z"/>
<path fill="#31BCC9" opacity="1.000000" stroke="none" 
	d="
M451.415833,548.904053 
	C450.765106,548.352539 450.449890,547.905884 449.883911,547.313965 
	C449.686584,546.976379 449.739990,546.783997 449.828308,546.294189 
	C452.562683,546.114319 454.483063,547.433899 455.617371,550.180481 
	C454.255676,550.045410 453.003479,549.527222 451.415833,548.904053 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M443.093201,544.314209 
	C444.155853,544.016296 444.991821,544.591064 445.860596,545.656372 
	C444.837280,545.989929 443.677734,546.025757 443.093201,544.314209 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M457.102051,553.291809 
	C458.270447,552.832336 458.998962,553.632690 459.890961,554.666992 
	C458.846191,554.994507 457.670837,555.024475 457.102051,553.291809 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M460.101288,555.302856 
	C461.213623,554.932129 461.997894,555.637817 462.890991,556.674438 
	C461.845154,557.004089 460.689972,556.998291 460.101288,555.302856 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M421.020447,515.303589 
	C421.975372,515.477966 422.083832,516.361389 422.056274,517.640686 
	C421.232758,517.406616 421.043854,516.557495 421.020447,515.303589 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M456.086670,552.342896 
	C456.272736,552.246826 456.573975,552.358948 456.960266,552.708191 
	C456.707947,552.934204 456.426788,552.802795 456.086670,552.342896 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M438.509949,540.310791 
	C438.711029,540.312256 438.822754,540.675110 438.819031,540.864990 
	C438.574036,540.998047 438.360291,540.887878 438.054504,540.590759 
	C437.934875,540.457214 438.308868,540.309387 438.509949,540.310791 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M420.127441,513.127197 
	C420.411102,513.009155 420.653259,513.225525 420.795715,513.798401 
	C420.522339,513.874756 420.319000,513.643616 420.127441,513.127197 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M424.058716,522.221741 
	C424.344391,522.078186 424.579529,522.268311 424.696808,522.818115 
	C424.424286,522.905212 424.234039,522.697754 424.058716,522.221741 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M433.027466,537.458618 
	C433.229340,537.281616 433.483002,537.403992 433.748535,537.860962 
	C433.553467,537.986145 433.326416,537.840271 433.027466,537.458618 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M244.327942,508.245056 
	C244.018478,506.624817 244.002151,505.237061 243.993652,503.432983 
	C242.754074,500.291595 245.045380,499.969604 246.613464,499.397186 
	C262.388550,493.638428 278.383545,492.660370 294.655304,497.191498 
	C297.933624,498.104431 300.725494,499.615570 303.370087,501.767822 
	C308.010193,505.544037 312.593719,509.379639 317.109314,513.301819 
	C320.303802,516.076599 322.497375,519.266968 323.835480,523.471558 
	C326.899994,533.100708 336.929901,539.516663 346.968842,539.760559 
	C353.017822,539.907410 358.626648,538.735413 364.481079,536.383789 
	C370.253784,535.809570 375.243195,532.905945 380.850311,533.385803 
	C388.563477,534.045837 395.195007,535.794250 399.231415,543.755066 
	C404.697937,554.536438 411.516754,564.446289 421.858612,571.498413 
	C424.940308,573.599854 426.480774,577.508789 429.143555,580.729492 
	C433.539734,584.119263 435.275238,589.757019 440.892273,591.803467 
	C441.450897,592.004395 441.667267,592.105469 442.178986,592.411133 
	C446.439880,597.515076 451.453796,601.318237 456.011047,605.600586 
	C460.215149,609.551086 465.587921,612.534729 467.112823,618.774475 
	C467.320282,619.623413 468.238251,620.007935 468.998199,620.258850 
	C474.157745,621.962524 477.407471,626.649414 482.351776,628.724060 
	C484.299347,629.541321 485.079529,631.499207 485.569916,633.507141 
	C486.282654,636.425598 487.402710,639.170593 489.527954,641.804443 
	C490.146881,642.373596 490.451233,642.745667 490.895477,643.463867 
	C491.933655,647.539856 494.113678,650.700989 495.878235,654.436646 
	C496.266388,655.656006 496.370239,656.574585 496.533875,657.822571 
	C496.518433,659.108459 497.047394,659.827637 497.702393,660.864380 
	C497.955566,661.381287 498.042603,661.596619 498.216858,662.151794 
	C498.399048,666.024353 498.723572,669.515320 500.381409,673.105103 
	C500.694855,673.631042 500.804413,673.852966 501.036499,674.428894 
	C501.274811,675.274841 501.346222,675.773132 501.391724,676.659485 
	C501.424805,677.555115 501.402588,678.067322 501.270142,678.959900 
	C500.937897,680.103638 500.509125,680.729248 499.392975,681.055115 
	C496.715363,678.271179 495.707336,674.822693 495.077118,671.572510 
	C493.556122,663.728333 490.630127,656.346375 487.963043,648.936523 
	C484.284821,638.717590 477.163727,630.955811 467.675171,625.556091 
	C466.619568,624.955383 465.102264,625.166016 463.399231,625.000244 
	C461.995148,620.987671 462.315643,616.081055 456.474030,615.334534 
	C453.219025,608.046265 445.826477,604.807922 440.430664,599.748657 
	C430.415283,590.357910 422.756226,578.758545 412.237457,570.097900 
	C405.097443,564.219116 400.537689,557.028687 395.961273,549.349304 
	C391.667053,542.143555 385.552094,536.974854 375.989868,538.927551 
	C374.083557,539.316895 372.477783,539.710022 371.180603,541.676392 
	C369.894989,541.698669 368.791199,541.392456 367.344299,541.045776 
	C365.867035,541.064087 365.175079,541.853394 364.215820,542.778748 
	C362.933441,542.999878 361.868286,543.002197 360.403748,543.004700 
	C359.128723,544.211121 357.531860,543.760010 356.234863,544.769897 
	C355.554047,544.998352 355.110016,544.999207 354.333008,545.000000 
	C351.595032,545.782043 348.780548,544.992859 346.373047,547.118286 
	C343.187225,548.930359 340.612335,546.794128 337.896301,546.673828 
	C327.018097,546.192139 320.483673,539.593567 315.616119,530.907654 
	C310.929901,522.545227 304.336426,516.141602 295.987061,511.861511 
	C288.921906,508.239655 281.168030,506.102814 273.469940,504.063171 
	C271.670380,503.586365 269.864258,504.363861 268.088501,504.801544 
	C263.402679,505.956512 258.714203,507.159882 253.822220,506.166168 
	C252.229935,505.842712 250.918121,506.371399 249.652817,507.315399 
	C248.170761,508.421173 246.604736,509.426147 244.327942,508.245056 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M707.816528,803.295898 
	C712.342590,802.706055 715.343872,799.323242 719.635742,798.040771 
	C719.956482,799.751648 718.784546,800.621826 717.404236,801.351990 
	C707.767944,806.448975 698.852478,813.588318 688.148315,815.760498 
	C679.556274,817.504089 672.245789,822.266052 663.424927,823.663025 
	C657.244751,824.641663 650.674561,827.732422 644.672668,830.844482 
	C643.670044,831.364258 642.455322,832.059509 641.454529,831.907898 
	C631.214294,830.357056 622.048401,835.009583 612.614014,837.321045 
	C600.117615,840.382629 587.536194,843.214233 575.151550,846.761963 
	C571.929504,847.684937 568.745911,846.734619 565.834351,845.871948 
	C559.396851,843.964661 552.745178,842.450806 547.659668,837.308105 
	C544.667664,834.282471 543.053162,830.173462 542.528625,826.825806 
	C541.253540,818.689026 537.271423,811.296265 536.890320,802.952820 
	C536.695984,798.698792 534.950500,794.582458 531.841553,791.300659 
	C531.177917,790.600159 530.255432,789.661194 530.283264,788.861084 
	C530.785583,774.435791 524.400574,761.653564 520.509705,748.320068 
	C517.165894,736.861084 513.867188,725.404541 511.037964,713.411194 
	C511.815399,712.047913 512.895996,711.948364 514.446899,712.347900 
	C516.645020,713.998230 516.822021,716.249756 517.355469,718.269104 
	C519.248047,725.432922 521.639709,732.445435 523.974426,739.806885 
	C525.195496,740.961182 525.866150,742.049744 526.663818,743.502197 
	C527.180603,744.771973 527.256653,745.740967 527.188599,747.069580 
	C526.493469,749.391541 526.790710,751.215637 528.230530,753.158813 
	C528.530945,753.628601 528.634338,753.828552 528.842285,754.352295 
	C528.366760,758.401489 529.515137,761.748596 531.397461,765.268250 
	C531.697449,765.769775 531.801575,765.981079 532.021484,766.528198 
	C532.246094,767.324707 532.317566,767.790894 532.369751,768.613525 
	C532.399292,769.426392 532.395081,769.888489 532.355103,770.689331 
	C531.882446,774.392517 534.384644,776.657349 535.640503,779.746826 
	C536.002441,780.466553 536.150574,780.903442 536.317688,781.697021 
	C536.676208,784.049316 535.913818,786.231079 537.535583,788.356262 
	C537.815491,788.874817 537.912537,789.090637 538.116333,789.645447 
	C539.008301,792.319641 537.415771,795.289124 540.348938,797.392212 
	C540.813477,798.089722 541.008911,798.526184 541.242065,799.330017 
	C541.480408,801.967651 541.161987,804.428894 543.829224,806.156616 
	C545.430664,807.903015 546.241333,809.637756 546.447021,812.001343 
	C546.450806,813.708801 546.380371,815.077942 547.504639,816.514587 
	C547.748535,817.032471 547.827515,817.248657 547.975952,817.803833 
	C548.582275,825.788025 552.382996,831.638306 558.416931,836.569092 
	C558.932861,836.809204 559.135681,836.912842 559.627197,837.197510 
	C562.624329,838.856445 565.255493,840.603455 568.933350,839.758545 
	C570.681091,839.190308 572.093079,838.730652 573.976440,839.164551 
	C577.876099,840.732605 581.091736,839.085327 584.341248,838.152405 
	C588.787170,836.875732 593.436707,836.854919 598.277466,835.687500 
	C604.028931,834.426880 609.618652,833.895508 614.629944,830.352234 
	C621.508545,828.392212 628.411804,828.481567 634.878784,826.315796 
	C644.948914,822.943359 656.076599,823.152588 665.697998,817.036133 
	C666.191101,816.622681 666.400085,816.471985 666.955322,816.152527 
	C672.080505,815.785278 676.512756,813.781616 681.248291,813.311462 
	C682.726990,813.164612 684.101868,812.512085 685.222656,810.875000 
	C686.133423,810.212646 686.838440,810.006531 687.932373,809.869141 
	C692.867615,810.374268 696.033752,806.414734 700.663696,806.001831 
	C703.433105,805.797302 705.787842,805.409119 707.816528,803.295898 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M515.488953,712.385437 
	C513.919983,713.009644 512.849731,713.009583 511.377350,713.018677 
	C510.652069,712.554321 510.328857,712.080872 509.962769,711.314148 
	C507.964813,702.551575 502.298096,695.335510 501.584229,686.070312 
	C501.626892,683.565674 501.359161,681.485657 500.247955,679.249634 
	C500.013580,678.548523 500.012512,678.108704 500.010620,677.338989 
	C500.523071,676.578430 500.667542,676.050537 500.214081,675.210693 
	C500.000000,675.000000 499.995758,674.502441 499.993591,674.253662 
	C498.710815,671.493286 498.097595,668.829041 498.320557,665.626953 
	C500.931702,663.434814 502.032013,665.305725 502.942017,667.048157 
	C503.917206,668.915466 504.164276,671.105652 505.519226,673.274536 
	C505.856323,673.864014 505.969025,674.112061 506.186462,674.753906 
	C506.853973,681.051514 508.489075,686.515503 510.752991,692.111450 
	C513.250488,698.284912 516.736938,704.573242 515.488953,712.385437 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M502.736328,684.729858 
	C505.485229,691.180664 503.935333,698.830322 510.322540,703.676025 
	C512.356689,705.219360 510.126495,707.998779 509.956970,710.621338 
	C508.928192,710.938538 507.600708,711.025452 507.340729,709.920837 
	C505.241302,701.000244 499.163361,693.352295 499.006256,683.408752 
	C500.920166,681.678833 501.628815,683.245728 502.736328,684.729858 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M502.988159,684.623352 
	C501.872742,684.377686 500.761353,683.747009 499.328003,683.055908 
	C499.006104,682.551819 499.006134,682.108154 499.007538,681.331543 
	C499.321320,680.524231 499.633759,680.049744 499.980408,679.281799 
	C502.858734,679.688171 502.885284,681.986023 502.988159,684.623352 
z"/>
<path fill="#BAB9BB" opacity="1.000000" stroke="none" 
	d="
M460.348175,626.991211 
	C460.135834,625.604004 460.965210,625.014343 462.652954,625.008789 
	C462.863739,626.394226 462.036469,626.986450 460.348175,626.991211 
z"/>
<path fill="#171D2F" opacity="1.000000" stroke="none" 
	d="
M720.228760,704.713196 
	C721.723938,704.894470 722.988037,705.177795 723.771118,704.031433 
	C726.456726,700.099670 730.216553,696.449585 731.443542,692.093994 
	C732.812439,687.234680 736.147583,683.469299 737.253906,678.594055 
	C737.863159,675.909241 740.208374,677.077209 741.730896,677.933838 
	C753.362976,684.478760 764.415955,691.901428 773.622742,701.626587 
	C776.347290,704.504517 775.167725,709.464600 777.726318,712.957336 
	C779.294067,715.097473 780.507385,717.732727 779.156006,720.803467 
	C778.551208,722.177856 779.072388,723.834045 781.576355,723.977539 
	C782.123840,724.152039 782.288757,724.306458 782.417114,724.797729 
	C782.197144,725.461670 782.013794,725.788574 781.739136,726.381897 
	C778.288330,729.303284 777.256042,733.499817 774.942322,736.856812 
	C771.952759,741.194397 768.910034,745.461365 765.003174,749.431824 
	C760.929871,749.375916 761.520996,746.481384 761.991516,744.444885 
	C762.999512,740.081604 762.426270,736.249878 759.782532,732.638428 
	C758.426575,730.786255 758.451355,728.547119 758.678467,726.381958 
	C759.484924,718.693787 755.330383,714.838135 747.495605,713.026855 
	C742.631409,711.902344 739.310120,710.378235 737.415466,705.590759 
	C736.331177,702.850952 733.456543,702.062500 731.267212,704.240906 
	C725.753235,709.727539 718.250610,713.039612 713.856567,720.287598 
	C712.618530,721.559753 711.426941,722.100769 709.645020,722.242188 
	C708.424744,722.133545 707.701599,721.824341 706.882751,720.939087 
	C706.422363,719.567444 706.637512,718.509399 707.289307,717.776978 
	C711.332764,713.233582 714.747009,708.082153 720.228760,704.713196 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M938.976379,765.394897 
	C939.470093,765.037109 939.905823,765.020813 940.668335,764.988098 
	C942.054077,764.976501 943.113037,764.981323 944.567505,764.971558 
	C948.694580,764.302185 952.426086,763.647339 956.578857,762.997437 
	C953.600708,767.082825 948.315430,767.178406 943.869507,768.771118 
	C938.750671,770.604980 934.191101,773.538086 929.128296,775.293945 
	C922.893738,777.456238 917.047668,781.384521 909.915344,780.052795 
	C906.827271,779.476257 903.988770,781.587769 900.610901,780.428040 
	C895.798096,778.775757 891.364868,776.708130 887.999023,772.926514 
	C883.795898,768.204346 877.225037,767.311646 872.755127,763.097168 
	C872.537537,762.892151 872.053284,762.975891 871.825195,762.775208 
	C860.863586,753.132568 845.910339,749.767029 835.158813,739.249146 
	C836.702698,737.279480 838.484070,738.072144 840.012512,739.043274 
	C850.105957,745.456848 861.599365,749.476746 870.625000,757.810791 
	C874.508118,761.396362 879.809204,763.413025 884.328430,766.354004 
	C887.615234,768.493042 891.455994,769.984131 893.519958,773.731689 
	C893.956055,774.523499 895.089661,775.608643 895.731079,775.504700 
	C901.897156,774.504883 907.389832,777.801819 913.376099,778.113098 
	C916.684387,778.285034 918.390564,776.087219 920.874146,775.089355 
	C924.557800,773.609253 928.397766,772.520264 932.095032,771.070740 
	C934.855164,769.988586 937.630981,768.772339 938.976379,765.394897 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M782.687622,725.014160 
	C782.300171,724.948975 782.219727,724.763184 782.049072,724.287598 
	C782.325195,723.573730 782.691528,723.149780 783.320374,722.628662 
	C783.991089,723.469299 783.757263,724.247253 782.687622,725.014160 
z"/>
<path fill="#1A2537" opacity="1.000000" stroke="none" 
	d="
M712.901978,720.340698 
	C714.422485,716.307678 716.578064,713.085205 719.981812,711.079590 
	C723.586731,708.955444 726.843750,706.507202 729.442383,703.245239 
	C730.824585,701.510193 732.356873,700.652954 734.617920,701.342651 
	C736.817810,702.013550 739.011658,702.273743 739.374573,705.408813 
	C739.846375,709.483643 742.429016,710.680054 746.601746,711.203674 
	C754.067627,712.140442 762.449036,716.938904 760.154663,727.319153 
	C759.945312,728.266479 759.689575,729.699524 760.182983,730.229309 
	C764.901794,735.296021 764.875244,741.071655 763.166931,747.229370 
	C763.038696,747.691650 763.701111,748.373291 764.341187,749.247070 
	C764.040222,751.983582 762.664368,754.026306 761.041626,756.295898 
	C759.504761,757.190002 758.443237,758.220703 757.451111,759.728394 
	C754.996033,760.998718 753.145569,762.670715 751.214111,764.762939 
	C747.135254,768.712219 743.686279,773.032104 737.545288,773.885620 
	C736.946350,773.725891 736.718628,773.621521 736.241028,773.236328 
	C738.064575,768.436646 743.245972,767.573486 746.275513,764.201599 
	C747.386475,762.965149 750.200195,762.887024 749.410828,760.366760 
	C748.786987,758.375061 746.750977,758.191956 744.940063,757.765625 
	C742.376709,757.162231 739.989929,755.862854 737.245361,755.955200 
	C735.006287,756.030579 734.118896,754.406616 733.386475,752.660339 
	C732.701477,751.027222 732.676941,749.001892 730.346802,748.071411 
	C728.308716,746.259216 729.179871,744.286133 729.527344,742.386353 
	C730.624756,736.387268 728.464355,731.496582 722.874512,729.126404 
	C718.550537,727.293030 715.669067,724.093689 712.901978,720.340698 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M689.363831,810.930786 
	C688.255737,811.015991 687.511475,811.019104 686.395142,811.023315 
	C680.498352,814.919739 673.638672,814.330078 667.298157,816.749512 
	C666.993713,816.991760 667.000000,817.000000 666.996094,816.996338 
	C659.661743,819.539185 652.308838,821.918945 644.687073,823.576172 
	C635.259033,825.626160 625.760986,827.296997 616.327698,829.300354 
	C614.813049,829.622070 613.551453,830.382690 611.998535,831.466125 
	C610.982178,832.022278 610.246948,832.225159 609.130310,832.421509 
	C607.522339,832.395691 606.432068,832.781006 605.158081,833.754333 
	C604.383423,834.153381 603.906982,834.303284 603.031677,834.458374 
	C594.418762,836.123047 586.384399,838.619873 577.704468,839.947632 
	C571.351929,838.834473 565.305481,838.165466 559.097229,836.280151 
	C556.309082,835.216858 554.692932,833.488770 553.708252,830.681519 
	C550.803101,823.227966 548.696533,815.916992 546.131775,808.766846 
	C545.557190,807.164978 544.673645,805.773438 546.057373,803.888550 
	C550.834839,803.582703 550.727966,808.247070 553.508850,810.404907 
	C555.095093,811.287964 556.287109,812.076538 556.050781,814.117676 
	C554.450806,816.002380 553.956970,817.661316 555.621643,819.787537 
	C556.962830,822.646545 556.855225,825.789978 559.489929,828.054321 
	C560.628174,829.544861 562.183350,829.651428 563.674255,830.609985 
	C567.856445,834.683289 572.807800,834.165955 577.562988,833.950989 
	C583.977783,833.661072 590.250916,832.339661 596.711121,830.270264 
	C597.451599,830.007751 597.893982,830.003235 598.668213,829.995972 
	C601.066711,829.663330 602.984070,829.003723 604.766235,827.257568 
	C614.412720,824.438904 624.457764,824.310730 633.283020,819.553772 
	C633.709045,819.324158 634.263855,819.041077 634.681885,819.132690 
	C641.703369,820.672241 648.412048,817.700500 654.317627,815.532593 
	C665.066040,811.586853 676.976135,811.904724 687.051575,805.826965 
	C689.617004,804.279419 692.988953,803.931335 696.593506,804.019958 
	C698.283081,806.460815 696.351685,807.288452 694.798218,807.961853 
	C692.983826,808.748413 691.426880,809.906616 689.363831,810.930786 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M589.356079,826.672791 
	C585.047424,832.147400 578.758606,830.965393 572.679626,830.682983 
	C571.388428,830.622986 570.111450,830.253784 568.399658,830.035156 
	C566.941345,830.994568 565.750122,831.405701 563.993774,830.989014 
	C563.184753,830.644775 562.725342,830.455872 561.994263,830.044434 
	C561.061584,828.621704 561.837524,826.999451 560.331055,825.752075 
	C559.797913,825.131714 559.520569,824.751160 559.090332,824.036682 
	C556.627869,818.150330 556.990051,811.597778 552.412231,806.492188 
	C550.511353,798.592712 546.186035,791.935303 544.261230,784.036133 
	C543.717102,783.047180 543.520752,782.316040 543.061401,781.291504 
	C542.265198,778.870667 543.067017,776.471191 541.335449,774.265747 
	C540.849304,773.222473 540.669373,772.480896 540.574097,771.325928 
	C538.469360,763.299500 537.949341,755.198303 533.736084,747.777344 
	C533.580750,746.870239 533.529175,746.346191 533.456299,745.427185 
	C531.460083,737.686157 527.990112,730.781372 526.370117,723.397461 
	C524.549255,715.098267 520.893433,707.556458 518.136658,699.650513 
	C517.297241,697.243347 517.153748,694.775085 517.998535,691.927429 
	C518.331116,691.362427 518.498230,691.159180 519.027344,690.803040 
	C520.242310,690.680420 520.798157,691.197510 521.500183,692.125488 
	C521.929260,692.877991 522.117981,693.331970 522.361450,694.147705 
	C522.682495,695.453186 523.359802,696.168396 523.984924,697.338684 
	C528.242310,710.971619 532.689941,724.143433 537.582520,737.171753 
	C540.079773,743.821533 541.061584,750.994568 543.638489,757.580811 
	C546.751587,765.537598 546.603638,774.329407 549.822510,781.983948 
	C553.151611,789.900513 554.245850,798.614502 558.917358,806.012329 
	C560.964478,809.254272 560.799011,813.427307 562.546570,816.821838 
	C564.677734,820.961426 566.063171,826.085876 572.649536,825.865112 
	C574.085999,825.570129 575.146790,825.594788 576.604492,825.743774 
	C580.849915,827.137634 584.784119,826.364868 589.356079,826.672791 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M719.237305,789.632751 
	C717.230225,790.306030 715.525696,790.683105 713.444763,791.082275 
	C712.459229,791.545349 711.932007,792.079102 711.182007,792.784790 
	C710.978882,792.978882 710.503418,793.007141 710.265869,793.022827 
	C705.483276,795.936646 700.235901,797.158203 695.269104,798.970520 
	C682.662048,803.570557 669.317322,805.639893 656.537354,809.606995 
	C639.939270,814.759277 622.678955,817.047485 606.028503,821.976440 
	C601.319458,823.370361 596.585754,824.986328 591.290894,825.923096 
	C589.225159,825.831726 587.570923,826.498352 585.557861,825.760925 
	C583.403625,820.985352 587.779236,822.179077 589.850220,820.695801 
	C590.613037,820.208008 591.076477,819.982605 591.915649,819.664185 
	C593.314026,819.363098 594.085632,818.651550 595.340942,818.023193 
	C609.325989,814.855652 622.788757,811.155457 636.389587,808.091003 
	C659.199158,802.951721 681.768738,796.972961 704.191711,790.385376 
	C709.265930,788.894653 714.020142,786.502686 719.551941,785.446777 
	C721.132751,787.001343 720.576233,788.194153 719.237305,789.632751 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M590.743347,825.202271 
	C603.054932,820.215637 615.898071,818.368164 628.342468,815.030884 
	C635.530457,813.103210 642.824341,811.742432 650.223328,810.744141 
	C653.281128,810.331543 655.997375,808.023254 659.295715,807.313904 
	C676.254272,803.666565 692.834778,798.632385 709.647583,793.070374 
	C708.062012,796.357422 704.398071,796.762390 701.274414,797.916199 
	C688.923584,802.478210 676.072266,805.347595 663.462341,808.933167 
	C650.141785,812.720825 636.608459,815.578857 623.278381,819.249084 
	C613.826599,821.851624 604.146423,823.639221 594.816711,826.772888 
	C593.604248,827.180054 592.183105,826.966003 590.439697,827.059265 
	C590.020752,827.078796 590.009888,827.039368 590.002502,827.020874 
	C589.541870,826.278748 589.972229,825.827209 590.743347,825.202271 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M562.731506,830.247803 
	C564.379639,830.000916 565.761902,830.012817 567.557861,830.033997 
	C571.628296,835.425171 576.937073,832.802917 581.527588,832.512024 
	C586.441162,832.200745 591.291016,830.884094 596.588928,830.009216 
	C596.812622,831.692566 595.962402,832.758728 594.206909,832.922363 
	C585.887634,833.698242 577.691833,835.890686 569.225403,835.011414 
	C566.698853,834.749023 564.259521,834.522766 563.051514,831.394775 
	C562.817017,830.854736 562.641357,830.680664 562.731506,830.247803 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M737.166138,773.144531 
	C742.965698,772.280884 745.993591,767.622437 750.667847,765.035400 
	C749.541748,769.754150 740.483459,776.457764 736.612183,775.336304 
	C736.342224,775.196167 736.153564,775.347778 735.806763,775.175171 
	C735.655029,774.032227 736.137512,773.563660 737.166138,773.144531 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M725.689941,781.216980 
	C726.698730,780.610901 727.439880,780.274170 728.477600,779.862671 
	C730.575623,778.486145 732.056946,776.574280 734.907837,776.965637 
	C734.976379,782.693604 729.675476,782.412231 726.357849,784.903870 
	C724.790405,785.981384 723.604492,787.071716 721.505493,786.582214 
	C719.868469,782.429871 723.953003,783.089478 725.689941,781.216980 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M689.221924,811.238220 
	C690.285950,807.241455 694.877075,807.594177 696.942993,804.368835 
	C697.457336,804.023560 697.898987,804.014771 698.672241,804.008606 
	C699.556396,804.509216 700.109009,805.007263 700.829224,805.730469 
	C697.621460,808.789185 693.738342,810.557800 689.221924,811.238220 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M735.443604,776.996948 
	C733.134094,777.736755 731.757690,779.740601 729.138977,779.821960 
	C728.874817,779.115601 728.975464,778.443298 729.077454,777.429077 
	C729.494446,775.897034 730.841553,776.114502 731.816772,775.213318 
	C732.044250,775.000549 731.991028,774.985840 731.987183,775.013184 
	C732.988403,774.309021 734.061462,773.970642 735.631775,774.734009 
	C735.984070,774.997559 736.260010,774.990417 736.395508,775.017456 
	C736.250671,775.624084 735.970337,776.203674 735.443604,776.996948 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M720.687195,785.793213 
	C722.390259,785.648621 723.846130,785.383423 725.676331,785.098022 
	C724.885742,787.968933 722.257874,788.916870 719.293701,789.911865 
	C719.270508,788.853333 719.606323,787.777771 719.979736,786.363464 
	C720.158264,785.907410 720.299133,785.789978 720.687195,785.793213 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M604.669067,827.055176 
	C604.444885,830.250000 601.996033,830.428345 599.224426,830.219971 
	C600.451599,828.434143 602.384155,827.756775 604.669067,827.055176 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M711.292603,792.943359 
	C711.102844,792.082764 711.407410,791.328003 712.746704,791.118042 
	C712.908081,791.970642 712.586914,792.706543 711.292603,792.943359 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M609.188965,516.348389 
	C618.443909,515.717529 627.385620,513.372009 636.398254,511.592560 
	C640.331055,510.816071 644.116211,511.621552 647.667908,512.331909 
	C663.006836,515.399475 677.721619,520.565308 690.179932,530.275513 
	C697.846069,536.250549 703.781372,543.756104 708.485107,552.706299 
	C713.938477,563.083008 716.594177,574.532959 722.723206,584.819946 
	C723.470947,585.326294 723.816711,585.732788 724.204773,586.591187 
	C726.645752,593.480896 728.966858,599.994385 731.703308,606.336609 
	C733.746765,611.072754 734.024902,616.218628 735.629272,621.100342 
	C738.353577,629.390137 743.288757,636.793335 745.102966,645.347046 
	C746.260864,650.806152 749.824890,655.581604 749.277832,661.753906 
	C747.939148,662.643555 746.842651,663.334167 745.221558,662.737671 
	C742.084778,657.556335 739.262573,652.699158 737.983337,646.781250 
	C736.130127,638.208740 731.638794,630.407471 728.956665,622.007690 
	C728.105774,619.342896 726.756531,616.798279 726.217896,614.016235 
	C723.762512,601.333008 717.487000,590.036804 713.209473,578.016602 
	C710.759888,571.133057 708.144897,564.318054 705.170227,557.633423 
	C700.278076,546.640137 692.412598,538.276001 682.382996,531.967285 
	C670.622864,524.570007 658.155029,518.909546 644.090271,517.303467 
	C639.707092,516.802979 635.459900,516.648560 631.224182,517.787109 
	C624.565796,519.576843 617.785400,519.780701 610.642334,519.346802 
	C609.947449,518.306152 609.570679,517.496338 609.188965,516.348389 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M610.280273,519.486023 
	C626.128723,517.234192 641.815979,512.777649 657.830261,518.372620 
	C674.596191,524.230225 689.683716,532.520569 700.927368,546.606201 
	C704.404541,550.962219 706.481201,556.239258 708.673340,561.390442 
	C714.461548,574.991516 718.621399,589.238892 725.148743,602.553833 
	C726.276917,604.855286 726.260071,607.695557 726.947388,610.239624 
	C729.781921,620.732788 734.344910,630.630249 737.712524,640.909241 
	C740.001160,647.894592 742.839783,654.675049 744.957764,662.139404 
	C743.596252,662.524597 742.239807,662.475891 740.481934,662.348633 
	C737.491333,659.679504 737.220703,656.198669 736.606018,652.894958 
	C736.247253,650.966370 735.688965,649.143127 734.689087,647.062378 
	C734.346985,646.542908 734.226746,646.326111 733.967834,645.767395 
	C733.026917,642.763367 733.144226,639.734253 730.535522,637.416382 
	C729.967346,636.487183 729.752869,635.800720 729.585693,634.733276 
	C729.513367,631.457581 727.732117,629.213867 726.388184,626.423584 
	C725.669373,625.041931 725.394897,623.876465 724.960205,622.385498 
	C724.304688,619.817932 725.137512,617.115906 722.419800,615.261963 
	C721.751343,614.045715 721.562195,613.069702 721.501099,611.694580 
	C722.037109,607.239136 719.523499,603.994690 718.288818,600.043579 
	C717.406189,598.294983 716.802734,596.806885 716.063843,595.017090 
	C715.616699,593.709167 715.515808,592.615662 714.562927,591.539307 
	C714.294678,591.086426 714.199890,590.898315 713.994080,590.415283 
	C713.566101,588.942505 713.570312,587.648071 712.471863,586.422607 
	C712.214722,585.958069 712.124756,585.764099 711.932983,585.264404 
	C711.479980,583.531982 711.755066,581.917725 710.380005,580.460938 
	C710.085754,579.977783 709.979187,579.775391 709.755493,579.247375 
	C707.369568,569.953857 703.887024,561.468079 699.447388,553.367798 
	C696.123840,547.303711 690.770996,543.111816 685.426819,538.498840 
	C684.891968,538.292847 684.681885,538.203979 684.183350,537.935974 
	C682.854187,537.009399 682.314453,535.609009 680.519104,535.341980 
	C679.756653,535.118286 679.339172,534.931458 678.642517,534.516357 
	C672.358704,530.207031 666.739929,525.365967 658.725220,524.509766 
	C656.794189,524.765625 655.271179,524.555603 653.394165,524.036987 
	C646.914185,521.214355 640.728699,519.150574 633.651978,521.828369 
	C632.320740,522.294678 631.310181,522.347290 629.926514,522.330811 
	C624.944580,521.813538 620.365295,521.763000 615.620361,523.601196 
	C614.666321,524.123413 613.979187,524.307739 612.882324,524.289368 
	C611.526367,524.130493 610.972107,523.495544 610.603638,522.330933 
	C610.423889,521.345337 610.330139,520.600708 610.280273,519.486023 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M710.719238,578.721436 
	C710.993896,579.004883 711.015625,579.489258 711.024719,579.731506 
	C712.453918,581.173035 711.596558,583.126526 712.765503,584.742371 
	C713.002441,584.997253 713.021545,585.486206 713.026611,585.730835 
	C714.196045,586.877808 713.765259,588.459045 714.771362,589.761841 
	C715.000000,589.997559 715.014343,590.488892 715.019287,590.734619 
	C715.869995,591.648193 715.950256,592.766846 716.759277,593.802185 
	C717.615417,595.460449 718.266174,596.893677 718.958496,598.663452 
	C719.552124,603.028992 721.236389,606.711975 722.742859,610.771118 
	C722.989380,612.073181 723.012939,613.121704 723.047852,614.563293 
	C724.960938,616.702942 724.223389,619.475037 725.796143,621.710571 
	C727.091003,622.849121 726.996643,624.071350 727.023438,625.618286 
	C728.363892,628.403503 728.413940,631.421204 730.730347,633.764648 
	C730.990356,634.757080 731.013794,635.488159 731.051025,636.584717 
	C733.543701,638.853760 732.698669,642.279480 734.753296,644.746948 
	C734.990784,645.007812 735.019958,645.487366 735.031372,645.727295 
	C736.040588,651.083435 739.189026,655.850769 737.284424,661.756836 
	C736.652283,662.176819 736.281616,662.235352 735.629456,662.304688 
	C735.068726,662.371887 734.789551,662.428284 734.116760,662.317383 
	C732.634521,661.051147 732.499329,659.659241 732.514465,657.814697 
	C732.585632,653.658081 730.898499,650.340759 729.493103,647.085144 
	C726.992859,641.293335 724.565857,635.506714 723.037170,629.377625 
	C722.306030,626.446289 721.345276,623.587158 719.472656,621.207764 
	C717.245789,618.378113 717.771423,614.844482 716.703674,611.750488 
	C712.318237,599.042664 707.840637,586.329895 702.484314,574.032654 
	C700.345703,569.122559 699.060303,563.656250 695.625244,559.245117 
	C694.427307,557.706665 693.891113,555.652527 694.516602,553.204285 
	C698.780701,550.380066 699.826172,553.565002 701.106323,556.299561 
	C704.504517,563.558533 707.651123,570.923828 710.719238,578.721436 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M720.685181,704.520691 
	C716.143799,709.667969 711.713074,714.491638 707.063477,719.553223 
	C705.822449,722.046753 704.370911,723.713928 701.272827,723.455933 
	C699.060303,720.757446 701.065674,719.251892 702.640808,717.272339 
	C703.316101,716.185913 703.901672,715.471069 704.622864,714.439697 
	C705.238892,713.358887 705.743225,712.609619 706.540161,711.658813 
	C709.581726,710.317383 710.611572,708.063599 710.876465,704.916870 
	C711.069214,704.367676 711.165527,704.152527 711.458618,703.639160 
	C715.221924,699.592957 715.873962,694.524658 717.648193,689.976379 
	C719.438354,685.387207 721.401855,680.850891 722.659607,675.688110 
	C722.902039,674.614014 723.227966,673.966919 723.865601,673.084229 
	C724.258179,672.646912 724.428101,672.487000 724.876953,672.124512 
	C726.668945,671.065125 728.374451,670.427429 729.198853,668.275391 
	C729.588257,667.854248 729.770569,667.708984 730.287720,667.453369 
	C731.103516,667.316772 731.552429,667.424683 732.294495,667.809387 
	C732.748657,668.753479 732.877869,669.553711 732.995361,670.670044 
	C729.819214,680.912170 728.199463,691.382507 722.373413,700.760315 
	C721.696350,702.112549 721.246033,703.154785 720.685181,704.520691 
z"/>
<path fill="#3F4243" opacity="1.000000" stroke="none" 
	d="
M749.431763,662.095825 
	C746.755493,655.086670 744.865784,647.898376 742.421265,641.017395 
	C740.198669,634.761353 737.154724,628.704529 735.085754,622.290344 
	C732.551208,614.432922 729.474854,606.735840 727.272888,598.791992 
	C726.197876,594.913879 723.049438,591.935730 723.046997,587.352966 
	C724.373108,585.919983 725.423767,586.561096 726.700317,587.767761 
	C726.985168,588.776184 726.997681,589.514526 727.018188,590.622131 
	C730.331299,593.580933 730.505371,597.845032 732.330383,601.228760 
	C733.242920,602.920715 734.079468,604.656677 735.360352,606.578735 
	C735.642822,607.418762 735.617188,607.912720 735.213623,608.695923 
	C733.320190,614.663330 735.934509,619.755737 738.156006,624.372986 
	C742.942810,634.322205 746.294312,644.790161 750.449646,654.956909 
	C751.258728,656.936462 752.134705,658.964478 751.993835,661.612000 
	C751.261230,662.045166 750.529175,662.074707 749.431763,662.095825 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M722.471497,700.984497 
	C725.964600,691.335754 727.741516,680.888672 732.730896,671.230530 
	C732.832031,679.245300 730.419739,686.971802 726.960327,694.332153 
	C725.890442,696.608459 725.147705,699.334656 722.471497,700.984497 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M737.391968,662.237427 
	C738.269531,656.773926 734.360596,652.107422 735.028442,646.404114 
	C737.908142,646.702332 737.202637,649.432312 737.778748,651.281982 
	C738.803833,654.573059 739.295837,658.030151 740.045654,661.843994 
	C739.307434,662.298889 738.534241,662.327698 737.391968,662.237427 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M733.671875,661.747559 
	C734.215271,662.022278 734.445007,662.066711 735.011353,662.213257 
	C734.782593,663.252563 734.279114,664.252197 732.822693,664.334229 
	C732.013123,664.241455 731.536316,664.110901 730.915405,663.396179 
	C730.693176,662.807861 730.685120,662.547241 730.900513,661.965820 
	C731.847473,661.230225 732.592651,661.195251 733.671875,661.747559 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M731.026001,662.770447 
	C731.750244,662.913818 732.031067,663.223267 732.436035,663.823730 
	C732.730164,664.638550 732.900146,665.162476 732.943481,666.097534 
	C732.190247,666.454651 731.565857,666.473145 730.498535,666.406250 
	C729.098022,664.963745 729.535217,663.901367 731.026001,662.770447 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M729.817261,666.367188 
	C730.648132,666.079224 731.275818,666.140930 732.360229,666.355713 
	C732.831482,666.840271 732.846130,667.171875 732.740112,667.728333 
	C732.075928,668.006470 731.532349,668.059692 730.580566,668.129517 
	C729.734192,667.768188 729.548035,667.291809 729.817261,666.367188 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M870.011597,747.732666 
	C872.096069,752.237183 876.427002,753.782166 880.057678,756.088501 
	C883.512451,758.283264 887.420898,759.795227 889.961304,763.637695 
	C889.184631,763.955261 888.009338,764.278076 887.580627,763.851135 
	C882.419434,758.711182 874.613770,757.704163 869.663147,752.358521 
	C868.292786,750.878784 865.927063,750.759949 865.064758,748.339111 
	C865.851807,747.831848 866.703674,747.663696 867.778320,747.252686 
	C868.377686,746.611023 868.835754,746.460999 869.687622,746.779846 
	C870.000000,747.000000 870.007629,747.488403 870.011597,747.732666 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M892.047729,765.349548 
	C895.807739,765.337708 897.627869,768.986633 900.930542,770.638611 
	C896.625977,771.608215 893.883911,769.457764 892.047729,765.349548 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M875.755615,746.996704 
	C876.307495,748.099792 876.615479,749.197144 876.968689,750.634399 
	C875.152100,750.754333 873.537048,750.052246 872.161987,748.275024 
	C872.584961,747.167969 873.439697,747.016602 874.687866,746.992920 
	C875.023926,746.982605 875.511780,746.990967 875.755615,746.996704 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M853.075195,737.359131 
	C854.366150,737.152405 855.999390,736.524536 856.921936,738.638428 
	C855.628601,738.849060 853.998901,739.468384 853.075195,737.359131 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M869.956787,746.663818 
	C869.555786,747.002686 869.111572,747.005432 868.334229,747.008911 
	C867.688660,746.218689 867.376221,745.427673 867.031860,744.318298 
	C868.506531,744.105713 869.570190,744.765869 869.956787,746.663818 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M861.051758,741.315308 
	C861.882629,741.100220 862.638245,741.379761 862.954834,742.666199 
	C862.116699,742.894714 861.362000,742.614014 861.051758,741.315308 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M735.201904,606.730591 
	C730.450867,605.297485 731.725342,600.212158 729.325256,597.179321 
	C728.155090,595.700684 727.817932,593.562805 727.064941,591.357300 
	C727.767578,590.994385 728.509033,590.997437 729.621460,591.001465 
	C730.174316,591.549255 730.356262,592.096130 730.766663,592.827148 
	C731.037170,595.235046 730.866272,597.541504 733.612427,598.922363 
	C733.975159,600.062927 733.991150,601.129272 734.005981,602.595337 
	C734.822205,603.994873 735.744263,604.950928 735.201904,606.730591 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M734.970215,607.365601 
	C734.672119,605.922791 734.377197,604.805176 734.043579,603.341309 
	C736.855286,603.676514 736.874146,605.981323 737.005554,608.606506 
	C736.558044,608.992004 736.107544,608.994385 735.318726,608.994751 
	C734.978088,608.558716 734.975769,608.124817 734.970215,607.365601 
z"/>
<path fill="#D5D3D3" opacity="1.000000" stroke="none" 
	d="
M910.010742,561.347961 
	C911.398254,561.137939 911.986084,561.968628 911.989319,563.656006 
	C910.606689,563.861755 910.013916,563.035034 910.010742,561.347961 
z"/>
<path fill="#DDD9D9" opacity="1.000000" stroke="none" 
	d="
M657.427734,663.053955 
	C658.184265,660.910034 657.713562,659.152649 657.331543,657.012451 
	C657.783081,655.728271 656.924927,655.171631 656.595703,654.170471 
	C656.051697,652.494446 655.634277,651.094177 655.181641,649.330444 
	C655.052673,648.426392 654.923828,647.896057 654.585693,647.143188 
	C654.230347,646.297791 653.976807,645.706360 653.691711,644.791992 
	C653.625488,643.752869 653.150269,643.198608 652.865784,642.207886 
	C652.426331,640.998291 652.073425,640.123291 651.639038,638.901367 
	C651.679260,637.721436 650.997559,637.162598 650.750366,636.151855 
	C650.303650,635.020691 649.938171,634.165405 649.494507,632.967163 
	C649.246887,630.477356 647.999390,628.643982 647.477478,626.237305 
	C646.760498,624.367065 646.215881,622.808960 645.627075,620.898315 
	C645.139038,618.148010 644.284302,615.870422 643.325195,613.263611 
	C642.310242,610.726440 641.510376,608.486145 640.636597,605.898865 
	C640.677612,604.715332 639.996704,604.158508 639.729492,603.150818 
	C639.339783,601.972900 639.036255,601.070068 638.707397,599.808167 
	C638.619324,598.742065 638.149780,598.190857 637.862549,597.203491 
	C636.612488,593.806213 635.449524,590.742004 634.250305,587.315308 
	C633.589478,584.403381 632.685852,581.945251 631.647217,579.187805 
	C631.276978,578.282776 631.033752,577.679565 630.751587,576.750610 
	C628.099670,570.279907 626.606140,563.733826 623.889954,557.235291 
	C623.438660,555.647156 623.064392,554.421875 622.754272,552.812500 
	C622.609802,551.628723 622.337524,550.841736 622.466248,549.611206 
	C631.975586,546.726013 641.446716,545.824646 651.068481,547.606323 
	C651.559265,547.697144 652.240601,547.787231 652.441895,548.135315 
	C655.409485,553.266052 660.867065,552.564636 665.489868,553.643494 
	C670.651306,554.847961 674.513245,557.869995 677.727478,561.789001 
	C681.518433,566.411255 685.267639,571.074402 688.828003,575.883423 
	C689.857544,577.273926 690.568359,578.793762 690.537354,580.487854 
	C690.411987,587.353699 693.535950,593.319702 695.393860,599.671875 
	C697.045471,605.318726 699.623169,610.158447 702.481445,615.212036 
	C704.883850,619.459534 705.357239,625.004700 706.648132,629.983582 
	C708.338440,636.503357 711.261597,642.366150 714.704346,648.109009 
	C719.490479,656.092834 719.023926,664.361694 715.951477,673.011230 
	C713.725220,679.278625 709.476990,684.369568 707.187378,690.581848 
	C704.784546,697.101196 701.895752,703.485474 699.502686,709.998291 
	C697.346252,715.867126 693.463806,719.803284 688.772888,723.306030 
	C680.356873,729.590332 670.381470,731.438049 660.336975,732.756592 
	C655.453064,733.397705 651.186096,735.704590 646.690613,737.227844 
	C641.232422,739.077332 635.656799,739.441650 630.150574,740.473694 
	C627.067261,741.051636 623.788879,740.836243 620.827148,738.310303 
	C620.086975,737.308777 619.731750,736.413940 619.331421,735.230957 
	C618.523682,732.411316 617.698547,729.900940 616.635803,727.122253 
	C615.715210,724.566101 614.911377,722.319092 614.068481,719.670654 
	C614.148193,718.614807 614.047363,718.005493 613.465698,717.198486 
	C613.208008,716.201172 613.141663,715.460938 613.450378,714.591858 
	C617.416565,710.630493 621.076050,706.807007 626.073486,704.857300 
	C630.600220,703.091431 634.445862,700.358826 638.452087,697.700806 
	C643.284058,694.494812 645.319946,689.284424 648.496094,685.027649 
	C652.068970,680.239197 654.299683,674.353455 657.195129,668.986633 
	C657.767273,667.926208 657.512085,666.722717 657.167419,665.221436 
	C657.106201,664.364502 657.174011,663.880249 657.427734,663.053955 
z"/>
<path fill="#D3D0D1" opacity="1.000000" stroke="none" 
	d="
M620.461670,738.582031 
	C629.809875,739.674133 638.946716,738.797424 647.659851,735.029358 
	C656.985291,730.996399 667.431641,731.288879 676.658264,728.022522 
	C686.718506,724.461060 695.854675,718.465149 699.302673,706.349060 
	C700.879456,700.808472 703.290710,695.114014 705.800293,689.644531 
	C708.564880,683.619446 712.755005,678.308350 714.671814,671.946167 
	C717.305847,663.203308 718.689636,654.558044 711.519409,646.902100 
	C709.173828,644.397583 708.432007,640.697876 707.357910,637.644958 
	C705.089111,631.196594 702.189575,624.758667 701.982483,617.696350 
	C701.967896,617.198608 702.086731,616.433655 701.821838,616.245300 
	C695.848999,611.998169 695.163635,605.037903 693.199280,598.882812 
	C691.670715,594.093079 688.297729,589.504761 689.149414,584.363770 
	C690.300537,577.415710 684.385254,574.324585 681.893738,569.446106 
	C681.233643,568.153625 679.504395,566.686462 678.333740,565.153259 
	C673.022583,558.196960 666.754822,552.897339 657.067322,554.778564 
	C656.045227,554.977051 654.708496,555.045654 654.423462,554.007446 
	C652.071350,545.441162 645.300720,548.625488 639.906555,548.591858 
	C634.507446,548.558167 629.105103,549.041809 623.349609,549.138306 
	C620.861877,546.331970 620.385132,543.573059 622.685791,540.383240 
	C624.048584,539.184265 625.564880,539.147827 627.082031,538.262573 
	C628.112671,537.949524 628.823181,537.916870 629.896301,538.060059 
	C634.001526,540.082764 637.813049,540.543213 642.150208,540.246460 
	C646.440735,539.952881 650.746155,542.286987 655.629028,541.793274 
	C656.212769,541.968262 656.437866,542.063904 656.963440,542.368835 
	C660.450317,548.790710 667.076721,550.068665 672.521545,552.888977 
	C675.264954,554.310059 678.235901,555.470093 681.436768,556.901367 
	C681.960632,557.215637 682.157288,557.363831 682.602722,557.786011 
	C686.680359,565.612793 691.589417,572.557922 695.249573,580.211975 
	C696.719727,583.286255 695.980469,587.344727 699.614014,589.851379 
	C700.503723,591.585938 700.614929,593.103638 700.568115,595.011108 
	C700.428162,597.780518 700.170532,600.220581 702.437378,602.374512 
	C702.761414,602.892273 702.872498,603.111145 703.103760,603.678040 
	C703.771912,608.364441 704.563599,612.620667 707.529968,616.525024 
	C708.072449,617.508240 708.273071,618.222839 708.414062,619.328735 
	C708.156433,623.984253 709.996155,627.513000 712.710388,631.069214 
	C715.680481,633.584534 716.573303,636.721436 717.238770,640.371704 
	C717.285278,644.854614 719.115479,648.246399 721.683960,651.751831 
	C726.648193,660.481567 725.163208,668.166138 719.306946,675.432983 
	C717.936829,677.133179 717.720825,679.415771 716.822632,681.777100 
	C711.239807,686.461853 710.700317,692.893921 709.323730,699.355774 
	C709.180542,699.945312 709.102295,700.174072 708.852905,700.719116 
	C705.297302,704.322754 704.003357,708.732910 702.159668,713.241333 
	C701.896912,713.756653 701.772522,713.952820 701.417603,714.410034 
	C698.762390,716.766235 696.893066,719.303589 695.154663,722.383911 
	C694.116211,724.223450 692.747070,725.166626 690.857849,726.026978 
	C689.341064,726.446777 688.276306,726.937622 687.176758,728.132690 
	C682.634827,730.692139 678.035767,732.078796 673.420349,733.399170 
	C660.861511,736.991943 648.207275,740.198608 635.395813,742.775940 
	C632.483337,743.361877 629.848938,744.891602 626.877808,745.401245 
	C625.327148,745.667175 623.947327,746.012390 622.643738,744.190674 
	C621.778320,742.223328 621.129822,740.591980 620.461670,738.582031 
z"/>
<path fill="#1D273D" opacity="1.000000" stroke="none" 
	d="
M712.675781,720.130005 
	C717.544983,724.499390 723.593628,726.964722 728.298706,731.344543 
	C732.320374,735.088379 732.523132,739.158691 730.870422,743.856934 
	C730.490784,744.936096 730.301941,746.082397 730.012634,747.598633 
	C728.897156,748.325623 727.590515,749.207703 726.723572,748.890015 
	C719.675903,746.307678 714.775879,750.729004 709.563416,753.980164 
	C705.388855,756.583923 703.247437,753.612915 700.681946,751.232849 
	C698.162842,748.895813 696.893433,746.577271 699.833252,743.310547 
	C701.740479,741.236633 703.620361,742.219849 705.528809,742.641113 
	C709.422546,743.500793 713.113831,740.373352 712.941467,736.386169 
	C712.828247,733.766357 711.332458,734.180847 710.083740,735.423279 
	C706.968567,738.523010 703.312134,738.202759 699.260132,737.227051 
	C695.740173,737.125549 693.040222,738.821716 690.246399,740.199829 
	C688.417297,741.102051 686.401184,741.595032 685.565735,739.468506 
	C684.360840,736.401611 687.640259,737.448730 688.846375,736.543640 
	C690.686829,735.162659 694.232666,737.592468 694.979248,733.393127 
	C694.587219,731.226257 696.037292,730.650024 697.125061,730.200195 
	C701.707703,728.304749 705.619263,725.604553 708.740723,721.249512 
	C710.117920,720.757996 711.238342,720.516663 712.675781,720.130005 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M657.322754,664.723083 
	C661.375854,669.981934 655.740234,672.828003 654.627686,676.721619 
	C652.734619,683.346497 647.963257,688.523254 644.420105,694.258301 
	C641.733521,698.606934 636.803101,701.599365 632.143921,703.935425 
	C627.131165,706.448853 621.408875,707.570251 618.248779,712.944275 
	C617.515076,714.191956 615.863281,713.923645 614.210327,714.351929 
	C613.825378,714.462952 613.827209,714.380615 613.856140,714.351318 
	C613.772888,713.792236 613.474365,713.406860 612.607422,713.146240 
	C609.760010,709.746826 609.047241,705.673218 607.721436,701.434204 
	C612.638550,698.887939 617.802979,697.240662 622.921082,695.666443 
	C635.532166,691.787720 645.228943,684.366638 652.675903,673.186646 
	C654.217590,670.670471 655.802917,668.574219 656.102783,665.517639 
	C656.103577,665.145874 656.095642,665.099976 656.118469,665.103271 
	C656.329041,664.716553 656.644653,664.545166 657.322754,664.723083 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M689.811157,725.227539 
	C691.294617,724.260254 692.519714,723.454834 693.921143,722.386108 
	C696.141602,719.990356 697.862610,717.511841 700.939331,716.002747 
	C702.109558,715.871948 702.852051,716.041931 703.795166,716.742554 
	C704.231750,719.480286 701.049927,719.827026 700.890015,722.415405 
	C701.810303,725.433411 699.450134,726.105408 698.021423,727.142517 
	C693.680664,730.293213 688.564270,731.710022 683.241028,732.977783 
	C664.574097,737.908752 646.008728,742.369019 627.229797,745.922791 
	C625.419556,746.265259 623.221741,747.603638 622.378723,744.196045 
	C624.273132,744.803711 626.072327,745.069946 628.119324,744.201294 
	C642.132935,738.254211 657.486816,737.128723 671.776062,732.140991 
	C676.603027,730.456116 681.487854,728.936890 686.678101,727.178406 
	C687.670593,726.180603 688.644775,725.836792 689.811157,725.227539 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M652.570312,672.965515 
	C650.058899,680.910583 643.902893,686.050720 637.416321,690.991150 
	C631.144958,695.767639 622.976868,696.051819 616.448242,700.189636 
	C614.069275,701.697327 611.234680,700.798096 608.150146,701.033081 
	C607.155701,699.905396 606.622070,698.771912 606.036316,697.320801 
	C609.325378,693.290894 614.267334,693.023926 618.510681,692.137695 
	C631.911621,689.338989 643.736450,684.259888 652.570312,672.965515 
z"/>
<path fill="#1A2537" opacity="1.000000" stroke="none" 
	d="
M699.010010,737.383362 
	C700.604248,736.713928 702.369019,735.863098 703.778137,736.263550 
	C706.419861,737.014404 707.864258,736.095581 709.027832,733.984497 
	C709.959412,732.294312 711.407288,731.637451 713.184082,732.327026 
	C715.103882,733.072266 715.120972,734.835938 714.885437,736.522888 
	C713.893311,743.628357 709.867371,745.936218 703.233337,743.310120 
	C702.496521,743.018433 701.613403,743.096375 700.399048,743.001465 
	C699.673340,741.255554 699.346680,739.511169 699.010010,737.383362 
z"/>
<path fill="#1A2537" opacity="1.000000" stroke="none" 
	d="
M683.017212,733.046021 
	C688.781067,729.568726 695.271484,727.667725 700.625244,722.992493 
	C703.455750,723.327576 704.131226,720.348083 706.508545,719.849060 
	C707.349365,720.166199 707.854126,720.541199 708.678223,720.957764 
	C707.930298,728.877258 700.845459,729.674805 695.238098,732.745483 
	C691.787537,732.273254 688.649536,731.884216 685.730408,734.070923 
	C684.788513,734.776367 683.575989,735.092041 683.017212,733.046021 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M616.449036,727.180176 
	C618.883606,728.811707 618.894775,731.774475 619.463989,734.689270 
	C617.463684,732.813049 617.152649,730.089172 616.449036,727.180176 
z"/>
<path fill="#C9C8C8" opacity="1.000000" stroke="none" 
	d="
M613.179810,717.146118 
	C614.289307,717.168579 615.742798,716.471558 616.133850,718.018188 
	C616.340088,718.833862 615.369324,718.889832 614.416016,719.201111 
	C613.714783,718.629761 613.400085,717.990295 613.179810,717.146118 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M736.168823,774.751709 
	C734.917725,775.022949 733.851440,775.048340 732.384277,775.057129 
	C732.638245,773.628601 733.786377,773.021790 735.644958,772.982483 
	C736.242188,772.976318 736.493225,772.997070 737.124512,773.029541 
	C737.120911,773.529358 736.737244,774.017578 736.168823,774.751709 
z"/>
<path fill="#080F10" opacity="1.000000" stroke="none" 
	d="
M731.712524,775.010132 
	C731.966492,776.434692 731.113403,777.024963 729.426514,777.086670 
	C729.078796,777.087280 729.043274,777.046082 729.024353,777.026611 
	C728.540466,776.273804 728.979675,775.822693 729.780579,775.218384 
	C730.496277,775.031677 730.938538,775.025696 731.712524,775.010132 
z"/>
<path fill="#1A2537" opacity="1.000000" stroke="none" 
	d="
M729.702148,775.032959 
	C729.777344,775.522705 729.500732,776.007751 729.114746,776.749939 
	C728.558655,777.002380 728.111938,776.997742 727.330444,776.978638 
	C727.153687,775.556396 728.014160,775.002991 729.702148,775.032959 
z"/>
<path fill="#DDD9D9" opacity="1.000000" stroke="none" 
	d="
M612.296265,713.573242 
	C612.664062,713.309998 613.102600,713.493469 613.713135,713.999512 
	C613.279602,714.720764 612.773193,714.622009 612.296265,713.573242 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M628.587097,521.055420 
	C630.064880,520.944092 631.131165,520.939026 632.597290,520.923706 
	C639.551758,519.912537 645.950012,520.867676 652.666931,522.704102 
	C654.737122,522.968506 656.437988,522.985474 658.563904,523.008057 
	C666.034729,525.488770 671.951111,530.061218 678.741516,533.748291 
	C679.445129,533.999146 679.881714,534.007996 680.645752,534.023376 
	C681.851807,534.862122 682.554443,535.875854 683.804688,536.760620 
	C684.029053,536.965149 684.518311,536.975464 684.762817,536.984192 
	C687.268250,539.127136 689.488708,541.309998 691.524231,543.654480 
	C692.784302,545.105896 694.220398,546.635437 693.121826,549.176025 
	C688.041199,550.708130 685.559753,547.121399 683.002136,544.296631 
	C681.156555,542.258179 679.285095,540.699829 676.693054,539.781494 
	C673.244568,538.559692 670.181458,536.554199 667.179626,534.473816 
	C657.480225,527.751831 646.413757,526.476929 635.052185,526.588745 
	C629.947693,526.638977 624.823364,526.460693 619.747620,527.439148 
	C618.254944,527.726929 616.744141,527.582397 615.823730,529.613342 
	C615.337463,530.026062 615.109070,530.148743 614.508301,530.159058 
	C612.858948,528.612244 612.893127,526.786621 612.583008,524.670898 
	C613.097107,523.882690 613.672180,523.468994 614.597900,522.989197 
	C617.939758,521.150330 621.287781,522.297852 624.446350,521.832703 
	C625.700500,521.648010 627.001526,521.820801 628.587097,521.055420 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M614.181763,530.389343 
	C614.135986,530.046692 614.559631,530.013916 614.771423,529.997070 
	C617.451782,526.583130 621.368408,527.117737 624.622864,527.133362 
	C632.172363,527.169434 639.696899,526.173218 647.320435,527.594910 
	C653.867371,528.815735 659.764771,530.921509 665.333252,535.088379 
	C666.263672,536.268250 666.717346,537.428833 668.414246,537.667603 
	C669.233276,537.905640 669.677856,538.119080 670.404907,538.617432 
	C671.943726,540.618591 673.230225,542.360718 672.190430,545.024536 
	C667.595642,545.787354 664.579895,542.763611 661.135681,541.043823 
	C653.907349,537.434448 646.594849,534.930908 638.297791,535.421265 
	C631.351746,535.831665 624.361572,535.517273 617.065186,536.263000 
	C614.497375,534.949829 615.568787,532.285889 614.181763,530.389343 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M616.743408,536.455933 
	C618.909668,534.441345 621.361572,533.960388 624.144775,533.932800 
	C629.772888,533.876953 635.387146,534.055969 641.046753,533.485352 
	C647.270508,532.857849 653.160339,535.413940 658.761902,537.940369 
	C663.100708,539.897278 667.130066,542.540100 671.648682,544.917603 
	C672.646729,544.437622 673.375732,544.172668 674.594360,544.346375 
	C676.960693,547.088379 680.038269,548.923889 680.749756,552.828003 
	C678.600952,556.476257 675.680359,554.788635 674.421814,553.204041 
	C670.391663,548.130005 664.808289,546.450745 659.065735,544.805420 
	C658.423035,544.621277 657.990967,544.053406 657.274963,543.279907 
	C657.000000,543.002441 656.505432,542.994873 656.258179,542.993774 
	C648.074524,538.652039 639.255981,539.638794 630.368042,538.901245 
	C629.248779,538.997925 628.500000,539.000793 627.376831,539.006714 
	C626.385742,539.565002 625.672119,539.782715 624.461304,539.601929 
	C621.923767,540.035583 619.892273,539.765015 617.745972,538.311096 
	C617.256042,537.608337 617.021118,537.206482 616.743408,536.455933 
z"/>
<path fill="#CDCCCD" opacity="1.000000" stroke="none" 
	d="
M617.492188,538.374634 
	C619.449646,538.119812 621.476929,537.653870 623.661743,538.857422 
	C624.177124,539.175171 624.354248,539.340515 624.269287,539.757690 
	C621.938293,542.523743 623.052917,545.431763 622.994934,548.586365 
	C622.964966,549.841553 622.934875,550.705017 622.861572,551.998474 
	C619.334473,548.530029 619.363708,543.265076 617.492188,538.374634 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M623.742493,557.225220 
	C627.465698,562.632629 628.753967,569.278076 630.773682,576.025879 
	C627.372986,570.513489 626.016724,563.861145 623.742493,557.225220 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M643.072510,613.346558 
	C645.980103,614.428955 645.686462,617.158386 645.698730,620.132324 
	C644.006897,618.544128 643.786743,616.035950 643.072510,613.346558 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M631.500122,579.248413 
	C633.868652,580.871155 634.080872,583.724915 634.405701,586.703857 
	C632.602783,584.766113 632.408691,582.051697 631.500122,579.248413 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M647.252869,626.287842 
	C649.734802,627.245789 649.170227,629.770264 649.553406,632.241394 
	C648.677673,630.632996 647.939148,628.641724 647.252869,626.287842 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M650.611206,636.169800 
	C651.528625,636.306274 651.848694,637.025146 651.668579,638.253296 
	C651.222778,637.857605 650.888000,637.160583 650.611206,636.169800 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M652.687500,642.154053 
	C653.575195,642.309326 653.904602,642.994934 653.757568,644.176270 
	C653.305420,643.790894 652.950623,643.112793 652.687500,642.154053 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M654.430664,647.175415 
	C655.424133,647.124451 655.922546,647.617004 655.325623,648.804932 
	C654.890076,648.465210 654.633728,647.963440 654.430664,647.175415 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M657.655029,664.519287 
	C657.255066,664.941895 656.940735,665.021118 656.386353,665.113770 
	C656.436035,664.400452 656.730774,663.694336 657.283997,662.845032 
	C657.606750,663.186462 657.671082,663.670959 657.655029,664.519287 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M656.418396,654.186890 
	C657.473328,654.272522 657.842834,654.991882 657.451904,656.332397 
	C656.979492,655.915649 656.673584,655.197449 656.418396,654.186890 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M637.694946,597.155396 
	C638.570984,597.298340 638.908081,597.977722 638.775391,599.156311 
	C638.326233,598.779419 637.970398,598.109863 637.694946,597.155396 
z"/>
<path fill="#B3B2B7" opacity="1.000000" stroke="none" 
	d="
M639.594177,603.170288 
	C640.534912,603.283325 640.827332,604.017883 640.666870,605.247314 
	C640.223450,604.856262 639.884216,604.160583 639.594177,603.170288 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M721.103027,785.621155 
	C720.934387,785.913818 720.476135,785.972290 720.246765,785.998535 
	C716.017761,785.850708 712.909119,788.484253 709.370605,789.678162 
	C691.186035,795.813599 672.833862,801.046692 654.126709,805.490784 
	C643.817871,807.939697 633.282349,810.006348 623.035828,813.138000 
	C614.248657,815.823547 605.173340,818.169128 595.459656,819.045593 
	C594.462585,819.548523 593.926697,820.073853 593.174194,820.779297 
	C592.532043,820.979004 592.096619,820.987915 591.334717,821.007568 
	C587.062256,822.236084 583.152710,823.633728 578.520508,823.455994 
	C577.587097,823.439819 577.059387,823.414001 576.152466,823.322693 
	C574.787109,823.086426 573.918274,822.566284 572.623657,822.168823 
	C572.071045,821.932861 571.857544,821.823242 571.348083,821.511597 
	C568.717834,819.512512 568.197815,816.513733 566.256653,814.016602 
	C565.876587,813.232361 565.738037,812.751404 565.620117,811.867310 
	C565.798035,805.610901 562.141785,800.900330 560.468018,795.624573 
	C557.323242,785.712524 554.683960,775.662842 551.687561,765.716553 
	C551.004883,763.450562 548.927856,761.358948 550.435425,758.310425 
	C553.116943,756.516602 553.928589,758.763794 555.428223,760.312256 
	C560.859985,777.247559 567.884460,793.239258 572.583130,810.033142 
	C573.489624,813.273071 575.250427,816.108154 578.624390,818.029053 
	C579.743225,818.099915 580.495789,818.085083 581.624634,818.065125 
	C583.202087,817.913086 584.476318,818.221802 585.834656,817.309937 
	C588.136475,816.795898 590.186462,816.457397 592.618774,816.055176 
	C593.593567,815.982239 594.133667,815.817322 594.831543,815.271057 
	C596.785400,814.743713 598.514038,814.396851 600.622192,814.031738 
	C601.593628,813.991211 602.136047,813.823853 602.847534,813.294800 
	C610.825195,811.337952 618.588928,809.635620 626.307190,807.747375 
	C653.247864,801.156677 680.491089,795.830750 707.196838,788.244995 
	C713.496643,786.455566 719.039917,782.982117 725.591003,780.984863 
	C725.637573,783.736389 722.111389,783.095642 721.103027,785.621155 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M578.856812,817.778564 
	C573.394287,819.821289 572.080872,814.642761 571.434875,811.856750 
	C569.213379,802.276794 564.557251,793.633728 561.795288,784.415466 
	C559.545654,776.907349 555.836182,769.759521 554.999268,761.412598 
	C553.559509,759.479126 554.225342,756.617615 551.436035,755.524353 
	C550.080688,753.565674 549.515198,751.714355 549.496948,749.342468 
	C549.528381,747.822021 549.198669,746.767761 548.360046,745.527466 
	C548.044067,744.826355 547.904053,744.413025 547.722900,743.678589 
	C547.603271,742.303223 547.262390,741.350769 546.407959,740.262512 
	C545.974121,739.416016 545.790527,738.809998 545.579834,737.880859 
	C545.402954,736.676331 544.817078,736.018921 544.214050,734.988892 
	C543.516785,733.394531 543.505493,731.942505 542.765625,730.333618 
	C542.639465,727.769348 541.867920,725.736145 541.004761,723.351440 
	C540.566467,721.835815 540.525879,720.630981 540.462769,719.081421 
	C540.595520,717.535889 540.239929,716.498352 539.229858,715.322388 
	C538.406067,713.983582 537.958435,712.865723 537.649109,711.307861 
	C536.608337,704.012268 533.600586,697.822571 530.333801,691.330627 
	C529.600464,689.831116 529.512695,688.571289 529.451904,686.943542 
	C529.386414,683.405029 527.570679,680.801880 526.432617,677.606201 
	C526.026062,676.865784 525.846802,676.417969 525.639526,675.599976 
	C524.442749,672.419434 525.145142,668.959167 522.393555,666.314697 
	C521.771851,665.095825 521.570190,664.136841 521.521729,662.775208 
	C521.279114,658.738220 520.405884,655.314880 517.485168,652.291504 
	C516.932922,651.264832 516.740906,650.515381 516.651978,649.334595 
	C516.673523,647.817993 516.963440,646.800476 518.118408,645.768066 
	C520.038086,645.198730 521.057129,645.944092 521.918335,647.616211 
	C522.197205,648.849487 522.363831,649.695007 522.771118,650.762817 
	C523.856873,651.647766 523.951294,652.756348 524.749268,653.793213 
	C528.430969,665.554626 531.205750,677.363892 535.558167,688.559998 
	C541.613220,704.135864 545.851501,720.250427 551.090515,736.059509 
	C556.278381,751.714233 561.204956,767.476990 566.490479,783.081299 
	C570.380615,794.566040 573.215881,806.508728 578.856812,817.778564 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M505.746460,603.310181 
	C506.752777,602.998352 507.499481,603.001648 508.619293,603.004883 
	C510.345734,605.888184 511.252808,608.872070 511.051056,612.520813 
	C511.445740,613.376526 511.803314,613.837524 511.989044,614.713074 
	C513.073242,619.024475 515.581360,622.680054 515.052368,627.515869 
	C517.036316,630.069580 517.465210,632.908936 518.700928,635.753174 
	C518.945312,636.766174 518.973877,637.505676 519.007324,638.615173 
	C520.076660,640.239563 520.047180,641.694519 519.577515,643.588684 
	C517.977966,645.147400 516.607422,644.677795 514.908325,643.647583 
	C511.767365,636.323303 511.488983,628.635315 508.889404,621.493042 
	C507.454620,617.551147 505.335693,613.692200 505.412537,608.875610 
	C505.737274,606.855347 504.848663,605.234863 505.746460,603.310181 
z"/>
<path fill="#071518" opacity="1.000000" stroke="none" 
	d="
M473.036926,567.359619 
	C478.927917,569.894409 484.589874,573.175659 489.756561,577.227173 
	C491.122559,578.298340 492.452362,579.068176 494.572632,579.031677 
	C494.953094,579.026001 494.989502,579.007690 494.982788,579.025818 
	C495.678864,579.788574 496.597717,580.186584 497.715515,580.820435 
	C497.933716,581.025391 497.993286,581.001953 497.993408,581.033936 
	C498.256592,581.299438 498.533508,581.515137 499.086029,581.744385 
	C499.347595,581.775940 499.703278,581.862427 499.840393,581.983643 
	C500.155731,582.241760 500.333954,582.378784 500.741150,582.763428 
	C500.970093,583.010986 500.999329,582.995972 500.994324,583.011475 
	C501.671173,583.829590 502.782715,584.207703 503.112091,585.574890 
	C502.820343,586.165955 502.619965,586.450378 502.194946,586.873779 
	C499.839172,586.915833 497.114197,588.756348 496.335144,584.673401 
	C496.039764,583.125366 493.540100,583.233765 492.126343,582.431885 
	C486.182404,579.060242 481.148468,574.260559 474.865814,571.420593 
	C473.243286,570.687195 472.907471,569.333313 473.036926,567.359619 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M511.092102,589.259888 
	C514.960571,590.103210 517.990051,592.724609 521.408569,595.256470 
	C521.210510,596.177551 520.685974,596.439148 519.600708,596.071472 
	C516.688660,593.496399 512.454834,593.443420 511.092102,589.259888 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M518.762024,644.334351 
	C518.982727,642.624207 518.991272,641.224976 519.005981,639.405457 
	C521.944092,641.113647 521.580383,644.577148 522.252319,647.767578 
	C521.201660,647.425659 520.372742,646.847351 519.271973,646.133423 
	C518.607483,645.627625 518.457458,645.176758 518.762024,644.334351 
z"/>
<path fill="#091A22" opacity="1.000000" stroke="none" 
	d="
M519.039368,596.117249 
	C519.738159,595.781433 520.261963,595.771118 521.173218,595.663574 
	C522.391968,596.049255 523.223267,596.532227 524.225586,597.252197 
	C524.327637,597.725708 524.258667,597.962158 524.126587,598.561890 
	C522.941833,599.028625 521.785278,598.992065 520.704956,599.261414 
	C517.236145,600.126099 518.074585,598.293762 519.039368,596.117249 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M518.929688,635.644165 
	C515.879700,634.494629 516.726196,630.950623 515.084290,628.308105 
	C518.433960,629.273071 518.148743,632.553528 518.929688,635.644165 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M506.112091,586.199585 
	C507.926758,586.147217 509.375793,587.197510 510.935425,588.606812 
	C509.007294,589.020691 506.915527,589.181335 506.112091,586.199585 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M503.450928,585.875244 
	C502.374329,585.130066 501.727875,584.378601 501.035339,583.327026 
	C502.668884,583.037659 503.927368,583.964966 505.356384,585.209839 
	C504.994171,585.606812 504.437622,585.737915 503.450928,585.875244 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M585.714539,817.090332 
	C585.498840,819.194824 584.134277,819.237793 582.239624,818.291870 
	C582.858643,816.877441 584.121704,817.031311 585.714539,817.090332 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M524.956177,653.670044 
	C523.568848,653.850769 523.006775,653.003357 523.015381,651.333069 
	C524.409363,651.139832 524.975647,651.987793 524.956177,653.670044 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M602.777527,813.108154 
	C602.831482,813.990906 602.677734,814.985840 601.178223,814.216919 
	C601.492126,813.705933 601.982422,813.398315 602.777527,813.108154 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M594.755371,815.081787 
	C594.825745,815.966736 594.700012,816.979431 593.176086,816.198364 
	C593.485352,815.685242 593.969666,815.379089 594.755371,815.081787 
z"/>
<path fill="#1D1F20" opacity="1.000000" stroke="none" 
	d="
M512.219360,614.821045 
	C511.705933,614.517029 511.391541,614.029419 511.067139,613.238037 
	C511.917572,613.177002 512.964966,613.270264 512.219360,614.821045 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M726.945251,587.703857 
	C725.890869,587.713196 724.809143,587.388550 723.392090,587.015747 
	C723.046387,586.528809 723.035950,586.089905 723.033020,585.321350 
	C723.524292,584.735596 724.007996,584.479553 724.741943,584.110474 
	C726.117371,584.845642 726.595459,586.063293 726.945251,587.703857 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M724.972900,581.686768 
	C723.598938,581.853577 723.016235,581.019714 723.041260,579.340637 
	C724.438965,579.156250 724.994568,580.009521 724.972900,581.686768 
z"/>
<path fill="#0DE3F6" opacity="1.000000" stroke="none" 
	d="
M512.114136,585.404846 
	C513.732666,579.589539 514.145935,573.651917 518.306580,568.890015 
	C522.134583,564.508911 524.976501,559.476868 523.187073,553.076843 
	C522.832153,551.807495 523.691650,550.198608 524.016968,548.341492 
	C525.643738,549.379944 527.478088,550.637756 528.801147,552.301941 
	C532.530884,556.993225 538.214294,558.758240 542.760254,562.115906 
	C548.101501,566.061096 556.457520,565.495483 559.281311,572.940796 
	C561.395569,578.515259 563.085938,584.249084 565.062256,589.877747 
	C567.581177,597.052002 570.198059,604.191772 572.951355,611.663818 
	C572.299622,615.169556 575.170288,617.115295 575.740723,619.800171 
	C576.136597,621.663574 577.056396,624.178528 575.184326,625.324036 
	C573.484558,626.364197 571.985596,624.308411 570.583984,623.183044 
	C564.608093,618.384827 557.086975,616.141052 551.136292,611.217346 
	C542.296387,603.903015 532.377991,598.176758 522.150635,592.355225 
	C521.478943,591.478577 520.929749,590.952637 520.164795,590.241821 
	C519.944336,590.061157 520.000000,590.002441 519.969666,590.028931 
	C519.467102,589.452026 518.908447,588.932739 518.114258,588.249268 
	C515.934570,587.483398 513.781982,587.364563 512.114136,585.404846 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M497.813995,580.731934 
	C496.777863,581.042358 495.657928,580.982849 495.043884,579.372437 
	C496.117310,579.050354 497.003082,579.554688 497.813995,580.731934 
z"/>
<path fill="#12D1EB" opacity="1.000000" stroke="none" 
	d="
M517.962402,588.384399 
	C518.827209,588.097107 519.611084,588.354736 519.905029,589.728394 
	C519.059570,589.942688 518.330627,589.620667 517.962402,588.384399 
z"/>
<path fill="#12D1EB" opacity="1.000000" stroke="none" 
	d="
M520.000244,590.369873 
	C520.884521,590.108215 521.649658,590.398621 521.894775,591.775024 
	C521.060120,591.939819 520.314026,591.628296 520.000244,590.369873 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M499.185974,581.558716 
	C499.002258,581.688660 498.656891,581.601318 498.152527,581.289978 
	C498.337158,581.157837 498.680756,581.249695 499.185974,581.558716 
z"/>
<path fill="#103A46" opacity="1.000000" stroke="none" 
	d="
M500.891479,582.772339 
	C500.637299,582.965149 500.369751,582.808105 500.072510,582.322388 
	C500.255951,582.247803 500.534393,582.390808 500.891479,582.772339 
z"/>
<path fill="#E4E3E1" opacity="1.000000" stroke="none" 
	d="
M526.181763,544.221558 
	C526.365234,544.151245 526.576172,544.335205 526.867432,544.734131 
	C526.658264,544.872314 526.412109,544.714600 526.181763,544.221558 
z"/>
<path fill="#05B9D9" opacity="1.000000" stroke="none" 
	d="
M251.879593,597.812012 
	C248.440994,598.738098 246.465042,596.003906 244.028732,594.502502 
	C237.349319,590.386230 230.844376,585.986938 224.093719,581.405640 
	C223.180893,579.666077 221.998489,578.811279 220.150681,578.286377 
	C216.666397,578.743042 215.220276,576.390137 213.272095,574.212158 
	C212.412109,573.428833 211.875534,572.798035 211.296799,572.200439 
	C207.939011,568.733459 208.450912,565.541504 213.089508,563.950684 
	C216.086487,562.922791 216.883560,561.794312 216.203461,558.640320 
	C215.469681,555.237427 216.744263,551.742126 217.202332,548.306396 
	C217.547928,545.714111 219.723801,544.465088 222.105743,543.912903 
	C227.738693,542.607239 233.026016,539.923828 239.028534,540.029175 
	C242.211273,540.085083 244.691467,539.371277 244.092651,534.823303 
	C243.588623,530.995239 245.961349,530.236389 250.970490,532.418335 
	C256.817719,535.431458 261.325897,539.789795 266.714447,542.861694 
	C268.573944,543.921692 270.431305,544.859741 272.760498,545.725525 
	C273.089691,545.888733 273.105713,545.954712 273.108734,545.918457 
	C273.629242,546.642700 274.347595,547.304993 274.157471,548.651001 
	C273.691498,550.828918 272.505981,552.821167 275.031738,554.187744 
	C275.811981,554.609985 276.048370,555.490112 275.418030,556.304016 
	C274.923065,556.943115 274.286896,556.761841 273.638245,556.491577 
	C269.854340,554.915344 267.026062,556.519470 268.317139,560.145325 
	C270.381927,565.943909 265.866211,569.820740 265.482941,574.770142 
	C265.362335,576.327820 263.707672,576.995239 262.439026,577.825806 
	C256.944397,581.423096 253.172134,586.732849 248.981262,591.619263 
	C248.206558,592.522522 248.144196,593.434021 249.437637,593.955078 
	C251.027206,594.595459 251.565201,595.929688 251.879593,597.812012 
z"/>
<path fill="#06D1F2" opacity="1.000000" stroke="none" 
	d="
M300.336700,629.964417 
	C299.659698,629.852539 299.426483,629.681641 299.131256,629.257690 
	C299.057098,627.177734 297.092407,626.839478 296.154114,625.293823 
	C295.366730,619.774963 290.166931,617.283813 288.582916,612.564636 
	C287.322784,608.810425 289.024780,599.944641 292.028290,597.392273 
	C294.132568,595.604065 295.345062,594.004211 293.673035,591.089294 
	C292.112915,588.369446 293.359985,585.809387 295.784058,584.208557 
	C298.371185,582.500122 299.727173,580.764160 297.897034,577.710083 
	C296.770050,575.829407 297.427277,574.543945 299.563141,574.076172 
	C300.846832,573.795166 302.479980,573.792114 302.500824,572.039246 
	C302.523163,570.156860 300.667267,569.914185 299.382935,569.289246 
	C297.281189,568.266602 293.572693,567.730591 298.677551,564.999634 
	C311.364563,568.544250 320.470764,577.358887 330.862213,584.639038 
	C332.861908,586.872559 333.555786,589.155884 333.183533,591.798645 
	C332.975006,593.279236 332.833862,594.785034 333.135651,596.263611 
	C335.098755,605.880859 330.875610,613.812195 325.957886,621.383118 
	C323.232819,625.578430 319.777435,629.208923 321.755951,634.865479 
	C322.282501,636.370789 321.609528,638.149292 320.288086,639.807495 
	C313.622620,636.575928 308.173676,631.371033 300.336700,629.964417 
z"/>
<path fill="#31BCC9" opacity="1.000000" stroke="none" 
	d="
M274.333496,548.979004 
	C273.695465,548.157288 273.386322,547.348877 273.094482,546.211426 
	C277.325470,547.507385 281.513763,549.180481 285.063141,552.516113 
	C284.657623,552.903625 284.187927,552.955566 283.364990,553.024902 
	C281.884369,551.706299 280.307831,552.071411 278.483154,551.944580 
	C277.198608,551.372131 276.857147,550.403931 276.187866,549.303650 
	C275.537628,549.057861 275.100006,549.025085 274.333496,548.979004 
z"/>
<path fill="#24CCDD" opacity="1.000000" stroke="none" 
	d="
M274.447418,614.884583 
	C273.825165,614.388977 273.526276,613.937683 273.128998,613.233276 
	C274.165222,613.040833 275.367737,612.956177 275.986481,614.646790 
	C275.642975,614.959717 275.206848,614.944275 274.447418,614.884583 
z"/>
<path fill="#24CCDD" opacity="1.000000" stroke="none" 
	d="
M272.943848,612.629578 
	C272.596802,612.946716 272.176331,612.929443 271.444214,612.868042 
	C270.810303,612.377502 270.488007,611.931030 270.058044,611.228882 
	C271.079865,611.068970 272.328339,610.901855 272.943848,612.629578 
z"/>
<path fill="#05B9D9" opacity="1.000000" stroke="none" 
	d="
M275.993591,549.434082 
	C277.036682,549.482971 277.717621,550.217712 278.045563,551.633545 
	C276.688904,551.916016 276.048340,551.138794 275.993591,549.434082 
z"/>
<path fill="#05B9D9" opacity="1.000000" stroke="none" 
	d="
M256.811523,600.826599 
	C256.426086,600.903442 255.860214,600.728210 255.089905,600.332275 
	C255.467346,600.265930 256.049225,600.420227 256.811523,600.826599 
z"/>
<path fill="#05B9D9" opacity="1.000000" stroke="none" 
	d="
M253.860626,599.794800 
	C253.660294,599.903748 253.397919,599.718628 253.063110,599.247864 
	C253.265259,599.129211 253.534561,599.308716 253.860626,599.794800 
z"/>
<path fill="#08DCF7" opacity="1.000000" stroke="none" 
	d="
M320.200958,640.302612 
	C319.635376,637.232849 320.114380,634.598572 318.877594,631.523193 
	C317.716278,628.635559 321.346161,624.812866 323.516449,621.739868 
	C328.881866,614.142944 333.764801,606.424072 331.314240,596.403503 
	C330.455902,592.893616 332.798126,589.260925 331.029694,585.342651 
	C337.477448,586.048096 341.130524,591.445068 346.169250,594.575562 
	C352.587494,598.563232 354.974884,605.663147 360.840485,609.944397 
	C363.800293,612.104858 366.746063,615.269714 368.514404,618.948608 
	C369.089630,620.145264 369.621368,621.543823 371.086670,621.807190 
	C376.818573,622.837585 380.781525,627.415100 386.191956,629.192566 
	C388.911987,630.086182 391.275574,631.418518 394.244019,630.172180 
	C395.835297,629.504150 397.507385,630.303162 399.096466,630.801575 
	C400.143219,631.129883 401.621948,630.885254 401.734711,632.500916 
	C401.818604,633.703125 400.752441,634.185120 399.865204,634.576294 
	C395.766083,636.383911 394.074219,640.650879 390.994141,643.532898 
	C383.330017,650.704285 375.222717,657.546387 368.717499,665.923401 
	C366.845703,668.333740 364.494690,666.556396 362.860474,665.692688 
	C357.472137,662.844543 351.874023,660.111267 347.162262,656.329834 
	C339.741577,650.374451 330.829315,647.183899 323.012604,642.047302 
	C322.181885,641.501404 321.287109,641.052917 320.200958,640.302612 
z"/>
<path fill="#EDECEF" opacity="1.000000" stroke="none" 
	d="
M410.807617,624.807007 
	C396.304077,616.539001 382.209900,608.187012 368.105133,599.852966 
	C365.682770,598.421631 363.215271,597.066772 360.418304,595.543823 
	C357.622650,596.265442 356.303833,594.002075 354.092010,593.195435 
	C353.785675,593.028381 353.560791,592.853088 353.432678,592.915649 
	C353.108337,592.868408 352.912048,592.758545 352.440155,592.454102 
	C351.893158,591.981628 351.621796,591.703613 351.185242,591.185791 
	C349.834961,589.924316 347.971252,589.332825 348.860840,586.822144 
	C350.370239,585.535278 351.888885,585.179138 353.357544,585.377014 
	C361.591400,586.486877 369.940552,585.809387 378.169678,587.187134 
	C383.848419,588.137939 389.535736,588.624451 394.692200,591.684570 
	C396.762207,592.913025 398.754364,593.981995 399.741211,596.202087 
	C401.821136,600.881348 404.751312,605.226868 405.256500,610.535950 
	C405.481232,612.897339 406.792755,614.787354 408.113464,616.764709 
	C409.683685,619.115601 412.226471,621.297791 410.807617,624.807007 
z"/>
<path fill="#46CAD9" opacity="1.000000" stroke="none" 
	d="
M357.889404,595.821045 
	C360.152130,599.705750 363.870697,601.360596 367.173248,603.427246 
	C376.957306,609.549866 387.572601,614.295959 396.957947,621.615784 
	C395.135101,622.272644 393.407806,621.502747 391.940063,620.465698 
	C383.855286,614.753601 373.969482,612.471069 365.912018,606.789368 
	C362.017456,604.043030 358.714142,600.555542 355.999695,596.197815 
	C356.470093,595.348572 357.055542,595.307251 357.889404,595.821045 
z"/>
<path fill="#AAE4EE" opacity="1.000000" stroke="none" 
	d="
M348.740051,586.738281 
	C349.549835,588.135803 350.247375,589.216980 350.982483,590.622070 
	C342.953064,588.210632 336.032471,583.622375 329.717957,577.521057 
	C333.156372,575.990173 334.710754,580.744934 338.479675,580.073242 
	C341.198029,581.565186 343.297455,583.433655 346.214783,584.633911 
	C346.925964,584.985046 347.313324,585.216919 347.943726,585.689331 
	C348.344879,586.075562 348.486359,586.248779 348.740051,586.738281 
z"/>
<path fill="#05657D" opacity="1.000000" stroke="none" 
	d="
M403.730835,636.289429 
	C404.297821,639.134155 401.626678,640.423584 400.255920,642.788025 
	C399.453003,642.714355 398.910919,642.432617 398.091766,642.014221 
	C399.681122,640.037170 401.547577,638.196838 403.730835,636.289429 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M358.587891,595.916504 
	C357.557098,596.023621 357.025482,595.979675 356.225861,595.892944 
	C355.356476,595.072327 354.755066,594.294434 353.969666,593.272461 
	C356.021820,592.805603 357.767029,594.106201 359.867920,595.167542 
	C359.741028,595.529236 359.414032,595.647339 358.587891,595.916504 
z"/>
<path fill="#46CAD9" opacity="1.000000" stroke="none" 
	d="
M397.079437,622.267456 
	C399.011505,622.024231 400.171265,623.580811 401.895752,624.740479 
	C400.048523,625.013550 397.819702,625.409851 397.079437,622.267456 
z"/>
<path fill="#05657D" opacity="1.000000" stroke="none" 
	d="
M408.630096,632.898560 
	C408.970581,633.333740 408.981964,633.799988 408.982666,634.614624 
	C408.430908,634.941956 407.889801,634.920898 406.944092,634.830017 
	C406.867584,633.900696 407.362244,633.201477 408.630096,632.898560 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M410.446106,629.707275 
	C411.108459,629.395264 411.605988,629.751160 411.803894,630.861267 
	C411.394592,630.796570 410.982300,630.389404 410.446106,629.707275 
z"/>
<path fill="#31BCC9" opacity="1.000000" stroke="none" 
	d="
M285.155304,553.033569 
	C285.256866,552.827515 285.421722,552.802795 285.830261,552.837219 
	C285.788849,553.002625 285.503693,553.108765 285.155304,553.033569 
z"/>
<path fill="#308DA3" opacity="1.000000" stroke="none" 
	d="
M352.319763,592.424561 
	C352.515411,592.190979 352.813843,592.286621 353.182220,592.762329 
	C353.005524,592.896606 352.729004,592.767029 352.319763,592.424561 
z"/>
<path fill="#04AFD0" opacity="1.000000" stroke="none" 
	d="
M251.325256,533.656189 
	C250.488571,533.742920 249.765808,533.638611 249.294769,533.238220 
	C248.197998,532.305786 247.016876,531.543030 245.764954,532.602539 
	C244.531555,533.646362 245.033691,535.097168 245.693985,536.269836 
	C247.521469,539.515625 246.994324,540.993408 242.921066,541.130005 
	C235.980286,541.362793 229.375092,543.567993 222.703888,545.287842 
	C219.713898,546.058655 217.776642,547.288208 218.429108,551.214722 
	C219.054733,554.979553 215.922699,559.049316 219.434479,562.827881 
	C220.066132,563.507507 218.119827,564.457581 217.042374,564.786316 
	C209.603058,567.056274 209.598526,567.041443 212.973236,573.666809 
	C200.395752,571.114258 193.186325,563.365662 190.774597,550.570068 
	C188.827332,540.238770 188.646118,539.927368 197.525879,534.537720 
	C198.759674,533.788818 199.795639,532.592285 200.687057,531.422974 
	C203.827545,527.303406 207.417953,523.673828 211.588837,520.587891 
	C214.012024,518.794983 216.209717,516.748474 216.880981,513.226562 
	C215.976761,511.423523 216.803726,510.441681 218.099594,509.289795 
	C220.470917,508.347961 222.719238,508.033905 224.628952,506.106323 
	C225.493454,505.515198 224.830872,506.029053 225.368454,505.636047 
	C236.037216,497.836426 238.683517,501.729492 241.300903,512.012085 
	C243.047958,518.875488 245.818695,524.601807 250.475555,529.666809 
	C251.409470,530.682617 251.881409,531.907349 251.325256,533.656189 
z"/>
<path fill="#05657D" opacity="1.000000" stroke="none" 
	d="
M219.343658,509.831604 
	C218.135468,510.527496 217.412354,511.224792 216.977432,512.569946 
	C211.930664,515.633179 208.128662,519.825989 204.074753,523.642456 
	C199.846985,527.622681 195.587769,531.534119 190.963318,535.036682 
	C189.529556,536.122620 188.113937,537.386230 185.751495,537.243225 
	C185.271378,536.762817 185.134888,536.280579 184.963272,535.426147 
	C184.947769,534.880188 184.967361,534.706482 184.999451,534.271118 
	C188.832428,532.118469 191.928665,529.349365 194.918518,526.336426 
	C198.779617,522.445496 202.896881,518.802246 207.016891,515.180969 
	C207.729050,514.555054 208.528229,514.178223 209.087280,513.253784 
	C213.689728,505.643311 222.112244,503.352753 229.118011,499.089996 
	C229.469177,498.876312 230.091766,499.108643 230.996552,499.199585 
	C231.777405,503.066711 228.005432,503.277710 225.965164,505.433472 
	C223.418564,506.598785 221.870483,508.680878 219.343658,509.831604 
z"/>
<path fill="#04AFD0" opacity="1.000000" stroke="none" 
	d="
M219.964722,578.421143 
	C221.412292,578.604675 225.747147,574.060608 223.981094,580.760498 
	C222.353683,580.771667 220.825256,580.374695 219.964722,578.421143 
z"/>
<path fill="#074F62" opacity="1.000000" stroke="none" 
	d="
M185.228973,537.724976 
	C184.846024,537.823425 184.395401,537.761597 184.979797,537.156555 
	C185.167999,537.106506 185.243317,537.516846 185.228973,537.724976 
z"/>
<path fill="#31BCC9" opacity="1.000000" stroke="none" 
	d="
M224.815689,505.334106 
	C227.066879,503.327667 229.086609,501.597076 231.255737,499.566193 
	C234.690079,497.505096 237.201187,494.532379 241.189529,493.281372 
	C241.819427,494.625183 242.061584,495.953552 242.408630,497.621338 
	C243.370178,499.354309 243.290298,500.787811 242.494354,502.641724 
	C241.284424,516.007629 246.996735,525.936035 256.409424,534.169861 
	C261.406860,538.541382 267.222046,541.723511 272.929993,545.590454 
	C268.740845,546.779541 265.711761,543.776489 262.813568,541.648193 
	C259.208435,539.000854 255.236221,536.974060 251.397339,534.129456 
	C251.154709,530.900269 248.523407,529.194641 246.859177,527.533630 
	C240.941711,521.627625 241.107925,513.267517 237.634613,506.393219 
	C235.496918,502.162354 233.602081,502.391907 230.446106,504.024872 
	C228.832474,504.859772 227.313904,505.878387 225.404312,506.858704 
	C224.715454,506.539215 224.558151,506.108063 224.815689,505.334106 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M345.976074,546.391357 
	C348.348419,544.761230 350.717651,543.625305 353.744354,544.746765 
	C353.742218,545.496094 353.484436,545.992249 353.113342,546.744141 
	C351.530426,546.928894 350.060822,546.857849 348.296997,546.898254 
	C347.246124,547.613464 346.571350,547.462097 345.976074,546.391357 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M356.369507,544.997192 
	C356.258545,542.979370 357.767365,543.004272 359.632599,543.004456 
	C359.741608,545.020569 358.233002,544.995117 356.369507,544.997192 
z"/>
<path fill="#BFBEC0" opacity="1.000000" stroke="none" 
	d="
M364.316559,542.963928 
	C364.251678,541.531738 364.480072,540.039551 366.799255,540.785645 
	C366.516754,542.021301 365.789429,542.738647 364.316559,542.963928 
z"/>
<path fill="#E3E0DF" opacity="1.000000" stroke="none" 
	d="
M347.121277,586.178345 
	C343.524872,585.410767 340.552917,584.140259 338.902222,580.431763 
	C344.070526,576.363770 350.458893,577.744690 356.191193,576.129517 
	C362.484985,574.356140 369.137024,575.159546 375.644135,574.977722 
	C379.255615,574.876770 382.646973,575.533081 385.640961,577.452698 
	C388.300354,579.157837 391.194397,580.430603 393.847107,582.054932 
	C408.982483,591.322327 419.121674,604.011353 419.933716,622.512756 
	C420.089630,626.065002 423.636230,628.271545 422.747314,632.141724 
	C419.115845,630.402710 415.762634,628.451965 412.202789,626.218018 
	C411.786804,625.432068 411.581451,624.925964 410.711731,624.829163 
	C410.445618,624.891052 410.428680,624.867981 410.415283,624.876465 
	C410.542999,621.990417 408.899811,619.406006 407.078613,618.011414 
	C404.603027,616.115784 403.738251,613.943054 403.657867,611.303772 
	C403.483551,605.579346 399.480377,601.355164 398.027527,596.153198 
	C397.899261,595.693909 397.530487,594.949036 397.249268,594.937744 
	C392.366730,594.741577 389.561951,590.093872 384.384766,589.672302 
	C376.161713,589.002686 367.953156,588.107300 359.701355,587.553345 
	C356.406555,587.332153 353.103119,587.153992 349.325714,587.037170 
	C348.852295,587.054565 348.444641,587.056335 348.241577,587.038574 
	C347.823425,586.764587 347.580383,586.538391 347.121277,586.178345 
z"/>
<path fill="#C6C4C6" opacity="1.000000" stroke="none" 
	d="
M345.669952,546.142456 
	C346.471222,546.311096 346.943634,546.627136 347.709412,546.976440 
	C347.675232,547.558655 347.410736,548.533813 347.010742,548.593018 
	C339.894012,549.646484 332.225616,553.915405 325.740936,549.389832 
	C320.238129,545.549500 313.138031,544.465820 308.758087,539.151428 
	C306.476501,536.383057 302.769623,537.558533 300.336365,535.293762 
	C298.788818,533.853394 297.326965,532.931946 299.688416,531.150574 
	C301.036774,530.133484 301.140503,529.027771 300.131683,527.751892 
	C299.061401,526.398254 297.897217,525.880127 296.317291,527.103760 
	C294.389221,528.597046 292.430542,528.104980 290.931244,526.517456 
	C288.056610,523.473572 283.282837,523.228699 281.012482,518.805237 
	C280.117859,517.062134 275.681671,516.987122 273.100250,515.474365 
	C272.829651,515.315735 271.623627,515.969238 271.559814,516.361328 
	C270.807556,520.981384 269.420471,520.565979 265.484253,518.625427 
	C263.427887,517.611633 257.561462,513.886536 255.494949,520.580200 
	C255.221085,521.467285 254.211655,521.467712 253.340637,521.187683 
	C252.404892,520.886780 251.136154,520.730347 251.468964,519.377380 
	C252.538239,515.030396 249.426422,515.308838 246.406693,515.000000 
	C245.380783,512.910034 244.746445,510.822540 244.073456,508.373779 
	C244.201248,508.006256 244.419479,508.066986 244.526718,507.984100 
	C252.315521,501.965515 262.273743,506.340881 270.652222,502.459778 
	C275.217438,500.345062 279.648254,504.204193 283.912506,505.514862 
	C288.030426,506.780518 292.589417,507.512299 296.338257,509.973297 
	C300.973846,513.016479 306.653717,515.357605 309.786926,519.563232 
	C313.274231,524.244080 316.246918,529.605774 319.827301,534.257996 
	C323.530853,539.070251 327.472229,544.591309 335.003235,544.242554 
	C338.555084,544.078064 341.553772,547.272400 345.669952,546.142456 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M286.977631,547.624756 
	C279.395844,547.362427 273.802155,542.411865 267.562805,538.921753 
	C264.994415,537.485046 262.853485,535.284058 260.259460,533.223389 
	C257.505066,530.485596 254.984863,527.979492 252.226807,525.239990 
	C251.672928,524.537537 251.357590,524.067688 251.002533,523.307739 
	C252.992035,522.859741 254.678024,523.352783 256.351318,524.734619 
	C260.378387,528.060364 265.789825,529.740112 268.638611,534.943420 
	C269.763275,534.044922 270.950409,533.529907 271.809204,532.238586 
	C279.381378,529.356750 281.941895,530.949646 282.235779,538.595398 
	C282.290527,540.019653 282.698822,540.672668 283.932831,541.138916 
	C286.780670,542.214844 287.185425,544.578674 286.977631,547.624756 
z"/>
<path fill="#CBCACA" opacity="1.000000" stroke="none" 
	d="
M271.633362,532.012634 
	C273.436584,534.683594 271.875061,535.073120 269.352936,534.998901 
	C268.974426,533.412537 269.238708,532.046143 271.633362,532.012634 
z"/>
<path fill="#EDECEF" opacity="1.000000" stroke="none" 
	d="
M410.567749,625.106201 
	C411.192993,624.464417 412.128265,623.380615 412.088074,625.660828 
	C411.560760,625.730225 411.125336,625.525757 410.567749,625.106201 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M242.575699,503.024902 
	C242.273666,501.622314 242.255157,500.204407 242.375076,498.373657 
	C243.465942,497.657104 244.418381,497.353424 245.681274,497.013123 
	C246.883408,495.796356 248.461243,496.232788 249.779480,495.259705 
	C250.762558,495.023376 251.497940,495.001343 252.601028,494.969452 
	C262.085693,490.720856 271.505096,488.034302 281.705200,489.374298 
	C282.202393,489.439606 282.712646,489.546387 283.195435,489.468048 
	C299.334442,486.849884 309.396149,497.800537 320.533112,506.265656 
	C322.522034,507.777374 324.091980,509.978546 324.745972,512.374756 
	C326.358337,518.282837 330.618530,522.730896 333.023376,528.240417 
	C335.932343,534.904846 348.507935,538.516663 353.911957,536.579041 
	C356.666534,535.591370 359.419617,535.413757 362.657043,535.623413 
	C363.584503,535.818237 364.069946,536.045898 364.763245,536.690857 
	C364.825775,538.420471 363.817200,538.713318 362.609894,539.092285 
	C355.984924,541.171631 349.094849,541.288940 342.282837,541.901367 
	C339.881439,542.117310 337.831055,540.591187 335.932098,539.268555 
	C329.052521,534.477051 323.122650,528.907227 321.200165,520.254272 
	C321.128387,519.931274 321.083069,519.515625 320.865906,519.323975 
	C310.956390,510.581268 302.584167,499.510315 288.388489,497.144836 
	C283.531494,496.335541 278.570526,496.434998 273.694611,495.285492 
	C270.731628,494.586975 268.041809,497.381805 264.656647,497.110046 
	C257.337158,496.522522 250.783234,499.684143 244.086945,502.659607 
	C243.620743,503.014313 243.239990,503.011963 242.575699,503.024902 
z"/>
<path fill="#CDCCCD" opacity="1.000000" stroke="none" 
	d="
M252.033844,525.349548 
	C255.781631,526.200623 257.964935,529.136658 259.956543,532.672119 
	C256.197510,531.832275 254.032410,528.876770 252.033844,525.349548 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M459.256653,992.765930 
	C455.911041,992.377808 452.819305,991.749451 449.338623,991.023560 
	C449.155212,990.315979 449.199829,989.562683 449.593750,989.120605 
	C451.404053,987.089233 453.114929,984.114197 456.284149,985.228699 
	C459.421692,986.332092 459.725494,989.504822 459.256653,992.765930 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M363.315033,536.745422 
	C359.712860,537.057983 356.640930,537.218323 353.394836,538.641174 
	C348.451233,540.808167 332.374969,534.390747 331.182098,529.184143 
	C329.808258,523.187561 324.791016,519.600891 323.485626,513.629761 
	C322.050659,507.065765 315.491150,504.216827 310.557892,500.382080 
	C308.342590,498.660095 305.728210,497.444855 303.547394,495.686584 
	C299.041962,492.054169 293.991486,490.035248 288.290466,491.099548 
	C283.705139,491.955597 279.209290,490.891785 274.854584,490.532898 
	C267.144897,489.897583 261.251648,495.748566 253.399200,494.972443 
	C253.289337,493.872681 253.609924,492.785797 253.974442,491.359253 
	C254.397263,490.364380 255.003632,490.001801 256.023193,489.630493 
	C257.338867,489.335999 258.074524,488.674988 259.264893,488.079224 
	C261.099457,487.494202 262.630798,487.450989 264.555664,487.476440 
	C268.781830,487.567963 272.513580,486.670258 276.263184,486.097931 
	C292.390320,483.636261 306.205078,487.999237 318.476471,498.808624 
	C326.934753,506.259216 331.653381,516.111267 337.456085,525.287354 
	C342.028503,532.517944 347.581665,535.134949 356.249359,533.200623 
	C357.530762,532.914673 358.840057,532.615906 360.459015,531.994019 
	C361.624756,531.653076 362.419067,531.486816 363.648499,531.488464 
	C367.537903,531.168335 370.706268,528.354309 374.782654,530.437744 
	C376.165619,532.838074 374.675537,533.498779 373.147614,534.368591 
	C370.169464,536.063843 366.779449,535.671814 363.315033,536.745422 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M257.185822,490.778107 
	C256.239838,490.983093 255.498993,490.994141 254.388245,491.012390 
	C253.569382,491.012848 253.120392,491.006073 252.334808,490.996155 
	C252.005203,489.805298 252.830322,489.151123 253.819214,488.230652 
	C254.692719,487.342529 255.343933,486.657654 256.552185,486.142395 
	C257.952515,485.589783 259.169617,485.684052 260.514587,484.950073 
	C261.955139,484.514343 263.019104,484.031982 264.493225,483.702942 
	C266.433868,483.533783 268.087830,483.656677 269.768280,482.373352 
	C270.573730,482.055176 271.060974,481.944275 271.947296,481.857239 
	C275.493744,481.902222 278.724915,480.782043 282.063110,482.717224 
	C282.879181,485.366913 281.336792,485.583038 279.597870,486.064056 
	C275.019135,487.330597 270.119965,486.648987 265.322784,488.733337 
	C263.282898,488.993164 261.578217,489.005005 259.447510,489.019226 
	C258.476318,489.540680 257.933441,490.062225 257.185822,490.778107 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M249.653641,495.031158 
	C249.719360,497.030975 248.220978,497.001770 246.362793,496.984039 
	C246.269257,494.949677 247.782745,494.996826 249.653641,495.031158 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M257.206970,486.771484 
	C256.413086,487.784821 255.575394,487.960266 254.335526,487.974792 
	C254.000504,487.541656 253.997360,487.105408 253.995300,486.342102 
	C262.171295,481.704071 270.682037,478.643921 280.565857,479.018616 
	C282.139191,481.595398 280.307526,481.848083 278.603394,481.935242 
	C276.627197,482.036377 274.661865,482.032806 272.416626,482.969543 
	C271.561737,483.022858 271.120361,483.023315 270.347931,483.027252 
	C268.815643,484.454742 266.867737,483.603699 265.231934,484.738586 
	C263.871826,485.273071 262.777649,485.582855 261.343079,485.931305 
	C259.798981,486.084412 258.522614,485.761780 257.206970,486.771484 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M249.300415,488.938629 
	C249.095398,488.098785 249.380661,487.339600 250.685959,487.050568 
	C250.903580,487.899414 250.607986,488.650513 249.300415,488.938629 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M365.764252,525.399170 
	C372.336670,523.446167 379.046021,523.193970 384.950592,519.403198 
	C385.382965,518.623718 385.815186,518.337646 386.725098,518.126343 
	C393.067047,517.266602 398.972534,516.736877 404.290955,521.313354 
	C405.194244,522.323669 405.492432,523.311951 406.528076,524.208435 
	C407.244171,525.036987 407.605591,525.664917 408.026855,526.669312 
	C409.390930,536.763611 415.144653,544.183533 421.102173,551.392761 
	C423.044464,553.743103 424.912567,556.482361 428.445465,557.615540 
	C429.003143,557.767273 429.220490,557.846252 429.739685,558.093506 
	C431.152161,559.003479 431.731903,560.411987 433.476410,560.903748 
	C433.984100,561.188843 434.180573,561.318420 434.644348,561.683228 
	C440.842224,567.906006 446.740143,573.725952 454.434296,577.771240 
	C465.648987,583.667480 474.986572,592.170166 483.233368,601.794067 
	C485.316071,604.224609 487.828979,606.033447 490.454926,607.808716 
	C496.277252,611.744934 499.640656,617.161255 499.466614,624.691589 
	C498.574127,625.470154 497.851562,625.374878 496.786560,624.957520 
	C489.479279,616.799744 481.894989,609.404358 474.424683,601.883423 
	C467.941681,595.356445 460.589203,589.928528 453.081238,584.905090 
	C445.747070,579.997925 438.377808,575.193970 431.955200,569.186340 
	C427.462738,564.984192 422.638641,561.207153 418.064575,557.124512 
	C410.294769,550.189331 406.425385,540.710938 402.269135,531.617737 
	C399.997589,526.648071 396.544739,525.065430 391.967163,524.545288 
	C388.529358,524.154663 385.074036,524.618896 381.409485,525.871826 
	C380.593719,526.213806 380.100861,526.336182 379.209229,526.466797 
	C374.634918,527.031494 370.702972,529.065186 366.026184,528.371704 
	C365.246674,527.356262 365.197266,526.560669 365.764252,525.399170 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M365.600189,525.104553 
	C366.004974,525.778870 365.999603,526.496582 365.992310,527.574097 
	C365.107452,529.223694 363.744629,529.432861 361.943787,529.527344 
	C357.185394,529.965454 352.961121,528.847961 348.725830,528.207092 
	C344.698944,527.597900 342.418762,524.541077 341.388855,520.855774 
	C338.945007,512.111084 333.639160,504.450592 327.580505,498.780212 
	C321.178162,492.788177 314.512299,485.253326 303.988403,484.435486 
	C303.042267,484.365173 302.509521,484.252808 301.605865,483.901672 
	C297.428345,481.310364 293.210266,482.930573 289.209320,482.482941 
	C286.831421,482.216888 284.117706,482.911133 282.948792,479.465332 
	C288.804504,476.968597 294.749207,477.885101 300.602814,478.433044 
	C315.339172,479.812469 326.301605,488.333435 335.133667,499.110229 
	C339.405640,504.322784 344.084137,510.060699 345.182037,517.468018 
	C345.755646,521.338013 351.585938,525.561890 356.338806,525.927795 
	C359.298279,526.155701 362.322327,526.324463 365.600189,525.104553 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M262.385864,478.965454 
	C262.168213,478.120270 262.518921,477.465973 263.428528,477.130981 
	C276.372894,472.364105 289.610138,470.530212 303.283234,473.149384 
	C303.839813,473.256012 304.298676,473.872681 304.903931,474.621582 
	C294.927032,475.462219 284.774170,473.917694 274.763062,476.005951 
	C270.741028,476.844940 266.778839,477.971069 262.385864,478.965454 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M332.036316,489.351746 
	C334.270203,488.710785 335.613678,490.187134 337.094299,491.565338 
	C343.548859,497.573517 346.929260,505.518494 350.423553,513.285461 
	C351.620758,515.946533 353.993195,517.614502 354.952759,520.649353 
	C352.041321,521.252991 349.944305,519.444031 349.212830,517.139038 
	C345.832397,506.486847 339.106232,498.018280 332.036316,489.351746 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M282.675659,479.031067 
	C284.281006,481.473602 286.602753,480.781555 288.669769,481.016937 
	C292.970764,481.506775 297.515900,479.645325 301.790192,482.677429 
	C300.246429,487.173523 297.029572,484.802338 293.882019,484.757416 
	C289.819214,484.784912 286.125153,485.543915 282.371399,483.322021 
	C278.856476,483.181396 275.692963,483.332123 272.266296,483.252655 
	C274.093933,479.652954 278.454681,482.401947 280.916962,479.393799 
	C281.462036,479.030121 281.903290,479.026581 282.675659,479.031067 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M306.070679,475.375977 
	C315.074829,475.153381 322.457733,479.373505 329.302887,484.843140 
	C330.359924,485.687805 331.020752,486.819733 331.004272,488.612091 
	C330.849976,488.959534 330.622772,489.007080 330.528625,488.916412 
	C323.624329,482.268921 314.985077,478.831696 306.070679,475.375977 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M377.338196,519.026855 
	C378.821503,514.750366 383.010651,516.012146 386.642639,515.055420 
	C387.614716,515.795593 387.498199,516.503357 386.705658,517.438599 
	C384.174561,518.086304 381.878815,518.135071 379.365479,519.028137 
	C378.549744,519.064270 378.108978,519.055359 377.338196,519.026855 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M379.241852,519.286377 
	C380.775787,517.480042 382.953247,517.076782 385.631348,517.015686 
	C386.329346,517.222778 386.654541,517.396057 387.153687,517.764282 
	C387.315430,518.179810 387.321899,518.364990 387.163452,518.773865 
	C386.562805,519.005737 386.127045,519.013855 385.365295,519.032837 
	C383.190582,519.195557 381.341888,519.347534 379.241852,519.286377 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M356.050415,521.361084 
	C357.118622,521.047668 358.338470,520.898193 358.935028,522.646606 
	C357.860596,522.967651 356.653168,523.092651 356.050415,521.361084 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M361.209808,523.220764 
	C361.591370,522.167175 362.447998,522.013550 363.711853,522.043945 
	C363.725616,523.567200 363.007111,524.333679 361.209808,523.220764 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M374.002869,519.276733 
	C374.758636,519.000671 375.517303,518.998962 376.654907,519.028809 
	C376.760254,520.586914 376.024078,521.300171 374.227112,520.316040 
	C374.007660,519.916870 374.006683,519.734009 374.002869,519.276733 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M370.286652,521.915222 
	C370.114777,521.063843 370.422607,520.313110 371.755402,520.107422 
	C371.893951,520.940308 371.548584,521.659302 370.286652,521.915222 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M445.071014,559.317017 
	C445.005219,558.239990 445.002411,557.498718 444.999207,556.387207 
	C448.187653,556.657654 450.562378,558.624756 453.134705,560.497803 
	C460.387909,565.779175 467.351654,571.511719 475.667877,575.866943 
	C477.524261,577.509094 479.024933,579.037231 480.776093,580.769043 
	C481.135986,581.295349 481.330811,581.547302 481.799500,581.869507 
	C482.622162,582.424255 483.264954,582.837280 483.063141,584.038940 
	C478.675934,586.368774 476.713470,582.070312 473.274414,580.892761 
	C469.277283,576.120728 463.571014,574.131897 459.129700,569.961304 
	C458.115082,569.168274 458.011414,568.183228 457.203308,567.251038 
	C455.870575,566.687927 454.779938,566.355103 453.342651,566.017639 
	C450.319214,563.962280 447.098053,562.584412 445.071014,559.317017 
z"/>
<path fill="#2C2D2F" opacity="1.000000" stroke="none" 
	d="
M505.970825,602.678589 
	C506.327148,604.429932 506.648224,605.864685 506.967468,607.670532 
	C504.244904,609.441284 503.297485,607.047241 502.474518,605.539062 
	C499.193512,599.526184 493.570221,596.067871 488.392090,592.121582 
	C486.421753,590.619995 484.110291,589.473206 484.683136,586.091064 
	C488.204315,584.489685 489.642059,588.246277 492.681213,588.785217 
	C493.300049,589.130737 493.553314,589.324829 493.877380,589.793701 
	C498.224518,592.378845 501.066559,596.283447 504.748962,599.764893 
	C505.302399,600.792847 505.618988,601.577515 505.970825,602.678589 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M504.945251,599.663086 
	C500.408051,598.007507 497.481750,594.077209 494.046295,590.344360 
	C500.386261,589.885498 501.546570,595.888184 504.945251,599.663086 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M492.835571,588.707275 
	C490.040131,589.349304 488.146973,587.025330 485.343506,586.052734 
	C483.755280,586.440552 483.210205,585.696289 482.893555,584.371338 
	C482.736481,583.514404 482.483459,583.011536 482.119141,582.249634 
	C486.906006,581.900879 489.144226,586.229919 492.835571,588.707275 
z"/>
<path fill="#252828" opacity="1.000000" stroke="none" 
	d="
M480.960388,580.652100 
	C478.454468,580.603638 477.023773,578.930603 476.037659,576.354370 
	C478.553284,576.390381 479.973053,578.076660 480.960388,580.652100 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M430.242065,559.256836 
	C429.987823,559.028625 429.503906,559.000854 429.261780,558.990845 
	C426.387543,557.095825 424.051361,554.895264 421.985901,552.403320 
	C420.836548,551.016663 419.658386,549.645996 420.736267,547.342651 
	C429.692139,552.893372 438.405029,558.748779 446.313568,565.738342 
	C450.728638,569.640442 455.018707,573.922241 461.952209,574.989624 
	C463.550262,575.130066 464.336060,575.570923 465.493591,575.977295 
	C466.000275,576.252197 466.195526,576.379333 466.652100,576.742798 
	C473.669830,583.765259 480.854675,590.202637 488.397064,595.955933 
	C497.618835,602.990234 503.692383,611.717163 507.926880,622.548096 
	C508.180878,623.346130 508.283539,623.812195 508.382935,624.638123 
	C508.690369,628.221375 508.957855,631.455078 511.354553,634.405579 
	C511.726257,635.153687 511.853302,635.613953 511.947021,636.449219 
	C511.508270,638.498352 512.148193,640.093750 512.429932,642.092285 
	C512.850830,645.720154 515.163086,648.686829 513.746460,652.516968 
	C509.785095,654.614014 510.121124,651.349731 509.317566,649.423950 
	C505.682373,640.712158 504.757233,631.194275 501.233582,622.442932 
	C498.452148,615.534851 494.496643,609.641479 488.560425,604.925720 
	C481.142487,599.032959 475.055481,591.636169 467.547852,585.783569 
	C464.635803,583.513428 461.555542,581.565674 458.347260,579.861633 
	C450.195374,575.531860 443.664917,569.089844 436.202209,563.278809 
	C434.999756,562.133423 434.683960,560.510498 432.649231,560.429016 
	C431.637085,560.223938 431.031036,559.927307 430.242065,559.256836 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M420.725220,547.025330 
	C420.805084,551.138611 424.892914,552.355652 426.584686,555.220581 
	C427.209869,556.279236 428.584259,556.906677 428.967377,558.634338 
	C423.794800,558.692871 421.850281,553.989868 418.704834,551.049500 
	C413.473633,546.159485 411.691833,538.903198 407.456360,533.239441 
	C406.543640,532.018921 407.102295,529.698120 406.980591,527.460754 
	C408.100250,526.472900 408.793335,527.163635 409.640381,528.205200 
	C410.188690,529.773376 409.619263,531.379456 411.397400,532.453247 
	C412.182037,534.168701 413.237213,535.390686 413.790466,537.219482 
	C414.361145,538.720947 414.522949,540.048401 415.699585,541.272949 
	C417.075317,543.216553 417.936890,545.124268 419.767334,546.742920 
	C420.017303,546.980835 420.489441,547.007751 420.725220,547.025330 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M387.263611,519.254211 
	C386.998535,518.997559 386.999756,518.501160 387.005371,518.253052 
	C387.872955,516.870728 388.989838,516.111816 390.675842,515.870117 
	C392.054443,516.000122 393.119049,515.988464 394.582794,515.970093 
	C396.265625,514.792053 397.834442,515.019287 399.734009,515.143066 
	C401.018921,515.556274 401.815308,516.145142 403.066772,516.609009 
	C404.294983,517.094238 405.077271,517.616272 405.850891,518.682251 
	C406.260651,519.496338 406.516968,519.992249 406.886292,520.743774 
	C407.085144,521.731506 406.920776,522.399414 406.205261,523.213745 
	C405.466125,523.570374 405.005493,523.577881 404.268738,523.230347 
	C399.312469,518.048279 393.412354,518.818115 387.263611,519.254211 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M466.226624,577.265808 
	C465.966675,577.026001 465.485992,577.010498 465.245789,576.999146 
	C464.324249,576.196960 463.383575,575.812683 462.241119,575.185486 
	C462.005341,574.995850 462.000092,575.000000 462.001770,574.996338 
	C461.689178,571.681152 457.329071,572.307678 456.787842,569.210022 
	C457.381805,568.620239 457.842102,568.470520 458.693115,568.763184 
	C463.784851,572.224487 469.495911,574.210876 472.707489,579.867554 
	C469.607086,581.271912 468.221832,579.032104 466.226624,577.265808 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M419.970398,546.621338 
	C417.247192,546.823669 416.361816,544.611938 415.127655,542.319336 
	C414.126801,540.991699 414.014618,539.633911 413.190002,538.258606 
	C412.339386,536.582031 411.701996,535.147949 411.018768,533.369751 
	C410.606720,532.610046 410.481323,532.131348 410.818054,531.269226 
	C415.821747,534.996887 417.037506,541.113892 419.970398,546.621338 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M444.752991,558.990601 
	C447.423309,561.366394 451.604584,561.549316 452.953094,565.665588 
	C447.868073,566.954773 446.496582,562.593445 444.036377,559.360107 
	C444.157959,559.013000 444.327942,559.006409 444.752991,558.990601 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M405.770630,523.318481 
	C406.171936,522.461365 406.354797,521.912964 406.768494,521.181885 
	C406.999298,520.999268 407.499054,520.998108 407.748901,521.001465 
	C409.051331,522.399902 409.302216,524.242920 410.756958,525.775574 
	C410.996674,527.073608 411.011566,528.134949 411.026184,529.594360 
	C410.297882,530.479004 409.838562,530.041992 409.200684,529.261475 
	C408.501556,528.425598 408.005524,527.848694 407.243744,527.154602 
	C406.708618,526.539185 406.439240,526.040955 406.047089,525.287292 
	C405.586029,524.620361 405.461975,524.152161 405.770630,523.318481 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M406.225586,518.793945 
	C405.200256,518.751526 404.396179,518.502686 403.301514,518.120850 
	C402.426636,517.321472 402.261627,516.564392 402.748444,515.375854 
	C405.002411,515.371399 406.690521,516.035889 406.225586,518.793945 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M401.185791,515.770020 
	C399.277313,516.010254 397.559540,516.000977 395.411865,515.977600 
	C396.132019,512.927307 398.379089,514.003052 400.738159,514.756470 
	C401.128784,515.182861 401.252686,515.351685 401.185791,515.770020 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M410.981781,525.659424 
	C408.335236,525.755127 407.929688,523.925720 407.999451,521.383606 
	C409.708405,521.942749 410.997345,523.172424 410.981781,525.659424 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M390.991699,515.737549 
	C389.964966,516.797607 389.290710,518.169067 387.349243,517.996948 
	C386.708679,517.881104 386.457489,517.690186 386.138794,517.225464 
	C386.336639,516.560303 386.652985,516.101624 387.009399,515.359619 
	C388.100433,515.036926 389.151428,514.997681 390.596924,514.936646 
	C390.992126,515.097656 390.992859,515.280518 390.991699,515.737549 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M408.997253,529.373169 
	C409.499725,529.259583 410.001892,529.516724 410.764954,529.883179 
	C411.025909,529.992371 411.031891,530.471191 411.035767,530.710083 
	C411.024536,531.410400 411.009430,531.871826 410.983643,532.679443 
	C408.985260,532.731873 408.996765,531.234497 408.997253,529.373169 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M401.051392,516.330261 
	C400.996918,515.851868 400.998810,515.684265 401.002777,515.265320 
	C401.443451,515.015625 401.882019,515.017151 402.649017,515.027344 
	C402.986725,515.773804 402.996002,516.511658 403.008118,517.618652 
	C402.123596,517.896362 401.367645,517.619202 401.051392,516.330261 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M458.950684,568.663452 
	C458.561798,568.982056 458.114532,568.988525 457.331787,568.997498 
	C456.989746,568.559998 456.983185,568.119995 456.968933,567.350403 
	C457.859070,567.095398 458.622894,567.364502 458.950684,568.663452 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M363.327240,536.995483 
	C365.193573,533.429382 369.642456,535.344482 372.459564,533.137817 
	C373.136780,532.607361 374.398743,532.837952 374.926666,531.375732 
	C375.909271,529.776550 377.255280,529.569458 379.033325,529.471558 
	C383.286499,529.116150 387.097412,529.913940 390.715485,530.846741 
	C396.613831,532.367188 400.002869,537.356079 402.179871,542.435364 
	C406.497162,552.508423 413.878906,559.911438 421.596161,567.166565 
	C422.065674,567.607910 422.851318,567.545410 423.865356,567.810913 
	C424.459717,568.057922 424.685547,568.185181 425.198853,568.575317 
	C426.618439,571.378662 427.716797,573.827515 430.106171,575.645996 
	C431.924255,577.029724 433.105743,579.167969 432.197693,582.062378 
	C431.199951,582.772583 430.432068,582.803711 429.319092,582.242554 
	C428.369843,581.799866 427.359894,581.761169 427.185944,581.360046 
	C423.440033,572.721619 414.588318,568.951599 409.049103,562.140503 
	C406.341980,558.811768 403.512299,555.345703 401.740143,551.119446 
	C399.676117,546.196960 397.110229,540.589172 392.761749,538.000916 
	C387.400085,534.809631 380.576904,533.520874 374.112885,535.633789 
	C371.359070,536.533997 368.723267,537.117126 365.449585,537.010010 
	C364.566162,537.006592 364.115601,537.002441 363.327240,536.995483 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M489.273254,642.781860 
	C485.800934,640.763428 485.002106,637.200745 484.074951,633.775208 
	C483.726074,632.486328 483.851624,630.866516 482.455505,630.362732 
	C477.202759,628.467041 473.939301,623.470154 468.615875,621.687134 
	C466.776154,621.070862 464.687653,620.379944 465.587280,617.505859 
	C466.019897,616.123840 464.647247,615.639832 463.802704,614.853088 
	C458.728149,610.126343 453.789246,605.253174 448.681458,600.563416 
	C446.334259,598.408325 442.587952,597.645081 442.037903,593.360657 
	C445.457855,590.333862 447.834503,592.351562 449.973999,594.949158 
	C454.382233,600.301086 460.171356,604.260315 464.973206,609.687744 
	C466.019928,610.126587 466.619568,610.575134 467.407715,611.383301 
	C469.034760,619.268738 475.753418,621.560791 481.245514,624.796021 
	C484.284088,626.586060 487.061066,628.443604 489.540710,631.391113 
	C489.927124,632.154968 490.077271,632.619385 490.223206,633.461060 
	C488.933716,636.643738 492.401154,639.862305 489.273254,642.781860 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M465.253021,610.718384 
	C464.356964,610.912781 463.302399,611.054382 463.113708,610.714783 
	C459.770172,604.698059 452.981720,602.000977 449.317535,596.394104 
	C447.653931,593.848450 445.733337,592.667786 442.392090,593.003174 
	C441.990326,593.004883 441.494568,593.003906 441.246674,593.001953 
	C437.957184,589.670898 433.616333,587.402466 432.609131,582.203247 
	C435.007141,580.504639 436.428650,582.224121 438.013672,583.287109 
	C440.586823,585.012939 442.626862,587.526550 446.081787,588.537109 
	C447.472321,588.689270 448.408386,589.022949 449.509552,589.904602 
	C453.038788,595.622864 458.805450,598.690308 462.607880,603.627869 
	C464.163300,605.647583 466.561768,607.310608 465.253021,610.718384 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M432.756836,581.809021 
	C433.949860,586.725891 439.965790,587.567383 440.952209,592.651245 
	C438.034088,593.236511 435.730072,592.400818 434.915344,589.204895 
	C434.103210,586.019287 430.183228,585.623962 429.042328,582.350342 
	C429.747009,582.002869 430.503540,581.998474 431.638245,581.990967 
	C432.181702,581.865356 432.347076,581.742920 432.756836,581.809021 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M495.315002,655.220520 
	C492.783752,651.857910 490.703857,648.647522 490.000488,644.380249 
	C490.374664,643.726562 490.799286,643.653564 491.571350,643.884338 
	C492.071991,644.145020 492.258820,644.274292 492.688354,644.642212 
	C493.583313,646.111633 494.793945,646.839600 495.833862,648.193237 
	C496.456085,650.573608 498.566193,652.239014 497.319275,654.970825 
	C496.618408,655.497192 496.130341,655.556091 495.315002,655.220520 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M492.350677,644.669189 
	C491.503357,644.740173 491.004272,644.482849 490.247253,644.113159 
	C489.862335,643.699097 489.669647,643.447754 489.204437,643.125854 
	C490.921753,640.209351 487.286102,637.461975 488.968811,634.326355 
	C491.275208,633.015930 491.882629,634.610229 492.469849,636.223755 
	C493.444977,638.903076 494.171387,641.591492 492.350677,644.669189 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M495.007294,655.397339 
	C495.453217,654.998108 495.897858,654.998657 496.676086,654.998413 
	C500.626465,655.622925 500.683228,655.739075 500.018677,661.970093 
	C499.182800,663.313660 498.423157,663.445190 497.265320,662.368591 
	C496.995117,662.005798 496.988037,661.507568 496.985565,661.258484 
	C496.455780,660.471802 495.933502,659.929321 495.204773,659.193787 
	C495.004761,657.929138 495.005402,656.863159 495.007294,655.397339 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M496.995728,662.443848 
	C497.492523,662.262085 497.989929,662.518311 498.743683,662.887268 
	C499.323730,663.400146 499.470062,663.857239 499.227600,664.678894 
	C498.525604,667.814575 499.977448,670.410645 499.992249,673.591492 
	C496.216278,671.044983 497.396484,666.750671 496.995728,662.443848 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M495.055328,659.307739 
	C495.889465,659.107300 496.646515,659.393677 496.934814,660.695679 
	C496.100494,660.893738 495.346893,660.603760 495.055328,659.307739 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M499.999573,675.334839 
	C500.628082,675.105347 501.378845,675.172729 501.420837,675.955444 
	C501.437042,676.257141 500.874329,676.589844 500.292542,676.958801 
	C500.006226,676.562622 500.002686,676.116150 499.999573,675.334839 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M547.240662,803.701782 
	C546.314941,804.574585 545.813843,805.369751 546.282471,806.146118 
	C550.609924,813.314636 550.931885,821.981567 554.981445,829.624756 
	C552.214600,831.384705 551.375916,829.016479 550.640564,827.401306 
	C549.353455,824.574036 547.731567,821.815430 547.244507,818.298462 
	C547.002441,818.000000 546.997192,817.504883 546.996155,817.257324 
	C545.791260,816.131775 546.238403,814.537170 545.233948,813.236328 
	C545.171143,810.984314 544.803589,809.135498 543.168213,807.314209 
	C541.557861,805.072205 541.289124,802.688660 540.246033,800.253418 
	C540.007141,799.551208 540.002075,799.110657 539.993652,798.339722 
	C537.523071,796.064270 539.437256,792.660034 537.253418,790.266479 
	C537.004883,789.995117 536.999756,789.499451 536.997437,789.251587 
	C535.165833,787.172241 536.666504,784.559448 535.248413,782.284363 
	C535.003906,781.553040 534.998047,781.112854 534.988770,780.342468 
	C533.774109,777.214233 533.270447,774.114136 531.204834,771.295410 
	C531.013489,770.550598 531.012451,770.109619 531.010620,769.338013 
	C531.522705,768.577393 531.668823,768.049988 531.220581,767.207031 
	C531.009766,766.993408 531.004456,766.497925 531.000977,766.250183 
	C529.290955,762.413086 529.191101,758.461365 528.218994,754.329651 
	C527.999512,754.002441 528.000000,754.000000 527.998779,754.000183 
	C526.842651,752.424805 526.228943,750.645508 526.002563,748.358643 
	C525.994019,746.934387 525.992432,745.869263 525.992981,744.404785 
	C529.606018,742.636353 529.166443,746.595215 531.113220,747.988159 
	C531.473938,748.453247 531.602844,748.650391 531.882812,749.165344 
	C533.392090,754.965332 533.734070,760.703125 536.410950,766.164917 
	C536.969727,766.768372 537.259155,767.142639 537.706787,767.851196 
	C538.077515,768.619507 538.218079,769.085999 538.352661,769.931396 
	C538.363708,772.978516 538.504456,775.631958 539.878052,778.440063 
	C540.171204,779.253113 540.281128,779.733643 540.385742,780.590454 
	C541.134399,787.752075 543.984436,793.826660 547.065674,800.190674 
	C547.443726,800.698120 547.564331,800.924866 547.766785,801.530884 
	C547.860168,802.439575 547.743042,802.938843 547.240662,803.701782 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M531.316284,748.729614 
	C527.961243,748.799683 528.858459,745.095520 526.331848,744.072388 
	C524.997253,743.503296 524.277954,742.777588 524.049561,741.311279 
	C523.869873,740.816895 523.727783,740.639160 523.591919,740.120117 
	C524.780945,737.797058 524.520508,735.976013 522.812012,733.977783 
	C520.877502,730.238220 518.721008,726.932068 519.507446,722.390381 
	C519.710754,718.826111 519.064880,715.963501 517.674500,712.976501 
	C515.040894,707.318542 513.135315,701.319946 512.015930,695.146667 
	C511.495087,692.273987 509.489685,690.181519 508.573151,687.522705 
	C507.228333,683.621460 506.160461,679.670654 505.246216,675.301636 
	C505.002441,674.997559 504.997009,674.501709 504.994843,674.253784 
	C504.194611,673.088684 503.981323,672.025574 504.386505,670.479614 
	C507.923187,668.347900 509.214264,670.889282 510.019592,673.155701 
	C512.550049,680.277588 514.665833,687.544434 516.790710,694.796997 
	C517.717163,697.959290 518.399048,701.168701 519.862122,704.160828 
	C524.969482,714.606140 526.932800,726.080139 530.597473,737.401550 
	C530.628845,738.838135 531.020508,739.748352 531.788208,740.937744 
	C532.160706,741.779663 532.288696,742.291443 532.385620,743.224915 
	C532.457520,745.287354 532.652466,746.970764 531.316284,748.729614 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M611.334778,831.287720 
	C614.425659,826.290222 620.060730,826.443115 624.778015,825.665955 
	C636.317688,823.764709 647.800293,821.831421 658.786194,817.737793 
	C661.269714,816.812439 663.676636,817.212585 666.554688,816.990845 
	C665.569336,821.126770 661.067139,820.995117 658.162659,822.322205 
	C653.982666,824.232056 649.326721,825.983643 644.010803,825.335266 
	C640.253052,824.877075 636.425415,827.684021 632.430054,828.632141 
	C626.931335,829.937134 621.445068,830.511292 615.448975,831.010620 
	C614.002563,831.831421 612.901672,832.076599 611.334778,831.287720 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M520.746399,721.707397 
	C520.241089,726.206116 522.091553,729.727905 523.958679,733.624756 
	C525.050720,735.661987 525.237732,737.419495 524.291016,739.650024 
	C524.038330,740.034058 524.030457,740.514526 524.021179,740.754639 
	C522.560547,740.862610 521.168335,740.198364 521.208862,738.725952 
	C521.312012,734.977295 519.055481,731.860596 518.487915,728.606506 
	C517.610107,723.573914 515.027832,718.987244 514.994507,713.416748 
	C514.990234,713.009766 514.999146,713.000732 515.002014,712.999084 
	C517.865662,712.161377 518.647339,714.210815 519.407104,716.216187 
	C520.038391,717.882385 519.759949,719.754028 520.746399,721.707397 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M576.695557,838.769043 
	C583.834351,839.600769 589.413391,835.017456 595.974609,834.457092 
	C598.077881,834.277466 600.136536,833.574707 602.609009,833.058105 
	C604.354309,836.550720 600.707886,835.341492 599.310181,836.783569 
	C591.210999,838.598633 583.422363,840.213440 575.615112,841.731995 
	C575.209290,841.811035 574.643799,841.069214 574.068665,840.361450 
	C574.395264,838.893372 575.180786,838.385620 576.695557,838.769043 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M546.952271,818.360596 
	C549.578186,818.464355 549.296875,820.864868 549.972412,822.541504 
	C551.051086,825.218811 551.538879,828.200989 554.647827,829.932983 
	C555.172974,832.264832 556.906189,833.393372 558.710144,834.839661 
	C559.600342,835.684143 559.767151,836.464478 559.245422,837.672241 
	C553.292053,836.060608 545.696350,823.944397 546.952271,818.360596 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M667.430908,816.997437 
	C667.319458,814.401672 669.702271,814.890198 671.208435,814.453552 
	C675.815063,813.118347 680.521729,812.128174 685.606201,811.014038 
	C685.772156,813.025452 684.589050,814.802612 682.741577,814.751953 
	C677.608276,814.611145 673.039490,817.776978 667.430908,816.997437 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M558.996216,838.003784 
	C558.995300,837.254395 558.997925,836.501343 559.001465,835.371704 
	C565.009705,835.802490 570.726013,838.169617 576.934570,838.657776 
	C576.200867,839.242371 575.387390,839.494568 574.279297,839.880005 
	C572.564758,840.309509 571.144897,840.605774 569.362488,840.947327 
	C565.924683,839.947021 562.568909,839.926025 559.306152,838.216187 
	C558.994263,838.006958 558.999756,838.000000 558.996216,838.003784 
z"/>
<path fill="#AFAEB0" opacity="1.000000" stroke="none" 
	d="
M599.402649,836.991211 
	C599.512329,834.983704 602.195557,835.477112 602.938843,833.329651 
	C603.442261,832.998474 603.882080,832.992126 604.651794,832.982788 
	C605.644897,832.130249 606.763000,832.051392 607.798767,831.246765 
	C608.767273,831.030273 609.510010,831.017456 610.623901,831.001099 
	C612.064636,830.999207 613.134216,831.000793 614.604858,831.007324 
	C613.200378,834.160278 610.076355,834.689575 606.884827,835.246948 
	C604.495850,835.664062 602.160889,836.390137 599.402649,836.991211 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M527.988525,754.387756 
	C531.381348,757.070740 530.800598,761.207458 530.999146,765.567993 
	C527.550110,762.915771 526.713379,759.126892 527.988525,754.387756 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M530.878357,771.296509 
	C535.535339,772.166504 534.861755,775.868164 534.990601,779.594604 
	C532.839722,777.577637 530.557007,775.212097 530.878357,771.296509 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M537.003052,790.420288 
	C541.530457,790.908752 539.510681,794.556885 539.993774,797.583618 
	C535.474792,797.089722 537.485901,793.446899 537.003052,790.420288 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M559.171509,838.313232 
	C562.175415,839.136963 565.852417,838.105408 568.899658,840.624451 
	C565.203247,842.559082 562.379028,840.170105 559.171509,838.313232 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M540.007446,800.383179 
	C543.056702,801.083130 542.615356,803.847168 542.998901,806.607788 
	C540.325928,805.730957 539.754028,803.450256 540.007446,800.383179 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M535.006348,782.415955 
	C538.448242,783.152649 536.442749,786.069580 536.997742,788.581421 
	C533.553711,787.846191 535.557251,784.929138 535.006348,782.415955 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M525.752197,748.235596 
	C527.337830,749.497253 527.688049,751.369751 527.990601,753.631592 
	C525.355652,752.973328 525.073730,750.883972 525.752197,748.235596 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M545.004883,813.370239 
	C547.027893,813.260803 546.994385,814.773621 546.995544,816.637451 
	C544.976685,816.746338 544.998413,815.238770 545.004883,813.370239 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M531.005737,767.329041 
	C531.637024,767.102905 532.387756,767.174377 532.424927,767.957581 
	C532.439270,768.260376 531.875977,768.590576 531.292786,768.957764 
	C531.007080,768.559753 531.004395,768.112183 531.005737,767.329041 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M505.257629,669.653625 
	C505.024597,671.041443 505.012756,672.119324 504.996796,673.601562 
	C502.365692,673.636719 503.169434,671.171021 502.411530,669.682312 
	C501.606964,668.101929 501.675476,666.031494 499.363098,665.064209 
	C499.011963,664.545349 499.008087,664.103882 499.002106,663.331177 
	C498.471466,660.420471 500.213409,657.478088 497.382172,655.102295 
	C497.452545,652.562561 494.420197,651.246887 494.998993,648.384460 
	C494.139557,647.143982 493.286743,646.283203 492.217163,645.210815 
	C492.002014,644.997559 492.000000,645.000000 492.001221,644.998779 
	C491.933136,641.245972 492.472443,637.366699 489.371826,634.079834 
	C489.010681,633.543518 489.009125,633.103333 489.006744,632.332764 
	C487.869110,629.050720 485.576691,626.978882 482.972809,625.620850 
	C476.781006,622.391602 471.986389,617.630676 467.204254,612.274658 
	C466.504974,611.741150 466.011200,611.482239 465.263000,611.109253 
	C464.279449,605.132690 459.121704,602.381348 455.625336,598.743591 
	C453.423889,596.453125 450.787079,594.541809 449.060425,591.349731 
	C449.761688,590.117249 450.759949,589.751282 452.316986,589.774780 
	C452.976837,589.875732 453.233185,589.945312 453.842468,590.188232 
	C462.149719,596.655884 468.787109,604.352600 475.753784,611.611877 
	C479.912079,615.944763 484.009155,619.944397 488.987000,623.304443 
	C491.994995,625.334900 493.471771,629.013428 494.473877,632.609558 
	C495.852386,637.556702 497.418793,642.450623 498.565704,647.463989 
	C499.174194,650.123779 500.789612,652.258118 502.717346,654.621948 
	C503.302917,655.905884 503.462402,656.907227 503.503143,658.304810 
	C503.337830,661.679077 503.888000,664.519287 505.531738,667.496094 
	C505.724854,668.365906 505.666901,668.862366 505.257629,669.653625 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M520.992737,721.580505 
	C518.372131,721.620483 519.054688,719.196960 518.447693,717.660400 
	C517.784729,715.982361 517.769714,714.013611 515.329346,713.151184 
	C514.591248,705.002930 511.685486,697.695129 508.277405,690.657227 
	C505.966827,685.885742 505.390045,680.948608 505.000549,675.431641 
	C508.402435,677.779785 507.877655,682.269043 509.626373,685.830688 
	C514.315002,695.380310 514.903320,706.495544 520.579163,715.792969 
	C521.411316,717.156128 520.892212,719.344238 520.992737,721.580505 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M552.698914,806.259338 
	C556.347900,806.832825 556.043518,810.021790 556.876282,812.325867 
	C558.155273,815.864319 558.967102,819.571655 559.989624,823.603638 
	C559.289978,824.384949 558.643372,824.170654 557.755371,823.545959 
	C556.918884,822.566650 556.588562,821.704468 556.161865,820.513123 
	C555.744324,819.081543 555.030579,818.190002 554.328003,816.899414 
	C554.079956,815.855957 554.150269,815.170715 554.751648,814.259583 
	C555.868042,812.345886 554.441956,812.397095 553.287964,812.018921 
	C551.951111,810.895935 551.984741,809.472595 551.917358,807.690674 
	C552.123108,807.029968 552.251892,806.781555 552.698914,806.259338 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M552.749390,806.709595 
	C552.998413,808.389160 553.004150,809.774109 553.011047,811.574158 
	C549.727234,810.519104 550.058899,806.424194 547.249634,804.205566 
	C546.986938,803.551575 546.990356,803.107788 547.000549,802.331055 
	C551.303589,800.403748 551.308716,804.151489 552.749390,806.709595 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M557.710205,823.314453 
	C558.496521,823.259949 558.992981,823.517456 559.745850,823.887451 
	C560.305481,824.127991 560.557800,824.322205 560.879517,824.791321 
	C561.996155,825.887390 561.997070,827.149902 562.140259,828.662964 
	C562.154724,829.303894 562.342529,829.550903 562.795593,829.869263 
	C562.997437,830.162170 562.997559,830.335388 562.995117,830.768677 
	C561.629272,830.836975 559.979980,831.468567 559.080811,829.351807 
	C557.705017,827.436401 556.532043,825.833679 557.710205,823.314453 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M557.994812,822.668213 
	C557.833496,824.830444 558.278320,826.554077 558.946533,828.626099 
	C555.207825,827.467407 555.641663,823.871643 555.208435,820.194092 
	C555.804626,819.457886 556.192200,819.447205 556.788208,819.789307 
	C557.810486,820.574707 557.980286,821.420105 557.994812,822.668213 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M554.622070,814.079895 
	C554.986206,814.737183 554.996033,815.489075 555.006592,816.616760 
	C555.578369,817.681885 555.743896,818.452637 555.237549,819.654297 
	C555.002441,819.997559 554.999695,820.000305 554.996216,820.003723 
	C551.784729,818.431580 552.545837,816.362732 554.622070,814.079895 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M607.674805,831.038330 
	C607.848877,832.423218 607.005005,832.992798 605.330078,832.978333 
	C605.145264,831.587524 605.983032,831.007019 607.674805,831.038330 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M505.389832,670.027161 
	C505.032715,669.528992 505.028992,669.094421 505.026306,668.333435 
	C504.865692,664.963989 504.095581,662.101807 502.260071,659.229492 
	C502.025513,657.913147 502.024658,656.854248 502.024017,655.398193 
	C499.069061,650.291382 498.189758,644.831299 496.759766,639.623535 
	C494.572174,631.656433 490.900940,624.992615 485.012665,618.907715 
	C479.954712,613.680786 474.055084,609.373047 469.405518,603.700073 
	C465.319061,598.714233 459.204773,595.920227 454.262878,591.247009 
	C454.001221,591.000000 453.515564,590.981079 453.272644,590.975464 
	C452.434998,590.277710 451.570343,590.032532 450.546997,589.414307 
	C448.636932,587.557312 448.121552,584.750549 444.966858,584.194092 
	C444.433594,583.981140 444.226318,583.877625 443.732330,583.573120 
	C436.251892,577.814636 430.118652,571.082642 423.254883,565.150696 
	C417.168793,559.890808 410.208923,555.340820 406.856995,547.087769 
	C406.500000,546.621704 406.370911,546.424805 406.083893,545.912537 
	C404.231659,540.864624 401.671478,536.569885 398.667480,532.614380 
	C394.262024,526.813721 390.509918,525.953308 383.031311,528.580078 
	C382.162598,528.723022 381.669373,528.644043 380.898193,528.194458 
	C380.213531,527.196716 380.190857,526.440674 380.760529,525.354614 
	C385.364899,521.756653 398.260651,522.665710 401.832428,526.362610 
	C403.516632,528.105896 402.938263,530.412903 403.819580,532.393066 
	C406.362030,538.105408 410.554413,542.884766 412.648560,548.851685 
	C413.946533,552.549988 417.418030,555.033447 420.224274,557.158142 
	C429.478699,564.165039 436.864655,573.562683 447.052246,579.171265 
	C455.811188,583.993347 462.697845,591.271729 471.365356,596.133667 
	C473.488861,597.324707 474.634247,600.143799 476.497162,601.935974 
	C483.587830,608.757507 490.952423,615.310120 496.853577,623.696838 
	C497.926208,625.436157 498.650879,626.991394 499.837555,628.703979 
	C500.176544,629.522827 500.295624,630.015076 500.395844,630.903503 
	C500.581177,638.009705 504.107971,643.788208 505.749298,650.103821 
	C507.953217,658.583984 512.188538,666.488159 513.875183,675.076477 
	C514.855042,680.065979 516.511353,684.532654 519.131042,689.198364 
	C519.335754,689.811523 519.367004,690.072021 519.195679,690.669922 
	C518.287781,698.609619 521.436157,705.079163 524.885254,711.506714 
	C527.067505,715.573364 526.425537,720.360779 528.436646,724.698669 
	C531.303894,730.883240 532.808533,737.699524 534.945129,744.623901 
	C533.082764,746.322632 532.373779,744.758484 531.269653,743.271118 
	C531.016052,742.549622 531.008911,742.107544 530.999634,741.333984 
	C530.459351,740.479919 529.943848,739.935059 529.228333,739.192627 
	C526.415283,727.083740 523.630737,715.176636 518.316406,704.164551 
	C515.274597,697.861328 514.726624,690.921021 512.008789,684.573120 
	C509.938873,679.738464 510.445160,673.804565 505.389832,670.027161 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M519.244873,691.002991 
	C518.993225,691.007324 518.979126,690.514709 518.975037,690.268311 
	C517.740417,688.838806 517.559143,687.297607 517.486450,685.384399 
	C517.170959,683.294739 516.495361,681.700073 515.210327,679.998779 
	C514.362366,677.693420 514.156555,675.573303 513.518555,673.198853 
	C513.527527,671.827942 513.050049,671.021118 512.288208,669.931396 
	C512.003540,668.593628 511.761658,667.613586 511.616882,666.224731 
	C511.632751,665.037048 511.765717,664.264221 512.373291,663.227234 
	C515.053528,661.643066 516.301147,663.099792 517.127441,665.051147 
	C517.698242,666.399048 518.043701,667.835693 519.302124,669.269165 
	C519.766479,670.061584 519.935547,670.554871 520.083679,671.454712 
	C520.329834,679.838196 523.976440,686.961731 526.065735,694.467957 
	C527.144531,698.343567 527.899109,702.516724 530.717896,706.184082 
	C531.098816,706.979553 531.251831,707.457947 531.406860,708.320923 
	C532.480225,711.928101 532.491699,715.517029 534.998535,718.633789 
	C537.143799,726.489319 540.826294,733.449158 542.490479,741.465576 
	C544.127686,751.666931 547.591919,761.001099 550.060364,770.595825 
	C552.647705,780.652893 554.896484,790.866882 559.445129,800.322876 
	C562.163879,805.974915 564.512390,811.736694 565.930481,817.893066 
	C566.364624,819.777893 568.358582,821.015381 569.962585,822.193054 
	C571.456177,823.289612 573.010986,824.253235 573.146240,826.672913 
	C568.170166,829.433167 566.167542,825.702942 563.907837,822.734253 
	C560.130432,817.771790 559.812195,811.378784 557.134827,805.960571 
	C554.261780,800.146362 552.875000,793.840149 550.775696,787.778442 
	C544.450867,769.515076 541.299072,750.320923 534.205872,732.318665 
	C529.763611,721.044556 525.813110,709.628540 522.981445,697.425781 
	C522.454712,696.470459 521.933533,695.928833 521.222656,695.178833 
	C521.028870,694.534302 521.026733,694.096436 521.022827,693.330200 
	C520.991333,692.404968 520.801819,691.869568 520.204773,691.206116 
	C519.832214,690.999573 519.664368,690.999084 519.244873,691.002991 
z"/>
<path fill="#5B5E5E" opacity="1.000000" stroke="none" 
	d="
M573.409424,827.036621 
	C565.503296,821.972168 563.403442,813.967834 560.509033,805.976868 
	C557.786377,798.460144 553.443970,791.435913 551.794373,783.517822 
	C548.923340,769.736938 543.973267,756.527771 541.023438,742.384766 
	C544.000549,740.688721 544.794983,742.875916 545.557373,744.922485 
	C546.651306,747.858826 547.397461,750.928040 548.210693,753.932190 
	C552.304993,769.057068 556.598267,784.109497 561.738281,798.920776 
	C563.198914,803.129639 565.657532,806.945068 566.735352,811.696777 
	C566.989441,812.448608 567.000427,812.884705 567.017090,813.648010 
	C568.576904,816.123352 568.019775,819.679565 571.714111,820.793884 
	C571.995117,821.000000 572.000000,821.000000 572.000732,821.002441 
	C572.612366,821.732849 573.425720,821.982910 574.668457,822.004639 
	C575.436707,822.022888 575.876221,822.031677 576.645264,822.050049 
	C578.390442,823.704651 581.198547,822.152283 582.400940,824.788940 
	C581.638611,827.670349 579.486511,826.764648 577.395142,827.026917 
	C575.936279,827.036377 574.872192,827.033142 573.409424,827.036621 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M552.998047,806.749512 
	C550.177490,806.501282 550.770508,802.327332 547.355896,802.046448 
	C547.007324,801.998230 546.981018,801.510925 546.972534,801.267090 
	C546.831665,800.877441 546.699219,800.731567 546.505249,800.296204 
	C546.427551,799.209045 546.348816,798.422791 545.896545,797.378296 
	C545.091309,794.572327 543.567749,792.327881 543.460693,789.261108 
	C543.039978,786.829956 542.023621,784.697998 544.575684,782.984802 
	C547.601196,790.415833 551.980164,797.191345 553.000305,805.585205 
	C553.012512,806.155273 553.007996,806.325073 552.998047,806.749512 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M537.283691,770.220947 
	C537.018127,769.531372 536.998901,769.096863 536.966919,768.336426 
	C536.840881,763.553467 537.208069,758.966370 533.364624,755.152222 
	C532.652222,753.368652 532.359070,751.845825 532.063110,749.911621 
	C532.104797,748.013611 532.350403,746.666443 534.625122,746.784607 
	C539.579224,754.043945 539.520020,762.493774 541.991821,770.590210 
	C541.230103,771.780701 540.314819,771.814575 539.003235,771.443481 
	C538.255005,771.085510 537.875916,770.803162 537.283691,770.220947 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M577.308777,827.302856 
	C578.120239,825.409973 580.172058,826.473999 581.752136,825.222839 
	C582.667786,824.362976 583.441040,824.254639 584.631836,824.738159 
	C586.697571,824.975586 588.418152,824.986694 590.568726,825.000122 
	C590.740967,825.498901 590.483276,825.995361 590.110352,826.747131 
	C585.914612,828.189331 581.791382,828.437500 577.308777,827.302856 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M544.954468,782.713013 
	C543.106323,784.733826 544.526611,786.518127 544.932373,788.645874 
	C539.267212,789.137207 541.182129,785.008301 541.311768,781.626587 
	C542.013855,780.799072 542.559814,780.575500 543.593872,780.787598 
	C544.304626,781.476624 544.616516,781.948364 544.954468,782.713013 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M534.996582,746.665039 
	C533.601624,747.136780 533.014771,747.964783 533.011475,749.647583 
	C532.287109,750.428101 531.824036,750.031433 531.198608,749.255005 
	C530.997559,749.002441 531.000000,749.000000 530.998779,749.001221 
	C531.002747,747.285156 531.007996,745.567871 531.018188,743.421143 
	C532.132568,743.622253 533.241943,744.252930 534.673218,744.942627 
	C534.996887,745.445251 534.998657,745.888855 534.996582,746.665039 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M543.996338,780.582764 
	C543.549377,781.004944 543.106018,781.004944 542.051392,780.782166 
	C540.858582,779.748535 540.710815,778.812195 540.648499,777.491394 
	C540.645569,776.406799 540.796448,775.709778 541.542114,774.515259 
	C545.441223,775.158691 543.442383,778.071106 543.996338,780.582764 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M538.746582,770.396851 
	C539.794067,770.308777 540.575989,770.622437 541.672852,770.969360 
	C541.993958,771.750244 542.000122,772.497803 542.010376,773.619019 
	C542.014526,773.992676 541.981323,774.009277 541.965576,774.018860 
	C538.655090,775.248596 537.924927,773.714294 538.746582,770.396851 
z"/>
<path fill="#828689" opacity="1.000000" stroke="none" 
	d="
M562.269165,828.768555 
	C561.690247,827.906189 561.349976,826.803833 561.004883,825.350708 
	C563.218811,825.442810 563.265442,826.813843 562.269165,828.768555 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M520.030029,691.294434 
	C520.877197,691.192749 521.958984,691.207397 521.221375,692.817017 
	C520.700806,692.530823 520.380371,692.059875 520.030029,691.294434 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M521.072754,695.287231 
	C521.907043,695.106201 522.657593,695.404175 522.927734,696.701965 
	C522.089539,696.890930 521.337769,696.594055 521.072754,695.287231 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M585.076477,824.612854 
	C584.231201,824.969666 583.485535,824.974915 582.366333,824.973511 
	C580.845398,823.212524 578.108276,824.902649 577.063599,822.396362 
	C581.360840,821.706543 585.746826,821.353455 590.570557,821.009399 
	C590.439880,824.573486 586.269653,821.650940 585.076477,824.612854 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M593.282349,820.931519 
	C593.094177,820.083191 593.398926,819.334167 594.715210,819.091370 
	C594.896973,819.939270 594.583008,820.679016 593.282349,820.931519 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M692.971558,549.330566 
	C692.048767,544.042358 687.102234,541.889465 685.048401,537.337158 
	C691.250732,540.506714 696.503967,545.284119 700.036377,551.390625 
	C704.887817,559.777405 708.825806,568.667725 710.946289,578.624268 
	C709.968201,578.897095 708.700500,578.616760 708.541199,577.630920 
	C707.363708,570.342102 702.338623,564.704102 700.212036,557.861145 
	C699.458130,555.435486 699.328369,552.827515 695.478516,552.956543 
	C693.609314,552.357727 693.078430,551.143372 692.971558,549.330566 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M678.956604,533.638672 
	C675.262817,533.922485 672.691406,531.529602 669.789551,529.604431 
	C666.421448,527.369934 662.714966,525.645386 659.072205,523.356567 
	C666.973450,523.829163 672.956543,528.524353 678.956604,533.638672 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M652.935303,522.595154 
	C646.555420,522.468567 640.074463,521.985596 633.295349,521.208008 
	C639.902466,516.970215 646.354980,519.805115 652.935303,522.595154 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M628.774963,520.731201 
	C627.140076,524.210632 623.981934,522.675354 621.349976,522.954956 
	C619.546692,523.146484 617.705566,522.982422 615.414917,522.950195 
	C617.247620,519.180054 621.272400,521.787964 624.284119,520.445984 
	C625.462585,519.920837 627.114868,520.459229 628.774963,520.731201 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M722.977905,610.614868 
	C718.683411,608.262451 719.140564,603.665283 718.750610,599.246338 
	C721.217712,602.418884 722.809998,606.060486 722.977905,610.614868 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M730.971558,633.646118 
	C727.015076,633.053467 727.050354,629.896667 727.013428,626.382935 
	C729.903381,627.562256 729.780029,630.771851 730.971558,633.646118 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M734.984375,644.619202 
	C731.146362,643.929016 732.466064,640.112671 731.093140,637.329468 
	C735.211243,637.849426 733.829895,641.719727 734.984375,644.619202 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M726.014893,621.577393 
	C723.275513,620.742249 722.784363,618.419189 723.040649,615.351440 
	C726.949463,615.645020 725.759949,618.750000 726.014893,621.577393 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M713.006470,584.605713 
	C710.375488,584.509338 711.172180,582.410339 711.021729,580.368225 
	C713.613159,580.491943 712.852600,582.567139 713.006470,584.605713 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M683.977905,536.631348 
	C682.425293,537.046204 681.075073,536.780640 680.992065,534.393555 
	C682.499939,534.079529 683.552490,534.746826 683.977905,536.631348 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M715.003845,589.626587 
	C712.960266,589.742554 713.012390,588.216431 713.020935,586.349365 
	C715.026062,586.264160 715.009888,587.764160 715.003845,589.626587 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M716.970337,593.676636 
	C715.579163,593.854858 715.008179,593.008362 715.022644,591.329529 
	C716.422485,591.140198 717.000732,591.982300 716.970337,593.676636 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M692.642334,548.983887 
	C694.088745,549.838440 694.616821,551.017883 695.037598,552.618103 
	C697.463379,559.766418 699.935913,566.559204 704.048584,572.570557 
	C705.416626,574.570129 705.324585,576.929993 706.206665,579.082153 
	C710.692932,590.027893 715.073364,601.050232 718.733154,612.292297 
	C722.527344,623.947388 726.369629,635.552246 731.159302,646.843750 
	C732.425476,649.828796 733.070801,653.077209 733.995728,656.603394 
	C732.562500,658.081482 731.593994,657.155762 730.376526,655.935486 
	C729.147400,654.157349 728.309143,652.605164 727.721313,650.533691 
	C726.814453,645.815369 724.795166,641.915527 722.695190,637.681946 
	C722.345642,637.199158 722.225891,636.992493 721.975830,636.453369 
	C721.187561,634.177063 722.181091,631.897888 720.398560,629.873779 
	C717.918213,627.469727 717.204895,624.338074 715.011780,621.731262 
	C713.601501,620.007690 713.524658,618.259033 713.445679,616.155640 
	C714.017395,611.870972 711.106750,608.952515 710.282043,605.062439 
	C709.021667,601.397827 709.792236,597.383606 706.382812,594.476440 
	C702.488159,582.744263 698.302795,571.511658 693.755493,560.406799 
	C692.984619,558.524292 691.090576,558.003906 689.905273,556.202637 
	C687.637939,551.721375 684.622314,548.594360 679.711914,547.077759 
	C679.188477,546.835938 678.987183,546.721008 678.515686,546.386353 
	C676.389771,543.095215 672.636414,542.228455 670.246277,539.252380 
	C669.562500,539.004089 669.124329,538.995911 668.357544,538.986572 
	C667.364563,538.131958 666.232788,538.050781 665.223633,537.195312 
	C659.786804,532.716309 653.844116,530.872192 647.188232,529.364441 
	C640.194397,527.780151 633.635071,529.532166 626.956604,528.850159 
	C623.071228,528.453308 619.450623,528.999878 615.379211,529.934082 
	C614.784241,527.449768 615.563660,526.373779 618.464355,525.862244 
	C627.354431,524.294373 636.278137,525.056458 645.144958,525.152222 
	C654.117126,525.249207 662.227478,529.138733 669.679077,533.951599 
	C671.459412,535.101562 673.084290,536.397217 675.092163,537.238647 
	C678.871460,538.822388 682.940063,539.688660 685.023560,544.238403 
	C686.170349,546.742615 689.144775,548.468018 692.642334,548.983887 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M730.535583,655.047485 
	C731.794250,655.635071 732.586060,656.270142 733.684082,656.953613 
	C733.994629,658.384583 733.998962,659.767151 733.994385,661.563721 
	C733.353027,662.129578 732.720520,662.281372 731.762207,662.446472 
	C728.673889,660.433167 728.437561,657.937500 730.535583,655.047485 
z"/>
<path fill="#C5C4C3" opacity="1.000000" stroke="none" 
	d="
M699.248474,596.253296 
	C699.002197,594.283142 698.999573,592.571167 698.995300,590.431335 
	C696.323486,585.826599 695.916809,580.569763 692.986206,576.606201 
	C688.800110,570.944580 688.503967,563.089783 682.275757,558.246460 
	C682.004883,557.995117 682.000000,558.000000 682.002441,557.997559 
	C680.803345,556.906250 679.689758,555.782349 680.498230,553.564270 
	C680.869202,552.728394 681.250000,552.405457 682.120361,552.154785 
	C682.734314,552.099182 682.979919,552.114807 683.573486,552.239868 
	C685.491821,553.706848 687.048767,555.082092 689.122314,556.348145 
	C689.513000,556.769592 689.649841,556.954651 689.945557,557.444458 
	C690.519958,560.451111 692.259033,562.047791 694.878357,563.364258 
	C697.075256,566.528503 697.212402,570.066589 698.469849,573.606750 
	C698.533752,574.966614 699.039001,575.768494 699.739746,576.881104 
	C700.292175,578.110840 700.470520,579.061951 700.527954,580.405640 
	C703.487244,587.149597 703.524353,594.471436 707.239685,600.948608 
	C707.598755,601.420166 707.727173,601.618286 708.009399,602.131714 
	C709.028198,604.459229 708.960266,606.930420 711.248413,608.622864 
	C711.645142,609.321838 711.800171,609.752869 711.934204,610.544556 
	C711.910950,615.838501 713.370361,620.371643 716.067139,624.813171 
	C716.743042,625.263184 717.061768,625.598755 717.484619,626.296936 
	C717.927429,627.542603 717.707275,628.460449 717.590332,629.722534 
	C718.453308,633.160889 720.335327,635.839294 721.401367,639.214722 
	C721.709839,639.702332 721.821289,639.905151 722.070068,640.426697 
	C722.415100,641.915588 723.051025,642.877930 723.869202,644.155884 
	C725.092957,648.451904 726.416992,652.343445 727.483154,656.320435 
	C728.818787,661.302368 728.587524,665.764038 725.509155,669.963318 
	C724.995117,670.664429 724.746887,671.411987 725.014282,672.648193 
	C724.812195,673.540161 724.570190,674.060974 724.208923,674.843872 
	C723.380005,675.538208 722.945557,676.270325 722.045715,676.934265 
	C721.106445,677.500305 720.549255,677.998779 720.429199,679.165344 
	C720.350708,679.903625 720.273071,680.328369 720.061401,681.076477 
	C719.542908,682.300659 718.917969,682.992493 717.670532,683.524658 
	C717.072083,683.603088 716.827698,683.585266 716.255371,683.401611 
	C715.817322,683.016846 715.707214,682.798096 715.777527,682.259216 
	C716.031372,678.739258 717.195374,676.090515 718.972656,673.332642 
	C722.419739,667.983765 725.658691,662.284851 721.276367,655.710266 
	C720.801147,654.997314 721.074219,653.785706 720.997681,652.403809 
	C719.559326,648.490662 718.001465,645.032532 716.212524,641.293335 
	C714.662048,638.259277 715.251831,634.670166 712.154419,632.313843 
	C710.679504,628.096252 707.734497,624.829285 707.242554,620.250000 
	C707.004089,619.244995 706.999573,618.498657 706.992676,617.379028 
	C704.772400,613.112793 705.225098,608.259277 702.258118,604.269775 
	C702.007324,603.994080 702.003601,603.497070 702.000610,603.248535 
	C700.070862,601.258606 701.135437,598.362244 699.248474,596.253296 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M725.310791,673.065063 
	C722.849426,672.658508 722.580627,671.265686 723.815979,669.845154 
	C729.663879,663.120117 725.501526,656.320251 723.946045,649.627869 
	C723.576660,648.038818 722.999512,646.505981 722.984131,644.428833 
	C722.875061,643.793274 722.769714,643.569946 722.871704,643.039917 
	C727.117859,643.174377 727.070190,646.917542 728.750061,649.736572 
	C729.628662,651.451233 730.269531,652.886292 730.956421,654.660645 
	C729.673096,657.246643 731.567688,659.401062 731.314087,662.040100 
	C731.436340,662.459778 731.436340,662.535889 731.452881,662.570190 
	C731.013123,663.495178 730.556885,664.385864 730.060486,665.647034 
	C730.028076,666.491943 730.035706,666.966431 730.107849,667.793518 
	C730.172363,668.146118 730.087708,668.575684 730.044312,668.790283 
	C729.009521,670.388000 727.112549,670.841553 726.243774,672.789062 
	C725.913513,673.080933 725.740417,673.095825 725.310791,673.065063 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M720.799683,676.236328 
	C721.827942,675.692017 722.630371,675.367126 723.761230,675.074097 
	C721.456116,685.178955 718.521790,695.136047 712.587280,704.177429 
	C711.774475,704.045288 711.460327,703.670776 711.253540,702.790161 
	C711.642700,699.775879 713.130310,697.452332 713.557800,694.442139 
	C713.680054,692.912354 714.093384,691.826599 714.634155,690.429565 
	C715.033630,689.302612 715.518677,688.553284 715.518799,687.325195 
	C715.581848,685.729309 715.037964,684.296997 716.766235,683.219849 
	C717.618347,682.538513 718.189941,682.034363 718.888855,681.269043 
	C719.910034,680.276367 720.011475,679.261353 720.043945,677.900696 
	C720.156677,677.175232 720.328613,676.799255 720.799683,676.236328 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M704.310303,716.901184 
	C703.292603,717.026550 702.532959,717.017761 701.394043,717.014160 
	C700.359863,716.349609 700.248169,715.565979 700.766724,714.354980 
	C701.004883,714.009766 701.000366,714.002319 701.000183,713.999939 
	C701.841003,712.854126 702.303589,711.227173 704.569092,711.607361 
	C705.796021,712.431274 705.830139,713.374084 705.862183,714.683228 
	C705.542053,715.588257 705.055298,716.177612 704.310303,716.901184 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M711.813110,704.838745 
	C712.004517,708.118286 711.809509,711.192810 707.659668,712.266113 
	C707.072998,712.176025 706.851196,712.038818 706.456055,711.550659 
	C706.113037,710.408203 706.231689,709.656677 706.814697,708.628113 
	C708.660706,707.274780 709.204529,704.909790 711.813110,704.838745 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M712.169556,704.828979 
	C709.991882,705.411743 710.490295,708.174805 708.356689,708.946899 
	C707.457336,708.560425 707.154968,707.962341 707.092102,706.894165 
	C707.159851,706.319397 707.214294,706.093201 707.408142,705.547607 
	C708.350464,704.107117 708.994629,702.857239 710.459229,701.865662 
	C710.998230,701.694702 711.228577,701.681030 711.747437,701.858521 
	C712.056580,702.548096 712.077271,703.046448 712.172729,703.915955 
	C712.247375,704.287109 712.200806,704.649170 712.169556,704.828979 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M726.394348,672.939758 
	C725.680725,670.558655 727.249878,669.627014 729.640625,669.034180 
	C730.037354,671.258301 728.219482,671.905762 726.394348,672.939758 
z"/>
<path fill="#A09FA0" opacity="1.000000" stroke="none" 
	d="
M706.716797,710.869812 
	C707.049744,711.247131 707.082336,711.462830 707.204895,711.995850 
	C706.989380,713.044189 706.683838,713.775024 706.203613,714.752441 
	C705.149658,714.457825 705.021851,713.587036 705.129395,712.015076 
	C705.577087,711.031067 705.964844,710.812805 706.716797,710.869812 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M680.750244,552.812256 
	C681.299377,554.426758 681.603638,555.848572 681.956360,557.632812 
	C673.557556,556.033875 666.278381,551.407166 658.599670,547.699097 
	C657.016418,546.934570 656.983337,545.312561 657.001099,543.390015 
	C659.726501,543.600037 662.521973,543.985840 665.162964,544.845825 
	C668.908264,546.065491 672.514038,547.479858 674.470093,551.517944 
	C675.292664,553.216125 677.241150,554.394836 679.770020,553.232544 
	C680.169189,552.863464 680.337280,552.741516 680.750244,552.812256 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M682.011475,558.366211 
	C685.186157,557.566589 686.828674,559.118652 687.919556,562.023438 
	C690.507751,568.915710 693.341980,575.707153 697.108521,582.079285 
	C698.369507,584.212463 699.352844,586.557312 698.995850,589.594849 
	C696.736694,589.694214 695.260010,588.414673 695.087830,586.165161 
	C694.679749,580.833252 692.106750,576.517761 689.187134,572.248962 
	C686.301575,568.030090 683.858948,563.532288 682.011475,558.366211 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M630.220276,539.267090 
	C630.225708,537.626648 631.122253,536.812195 632.411621,537.068054 
	C638.711487,538.318237 645.316650,537.058533 651.460938,539.352173 
	C653.105957,539.966248 654.993958,540.434204 655.936768,542.647339 
	C651.786560,543.223145 647.502319,544.081848 643.614197,541.421753 
	C642.747559,540.828857 641.772766,541.331360 640.817627,541.740601 
	C636.878479,543.428406 633.566589,541.860352 630.220276,539.267090 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M715.578491,681.967163 
	C715.967163,682.109436 715.976318,682.279724 715.991638,682.705688 
	C715.578613,684.067505 715.742004,685.190674 715.871338,686.660278 
	C715.683777,687.692810 715.425049,688.342957 714.984741,689.302002 
	C713.648438,691.900696 711.921875,693.998657 712.405701,697.219849 
	C712.327393,698.398804 712.073425,699.134277 711.360168,700.105042 
	C710.288452,700.795776 709.491638,700.815857 708.342285,700.242920 
	C706.983826,694.252930 711.444397,689.766846 711.667114,684.243225 
	C711.722229,682.876526 713.549377,682.038940 715.578491,681.967163 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M702.004395,604.415405 
	C707.632019,606.409973 706.963135,611.413818 706.993652,616.567261 
	C702.863831,613.959412 702.030151,609.563110 702.004395,604.415405 
z"/>
<path fill="#C7C5C3" opacity="1.000000" stroke="none" 
	d="
M704.993835,711.995300 
	C703.557373,711.975342 702.628357,712.958130 701.290161,713.850830 
	C701.682739,709.003296 703.535950,704.569763 707.592651,700.993652 
	C709.367249,702.238220 708.994568,703.710632 708.227783,705.597412 
	C707.995178,705.992920 708.000000,706.000000 707.995850,705.998108 
	C706.680786,707.603638 706.353271,709.676331 705.199707,711.723755 
	C704.991394,711.990723 704.999268,711.999084 704.993835,711.995300 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M715.996338,641.410522 
	C719.574463,643.455750 721.410339,646.763000 720.997009,651.589111 
	C717.248901,649.626892 716.338257,645.860596 715.996338,641.410522 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M707.005798,620.399658 
	C709.345703,623.523621 711.427917,627.173157 712.120728,631.702759 
	C708.460938,629.102539 707.168945,625.205688 707.005798,620.399658 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M700.641968,714.018311 
	C701.004944,714.761841 701.004944,715.513916 701.009827,716.642700 
	C698.582336,718.239746 697.421997,721.062561 694.426453,722.055298 
	C695.163147,718.664612 697.376038,716.082275 700.641968,714.018311 
z"/>
<path fill="#CDCCCC" opacity="1.000000" stroke="none" 
	d="
M699.003052,596.395142 
	C702.981934,596.613464 701.750427,599.754700 701.998779,602.589294 
	C698.631775,602.074707 699.137451,599.279968 699.003052,596.395142 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M708.280823,705.849365 
	C707.993042,704.599365 707.990967,703.205811 707.976929,701.393066 
	C707.964966,700.973816 707.988342,700.488281 707.995361,700.245361 
	C708.748169,699.997620 709.493835,699.992737 710.612427,699.986694 
	C711.345520,700.381897 711.500000,700.846680 711.247192,701.703491 
	C710.219055,703.253357 709.392761,704.479614 708.280823,705.849365 
z"/>
<path fill="#CFCDCE" opacity="1.000000" stroke="none" 
	d="
M624.286255,540.126587 
	C624.005676,539.842834 624.004089,539.676147 624.001221,539.259644 
	C624.750549,539.007568 625.501160,539.005310 626.627075,539.006470 
	C626.829346,540.683777 625.963196,540.988953 624.286255,540.126587 
z"/>
<path fill="#C2C1C2" opacity="1.000000" stroke="none" 
	d="
M689.713989,725.050537 
	C689.547241,726.061157 688.794800,726.763245 687.329590,726.989502 
	C687.136108,725.576111 687.990662,725.001343 689.713989,725.050537 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M670.044983,539.361206 
	C673.695251,540.083008 676.983215,541.730652 678.964233,545.643921 
	C677.697266,547.106445 676.615051,546.440674 675.274048,545.279175 
	C674.238770,545.007080 673.492615,544.997070 672.373413,544.972168 
	C671.363403,543.208191 670.726379,541.459229 670.044983,539.361206 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M665.075256,537.314758 
	C666.143494,537.044800 667.370117,536.914001 667.953003,538.651733 
	C666.878723,538.948059 665.641357,539.099915 665.075256,537.314758 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M675.038330,545.378418 
	C676.096619,545.316895 677.208252,545.616760 678.665527,545.952881 
	C679.011108,545.989197 679.000000,545.998840 679.003784,545.994141 
	C681.337891,547.525513 684.431213,548.526794 683.241577,552.676636 
	C682.560242,552.991638 682.113098,552.995483 681.330566,553.002075 
	C680.995117,553.004883 680.498047,552.995117 680.249512,552.990234 
	C679.762634,549.622864 674.636963,549.571960 675.038330,545.378418 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M566.991089,811.595398 
	C564.385010,811.549072 564.589417,809.037964 563.784363,807.391907 
	C559.515015,798.662048 556.519104,789.316956 553.697388,780.156799 
	C550.478943,769.709045 547.641052,759.026001 544.976440,748.356506 
	C544.396057,746.032471 544.613159,743.259888 541.380615,742.047546 
	C541.628662,738.812500 539.720276,736.118896 538.496887,733.714417 
	C536.212952,729.225525 535.681458,724.370117 534.064575,719.382202 
	C533.750732,716.723694 531.993469,714.565002 533.785461,711.888855 
	C537.046082,710.961121 537.880127,712.968323 538.417358,715.191284 
	C539.462952,719.518188 541.041321,723.681091 542.435486,728.299072 
	C544.131592,733.700867 545.018616,738.953796 547.511475,744.092590 
	C547.874451,744.883972 548.033081,745.354309 548.273926,746.182251 
	C549.092163,749.308777 549.840332,752.073059 551.388794,754.936707 
	C551.859009,755.976746 551.832092,756.677368 551.282349,757.670532 
	C550.308350,760.701599 552.382935,762.660522 553.026367,765.010498 
	C555.206360,772.972656 557.015381,781.090454 559.945435,788.775391 
	C562.104614,794.438416 562.451355,800.777954 566.206482,805.843811 
	C567.397949,807.451172 566.909180,809.373047 566.991089,811.595398 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M571.841125,820.693481 
	C567.737610,821.012268 566.793152,818.362488 567.012512,814.380554 
	C570.091370,815.011292 569.445618,818.749329 571.841125,820.693481 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M551.389038,758.044678 
	C551.048462,757.226624 551.029297,756.485291 551.010193,755.372681 
	C551.185303,754.864990 551.360352,754.728516 551.801514,754.792297 
	C555.388794,755.415222 554.950867,757.951477 554.998779,760.608521 
	C552.968201,761.105286 553.244202,758.582458 551.389038,758.044678 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M574.780762,821.763062 
	C573.864746,823.167053 572.869141,823.285950 572.054932,821.318359 
	C572.855713,821.173889 573.709961,821.342896 574.780762,821.763062 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M542.386169,728.534546 
	C538.631287,725.318237 538.876282,720.248962 537.092957,715.940125 
	C536.485962,714.473450 536.735535,712.694214 534.405151,712.026978 
	C533.568115,711.649963 533.209412,711.248169 532.832031,710.363220 
	C532.604126,707.356079 531.777344,704.940063 530.871460,702.494812 
	C530.222595,700.743286 529.500916,698.762878 531.327087,696.769104 
	C533.408569,696.431946 533.782715,697.711365 534.301514,698.953796 
	C535.870422,702.710632 537.221863,706.544556 538.760681,710.696655 
	C539.321411,712.089661 539.635437,713.187744 539.997437,714.627075 
	C540.864746,715.654663 540.952759,716.765381 541.767090,717.797241 
	C541.986694,719.402344 541.997620,720.786011 542.011841,722.584839 
	C541.836121,724.750244 543.204224,726.261414 542.386169,728.534546 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M522.743347,661.709229 
	C523.003967,663.064941 523.019470,664.120728 523.040283,665.572388 
	C525.187439,668.405212 524.356201,672.048584 526.745728,674.746460 
	C526.998169,675.447021 527.006104,675.885254 527.019653,676.652161 
	C528.273865,678.488403 527.982849,680.254395 527.686768,682.381226 
	C524.981201,684.495605 523.623474,682.687561 523.098999,680.802124 
	C520.979553,673.182922 518.851196,665.573547 516.199341,658.117371 
	C515.853760,657.145691 516.094421,656.161072 516.941406,655.007690 
	C521.834412,654.731140 520.600220,659.303589 522.743347,661.709229 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M539.006409,710.590454 
	C536.006348,709.299133 535.978210,706.015808 534.890564,703.299194 
	C534.109924,701.349304 533.326904,699.400330 532.269958,697.225220 
	C531.597412,696.686157 531.259949,696.317688 530.837036,695.546265 
	C530.458374,693.973145 529.936279,692.760315 530.800354,691.227905 
	C535.786133,696.509277 537.355591,703.365906 539.006409,710.590454 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M547.354309,745.217285 
	C542.898804,740.765686 543.712952,734.874512 541.983643,729.425659 
	C541.977112,729.021729 542.000732,729.000610 542.012573,728.991211 
	C542.742065,728.564758 543.190063,728.976990 543.810913,729.738281 
	C544.727173,731.307190 545.111816,732.710510 545.038940,734.595276 
	C545.542969,735.531372 546.070251,736.067932 546.795105,736.805542 
	C547.008484,737.743774 547.021179,738.483826 547.044189,739.593750 
	C547.862549,740.657227 547.953491,741.765686 548.778687,742.789307 
	C548.999451,743.449524 549.006287,743.893555 549.020569,744.670471 
	C548.625366,745.319336 548.171326,745.472046 547.354309,745.217285 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M531.048401,690.544434 
	C531.339355,692.088806 531.615906,693.222595 531.915710,694.707031 
	C528.243713,696.038940 528.466431,692.605408 527.352600,690.836365 
	C526.247681,689.081421 525.325989,687.120850 526.298828,684.526123 
	C528.424194,682.614075 529.473572,683.885132 530.717896,685.698730 
	C531.000549,687.386169 531.017273,688.759949 531.048401,690.544434 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M522.982910,661.618530 
	C520.053894,660.811157 519.940735,657.681946 518.249146,655.229492 
	C517.414490,654.503540 517.212952,653.863831 517.668335,652.554565 
	C522.328064,653.664307 522.000916,657.793518 522.982910,661.618530 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M551.997314,754.607788 
	C551.891296,754.995361 551.715027,754.998047 551.274536,755.001099 
	C548.864441,753.016174 547.799500,750.455627 547.090942,747.329346 
	C548.372925,745.912842 549.423157,746.560181 550.707764,747.747559 
	C550.908203,750.141846 551.074341,752.234497 551.997314,754.607788 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M530.980225,685.627197 
	C529.878845,685.376587 528.773987,684.740784 527.347412,684.045044 
	C526.891785,683.825806 526.757874,683.666626 526.809570,683.256592 
	C526.999451,681.284790 527.003784,679.563721 527.016724,677.411743 
	C529.906982,678.986206 529.574341,682.529114 530.980225,685.627197 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M526.941345,674.624451 
	C523.218079,673.442261 523.620789,669.864075 523.032837,666.368835 
	C527.316406,667.335693 525.222107,671.663330 526.941345,674.624451 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M518.638184,643.976318 
	C518.979797,644.462097 518.985352,644.900696 518.995483,645.668579 
	C518.188416,646.572693 518.028015,647.422180 518.006470,648.667908 
	C517.411621,649.542603 516.725708,649.802612 515.564575,649.622803 
	C514.795288,649.208252 514.479858,648.833252 514.185242,648.011719 
	C514.094543,646.235718 513.943420,644.798096 514.802368,643.198853 
	C516.141418,643.281006 517.221802,643.605103 518.638184,643.976318 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M544.015625,729.596436 
	C543.522095,729.726685 543.025879,729.472107 542.276978,729.099609 
	C541.866394,727.145264 541.708435,725.308716 541.782837,723.236084 
	C543.484436,724.812134 543.947388,726.947937 544.015625,729.596436 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M550.956421,747.693909 
	C549.900085,747.697571 548.815979,747.382629 547.397339,747.017944 
	C547.052246,746.531006 547.041748,746.093689 547.041199,745.328247 
	C547.489990,745.001282 547.928833,745.002502 548.697876,745.003540 
	C550.046936,745.485291 550.747864,746.221924 550.956421,747.693909 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M514.787598,649.351135 
	C515.792725,648.978455 516.527832,648.989075 517.630371,649.000854 
	C518.004883,649.748779 518.011902,650.495605 518.035950,651.615112 
	C518.052979,651.987854 517.970581,652.013184 517.929688,652.026855 
	C515.446411,653.199524 514.268494,652.510010 514.787598,649.351135 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M548.988770,742.658447 
	C547.588806,742.854370 547.011780,742.012390 547.046448,740.314941 
	C548.423889,740.149414 548.999084,740.988037 548.988770,742.658447 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M541.975952,717.670044 
	C540.579651,717.854858 540.004028,717.012024 540.038635,715.319092 
	C541.427429,715.146729 541.995605,715.992920 541.975952,717.670044 
z"/>
<path fill="#303334" opacity="1.000000" stroke="none" 
	d="
M546.947937,736.691528 
	C546.104187,736.892212 545.345886,736.602783 545.084106,735.282959 
	C545.919739,735.102234 546.669861,735.399902 546.947937,736.691528 
z"/>
<path fill="#3C3E3F" opacity="1.000000" stroke="none" 
	d="
M515.045166,642.542236 
	C515.200195,644.164673 515.339417,645.372559 515.260132,646.792236 
	C512.701233,647.533020 512.722229,645.582458 512.098511,643.877930 
	C512.336914,641.461609 511.124603,639.693298 510.975220,637.360229 
	C510.954163,636.562073 510.952179,636.131958 510.949493,635.379150 
	C508.843597,632.232422 509.216278,628.511902 507.277191,625.262329 
	C507.038452,624.539490 507.029907,624.098755 507.018860,623.327515 
	C504.776825,610.901123 497.113159,602.453857 488.040894,595.016907 
	C484.080994,591.770752 480.457214,588.117065 476.108032,585.328430 
	C474.460022,584.271790 472.950745,582.867920 472.851074,580.338562 
	C472.992462,580.009827 473.005310,579.994324 473.014160,579.985352 
	C476.828003,579.637451 478.479187,584.244995 482.618042,584.005005 
	C483.483765,584.593628 483.978027,585.170044 484.730713,585.876892 
	C485.546936,590.765747 490.361267,591.010254 493.100891,593.392029 
	C498.099609,597.737915 502.668610,602.348572 506.632812,607.961609 
	C506.521423,614.233154 510.007233,619.453613 511.486267,625.154907 
	C512.947205,630.786133 513.836182,636.475586 515.045166,642.542236 
z"/>
<path fill="#EDECEF" opacity="1.000000" stroke="none" 
	d="
M347.067932,586.231812 
	C347.209778,586.206482 347.486359,586.398438 347.900757,586.805542 
	C347.759918,586.830200 347.481293,586.639648 347.067932,586.231812 
z"/>
<path fill="#1A98B0" opacity="1.000000" stroke="none" 
	d="
M224.707306,505.082825 
	C225.048782,505.468964 225.050385,505.879700 225.054138,506.596924 
	C224.349762,509.653046 221.826859,509.387054 219.489716,510.057983 
	C220.306168,507.825653 222.032333,506.145477 224.707306,505.082825 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M281.920074,483.390137 
	C285.698761,483.030090 289.377502,483.029541 293.515137,483.032379 
	C294.833710,483.775360 295.547363,484.840271 296.788910,484.881409 
	C304.134979,485.124847 310.180420,488.554565 315.971130,492.622864 
	C316.948517,493.309509 318.045319,493.559143 319.547424,493.782806 
	C320.338196,494.079376 320.745880,494.326904 321.351288,494.890869 
	C322.516022,496.264252 322.225006,498.312805 324.549652,498.721527 
	C325.504913,499.129456 326.045380,499.549652 326.689697,500.377991 
	C327.349030,503.357300 328.501892,505.698608 330.324036,507.735443 
	C334.821167,512.762207 336.777985,519.290894 340.379120,524.851685 
	C340.845703,525.572144 341.297424,526.222229 342.406464,526.724609 
	C342.920380,526.990295 343.117096,527.112976 343.586243,527.449829 
	C346.998962,531.219238 350.912811,531.195923 355.417084,529.964600 
	C357.371063,529.458435 358.941040,529.452637 360.783447,530.449951 
	C361.421112,531.193054 361.532013,531.723206 361.223022,532.648560 
	C353.293518,536.527893 346.007019,536.058838 338.981323,531.064087 
	C337.185211,529.787231 337.542755,527.744385 336.489960,526.429504 
	C331.774353,520.540039 329.419586,513.140808 324.572937,507.433014 
	C311.788147,492.376923 296.187927,483.961182 275.703613,487.819672 
	C272.460083,488.430603 269.136261,488.614990 265.418579,488.988983 
	C266.843018,485.651245 270.206024,486.292969 273.126434,486.051453 
	C276.194122,485.797791 279.299927,485.964752 281.920074,483.390137 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M380.669342,525.032349 
	C381.001007,525.770386 381.003235,526.503662 381.005646,527.604248 
	C381.639496,528.975952 381.174927,529.751587 380.228516,530.726807 
	C378.596313,530.986511 377.218750,530.999268 375.428650,531.018433 
	C371.335724,530.278015 368.165161,532.229126 364.371246,532.962769 
	C362.804016,530.403198 364.862549,530.068237 366.428925,529.260986 
	C370.215057,527.309875 374.687256,527.637024 378.722473,525.309326 
	C379.461517,525.046692 379.900696,525.037170 380.669342,525.032349 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M378.626495,525.090332 
	C378.442444,527.538330 376.414459,527.685120 374.411652,528.026611 
	C370.679138,528.663086 366.980927,529.454773 364.060547,532.622559 
	C363.233704,532.973389 362.489319,532.983276 361.372467,532.997803 
	C360.999664,532.559082 360.999329,532.115723 360.998901,531.339844 
	C360.422211,530.331665 360.277344,529.569824 360.792725,528.400452 
	C362.417053,528.044617 363.792053,528.014221 365.578705,527.958862 
	C370.070465,526.997498 374.150574,526.061035 378.626495,525.090332 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M257.295502,490.929108 
	C257.103424,490.087769 257.402222,489.336884 258.706512,489.065186 
	C258.897034,489.904907 258.601318,490.658661 257.295502,490.929108 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M265.360535,484.976227 
	C265.514282,482.405823 267.580292,483.159760 269.624146,483.019531 
	C269.485870,485.619507 267.405090,484.835724 265.360535,484.976227 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M257.346924,486.985138 
	C257.451935,484.790436 258.822083,484.744080 260.764252,485.731384 
	C260.152710,487.105164 258.910248,486.975006 257.346924,486.985138 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M435.691833,561.781006 
	C440.062256,564.404053 444.123291,566.804504 447.260559,570.448608 
	C451.037567,574.835876 456.559296,576.695496 461.231537,579.745605 
	C467.494324,583.834106 473.439758,588.073303 478.491028,593.652954 
	C481.818268,597.328247 485.470184,600.826599 489.442444,603.777283 
	C498.403931,610.433960 502.728882,619.572021 505.121216,630.108887 
	C506.879364,637.852661 509.257660,645.450256 512.607056,652.936768 
	C513.192383,653.230713 513.415649,653.420715 513.855591,653.993164 
	C514.391724,656.927551 515.280762,659.613220 511.997559,661.447632 
	C511.029572,661.350403 510.582031,661.066833 510.050476,660.280884 
	C508.534302,657.448181 509.417542,654.143738 506.727905,651.798462 
	C504.660919,649.381042 505.272766,646.670227 504.754517,643.865234 
	C504.569550,642.871704 504.446747,642.212891 504.072388,641.277222 
	C503.376251,639.369446 504.029755,637.434998 502.409180,635.817505 
	C501.652527,634.865479 501.263977,634.081177 500.873718,632.941162 
	C500.587372,631.828796 500.603577,630.994019 500.342865,629.908447 
	C500.142548,629.269226 500.047577,628.903320 499.885590,628.274841 
	C499.695404,627.677429 499.587189,627.338928 499.374756,626.758850 
	C499.144623,626.188904 499.054779,625.851135 499.015137,625.241089 
	C497.457153,617.897034 495.175568,611.406006 487.776337,608.063049 
	C484.760559,606.700500 482.942596,603.662598 480.696716,601.243958 
	C471.161102,590.974731 460.691010,581.959045 447.869812,575.822815 
	C442.013611,573.020142 438.312225,567.473083 434.044006,562.351685 
	C434.393372,561.653931 434.855011,561.506287 435.691833,561.781006 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M498.765625,624.873962 
	C499.324554,625.115662 499.572754,625.314819 499.889038,625.786499 
	C500.245514,626.202881 500.459961,626.431763 500.824829,626.841431 
	C500.999237,627.444580 500.999695,627.888977 501.000092,628.666687 
	C500.600586,629.322388 500.144714,629.467041 499.329651,629.217102 
	C497.139252,628.154785 496.937897,626.509277 497.007507,624.366943 
	C497.509460,624.254150 498.007416,624.511353 498.765625,624.873962 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M430.034943,559.362671 
	C430.738342,559.020264 431.488861,559.011902 432.614532,559.006653 
	C433.575287,559.681946 433.733643,560.445190 433.246948,561.638489 
	C431.503845,561.906067 430.445709,561.247742 430.034943,559.362671 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M433.261902,561.985901 
	C433.009735,561.237549 433.002777,560.494995 432.992767,559.381104 
	C434.505035,559.098572 435.566193,559.760803 435.957031,561.661255 
	C435.555969,561.998840 435.110748,562.000183 434.331543,562.004395 
	C433.997559,562.007324 433.507172,561.991699 433.261902,561.985901 
z"/>
<path fill="#6B6B6E" opacity="1.000000" stroke="none" 
	d="
M405.656677,523.007690 
	C405.983429,523.459167 405.977875,523.908630 405.948334,524.694946 
	C405.062744,524.891113 404.351074,524.555725 404.046021,523.317627 
	C404.437927,523.008423 404.881165,523.007019 405.656677,523.007690 
z"/>
<path fill="#818283" opacity="1.000000" stroke="none" 
	d="
M360.596130,528.050903 
	C361.028290,528.807373 361.014557,529.539673 360.999786,530.639648 
	C359.289185,531.011230 357.579620,531.015076 355.442749,531.021729 
	C351.001343,531.175110 347.082092,530.892578 343.318054,528.258118 
	C343.017670,527.987244 343.000000,527.998779 343.006897,527.989868 
	C339.432495,524.894348 338.025848,520.488953 336.007751,516.451111 
	C333.356110,511.145599 329.265808,506.762970 326.210022,501.291138 
	C325.524231,500.691345 325.057800,500.374756 324.301086,500.023224 
	C322.846375,499.029907 322.426086,497.534668 321.232788,496.213928 
	C320.886383,495.683350 320.691254,495.430145 320.219574,495.108032 
	C315.453156,492.437531 311.458191,488.933746 306.432617,487.159485 
	C304.910980,486.622314 303.369659,485.741943 303.768677,483.335510 
	C309.399536,483.593658 314.041656,485.485321 318.134735,489.393677 
	C320.763153,491.903442 323.635040,494.314056 327.481079,495.605347 
	C331.811676,497.059357 331.684845,502.636505 334.699554,505.794006 
	C338.915710,510.209808 341.790466,515.635498 343.253571,521.777161 
	C343.869354,524.361938 345.982391,526.444336 348.826202,526.882385 
	C352.570770,527.459229 356.372864,527.662720 360.596130,528.050903 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M303.650696,483.025269 
	C304.498596,485.642761 306.901642,485.958923 308.763428,486.738373 
	C313.008759,488.515808 316.988983,490.594879 319.943359,494.655273 
	C314.789124,496.185425 312.511597,490.831604 308.193054,489.661560 
	C304.143402,488.564362 300.569733,485.827759 296.087982,485.768890 
	C295.069397,485.755493 294.400848,484.812225 294.044434,483.404053 
	C296.450806,483.427399 298.865875,484.755463 301.721924,483.272156 
	C302.436188,483.016632 302.877930,483.017334 303.650696,483.025269 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M472.642853,579.963074 
	C476.838562,585.576416 483.320404,588.077209 487.886292,592.860779 
	C491.711029,596.867737 496.556702,599.990540 499.954376,604.291565 
	C504.069824,609.501221 507.005310,615.591736 507.243134,622.749023 
	C502.956451,617.063721 501.318176,609.874023 495.681366,604.592773 
	C489.292114,598.606445 482.096497,593.545044 476.135498,587.099243 
	C473.018341,583.728516 468.351898,581.996033 466.020691,577.366760 
	C468.075531,577.989441 470.184387,578.952881 472.642853,579.963074 
z"/>
<path fill="#4F5053" opacity="1.000000" stroke="none" 
	d="
M513.239990,654.298584 
	C512.995117,654.002441 512.980835,653.521179 512.974976,653.280884 
	C513.583374,649.382996 511.518402,646.233521 511.013947,642.391602 
	C511.726624,641.546631 512.176453,641.974854 512.796814,642.747559 
	C512.986328,644.418335 513.077515,645.794434 514.737427,646.839722 
	C515.037720,647.440735 515.033752,647.877502 515.043701,648.640991 
	C514.961243,650.541565 515.167786,651.903748 517.534180,652.022583 
	C517.917969,652.780029 517.947083,653.519592 517.984985,654.629822 
	C517.314941,655.581177 516.903259,656.333435 517.255798,657.178284 
	C520.425537,664.773987 522.437683,672.717529 524.436096,680.670044 
	C524.672729,681.611755 525.228333,682.494568 526.637817,682.928101 
	C526.995117,683.005798 527.007690,683.495667 527.016724,683.740356 
	C526.893311,688.217346 528.997864,691.578369 531.603455,694.966309 
	C531.952454,695.489014 531.965881,695.920288 531.987183,696.675537 
	C530.249084,701.458801 534.803650,704.876343 534.004272,709.589172 
	C532.690613,711.088196 531.620239,710.464600 530.316895,709.253540 
	C530.028625,708.542236 530.017700,708.101685 530.003357,707.330566 
	C527.587524,700.522644 525.785278,693.835815 523.480957,687.338562 
	C521.772827,682.522461 521.055420,677.455933 519.049316,672.366089 
	C518.960327,671.567505 518.952576,671.139282 518.942505,670.389893 
	C516.815369,665.018738 513.565552,660.365417 513.239990,654.298584 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M507.033936,625.385864 
	C511.195709,626.859985 510.013214,631.004517 510.963928,634.646484 
	C506.840179,633.154968 507.982849,629.027100 507.033936,625.385864 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M512.988770,642.633301 
	C512.501099,642.743958 512.007202,642.484863 511.258667,642.111450 
	C511.077240,640.455750 509.486084,639.103760 510.722046,637.224609 
	C512.350952,638.472839 512.676147,640.364502 512.988770,642.633301 
z"/>
<path fill="#454748" opacity="1.000000" stroke="none" 
	d="
M462.152100,575.280518 
	C463.141602,575.042419 464.359711,574.912109 464.935974,576.653687 
	C463.863403,576.970093 462.935425,576.544861 462.152100,575.280518 
z"/>
<path fill="#5E6062" opacity="1.000000" stroke="none" 
	d="
M412.987640,538.361206 
	C414.414032,538.648804 415.016449,539.789612 414.985352,541.664551 
	C413.601898,541.344299 412.975922,540.220154 412.987640,538.361206 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M380.410034,530.983521 
	C380.232086,530.169373 380.490356,529.365112 380.877228,528.266113 
	C381.446075,527.973511 381.886353,527.975525 382.656860,527.974243 
	C390.794006,526.191711 396.334869,529.689880 400.692474,535.712524 
	C402.901978,538.766235 404.366821,542.296326 406.785797,545.680847 
	C407.005463,545.991333 407.000000,546.000000 407.005310,545.997620 
	C408.924438,554.494385 416.555267,558.290710 421.868439,563.984375 
	C423.094025,565.297729 424.931549,566.133606 424.231232,568.694824 
	C418.289032,569.437622 416.529114,564.031738 413.379913,561.153687 
	C407.573120,555.846924 403.615143,549.066467 400.369019,541.627563 
	C396.937561,533.763855 389.523743,530.581177 380.410034,530.983521 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M424.255920,568.996094 
	C421.826233,564.704102 417.950592,561.952637 414.552399,558.815186 
	C410.904999,555.447632 406.908112,552.443115 407.007202,546.390442 
	C407.575439,546.300903 408.491760,546.480591 408.653137,546.930847 
	C411.649597,555.292664 419.197205,559.386658 425.265594,564.888550 
	C431.563690,570.598633 437.694641,576.493042 443.943237,582.659546 
	C439.305908,585.189148 436.430603,581.615417 433.903809,579.088013 
	C430.941223,576.124817 427.903900,573.077271 425.247620,569.281860 
	C424.990173,569.015076 424.500671,569.002136 424.255920,568.996094 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M424.992493,569.428467 
	C428.680511,569.858276 430.743134,572.851318 432.616730,575.587280 
	C435.310028,579.520142 439.131989,581.359253 443.619446,583.003540 
	C443.987793,583.012207 444.482635,583.020386 444.730103,583.022400 
	C446.000336,583.917603 446.973297,584.844604 447.230499,586.688232 
	C447.254669,587.926025 447.032104,588.690735 446.266724,589.674255 
	C441.903442,590.114014 440.253754,586.187256 437.179199,584.582764 
	C435.941010,583.936707 435.234406,582.406372 433.346069,582.042847 
	C433.001221,581.997559 432.508606,581.992920 432.262482,581.990356 
	C430.972137,579.293823 430.221924,576.492798 426.949799,575.364441 
	C424.261749,574.437378 425.166779,571.864441 424.992493,569.428467 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M467.010437,612.390442 
	C469.568146,612.838440 471.552124,614.224609 473.220459,616.547119 
	C476.765167,621.481689 483.343201,622.818115 487.088287,627.619385 
	C488.031433,628.828491 490.095184,629.418701 489.226837,631.794189 
	C483.514740,627.375916 477.273529,623.951416 470.870331,620.815002 
	C467.390625,619.110596 467.984283,615.607910 467.010437,612.390442 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M446.344055,589.996582 
	C446.008972,589.240417 446.006317,588.493103 446.005493,587.371826 
	C448.162231,585.472595 449.085693,587.667664 450.752289,588.720337 
	C451.916077,589.083618 452.657166,589.389282 452.971008,590.661255 
	C451.952728,590.980896 450.875793,590.991821 449.395203,591.006226 
	C448.427612,590.196228 447.584564,590.025818 446.344055,589.996582 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M492.045593,645.333496 
	C493.505737,645.108582 494.572052,645.765137 494.952759,647.665771 
	C493.493317,647.888733 492.428192,647.231445 492.045593,645.333496 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M541.998779,781.002441 
	C542.240845,783.764038 541.130615,786.914001 544.617310,788.923584 
	C544.312256,792.064880 547.802917,794.092102 547.006470,797.589722 
	C547.384399,798.384827 547.530029,798.846191 547.230591,799.692871 
	C547.008850,800.175598 547.003113,800.345215 546.980713,800.769043 
	C545.157654,800.828064 543.386536,799.784546 543.390625,798.185486 
	C543.405640,792.278442 538.982178,787.646667 539.008911,781.403809 
	C539.693237,780.420593 540.461670,780.266235 541.655029,780.766602 
	C541.995117,781.002441 542.000000,781.000000 541.998779,781.002441 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M530.997314,749.426453 
	C531.494690,749.258911 531.991882,749.515381 532.745789,749.883423 
	C533.726990,751.320007 534.122742,752.723694 534.023376,754.618286 
	C536.389343,758.526489 537.670654,762.302063 536.272644,766.819458 
	C535.326965,767.233887 534.334473,767.509644 534.235474,766.818054 
	C533.418030,761.109436 531.194824,755.670654 530.997314,749.426453 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M536.076233,767.241394 
	C536.703857,763.050415 534.258179,759.605225 534.030884,755.385986 
	C537.780151,757.148865 539.509155,763.259521 537.310059,767.929504 
	C536.654724,767.886047 536.404297,767.696777 536.076233,767.241394 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M538.682739,769.991577 
	C539.124939,771.664307 538.515381,773.731201 541.578247,774.004578 
	C541.957825,774.768555 541.965759,775.508728 541.969727,776.619385 
	C541.132935,777.695740 540.894409,779.014526 539.309875,779.132324 
	C536.395691,776.744629 537.105103,773.683167 537.023438,770.391724 
	C537.476013,769.973267 537.914612,769.980652 538.682739,769.991577 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M539.000854,779.332947 
	C539.786316,778.364136 540.572693,777.728333 541.662415,777.041199 
	C541.976624,778.059998 541.987488,779.130005 541.996704,780.601196 
	C541.248840,781.001160 540.502625,780.999939 539.383423,780.998108 
	C539.007568,780.553650 539.004639,780.109802 539.000854,779.332947 
z"/>
<path fill="#A5A6A8" opacity="1.000000" stroke="none" 
	d="
M524.379761,739.941040 
	C524.026550,738.307007 524.014832,736.579956 524.006897,734.421814 
	C526.431091,735.678284 526.846924,737.609009 524.379761,739.941040 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M529.078735,739.302002 
	C529.912476,739.108398 530.658630,739.403809 530.946411,740.691223 
	C530.113770,740.890076 529.355957,740.607300 529.078735,739.302002 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M454.045532,591.344421 
	C461.053345,594.520813 467.568268,598.842651 472.678040,604.883545 
	C473.737885,606.136475 474.726990,607.402466 476.022675,608.383057 
	C488.696808,617.974670 497.312897,630.120544 500.087006,646.060242 
	C500.587677,648.937012 501.662750,651.713745 502.248444,654.768921 
	C496.398682,651.675964 497.079651,645.314697 495.047333,640.285400 
	C493.750427,637.075928 493.324768,633.516968 492.055786,630.292908 
	C490.501587,626.344177 488.087158,623.035278 483.652893,621.681580 
	C482.281921,621.263000 480.850403,620.390137 479.909210,619.318909 
	C471.781128,610.068176 463.819366,600.667480 454.441742,592.607910 
	C454.222870,592.419739 454.202698,592.000366 454.045532,591.344421 
z"/>
<path fill="#999B9C" opacity="1.000000" stroke="none" 
	d="
M502.016632,659.386963 
	C506.403564,660.640076 506.638916,664.005310 505.348816,667.935547 
	C501.833313,666.077148 501.954254,662.926941 502.016632,659.386963 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M555.334595,819.998779 
	C555.002319,819.246399 555.002258,818.495300 555.004761,817.368347 
	C556.399780,817.138306 556.986084,817.970764 556.987305,819.656494 
	C556.552307,820.003296 556.109497,820.001648 555.334595,819.998779 
z"/>
<path fill="#7C7D7F" opacity="1.000000" stroke="none" 
	d="
M510.742676,658.749023 
	C511.006134,659.469604 511.028107,659.929871 511.082275,660.734253 
	C511.410370,661.427612 511.661682,661.806519 511.923096,662.552002 
	C512.031250,663.789062 511.971344,664.712952 512.754639,665.759155 
	C512.994873,667.067566 513.006836,668.124939 513.022583,669.578857 
	C513.540466,670.524170 514.062439,671.065430 514.779053,671.810791 
	C515.322632,674.397766 515.671509,676.780823 516.025452,679.568909 
	C517.250305,681.281311 517.232666,683.265625 518.747559,684.774719 
	C518.745728,686.470032 518.350159,687.922913 518.955994,689.702148 
	C515.414673,687.706970 513.670349,683.699951 513.302734,680.185425 
	C512.195984,669.604797 506.471802,660.595642 504.239166,650.477356 
	C502.844086,644.154663 499.109375,638.493347 499.030029,631.394897 
	C500.030487,630.196411 500.855286,630.444702 501.779877,631.697815 
	C502.248016,632.806091 502.502625,633.606873 502.890686,634.697510 
	C504.403320,636.254211 503.782959,638.024719 504.269409,639.863647 
	C504.530762,641.080872 504.938782,641.854004 505.763397,642.769531 
	C507.854156,645.230164 505.499878,648.002197 506.981567,650.628845 
	C509.766693,652.748047 508.326019,656.454529 510.742676,658.749023 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M407.004211,545.605469 
	C404.519623,545.092407 403.089050,543.359131 402.033966,540.884033 
	C398.624329,532.885437 392.356903,528.798340 383.347107,528.139526 
	C385.704041,525.327942 392.052948,524.421509 395.062134,526.826172 
	C401.021851,531.588745 404.793915,537.937134 407.004211,545.605469 
z"/>
<path fill="#969698" opacity="1.000000" stroke="none" 
	d="
M450.977722,588.618896 
	C449.142151,589.409424 448.101135,587.827820 446.346680,587.066162 
	C445.694427,585.900269 445.381500,584.802551 445.023071,583.364624 
	C448.818726,582.666565 449.447754,585.946289 450.977722,588.618896 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M501.991211,631.670410 
	C501.216339,631.688538 500.439270,631.371826 499.349976,631.016602 
	C499.030609,630.538574 499.023499,630.099060 499.021606,629.329834 
	C499.465179,629.000427 499.903564,629.000854 500.670959,629.000671 
	C501.816162,629.572510 501.981964,630.420410 501.991211,631.670410 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M518.743164,672.247925 
	C519.896484,672.465210 520.691345,673.117371 520.859375,674.176086 
	C522.139526,682.240540 526.616943,689.427673 527.518311,697.508789 
	C527.895081,700.886536 532.256226,702.655396 530.252441,706.751831 
	C526.413147,704.000000 526.147583,699.430298 524.859192,695.456116 
	C522.412903,687.910339 519.547729,680.448364 518.743164,672.247925 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M511.360352,663.130127 
	C511.088776,662.465576 511.089661,662.068481 511.102478,661.374817 
	C514.396790,659.799622 512.459961,656.951660 512.991882,654.423706 
	C513.539978,654.325134 514.572815,654.673950 514.557129,654.966736 
	C514.273010,660.291870 518.773132,664.123901 518.949585,669.670105 
	C514.924561,669.449158 517.362122,664.005127 513.370850,663.040283 
	C512.629456,663.375488 512.169678,663.514160 511.360352,663.130127 
z"/>
<path fill="#58575B" opacity="1.000000" stroke="none" 
	d="
M530.076843,709.362427 
	C531.132324,709.295532 532.224976,709.608215 533.658813,709.960449 
	C534.008423,710.434814 534.016846,710.869629 534.030334,711.629700 
	C533.376831,714.194885 535.371155,716.242432 534.261230,718.746826 
	C530.666931,716.773804 531.536987,712.774902 530.076843,709.362427 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M518.934326,684.659790 
	C516.604248,684.526123 516.246643,682.663940 516.028564,680.340820 
	C518.492371,680.419678 518.137634,682.731018 518.934326,684.659790 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M511.067352,663.223938 
	C511.514954,662.891235 511.942017,662.919800 512.690674,662.964844 
	C513.006104,663.738586 513.000000,664.495850 512.988403,665.631592 
	C511.535980,665.841797 510.992218,664.952332 511.067352,663.223938 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M514.929565,671.700806 
	C514.092834,671.888794 513.340759,671.593018 513.070068,670.289978 
	C513.905457,670.104248 514.658813,670.398743 514.929565,671.700806 
z"/>
<path fill="#909092" opacity="1.000000" stroke="none" 
	d="
M547.296997,799.956177 
	C547.011047,799.559204 547.007446,799.112488 547.006775,798.330688 
	C547.635742,798.104675 548.385315,798.175049 548.424561,798.955933 
	C548.439758,799.257751 547.878174,799.588684 547.296997,799.956177 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M697.206848,575.195862 
	C696.174438,571.513184 695.342712,568.029358 694.254272,564.275269 
	C692.730225,561.955872 690.346924,560.837341 689.205078,558.255615 
	C688.995117,558.002625 688.983887,557.505920 688.979980,557.257568 
	C688.937195,556.594299 688.993713,556.191772 689.575684,555.390259 
	C696.033203,558.190552 697.154358,564.384888 699.183777,570.020569 
	C701.344727,576.021179 703.268188,582.103821 706.075500,587.868652 
	C706.829773,589.417542 706.719421,591.387512 706.698120,593.781616 
	C704.242859,594.608032 704.268921,592.826050 703.674255,591.527222 
	C702.169800,588.241150 701.922852,584.432739 699.267456,581.257629 
	C699.011963,579.927612 699.002991,578.867004 698.990967,577.408691 
	C698.458069,576.475159 697.936157,575.931641 697.206848,575.195862 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M716.281067,625.655273 
	C715.219299,625.815369 713.831299,625.736084 713.770264,625.409180 
	C712.895020,620.720459 710.158569,616.468811 710.717957,611.250366 
	C713.737793,610.557617 713.203979,613.457886 714.750488,614.753662 
	C715.157837,616.810791 715.325439,618.617065 715.751953,620.703064 
	C717.064880,622.309448 717.142578,623.763062 716.281067,625.655273 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M683.252197,552.994263 
	C683.188171,550.041809 680.419006,548.799255 679.161621,546.292114 
	C684.443848,546.385437 688.105469,549.141296 690.007507,554.616760 
	C690.019287,554.980774 690.004211,554.995789 689.998413,555.001587 
	C687.568359,556.818420 686.167114,554.798096 684.272461,553.283447 
	C683.986938,553.010803 683.497070,553.000671 683.252197,552.994263 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M707.247375,603.245911 
	C707.000000,603.000000 706.993408,602.504639 706.991821,602.256958 
	C705.827454,599.675659 704.798828,597.330444 706.672913,594.390625 
	C706.978333,594.019226 706.991150,594.007751 706.998047,594.001953 
	C711.438477,596.146912 711.183472,600.151489 710.691895,604.700439 
	C709.034058,605.295410 708.344849,604.263794 707.247375,603.245911 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M728.990906,649.621826 
	C725.694763,648.894653 726.273315,644.804382 723.367188,643.047241 
	C722.672302,642.694275 722.421936,642.343018 722.206299,641.600342 
	C722.418884,640.010498 721.920593,638.664185 722.786987,637.212891 
	C723.750916,637.317871 725.094543,637.619263 725.109741,637.976990 
	C725.286133,642.137939 728.474548,645.225525 728.990906,649.621826 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M714.995728,614.626953 
	C712.605042,615.008484 712.247803,613.015564 711.220520,611.210632 
	C710.966614,610.554077 710.965698,610.120178 710.960144,609.360718 
	C710.135925,608.022339 709.892212,606.911743 710.686279,605.342224 
	C710.987793,605.008423 710.999939,605.000061 711.007202,604.993896 
	C713.283325,607.670349 714.282532,610.899475 714.995728,614.626953 
z"/>
<path fill="#B5B3B6" opacity="1.000000" stroke="none" 
	d="
M716.197632,626.128662 
	C715.994324,624.613586 715.998474,623.217468 716.006775,621.401978 
	C719.236206,622.360474 718.098816,626.629028 720.939209,628.653076 
	C721.342407,629.362549 721.526550,629.789001 721.431091,630.627197 
	C720.977356,631.312744 720.570251,631.537109 719.737854,631.590088 
	C719.176514,631.460327 718.966187,631.361206 718.514282,631.014526 
	C717.570496,629.822144 717.538879,628.669006 717.134644,627.291199 
	C716.855896,626.697693 716.662415,626.447815 716.197632,626.128662 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M720.749695,631.288513 
	C720.995789,630.556580 720.998901,630.110779 721.007080,629.330444 
	C724.443420,630.152283 722.444153,633.065491 723.002930,635.572510 
	C722.234314,636.435303 721.569519,636.147217 720.673340,635.417358 
	C720.167297,634.688965 720.003296,634.212708 719.925598,633.327148 
	C720.004639,632.442322 720.198730,631.984253 720.749695,631.288513 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M720.710693,635.330444 
	C721.505127,635.246155 721.998047,635.507935 722.746704,635.881897 
	C723.002441,635.994080 723.008972,636.492371 723.016724,636.741333 
	C722.848511,638.228577 724.137024,639.287903 723.245483,640.797180 
	C722.632874,641.383972 722.166870,641.528076 721.315735,641.218811 
	C721.002441,640.997559 720.999939,640.500000 720.998779,640.251221 
	C719.919983,638.680359 719.880676,637.217102 720.710693,635.330444 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M706.632141,593.929688 
	C706.984863,596.386108 706.991394,598.753052 706.994019,601.564636 
	C704.243286,600.840210 703.414490,598.235657 703.120300,595.769897 
	C702.531677,590.837158 700.967590,586.258179 699.063354,581.358521 
	C703.646057,582.987793 702.444336,588.255981 704.871704,591.514771 
	C705.386353,592.205750 705.187195,593.422974 706.632141,593.929688 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M721.009949,634.654053 
	C721.008423,636.378418 721.004639,637.772461 720.999207,639.584473 
	C717.905457,637.677124 718.236023,633.547485 716.059692,630.352417 
	C716.701782,629.418213 717.468628,629.263916 718.657104,629.770020 
	C718.995117,630.007324 718.995483,630.503662 718.996521,630.751831 
	C719.541687,631.521973 720.060852,632.068665 720.792114,632.803101 
	C720.997742,633.442688 721.002686,633.883179 721.009949,634.654053 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M710.700684,604.856018 
	C710.986267,606.081543 710.984741,607.154724 710.969360,608.631470 
	C707.052429,609.182495 708.266785,605.599182 707.051270,603.375000 
	C708.433411,602.975647 709.368835,603.949097 710.700684,604.856018 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M689.002502,558.366577 
	C690.805542,559.623047 693.923401,560.007996 693.990845,563.643433 
	C691.154724,563.362854 689.411621,561.680908 689.002502,558.366577 
z"/>
<path fill="#BCB9B9" opacity="1.000000" stroke="none" 
	d="
M684.038696,553.352783 
	C685.747253,553.669800 687.507507,554.328796 689.630249,554.997559 
	C689.809448,555.554260 689.626282,556.101257 689.209595,556.828674 
	C686.906555,556.554321 684.863220,556.060974 684.038696,553.352783 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M715.301208,687.197876 
	C715.156067,685.816956 715.327515,684.645691 715.748413,683.217896 
	C716.172668,682.972290 716.347473,682.983276 716.784546,683.018433 
	C716.377930,684.152039 716.699890,685.282959 716.987610,686.707642 
	C716.610840,687.378601 716.149597,687.513672 715.301208,687.197876 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M718.961609,629.687744 
	C718.250427,630.002869 717.505676,629.998413 716.389038,629.990967 
	C716.255554,629.185486 716.494019,628.382996 716.858887,627.288818 
	C718.012085,627.476868 718.735718,628.204895 718.961609,629.687744 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M721.009521,641.345459 
	C721.452087,640.998901 721.901672,641.000305 722.689087,641.004517 
	C723.025391,641.443176 723.023987,641.878967 723.025879,642.641113 
	C723.020630,643.142090 723.011963,643.316895 722.991882,643.754150 
	C721.594421,643.860413 721.011597,643.025269 721.009521,641.345459 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M720.686096,676.075562 
	C721.028015,676.460449 721.030518,676.903931 721.025146,677.680054 
	C720.596069,678.500061 720.242188,679.063660 719.287231,679.136292 
	C719.047729,677.853333 718.910583,676.621765 720.686096,676.075562 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M718.986389,679.330322 
	C719.495422,678.740601 719.997009,678.486633 720.757812,678.122681 
	C720.957764,679.145691 721.088318,680.367065 719.350830,680.941467 
	C719.003784,680.560608 718.991333,680.113281 718.986389,679.330322 
z"/>
<path fill="#BBBABD" opacity="1.000000" stroke="none" 
	d="
M697.056274,575.310303 
	C697.892029,575.108459 698.649170,575.394836 698.938599,576.697144 
	C698.103333,576.897522 697.346130,576.611511 697.056274,575.310303 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M711.353760,697.238403 
	C710.543945,694.085632 711.533264,691.615234 713.731323,689.221375 
	C714.713928,688.535339 715.244995,688.883606 715.867249,689.687073 
	C715.684631,691.101440 715.379944,692.204773 715.031494,693.650879 
	C713.681030,694.954102 714.178223,697.651672 711.353760,697.238403 
z"/>
<path fill="#A9A8A9" opacity="1.000000" stroke="none" 
	d="
M711.036682,697.398804 
	C713.053528,697.094849 712.759644,694.552429 714.660889,694.060120 
	C714.888489,696.747925 713.966309,699.238159 712.296143,701.810852 
	C712.035889,702.049744 711.540283,702.028137 711.292847,702.027649 
	C711.031860,701.573486 711.018372,701.119751 710.995117,700.325806 
	C710.999573,699.246338 711.013733,698.507263 711.036682,697.398804 
z"/>
<path fill="#ACADAD" opacity="1.000000" stroke="none" 
	d="
M716.238037,689.779663 
	C715.498047,689.740723 715.006714,689.483215 714.261353,689.113708 
	C714.183350,688.455017 714.359314,687.908325 714.760010,687.174988 
	C715.432922,686.991211 715.881165,686.994080 716.665405,686.999756 
	C716.829895,687.855347 716.658325,688.708252 716.238037,689.779663 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M705.250793,711.883301 
	C705.211182,709.825073 705.298767,707.607178 707.646851,706.071411 
	C707.998413,706.750305 708.005127,707.504456 708.020996,708.635864 
	C707.713440,709.488159 707.396729,709.963135 707.048584,710.734741 
	C706.514832,711.279602 706.012512,711.527710 705.250793,711.883301 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M325.993103,501.395142 
	C332.511414,506.104706 336.374390,513.018372 339.053436,520.630798 
	C339.947021,523.169922 343.116302,524.204773 343.002655,527.611938 
	C338.299591,528.451904 337.839386,523.880066 336.692352,521.479675 
	C334.250153,516.368713 331.686249,511.507477 327.900940,507.283264 
	C326.535126,505.759186 325.875824,503.877563 325.993103,501.395142 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M343.083466,528.340088 
	C346.722015,529.126648 350.781311,528.897156 354.638306,530.963501 
	C352.006165,533.379089 346.848206,532.365662 343.083466,528.340088 
z"/>
<path fill="#8B8C8D" opacity="1.000000" stroke="none" 
	d="
M321.016846,496.350708 
	C323.012268,496.169586 323.955414,497.307831 324.001282,499.628571 
	C321.979309,499.841553 321.042297,498.694824 321.016846,496.350708 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M510.936035,658.628174 
	C507.148560,657.935425 508.479736,654.112915 507.068298,651.351135 
	C511.562714,651.673706 509.166321,656.053711 510.936035,658.628174 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M504.251221,641.190674 
	C503.037201,639.377197 502.915619,637.594543 503.016724,635.379150 
	C506.067902,635.814087 504.627502,638.344299 505.000122,640.593140 
	C504.834564,641.127136 504.667877,641.254211 504.251221,641.190674 
z"/>
<path fill="#757678" opacity="1.000000" stroke="none" 
	d="
M504.055206,641.312073 
	C504.001221,641.000000 504.501221,641.000000 504.751221,641.000000 
	C505.313416,641.472900 505.625610,641.945740 505.965332,642.711792 
	C505.108002,642.895264 504.354919,642.605774 504.055206,641.312073 
z"/>
<path fill="#717375" opacity="1.000000" stroke="none" 
	d="
M500.881042,626.789978 
	C500.693176,626.875183 500.440582,626.681152 500.124268,626.207092 
	C500.311584,626.123169 500.563507,626.317749 500.881042,626.789978 
z"/>
<path fill="#B7B5B5" opacity="1.000000" stroke="none" 
	d="
M720.996216,632.668823 
	C720.108215,632.893066 719.353210,632.606445 719.051880,631.312134 
	C719.440979,631.000244 719.884460,631.000549 720.660278,631.001587 
	C720.994995,631.446838 720.997314,631.891174 720.996216,632.668823 
z"/>
</g>
</svg>

    </Box> 
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
